import { ServiceAuth } from '@ateams/service-utils';
import { ServiceEndpoint } from './utils';
import {
  AccountId,
  MinimalAccountMemberObject,
  MinimalAccountObject,
} from '@a_team/models/dist/Account';
import { ClientBillingInfo } from '@a_team/models/dist/BillingInfo';
import { QueryResult } from '@a_team/models/dist/misc';
import { MinimalMissionObject } from '@a_team/models/src/MissionObject';

export const BasePath = '/accounts';

export default class AccountsEndpoint extends ServiceEndpoint {
  public adminQueryMinimal(
    auth: ServiceAuth,
    query: string,
    limit?: number,
  ): Promise<QueryResult<MinimalAccountObject>> {
    return this.fetch(
      auth,
      `${BasePath}/admin/minimal?query=${query}${
        limit ? `&limit=${limit}` : ''
      }`,
    );
  }

  public adminGetAccount(
    auth: ServiceAuth,
    id: string,
  ): Promise<MinimalAccountObject> {
    return this.fetch(auth, `${BasePath}/admin/${id}/minimal`);
  }

  public adminGetAccountMembers(
    auth: ServiceAuth,
    id: string,
  ): Promise<QueryResult<MinimalAccountMemberObject>> {
    return this.fetch(auth, `${BasePath}/admin/${id}/members`);
  }

  public adminGetMinimalMissions(
    auth: ServiceAuth,
    id: AccountId,
  ): Promise<QueryResult<MinimalMissionObject>> {
    return this.fetch(auth, `${BasePath}/admin/${id}/minimal-missions`);
  }

  public getBillingInfo(
    auth: ServiceAuth,
    id: AccountId,
  ): Promise<ClientBillingInfo | null> {
    return this.fetch(auth, `${BasePath}/${id}/billing-info`);
  }

  public setBillingInfo(
    auth: ServiceAuth,
    id: AccountId,
    data: ClientBillingInfo,
  ): Promise<void> {
    return this.fetch(
      auth,
      `${BasePath}/${id}/billing-info`,
      null,
      'put',
      data,
    );
  }
}
