import React, {
  CSSProperties,
  ReactElement,
  ReactNode,
  MouseEvent,
  useRef,
} from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { Icon, IconType } from '@ateams/components';
import { Widths } from '@ateams/components';
import useClickOutside from '@src/hooks/useClickOutside';

interface Props {
  children: ReactNode;
  open?: boolean;
  onToggle(open: boolean): void;
  dropdown?: ReactNode;
  width?: keyof typeof Widths;
  className?: string;
  style?: CSSProperties;
  iconType?: IconType;
  iconSize?: string;
}

const useStyles = createUseStyles<Props>({
  root: {
    position: 'relative',
  },
  text: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '24px',
    color: '#222222',
  },
  container: {
    width: (props: Props) => Widths[props.width || 'default'],
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  },
  caret: {
    fontSize: (props) => (props.iconSize ? props.iconSize : '20px'),
    marginLeft: '14px',
    cursor: 'pointer',

    '&.open': {
      transform: 'rotate(180deg)',
    },
  },
  dropdown: {
    position: 'absolute',
    left: 0,
    top: 40,
    zIndex: 1000,
  },
});

export default function FilterToggle(props: Props): ReactElement {
  const {
    children,
    open,
    dropdown,
    onToggle,
    className,
    style,
    iconType = IconType.CaretDown,
  } = props;
  const styles = useStyles(props);

  const handleClick = (e: MouseEvent<HTMLDivElement>): void => {
    onToggle(!open);
  };

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, (): void => {
    if (!open) return;

    onToggle(false);
  });

  return (
    <div ref={ref} className={cx(styles.root, className)} style={style}>
      <div className={styles.container} onClick={handleClick}>
        <div className={styles.text}>{children}</div>
        <Icon type={iconType} className={cx(styles.caret, { open })} />
      </div>

      {!!dropdown && open && <div className={styles.dropdown}>{dropdown}</div>}
    </div>
  );
}
