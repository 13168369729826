import React from 'react';

export const EyeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <circle
        opacity="0.6"
        cx="8"
        cy="8"
        r="2"
        stroke="#62646A"
        strokeWidth="1.4"
      />
      <path
        d="M15 8C15 8.2 13 13 8 13C3 13 1 8.2 1 8C1 7.8 3 3 8 3C13 3 15 7.8 15 8Z"
        stroke="#62646A"
        strokeWidth="1.4"
      />
    </svg>
  );
};
