import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { theme } from '@a_team/ui-components';
import { Spacing } from '@ateams/components';

export interface CardProps {
  title?: string | React.ReactNode;
  titleClassName?: string;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: Spacing.large,
    borderRadius: Spacing.small,
    border: `1px solid ${theme.colors.Grey[300]}`,
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    letterSpacing: '0.001em',
    color: theme.colors.Grey[800],
    padding: '16px 24px',
    background: theme.colors.Hannibal[100],
    margin: '-24px -24px 24px -24px',
    borderTopRightRadius: 'inherit',
    borderTopLeftRadius: 'inherit',
  },
});

export const Card: React.FC<CardProps> = (props) => {
  const { title, children, className, titleClassName } = props;
  const styles = useStyles();

  return (
    <div className={cx(styles.container, className)}>
      {title && <div className={cx(styles.title, titleClassName)}>{title}</div>}
      {children}
    </div>
  );
};
