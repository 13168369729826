import { serviceFetch, ServiceAuth, ServiceError } from '@ateams/service-utils';
import { API_SERVICE_BASE_PATH } from '../config';

export type { ServiceAuth, ServiceError };

// exported functions

export abstract class ServiceEndpoint {
  protected fetch<T>(...params: Parameters<typeof serviceFetch>): Promise<T> {
    const [auth, path, ...leftParams] = params;

    return serviceFetch(auth, API_SERVICE_BASE_PATH + path, ...leftParams);
  }
}
