import React, {
  CSSProperties,
  ReactElement,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import { Card } from '@ateams/components';
import TabButtons, { TabButton } from '@src/components/TabButtons';
import { createUseStyles } from 'react-jss';
import { UploadTab } from '@src/components/FileUploader/components/UploadTab';
import { LinkTab } from '@src/components/FileUploader/components/LinkTab';
import { UnsplashTab } from '@src/components/FileUploader/components/UnsplashTab';
import { ButtonsPosition } from '@src/components/FileUploader/components/FileSelectionButtons';

export interface FileSelectionBoxProps {
  uploadFromUrl: (url: string) => void;
  onUnsplashSelect?: (url: string) => void;
  openDialog?: () => void;
  withImageSearch?: boolean;
  style?: CSSProperties;
  disableFileSelect?: boolean;
  position?: ButtonsPosition;
}

const useStyles = createUseStyles({
  container: (props: FileSelectionBoxProps) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 40px',
    ...(props.position === 'right' && {
      justifyContent: 'flex-end',
    }),
    ...(props.position === 'left' && {
      justifyContent: 'flex-start',
    }),
  }),
  card: (props: FileSelectionBoxProps) => ({
    position: 'absolute',
    top: 42,
    padding: 0,
    width: 530,
    margin: 0,
    overflow: 'hidden',
    cursor: 'default',
    zIndex: 9,
    ...(props.position === 'right' && {
      top: 'auto',
      bottom: 95,
    }),
  }),
});

export const FileSelectionBox = (
  props: FileSelectionBoxProps,
): ReactElement => {
  const [selectedTab, setSelectedTab] = useState(
    !props.disableFileSelect ? 'upload' : 'link',
  );
  const styles = useStyles(props);

  const tabs: { id: string; label: string; component: ReactNode }[] = [
    {
      id: 'link',
      label: 'Link',
      component: <LinkTab {...props} />,
    },
  ];

  props.withImageSearch &&
    tabs.push({
      id: 'unspalsh',
      label: 'Stock Image',
      component: <UnsplashTab {...props} />,
    });
  !props.disableFileSelect &&
    tabs.unshift({
      id: 'upload',
      label: 'Upload',
      component: <UploadTab {...props} />,
    });

  const selectedTabData = useMemo(() => {
    return tabs.find((tab) => tab.id === selectedTab);
  }, [selectedTab]);

  return (
    <div onClick={(e) => e.stopPropagation()} className={styles.container}>
      <Card className={styles.card} style={props.style}>
        <TabButtons style={{ background: '#fff', height: 80 }}>
          {tabs.map((tabButton) => (
            <TabButton
              onClick={(e): void => {
                e?.preventDefault();
                setSelectedTab(tabButton.id);
              }}
              active={selectedTab === tabButton.id}
              key={tabButton.id}
            >
              {tabButton.label}
            </TabButton>
          ))}
        </TabButtons>
        {selectedTabData && selectedTabData.component}
      </Card>
    </div>
  );
};
