import React, { ReactElement } from 'react';
import ExpertiseModal from '@src/components/Modal/ExpertiseModal';
import Profile from '@src/stores/Profile/Profile';
import { Expertise } from '@src/stores/Profile/models';
import { observer } from 'mobx-react';

interface Props {
  onClose: (isOpen?: boolean) => void;
  open: boolean;
  profile: Profile;
}

const RolesModal = (props: Props): ReactElement => {
  const { profile, open, onClose } = props;

  const onSearch = (query: string): Promise<Expertise[]> => {
    return profile.queryRoles(query, false, true);
  };

  return (
    <ExpertiseModal
      title={'Add new roles'}
      onSubmit={(items) => {
        profile?.setAdditionalRoles(items);
        onClose(false);
      }}
      selectedItems={profile?.roles}
      open={open}
      onSearch={onSearch}
      onClose={onClose}
      displayType={'tags'}
    />
  );
};

export default observer(RolesModal);
