import { useAnalytics, UserEvent } from '@ateams/analytics/dist/platform';
import { apiTeamPulse } from '@ateams/api';
import {
  SurveyQuestionType,
  SurveyResponseMap,
} from '@a_team/models/dist/Survey';
import useLoadingState from '@src/hooks/useLoadingState';
import { TeamPulseSurvey as TTeamPulseSurvey } from '@a_team/models/dist/TeamPulse';
import { useStores } from '@src/stores';
import { useEffect } from 'react';
import { TalentSkillId } from '@a_team/models/dist/TalentCategories';
import RoleCategoryObject from '@a_team/models/dist/RoleCategory';

interface UseTeamPulseProps {
  teamPulseSurvey: TTeamPulseSurvey;
}

export type TeamPulseRecommendedRole = {
  roleCategoryId: string;
  name: string;
  skillIds: TalentSkillId[];
  comment: string;
  roleCategory?: RoleCategoryObject;
};

export const useTeamPulse = ({ teamPulseSurvey }: UseTeamPulseProps) => {
  const [loading, setLoading] = useLoadingState();

  const analytics = useAnalytics();
  const { auth, missions } = useStores();
  const { currentMission } = missions;

  useEffect(() => {
    if (currentMission && teamPulseSurvey)
      analytics.track(UserEvent.TeamPulseSurveyStarted, {
        mid: currentMission.mid,
        teamPulseId: teamPulseSurvey.teamPulseId,
        teamPulseSurveyId: teamPulseSurvey.teamPulseSurveyId,
      });
  }, [currentMission, teamPulseSurvey]);

  const submit = async (
    response: SurveyResponseMap,
    hiddenUsersIds?: string[],
  ) => {
    const promise = apiTeamPulse.submitTeamPulseSurveyResponse(auth, {
      response,
      hiddenUsersIds,
      teamPulseSurveyId: teamPulseSurvey.teamPulseSurveyId,
    });
    setLoading(promise);
    await promise;

    if (!currentMission) {
      throw new Error('currentMission is unset');
    }

    analytics.track(UserEvent.TeamPulseSurveySubmitted, {
      mid: currentMission.mid,
      teamPulseId: teamPulseSurvey.teamPulseId,
      teamPulseSurveyId: teamPulseSurvey.teamPulseSurveyId,
      overallRating: (
        response as unknown as SurveyResponseMap<{
          overallRating: SurveyQuestionType.RatingWithFreeFormComment;
        }>
      ).overallRating?.value as number,
    });

    await currentMission.refreshTeamPulse();
  };

  return {
    loading,
    submit,
  };
};
