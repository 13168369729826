import React, { ReactElement } from 'react';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import AdminTeamWorkMissionsList from '../AdminMissionsList/AdminMissionsList';

function AdminTeamWorkEndedMissions(): ReactElement {
  const { missions } = useStores();

  return (
    <AdminTeamWorkMissionsList
      loadMore={() => missions.getAdminEndedMissions(true)}
      showMore={!!missions.adminNextTokens.endedMissions}
      type={'ended'}
      title={'Ended missions'}
      missions={missions.adminViewMissions.endedMissions || []}
    />
  );
}

export default observer(AdminTeamWorkEndedMissions);
