import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import UserCard from '../UserCard';
import { UserCardObject } from '@a_team/models/dist/UserObject';
import { Breakpoints, LoadMoreLink } from '@ateams/components';

interface Props {
  users: UserCardObject[];
  onLoadMore?: () => void;
  topUsers?: boolean;
  minimal?: boolean;
  track?: (useR: UserCardObject) => void;
}

const useStyles = createUseStyles({
  cardsContainer: {
    marginTop: '32px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    gap: '24px',
    marginRight: 0,
  },
  userCard: {
    width: '280px',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    cardsContainer: {
      gap: '40px',
      justifyContent: 'flex-start',
    },
  },
});

export default function UserCardList(props: Props): ReactElement {
  const styles = useStyles();

  return (
    <>
      <div className={styles.cardsContainer}>
        {props.users.length > 0 &&
          props.users.map((user) => {
            return (
              <div key={user.uid} className={styles.card}>
                <UserCard
                  className={styles.userCard}
                  user={user}
                  topMember={props.topUsers}
                  minimal={props.minimal}
                  onClick={() => (props.track ? props.track(user) : undefined)}
                />
              </div>
            );
          })}
      </div>
      {props.onLoadMore && <LoadMoreLink onLoadMore={props.onLoadMore} />}
    </>
  );
}
