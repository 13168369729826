import React, { ReactElement } from 'react';
import {
  currencies,
  Props,
} from '@src/views/Mission/Documents/InvoicesTable/index';
import { format } from 'date-fns';
import { InvoiceStatusDisplay } from '@src/views/Mission/Documents/InvoicesTable/InvoiceStatusDisplay';
import { stringifyMinutes } from '@src/helpers/time';
import { numberWithCommas } from '@src/helpers/numbers';
import { createUseStyles } from 'react-jss';
import TextButton from '@src/components/TextButton';
import { Icon, IconType, Card } from '@ateams/components';
import { InvoiceType } from '@a_team/models/dist/InvoiceObject';
import MissionPaymentCycleObject from '@a_team/models/dist/MissionPaymentCycleObject';

const useStyles = createUseStyles({
  row: {
    marginBottom: 8,
  },
});

interface MobileInvoicesTableProps extends Props {
  paymentCycleIdToPeriodMap: Record<MissionPaymentCycleObject['yid'], string>;
}

export const MobileInvoicesTable = (
  props: MobileInvoicesTableProps,
): ReactElement => {
  const { invoices, paymentCycleIdToPeriodMap } = props;
  const styles = useStyles();

  return (
    <Card style={{ padding: 0, marginRight: 0 }}>
      {invoices.map((invoice) => (
        <div
          style={{
            padding: '24px 16px',
            borderBottom: '1px solid #C0C0C0',
            fontSize: 15,
          }}
        >
          <div
            className={styles.row}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 16,
            }}
          >
            <span style={{ fontWeight: 500 }}>
              {format(new Date(invoice.createdAt), 'PP')}
            </span>
            {invoice.downloadURL && (
              <TextButton
                highlight
                onClick={() => window.open(invoice.downloadURL)}
              >
                <Icon type={IconType.OrangeDownload} /> Export
              </TextButton>
            )}
          </div>
          {invoice.paymentCycle &&
            paymentCycleIdToPeriodMap[invoice.paymentCycle] && (
              <div className={styles.row}>
                {`Invoice Period: ${
                  paymentCycleIdToPeriodMap[invoice.paymentCycle]
                }`}
              </div>
            )}
          <div className={styles.row}>
            Invoice:{' '}
            {invoice.type === InvoiceType.MissionRolePlatformFee
              ? 'Fees'
              : 'Payment'}{' '}
            #{invoice.referenceNumber}
          </div>
          <div className={styles.row}>
            Invoice Status:{' '}
            <InvoiceStatusDisplay
              status={invoice.status}
              paidAt={invoice.paidAt}
            />
          </div>
          {invoice.hourlyRate &&
            invoice.type !== InvoiceType.MissionRolePlatformFee && (
              <div className={styles.row}>
                Total Hours:{' '}
                {stringifyMinutes(
                  (60 * invoice.totalAmount) / invoice.hourlyRate,
                )}
              </div>
            )}
          <div>
            Total Payment: {currencies[invoice.currency]}
            {numberWithCommas(invoice.totalAmount)}
          </div>
        </div>
      ))}
    </Card>
  );
};
