import {
  BorderColors,
  Breakpoints,
  Colors,
  TextColors,
} from '@ateams/components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Select, { components } from 'react-select';
import { Option } from '../utils';
import { Checkbox } from '@a_team/ui-components';
import { noop } from 'lodash';

interface SkillsDropDownProps {
  profileSkills: Option[];
  allSkills: Option[];
  selectedSkills: Option[];
  setSelectedSkills: (skills: Option[]) => void;
}

const useStyles = createUseStyles({
  container: {
    maxWidth: 400,
    position: 'relative',
  },
  placeholder: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 12,
    color: TextColors.placeholderRegular,
  },
  counter: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 50,
    width: 22,
    height: 22,
    borderRadius: '50%',
    backgroundColor: Colors.backgroundDark,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: TextColors.regular,
    fontSize: 12,
  },
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& svg': {
      marginBottom: 3,
      [`@media (min-width: ${Breakpoints.sm}px)`]: {
        marginBottom: 5,
      },
    },
  },
  checkbox: {
    marginRight: 8,
  },
});

// Custom option component for the Select dropdown
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomOption = ({ children, ...props }: any) => {
  const styles = useStyles();

  return (
    <components.Option {...props}>
      <div className={styles.optionContainer}>
        <Checkbox
          checked={props.isSelected}
          onChange={noop}
          className={styles.checkbox}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
        {children}
      </div>
    </components.Option>
  );
};

function SkillsDropDown({
  profileSkills,
  allSkills,
  selectedSkills,
  setSelectedSkills,
}: SkillsDropDownProps) {
  const styles = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  // Memoized grouped options for the dropdown
  const groupedOptions = useMemo(() => {
    // Remove the profile skills from all the skills to avoid duplication
    const allSkillsFiltered = allSkills.filter(
      (skill) =>
        !profileSkills.some(
          (profileSkill) => profileSkill.value === skill.value,
        ),
    );

    return [
      {
        label: 'Your Skills',
        options: profileSkills,
      },
      {
        label: 'All Skills',
        options: allSkillsFiltered,
      },
    ];
  }, [profileSkills, allSkills]);

  // Effect to handle closing the dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handler for input change in the Select component
  const handleInputChange = (
    newValue: string,
    actionMeta: { action: string },
  ) => {
    if (actionMeta.action === 'input-change') {
      setInputValue(newValue);
    }
  };

  // Handler for selecting/deselecting skills
  const handleSkillChange = (skills: readonly Option[]) => {
    setSelectedSkills(skills as Option[]);
  };

  return (
    <div className={styles.container} ref={selectRef}>
      <Select
        placeholder=""
        isMulti={true}
        styles={{
          control: (base) => ({
            ...base,
            border: `1px solid ${BorderColors.lighter}`,
          }),
          placeholder: (base) => ({
            ...base,
            color: TextColors.lighter,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }),
          option: (base) => ({
            ...base,
            backgroundColor: 'white',
            color: TextColors.regular,
            '&:hover': {
              backgroundColor: '#deebff',
            },
          }),
        }}
        components={{
          MultiValue: () => null, // Hide multi-value components as we're using a custom counter
          Option: CustomOption,
        }}
        menuPlacement="bottom"
        value={selectedSkills}
        onChange={handleSkillChange}
        options={groupedOptions}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        menuIsOpen={isOpen}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        openMenuOnClick={true}
        openMenuOnFocus={true}
      />
      {!inputValue && (
        <div className={styles.placeholder} onClick={() => setIsOpen(!isOpen)}>
          Enter skills...
        </div>
      )}
      {selectedSkills.length > 0 && (
        <div className={styles.counter}>{selectedSkills.length}</div>
      )}
    </div>
  );
}

export default SkillsDropDown;
