import { NotificationBanner } from '@ateams/components';
import { ScrubReason } from '@a_team/models/dist/UserObject';
import { isEmpty } from 'lodash';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useStores } from '@src/stores';

interface ReasonsProps {
  scrubbedMinusOneReasons: ScrubReason[];
}

const useStyles = createUseStyles({
  banner: {
    borderWidth: '0 0 0 4px',
    borderColor: '#FF0FAD',
    borderStyle: 'solid',
    '& h2': {
      background: 'transparent',
      color: '#222',
      fontSize: '16px',
      padding: 0,
      fontWieght: 500,
    },
  },
  wrapper: {
    border: '1px solid #D4A8FF',
    borderRadius: 8,
    marginTop: 16,
    padding: 16,
  },
});

export default function Reasons(props: ReasonsProps) {
  const styles = useStyles();
  const { auth } = useStores();

  const renderReason = (reason: ScrubReason) => {
    return (
      <li key={reason.text}>
        <p>{reason.text}</p>
        {!isEmpty(reason.details) && (
          <ul>
            {reason.details?.map((detail) => (
              <li key={detail}>{detail}</li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <NotificationBanner
      title={'We need more information to consider you for A.Team'}
      className={styles.banner}
    >
      <div>
        {auth.withOnboardingV2 ? (
          <>
            To determine whether your skills align with the needs of the
            companies in our network, we need some additional information.
            Please add the following information to your profile by including it
            in a project card or the relevant field, and then resubmit your
            profile for review from the setup page.
          </>
        ) : (
          <>
            To determine whether your skills align with the needs of the
            companies in our network, we need some additional information.
            Please add the following information to your profile by including it
            in a project card or the relevant field:
          </>
        )}
        <div className={styles.wrapper}>
          {<ul>{props.scrubbedMinusOneReasons.map(renderReason)}</ul>}
          <p>
            You’ll have one opportunity to be reconsidered for A.Team. After
            that, you must wait 12 months before requesting to join again.
          </p>
        </div>
      </div>
    </NotificationBanner>
  );
}
