export const fadeInAnimation = {
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
};

export const floatAnimation = {
  '@keyframes float': {
    '0%': {
      transform: 'translateY(0px)',
    },
    '50%': {
      transform: 'translateY(5px)',
    },
    '100%': {
      transform: 'translateY(0px)',
    },
  },
};
