import { Modal } from '@a_team/ui-components';
import { addApostropheToName } from '@src/helpers/strings';
import useIntersectionObserver from '@src/hooks/useIntersectionObserver';
import { useGetUserConnections } from '@src/rq/connections';
import Profile from '@src/stores/Profile/Profile';
import React, { useEffect, useMemo, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import UserConnection from './UserConnection';
import UserAvatar from '@src/components/UserAvatar';

interface UserConnectionsModalProps {
  onClose: () => void;
  isOpen: boolean;
  profile: Profile;
}

const useStyles = createUseStyles<{
  isOpen: boolean;
}>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '40px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    margin: 0,
  },
  userConnectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
  },
  userConnectionAvatarNameContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  modal: {
    '&&': {
      width: '100%',
      maxWidth: '100%',
    },
    // select only the second div inside the modal
    '& > div': {
      padding: '24px 8px !important',
    },
  },
  [`@media (min-width: 1024px)`]: {
    title: {
      fontSize: '20px',
      fontWeight: 600,
      margin: 0,
    },
    modal: {
      '&&': {
        width: '680px !important',
        maxHeight: '650px !important',
        maxWidth: '680px',
      },
      '& > div': {
        padding: '40px !important',
      },
      '& > div:last-of-type': {
        display: 'none',
      },
    },
  },
});

const UserConnectionsModal = ({
  isOpen,
  onClose,
  profile,
}: UserConnectionsModalProps): JSX.Element => {
  const styles = useStyles({
    isOpen,
  });

  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  const {
    data,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isInitialLoading,
    isFetching,
  } = useGetUserConnections(profile.data.uid, isOpen);

  useEffect(() => {
    if (
      isVisible &&
      hasNextPage &&
      !isFetchingNextPage &&
      !isInitialLoading &&
      !isFetching
    ) {
      fetchNextPage();
    }
  }, [
    isVisible,
    isFetchingNextPage,
    hasNextPage,
    isInitialLoading,
    isFetching,
  ]);

  const connectionTitle = useMemo(() => {
    if (profile.isCurrentUser) {
      return 'My';
    }
    return addApostropheToName(profile.data.firstName);
  }, [profile.data.firstName, profile.isCurrentUser]);

  const connections = useMemo(
    () => data?.pages.flatMap(({ items }) => items),
    [data?.pages],
  );

  if (error) {
    return <div>Something went wrong</div>;
  }

  return (
    <Modal
      variant="slideUp"
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
    >
      <div className={styles.container}>
        <div className={styles.userConnectionAvatarNameContainer}>
          <div>
            <UserAvatar
              src={profile.data.profilePictureURL}
              alt={profile.data.fullName}
            />
          </div>
          <h4 className={styles.title}>{connectionTitle} Connections</h4>
        </div>
        <div className={styles.userConnectionsContainer}>
          {connections?.map((userConnection) => {
            return (
              <UserConnection
                key={userConnection.user.uid}
                userConnection={userConnection}
              />
            );
          })}

          <div
            style={{
              height: '1px',
              width: '100%',
            }}
            ref={ref}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UserConnectionsModal;
