import { MissionStatus } from '@a_team/models/dist/MissionObject';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';
import { MissionApplicationReviewStatusOther } from '@a_team/models/dist/MissionApplicationObject';
import { AppliedMissionsDataResponse } from '@a_team/models/dist/v2/MissionApplication';

const isConcluded = (
  application: AppliedMissionsDataResponse,
  username?: string,
) => {
  const status = application.mission.status;
  const reviewStatus = application?.reviewStatus;
  const notSelected = (reviewStatus?.notSelected?.length ?? 0) > 0;
  const unavailable = reviewStatus?.other?.includes(
    MissionApplicationReviewStatusOther.Unavailable,
  );

  if (unavailable) {
    return true;
  }

  const role = application.mission.roles.find(
    (role) => role.rid === application.rid,
  );

  // if the user on the role is diff from the user logged in, then it's concluded
  if (role?.user?.username && role?.user?.username !== username) {
    return true;
  }

  if (!role) {
    return true;
  }

  if (
    [
      MissionRoleStatus.Canceled,
      MissionRoleStatus.Ended,
      MissionRoleStatus.ScheduledToEnd,
    ].includes(role.status)
  ) {
    return true;
  }

  return (
    [
      MissionStatus.Ended,
      MissionStatus.Archived,
      MissionStatus.ScheduledToEnd,
    ].includes(status) || notSelected
  );
};

export function toGroupedApplications(
  applications: AppliedMissionsDataResponse[],
  username?: string,
) {
  const concludedApplications: AppliedMissionsDataResponse[] = [];
  const activeApplications: AppliedMissionsDataResponse[] = [];

  applications.forEach((application) => {
    if (isConcluded(application, username)) {
      concludedApplications.push(application);
    } else {
      activeApplications.push(application);
    }
  });

  return {
    activeApplications,
    concludedApplications,
  };
}
