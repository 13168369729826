import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import SectionHeading from '@src/components/SectionHeading';

const useStyles = createUseStyles({
  root: {
    lineHeight: '24px',
  },
  light: {
    color: '#62646A',
  },
});

export default function TermsOfService(): ReactElement {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <SectionHeading isFirst>
        ATeams Tech Maker Terms of Service Agreement
      </SectionHeading>
      <div style={{ marginTop: '32px' }}>
        This Terms of Service Agreement (“Agreement”) is for Tech Makers
        (defined below) and governs Your use of ATeams’ “Marketplace Platform”
        which connects networks of independent, professional tech makers (like
        You) to companies with technology project needs. Accepting the terms and
        conditions of this Agreement will allow You to join a collaborative
        community of independent professionals who are helping to create the
        future. To use the Marketplace Platform as a Client, You must agree to
        ATeams' Client Terms of Service Agreement, which can be found here.
      </div>
      <div style={{ marginTop: '16px' }}>
        TO ACCESS AND USE ATEAMS’ MARKETPLACE PLATFORM AS A TECH MAKER, YOU MUST
        REVIEW AND ACCEPT THE TERMS OF THIS AGREEMENT BY CLICKING ON THE “I
        ACCEPT” BUTTON AT THE END OF THE TEXT. ONCE ACCEPTED, THIS AGREEMENT
        BECOMES A BINDING LEGAL COMMITMENT BETWEEN YOU AND ATEAMS, INC.
        (“ATEAMS”). IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, YOU SHOULD
        NOT CLICK THE “I ACCEPT” BUTTON.{' '}
      </div>
      <SectionHeading>1. Definitions</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        The following terms shall have the meanings set forth below. Capitalized
        terms not defined in this Section shall have the meanings assigned to
        them where used in this Agreement.
      </div>
      <div style={{ marginTop: '8px' }}>
        “Clients” means individuals or entities who use the Matching Services to
        seek and purchase Tech Maker Services for Projects.
        <br />
        “Confidential Information” shall mean all written or oral information,
        disclosed by or on behalf of either ATeams or You to the other that has
        been identified as confidential or that by the nature of the information
        or circumstances surrounding disclosure would be reasonably understood
        to be confidential or proprietary.
        <br />
        “Matching Services” means the services provided by ATeams whereby a Tech
        Maker and Client are matched for the potential provision of Tech Maker
        Services to Client for one or more Projects.
        <br />
        “Project” means an assignment or project advertised through, or
        otherwise submitted to, the Services for which a Client seeks Tech Maker
        Services.
        <br />
        “Project Fees” means the compensation owed by a Client to Tech Maker for
        Tech Maker Services for a Project on a time and materials or fixed fee
        basis.
        <br />
        <br />
        “Project Order” means the services order generated by ATeams when a
        Client and Tech Maker confirm with ATeams that they have entered into a
        Tech Maker Contract.
        <br />
        “Services” means the service provided by ATeams whereby Clients can
        list, advertise, or otherwise submit Projects, find matches for
        Projects, and coordinate payments for Projects, and Tech Makers can post
        their credentials and submit proposals for Projects.
        <br />
        “Service Fee” means the portion of the Project Fees, retained by ATeams
        as compensation for the Services, as set forth on each Project Order.
        <br />
        “Tech Maker” means an individual, including You, who provides Tech Maker
        Services to Clients, including as part of teams.
        <br />
        “Tech Maker Contract” means a contract between a Tech Maker (or team of
        Tech Makers) and a Client setting forth the terms for the provision of
        Tech Maker Services in connection with a Project.
        <br />
        “Tech Maker Services” means services Tech Makers provide to Clients.
        “Users” means users of the Services and includes both Clients and Tech
        Makers (including You).
        <br />
        “Work Product” means any and all work product created by Tech Maker for
        a Project.
      </div>
      <SectionHeading>2. Services</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        The Services act as a marketplace whereby Clients and Tech Makers can
        connect and identify each other for the sale and purchase of Tech Maker
        Services for Projects. Subject to the terms and conditions set forth in
        this Agreement, ATeams will use commercially reasonable efforts to
        provide You with access to the Services. You can submit to ATeams
        descriptions of the Tech Maker Services You provide, via email at
        help@a.team or through the proper functionality on the ATeams
        Marketplace Platform (each, a “Submission”). ATeams reserves the right
        to refuse any and all Submissions at any time. Submissions must be
        expressly accepted by ATeams. ATeams does not guarantee that any matches
        will be made through the Services.
      </div>
      <SectionHeading>3. Relationship with ATeams</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        ATeams merely makes the Services available to enable Tech Makers and
        Clients to find and transact directly with each other. Through the
        Services, You may be notified of Clients that may be seeking the
        services You offer, and Clients may be notified that You offer the
        services they seek.
      </div>
      <SectionHeading>4. User Responsibilities</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        4.1 You, and not ATeams, are responsible for evaluating and determining
        the suitability of any Project or Client on your own. If You decide to
        enter into a Tech Maker Contract, the Tech Maker Contract is directly
        between You and the Client, and ATeams is not a party to that Tech Maker
        Contract, the relationship, or any dealings or transactions between
        Client and You. Without limitation, You are solely responsible for (a)
        verifying the accuracy and legality of any User Content provided or
        received through the Services, (b) determining the ultimate suitability
        of Clients for a Tech Maker Contract, (c) negotiating, agreeing to, and
        executing any terms or conditions of Tech Maker Contracts, and (d)
        performing Tech Maker Services. ATeams does not, in any way, supervise,
        direct, control, or evaluate Tech Makers or their work and is not
        responsible for any Project, Project or Tech Maker Contract terms, or
        Work Product.
      </div>
      <div style={{ marginTop: '16px' }}>
        4.2 Necessary Equipment and Software. You must provide all equipment and
        software necessary to connect to the Services, as well as for the
        Projects and Tech Maker Services. ATeams does not provide You with
        training or any equipment, labor, tools, or materials related to any
        Tech Maker Contract; and ATeams does not provide the premises at which
        You will perform the work
      </div>
      <SectionHeading>5. Service Limitations</SectionHeading>
      <div style={{ marginTop: '16px' }}>
        ATeams will not have any liability or obligations under or related to
        Tech Maker Contracts and/or Tech Maker Services for any acts or
        omissions by You or any other Users. ATeams does not, in any way,
        supervise, direct, or control You or any Tech Maker Services; does not
        impose quality standards or a deadline for completion of any Tech Maker
        Services; and does not dictate the performance, methods or process You
        use to perform services. You are free to determine when and if to
        perform Tech Maker Services, including the days worked and time periods
        of work, and ATeams does not set or have any control over Your pricing,
        work hours, work schedules, or work location, nor is ATeams involved in
        any other way in determining the nature and amount of any compensation
        that You may charge or be paid for a Project. You will be paid at such
        times and amounts as agreed with a Client in a given Tech Maker
        Contract, and ATeams does not, in any way, provide or guarantee You a
        regular salary or any minimum, regular payment.
      </div>
      <div style={{ marginTop: '16px' }}>
        5.1 Other Business Activities. Nothing in this Agreement is intended to
        prohibit or discourage any User from engaging in other business
        activities or providing any services through any other channels,
        provided You comply with the Referral provisions described in Section 15
        if applicable. You are free at all times to engage in such other
        business activities and services and are encouraged to do so.
      </div>
      <SectionHeading>6. Accounts</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        6.1 Registration. Use of and access to the Services may require
        registration of an account for the Services (“Account”). In registering
        an Account, You agree to (a) provide true, accurate, current and
        complete information (“Registration Data”) and (b) maintain and promptly
        update the Registration Data to keep it true, accurate, current and
        complete. You agree not to provide any false or misleading information
        about Your identity or location, business, skills, or services and to
        correct any such information that is or becomes false or misleading. You
        acknowledge and agree that Registration Data may be shared with other
        Users in connection with the Services, and You hereby grants ATeams a
        non-exclusive, worldwide, royalty free license to use, display, perform,
        transmit, and otherwise exploit Your Registration Data in connection
        with the Services. You are responsible for all activities that occur
        under Your Account and may not share Account or password information
        with anyone. You agree to notify ATeams immediately of any unauthorized
        use of Your password or any other breach of security. If You provide any
        information that is untrue, inaccurate, not current or incomplete, or
        ATeams has reasonable grounds to suspect that any information You
        provide is untrue, inaccurate, not current or incomplete, ATeams has the
        right to suspend or terminate Your Account and refuse any and all
        current or future use of the Services (or any portion thereof). You may
        not have more than one Account at any given time. You may not create an
        Account or use the Service if You have been previously removed by ATeams
        or previously banned from the Services. ATeams reserves the right to
        decline a registration to join ATeams or to add an Account type, for any
        lawful reason, including supply and demand, cost to maintain data, or
        other business considerations.
      </div>
      <div style={{ marginTop: '16px' }}>
        6.2 Account Verification. When You register for an Account and from time
        to time thereafter, Your Account will be subject to verification,
        including, but not limited to, validation against third-party databases
        or verification of one or more official government or legal documents
        that confirm Your identity, location, and ability to act on behalf of
        Your business. You authorize ATeams, directly or through third parties,
        to make any inquiries necessary to validate Your identity, location, and
        ownership of Your email address or financial accounts, subject to
        applicable law. When requested, You must timely provide ATeams with
        complete information about You and Your business, which includes, but is
        not limited to, providing official government or legal documents.
      </div>
      <div style={{ marginTop: '16px' }}>
        6.3 Permitted Users. By granting any individuals or entities permissions
        under Your Account (a “Permitted User”), You represent and warrant that
        (a) the Permitted User is authorized to act on Your behalf, and (b) You
        are fully responsible and liable for any action of any Permitted User
        and any other person who uses the Account. If any such Permitted User
        violates the terms of this Agreement, it may affect Your ability to use
        the Services.
      </div>
      <SectionHeading>7. Tech Maker Contracts</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        7.1 Tech Maker Contract Terms. You and Your Client must enter into a
        Tech Maker Contract for each Project or arrangement for Tech Maker
        Services. You acknowledge and agree that (a) if You and a Client decide
        to enter into a Tech Maker Contract, the Tech Maker Contract is a
        contractual relationship directly between the Client and You (b) Client
        and You have complete discretion both with regard to whether to enter
        into a Tech Maker Contract and with regard to the terms of any Tech
        Maker Contract, and (c) You will notify ATeams that You have entered
        into the Tech Maker Contract to generate a Project Order; and Clients
        and You may enter into any written agreements that You and Clients deem
        appropriate (e.g., confidentiality agreements, invention assignment
        agreements, assignment of rights, etc.) provided that any such
        agreements do not conflict with, narrow, or expand ATeams’s rights and
        obligations under this Agreement or otherwise conflict with or violate
        this Agreement. You acknowledge, agree, and understand that ATeams is
        not a party to any Tech Maker Contract, and that the formation of a Tech
        Maker Contract between You and Clients will not, under any circumstance,
        create an employment or other service relationship, or a partnership or
        joint venture between ATeams and You.
      </div>
      <div style={{ marginTop: '16px' }}>
        7.2 Optional Tech Maker Contract Terms. If You and a Client prefer, You
        can agree to the Optional Tech Maker Contract Terms attached as Exhibit
        A (“Optional Terms”), in whole or in part, in addition to or instead of
        other such agreements. The Optional Terms are provided as a sample only
        and may not be appropriate for all jurisdictions or all contracts. You
        are responsible for complying with any local requirements. You may use
        the Optional Terms in whole or in part, or agree to different or
        additional terms for Your Tech Maker Contract(s), and the terms should
        be adjusted and/or supplemented as You deem appropriate. Neither ATeams
        nor any affiliate of ATeams is a party to any Tech Maker Contract
        regardless of whether the Optional Terms are used or incorporated.
      </div>
      <div style={{ marginTop: '16px' }}>
        7.3 Disclaimer. ATeams does not assume any responsibility for any
        consequence of using the Optional Terms. The Optional Terms are not
        intended to and do not (a) constitute legal advice, (b) create an
        attorney-client relationship, or (c) constitute advertising or a
        solicitation of any type. Each situation is highly fact-specific and
        requirements vary by situation and jurisdiction and therefore You should
        seek legal advice from a licensed attorney in the relevant
        jurisdictions. ATeams expressly disclaims any and all liability with
        respect to actions or omissions based on the Optional Terms.
      </div>
      <SectionHeading>8. Ownership</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        8.1 ATeams. ATeams and its suppliers own all rights, title and interest
        in the Services; all information and materials provided by or on behalf
        of ATeams to You in connection with the Services (excluding User
        Content); and ATeams’s trademarks, and all related graphics, logos,
        service marks and trade names used on or in connection with the Services
        (collectively, “ATeams IP”). ATeams reserves all rights in ATeams IP not
        expressly granted herein.
      </div>
      <div style={{ marginTop: '16px' }}>
        8.2 User Content. You own all rights, title and interest in, and You
        hereby grant ATeams a fully paid, royalty-free, worldwide, non-exclusive
        right and license to use, license, distribute, reproduce, modify, adapt,
        publicly perform, and publicly display, any information, data, text,
        software, and/or other materials provided by or on Your behalf to ATeams
        in connection with the Services (collectively, “User Content”) for the
        purposes of operating and providing the Services to You and other Users.
        You are solely responsible for Your User Content, including the accuracy
        thereof, and for any legal action that may be instituted by other Users
        or third parties as a result of or in connection with Your User Content.
      </div>
      <div style={{ marginTop: '16px' }}>
        8.3 Feedback. You hereby grant to ATeams a fully paid, royalty-free,
        perpetual, irrevocable, worldwide, non-exclusive, and fully
        sublicensable right and license to use, reproduce, perform, display,
        distribute, adapt, modify, re-format, create derivative works of, and
        otherwise commercially or non-commercially exploit in any manner (a) any
        and all feedback, suggestions, or ideas related to the Services or
        ATeams’s products, services, or business provided by You (collectively,
        “Feedback”) and to sublicense the foregoing rights, in connection with
        the operation, maintenance, and improvement of the Services and/or
        ATeams’s business and (b) any feedback, suggestions, ideas, responses,
        comments, information, and data, including survey responses, provided by
        You or on Your behalf related to any Tech Maker Services, Projects, or
        other Users (“Service Assessments”), and to sublicense the foregoing
        rights, in connection with the operation, maintenance, and improvement
        of the Services and/or ATeams’s business, provided that ATeams shall not
        share any Service Assessments with any third parties in a manner that
        identifies You by name.
      </div>
      <div style={{ marginTop: '16px' }}>
        8.4 Work Product. Ownership by and between You and Your Clients of, and
        rights in and to, all Work Product is governed solely by the Tech Maker
        Contracts.
      </div>
      <SectionHeading>9. Restrictions</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        You represent, warrant, and covenant that You will not, and shall not
        permit any Permitted User or third party, to (a) use the Services to
        provide services to third parties or otherwise provide access to the
        Services to third parties; (b) modify any documentation, or create any
        derivative product thereof; (c) assign, sublicense, sell, resell, lease,
        rent, or otherwise transfer or convey, or pledge as security or
        otherwise encumber ATeams’s rights under this Section; (d) harvest,
        collect, gather or assemble information or data regarding other Users
        without their consent; (e) use the Services to solicit, advertise for,
        or contact in any form, Users for employment or any other purpose not
        related to the Projects advertised through the Services; (f) use the
        Services or any information or data received through or in connection
        with the Services in a manner that (i) may infringe or violate the
        intellectual property or other rights of any individual or entity,
        including without limitation the rights of publicity or privacy; (ii)
        may violate applicable laws or governmental regulations; (iii) is
        unlawful, threatening, abusive, harassing, misleading, false,
        defamatory, libelous, deceptive, fraudulent, invasive of another’s
        privacy, tortious, obscene, offensive, profane or racially, ethnically,
        or otherwise discriminatory; (iv) constitutes unauthorized or
        unsolicited advertising, junk or bulk e-mail; (v) impersonates any
        person or entity, including any employee or representative of ATeams;
        (vi) interferes with or attempts to interfere with the proper
        functioning of the Services or uses the Services in any way not
        expressly permitted by this Agreement; or (vii) attempts to engage in or
        engages in, any potentially harmful acts that are directed against the
        Services; or (g) permits any third party to do any of the foregoing,
        directly or indirectly.
      </div>
      <SectionHeading>10. Investigations</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        Although ATeams does not generally monitor User activity occurring in
        connection with the Services or any Projects or Tech Maker Services, if
        ATeams becomes aware of any possible violations by any Users of any
        terms between ATeams and its Users, ATeams reserves the right, but has
        no obligation, to investigate such violations. If, as a result of the
        investigation, ATeams believes that criminal activity has occurred,
        ATeams reserves the right to refer the matter to, and to cooperate with,
        any and all applicable legal authorities. ATeams is entitled, except to
        the extent prohibited by applicable law, to disclose any information or
        materials on or in connection with the Services, including User Content
        or Registration Data, in ATeams’s possession in connection with Your use
        of the Services, to (i) comply with applicable laws, legal process or
        governmental request; (ii) enforce the Agreement; (iii) respond to any
        claims that Your content, acts, or omissions violates the rights of
        third parties; (iv) respond to requests for customer service; or (v)
        protect the rights, property or personal safety of ATeams, its Users or
        the public, and all enforcement or other government officials, as ATeams
        in its sole discretion believes to be necessary or appropriate.
      </div>
      <SectionHeading>11. Interactions with Other Users</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        You are solely responsible for Your interactions with other Users and
        any other parties with whom Your interact; provided, however, that
        ATeams reserves the right, but has no obligation, to intercede in such
        disputes. You agree that ATeams will not be responsible for any
        liability incurred as the result of such interactions. While ATeams may,
        in ATeams’s sole discretion, help facilitate the resolution of disputes
        through various programs, ATeams has no control over and does not
        guarantee the existence, quality, safety or legality of Tech Maker
        Services or Projects; the truth or accuracy of Your content or listings;
        Your ability to sell or provide services; Clients’ ability to pay for
        Tech Maker Services; or that a Client and You will actually complete a
        transaction.
      </div>
      <SectionHeading>12. Confidential Information</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        12.1 Between ATeams and User. ATeams and You each agree as follows: (a)
        to use Confidential Information disclosed by the other party only for
        the purposes expressly permitted herein; (b) that such party will not
        reproduce Confidential Information disclosed by the other party, and
        will hold in confidence and protect such Confidential Information from
        dissemination to, and use by, any third party; (c) that neither party
        will create any derivative work from Confidential Information disclosed
        to such party by the other party; (d) to restrict access to the
        Confidential Information disclosed by the other party to such of its
        personnel, agents, and/or consultants, who have a need to have access
        and who have been advised of, and have agreed in writing to treat such
        information in accordance with, the terms of this Agreement; and (e) to
        the extent practicable, return or destroy all Confidential Information
        disclosed by the other party that is in its possession upon termination
        or expiration of this Agreement. Both parties agree that all items of
        Confidential Information are proprietary to the disclosing party, and as
        between the parties, will remain the sole property of the disclosing
        party.
      </div>
      <div style={{ marginTop: '16px' }}>
        12.2 Confidentiality Exceptions. Notwithstanding the foregoing, the
        provisions of Section 12.1 will not apply to Confidential Information
        that (a) is publicly available or in the public domain at the time
        disclosed; (b) is or becomes publicly available or enters the public
        domain through no fault of the recipient; (c) is rightfully communicated
        to the recipient by persons not bound by confidentiality obligations
        with respect thereto; (d) is already rightfully in the recipient’s
        possession free of any confidentiality obligations with respect thereto
        at the time of disclosure; (e) is independently developed by the
        recipient without use of or reference to the other party’s Confidential
        Information; or (f) is approved for release or disclosure by the
        Disclosing party without restriction. Notwithstanding the foregoing,
        each party may disclose Confidential Information to the limited extent
        required (i) in order to comply with the order of a court or other
        governmental body, or as otherwise necessary to comply with applicable
        law, provided that the party making the disclosure pursuant to the order
        shall first have given written notice to the other party (to the extent
        legally permitted) and made a reasonable effort to obtain a protective
        order; or (ii) to establish a party’s rights under this Agreement,
        including to make such court filings as it may be required to do.
      </div>
      <div style={{ marginTop: '16px' }}>
        12.3 Between Users. You may, between You and other Users, agree to any
        terms You deem appropriate with respect to confidentiality, including
        those set forth in the Optional Terms. If and to the extent that You do
        not articulate any different agreement, then You agree that this Section
        12.3 applies. To the extent one User provides Confidential Information
        to another User, the recipient will protect the secrecy of the
        discloser’s Confidential Information with the same degree of care as it
        uses to protect its own Confidential Information, but in no event with
        less than due care. On a User’s written request, the party that received
        Confidential Information will promptly destroy or return the disclosing
        party’s Confidential Information and any copies thereof contained in or
        on its premises, systems, or any other equipment otherwise under its
        control.
      </div>
      <SectionHeading>13. Worker Classification</SectionHeading>
      <div style={{ marginTop: '16px' }}>
        Nothing in this Agreement is intended to or should be construed to
        create a partnership, joint venture, franchisor/franchisee or
        employer-employee relationship between ATeams and You. Clients are
        solely responsible for and have complete discretion with regard to
        selection of any Tech Maker for any Project. ATeams will have no input
        into, or involvement in, worker classification as between Client and You
        and You agree that ATeams has no involvement in and will have no
        liability arising from or relating to Your classification generally or
        with regard to a particular Project.
      </div>
      <SectionHeading>14. No Solicitation</SectionHeading>
      <div style={{ marginTop: '16px' }}>
        You may not use the Services to solicit for any other business, website
        or services. You may not solicit, advertise for, or contact Clients or
        other Tech Makers for employment, contracting, or any other purpose not
        related to the Services.
      </div>
      <SectionHeading>15. Non-Circumvention</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        15.1 Non-Circumvention Period. The value of the Services rests in its
        thriving marketplace for Clients and Tech Makers. You acknowledge and
        agree that a substantial portion of the compensation ATeams receives for
        providing the Services is collected through the Service Fee described in
        this Agreement. ATeams only receives this Service Fee when a Client and
        a Tech Maker pay and receive payment through the Services. Therefore,
        for 24 months from the time You enter into this Agreement (the
        “Non-Circumvention Period”), You agree to use the Services as Your
        exclusive method to request, make, and receive all payments for work
        directly or indirectly with any Client identified or matched through the
        Services (the “ATeams Relationship”). For the avoidance of doubt, if You
        did not identify, and were not identified by, another party through the
        Services, i.e., if You and Client worked together before being
        introduced or matched through the Services, then the Non-Circumvention
        Period does not apply with respect to You and such Client. If You use
        the Services as an employee, agent, or representative of another
        business, then the Non-Circumvention Period applies to You and other
        employees, agents, or representatives of the business when acting in
        that capacity with respect to the other User.
      </div>
      <div style={{ marginTop: '16px' }}>
        15.2 Restrictions. Unless You pay the Network Fee, You agree not to
        circumvent the payment process managed by ATeams in connection with the
        Services. Without limiting the generality of the foregoing, You agree
        not to: (a) solicit or submit proposals to parties identified through
        the Services to contract, hire, work with, or pay outside the Services;
        (b) accept proposals or solicit parties identified through the Services
        to contract, invoice, or receive payment outside the Services; (c)
        invoice or report on the Services, or in a Network Fee request, a
        payment amount lower than that actually agreed between Users (including
        with respect to allocations between Project Fees and Expenses); or (d)
        refer a User identified on the Services to a third-party who is not a
        User of the Services for any purpose, including making or receiving
        payments in a manner that ATeams does not receive the Service Fee. You
        shall notify ATeams immediately upon becoming aware of a breach or
        potential breach of this non-circumvention provision.
      </div>
      <div style={{ marginTop: '16px' }}>
        15.3 Connection Fee. The non-circumvention obligation in this Section 15
        will not apply to You with respect to an ATeams Relationship only if the
        applicable Client or You pays ATeams a fee for such relationship (the
        “Network Fee”), including if You want to commence or continue working
        with any other Client who is an ATeams Relationship after termination or
        expiration of this Agreement. The Network Fee is computed as follows:
        the greater of (a) twenty thousand dollars ($20,000); or (b) the amount
        that is equal to 3 times (3x) the anticipated monthly compensation paid
        to You, plus (c) ten percent (10%) of any one-time signing bonus or
        similar additional compensation.
      </div>
      <SectionHeading>16. Payment</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        16.1 Fees. When You enter a Tech Maker Contract, You agree to use the
        Services to invoice any Project Fees You are owed under the Tech Maker
        Contract. All Project Fees and other payments for Projects must be made
        through the Services.
      </div>
      <div style={{ marginTop: '16px' }}>
        16.2 Project Fees. Subject to the terms and conditions of this
        Agreement, ATeams will remit to You the Project Fees, minus the Service
        Fee, within thirty (30) days of actually receiving payment from Your
        Clients. Clients are responsible for making all payments in the amounts
        and on the schedule agreed to with You in Your Tech Maker Contracts and
        as set forth herein. ATeams shall not be responsible for payment of any
        amounts not actually received by ATeams, or for any chargebacks,
        deductions, errors, or other payment disputes or issues, provided that
        ATeams reserves the right to offset or deduct amounts owed to ATeams, or
        for chargebacks, deductions, errors, or other payment issues, from
        amounts received by ATeams hereunder.
      </div>
      <div style={{ marginTop: '16px' }}>
        16.3 Expenses. ATeams shall not be responsible for any expenses incurred
        by You in connection with any Projects. If a Client agrees to reimburse
        You for expenses incurred in connection with a Project (“Expenses”),
        such reimbursement will be handled directly between Client and You in
        accordance with the terms set forth in the applicable Tech Maker
        Contract; provided that Expenses shall only include amounts actually
        paid by You to third parties for products and services required for Your
        provision of the Tech Maker Services. All other amounts paid to You
        shall be considered Project Fees and must be paid through the Services.
        ATeams reserves the right to require You and/or Client to provide
        receipts and other documentation for any Expenses.
      </div>
      <div style={{ marginTop: '16px' }}>
        16.4 No Refunds; Non-Payment. All Fees and other amounts paid hereunder
        are nonrefundable. Furthermore, ATeams reserves the right to seek
        reimbursement from You, and You will reimburse ATeams, if ATeams (a)
        suspect fraud or criminal activity associated Your payment, withdrawal,
        or Project; (b) discover erroneous or duplicate transactions; or (c)
        receive any chargebacks from a payment method.
      </div>
      <SectionHeading>17. Taxes</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        17.1 Taxes. The amounts paid under this Agreement do not include any
        taxes or withholdings (“Taxes”) that may be due in connection with any
        Services provided under this Agreement. If ATeams determines it has a
        legal obligation to collect Tax from You in connection with this
        Agreement, ATeams shall collect such Tax in addition to the amounts
        required under this Agreement. If any Services, or payments for any
        Services, under the Agreement are subject to Tax in any jurisdiction and
        You have not remitted the applicable Tax to ATeams, You will be
        responsible for the payment of such Tax and any related penalties or
        interest to the relevant tax authority and will indemnify ATeams Parties
        for any liability or expense incurred. Upon ATeams’s request, You will
        provide official receipts issued by the appropriate taxing authority, or
        such other evidence or documents reasonably requested.
      </div>
      <div style={{ marginTop: '16px' }}>
        17.2 Tech Maker Taxes. You acknowledge and agree that You are solely
        responsible (a) for all tax liability associated with payments received
        from Your Clients and through ATeams, and that ATeams will not withhold
        any taxes from payments to You; (b) to obtain any liability, health,
        workers’ compensation, disability, unemployment, or other insurance
        needed, desired, or required by law, and that You are not covered by or
        eligible for any insurance from ATeams; (c) for determining whether You
        are required by applicable law to issue any particular invoices for the
        Project Fees and for issuing any invoices so required; (d) for filing
        all tax returns and submitting all payments as required by any federal,
        state, local, or foreign tax authority arising from the payment of
        Project Fees to You, and You agree to do so in a timely manner; and (e)
        if outside of the United States, for determining if ATeams is required
        by applicable law to withhold any amount of the Project Fees and for
        notifying ATeams of any such requirement and indemnifying ATeams for any
        requirement to pay any withholding amount to the appropriate authorities
        (including penalties and interest). If applicable, ATeams will report
        the Project Fees paid to You by filing Form 1099-MISC with the Internal
        Revenue Service as required by law. In the event of an audit of ATeams,
        You agree to promptly cooperate with ATeams and provide copies of Your
        tax returns and other documents as may be reasonably requested for
        purposes of such audit, including but not limited to records showing You
        are engaging in an independent business as represented to ATeams. You
        further acknowledge, agree, and understand that: (i) You are not an
        employee of ATeams and are not eligible for any of the rights or
        benefits of employment (including unemployment and/or workers
        compensation insurance).
      </div>
      <SectionHeading>18. Records</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        You will create and maintain records to document satisfaction of
        obligations under this Agreement and provide copies of such records to
        ATeams upon request.
      </div>
      <SectionHeading>19. Indemnification</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        You agree to defend, indemnify and hold ATeams, its parents,
        subsidiaries, affiliates, officers, employees, agents, partners,
        suppliers, and licensors (each, a “ATeams Party” and collectively, the
        “ATeams Parties”) harmless from any losses, costs, liabilities and
        expenses (including reasonable attorneys’ fees) relating to or arising
        out of any and all of the following: (a) User Content; (b) Your use of
        the Services; (c) any Tech Maker Contract entered into by You; (d)
        payment or nonpayment of amounts incurred in connection with the
        Services; (e) Your violation of the Agreement or of any rights of
        another party, including any other Users; or (e) Your violation of any
        applicable laws, rules or regulations. ATeams reserves the right, at its
        own cost, to assume the exclusive defense and control of any matter
        otherwise subject to indemnification by You, in which event You will
        fully cooperate with ATeams in asserting any available defenses. For
        purposes of this Section 19, You includes any of Your agents or any
        person who has apparent authority to access or use Your Account.
      </div>
      <SectionHeading>20. Disclaimer of Warranties</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        20.1 As Is. YOUR USE OF THE SERVICES AND PARTICIPATION IN ANY
        TRANSACTIONS OR ARRANGEMENTS MADE IN CONNECTION THEREWITH ARE AT YOUR
        SOLE RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
        BASIS, WITH ALL FAULTS. ATEAMS PARTIES EXPRESSLY DISCLAIM ALL
        WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER EXPRESS
        OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR
        CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
        NON-INFRINGEMENT. THE ATEAMS PARTIES MAKE NO WARRANTY, REPRESENTATION OR
        CONDITION THAT THE SERVICES OR ANY SERVICES OBTAINED OR TRANSACTIONS
        MADE IN CONNECTION THEREWITH WILL MEET YOUR REQUIREMENTS.
      </div>
      <div style={{ marginTop: '16px' }}>
        20.1.1 Third Parties or Other Users. YOU ACKNOWLEDGE AND AGREE THAT
        ATEAMS PARTIES ARE NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD ATEAMS
        PARTIES LIABLE, FOR THE CONDUCT OF THIRD PARTIES, AND THAT THE RISK OF
        INJURY FROM SUCH THIRD PARTIES RESTS ENTIRELY WITH YOU. YOU ARE SOLELY
        RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH OTHER
        USERS OF THE SERVICES.
      </div>
      <SectionHeading>21. Limitation of Liability</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT SHALL ATEAMS PARTIES
        BE LIABLE FOR (a) ANY INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
        DAMAGES, OR DAMAGES OR COSTS DUE TO LOSS OF PRODUCTION OR USE, BUSINESS
        INTERRUPTION, OR PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, IN EACH
        CASE WHETHER OR NOT ATEAMS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF SERVICE OR
        ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS, ON ANY
        THEORY OF LIABILITY OR (b) ANY AMOUNTS THAT ARE GREATER THAN THE TOTAL
        AMOUNT PAID TO ATeams by YOU during the TWelve-month period prior to the
        act, omission or occurrence giving rise to such liability. THE
        LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE
        BASIS OF THE BARGAIN BETWEEN ATEAMS AND YOU.
      </div>
      <SectionHeading>22. Release</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        ATeams expressly disclaims any liability that may arise between Users.
        Because ATeams is not a party to the actual contracts between Clients
        and You, in the event that You have a dispute with one or more Users,
        You release ATeams, its parents, subsidiaries, affiliates, officers,
        employees, investors, agents, partners and licensors, but excluding any
        Users (collectively, the “ATeams Parties”) from any and all claims,
        demands, or damages (actual or consequential) of every kind and nature,
        known and unknown, suspected and unsuspected, disclosed and undisclosed,
        arising out of or in any way connected with such disputes. You hereby
        waive California Civil Code Section 1542, or any similar law of any
        other jurisdiction which states in substance, “A general release does
        not extend to claims that the creditor or releasing party does not know
        or suspect to exist in his or her favor at the time of executing the
        release and that, if known by him or her, would have materially affected
        his or her settlement with the debtor or released party.”
      </div>
      <SectionHeading>23. Term and Termination</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        23.1 Term. The Agreement commences on the Effective Date and remain in
        full force and effect until terminated by either party in accordance
        with this Agreement.
      </div>
      <div style={{ marginTop: '16px' }}>
        23.2 Termination for Breach. Either party may terminate this Agreement
        upon written notice to the other party if the other party breaches this
        Agreement and does not cure such breach within fifteen (15) days of
        receiving notice thereof. Furthermore, without limiting ATeams’s other
        rights or remedies, ATeams may, but is not obligated to, temporarily or
        indefinitely revoke access to the Services, Your registration, or
        permanently revoke Your access to the Services if ATeams suspects or
        becomes aware that You have provided false or misleading information to
        us or if ATeams believes, in ATeams’s sole discretion, that Your actions
        may cause legal liability for ATeams, Users, or ATeams or any ATeams
        Parties; may be contrary to the interests of the Services or User
        community; or may involve illicit or illegal activity. If Your Account
        is temporarily or permanently closed, You may not use the Services under
        the same Account or a different Account or reregister under a new
        Account without ATeams’s prior written consent.
      </div>
      <div style={{ marginTop: '16px' }}>
        23.3 Termination for Convenience. ATeams may terminate this Agreement
        for its convenience at any time for any reason or no reason at all by
        providing You with at least thirty (30) days prior written notice.{' '}
      </div>
      <div style={{ marginTop: '16px' }}>
        23.4 Effect of Termination. Termination of this Agreement does not
        terminate or otherwise impact any Tech Maker Contract or Project entered
        into between You and Clients. If this Agreement is terminated while one
        or more open Projects and/or Tech Maker Contracts are in effect, You
        agree (a) You will continue to be bound by this Agreement until all such
        Projects and Tech Maker Contracts are closed or end (in accordance with
        their terms); (b) ATeams will continue to perform the Services necessary
        to complete any open Project or related transaction between You and Your
        Client(s); and (c) You will continue to be obligated to pay any amounts
        accrued but unpaid as of the date of termination or as of the closure of
        any open Tech Maker Contracts, whichever is later, to ATeams for any
        amounts owed under the Agreement. Any provisions that by their nature
        would be expected to survive any termination or expiration of this
        Agreement will survive such termination or expiration.
      </div>
      <div style={{ marginTop: '16px' }}>
        23.5 Notification. If ATeams decides to temporarily or permanently close
        Your Account, ATeams has the right where allowed by law but not the
        obligation to: (a) notify other Users that have entered into Tech Maker
        Contracts with You to inform them of Your closed account status, and (b)
        provide those Users with a summary of the reasons for Your account
        closure. You agree that ATeams will have no liability arising from or
        relating to any notice that it may provide to any User regarding closed
        account status or the reason(s) for the closure.
      </div>
      <SectionHeading>24. Dispute Resolution</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        All claims and disputes arising out of or relating to the Agreement will
        be litigated exclusively in the state or federal courts located in New
        York, NY. This Agreement and any action related thereto will be governed
        and interpreted by and under the laws of the State of New York, New
        York, consistent with the Federal Arbitration Act, without giving effect
        to any principles that provide for the application of the law of another
        jurisdiction. The United Nations Convention on Contracts for the
        International Sale of Goods does not apply to the Agreement.
      </div>
      <SectionHeading>25. Construction</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        Section headings are included in this Agreement merely for convenience
        of reference; they are not to be considered part of this Agreement or
        used in the interpretation of this Agreement. When used in this
        Agreement, “including” means “including without limitation.” No rule of
        strict construction will be applied in the interpretation or
        construction of this Agreement.
      </div>
      <SectionHeading>26. General Provisions</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        The Agreement, and Your rights and obligations hereunder, may not be
        assigned, subcontracted, delegated or otherwise transferred by You
        without ATeams’s prior written consent, and any attempted assignment,
        subcontract, delegation, or transfer in violation of the foregoing will
        be null and void. ATeams shall not be liable for any delay or failure to
        perform resulting from causes outside its reasonable control, including,
        but not limited to, acts of God, war, natural disasters, disease,
        terrorism, riots, embargos, acts of civil or military authorities, fire,
        floods, accidents, strikes or shortages of transportation facilities,
        fuel, energy, labor or materials. You may give notice to ATeams at the
        following address: 12 W 10th Street, New York NY 10011. Such notice
        shall be deemed given when received by ATeams by letter delivered by
        nationally recognized overnight delivery service or first class postage
        prepaid mail at the above address. Any waiver or failure to enforce any
        provision of the Agreement on one occasion will not be deemed a waiver
        of any other provision or of such provision on any other occasion. If
        any portion of this Agreement is held invalid or unenforceable, that
        portion shall be construed in a manner to reflect, as nearly as
        possible, the original intention of the parties, and the remaining
        portions shall remain in full force and effect. The Agreement is the
        final, complete and exclusive agreement of the parties with respect to
        the subject matter hereof and supersedes and merges all prior
        discussions between the parties with respect to such subject matter.
      </div>
      <div
        style={{ textAlign: 'right', marginTop: '32px' }}
        className={styles.light}
      >
        232276108 v3{' '}
      </div>
    </div>
  );
}
