import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { format } from 'date-fns';
import uniqueId from 'lodash/uniqueId';
import { theme } from '@a_team/ui-components';
import {
  SelectionTeamOnboardingPlan,
  SelectionTeamOnboardingPlanMilestone,
} from '@a_team/models/dist/selection-team-onboarding';
import { Spacing } from '@ateams/components';
import { Button } from '@src/views/VettingFeedbackForm/components/button';
import { Text } from '@src/views/VettingFeedbackForm/components/typography';
import {
  MilestoneInput,
  OnMilestoneInputChange,
  OnRemoveMilestone,
} from './milestone-input';

export interface SelectionTeamOnboardingPlanFormProps {
  defaultValues?: SelectionTeamOnboardingPlan;
  onSubmit: OnSubmitSelectionTeamOnboardingPlanForm;
  className?: string;
}

export type OnSubmitSelectionTeamOnboardingPlanForm = (
  milestones: SelectionTeamOnboardingPlanMilestone[],
) => void;

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '900px',
  },
  milestonesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    marginBottom: Spacing.large,
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionsRightSide: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: Spacing.medium,
  },
  savedLabel: {
    color: theme.colors.Grey[500],
  },
});

interface SelectionTeamOnboardingPlanMilestoneWithId
  extends SelectionTeamOnboardingPlanMilestone {
  id: string;
}

export const SelectionTeamOnboardingPlanForm: React.FC<
  SelectionTeamOnboardingPlanFormProps
> = (props) => {
  const styles = useStyles();
  const [milestones, setMilestones] = useState<
    SelectionTeamOnboardingPlanMilestoneWithId[]
  >([]);

  useEffect(() => {
    setMilestones(
      props.defaultValues?.milestones.map((milestone) => ({
        ...milestone,
        id: uniqueId(),
      })) || [{ id: uniqueId(), minInterviews: 0, minTimeInDays: 0 }],
    );
  }, [props.defaultValues]);

  const onMilestoneChange: OnMilestoneInputChange = (milestone, index) => {
    const newMilestones = [...(milestones || [])];
    newMilestones[index] = { ...milestone, id: newMilestones[index].id };
    setMilestones(newMilestones);
  };

  const onAddMilestone = () => {
    const newMilestones = [...(milestones || [])];

    newMilestones.push({
      id: uniqueId(),
    });

    setMilestones(newMilestones);
  };

  const onRemoveMilestone: OnRemoveMilestone = (milestoneIndex) => {
    const newMilestones = milestones.filter((_, i) => i !== milestoneIndex);

    setMilestones(newMilestones);
  };

  const onSubmit = () => {
    props.onSubmit(milestones);
  };

  return (
    <div className={cx(styles.container, props.className)}>
      <div className={styles.milestonesContainer}>
        {milestones?.map((milestone, i) => (
          <MilestoneInput
            key={milestone.id}
            milestone={milestone}
            milestoneIndex={i}
            onChange={onMilestoneChange}
            onRemove={onRemoveMilestone}
            /** We want to force the first milestone to be with zero values */
            isDisabled={i === 0}
          />
        ))}
      </div>

      <div className={styles.actionsContainer}>
        <Button onClick={onAddMilestone}>Add milestone</Button>

        <div className={styles.actionsRightSide}>
          {props.defaultValues?.createdAt && (
            <Text className={styles.savedLabel}>
              Last saved{' '}
              {format(
                new Date(props.defaultValues.createdAt),
                'MMM d, yyyy, h:mm a',
              )}
            </Text>
          )}

          <Button onClick={onSubmit}>Save</Button>
        </div>
      </div>
    </div>
  );
};
