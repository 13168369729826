import {
  Spacing,
  FontSizes,
  FontWeights,
  Colors,
  BorderRadius,
  BorderColors,
} from '@ateams/components';
import { format } from 'date-fns';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  transcriptSummary: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: Spacing.large,
    margin: Spacing.xLarge,
  },
  durationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.small,
  },
  durationLabel: {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.semiBold,
    color: Colors.regularDark,
  },
  durationValue: {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.semiBold,
    color: Colors.dark,
    background: Colors.backgroundDark,
    padding: `0 ${Spacing.xsmall}px`,
    borderRadius: BorderRadius.default,
  },
  summary: {
    borderTop: `1px solid ${BorderColors.lighter}`,
    paddingTop: Spacing.large,
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.small,
    lineHeight: `${Spacing.large}px`,
    color: Colors.dark,
  },
});

interface TranscriptSummaryProps {
  summary: string;
  duration: number;
}

const TranscriptSummary: React.FC<TranscriptSummaryProps> = ({
  summary,
  duration,
}) => {
  const styles = useStyles();

  const getDurationFormatted = (duration: number) => {
    return format(new Date(duration * 1000), 'mm');
  };

  return (
    <div className={styles.transcriptSummary}>
      <div className={styles.durationContainer}>
        <div className={styles.durationLabel}>Interview duration</div>
        <div className={styles.durationValue}>
          {`${getDurationFormatted(duration)} minutes`}
        </div>
      </div>
      <div className={styles.summary}>{summary}</div>
    </div>
  );
};

export default TranscriptSummary;
