import { ServiceAuth } from '@ateams/service-utils';
import {
  VettingProcessId,
  VettingType,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { ServiceEndpoint } from './utils';
import LocationObject from '@a_team/models/src/LocationObject';
import PortfolioObject from '@a_team/models/src/PortfolioObject';
import { DateISOString } from '@a_team/models/src/misc';
import { AdminNotesScore } from '@a_team/models/dist/AdminNotesObject';
import { VettingFeedbackFormAvailabilityOption } from '@a_team/models/src/vetting-processes/feedbackV2';
import { RoleCategoryId } from '@a_team/models/src/RoleCategory';

export const BasePath = '/vetting-process/feedback-v2';

export interface CustomUserTagSelectOption {
  id: string;
  name: string;
}

export interface VettingFeedbackFormSelectOption<T> {
  value: T;
  label: string;
}

export interface VettingFeedbackFormAdditionalMaterial {
  id?: string;
  url?: string;
  label?: string;
  fileSize?: number;
  file?: File;
}

export interface VettingProcessFeedbackScoresV2 {
  expertise?: AdminNotesScore;
  interactionExperience?: AdminNotesScore;
  englishLevel?: AdminNotesScore;
  accent?: AdminNotesScore;
}

export enum VettingProcessFeedbackRoleExpertiseScoreType {
  PRIMARY = 'primary',
  APPLIED = 'applied',
  ADDITIONAL = 'additional',
}

export interface VettingProcessFeedbackRoleExpertiseScore {
  roleId: string;
  roleName?: string;
  roleType: VettingProcessFeedbackRoleExpertiseScoreType;
  score: number;
  reason: string;
}

export interface VettingFeedbackFormDefaultsV2
  extends VettingFeedbackSubmitFormV2 {
  uid: string;
  username: string;
  name: string;
  email: string;
  userLocation?: LocationObject;
  avatarUrl?: string;
  profileUrl?: string;
  linkedInUrl?: string;
  resumeUrl?: string;
  portfolio?: PortfolioObject;
  evaluationCalls?: VettingFeedbackFormEvaluationCall[];
  appliedMissions?: VettingProcessFeedbackAppliedMission[];
  uploadedMaterials?: VettingProcessFeedbackUploadedMaterial[];
  additionalMaterialDetails?: string;
  evaluationType: VettingType;
  talentCategory: {
    tid: string;
    name: string;
  };
  admin: {
    uid: string;
    name: string;
  };
  interviewer: {
    uid: string;
    name: string;
  };
  selectedProjects?: string[];
  guildsValues: CustomUserTagSelectOption[];
  availabilityOptions: VettingFeedbackFormSelectOption<VettingFeedbackFormAvailabilityOption>[];
  talentCategoryIds: string[];
  additionalMaterialsFiles: VettingFeedbackFormAdditionalMaterial[];
  roleExpertiseScores: VettingProcessFeedbackRoleExpertiseScore[];
  individualContributor: boolean;
  teamLead: boolean;
  consultantStrategist: boolean;
  cSuiteLevel: boolean;
  lastSaveDate: DateISOString | undefined;
  fromNewEvaluationFeedbackForm?: boolean;
  isViewMode: boolean;
  primaryRoleCategoryId?: RoleCategoryId;
}

export interface VettingFeedbackSubmitFormV2 {
  interviewDate: DateISOString | undefined;
  askedNotToBeRecorded: boolean;
  scores: VettingProcessFeedbackScoresV2;
  expertiseReason?: string;
  interactionExperienceReason: string;
  projects: VettingProcessFeedbackProject[];
  mostProudOf: string;
  workOnProductThatScaled: boolean;
  scaleNotes: string;
  guildsRecommendation: CustomUserTagSelectOption[];
  location: LocationObject | null;
  availability?: VettingFeedbackFormSelectOption<VettingFeedbackFormAvailabilityOption>;
  locationImpressions: string;
  additionalMaterialsFiles: VettingFeedbackFormAdditionalMaterial[];
  skills: VettingFeedbackFormSkill[];
  skillsImpressions: string;
  callSummary: string;
  vetterNotes: string;
  roleExpertiseScores: VettingProcessFeedbackRoleExpertiseScore[];
  individualContributor: boolean;
  teamLead: boolean;
  consultantStrategist: boolean;
  cSuiteLevel: boolean;
  isDraft: boolean;
}

export interface VettingFeedbackFormSkill {
  id: string;
  name: string;
  score: number;
  required: boolean;
  addedFromInterviewer?: boolean;
  notes?: string;
}

export interface VettingFeedbackFormEvaluationCall {
  jobId: string;
  interviewDate: DateISOString;
  recordingUrl?: string;
}

export interface VettingProcessFeedbackAppliedMission {
  missionId: string;
  label: string;
  url: string;
}

export interface VettingProcessFeedbackProject {
  id: string;
  impressions?: string;
}

export interface VettingProcessFeedbackUploadedMaterial {
  url: string;
  label: string;
}

export default class VettingProcessV2Endpoint extends ServiceEndpoint {
  /**
   * Get the default values for the vetting feedback form inputs
   * @param auth - Admins or Vetters
   */
  public getVettingFeedbackFormDefaults(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
  ): Promise<VettingFeedbackFormDefaultsV2> {
    return this.fetch(
      auth,
      `${BasePath}/${vettingProcessId}/defaults`,
      null,
      'get',
    );
  }

  /**
   * Submits vetting process feedback form
   * @param auth - Admins or Vetters
   */
  public submitFeedbackForm(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
    payload: VettingFeedbackSubmitFormV2,
    files: File[],
  ): Promise<void> {
    const formData = new FormData();
    formData.append('json', JSON.stringify(payload));
    files.forEach((file) => {
      formData.append('files', file);
    });

    return this.fetch(
      auth,
      `${BasePath}/${vettingProcessId}/submit`,
      null,
      'put',
      formData,
    );
  }
}
