import React, { ReactElement } from 'react';
import { SidebarSection } from '@src/views/Profile/Sidebar/SidebarSection';
import PhoneNumberInput from '@src/components/Inputs/PhoneNumberInput';

interface Props {
  phone?: string;
  disabled?: boolean;
  readonly?: boolean;
  onChange: (phone: string) => void;
}

export const Phone = (props: Props): ReactElement => {
  const { phone, disabled, onChange, readonly } = props;
  return (
    <SidebarSection title={'Phone Number'} withAddIcon={false}>
      {readonly ? (
        <p style={{ fontSize: 17, color: '#222', marginTop: 12 }}>{phone}</p>
      ) : (
        <PhoneNumberInput
          onChange={onChange}
          value={phone || ''}
          disabled={disabled}
          noBorder
          style={{
            fontSize: 17,
            borderBottom: `1px dashed #C0C0C0`,
            paddingBottom: 6,
            marginBottom: 6,
          }}
        />
      )}
    </SidebarSection>
  );
};
