import React, { ReactElement } from 'react';
import UserAvatar from '@src/components/UserAvatar';
import { createUseStyles } from 'react-jss';
import { EmailFormDataUser } from '@src/views/SkillTargeter/TargeterEmail/EmailForm';

interface UserItemProps {
  user: EmailFormDataUser;
  onUserSelect: (user: EmailFormDataUser) => void;
  hideUserEmail?: boolean;
}

const useStyles = createUseStyles({
  item: {
    display: 'flex',
    padding: '15px 24px',
    cursor: 'pointer',
    '&:last-of-type': {
      marginBottom: 0,
    },
    '&:hover': {
      background: '#FFFAF7',
    },
  },
  iconContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    fontSize: 15,
    color: '#222222',
    fontWeight: 500,
  },
  userDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 24,
    justifyContent: 'center',
  },
});

export const UserItem = (props: UserItemProps): ReactElement => {
  const { fullName } = props.user;
  const profileImage = props.user.imageUrl;
  const styles = useStyles();

  const onSelect = (): void => {
    props.onUserSelect(props.user);
  };

  return (
    <div className={styles.item} onClick={onSelect}>
      <div className={styles.iconContainer}>
        <UserAvatar src={profileImage} />
      </div>
      <div className={styles.userDetails}>
        <span className={styles.name}>{fullName || props.user.email}</span>
        {fullName ? <span>{props.user.email}</span> : null}
      </div>
    </div>
  );
};
