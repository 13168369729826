import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import RegistrationStep from '@src/views/Registration/RegistrationStep';
import { useStores } from '@src/stores';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import useLoadingState from '@src/hooks/useLoadingState';
import { AsyncSelect, Breakpoints, SelectOption } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { ProjectMember } from '@src/views/Registration/Steps/project/ProjectMember';
import { UserId } from '@a_team/models/dist/UserObject';
import { ExperienceUserMember } from '@a_team/models/dist/ExperienceObject';

const useStyles = createUseStyles({
  input: {
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  [`@media (min-width: ${Breakpoints.xl}px)`]: {
    input: {
      marginBottom: '2em',
      '& .input-label': {
        fontSize: '0.9em',
        marginBottom: '0.8em',
      },
    },
  },
});

const Project = (): ReactElement => {
  const styles = useStyles();
  const {
    registration: {
      applyingAsTeam,
      teamMembersWithoutOwner,
      project,
      formData,
      toProjectMember,
      setProjectMembers,
      setProject,
      currentStep,
      querySpecializations,
      PROJECT_TITLE_MIN_CHARS,
      PROJECT_DESCRIPTION_MIN_CHARS,
    },
  } = useStores();
  const [loading, setLoading] = useLoadingState();
  const applyAsTeamText =
    "Forget everything you know about LinkedIn profiles for this part. It's helpful to know where you've worked, but what you accomplished on specific projects is much more relevant for matching you with A.Team missions.";
  const individualText =
    "Forget everything you know about LinkedIn profiles for this part. It's helpful to know where you've worked, but your role on specific projects is much more relevant for matching you with A.Team missions.";

  const getMember = (uid: UserId): ExperienceUserMember | undefined => {
    const member =
      formData.teams &&
      formData.teams[0].members.find((member) => member.uid === uid);
    return member ? toProjectMember(member) : undefined;
  };

  const isTeamMemberInProject = (uid: UserId): boolean => {
    return !!project.members.find((projectMember) => {
      return (projectMember as ExperienceUserMember).uid === uid;
    });
  };

  const onMemberRoleChange = (uid: UserId, role: SelectOption | null) => {
    setProjectMembers([
      ...project.members,
      {
        ...getMember(uid),
        ...(role?.label && {
          memberTitle: role.label,
        }),
      },
    ] as ExperienceUserMember[]);
  };

  const onInTeamChange = (uid: UserId, notInProject: boolean): void => {
    if (notInProject) {
      setProjectMembers(
        project.members.filter(
          (member) => (member as ExperienceUserMember).uid !== uid,
        ),
      );
    } else {
      const member = getMember(uid);
      if (member) {
        setProjectMembers([...project.members, member]);
      }
    }
  };

  const onSubmit = () => {
    currentStep.onSubmit && setLoading(currentStep.onSubmit());
  };

  return (
    <RegistrationStep
      title={`Show Us Something You've Built Recently ${
        applyingAsTeam ? 'as a Team' : ''
      } that You're Really Proud of`}
      description={applyingAsTeam ? applyAsTeamText : individualText}
      onSubmit={onSubmit}
      canSubmit={loading !== true && currentStep.canSubmit}
      loading={loading}
    >
      <OutlinedInput
        value={project?.title || ''}
        label={'What Did You Build?'}
        placeholder={'Interface development for a crypto trading platform...'}
        onChange={(e) =>
          setProject({
            ...project,
            title: e.target.value,
          })
        }
        required
        minLength={PROJECT_TITLE_MIN_CHARS}
        className={styles.input}
      />
      <OutlinedInput
        value={project?.description || ''}
        label={'Tell Us More About It'}
        placeholder={
          "Full-stack development for the Coin Base's user interfaces including onboarding, crypto wallets, transactions, and dashboards..."
        }
        minRows={5}
        onChange={(e) =>
          setProject({
            ...project,
            description: e.target.value,
          })
        }
        minLength={PROJECT_DESCRIPTION_MIN_CHARS}
        required
        multiline
        className={styles.input}
      />
      <AsyncSelect
        label={'How Did You Contribute?'}
        placeholder={'Select your role...'}
        defaultInputValue={project?.jobRole}
        loadOptions={(query) => querySpecializations(query)}
        hideSelectedOptions={false}
        onChange={(item: SelectOption | SelectOption[] | null) =>
          setProject({
            ...project,
            jobRole: (item as SelectOption).label,
          })
        }
        required
        className={styles.input}
      />
      {applyingAsTeam &&
        teamMembersWithoutOwner.map((member) => {
          return (
            <ProjectMember
              key={member._id}
              member={member}
              inProject={isTeamMemberInProject(member._id)}
              onRoleChange={(option) => onMemberRoleChange(member._id, option)}
              onInProjectChange={(checked) =>
                onInTeamChange(member._id, !checked)
              }
              onQuerySpecializations={querySpecializations}
            />
          );
        })}
    </RegistrationStep>
  );
};

export default observer(Project);
