import React, { ReactElement } from 'react';
import { Checkbox, Select } from '@ateams/components';
import { UserStatus } from '@a_team/models/dist/UserObject';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery/index';

const USER_STATUS_DEFAULT = [
  { label: UserStatus.Active, value: UserStatus.Active },
];
const USER_STATUS_OPTIONS = Object.values(UserStatus).map((status) => ({
  label: status,
  value: status,
}));

interface Props {
  selectedStatuses?: string[];
  isAdmin?: boolean;
  optedOutOfClientDiscovery?: boolean;
  requireCurrentlyInterviewing?: boolean;
  requireCurrentlyProposed?: boolean;
  setFilterValue: (filter: Partial<RoleFilter>) => void;
}

const UserStatusSelect = (props: Props): ReactElement => {
  const {
    selectedStatuses,
    isAdmin,
    setFilterValue,
    optedOutOfClientDiscovery,
    requireCurrentlyInterviewing,
    requireCurrentlyProposed,
  } = props;
  const value = selectedStatuses
    ? selectedStatuses.map((status) => ({
        label: status,
        value: status,
      }))
    : USER_STATUS_DEFAULT;

  return (
    <>
      <Checkbox
        onChange={(e) =>
          setFilterValue({
            isAdmin: e.target.checked || undefined,
          })
        }
        margin="none"
        checked={isAdmin}
        label="Only show admin users"
      />
      <Checkbox
        onChange={(e) =>
          setFilterValue({
            optedOutOfClientDiscovery: e.target.checked || undefined,
          })
        }
        margin="top"
        checked={optedOutOfClientDiscovery}
        label="Exclude builders with privacy flag"
      />
      <Checkbox
        onChange={(e) =>
          setFilterValue({
            requireCurrentlyInterviewing: e.target.checked || undefined,
          })
        }
        margin="top"
        checked={requireCurrentlyInterviewing}
        label="Only show currently interviewing"
      />
      <Checkbox
        onChange={(e) =>
          setFilterValue({
            requireCurrentlyProposed: e.target.checked || undefined,
          })
        }
        margin="top"
        checked={requireCurrentlyProposed}
        label="Only show currently proposed"
      />
      <Select
        isMulti
        hideTags
        showItemCount
        isSearchable
        itemCountPrefix="Selected statuses"
        placeholder=""
        value={value}
        onChange={(selectedOptions) =>
          setFilterValue({
            userStatus: selectedOptions.map(({ value }) => value),
          })
        }
        options={USER_STATUS_OPTIONS}
      />
    </>
  );
};
export default UserStatusSelect;
