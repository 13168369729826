import {
  SurveyQuestionMap,
  SurveyQuestionType,
  SurveyType,
} from '@a_team/models/dist/Survey';
import { PersonRatingWithFreeFormCommentSurveyQuestionProps } from '@a_team/models/dist/Survey/questions/types/PersonRatingWithFreeFormCommentSurveyQuestion';
import TextButton from '@src/components/TextButton';
import UserAvatar from '@src/components/UserAvatar';
import { useStores } from '@src/stores';
import React, { MouseEvent, ReactElement, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import TeamPulseSidebarQuestionItem from './TeamPulseSidebarQuestionItem';
import cx from 'classnames';
import logo from './logo.svg';
import { Icon, IconType } from '@ateams/components';

type DivProps = JSX.IntrinsicElements['div'];

export interface Props<T extends SurveyType<keyof T> = SurveyType<never>>
  extends DivProps {
  items: {
    [K in keyof T]: {
      question: SurveyQuestionMap<T>[K];
      completed: boolean;
      disabled: boolean;
    };
  };
  selectedItem: keyof T;
  onSelectItem: <K extends keyof T>(key: K) => void;
  hideQuestionSubtitles?: boolean;
  hideBackButton?: boolean;
}

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  ul: {
    listStyle: 'none',
    padding: 0,
  },
  li: {
    marginTop: '16px',
    marginBottom: '16px',
  },
});

const TeamPulseSurveyModalSidebar = <T extends SurveyType<keyof T>>(
  props: Props<T>,
): ReactElement | null => {
  const styles = useStyles();
  const {
    items,
    onSelectItem,
    selectedItem,
    className,
    hideQuestionSubtitles,
    hideBackButton,
    ...rest
  } = props;
  const keys = useMemo(() => Object.keys(items) as Array<keyof T>, [items]);
  const questionIndex = useMemo(
    () => keys.indexOf(selectedItem),
    [keys, selectedItem],
  );
  const isFirstQuestion = useMemo(() => questionIndex === 0, [questionIndex]);
  const { missions } = useStores();
  const { currentMission } = missions;

  return (
    <div className={cx(className, styles.wrapper)} {...rest}>
      <div>
        <img
          src={logo}
          alt="ateams"
          style={{ width: '32px', height: '32px', marginRight: '16px' }}
        />
      </div>
      <nav>
        <ul className={styles.ul}>
          {keys.map(<K extends keyof T>(key: K, i: number) => {
            const { question, completed, disabled } = items[key];
            const active = i === questionIndex;

            const onClick = (event: MouseEvent) => {
              event.preventDefault();
              if (!disabled) {
                onSelectItem(key);
              }
            };

            if (key === 'overallRating') {
              return (
                <li key={i} className={styles.li}>
                  <TeamPulseSidebarQuestionItem
                    title="Your team"
                    subtitle={
                      hideQuestionSubtitles
                        ? undefined
                        : 'Please answer our questions, it will help us to know better how your team is doing.'
                    }
                    onClick={onClick}
                    active={active}
                    disabled={disabled}
                    completed={completed}
                    icon={(props) => (
                      <div {...props}>
                        <img
                          src={currentMission?.data.logoURL}
                          alt="logo"
                          style={{ width: '100%', height: '100%' }}
                        />
                      </div>
                    )}
                  />
                </li>
              );
            }
            if (
              question.type ===
              SurveyQuestionType.PersonRatingWithFreeFormComment
            ) {
              const { uid } =
                question.props as PersonRatingWithFreeFormCommentSurveyQuestionProps;
              const user = currentMission?.data.roles.find(
                (role) =>
                  role.user && 'uid' in role.user && role.user.uid === uid,
              )?.user;

              return (
                <li key={i} className={styles.li}>
                  <TeamPulseSidebarQuestionItem
                    title={user?.fullName ?? 'Member'}
                    onClick={onClick}
                    active={active}
                    disabled={disabled}
                    completed={completed}
                    icon={(props) => (
                      <UserAvatar
                        size={32}
                        src={
                          user?.username ? user?.profilePictureURL : undefined
                        }
                        {...props}
                      />
                    )}
                  />
                </li>
              );
            }

            return (
              <li key={i} className={styles.li}>
                <TeamPulseSidebarQuestionItem
                  title="Question"
                  onClick={onClick}
                  active={active}
                  disabled={disabled}
                  completed={completed}
                />
              </li>
            );
          })}
        </ul>
      </nav>

      <div>
        {!hideBackButton && (
          <TextButton
            onClick={(event) => {
              event.preventDefault();
              if (questionIndex > 0) {
                onSelectItem(keys[questionIndex - 1]);
              }
            }}
            disabled={isFirstQuestion}
            style={{
              visibility: isFirstQuestion ? 'hidden' : 'visible',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon type={IconType.ArrowBack} style={{ marginRight: '13px' }} />
            <span>Back</span>
          </TextButton>
        )}
      </div>
    </div>
  );
};

export default TeamPulseSurveyModalSidebar;
