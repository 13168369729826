import { ServiceAuth } from '@ateams/service-utils';
import { ServiceEndpoint } from './utils';
import { CustomUserTagObject } from '@a_team/models/dist/CustomUserTagObject';

export const BasePath = '/custom-user-tags';

export type FindCustomUserTagsResponse = CustomUserTagObject[];

export default class CustomUserTagsEndpoint extends ServiceEndpoint {
  findCustomUserTags(auth: ServiceAuth): Promise<FindCustomUserTagsResponse> {
    return this.fetch(auth, BasePath, null, 'get');
  }
}
