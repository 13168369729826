import { Breakpoints, Colors, TextColors } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  UserConnection as UserConnectionType,
  ConnectionType as ConnectionTypes,
} from '@a_team/models/dist/ConnectionObject';
import { Link } from 'react-router-dom';
import UserAvatar from '@src/components/UserAvatar';
import ConnectionType from './ConnectionType';
import { useStores } from '@src/stores';
import { useAnalytics } from '@ateams/analytics/dist/platform';

interface UserConnectionProps {
  userConnection: UserConnectionType;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    borderRadius: '8px',

    '&:hover': {
      background: Colors.backgroundLight,
      transition: 'background 0.2s ease-in-out',
    },
  },
  userFullName: {
    fontWeight: 500,
  },
  userRole: {
    fontSize: '12px',
  },
  viewProfileBtn: {
    background: '#EFEFF0',
    border: 'none',
    borderRadius: '4px',
    color: TextColors.regular,
    padding: '6.5px 16px',
    fontSize: '14px',
    '&:hover': {
      background: '#DADADC',
      color: TextColors.regular,
    },
  },
  userContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
    width: '100%',
  },
  connectionInfo: {
    display: 'flex',
    fontSize: '12px',
    gap: '8px',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '100%',
  },
  viewProfileDesktop: {
    display: 'none',
  },
  viewProfileMobile: {
    display: 'block',
  },
  userFullNameViewProfileBtnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    viewProfileDesktop: {
      display: 'block',
    },
    viewProfileMobile: {
      display: 'none',
    },
    userInfo: {
      width: 'fit-content',
    },
    userContainer: {
      width: 'fit-content',
    },
  },
});

const UserConnection = ({
  userConnection,
}: UserConnectionProps): JSX.Element => {
  const styles = useStyles();

  const { auth } = useStores();
  const analytics = useAnalytics();

  const trackViewProfile = () => {
    if (!auth.user) {
      return;
    }
    analytics.trackViewAllUserConnectionsViewProfileClicked(
      userConnection.user.uid,
      auth.user.uid,
    );
  };

  const title =
    userConnection.user.talentProfile?.talentSpecializations
      ?.mainTalentSpecialization?.name;

  return (
    <div className={styles.container}>
      <div className={styles.userContainer}>
        <div>
          <UserAvatar
            src={userConnection.user.profilePictureURL}
            alt={`${userConnection.user.firstName} ${userConnection.user.lastName}`}
          />
        </div>
        <div className={styles.userInfo}>
          <div className={styles.userFullNameViewProfileBtnContainer}>
            <div>
              <div className={styles.userFullName}>
                {userConnection.user.firstName +
                  ' ' +
                  userConnection.user.lastName}
              </div>
              <div className={styles.userRole}>{title}</div>
            </div>
            <div className={styles.viewProfileMobile}>
              <Link
                to={`/${userConnection.user.username}`}
                onClick={trackViewProfile}
                className={styles.viewProfileBtn}
              >
                View Profile
              </Link>
            </div>
          </div>
          <div className={styles.connectionInfo}>
            {userConnection.connectionTypes
              .sort(
                // sort so work is always first
                (a, b) => a.localeCompare(b),
              )
              .map((connectionType) => {
                // once we remove the social connection type, we can remove this check
                // and just return the ConnectionType component
                // the lint will complain once the social connection type is removed
                if (connectionType === ConnectionTypes.Social) return null;
                // only add dot if it's not the first connection type
                const addDot =
                  userConnection.connectionTypes.indexOf(connectionType) !== 0;

                return (
                  <ConnectionType
                    addDot={addDot}
                    connectionType={connectionType}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <div className={styles.viewProfileDesktop}>
        <Link
          to={`/${userConnection.user.username}`}
          onClick={trackViewProfile}
          className={styles.viewProfileBtn}
        >
          View Profile
        </Link>
      </div>
    </div>
  );
};

export default UserConnection;
