import {
  InterviewScheduledNotificationObject,
  NotificationType,
} from '@a_team/models/dist/NotificationObject';
import React, { useMemo } from 'react';
import Notification from './Notification';
import { DateTime, IANAZone } from 'luxon';

interface InterviewScheduledNotificationProps {
  notification: InterviewScheduledNotificationObject;
  onMarkAsRead(): void;
  isRead?: boolean;
  onViewRequest: () => void;
}

const InterviewScheduledNotification = ({
  isRead,
  onMarkAsRead,
  onViewRequest,
  notification,
}: InterviewScheduledNotificationProps) => {
  const companyName = notification.companyName;

  const title = `Interview with ${companyName} scheduled`;

  const { date, time } = useMemo(() => {
    if (!notification.builderTimezone) {
      return { date: 'Invalid date', time: 'Invalid time' };
    }
    const isValidTimeZone = IANAZone.isValidZone(notification.builderTimezone);

    if (
      !notification.interviewStartDate ||
      !notification.interviewEndDate ||
      !notification.builderTimezone
    ) {
      console.error('Invalid input:', {
        startDate: notification.interviewStartDate,
        endDate: notification.interviewEndDate,
        isValidTimeZone,
      });
      return { date: 'Invalid date', time: 'Invalid time' };
    }

    const startDate = DateTime.fromISO(notification.interviewStartDate, {
      zone: notification.builderTimezone,
    });
    const endDate = DateTime.fromISO(notification.interviewEndDate, {
      zone: notification.builderTimezone,
    });

    if (!startDate.isValid || !endDate.isValid) {
      console.error('Conversion error:', {
        startDateError: startDate.invalidReason,
        endDateError: endDate.invalidReason,
      });
      return { date: 'Invalid date', time: 'Invalid time' };
    }

    const formattedDate = startDate.toLocaleString({
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    const startTime = startDate
      .toLocaleString(DateTime.TIME_SIMPLE)
      .toLowerCase();
    const endTime = endDate.toLocaleString(DateTime.TIME_SIMPLE).toLowerCase();
    const timeZone = startDate.toFormat('ZZZZ'); // Get the timezone abbreviation

    const formattedTime = `${startTime} to ${endTime} ${timeZone}`;

    return { date: formattedDate, time: formattedTime };
  }, [
    notification.interviewStartDate,
    notification.interviewEndDate,
    notification.builderTimezone,
  ]);

  return (
    <>
      <Notification
        createdAt={notification.createdAt}
        notificationType={NotificationType.CompanyLikedYou}
        ctaName="Join interview"
        ctaAction={() => {
          window?.open(notification.interviewUrl, '_blank');
          onViewRequest();
        }}
        title={title}
        description={`${date} - ${time}`}
        imageUrl={notification.companyImageUrl}
        onMarkAsRead={onMarkAsRead}
        isRead={isRead}
      />
    </>
  );
};

export default InterviewScheduledNotification;
