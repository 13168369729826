import { createContext, useContext } from 'react';

export interface CarouselState {
  scrollLeft: number;
}

export type CarouselStateContext = () => CarouselState | null;

const Context = createContext<CarouselStateContext>(() => null);

export const useCarouselState = (): CarouselStateContext => useContext(Context);

export const CarouselStateProvider = Context.Provider;
