import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { theme } from '@a_team/ui-components';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import { InputContainer, InputContainerProps } from './input-container';
import { Separator } from './separator';
import { Spacing } from '@ateams/components';
import { Size, Text } from './typography';

export interface TextInputProps
  extends TextareaAutosizeProps,
    InputContainerProps {
  fullWidth?: boolean;
  withLine?: boolean;
  isReadOnly?: boolean;
  readOnlyText?: string;
  inputContainerClassName?: string;
}

const useStyles = createUseStyles({
  container: ({ fullWidth }: TextInputProps) => ({
    display: 'flex',
    flexDirection: 'row',
    width: fullWidth ? '100%' : 'auto',
  }),
  input: {
    width: '100%',
    resize: 'none',
    border: 'none',
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: 400,
    padding: 0,
    background: 'transparent',
    color: theme.colors.Grey[800],
    '&::placeholder': {
      color: theme.colors.Grey[500],
    },
  },
  separator: {
    marginRight: Spacing.medium,
  },
  readOnlyText: {
    width: '100%',
  },
});

export const TextInput: React.FC<TextInputProps> = (props) => {
  const styles = useStyles(props);
  const {
    label,
    labelSize,
    error,
    fullWidth,
    isReadOnly,
    readOnlyText,
    ...rest
  } = props;

  return (
    <InputContainer
      className={cx(styles.container, props.inputContainerClassName)}
      label={label}
      labelSize={labelSize}
      error={error}
    >
      {props.withLine && (
        <Separator
          direction={'vertical'}
          color={theme.colors.Grey[200]}
          width={2}
          className={styles.separator}
        />
      )}

      {props.isReadOnly ? (
        <Text
          size={Size.Small}
          isReadOnly={isReadOnly}
          readOnlyText={readOnlyText}
          className={styles.readOnlyText}
        >
          {props.value || props.defaultValue}
        </Text>
      ) : (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <TextareaAutosize
          {...rest}
          className={cx(styles.input, props.className)}
        />
      )}
    </InputContainer>
  );
};

TextInput.defaultProps = {
  fullWidth: true,
  withLine: true,
};

export type NumberInputProps = {
  value?: number;
  onChange: (value?: number) => void;
} & Omit<TextInputProps, 'value' | 'onChange'>;

export const NumberInput: React.FC<NumberInputProps> = (props) => {
  const { value, ...rest } = props;

  const onChange: TextInputProps['onChange'] = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
    const num = parseInt(e.target.value);
    props.onChange(isNaN(num) ? undefined : num);
  };

  return (
    <TextInput
      {...rest}
      value={typeof value !== 'undefined' ? String(value) : undefined}
      onChange={onChange}
    />
  );
};
