import { apiConnectionsV2, apiNotifications } from '@ateams/api';
import { ConnectionObjectV2 } from '@a_team/models/dist/ConnectionObject';
import { QueryResult } from '@a_team/models/dist/misc';
import {
  NotificationObject,
  UnreadNotificationObject,
} from '@a_team/models/dist/NotificationObject';
import { Stores, useStores } from '@src/stores';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useDiscovery } from './discovery';
import { queryKeys } from './keys';

const queryRecentConnections = async (
  stores: Stores,
  recentConnectionsChanges: QueryResult<ConnectionObjectV2> | undefined,
  next: string | undefined,
) => {
  if (!next) {
    return {
      recentConnections: recentConnectionsChanges?.items || [],
      next: recentConnectionsChanges?.next,
    };
  }

  const recentConnections = await apiConnectionsV2.getRecentChanges(
    stores.auth,
    next,
  );

  return {
    recentConnections: recentConnections.items,
    next: recentConnections.next,
  };
};

export const useRecentConnections = () => {
  const stores = useStores();
  const { data: discovery } = useDiscovery();
  const recentConnections = discovery?.recentConnectionChanges;

  return useInfiniteQuery(
    queryKeys.notifications.recent,
    ({ pageParam = undefined }) =>
      queryRecentConnections(stores, recentConnections, pageParam),
    {
      getNextPageParam: (lastPage) => lastPage?.next,
      enabled: recentConnections?.items !== undefined,
    },
  );
};

export const useGetAllNotifications = ({
  onlyUnread,
  enabled = true,
}: {
  onlyUnread?: boolean;
  enabled?: boolean;
}) => {
  const stores = useStores();

  return useQuery({
    queryKey: queryKeys.notifications.getAllNotifications(
      onlyUnread || false,
      stores?.auth?.currentUser?.uid,
    ),
    queryFn: () => {
      return apiNotifications.getAllNotifications(stores.auth, onlyUnread);
    },
    enabled: enabled && !!stores?.auth?.currentUser,
  });
};

export const useMarkMultipleNotificationsAsRead = () => {
  const stores = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ notificationIds }: { notificationIds: string[] }) =>
      apiNotifications.markMultipleAsRead(stores.auth, notificationIds),
    onMutate: ({ notificationIds }) => {
      // update unread notifications
      queryClient.setQueryData(
        queryKeys.notifications.getAllNotifications(
          true,
          stores?.auth?.currentUser?.uid,
        ),
        (notifications: UnreadNotificationObject[] | undefined) => {
          if (notifications) {
            return notifications.filter(
              (notification) => !notificationIds.includes(notification.nid),
            );
          }

          return notifications;
        },
      );

      // update all notifications
      queryClient.setQueryData(
        queryKeys.notifications.getAllNotifications(
          false,
          stores?.auth?.currentUser?.uid,
        ),
        (notifications: UnreadNotificationObject[] | undefined) => {
          if (notifications) {
            return notifications.map((notification) =>
              notificationIds.includes(notification.nid)
                ? { ...notification, unread: false }
                : notification,
            );
          }

          return notifications;
        },
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.notifications.getAllNotifications(
          true,
          stores?.auth?.currentUser?.uid,
        ),
      );
      queryClient.invalidateQueries(
        queryKeys.notifications.getAllNotifications(
          false,
          stores?.auth?.currentUser?.uid,
        ),
      );
    },
  });
};

export const useGetInterviewRequestNotificationByCalComBookingId = ({
  calComBookingId,
  onSuccess,
  enabled,
}: {
  calComBookingId: string;
  onSuccess?: (notification: NotificationObject | null) => void;
  enabled?: boolean;
}) => {
  const { auth } = useStores();

  return useQuery({
    queryKey:
      queryKeys.notifications.useQueryInterviewRequestNotificationByCalComBookingId(
        calComBookingId,
      ),
    queryFn: async () => {
      return await apiNotifications.getInterviewRequestNotificationByCalComBookingId(
        auth,
        calComBookingId,
      );
    },
    onSuccess,
    enabled,
    staleTime: 5 * 60 * 1000,
  });
};

export const useDeleteNotificationById = () => {
  const stores = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ notificationId }: { notificationId: string }) =>
      apiNotifications.deleteNotificationById(stores.auth, notificationId),
    onMutate: ({ notificationId }) => {
      // update unread notifications
      queryClient.setQueryData(
        queryKeys.notifications.getAllNotifications(
          true,
          stores?.auth?.currentUser?.uid,
        ),
        (notifications: UnreadNotificationObject[] | undefined) => {
          if (notifications) {
            return notifications.filter(
              (notification) => notification.nid !== notificationId,
            );
          }

          return notifications;
        },
      );

      // update all notifications
      queryClient.setQueryData(
        queryKeys.notifications.getAllNotifications(
          false,
          stores?.auth?.currentUser?.uid,
        ),
        (notifications: UnreadNotificationObject[] | undefined) => {
          if (notifications) {
            return notifications.filter(
              (notification) => notification.nid !== notificationId,
            );
          }

          return notifications;
        },
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.notifications.getAllNotifications(
          true,
          stores?.auth?.currentUser?.uid,
        ),
      );
      queryClient.invalidateQueries(
        queryKeys.notifications.getAllNotifications(
          false,
          stores?.auth?.currentUser?.uid,
        ),
      );
    },
  });
};

export const useRefreshNotifications = () => {
  const queryClient = useQueryClient();
  const { auth } = useStores();

  return () => {
    queryClient.invalidateQueries(
      queryKeys.notifications.getAllNotifications(true, auth?.currentUser?.uid),
    );
    queryClient.invalidateQueries(
      queryKeys.notifications.getAllNotifications(
        false,
        auth?.currentUser?.uid,
      ),
    );
  };
};
