import { BasicInvoiceObject } from '../InvoiceObject';
import { DateISOString } from '../misc';
import { BasicUserObject } from '../UserObject';
import { MinimalAccountObject } from '../Account';
import { MinimalMissionObject } from '../MissionObject';

export type DiscountId = string;

export enum DiscountType {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export enum DiscountStatus {
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export enum DiscountApplicableFor {
  ALL = 'ALL',
  FEES = 'FEES',
}

export interface BasicDiscountObject<
  ObjectName extends string = 'BasicDiscount',
> {
  did: DiscountId;
  object: ObjectName;
  account: MinimalAccountObject;
  missions: MinimalMissionObject[];
  status: DiscountStatus;
  totalCredit: number;
  usedCredit: number;
  summary: string;
}

export default interface DiscountObject
  extends BasicDiscountObject<'Discount'> {
  invoices: DiscountInvoice[];
  changes: DiscountChange[];
}

export interface DiscountInvoice {
  invoice: BasicInvoiceObject;
  appliedCredit: number;
}

export interface DiscountChange {
  id: string;
  action: string;
  appliedAt: DateISOString;
  user: BasicUserObject;
  notes?: string;
}
