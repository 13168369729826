import { useState } from 'react';

export enum AdminNotesTab {
  Notes = 'notes',
  Missions = 'missions',
  Applications = 'applications',
  Discovery = 'discovery',
  Vetting = 'vetting',
}

export const AdminNotesTabLabels: Record<AdminNotesTab, string> = {
  [AdminNotesTab.Notes]: 'Notes',
  [AdminNotesTab.Missions]: 'Missions',
  [AdminNotesTab.Applications]: 'Applications',
  [AdminNotesTab.Discovery]: 'Discovery',
  [AdminNotesTab.Vetting]: 'Evaluation',
};

export type AdminNotesTabsState = [
  tab: AdminNotesTab,
  setTab: (tab: AdminNotesTab) => void,
];

export function useAdminNotesTabs(tab?: AdminNotesTab): AdminNotesTabsState {
  return useState(tab || AdminNotesTab.Notes);
}
