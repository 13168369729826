import React from 'react';
import cx from 'classnames';
import { useCommonStyles } from '../../commonStyles';
import { HowToConnect } from '.';
import { Step } from './Step';
import SupportedCalendarsImg from '../svgs/supported.svg';
import AddCalendar from '../svgs/addCalendar.svg';
import Connect from '../svgs/connect.svg';

interface SupportedCalendarProps {
  onClose: () => void;
  onSupport: () => void;
}

export const HowToSupportedCalendar = (props: SupportedCalendarProps) => {
  const styles = useCommonStyles();

  const getTitle = () => (
    <div className={styles.hero}>
      <img
        src={SupportedCalendarsImg}
        className={styles.heroImg}
        alt="Connect supported Calendars"
      />
      <h4 className={styles.modalTitle}>
        Learn how to sync multiple supported calendars
      </h4>
      <p className={cx(styles.heroCopy, 'lessPadding')}>
        It’s easy to connect multiple supported calendars to ensure accurate
        availability is shown to companies.
      </p>
    </div>
  );

  return (
    <HowToConnect
      title={getTitle()}
      onClose={props.onClose}
      onSupport={props.onSupport}
    >
      <div className={styles.steps}>
        <Step
          title="Step 1"
          copy="Connect your first calendar by clicking 'Connect calendar' below"
          link="https://guide.a.team/missions/interview-scheduling#008c5012242c4c5e88f448f0a4026d11"
          linkTitle="How to Connect"
          img={<img src={Connect} alt="Connect calendar" />}
        />
        <Step
          title="Step 2"
          copy="To add additional calendars, go to your profile, select Settings -> Interviews, -> Manage calendar and 'Add'."
          link="https://guide.a.team/missions/interview-scheduling#008c5012242c4c5e88f448f0a4026d11"
          linkTitle="How to add another calendar"
          img={<img src={AddCalendar} alt="Add another calendar" />}
        />
      </div>
    </HowToConnect>
  );
};
