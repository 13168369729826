import React, { ComponentType, ReactElement } from 'react';
import { SurveyQuestionType } from '@a_team/models/dist/Survey';
import RatingWithFreeFormCommentQuestion from './RatingWithFreeFormCommentQuestion';
import { QuestionComponentProps } from './types';
import PersonRatingWithFreeFormCommentQuestion from './PersonRatingWithFreeFormCommentQuestion';

export const questionComponents = {
  [SurveyQuestionType.RecommendedRoles]: null,
  [SurveyQuestionType.RatingWithFreeFormComment]:
    RatingWithFreeFormCommentQuestion,
  [SurveyQuestionType.PersonRatingWithFreeFormComment]:
    PersonRatingWithFreeFormCommentQuestion,
  [SurveyQuestionType.Comment]: null,
  [SurveyQuestionType.YesOrNo]: null,
  [SurveyQuestionType.List]: null,
};

const Question = <T extends SurveyQuestionType>(
  props: QuestionComponentProps<T>,
): ReactElement | null => {
  const component = questionComponents[props.question.type] as ComponentType<
    QuestionComponentProps<T>
  >;
  if (!component) {
    throw new Error(`Unexpected question type: ${props.question.type}`);
  }
  return React.createElement(component, props);
};

export default Question;
