import {
  BasicUserObject,
  CollaboratorStatus,
  UserStatus,
  UserUsername,
} from './UserObject';
import { BasicTeamObject } from './TeamObject';
import { DateISOString } from './misc';
import { Company, CompanyId } from './Company';

export type ExperienceId = string;
export type ExperienceKey = string;
export type MemberRoleId = string;

export enum ExperienceMemberRole {
  Owner = 'owner',
  Member = 'member',
}

export enum ExperienceSource {
  Default = 'Default',
  LinkedIn = 'LinkedIn',
  AISuggestion = 'AISuggestion',
  Resume = 'Resume',
  WronglyCreatedWithAISuggestion = 'WronglyCreatedWithAISuggestion',
}

export interface ExperienceMemberData {
  username?: UserUsername;
  experienceRole: ExperienceMemberRole;
  experienceDimension?: ExperienceDimension;
  memberRole?: MemberRoleId;
  collaboratorStatus?: CollaboratorStatus;
}

export interface ExperienceUserMember
  extends BasicUserObject,
    Omit<ExperienceMemberData, 'tid'> {
  // type: UserType;
  username: UserUsername;
  memberTitle: string;
  deleted?: boolean;
  status: UserStatus;
  collaboratorStatus?: CollaboratorStatus;
}

export interface ExperienceTeamMember
  extends BasicTeamObject,
    Omit<ExperienceMemberData, 'username'> {
  type: 'team';
  memberTitle: string;
  status?: UserStatus;
}

export type ExperienceMember = ExperienceUserMember | ExperienceTeamMember;

export enum ExperienceType {
  Job = 'job',
  Project = 'project',
}

export const ExperienceTypeNames = {
  [ExperienceType.Job]: 'Job',
  [ExperienceType.Project]: 'Project',
};

export interface ExperienceDimension {
  rows: number;
  columns: number;
}

export interface ExperienceDuplicateData {
  members: ExperienceMemberData[];
}

export interface JobExperienceSkill {
  talentSkillId: string;
  talentSkillName?: string;
}

export interface JobExperienceSpecialization {
  talentSpecializationId: string;
  talentSpecializationName?: string;
}

export interface BasicExperienceData extends ExperienceDuplicateData {
  type: ExperienceType;
  description?: string;
  descriptionHTML?: string;
  imageURL?: string;
  imagesUrls?: string[];
  companyV2Id?: CompanyId | null;
  companyName?: string | null;
  websiteURL?: string | null;
  source?: ExperienceSource;
  skills?: string[];
  industries?: string[];
  hasZeroToOneExperience?: boolean;
  hasManagedPeople?: boolean;
  numberOfPeopleManaged?: string;
  logoURL?: string;
}

export interface JobExperienceData extends BasicExperienceData {
  type: ExperienceType.Job;
  name: string;
  eid?: ExperienceId;
  jobRole?: string;
  applicableTalentSkills?: JobExperienceSkill[];
  applicableTalentSpecialization?: JobExperienceSpecialization;
  startDate?: DateISOString;
  endDate?: DateISOString;
  companyData?: Company;
  relatedProjectIds?: ExperienceId[] | null;
  relatedProjects?: Omit<ProjectExperienceData, 'relatedJob'>[];
}

export interface MetricData {
  title: string;
  description: string;
}

export interface ProjectExperienceData extends BasicExperienceData {
  type: ExperienceType.Project;
  title: string;
  name?: string;
  eid?: ExperienceId;
  jobRole?: string;
  jobRoleId?: string;
  /** @deprecated */
  industry?: string;
  // description?: string;
  startDate?: DateISOString;
  endDate?: DateISOString;
  skills?: string[];
  companyData?: Company;
  projectUrl?: string;
  metrics?: MetricData[];
  relatedJobId?: ExperienceId | null;
  relatedJob?: Omit<JobExperienceData, 'relatedProjects'>;
}

export interface ProjectDescriptionBlurbData {
  description: string;
  title?: string;
  companyName?: string;
  /** @deprecated */
  industry?: string;
  industries?: string;
  role?: string;
  timeFrame?: string;
  skills?: string;
  metrics?: string;
  teamMembers?: string;
  hasZeroToOneExperience?: boolean;
  hasManagedPeople?: boolean;
  numberOfPeopleManaged?: string;
}

export enum ProjectDescriptionBlurbNodeType {
  Title = 'Title',
  Paragraph = 'Paragraph',
}

export interface ProjectDescriptionBlurbNode {
  type: ProjectDescriptionBlurbNodeType;
  content: string;
}

export type ExperienceData = BasicExperienceData &
  (JobExperienceData | ProjectExperienceData);

export type ExperienceDataInput = ExperienceData &
  Omit<Partial<JobExperienceData> & Partial<ProjectExperienceData>, 'type'>;

export interface MinimalExperienceObject {
  eid: ExperienceId;
  key: ExperienceKey;
  type: ExperienceType;
  title: string;
}

export type BasicExperienceObject = MinimalExperienceObject &
  Omit<ExperienceData, 'members' | 'title'> & {
    members: ExperienceMember[];
    isOwner?: boolean;
    canLeave?: boolean;
    isSuggested?: boolean;
    isMissingMinimumRequirements?: boolean;
  };

export type ExperienceObject = BasicExperienceObject & {
  createdAt: DateISOString;
  companyName?: string;
};

// Not supported by webpack
// export default ExperienceObject;
