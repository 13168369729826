import React, { FormEvent, ReactElement, useState } from 'react';
import Modal from '..';
import SectionHeading from '@src/components/SectionHeading';
import { Button as CallToActionButton } from '@ateams/components';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import UserObject from '@a_team/models/dist/UserObject';
import { ChangePasswordData } from '@ateams/api/dist/endpoints/User';
import useLoadingState from '@src/hooks/useLoadingState';
import { useStores } from '@src/stores';
import LoadingIndicator from '@src/components/LoadingIndicator';
import ClientVisibility from './ClientVisibility';
import BuilderVisibility from './BuilderVisibility';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';

interface Props {
  profile: UserObject;
  onClose(): void;
  open: boolean;
}

const useStyles = createUseStyles({
  discovery: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    marginBottom: 40,
  },
});

const AccountSettingsModal = (props: Props): ReactElement => {
  const { open, onClose, profile } = props;
  const { auth } = useStores();
  const styles = useStyles();

  const [data, setData] = useState<ChangePasswordData>({
    oldPassword: '',
    password: '',
  });
  const [loading, setLoading, error] = useLoadingState();

  const history = useHistory();

  const isValid = loading !== true && data.oldPassword && data.password;

  const handleInputChange =
    (name: keyof ChangePasswordData) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setData({ ...data, [name]: e.target.value });
    };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    if (!isValid) return;
    setLoading(
      auth.changePassword(data).then(() => {
        setData({ oldPassword: '', password: '' });
        onClose();
        history.push('/sign-in');
      }),
      'Password was updated!',
    );
  };
  return (
    <>
      <Modal
        onClose={onClose}
        open={open}
        style={{ maxHeight: 600, minWidth: '50%' }}
      >
        <div className={styles.discovery}>
          <ClientVisibility />
          <BuilderVisibility />
        </div>
        <SectionHeading isFirst>Username</SectionHeading>
        <p>Your username shows up in the url for your profile</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <OutlinedInput
            disabled
            value={`platform.a.team/${profile.username}`}
            style={{ width: '50%', flex: 1, marginRight: 22 }}
          />
          <p>
            <a href={'mailto:support@a.team'}>Contact</a> Support to change your
            username
          </p>
        </div>

        <SectionHeading isFirst>Password</SectionHeading>
        <p>
          Your password should contain 8 characters minimum, including upper
          case characters and numbers.
        </p>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ marginRight: '32px', width: '50%' }}>
            <div>Old Password</div>
            <OutlinedInput
              type="password"
              placeholder="Old Password..."
              name="oldPassword"
              onChange={handleInputChange('oldPassword')}
              value={data.oldPassword || ''}
              required
              error={!!error}
              style={{ marginTop: '16px' }}
            />
          </div>
          <div style={{ width: '50%' }}>
            <div>New Password</div>
            <OutlinedInput
              type="password"
              placeholder="New Password..."
              name="password"
              onChange={handleInputChange('password')}
              value={data.password || ''}
              disabled={loading === true}
              required
              error={!!error}
              style={{ marginTop: '16px' }}
            />
          </div>
        </div>

        <SectionHeading isFirst>Email Address</SectionHeading>
        <p>
          Your email won’t be shown to other users, it will be used for
          important notifications from the Build Team and Support Team.
        </p>
        <OutlinedInput disabled value={profile.email} />
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <CallToActionButton
            style={{ marginTop: '8px' }}
            disabled={!isValid}
            type="submit"
            onClick={handleSubmit}
          >
            Save
          </CallToActionButton>
        </div>
      </Modal>
      <LoadingIndicator loading={loading} />
    </>
  );
};

export default AccountSettingsModal;
