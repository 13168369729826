import { apiRoleOptimizationRequests } from '@ateams/api';
import { useStores } from '@src/stores';
import { useMutation } from '@tanstack/react-query';
import { CreateRoleOptimizationRequestData } from '@a_team/models/dist/RoleOptimizationRequest';

export const useMutationCreateRoleOptimizationRequest = () => {
  const { auth, missions } = useStores();

  return useMutation(async (args: CreateRoleOptimizationRequestData) => {
    await apiRoleOptimizationRequests.createRoleOptimizationRequest(auth, args);
    missions.currentMission?.mid &&
      missions.reloadCurrentMission(missions.currentMission?.mid, true);
  });
};
