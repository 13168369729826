import {
  NotificationBannerV2Props,
  NotificationBannerV2Theme,
} from '@src/components/NotificationBannerV2';
import { MissionControlAllMissionsLocation } from '@src/locations';
import { useStores } from '@src/stores';
import { ApplicationStep } from '@src/stores/Application';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getCtaLabel, getCtaLink, getDescription, getTitle } from './utils';

export const useMissionNotification = (
  isNewApplication: boolean,
  totalOpenRoles: number,
): void => {
  const history = useHistory();
  const {
    auth,
    users: { profile },
    missions: { currentMission },
  } = useStores();

  const profileData = profile?.data;
  const profileApplication = profile?.application;
  const currentMissionData = currentMission?.data;

  useEffect(() => {
    if (!auth.withTeamUp || !profileApplication?.submitted) {
      return;
    }

    const isEditingApplication =
      profileApplication?.step === ApplicationStep.Application;
    const hasMultipleOpenRoles = totalOpenRoles > 1;

    if (isNewApplication && isEditingApplication && hasMultipleOpenRoles) {
      return;
    }

    let notificationState: NotificationBannerV2Props | undefined;

    if (
      profileData?.profilePictureURL &&
      currentMissionData?.companyName &&
      currentMissionData?.mid &&
      profileApplication?.rid &&
      profileApplication?.aid
    ) {
      notificationState = {
        profilePictureURLs: [profileData.profilePictureURL],
        title: getTitle(
          profileApplication.shouldShowRequirementsMissingWarning,
          currentMissionData?.companyName,
          isNewApplication,
          profileApplication?.requestedTeammates?.length || 0,
        ),
        description: getDescription(
          profileApplication.shouldShowRequirementsMissingWarning,
          isNewApplication,
        ),
        ctaLabel: getCtaLabel(
          profileApplication.shouldShowRequirementsMissingWarning,
        ),
        ctaLink: getCtaLink(
          profileApplication.shouldShowRequirementsMissingWarning,
          currentMissionData.mid,
          profileApplication.rid,
          profileApplication.aid,
        ),
        highlightUser: true,
        showCloseButton: true,
        level: profileApplication.shouldShowRequirementsMissingWarning
          ? NotificationBannerV2Theme.Warning
          : NotificationBannerV2Theme.Success,
      };
    }

    if (auth.onboardingCompleted) {
      history.push({
        pathname: MissionControlAllMissionsLocation,
        state: notificationState,
      });
    }
  }, [
    auth.withTeamUp,
    profile?.application?.submitted,
    profile?.application?.step,
    profile?.application?.requestedTeammates?.length,
    profile?.application?.shouldShowRequirementsMissingWarning,
    profile?.application?.rid,
    profile?.application?.aid,
    currentMission?.data?.mid,
    currentMission?.data?.companyName,
    isNewApplication,
    totalOpenRoles,
    history,
  ]);
};
