import React, { ReactElement, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import { toJS } from 'mobx';
import CardList from '../CardList';
import NotInterestedMissionCard from '../MissionControlCard/NotInterestedMissionCard';
import { LoadingCards } from '@src/views/MissionControl/LoadingCards';
import {
  useGetNotInterestedMissionsQuery,
  useGetRecommendedMissionsQuery,
} from '@src/rq/missions';
import NoResults from '@src/components/NoResults';

const NotInterestedMissions = (): ReactElement => {
  const {
    missionControl: {
      getNotInterested: missions,
      getRecommendedMissionIndices: recommendedIndices,
    },
  } = useStores();
  const { isLoading: isLoadingRecommendedMissions } =
    useGetRecommendedMissionsQuery();
  const { isLoading } = useGetNotInterestedMissionsQuery();

  const loading = useMemo(
    () => (isLoading || isLoadingRecommendedMissions) && !missions.length,
    [isLoading, isLoadingRecommendedMissions, missions.length],
  );

  if (!loading && missions.length === 0) {
    return (
      <NoResults
        imageType="noSearchResults"
        title="Nothing Found"
        text={
          <>
            <p>There are no missions that you have marked as not interested.</p>
          </>
        }
      />
    );
  }

  return (
    <>
      <CardList>
        {loading ? (
          <LoadingCards type={'notInterested'} />
        ) : (
          missions.map((mission) => (
            <NotInterestedMissionCard
              key={mission.mid}
              mission={toJS(mission)}
              recommended={recommendedIndices.get(mission.mid) !== undefined}
            />
          ))
        )}
      </CardList>
    </>
  );
};

export default observer(NotInterestedMissions);
