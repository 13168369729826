import { ClientBillingInfo } from '../BillingInfo';

export * from './PaymentTerms';

export type BillingAccountId = string;

export default interface BillingAccountObject {
  id: BillingAccountId;
  billingInfo?: ClientBillingInfo;
}
