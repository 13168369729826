import React from 'react';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import { Colors, floatAnimation } from '@ateams/components';
import { Footer } from '@src/views/Registration/Footer';
import { ATeamIcon } from '@src/views/VettingPostEvaluationSurvey/form/ATeamIcon';

export interface PreVettingLayoutProps {
  title?: string;
}

const useStyles = createUseStyles({
  root: {
    alignItems: 'center',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    padding: '40px 40px 16px 40px',
  },
  content: {
    minHeight: '100vh',
    paddingRight: 20,
    overflow: 'auto',
    flexShrink: 0,
    flexGrow: 1,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    background: Colors.backgroundWhite,
  },
  contentInner: {
    alignItems: 'center',
    height: '100%',
    display: 'flex',
    flex: 1,
  },
  ...floatAnimation,
  footerContainer: {
    alignSelf: 'center',
  },
  [`@media (max-width: 500px)`]: {
    topBar: {
      padding: '24px',
    },
  },
  steps: {
    fontSize: 12,
    fontWeight: 500,
    height: 24,
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: 12,
    padding: '2.5px 12px',
    backgroundColor: '#F7F7F7',
  },
});

export const PreVettingLayout: React.FC<PreVettingLayoutProps> = observer(
  (props) => {
    const { title, children } = props;
    const styles = useStyles();
    const { document } = useStores();

    document.setInnerTitle(title || 'PreVetting');

    return (
      <>
        <div className={styles.root}>
          <div className={styles.content}>
            <div className={styles.topBar}>
              <ATeamIcon />
              <div className={styles.steps}>Step 1 of 2</div>
            </div>
            <div className={styles.contentInner}>{children}</div>

            <div className={styles.footerContainer}>
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  },
);
