import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Icon, IconType } from '@ateams/components';
import { TooltipProps } from 'react-tippy';
import TooltipWrapped from '@src/components/TooltipWrapped';

const MAX_DISPLAYED_MATCHES = 5;

interface Props extends TooltipProps {
  criteria: string[];
  numberOnly?: boolean;
}

const useStyles = createUseStyles({
  bold: {
    fontWeight: 500,
  },
  tooltip: {
    fontSize: '12px',
    textAlign: 'left',
    paddingLeft: '8px',
    lineHeight: '16px',
    margin: '3px 6px',
  },
});

const MatchList = (props: Props): ReactElement => {
  const { criteria, numberOnly } = props;
  const styles = useStyles(props);

  const hiddenMatches: number = criteria.length - MAX_DISPLAYED_MATCHES + 1;
  const abridgedCriteria =
    hiddenMatches < 2
      ? criteria
      : [...criteria.slice(0, 4), `+${hiddenMatches} Skills`];

  return (
    <>
      <Icon
        type={IconType.ATeamGray}
        size={'exact'}
        style={{ marginRight: '0.5em' }}
      />
      <span className={styles.bold}>Matched on: </span>
      {numberOnly ? (
        <TooltipWrapped
          theme={'dark'}
          position={'top'}
          arrow
          html={
            <ul className={styles.tooltip}>
              {abridgedCriteria.map((criterion, ind) => (
                <li key={`match-${ind}`}>{criterion}</li>
              ))}
            </ul>
          }
        >
          {`${abridgedCriteria.length} Profile settings`}
        </TooltipWrapped>
      ) : (
        abridgedCriteria.join(', ')
      )}
    </>
  );
};

export default MatchList;
