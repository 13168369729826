import { Button as CallToActionButton, Colors } from '@ateams/components';
import {
  AvailabilityData,
  AvailableType,
} from '@a_team/models/dist/AvailabilityObject';
import { DateISOString } from '@a_team/models/dist/misc';
import { DateInput } from '@src/components/DateInput';
import SectionHeading from '@src/components/SectionHeading';
import { enumKeys } from '@src/helpers/types';
import { AvailabilityTypeLabels } from '@src/stores/NotificationPreferencesUser';
import { MAX_AVAILABILITY_HOURS } from '@src/views/Profile/Main/Availability';
import { HoursInput } from '@src/views/Profile/Main/Availability/HoursInput';
import { StatusSelect } from '@src/views/Profile/Main/Availability/StatusSelect';
import { add } from 'date-fns';
import React, { ReactElement, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Modal, { ModalBasicProps } from '..';

interface SetAvailabilityModalProps extends ModalBasicProps {
  onSubmit: (availability: AvailabilityData) => void;
}

const defaultProps = {
  singeSelection: false,
};

const useStyles = createUseStyles({
  title: {
    fontSize: 28,
  },
  description: {
    fontSize: 14,
    marginBottom: 24,
  },
  label: {
    color: Colors.regularDark,
    marginBottom: '12px',
  },
  inputsWrapper: {
    fontSize: '17px',
    display: 'flex',
    gap: '10px',
  },
  statusSelect: {
    width: 'auto',
    '& > div': {
      padding: 0,
      minHeight: 'auto',
      border: 'none',
      '& select': {
        width: 'auto',
        fontSize: '17px',
        padding: '1px 2px',
        borderBottom: `1px dashed ${Colors.regular}`,
        paddingRight: '15px',
      },
    },
  },
  datePicker: {
    fontSize: '17px',
    border: 'none',
    borderBottom: `1px dashed ${Colors.regular}`,
    maxWidth: '70px',
    paddingBottom: '3px',
  },
  hoursInput: {
    display: 'inline-block',
    width: '30px',
    color: '#222222',
    paddingBottom: '3px',
  },
  buttonBox: {
    marginTop: 60,
    display: 'flex',
    gap: '16px',
    '& > button': {
      width: '100%',
      height: 'auto',
    },
  },
});

const SetAvailabilityModal = ({
  onClose,
  onSubmit,
  open,
}: SetAvailabilityModalProps): ReactElement => {
  const styles = useStyles();
  const [availabilityType, setAvailabilityType] = useState<AvailableType>(
    AvailableType.UnknownAvailability,
  );
  const [fromDate, setFromDate] = useState<DateISOString>(new Date());
  const [hours, setHours] = useState<number | undefined>(undefined);

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const label = e.target.value;
    const status = enumKeys(AvailabilityTypeLabels).find(
      (key) => AvailabilityTypeLabels[key] === label,
    );
    setAvailabilityType(status as AvailableType);
  };

  const handleDateChange = (date: Date) => {
    setFromDate((date as Date).toISOString());
  };

  const isValidHours = (value: string) => {
    return !isNaN(Number(value)) && Number(value) <= MAX_AVAILABILITY_HOURS;
  };

  const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!isValidHours(value)) return;
    setHours(Number(value));
  };

  const showDateInput = useMemo(() => {
    return availabilityType === AvailableType.FutureDate;
  }, [availabilityType]);

  const isValid = useMemo(() => {
    if (!hours) {
      return false;
    }
    if (
      availabilityType !== AvailableType.Now &&
      availabilityType !== AvailableType.FutureDate
    ) {
      return false;
    }

    if (availabilityType === AvailableType.FutureDate && !fromDate) {
      return false;
    }

    return true;
  }, [availabilityType, fromDate, hours]);

  const handleOnSubmit = () => {
    let availability: AvailabilityData;
    if (availabilityType === AvailableType.Now && hours) {
      availability = {
        type: AvailableType.Now,
        weeklyHoursAvailable: hours,
      };

      onSubmit(availability);
    } else if (
      availabilityType === AvailableType.FutureDate &&
      fromDate &&
      hours
    ) {
      availability = {
        type: AvailableType.FutureDate,
        date: fromDate as string,
        weeklyHoursAvailable: hours,
      };

      onSubmit(availability);
    }
  };

  return (
    <Modal
      onClose={onClose}
      hideCloseButton={true}
      open={open}
      style={{
        maxWidth: '600px',
        width: '100%',
        padding: '24px',
        overflow: 'visible',
      }}
    >
      <div>
        <SectionHeading className={styles.title} isFirst>
          Please edit your availability
        </SectionHeading>

        <div className={styles.description}>
          You don’t have enough availability for this role. Please update your
          availability to continue.
        </div>

        <div className={styles.label}>My availability</div>
        <div className={styles.inputsWrapper}>
          <StatusSelect
            value={
              availabilityType === AvailableType.UnknownAvailability
                ? ''
                : availabilityType
                ? AvailabilityTypeLabels[availabilityType]
                : ''
            }
            onChange={handleStatusChange}
            className={styles.statusSelect}
          />

          {showDateInput && (
            <div>
              <DateInput
                className={styles.datePicker}
                minDate={add(new Date(), { days: 1 })}
                selected={new Date(fromDate)}
                onChange={handleDateChange}
              />
            </div>
          )}

          <div>
            for{' '}
            <HoursInput
              value={hours}
              onChange={handleHoursChange}
              tooltip={
                "Please enter the number of hours you'll be available to work on Missions"
              }
              className={styles.hoursInput}
            />{' '}
            hours per week
          </div>
        </div>

        <div className={styles.buttonBox}>
          <CallToActionButton
            width="auto"
            onClick={onClose}
            size="small"
            color={'regularLight'}
          >
            Cancel
          </CallToActionButton>
          <CallToActionButton
            width="auto"
            onClick={handleOnSubmit}
            disabled={!isValid}
            size="small"
          >
            Save
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
};

SetAvailabilityModal.defaultProps = defaultProps;

export default SetAvailabilityModal;
