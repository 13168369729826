import React, { ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Modal, { ModalBasicProps } from '..';
import SectionHeading from '@src/components/SectionHeading';
import { Button as CallToActionButton, Checkbox } from '@ateams/components';
import Mission from '@src/stores/Missions/Mission';
import DropdownInput from '@src/components/Inputs/DropdownInput';
import {
  MissionPaymentCycleId,
  MissionPaymentCycleStatus,
} from '@a_team/models/dist/MissionPaymentCycleObject';
import { numberWithCommas } from '@src/helpers/numbers';
import InvoiceRow from '@src/components/Modal/ClosePaymentCycleModal/InvoiceRow';
import {
  AdminMissionPaymentCycleSummarize,
  PaymentCycleInvoiceRequest,
} from '@ateams/api/dist/endpoints/Missions';
import UserAvatar from '@src/components/UserAvatar';
import { stringifyMinutes } from '@src/helpers/time';
import { createUseStyles } from 'react-jss';
import logo from './logo.svg';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useDebounceState from '@src/hooks/useDebounceState';
import LabeledCheckboxInput from '@src/components/Inputs/LabeledCheckboxInput';
import { AdminInvoicesDiscountsViewLocation } from '@src/locations';

interface Props extends ModalBasicProps {
  mission: Mission;
  defaultSelectedCycleId?: MissionPaymentCycleId;
  generateInvoice?: boolean;
  onSubmit: (
    yid: MissionPaymentCycleId,
    request: PaymentCycleInvoiceRequest,
  ) => void;
}

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    borderBottom: '1px solid #C0C0C0',
    paddingBottom: 16,
    marginBottom: 18,
  },
  label: {
    width: 108,
    marginRight: 12,
    display: 'inline-block',
  },
  inlineInput: {
    border: 0,
    borderBottom: '1px dashed #ccc',
  },
});

const ClosePaymentCycleModal = (props: Props): ReactElement => {
  const styles = useStyles();
  const {
    mission,
    defaultSelectedCycleId,
    onSubmit,
    onClose,
    generateInvoice,
    ...left
  } = props;
  const { selectedPaymentCycle, paymentCycles, getInvoiceSummary } = mission;
  const [cycleToInvoice, setCycleToInvoice] = useState<MissionPaymentCycleId>();
  const [createInvoiceFlag, setCreateInvoiceFlag] = useState(generateInvoice);
  const [invoiceSummary, setInvoiceSummary] = useState<
    AdminMissionPaymentCycleSummarize | undefined
  >();
  const [loading, setLoading] = useLoadingState();
  const [requestData, setRequestData, isDraft] = useDebounceState(
    {
      amount: '0',
      reason: '',
      claimedCredit: null as string | null,
      valid: true,
      basedOnInvoiceId: '',
    },
    (data) => {
      const amount = Number(data.amount);
      const claimedCredit =
        data.claimedCredit != null ? Number(data.claimedCredit) : undefined;

      if (isNaN(amount) || (claimedCredit != null && isNaN(claimedCredit))) {
        return;
      }

      updateSummary({
        clientDiscount: amount
          ? {
              id: invoiceSummary?.clientDiscount?.id,
              amount,
              reason: data.reason || undefined,
              claimedCredit: claimedCredit || undefined,
            }
          : undefined,
        basedOnInvoiceId: data.basedOnInvoiceId || undefined,
      });
    },
    1000,
  );

  const isValid =
    (!isDraft &&
      requestData.valid &&
      loading !== true &&
      invoiceSummary?.closeable) ||
    false;

  const updateSummary = (request: PaymentCycleInvoiceRequest): void => {
    if (!cycleToInvoice) return;

    setLoading(
      getInvoiceSummary(cycleToInvoice, request).then((summary) => {
        setInvoiceSummary(summary);
        setRequestData(
          {
            ...requestData,
            amount: (summary.clientDiscount?.amount || 0).toString(),
            reason: summary.clientDiscount?.reason || '',
            claimedCredit:
              summary.clientDiscount?.claimedCredit?.toString() || null,
            valid: true,
            basedOnInvoiceId: summary.basedOnInvoiceId || '',
          },
          true,
        );
      }),
    );
  };

  useEffect(() => {
    defaultSelectedCycleId && setCycleToInvoice(defaultSelectedCycleId);
  }, [defaultSelectedCycleId]);

  useEffect(() => {
    setCreateInvoiceFlag(generateInvoice);
  }, [generateInvoice, left.open]);

  useEffect(() => {
    setInvoiceSummary(undefined);
    setRequestData(
      {
        amount: '0',
        reason: '',
        claimedCredit: null,
        valid: true,
        basedOnInvoiceId: '',
      },
      true,
    );
    updateSummary({
      loadCanceled: true,
    });
  }, [cycleToInvoice]);

  return (
    <Modal onClose={onClose} {...left}>
      <SectionHeading style={{ marginTop: 0 }}>
        Close a Payment Cycle
      </SectionHeading>
      <DropdownInput
        style={{ marginBottom: 35 }}
        placeholder="Select a payment cycle"
        value={cycleToInvoice}
        defaultValue={selectedPaymentCycle?.yid}
        onChange={(e): void => setCycleToInvoice(e.target.value)}
        required
      >
        {paymentCycles.map((cycle) => {
          if (
            !cycle.closeNotificationShown ||
            cycle.status === MissionPaymentCycleStatus.Closed
          ) {
            return null;
          }
          return (
            <option key={cycle.yid} value={cycle.yid}>
              {cycle.formattedStartDate} - {cycle.formattedEndDate}
            </option>
          );
        })}
      </DropdownInput>
      {invoiceSummary?.roles.map((role) => {
        const missionRole = mission.data.roles.find(
          (missionRole) => missionRole.rid === role.rid,
        );
        return (
          cycleToInvoice && (
            <InvoiceRow roleInvoiceSummary={role} user={missionRole?.user} />
          )
        );
      })}
      {invoiceSummary && (
        <>
          {/*<div className={styles.row}>*/}
          {/*  <UserAvatar src={logo} style={{ marginRight: 24 }} />*/}
          {/*  <div style={{ display: 'flex', flexDirection: 'column' }}>*/}
          {/*    <span style={{ fontSize: 15, fontWeight: 500, marginBottom: 2 }}>*/}
          {/*      Freelancers Fee*/}
          {/*    </span>*/}
          {/*    <span>*/}
          {/*      <span className={styles.label}>Total payment:</span>*/}
          {/*      {`$${numberWithCommas(invoiceSummary.rolesFee)}`}*/}
          {/*    </span>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={styles.row}>
            <UserAvatar src={logo} containerStyle={{ marginRight: 24 }} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontSize: 15, fontWeight: 500, marginBottom: 2 }}>
                {invoiceSummary.billingAccount?.billingInfo?.name ||
                  'Client Fee'}
              </span>
              <span>
                <span className={styles.label}>Platform Fees:</span>
                {`$${numberWithCommas(invoiceSummary.clientFee)}`}
              </span>
              <span style={{ marginTop: 4 }}>
                <span className={styles.label}>Discount:</span>
                <input
                  name="discount-amount"
                  type="number"
                  className={styles.inlineInput}
                  value={requestData.amount}
                  onChange={(e) =>
                    setRequestData({
                      ...requestData,
                      amount: e.target.value || '',
                      valid: false,
                    })
                  }
                />
              </span>
              {invoiceSummary.clientDiscount && (
                <>
                  <span style={{ marginTop: 4 }}>
                    <span className={styles.label}>Reason:</span>
                    <input
                      name="discount-reason"
                      className={styles.inlineInput}
                      value={requestData.reason}
                      onChange={(e) =>
                        setRequestData({
                          ...requestData,
                          reason: e.target.value || '',
                          valid: false,
                        })
                      }
                    />
                  </span>

                  {!!invoiceSummary.clientDiscount.id && (
                    <span style={{ marginTop: 4 }}>
                      <Checkbox
                        margin="top"
                        checked={requestData.claimedCredit !== null}
                        onChange={(e) =>
                          setRequestData({
                            ...requestData,
                            claimedCredit: e.target.checked
                              ? requestData.claimedCredit ?? requestData.amount
                              : null,
                            valid: false,
                          })
                        }
                        label={
                          <>
                            Reduce ${' '}
                            <input
                              className={styles.inlineInput}
                              value={
                                requestData.claimedCredit ?? requestData.amount
                              }
                              disabled={requestData.claimedCredit == null}
                              onChange={(e) =>
                                setRequestData({
                                  ...requestData,
                                  claimedCredit: e.target.value || '',
                                  valid: false,
                                })
                              }
                              style={{ width: 60 }}
                            />{' '}
                            from the{' '}
                            <a
                              href={AdminInvoicesDiscountsViewLocation(
                                invoiceSummary.clientDiscount.id,
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              client credit
                            </a>
                          </>
                        }
                      />
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
      {invoiceSummary && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>Total before VAT:</span>
            <span>{`$${numberWithCommas(
              invoiceSummary.totalPayments - invoiceSummary.totalVAT,
            )}`}</span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 8,
            }}
          >
            <span>Total VAT:</span>
            <span>{`$${numberWithCommas(invoiceSummary.totalVAT)}`}</span>
          </div>

          {invoiceSummary.paymentTerms && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 8,
              }}
            >
              <span>Payment terms:</span>
              <span>{invoiceSummary.paymentTerms}</span>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontWeight: 600,
              fontSize: 20,
              margin: '32px 0',
            }}
          >
            <span>{stringifyMinutes(invoiceSummary.totalMinutes)}</span>
            <span>${numberWithCommas(invoiceSummary.totalPayments)}</span>
          </div>
        </>
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CallToActionButton
          onClick={() => {
            if (!isValid || !cycleToInvoice || !invoiceSummary) return;

            onSubmit(cycleToInvoice, {
              clientDiscount: invoiceSummary.clientDiscount,
              basedOnInvoiceId: invoiceSummary.basedOnInvoiceId,
              generateInvoice: createInvoiceFlag,
            });
            onClose();
          }}
          disabled={!isValid}
          style={{ margin: '0 8px' }}
        >
          Create
        </CallToActionButton>
      </div>
      {generateInvoice && (
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}
        >
          <LabeledCheckboxInput
            margin="none"
            label="generate invoice"
            checked={createInvoiceFlag}
            onChange={(e) => setCreateInvoiceFlag(e.target.checked)}
          />
        </div>
      )}
      <LoadingIndicator loading={loading || null} />
    </Modal>
  );
};

export default observer(ClosePaymentCycleModal);
