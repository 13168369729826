import { AdminBasicUserObject } from '@a_team/models/dist/UserObject';

export const isUserFullyVetted = (user: AdminBasicUserObject): boolean => {
  return [
    user.expertiseScore,
    user.interactionExperienceScore,
    user.englishScore,
    user.accentScore,
  ].every(Number.isInteger);
};
