import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface ErrorMessageProps {
  message: string;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    color: '#F63131',
    fontSize: '12px',
    marginTop: 12,
  },
});

const ErrorMessage = ({
  className,
  message,
}: ErrorMessageProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={cx(styles.container, className)}>
      <span>{message}</span>
    </div>
  );
};

export default ErrorMessage;
