import { ExistingProject } from '@src/stores/Profile/models';
import React from 'react';
import { FieldErrors } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { ProjectWithSelectedSkills } from '../..';

interface ErrorFormProps {
  field:
    | keyof Omit<
        ExistingProject,
        'key' | '_id' | 'eid' | 'name' | 'isOwner' | 'canLeave' | 'relatedJob'
      >
    | 'selectedSkills';
  errors: FieldErrors<ProjectWithSelectedSkills>;
  centered?: boolean;
}

const useStyles = createUseStyles({
  container: {
    marginTop: 4,
    color: '#F63041',
    fontSize: 12,
    textAlign: ({ centered }: { centered?: boolean }) =>
      centered ? 'center' : 'left',
  },
});

const ErrorForm = ({
  field,
  errors,
  centered,
}: ErrorFormProps): JSX.Element | null => {
  const styles = useStyles({ centered });

  const message = errors[field]?.message;

  if (!message) {
    return null;
  }

  return <div className={styles.container}>{message}</div>;
};

export default ErrorForm;
