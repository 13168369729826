import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import SectionHeading from '@src/components/SectionHeading';

const useStyles = createUseStyles({
  root: {
    lineHeight: '24px',
  },
  light: {
    color: '#62646A',
  },
});

export default function ClientTermsOfService(): ReactElement {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <SectionHeading isFirst>
        ATeams Client Terms of Service Agreement
      </SectionHeading>
      <div style={{ marginTop: '32px' }}>
        This Terms of Service Agreement (“<b>Agreement</b>”) is for Clients
        (defined below) and governs Your use of ATeams’ “<b>Platform</b>” which
        connects networks of independent, professional developers to companies
        (like Yours) with technology project needs. Accepting the terms and
        conditions of this Agreement will allow You to access a collaborative
        community of independent professionals who are helping to create the
        future. To engage an A.Teamer (defined below) to provide A.Teamer
        Services (defined below), You must agree to the terms of the Client
        Engagement Agreement (the “<b>CE Agreement</b>”).
      </div>
      <div style={{ marginTop: '16px' }}>
        TO ACCESS AND USE ATEAMS’ PLATFORM AS A CLIENT, YOU MUST REVIEW AND
        ACCEPT THE TERMS OF THIS AGREEMENT BY CLICKING ON THE “<b>I ACCEPT</b>”
        BUTTON AT THE END OF THE TEXT. ONCE ACCEPTED, THIS AGREEMENT BECOMES A
        BINDING LEGAL COMMITMENT BETWEEN YOU AND ATEAMS, INC. (“<b>ATEAMS</b>”).
        YOU REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND
        BY THE TERMS OF THIS AGREEMENT, (2) YOU ARE OF LEGAL AGE TO FORM A
        BINDING CONTRACT WITH ATEAMS, AND (3) YOU HAVE THE AUTHORITY TO ENTER
        INTO THE AGREEMENT ON BEHALF OF THE CLIENT YOU HAVE NAMED AS THE USER
        (AND, IF YOU ARE ENTERING INTO THIS AGREEMENT AS A REPRESENTATIVE, AGENT
        OR EMPLOYEE OF THE CLIENT NAMED AS USER, YOU HAVE THE RIGHT TO BIND THAT
        COMPANY TO THE TERMS OF THIS AGREEMENT). THE TERM “<b>YOU</b>” REFERS TO
        THE INDIVIDUAL OR LEGAL ENTITY, AS APPLICABLE, IDENTIFIED AS THE USER
        WHEN YOU REGISTER. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, YOU
        SHOULD NOT CLICK THE “<b>I ACCEPT</b>” BUTTON.
      </div>

      <SectionHeading>1. DEFINITIONS</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        The following terms shall have the meanings set forth below. Capitalized
        terms not defined in this Section shall have the meanings assigned to
        them where used in this Agreement. “<b>A.Teamer</b>” means an
        individual, who provides A.Teamer Services to Clients, including as part
        of teams.
      </div>
      <div style={{ marginTop: '8px' }}>
        <br />“<b>A.Teamer</b>” means an individual, who provides A.Teamer
        Services to Clients, including as part of teams.
        <br />“<b>A.Teamer Contract</b>” means a contract between an A.Teamer
        (or team of A.Teamers) and a Client setting forth the terms for the
        provision of A.Teamer Services in connection with an Assignment.
        <br />“<b>A.Teamer Fees</b>” means the compensation owed by a Client to
        A.Teamer for A.Teamer Services on a time and materials or fixed fee
        basis.
        <br />“<b>A.Teamer Services</b>” means services A.Teamers provide to
        Clients.
        <br />“<b>Assignment</b>” means an assignment advertised through, or
        otherwise submitted to, the Services for which a Client seeks A.Teamer
        Services.
        <br />“<b>Clients</b>” means individuals or entities who use the
        Matching Services to seek and purchase A.Teamer Services for
        Assignments.
        <br />“<b>Confidential Information</b>” shall mean all written or oral
        information, disclosed by or on behalf of either ATeams or You to the
        other that has been identified as confidential or that by the nature of
        the information or circumstances surrounding disclosure would be
        reasonably understood to be confidential or proprietary.
        <br />“<b>Matching Services</b>” means the services provided by ATeams
        whereby an A.Teamer and Client are matched for the potential provision
        of A.Teamer Services to Client for one or more Assignments.
        <br />“<b>Services</b>” means the service provided by ATeams whereby
        Clients can list, advertise, or otherwise submit Assignments, find
        matches for Assignments, and coordinate payments for Assignments, and
        A.Teamers can post their credentials and submit proposals for
        Assignments.
        <br />“<b>Users</b>” means users of the Services and includes both
        Clients (including You) and A.Teamers.
      </div>

      <SectionHeading>2. Services</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        The Services act as a marketplace whereby Clients and A.Teamers can
        connect and identify each other for the sale and purchase of A.Teamer
        Services for Assignments. Subject to the terms and conditions set forth
        in this Agreement, ATeams will use commercially reasonable efforts to
        provide You with access to the Services. You can submit to ATeams
        descriptions of the Assignments for which You are seeking A.Teamers, via
        email at [email address] or through the proper functionality on the
        ATeams Platform (each, a “<b>Submission</b>”). ATeams reserves the right
        to refuse any and all Submissions at any time. Submissions must be
        expressly accepted by ATeams. ATeams does not guarantee that any matches
        will be made through the Services. You must provide all equipment and
        software necessary to connect to the Services.
      </div>

      <SectionHeading>3. Relationship with ATeams</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        ATeams merely makes the Services available to enable A.Teamers and
        Clients to find and transact directly with each other. Through the
        Services, You may be notified of the services A.Teamers may provide, and
        A.Teamers may be notified of Your Assignments.
      </div>

      <SectionHeading>4. Other Business Activities</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        Nothing in this Agreement is intended to prohibit or discourage You from
        engaging in other business activities or seeking any services through
        any other channels, provided You comply with the obligations described
        in Section 12 if applicable.
      </div>

      <SectionHeading>5. Accounts</SectionHeading>
      <div style={{ marginTop: '16px' }}>
        <b>5.1 Registration.</b> Use of and access to the Services may require
        registration of an account for the Services (“<b>Account</b>”). In
        registering an Account, You agree to (a) provide true, accurate, current
        and complete information (“<b>Registration Data</b>”) and (b) maintain
        and promptly update the Registration Data to keep it true, accurate,
        current and complete. You agree not to provide any false or misleading
        information about Your identity or location, business, or available
        Assignments and to correct any such information that is or becomes false
        or misleading. You acknowledge and agree that Registration Data may be
        shared with other Users in connection with the Services, and You hereby
        grants ATeams a non-exclusive, worldwide, royalty free license to use,
        display, perform, transmit, and otherwise exploit Your Registration Data
        in connection with the Services. You are responsible for all activities
        that occur under Your Account and may not share Account or password
        information with anyone. You agree to notify ATeams immediately of any
        unauthorized use of Your password or any other breach of security. If
        You provide any information that is untrue, inaccurate, not current or
        incomplete, or ATeams has reasonable grounds to suspect that any
        information You provide is untrue, inaccurate, not current or
        incomplete, ATeams has the right to suspend or terminate Your Account
        and refuse any and all current or future use of the Services (or any
        portion thereof). You may not have more than one Account at any given
        time. You may not create an Account or use the Service if You have been
        previously removed by ATeams or previously banned from the Services.
        ATeams reserves the right to decline a registration to join ATeams or to
        add an Account type, for any lawful reason, including supply and demand,
        cost to maintain data, or other business considerations.
      </div>
      <div style={{ marginTop: '16px' }}>
        <b>5.2 Account Verification.</b> When You register for an Account and
        from time to time thereafter, Your Account will be subject to
        verification, including, but not limited to, validation against
        third-party databases or verification of one or more official government
        or legal documents that confirm Your identity, location, and ability to
        act on behalf of Your business. You authorize ATeams, directly or
        through third parties, to make any inquiries necessary to validate Your
        identity, location, and ownership of Your email address or financial
        accounts, subject to applicable law. When requested, You must timely
        provide ATeams with complete information about You and Your business,
        which includes, but is not limited to, providing official government or
        legal documents.
      </div>
      <div style={{ marginTop: '16px' }}>
        <b>5.3 Permitted Users.</b> By granting any individuals or entities
        permissions under Your Account (a “<b>Permitted User</b>”), You
        represent and warrant that (a) the Permitted User is authorized to act
        on Your behalf, and (b) You are fully responsible and liable for any
        action of any Permitted User and any other person who uses the Account.
        If any such Permitted User violates the terms of this Agreement, it may
        affect Your ability to use the Services.
      </div>
      <div style={{ marginTop: '16px' }}>
        <b>5.4 A.TEAMER CONTRACTS; FEES. You and Your A.</b>Teamer(s) must enter
        into an A.Teamer Contract for each Assignment or arrangement for
        A.Teamer Services. When You enter into an A.Teamer Contract, You agree
        to use the Services to pay any A.Teamer Fees You owe under the A.Teamer
        Contract. All A.Teamer Fees and other payments for Assignments must be
        made through the Services in accordance with the terms of the CE
        Agreement.
      </div>
      <SectionHeading>6. Ownership</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        <b>6.1 ATeams.</b> ATeams and its suppliers own all rights, title and
        interest in the Services; all information and materials provided by or
        on behalf of ATeams to You in connection with the Services (excluding
        User Content); and ATeams’s trademarks, and all related graphics, logos,
        service marks and trade names used on or in connection with the Services
        (collectively, “<b>ATeams IP</b>”). ATeams reserves all rights in ATeams
        IP not expressly granted herein.
      </div>
      <div style={{ marginTop: '16px' }}>
        <b>6.2 User Content.</b> You own all rights, title and interest in, and
        You hereby grant ATeams a fully paid, royalty-free, worldwide,
        non-exclusive right and license to use, license, distribute, reproduce,
        modify, adapt, publicly perform, and publicly display, any information,
        data, text, software, and/or other materials provided by or on Your
        behalf to ATeams in connection with the Services (collectively, “
        <b>User Content</b>”) for the purposes of operating and providing the
        Services to You and other Users. You are solely responsible for Your
        User Content, including the accuracy thereof, and for any legal action
        that may be instituted by other Users or third parties as a result of or
        in connection with Your User Content.
      </div>
      <div style={{ marginTop: '16px' }}>
        <b>6.3 Feedback.</b> You hereby grant to ATeams a fully paid,
        royalty-free, perpetual, irrevocable, worldwide, non-exclusive, and
        fully sublicensable right and license to use, reproduce, perform,
        display, distribute, adapt, modify, re-format, create derivative works
        of, and otherwise commercially or non-commercially exploit in any manner
        (a) any and all feedback, suggestions, or ideas related to the Services
        or ATeams’s products, services, or business provided by You
        (collectively, “<b>Feedback</b>”) and to sublicense the foregoing
        rights, in connection with the operation, maintenance, and improvement
        of the Services and/or ATeams’s business and (b) any feedback,
        suggestions, ideas, responses, comments, information, and data,
        including survey responses, provided by You or on Your behalf related to
        any A.Teamer Services, Assignments, or other Users (“
        <b>Service Assessments</b>”), and to sublicense the foregoing rights, in
        connection with the operation, maintenance, and improvement of the
        Services and/or ATeams’s business, provided that ATeams shall not share
        any Service Assessments with any third parties in a manner that
        identifies You by name.
      </div>

      <SectionHeading>7. Restrictions</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        You represent, warrant, and covenant that You will not, and shall not
        permit any Permitted User or third party, to directly or indirectly (a)
        use the Services to provide services to third parties or otherwise
        provide access to the Services to third parties; (b) modify any
        documentation, or create any derivative product thereof; (c) assign,
        sublicense, sell, resell, lease, rent, or otherwise transfer or convey,
        or pledge as security or otherwise encumber ATeams’s rights under this
        Section; (d) harvest, collect, gather or assemble information or data
        regarding other Users without their consent; (e) use the Services to
        solicit, advertise for, or contact in any form, Users for employment or
        any other purpose not related to the Assignments advertised through the
        Services; or (f) use the Services or any information or data received
        through or in connection with the Services in a manner that (i) may
        infringe or violate the intellectual property or other rights of any
        individual or entity, including without limitation the rights of
        publicity or privacy; (ii) may violate applicable laws or governmental
        regulations; (iii) is unlawful, threatening, abusive, harassing,
        misleading, false, defamatory, libelous, deceptive, fraudulent, invasive
        of another’s privacy, tortious, obscene, offensive, profane or racially,
        ethnically, or otherwise discriminatory; (iv) constitutes unauthorized
        or unsolicited advertising, junk or bulk e-mail; (v) impersonates any
        person or entity, including any employee or representative of ATeams;
        (vi) interferes with or attempts to interfere with the proper
        functioning of the Services or uses the Services in any way not
        expressly permitted by this Agreement; or (vii) attempts to engage in or
        engages in, any potentially harmful acts that are directed against the
        Services.
      </div>

      <SectionHeading>8. Investigations</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        Although ATeams does not generally monitor User activity occurring in
        connection with the Services or any Assignments or A.Teamer Services, if
        ATeams becomes aware of any possible violations by any Users of any
        terms between ATeams and its Users, ATeams reserves the right, but has
        no obligation, to investigate such violations. If, as a result of the
        investigation, ATeams believes that criminal activity has occurred,
        ATeams reserves the right to refer the matter to, and to cooperate with,
        any and all applicable legal authorities. ATeams is entitled, except to
        the extent prohibited by applicable law, to disclose any information or
        materials on or in connection with the Services, including User Content
        or Registration Data, in ATeams’s possession in connection with Your use
        of the Services, to (i) comply with applicable laws, legal process or
        governmental request; (ii) enforce the Agreement; (iii) respond to any
        claims that Your content, acts, or omissions violates the rights of
        third parties; (iv) respond to requests for customer service; or (v)
        protect the rights, property or personal safety of ATeams, its Users or
        the public, and all enforcement or other government officials, as ATeams
        in its sole discretion believes to be necessary or appropriate.
      </div>

      <SectionHeading>9. Interactions With Other Users</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        You are solely responsible for Your interactions with other Users and
        any other parties with whom You interact; provided, however, that ATeams
        reserves the right, but has no obligation, to intercede in such
        disputes. You agree that ATeams will not be responsible for any
        liability incurred as the result of such interactions. While ATeams may,
        in ATeams’s sole discretion, help facilitate the resolution of disputes
        through various programs, ATeams has no control over and does not
        guarantee the existence, quality, safety or legality of A.Teamer
        Services or Assignments; the truth or accuracy of Your content or
        listings; Your ability to procure services; A.Teamers’ ability to
        provide A.Teamer Services; or that an A.Teamer and You will actually
        complete a transaction.
      </div>

      <SectionHeading>10. Confidential Information</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        <b>10.1 Between ATeams and User.</b> ATeams and You each agree as
        follows: (a) to use Confidential Information disclosed by the other
        party only for the purposes expressly permitted herein; (b) that such
        party will not reproduce Confidential Information disclosed by the other
        party, and will hold in confidence and protect such Confidential
        Information from dissemination to, and use by, any third party; (c) that
        neither party will create any derivative work from Confidential
        Information disclosed to such party by the other party; (d) to restrict
        access to the Confidential Information disclosed by the other party to
        such of its personnel, agents, and/or consultants, who have a need to
        have access and who have been advised of, and have agreed in writing to
        treat such information in accordance with, the terms of this Agreement;
        and (e) to the extent practicable, return or destroy all Confidential
        Information disclosed by the other party that is in its possession upon
        termination or expiration of this Agreement. Both parties agree that all
        items of Confidential Information are proprietary to the disclosing
        party, and as between the parties, will remain the sole property of the
        disclosing party.
      </div>
      <div style={{ marginTop: '32px' }}>
        <b>10.2 Confidentiality Exceptions.</b> Notwithstanding the foregoing,
        the provisions of Section 10.1 will not apply to Confidential
        Information that (a) is publicly available or in the public domain at
        the time disclosed; (b) is or becomes publicly available or enters the
        public domain through no fault of the recipient; (c) is rightfully
        communicated to the recipient by persons not bound by confidentiality
        obligations with respect thereto; (d) is already rightfully in the
        recipient’s possession free of any confidentiality obligations with
        respect thereto at the time of disclosure; (e) is independently
        developed by the recipient without use of or reference to the other
        party’s Confidential Information; or (f) is approved for release or
        disclosure by the Disclosing party without restriction. Notwithstanding
        the foregoing, each party may disclose Confidential Information to the
        limited extent required (i) to comply with the order of a court or other
        governmental body, or as otherwise necessary to comply with applicable
        law, provided that the party making the disclosure pursuant to the order
        shall first have given written notice to the other party (to the extent
        legally permitted) and made a reasonable effort to obtain a protective
        order; or (ii) to establish a party’s rights under this Agreement,
        including to make such court filings as it may be required to do.
      </div>

      <SectionHeading>11. No Solicitation</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        You may not use the Services to solicit for any other business, website
        or services. You may not solicit, advertise for, or contact A.Teamers
        for employment, contracting, or any other purpose not related to the
        Services.
      </div>

      <SectionHeading>12. Non-Circumvention</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        <b>12.1 Non-Circumvention Period.</b> The value of the Services rests in
        its thriving marketplace for Clients and A.Teamers. You acknowledge and
        agree that a substantial portion of the compensation ATeams receives for
        providing the Services is collected through the Service Fee described in
        this Agreement. ATeams only receives this Service Fee when a Client and
        an A.Teamer pay and receive payment through the Services. Therefore,
        during the term of this Agreement and for 12 months thereafter (the “
        <b>Non-Circumvention Period</b>”), You agree to use the Services as Your
        exclusive method to make all payments for work directly or indirectly
        with any A.Teamer identified or matched through the Services (the “
        <b>ATeams Relationship</b>”). For the avoidance of doubt, if You did not
        identify, and were not identified by, another party through the Services
        – i.e., if You and A.Teamer worked together before being introduced or
        matched through the Services – then the Non-Circumvention Period does
        not apply with respect to You and such A.Teamer. If You use the Services
        as an employee, agent, or representative of another business, then the
        Non-Circumvention Period applies to You and other employees, agents, or
        representatives of the business when acting in that capacity with
        respect to the other User.
      </div>
      <div style={{ marginTop: '16px' }}>
        <b>12.2 Restrictions.</b> Unless You pay the Network Fee, You agree not
        to circumvent the payment process managed by ATeams in connection with
        the Services. Without limiting the generality of the foregoing, You
        agree not to: (a) solicit proposals or submit Assignments to parties
        identified through the Services to contract, hire, work with, or pay
        outside the Services; (b) accept proposals or solicit parties identified
        through the Services to contract, invoice, or receive payment outside
        the Services; (c) invoice or report on the Services, or in a Network Fee
        request, a payment amount lower than that actually agreed between Users
        (including with respect to allocations between A.Teamer Fees and
        Expenses); or (d) refer a User identified on the Services to a
        third-party who is not a User of the Services for any purpose, including
        making or receiving payments in a manner that ATeams does not receive
        the Service Fee. You shall notify ATeams immediately upon becoming aware
        of a breach or potential breach of this non-circumvention provision.
      </div>
      <div style={{ marginTop: '16px' }}>
        <b>12.3 Connection Fee.</b> The non-circumvention obligation in this
        Section 12 will not apply to You with respect to an ATeams Relationship
        only if the applicable A.Teamer or You pays ATeams a fee for such
        relationship (the “<b>Network Fee</b>”), including if You want to
        commence or continue working with any other A.Teamer, in any capacity
        (i.e., as an employee or contractor), who has an ATeams Relationship
        after termination or expiration of this Agreement. The Network Fee is
        due and payable on the first day the A.Teamer begins working for you
        outside the Services and is computed as follows: the greater of (a)
        twenty thousand dollars ($20,000); or (b) the amount that is equal to 3
        times (3x) the monthly amount You owe the A.Teamer (“<b>Monthly Comp</b>
        ”) (salary if an employee; fees if a contractor), plus (c) ten percent
        (10%) of any one-time signing bonus or similar additional compensation.
        If the Monthly Comp is not fixed, it shall be determined by averaging
        the first 3 months of the A.Teamer’s Monthly Comp.
      </div>

      <SectionHeading>13. Indemnification</SectionHeading>
      <div style={{ marginTop: '16px' }}>
        You agree to defend, indemnify and hold ATeams, its parents,
        subsidiaries, affiliates, officers, employees, agents, partners,
        suppliers, and licensors (each, a “<b>ATeams Party</b>” and
        collectively, the “<b>ATeams Parties</b>”) harmless from any losses,
        costs, liabilities and expenses (including reasonable attorneys’ fees)
        relating to or arising out of any and all of the following: (a) User
        Content; (b) Your use of the Services; (c) Your violation of the
        Agreement or of any rights of another party, including any other Users;
        or (d) Your violation of any applicable laws, rules or regulations.
        ATeams reserves the right, at its own cost, to assume the exclusive
        defense and control of any matter otherwise subject to indemnification
        by You, in which event You will fully cooperate with ATeams in asserting
        any available defenses. For purposes of this Section 13, You includes
        any of Your agents or any person who has apparent authority to access or
        use Your Account.
      </div>

      <SectionHeading>14. Disclaimer of Warranties</SectionHeading>
      <div style={{ marginTop: '16px' }}>
        <b>14.1 As Is.</b> YOUR USE OF THE SERVICES AND PARTICIPATION IN ANY
        TRANSACTIONS OR ARRANGEMENTS MADE IN CONNECTION THEREWITH ARE AT YOUR
        SOLE RISK. THE SERVICES ARE PROVIDED ON AN “
        <b>AS IS” AND “AS AVAILABLE</b>” BASIS, WITH ALL FAULTS. ATEAMS PARTIES
        EXPRESSLY DISCLAIM ALL WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF
        ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
        IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE AND NON-INFRINGEMENT. THE ATEAMS PARTIES MAKE NO
        WARRANTY, REPRESENTATION OR CONDITION THAT THE SERVICES OR ANY SERVICES
        OBTAINED OR TRANSACTIONS MADE IN CONNECTION THEREWITH WILL MEET YOUR
        REQUIREMENTS.
      </div>
      <div style={{ marginTop: '16px' }}>
        <b>14.2 Third Parties or Other Users.</b> YOU ACKNOWLEDGE AND AGREE THAT
        ATEAMS PARTIES ARE NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD ATEAMS
        PARTIES LIABLE, FOR THE CONDUCT OF THIRD PARTIES, AND THAT THE RISK OF
        INJURY FROM SUCH THIRD PARTIES RESTS ENTIRELY WITH YOU. YOU ARE SOLELY
        RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH OTHER
        USERS OF THE SERVICES.
      </div>

      <SectionHeading>15. Limitation of Liability</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT SHALL ATEAMS PARTIES
        BE LIABLE TO YOU UNDER THIS AGREEMENT AND THE ENGAGEMENT AGREEMENT,
        COLLECTIVELY, FOR (a) ANY INDIRECT, INCIDENTAL, SPECIAL, OR
        CONSEQUENTIAL DAMAGES, OR DAMAGES OR COSTS DUE TO LOSS OF PRODUCTION OR
        USE, BUSINESS INTERRUPTION, OR PROCUREMENT OF SUBSTITUTE GOODS OR
        SERVICES, IN EACH CASE WHETHER OR NOT ATEAMS HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THESE
        TERMS OF SERVICE OR ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH
        OTHER USERS, ON ANY THEORY OF LIABILITY, OR (b) ANY AMOUNTS THAT ARE
        GREATER THAN THE TOTAL AMOUNT PAID TO ATEAMS BY YOU DURING THE
        TWELVE-MONTH PERIOD PRIOR TO THE ACT, OMISSION OR OCCURRENCE GIVING RISE
        TO SUCH LIABILITY. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
        FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN ATEAMS AND YOU.
      </div>

      <SectionHeading>16. Term and Termination</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        <b>16.1 Term.</b> The Agreement commences on the Effective Date and
        remain in full force and effect until terminated by either party in
        accordance with this Agreement.
      </div>
      <div style={{ marginTop: '16px' }}>
        <b>16.2 Termination for Breach.</b> Either party may terminate this
        Agreement upon written notice to the other party if the other party
        breaches this Agreement and does not cure such breach within fifteen
        (15) days of receiving notice thereof. Furthermore, without limiting
        ATeams’s other rights or remedies, ATeams may, but is not obligated to,
        temporarily or indefinitely revoke access to the Services, Your
        registration, or permanently revoke Your access to the Services if
        ATeams suspects or becomes aware that You have provided false or
        misleading information to us or if ATeams believes, in ATeams’s sole
        discretion, that Your actions may cause legal liability for ATeams,
        Users, or ATeams or any ATeams Parties; may be contrary to the interests
        of the Services or User community; or may involve illicit or illegal
        activity. If Your Account is temporarily or permanently closed, You may
        not use the Services under the same Account or a different Account or
        reregister under a new Account without ATeams’s prior written consent.
      </div>
      <div style={{ marginTop: '16px' }}>
        <b>16.3 Termination for Convenience.</b> ATeams may terminate this
        Agreement for its convenience at any time for any reason or no reason at
        all by providing You with at least thirty (30) days prior written
        notice.
      </div>
      <div style={{ marginTop: '16px' }}>
        <b>16.4 Effect of Termination.</b> Termination of this Agreement does
        not terminate or otherwise impact any A.Teamer Contract or Assignment
        entered into between You and A.Teamers. If this Agreement is terminated
        while one or more open Assignments and/or A.Teamer Contracts are in
        effect, You agree (a) You will continue to be bound by this Agreement
        until all such Assignments and A.Teamer Contracts are closed or end (in
        accordance with their terms); (b) ATeams will continue to perform the
        Services necessary to complete any open Assignment or related
        transaction between You and Your A.Teamer(s); and (c) You will continue
        to be obligated to pay any amounts owed under the Agreement. Any
        provisions that by their nature would be expected to survive any
        termination or expiration of this Agreement will survive such
        termination or expiration.
      </div>
      <div style={{ marginTop: '16px' }}>
        <b>16.5 Notification.</b> If ATeams decides to temporarily or
        permanently close Your Account, ATeams has the right where allowed by
        law but not the obligation to: (a) notify other Users that have entered
        into A.Teamer Contracts with You to inform them of Your closed account
        status, and (b) provide those Users with a summary of the reasons for
        Your account closure. You agree that ATeams will have no liability
        arising from or relating to any notice that it may provide to any User
        regarding closed account status or the reason(s) for the closure.
      </div>

      <SectionHeading>17. Release</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        ATeams expressly disclaims any liability that may arise between Users.
        Because ATeams is not a party to the actual contracts between A.Teamers
        and You, in the event that You have a dispute with one or more Users,
        You release ATeams, its parents, subsidiaries, affiliates, officers,
        employees, investors, agents, partners and licensors, but excluding any
        Users (collectively, the “<b>ATeams Parties</b>”) from any and all
        claims, demands, or damages (actual or consequential) of every kind and
        nature, known and unknown, suspected and unsuspected, disclosed and
        undisclosed, arising out of or in any way connected with such disputes.
        You hereby waive California Civil Code Section 1542, or any similar law
        of any other jurisdiction which states in substance, “A general release
        does not extend to claims that the creditor or releasing party does not
        know or suspect to exist in his or her favor at the time of executing
        the release and that, if known by him or her, would have materially
        affected his or her settlement with the debtor or released party.”
      </div>

      <SectionHeading>18. Dispute Resolution</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        All claims and disputes arising out of or relating to the Agreement will
        be litigated exclusively in the state or federal courts located in New
        York, NY. This Agreement and any action related thereto will be governed
        and interpreted by and under the laws of the State of New York, New
        York, consistent with the Federal Arbitration Act, without giving effect
        to any principles that provide for the application of the law of another
        jurisdiction. The United Nations Convention on Contracts for the
        International Sale of Goods does not apply to the Agreement.
      </div>

      <SectionHeading>19. Construction</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        Section headings are included in this Agreement merely for convenience
        of reference; they are not to be considered part of this Agreement or
        used in the interpretation of this Agreement. When used in this
        Agreement, “<b>including” means “including without limitation.</b>” No
        rule of strict construction will be applied in the interpretation or
        construction of this Agreement.
      </div>

      <SectionHeading>20. General Provisions</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        The Agreement, and Your rights and obligations hereunder, may not be
        assigned, subcontracted, delegated or otherwise transferred by You
        without ATeams’s prior written consent, and any attempted assignment,
        subcontract, delegation, or transfer in violation of the foregoing will
        be null and void. Nothing in this Agreement is intended to or should be
        construed to create a partnership, joint venture, franchisor/franchisee
        or employer-employee relationship between ATeams and You. ATeams shall
        not be liable for any delay or failure to perform resulting from causes
        outside its reasonable control, including, but not limited to, acts of
        God, war, natural disasters, disease, terrorism, riots, embargos, acts
        of civil or military authorities, fire, floods, accidents, strikes or
        shortages of transportation facilities, fuel, energy, labor or
        materials. You may give notice to ATeams at the following address: 12 W
        10th Street, New York NY 10011. Such notice shall be deemed given when
        received by ATeams by letter delivered by nationally recognized
        overnight delivery service or first class postage prepaid mail at the
        above address. Any waiver or failure to enforce any provision of the
        Agreement on one occasion will not be deemed a waiver of any other
        provision or of such provision on any other occasion. If any portion of
        this Agreement is held invalid or unenforceable, that portion shall be
        construed in a manner to reflect, as nearly as possible, the original
        intention of the parties, and the remaining portions shall remain in
        full force and effect. The Agreement is the final, complete and
        exclusive agreement of the parties with respect to the subject matter
        hereof and supersedes and merges all prior discussions between the
        parties with respect to such subject matter.
      </div>

      <div
        style={{ textAlign: 'right', marginTop: '32px' }}
        className={styles.light}
      >
        260691702 v3{' '}
      </div>
    </div>
  );
}
