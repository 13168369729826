import React, {
  CSSProperties,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from 'react';
import { createUseStyles } from 'react-jss';
import Colors, { ColorName, TextColors } from '../theme/colors';
import cx from 'classnames';
import { Breakpoints } from '../theme/styles';

export interface NotificationBannerProps {
  title?: string;
  text?: string | ReactNode;
  backgroundColor?: ColorName;
  style?: CSSProperties;
  className?: string;
}

const defaultProps = {
  withButton: true,
};

const useStyles = createUseStyles({
  container: (props: NotificationBannerProps) => ({
    color: props.backgroundColor ? TextColors[props.backgroundColor] : '#222',
    background: props.backgroundColor
      ? Colors[props.backgroundColor]
      : '#EDE0FF',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16,
    marginRight: 0,
    flexDirection: 'column',
    boxShadow: 'none',
    border: 0,
    borderRadius: '0.5em',
    position: 'relative',
    margin: '0 1.34em 1.34em 0',
    '& a': {
      color: props.backgroundColor
        ? TextColors[props.backgroundColor]
        : '#6D00D7',
      textDecoration: 'underline',
    },
  }),
  title: {
    background: '#6D00D7',
    borderRadius: 4,
    color: '#FFFFFF',
    padding: '8px 12px',
    width: 'auto',
    display: 'inline-block',
    marginBottom: 16,
    fontWeight: 400,
    fontSize: 14,
  },
  text: {
    margin: 0,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      flexDirection: 'row',
    },
  },
});

const NotificationBanner = (
  props: PropsWithChildren<NotificationBannerProps>,
): ReactElement => {
  const styles = useStyles(props);
  const { style, title, text, className } = props;

  return (
    <div className={cx(styles.container, className)} style={style}>
      <div>
        {title && <h2 className={styles.title}>{title}</h2>}
        {text && <p className={styles.text}>{text}</p>}
        {props.children}
      </div>
    </div>
  );
};

NotificationBanner.defaultProps = defaultProps;
export default NotificationBanner;
