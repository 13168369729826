import React from 'react';
import {
  BorderRadius,
  Button,
  Icon,
  IconType,
  Paddings,
  Spacing,
  Tag,
  TagProps,
  TextColors,
} from '@ateams/components';
import { MissionRoleVisibilityStatus } from '@a_team/models/dist/MissionRole';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

export enum ReviewStatus {
  Initial,
  Pending,
  ReadyForReview,
}

interface Props extends Pick<TagProps, 'className' | 'square' | 'color'> {
  visibilityStatus?: MissionRoleVisibilityStatus;
  reviewStatus?: ReviewStatus;
  onClickOnRequestOptimization?: () => void;
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  reviewStatusContainer: {},
  baseButton: {
    height: 'auto',
    padding: `${Paddings.xsmall}px ${Paddings.small}px`,
    margin: `${Spacing.xxsmall}px 0`,
  },
  requestOptimizationButton: {
    borderRadius: BorderRadius.default,
    color: TextColors.regular,
  },
  optimizationRequestedButton: {
    borderRadius: BorderRadius.default,
    color: TextColors.regular,
    background: 'rgba(255,255,255, 0.5)',
  },
  requestOptimizationTransparentButton: {
    color: TextColors.regular,
    background: 'transparent',
    marginRight: Spacing.small,
  },
  buttonIcon: {
    marginRight: Spacing.small,
  },
});

export const MissionRoleVisibilityStatusTag: React.FC<Props> = ({
  visibilityStatus,
  square,
  className,
  color,
  reviewStatus,
  onClickOnRequestOptimization,
}) => {
  const styles = useStyles();
  return visibilityStatus ? (
    <Tag square={square} className={cx(styles.root, className)} color={color}>
      {getVisibilityStatusText(visibilityStatus)}
      <div className={styles.reviewStatusContainer}>
        {reviewStatus === ReviewStatus.Initial && (
          <>
            <Button
              className={cx(
                styles.baseButton,
                styles.requestOptimizationButton,
              )}
              onClick={() =>
                onClickOnRequestOptimization && onClickOnRequestOptimization()
              }
              size="small"
              color="regularLight"
              squared
              width="auto"
            >
              Request optimization
            </Button>
          </>
        )}
        {reviewStatus === ReviewStatus.Pending && (
          <>
            <Button
              className={cx(
                styles.baseButton,
                styles.optimizationRequestedButton,
              )}
              disabled
              size="small"
              squared
              width="auto"
            >
              <Icon
                size="exact"
                className={styles.buttonIcon}
                type={IconType.Refresh}
              />
              Optimization requested
            </Button>
          </>
        )}
        {reviewStatus === ReviewStatus.ReadyForReview && (
          <>
            <Button
              onClick={() => {
                onClickOnRequestOptimization && onClickOnRequestOptimization();
              }}
              width="auto"
              className={cx(
                styles.baseButton,
                styles.requestOptimizationTransparentButton,
              )}
            >
              Request new optimization
            </Button>
            <Tag square color="dark">
              <Icon className={styles.buttonIcon} type={IconType.Flag} />
              Ready for review
            </Tag>
          </>
        )}
      </div>
    </Tag>
  ) : (
    <></>
  );
};

const getVisibilityStatusText = (
  visibilityStatus: MissionRoleVisibilityStatus,
): string => {
  switch (visibilityStatus) {
    case MissionRoleVisibilityStatus.All:
      return `Visible to all users`;
    case MissionRoleVisibilityStatus.OnlyAdmin:
      return `Only visible to admins`;
  }
};
