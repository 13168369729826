import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import {
  TagList,
  Tag,
  Icon,
  IconType,
  BorderRadius,
  Colors,
  FontSizes,
  Spacing,
} from '@ateams/components';
import cx from 'classnames';
import { useCommonStyles } from './commonStyles';
import { SkillSelector } from '@src/components/SkillSelector';
import { useTalentSkills } from '@src/rq/useTalentSkills';
import { MAX_SELECTED_SKILLS } from '.';

const useStyles = createUseStyles({
  skillsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: Spacing.small,
  },
  skills: {
    flexWrap: 'wrap',
    marginTop: Spacing.medium,
    columnGap: Spacing.xsmall,
    alignItems: 'center',
  },
  skill: {
    padding: Spacing.small,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: BorderRadius.medium,
  },
  skillText: {
    fontSize: FontSizes.small,
    color: Colors.dark,
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.xsmall,
  },
  readonlySkillText: {
    color: Colors.regularDark,
  },
  deleteIcon: {
    width: 8,
    height: 8,
    cursor: 'pointer',
    stroke: Colors.dark,
  },
  skillSelector: {
    width: '350px',
    backgroundColor: 'transparent',
    padding: 0,
    marginTop: Spacing.medium,
  },
});

export interface SkillsSectionProps {
  talentCategoryIds: string[];
  selectedSkillIds: string[];
  setSelectedSkillIds: (skillIds: string[]) => void;
  requiredSkillIds: string[];
  showSkillsError: boolean;
}

const SkillsSection = ({
  talentCategoryIds,
  selectedSkillIds,
  setSelectedSkillIds,
  requiredSkillIds,
  showSkillsError,
}: SkillsSectionProps) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const skillsQueryResult = useTalentSkills({
    talentCategoryIds,
  });

  const allSkills = useMemo(() => {
    return skillsQueryResult?.data || [];
  }, [skillsQueryResult]);

  const requiredSkills = allSkills.filter((skill) =>
    requiredSkillIds.includes(skill.id),
  );
  const selectedSkills = allSkills.filter((skill) =>
    selectedSkillIds.includes(skill.id),
  );

  const onSkillSelectorChange = (skillsToAdd: string[]) => {
    const newSkills = skillsToAdd.filter(
      (skillId) =>
        !selectedSkillIds.includes(skillId) &&
        !requiredSkillIds.includes(skillId),
    );
    const uniqueSkills = [...selectedSkillIds, ...newSkills];
    setSelectedSkillIds(uniqueSkills);
  };

  const removeSkill = (skillId: string) => {
    const updatedSkills = selectedSkillIds.filter((id) => id !== skillId);
    setSelectedSkillIds(updatedSkills);
  };

  const allSelectedSkills = useMemo(() => {
    return [...requiredSkills, ...selectedSkills];
  }, [requiredSkills, selectedSkills]);

  return (
    <div className={styles.skillsContainer}>
      <div className={commonStyles.title}>Skills</div>
      <div className={commonStyles.description}>
        Please add up to {MAX_SELECTED_SKILLS} skills you want to be evaluated
        on. <br />
        {requiredSkillIds.length > 0 && (
          <span>
            Skills that were required on missions you applied to can't be
            removed.
          </span>
        )}
        {showSkillsError && (
          <div className={commonStyles.errorText}>
            Please make sure at least two skills are selected.
          </div>
        )}
      </div>
      {selectedSkills.length < MAX_SELECTED_SKILLS && (
        <div className={styles.skillSelector}>
          <SkillSelector
            type="all"
            value={allSelectedSkills.map((skill) => ({
              value: skill.id,
              label: skill.name,
            }))}
            talentCategoryIds={talentCategoryIds}
            onChange={onSkillSelectorChange}
            hideTags={true}
          />
        </div>
      )}
      {requiredSkillIds.length > 0 && (
        <TagList className={styles.skills}>
          {requiredSkills.map((skill) => (
            <Tag className={styles.skill} key={skill.name}>
              <div className={cx(styles.skillText, styles.readonlySkillText)}>
                <div>{skill.name}</div>
              </div>
            </Tag>
          ))}
        </TagList>
      )}
      {selectedSkills.length > 0 && (
        <TagList className={styles.skills}>
          {selectedSkills.map((skill) => (
            <Tag className={styles.skill} key={skill.name}>
              <div className={styles.skillText}>
                <div>{skill.name}</div>
                <Icon
                  type={IconType.Close}
                  className={styles.deleteIcon}
                  onClick={() => removeSkill(skill.id)}
                />
              </div>
            </Tag>
          ))}
        </TagList>
      )}
    </div>
  );
};

export default SkillsSection;
