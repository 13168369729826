import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import {
  MissionAdminTimeTrackingLocation,
  MissionPageLocation,
  MissionControlBase,
} from '@src/locations';
import { observer } from 'mobx-react';
import { Location } from 'history';
import { useStores } from '@src/stores';
import { loadMission, MissionMatch } from '@src/url-loaders/loadMission';

interface Props {
  location: Location;
  match: MissionMatch;
}

export const missionAdminViewLoader = loadMission;

function MissionAdminPageView(props: Props): ReactElement {
  const { match } = props;
  const stores = useStores();
  const { auth } = stores;

  if (!auth.isAdmin) {
    return (
      <Redirect
        to={
          match.params.mid
            ? MissionPageLocation(match.params.mid)
            : MissionControlBase
        }
      />
    );
  }

  return (
    <Redirect
      to={
        match.params.mid
          ? MissionAdminTimeTrackingLocation(match.params.mid)
          : MissionControlBase
      }
    />
  );
}

export default observer(MissionAdminPageView);
