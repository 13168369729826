import React, { ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { sectionTitleStyle } from '../common-styles';

export const ROW_MAX_HEIGHT_RATIO = 1.6666;

interface Props {
  children: ReactNode;
  style?: React.CSSProperties;
}

const useStyles = createUseStyles({
  root: {
    width: '100%',
    borderCollapse: 'collapse',
    margin: 0,
    padding: 0,

    '& tr > td, & tr > th': {
      padding: '4pt',
      margin: 0,
      textAlign: 'left',
      paddingLeft: 0,
    },

    '& tr > td': {
      borderTop: '1px solid #f2f2f2',
    },

    '& tr > th': {
      ...sectionTitleStyle,
    },
  },

  cellContent: {
    display: 'block',
    maxHeight: '3.6em',
    lineHeight: '1.8em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default function Table(props: Props): ReactElement {
  const styles = useStyles();

  return (
    <table className={styles.root} style={props.style}>
      {props.children}
    </table>
  );
}

export function CellContent(props: Props): ReactElement {
  const styles = useStyles();

  return <div className={styles.cellContent}>{props.children}</div>;
}
