import { ServiceAuth } from '@ateams/service-utils';
import { ServiceEndpoint } from './utils';
import { CreateRoleOptimizationRequestData } from '@a_team/models/dist/RoleOptimizationRequest';

export const BasePath = '/role-optimization-request';

export default class RoleOptimizationRequestsEndpoint extends ServiceEndpoint {
  public createRoleOptimizationRequest(
    auth: ServiceAuth,
    data: CreateRoleOptimizationRequestData,
  ): Promise<void> {
    return this.fetch(auth, `${BasePath}`, null, 'post', data);
  }
}
