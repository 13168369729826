import { Breakpoints } from '@ateams/components';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import ControlButtons from './common/ControlButtons';
import { TeamPulseSectionsEnum } from './TeamPulseModal';
import ReferBadge from '@src/views/Mission/TeamPulse/components/newTeamPulseModal/common/ReferBadge';
import cx from 'classnames';
import { SkillSelector } from '@src/components/SkillSelector';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { TeamPulseRecommendedRole } from '@src/views/Mission/TeamPulse/components/newTeamPulseModal/hooks/useTeamPulse';
import { apiTalentCategories } from '@ateams/api';
import { useStores } from '@src/stores';
import { TalentCategoryId } from '@a_team/models/dist/TalentCategories';
import RoleCategoryObject from '@a_team/models/dist/RoleCategory';

interface HowBuildersWouldHelpMissionProps {
  setSection: (section: string) => void;
  setRoleRecommendations: (value: Array<TeamPulseRecommendedRole>) => void;
  missionTitle: string;
  initialState: Array<TeamPulseRecommendedRole>;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    height: '100%',
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'start',
  },
  roleTitle: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '26px',
    marginBottom: 16,
  },
  outlinedInput: {
    minHeight: '40px',
    borderRadius: 4,
    border: '1px solid #D1D2D6',
    padding: '8px 12px',
    lineHeight: '24px',
  },
  desc: {
    textAlign: 'start',
    color: '#707275',
    marginTop: 8,
  },
  wrapper: {
    padding: 20,
  },
  roleList: {
    gap: 12,
    display: 'flex',
    flexDirection: 'column',
  },
  roleContainer: {
    border: `1px solid #DADADC`,
    borderRadius: 8,
    width: '100%',
    padding: 16,
  },
  buttonContainer: {
    width: '100%',
    height: '120px',
    backgroundColor: 'white',
    position: 'sticky',
    bottom: 0,
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    wrapper: {
      padding: 0,
    },
  },
});

const HowBuildersWouldHelpMission = ({
  setSection,
  setRoleRecommendations,
  missionTitle,
  initialState,
}: HowBuildersWouldHelpMissionProps): JSX.Element => {
  const styles = useStyles();
  const [roleDetails, setRoleDetails] =
    React.useState<Array<TeamPulseRecommendedRole>>(initialState);
  const [swEngineeringCategoryIds, setSwEngineeringCategoryIds] =
    React.useState<Array<TalentCategoryId>>();
  const [generalSkillCategoryIds, setGeneralSkillCategoryIds] =
    React.useState<Array<TalentCategoryId>>();

  const { auth } = useStores();

  const loadTalentCategories = async () => {
    const [swEngineeringCategories, generalSkillCategories] = await Promise.all(
      [
        apiTalentCategories.queryTalentCategories(auth, {
          textId: 'software-engineering',
        }),
        apiTalentCategories.queryTalentCategories(auth, {
          textId: 'general',
        }),
      ],
    );
    setSwEngineeringCategoryIds(
      swEngineeringCategories.items.map((category) => category.id),
    );
    setGeneralSkillCategoryIds(
      generalSkillCategories.items.map((category) => category.id),
    );
  };

  useEffect(() => {
    loadTalentCategories();
  }, []);

  const onBack = (): void => {
    setRoleRecommendations(roleDetails);
    setSection(TeamPulseSectionsEnum.RoleRecommendation);
  };

  const onContinue = (): void => {
    setRoleRecommendations(roleDetails);
    setSection(TeamPulseSectionsEnum.AnyBuildersInMind);
  };

  const isSwEngineeringCategory = (roleCategory: RoleCategoryObject) => {
    if (swEngineeringCategoryIds) {
      return !!roleCategory.talentCategoryIds.find((talentCategoryId) =>
        swEngineeringCategoryIds.includes(talentCategoryId),
      );
    }
    return true;
  };

  const skillCategoryIdsForRole = (role: TeamPulseRecommendedRole) => {
    const generalCategoryIds = generalSkillCategoryIds ?? [];
    if (role.roleCategory?.talentCategoryIds) {
      return [...role.roleCategory?.talentCategoryIds, ...generalCategoryIds];
    }
    return generalCategoryIds.length > 0 ? generalCategoryIds : undefined;
  };

  const dataValid = roleDetails.every(
    (role) =>
      role.comment.length > 0 &&
      (role.skillIds.length > 0 ||
        !role.roleCategory ||
        !isSwEngineeringCategory(role.roleCategory)),
  );

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>
          How would additional builders help this mission and what skills
          exactly are needed?
        </h4>
        <p className={styles.desc}>
          This answer is not shared with {missionTitle}.
        </p>
        <ReferBadge missionTitle={missionTitle} />
        <div className={styles.roleList}>
          {roleDetails.map((role) => {
            return (
              <div className={styles.roleContainer} key={role.roleCategoryId}>
                <h5 className={cx(styles.title, styles.roleTitle)}>
                  {role.name}
                </h5>
                <SkillSelector
                  data-testing-id="skill-selector"
                  required={
                    !role.roleCategory ||
                    isSwEngineeringCategory(role.roleCategory)
                  }
                  label="What skills are needed?"
                  onChange={(e) => {
                    role.skillIds = e;
                    setRoleDetails([...roleDetails]);
                  }}
                  type="all"
                  defaultValue={role.skillIds}
                  talentCategoryIds={skillCategoryIdsForRole(role)}
                />
                <OutlinedInput
                  data-testing-id="comment-input"
                  label="How will this role help the mission?"
                  style={{ borderRadius: '4px' }}
                  placeholder="Enter explanation"
                  controlClassName={styles.outlinedInput}
                  value={role.comment}
                  required={true}
                  onChange={(e) => {
                    role.comment = e.target.value;
                    setRoleDetails([...roleDetails]);
                  }}
                  margin="bottom"
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        {/*Without the button container the content scrolls "under" the buttons, which does not look good.*/}
        <ControlButtons
          canContinue={dataValid}
          onBack={onBack}
          onContinue={onContinue}
        />
      </div>
    </div>
  );
};

export default HowBuildersWouldHelpMission;
