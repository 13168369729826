import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { BasicVettingProcessColumnProps } from '..';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { OutlinedDateInput } from '@src/components/OutlinedDateInput';

export const InterviewDateField: React.FC<BasicVettingProcessColumnProps> =
  observer(({ vettingProcess, isAdminView }) => {
    const { auth, vettingProcesses } = useStores();
    const [loading, setLoading] = useLoadingState();

    const interviewDate = useMemo(
      () =>
        vettingProcess.interviewDate
          ? new Date(vettingProcess.interviewDate)
          : null,
      [vettingProcess.interviewDate],
    );

    const onInterviewDateChange = (date: Date | null) => {
      setLoading(
        (async () => {
          await vettingProcesses[
            isAdminView ? 'adminSetInterviewDate' : 'setInterviewDate'
          ]({
            id: vettingProcess.id,
            interviewDate: date ? date.toISOString() : null,
          });
        })(),
        'Interview date saved successfully',
      );
    };

    const isLoading = loading === true;

    function checkIsDisabled() {
      if (isLoading) {
        return true;
      }

      // Admins can edit this field in every status
      if (isAdminView) {
        return false;
      }

      if (
        // Current user is the interviewer
        vettingProcess.vetter?.user.id === auth.user?.uid
      ) {
        return false;
      }

      return true;
    }

    const isDisabled = checkIsDisabled();

    return (
      <>
        <OutlinedDateInput
          selected={interviewDate}
          onChange={onInterviewDateChange}
          isDisabled={isDisabled}
          datePickerProps={{ portalId: 'root' }}
        />
        <LoadingIndicator loading={loading} />
      </>
    );
  });
