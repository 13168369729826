import React from 'react';
import MainLayout from '@src/layouts/Main';

const AStore = (): JSX.Element => {
  return (
    <MainLayout title="A.Store" style={{ padding: 0 }}>
      <iframe
        title="guide"
        is="x-frame-bypass"
        src={'https://astore.super.site'}
        width="100%"
        style={{ height: '100vh' }}
        frameBorder="0"
      />
    </MainLayout>
  );
};

export default AStore;
