import React, { ReactElement, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { AsyncSelect, SelectOption, TagList } from '@ateams/components';
import { TalentSkillRating } from '@a_team/models/dist/TalentCategories';
import { GenericTalent } from '@src/components/TeamGraphBuilderQuery';
import {
  getTalentMap,
  talentToSelectOption,
  getUpdatedTalents,
  removeTalentOverlap,
} from './mixedTalentListUtils';
import { TalentTag } from './TalentTag';

const MAX_LABEL_LENGTH = 25;

interface Props {
  onChange: (required: GenericTalent[], preferred: GenericTalent[]) => void;
  search: (searchTerm: string) => Promise<SelectOption[]>;
  preferred: GenericTalent[];
  preferredPlaceholder: string;
  required: GenericTalent[];
  requiredPlaceholder: string;
  requiredHasRatings?: boolean;
  sidebar?: boolean;
  isLoadingTags?: boolean;
}

const useStyles = createUseStyles({
  selectionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  selector: {
    maxWidth: 200,
    marginRight: 25,
    fontSize: 14,
  },
  sidebar: {
    maxWidth: 'unset',
    marginRight: 0,
    marginTop: 12,
  },
  tagList: {
    flexWrap: 'wrap',
    marginTop: '1em',
  },
  starIcon: {
    marginRight: 5,
  },
  closeIcon: {
    marginLeft: 3,
  },
  tag: {
    marginLeft: 0,
    marginRight: 8,
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const MixedTalentList = (props: Props): ReactElement => {
  const styles = useStyles();
  const {
    onChange,
    search,
    preferred,
    preferredPlaceholder,
    required,
    requiredPlaceholder,
    requiredHasRatings = false,
    sidebar = false,
    isLoadingTags = false,
  } = props;

  const [talentMap, preferredValue, requiredValue] = useMemo(
    () => [
      getTalentMap(preferred, required),
      preferred.map(talentToSelectOption),
      required.map(talentToSelectOption),
    ],
    [preferred, required],
  );

  const updateTalents = (
    selection: SelectOption | SelectOption[] | null,
    requiredTalent: boolean,
  ): void => {
    const updatedTalents: GenericTalent[] = getUpdatedTalents(
      selection,
      requiredTalent,
      talentMap,
    );
    if (requiredTalent) {
      const preferredTalents = removeTalentOverlap(preferred, updatedTalents);
      return onChange(updatedTalents, preferredTalents);
    }

    const requiredTalents = removeTalentOverlap(required, updatedTalents);
    onChange(requiredTalents, updatedTalents);
  };

  const onRatingChange = (
    talent: GenericTalent,
    newRating?: TalentSkillRating,
  ): void => {
    let updatedRequiredTalents;
    let updatedPreferredTalents;
    if (required.some((t) => t.id === talent.id)) {
      if (newRating) {
        updatedRequiredTalents = required.map((t) => {
          if (t.id === talent.id) {
            return { ...t, rating: newRating, id: `${talent.id}-${newRating}` };
          }
          return t;
        });
      } else {
        updatedRequiredTalents = required.filter((t) => t.id !== talent.id);
        updatedPreferredTalents = preferred.concat(talent);
      }
    }
    if (preferred.some((t) => t.id === talent.id)) {
      if (newRating) {
        updatedPreferredTalents = preferred.filter((t) => t.id !== talent.id);
        updatedRequiredTalents = required.concat({
          ...talent,
          rating: newRating,
          id: `${talent.id}-${newRating}`,
        });
      }
    }
    onChange(
      updatedRequiredTalents || required,
      updatedPreferredTalents || preferred,
    );
  };

  const removeTalent = (talentId: string): void => {
    onChange(
      required.filter((t) => t.id !== talentId),
      preferred.filter((t) => t.id !== talentId),
    );
  };

  const switchTalentPreferredRequired = (talent: GenericTalent): void => {
    const isRequired = required.some((t) => t.id === talent.id);
    if (isRequired) {
      onChange(
        required.filter((t) => t.id !== talent.id),
        preferred.concat(talent),
      );
    } else {
      onChange(
        required.concat(talent),
        preferred.filter((t) => t.id !== talent.id),
      );
    }
  };

  const talentTagList = [...required, ...preferred];

  return (
    <>
      <div className={styles.selectionContainer}>
        <AsyncSelect
          value={requiredValue}
          loadOptions={search}
          onChange={(options) => updateTalents(options, true)}
          placeholder={requiredPlaceholder}
          margin={'none'}
          className={cx(styles.selector, sidebar && styles.sidebar)}
          hideTags
          isMulti
        />
        <AsyncSelect
          value={preferredValue}
          loadOptions={search}
          onChange={(options) => updateTalents(options, false)}
          placeholder={preferredPlaceholder}
          margin={'none'}
          className={cx(styles.selector, sidebar && styles.sidebar)}
          hideTags
          isMulti
        />
      </div>
      {!isLoadingTags ? (
        <TagList className={styles.tagList}>
          {talentTagList.map((talent) => (
            <TalentTag
              key={talent.id + talent.rating}
              talent={talent}
              tagClassName={styles.tag}
              hasRatings={requiredHasRatings}
              starIconClassName={styles.starIcon}
              closeIconClassName={styles.closeIcon}
              maxLabelLength={MAX_LABEL_LENGTH}
              onRatingChange={(rating) => onRatingChange(talent, rating)}
              onClickOnRemove={() => removeTalent(talent.id)}
              onClickOnStar={() => switchTalentPreferredRequired(talent)}
            ></TalentTag>
          ))}
        </TagList>
      ) : (
        <div
          style={{ display: 'block', padding: '0.5rem', textAlign: 'center' }}
        >
          Loading...
        </div>
      )}
    </>
  );
};

export default MixedTalentList;
