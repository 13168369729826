import React, { ReactElement } from 'react';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import AdminTeamWorkMissionsList from '../AdminMissionsList/AdminMissionsList';

function AdminTeamWorkArchivedMissions(): ReactElement {
  const { missions } = useStores();

  return (
    <AdminTeamWorkMissionsList
      loadMore={() => missions.getAdminArchivedMissions(true)}
      showMore={!!missions.adminNextTokens.archivedMissions}
      type={'archived'}
      title={'Archived missions'}
      missions={missions.adminViewMissions.archivedMissions || []}
    />
  );
}

export default observer(AdminTeamWorkArchivedMissions);
