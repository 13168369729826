import { ServiceAuth } from '@ateams/service-utils';
import { ServiceEndpoint } from './utils';
import { QueryResult } from '@a_team/models/dist/misc';
import { Company, CompanyId, CompanyStatus } from '@a_team/models/dist/Company';

export const BasePath = '/companies';

export interface AutoCompleteCompanyData {
  searchTerm?: string;
  useEmployeesCollection?: boolean;
}

export interface UpdateCompanyData {
  status?: typeof CompanyStatus.Verified | typeof CompanyStatus.Denied;
  domain?: string;
  replaceWith?: string;
  name?: string;
}

export interface CreateCompanyData {
  name: string;
  domain?: string;
}

export default class CompaniesEndpoint extends ServiceEndpoint {
  public autoCompleteCompany(
    auth: ServiceAuth,
    data: AutoCompleteCompanyData,
  ): Promise<QueryResult<Company>> {
    return this.fetch(auth, `${BasePath}/`, data, 'get');
  }

  public verifyCompany(
    auth: ServiceAuth,
    companyId: CompanyId,
    domain: string,
  ): Promise<Company> {
    return this.fetch(auth, `${BasePath}/${companyId}`, null, 'PATCH', {
      status: CompanyStatus.Verified,
      domain,
    });
  }

  public denyCompany(
    auth: ServiceAuth,
    companyId: CompanyId,
    replaceWith?: string,
  ): Promise<Company> {
    return this.fetch(auth, `${BasePath}/${companyId}`, null, 'PATCH', {
      status: CompanyStatus.Denied,
      replaceWith,
    });
  }

  public renameCompany(
    auth: ServiceAuth,
    companyId: CompanyId,
    name: string,
  ): Promise<Company> {
    return this.fetch(auth, `${BasePath}/${companyId}`, null, 'PATCH', {
      name,
    });
  }

  public createCompany(
    auth: ServiceAuth,
    data: CreateCompanyData,
  ): Promise<Company> {
    return this.fetch(auth, `${BasePath}`, null, 'post', data);
  }

  public getCompany(auth: ServiceAuth, companyId: string): Promise<Company> {
    return this.fetch(auth, `${BasePath}/${companyId}`, null, 'get');
  }
}
