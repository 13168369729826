import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { CompanySize } from '@a_team/models/dist/vetting-processes/pre-vetting';
import { VettingProcessFeedbackCompanySizes } from '@a_team/models/dist/vetting-processes/feedback';
import { Spacing } from '@ateams/components';
import { CheckboxValueTag } from '../../components/value-tag';
import { TextInput } from '../../components/text-input';

const CompanySizeLabels: Record<CompanySize, string> = {
  [CompanySize.MarketPowerhouse]:
    'Market Powerhouse -- Google/Amazon/Facebook/Apple...',
  [CompanySize.Enterprise]: 'Enterprise -- 1,000+ employees',
  [CompanySize.Large]: 'Large Company -- 200 to 1,000 employees',
  [CompanySize.Medium]: 'Medium Company -- 30 to 200 employees',
  [CompanySize.Small]: 'Startup',
};

export type OnCompanySizesChange = (
  companySizes: VettingProcessFeedbackCompanySizes,
) => void;

export interface CompanySizesInputProps {
  value: VettingProcessFeedbackCompanySizes;
  onChange?: OnCompanySizesChange;
  isReadOnly?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  marginLeft: {
    marginLeft: Spacing.medium,
  },
});

export const CompanySizesInput: React.FC<CompanySizesInputProps> = (props) => {
  const { isReadOnly, value } = props;
  const styles = useStyles();

  const onCompanySizeChange = (
    companySize: CompanySize,
    isChecked: boolean,
  ) => {
    let newCompanySizes: VettingProcessFeedbackCompanySizes;
    if (isChecked) {
      newCompanySizes = [...value, { companySize, description: '' }];
    } else {
      newCompanySizes = value.filter(
        (currentCompanySize) => currentCompanySize.companySize !== companySize,
      );
    }

    props.onChange?.(newCompanySizes);
  };

  const onCompanySizeDescriptionChange = (
    companySize: CompanySize,
    description: string,
  ) => {
    const newCompanySizes = value.map((currentCompanySize) =>
      currentCompanySize.companySize === companySize
        ? { ...currentCompanySize, description }
        : currentCompanySize,
    );

    props.onChange?.(newCompanySizes);
  };

  return (
    <div className={props.className}>
      {Object.entries(CompanySizeLabels).map(([companySize, label]) => {
        const selectedCompanySize = value.find(
          ({ companySize: currentCompanySize }) =>
            currentCompanySize === companySize,
        );
        const isSelected = Boolean(selectedCompanySize);

        if (isReadOnly && !isSelected) {
          return null;
        }

        return (
          <>
            <CheckboxValueTag
              key={`${companySize}-CheckboxValueTag`}
              label={label}
              checked={isSelected}
              className={styles.marginBottom}
              onClick={(isChecked) =>
                onCompanySizeChange(companySize as CompanySize, isChecked)
              }
              isReadOnly={isReadOnly}
            />
            {isSelected && (
              <div
                key={`${companySize}-details`}
                className={cx(styles.marginLeft, styles.marginBottom)}
              >
                <TextInput
                  value={selectedCompanySize?.description}
                  onChange={(e) =>
                    onCompanySizeDescriptionChange(
                      companySize as CompanySize,
                      e.target.value,
                    )
                  }
                  placeholder={'Enter details...'}
                  isReadOnly={isReadOnly}
                  readOnlyText={'No details were added.'}
                />
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};
