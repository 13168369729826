import React from 'react';
import { createUseStyles } from 'react-jss';

interface TagProps {
  title: string;
  background: string;
  color?: string;
}

const useStyles = createUseStyles({
  wrapper: {
    backgroundColor: ({ background }: TagProps) => background,
    fontSize: 12,
    lineHeight: '16px',
    padding: '6px 10px',
    borderRadius: 8,
    color: ({ color }: TagProps) => color ?? '#222222',
  },
});

function Tag({ title, background, color }: TagProps) {
  const styles = useStyles({ background, color });
  return <span className={styles.wrapper}>{title}</span>;
}

export default Tag;
