import { PlatformServiceAnalytics } from '@ateams/analytics/dist/platform';
import {
  type LoginData,
  type LoginWithCredentials,
  type OAuthStartRequest,
  type OAuthStartResponse,
  type PasswordResetPayload,
  type PasswordResetRequest,
  type BuilderOnboardingStateModel,
  RegistrationResultType,
} from '@ateams/api/dist/endpoints/Auth';
import { ChangePasswordData } from '@ateams/api/dist/endpoints/User';
import { ServiceAuth } from '@ateams/api/dist/endpoints/utils';
import {
  ApiTokenPayload,
  ApiTokenPayloadData,
} from '@a_team/models/dist/ApiToken';
import type { AvailabilitySummaryObject } from '@a_team/models/dist/AvailabilityObject';
import {
  AvailabilityData,
  AvailableType,
} from '@a_team/models/dist/AvailabilityObject';
import LocationObject from '@a_team/models/dist/LocationObject';
import type UserObject from '@a_team/models/dist/UserObject';
import type {
  PromptAvailUpdateObject,
  RegisteredUserObject,
} from '@a_team/models/dist/UserObject';
import { OnboardingStage, UserScrubbed } from '@a_team/models/dist/UserObject';
import {
  CurrentUserObject,
  UserBadge,
  UserId,
  UserStatus,
  UserType,
  UserUsername,
} from '@a_team/models/dist/UserObject';
import TimezoneObject from '@a_team/models/src/TimezoneObject';
import { WorkingHoursSchema } from '@a_team/models/src/WorkingHoursObject';
import { DESIGNER_CATEGORY_ID, IS_LOCALHOST, IS_SANDBOX } from '@src/config';
import {
  apiAuth,
  apiRegistration,
  apiUser,
  apiUsers,
} from '@src/logic/services/endpoints';
import { Stores } from '@src/stores/index';
import jwtDecode from 'jwt-decode';
import { action, computed, observable } from 'mobx';

import { add, formatISO, isAfter, parseISO, subDays } from 'date-fns';
import Cookies from 'js-cookie';
import {
  FinishOnboardingLocation,
  RegistrationLocation,
  RootLocation,
  VerifyEmailLocation,
} from '@src/locations';
import { FeatureFlagNames } from '@a_team/models/dist/FeatureFlag';
import { PreferencesDtoDisabledCategoriesEnum } from '@a_team/user-notification-service-js-sdk';

export const TOKEN_COOKIE_NAME = `loginToken${IS_SANDBOX ? 'Sandbox' : ''}`;
const TOKEN_COOKIE_DOMAIN = IS_LOCALHOST ? '' : '.a.team';
const TOKEN_REFRESH_TIME = 2 * 22 * 3600e3; // Just under 2 days. Token expires after 2 days.

export type Flags = Record<string, boolean>;

export interface CookieManager {
  get(key: string): string | undefined;
  set(
    key: string,
    value: string,
    opts: {
      expires?: Date;
      domain?: string;
      httpOnly?: boolean;
      secure?: boolean;
      sameSite?:
        | 'none'
        | 'None'
        | 'strict'
        | 'lax'
        | 'Strict'
        | 'Lax'
        | undefined;
    },
  ): void;
  remove(key: string, opts?: { domain?: string }): void;
}

export interface AuthStoreData {
  currentUser: CurrentUserObject | RegisteredUserObject | null;
}

export interface AuthData extends ApiTokenPayloadData {
  token: string;
}

const showPrideModalCookie = 'ateam-show-pride-modal';

export default class AuthStore implements ServiceAuth, AuthStoreData {
  @observable private data?: AuthData;
  @observable private onboardingState?: BuilderOnboardingStateModel;
  @observable public currentUser: AuthStoreData['currentUser'] = null;
  @observable
  public promptAvailUpdate: PromptAvailUpdateObject = {} as PromptAvailUpdateObject;
  @observable public abFlagsList: Flags = {};
  @observable public userAbFlags: Flags = {};
  @observable private showPrideModalValue?: boolean;

  private cookie: CookieManager;
  private analytics: PlatformServiceAnalytics;
  private rootStore: Stores;

  public constructor(
    rootStore: Stores,
    cm: CookieManager,
    analytics: PlatformServiceAnalytics,
    initialState?: AuthStoreData,
  ) {
    this.rootStore = rootStore;
    this.cookie = cm;
    this.analytics = analytics;

    try {
      this.token = this.cookie.get(TOKEN_COOKIE_NAME) || null;
      this.showPrideModalValue = !Cookies.get(showPrideModalCookie);
    } catch (e) {
      console.warn(e);
    }

    if (initialState) {
      this.currentUser = initialState.currentUser;

      this.handleCurrentUser();
    }
  }

  @computed public get maybeAuth(): AuthStore | null {
    return this.data ? this : null;
  }

  @computed public get uid(): string | null {
    return this.data ? this.data.uid : null;
  }

  @computed public get email(): string | null {
    return this.data ? this.data.email : null;
  }

  @computed public get isAdmin(): boolean {
    return this.data?.isAdmin || false;
  }

  @computed public get isVetter(): boolean {
    return (
      this.data?.isVetter ||
      (this.currentUser &&
        'isVetter' in this.currentUser &&
        this.currentUser.isVetter) ||
      false
    );
  }

  @computed get withWorkingHours(): boolean {
    return this.isFeatureOpen(FeatureFlagNames.WorkingHours);
  }

  @computed get withWhatsNew(): boolean {
    return this.isFeatureOpen(FeatureFlagNames.WhatsNew);
  }

  @computed get withCompaniesInTargeter(): boolean {
    return this.isFeatureOpen(FeatureFlagNames.CompaniesInTargeter);
  }

  @computed get withNewOnboardingBannerWithLongProcessingTimes(): boolean {
    return this.isFeatureOpen(
      FeatureFlagNames.NewOnboardingBannerWithLongProcessingTimes,
    );
  }

  @computed get withAStore(): boolean {
    return this.isFeatureOpen(FeatureFlagNames.AStore);
  }

  @computed get withNewHubspotFlow(): boolean {
    return this.isFeatureOpen(FeatureFlagNames.Hubspot);
  }

  @computed get withMissionApplicationStatusV2(): boolean {
    return this.isFeatureOpen(FeatureFlagNames.MissionApplicationStatusV2);
  }

  @computed get withMissionApplicationAnalysis(): boolean {
    return this.isFeatureOpen(FeatureFlagNames.MissionApplicationAnalysis);
  }

  @computed get withBuilderLikes(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.BuilderLikes);
  }

  @computed get withWhatsNewBanner(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.WhatsNewBanner);
  }

  @computed get withNewEvaluation(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.NewEvaluation);
  }

  @computed get withTeamPulseNewQuestions(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.TeamPulseNewQuestions);
  }

  @computed get withProjectDescriptionAssistant(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.ProjectDescriptionAssistant);
  }

  @computed get withTeamUp(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.TeamUp);
  }

  @computed get withClientUserMgmt(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.ClientUserMgmt);
  }

  @computed get withSkipTeamUp(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.SkipTeamUp);
  }

  @computed get withApplicationPreSave(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.ApplicationPreSave);
  }

  @computed get withPitchWriterAssistant(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.PitchWriterAssistant);
  }

  @computed get withApplicationBuilderScoring(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.ApplicationBuilderScoring);
  }

  @computed get withProfileCompleteness(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.ProfileCompleteness);
  }

  @computed get withViewPastApplications(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.ViewPastApplications);
  }

  @computed get witCompanyQuestionRecommendation(): boolean | undefined {
    return this.isFeatureEnabled(
      FeatureFlagNames.CompanyQuestionRecommendation,
    );
  }

  @computed get withNewTimesheets(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.NewTimesheets);
  }

  @computed get withAiChatCta(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.AiChatCta);
  }

  @computed get withRoleKeywords(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.RoleKeywords);
  }

  @computed get withNewCalendarLinkingFlow(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.NewCalendarLinkingFlow);
  }

  @computed get withSaveApplicationProgress(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.SaveApplicationProgress);
  }

  @computed get withGenerateAboutMeSuggestionOnDemand(): boolean | undefined {
    return this.isFeatureEnabled(
      FeatureFlagNames.GenerateAboutMeSuggestionOnDemand,
    );
  }

  @computed get withMissionMonthlyRates(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.MissionMonthlyRates);
  }

  @computed get withNewProjectExperienceFields(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.NewProjectExperienceFields);
  }

  @computed get withMultipleIndustriesForExperiences(): boolean | undefined {
    return this.isFeatureEnabled(
      FeatureFlagNames.MultipleIndustriesForExperiences,
    );
  }

  @computed get withMonthlyRetainerInvoicing(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.MonthlyRetainerInvoicing);
  }

  @computed get withLimitedAccess(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.LimitedAccess);
  }

  @computed get withNewJobs(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.NewJobs);
  }

  @computed get withRelatedExperiences(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.RelatedExperiences);
  }

  @computed get withRequestExtension(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.RequestExtension);
  }

  @computed get withSharedCalendarFlow(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.SharedCalendarFLow);
  }

  @computed get withNewMissionControlAllMissionsSorting(): boolean | undefined {
    return this.isFeatureEnabled(
      FeatureFlagNames.NewMissionControlAllMissionsSorting,
    );
  }

  @computed get withLocationMismatch(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.LocationMismatch);
  }

  @computed get withBuilderRatings(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.BuilderRatings);
  }

  @computed get withSaveSkillsRightAwayOnApplication(): boolean | undefined {
    return this.isFeatureEnabled(
      FeatureFlagNames.SaveSkillsRightAwayOnApplication,
    );
  }

  @computed get withSaveRolesRightAwayOnApplication(): boolean | undefined {
    return this.isFeatureEnabled(
      FeatureFlagNames.SaveRolesRightAwayOnApplication,
    );
  }

  @computed get withSaveApplicationProgressProjects(): boolean | undefined {
    return this.isFeatureEnabled(
      FeatureFlagNames.SaveApplicationProgressProjects,
    );
  }

  @computed get withCustomRoleMarkups(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.CustomRoleMarkups);
  }

  @computed get withInvitePage(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.InvitePage);
  }

  @computed get withOnboardingV2(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.OnboardingV2);
  }

  @computed get withSkillsSelectorInline(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.SkillsSelectorInline);
  }

  @computed get withRemoveFeatureSkillOption(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.RemoveFeatureSkillOption);
  }

  @computed get withNewCalendarLoginFlow(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.NewCalendarLoginFlow);
  }

  @computed get withNewEvaluationFeedbackForm(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.NewEvaluationFeedbackForm);
  }

  @computed get withNewRoleExpertiseScore(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.NewRoleExpertiseScore);
  }

  @computed get withSuggestedProjectsOnApplication(): boolean | undefined {
    return this.isFeatureEnabled(
      FeatureFlagNames.SuggestedProjectsOnApplication,
    );
  }

  @computed get withPaymentMethod(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.PaymentMethod);
  }

  @computed get withNewCalendarFlow(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.NewCalendarFlow);
  }

  @computed get withGenerateAboutMeSuggestionOnApplication():
    | boolean
    | undefined {
    return this.isFeatureEnabled(
      FeatureFlagNames.GenerateAboutMeSuggestionOnApplication,
    );
  }

  @computed get withSuggestProjectsFromURlOrPDF(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.SuggestProjectsFromURLOrPDF);
  }
  @computed get withMissionControlOptimization(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.MissionControlOptimization);
  }

  @computed get withContractsV2(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.ContractsV2);
  }

  @computed get withRoleDescriptionRichText(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.RoleDescriptionRichText);
  }

  @computed get withLogoOnNav(): boolean | undefined {
    return this.isFeatureEnabled(FeatureFlagNames.LogoOnNav);
  }

  @computed public get canSwitchUser(): boolean {
    return this.data ? !!(this.data.isAdmin || this.data.adminUser) : false;
  }

  @computed public get abFlags(): CurrentUserObject['abFlags'] | undefined {
    return this.currentUser && 'abFlags' in this.currentUser
      ? this.currentUser.abFlags
      : undefined;
  }

  @computed public get bearerToken(): string {
    if (!this.data) {
      // throwing error would break the UI, but an empty token simply signs out the user
      return '';
    }

    return this.data.token;
  }

  @computed public get userType(): UserType | null {
    return this.data ? this.data.type || UserType.User : null;
  }

  @computed public get userStatus(): UserStatus | null {
    return this.data ? this.data.status || UserStatus.Active : null;
  }

  @computed public get isActiveWaitlistedAdVerified(): boolean {
    return !!(
      this.userStatus === UserStatus.ActiveWaitlisted &&
      this.user?.scrubbed &&
      [UserScrubbed.Verified, UserScrubbed.Exceptional].includes(
        this.user?.scrubbed,
      )
    );
  }

  @computed public get verified(): boolean | null {
    return this.data ? !this.data.unverified : null;
  }

  @computed public get isActive(): boolean {
    return (
      !!this.username &&
      (this.userStatus === UserStatus.Active ||
        (this.userStatus === UserStatus.ActiveWaitlisted &&
          !!this.withOnboardingV2))
    );
  }

  @computed public get isActiveWaitlisted(): boolean {
    return !!this.username && this.userStatus === UserStatus.ActiveWaitlisted;
  }

  @computed public get isActiveBuilder(): boolean {
    return this.isActive && this.userType === UserType.User;
  }

  @computed public get isActiveCompanyUser(): boolean {
    return this.isActive && this.userType === UserType.CompanyUser;
  }

  @computed public get restrictedAccess(): boolean {
    if (this.data?.restrictedAccess) {
      return true;
    }

    return !!this.username && this.userStatus === UserStatus.Rejected;
  }

  @computed public get isRejected(): boolean {
    return !!this.username && this.userStatus === UserStatus.Rejected;
  }

  @computed public get basicAccess(): boolean {
    if (this.currentUser?.lacksInformation) {
      return false;
    }

    return (
      this.isActive &&
      !this.data?.restrictedAccess &&
      (this.data?.basicAccess || (this.currentUser?.wasScrubbed ?? false))
    );
  }

  @computed public get limitedAccess(): boolean {
    return !!(
      this.isActive &&
      this.data?.limitedAccess &&
      this.withLimitedAccess &&
      !this.currentUser?.badges?.includes(UserBadge.BeenOnMission)
    );
  }

  @computed public get onboardingCompleted(): boolean {
    /*
     * The onboardingCompleted flag is part of a new onboarding flow.
     * If it's undefined, it means the user is not part of this new flow,
     * so we treat them as having completed onboarding.
     * If it's defined, we use its value to determine if onboarding is completed.
     * All users will have this flag when they log in again on the platform.
     */
    return (
      !this.currentUser?.onboardingStage ||
      this.currentUser.onboardingStage === OnboardingStage.Completed
    );
  }

  @computed public get fullAccess(): boolean {
    return this.isActive && (this.data?.fullAccess ?? false);
  }

  @computed public get readonlyAccess(): boolean {
    return this.isActive && Boolean(this.data?.readonly);
  }

  @computed public get onboardingLocation(): string {
    switch (this.onboardingState?.resultType) {
      case RegistrationResultType.CANDIDATE_VERIFIED:
        return `${FinishOnboardingLocation}?token=${encodeURI(
          atob(this.onboardingState.token ?? ''),
        )}`;
      case RegistrationResultType.CANDIDATE_VERIFICATION_PENDING:
        return `${VerifyEmailLocation}?email=${encodeURI(
          this.currentUser?.email ?? '',
        )}`;
      default:
        return RegistrationLocation;
    }
  }

  @computed public get newOnboardingFlow(): boolean {
    return this.onboardingState?.resultType !== undefined;
  }

  @computed public get token(): string | null {
    return this.data ? this.data.token : null;
  }

  public set token(token: string | null) {
    if (!token) {
      this.setData(undefined);
      this.cookie.remove(TOKEN_COOKIE_NAME, {
        domain: TOKEN_COOKIE_DOMAIN,
      });
      return;
    }

    const tokenPayload = jwtDecode(token) as ApiTokenPayload & {
      exp: number;
    };

    if (
      !tokenPayload ||
      !tokenPayload.uid ||
      !tokenPayload.email ||
      !tokenPayload.exp
    ) {
      throw new TypeError(
        `Invalid token payload: ${JSON.stringify(jwtDecode(token))}`,
      );
    }

    this.setData({
      token,
      ...tokenPayload,
    });

    const expires = new Date(tokenPayload.exp * 1000);

    this.cookie.set(TOKEN_COOKIE_NAME, token, {
      expires,
      domain: TOKEN_COOKIE_DOMAIN,
      httpOnly: false, // our client side JS needs to read the cookie so we can't set this flag to true
      secure: !IS_LOCALHOST,
      sameSite: 'lax',
    });

    if (
      typeof window === 'object' &&
      expires.getTime() < Date.now() - TOKEN_REFRESH_TIME
    ) {
      setTimeout((): void => {
        if (this.token !== token) return;

        this.renewToken().catch((err): void => {
          console.warn(`Error while auto-renew token:`, err);
        });
      }, 2e3);
    }
  }

  public invalidate(): void {
    this.token = null;
  }

  public getData(): AuthData | null {
    return this.data ? { ...this.data } : null;
  }

  /**
   * @deprecated please use isFeatureEnabled instead
   */
  public isFeatureOpen(featureName: FeatureFlagNames): boolean {
    const userFeatures = this.currentUser?.featureFlags?.find(
      (feature) => feature.name === featureName,
    );
    return !!userFeatures && userFeatures.isOpen;
  }

  public isFeatureEnabled(featureName: FeatureFlagNames): boolean | undefined {
    if (!this.currentUser) {
      return undefined;
    }

    const userFeatures = this.currentUser?.featureFlags?.find(
      (feature) => feature.name === featureName,
    );

    return !!userFeatures && userFeatures.isOpen;
  }

  @action private setData(data: AuthData | undefined): void {
    this.data = data;
  }

  @action private signInUserAction(
    user: RegisteredUserObject,
    token: string,
  ): void {
    this.analytics.trackSignIn(user);
    // when user is signed in, in set user - if scrub value changed we can renew token
    this.token = token;
    this.setUser(user);
  }

  @computed public get user(): CurrentUserObject | null {
    return this.currentUser?.uid === this.data?.uid &&
      (this.currentUser?.status === UserStatus.Active ||
        this.currentUser?.status === UserStatus.ActiveWaitlisted ||
        this.currentUser?.status === UserStatus.Rejected)
      ? (this.currentUser as CurrentUserObject)
      : null;
  }

  @computed public get onboardingStage(): OnboardingStage | undefined {
    if (this.currentUser) {
      return this.currentUser.onboardingStage;
    }
    return undefined;
  }

  @computed public get registeredUser(): RegisteredUserObject | null {
    return this.currentUser?.uid === this.data?.uid
      ? (this.currentUser as RegisteredUserObject)
      : null;
  }

  @computed public get username(): string | null {
    return this.data?.username || null;
  }

  @computed public get isCurrentUserDesigner(): boolean {
    return (
      this.currentUser?.talentProfile?.talentSpecializations?.mainTalentSpecialization?.talentCategoryIds.includes(
        DESIGNER_CATEGORY_ID,
      ) ?? false
    );
  }

  @computed
  public get isReSubscriptionReminderEnabledForMissionNotifications(): boolean {
    return (this.currentUser as CurrentUserObject)
      ?.reSubscriptionReminderActiveForCategory ===
      PreferencesDtoDisabledCategoriesEnum.MissionNotification
      ? true
      : false;
  }

  @action public unsetReSubscriptionReminderActiveForCategory(): void {
    if (this.currentUser) {
      (
        this.currentUser as CurrentUserObject
      ).reSubscriptionReminderActiveForCategory = undefined;
    }
  }

  @action public setUser(
    user: CurrentUserObject | RegisteredUserObject | null,
  ): void {
    // Renew user token if the scrub value changed or onboarding stage changed
    if (
      this.currentUser &&
      user &&
      (user?.verified !== this.currentUser?.verified ||
        user.onboardingStage !== this.currentUser?.onboardingStage)
    ) {
      this.renewToken();
    }
    this.currentUser = user;
    this.rootStore.rewards.fetchCurrentScore();
    this.rootStore.missionControl.filterByUserSettings(user);
    this.handleCurrentUser();
  }

  @action public setAcceptTOS(): void {
    if (!this.user) return;
    this.user.needsAcceptTOS = undefined;
  }

  @action public showWelcomePage(): void {
    if (!this.user) return;
    this.user.needsWelcomePage = true;
  }

  @action public dismissWelcomePage(): void {
    if (!this.user) return;
    this.user.needsWelcomePage = false;
  }

  @action public onUserChange(user: UserObject): void {
    if (!this.currentUser || this.currentUser.uid !== user.uid) {
      return;
    }

    this.currentUser = {
      ...this.currentUser,
      ...user,
    };
  }

  @action
  public dismissPrideModal(): void {
    this.showPrideModalValue = false;
    Cookies.set(showPrideModalCookie, 'viewed', {
      expires: add(new Date(), {
        years: 1,
      }),
    });
  }

  public async fetchData(reload = false): Promise<void> {
    if (!this.data || (this.currentUser && !reload)) {
      return Promise.resolve();
    }

    return apiUser.getCurrentUser(this).then(
      (user) => {
        this.setUser(user);
      },
      (err) => {
        if (err.code === 404) {
          this.token = null;
        }

        throw err;
      },
    );
  }

  public registerCompanyByRequest(
    ...args: Parameters<typeof apiRegistration.registerCompanyByRequest>
  ): Promise<CurrentUserObject | RegisteredUserObject> {
    return apiRegistration
      .registerCompanyByRequest(...args)
      .then(({ token, user }) => {
        this.analytics.trackSignUp(user);
        this.token = token;
        this.setUser(user);

        return user;
      });
  }

  public login(
    data: LoginData,
  ): Promise<CurrentUserObject | RegisteredUserObject> {
    return apiAuth.login(data).then(({ token, user, onboardingState }) => {
      this.signInUserAction(user, token);
      this.onboardingState = onboardingState;
      return user;
    });
  }

  public acceptToS(auth: ServiceAuth): Promise<void> {
    return apiUser.acceptTOS(auth).then(() => {
      if (this.user) {
        this.setAcceptTOS();
      }
    });
  }

  public acceptPlatformCodeOfConduct(auth: ServiceAuth): Promise<void> {
    return apiUser
      .acceptPlatformCodeOfConduct(auth)
      .then(() => {
        if (this.user) {
          this.user.needsAcceptPlatformCodeOfConduct = false;
        }
      })
      .catch((err) => {
        if (
          err?.message === 'Platform code of conduct already accepted' &&
          this.user
        ) {
          this.user.needsAcceptMissionCodeOfConduct = false;
        }
      });
  }

  public dismissTimesheetInitiativesGuidance(auth: ServiceAuth): Promise<void> {
    return apiUser
      .dismissTimesheetInitiativesGuidance(auth)
      .then(() => {
        if (this.user) {
          this.user.needsTimesheetInitiativesGuidance = false;
        }
      })
      .catch((err) => {
        if (
          err?.message ===
            'Error dismissing the timesheet initiatives guidance.' &&
          this.user
        ) {
          this.user.needsTimesheetInitiativesGuidance = false;
        }
      });
  }

  public setWhatsNewModalAsDisplayed(auth: ServiceAuth): Promise<void> {
    return apiUser
      .setWhatsNewModalAsDisplayed(auth)
      .then(() => {
        if (this.user) {
          this.user.needsWhatsNewModal = false;
        }
      })
      .catch((err) => {
        if (err?.message === 'WhatsNew modal already displayed' && this.user) {
          this.user.needsWhatsNewModal = false;
        }
      });
  }

  public setWhatsNewBannerAsDisplayed(auth: ServiceAuth): Promise<void> {
    return apiUser
      .setWhatsNewBannerAsDisplayed(auth)
      .then(() => {
        if (this.user) {
          this.user.needsWhatsNewBanner = false;
        }
      })
      .catch((err) => {
        if (err?.message === 'WhatsNew modal already displayed' && this.user) {
          this.user.needsWhatsNewBanner = false;
        }
      });
  }

  public handleProfileCompleteness(
    auth: ServiceAuth,
    data: {
      haveNotHadThreeJobs?: boolean;
      dismissed?: boolean;
    },
  ): Promise<void> {
    return apiUser
      .handleProfileCompleteness(auth, data)
      .then(() => {
        if (this.user) {
          this.user.profileCompleteness = data;
        }
        this.fetchData(true);
      })
      .catch((err) => {
        console.warn(err);
      });
  }

  public acceptMissionCodeOfConduct(auth: ServiceAuth): Promise<void> {
    return apiUser
      .acceptMissionCodeOfConduct(auth)
      .then(() => {
        if (this.user) {
          this.user.needsAcceptMissionCodeOfConduct = false;
        }
      })
      .catch((err) => {
        if (
          err?.message === 'Mission code of conduct already accepted' &&
          this.user
        ) {
          this.user.needsAcceptMissionCodeOfConduct = false;
        }
      });
  }

  public oauthStart(data: OAuthStartRequest): Promise<OAuthStartResponse> {
    return apiAuth.oauthStart(data);
  }

  public loginWith(
    data: LoginWithCredentials,
  ): Promise<CurrentUserObject | RegisteredUserObject> {
    return apiAuth.loginWith(data).then(({ token, user, onboardingState }) => {
      this.signInUserAction(user, token);
      this.onboardingState = onboardingState;
      return user;
    });
  }

  public loginAs(
    username: UserUsername,
  ): Promise<CurrentUserObject | RegisteredUserObject> {
    return apiAuth.loginAs(this, username).then(({ token, user }) => {
      this.token = token;
      this.setUser(user);

      return user;
    });
  }

  public getUserAbFlags(auth: ServiceAuth, username: UserUsername): void {
    apiUsers.getUserAbFlags(auth, username).then((data) => {
      this.userAbFlags = { ...this.abFlagsList, ...data };
    });
  }

  public assignUserAbFlags = async (
    auth: ServiceAuth,
    username: UserUsername,
    payload: Flags,
  ): Promise<void> => {
    const flags = await apiUsers.assignUserAbFlags(auth, username, payload);
    this.setABFlags(flags);
  };

  @action setABFlags = (flags: Flags): void => {
    this.userAbFlags = { ...this.abFlagsList, ...flags };
  };

  @computed get availability(): AvailabilitySummaryObject | undefined {
    if (
      this.currentUser &&
      'createdAt' in this.currentUser /** Checks if user is registered */
    ) {
      return this.currentUser.availability;
    }
    return undefined;
  }

  @action public updateOnboardingStage = (
    onboardingStage: OnboardingStage,
  ): void => {
    if (this.currentUser && 'onboardingStage' in this.currentUser) {
      this.currentUser.onboardingStage = onboardingStage;
    }
  };

  public updateAvailabilityHours = async (
    hours: number | undefined,
    rejectedUpdatePrompt: boolean | undefined,
  ): Promise<void> => {
    if (
      this.availability &&
      this.availability.type !== AvailableType.UnknownAvailability
    ) {
      const {
        type,
        weeklyHoursAvailable,
        availableFrom,
        keepNotifications,
        remindMeAt,
        notes,
      } = this.availability;
      const data: Partial<AvailabilityData> = {
        type: type,
      };

      if (data.type === AvailableType.Now) {
        hours
          ? (data.weeklyHoursAvailable = hours)
          : (data.weeklyHoursAvailable = weeklyHoursAvailable);
      }

      if (data.type === AvailableType.FutureDate) {
        data.weeklyHoursAvailable = weeklyHoursAvailable || 0;
        data.date = availableFrom;
        if (hours) data.weeklyHoursAvailable = hours;
      }

      if (data.type === AvailableType.NotAvailable) {
        data.keepNotifications =
          keepNotifications === true
            ? keepNotifications
            : undefined; /** For a weird reason instead of using false we use undefined */
        data.remindMeAt = remindMeAt;
      }
      if (rejectedUpdatePrompt) {
        data.rejectedUpdatePrompt = formatISO(Date.now());
      }
      if (notes) {
        data.notes = notes;
      }
      const availabilitySummary = await apiUser.updateAvailability(
        this,
        data as AvailabilityData,
      );

      this.setAvailability(availabilitySummary);
      this.setPromptAvailUpdate(false, undefined, undefined);
    }
  };

  public updateAvailability = async (
    availability: AvailabilityData,
  ): Promise<void> => {
    if (availability.type === AvailableType.UnknownAvailability) {
      return;
    }
    const availabilitySummary = await apiUser.updateAvailability(
      this,
      availability,
    );
    this.setAvailability(availabilitySummary);
  };

  @action private setAvailability(
    availabilitySummary: AvailabilitySummaryObject,
  ): boolean {
    if (
      this.currentUser &&
      'createdAt' in this.currentUser /** Checks if user is registered */
    ) {
      this.currentUser.availability = availabilitySummary;
      // Update availability in profile as well
      this.rootStore.users.profile?.setAvailability(availabilitySummary);
      return true;
    }

    return false;
  }

  @action private setPromptAvailUpdate(
    shouldUpdate: boolean,
    profileHours: number | undefined,
    applicationHours: number | undefined,
  ): boolean {
    if (
      this.currentUser &&
      'createdAt' in this.currentUser /** Checks if user is registered */
    ) {
      this.promptAvailUpdate = {
        shouldUpdate: shouldUpdate,
        profileHours: profileHours ? profileHours : 0,
        applicationHours: applicationHours ? applicationHours : 0,
      };
      return true;
    }

    return false;
  }

  handleAvailabilityChangeOnSubmit = (newAvail: number | undefined): void => {
    const originalAvail = this.availability;
    const profileHours = originalAvail?.weeklyHoursAvailable;
    const rejectedUpdatePrompt = originalAvail?.rejectedUpdatePrompt;

    if (
      rejectedUpdatePrompt &&
      isAfter(parseISO(rejectedUpdatePrompt), subDays(Date.now(), 7))
    ) {
      return;
    }

    if (profileHours && newAvail && profileHours !== newAvail) {
      this.setPromptAvailUpdate(true, profileHours, newAvail);
      return;
    }
    return;
  };

  public requestPasswordReset(data: PasswordResetRequest): Promise<void> {
    return apiAuth.requestPasswordReset(data);
  }

  public resetPassword(uid: UserId, data: PasswordResetPayload): Promise<void> {
    return apiAuth.resetPassword(uid, data).then(({ token, user }) => {
      this.signInUserAction(user, token);
    });
  }

  public changePassword(
    data: ChangePasswordData,
  ): Promise<void | CurrentUserObject> {
    return apiUser.changePassword(this, data).then(() => {
      this.token = null;
      this.setUser(null);
    });
  }

  public renewToken(): Promise<void> {
    return apiAuth.renewToken(this).then((res): void => {
      this.token = res.token;
    });
  }

  public canScheduleCall(auth: ServiceAuth): Promise<boolean> {
    try {
      return apiUser.canScheduleCall(auth);
    } catch (err) {
      return Promise.resolve(false);
    }
  }

  public updateOptedOutOfClientDiscovery = (
    optedOutOfClientDiscovery: boolean,
  ) => {
    if (this.currentUser) {
      this.currentUser.optedOutOfClientDiscovery = optedOutOfClientDiscovery;
    }
  };

  public updateOptedOutOfBuilderDiscovery = (
    optedOutOfBuilderDiscovery: boolean,
  ) => {
    if (this.currentUser) {
      this.currentUser.optedOutOfBuilderDiscovery = optedOutOfBuilderDiscovery;
    }
  };

  public handleCurrentUser(): void {
    if (this.currentUser?.outdatedToken && this.data) {
      // prevent fetching in a loop in case somethings goes wrong
      delete this.currentUser.outdatedToken;

      setTimeout(
        () =>
          this.renewToken().catch((err) =>
            console.warn('failed renew outdated token:', err),
          ),
        2e3,
      );
    }
  }

  public revokeToken(): void {
    this.token = null;
    this.setUser(null);
    window.location.replace(RootLocation);
  }

  public logout(allowReuse = false): Promise<void> {
    if (!this.data) {
      if (allowReuse) {
        return Promise.resolve();
      }

      return Promise.reject(new Error(`User already logged out`));
    }

    return apiAuth.logout(this).then((): void => {
      this.token = null;
      this.setUser(null);
    });
  }

  public serialize(): AuthStoreData {
    return {
      currentUser: this.currentUser,
    };
  }

  public updateLocation = async (location: LocationObject): Promise<void> => {
    const user = await apiUser.updatePersonalInfo(this, { location });
    this.setUser(user);
    this.rootStore.users.profile?.setLocation(location);
  };

  public updateTimezoneAndWorkingHours = async (
    timezone: TimezoneObject,
    workingHours: WorkingHoursSchema,
  ): Promise<void> => {
    const user = await apiUser.updatePersonalInfo(this, {
      timezone,
      workingHours,
    });
    this.setUser(user);
    this.rootStore.users.profile?.setTimezone(timezone);
  };

  public updateUserUnsupportedCalendarName = async (
    calendarName: string,
  ): Promise<void> => {
    const user = await apiUser.updateUserUnsupportedCalendarName(
      this,
      calendarName,
    );
    this.setUser(user);
  };

  public redeemIdentityToken = async (
    identityToken: string,
  ): Promise<CurrentUserObject | RegisteredUserObject> => {
    return apiAuth
      .loginUserWithIdentityToken(identityToken)
      .then(({ token, user, onboardingState }) => {
        this.signInUserAction(user, token);
        this.onboardingState = onboardingState;
        return user;
      });
  };
}
