import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import Colors, { ColorName, TextColors } from '../theme/colors';
import Icon, { IconType } from '../Icon';
import { TagList } from './TagList';
import { SelectableTagList } from './SelectableList';
import { FontSizes } from '../theme/styles';

export interface TagProps {
  style?: CSSProperties;
  children: ReactNode;
  thin?: boolean;
  onClick?: (e?: React.SyntheticEvent) => void;
  color?: ColorName;
  square?: boolean;
  onRemove?(): void;
  className?: string;
  iconTitle?: string;
  'data-testing-id'?: string;
  borderColor?: ColorName;
  fontSize?: keyof typeof FontSizes;
}

const defaultProps = {
  square: false,
  onRemove: undefined,
};

const useStyles = createUseStyles({
  root: {
    background: (props: TagProps): string => Colors[props.color || 'tag'],
    borderRadius: (props: TagProps): number => (props.square ? 4 : 104),
    fontSize: (props: TagProps): string =>
      FontSizes[props.fontSize || 'small'] as string,
    lineHeight: '16px',
    color: (props: TagProps): string => TextColors[props.color || 'tag'],
    display: 'inline-block',
    padding: (props: TagProps): string =>
      props.thin ? '4px 10px' : '8px 16px',
    border: (props: TagProps): string =>
      props.borderColor
        ? `1px solid ${Colors[props.borderColor || 'tag']}`
        : 'none',
  },
  close: {
    display: 'inline-block',
    cursor: 'pointer',
    fontSize: '0.6em',
    marginLeft: 9,
    verticalAlign: 'middle',
  },
});

export { TagList, SelectableTagList };

const Tag = (props: TagProps): ReactElement => {
  const { children, onRemove, className, iconTitle, square, ...left } = props;
  const styles = useStyles(props);

  return (
    <div className={cx(styles.root, className)} {...left}>
      {children}
      {onRemove && (
        <Icon
          title={iconTitle}
          type={IconType.Close}
          className={styles.close}
          onClick={onRemove}
        />
      )}
    </div>
  );
};

Tag.defaultProps = defaultProps;
export default Tag;
