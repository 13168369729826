import {
  BorderColors,
  Breakpoints,
  Colors,
  TextColors,
} from '@ateams/components';
import { Tooltip } from '@a_team/ui-components';
import { AnswerScore } from '@src/components/AnswerScore';
import cx from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Option } from '../utils';

interface RatingProps {
  value: AnswerScore;
  selectedSkill: Option;
  selectedSkills: Option[];
  setSelectedSkills: (skills: Option[]) => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 500,
    border: `1px solid ${BorderColors.lighter}`,
    borderRadius: 6,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    width: '20%',
    height: 40,
    [`@media (min-width: ${Breakpoints.sm}px)`]: {
      width: 32,
      height: 32,
    },
  },
  notSelected: {
    backgroundColor: 'white',
    color: TextColors.regular,
    '&:hover': {
      backgroundColor: '#f3e6ff',
    },
  },
  selected: {
    backgroundColor: Colors.secondaryDark,
    color: TextColors.secondary,
  },
});

const getRatingDescription = (value: AnswerScore): string => {
  switch (value) {
    case 1:
      return 'No experience';
    case 5:
      return 'Deep expertise';
    default:
      return '';
  }
};

const Rating: React.FC<RatingProps> = ({
  value,
  selectedSkill,
  selectedSkills,
  setSelectedSkills,
}) => {
  const isSelected = selectedSkill.rating === value;
  const styles = useStyles();
  const tooltipText = getRatingDescription(value);

  const handleClick = () => {
    const updatedSkills = selectedSkills.map((skill) =>
      skill.value === selectedSkill.value ? { ...skill, rating: value } : skill,
    );
    setSelectedSkills(updatedSkills);
  };

  return (
    <Tooltip
      text={<div>{getRatingDescription(value)}</div>}
      placement="top"
      disabled={!tooltipText}
    >
      <div
        className={cx(styles.container, {
          [styles.notSelected]: !isSelected,
          [styles.selected]: isSelected,
        })}
        onClick={handleClick}
      >
        {value}
      </div>
    </Tooltip>
  );
};

export default Rating;
