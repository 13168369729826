import React, { ReactElement } from 'react';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import AdminTeamWorkMissionsList from '../AdminMissionsList/AdminMissionsList';

function AdminTeamWorkRunningMissions(): ReactElement {
  const { missions } = useStores();

  return (
    <AdminTeamWorkMissionsList
      loadMore={() => missions.getAdminRunningMissions(true)}
      showMore={!!missions.adminNextTokens.runningMissions}
      type={'archived'}
      title={'Running missions'}
      missions={missions.adminViewMissions.runningMissions || []}
    />
  );
}

export default observer(AdminTeamWorkRunningMissions);
