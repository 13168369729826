import { ServiceAuth, ServiceEndpoint } from './utils';
import { QueryRequestPayload, QueryResult } from '@a_team/models/dist/misc';
import {
  TalentCategory,
  TalentCategoryId,
} from '@a_team/models/dist/TalentCategories';

export const BasePath = '/talent-categories';

export interface TalentCategoriesQueryData extends QueryRequestPayload {
  ids?: string; // Comma separated list of talent ids
  parentCategoryId?: TalentCategoryId;
  textId?: string;
  searchTerm?: string;
  isVettingEligible?: boolean;
}

export interface TalentCategoryData {
  name: string;
}

export default class TalentCategoriesEndpoint extends ServiceEndpoint {
  public queryTalentCategories(
    auth: ServiceAuth,
    query: TalentCategoriesQueryData,
  ): Promise<QueryResult<TalentCategory>> {
    return this.fetch(auth, BasePath, { ...query });
  }

  public getTalentCategoryById(
    talentCategoryId: string,
  ): Promise<TalentCategory> {
    return this.fetch(null, `${BasePath}/${talentCategoryId}`, null, 'get');
  }

  public createTalentCategory(
    auth: ServiceAuth,
    data: TalentCategoryData,
  ): Promise<QueryResult<TalentCategory>> {
    return this.fetch(auth, BasePath, null, 'post', data);
  }

  public updateTalentCategory(
    talentCategoryId: string,
    auth: ServiceAuth,
    data: TalentCategoryData,
  ): Promise<QueryResult<TalentCategory>> {
    return this.fetch(
      auth,
      `${BasePath}/${talentCategoryId}`,
      null,
      'PATCH',
      data,
    );
  }

  public deleteTalentCategory(
    talentCategoryId: string,
    auth: ServiceAuth,
  ): Promise<void> {
    return this.fetch(auth, `${BasePath}/${talentCategoryId}`, null, 'delete');
  }
}
