import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { SelectableUser } from './AdminTable';

interface UserTabsProps {
  allSelectableUsers: SelectableUser[];
  setSelectedUser: (user: SelectableUser | undefined) => void;
  selectedUser: SelectableUser | undefined;
}

const useStyles = createUseStyles({
  teamMemberAvatarContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  teamMemberAvatar: {
    margin: '-4px',
    height: 18,
    width: 18,
    borderRadius: '50%',
    border: '1px solid white',
  },
  teamMember: {
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    cursor: 'pointer',
    paddingBottom: 16,
  },
  userList: {
    display: 'flex',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    gap: 24,
    padding: '16px 16px 0 16px',
    borderTop: '1px solid #DADADC',
    borderLeft: '1px solid #DADADC',
    borderRight: '1px solid #DADADC',
    marginBottom: 0,
  },
  selected: {
    borderBottom: '2px solid #7000E3;',
  },
});

const UserTabs = ({
  allSelectableUsers,
  setSelectedUser,
  selectedUser,
}: UserTabsProps): JSX.Element => {
  const styles = useStyles();
  return (
    <ul className={styles.userList}>
      <li
        className={cx(styles.teamMember, {
          [styles.selected]: !selectedUser,
        })}
        onClick={() => {
          setSelectedUser(undefined);
        }}
      >
        <div className={styles.teamMemberAvatarContainer}>
          {allSelectableUsers.map((selectableUser) => {
            if (selectableUser) {
              return (
                <div
                  key={selectableUser?.user?.uid}
                  className={styles.teamMemberAvatarContainer}
                >
                  <img
                    className={styles.teamMemberAvatar}
                    alt="Team Architect"
                    src={selectableUser.user.profilePictureURL}
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
        Team timesheet
      </li>
      {allSelectableUsers.map((selectableUser) => (
        <li
          key={selectableUser.user.uid}
          className={cx(styles.teamMember, {
            [styles.selected]:
              selectedUser?.user?.username === selectableUser.user.username,
          })}
          onClick={() => {
            setSelectedUser(selectableUser);
          }}
        >
          <div className={styles.teamMemberAvatarContainer}>
            <div className={styles.teamMemberAvatarContainer}>
              <img
                className={styles.teamMemberAvatar}
                alt="Team Architect"
                src={selectableUser.user.profilePictureURL}
              />
            </div>
          </div>
          {selectableUser.user.fullName}
        </li>
      ))}
    </ul>
  );
};

export default UserTabs;
