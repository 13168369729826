import { NotInterestedMissionsDataResponse } from '@a_team/models/dist/v2/Mission';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { Card } from '@ateams/components';
import CompanyAvatar from '@src/components/CompanyAvatar/CompanyAvatar';
import { MissionLocation } from '@src/locations';
import { stripDescriptionHtml } from '@src/logic/utils';
import { useStores } from '@src/stores';
import { getMissionTitle } from '@src/views/Mission/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { useStyles } from '../../MissionControlCard/styles';
import ActionButton from '../../MissionControlCard/ActionButton';
import ListRoleCategories from './ListRoleCategories';
import { useGetNotInterestedMissionsQueryV2 } from '@src/rq/missions';

interface NotInterestedMissionCardV2Props {
  mission: NotInterestedMissionsDataResponse;
  isLoading: boolean;
}

const NotInterestedMissionCardV2 = ({
  mission,
  isLoading,
}: NotInterestedMissionCardV2Props) => {
  const { refetch } = useGetNotInterestedMissionsQueryV2();

  const { missionControl } = useStores();
  const styles = useStyles({ descriptionLines: 4, fullWidth: false });
  const analytics = useAnalytics();

  const missionTitle = useMemo(() => {
    return getMissionTitle(mission?.title);
  }, [mission?.title]);

  const onUnhide = async (): Promise<void> => {
    if (!mission) return;
    const recommended =
      missionControl.getRecommendedMissionIndices.get(mission.mid) !==
      undefined;

    analytics.trackMissionCardNotInterestedV2(mission, recommended, false);
    await missionControl.restoreNotInterested(mission.mid);
    await refetch();
  };

  return (
    <li className={cx(styles.listItem)}>
      <Card className={styles.card}>
        <Link
          to={`${MissionLocation}/${mission.mid}`}
          className={styles.cardLink}
        >
          <div className={styles.headerContainer}>
            <div className={styles.logo}>
              {isLoading ? (
                <Skeleton
                  circle
                  height={40}
                  width={40}
                  className={styles.logoInner}
                />
              ) : (
                <CompanyAvatar
                  src={mission?.logoURL ?? ''}
                  alt={`${mission?.companyName} company logo`}
                  size={40}
                  className={styles.logoInner}
                />
              )}
            </div>
          </div>
          <div className={cx(styles.descriptionContainer, styles.container)}>
            {isLoading ? (
              <>
                <Skeleton className={styles.companyName} />
                <Skeleton className={styles.title} />
                <Skeleton count={4} />
              </>
            ) : (
              <>
                <p className={styles.companyName}>
                  <small>{mission?.companyName}</small>
                </p>

                <h4 className={styles.title}>{missionTitle}</h4>

                <p className={styles.description}>
                  {mission && stripDescriptionHtml(mission.description)}
                </p>
                <ListRoleCategories roleCategories={mission.roleCategories} />
              </>
            )}
          </div>
        </Link>

        <div className={styles.editActionButtonContainer}>
          {isLoading ? (
            <>
              <Skeleton width={200} />
              <Skeleton width={100} />
            </>
          ) : (
            <>
              <div></div>
              <ActionButton type="restore" onClick={onUnhide} />
            </>
          )}
        </div>
      </Card>
    </li>
  );
};

export default observer(NotInterestedMissionCardV2);
