import {
  RewardBadge,
  CurrentRewardsScoreContribution,
  RewardScoreType,
} from '@ateams/api/dist/endpoints/Rewards';
import { action, computed, observable } from 'mobx';
import { Stores } from '@src/stores/index';
import AuthStore from '@src/stores/Auth';
import { apiRewards } from '@ateams/api';
import { add, isAfter } from 'date-fns';
import { DateISOString } from '@a_team/models/dist/misc';
import Cookies from 'js-cookie';

export interface RewardsStoreData {
  totalScore: number | null;
  contributions: CurrentRewardsScoreContribution[] | null;
  badges?: Array<RewardBadge>;
  lastMonthDifference?: number;
  builderTotal?: number;
  builderReferralTotal?: number;
  companyReferralTotal?: number;
  loading?: boolean;
  errorMessage?: string;
  showTokenIntroModal?: boolean;
}

const showTokenIntroCookie = 'ateam-show-token-intro';

export default class RewardsStore implements RewardsStoreData {
  @observable loading?: boolean;
  @observable errorMessage?: string;

  private auth: AuthStore;
  private rootStore: Stores;
  @observable private data?: RewardsStoreData;
  private dataUserOwner?: string;
  @observable private showTokenIntro?: boolean;

  constructor(rootStore: Stores, initialState?: RewardsStoreData) {
    this.rootStore = rootStore;
    this.auth = rootStore.auth;
    this.loading = false;
    this.errorMessage = undefined;
    this.showTokenIntro = !Cookies.get(showTokenIntroCookie);

    if (initialState) {
      this.data = initialState;
    }
  }

  @computed public get totalScore(): number | null {
    return this.data?.totalScore ?? null;
  }

  @computed public get contributions():
    | CurrentRewardsScoreContribution[]
    | null {
    return this.data?.contributions ?? null;
  }

  @computed public get badges(): RewardBadge[] | undefined {
    return this.data?.badges;
  }

  @computed public get lastMonthDifference(): number | undefined {
    return Math.round(
      this.data?.contributions?.reduce<number | undefined>(
        (runningTotal, { date, totalScore }) =>
          RewardsStore.isWithinLastMonth(date)
            ? (runningTotal ?? 0) + totalScore
            : runningTotal,
        0,
      ) ?? 0,
    );
  }

  @computed public get builderTotal(): number | undefined {
    return (
      this.data?.contributions?.reduce(
        (runningTotal, contribution) =>
          RewardsStore.isBuilderToken(contribution)
            ? runningTotal + contribution.totalHours
            : runningTotal,
        0,
      ) ?? 0
    );
  }

  @computed public get builderReferralTotal(): number | undefined {
    return (
      this.data?.contributions?.reduce(
        (runningTotal, contribution) =>
          RewardsStore.isBuilderReferralToken(contribution)
            ? runningTotal + contribution.totalHours
            : runningTotal,
        0,
      ) ?? 0
    );
  }

  @computed public get companyReferralTotal(): number | undefined {
    return (
      this.data?.contributions?.reduce(
        (runningTotal, contribution) =>
          RewardsStore.isCompanyReferralToken(contribution)
            ? runningTotal + contribution.totalHours
            : runningTotal,
        0,
      ) ?? 0
    );
  }

  @computed public get showTokenIntroModal(): boolean | undefined {
    return this.showTokenIntro;
  }

  @action
  public dismissTokenIntroModal(): void {
    this.showTokenIntro = false;
    Cookies.set(showTokenIntroCookie, 'viewed', {
      expires: add(new Date(), {
        years: 1,
      }),
    });
  }

  @action
  public async fetchCurrentScore(): Promise<void> {
    if (!this.auth?.currentUser) {
      this.data = undefined;
      this.dataUserOwner = undefined;
      return;
    }

    this.loading = true;
    this.data = undefined;
    this.dataUserOwner = this.auth.currentUser.uid;

    apiRewards
      .getCurrentRewardsScores(this.auth)
      .then(
        ({ totalScore, contributions, badges }) =>
          (this.data = {
            totalScore,
            badges: (badges ?? []) as RewardBadge[],
            contributions:
              (contributions as CurrentRewardsScoreContribution[])?.sort(
                ({ date: dateA }, { date: dateB }) =>
                  new Date(dateA) >= new Date(dateB) ? -1 : 1,
              ) ?? [],
          }),
      )
      .catch((error) => (this.errorMessage = error.message))
      .finally(() => (this.loading = false));
  }

  public serialize(): RewardsStoreData {
    return {
      totalScore: this.totalScore,
      contributions: this.contributions,
      badges: this.badges,
      loading: this.loading,
      errorMessage: this.errorMessage,
      builderTotal: this.builderTotal,
      builderReferralTotal: this.builderReferralTotal,
      companyReferralTotal: this.companyReferralTotal,
      lastMonthDifference: this.lastMonthDifference,
      showTokenIntroModal: this.showTokenIntroModal,
    };
  }

  private static isWithinLastMonth(dateString: DateISOString): boolean {
    return isAfter(
      new Date(dateString),
      add(new Date(), {
        months: -1,
      }),
    );
  }

  private static isBuilderToken(
    contribution: CurrentRewardsScoreContribution,
  ): boolean {
    return contribution.type === RewardScoreType.BilledHoursToAnyMission;
  }

  private static isBuilderReferralToken(
    contribution: CurrentRewardsScoreContribution,
  ): boolean {
    return contribution.type === RewardScoreType.InvitedBuilderBilledToMission;
  }

  private static isCompanyReferralToken(
    contribution: CurrentRewardsScoreContribution,
  ): boolean {
    return contribution.type === RewardScoreType.InvitedCompanyBilledToMission;
  }
}
