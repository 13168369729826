import React, { ReactElement, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  Breakpoints,
  Colors,
  Icon,
  IconType,
  Spacing,
} from '@ateams/components';
import { PotentialTeams } from '@src/views/Mission/Proposal/PotentialTeams';
import Mission from '@src/stores/Missions/Mission';
import { useHistory } from 'react-router-dom';
import { MissionProposalLocation } from '@src/locations';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';
import { SuggestedTeamWithApplications } from '@src/views/Mission/Proposal/utils';
import { PotentialExtensions } from '@src/views/Mission/Proposal/PotentialExtensions';

interface Props {
  currentMission: Mission;
  precomputedTeams?: SuggestedTeamWithApplications[];
  extensions?: SuggestedTeamWithApplications[];
}

const useStyles = createUseStyles({
  '@global': {
    '.tippy-popper': {
      zIndex: '999999 !important',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
    borderRadius: 5,
    padding: 24,
    paddingTop: 0,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: Colors.dark,
    cursor: 'pointer',
  },
  titleRow: {
    marginTop: 0,
    width: '100%',
  },
  icon: {
    marginLeft: Spacing.small,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    wrapper: {
      textAlign: 'left',
      alignItems: 'flex-start',
      margin: 0,
    },
  },
});

export const ApplicationPotentialTeamsAndExtensions = (
  props: Props,
): ReactElement => {
  const styles = useStyles();
  const history = useHistory();
  const openRoles = props.currentMission?.data.roles.filter(
    (e) => e.status === MissionRoleStatus.Open,
  );

  const [potentialTeamsCollapsed, setPotentialTeamsCollapsed] = useState(true);
  const [potentialExtensionsCollapsed, setPotentialExtensionsCollapsed] =
    useState(true);

  if (!props.precomputedTeams?.length && !props.extensions?.length)
    return <></>;

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        {props.precomputedTeams?.length !== 0 ? (
          <>
            <h4
              onClick={() => setPotentialTeamsCollapsed((val) => !val)}
              className={styles.title}
            >
              Potential Teams
              <Icon
                type={IconType.Chevron}
                className={styles.icon}
                size="exact"
                style={{
                  transform: !potentialTeamsCollapsed
                    ? 'rotate(-180deg)'
                    : 'rotate(0deg)',
                }}
              />
            </h4>
            {!potentialTeamsCollapsed && (
              <PotentialTeams
                allContentVisible={true}
                missionStatus={props.currentMission.data.status}
                proposeTeamButtonText="Open in proposal"
                onClickOnProposeTeam={() =>
                  history.push(
                    MissionProposalLocation(props.currentMission.mid || ''),
                  )
                }
                teams={props.precomputedTeams || []}
                openRoles={openRoles || []}
                applications={props.currentMission.applications || []}
              />
            )}
          </>
        ) : null}
        {props.extensions?.length !== 0 ? (
          <>
            <h4
              onClick={() => setPotentialExtensionsCollapsed((val) => !val)}
              className={styles.title}
            >
              Potential Extensions
              <Icon
                type={IconType.Chevron}
                className={styles.icon}
                size="exact"
                style={{
                  transform: !potentialExtensionsCollapsed
                    ? 'rotate(-180deg)'
                    : 'rotate(0deg)',
                }}
              />
            </h4>
            {!potentialExtensionsCollapsed && (
              <PotentialExtensions
                allContentVisible={true}
                missionStatus={props.currentMission.data.status}
                proposeTeamButtonText="Open in proposal"
                onClickOnProposeTeam={() =>
                  history.push(
                    MissionProposalLocation(props.currentMission.mid || ''),
                  )
                }
                teams={props.extensions || []}
                openRoles={openRoles || []}
                applications={props.currentMission.applications || []}
              />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};
