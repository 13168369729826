import { Tag } from '@ateams/components';
import TextAreaInput from '@src/components/Inputs/TextAreaInput';
import React, { ChangeEvent, ReactElement } from 'react';
import CommonStyles from '../partials/styles';

interface Props {
  text?: string;
  onChange(text: string): void;
  disabled?: boolean;
  readonly?: boolean;
  error?: boolean;
  rows?: number;
  isApplicationAvailabilityNotesEdited?: boolean;
}

export const NotesInput = React.memo((props: Props): ReactElement => {
  const {
    disabled,
    text,
    onChange,
    readonly,
    error,
    rows,
    isApplicationAvailabilityNotesEdited,
  } = props;

  const commonStyles = CommonStyles();

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    onChange(e.target.value.trimStart());
  };

  return (
    <div style={{ flexGrow: 1, marginTop: 4 }}>
      {readonly ? (
        <div className={commonStyles.readingOnlyText}>
          {text}
          {isApplicationAvailabilityNotesEdited ? (
            <Tag className={commonStyles.tagEdited}>Edited</Tag>
          ) : null}
        </div>
      ) : (
        <TextAreaInput
          id="notes"
          value={text || ''}
          disabled={disabled}
          onChange={handleChange}
          placeholder="Note specifics about your availability: special circumstances like ramping up hours, flexibility, upcoming vacations, or engagements on other projects with expected end dates."
          error={error}
          className={commonStyles.textArea}
          rows={rows || 4}
        />
      )}
    </div>
  );
});
