import { TeamPulse } from '@a_team/models/dist/TeamPulse';

export const getTeamPulseAverageScore = (
  teamPulse: TeamPulse,
  admin: boolean | undefined,
): number | null => {
  if (!teamPulse.averageRatingBuilder && !teamPulse.averageRatingClient) {
    return null;
  } else if (teamPulse.averageRatingBuilder && !admin) {
    return teamPulse.averageRatingBuilder;
  } else if (!teamPulse.averageRatingBuilder) {
    return teamPulse.averageRatingClient;
  } else if (!teamPulse.averageRatingClient) {
    return teamPulse.averageRatingBuilder;
  } else {
    return (teamPulse.averageRatingBuilder + teamPulse.averageRatingClient) / 2;
  }
};
