import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface CompanyLogoProps {
  logoUrl: string | undefined | null;
  className?: string;
  size?: number;
}

const useStyles = createUseStyles<{
  size: number;
}>({
  imageContainer: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: ({ size }) => size ?? 48,
    height: ({ size }) => size ?? 48,
    overflow: 'hidden',
    borderRadius: '8px',
    '& > img': {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
    },
  },
  noLogo: {
    height: ({ size }) => size ?? 48,
    width: ({ size }) => size ?? 48,
    borderRadius: '8px',
    background:
      'linear-gradient(197.15deg, rgb(148, 254, 12) 0%, rgb(84, 214, 255) 88.21%);',
  },
});

const CompanyLogo = ({
  logoUrl,
  className,
  size,
}: CompanyLogoProps): JSX.Element => {
  const styles = useStyles({
    size,
  });

  return (
    <div className={cx(styles.imageContainer, className)}>
      {logoUrl ? (
        <img src={logoUrl} alt="Company Logo" />
      ) : (
        <div className={styles.noLogo} />
      )}
    </div>
  );
};

export default CompanyLogo;
