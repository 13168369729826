import TextButton from '@src/components/TextButton';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { TextColors } from '@ateams/components';

const useStyles = createUseStyles({
  container: {
    marginBottom: 16,
  },
  sectionHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textButton: {
    color: TextColors.primaryLight,
  },
  criteriaName: {
    marginBottom: 12,
    marginTop: 12,
    fontWeight: 600,
  },
});

type Props = {
  sectionTitle: string;
  sidebar?: boolean;
  clearCallBack: (params: unknown) => void;
  children?: React.ReactNode;
  buttonName?: string;
};
const FilterGroupContainer = (props: Props): ReactElement => {
  const styles = useStyles();
  const { children, clearCallBack, sectionTitle, buttonName } = props;
  return (
    <div className={styles.container}>
      <div className={styles.sectionHead}>
        <div className={styles.criteriaName}>{sectionTitle}</div>
        <TextButton className={styles.textButton} onClick={clearCallBack}>
          {buttonName || 'Clear'}
        </TextButton>
      </div>
      {children}
    </div>
  );
};
export default FilterGroupContainer;
