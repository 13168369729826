import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Spacing } from '@ateams/components';
import { Card } from '../components/card';
import { Text } from '../components/typography';
import { VettingFeedbackFormDefaults } from '@ateams/api/dist/endpoints/vetting-process-feedback';
import { Comment } from '../components/comment';

const useStyles = createUseStyles({
  sectionDescription: {
    marginBottom: Spacing.large,
  },
  comment: {
    marginBottom: Spacing.large,
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

export type VettingFeedbackFormBackgroundComments = {
  comments: VettingFeedbackFormDefaults['answers']['backgroundComments'];
  comment?: string;
};

export type OnVettingFeedbackFormBackgroundCommentsChange = (
  data: VettingFeedbackFormBackgroundComments,
) => void;

export interface VettingFeedbackFormBackgroundCommentsProps {
  defaultValues: VettingFeedbackFormBackgroundComments;
  onChange?: OnVettingFeedbackFormBackgroundCommentsChange;
  avatarSrc?: string;
  currentUserId?: string;
  isCurrentUserAdmin?: boolean;
  isReadOnly?: boolean;
  className?: string;
}

export const VettingFeedbackFormBackgroundCommentsForm: React.FC<VettingFeedbackFormBackgroundCommentsProps> =
  React.memo((props) => {
    const { isReadOnly } = props;
    const styles = useStyles();
    const [comments, setComments] = useState(props.defaultValues.comments);

    const onChange = (data: Partial<VettingFeedbackFormBackgroundComments>) => {
      props.onChange?.({
        comments: data.comments || comments,
      });
    };

    const onCommentEdit = (comment: string, commentIndex: number) => {
      if (comments) {
        const newComments = [
          ...comments.slice(0, commentIndex),
          { ...comments[commentIndex], comment },
          ...comments.slice(commentIndex + 1),
        ];

        setComments(newComments);
        onChange({ comments: newComments });
      }
    };

    const onCommentRemove = (commentIndex: number) => {
      const newComments = comments?.filter((_, i) => commentIndex !== i);

      setComments(newComments);
      onChange({ comments: newComments });
    };

    return (
      <Card title={'Background'} className={props.className}>
        {!isReadOnly && (
          <Text className={styles.sectionDescription}>
            If the builder mentions it, add here their past employers,
            education, details about their career beginning, etc.
          </Text>
        )}

        {comments &&
          comments.map((comment, i) => (
            <Comment
              key={comment.id}
              className={styles.comment}
              value={comment.comment}
              onCommentChange={(newComment) => onCommentEdit(newComment, i)}
              onCommentRemove={() => onCommentRemove(i)}
              showEditActions={
                props.isCurrentUserAdmin ||
                comment.user.uid === props.currentUserId
              }
              avatarSrc={comment.user.profilePictureURL}
              fullName={comment.user.fullName}
              date={comment.createdAt}
              isNewComment={false}
              isReadOnly={isReadOnly}
            />
          ))}

        {isReadOnly && !comments.length && (
          <Text isReadOnly readOnlyText={'No comments were added.'} />
        )}
      </Card>
    );
  });
