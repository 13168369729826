import React, { ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface Props {
  details: Record<string, string | null>;
  total: string;
  totalLabel?: string;
  children?: ReactNode;
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '40pt 30pt',

    '& h4': {
      margin: 0,
      textTransform: 'uppercase',
    },
  },
  children: {
    width: '50%',
    paddingRight: '32pt',

    '& a': {
      color: '#fff',
    },
  },
  summary: {
    width: '50%',
    fontWeight: 800,
  },
  row: {
    display: 'flex',
    alignItems: 'center',

    '& + &': {
      marginTop: '8pt',
    },

    '& > h4': {
      width: '50%',
    },
  },
  total: {
    borderTop: '1px solid #fff',
    paddingTop: '16pt',
    fontSize: '1.6em',
  },
});

export default function FooterTotal(props: Props): ReactElement {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {!!props.children && (
        <div className={styles.children}>{props.children}</div>
      )}

      <div className={styles.summary}>
        {Object.entries(props.details).map(
          ([key, value]) =>
            !!value && (
              <div key={key} className={styles.row}>
                <h4>{key}</h4>
                <span>{value}</span>
              </div>
            ),
        )}
        <div className={cx(styles.row, styles.total)}>
          <h4>{props.totalLabel || 'Total'}</h4>
          <span>{props.total}</span>
        </div>
      </div>
    </div>
  );
}
