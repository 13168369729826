import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import MainLayout from '@src/layouts/Main';
import NewTopBar from '@src/views/Discovery/components/TopBar';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import Teaser from './Teaser';
import Stats from './Stats';
import Invite from './Invite';
import TokenInfo from './TokenInfo';
import Summary from './Summary';
import { useStores } from '@src/stores';
import cx from 'classnames';
import { ComingSoon } from '../Discovery/components/ComingSoon/ComingSoon';

const useStyles = createUseStyles({
  layoutContainer: {
    marginTop: '40px',
    marginBottom: '40px',
    display: 'grid',
    gridTemplateColumns: 'auto',
    padding: 12,
    gap: '16px',
    '& > div': {
      overflowY: 'hidden',
    },
  },
  card: {
    margin: '0 0 16px 0',
    '&:last-child': {
      margin: 0,
    },
  },
  inviteCard: {
    paddingBottom: '8px',
  },
  comingSoon: {
    height: 'fit-content',
    padding: '15px',
    marginBottom: '16px',
  },
  titleContainer: {
    gridColumn: 'span 1',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    layoutContainer: {
      paddingLeft: 56,
      paddingRight: 56,
      gridTemplateColumns: '66% auto',
    },
    titleContainer: {
      gridColumn: 'span 2',
    },
  },
});

const TokensView: React.FC = () => {
  const styles = useStyles();
  const [sticky, setSticky] = useState(false);
  const [stickyHeight, setStickyHeight] = useState(9999);
  const stores = useStores();
  const { auth, rewards } = stores;

  useEffect(() => {
    rewards.fetchCurrentScore();
  }, [stores.auth?.currentUser?.uid]);

  const handleScroll = (): void => {
    window.pageYOffset >= stickyHeight ? setSticky(true) : setSticky(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return (): void => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [stickyHeight]);

  return (
    <MainLayout title="Tokens" style={{ padding: 0 }}>
      {auth.fullAccess && (
        <NewTopBar
          sticky={sticky}
          setStickyHeight={(val: number): void => setStickyHeight(val)}
          track={() => undefined}
        />
      )}

      <div className={styles.layoutContainer}>
        {!auth.fullAccess && (
          <div className={styles.titleContainer}>
            <h2>Token Balance</h2>
          </div>
        )}

        <div>
          <Summary className={styles.card} />
          <Teaser
            className={styles.card}
            profilePictureURL={auth.currentUser?.profilePictureURL}
          />
          <Stats className={styles.card} />
        </div>

        <div>
          {!auth.fullAccess && <ComingSoon className={styles.comingSoon} />}

          <Invite
            className={cx(styles.card, styles.inviteCard)}
            shareLink={`invite.a.team/${auth.currentUser?.username}`}
          />
          <TokenInfo className={styles.card} />
        </div>
      </div>
    </MainLayout>
  );
};

export default observer(TokensView);
