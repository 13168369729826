import {
  ExperienceMember,
  ExperienceMemberRole,
} from '@a_team/models/dist/ExperienceObject';
import {
  CollaboratorStatus,
  UserId,
  UserScrubbed,
} from '@a_team/models/dist/UserObject';
import { SelectOption } from '@ateams/components';
import { getProjectMemberId } from '@src/views/Profile/helpers/experience';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import CommonStyles from '../common/styles';
import AddCollaborator from './AddCollaborator';
import Collaborator from './Collaborator';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';

interface CollaboratorsProps {
  currentUserId: UserId;
  roles: { value: string; label: string }[] | undefined;
  isOwner: boolean;
}

const useStyles = createUseStyles({
  addCollaborator: {
    fontSize: 14,
    fontWeight: 500,
    color: '#7000E3',
    marginTop: 16,
    cursor: 'pointer',
  },
});

const Collaborators = ({
  currentUserId,
  roles,
  isOwner,
}: CollaboratorsProps): JSX.Element | null => {
  const { auth } = useStores();
  const { setValue, watch } = useFormContext();
  const commonStyles = CommonStyles();
  const styles = useStyles();
  const [newMembers, setNewMembers] = useState<number[]>([]);

  const members = watch('members');

  const membersToDisplay = useMemo(
    () =>
      (members as ExperienceMember[]).filter(
        (member) => member && getProjectMemberId(member) !== currentUserId,
      ),
    [members],
  );

  const hideCollaboratorsSection = useMemo(() => {
    const scrubbed = auth.user?.scrubbed;

    // If the auth user is the owner and it is not scrubbed
    // or the scrubbed value is not either Exceptional, Verified or Unknown
    // then we don't want to show the collaborators section.
    // In the case where the the user is not the owner
    // we are safe as collaborators cannot edit the section
    if (
      isOwner &&
      (!scrubbed ||
        ![
          UserScrubbed.Exceptional,
          UserScrubbed.Verified,
          UserScrubbed.Unknown,
        ].includes(scrubbed))
    ) {
      return true;
    }

    return false;
  }, [isOwner, auth.user?.scrubbed]);

  useEffect(() => {
    if (membersToDisplay.length === 0 && newMembers.length === 0) {
      setNewMembers([0]);
    }
  }, [membersToDisplay.length, newMembers.length]);

  const setMember = (index: number, member: ExperienceMember) => {
    const list: ExperienceMember[] = [...membersToDisplay];
    const updatedList = list.map((user, i) => {
      if (i === index) {
        return {
          ...member,
          ...{
            experienceRole: ExperienceMemberRole.Member,
            collaboratorStatus: CollaboratorStatus.Pending,
            memberRole: user.memberRole ?? '',
          },
        };
      } else {
        return user;
      }
    });

    setValue('members', updatedList);
  };

  const setRole = (role: SelectOption, member: ExperienceMember) => {
    const list = [...membersToDisplay];
    const updatedList = list.map((user) => {
      if (getProjectMemberId(user) === getProjectMemberId(member)) {
        return { ...user, memberRole: role.value };
      } else {
        return user;
      }
    });

    setValue('members', updatedList);
  };

  const addCollaborator = () => {
    setNewMembers([...newMembers, newMembers.length]);
  };

  const removeCollaborator = (memberToRemove: ExperienceMember) => {
    const newMembers = (members as ExperienceMember[]).filter((member) => {
      return getProjectMemberId(member) !== getProjectMemberId(memberToRemove);
    });

    setValue('members', [...newMembers]);
  };

  if (hideCollaboratorsSection) {
    return null;
  }

  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.title}>Add collaborators (Optional)</div>
      <div className={commonStyles.description}>
        Add the builders you collaborated with on this project. If they confirm
        that you’ve worked together, you’ll both be added as connections and
        receive priority when applying to missions together.
      </div>
      <div className={commonStyles.box}>
        {membersToDisplay.map((member, index) => (
          <Collaborator
            key={getProjectMemberId(member)}
            memberIdex={index}
            member={member}
            roles={roles}
            setMember={setMember}
            setRole={setRole}
            removeCollaborator={removeCollaborator}
            isOwner={isOwner}
          />
        ))}
        {isOwner &&
          newMembers.map((memberIndex) => {
            return (
              <AddCollaborator
                index={memberIndex}
                key={`collaborator-${memberIndex}`}
                memberIndex={memberIndex}
                newMembers={newMembers}
                setNewMembers={setNewMembers}
                roles={roles}
              />
            );
          })}
        {isOwner && membersToDisplay.length > 0 && (
          <div className={styles.addCollaborator} onClick={addCollaborator}>
            Add a collaborator +
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(Collaborators);
