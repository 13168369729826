import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Spacing } from '@ateams/components';
import { InputLabel } from '../../components/typography';
import { TextInput } from '../../components/text-input';

type TeamSizeValue = string | undefined;

export type OnTeamSizeChange = (teamSize: TeamSizeValue) => void;

export interface TeamSizeInputProps {
  value: TeamSizeValue;
  onChange?: OnTeamSizeChange;
  isReadOnly?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  marginLeft: {
    marginLeft: Spacing.medium,
  },
});

export const TeamSizeInput: React.FC<TeamSizeInputProps> = (props) => {
  const { isReadOnly, value } = props;
  const styles = useStyles();

  const onTeamSizeChange = (teamSize: string) => {
    props.onChange?.(teamSize);
  };

  return (
    <div>
      <InputLabel className={styles.marginBottom}>
        What is a typical size of development team you’ve worked with?
      </InputLabel>
      <div
        className={cx(
          { [styles.marginLeft]: !isReadOnly && !value },
          styles.marginBottom,
        )}
      >
        <TextInput
          value={value}
          onChange={(e) => onTeamSizeChange(e.target.value)}
          placeholder={'Enter team size...'}
          withLine={!isReadOnly && !value}
          isReadOnly={isReadOnly}
        />
      </div>
    </div>
  );
};
