import React, { ReactNode } from 'react';
import SectionHeading from '@src/components/SectionHeading';
import Modal, { ModalBasicProps } from '..';
import {
  Button as CallToActionButton,
  ButtonColor,
  Colors,
  TextColors,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

export interface ConfirmModalProps extends ModalBasicProps {
  title: string;
  description: NonNullable<ReactNode>;
  actionColor?: ButtonColor;
  cancelColor?: ButtonColor;
  actionLabel?: JSX.Element | string;
  actionTestingId?: string;
  actionLoading?: boolean;
  cancelLabel?: JSX.Element | string;
  cancelDisabled?: boolean;
  actionDisabled?: boolean;
  onConfirm(): void;
  width?: number;
  hideCloseButton?: boolean;
  children?: ReactNode;
  onCloseButtonClicked?: () => void;
}

const useStyles = createUseStyles(() => ({
  modal: {
    '&.bordered': {
      borderRadius: '8px !important',
    },
  },
  title: {
    marginBottom: 8,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '26px',
  },
  description: {
    color: TextColors.regularLight,
    margin: '0 0 24px 0',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
  },
  button: {
    padding: '8px 24px',
    borderRadius: 8,
    width: 'auto',
    fontSize: 14,
    transition: 'opacity 0.3s ease',
    '&:hover': {
      opacity: 0.9,
    },
  },
  cancelButton: {
    backgroundColor: Colors.backgroundDark,
  },
  confirmButton: {
    backgroundColor: Colors.secondaryDark,
  },
}));

export const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const {
    title,
    description,
    onClose,
    onCloseButtonClicked,
    onConfirm,
    actionLabel,
    actionColor,
    cancelColor,
    actionLoading,
    cancelLabel,
    cancelDisabled,
    actionTestingId,
    actionDisabled,
    width,
    children,
    hideCloseButton = true,
    ...left
  } = props;
  const styles = useStyles();

  return (
    <Modal
      onClose={onCloseButtonClicked ?? onClose}
      hideCloseButton={hideCloseButton}
      {...left}
      className={styles.modal}
      style={{
        zIndex: 10003,
        textAlign: 'center',
        width: width || 496,
      }}
    >
      <div style={{ color: 'black' }}>
        <SectionHeading isFirst className={styles.title}>
          {title}
        </SectionHeading>
        <p className={styles.description}>{description}</p>

        {children && (
          <div style={{ marginTop: '32px', textAlign: 'left' }}>{children}</div>
        )}

        <div className={cx(styles.buttonContainer)} style={{}}>
          <CallToActionButton
            disabled={cancelDisabled}
            size="small"
            color={cancelColor || 'regular'}
            onClick={onClose}
            className={cx(styles.button, styles.cancelButton)}
          >
            {cancelLabel || 'Cancel'}
          </CallToActionButton>
          <CallToActionButton
            size="small"
            disabled={actionLoading || actionDisabled}
            loading={actionLoading}
            color={actionColor}
            onClick={onConfirm}
            className={cx(styles.button, styles.confirmButton)}
            data-testing-id={actionTestingId}
          >
            {actionLabel || 'Confirm'}
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
