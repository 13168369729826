import React, { ReactElement } from 'react';
import OutlinedInput, {
  OutlinedInputProps,
} from '@src/components/Inputs/OutlinedInput';
import { inputStyles as defaultStyles } from '@src/components/Inputs/InlineInput/styles';
import { Colors } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

const useStyles = createUseStyles({
  error: {
    '&::placeholder': {
      color: Colors.danger,
    },
  },
});

const InlineInput = (props: OutlinedInputProps): ReactElement => {
  const styles = useStyles();
  const { inputStyles, error, inputClassName, ...left } = props;
  return (
    <OutlinedInput
      autosize
      noBorder
      margin={'none'}
      inputStyles={{
        color: '#222',
        ...defaultStyles,
        ...inputStyles,
        ...(error && {
          borderColor: Colors.danger,
          color: Colors.danger,
          textDecorationColor: Colors.danger,
        }),
      }}
      inputClassName={cx(inputClassName, { [styles.error]: error })}
      {...left}
    />
  );
};

export default InlineInput;
