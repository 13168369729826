import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { truncate } from 'lodash';

import { Margins, Select, SelectOption } from '@ateams/components';
import { apiMissions } from '@ateams/api';
import { useStores } from '@src/stores';
import { MissionRoleVisibilityStatus } from '@a_team/models/dist/MissionRole';

type SelectChangeValue = SelectOption | SelectOption[] | null;

interface Props {
  selectedMission?: string;
  roleSelection?: string[];
  onChange: (roles: string[]) => void;
  itemCountPrefix: string;
  margin: keyof typeof Margins;
  disabled?: boolean;
}

const RoleSearch = (props: Props): ReactElement => {
  const { auth } = useStores();
  const { selectedMission, roleSelection, onChange, margin, itemCountPrefix } =
    props;
  const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (!selectedMission) return setRoleOptions([]);

    apiMissions.adminGetMissionById(auth, selectedMission).then(({ roles }) => {
      setRoleOptions(
        roles.map(({ rid, category, headline, visibility }) => ({
          value: rid,
          label: truncate(
            `${
              visibility &&
              visibility.visibilityStatus ===
                MissionRoleVisibilityStatus.OnlyAdmin
                ? '(a)'
                : ''
            } ${category.title}: ${headline}`,
            { length: 45 },
          ),
        })),
      );
    });
  }, [selectedMission]);

  const selectedRoles: SelectOption[] = useMemo(() => {
    if (roleOptions.length === 0 || roleSelection?.length === 0) return [];

    return roleOptions.filter(({ value }) => roleSelection?.includes(value));
  }, [roleOptions, roleSelection]);

  const handleSelections = (selections: SelectChangeValue): void => {
    const selectedOptions = !selections
      ? []
      : Array.isArray(selections)
      ? selections
      : [selections];

    onChange(selectedOptions.map(({ value }) => value));
  };

  return (
    <Select
      isDisabled={props.disabled}
      hideTags
      isMulti={true as false}
      isSearchable
      showItemCount
      itemCountPrefix={itemCountPrefix}
      placeholder=""
      value={selectedRoles}
      margin={margin}
      onChange={handleSelections}
      options={roleOptions}
    />
  );
};

RoleSearch.defaultProps = { margin: 'default' };
export default RoleSearch;
