import React, { useEffect, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import OutlinedInput, {
  OutlinedInputProps,
} from '@src/components/Inputs/OutlinedInput';
import { XMarkIcon } from './x-mark-icon';

export type OnDateChange = (date: Date | null) => unknown;
export type OnDateRangeChange = (date: [Date, Date] | null) => unknown;

export interface OutlinedDateInputProps<
  T extends ReactDatePickerProps = ReactDatePickerProps,
> {
  selected?: Date | null;
  onChange?: T['selectsRange'] extends true ? OnDateRangeChange : OnDateChange;
  isDisabled?: boolean;
  placeholder?: string;
  isClearable?: boolean;

  /** Adding classNames to OutlinedInput */
  className?: string;
  controlClassName?: string;
  inputClassName?: string;

  /** Adding className to the clearableIcon */
  xMarkIconClassName?: string;

  /** Overriding subcomponents props */
  datePickerProps?: Partial<ReactDatePickerProps>;
  outlinedInputProps?: Partial<OutlinedInputProps>;
}

const useStyles = createUseStyles({
  '@global': {
    '.react-datepicker-popper': {
      zIndex: 100,
    },
  },
  input: {
    fontSize: '15px',
    margin: 0,
  },
  textInput: {
    padding: 0,
    margin: 0,
    height: '20px',
  },
  textInputControl: {
    padding: '10px 12px',
    height: '42px',
    width: '200px',
    minHeight: 'initial',
    borderRadius: '4px',
  },
});

export const OutlinedDateInput: React.FC<OutlinedDateInputProps> = (props) => {
  const {
    isDisabled,
    placeholder,
    isClearable = true,

    className,
    controlClassName,
    inputClassName,
    xMarkIconClassName,

    datePickerProps,
    outlinedInputProps,
  } = props;
  const styles = useStyles();
  const [selectedControlled, setSelectedControlled] = useState<
    Date | null | undefined
  >(props.selected || null);

  const selected = 'selected' in props ? selectedControlled : props.selected;

  useEffect(() => {
    if (props.selected !== selectedControlled) {
      setSelectedControlled(props.selected);
    }
  }, [props.selected]);

  const onChange = (date: Date | [Date, Date] | null) => {
    const newSelected = Array.isArray(date) ? date[0] : date;
    setSelectedControlled(newSelected);

    if (props.onChange) {
      props.onChange(date as Date | null);
    }
  };

  return (
    <DatePicker
      selected={selected}
      onChange={onChange}
      popperPlacement="top-end"
      placeholderText={
        !isDisabled ? placeholder || 'Select Date...' : undefined
      }
      disabled={isDisabled}
      onKeyDown={(e) => e.preventDefault()} // To avoid entering date manually
      customInput={
        <OutlinedInput
          className={cx(styles.input, className)}
          controlClassName={cx(styles.textInputControl, controlClassName)}
          inputClassName={cx(styles.textInput, inputClassName)}
          endAdornment={
            isClearable && selected ? (
              <XMarkIcon
                className={xMarkIconClassName}
                onClick={(e) => {
                  e.stopPropagation(); // Without this the date-input will be unintentionally opened
                  onChange(null);
                }}
                isDisabled={isDisabled}
              />
            ) : undefined
          }
          {...outlinedInputProps}
        />
      }
      {...datePickerProps}
    />
  );
};
