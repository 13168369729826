import React, { ReactElement, useEffect, useState } from 'react';
import { MissionPaymentCycleId } from '@a_team/models/dist/MissionPaymentCycleObject';
import { PaymentCycle } from '@src/views/Mission/TimeTracking/PaymentCycles/PaymentCycle';
import MissionPaymentCycle from '@src/stores/Missions/MissionPaymentCycle';
import CycleCards from '@src/components/CycleCards';

interface Props {
  paymentCycles: MissionPaymentCycle[] | undefined;
  updateSelectedCycle?: (id: MissionPaymentCycleId) => void;
  selectedPaymentCycleId?: MissionPaymentCycleId;
  clientView?: boolean;
  adminView?: boolean;
}

const PaymentCyclesOverview = (props: Props): ReactElement => {
  const { paymentCycles, selectedPaymentCycleId, clientView, adminView } =
    props;
  const [dummySelectedId, setDummySelectedId] = useState<
    MissionPaymentCycleId | undefined
  >(selectedPaymentCycleId);

  const handleClick = (id: MissionPaymentCycleId) => {
    setDummySelectedId(id);
    props.updateSelectedCycle && props.updateSelectedCycle(id);
  };

  useEffect(() => {
    setDummySelectedId(selectedPaymentCycleId);
  }, [selectedPaymentCycleId]);

  return (
    <CycleCards>
      {paymentCycles?.map((cycle) => (
        <PaymentCycle
          key={cycle.data.yid}
          cycle={cycle}
          onClick={handleClick}
          selected={cycle.data.yid === dummySelectedId}
          showTotals={!clientView || adminView}
        />
      ))}
    </CycleCards>
  );
};

export default PaymentCyclesOverview;
