import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { VettingProccessFeedbackScaledProducts } from '@a_team/models/dist/vetting-processes/feedback';
import { Spacing } from '@ateams/components';
import { InputLabel } from '../../components/typography';
import { CheckboxValueTag } from '../../components/value-tag';
import { TextInput } from '../../components/text-input';
import { Text } from '../../components/typography';

const INPUT_LABEL = 'Has this builder worked on a product that scaled?';

type ScaledProductsValue = VettingProccessFeedbackScaledProducts | undefined;

export type OnScaledProductsChange = (
  scaledProducts: ScaledProductsValue,
) => void;

export interface ScaledProductsInputProps {
  value: ScaledProductsValue;
  onChange?: OnScaledProductsChange;
  isReadOnly?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  marginLeft: {
    marginLeft: Spacing.medium,
  },
  booleanCheckboxValueTag: {
    marginRight: Spacing.small,
    '&:last-child': {
      marginRight: 0,
    },
  },
  inputsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const ScaledProductsInput: React.FC<ScaledProductsInputProps> = (
  props,
) => {
  const { isReadOnly, value } = props;
  const styles = useStyles();

  const onHasWorkedOnProductThatScaledChange = (
    hasWorkedOnProductThatScaled: boolean,
  ) => {
    const newScaledProducts =
      value?.hasWorkedOnProductThatScaled === hasWorkedOnProductThatScaled
        ? undefined
        : {
            hasWorkedOnProductThatScaled,
            description: undefined,
          };

    props.onChange?.(newScaledProducts);
  };

  const onWorkedOnProductThatScaledDescriptionChange = (
    description: string,
  ) => {
    if (value) {
      const newWorkedOnProductThatScaled = {
        ...value,
        description,
      };

      props.onChange?.(newWorkedOnProductThatScaled);
    }
  };

  if (isReadOnly && !value) {
    return (
      <div className={cx(styles.marginBottom, props.className)}>
        <InputLabel className={styles.marginBottom}>{INPUT_LABEL}</InputLabel>
        <Text isReadOnly />
      </div>
    );
  }

  return (
    <div className={cx(styles.marginBottom, props.className)}>
      <InputLabel className={styles.marginBottom}>{INPUT_LABEL}</InputLabel>
      <div className={cx(styles.inputsRow, styles.marginBottom)}>
        <CheckboxValueTag
          label={'Yes'}
          checked={value?.hasWorkedOnProductThatScaled === true}
          className={styles.booleanCheckboxValueTag}
          onClick={() => onHasWorkedOnProductThatScaledChange(true)}
          isReadOnly={isReadOnly}
        />
        <CheckboxValueTag
          label={'No'}
          checked={value?.hasWorkedOnProductThatScaled === false}
          className={styles.booleanCheckboxValueTag}
          onClick={() => onHasWorkedOnProductThatScaledChange(false)}
          isReadOnly={isReadOnly}
        />
      </div>
      {value?.hasWorkedOnProductThatScaled === true && (
        <div className={cx(styles.marginLeft, styles.marginBottom)}>
          <TextInput
            value={value.description}
            onChange={(e) =>
              onWorkedOnProductThatScaledDescriptionChange(e.target.value)
            }
            placeholder={'What is the scale? Ex. ‘Saved $10m a year’'}
            isReadOnly={isReadOnly}
            readOnlyText={'No details were added.'}
          />
        </div>
      )}
    </div>
  );
};
