import { IconType } from '@ateams/components';
import { useStores } from '@src/stores';
import React from 'react';
import Requirement from '../common/Requirement';

interface AvailabilityProps {
  weeklyHoursAvailable: number;
  userWeeklyHoursAvailable: number | undefined;
  toggleSetAvailabilityModalOpen?(): void;
}

function Availability({
  weeklyHoursAvailable,
  userWeeklyHoursAvailable,
  toggleSetAvailabilityModalOpen,
}: AvailabilityProps): JSX.Element {
  const stores = useStores();
  const { auth } = stores;

  const meetsRequirement =
    !!userWeeklyHoursAvailable &&
    weeklyHoursAvailable <= userWeeklyHoursAvailable;

  const feedback = meetsRequirement
    ? `You're available ${userWeeklyHoursAvailable} hours per week`
    : 'You don’t have enough availability';

  const onClickEvent =
    !meetsRequirement && auth?.user?.username
      ? toggleSetAvailabilityModalOpen
      : undefined;

  return (
    <Requirement
      iconType={IconType.Clock}
      name="Minimum availability"
      dataTestingId="availability-requirement"
      meetsRequirement={meetsRequirement}
      feedback={feedback}
      onClick={onClickEvent}
    >
      {weeklyHoursAvailable} hours
    </Requirement>
  );
}

export default Availability;
