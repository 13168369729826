import React, { ChangeEvent, useRef, useState } from 'react';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { useStores } from '@src/stores';
import useClickOutside from '@src/hooks/useClickOutside';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import useDebounceState from '@src/hooks/useDebounceState';
import { Card, Icon, IconType } from '@ateams/components';
import { MinimalAccountObject } from '@a_team/models/dist/Account';
import { apiAccounts } from '@src/logic/services/endpoints';

interface Props {
  required?: boolean;
  selected?: MinimalAccountObject;
  onSelect?(selected?: MinimalAccountObject): void;
}

const useStyles = createUseStyles({
  menu: {
    overflowY: 'scroll',
    width: '100%',
    maxHeight: 336,
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    lineHeight: '3em',
  },
  icon: {
    marginRight: '0.5em',
  },
});

const AccountsSelector = observer(({ required, selected, onSelect }: Props) => {
  const { auth } = useStores();

  const [isOpen, setIsOpen] = useState(false);
  const [results, setResults] = useState<MinimalAccountObject[]>([]);

  const [searchQuery, setSearchQuery] = useDebounceState<string>(
    selected?.name ?? '',
    (query) => lodResult(query),
    400,
  );

  const menuRef = useRef(null);
  const styles = useStyles();

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(e.target.value);

    if (!isOpen) {
      setIsOpen(true);
    }

    if (e.target.value === '' && onSelect) {
      onSelect();
      setIsOpen(false);
    }
  };

  const onPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.keyCode === 8 && selected && onSelect) {
      onSelect();
    }
  };

  const select = (item: MinimalAccountObject) => {
    if (onSelect) {
      onSelect(item);
    }
    setIsOpen(false);
    setSearchQuery('');
  };

  const lodResult = (query: string) =>
    apiAccounts
      .adminQueryMinimal(auth, query)
      .then((res) => setResults(res.items));

  useClickOutside(menuRef, () => setIsOpen(false));

  return (
    <OutlinedInput
      readOnly={!onSelect}
      type="text"
      placeholder="Select account..."
      onChange={onChange}
      onKeyDown={onPress}
      value={selected?.name || searchQuery}
      required={required}
      icon={
        selected ? (
          <Icon className={styles.icon} type={IconType.Company} />
        ) : (
          <Icon className={styles.icon} type={IconType.SearchGrey} />
        )
      }
      dropdown={
        isOpen &&
        !!results.length && (
          <Card className={styles.menu}>
            <div ref={menuRef}>
              {results.map((item) => {
                return (
                  <div key={item.id} onClick={() => select(item)}>
                    {item.name}
                  </div>
                );
              })}
            </div>
          </Card>
        )
      }
    />
  );
});

export default AccountsSelector;
