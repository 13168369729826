import { Select } from '@a_team/ui-components';
import { useQueryTalentIndustries } from '@src/rq/industries';
import React from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { FormValues } from '.';

interface IndustryProps {
  register: UseFormRegister<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  trigger: UseFormTrigger<FormValues>;
  watch: UseFormWatch<FormValues>;
}

const useStyles = createUseStyles({
  label: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  desc: {
    fontSize: '15px',
    fontWeight: 300,
    lineHeight: '24px',
    color: '#62646A',
    textAlign: 'start',
    margin: 0,
  },
  labelDescContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    marginBottom: 12,
  },
});

const Industry = ({
  watch,
  register,
  setValue,
  trigger,
}: IndustryProps): JSX.Element => {
  const styles = useStyles();

  const { data: allIndustries, isLoading } = useQueryTalentIndustries({
    query: '',
  });

  const options = allIndustries?.map((industry) => ({
    value: industry.id,
    label: industry.name,
  }));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const industriesSelected = watch('industries');

  const values = industriesSelected?.map((industry) => {
    const foundIndustry = allIndustries?.find((i) => i.id === industry);
    return {
      value: foundIndustry?.id,
      label: foundIndustry?.name,
    };
  });

  return (
    <div>
      <div className={styles.labelDescContainer}>
        <label className={styles.label}>Industry</label>
        <span className={styles.desc}>Add up to 2 industries.</span>
      </div>
      <Select
        alwaysShowCounter={true}
        isMulti={true}
        styles={{
          control: (base) => ({
            ...base,
            border: '1px solid #DADADC',
          }),
          placeholder: (base) => ({
            ...base,

            '& span': {
              display: values?.length === 0 ? 'none' : 'inline',
              backgroundColor: '#6D00D7 !important',
              borderRadius: '8px !important',
            },
          }),
        }}
        {...register('industries')}
        value={values}
        placeholder={
          values?.length === 0
            ? 'Select industry'
            : values?.length === 1
            ? `Selected industry`
            : `Selected industries`
        }
        onChange={(value) => {
          const newValues = value as { value: string; label: string }[];

          const newSelectedIndustries = newValues.map(
            (industry) => industry.value,
          );

          setValue('industries', newSelectedIndustries, {
            shouldDirty: true,
          });
          trigger('industries');
        }}
        options={options}
      />
    </div>
  );
};

export default Industry;
