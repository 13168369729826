import React, { ReactElement, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import OutlinedInput from '../Inputs/OutlinedInput';

interface Props {
  numericValue?: number;
  name: string;
  onChange: (value: number | undefined) => void;
}

const useStyles = createUseStyles({
  integerInput: {
    margin: 0,
    '& > div': {
      borderRadius: 4,
      minHeight: 'unset',
      padding: '0.6em 0.5em',
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 14,
    lineHeight: '2.6em',
    color: '#62646A',
  },
});

const RankingParam = (props: Props): ReactElement => {
  const styles = useStyles();
  const { numericValue, name, onChange } = props;
  const [value, setValue] = React.useState(numericValue);

  const defaults_dict = {
    'Score Strictness Factor': 0.5,
    'Hourly Rate Decay Rate Outside': 2.6,
    'Hourly Rate Default Boosting Factor If Missing': 0,
    'Hourly Rate Min Boosting Factor': 0.002,
    'Hourly Rate Within Range Boosting Factor': 50,
  };

  useEffect(() => {
    setValue(numericValue);
  }, [numericValue]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    try {
      onChange(parseFloat(e.target.value));
    } catch (e) {
      onChange(numericValue);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setValue(parseFloat(e.target.value));
    } catch (e) {
      setValue(numericValue);
    }
  };

  return (
    <div style={{ marginTop: 12 }}>
      <div className={styles.titleContainer}>
        <span className={styles.label}>{name}</span>
      </div>
      <OutlinedInput
        type="number"
        className={styles.integerInput}
        onBlur={handleBlur}
        onChange={handleChange}
        value={value || ''}
        placeholder={
          name in defaults_dict
            ? 'Default value: ' +
              String(defaults_dict[name as keyof typeof defaults_dict])
            : 'Default value: 1'
        }
      />
    </div>
  );
};

export default RankingParam;
