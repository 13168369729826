import { Button, Icon } from '@a_team/ui-components';
import { Breakpoints, Colors } from '@ateams/components';
import CompanyLogo from '@src/components/CompanyLogo';
import TextInput from '@src/components/Inputs/TextInput/TextInput';
import React from 'react';
import { createUseStyles } from 'react-jss';
import shareRateIcon from './share-rate.svg';
import { useCommonStyles } from './commonStyles';

interface ShareRateProps {
  companyImageUrl?: string | null;
  onBack: () => void;
  onSchedule: () => void;
  onChange: (value: number | undefined) => void;
  builderHourlyRate?: number;
  isLoading?: boolean;
  clientMinHourlyRateForBuilder?: number;
  clientMaxHourlyRateForBuilder?: number;
}

const useStyles = createUseStyles({
  hourlyRateInputsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputWrapper: {
    position: 'relative',
    margin: 0,
  },
  prefix: {
    position: 'absolute',
    left: 10,
    top: 9,
  },
  rateContainer: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 12,
  },
  suffix: {
    position: 'absolute',
    right: 10,
    top: 9,
  },
  rateInput: {
    border: '1px solid #DADADC',
    borderRadius: 4,
    padding: '8px 26px 8px 26px',
    width: 135,
    fontWeight: 400,
  },
  rateInfo: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: Colors.regularDark,
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    rateContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
});

const isValid = (text: string) => {
  return !isNaN(Number(text));
};

const ShareRate = ({
  companyImageUrl,
  onBack,
  onSchedule,
  onChange,
  builderHourlyRate,
  clientMinHourlyRateForBuilder,
  clientMaxHourlyRateForBuilder,
  isLoading,
}: ShareRateProps): JSX.Element => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.logoContainer}>
        <CompanyLogo logoUrl={companyImageUrl} size={72} />
        <img
          className={commonStyles.indicatorIcon}
          src={shareRateIcon}
          alt="share rate"
        />
      </div>
      <h4 className={commonStyles.title}>
        Before we schedule the interview, share your preferred rate
      </h4>
      {clientMinHourlyRateForBuilder && clientMaxHourlyRateForBuilder && (
        <div
          className={commonStyles.desc}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: Colors.regularDark,
          }}
        >
          <Icon name="money" style={{ color: Colors.regularDark }} /> Company’s
          budget — ${clientMinHourlyRateForBuilder} to $
          {clientMaxHourlyRateForBuilder} per hour
        </div>
      )}
      <div className={styles.rateContainer}>
        My hourly rate is{' '}
        <div className={styles.hourlyRateInputsWrapper}>
          <div className={styles.inputWrapper}>
            <span className={styles.prefix}>$</span>
            <TextInput
              variant="dashed"
              className={styles.rateInput}
              value={builderHourlyRate}
              onChange={(e) => {
                if (!isValid(e.target.value)) return;

                onChange(Number(e.target.value));
              }}
            />
            <span className={styles.suffix}>/h</span>
          </div>
        </div>
      </div>

      {(!builderHourlyRate ||
        (!!clientMaxHourlyRateForBuilder &&
          builderHourlyRate <= clientMaxHourlyRateForBuilder)) && (
        <div className={styles.rateInfo} style={{ marginTop: 8 }}>
          You can adjust your rate if the interview reveals new details that
          affect your rate.
        </div>
      )}
      {!!builderHourlyRate &&
        !!clientMaxHourlyRateForBuilder &&
        builderHourlyRate > clientMaxHourlyRateForBuilder && (
          <>
            <div className={styles.rateInfo} style={{ marginTop: 8 }}>
              Your rate is above the client's budget.
            </div>
            <div className={styles.rateInfo}>
              You can still request this rate, but it may limit your chances of
              being selected.
            </div>
          </>
        )}

      <div className={commonStyles.btnsContainer}>
        <Button onClick={onBack} disabled={isLoading} variant="secondary">
          Back
        </Button>
        <Button
          loading={isLoading}
          disabled={!builderHourlyRate || isLoading}
          onClick={onSchedule}
        >
          Schedule
        </Button>
      </div>
    </div>
  );
};

export default ShareRate;
