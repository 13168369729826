import { Editor, EditorContent } from '@tiptap/react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import MenuBar from './MenuBar';
import cx from 'classnames';
import { Breakpoints } from '@ateams/components';

interface TextEditorProps {
  editor: Editor;
  charCount?: number;
  readOnly?: boolean;
  maxCharCount?: string;
  hideHeading?: boolean;
}

const useStyles = createUseStyles({
  root: {
    '& h3': {
      color: '#222222',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '22px',
    },
    '& p': {
      color: '#222222',
      fontSize: 15,
      fontWeight: 300,
      lineHeight: '20px',
    },
  },
  textEditorWrapper: {
    marginTop: 8,
    border: '1px solid #DADADC',
    borderRadius: 8,
    padding: 16,

    position: 'relative',
    '& .ProseMirror': {
      padding: 0,
      border: 'none',
      marginBottom: 40,
    },
    '& .ProseMirror-focused': {
      outline: 'none',
    },
    '& .ProseMirror h3.is-empty:nth-child(1)::before': {
      color: '#62646A',
      content: 'attr(data-placeholder)',
      float: 'left',
      height: 0,
      pointerEvents: 'none',
    },
    '& .ProseMirror p.is-empty:nth-child(2):last-child::before': {
      color: '#62646A',
      content: 'attr(data-placeholder)',
      float: 'left',
      height: 0,
      pointerEvents: 'none',
    },
  },
  textEditorWrapperReadOnly: {
    maxWidth: 650,
    '& h3': {
      marginTop: 40,
      marginBottom: 16,
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '150%',
    },
    '& p': {
      marginTop: 0,
      fontSize: 16,
      marginBottom: 0,
      fontWeight: 300,
      lineHeight: '170%',
    },
    '& ul, ol': {
      paddingLeft: 15,
    },
  },
  charWrapper: {
    position: 'absolute',
    bottom: 0,
    right: 16,
    fontSize: 12,
    lineHeight: '16px',
    paddingLeft: 32,
    paddingBottom: 16,
    width: '100%',
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    textEditorWrapper: {
      '& .ProseMirror': {
        marginBottom: 35,
      },
    },
  },
});

const TextEditor = ({
  charCount,
  editor,
  readOnly,
  maxCharCount,
  hideHeading,
}: TextEditorProps) => {
  const styles = useStyles();

  return (
    <div
      className={cx(
        styles.root,
        readOnly ? styles.textEditorWrapperReadOnly : styles.textEditorWrapper,
      )}
    >
      {!readOnly && (
        <MenuBar
          editor={editor}
          nodesWithNoStyling={['title']}
          hideHeading={hideHeading}
        />
      )}
      <EditorContent editor={editor} />

      {!readOnly && (
        <div className={styles.charWrapper}>
          <div>
            <div>100 characters minimum</div>
            <div>
              {charCount}/{maxCharCount || '1,000'} characters
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextEditor;
