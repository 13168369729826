import React, { MutableRefObject, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Colors, Icon, IconType } from '@ateams/components';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'stretch',
    width: '100%',
    padding: '0.5em',
    backgroundColor: Colors.backgroundWhite,
    borderRadius: '5px',
    boxShadow: `0 0 5px ${Colors.regularLight}`,
    border: `1px solid ${Colors.regularLight}`,
    marginBottom: '0.5em',
    marginTop: '0.5em',
  },
  searchButton: {
    background: Colors.primary,
    color: Colors.backgroundWhite,
    cursor: 'pointer',
    border: 0,
    borderRadius: '5px',
    maxHeight: '32px',
  },
  icon: {
    cursor: 'pointer',
    margin: '4px',
    color: Colors.backgroundWhite,
  },
  keywordSearch: {
    border: 0,
    position: 'relative',
    width: '100%',
    textAlign: 'left',
    verticalAlign: 'middle',
    '&:focus': {
      outline: 'none',
    },
  },
  highlighted: {
    color: Colors.secondaryDark,
    textDecoration: 'underline',
  },
  highlightColors: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    userSelect: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  highlightEditable: {
    position: 'relative',
    color: 'transparent',
    caretColor: 'black',
    '&:focus': {
      outline: 'none',
    },
    '&[placeholder]:empty:before': {
      content: 'attr(placeholder)',
      color: '#555',
    },
    '&[placeholder]:empty:focus:before': {
      content: '""',
    },
  },
});

interface Props {
  updateFilter: (keywords?: string) => void;
  value?: string;
  placeholder?: string;
  keywordRef: MutableRefObject<HTMLDivElement | null>;
}

function setEndOfContenteditable(contentEditableElement: Node) {
  const sel = window.getSelection();
  if (!sel) return;
  sel.selectAllChildren(contentEditableElement);
  sel.collapseToEnd();
}

export function SearchKeywordBox(props: Props) {
  const { updateFilter, keywordRef, value: keywordValue, placeholder } = props;
  const styles = useStyles();
  const ref2 = React.useRef<HTMLDivElement>(null);

  const highLite = (el: HTMLDivElement | null) => {
    if (!el || !el.previousElementSibling || !ref2 || !ref2.current) {
      return;
    }
    const reg = new RegExp('( AND| OR|name:|rate:)', 'gi');
    ref2.current.innerHTML = el.innerHTML.replace(
      reg,
      (match) => ` <span class="${styles.highlighted}">${match.trim()}</span>`,
    );
  };
  useEffect(() => {
    highLite(keywordRef.current);
    setEndOfContenteditable(keywordRef.current as Node);
  }, [keywordValue]);

  return (
    <div className={styles.container}>
      <div className={styles.keywordSearch}>
        <div
          id={'targeter-keyword-highlight'}
          ref={ref2}
          className={styles.highlightColors}
          placeholder={'Keyword Search...'}
        >
          {keywordValue}
        </div>
        <div
          id={'targeter-keyword-editable'}
          ref={keywordRef}
          className={styles.highlightEditable}
          contentEditable={true}
          suppressContentEditableWarning={true}
          autoCorrect={'off'}
          spellCheck={false}
          placeholder={placeholder || 'Keyword Search...'}
          onInput={(event) => {
            highLite(event.currentTarget as HTMLDivElement);
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              updateFilter(event.currentTarget.innerText);
              event.preventDefault();
              return;
            }
          }}
          onPaste={(event) => {
            event.preventDefault();
            const text = event.clipboardData.getData('text/plain');
            document.execCommand('insertHTML', false, text);
          }}
        >
          {keywordValue}
        </div>
      </div>
      <button
        id="targeter-keyword-search-button"
        className={styles.searchButton}
        onClick={() => updateFilter(keywordRef.current?.innerText)}
      >
        <Icon type={IconType.Search} size={'small'} className={styles.icon} />
      </button>
    </div>
  );
}
