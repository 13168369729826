import { SurveyQuestionType } from '@a_team/models/dist/Survey';
import React, { ReactElement } from 'react';
import RatingWithFreeFormCommentResponse from './RatingWithFreeFormCommentResponse';
import { ResponseProps } from './ResponseProps';

export type Props =
  ResponseProps<SurveyQuestionType.PersonRatingWithFreeFormComment>;

const PersonRatingWithFreeFormCommentResponse = (
  props: Props,
): ReactElement | null => {
  const { question, response, children, ...rest } = props;
  return (
    <RatingWithFreeFormCommentResponse
      question={{
        type: SurveyQuestionType.RatingWithFreeFormComment,
        props: question.props,
      }}
      response={
        response && { value: response.value, comment: response.comment }
      }
      {...rest}
    >
      {response?.unableToRespond && <p>I didn’t work with this teammate.</p>}
      {children}
    </RatingWithFreeFormCommentResponse>
  );
};

export default PersonRatingWithFreeFormCommentResponse;
