import { ServiceAuth, ServiceEndpoint } from './utils';
import ContractObject, {
  BasicContractObject,
  ContractId,
} from '@a_team/models/dist/ContractObject';
import { QueryNextToken, QueryResult } from '@a_team/models/dist/misc';

export const BasePath = '/contracts';

// exported functions

export default class ContractsEndpoint extends ServiceEndpoint {
  public getById(auth: ServiceAuth, sid: ContractId): Promise<ContractObject> {
    return this.fetch(auth, BasePath + `/contract/${sid}`);
  }

  public sign(auth: ServiceAuth, sid: ContractId): Promise<ContractObject> {
    return this.fetch(auth, BasePath + `/contract/${sid}/sign`, null, 'post');
  }

  public getUnsignedContracts(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<QueryResult<BasicContractObject>> {
    return this.fetch(auth, BasePath + `/unsigned`, { next });
  }

  public deleteCustomContract(
    auth: ServiceAuth,
    sid: ContractId,
  ): Promise<ContractObject> {
    return this.fetch(auth, BasePath + `/contract/${sid}`, null, 'delete');
  }
}
