import React, { ReactElement, ReactNode } from 'react';

/**
 * Truncates a given string according to a given char limit and adds '...'
 * @param {String} string - The string to truncate
 * @param {Number} limit - The chars limit to cut at string at
 */
export const truncate = (string: string, limit: number): string =>
  string.length > limit ? `${string.substring(0, limit)}…` : string;

/**
 * Generates a paragraphed version of a text with line breaks
 * @param {String} string - The string to break into paragraphs
 */
export const splitToParagraphs = (string: string): ReactNode => {
  return string
    .split(/(\s*\n)+\s*/g)
    .map((p, i): ReactElement => <p key={`p-${i}`}>{p}</p>);
};

/**
 * Checks if a given string is a valid image url
 * @param {String} url - The url to check
 */
export const isImageUrl = (url: string): boolean => {
  const regex = /^(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|jpeg|gif|png)$/;
  return regex.test(url);
};

export const getHQAddress = (part?: string): string => {
  // This is CRAZY we store this info on a react utils package
  // not sure even what was the reason for moving this out of the api-service
  // TODO move this back to the api-service and fetch it from the server

  const city = 'New York';
  const state = 'NY';
  const zip = '10003';

  const address: Record<string, string> = {
    name: 'ATeams Inc.',
    line1: '88 University Place',
    line2: '5th floor',
    city,
    state,
    zip,
    country: 'USA',
    rest: `${city}, ${state} ${zip}`,
  };

  return part
    ? address[part]
    : `${address.line1}, ${address.line2}, ${address.city} ${address.state} ${address.zip}`;
};
