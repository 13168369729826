import TextAreaInput from '@src/components/Inputs/TextAreaInput';
import React from 'react';
import { createUseStyles } from 'react-jss';
import ControlButtons from './common/ControlButtons';
import { Breakpoints, Checkbox } from '@ateams/components';
import {
  IMemberResponse,
  TeamPulseUserReviewSectionsEnum,
} from './TeamPulseModal';
import TeamMemberCard from './common/TeamMemberCard';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import HiddenMembers from './HiddenMembers';

interface TeamMemberFeedbackProps {
  setSection: (section: string) => void;
  moveUserToReview: (uid: string) => void;
  toggleHiddenUsers: boolean;
  setToggleHiddenUsers: (toggle: boolean) => void;
  isDesktop: boolean;
  hiddenUsers: BasicUserObject[] | undefined;
  missionTitle: string;
  onNextMember: () => void;
  teamMember: BasicUserObject | undefined;
  handleTeamMemberFeedback: (
    shareFeedbackWithTeammate: boolean,
    comment?: string,
  ) => void;
  memberResponse?: IMemberResponse;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    height: '100%',
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'start',
  },
  desc: {
    textAlign: 'start',
    color: '#707275',
    marginBottom: 40,
    marginTop: 8,
  },
  textArea: {
    border: '1px solid #D1D2D6',
    height: 140,
    // border-radius only for the top corners
    borderRadius: '8px 8px 0 0',
    padding: 10,
  },
  checkboxWrapper: {
    height: 'fit-content',
    display: 'flex',
    gap: 10,
    padding: '15px 10px',
    // border only for the bottom corners
    // border on right, left and bottom only
    borderLeft: '1px solid #D1D2D6',
    borderRight: '1px solid #D1D2D6',
    borderBottom: '1px solid #D1D2D6',
    borderRadius: '0 0 8px 8px',
  },
  checkbox: {
    margin: 0,
  },
  textAreaCheckboxContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    padding: 20,
  },
  bottomContainer: {
    width: '100%',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    wrapper: {
      padding: 0,
    },
    bottomContainer: {
      position: 'sticky',
      bottom: 0,
    },
  },
});

const TeamMemberFeedback = ({
  setSection,
  onNextMember,
  missionTitle,
  teamMember,
  memberResponse,
  handleTeamMemberFeedback,
  hiddenUsers,
  isDesktop,
  moveUserToReview,
  toggleHiddenUsers,
  setToggleHiddenUsers,
}: TeamMemberFeedbackProps): JSX.Element => {
  const styles = useStyles();
  const [text, setText] = React.useState(memberResponse?.comment || '');
  const [shareFeedbackWithTeamMember, setShareFeedbackWithTeamMember] =
    React.useState(memberResponse?.shareFeedbackWithTeammate || false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setText(e.target.value.trimStart());
  };

  const onBack = (): void => {
    setSection(TeamPulseUserReviewSectionsEnum.TeamMemberRating);
  };

  const onContinue = (): void => {
    handleTeamMemberFeedback(shareFeedbackWithTeamMember, text);
    onNextMember();
  };

  const onShareFeedbackWithTeamMemberChange = (): void => {
    setShareFeedbackWithTeamMember(!shareFeedbackWithTeamMember);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {teamMember && (
          <TeamMemberCard
            fullName={teamMember?.fullName}
            profileImg={teamMember?.profilePictureURL}
            simple
          />
        )}
        <h4 className={styles.title}>
          What have you liked about teaming up with {teamMember?.firstName} —
          and what could be improved?
        </h4>
        <p className={styles.desc}>
          This question is optional and will not be shared with {missionTitle}{' '}
          or {teamMember?.firstName}.
        </p>
        <div className={styles.textAreaCheckboxContainer}>
          <TextAreaInput
            value={text}
            className={styles.textArea}
            onChange={handleChange}
            placeholder="Please share your feedback here"
          />
          <div className={styles.checkboxWrapper}>
            <Checkbox
              id="shareFeedbackWithTeamMember"
              className={styles.checkbox}
              onChange={onShareFeedbackWithTeamMemberChange}
              checked={shareFeedbackWithTeamMember}
              disabled={text.length === 0}
            />
            <label htmlFor="shareFeedbackWithTeamMember">
              Share this feedback with my teammate
            </label>
          </div>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        {!isDesktop && hiddenUsers && !!hiddenUsers.length && (
          <HiddenMembers
            toggleHiddenUsers={toggleHiddenUsers}
            setToggleHiddenUsers={setToggleHiddenUsers}
            hiddenUsers={hiddenUsers}
            moveUserToReview={moveUserToReview}
          />
        )}

        <ControlButtons onBack={onBack} onContinue={onContinue} />
      </div>
    </div>
  );
};

export default TeamMemberFeedback;
