import { getLocalTime } from '@src/helpers/time';
import { uuid } from 'uuidv4';
import { format } from 'date-fns';
import { TimeEntry } from './TanstackTable';
import { isEqual, sortBy } from 'lodash';
import { TimesheetRecordType } from '@a_team/models/dist/TimesheetObject';

export const generateUniqueKey = () => {
  return uuid();
};

export const formatDate = (date: number) => {
  return format(getLocalTime(new Date(date)), 'EEE, LLL d');
};

export const isRowFilled = (row: TimeEntry) => {
  return !!row.time || !!row.type || !!row.task || row.initiativeIds.length > 0;
};

export const areArraysEqualRegardlessOfOrder = (
  arr1: string[],
  arr2: string[],
) => {
  return isEqual(sortBy(arr1), sortBy(arr2));
};

export const TIMESHEET_TASK_MIN_LENGTH = 10;

export const checkIfRowIsValid = (
  row: TimeEntry,
  isFullTimeRetainer: boolean,
) => {
  // if no single field is set
  // or all fields are set
  // or it is OOO
  // then the row is valid
  return (
    ((!row.time || isFullTimeRetainer) &&
      !row.type &&
      !row.task &&
      row.initiativeIds.length === 0) ||
    ((row.time || isFullTimeRetainer) &&
      row.type &&
      row.task &&
      row.task.length >= TIMESHEET_TASK_MIN_LENGTH &&
      row.initiativeIds.length > 0) ||
    (row.type &&
      [
        TimesheetRecordType.TimeOff,
        TimesheetRecordType.SickDay,
        TimesheetRecordType.Holiday,
      ].includes(row.type))
  );
};
