import React from 'react';
import SectionHeading from '@src/components/SectionHeading';
import Modal, { ModalBasicProps } from '..';
import { Button as CallToActionButton } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { VetterId, VetterSuggestion } from '@a_team/models/dist/vetter';
import {
  OnVetterSuggestionCheck,
  VetterSuggestionRow,
} from './vetter-suggestion-row';

export type OnMatchInterviewer = (selectedSuggestion?: VetterId) => void;
export type OnInterviewerSelect = (
  selectedSuggestion: VetterSuggestion,
) => void;

export interface VetterSuggestionsModalProps extends ModalBasicProps {
  suggestions: VetterSuggestion[];
  selectedSuggestion?: VetterId;
  onChange: OnInterviewerSelect;
  onConfirm: OnMatchInterviewer;
  loading?: boolean;
}

const useStyles = createUseStyles({
  container: {
    overflow: 'auto',
    padding: '20px 24px',
    display: 'flex',
    flexDirection: 'column',
  },
  actionsBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '32px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  button: {
    marginRight: '24px',
  },
});

export const VetterSuggestionsModal: React.FC<VetterSuggestionsModalProps> = (
  props,
) => {
  const styles = useStyles();
  const { suggestions, selectedSuggestion, onChange, loading, open, onClose } =
    props;

  const onConfirm = () => {
    props.onConfirm(selectedSuggestion);
  };

  const onVetterSuggestionCheck: OnVetterSuggestionCheck = (suggestion) => {
    onChange(suggestion);
  };

  return (
    <Modal open={open} onClose={onClose} style={{ padding: 0, minWidth: 700 }}>
      <div className={styles.container}>
        <SectionHeading isFirst>Interviewers Suggestions</SectionHeading>

        <table>
          <tbody>
            {suggestions.map((suggestion) => (
              <VetterSuggestionRow
                key={suggestion.id}
                suggestion={suggestion}
                checked={suggestion.id === selectedSuggestion}
                onCheck={onVetterSuggestionCheck}
              />
            ))}
          </tbody>
        </table>

        <div className={styles.actionsBar}>
          <CallToActionButton
            size="small"
            color="regular"
            className={styles.button}
            onClick={onClose}
          >
            Cancel
          </CallToActionButton>
          <CallToActionButton
            size="small"
            className={styles.button}
            disabled={loading || !selectedSuggestion}
            onClick={onConfirm}
          >
            Set Interviewer
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
};
