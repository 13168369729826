export const getEmailCategoryLabel = (category: string) => {
  switch (category) {
    case 'MissionNotification':
      return 'Mission notifications';
    case 'AvailabilityReminder':
      return 'Availability reminders';
    case 'Community':
      return 'Community notifications';
    case 'CompanyMarketing':
      return 'Company marketing notifications';
    case 'Referrals':
      return 'Referral notifications';
    default:
      return 'notifications';
  }
};

export const getEmailCategoryShortLabel = (category: string) => {
  switch (category) {
    case 'MissionNotification':
      return 'mission';
    case 'AvailabilityReminder':
      return 'availability reminder';
    case 'Community':
      return 'community';
    case 'CompanyMarketing':
      return 'marketing';
    case 'Referrals':
      return 'referral';
    default:
      return 'notification';
  }
};
