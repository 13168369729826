import { Breakpoints, Button } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import teamPulseStartIcon from './assets/teampulse-start.svg';
import teamPulseStartLongIcon from './assets/teampulse-start-long.svg';
import { TeamPulseSectionsEnum } from './TeamPulseModal';

interface TimeForTeamPulseProps {
  setSection: (section: string) => void;
  longForm?: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  timeBadge: {
    backgroundColor: '#F7F7F9',
    color: 'black',
    border: '1px solid #D1D2D6',
    borderRadius: '8px',
    padding: '4px 8px',
    marginTop: 40,
  },
  title: {
    marginTop: 16,
    marginBottom: 0,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
  },
  desc: {
    color: '#707275',
    marginBottom: 40,
    marginTop: 8,
    textAlign: 'center',
    maxWidth: 440,
  },
  button: {
    width: 160,
    height: 40,
    padding: 0,
  },
  img: {
    width: '115px',
    height: '120px',
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '350px',
    background: '#F7F7F7',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    imgContainer: {
      height: 'auto',
      background: 'none',
    },
    wrapper: {
      padding: 0,
    },
  },
});

const TimeForTeamPulse = ({
  setSection,
  longForm,
}: TimeForTeamPulseProps): JSX.Element => {
  const styles = useStyles();

  const onStartTeamPulse = () => {
    setSection(TeamPulseSectionsEnum.HowsTheMissionIsGoing);
  };
  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <img
          className={styles.img}
          src={longForm ? teamPulseStartLongIcon : teamPulseStartIcon}
          alt="team pulse start"
        />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.timeBadge}>
          {longForm ? '5' : '2'} minutes to finish
        </div>
        <h4 className={styles.title}>
          {longForm
            ? 'Review Your Mission & Teammates'
            : 'Time for a Team Pulse'}
        </h4>
        <p className={styles.desc}>
          {longForm
            ? `This Team Pulse will be a bit longer. We'll ask for your feedback on your teammates and your mission.`
            : `Let’s get some feedback before you submit your timesheet, so we can set you up for success.`}
        </p>
        <Button
          onClick={onStartTeamPulse}
          className={styles.button}
          data-testing-id="start-team-pulse-button"
        >
          Start Team Pulse
        </Button>
      </div>
    </div>
  );
};

export default TimeForTeamPulse;
