import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import { History } from 'history';
import { useStores } from '@src/stores';
import { RootLocation } from '@src/locations';
import MainLayout from '@src/layouts/Main';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { createUseStyles } from 'react-jss';
import SectionHeading from '@src/components/SectionHeading';
import { Button as CallToActionButton } from '@ateams/components';
import { match } from 'react-router-dom';
import { UserId } from '@a_team/models/dist/UserObject';
import { useStrongPassword } from '@src/hooks/useStrongPassword';
import { ZxcvbnResult } from '@zxcvbn-ts/core';
import PasswordStrengthIndicator from '@src/components/PasswordStrenghtIndicator';
import { MIN_SCORE } from '@src/helpers/auth';

type Match = match<{ uid: UserId; hashCode: string }>;

interface Props {
  history: History;
  match: Match;
}

interface UpdatePasswordPayload {
  password: string;
  confirmPassword: string;
}

const useStyles = createUseStyles({
  signInCTA: {
    width: '280px',
    position: 'absolute',
    bottom: '-27px',
  },
  form: {
    position: 'relative',
    backgroundColor: '#fff',
    padding: '24px',
    borderRadius: '1em',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.08)',
    width: '384px',
    margin: '5em auto 1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  password1: {
    marginTop: '40px',
  },
  password2: {
    marginTop: '0px',
    marginBottom: '40px',
  },
});

export default function UpdatePasswordView(props: Props): ReactElement {
  const { history, match } = props;
  const { auth } = useStores();

  const { uid, hashCode } = match.params;

  const [data, setData] = useState<Partial<UpdatePasswordPayload>>({});
  const [loading, setLoading, error] = useLoadingState();
  const [passWordStrength, setPassWordStrength] = useState<ZxcvbnResult | null>(
    null,
  );

  const { checkPasswordStrength } = useStrongPassword();

  const onCheckPasswordStrength = async () => {
    if (!data.password) {
      setPassWordStrength(null);
      return;
    }
    const result = await checkPasswordStrength(data.password);
    setPassWordStrength(result);
  };

  useEffect(() => {
    onCheckPasswordStrength();
  }, [data.password]);

  const isValid =
    loading !== true &&
    data.password &&
    data.confirmPassword &&
    data.password === data.confirmPassword &&
    (!passWordStrength?.feedback.suggestions.length ||
      passWordStrength.score >= MIN_SCORE);

  const styles = useStyles();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleInputChange =
    (name: keyof UpdatePasswordPayload) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setData({ ...data, [name]: e.target.value });
    };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    data.confirmPassword &&
      setLoading(
        auth
          .resetPassword(uid, {
            password: data.confirmPassword,
            hashCode: hashCode,
          })
          .then((): string => {
            history.push(RootLocation);
            return 'Password was updated!';
          }),
      );
  };

  const ignoreSubmit = (e: FormEvent): void => {
    e.preventDefault();
  };

  return (
    <MainLayout title="Update Password" naked>
      <form
        onSubmit={isValid ? handleSubmit : ignoreSubmit}
        className={styles.form}
      >
        <SectionHeading
          isFirst
          style={{
            alignSelf: 'flex-start',
            marginBottom: 0,
            marginTop: '16px',
          }}
        >
          Update Password
        </SectionHeading>
        <div style={{ marginTop: '16px', alignSelf: 'flex-start' }}>
          Please enter your new password.
        </div>
        <OutlinedInput
          type="password"
          placeholder="New password..."
          name="password"
          onChange={handleInputChange('password')}
          value={data.password || ''}
          disabled={loading === true}
          required
          error={!!error}
          className={styles.password1}
        />
        <div
          style={{
            width: '100%',
            marginBottom: '32px',
          }}
        >
          <div>
            {passWordStrength?.feedback.suggestions.map((suggestion) => (
              <small
                key={suggestion}
                style={{ color: '#ff0000', display: 'block' }}
              >
                {suggestion}
              </small>
            ))}
          </div>
          {passWordStrength && (
            <PasswordStrengthIndicator score={passWordStrength.score} />
          )}
        </div>

        <OutlinedInput
          type="password"
          placeholder="Validate password..."
          name="confirmPassword"
          onChange={handleInputChange('confirmPassword')}
          value={data.confirmPassword || ''}
          disabled={loading === true}
          required
          error={!!error}
          className={styles.password2}
        />
        <CallToActionButton
          type="submit"
          disabled={!isValid}
          className={styles.signInCTA}
        >
          Update Password
        </CallToActionButton>
        <LoadingIndicator loading={loading} />
      </form>
    </MainLayout>
  );
}
