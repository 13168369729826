import { Expertise } from '@src/stores/Profile/models';
import React, { ReactElement } from 'react';
import { Tags } from './Tags';

interface DescriptionProps {
  missingRequiredSkills: Expertise[];
  missingPreferredSkills: Expertise[];
  missingRole: Expertise[];
  removePrefSkill: (skill: Expertise) => void;
}

const Description = ({
  missingRequiredSkills,
  missingPreferredSkills,
  missingRole,
  removePrefSkill,
}: DescriptionProps): ReactElement => {
  return (
    <>
      <p>
        The client is looking for a specific mix of role and skill experience
        for this position, and we noticed you are missing some of these on your
        profile. Please add these to apply to the position. Click ‘Continue &
        Update Skills’ to add these skills to your profile and continue the
        application process.
        {missingPreferredSkills.length > 0
          ? ' Note: you are not required to add the “Nice-to-Have Skills”.'
          : ''}
      </p>
      {missingRole.length > 0 && <Tags items={missingRole} title="Role" />}
      {missingRequiredSkills.length > 0 && (
        <Tags items={missingRequiredSkills} title="Must-Have Skills" />
      )}
      {missingPreferredSkills.length > 0 && (
        <Tags
          items={missingPreferredSkills}
          title="Nice-to-Have Skills"
          onRemove={removePrefSkill}
        />
      )}
    </>
  );
};

export default Description;
