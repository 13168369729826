import { Button as CallToActionButton } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import MissionRole, {
  MissionAdminRole,
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';

interface RequestToJoinProps {
  role: MissionRole | MissionAdminRole;
  referralMode?: boolean;
  onApply?(): void;
  onEdit?(): void;
  currentUserHasRole?: MissionRole;
}

const useStyles = createUseStyles({
  requestToJoin: {
    width: '100%',
    color: '#FFFFFF',
    fontSize: '14px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    requestToJoin: {
      fontSize: '15px',
    },
  },
});

function RequestToJoin({
  role,
  referralMode,
  onApply,
  onEdit,
  currentUserHasRole,
}: RequestToJoinProps): JSX.Element {
  const styles = useStyles();

  return (
    <>
      {!referralMode ? (
        role.application ? (
          <>
            {!onEdit && <div>Applied</div>}
            {onEdit && (
              <div>
                <CallToActionButton
                  className={styles.requestToJoin}
                  disabled={false}
                  onClick={onEdit}
                  size="small"
                >
                  {role.application.withdrawn
                    ? 'Request to join'
                    : 'Edit application'}
                </CallToActionButton>
              </div>
            )}
          </>
        ) : (
          currentUserHasRole?.status !== MissionRoleStatus.Active && (
            <div>
              <CallToActionButton
                disabled={!onApply}
                onClick={onApply}
                className={styles.requestToJoin}
                size="small"
                data-testing-id="request-to-join-button"
              >
                Request to join
              </CallToActionButton>
            </div>
          )
        )
      ) : null}
    </>
  );
}

export default RequestToJoin;
