import { Avatar } from '@a_team/ui-components';
import { useStores } from '@src/stores';
import React from 'react';
import { createUseStyles } from 'react-jss';
import CommonStyles from '../common/styles';
import { DateISOString } from '@a_team/models/dist/misc';
import { format } from 'date-fns';
import { useQueryTalentSpecializationRoles } from '@src/rq/specializations';

interface RoleProps {
  jobRole?: string;
  jobRoleId?: string;
  startDate?: DateISOString;
  endDate?: DateISOString;
}

const useStyles = createUseStyles({
  container: {
    marginTop: 64,
  },
  row: {
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  jobRole: {
    fontSize: 16,
    fontWeight: 500,
  },
  dates: {
    fontSize: 12,
  },
});

const Role = ({
  jobRole,
  jobRoleId,
  startDate,
  endDate,
}: RoleProps): JSX.Element | null => {
  const commonStyles = CommonStyles();
  const styles = useStyles();
  const { users } = useStores();
  const profile = users.profile;
  const { data: roles } = useQueryTalentSpecializationRoles({
    query: '',
  });

  if (!startDate && !endDate) {
    return null;
  }

  let roleName = '';

  if (jobRole) {
    roleName = jobRole;
  } else if (jobRoleId) {
    const role = roles?.find((role) => role.id === jobRoleId);
    roleName = role?.name ?? '';
  }

  if (!roleName) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={commonStyles.title}>Role</div>
      <div className={styles.row}>
        <div>
          <Avatar
            size={'lg'}
            styles={{
              borderRadius: 100,
            }}
            src={profile?.data?.profilePictureURL}
          />
        </div>
        <div>
          <div className={styles.jobRole}>{roleName}</div>
          {startDate && (
            <div className={styles.dates}>
              {format(new Date(startDate ?? ''), 'MMM, yyyy')} -{' '}
              {endDate
                ? format(new Date(endDate ?? ''), 'MMM, yyyy')
                : 'Present'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Role;
