import React, { ReactElement, useState, useEffect } from 'react';
import Modal, { ModalBasicProps } from '..';
import UserObject, {
  CurrentUserObject,
  BasicUserObject,
} from '@a_team/models/dist/UserObject';
import { createUseStyles } from 'react-jss';
import SectionHeading from '@src/components/SectionHeading';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { Button as CallToActionButton } from '@ateams/components';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { ReCaptcha } from 'react-recaptcha-v3';
import { RECAPTCHA_SITE_KEY } from '@src/config';
import { useSendMessage } from '@src/rq/messages';
import TextAreaInput from '@src/components/Inputs/TextAreaInput';
import { observer } from 'mobx-react';

interface Props extends ModalBasicProps {
  currentUser?: CurrentUserObject | null;
  profile: BasicUserObject | UserObject;
  apply?: boolean;
  title?: string;
  message?: string;
}

const useStyles = createUseStyles({
  messageWrapper: {
    padding: 10,
    border: '1px solid rgb(192, 192, 192)',
    borderRadius: 5,
    marginBottom: 24,
    textDecoration: 'none',
    '&::placeholder': {
      textDecoration: 'none',
    },
  },
  containerCTA: {
    display: 'flex',
    justifyContent: 'center',
  },
  recaptchaTerms: {
    textAlign: 'center',
    fontSize: 11,
    color: '#aaa',
    marginTop: '0.3em',
    '& > a': {
      color: 'inherit',
      textDecoration: 'underline',
    },
  },
});

const MessageModal = (props: Props): ReactElement => {
  const { open, onClose, profile, currentUser, title, message } = props;
  const [messageInput, setMessageInput] = useState(message ? message : '');
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [senderName, setSenderName] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [disabledCheck, setDisabledCheck] = useState(true);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const styles = useStyles();
  const { mutateAsync: sendMessageMutation } = useSendMessage();

  useEffect(() => {
    if (message) {
      setMessageInput(message);
    }
  }, [message]);

  useEffect(() => {
    if (currentUser) {
      if (messageInput.length < 1) {
        setDisabledCheck(true);
      } else {
        setDisabledCheck(false);
      }
    } else {
      if (
        messageInput.length > 0 &&
        senderName.length > 0 &&
        senderEmail.length > 0
      ) {
        setDisabledCheck(false);
      } else {
        setDisabledCheck(true);
      }
    }
  }, [messageInput, senderName, senderEmail]);

  const clearInputs = (): void => {
    setMessageInput('');
    setSenderName('');
    setSenderEmail('');
  };

  const verifyCallback = (recaptchaToken: string): void => {
    setRecaptchaToken(recaptchaToken);
  };

  const sendMessage = async (): Promise<void> => {
    const payload = {
      username: profile.username,
      message: messageInput,
      recaptchaToken,
      ...(!currentUser && {
        email: senderEmail,
        fullName: senderName,
      }),
    };
    setIsLoading(true);
    await sendMessageMutation(payload);
    setIsLoading(false);
    clearInputs();
    onClose();
  };

  return (
    <>
      <Modal
        onClose={onClose}
        open={open}
        style={{
          width: '496px',
          transition: 'all 0.5s',
          padding: '24px',
          color: '#222',
        }}
      >
        <SectionHeading
          isFirst
          style={{ marginTop: '16px', marginBottom: '32px' }}
        >
          {title ? title : `Message ${profile.firstName}`}
        </SectionHeading>
        {currentUser ? (
          <>
            <TextAreaInput
              className={styles.messageWrapper}
              disabled={!!isLoading}
              onChange={(e): void => setMessageInput(e.target.value)}
              placeholder="Write the message..."
              minHeight={135}
              autoFocus
              autoResize
            />
          </>
        ) : (
          <>
            <OutlinedInput
              style={{ width: '100%' }}
              value={senderName}
              placeholder={'Full name...'}
              onChange={(e): void => setSenderName(e.target.value)}
              disabled={!!isLoading}
            />
            <OutlinedInput
              style={{ width: '100%' }}
              value={senderEmail}
              placeholder={'Email...'}
              onChange={(e): void => setSenderEmail(e.target.value)}
              disabled={!!isLoading}
            />
            <TextAreaInput
              className={styles.messageWrapper}
              disabled={!!isLoading}
              onChange={(e): void => setMessageInput(e.target.value)}
              placeholder="Write the message..."
              minHeight={135}
              autoFocus
              autoResize
            />
          </>
        )}
        <div className={styles.containerCTA}>
          <ReCaptcha
            sitekey={RECAPTCHA_SITE_KEY}
            action="action_name"
            verifyCallback={verifyCallback}
          />
          <CallToActionButton
            disabled={disabledCheck || !!isLoading}
            onClick={(): void => {
              sendMessage();
            }}
          >
            Send
          </CallToActionButton>
        </div>
        <div className={styles.recaptchaTerms}>
          Protected by reCAPTCHA. &nbsp;{' '}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer noopener"
          >
            Privacy
          </a>{' '}
          &nbsp;{' '}
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noreferrer noopener"
          >
            Terms
          </a>
        </div>
      </Modal>
      <LoadingIndicator
        loading={isLoading}
        successMsg={`Your message was sent.`}
      />
    </>
  );
};

export default observer(MessageModal);
