import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  TeammateSuggestionObject,
  UserId,
} from '@a_team/models/dist/UserObject';
import { TeammateCard } from '@src/views/Application/TeammateRecommendations/TeammateCard';
import SectionHeading from '@src/components/SectionHeading';
import { Tag, TagList, TextColors } from '@ateams/components';
import { MinimalRecommendationData } from '@a_team/models/dist/RecommendationObject';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import { Search } from '@src/views/Application/TeammateRecommendations/Search';
import NoResults from '@src/components/NoResults';
import { ProfileVersion, useAnalytics } from '@ateams/analytics/dist/platform';
import MissionRole from '@a_team/models/dist/MissionRole';
import Profile from '@src/stores/Profile/Profile';
import ApplicationModal from '@src/components/Modal/ApplicationModal';

interface Props {
  cards: TeammateSuggestionObject[];
  searchResults?: TeammateSuggestionObject[];
  requestedTeammates: MinimalRecommendationData[];
  onRequest: (
    card: TeammateSuggestionObject,
    requested: boolean,
    source: 'recommendation' | 'search',
    placement: 'card' | 'modal',
  ) => void;
  onSearch: (query: string) => void;
  currentRole: MissionRole | null;
}

const useStyles = createUseStyles({
  container: {
    padding: '25px 15px',
    paddingTop: 150,
  },
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
    fontSize: 14,
    color: '#62646A',
    fontWeight: 400,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  cardsContainer: {
    display: 'block',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  noResults: {
    width: '100%',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      padding: 40,
    },
    cardsContainer: {
      display: 'flex',
    },
  },
});

const TeammateRecommendations = (props: Props): ReactElement => {
  const styles = useStyles();
  const analytics = useAnalytics();
  const { requestedTeammates, onSearch, searchResults } = props;
  const { missions, auth } = useStores();
  const [loading, setLoading] = useLoadingState();
  const [cardsView, setCardsView] = useState<'default' | 'search'>('default');
  const [applicationProfiles, setApplicationProfiles] = useState<{
    [uid: string]: Profile;
  }>({});
  const [displayedPreviewId, setDisplayedPreviewId] = useState<UserId>();
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const roles = missions.currentMission?.data.roles || [];
  const notAppliedMissionRoles =
    roles?.filter((role) => role.rid !== props.currentRole?.rid) ?? [];

  const displayedCards = useMemo(() => {
    return cardsView === 'search' ? searchResults : props.cards;
  }, [cardsView, props.cards, searchResults]);

  const displayedProfile = useMemo(() => {
    return displayedPreviewId
      ? applicationProfiles[displayedPreviewId]
      : undefined;
  }, [applicationProfiles, displayedPreviewId]);

  useEffect(() => {
    if (!missions.currentMission || !displayedPreviewId) return;

    const user = displayedCards?.find(
      (card) => card.uid === displayedPreviewId,
    );
    if (!user) return;
    setLoading(
      missions.getApplicationProfile(user?.username).then((preview) => {
        setApplicationProfiles({
          ...applicationProfiles,
          ...{ [displayedPreviewId]: preview },
        });
      }),
      null,
    );
  }, [displayedPreviewId]);

  const displayedPreviewRequested = useMemo(() => {
    return !!requestedTeammates.find(
      (request) => request.userId === displayedPreviewId,
    );
  }, [displayedPreviewId, requestedTeammates]);

  const nextCard = useMemo(() => {
    if (!displayedCards) return undefined;
    const currentIndex = displayedCards.findIndex(
      (card) => card.uid === displayedPreviewId,
    );
    return displayedCards[currentIndex + 1];
  }, [displayedPreviewId, displayedCards]);

  const prevCard = useMemo(() => {
    if (!displayedCards) return undefined;
    const currentIndex = displayedCards.findIndex(
      (card) => card.uid === displayedPreviewId,
    );
    return displayedCards[currentIndex - 1];
  }, [displayedPreviewId, displayedCards]);

  const onCardClick = (uid: UserId, source: 'cards' | 'modal' = 'cards') => {
    setDisplayedPreviewId(uid);
    setPreviewModalOpen(true);
    const user = displayedCards?.find((card) => card.uid === uid);

    if (!user || !missions.currentMission || !props.currentRole) return;

    const viewName = cardsView === 'search' ? 'search' : 'recommendation';
    if (source === 'modal') {
      analytics.trackTeammateRecommendationsModalNavigationClick(
        user,
        missions.currentMission.data,
        props.currentRole,
        viewName,
        ProfileVersion.New,
      );
    } else {
      analytics.trackTeammateRecommendationsTeammateCardClick(
        user,
        missions.currentMission.data,
        props.currentRole,
        viewName,
        ProfileVersion.New,
      );
    }
  };

  const handleRequest = (
    uid: UserId,
    requested: boolean,
    placement: 'card' | 'modal',
  ) => {
    const recommendation = displayedCards?.find((card) => card.uid === uid);

    recommendation &&
      props.onRequest(
        recommendation,
        requested,
        cardsView === 'search' ? 'search' : 'recommendation',
        placement,
      );

    recommendation &&
      missions.currentMission &&
      props.currentRole &&
      analytics.trackTeammateRecommendationsRequestedTeammateClick(
        recommendation,
        missions.currentMission?.data,
        props.currentRole,
        cardsView === 'search' ? 'search' : 'recommendation',
        placement,
        ProfileVersion.New,
      );
  };

  return (
    <div className={styles.container}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <div style={{ width: '100%' }}>
          <SectionHeading isFirst>
            Choose who you'd like to team up with on this mission
          </SectionHeading>
          <p>
            Let's make a team! Take a look at the A·Teamers below and choose
            teammates you'd like to request working with on this mission.
          </p>
          <div className={styles.searchWrapper}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <span style={{ marginRight: 12, padding: '8px 0' }}>
                Open Roles:
              </span>
              <TagList>
                {/* Shows the roles excluding the one you are applying for */}
                {notAppliedMissionRoles.map((role) => (
                  <Tag
                    key={role?.rid}
                    style={{ color: TextColors.regularLight }}
                  >
                    {role?.category?.title}
                  </Tag>
                ))}
              </TagList>
            </div>
            {auth.fullAccess && (
              <Search
                onSubmit={(query) => {
                  if (query === '') {
                    setCardsView('default');
                  } else {
                    setCardsView('search');
                    onSearch(query);
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>

      <ApplicationModal
        profile={displayedProfile}
        open={previewModalOpen}
        onClose={() => {
          setPreviewModalOpen(false);
          setDisplayedPreviewId(undefined);
        }}
        prevCard={prevCard}
        nextCard={nextCard}
        requested={displayedPreviewRequested}
        onRequest={handleRequest}
        onNavigationClick={(uid) => onCardClick(uid)}
      />

      <div className={styles.cardsContainer}>
        {displayedCards &&
          (cardsView === 'search' && displayedCards.length === 0 ? (
            <NoResults className={styles.noResults} />
          ) : (
            displayedCards.map((card) => {
              return (
                <TeammateCard
                  key={card.uid}
                  card={card}
                  onClick={onCardClick}
                  onRequest={handleRequest}
                  requested={
                    !!requestedTeammates.find(
                      (request) => request.userId === card.uid,
                    )
                  }
                />
              );
            })
          ))}
      </div>
      <LoadingIndicator loading={loading} />
    </div>
  );
};

export default observer(TeammateRecommendations);
