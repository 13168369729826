import React, { ReactElement } from 'react';
import { SidebarSection } from '@src/views/Profile/Sidebar/SidebarSection';
import ExperienceMember from '@src/components/ExperienceMember';
import { BasicUserObject } from '@a_team/models/dist/UserObject';

interface Props {
  user: BasicUserObject;
  clickable?: boolean;
}

export const InvitedBy = (props: Props): ReactElement => {
  const { user, clickable } = props;
  return (
    <SidebarSection title={'Invited By'} withAddIcon={false}>
      <div style={{ marginTop: 12 }}>
        <ExperienceMember user={user} clickable={clickable} />
      </div>
    </SidebarSection>
  );
};
