import React, { ReactElement } from 'react';
import ExpertiseModal from '@src/components/Modal/ExpertiseModal';
import Profile from '@src/stores/Profile/Profile';
import { Expertise } from '@src/stores/Profile/models';
import { observer } from 'mobx-react';
import { ExpertiseLegend } from '@src/components/Modal/ExpertiseModal/ExpertiseLegend';
import { useStores } from '@src/stores';

interface Props {
  onClose: (isOpen?: boolean) => void;
  open: boolean;
  skills?: Expertise[];
  profile: Profile;
  saveRightAway?: boolean;
}

const SkillsModal = (props: Props): ReactElement => {
  const { profile, open, onClose, skills } = props;
  const { auth } = useStores();

  const onSearch = (query: string): Promise<Expertise[]> => {
    return profile?.querySkills(query, false);
  };

  const handleSubmit = (updatedItems: Expertise[]) => {
    const filteredSkills = profile.allSkills.filter(
      (skill) => !updatedItems.find((item) => item.id === skill.id),
    );
    profile?.updateSkills(
      skills && skills.length
        ? [...filteredSkills, ...updatedItems]
        : updatedItems,
    );

    if (props.saveRightAway && auth.withSaveSkillsRightAwayOnApplication) {
      profile?.saveSkills();
    }

    onClose();
  };

  return (
    <ExpertiseModal
      closeMenuOnSelect={false}
      blockSkillsWhenNotRated
      title={skills ? 'Add skills' : 'Add new skills'}
      buttonText={'Add skills'}
      placeholder={'Type to search...'}
      onSubmit={handleSubmit}
      hideSearch={!!skills}
      selectedItems={skills || profile?.allSkills}
      open={open}
      onSearch={onSearch}
      onClose={onClose}
      description={<ExpertiseLegend type={'Skills'} />}
      featuredLimit={profile?.MAX_FEATURED_SKILLS}
      defaultFeatured
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    />
  );
};

export default observer(SkillsModal);
