import { EmailFromTargeterSendDtoTypeEnum } from '@a_team/user-notification-service-js-sdk';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import React, { useEffect, useState } from 'react';
import { EmailFormDataUser } from './EmailForm';
import { MissionAdminObject } from '@a_team/models/dist/MissionObject';
import { isEmpty, truncate, uniq } from 'lodash';
import { createUseStyles } from 'react-jss';
import { Colors, Spacing } from '@ateams/components';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { AudienceData } from './AudienceData';
const useStyles = createUseStyles({
  roleSettingsContainer: {
    marginTop: '1rem',
    textAlign: 'left',
    backgroundColor: Colors.backgroundLight,
    padding: `${Spacing.small}px ${Spacing.medium}px`,
  },
});

const DEFAULT_HOURS_BETWEEN_EMAILS = 8;
const DEFAULT_MAX_NUMBER_OF_BUILDERS_PER_ROLE = 8;

export const CreateCampaignModal = ({
  mission,
  audiences,
  confirmCreateCampaignModalOpen,
  setConfirmCreateCampaignModalOpen,
  onConfirmCreateCampaign,
  createCampaignLoading,
  selectedMissionHasNoOwner,
  emailFormTeamArchitectUser,
  emailFormSignatureUser,
  emailFormEmailType,
  currentUserEmail,
}: {
  mission?: MissionAdminObject;
  audiences: AudienceData[];
  confirmCreateCampaignModalOpen: boolean;
  setConfirmCreateCampaignModalOpen: (open: boolean) => void;
  onConfirmCreateCampaign: (
    timeBetweenEmails: number,
    maxNumberOfBuildersPerRole: Record<string, number>,
  ) => void;
  createCampaignLoading: boolean;
  selectedMissionHasNoOwner: boolean;
  emailFormTeamArchitectUser?: EmailFormDataUser;
  emailFormSignatureUser?: EmailFormDataUser;
  emailFormEmailType?: EmailFromTargeterSendDtoTypeEnum;
  currentUserEmail?: string;
}) => {
  const styles = useStyles();
  const uniqueRoleIdsInUse = uniq(
    audiences.map((audience) => audience.roleId),
  ).filter((roleId): roleId is string => !!roleId);
  const [timeBetweenEmails, setTimeBetweenEmails] = useState<number | ''>(
    DEFAULT_HOURS_BETWEEN_EMAILS,
  );
  const [maxNumberOfBuildersPerRole, setMaxNumberOfBuildersPerRole] = useState<
    Record<string, number | ''>
  >({});

  useEffect(() => {
    if (uniqueRoleIdsInUse.length && isEmpty(maxNumberOfBuildersPerRole)) {
      setMaxNumberOfBuildersPerRole(
        uniqueRoleIdsInUse.reduce((acc, roleId) => {
          acc[roleId] = DEFAULT_MAX_NUMBER_OF_BUILDERS_PER_ROLE;
          return acc;
        }, {} as Record<string, number>),
      );
    }
  }, [uniqueRoleIdsInUse]);

  const isFormValid =
    timeBetweenEmails &&
    Object.values(maxNumberOfBuildersPerRole).every(
      (value) => !!value && value > 0 && !isNaN(value),
    );

  const onClickOnCreateCampaign = () => {
    if (!isFormValid) {
      return;
    }
    onConfirmCreateCampaign(
      timeBetweenEmails,
      maxNumberOfBuildersPerRole as Record<string, number>,
    );
  };

  return (
    <ConfirmModal
      width={600}
      cancelDisabled={createCampaignLoading === true}
      actionLoading={createCampaignLoading === true}
      actionDisabled={createCampaignLoading === true || !isFormValid}
      open={confirmCreateCampaignModalOpen}
      onClose={() => setConfirmCreateCampaignModalOpen(false)}
      onConfirm={onClickOnCreateCampaign}
      title={`Before you start your campaign`}
      description={
        <>
          <p>This will create a campaign with the selected audiences.</p>
          {selectedMissionHasNoOwner && emailFormTeamArchitectUser?.id && (
            <p>
              This will assign{' '}
              <strong>
                {emailFormTeamArchitectUser?.fullName ||
                  emailFormTeamArchitectUser?.email}
              </strong>{' '}
              as owner for this mission.
            </p>
          )}
          <div className={styles.roleSettingsContainer}>
            <OutlinedInput
              type="number"
              label="Time between emails"
              onChange={(e) =>
                setTimeBetweenEmails(
                  e.target.value ? Number(e.target.value) : '',
                )
              }
              value={timeBetweenEmails}
              endAdornment="hours"
            />
            {uniqueRoleIdsInUse.map((roleId) => {
              const roleData = mission?.roles.find(
                (role) => role.rid === roleId,
              );
              return (
                <div key={roleId}>
                  <strong>{roleData?.category.title}</strong>
                  <p>{truncate(roleData?.headline || '', { length: 140 })}</p>
                  <OutlinedInput
                    type="number"
                    label="Max. number of builders matching acceptance criteria"
                    onChange={(e) =>
                      setMaxNumberOfBuildersPerRole((prev) => ({
                        ...prev,
                        [roleId]: e.target.value ? Number(e.target.value) : '',
                      }))
                    }
                    value={maxNumberOfBuildersPerRole[roleId]}
                  />
                </div>
              );
            })}
          </div>
          {emailFormSignatureUser &&
            currentUserEmail &&
            (emailFormEmailType ===
              EmailFromTargeterSendDtoTypeEnum.RichReachout ||
              emailFormEmailType ===
                EmailFromTargeterSendDtoTypeEnum.RichReachoutV2) &&
            emailFormSignatureUser.email !== currentUserEmail && (
              <p>
                <strong>Warning:</strong> You are creating a campaign with
                signature{' '}
                <strong>
                  {emailFormSignatureUser?.fullName ||
                    emailFormSignatureUser?.email}
                </strong>
                .
              </p>
            )}
        </>
      }
      actionLabel="Create campaign"
    ></ConfirmModal>
  );
};
