import type { SurveyQuestionProps } from './SurveyQuestionProps';
import type { SurveyQuestionResponse } from './SurveyQuestionResponse';
import { SurveyQuestionResponseValidator } from './SurveyQuestionResponseValidator';
import { SurveyQuestionType } from './SurveyQuestionType';
import { validateRatingWithFreeFormCommentSurveyQuestionResponse } from './types';
import { validateCommentSurveyQuestionResponse } from './types/CommentSurveyQuestion';
import { validateListSurveyQuestionResponse } from './types/ListSurveyQuestion';
import { validatePersonRatingWithFreeFormCommentSurveyQuestionResponse } from './types/PersonRatingWithFreeFormCommentSurveyQuestion';
import { validateYesOrNoSurveyQuestionResponse } from './types/YesOrNoSurveyQuestion';
import { validateRecommendedRolesSurveyQuestionResponse } from './types/RecommendedRolesSurveyQuestion';

const validators: {
  [T in SurveyQuestionType]: SurveyQuestionResponseValidator<T>;
} = {
  [SurveyQuestionType.RatingWithFreeFormComment]:
    validateRatingWithFreeFormCommentSurveyQuestionResponse,
  [SurveyQuestionType.PersonRatingWithFreeFormComment]:
    validatePersonRatingWithFreeFormCommentSurveyQuestionResponse,
  [SurveyQuestionType.RecommendedRoles]:
    validateRecommendedRolesSurveyQuestionResponse,
  [SurveyQuestionType.Comment]: validateCommentSurveyQuestionResponse,
  [SurveyQuestionType.YesOrNo]: validateYesOrNoSurveyQuestionResponse,
  [SurveyQuestionType.List]: validateListSurveyQuestionResponse,
};

export const validateQuestionResponse = <T extends SurveyQuestionType>(
  type: T,
  question: SurveyQuestionProps[T],
  response: SurveyQuestionResponse[T],
): void => {
  if (response === undefined && !question.required) {
    return;
  }

  if (!(type in validators)) {
    throw new Error(`unknown question type ${type}`);
  }
  const validator = validators[type] as SurveyQuestionResponseValidator<T>;
  return validator(question, response);
};
