import { Breakpoints } from '@ateams/components';
import { createUseStyles } from 'react-jss';

const useCommonStyles = createUseStyles({
  container: {
    background: '#FCFAFF',
    padding: 16,
    color: '#6D00D7',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    marginTop: -4,
  },
  desc: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: 8,
    fontSize: 12,
  },
  suggestedDescription: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    fontSize: 12,
    gap: 8,
    '& i': {
      flexShrink: 0,
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    desc: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    suggestedDescription: {
      alignItems: 'flex-start',
      flexDirection: 'row',
    },
  },
});

export default useCommonStyles;
