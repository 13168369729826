import { pick } from 'lodash';
import {
  TargeterSuggestedTeamsSidebarAudience,
  TargeterSuggestedTeamsSidebarParameters,
} from '@a_team/models/dist/TargeterSuggestedTeams';
import { TeamTargeterCriteriaParametersV2 } from '@a_team/data-science-api-client';

export const initialSidebarParameters = (
  suggestedTeamsCriteria: TeamTargeterCriteriaParametersV2['teamCriteria'],
  activeRoleTabNames?: string[],
  initialSuggestedTeamsParameters?: TargeterSuggestedTeamsSidebarParameters,
): TargeterSuggestedTeamsSidebarParameters => {
  const audiences = Object.keys(suggestedTeamsCriteria).reduce(
    (
      aud: Record<string, TargeterSuggestedTeamsSidebarAudience>,
      key: string,
    ) => {
      const criteria = suggestedTeamsCriteria[key];
      const audience = initialSuggestedTeamsParameters?.audiences[key] ?? {
        makeCore: activeRoleTabNames?.find((ar) => ar === key) !== undefined,
        buildersPerTab: criteria.requiredCount + criteria.optionalCount,
        limit: criteria.limit,
      };

      return { ...aud, [key]: audience };
    },
    {} as Record<string, TargeterSuggestedTeamsSidebarAudience>,
  );

  return {
    allowMultipleBuildersPerAudience: true,
    showTheAudienceLimit: true,
    showPartialTeams: (activeRoleTabNames?.length || 0) > 0,
    requiredTimeOverlapOnExperiences: false,
    invitedByOrInvitations: true,
    missions: true,
    projects: true,
    connections: true,
    teams: true,
    companies: true,
    ...initialSuggestedTeamsParameters,
    audiences,
  };
};

export const updateSuggestedTeamsCriteria = (
  newParameters: TargeterSuggestedTeamsSidebarParameters,
  suggestedTeamsCriteria: TeamTargeterCriteriaParametersV2['teamCriteria'],
  setSuggestedTeamsCriteria: (
    criteria: TeamTargeterCriteriaParametersV2,
  ) => void,
) => {
  const allCores = !(newParameters?.showPartialTeams || false);
  const teamCriteria = { ...suggestedTeamsCriteria };

  Object.keys(newParameters.audiences).forEach((key) => {
    const audience = newParameters.audiences[key];
    const roleRequired = allCores || audience.makeCore;
    const count = newParameters.allowMultipleBuildersPerAudience
      ? Math.max(audience.buildersPerTab, 1) || 1
      : 1;
    const limit = !newParameters.showTheAudienceLimit
      ? 500
      : audience.limit && audience.limit > 0
      ? audience.limit || 500
      : 500;

    const requiredConnections = [
      ...Object.keys(newParameters.audiences),
    ].filter((k) => {
      if (k === key) return false;
      if (allCores) return true;
      if (!newParameters.audiences[key].makeCore) return false;
      return newParameters.audiences[k].makeCore;
    });

    teamCriteria[key] = {
      ...teamCriteria[key],
      requiredCount: roleRequired ? count : 0,
      optionalCount: roleRequired ? 0 : count,
      limit,
      requiredConnections,
    };
  });

  const teamOptions = {
    overlap: newParameters.requiredTimeOverlapOnExperiences,
    narratives: {
      ...pick(newParameters, [
        'missions',
        'projects',
        'companies',
        'teams',
        'connections',
      ]),
      invitedBy: newParameters.invitedByOrInvitations,
      invitations: newParameters.invitedByOrInvitations,
    },
  };

  setSuggestedTeamsCriteria({ teamCriteria, teamOptions });
};

export const getCombinations = (
  arr: UserData[],
  k: number,
  start = 0,
  combination: UserData[] = [],
  combinations: UserData[][] = [],
) => {
  if (combination.length === k) {
    combinations.push([...combination]);
  } else {
    for (let i = start; i < arr.length; i++) {
      combination.push(arr[i]);
      getCombinations(arr, k, i + 1, combination, combinations);
      combination.pop();
    }
  }
  return combinations;
};

export interface UserData {
  id: string;
  fullName: string;
}

const YEAR_DAYS = 365;
const MONTH_DAYS = 30;

export const getFormattedPeriod = (days: number): string => {
  let result = '';

  let remainingDays = days;

  if (remainingDays >= YEAR_DAYS) {
    const years = Math.floor(days / YEAR_DAYS);
    result += `${years}yr`;
    remainingDays = remainingDays % YEAR_DAYS;
  }

  if (remainingDays >= MONTH_DAYS) {
    if (result.length > 0) {
      result += ' ';
    }
    const months = Math.floor(remainingDays / MONTH_DAYS);
    result += `${months}mo`;
    remainingDays = remainingDays % MONTH_DAYS;
  }

  if (result.length === 0 && remainingDays > 0) {
    const days = Math.floor(remainingDays);
    result += `${days}d`;
  }

  return result;
};
