import React, { ReactElement, useEffect, useState } from 'react';
import { LocalTeamMember } from '@src/stores/Registration/models';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import {
  AsyncSelect,
  Breakpoints,
  Colors,
  SelectOption,
} from '@ateams/components';
import { Icon, IconType } from '@ateams/components';
import { getLinkedinUsernameFromURL, isLinkedInUrl } from '@src/helpers/urls';
import { createUseStyles } from 'react-jss';

interface Props {
  member: LocalTeamMember;
  onChange: (member: LocalTeamMember) => void;
  onRemove?: (member: LocalTeamMember) => void;
  onRoleSearch: (val: string) => Promise<SelectOption[]>;
  isEmailValid?: boolean;
}

const useStyles = createUseStyles({
  card: {
    background: Colors.backgroundMedium,
    padding: '1px 15px',
    borderRadius: 12,
    position: 'relative',
    marginTop: 20,
  },
  input: {},
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    card: {
      padding: '1px 20px',
    },
  },
  [`@media (min-width: ${Breakpoints.xl}px)`]: {
    input: {
      marginBottom: '2em',
      '& .input-label': {
        fontSize: '0.9em',
        marginBottom: '0.8em',
      },
    },
  },
});

export const MemberForm = (props: Props): ReactElement => {
  const styles = useStyles();
  const { member, onChange, onRemove, onRoleSearch, isEmailValid } = props;
  const [linkedinUrl, setLinkedinUrl] = useState(
    member.linkedInUsername
      ? `https://www.linkedin.com/in/${member.linkedInUsername}/`
      : '',
  );

  useEffect(() => {
    onChange({
      ...member,
      linkedInUsername: getLinkedinUsernameFromURL(linkedinUrl),
    });
  }, [linkedinUrl]);

  const onSelect = (item: SelectOption | null) => {
    if (!item) return;
    onChange({
      ...member,
      role: {
        id: item.id,
        name: item.name,
        talentCategoryIds: item.talentCategoryIds || [],
      },
    });
  };

  return (
    <div className={styles.card}>
      {onRemove && !member.existingMember && (
        <Icon
          type={IconType.Close}
          size={'xsmall'}
          onClick={() => onRemove(member)}
          style={{ position: 'absolute', right: 14, top: 14 }}
          title={'Remove'}
        />
      )}
      <OutlinedInput
        label={'Full Name'}
        placeholder={'Jack Jackson...'}
        value={`${member?.fullName}`}
        required
        onChange={(e) => onChange({ ...member, fullName: e.target.value })}
        className={styles.input}
        disabled={member.existingMember}
      />
      <OutlinedInput
        label={'Email Address'}
        placeholder={'name@company.com...'}
        value={member.email}
        required
        valid={isEmailValid}
        error={!!member.email && isEmailValid === false}
        onChange={(e) => {
          onChange({ ...member, email: e.target.value });
        }}
        className={styles.input}
        errorTooltip={'Oops, the email you entered in invalid.'}
        disabled={member.existingMember}
      />
      <AsyncSelect
        required
        label={'Role'}
        placeholder={"Select this member's role..."}
        defaultInputValue={member.role?.name}
        loadOptions={onRoleSearch}
        onChange={(item: SelectOption | SelectOption[] | null) =>
          onSelect(item as SelectOption)
        }
      />
      <OutlinedInput
        label={'LinkedIn'}
        placeholder={'https://linkedin.com/in/...'}
        valid={linkedinUrl ? isLinkedInUrl(linkedinUrl) : undefined}
        error={!!linkedinUrl && !isLinkedInUrl(linkedinUrl)}
        value={linkedinUrl}
        onChange={(e) => setLinkedinUrl(e.target.value)}
        className={styles.input}
        errorTooltip={'Oops, the link you entered in invalid.'}
        disabled={member.existingMember}
      />
    </div>
  );
};
