import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import {
  VettingType,
  vettingTypeToStatusMap,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { useStores } from '@src/stores';
import { AdminVettingProcessColumnProps } from '..';
import useLoadingState from '@src/hooks/useLoadingState';
import { Select, SelectProps } from '@ateams/components';
import LoadingIndicator from '@src/components/LoadingIndicator';

const options = Object.entries(vettingTypeToStatusMap).map(([key, value]) => ({
  value: key,
  label: value,
}));

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const InterviewTypeField: React.FC<AdminVettingProcessColumnProps> = ({
  vettingProcess,
}) => {
  const styles = useStyles();
  const { vettingProcesses } = useStores();
  const [loading, setLoading] = useLoadingState();

  const value = useMemo(() => {
    return {
      value: vettingProcess.vettingType,
      label: vettingTypeToStatusMap[vettingProcess.vettingType],
    };
  }, [vettingProcess.vettingType]);

  const onSelect: SelectProps['onChange'] = (option) => {
    if (option) {
      setLoading(
        (async () => {
          await vettingProcesses.patchVettingProcess({
            id: vettingProcess.id,
            vettingType: option.value as VettingType,
          });
        })(),
        'Interview type changed successfully',
      );
    }
  };

  return (
    <div className={styles.container}>
      <Select
        menuPortalTarget={document.body}
        value={value}
        onChange={onSelect}
        hideSelectedOptions={false}
        placeholder={'Select status'}
        options={options}
        width="fixed"
        isDisabled={loading === true}
        useRegularSelectInput
      />
      <LoadingIndicator loading={loading} />
    </div>
  );
};
