import React, { ReactElement, useMemo } from 'react';
import { ProfileTooltip } from '@src/views/Profile/ProfileTooltip';
import TextInput from '@src/components/Inputs/TextInput/TextInput';
import { createUseStyles } from 'react-jss';
import { Colors } from '@ateams/components';

interface Props {
  years?: number;
  readonly?: boolean;
  onChange?(years?: number): void;
  error?: boolean;
  disabled?: boolean;
}

const useStyles = createUseStyles({
  input: {
    display: 'inline-block',
    width: '25px',
    marginRight: '8px',
    fontSize: '17px',
    fontWeight: '400',
    color: Colors.regular,
    '&::placeholder': {
      color: Colors.regular,
    },
  },
});

const MAX_YEARS_ALLOWED = 50;

export const YearsOfExperience = (props: Props): ReactElement => {
  const { readonly, years, onChange, error } = props;
  const styles = useStyles();

  const tooltipText = useMemo(() => {
    let text;
    !years && (text = 'How long have you been doing this work?');
    error && (text = 'Please enter your years of experience');
    return text;
  }, [error, years]);

  const validate = (text: string) => {
    if (!onChange) {
      return;
    }
    if (text === '') {
      return onChange();
    }
    const num = Number(text);
    if (isNaN(num) || num > MAX_YEARS_ALLOWED || num < 1) {
      return;
    }
    onChange(Number(text));
  };

  return (
    <div style={{ color: '#BDBDBD', fontSize: 18, marginTop: 8 }}>
      {readonly || !onChange ? (
        <span
          style={{ marginRight: 6, marginTop: 8, display: 'inline-block' }}
          data-testing-id={'profile-years-experience-text'}
        >
          {Number(years) > MAX_YEARS_ALLOWED ? '20+' : years}
        </span>
      ) : (
        <ProfileTooltip
          title={tooltipText}
          disabled={!tooltipText}
          arrow
          position={'top'}
        >
          <TextInput
            variant="dashed"
            placeholder="XX"
            value={years !== undefined ? years : ''}
            onChange={(e) => validate(e.target.value)}
            data-testing-id={'profile-years-experience-text-input'}
            className={styles.input}
            error={error}
          />
        </ProfileTooltip>
      )}
      <span>{`Year${!years || years > 1 ? 's' : ''} Experience`}</span>
    </div>
  );
};
