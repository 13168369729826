import NoResults from '@src/components/NoResults';
import { useGetCompletedMissionsQueryV2 } from '@src/rq/missions';
import { observer } from 'mobx-react';
import React from 'react';
import CardList from '../CardList';
import CompletedMissionCardV2 from './CompletedMissionCardV2';

const CompletedMissionsV2 = () => {
  const { data, isLoading } = useGetCompletedMissionsQueryV2();
  const completedMissions = data?.data ?? [];

  return (
    <div>
      {completedMissions.length === 0 || isLoading ? (
        <NoResults
          imageType="noSearchResults"
          title="No completed missions found!"
          text={<p>Looks like you haven't completed any mission yet.</p>}
        />
      ) : (
        completedMissions.length > 0 && (
          <CardList>
            {completedMissions?.map((mission) => (
              <CompletedMissionCardV2
                key={`completed-mission-${mission.mid}`}
                mission={mission}
                isLoading={isLoading}
              />
            ))}
          </CardList>
        )
      )}
    </div>
  );
};

export default observer(CompletedMissionsV2);
