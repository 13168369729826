import React from 'react';
import { Card } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import tokenIcon from './tokenIcon.svg';
import upArrow from './upArrow.svg';
import { Breakpoints } from '@ateams/components';
import Breakdown from './Breakdown';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';

interface Props {
  className?: string;
}

const useStyles = createUseStyles({
  pointsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px',
    '& > div:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  horizontalSeries: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: '8px',
    },
  },
  mainPoints: {
    fontSize: '38px',
    fontWeight: 500,
  },
  tokens: {
    color: '#62646A',
    fontSize: '24px',
    fontWeight: 500,
  },
  recentPoints: {
    fontSize: '16px',
    fontWeight: 500,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    pointsContainer: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    horizontalSeries: {
      marginBottom: 0,
    },
  },
});

const Summary: React.FC<Props> = ({ className }) => {
  const styles = useStyles();
  const { rewards } = useStores();

  return (
    <Card className={className}>
      <div className={styles.pointsContainer}>
        <div className={styles.horizontalSeries}>
          {rewards?.totalScore !== undefined && (
            <>
              <img src={tokenIcon} alt="Token icon" />
              <span className={styles.mainPoints}>
                {Math.floor(rewards?.totalScore ?? 0)}
              </span>
              <span className={styles.tokens}>
                {rewards?.totalScore !== 1 ? 'tokens' : 'token'}
              </span>
            </>
          )}
        </div>
        <div className={styles.horizontalSeries}>
          {!!rewards?.lastMonthDifference && (
            <>
              <img src={upArrow} alt="Token icon" />
              <span className={styles.recentPoints}>
                {rewards.lastMonthDifference > 0 ? '+' : ''}{' '}
                {rewards.lastMonthDifference} last month
              </span>
            </>
          )}
        </div>
      </div>
      <Breakdown
        builderTotal={rewards?.builderTotal}
        builderReferralTotal={rewards?.builderReferralTotal}
        companyReferralTotal={rewards?.companyReferralTotal}
      />
    </Card>
  );
};

export default observer(Summary);
