const CDN_AVATARS_PREFIX = 'https://cdn.a.team/avatars/defaults';

export const profilePicturesByCategory = {
  design: `${CDN_AVATARS_PREFIX}/avatar_design.jpg`,
  development: `${CDN_AVATARS_PREFIX}/avatar_development.jpg`,
  management: `${CDN_AVATARS_PREFIX}/avatar_management.jpg`,
  marketing: `${CDN_AVATARS_PREFIX}/avatar_marketing.jpg`,
  dataScience: `${CDN_AVATARS_PREFIX}/avatar_data_science.jpg`,
  general: `${CDN_AVATARS_PREFIX}/avatar_general.jpg`,
};

// These match the relevant RoleCategories textIds in the DB
export type ProfileImageCategoryTextIds =
  | 'design'
  | 'software-engineering'
  | 'product-management'
  | 'project-management'
  | 'marketing'
  | 'data-science'
  | 'general';

export const DEFAULT_PROFILE_IMAGES: Record<
  ProfileImageCategoryTextIds,
  string
> = {
  design: profilePicturesByCategory.design,
  'software-engineering': profilePicturesByCategory.development,
  'product-management': profilePicturesByCategory.management,
  'project-management': profilePicturesByCategory.management,
  marketing: profilePicturesByCategory.marketing,
  'data-science': profilePicturesByCategory.dataScience,
  general: profilePicturesByCategory.general,
};
