import CarouselControl from '@src/components/ItemsCarousel/CarouselControl';
import React from 'react';

interface MultipleImagesArrowsProps {
  next: () => void;
  prev: () => void;
}

const MultipleImagesArrows = ({
  next,
  prev,
}: MultipleImagesArrowsProps): JSX.Element => {
  return (
    <div>
      <CarouselControl show type="left" onClick={next} offset={10} />
      <CarouselControl show type="right" onClick={prev} offset={10} />
    </div>
  );
};

export default MultipleImagesArrows;
