import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import useToggle from '@src/hooks/useToggle';
import { IconType, Tag, TagList, TextColors } from '@ateams/components';
import Dropdown, { DropdownItem } from '@src/components/Dropdown';
import LabeledCheckboxInput from '@src/components/Inputs/LabeledCheckboxInput';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import FilterToggle from '@src/components/Filters/FilterToggle';

interface TagItem {
  id: string;
  label: string;
}

interface Props {
  inline?: boolean;
  label: string;
  items: TagItem[];
  selected?: string[];
  searchable?: boolean;
  searchPlaceholder?: string;
  onChange(selected: string[]): void;
  className?: string;
  iconType?: IconType;
  hideTags?: boolean;
}

const useStyles = createUseStyles({
  checkbox: {
    margin: '-1em 0',
    padding: '1em 0',
  },
  tags: (props: Props) => ({
    marginTop: props.inline ? 0 : '32px',
  }),
  itemsFilter: {
    margin: 10,
    width: 'auto',
    minWidth: 254,
  },
  noResults: {
    padding: 10,
  },
  textContainer: {
    margin: '0',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  numberBadge: {
    flex: '0 0 min-content',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    padding: '2px 11px',
    fontSize: '80%',
    color: TextColors.primary,
    background: '#79CE00',
    borderRadius: '50px',
    margin: '0px 4px',
  },
});

export default function TagsFilter(props: Props): ReactElement {
  const {
    selected,
    onChange,
    label,
    items,
    searchable,
    searchPlaceholder = '',
    className = '',
    iconType,
    hideTags = false,
    ...rest
  } = props;

  const styles = useStyles(props);
  const [open, toggleOpen] = useToggle();
  const [search, setSearch] = useState('');
  const itemsMap = useMemo(
    () => new Map(items.map((item) => [item.id, item])),
    [items],
  );
  const filteredItems = useMemo(() => {
    return items.filter(
      ({ label }) =>
        !search ||
        label.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
    );
  }, [search, items]);
  const selectedSet = useMemo(() => new Set(selected), [selected]);

  useEffect(() => {
    !open && setSearch('');
  }, [open, setSearch]);

  const handleAdd = (id: string): void => {
    onChange([...(selected || []), id]);
  };

  const handleRemove = (id: string): void => {
    onChange(selected?.filter((x) => x !== id) || []);
  };

  return (
    <>
      <FilterToggle
        open={open}
        className={className}
        iconType={iconType}
        iconSize="0.8em"
        onToggle={toggleOpen}
        width="fixed"
        dropdown={
          <Dropdown open={open}>
            {searchable && (
              <OutlinedInput
                placeholder={searchPlaceholder}
                onChange={(e) => setSearch(e.target.value)}
                className={styles.itemsFilter}
              />
            )}
            {filteredItems.length > 0 ? (
              filteredItems.map((item) => (
                <DropdownItem key={item.id}>
                  <LabeledCheckboxInput
                    className={styles.checkbox}
                    label={item.label}
                    margin="none"
                    checked={selectedSet.has(item.id)}
                    onChange={(e): void =>
                      e.target.checked
                        ? handleAdd(item.id)
                        : handleRemove(item.id)
                    }
                  />
                </DropdownItem>
              ))
            ) : (
              <div className={styles.noResults}>No results</div>
            )}
          </Dropdown>
        }
        {...rest}
      >
        <p className={styles.textContainer}>
          {label}
          {selectedSet.size > 0 && (
            <span className={styles.numberBadge}>{selectedSet.size}</span>
          )}
        </p>
      </FilterToggle>

      {selectedSet.size > 0 && !hideTags && (
        <TagList className={styles.tags}>
          {selected?.map((id) => (
            <Tag key={id} onRemove={(): void => handleRemove(id)}>
              {itemsMap.get(id)?.label}
            </Tag>
          ))}
        </TagList>
      )}
    </>
  );
}
