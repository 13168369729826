import { VettingProcessEmailTemplates } from '@a_team/models/dist/vetting-processes/emails';
import { EmailTemplateGenerator } from '..';

export const createInternalNonTechnicalInitialEmailTemplate: EmailTemplateGenerator =
  () => {
    return {
      subject: '{{builderFirstName}}, schedule your A.Team evaluation call!',
      body: `<p>Hi {{builderFirstName}},</p>
<p>{{contactOwnerFirstName}} from A.Team here. I’m excited to invite you to an <a href="https://guide.a.team/getting-started/evaluation-call">evaluation call</a> — a mandatory next step before we can consider you for missions.</p>
<p>The <a href="https://guide.a.team/getting-started/evaluation-call">evaluation call</a> is your chance to tell us about all the great things we can use to pitch you for missions, and it's our opportunity to confirm that you're a good fit for our network and the top-tier companies we partner with.</p>
<p>Best to come prepared with:</p>
<ul>
<li>1-2 minute "elevator pitch" about your skills, experience, and achievements</li>
<li>2-3 project examples - focus on projects you had a significant contribution to and be ready to answer questions about them</li>
<li>Your portfolio and case studies (for designers) OR sample code (for developers), if provided</li>
<li>Be ready to walk the interviewer through your thought process and success metrics</li>
<li>A stable internet connection, as well as high-quality audio and video setup</li>
</ul>
<div>{{requiredCallParticipationNote}}</div>
<h3><b>Schedule your evaluation call <a href="{{preVettingFormUrl}}">here</a>!</b></h3>
<p>Looking forward to get to know you!</p>
<p>Best,<br>
{{contactOwnerFirstName}}</p>
<p>P.S. We’ll be recording this call for quality control and internal learning purposes.</p>
<p>P.P.S. Unless you encounter any issues or have questions, there’s no need to reply to this email. We’ve got you covered.</p>
`,
      templateType: VettingProcessEmailTemplates.InternalNonTechnicalInitial,
      sendInThread: false,
    };
  };

export const createInternalNonTechnicalFirstFollowupEmailTemplate: EmailTemplateGenerator =
  () => {
    return {
      subject: 'Reminder! Schedule your A.Team evaluation call',
      body: `<p>Hi {{builderFirstName}},</p>
<p>{{contactOwnerFirstName}} from A.Team again. I sent you an email about scheduling your <a href="{{preVettingFormUrl}}">evaluation call</a> to assess your skills and ensure they’re a fit for our open missions.</p>
<h3><span>If you’re still interested, </span><a href="{{preVettingFormUrl}}">schedule a call with us!</a></h3>
<div>{{requiredCallParticipationWarning}}</div>
<p>I’m looking forward to talking soon!<br>
{{contactOwnerFirstName}}</p>`,
      templateType: VettingProcessEmailTemplates.InternalNonTechnicalFollowUp,
      sendInThread: true,
    };
  };
