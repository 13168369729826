import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import SectionHeading from '@src/components/SectionHeading';

const useStyles = createUseStyles({
  root: {
    lineHeight: '24px',
  },
  bold: {
    fontWeight: 500,
  },
  light: {
    color: '#62646A',
  },
});

export default function TalentPolicy(): ReactElement {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.bold}>Last Updated: September 1st, 2020</div>
      <SectionHeading isFirst style={{ marginTop: '32px' }}>
        Introduction
      </SectionHeading>
      <div style={{ marginTop: '32px' }}>
        This Talent Privacy Statement (“Privacy Statement”) describes ATeams
        Inc.’s and its affiliates and subsidiaries’ (collectively, “ATeams”,
        “we” or “our”) personal information practices with respect to elite tech
        talent (“Talent”) in connecting Talent with our community of customers
        (including corporate and non-profit organizations) as they seek to
        augment their staffing capabilities. The Privacy Statement describes
        what personal information ATeam collects and how it collects, uses,
        discloses or otherwise processes information about Talent. This Privacy
        Statement also describes your ability to control certain uses of your
        personal information. Please read this Privacy Statement carefully
        before using the Talent portal (“Site”). By using the Site, you
        acknowledge and agree to the practices described in this Privacy
        Statement.
      </div>
      <div style={{ marginTop: '8px' }}>
        ATeam and its affiliates and subsidiaries operate globally. We provide
        additional notices to residents of the European Union, United Kingdom
        and Switzerland (collectively, “Europe” or “European”) below.
      </div>
      <div style={{ marginTop: '8px' }}>
        This Privacy Statement does not form part of any contract of employment.
      </div>
      <SectionHeading>Information Collection</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        <div className={styles.bold}>
          A. What information we collect about Talent.
        </div>{' '}
        Before, during and after your application to become part of the ATeam
        Talent community, including time spent on the Site, we may collect and
        process information about you. We may collect the following information
        about you:
      </div>
      <ul>
        <li>
          Personal details: Name, home contact details (email, phone numbers,
          physical address) languages(s) spoken, gender, date of birth, national
          identification number, Social Security number, driver’s license
          information, accommodation requests, emergency contact information and
          photograph;
        </li>
        <li>Account information: Username and password;</li>
        <li>Financial information: Bank account details;</li>
        <li>
          Documentation required under immigration laws: Citizenship and
          passport data, details of residency or work permit;
        </li>
        <li>
          Talent management information: Details contained in letters of
          application and resume/CV (previous employment background, education
          history, professional qualifications and memberships (such as
          licenses, and permits), language and other relevant skills,
          certification, certification expiration dates), writing samples,
          information necessary to complete a background check (including
          drug/alcohol) and information received during these checks,
          information relating to references such as referees’ names and contact
          details, details on performance management ratings, development
          programs planned and attended, e-learning programs, performance and
          development reviews, willingness to relocate, and information used to
          populate employee biographies;
        </li>
        <li>
          Any other information: Such as current salary, desired salary,
          employment preferences, references, whether you are subject to prior
          employer obligations, information from job application materials (for
          example, your cover letter, references, work history, education
          transcripts;
        </li>
        <li>
          Sensitive information: Such as information revealing racial or ethnic
          origin, political opinions, religious or philosophical beliefs, or
          trade union membership, and the processing of genetic data or
          biometric data for the purpose of uniquely identifying a natural
          person, data concerning health (including information needed to
          understand and assess accommodation requests regarding potential
          disabilities or other health conditions) or data concerning a person’s
          sex life or sexual orientation, and criminal conviction data.
        </li>
      </ul>
      <div style={{ marginTop: '8px' }}>
        We ask that you avoid submitting sensitive information, unless such
        information is legally required and/or ATeam specifically requests you
        to submit such information.
      </div>
      <div style={{ marginTop: '8px' }}>
        Any information you submit through the Site must be true, complete and
        not misleading. Submitting inaccurate, incomplete or misleading
        information may lead to a rejection of your application to join the
        ATeam Talent community or disciplinary action including immediate
        termination of your community participation. In addition, it is your
        responsibility to ensure that the information you submit does not
        violate any third party’s rights.
      </div>
      <div style={{ marginTop: '8px' }}>
        If you provide us with personal information of a referee or any other
        individual as part of your application to join the ATeam Talent
        community, it is your responsibility to obtain consent from that
        individual prior to providing the information to us.{' '}
      </div>
      <div style={{ marginTop: '8px' }}>
        In certain cases, we may ask you for additional information for purposes
        of complying with applicable laws.
      </div>
      <div style={{ marginTop: '32px' }}>
        <div className={styles.bold}>B. Sources of Information.</div> Where
        permitted by governing law, we may collect information from the
        following sources:
      </div>
      <ul>
        <li>
          Talent: In person, online, by telephone or in written correspondence
          and forms;
        </li>
        <li>
          Third-party and ATeam websites: Where you can apply to join the ATeam
          Talent community;
        </li>
        <li>
          Previous employers and other referees: In the form of employment
          references;
        </li>
        <li>
          Educational establishments: In the form of education references;
        </li>
        <li>
          Background and credit check vendors: As part of the recruitment
          process;
        </li>
        <li>Other persons acting on your behalf;</li>
        <li>
          Providers of sanctions and “politically exposed persons” screening
          lists;
        </li>
        <li>ATeam personnel;</li>
        <li>
          Publicly accessible sources: For example, tax codes from applicable
          tax authorities, through job search or career networking sites or
          social media profiles;
        </li>
        <li>
          Automatic data collection tools: For example, cookies and other
          similar technologies when you use the Site. To learn more about these
          cookies and other technologies, please visit our Cookie Policy.
        </li>
      </ul>
      <div style={{ marginTop: '8px' }}>
        If you provide us with details of referees, you confirm that your
        referees have consented to our collection, use and disclosure (including
        transfers to other countries as described in this Privacy Statement) of
        their personal information for the purposes of contacting them as
        referees, and being contacted by us in connection with your application.
      </div>
      <SectionHeading>Information Use and Disclosure</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        <div className={styles.bold}>A. Why we collect your information.</div>{' '}
        We may use your personal information, to manage and facilitate the
        recruitment process to join the ATeam Talent community or as otherwise
        described when you provide your information (or when information is
        provided on your behalf), to:
      </div>
      <ul>
        <li>
          Managing the community workforce: Managing recruitment and assessing
          your suitability, capabilities and qualifications to join the ATeam
          Talent community, processing your ATeam Talent community participation
          including by sharing information about roles about which you may be
          interested and sharing information with potential employers,
          processing your application and performing background checks if we
          offer you the ability to participate in the ATeam Talent community,
          such as credit checks, anti-fraud checks and checks to prevent fraud
          and money laundering, enabling us to conduct our business, managing IT
          systems and infrastructure, analysing and improving our application
          and recruitment processes, accommodating disabilities or health
          conditions, and providing you with customized content;
        </li>
        <li>
          Operating the Site: Operating the Site and any other site to which
          this Privacy Statement is posted;
        </li>
        <li>
          Communications: Facilitating communication with you regarding your
          application;
        </li>
        <li>
          Taking legal action: Pursuing legal rights and remedies, defending
          litigation and managing any internal complaints or claims, conducting
          investigations and complying with internal policies and procedures,
          complying with internal policies and procedures, protecting our, your
          or others’ rights, safety and property, investigating and deterring
          against fraudulent, harmful, unauthorized, unethical or illegal
          activity, and other business operations;
        </li>
        <li>
          Compliance: Complying with legal (including, in respect of sensitive
          information, obligations under employment law) and other requirements,
          such as record-keeping and reporting obligations, conducting audits,
          compliance with government inspections and other requests from
          government or other public authorities, responding to legal process
          such as subpoenas, sharing information with government authorities,
          law enforcement and private parties where we have a good-faith belief
          it is necessary;
        </li>
        <li>
          Analytics: Creating anonymous, aggregated or de-identified data that
          we use and share to analyse our application and recruitment
          activities, business and for other lawful business purposes.
        </li>
      </ul>
      <div style={{ marginTop: '8px' }}>
        We may use your information for other business operations. There may be
        more than one purpose that justifies our use of your information in any
        particular circumstances. We will only use your information for the
        purposes for which we collected it, unless we reasonably consider that
        we need to use it for another reason and that reason is compatible with
        the original purpose. If we need to use your information for an
        unrelated purpose, we will notify you and explain the legal basis which
        allows us to do so. If you fail to provide certain information when
        requested, we may not be able to review your application or we may be
        prevented from complying with our legal obligations.
      </div>
      <div style={{ marginTop: '8px' }}>
        If we think you are suitable to join the ATeam Talent community, we will
        use your information to assess your suitability for roles you may be
        interested in and to inform you about these vacancies or opportunities
        as well as to share your information with potential employers.{' '}
      </div>
      <div style={{ marginTop: '8px' }}>
        For certain positions, it will be necessary for us to verify the details
        you have supplied (for example, in relation to your academic credentials
        or your employment history) and/or carry out pre-employment checks in
        the event that we extend an offer to join the ATeam Talent community
        (for example, in relation to previous criminal convictions). If a
        position that is or may be of interest to you requires pre-employment
        vetting, we will tell you about this. Such vetting will not usually
        occur unless and until an offer has been made. We will carry out
        background checks only where permitted by law or, where applicable, with
        your consent.
      </div>
      <div style={{ marginTop: '8px' }}>
        Some information we collect is required in order for you to use the Site
        and/or apply to join the ATeam Talent community. We may also require
        personal information to comply with legal or contractual obligations and
        the provision of such information is mandatory. If such information is
        not provided, please note that we will not be able to process your
        application to join the ATeam Talent community or maintain our
        relationship with you, as applicable.{' '}
      </div>
      <div style={{ marginTop: '32px' }}>
        <div className={styles.bold}>
          B. To whom we disclose your information.
        </div>{' '}
        We may disclose your personal information to:
      </div>
      <ul>
        <li>
          Corporate affiliates, such as entities that are a holding company or a
          subsidiary of ATeam for purposes set out in this Privacy Statement,
          including recruitment and human resource management purposes, for the
          purposes of compliance with applicable laws and regulations, and IT
          support.
        </li>
        <li>
          Service providers, these are entities or people that provide products
          and services to ATeam such as IT systems suppliers and support,
          background and credit check providers, and hosting service providers.
        </li>
        <li>
          Governmental authorities, law enforcement and others: Entities that
          regulate or have jurisdiction over ATeam such as regulatory
          authorities, law enforcement bodies, public bodies, and judicial
          bodies, including to meet national security or law enforcement
          requirements.
        </li>
        <li>
          Private parties: For the purposes specified in this Privacy Statement
          such as to comply with legal process.
        </li>
        <li>
          Professional advisors: Lawyers, immigration advisors and other outside
          professional advisors.
        </li>
        <li>
          Business transferees: We may sell or purchase assets during the normal
          course of our business. If another entity acquires us or any of our
          assets, information we have collected about you may be transferred to
          such entity. In addition, if any bankruptcy or reorganization
          proceeding is brought by or against us, such information will be
          considered our asset and may be sold or transferred to third parties.
          Should such a sale or transfer occur, we will use reasonable efforts
          to require that the transferee use your personal information in a
          manner that is consistent with this Privacy Statement.
        </li>
        <li>
          Customers and business partners: ATeam corporate and non-profit
          customers and individuals with whom ATeam does business or is
          exploring a business relationship to facilitate your employment with
          such partners. We are not responsible for how any partners process
          your personal information. Please review the relevant partner’s
          privacy policy to understand how it may use your personal information.
        </li>
      </ul>
      <SectionHeading>International Data Transfer</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        ATeam may transfer your personal information to the United States and to
        other countries throughout the world that are not your country or
        jurisdiction of residence. The countries to which your information may
        be transferred may not have equivalent data protection laws to that
        which applies in your own country or jurisdiction. Your personal
        information will be stored and processed manually and electronically
        through global systems and tools for the purposes specified in this
        Privacy Statement.{' '}
      </div>
      <SectionHeading>Information Retention</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        ATeam’s retention periods for information collected under this Privacy
        Statement are based on business needs and legal requirements. We retain
        information for as long as is necessary for the processing purpose(s)
        for which we collected the information, as set out in this Privacy
        Statement, and any other permissible, related purposes. Unless
        instructed otherwise or required by applicable law, we will retain
        information you have supplied to us for the purposes specified in this
        Privacy Statement including, without limitation, in order to contact you
        (including by email) about other suitable vacancies and opportunities
        which may become available from time to time. Details about successful
        applicants to join the ATeam Talent community will be transferred to
        ATeam customer community members and information regarding their
        retention periods may be shared with you by such member, if applicable.
        When your information is no longer needed, we destroy the data.{' '}
      </div>
      <SectionHeading>Information Security</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        We maintain technical, administrative, physical and procedural security
        measures designed to protect your personal information from misuse,
        unauthorized access or disclosure, loss alteration or destruction.
      </div>
      <div style={{ marginTop: '8px' }}>
        Because there is always some risk in transmitting information over the
        Internet, while we make every effort to help ensure the integrity and
        security of our network and systems, we cannot guarantee our security
        measures. Users of this Site are required to keep their password
        confidential as a loss of such password may lead to third party
        individuals accessing and amending data without their consent, for which
        we cannot be held liable.
      </div>
      <SectionHeading>
        Links to Other Sites or Apps or Third Party Services
      </SectionHeading>
      <div style={{ marginTop: '32px' }}>
        Where this Site has links to third-party sites or applications, such
        sites may have privacy policies that differ from our own. When you use
        these links you will leave our website and enter sites we have no
        control over. This Privacy Statement does not cover your use of these
        sites or govern the personal information which you may provide. We are
        not responsible for the practices of such sites or applications. To
        protect your privacy, we suggest that you review the individual privacy
        statement on any third party website before using the site or submitting
        any personal information. This Privacy Statement does not address, and
        we are not responsible for, the practices of any third parties, which
        may have their own rules for how they collect and use your information.
      </div>
      <SectionHeading>Children</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        This Site is not intended for minors under the age of 16.
      </div>
      <SectionHeading>Your Choices</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        In certain circumstances, you may exercise choices in relation to the
        information we hold about you. We set out below an outline of these
        choices and how to exercise them. Please note that for each of the
        choices below, we may have valid legal reasons to refuse your request,
        in such instances we will let you know if that is the case. If you want
        more detail on when the choices apply, please contact{' '}
        <a href="mailto:privacy@a.team">privacy@a.team</a>.
      </div>
      <div style={{ marginTop: '16px' }}>
        <div className={styles.bold}>A. Deleting Your Information.</div> When
        you deactivate your account, we will still store your data as described
        in this Privacy Statement. To delete your data, please email us at
        <a href="mailto:privacy@a.team">privacy@a.team</a>. We reserve the right
        to retain your data for the time periods set out in this Privacy
        Statement.
      </div>
      <div style={{ marginTop: '16px' }}>
        <div className={styles.bold}>B. Objection to Marketing.</div> At any
        time you may object to our processing of information about you in order
        to send you information about roles that we think you may be interested
        in, including where we build profiles for such purposes and we will stop
        processing the data for that purpose. If you no longer wish to receive
        any updates from us regarding new job opportunities and vacancies, you
        should contact <a href="mailto:privacy@a.team">privacy@a.team</a>.
      </div>
      <div style={{ marginTop: '16px' }}>
        <div className={styles.bold}>C. Complaints.</div> In the event that you
        wish to make a complaint about how we process your personal data, please
        contact us in the first instance at{' '}
        <a href="mailto:privacy@a.team">privacy@a.team</a>. We will promptly
        take all reasonable steps to investigate any complaints or requests in
        relation to personal information.{' '}
      </div>
      <div style={{ marginTop: '16px' }}>
        <div className={styles.bold}>D. Do Not Track.</div> Some Internet
        browsers may be configured to send “Do Not Track” signals to the online
        services that you visit. We currently do not respond to “Do Not Track”
        or similar signals. To find out more about “Do Not Track,” please visit
        <a href="http://www.allaboutdnt.com">http://www.allaboutdnt.com</a>.
      </div>
      <div style={{ marginTop: '16px' }}>
        <div className={styles.bold}>
          E. Choosing Not to Share Your Information.
        </div>{' '}
        Where we are required by law to collect your information or where we
        need your information in order to consider your candidacy, if you do not
        provide this information when requested (or you alter ask to delete it),
        we may not be able to consider your candidacy. We will tell you what
        information you must provide at the time of collection or through other
        appropriate means.
      </div>
      <SectionHeading>If You Have Questions</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        If you have any questions about this Privacy Statement or the practices
        described herein, you can contact us at{' '}
        <a href="mailto:privacy@a.team">privacy@a.team</a>.
      </div>
      <SectionHeading>Revisions to This Privacy Statement</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        We reserve the right to revise this Privacy Statement at any time. If we
        do so, we will post such change(s) on the Site. Please check back
        frequently to see any updates or changes to this Privacy Statement. Any
        changes will become effective when we post the revised notice on our
        Site or as otherwise indicated.
      </div>
      <SectionHeading>Notice to European Resident Talent</SectionHeading>
      <div style={{ marginTop: '32px' }}>
        <div className={styles.bold}>A. General.</div> The information provided
        in this “Notice to European Residents Talent” section applies only to
        individuals in Europe. References to “personal information” or
        “information” in this Privacy Statement are equivalent to “personal
        data” governed by European data protection laws.
      </div>
      <div style={{ marginTop: '16px' }}>
        <div className={styles.bold}>B. Controller and EU Representative.</div>{' '}
        ATeams, Inc. is the data controller. For questions or concerns related
        to your personal data, please contact ATeam Privacy at:
      </div>
      <div style={{ marginTop: '8px' }}>
        E-mail: privacy@a.team
        <br />
        Phone: [INSERT]
      </div>
      <div style={{ marginTop: '16px' }}>
        Our European Union data representative is [INSERT] and can be contacted
        at:
      </div>
      <div style={{ marginTop: '8px' }}>
        E-mail: [INSERT]
        <br />
        Phone: [INSERT]
        <br />
        Post: [INSERT]
      </div>
      <div style={{ marginTop: '16px' }}>
        <div className={styles.bold}>C. Legal Bases for Processing.</div> We may
        use your personal information only as permitted by law. Our legal bases
        for processing the personal information described in this Privacy
        Statement are described in the table below.
      </div>
      <div style={{ border: '1px solid #62646A', marginTop: '24px' }}>
        <div style={{ display: 'flex', backgroundColor: '#fff' }}>
          <div style={{ width: '50%', padding: '16px', flexShrink: 0 }}>
            <div className={styles.bold}>Processing purpose</div>
            <div className={styles.light} style={{ marginTop: '8px' }}>
              Details regarding each processing purpose listed below are
              provided in the section above titled “Information Use and
              Disclosure”.
            </div>
          </div>
          <div
            style={{ padding: '16px', borderLeft: '1px solid #62646A' }}
            className={styles.bold}
          >
            Legal basis
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div style={{ width: '50%', padding: '16px', flexShrink: 0 }}>
            <div className={styles.bold}>
              <li>Managing the community workforce</li>
              <li>Operating the Site</li>
              <li>Сommunications</li>
              <li>Taking legal action</li>
              <li>Analytics</li>
            </div>
          </div>
          <div style={{ padding: '16px', borderLeft: '1px solid #62646A' }}>
            These activities constitute our legitimate interests. We do not use
            your personal information for these activities where our interests
            are overridden by the impact on you (unless we have your consent or
            are otherwise required or permitted to by law).
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div style={{ width: '50%', padding: '16px', flexShrink: 0 }}>
            <div className={styles.bold}>
              <li>For research and development</li>
              <li>To send you marketing communications</li>
              <li>To display advertisements</li>
              <li>For compliance, fraud prevention and safety</li>
              <li>To create anonymous, aggregated or de-identified data</li>
            </div>
          </div>
          <div style={{ padding: '16px', borderLeft: '1px solid #62646A' }}>
            These activities constitute our legitimate interests. We do not use
            your personal information for these activities where our interests
            are overridden by the impact on you (unless we have your consent or
            are otherwise required or permitted to by law).
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div style={{ width: '50%', padding: '16px', flexShrink: 0 }}>
            <div className={styles.bold}>Compliance</div>
          </div>
          <div style={{ padding: '16px', borderLeft: '1px solid #62646A' }}>
            Processing is necessary to comply with our legal obligations.
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div style={{ width: '50%', padding: '16px', flexShrink: 0 }}>
            <div className={styles.bold}>With your consent</div>
          </div>
          <div style={{ padding: '16px', borderLeft: '1px solid #62646A' }}>
            Processing is based on your consent. Where we rely on your consent
            you have the right to withdraw it any time in the manner indicated
            when you consent or otherwise instructed.
          </div>
        </div>
      </div>
      <div style={{ marginTop: '16px' }}>
        In particular, we may use your sensitive information, such as
        health/medical information, in order to accommodate a disability or
        illness during the application to become part of the ATeam Talent
        community process, your diversity-related information (such as race or
        ethnicity) in order to comply with legal obligations relating to
        diversity and anti-discrimination, and your criminal conviction data
        only where it is appropriate (given the role for which you are applying)
        and we are legally able to do so.
      </div>
      <div style={{ marginTop: '16px' }}>
        <div className={styles.bold}>D. International Data Transfers.</div> If
        we transfer your personal information out of Europe to a country not
        deemed by the European Commission to provide an adequate level of
        personal information protection, the transfer will be performed:
      </div>
      <ul>
        <li>
          Pursuant to the recipient’s compliance with standard contractual
          clauses, Swiss-US Privacy Shield (as applicable), or Binding Corporate
          Rules;
        </li>
        <li>
          Pursuant to the consent of the individual to whom the personal
          information pertains;
        </li>
        <li>As otherwise permitted by applicable European requirements.</li>
      </ul>
      <div style={{ marginTop: '8px' }}>
        You may contact us if you want further information on the specific
        mechanism used by us when transferring your personal information out of
        Europe.
      </div>
      <div style={{ marginTop: '16px' }}>
        <div className={styles.bold}>E. Automated Decisions.</div> ATeam does
        not envisage that you will be subject to decisions that will have a
        significant impact on you based solely on automated decision making.
      </div>
      <div style={{ marginTop: '16px' }}>
        <div className={styles.bold}>F. Your Rights.</div> In certain
        circumstances, you have rights in relation to the personal information
        we collect about you. We set out below an outline of those rights and
        how to exercise them.
      </div>
      <ul>
        <li>
          Access and portability: You have the right to know whether we process
          personal information about you, and if we do, to access information we
          hold about you and certain information about how we use it and with
          whom we share it. You also have the right to receive the information
          you have provided to ATeam in a structured, commonly used and
          machine-readable format for onward transmission.
        </li>
        <li>
          Correction, erasure and restriction of processing: You have the right
          to require us to correct any personal information held about you that
          is inaccurate and have incomplete information completed or ask us to
          delete information (i) where you believe it is no longer necessary for
          us to hold the personal information; (ii) where we are processing your
          information on the basis of our legitimate interest and you object to
          such processing; or (iii) if you believe the personal information we
          hold about you is being unlawfully processed by us. You can ask us to
          restrict processing information we hold about you other than for
          storage purposes if you believe the personal information is not
          accurate (whilst we verify accuracy); where we no longer need the
          personal information for the purposes of the processing but you
          require us to retain the information for the establishment, exercise
          or defence of legal claims; or where you have objected to us
          processing personal information and we are considering your
          objection.;
        </li>
        <li>
          Objection: You have the right to object to our processing of
          information about you based on legitimate interests and any automated
          decision-making and we will consider your request. Please provide us
          with detail as to your reasoning so that we can assess whether there
          is a compelling overriding interest in us continuing to process such
          information or we need to process it in relation to legal claims;
        </li>
        <li>
          Complaints: In the event that you wish to make a complaint about how
          we process your personal information, please contact us in the first
          instance at <a href="mailto:privacy@a.team">privacy@a.team</a> and we
          will endeavour to deal with your request. This is without prejudice to
          your right to raise a complaint with a relevant supervisory authority.
        </li>
      </ul>
      <div style={{ marginTop: '8px' }}>
        You may exercise the rights set out at above by emailing{' '}
        <a href="mailto:privacy@a.team">privacy@a.team</a>. Please note that we
        will require you to verify your identity before responding to any
        requests to exercise your rights by providing suitable forms of
        identification, which are given below. ATeam requires 2 pieces of ID, 1
        from list A and 1 from list B, as below:{' '}
      </div>
      <div style={{ border: '1px solid #62646A', marginTop: '24px' }}>
        <div style={{ display: 'flex', backgroundColor: '#fff' }}>
          <div style={{ width: '50%', padding: '16px', flexShrink: 0 }}>
            <div className={styles.bold}>List A</div>
          </div>
          <div
            style={{ padding: '16px', borderLeft: '1px solid #62646A' }}
            className={styles.bold}
          >
            List B
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div style={{ width: '50%', padding: '16px', flexShrink: 0 }}>
            <div className={styles.bold}>Valid Passport</div>
          </div>
          <div style={{ padding: '16px', borderLeft: '1px solid #62646A' }}>
            Recent utility bill showing data subject’s current home address (no
            more than 3 months’ old)
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div style={{ width: '50%', padding: '16px', flexShrink: 0 }}>
            <div className={styles.bold}>Valid Photo Driving Licence</div>
          </div>
          <div style={{ padding: '16px', borderLeft: '1px solid #62646A' }}>
            Recent Bank or Building Society Statement (no more than 3 months’
            old)
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div style={{ width: '50%', padding: '16px', flexShrink: 0 }}>
            <div className={styles.bold}>Compliance</div>
          </div>
          <div style={{ padding: '16px', borderLeft: '1px solid #62646A' }}>
            Processing is necessary to comply with our legal obligations.
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div style={{ width: '50%', padding: '16px', flexShrink: 0 }}>
            <div className={styles.bold}>With your consent</div>
          </div>
          <div style={{ padding: '16px', borderLeft: '1px solid #62646A' }}>
            Processing is based on your consent. Where we rely on your consent
            you have the right to withdraw it any time in the manner indicated
            when you consent or otherwise instructed.
          </div>
        </div>
      </div>
      <div style={{ marginTop: '16px' }}>
        Please note that for each of the rights above we may have valid legal
        reasons to refuse your request, in such instances we will let you know
        if that is the case. If you want more detail on when the rights apply,
        please contact <a href="mailto:privacy@a.team">privacy@a.team</a>. You
        also can file a complaint with your local data protection supervisory
        authority. You can find your data protection regulator{' '}
        <a href="https://edpb.europa.eu/about-edpb/board/members_en">here</a>.
      </div>
      <div style={{ marginTop: '8px' }}>
        You can withdraw your consent at any time by sending an e-mail to{' '}
        <a href="mailto:privacy@a.team">privacy@a.team</a>.{' '}
      </div>
      <div
        style={{ textAlign: 'right', marginTop: '32px' }}
        className={styles.light}
      >
        232972090 v1{' '}
      </div>
    </div>
  );
}
