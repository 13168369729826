import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import CommonStyles from '../common/styles';
import { Expertise } from '@src/stores/Profile/models';

interface SkillsProps {
  skills: string[];
  allProfileSkills: Expertise[];
}

const useStyles = createUseStyles({
  container: {
    marginTop: 64,
  },
  tags: {
    marginTop: 24,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
  },
  tag: {
    backgroundColor: '#F7F7F7',
    borderRadius: 6,
    padding: 16,
    fontSize: 14,
  },
});

const Skills = ({
  skills,
  allProfileSkills,
}: SkillsProps): JSX.Element | null => {
  const commonStyles = CommonStyles();
  const styles = useStyles();

  const skillNames = useMemo(() => {
    if (skills.length === 0 || allProfileSkills.length === 0) {
      return [];
    }
    const skillNames: string[] = [];
    skills.forEach((skill) => {
      const foundSkill = allProfileSkills.find((pSkill) => pSkill.id === skill);
      if (foundSkill) {
        skillNames.push(foundSkill.name);
      }
    });

    return skillNames;
  }, [skills, allProfileSkills]);

  if (skillNames.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={commonStyles.title}>Skills used</div>
      <div className={styles.tags}>
        {skillNames.map((skillName, index) => (
          <div key={`skill-${index}`} className={styles.tag}>
            {skillName}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;
