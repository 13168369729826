import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ModalBasicProps } from '@src/components/Modal';
import { Modal, Select, SelectOption } from '@a_team/ui-components';
import { Breakpoints, Button } from '@ateams/components';
import cx from 'classnames';
import { format } from 'date-fns';
import { createUseStyles } from 'react-jss';
import {
  useAdminMigrateTimesheetToNewMission,
  useAdminQueryBuilderActiveMissions,
} from '@src/rq/missions';
import { useHistory } from 'react-router-dom';

interface Props extends ModalBasicProps {
  startDate: Date;
  endDate: Date;
  currentMissionId: string;
  timesheetId: string;
  builderId: string;
  builderName: string;
}

const useStyles = createUseStyles({
  modal: {
    padding: 0,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  title: {
    textAlign: 'center',
    margin: 0,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '26px',
  },
  description: {
    textAlign: 'center',
    margin: '8px 0 0 0',
    fontSize: 15,
    lineHeight: '24px',
    color: '#818388',
  },
  builderInfo: {
    background: '#F9FAFC',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16,
    borderRadius: 8,
  },
  destination: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  buttonsWrapper: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center',
    gap: 12,
  },
  button: {
    maxWidth: 185,
    borderRadius: 8,
    transition: 'background 0.3s ease',
    width: 'fit-content',
    padding: '8px 12px',
    fontSize: 14,
    minWidth: 132,
  },
  secondaryActionButton: {
    '&:hover': {
      background: '#DADADC',
    },
  },
  primaryActionButton: {
    '&:disabled': {
      background: '#E8D0FF',

      '&:hover': {
        background: '#E8D0FF',
      },
    },
    '&:hover': {
      background: '#5900b3',
    },
  },
  error: {
    color: '#F63041',
    fontSize: 14,
    fontWeight: 400,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '100% !important',
      maxWidth: 680,
      padding: 40,
    },
    wrapper: {
      padding: '0 48px',
    },
    description: {
      padding: '0 32px',
    },
  },
});

export default function AdminConfirmTimesheetMigrationModal(
  props: Props,
): ReactElement | null {
  const { startDate, endDate, onClose } = props;
  const styles = useStyles();
  const history = useHistory();
  const [newMissionId, setNewMissionId] = useState<string | undefined>(
    undefined,
  );
  const { data: activeMissions, isLoading: isLoadingActiveMissions } =
    useAdminQueryBuilderActiveMissions(props.builderId);
  const {
    mutate: migrateTimesheet,
    isLoading: isMigratingTimesheet,
    isSuccess: hasMigratedTimesheet,
    error,
    reset,
  } = useAdminMigrateTimesheetToNewMission(props.timesheetId);

  const formattedStartDate = useMemo(
    () => format(startDate, 'LLL d'),
    [startDate],
  );

  const formattedEndDate = useMemo(() => format(endDate, 'LLL d'), [endDate]);
  const year = useMemo(() => format(endDate, 'yyyy'), [endDate]);

  const handleClose = () => {
    setNewMissionId(undefined);
    // to clear out error state
    reset();
    onClose();
  };

  const handleMigrateTimesheet = useCallback(() => {
    if (!newMissionId || isMigratingTimesheet) {
      return;
    }

    migrateTimesheet(newMissionId);
  }, [newMissionId, isMigratingTimesheet]);

  useEffect(() => {
    if (hasMigratedTimesheet) {
      history.push(`/mission/${newMissionId}/time-tracking/admin`);
    }
  }, [hasMigratedTimesheet, newMissionId]);

  if (!props.builderId) {
    return null;
  }

  return (
    <Modal
      variant="slideUp"
      isOpen={props.open}
      onClose={handleClose}
      className={styles.modal}
      shouldHideGradientStroke
    >
      <div className={styles.wrapper}>
        <h4 className={styles.title}>
          {`Migrate ${formattedStartDate} - ${formattedEndDate} timesheet`}
        </h4>
        <div className={styles.description}>
          Choose from the builder’s active roles where the timesheet should be
          migrated.
        </div>
        <div className={styles.builderInfo}>
          <div>{props.builderName}</div>
          <div>{`${formattedStartDate} - ${formattedEndDate}, ${year}`}</div>
        </div>
        <div className={styles.destination}>
          <label htmlFor="destination-mission">
            Select destination Mission
          </label>
          <Select
            id="destination-mission"
            styles={{
              control: (base) => ({
                ...base,
                border: '1px solid #DADADC',
                borderColor: '#DADADC !important',
                backgroundColor: 'white',
              }),
              placeholder: (base) => ({
                ...base,
                color: '#62646A',
              }),
            }}
            placeholder="Select"
            options={activeMissions?.items.map((mission) => ({
              label: `${mission.title}${
                mission.mid === props.currentMissionId ? ' (Current)' : ''
              }`,
              value: mission.mid,
            }))}
            isOptionDisabled={(option) =>
              (option as SelectOption).value === props.currentMissionId
            }
            isOptionSelected={(option) =>
              (option as SelectOption).value === newMissionId
            }
            onChange={(data) => setNewMissionId((data as SelectOption).value)}
          />
          {error && <p className={styles.error}>{(error as Error).message}</p>}
        </div>
        <div className={styles.buttonsWrapper}>
          <Button
            className={cx(styles.button, styles.secondaryActionButton)}
            onClick={handleClose}
            size="small"
            color={'backgroundDark'}
            data-testing-id="cancel-button"
          >
            Cancel
          </Button>
          <Button
            className={cx(styles.button, styles.primaryActionButton)}
            onClick={handleMigrateTimesheet}
            size="small"
            color={'secondaryDark'}
            disabled={!newMissionId || isLoadingActiveMissions}
          >
            {isMigratingTimesheet ? `Migrating timesheet...` : `Move timesheet`}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
