import React, { ReactElement, ReactNode } from 'react';

/**
 * Truncates a given string according to a given char limit and adds '...'
 * @param {String} string - The string to truncate
 * @param {Number} limit - The chars limit to cut at string at
 */
export const truncate = (string: string, limit: number): string =>
  string.length > limit ? `${string.substring(0, limit)}…` : string;

/**
 * Generates a paragraphed version of a text with line breaks
 * @param {String} string - The string to break into paragraphs
 */
export const splitToParagraphs = (string: string): ReactNode => {
  return string
    .split(/(\s*\n)+\s*/g)
    .map((p, i): ReactElement => <p key={`p-${i}`}>{p}</p>);
};

/**
 * Checks if a given string is a valid image url
 * @param {String} url - The url to check
 */
export const isImageUrl = (url: string): boolean => {
  const regex = /^(http(s?):)([/|.|\w|\s|:|,|-])*\.(?:jpe?g|gif|png|svg)$/;
  return regex.test(url);
};

/**
 * Generates a human-readable slug with no special chars
 * @param {String} str - String to turn to slug
 */
export const toSlug = (str: string): string => {
  str = str.replace(/^\s+|\s+$/g, '');
  str = str.toLowerCase();

  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');

  return str;
};

export const generateUniqueId = (): string => {
  return Array.from({ length: 24 }, () =>
    '0123456789abcdef'.charAt(Math.floor(Math.random() * 16)),
  ).join('');
};

export const addApostropheToName = (name: string): string => {
  if (name.endsWith('s')) {
    return `${name}'`;
  }
  return `${name}'s`;
};

export function pluralize(
  count: number | undefined,
  noun: string,
  suffix = 's',
) {
  return `${count || 1} ${noun}${count !== 1 ? suffix : ''}`;
}
