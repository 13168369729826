import React, { useEffect } from 'react';
import VettingDashboardLayout, {
  useAdminModeGuard,
} from '@src/layouts/Vetting';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import VettingTable from '@src/views/VettingDashboard/vetting-table';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import { VettingProcessStatus } from '@a_team/models/dist/vetting-processes/status';
import { VettingProcessesQueryBar } from '../vetting-processes-query-bar';
import { ColumnsConfig, useColumns } from './columns';
import { FiltersConfig, useFilters } from './filters';
import { usePagination } from './pagination';
import { useSort } from './sorting';

interface GetTableProcessesPageConfig {
  tabName: string;
  columnsConfig: ColumnsConfig;
  filtersConfig: FiltersConfig;
  title: string;
  noProcessesMessage: string;
  statuses: VettingProcessStatus[];
  isStale?: boolean;
}

export const VETTING_PROCESSES_TABLE_TABS = {
  membersToVet: 'members-to-vet',
  pendingInterviews: 'pending-interviews',
  completedReviews: 'completed-reviews',
};

export function getProcessesTablePage({
  tabName,
  columnsConfig,
  filtersConfig,
  title,
  noProcessesMessage,
  statuses,
  isStale,
}: GetTableProcessesPageConfig): () => JSX.Element {
  const Component = () => {
    useAdminModeGuard();
    const { vettingProcesses, vetters } = useStores();
    const [loading, setLoading] = useLoadingState();
    const { availableFilters, filters, onFiltersChange } = useFilters(
      filtersConfig,
      tabName,
      vetters.vetterConfiguration,
      vettingProcesses.isAdminView,
    );
    const { sorting, onSortChange } = useSort();
    const paginationProps = usePagination();
    const {
      columns,
      columnsOptions,
      selectedColumnsOptions,
      onColumnsChange,
      onResetColumns,
    } = useColumns(
      columnsConfig,
      tabName,
      vettingProcesses.processesQuery.shouldShowAllColumns,
      vetters.vetterConfiguration?.type,
      vettingProcesses.isAdminView,
    );

    function getVettingProcesses() {
      return vettingProcesses.getVettingProcesses({
        filters: { ...filters, statuses, isStale },
        sorting,
      });
    }

    function getVetterConfiguration() {
      return vetters.getVettingConfiguration();
    }

    useEffect(() => {
      vettingProcesses.clearVettingProcesses();

      setLoading(
        (async () => {
          const promises: Promise<unknown>[] = [getVettingProcesses()];

          if (!vetters.vetterConfiguration) {
            promises.push(getVetterConfiguration());
          }

          await Promise.all(promises);
        })(),
        'Vetting Processes Loaded',
      );
    }, [
      filters,
      vettingProcesses.processesQuery.batchSize,
      vettingProcesses.processesQuery.page,
    ]);

    useEffect(() => {
      vettingProcesses.getVettingProcessesCount({
        filters: { ...filters, statuses, isStale },
      });
    }, [filters]);

    const isLoading = loading === true;

    return (
      <VettingDashboardLayout title={title}>
        {vettingProcesses.isAdminView || availableFilters.size ? (
          <VettingProcessesQueryBar
            defaultFilters={filters}
            onFiltersChange={onFiltersChange}
            availableFilters={availableFilters}
            columnsOptions={columnsOptions}
            selectedColumns={selectedColumnsOptions}
            onColumnsChange={onColumnsChange}
            onResetColumns={onResetColumns}
            enableColumnsConfiguration={Boolean(vettingProcesses.isAdminView)}
          />
        ) : undefined}
        {isLoading ? (
          ''
        ) : vettingProcesses.vettingProcesses?.length ||
          vettingProcesses.processesQuery.page !== 1 ? (
          <>
            <VettingTable
              columns={columns}
              rows={vettingProcesses.vettingProcesses || []}
              paginationProps={paginationProps}
              isAdminView={Boolean(vettingProcesses.isAdminView)}
              sorting={sorting}
              onSortChange={onSortChange}
            />
          </>
        ) : (
          noProcessesMessage
        )}
        <LoadingIndicator loading={loading} />
      </VettingDashboardLayout>
    );
  };

  return observer(Component);
}
