import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useStores } from '@src/stores';
import { AdminVettingProcessColumnProps } from '..';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useToggle from '@src/hooks/useToggle';
import { Button } from '@ateams/components';
import { VettingType } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { VettingProcessStatus } from '@a_team/models/dist/vetting-processes/status';
import { VetterId, VetterSuggestion } from '@a_team/models/dist/vetter';
import {
  OnInterviewerSelect,
  OnMatchInterviewer,
  VetterSuggestionsModal,
} from '@src/components/Modal/VetterSuggestionsModal';

const useStyles = createUseStyles({
  button: {
    width: '230px',
  },
});

export const InterviewerSuggestionsField: React.FC<
  AdminVettingProcessColumnProps
> = ({ vettingProcess }) => {
  const styles = useStyles();
  const { vettingProcesses } = useStores();
  const [suggestions, setSuggestions] = useState<VetterSuggestion[]>();
  const [selectedSuggestion, setSelectedSuggestion] = useState<
    VetterId | undefined
  >(vettingProcess.vetter?.id);
  const [vetterSuggestionsModalOpen, toggleVetterSuggestionsModalOpen] =
    useToggle();
  const [loading, setLoading] = useLoadingState();

  /**
   * We want the current vetter (or none if not set) whenever the modal is being
   * opened (specifically when we re-open it, we want to re-initialize the selected vetter)
   */
  useEffect(() => {
    if (vetterSuggestionsModalOpen) {
      setSelectedSuggestion(vettingProcess.vetter?.id);
    }
  }, [vetterSuggestionsModalOpen]);

  const loadSuggestions = () => {
    setLoading(
      (async () => {
        const suggestions = await vettingProcesses.getInterviewerSuggestions(
          vettingProcess.id,
        );

        if (!suggestions.length) {
          return 'No suggestions found';
        }

        setSuggestions(suggestions);
        toggleVetterSuggestionsModalOpen(true);

        return 'Suggestions Loaded';
      })(),
    );
  };

  const onVetterSelect: OnInterviewerSelect = (selectedSuggestion) => {
    setSelectedSuggestion(selectedSuggestion.id);
  };

  const onMatchInterviewer: OnMatchInterviewer = (selectedSuggestion) => {
    if (selectedSuggestion) {
      setLoading(
        (async () => {
          await vettingProcesses.patchVettingProcess({
            id: vettingProcess.id,
            vetter: selectedSuggestion,
          });

          toggleVetterSuggestionsModalOpen(false);
        })(),
        'Interviewer set successfully',
      );
    }
  };

  const hasCodeSampleProgrammingLanguages =
    vettingProcess.preVetting &&
    'codeSampleProgrammingLanguages' in vettingProcess.preVetting &&
    vettingProcess.preVetting.codeSampleProgrammingLanguages.length;

  const isButtonDisabled = React.useMemo(
    () =>
      Boolean(
        loading === true ||
          vettingProcess.vettingType !== VettingType.SelectionTeam ||
          !hasCodeSampleProgrammingLanguages ||
          vettingProcess.status === VettingProcessStatus.Completed,
      ),
    [loading, vettingProcess],
  );

  return (
    <>
      <Button
        onClick={loadSuggestions}
        disabled={isButtonDisabled}
        size="small"
        className={styles.button}
      >
        Interviewer Suggestions
      </Button>

      {suggestions && (
        <VetterSuggestionsModal
          open={vetterSuggestionsModalOpen}
          onClose={toggleVetterSuggestionsModalOpen}
          suggestions={suggestions}
          selectedSuggestion={selectedSuggestion}
          onChange={onVetterSelect}
          onConfirm={onMatchInterviewer}
          loading={loading === true}
        />
      )}

      <LoadingIndicator loading={loading} />
    </>
  );
};
