import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { VettingProcessFeedbackScores } from '@a_team/models/dist/vetting-processes/feedback';
import {
  AccentScoreLabels,
  AdminNotesScore,
  EnglishLevelLabels,
  ExpertiseScoreLabels,
  InteractionExperienceLabels,
  ScoreType,
  ScoreTypeLabels,
} from '@a_team/models/dist/AdminNotesObject';
import { Colors, Spacing } from '@ateams/components';
import Modal, { ModalBasicProps } from '@src/components/Modal';
import { CallToActionButton, CallToActionButtonType } from './cta-button';
import { Size, Text } from '../components/typography';
import { RightArrow } from '../components/icons/arrows';
import { FontWeights } from '@ateams/components';

export interface OverwriteProfileScoresModalProps
  extends ModalBasicProps,
    ScoreChangesProps {
  onOverwrite: () => void;
  onDiscard: () => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    padding: '40px',
  },
  title: {
    marginBottom: Spacing.small,
  },
  description: {
    color: '#818388',
    marginBottom: '40px',
    textAlign: 'center',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    height: '40px',
    width: '160px',
  },
  discardChangesButton: {
    marginRight: '12px',
  },
  scoresChangesContainer: {
    marginBottom: '40px',
  },
  disabled: {
    opacity: 0.5,
  },
});

/**
 * Figma: {@link https://www.figma.com/file/fiIpGWCIUv1WVZWVn7wwP6/Admin-side%3A-Vetting?node-id=2945%3A3272&t=4S29s9gyvCoHtQRU-0}
 */
export const OverwriteProfileScoresModal: React.FC<
  OverwriteProfileScoresModalProps
> = (props) => {
  const { onClose, onOverwrite, onDiscard, currentScores, newScores, ...rest } =
    props;
  const styles = useStyles();

  const hasScoreChanged = (
    currentScore?: AdminNotesScore,
    newScore?: AdminNotesScore,
  ) => {
    return currentScore != null && newScore != null && newScore >= currentScore;
  };

  const noScoresChanges = useMemo(() => {
    return (
      !hasScoreChanged(currentScores.expertise, newScores.expertise) &&
      !hasScoreChanged(
        currentScores.interactionExperience,
        newScores.interactionExperience,
      ) &&
      !hasScoreChanged(currentScores.englishLevel, newScores.englishLevel) &&
      !hasScoreChanged(currentScores.accent, newScores.accent)
    );
  }, [currentScores, newScores]);

  return (
    <Modal
      onClose={onClose}
      style={{ padding: 0, width: '750px' }} // using className doesn't work here
      {...rest}
    >
      <div className={styles.container}>
        <Text size={Size.ExtraLarge} className={styles.title}>
          {'Do you want to overwrite this builder’s scores?'}
        </Text>
        <Text size={Size.Medium} className={styles.description}>
          {
            'This builder was previously scored by another admin. Do you want to overwrite\nthose scores with the ones entered on this form?'
          }
        </Text>

        <ScoresChanges
          className={styles.scoresChangesContainer}
          currentScores={currentScores}
          newScores={newScores}
        />

        <div className={styles.actionsContainer}>
          <CallToActionButton
            ctaType={CallToActionButtonType.Secondary}
            onClick={onDiscard}
            className={cx(styles.button, styles.discardChangesButton)}
          >
            {'Discard scores'}
          </CallToActionButton>
          <CallToActionButton
            ctaType={CallToActionButtonType.Primary}
            onClick={onOverwrite}
            className={cx(styles.button, noScoresChanges && styles.disabled)}
            disabled={noScoresChanges}
          >
            {'Overwrite scores'}
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
};

interface ScoreChangesProps {
  currentScores: VettingProcessFeedbackScores;
  newScores: VettingProcessFeedbackScores;
  className?: string;
}

const useScoresChangesStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Spacing.small,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  currentScoreText: {
    width: '240px',
  },
  scoreLabel: {
    fontWeight: FontWeights.small,
  },
  rightArrowIcon: {
    marginLeft: Spacing.large,
    marginRight: Spacing.large,
  },
  bold: {
    fontWeight: FontWeights.medium,
  },
  ignore: {
    color: Colors.danger,
  },
});

const ScoresChanges: React.FC<ScoreChangesProps> = ({
  currentScores,
  newScores,
  className,
}) => {
  const styles = useScoresChangesStyles();

  const hasScoreChanged = (
    currentScore?: AdminNotesScore,
    newScore?: AdminNotesScore,
  ) => {
    return currentScore != null && newScore != null && newScore <= currentScore;
  };

  const getIgnoreChange = (
    currentScore?: AdminNotesScore,
    newScore?: AdminNotesScore,
  ) => {
    return hasScoreChanged(currentScore, newScore) ? ' (Ignore)' : '';
  };

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <Text
          size={Size.Large}
          className={cx(styles.currentScoreText, styles.bold)}
        >
          Existing scores
        </Text>
        <RightArrow className={styles.rightArrowIcon} />
        <Text size={Size.Large} className={styles.bold}>
          New scores
        </Text>
      </div>

      {typeof newScores.expertise === 'number' && (
        <div className={styles.row}>
          <Text className={cx(styles.scoreLabel, styles.currentScoreText)}>
            {ScoreTypeLabels[ScoreType.Expertise]}:{' '}
            {typeof currentScores.expertise === 'number' ? (
              <span className={styles.bold}>
                {ExpertiseScoreLabels[currentScores.expertise]}
              </span>
            ) : (
              <NoValueScore />
            )}
          </Text>
          <RightArrow className={styles.rightArrowIcon} />
          <Text className={styles.scoreLabel}>
            {ScoreTypeLabels[ScoreType.Expertise]}:{' '}
            <span className={styles.bold}>
              {ExpertiseScoreLabels[newScores.expertise]}
            </span>
            <span className={styles.ignore}>
              {getIgnoreChange(currentScores.expertise, newScores.expertise)}
            </span>
          </Text>
        </div>
      )}

      {typeof newScores.interactionExperience === 'number' && (
        <div className={styles.row}>
          <Text className={cx(styles.scoreLabel, styles.currentScoreText)}>
            {ScoreTypeLabels[ScoreType.InteractionExperience]}:{' '}
            {typeof currentScores.interactionExperience === 'number' ? (
              <span className={styles.bold}>
                {
                  InteractionExperienceLabels[
                    currentScores.interactionExperience
                  ]
                }
              </span>
            ) : (
              <NoValueScore />
            )}
          </Text>
          <RightArrow className={styles.rightArrowIcon} />
          <Text className={styles.scoreLabel}>
            {ScoreTypeLabels[ScoreType.InteractionExperience]}:{' '}
            <span className={styles.bold}>
              {InteractionExperienceLabels[newScores.interactionExperience]}
            </span>
            <span className={styles.ignore}>
              {getIgnoreChange(
                currentScores.interactionExperience,
                newScores.interactionExperience,
              )}
            </span>
          </Text>
        </div>
      )}

      {typeof newScores.englishLevel === 'number' && (
        <div className={styles.row}>
          <Text className={cx(styles.scoreLabel, styles.currentScoreText)}>
            {ScoreTypeLabels[ScoreType.English]}:{' '}
            {typeof currentScores.englishLevel === 'number' ? (
              <span className={styles.bold}>
                {EnglishLevelLabels[currentScores.englishLevel]}
              </span>
            ) : (
              <NoValueScore />
            )}
          </Text>
          <RightArrow className={styles.rightArrowIcon} />
          <Text className={styles.scoreLabel}>
            {ScoreTypeLabels[ScoreType.English]}:{' '}
            <span className={styles.bold}>
              {EnglishLevelLabels[newScores.englishLevel]}
            </span>
            <span className={styles.ignore}>
              {getIgnoreChange(
                currentScores.englishLevel,
                newScores.englishLevel,
              )}
            </span>
          </Text>
        </div>
      )}

      {typeof newScores.accent === 'number' && (
        <div className={styles.row}>
          <Text className={cx(styles.scoreLabel, styles.currentScoreText)}>
            {ScoreTypeLabels[ScoreType.Accent]}:{' '}
            {typeof currentScores.accent === 'number' ? (
              <span className={styles.bold}>
                {AccentScoreLabels[currentScores.accent]}
              </span>
            ) : (
              <NoValueScore />
            )}
          </Text>
          <RightArrow className={styles.rightArrowIcon} />
          <Text className={styles.scoreLabel}>
            {ScoreTypeLabels[ScoreType.Accent]}:{' '}
            <span className={styles.bold}>
              {AccentScoreLabels[newScores.accent]}
            </span>
            <span className={styles.ignore}>
              {getIgnoreChange(currentScores.accent, newScores.accent)}
            </span>
          </Text>
        </div>
      )}
    </div>
  );
};

const useNoValueScoreStyles = createUseStyles({
  text: {
    color: '#818388',
  },
});

const NoValueScore = () => {
  const styles = useNoValueScoreStyles();

  return <div className={styles.text}>N/A</div>;
};
