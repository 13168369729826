import { action, computed, observable } from 'mobx';
import { Stores } from '@src/stores/index';
import { apiDiscounts } from '@src/logic/services/endpoints';
import { type QueryResult } from '@a_team/models/dist/misc';
import DiscountObject, {
  BasicDiscountObject,
  DiscountId,
} from '@a_team/models/dist/Discount';
import { appendQueryResult, lockBy, transformQueryResult } from './utils';
import {
  CreateDiscountData,
  TerminateDiscountData,
} from '@ateams/api/dist/endpoints/Discounts';

type ObjectType = DiscountObject | BasicDiscountObject;

export interface DiscountsStoreData {
  currentId?: DiscountId;
  objects?: Record<DiscountId, ObjectType>;
  list?: QueryResult<DiscountId>;
}

export default class DiscountsStore {
  @observable public currentId: DiscountsStoreData['currentId'];
  @observable public objects: DiscountsStoreData['objects'];
  @observable public list: DiscountsStoreData['list'];

  private readonly rootStore: Stores;

  public constructor(rootStore: Stores, initialState?: DiscountsStoreData) {
    this.rootStore = rootStore;

    if (initialState) {
      this.currentId = initialState.currentId;
      this.objects = initialState.objects;
      this.list = initialState.list;
    }
  }

  public serialize(): DiscountsStoreData {
    return {
      currentId: this.currentId,
      objects: this.objects,
      list: this.list,
    };
  }

  // getters

  @computed public get current(): DiscountObject | undefined {
    if (!this.currentId) {
      return undefined;
    }

    return this.objects?.[this.currentId] as DiscountObject | undefined;
  }

  public getDiscount(did: DiscountId): DiscountObject | null {
    if (!this.current || this.current.did !== did) {
      return null;
    }

    return this.current;
  }

  public getDiscounts(): QueryResult<ObjectType> | null {
    const { list, objects } = this;

    if (!list || !objects) {
      return null;
    }

    return transformQueryResult(list, (did) => objects[did]);
  }

  // api

  async loadDiscount(did: DiscountId): Promise<void> {
    const discount = await lockBy(did, () =>
      apiDiscounts.getDiscount(this.rootStore.auth, did),
    );

    this.setCurrent(discount);
  }

  async loadDiscounts(reset?: boolean): Promise<void> {
    const { auth } = this.rootStore;

    const prevList = reset ? undefined : this.list;

    const discounts = await lockBy(`discounts-${prevList?.next}`, () =>
      apiDiscounts.getDiscounts(auth, prevList?.next ?? undefined),
    );

    this.appendList(discounts, prevList);
  }

  async createDiscount(data: CreateDiscountData): Promise<void> {
    const discount = await apiDiscounts.createDiscount(
      this.rootStore.auth,
      data,
    );

    this.setCurrent(discount);
    this.prepend(discount);
  }

  async terminateDiscount(
    did: DiscountId,
    data: TerminateDiscountData,
  ): Promise<void> {
    const discount = await apiDiscounts.terminateDiscount(
      this.rootStore.auth,
      did,
      data,
    );

    if (this.current?.did === did) {
      this.setCurrent(discount);
    } else {
      this.updateObject(discount);
    }
  }

  // actions

  @action private setCurrent(current: DiscountObject | undefined): void {
    if (current) {
      this.updateObject(current);
    }

    this.currentId = current?.did;
  }

  @action private updateObject(object: ObjectType): void {
    this.objects = {
      ...(this.objects ?? {}),
      [object.did]: object,
    };
  }

  @action private appendList(
    list: QueryResult<ObjectType>,
    prevList: DiscountsStoreData['list'],
  ): void {
    this.objects = {
      ...(this.objects ?? {}),
    };

    for (const discount of list.items) {
      this.objects[discount.did] = discount;
    }

    this.list = appendQueryResult(
      transformQueryResult(list, ({ did }) => did),
      prevList,
    );
  }

  @action private prepend(object: ObjectType): void {
    if (!this.list) return;

    this.list = {
      ...this.list,
      items: [object.did, ...this.list.items],
    };
  }
}
