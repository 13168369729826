import {
  useMutation,
  UseMutationResult,
  useQuery,
} from '@tanstack/react-query';
import { apiInvitation } from '@ateams/api';
import {
  ClientInvitationRequestData,
  InvitationRequestData,
} from '@ateams/api/dist/endpoints/Invitations';
import { BasicInvitationObject } from '@a_team/models/dist/InvitationObject';
import { useStores } from '@src/stores';
import { queryKeys } from './keys';

export const useInviteMutation = (): UseMutationResult<
  BasicInvitationObject,
  Error,
  InvitationRequestData
> => {
  const stores = useStores();
  return useMutation<BasicInvitationObject, Error, InvitationRequestData>(
    (data: InvitationRequestData) => apiInvitation.invite(stores.auth, data),
  );
};

export const useClientInviteMutation = (): UseMutationResult<
  {
    success: boolean;
  },
  Error,
  ClientInvitationRequestData
> => {
  const stores = useStores();
  return useMutation<
    {
      success: boolean;
    },
    Error,
    ClientInvitationRequestData
  >((data: ClientInvitationRequestData) =>
    apiInvitation.clientInvite(stores.auth, data),
  );
};

export const useGetUserInvitationByEmail = ({
  email,
  enabled = true,
}: {
  email: string;
  enabled?: boolean;
}) => {
  const stores = useStores();
  return useQuery({
    queryKey: queryKeys.invite.byEmail(email),
    queryFn: () => apiInvitation.getUserInvitationByEmail(stores.auth, email),
    enabled: !!email && enabled,
  });
};
