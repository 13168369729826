import { VettingProcessAuditAutomationReason } from '../vetting-process-audit';
import { UserId } from '../UserObject';
import { VetterId } from '../vetter';

export interface VetterNotified {
  id: string;
  vetter: VetterId;
  date: Date;
}

export interface ReachoutEmail {
  user: {
    uid: UserId;
    email: string;
  };
  date: Date;
  automationReason?: VettingProcessAuditAutomationReason;
}

export interface InterviewInviteEmail {
  date: Date;
}

export interface AdminInterviewInviteEmail {
  vetter: {
    uid: UserId;
    email: string;
  };
  date: Date;
}

export interface EmailThreadMetadata {
  messageId: string;
  subject: string;
}

export enum VettingProcessEmailTemplates {
  // Selection Team
  STInitial = 'STInitial',
  STFollowUp = 'STFollowUp',
  STSecondFollowUp = 'STSecondFollowUp',
  STScheduleCall = 'STScheduleCall',

  // Internal Technical
  InternalTechnicalInitial = 'internalTechnicalInitial',
  InternalTechnicalFollowUp = 'internalTechnicalFollowUp',

  // Internal Non-Technical
  InternalNonTechnicalInitial = 'internalNonTechnicalInitial',
  InternalNonTechnicalFollowUp = 'internalNonTechnicalFollowUp',
}
