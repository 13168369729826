import { createUseStyles } from 'react-jss';
import { Classes } from 'jss';
import { tagEditedYellowStyle } from '@src/common-styles/tag-edited';
import { Colors, TextColors } from '@ateams/components';

const useStyles: () => Classes = createUseStyles({
  textArea: {
    padding: 16,
    textDecorationLine: 'none',
    border: '1px solid #DADADC',
    borderRadius: '4px',
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: 400,
    '&::placeholder': {
      textDecorationLine: 'none',
    },
  },
  tagEdited: {
    ...tagEditedYellowStyle,
    marginLeft: 8,
  },
  error: {
    borderColor: Colors.primary,
    '&::placeholder': {
      color: TextColors.placeholderRegular,
    },
  },
  readingOnlyText: {
    fontSize: 15,
    lineHeight: '21px',
    fontWeight: 300,
    marginTop: 10,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    '&.admin': {
      border: '1px solid #DADADC',
      borderRadius: '4px',
      padding: 16,
    },
  },
  minLengthHint: {
    fontSize: 12,
    color: '#6E7177',
  },
  textareaWrapper: {
    gap: 4,
  },
});

export default useStyles;
