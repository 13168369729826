import React from 'react';
import { createUseStyles } from 'react-jss';
import bookIcon from './bookIcon.svg';

interface InfoBoxProps {
  withReminderCopy?: boolean;
}

const useStyles = createUseStyles({
  container: {
    background: '#F7F7F7',
    padding: '16px',
    display: 'flex',
    gap: '20px',
  },
  description: {
    margin: 0,
  },
});

const InfoBox = ({ withReminderCopy }: InfoBoxProps): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <img src={bookIcon} alt="book icon" />
      <p className={styles.description}>
        {withReminderCopy ? 'As a reminder: you' : 'You'} can report any
        suspected violations to{' '}
        <a href="mailto:community@a.team">community@a.team</a> or anonymously{' '}
        <a
          href="https://forms.gle/FuMbGGfoUWQGtGDd7"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        . Everything will be kept strictly confidential.
      </p>
    </div>
  );
};

export default InfoBox;
