export enum RoleOptimizationRequestReason {
  CompanyDescriptionLacking = 'companyDescriptionLacking',
  HourlyRateNeedsClarification = 'hourlyRateNeedsClarification',
  MissionDescriptionLacking = 'missionDescriptionLacking',
  AvailabilityNeedsClarification = 'availabilityNeedsClarification',
  RoleDescriptionLacking = 'roleDescriptionLacking',
  VideoMissing = 'videoMissing',
  SkillsNeedClarification = 'skillsNeedClarification',
  VideoNotWorking = 'videoNotWorking',
  LocationRequirementsNeedClarification = 'locationRequirementsNeedClarification',
}

export interface RoleOptimizationRequest {
  id: string;
  roleId: string;
  reasons: RoleOptimizationRequestReason[];
  details?: string;
  createdAt: Date;
  createdBy: string;
}

export type CreateRoleOptimizationRequestData = Pick<
  RoleOptimizationRequest,
  'roleId' | 'reasons' | 'details'
>;

export const RoleOptimizationRequestReasonLabels: Record<
  RoleOptimizationRequestReason,
  string
> = {
  [RoleOptimizationRequestReason.CompanyDescriptionLacking]:
    'Company description lacking',
  [RoleOptimizationRequestReason.HourlyRateNeedsClarification]:
    'Hourly rate needs clarification',
  [RoleOptimizationRequestReason.MissionDescriptionLacking]:
    'Mission description lacking',
  [RoleOptimizationRequestReason.AvailabilityNeedsClarification]:
    'Availability needs clarification',
  [RoleOptimizationRequestReason.RoleDescriptionLacking]:
    'Role description lacking',
  [RoleOptimizationRequestReason.VideoMissing]: 'Video missing',
  [RoleOptimizationRequestReason.SkillsNeedClarification]:
    'Skills needs clarification',
  [RoleOptimizationRequestReason.VideoNotWorking]: 'Video not working',
  [RoleOptimizationRequestReason.LocationRequirementsNeedClarification]:
    'Location requirements needs clarification',
};
