import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
    '& ol li::marker': {
      fontWeight: 600,
    },
    '& li': {
      listStyle: 'initial',
    },
  },
});

const Honesty = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <h4>Honesty</h4>
      <p>
        <strong>Time tracking:</strong> Unless you’ve explicitly been told
        otherwise, companies will always expect timesheets that reflect the
        hours you’ve spent actually working each day — not an approximation,
        average daily number, or even the estimated hours you agreed to work
        ahead of time. This makes sense given that A.Team builders tend to work
        flexible hours and charge relatively high rates. So:
      </p>

      <ol type="1">
        <li>
          <div>
            <p>
              <strong>Zero tolerance for over-billing</strong>
            </p>
            <p>
              Billing accurately is a critical factor for growing your career at
              A.Team and ensuring companies can trust us. Accordingly, we have a
              zero-tolerance policy for inaccurate time sheets and deliberate
              over-billing will result in immediate removal from our network at
              the very least.
            </p>
          </div>
        </li>
        <li>
          <div>
            <p>
              <strong>Keep track of your hours as you work</strong>
            </p>
            <p>
              Keep track of your hours as you work. Take notes throughout the
              day or use time tracking software. Do not wait until the end of
              the day (or worse, end of the week) to estimate the time you spent
              on different tasks — this actually leads to overlooking hidden
              tasks!
            </p>
          </div>
        </li>
        <li>
          <div>
            <p>
              <strong>Understand how much detail each company wants</strong>
            </p>
            <p>
              Understand how much detail each company wants. Expectations about
              the amount of text you provide on timesheets may vary according to
              companies (e.g. “Slack integration” vs. “tested initial
              notification triggers for Slack API in staging”). You should
              discuss expectations each time you start a new mission.
            </p>
            <ul>
              <li>
                As a default, provide at least one or two sentences summarizing
                what you worked on each day and complete your timesheet at least
                once a week.
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div>
            <p>
              <strong>
                “Hours billed” includes only the hours you’ve worked each day
              </strong>
            </p>
            <p>
              Time spent leaving your desk to take breaks, eat meals, etc.
              likely isn’t billable. Ultimately you’ll have to exercise your own
              judgment over what can be billed (e.g. a break partly spent
              thinking about the project), but try to be reasonable — working as
              a contractor is not the same as full-time, for better or worse!
            </p>
          </div>
        </li>
        <li>
          <div>
            <p>
              <strong>Billing is a part of the work</strong>
            </p>
            <p>
              We know from experience that billing is tedious. But it’s a
              central part of the work to be done, and should be treated as a
              mission-related task, not an afterthought. The best builders in
              our network dedicate at least a few minutes each day to filling
              out timesheets.
            </p>
          </div>
        </li>
      </ol>
    </div>
  );
};

export default Honesty;
