import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { AdminVettingProcess } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { theme } from '@a_team/ui-components';
import useToggle from '@src/hooks/useToggle';
import { Colors, FontSizes, Spacing } from '@ateams/components';
import { Card } from '@src/views/VettingFeedbackForm/components/card';
import {
  Size,
  Text,
} from '@src/views/VettingFeedbackForm/components/typography';
import { Button } from '@src/views/VettingFeedbackForm/components/button';
import { EmailIcon } from './email-icon';
import {
  EvaluationInviteModal,
  OnEvaluationFollowupInviteSubmit,
  OnEvaluationInviteSubmit,
} from '@src/components/Modal/EvaluationInviteModal';

export interface ComposeEmailBoxProps {
  rejected?: boolean;
  vettingProcess: AdminVettingProcess;
  onEvaluationInvite: OnEvaluationFollowupInviteSubmit;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.colors.Hannibal[100],
    borderColor: '#DAB5FF',
    padding: Spacing.medium,
  },
  textOnTheLeftContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Spacing.small,
  },
  title: {},
  emailIcon: {
    marginRight: Spacing.small,
  },
  subTitle: {
    color: '#818388',
  },
  button: {
    width: '108px',
    height: '32px',
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: FontSizes.small,
    background: Colors.secondaryDark,
  },
  buttonDisabled: {
    background: Colors.secondaryLight,
  },
});

export const ComposeEmailBox: React.FC<ComposeEmailBoxProps> = (props) => {
  const { rejected, className } = props;
  const styles = useStyles();
  const [evaluationInviteModalOpen, toggleEvaluationInviteModalOpen] =
    useToggle();

  const onEvaluationInvite: OnEvaluationInviteSubmit = async (...args) => {
    props.onEvaluationInvite(props.vettingProcess.id, ...args);
    toggleEvaluationInviteModalOpen();
  };

  return (
    <Card className={cx(styles.container, className)}>
      {evaluationInviteModalOpen && (
        <EvaluationInviteModal
          open
          vettingProcess={props.vettingProcess}
          onClose={toggleEvaluationInviteModalOpen}
          onSubmit={onEvaluationInvite}
        />
      )}

      <div className={styles.textOnTheLeftContainer}>
        <div className={styles.titleContainer}>
          <EmailIcon className={styles.emailIcon} />
          <Text className={styles.title}>Send email</Text>
        </div>
        <Text size={Size.Small} className={styles.subTitle}>
          Send invitation email or follow-up with a builder
        </Text>
      </div>

      <Button
        onClick={toggleEvaluationInviteModalOpen}
        className={
          rejected ? cx(styles.button, styles.buttonDisabled) : styles.button
        }
        disabled={rejected}
        title={
          rejected
            ? 'You need to uncheck rejected in order to compose email'
            : undefined
        }
      >
        Compose email
      </Button>
    </Card>
  );
};
