import cx from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Skeleton from 'react-loading-skeleton';

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    color: '#000000',
    display: 'flex',
    gap: 16,
    padding: 16,
    borderBottom: '1px solid #DADADC',
  },
  title: {
    fontWeight: 500,
  },
  desc: {
    color: '#818388',
    fontSize: 14,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  btn: {
    fontWeight: 500,
    fontSize: 14,
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    outline: 'none',
  },
  ctaBtn: {
    color: '#6D00D7',
  },
  markAsReadBtn: {
    color: '#818388',
  },
  btnsContainer: {
    display: 'flex',
    gap: 16,
  },
});

const NotificationSkeleton = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div>
        <Skeleton circle={true} width={40} height={40} />
      </div>
      <div className={styles.wrapper}>
        <div>
          <div className={styles.title}>
            <Skeleton width={130} height={20} />
          </div>
          <div className={styles.desc}>
            <Skeleton width={260} height={20} />
          </div>
        </div>
        <div className={styles.btnsContainer}>
          <button className={cx(styles.btn, styles.ctaBtn)}>
            <Skeleton width={100} height={20} />
          </button>
          <button className={cx(styles.btn, styles.markAsReadBtn)}>
            <Skeleton width={120} height={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationSkeleton;
