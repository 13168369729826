import {
  BasicConnectionObjectV2,
  ConnectionStatus,
} from '@a_team/models/dist/ConnectionObject';
import { Button } from '@ateams/components';
import React from 'react';

interface ExperienceConnectionButtonsProps {
  className: string;
  onClick(): void;
  loading: boolean;
  disabled?: boolean;
  existingConnection?: BasicConnectionObjectV2;
}

const ExperienceConnectionButtons = ({
  className,
  onClick,
  loading,
  disabled,
  existingConnection,
}: ExperienceConnectionButtonsProps): JSX.Element | null => {
  if (!existingConnection) {
    return (
      <Button
        disabled={disabled}
        onClick={onClick}
        size="small"
        className={className}
        loading={loading}
        color="secondaryDark"
        data-testing-id="connect-work-connection-button"
      >
        Add Connection
      </Button>
    );
  }

  if (
    existingConnection.status === ConnectionStatus.Pending &&
    existingConnection.canApprove
  ) {
    return (
      <Button
        disabled={disabled}
        onClick={onClick}
        size="small"
        className={className}
        loading={loading}
        color="secondaryDark"
        data-testing-id="accept-connection-request-with-working-experience"
      >
        Accept
      </Button>
    );
  }

  if (
    existingConnection.status === ConnectionStatus.Active ||
    (ConnectionStatus.Pending && !existingConnection.canApprove)
  ) {
    return (
      <Button
        disabled={disabled}
        onClick={onClick}
        size="small"
        className={className}
        loading={loading}
        color="secondaryDark"
        data-testing-id="update-work-connection-button"
      >
        Update
      </Button>
    );
  }

  return null;
};

export default ExperienceConnectionButtons;
