import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import {
  SubmitVettingFeedbackFormPayload,
  VettingFeedbackFormDefaults,
} from '@ateams/api/dist/endpoints/vetting-process-feedback';
import {
  AdminVettingProcess,
  OptOutReasonTypes,
  VettingType,
  optOutReasonTypeToLabelMap,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { useStores } from '@src/stores';
import { RootLocation, VettingDashboardLocation } from '@src/locations';
import useToggle from '@src/hooks/useToggle';
import MainLayout from '@src/layouts/Main';
import { Colors, Spacing } from '@ateams/components';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import {
  OnVettingFeedbackFormSave,
  OnVettingFeedbackFormSubmit,
  VettingFeedbackForm,
} from './form';
import { apiVettingProcess } from '@ateams/api';
import { getStatusFromVettingProcess } from '../VettingDashboard/vetting-table/columns/columns/status';
import { VettingProcessPublicStatus } from '@a_team/models/dist/vetting-processes/status';
import { OverwriteProfileScoresModal } from './modals/overwrite-profile-scores-modal';
import { ApplyScoresModal } from './modals/apply-scores-modal';
import {
  OnDeleteAdditionalMaterial,
  OnUploadAdditionalMaterial,
} from './form/builder-info/additional-materials-files';

export const useStyles = createUseStyles({
  content: {
    background: Colors.backgroundWhite,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100%',
    padding: 0,
    paddingTop: Spacing.xLarge,
    paddingBottom: Spacing.xLarge,
  },
});

/**
 * Figma design {@link https://www.figma.com/file/fiIpGWCIUv1WVZWVn7wwP6/Admin-side%3A-Vetting?node-id=202%3A9&t=CAkbL4Px1pLAxwAL-0}
 */
const VettingFeedbackFormView = () => {
  const styles = useStyles();
  const { auth, vettingProcesses } = useStores();
  const { vpid } = useParams<{
    vpid: string;
  }>();
  const history = useHistory();
  const location = useLocation();
  const [defaultValues, setDefaultValues] =
    useState<VettingFeedbackFormDefaults>();
  const [formOpenedAt, setFormOpenedAt] = useState(new Date());
  const [scoresChangesModalOpen, toggleScoresChangesModal] = useToggle();
  const [adminVettingProcess, setAdminVettingProcess] =
    useState<AdminVettingProcess>();
  const [loading, setLoading] = useLoadingState();

  const getFormDefaults = async () => {
    const defaults = await vettingProcesses.getFeedbackFormDefaults(vpid);

    setDefaultValues(defaults);
  };

  useEffect(() => {
    setLoading(getFormDefaults(), 'Feedback Form Loaded');
  }, []);

  const hasRatingConflict = useMemo(() => {
    if (!adminVettingProcess) {
      return null;
    }

    const publicStatus = getStatusFromVettingProcess(adminVettingProcess);

    return publicStatus === VettingProcessPublicStatus.RatingConflict;
  }, [adminVettingProcess]);

  if ((!auth.isAdmin && !auth.isVetter) || !auth.uid) {
    return <Redirect to={RootLocation} />;
  }

  const handleScoreChangesModalAfterSubmit = async () => {
    try {
      if (
        defaultValues?.general.vettingProcess.vettingType ===
        VettingType.SelectionTeam
      ) {
        onClose();
        return;
      }

      const adminVettingProcess =
        await apiVettingProcess.fetchAdminVettingProcessById(auth, vpid);

      setAdminVettingProcess(adminVettingProcess);
      toggleScoresChangesModal();
    } catch (err) {
      console.error(
        'Something went terribly wrong when trying to display the scores changes modal 😱',
        err,
      );
    }
  };

  /**
   * We want to go to the previous page
   * Since the form is accesible through a URL, if there is no page back we want to just go
   * to the Selection Team page
   */
  const onClose = () => {
    /**
     * "location.key" ? Nice going Javascript 😅
     * {@link https://stackoverflow.com/a/70532858}
     * */
    const canGoBack = typeof location.key !== 'undefined';

    if (canGoBack) {
      history.goBack();
    } else {
      history.push(VettingDashboardLocation);
    }
  };

  const onSubmit: OnVettingFeedbackFormSubmit = async (payload) => {
    return new Promise((resolve, reject) => {
      setLoading(
        (async () => {
          try {
            const newDefaults = await vettingProcesses[
              auth.isAdmin
                ? 'adminSubmitVettingFeedbackForm'
                : 'vetterSubmitVettingFeedbackForm'
            ](vpid, payload);

            setDefaultValues(newDefaults);
            setFormOpenedAt(new Date());
            await handleScoreChangesModalAfterSubmit();
            resolve(newDefaults);
          } catch (err) {
            console.error('Failed to submit feedback form 💩', err);

            reject(err);
            throw err;
          }
        })(),
        'Feedback Form Submitted',
      );
    });
  };

  const onBuilderDidNotShowUpToCallSubmit = () => {
    setLoading(
      (async () => {
        try {
          await vettingProcesses[
            !auth.isAdmin ||
            auth.uid === defaultValues?.general.vettingProcess.vetter?.id
              ? 'vetterMarkBuilderAsOptedOut'
              : 'adminMarkBuilderAsOptedOutOnVetterBehalf'
          ]({
            vettingProcessId: vpid,
            reason:
              optOutReasonTypeToLabelMap[
                OptOutReasonTypes.DidNotShowUpToTheCall
              ],
          });

          onClose();
        } catch (err) {
          console.error('Failed to submit feedback form 💩', err);

          throw err;
        }
      })(),
    );
  };

  const saveFeedbackFormDraft = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolve: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reject: any,
    payload: SubmitVettingFeedbackFormPayload,
  ) => {
    (async () => {
      try {
        const newDefaults = await vettingProcesses[
          auth.isAdmin
            ? 'adminSaveFeedbackFormDraft'
            : 'vetterSaveFeedbackFormDraft'
        ](vpid, payload);

        setDefaultValues(newDefaults);
        setFormOpenedAt(new Date());
        resolve(newDefaults);
      } catch (err) {
        console.error('Failed to save feedback form 💩', err);

        reject(err);
        throw err;
      }
    })();
  };

  const onSave: OnVettingFeedbackFormSave = async (payload, skipLoading) => {
    if (skipLoading) {
      return new Promise((resolve, reject) => {
        saveFeedbackFormDraft(resolve, reject, payload);
      });
    } else {
      return new Promise((resolve, reject) => {
        setLoading(saveFeedbackFormDraft(resolve, reject, payload));
      });
    }
  };

  const onUploadAdditionalMaterial: OnUploadAdditionalMaterial = async (
    file,
  ) => {
    try {
      return await vettingProcesses[
        auth.isAdmin
          ? 'adminUploadFeedbackAdditionalMaterial'
          : 'vetterUploadFeedbackAdditionalMaterial'
      ](vpid, file);
    } catch (err) {
      console.error('Failed to upload additional material 💩', err);

      throw err;
    }
  };

  const onDeleteAdditionalMaterial: OnDeleteAdditionalMaterial = async (
    additionalMaterialId,
  ) => {
    try {
      if (
        defaultValues?.answers.additionalMaterialsFiles?.find(({ id }) => {
          return id === additionalMaterialId;
        })
      ) {
        return;
      }

      return await vettingProcesses[
        auth.isAdmin
          ? 'adminDeleteFeedbackAdditionalMaterial'
          : 'vetterDeleteFeedbackAdditionalMaterial'
      ](vpid, additionalMaterialId);
    } catch (err) {
      console.error('Failed to delete additional material 💩', err);

      throw err;
    }
  };

  const handleAcceptOrRejectScores = (shouldAcceptNewScores: boolean) => {
    setLoading(
      (async () => {
        await vettingProcesses.acceptFeedbackForm(vpid, {
          shouldAcceptNewScores,
        });

        toggleScoresChangesModal();
        onClose();
      })(),
    );
  };

  const handleAcceptScores = () => handleAcceptOrRejectScores(true);
  const handleRejectScores = () => handleAcceptOrRejectScores(false);

  return (
    <MainLayout title="Vetting Feedback Form" contentClassName={styles.content}>
      {defaultValues && (
        <VettingFeedbackForm
          key={formOpenedAt.getTime()}
          defaultValues={defaultValues}
          vettingProcessId={vpid}
          currentUserProfilePictureURL={auth.user?.profilePictureURL || ''}
          isCurrentUserAdmin={auth.isAdmin}
          currentUserId={auth.uid}
          onClose={onClose}
          onSubmit={onSubmit}
          onBuilderDidNotShowUpToCallSubmit={onBuilderDidNotShowUpToCallSubmit}
          onSave={onSave}
          onUploadAdditionalMaterial={onUploadAdditionalMaterial}
          onDeleteAdditionalMaterial={onDeleteAdditionalMaterial}
        />
      )}

      {adminVettingProcess &&
        adminVettingProcess.feedback &&
        adminVettingProcess.feedback.feedbackAccepted === undefined &&
        loading !== true &&
        (hasRatingConflict ? (
          <OverwriteProfileScoresModal
            open={scoresChangesModalOpen}
            onClose={toggleScoresChangesModal}
            currentScores={adminVettingProcess.user.scores}
            newScores={adminVettingProcess.feedback.answers.scores}
            onDiscard={handleRejectScores}
            onOverwrite={handleAcceptScores}
          />
        ) : (
          <ApplyScoresModal
            open={scoresChangesModalOpen}
            onClose={toggleScoresChangesModal}
            onSave={hasRatingConflict ? undefined : handleAcceptScores}
            onDiscard={handleRejectScores}
          />
        ))}

      <LoadingIndicator loading={loading} />
    </MainLayout>
  );
};

export default observer(VettingFeedbackFormView);
