import {
  BasicExperienceObject,
  ExperienceSource,
  JobExperienceData,
  ProjectExperienceData,
} from '@a_team/models/dist/ExperienceObject';
import { AnswerScore } from '@src/components/AnswerScore';
import { TalentCategoryId } from '@a_team/models/dist/TalentCategories';
import { Company } from '@a_team/models/dist/Company';
import {
  MissionRoleRequiredSkill,
  MissionRoleSkill,
} from '@a_team/models/dist/MissionRole';

export type CTA = {
  label: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: () => Promise<any>;
  confirmation?: string;
  tooltipErrors?: string[];
};

export enum ProfileViewMode {
  View = 'View',
  Edit = 'Edit',
  Admin = 'Admin',
}

export interface TagList {
  id: string;
  title: string;
  items: string[];
  shown: boolean;
  placeholder: string;
  onChange: (items: string[]) => void;
}

export type ExistingJob = BasicExperienceObject & JobExperienceData;
export type NewJob = {
  _id: string;
  type: ExistingJob['type'];
  name: ExistingJob['name'];
  jobRole?: ExistingJob['jobRole'];
  members: ExistingJob['members'];
  companyV2Id?: string | null;
  companyName?: string | null;
  websiteURL?: string | null;
  industries?: ExistingJob['industries'];
  applicableTalentSkills?: ExistingJob['applicableTalentSkills'];
  applicableTalentSpecialization?: ExistingJob['applicableTalentSpecialization'];
  startDate?: ExistingJob['startDate'];
  endDate?: ExistingJob['endDate'];
  isSuggested?: ExistingJob['isSuggested'];
  hasZeroToOneExperience?: ExistingProject['hasZeroToOneExperience'];
  hasManagedPeople?: ExistingProject['hasManagedPeople'];
  numberOfPeopleManaged?: ExistingProject['numberOfPeopleManaged'];
  description?: ExistingJob['description'];
  descriptionHTML?: ExistingJob['descriptionHTML'];
  logoURL?: ExistingJob['logoURL'];
};
export type LocalJobs = Array<ExistingJob | NewJob>;

export type ExistingProject = BasicExperienceObject & ProjectExperienceData;
export type NewProject = {
  _id: string;
  type: ExistingProject['type'];
  title: ExistingProject['title'];
  description: ExistingProject['description'];
  jobRole?: ExistingProject['jobRole'];
  jobRoleId?: string;
  /** @deprecated */
  industry?: ExistingProject['industry'];
  industries?: ExistingProject['industries'];
  companyData?: Company;
  companyV2Id?: string | null;
  companyName?: string | null;
  websiteURL?: string | null;
  members: ExistingProject['members'];
  imageURL?: ExistingProject['imageURL'];
  imagesUrls?: ExistingProject['imagesUrls'];
  projectUrl?: ExistingProject['projectUrl'];
  logoURL?: ExistingProject['logoURL'];
  placeholder?: boolean;
  startDate?: ExistingProject['startDate'];
  endDate?: ExistingProject['endDate'];
  skills?: ExistingProject['skills'];
  isSuggested?: ExistingProject['isSuggested'];
  metrics?: ExistingProject['metrics'];
  descriptionHTML?: ExistingProject['descriptionHTML'];
  source?: ExperienceSource;
  isMissingMinimumRequirements?: ExistingProject['isMissingMinimumRequirements'];
  hasZeroToOneExperience?: ExistingProject['hasZeroToOneExperience'];
  hasManagedPeople?: ExistingProject['hasManagedPeople'];
  numberOfPeopleManaged?: ExistingProject['numberOfPeopleManaged'];
  relatedJobId?: ExistingProject['relatedJobId'];
};
export type LocalProjects = Array<ExistingProject | NewProject>;

export type Website = { _id: string; url: string };
export type LocalWebsites = Website[];

export interface Expertise {
  id: string;
  rating?: AnswerScore;
  name: string;
  featured?: boolean;
  categoryIds?: TalentCategoryId[];
  verifiedSkill?: boolean;
  confidence?: number;
}

export interface SkillRequirement extends MissionRoleRequiredSkill {
  inRequiredRating?: boolean;
  missing?: boolean;
}

export interface SkillPreference extends MissionRoleSkill {
  missing?: boolean;
}

export type LocalSkills = {
  main: Expertise[];
  additional: Expertise[];
};
export type LocalRoles = Expertise[];

export type ApplicationDetails = { mid: string; rid: string; aid?: string };
