import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { Colors, Spacing } from '@ateams/components';
import MainLayout from '@src/layouts/Main';
import SectionHeading from '@src/components/SectionHeading';

// <MainLayout /> accepts no className styling
const LAYOUT_STYLES: CSSProperties = {
  padding: 0,
  background: Colors.backgroundWhite,
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const useStyles = createUseStyles({
  navBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 ${Spacing.large}px`,
    height: 90,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginBottom: 0,
  },
});

interface Props {
  headerRightContent: ReactNode;
}

const TargeterLayout: React.FC<Props> = ({
  children,
  headerRightContent,
}): ReactElement => {
  const styles = useStyles();
  return (
    <MainLayout title="Targeter" style={LAYOUT_STYLES}>
      <div className={styles.navBar}>
        <div className={styles.titleContainer}>
          <SectionHeading className={styles.title} isFirst>
            Elastic Targeter
          </SectionHeading>
        </div>
        <div style={{ display: 'flex' }}>{headerRightContent}</div>
      </div>
      {children}
    </MainLayout>
  );
};

export default TargeterLayout;
