import { ServiceAuth, ServiceEndpoint } from './utils';
import TeamObject, {
  TeamId,
  TeamMemberObject,
  TeamMemberRole,
} from '@a_team/models/dist/TeamObject';
import { UserId } from '@a_team/models/dist/UserObject';
import { UserSpecializationAssignmentData } from './User';
import { DateISOString } from '@a_team/models/dist/misc';

export const BasePath = '/teams';

export interface BasicTeamMemberData {
  teamRole: TeamMemberRole;
  role?: UserSpecializationAssignmentData;
}

export interface TeamMemberData extends BasicTeamMemberData {
  userId: UserId;
  invitedAt?: DateISOString;
  acceptedAt?: DateISOString;
  declinedAt?: DateISOString;
}

export interface NewTeamMemberUserData extends BasicTeamMemberData {
  fullName: string;
  email: string;
  linkedInUsername?: string;
}

export interface TeamData {
  members: NewTeamMemberUserData[];
}

export interface TeamUpResponseData {
  accepted: boolean;
}

// exported functions

export default class TeamsEndpoint extends ServiceEndpoint {
  public createTeam(auth: ServiceAuth, data: TeamData): Promise<TeamObject> {
    return this.fetch(auth, BasePath, null, 'post', data);
  }

  public updateTeam(
    auth: ServiceAuth,
    teamId: TeamId,
    data: TeamData,
  ): Promise<TeamObject> {
    return this.fetch(auth, BasePath + `/${teamId}`, null, 'put', data);
  }

  public getTeamByTeamId(
    auth: ServiceAuth,
    teamId: TeamId,
  ): Promise<TeamObject> {
    return this.fetch(auth, BasePath + `/${teamId}`);
  }

  public deleteTeam(auth: ServiceAuth, teamId: TeamId): Promise<null> {
    return this.fetch(auth, BasePath + `/${teamId}`, null, 'delete');
  }

  public respondToTeamUpRequest(
    auth: ServiceAuth,
    teamId: TeamId,
    data: TeamUpResponseData,
  ): Promise<TeamMemberObject> {
    return this.fetch(
      auth,
      BasePath + `/${teamId}/team-up-response`,
      null,
      'put',
      data,
    );
  }
}
