import { useQueryPendingUserRecommendations } from '@src/rq/teamUp';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import Banner from './Banner';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';
import TeamUpPendingInvitesModal, {
  hasAppliedToSameRole,
} from '@src/components/Modal/TeamUpPendingInvitesModal';
import useToggle from '@src/hooks/useToggle';
import { RecommendationStatus } from '@a_team/models/dist/RecommendationObject';

interface TeamUpBannerProps {
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    width: '100%',
    margin: '24px 0',
  },
});

const TeamUpBanner = ({ className }: TeamUpBannerProps) => {
  const styles = useStyles();
  const { missions } = useStores();
  const [open, setOpen] = useToggle(false);
  const { currentMission } = missions;
  const { isLoading, data } = useQueryPendingUserRecommendations({
    missionId: currentMission?.mid || '',
    enabled: !!currentMission?.mid,
  });

  const { data: pendingUsersData } = useQueryPendingUserRecommendations({
    missionId: currentMission?.mid || '',
  });

  const hasPendingInvites =
    pendingUsersData?.some(
      (recommendation) =>
        recommendation.status === RecommendationStatus.Pending &&
        !hasAppliedToSameRole(recommendation),
    ) || false;

  const hasApplied = useMemo(() => {
    const openAndActiveRoles =
      currentMission?.rolesSorted.filter(
        (role) =>
          role.status === MissionRoleStatus.Active ||
          role.status === MissionRoleStatus.ScheduledToEnd ||
          role.status === MissionRoleStatus.Open,
      ) || [];

    return openAndActiveRoles.some(
      (role) => role.application && !role.application.withdrawn,
    );
  }, [currentMission]);

  if (isLoading || !data) {
    return null;
  }

  const requestorUsers = data.map((item) => item.requestor);

  if (requestorUsers.length === 0 || (!hasPendingInvites && !open)) {
    return null;
  }

  const getTitle = () => {
    let title;
    if (requestorUsers.length === 1) {
      title = `${requestorUsers[0].firstName} invited you to team up on this mission.`;
    } else if (requestorUsers.length === 2) {
      title = `${requestorUsers[0].firstName} and ${requestorUsers[1].firstName} invited you to team up on this mission.`;
    } else {
      title = `${requestorUsers[0].firstName} and ${
        requestorUsers.length - 1
      } others invited you to team up on this mission.`;
    }

    return title;
  };

  const getDesc = () => {
    if (hasApplied) {
      return `Team up with builders to increase your chances of being selected.`;
    }

    return `Apply to the mission and build your team. Companies value teams for their effective collaboration.`;
  };
  const title = getTitle();
  const description = getDesc();

  return (
    <div className={cx(styles.container, className)}>
      <Banner
        ctaLabel={hasApplied ? 'View invites' : undefined}
        ctaOnClick={() => {
          setOpen();
        }}
        title={title}
        users={requestorUsers}
        description={description}
      />
      {currentMission?.mid && (
        <TeamUpPendingInvitesModal
          onClose={() => setOpen(false)}
          missionId={currentMission.mid}
          isOpen={open}
        />
      )}
    </div>
  );
};

export default observer(TeamUpBanner);
