import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './keys';
import { apiCustomUserTags } from '@src/logic/services/endpoints';
import { CustomUserTagObject } from '@a_team/models/dist/CustomUserTagObject';
import { useStores } from '@src/stores';

export const useQueryCustomTags = ({
  query,
  onSuccess,
  enabled = true,
}: {
  query?: string;
  onSuccess?: (data: CustomUserTagObject[]) => void;
  enabled?: boolean;
} = {}) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.customTags.list(query || ''),
    queryFn: async () => {
      const items = await apiCustomUserTags.findCustomUserTags(auth);

      return items;
    },
    onSuccess: onSuccess,
    enabled: enabled,
  });
};
