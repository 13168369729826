import { env } from './env';

export const ENVIRONMENT: string = env.NODE_ENV || 'development';
export const IS_PROD_BUILD: boolean = ENVIRONMENT === 'production';

export const IS_SANDBOX: boolean = env.SANDBOX === 'true';
export const IS_PROD: boolean = IS_PROD_BUILD && !IS_SANDBOX;

export const DEFAULT_ROLE_MARKUP = 0.25; // Used as a default for calculations between client and builder rates

export { env };
