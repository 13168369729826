import React, { ReactElement, useState } from 'react';
import Modal, { ModalBasicProps } from '..';
import { Colors, TextColors, Breakpoints } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import useLoadingState, { LoadingState } from '@src/hooks/useLoadingState';
import { useScreenClass } from 'react-grid-system';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { SendTimesheet, SendTimesheetProps } from './send-timesheet-modal';

type Props = Omit<ModalBasicProps, 'onClose'> &
  Omit<SendTimesheetProps, 'onSubmit'> & {
    firstName: string | undefined;
    onClose: (isInactive?: boolean) => void;
    onCancel: VoidFunction;
    loading?: LoadingState;
  };

export const useStyles = createUseStyles({
  wrapper: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  leftSide: {
    padding: '1.6em',
    background: 'linear-gradient(199.15deg, #9A00E3 7.03%, #8A00E3 100%)',
    color: Colors.backgroundWhite,
    height: 200,
    position: 'relative',
    overflow: 'hidden',
    paddingTop: 80,
  },
  rightSide: {
    padding: '1.6em',
    overflowY: 'overlay',
  },
  moneyIcon: {
    color: TextColors.backgroundWhite,
    opacity: 0.12,
    fontSize: '13em',
    position: 'absolute',
    bottom: 0,
    left: '-10px',
    display: 'none',
  },
  usersIcon: {
    color: TextColors.backgroundWhite,
    opacity: 0.12,
    fontSize: '23em',
    position: 'absolute',
    bottom: 0,
    left: '-40px',
    display: 'none',
  },
  input: {
    height: 40,
    marginBottom: 10,
    width: '100%',
    border: '1px solid ' + Colors.regular,
    borderRadius: 5,
    padding: '1em 0.5em',
  },
  invalid: {
    borderColor: Colors.danger,
  },
  link: {
    color: Colors.primary,
    cursor: 'pointer',
  },
  button: {
    display: 'flex',
    marginTop: '16px',
    justifyContent: 'flex-end',
  },
  buttons: {
    justifyContent: 'space-evenly',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    wrapper: {
      height: 'auto',
      flexDirection: 'row',
    },
    leftSide: {
      width: 140,
      height: 'auto',
      paddingTop: 24,
    },
    rightSide: {
      width: 420,
      maxHeight: 306,
    },
    moneyIcon: {
      display: 'block',
    },
    usersIcon: {
      display: 'block',
    },
  },
});

export default function ConfirmTimesheetModal(props: Props): ReactElement {
  const {
    startDate,
    endDate,
    onClose,
    onCancel,
    totalAmount,
    firstName,
    isEmptyTimesheet,
    ...left
  } = props;
  const screenClass = useScreenClass();
  const isMobile = screenClass === 'xs' || screenClass === 'sm';
  const [loading] = useLoadingState();
  const [userInactive, setUserInactive] = useState<undefined | boolean>(
    undefined,
  );

  const onSendTimesheetClick = (isInactive: boolean) => {
    setUserInactive(isInactive);
    onClose(userInactive);
  };

  return (
    <>
      <Modal
        onClose={onCancel}
        style={{
          padding: 0,
          width: isMobile ? '100%' : 560,
          border: 'none',
          maxHeight: '100vh',
          ...(isMobile && {
            borderRadius: 0,
          }),
        }}
        {...left}
      >
        <SendTimesheet
          loading={props.loading}
          totalAmount={totalAmount}
          startDate={startDate}
          endDate={endDate}
          isEmptyTimesheet={isEmptyTimesheet}
          onSubmit={onSendTimesheetClick}
        />
      </Modal>
      <LoadingIndicator loading={loading} />
    </>
  );
}
