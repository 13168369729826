import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { VettingProcessFeedbackTechnicalBackground } from '@a_team/models/dist/vetting-processes/feedback';
import { Spacing } from '@ateams/components';
import { InputLabel } from '../../components/typography';
import { CheckboxValueTag } from '../../components/value-tag';
import { TextInput } from '../../components/text-input';
import { Text } from '../../components/typography';

const INPUT_LABEL = 'Does this builder have a technical background?';

type TechnicalBackgroundValue =
  | VettingProcessFeedbackTechnicalBackground
  | undefined;

export type OnTechnicalBackgroundChange = (
  technicalBackground: TechnicalBackgroundValue,
) => void;

export interface TechnicalBackgroundInputProps {
  value: TechnicalBackgroundValue;
  onChange?: OnTechnicalBackgroundChange;
  isReadOnly?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  marginLeft: {
    marginLeft: Spacing.medium,
  },
  booleanCheckboxValueTag: {
    marginRight: Spacing.small,
    '&:last-child': {
      marginRight: 0,
    },
  },
  inputsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const TechnicalBackgroundInput: React.FC<
  TechnicalBackgroundInputProps
> = (props) => {
  const { isReadOnly, value } = props;
  const styles = useStyles();

  const onHasTechnicalBackgroundChange = (isChecked: boolean) => {
    let newTechnicalBackground: TechnicalBackgroundValue;
    if (value?.hasTechnicalBackground === isChecked) {
      newTechnicalBackground = undefined;
    } else {
      newTechnicalBackground = {
        ...value,
        hasTechnicalBackground: isChecked,
        rolesDescription: undefined,
      };
    }

    props.onChange?.(newTechnicalBackground);
  };

  const onTechnicalBackgroundRolesDescriptionChange = (
    rolesDescription: string,
  ) => {
    if (value?.hasTechnicalBackground) {
      const newTechnicalBackground = {
        ...value,
        rolesDescription,
      };

      props.onChange?.(newTechnicalBackground);
    }
  };

  if (isReadOnly && !value) {
    return (
      <div className={props.className}>
        <InputLabel className={styles.marginBottom}>{INPUT_LABEL}</InputLabel>
        <Text isReadOnly />
      </div>
    );
  }

  return (
    <div className={props.className}>
      <InputLabel className={styles.marginBottom}>{INPUT_LABEL}</InputLabel>
      <div className={cx(styles.inputsRow, styles.marginBottom)}>
        <CheckboxValueTag
          label={'Yes'}
          checked={value?.hasTechnicalBackground === true}
          className={styles.booleanCheckboxValueTag}
          onClick={() => onHasTechnicalBackgroundChange(true)}
          isReadOnly={isReadOnly}
        />
        <CheckboxValueTag
          label={'No'}
          checked={value?.hasTechnicalBackground === false}
          className={styles.booleanCheckboxValueTag}
          onClick={() => onHasTechnicalBackgroundChange(false)}
          isReadOnly={isReadOnly}
        />
      </div>
      {value?.hasTechnicalBackground === true && (
        <div className={styles.marginLeft}>
          <TextInput
            value={value.rolesDescription}
            onChange={(e) =>
              onTechnicalBackgroundRolesDescriptionChange(e.target.value)
            }
            placeholder={'Where? What roles?'}
            isReadOnly={isReadOnly}
          />
        </div>
      )}
    </div>
  );
};
