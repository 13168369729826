import React, { ReactNode, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import AdminInvoicesDashboardLayout from '@src/layouts/InvoicesDashboard';
import { loadInvoicesToBeIssued } from '@src/url-loaders/loadInvoicesToBeIssued';
import { useStores } from '@src/stores';
import Table, { TableRow } from '@src/components/Table';
import TextButton from '@src/components/TextButton';
import { MissionAdminTimeTrackingLocation } from '@src/locations';
import { Button as CallToActionButton, LoadMoreLink } from '@ateams/components';
import ClosePaymentCycleModal from '@src/components/Modal/ClosePaymentCycleModal';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { MissionId } from '@a_team/models/dist/MissionObject';
import {
  MissionPaymentCycleId,
  MissionPaymentCycleStatus,
} from '@a_team/models/dist/MissionPaymentCycleObject';
import { TimesheetsStatusDisplay } from '@src/views/InvoicesAdminDashboard/endedPaymentCycles/timesheetStatusDisplay';
import TimesheetRemindersModal from '@src/components/Modal/TimesheetRemindersModal';
import { UserId } from '@a_team/models/dist/UserObject';
import { InvoiceGenerateModal } from '@src/components/Modal/InvoiceGenerateModal';

export const invoicesToBeIssuedLoader = loadInvoicesToBeIssued;

const EndedPaymentCyclesView = () => {
  const { missions, users, auth } = useStores();
  const {
    endedPaymentCycles,
    loadCurrent,
    currentMission,
    adminNextTokens,
    fetchEndedPaymentCycles,
  } = missions;
  const [closeCycleModalOpen, setCloseCycleModalOpen] = useState(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [remindersModalOpen, setRemindersModalOpen] = useState(false);
  const [selectedPaymentCycleId, setSelectedPaymentCycleId] =
    useState<MissionPaymentCycleId>();
  const [loading, setLoading] = useLoadingState();

  const selectedPaymentCycle = useMemo(() => {
    return endedPaymentCycles.find(
      (cycle) => cycle.yid === selectedPaymentCycleId,
    );
  }, [endedPaymentCycles, selectedPaymentCycleId]);

  const headers: ReactNode[] = useMemo(() => {
    return [
      'Mission',
      'Total hours',
      'Total payments',
      'Period',
      'Timesheets status',
      '',
    ];
  }, []);

  const onCloseCycle = (mid: MissionId, yid: MissionPaymentCycleId) => {
    setLoading(
      loadCurrent(mid, true).then(() => {
        setSelectedPaymentCycleId(yid);
        setCloseCycleModalOpen(true);
      }),
      null,
    );
  };

  const onCreateInvoice = (mid: MissionId, yid: MissionPaymentCycleId) => {
    setLoading(
      loadCurrent(mid, true).then(() => {
        setSelectedPaymentCycleId(yid);
        setInvoiceModalOpen(true);
      }),
      null,
    );
  };

  const onSendReminders = (mid: MissionId, yid: MissionPaymentCycleId) => {
    setLoading(
      loadCurrent(mid, true).then(() => {
        setSelectedPaymentCycleId(yid);
        setRemindersModalOpen(true);
      }),
      null,
    );
  };

  const onSendRemindersSubmit = (ids: UserId[]) => {
    currentMission &&
      selectedPaymentCycleId &&
      setLoading(
        currentMission
          .sendTimesheetReminders(ids, selectedPaymentCycleId)
          .then(() => setRemindersModalOpen(false)),
      );
  };

  const onFetchMoreClick = () => {
    setLoading(fetchEndedPaymentCycles());
  };

  return (
    <AdminInvoicesDashboardLayout title={'Invoices to be issued'}>
      {currentMission && selectedPaymentCycle && (
        <TimesheetRemindersModal
          mission={currentMission}
          paymentCycle={selectedPaymentCycle}
          open={remindersModalOpen}
          onClose={() => setRemindersModalOpen(false)}
          onGetUser={(username) => users.getFullUser(username, auth)}
          onSubmit={onSendRemindersSubmit}
        />
      )}
      {currentMission && (
        <>
          <ClosePaymentCycleModal
            mission={currentMission}
            defaultSelectedCycleId={selectedPaymentCycleId}
            generateInvoice
            onSubmit={(yid, request) =>
              setLoading(currentMission?.closePaymentCycle(yid, request))
            }
            open={closeCycleModalOpen}
            onClose={() => setCloseCycleModalOpen(false)}
          />
          <InvoiceGenerateModal
            onConfirm={(onlyCurrent) => {
              setInvoiceModalOpen(false);

              currentMission.selectedPaymentCycle &&
                setLoading(
                  onlyCurrent
                    ? currentMission.generatePaymentCycleInvoice(
                        currentMission.selectedPaymentCycle.yid,
                      )
                    : currentMission.generateInvoices(),
                );
            }}
            open={invoiceModalOpen}
            onClose={() => setInvoiceModalOpen(false)}
          />
        </>
      )}
      {endedPaymentCycles.length > 0 ? (
        <Table headers={headers}>
          {endedPaymentCycles.map((cycle) => (
            <TableRow
              key={cycle.yid}
              cells={[
                <TextButton
                  style={{ maxWidth: 250 }}
                  highlight
                  disabled={!cycle.mid}
                  onClick={() =>
                    cycle.mid
                      ? window.open(MissionAdminTimeTrackingLocation(cycle.mid))
                      : undefined
                  }
                >
                  {cycle.missionTitle}
                </TextButton>,
                cycle.formattedTotalHours,
                cycle.formattedTotalPayments,
                `${cycle.formattedStartDateFull} - ${cycle.formattedEndDateFull}`,
                <TimesheetsStatusDisplay
                  paymentCycle={cycle}
                  onSendReminder={() =>
                    cycle.mid
                      ? onSendReminders(cycle.mid, cycle.yid)
                      : undefined
                  }
                />,
                !cycle.invoice &&
                  (cycle.status === MissionPaymentCycleStatus.Open ? (
                    <CallToActionButton
                      width={'auto'}
                      size={'small'}
                      style={{ height: 'auto' }}
                      onClick={() =>
                        cycle.mid
                          ? onCloseCycle(cycle.mid, cycle.yid)
                          : undefined
                      }
                    >
                      Close Cycle
                    </CallToActionButton>
                  ) : (
                    <CallToActionButton
                      width={'auto'}
                      size={'small'}
                      outlined
                      style={{ height: 'auto' }}
                      onClick={() =>
                        cycle.mid
                          ? onCreateInvoice(cycle.mid, cycle.yid)
                          : undefined
                      }
                    >
                      Create Invoice
                    </CallToActionButton>
                  )),
              ]}
            />
          ))}
        </Table>
      ) : (
        <p>No invoices to issue</p>
      )}
      {adminNextTokens.endedCycles && (
        <LoadMoreLink onLoadMore={onFetchMoreClick} buttonText={'Load more'} />
      )}
      <LoadingIndicator loading={loading} />
    </AdminInvoicesDashboardLayout>
  );
};

export default observer(EndedPaymentCyclesView);
