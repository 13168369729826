import React, { ReactElement } from 'react';
import { Icon, IconType, Tag, TagList } from '@ateams/components';
import { SkillPreference, SkillRequirement } from '@src/stores/Profile/models';
import TextButton from '@src/components/TextButton';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { sortBy } from 'lodash';

interface Props {
  title: string;
  skills: SkillRequirement[] | SkillPreference[];
  required?: boolean;
  guidanceMessage?: ReactElement;
  onClick?: () => void;
  disabled?: boolean;
}

const useStyles = createUseStyles({
  tag: {
    fontSize: 15,
    padding: 10,
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  icon: {
    marginRight: 8,
    transform: 'rotate(-90deg)',
  },
});

export const SkillList = (props: Props): ReactElement => {
  const styles = useStyles();
  const { required, skills, guidanceMessage, onClick, disabled } = props;

  const sortedSkills: Array<SkillRequirement | SkillPreference> = sortBy(
    skills,
    'name',
  );

  return (
    <div style={{ marginTop: 24 }}>
      <div>
        {required ? 'The company requires' : 'The company would prefer'} the
        following {skills.length === 1 ? 'skill' : `${skills.length} skills`}
      </div>
      {guidanceMessage}
      <TagList style={{ marginTop: 16 }}>
        {sortedSkills.map((skill) => {
          const notInRating =
            !skill.missing &&
            'inRequiredRating' in skill &&
            skill.inRequiredRating === false;
          const missingRequirement = skill.missing || notInRating;
          return (
            <Tag
              key={skill.talentSkillId}
              square
              className={cx(styles.tag, { [styles.disabled]: !!disabled })}
              color={missingRequirement ? 'backgroundLight' : undefined}
            >
              {notInRating && (
                <Icon
                  type={IconType.ArrowBack}
                  size={'exact'}
                  className={styles.icon}
                />
              )}
              <span
                style={{
                  ...(skill.missing && {
                    color: '#818388',
                  }),
                }}
              >
                {skill.talentSkillName}
              </span>
              {missingRequirement && (
                <TextButton
                  onClick={onClick}
                  style={{ marginLeft: 8 }}
                  color={'secondaryDark'}
                >
                  {skill.missing ? 'Add' : 'Update'}
                </TextButton>
              )}
            </Tag>
          );
        })}
      </TagList>
    </div>
  );
};
