import React, { ChangeEvent, ReactElement } from 'react';
import { ClientRoleQuestion } from '@a_team/models/dist/MissionRole';
import Section from '@src/views/Profile/Main/partials/Section';
import cx from 'classnames';
import CommonStyles from '@src/views/Profile/Main/partials/styles';
import { observer } from 'mobx-react';
import TextAreaInput from '@src/components/Inputs/TextAreaInput';
import { Tag } from '@ateams/components';
import { GuidanceTooltip } from '@src/views/Profile/Main/partials/Section/GuidanceTooltip/GuidanceTooltip';
import { TooltipContent } from '@src/views/Profile/Main/partials/Section/TooltipContent';
import { useStores } from '@src/stores';
import Assistant from './Assistant';
import { CustomQuestionReply } from '@a_team/models/dist/MissionApplicationObject';

interface Props {
  companyName: string;
  answers?: Map<ClientRoleQuestion['qid'], string | undefined>;
  questions: ClientRoleQuestion[];
  onChange(qid: ClientRoleQuestion['qid'], text: string): void;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  isApplicationEdited?: ClientRoleQuestion['qid'][];
  userSkills: string[];
  projectsCount: number;
  missionId: string;
  roleId: string;
  guidanceMessage?: ReactElement;
  questionsWithErrors: CustomQuestionReply['qid'][];
}

const ClientQuestions = (props: Props): ReactElement => {
  const { auth } = useStores();
  const {
    questions,
    companyName,
    answers,
    readOnly,
    onChange,
    isApplicationEdited,
    disabled,
    guidanceMessage,
    questionsWithErrors,
  } = props;
  const commonStyles = CommonStyles();

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement>,
    qid: string,
  ): void => {
    onChange(qid, e.target.value.trimStart());
  };

  return (
    <Section
      readonly={readOnly}
      type={'large'}
      title={`Question${questions.length > 1 ? 's' : ''} from ${companyName}`}
      dataTestingId={'mission-application-custom-question-section'}
      guidanceTooltip={
        !readOnly ? (
          <GuidanceTooltip label={TooltipContent.clientQuestion.label}>
            {TooltipContent.clientQuestion.component}
          </GuidanceTooltip>
        ) : undefined
      }
    >
      {questions.map((question) => {
        const hasErrors = questionsWithErrors.includes(question.qid);
        const answerText = answers?.get(question.qid);
        return (
          <div key={question.qid}>
            {readOnly ? (
              <div className={cx(commonStyles.readingOnlyText, 'admin')}>
                <strong>{question.text}</strong>
                <p>{answerText}</p>
              </div>
            ) : (
              <>
                <label
                  style={{
                    display: 'block',
                    fontSize: 16,
                    fontWeight: 500,
                    marginTop: 24,
                    marginBottom: 8,
                  }}
                >
                  {question.text}

                  {isApplicationEdited?.includes(question.qid) ? (
                    <Tag className={commonStyles.tagEdited}>Edited</Tag>
                  ) : null}
                </label>
                {guidanceMessage}
                <TextAreaInput
                  disabled={disabled}
                  value={answerText || ''}
                  onChange={(e) => handleChange(e, question.qid)}
                  placeholder="Thorough answers to company questions let you showcase strengths in areas they value most"
                  data-testing-id={
                    'mission-application-client-question-text-input'
                  }
                  maxLength={3000}
                  showCharsLeft
                  autoResize
                  minLength={20}
                  error={hasErrors}
                  errorColor="primary"
                  wrapperClassname={commonStyles.textareaWrapper}
                  className={cx(commonStyles.textArea, {
                    [commonStyles.error]: hasErrors,
                  })}
                  footer={
                    auth.witCompanyQuestionRecommendation && !readOnly ? (
                      <Assistant
                        missionId={props.missionId}
                        roleId={props.roleId}
                        userSkills={props.userSkills}
                        projectsCount={props.projectsCount}
                        questionId={question.qid}
                      />
                    ) : undefined
                  }
                />
              </>
            )}
          </div>
        );
      })}
    </Section>
  );
};

export default observer(ClientQuestions);
