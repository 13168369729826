import { apiUser } from '@ateams/api';
import { REACT_APP_CALCOM_URL } from '@src/config';
import { profileKeys } from '@src/rq/keys/profile';
import AuthStore from '@src/stores/Auth';
import { QueryClient } from '@tanstack/react-query';

const DELAY_POLL_INTERVAL = 1000; // poll per second

export const goToAddACalendarClick = (
  auth: AuthStore,
  rqClient: QueryClient,
  initialPath: string,
) => {
  openCalComLinkWithAutoLogin(
    '/apps/google-calendar',
    auth.withNewCalendarLoginFlow,
  );

  pollCalendarSetup(auth, rqClient, initialPath);
};

export const openCalComLinkWithAutoLogin = (
  path: string,
  clearSession?: boolean,
) => {
  const loginUrl = clearSession
    ? 'auth/auto-login?callback'
    : 'auth/login?autoLogin=true&callbackUrl';
  window.open(
    `${REACT_APP_CALCOM_URL}/${loginUrl}=${encodeURIComponent(path)}`,
    '_blank',
  );
};

export const goToAddGoogleCalendarClick = (
  auth: AuthStore,
  rqClient: QueryClient,
  initialPath: string,
) => {
  openCalComLinkWithAutoLogin(
    '/apps/google-calendar',
    auth.withNewCalendarLoginFlow,
  );

  pollCalendarSetup(auth, rqClient, initialPath);
};

export const goToAddAppleCalendarClick = (
  auth: AuthStore,
  rqClient: QueryClient,
  initialPath: string,
) => {
  openCalComLinkWithAutoLogin(
    '/apps/apple-calendar',
    auth.withNewCalendarLoginFlow,
  );

  pollCalendarSetup(auth, rqClient, initialPath);
};

export const goToAddOutlookCalendarClick = (
  auth: AuthStore,
  rqClient: QueryClient,
  initialPath: string,
) => {
  openCalComLinkWithAutoLogin(
    '/apps/office365-calendar',
    auth.withNewCalendarLoginFlow,
  );

  pollCalendarSetup(auth, rqClient, initialPath);
};

export const goToManageCalendarClick = (withNewCalendarLoginFlow?: boolean) => {
  openCalComLinkWithAutoLogin(
    '/apps/installed/calendar',
    withNewCalendarLoginFlow,
  );
};

export const goToWorkingHoursClick = (
  scheduleId?: number,
  withNewCalendarLoginFlow?: boolean,
) => {
  openCalComLinkWithAutoLogin(
    `/availability/${scheduleId}`,
    withNewCalendarLoginFlow,
  );
};

const pollCalendarSetup = async (
  auth: AuthStore,
  rqClient: QueryClient,
  initialPath: string,
): Promise<void> => {
  let timer: ReturnType<typeof setTimeout> | undefined = undefined;
  const currentPath = window.location.pathname;

  try {
    const calendar = await apiUser.getUserCalendar(auth);

    if (!calendar.calcom?.selectedCalendar && initialPath === currentPath) {
      timer = setTimeout(
        () => pollCalendarSetup(auth, rqClient, initialPath),
        DELAY_POLL_INTERVAL,
      );
    } else {
      if (timer) {
        clearTimeout(timer);
        await apiUser.setCalendarConnected(auth);
      }
      rqClient.setQueryData(profileKeys.calendar(auth.uid ?? ''), calendar);
      // somce we have multiple ways to connect, not possible to dismiss dialog everywhere, so we just refresh the page to have calendar fully loaded
      window.location.reload();
    }
  } catch (err) {
    // do nothing
  }
};

export const getSupport = () => window.open('mailto:support@a.team');
