import {
  BasicConnectionObjectV2,
  ConnectionType,
} from '@a_team/models/dist/ConnectionObject';

export const getConnection = (
  connectionType: ConnectionType,
  connections: BasicConnectionObjectV2[] | [],
) => {
  const foundConnection = connections.find(
    (connection: BasicConnectionObjectV2) => connection.type === connectionType,
  );
  if (!foundConnection) {
    return undefined;
  }

  return foundConnection;
};
