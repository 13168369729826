import React from 'react';
import Intro from './Intro';
import Availability from './Availability';
import DeliverExceptionalResults from './DeliverExceptionalResults';
import Honesty from './Honesty';
import MeetDeadlines from './MeetDeadlines';
import OverCommunicate from './OverCommunicate';
import Plagiarism from './Plagiarism';
import Professionalism from './Professionalism';
import ResponsibleCommunity from './ResponsibleCommunity';

const MissionCodeOfConduct = (): JSX.Element => {
  return (
    <div>
      <h2>Mission Code of Conduct </h2>
      <Intro />
      <Professionalism />
      <OverCommunicate />
      <MeetDeadlines />
      <DeliverExceptionalResults />
      <ResponsibleCommunity />
      <Honesty />
      <Availability />
      <Plagiarism />
    </div>
  );
};

export default MissionCodeOfConduct;
