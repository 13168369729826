import { Breakpoints, Colors, Icon, IconType } from '@ateams/components';
import cx from 'classnames';
import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import UpdateButton from './UpdateButton';

interface RequirementProps {
  children: ReactNode;
  iconType: IconType;
  name: string;
  dataTestingId: string;
  meetsRequirement: boolean;
  feedback?: JSX.Element | string;
  onClick?(): void;
}

export const useStyles = createUseStyles({
  requirementWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '15px',
    gap: '10px',
  },
  horizontalDivider: {
    background: '#EFEFF0',
    height: '1px',
    margin: '24px 0',
  },
  label: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '10px',
  },
  feedbackWrapper: {
    marginTop: 6,
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    gap: 6,
  },
  feedback: {
    display: 'flex',
    alignItems: 'center',
  },
  successMessage: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    color: Colors.successDark,
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    color: Colors.primary,
  },
  smallIcon: {
    marginRight: 4,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    requirementWrapper: {
      flexDirection: 'row',
      marginBottom: 20,
    },
    horizontalDivider: {
      display: 'none',
    },
    label: {
      minWidth: 200,
    },
    feedbackWrapper: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: 12,
    },
  },
});

function Requirement({
  children,
  iconType,
  name,
  dataTestingId,
  meetsRequirement,
  feedback,
  onClick,
}: RequirementProps): JSX.Element {
  const styles = useStyles();

  return (
    <div data-testing-id={dataTestingId}>
      <div className={styles.requirementWrapper}>
        <div>
          <div className={styles.label}>
            <Icon type={iconType} size={'exact'} className={styles.icon} />
            {name}
          </div>
        </div>
        <div>
          <div className={styles.requirement}>{children}</div>
          {!!feedback && (
            <>
              {meetsRequirement ? (
                <div
                  className={styles.feedbackWrapper}
                  data-testing-id="meets-requirement"
                >
                  <div className={cx(styles.feedback, styles.successMessage)}>
                    <Icon
                      type={IconType.Check}
                      size={'xsmall'}
                      className={styles.smallIcon}
                    />
                    {feedback}
                  </div>
                </div>
              ) : (
                <div className={styles.feedbackWrapper}>
                  <div className={cx(styles.feedback, styles.errorMessage)}>
                    <Icon
                      type={IconType.CloseOrange}
                      size={'xsmall'}
                      className={styles.smallIcon}
                    />
                    {feedback}
                  </div>
                  {!meetsRequirement && onClick && (
                    <div>
                      <UpdateButton label="Update" onClick={onClick} />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.horizontalDivider} />
    </div>
  );
}

export default Requirement;
