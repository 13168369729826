import { Button, Icon } from '@a_team/ui-components';
import { Breakpoints, Colors, TextColors } from '@ateams/components';
import CompanyLogo from '@src/components/CompanyLogo';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useCommonStyles } from './commonStyles';
import { DateISOString } from '@a_team/models/dist/misc';

interface RequestProps {
  companyImageUrl?: string | null;
  companyName: string;
  clientInterviewCreatedAt?: DateISOString;
  date: string;
  time: string;
  description: string;
  clientMinHourlyRateForBuilder?: number;
  clientMaxHourlyRateForBuilder?: number;
  onDecline: () => void;
  onNext: () => void;
}

export const useRequestStyles = createUseStyles({
  content: {
    padding: 24,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    marginTop: 40,
    padding: 16,
    border: '1px solid #DADADC',
    borderRadius: 8,
    whiteSpace: 'pre-wrap',
  },
  descriptionText: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 8,
  },
  btnsContainer: {
    display: 'flex',
    gap: 16,
    justifyContent: 'space-between',
    borderTop: '1px solid #DADADC',
    padding: 16,

    '& button': {
      minWidth: 120,
      height: 40,
    },
  },
  timeLeft: {
    marginTop: 24,
    padding: '2px 10px',
    background: Colors.backgroundLight,
    color: TextColors.regular,
    borderRadius: 8,
  },
  timeLeftContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    content: {
      padding: 40,
    },
  },
});

const Request = ({
  companyName,
  companyImageUrl,
  date,
  time,
  description,
  clientInterviewCreatedAt,
  onDecline,
  onNext,
  clientMinHourlyRateForBuilder,
  clientMaxHourlyRateForBuilder,
}: RequestProps): JSX.Element => {
  const styles = useRequestStyles();
  const commonStyles = useCommonStyles();
  const title = `${companyName} invited you to an interview`;

  const hoursLeft = useMemo(() => {
    if (clientInterviewCreatedAt === undefined) {
      return null;
    }
    const createdAt = new Date(clientInterviewCreatedAt);
    const now = new Date();
    const timeLeftMs =
      createdAt.getTime() + 24 * 60 * 60 * 1000 - now.getTime();

    if (timeLeftMs <= 0) {
      return 'The response time has expired.';
    }

    const hours = Math.floor(timeLeftMs / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeftMs % (1000 * 60 * 60)) / (1000 * 60));

    let timeLeftDescription;
    if (hours > 0) {
      timeLeftDescription = `${hours} hour${
        hours > 1 ? 's' : ''
      } left to respond`;
    } else if (minutes > 0) {
      timeLeftDescription = `${minutes} minute${
        minutes > 1 ? 's' : ''
      } left to respond`;
    } else {
      timeLeftDescription = 'Less than a minute left to respond';
    }

    return timeLeftDescription;
  }, [clientInterviewCreatedAt]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <CompanyLogo logoUrl={companyImageUrl} size={72} />
        {hoursLeft && (
          <div className={styles.timeLeftContainer}>
            <span className={styles.timeLeft}>{hoursLeft}</span>
          </div>
        )}
        <h4 className={commonStyles.title}>{title}</h4>
        <div className={commonStyles.date}>
          <div>{date}</div>
          <span className={commonStyles.line} />
          <div>{time}</div>
        </div>
        {clientMinHourlyRateForBuilder !== undefined &&
          clientMaxHourlyRateForBuilder !== undefined && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: Colors.regularDark,
                marginTop: 16,
                gap: 10,
              }}
            >
              <Icon name="money" style={{ color: Colors.regularDark }} />{' '}
              Company’s budget — ${clientMinHourlyRateForBuilder} to $
              {clientMaxHourlyRateForBuilder} per hour
            </div>
          )}
        <div className={styles.description}>
          <div className={styles.descriptionText}>Description</div>
          {description}
        </div>
      </div>
      <div className={styles.btnsContainer}>
        <Button onClick={onDecline} variant="secondary">
          Decline
        </Button>
        <Button onClick={onNext}>Next</Button>
      </div>
    </div>
  );
};

export default Request;
