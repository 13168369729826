import React from 'react';

export const UploadIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g id="Upload">
        <path
          id="Vector_02"
          opacity="0.6"
          d="M8 10V2M8 2L5 5M8 2L11 5"
          stroke="#7000E3"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_01"
          d="M14 10C13.1266 12.3304 10.7701 14 8 14C5.22994 14 2.87337 12.3304 2 10"
          stroke="#7000E3"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
