import React from 'react';
import { createUseStyles } from 'react-jss';
import avatarGrayIcon from './avatar-gray-icon.svg';
import avatarIcon from './avatar-icon.svg';

interface TeamStatusProps {
  hasAtLeastOneOpenRoleLookingForMoreApplicants: boolean;
}

const useStyles = createUseStyles({
  container: {
    color: '#222222',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    justifyContent: 'center',
  },
  icon: {
    height: 32,
    width: 32,
  },
});

const TeamStatus = ({
  hasAtLeastOneOpenRoleLookingForMoreApplicants,
}: TeamStatusProps): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {hasAtLeastOneOpenRoleLookingForMoreApplicants ? (
        <>
          <img
            className={styles.icon}
            src={avatarIcon}
            alt="Actively looking for builders"
          />

          <span>Actively looking for builders</span>
        </>
      ) : (
        <>
          <img
            className={styles.icon}
            src={avatarGrayIcon}
            alt="Builders proposed to company"
          />

          <span style={{ color: '#62646A' }}>Builders proposed to company</span>
        </>
      )}
    </div>
  );
};

export default TeamStatus;
