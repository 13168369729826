import { createUseStyles } from 'react-jss';
import { Classes } from 'jss';
import { ColorName, Colors, TextColors } from '@ateams/components';

export interface StyleProps {
  error?: boolean;
  errorColor?: ColorName;
  autoResize?: boolean;
}

const useStyles: (data: StyleProps) => Classes = createUseStyles<StyleProps>({
  wrapper: ({ autoResize }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: autoResize ? 16 : 0,
  }),
  textarea: ({ autoResize }) => ({
    width: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    color: TextColors.regular,
    textDecorationThickness: '1px',
    textDecorationStyle: 'dashed',
    textDecorationLine: 'underline',
    textDecorationColor: Colors.regular,
    resize: autoResize ? 'none' : 'vertical',
    '&::placeholder': {
      textDecorationThickness: '1px',
      textDecorationStyle: 'dashed',
      textDecorationLine: 'underline',
      textDecorationColor: Colors.regular,
    },
  }),
  error: ({ error }) => ({
    '&::placeholder': {
      color: error ? Colors.danger : TextColors.placeholderRegular,
    },
  }),
  readonly: {
    backgroundColor: 'transparent !important',
    borderColor: 'transparent !important',
    textDecorationColor: 'transparent !important',
    textDecoration: 'none',
    '&::placeholder': {
      textDecorationColor: 'transparent !important',
    },
    '&:disabled': {
      color: `${TextColors.regular} !important`,
      '-webkit-text-fill-color': TextColors.regular,
      opacity: 1,
    },
  },
  label: {
    color: TextColors.regularLight,
    fontSize: 14,
  },
  charsLeft: {
    alignSelf: 'flex-end',
    fontSize: '12px',
  },
});

export default useStyles;
