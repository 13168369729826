import {
  BorderRadius,
  CollapsibleContainer,
  Colors,
  Select,
  Spacing,
  TextColors,
  ToggleSwitch,
} from '@ateams/components';
import TextAreaInput from '@src/components/Inputs/TextAreaInput';
import TextInput from '@src/components/Inputs/TextInput/TextInput';
import TextButton from '@src/components/TextButton';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { ValidAudienceFormData } from './AudienceForm';

const useStyles = createUseStyles({
  header: {
    background: Colors.backgroundLight,
    padding: `${Spacing.medium}px ${Spacing.medium}px`,
    marginBottom: Spacing.small,
    borderRadius: BorderRadius.default,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 0,
  },
  toggleSwitchContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  toggleSwitch: {
    marginLeft: '8px',
  },
  propertyLabel: {
    color: TextColors.regularLight,
    marginBottom: Spacing.small,
  },
  propertyValue: {
    marginTop: 0,
    marginBottom: 0,
  },
  audienceSize: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  limitInput: {
    width: '45px',
  },
  limitInputText: {
    marginTop: Spacing.medium,
  },
  separator: {
    marginTop: Spacing.small,
  },
});

interface Props {
  title: string;
  totalCount?: number;
  selectedCount?: number;
  onClickOnSelectAll?: () => void;
  formData?: ValidAudienceFormData;
  onFormDataChange?: (data: Partial<ValidAudienceFormData>) => void;
  showMissionFields?: boolean;
  missionRoleOptions?: { label: string; value: string }[];
  automatedReachoutPreset?: string;
}

export const SidebarItem: React.FC<Props> = (props) => {
  const styles = useStyles();

  return (
    <CollapsibleContainer
      title={props.title}
      openDefault={true}
      arrowPosition="left"
      headerClassName={styles.header}
      right={
        <div className={styles.toggleSwitchContainer}>
          {!props.formData?.disabled ? 'Included' : ''}
          <ToggleSwitch
            data-testing-id="targeter-audience-toggle"
            checked={!props.formData?.disabled}
            onChange={() => {
              props.onFormDataChange &&
                props.onFormDataChange({ disabled: !props.formData?.disabled });
            }}
            size="xsmall"
            checkedBackgroundColor="secondary"
            className={styles.toggleSwitch}
          ></ToggleSwitch>
        </div>
      }
    >
      <div data-testing-id="targeter-audience-form">
        <p className={styles.propertyLabel}>Audience size</p>
        <div className={styles.audienceSize}>
          <p className={styles.propertyValue}>
            {props.totalCount !== undefined
              ? `${props.selectedCount} of ${props.totalCount} selected`
              : 'loading...'}
          </p>
          {props.totalCount !== undefined && !props.automatedReachoutPreset ? (
            <TextButton
              color="primary"
              onClick={() =>
                props.onClickOnSelectAll && props.onClickOnSelectAll()
              }
            >
              Select All
            </TextButton>
          ) : null}
        </div>
        {props.automatedReachoutPreset && (
          <>
            <div className={styles.separator}></div>
            <p>
              Generated by Automated Reachout.{' '}
              <strong>Preset: {props.automatedReachoutPreset}.</strong>
            </p>
          </>
        )}

        <div className={styles.separator}></div>
        {props.totalCount !== undefined && (
          <p className={styles.limitInputText}>
            Email only top{' '}
            <TextInput
              name="limit"
              autoComplete="off"
              type="text"
              variant="dashed"
              value={props.formData?.limit || ''}
              className={styles.limitInput}
              onChange={(e) => {
                const limit = parseInt(e.target.value);
                if (limit < 0 || limit > (props.totalCount || 0)) {
                  return;
                }
                props.onFormDataChange &&
                  props.onFormDataChange({
                    limit,
                  });
              }}
            />{' '}
            builders
          </p>
        )}
        {props.showMissionFields && (
          <>
            <div className={styles.separator}></div>
            <Select
              label={'Mission Role'}
              options={props.missionRoleOptions || []}
              value={
                props.missionRoleOptions?.find(
                  (option) => props.formData?.missionRoleId === option.value,
                ) || null
              }
              onChange={(option) =>
                props.onFormDataChange &&
                option?.value &&
                props.onFormDataChange({
                  missionRoleId: option.value,
                })
              }
              isOptionWithDescription
              menuPortalTarget={document.body}
            />
            <TextAreaInput
              label={'Mission Role Description'}
              value={props.formData?.missionRoleDescription}
              onChange={(event) =>
                props.onFormDataChange &&
                props.onFormDataChange({
                  missionRoleDescription: event.target.value,
                })
              }
            />
            <div className={styles.separator}></div>
          </>
        )}
      </div>
    </CollapsibleContainer>
  );
};
