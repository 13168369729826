import { Modal, Button } from '@a_team/ui-components';
import {
  BorderColors,
  Breakpoints,
  Colors,
  Icon,
  IconType,
  TextColors,
} from '@ateams/components';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import FileDropArea from '@src/components/FileDropArea';
import useLoadingState from '@src/hooks/useLoadingState';
import * as uploadcareApi from '@src/logic/uploadcare';
import { FileRejection, FileWithPath } from 'react-dropzone';
import {
  useEnhanceProfileWithPolling,
  useGenerateProjectsSuggestionFromWebsiteOrPdf,
} from '@src/rq/profileSuggestions';
import SubmissionCompleted from './common/SubmissionCompleted';
import { isValidUrl, withHttps } from '@src/helpers/urls';

interface CreteProjectWithAIModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const useStyles = createUseStyles({
  modal: {
    '& > div': {
      padding: 0,
    },
  },
  container: {
    margin: 24,
  },
  centeredContainer: {
    maxWidth: 450,
    margin: '0 auto',
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '26px',
    margin: 0,
    textAlign: 'center',
  },
  description: {
    fontSize: 15,
    marginTop: 8,
    color: Colors.regularDark,
    textAlign: 'center',
    maxWidth: 400,
  },
  inputsContainer: {
    marginTop: 24,
  },
  label: {
    display: 'block',
    fontSize: 14,
  },
  input: {
    display: 'block',
    marginTop: 8,
    width: '100%',
    padding: 16,
    borderRadius: 8,
    border: `1px solid ${BorderColors.lighter}`,
    '&:focus': {
      borderColor: Colors.secondaryDark,
    },
  },
  uploader: {
    padding: 16,
    borderRadius: 8,
    border: `1px solid ${BorderColors.lighter}`,
    marginTop: 8,
  },
  uploaderDisabled: {
    opacity: 0.4,
  },
  upload: {
    textAlign: 'center',
  },
  uploading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pdfInputContainer: {
    marginTop: 16,
  },
  uploadPDFIcon: {
    marginRight: 8,
  },
  uploadingIcon: {
    fontSize: 16,
  },
  remove: {
    marginRight: 16,
    fontSize: 14,
    fontWeight: 500,
    color: Colors.dark,
  },
  successIcon: {
    fontSize: 15,
  },
  info: {
    marginTop: 8,
    color: TextColors.regularDark,
  },
  footer: {
    display: 'flex',
    gap: 16,
    marginTop: 40,
    justifyContent: 'center',
  },
  button: {
    padding: '12px 12px !important',
    position: 'relative',
    fontSize: 14,
    flexGrow: 1,
    width: '100% !important',
  },
  errorText: {
    color: '#F63041',
    fontSize: 14,
    marginTop: 8,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '90vw !important',
      maxWidth: '680px !important',
      padding: 0,
      borderRadius: '8px !important',
      '& > div': {
        padding: 0,
        overflow: 'hidden !important',
      },
    },
    container: {
      margin: 40,
    },
    button: {
      maxWidth: '180px !important',
    },
  },
});

const CreteProjectWithAIModal = ({
  isOpen,
  onClose,
}: CreteProjectWithAIModalProps): JSX.Element => {
  const styles = useStyles();
  const [uploading, setUploading] = useLoadingState(null);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { initiateSuggestionsPolling } = useEnhanceProfileWithPolling();
  const [websiteUrlError, setWebsiteUrlError] = useState('');
  const { mutate: generateProjectsSuggestion, isLoading: isSubmitting } =
    useGenerateProjectsSuggestionFromWebsiteOrPdf({
      onSuccess: () => {
        setIsFormSubmitted(true);
        initiateSuggestionsPolling();
      },
      onError: (error) => {
        setWebsiteUrlError(error.message);
      },
    });

  const handleFileSelected = async (
    files: Array<FileWithPath>,
    rejectedFiles: Array<FileRejection>,
  ) => {
    try {
      setUploading(null);

      if (rejectedFiles.length > 0) {
        return setUploading(new Error(rejectedFiles[0].errors[0].message));
      }

      if (!files.length) {
        return;
      }

      const [file] = files;

      setUploading(true);

      const downloadUrl = await uploadcareApi.uploadFile(
        new File([file], file.name, { type: file.type }),
      );

      setPdfUrl(downloadUrl);

      setUploading('Resume Uploaded');
      setUploading(null);
    } catch (err) {
      setUploading(err as Error);
    }
  };

  const handleSubmit = async () => {
    if (websiteUrl && !isValidUrl(websiteUrl)) {
      setWebsiteUrlError('Please enter a valid URL');
      return;
    }

    await generateProjectsSuggestion({
      websiteUrl,
      pdfUrl,
    });
  };

  const addHttps = (value: string) => {
    return value !== '' ? withHttps(value) : value;
  };

  return (
    <>
      <Modal
        variant="slideUp"
        isOpen={isOpen}
        onClose={onClose}
        className={styles.modal}
        shouldHideGradientStroke={true}
        zIndex={9999}
      >
        <div className={styles.container}>
          <div className={styles.centeredContainer}>
            {isFormSubmitted ? (
              <SubmissionCompleted onClose={onClose} />
            ) : (
              <>
                <h1 className={styles.title}>Import projects with AI</h1>
                <div className={styles.description}>
                  Quickly create projects by importing case studies, briefs,
                  PDFs, or other documentation. We’ll analyze the content and
                  suggest pre-filled projects on your profile.
                </div>
                <div className={styles.inputsContainer}>
                  <div className={styles.inputContainer}>
                    <label className={styles.label}>Project(s) URL</label>
                    <input
                      className={styles.input}
                      placeholder="Enter link"
                      value={websiteUrl}
                      onChange={(e) => {
                        setWebsiteUrl(e.target.value);
                        setWebsiteUrlError('');
                      }}
                      onBlur={(e) => {
                        setWebsiteUrl(addHttps(e.target.value));
                      }}
                      disabled={!!uploading || !!pdfUrl}
                    />
                    {websiteUrlError && (
                      <div className={styles.errorText}>{websiteUrlError}</div>
                    )}
                  </div>

                  <div
                    className={cx(
                      styles.inputContainer,
                      styles.pdfInputContainer,
                    )}
                  >
                    <label className={styles.label}>PDF file</label>
                    {uploading || pdfUrl ? (
                      <div className={cx(styles.uploader, styles.uploading)}>
                        <div>
                          <Icon
                            type={IconType.PaperBlack}
                            size={'small'}
                            className={styles.uploadPDFIcon}
                          />
                          file.pdf
                        </div>
                        {uploading ? (
                          <Icon
                            type={IconType.CircleLoadingViolet}
                            className={styles.uploadingIcon}
                          />
                        ) : (
                          <div>
                            <a
                              href="/"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setPdfUrl('');
                              }}
                              className={styles.remove}
                            >
                              Remove
                            </a>
                            <Icon
                              type={IconType.CheckPurple}
                              className={styles.successIcon}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <FileDropArea
                        accept={['application/pdf']}
                        onFileDrop={handleFileSelected}
                        disabled={!!websiteUrl}
                      >
                        {({ openDialog }) => (
                          <div
                            className={cx([
                              styles.uploader,
                              styles.upload,
                              { [styles.uploaderDisabled]: !!websiteUrl },
                            ])}
                            onClick={(e) => {
                              e.stopPropagation();
                              openDialog();
                            }}
                          >
                            <Icon
                              type={IconType.PaperBlack}
                              size={'small'}
                              className={styles.uploadPDFIcon}
                            />
                            Upload project PDF
                          </div>
                        )}
                      </FileDropArea>
                    )}
                    <div className={styles.info}>
                      PDF file format. Up to 10MB.
                    </div>
                  </div>
                </div>

                <div className={styles.footer}>
                  <Button
                    variant="secondary"
                    className={styles.button}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={styles.button}
                    disabled={(!websiteUrl && !pdfUrl) || !!isSubmitting}
                    loading={!!isSubmitting}
                    onClick={handleSubmit}
                  >
                    <Icon
                      type={IconType.StarsWhite}
                      style={{
                        position: 'absolute',
                        left: 0,
                        top: -5,
                        fontSize: 52,
                      }}
                      size={'large'}
                    />
                    <span style={{ marginLeft: 18 }}>Import project(s)</span>
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default observer(CreteProjectWithAIModal);
