import { SelectOption } from '@ateams/components';
import AF from './countriesFlags/AF.svg';
import AX from './countriesFlags/AX.svg';
import AL from './countriesFlags/AL.svg';
import DZ from './countriesFlags/DZ.svg';
import AS from './countriesFlags/AS.svg';
import AD from './countriesFlags/AD.svg';
import AO from './countriesFlags/AO.svg';
import AI from './countriesFlags/AI.svg';
import AQ from './countriesFlags/AQ.svg';
import AG from './countriesFlags/AG.svg';
import AR from './countriesFlags/AR.svg';
import AM from './countriesFlags/AM.svg';
import AW from './countriesFlags/AW.svg';
import AU from './countriesFlags/AU.svg';
import AT from './countriesFlags/AT.svg';
import AZ from './countriesFlags/AZ.svg';
import BS from './countriesFlags/BS.svg';
import BH from './countriesFlags/BH.svg';
import BD from './countriesFlags/BD.svg';
import BB from './countriesFlags/BB.svg';
import BY from './countriesFlags/BY.svg';
import BE from './countriesFlags/BE.svg';
import BZ from './countriesFlags/BZ.svg';
import BJ from './countriesFlags/BJ.svg';
import BM from './countriesFlags/BM.svg';
import BT from './countriesFlags/BT.svg';
import BO from './countriesFlags/BO.svg';
import BQ from './countriesFlags/BQ.svg';
import BA from './countriesFlags/BA.svg';
import BW from './countriesFlags/BW.svg';
import BV from './countriesFlags/BV.svg';
import BR from './countriesFlags/BR.svg';
import IO from './countriesFlags/IO.svg';
import VG from './countriesFlags/VG.svg';
import BN from './countriesFlags/BN.svg';
import BG from './countriesFlags/BG.svg';
import BF from './countriesFlags/BF.svg';
import BI from './countriesFlags/BI.svg';
import KH from './countriesFlags/KH.svg';
import CM from './countriesFlags/CM.svg';
import CA from './countriesFlags/CA.svg';
import CV from './countriesFlags/CV.svg';
import KY from './countriesFlags/KY.svg';
import CF from './countriesFlags/CF.svg';
import TD from './countriesFlags/TD.svg';
import CL from './countriesFlags/CL.svg';
import CN from './countriesFlags/CN.svg';
import CX from './countriesFlags/CX.svg';
import CC from './countriesFlags/CC.svg';
import CO from './countriesFlags/CO.svg';
import KM from './countriesFlags/KM.svg';
import CK from './countriesFlags/CK.svg';
import CR from './countriesFlags/CR.svg';
import HR from './countriesFlags/HR.svg';
import CU from './countriesFlags/CU.svg';
import CW from './countriesFlags/CW.svg';
import CY from './countriesFlags/CY.svg';
import CZ from './countriesFlags/CZ.svg';
import CD from './countriesFlags/CD.svg';
import DK from './countriesFlags/DK.svg';
import DJ from './countriesFlags/DJ.svg';
import DM from './countriesFlags/DM.svg';
import DO from './countriesFlags/DO.svg';
import TL from './countriesFlags/TL.svg';
import EC from './countriesFlags/EC.svg';
import EG from './countriesFlags/EG.svg';
import SV from './countriesFlags/SV.svg';
import GQ from './countriesFlags/GQ.svg';
import ER from './countriesFlags/ER.svg';
import EE from './countriesFlags/EE.svg';
import ET from './countriesFlags/ET.svg';
import FK from './countriesFlags/FK.svg';
import FO from './countriesFlags/FO.svg';
import FJ from './countriesFlags/FJ.svg';
import FI from './countriesFlags/FI.svg';
import FR from './countriesFlags/FR.svg';
import GF from './countriesFlags/GF.svg';
import PF from './countriesFlags/PF.svg';
import TF from './countriesFlags/TF.svg';
import GA from './countriesFlags/GA.svg';
import GM from './countriesFlags/GM.svg';
import GE from './countriesFlags/GE.svg';
import DE from './countriesFlags/DE.svg';
import GH from './countriesFlags/GH.svg';
import GI from './countriesFlags/GI.svg';
import GR from './countriesFlags/GR.svg';
import GL from './countriesFlags/GL.svg';
import GD from './countriesFlags/GD.svg';
import GP from './countriesFlags/GP.svg';
import GU from './countriesFlags/GU.svg';
import GT from './countriesFlags/GT.svg';
import GG from './countriesFlags/GG.svg';
import GN from './countriesFlags/GN.svg';
import GW from './countriesFlags/GW.svg';
import GY from './countriesFlags/GY.svg';
import HT from './countriesFlags/HT.svg';
import HM from './countriesFlags/HM.svg';
import HN from './countriesFlags/HN.svg';
import HK from './countriesFlags/HK.svg';
import HU from './countriesFlags/HU.svg';
import IS from './countriesFlags/IS.svg';
import IN from './countriesFlags/IN.svg';
import ID from './countriesFlags/ID.svg';
import IR from './countriesFlags/IR.svg';
import IQ from './countriesFlags/IQ.svg';
import IE from './countriesFlags/IE.svg';
import IM from './countriesFlags/IM.svg';
import IL from './countriesFlags/IL.svg';
import IT from './countriesFlags/IT.svg';
import CI from './countriesFlags/CI.svg';
import JM from './countriesFlags/JM.svg';
import JP from './countriesFlags/JP.svg';
import JE from './countriesFlags/JE.svg';
import JO from './countriesFlags/JO.svg';
import KZ from './countriesFlags/KZ.svg';
import KE from './countriesFlags/KE.svg';
import KI from './countriesFlags/KI.svg';
import XK from './countriesFlags/XK.svg';
import KW from './countriesFlags/KW.svg';
import KG from './countriesFlags/KG.svg';
import LA from './countriesFlags/LA.svg';
import LV from './countriesFlags/LV.svg';
import LB from './countriesFlags/LB.svg';
import LS from './countriesFlags/LS.svg';
import LR from './countriesFlags/LR.svg';
import LY from './countriesFlags/LY.svg';
import LI from './countriesFlags/LI.svg';
import LT from './countriesFlags/LT.svg';
import LU from './countriesFlags/LU.svg';
import MO from './countriesFlags/MO.svg';
import MG from './countriesFlags/MG.svg';
import MW from './countriesFlags/MW.svg';
import MY from './countriesFlags/MY.svg';
import MV from './countriesFlags/MV.svg';
import ML from './countriesFlags/ML.svg';
import MT from './countriesFlags/MT.svg';
import MH from './countriesFlags/MH.svg';
import MQ from './countriesFlags/MQ.svg';
import MR from './countriesFlags/MR.svg';
import MU from './countriesFlags/MU.svg';
import YT from './countriesFlags/YT.svg';
import MX from './countriesFlags/MX.svg';
import FM from './countriesFlags/FM.svg';
import MD from './countriesFlags/MD.svg';
import MC from './countriesFlags/MC.svg';
import MN from './countriesFlags/MN.svg';
import ME from './countriesFlags/ME.svg';
import MS from './countriesFlags/MS.svg';
import MA from './countriesFlags/MA.svg';
import MZ from './countriesFlags/MZ.svg';
import MM from './countriesFlags/MM.svg';
import NA from './countriesFlags/NA.svg';
import NR from './countriesFlags/NR.svg';
import NP from './countriesFlags/NP.svg';
import NL from './countriesFlags/NL.svg';
import NC from './countriesFlags/NC.svg';
import NZ from './countriesFlags/NZ.svg';
import NI from './countriesFlags/NI.svg';
import NE from './countriesFlags/NE.svg';
import NG from './countriesFlags/NG.svg';
import NU from './countriesFlags/NU.svg';
import NF from './countriesFlags/NF.svg';
import KP from './countriesFlags/KP.svg';
import MK from './countriesFlags/MK.svg';
import MP from './countriesFlags/MP.svg';
import NO from './countriesFlags/NO.svg';
import OM from './countriesFlags/OM.svg';
import PK from './countriesFlags/PK.svg';
import PW from './countriesFlags/PW.svg';
import PS from './countriesFlags/PS.svg';
import PA from './countriesFlags/PA.svg';
import PG from './countriesFlags/PG.svg';
import PY from './countriesFlags/PY.svg';
import PE from './countriesFlags/PE.svg';
import PH from './countriesFlags/PH.svg';
import PN from './countriesFlags/PN.svg';
import PL from './countriesFlags/PL.svg';
import PT from './countriesFlags/PT.svg';
import PR from './countriesFlags/PR.svg';
import QA from './countriesFlags/QA.svg';
import CG from './countriesFlags/CG.svg';
import RO from './countriesFlags/RO.svg';
import RU from './countriesFlags/RU.svg';
import RW from './countriesFlags/RW.svg';
import RE from './countriesFlags/RE.svg';
import BL from './countriesFlags/BL.svg';
import SH from './countriesFlags/SH.svg';
import KN from './countriesFlags/KN.svg';
import LC from './countriesFlags/LC.svg';
import MF from './countriesFlags/MF.svg';
import PM from './countriesFlags/PM.svg';
import VC from './countriesFlags/VC.svg';
import WS from './countriesFlags/WS.svg';
import SM from './countriesFlags/SM.svg';
import SA from './countriesFlags/SA.svg';
import SN from './countriesFlags/SN.svg';
import RS from './countriesFlags/RS.svg';
import SC from './countriesFlags/SC.svg';
import SL from './countriesFlags/SL.svg';
import SG from './countriesFlags/SG.svg';
import SX from './countriesFlags/SX.svg';
import SK from './countriesFlags/SK.svg';
import SI from './countriesFlags/SI.svg';
import SB from './countriesFlags/SB.svg';
import SO from './countriesFlags/SO.svg';
import ZA from './countriesFlags/ZA.svg';
import GS from './countriesFlags/GS.svg';
import KR from './countriesFlags/KR.svg';
import SS from './countriesFlags/SS.svg';
import ES from './countriesFlags/ES.svg';
import LK from './countriesFlags/LK.svg';
import SD from './countriesFlags/SD.svg';
import SR from './countriesFlags/SR.svg';
import SJ from './countriesFlags/SJ.svg';
import SZ from './countriesFlags/SZ.svg';
import SE from './countriesFlags/SE.svg';
import CH from './countriesFlags/CH.svg';
import SY from './countriesFlags/SY.svg';
import ST from './countriesFlags/ST.svg';
import TW from './countriesFlags/TW.svg';
import TJ from './countriesFlags/TJ.svg';
import TZ from './countriesFlags/TZ.svg';
import TH from './countriesFlags/TH.svg';
import TG from './countriesFlags/TG.svg';
import TK from './countriesFlags/TK.svg';
import TO from './countriesFlags/TO.svg';
import TT from './countriesFlags/TT.svg';
import TN from './countriesFlags/TN.svg';
import TR from './countriesFlags/TR.svg';
import TM from './countriesFlags/TM.svg';
import TC from './countriesFlags/TC.svg';
import TV from './countriesFlags/TV.svg';
import VI from './countriesFlags/VI.svg';
import UG from './countriesFlags/UG.svg';
import UA from './countriesFlags/UA.svg';
import AE from './countriesFlags/AE.svg';
import GB from './countriesFlags/GB.svg';
import US from './countriesFlags/US.svg';
import UY from './countriesFlags/UY.svg';
import UZ from './countriesFlags/UZ.svg';
import VU from './countriesFlags/VU.svg';
import VA from './countriesFlags/VA.svg';
import VE from './countriesFlags/VE.svg';
import VN from './countriesFlags/VN.svg';
import WF from './countriesFlags/WF.svg';
import EH from './countriesFlags/EH.svg';
import YE from './countriesFlags/YE.svg';
import ZM from './countriesFlags/ZM.svg';
import ZW from './countriesFlags/ZW.svg';

export interface RawCountry {
  name: string;
  dial_code: string;
  code: string;
  flag: string;
  flagSVG: string;
  continent: string;
}

export const rawCountryList: RawCountry[] = [
  {
    name: 'Afghanistan',
    dial_code: '+93',
    code: 'AF',
    flag: '🇦🇫',
    flagSVG: AF,
    continent: 'AS',
  },
  {
    name: 'Åland',
    dial_code: '+358',
    code: 'AX',
    flag: '🇦🇽',
    flagSVG: AX,
    continent: 'EU',
  },
  {
    name: 'Albania',
    dial_code: '+355',
    code: 'AL',
    flag: '🇦🇱',
    flagSVG: AL,
    continent: 'EU',
  },
  {
    name: 'Algeria',
    dial_code: '+213',
    code: 'DZ',
    flag: '🇩🇿',
    flagSVG: DZ,
    continent: 'AF',
  },
  {
    name: 'American Samoa',
    dial_code: '+1684',
    code: 'AS',
    flag: '🇦🇸',
    flagSVG: AS,
    continent: 'OC',
  },
  {
    name: 'Andorra',
    dial_code: '+376',
    code: 'AD',
    flag: '🇦🇩',
    flagSVG: AD,
    continent: 'EU',
  },
  {
    name: 'Angola',
    dial_code: '+244',
    code: 'AO',
    flag: '🇦🇴',
    flagSVG: AO,
    continent: 'AF',
  },
  {
    name: 'Anguilla',
    dial_code: '+1264',
    code: 'AI',
    flag: '🇦🇮',
    flagSVG: AI,
    continent: 'NA',
  },
  {
    name: 'Antarctica',
    dial_code: '+672',
    code: 'AQ',
    flag: '🇦🇶',
    flagSVG: AQ,
    continent: 'AN',
  },
  {
    name: 'Antigua and Barbuda',
    dial_code: '+1268',
    code: 'AG',
    flag: '🇦🇬',
    flagSVG: AG,
    continent: 'NA',
  },
  {
    name: 'Argentina',
    dial_code: '+54',
    code: 'AR',
    flag: '🇦🇷',
    flagSVG: AR,
    continent: 'SA',
  },
  {
    name: 'Armenia',
    dial_code: '+374',
    code: 'AM',
    flag: '🇦🇲',
    flagSVG: AM,
    continent: 'AS',
  },
  {
    name: 'Aruba',
    dial_code: '+297',
    code: 'AW',
    flag: '🇦🇼',
    flagSVG: AW,
    continent: 'NA',
  },
  {
    name: 'Australia',
    dial_code: '+61',
    code: 'AU',
    flag: '🇦🇺',
    flagSVG: AU,
    continent: 'OC',
  },
  {
    name: 'Austria',
    dial_code: '+43',
    code: 'AT',
    flag: '🇦🇹',
    flagSVG: AT,
    continent: 'EU',
  },
  {
    name: 'Azerbaijan',
    dial_code: '+994',
    code: 'AZ',
    flag: '🇦🇿',
    flagSVG: AZ,
    continent: 'AS',
  },
  {
    name: 'Bahamas',
    dial_code: '+1242',
    code: 'BS',
    flag: '🇧🇸',
    flagSVG: BS,
    continent: 'NA',
  },
  {
    name: 'Bahrain',
    dial_code: '+973',
    code: 'BH',
    flag: '🇧🇭',
    flagSVG: BH,
    continent: 'AS',
  },
  {
    name: 'Bangladesh',
    dial_code: '+880',
    code: 'BD',
    flag: '🇧🇩',
    flagSVG: BD,
    continent: 'AS',
  },
  {
    name: 'Barbados',
    dial_code: '+1246',
    code: 'BB',
    flag: '🇧🇧',
    flagSVG: BB,
    continent: 'NA',
  },
  {
    name: 'Belarus',
    dial_code: '+375',
    code: 'BY',
    flag: '🇧🇾',
    flagSVG: BY,
    continent: 'EU',
  },
  {
    name: 'Belgium',
    dial_code: '+32',
    code: 'BE',
    flag: '🇧🇪',
    flagSVG: BE,
    continent: 'EU',
  },
  {
    name: 'Belize',
    dial_code: '+501',
    code: 'BZ',
    flag: '🇧🇿',
    flagSVG: BZ,
    continent: 'NA',
  },
  {
    name: 'Benin',
    dial_code: '+229',
    code: 'BJ',
    flag: '🇧🇯',
    flagSVG: BJ,
    continent: 'AF',
  },
  {
    name: 'Bermuda',
    dial_code: '+1441',
    code: 'BM',
    flag: '🇧🇲',
    flagSVG: BM,
    continent: 'NA',
  },
  {
    name: 'Bhutan',
    dial_code: '+975',
    code: 'BT',
    flag: '🇧🇹',
    flagSVG: BT,
    continent: 'AS',
  },
  {
    name: 'Bolivia',
    dial_code: '+591',
    code: 'BO',
    flag: '🇧🇴',
    flagSVG: BO,
    continent: 'SA',
  },
  {
    name: 'Bonaire',
    dial_code: '+5997',
    code: 'BQ',
    flag: '🇧🇶',
    flagSVG: BQ,
    continent: 'NA',
  },
  {
    name: 'Bosnia and Herzegovina',
    dial_code: '+387',
    code: 'BA',
    flag: '🇧🇦',
    flagSVG: BA,
    continent: 'EU',
  },
  {
    name: 'Botswana',
    dial_code: '+267',
    code: 'BW',
    flag: '🇧🇼',
    flagSVG: BW,
    continent: 'AF',
  },
  {
    name: 'Bouvet Island',
    dial_code: '+47',
    code: 'BV',
    flag: '🇧🇻',
    flagSVG: BV,
    continent: 'AN',
  },
  {
    name: 'Brazil',
    dial_code: '+55',
    code: 'BR',
    flag: '🇧🇷',
    flagSVG: BR,
    continent: 'SA',
  },
  {
    name: 'British Indian Ocean Territory',
    dial_code: '+246',
    code: 'IO',
    flag: '🇮🇴',
    flagSVG: IO,
    continent: 'AS',
  },
  {
    name: 'British Virgin Islands',
    dial_code: '+1284',
    code: 'VG',
    flag: '🇻🇬',
    flagSVG: VG,
    continent: 'NA',
  },
  {
    name: 'Brunei',
    dial_code: '+673',
    code: 'BN',
    flag: '🇧🇳',
    flagSVG: BN,
    continent: 'AS',
  },
  {
    name: 'Bulgaria',
    dial_code: '+359',
    code: 'BG',
    flag: '🇧🇬',
    flagSVG: BG,
    continent: 'EU',
  },
  {
    name: 'Burkina Faso',
    dial_code: '+226',
    code: 'BF',
    flag: '🇧🇫',
    flagSVG: BF,
    continent: 'AF',
  },
  {
    name: 'Burundi',
    dial_code: '+257',
    code: 'BI',
    flag: '🇧🇮',
    flagSVG: BI,
    continent: 'AF',
  },
  {
    name: 'Cambodia',
    dial_code: '+855',
    code: 'KH',
    flag: '🇰🇭',
    flagSVG: KH,
    continent: 'AS',
  },
  {
    name: 'Cameroon',
    dial_code: '+237',
    code: 'CM',
    flag: '🇨🇲',
    flagSVG: CM,
    continent: 'AF',
  },
  {
    name: 'Canada',
    dial_code: '+1',
    code: 'CA',
    flag: '🇨🇦',
    flagSVG: CA,
    continent: 'NA',
  },
  {
    name: 'Cape Verde',
    dial_code: '+238',
    code: 'CV',
    flag: '🇨🇻',
    flagSVG: CV,
    continent: 'AF',
  },
  {
    name: 'Cayman Islands',
    dial_code: '+1345',
    code: 'KY',
    flag: '🇰🇾',
    flagSVG: KY,
    continent: 'NA',
  },
  {
    name: 'Central African Republic',
    dial_code: '+236',
    code: 'CF',
    flag: '🇨🇫',
    flagSVG: CF,
    continent: 'AF',
  },
  {
    name: 'Chad',
    dial_code: '+235',
    code: 'TD',
    flag: '🇹🇩',
    flagSVG: TD,
    continent: 'AF',
  },
  {
    name: 'Chile',
    dial_code: '+56',
    code: 'CL',
    flag: '🇨🇱',
    flagSVG: CL,
    continent: 'SA',
  },
  {
    name: 'China',
    dial_code: '+86',
    code: 'CN',
    flag: '🇨🇳',
    flagSVG: CN,
    continent: 'AS',
  },
  {
    name: 'Christmas Island',
    dial_code: '+61',
    code: 'CX',
    flag: '🇨🇽',
    flagSVG: CX,
    continent: 'AS',
  },
  {
    name: 'Cocos [Keeling] Islands',
    dial_code: '+61',
    code: 'CC',
    flag: '🇨🇨',
    flagSVG: CC,
    continent: 'AS',
  },
  {
    name: 'Colombia',
    dial_code: '+57',
    code: 'CO',
    flag: '🇨🇴',
    flagSVG: CO,
    continent: 'SA',
  },
  {
    name: 'Comoros',
    dial_code: '+269',
    code: 'KM',
    flag: '🇰🇲',
    flagSVG: KM,
    continent: 'AF',
  },
  {
    name: 'Cook Islands',
    dial_code: '+682',
    code: 'CK',
    flag: '🇨🇰',
    flagSVG: CK,
    continent: 'OC',
  },
  {
    name: 'Costa Rica',
    dial_code: '+506',
    code: 'CR',
    flag: '🇨🇷',
    flagSVG: CR,
    continent: 'NA',
  },
  {
    name: 'Croatia',
    dial_code: '+385',
    code: 'HR',
    flag: '🇭🇷',
    flagSVG: HR,
    continent: 'EU',
  },
  {
    name: 'Cuba',
    dial_code: '+53',
    code: 'CU',
    flag: '🇨🇺',
    flagSVG: CU,
    continent: 'NA',
  },
  {
    name: 'Curacao',
    dial_code: '+5999',
    code: 'CW',
    flag: '🇨🇼',
    flagSVG: CW,
    continent: 'NA',
  },
  {
    name: 'Cyprus',
    dial_code: '+357',
    code: 'CY',
    flag: '🇨🇾',
    flagSVG: CY,
    continent: 'EU',
  },
  {
    name: 'Czech Republic',
    dial_code: '+420',
    code: 'CZ',
    flag: '🇨🇿',
    flagSVG: CZ,
    continent: 'EU',
  },
  {
    name: 'Democratic Republic of the Congo',
    dial_code: '+243',
    code: 'CD',
    flag: '🇨🇩',
    flagSVG: CD,
    continent: 'AF',
  },
  {
    name: 'Denmark',
    dial_code: '+45',
    code: 'DK',
    flag: '🇩🇰',
    flagSVG: DK,
    continent: 'EU',
  },
  {
    name: 'Djibouti',
    dial_code: '+253',
    code: 'DJ',
    flag: '🇩🇯',
    flagSVG: DJ,
    continent: 'AF',
  },
  {
    name: 'Dominica',
    dial_code: '+1767',
    code: 'DM',
    flag: '🇩🇲',
    flagSVG: DM,
    continent: 'NA',
  },
  {
    name: 'Dominican Republic',
    dial_code: '+1849',
    code: 'DO',
    flag: '🇩🇴',
    flagSVG: DO,
    continent: 'NA',
  },
  {
    name: 'East Timor',
    dial_code: '+670',
    code: 'TL',
    flag: '🇹🇱',
    flagSVG: TL,
    continent: 'OC',
  },
  {
    name: 'Ecuador',
    dial_code: '+593',
    code: 'EC',
    flag: '🇪🇨',
    flagSVG: EC,
    continent: 'SA',
  },
  {
    name: 'Egypt',
    dial_code: '+20',
    code: 'EG',
    flag: '🇪🇬',
    flagSVG: EG,
    continent: 'AF',
  },
  {
    name: 'El Salvador',
    dial_code: '+503',
    code: 'SV',
    flag: '🇸🇻',
    flagSVG: SV,
    continent: 'NA',
  },
  {
    name: 'Equatorial Guinea',
    dial_code: '+240',
    code: 'GQ',
    flag: '🇬🇶',
    flagSVG: GQ,
    continent: 'AF',
  },
  {
    name: 'Eritrea',
    dial_code: '+291',
    code: 'ER',
    flag: '🇪🇷',
    flagSVG: ER,
    continent: 'AF',
  },
  {
    name: 'Estonia',
    dial_code: '+372',
    code: 'EE',
    flag: '🇪🇪',
    flagSVG: EE,
    continent: 'EU',
  },
  {
    name: 'Ethiopia',
    dial_code: '+251',
    code: 'ET',
    flag: '🇪🇹',
    flagSVG: ET,
    continent: 'AF',
  },
  {
    name: 'Falkland Islands',
    dial_code: '+500',
    code: 'FK',
    flag: '🇫🇰',
    flagSVG: FK,
    continent: 'SA',
  },
  {
    name: 'Faroe Islands',
    dial_code: '+298',
    code: 'FO',
    flag: '🇫🇴',
    flagSVG: FO,
    continent: 'EU',
  },
  {
    name: 'Fiji',
    dial_code: '+679',
    code: 'FJ',
    flag: '🇫🇯',
    flagSVG: FJ,
    continent: 'OC',
  },
  {
    name: 'Finland',
    dial_code: '+358',
    code: 'FI',
    flag: '🇫🇮',
    flagSVG: FI,
    continent: 'EU',
  },
  {
    name: 'France',
    dial_code: '+33',
    code: 'FR',
    flag: '🇫🇷',
    flagSVG: FR,
    continent: 'EU',
  },
  {
    name: 'French Guiana',
    dial_code: '+594',
    code: 'GF',
    flag: '🇬🇫',
    flagSVG: GF,
    continent: 'SA',
  },
  {
    name: 'French Polynesia',
    dial_code: '+689',
    code: 'PF',
    flag: '🇵🇫',
    flagSVG: PF,
    continent: 'OC',
  },
  {
    name: 'French Southern Territories',
    dial_code: '+262',
    code: 'TF',
    flag: '🇹🇫',
    flagSVG: TF,
    continent: 'AN',
  },
  {
    name: 'Gabon',
    dial_code: '+241',
    code: 'GA',
    flag: '🇬🇦',
    flagSVG: GA,
    continent: 'AF',
  },
  {
    name: 'Gambia',
    dial_code: '+220',
    code: 'GM',
    flag: '🇬🇲',
    flagSVG: GM,
    continent: 'AF',
  },
  {
    name: 'Georgia',
    dial_code: '+995',
    code: 'GE',
    flag: '🇬🇪',
    flagSVG: GE,
    continent: 'EU',
  },
  {
    name: 'Germany',
    dial_code: '+49',
    code: 'DE',
    flag: '🇩🇪',
    flagSVG: DE,
    continent: 'EU',
  },
  {
    name: 'Ghana',
    dial_code: '+233',
    code: 'GH',
    flag: '🇬🇭',
    flagSVG: GH,
    continent: 'AF',
  },
  {
    name: 'Gibraltar',
    dial_code: '+350',
    code: 'GI',
    flag: '🇬🇮',
    flagSVG: GI,
    continent: 'EU',
  },
  {
    name: 'Greece',
    dial_code: '+30',
    code: 'GR',
    flag: '🇬🇷',
    flagSVG: GR,
    continent: 'EU',
  },
  {
    name: 'Greenland',
    dial_code: '+299',
    code: 'GL',
    flag: '🇬🇱',
    flagSVG: GL,
    continent: 'NA',
  },
  {
    name: 'Grenada',
    dial_code: '+1473',
    code: 'GD',
    flag: '🇬🇩',
    flagSVG: GD,
    continent: 'NA',
  },
  {
    name: 'Guadeloupe',
    dial_code: '+590',
    code: 'GP',
    flag: '🇬🇵',
    flagSVG: GP,
    continent: 'NA',
  },
  {
    name: 'Guam',
    dial_code: '+1671',
    code: 'GU',
    flag: '🇬🇺',
    flagSVG: GU,
    continent: 'OC',
  },
  {
    name: 'Guatemala',
    dial_code: '+502',
    code: 'GT',
    flag: '🇬🇹',
    flagSVG: GT,
    continent: 'NA',
  },
  {
    name: 'Guernsey',
    dial_code: '+44',
    code: 'GG',
    flag: '🇬🇬',
    flagSVG: GG,
    continent: 'EU',
  },
  {
    name: 'Guinea',
    dial_code: '+224',
    code: 'GN',
    flag: '🇬🇳',
    flagSVG: GN,
    continent: 'AF',
  },
  {
    name: 'Guinea-Bissau',
    dial_code: '+245',
    code: 'GW',
    flag: '🇬🇼',
    flagSVG: GW,
    continent: 'AF',
  },
  {
    name: 'Guyana',
    dial_code: '+592',
    code: 'GY',
    flag: '🇬🇾',
    flagSVG: GY,
    continent: 'SA',
  },
  {
    name: 'Haiti',
    dial_code: '+509',
    code: 'HT',
    flag: '🇭🇹',
    flagSVG: HT,
    continent: 'NA',
  },
  {
    name: 'Heard Island and McDonald Islands',
    dial_code: '+61',
    code: 'HM',
    flag: '🇭🇲',
    flagSVG: HM,
    continent: 'AN',
  },
  {
    name: 'Honduras',
    dial_code: '+504',
    code: 'HN',
    flag: '🇭🇳',
    flagSVG: HN,
    continent: 'NA',
  },
  {
    name: 'Hong Kong',
    dial_code: '+852',
    code: 'HK',
    flag: '🇭🇰',
    flagSVG: HK,
    continent: 'AS',
  },
  {
    name: 'Hungary',
    dial_code: '+36',
    code: 'HU',
    flag: '🇭🇺',
    flagSVG: HU,
    continent: 'EU',
  },
  {
    name: 'Iceland',
    dial_code: '+354',
    code: 'IS',
    flag: '🇮🇸',
    flagSVG: IS,
    continent: 'EU',
  },
  {
    name: 'India',
    dial_code: '+91',
    code: 'IN',
    flag: '🇮🇳',
    flagSVG: IN,
    continent: 'AS',
  },
  {
    name: 'Indonesia',
    dial_code: '+62',
    code: 'ID',
    flag: '🇮🇩',
    flagSVG: ID,
    continent: 'AS',
  },
  {
    name: 'Iran',
    dial_code: '+98',
    code: 'IR',
    flag: '🇮🇷',
    flagSVG: IR,
    continent: 'AS',
  },
  {
    name: 'Iraq',
    dial_code: '+964',
    code: 'IQ',
    flag: '🇮🇶',
    flagSVG: IQ,
    continent: 'AS',
  },
  {
    name: 'Ireland',
    dial_code: '+353',
    code: 'IE',
    flag: '🇮🇪',
    flagSVG: IE,
    continent: 'EU',
  },
  {
    name: 'Isle of Man',
    dial_code: '+44',
    code: 'IM',
    flag: '🇮🇲',
    flagSVG: IM,
    continent: 'EU',
  },
  {
    name: 'Israel',
    dial_code: '+972',
    code: 'IL',
    flag: '🇮🇱',
    flagSVG: IL,
    continent: 'AS',
  },
  {
    name: 'Italy',
    dial_code: '+39',
    code: 'IT',
    flag: '🇮🇹',
    flagSVG: IT,
    continent: 'EU',
  },
  {
    name: 'Ivory Coast',
    dial_code: '+225',
    code: 'CI',
    flag: '🇨🇮',
    flagSVG: CI,
    continent: 'AF',
  },
  {
    name: 'Jamaica',
    dial_code: '+1876',
    code: 'JM',
    flag: '🇯🇲',
    flagSVG: JM,
    continent: 'NA',
  },
  {
    name: 'Japan',
    dial_code: '+81',
    code: 'JP',
    flag: '🇯🇵',
    flagSVG: JP,
    continent: 'AS',
  },
  {
    name: 'Jersey',
    dial_code: '+44',
    code: 'JE',
    flag: '🇯🇪',
    flagSVG: JE,
    continent: 'EU',
  },
  {
    name: 'Jordan',
    dial_code: '+962',
    code: 'JO',
    flag: '🇯🇴',
    flagSVG: JO,
    continent: 'AS',
  },
  {
    name: 'Kazakhstan',
    dial_code: '+77',
    code: 'KZ',
    flag: '🇰🇿',
    flagSVG: KZ,
    continent: 'AS',
  },
  {
    name: 'Kenya',
    dial_code: '+254',
    code: 'KE',
    flag: '🇰🇪',
    flagSVG: KE,
    continent: 'AF',
  },
  {
    name: 'Kiribati',
    dial_code: '+686',
    code: 'KI',
    flag: '🇰🇮',
    flagSVG: KI,
    continent: 'OC',
  },
  {
    name: 'Kosovo',
    dial_code: '+377,381,383,386',
    code: 'XK',
    flag: '🇽🇰',
    flagSVG: XK,
    continent: 'EU',
  },
  {
    name: 'Kuwait',
    dial_code: '+965',
    code: 'KW',
    flag: '🇰🇼',
    flagSVG: KW,
    continent: 'AS',
  },
  {
    name: 'Kyrgyzstan',
    dial_code: '+996',
    code: 'KG',
    flag: '🇰🇬',
    flagSVG: KG,
    continent: 'AS',
  },
  {
    name: 'Laos',
    dial_code: '+856',
    code: 'LA',
    flag: '🇱🇦',
    flagSVG: LA,
    continent: 'AS',
  },
  {
    name: 'Latvia',
    dial_code: '+371',
    code: 'LV',
    flag: '🇱🇻',
    flagSVG: LV,
    continent: 'EU',
  },
  {
    name: 'Lebanon',
    dial_code: '+961',
    code: 'LB',
    flag: '🇱🇧',
    flagSVG: LB,
    continent: 'AS',
  },
  {
    name: 'Lesotho',
    dial_code: '+266',
    code: 'LS',
    flag: '🇱🇸',
    flagSVG: LS,
    continent: 'AF',
  },
  {
    name: 'Liberia',
    dial_code: '+231',
    code: 'LR',
    flag: '🇱🇷',
    flagSVG: LR,
    continent: 'AF',
  },
  {
    name: 'Libya',
    dial_code: '+218',
    code: 'LY',
    flag: '🇱🇾',
    flagSVG: LY,
    continent: 'AF',
  },
  {
    name: 'Liechtenstein',
    dial_code: '+423',
    code: 'LI',
    flag: '🇱🇮',
    flagSVG: LI,
    continent: 'EU',
  },
  {
    name: 'Lithuania',
    dial_code: '+370',
    code: 'LT',
    flag: '🇱🇹',
    flagSVG: LT,
    continent: 'EU',
  },
  {
    name: 'Luxembourg',
    dial_code: '+352',
    code: 'LU',
    flag: '🇱🇺',
    flagSVG: LU,
    continent: 'EU',
  },
  {
    name: 'Macao',
    dial_code: '+853',
    code: 'MO',
    flag: '🇲🇴',
    flagSVG: MO,
    continent: 'AS',
  },
  {
    name: 'Madagascar',
    dial_code: '+261',
    code: 'MG',
    flag: '🇲🇬',
    flagSVG: MG,
    continent: 'AF',
  },
  {
    name: 'Malawi',
    dial_code: '+265',
    code: 'MW',
    flag: '🇲🇼',
    flagSVG: MW,
    continent: 'AF',
  },
  {
    name: 'Malaysia',
    dial_code: '+60',
    code: 'MY',
    flag: '🇲🇾',
    flagSVG: MY,
    continent: 'AS',
  },
  {
    name: 'Maldives',
    dial_code: '+960',
    code: 'MV',
    flag: '🇲🇻',
    flagSVG: MV,
    continent: 'AS',
  },
  {
    name: 'Mali',
    dial_code: '+223',
    code: 'ML',
    flag: '🇲🇱',
    flagSVG: ML,
    continent: 'AF',
  },
  {
    name: 'Malta',
    dial_code: '+356',
    code: 'MT',
    flag: '🇲🇹',
    flagSVG: MT,
    continent: 'EU',
  },
  {
    name: 'Marshall Islands',
    dial_code: '+692',
    code: 'MH',
    flag: '🇲🇭',
    flagSVG: MH,
    continent: 'OC',
  },
  {
    name: 'Martinique',
    dial_code: '+596',
    code: 'MQ',
    flag: '🇲🇶',
    flagSVG: MQ,
    continent: 'NA',
  },
  {
    name: 'Mauritania',
    dial_code: '+222',
    code: 'MR',
    flag: '🇲🇷',
    flagSVG: MR,
    continent: 'AF',
  },
  {
    name: 'Mauritius',
    dial_code: '+230',
    code: 'MU',
    flag: '🇲🇺',
    flagSVG: MU,
    continent: 'AF',
  },
  {
    name: 'Mayotte',
    dial_code: '+262',
    code: 'YT',
    flag: '🇾🇹',
    flagSVG: YT,
    continent: 'AF',
  },
  {
    name: 'Mexico',
    dial_code: '+52',
    code: 'MX',
    flag: '🇲🇽',
    flagSVG: MX,
    continent: 'NA',
  },
  {
    name: 'Micronesia',
    dial_code: '+691',
    code: 'FM',
    flag: '🇫🇲',
    flagSVG: FM,
    continent: 'OC',
  },
  {
    name: 'Moldova',
    dial_code: '+373',
    code: 'MD',
    flag: '🇲🇩',
    flagSVG: MD,
    continent: 'EU',
  },
  {
    name: 'Monaco',
    dial_code: '+377',
    code: 'MC',
    flag: '🇲🇨',
    flagSVG: MC,
    continent: 'EU',
  },
  {
    name: 'Mongolia',
    dial_code: '+976',
    code: 'MN',
    flag: '🇲🇳',
    flagSVG: MN,
    continent: 'AS',
  },
  {
    name: 'Montenegro',
    dial_code: '+382',
    code: 'ME',
    flag: '🇲🇪',
    flagSVG: ME,
    continent: 'EU',
  },
  {
    name: 'Montserrat',
    dial_code: '+1664',
    code: 'MS',
    flag: '🇲🇸',
    flagSVG: MS,
    continent: 'NA',
  },
  {
    name: 'Morocco',
    dial_code: '+212',
    code: 'MA',
    flag: '🇲🇦',
    flagSVG: MA,
    continent: 'AF',
  },
  {
    name: 'Mozambique',
    dial_code: '+258',
    code: 'MZ',
    flag: '🇲🇿',
    flagSVG: MZ,
    continent: 'AF',
  },
  {
    name: 'Myanmar [Burma]',
    dial_code: '+95',
    code: 'MM',
    flag: '🇲🇲',
    flagSVG: MM,
    continent: 'AS',
  },
  {
    name: 'Namibia',
    dial_code: '+264',
    code: 'NA',
    flag: '🇳🇦',
    flagSVG: NA,
    continent: 'AF',
  },
  {
    name: 'Nauru',
    dial_code: '+674',
    code: 'NR',
    flag: '🇳🇷',
    flagSVG: NR,
    continent: 'OC',
  },
  {
    name: 'Nepal',
    dial_code: '+977',
    code: 'NP',
    flag: '🇳🇵',
    flagSVG: NP,
    continent: 'AS',
  },
  {
    name: 'Netherlands',
    dial_code: '+31',
    code: 'NL',
    flag: '🇳🇱',
    flagSVG: NL,
    continent: 'EU',
  },
  {
    name: 'New Caledonia',
    dial_code: '+687',
    code: 'NC',
    flag: '🇳🇨',
    flagSVG: NC,
    continent: 'OC',
  },
  {
    name: 'New Zealand',
    dial_code: '+64',
    code: 'NZ',
    flag: '🇳🇿',
    flagSVG: NZ,
    continent: 'OC',
  },
  {
    name: 'Nicaragua',
    dial_code: '+505',
    code: 'NI',
    flag: '🇳🇮',
    flagSVG: NI,
    continent: 'NA',
  },
  {
    name: 'Niger',
    dial_code: '+227',
    code: 'NE',
    flag: '🇳🇪',
    flagSVG: NE,
    continent: 'AF',
  },
  {
    name: 'Nigeria',
    dial_code: '+234',
    code: 'NG',
    flag: '🇳🇬',
    flagSVG: NG,
    continent: 'AF',
  },
  {
    name: 'Niue',
    dial_code: '+683',
    code: 'NU',
    flag: '🇳🇺',
    flagSVG: NU,
    continent: 'OC',
  },
  {
    name: 'Norfolk Island',
    dial_code: '+672',
    code: 'NF',
    flag: '🇳🇫',
    flagSVG: NF,
    continent: 'OC',
  },
  {
    name: 'North Korea',
    dial_code: '+850',
    code: 'KP',
    flag: '🇰🇵',
    flagSVG: KP,
    continent: 'AS',
  },
  {
    name: 'North Macedonia',
    dial_code: '+389',
    code: 'MK',
    flag: '🇲🇰',
    flagSVG: MK,
    continent: 'EU',
  },
  {
    name: 'Northern Mariana Islands',
    dial_code: '+1670',
    code: 'MP',
    flag: '🇲🇵',
    flagSVG: MP,
    continent: 'OC',
  },
  {
    name: 'Norway',
    dial_code: '+47',
    code: 'NO',
    flag: '🇳🇴',
    flagSVG: NO,
    continent: 'EU',
  },
  {
    name: 'Oman',
    dial_code: '+968',
    code: 'OM',
    flag: '🇴🇲',
    flagSVG: OM,
    continent: 'AS',
  },
  {
    name: 'Pakistan',
    dial_code: '+92',
    code: 'PK',
    flag: '🇵🇰',
    flagSVG: PK,
    continent: 'AS',
  },
  {
    name: 'Palau',
    dial_code: '+680',
    code: 'PW',
    flag: '🇵🇼',
    flagSVG: PW,
    continent: 'OC',
  },
  {
    name: 'Palestine',
    dial_code: '+970',
    code: 'PS',
    flag: '🇵🇸',
    flagSVG: PS,
    continent: 'AS',
  },
  {
    name: 'Panama',
    dial_code: '+507',
    code: 'PA',
    flag: '🇵🇦',
    flagSVG: PA,
    continent: 'NA',
  },
  {
    name: 'Papua New Guinea',
    dial_code: '+675',
    code: 'PG',
    flag: '🇵🇬',
    flagSVG: PG,
    continent: 'OC',
  },
  {
    name: 'Paraguay',
    dial_code: '+595',
    code: 'PY',
    flag: '🇵🇾',
    flagSVG: PY,
    continent: 'SA',
  },
  {
    name: 'Peru',
    dial_code: '+51',
    code: 'PE',
    flag: '🇵🇪',
    flagSVG: PE,
    continent: 'SA',
  },
  {
    name: 'Philippines',
    dial_code: '+63',
    code: 'PH',
    flag: '🇵🇭',
    flagSVG: PH,
    continent: 'AS',
  },
  {
    name: 'Pitcairn Islands',
    dial_code: '+64',
    code: 'PN',
    flag: '🇵🇳',
    flagSVG: PN,
    continent: 'OC',
  },
  {
    name: 'Poland',
    dial_code: '+48',
    code: 'PL',
    flag: '🇵🇱',
    flagSVG: PL,
    continent: 'EU',
  },
  {
    name: 'Portugal',
    dial_code: '+351',
    code: 'PT',
    flag: '🇵🇹',
    flagSVG: PT,
    continent: 'EU',
  },
  {
    name: 'Puerto Rico',
    dial_code: '+1939',
    code: 'PR',
    flag: '🇵🇷',
    flagSVG: PR,
    continent: 'NA',
  },
  {
    name: 'Qatar',
    dial_code: '+974',
    code: 'QA',
    flag: '🇶🇦',
    flagSVG: QA,
    continent: 'AS',
  },
  {
    name: 'Republic of the Congo',
    dial_code: '+242',
    code: 'CG',
    flag: '🇨🇬',
    flagSVG: CG,
    continent: 'AF',
  },
  {
    name: 'Romania',
    dial_code: '+40',
    code: 'RO',
    flag: '🇷🇴',
    flagSVG: RO,
    continent: 'EU',
  },
  {
    name: 'Russia',
    dial_code: '+7',
    code: 'RU',
    flag: '🇷🇺',
    flagSVG: RU,
    continent: 'EU',
  },
  {
    name: 'Rwanda',
    dial_code: '+250',
    code: 'RW',
    flag: '🇷🇼',
    flagSVG: RW,
    continent: 'AF',
  },
  {
    name: 'Réunion',
    dial_code: '+262',
    code: 'RE',
    flag: '🇷🇪',
    flagSVG: RE,
    continent: 'AF',
  },
  {
    name: 'Saint Barthélemy',
    dial_code: '+590',
    code: 'BL',
    flag: '🇧🇱',
    flagSVG: BL,
    continent: 'NA',
  },
  {
    name: 'Saint Helena',
    dial_code: '+290',
    code: 'SH',
    flag: '🇸🇭',
    flagSVG: SH,
    continent: 'AF',
  },
  {
    name: 'Saint Kitts and Nevis',
    dial_code: '+1869',
    code: 'KN',
    flag: '🇰🇳',
    flagSVG: KN,
    continent: 'NA',
  },
  {
    name: 'Saint Lucia',
    dial_code: '+1758',
    code: 'LC',
    flag: '🇱🇨',
    flagSVG: LC,
    continent: 'NA',
  },
  {
    name: 'Saint Martin',
    dial_code: '+590',
    code: 'MF',
    flag: '🇲🇫',
    flagSVG: MF,
    continent: 'NA',
  },
  {
    name: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    code: 'PM',
    flag: '🇵🇲',
    flagSVG: PM,
    continent: 'NA',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dial_code: '+1784',
    code: 'VC',
    flag: '🇻🇨',
    flagSVG: VC,
    continent: 'NA',
  },
  {
    name: 'Samoa',
    dial_code: '+685',
    code: 'WS',
    flag: '🇼🇸',
    flagSVG: WS,
    continent: 'OC',
  },
  {
    name: 'San Marino',
    dial_code: '+378',
    code: 'SM',
    flag: '🇸🇲',
    flagSVG: SM,
    continent: 'EU',
  },
  {
    name: 'Saudi Arabia',
    dial_code: '+966',
    code: 'SA',
    flag: '🇸🇦',
    flagSVG: SA,
    continent: 'AS',
  },
  {
    name: 'Senegal',
    dial_code: '+221',
    code: 'SN',
    flag: '🇸🇳',
    flagSVG: SN,
    continent: 'AF',
  },
  {
    name: 'Serbia',
    dial_code: '+381',
    code: 'RS',
    flag: '🇷🇸',
    flagSVG: RS,
    continent: 'EU',
  },
  {
    name: 'Seychelles',
    dial_code: '+248',
    code: 'SC',
    flag: '🇸🇨',
    flagSVG: SC,
    continent: 'AF',
  },
  {
    name: 'Sierra Leone',
    dial_code: '+232',
    code: 'SL',
    flag: '🇸🇱',
    flagSVG: SL,
    continent: 'AF',
  },
  {
    name: 'Singapore',
    dial_code: '+65',
    code: 'SG',
    flag: '🇸🇬',
    flagSVG: SG,
    continent: 'AS',
  },
  {
    name: 'Sint Maarten',
    dial_code: '+1721',
    code: 'SX',
    flag: '🇸🇽',
    flagSVG: SX,
    continent: 'NA',
  },
  {
    name: 'Slovakia',
    dial_code: '+421',
    code: 'SK',
    flag: '🇸🇰',
    flagSVG: SK,
    continent: 'EU',
  },
  {
    name: 'Slovenia',
    dial_code: '+386',
    code: 'SI',
    flag: '🇸🇮',
    flagSVG: SI,
    continent: 'EU',
  },
  {
    name: 'Solomon Islands',
    dial_code: '+677',
    code: 'SB',
    flag: '🇸🇧',
    flagSVG: SB,
    continent: 'OC',
  },
  {
    name: 'Somalia',
    dial_code: '+252',
    code: 'SO',
    flag: '🇸🇴',
    flagSVG: SO,
    continent: 'AF',
  },
  {
    name: 'South Africa',
    dial_code: '+27',
    code: 'ZA',
    flag: '🇿🇦',
    flagSVG: ZA,
    continent: 'AF',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    code: 'GS',
    flag: '🇬🇸',
    flagSVG: GS,
    continent: 'AN',
  },
  {
    name: 'South Korea',
    dial_code: '+82',
    code: 'KR',
    flag: '🇰🇷',
    flagSVG: KR,
    continent: 'AS',
  },
  {
    name: 'South Sudan',
    dial_code: '+211',
    code: 'SS',
    flag: '🇸🇸',
    flagSVG: SS,
    continent: 'AF',
  },
  {
    name: 'Spain',
    dial_code: '+34',
    code: 'ES',
    flag: '🇪🇸',
    flagSVG: ES,
    continent: 'EU',
  },
  {
    name: 'Sri Lanka',
    dial_code: '+94',
    code: 'LK',
    flag: '🇱🇰',
    flagSVG: LK,
    continent: 'AS',
  },
  {
    name: 'Sudan',
    dial_code: '+249',
    code: 'SD',
    flag: '🇸🇩',
    flagSVG: SD,
    continent: 'AF',
  },
  {
    name: 'Suriname',
    dial_code: '+597',
    code: 'SR',
    flag: '🇸🇷',
    flagSVG: SR,
    continent: 'SA',
  },
  {
    name: 'Svalbard and Jan Mayen',
    dial_code: '+4779',
    code: 'SJ',
    flag: '🇸🇯',
    flagSVG: SJ,
    continent: 'EU',
  },
  {
    name: 'Swaziland',
    dial_code: '+268',
    code: 'SZ',
    flag: '🇸🇿',
    flagSVG: SZ,
    continent: 'AF',
  },
  {
    name: 'Sweden',
    dial_code: '+46',
    code: 'SE',
    flag: '🇸🇪',
    flagSVG: SE,
    continent: 'EU',
  },
  {
    name: 'Switzerland',
    dial_code: '+41',
    code: 'CH',
    flag: '🇨🇭',
    flagSVG: CH,
    continent: 'EU',
  },
  {
    name: 'Syria',
    dial_code: '+963',
    code: 'SY',
    flag: '🇸🇾',
    flagSVG: SY,
    continent: 'AS',
  },
  {
    name: 'São Tomé and Príncipe',
    dial_code: '+239',
    code: 'ST',
    flag: '🇸🇹',
    flagSVG: ST,
    continent: 'AF',
  },
  {
    name: 'Taiwan',
    dial_code: '+886',
    code: 'TW',
    flag: '🇹🇼',
    flagSVG: TW,
    continent: 'AS',
  },
  {
    name: 'Tajikistan',
    dial_code: '+992',
    code: 'TJ',
    flag: '🇹🇯',
    flagSVG: TJ,
    continent: 'AS',
  },
  {
    name: 'Tanzania',
    dial_code: '+255',
    code: 'TZ',
    flag: '🇹🇿',
    flagSVG: TZ,
    continent: 'AF',
  },
  {
    name: 'Thailand',
    dial_code: '+66',
    code: 'TH',
    flag: '🇹🇭',
    flagSVG: TH,
    continent: 'AS',
  },
  {
    name: 'Togo',
    dial_code: '+228',
    code: 'TG',
    flag: '🇹🇬',
    flagSVG: TG,
    continent: 'AF',
  },
  {
    name: 'Tokelau',
    dial_code: '+690',
    code: 'TK',
    flag: '🇹🇰',
    flagSVG: TK,
    continent: 'OC',
  },
  {
    name: 'Tonga',
    dial_code: '+676',
    code: 'TO',
    flag: '🇹🇴',
    flagSVG: TO,
    continent: 'OC',
  },
  {
    name: 'Trinidad and Tobago',
    dial_code: '+1868',
    code: 'TT',
    flag: '🇹🇹',
    flagSVG: TT,
    continent: 'NA',
  },
  {
    name: 'Tunisia',
    dial_code: '+216',
    code: 'TN',
    flag: '🇹🇳',
    flagSVG: TN,
    continent: 'AF',
  },
  {
    name: 'Turkey',
    dial_code: '+90',
    code: 'TR',
    flag: '🇹🇷',
    flagSVG: TR,
    continent: 'AS',
  },
  {
    name: 'Turkmenistan',
    dial_code: '+993',
    code: 'TM',
    flag: '🇹🇲',
    flagSVG: TM,
    continent: 'AS',
  },
  {
    name: 'Turks and Caicos Islands',
    dial_code: '+1649',
    code: 'TC',
    flag: '🇹🇨',
    flagSVG: TC,
    continent: 'NA',
  },
  {
    name: 'Tuvalu',
    dial_code: '+688',
    code: 'TV',
    flag: '🇹🇻',
    flagSVG: TV,
    continent: 'OC',
  },
  {
    name: 'U.S. Minor Outlying Islands',
    dial_code: '+1',
    code: 'UM',
    flag: '🇺🇲',
    flagSVG: US,
    continent: 'OC',
  },
  {
    name: 'U.S. Virgin Islands',
    dial_code: '+1340',
    code: 'VI',
    flag: '🇻🇮',
    flagSVG: VI,
    continent: 'NA',
  },
  {
    name: 'Uganda',
    dial_code: '+256',
    code: 'UG',
    flag: '🇺🇬',
    flagSVG: UG,
    continent: 'AF',
  },
  {
    name: 'Ukraine',
    dial_code: '+380',
    code: 'UA',
    flag: '🇺🇦',
    flagSVG: UA,
    continent: 'EU',
  },
  {
    name: 'United Arab Emirates',
    dial_code: '+971',
    code: 'AE',
    flag: '🇦🇪',
    flagSVG: AE,
    continent: 'AS',
  },
  {
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'GB',
    flag: '🇬🇧',
    flagSVG: GB,
    continent: 'EU',
  },
  {
    name: 'United States',
    dial_code: '+1',
    code: 'US',
    flag: '🇺🇸',
    flagSVG: US,
    continent: 'NA',
  },
  {
    name: 'Uruguay',
    dial_code: '+598',
    code: 'UY',
    flag: '🇺🇾',
    flagSVG: UY,
    continent: 'SA',
  },
  {
    name: 'Uzbekistan',
    dial_code: '+998',
    code: 'UZ',
    flag: '🇺🇿',
    flagSVG: UZ,
    continent: 'AS',
  },
  {
    name: 'Vanuatu',
    dial_code: '+678',
    code: 'VU',
    flag: '🇻🇺',
    flagSVG: VU,
    continent: 'OC',
  },
  {
    name: 'Vatican City',
    dial_code: '+379',
    code: 'VA',
    flag: '🇻🇦',
    flagSVG: VA,
    continent: 'EU',
  },
  {
    name: 'Venezuela',
    dial_code: '+58',
    code: 'VE',
    flag: '🇻🇪',
    flagSVG: VE,
    continent: 'SA',
  },
  {
    name: 'Vietnam',
    dial_code: '+84',
    code: 'VN',
    flag: '🇻🇳',
    flagSVG: VN,
    continent: 'AS',
  },
  {
    name: 'Wallis and Futuna',
    dial_code: '+681',
    code: 'WF',
    flag: '🇼🇫',
    flagSVG: WF,
    continent: 'OC',
  },
  {
    name: 'Western Sahara',
    dial_code: '+212',
    code: 'EH',
    flag: '🇪🇭',
    flagSVG: EH,
    continent: 'AF',
  },
  {
    name: 'Yemen',
    dial_code: '+967',
    code: 'YE',
    flag: '🇾🇪',
    flagSVG: YE,
    continent: 'AS',
  },
  {
    name: 'Zambia',
    dial_code: '+260',
    code: 'ZM',
    flag: '🇿🇲',
    flagSVG: ZM,
    continent: 'AF',
  },
  {
    name: 'Zimbabwe',
    dial_code: '+263',
    code: 'ZW',
    flag: '🇿🇼',
    flagSVG: ZW,
    continent: 'AF',
  },
];

export const continentsOptionsMap: Record<
  string,
  { label: string; value: string; options: { label: string; value: string }[] }
> = {
  NA: {
    label: 'North America',
    value: 'NA',
    options: [],
  },
  SA: {
    label: 'South America',
    value: 'SA',
    options: [],
  },
  EU: {
    label: 'Europe',
    value: 'EU',
    options: [],
  },
  AF: {
    label: 'Africa',
    value: 'AF',
    options: [],
  },
  AS: {
    label: 'Asia',
    value: 'AS',
    options: [],
  },
  OC: {
    label: 'Oceania',
    value: 'OC',
    options: [],
  },
  AN: {
    label: 'Antarctica',
    value: 'AN',
    options: [],
  },
};

export const countryListOptionMap: Record<string, SelectOption> = {};
rawCountryList.forEach((country) => {
  continentsOptionsMap[
    country.continent as keyof typeof continentsOptionsMap
  ].options.push({
    label: country.name,
    value: country.code,
  });
  countryListOptionMap[country.code] = {
    value: country.code,
    label: country.name,
  };
});
