import { UserCardObject } from '@a_team/models/dist/UserObject';
import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { TextColors } from '@ateams/components';

interface UserCardProps {
  user: UserCardObject;
  onRemove?: (uid: string) => void;
  onSelect?: (user: UserCardObject) => void;
  className?: string;
  style?: React.CSSProperties;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: 16,
  },
  profile: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  profileImg: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 16,
  },
  button: {
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    outline: 'none',
    fontSize: '14px',
  },
  removeBtn: {
    color: TextColors.regularLight,
  },
  selectBtn: {
    color: TextColors.primaryLight,
  },
  fullName: {
    color: 'black',
  },
});

const UserCard = ({
  user,
  onRemove,
  onSelect,
  className,
  style,
}: UserCardProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div style={style} className={cx(styles.container, className)}>
      <div className={styles.profile}>
        <img
          className={styles.profileImg}
          src={user.profilePictureURL}
          alt={user.fullName}
        />
        <div>
          <div className={styles.fullName}>{user.fullName}</div>
        </div>
      </div>
      <div>
        {onRemove && (
          <button
            className={cx(styles.button, styles.removeBtn)}
            onClick={() => onRemove(user.uid)}
          >
            Remove
          </button>
        )}
        {onSelect && (
          <button
            data-testing-id="select-user"
            className={cx(styles.button, styles.selectBtn)}
            onClick={() => onSelect(user)}
          >
            Select
          </button>
        )}
      </div>
    </div>
  );
};

export default UserCard;
