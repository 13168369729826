/** @deprecated: please remove **/
import React from 'react';
import { observer } from 'mobx-react';
import { AdminTeamWorkMissionsLocation, RootLocation } from '@src/locations';
import MainLayout, { MainLayoutProps } from '@src/layouts/Main';
import SectionHeading from '@src/components/SectionHeading';
import { Redirect } from 'react-router-dom';
import TabButtons, { TabNavLink } from '@src/components/TabButtons';
import { useStores } from '@src/stores';

const AdminDashboardLayout = (props: MainLayoutProps) => {
  const { auth } = useStores();
  const { title, ...leftProps } = props;

  if (!auth.isAdmin) {
    return <Redirect to={RootLocation} />;
  }

  const tabs = [
    {
      label: `Missions`,
      to: AdminTeamWorkMissionsLocation,
    },
  ];

  return (
    <MainLayout
      title={'BuildTeam'}
      topNav={
        <div
          style={{
            padding: '40px 56px 0',
            boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <SectionHeading isFirst>BuildTeam HQ</SectionHeading>
          <p>
            Everything you need to help A.Team shape its community of
            accomplished product builders is right here, in the BuildTeam HQ −
            where you can manage missions, members, and more.
          </p>
          <TabButtons style={{ boxShadow: 'none', marginLeft: -32 }}>
            {tabs.map((tab, i) => (
              <TabNavLink key={i} to={tab.to}>
                <>{tab.label}</>
              </TabNavLink>
            ))}
          </TabButtons>
        </div>
      }
      {...leftProps}
    />
  );
};

export default observer(AdminDashboardLayout);
