import React, { ReactElement } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { BorderColors } from '../theme/colors';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${BorderColors['light']}`,
    },
  },
});

export const FormGroupRow: React.FC = (props): ReactElement => {
  const styles = useStyles();

  return <div className={cx(styles.root)}>{props.children}</div>;
};
