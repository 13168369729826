import React from 'react';
import { SelectOption, SelectProps } from '@ateams/components';
import { Select } from './select';

/**
 * 12-hour clock format sucks !
 */
const options: SelectOption[] = [
  { label: '1am', value: '1' },
  { label: '2am', value: '2' },
  { label: '3am', value: '3' },
  { label: '4am', value: '4' },
  { label: '5am', value: '5' },
  { label: '6am', value: '6' },
  { label: '7am', value: '7' },
  { label: '8am', value: '8' },
  { label: '9am', value: '9' },
  { label: '10am', value: '10' },
  { label: '11am', value: '11' },
  { label: '12am', value: '12' },
  { label: '1pm', value: '13' },
  { label: '2pm', value: '14' },
  { label: '3pm', value: '15' },
  { label: '4pm', value: '16' },
  { label: '5pm', value: '17' },
  { label: '6pm', value: '18' },
  { label: '7pm', value: '19' },
  { label: '8pm', value: '20' },
  { label: '9pm', value: '21' },
  { label: '10pm', value: '22' },
  { label: '11pm', value: '23' },
  { label: '12pm', value: '24' },
];

interface TimeOption {
  label: string;
  value: number;
}

export type OnTimeSelectChange = (option: null | TimeOption) => void;

export type TimeSelectProps = Omit<SelectProps, 'options' | 'onChange'> & {
  onChange: OnTimeSelectChange;
};

export function minutesFromStartOfDayToOption(
  minutesFromStartOfDay: number,
): SelectOption | undefined {
  const hours = minutesFromStartOfDay / 60;

  return options.find((option) => parseInt(option.value) === hours);
}

export function optionToMinutesFromStartOfDay(option: SelectOption): number {
  return parseInt(option.value) * 60;
}

export const TimeSelect: React.FC<TimeSelectProps> = (props) => {
  const onChange: SelectProps['onChange'] = (option) => {
    props.onChange?.(
      option
        ? {
            label: option.label,
            value: optionToMinutesFromStartOfDay(option),
          }
        : null,
    );
  };

  return <Select {...props} onChange={onChange} options={options} />;
};
