import { useStores } from '@src/stores';
import { queryKeys } from './keys';
import { apiCompanies } from '@ateams/api';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { Company, CompanyId, CompanyStatus } from '@a_team/models/dist/Company';
import { QueryResult } from '@a_team/models/dist/misc';
import { NewCompanyId } from '@src/components/CompanyPicker';

export const useQueryAutoCompleteCompany = ({
  searchTerm,
  onSuccess,
  canCreateNewCompanies,
  useEmployeesCollection,
}: {
  searchTerm: string;
  onSuccess?: (data: QueryResult<Company>) => void;
  canCreateNewCompanies?: boolean;
  useEmployeesCollection?: boolean;
}) => {
  const stores = useStores();
  return useQuery({
    queryKey: queryKeys.companies.autoCompleteCompany(
      searchTerm,
      useEmployeesCollection,
    ),
    queryFn: async () => {
      if (!searchTerm) {
        return Promise.resolve({ items: [], next: null });
      }
      return apiCompanies.autoCompleteCompany(stores.auth, {
        searchTerm,
        useEmployeesCollection,
      });
    },
    select: (data) => {
      const newCompany = {
        id: NewCompanyId,
        name: searchTerm,
        talentIndustryIds: [],
        status: CompanyStatus.Unverified,
      };
      if (canCreateNewCompanies && searchTerm) {
        return {
          items: [...data.items, newCompany],
          next: data.next,
        };
      }
      return data;
    },
    onSuccess,
  });
};

export const useMutationCreateCompany = () => {
  const stores = useStores();
  return useMutation({
    mutationFn: (name: string) => {
      return apiCompanies.createCompany(stores.auth, { name });
    },
  });
};

export const useQueriesGetCompanies = ({
  companyIds,
  onSuccess,
  enabled = true,
}: {
  companyIds: CompanyId[];
  enabled: boolean;
  onSuccess?: (data: Company) => void;
}) => {
  const stores = useStores();
  return useQueries({
    queries:
      companyIds.length > 0
        ? companyIds.map((companyId) => ({
            queryKey: queryKeys.companies.getCompany(companyId),
            queryFn: () => {
              return apiCompanies.getCompany(stores.auth, companyId);
            },
            onSuccess,
            enabled: enabled,
          }))
        : [],
  });
};
