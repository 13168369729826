import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
  },
});

const DeliverExceptionalResults = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <p>
        <strong>Deliver exceptional results</strong>: Companies expect
        exceptional work from the A.Team community and can also sense it when
        someone underperforms. This is why they pay such high hourly rates. You
        have been admitted to the A.Team network because we are positive you can
        provide such exceptional work! Wow the company you’re working with and
        deliver some of the best work of your career.{' '}
      </p>
    </div>
  );
};

export default DeliverExceptionalResults;
