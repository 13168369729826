import React, { ReactElement, useEffect, useState } from 'react';
import Modal from '..';
import { observer } from 'mobx-react';
import SectionHeading from '@src/components/SectionHeading';
import useLoadingState from '@src/hooks/useLoadingState';
import { useStores } from '@src/stores';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { Button as CallToActionButton, ToggleSwitch } from '@ateams/components';
import { Flags } from '@src/stores/Auth';

interface Props {
  onClose(): void;
  onSubmit(flags: Flags): Promise<void>;
  open: boolean;
}

const ABFlagsModal = (props: Props): ReactElement => {
  const { open, onClose, onSubmit } = props;
  const { auth, users } = useStores();
  const [flags, setFlags] = useState<Flags>({});
  const [loading, setLoading] = useLoadingState();

  useEffect(() => {
    Object.keys(auth.userAbFlags).length > 0 && setFlags(auth.userAbFlags);
  }, [auth.userAbFlags]);

  useEffect(() => {
    if (users.profile && auth.isAdmin) {
      auth.getUserAbFlags(auth, users.profile.data.username);
    }
  }, [users.profile?.data.username, auth.isAdmin]);

  const handleSubmit = () => {
    setLoading(onSubmit(flags).then(() => window.location.reload()));
  };

  return (
    <>
      <Modal
        onClose={onClose}
        open={open}
        style={{ maxHeight: 600, minWidth: '50%' }}
      >
        <SectionHeading isFirst>AB Flags</SectionHeading>
        <p>
          Changing these flags will affect {users.profile?.data.firstName}'s
          view of the platform
        </p>
        {Object.keys(flags).map((flag) => {
          return (
            <div
              key={flag}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p>{flag}</p>
              <ToggleSwitch
                checked={flags[flag]}
                onChange={(checked) => setFlags({ ...flags, [flag]: checked })}
              />
            </div>
          );
        })}
        <div style={{ marginTop: 32 }}>
          Please note: Saving will result in a page refresh
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}
        >
          <CallToActionButton onClick={handleSubmit}>Save</CallToActionButton>
        </div>
      </Modal>
      <LoadingIndicator loading={loading} />
    </>
  );
};

export default observer(ABFlagsModal);
