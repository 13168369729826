/* eslint-disable @typescript-eslint/ban-types,no-console */
export const createMock = <T extends object>(
  fields: string[],
  apply: (fields: string[], args: unknown[]) => void,
): T =>
  new Proxy<T>((() => void 0) as unknown as T, {
    get: <K extends keyof T>(
      _target: unknown,
      property: K | string | symbol,
    ): T[K] =>
      createMock<T[K] & object>([...fields, property as string], apply),
    apply: (target: unknown, thisArg: unknown, argArray: unknown[]): void =>
      apply(fields, argArray),
  });
