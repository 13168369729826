import React, { ReactElement, ReactNode, useMemo } from 'react';
import MainLayout from '@src/layouts/Main';
import TabButtons, { TabButton } from '@src/components/TabButtons';
import WebsitePolicy from './WebsitePolicy';
import TalentPolicy from './TalentPolicy';
import TermsOfService from './TermsOfService';
import {
  PrivacyPolicyLocation,
  TermsOfServiceLocation,
  TalentPrivacyStatementLocation,
  ClientTermsOfServiceLocation,
  CodeOfConductLocation,
} from '@src/locations';
import { match, useHistory } from 'react-router-dom';
import ClientTermsOfService from './ClientTermsOfService';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import PlatformCodeOfConduct from '@src/components/Modal/CodeOfConductModal/PlatformCodeOfConduct';

interface Props {
  match: match;
}

const useStyles = createUseStyles({
  tabContent: {
    padding: 24,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    tabContent: {
      padding: 56,
    },
  },
});

export default function PrivacyPolicyView(props: Props): ReactElement {
  const { match } = props;
  const styles = useStyles();
  const history = useHistory();

  const tabs: {
    id: string;
    label: string | ReactNode;
    component: ReactNode;
  }[] = [
    {
      id: TermsOfServiceLocation,
      label: 'Tech Maker Terms of Service',
      component: <TermsOfService />,
    },
    {
      id: PrivacyPolicyLocation,
      label: 'Website Privacy Policy',
      component: <WebsitePolicy />,
    },
    {
      id: TalentPrivacyStatementLocation,
      label: 'Talent Privacy Statement',
      component: <TalentPolicy />,
    },
    {
      id: ClientTermsOfServiceLocation,
      label: 'Client Terms of Service Agreement',
      component: <ClientTermsOfService />,
    },
    {
      id: CodeOfConductLocation,
      label: 'Code of Conduct',
      component: <PlatformCodeOfConduct />,
    },
  ];

  const selectedTabData = useMemo(() => {
    return tabs.find((tab) => tab.id === match.path);
  }, [match.path, tabs]);

  return (
    <MainLayout title={'Privacy Policy'} style={{ padding: 0, paddingTop: 20 }}>
      <TabButtons>
        {tabs.map((tabButton) => (
          <TabButton
            onClick={(): void => {
              history.push(tabButton.id);
            }}
            active={match.path === tabButton.id}
            key={tabButton.id}
          >
            {tabButton.label}
          </TabButton>
        ))}
      </TabButtons>
      <div className={styles.tabContent}>
        {selectedTabData && selectedTabData.component}
      </div>
    </MainLayout>
  );
}
