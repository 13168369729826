import { TimesheetInitiativeId } from '@a_team/models/dist/TimesheetInitiativeObject';
import { TimesheetRecordType } from '@a_team/models/dist/TimesheetObject';
import Mission, { RoleRecord } from '@src/stores/Missions/Mission';
import {
  ColumnDef,
  RowData,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import cx from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { TimeEntry } from './TanstackTable';
import { minutesToTime } from './TimeInput';
import InitiativeTags from './common/InitiativeTags';
import Tag from './common/Tag';
import { allOptions, outOfOfficeTypes } from './data';
import { formatDate, isRowFilled } from './utils';
import EmptyCell from './common/EmptyCell';
import { useCommonStyles } from './common/commonStyles';
import SectionHeader from './common/SectionHeader';
import MissionRole from '@a_team/models/dist/MissionRole';
import TeamMember from '@src/layouts/Mission/NewMissionNavbar/TeamMember';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import UserTabs from './UserTabs';
import { SelectableUser } from './AdminTable';
import useGetPaymentAndHoursForGivenPeriod from '../hooks/useGetPaymentAndHoursForGivenPeriod';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import useGetMonthlyRetainerDataForCurrentUserRole from '../hooks/useGetMonthlyRetainerDataForCurrentUserRole';
import useGetGroupedTimeByDay from '../hooks/useGetGroupedTimeByDay';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    hourlyRate?: number;
  }
}

const useStyles = createUseStyles({
  disabledBanner: {
    margin: '3px 0 24px 0',
    borderRadius: 8,
    padding: 16,
    border: '1px solid #DADADC',
    backgroundColor: '#F7F7F7',
    fontSize: 14,
    lineHight: '19px',
    fontWeight: 500,
  },
});

interface TanstackTableProps {
  sid?: string;
  records?: RoleRecord[];
  currentMission?: Mission;
  teamTable?: boolean;
  allSelectableUsers?: SelectableUser[];
  setSelectedUser?: (user: SelectableUser | undefined) => void;
  selectedUser?: SelectableUser | undefined;
}

function TanstackTableReadOnly({
  sid,
  records,
  currentMission,
  teamTable,
  allSelectableUsers,
  setSelectedUser,
  selectedUser,
}: TanstackTableProps) {
  const { missions } = useStores();
  const commonStyles = useCommonStyles({ cellPadding: 16 });
  const styles = useStyles();

  const [data, setData] = React.useState<TimeEntry[]>([]);
  const { isFullTimeRetainer } = useGetMonthlyRetainerDataForCurrentUserRole();

  const timeEntriesMap = useGetGroupedTimeByDay(data, isFullTimeRetainer);

  const { startDate: paymentCycleStartDate, endDate: paymentCycleEndDate } =
    missions.currentMission?.selectedPaymentCycle?.data ?? {};
  const { fixedPaymentAmount, fixedHoursAmount } =
    useGetPaymentAndHoursForGivenPeriod(
      paymentCycleStartDate,
      paymentCycleEndDate,
      selectedUser?.role,
    );

  const columns = useMemo(() => {
    const allColumns: ColumnDef<TimeEntry>[] = [
      {
        accessorKey: 'date',
        header: 'Date',
        footer: 'Total',
        size: 140,
        maxSize: 140,
        minSize: 140,
        cell: (info) => {
          const rowIndex = info.table.getRowModel().rows.indexOf(info.row);
          const isFirstEntryOfDay =
            info.table.getRowModel().rows.length === 0 ||
            info.table.getRowModel().rows[rowIndex - 1]?.original.date !==
              info.row.original.date;

          if (!isFirstEntryOfDay) {
            return <div />;
          }
          const date = formatDate(info.row.original.date);
          const time = info.table
            .getRowModel()
            .rows.find((row) => row.original.key === info.row.original.key)
            ?.original.time;

          const timeEntry = info.table.options.meta?.timeEntriesMap?.get(
            info.row.original.date,
          );

          return (
            <div>
              <div style={{ color: time ? '#222222' : '#818388' }}>{date}</div>
              {timeEntry && timeEntry.totalRecordsAssociated > 1 && (
                <div style={{ marginTop: 4, color: '#818388' }}>
                  {timeEntry.totalFormattedTime}
                </div>
              )}
            </div>
          );
        },
      },
      {
        accessorKey: 'time',
        header: 'Time',
        footer: (info) => {
          if (info.table.options.meta?.isFullTimeRetainer) {
            return `${info.table.options.meta?.fixedHoursAmount}h`;
          } else {
            const total = info.table
              .getRowModel()
              .rows.reduce((acc, row) => acc + Number(row.original.time), 0);

            return minutesToTime(total);
          }
        },
        size: 100,
        maxSize: 100,
        minSize: 100,
        cell: ({ getValue, row: { id: rowKey }, column: { id }, table }) => {
          const cellValue = getValue() as number;

          const rowType = table
            .getRowModel()
            .rows.find((row) => row.original.key === rowKey)?.original.type;

          if (
            (rowType && outOfOfficeTypes.has(rowType)) ||
            cellValue === 0 ||
            table.options.meta?.isFullTimeRetainer
          ) {
            return <EmptyCell />;
          }

          return minutesToTime(cellValue);
        },
      },

      {
        accessorKey: 'type',
        header: 'Type',
        footer: ({ table }) => {
          if (teamTable) {
            return null;
          }
          if (table.options.meta?.isFullTimeRetainer) {
            return table.options.meta?.fixedPaymentAmount;
          } else {
            const hourlyRate = table.options.meta?.hourlyRate;

            const totalTime = table
              .getRowModel()
              .rows.reduce((acc, row) => acc + Number(row.original.time), 0);

            if (!hourlyRate) {
              return null;
            }

            const total = Math.round(hourlyRate * (totalTime / 60) * 100) / 100;

            return `$${total.toLocaleString()}`;
          }
        },
        size: 200,
        maxSize: 200,
        minSize: 200,
        cell: ({ getValue, row: { id: rowKey }, column: { id }, table }) => {
          const cellValue = getValue() as TimesheetRecordType;

          const type = allOptions.find((o) => o.label === cellValue);

          if (!type) {
            return <EmptyCell />;
          }

          return (
            <Tag
              title={type.label}
              background={type.background}
              color={type.color}
            />
          );
        },
      },
      {
        accessorKey: 'task',
        header: 'Task',
        size: 450,
        maxSize: 450,
        minSize: 450,
        cell: ({ getValue, row: { id: rowKey }, column: { id }, table }) => {
          const cellValue = getValue() as string;

          const value = table
            .getRowModel()
            .rows.find((row) => row.original.key === rowKey)?.original.type;

          if (value && outOfOfficeTypes.has(value)) {
            return <EmptyCell />;
          }

          if (!cellValue) {
            return <EmptyCell />;
          }

          return cellValue;
        },
      },
      {
        accessorKey: 'initiativeIds',
        header: 'Initiative',
        size: 300,
        maxSize: 300,
        minSize: 300,
        cell: ({ getValue, row: { id: rowKey }, column: { id }, table }) => {
          const initiativeIds = getValue() as TimesheetInitiativeId[];

          const type = table
            .getRowModel()
            .rows.find((row) => row.original.key === rowKey)?.original.type;

          if (
            (type && outOfOfficeTypes.has(type)) ||
            initiativeIds.length === 0
          ) {
            return <EmptyCell />;
          }

          return (
            <div>
              <InitiativeTags sid={sid} initiativeIds={initiativeIds} />
            </div>
          );
        },
      },
    ];

    if (!teamTable) {
      return allColumns;
    }

    const teamCol: ColumnDef<TimeEntry> = {
      accessorKey: 'role',
      header: 'Member',
      size: 100,
      maxSize: 100,
      minSize: 100,
      cell: ({ getValue, row }) => {
        const cellValue = getValue() as MissionRole;

        const isRowEmpty = !isRowFilled(row.original);

        if (!cellValue.user || isRowEmpty) {
          return <EmptyCell />;
        }

        return (
          <TeamMember
            user={cellValue.user as BasicUserObject}
            roleTitle={cellValue.category.title}
          />
        );
      },
    };

    allColumns.splice(2, 0, teamCol);

    return allColumns;
  }, [records]);

  useEffect(() => {
    setData(() => {
      return (
        records?.map((record) => {
          const entry: TimeEntry = {
            key: record.key,
            date: new Date(record.date).getTime(),
            time: record.minutes,
            type: record.type || null,
            task: record.details,
            initiativeIds: record.initiativeIds ?? [],
            role: record.role,
          };

          return entry;
        }) ?? []
      );
    });
  }, [records]);

  const table = useReactTable({
    data,
    columns,
    getRowId: (row) => row.key,
    getCoreRowModel: getCoreRowModel(),

    meta: {
      onUpdateMutation: () => ({}),
      updateData: () => ({}),
      removeRow: () => ({}),
      hourlyRate: currentMission?.currentUserRole?.hourlyRate,
      sid: selectedUser?.sid ?? '',
      isFullTimeRetainer,
      fixedHoursAmount,
      fixedPaymentAmount,
      timeEntriesMap,
    },
  });

  return (
    <div>
      <SectionHeader sid={sid} />

      <div className={commonStyles.container}>
        {!teamTable && (
          <div className={styles.disabledBanner}>
            The timesheet has been submitted. Further editing is disabled.
          </div>
        )}

        {allSelectableUsers && setSelectedUser && (
          <UserTabs
            selectedUser={selectedUser}
            allSelectableUsers={allSelectableUsers}
            setSelectedUser={setSelectedUser}
          />
        )}

        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{
                      width: header.getSize(),
                      maxWidth: header.getSize(),
                      minWidth: header.getSize(),
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, rowIndex) => {
              const isOutOfOfficeType =
                row.original.type && outOfOfficeTypes.has(row.original.type);

              return (
                <tr
                  className={cx({
                    [commonStyles.disabledBg]: isOutOfOfficeType,
                  })}
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => {
                    // Determine if this is a date cell
                    const isDateCell = cell.column.id === 'date';

                    // Check if it's not the first entry of the day
                    const isSubsequentEntryOfDay =
                      rowIndex > 0 &&
                      table.getRowModel().rows[rowIndex - 1].original.date ===
                        row.original.date;

                    // Choose the appropriate class name
                    const cellClassName =
                      isDateCell && isSubsequentEntryOfDay
                        ? commonStyles.emptyCell
                        : isDateCell
                        ? commonStyles.dateCell
                        : commonStyles.cell;

                    return (
                      <td key={cell.id} className={cx(cellClassName)}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>

          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => {
                  if (header.column.columnDef.footer === undefined) {
                    return null;
                  }
                  return (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext(),
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default observer(TanstackTableReadOnly);
