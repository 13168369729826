import React, { ButtonHTMLAttributes, ReactElement } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { Colors, ColorName } from '@ateams/components';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ColorName;
  highlight?: boolean;
}

const useStyles = createUseStyles({
  root: {
    border: 0,
    margin: 0,
    padding: 0,
    background: 'none',
    fontSize: '1em',
    fontFamily: 'inherit',
    fontWeight: (props: Props): string | number =>
      props.highlight ? 500 : 'inherit',
    color: (props: Props): string =>
      props.disabled
        ? '#aaa'
        : props.highlight
        ? Colors.primary
        : props.color
        ? Colors[props.color]
        : '#62646a',
    cursor: (props: Props): string =>
      props.disabled ? 'not-allowed' : 'pointer',
  },
});

export default function TextButton(props: Props): ReactElement {
  const { type, className, highlight, ...left } = props;
  const styles = useStyles(props);

  return (
    <button
      type={type || 'button'}
      className={cx(styles.root, className)}
      {...left}
    />
  );
}
