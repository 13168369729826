import React, { useEffect, useState } from 'react';
import EmptyView from './EmptyView';
import { useGenerateCustomQuestionRecommendation } from '@src/rq/missions';
import { isEqual } from 'lodash';
import Recommendation from './Recommendation';

interface AssistantProps {
  missionId: string;
  roleId: string;
  questionId: string;
  userSkills: string[];
  projectsCount: number;
}

const Assistant = (props: AssistantProps) => {
  const { missionId, roleId, questionId, projectsCount } = props;
  const [userSkills, setUserSkills] = useState<string[]>(props.userSkills);

  const {
    mutate: generateRecommendation,
    data: recommendation,
    isLoading,
    isError,
  } = useGenerateCustomQuestionRecommendation();

  // for non-primitive data types, mobx will always give a different instance even when they're same, this causes rendering, and unnecessary query firing, so we have to put this in state to avoid running query multiple times, such as on each key stroke in a text field
  useEffect(() => {
    if (!isEqual(props.userSkills, userSkills)) {
      setUserSkills(props.userSkills);
    }
  }, [props.userSkills]);

  useEffect(() => {
    // in order to save on GPT usages, we avoid if a user doesn't have at least one project or a matching role
    if (props.missionId === '' || props.roleId === '' || projectsCount < 1) {
      return;
    }
    generateRecommendation({
      mid: missionId,
      rid: roleId,
      data: {
        customSkills: userSkills,
        questionId,
      },
    });
  }, [userSkills, projectsCount, props.missionId, props.roleId]);

  if (isLoading || isError) {
    return <EmptyView isLoading={isLoading} />;
  }

  return <Recommendation text={recommendation?.response} />;
};

export default Assistant;
