import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
    '& ol li::marker': {
      fontWeight: 600,
    },
  },
});

const OverCommunicate = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <p>
        <strong>Over-Communicate:</strong> Communicate accurate information as
        much as possible with whoever might need to hear it. If you need help,
        raise your flag. If you’re taking time off, give notice. If something
        unexpected happens, tell the company and a BuildTeam contact as soon as
        possible, so there is no perception of “ghosting” (you can always email
        help@a.team). Communicate like you would like to be communicated with.
      </p>

      <ol type="1">
        <li>
          <div>
            <p>
              <strong>Methods of communication</strong>
            </p>
            <p>
              You should be in touch regularly with your team, company, and
              A.Team contacts. Figure out which tools folks prefer (email, zoom,
              slack, calling, etc.). Be available when you’ve agreed to be, and
              provide regular updates. Know what’s expected and share your
              expectations. This is a good opportunity to mention that you
              should 100% be ready with a high net bandwidths and as good of a
              video and audio setup as possible.
            </p>
          </div>
        </li>
        <li>
          <div>
            <p>
              <strong>Dealing with issues</strong>
            </p>
            <p>
              Your A.Team contacts are first and foremost here to help you, more
              than anything. If you run into issues, don’t shy away from
              confronting them by disappearing or avoiding communication. Reach
              out to us first and we can almost always find a way to help.
            </p>
          </div>
        </li>
        <li>
          <div>
            <p>
              <strong>If the company isn’t using your time</strong>
            </p>
            <p>
              If you were requested to reserve an hourly availability and no
              work is assigned to you, please tell the company and the BuildTeam
              so we can help!
            </p>
          </div>
        </li>
      </ol>
    </div>
  );
};

export default OverCommunicate;
