import Image from '@src/components/Image';
import PreviewImagesUnderneath from './PreviewImagesUnderneath';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';

interface HeaderProps {
  images: string[];
}

const useStyles = createUseStyles({
  container: {
    marginTop: 40,
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  displayedImage: {
    borderRadius: 16,
    objectFit: 'cover',
    width: '100%',
    maxHeight: 600,
  },
});

const Images = ({ images }: HeaderProps): JSX.Element | null => {
  const styles = useStyles();
  const [currentImage, setCurrentImage] = useState<string | null>(null);

  useEffect(() => {
    if (images.length > 0) {
      setCurrentImage(images[0]);
    } else {
      setCurrentImage(null);
    }
  }, [images]);

  if (!currentImage) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <Image
          aspectRatio={4 / 3}
          withPlaceholder
          className={styles.displayedImage}
          src={currentImage}
          alt="Project"
          sizes={`(min-width: ${Breakpoints.sm}) 800px, 90vw`}
        />
      </div>
      {images.length > 1 && (
        <PreviewImagesUnderneath
          images={images}
          currentImage={currentImage}
          setCurrentImage={setCurrentImage}
        />
      )}
    </div>
  );
};

export default Images;
