import { Breakpoints, Button, SelectOption } from '@ateams/components';
import {
  Checkbox,
  DatePicker,
  Input,
  Select,
  Textarea,
  theme,
  Typography,
} from '@a_team/ui-components';
import { useStores } from '@src/stores';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useReducer } from 'react';
import { createUseStyles } from 'react-jss';
import { EditingExperienceObject } from '../../types';
import Description from '../../partials/Description';
import Experience from '../../partials/Experience';
import Label from '../../partials/Label/Index';
import Title from '../../partials/Title';
import { EditExperienceActions, initialState, reducer } from './reducer';
import {
  getSelectedRole,
  hasChanges,
  hasError,
  toDate,
  getSkillsUsed,
  toPlaceholder,
  toRoleOptions,
  toSkillLabels,
  hasErrors,
} from './utils';
import { ExperienceType } from '@a_team/models/dist/ExperienceObject';
import HasManagedPeople from './HasManagedPeople';
import HasZeroToOneExperience from './HasZeroToOneExperience';
import DescriptionEditor from './Description';

interface EditSharedExperienceProps {
  editingExperience: EditingExperienceObject;
  sharedWith: string;
  onSave: (experience: EditingExperienceObject) => void;
  onCancel: () => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textAlign: 'left',
    padding: 0,
  },
  wrapper: {
    padding: '24px',
  },
  section: {
    marginTop: 24,
  },
  experience: {
    marginTop: 16,
    border: `1px solid ${theme.colors.Grey['400']}`,
    borderRadius: '8px',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0px',
    position: 'sticky',
    bottom: '0',
    background: '#fff',
    padding: '16px',
    borderTop: `1px solid ${theme.colors.Grey['400']}`,
  },
  button: {
    borderRadius: '4px',
    maxWidth: '75px',
    padding: 0,
  },
  duration: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '18px',
    position: 'relative',
  },
  calendar: {
    position: 'absolute',
    zIndex: 100,
  },
  divider: {
    width: '20px',
    height: '1px;',
    background: theme.colors.Grey['400'],
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    wrapper: {
      padding: '40px',
    },
  },
});

function EditSharedExperience(props: EditSharedExperienceProps) {
  const styles = useStyles();
  const { users, auth } = useStores();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { errors, experience } = state;

  useEffect(() => {
    if (users.profile?.queryRoles && props.editingExperience.eid) {
      users.profile?.queryRoles('').then((roles) =>
        dispatch({
          type: EditExperienceActions.INIT,
          payload: {
            roles,
            experience: {
              ...props.editingExperience,
              hasZeroToOneExperience: false,
              hasManagedPeople: false,
              numberOfPeopleManaged: '',
            },
          },
        }),
      );
    }
  }, [props.editingExperience.eid, users.profile?.queryRoles]);

  const userProfileSkills = useMemo(
    () =>
      [
        ...(auth.currentUser?.talentProfile?.talentSkills.mainTalentSkills ||
          []),
        ...(auth.currentUser?.talentProfile?.talentSkills
          .additionalTalentSkills || []),
      ].map((skill) => ({
        value: skill.talentSkillId,
        label: skill.talentSkillName,
      })),
    [auth.currentUser?.talentProfile],
  );

  const startDate = useMemo(
    () => toDate(experience.startDate),
    [experience.startDate],
  );
  const endDate = useMemo(
    () => toDate(experience.endDate),
    [experience.endDate],
  );
  const isProject = useMemo(
    () => experience.type === ExperienceType.Project,
    [experience.type],
  );

  const roles = useMemo(() => toRoleOptions(state.roles), [state.roles]);

  const saveExperience = () => props.onSave(state.experience);

  const renderRole = () => {
    if (isProject) {
      return (
        <>
          <Label text="Your role and title" />
          <Select
            value={getSelectedRole(experience)}
            placeholder={toPlaceholder(roles, 'Select role', experience)}
            styles={{
              control: (provided) => ({
                ...provided,
                borderColor: theme.colors.Grey['400'],
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 20000,
                color: theme.colors.Grey['800'],
              }),
            }}
            menuPortalTarget={document.body}
            // eslint-disable-next-line
            onChange={(role: any) => {
              dispatch({
                type: EditExperienceActions.EDIT_ROLE,
                payload: (role as SelectOption).value,
              });
            }}
            options={roles}
          />
          <Input
            placeholder={'Enter Title'}
            value={experience.jobRole || ''}
            size={'stretch'}
            style={{
              marginTop: 12,
              borderColor: theme.colors.Grey['400'],
            }}
            maxLength={200}
            onChange={(event) =>
              dispatch({
                type: EditExperienceActions.EDIT_JOB_ROLE,
                payload: event.target.value,
              })
            }
          />
        </>
      );
    }

    return (
      <>
        <Label text="Select title and specialization" />
        <Input
          placeholder={'Enter Title'}
          value={experience.jobRole || ''}
          size={'stretch'}
          style={{
            marginBottom: 12,
            borderColor: theme.colors.Grey['400'],
          }}
          maxLength={200}
          onChange={(event) =>
            dispatch({
              type: EditExperienceActions.EDIT_JOB_ROLE,
              payload: event.target.value,
            })
          }
        />
        <Select
          value={getSelectedRole(experience)}
          placeholder={toPlaceholder(
            roles,
            'Select a specialization',
            experience,
          )}
          styles={{
            control: (provided) => ({
              ...provided,
              borderColor: theme.colors.Grey['400'],
            }),
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 20000,
              color: theme.colors.Grey['800'],
            }),
          }}
          menuPortalTarget={document.body}
          // eslint-disable-next-line
          onChange={(role: any) => {
            dispatch({
              type: EditExperienceActions.EDIT_ROLE,
              payload: (role as SelectOption).value,
            });
          }}
          options={roles}
        />
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Title level="h5" text="Shared experiences" textAlign="left" />
        <Description
          align="left"
          text="You were added to this experience by a collaborator. Edit the information that applies to your role and contribution."
        />
        <Experience
          className={styles.experience}
          sharedWith={props.sharedWith}
          minimal
          experience={props.editingExperience}
        />
        {isProject && (
          <>
            <Label text="Project Description" />
            <Textarea
              style={{
                marginTop: 0,
                borderColor: theme.colors.Grey['400'],
                minHeight: '150px',
              }}
              size="stretch"
              error={hasError(errors, 'description')}
              errorText={errors?.description}
              value={experience.description}
              height={'150px'}
              onChange={(event) =>
                dispatch({
                  type: EditExperienceActions.EDIT_DESC,
                  payload: event.target.value,
                })
              }
              data-testing-id="experience-description"
            />
          </>
        )}
        {renderRole()}
        <Label text="Duration" />
        <div className={styles.duration}>
          <DatePicker
            key={startDate?.toISOString()}
            placeholder={'Start date'}
            defaultDate={startDate}
            style={{
              maxWidth: '171px',
              borderColor: theme.colors.Grey['400'],
            }}
            onChange={(date) =>
              dispatch({
                type: EditExperienceActions.EDIT_START_DATE,
                payload: date,
              })
            }
            calendarOptions={{
              maxDetail: 'year',
              className: styles.calendar,
            }}
            dateFormatter={(date) => format(date, 'MMM, yyyy')}
          />
          <div className={styles.divider} />
          <DatePicker
            key={endDate?.toISOString()}
            placeholder={'End date'}
            disabled={experience.isCurrent}
            defaultDate={endDate}
            style={{
              maxWidth: '171px',
              borderColor: theme.colors.Grey['400'],
            }}
            onChange={(date) =>
              dispatch({
                type: EditExperienceActions.EDIT_END_DATE,
                payload: date,
              })
            }
            calendarOptions={{
              maxDetail: 'year',
              className: styles.calendar,
            }}
            dateFormatter={(date) => format(date, 'MMM, yyyy')}
          />
        </div>
        <Checkbox
          onChange={(event) =>
            dispatch({
              type: EditExperienceActions.EDIT_IS_CURRENT,
              payload: event.target.checked,
            })
          }
          style={{ marginTop: 12 }}
          checked={experience.isCurrent}
          label={
            <Typography
              variant={'textMedium'}
              weight={'light'}
              style={{ marginLeft: 8, userSelect: 'none' }}
            >
              {isProject
                ? `I'm still working on this project`
                : 'I still work here'}
            </Typography>
          }
        />
        <div>
          <Label text="Skills used" optional />
          <Select
            alwaysShowCounter={false}
            isMulti={true}
            value={getSkillsUsed(experience, userProfileSkills)}
            placeholder={toSkillLabels(
              'Select skills',
              experience,
              userProfileSkills,
            )}
            onChange={(options: unknown) =>
              dispatch({
                type: EditExperienceActions.EDIT_SKILLS,
                payload: options as SelectOption[],
              })
            }
            options={userProfileSkills}
            styles={{
              control: (provided) => ({
                ...provided,
                borderColor: theme.colors.Grey['400'],
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 20000,
                color: theme.colors.Grey['800'],
              }),
            }}
            menuPlacement="top"
            menuPortalTarget={document.body}
          />
        </div>

        <div className={styles.section}>
          <DescriptionEditor
            value={experience.descriptionHTML ?? ''}
            onChange={(description, descriptionHTML) => {
              dispatch({
                type: EditExperienceActions.EDIT_DESCRIPTION,
                payload: { description, descriptionHTML },
              });
            }}
          />
        </div>

        <div className={styles.section}>
          <HasManagedPeople
            hasManagedPeople={!!experience.hasManagedPeople}
            numberOfPeopleManaged={experience.numberOfPeopleManaged ?? ''}
            onChange={(hasManagedPeople, numberOfPeopleManaged) => {
              dispatch({
                type: EditExperienceActions.EDIT_HAS_MANAGED_PEOPLE,
                payload: { hasManagedPeople, numberOfPeopleManaged },
              });
            }}
          />
        </div>

        <div className={styles.section}>
          <HasZeroToOneExperience
            value={!!experience.hasZeroToOneExperience}
            onChange={(value) => {
              dispatch({
                type: EditExperienceActions.EDIT_HAS_ZERO_TO_ONE_EXPERIENCE,
                payload: value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          className={styles.button}
          onClick={props.onCancel}
          size="small"
          color="regularLight"
          loading={false}
        >
          Back
        </Button>
        <Button
          disabled={!hasChanges(state) || hasErrors(state)}
          onClick={saveExperience}
          className={styles.button}
          size="small"
          color="secondaryDark"
          loading={false}
          data-testing-id="save-experience"
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default EditSharedExperience;
