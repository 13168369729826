import { useMutationSetOptOutBuilderDiscovery } from '@src/rq/profile';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React from 'react';
import Visibility from './Visibility';

const BuilderVisibility = (): JSX.Element => {
  const { auth } = useStores();

  const { mutate } = useMutationSetOptOutBuilderDiscovery({
    onSuccess: (optedOut) => {
      // makes sure that the state is updated after the request is sent to be in sync with the server
      auth.updateOptedOutOfBuilderDiscovery(optedOut);
    },
  });

  const handleToggle = () => {
    mutate({
      optOut: !auth.currentUser?.optedOutOfBuilderDiscovery,
    });
    // updates state immediately to avoid waiting for the request to finish
    auth.updateOptedOutOfBuilderDiscovery(
      !auth.currentUser?.optedOutOfBuilderDiscovery,
    );
  };

  return (
    <Visibility
      title="Builder Visibility"
      description="When enabled, your profile may appear as a recommended connection on the discovery page. You can choose to opt out of discovery by disabling the toggle."
      checked={!auth.currentUser?.optedOutOfBuilderDiscovery}
      handleToggle={handleToggle}
    />
  );
};

export default observer(BuilderVisibility);
