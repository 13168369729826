import { Icon, IconType } from '@ateams/components';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { observer } from 'mobx-react';
import React from 'react';
import TimeGuidance from './TimeGuidance';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  '@global': {
    '.tippy-tooltip': {
      padding: 0,
    },
  },
});

function TimeTooltip() {
  useStyles();

  return (
    <TooltipWrapped
      interactive
      theme={'light'}
      html={<TimeGuidance />}
      position="bottom"
      animation="fade"
      style={{ padding: 0 }}
    >
      <Icon
        type={IconType.Info}
        style={{ fontSize: 16 }}
        size="small"
        title=""
      />
    </TooltipWrapped>
  );
}

export default observer(TimeTooltip);
