import React from 'react';

export const EmailIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M1 4V12C1 13.1046 1.89543 14 3 14H13C14.1046 14 15 13.1046 15 12V4C15 2.89543 14.1046 2 13 2H3C1.89543 2 1 2.89543 1 4Z"
        stroke="#202020"
        strokeWidth="1.4"
      />
      <path
        opacity="0.4"
        d="M4 5L8 9L12 5"
        stroke="#202020"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
