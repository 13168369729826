import React, { ReactElement } from 'react';
import {
  ApplicantStatus,
  ExclusiveStatus,
  MissionApplicationInternalStatus,
  MissionApplicationInternalStatusLabels,
  MissionApplicationLowCompetitivenessReasonLabels,
} from '@a_team/models/dist/MissionApplicationObject';
import { ApplicationStatusAdminColors, Tag, TagList } from '@ateams/components';
import { MissionStatus } from '@a_team/models/dist/MissionObject';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';

interface Props {
  status: ApplicantStatus;
  missionStatus: MissionStatus;
  roleStatus: MissionRoleStatus;
  hired?: boolean;
}

export const OldApplicationStatusIndication = (
  props: Props,
): ReactElement | null => {
  const { status, missionStatus, roleStatus, hired = false } = props;
  const showRejectedTag =
    status.internalStatus === MissionApplicationInternalStatus.Rejected;
  const showInternalStatus =
    !showRejectedTag &&
    !status.proposalInterviewing &&
    status.internalStatus !== MissionApplicationInternalStatus.New &&
    status.internalStatus !== MissionApplicationInternalStatus.Interviewing &&
    status.internalStatus in MissionApplicationInternalStatus;
  const showMissionCancelledTag =
    (missionStatus === MissionStatus.Ended ||
      missionStatus === MissionStatus.ScheduledToEnd ||
      missionStatus === MissionStatus.Archived) &&
    roleStatus !== MissionRoleStatus.Canceled &&
    roleStatus !== MissionRoleStatus.ScheduledToEnd &&
    roleStatus !== MissionRoleStatus.Ended;
  const showRoleCancelledTag =
    !showMissionCancelledTag && roleStatus === MissionRoleStatus.Canceled;
  const showLowCompetitivenessTag =
    status.lowCompetitiveness && status.lowCompetitiveness.length > 0;

  return (
    <TagList style={{ marginTop: 8 }}>
      {hired && (
        <Tag thin color={ApplicationStatusAdminColors.ShortlistStrong}>
          {`Selected for Role`}
        </Tag>
      )}
      {status.exclusiveStatus && (
        <Tag
          thin
          color={
            status.exclusiveStatus === ExclusiveStatus.OnHold
              ? ApplicationStatusAdminColors.OnHold
              : ApplicationStatusAdminColors.Exclusive
          }
        >
          {status.exclusiveStatus === ExclusiveStatus.OnHold
            ? MissionApplicationInternalStatusLabels.OnHold
            : MissionApplicationInternalStatusLabels.Exclusive}
        </Tag>
      )}
      {status.proposedToClient && (
        <Tag thin color={ApplicationStatusAdminColors.Proposed}>
          {MissionApplicationInternalStatusLabels.Proposed}
        </Tag>
      )}
      {showRejectedTag && (
        <Tag thin color={ApplicationStatusAdminColors.Rejected}>
          {MissionApplicationInternalStatusLabels.Rejected}
        </Tag>
      )}
      {(status.proposalInterviewing ||
        status.internalStatus ===
          MissionApplicationInternalStatus.Interviewing) && (
        <Tag thin color={ApplicationStatusAdminColors.Interviewing}>
          {MissionApplicationInternalStatusLabels.Interviewing}
        </Tag>
      )}
      {showInternalStatus && (
        <>
          <Tag thin color={ApplicationStatusAdminColors[status.internalStatus]}>
            {MissionApplicationInternalStatusLabels[status.internalStatus]}
          </Tag>
        </>
      )}
      {showLowCompetitivenessTag &&
        status.lowCompetitiveness?.map((reason) => (
          <Tag
            key={reason}
            thin
            color={ApplicationStatusAdminColors.LowCompetitiveness}
          >
            Low competitiveness:&nbsp;
            {MissionApplicationLowCompetitivenessReasonLabels[reason]}
          </Tag>
        ))}
      {showMissionCancelledTag && (
        <Tag thin color={'regular'}>
          Mission Cancelled
        </Tag>
      )}
      {showRoleCancelledTag && (
        <Tag thin color={'regular'}>
          Role Cancelled
        </Tag>
      )}
    </TagList>
  );
};
