import { Checkbox, theme } from '@a_team/ui-components';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface MissionCodeOfConductChecklistProps {
  setPercentageMissionChecklistCompleted: (percentage: number) => void;
}

const useStyles = createUseStyles({
  container: {
    overflowY: 'auto',
  },
  checklist: { marginTop: 27 },
  checklistItem: {
    padding: 24,
    backgroundColor: `${theme.colors.Hannibal[100]}`,
    borderRadius: 16,
    marginBottom: 16,
  },
  checkbox: {
    display: 'flex',
    alignItems: 'start !important',

    '& > span:first-child': {
      marginTop: 2,
    },
    '& > span:last-child': {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: '19px',
    },
  },
});

const conductItems = [
  'I will accurately report hours worked, ensuring billed hours reflect actual work. For retainers, I will maintain accurate and transparent reporting.',
  'I understand subcontracting is prohibited without explicit permission.',
  'I will treat all mission-related information as confidential and will not share or use company data outside of the mission without permission.',
  'I will respond promptly to all communications to help the mission succeed and ensure smooth collaboration with the client and A.Team.',
];

const MissionCodeOfConductChecklist = ({
  setPercentageMissionChecklistCompleted,
}: MissionCodeOfConductChecklistProps): JSX.Element => {
  const styles = useStyles();
  const [checkedItems, setCheckedItems] = React.useState<boolean[]>(
    new Array(conductItems.length).fill(false),
  );

  const handleCheck = (index: number) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
    setPercentageMissionChecklistCompleted(
      (newCheckedItems.filter(Boolean).length / conductItems.length) * 100,
    );
  };

  return (
    <div className={styles.container}>
      <h2>Agree to the Mission Code of Conduct</h2>

      <p>
        By opting in, you're agreeing to follow A.Team's Mission Code of
        Conduct, which ensures a rewarding, safe, and productive experience for
        everyone on the platform. Here are some of the key points you're
        committing to:
      </p>
      <div className={styles.checklist}>
        {conductItems.map((item, index) => (
          <div key={index} className={styles.checklistItem}>
            <Checkbox
              checked={checkedItems[index]}
              onChange={() => handleCheck(index)}
              className={styles.checkbox}
              label={item}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MissionCodeOfConductChecklist;
