import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import {
  Breakpoints,
  Colors,
  Icon,
  IconType,
  TextColors,
} from '@ateams/components';
import cx from 'classnames';

interface Props {
  hasMinChars: boolean;
  hasReqChars: boolean;
  showError?: boolean;
}

const useStyles = createUseStyles({
  passwordReqs: {
    textAlign: 'left',
    fontSize: 14,
    lineHeight: '22px',
    paddingLeft: 0,
    color: '#62646A',
    listStylePosition: 'inside',
    '& .valid': {
      color: TextColors.successLight,
      listStyle: 'none',
    },
    '& .error': {
      color: Colors.danger,
    },
  },
  check: {
    marginRight: 8,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    passwordReqs: {
      fontSize: 12,
      color: '#fff',
      lineHeight: '16px',
    },
  },
});

export const PasswordRequirements = (props: Props): ReactElement => {
  const styles = useStyles();
  const { hasMinChars, hasReqChars, showError } = props;

  return (
    <ul className={styles.passwordReqs}>
      <li
        className={cx({
          valid: hasMinChars,
          error: !hasMinChars && showError,
        })}
      >
        {hasMinChars && (
          <Icon
            type={IconType.Check}
            size={'xsmall'}
            className={styles.check}
          />
        )}
        6 characters minimum
      </li>
      <li
        className={cx({
          valid: hasReqChars,
          error: !hasReqChars && showError,
        })}
      >
        {hasReqChars && (
          <Icon
            type={IconType.Check}
            size={'xsmall'}
            className={styles.check}
          />
        )}
        One uppercase or special character
      </li>
    </ul>
  );
};
