import {
  NarrativeConnectionSummary,
  UserCardObject,
} from '@a_team/models/dist/UserObject';
import React from 'react';
import { createUseStyles } from 'react-jss';
import UserAvatar from '../UserAvatar';
import Icons from './Icons';
import SeeProfileAndConnect from './SeeProfileAndConnect';
import SelectedStatus from './SelectedStatus';
import cx from 'classnames';
import { pluralize } from '@src/helpers/strings';

export enum DiscoveryCardType {
  Discovered = 'Discovered',
  Suggested = 'Suggested',
}

interface DiscoveryUserCardProps {
  type: DiscoveryCardType;
  suggestedUser: UserCardObject;
  roleDoesNotMatch?: boolean;
  isSelected?: boolean;
  onSelect?: (user: UserCardObject) => void;
  onUnselect?: (user: UserCardObject) => void;
  className?: string;
}

export const useStylesDiscoveryUserCard = createUseStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginBottom: '8px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: ({ isSelected }) =>
      `1px solid ${isSelected ? '#7000E3' : '#DADADC'}`,
    padding: '16px',
    width: '100%',
    maxWidth: '100%',
    background: 'white',
    borderRadius: '4px',
    cursor: ({ type, roleDoesNotMatch }) =>
      type === DiscoveryCardType.Suggested && !roleDoesNotMatch
        ? 'pointer'
        : 'default',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  roleDoesNotMatch: {
    color: '#818388',
    fontWeight: 500,
    fontSize: 14,
  },
  fullName: {
    fontWeight: 500,
    color: '#222222',
    fontSize: '15px',
  },
  mainRole: {
    color: '#222222',
    fontSize: '14px',
    marginBottom: '24px',
  },
  connectionInsightContainer: {
    color: '#62646A',
    marginBottom: ({ type }) =>
      type === DiscoveryCardType.Discovered ? '24px' : '0',
  },
  userAvatar: {
    display: 'flex',
    justifyContent: 'start',
  },
  connectionTypesContainer: {
    display: 'flex',
    gap: '4px',
    marginBottom: '8px',
  },
  connectionType: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    background: '#F7F7F7',
    borderRadius: '4px',
    '& img': {
      width: '16px',
      height: '16px',
    },
  },
});

const DiscoveryUserCard = ({
  type,
  suggestedUser,
  roleDoesNotMatch,
  isSelected,
  onSelect,
  onUnselect,
  className,
}: DiscoveryUserCardProps): JSX.Element => {
  const styles = useStylesDiscoveryUserCard({
    type,
    roleDoesNotMatch,
    isSelected,
  });

  const insightMessage = getInsightMessages(suggestedUser.narrativeConnection);

  const handleOnClick = () => {
    if (
      type === DiscoveryCardType.Suggested &&
      !roleDoesNotMatch &&
      isSelected !== undefined &&
      onSelect &&
      onUnselect
    ) {
      if (isSelected) {
        onUnselect(suggestedUser);
      } else {
        onSelect(suggestedUser);
      }
    }
  };

  return (
    <div className={cx(styles.container, className)} onClick={handleOnClick}>
      <div className={styles.content}>
        <div>
          <div className={styles.header}>
            <UserAvatar
              containerClassName={styles.userAvatar}
              src={suggestedUser.profilePictureURL}
            />
            {roleDoesNotMatch ? (
              <div className={styles.roleDoesNotMatch}>Role doesn't match</div>
            ) : (
              type === DiscoveryCardType.Suggested &&
              isSelected !== undefined && (
                <SelectedStatus isSelected={isSelected} />
              )
            )}
          </div>
          {type === DiscoveryCardType.Suggested ? (
            <a
              className={styles.fullName}
              href={`/${suggestedUser.username}`}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              {suggestedUser.fullName}
            </a>
          ) : (
            <div className={styles.fullName}>{suggestedUser.fullName}</div>
          )}

          <div className={styles.mainRole}>
            {
              suggestedUser?.talentProfile?.talentSpecializations
                ?.mainTalentSpecialization?.name
            }
          </div>
        </div>
        <div>
          <div className={styles.connectionTypesContainer}>
            <Icons types={suggestedUser.narrativeConnection?.types || []} />
          </div>
          <div className={styles.connectionInsightContainer}>
            {insightMessage}
          </div>
        </div>
      </div>
      <div className="btn-container">
        {type === DiscoveryCardType.Discovered && (
          <SeeProfileAndConnect suggestedUser={suggestedUser} />
        )}
      </div>
    </div>
  );
};

export default DiscoveryUserCard;

export function getInsightMessages(
  narrativeConnection: NarrativeConnectionSummary | undefined,
) {
  const defaultMessage =
    'This user is not in your network yet, but you can still recommend them.';

  if (!narrativeConnection) return defaultMessage;

  const { types, companies, projectsCount, mutualConnectionsCount } =
    narrativeConnection;

  const messages: string[] = [];
  const maxMessages = 2;
  let workedUsed = false;

  const conditions = [
    {
      check: () => types.includes('companies') && companies,
      message: () => {
        if (workedUsed) {
          return `at ${companies && companies[0]} together`;
        } else {
          workedUsed = true;
          return `worked at ${companies && companies[0]} together`;
        }
      },
    },
    {
      check: () => types.includes('missions'),
      message: () => {
        if (workedUsed) {
          return `on a mission together`;
        } else {
          workedUsed = true;
          return `worked on a mission together`;
        }
      },
    },
    {
      check: () => types.includes('projects') && projectsCount,
      message: () => {
        if (workedUsed) {
          return `on ${pluralize(projectsCount, 'project')} together`;
        } else {
          workedUsed = true;
          return `worked on ${pluralize(projectsCount, 'project')} together`;
        }
      },
    },
    {
      check: () =>
        types.includes('mutualConnections') && mutualConnectionsCount,
      message: () =>
        `have ${pluralize(mutualConnectionsCount, 'mutual connection')}`,
    },
    {
      check: () => types.includes('teams'),
      message: () => 'are part of the same team',
    },
    {
      check: () => types.includes('invitedBy'),
      message: () => `were referred by this person to A.Team`,
    },
    {
      check: () => types.includes('invitations'),
      message: () => 'referred this person to A.Team',
    },
    {
      check: () => types.includes('missionRecommendations'),
      message: () => 'were recommended by this person to a mission',
    },
    {
      check: () => types.includes('role'),
      message: () => 'Suggested based on Role',
    },
    {
      check: () => types.includes('location'),
      message: () => 'Suggested based on Location',
    },
    {
      check: () => types.includes('connections'),
      message: () => 'are connected',
    },
  ];

  for (const condition of conditions) {
    if (messages.length >= maxMessages) break;
    if (condition.check()) messages.push(condition.message());
  }

  const finalMessage = messages.slice(0, maxMessages).join(' and ');

  return finalMessage.length
    ? `${finalMessage.startsWith('Suggested') ? '' : 'You'} ${finalMessage}.`
    : defaultMessage;
}
