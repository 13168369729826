import { getData as getCountries } from 'country-list';
import { AddressInfo } from '@a_team/models/dist/BillingInfo';
import DropdownInput from '@src/components/Inputs/DropdownInput';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import React, { useEffect, useState } from 'react';
import { InvoiceAddress } from '@a_team/models/dist/InvoiceObject';

const initData = {};

// get list of countries listed alphabetically
const countries = getCountries().sort((a, b) => a.name.localeCompare(b.name));

const toPartialAddress = (
  address?: InvoiceAddress | AddressInfo | null,
): Partial<AddressInfo> =>
  address
    ? {
        ...address,
        postalCode: String(address.postalCode || '') || undefined,
        countryCode:
          (address as AddressInfo).countryCode ||
          (address as InvoiceAddress).country ||
          undefined,
      }
    : initData;

export const AdminBillingAddress: React.FC<{
  value?: InvoiceAddress | AddressInfo | null;
  onChange?(value: AddressInfo): void;
}> = ({ value, onChange }) => {
  const [initValue, setInitValue] = useState(() => toPartialAddress(value));
  const [data, setData] = useState<Partial<AddressInfo>>(initValue);

  const changed = data !== initValue;

  useEffect(() => {
    const newInit = toPartialAddress(value);

    setInitValue(newInit);
    setData(newInit);
  }, [value]);

  useEffect(() => {
    const isValid = !!(
      data.line1 &&
      data.city &&
      data.countryCode &&
      (data.countryCode !== 'US' || data.state) &&
      data.postalCode
    );

    if (changed && isValid && onChange) {
      onChange(data as AddressInfo);
    }
  }, [changed, data]);

  return (
    <div style={{ margin: '24px 0' }}>
      <OutlinedInput
        placeholder="Line 1"
        value={data.line1 ?? ''}
        error={changed && !data.line1}
        onChange={(e) =>
          setData({
            ...data,
            line1: e.target.value,
          })
        }
        margin="bottom"
      />
      <OutlinedInput
        placeholder="Line 2"
        value={data.line2 ?? ''}
        onChange={(e) =>
          setData({
            ...data,
            line2: e.target.value,
          })
        }
        margin="bottom"
      />
      <div style={{ display: 'flex' }}>
        <OutlinedInput
          placeholder="City"
          value={data.city ?? ''}
          error={changed && !data.city}
          onChange={(e) =>
            setData({
              ...data,
              city: e.target.value,
            })
          }
          margin="bottom"
        />
        <OutlinedInput
          placeholder="State"
          value={data.state ?? ''}
          error={changed && !data.state && data.countryCode === 'US'}
          onChange={(e) =>
            setData({
              ...data,
              state: e.target.value,
            })
          }
          style={{ marginLeft: 8 }}
          margin="bottom"
        />
      </div>
      <div style={{ display: 'flex' }}>
        <OutlinedInput
          placeholder="Postal Code"
          value={data.postalCode ?? ''}
          error={changed && !data.postalCode}
          onChange={(e) =>
            setData({
              ...data,
              postalCode: e.target.value.replace(/[^0-9]+/g, ''),
            })
          }
          margin="bottom"
        />
        <DropdownInput
          placeholder="Country"
          value={data.countryCode ?? ''}
          error={changed && !data.countryCode}
          onChange={(e) =>
            setData({
              ...data,
              countryCode: e.target.value,
            })
          }
          style={{ marginLeft: 8 }}
          margin="bottom"
        >
          {countries.map((country) => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </DropdownInput>
      </div>
    </div>
  );
};
