import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  VettingProcessAudit,
  VettingProcessAuditTypes,
} from '@a_team/models/dist/vetting-process-audit';
import { ActivityRecordMainText, ActivityRecordUser } from '.';

export interface BuilderCanceledActivityRecordProps {
  vettingProcessAudit: VettingProcessAudit<VettingProcessAuditTypes.BuilderCanceled>;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const BuilderCanceledActivityRecord: React.FC<
  BuilderCanceledActivityRecordProps
> = ({ vettingProcessAudit }) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <ActivityRecordMainText>{`Builder canceled interview ${
        vettingProcessAudit.payload.reason ? 'with reason: ' : ''
      } ${vettingProcessAudit.payload.reason || ''}`}</ActivityRecordMainText>

      {vettingProcessAudit.payload.canceledUser && (
        <ActivityRecordUser user={vettingProcessAudit.payload.canceledUser} />
      )}
    </div>
  );
};
