import React from 'react';
import FinalNotes from './FinalNotes';
import OurTeamBuildingProcess from './OurTeamBuildingProcess';
import OurTerminology from './OurTerminology';
import PlatformWalkthrough from './PlatformWalkthrough';
import SomeTips from './SomeTips';
import Welcome from './Welcome';

export const QuickOnboardingChecklist = (): JSX.Element => {
  return (
    <div>
      <h2>Quick Onboarding Checklist</h2>
      <Welcome />
      <OurTerminology />
      <OurTeamBuildingProcess />
      <PlatformWalkthrough />
      <SomeTips />
      <FinalNotes />
    </div>
  );
};

export default QuickOnboardingChecklist;
