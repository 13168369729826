import { ServiceAuth, ServiceEndpoint } from './utils';
import {
  SelectionTeamOnboardingPlan,
  SelectionTeamOnboardingPlanMilestone,
} from '@a_team/models/src/selection-team-onboarding';

export const BasePath = '/selection-team-onboarding';

export interface PutSelectionTeamOnboardingPlan {
  milestones: SelectionTeamOnboardingPlanMilestone[];
}

export default class SelectionTeamOnboardingEndpoint extends ServiceEndpoint {
  /**
   * Returns the current active selection-team-onboarding-plan
   * @param auth  - Admins
   */
  public fetchCurrentSelectionTeamOnboardingPlan(
    auth: ServiceAuth,
    abortController?: AbortController,
  ): Promise<SelectionTeamOnboardingPlan> {
    return this.fetch(
      auth,
      `${BasePath}/plan`,
      null,
      'get',
      undefined,
      abortController,
    );
  }

  /**
   * Replaces the current active selection-team-onboarding-plan
   * @param auth - Admins
   */
  public replaceSelectionTeamOnboardingPlan(
    auth: ServiceAuth,
    payload: PutSelectionTeamOnboardingPlan,
  ): Promise<SelectionTeamOnboardingPlan> {
    return this.fetch(auth, `${BasePath}/plan`, null, 'put', payload);
  }
}
