import React, { useMemo } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { components, MultiValueProps } from 'react-select';
import { theme } from '@a_team/ui-components';
import {
  BorderRadius,
  Colors,
  LocationAutocomplete as DefaultLocationAutocomplete,
  Select as DefaultSelect,
  SelectOption,
  SelectProps as DefaultSelectProps,
  Spacing,
  TextColors,
} from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import { CSSObject } from '@emotion/styled/macro';
import { UserSelector as DefaultUserSelector } from '@src/components/UserSelector';
import { VetterSelector as DefaultVetterSelector } from '@src/views/VettingDashboard/vetter-selector';
import { SkillSelector as DefaultSkillSelector } from '@src/components/SkillSelector';
import { TalentSkillSelector as DefaultTalentSkillSelector } from '@src/components/TalentSkillSelector';
import { TalentIndustrySelector as DefaultTalentIndustrySelector } from '@src/components/TalentIndustrySelector';
import { TalentCategorySelector as DefaultTalentCategorySelector } from '@src/components/TalentCategorySelector';
import { RoleSelector as DefaultRoleSelector } from '@src/views/VettingDashboard/role-selector';
import { InputContainer, InputContainerProps } from './input-container';
import { Size, Text } from './typography';

export const useSelectStyles = createUseStyles({
  select: {
    margin: 0,
    fontSize: '12px',
    fontWeight: 400,
    color: theme.colors.Grey[800],
    width: 'auto',
    border: 'none',
  },
  userSelectorFullNameLabel: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: theme.colors.Grey[800],
  },
  userSelectorEmailLabel: {
    fontSize: '10px',
    lineHeight: '14px',
    color: theme.colors.Grey[800],
  },
});

const getOverrideStyles = (color?: string, error?: boolean) => ({
  control: (provided: CSSObject): CSSObject => ({
    ...provided,
    minHeight: '30px',
    borderRadius: '4px',
    boxShadow: 'none',
    background: color,
    border: color
      ? `1px solid ${color}`
      : error
      ? `1px solid ${theme.colors.Red[600]}`
      : undefined,
  }),
  valueContainer: (provided: CSSObject): CSSObject => ({
    ...provided,
    minHeight: '30px',
    padding: '0 0 0 5.5px',
    flexWrap: 'nowrap',
  }),
  input: (provided: CSSObject): CSSObject => ({
    ...provided,
    fontSize: '12px',
    fontWeight: 400,
    color: theme.colors.Grey[800],
    margin: 0,
  }),
  menu: ({ width, ...provided }: CSSObject): CSSObject => ({
    ...provided,
    width: 'max-content',
    minWidth: '100%',
    zIndex: 100,
  }),
  singleValue: (provided: CSSObject): CSSObject => ({
    ...provided,
    color: theme.colors.Grey[800],
    position: 'relative',
    transform: 'initial',
    maxWidth: '100%',
    paddingRight: '8px',
  }),
  placeholder: (provided: CSSObject): CSSObject => ({
    ...provided,
    position: 'relative',
    transform: 'initial',
  }),
  dropdownIndicator: (provided: CSSObject): CSSObject => ({
    ...provided,
    color: 'white',
  }),
});

const useSelectValueWithCountContainerStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  count: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: TextColors.primary,
    background: Colors.success,
    borderRadius: '12px',
    height: '16px',
    width: '24px',
    marginLeft: '8px',
  },
});

export const SelectValueWithCountContainer: React.FC<
  MultiValueProps<SelectOption>
> = (props) => {
  const { children, ...rest } = props;
  const styles = useSelectValueWithCountContainerStyles();
  const selectedCount = props.getValue()?.length;

  if (!selectedCount) {
    return <components.ValueContainer {...props} />;
  }

  /**
   * This is important to preserve the react-select functionality
   * The first element in that array are <components.MultiValue> and there is one for each element selected but we don't want to display them
   */
  const inputChild = Array.isArray(children) && children[1];

  return (
    <components.ValueContainer {...rest}>
      <div className={styles.container}>
        <span>Selected</span>
        <div className={styles.count}>{selectedCount}</div>
      </div>
      {inputChild}
    </components.ValueContainer>
  );
};

export interface SelectProps
  extends Omit<DefaultSelectProps, 'error'>,
    InputContainerProps {
  inputContainerClassName?: string;
  isReadOnly?: boolean;
}

const useSelectReadOnlyValueStyles = createUseStyles({
  container: ({ color }: { color?: string }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: BorderRadius.default,
    paddingLeft: color ? '10px' : 0,
    paddingRight: color ? '10px' : 0,
    backgroundColor: color ? color : 'auto',
    height: color ? '32px' : '20px',
  }),
  avatar: {
    marginRight: Spacing.small,
  },
});

const SelectReadOnlyValue: React.FC<{
  label?: string;
  color?: string;
  className?: string;
}> = ({ label, color, className }) => {
  const styles = useSelectReadOnlyValueStyles({ color });

  return (
    <Text size={Size.Small} className={cx(styles.container, className)}>
      {label || '--'}
    </Text>
  );
};

const SelectWithAvatarReadOnlyValue: React.FC<{
  label?: string;
  color?: string;
  avatarSrc?: string;
  avatarSize?: number;
}> = ({ label, color, avatarSrc, avatarSize = 20 }) => {
  const styles = useSelectReadOnlyValueStyles({ color });

  return (
    <div className={styles.container}>
      {avatarSrc && (
        <UserAvatar
          src={avatarSrc}
          size={avatarSize}
          containerClassName={styles.avatar}
        />
      )}
      <SelectReadOnlyValue label={label} color={color} />
    </div>
  );
};

export const Select: React.FC<SelectProps> = (props) => {
  const {
    color,
    label,
    labelSize,
    error,
    inputContainerClassName,
    isReadOnly,
    ...rest
  } = props;
  const selectStyles = useSelectStyles();

  const overrideStyles = useMemo(
    () => ({
      ...getOverrideStyles(color, Boolean(error)),
      ...props.overrideStyles,
    }),
    [props.overrideStyles, color, error],
  );

  return (
    <InputContainer
      label={label}
      labelSize={labelSize}
      error={typeof error === 'string' ? error : undefined}
      className={inputContainerClassName}
    >
      {isReadOnly ? (
        <SelectReadOnlyValue
          label={props.value?.label || props.defaultValue?.label}
          color={color}
          className={props.className}
        />
      ) : (
        <DefaultSelect
          {...rest}
          className={cx(selectStyles.select, props.className)}
          overrideStyles={overrideStyles}
        />
      )}
    </InputContainer>
  );
};

export const UserSelector: typeof DefaultUserSelector = (props) => {
  const { color, label, error, inputContainerClassName, isReadOnly, ...rest } =
    props;
  const selectStyles = useSelectStyles();

  const overrideStyles = useMemo(
    () => ({
      ...getOverrideStyles(color, Boolean(error)),
      ...props.overrideStyles,
    }),
    [props.overrideStyles, color, error],
  );

  const readOnlyAvatarSrc =
    props.value?.user?.profilePictureURL ||
    (typeof props.defaultValue === 'object'
      ? props.defaultValue.profilePictureURL
      : undefined);
  const readOnlyLabel =
    props.value?.label ||
    (typeof props.defaultValue === 'object'
      ? props.defaultValue.fullName
      : undefined);

  return (
    <InputContainer
      label={label}
      error={typeof error === 'string' ? error : undefined}
      className={inputContainerClassName}
    >
      {isReadOnly ? (
        <SelectWithAvatarReadOnlyValue
          avatarSrc={readOnlyAvatarSrc}
          label={readOnlyLabel}
        />
      ) : (
        <DefaultUserSelector
          {...rest}
          className={cx(selectStyles.select, props.className)}
          overrideStyles={overrideStyles}
          userOptionsProps={{
            avatarSize: 20,
            fullNameLabelClassName: selectStyles.userSelectorFullNameLabel,
            emailLabelClassName: selectStyles.userSelectorEmailLabel,
          }}
          userSingleValueProps={{
            avatarSize: 20,
            fullNameLabelClassName: selectStyles.userSelectorFullNameLabel,
          }}
        />
      )}
    </InputContainer>
  );
};

export const VetterSelector: typeof DefaultVetterSelector = (props) => {
  const { color, label, error, inputContainerClassName, isReadOnly, ...rest } =
    props;
  const selectStyles = useSelectStyles();

  const overrideStyles = useMemo(
    () => ({
      ...getOverrideStyles(color, Boolean(error)),
      ...props.overrideStyles,
    }),
    [props.overrideStyles, color, error],
  );

  const readOnlyAvatarSrc =
    props.value?.user.profilePictureURL ||
    (typeof props.defaultValue === 'object'
      ? props.defaultValue.user.profilePictureURL
      : undefined);
  const readOnlyLabel =
    props.value?.user.fullName ||
    (typeof props.defaultValue === 'object'
      ? props.defaultValue.user.fullName
      : undefined);

  return (
    <InputContainer
      label={label}
      error={typeof error === 'string' ? error : undefined}
      className={inputContainerClassName}
    >
      {isReadOnly ? (
        <SelectWithAvatarReadOnlyValue
          avatarSrc={readOnlyAvatarSrc}
          label={readOnlyLabel}
        />
      ) : (
        <DefaultVetterSelector
          {...rest}
          className={cx(selectStyles.select, props.className)}
          overrideStyles={overrideStyles}
          vetterOptionsProps={{
            avatarSize: 20,
            fullNameLabelClassName: selectStyles.userSelectorFullNameLabel,
            vetterTypeLabelClassName: selectStyles.userSelectorEmailLabel,
          }}
          vetterSingleValueProps={{
            avatarSize: 20,
            fullNameLabelClassName: selectStyles.userSelectorFullNameLabel,
          }}
        />
      )}
    </InputContainer>
  );
};

export const SkillSelector: typeof DefaultSkillSelector = (props) => {
  const { color, label, error, inputContainerClassName, ...rest } = props;
  const selectStyles = useSelectStyles();

  const overrideStyles = useMemo(
    () => ({
      ...getOverrideStyles(color, Boolean(error)),
      ...props.overrideStyles,
    }),
    [props.overrideStyles, color, error],
  );

  return (
    <InputContainer
      label={label}
      error={typeof error === 'string' ? error : undefined}
      className={inputContainerClassName}
    >
      <DefaultSkillSelector
        {...rest}
        className={cx(selectStyles.select, props.className)}
        overrideStyles={overrideStyles}
      />
    </InputContainer>
  );
};

export const TalentSkillSelector: typeof DefaultTalentSkillSelector = (
  props,
) => {
  const { color, label, error, inputContainerClassName, isReadOnly, ...rest } =
    props;
  const selectStyles = useSelectStyles();

  const overrideStyles = useMemo(
    () => ({
      ...getOverrideStyles(color, Boolean(error)),
      ...props.overrideStyles,
    }),
    [props.overrideStyles, color, error],
  );

  const getReadOnlyValue = () => {
    const value = props.value || props.defaultValue;
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      return value.label;
    }
  };

  const readOnlyLabel = getReadOnlyValue();

  return (
    <InputContainer
      label={label}
      error={typeof error === 'string' ? error : undefined}
      className={inputContainerClassName}
    >
      {isReadOnly ? (
        <SelectReadOnlyValue label={readOnlyLabel} />
      ) : (
        <DefaultTalentSkillSelector
          {...rest}
          className={cx(selectStyles.select, props.className)}
          overrideStyles={overrideStyles}
        />
      )}
    </InputContainer>
  );
};

export const TalentIndustrySelector: typeof DefaultTalentIndustrySelector = (
  props,
) => {
  const { color, label, error, inputContainerClassName, ...rest } = props;
  const selectStyles = useSelectStyles();

  const overrideStyles = useMemo(
    () => ({
      ...getOverrideStyles(color, Boolean(error)),
      ...props.overrideStyles,
    }),
    [props.overrideStyles, color, error],
  );

  return (
    <InputContainer
      label={label}
      error={typeof error === 'string' ? error : undefined}
      className={inputContainerClassName}
    >
      <DefaultTalentIndustrySelector
        {...rest}
        className={cx(selectStyles.select, props.className)}
        overrideStyles={overrideStyles}
      />
    </InputContainer>
  );
};

export const TalentCategorySelector: typeof DefaultTalentCategorySelector = (
  props,
) => {
  const {
    color,
    label,
    labelSize,
    error,
    inputContainerClassName,
    isReadOnly,
    ...rest
  } = props;
  const selectStyles = useSelectStyles();

  const overrideStyles = useMemo(
    () => ({
      ...getOverrideStyles(color, Boolean(error)),
      ...props.overrideStyles,
    }),
    [props.overrideStyles, color, error],
  );

  const getReadOnlyValue = () => {
    const value = props.value || props.defaultValue;
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      return value.label;
    }
  };

  const readOnlyLabel = getReadOnlyValue();

  return (
    <InputContainer
      label={label}
      labelSize={labelSize}
      error={typeof error === 'string' ? error : undefined}
      className={inputContainerClassName}
    >
      {isReadOnly ? (
        <SelectReadOnlyValue label={readOnlyLabel} />
      ) : (
        <DefaultTalentCategorySelector
          {...rest}
          className={cx(selectStyles.select, props.className)}
          overrideStyles={overrideStyles}
        />
      )}
    </InputContainer>
  );
};

export const RoleSelector: typeof DefaultRoleSelector = (props) => {
  const { color, label, labelSize, error, inputContainerClassName, ...rest } =
    props;
  const selectStyles = useSelectStyles();

  const overrideStyles = useMemo(
    () => ({
      ...getOverrideStyles(color, Boolean(error)),
      ...props.overrideStyles,
    }),
    [props.overrideStyles, color, error],
  );

  return (
    <InputContainer
      label={label}
      labelSize={labelSize}
      error={typeof error === 'string' ? error : undefined}
      className={inputContainerClassName}
    >
      <DefaultRoleSelector
        {...rest}
        className={cx(selectStyles.select, props.className)}
        overrideStyles={overrideStyles}
        isDisabled={props.isDisabled}
      />
    </InputContainer>
  );
};

export const LocationAutocomplete: typeof DefaultLocationAutocomplete = (
  props,
) => {
  const { color, label, error, inputContainerClassName, isReadOnly, ...rest } =
    props;
  const selectStyles = useSelectStyles();

  const overrideStyles = useMemo(
    () => ({
      ...getOverrideStyles(color, Boolean(error)),
      ...props.overrideStyles,
    }),
    [props.overrideStyles, color, error],
  );

  return (
    <InputContainer
      label={label}
      error={typeof error === 'string' ? error : undefined}
      className={inputContainerClassName}
    >
      {isReadOnly ? (
        <SelectReadOnlyValue label={props.defaultInputValue} />
      ) : (
        <DefaultLocationAutocomplete
          {...rest}
          className={cx(selectStyles.select, props.className)}
          overrideStyles={overrideStyles}
        />
      )}
    </InputContainer>
  );
};
