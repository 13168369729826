import { Colors } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Body from './Body';
import HideScrollerGlobally from '../HideScrollerGlobally';

interface NotificationMobileSliderProps {
  isOpen: boolean | undefined;
  toggle: () => void;
}

const MOBILE_TOP_NAV_HEIGHT = 50;
const TRANSITION_DURATION = 300;

const useStyles = createUseStyles({
  navigation: {
    position: 'fixed',
    top: (props: { isOpen: boolean }) =>
      props.isOpen ? `${MOBILE_TOP_NAV_HEIGHT}px` : `-${100}%`,
    left: 0,
    height: `calc(100% - ${MOBILE_TOP_NAV_HEIGHT}px)`,
    width: '100%',
    background: Colors.backgroundWhite,
    transition: `top ${TRANSITION_DURATION / 1000}s ease-in-out`,
    zIndex: 1001,
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
});

const NotificationMobileSlider = ({
  isOpen,
  toggle,
}: NotificationMobileSliderProps) => {
  const styles = useStyles({ isOpen });

  return (
    <div className={styles.navigation}>
      {isOpen && <HideScrollerGlobally />}
      <Body toggle={toggle} />
    </div>
  );
};

export default NotificationMobileSlider;
