import React, { ReactElement } from 'react';
import cx from 'classnames';
import { Icon as UiIcon } from '@a_team/ui-components';
import TextInput from '@src/components/Inputs/TextInput/TextInput';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  hourlyRate: {
    alignItems: 'center',
    display: 'flex',
    gap: 8,
  },
  hide: {
    display: 'none',
  },
  label: {
    display: 'flex',
    gap: 8,
    marginBottom: 8,
    alignItems: 'center',
  },
});

export const HourlyRate = ({
  hideRate,
  hourlyRate,
  setRate,
}: {
  hideRate: boolean;
  hourlyRate?: number;
  setRate: (rate: string) => void;
}): ReactElement => {
  const styles = useStyles();
  return (
    <div className={cx(styles.hourlyRate, hideRate && styles.hide)}>
      <label
        htmlFor="clientRate"
        className={styles.label}
        style={{ marginBottom: 0 }}
      >
        <UiIcon size="md" name="money" />
        Client Rate: $
      </label>
      <TextInput
        style={{ width: 35, padding: '0 4px' }}
        variant={'dashed'}
        id={'clientRate'}
        placeholder="Set rate"
        value={hourlyRate}
        onChange={(e) => setRate(e.target.value)}
      />
      <span>/h</span>
    </div>
  );
};
