import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { useStores } from '@src/stores';
import { RootLocation } from '@src/locations';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import { createUseStyles } from 'react-jss';
import {
  FontSizes,
  FontWeights,
  Spacing,
  ToggleSwitch,
} from '@ateams/components';
import qs from 'query-string';
import DropdownInput from '@src/components/Inputs/DropdownInput';
import { enumKeys } from '@src/helpers/types';
import { PausePeriod } from '@src/stores/NotificationPreferencesUser';
import { PreferencesDtoDisabledCategoriesEnum } from '@a_team/user-notification-service-js-sdk';

interface Props {
  location: Location;
}

const useStyles = createUseStyles({
  title: {
    fontSize: FontSizes.extraLarge,
    fontWeight: FontWeights.semiBold,
  },
  subTitle: {
    fontSize: FontSizes.regular,
    marginTop: Spacing.small,
    paddingBottom: Spacing.xxxLarge,
  },
  header: {
    fontSize: FontSizes.regular,
    marginBottom: Spacing.medium,
  },
  section: {
    marginBottom: Spacing.xLarge,
  },
  row: {
    marginBottom: Spacing.small,
  },
  endDate: {
    bottom: -22,
    position: 'absolute',
    fontSize: 11,
    width: '100%',
    textAlign: 'center',
  },
  select: {
    '& > div': {
      padding: '0 16px',
      minHeight: 35,
    },
  },
  switch: {
    minWidth: 45,
  },
});

const NotificationPreferences = (props: Props) => {
  const styles = useStyles();
  const { notificationPreferencesUser, auth } = useStores();
  const {
    preferences,
    currentActivePausePeriod,
    formatedEndDate,
    allCategoriesDisabled,
    allCategoriesInUse,
    unsubscribeButtonsDisabled,
    missionRecommendationEnabled,
    availabilityReminderNotificationsEnabled,
    confirmAvailability,
    companyMarketingNotificationsEnabled,
  } = notificationPreferencesUser;
  const [loading, setLoading] = useLoadingState();
  const query = useMemo(
    () => qs.parse(props.location.search),
    [props.location.search],
  );

  useEffect(() => {
    query.linkid === 'confirm_availability' &&
      notificationPreferencesUser.dataLoaded &&
      setLoading(
        confirmAvailability(),
        'Your availability settings were confirmed',
      );
  }, [query.linkid, notificationPreferencesUser.dataLoaded]);

  const isBlockedForEmail = useMemo(() => {
    return auth.user?.emailNotificationsBlocked;
  }, [auth.user?.emailNotificationsBlocked]);

  if (notificationPreferencesUser.invalidToken) {
    return <Redirect to={RootLocation} />;
  }

  const onCategoryChange = (category: PreferencesDtoDisabledCategoriesEnum) => {
    const exists = preferences.disabledCategories?.includes(category);
    const newDisabledCategories = exists
      ? preferences.disabledCategories?.filter(
          (disabledcategory) => disabledcategory !== category,
        )
      : [...(preferences.disabledCategories || []), category];
    setLoading(
      notificationPreferencesUser.updateNotificationPreferences(
        Object.assign({}, preferences, {
          disabledCategories: newDisabledCategories,
        }),
      ),
    );
  };

  const onUnsubscribeAllChange = () => {
    setLoading(
      notificationPreferencesUser.updateNotificationPreferences(
        Object.assign({}, preferences, {
          disabledCategories: allCategoriesDisabled ? [] : allCategoriesInUse,
        }),
      ),
    );
  };

  return (
    <div>
      <div className={styles.title}>Notification preferences</div>
      <div className={styles.subTitle}>
        Manage your email notification preferences.
      </div>
      <h3 className={styles.header}>A.Team mission reach outs</h3>
      <div className={styles.section}>
        <div className={styles.row}>
          <ToggleSwitch
            className={styles.switch}
            disabled={
              unsubscribeButtonsDisabled || !!loading || isBlockedForEmail
            }
            size={'small'}
            checked={missionRecommendationEnabled}
            onChange={() =>
              onCategoryChange(
                PreferencesDtoDisabledCategoriesEnum.MissionNotification,
              )
            }
            label={`Include me in targeted mission reach outs from the formation team. This does not include all recommended missions.`}
          />
        </div>
      </div>
      <h3 className={styles.header}>Availability reminders</h3>
      <div className={styles.section}>
        <div className={styles.row}>
          <ToggleSwitch
            className={styles.switch}
            color="purple"
            disabled={
              unsubscribeButtonsDisabled || !!loading || isBlockedForEmail
            }
            size={'small'}
            checked={availabilityReminderNotificationsEnabled}
            onChange={() =>
              onCategoryChange(
                PreferencesDtoDisabledCategoriesEnum.AvailabilityReminder,
              )
            }
            label={'I want to receive availability reminder emails'}
          />
        </div>
      </div>

      <h3 className={styles.header}>Community & Product Updates</h3>
      <div className={styles.section}>
        <div className={styles.row}>
          <ToggleSwitch
            className={styles.switch}
            color="purple"
            disabled={
              unsubscribeButtonsDisabled || !!loading || isBlockedForEmail
            }
            size={'small'}
            checked={companyMarketingNotificationsEnabled}
            onChange={() =>
              onCategoryChange(
                PreferencesDtoDisabledCategoriesEnum.CompanyMarketing,
              )
            }
            label={
              'I want to receive emails about A.Team community and product initiatives. This includes event invites, user experience surveys and updates about new features.'
            }
          />
        </div>
      </div>

      <h3 className={styles.header}>Pause notifications</h3>
      <div className={styles.section}>
        <ToggleSwitch
          className={styles.switch}
          color="purple"
          size={'small'}
          checked={!!currentActivePausePeriod}
          onChange={(value) =>
            notificationPreferencesUser.updatePauseNotifications(value)
          }
          disabled={
            !currentActivePausePeriod &&
            (!!allCategoriesDisabled || !!loading || isBlockedForEmail)
          }
          label={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ flexShrink: 0, marginRight: 8 }}>
                Pause notifications for
              </span>
              <div style={{ position: 'relative' }}>
                <DropdownInput
                  disabled={!currentActivePausePeriod}
                  margin={'none'}
                  onChange={(e) =>
                    notificationPreferencesUser.updatePauseNotificationsPeriod(
                      e.target.value as PausePeriod,
                    )
                  }
                  value={currentActivePausePeriod}
                  className={styles.select}
                  background={'transparent'}
                >
                  {enumKeys(PausePeriod).map((key) => (
                    <option key={key}>{PausePeriod[key]}</option>
                  ))}
                </DropdownInput>
                {currentActivePausePeriod && (
                  <span className={styles.endDate}>
                    {`Ends ${formatedEndDate}`}
                  </span>
                )}
              </div>
            </span>
          }
        />
      </div>
      <div className={styles.section}>
        <ToggleSwitch
          className={styles.switch}
          color="purple"
          disabled={
            unsubscribeButtonsDisabled || !!loading || isBlockedForEmail
          }
          size={'small'}
          checked={allCategoriesDisabled}
          onChange={() => onUnsubscribeAllChange()}
          label={'Unsubscribe from all platform emails *'}
        />
        <p>
          * You will still receive emails related to your applications,
          confirmed missions and active vetting processes
        </p>
      </div>
      <LoadingIndicator loading={loading} />
    </div>
  );
};

export default observer(NotificationPreferences);
