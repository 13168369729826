import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';

export const useStrongPassword = () => {
  const loadOptions = async () => {
    const zxcvbnCommonPackage = await import(
      /* webpackChunkName: "zxcvbnCommonPackage" */ '@zxcvbn-ts/language-common'
    );
    const zxcvbnEnPackage = await import(
      /* webpackChunkName: "zxcvbnEnPackage" */ '@zxcvbn-ts/language-en'
    );

    return {
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
      },
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      translations: zxcvbnEnPackage.translations,
    };
  };

  const checkPasswordStrength = async (password: string) => {
    const options = await loadOptions();
    zxcvbnOptions.setOptions(options);
    return zxcvbn(password);
  };

  return { checkPasswordStrength };
};
