import { Colors, TextColors } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { Classes } from 'jss';

export interface StyleProps {
  variant?: 'dashed';
}

const useStyles: (data: StyleProps) => Classes = createUseStyles({
  readonly: {
    backgroundColor: 'transparent !important',
    borderColor: 'transparent !important',
    textDecorationColor: 'transparent !important',
    '&::placeholder': {
      textDecorationColor: 'transparent !important',
    },
    '&:disabled': {
      color: `${TextColors.regular} !important`,
      '-webkit-text-fill-color': TextColors.regular,
      opacity: 1,
    },
  },
  input: {
    width: '100%',
    border: 'none',
    margin: 0,
    color: TextColors.regular,
    '&::placeholder': {
      color: TextColors.placeholderRegular,
      verticalAlign: 'middle',
    },
    fontWeight: ({ variant }: StyleProps) =>
      variant === 'dashed' ? '600' : null,
    borderBottom: ({ variant }: StyleProps) =>
      variant === 'dashed' ? `1px dashed ${Colors.regular}` : null,
    background: ({ variant }: StyleProps) =>
      variant === 'dashed' ? 'none' : 'auto',
  },
  error: {
    '&::placeholder': {
      color: Colors.danger,
    },
  },
});

export default useStyles;
