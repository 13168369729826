import React from 'react';
import dots from './dots.svg';
import { createUseStyles } from 'react-jss';
import UserAvatar from '@src/components/UserAvatar';
import { useStores } from '@src/stores';

const useStyles = createUseStyles({
  wrapper: {
    marginTop: 0,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: 176,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 24,
  },
  dots: {
    height: 120,
    width: 120,
    aspectRatio: '1/1',
  },
});

function SkipModalHeaderImage() {
  const styles = useStyles();
  const stores = useStores();
  const { auth } = stores;

  if (!auth.user?.profilePictureURL) return null;

  return (
    <div className={styles.wrapper}>
      <img src={dots} alt="Connection confirmed" className={styles.dots} />
      <UserAvatar
        src={auth.user.profilePictureURL}
        containerStyle={{
          position: 'absolute',
          borderRadius: '50%',
          aspectRatio: '1/1',
          top: 33,
          right: 60,
          height: 56,
          width: 56,
        }}
        size={56}
      />
    </div>
  );
}

export default SkipModalHeaderImage;
