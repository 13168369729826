import React, { ReactElement, useState } from 'react';
import { isImageUrl } from '@src/helpers/strings';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { Button as CallToActionButton } from '@ateams/components';
import { FileSelectionBoxProps } from './FileSelectionBox';
import { createUseStyles } from 'react-jss';
import { ERROR_MAP } from '@src/components/FileUploader';
import { Colors } from '@ateams/components';

const useStyles = createUseStyles({
  tabInner: {
    padding: 32,
  },
  button: {
    marginTop: 25,
    display: 'block',
    margin: '0 auto',
  },
  error: {
    color: Colors.danger,
    display: 'block',
    fontSize: 12,
    marginTop: -14,
  },
});

export const LinkTab = (props: FileSelectionBoxProps): ReactElement => {
  const [urlToUpload, setUrlToUpload] = useState<string>();
  const [urlError, setUrlError] = useState(false);
  const styles = useStyles();

  const handleUrlSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    if (!urlToUpload) {
      return;
    }

    if (!isImageUrl(urlToUpload)) {
      setUrlError(true);
      return;
    }

    props.uploadFromUrl(urlToUpload);
  };

  return (
    <div className={styles.tabInner}>
      <p>An image 1600x1200 or larger recommended, up to 10Mb.</p>
      <OutlinedInput
        value={urlToUpload}
        placeholder={'http://'}
        error={urlError || false}
        onChange={(e): void => {
          setUrlError(false);
          setUrlToUpload(e.target.value);
        }}
      />
      {urlError && (
        <span className={styles.error}>{ERROR_MAP['file-invalid-type']}</span>
      )}
      <CallToActionButton
        className={styles.button}
        onClick={handleUrlSubmit}
        disabled={!urlToUpload}
      >
        Upload File
      </CallToActionButton>
    </div>
  );
};
