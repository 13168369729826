import React, { ReactElement } from 'react';
import { MissionCardObject } from '@a_team/models/dist/MissionObject';
import MissionControlCard from '..';
import { BasicMissionRole } from '@a_team/models/dist/MissionRole';
import ApplicationDetails from '../ApplicationDetails';
interface Props {
  mission?: MissionCardObject;
  userRole?: BasicMissionRole;
  loading?: boolean;
}

const CompletedMissionCard = (props: Props): ReactElement => {
  const { mission, userRole, loading } = props;

  return (
    <MissionControlCard
      loading={loading}
      mission={mission}
      details={
        <ApplicationDetails
          role={userRole?.category.title || ''}
          hourlyRate={
            userRole?.hourlyRate ? `$${userRole.hourlyRate}/hour` : undefined
          }
          dateApplied={userRole?.userAssignedAt}
        />
      }
      fullWidth={false}
      descriptionLines={2}
    />
  );
};

export default CompletedMissionCard;
