import React, { ReactElement, ReactNode } from 'react';
import { TooltipProps } from 'react-tippy';
import { createUseStyles } from 'react-jss';
import { ColorName, Colors } from '@ateams/components';
import cx from 'classnames';
import TooltipWrapped from '@src/components/TooltipWrapped';

interface Props extends TooltipProps {
  children: ReactNode;
  className?: string;
  textColor?: ColorName;
}

const useStyles = createUseStyles({
  '@global': {
    '.tippy-popper .tippy-tooltip': (props: Props) => ({
      color: `${
        props.textColor ? Colors[props.textColor] : Colors.primary
      } !important`,
    }),
  },
  tooltip: {},
});

export const ProfileTooltip = (props: Props): ReactElement => {
  const { children, className, textColor, ...left } = props;
  const styles = useStyles(props);

  return (
    <TooltipWrapped
      theme={'light'}
      position={'right-start'}
      arrow
      className={cx(styles.tooltip, className)}
      {...left}
    >
      {props.children}
    </TooltipWrapped>
  );
};
