import { SelectOption } from '@ateams/components';
import { TalentSkillRating } from '@a_team/models/dist/TalentCategories';
import { GenericTalent } from '@src/components/TeamGraphBuilderQuery';

const toGenericTalent = (
  option: SelectOption,
  rating: TalentSkillRating | undefined,
): GenericTalent => {
  return { id: option.value, label: option.label, rating };
};

export const getTalentMap = (
  preferred: GenericTalent[],
  required: GenericTalent[],
): Map<string, TalentSkillRating | boolean> => {
  return new Map<string, TalentSkillRating | boolean>([
    ...required.map(({ id, rating }) => [id, rating ?? true]),
    ...preferred.map(({ id }) => [id, true]),
  ] as Array<[string, TalentSkillRating | boolean]>);
};

export const talentToSelectOption = (talent: GenericTalent): SelectOption => ({
  value: talent.id,
  label: talent.label,
});

export const getUpdatedTalents = (
  selection: SelectOption | SelectOption[] | null,
  requiredTalent: boolean,
  talentMap: Map<string, TalentSkillRating | boolean>,
): GenericTalent[] => {
  const options =
    selection === null
      ? []
      : Array.isArray(selection)
      ? selection
      : [selection];

  return options.map((option: SelectOption) => {
    const previousRating = talentMap.get(option.value);
    const updatedRating =
      requiredTalent === false
        ? undefined
        : !previousRating || previousRating === true
        ? TalentSkillRating.AboveAverage
        : previousRating;

    return toGenericTalent(option, updatedRating);
  });
};

export const removeTalentOverlap = (
  initialTalents: GenericTalent[],
  talentsToRemove: GenericTalent[],
): GenericTalent[] => {
  // Implement talent removal as a map to avoid higher-order complexity
  const unwantedTalentMap = new Map<string, boolean>(
    talentsToRemove.map(({ id }) => [id, true]),
  );

  const remainingTalents = initialTalents.filter(
    (talent) => !unwantedTalentMap.get(talent.id),
  );

  return remainingTalents;
};
