import React, { ReactElement } from 'react';
import MissionPaymentCycle from '@src/stores/Missions/MissionPaymentCycle';
import TextButton from '@src/components/TextButton';
import { Colors } from '@ateams/components';

interface Props {
  paymentCycle: MissionPaymentCycle;
  onSendReminder: () => void;
}

export const TimesheetsStatusDisplay = (props: Props): ReactElement => {
  const {
    onSendReminder,
    paymentCycle: { timesheets, submittedTimesheets, allTimesheetsSubmitted },
  } = props;

  if (allTimesheetsSubmitted) {
    return (
      <div style={{ color: Colors.success }}>All timesheets submitted</div>
    );
  }

  return (
    <div>
      <div>
        {submittedTimesheets.length} / {timesheets?.length} timesheets submitted
      </div>
      <TextButton highlight onClick={onSendReminder}>
        Send reminders
      </TextButton>
    </div>
  );
};
