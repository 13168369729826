import { Modal, Button } from '@a_team/ui-components';
import { Breakpoints } from '@ateams/components';
import { ExistingProject } from '@src/stores/Profile/models';
import React, { useMemo } from 'react';
import { ModalBasicProps } from '..';
import { createUseStyles } from 'react-jss';
import {
  ExperienceMemberRole,
  ExperienceUserMember,
} from '@a_team/models/dist/ExperienceObject';
import { format } from 'date-fns';
import CompanyLogoWithOwner from '@src/components/CompanyLogoWithOwner';
import CompanyLogoFallback from '@src/components/CompanyLogoFallback';
import { useHasPendingCollaboratorRequest } from '@src/rq/experiences';
import cx from 'classnames';

interface CollaboratorRequestModalProps extends ModalBasicProps {
  selectedProject: ExistingProject;
  onReject: () => void;
  onEditProject: () => void;
}

const useStyles = createUseStyles({
  modal: {
    width: 'auto',
    padding: 0,
    '& > div': {
      padding: 0,
    },
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  centerText: {
    textAlign: 'center',
  },
  container: {
    margin: 40,
    maxWidth: '800px',
  },
  companyLogo: {
    display: 'flex',
    justifyContent: 'center',
  },
  coverImageWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginTop: 24,
    marginBottom: 8,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  tileNoRequestAvailable: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '26px',
    letterSpacing: '0.02px',
    margin: 0,
  },
  contentNoRequestAvailable: {
    color: '#818388',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '24px',
    margin: '8px 0 40px 0',
  },
  description: {
    color: '#818388',
    lineHeight: '24px',
    fontSize: 15,
    textAlign: 'center',
  },
  projectDetails: {
    marginTop: 40,
    padding: 16,
    border: '1px solid #DADADC',
    borderRadius: 8,
  },
  projectDetailsHeader: {
    display: 'flex',
    gap: 16,
  },
  projectDetailsTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  projectDetailsCollaborators: {
    fontSize: 14,
    color: '#818388',
    fontWeight: 400,
  },
  projectDetailsFooter: {
    marginTop: 16,
    display: 'flex',
    gap: 8,
  },
  footer: {
    padding: '0',
    display: 'flex',
    justifyContent: 'end',
    gap: 16,
    borderTop: '1px solid #DADADC',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      minWidth: 680,
      borderRadius: '16px !important',
    },
    title: {
      width: 400,
    },
    footer: {
      padding: 16,
    },
  },
});

function CollaboratorRequestModal({
  selectedProject,
  open,
  onClose,
  onReject,
  onEditProject,
}: CollaboratorRequestModalProps): JSX.Element | null {
  const styles = useStyles();

  const { data, isLoading } = useHasPendingCollaboratorRequest({
    eid: selectedProject?.eid ?? '',
  });

  const owner = selectedProject?.members.find(
    (member) => member.experienceRole === ExperienceMemberRole.Owner,
  ) as ExperienceUserMember;

  const coverImage =
    selectedProject?.imagesUrls && selectedProject.imagesUrls.length > 0
      ? selectedProject.imagesUrls[0]
      : selectedProject.logoURL;

  const footerItems = useMemo(() => {
    const items: JSX.Element[] = [];
    if (selectedProject.jobRole) {
      items.push(<span>{selectedProject.jobRole}</span>);
    }
    if (selectedProject.startDate) {
      items.push(
        <span>
          {format(new Date(selectedProject.startDate ?? ''), 'MMM yyyy')} -{' '}
          {selectedProject.endDate
            ? format(new Date(selectedProject.endDate ?? ''), 'MMM yyyy')
            : 'Present'}
        </span>,
      );
    }
    if (selectedProject.skills && selectedProject.skills?.length > 0) {
      items.push(<span>{selectedProject.skills?.length} skills</span>);
    }

    const finalItems = items.reduce<JSX.Element[]>((acc, val, index) => {
      return index !== items.length - 1
        ? [...acc, val, <span>·</span>]
        : [...acc, val];
    }, []);

    return finalItems;
  }, [
    selectedProject.jobRole,
    selectedProject.startDate,
    selectedProject.endDate,
    selectedProject.skills,
  ]);

  return (
    <>
      <Modal
        variant="slideUp"
        isOpen={open}
        onClose={onClose}
        className={styles.modal}
        shouldHideGradientStroke={true}
      >
        {isLoading && 'Loading...'}

        {!isLoading && !data?.hasPendingCollaboratorRequest && (
          <div className={cx(styles.centerContent, styles.container)}>
            <div className={styles.wrapper}>
              <h4
                className={cx(styles.centerText, styles.tileNoRequestAvailable)}
              >
                This collaborator request is no longer available
              </h4>
              <p
                className={cx(
                  styles.centerText,
                  styles.contentNoRequestAvailable,
                )}
              >
                The request has already been accepted or declined.
              </p>
            </div>
            <Button
              className={styles.closeBtn}
              size="md"
              variant="secondary"
              onClick={onClose}
            >
              Close
            </Button>
          </div>
        )}

        {!isLoading && !!data?.hasPendingCollaboratorRequest && (
          <>
            <div className={styles.container}>
              <div className={styles.companyLogo}>
                <CompanyLogoWithOwner
                  logoUrl={selectedProject.logoURL}
                  profilePictureURL={owner.profilePictureURL}
                  size={80}
                />
              </div>

              <div className={styles.title}>
                {owner.fullName} wants to add you as a collaborator on a
                project.
              </div>

              <div className={styles.description}>
                If the request is accepted you and {owner.firstName} will become
                connected.
              </div>
              <div className={styles.description}>
                If you want to accept, edit the experience to clarify your role
                and contribution.
              </div>
              <div className={styles.projectDetails}>
                <div className={styles.projectDetailsHeader}>
                  <div className={styles.coverImageWrapper}>
                    <CompanyLogoFallback logoUrl={coverImage} size={40} />
                  </div>
                  <div>
                    <div className={styles.projectDetailsTitle}>
                      {selectedProject.title}
                    </div>
                    <div className={styles.projectDetailsCollaborators}>
                      {selectedProject.members?.length} Collaborators
                    </div>
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<p>${selectedProject.description?.replace(
                      /\n\n/g,
                      '</p><p>',
                    )}</p>`,
                  }}
                />
                <div className={styles.projectDetailsFooter}>{footerItems}</div>
              </div>
            </div>

            <div className={styles.footer}>
              <Button size="md" variant="secondary" onClick={onReject}>
                Reject
              </Button>

              <Button size="md" onClick={onEditProject}>
                Edit & accept
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}

export default CollaboratorRequestModal;
