import { ServiceAuth, ServiceEndpoint } from './utils';

export const BasePath = '/oauth';

export interface OAuthCodeRequest {
  client_id: string;
  redirect_uri: string;
  scope: string;
  state: string;
}

export interface OAuthCodeResponse {
  code: string;
  redirect_uri: string;
}

export interface OAuthTokenRequest {
  grant_type: string;
  code: string;
  redirect_uri: string;
  client_id: string;
  client_secret: string;
}

export interface OAuthTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token?: string;
}

// exported functions

export default class OAuthEndpoint extends ServiceEndpoint {
  async code(
    auth: ServiceAuth,
    request: OAuthCodeRequest,
  ): Promise<OAuthCodeResponse> {
    return this.fetch(auth, BasePath + '/code', null, 'post', request);
  }

  async token(request: OAuthTokenRequest): Promise<OAuthTokenResponse> {
    return this.fetch(null, BasePath + '/token', null, 'post', request);
  }
}
