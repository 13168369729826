import React, { ReactElement } from 'react';
import { MissionRolePlatformFeeInvoiceObject } from '@a_team/models/dist/InvoiceObject';
import Table from '../Table';
import { stringifyMinutes, stringifyDate } from '@src/helpers/time';
import FooterTotal from '@src/views/InvoicePdf/components/FooterTotal';
import PageLayout from '@src/views/InvoicePdf/components/PageLayout';
import HeroSections, {
  HeroAddressSection,
  HeroSection,
} from '@src/views/InvoicePdf/components/HeroSections';

interface Props {
  invoice: MissionRolePlatformFeeInvoiceObject;
}

export default function MissionRolePlatformFeeInvoice(
  props: Props,
): ReactElement {
  const { invoice } = props;
  const { period, to, from } = invoice;

  const platformMargin = `${Math.round(invoice.platformMargin * 10000) / 100}%`;

  const totalHours = stringifyMinutes(invoice.totalMinutes);
  const costPerHour = `$${invoice.hourlyRate.toLocaleString()}`;
  const totalPayments = `$${invoice.totalPayments.toLocaleString()}`;
  const total = `$${invoice.totalAmount.toLocaleString()}`;

  return (
    <PageLayout
      invoice={invoice}
      period={period}
      footer={
        <FooterTotal
          details={{
            'Total Payments': totalPayments,
            'Platform Fee': platformMargin,
          }}
          total={total}
        />
      }
    >
      <HeroSections>
        <HeroSection grow title="Summary for Period">
          {stringifyDate(period.startDate, true)}-
          {stringifyDate(period.endDate)}
        </HeroSection>
        <HeroAddressSection title="From" address={from} />
        <HeroAddressSection title="To" address={to} />
      </HeroSections>
      <Table>
        <thead>
          <tr>
            <th>Role</th>
            <th># of Hours</th>
            <th>$ per Hour</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{invoice.roleCategory.title}</td>
            <td>{totalHours}</td>
            <td>{costPerHour}</td>
            <td>
              <strong>{totalPayments}</strong>
            </td>
          </tr>
        </tbody>
      </Table>
    </PageLayout>
  );
}
