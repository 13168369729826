import { BasicUserObject } from './UserObject';
import { DateISOString } from './misc';
import { TalentSpecialization } from './TalentCategories';

export type TeamId = string;

export enum TeamMemberRole {
  Owner = 'owner',
  Member = 'member',
}

export enum TeamMemberStatus {
  Active = 'Active', // User has opted-in to team
  Pending = 'Pending', // User has not responded to team-up request
  Declined = 'Declined', // User has opted-out of the team
}

export interface BasicTeamMemberObject extends BasicUserObject {
  teamRole: TeamMemberRole;
  memberTitle?: TalentSpecialization;
  linkedInUsername?: string;

  // Will return only for the current user
  memberStatus?: TeamMemberStatus;
}

export interface TeamMemberObject extends BasicTeamMemberObject {
  memberStatus: TeamMemberStatus;
  email: string;
  invitedAt?: DateISOString;
  acceptedAt?: DateISOString;
  declinedAt?: DateISOString;
}

export interface BasicTeamObject {
  tid: TeamId;
  members: BasicTeamMemberObject[];
  createdAt: DateISOString;
}

export default interface TeamObject extends Omit<BasicTeamObject, 'members'> {
  members: TeamMemberObject[];
}
