import React, { ButtonHTMLAttributes, ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import ButtonIcon from './ButtonIcon';
import { Breakpoints } from '@ateams/components';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

const useStyles = createUseStyles({
  root: {
    padding: '16px 0',
    margin: '24px 0',
    width: '100%',
    height: '56px',
    background: '#fff',
    borderRadius: '100px',
    border: '1px solid #c0c0c0',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.06)',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '24px',
    color: (props: Props): string => (props.disabled ? '#999' : '#222'),
    cursor: (props: Props): string =>
      props.disabled ? 'not-allowed' : 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: (props: Props): number => (props.disabled ? 0.5 : 1),
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      width: '416px',
    },
  },
});

export default function RegistrationConnectButton(props: Props): ReactElement {
  const { className, children, ...left } = props;
  const styles = useStyles(props);

  return (
    <button className={cx(styles.root, className)} {...left}>
      {children}
    </button>
  );
}

export { ButtonIcon };
