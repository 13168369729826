import { ServiceAuth, ServiceEndpoint } from './utils';
import {
  InvoiceObject,
  InvoiceId,
  BasicInvoiceObject,
  PaymentPendingInvoiceReportRow,
  InvoiceReceivableSource,
} from '@a_team/models/dist/InvoiceObject';
import { QueryNextToken, QueryResult } from '@a_team/models/dist/misc';
import { UserId } from '@a_team/models/dist/UserObject';
import { PaymentMethodId } from '@a_team/models/dist/PaymentMethod';

export const BasePath = '/invoices';

export interface MarkAsPaidData {
  dateReceived: Date;
  receivableSource: InvoiceReceivableSource;
  amountPaid: number;
  memo?: string;
}

export default class InvoicesEndpoint extends ServiceEndpoint {
  public getInvoiceByNonce(
    iid: InvoiceId,
    nonce: string,
  ): Promise<InvoiceObject> {
    return this.fetch(null, BasePath + `/invoice/${iid}-${nonce}`);
  }

  public getPaymentInfo(
    auth: ServiceAuth,
    iid: InvoiceId,
    paymentMethodId: PaymentMethodId,
  ): Promise<
    Pick<BasicInvoiceObject, 'totalAmount' | 'chargeFee' | 'amountCharged'>
  > {
    return this.fetch(auth, BasePath + `/invoice/${iid}/payment-info`, {
      paymentMethod: paymentMethodId,
    });
  }

  public pay(
    auth: ServiceAuth,
    iid: InvoiceId,
    paymentMethodId: PaymentMethodId,
  ): Promise<BasicInvoiceObject> {
    return this.fetch(
      auth,
      BasePath + `/invoice/${iid}/pay`,
      { paymentMethod: paymentMethodId },
      'post',
    );
  }

  public process(
    auth: ServiceAuth,
    iid: InvoiceId,
  ): Promise<BasicInvoiceObject> {
    return this.fetch(auth, BasePath + `/invoice/${iid}/process`, null, 'post');
  }

  public markAsPaid(
    auth: ServiceAuth,
    iid: InvoiceId,
    data: MarkAsPaidData,
  ): Promise<BasicInvoiceObject> {
    return this.fetch(
      auth,
      BasePath + `/invoice/${iid}/paid`,
      null,
      'post',
      data,
    );
  }

  public listInvoices(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<QueryResult<BasicInvoiceObject>> {
    return this.fetch(auth, BasePath, { next });
  }

  public adminListPaymentPending(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<QueryResult<PaymentPendingInvoiceReportRow>> {
    return this.fetch(auth, BasePath + `/payment-pending`, { next }, 'get');
  }

  public adminSendClientInvoiceReminderEmail(
    auth: ServiceAuth,
    uid: UserId,
    iid: InvoiceId,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/invoice/${iid}/client/${uid}/send-reminder-email`,
      null,
      'post',
    );
  }
}
