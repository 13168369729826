import React, { CSSProperties, ReactElement } from 'react';
import {
  Button as CallToActionButton,
  IconType,
  Icon,
  Card,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { Colors, ColorName, TextColors } from '@ateams/components';
import { Breakpoints } from '@ateams/components';

interface Props {
  title: string;
  text: string;
  buttonText?: string;
  onButtonClick?: () => void;
  backgroundColor?: string;
  buttonColor?: ColorName;
  style?: CSSProperties;
  withButton?: boolean;
  disabled?: boolean;
  icon?: IconType;
  'data-testing-id'?: string;
}

const useStyles = createUseStyles({
  container: {
    color: TextColors.primary,
    background: (props: Props): string =>
      props.backgroundColor || Colors.primary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '32px 24px',
    marginRight: 0,
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1em',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 4,
  },
  text: {
    margin: 0,
  },
  button: (props: Props) => ({
    padding: '8px 24px',
    width: 'auto',
    height: 'auto',
    marginTop: 24,
    ...(!props.buttonColor && {
      background: Colors.backgroundWhite,
      color: props.backgroundColor || Colors.primary,
    }),
  }),
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      flexDirection: 'row',
    },
    contentContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    button: {
      marginTop: '0 !important',
    },
  },
});

const PaymentNotification = (props: Props): ReactElement => {
  const styles = useStyles(props);

  return (
    <Card
      className={styles.container}
      style={props.style}
      data-testing-id={props['data-testing-id']}
    >
      <div className={styles.contentContainer}>
        {props.icon && <Icon type={props.icon} size={'large'} />}
        <div>
          <h2 className={styles.title}>{props.title}</h2>
          <p className={styles.text}>{props.text}</p>
        </div>
      </div>

      {props.withButton && (
        <CallToActionButton
          data-testing-id="payment-notification-button"
          color={props.buttonColor || undefined}
          disabled={props.disabled}
          onClick={props.onButtonClick}
          className={styles.button}
        >
          {props.buttonText || 'Confirm'}
        </CallToActionButton>
      )}
    </Card>
  );
};

export default PaymentNotification;
