import React, { CSSProperties, ReactElement } from 'react';
import Card from '../../../../../packages/components/src/Card';
import { Button as CallToActionButton } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import logo from './logo.svg';
import { Breakpoints } from '@ateams/components';

interface Props {
  onRequestInvite(): void;
  style?: CSSProperties;
}

const useStyles = createUseStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-between',
    background: '#222222',
    margin: '24px 0',
  },
  textContainer: {
    marginLeft: 0,
    padding: '16px 0',
    color: '#fff',
  },
  title: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '22px',
  },
  details: {
    marginTop: '8px',
    fontSize: '15px',
    lineHeight: '24px',
  },
  button: {
    width: '168px',
    flexShrink: 0,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    card: {
      margin: '40px 0',
      height: '126px',
      flexDirection: 'row',
      textAlign: 'left',
    },
    textContainer: {
      padding: 0,
      marginLeft: 24,
    },
  },
});

export default function RequestInviteCard(props: Props): ReactElement {
  const styles = useStyles();
  return (
    <Card className={styles.card} style={props.style}>
      <img src={logo} alt="logo" />
      <div>
        <div className={styles.textContainer}>
          <div className={styles.title}>Welcome to A·Team</div>
          <div className={styles.details}>
            Create your profile to contact high-level professionals.
          </div>
        </div>
      </div>
      <CallToActionButton
        className={styles.button}
        onClick={props.onRequestInvite}
      >
        Request Invite
      </CallToActionButton>
    </Card>
  );
}
