import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  fadeIn: (props) => ({
    animation: `$fadeIn ${props.duration || 1000}ms ease-in forwards`,
  }),
  fadeOut: (props) => ({
    animation: `$fadeOut ${props.duration || 1000}ms ease-out forwards`,
  }),
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '25%': { opacity: 0.4 },
    '50%': { opacity: 0.8 },
    '100%': { opacity: 1 },
  },
  '@keyframes fadeOut': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0.4 },
    '75%': { opacity: 0.2 },
    '100%': { opacity: 0 },
  },
});

const FadeInOut = ({
  children,
  isVisible,
  duration,
}: {
  children: React.ReactNode;
  isVisible: boolean;
  duration?: number;
}) => {
  const styles = useStyles({ duration });
  const [shouldRender, setShouldRender] = useState(isVisible);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    if (isVisible) {
      setShouldRender(true);
    } else {
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, duration || 1000);
      return () => clearTimeout(timer);
    }

    return;
  }, [isVisible, duration]);

  const animationClass = isFirstRender
    ? ''
    : isVisible
    ? styles.fadeIn
    : styles.fadeOut;

  return shouldRender ? <div className={animationClass}>{children}</div> : null;
};

export default FadeInOut;
