import React, { ReactElement } from 'react';
import { LocalUserReferral } from '@src/stores/Registration/models';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { Breakpoints, Colors } from '@ateams/components';
import { Icon, IconType } from '@ateams/components';
import { isLinkedInUrl, isValidUrl } from '@src/helpers/urls';
import { createUseStyles } from 'react-jss';

interface Props {
  referral: LocalUserReferral;
  onChange: (referral: LocalUserReferral) => void;
  onRemove?: (referral: LocalUserReferral) => void;
  isEmailValid?: boolean;
}

const useStyles = createUseStyles({
  card: {
    background: Colors.backgroundMedium,
    padding: '1px 15px',
    borderRadius: 12,
    position: 'relative',
    marginTop: 20,
  },
  input: {},
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    card: {
      padding: '1px 20px',
    },
  },
  [`@media (min-width: ${Breakpoints.xl}px)`]: {
    input: {
      marginBottom: '2em',
      '& .input-label': {
        fontSize: '0.9em',
        marginBottom: '0.8em',
      },
    },
  },
});

export const ReferralUserForm = (props: Props): ReactElement => {
  const styles = useStyles();
  const { referral, onChange, onRemove, isEmailValid } = props;

  return (
    <div className={styles.card}>
      {onRemove && (
        <Icon
          type={IconType.Close}
          size={'xsmall'}
          onClick={() => onRemove(referral)}
          style={{ position: 'absolute', right: 14, top: 14 }}
          title={'Remove'}
        />
      )}
      <OutlinedInput
        label={'First Name'}
        placeholder={'Jack...'}
        value={`${referral?.firstName}`}
        required
        onChange={(e) => onChange({ ...referral, firstName: e.target.value })}
        className={styles.input}
      />
      <OutlinedInput
        label={'Last Name'}
        placeholder={'Jackson...'}
        value={`${referral?.lastName}`}
        required
        onChange={(e) => onChange({ ...referral, lastName: e.target.value })}
        className={styles.input}
      />
      <OutlinedInput
        label={'Email Address'}
        placeholder={'name@company.com...'}
        value={referral.email}
        required
        valid={isEmailValid}
        error={!!referral.email && isEmailValid === false}
        onChange={(e) => {
          onChange({ ...referral, email: e.target.value });
        }}
        className={styles.input}
        errorTooltip={'Oops, the email you entered in invalid.'}
      />
      <OutlinedInput
        label={'LinkedIn'}
        placeholder={'https://linkedin.com/in/...'}
        valid={
          referral.linkedinUrl ? isLinkedInUrl(referral.linkedinUrl) : undefined
        }
        error={!!referral.linkedinUrl && !isLinkedInUrl(referral.linkedinUrl)}
        value={referral?.linkedinUrl}
        onChange={(e) => onChange({ ...referral, linkedinUrl: e.target.value })}
        className={styles.input}
        errorTooltip={'Oops, the link you entered in invalid.'}
      />
      <OutlinedInput
        label={'Personal Website'}
        placeholder={'https://mywebsite.com...'}
        valid={referral.website ? isValidUrl(referral.website) : undefined}
        error={!!referral.website && !isValidUrl(referral.website)}
        value={referral?.website}
        onChange={(e) => onChange({ ...referral, website: e.target.value })}
        className={styles.input}
        errorTooltip={'Oops, the link you entered in invalid.'}
      />
    </div>
  );
};
