import {
  CommunityBadgeObject,
  UserBadge as UserBadgeType,
} from '@a_team/models/dist/UserObject';
import { DEFAULT_PROFILE_IMAGES } from '@a_team/models/dist/constants/Profile';
import UserBadges from '@src/components/UserBadges';
import {
  isUploadCareURL,
  previewUploadCareImageUrl,
} from '@src/logic/uploadcare';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { CSSProperties, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import Image from '../Image';

export enum BadgesPosition {
  BeforeName = 'BeforeName',
  AfterName = 'AfterName',
  BelowName = 'BelowName',
  Underneath = 'Underneath',
}

const useStyles = createUseStyles({
  container: (props: Props) => ({
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  root: (props: Props) => ({
    borderRadius: '100%',
    verticalAlign: 'middle',
    width: props.size || '40px',
    height: props.size || '40px',
  }),
  imageContainer: {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  anonymous: {
    background: 'linear-gradient(197.15deg, #94FE0C 0%, #54D6FF 88.21%)',
  },
  label: {
    fontSize: 15,
    lineHeight: '24px',
    color: '#222222',
    marginLeft: 8,
  },
});

interface Props {
  containerClassName?: string;
  containerStyle?: CSSProperties;
  imageClassName?: string;
  labelClassName?: string;
  src?: string;
  alt?: string;
  size?: number;
  shouldResizeUploadCareImages?: boolean;
  label?: string;
  tooltipTheme?: 'light' | 'dark';
  badges?: UserBadgeType[];
  lastScheduledVettingInterviewDate?: Date;
  communityBadges?: CommunityBadgeObject[];
  badgesInline?: boolean;
  badgesPosition?: BadgesPosition;
  showBadgesUnderneath?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const UserAvatar: React.FC<Props> = (props) => {
  const {
    containerClassName,
    containerStyle,
    src,
    label,
    alt,
    badges,
    lastScheduledVettingInterviewDate,
    size,
    imageClassName,
    shouldResizeUploadCareImages = true,
    labelClassName,
    children,
    badgesPosition,
    onClick,
  } = props;

  const styles = useStyles(props);

  const resizedImageSrc = useMemo(() => {
    if (!src) {
      return undefined;
    }

    if (!shouldResizeUploadCareImages) {
      return src;
    }

    if (!isUploadCareURL(src) || typeof size !== 'number') {
      return src;
    }

    return previewUploadCareImageUrl(src, size);
  }, [shouldResizeUploadCareImages, src, size]);

  if (!resizedImageSrc) {
    return (
      <div
        className={cx(styles.container, containerClassName)}
        style={containerStyle}
      >
        <div className={cx(styles.root, styles.anonymous)} />
      </div>
    );
  }

  const order = ['guild_', 'community_', 'beta_'];

  const communityBadges = (props.communityBadges ?? []).sort((a, b) => {
    const typeA = a.type;
    const typeB = b.type;

    const indexA = order.findIndex((prefix) => typeA.startsWith(prefix));
    const indexB = order.findIndex((prefix) => typeB.startsWith(prefix));

    return indexA - indexB;
  });

  return (
    <>
      <div
        className={cx(styles.container, containerClassName)}
        style={containerStyle}
        onClick={onClick}
      >
        <div className={styles.imageContainer}>
          {children}

          <Image
            className={cx(styles.root, imageClassName)}
            src={resizedImageSrc || DEFAULT_PROFILE_IMAGES.general}
            fallbackSrc={DEFAULT_PROFILE_IMAGES.general}
            alt={alt || 'User Avatar'}
            sizes={`${size}px`}
          />

          {badgesPosition === BadgesPosition.BeforeName && (
            <UserBadges
              badges={badges}
              communityBadges={communityBadges}
              style={{ marginLeft: 5 }}
              lastScheduledVettingInterviewDate={
                lastScheduledVettingInterviewDate
              }
            />
          )}
        </div>
        {label && (
          <div
            style={{
              ...(badgesPosition === BadgesPosition.BelowName
                ? { display: 'flex', gap: 0, flexDirection: 'column' }
                : { display: 'flex', gap: 8, flexDirection: 'row' }),
            }}
          >
            <div className={cx(styles.label, labelClassName)}>{label}</div>
            {(!badgesPosition ||
              badgesPosition === BadgesPosition.AfterName ||
              badgesPosition === BadgesPosition.BelowName) && (
              <UserBadges
                badges={badges}
                communityBadges={communityBadges}
                style={{
                  justifyContent: 'start',
                  marginLeft: 8,
                }}
                lastScheduledVettingInterviewDate={
                  lastScheduledVettingInterviewDate
                }
              />
            )}
          </div>
        )}
      </div>

      {badgesPosition === BadgesPosition.Underneath && (
        <UserBadges
          badges={badges}
          communityBadges={communityBadges}
          lastScheduledVettingInterviewDate={lastScheduledVettingInterviewDate}
        />
      )}
    </>
  );
};

export default observer(UserAvatar);
