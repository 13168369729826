import { Icon, IconType } from '@ateams/components';
import cx from 'classnames';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';

interface AccordionProps {
  title: string;
  titleColor?: string;
  children: React.ReactNode;
}

const useStyles = createUseStyles<{ isOpen: boolean; titleColor?: string }>({
  container: {
    marginLeft: '15px',
  },
  content: {
    height: ({ isOpen }) => (isOpen ? 'auto' : 0),
    overflow: 'hidden',
    transition: 'height 0.3s ease-in-out',
  },
  reveal: {
    transform: 'rotate(-180deg)',
  },
  openButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    // remove default button styles
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
  },
  title: {
    color: ({ titleColor }) => titleColor || '#000000',
  },
  chevron: {
    fontSize: '14px',
  },
});

const Accordion = ({
  title,
  children,
  titleColor,
}: AccordionProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(true);
  const styles = useStyles({ isOpen, titleColor });

  const handleToggle = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.container}>
      <button className={styles.openButton} onClick={handleToggle}>
        <Icon
          type={IconType.Chevron}
          className={cx(styles.chevron, !isOpen ? styles.reveal : null)}
        />
        <p className={styles.title}>
          <strong>{title}</strong>
        </p>
      </button>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Accordion;
