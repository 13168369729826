import React, { ReactElement, ReactNode } from 'react';
import AdminTeamWorkView from '../';
import SectionHeading from '@src/components/SectionHeading';
import MissionList from '../MissionList';
import { observer } from 'mobx-react';
import { QueryResult } from '@a_team/models/dist/misc';
import { MissionCardObject } from '@a_team/models/dist/MissionObject';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';

interface Props {
  title: string;
  missions: MissionCardObject[];
  loadMore: () => Promise<QueryResult<MissionCardObject>>;
  showMore: boolean;
  children?: ReactNode;
  type: string;
}

function AdminTeamWorkMissionsList(props: Props): ReactElement {
  const { title, missions, showMore, loadMore, children, type } = props;
  const [loading, setLoading] = useLoadingState(null);

  return (
    <>
      <AdminTeamWorkView>
        <SectionHeading isFirst>{title}</SectionHeading>
        {children}
        <MissionList
          type={type}
          missions={missions}
          showMore={showMore}
          onShowMore={() => setLoading(loadMore(), null)}
        />
      </AdminTeamWorkView>
      <LoadingIndicator loading={loading} />
    </>
  );
}

export default observer(AdminTeamWorkMissionsList);
