import React, { ReactElement } from 'react';
import SectionHeading from '@src/components/SectionHeading';
import { Button as CallToActionButton } from '@ateams/components';
import { Icon, IconType } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import Profile from '@src/stores/Profile/Profile';
import { UserId } from '@a_team/models/dist/UserObject';

interface Props {
  applicationPreview?: Profile;
  onRequest: (
    uid: UserId,
    requested: boolean,
    placement: 'card' | 'modal',
  ) => void;
  onClose: () => void;
  requested?: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #6D00D7',
    height: 56,
  },
  name: {
    display: 'none',
  },
  cta: {
    borderRadius: 0,
    height: '100%',
  },
  back: {
    display: 'block',
    marginLeft: 20,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      height: 80,
      background: '#F7F7F7',
    },
    name: {
      display: 'block',
      padding: '23px 40px',
      marginBottom: 0,
      fontSize: 28,
    },
    cta: {
      marginRight: 50,
    },
    back: {
      display: 'none',
    },
  },
});

export const Header = (props: Props): ReactElement => {
  const { applicationPreview, onRequest, onClose, requested } = props;
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Icon
        type={IconType.Back}
        invert
        size={'exact'}
        className={styles.back}
        onClick={onClose}
      />
      <SectionHeading className={styles.name} isFirst>
        {applicationPreview?.data.fullName || ''}
      </SectionHeading>
      <CallToActionButton
        className={styles.cta}
        style={{
          ...(requested && {
            background: '#E3E3E4',
            color: '#62646A',
          }),
        }}
        disabled={!applicationPreview}
        width={'auto'}
        onClick={() => {
          if (!applicationPreview) return;
          onRequest(applicationPreview.data.uid, !requested, 'modal');
        }}
      >
        {requested ? (
          <span>
            <Icon
              type={IconType.Check}
              muted
              size={'exact'}
              style={{ marginRight: 8 }}
            />
            Requested
          </span>
        ) : (
          'Request as Teammate'
        )}
      </CallToActionButton>
    </div>
  );
};
