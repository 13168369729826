import React, { ReactElement } from 'react';
import cx from 'classnames';
import Icon, { IconType } from '../Icon';
import { createUseStyles } from 'react-jss';

export interface LoadMoreLinkProps {
  onLoadMore: () => void;
  buttonText?: string;
  withIcon?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  loadMore: {
    width: '100%',
    textAlign: 'center',
    color: '#FE630C',
    fontSize: 15,
    cursor: 'pointer',
    padding: '24px 0',
  },
  icon: {
    marginLeft: 8,
  },
});

const LoadMoreLink = (props: LoadMoreLinkProps): ReactElement => {
  const styles = useStyles();
  const { onLoadMore, buttonText, withIcon = true, className } = props;

  return (
    <div className={cx(styles.loadMore, className)}>
      <span onClick={onLoadMore}>
        {buttonText || 'Show Me More'}
        {withIcon && (
          <Icon
            type={IconType.ArrowDown}
            size="xsmall"
            clickable
            className={styles.icon}
          />
        )}
      </span>
    </div>
  );
};

export default LoadMoreLink;
