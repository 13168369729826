import React from 'react';
import {
  AdminVettingProcessColumnProps,
  BasicVettingProcessColumnProps,
} from '..';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import { createUseStyles } from 'react-jss';
import { Vetter } from '@a_team/models/dist/vetter';
import { VetterSelector } from '../../../vetter-selector';

const useStyles = createUseStyles({
  container: {
    minWidth: '280px',
  },
});

interface VetterOptionType {
  key: string;
  value: string;
  label: string;
  vetter?: Vetter | null;
}

export const InterviewerField: React.FC<AdminVettingProcessColumnProps> = ({
  vettingProcess,
}) => {
  const { vettingProcesses } = useStores();
  const styles = useStyles();
  const [loading, setLoading] = useLoadingState();

  const onVetterChange = async (
    option: VetterOptionType | VetterOptionType[] | null,
  ) => {
    const vetter =
      (option && !Array.isArray(option) && option.vetter?.id) || null;

    setLoading(
      vettingProcesses.patchVettingProcess({
        id: vettingProcess.id,
        vetter,
      }),
    );
  };

  return (
    <div className={styles.container}>
      <VetterSelector
        placeholder={'Search Interviewer...'}
        value={vettingProcess.vetter}
        onVetterSelect={onVetterChange}
        isDisabled={loading}
        useRegularSelectInput
        menuPortalTarget={document.body}
      />
    </div>
  );
};

export const InterviewerReadOnlyField: React.FC<
  BasicVettingProcessColumnProps
> = ({ vettingProcess }) => {
  const styles = useStyles();

  if (!vettingProcess.vetter) {
    return null;
  }

  return (
    <div className={styles.container}>
      <VetterSelector
        value={vettingProcess.vetter}
        isDisabled
        useRegularSelectInput
        menuPortalTarget={document.body}
      />
    </div>
  );
};
