import React, { CSSProperties, ReactElement, useEffect, useRef } from 'react';
import cx from 'classnames';
import check from './check.svg';
import errorIcon from './error.svg';
import loadingIcon from './loading.png';
import { createUseStyles } from 'react-jss';
import { LoadingState } from '@src/hooks/useLoadingState';
import { createPortal } from 'react-dom';

interface Props {
  loading: LoadingState;
  successMsg?: string;
  loadingMsg?: string;
  withPortal?: boolean;
  position?: 'fixed' | 'absolute';
  style?: CSSProperties;
}

const defaultProps = {
  withPortal: true,
  position: 'fixed',
};

const useStyles = createUseStyles<{ position?: string }>({
  root: (props) => ({
    position: props.position || 'fixed',
    bottom: '40px',
    right: '40px',
    zIndex: 10000,
    background: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(4px)',
    borderRadius: '104px',
    padding: '1em 1.5em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    opacity: 0,
    animation: '$fadeIn 300ms ease-in-out 300ms both',

    '&.saved': {
      animation: '$fadeOut 300ms ease-in-out 2s both',
    },

    '&.error': {
      animation: '$fadeOut 300ms ease-in-out 8s both',
    },

    '&.loading > img': {
      animation: '$loading 1s linear infinite',
    },
  }),
  '@keyframes fadeIn': {
    from: { opacity: 0, zIndex: 10000 },
    to: { opacity: 1, zIndex: 20000 },
  },
  '@keyframes fadeOut': {
    from: { opacity: 1, zIndex: 10000 },
    to: { opacity: 0, zIndex: 9, pointerEvents: 'none' },
  },
  '@keyframes loading': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
});

const Loader = (
  props: Partial<Props> & {
    className: string;
    icon: string;
    label: string | null;
    style?: CSSProperties;
  },
) => {
  const styles = useStyles({ position: props?.position });
  const { icon, style, className, label } = props;

  return (
    <div
      className={cx(styles.root, className)}
      data-testing-id="loading-indicator"
      style={style}
    >
      <img src={icon} alt={className} />
      {label ? <span style={{ marginLeft: '12px' }}>{label}</span> : null}
    </div>
  );
};

const LoadingIndicator = (props: Props): ReactElement | null => {
  const { loading, successMsg, withPortal, style, loadingMsg, ...rest } = props;

  const portalElm = useRef<HTMLDivElement>(
    typeof window === 'object' ? document.createElement('div') : null,
  );

  useEffect((): (() => void) | void => {
    if (!portalElm.current) return;
    document.body.appendChild(portalElm.current);

    return (): void => {
      if (!portalElm.current) return;

      portalElm.current.remove();
    };
  }, []);

  if (loading === null || !portalElm.current) {
    return null;
  }

  let className;
  let label;
  let icon;

  if (!loading || typeof loading === 'string') {
    className = 'saved';
    label = loading || successMsg || 'Saved';
    icon = check;
  } else if (loading === true) {
    className = 'loading';
    label = loadingMsg || null;
    icon = loadingIcon;
  } else {
    className = 'error';
    console.error(loading);
    label = String(loading.message || loading);
    icon = errorIcon;
  }

  if (!withPortal) {
    return (
      <Loader
        className={className}
        icon={icon}
        label={label}
        style={style}
        {...rest}
      />
    );
  }

  return createPortal(
    <Loader
      className={className}
      icon={icon}
      label={label}
      style={style}
      {...rest}
    />,
    portalElm.current,
  );
};

LoadingIndicator.defaultProps = defaultProps;
export default LoadingIndicator;
