import { rawTimeZones, RawTimeZone } from '@vvo/tzdb';
import { Option } from 'react-select/src/filters';
import TimezoneObject from '@a_team/models/dist/TimezoneObject';
import { getGMTFromMinutesOffset } from '@ateams/react-utils/dist/helpers/timezone';
import React, { ReactElement, useCallback, useMemo } from 'react';
import Select, { SelectOption, SelectProps } from './';

export interface TimezoneSelectProps extends Omit<SelectProps, 'onChange'> {
  onChange(data: TimezoneObject | TimezoneObject[] | undefined): void;
  value?: TimezoneObject | TimezoneObject[];
  readonly?: boolean;
}

const filterOptions = function (option: Option, input: string): boolean {
  const { data, label } = option;
  const { countryName, group, mainCities, name } = data;

  if (!input) return true;

  return !![countryName, ...group, ...mainCities, name, label].find(
    (searchable) => {
      return (
        input &&
        searchable &&
        searchable.toLowerCase().includes(input.toLocaleLowerCase())
      );
    },
  );
};

export const TimezoneSelect = (props: TimezoneSelectProps): ReactElement => {
  const { onChange, value: timezone, options, className, ...rest } = props;

  const customOptionsFilter = useCallback(filterOptions, []);

  const timezoneList = useMemo<(RawTimeZone & Option)[]>(() => {
    if (options) return options;

    return rawTimeZones.map(({ name, ...rest }, value) => ({
      label: `${name} - ${getGMTFromMinutesOffset(-rest.rawOffsetInMinutes)}`,
      value: String(value),
      name,
      ...rest,
    }));
  }, [rawTimeZones, options]);

  const selectedValue = useMemo(() => {
    if (!timezone) return null;

    if (Array.isArray(timezone)) {
      return timezoneList.filter(({ name }) =>
        timezone.find((tz) => tz.name === name),
      );
    } else {
      return timezoneList.find(({ name }) => name === timezone.name) || null;
    }
  }, [timezoneList, timezone]);

  const handleTimezoneSelect = (selected: SelectOption | null) => {
    if (selected) {
      const selectedTimeZone = timezoneList[Number(selected.value)];
      const { rawOffsetInMinutes, name } = selectedTimeZone;

      onChange({
        utcOffset: -rawOffsetInMinutes,
        name,
      });
    } else {
      onChange(undefined);
    }
  };

  const handleMultiSelect = (selected: SelectOption[] | null) => {
    if (selected) {
      const selectedIndexes = selected.map(({ value }) => Number(value));
      const selectedTimezones = timezoneList.filter((_, index) =>
        selectedIndexes.includes(index),
      );

      onChange(
        selectedTimezones.map(({ rawOffsetInMinutes, name }) => ({
          utcOffset: -rawOffsetInMinutes,
          name,
        })),
      );
    } else {
      onChange([]);
    }
  };

  return (
    <Select
      value={selectedValue && selectedValue}
      filterOption={customOptionsFilter}
      options={timezoneList}
      className={className}
      onChange={(val) =>
        Array.isArray(val) ? handleMultiSelect(val) : handleTimezoneSelect(val)
      }
      {...rest}
    />
  );
};
