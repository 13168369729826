import React from 'react';
import { createUseStyles } from 'react-jss';
import { Status } from './applicationStatusesUtils';
import { MissionApplicationReviewStatus } from '@a_team/models/dist/MissionApplicationObject';

interface ApplicationV2StatusV2Props {
  status: {
    status: Status<keyof MissionApplicationReviewStatus>;
    color: string;
  };
}

const useStyles = createUseStyles<{
  color: string;
}>({
  container: {
    padding: 16,
  },
  statusContainer: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  coloredSquare: {
    width: 12,
    height: 12,
    borderRadius: 2,
    backgroundColor: ({ color }) => color,
  },
  status: {
    fontWeight: 500,
    fontSize: 15,
  },
  statusDesc: {
    fontWeight: 400,
    fontSize: 14,
    marginTop: 8,
    marginBottom: 8,
  },
});

const ApplicationV2StatusV2 = ({
  status,
}: ApplicationV2StatusV2Props): JSX.Element => {
  const styles = useStyles({
    color: status.color,
  });
  return (
    <div className={styles.container}>
      <div className={styles.statusContainer}>
        <span className={styles.coloredSquare} />
        <span className={styles.status}>{status.status.name}</span>
      </div>
      <p className={styles.statusDesc}>{status.status.description}</p>
    </div>
  );
};

export default ApplicationV2StatusV2;
