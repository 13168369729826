import { Colors, IconType } from '@ateams/components';
import TextAreaInput from '@src/components/Inputs/TextAreaInput';
import React, { ChangeEvent, ReactElement, useMemo, useState } from 'react';
import { useScreenClass } from 'react-grid-system';
import Section, { SectionProps } from '../partials/Section';
import CommonStyles from '../partials/styles';
import cx from 'classnames';
import { useStores } from '@src/stores';
import Assistant from './Assistant';
import { CustomQuestionWithAnswer } from '@a_team/models/dist/MissionApplicationObject';
import { useGenerateMissionApplicationPitch } from '@src/rq/missions';
import { useAnalytics } from '@ateams/analytics/dist/platform';

interface PitchProps {
  title?: string;
  description?: ReactElement | string;
  text?: string;
  readonly?: boolean;
  error?: boolean;
  withIcon?: boolean;
  placeholder?: string;
  label?: string;
  isApplicationEdited?: boolean;
  type?: SectionProps['type'];
  guidanceTooltip?: ReactElement;
  guidanceMessage?: ReactElement;
  missionId: string;
  roleId: string;
  questionResponses?: CustomQuestionWithAnswer[];
  missinRequiredSkills?: boolean;
  missingPreferredSkills?: boolean;
  selectedProjects?: string[];
  requiredSkills?: string[];
  preferredSkills?: string[];
  onChange(text: string): void;
  onSuggestionGenerate?: () => void;
}

const PitchV2 = ({
  text,
  title,
  description,
  readonly,
  error,
  label,
  isApplicationEdited,
  onChange,
  onSuggestionGenerate,
  placeholder,
  guidanceTooltip,
  guidanceMessage,
  withIcon = true,
  type,
  questionResponses = [],
  selectedProjects = [],
  requiredSkills = [],
  preferredSkills,
  missinRequiredSkills,
  missingPreferredSkills,
  missionId,
  roleId,
}: PitchProps): ReactElement => {
  const { auth } = useStores();
  const commonStyles = CommonStyles();
  const screenClass = useScreenClass();
  const {
    mutate: generatePitch,
    reset,
    data: suggestion,
    isLoading,
  } = useGenerateMissionApplicationPitch();
  const analytics = useAnalytics();
  const [isUsingSuggestion, setIsUsingSuggestion] = useState<boolean>(false);

  const tooltipText = useMemo(() => {
    if (screenClass === 'xs') return undefined;
    let tooltip =
      'Showcase your relevant experience to stand out from the crowd.';
    error && (tooltip = 'Please enter a short description');
    return tooltip;
  }, [error, text, screenClass]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    onChange(e.target.value.trimStart());
  };

  const handleGeneration = () => {
    generatePitch({
      mid: missionId,
      rid: roleId,
      data: {
        skills: [...requiredSkills, ...(preferredSkills ?? [])],
        projects: selectedProjects,
        pitch: text,
      },
    });
    onSuggestionGenerate && onSuggestionGenerate();
  };

  const handleInsertSuggestion = (pitch: string) => {
    onChange(pitch);
    setIsUsingSuggestion(true);
    reset();
    analytics.trackPitchSuggestionInsertClicked(auth.uid ?? '', pitch);
  };

  const handleDiscardSuggestion = (pitch: string) => {
    reset();
    setIsUsingSuggestion(false);
    analytics.trackPitchSuggestionDiscardClicked(auth.uid ?? '', pitch);
  };

  return (
    <Section
      guidanceTooltip={guidanceTooltip}
      type={type}
      iconType={withIcon ? IconType.User : undefined}
      title={title || 'What differentiates you from other builders?'}
      description={
        description || (
          <div>
            <div>
              <strong>
                If your application is{' '}
                <span style={{ color: Colors.secondaryDark }}>
                  proposed to the company
                </span>
                , this response will be shared with them directly.
              </strong>{' '}
              Highlight how your skills match the requirements and why you're
              excited about this specific mission. If you've worked on something
              similar, include that as well.
            </div>
          </div>
        )
      }
      tooltipText={tooltipText}
      readonly={readonly}
      isApplicationEdited={isApplicationEdited}
      error={error}
    >
      {readonly ? (
        <div className={cx(commonStyles.readingOnlyText, 'admin')}>{text}</div>
      ) : (
        <>
          {label && (
            <label
              style={{
                display: 'block',
                fontSize: 16,
                fontWeight: 500,
                marginTop: 24,
                marginBottom: 8,
              }}
            >
              {label}
            </label>
          )}
          {guidanceMessage}
          <TextAreaInput
            key={`textarea-${isUsingSuggestion}`}
            value={text}
            onChange={handleChange}
            placeholder={placeholder || 'Ready, set, pitch!'}
            error={error}
            errorColor={type === 'large' ? 'primary' : undefined}
            data-testing-id={'mission-application-pitch-text-input'}
            maxLength={3000}
            showCharsLeft
            autoResize
            minLength={20}
            wrapperClassname={commonStyles.textareaWrapper}
            className={cx(commonStyles.textArea, {
              [commonStyles.error]: type === 'large' && error,
            })}
            footer={
              auth.withPitchWriterAssistant ? (
                <Assistant
                  isLoading={isLoading}
                  hasSkills={!missinRequiredSkills && !missingPreferredSkills}
                  hasProjects={selectedProjects.length > 0}
                  hasDescription={!!text?.length}
                  suggestion={suggestion?.response}
                  onGenerateSuggestion={handleGeneration}
                  onInsertSuggestion={handleInsertSuggestion}
                  onDiscardSuggestion={handleDiscardSuggestion}
                />
              ) : undefined
            }
          />
        </>
      )}
    </Section>
  );
};

export default PitchV2;
