import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  flag: {
    width: '20px',
  },
});

function Flag(): ReactElement {
  const styles = useStyles();

  return (
    <div className={styles.flag}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="22"
        fill="none"
        viewBox="0 0 20 22"
      >
        <path
          fill="url(#paint0_linear_4902:32032)"
          d="M0 0h20v19.839c0 1.677-1.94 2.61-3.25 1.562L11.25 17a2 2 0 00-2.5 0l-5.5 4.4C1.94 22.449 0 21.517 0 19.84V0z"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_4902:32032"
            x1="8"
            x2="8"
            y1="-6"
            y2="32"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9C64"></stop>
            <stop offset="1" stopColor="#FFC46C"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default Flag;
