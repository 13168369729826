import { MissionRoleId } from '@a_team/models/dist/MissionRole';
import {
  RecommendationId,
  RecommendationObject,
  RecommendationStatus,
} from '@a_team/models/dist/RecommendationObject';
import { Button, Icon } from '@a_team/ui-components';
import { Breakpoints } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import cx from 'classnames';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { isApplyingToTheSameRole } from './utils';

interface TeamUpRequestCardProps {
  userRecommendation: RecommendationObject;
  currentRoleId: MissionRoleId | undefined;
  onAccept: (rid: RecommendationId) => void;
  onReject: (rid: RecommendationId) => void;
  onPending: (rid: RecommendationId) => void;
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    border: '1px solid #DADADC',
    padding: 16,
    gap: 16,
    borderRadius: 8,
    flexDirection: 'column',
    width: '100%',
    '&:hover': {
      transition: 'background-color 0.2s ease-in-out',
      backgroundColor: '#EFEFF0',
    },
  },
  userInfo: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonsWrapper: {
    display: 'flex',
    gap: 8,
    '& button': {
      width: 'fit-content',
      padding: 8,
      height: 32,
    },
  },
  button: {
    borderRadius: '4px',
    transition: 'background 0.3s ease',
    whiteSpace: 'nowrap',
    width: 'auto',
    padding: 8,
    height: 'auto',
    fontSize: 14,
    lineHeight: '16px',
    minWidth: 60,
  },
  declineButton: {
    color: 'black',
  },
  icon: {
    '& span': {
      display: 'flex',
    },
  },
  appliedSameRole: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  accepted: {
    display: 'flex',
    gap: 5,
    color: '#6D00D7',
    fontSize: 12,
    alignItems: 'center',
  },
  declined: {
    display: 'flex',
    gap: 5,
    color: '#62646A',
    fontSize: 12,
    alignItems: 'center',
  },
  appliedToSameRole: {
    opacity: 0.5,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      border: 'none',
      marginBottom: 8,
    },
  },
});

const TeamUpRequestCard = ({
  userRecommendation,
  currentRoleId,
  onAccept,
  onReject,
  onPending,
}: TeamUpRequestCardProps) => {
  const styles = useStyles();

  const appliedToTheSameRole = useMemo(() => {
    return (
      currentRoleId &&
      isApplyingToTheSameRole(userRecommendation, currentRoleId)
    );
  }, [userRecommendation]);

  const isUnavailable =
    userRecommendation.status === RecommendationStatus.Unavailable;

  const appliedRolesText = useMemo(() => {
    const appliedRoles = userRecommendation.requestorAppliedRoles.map(
      (role) => role.category.title,
    );
    const appliedRolesCount = appliedRoles.length;

    if (appliedRolesCount === 0) {
      return '';
    }

    if (appliedRolesCount === 1) {
      return `Applied for the ${appliedRoles[0]} role`;
    }

    return `Applied for the ${appliedRoles[0]} role and ${
      appliedRolesCount - 1
    } others`;
  }, [userRecommendation.requestorAppliedRoles]);

  return (
    <div className={styles.root}>
      <div
        className={cx(
          styles.userInfo,
          appliedToTheSameRole || isUnavailable ? styles.appliedToSameRole : '',
        )}
      >
        <UserAvatar
          alt={userRecommendation.requestor.fullName}
          size={40}
          src={userRecommendation.requestor.profilePictureURL}
        />
        <div>
          <div>
            <strong>{userRecommendation.requestor.fullName}</strong>
          </div>
          <div>{appliedRolesText}</div>
        </div>
      </div>
      {appliedToTheSameRole || isUnavailable ? (
        <>
          {isUnavailable ? (
            <div className={styles.appliedSameRole}>
              {userRecommendation.requestor.firstName} is no longer available to
              team up on this mission
            </div>
          ) : (
            <div className={styles.appliedSameRole}>
              You are applying to the same role
            </div>
          )}
        </>
      ) : (
        <div className={styles.actions}>
          <div className={styles.buttonsWrapper}>
            {userRecommendation?.status === RecommendationStatus.Pending && (
              <>
                <Button
                  className={cx(styles.button)}
                  onClick={() => onAccept(userRecommendation.rid)}
                >
                  Team up
                </Button>
                <Button
                  className={cx(styles.button, styles.declineButton)}
                  variant="secondary"
                  onClick={() => onReject(userRecommendation.rid)}
                >
                  Decline
                </Button>
              </>
            )}

            {userRecommendation?.status === RecommendationStatus.Active && (
              <>
                <span className={styles.accepted}>
                  <Icon
                    className={styles.icon}
                    color="Hannibal@500"
                    size="sm"
                    name="statusPositiveNoBorder"
                  />
                  Accepted
                </span>
              </>
            )}
            {userRecommendation?.status === RecommendationStatus.Rejected && (
              <>
                <span className={styles.declined}>
                  <Icon
                    className={styles.icon}
                    color="Grey@500"
                    size="sm"
                    name="statusNegativeNoBorder"
                  />
                  Declined
                </span>
              </>
            )}

            {userRecommendation?.status !== RecommendationStatus.Pending && (
              <>
                <Button
                  className={cx(styles.button, styles.declineButton)}
                  variant="secondary"
                  onClick={() => onPending(userRecommendation.rid)}
                >
                  Edit
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamUpRequestCard;
