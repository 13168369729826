import { ServiceAuth, ServiceEndpoint } from './utils';
import RoleCategory, {
  GroupName,
  RoleCategoryAnchor,
} from '@a_team/models/dist/RoleCategory';

export const BasePath = '/role-categories';

export interface CreateRoleCategory {
  title: string;
  group: GroupName;
  anchors?: RoleCategoryAnchor[];
}

export type UpdateRoleCategory = CreateRoleCategory;

export default class RoleCategoriesEndpoint extends ServiceEndpoint {
  /**
   * Gets all role categories.
   *
   * @param auth
   */
  public getAllCategories(auth: ServiceAuth): Promise<RoleCategory[]> {
    return this.fetch(auth, BasePath);
  }

  /**
   * Creates a new role category.
   *
   * Can only be used by Admin.
   *
   * @param auth
   */
  public createCategory(
    auth: ServiceAuth,
    data: CreateRoleCategory,
  ): Promise<RoleCategory> {
    return this.fetch<RoleCategory>(auth, BasePath, null, 'post', data);
  }

  /**
   * Updates an existing role category.
   *
   * Can only be used by Admin.
   *
   * @param auth
   */
  public updateCategory(
    auth: ServiceAuth,
    cid: string,
    data: UpdateRoleCategory,
  ): Promise<RoleCategory> {
    return this.fetch<RoleCategory>(
      auth,
      BasePath + '/' + cid,
      null,
      'put',
      data,
    );
  }
}
