import { UserId } from '../../../UserObject';
import { SurveyQuestionResponseValidator } from '../SurveyQuestionResponseValidator';
import { SurveyQuestionType } from '../SurveyQuestionType';
import {
  RatingWithFreeFormCommentSurveyQuestionProps,
  RatingWithFreeFormCommentSurveyQuestionResponse,
  validateRatingWithFreeFormCommentSurveyQuestionResponse,
} from './RatingWithFreeFormCommentSurveyQuestion';

/** Properties of a person rating question with a free form comment */
export interface PersonRatingWithFreeFormCommentSurveyQuestionProps
  extends RatingWithFreeFormCommentSurveyQuestionProps {
  uid: UserId;
}
export interface AbleToRespond
  extends RatingWithFreeFormCommentSurveyQuestionResponse {
  unableToRespond?: never;
  didNotSpendSignificantTime?: { value?: boolean };
  overallRating?: { value?: string };
  whatYouLikedWhatCouldBeImproved?: { value?: string };
  wouldTimeUpAgain?: { value?: string };
}

export interface UnableToRespond {
  value?: never;
  comment?: never;
  unableToRespond?: boolean;
  didNotSpendSignificantTime?: { value?: boolean };
  overallRating?: { value?: string };
  whatYouLikedWhatCouldBeImproved?: { value?: string };
  wouldTimeUpAgain?: { value?: boolean };
}

/** Response value of a person rating question with a free form comment */
export type PersonRatingWithFreeFormCommentSurveyQuestionResponse =
  | AbleToRespond
  | UnableToRespond;

export const responseIsUnableToRespond = (
  response: PersonRatingWithFreeFormCommentSurveyQuestionResponse,
): response is UnableToRespond => 'unableToRespond' in response;

export const responseIsAbleToRespond = (
  response: PersonRatingWithFreeFormCommentSurveyQuestionResponse,
): response is AbleToRespond => {
  if ('value' in response) {
    return true;
  }

  if (!response?.didNotSpendSignificantTime?.value) {
    return true;
  }

  return false;
};

export const validatePersonRatingWithFreeFormCommentSurveyQuestionResponse: SurveyQuestionResponseValidator<
  SurveyQuestionType.PersonRatingWithFreeFormComment
> = (props, response): void => {
  if (responseIsAbleToRespond(response)) {
    validateRatingWithFreeFormCommentSurveyQuestionResponse(props, response);
  } else if (responseIsUnableToRespond(response)) {
    if (response.unableToRespond !== true) {
      throw new Error('unableToRespond must be unset or true');
    }
  } else if (props.required) {
    throw new Error(
      'Response for PersonRatingWithFreeForm must contain `value` or `unableToRespond`',
    );
  }
};
