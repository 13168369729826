import { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { DateISOString } from '@a_team/models/dist/misc';
import {
  canSubmitOrEditVettingFeedbackForm,
  DAYS_TO_EDIT_FEEDBACK_FORM_ANSWERS,
} from '@src/helpers/vetting';
import { VettingFeedbackFormBackgroundComments } from './background-comments';
import { VettingFeedbackFormBuilderInfo } from './builder-info';
import { VettingFeedbackFormExperience } from './experience';
import { VettingFeedbackFormGeneralComments } from './general-comments';
import { VettingFeedbackFormGeneralData } from './general-data';
import { VettingFeedbackFormMainInfo } from './main-info';
import { VettingFeedbackFormProjects } from './projects';
import { VettingFeedbackFormRoles } from './roles';
import { VettingFeedbackFormSummaryComments } from './summary-comments';
import { VettingFeedbackFormTechStack } from './tech-stack';
import { VettingFeedbackFormIndustries } from './industries';

export interface VettingFeedbackFormErrors {
  generalData: {
    interviewer?: string;
    category?: string;
  };
  mainInfo: {
    interviewDate?: string;
    scores: {
      expertise?: string;
      interactionExperience?: string;
      englishLevel?: string;
      accent?: string;
    };
  };
  roles: {
    generalError?: string;
  };
  summaryComments: {
    generalError?: string;
  };
}

const DEBOUNCE_MILLISECONDS_DELAY = 300;

const REQUIRED_FIELD_ERROR_LABEL = 'Required field';

export const useVettingFeedbackFormErrors = (
  {
    generalData,
    mainInfo,
    builderInfo,
    roles,
    experience,
    projects,
    techStack,
    industries,
    backgroundComments,
    generalComments,
    summaryComments,
  }: {
    generalData: VettingFeedbackFormGeneralData;
    mainInfo: VettingFeedbackFormMainInfo;
    builderInfo: VettingFeedbackFormBuilderInfo;
    roles: VettingFeedbackFormRoles;
    experience: VettingFeedbackFormExperience;
    projects: VettingFeedbackFormProjects;
    techStack: VettingFeedbackFormTechStack;
    industries: VettingFeedbackFormIndustries;
    backgroundComments: VettingFeedbackFormBackgroundComments;
    generalComments: VettingFeedbackFormGeneralComments;
    summaryComments: VettingFeedbackFormSummaryComments;
  },
  hasUserTriedToSubmit: boolean,
  feedbackFormSentDate: DateISOString | undefined,
  isCurrentUserAdmin: boolean,
  additionalMaterialsOperationsCount: number,
) => {
  const hasFormSubmissionTimeout = useMemo(() => {
    return Boolean(
      canSubmitOrEditVettingFeedbackForm(
        isCurrentUserAdmin,
        true,
        feedbackFormSentDate,
      ),
    );
  }, [feedbackFormSentDate, isCurrentUserAdmin]);
  const [formErrors, setFormErrors] = useState(
    () =>
      getErrors(
        generalData,
        mainInfo,
        roles,
        summaryComments,
        hasUserTriedToSubmit,
        additionalMaterialsOperationsCount,
      )[0],
  );
  const [formErrorsNotes, setFormErrorsNotes] = useState(
    () =>
      getErrors(
        generalData,
        mainInfo,
        roles,
        summaryComments,
        hasUserTriedToSubmit,
        additionalMaterialsOperationsCount,
      )[1],
  );

  /**
   * We create a debounced function that checks for errors on the form
   * We do this for performance - if we check for errors every time one of the form values changes then we'd cause
   * the form to re-render (since formErrors will be re-created and is passed as props to each form section)
   */
  const getErrorsDebounced = useMemo(
    () =>
      debounce(
        (
          generalData: VettingFeedbackFormGeneralData,
          mainInfo: VettingFeedbackFormMainInfo,
          roles: VettingFeedbackFormRoles,
          summaryComments: VettingFeedbackFormSummaryComments,
          hasUserTriedToSubmit: boolean,
        ) => {
          const [formErrors, formErrorsNotes] = getErrors(
            generalData,
            mainInfo,
            roles,
            summaryComments,
            hasUserTriedToSubmit,
            additionalMaterialsOperationsCount,
          );

          setFormErrors(formErrors);
          setFormErrorsNotes(formErrorsNotes);
        },
        DEBOUNCE_MILLISECONDS_DELAY,
      ),
    [setFormErrors, setFormErrorsNotes],
  );

  useEffect(() => {
    getErrorsDebounced(
      generalData,
      mainInfo,
      roles,
      summaryComments,
      hasUserTriedToSubmit,
    );
  }, [
    generalData,
    mainInfo,
    builderInfo,
    roles,
    experience,
    projects,
    techStack,
    industries,
    backgroundComments,
    generalComments,
    summaryComments,
    hasUserTriedToSubmit,
    additionalMaterialsOperationsCount,
  ]);

  function getErrors(
    generalData: VettingFeedbackFormGeneralData,
    mainInfo: VettingFeedbackFormMainInfo,
    roles: VettingFeedbackFormRoles,
    summaryComments: VettingFeedbackFormSummaryComments,
    hasUserTriedToSubmit: boolean,
    additionalMaterialsOperationsCount: number,
  ): [VettingFeedbackFormErrors, string[]] {
    let notes: string[] = [];
    const errors: VettingFeedbackFormErrors = {
      generalData: {},
      mainInfo: {
        scores: {},
      },
      roles: {},
      summaryComments: {},
    };

    // General data
    if (!generalData.vetter) {
      hasUserTriedToSubmit &&
        (errors.generalData.interviewer = REQUIRED_FIELD_ERROR_LABEL);
      notes.push('Missing interviewer');
    }
    if (!generalData.category) {
      hasUserTriedToSubmit &&
        (errors.generalData.category = REQUIRED_FIELD_ERROR_LABEL);
      notes.push('Missing specialization');
    }

    // Main info
    if (!mainInfo.interviewDate) {
      hasUserTriedToSubmit &&
        (errors.mainInfo.interviewDate = REQUIRED_FIELD_ERROR_LABEL);
      notes.push('Missing interview date');
    }
    if (typeof mainInfo.scores?.expertise !== 'number') {
      hasUserTriedToSubmit &&
        (errors.mainInfo.scores.expertise = REQUIRED_FIELD_ERROR_LABEL);
      notes.push('Missing expertise score');
    }
    if (typeof mainInfo.scores?.interactionExperience !== 'number') {
      hasUserTriedToSubmit &&
        (errors.mainInfo.scores.interactionExperience =
          REQUIRED_FIELD_ERROR_LABEL);
      notes.push('Missing interaction score');
    }
    if (typeof mainInfo.scores?.englishLevel !== 'number') {
      hasUserTriedToSubmit &&
        (errors.mainInfo.scores.englishLevel = REQUIRED_FIELD_ERROR_LABEL);
      notes.push('Missing english level score');
    }
    if (typeof mainInfo.scores?.accent !== 'number') {
      hasUserTriedToSubmit &&
        (errors.mainInfo.scores.accent = REQUIRED_FIELD_ERROR_LABEL);
      notes.push('Missing accent score');
    }

    // Roles
    if (!roles.roles.length) {
      hasUserTriedToSubmit &&
        (errors.roles.generalError = 'Select at least one role');
      notes.push('Should choose at least 1 role');
    }

    // Summary comments
    if (
      !summaryComments.comment && // either added a comment or already had one
      !summaryComments.comments?.length
    ) {
      hasUserTriedToSubmit &&
        (errors.summaryComments.generalError = 'This section is required');
      notes.push('Missing bottom line / general impression');
    }

    if (additionalMaterialsOperationsCount > 0) {
      notes.push(
        `Additional materials operations are in progress. Please wait until they are done`,
      );
    }

    /**
     * In case we can't submit the form due to too much time passing since the initial submission we don't
     * want to display other errors to the user
     */
    if (hasFormSubmissionTimeout) {
      notes = [
        `Cannot edit form after ${DAYS_TO_EDIT_FEEDBACK_FORM_ANSWERS} days from initial submission`,
      ];
    }

    return [errors, notes];
  }

  return {
    formErrors,
    formErrorsNotes,
    hasFormSubmissionTimeout,
  };
};
