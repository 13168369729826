import React, { ReactElement, useState } from 'react';
import cx from 'classnames';
import Select, { SelectOption } from './index';
import { createUseStyles } from 'react-jss';
import {
  components,
  GroupTypeBase,
  MenuPlacement,
  PlaceholderProps,
} from 'react-select';
import { GroupHeadingProps } from 'react-select/src/components/Group';
import Colors, { TextColors } from '../theme/colors';
import Checkbox from '../Checkbox';
import Icon, { IconType } from '../Icon';
import { BorderRadius, Spacing } from '../theme/styles';

type SelectOptionEx = SelectOption & { options: SelectOption[] };

interface NestedSelectProps {
  placeholder: string;
  items: string[];
  onChange: (items: string[]) => void;
  onMenuClose?: () => void;
  sidebar?: boolean;
  hideTags?: boolean;
  isClearable?: boolean;
  className?: string;
  allItemsMap: Record<string, SelectOption>;
  allItemGroups: Record<string, SelectOptionEx>;
  customMultiValueListValues?: (value: SelectOption[]) => SelectOption[];
  additionalPlaceholder?: string;
  onRemoveOption?: (options: SelectOption) => void;
  CustomPlaceholderOverride?: (
    props: PlaceholderProps<
      SelectOption,
      false,
      GroupTypeBase<SelectOption>
    > & {
      itemSelections: string[] | undefined;
      hideTags?: boolean | undefined;
    },
  ) => JSX.Element;
  menuPlacement?: MenuPlacement | undefined;
  hideGroupCheckbox?: boolean;
}

const useStyles = createUseStyles({
  selector: {
    fontSize: 14,
  },
  sidebar: {
    maxWidth: 'unset',
    marginRight: 0,
    marginTop: 12,
  },
  itemSelect: {
    '& .select-option-checkbox-wrapper': {
      display: 'none',
    },
    '& .react-select-option-group': {
      '& > div.select-option-checkbox-wrapper': {
        display: 'block!important',
      },
    },
  },
  placeholderCount: {
    marginLeft: Spacing.small,
    padding: Spacing.xsmall,
    background: Colors.backgroundDark,
    borderRadius: BorderRadius.default,
  },
});

export const NestedSelect = (props: NestedSelectProps): ReactElement => {
  const styles = useStyles();
  const {
    placeholder,
    additionalPlaceholder,
    items,
    onChange,
    onMenuClose,
    sidebar,
    isClearable,
    hideTags,
    allItemsMap,
    allItemGroups,
    customMultiValueListValues,
    onRemoveOption,
    CustomPlaceholderOverride,
    menuPlacement,
    hideGroupCheckbox,
  } = props;

  const itemSelections: string[] | undefined = items;

  const removeItem = (options: SelectOption | SelectOption[]): void => {
    const itemToRemove: string[] = !options
      ? []
      : Array.isArray(options)
      ? options.map(({ value }) => value)
      : [options.value];
    const newItemSelections = itemSelections
      ? itemSelections.filter((item) => !itemToRemove.includes(item))
      : [];

    const option = Array.isArray(options) ? options[0] : options;
    onRemoveOption?.(option);
    onChange(newItemSelections);
  };

  const updateItems = (options: SelectOption | SelectOption[] | null): void => {
    const categoryTalents: string[] = !options
      ? []
      : Array.isArray(options)
      ? options.map(({ value }) => value)
      : [options.value];

    onChange(categoryTalents);
  };

  const optionGroupClassName = 'react-select-option-group';

  // handle options group header click event
  // hide and show the options under clicked group
  function handleHeaderClick(id: string) {
    const node = document.querySelector(`#${id}`);
    const groupNodeClassList =
      node?.parentElement?.nextElementSibling?.classList;
    if (groupNodeClassList?.contains(optionGroupClassName)) {
      groupNodeClassList?.remove(optionGroupClassName);
      return false;
    }
    groupNodeClassList?.add(optionGroupClassName);
    return true;
  }

  // Create custom GroupHeading component, which will wrap
  // react-select GroupHeading component inside a div and
  // register onClick event on that div
  function CustomGroupHeading(
    props: GroupHeadingProps<
      SelectOption,
      true,
      SelectOption & { options: SelectOption[] }
    > & {
      id: string;
      data: GroupTypeBase<SelectOption>;
      itemSelections: string[];
      updateItems: (options: SelectOption | SelectOption[] | null) => void;
      removeItem: (options: SelectOption | SelectOption[]) => void;
      showCheckbox?: boolean;
    },
  ) {
    const { children, data, itemSelections, updateItems, removeItem, ...rest } =
      props;
    const options = data.options;
    const selectedOptionsCount = options.filter((option) =>
      itemSelections.includes(option.value),
    ).length;
    const [groupCollapsed, setGroupCollapsed] = useState<
      Record<string, boolean>
    >({});

    return (
      <div
        style={{
          background: Colors.backgroundDark,
        }}
        onClick={(e) => e.preventDefault()}
        className="group-heading-wrapper"
      >
        <components.GroupHeading {...rest}>
          <div
            style={{
              display: 'flex',
              flex: '2 1 auto',
              color: TextColors.regular,
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            {props.showCheckbox && (
              <Checkbox
                style={{ padding: '10px 0px' }}
                margin={'none'}
                label={undefined}
                checked={options
                  .map((e) => e.value)
                  .every((e) => itemSelections?.includes(e))}
                indeterminate={
                  options
                    .map((e) => e.value)
                    .some((e) => itemSelections?.includes(e)) &&
                  !options
                    .map((e) => e.value)
                    .every((e) => itemSelections?.includes(e))
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    updateItems(
                      options
                        .filter((o) => !itemSelections.includes(o.value))
                        .concat(
                          itemSelections?.map((e) => ({
                            value: e,
                            label: e,
                          })),
                        ),
                    );
                  } else {
                    removeItem(options.map((e) => e));
                  }
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            )}
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexGrow: 2,
              }}
              onClick={(e) => {
                setGroupCollapsed({
                  ...groupCollapsed,
                  [props.id]: handleHeaderClick(props.id),
                });
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                {data.label}{' '}
                {groupCollapsed[props.id] ? (
                  <Icon
                    type={IconType.ArrowUp}
                    size="xsmall"
                    clickable
                    style={{ marginLeft: '5px', filter: 'grayscale(100%)' }}
                  />
                ) : (
                  <Icon
                    type={IconType.ArrowDown}
                    size="xsmall"
                    clickable
                    style={{ marginLeft: '5px', filter: 'grayscale(100%)' }}
                  />
                )}
              </div>
              <div
                style={{
                  display: 'inline-block',
                  textAlign: 'right',
                  padding: '10px 0px',
                  fontWeight: 'normal',
                }}
              >
                {selectedOptionsCount} / {options.length}
              </div>
            </div>
          </div>
        </components.GroupHeading>
      </div>
    );
  }

  function CustomPlaceholder(
    props: PlaceholderProps<
      SelectOption,
      false,
      GroupTypeBase<SelectOption>
    > & {
      itemSelections: string[] | undefined;
      hideTags?: boolean | undefined;
    },
  ) {
    const { itemSelections } = props;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0px 5px',
        }}
      >
        <div>{placeholder}</div>
        <div className={styles.placeholderCount}>
          {itemSelections?.length || 0}
          {' / '}
          {Object.keys(allItemsMap).length}
        </div>
        {additionalPlaceholder && (
          <div className={styles.placeholderCount}>{additionalPlaceholder}</div>
        )}
      </div>
    );
  }

  return (
    <Select
      isClearable={isClearable}
      hideTags={hideTags}
      menuPlacement={menuPlacement}
      components={{
        ClearIndicator: (props) => {
          return (
            <components.ClearIndicator
              {...props}
              children={
                <div style={{ color: '#333', cursor: 'pointer' }}>
                  X Clear All
                </div>
              }
            />
          );
        },
        GroupHeading: (props) => (
          <CustomGroupHeading
            {...props}
            itemSelections={itemSelections}
            updateItems={updateItems}
            removeItem={removeItem}
            showCheckbox={!hideGroupCheckbox}
          />
        ),
        Placeholder: CustomPlaceholderOverride
          ? (props) => (
              <CustomPlaceholderOverride
                {...props}
                itemSelections={itemSelections}
                hideTags={hideTags}
              />
            )
          : (props) => (
              <CustomPlaceholder
                {...props}
                itemSelections={itemSelections}
                hideTags={hideTags}
              />
            ),
      }}
      className={cx(
        styles.selector,
        sidebar && styles.sidebar,
        styles.itemSelect,
      )}
      options={Object.values(allItemGroups)}
      value={itemSelections
        .map((value) => {
          if (allItemsMap[value]) {
            return allItemsMap[value];
          }
          return { value: '', label: value };
        })
        .filter((e) => e?.value)}
      onChange={updateItems}
      onMenuClose={onMenuClose}
      placeholder={placeholder}
      margin="none"
      onRemoveOption={(option) => removeItem([option])}
      isMulti={true as false}
      customMultiValueListValues={customMultiValueListValues}
    />
  );
};
