import React from 'react';
import { createUseStyles } from 'react-jss';
import userFeedbackIcon from './user-feedback-icon.svg';

interface FeedbackIconProps {
  value: number;
}

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '24px',
    height: '24px',
  },
  value: {
    // center the value in the circle
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#6D00D7',
    fontSize: '12px',
  },
  icon: {
    width: '24px',
    height: '24px',
  },
});

const FeedbackIcon = ({ value }: FeedbackIconProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <img
        className={styles.icon}
        src={userFeedbackIcon}
        alt="user feedback square"
      />
      <span className={styles.value}>{value}</span>
    </div>
  );
};

export default FeedbackIcon;
