import { Breakpoints } from '@ateams/components';
import {
  BasicConnectionObjectV2,
  ConnectionObjectV2,
  ConnectionStatus,
  ConnectionType,
} from '@a_team/models/dist/ConnectionObject';
import {
  CurrentUserObject,
  UserCardObject,
} from '@a_team/models/dist/UserObject';
import { Modal } from '@a_team/ui-components';
import React, { ReactElement, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { ModalBasicProps } from '..';
import { ConnectModalScreens } from './types';
import ProfilePictures from './partials/ProfilePictures';
import CancelConnectionRequest from './screens/CancelConnectionRequest';
import ConnectionConfirmed from './screens/ConnectionConfirmed';
import DeleteConnection from './screens/DeleteConnection';
import Home from './screens/Home';
import SetWorkingExperience from './screens/SetWorkingExperience';
import { ConnectionsModalSources } from '@ateams/analytics/dist/platform';
import RejectConnectionRequest from './screens/RejectConnectionRequest';
import { getConnection } from './utils/utils';
import NoLongerAvailable from './screens/NoLongerAvailable';

interface ConnectModalV2Props extends ModalBasicProps {
  currentUser: CurrentUserObject;
  userToConnect: UserCardObject;
  source: ConnectionsModalSources;
  connectActionOnCompleted?: (connection: ConnectionObjectV2) => void;
}

const useStyles = createUseStyles<{
  screen: ConnectModalScreens;
}>({
  modal: {
    padding: 0,
    '& > div:first-child': {
      padding: ({ screen }) =>
        [ConnectModalScreens.SetWorkingExperience].includes(screen) ? 0 : 24,
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '100% !important',
      maxWidth: '680px',
      // if screen is SetWorkingExperience padding is 0
      padding: ({ screen }) =>
        [ConnectModalScreens.SetWorkingExperience].includes(screen) ? 0 : 34,
      // select first div inside modal and set padding to 0 if screen is SetWorkingExperience
      '& > div:first-child': {
        padding: '0 !important',
      },
    },
  },
});

function ConnectModalV2({
  open,
  onClose,
  currentUser,
  userToConnect,
  source,
  connectActionOnCompleted,
}: ConnectModalV2Props): ReactElement {
  const [screen, setScreen] = useState<ConnectModalScreens>(
    ConnectModalScreens.Home,
  );
  const styles = useStyles({
    screen,
  });
  const [currentConnection, setCurrentConnection] =
    useState<BasicConnectionObjectV2 | null>(null);
  const [hideProfilePictures, setHideProfilePictures] =
    useState<boolean>(false);
  const [loadingConnectionType, setLoadingConnectionType] =
    useState<ConnectionType>();
  const connections = userToConnect?.connections;
  const workConnection = getConnection(ConnectionType.Work, connections);
  const teamUpConnection = getConnection(ConnectionType.TeamUp, connections);

  const [newlyCreatedExperiencesIds, setNewlyCreatedExperiencesIds] = useState<
    string[]
  >([]);

  useEffect(() => {
    const isWorkConnectionPending =
      workConnection?.status === ConnectionStatus.Pending;
    const isTeamUpConnectionPending =
      teamUpConnection?.status === ConnectionStatus.Pending;
    const isNotificationOrDiscovery =
      ConnectionsModalSources.DiscoveryNotifications === source ||
      ConnectionsModalSources.NotificationBubble === source;

    const isNoLongerAvailable =
      !isTeamUpConnectionPending &&
      !isWorkConnectionPending &&
      isNotificationOrDiscovery;

    if (isNoLongerAvailable) {
      setScreen(ConnectModalScreens.NoLongerAvailable);
    }
  }, [open]);

  const handleConnectionActionComplete = (connection: ConnectionObjectV2) => {
    setLoadingConnectionType(undefined);
    connectActionOnCompleted && connectActionOnCompleted(connection);
  };

  const handleConnectionAction = (type?: ConnectionType) => {
    setLoadingConnectionType(type);
  };

  const handleScreenClose = (keepModalOpen?: boolean) => {
    setCurrentConnection(null);
    setScreen(ConnectModalScreens.Home);
    setHideProfilePictures(false);
    if (!keepModalOpen) {
      onClose();
    }
  };

  const handleModalClose = () => {
    setScreen(ConnectModalScreens.Home);
    setHideProfilePictures(false);
    onClose();
  };

  const handleSetScreen = (
    screen: ConnectModalScreens,
    hidePictures?: boolean,
  ) => {
    setScreen(screen);
    setHideProfilePictures(Boolean(hidePictures));
  };

  const hiddenProfilePictures =
    hideProfilePictures || screen === ConnectModalScreens.NoLongerAvailable;

  return (
    <Modal
      variant="slideUp"
      isOpen={open}
      onClose={handleModalClose}
      className={styles.modal}
      shouldHideGradientStroke={true}
      zIndex={9999}
    >
      {!hiddenProfilePictures && (
        <ProfilePictures
          source={source}
          screen={screen}
          currentUserProfilePictureURL={currentUser.profilePictureURL}
          userToConnectProfilePictureURL={userToConnect.profilePictureURL}
        />
      )}

      {screen === ConnectModalScreens.NoLongerAvailable && (
        <NoLongerAvailable />
      )}

      {screen === ConnectModalScreens.Home && (
        <Home
          handleScreenClose={handleScreenClose}
          source={source}
          userToConnect={userToConnect}
          setCurrentConnection={setCurrentConnection}
          setScreen={setScreen}
          connectActionOnCompleted={handleConnectionActionComplete}
          onConnectionAction={handleConnectionAction}
          loadingConnectionType={loadingConnectionType}
        />
      )}

      {screen === ConnectModalScreens.CancelConnectionRequest &&
        currentConnection && (
          <CancelConnectionRequest
            userToConnect={userToConnect}
            connection={currentConnection}
            onClose={handleScreenClose}
            connectActionOnCompleted={handleConnectionActionComplete}
            onConnectionAction={handleConnectionAction}
          />
        )}

      {screen === ConnectModalScreens.RejectConnectionRequest &&
        currentConnection && (
          <RejectConnectionRequest
            source={source}
            handleScreenClose={handleScreenClose}
            userToConnect={userToConnect}
            connection={currentConnection}
            onClose={handleScreenClose}
            connectActionOnCompleted={handleConnectionActionComplete}
            handleSetScreen={handleSetScreen}
            onConnectionAction={handleConnectionAction}
          />
        )}

      {screen === ConnectModalScreens.SetWorkingExperience && (
        <SetWorkingExperience
          source={source}
          newlyCreatedExperiencesIds={newlyCreatedExperiencesIds}
          setNewlyCreatedExperiencesIds={setNewlyCreatedExperiencesIds}
          userToConnect={userToConnect}
          setCurrentConnection={setCurrentConnection}
          setScreen={handleSetScreen}
          connectActionOnCompleted={handleConnectionActionComplete}
          onClose={handleScreenClose}
          onConnectionAction={handleConnectionAction}
        />
      )}

      {screen === ConnectModalScreens.ConnectionConfirmed &&
        currentConnection && (
          <ConnectionConfirmed
            userToConnect={userToConnect}
            connection={currentConnection}
            onClose={handleScreenClose}
          />
        )}

      {screen === ConnectModalScreens.DeleteConnection && currentConnection && (
        <DeleteConnection
          userToConnect={userToConnect}
          connection={currentConnection}
          onClose={handleScreenClose}
          connectActionOnCompleted={handleConnectionActionComplete}
          onConnectionAction={handleConnectionAction}
        />
      )}
    </Modal>
  );
}

export default ConnectModalV2;
