export const ProfilePictureValidationLabels: Record<
  string,
  { title: string; description: string; type: 'warning' | 'error' }
> = {
  not_depict_human: {
    title: 'Does Not Depict a Human',
    description: 'Choose a real photo that clearly shows your face.',
    type: 'error',
  },
  sunglasses: {
    title: 'Wearing Sunglasses',
    description:
      'Avoid wearing anything that obscures your face, such as hats or sunglasses.',
    type: 'error',
  },
  group_picture: {
    title: 'More Than One Person Shown',
    description:
      'Make sure only you are in the photo to maintain focus on your profile.',
    type: 'error',
  },
  props: {
    title: 'Using Accessories',
    description:
      'Steer clear of accessories like headphones to maintain a professional appearance..',
    type: 'error',
  },
  not_wearing_clothes: {
    title: 'Clothing Not Detected',
    description: 'Make sure you are wearing clothing in your profile picture.',
    type: 'error',
  },
  image_not_color: {
    title: 'Black and White Image',
    description: 'Consider using a color image to stand out. ',
    type: 'warning',
  },
  lighting_issues: {
    title: 'Lighting Issues',
    description: 'Ensure your face is well-lit and avoid shadows.',
    type: 'warning',
  },
  distracting_background: {
    title: 'Distracting Background',
    description:
      'Ensure the background is neutral or blurred to keep the focus on you.',
    type: 'warning',
  },
  selfie: {
    title: 'Selfie Image',
    description:
      'Ask someone to take your photo instead of using a selfie for a more professional look.',
    type: 'warning',
  },
  illustration: {
    title: 'Illustration',
    description:
      'Use a real photo instead of an illustration to  represent yourself professionally.',
    type: 'warning',
  },
  face_far_away: {
    title: 'Looking Away from the Camera',
    description: 'Zoom in',
    type: 'warning',
  },
  face_too_close: {
    title: 'Zoom out',
    description:
      'Adjust the photo so your face covers no more than 80% of the frame.',
    type: 'warning',
  },
  angry: {
    title: 'Angry or Intimidating Expression',
    description:
      'Maintain a neutral and friendly expression to appear more approachable.',
    type: 'warning',
  },
};
