import { observer } from 'mobx-react';
import React, { ChangeEvent, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useScreenClass } from 'react-grid-system';
import Section from '../partials/Section';
import { GuidanceTooltip } from '../partials/Section/GuidanceTooltip/GuidanceTooltip';
import { TooltipContent } from '../partials/Section/TooltipContent';
import {
  BorderColors,
  BorderRadius,
  Breakpoints,
  Colors,
  FontSizes,
  FontWeights,
  HelpTooltip,
  Icon,
  IconType,
  Margins,
  Paddings,
  Spacing,
  TextColors,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';
import TextInput from '@src/components/Inputs/TextInput/TextInput';
import Profile from '@src/stores/Profile/Profile';
import { round } from 'lodash';
import MissionRole from '@a_team/models/dist/MissionRole';
import { GuidanceMessage } from '../partials/Section/GuidanceMessage';

interface MissionRoleRatesProps {
  profile?: Profile;
  currentRole?: MissionRole;
  readonly?: boolean;
  error?: boolean;
}

const useStyles = createUseStyles({
  rateSection: {
    marginBottom: Spacing.xxxLarge,
  },
  rateTitle: {
    fontWeight: FontWeights.semiBold,
    fontSize: FontSizes.regular,
    marginBottom: Spacing.small,
  },
  budget: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.small,
    marginBottom: Spacing.medium,
    color: TextColors.regularLight,
    fontSize: FontSizes.small,
    lineHeight: '16px',
  },
  rateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,

    '&.disabled': {
      color: TextColors.regularLight,
    },
  },
  rateLabel: {
    marginBottom: Spacing.small,
  },
  rateInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.small,
  },
  rateInput: {
    position: 'relative',
    margin: Margins.none,
  },
  prefix: {
    position: 'absolute',
    left: 10,
    top: 9,
  },
  suffix: {
    position: 'absolute',
    right: 10,
    top: 9,
  },
  input: {
    border: `1px solid ${BorderColors.lighter}`,
    borderRadius: BorderRadius.default,
    padding: '8px 30px 8px 26px',
    width: 140,
    fontWeight: FontWeights.regular,
  },
  disabledToolip: {
    background: Colors.dark,
    fontSize: FontSizes.small,
    padding: Paddings.small,
    color: Colors.lighter,
    borderRadius: BorderRadius.medium,
  },
  rateHint: {
    display: 'flex',
    background: Colors.backgroundLight,
    padding: '9px 12px',
    borderRadius: 4,
    gap: 8,
  },
  helpTooltip: {
    width: '300px !important',
    left: 'calc(50% - 50px) !important',
  },
  rateTooltip: {
    padding: 0,
  },
  tooltipTitle: {
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.small,
    marginBottom: 16,
    textAlign: 'left',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  break: {
    margin: 0,
    background: Colors.regularLight,
  },
  bold: {
    fontWeight: FontWeights.bold,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    rateWrapper: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    rateLabel: {
      marginBottom: 0,
    },
  },
});

const AVG_WEEKS_PER_MONTH = 4.33;
const HOURS_PER_WEEK = 40;
const HIDE_HOURLY_EQUIVALENT = true;

const MissionRoleRates = (props: MissionRoleRatesProps) => {
  const { profile, currentRole, error, readonly } = props;
  const styles = useStyles();
  const screenClass = useScreenClass();

  const monthlyRate = useMemo(() => {
    const builderDefaultMinRate = profile?.minimumMonthlyRate ?? 0;
    const builderApplicationMinRate =
      profile?.application?.monthlyRateRangeMin ?? 0;

    if (builderApplicationMinRate > 0) {
      return builderApplicationMinRate;
    }

    return builderDefaultMinRate > 0 ? builderApplicationMinRate : '';
  }, [profile?.minimumMonthlyRate, profile?.application?.monthlyRateRangeMin]);

  const handleHourlyMinRateChange = (e: ChangeEvent<HTMLInputElement>) =>
    profile?.application?.setMinHourlyRate(Number(e.target.value));

  const handleHourlyMaxRateChange = (e: ChangeEvent<HTMLInputElement>) =>
    profile?.application?.setMaxHourlyRate(Number(e.target.value));

  const handleMinHourlyRateChangeOnBlur = () => {
    if (
      profile?.application?.minHourlyRate &&
      profile?.application.maxHourlyRate &&
      profile?.application.minHourlyRate > profile?.application.maxHourlyRate
    ) {
      profile.application.setMaxHourlyRate(0);
    }
  };

  const handleMaxHourlyRateChangeOnBlur = () => {
    if (
      profile?.application?.minHourlyRate &&
      profile?.application.maxHourlyRate &&
      profile?.application.minHourlyRate > profile?.application.maxHourlyRate
    ) {
      profile.application.setMinHourlyRate(0);
    }
  };

  const handleMonthlyRateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const minrate = Number(e.target.value);

    profile?.application?.setMonthlyRateRange({
      min: minrate,
      max: minrate,
    });
  };

  const tooltipText = useMemo(() => {
    if (screenClass === 'xs') return undefined;
    let tooltip =
      'Setting a top and bottom rate makes it easier to connect with more companies who are at different stages of growth.\n' +
      '\n' +
      'The rate that you set here is your take-home rate. The client will pay rate that is 20% higher rate which means A.Team will collect 16.67% of the total payout. For example, if you set the rate to $100 the client will pay $120 and A.Team will collect $20 as fees';
    error && (tooltip = 'Please enter a minimum and maximum rate');
    return tooltip;
  }, [error, screenClass]);

  const hasFullAvailability = useMemo(() => {
    if (!profile?.availability?.weeklyHoursAvailable) {
      return false;
    }

    return profile.availability.weeklyHoursAvailable >= 40;
  }, [profile?.availability?.weeklyHoursAvailable]);

  const isHourlRateAboveRange = useMemo(() => {
    if (!currentRole?.showRateRangeToBuilders) {
      return false;
    }

    if (!currentRole?.builderRateMax) {
      return false;
    }

    const minHourlyRate = profile?.application?.minHourlyRate ?? 0;
    const maxHourlyRate = profile?.application?.maxHourlyRate ?? 0;

    return (
      minHourlyRate > currentRole.builderRateMax &&
      maxHourlyRate > currentRole.builderRateMax
    );
  }, [
    profile?.application?.minHourlyRate,
    profile?.application?.maxHourlyRate,
    currentRole?.showRateRangeToBuilders,
    currentRole?.builderRateMax,
  ]);

  const isMonthlyRateAboveRange = useMemo(() => {
    if (!currentRole?.showRateRangeToBuilders) {
      return false;
    }

    if (!currentRole?.builderMonthlyRateMax) {
      return false;
    }

    return Number(monthlyRate) > currentRole.builderMonthlyRateMax;
  }, [
    monthlyRate,
    currentRole?.showRateRangeToBuilders,
    currentRole?.builderMonthlyRateMax,
  ]);

  const hourlyRateEquivalent = useMemo(() => {
    const rate = Number(monthlyRate);

    if (rate === 0) {
      return 0;
    }

    return rate / AVG_WEEKS_PER_MONTH / HOURS_PER_WEEK;
  }, [monthlyRate]);

  const monthlyRateEquivalent = useMemo(() => {
    return hourlyRateEquivalent * HOURS_PER_WEEK * AVG_WEEKS_PER_MONTH;
  }, [hourlyRateEquivalent]);

  const shouldShowHourlyEquivalent = useMemo(() => {
    if (HIDE_HOURLY_EQUIVALENT) {
      return false;
    }

    return hasFullAvailability && monthlyRate !== '' && monthlyRate > 0;
  }, [hasFullAvailability, monthlyRate]);

  useEffect(() => {
    if (!hasFullAvailability) {
      profile?.application?.removeMonthlyRateRange();
    }
  }, [hasFullAvailability]);

  const renderHourlyBudget = () => {
    if (
      readonly ||
      !currentRole?.showRateRangeToBuilders ||
      !currentRole.builderRateMin ||
      !currentRole.builderRateMax
    ) {
      return null;
    }

    return (
      <div className={styles.budget}>
        <Icon type={IconType.Cash} size={'exact'} className={styles.cashIcon} />
        Hourly budget of ${Math.round(currentRole.builderRateMin)} — $
        {Math.round(currentRole.builderRateMax)}
      </div>
    );
  };

  const renderMonthlyBudget = () => {
    if (
      readonly ||
      !currentRole?.showRateRangeToBuilders ||
      !currentRole.collectMonthlyRate
    ) {
      return null;
    }

    return (
      <div className={styles.budget}>
        <Icon type={IconType.Cash} size={'exact'} className={styles.cashIcon} />
        Monthly budget of $
        {round(currentRole.builderMonthlyRateMin ?? 0, 2).toLocaleString()} — $
        {round(currentRole.builderMonthlyRateMax ?? 0, 2).toLocaleString()}
      </div>
    );
  };

  // avoids optional chaining afterwards
  if (!profile || !profile.application) {
    return null;
  }

  return (
    <Section
      title="Rate"
      type="large"
      tooltipText={tooltipText}
      guidanceTooltip={
        !profile.application.readonly ? (
          <GuidanceTooltip label={TooltipContent.missionRoleRates.label}>
            {TooltipContent.missionRoleRates.component}
          </GuidanceTooltip>
        ) : undefined
      }
      className={styles.rateSection}
    >
      <Section
        title={
          <>
            <h4 className={styles.rateTitle}>
              Hourly missions — variable hours based on company needs.
            </h4>
            {renderHourlyBudget()}
          </>
        }
      >
        {isHourlRateAboveRange && (
          <GuidanceMessage
            text={profile.application.guidanceMessages.rateAbovePreferredRange}
            type={'secondary'}
            iconType={IconType.StarsPurple}
          />
        )}
        <div className={styles.rateWrapper}>
          <div className={styles.rateLabel}>My hourly rate is</div>
          <div className={styles.rateInputWrapper}>
            <div className={styles.rateInput}>
              <span className={styles.prefix}>$</span>
              <TextInput
                data-testing-id={'mission-application-min-rate-text-input'}
                disabled={readonly}
                variant="dashed"
                placeholder={'XXX'}
                className={styles.input}
                value={profile?.application.minHourlyRate || ''}
                onChange={handleHourlyMinRateChange}
                onBlur={handleMinHourlyRateChangeOnBlur}
                error={
                  (profile?.showErrors &&
                    profile?.application &&
                    profile.application.hasHourlyRateError) ??
                  false
                }
              />
              <span className={styles.suffix}>/h</span>
            </div>
            <span>to</span>
            <div className={styles.rateInput}>
              <span className={styles.prefix}>$</span>
              <TextInput
                data-testing-id={'mission-application-max-rate-text-input'}
                disabled={readonly}
                variant="dashed"
                placeholder={'XXX'}
                className={styles.input}
                value={profile?.application.maxHourlyRate || ''}
                onChange={handleHourlyMaxRateChange}
                onBlur={handleMaxHourlyRateChangeOnBlur}
                error={
                  (profile?.showErrors &&
                    profile?.application &&
                    profile.application.hasHourlyRateError) ??
                  false
                }
              />
              <span className={styles.suffix}>/h</span>
            </div>
          </div>
        </div>
      </Section>
      {currentRole?.collectMonthlyRate && (
        <Section
          title={
            <>
              <h4 className={styles.rateTitle}>
                Monthly missions — fixed 40h per week and consistent income.
              </h4>
              {renderMonthlyBudget()}
            </>
          }
          className={styles.rateSection}
        >
          {isMonthlyRateAboveRange && (
            <GuidanceMessage
              text={
                profile.application.guidanceMessages.rateAbovePreferredRange
              }
              type={'secondary'}
              iconType={IconType.StarsPurple}
            />
          )}
          <div
            className={cx(styles.rateWrapper, {
              disabled: !hasFullAvailability,
            })}
          >
            <div className={styles.rateLabel}>My monthly rate is</div>
            <div className={styles.rateInputWrapper}>
              <div className={styles.rateInput}>
                <span className={styles.prefix}>$</span>
                <TextInput
                  disabled={readonly || !hasFullAvailability}
                  variant="dashed"
                  placeholder={'XXX'}
                  className={styles.input}
                  value={profile?.application.monthlyRateRangeMin || ''}
                  onChange={handleMonthlyRateChange}
                  onBlur={handleMonthlyRateChange}
                  error={
                    (profile?.showErrors &&
                      profile?.application &&
                      profile.application.hasMonthlyRateError) ??
                    false
                  }
                />
                <span className={styles.suffix}>/m</span>
              </div>
              {shouldShowHourlyEquivalent && (
                <div className={styles.rateHint}>
                  <span>{`Hourly equivalent — ${round(
                    hourlyRateEquivalent,
                    2,
                  ).toLocaleString()}/h`}</span>
                  <HelpTooltip className={styles.helpTooltip}>
                    <div className={styles.rateTooltip}>
                      <h4 className={styles.tooltipTitle}>
                        How is your rate calculated
                      </h4>
                      <div className={styles.table}>
                        <div className={styles.row}>
                          <span>Hourly rate</span>
                          <span>{`$${round(
                            hourlyRateEquivalent,
                            2,
                          ).toLocaleString()} per hour`}</span>
                        </div>
                        <div className={styles.row}>
                          <span>Hours per week</span>
                          <span>x 40</span>
                        </div>
                        <div className={styles.row}>
                          <span>Average weeks in a month</span>
                          <span>x 4.33</span>
                        </div>
                        <hr className={styles.break} />
                        <div className={styles.row}>
                          <span className={styles.bold}>Monthly rate</span>
                          <span className={styles.bold}>
                            ${round(monthlyRateEquivalent, 2).toLocaleString()}{' '}
                            per month
                          </span>
                        </div>
                      </div>
                    </div>
                  </HelpTooltip>
                </div>
              )}
              {!hasFullAvailability && (
                <div className={styles.disabledToolip}>
                  To add a monthly rate, update availability to 40h/week
                </div>
              )}
            </div>
          </div>
        </Section>
      )}
    </Section>
  );
};

export default observer(MissionRoleRates);
