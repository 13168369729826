// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { components, OptionProps } from 'react-select';
import React, { ReactElement } from 'react';
import Checkbox from '../Checkbox';
import { createUseStyles } from 'react-jss';
import Colors from '../theme/colors';

const useStyles = createUseStyles({
  checkboxWrapper: {
    display: 'flex',
    color: Colors.dark,
  },
  checkbox: {
    marginRight: 8,
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Option = (props: OptionProps<any, any>): ReactElement => {
  const styles = useStyles(props);
  return (
    <components.Option className={'select-option-checkbox-wrapper'} {...props}>
      <div
        className={styles.checkboxWrapper}
        style={{
          cursor: props.isDisabled ? 'default' : 'pointer',
        }}
      >
        <Checkbox
          readOnly
          margin={'none'}
          checked={props.isSelected}
          disabled={props.isDisabled}
          className={styles.checkbox}
          color={Colors.secondaryDark}
        />
        {props.label}
      </div>
    </components.Option>
  );
};
