import React, { ReactElement, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import CardList from '../CardList';
import CompletedMissionCard from '../MissionControlCard/CompletedMissionCard';
import { LoadingCards } from '@src/views/MissionControl/LoadingCards';
import { useGetCompletedMissionsQuery } from '@src/rq/missions';
import NoResults from '@src/components/NoResults';

const CompletedMissions = (): ReactElement => {
  const { missionControl, auth } = useStores();
  const { isLoading, isError } = useGetCompletedMissionsQuery();
  const completedMissions = useMemo(
    () => missionControl.getCompleted,
    [isLoading, isError, missionControl.getCompleted],
  );
  const loading = useMemo(
    () => isLoading && !completedMissions.length,
    [isLoading, completedMissions.length],
  );

  if (!loading && completedMissions.length === 0) {
    return (
      <NoResults
        imageType="noSearchResults"
        title="No completed missions found!"
        text={
          <>
            <p>Looks like you haven't completed any mission yet.</p>
          </>
        }
      />
    );
  }

  return (
    <CardList>
      {loading ? (
        <LoadingCards type={'completed'} />
      ) : (
        completedMissions.map((completedMission) => {
          const userRole = completedMission.roles.find(
            (role) =>
              role.user &&
              'uid' in role.user &&
              role.user.uid === auth.user?.uid,
          );

          if (!userRole) return <></>;

          return (
            <CompletedMissionCard
              key={completedMission.mid}
              mission={completedMission}
              userRole={userRole}
            />
          );
        })
      )}
    </CardList>
  );
};

export default observer(CompletedMissions);
