import React from 'react';

export const AttachmentIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g id="Attached file">
        <path
          id="Vector_02"
          opacity="0.6"
          d="M12.38 7.8011L12.849 7.33206C14.0147 6.16637 14.0147 4.27641 12.849 3.11073V3.11073C11.6833 1.94504 9.79336 1.94504 8.62767 3.11072L3.70278 8.03561C2.40758 9.33082 2.40757 11.4308 3.70278 12.726V12.726C4.99799 14.0212 7.09794 14.0212 8.39315 12.726L10.2693 10.8498C10.9169 10.2022 10.9169 9.15226 10.2693 8.50465V8.50465C9.6217 7.85705 8.57172 7.85705 7.92412 8.50465L5.81345 10.6153"
          stroke="#202020"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_01"
          d="M12.38 7.8011L12.849 7.33206C14.0147 6.16637 14.0147 4.27641 12.849 3.11073V3.11073C11.6833 1.94504 9.79336 1.94504 8.62767 3.11072L3.70278 8.03561C2.40758 9.33082 2.40757 11.4308 3.70278 12.726V12.726C4.99799 14.0212 7.09794 14.0212 8.39315 12.726L8.79552 12.3236"
          stroke="#202020"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
