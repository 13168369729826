import { ServiceAuth, ServiceEndpoint } from './utils';
import { QueryRequestPayload, QueryResult } from '@a_team/models/dist/misc';
import { TalentIndustry } from '@a_team/models/dist/TalentIndustry';

export const BasePath = '/talent-industries';

export interface TalentIndustriesQueryData extends QueryRequestPayload {
  ids?: string; // Comma separated list of ids
  searchTerm?: string;
  pageSize?: number;
}

export interface TalentIndustriesCreateData {
  name: string;
  description: string;
}

export default class TalentIndustriesEndpoint extends ServiceEndpoint {
  public queryTalentIndustries(
    auth: ServiceAuth,
    query: TalentIndustriesQueryData,
  ): Promise<QueryResult<TalentIndustry>> {
    return this.fetch(auth, BasePath, { ...query });
  }

  public getTalentIndustryById(
    talentIndustryId: string,
  ): Promise<TalentIndustry> {
    return this.fetch(null, `${BasePath}/${talentIndustryId}`, null, 'get');
  }

  public createTalentIndustry(
    auth: ServiceAuth,
    data: TalentIndustriesCreateData,
  ): Promise<TalentIndustry> {
    return this.fetch(auth, BasePath, null, 'post', data);
  }
}
