import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import Modal, { ModalBasicProps } from '..';
import SectionHeading from '@src/components/SectionHeading';
import {
  Button as CallToActionButton,
  Checkbox,
  Icon,
  IconType,
} from '@ateams/components';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import useToggle from '@src/hooks/useToggle';
import logo from './logo.svg';
import bg from './bg.png';
import { createUseStyles } from 'react-jss';
import {
  TalentPrivacyStatementLocation,
  TermsOfServiceLocation,
} from '@src/locations';
import { Breakpoints, Colors } from '@ateams/components';
import { useScreenClass } from 'react-grid-system';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { PasswordRequirements } from '@src/components/Modal/SetPasswordModal/PasswordRequirements';
import TextButton from '@src/components/TextButton';

interface Props extends ModalBasicProps {
  email: string;
  defaultPassword?: string;
  onSubmit: (password: string) => void;
  onTosChange?: (checked: boolean) => void;
}

const useStyles = createUseStyles({
  '@global': {
    '.tippy-popper': {
      zIndex: '999999 !important',
    },
  },
  container: {
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  main: {
    padding: '30px 15px',
    background: Colors.backgroundWhite,
    flex: 1,
    marginTop: -10,
    zIndex: 99,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  sidebar: {
    position: 'relative',
    height: 75,
    background: Colors.secondary,
    backgroundImage: `url(${bg}), linear-gradient(181.93deg, #9A00E3 0.89%, #8A00E3 98.37%)`,
    backgroundSize: 'cover',
  },
  logo: {
    position: 'absolute',
    top: 15,
    left: 15,
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  input: {
    maxWidth: '100%',
    margin: '8px 0',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 22,
  },
  checkbox: {
    marginTop: 24,
    alignItems: 'flex-start',
    '& > div': {
      marginTop: 5,
    },
  },
  passwordContainer: {
    marginLeft: 0,
    flex: 1,
  },
  description: {
    marginBottom: 16,
  },
  button: {},
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      flexDirection: 'row',
      width: '80% !important',
      height: 'auto',
    },
    sidebar: {
      height: 'auto',
      maxWidth: '30em',
      width: '35%',
    },
    logo: {
      top: 44,
      left: 44,
    },
    main: {
      width: '62%',
      padding: 48,
      borderBottomRightRadius: 16,
      borderTopRightRadius: 16,
      borderTopLeftRadius: 0,
    },
    form: {
      flexDirection: 'row',
    },
    input: {
      maxWidth: '16em',
      margin: '2em 0 !important',
    },
    buttonContainer: {
      display: 'block',
      marginTop: 20,
    },
    checkbox: {
      marginTop: 0,
      '& > div': {
        marginTop: 2,
      },
    },
    passwordContainer: {
      marginLeft: 20,
    },
    description: {
      marginBottom: 0,
    },
  },
  [`@media (min-width: ${Breakpoints.xl}px)`]: {
    container: {
      maxWidth: '50% !important',
      fontSize: '2em',
    },
    input: {
      marginBottom: '2em',
      '& .input-label': {
        fontSize: '0.9em',
        marginBottom: '0.8em',
      },
    },
    button: {
      height: '2em',
      padding: '0.8em 1em',
    },
  },
});

const SetPasswordModal = (props: Props): ReactElement => {
  const styles = useStyles();
  const screenClass = useScreenClass();
  const isMobile = screenClass === 'xs' || screenClass === 'sm';
  const { onClose, email, onSubmit, defaultPassword, onTosChange, ...left } =
    props;
  const [password, setPassword] = useState(defaultPassword || '');
  const [showPassword, toggleShowPassword] = useToggle();
  const [acceptedTos, toggleAcceptedTos] = useToggle();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    onTosChange && onTosChange(acceptedTos);
  }, [acceptedTos]);

  const canSubmit = useMemo(() => {
    return password && (onTosChange ? acceptedTos : true);
  }, [password, onTosChange, acceptedTos]);

  const hasMinChars = useMemo(() => {
    return password.length >= 6;
  }, [password]);

  const hasReqChars = useMemo(() => {
    return password.length && !password.match(/^[a-z0-9]+$/);
  }, [password]);

  const validatePasswordAndSubmit = () => {
    if (!hasMinChars || !hasReqChars) {
      setShowError(true);
      return;
    }

    onSubmit(password);
  };

  return (
    <Modal
      onClose={onClose}
      hideCloseButton
      className={styles.container}
      style={{
        maxWidth: 880,
        display: 'flex',
        padding: 0,
        border: 'none',
        background: 'none',
        ...(isMobile && {
          maxHeight: '100vh',
          maxWidth: '100%',
          width: '100%',
          borderRadius: 0,
          fontSize: 15,
        }),
      }}
      {...left}
    >
      <div className={styles.sidebar}>
        <img src={logo} alt={'A.Team Logo'} className={styles.logo} />
      </div>
      <div className={styles.main}>
        <SectionHeading isFirst style={{ marginBottom: 20 }}>
          Set My Password
        </SectionHeading>
        <p className={styles.description}>
          Let's finish getting your A.Team account set up.
        </p>
        <div className={styles.form}>
          <OutlinedInput
            label={'Email Address'}
            value={email}
            disabled
            className={styles.input}
          />
          <div className={styles.passwordContainer}>
            <TooltipWrapped
              position={'right-start'}
              arrow
              disabled={isMobile}
              html={
                <PasswordRequirements
                  showError={showError}
                  hasMinChars={hasMinChars}
                  hasReqChars={!!hasReqChars}
                />
              }
            >
              <OutlinedInput
                label={'Password'}
                placeholder={'Company_q9613W...'}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setShowError(false);
                }}
                type={showPassword ? 'text' : 'password'}
                required
                error={showError && (!hasReqChars || !hasMinChars)}
                className={styles.input}
                endAdornment={
                  <Icon
                    type={showPassword ? IconType.EyeClosed : IconType.Eye}
                    title={showPassword ? 'Hide Password' : 'Show Password'}
                    onClick={toggleShowPassword}
                  />
                }
              />
              {isMobile && (
                <PasswordRequirements
                  showError={showError}
                  hasMinChars={hasMinChars}
                  hasReqChars={!!hasReqChars}
                />
              )}
            </TooltipWrapped>
          </div>
        </div>
        {onTosChange && (
          <Checkbox
            label={
              <span style={{ lineHeight: '24px' }}>
                I agree to the A.Team{' '}
                <TextButton
                  highlight
                  onClick={() => window.open(TermsOfServiceLocation, 'blank')}
                >
                  Terms of Service
                </TextButton>{' '}
                and{' '}
                <TextButton
                  highlight
                  onClick={() =>
                    window.open(TalentPrivacyStatementLocation, 'blank')
                  }
                >
                  Privacy Policy
                </TextButton>
                .
              </span>
            }
            margin={'none'}
            checked={acceptedTos}
            onChange={(e) => toggleAcceptedTos(e.target.checked)}
            className={styles.checkbox}
          />
        )}
        <div className={styles.buttonContainer}>
          <CallToActionButton
            onClick={validatePasswordAndSubmit}
            size={'small'}
            width={'auto'}
            disabled={!canSubmit}
            className={styles.button}
          >
            Confirm
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
};

export default SetPasswordModal;
