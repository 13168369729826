import Cookies from 'js-cookie';
import { TOKEN_COOKIE_NAME } from '@src/stores/Auth';

let authToken: string | undefined;

export function getAuthToken() {
  if (!authToken) {
    authToken = Cookies.get(TOKEN_COOKIE_NAME);
  }
  return authToken ?? '';
}

export const MIN_SCORE = 3;
