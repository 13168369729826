import { Icon, IconType } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface SuggestionsBannerProps {
  title: string;
  description: string;
  className?: string;
}

const useStyles = createUseStyles<{
  title: string;
  description: string;
}>({
  container: {
    padding: 12,
    borderRadius: 4,
    marginBottom: 24,
    background: '#FCFAFF',
  },
  title: {
    color: '#6D00D7',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    fontWeight: 500,
  },
  description: {
    marginTop: 8,
  },
});

const SuggestionsBanner = ({
  title,
  description,
  className,
}: SuggestionsBannerProps): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.title}>
        <Icon size="smaller" type={IconType.SuggestedTeams} />
        {title}
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default SuggestionsBanner;
