import {
  UserCardObject,
  UserStatus,
  UserType,
} from '@a_team/models/dist/UserObject';

// We mock this data so we can use the <DiscoveryUserCardV2> component without having to re-write the component
export const sampleOnboardingUsers: UserCardObject[] = [
  {
    fullName: 'Arlene Mckinney',
    profilePictureURL:
      'https://ucarecdn.com/d1933b72-6a28-42c5-b0cb-9db35dc03501/-/preview/512x512/',
    roleCategory: null,
    connection: null,
    connections: [],
    uid: '123',
    firstName: 'Arlene',
    lastName: 'Mckinney',
    username: 'arlene',
    profileURL: '',
    title: 'Product manager',
    type: UserType.User,
    verified: true,
    status: UserStatus.Active,
    badges: [],
    narrativeConnection: {
      types: ['teams'],
      companies: [],
      projectsCount: 0,
      mutualConnectionsCount: 0,
      missionsCount: 0,
    },
    talentProfile: {
      talentSpecializations: {
        mainTalentSpecialization: {
          name: 'Product manager',
          id: 'product-manager',
          talentCategoryIds: ['product'],
        },
        additionalTalentSpecializations: [],
      },
      talentSkills: {
        mainTalentSkills: [],
        additionalTalentSkills: [],
      },
    },
  },
  {
    fullName: 'Kirill Petrushevskiy',
    profilePictureURL:
      'https://ucarecdn.com/6458b44a-722a-4b0d-a682-733cd3354217/-/preview/512x512/',
    roleCategory: null,
    connection: null,
    connections: [],
    uid: '456',
    firstName: 'Kirill',
    lastName: 'Petrushevskiy',
    username: 'kirill',
    profileURL: '/',
    title: 'Product manager',
    type: UserType.User,
    verified: true,
    status: UserStatus.Active,
    badges: [],
    narrativeConnection: {
      types: ['companies'],
      companies: ['Microsoft'],
      projectsCount: 0,
      mutualConnectionsCount: 0,
      missionsCount: 0,
    },
    talentProfile: {
      talentSpecializations: {
        mainTalentSpecialization: {
          name: 'Product manager',
          id: 'product-manager',
          talentCategoryIds: ['product'],
        },
        additionalTalentSpecializations: [],
      },
      talentSkills: {
        mainTalentSkills: [],
        additionalTalentSkills: [],
      },
    },
  },
  {
    fullName: 'Adam Surströmming',
    profilePictureURL:
      'https://ucarecdn.com/ae71b3b9-ffac-4a9f-a9c0-80a89c9ea43e/-/preview/512x512/',
    roleCategory: null,
    connection: null,
    connections: [],
    uid: '789',
    firstName: 'Adam',
    lastName: 'Surströmming',
    username: 'adam',
    profileURL: '/',
    title: 'Product manager',
    type: UserType.User,
    verified: true,
    status: UserStatus.Active,
    badges: [],
    narrativeConnection: {
      types: ['mutualConnections'],
      companies: [],
      projectsCount: 0,
      mutualConnectionsCount: 20,
      missionsCount: 0,
    },
    talentProfile: {
      talentSpecializations: {
        mainTalentSpecialization: {
          name: 'Product manager',
          id: 'product-manager',
          talentCategoryIds: ['product'],
        },
        additionalTalentSpecializations: [
          {
            name: 'UX Designer',
            id: 'ux-designer',
            talentCategoryIds: ['design'],
          },
          {
            name: 'Frontend Developer',
            id: 'frontend-developer',
            talentCategoryIds: ['development'],
          },
        ],
      },
      talentSkills: {
        mainTalentSkills: [],
        additionalTalentSkills: [],
      },
    },
  },
];
