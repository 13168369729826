import { Checkbox } from '@a_team/ui-components';
import { noop } from 'lodash/fp';
import React from 'react';
import { useTeammateRecommendationCardStyles } from '.';
import Skeleton from 'react-loading-skeleton';

const TeammateRecommendationCard = (): JSX.Element => {
  const styles = useTeammateRecommendationCardStyles({
    selected: false,
  });

  return (
    <div className={styles.container}>
      <Checkbox
        disabled
        className={styles.checkbox}
        checked={false}
        onChange={noop}
      />
      <div className={styles.userInfo}>
        <div className={styles.header}>
          <Skeleton height={108} width={108} circle />
        </div>

        <div className={styles.fullName}>
          <Skeleton width={150} />
        </div>
        {
          <div className={styles.mainRole}>
            <Skeleton width={100} />
          </div>
        }

        <div>
          <div className={styles.connectionInsightContainer}>
            <Skeleton width={200} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeammateRecommendationCard;
