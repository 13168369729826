import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Icon, IconType } from '@ateams/components';

interface ComingSoonProps {
  className?: string;
}

const useStyles = createUseStyles({
  banner: {
    display: 'flex',
    border: '1px solid #D3D6DC',
    height: '100%',
    borderRadius: '8px',
    background: 'white',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  info: {
    marginRight: '8px',
  },
  notice: {
    fontSize: '12px',
    lineHeight: 1.1,
  },
  link: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
  },
});

export const ComingSoon: React.FC<ComingSoonProps> = ({ className }) => {
  const styles = useStyles();

  return (
    <div className={cx(styles.banner, className)}>
      <Icon type={IconType.Points} size="large" className={styles.info} />
      <div className={styles.notice}>
        <span>
          <strong>Introducing the Build Token:</strong> a new way to capture and
          reward the value you bring to the network we're building together.
        </span>{' '}
        <a
          href="https://www.a.team/build-token"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          Read more &rarr;
        </a>
      </div>
    </div>
  );
};
