import React, { ReactElement, useEffect, useState } from 'react';
import SectionHeading from '@src/components/SectionHeading';
import {
  Button as CallToActionButton,
  CheckboxProps,
  Select,
  SelectProps,
} from '@ateams/components';
import Modal, { ModalBasicProps } from '..';
import { useScreenClass } from 'react-grid-system';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import {
  AvailabilityReminderLabels,
  AvailabilityTypeLabels,
} from '@src/stores/NotificationPreferencesUser';
import {
  AvailabilityData,
  AvailabilitySummaryObject,
  AvailableType,
  ReminderPeriod,
} from '@a_team/models/dist/AvailabilityObject';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { DateInput, DateInputProps } from '@src/components/DateInput';
import { add, setHours } from 'date-fns';
import Checkbox from '@src/components/Checkbox';

interface Props extends ModalBasicProps {
  currentAvailability?: AvailabilitySummaryObject;
  onSubmit(data: AvailabilityData): void | Promise<void>;
}

const MAX_AVAILABILITY_HOURS = 80;

const AVAILABILITY_STATUS_OPTIONS = Object.entries(AvailabilityTypeLabels).map(
  ([availabilityType, label]) => ({ label, value: availabilityType }),
);

const AVAILABILITY_REMIND_ME_AT_OPTIONS = Object.entries(
  AvailabilityReminderLabels,
).map(([offset, label]) => ({ label, value: offset }));

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    color: 'black',
    backgroundColor: '#f7f7f7',
    padding: '24px',
  },
  title: {
    fontSize: '20px',
    lineHeight: '26px',
    margin: 0,
    marginBottom: '8px',
  },
  descriptionText: {
    fontSize: '15px',
    lineHeight: '24px',
    margin: 0,
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '12px',
  },
  inputLabel: {
    fontWeight: 500,
  },
  inputContainer: {
    width: '232px',
    height: '40px',
  },
  weeklyHoursAvailableContainer: {
    width: '100px',
  },
  weeklyHoursAvailableControl: {
    height: '40px',
    width: '100px',
    paddingRight: '12px',
    minHeight: 0,
    padding: 0,
  },
  weeklyHoursAvailableInput: {
    padding: 0,
    textAlign: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmButton: {
    width: '152px',
    height: '40px',
    padding: '11px 32px',
    margin: 0,
    marginTop: '14px',
    fontSize: '15px',
    lineHeight: '18px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      color: 'black',
      backgroundColor: '#f7f7f7',
    },
  },
});

export function UpdateAvailabilityModal(props: Props): ReactElement {
  const { currentAvailability, open, onClose, onSubmit } = props;

  const styles = useStyles();
  const screenClass = useScreenClass();

  const [availabilityType, setAvailabilityType] = useState<AvailableType>(
    currentAvailability?.type || AvailableType.UnknownAvailability,
  );
  const [weeklyHoursAvailable, setWeeklyHoursAvailable] = useState<
    number | undefined
  >(currentAvailability?.weeklyHoursAvailable);
  const [availableFrom, setAvailableFrom] = useState(
    currentAvailability?.availableFrom ||
      add(new Date(), { days: 1 }).toISOString(),
  );
  const [reminderPeriod, setReminderPeriod] = useState(ReminderPeriod.OneMonth);
  const [remindMeAt, setRemindMeAt] = useState<string>('');
  const [keepNotifications, setKeepNotifications] = useState<boolean>(true);

  useEffect(() => {
    const today = setHours(new Date(), 0).setMinutes(0);

    let months = 0;
    switch (reminderPeriod) {
      case ReminderPeriod.OneMonth:
        months = 1;
        break;

      case ReminderPeriod.ThreeMonths:
        months = 3;
        break;

      case ReminderPeriod.SixMonths:
        months = 6;
        break;
    }

    setRemindMeAt(add(today, { months }).toISOString());
  }, [reminderPeriod]);

  const onAvailabilityTypeChange: SelectProps['onChange'] = (option) => {
    if (option) {
      setAvailabilityType(option.value as AvailableType);
    }
  };

  const onWeeklyHoursAvailableChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!e.target.value) {
      setWeeklyHoursAvailable(undefined);

      return;
    }

    const hours = parseInt(e.target.value, 10);
    if (!isNaN(hours) && hours >= 0 && hours <= MAX_AVAILABILITY_HOURS) {
      setWeeklyHoursAvailable(hours);
    }
  };

  const onShowAvailableFromChange: DateInputProps['onChange'] = (date) => {
    if (date instanceof Date) {
      setAvailableFrom(date.toISOString());
    }
  };

  const onReminderPeriodChange: SelectProps['onChange'] = (option) => {
    if (option) {
      setReminderPeriod(option.value as ReminderPeriod);
    }
  };

  const onKeepNotificationsChange: CheckboxProps['onChange'] = (e) => {
    setKeepNotifications(e.target.checked);
  };

  /**
   * Constructs the AvailabilityData object from the state
   * of the inputs. Each AvailableType has different properties.
   */
  const onConfirm = () => {
    const data: Partial<AvailabilityData> = {
      type: availabilityType,
    };

    if (data.type === AvailableType.Now) {
      data.weeklyHoursAvailable = weeklyHoursAvailable || 0;
    }

    if (data.type === AvailableType.FutureDate) {
      data.weeklyHoursAvailable = weeklyHoursAvailable || 0;
      data.date = availableFrom;
    }

    if (data.type === AvailableType.NotAvailable) {
      data.keepNotifications =
        keepNotifications === true
          ? keepNotifications
          : undefined; /** For a weird reason instead of using false we use undefined */
      data.remindMeAt = remindMeAt;
    }

    onSubmit(data as AvailabilityData);
  };

  const showWeeklyHoursAvailable = [
    AvailableType.Now,
    AvailableType.FutureDate,
  ].includes(availabilityType);
  const showAvailableFrom = availabilityType === AvailableType.FutureDate;
  const showRemindMeAt = availabilityType === AvailableType.NotAvailable;
  const showKeepNotifications = availabilityType === AvailableType.NotAvailable;

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        width: screenClass === 'xs' ? '95%' : '480px',
        transition: 'all 0.5s',
        padding: 0,
      }}
    >
      <div className={styles.container}>
        <SectionHeading isFirst className={styles.title}>
          Update Your Availability
        </SectionHeading>
        <p className={styles.descriptionText}>
          Now that you're starting on a new mission, please update your
          availability:
        </p>

        <div className={styles.inputRow}>
          <div className={styles.inputLabel}>Availability Status</div>

          <div className={styles.inputContainer}>
            <Select
              onChange={onAvailabilityTypeChange}
              options={AVAILABILITY_STATUS_OPTIONS}
              value={AVAILABILITY_STATUS_OPTIONS.find(
                ({ value }) => value === availabilityType,
              )}
              margin="none"
            />
          </div>
        </div>

        {showWeeklyHoursAvailable && (
          <div className={styles.inputRow}>
            <div className={styles.inputLabel}>Current Availability</div>

            <OutlinedInput
              value={weeklyHoursAvailable}
              endAdornment={'hr/wk'}
              placeholder={'XX'}
              onChange={onWeeklyHoursAvailableChange}
              className={styles.weeklyHoursAvailableContainer}
              inputClassName={styles.weeklyHoursAvailableInput}
              controlClassName={styles.weeklyHoursAvailableControl}
              margin={'none'}
            />
          </div>
        )}

        {showAvailableFrom && (
          <div className={styles.inputRow}>
            <div className={styles.inputLabel}>Will be available</div>

            <DateInput
              minDate={add(new Date(), { days: 1 })}
              selected={new Date(availableFrom)}
              onChange={onShowAvailableFromChange}
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                },
              }}
            />
          </div>
        )}

        {showRemindMeAt && (
          <div className={styles.inputRow}>
            <div className={styles.inputLabel}>Availability Reminder</div>

            <div className={styles.inputContainer}>
              <Select
                onChange={onReminderPeriodChange}
                options={AVAILABILITY_REMIND_ME_AT_OPTIONS}
                value={AVAILABILITY_REMIND_ME_AT_OPTIONS.find(
                  ({ value }) => value === reminderPeriod,
                )}
                margin="none"
              />
            </div>
          </div>
        )}

        {showKeepNotifications && (
          <div className={styles.inputRow}>
            <div className={styles.inputLabel}>
              Receive recommended mission emails postings
            </div>

            <Checkbox
              checked={keepNotifications}
              onChange={onKeepNotificationsChange}
            />
          </div>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div className={styles.buttonsContainer}>
            <CallToActionButton
              width="expend"
              onClick={onConfirm}
              className={styles.confirmButton}
              disabled={availabilityType === AvailableType.UnknownAvailability}
            >
              Confirm
            </CallToActionButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
