import { Button, Colors } from '@ateams/components';
import MissionPaymentCycle from '@src/stores/Missions/MissionPaymentCycle';
import React from 'react';
import { createUseStyles } from 'react-jss';
import teamPulseHeart from './assets/teampulse-heart.svg';

interface AllDoneProps {
  paymentCycle: MissionPaymentCycle | undefined;
  handleSubmit: () => void;
  loading: boolean;
  withSubmitTimesheets?: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 40,
    height: '100%',
  },
  imageTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
  },
  title: {
    textAlign: 'center',
    marginBottom: 0,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
  },
  button: {
    width: 'fit-content',
    height: 40,
    padding: '10px 32px',
  },
  img: {
    width: '76px',
    height: '76px',
  },
  paymentBadge: {
    background: '#FCFAFF',
    border: `1px solid ${Colors.secondary}`,
    padding: 16,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 400,
    alignItems: 'center',
  },
  paymentBadgeTitle: {
    fontWeight: 600,
    fontSize: 28,
    lineHeight: '34px',
    letterSpacing: '0.002em',
  },
});

const AllDone = ({
  paymentCycle,
  handleSubmit,
  loading,
  withSubmitTimesheets,
}: AllDoneProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.imageTitleContainer}>
        <img
          className={styles.img}
          src={teamPulseHeart}
          alt="team pulse heart"
        />
        <h4 className={styles.title}>
          All done — thank you! <br />{' '}
          {withSubmitTimesheets ? 'Now it’s time to get paid.' : ''}
        </h4>
      </div>
      {withSubmitTimesheets && (
        <div className={styles.paymentBadge}>
          <span className={styles.paymentBadgeTitle}>
            {paymentCycle?.formattedTotalPayments}
          </span>{' '}
          <span>
            Period of {paymentCycle?.formattedStartDate} -{' '}
            {paymentCycle?.formattedEndDate}
          </span>
        </div>
      )}

      <Button
        data-testing-id="submit-team-pulse-button"
        loading={loading}
        onClick={handleSubmit}
        disabled={loading}
        className={styles.button}
      >
        {withSubmitTimesheets ? 'Submit Timesheet' : 'Submit'}
      </Button>
    </div>
  );
};

export default AllDone;
