import React, {
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  RefObject,
} from 'react';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';

export type RefType = RefObject<HTMLHeadingElement>;

interface Props {
  children: ReactNode;
  muted?: boolean;
}

const useStyles = createUseStyles({
  header: {
    letterSpacing: '0.002em',
    fontWeight: 600,
    fontSize: '28px',
    width: 'auto',
    margin: '88px 0 24px',
    padding: 0,
    color: (props: Props) => (props.muted ? '#EFEFF0' : undefined),
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    header: {
      margin: '184px 0 24px',
      width: '416px',
    },
  },
});

export default forwardRef(function RegistrationTitle(
  props: Props,
  ref: Ref<HTMLHeadingElement>,
): ReactElement {
  const { children } = props;
  const styles = useStyles(props);

  return (
    <h1 ref={ref} className={styles.header}>
      {children}
    </h1>
  );
});
