import React, { FC } from 'react';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import { Card } from '@ateams/components';
import RoleCategoryObject from '@a_team/models/dist/RoleCategory';
import { Avatar } from '@a_team/ui-components';
import { createUseStyles } from 'react-jss';
import { Colors } from '@ateams/components';
import cx from 'classnames';
import ContractUploader from '../ContractUploader';

export interface BuilderContractCardProps {
  user: BasicUserObject;
  category: RoleCategoryObject;
  disable?: boolean;
  onClick(): void;
  onFileUrl(file: string): Promise<void>;
}

const useStyles = createUseStyles({
  card: {
    marginRight: 0,
    marginBottom: 8,
    padding: {
      top: 16,
      left: 16,
      right: 16,
      bottom: 18,
    },
    boxSizing: 'border-box',
  },
  header: {
    display: 'flex',
    marginBottom: 9,
  },
  actions: {
    paddingTop: 1,
    marginLeft: 'auto',
    display: 'flex',
  },
  action: {
    cursor: 'pointer',
    color: Colors.primary,
  },
  avatar: {
    maxWidth: 40,
    '&& img': {
      borderRadius: 20,
    },
  },
  fullname: {
    fontWeight: 500,
    marginBottom: 3,
  },
  category: {
    color: Colors.regularDark,
  },
  wrapper: {
    flex: '288px 0 0',
    padding: 8,
  },
  disabled: {
    color: Colors.regularLight,
  },
});

const BuilderContractCard: FC<BuilderContractCardProps> = (props) => {
  const { user, category, disable, onClick, onFileUrl } = props;
  const { fullName, profilePictureURL } = user || {};
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <Card onClick={onClick} className={styles.card}>
        <div className={styles.header}>
          <Avatar src={profilePictureURL} size="lg" className={styles.avatar} />
          <div className={styles.actions}>
            <ContractUploader onUploaded={onFileUrl}>
              <div
                className={cx(styles.action, { [styles.disabled]: disable })}
              >
                Upload
              </div>
            </ContractUploader>
          </div>
        </div>
        <div className={styles.fullname}>{fullName}</div>
        <div className={styles.category}>{category.title}</div>
      </Card>
    </div>
  );
};

export default BuilderContractCard;
