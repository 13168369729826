import { createQueryKeys } from '@lukemorales/query-key-factory';

export const timesheetsKeys = createQueryKeys('timesheets', {
  getMissionPaymentCycle: (mid: string, yid: string, uid: string) => ({
    mid,
    yid,
    uid,
  }),
  adminGetMissionPaymentCycle: (mid: string, yid: string, uid: string) => ({
    mid,
    yid,
    uid,
  }),
  getTimesheetInitiativesForMission: (sid: string, uid: string) => ({
    sid,
    uid,
  }),
  generateSummaryBlurb: (sid: string, uid: string) => ({
    sid,
    uid,
  }),
});
