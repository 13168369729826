import React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';

export interface SpinnerProps {
  className?: string;
  size?: number;
  color?: string;
}

const useStyles = createUseStyles<{
  size?: number;
  color?: string;
}>({
  loader: ({ size, color }) => ({
    display: 'inline-block',
    width: size || 24,
    height: size || 24,
    borderRadius: '50%',
    background: `radial-gradient(farthest-side, ${
      color || '#FFF'
    } 94%, #0000) top/3px 3px no-repeat, conic-gradient(#0000 30%, ${
      color || '#FFF'
    })`,
    WebkitMask: 'radial-gradient(farthest-side,#0000 calc(100% - 3px),#000 0)',
    animation: '$spin 1s infinite linear',
  }),
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
});

const Spinner = ({ className, size, color }: SpinnerProps): JSX.Element => {
  const styles = useStyles({ size, color });
  return <span className={cx(styles.loader, className)} />;
};

export default Spinner;
