import React, { ReactElement } from 'react';
import VettingDashboardLayout from '@src/layouts/Vetting';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  iframe: {
    width: '100%',
    height: 'calc(100vh - 231px)',
    border: 0,
    padding: 0,
  },
  layoutContent: {
    padding: 0,
  },
});

const GuidebookView = (): ReactElement => {
  const styles = useStyles();

  return (
    <VettingDashboardLayout
      title={'Guidebook'}
      contentClassName={styles.layoutContent}
    >
      <iframe
        title="guidebook"
        src="https://guidebook.super.site/"
        className={styles.iframe}
      />
    </VettingDashboardLayout>
  );
};

export default GuidebookView;
