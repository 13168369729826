import React, { ReactElement, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import Mission from '@src/stores/Missions/Mission';
import UserAvatar from '@src/components/UserAvatar';
import { Tag } from '@ateams/components';
import {
  TimesheetId,
  TimesheetStatus,
} from '@a_team/models/dist/TimesheetObject';
import { ColorName } from '@ateams/components';
import {
  BasicInvoiceObject,
  InvoiceStatus,
} from '@a_team/models/dist/InvoiceObject';
import TextButton from '@src/components/TextButton';
import AdminConfirmTimesheetModal from '@src/components/Modal/AdminConfirmTimesheetModal';
import { getLocalTime } from '@src/helpers/time';
import { MissionPaymentCycleStatus } from '@a_team/models/dist/MissionPaymentCycleObject';
import { SetLoading } from '@src/hooks/useLoadingState';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import { numberWithCommas } from '@src/helpers/numbers';
import { canReopenPaymentCycle, reOpenPaymentCycleModalText } from '../utils';

interface Props {
  mission: Mission;
  setLoading: SetLoading;
}

const useStyles = createUseStyles({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid #C0C0C0',
    borderTop: 'none',
    borderRight: 'none',
    fontSize: 15,
    fontWeight: 'normal',
    color: '#62646A',
    padding: 32,
    '&:first-child': {
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
  tr: {
    borderBottom: '1px solid #C0C0C0',
    display: 'block',
    padding: 32,
    minHeight: 100,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  date: {
    borderRight: '1px solid #C0C0C0',
    textAlign: 'center',
  },
  members: {
    borderRight: '1px solid #C0C0C0',
  },
  label: {
    fontSize: 14,
    marginRight: 8,
  },
});

const TimesheetsStatuses = (props: Props): ReactElement => {
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  const [processInvoiceModal, setProcessInvoiceModal] =
    useState<BasicInvoiceObject>();

  const [timesheetToSubmit, setTimesheetToSubmit] = useState<TimesheetId>();
  const [timesheetToInvoice, setTimesheetToInvoice] = useState<TimesheetId>();
  const [reopenCycleModal, setReopenCycleModal] = useState(false);

  const {
    mission: {
      selectedPaymentCycle,
      data: { roles },
      displayedTimesheets,
      adminSubmitUserTimesheet,
      adminReopenTimesheet,
      reopenPaymentCycle,
      processInvoice,
      createTimesheetInvoice,
    },
    setLoading,
  } = props;
  const styles = useStyles();

  const statusColors: { [key: string]: ColorName } = {
    [TimesheetStatus.Open]: 'primary',
    [TimesheetStatus.Submitted]: 'info',
    [InvoiceStatus.Paid]: 'success',
    [InvoiceStatus.Processing]: 'warning',
    [InvoiceStatus.Created]: 'info',
    [InvoiceStatus.Canceled]: 'info',
  };

  const handleCancelInvoice = (): void => {
    if (!selectedPaymentCycle) return;

    setReopenCycleModal(false);
    setLoading(reopenPaymentCycle(selectedPaymentCycle.yid, {}), 'Success!');
  };

  return (
    <>
      {selectedPaymentCycle && (
        <AdminConfirmTimesheetModal
          startDate={getLocalTime(
            new Date(selectedPaymentCycle.data.startDate),
          )}
          endDate={getLocalTime(new Date(selectedPaymentCycle.data.endDate))}
          open={paymentModalOpen}
          onSubmit={() =>
            timesheetToSubmit
              ? setLoading(adminSubmitUserTimesheet(timesheetToSubmit))
              : undefined
          }
          title={'Submit timesheet for user'}
          text={`Are you sure you want to to submit the timesheet on behalf of this user for the period ${selectedPaymentCycle?.formattedStartDate}-${selectedPaymentCycle?.formattedEndDate}?`}
          onClose={() => setPaymentModalOpen(false)}
        />
      )}

      {processInvoiceModal && (
        <ConfirmModal
          open={!!processInvoiceModal}
          onClose={() => setProcessInvoiceModal(undefined)}
          onConfirm={() => {
            if (processInvoiceModal) {
              setLoading(processInvoice(processInvoiceModal.iid));
            }

            setProcessInvoiceModal(undefined);
          }}
          title="Process User Invoice"
          description={`Are you sure you want to process invoice with amount of $${numberWithCommas(
            processInvoiceModal.totalAmount,
          )} and pay it?`}
        />
      )}

      {timesheetToInvoice && (
        <ConfirmModal
          open={!!timesheetToInvoice}
          onClose={() => setTimesheetToInvoice(undefined)}
          onConfirm={() => {
            if (timesheetToInvoice) {
              setLoading(createTimesheetInvoice(timesheetToInvoice));
            }

            setTimesheetToInvoice(undefined);
          }}
          title="Generate Builder Invoice?"
          description={`You will not able to make any changes on this timesheet unless you re-open the payment cycle.`}
        />
      )}

      {reopenCycleModal &&
        selectedPaymentCycle &&
        canReopenPaymentCycle(selectedPaymentCycle) && (
          <ConfirmModal
            title={reOpenPaymentCycleModalText(selectedPaymentCycle).title}
            description={
              reOpenPaymentCycleModalText(selectedPaymentCycle).description
            }
            onConfirm={handleCancelInvoice}
            onClose={() => setReopenCycleModal(false)}
            open
          />
        )}

      <table className={styles.table}>
        <tbody>
          <tr>
            <th className={styles.th}>Period</th>
            <th className={styles.th}>Team Members</th>
            <th className={styles.th}>Timesheet & Invoice Status</th>
          </tr>
          <tr>
            <td className={styles.date}>
              {selectedPaymentCycle?.data?.startDate &&
                format(
                  new Date(selectedPaymentCycle.data?.startDate),
                  'LLL d',
                )}{' '}
              -{' '}
              {selectedPaymentCycle?.data?.endDate &&
                format(new Date(selectedPaymentCycle.data?.endDate), 'LLL d')}
            </td>
            <td className={styles.members}>
              <table className={styles.table}>
                <tbody>
                  <tr className={styles.tr}>
                    <td style={{ display: 'block', textAlign: 'center' }}>
                      <strong>
                        {props.mission.data.owner?.fullName || 'Paying Client'}
                      </strong>
                    </td>
                  </tr>

                  {displayedTimesheets &&
                    displayedTimesheets.map((timesheet) => {
                      const roleData = roles.find(
                        (role) => role.rid === timesheet.rid,
                      );

                      return (
                        <tr className={styles.tr} key={timesheet.sid}>
                          <td style={{ display: 'block', textAlign: 'center' }}>
                            {roleData && (
                              <UserAvatar
                                size={24}
                                src={
                                  roleData.user?.username
                                    ? roleData.user?.profilePictureURL
                                    : undefined
                                }
                                label={roleData.user?.fullName}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </td>
            <td>
              <table className={styles.table}>
                <tbody>
                  <tr className={styles.tr}>
                    <td>
                      <span className={styles.label}>Invoice:</span>

                      <Tag
                        data-testing-id="paying-client-invoice-status"
                        style={{ marginRight: 8 }}
                        color={
                          selectedPaymentCycle?.invoice
                            ? (statusColors[
                                selectedPaymentCycle?.invoice.status
                              ] as ColorName)
                            : 'primary'
                        }
                      >
                        {selectedPaymentCycle?.invoice?.status || 'To Submit'}
                      </Tag>
                      {!selectedPaymentCycle?.invoice &&
                        selectedPaymentCycle?.status ===
                          MissionPaymentCycleStatus.Closed && (
                          <TextButton
                            highlight
                            onClick={() => setReopenCycleModal(true)}
                          >
                            Re-Open Payment Cycle
                          </TextButton>
                        )}
                    </td>
                  </tr>

                  {displayedTimesheets &&
                    displayedTimesheets.map((timesheet) => {
                      return (
                        <tr className={styles.tr} key={timesheet.sid}>
                          <td>
                            <span className={styles.label}>Timesheet:</span>
                            <Tag
                              style={{ marginRight: 16 }}
                              color={
                                statusColors[timesheet.status] as ColorName
                              }
                            >
                              {timesheet.status}
                            </Tag>
                            {timesheet.invoice && (
                              <>
                                <span className={styles.label}>Invoice:</span>
                                <Tag
                                  style={{ marginRight: 8 }}
                                  color={
                                    statusColors[
                                      timesheet.invoice?.status
                                    ] as ColorName
                                  }
                                >
                                  {timesheet.invoice?.status}
                                </Tag>
                              </>
                            )}
                            {timesheet.status !== TimesheetStatus.Submitted &&
                              selectedPaymentCycle?.closeNotificationShown && (
                                <TextButton
                                  highlight
                                  onClick={() => {
                                    setTimesheetToSubmit(timesheet.sid);
                                    setPaymentModalOpen(true);
                                  }}
                                >
                                  Submit timesheet
                                </TextButton>
                              )}
                            {timesheet.status === TimesheetStatus.Submitted &&
                              !timesheet.invoice &&
                              selectedPaymentCycle?.status !==
                                MissionPaymentCycleStatus.Closed && (
                                <>
                                  <TextButton
                                    highlight
                                    onClick={() => {
                                      setLoading(
                                        adminReopenTimesheet(timesheet.sid),
                                      );
                                    }}
                                  >
                                    Re-Open
                                  </TextButton>
                                  {' | '}
                                  <TextButton
                                    highlight
                                    onClick={() => {
                                      setTimesheetToInvoice(timesheet.sid);
                                    }}
                                  >
                                    Invoice
                                  </TextButton>
                                </>
                              )}
                            {timesheet.invoice?.status ===
                              InvoiceStatus.Created && (
                              <TextButton
                                highlight
                                onClick={() => {
                                  setProcessInvoiceModal(timesheet.invoice);
                                }}
                              >
                                Prepay
                              </TextButton>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default observer(TimesheetsStatuses);
