import React, { FC, useState } from 'react';
import SectionHeading from '@src/components/SectionHeading';
import { Button as CallToActionButton } from '@ateams/components';
import Modal, { ModalBasicProps } from '..';
import { useScreenClass } from 'react-grid-system';
import { createUseStyles } from 'react-jss';
import { Breakpoints, LoadMoreLink } from '@ateams/components';
import { VXToggle } from './VXToggle';
import {
  AppliedMissionCardObject,
  MissionApplicationId,
} from '@a_team/models/dist/MissionApplicationObject';
import { QueryResult } from '@a_team/models/dist/misc';
import useLoadingState from '@src/hooks/useLoadingState';

interface Props extends ModalBasicProps {
  missionApplications?: QueryResult<AppliedMissionCardObject>;
  loadMore: () => Promise<unknown>;
  onSubmit(removedApplications: MissionApplicationId[]): void | Promise<void>;
}

/**
 * Figma: {@link https://www.figma.com/file/8TyP7LOpt3IsF9QCzc4hUL/TeamWork-%2B-Mission?node-id=3616%3A26560}
 */
const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: '346px',
    color: 'black',
    backgroundColor: '#f7f7f7',
    padding: '24px',
  },
  scrollableContent: {
    overflow: 'auto',
  },
  title: {
    fontSize: '20px',
    lineHeight: '26px',
    margin: 0,
    marginBottom: '8px',
  },
  descriptionText: {
    fontSize: '15px',
    lineHeight: '24px',
    margin: 0,
    marginBottom: '8px',
  },
  applicationInputContainer: {
    margin: '4px 0',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmButton: {
    width: '152px',
    height: '40px',
    padding: '11px 32px',
    margin: 0,
    marginTop: '14px',
    fontSize: '15px',
    lineHeight: '18px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      color: 'black',
      backgroundColor: '#f7f7f7',
    },
  },
});

export const StillAvailableModal: FC<Props> = (props) => {
  const { missionApplications, loadMore, open, onClose, onSubmit } = props;

  const styles = useStyles();
  const screenClass = useScreenClass();

  const [removedApplications, setRemovedApplications] = useState<
    Set<AppliedMissionCardObject['application']['aid']>
  >(new Set());
  const [loading, setLoading] = useLoadingState();

  const onToggleApplication = (
    aid: AppliedMissionCardObject['application']['aid'],
    checked: boolean,
  ) => {
    const newRemovedApplications = new Set(removedApplications);

    if (checked) {
      newRemovedApplications.add(aid);
    } else {
      newRemovedApplications.delete(aid);
    }

    setRemovedApplications(newRemovedApplications);
  };

  const onConfirm = () => {
    onSubmit(Array.from(removedApplications));
  };

  const onLoadMore = () => {
    setLoading(loadMore());
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        width: screenClass === 'xs' ? '95%' : '480px',
        transition: 'all 0.5s',
        padding: 0,
      }}
    >
      <div className={styles.container}>
        <div className={styles.scrollableContent}>
          <SectionHeading isFirst className={styles.title}>
            Are You Still Available?
          </SectionHeading>
          <p className={styles.descriptionText}>
            You just adjusted your availability, so please confirm if you are
            still available for the {missionApplications?.items?.length}{' '}
            missions you applied to:
          </p>

          <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
            {missionApplications?.items.map((missionApplication) => (
              <VXToggle
                checked={
                  !removedApplications.has(missionApplication.application.aid)
                }
                onChange={(e) =>
                  onToggleApplication(
                    missionApplication.application.aid,
                    e.target.checked,
                  )
                }
                label={`${missionApplication.mission.title} - ${missionApplication.application.roleCategory.title}`}
                containerClassName={styles.applicationInputContainer}
              />
            ))}
          </div>

          {missionApplications?.next && !loading && (
            <LoadMoreLink onLoadMore={onLoadMore} />
          )}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div className={styles.buttonsContainer}>
            <CallToActionButton
              width="expend"
              onClick={onConfirm}
              className={styles.confirmButton}
            >
              Confirm
            </CallToActionButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
