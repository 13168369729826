import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { BasicVetterObject } from '@a_team/models/dist/vetter';
import { VettingType } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { FetchVettingProcessesFilters } from '@ateams/api/dist/endpoints/vetting-process';
import {
  FilterComponents,
  OnVettingProcessesFiltersChange,
} from '../vetting-processes-query-bar/filters';
import { VETTING_PROCESSES_TABLE_TABS } from '.';

export interface FiltersConfig {
  ADMIN: Set<FilterComponents>;
  DEFAULT?: Set<FilterComponents>;
  [VettingType.SelectionTeam]?: Set<FilterComponents>;
  [VettingType.InternalNonTechnical]?: Set<FilterComponents>;
  [VettingType.InternalTechnical]?: Set<FilterComponents>;
}

export const useFilters = (
  filtersConfig: FiltersConfig,
  tab: string,
  vetterConfiguration?: BasicVetterObject,
  isAdminView?: boolean,
) => {
  const history = useHistory();
  const [availableFilters, setAvailableFilters] = useState<
    Set<FilterComponents>
  >(getInitialAvailableFilters(filtersConfig));

  function getInitialAvailableFilters(filtersConfig: FiltersConfig) {
    if (!isAdminView) {
      if (
        vetterConfiguration?.type &&
        vetterConfiguration.type in filtersConfig
      ) {
        return filtersConfig[vetterConfiguration.type] as Set<FilterComponents>;
      }

      return filtersConfig.DEFAULT || new Set();
    }

    return filtersConfig.ADMIN;
  }

  useEffect(() => {
    setAvailableFilters(getInitialAvailableFilters(filtersConfig));
  }, [filtersConfig, vetterConfiguration?.type, isAdminView]);

  const filters = useMemo(() => {
    const { filters } = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    return filters || {};
  }, [history.location.search]) as FetchVettingProcessesFilters;

  useEffect(() => {
    if (
      vetterConfiguration?.type === VettingType.InternalTechnical &&
      [
        VETTING_PROCESSES_TABLE_TABS.pendingInterviews,
        VETTING_PROCESSES_TABLE_TABS.completedReviews,
      ].includes(tab) &&
      !Object.keys(filters).length
    ) {
      onFiltersChange({
        vetter: vetterConfiguration?.id,
      });
    }
  }, [tab, vetterConfiguration]);

  const onFiltersChange: OnVettingProcessesFiltersChange = (filters) => {
    const searchParams = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    }) as unknown as { filters: FetchVettingProcessesFilters };

    searchParams.filters = filters;

    const newSearchParams = new URLSearchParams(
      qs.stringify(searchParams),
    ).toString();

    if (newSearchParams !== history.location.search.substring(1)) {
      history.replace({
        search: new URLSearchParams(qs.stringify(searchParams)).toString(),
      });
    }
  };

  return {
    availableFilters,
    filters,
    onFiltersChange,
  };
};
