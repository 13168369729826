import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import { AccordionMenuItem } from './AccordionMenuItem';

export interface AccordionMenuProps {
  openDefault?: number | string;
  withArrow?: boolean;
  children: ReactNode;
}

const defaultProps = {
  openDefault: undefined,
  withArrow: true,
};

export { AccordionMenuItem };

const AccordionMenu = (props: AccordionMenuProps): ReactElement => {
  const [openItemId, setOpenIndex] = useState<number | string | undefined>(
    props.openDefault,
  );

  return (
    <div>
      {Children.toArray(props.children).map((elm) =>
        isValidElement(elm)
          ? cloneElement(elm, {
              // TODO: remove this hack
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onOpen: setOpenIndex,
              openItemId,
              withArrow: props.withArrow,
            })
          : elm,
      )}
    </div>
  );
};

AccordionMenu.defaultProps = defaultProps;

export default AccordionMenu;
