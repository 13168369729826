import React from 'react';

export const ATeamIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="36" height="30" viewBox="0 0 36 30" fill="none" {...props}>
      <path
        d="M6.27916 30C5.91079 30 5.54241 29.918 5.19079 29.7705C3.88474 29.1805 3.31544 27.6726 3.91823 26.3942L15.6392 1.48106C16.2419 0.20263 17.7824 -0.354637 19.0885 0.23541C20.3945 0.825457 20.9638 2.33335 20.361 3.61179L8.64009 28.5249C8.20474 29.4591 7.26707 30 6.27916 30Z"
        fill="url(#paint0_linear_3159_1325)"
      />
      <path
        d="M18 20.1677C8.72372 20.1677 4.28651 15.2999 2.19349 11.2187C0.0167443 6.97364 0 2.72858 0 2.54829C0 1.13874 1.15535 0.0078125 2.59535 0.0078125C4.0186 0.0078125 5.1907 1.13874 5.1907 2.54829C5.1907 2.63024 5.24093 5.92467 6.91535 9.10437C9.00837 13.0872 12.7423 15.1032 18 15.1032C23.2577 15.1032 26.9916 13.0872 29.0847 9.10437C30.7591 5.90828 30.8093 2.58107 30.8093 2.54829C30.8093 1.13874 31.9647 0.0078125 33.4047 0.0078125C34.8447 0.0078125 36 1.15513 36 2.54829C36 2.72858 35.9833 6.97364 33.8065 11.2187C31.7135 15.2999 27.2763 20.1677 18 20.1677Z"
        fill="#EBECEF"
      />
      <path
        d="M29.7208 30C28.7329 30 27.7952 29.4591 27.3599 28.5249L15.6389 3.61179C15.0361 2.33335 15.6054 0.825457 16.9115 0.23541C18.2175 -0.354637 19.758 0.20263 20.3608 1.48106L32.0817 26.3942C32.6845 27.6726 32.1152 29.1805 30.8092 29.7705C30.4575 29.918 30.0892 30 29.7208 30Z"
        fill="url(#paint1_linear_3159_1325)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3159_1325"
          x1="12.1396"
          y1="0"
          x2="12.1396"
          y2="30"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#08A5FE" />
          <stop offset="1" stopColor="#AB54FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3159_1325"
          x1="23.8603"
          y1="0"
          x2="23.8603"
          y2="30"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#08A5FE" />
          <stop offset="1" stopColor="#AB54FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
