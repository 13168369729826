import { Button } from '@a_team/ui-components';
import CompanyLogo from '@src/components/CompanyLogo';
import React from 'react';
import { useCommonStyles } from './commonStyles';
import interviewScheduledIcon from './interview-scheduled.svg';

interface ScheduleConfirmedProps {
  companyName: string;
  companyImageUrl?: string | null;
  onClose: () => void;
  date: string;
  time: string;
}

const ScheduleConfirmed = ({
  companyName,
  companyImageUrl,
  onClose,
  date,
  time,
}: ScheduleConfirmedProps): JSX.Element => {
  const commonStyles = useCommonStyles();

  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.logoContainer}>
        <CompanyLogo logoUrl={companyImageUrl} size={72} />
        <img
          className={commonStyles.indicatorIcon}
          src={interviewScheduledIcon}
          alt="interview scheduled"
        />
      </div>
      <h4 className={commonStyles.title}>
        Your interview with {companyName} has been scheduled
      </h4>
      <div className={commonStyles.date}>
        <div>{date}</div>
        <span className={commonStyles.line} />
        <div>{time}</div>
      </div>
      <div className={commonStyles.desc} style={{ marginTop: 8 }}>
        You can join the meeting using the link in the calendar invite sent to
        your email, you can reply to the team advisor with any questions.
      </div>
      <div className={commonStyles.btnsContainer}>
        <Button onClick={onClose} variant="secondary">
          Close
        </Button>
      </div>
    </div>
  );
};

export default ScheduleConfirmed;
