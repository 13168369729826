import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  BorderRadius,
  Breakpoints,
  FontSizes,
  FontWeights,
  Spacing,
  TextColors,
} from '@ateams/components';
import { Colors } from '@ateams/components';

export const useCommonStyles = createUseStyles({
  headline: {
    fontSize: FontSizes.extraLarge,
    fontWeight: FontWeights.semiBold,
  },
  subHeadline: {
    fontSize: '16px',
    fontWeight: FontWeights.medium,
  },
  mainCopy: {
    fontSize: FontSizes.regular,
    marginTop: Spacing.small,
  },
  copy: {
    fontSize: FontSizes.regular,
  },
  smallCopy: {
    fontSize: FontSizes.small,
    color: TextColors.lighter,
  },
  heroCopy: {
    fontSize: FontSizes.small,
    color: TextColors.lighter,
    textAlign: 'center',
  },
  panelCopy: {
    fontSize: FontSizes.small,
    color: TextColors.lighter,
    padding: 0,
    margin: 0,
  },
  section: {
    marginBottom: 64,
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  cantConnect: {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.semiBold,
  },
  linkButton: {
    textAlign: 'left',
    padding: 0,
    background: 'none',
    border: 'none',
    fontSize: FontSizes.small,
    fontWeight: FontWeights.semiBold,
    color: Colors.secondaryDark,
    cursor: 'pointer',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linkButtonLight: {
    textAlign: 'left',
    padding: 0,
    background: 'none',
    border: 'none',
    fontSize: FontSizes.small,
    fontWeight: FontWeights.semiBold,
    color: TextColors.lighter,
    cursor: 'pointer',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.xxxLarge,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
    maxWidth: 768,
  },
  calendarButton: {
    height: 40,

    '& span': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 10,
    },
  },
  pickCalendar: {
    position: 'absolute',
    right: 0,
    minWidth: '260px',

    '& button:hover': {
      background: 'rgba(232, 209, 255, 0.4)',
      color: TextColors.regular,
    },
  },
  calendarItem: {
    display: 'flex',
    gap: 10,
    justifyContent: 'flex-start',
  },
  calendarMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: Spacing.medium,
    backgroundColor: '#F7F7F7',
    borderRadius: BorderRadius.medium,
    gap: 24,
  },
  calendarWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: Spacing.medium,
    alignItems: 'center',
  },
  noCalendarInstalledTitle: {
    color: TextColors.regular,
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.semiBold,
  },
  noCalendarInstalledSubtitle: {
    color: TextColors.primaryLight,
    fontSize: FontSizes.xsmall,
  },
  calendarInstalledTitle: {
    color: TextColors.regular,
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.semiBold,
  },
  calendarInstalledSubtitle: {
    color: TextColors.regular,
    fontSize: FontSizes.xsmall,
  },
  modal: {
    padding: 0,

    '& > div': {
      overflowX: 'hidden',
    },
  },
  hero: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
  },
  modalv2: {
    padding: 0,

    '& > div': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 40,
  },
  modalTitle: {
    fontSize: FontSizes.large,
    fontWeight: FontWeights.medium,
    margin: 0,
    textAlign: 'center',
  },
  heroImg: {
    marginBottom: 16,
    maxWidth: 192,
  },
  img: {
    width: '100%',
  },
  optionsWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    gap: 8,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,
    width: '100%',
  },
  optionWrap: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 8,
    border: `1px solid ${BorderColors.light}`,
    borderRadius: 8,
    width: '100%',
    padding: 16,

    '&:hover': {
      background: 'rgba(232, 209, 255, 0.4)',
      borderColor: Colors.secondaryDark,
    },
  },
  optionLabel: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '24px',
    userSelect: 'none',

    '& img': {
      marginRight: 8,
    },
  },
  linkWrap: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    width: '100%',
  },
  calendarLinkLabel: {
    fontWeigth: FontWeights.semiBold,
    fontSize: FontSizes.small,
  },
  linkInput: {
    borderRadius: 8,
    width: '100%',
    padding: 16,
    border: `1px solid ${BorderColors.light}`,
  },
  cta: {
    display: 'flex',
    flexDirection: 'column',
    background: Colors.banner,
    width: '100%',
    borderRadius: 8,
    padding: '12px 16px',
    gap: 16,

    '& p': {
      fontSize: FontSizes.small,
      margin: 0,
      padding: 0,
    },
  },
  teaserBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    marginTop: 40,

    '&.leftAligned': {
      alignItems: 'flex-start',
    },
  },
  howtoWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    marginTop: 40,
  },
  panel: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    background: Colors.backgroundLight,
    borderRadius: 16,
    gap: 8,

    '&.ghost': {
      background: 'transparent',
      border: `1px solid ${BorderColors.lighter}`,
    },

    '&.interactive': {
      cursor: 'pointer',
    },
    '&.interactive:hover': {
      background: 'rgba(232, 209, 255, 0.4)',
      borderColor: Colors.secondaryDark,
    },
  },
  panelTitle: {
    fontWeight: FontWeights.medium,
    fontSize: FontSizes.small,
    margin: 0,
  },
  subPanelTitle: {
    color: Colors.regularDark,
    fontWeight: FontWeights.medium,
    fontSize: FontSizes.small,
    margin: 0,
  },
  passive: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px 0 40px',
  },
  closeBtn: {
    minWidth: 120,
    maxWidth: 120,
    height: 40,
  },
  primaryButton: {
    minWidth: 120,
    maxWidth: 120,
    height: 40,
  },
  howtoBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noPadding: {
    padding: 0,
    overflow: 'hidden',
  },
  steps: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    gap: 8,
  },
  stepWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
  },
  stepCopy: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 8,
  },
  panelHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  headerImg: {
    width: 70,
  },
  message: {
    padding: 10,
    border: '1px solid rgb(192, 192, 192)',
    borderRadius: 5,
    textDecoration: 'none',
    width: '40vh',

    '&::placeholder': {
      textDecoration: 'none',
    },
  },
  error: {
    margin: 0,
    color: '#F63131',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    calendarMainContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    calendarWrap: {
      justifyContent: 'space-between',
    },
    modal: {
      width: '100% !important',
      maxWidth: 680,
      padding: 24,
    },
    modalV2: {
      width: '100% !important',
      padding: 24,
    },
    modalContainer: {
      maxWidth: 600,
    },
    modalTitle: {
      '&.bookInterviews': {
        padding: '0 90px',
      },
    },
    linkWrap: {
      maxWidth: 500,
    },
    cta: {
      maxWidth: 500,
    },
    heroCopy: {
      padding: '0 90px',

      '&.lessPadding': {
        padding: '0 15px',
      },
    },
    hero: {
      padding: '0 35px',
    },
    teaserBody: {
      padding: '0 90px',
    },
    howtoWrap: {
      padding: '0 45px',
    },
    stepWrap: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 0 0 24px',
    },
    message: {
      width: 500,
    },
  },
});
