import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import { toJS } from 'mobx';
import CardList from '../CardList';
import AppliedMissionCard from '../MissionControlCard/AppliedMissionCard';
import {
  AppliedMissionCardObject,
  MissionApplicationLifecycleStage,
  MissionApplicationLifecycleStageLabels,
  MissionApplicationReviewStatusOther,
} from '@a_team/models/dist/MissionApplicationObject';
import {
  Breakpoints,
  Divider,
  Icon,
  IconType,
  Select,
  SelectOption,
  Tag,
} from '@ateams/components';
import { enumKeys } from '@src/helpers/types';
import { createUseStyles } from 'react-jss';
import TextButton from '@src/components/TextButton';
import NoResults from '@src/components/NoResults';
import WithdrawApplicationModal from '../WithdrawApplicationModal';
import useWithdrawApplication from '../MissionControlCard/useWithdrawApplication';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { LoadingCards } from '@src/views/MissionControl/LoadingCards';
import { MissionStatus } from '@a_team/models/dist/MissionObject';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';
import { useGetAppliedMissionsQuery } from '@src/rq/missions';

const useStyles = createUseStyles<{
  isNewStatus: boolean;
}>({
  '@global': {
    '.tippy-popper div': {
      borderRadius: '4px !important',
    },
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  cardListContainer: {
    display: ({ isNewStatus }) => (isNewStatus ? 'grid' : undefined),
    gridTemplateColumns: ({ isNewStatus }) =>
      isNewStatus ? 'repeat(auto-fill, minmax(300px, 612px))' : undefined,
  },
  section: {
    fontWeight: 500,
  },
  select: {
    margin: '0px',
    marginBottom: '5px',
    width: '100%',
  },
  tag: {
    background: '#383A3D',
    color: '#fff',
    border: '1px solid #494B50',
  },
  info: {
    display: 'none',
    fontSize: 12,
    marginRight: 16,
  },
  tooltipContent: {
    fontSize: 12,
    textAlign: 'left',
  },
  divider: {
    margin: '16px 0',
  },
  icon: {
    fontSize: 20,
    marginLeft: 2,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    form: {
      flexDirection: 'row',
    },
    select: {
      width: 240,
      marginRight: '12px',
    },
    info: {
      display: 'block',
    },
  },
});

function getApplicationCategory(
  appliedMissionCards: AppliedMissionCardObject[],
  username?: string,
) {
  const concluded: AppliedMissionCardObject[] = [];
  const active: AppliedMissionCardObject[] = [];

  const isConcluded = (appliedMissionCard: AppliedMissionCardObject) => {
    const status = appliedMissionCard.mission.status;
    const reviewStatus = appliedMissionCard.application?.reviewStatus;
    const notSelected = (reviewStatus?.notSelected?.length ?? 0) > 0;
    const unavailable = reviewStatus?.other?.includes(
      MissionApplicationReviewStatusOther.Unavailable,
    );

    if (unavailable) {
      return true;
    }

    const application = appliedMissionCard.application;
    const role = appliedMissionCard.mission.roles.find(
      (role) => role.rid === application.rid,
    );

    // if the user on the role is diff from the user logged in, then it's concluded
    if (role?.user?.username && role?.user?.username !== username) {
      return true;
    }

    if (!role) {
      return true;
    }

    if (
      [
        MissionRoleStatus.Canceled,
        MissionRoleStatus.Ended,
        MissionRoleStatus.ScheduledToEnd,
      ].includes(role.status)
    ) {
      return true;
    }

    return (
      [
        MissionStatus.Ended,
        MissionStatus.Archived,
        MissionStatus.ScheduledToEnd,
      ].includes(status) || notSelected
    );
  };

  appliedMissionCards.forEach((appliedMissionCard) => {
    if (isConcluded(appliedMissionCard)) {
      concluded.push(appliedMissionCard);
    } else {
      active.push(appliedMissionCard);
    }
  });

  return {
    active,
    concluded,
  };
}

const AppliedMissions = (): ReactElement => {
  const { missionControl, auth } = useStores();
  const styles = useStyles({
    isNewStatus: auth.withMissionApplicationStatusV2,
  });
  const {
    isLoading: isLoadingAppliedMissions,
    isError: isErrorLoadingAppliedMissions,
    refetch,
  } = useGetAppliedMissionsQuery();
  const appliedMissions = useMemo(
    () => missionControl.getApplied,
    [
      isLoadingAppliedMissions,
      isErrorLoadingAppliedMissions,
      missionControl.getApplied,
    ],
  );

  const {
    selectedApplication,
    confirmationModalOpen,
    onConfirmWithdrawModalOpen,
    onConfirmModalClose,
  } = useWithdrawApplication();

  const useStatusV2 = auth.withMissionApplicationStatusV2;

  const loading = useMemo(
    () => isLoadingAppliedMissions && !appliedMissions.length,
    [isLoadingAppliedMissions, appliedMissions.length],
  );

  const clearFilters = () => {
    missionControl.clearAppliedMissionsFilters();
    refetch();
  };

  useEffect(() => {
    if (useStatusV2) {
      clearFilters();
    }
  }, [useStatusV2]);

  const stageOptions = useMemo(() => {
    return enumKeys(MissionApplicationLifecycleStage).map((stage) => {
      return {
        label: `${MissionApplicationLifecycleStageLabels[stage]} (${missionControl.applied?.totals[stage]})`,
        value: stage,
      };
    });
  }, [missionControl.applied?.totals.total]);

  const onFilterChange = useCallback((option: SelectOption | null) => {
    option &&
      missionControl.setLifecycleStageFilter(
        option.value as MissionApplicationLifecycleStage,
      );
  }, []);

  const { active, concluded } = useMemo(() => {
    return getApplicationCategory(appliedMissions, auth.user?.username);
  }, [appliedMissions, auth.user?.username]);

  const renderAppliedMissionCard = () => {
    if (useStatusV2) {
      if (loading) {
        return (
          <CardList className={styles.cardListContainer}>
            <LoadingCards type={'applied'} />
          </CardList>
        );
      }

      return (
        <div>
          {active.length > 0 && (
            <>
              <p className={styles.section}>Active</p>
              <CardList className={styles.cardListContainer}>
                {active.map((appliedMission) => (
                  <AppliedMissionCard
                    key={appliedMission.application.aid}
                    mission={toJS(appliedMission.mission)}
                    application={appliedMission.application}
                    userRecommendations={appliedMission.userRecommendations}
                    onWithdrawClick={onConfirmWithdrawModalOpen}
                  />
                ))}
              </CardList>
            </>
          )}
          {concluded.length > 0 && (
            <>
              <p className={styles.section}>Concluded</p>
              <CardList className={styles.cardListContainer}>
                {concluded.map((appliedMission) => (
                  <AppliedMissionCard
                    hideInvite
                    key={appliedMission.application.aid}
                    mission={toJS(appliedMission.mission)}
                    application={appliedMission.application}
                    userRecommendations={appliedMission.userRecommendations}
                    onWithdrawClick={onConfirmWithdrawModalOpen}
                  />
                ))}
              </CardList>
            </>
          )}
        </div>
      );
    }

    return (
      <CardList className={styles.cardListContainer}>
        {loading ? (
          <LoadingCards type={'applied'} />
        ) : (
          appliedMissions.map((appliedMission) => (
            <AppliedMissionCard
              key={appliedMission.application.aid}
              mission={toJS(appliedMission.mission)}
              application={appliedMission.application}
              userRecommendations={appliedMission.userRecommendations}
              onWithdrawClick={onConfirmWithdrawModalOpen}
            />
          ))
        )}
      </CardList>
    );
  };

  return (
    <>
      {!useStatusV2 && (
        <form className={styles.form}>
          <Select
            hideTags
            value={missionControl.selectedLifecycleFilterOption}
            placeholder="Application status"
            onChange={onFilterChange}
            options={stageOptions}
            className={styles.select}
            isDisabled={missionControl.loading}
          />
          <div className={styles.info}>
            Missing applications? Try changing this filter.{' '}
            <TooltipWrapped
              distance={0}
              theme={'dark'}
              animation={'fade'}
              interactive
              html={
                <div className={styles.tooltipContent}>
                  <h3>How Application filter works</h3>
                  <p>
                    There are 3 filter stages on applied missions, to help
                    signal the chance of the mission application being
                    successful.
                  </p>
                  <p>
                    We recommend you focus on the “Active” bucket, and if you’re
                    looking for work to always have a few missions in there!
                  </p>
                  <Divider color={'#38393D'} className={styles.divider} />
                  <Tag className={styles.tag} thin>
                    {MissionApplicationLifecycleStageLabels.Active}
                  </Tag>
                  <p>
                    These still have a good chance of success. New submissions
                    and recently shortlisted/proposed missions show here.
                  </p>
                  <Tag thin className={styles.tag}>
                    {MissionApplicationLifecycleStageLabels.LowChance}
                  </Tag>
                  <p>These are unlikely to be successful.</p>
                  <Tag thin className={styles.tag}>
                    {MissionApplicationLifecycleStageLabels.Concluded}
                  </Tag>
                  <p>These have a final status assigned already.</p>
                </div>
              }
              position={'bottom'}
            >
              <Icon type={IconType.Info} className={styles.icon} />
            </TooltipWrapped>
          </div>
          <TextButton onClick={clearFilters} disabled={loading} color="primary">
            Clear All
          </TextButton>
        </form>
      )}

      {appliedMissions.length > 0 || loading ? (
        renderAppliedMissionCard()
      ) : !missionControl.loading ? (
        <NoResults
          imageType="noSearchResults"
          title="No results found!"
          text={
            <>
              <p>
                Sorry, we didn't find any applications with the selected status.
              </p>
              <p>
                Please update your selected filters or{' '}
                <TextButton onClick={clearFilters} color="primary">
                  start again
                </TextButton>
                .
              </p>
            </>
          }
        />
      ) : null}
      <WithdrawApplicationModal
        open={confirmationModalOpen}
        application={selectedApplication}
        onClose={onConfirmModalClose}
      />
    </>
  );
};

export default observer(AppliedMissions);
