import { components, OptionProps } from 'react-select';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { TextColors } from '../theme/colors';
import { FontSizes } from '../theme/styles';

const useStyles = createUseStyles({
  option: {
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    color: TextColors.regularDark,
    fontSize: FontSizes.small,
  },
});

export const OptionWithDescription = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: OptionProps<any, any>,
): ReactElement => {
  const styles = useStyles(props);
  return (
    <components.Option className={'select-option-checkbox-wrapper'} {...props}>
      <div
        className={styles.option}
        style={{
          cursor: props.isDisabled ? 'default' : 'pointer',
        }}
      >
        {props.label}
        <span className={styles.description}>
          {props.data.data.description}
        </span>
      </div>
    </components.Option>
  );
};
