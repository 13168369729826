import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FetchVettersFilters } from '@ateams/api/dist/endpoints/vetter';
import { UserId } from '@a_team/models/dist/UserObject';
import {
  TalentCategoryId,
  TalentSpecializationId,
} from '@a_team/models/dist/TalentCategories';
import { OnUserSelect, UserSelector } from '@src/components/UserSelector';
import { AsyncSelectProps, Select, SelectProps } from '@ateams/components';
import { RoleSelector } from '../../role-selector';
import {
  SkillSelector,
  SkillSelectorProps,
} from '@src/components/SkillSelector';

export type OnVettersFiltersChange = (filters: FetchVettersFilters) => void;

export interface VettingProcessesFiltersProps {
  onChange?: OnVettersFiltersChange;
  defaultValues?: FetchVettersFilters;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '10px',
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  inputContainer: {
    display: 'flex',
    alignSelf: 'center',
    width: '280px',
    marginBottom: '10px',
    marginRight: '20px',
    '&:last-child': {
      marginRight: 0,
    },
  },
});

export enum IsActiveOptions {
  Yes = 'Yes',
  No = 'No',
}

const IS_ACTIVE_OPTIONS = [
  { value: IsActiveOptions.Yes, label: IsActiveOptions.Yes },
  { value: IsActiveOptions.No, label: IsActiveOptions.No },
];

function getIsActiveDefaultOption(isActive?: boolean) {
  if (typeof isActive === 'undefined') {
    return undefined;
  }

  const value =
    (typeof isActive === 'string' && isActive === 'true') || isActive === true
      ? IsActiveOptions.Yes
      : IsActiveOptions.No;

  return { value, label: value };
}

export const SelectionTeamExplorerQueryBar: React.FC<
  VettingProcessesFiltersProps
> = (props) => {
  const { defaultValues } = props;
  const styles = useStyles();
  const [user, setUser] = useState<UserId | undefined>(
    defaultValues?.user || undefined,
  );
  const [mainRole, setMainRole] = useState<TalentSpecializationId | undefined>(
    defaultValues?.mainRole || undefined,
  );
  const [vettingSkills, setVettingSkills] = useState(
    defaultValues?.vettingSkills || undefined,
  );
  const [vettingRoles, setVettingRoles] = useState(
    defaultValues?.vettingRoles || undefined,
  );
  const [isActive, setIsActive] = useState<boolean | undefined>(
    defaultValues?.isActive || undefined,
  );

  const onChange = () => {
    if (props.onChange) {
      props.onChange({
        user,
        mainRole,
        vettingSkills,
        vettingRoles,
        isActive,
      });
    }
  };

  useEffect(onChange, [user, mainRole, vettingSkills, vettingRoles, isActive]);

  const onUserSelect: OnUserSelect = (option) => {
    const selectedUser = option?.user?.uid || undefined;
    setUser(selectedUser);
  };

  const onMainRoleChange: AsyncSelectProps['onChange'] = (option) => {
    setMainRole((option?.value as TalentSpecializationId) || undefined);
  };

  const onVettingSkillsChange: SkillSelectorProps['onChange'] = (skills) => {
    setVettingSkills(skills);
  };

  const onVettingRolesChange: AsyncSelectProps<true>['onChange'] = (
    options,
  ) => {
    setVettingRoles(
      (options?.map((option) => option.value) as TalentCategoryId[]) ||
        undefined,
    );
  };

  const onIsActiveChange: SelectProps['onChange'] = (option) => {
    if (option) {
      setIsActive(option.value === IsActiveOptions.Yes);
    } else {
      setIsActive(undefined);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Filters</div>

      <div className={styles.inputsContainer}>
        <div className={styles.inputContainer}>
          <UserSelector
            label={'Selection Team User'}
            placeholder={'Search User...'}
            defaultValue={defaultValues?.user}
            onUserSelect={onUserSelect}
            margin={'none'}
          />
        </div>

        <div className={styles.inputContainer}>
          <RoleSelector
            label={'Main Role'}
            onChange={onMainRoleChange}
            defaultValue={defaultValues?.mainRole}
            margin={'none'}
          />
        </div>

        <div className={styles.inputContainer}>
          <Select
            label={'Is Active'}
            onChange={onIsActiveChange}
            options={IS_ACTIVE_OPTIONS}
            defaultValue={getIsActiveDefaultOption(defaultValues?.isActive)}
            margin={'none'}
            isClearable
          />
        </div>
      </div>

      <div className={styles.inputsContainer}>
        <div className={styles.inputContainer}>
          <SkillSelector
            type={'all'}
            label={'Vetting Skills'}
            onChange={onVettingSkillsChange}
            defaultValue={defaultValues?.vettingSkills}
            margin={'none'}
          />
        </div>

        <div className={styles.inputContainer}>
          <RoleSelector
            type={'all'}
            isMulti
            label={'Vetting Roles'}
            onChange={onVettingRolesChange}
            defaultValue={defaultValues?.vettingRoles}
            margin={'none'}
          />
        </div>
      </div>
    </div>
  );
};
