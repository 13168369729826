import React, { ReactElement, ReactNode, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { sumBy } from 'lodash';
import TabButtons, { TabButton } from '@src/components/TabButtons';
import UserAvatar from '@src/components/UserAvatar';
import TableContent from './TableContent';
import {
  TimesheetId,
  TimesheetRecordKey,
  TimesheetRecordData,
} from '@a_team/models/dist/TimesheetObject';
import { LoadingState, SetLoading } from '@src/hooks/useLoadingState';
import { TabLabel } from '@src/views/Mission/TimeTracking/TimesheetsTable/TabLabel/TabLabel';
import { Breakpoints, Card } from '@ateams/components';
import Mission from '@src/stores/Missions/Mission';

interface Props {
  mission: Mission;
  loading: LoadingState;
  setLoading: SetLoading;
  adminView?: boolean;
  disabled?: boolean;
}

const defaultProps: Partial<Props> = {
  adminView: false,
  disabled: false,
};

type Tab = {
  id: string;
  label: string | ReactNode;
  component: ReactNode;
  disabled?: boolean;
};

const useStyles = createUseStyles({
  container: {
    padding: 0,
    paddingTop: 100,
    marginRight: 0,
  },
  tabs: {
    marginTop: '8px',
    boxShadow: 'none',
    backgroundColor: 'white',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
    padding: 0,
    zIndex: 9,
    position: 'absolute',
    justifyContent: 'space-evenly',
    overflow: 'auto',
    width: '100%',
  },
  tab: {
    padding: '24px',
  },
  teamAvatars: {
    display: 'none',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      paddingTop: 0,
    },
    teamAvatars: {
      display: 'block',
    },
    tabs: {
      position: 'relative',
      justifyContent: 'flex-start',
    },
  },
});

const TimesheetsTable = (props: Props): ReactElement => {
  const { loading, mission, setLoading, adminView, disabled } = props;

  const styles = useStyles();
  const clientView = mission.isMissionManager;

  const deleteRecord = async (sid: TimesheetId, key: TimesheetRecordKey) => {
    if (!mission) return;
    setLoading(mission.deleteRecord(sid, key));
  };

  const submittedTimesheetRoles = useMemo(() => {
    return mission.data.roles.filter((role) => {
      const timesheet = mission.data.paymentCycles?.current.timesheets.find(
        (ts) => ts.rid === role.rid,
      );

      return !!timesheet?.records.length;
    });
  }, [mission]);

  const updateRecord = async (
    sid: TimesheetId,
    key: TimesheetRecordKey,
    data: TimesheetRecordData,
  ) => {
    if (!mission) return;
    setLoading(mission.updateRecord(sid, key, data));
  };

  let tabs: Tab[] = [
    ...(!adminView && !clientView
      ? [
          {
            id: 'single',
            label: (
              <TabLabel
                user={mission.currentUserRole?.user || null}
                isCurrentUser={!!mission.currentUserRole}
              />
            ),
            component: (
              <TableContent
                userRecords={mission.groupedUserTimesheetsRecords}
                currentUserRole={mission.currentUserRole}
                totalMinutes={mission.userTotalMinutes}
                timesheetId={mission.currentUserTimesheet?.sid}
                loading={loading}
                updateRecord={updateRecord}
                deleteRecord={deleteRecord}
                timesheetsView={mission.timesheetsView}
                disabled={disabled}
                invoiceURL={mission.currentUserTimesheet?.invoice?.downloadURL}
                exportTimesheet={mission.exportTimehseet}
              />
            ),
          },
        ]
      : []),
    {
      id: 'team',
      label: (
        <div style={{ display: 'flex', alignItems: 'center', fontSize: 15 }}>
          {submittedTimesheetRoles.map((role, i) => (
            <UserAvatar
              key={role.rid}
              size={24}
              containerStyle={{ marginLeft: i > 0 ? '-8px' : 0 }}
              src={
                role?.user?.username
                  ? role?.user?.profilePictureURL
                  : 'placeholder'
              }
              containerClassName={styles.teamAvatars}
            />
          ))}
          <div style={{ marginLeft: '8px' }}>Team Timesheet</div>
        </div>
      ),
      component: (
        <TableContent
          timesheetId={mission.currentUserTimesheet?.sid}
          currentUserRole={mission.currentUserRole}
          totalMinutes={mission.teamTotalMinutes}
          loading={loading}
          updateRecord={updateRecord}
          deleteRecord={deleteRecord}
          teamRecords={mission.groupedTeamTimesheetsRecords}
          timesheetsView={mission.timesheetsView}
          disabled={disabled}
          clientView={clientView}
          adminView={adminView}
          exportTimesheet={mission.exportTimehseet}
        />
      ),
    },
  ];

  if (adminView || clientView) {
    const memberTabs: Tab[] = mission
      ? (mission.displayedTimesheets || [])
          .map((roleTimesheet): Tab | null => {
            const role = mission.data.roles?.find(
              (r) => r.rid === roleTimesheet.rid,
            );
            if (!role) {
              return null;
            }

            return {
              id: role.rid,
              label: <TabLabel user={role.user || null} />,
              component: (
                <TableContent
                  userRecords={
                    roleTimesheet &&
                    mission.groupUserRecords(
                      roleTimesheet.records,
                      role.user?.username,
                    )
                  }
                  currentUserRole={role}
                  totalMinutes={
                    roleTimesheet && sumBy(roleTimesheet.records, 'minutes')
                  }
                  timesheetId={roleTimesheet?.sid}
                  loading={loading}
                  updateRecord={updateRecord}
                  deleteRecord={deleteRecord}
                  timesheetsView={mission.timesheetsView}
                  invoiceURL={
                    mission.currentUserTimesheet?.invoice?.downloadURL
                  }
                  adminView={adminView}
                  clientView={clientView}
                  exportTimesheet={mission.exportTimehseet}
                />
              ),
            };
          })
          .filter((tab): tab is Tab => tab !== null)
      : [];

    tabs = tabs.concat(memberTabs);
  }

  const selectedTabData = tabs.find((tab) => tab.id === mission.timesheetsView);

  return (
    <div>
      <Card className={styles.container}>
        <TabButtons className={styles.tabs}>
          {tabs.map((tabButton) => (
            <TabButton
              onClick={(): void =>
                !tabButton.disabled
                  ? mission.changeTimesheetsView(tabButton.id)
                  : undefined
              }
              active={mission.timesheetsView === tabButton.id}
              className={styles.tab}
              key={tabButton.id}
            >
              <div
                style={{
                  fontWeight: 500,
                  color: tabButton.disabled ? '#C0C0C0' : '#222',
                }}
              >
                {tabButton.label}
              </div>
            </TabButton>
          ))}
        </TabButtons>
        <div>{selectedTabData && selectedTabData.component}</div>
      </Card>
    </div>
  );
};

TimesheetsTable.defaultProps = defaultProps;
export default TimesheetsTable;
