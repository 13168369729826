import React, { ReactElement, useState } from 'react';
import { AirtableRecord } from '..';
import { createUseStyles } from 'react-jss';
import { Icon, IconType } from '@ateams/components';
import User from './User';
import { Card } from '@ateams/components';

interface Props {
  users: AirtableRecord[];
  title: string;
  value: string | number;
}

const useStyles = createUseStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    marginTop: '28px',
  },
  bold: {
    fontWeight: 'bold',
    marginRight: '8px',
  },
  loader: {
    color: '#fff',
    background: '#ffbf2b',
    padding: '8px',
    borderRadius: '4px',
    opacity: 1,
    animation: '$loading 1s linear infinite',
  },
  '@keyframes loading': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
});

export default function AirtableUsers(props: Props): ReactElement {
  const { users, title, value } = props;
  const styles = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <div
        className={styles.header}
        style={{ cursor: users.length > 1 ? 'pointer' : 'auto' }}
        onClick={() => users.length > 1 && setExpanded(!expanded)}
      >
        <div className={styles.bold}>{title}:</div>{' '}
        {users.length < 1 ? (
          <div className={styles.loader} />
        ) : (
          <>
            <div>{value}</div>
            <Icon
              type={IconType.CaretDown}
              style={{
                cursor: 'pointer',
                marginLeft: '8px',
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg',
                transition: 'all 0.5s',
              }}
            />
          </>
        )}
      </div>
      {expanded && (
        <Card style={{ marginTop: '16px', padding: 0 }}>
          {users.map((user, i) => (
            <div key={`${i}-user["0. AceNumber"]`}>
              <User user={user} />
              <hr style={{ padding: 0, margin: 0 }} />
            </div>
          ))}
        </Card>
      )}
    </div>
  );
}
