import { MissionRoleId } from './MissionRole';
import { DateISOString } from './misc';
import { BasicInvoiceObject } from './InvoiceObject';
import { TimesheetInitiativeId } from './TimesheetInitiativeObject';

export type TimesheetId = string;

export enum TimesheetStatus {
  Open = 'Open',
  Submitted = 'Submitted',
}

export type TimesheetRecordKey = string;

export enum TimesheetRecordType {
  DataAnalysis = 'Data & Analysis',
  Design = 'Design',
  Documentation = 'Documentation',
  Engineering = 'Engineering',
  Growth = 'Growth',
  Management = 'Management',
  Marketing = 'Marketing',
  Meetings = 'Meetings',
  Operational = 'Operational',
  Other = 'Other',
  Planning = 'Planning',
  Research = 'Research',
  Strategy = 'Strategy',
  Testing = 'Testing',
  Holiday = 'Holiday',
  SickDay = 'Sick Day',
  TimeOff = 'Time off',
}

export interface TimesheetRecordData {
  key?: string;
  date: DateISOString;
  minutes: number;
  details: string;
  type?: TimesheetRecordType;
  initiativeIds?: TimesheetInitiativeId[];
  isRetainerBased?: boolean;
}

export interface TimesheetRecord extends TimesheetRecordData {
  key: TimesheetRecordKey;
}

export default interface TimesheetObject {
  sid: TimesheetId;
  rid: MissionRoleId;
  status: TimesheetStatus;
  records: TimesheetRecord[];
  invoice?: BasicInvoiceObject;
  demoLink?: string;
  summary?: string;
  summaryHtml?: string;
  usedMachineTextForSummary?: boolean;
}
