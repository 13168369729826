import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { theme } from '@a_team/ui-components';
import {
  VettingProcessPostEvaluationSurvery,
  VettingProcessPostEvaluationSurveyScore,
} from '@a_team/models/dist/vetting-processes/post-evaluation-survey';
import { BorderRadius, FontWeights, Spacing } from '@ateams/components';
import { ATeamIcon } from './ATeamIcon';
import {
  Size,
  Text,
} from '@src/views/VettingFeedbackForm/components/typography';
import { InputLabel } from '@src/views/VettingFeedbackForm/components/typography';
import { TextInput } from '@src/views/VettingFeedbackForm/components/text-input';
import { Button } from '@src/views/VettingFeedbackForm/components/button';
import { ScoreInput } from './score-input';

export type OnPostEvaliationSurveySubmit = (
  payload: VettingProcessPostEvaluationSurvery,
) => void;

export interface PostEvaluationSurveyProps {
  onSubmit: OnPostEvaliationSurveySubmit;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '600px',
  },
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  largeMarginBottom: {
    marginBottom: '40px',
  },
  title: {
    height: '30px',
    fontWeight: 500,
  },
  description: {
    textAlign: 'center',
    fontWeight: FontWeights.small,
  },
  optionalSpan: {
    color: theme.colors.Grey[500],
  },
  submitButton: {
    width: '160px',
    height: '40px',
    borderRadius: BorderRadius.medium,
  },
});

export const PostEvaluationSurvey: React.FC<PostEvaluationSurveyProps> = (
  props,
) => {
  const styles = useStyles();
  const [experienceWithInterviewer, setExperienceWithInterviewer] = useState<
    VettingProcessPostEvaluationSurveyScore | undefined
  >();
  const [likelinessToRecommendATeam, setLikelinessToRecommendATeam] = useState<
    VettingProcessPostEvaluationSurveyScore | undefined
  >();
  const [additionalFeedback, setAdditionalFeedback] = useState<
    string | undefined
  >();

  const onExperienceWithInterviewerChange = (
    experienceWithInterviewer: VettingProcessPostEvaluationSurveyScore,
  ) => {
    setExperienceWithInterviewer(experienceWithInterviewer);
  };

  const onLikelinessToRecommendATeamChange = (
    likelinessToRecommendATeam: VettingProcessPostEvaluationSurveyScore,
  ) => {
    setLikelinessToRecommendATeam(likelinessToRecommendATeam);
  };

  const onAdditionalFeedbackChange = (additionalFeedback: string) => {
    setAdditionalFeedback(additionalFeedback);
  };

  function validateCanSubmit(
    payload: Partial<VettingProcessPostEvaluationSurvery>,
  ): payload is VettingProcessPostEvaluationSurvery {
    if (typeof experienceWithInterviewer !== 'number') {
      return false;
    }

    if (typeof likelinessToRecommendATeam !== 'number') {
      return false;
    }

    return true;
  }

  const payload = useMemo(
    () => ({
      experienceWithInterviewer,
      likelinessToRecommendATeam,
      additionalFeedback,
    }),
    [experienceWithInterviewer, likelinessToRecommendATeam, additionalFeedback],
  );

  const canSubmit = useMemo(() => validateCanSubmit(payload), [payload]);

  const onSubmit = () => {
    if (validateCanSubmit(payload)) {
      props.onSubmit(payload);
    }
  };

  return (
    <div className={cx(styles.container, props.className)}>
      <ATeamIcon className={styles.marginBottom} />

      <Text
        size={Size.ExtraLarge}
        className={cx(styles.title, styles.marginBottom)}
      >
        How was your evaluation call with A.Team?
      </Text>

      <div className={styles.formContent}>
        <Text className={cx(styles.description, styles.largeMarginBottom)}>
          We’d love your feedback on the evaluation call so we can make it
          better for future builders.
          <br />
          Please share your honest experience—we're eager to learn.
        </Text>

        <ScoreInput
          value={experienceWithInterviewer}
          onChange={onExperienceWithInterviewerChange}
          label={'How was your experience with the interviewer? *'}
          scales={{
            low: 'Very negative',
            high: 'Very positive',
          }}
          className={styles.largeMarginBottom}
        />

        <ScoreInput
          value={likelinessToRecommendATeam}
          onChange={onLikelinessToRecommendATeamChange}
          label={
            'Based on your evaluation-call experience, how likely are you to recommend A.Team to a\nfriend or colleague? *'
          }
          scales={{
            low: 'Very unlikely',
            high: 'Very likely',
          }}
          className={styles.largeMarginBottom}
        />

        <InputLabel className={styles.marginBottom}>
          Feel free to share any additional feedback.{' '}
          <span className={styles.optionalSpan}>{'(Optional)'}</span>
        </InputLabel>

        <TextInput
          value={additionalFeedback}
          onChange={(e) => onAdditionalFeedbackChange(e.target.value)}
          placeholder={'Enter your feedback'}
          inputContainerClassName={styles.largeMarginBottom}
        />
      </div>

      <Button
        onClick={onSubmit}
        disabled={!canSubmit}
        className={styles.submitButton}
      >
        Submit
      </Button>
    </div>
  );
};
