import React, { useMemo, useState } from 'react';
import { Modal } from '@a_team/ui-components';
import { Reasons } from './Reasons';
import { useCommonStyles } from '../commonStyles';
import { isNil } from 'lodash';
import { CalendarType } from './CalendarType';
import { NoCalendarReason } from '@a_team/models/dist/UserObject';
import { ReasonsModalContext } from './context';
import { Teaser } from './Teaser';
import { Privacy } from './Privacy';
import { HowToUnsupportedCalendar } from './HowToConnect/HowToUnsupportedCalendar';
import { MultiCalendars } from './MultiCalendars';
import { Support } from './Support';

interface NoCalendarReasonsModalProps {
  open: boolean;
  canClose?: boolean;
  fullScreen?: boolean;
  onClose: () => void;
}

export const NoCalendarReasonsModal = (props: NoCalendarReasonsModalProps) => {
  const styles = useCommonStyles();

  const [noCalendarReason, setNoCalendarReason] = useState<NoCalendarReason>();
  const [calendarName, setCalendarName] = useState<string>();
  const [showReasonsModal, setShowReasonsModal] = useState<boolean>(false);
  const [showSupportModal, setShowSupportModal] = useState<boolean>(false);

  const resetSelectionState = () => {
    setNoCalendarReason(undefined);
    setCalendarName(undefined);
  };

  const handleModalClose = () => {
    resetSelectionState();
    setShowReasonsModal(false);
    setShowSupportModal(false);
    props.onClose();
  };

  const handleBack = () => {
    resetSelectionState();
    setShowSupportModal(false);
    setShowReasonsModal(true);
  };

  const handleSupportModal = () => {
    resetSelectionState();
    setShowReasonsModal(false);
    setShowSupportModal(true);
  };

  const Step = useMemo(() => {
    if (showSupportModal) {
      return (
        <Support
          label="Describe your issue"
          placeholder="I am having problems connecting my calendar because..."
          onClose={handleBack}
        />
      );
    }
    if (showReasonsModal) {
      return (
        <Reasons
          onClose={handleModalClose}
          onSelectReason={(reason?: NoCalendarReason) => {
            setShowReasonsModal(false);
            if (reason) {
              setNoCalendarReason(reason);
            }
          }}
        />
      );
    }

    if (
      noCalendarReason === NoCalendarReason.NonSupportedCalendar &&
      !!calendarName?.length
    ) {
      return (
        <HowToUnsupportedCalendar
          title="Learn how to sync your non-supported calendar"
          copy="Connect a supported calendar (Google, Apple or Microsoft) to A.Team and then sync it with your other calendar"
          onClose={handleBack}
          onSupport={handleSupportModal}
        />
      );
    }

    switch (noCalendarReason) {
      case NoCalendarReason.NonSupportedCalendar:
        return (
          <CalendarType onClose={handleBack} onContinue={setCalendarName} />
        );
      case NoCalendarReason.PrivacyConcern:
        return <Privacy onClose={handleBack} />;
      case NoCalendarReason.MultipleCalendars:
        return (
          <MultiCalendars onClose={handleBack} onSupport={handleSupportModal} />
        );
      case NoCalendarReason.TechIssue:
        return (
          <Support
            label={'Still need help? Reach out to support'}
            placeholder="I am having problems connecting my calendar because..."
            showBanner
            onClose={handleBack}
          />
        );
      default:
        return (
          <Teaser
            onClose={handleModalClose}
            onContinue={() => setShowReasonsModal(true)}
          />
        );
    }
  }, [noCalendarReason, calendarName, showReasonsModal]);

  return (
    <Modal
      variant={props.fullScreen ? 'fullScreen' : 'slideUp'}
      isOpen={props.open}
      onClose={handleModalClose}
      shouldHideGradientStroke={true}
      className={props.fullScreen ? styles.modalv2 : styles.modal}
      canClose={props.canClose}
    >
      <ReasonsModalContext.Provider
        value={{
          canClose: isNil(props.canClose) ? true : props.canClose,
          onModalClose: props.onClose,
        }}
      >
        <div className={styles.modalContainer}>{Step}</div>
      </ReasonsModalContext.Provider>
    </Modal>
  );
};
