import {
  AutomaticallyUnsubscribedEmailNotificationObject,
  NotificationType,
} from '@a_team/models/dist/NotificationObject';
import React, { ReactElement } from 'react';
import Notification from './Notification';
import { getEmailCategoryShortLabel } from '@src/views/EmailUnsubscribe/emailCategoryLabels';

interface AutomaticallyUnsubscribedEmailNotificationProps {
  notification: AutomaticallyUnsubscribedEmailNotificationObject;
  onResubscribe(): Promise<void>;
  isRead: boolean;
  dataTestingId?: string;
}

export default function AutomaticallyUnsubscribedEmailNotification({
  onResubscribe,
  isRead,
  notification,
  dataTestingId,
}: AutomaticallyUnsubscribedEmailNotificationProps): ReactElement {
  const { emailCategory } = notification;

  const handleResubscribe = async () => {
    await onResubscribe();
  };

  return (
    <>
      <Notification
        dataTestingId={dataTestingId}
        createdAt={notification.createdAt}
        notificationType={NotificationType.AutomaticallyUnsubscribedEmail}
        ctaName="Resubscribe"
        ctaAction={handleResubscribe}
        title={`You've been automatically unsubscribed from ${getEmailCategoryShortLabel(
          emailCategory,
        )} emails`}
        description={
          'You have been automatically unsubscribed because we noticed you have not engaged with these emails for over 3 months.'
        }
        isRead={isRead}
      />
    </>
  );
}
