import React, { ReactElement, useState } from 'react';
import cx from 'classnames';
import { CreateCompanyUserPayload } from '@ateams/api/dist/endpoints/Registration';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { Button as CallToActionButton } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { SetLoading } from '@src/hooks/useLoadingState';

interface Props {
  onCreateUser: (data: CreateCompanyUserPayload) => Promise<void>;
  setLoading: SetLoading;
}

const useStyles = createUseStyles({
  input: {
    margin: 0,
    marginBottom: 16,
  },
  shared: {
    '&:first-child': {
      marginRight: 16,
    },
  },
});

export const CreateCompanyUser = (props: Props): ReactElement => {
  const styles = useStyles();
  const [companyUser, updateCompanyUser] =
    useState<Partial<CreateCompanyUserPayload>>();

  return (
    <div style={{ marginTop: 8 }}>
      <div style={{ display: 'flex' }}>
        <OutlinedInput
          value={companyUser?.firstName || ''}
          onChange={(e): void =>
            updateCompanyUser({ ...companyUser, firstName: e.target.value })
          }
          placeholder={'First name'}
          className={cx(styles.input, styles.shared)}
        />
        <OutlinedInput
          value={companyUser?.lastName || ''}
          onChange={(e): void =>
            updateCompanyUser({ ...companyUser, lastName: e.target.value })
          }
          placeholder={'Last name'}
          className={cx(styles.input, styles.shared)}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <OutlinedInput
          value={companyUser?.email || ''}
          onChange={(e): void =>
            updateCompanyUser({ ...companyUser, email: e.target.value })
          }
          placeholder={'Email'}
          className={styles.input}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CallToActionButton
          onClick={() =>
            props.setLoading(
              props
                .onCreateUser(companyUser as CreateCompanyUserPayload)
                .then(() => {
                  updateCompanyUser(undefined);
                }),
              'Company user created',
            )
          }
        >
          Create User
        </CallToActionButton>
      </div>
    </div>
  );
};
