import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { MissionLink } from '@a_team/models/dist/MissionObject';
import { Breakpoints } from '@ateams/components';

interface Props {
  items: MissionLink[];
}

const useStyles = createUseStyles({
  root: {
    marginTop: '32px',
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '12px',
  },
  linkTitle: {
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#222222',
    width: '120px',
    marginRight: '16px',
    flexShrink: 0,
  },
  linkURL: {
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#FE630C',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    linkTitle: {
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '24px',
      color: '#222222',
      width: '180px',
      marginRight: '16px',
      flexShrink: 0,
    },
  },
});

export default function LinksList(props: Props): ReactElement {
  const { items } = props;
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {items.map((item, index) => (
        <div key={`link-${index}`} className={styles.linkContainer}>
          <div className={styles.linkTitle}>{item.title}</div>
          <a
            href={item.URL}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.linkURL}
          >
            {item.URL}
          </a>
        </div>
      ))}
    </div>
  );
}
