import {
  NarrativeConnectionSummary,
  UserCardObject,
} from '@a_team/models/dist/UserObject';
import React from 'react';
import { createUseStyles } from 'react-jss';
import UserAvatar from '../UserAvatar';
import cx from 'classnames';
import SeeProfileAndConnectV2 from './SeeProfileAndConnectV2';
import blueCompany from './blue-company.svg';
import blueEmail from './blue-email.svg';
import greenSuitcase from './green-suitcase.svg';
import pinkSuitcase from './pink-suitcase.svg';
import greyUser from './grey-user.svg';
import orangeUser from './orange-user.svg';
import purpleMission from './purple-mission.svg';
import purpleTeam from './purple-team.svg';
import blueLocation from './blue-location.svg';
import { pluralize } from '@src/helpers/strings';
import { Colors, Icon, IconType } from '@ateams/components';

export enum DiscoveryCardType {
  Discovered = 'Discovered',
  Search = 'Search',
}

interface DiscoveryUserCardProps {
  type: DiscoveryCardType;
  suggestedUser: UserCardObject;
  onDismiss?: (builderId: string) => void;
  className?: string;
}

export const useStylesDiscoveryUserCard = createUseStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginBottom: '8px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    padding: '24px',
    width: '100%',
    maxWidth: '100%',
    background: Colors.backgroundLight,
    position: 'relative',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  fullName: {
    color: '#222222',
    fontSize: '18px',
  },
  mainRole: {
    marginTop: 4,
    color: '#222222',
    fontSize: '14px',
    marginBottom: '24px',
  },
  connectionInsightContainer: {
    color: '#62646A',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    alignItems: 'center',
    marginBottom: ({ type }) =>
      type === DiscoveryCardType.Discovered ? '24px' : '0',
  },
  userAvatar: {
    display: 'flex',
    justifyContent: 'start',
  },
  connectionTypesContainer: {
    display: 'flex',
    gap: '4px',
    marginBottom: '8px',
  },
  connectionType: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    background: '#F7F7F7',
    borderRadius: '4px',
    '& img': {
      width: '16px',
      height: '16px',
    },
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  removeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
    width: 21,
    height: 21,
    cursor: 'pointer',
    // remove button default styles
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    outline: 'none',

    // select svg and give it cursor
    '& i': {
      cursor: 'pointer',
    },
  },
  iconMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
});

const DiscoveryUserCard = ({
  type,
  suggestedUser,
  onDismiss,
  className,
}: DiscoveryUserCardProps): JSX.Element => {
  const styles = useStylesDiscoveryUserCard({
    type,
  });

  const insightMessage = getInsightMessages(suggestedUser.narrativeConnection);

  const mainRole =
    suggestedUser?.talentProfile?.talentSpecializations
      ?.mainTalentSpecialization?.name;

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.content}>
        <div className={styles.userInfo}>
          {type === DiscoveryCardType.Discovered && onDismiss && (
            <button
              className={styles.removeButton}
              onClick={() => {
                onDismiss(suggestedUser.uid);
              }}
            >
              <Icon
                type={IconType.Close}
                style={{
                  height: '12px',
                  width: '12px',
                }}
              />
            </button>
          )}

          <div className={styles.header}>
            <UserAvatar
              size={108}
              shouldResizeUploadCareImages={false}
              containerClassName={styles.userAvatar}
              src={suggestedUser.profilePictureURL}
            />
          </div>

          <div className={styles.fullName}>{suggestedUser.fullName}</div>
          {mainRole && <div className={styles.mainRole}>{mainRole}</div>}
        </div>
        <div>
          {[DiscoveryCardType.Discovered].includes(type) && (
            <div className={styles.connectionInsightContainer}>
              {insightMessage.map((message, index) => {
                return (
                  <div key={index} className={styles.iconMessageContainer}>
                    {message.icon}
                    {message.message}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div>
        {[DiscoveryCardType.Discovered, DiscoveryCardType.Search].includes(
          type,
        ) && <SeeProfileAndConnectV2 suggestedUser={suggestedUser} />}
      </div>
    </div>
  );
};

export default DiscoveryUserCard;

export function getInsightMessages(
  narrativeConnection: NarrativeConnectionSummary | undefined,
): { message: string; icon: JSX.Element }[] {
  const defaultMessage =
    'This user is not in your network yet, but you can still recommend them.';

  if (!narrativeConnection)
    return [
      {
        message: defaultMessage,
        icon: <img src={greyUser} alt="default icon" />,
      },
    ];

  const {
    types,
    companies,
    projectsCount,
    mutualConnectionsCount,
    missionsCount,
    inviterUser,
  } = narrativeConnection;

  const messagesWithIcons: { message: string; icon: JSX.Element }[] = [];
  const maxMessages = 2;

  const conditions = [
    {
      check: () => types.includes('companies') && companies,
      message: `You both worked at ${companies && companies[0]}`,
      icon: (
        <img
          src={blueCompany}
          alt="company icon"
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      ),
    },
    {
      check: () => types.includes('missions'),
      message: `You worked on ${pluralize(missionsCount, 'mission')} together`,
      icon: (
        <img
          src={purpleMission}
          alt="mission icon"
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      ),
    },
    {
      check: () => types.includes('projects') && projectsCount,
      message: `You worked on ${pluralize(projectsCount, 'project')} together`,
      icon: (
        <img
          src={greenSuitcase}
          alt="Project icon"
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      ),
    },
    {
      check: () =>
        types.includes('mutualConnections') && mutualConnectionsCount,
      message: `You have ${pluralize(
        mutualConnectionsCount,
        'mutual connection',
      )}`,
      icon: (
        <img
          src={orangeUser}
          alt="mutualConnections icon"
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      ),
    },
    {
      check: () => types.includes('teams'),
      message: 'You are part of the same team',
      icon: <img src={purpleTeam} alt="team" />,
    },
    {
      check: () => types.includes('invitedBy'),
      message: `You’ve both been referred by ${inviterUser} to A.Team`,
      icon: (
        <img
          src={blueEmail}
          alt="referred icon"
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      ),
    },
    {
      check: () => types.includes('invitations'),
      message: `You referred this person to A.Team`,
      icon: (
        <img
          src={blueEmail}
          alt="referral icon"
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      ),
    },
    {
      check: () => types.includes('missionRecommendations'),
      message: `You were recommended by this person to a mission`,
      icon: (
        <img
          src={blueEmail}
          alt="mission recommendation icon"
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      ),
    },
    {
      check: () => types.includes('role'),
      message: 'Suggested based on Role',
      icon: (
        <img
          src={pinkSuitcase}
          alt="Role icon"
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      ),
    },
    {
      check: () => types.includes('location'),
      message: 'Suggested based on Location',
      icon: (
        <img
          src={blueLocation}
          alt="Locations icon"
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      ),
    },
  ];

  for (const condition of conditions) {
    if (messagesWithIcons.length >= maxMessages) break;
    if (condition.check())
      messagesWithIcons.push({
        message: condition.message,
        icon: condition.icon,
      });
  }

  return messagesWithIcons.length
    ? messagesWithIcons
    : [
        {
          message: defaultMessage,
          icon: <img src={greyUser} alt="default icon" />,
        },
      ];
}
