import { RefObject, useEffect } from 'react';

export default function useClickOutside<T extends HTMLElement>(
  ref: RefObject<T>,
  onClick: () => void,
): void {
  function handleClick(e: MouseEvent): void {
    if (ref.current && !ref.current.contains(e.target as Node) && onClick) {
      onClick();
    }
  }

  useEffect((): (() => void) => {
    document.addEventListener('mousedown', handleClick);

    return (): void => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
}
