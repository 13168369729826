import { IconType } from '@ateams/components';
import { CountryObject } from '@src/components/Modal/AllLocationsModal';
import { observer } from 'mobx-react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Requirement from '../common/Requirement';

interface LocationProps {
  userCountry: [CountryObject | undefined, boolean];
  toggleAllLocationsModalOpen?(): void;
}

export const useStyles = createUseStyles({
  seeLocation: {
    fontSize: '15px',
    textDecorationLine: 'underline',
    cursor: 'pointer',
    textUnderlineOffset: '3px',
  },
});

function Location({
  userCountry,
  toggleAllLocationsModalOpen,
}: LocationProps): JSX.Element {
  const styles = useStyles();

  const [currentUserCountry, userCountryInRoleLocations] = userCountry;

  const meetsRequirement =
    userCountryInRoleLocations && currentUserCountry?.name !== undefined;

  const feedback = meetsRequirement
    ? `Your location works for this role`
    : 'Your location doesn’t work for this role';

  return (
    <Requirement
      iconType={IconType.Location}
      name="Required location"
      dataTestingId="location-requirement"
      meetsRequirement={meetsRequirement}
      feedback={feedback}
    >
      <div
        onClick={toggleAllLocationsModalOpen}
        className={styles.seeLocation}
        data-testing-id="see-locations"
      >
        See locations
      </div>
    </Requirement>
  );
}

export default observer(Location);
