import React, { ReactElement, useState } from 'react';
import {
  TimesheetRecordData,
  TimesheetRecordKey,
} from '@a_team/models/dist/TimesheetObject';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Icon, IconType } from '@ateams/components';
import { LoadingState } from '@src/hooks/useLoadingState';
import UserAvatar from '@src/components/UserAvatar';
import {
  MissionAdminRole,
  MissionRoleId,
} from '@a_team/models/dist/MissionRole';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { RoleRecord } from '@src/stores/Missions/Mission';
import Tooltip from '@src/components/Tooltip';
import { TimeField } from '@src/components/TimeField';
import { formatMinutesToTime, stringifyMinutes } from '@src/helpers/time';

interface Props {
  record: RoleRecord;
  currentUserRole: MissionAdminRole | undefined;
  loading: LoadingState;
  index: number;
  onEdit(key: TimesheetRecordKey, data: TimesheetRecordData): void;
  onDelete(key: TimesheetRecordKey): void;
  timesheetsView: 'single' | 'team' | MissionRoleId;
  disabled?: boolean;
  clientView?: boolean;
}

const useStyles = createUseStyles({
  borderRight: { borderRight: '0.5px solid #C0C0C0' },
  borderLeft: { borderLeft: '0.5px solid #C0C0C0' },
  cell: {
    paddingTop: '32px',
    paddingBottom: '32px',
    paddingRight: '24px',
    paddingLeft: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  teamCell: {
    width: '230px',
    flexShrink: 0,
  },
  hoursCell: {
    width: '158px',
    flexShrink: 0,
  },
  taskCell: {
    flex: 1,
    width: '100%',
    justifyContent: 'flex-start',
  },
  editCell: {
    width: '158px',
    flexShrink: 0,
  },
  smallDivider: {
    borderRight: '1px solid #C0C0C0',
    height: '16px',
    marginLeft: '12px',
    marginRight: '12px',
  },
  done: {
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#FE630C',
    cursor: 'pointer',
  },
});

export default function TableRow(props: Props): ReactElement {
  const {
    record,
    index,
    loading,
    currentUserRole,
    timesheetsView,
    onEdit,
    onDelete,
    disabled,
    clientView,
  } = props;
  const styles = useStyles();
  const [hoursError, setHoursError] = useState(false);
  const [detailsError, setDetailsError] = useState(false);
  const [editing, setEditing] = useState(false);
  const [minutes, setMinutes] = useState(record.minutes);
  const [details, setDetails] = useState(record.details);

  const checkForEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      editRecord();
    } else if (e.key === 'Escape') {
      setEditing(false);
    }
  };

  const editRecord = () => {
    if (!minutes) {
      setHoursError(true);
    } else if (details.length < 1) {
      setDetailsError(true);
    } else {
      setHoursError(false);
      setDetailsError(false);
      onEdit(record.key, {
        date: record.date,
        minutes,
        details,
      });
      setEditing(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        borderTop: index > 0 ? '1px solid #C0C0C0' : 'none',
      }}
      className={styles.borderLeft}
    >
      {timesheetsView === 'team' && (
        <div className={cx(styles.cell, styles.teamCell, styles.borderRight)}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UserAvatar
              src={
                record.role.user?.username
                  ? record.role.user.profilePictureURL
                  : undefined
              }
              size={24}
              containerStyle={{ marginRight: '8px' }}
            />
            <div>
              {record.role.user?.username && record.role.user?.fullName}
            </div>
          </div>
        </div>
      )}
      <div
        className={cx(styles.cell, styles.hoursCell, styles.borderRight)}
        style={{ padding: editing ? 0 : 'auto', minHeight: '89px' }}
      >
        {editing ? (
          <Tooltip label={hoursError && '❌ Value must be greater than zero'}>
            <TimeField
              style={{
                margin: 0,
                width: '110px',
              }}
              defaultValue={formatMinutesToTime(record.minutes)}
              onChange={(e, minutes) => {
                setMinutes(minutes);
                setHoursError(false);
              }}
              onKeyUp={checkForEnter}
              error={hoursError}
              disabled={disabled}
              defaultFocused
            />
          </Tooltip>
        ) : (
          <div>{stringifyMinutes(record.minutes)}</div>
        )}
      </div>
      <div
        className={cx(styles.cell, styles.taskCell, styles.borderRight)}
        style={{ ...(editing && { padding: 0 }), minHeight: '89px' }}
      >
        {editing ? (
          <Tooltip
            label={detailsError && '❌ Length must be greater than zero'}
            rootStyle={{ width: 'calc(100% - 48px)' }}
          >
            <OutlinedInput
              style={{
                marginTop: 0,
                marginBottom: 0,
                marginLeft: '24px',
                marginRight: '24px',
              }}
              error={detailsError}
              value={details || ''}
              placeholder="Describe the main task..."
              onChange={(e): void => setDetails(e.target.value)}
              onKeyUp={checkForEnter}
            />
          </Tooltip>
        ) : (
          <div style={{ wordBreak: 'break-word' }}>{record.details}</div>
        )}
      </div>
      <div className={cx(styles.cell, styles.editCell)}>
        {currentUserRole?.rid === record.role.rid && !clientView && (
          <>
            {!editing ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '24px',
                }}
              >
                <Icon
                  type={IconType.EditGray}
                  style={{
                    cursor: disabled ? 'default' : 'pointer',
                    ...(disabled && { pointerEvents: 'none', opacity: 0.2 }),
                  }}
                  onClick={() => setEditing(true)}
                />
                <div className={styles.smallDivider} />
                <Icon
                  type={IconType.TrashRed}
                  style={{
                    cursor: disabled ? 'default' : 'pointer',
                    ...(disabled && { pointerEvents: 'none', opacity: 0.2 }),
                  }}
                  onClick={() => loading !== true && onDelete(record.key)}
                />
              </div>
            ) : (
              <div className={styles.done} onClick={editRecord}>
                Done
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
