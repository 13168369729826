import { Colors, IconType } from '@ateams/components';
import { Icon } from '@ateams/components';
import AspectRatioBox from '@src/components/AspectRatioBox';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Skeleton from 'react-loading-skeleton';

const useStyles = createUseStyles({
  container: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cover: {
    backgroundColor: '#FBF6FF',
    color: Colors.secondaryDark,
    borderRadius: 8,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: 8,
      fontSize: 12,
    },
  },
});

export const ImportingProjectSkeleton = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <AspectRatioBox aspectRatio={4 / 3} className={styles.cover}>
        <div>
          <Icon type={IconType.CircleLoadingViolet} rotating />
        </div>
        <div>Importing...</div>
      </AspectRatioBox>
      <div>
        <Skeleton
          width={18}
          height={18}
          baseColor="#FBF6FF"
          highlightColor="#fff"
          style={{ marginTop: 18, marginRight: 8 }}
          inline
        />
        <Skeleton
          width="60%"
          height={18}
          baseColor="#FBF6FF"
          highlightColor="#fff"
          style={{ marginTop: 18 }}
          inline
        />
      </div>
      <Skeleton
        width="80%"
        height={18}
        baseColor="#FBF6FF"
        highlightColor="#fff"
        style={{ marginTop: 12 }}
      />
      <Skeleton
        width="50%"
        height={12}
        baseColor="#FBF6FF"
        highlightColor="#fff"
        style={{ marginTop: 12 }}
      />
      <Skeleton
        width="50%"
        height={20}
        baseColor="#FBF6FF"
        highlightColor="#fff"
        style={{ marginTop: 12 }}
      />
    </div>
  );
};
