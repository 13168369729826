import React from 'react';
import { VetterRowProps } from '.';
import { Tag, TagList } from '@ateams/components';

export const VettingRolesField: React.FC<VetterRowProps> = ({ vetter }) => {
  return (
    <TagList>
      {vetter.vettingRoles?.map((role) => (
        <Tag key={role.cid}>{role.title}</Tag>
      ))}
    </TagList>
  );
};
