import cx from 'classnames';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import Circle from '../Circle';

type DivProps = JSX.IntrinsicElements['div'];

export interface Props extends DivProps {
  color: string;
  inner?: JSX.IntrinsicElements['div'];
}

const useStyles = createUseStyles<Props>({
  inner: {
    background: ({ color }) => color,
  },
});

const ColoredCircle = ({
  inner: { className: innerClassName, ...innerProps } = {},
  ...props
}: Props): ReactElement | null => {
  const styles = useStyles(props);
  return (
    <Circle
      inner={{ className: cx(innerClassName, styles.inner), ...innerProps }}
      {...props}
    />
  );
};

export default ColoredCircle;
