import { Button } from '@a_team/ui-components';
import CompanyLogo from '@src/components/CompanyLogo';
import React from 'react';
import { useRequestStyles } from './Request';
import Skeleton from 'react-loading-skeleton';
import { useCommonStyles } from './commonStyles';

const RequestSkeleton = (): JSX.Element => {
  const styles = useRequestStyles();
  const commonStyles = useCommonStyles();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <CompanyLogo logoUrl={undefined} size={72} />
        <h4 className={commonStyles.title}>
          <Skeleton width={200} height={20} />
        </h4>
        <div className={styles.date}>
          <div>
            <Skeleton width={100} />
          </div>
          <span className={styles.line} />
          <div>
            <Skeleton width={100} />
          </div>
        </div>
        <div className={styles.description}>
          <div className={styles.descriptionText}>Description</div>

          <Skeleton count={3} />
        </div>
      </div>
      <div className={styles.btnsContainer}>
        <Button disabled variant="secondary">
          Decline
        </Button>
        <Button disabled>Accept</Button>
      </div>
    </div>
  );
};

export default RequestSkeleton;
