import React, { ReactElement } from 'react';

export type Props = JSX.IntrinsicElements['svg'];

const CheckIcon = (props: Props): ReactElement | null => (
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5537 7.24218L12.005 0.790842C12.3928 0.403053 13.0216 0.403053 13.4094 0.790842C13.7971 1.17863 13.7971 1.80736 13.4094 2.19515L5.5537 10.0508L0.991038 5.48814C0.603248 5.10035 0.603248 4.47162 0.991038 4.08383C1.37883 3.69604 2.00756 3.69604 2.39535 4.08383L5.5537 7.24218Z"
    />
  </svg>
);

export default CheckIcon;
