import React from 'react';
import { ModalPages } from '.';
import MissionCodeOfConduct from './MissionCodeOfConduct';
import PlatformCodeOfConduct from './PlatformCodeOfConduct';
import QuickOnboardingChecklist from './QuickOnboardingChecklist';
import MissionCodeOfConductChecklist from './MissionCodeOfConductChecklist';

interface BodyProps {
  page: ModalPages;
  setPercentageMissionChecklistCompleted: (percentage: number) => void;
}

const Body = ({
  page,
  setPercentageMissionChecklistCompleted,
}: BodyProps): JSX.Element => {
  return (
    <div>
      {page === ModalPages.PlatformCodeOfConduct && <PlatformCodeOfConduct />}
      {page === ModalPages.MissionCodeOfConduct && <MissionCodeOfConduct />}
      {page === ModalPages.QuickOnboardingChecklist && (
        <QuickOnboardingChecklist />
      )}
      {page === ModalPages.MissionCodeOfConductChecklist && (
        <MissionCodeOfConductChecklist
          setPercentageMissionChecklistCompleted={
            setPercentageMissionChecklistCompleted
          }
        />
      )}
    </div>
  );
};

export default Body;
