import React from 'react';
import { createUseStyles } from 'react-jss';
import { components, OptionProps } from 'react-select';
import { VettingProcessEmailTemplates } from '@a_team/models/dist/vetting-processes/emails';
import { TextColors } from '@ateams/components';
import {
  Size,
  Text,
} from '@src/views/VettingFeedbackForm/components/typography';
import {
  EmailTemplateToDescriptionMap,
  EmailTemplateToLabelMap,
} from './options';
import { EmailTemplateOptionType } from '.';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 16px 10px 16px',
    zIndex: 100,
  },
  description: {
    color: TextColors.regularLight,
    lineHeight: '16px',
  },
});

export const EmailTemplateSelectorOption: React.FC<
  OptionProps<EmailTemplateOptionType, false>
> = (props) => {
  const styles = useStyles();

  const emailTemplate = props.data.template as VettingProcessEmailTemplates;

  return (
    <components.Option {...props} className={styles.container}>
      <Text size={Size.Medium}>{EmailTemplateToLabelMap[emailTemplate]}</Text>
      <Text size={Size.Small} className={styles.description}>
        {EmailTemplateToDescriptionMap[emailTemplate]}
      </Text>
    </components.Option>
  );
};
