import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
} from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

export interface ProgressBarProps {
  progress: number; // Percent
  position?: number; // Percent
  className?: string;
  direction?: 'horizontal' | 'vertical';
  fillColor?: string;
  children?: ReactElement[];
}

const defaultProps = {
  direction: 'horizontal',
  fillColor: '#79CE00',
};

const useStyles = createUseStyles({
  bar: (props: ProgressBarProps) => ({
    background: '#EFEFF0',
    borderRadius: 100,
    minWidth: 80,
    height: 2,
    position: 'relative',
    ...(props.direction === 'vertical' && {
      width: 2,
      height: '100%',
      minHeight: 80,
      minWidth: 'auto',
    }),
  }),
  fill: (props: ProgressBarProps) => ({
    background: props.fillColor || '#79CE00',
    height: '100%',
    transition: 'all 1s',
    ...(props.direction === 'vertical' && {
      width: '100%',
      height: 'auto',
    }),
  }),
});

const ProgressBar = (props: ProgressBarProps): ReactElement => {
  const styles = useStyles(props);
  const { className, progress, position, direction, children } = props;

  return (
    <div className={cx(styles.bar, className)}>
      <div
        className={styles.fill}
        style={
          direction === 'vertical'
            ? { height: `${progress}%` }
            : { width: `${progress}%` }
        }
      />
      {Children.toArray(children).map((elm, i) =>
        isValidElement(elm)
          ? cloneElement(elm, {
              key: i,
              // TODO: Fix this hack
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              style:
                direction === 'vertical'
                  ? { top: `${elm.props.position}%`, left: -5 }
                  : { left: `${elm.props.position}%`, top: -5 },
              label: (
                position !== undefined
                  ? position === elm.props.position
                  : progress === elm.props.position
              )
                ? elm.props.label || `${i + 1}/${children?.length}`
                : undefined,
              color:
                elm.props.color ||
                (progress >= elm.props.position ? props.fillColor : undefined),
            })
          : elm,
      )}
    </div>
  );
};

ProgressBar.defaultProps = defaultProps;
export default ProgressBar;
