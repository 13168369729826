import React, { ReactElement } from 'react';
import { SearchObject } from '@src/stores/Search';
import { createUseStyles } from 'react-jss';
import { Icon, IconType } from '@ateams/components';
import cx from 'classnames';

interface Props {
  item: SearchObject;
  onSelect: (item: SearchObject) => void;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    padding: '15px 24px',
    cursor: 'pointer',
    display: 'flex',
    '&:hover': {
      background: '#FFFAF7',
      '& >.icon': {
        background: 'rgba(254,99,12, 0.05)',
      },
    },
  },
  query: {
    display: 'block',
    color: '#222222',
    fontWeight: 500,
    fontSize: 15,
  },
  category: {
    display: 'block',
    color: '#62646A',
    fontSize: 15,
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    background: '#F7F7F7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#62646A',
    marginRight: 24,
  },
});

export const SearchItem = (props: Props): ReactElement => {
  const styles = useStyles();
  const { item, className } = props;

  return (
    <div
      className={cx(styles.container, className)}
      onClick={(): void => {
        props.onSelect(item);
      }}
    >
      <div className={cx(styles.icon, 'icon')}>
        <Icon type={IconType.Search} size="xsmall" muted />
      </div>
      <div>
        <span className={styles.query}>
          {item.user?.fullName || item.query}
        </span>
        <span className={styles.category}>
          {item.user?.roleCategory?.title || item.user?.title || item.category}
        </span>
      </div>
    </div>
  );
};
