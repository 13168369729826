import React from 'react';
import { theme } from '@a_team/ui-components';

export const PlusIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M12.0413 7.91678H3.79171M7.9165 3.79199V12.0416"
        stroke={theme.colors.Grey[800]}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
