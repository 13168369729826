import { Avatar, Checkbox, DatePicker, Select } from '@a_team/ui-components';
import { SelectOption } from '@ateams/components';
import { useStores } from '@src/stores';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import ErrorForm from '../common/ErrorForm';
import CommonStyles from '../common/styles';

interface RoleProps {
  roles: { value: string; label: string }[] | undefined;
}

const useStyles = createUseStyles({
  box: {
    marginTop: 8,
    padding: 16,
    border: '1px solid #DADADC',
    borderRadius: 8,
  },
  roleName: { marginTop: 8 },
  role: {
    marginTop: 16,
  },
  dates: {
    marginTop: 8,
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  date: {
    flex: 1,
    position: 'relative',
    '& input': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  calendar: {
    minWidth: '296px !important',
    position: 'absolute',
    zIndex: 999999,
  },
  isOngoingCheckboxWrapper: {
    display: 'flex',
    paddingTop: 16,
    '& > label > span:first-child': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& svg': {
      marginBottom: 0,
    },
  },
});

const Role = ({ roles }: RoleProps): JSX.Element => {
  const {
    register,
    unregister,
    trigger,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();
  const commonStyles = CommonStyles();
  const styles = useStyles();
  const { auth } = useStores();

  const jobRoleId = watch('jobRoleId');
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const [isOngoing, setStillWorking] = useState(!!startDate && !endDate);
  const isOngoingRef = useRef(isOngoing);

  useEffect(() => {
    isOngoingRef.current = isOngoing;
  }, [isOngoing]);

  useEffect(() => {
    register('jobRoleId', {
      required: 'A job role  is required',
    });

    register('startDate', {
      required: 'Start date is required',
    });

    register('endDate', {
      validate: (endDate) => {
        const startDate = getValues('startDate');
        if (startDate && endDate) {
          const startDateObj = new Date(startDate);
          const endDateObj = new Date(endDate);
          if (endDateObj < startDateObj) {
            return 'The end date cannot be earlier than the start date';
          }
        }

        if (startDate && !endDate && !isOngoingRef.current) {
          return `The end date is required if you're not still working on this role`;
        }

        return true;
      },
    });

    return () => {
      unregister('jobRoleId');
      unregister('startDate');
      unregister('endDate');
    };
  }, [register, unregister]);

  const role = useMemo(
    () => roles?.find((role) => role.value === jobRoleId),
    [roles, jobRoleId],
  );

  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.title}>Role</div>
      <div className={styles.box}>
        <div className={commonStyles.halfWidthContainer}>
          <div>
            <Avatar
              size={'md'}
              styles={{
                borderRadius: 100,
              }}
              src={auth.user?.profilePictureURL}
            />
          </div>
          <div className={styles.roleName}>
            <Controller
              name="jobRole"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  className={commonStyles.inputWithDottedUnderline}
                  type="text"
                  placeholder="Role Name"
                  data-testing-id="profile-project-card-role-text-input"
                />
              )}
            />
          </div>
          <div
            className={styles.role}
            data-testing-id="profile-project-card-role-select"
          >
            <Select
              styles={{
                control: (base) => ({
                  ...base,
                  border: '1px solid #DADADC',
                  borderColor: '#DADADC !important',
                  backgroundColor: 'white',
                }),
                placeholder: (base) => ({
                  ...base,
                  color: '#62646A',
                }),
              }}
              value={role}
              placeholder="Select role type"
              options={roles}
              onChange={(data) => {
                setValue('jobRoleId', (data as SelectOption).value);
                trigger('jobRoleId');
              }}
            />
          </div>
          <div className={styles.dates}>
            <div
              className={styles.date}
              data-testing-id="profile-project-card-start-date-picker"
            >
              <DatePicker
                placeholder="Date"
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  borderColor: '#DADADC',
                  paddingTop: '8px !important',
                  paddingBottom: '8px !important',
                }}
                calendarOptions={{
                  maxDetail: 'year',
                  className: styles.calendar,
                  maxDate: endDate ? new Date(endDate) : undefined,
                }}
                dateFormatter={(date) => format(date, 'MMM, yyyy')}
                defaultDate={startDate ? new Date(startDate) : undefined}
                onChange={(date) => {
                  setValue('startDate', date);
                  trigger('startDate');
                }}
              />
            </div>
            <div>to</div>
            <div
              className={styles.date}
              data-testing-id="profile-project-card-end-date-picker"
            >
              {isOngoing ? (
                <DatePicker
                  key="date-picker-present"
                  placeholder="Present"
                  style={{
                    width: '100%',
                    maxWidth: '100%',
                    borderColor: '#DADADC',
                    paddingTop: '8px !important',
                    paddingBottom: '8px !important',
                  }}
                  defaultValue=""
                  disabled
                />
              ) : (
                <DatePicker
                  key="date-picker-end-date"
                  placeholder="Date"
                  style={{
                    width: '100%',
                    maxWidth: '100%',
                    borderColor: '#DADADC',
                    paddingTop: '8px !important',
                    paddingBottom: '8px !important',
                  }}
                  calendarOptions={{
                    maxDetail: 'year',
                    className: styles.calendar,
                    minDate: startDate ? new Date(startDate) : undefined,
                  }}
                  dateFormatter={(date) => format(date, 'MMM, yyyy')}
                  defaultDate={endDate ? new Date(endDate) : undefined}
                  onChange={(date) => {
                    setValue('endDate', date);
                  }}
                  disabled={isOngoing || !startDate}
                />
              )}
            </div>
          </div>
          <div className={styles.isOngoingCheckboxWrapper}>
            <Checkbox
              onChange={() => {
                if (!isOngoing) {
                  setValue('endDate', undefined);
                }
                setStillWorking(!isOngoing);
              }}
              label="I’m still working on this project"
              checked={isOngoing}
            />
          </div>
        </div>
      </div>
      <ErrorForm field="jobRoleId" errors={errors} />
      <ErrorForm field="startDate" errors={errors} />
      <ErrorForm field="endDate" errors={errors} />
    </div>
  );
};

export default Role;
