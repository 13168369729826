import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  VettingProcessAudit,
  VettingProcessAuditTypes,
} from '@a_team/models/dist/vetting-process-audit';
import { ActivityRecordMainText, ActivityRecordUser } from '.';

export interface VetterNoShowActivityRecordProps {
  vettingProcessAudit: VettingProcessAudit<VettingProcessAuditTypes.VetterNoShow>;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const VetterNoShowActivityRecord: React.FC<
  VetterNoShowActivityRecordProps
> = ({ vettingProcessAudit }) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <ActivityRecordMainText>{`Vetter hasn't shown up`}</ActivityRecordMainText>

      {vettingProcessAudit.payload.noShowUser && (
        <ActivityRecordUser user={vettingProcessAudit.payload.noShowUser} />
      )}
    </div>
  );
};
