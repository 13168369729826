import React, { ReactElement, useMemo } from 'react';
import { observer } from 'mobx-react';
import MainLayout from '@src/layouts/Main';
import cx from 'classnames';
import SearchInput from '@src/views/Discovery/components/SearchInput';
import { createUseStyles } from 'react-jss';
import { useStores } from '@src/stores';
import UserCardList from '@src/components/UserCardList';
import SectionHeading from '@src/components/SectionHeading';
import { Tag } from '@ateams/components';
import { searchResultsLoader } from '@src/hooks/useSearchResults';
import NoResults from '@src/components/NoResults';
import { Breakpoints } from '@ateams/components';
import { UserCardObject } from '@a_team/models/dist/UserObject';

export const searchResultsViewLoader = searchResultsLoader;

const useStyles = createUseStyles({
  stickyContainer: {
    position: 'fixed',
    top: 40,
    padding: 0,
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#f7f7f7',
    transition: 'all 0.5s',
    zIndex: 10,
  },
  innerContent: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 150,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    stickyContainer: {
      width: 'calc(100% - 120px)',
      padding: 24,
      top: 0,
      paddingLeft: '56px',
      paddingRight: '56px',
    },
    innerContent: {
      paddingLeft: 55,
      paddingRight: 55,
      paddingTop: 125,
    },
  },
});

const SearchResultsView = (): ReactElement => {
  const styles = useStyles();
  const { search } = useStores();
  const { userSearch, noResults, loadingResults, searchResults } = search;

  const userCards = useMemo(() => {
    return searchResults.users
      ? searchResults.users
          .map((item) => item.user)
          .filter((item) => item !== undefined)
      : [];
  }, [searchResults.users]);

  return (
    <MainLayout title="Search Results" style={{ padding: 0 }}>
      <div className={cx(styles.stickyContainer)}>
        <SearchInput withMenu style={{ width: '100%', position: 'relative' }} />
      </div>
      <div className={styles.innerContent}>
        {!noResults && (
          <Tag key={userSearch?.category} color="success">
            {userSearch?.category}
          </Tag>
        )}
        {!loadingResults && noResults ? (
          <NoResults />
        ) : (
          <>
            <SectionHeading>
              {search.searchResults.users && search.searchResults.users.length}{' '}
              Results Available
            </SectionHeading>
            <UserCardList users={userCards as UserCardObject[]} />
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default observer(SearchResultsView);
