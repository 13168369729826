import React from 'react';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { createUseStyles } from 'react-jss';
import {
  FontSizes,
  FontWeights,
  Spacing,
  TextColors,
} from '@ateams/components';
import TargeterSuggestedTeamCardConnectionItem from './TeamCardConnectionItem';
import { getFormattedPeriod } from './utils';
import CompanyAvatar from '@src/components/CompanyAvatar/CompanyAvatar';
import { TeamCardUserTooltipGroup } from '@a_team/models/dist/TeamGraphObject';

interface Props {
  groups: TeamCardUserTooltipGroup[];
  disabled?: boolean;
}

const useStyles = createUseStyles({
  '@global': {
    '.tippy-tooltip': {
      padding: 0,
    },
  },
  connectionItem: {
    '&:not(:first-child)': {
      marginLeft: -4,
    },
  },
});

function formatYearRange(from?: number, to?: number): string {
  if (!from && !to) {
    return '';
  }
  if (!from) {
    return to?.toString() || '';
  }
  if (!to) {
    return from?.toString() || '';
  }
  return from !== to ? `${from} - ${to}` : from.toString();
}

const TeamCardUserTooltip: React.FC<Props> = ({
  children,
  groups,
  disabled,
}) => {
  const styles = useStyles();

  return (
    <TooltipWrapped
      disabled={disabled}
      html={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 386,
            padding: Spacing.large,
            gap: Spacing.large,
          }}
        >
          {groups.map((group) => {
            const key = `${group.cid}-${group.connections.map((c) =>
              c.users.join(','),
            )}`;
            return (
              <div
                key={key}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: Spacing.small,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <CompanyAvatar
                    src={group.logoUrl}
                    alt={`${group.name} company logo`}
                    size={32}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      paddingLeft: Spacing.small,
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: 'nowrap',
                        fontSize: FontSizes.small,
                        fontWeight: FontWeights.regular,
                      }}
                    >
                      {group.name}
                    </div>
                    <div
                      style={{
                        color: TextColors.regularDark,
                        whiteSpace: 'nowrap',
                        fontSize: FontSizes.small,
                        fontWeight: FontWeights.regular,
                      }}
                    >
                      {group.type}
                    </div>
                  </div>
                </div>
                {group.connections.map((connection) => {
                  const key = `${group.cid}-${connection.users.join(',')}`;
                  return (
                    <div
                      key={key}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        {connection.users.map((user) => (
                          <TargeterSuggestedTeamCardConnectionItem
                            key={`company-${user?.user || ''}`}
                            username={user?.username || ''}
                            profilePictureURL={user?.profilePictureURL || ''}
                            itemClassName={styles.connectionItem}
                            isLoading={false}
                          />
                        ))}
                      </div>
                      <div
                        style={{
                          fontSize: FontSizes.small,
                          fontWeight: FontWeights.regular,
                        }}
                      >
                        {connection.overlap
                          ? `${getFormattedPeriod(
                              connection.overlapDays,
                            )} overlap in ${formatYearRange(
                              connection.overlapStartYear,
                              connection.overlapEndYear,
                            )}`
                          : connection.overlapDays !== -1
                          ? 'Worked at different times'
                          : 'Overlap Unknown'}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      }
      position="top"
      arrow
      animation="fade"
    >
      {children}
    </TooltipWrapped>
  );
};

export default TeamCardUserTooltip;
