import { Checkbox } from '@a_team/ui-components';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface HasZeroToOneExperienceProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  label: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  desc: {
    display: 'block',
    fontSize: '15px',
    fontWeight: 300,
    lineHeight: '24px',
    color: '#62646A',
    textAlign: 'start',
    margin: 0,
  },
});

const HasZeroToOneExperience = ({
  value,
  onChange,
}: HasZeroToOneExperienceProps): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div>
        <label className={styles.label}>
          Were you involved from inception to launch (0 -{'>'} 1)? (Optional)
        </label>
        <span className={styles.desc}>
          Zero to one is creation and development of a unique product from the
          ground up.
        </span>
      </div>
      <Checkbox
        label="I was involved in zero to one with this project"
        checked={value}
        onChange={() => {
          onChange(!value);
        }}
      />
    </div>
  );
};

export default HasZeroToOneExperience;
