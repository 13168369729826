import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import AdminNotesObject from '@a_team/models/dist/AdminNotesObject';
import { stringifyMinutes } from '@src/helpers/time';
import {
  AdminBasicUserObject,
  BasicUserObject,
  UserScrubbed,
} from '@a_team/models/dist/UserObject';
import { AsyncSelect, SelectOption } from '@ateams/components';
import UserAvatar, { BadgesPosition } from '@src/components/UserAvatar';
import UserSup from '@src/components/UserSup';
import { isUserFullyVetted } from '@src/helpers/users';

interface Props {
  joined: Date;
  notes: AdminNotesObject;
  scrubbed?: UserScrubbed;
  referredBy?: BasicUserObject;
  onReferredByChange: (user: UserData) => void;
  onQueryUsers: (query: string) => Promise<SelectOption[]>;
}

type UserData = SelectOption & AdminBasicUserObject;

const useStyles = createUseStyles({
  label: {
    fontSize: 14,
    lineHeight: '2.6em',
    color: '#62646A',
    display: 'block',
  },
});

const LineItem = (props: {
  field: string;
  value: number | string;
}): ReactElement => {
  return (
    <div style={{ margin: '8px 0' }}>
      <span style={{ fontWeight: 500 }}>{`${props.field}:`}</span>
      <span>{` ${props.value}`}</span>
    </div>
  );
};

const customLabel = (opt: UserData): ReactElement => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <UserAvatar
        src={opt.profilePictureURL}
        label={opt.fullName}
        badges={opt.badges}
        size={24}
        badgesInline
        badgesPosition={BadgesPosition.BeforeName}
      />
      <UserSup
        type={opt.type}
        scrubbed={opt.scrubbed}
        isVetted={isUserFullyVetted(opt)}
      />
    </div>
  );
};

export const UserDetailsSection = (props: Props): ReactElement => {
  const styles = useStyles();
  const {
    joined,
    notes: { builderHourlyRate, latestTimesheetsSummaries, totalMinutesBilled },
    referredBy,
    onQueryUsers,
    onReferredByChange,
  } = props;

  const lastPaymentCycleMinutes = latestTimesheetsSummaries[0]?.totalMinutes;

  const onChange = async (opt: UserData | null): Promise<void> => {
    if (!opt) return;
    await onReferredByChange(opt);
  };

  return (
    <>
      <div className={styles.label}> User Details</div>
      <div>
        <LineItem field="Joined" value={format(joined, 'dd MMMM yyyy')} />
        <LineItem
          field="Total Hours Billed"
          value={stringifyMinutes(totalMinutesBilled)}
        />
        <LineItem
          field="Last Billing Cycle Hours"
          value={stringifyMinutes(lastPaymentCycleMinutes)}
        />
        {props.scrubbed && (
          <LineItem field="Scrubbed" value={props.scrubbed || 'Not scrubbed'} />
        )}
        {builderHourlyRate && (
          <LineItem
            field="Last Charged Builder Rate"
            value={`$${builderHourlyRate}`}
          />
        )}
        <div className={styles.label}> Referred by</div>
        <AsyncSelect
          defaultValue={
            referredBy
              ? {
                  label: referredBy?.fullName,
                  value: referredBy?.uid,
                  ...referredBy,
                }
              : undefined
          }
          loadOptions={onQueryUsers}
          formatOptionLabel={customLabel}
          onChange={(opt) => onChange(opt as unknown as UserData)}
          margin={'none'}
        />
      </div>
    </>
  );
};
