import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { Select, SelectProps } from '@ateams/components';

export type OnPageSizeChange = (pageSize: number) => unknown;

export interface PageSizeSelectProps {
  value: number;
  onChange: OnPageSizeChange;
  pageSizeOptions: number[];
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    width: '86px',
  },
  select: {
    margin: 0,
  },
});

export const PageSizeSelect: React.FC<PageSizeSelectProps> = ({
  value,
  onChange,
  pageSizeOptions,
}) => {
  const styles = useStyles();

  const selectedOption = useMemo(
    () => ({
      value: value.toString(),
      label: value.toString(),
    }),
    [value],
  );

  const onSelectChange: SelectProps['onChange'] = (option) => {
    if (option) {
      onChange(parseInt(option.value));
    }
  };

  const options = useMemo(() => {
    return pageSizeOptions.map((size) => ({
      value: size.toString(),
      label: size.toString(),
    }));
  }, [pageSizeOptions]);

  return (
    <div className={styles.container}>
      <div className={styles.label}>Page Size:</div>

      <Select
        menuPortalTarget={document.body}
        value={selectedOption}
        onChange={onSelectChange}
        hideSelectedOptions={false}
        options={options}
        menuPlacement="top"
        width="fixed"
        className={styles.select}
      />
    </div>
  );
};
