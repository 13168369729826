import { Breakpoints, Icon, IconType } from '@ateams/components';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { RoleCategoryTag } from '.';

interface RoleCategoryTagsProps {
  roleCategoryTags: Map<string, RoleCategoryTag>;
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 8,
    gap: 8,
  },
  tag: {
    background: '#F7F7F7',
    borderRadius: 8,
    padding: 8,
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    tag: {
      padding: 16,
    },
  },
});

function RoleCategoryTags({ roleCategoryTags }: RoleCategoryTagsProps) {
  const styles = useStyles();

  const roleCategoryTagMap = Array.from(roleCategoryTags);

  const roleCategoryTagsSorted = useMemo(() => {
    return [...roleCategoryTagMap].sort((a, b) => {
      // Use the localeCompare method to compare the 'title' properties
      // It returns a negative, positive, or zero value based on the comparison
      return a[1].title.localeCompare(b[1].title);
    });
  }, [roleCategoryTagMap]);

  return (
    <div className={styles.root}>
      {roleCategoryTagsSorted.map(
        ([cid, roleCategoryTag]: [string, RoleCategoryTag]) => (
          <div key={cid} className={styles.tag}>
            <Icon type={IconType.SuitcaseGray} size={'exact'} />
            {roleCategoryTag.quantity > 1
              ? `${roleCategoryTag.quantity}x `
              : ''}
            {roleCategoryTag.title}
          </div>
        ),
      )}
    </div>
  );
}

export default RoleCategoryTags;
