import React, { useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  VettingProcessFeedbackAdditionalMaterial,
  VettingProcessFeedbackAdditionalMaterialId,
} from '@a_team/models/dist/vetting-processes/feedback-additional-material';
import { theme } from '@a_team/ui-components';
import { Spacing } from '@ateams/components';
import { Text } from '../../components/typography';
import { InputContainer } from '../../components/input-container';
import { Button } from '../../components/button';
import { UploadIcon } from '../../components/icons/upload';
import { AdditionalMaterial } from '../../components/additional-material';

type BaseAdditionMaterialFilesProps = {
  additionalMaterials: VettingProcessFeedbackAdditionalMaterial[];
  className?: string;
};

type ReadOnlyAdditionMaterialFilesProps = {
  isReadOnly: true;
};

type EditableAdditionMaterialFilesProps = {
  isReadOnly?: false;
  onUploadAdditionalMaterial: OnUploadAdditionalMaterial;
  onDeleteAdditionalMaterial: OnDeleteAdditionalMaterial;
};

type AdditionMaterialFilesProps = BaseAdditionMaterialFilesProps &
  (ReadOnlyAdditionMaterialFilesProps | EditableAdditionMaterialFilesProps);

export type OnUploadAdditionalMaterial = (
  file: File,
) => Promise<VettingProcessFeedbackAdditionalMaterial>;
export type OnDeleteAdditionalMaterial = (
  additionalMaterialId: VettingProcessFeedbackAdditionalMaterialId,
) => Promise<unknown>;

const useStyles = createUseStyles({
  inputLabel: {
    marginBottom: Spacing.small,
  },
  filesContainer: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: Spacing.small,
  },
  uploadFilesButton: {
    height: '32px',
    fontSize: '12px',
    fontWeight: 300,
    borderColor: theme.colors.Hannibal[600],
    color: theme.colors.Hannibal[600],
  },
});

export const AdditionalMaterialsFiles: React.FC<AdditionMaterialFilesProps> = (
  props,
) => {
  const styles = useStyles();
  const [uploadingCount, setUploadingCount] = useState(0);
  const [filesBeingDeleted, setFilesBeingDeleted] = useState(
    new Set<VettingProcessFeedbackAdditionalMaterialId>(),
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  const inputLabelProps = useMemo(
    () => ({
      className: styles.inputLabel,
    }),
    [],
  );

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    if (file) {
      setUploadingCount((count) => count + 1);
      try {
        if ('onUploadAdditionalMaterial' in props) {
          await props.onUploadAdditionalMaterial?.(file);
        }
      } catch (_) {
        // pass
      } finally {
        setUploadingCount((count) => count - 1);
      }
    }
  };

  const onRemoveClick = async (
    additionalMaterialId: VettingProcessFeedbackAdditionalMaterialId,
  ) => {
    setFilesBeingDeleted((filesBeingDeleted) =>
      new Set(filesBeingDeleted).add(additionalMaterialId),
    );

    try {
      if ('onDeleteAdditionalMaterial' in props) {
        await props.onDeleteAdditionalMaterial(additionalMaterialId);
      }
    } finally {
      setFilesBeingDeleted((filesBeingDeleted) => {
        const newFilesBeingDeleted = new Set(filesBeingDeleted);
        newFilesBeingDeleted.delete(additionalMaterialId);
        return newFilesBeingDeleted;
      });
    }
  };

  const onOpenFileUploadModal = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <InputContainer
      label={'Additional materials'}
      inputLabelProps={inputLabelProps}
      className={props.className}
    >
      {props.isReadOnly && !props.additionalMaterials.length ? (
        <Text isReadOnly readOnlyText={'No attachments have been added.'} />
      ) : (
        <div className={styles.filesContainer}>
          {!props.isReadOnly && (
            <Button
              startAdornment={<UploadIcon />}
              noColor
              className={styles.uploadFilesButton}
              onClick={onOpenFileUploadModal}
            >
              <input
                type="file"
                ref={fileInputRef}
                onChange={onFileChange}
                hidden
              />
              Upload files
            </Button>
          )}

          {props.additionalMaterials.map((additionalMaterial) => (
            <AdditionalMaterial
              key={additionalMaterial.id}
              additionalMaterial={additionalMaterial}
              isDeleting={filesBeingDeleted.has(additionalMaterial.id)}
              onRemoveClick={
                filesBeingDeleted.has(additionalMaterial.id)
                  ? undefined
                  : () => onRemoveClick(additionalMaterial.id)
              }
              isReadOnly={props.isReadOnly}
            />
          ))}

          {new Array(uploadingCount).fill(null).map((_, index) => (
            <AdditionalMaterial key={index} isUploading />
          ))}
        </div>
      )}
    </InputContainer>
  );
};
