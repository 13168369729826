import React, { ReactElement } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { inputStyles } from '@src/components/Inputs/InlineInput/styles';
import { Colors, FontSizes, TextColors } from '@ateams/components';
import { Spacing } from '@ateams/components';
import { FontWeights } from '@ateams/components';

export interface DateInputProps extends ReactDatePickerProps {
  addClassName?: string;
}

const useStyles = createUseStyles({
  '@global': {
    '.react-datepicker__close-icon::after': {
      backgroundColor: Colors.backgroundWhite,
      color: TextColors.regular,
      fontSize: FontSizes.extraLarge,
      fontWeight: FontWeights.medium,
      paddingRight: Spacing.medium,
    },
  },
  dateInput: {
    width: 88,
    background: 'none',
    border: 'none',
    fontWeight: 600,
    ...inputStyles,
    padding: '0 8px',
    marginBottom: 0,
    paddingBottom: 0,
    fontSize: 20,
    lineHeight: '1.4em',
  },
});

export const DateInput = (props: DateInputProps): ReactElement => {
  const styles = useStyles();
  const { addClassName, ...rest } = props;

  return (
    <DatePicker
      dateFormat={'LLL d'}
      popperPlacement="top-end"
      {...rest}
      className={cx(props.className || styles.dateInput, addClassName)}
    />
  );
};
