import {
  BorderRadius,
  Colors,
  FontWeights,
  Icon,
  IconType,
  Paddings,
  Spacing,
} from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { round } from 'lodash';

interface RateRangeProps {
  builderRateMin: number;
  builderRateMax: number;
  builderMonthlyRateMin: number;
  builderMonthlyRateMax: number;
}

const useStyles = createUseStyles({
  root: {
    marginBottom: Spacing.large,
    display: 'flex',
    flextDirection: 'row',
    gap: Spacing.medium,
  },
  preferredRateWrapper: {
    background: Colors.backgroundLight,
    borderRadius: BorderRadius.medium,
    padding: Paddings.medium,
    fontWeight: FontWeights.semiBold,
    display: 'flex',
    alignItems: 'center',
  },
  cashIcon: {
    marginRight: '10px',
  },
});

function RateRangeV2({
  builderRateMin,
  builderRateMax,
  builderMonthlyRateMin,
  builderMonthlyRateMax,
}: RateRangeProps): JSX.Element {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.preferredRateWrapper}>
        <Icon type={IconType.Cash} size={'exact'} className={styles.cashIcon} />
        Hourly budget of ${Math.round(builderRateMin)} — $
        {Math.round(builderRateMax)}
      </div>
      <div className={styles.preferredRateWrapper}>
        <Icon type={IconType.Cash} size={'exact'} className={styles.cashIcon} />
        Monthly budget of ${round(builderMonthlyRateMin).toLocaleString()} — $
        {round(builderMonthlyRateMax).toLocaleString()}
      </div>
    </div>
  );
}

export default RateRangeV2;
