import React, { ReactElement, useCallback, useState } from 'react';
import { format } from 'date-fns';
import { numberWithCommas } from '@src/helpers/numbers';
import SectionHeading from '@src/components/SectionHeading';
import Modal, { ModalBasicProps } from '..';
import { Button as CallToActionButton } from '@ateams/components';
import { DateISOString } from '@a_team/models/dist/misc';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { createUseStyles } from 'react-jss';
import { PaymentCycleReopenRequest } from '@ateams/api/dist/endpoints/Missions';

interface Props extends ModalBasicProps {
  createdAt: DateISOString;
  currency: string;
  totalAmount: number;
  onConfirm(data: PaymentCycleReopenRequest): void;
}

const useStyles = createUseStyles({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '32px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  button: {
    marginRight: '24px',
  },
  inputLabel: {
    margin: '24px 0 12px 0',
  },
  input: {
    margin: '12px 0',
  },
  noteLabel: {
    fontWeight: 800,
    margin: '36px 0',
    color: 'red',
  },
});

export function InvoiceCancelModal(props: Props): ReactElement {
  const { createdAt, currency, totalAmount, onClose, onConfirm, ...left } =
    props;
  const styles = useStyles();

  const [memo, setMemo] = useState<string>('');

  const isValidInput = true; //!!memo.trim();

  const handleConfirm = useCallback(
    () =>
      onConfirm({
        memo: memo || undefined,
      }),
    [memo, onConfirm],
  );

  return (
    <Modal onClose={onClose} hideCloseButton {...left}>
      <div style={{ color: 'black' }}>
        <SectionHeading isFirst>Cancel Invoice</SectionHeading>
        <div>
          <p className={styles.inputLabel}>Notes</p>
          <OutlinedInput
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            placeholder={'memo'}
            className={styles.input}
          />
        </div>

        <p
          className={styles.noteLabel}
        >{`Are you sure you want to cancel the invoice from ${format(
          new Date(createdAt),
          'LLL d',
        )} with an amount of ${currency}${numberWithCommas(totalAmount)}?`}</p>

        <div className={styles.buttonsContainer}>
          <CallToActionButton
            color="regular"
            onClick={onClose}
            className={styles.button}
          >
            Cancel
          </CallToActionButton>
          <CallToActionButton
            onClick={handleConfirm}
            className={styles.button}
            disabled={!isValidInput}
          >
            Confirm
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
}
