import React, { ReactElement } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { Spacing } from '../theme/styles';
import Colors, { BorderColors } from '../theme/colors';

export interface RadioGroupProps {
  options: Array<{ label: string; value: string }>;
  value: string;
  onChange: (value: string) => void;
}

const useStyles = createUseStyles({
  root: (props: RadioGroupProps) => ({
    display: 'inline-flex',
    border: `1px solid ${BorderColors.light}`,
    borderRadius: '0.5em', // Not using border radius variables to adapt to current usage context
    background: Colors.backgroundWhite,
    '& > label': {
      flex: 1,
      borderRight: `1px solid ${BorderColors.light}`,
      padding: 17, // fixed to adapt to the other input's size
      display: 'flex',
      alignItems: 'center',
      '& > input': {
        margin: `0 ${Spacing.small}px`,
      },
      '&:last-child': {
        borderRight: 'none',
      },
    },
  }),
});

const RadioGroup = (props: RadioGroupProps): ReactElement => {
  const { options, value, onChange } = props;
  const styles = useStyles(props);

  return (
    <div className={cx(styles.root)}>
      {options.map((option) => (
        <label>
          <input
            value={option.value}
            checked={value === option.value}
            onChange={() => onChange(option.value)}
            type={'radio'}
          />
          <span>{option.label}</span>
        </label>
      ))}
    </div>
  );
};

export default RadioGroup;
