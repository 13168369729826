import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { PrivacyPolicyLocation, TermsOfServiceLocation } from '@src/locations';
import { Link } from 'react-router-dom';
import { getCurrentYear } from '@src/helpers/time';

const useStyles = createUseStyles({
  root: {
    textAlign: 'center',
    marginTop: '80px',
    fontSize: '14px',
    color: '#62646A',
  },
});

export default function Footer(): ReactElement {
  const styles = useStyles();

  return (
    <div style={{}} className={styles.root}>
      © {getCurrentYear()}, ATeams, Inc.{' '}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link to={TermsOfServiceLocation}>Terms</Link>
      &nbsp;and&nbsp;
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link to={PrivacyPolicyLocation}>Privacy Policy</Link>.
    </div>
  );
}
