import { Spacing } from '@ateams/components';
import { createUseStyles } from 'react-jss';

export const useCommonStyles = createUseStyles({
  title: {
    fontSize: 14,
    fontWeight: 500,
    color: '#202020',
    lineHeight: '20px',
    marginBottom: Spacing.xsmall,
  },
  description: {
    fontSize: 12,
    color: '#222',
    lineHeight: '16px',
  },
  errorText: {
    color: '#F63131',
    fontSize: 12,
    lineHeight: '16px',
    marginTop: 8,
  },
});
