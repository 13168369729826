import React, { ReactElement, useEffect } from 'react';
// import { createUseStyles } from 'react-jss';
import { MissionLink } from '@a_team/models/dist/MissionObject';
import TextButton from '@src/components/TextButton';
import LinkInput from '@src/views/Mission/EditMission/LinksListInput/LinkInput';
import useToggle from '@src/hooks/useToggle';

interface Props {
  links: MissionLink[];
  onChange(links: MissionLink[]): void;
}

// const useStyles = createUseStyles({
//   root: {}
// });

export default function LinksListInput(props: Props): ReactElement {
  const { links, onChange } = props;

  // const styles = useStyles();
  const [draft, toggleDraft] = useToggle(!links.length);

  useEffect((): void => {
    toggleDraft(!links.length);
  }, [links]);

  const handleChange = (index: number, item: MissionLink): void => {
    const newItems = [...links];
    newItems[index] = item;

    onChange(newItems);
  };

  const handleRemove = (index: number): void => {
    const newItems = [...links];
    newItems.splice(index, 1);

    onChange(newItems);
  };

  const handleAdd = (item: MissionLink): void => {
    onChange([...links, item]);
    toggleDraft(false);
  };

  // TODO Spec this edit flow and handle this logic for <LinksListInput> and <TeamWorkRolesInput> on
  //   dedicated component for list edit

  return (
    <div>
      {links
        .map((link, i) => (
          <LinkInput
            key={`link-${i}`}
            link={link}
            onChange={handleChange.bind(null, i)}
            onRemove={handleRemove.bind(null, i)}
          />
        )) // using concat to fix draft smooth rendering
        .concat(
          draft
            ? [
                <LinkInput
                  key={`link-${links.length}`}
                  onChange={handleAdd}
                  onRemove={links.length ? toggleDraft : undefined}
                />,
              ]
            : [],
        )}
      <TextButton color="success" disabled={draft} onClick={toggleDraft}>
        + Add New Attribution
      </TextButton>
    </div>
  );
}
