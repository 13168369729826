import React, { ReactElement, useEffect, useState } from 'react';
import UserAvatar from '@src/components/UserAvatar';
import { Icon, IconType } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import {
  CommunityBadgeObject,
  UserBadge,
} from '@a_team/models/dist/UserObject';
import ProfileImageUploader from '@src/views/ProfileImageUploader';
import Modal from 'react-modal';
import { useScreenClass } from 'react-grid-system';
import { useStores } from '@src/stores';
import { useAnalytics } from '@ateams/analytics/dist/platform';

interface Props {
  url?: string;
  src?: string;
  readonly?: boolean;
  disabled?: boolean;
  badges: UserBadge[];
  communityBadges?: CommunityBadgeObject[];
  defaultOpen?: boolean;
  onChange?: (url: string) => Promise<void>;
}

const defaultProps = {
  readonly: false,
  defaultOpen: false,
};

const useStyles = createUseStyles({
  editButton: {
    cursor: 'pointer',
    position: 'absolute',
    background: '#fff',
    padding: 10,
    borderRadius: '50%',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.08)',
    bottom: 0,
    right: 0,
    transition: 'all 0.1s ease',
    '&:hover': {
      background: '#FFFAF7',
    },
  },
});

export const ProfileImage = (props: Props): ReactElement => {
  const {
    url,
    src,
    readonly,
    disabled,
    onChange,
    badges,
    defaultOpen,
    communityBadges,
  } = props;
  const styles = useStyles();
  const analytics = useAnalytics();
  const screenClass = useScreenClass();
  const { users, missions } = useStores();
  const { profile } = users;

  const [imageModalOpen, setImageModalOpen] = useState(defaultOpen || false);
  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  const modalCustomStyles = {
    content: {
      top: 20,
      left: isMobile ? 20 : 140,
      right: 20,
      bottom: 20,
      transform: 'none',
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.6)',
    },
  };

  useEffect(() => {
    // prevent the body to scroll
    if (imageModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [imageModalOpen]);

  const trackProfileImageClick = () => {
    profile &&
      analytics.trackProfileImageEditClicked(
        profile.eventsSource,
        profile.application ? missions.currentMission?.data : undefined,
        profile.application?.currentRole,
        profile.application?.aid,
      );
  };

  const onImageEdit = () => {
    trackProfileImageClick();
    setImageModalOpen(true);
  };

  return (
    <>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <a href={url}>
          <UserAvatar
            src={src}
            badges={!readonly || !onChange ? [] : badges}
            communityBadges={!readonly || !onChange ? [] : communityBadges}
            size={160}
            tooltipTheme={'dark'}
            showBadgesUnderneath
          />
        </a>
        {(!readonly || !onChange) && (
          <div
            className={styles.editButton}
            onClick={disabled ? undefined : onImageEdit}
          >
            <Icon type={IconType.Pencil} clickable size={'small'} />
          </div>
        )}
      </div>

      <Modal
        isOpen={imageModalOpen}
        style={modalCustomStyles}
        onRequestClose={() => setImageModalOpen(false)}
        overlayClassName={undefined}
        shouldCloseOnOverlayClick={true}
      >
        <ProfileImageUploader
          withoutMainLayout
          onClose={() => setImageModalOpen(false)}
        />
      </Modal>
    </>
  );
};

ProfileImage.defaultProps = defaultProps;
