import React, { forwardRef, useMemo } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import { DateISOString } from '@a_team/models/dist/misc';
import {
  VettingProcessAudit,
  VettingProcessAuditTypes,
  VettingProcessAuditUser,
} from '@a_team/models/dist/vetting-process-audit';
import { Colors, Spacing, Tag } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import { Text } from '@src/views/VettingFeedbackForm/components/typography';
import { CalendarIcon } from '../icons/calendar';
import { ProcessStartedActivityRecord } from './process-started';
import { PreVettingFormSubmissionActivityRecord } from './pre-vetting-form-submission';
import { NotifyInterviewerOnProcessActivityRecord } from './notify-interviewer-on-process';
import { ProcessAcceptedByInterviewerActivityRecord } from './process-accepted-by-interviewer';
import { ProcessDeclinedByInterviewerActivityRecord } from './process-declined-by-interviewer';
import { InterviewDateSetActivityRecord } from './interview-date-set';
import { FeedbackFormSubmissionActivityRecord } from './feedback-form-submission';
import { FeedbackFormEditedActivityRecord } from './feedback-form-edited';
import { BuilderOptedOutOfVettingActivityRecord } from './builder-opted-out-of-vetting';
import { ProcessRemovedActivityRecord } from './process-removed';
import { EvaluationInviteEmailActivityRecord } from './evaluation-invite';
import { BuilderNoShowActivityRecord } from './builder-no-show';
import { VetterNoShowActivityRecord } from './vetter-no-show';
import { BuilderCanceledActivityRecord } from './builder-canceled';
import { VetterCanceledActivityRecord } from './vetter-canceled';

export interface ActivityRecordProps {
  vettingProcessAudit: VettingProcessAudit;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: `2px solid ${Colors.backgroundDark}`,
    paddingLeft: '24px',
  },
  activityDateRow: {
    marginBottom: '8px',
  },
});

export const ActivityRecord: React.FC<ActivityRecordProps> = ({
  vettingProcessAudit,
  className,
}) => {
  const styles = useStyles();

  const activityRecord = useMemo(() => {
    switch (vettingProcessAudit.auditType) {
      case VettingProcessAuditTypes.ProcessStarted:
        return (
          <ProcessStartedActivityRecord
            vettingProcessAudit={
              vettingProcessAudit as VettingProcessAudit<VettingProcessAuditTypes.ProcessStarted>
            }
          />
        );

      case VettingProcessAuditTypes.PreVettingFormSubmission:
        return (
          <PreVettingFormSubmissionActivityRecord
            vettingProcessAudit={
              vettingProcessAudit as VettingProcessAudit<VettingProcessAuditTypes.PreVettingFormSubmission>
            }
          />
        );

      case VettingProcessAuditTypes.NotifyInterviewerOnProcess:
        return (
          <NotifyInterviewerOnProcessActivityRecord
            vettingProcessAudit={
              vettingProcessAudit as VettingProcessAudit<VettingProcessAuditTypes.NotifyInterviewerOnProcess>
            }
          />
        );

      case VettingProcessAuditTypes.ProcessAcceptedByInterviewer:
        return (
          <ProcessAcceptedByInterviewerActivityRecord
            vettingProcessAudit={
              vettingProcessAudit as VettingProcessAudit<VettingProcessAuditTypes.ProcessAcceptedByInterviewer>
            }
          />
        );

      case VettingProcessAuditTypes.ProcessDeclinedByInterviewer:
        return (
          <ProcessDeclinedByInterviewerActivityRecord
            vettingProcessAudit={
              vettingProcessAudit as VettingProcessAudit<VettingProcessAuditTypes.ProcessDeclinedByInterviewer>
            }
          />
        );

      case VettingProcessAuditTypes.InterviewDateSet:
        return (
          <InterviewDateSetActivityRecord
            vettingProcessAudit={
              vettingProcessAudit as VettingProcessAudit<VettingProcessAuditTypes.InterviewDateSet>
            }
          />
        );

      case VettingProcessAuditTypes.FeedbackFormSubmission:
        return (
          <FeedbackFormSubmissionActivityRecord
            vettingProcessAudit={
              vettingProcessAudit as VettingProcessAudit<VettingProcessAuditTypes.FeedbackFormSubmission>
            }
          />
        );

      case VettingProcessAuditTypes.FeedbackFormEdited:
        return (
          <FeedbackFormEditedActivityRecord
            vettingProcessAudit={
              vettingProcessAudit as VettingProcessAudit<VettingProcessAuditTypes.FeedbackFormEdited>
            }
          />
        );

      case VettingProcessAuditTypes.BuilderOptedOutOfVetting:
        return (
          <BuilderOptedOutOfVettingActivityRecord
            vettingProcessAudit={
              vettingProcessAudit as VettingProcessAudit<VettingProcessAuditTypes.BuilderOptedOutOfVetting>
            }
          />
        );

      case VettingProcessAuditTypes.ProcessRemoved:
        return (
          <ProcessRemovedActivityRecord
            vettingProcessAudit={
              vettingProcessAudit as VettingProcessAudit<VettingProcessAuditTypes.ProcessRemoved>
            }
          />
        );

      case VettingProcessAuditTypes.EvaluationInviteEmail:
        return (
          <EvaluationInviteEmailActivityRecord
            vettingProcessAudit={
              vettingProcessAudit as VettingProcessAudit<VettingProcessAuditTypes.EvaluationInviteEmail>
            }
          />
        );

      case VettingProcessAuditTypes.BuilderNoShow:
        return (
          <BuilderNoShowActivityRecord
            vettingProcessAudit={
              vettingProcessAudit as VettingProcessAudit<VettingProcessAuditTypes.BuilderNoShow>
            }
          />
        );

      case VettingProcessAuditTypes.VetterNoShow:
        return (
          <VetterNoShowActivityRecord
            vettingProcessAudit={
              vettingProcessAudit as VettingProcessAudit<VettingProcessAuditTypes.VetterNoShow>
            }
          />
        );

      case VettingProcessAuditTypes.BuilderCanceled:
        return (
          <BuilderCanceledActivityRecord
            vettingProcessAudit={
              vettingProcessAudit as VettingProcessAudit<VettingProcessAuditTypes.BuilderCanceled>
            }
          />
        );

      case VettingProcessAuditTypes.VetterCanceled:
        return (
          <VetterCanceledActivityRecord
            vettingProcessAudit={
              vettingProcessAudit as VettingProcessAudit<VettingProcessAuditTypes.VetterCanceled>
            }
          />
        );

      default:
        console.error(`Received unknown auditType 🙀`, vettingProcessAudit);
        return null;
    }
  }, [vettingProcessAudit]);

  return (
    <div className={cx(styles.container, className)}>
      {vettingProcessAudit.date && (
        <ActivityDateRow
          date={vettingProcessAudit.date}
          className={styles.activityDateRow}
        />
      )}
      {activityRecord}
    </div>
  );
};

interface ActivityDateRowProps {
  date: DateISOString;
  className?: string;
}

const useActivityDateRowStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  calendarIcon: {
    marginRight: '4px',
    alignSelf: 'center',
  },
  text: {
    color: '#62646A',
  },
});

const ActivityDateRow: React.FC<ActivityDateRowProps> = (props) => {
  const styles = useActivityDateRowStyles();

  return (
    <div className={cx(styles.container, props.className)}>
      <CalendarIcon className={styles.calendarIcon} />
      <Text className={styles.text}>
        {format(new Date(props.date), "MMMM d, yyyy 'at' h:mm a")}
      </Text>
    </div>
  );
};

export interface ActivityRecordUserProps {
  user: VettingProcessAuditUser;
  automationReason?: VettingProcessAudit['automationReason'];
}

const useActivityRecordUserStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    marginLeft: '16px',
    marginRight: '8px',
  },
  userFullName: {
    fontSize: '15px',
  },
  automatedTag: {
    marginLeft: Spacing.xsmall,
  },
});

export const ActivityRecordUser: React.FC<ActivityRecordUserProps> = ({
  user,
  automationReason,
}) => {
  const styles = useActivityRecordUserStyles();

  return (
    <div className={styles.container}>
      <UserAvatar
        src={user.profilePictureURL}
        containerClassName={styles.avatar}
        size={24}
      />
      <Text className={styles.userFullName}>
        {user.fullName}{' '}
        {automationReason ? (
          <Tag className={styles.automatedTag} thin color="secondary">
            Automated ({automationReason})
          </Tag>
        ) : (
          ''
        )}
      </Text>
    </div>
  );
};

const useActivityRecordMainTextStyles = createUseStyles({
  text: {
    fontSize: '15px',
    fontWeight: 500,
  },
});

export const ActivityRecordMainText: typeof Text = forwardRef((props, ref) => {
  const styles = useActivityRecordMainTextStyles();

  return (
    <Text {...props} ref={ref} className={cx(styles.text, props.className)} />
  );
});
