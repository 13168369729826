import CompanyLogo from '@src/components/CompanyLogo';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface NoLongerActiveProps {
  companyLogo?: string;
}

const useStyles = createUseStyles({
  container: {
    margin: '24px auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#202020',
    padding: '16px',
    borderRadius: '16px',
    color: '#fff',
    maxWidth: '620px',
  },
});

export const NoLongerActive = ({ companyLogo }: NoLongerActiveProps) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <CompanyLogo logoUrl={companyLogo} size={40} />
      <span>
        This role is no longer available. Join A.Team to get invited to more
        missions.
      </span>
    </div>
  );
};
