import { ServiceAuth, ServiceEndpoint } from './utils';
import AdminNotesObject, {
  AdminNoteCategory,
  AdminNoteId,
  AdminNotesScore,
} from '@a_team/models/dist/AdminNotesObject';
import {
  AvailabilityData,
  AvailabilitySummaryObject,
} from '@a_team/models/dist/AvailabilityObject';
import UserObject, {
  AdminBasicUserObject,
  AdminUnvettedApplicant,
  BasicUserObject,
  CurrentUserObject,
  UserCardObject,
  UserContentData,
  UserId,
  UserScrubbed,
  UserStatus,
  UserType,
  UserUsername,
} from '@a_team/models/dist/UserObject';

import {
  UserApplicationDataChatGtpExportObject,
  UserDataChatGtpExportObject,
} from '@a_team/models/dist/UserDataChatGtpExport';

import { ApplicantFlags } from '@a_team/models/dist/ApplicantFlags';

import { ConnectionObject } from '@a_team/models/dist/ConnectionObject';
import { QueryNextToken, QueryResult } from '@a_team/models/dist/misc';
import { TalentProfile } from '@a_team/models/dist/TalentCategories';
import { CustomUserTagObject } from '@a_team/models/dist/CustomUserTagObject';
import { BuilderData } from '@a_team/models/dist/LinkedIn';

export const BasePath = '/users';

export interface MessagePayload {
  message: string;
  recaptchaToken: string;
}

export interface UsersStats {
  countByScrubbed: Record<UserScrubbed, number>;
}

export interface UpdateUserScrubbedData {
  scrubbed: UserScrubbed;
  updateUserStatus?: boolean;
  suppressEmail?: boolean;
}

export interface TalentProfileUser extends AdminBasicUserObject {
  talentProfile: TalentProfile;
  email: CurrentUserObject['email'];
  phoneNumber: CurrentUserObject['phoneNumber'];
  linkedIn?: UserContentData['linkedIn'];
  availability: AvailabilitySummaryObject;
  hourlyRate?: number;
}

export interface AdminNotesRequestPayload
  extends Partial<
    Omit<AdminNotesObject, 'pitch' | 'notes' | 'vettingProcesses' | 'scores'>
  > {
  scores?: {
    expertise?: AdminNotesScore | null;
    interactionExperience?: AdminNotesScore | null;
    english?: AdminNotesScore | null;
    accent?: AdminNotesScore | null;
  };
  pitch?: string;
  note?: string;
  noteId?: AdminNoteId;
  noteCategory?: AdminNoteCategory;
  preventPrepay?: boolean;
}

export interface GenerateUserBlurbForSampleProposalPayload {
  roleName?: string;
  skills?: string[];
}

export type AdminUserUpdateData = Pick<
  Partial<AdminBasicUserObject>,
  'firstName' | 'lastName' | 'email' | 'username'
> & { linkedinUsername?: string };

// export interface MessageAnonymousPayload extends MessagePayload {
//   fullName: string;
//   email: string;
// }

// exported functions

export default class UsersEndpoint extends ServiceEndpoint {
  public getUserByUsername(
    auth: ServiceAuth,
    username: UserUsername,
  ): Promise<UserObject> {
    return this.fetch(auth, BasePath + `/${username}`);
  }

  public setupAccount(
    auth: ServiceAuth,
    username: UserUsername,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/${username}/calcom-setup-account`,
      null,
      'post',
      {},
    );
  }

  public adminGetUsersByIds(
    auth: ServiceAuth,
    uids: UserId[],
  ): Promise<BasicUserObject[]> {
    return this.fetch(auth, BasePath + `/admin/by-ids`, { uids });
  }

  public getUserById(auth: ServiceAuth, userId: UserId): Promise<UserObject> {
    return this.fetch(auth, `${BasePath}/id/${userId}`);
  }

  public recordProfileVisit(
    auth: ServiceAuth,
    username: UserUsername,
  ): Promise<void> {
    // TODO use `navigator.sendBeacon`

    return this.fetch(
      auth,
      BasePath + `/${username}/profile-visit`,
      null,
      'post',
    );
  }

  public getUserCardByUsername(
    auth: ServiceAuth,
    username: UserUsername,
  ): Promise<UserCardObject> {
    return this.fetch(auth, BasePath + `/${username}/card`);
  }

  public getUserConnections(
    auth: ServiceAuth,
    username: UserUsername,
  ): Promise<ConnectionObject[]> {
    return this.fetch(auth, BasePath + `/${username}/connections`);
  }

  /**
   * @deprecated
   */
  public queryAll(auth: ServiceAuth): Promise<BasicUserObject[]> {
    return this.fetch(auth, BasePath + `/query/all`);
  }

  public queryByString(
    auth: ServiceAuth,
    query: string,
  ): Promise<BasicUserObject[]> {
    return this.fetch(auth, BasePath + `/query/by-string`, { query });
  }

  public messageUser(
    auth: ServiceAuth,
    username: UserUsername,
    payload: MessagePayload,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/${username}/message`,
      null,
      'post',
      payload,
    );
  }

  public getUserAbFlags(
    auth: ServiceAuth,
    username: UserUsername,
  ): Promise<CurrentUserObject['abFlags']> {
    return this.fetch(auth, BasePath + `/${username}/ab-flags`);
  }

  public assignUserAbFlags(
    auth: ServiceAuth,
    username: UserUsername,
    payload: Record<string, boolean>,
  ): Promise<CurrentUserObject['abFlags']> {
    return this.fetch(
      auth,
      BasePath + `/${username}/ab-flags`,
      null,
      'post',
      payload,
    );
  }

  public verifyUserProfile(
    auth: ServiceAuth,
    username: UserUsername,
  ): Promise<UserObject> {
    return this.fetch(auth, BasePath + `/${username}/verify`, null, 'post');
  }

  public updateUserScrubbed(
    auth: ServiceAuth,
    username: UserUsername,
    data: UpdateUserScrubbedData,
  ): Promise<UserObject> {
    return this.fetch(
      auth,
      BasePath + `/${username}/scrubbed`,
      null,
      'put',
      data,
    );
  }

  public adminMarkAsRejected(
    auth: ServiceAuth,
    username: UserUsername,
  ): Promise<UserObject> {
    return this.fetch(
      auth,
      BasePath + `/${username}/mark-as-rejected`,
      null,
      'put',
    );
  }

  public adminUnmarkAsRejected(
    auth: ServiceAuth,
    username: UserUsername,
  ): Promise<UserObject> {
    return this.fetch(
      auth,
      BasePath + `/${username}/unmark-as-rejected`,
      null,
      'put',
    );
  }

  public adminCancelAutomatedRejection(
    auth: ServiceAuth,
    username: UserUsername,
  ): Promise<UserObject> {
    return this.fetch(
      auth,
      BasePath + `/${username}/cancel-automated-rejection`,
      null,
      'put',
    );
  }

  public adminUpdateAvailability(
    auth: ServiceAuth,
    username: UserUsername,
    availability: AvailabilityData,
  ): Promise<AvailabilitySummaryObject> {
    return this.fetch(
      auth,
      BasePath + `/${username}/availability`,
      null,
      'put',
      availability,
    );
  }

  public adminUpdate(
    auth: ServiceAuth,
    username: UserUsername,
    data: AdminUserUpdateData,
  ): Promise<CurrentUserObject> {
    return this.fetch(auth, BasePath + `/${username}`, null, 'put', data);
  }

  public getUsersStats(auth: ServiceAuth): Promise<UsersStats> {
    return this.fetch(auth, BasePath + `/all/stats`);
  }

  public adminQueryUnvettedApplicants(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<QueryResult<AdminUnvettedApplicant>> {
    return this.fetch(auth, BasePath + '/admin/unvetted-applicants', { next });
  }

  public adminQueryNewUsers(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<QueryResult<AdminBasicUserObject>> {
    return this.fetch(auth, BasePath + `/admin/new-users`, { next });
  }

  public adminQueryByString(
    auth: ServiceAuth,
    query: string,
    options?: {
      queryOnlyAdminUsers?: boolean;
      userStatus?: UserStatus[];
      userType?: UserType[];
    },
    abortController?: AbortController,
  ): Promise<AdminBasicUserObject[]> {
    return this.fetch(
      auth,
      BasePath + `/admin/query/by-string`,
      {
        query,
        ...options,
      },
      'get',
      undefined,
      abortController,
    );
  }

  public adminGetNotes(
    auth: ServiceAuth,
    username: UserUsername,
  ): Promise<AdminNotesObject> {
    return this.fetch(auth, BasePath + `/${username}/admin-notes`);
  }

  public adminAddNotes(
    auth: ServiceAuth,
    username: UserUsername,
    notes: AdminNotesRequestPayload,
  ): Promise<AdminNotesObject> {
    return this.fetch(
      auth,
      BasePath + `/${username}/admin-notes`,
      null,
      'put',
      notes,
    );
  }

  public adminDeleteNote(
    auth: ServiceAuth,
    username: UserUsername,
    noteId: AdminNoteId,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/${username}/admin-notes/${noteId}`,
      null,
      'delete',
    );
  }

  public deleteUser(auth: ServiceAuth, username: UserUsername): Promise<void> {
    return this.fetch(auth, BasePath + `/${username}`, null, 'delete');
  }

  public updateUserCustomTags(
    auth: ServiceAuth,
    username: UserUsername,
    data: { customTags: string[] },
  ): Promise<CustomUserTagObject[]> {
    return this.fetch(
      auth,
      BasePath + `/${username}/custom-tags`,
      null,
      'put',
      data,
    );
  }

  public getUserDataChatGptExport(
    auth: ServiceAuth,
    username: UserUsername,
  ): Promise<UserDataChatGtpExportObject> {
    return this.fetch(
      auth,
      BasePath + `/${username}/user-data-chatgpt-export`,
      null,
      'get',
    );
  }

  public getUserApplicationDataChatGptExport(
    auth: ServiceAuth,
    username: UserUsername,
    applicationId: string,
  ): Promise<UserApplicationDataChatGtpExportObject> {
    return this.fetch(
      auth,
      BasePath +
        `/${username}/user-application-data-chatgpt-export/${applicationId}`,
      null,
      'get',
    );
  }

  public generateUserBlurbForProposal(
    auth: ServiceAuth,
    username: UserUsername,
    applicationId: string,
  ): Promise<{
    text: string;
    gptUsageLogId: string;
  }> {
    return this.fetch(
      auth,
      BasePath +
        `/${username}/generate-user-blurb-for-proposal/${applicationId}`,
      null,
      'get',
    );
  }

  public generateUserBlurbForSampleProposal(
    auth: ServiceAuth,
    username: UserUsername,
    roleName?: string,
    skills: string[] = [],
  ): Promise<{
    text: string;
    gptUsageLogId: string;
  }> {
    return this.fetch(
      auth,
      BasePath + `/${username}/generate-user-blurb-for-sample-proposal`,
      null,
      'post',
      { roleName, skills },
    );
  }

  public updateUserLinkedInData(
    auth: ServiceAuth,
    username: UserUsername,
    payload: BuilderData,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/${username}/update-linkedin`,
      null,
      'post',
      payload,
    );
  }

  public contactSupport(
    auth: ServiceAuth,
    payload: MessagePayload,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/message-support`,
      null,
      'post',
      payload,
    );
  }

  public getApplicantFlags(
    auth: ServiceAuth,
    uid: UserId,
  ): Promise<ApplicantFlags> {
    return this.fetch(auth, BasePath + `/${uid}/applicant-flags`, null, 'get');
  }

  public evaluateFeatureFlag(
    auth: ServiceAuth,
    uid: UserId,
    featureFlag: string,
  ): Promise<boolean> {
    return this.fetch(
      auth,
      BasePath + `/${uid}/evaluate-feature-flag/${featureFlag}`,
      null,
      'get',
    );
  }
}
