import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Checkbox, Select, SelectOption } from '@ateams/components';
import { RoleFilter } from '.';
import { useQueryUserCitiesByCountryOrProvince } from '@src/rq/citiesSearch';
import { ENVIRONMENT } from '@src/config';

interface Props {
  cities: string[];
  onChange: (filter: Partial<RoleFilter>) => void;
  sidebar?: boolean;
  countries?: string[];
  provinces?: string[];
  requireCity?: boolean;
}

const useStyles = createUseStyles({
  selectionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginBottom: 8,
  },
  selector: {
    fontSize: 14,
  },
  sidebar: {
    maxWidth: 'unset',
    marginRight: 0,
    marginTop: 12,
  },
  checkbox: {
    paddingBottom: 12,
  },
});

const CityList = (props: Props): ReactElement => {
  const styles = useStyles();
  const { cities, onChange, sidebar, requireCity, countries, provinces } =
    props;
  const citySelections: string[] | undefined = cities;

  const removeCity = (options: SelectOption | SelectOption[]): void => {
    const cityToRemove: string[] = !options
      ? []
      : Array.isArray(options)
      ? options.map(({ value }) => value)
      : [options.value];
    const newCitySelections = citySelections
      ? citySelections.filter((city) => !cityToRemove.includes(city))
      : [];

    onChange({ cities: newCitySelections });
  };

  const updateCities = (
    options: SelectOption | SelectOption[] | null,
  ): void => {
    const cities: string[] = !options
      ? []
      : Array.isArray(options)
      ? options.map(({ value }) => value)
      : [options.value];

    onChange({ cities });
  };

  const { isLoading, data: allCities } = useQueryUserCitiesByCountryOrProvince({
    filter: { countryCode: countries, province: provinces },
    applicationName: `targeter-city-select-${ENVIRONMENT}}`,
  });

  return (
    <div className={styles.selectionContainer}>
      <Checkbox
        className={styles.checkbox}
        label="Require cities"
        checked={requireCity ?? true}
        onChange={(e) => onChange({ requireCity: e.target.checked })}
        margin="none"
      />
      <Select
        isLoading={isLoading}
        hideTags={false}
        className={cx(styles.selector, sidebar && styles.sidebar)}
        options={allCities}
        value={citySelections.map((value) => {
          return { value: value, label: value };
        })}
        onChange={updateCities}
        placeholder={`Cities`}
        margin="none"
        onRemoveOption={removeCity}
        isMulti={true as false}
        keepTextOnSelect={true}
      />
    </div>
  );
};
export default CityList;
