import React from 'react';
import { createUseStyles } from 'react-jss';
import { theme } from '@a_team/ui-components';
import { Spacing } from '@ateams/components';
import PercentageIndicator from '@src/components/PercentageIndicator/PercentageIndicator';

interface AnalysisScoreProps {
  heading: string;
  score: number;
}

const useAnalysisScoreStyles = createUseStyles({
  container: {
    width: '132px',
    minHeight: '56px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.Grey[1000],
  },
  percentageIndicator: {
    marginBottom: Spacing.small,
  },
});

export const AnalysisScore: React.FC<AnalysisScoreProps> = (props) => {
  const { heading, score } = props;
  const styles = useAnalysisScoreStyles();

  return (
    <div className={styles.container}>
      <PercentageIndicator
        percent={score}
        className={styles.percentageIndicator}
      />
      <div className={styles.heading}>{heading}</div>
    </div>
  );
};
