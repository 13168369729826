import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  term: {
    color: '#FE630C',
  },
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
  },
});

const OurTerminology = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <h4>📚 Our Terminology</h4>

      <div>
        <p className={styles.term}>
          <strong>A.Team</strong>
        </p>
        <p>A team of aces, a group of the best in a particular area</p>
      </div>

      <div>
        <p className={styles.term}>
          <strong>BuildTeam</strong>
        </p>
        <p>
          The A.Team staff - the matchers, onboarding, community, and all other
          personnel running the show.
        </p>
      </div>

      <div>
        <p className={styles.term}>
          <strong>Builder or A.Teamer</strong>
        </p>
        <p>
          You! (and all the other creators, developers, designers, writers,
          business developers, product managers, and more on the platform).
        </p>
      </div>

      <div>
        <p className={styles.term}>
          <strong>Mission</strong>
        </p>
        <p>
          A product you'd build with us. Sort of like a project, but more
          comprehensive.
        </p>
      </div>

      <div>
        <p className={styles.term}>
          <strong>Mission Control</strong>
        </p>
        <p>The page you reach by clicking on “Teams”.</p>
      </div>
    </div>
  );
};

export default OurTerminology;
