import React from 'react';
import cx from 'classnames';
import { useCommonStyles } from '../commonStyles';
import { Button } from '@a_team/ui-components';
import { ConnectCalendarButton } from '../ConnectCalendarButton';
import { CDN_BASE_URL, UPLOADCARE_ASSETS_BASE_URL } from '@src/config';

export enum CalendarPrivacyConcern {
  Generic = 'generic',
  Client = 'client',
  Availability = 'availability',
}

interface PrivacyProps {
  onOptOut: () => void;
  onClose: () => void;
  concern?: CalendarPrivacyConcern;
}

export const Privacy = (props: PrivacyProps) => {
  const styles = useCommonStyles();
  const concern = props.concern ?? CalendarPrivacyConcern.Generic;

  const renderHero = () => {
    switch (concern) {
      case CalendarPrivacyConcern.Availability:
        return (
          <img
            src={`${UPLOADCARE_ASSETS_BASE_URL}/d70c323f-f986-483b-a265-dc88e12eeb24/-/preview/1000x352/`}
            alt="A.Team availability and privacy"
            className={styles.img}
          />
        );
      case CalendarPrivacyConcern.Client:
      case CalendarPrivacyConcern.Generic:
      default:
        return (
          <img
            src={`${CDN_BASE_URL}/website/calcom-banner-01.jpg`}
            alt="A.Team calendar banner"
            className={styles.img}
          />
        );
    }
  };

  const renderConcernCopy = () => {
    switch (concern) {
      case CalendarPrivacyConcern.Client:
        return <ClientPrivacyCopy />;
      case CalendarPrivacyConcern.Availability:
        return <AvailabilityPrivacyCopy />;
      case CalendarPrivacyConcern.Generic:
      default:
        return <GenericPrivacyCopy />;
    }
  };

  return (
    <>
      <div className={styles.hero}>
        <h4 className={styles.modalTitle}>
          Learn more about interview scheduling on A.Team
        </h4>
        <p className={cx(styles.heroCopy, 'lessPadding')}>
          Schedule interviews seamlessly by connecting your calendar.
        </p>
      </div>
      {renderHero()}
      <div className={styles.teaserBody}>
        {renderConcernCopy()}
        <div
          className={styles.actions}
          style={{ paddingTop: 32, paddingBottom: 32 }}
        >
          <Button
            className={styles.closeBtn}
            variant="secondary"
            size="sm"
            onClick={props.onClose}
          >
            Back
          </Button>
          <ConnectCalendarButton />
        </div>
        <div className={styles.passive}>
          <button className={styles.passiveLinkButton} onClick={props.onOptOut}>
            I still don't want to connect
          </button>
        </div>
      </div>
    </>
  );
};

const GenericPrivacyCopy = () => {
  const styles = useCommonStyles();

  return (
    <>
      {' '}
      <div className={styles.panel}>
        <h5 className={styles.panelTitle}>
          Your events are private and secure
        </h5>
        <p className={styles.panelCopy}>
          Companies can only view your availability for the next 7 days during
          your designated working hours, not your specific events.
        </p>
      </div>
      <div className={styles.panel}>
        <h5 className={styles.panelTitle}>
          A.Team does not store calendar data.
        </h5>
        <p className={styles.panelCopy}>
          Your calendar is only checked for windows of availability when
          scheduling interviews.
        </p>
      </div>
      <div className={styles.panel}>
        <h5 className={styles.panelTitle}>
          A.Team is SOC2 Security Certified.
        </h5>
        <p className={styles.panelCopy}>
          A.Team has been audited by an independent third party to verify
          compliance with SOC2, the highest industry security standard.
        </p>
      </div>
      <div className={styles.panel}>
        <h5 className={styles.panelTitle}>
          A.Team is a Google Approved Vendor.
        </h5>
        <p className={styles.panelCopy}>
          A.Team has been vetted by Google and complies with all security
          practices.
        </p>
      </div>
      <div className={styles.panel}>
        <h5 className={styles.panelTitle}>
          Why does A.Team need view and edit permissions?
        </h5>
        <p className={styles.panelCopy}>
          These are standard permissions in all leading scheduling platforms
          like Calendly® and others — they allow us to create, reschedule and
          cancel events we’ve created for you.
        </p>
        <h5 className={styles.subPanelTitle}>View Calendar Events</h5>
        <p className={styles.panelCopy}>
          A.Team needs permission to view your calendar events to look at your
          current schedule and find available timeslots for booking, and prevent
          double-booking.
        </p>

        <h5 className={styles.subPanelTitle}>Create events</h5>
        <p className={styles.panelCopy}>
          When a company books or requests and interview with you, the platform
          needs permission to create a new event on your calendar to speed up
          the booking process.
        </p>

        <h5 className={styles.subPanelTitle}>Edit events</h5>
        <p className={styles.panelCopy}>
          Giving edit permissions allows the platform to automatically update
          the event on your Calendar if a meeting gets canceled or rescheduled.
        </p>

        <h5 className={styles.subPanelTitle}>Delete events</h5>
        <p className={styles.panelCopy}>
          Similarly, if an event is canceled via A.Team, the platform may need
          to remove the event from your Calendar. This is to ensure your
          calendar remains accurate and up-to-date.
        </p>
      </div>
    </>
  );
};

const ClientPrivacyCopy = () => {
  const styles = useCommonStyles();

  return (
    <>
      {' '}
      <div className={styles.panel}>
        <h5 className={styles.panelTitle}>
          Only your availability during your working hours is shared
        </h5>
        <p className={styles.panelCopy}>
          Companies can only view your availability for the next 7 days during
          your designated working hours, not your specific events.
        </p>
      </div>
      <div className={styles.panel}>
        <h5 className={styles.panelTitle}>
          Your events are private and secure
        </h5>
        <p className={styles.panelCopy}>
          A.Team has been audited by an independent third party to verify
          compliance with SOC2, the highest industry security standard.
        </p>
      </div>
      <div className={styles.panel}>
        <h5 className={styles.panelTitle}>
          A.Team does not store calendar data
        </h5>
        <p className={styles.panelCopy}>
          Your calendar is only checked for windows of availability when
          scheduling interviews.
        </p>
      </div>
    </>
  );
};

const AvailabilityPrivacyCopy = () => {
  const styles = useCommonStyles();

  return (
    <>
      <div className={styles.panel}>
        <h5 className={styles.panelTitle}>Who can book interviews with me?</h5>
        <p className={styles.panelCopy}>
          After applying to a mission, if you're proposed to a client, they can
          schedule an interview on your calendar during your available slots.
          Additionally, once your calendar is connected, approved clients in the
          A.Team network can search for your profile and request interviews with
          you without you applying.
        </p>
      </div>
      <div className={styles.panel}>
        <h5 className={styles.panelTitle}>
          Can I opt out of client discovery and direct booking?
        </h5>
        <p className={styles.panelCopy}>
          Yes — you can control your profile client visibility and disable the
          ability for clients to discover and schedule interviews with you.
          Navigate to your profile settings, then Privacy tab to disable.
        </p>
      </div>
      <div className={styles.panel}>
        <h5 className={styles.panelTitle}>
          Can I choose when I’m available for interviews?
        </h5>
        <p className={styles.panelCopy}>
          Yes — you can select the times and days of the week when you are
          available for interviews. We then check for any conflicts and allow
          bookings during your available windows when there are no conflicts.
        </p>
      </div>
      <div className={styles.panel}>
        <h5 className={styles.panelTitle}>
          Only your availability during your working hours is shared
        </h5>
        <p className={styles.panelCopy}>
          Companies can only view your availability for the next 7 days during
          your designated working hours, not your specific events.
        </p>
      </div>
    </>
  );
};
