import { Breakpoints, Colors } from '@ateams/components';
import UserConnectionsModal from '@src/components/Modal/UserConnectionsModal/UserConnectionsModal';
import { addApostropheToName } from '@src/helpers/strings';
import Profile from '@src/stores/Profile/Profile';
import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import ConnectionSummaryBox from './ConnectionSummaryBox';
import NoConnections from './NoConnections';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';

interface ConnectionSummaryProps {
  profile: Profile;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    margin: '0 24px 24px 24px',
  },
  connectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  viewAllBtn: {
    background: 'none',
    border: 'none',
    fontSize: '14px',
    fontWeight: 500,
    color: Colors.regularDark,
    cursor: 'pointer',
  },
  connectionTitle: {
    fontSize: '14px',
    color: Colors.regularDark,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      margin: '0 0 40px 0',
    },
  },
});

const ConnectionSummary = ({
  profile,
}: ConnectionSummaryProps): JSX.Element | null => {
  const [isUserConnectionsModalOpen, setIsUserConnectionsModalOpen] =
    useState(false);
  const styles = useStyles();
  const analytics = useAnalytics();
  const { auth } = useStores();

  const handleViewAll = () => {
    setIsUserConnectionsModalOpen(true);

    if (!auth.user) {
      return;
    }
    analytics.trackViewAllUserConnectionsClicked(
      profile.data.uid,
      auth.user.uid,
    );
  };

  const connectionTitle = useMemo(() => {
    if (profile.isCurrentUser) {
      return 'My';
    }
    return addApostropheToName(profile.data.firstName);
  }, [profile.data.firstName, profile.isCurrentUser]);

  if (
    profile.isCurrentUser &&
    profile.totalWorkConnections === 0 &&
    profile.totalTeamUpConnections === 0
  ) {
    // the NoConnections component has a link that takes the user to discovery page
    // basic access users do not have access to discovery page
    if (auth.basicAccess) {
      return null;
    }

    return (
      <div className={styles.container}>
        <NoConnections />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.connectionHeader}>
        <span className={styles.connectionTitle}>
          {connectionTitle} Connections
        </span>
        {(profile.totalTeamUpConnections > 0 ||
          profile.totalWorkConnections > 0) && (
          <button onClick={handleViewAll} className={styles.viewAllBtn}>
            View All
          </button>
        )}
      </div>
      <ConnectionSummaryBox onClick={handleViewAll} profile={profile} />
      <UserConnectionsModal
        profile={profile}
        isOpen={isUserConnectionsModalOpen}
        onClose={() => {
          setIsUserConnectionsModalOpen(false);
        }}
      />
    </div>
  );
};

export default observer(ConnectionSummary);
