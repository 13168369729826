import { Breakpoints, Checkbox } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import ControlButtons from './common/ControlButtons';
import { TeamPulseSectionsEnum } from './TeamPulseModal';

interface ScopedWellDefinedProps {
  setSection: (section: string) => void;
  setScopedWellDefined: (value: boolean | undefined) => void;
  missionTitle: string;
  initialState?: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    height: '100%',
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'start',
  },
  desc: {
    textAlign: 'start',
    color: '#707275',
    marginBottom: 40,
    marginTop: 8,
  },
  checkboxContainer: {
    display: 'flex',
    width: '100%',
    gap: 12,
  },
  checkboxWrapper: {
    height: 'fit-content',
    padding: '12px 8px',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    width: '100%',
    border: '1px solid #D1D2D6',
    borderRadius: 8,
  },
  checkbox: {
    margin: 0,
  },
  wrapper: {
    padding: 20,
    width: '100%',
  },
  label: {
    width: '100%',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    wrapper: {
      padding: 0,
    },
  },
});

enum WellDefinedEnum {
  Yes = 'Yes',
  No = 'No',
  NotAnswered = 'Not answered',
}

const ScopedWellDefined = ({
  setSection,
  setScopedWellDefined,
  missionTitle,
  initialState,
}: ScopedWellDefinedProps): JSX.Element => {
  const styles = useStyles();
  const [wellDefined, setWellDefined] = React.useState<WellDefinedEnum>(
    typeof initialState === 'boolean'
      ? initialState
        ? WellDefinedEnum.Yes
        : WellDefinedEnum.No
      : WellDefinedEnum.NotAnswered,
  );
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    // clicking sets checkbox to true, if clicks again sets to undefined

    if (e.target.name === WellDefinedEnum.Yes.toString()) {
      // if it is already checked, uncheck it
      if (wellDefined === WellDefinedEnum.Yes) {
        setWellDefined(WellDefinedEnum.NotAnswered);
      } else {
        setWellDefined(WellDefinedEnum.Yes);
      }
    }
    if (e.target.name === WellDefinedEnum.No.toString()) {
      // if it is already checked, uncheck it
      if (wellDefined === WellDefinedEnum.No) {
        setWellDefined(WellDefinedEnum.NotAnswered);
      } else {
        setWellDefined(WellDefinedEnum.No);
      }
    }
  };

  const onBack = (): void => {
    setSection(TeamPulseSectionsEnum.HowsTheMissionIsGoing);
  };

  const onContinue = (): void => {
    if (wellDefined === WellDefinedEnum.Yes) {
      setScopedWellDefined(true);
    } else if (wellDefined === WellDefinedEnum.No) {
      setScopedWellDefined(false);
    }
    setSection(TeamPulseSectionsEnum.WhatCouldImproveExperience);
  };

  const onSkip = (): void => {
    setScopedWellDefined(undefined);
    setSection(TeamPulseSectionsEnum.WhatCouldImproveExperience);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>
          Is the scope of the mission well-defined?
        </h4>
        <p className={styles.desc}>
          This question is optional and is not shared with the {missionTitle}.
        </p>
        <div className={styles.checkboxContainer}>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              id={WellDefinedEnum.Yes}
              className={styles.checkbox}
              name={WellDefinedEnum.Yes}
              checked={wellDefined === WellDefinedEnum.Yes}
              onChange={handleChange}
            />
            <label htmlFor={WellDefinedEnum.Yes} className={styles.label}>
              Yes
            </label>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              id={WellDefinedEnum.No}
              className={styles.checkbox}
              name={WellDefinedEnum.No}
              checked={wellDefined === WellDefinedEnum.No}
              onChange={handleChange}
            />
            <label htmlFor={WellDefinedEnum.No} className={styles.label}>
              No
            </label>
          </div>
        </div>
      </div>
      <ControlButtons
        canContinue={wellDefined !== WellDefinedEnum.NotAnswered}
        onBack={onBack}
        onContinue={onContinue}
        onSkip={onSkip}
      />
    </div>
  );
};

export default ScopedWellDefined;
