import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@a_team/ui-components';
import ProgressCircle from '@src/components/ProgressCircle';
import { createUseStyles } from 'react-jss';
import { Breakpoints, Icon, IconType } from '@ateams/components';
import cx from 'classnames';
import LoaderIcon from '@src/components/LoadingIndicator/loading.png';

interface Props {
  title: string;
  description: string;
  progress?: number;
  loading?: boolean;
  sticky?: boolean;
}

const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    width: '100%',
    padding: '16px 24px',
    background: 'linear-gradient(270deg, #000 0%, #67005D 100%)',
    borderRadius: 16,
    display: 'flex',
    gap: 24,
    alignItems: 'flex-start',
    transition: 'all 0.5s',
    flexDirection: 'column',
  },
  loading: {
    background: '#000 !important',
    width: 'auto',
    display: 'inline-flex',
    '& img': {
      animation: '$loading 1s linear infinite',
    },
  },
  circle: {
    rotate: '-90deg',
    flexShrink: 0,
  },
  sticky: {
    position: 'sticky',
    bottom: 16,
    zIndex: 999,
  },
  highLevel: {
    background: 'linear-gradient(270deg, #000 0%, #4E019D 100%)',
  },
  '@keyframes loading': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
});

const QualityGuidance = (props: Props): ReactElement => {
  const styles = useStyles();
  const { title, description, progress = 5, sticky = true, loading } = props;
  const isHighLevel = progress > 50;
  return (
    <div className={cx(styles.wrapper, { [styles.sticky]: !!sticky })}>
      <div
        className={cx(styles.root, {
          [styles.highLevel]: isHighLevel,
          [styles.loading]: !!loading,
        })}
      >
        {loading ? (
          <img src={LoaderIcon} alt="loading" />
        ) : (
          <div style={{ position: 'relative', height: 50 }}>
            <Icon
              type={IconType.StarsWhite}
              style={{ position: 'absolute', left: 5, top: 5, fontSize: 40 }}
              size={'large'}
            />
            <ProgressCircle
              progress={progress}
              radius={25}
              strokeSize={3}
              barColor={isHighLevel ? '#5600B0' : '#77006C'}
              fillColor={'#fff'}
              className={styles.circle}
            />
          </div>
        )}
        <div>
          {(title || loading) && (
            <Typography
              variant={'h4'}
              color={'Grey@0'}
              weight={'bold'}
              margin={'none'}
              size={15}
              style={{ marginBottom: 4 }}
            >
              {loading ? 'Loading guidance' : title}
            </Typography>
          )}
          {description && !loading && (
            <Typography
              variant={'textMedium'}
              color={'Grey@400'}
              weight={'regular'}
            >
              {description}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(QualityGuidance);
