import { MissionId } from '@a_team/models/dist/MissionObject';
import MissionRole from '@a_team/models/dist/MissionRole';
import { MinimalRecommendationData } from '@a_team/models/dist/RecommendationObject';
import { TalentCategoryId } from '@a_team/models/dist/TalentCategories';
import {
  TeammateSuggestionObject,
  UserCardObject,
  UserId,
} from '@a_team/models/dist/UserObject';
import { Breakpoints } from '@ateams/components';
import DiscoveryUserCard, {
  DiscoveryCardType,
} from '@src/components/DiscoveryUserCard';
import DiscoveryUserCardSkeleton from '@src/components/DiscoveryUserCard/DiscoveryUserCardSkeleton';
import ConfirmModalV2 from '@src/components/Modal/ConfirmModalV2';
import useIntersectionObserver from '@src/hooks/useIntersectionObserver';
import { useNarrativeTeammateSuggestions } from '@src/rq/missions';
import { useStores } from '@src/stores';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import EndOfRecommendations from './EndOfRecommendations';
import RoleCategoryTags from './RoleCategoryTags';
import Search from './Search';
import searchIcon from './searchIcon.svg';
import NotificationBannerV2 from '@src/components/NotificationBannerV2';
import TeammateRecommendationCard from '../TeammateRecommendationCard';
import TeammateRecommendationCardSkeleton from '../TeammateRecommendationCard/TeammateRecommendationCardSkeleton';

export enum SuggestionSource {
  Recommendation = 'recommendation',
  Search = 'search',
}

export interface RoleCategoryTag {
  title: string;
  quantity: number;
}

interface TeammateRecommendationsV2Props {
  missionId: MissionId;
  currentRole: MissionRole;
  requestedTeammates: MinimalRecommendationData[];
  onRequest: (uid: UserId, requested: boolean) => void;
}

const useStyles = createUseStyles({
  root: {
    marginTop: 172,
    paddingTop: 24,
    paddingBottom: 90,
  },
  headerSection: {
    textAlign: 'center',
  },
  title: {
    margin: 0,
    fontWeight: 600,
    fontSize: 20,
  },
  subtitle: {
    marginTop: 8,
    fontSize: 14,
    maxWidth: 595,
    margin: '15px auto 0 auto',
  },
  availableRolesTitle: {
    marginTop: 24,
    fontSize: 14,
  },
  discoveryCardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 100%))',
    gap: 12,
    marginTop: 24,
    marginBottom: 60,
    justifyContent: 'center',
  },
  discoveryCard: {
    width: '100%',
    minWidth: 250,
  },
  noResultsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: 40,
    fontSize: 14,
  },
  iconWrapper: {
    marginBottom: 20,
  },
  [`@media (min-width: 520px)`]: {
    discoveryCardsContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, 352px)',
    },
    discoveryCard: {
      width: 294,
    },
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    root: {
      marginTop: 20,
      padding: 0,
      paddingBottom: 60,
    },
    title: {
      fontSize: 24,
    },
    subtitle: {
      fontSize: 15,
    },
    availableRolesTitle: {
      fontSize: 15,
    },
    discoveryCardsContainer: {
      marginTop: 40,
      gap: 24,
    },
  },
});

function TeammateRecommendationsV2({
  missionId,
  currentRole,
  requestedTeammates,
  onRequest,
}: TeammateRecommendationsV2Props) {
  const styles = useStyles();
  const {
    missions,
    auth,
    users: { profile },
  } = useStores();

  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;
  const [searchQuery, setSearchQuery] = useState('');
  const roles = missions.currentMission?.data.roles || [];
  const notAppliedMissionRoles = useMemo(() => {
    return roles?.filter((role) => role.rid !== currentRole.rid) ?? [];
  }, roles);

  const [roleCategoryTags, talentCategoryIdsForOpenRoles]: [
    Map<string, RoleCategoryTag>,
    TalentCategoryId[],
  ] = useMemo(() => {
    const roleCategoryTags: Map<string, RoleCategoryTag> = new Map();
    let talentCategoryIdsForOpenRoles: TalentCategoryId[] = [];
    for (const role of notAppliedMissionRoles) {
      const key = role.category.cid;
      let roleCategoryTag = roleCategoryTags.get(key);
      if (!roleCategoryTag) {
        roleCategoryTag = { title: role.category.title, quantity: 0 };
        roleCategoryTags.set(key, roleCategoryTag);
      }

      roleCategoryTags.set(key, {
        ...roleCategoryTag,
        quantity: roleCategoryTag.quantity + 1,
      });

      talentCategoryIdsForOpenRoles.push(...role.category.talentCategoryIds);
    }

    talentCategoryIdsForOpenRoles = _.uniq(talentCategoryIdsForOpenRoles);

    return [roleCategoryTags, talentCategoryIdsForOpenRoles];
  }, [notAppliedMissionRoles]);

  const { data, hasNextPage, fetchNextPage, isLoading, isFetching } =
    useNarrativeTeammateSuggestions({
      missionId,
      rid: currentRole.rid,
      searchQuery,
      onSuccess: (data) => {
        let suggestedTeammates = [
          ...(profile?.application?.suggestedTeammates ?? []),
          ...(data?.pages.flatMap(
            ({ teammateSuggestions }) => teammateSuggestions,
          ) || []),
        ];

        suggestedTeammates = _.uniqBy(suggestedTeammates, 'uid');

        profile?.application?.setSuggestedTeammates(
          suggestedTeammates as TeammateSuggestionObject[],
        );
      },
    });

  const suggestedUsers = useMemo(
    () =>
      data?.pages.flatMap(({ teammateSuggestions }) => teammateSuggestions) ||
      [],
    [data?.pages],
  );

  useEffect(() => {
    if (isVisible && hasNextPage) {
      fetchNextPage();
    }
  }, [isVisible, hasNextPage]);
  const handleOnSelect = (user: UserCardObject): void => {
    onRequest(user.uid, true);
  };

  const handleUnselect = (user: UserCardObject): void => {
    onRequest(user.uid, false);
  };

  const checkIfUserRoleIsAvailable = (
    talentCategoryIdsForOpenRoles: TalentCategoryId[],
    suggestedUser: UserCardObject,
  ): boolean => {
    const talentSpecializations =
      suggestedUser?.talentProfile?.talentSpecializations;

    if (!talentSpecializations) {
      return false;
    }

    let talentCategoryIdsForSuggestedUser = _.flatten(
      [
        ...(talentSpecializations.mainTalentSpecialization?.talentCategoryIds ??
          []),
        ...talentSpecializations.additionalTalentSpecializations.map(
          (specialization) => specialization.talentCategoryIds,
        ),
      ].filter((id) => id !== undefined) as TalentCategoryId[],
    );

    talentCategoryIdsForSuggestedUser = _.uniq(
      talentCategoryIdsForSuggestedUser,
    );

    const intersectedTalentCategoryIds = _.intersection(
      talentCategoryIdsForOpenRoles,
      talentCategoryIdsForSuggestedUser,
    );

    return intersectedTalentCategoryIds.length > 0;
  };

  const checkIfSelected = (user: UserCardObject): boolean => {
    return !!requestedTeammates.find(
      (requestedTeammate) => requestedTeammate.userId === user.uid,
    );
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const toggleSkipModal = () => {
    profile?.application?.setShowSkipPostApplicationSuggestionsModal(
      !profile?.application?.showSkipPostApplicationSuggestionsModal,
    );
  };

  const handleSkip = () => {
    toggleSkipModal();
    profile?.application?.setRequestedTeammates([]);
    profile?.application?.skipRecommendations();
  };

  function getDescription() {
    if (requestedTeammates.length > 0) {
      return 'Your selection will be lost and you will be considered as an individual. Teaming up with other builders can increase your chances of getting selected.';
    }

    if (auth.withTeamUp === undefined) {
      return '';
    }

    if (auth.withTeamUp) {
      return 'Applying as a team can increase your chances of being selected. If you skip this step, you’ll only be considered for missions as an individual.';
    }

    return 'Teaming up can increase your chances of being selected for missions. If you skip this step, you’ll only be considered for missions as an individual.';
  }

  const description = getDescription();

  const selectedUsersProfilePictureURLs = useMemo(() => {
    return (
      profile?.application?.requestedUsers.map(
        (requestedUser) => requestedUser.profilePictureURL,
      ) ?? []
    );
  }, [profile?.application?.requestedUsers.length]);

  return (
    <div className={styles.root}>
      <div className={styles.headerSection}>
        <h3 className={styles.title}>
          {auth.withTeamUp
            ? 'Build your dream team of builders you want to work with'
            : 'Would you like to team up with someone on this mission?'}
        </h3>
        <div className={styles.subtitle}>
          {auth.withTeamUp
            ? 'Improve your chances of being selected by inviting builders you like to work with. Companies view teams as a sign of compatibility and successful outcomes.'
            : 'Builders who apply as a team increase their chances of being selected for missions.'}
        </div>
        <div className={styles.availableRolesTitle}>Available roles</div>
        <RoleCategoryTags roleCategoryTags={roleCategoryTags} />
        <div>
          <Search
            searchQuery={searchQuery}
            onSearch={handleSearch}
            isLoading={isLoading && searchQuery !== ''}
          />
        </div>
      </div>
      <div className={styles.discoveryCardsContainer}>
        {auth.withTeamUp &&
          !isLoading &&
          suggestedUsers.length > 0 &&
          suggestedUsers.map((suggestedUser) => (
            <TeammateRecommendationCard
              key={suggestedUser.uid}
              isSearch={searchQuery !== ''}
              selected={checkIfSelected(suggestedUser)}
              suggestedUser={suggestedUser}
              onSelect={handleOnSelect}
              onUnselect={handleUnselect}
            />
          ))}

        {!auth.withTeamUp &&
          !isLoading &&
          suggestedUsers.length > 0 &&
          suggestedUsers.map((suggestedUser) => (
            <DiscoveryUserCard
              key={suggestedUser.uid}
              className={styles.discoveryCard}
              type={DiscoveryCardType.Suggested}
              roleDoesNotMatch={
                !checkIfUserRoleIsAvailable(
                  talentCategoryIdsForOpenRoles,
                  suggestedUser,
                )
              }
              suggestedUser={suggestedUser}
              isSelected={checkIfSelected(suggestedUser)}
              onSelect={handleOnSelect}
              onUnselect={handleUnselect}
            />
          ))}

        {(isLoading || isFetching) &&
          Array.from(Array(20).keys()).map((index) => {
            if (auth.withTeamUp === undefined) {
              return null;
            }

            if (auth.withTeamUp) {
              return <TeammateRecommendationCardSkeleton key={index} />;
            }

            return (
              <DiscoveryUserCardSkeleton
                key={index}
                type={DiscoveryCardType.Suggested}
                className={styles.discoveryCard}
              />
            );
          })}
      </div>

      {!isLoading && suggestedUsers.length === 0 && (
        <div className={styles.noResultsWrapper}>
          <div className={styles.iconWrapper}>
            <img src={searchIcon} alt="Search" />
          </div>
          <div>No profiles matched your search.</div>
          <div style={{ marginTop: 5 }}>
            Try using alternative names or spelling variation.
          </div>
        </div>
      )}

      <div
        style={{
          height: '1px',
          width: '100%',
        }}
        ref={ref}
      />

      {!isLoading &&
        !hasNextPage &&
        searchQuery === '' &&
        suggestedUsers.length > 0 && <EndOfRecommendations />}

      <ConfirmModalV2
        title={
          requestedTeammates.length > 0
            ? 'Are you sure you want to skip?'
            : 'Are you sure you want to skip teaming up?'
        }
        description={description}
        open={
          profile?.application?.showSkipPostApplicationSuggestionsModal ?? false
        }
        onClose={toggleSkipModal}
        onCancel={toggleSkipModal}
        onCancelText="Cancel"
        onConfirm={handleSkip}
        onConfirmText="Skip teaming up"
      />

      {auth.withTeamUp && (
        <NotificationBannerV2
          profilePictureURLs={selectedUsersProfilePictureURLs}
          title="Way to go, your team looks great."
          description="If your teammates accept, you will have higher chances of being selected."
          showUsersCounter
        />
      )}
    </div>
  );
}

export default observer(TeammateRecommendationsV2);
