import {
  Button,
  Checkbox,
  Colors,
  Icon,
  IconType,
  Spacing,
  TextColors,
  ToggleSwitch,
} from '@ateams/components';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import TextButton from '@src/components/TextButton';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import {
  initialSidebarParameters,
  updateSuggestedTeamsCriteria,
} from './utils';
import {
  TargeterSuggestedTeamsSidebarParameters,
  TargeterSuggestedTeamsSidebarAudience,
} from '@a_team/models/dist/TargeterSuggestedTeams';
import { TeamTargeterCriteriaParametersV2 } from '@a_team/data-science-api-client';

interface Props {
  isLoading?: boolean;
  suggestedTeamsParameters?: TargeterSuggestedTeamsSidebarParameters;
  setSuggestedTeamsParameters: (
    parameters: TargeterSuggestedTeamsSidebarParameters,
  ) => void;
  suggestedTeamsCriteria: TeamTargeterCriteriaParametersV2 | null;
  setSuggestedTeamsCriteria: (
    criteria: TeamTargeterCriteriaParametersV2,
  ) => void;
  activeRoleTabNames?: string[];
  runSearch: () => void;
}

const useStyles = createUseStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '28em',
    padding: '1em',
    boxShadow: `5px 0 5px ${Colors.regularLight}`,
    gap: 24,
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  title: {
    fontWeight: 600,
    fontSize: 15,
    color: TextColors.regular,
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 15,
    color: TextColors.regularLight,
  },
  textButton: {
    color: TextColors.primaryLight,
  },
  narrativeTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  narrativeButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  buildersLimit: {
    width: 80,
    margin: 0,
    '& > div': {
      minHeight: 'unset',
      padding: '0.35em 0.5em',
    },
  },
  rolesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  audienceMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  audienceContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  audienceInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  runSearch: {
    width: 114,
    paddingLeft: Spacing.medium,
    paddingRight: Spacing.medium,
    zIndex: 1,
  },
  fetchingDataContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
});

const MIN_CORE_ROLES_COUNT = 2;

const TargeterSuggestedTeamsSidebar = (props: Props): ReactElement => {
  const styles = useStyles(props);
  const {
    suggestedTeamsParameters,
    setSuggestedTeamsParameters,
    suggestedTeamsCriteria,
    setSuggestedTeamsCriteria,
    isLoading,
    runSearch,
    activeRoleTabNames,
  } = props;

  const teamCriteria = suggestedTeamsCriteria?.teamCriteria ?? {};

  useEffect(() => {
    if (!suggestedTeamsParameters) {
      const newParameters = initialSidebarParameters(
        teamCriteria,
        activeRoleTabNames,
        suggestedTeamsParameters,
      );
      setSuggestedTeamsParameters(newParameters);
      updateSuggestedTeamsCriteria(
        newParameters,
        teamCriteria,
        setSuggestedTeamsCriteria,
      );
    }
  }, [suggestedTeamsCriteria, activeRoleTabNames]);

  const onChange = (
    changes: Partial<TargeterSuggestedTeamsSidebarParameters>,
  ) => {
    if (suggestedTeamsParameters) {
      const newParameters = {
        ...suggestedTeamsParameters,
        ...changes,
      };
      setSuggestedTeamsParameters(newParameters);
      updateSuggestedTeamsCriteria(
        newParameters,
        teamCriteria,
        setSuggestedTeamsCriteria,
      );
    }
  };

  const onChangeAudience = (
    key: string,
    changes: Partial<TargeterSuggestedTeamsSidebarAudience>,
  ) => {
    if (suggestedTeamsParameters) {
      const newParameters = { ...suggestedTeamsParameters };
      newParameters.audiences[key] = {
        ...newParameters?.audiences[key],
        ...changes,
      };
      setSuggestedTeamsParameters(newParameters);
      updateSuggestedTeamsCriteria(
        newParameters,
        teamCriteria,
        setSuggestedTeamsCriteria,
      );
    }
  };

  const onClickClear = () => {
    onChange({
      invitedByOrInvitations: false,
      missions: false,
      projects: false,
      connections: false,
      teams: false,
      companies: false,
    });
  };

  const onClickSelectAll = () => {
    onChange({
      invitedByOrInvitations: true,
      missions: true,
      projects: true,
      connections: true,
      teams: true,
      companies: true,
    });
  };

  const narrativesEnabled = useMemo(() => {
    let coreRolesCount = 0;

    if (suggestedTeamsParameters?.audiences) {
      Object.values(suggestedTeamsParameters.audiences).forEach(
        (audience) =>
          (coreRolesCount += audience.makeCore ? audience.buildersPerTab : 0),
      );
    }
    return (
      !(suggestedTeamsParameters?.showPartialTeams || false) ||
      coreRolesCount >= MIN_CORE_ROLES_COUNT
    );
  }, [suggestedTeamsParameters?.showPartialTeams]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.sectionContainer}>
        <div>
          <div className={styles.title}>Tabs</div>
          <div className={styles.subtitle}>
            All "core" roles are must-have and required to be connected with
            each other. Set number to limit query range.
          </div>
        </div>
        <Checkbox
          onChange={(e) =>
            onChange({
              ...suggestedTeamsParameters,
              allowMultipleBuildersPerAudience: e.target.checked,
            })
          }
          margin="none"
          checked={
            suggestedTeamsParameters?.allowMultipleBuildersPerAudience || false
          }
          label="Allow multiple builders per audience"
        />
        <Checkbox
          onChange={(e) => onChange({ showTheAudienceLimit: e.target.checked })}
          margin="none"
          checked={suggestedTeamsParameters?.showTheAudienceLimit || false}
          label="Show the audience limit"
        />
        <div className={styles.rolesContainer}>
          {Object.keys(teamCriteria).map((key) => {
            const criteria = teamCriteria[key];
            return (
              <div key={key} className={styles.audienceMainContainer}>
                <div
                  key={`audience-container-${key}`}
                  className={styles.audienceContainer}
                >
                  <div className={styles.audienceInput}>
                    {suggestedTeamsParameters?.showTheAudienceLimit && (
                      <OutlinedInput
                        key={`audience-builder-input-${key}`}
                        type="number"
                        className={styles.buildersLimit}
                        defaultValue={criteria.limit}
                        onChange={(e) =>
                          onChangeAudience(key, {
                            limit: parseInt(e.target.value),
                          })
                        }
                      />
                    )}
                    <div>{key}</div>
                  </div>
                  <ToggleSwitch
                    key={`audience-make-core-${key}`}
                    label="Make core"
                    disabled={!suggestedTeamsParameters?.showPartialTeams}
                    checked={
                      !suggestedTeamsParameters?.showPartialTeams ||
                      suggestedTeamsParameters?.audiences[key]?.makeCore ||
                      false
                    }
                    onChange={(checked) =>
                      onChangeAudience(key, {
                        makeCore: checked,
                      })
                    }
                    size="small"
                    justify="right"
                    checkedBackgroundColor="secondary"
                  />
                </div>

                {suggestedTeamsParameters?.allowMultipleBuildersPerAudience && (
                  <div
                    key={`audience-builder-per-tab-container-${key}`}
                    className={styles.audienceContainer}
                  >
                    <div className={styles.audienceInput}>
                      <OutlinedInput
                        key={`audience-builder-per-tab-${key}`}
                        type="number"
                        className={styles.buildersLimit}
                        defaultValue={
                          suggestedTeamsParameters?.audiences[key]
                            ?.buildersPerTab
                        }
                        onChange={(e) =>
                          onChangeAudience(key, {
                            buildersPerTab: parseInt(e.target.value),
                          })
                        }
                      />
                      <div>Per tab</div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <div className={styles.title}>Team Options</div>
        <Checkbox
          onChange={(e) => onChange({ showPartialTeams: e.target.checked })}
          margin="none"
          checked={suggestedTeamsParameters?.showPartialTeams || false}
          label="Show partial teams"
        />
        <Checkbox
          onChange={(e) =>
            onChange({ requiredTimeOverlapOnExperiences: e.target.checked })
          }
          margin="none"
          checked={
            suggestedTeamsParameters?.requiredTimeOverlapOnExperiences || false
          }
          label="Required Time Overlap on Experiences"
        />
      </div>

      <div className={styles.sectionContainer}>
        <div className={styles.narrativeTitleContainer}>
          <div className={styles.title}>Narratives</div>
          <div className={styles.narrativeButtonsContainer}>
            <TextButton
              className={styles.textButton}
              onClick={onClickSelectAll}
            >
              Select All
            </TextButton>
            <TextButton className={styles.textButton} onClick={onClickClear}>
              Clear
            </TextButton>
          </div>
        </div>
        <Checkbox
          onChange={(e) => onChange({ teams: e.target.checked })}
          margin="none"
          disabled={!narrativesEnabled}
          checked={
            !narrativesEnabled || suggestedTeamsParameters?.teams || false
          }
          label="Team Collection"
        />
        <Checkbox
          onChange={(e) =>
            onChange({ invitedByOrInvitations: e.target.checked })
          }
          margin="none"
          disabled={!narrativesEnabled}
          checked={
            !narrativesEnabled ||
            suggestedTeamsParameters?.invitedByOrInvitations ||
            false
          }
          label="Invited by / Invitee"
        />
        <Checkbox
          onChange={(e) => onChange({ missions: e.target.checked })}
          margin="none"
          disabled={!narrativesEnabled}
          checked={
            !narrativesEnabled || suggestedTeamsParameters?.missions || false
          }
          label="Shared A.Team Mission"
        />
        <Checkbox
          onChange={(e) => onChange({ projects: e.target.checked })}
          margin="none"
          disabled={!narrativesEnabled}
          checked={
            !narrativesEnabled || suggestedTeamsParameters?.projects || false
          }
          label="Shared Project"
        />
        <Checkbox
          onChange={(e) => onChange({ companies: e.target.checked })}
          margin="none"
          disabled={!narrativesEnabled}
          checked={
            !narrativesEnabled || suggestedTeamsParameters?.companies || false
          }
          label="Shared Company Experience"
        />
        <Checkbox
          onChange={(e) => onChange({ connections: e.target.checked })}
          margin="none"
          disabled={!narrativesEnabled}
          checked={
            !narrativesEnabled || suggestedTeamsParameters?.connections || false
          }
          label="Platform Connection"
        />
      </div>

      <Button
        onClick={runSearch}
        squared
        size="small"
        width="auto"
        className={styles.runSearch}
        disabled={isLoading}
      >
        {isLoading ? (
          <div className={styles.fetchingDataContainer}>
            <Icon
              size="exact"
              rotating={true}
              type={IconType.CircleLoadingRed}
            />
            <span>Fetching</span>
          </div>
        ) : (
          'Run Search'
        )}
      </Button>
    </div>
  );
};

export default TargeterSuggestedTeamsSidebar;
