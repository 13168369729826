import React from 'react';
import { createUseStyles } from 'react-jss';
import { Icon, IconType, Spacing, Colors, FontSizes } from '@ateams/components';
import { debounce } from 'lodash';
import LinkInput from './LinkInput';

const useStyles = createUseStyles({
  linksContainer: {
    width: 600,
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    marginTop: Spacing.medium,
  },
  linksHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: Spacing.xsmall,
  },
  linksTitle: {
    color: Colors.dark,
    fontSize: FontSizes.medium,
    lineHeight: '19px',
  },
  addIcon: {
    width: 12,
    height: 12,
    cursor: 'pointer',
    stroke: Colors.dark,
  },
  errorText: {
    color: Colors.danger,
    fontSize: FontSizes.xsmall,
    lineHeight: '16px',
  },
});

interface LinksSectionProps {
  links: string[];
  setLinks: (links: string[]) => void;
  validLinks: boolean[];
  setValidLinks: (validLinks: boolean[]) => void;
}

const LinksSection: React.FC<LinksSectionProps> = ({
  links,
  setLinks,
  validLinks,
  setValidLinks,
}) => {
  const styles = useStyles();

  const validateUrl = (url: string): boolean => {
    try {
      if (!url) {
        return true;
      }

      if (!url.startsWith('https://')) {
        return false;
      }

      const parsedUrl = new URL(url);

      if (!parsedUrl.hostname || !parsedUrl.hostname.includes('.')) {
        return false;
      }

      const disallowedChars = /[<>|\\^`{}]/;
      if (disallowedChars.test(url)) {
        return false;
      }

      return true;
    } catch {
      return false;
    }
  };

  const handleInputChange = (index: number, value: string) => {
    const newLinks = [...links];
    newLinks[index] = value;
    setLinks(newLinks);
    debouncedValidateUrl(index, value);

    if (value === '' && newLinks.length > 1) {
      newLinks.splice(index, 1);
      setLinks(newLinks);
      setValidLinks(newLinks.map((link) => validateUrl(link)));
    }
  };

  const debouncedValidateUrl = debounce((index: number, value: string) => {
    const valid = validateUrl(value);
    const updatedValidLinks = [...validLinks];
    updatedValidLinks[index] = valid;
    setValidLinks(updatedValidLinks);
  }, 500);

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const addLink = () => {
    setLinks(['', ...links]);
    setValidLinks([true, ...validLinks]);
  };

  return (
    <div className={styles.linksContainer}>
      <div className={styles.linksHeader}>
        <div className={styles.linksTitle}>Add links</div>
        <Icon
          type={IconType.PlusBlackFlat}
          className={styles.addIcon}
          onClick={addLink}
        />
      </div>
      {links.map((link, index) => (
        <div
          key={`link-${index}`}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: Spacing.xsmall,
          }}
        >
          <LinkInput
            link={link}
            index={index}
            valid={validLinks[index]}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
          />
          {link && !validLinks[index] && (
            <div className={styles.errorText}>
              Please make sure the URL starts with https://
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default LinksSection;
