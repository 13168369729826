import React from 'react';
import { Button, Modal } from '@a_team/ui-components';
import { createUseStyles } from 'react-jss';
import {
  CloseButton,
  FontSizes,
  Spacing,
  TextColors,
} from '@ateams/components';
import { useStores } from '@src/stores';
import resubscribe from '@src/assets/svgs/resubscribe.svg';
import useLoadingState from '@src/hooks/useLoadingState';
import { PreferencesDtoDisabledCategoriesEnum } from '@a_team/user-notification-service-js-sdk';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { useRefreshNotifications } from '@src/rq/notifications';

interface ReSubscribeNotificationsModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    color: TextColors.regular,
    textAlign: 'center',
    flexDirection: 'column',
  },
  content: {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: FontSizes.large,
    letterSpacing: '0.001em',
    lineHeight: '120%',
    margin: '16px 0',
  },
  desc: {
    margin: '0 0 40px 0',
  },
  modal: {
    '&&': {
      '& > div': {
        padding: '0 !important',
      },
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
  },
  closeButtonContainer: {
    position: 'absolute',
    top: Spacing.small,
    right: Spacing.small,
  },
});

const ReSubscribeMissionNotificationsModal = ({
  onClose,
  isOpen,
}: ReSubscribeNotificationsModalProps): JSX.Element => {
  const styles = useStyles();

  const { notificationPreferencesUser } = useStores();
  const refreshNotifications = useRefreshNotifications();
  const [resubscribingLoading, setResubscribingLoading] = useLoadingState();

  const onClickOnResubscribe = async () => {
    try {
      await notificationPreferencesUser.updateNotificationPreferences({
        disabledCategories: [
          ...(notificationPreferencesUser.preferences.disabledCategories || []),
        ].filter(
          (category) =>
            category !==
            PreferencesDtoDisabledCategoriesEnum.MissionNotification,
        ),
      });
      refreshNotifications();
      setResubscribingLoading(Promise.resolve(`Re-subscribed successfully`));
      onClose();
    } catch {
      setResubscribingLoading(Promise.reject(`Couldn't re-subscribe`));
    }
  };

  const handleOnClose = async () => {
    notificationPreferencesUser.dismissReSubscriptionReminder();
    onClose();
  };

  return (
    <Modal
      className={styles.modal}
      hideCloseButton
      variant="slideUp"
      isOpen={isOpen}
      onClose={handleOnClose}
    >
      <div className={styles.container}>
        <div className={styles.closeButtonContainer}>
          <CloseButton onClick={handleOnClose} />
        </div>
        <div className={styles.content}>
          <div className={styles.imageContainer}>
            <img src={resubscribe} alt="re-subscribe" />
          </div>
          <h2 className={styles.title}>
            Would you like to resubscribe to mission notifications?
          </h2>
          <p className={styles.desc}>
            Mission notifications ensure you’re one of the first to know about
            new relevant mission opportunities, increasing your chance of
            success.
          </p>
          <div className={styles.buttonsContainer}>
            <Button
              variant="secondary"
              size="md"
              onClick={() => handleOnClose()}
            >
              Cancel
            </Button>
            <Button size="md" onClick={() => onClickOnResubscribe()}>
              Re-subscribe
            </Button>
          </div>
        </div>
      </div>
      <LoadingIndicator loading={resubscribingLoading} />
    </Modal>
  );
};

export default ReSubscribeMissionNotificationsModal;
