import { SurveyQuestionResponseValidator } from '../SurveyQuestionResponseValidator';
import { SurveyQuestionType } from '../SurveyQuestionType';
import { SurveyQuestionBaseProps } from '../SurveyQuestionBaseProps';

export interface RecommendedRolesSurveyQuestionProps
  extends SurveyQuestionBaseProps {
  title: string;
}

interface RecommededRoleResponse {
  roleCategoryId?: string;
  skillIds?: string[];
  comment: string;
}

export interface RecommendedRolesSurveyQuestionResponse {
  roles?: RecommededRoleResponse[];
}

export const validateRecommendedRolesSurveyQuestionResponse: SurveyQuestionResponseValidator<
  SurveyQuestionType.RecommendedRoles
> = (props, response): void => {
  response.roles?.forEach((value, index) => {
    if (!value.roleCategoryId) {
      throw new Error(`roleCategoryId is required at index ${index}`);
    }
    if (!value.comment) {
      throw new Error(`Role justification is required at index ${index}`);
    }
  });
};
