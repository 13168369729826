import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface CheckboxProps {
  className?: string;
  checked?: boolean;
}

const useStyles = createUseStyles({
  container: {
    height: '16px',
    width: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  unchecked: {
    borderRadius: '4px',
    border: '1px solid #DADADC',
    background: '#F7F7F7',
    height: '8px',
    width: '8px',
  },
});

const Checkbox = ({ className, checked }: CheckboxProps): JSX.Element => {
  const styles = useStyles();

  if (checked) {
    return (
      <div className={cx(styles.container, className)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 16 16"
        >
          <rect width="16" height="16" fill="#7000E3" rx="8" />
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.4"
            d="M4 8l3 3 5-6"
          />
        </svg>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={cx(styles.unchecked, className)} />
    </div>
  );
};

export default Checkbox;
