import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import OutlinedInput, {
  OutlinedInputProps,
} from '@src/components/Inputs/OutlinedInput';
import searchIcon from '@src/components/Inputs/UsersSearchInput/search.svg';
import { Card } from '@ateams/components';
import { useStores } from '@src/stores';
import UserObject, {
  AnonymousUserObject,
  BasicUserObject,
  UserType,
} from '@a_team/models/dist/UserObject';
import useClickOutside from '@src/hooks/useClickOutside';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import UserAvatar from '@src/components/UserAvatar';
import useDebounceState from '@src/hooks/useDebounceState';
import { UserItem } from '@src/components/BasicUserSelector/UserItem';

export interface BasicUserSelectorProps {
  user?: BasicUserObject | UserObject | AnonymousUserObject;
  allowNonUser?: boolean;
  displaySelection?: boolean;
  required?: boolean;
  margin?: OutlinedInputProps['margin'];
  placeholder?: string;
  onSelect(user: AnonymousUserObject | BasicUserObject | null): void;
  disabled?: boolean;
  includeClientUsers?: boolean;
}

const useStyles = createUseStyles({
  menu: {
    padding: 0,
    overflowY: 'scroll',
    margin: 0,
    display: 'block',
    width: '100%',
    maxHeight: 336,
  },
  userIcon: {
    marginRight: 8,
  },
});

const BasicUserSelector = observer((props: BasicUserSelectorProps) => {
  const stores = useStores();
  const {
    auth,
    users: { getUsersByString, adminQueryByString },
  } = stores;
  const { displaySelection = true, disabled = false } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [userResults, setUserResults] = useState<
    (BasicUserObject | AnonymousUserObject)[]
  >([]);
  const [selectedUser, setSelectedUser] = useState<
    BasicUserObject | AnonymousUserObject | undefined | null
  >(props.user);

  const [searchQuery, setSearchQuery] = useDebounceState<string>(
    displaySelection && selectedUser ? selectedUser.fullName : '',
    (query) => getUsers(query, props.includeClientUsers ?? false),
    400,
  );

  const menuRef = useRef(null);
  const styles = useStyles();

  useEffect((): void => {
    setSelectedUser(props.user);
  }, [props.user]);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(e.target.value);
    !isOpen && setIsOpen(true);
    if (e.target.value === '') {
      setSelectedUser(undefined);
      props.onSelect(null);
      setIsOpen(false);
    }
  };

  const onPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.keyCode === 8 && selectedUser) {
      setSelectedUser(null);
      props.onSelect(null);
    }
  };

  const getUsers = async (
    query: string,
    includeCompanyUsers = false,
  ): Promise<void> => {
    if (query !== '') {
      const result = auth.isAdmin
        ? await adminQueryByString(query)
        : await getUsersByString(query);
      const filtered = result.filter(
        (user) =>
          (includeCompanyUsers && user.type === UserType.CompanyUser) ||
          user.type === UserType.User,
      );
      setUserResults(
        filtered.length
          ? filtered
          : [{ username: null, fullName: searchQuery }],
      );
    }
  };

  const onUserSelect = (
    user: AnonymousUserObject | BasicUserObject | null,
  ): void => {
    setSearchQuery('');
    setSelectedUser(displaySelection && user ? user : undefined);
    props.onSelect(user || null);
    setIsOpen(false);
  };

  useClickOutside(menuRef, () => setIsOpen(false));

  return (
    <OutlinedInput
      disabled={disabled}
      type="text"
      placeholder={props.placeholder || 'Add a person...'}
      name="team"
      onChange={onChange}
      onKeyDown={onPress}
      value={searchQuery || selectedUser?.fullName}
      required={props.required}
      margin={props.margin}
      icon={
        selectedUser ? (
          <UserAvatar
            src={
              selectedUser.username !== null
                ? selectedUser.profilePictureURL
                : undefined
            }
            size={24}
            containerClassName={styles.userIcon}
          />
        ) : (
          searchIcon
        )
      }
      dropdown={
        isOpen && (
          <Card className={styles.menu}>
            <div ref={menuRef}>
              {userResults.map((user) => {
                return (
                  <UserItem
                    user={user}
                    onUserSelect={onUserSelect}
                    key={'uid' in user ? user.uid : user.fullName}
                  />
                );
              })}
            </div>
          </Card>
        )
      }
    />
  );
});

export default BasicUserSelector;
