import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  VettingProcessAudit,
  VettingProcessAuditTypes,
} from '@a_team/models/dist/vetting-process-audit';
import { ActivityRecordMainText, ActivityRecordUser } from '.';

export interface VetterCanceledActivityRecordProps {
  vettingProcessAudit: VettingProcessAudit<VettingProcessAuditTypes.VetterCanceled>;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const VetterCanceledActivityRecord: React.FC<
  VetterCanceledActivityRecordProps
> = ({ vettingProcessAudit }) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <ActivityRecordMainText>{`Vetter canceled interview ${
        vettingProcessAudit.payload.reason ? 'with reason: ' : ''
      } ${vettingProcessAudit.payload.reason || ''}`}</ActivityRecordMainText>

      {vettingProcessAudit.payload.canceledUser && (
        <ActivityRecordUser user={vettingProcessAudit.payload.canceledUser} />
      )}
    </div>
  );
};
