import React, { ReactElement, useMemo } from 'react';
import UserObject from '@a_team/models/dist/UserObject';
import { createUseStyles } from 'react-jss';
import {
  Breakpoints,
  Colors,
  Icon,
  IconType,
  Spacing,
} from '@ateams/components';
import Application from '@src/stores/Application';
import { Skills } from '../Skills';
import { getHumanReadableTime } from '../WorkingHours/utils';
import {
  continentsOptionsMap,
  countryListOptionMap,
} from '@src/helpers/rawCountryList';
import TooltipWrapped from '@src/components/TooltipWrapped';
import {
  ApplicationMissingRequirementsResponse,
  MissionMissingRequirement,
} from '@a_team/models/dist/MissionApplicationObject';
import { getContinentAwareLocations } from './MissingRequirementsUtils';
import { Expertise } from '@src/stores/Profile/models';
import { useQueryBuilderLoginHistory } from '@src/rq/builderLoginHistory';

interface Props {
  profile: UserObject;
  applicationMissingRequirementsResponse: ApplicationMissingRequirementsResponse;
  application?: Application;
}

const useStyles = createUseStyles({
  '@global': {
    '.tippy-popper': {
      zIndex: '999999 !important',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
    borderRadius: 5,
    padding: 24,
    paddingTop: 0,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: '#222222',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    height: 32,
  },
  icon: {
    color: '#222222',
    marginRight: 8,
    width: 16,
  },
  fieldName: {
    color: '#222222',
    fontSize: 15,
    fontWeight: 500,
    width: 170,
  },
  fieldValue: {
    display: 'flex',
    flexDirection: 'row',
  },
  verticalSeparator: {
    width: 1,
    height: 16,
    background: '#C0C0C0',
    marginTop: 3,
    marginLeft: 8,
    marginRight: 8,
  },
  errorIcon: {
    width: 8,
    marginLeft: 8,
    marginRight: 8,
  },
  error: {
    color: '#F63131',
  },
  success: {
    color: Colors.success,
    marginLeft: Spacing.xsmall,
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 0,
    width: '100%',
  },
  seeLocations: {
    textDecoration: 'underline',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    wrapper: {
      textAlign: 'left',
      alignItems: 'flex-start',
      margin: 0,
    },
  },
});

export const MissingRequirements = (props: Props): ReactElement => {
  const { data } = useQueryBuilderLoginHistory({
    uid: props.profile.uid,
  });

  const locationMismatch = data?.hasLocationMismatch;

  const styles = useStyles();
  const { application, profile, applicationMissingRequirementsResponse } =
    props;

  const missingOrLowProficiencySkills = useMemo<Expertise[]>(
    () =>
      applicationMissingRequirementsResponse.missingOrLowProficiencySkills.map(
        (skill) =>
          ({
            id: skill.talentSkillId,
            name: skill.talentSkillName,
            rating: skill.rating,
            featured: true,
            categoryIds: [],
          } as Expertise),
      ),
    [profile],
  );

  const continentAwareLocations = useMemo(
    () => getContinentAwareLocations(application?.currentRole),
    [application?.currentRole],
  );

  if (
    (!applicationMissingRequirementsResponse.missingRequirements ||
      !applicationMissingRequirementsResponse.missingRequirements.length) &&
    !locationMismatch
  ) {
    return <></>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <h4 className={styles.title}>Builder Missing Requirements</h4>
      </div>
      {applicationMissingRequirementsResponse.missingRequirements.includes(
        MissionMissingRequirement.StrictRateMismatch,
      ) ||
      applicationMissingRequirementsResponse.missingRequirements.includes(
        MissionMissingRequirement.BufferedRateMismatch,
      ) ? (
        <div className={styles.row}>
          <Icon type={IconType.MoneyGray} className={styles.icon} />
          <div className={styles.fieldName}>Rate</div>
          <div className={styles.fieldValue}>
            ${application?.currentRole?.builderRateMin?.toFixed()} - $
            {Math.ceil(application?.currentRole?.builderRateMax || 0).toFixed()}
            <div className={styles.verticalSeparator}></div> User set $
            {application?.minHourlyRate.toFixed()} - $
            {application?.maxHourlyRate.toFixed()}
            <Icon type={IconType.Missing} className={styles.errorIcon} />
            <div className={styles.error}>The rate is out of range</div>
            {!applicationMissingRequirementsResponse.missingRequirements.includes(
              MissionMissingRequirement.BufferedRateMismatch,
            ) && <div className={styles.success}>(within threshold)</div>}
          </div>
        </div>
      ) : null}
      {applicationMissingRequirementsResponse.missingRequirements.includes(
        MissionMissingRequirement.StrictAvailabilityMismatch,
      ) ||
      applicationMissingRequirementsResponse.missingRequirements.includes(
        MissionMissingRequirement.BufferedAvailabilityMismatch,
      ) ? (
        <div className={styles.row}>
          <Icon type={IconType.Clock} className={styles.icon} />
          <div className={styles.fieldName}>Availability</div>
          <div className={styles.fieldValue}>
            Min.{' '}
            {application?.currentRole?.availability?.weeklyHoursAvailable || 0}{' '}
            hours per week&nbsp;
            <div className={styles.verticalSeparator}></div> User has{' '}
            {applicationMissingRequirementsResponse.weeklyHoursAvailable || 0}{' '}
            hours per week
            <Icon type={IconType.Missing} className={styles.errorIcon} />
            <div className={styles.error}>Not enough availability</div>
            {!applicationMissingRequirementsResponse.missingRequirements.includes(
              MissionMissingRequirement.BufferedAvailabilityMismatch,
            ) && <div className={styles.success}>(within threshold)</div>}
          </div>
        </div>
      ) : null}
      {applicationMissingRequirementsResponse.missingRequirements.includes(
        MissionMissingRequirement.StrictWorkingHoursMismatch,
      ) ||
      applicationMissingRequirementsResponse.missingRequirements.includes(
        MissionMissingRequirement.BufferedWorkingHoursMismatch,
      ) ? (
        <div className={styles.row}>
          <Icon type={IconType.World} className={styles.icon} />
          <div className={styles.fieldName}>Timezone Overlap</div>
          <div className={styles.fieldValue}>
            {getHumanReadableTime(
              application?.currentRole?.workingHours?.numberOfMinutesOverlap ||
                0,
            )}{' '}
            hours required&nbsp;
            <div className={styles.verticalSeparator}></div>User has{' '}
            {getHumanReadableTime(
              applicationMissingRequirementsResponse.overlappedTimeInMinutes ||
                0,
            )}
            &nbsp;of overlap
            <Icon type={IconType.Missing} className={styles.errorIcon} />
            <div className={styles.error}>Not enough overlap</div>
            {!applicationMissingRequirementsResponse.missingRequirements.includes(
              MissionMissingRequirement.BufferedWorkingHoursMismatch,
            ) && <div className={styles.success}>&nbsp;(within threshold)</div>}
          </div>
        </div>
      ) : null}
      {applicationMissingRequirementsResponse.missingRequirements.includes(
        MissionMissingRequirement.StrictLocationMismatch,
      ) || locationMismatch ? (
        <div className={styles.row}>
          <Icon type={IconType.Location} className={styles.icon} />
          <div className={styles.fieldName}>Required Location</div>
          <div className={styles.fieldValue}>
            <TooltipWrapped
              title={continentAwareLocations
                .map((code) =>
                  code.startsWith('_')
                    ? continentsOptionsMap[code.replace('_', '')].label
                    : countryListOptionMap[code].label,
                )
                .join(', ')}
              theme={'light'}
              position={'right'}
              arrow
              animation="fade"
              style={{
                top: 0,
                left: 100,
                zIndex: 100000,
                color: '#222222',
              }}
            >
              <div className={styles.seeLocations}>See locations</div>
            </TooltipWrapped>
            {applicationMissingRequirementsResponse.missingRequirements.includes(
              MissionMissingRequirement.StrictLocationMismatch,
            ) && (
              <>
                <Icon type={IconType.Missing} className={styles.errorIcon} />
                <div className={styles.error}>
                  Location requirements not met
                </div>
              </>
            )}

            {locationMismatch && (
              <>
                <Icon type={IconType.Missing} className={styles.errorIcon} />
                <div className={styles.error}>Location doesn’t match IP</div>
              </>
            )}
          </div>
        </div>
      ) : null}

      {applicationMissingRequirementsResponse.missingRequirements.includes(
        MissionMissingRequirement.StrictSkillsMismatch,
      ) ||
      applicationMissingRequirementsResponse.missingRequirements.includes(
        MissionMissingRequirement.BufferedSkillsMismatch,
      ) ? (
        <div className={styles.row}>
          <Icon type={IconType.Tag} className={styles.icon} />
          <div className={styles.fieldName}>Required Skills</div>
          <div className={styles.fieldValue}>
            Missing or low proficiency skills&nbsp;
            {!applicationMissingRequirementsResponse.missingRequirements.includes(
              MissionMissingRequirement.BufferedSkillsMismatch,
            ) && <div className={styles.success}>&nbsp;(Within threshold)</div>}
            <Skills
              skills={missingOrLowProficiencySkills}
              isAdmin={true}
              readonly={true}
              disabled={false}
              fontSize={15}
              showColorByRating={true}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
