import React from 'react';
import cx from 'classnames';
import { useCommonStyles } from '../commonStyles';
import { Button } from '@a_team/ui-components';
import { ConnectCalendarButton } from '../ConnectCalendarButton';
import { CDN_BASE_URL } from '@src/config';

interface PrivacyProps {
  onClose: () => void;
}

export const Privacy = (props: PrivacyProps) => {
  const styles = useCommonStyles();

  return (
    <>
      <div className={styles.hero}>
        <h4 className={styles.modalTitle}>Connect with confidence</h4>
        <p className={cx(styles.heroCopy, 'lessPadding')}>
          Securely sync your calendar knowing companies can only see your
          availability and that A.Team is SOC2 security verified and Google
          approved.
        </p>
      </div>
      <img
        src={`${CDN_BASE_URL}/website/calcom-banner-01.jpg`}
        alt="A.Team calendar banner"
        className={styles.img}
      />
      <div className={styles.teaserBody}>
        <div className={styles.panel}>
          <h5 className={styles.panelTitle}>
            Your events are private and secure
          </h5>
          <p className={styles.panelCopy}>
            Companies can only view your availability for the next 7 days during
            your designated working hours, not your specific events.
            <br />
            <strong>A.Team does not store calendar data.</strong>
          </p>
        </div>
        <div className={styles.panel}>
          <h5 className={styles.panelTitle}>
            A.Team is SOC2 Security Certified.
          </h5>
          <p className={styles.panelCopy}>
            A.Team has been audited by an independent third party to verify
            compliance with SOC2, the highest industry security standard.
          </p>
        </div>
        <div className={styles.panel}>
          <h5 className={styles.panelTitle}>
            A.Team is a Google Approved Vendor.
          </h5>
          <p className={styles.panelCopy}>
            A.Team has been vetted by Google and complies with all security
            practices.
          </p>
        </div>
        <div className={styles.panel}>
          <h5 className={styles.panelTitle}>
            Why does A.Team need view and edit permissions?
          </h5>
          <p className={styles.panelCopy}>
            These are standard permissions in all leading scheduling platforms
            like Calendly® and others — they allow us to create, reschedule and
            cancel events we’ve created for you.
          </p>
          <h5 className={styles.subPanelTitle}>View Calendar Events</h5>
          <p className={styles.panelCopy}>
            A.Team needs permission to view your calendar events to look at your
            current schedule and find available timeslots for booking, and
            prevent double-booking.
          </p>

          <h5 className={styles.subPanelTitle}>Create events</h5>
          <p className={styles.panelCopy}>
            When a company books or requests and interview with you, the
            platform needs permission to create a new event on your calendar to
            speed up the booking process.
          </p>

          <h5 className={styles.subPanelTitle}>Edit events</h5>
          <p className={styles.panelCopy}>
            Giving edit permissions allows the platform to automatically update
            the event on your Calendar if a meeting gets canceled or
            rescheduled.
          </p>

          <h5 className={styles.subPanelTitle}>Delete events</h5>
          <p className={styles.panelCopy}>
            Similarly, if an event is canceled via A.Team, the platform may need
            to remove the event from your Calendar. This is to ensure your
            calendar remains accurate and up-to-date.
          </p>
        </div>
        <div
          className={styles.actions}
          style={{ paddingTop: 32, paddingBottom: 32 }}
        >
          <Button
            className={styles.closeBtn}
            variant="secondary"
            size="sm"
            onClick={props.onClose}
          >
            Back
          </Button>
          <ConnectCalendarButton />
        </div>
      </div>
    </>
  );
};
