import React from 'react';
import Modal from '..';
import SectionHeading from '@src/components/SectionHeading';
import { createUseStyles } from 'react-jss';
import Alert from '@src/components/Alert';
import { Breakpoints } from '@ateams/components';
import { observer } from 'mobx-react';

const useStyles = createUseStyles({
  modalContainer: {
    paddingTop: '24px !important',
  },
  title: {
    marginTop: 0,
  },
  alert: {
    marginBottom: 16,
  },
  alertContent: {
    marginTop: 0,
    marginBottom: 0,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modalContainer: {
      minWidth: 900,
    },
  },
});

interface Props {
  show?: boolean;
  onDismiss: VoidFunction;
}

const TokenIntroductionModal: React.FC<Props> = ({ show, onDismiss }) => {
  const styles = useStyles();

  React.useEffect(() => {
    const script1 = document.createElement('script');

    script1.src = 'https://fast.wistia.net/assets/external/E-v1.js';
    script1.async = true;
    document.body.appendChild(script1);
  }, []);

  return (
    <Modal open={!!show} onClose={onDismiss} className={styles.modalContainer}>
      <SectionHeading isFirst className={styles.title}>
        Introducing Build Token, a new way to reward our community.
      </SectionHeading>
      <Alert className={styles.alert}>
        <p className={styles.alertContent}>
          The Build Token captures the value you create in the network &mdash;
          enabling you to participate in the governance of A.Team, gain special
          status, and benefit from our shared growth.
        </p>
      </Alert>
      <div
        className="wistia_responsive_padding"
        style={{ padding: '56.25% 0 0 0', position: 'relative' }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        >
          <iframe
            src="https://fast.wistia.net/embed/iframe/g9lriqw3fo?seo=false&videoFoam=true"
            title="Intro Build Token Video"
            allow="autoplay; fullscreen"
            allowTransparency
            frameBorder="0"
            scrolling="no"
            className="wistia_embed"
            name="wistia_embed"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </Modal>
  );
};

export default observer(TokenIntroductionModal);
