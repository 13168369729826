import React, { ReactElement, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { AdminNote } from '@a_team/models/dist/AdminNotesObject';
import { format } from 'date-fns';
import {
  Icon,
  IconType,
  Card,
  Button as CallToActionButton,
  Colors,
} from '@ateams/components';
import SectionHeading from '@src/components/SectionHeading';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import UserAvatar from '@src/components/UserAvatar';
import { ProfileLocation } from '@src/locations';
import Autolinker from 'autolinker';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';

interface Props {
  note: AdminNote;
  editable: boolean;

  username: string;
  highlight?: boolean;
  onSave: (text: string, id?: string) => void;
  onDelete: () => void;
}

const useStyles = createUseStyles({
  noteCard: (props: Props) => ({
    display: 'flex',
    flexDirection: 'column',
    background: props.highlight ? Colors.primaryLight : '#fff',
  }),
  noteContent: {
    fontFamily: ['Inter', 'sans-serif'],
    whiteSpace: 'break-spaces',
    lineBreak: 'auto',
    '& code': {
      whiteSpace: 'break-spaces',
    },
  },
  cta: {
    alignSelf: 'flex-end',
    display: 'flex',
  },
  smallDivider: {
    borderRight: '1px solid #C0C0C0',
    height: '16px',
    marginLeft: '12px',
    marginRight: '12px',
  },
  button: {
    color: '#fff',
    marginLeft: 12,
  },
});

export const AdminNoteCard = (props: Props): ReactElement => {
  const styles = useStyles(props);
  const { editable, note, username } = props;
  const date = useMemo(
    () => format(new Date(note.createdAt), "MMMM d, yyyy 'at' h:mm a"),
    [note.createdAt],
  );

  const [loading, setLoading] = useLoadingState();

  const [text, setText] = useState<string>(note.text);
  const [edit, setEdit] = useState<boolean>(false);

  const copyNoteLink = (nid: string): void => {
    const link = `${window.location.origin}/${username}?note=${nid}`;
    navigator.clipboard.writeText(link);
    setLoading(Promise.resolve(), 'Link copied to clipboard');
  };

  return (
    <div id={note.nid}>
      <Card className={styles.noteCard}>
        {edit ? (
          <>
            <SectionHeading isFirst>Edit Note</SectionHeading>
            <OutlinedInput
              type="text"
              multiline
              value={text}
              onChange={(e): void => setText(e.target.value)}
              margin="none"
            />
            <div className={styles.cta} style={{ marginTop: 16 }}>
              <CallToActionButton
                onClick={() => {
                  setEdit(false);
                  text ? props.onSave(text, note.nid) : setText(note.text);
                }}
                className={styles.button}
                size="small"
                width="auto"
              >
                Save
              </CallToActionButton>
              <CallToActionButton
                onClick={() => setEdit(false)}
                className={styles.button}
                color="regular"
                size="small"
                width="auto"
              >
                Cancel
              </CallToActionButton>
            </div>
          </>
        ) : (
          <>
            {note.author && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Link to={ProfileLocation(note.author.username || '')}>
                    <UserAvatar src={note.author.profilePictureURL} size={24} />
                  </Link>
                  <span
                    style={{
                      fontSize: 14,
                      color: '#62646A',
                      margin: 'auto 8px',
                    }}
                  >
                    {`${note.author.fullName + ' '} added on ${date}`}
                  </span>
                </div>
                <Icon
                  style={{ cursor: 'pointer', filter: 'grayscale(1)' }}
                  title={'Copy link'}
                  type={IconType.Link}
                  onClick={() => copyNoteLink(note.nid)}
                />
              </div>
            )}
            <pre
              className={styles.noteContent}
              dangerouslySetInnerHTML={{ __html: Autolinker.link(note.text) }}
            ></pre>
            {editable && (
              <div className={styles.cta}>
                <Icon
                  title={'Edit'}
                  type={IconType.EditGray}
                  onClick={() => setEdit(true)}
                />
                <div className={styles.smallDivider} />
                <Icon
                  title={'Delete'}
                  type={IconType.TrashRed}
                  onClick={props.onDelete}
                />
              </div>
            )}
          </>
        )}
      </Card>
      <LoadingIndicator loading={loading} />
    </div>
  );
};
