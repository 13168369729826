import React from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { AdminVettingProcessColumnProps } from '..';
import { TranscriptLocation } from '@src/locations';

const useStyles = createUseStyles({
  list: {
    margin: 0,
    padding: 0,
    '& li': {
      listStyle: 'none',
    },
  },
});

export const TranscriptUrls: React.FC<AdminVettingProcessColumnProps> = ({
  isAdminView,
  vettingProcess,
}) => {
  const styles = useStyles();

  if (!isAdminView) {
    return null;
  }

  return (
    <>
      {vettingProcess?.transcriptJobIds && (
        <ul className={styles.list}>
          {vettingProcess.transcriptJobIds?.map((jobId, idx) => {
            const recordingPageUrl = `${TranscriptLocation(jobId)}`;
            return (
              <li key={jobId}>
                <Link
                  to={{ pathname: recordingPageUrl }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Transcript link {idx + 1}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};
