import {
  AdminMissionApplicationObject,
  MissionApplicationReviewStatusNotSelectedLabels,
  MissionApplicationReviewStatusOpportunityToUpdateLabels,
  MissionApplicationReviewStatusOtherLabels,
  MissionApplicationReviewStatusWaitlistedLabels,
} from '@a_team/models/dist/MissionApplicationObject';
import { BulkMissionApplicationStatusUpdateItem } from '@ateams/api/dist/endpoints/Missions';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  section: {
    padding: '20px',
    backgroundColor: '#f6f8fa',
    borderRadius: '4px',
    margin: '10px 0',
  },
  header: {
    fontWeight: 'bold',
    marginBottom: '15px',
    maxWidth: '500px',
    marginTop: '0',
  },
  ulReset: {
    paddingLeft: '0',
  },
  listItem: {
    marginBottom: '12px',
    listStyleType: 'none',
  },
  subItem: {
    marginLeft: '20px',
  },
});

interface ItemsToUpdateSummaryProps {
  itemsToUpdate: BulkMissionApplicationStatusUpdateItem[];
  filteredApplications: AdminMissionApplicationObject[] | null | undefined;
}

const ItemsToUpdateSummary: React.FC<ItemsToUpdateSummaryProps> = ({
  itemsToUpdate,
  filteredApplications,
}) => {
  const styles = useStyles();

  const getApplication = (aid: string) => {
    return filteredApplications?.find((app) => app.aid === aid) || null;
  };

  const mapToLabel = (status: string[], labelMap: Record<string, string>) => {
    return status.map((s) => labelMap[s]).join(', ');
  };

  return (
    <section className={styles.section}>
      <h4 className={styles.header}>Summary of Unsaved Changes</h4>
      <ul className={styles.ulReset}>
        {itemsToUpdate.map((item, index) => {
          const application = getApplication(item.aid);
          const { reviewStatus = {} } = item;

          return (
            <li key={index} className={styles.listItem}>
              <strong>Applicant:</strong>{' '}
              {application?.user?.fullName || 'Unknown'}
              <div className={styles.subItem}>
                {!!reviewStatus.notSelected?.length && (
                  <div>
                    <strong>Not selected:</strong>{' '}
                    {mapToLabel(
                      reviewStatus.notSelected,
                      MissionApplicationReviewStatusNotSelectedLabels,
                    )}
                  </div>
                )}
                {!!reviewStatus.opportunityToUpdate?.length && (
                  <div>
                    <strong>Opportunity to update:</strong>{' '}
                    {mapToLabel(
                      reviewStatus.opportunityToUpdate,
                      MissionApplicationReviewStatusOpportunityToUpdateLabels,
                    )}
                  </div>
                )}
                {!!reviewStatus.other?.length && (
                  <div>
                    <strong>Other:</strong>
                    {mapToLabel(
                      reviewStatus.other,
                      MissionApplicationReviewStatusOtherLabels,
                    )}
                  </div>
                )}
                {!!reviewStatus.waitlisted?.length && (
                  <div>
                    <strong>Waitlisted:</strong>{' '}
                    {mapToLabel(
                      reviewStatus.waitlisted,
                      MissionApplicationReviewStatusWaitlistedLabels,
                    )}
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
      <small>
        Note: Once you save, these changes will be applied to the selected
        applications. Emails might be sent to the applicants.
      </small>
    </section>
  );
};

export default ItemsToUpdateSummary;
