import { useMemo } from 'react';
import { TimeEntry } from '../Table/TanstackTable';

export interface GroupedTimeByDayMapValue {
  totalRecordsAssociated: number;
  totalTime: number;
  totalFormattedTime: string;
}

const useGetGroupedTimeByDay = (
  timeEntries: TimeEntry[],
  isFullTimeRetainer?: boolean,
): Map<number, GroupedTimeByDayMapValue> => {
  const timeEntriesMap = useMemo(() => {
    const map = new Map<number, GroupedTimeByDayMapValue>();

    // If the role is a full-time retainer we don't need to show any grouped time
    if (isFullTimeRetainer) {
      return map;
    }

    timeEntries.forEach((entry) => {
      const { date, time } = entry;

      // Validate date and time, return early if invalid
      if (time === null || isNaN(date) || date <= 0 || time < 0) {
        return;
      }

      const existingEntry = map.get(date);
      if (existingEntry) {
        // Create a new object to maintain immutability
        map.set(date, {
          totalRecordsAssociated: existingEntry.totalRecordsAssociated + 1,
          totalTime: existingEntry.totalTime + time,
          totalFormattedTime: formatTime(existingEntry.totalTime + time),
        });
      } else {
        // Create a new entry and add it to the map
        map.set(date, {
          totalRecordsAssociated: 1,
          totalTime: time,
          totalFormattedTime: formatTime(time),
        });
      }
    });

    return map;
  }, [timeEntries, isFullTimeRetainer]);

  return timeEntriesMap;
};

export default useGetGroupedTimeByDay;

// Function to format time in minutes to a string representation
const formatTime = (timeInMinutes: number): string => {
  const hours = Math.floor(timeInMinutes / 60);
  const minutes = timeInMinutes % 60;

  if (hours === 0) {
    return `${minutes}m`;
  } else if (minutes === 0) {
    return `${hours}h`;
  } else {
    return `${hours}h ${minutes.toString().padStart(2, '0')}m`;
  }
};
