import React, { memo, ReactElement, useMemo } from 'react';
import { SidebarSection } from '@src/views/Profile/Sidebar/SidebarSection';
import { createUseStyles } from 'react-jss';
import InlineRows from '@src/components/InlineRows';
import { JobRow } from '@src/views/Profile/Sidebar/Jobs/JobRow';
import { ExistingJob, LocalJobs, NewJob } from '@src/stores/Profile/models';
import { propComparison } from '@src/helpers/propComparison';
import { ExperienceMemberRole } from '@a_team/models/dist/ExperienceObject';
import { UserId } from '@a_team/models/dist/UserObject';
import { getDateString } from './utils';

interface Props {
  jobs: LocalJobs;
  onChange(items: LocalJobs): void;
  onClickUpdate(job: NewJob | ExistingJob): void;
  onAdd(): void;
  readonly?: boolean;
  error?: boolean;
  disabled?: boolean;
  currentUserId: UserId;
  defaultRole?: string;
  ownerJobsOnly?: boolean;
}

const useStyles = createUseStyles({
  list: {
    paddingLeft: 20,
    marginTop: 0,
  },
  job: {
    fontSize: 17,
    color: '#222',
    fontWeight: 400,
    marginTop: 12,
    marginBottom: 16,
    lineHeight: '24px',
  },
  role: {
    color: '#62646A',
    fontWeight: 400,
  },
});

export const Jobs = memo((props: Props): ReactElement => {
  const styles = useStyles();
  const {
    jobs,
    onChange,
    onAdd,
    readonly = true,
    disabled,
    error,
    defaultRole,
    currentUserId,
    onClickUpdate,
    ownerJobsOnly = true,
  } = props;

  const jobsOwnedByCurrentUser = useMemo(() => {
    if (!ownerJobsOnly) return jobs;
    return jobs.filter((job) => {
      const jobOwner = job?.members.find(
        (member) => member.experienceRole === ExperienceMemberRole.Owner,
      );
      const isOwner =
        jobOwner && 'uid' in jobOwner && jobOwner.uid === currentUserId;

      return isOwner;
    });
  }, [jobs, currentUserId, ExperienceMemberRole, ownerJobsOnly]);

  const handleChange = (
    job: ExistingJob | NewJob,
    index: number,
    remove?: boolean,
  ) => {
    const updatedJobs = [...jobsOwnedByCurrentUser];
    if (remove) {
      updatedJobs.splice(index, 1);
    } else {
      updatedJobs[index] = job;
    }
    onChange(updatedJobs);
  };

  return (
    <SidebarSection
      title={'Jobs'}
      onAddClick={onAdd}
      readonly={readonly}
      disabled={disabled}
      error={error}
      addIconTestingId={'profile-job-add-button'}
    >
      <ul className={styles.list}>
        {readonly ? (
          jobsOwnedByCurrentUser.map((job) => {
            return (
              <li
                key={(job as ExistingJob).eid}
                className={styles.job}
                data-testing-id={'profile-job'}
              >
                {job.name}{' '}
                <span className={styles.role}>
                  {job.jobRole}
                  <small>{getDateString(job)}</small>
                </span>
              </li>
            );
          })
        ) : (
          <InlineRows
            items={jobsOwnedByCurrentUser}
            minLength={0}
            render={(key, item, index, nextIndex) => {
              return (
                <JobRow
                  key={key}
                  role={item ? item : undefined}
                  isOwner={true}
                  defaultRole={defaultRole}
                  disabled={false}
                  updateJob={(job, remove) =>
                    handleChange(job, nextIndex(), remove)
                  }
                  onUpdateClick={(job) => job && onClickUpdate(job)}
                />
              );
            }}
          />
        )}
      </ul>
    </SidebarSection>
  );
}, propComparison('onChange'));
