import React from 'react';
import { observer } from 'mobx-react';
import Modal, { ModalBasicProps } from '@src/components/Modal';
import SectionHeading from '@src/components/SectionHeading';
import { Button as CallToActionButton } from '@ateams/components';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    overflow: 'auto',
    padding: '20px 24px',
    display: 'flex',
    flexDirection: 'column',
  },
  actionsBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '32px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  button: {
    marginRight: '24px',
  },
});

export interface BuilderAssignmentWarningModalProps extends ModalBasicProps {
  onSelectAnother(): void;
  assignedBuilderName?: string;
  roleName?: string;
}

const BuilderAssignmentWarningModal: React.FC<
  BuilderAssignmentWarningModalProps
> = ({
  open,
  onClose,
  onSelectAnother,
  assignedBuilderName,
  roleName,
}: BuilderAssignmentWarningModalProps) => {
  const styles = useStyles();

  return (
    <Modal open={open} onClose={onClose} style={{ padding: 0, minWidth: 700 }}>
      <div className={styles.container}>
        <SectionHeading isFirst>Warning</SectionHeading>
        <p>
          <strong>{assignedBuilderName}</strong> has not applied for the{' '}
          <strong>{roleName}</strong> role for this mission. Are you sure you
          want to confirm them on this role?
        </p>
        <div className={styles.actionsBar}>
          <CallToActionButton
            size="small"
            color="regular"
            className={styles.button}
            onClick={onSelectAnother}
          >
            Select Another Builder
          </CallToActionButton>
          <CallToActionButton
            size="small"
            className={styles.button}
            onClick={onClose}
          >
            Confirm Builder
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
};

export default observer(BuilderAssignmentWarningModal);
