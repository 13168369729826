import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Modal } from '@a_team/ui-components';
import AspectRatioBox from '@src/components/AspectRatioBox';
import { useHistory } from 'react-router-dom';
import { DiscoveryLocation } from '@src/locations';
import useMediaQuery from '@src/hooks/useMediaQuery';
import { Breakpoints } from '@ateams/components';
import { CDN_BASE_URL } from '@src/config';
import Circle from './Circle';

interface ReleaseStepsModalProps {
  setIsWhatsNewModalOpen: (force?: unknown) => void;
  open: boolean;
  onClose: (force?: unknown) => void;
}

const useStyles = createUseStyles({
  modal: {
    // select child div and give it a with of 600
    width: '680px !important',
    '& > div': {
      padding: 0,
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
  },
  content: {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: 16,
  },
  img: {
    width: '100%',
    height: 'auto',
    borderRadius: '16px 16px 0px 0px',
  },
  btnsContainer: {
    marginTop: 30,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    justifyContent: 'space-between',
    width: '100%',
    // select all buttons and give them a width of fit-content
    '& > button': {
      width: 'fit-content',
    },
  },
  nextBtn: {
    justifySelf: 'flex-end',
    background: '#6D00D7 !important',
  },
  circleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ctaBtn: {
    justifySelf: 'flex-end',
  },
});

const images = {
  desktop: {
    stepOne: `${CDN_BASE_URL}/website/connection-release-b.jpg`,
    stepTwo: `${CDN_BASE_URL}/website/connection-release-a.jpg`,
    stepThree: `${CDN_BASE_URL}/website/3-connection-why.jpg`,
  },
  mobile: {
    stepOne: `${CDN_BASE_URL}/website/connection-release-b-mobile.jpg`,
    stepTwo: `${CDN_BASE_URL}/website/connection-release-a-mobile.jpg`,
    stepThree: `${CDN_BASE_URL}/website/3-m-connection-why.jpg`,
  },
};

enum StepsEnum {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

const ReleaseStepsModal = ({
  setIsWhatsNewModalOpen,
  open,
  onClose,
}: ReleaseStepsModalProps): JSX.Element => {
  const styles = useStyles();
  const [step, setStep] = useState<StepsEnum>(StepsEnum.ONE);
  const isDesktop = useMediaQuery(`(min-width: ${Breakpoints.md}px)`);
  const history = useHistory();
  const stepsArray: StepsEnum[] = [
    StepsEnum.ONE,
    StepsEnum.TWO,
    StepsEnum.THREE,
  ];

  const image = useMemo(() => {
    if (step === StepsEnum.ONE) {
      return isDesktop ? images.desktop.stepOne : images.mobile.stepOne;
    }
    if (step === StepsEnum.TWO) {
      return isDesktop ? images.desktop.stepTwo : images.mobile.stepTwo;
    }
    return isDesktop ? images.desktop.stepThree : images.mobile.stepThree;
  }, [step, isDesktop]);

  const handleLastStep = () => {
    history.push(DiscoveryLocation);
    onClose(false);
  };

  return (
    <Modal
      variant="slideUp"
      className={styles.modal}
      isOpen={open}
      onClose={() => onClose(false)}
    >
      <div>
        <div>
          <AspectRatioBox aspectRatio={isDesktop ? 17 / 6 : 13 / 8}>
            <img className={styles.img} src={image} alt="step" />
          </AspectRatioBox>
        </div>
        {step === 1 && (
          <div className={styles.content}>
            <div className={styles.title}>What are connections?</div>
            <div>
              Connect with builders you've enjoyed working with in the past, and
              those you’d like to work with in the future to indicate your
              shared interest in teaming up on missions.
              <br></br>
              <br></br>
              Adding shared work experience shows companies that you have a
              track record of working as a team — increasing your chances of
              landing missions together.
            </div>
            <div className={styles.btnsContainer}>
              <Button
                size="md"
                variant="secondary"
                onClick={() => {
                  setIsWhatsNewModalOpen(true);
                  onClose();
                }}
              >
                Back
              </Button>
              <div className={styles.circleContainer}>
                {stepsArray.map((s) => (
                  <Circle
                    key={s}
                    id={s}
                    onSelect={() => setStep(s)}
                    selectedId={step}
                  />
                ))}
              </div>
              <Button
                className={styles.nextBtn}
                size="md"
                onClick={() => setStep(StepsEnum.TWO)}
              >
                Next
              </Button>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className={styles.content}>
            <div className={styles.title}>How do I get started?</div>
            <div>
              You can either visit a builder’s profile and select 'Connect', or
              explore the Discovery page to find and connect with potential
              teammates.
            </div>
            <div className={styles.btnsContainer}>
              <Button
                size="md"
                variant="secondary"
                onClick={() => setStep(StepsEnum.ONE)}
              >
                Back
              </Button>
              <div className={styles.circleContainer}>
                {stepsArray.map((s) => (
                  <Circle
                    key={s}
                    id={s}
                    onSelect={() => setStep(s)}
                    selectedId={step}
                  />
                ))}
              </div>
              <Button
                className={styles.nextBtn}
                size="md"
                onClick={() => setStep(StepsEnum.THREE)}
              >
                Next
              </Button>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className={styles.content}>
            <div className={styles.title}>Why should I connect?</div>
            <div>
              Connections isn't just for networking; it's your fast track to
              landing more missions.
              <br></br>
              <br></br>
              We prioritize forming teams of builders that are the best fit for
              the mission and have either worked together before or expressed a
              desire to collaborate in the future. As you connect, you boost
              your chances of landing roles with your connections.
            </div>
            <div className={styles.btnsContainer}>
              <Button
                size="md"
                variant="secondary"
                onClick={() => setStep(StepsEnum.TWO)}
              >
                Back
              </Button>
              <div className={styles.circleContainer}>
                {stepsArray.map((s) => (
                  <Circle
                    key={s}
                    id={s}
                    onSelect={() => setStep(s)}
                    selectedId={step}
                  />
                ))}
              </div>
              <Button
                className={styles.ctaBtn}
                size="md"
                onClick={handleLastStep}
              >
                {isDesktop ? 'Start connecting' : 'Discover'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ReleaseStepsModal;
