import React from 'react';
import { createUseStyles } from 'react-jss';
import { OnPageClick, PageSelect } from './page-select';
import { OnPageSizeChange, PageSizeSelect } from './page-size-select';
import { RecordsCount } from './records-count';

export interface PaginationProps {
  /** Page that is currently displayed */
  currentPage: number;

  /** Total number of pages */
  pagesCount: number;

  /** Show record indexes being displayed: 'x-y of z' */
  showRecordsCount?: boolean;

  /** Total number of records in all pages */
  recordsCount?: number;

  /** Maximum number of records per page */
  pageBatchSize: number;
  pageSizeOptions: number[] | undefined;
  onPageClick: OnPageClick;
  onPageBatchSizeChange: OnPageSizeChange | undefined;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px 0',
  },
  separation: {
    width: '30px',
  },
});

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  pagesCount,
  recordsCount,
  showRecordsCount = true,
  pageBatchSize,
  pageSizeOptions,
  onPageClick,
  onPageBatchSizeChange,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <PageSelect
        currentPage={currentPage}
        pagesCount={pagesCount}
        onPageClick={onPageClick}
      />

      {showRecordsCount && recordsCount && (
        <>
          <span className={styles.separation} />

          <RecordsCount
            recordsCount={recordsCount}
            pageBatchSize={pageBatchSize}
            currentPage={currentPage}
          />
        </>
      )}

      <span className={styles.separation} />

      {pageSizeOptions && onPageBatchSizeChange && (
        <PageSizeSelect
          value={pageBatchSize}
          onChange={onPageBatchSizeChange}
          pageSizeOptions={pageSizeOptions}
        />
      )}
    </div>
  );
};
