import { ReminderPeriod } from '@a_team/models/dist/AvailabilityObject';
import { differenceInMonths } from 'date-fns';

export const getReminderPeriod = (
  startDate: Date,
  endDate: Date,
): ReminderPeriod => {
  const months = differenceInMonths(endDate, startDate);

  switch (months) {
    case 0:
    case 1:
      return ReminderPeriod.OneMonth;
    case 2:
    case 3:
      return ReminderPeriod.ThreeMonths;
    case 5:
    case 6:
      return ReminderPeriod.SixMonths;
    default:
      return ReminderPeriod.OneMonth;
  }
};
