import { Select } from '@a_team/ui-components';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { FormData } from '.';

interface RoleMultiSelectProps {
  register: UseFormRegister<FormData>;
  setValue: UseFormSetValue<FormData>;
  trigger: UseFormTrigger<FormData>;
  roles: string[];
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
  },
});

const RoleMultiSelect = ({
  register,
  setValue,
  trigger,
  roles,
}: RoleMultiSelectProps): JSX.Element => {
  const styles = useStyles();
  const { auth, roleCategories } = useStores();

  useEffect(() => {
    roleCategories.load(auth).catch((err) => console.error(err));
  }, []);

  const options = useMemo(() => {
    return roleCategories?.categories?.map((role) => {
      return { value: role.title, label: role.title };
    });
  }, [roleCategories.categories]);

  return (
    <div className={styles.container}>
      <Select
        alwaysShowCounter={true}
        isMulti={true}
        styles={{
          control: (base) => ({
            ...base,
            border: '1px solid #DADADC',
          }),
          placeholder: (base) => ({
            ...base,

            '& span': {
              display: roles?.length === 0 ? 'none' : 'inline',
              backgroundColor: '#6D00D7 !important',
              borderRadius: '8px !important',
            },
          }),
        }}
        {...register('roles')}
        value={roles?.map((role) => ({ value: role, label: role }))}
        placeholder={
          roles?.length === 0
            ? 'Select role'
            : roles?.length === 1
            ? `Selected role`
            : `Selected roles`
        }
        onChange={(value) => {
          const newValues = value as { value: string; label: string }[];
          const selectedRoles = newValues.map((option) => option.value);

          setValue('roles', selectedRoles);
          trigger('roles');
        }}
        options={options}
      />
    </div>
  );
};

export default observer(RoleMultiSelect);
