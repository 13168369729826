import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { VettingFeedbackFormViewModeData } from '@ateams/api/dist/endpoints/vetting-process-feedback';
import { useStores } from '@src/stores';
import { VettingDashboardLocation } from '@src/locations';
import MainLayout from '@src/layouts/Main';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import { VettingFeedbackFormViewMode } from './form';
import { useStyles } from '..';

const useViewModeStyles = createUseStyles({
  content: {
    paddingTop: 0,
  },
});

/**
 * Figma design {@link https://www.figma.com/file/fiIpGWCIUv1WVZWVn7wwP6/Admin-side%3A-Vetting?node-id=3119-1268&t=hxGxUBTgoAM3Mrrc-0}
 */
const VettingFeedbackFormViewModeView = () => {
  const styles = useStyles();
  const viewModeStyles = useViewModeStyles();
  const { vettingProcesses } = useStores();
  const { vpid } = useParams<{
    vpid: string;
  }>();
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState<VettingFeedbackFormViewModeData>();
  const [loading, setLoading] = useLoadingState();

  useEffect(() => {
    setLoading(
      (async () => {
        const data = await vettingProcesses.getFeedbackFormViewModeData(vpid);

        setData(data);
      })(),
      'Feedback Form Loaded',
    );
  }, []);

  /**
   * We want to go to the previous page
   * Since the form is accesible through a URL, if there is no page back we want to just go
   * to the Selection Team page
   */
  const onClose = () => {
    /**
     * "location.key" ? Nice going Javascript 😅
     * {@link https://stackoverflow.com/a/70532858}
     * */
    const canGoBack = typeof location.key !== 'undefined';

    if (canGoBack) {
      history.goBack();
    } else {
      history.push(VettingDashboardLocation);
    }
  };

  return (
    <MainLayout
      title="Vetting Feedback Form"
      contentClassName={cx(styles.content, viewModeStyles.content)}
    >
      {data && (
        <VettingFeedbackFormViewMode
          data={data}
          vettingProcessId={vpid}
          onClose={onClose}
        />
      )}

      <LoadingIndicator loading={loading} />
    </MainLayout>
  );
};

export default observer(VettingFeedbackFormViewModeView);
