import {
  BuilderSuggestionType,
  SuggestionActionType,
} from '@a_team/models/dist/ProfileEnhancements';
import { UserId } from '@a_team/models/dist/UserObject';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import {
  useApplyProfileSuggestion,
  useGetAdminProfileSuggestions,
  useGetProfileSuggestions,
  useRejectProfileSuggestion,
} from '@src/rq/profileSuggestions';
import { ProfileViewMode } from '@src/stores/Profile/models';
import Profile from '@src/stores/Profile/Profile';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Suggestions from '../Suggestions';

const useStyles = createUseStyles({
  container: {
    marginBottom: 24,
  },
});

interface SuggestedPhoneNumberProps {
  profile: Profile;
  userId: UserId;
  adminOnly?: boolean;
}
const SuggestedPhoneNumber = ({
  profile,
  userId,
  adminOnly,
}: SuggestedPhoneNumberProps) => {
  const [phoneNumberModalOpen, setPhoneNumberModalOpen] = useState(false);
  const { data } = useGetProfileSuggestions();
  const { data: adminData } = useGetAdminProfileSuggestions();
  const { mutateAsync: applySuggestedPhoneNumber } = useApplyProfileSuggestion({
    shouldRefreshProfile: true,
  });
  const { mutateAsync: rejectSuggestion } = useRejectProfileSuggestion();
  const analytics = useAnalytics();
  const styles = useStyles();

  const toggleSkillsModalOpen = () => {
    setPhoneNumberModalOpen(!phoneNumberModalOpen);
  };

  const rawSuggestions = adminOnly ? adminData : data;

  const phoneNumber = rawSuggestions?.phoneNumber;

  const phoneNumbers = phoneNumber?.phoneNumber
    ? [
        {
          suggestionId: rawSuggestions?.phoneNumber?.sid,
          partialSuggestionId: rawSuggestions?.phoneNumber?.sid,
          label: phoneNumber.phoneNumber,
          type: BuilderSuggestionType.PHONE_NUMBER,
        },
      ]
    : [];

  const onDiscard = async () => {
    try {
      if (rawSuggestions?.phoneNumber) {
        await rejectSuggestion(rawSuggestions.phoneNumber.phoneNumber);
        analytics.trackProfileSuggestionInteraction(
          userId,
          rawSuggestions.phoneNumber.sid,
          SuggestionActionType.REJECT,
          BuilderSuggestionType.PHONE_NUMBER,
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAccept = async () => {
    try {
      if (rawSuggestions?.phoneNumber?.sid) {
        const phoneNumber = rawSuggestions.phoneNumber.phoneNumber;

        if (!phoneNumber) {
          return;
        }

        await applySuggestedPhoneNumber({
          sid: rawSuggestions.phoneNumber.sid,
        });

        analytics.trackProfileSuggestionInteraction(
          userId,
          rawSuggestions.phoneNumber.sid,
          SuggestionActionType.ACCEPT,
          BuilderSuggestionType.PHONE_NUMBER,
          [phoneNumber],
        );
        profile.refreshProfile(ProfileViewMode.View);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.container}>
      <Suggestions
        onDiscard={onDiscard}
        onAccept={handleAccept}
        title={'Suggested Phone Number'}
        onClick={toggleSkillsModalOpen}
        userId={userId}
        suggestions={phoneNumbers ?? []}
        adminOnly={adminOnly}
      />
    </div>
  );
};

export default SuggestedPhoneNumber;
