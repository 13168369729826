import { ServiceAuth } from '@ateams/service-utils';
import { ServiceEndpoint } from './utils';
import { ClientBillingInfo } from '@a_team/models/dist/BillingInfo';
import { ClientCompanyId } from '@a_team/models/src/ClientCompanies';

export const BasePath = '/client-companies';

export default class ClientCompaniesEndpoint extends ServiceEndpoint {
  public getBillingInfo(
    auth: ServiceAuth,
    id: ClientCompanyId,
  ): Promise<ClientBillingInfo | null> {
    return this.fetch(auth, `${BasePath}/${id}/billing-info`);
  }

  public setBillingInfo(
    auth: ServiceAuth,
    id: ClientCompanyId,
    data: ClientBillingInfo,
  ): Promise<void> {
    return this.fetch(
      auth,
      `${BasePath}/${id}/billing-info`,
      null,
      'put',
      data,
    );
  }
}
