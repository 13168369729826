import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    borderBottom: '1px solid #ddd',
    padding: 12,
    listStyleType: 'none',
    '&:last-child': {
      border: 0,
    },
  },
});

const ListItem: React.FC = ({ children }) => {
  const styles = useStyles();
  return <li className={styles.container}>{children}</li>;
};

export default ListItem;
