import {
  MissionCardObject,
  RecommendedMission,
} from '@a_team/models/dist/MissionObject';
import {
  BasicMissionRole,
  MissionRoleStatus,
  MissionRoleVisibilityStatus,
  RecommendedRole,
} from '@a_team/models/dist/MissionRole';
import { TalentProfile } from '@a_team/models/dist/TalentCategories';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import { joinWithCommasAnd } from '@src/logic/utils';

const MAX_MEMBERS_AVATARS = 5;

export function getVisibleRoles(
  roles: BasicMissionRole[] | RecommendedRole[],
  isAdmin: boolean,
) {
  return roles.filter(
    (role) =>
      role.status === MissionRoleStatus.Open &&
      (isAdmin ||
        !role.visibility?.visibilityStatus ||
        role.visibility?.visibilityStatus === MissionRoleVisibilityStatus.All),
  );
}

export function getAllAdditionalTalentCategoryIds(
  category: TalentProfile | undefined,
) {
  return (
    category?.talentSpecializations?.additionalTalentSpecializations || []
  )
    .map((spec) => spec.talentCategoryIds)
    .flat();
}

export function groupAndPrefix(arr: string[]): string[] {
  // Step 1: Create a frequency map
  const frequencyMap = arr.reduce<{ [key: string]: number }>((acc, curr) => {
    acc[curr] = (acc[curr] || 0) + 1;
    return acc;
  }, {});

  // Step 2: Construct the desired output
  const result: string[] = [];
  for (const key in frequencyMap) {
    if (frequencyMap[key] > 1) {
      result.push(frequencyMap[key] + 'x ' + key);
    } else {
      result.push(key);
    }
  }

  return result;
}

export function isValidSecondaryRole(
  openRole: BasicMissionRole,
  primaryMatchedRole: BasicMissionRole | undefined,
  userAdditionalTalentCategoryIds: string[],
): boolean {
  if (primaryMatchedRole?.category.cid === openRole.category.cid) {
    return false;
  }

  const openRoleTalentCategoryIds = openRole.category.talentCategoryIds;

  // Skip if either list is empty
  if (
    !userAdditionalTalentCategoryIds.length ||
    !openRoleTalentCategoryIds.length
  ) {
    return false;
  }

  // Check if this is a valid secondary role
  return userAdditionalTalentCategoryIds.some((categoryId: string) =>
    openRoleTalentCategoryIds.includes(categoryId),
  );
}

export function getFirstRoleToHighlight(openRoles: BasicMissionRole[]) {
  const nonMatchedRolesToHighligh: string[] = [];

  const firsOpenRole = openRoles[0].category.cid;

  for (const openRole of openRoles) {
    if (firsOpenRole === openRole.category.cid) {
      nonMatchedRolesToHighligh.push(openRoles[0]?.category.title);
    }
  }

  return nonMatchedRolesToHighligh;
}

export function getUsersToDisplay(
  mission: MissionCardObject | RecommendedMission,
) {
  const connectionsApplied =
    mission?.missionConnections?.connectionsApplied ?? [];
  if (connectionsApplied.length > 0) {
    return connectionsApplied;
  }

  const connectionsAssigned =
    mission?.missionConnections?.connectionsAssigned ?? [];
  if (connectionsAssigned.length > 0) {
    return connectionsAssigned;
  }

  const usersAssigned =
    mission?.roles &&
    (mission.roles
      .filter((role) => {
        const user = role.user;
        if (!user) return false;
        if (role.status !== MissionRoleStatus.Active) return false;

        return true;
      })
      .map((role) => role.user)
      .slice(0, MAX_MEMBERS_AVATARS) as BasicUserObject[]);

  if (usersAssigned && usersAssigned?.length > 0) {
    return usersAssigned;
  }

  return [];
}

export function getDescription(
  mission: MissionCardObject | RecommendedMission,
) {
  const missionConnections = mission?.missionConnections;
  if (missionConnections && missionConnections.connectionsApplied.length > 0) {
    const names = missionConnections.connectionsApplied.map(
      (user) => user.firstName,
    );
    return joinWithCommasAnd(names) + ' applied to this mission';
  }

  if (missionConnections && missionConnections.connectionsAssigned.length > 0) {
    return `${
      missionConnections.connectionsAssigned.length === 1
        ? '1 connections is'
        : `${missionConnections.connectionsAssigned.length} connections are`
    } on this mission`;
  }

  return null;
}
