import { Select, SelectOption } from '@ateams/components';
import { DateISOString } from '@a_team/models/dist/misc';
import cx from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { getYears, monthNames } from './consts';

interface DatePickerSelectProps {
  date?: string | Date;
  disabled?: boolean;
  className?: string;
  onChange: (arg: {
    day: number;
    month: number;
    year: number;
    date: DateISOString;
  }) => void;
  yearsBack?: number;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    gap: '8px',
    width: 'auto',
  },
  yearSelect: {
    width: '90px',
  },
  monthSelect: {
    width: '132px',
  },
});

// monthOptions
const monthOptions: SelectOption[] = monthNames.map((month, index) => ({
  value: `${index}`,
  label: month,
}));

const yearOptions = (yearsBack?: number): SelectOption[] => {
  const yaers = yearsBack ? getYears(yearsBack) : getYears();
  return yaers.map((year) => ({
    value: `${year}`,
    label: `${year}`,
  }));
};

const DatePickerSelect = ({
  className,
  onChange,
  date,
  disabled,
  yearsBack,
}: DatePickerSelectProps): JSX.Element => {
  const styles = useStyles();

  const givenDate = date ? new Date(date) : new Date();
  const yearsOptions = yearOptions(yearsBack);

  const [selectedMonth, setSelectedMonth] = React.useState<SelectOption>({
    label: monthNames[givenDate.getMonth()],
    value: givenDate.getMonth().toString(),
  });

  const [selectedYear, setSelectedYear] = React.useState<SelectOption>({
    label: givenDate.getFullYear().toString(),
    value: givenDate.getFullYear().toString(),
  });

  const onMonthChange = (month: string) => {
    const monthNumber = parseInt(month);
    setSelectedMonth({
      label: monthNames[monthNumber],
      value: `${month}`,
    });
    const year = parseInt(selectedYear.value);
    onChange({
      day: 1,
      month: monthNumber + 1,
      year,
      date: new Date(year, monthNumber, 1).toISOString(),
    });
  };

  const onYearChange = (year: string) => {
    setSelectedYear({
      label: `${year}`,
      value: `${year}`,
    });
    const month = selectedMonth.value as unknown as number;
    const yearNumber = parseInt(year);
    onChange({
      day: 1,
      month: month,
      year: yearNumber,
      date: new Date(yearNumber, month, 1).toISOString(),
    });
  };

  return (
    <div className={styles.container}>
      <Select
        isDisabled={disabled}
        className={cx(className, styles.monthSelect)}
        value={selectedMonth}
        options={monthOptions}
        onChange={(e) => {
          if (typeof e?.value === 'string') {
            onMonthChange(e.value);
          }
        }}
        placeholder={'Select a specialization...'}
      />
      <Select
        isDisabled={disabled}
        className={cx(className, styles.yearSelect)}
        value={selectedYear}
        options={yearsOptions}
        onChange={(e) => {
          if (typeof e?.value === 'string') {
            onYearChange(e.value);
          }
        }}
        placeholder={'Select a specialization...'}
      />
    </div>
  );
};

export default DatePickerSelect;
