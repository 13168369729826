import React, { ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  type: string;
  children?: ReactNode;
}

const useStyles = createUseStyles({
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    marginTop: 12,
  },
  item: {
    display: 'flex',
    lineHeight: '24px',
  },
  num: {
    display: 'block',
    fontWeight: 500,
    width: 12,
    marginRight: 8,
  },
  note: {
    marginTop: '24px',
    background: '#EFEFF0',
    borderRadius: '8px',
    padding: '16px',
  },
});

export const ExpertiseLegend = (props: Props): ReactElement => {
  const styles = useStyles();
  return (
    <div>
      Tell us how familiar you are with these {props.type.toLowerCase()}, 1-5
      meaning:
      <ul className={styles.list}>
        <li className={styles.item}>
          <span className={styles.num}>1</span>I don't have any experience.
        </li>
        <li className={styles.item}>
          <span className={styles.num}>2</span>I have light experience, and I
          can ramp up quickly.
        </li>
        <li className={styles.item}>
          <span className={styles.num}>3</span>I have decent experience, but I'm
          not an expert.
        </li>
        <li className={styles.item}>
          <span className={styles.num}>4</span>I have substantial experience,
          and I’m proficient.
        </li>
        <li className={styles.item}>
          <span className={styles.num}>5</span>I have extensive experience, and
          deep expertise.
        </li>
      </ul>
      <div className={styles.note}>
        Note that companies only tend to consider builders with 3 or above when
        it comes to required skills on a mission.
      </div>
      {props.children && props.children}
    </div>
  );
};
