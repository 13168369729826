import { IconType } from '@ateams/components';
import { NarrativeItemType } from './NarrativeItem';

export const narrativeDetailsForType: Record<
  NarrativeItemType,
  { icon: IconType; text: string }
> = {
  missions: {
    icon: IconType.ATeamGray,
    text: 'Worked together at A.Team',
  },
  projects: {
    icon: IconType.WorkConnection,
    text: 'Worked together on a project',
  },
  companies: {
    icon: IconType.WorkTogether,
    text: 'Worked together in a company',
  },
  missionRecommendations: {
    icon: IconType.CollaboratorBlack,
    text: 'Requested to team up',
  },
  invitations: {
    icon: IconType.Mail,
    text: 'Referred by',
  },
};
