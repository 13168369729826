import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  UpdateSubscriptionAction,
  useMutationUpdateEmailCategorySubscription,
} from '@src/rq/emailPreferences';
import { EmailSubscriptionChangeDtoCategoryEnum } from '@a_team/user-notification-service-js-sdk';
import { createUseStyles } from 'react-jss';
import {
  Button,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from '@ateams/components';
import unsubscribeIcon from '@src/assets/svgs/unsubscribe.svg';
import subscribeIcon from '@src/assets/svgs/subscribe.svg';
import { Link, useLocation } from 'react-router-dom';
import { getEmailCategoryLabel } from './emailCategoryLabels';
import { EmailSubscribeLocation } from '@src/locations';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    '& h1': {
      fontSize: FontSizes.large,
      fontWeight: FontWeights.regular,
      marginBottom: 0,
    },
    '& p': {
      color: Colors.regularDark,
    },
  },
  content: {
    marginTop: '100px',
    width: '400px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  separator: {
    width: 50,
    height: 1,
    backgroundColor: Colors.regular,
    marginTop: Spacing.large,
    marginBottom: Spacing.large,
  },
  button: {
    marginTop: Spacing.medium,
  },
  buttonLink: {
    background: 'none',
    border: 'none',
    color: Colors.primary,
    cursor: 'pointer',
    padding: 0,
  },
  support: {
    marginBottom: 20,
  },
  noMargin: {
    margin: 0,
  },
});

const QUERY_PARAM_TOKEN = 'token';
const QUERY_PARAM_CATEGORY = 'category';

const EmailUnsubscribeView = () => {
  const styles = useStyles();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get(QUERY_PARAM_TOKEN) as string;
  const category = query.get(
    QUERY_PARAM_CATEGORY,
  ) as EmailSubscriptionChangeDtoCategoryEnum;
  const { mutate, isError } = useMutationUpdateEmailCategorySubscription();
  const isSubscribe = location.pathname === EmailSubscribeLocation;
  const [isResubscribed, setIsResubscribed] = useState(isSubscribe);

  useEffect(() => {
    if (isSubscribe) {
      mutate({ token, category, action: UpdateSubscriptionAction.SUBSCRIBE });
    }
  }, [isSubscribe]);

  const linkMalformed = !token || !category;

  useEffect(() => {
    if (linkMalformed || isSubscribe) {
      return;
    }
    mutate({ token, category, action: UpdateSubscriptionAction.UNSUBSCRIBE });
  }, []);

  const onClickOnResubscribe = () => {
    if (linkMalformed) {
      return;
    }
    mutate(
      { token, category, action: UpdateSubscriptionAction.SUBSCRIBE },
      {
        onSuccess: () => setIsResubscribed(true),
      },
    );
  };

  if (linkMalformed || isError) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <h1>Oops, there was an error</h1>
          <p>
            We couldn't unsubscribe you due to an error. Please try again or{' '}
            <a href="mailto:support@a.team">contact support</a>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          src={isResubscribed ? subscribeIcon : unsubscribeIcon}
          alt="Unsubscribe"
        />
        {isResubscribed ? (
          <h1>You have subscribed again</h1>
        ) : (
          <h1>You have unsubscribed</h1>
        )}
        {isResubscribed ? (
          <p>
            You have been successfully subscribed to{' '}
            {getEmailCategoryLabel(category)} from A.Team.
          </p>
        ) : (
          <p>
            You have been successfully unsubscribed from{' '}
            {getEmailCategoryLabel(category)}, and you won't receive these
            emails again.
          </p>
        )}
        <Link to="/settings?menu=notifications">
          <Button
            className={styles.button}
            color="regularLight"
            width="auto"
            squared
            size="small"
          >
            Manage other email preferences
          </Button>
        </Link>

        {!isResubscribed ? (
          <>
            <div className={styles.separator} />
            <p className={styles.noMargin}>
              Second thoughts?{' '}
              <button
                className={styles.buttonLink}
                onClick={() => onClickOnResubscribe()}
              >
                Resubscribe
              </button>
            </p>
          </>
        ) : null}
      </div>
      <div className={styles.support}>
        <p>
          Having trouble? <a href="mailto:support@a.team">Contact support</a>
        </p>
      </div>
    </div>
  );
};

export default observer(EmailUnsubscribeView);
