import React from 'react';
import { AdminVettingProcessColumnProps } from '..';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import { createUseStyles } from 'react-jss';
import { UserOptionType, UserSelector } from '@src/components/UserSelector';
import LoadingIndicator from '@src/components/LoadingIndicator';

const useStyles = createUseStyles({
  container: {
    minWidth: '280px',
  },
});

export const ContactOwnerField: React.FC<AdminVettingProcessColumnProps> = ({
  vettingProcess,
}) => {
  const { vettingProcesses } = useStores();
  const styles = useStyles();
  const [loading, setLoading] = useLoadingState();

  const onUserSelect = async (
    option: UserOptionType | UserOptionType[] | null,
  ) => {
    const contactOwner =
      (option && !Array.isArray(option) && option.user?.uid) || null;

    setLoading(
      (async () => {
        await vettingProcesses.patchVettingProcess({
          id: vettingProcess.id,
          contactOwner,
        });
      })(),
      'Contact owner set successfully',
    );
  };

  return (
    <div className={styles.container}>
      <UserSelector
        placeholder={'Search User...'}
        defaultValue={vettingProcess.contactOwner}
        onUserSelect={onUserSelect}
        isDisabled={loading === true}
        useRegularSelectInput
        menuPortalTarget={document.body}
      />
      <LoadingIndicator loading={loading} />
    </div>
  );
};
