import React, { useContext, useState } from 'react';
import cx from 'classnames';
import { Button } from '@a_team/ui-components';
import { useCommonStyles } from '../commonStyles';
import { DefaultOption } from './OptionsGroup/DefaultOption';
import { NoCalendarReason } from '@a_team/models/dist/UserObject';
import { ReasonsModalContext } from './context';

interface ReasonsProps {
  onSelectReason: (reason?: NoCalendarReason, reasonText?: string) => void;
  onClose: () => void;
}

export const Reasons = (props: ReasonsProps) => {
  const styles = useCommonStyles();
  const [reason, setReason] = useState<NoCalendarReason>();
  const { canClose } = useContext(ReasonsModalContext);

  const handleContinue = () => {
    props.onSelectReason(reason, '');
  };

  return (
    <>
      <div className={styles.hero}>
        <h4 className={styles.modalTitle}>Tell us why you can’t connect?</h4>
        <p className={cx(styles.heroCopy, 'lessPadding')}>
          Get support setting up your calendar to enable instant scheduling.
        </p>
      </div>
      <div className={styles.optionsWrap}>
        <DefaultOption
          option={NoCalendarReason.NonSupportedCalendar}
          label={`I don't use a Google, Apple or Outlook Calendar`}
          checked={reason === NoCalendarReason.NonSupportedCalendar}
          onClick={setReason}
        />
        <DefaultOption
          option={NoCalendarReason.PrivacyConcern}
          label={`I have privacy concerns`}
          checked={reason === NoCalendarReason.PrivacyConcern}
          onClick={setReason}
        />
        <DefaultOption
          option={NoCalendarReason.TechIssue}
          label={`I am having technical difficulties`}
          checked={reason === NoCalendarReason.TechIssue}
          onClick={setReason}
        />
        <DefaultOption
          option={NoCalendarReason.MultipleCalendars}
          label={`I use multiple calendars`}
          checked={reason === NoCalendarReason.MultipleCalendars}
          onClick={setReason}
        />
      </div>
      <div className={styles.actions}>
        {canClose && (
          <Button
            variant="secondary"
            size="sm"
            className={styles.closeBtn}
            onClick={props.onClose}
          >
            Close
          </Button>
        )}
        <Button
          disabled={!reason}
          variant="main"
          size="sm"
          className={styles.primaryButton}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </div>
    </>
  );
};
