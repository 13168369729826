import React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import { theme } from '@a_team/ui-components';
import {
  OutlinedDateInput,
  OutlinedDateInputProps,
  OnDateChange as DefaultOnDateChange,
} from '@src/components/OutlinedDateInput';
import { InputContainer, InputContainerProps } from './input-container';
import { Size, Text } from './typography';

export interface DateInputProps
  extends OutlinedDateInputProps,
    InputContainerProps {
  isReadOnly?: boolean;
}

const useDateInputStyles = createUseStyles({
  root: {
    cursor: ({ isDisabled }: OutlinedDateInputProps) =>
      isDisabled ? 'not-allowed' : 'pointer',
  },
  control: {
    border: 'none',
    padding: '8px 10px',
    height: '32px',
    background: theme.colors.Grey[200],
    borderRadius: '4px',
  },
  input: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.colors.Grey[800],
    cursor: ({ isDisabled }: OutlinedDateInputProps) =>
      isDisabled ? 'not-allowed' : 'pointer',
  },
});

export const DEFAULT_DATE_FORMAT = 'LLLL d, yyyy';

export const DateInput: React.FC<DateInputProps> = (props) => {
  const { error, label, isReadOnly, ...rest } = props;
  const styles = useDateInputStyles(props);

  return (
    <InputContainer label={label} error={error}>
      {isReadOnly ? (
        <Text size={Size.Small}>
          {props.selected ? format(props.selected, DEFAULT_DATE_FORMAT) : '--'}
        </Text>
      ) : (
        <OutlinedDateInput
          {...rest}
          className={cx(styles.root, props.className)}
          controlClassName={cx(styles.control, props.controlClassName)}
          inputClassName={cx(styles.input, props.inputClassName)}
          datePickerProps={{ dateFormat: DEFAULT_DATE_FORMAT }}
        />
      )}
    </InputContainer>
  );
};

export type OnDateChange = DefaultOnDateChange;
