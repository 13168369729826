import { TimesheetRecordType } from '@a_team/models/dist/TimesheetObject';
import {
  BorderColors,
  Colors,
  Select,
  SelectOption,
  SelectProps,
} from '@ateams/components';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { getLocalTime } from '@src/helpers/time';
import useLoadingState from '@src/hooks/useLoadingState';
import { queryKeys } from '@src/rq/keys';
import { useMutationSetRecord } from '@src/rq/timesheets';
import { useStores } from '@src/stores';
import { useQueryClient } from '@tanstack/react-query';
import cx from 'classnames';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import useGetMonthlyRetainerDataForCurrentUserRole from '../../hooks/useGetMonthlyRetainerDataForCurrentUserRole';
import InitiativeSelect from '../InitiativeSelect';
import TextInput from '../TextInput';
import TimeInput from '../TimeInput';
import TypeSelect from '../TypeSelect';
import EmptyCell from '../common/EmptyCell';
import { useCommonStyles } from '../common/commonStyles';
import { outOfOfficeTypes } from '../data';
import { TIMESHEET_TASK_MIN_LENGTH, generateUniqueKey } from '../utils';
import sendIcon from './sendIcon.svg';
import TimeTooltip from './TimeTooltip';
import InitiativesTooltip from './InitiativesTooltip';
import { MissionPaymentCycleId } from '@a_team/models/dist/MissionPaymentCycleObject';
import { DataGridNav } from '@table-nav/core';

interface AddRecordProps {
  sid: string;
  updateSelectedCycle?: (id: MissionPaymentCycleId) => void;
}

const useStyles = createUseStyles({
  container: {
    minWidth: 1239,
    margin: '24px 0',
    width: '100%',
    '& table': {
      borderCollapse: 'collapse',
      width: '100%',
      fontSize: 14,
      color: '#4C4D50',
      '& th': {
        backgroundColor: Colors.backgroundLight,
        padding: 14,
      },
      '& td': {
        '&:focus-visible': {
          borderRadius: 8,
          outline: '2px solid #6D00D7',
          zIndex: 1,
        },
      },
      '& th, & td': {
        fontWeight: '300',
        border: `1px solid ${BorderColors.lighter}`,
        textAlign: 'left',
      },
    },
  },
  headerWithTooltip: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textInputWrapper: {
    position: 'relative',
  },
  actionsWrapper: {
    padding: '0 16px',
  },
  submitButton: {
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: Colors.secondaryDark,
    padding: 8,
    display: 'flex',
    borderRadius: 6,
    border: 'none',
    transition: 'opacity 0.2s ease-in-out',
    opacity: 0.9,
    '&:disabled': {
      opacity: 0.2,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -5,
      left: -5,
      right: -5,
      bottom: -5,
      border: '2.5px solid transparent',
      borderRadius: 10,
      pointerEvents: 'none',
      transition: 'border-color 0.3s ease',
    },
    '&:hover:not([disabled])::before, &:focus:not([disabled])::before': {
      borderColor: Colors.secondaryDark,
    },
  },
});

function AddRecord({ sid, updateSelectedCycle }: AddRecordProps) {
  const styles = useStyles();
  const {
    missions: { currentMission },
    auth,
  } = useStores();
  const commonStyles = useCommonStyles({});
  const cycleDates = currentMission?.cycleDates;
  const { mutate: setRecord, isLoading } = useMutationSetRecord();
  const { isFullTimeRetainer } = useGetMonthlyRetainerDataForCurrentUserRole();

  const queryClient = useQueryClient();
  const dateSelectReft = useRef<SelectProps['ref']>(null);
  const [loading, setLoading] = useLoadingState();

  const [date, setDate] = useState<Date | null>(null);
  const [time, setTime] = useState(0);
  const [type, setType] = useState<TimesheetRecordType | null>(null);
  const [task, setTask] = useState('');
  const [initiativeIds, setInitiativeIds] = useState<string[]>([]);
  const [needsValidation, setNeedsValidation] = useState(false);

  const isOutOfOfficeType = useMemo(() => {
    return !!type && outOfOfficeTypes.has(type);
  }, [type]);

  const formHasErrors = useMemo(() => {
    return (
      !date ||
      (!isOutOfOfficeType && !isFullTimeRetainer && !time) ||
      !type ||
      (!isOutOfOfficeType && !task) ||
      (!isOutOfOfficeType && !initiativeIds.length)
    );
  }, [date, time, type, task, initiativeIds]);

  useEffect(() => {
    if (!cycleDates) return;
    setDate(
      cycleDates
        .slice()
        .reverse()
        .find(
          (date) => getLocalTime(date).getDate() === new Date().getDate(),
        ) || cycleDates[0],
    );
  }, [cycleDates?.map((date) => date.toISOString()).join(',')]);

  const handleSubmit = () => {
    if (
      !date ||
      (!isOutOfOfficeType && !isFullTimeRetainer && !time) ||
      !type ||
      (!isOutOfOfficeType && !task) ||
      (!isOutOfOfficeType && !initiativeIds.length)
    ) {
      return;
    }

    if (!isOutOfOfficeType && task.length < TIMESHEET_TASK_MIN_LENGTH) {
      setNeedsValidation(true);
      return;
    } else if (needsValidation) {
      setNeedsValidation(false);
    }

    // Create a clone of the original date to avoid mutating it
    const clonedDate = new Date(date);
    // Set the time components of the cloned date to midnight (00:00:00) in UTC
    clonedDate.setUTCHours(0, 0, 0, 0);

    setLoading(true);
    setRecord(
      {
        data: {
          date: clonedDate.toISOString(),
          minutes: isOutOfOfficeType ? 0 : time,
          details: isOutOfOfficeType ? '' : task,
          type: type,
          initiativeIds: isOutOfOfficeType ? [] : initiativeIds,
        },
        key: generateUniqueKey(),
        sid,
      },
      {
        onSuccess: () => {
          setLoading('Record saved');
          if (currentMission?.selectedPaymentCycle?.data.yid) {
            queryClient.invalidateQueries(
              queryKeys.timesheets.getMissionPaymentCycle(
                currentMission.mid,
                currentMission?.selectedPaymentCycle?.data.yid,
                auth.uid || '',
              ),
            );

            updateSelectedCycle &&
              updateSelectedCycle(currentMission.selectedPaymentCycle.data.yid);

            setTime(0);
            setType(null);
            setTask('');
            setInitiativeIds([]);

            dateSelectReft?.current?.focus();
          }
        },
        onError: (error) => {
          setLoading(error as Error);
        },
      },
    );
  };

  const options: SelectOption[] = useMemo(() => {
    return (
      cycleDates?.map((date) => ({
        value: date.toISOString(),
        label: format(getLocalTime(date), 'EEE, LLL d'),
      })) ?? []
    );
  }, [cycleDates]);

  const dataGridNav = new DataGridNav();

  const listeners = {
    selectors: {
      focusableCell: 'td[tabindex="-1"], .submit-button',
    },
    onKeyDown: (e: React.KeyboardEvent<HTMLTableElement>) => {
      const target = e.target as HTMLElement;
      if (
        (target.nodeName === 'INPUT' || target.nodeName === 'TEXTAREA') &&
        (e.key === 'ArrowUp' ||
          e.key === 'ArrowRight' ||
          e.key === 'ArrowDown' ||
          e.key === 'ArrowLeft')
      ) {
        return;
      }

      dataGridNav.tableKeyDown(e as unknown as KeyboardEvent);
    },
    onKeyUp: () => dataGridNav.tableKeyUp(),
  };

  return (
    <div className={styles.container}>
      <table {...listeners}>
        <thead>
          <tr>
            <th className={commonStyles.dateColumn}>Date</th>
            <th className={commonStyles.timeColumn}>
              <div className={styles.headerWithTooltip}>
                Time
                <TimeTooltip />
              </div>
            </th>
            <th className={commonStyles.typeColumn}>Type</th>
            <th>Task</th>
            <th className={commonStyles.initiativesColumn}>
              <div className={styles.headerWithTooltip}>
                Initiative <InitiativesTooltip sid={sid} />
              </div>
            </th>
            <th className={commonStyles.actionsColumn}></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={commonStyles.dateColumn} tabIndex={-1}>
              <Select
                selectRef={dateSelectReft}
                options={options}
                value={
                  date
                    ? {
                        value: date.toISOString(),
                        label: format(getLocalTime(date), 'EEE, LLL d'),
                      }
                    : null
                }
                onChange={(data) => {
                  if (data) {
                    setDate(new Date(data.value));
                  }
                }}
                placeholder="Select date"
                margin="none"
                styles={{
                  input: (base) => ({
                    ...base,
                    border: 'none',
                  }),
                  control: (base, state) => ({
                    ...base,
                    boxShadow: 'none',
                    border: 'none',
                    outline: state.isFocused
                      ? `2px solid ${Colors.secondaryDark}`
                      : '2px solid transparent',
                    borderRadius: 8,
                    padding: 10,
                    margin: 0,
                    '&:hover': {
                      border: 'none',
                      outline: state.isFocused
                        ? `2px solid ${Colors.secondaryDark}`
                        : '2px solid transparent',
                    },
                  }),
                }}
                filterOption={(option, inputValue) => {
                  const { value, label } = option;
                  const selectedDate = new Date(value);
                  const alias = format(
                    getLocalTime(selectedDate),
                    'EEEE, LLLL d',
                  );

                  const inputValueLower = inputValue.toLowerCase();

                  return (
                    label.toLowerCase().includes(inputValueLower) ||
                    alias.toLowerCase().includes(inputValueLower)
                  );
                }}
              />
            </td>
            <td className={commonStyles.timeColumn} tabIndex={-1}>
              {isOutOfOfficeType || isFullTimeRetainer ? (
                <EmptyCell padding={16} />
              ) : (
                <TimeInput onChange={setTime} initialValue={time} />
              )}
            </td>
            <td className={commonStyles.typeColumn} tabIndex={-1}>
              <TypeSelect onChange={setType} cellValue={type} />
            </td>
            <td className={styles.textInputWrapper} tabIndex={-1}>
              {isOutOfOfficeType ? (
                <EmptyCell padding={16} />
              ) : (
                <TextInput
                  onChange={setTask}
                  placeholder="Enter task"
                  cellValue={task}
                  minChars={TIMESHEET_TASK_MIN_LENGTH}
                  needsValidation={needsValidation}
                  showOutlineError
                />
              )}
            </td>
            <td className={commonStyles.initiativesColumn} tabIndex={-1}>
              {isOutOfOfficeType ? (
                <EmptyCell padding={16} />
              ) : (
                <InitiativeSelect
                  sid={sid}
                  cellValue={initiativeIds}
                  onChange={setInitiativeIds}
                />
              )}
            </td>
            <td
              className={cx(styles.actionsWrapper, commonStyles.actionsColumn)}
              tabIndex={-1}
            >
              <button
                data-testing-id="timesheets-submit-record-button"
                className={cx(styles.submitButton, 'submit-button')}
                onClick={handleSubmit}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    handleSubmit();
                    event.preventDefault();
                    return;
                  }
                }}
                disabled={formHasErrors || isLoading}
              >
                <img src={sendIcon} alt="Submit" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <LoadingIndicator loading={loading || null} />
    </div>
  );
}

export default observer(AddRecord);
