import React, { ReactElement, ReactNode, useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import Colors from '../theme/colors';
import { Breakpoints } from '../theme/styles';
import Icon, { IconType } from '../Icon';

type ContentPlacement = 'top' | 'bottom';

interface Props {
  children: ReactNode;
  openDefault?: boolean;
  title: string;
  expandTitle?: string;
  maxContentHeight?: string | number;
  icon?: IconType;
  titleClassName?: string;
  headerClassName?: string;
  arrowClassName?: string;
  right?: ReactNode;
  arrowPosition?: 'left' | 'right';
  contentPlacement?: ContentPlacement;
  overflow?: 'auto' | 'hidden' | 'scroll' | 'visible' | 'unset';
}

const useStyles = createUseStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '1.5em 0em 1em',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  title: {
    fontWeight: 'bold',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '1em',
    marginRight: '0.5em',
    width: '2em',
    height: '2em',
  },
  arrow: {
    cursor: 'pointer',
    transition: 'all 0.3s',
  },
  arrowLeft: {
    marginRight: '0.5em',
  },
  arrowRight: {
    marginLeft: '0.5em',
  },
  content: (props: Props) => ({
    maxHeight: 0,
    overflow: props.overflow || 'auto',
    transition: 'all 0.3s ease',
    '&.open': {
      maxHeight: props.maxContentHeight || 500,
    },
  }),
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    iconContainer: {
      background: Colors.backgroundDark,
    },
  },
  rightContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const CollapsibleContainer = (props: Props): ReactElement => {
  const styles = useStyles(props);
  const {
    openDefault,
    title,
    icon,
    titleClassName,
    arrowClassName,
    right,
    arrowPosition = 'right',
    contentPlacement = 'bottom',
  } = props;
  const [isOpen, setIsOpen] = useState(!!openDefault);

  const renderIcon = (arrowStyles: string) => {
    return (
      <Icon
        type={IconType.ArrowDown}
        muted
        size="exact"
        flip={isOpen ? 'vertical' : undefined}
        className={cx(styles.arrow, arrowStyles)}
      />
    );
  };

  const content = isOpen ? (
    <div className={cx(styles.content, { open: true })}>{props.children}</div>
  ) : null;

  return (
    <>
      {isOpen && contentPlacement === 'top' && content}
      <div
        className={cx(styles.headerContainer, props.headerClassName, {
          open: isOpen,
        })}
      >
        <div
          className={styles.titleContainer}
          onClick={() => setIsOpen(!isOpen)}
        >
          {icon && (
            <div className={styles.iconContainer}>
              <Icon type={icon} size="exact" />
            </div>
          )}
          {arrowPosition === 'left' &&
            renderIcon(cx(styles.arrowLeft, arrowClassName))}
          <div className={cx(styles.title, titleClassName)}>
            {isOpen && props.expandTitle ? props.expandTitle : title}
          </div>
          {arrowPosition === 'right' &&
            renderIcon(cx(styles.arrowRight, arrowClassName))}
        </div>
        <div className={styles.rightContainer}>{right}</div>
      </div>
      {isOpen && contentPlacement === 'bottom' && content}
    </>
  );
};

export default CollapsibleContainer;
