import React, { ReactElement } from 'react';
import { Card } from '@ateams/components';
import {
  BasicContractObject,
  ContractId,
  ContractTitles,
} from '@a_team/models/dist/ContractObject';
import { format } from 'date-fns';
import TextButton from '@src/components/TextButton';
import { createUseStyles } from 'react-jss';
import { Breakpoints, Colors } from '@ateams/components';

interface Props {
  contract: BasicContractObject;
  onCardClick?(sid: ContractId): void;
  onDeleteClick?(sid: ContractId): void;
}

const useStyles = createUseStyles({
  card: {
    display: 'flex',
    flex: '0 0 100%',
    padding: '19px 16px 18px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    cursor: (props: Props) => (props.onCardClick ? 'pointer' : 'default'),
  },
  title: {
    fontSize: 15,
    fontWeight: 500,
    margin: 0,
    marginBottom: 4,
  },
  details: {
    display: 'flex',
    color: '#62646A',
    fontSize: 15,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    card: {
      flex: '0 0 392px',
    },
  },
  status: {
    color: Colors.success,
  },
  view: {
    fontWeight: 300,
  },
  smallDivider: {
    padding: '0 7px',
    fontSize: 12,
    color: '#C0C0C0',
  },
});

export const ContractCard = (props: Props): ReactElement => {
  const styles = useStyles(props);
  const {
    contract: { sid, type, createdAt, signedAt, downloadURL, custom },
    onDeleteClick,
    onCardClick,
  } = props;

  return (
    <Card
      className={styles.card}
      onClick={() => (onCardClick ? onCardClick(sid) : undefined)}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h5 className={styles.title}>{ContractTitles[type] || 'Contract'}</h5>
        <div className={styles.details}>
          <span>{format(new Date(createdAt), 'MMM dd, yyyy')}</span>
          <span className={styles.smallDivider}>|</span>
          <span className={styles.status}>
            {custom ? 'Uploaded' : signedAt ? 'Signed' : 'Pending'}
          </span>
        </div>
      </div>
      <div className="actions">
        <TextButton
          className={styles.view}
          highlight
          onClick={() =>
            signedAt && downloadURL ? window.open(downloadURL) : onCardClick
          }
        >
          View
        </TextButton>
        <span className={styles.smallDivider} style={{ padding: '0 5px' }}>
          |
        </span>
        <TextButton
          onClick={(e) => {
            e.stopPropagation();
            onDeleteClick && onDeleteClick(sid);
          }}
          color="regularDark"
        >
          Remove
        </TextButton>
      </div>
    </Card>
  );
};
