import { AvailableType } from '@a_team/models/dist/AvailabilityObject';
import { Breakpoints, Colors } from '@ateams/components';
import cx from 'classnames';
import { format, formatDistanceToNow } from 'date-fns';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import available from './icons/available.svg';
import availableInTheFuture from './icons/availableInTheFuture.svg';
import notAvailable from './icons/notAvailable.svg';

interface AvailabilityBannerV2Props {
  type: AvailableType;
  fromDate?: Date;
  hours?: number;
  lastUpdate?: Date;
  notes?: string;
  className?: string;
}

const useStyles = createUseStyles<{
  backgroundColor: string;
  textColor: string;
}>({
  wrapper: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    padding: 16,
    borderRadius: 8,
    marginBottom: 28,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    color: ({ textColor }) => textColor,
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '24px',
    gap: 12,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  icon: {
    width: 24,
    height: 24,
  },
  body: {
    marginTop: 16,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '24px',
  },
  updated: {},
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 8,
    },
    title: {
      flexDirection: 'row',
      alignItems: 'top',
      gap: 8,
    },
    icon: {
      width: 16,
      height: 16,
      marginTop: 3,
    },
    updated: {
      textAlign: 'right',
    },
  },
});

const getIcon = (type: AvailableType) => {
  switch (type) {
    case AvailableType.Now:
      return available;
    case AvailableType.FutureDate:
      return availableInTheFuture;
    case AvailableType.NotAvailable:
      return notAvailable;
    default:
      return '';
  }
};

const getBackGroundColor = (type: AvailableType) => {
  switch (type) {
    case AvailableType.Now:
      return Colors.banner;
    case AvailableType.FutureDate:
      return Colors.primaryVeryLight;
    case AvailableType.NotAvailable:
      return Colors.backgroundLight;
    default:
      return Colors.backgroundLight;
  }
};

const getTextColor = (type: AvailableType) => {
  switch (type) {
    case AvailableType.Now:
      return Colors.secondaryDark;
    case AvailableType.FutureDate:
      return Colors.primary;
    case AvailableType.NotAvailable:
      return '#222222';
    default:
      return '#222222';
  }
};

function AvailabilityBannerV2({
  type,
  fromDate,
  hours,
  lastUpdate,
  notes,
  className,
}: AvailabilityBannerV2Props) {
  const [icon, backgroundColor, textColor] = useMemo(() => {
    return [getIcon(type), getBackGroundColor(type), getTextColor(type)];
  }, [type]);

  const styles = useStyles({
    backgroundColor,
    textColor,
  });

  const availabilityText = useMemo(() => {
    if (type === AvailableType.Now) {
      return (
        <div>
          Available now to work on missions
          {hours ? (
            <>
              {' '}
              for{' '}
              <span data-testing-id={'profile-availability-hours-text'}>
                {hours}
              </span>
              h per week
            </>
          ) : (
            ''
          )}
          .
        </div>
      );
    }

    if (type === AvailableType.FutureDate) {
      return (
        <div>
          Available to join missions{' '}
          {fromDate ? `after ${format(fromDate, 'LLL d, yyyy')}` : 'soon'}
          {hours ? (
            <>
              {' '}
              for{' '}
              <span data-testing-id={'profile-availability-hours-text'}>
                {hours}
              </span>
              h per week
            </>
          ) : (
            '.'
          )}
          .
        </div>
      );
    }

    if (type === AvailableType.NotAvailable) {
      return 'Unavailable to join missions right now.';
    }

    return;
  }, [type, fromDate, hours]);

  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={styles.header}>
        <div className={styles.title}>
          <img className={styles.icon} src={icon} alt="Availability" />
          {availabilityText}
        </div>
        {lastUpdate && (
          <div className={styles.updated}>
            Updated {formatDistanceToNow(lastUpdate, { addSuffix: true })}
          </div>
        )}
      </div>
      {!!notes && <div className={styles.body}>“{notes}”</div>}
    </div>
  );
}

export default AvailabilityBannerV2;
