import companyDefaultLogo from '@src/components/CompanyAvatar/avatar.svg';
import ImageFallback from '@src/components/ImageFallback';
import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface CompanyLogoFallbackProps {
  logoUrl?: string;
  size?: number;
  borderRadius?: number;
  alt?: string;
  className?: string;
}

const useStyles = createUseStyles({
  logo: {
    borderRadius: ({ finalBorderRadius }) => finalBorderRadius,
    height: ({ finalSize }) => finalSize,
    width: ({ finalSize }) => finalSize,
  },
});

const CompanyLogoFallback = ({
  logoUrl,
  borderRadius,
  size,
  alt,
  className,
}: CompanyLogoFallbackProps): JSX.Element => {
  const finalBorderRadius = borderRadius ?? 16;
  const finalSize = size ? `${size}px` : '100%';

  const styles = useStyles({ finalBorderRadius, finalSize });

  return (
    <ImageFallback
      className={cx(styles.logo, className)}
      src={logoUrl || companyDefaultLogo}
      fallbackImage={companyDefaultLogo}
      alt={alt ?? 'Company logo'}
    />
  );
};

export default CompanyLogoFallback;
