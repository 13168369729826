import { Stores } from '@src/stores';
import { observable } from 'mobx';
import {
  SelectionTeamOnboardingPlan,
  SelectionTeamOnboardingPlanMilestone,
} from '@a_team/models/dist/selection-team-onboarding';
import { apiSelectionTeamOnboarding } from '@ateams/api';

export interface SelectionTeamOnboardingStoreData {
  currentSelectionTeamOnboardingPlan?: SelectionTeamOnboardingPlan;
}

export default class SelectionTeamOnboardingStore
  implements SelectionTeamOnboardingStoreData
{
  @observable
  currentSelectionTeamOnboardingPlan: SelectionTeamOnboardingStoreData['currentSelectionTeamOnboardingPlan'];

  private readonly rootStore: Stores;
  protected getCurrentSelectionTeamOnboardingPlanController?: AbortController;

  public constructor(
    rootStore: Stores,
    initialState?: SelectionTeamOnboardingStoreData,
  ) {
    this.rootStore = rootStore;

    if (initialState) {
      this.currentSelectionTeamOnboardingPlan =
        initialState.currentSelectionTeamOnboardingPlan;
    }
  }

  public serialize(): SelectionTeamOnboardingStoreData {
    return {
      currentSelectionTeamOnboardingPlan:
        this.currentSelectionTeamOnboardingPlan,
    };
  }

  private fetchCurrentSelectionTeamOnboardingPlan = async (
    abortController?: AbortController,
  ): Promise<SelectionTeamOnboardingPlan> => {
    return apiSelectionTeamOnboarding.fetchCurrentSelectionTeamOnboardingPlan(
      this.rootStore.auth,
      abortController,
    );
  };

  getCurrentSelectionTeamOnboardingPlan = async () => {
    const newAbortController = new AbortController();

    this.getCurrentSelectionTeamOnboardingPlanController?.abort();
    this.getCurrentSelectionTeamOnboardingPlanController = newAbortController;

    try {
      const currentSelectionTeamOnboardingPlan =
        await this.fetchCurrentSelectionTeamOnboardingPlan(
          this.getCurrentSelectionTeamOnboardingPlanController,
        );

      this.currentSelectionTeamOnboardingPlan =
        currentSelectionTeamOnboardingPlan;
    } catch (err) {
      if (!newAbortController.signal.aborted) {
        throw err;
      }
    }
  };

  replaceSelectionTeamOnboardingPlan = async (
    milestones: SelectionTeamOnboardingPlanMilestone[],
  ) => {
    const milestonesSortedByInterviewsCount = [...milestones].sort(
      (a, b) => (a.minInterviews || 0) - (b.minInterviews || 0),
    );

    const newSelectionTeamOnboardingPlan =
      await apiSelectionTeamOnboarding.replaceSelectionTeamOnboardingPlan(
        this.rootStore.auth,
        { milestones: milestonesSortedByInterviewsCount },
      );

    this.currentSelectionTeamOnboardingPlan = newSelectionTeamOnboardingPlan;
  };
}
