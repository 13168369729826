import React, { ReactElement, useMemo } from 'react';
import {
  ApplicantStatus,
  ExclusiveStatus,
  MissionApplicationReviewStatusOther,
} from '@a_team/models/dist/MissionApplicationObject';
import { MissionStatus } from '@a_team/models/dist/MissionObject';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';
import { createUseStyles } from 'react-jss';
import {
  StatusTag,
  TagStatusExternalValues,
  TagStatusItem,
  allTagStatusItems,
} from './StatusTag';
import _ from 'lodash';

interface Props {
  status: ApplicantStatus;
  missionStatus: MissionStatus;
  roleStatus: MissionRoleStatus;
  hired?: boolean;
  confirmedAnotherBuilder?: boolean | null;
  withdrawn?: boolean;
  useTagStyle?: boolean;
  noMargin?: boolean;
}

const useStyles = createUseStyles({
  tagsList: ({ noMargin }: Props) => ({
    display: 'inline-flex',
    flexWrap: 'wrap',
    gap: 8,
    marginTop: noMargin ? 0 : 8,
    marginBottom: noMargin ? 0 : 16,
  }),
});

export const ApplicationStatusIndication = (
  props: Props,
): ReactElement | null => {
  const styles = useStyles(props);

  const {
    status,
    missionStatus,
    roleStatus,
    hired = false,
    confirmedAnotherBuilder = false,
    withdrawn = false,
  } = props;
  const showMissionCancelledTag =
    (missionStatus === MissionStatus.Ended ||
      missionStatus === MissionStatus.ScheduledToEnd ||
      missionStatus === MissionStatus.Archived) &&
    roleStatus !== MissionRoleStatus.Canceled &&
    roleStatus !== MissionRoleStatus.ScheduledToEnd &&
    roleStatus !== MissionRoleStatus.Ended;
  const showRoleCancelledTag =
    !showMissionCancelledTag && roleStatus === MissionRoleStatus.Canceled;

  const tagStatusItems = useMemo<TagStatusItem[]>(() => {
    let statuses: TagStatusItem[] = [];

    if (allTagStatusItems) {
      statuses = _.concat<TagStatusItem>(
        withdrawn
          ? [
              allTagStatusItems.find(
                (o) => o.value === TagStatusExternalValues.Withdrawn,
              ),
            ].filter((item): item is TagStatusItem => item !== undefined)
          : [],
        hired
          ? [
              allTagStatusItems.find(
                (o) => o.value === TagStatusExternalValues.SelectedForRole,
              ),
            ].filter((item): item is TagStatusItem => item !== undefined)
          : [],
        confirmedAnotherBuilder
          ? [
              allTagStatusItems.find(
                (o) =>
                  o.value === TagStatusExternalValues.ConfirmedAnotherBuilder,
              ),
            ].filter((item): item is TagStatusItem => item !== undefined)
          : [],
        showMissionCancelledTag
          ? [
              allTagStatusItems.find(
                (o) => o.value === TagStatusExternalValues.MissionCancelled,
              ),
            ].filter((item): item is TagStatusItem => item !== undefined)
          : [],
        showRoleCancelledTag
          ? [
              allTagStatusItems.find(
                (o) => o.value === TagStatusExternalValues.RoleCancelled,
              ),
            ].filter((item): item is TagStatusItem => item !== undefined)
          : [],
        status.accepted
          ? [
              allTagStatusItems.find(
                (o) => o.value === TagStatusExternalValues.Accepted,
              ),
            ].filter((item): item is TagStatusItem => item !== undefined) || []
          : [],
        status.exclusiveStatus === ExclusiveStatus.Exclusive
          ? [
              allTagStatusItems.find(
                (o) => o.value === TagStatusExternalValues.Exclusive,
              ),
            ].filter((item): item is TagStatusItem => item !== undefined) || []
          : [],
        status.exclusiveStatus === ExclusiveStatus.OnHold
          ? [
              allTagStatusItems.find(
                (o) => o.value === TagStatusExternalValues.OnHold,
              ),
            ].filter((item): item is TagStatusItem => item !== undefined) || []
          : [],
        status.vettingScheduled
          ? [
              allTagStatusItems.find(
                (o) => o.value === TagStatusExternalValues.HasVettingScheduled,
              ),
            ].filter((item): item is TagStatusItem => item !== undefined) || []
          : [],
        status.proposalInterviewing
          ? [
              allTagStatusItems.find(
                (o) => o.value === TagStatusExternalValues.ProposalInterviewing,
              ),
            ].filter((item): item is TagStatusItem => item !== undefined) || []
          : [],
        status.proposal
          ? [
              allTagStatusItems.find(
                (o) => o.value === TagStatusExternalValues.Proposal,
              ),
            ].filter((item): item is TagStatusItem => item !== undefined) || []
          : [],
        status.reviewStatus?.notSelected
          ?.map((s) => allTagStatusItems.find((o) => o.value === s))
          .filter((item): item is TagStatusItem => item !== undefined) || [],
        status.reviewStatus?.opportunityToUpdate
          ?.map((s) => allTagStatusItems.find((o) => o.value === s))
          .filter((item): item is TagStatusItem => item !== undefined) || [],
        status.reviewStatus?.waitlisted
          ?.map((s) => allTagStatusItems.find((o) => o.value === s))
          .filter((item): item is TagStatusItem => item !== undefined) || [],
        status.reviewStatus?.other
          ?.map((s) => allTagStatusItems.find((o) => o.value === s))
          .filter((item): item is TagStatusItem => item !== undefined) || [],
      );

      if (statuses.length === 0) {
        return (
          [
            allTagStatusItems.find(
              (o) => o.value === TagStatusExternalValues.InReview,
            ),
          ].filter((item): item is TagStatusItem => item !== undefined) || []
        );
      }
    }

    if (
      statuses.find(
        (s) =>
          s.value === MissionApplicationReviewStatusOther.PresentedToClient,
      )
    ) {
      statuses = statuses.filter(
        (s) => s.value !== TagStatusExternalValues.Proposal,
      );
    }

    // Return only distinct values
    return _.uniqBy(statuses, 'value') || [];
  }, []);

  return (
    <div className={styles.tagsList}>
      {tagStatusItems.map((ss) => (
        <StatusTag useTagStyle={props.useTagStyle} item={ss} />
      ))}
    </div>
  );
};
