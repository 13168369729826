import React from 'react';
import { createUseStyles } from 'react-jss';
import { ConnectionType as ConnectionTypes } from '@a_team/models/dist/ConnectionObject';
import { Icon, IconType, TextColors } from '@ateams/components';

interface ConnectionTypeProps {
  connectionType: ConnectionTypes.Work | ConnectionTypes.TeamUp;
  addDot?: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: TextColors.regular,
  },
  dot: {
    color: '#818388',
    fontSize: '8px',
  },
});

const connectionTypeMap: Record<
  ConnectionTypes.Work | ConnectionTypes.TeamUp,
  { icon: IconType; label: string }
> = {
  [ConnectionTypes.Work]: {
    icon: IconType.Match,
    label: 'Worked Together',
  },
  [ConnectionTypes.TeamUp]: {
    icon: IconType.Collaborator,
    label: 'Connected',
  },
};

const ConnectionType = ({
  connectionType,
  addDot,
}: ConnectionTypeProps): JSX.Element => {
  const styles = useStyles();

  const { icon, label } = connectionTypeMap[connectionType];

  return (
    <div className={styles.container}>
      {addDot && <span className={styles.dot}>&#8226;</span>}
      <Icon type={icon} size="small" />
      <span>{label}</span>
    </div>
  );
};

export default ConnectionType;
