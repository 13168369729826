import React, { ReactElement, useEffect, useState } from 'react';
import Modal from '..';
import SectionHeading from '@src/components/SectionHeading';
import BasicUserSelector from '@src/components/BasicUserSelector';
import { BasicUserObject, UserId } from '@a_team/models/dist/UserObject';
import {
  ExperienceMember,
  ExperienceMemberRole,
} from '@a_team/models/dist/ExperienceObject';
import UserAvatar from '@src/components/UserAvatar';
import {
  getProjectMemberId,
  isProjectMemberUser,
} from '@src/views/Profile/helpers/experience';
import { Divider } from '@ateams/components';
import TextButton from '@src/components/TextButton';
import { Button as CallToActionButton } from '@ateams/components';
import { isEqual } from 'lodash/fp';

interface Props {
  members: ExperienceMember[];
  open: boolean;
  onClose(): void;
  onAccept(members: ExperienceMember[]): void;
  currentUserId: UserId | null;
}

const ProjectMembersModal = (props: Props): ReactElement => {
  const { members, open, onClose, onAccept, currentUserId } = props;
  const [memberList, setMemberList] = useState(members);

  useEffect(() => {
    if (!isEqual(memberList, members)) {
      setMemberList(members);
    }
  }, [members, memberList]);

  const addMember = (user: BasicUserObject) => {
    if (!user) return;
    const alreadyExists = memberList.find(
      (member) => member && getProjectMemberId(member) === user.uid,
    );
    const isCurrentUser = user.uid === currentUserId;

    if (alreadyExists || isCurrentUser) return;

    const list = [...memberList];
    list.push({
      ...user,
      ...{
        memberTitle: user.title,
        experienceRole: ExperienceMemberRole.Member,
      },
    });
    setMemberList(list);
  };

  const removeMember = (member: ExperienceMember) => {
    setMemberList(
      memberList.filter(
        (user) => getProjectMemberId(user) !== getProjectMemberId(member),
      ),
    );
  };

  return (
    <Modal onClose={onClose} open={open} style={{ overflow: 'visible' }}>
      <SectionHeading isFirst>Add Collaborators</SectionHeading>
      <BasicUserSelector
        placeholder={'Search for Collaborators'}
        onSelect={(user): void => addMember(user as BasicUserObject)}
        margin="none"
      />
      <Divider margin={'small'} />
      {memberList.length > 0 ? (
        memberList.map((member, i) => {
          return (
            isProjectMemberUser(member) && (
              <div
                key={getProjectMemberId(member)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 24,
                }}
              >
                <div style={{ display: 'flex' }}>
                  <UserAvatar
                    src={member.profilePictureURL}
                    containerStyle={{ marginRight: 24 }}
                    size={40}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ color: '#222', fontWeight: 500 }}>
                      {member.fullName}
                    </div>
                    <div style={{ color: '#62646A', fontWeight: 400 }}>
                      {member.memberTitle}
                    </div>
                  </div>
                </div>
                {member.experienceRole !== ExperienceMemberRole.Owner && (
                  <TextButton highlight onClick={() => removeMember(member)}>
                    Remove
                  </TextButton>
                )}
              </div>
            )
          );
        })
      ) : (
        <p>No collaborators selected</p>
      )}
      <Divider margin={'small'} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CallToActionButton onClick={() => onAccept(memberList)}>
          Done
        </CallToActionButton>
      </div>
    </Modal>
  );
};

export default ProjectMembersModal;
