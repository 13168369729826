import React from 'react';
import { Button, Modal } from '@a_team/ui-components';
import { createUseStyles } from 'react-jss';
import { Breakpoints, TextColors } from '@ateams/components';
import { useStores } from '@src/stores';
import GradientBox from '../../GradientBox';
import Lottie from 'lottie-react';
import image from './image.json';

interface ReleaseTeamUpModalProps {
  onClose: () => void;
  setIsReleaseStepsModalOpen: (force?: unknown) => void;
  shouldSetWhatsNewModalAsDisplayed?: boolean;
  isOpen: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    color: TextColors.regular,
    textAlign: 'center',
    flexDirection: 'column',
  },
  content: {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    backgroundColor: 'transparent !important',
    color: 'black !important',
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& > img': {
      borderRadius: '16px 16px 0px 0px',
      aspectRatio: '1.3',
    },
    '& svg': {
      borderRadius: 20,
    },
  },
  title: {
    fontSize: 32,
    letterSpacing: '0.001em',
    lineHeight: '120%',
    margin: '16px 0',
  },
  desc: {
    margin: '0 0 40px 0',
  },

  modal: {
    '&&': {
      width: '100%',
      maxWidth: '100%',
      borderRadius: '8px !important',
      '& > div': {
        padding: '0 !important',
      },
    },
  },
  img: {
    width: '100%',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    container: {
      flexDirection: 'row-reverse',
    },
    imageContainer: {
      maxWidth: '425px',
      width: '100%',
      '& > img': {
        aspectRatio: '0.94',
        borderRadius: '0 8px 8px 0',
      },
    },
    modal: {
      '&&': {
        width: '850px !important',
        maxWidth: '850px',
        padding: '0 !important',
      },
      '& > div': {
        padding: '0 !important',
      },
      '& > div:last-of-type': {
        display: 'none',
      },
    },
  },
});

const ReleaseTeamUpModal = ({
  onClose,
  isOpen,
  shouldSetWhatsNewModalAsDisplayed,
  setIsReleaseStepsModalOpen,
}: ReleaseTeamUpModalProps): JSX.Element => {
  const styles = useStyles();

  const { auth } = useStores();

  const handleCTA = async () => {
    if (!shouldSetWhatsNewModalAsDisplayed) {
      onClose();
      setIsReleaseStepsModalOpen(true);
      return;
    }
    try {
      await auth.setWhatsNewModalAsDisplayed(auth);
    } catch (e) {
      console.error(e);
    } finally {
      onClose();
      setIsReleaseStepsModalOpen(true);
    }
  };

  const handleOnClose = async () => {
    if (!shouldSetWhatsNewModalAsDisplayed) {
      onClose();
      return;
    }
    try {
      await auth.setWhatsNewModalAsDisplayed(auth);
    } catch (e) {
      console.error(e);
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      className={styles.modal}
      hideCloseButton
      variant="slideUp"
      isOpen={isOpen}
      onClose={handleOnClose}
    >
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <Lottie className={styles.img} animationData={image} loop={true} />
        </div>
        <div className={styles.content}>
          <GradientBox>Now launching</GradientBox>
          <h2 className={styles.title}>It’s time to team up</h2>
          <p className={styles.desc}>
            You can now invite other A.Team builders to Team Up with you on
            missions, increasing your chances of being selected as a team.
          </p>
          <Button size="md" onClick={handleCTA}>
            See what’s new
          </Button>
          <Button
            size="md"
            className={styles.closeButton}
            onClick={handleOnClose}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReleaseTeamUpModal;
