import { ServiceAuth, ServiceEndpoint } from './utils';
import CompanyLeadObject, {
  AdminCompanyLeadObject,
  CompanyLeadId,
} from '@a_team/models/dist/CompanyLeadObject';
import { QueryNextToken, QueryResult } from '@a_team/models/dist/misc';
import CompanyLeadApplicationObject, {
  AdminCompanyLeadApplicationObject,
  CompanyLeadApplicationData,
} from '@a_team/models/dist/CompanyLeadApplicationObject';

export const BasePath = '/company-leads';

export type CompanyLeadData = Pick<
  CompanyLeadObject,
  'name' | 'description' | 'logoURL'
>;

// exported functions

export default class CompanyLeadsEndpoint extends ServiceEndpoint {
  public createCompanyLead(
    auth: ServiceAuth,
    data: CompanyLeadData,
  ): Promise<CompanyLeadObject> {
    return this.fetch(auth, BasePath, null, 'post', data);
  }

  public adminCreateCompanyLead(
    auth: ServiceAuth,
    data: CompanyLeadData,
  ): Promise<AdminCompanyLeadObject> {
    return this.fetch(auth, BasePath, { admin: 1 }, 'post', data);
  }

  public getCompanyLead(
    auth: ServiceAuth,
    lid: CompanyLeadId,
  ): Promise<CompanyLeadObject> {
    return this.fetch(auth, BasePath + `/${lid}`);
  }

  public adminGetCompanyLead(
    auth: ServiceAuth,
    lid: CompanyLeadId,
  ): Promise<AdminCompanyLeadObject> {
    return this.fetch(auth, BasePath + `/${lid}`, { admin: 1 });
  }

  public adminUpdateCompanyLead(
    auth: ServiceAuth,
    lid: CompanyLeadId,
    data: CompanyLeadData,
  ): Promise<AdminCompanyLeadObject> {
    return this.fetch(auth, BasePath + `/${lid}`, null, 'put', data);
  }

  public adminDeleteCompanyLead(
    auth: ServiceAuth,
    lid: CompanyLeadId,
  ): Promise<void> {
    return this.fetch(auth, BasePath + `/${lid}`, null, 'delete');
  }

  public getCompanyLeads(
    auth: ServiceAuth,
    nextToken?: QueryNextToken | null,
  ): Promise<QueryResult<CompanyLeadObject>> {
    return this.fetch(auth, BasePath, { next: nextToken });
  }

  public adminGetCompanyLeads(
    auth: ServiceAuth,
    nextToken?: QueryNextToken | null,
  ): Promise<QueryResult<AdminCompanyLeadObject>> {
    return this.fetch(auth, BasePath, { admin: 1, next: nextToken });
  }

  public applyForCompanyLead(
    auth: ServiceAuth,
    lid: CompanyLeadId,
    data: CompanyLeadApplicationData,
  ): Promise<CompanyLeadApplicationObject> {
    return this.fetch(auth, BasePath + `/${lid}/apply`, null, 'post', data);
  }

  public adminGetCompanyLeadApplications(
    auth: ServiceAuth,
    lid: CompanyLeadId,
    nextToken?: QueryNextToken | null,
  ): Promise<QueryResult<AdminCompanyLeadApplicationObject>> {
    return this.fetch(auth, BasePath + `/${lid}/applications`, {
      next: nextToken,
    });
  }
}
