import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import RegistrationStep from '@src/views/Registration/RegistrationStep';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import { Breakpoints, RangeSlider } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { useScreenClass } from 'react-grid-system';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';

const useStyles = createUseStyles({
  '@global': {
    '.rc-slider-tooltip': {
      zIndex: 999,
    },
  },
  slider: {
    transform: 'scale(1.2)',
    margin: '42px 44px',
    width: '74%',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    width: 81,
    marginLeft: 8,
    marginBottom: 16,
  },
  inputInner: {
    textAlign: 'center',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    slider: {
      transform: 'none',
      margin: '75px 0 0',
      width: '100%',
    },
  },
  [`@media (min-width: ${Breakpoints.xl}px)`]: {
    '@global': {
      '.rc-slider-tooltip-inner': {
        fontSize: '1.5em',
      },
    },
    slider: {
      marginTop: 100,
      '& .rc-slider-rail, & .rc-slider-track': {
        height: '0.2em !important',
      },
      '& .rc-slider-handle': {
        height: '1em !important',
        width: '1em !important',
      },
    },
  },
});

const HourlyRate = (): ReactElement => {
  const styles = useStyles();
  const screenClass = useScreenClass();
  const {
    registration: {
      currentStep,
      setHourlyRateRange,
      hourlyRate,
      hourlyRateValid,
      MIN_HOURLY_RATE_LIMIT,
      MAX_HOURLY_RATE_LIMIT,
      MIN_HOURLY_RATE_DEFAULT,
      MAX_HOURLY_RATE_DEFAULT,
    },
  } = useStores();
  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  const [loading, setLoading] = useLoadingState();

  const onSubmit = () => {
    currentStep.onSubmit && setLoading(currentStep.onSubmit());
  };

  const tooltipFormatter = (value: number) => {
    return `${
      value === MIN_HOURLY_RATE_LIMIT
        ? '≤ '
        : `${value === MAX_HOURLY_RATE_LIMIT ? '≥ ' : ''}`
    }$${value}`;
  };

  return (
    <RegistrationStep
      title={'What is Your Hourly Rate These Days?'}
      description={
        'We are here to empower builders to work autonomously on the problems they care most about for the compensation they deserve.'
      }
      onSubmit={onSubmit}
      canSubmit={loading !== true && currentStep.canSubmit}
      loading={loading}
    >
      <RangeSlider
        className={styles.slider}
        onChange={setHourlyRateRange}
        min={MIN_HOURLY_RATE_LIMIT}
        max={MAX_HOURLY_RATE_LIMIT}
        defaultValue={[
          hourlyRate?.min || MIN_HOURLY_RATE_DEFAULT,
          hourlyRate?.max || MAX_HOURLY_RATE_DEFAULT,
        ]}
        value={[
          hourlyRate?.min || MIN_HOURLY_RATE_DEFAULT,
          hourlyRate?.max || MAX_HOURLY_RATE_DEFAULT,
        ]}
        tipFormatter={tooltipFormatter}
        alwaysShowTooltip={!isMobile}
      />
      {isMobile && (
        <div>
          <div className={styles.row}>
            <label>Minimum Amount</label>
            <div className={styles.inputContainer}>
              $
              <OutlinedInput
                margin={'none'}
                min={MIN_HOURLY_RATE_LIMIT}
                type={'number'}
                value={hourlyRate?.min}
                defaultValue={hourlyRate?.min || MIN_HOURLY_RATE_DEFAULT}
                onChange={(e) =>
                  setHourlyRateRange([
                    Number(e.target.value),
                    Number(hourlyRate?.max),
                  ])
                }
                className={styles.input}
                inputClassName={styles.inputInner}
                error={hourlyRate?.min !== undefined && !hourlyRateValid}
              />
            </div>
          </div>
          <div className={styles.row}>
            <label>Maximum Amount</label>
            <div className={styles.inputContainer}>
              $
              <OutlinedInput
                max={MAX_HOURLY_RATE_LIMIT}
                margin={'none'}
                type={'number'}
                value={hourlyRate?.max}
                defaultValue={hourlyRate?.max || MAX_HOURLY_RATE_DEFAULT}
                onChange={(e) =>
                  setHourlyRateRange([
                    Number(hourlyRate?.min),
                    Number(e.target.value),
                  ])
                }
                className={styles.input}
                inputClassName={styles.inputInner}
                error={hourlyRate?.max !== undefined && !hourlyRateValid}
              />
            </div>
          </div>
        </div>
      )}
    </RegistrationStep>
  );
};

export default observer(HourlyRate);
