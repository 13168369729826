import { apiBuilderLoginHistory } from '@ateams/api';
import { queryKeys } from './keys';
import { useQuery } from '@tanstack/react-query';
import { useStores } from '@src/stores';

export const useQueryBuilderLoginHistory = ({ uid }: { uid: string }) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.builderLoginHistory.byUid(uid),
    queryFn: async () => {
      return await apiBuilderLoginHistory.getBuilderLoginHistory(auth, uid);
    },
    enabled: !!auth.withLocationMismatch && auth.isAdmin,
  });
};
