import { useEffect } from 'react';

export const NotificationPreferencesRedirect = () => {
  useEffect(() => {
    if (window?.location) {
      window.location.href = '/settings?menu=notifications';
    }
  }, []);

  return null;
};

export default NotificationPreferencesRedirect;
