import { observable, action } from 'mobx';
import { Stores } from '@src/stores/index';

export interface UIStoreData {
  notificationSlider?: boolean;
}

export default class UIStore implements UIStoreData {
  @observable public notificationSlider: UIStoreData['notificationSlider'] =
    false;

  @observable public timesheetDescription = '';
  @observable public timesheetDescriptionHtml = '';
  @observable public timesheetVideoLink = '';
  @observable public originalTimesheetDescriptionHtml = '';
  @observable public gptUsageLogIdForTimesheetSummary: string | undefined =
    undefined;

  public constructor(rootStore: Stores, initialState?: UIStoreData) {
    if (initialState) {
      this.notificationSlider = initialState.notificationSlider || false;
    }
  }

  @action setTimesheetDescription = (description: string) => {
    this.timesheetDescription = description;
  };

  @action setTimesheetDescriptionHtml = (descriptionHtml: string) => {
    this.timesheetDescriptionHtml = descriptionHtml;
  };

  @action setTimesheetVideoLink = (videoLink: string) => {
    this.timesheetVideoLink = videoLink;
  };

  @action setGptUsageLogIdForTimesheetSummary = (id?: string) => {
    this.gptUsageLogIdForTimesheetSummary = id;
  };

  @action toggleNotificationSlider = () => {
    this.notificationSlider = !this.notificationSlider;
  };

  @action setOriginalTimesheetDescriptionHtml = (descriptionHtml: string) => {
    this.originalTimesheetDescriptionHtml = descriptionHtml;
  };

  public serialize(): UIStoreData {
    return {
      notificationSlider: this.notificationSlider,
    };
  }
}
