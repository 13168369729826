import React, { ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  query: string;
  children: string;
}

const useStyles = createUseStyles({
  highlight: {
    color: '#FE630C',
  },
});

export default function HighlightedText(props: Props): ReactElement {
  const { query, children } = props;
  const styles = useStyles();

  const match = query.trim().toLowerCase();
  if (!match || !children) {
    return <>children</>;
  }

  const parts = children.toLowerCase().split(match);
  let pos = parts[0].length;
  const nodes: ReactNode[] = [children.substring(0, pos)];

  for (let i = 1; i < parts.length; i += 1) {
    const start = pos;
    const middle = pos + match.length;
    pos = middle + parts[i].length;

    nodes.push(
      <span className={styles.highlight}>
        {children.substring(start, middle)}
      </span>,
      children.substring(middle, pos),
    );
  }

  return <>{nodes}</>;
}
