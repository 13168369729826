import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { format } from 'date-fns';
import { Size, Text } from '../../components/typography';
import { Select } from '../../components/select';
import { AvailableType } from '@a_team/models/dist/AvailabilityObject';
import { DateISOString } from '@a_team/models/dist/misc';
import {
  DateInput,
  DEFAULT_DATE_FORMAT,
  OnDateChange,
} from '../../components/date-input';
import { SelectProps } from '@ateams/components';
import { AvailabilityTypeLabels } from '@src/stores/NotificationPreferencesUser';
import { theme } from '@a_team/ui-components';
import { TextInput } from '../../components/text-input';

const availabilityOptions = Object.entries(AvailabilityTypeLabels).map(
  ([key, value]) => ({
    value: key,
    label: value,
  }),
);

interface AvailabilityValue {
  type: AvailableType;
  availableFrom?: DateISOString;
  weeklyHoursAvailable?: number;
}

export type OnAvailabilityChange = (
  availability: AvailabilityValue | undefined,
) => void;

export interface AvailabilityInputProps {
  value?: AvailabilityValue | undefined;
  onChange?: OnAvailabilityChange;
  isReadOnly?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  valueRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'initial',
    alignItems: 'center',
  },
  availabilityTypeSelect: {
    minWidth: '152px',
  },
  inputLabelSpace: {
    width: '12px',
  },
  availabilityFutureDate: {
    width: '140px',
  },
  availabilityWeeklyHoursInput: {
    height: '20px',
    width: '50px',
    textAlign: 'center',
    padding: '0 12px',
    whiteSpace: 'nowrap',
  },
  availabilityHoursPerWeekLabel: {
    whiteSpace: 'nowrap',
  },
});

export const AvailabilityInput: React.FC<AvailabilityInputProps> = (props) => {
  const { isReadOnly, value } = props;
  const styles = useStyles();

  const onAvailabilityTypeChange: SelectProps['onChange'] = (option) => {
    if (option) {
      const type = option.value as AvailableType;

      if (type === AvailableType.NotAvailable) {
        props.onChange?.({ type });
      }
      if (type === AvailableType.Now) {
        props.onChange?.({
          type,
          weeklyHoursAvailable: value?.weeklyHoursAvailable,
        });
      }
      if (type === AvailableType.FutureDate) {
        props.onChange?.({
          type,
          weeklyHoursAvailable: value?.weeklyHoursAvailable,
          availableFrom: value?.availableFrom,
        });
      }
    } else {
      props.onChange?.(undefined);
    }
  };

  const onAvailableFromChange: OnDateChange = (date) => {
    if (date && value?.type) {
      const availableFrom = date.toISOString();

      props.onChange?.({ ...value, availableFrom });
    }
  };

  const onWeeklyHoursAvailableChange = (weeklyHoursAvailable?: number) => {
    if (
      value?.type &&
      (typeof weeklyHoursAvailable === 'undefined' ||
        !isNaN(weeklyHoursAvailable))
    ) {
      props.onChange?.({ ...value, weeklyHoursAvailable });
    }
  };

  const selectedAvailabilityOption = useMemo(() => {
    return availabilityOptions.find((option) => option.value === value?.type);
  }, [value?.type]);

  if (isReadOnly) {
    let label: string;
    if (!value?.type) {
      label = '--';
    } else {
      label = value.type;

      // Add future date
      if (value.type === AvailableType.FutureDate && value.availableFrom) {
        const date = format(new Date(value.availableFrom), DEFAULT_DATE_FORMAT);

        label = `${label} at ${date}`;
      }

      // Add weekly hours
      if (
        value.type !== AvailableType.NotAvailable &&
        value.weeklyHoursAvailable
      ) {
        label = `${label} for ${value.weeklyHoursAvailable} hours per week`;
      }
    }

    return (
      <div className={props.className}>
        <Text size={Size.Small}>{label}</Text>
      </div>
    );
  }

  return (
    <div className={cx(props.className, styles.valueRow)}>
      <Select
        value={selectedAvailabilityOption}
        onChange={onAvailabilityTypeChange}
        isClearable
        hideSelectedOptions={false}
        options={availabilityOptions}
        color={selectedAvailabilityOption ? theme.colors.Grey[200] : undefined}
        className={styles.availabilityTypeSelect}
        menuPortalTarget={document.body}
      />

      {value?.type === AvailableType.FutureDate && (
        <Text size={Size.Small} className={styles.valueRow}>
          <div className={styles.inputLabelSpace} />
          at
          <div className={styles.inputLabelSpace} />
          <DateInput
            selected={
              value.availableFrom ? new Date(value.availableFrom) : undefined
            }
            onChange={onAvailableFromChange}
            isClearable={false}
            controlClassName={styles.availabilityFutureDate}
          />
        </Text>
      )}

      {[AvailableType.Now, AvailableType.FutureDate].includes(
        value?.type as AvailableType,
      ) && (
        <Text size={Size.Small} className={styles.valueRow}>
          <div className={styles.inputLabelSpace} />
          for
          <TextInput
            value={
              value?.weeklyHoursAvailable
                ? String(value.weeklyHoursAvailable)
                : ''
            }
            onChange={(e) =>
              onWeeklyHoursAvailableChange(
                e.target.value ? parseInt(e.target.value, 10) : undefined,
              )
            }
            placeholder={'...'}
            className={styles.availabilityWeeklyHoursInput}
          />
          <Text
            size={Size.Small}
            className={styles.availabilityHoursPerWeekLabel}
          >
            hours per week
          </Text>
        </Text>
      )}
    </div>
  );
};
