/**
 * Accepts an array of elements and a sorting order, returns the sorted version
 * @param elements An array of elements to be sorted by the order specified in sortingOrder
 * @param sortingOrder an array of elements specifying the order to sort @param elements
 * @returns @param elements sorted by the order of @param sortingOrder
 */
export function sortSubArrayByArray<T>(elements: T[], sortingOrder: T[]): T[] {
  return elements.sort((elementA, elementB) => {
    const elementAIndex = sortingOrder.indexOf(elementA);
    const elementBIndex = sortingOrder.indexOf(elementB);

    if (elementAIndex > elementBIndex) {
      return 1;
    } else if (elementBIndex > elementAIndex) {
      return -1;
    } else {
      return 0;
    }
  });
}

export function isNonNullable<TValue>(
  value: TValue | undefined | null,
): value is TValue {
  return value !== null && value !== undefined;
}
