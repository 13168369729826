import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { ModalBasicProps } from '@src/components/Modal';
import { ContractId } from '@a_team/models/dist/ContractObject';
import { LegalPdfViewer } from '@src/components/Modal/LegalPdfViewer/LegalPdfViewer';

interface Props extends ModalBasicProps {
  loading: boolean;
  onAccept(ids: ContractId[]): void;
  acceptedContracts: ContractId[];
  contractUrl?: string;
  children: ReactNode;
  style?: CSSProperties;
  allowButtonEnabled?: boolean;
  isFullTimeRetainer?: boolean;
}

export const ContractModal = (props: Props): ReactElement => {
  const { onAccept, acceptedContracts, children, ...left } = props;

  const onAcceptClick = () => {
    onAccept(acceptedContracts);
  };

  return (
    <LegalPdfViewer onAccept={onAcceptClick} {...left}>
      {children}
    </LegalPdfViewer>
  );
};
