import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  Icon,
  IconType,
  Spacing,
  Colors,
  FontSizes,
  BorderRadius,
  BorderColors,
  FontWeights,
} from '@ateams/components';
import { useCommonStyles } from '../commonStyles';

const useStyles = createUseStyles({
  uploadFilesContainer: {
    width: 600,
    padding: Spacing.medium,
    backgroundColor: Colors.backgroundLight,
    borderRadius: BorderRadius.xLarge,
    marginTop: Spacing.medium,
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
  },
  uploadButton: {
    width: 'fit-content',
    padding: Spacing.small,
    borderRadius: BorderRadius.medium,
    border: `1px solid ${Colors.secondaryDark}`,
    backgroundColor: Colors.backgroundWhite,
    cursor: 'pointer',
    textAlign: 'center',
    color: Colors.secondaryDark,
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.xsmall,
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadIcon: {
    width: 16,
    height: 16,
  },
  uploadText: {
    fontSize: FontSizes.xsmall,
    lineHeight: '16px',
  },
  fileList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: Spacing.small,
    marginTop: Spacing.small,
  },
  fileItem: {
    width: 'fit-content',
    height: 32,
    display: 'flex',
    gap: Spacing.small,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: Spacing.small,
    backgroundColor: Colors.backgroundWhite,
    border: `1px solid ${BorderColors.lighter}`,
    borderRadius: BorderRadius.default,
    whiteSpace: 'nowrap',
  },
  fileInfo: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
    alignItems: 'center',
  },
  fileName: {
    fontSize: FontSizes.small,
    color: Colors.dark,
    fontWeight: FontWeights.semiBold,
    lineHeight: '16px',
  },
  fileSize: {
    fontSize: FontSizes.small,
    color: Colors.dark,
    lineHeight: '16px',
  },
  attachmentIcon: {
    width: 16,
    height: 16,
  },
  removeIcon: {
    width: 8,
    height: 8,
  },
});

interface FileUploadSectionProps {
  uploadedFiles: File[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const FileUploadSection = ({
  uploadedFiles,
  setUploadedFiles,
}: FileUploadSectionProps) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newFiles = Array.from(files);
      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const removeFile = (fileName: string) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName),
    );
  };

  const formatFileSize = (size: number) => {
    if (size < 1024) {
      return `${size}B`;
    } else if (size < 1048576) {
      return `${(size / 1024).toFixed(2)}KB`;
    } else {
      return `${(size / 1048576).toFixed(2)}MB`;
    }
  };

  return (
    <div className={styles.uploadFilesContainer}>
      <div className={commonStyles.title}>Upload files</div>
      <input
        type="file"
        id="fileInput"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <div className={styles.fileList}>
        <div
          className={styles.uploadButton}
          onClick={() => document.getElementById('fileInput')?.click()}
        >
          <Icon type={IconType.Upload2} className={styles.uploadIcon} />
          <div className={styles.uploadText}>Upload files</div>
        </div>
        {uploadedFiles.map((file) => (
          <div className={styles.fileItem} key={file.name}>
            <Icon
              type={IconType.Attachment}
              className={styles.attachmentIcon}
            />
            <div className={styles.fileInfo}>
              <div className={styles.fileName}>{file.name}</div>
              <div className={styles.fileSize}>{formatFileSize(file.size)}</div>
            </div>
            <Icon
              type={IconType.Close}
              className={styles.removeIcon}
              onClick={() => removeFile(file.name)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileUploadSection;
