import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import UserCard from '../UserCard';
import { SelectionTeamCardObject } from '@a_team/models/dist/UserObject';
import {
  BorderColors,
  Breakpoints,
  FontSizes,
  LoadMoreLink,
  Spacing,
  TextColors,
} from '@ateams/components';
import cx from 'classnames';
import { REACT_APP_CALCOM_URL } from '@src/config';
import { vettingTypeToStatusMap } from '@a_team/models/dist/vetting-processes/vetting-processes';

interface Props {
  users: SelectionTeamCardObject[];
  onLoadMore?: () => void;
  topUsers?: boolean;
  minimal?: boolean;
  track?: (useR: SelectionTeamCardObject) => void;
}

const useStyles = createUseStyles({
  cardsContainer: {
    marginTop: '32px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '24px',
    marginRight: 0,
  },
  card: {
    flex: '0 1 280px',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    cardsContainer: {
      gap: '20px',
      justifyContent: 'flex-start',
    },
  },
  details: {
    marginTop: Spacing.medium,
    borderTop: `1px solid ${BorderColors.lighter}`,
    borderBottom: `1px solid ${BorderColors.lighter}`,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  detailItem: {
    flex: '1 1 50%',
    display: 'flex',
    flexDirection: 'column',
    padding: Spacing.medium,
  },
  detailItemFull: {
    flex: '1 1 100%',
    width: '100%',
  },
  detailValue: {
    color: TextColors.regularLight,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  calendarConnectedLabel: {
    color: TextColors.primaryLight,
  },
  calComLink: {
    fontSize: FontSizes.small,
    textWrap: 'nowrap',
  },
});

export default function UserCardListForSelectionTeam(
  props: Props,
): ReactElement {
  const styles = useStyles();

  return (
    <>
      <div className={styles.cardsContainer}>
        {props.users.length > 0 &&
          props.users.map((user) => {
            return (
              <div key={user.uid} className={styles.card}>
                <UserCard
                  user={user}
                  topMember={props.topUsers}
                  minimal
                  onClick={() => (props.track ? props.track(user) : undefined)}
                >
                  <div className={styles.details}>
                    <div className={styles.detailItem}>
                      <div className={styles.detailTitle}>Calendar</div>
                      <div className={styles.detailValue}>
                        {user.hasCalendarConnected ? (
                          'Connected'
                        ) : (
                          <span className={styles.calendarConnectedLabel}>
                            Not connected
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      className={cx(styles.detailItem, styles.detailItemFull)}
                    >
                      <div className={styles.detailTitle}>Interview type</div>
                      <div className={styles.detailValue}>
                        {vettingTypeToStatusMap[user.vettingType]}
                      </div>
                    </div>
                    <div
                      className={cx(styles.detailItem, styles.detailItemFull)}
                    >
                      <div className={styles.detailTitle}>Booking link</div>
                      <div className={styles.detailValue}>
                        {user.hasCalendarConnected ? (
                          <a
                            className={styles.calComLink}
                            href={`${REACT_APP_CALCOM_URL}/${user.username}/evaluation`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {`${REACT_APP_CALCOM_URL}/${user.username}/evaluation`}
                          </a>
                        ) : (
                          'None'
                        )}
                      </div>
                    </div>
                  </div>
                </UserCard>
              </div>
            );
          })}
      </div>
      {props.onLoadMore && <LoadMoreLink onLoadMore={props.onLoadMore} />}
    </>
  );
}
