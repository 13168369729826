export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getYears = (goBack = 30): number[] => {
  const now = new Date().getUTCFullYear();
  return Array(now - (now - goBack))
    .fill('')
    .map((v, idx) => now - idx);
};
