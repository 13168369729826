import { action, computed, observable } from 'mobx';
import { apiDiscovery } from '@src/logic/services/endpoints';
import { throttle } from 'lodash';
import { UserCardObject } from '@a_team/models/dist/UserObject';
import { QueryResult } from '@a_team/models/dist/misc';
import { ConnectionObject } from '@a_team/models/dist/ConnectionObject';
import { Stores } from '@src/stores/index';
import { PlatformServiceAnalytics } from '@ateams/analytics/dist/platform';
import { generateUniqueId } from '@src/helpers/strings';
import { DEFAULT_PROFILE_IMAGES } from '@a_team/models/dist/constants/Profile';

export interface SearchStoreData {
  searchQuery?: string;
  searchCategory: SearchCategories;
  recentSearches: SearchObject[];
  searchResults: SearchResults;
  searchResultsSummary: SearchResults;
  userSearch?: SearchObject;
  loadingResults: boolean;
}

export enum SearchCategories {
  Members = 'Members',
}

export type SearchObject = {
  _id: string;
  query: string;
  category: SearchCategories;
  user?: UserCardObject;
};
export type SearchResults = {
  users?: SearchObject[];
};

export default class SearchStore implements SearchStoreData {
  @observable searchQuery: SearchStoreData['searchQuery'] = '';
  @observable searchCategory: SearchStoreData['searchCategory'] =
    SearchCategories.Members;
  @observable recentSearches: SearchStoreData['recentSearches'] = [];
  @observable searchResults: SearchStoreData['searchResults'] = {};
  @observable
  searchResultsSummary: SearchStoreData['searchResultsSummary'] = {};
  @observable userSearch: SearchStoreData['userSearch'];
  @observable loadingResults: SearchStoreData['loadingResults'] = false;

  private rootStore: Stores;
  private analytics: PlatformServiceAnalytics;

  public constructor(
    rootStore: Stores,
    analytics: PlatformServiceAnalytics,
    initialState?: SearchStoreData,
  ) {
    this.rootStore = rootStore;
    this.analytics = analytics;
    if (initialState) {
      this.searchCategory = initialState.searchCategory;
      this.searchQuery = initialState.searchQuery;
      this.recentSearches = initialState.recentSearches;
      this.searchResults = initialState.searchResults;
      this.searchResultsSummary = initialState.searchResultsSummary;
    }

    this.getRecentHistory();
  }

  @computed get shownUserSearchResults(): SearchObject[] {
    return this.searchResultsSummary.users || [];
  }

  @computed get totalSearchItems(): SearchObject[] {
    return [...this.shownUserSearchResults, ...this.recentSearches];
  }

  @computed get totalSearchItemsLength(): number {
    return this.totalSearchItems.length;
  }

  @computed get searchUrl(): string {
    return `/search?category=${this.searchCategory}&query=${this.searchQuery}`;
  }

  @computed get searchResultsAvailable(): boolean {
    return this.searchResults && !!this.searchResults.users;
  }

  @computed get userResultsExist(): boolean {
    return !!(this.searchResults.users && this.searchResults.users.length > 0);
  }

  @computed get noResults(): boolean {
    return !this.userResultsExist;
  }

  @action setUserSearch = (search: SearchObject): void => {
    this.userSearch = search;
  };

  private debouncedSearch = throttle(() => this.fetchSearchResults(true), 600);

  @action onSearchQueryChange = (query: string): void => {
    this.searchQuery = query;
    this.debouncedSearch();
  };

  @action onCategoryChange = (category: string): void => {
    category in SearchCategories &&
      (this.searchCategory = category as SearchCategories);
  };

  @action onExistingSearchSelect = (searchItem: SearchObject): void => {
    this.searchCategory = searchItem.category;
    this.searchQuery = searchItem.query;
    this.analytics.trackSearchHistoryClicked(searchItem);
  };

  @action getRecentHistory = (): void => {
    const items =
      typeof window !== 'object' ? '' : localStorage.getItem('recentSearches');
    if (items) {
      this.recentSearches = JSON.parse(items);
    }
  };

  @action clearSearchHistory = (): void => {
    this.recentSearches = [];
    typeof window === 'object' &&
      localStorage.setItem(
        'recentSearches',
        JSON.stringify(this.recentSearches),
      );
  };

  @action addSearchHistory = (item: SearchObject): void => {
    this.userSearch && this.recentSearches.unshift(item);
    this.recentSearches.length > 3 && this.recentSearches.pop();
    typeof window === 'object' &&
      localStorage.setItem(
        'recentSearches',
        JSON.stringify(this.recentSearches),
      );
    this.userSearch &&
      this.analytics.trackSearchQuerySubmitted(this.userSearch);
  };

  @action fetchSearchResults = (
    summary = false,
    callback?: () => void,
  ): void => {
    this.loadingResults = true;
    if (!this.searchQuery || !this.searchCategory) {
      this.loadingResults = false;
      this.searchResults = {};
      this.userSearch = undefined;
      summary ? this.setUserResultsSummary() : this.setUserResults();
      return;
    }

    this.setUserSearch({
      _id: generateUniqueId(),
      query: this.searchQuery,
      category: this.searchCategory,
    });

    apiDiscovery
      .searchUsers(this.rootStore.auth, this.searchQuery)
      .then((results) => {
        summary
          ? this.setUserResultsSummary(results)
          : this.setUserResults(results);
        this.loadingResults = false;
      })
      .then(() => {
        callback && callback();
      });
  };

  @action setUserResults = (results?: QueryResult<UserCardObject>): void => {
    this.searchResults.users =
      results?.items
        .filter(
          ({ profilePictureURL }) =>
            !Object.values(DEFAULT_PROFILE_IMAGES).includes(profilePictureURL),
        )
        .map((item) => {
          return {
            _id: generateUniqueId(),
            query: '',
            category: SearchCategories.Members,
            user: item,
          };
        }) ?? [];
  };

  @action setUserResultsSummary = (
    results?: QueryResult<UserCardObject>,
  ): void => {
    this.searchResultsSummary.users =
      results?.items
        .filter(
          ({ profilePictureURL }) =>
            !Object.values(DEFAULT_PROFILE_IMAGES).includes(profilePictureURL),
        )
        .slice(0, 5)
        .map((item) => {
          return {
            _id: generateUniqueId(),
            query: '',
            category: SearchCategories.Members,
            user: item,
          };
        }) ?? [];
  };

  @action
  updateUserConnection = (connection: ConnectionObject): void => {
    if (!this.searchResults.users) return;

    const updatedUserIndex = this.searchResults.users.findIndex(
      (item) => item.user?.uid === connection.toUser.uid,
    );

    if (updatedUserIndex !== -1) {
      const existing = this.searchResults.users[updatedUserIndex].user;
      existing && (existing.connection = connection);
    }
  };

  public serialize(): SearchStoreData {
    return {
      searchQuery: this.searchQuery,
      searchCategory: this.searchCategory,
      recentSearches: this.recentSearches,
      searchResults: this.searchResults,
      searchResultsSummary: this.searchResultsSummary,
      loadingResults: this.loadingResults,
    };
  }
}
