import { Icon, IconType } from '@ateams/components';
import { DateISOString } from '@a_team/models/dist/misc';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import DateString from './DateString';

interface Props {
  role: string;
  commitment?: string;
  hourlyRate?: string;
  dateApplied?: DateISOString;
}

const useStyles = createUseStyles({
  detail: {
    margin: 0,

    '&:last-child': {
      marginBottom: '1em',
    },
  },
  icon: {
    marginRight: '0.5em',
  },
});

export default function ApplicationDetails(props: Props): ReactElement {
  const { role, commitment, hourlyRate, dateApplied } = props;

  const styles = useStyles();

  return (
    <div>
      <p className={styles.detail}>
        <Icon
          type={IconType.Applicant}
          size={'exact'}
          className={styles.icon}
        />
        {role}
      </p>
      {commitment && (
        <p className={styles.detail}>
          <Icon type={IconType.Clock} size={'exact'} className={styles.icon} />
          {commitment}
        </p>
      )}
      {hourlyRate && (
        <p className={styles.detail}>
          <Icon
            type={IconType.MoneyGray}
            size={'exact'}
            className={styles.icon}
          />
          {hourlyRate}
        </p>
      )}
      {dateApplied && <DateString dateApplied={dateApplied} />}
    </div>
  );
}
