import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { loadMission, MissionMatch } from '@src/url-loaders/loadMission';
import SectionHeading from '@src/components/SectionHeading';
import { useStores } from '@src/stores';
import { Redirect } from 'react-router-dom';
import { MissionControlBase, MissionPageLocation } from '@src/locations';
import MissionLayout from '@src/layouts/Mission';
import MissionApplicationObject, {
  ExclusiveStatus,
  MissionApplicationId,
} from '@a_team/models/dist/MissionApplicationObject';
import { createUseStyles } from 'react-jss';
import {
  Button as CallToActionButton,
  CollapsibleContainer,
  Divider,
  Spacing,
  RadioGroup,
} from '@ateams/components';
import RoleMembersSelector from '@src/views/Mission/Proposal/RoleMembersSelector/RoleMembersSelector';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import TextButton from '@src/components/TextButton';
import { TeamPreview } from '@src/views/Mission/Proposal/TeamPreview';
import ApplicationModal from '@src/components/Modal/ApplicationModal';
import useToggle from '@src/hooks/useToggle';
import {
  MissionAdminRole,
  MissionRoleId,
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import Loader from '@src/components/Loader';
import { MissionApplicationStatusUpdate } from '@ateams/api/dist/endpoints/Missions';
import { ExistingProposalTable } from '@src/views/Mission/Proposal/ExistingProposalTable';
import UserObject, { UserId } from '@a_team/models/dist/UserObject';
import BlurbsHistoryModal from './BlurbsHistoryModal';
import {
  ProposalData,
  TeamNarrativeType,
  ProposalDataCurrency,
  ProposalTfsPitch,
} from '@a_team/models/dist/ProposalObject';
import { isValidUrl } from '@src/helpers/urls';
import { validateStringLength } from '@src/helpers/richTextEditor';
import { BLURB_TEXT_LENGTH_LIMIT } from './RoleMembersSelector/Member';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@src/rq/keys';
import { TeamNarrative } from './TeamNarrative';
import { TeamBlurbInput } from './TeamBlurbInput';
import { apiTeamGraph } from '@ateams/api';
import { EnrichedTeamNarrativesResponse } from '@a_team/models/dist/TeamGraphObject';
import { isEmpty } from 'lodash';
import { PotentialTeams } from './PotentialTeams';
import { SuggestedTeamMemberWithApplication } from './SuggestedTeamMemberWithApplication';
import { getSuggestedTeamsWithApplications } from './utils';
import { PotentialExtensions } from './PotentialExtensions';
import { ProposalMemberPropertyToUpdate } from '@src/stores/Missions/Proposal';
import { FeatureFlagNames } from '@a_team/models/dist/FeatureFlag';
import { MissionAdminObject } from '@a_team/models/dist/MissionObject';

export const missionProposalViewLoader = loadMission;

interface Props {
  match: MissionMatch;
}

const useStyles = createUseStyles({
  sidebar: {
    width: '35%',
    marginLeft: Spacing.xLarge,
  },
  currencyWrapper: {
    marginTop: Spacing.small,
  },
});

// Make sure this stays in parity with the backend
// services/api-service/src/logic//missionPaymentCycles/utils.ts
const getRoleMargin = (
  mission: MissionAdminObject | null,
  role: MissionAdminRole,
): number | undefined => {
  return role?.margin ?? mission?.rolesMargin ?? 0;
};

const MissionProposalView = (props: Props) => {
  const { match } = props;
  const styles = useStyles();
  const { auth, missions } = useStores();
  const [loading, setLoading] = useLoadingState(null);
  const [applicationModalOpen, toggleApplicationModalOpen] = useToggle();
  const [draggedApplication, setDraggedApplication] = useState<
    MissionApplicationId | undefined
  >();
  const [historyModalUserId, setHistoryModalUserId] = useState<UserId>();

  const proposalCandidates = (
    missions.currentMission?.proposal?.candidates || []
  )
    .map((c) => c.userId)
    .filter((userId): userId is string => typeof userId === 'string');

  const teamNarrativesQuery = useQuery({
    queryKey: queryKeys.teamNarratives.get(proposalCandidates),
    queryFn: () =>
      apiTeamGraph.getEnrichedTeamNarratives(auth, {
        uids: proposalCandidates,
      }),
    enabled: false,
  });

  const applicantReviewTeamsResponse = useQuery({
    queryKey: queryKeys.teamNarratives.getApplicantReviewTeams(
      missions.currentMission?.mid || '',
    ),
    queryFn: async () => {
      if (!missions.currentMission?.mid) return;
      return apiTeamGraph.getApplicantReviewTeams(
        auth,
        missions.currentMission?.mid,
      );
    },
    enabled: auth.isAdmin,
  });

  const candidateUids = (missions.currentMission?.proposal?.candidates || [])
    .map((c) => c.userId)
    .filter((userId): userId is string => typeof userId === 'string');

  const teamNarrativesDataForExportQuery = useQuery({
    queryKey: queryKeys.teamNarratives.getForExport(candidateUids),
    queryFn: () =>
      apiTeamGraph.getEnrichedTeamNarrativesForExport(auth, {
        uids: candidateUids,
      }),
    onSuccess: (data) => {
      navigator.clipboard.writeText(
        JSON.stringify({
          ...data,
          userBlurbs: missions.currentMission?.proposal?.candidates.map(
            (candidate) => ({
              userFullName: candidate.fullName,
              blurb: candidate.tfsPitch?.blurb,
            }),
          ),
        }),
      );
    },
    enabled: false,
  });

  const onClickOnCopyTeamNarrativeData = async () => {
    setLoading(
      teamNarrativesDataForExportQuery
        .refetch()
        .then(() => 'Copied to clipboard')
        .catch(() => Promise.reject(`Couldn't copy to clipboard`)),
    );
  };

  const filteredPrecomputedTeams = useMemo(
    () =>
      getSuggestedTeamsWithApplications(
        applicantReviewTeamsResponse.data?.newTeams,
        missions.currentMission?.proposal?.applications || [],
      ),
    [
      applicantReviewTeamsResponse.data?.newTeams,
      missions.currentMission?.proposal?.applications,
    ],
  );

  const filteredExtensions = useMemo(() => {
    return getSuggestedTeamsWithApplications(
      applicantReviewTeamsResponse.data?.extensionTeams,
      missions.currentMission?.proposal?.applications || [],
    );
  }, [
    applicantReviewTeamsResponse.data?.extensionTeams,
    missions.currentMission?.proposal?.applications,
  ]);

  const currentApplicationPrecomputedTeams = useMemo(() => {
    return filteredPrecomputedTeams.filter((team) => {
      return Object.values(team.members).some(
        (member) =>
          member.application.aid ===
          missions.currentApplication?.application?.aid,
      );
    });
  }, [filteredPrecomputedTeams, missions.currentApplication]);

  const currentApplicationExtensions = useMemo(() => {
    return filteredExtensions.filter((team) => {
      return Object.values(team.members).some(
        (member) =>
          member.application.aid ===
          missions.currentApplication?.application?.aid,
      );
    });
  }, [filteredExtensions, missions.currentApplication]);

  useEffect(() => {
    if (!auth.isAdmin) return;
    !missions.currentMission?.proposal &&
      missions.currentMission?.setProposal();
  }, [missions.currentMission, auth.isAdmin]);

  const proposalHasMoreThanOneCandidatePerRole =
    missions.currentMission?.proposal?.formData.roles.some(
      (role) => role.candidates.length > 1,
    );

  const onAddTeamMembers = (
    members: {
      aid: MissionApplicationId;
      rid: MissionRoleId;
      fromIndex?: number;
      toIndex?: number;
    }[],
  ) => {
    const memberRoleIds = members.map((member) => member.rid);
    if (
      missions.currentMission?.proposal?.isNarrativeProposal &&
      proposal.formData.roles
        .filter((r) => memberRoleIds.includes(r.id))
        .some((r) => r.candidates.length > 0)
    ) {
      setLoading(
        new Promise(() => {
          throw new Error(
            `Can't add more than one team member per role to narrative proposal`,
          );
        }),
      );
      return;
    }
    setLoading(
      Promise.all(
        members.map((member) =>
          proposal?.addTeamMember(
            member.aid,
            member.rid,
            undefined,
            member.fromIndex,
            member.toIndex,
          ),
        ),
      ),
      null,
    );
  };

  const onClickOnProposeTeam = async (
    team: Record<string, SuggestedTeamMemberWithApplication>,
  ) => {
    if (!applications) return;
    missions.currentMission?.proposal?.updateIsNarrativeProposal(true);
    onAddTeamMembers(
      Object.keys(team)
        .map((roleId) => {
          const member = team[roleId];
          const aid = member.application.aid;
          const rid = roleId as MissionRoleId;
          if (!aid || !rid) {
            return undefined;
          }
          return {
            aid,
            rid,
          };
        })
        .filter(
          (x): x is { aid: MissionApplicationId; rid: MissionRoleId } => !!x,
        ),
    );
  };

  const onIsNarrativeProposalChange = (value: boolean) => {
    if (value) {
      teamNarrativesQuery.refetch();
    } else {
      missions.currentMission?.proposal?.updateNarrativeTypesSelected([]);
    }
    missions.currentMission?.proposal?.updateIsNarrativeProposal(value);
  };

  useEffect(() => {
    if (
      missions.currentMission?.proposal?.isNarrativeProposal &&
      !!missions.currentMission?.proposal?.candidates.length &&
      missions.currentMission?.proposal?.candidates.length > 1
    ) {
      teamNarrativesQuery.refetch();
    }
  }, [missions.currentMission?.proposal?.candidates]);

  useEffect(() => {
    if (!teamNarrativesQuery.data) {
      return;
    }
    const narratives = Object.keys(teamNarrativesQuery.data).slice(0, 3);
    missions.currentMission?.proposal?.updateNarrativeTypesSelected(narratives);
  }, [teamNarrativesQuery.data]);

  const viewApplicantSubmission = async (
    application: MissionApplicationObject,
  ): Promise<void> => {
    toggleApplicationModalOpen();
    missions.getApplicationProfile(
      application.user.username,
      application,
      application.rid,
    );
  };

  const handleApplicationStatusChange = async (
    aid: MissionApplicationId,
    data: MissionApplicationStatusUpdate,
  ): Promise<void> => {
    if (!missions.currentMission) return;

    const res = await missions.currentMission.updateMissionApplicationStatus(
      aid,
      data,
    );

    missions.currentApplication?.application?.setApplicationStatus(res);
    missions.currentMission.proposal?.updateApplicationsExclusiveStatus(
      res.user.uid,
      res.exclusiveStatus === ExclusiveStatus.Exclusive ? res.aid : undefined,
    );
  };

  if (!auth.isAdmin) {
    return (
      <Redirect
        to={
          match.params.mid
            ? MissionPageLocation(match.params.mid)
            : MissionControlBase
        }
      />
    );
  }

  if (!missions.currentMission?.proposal) {
    return <Loader />;
  }

  const showTfsPitch = auth.isFeatureOpen(FeatureFlagNames.TFSProposalBlurb);

  const { proposal } = missions.currentMission;
  const {
    formData,
    roles,
    candidates,
    applications,
    generateProposal,
    updateProposal,
    url,
    proposals,
    builderTfsPitches,
  } = proposal;

  const openRoles = formData.roles.filter(
    (e) => e.status === MissionRoleStatus.Open,
  );
  const roleGroups: Record<string, typeof formData.roles> = {};
  const currentUserHistoryModal =
    historyModalUserId && proposal.users[historyModalUserId];

  const currentUserTfsPitches =
    historyModalUserId && builderTfsPitches.has(historyModalUserId)
      ? builderTfsPitches.get(historyModalUserId)
      : [];

  formData.roles
    .filter((e) => e.status !== MissionRoleStatus.Open)
    .forEach((e) => {
      if (!roleGroups[e.status]) {
        roleGroups[e.status] = [];
      }
      roleGroups[e.status].push(e);
    });

  const handleSetBlurb = (blurb: ProposalTfsPitch['blurb']): void => {
    const { aid } =
      proposal.candidates.find(({ userId }) => userId === historyModalUserId) ||
      {};

    if (aid && blurb) {
      proposal.updateMemberData(
        aid,
        ProposalMemberPropertyToUpdate.Blurb,
        blurb,
      );
    }

    setHistoryModalUserId(undefined);
  };

  const onClickOnClearTeam = async (): Promise<void> => {
    proposal.clearTeamMembers();
  };

  const proposalFormDataIsInvalid =
    !candidates.length ||
    !!url ||
    (showTfsPitch &&
      !candidates.every((c) =>
        validateStringLength(c.tfsPitch?.blurb, BLURB_TEXT_LENGTH_LIMIT),
      )) || // all candidates must have a valid blurb
    (showTfsPitch &&
      !candidates.every((c) =>
        (c.tfsPitch?.website || '') !== ''
          ? isValidUrl(c.tfsPitch?.website || '')
          : true,
      )) ||
    (showTfsPitch &&
      !candidates.every((c) =>
        (c.githubUrl || '') !== '' ? isValidUrl(c.githubUrl || '') : true,
      )) ||
    (showTfsPitch &&
      !candidates.every((c) =>
        (c.cvUrl || '') !== ''
          ? isValidUrl((c.cvUrl || '').trim().replace(/ /g, '%20'))
          : true,
      )) ||
    (showTfsPitch &&
      !candidates.every((c) =>
        (c.portfolioUrl || '') !== '' ? isValidUrl(c.portfolioUrl || '') : true,
      ));

  return (
    <MissionLayout title={'Create Proposal'} match={props.match}>
      <ApplicationModal
        profile={missions.currentApplication}
        open={applicationModalOpen}
        onClose={() => {
          toggleApplicationModalOpen(false);
          missions.resetCurrentApplication();
        }}
        onStatusChanged={handleApplicationStatusChange}
        currentMission={missions.currentMission}
        precomputedTeams={currentApplicationPrecomputedTeams}
        extensions={currentApplicationExtensions}
      />
      <div style={{ padding: '0 10%' }}>
        <div>
          {proposals?.records && proposals?.records?.length > 0 && (
            <>
              <SectionHeading>Existing proposals</SectionHeading>
              <ExistingProposalTable
                proposals={proposals}
                updateProposal={updateProposal}
                setLoading={setLoading}
              />
            </>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1 }}>
            <h2>Create team proposal</h2>
          </div>
          <div style={{ flex: 0 }}>
            <div className={styles.currencyWrapper}>
              <RadioGroup
                options={[
                  { label: '$', value: ProposalDataCurrency.USD },
                  { label: '€', value: ProposalDataCurrency.EUR },
                ]}
                value={formData.currency}
                onChange={(value) => {
                  proposal?.updateCurrency(value as ProposalDataCurrency);
                }}
              />
            </div>
          </div>
        </div>
        <Divider margin="compact" />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1 }}>
            {filteredPrecomputedTeams.length ? (
              <PotentialTeams
                showHeader
                missionStatus={missions.currentMission?.data.status}
                proposeTeamDisabled={!!proposal.candidates.length}
                onClickOnProposeTeam={onClickOnProposeTeam}
                teams={filteredPrecomputedTeams || []}
                openRoles={openRoles}
                applications={applications}
              />
            ) : null}
            {filteredExtensions.length ? (
              <PotentialExtensions
                showHeader
                missionStatus={missions.currentMission?.data.status}
                onClickOnProposeTeam={onClickOnProposeTeam}
                teams={filteredExtensions || []}
                openRoles={openRoles}
                applications={applications}
              />
            ) : null}
            <SectionHeading isFirst style={{ marginBottom: 8 }}>
              Team Members
            </SectionHeading>
            <p style={{ marginTop: 0, marginBottom: 32 }}>
              Select the proposed members for each open role
            </p>
            {
              // put open roles first and other last
              openRoles.map((role, i) => {
                const margin = getRoleMargin(
                  missions.currentMission?.data as MissionAdminObject,
                  role,
                );

                return (
                  <>
                    <RoleMembersSelector
                      currency={formData.currency}
                      key={role.id}
                      role={roles.find(
                        (missionRole) => missionRole.rid === role.id,
                      )}
                      applications={applications}
                      members={role.candidates}
                      missionRolesMargin={margin || 0}
                      onMemberAdd={(aid, rid, fromIndex, toIndex) =>
                        onAddTeamMembers([{ aid, rid, fromIndex, toIndex }])
                      }
                      onMemberRemove={(aid) =>
                        proposal.removeTeamMember(aid, role.id)
                      }
                      onIncludeReplyToggle={proposal.updateIncludeReply}
                      onMemberDataEdit={proposal.updateMemberData}
                      onApplicationClick={(
                        application: MissionApplicationObject,
                      ) => viewApplicantSubmission(application)}
                      onRoleOrderChange={proposal?.changeRoleOrder}
                      draggedApplication={draggedApplication}
                      setDraggedApplication={setDraggedApplication}
                      allowOrderIncrease={i !== 0}
                      allowOrderDecrease={i < formData.roles.length - 1}
                      showTfsPitch={showTfsPitch}
                      onOpenBlurbHistory={setHistoryModalUserId}
                      tfsPitches={builderTfsPitches}
                    />
                  </>
                );
              })
            }
            {Object.entries(roleGroups).map(([status, roleGroup]) => {
              return (
                <div key={status}>
                  {roleGroup.length > 0 && (
                    <CollapsibleContainer
                      title={`${status} Roles`}
                      openDefault={false}
                    >
                      {roleGroup.map((role, i) => {
                        const margin = getRoleMargin(
                          missions.currentMission?.data as MissionAdminObject,
                          role,
                        );
                        return (
                          <RoleMembersSelector
                            currency={formData.currency}
                            key={role.id}
                            role={roles.find(
                              (missionRole) => missionRole.rid === role.id,
                            )}
                            applications={applications}
                            members={role.candidates}
                            missionRolesMargin={margin || 0}
                            onMemberAdd={(aid, rid, fromIndex, toIndex) =>
                              onAddTeamMembers([
                                { aid, rid, fromIndex, toIndex },
                              ])
                            }
                            onIncludeReplyToggle={proposal.updateIncludeReply}
                            onMemberRemove={(aid) =>
                              proposal.removeTeamMember(aid, role.id)
                            }
                            onMemberDataEdit={proposal.updateMemberData}
                            onApplicationClick={(
                              application: MissionApplicationObject,
                            ) => viewApplicantSubmission(application)}
                            onRoleOrderChange={proposal?.changeRoleOrder}
                            draggedApplication={draggedApplication}
                            setDraggedApplication={setDraggedApplication}
                            allowOrderIncrease={i !== 0}
                            allowOrderDecrease={i < formData.roles.length - 1}
                            onOpenBlurbHistory={setHistoryModalUserId}
                            tfsPitches={builderTfsPitches}
                            showTfsPitch={showTfsPitch}
                          />
                        );
                      })}
                    </CollapsibleContainer>
                  )}
                </div>
              );
            })}
          </div>
          <div className={styles.sidebar}>
            <TeamPreview
              currency={formData.currency}
              narrativeProposalDisabled={proposalHasMoreThanOneCandidatePerRole}
              isNarrativeProposal={
                missions.currentMission?.proposal?.isNarrativeProposal || false
              }
              onIsNarrativeProposalChange={(value) =>
                onIsNarrativeProposalChange(value)
              }
              members={candidates}
              onClickOnClearTeam={onClickOnClearTeam}
              isShowingRatesForAllBuilders={
                missions.currentMission?.proposal?.isShowingRatesForAllBuilders
              }
              onIsShowingRatesForAllBuildersChange={(value) =>
                missions.currentMission?.proposal?.updateShowRatesForAllCandidates(
                  value,
                )
              }
            />
            {missions.currentMission?.proposal?.isNarrativeProposal &&
              !isEmpty(teamNarrativesQuery.data) &&
              !!missions.currentMission?.proposal?.candidates.length &&
              missions.currentMission?.proposal?.candidates.length > 1 &&
              !teamNarrativesQuery.isFetching && (
                <>
                  <TeamBlurbInput
                    value={missions.currentMission?.proposal?.teamBlurb || ''}
                    onValueChange={(value) =>
                      missions.currentMission?.proposal?.setTeamBlurb(value)
                    }
                  />
                  <TeamNarrative
                    onClickOnCopyTeamNarrativeData={() =>
                      onClickOnCopyTeamNarrativeData()
                    }
                    teamNarrativeTypesSelected={
                      missions.currentMission?.proposal?.narrativeTypesSelected
                    }
                    onTeamNarrativeTypesSelectedChange={(types) =>
                      missions.currentMission?.proposal?.updateNarrativeTypesSelected(
                        types,
                      )
                    }
                    enrichedTeamNarratives={teamNarrativesQuery.data}
                    isLoading={teamNarrativesQuery.isLoading}
                  />
                </>
              )}
            {missions.currentMission?.proposal?.isNarrativeProposal &&
              !!missions.currentMission?.proposal?.candidates.length &&
              missions.currentMission?.proposal?.candidates.length > 1 && (
                <>
                  {isEmpty(teamNarrativesQuery.data) &&
                    !teamNarrativesQuery.isFetching && (
                      <>
                        <p>No team narratives found for the given team</p>
                      </>
                    )}
                  {teamNarrativesQuery.isFetching && (
                    <>
                      <p>Loading team narratives...</p>
                    </>
                  )}
                </>
              )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 32,
          }}
        >
          <CallToActionButton
            disabled={proposalFormDataIsInvalid}
            onClick={() =>
              setLoading(
                generateProposal(
                  missions,
                  missions.currentMission?.proposal?.teamBlurb,
                  missions.currentMission?.proposal?.isNarrativeProposal
                    ? ((
                        missions.currentMission?.proposal
                          ?.narrativeTypesSelected || []
                      )
                        .map((narrativeType) => {
                          const type =
                            getTeamNarrativeTypeFromKey(narrativeType);
                          const text =
                            teamNarrativesQuery.data?.[
                              narrativeType as keyof EnrichedTeamNarrativesResponse
                            ]?.overlapText;
                          const builderUids = teamNarrativesQuery.data?.[
                            narrativeType as keyof EnrichedTeamNarrativesResponse
                          ]?.overlapUsers.map((user) => user.uid);

                          if (!type || !text || !builderUids) {
                            return undefined;
                          }
                          return {
                            type,
                            text,
                            builderUids,
                          };
                        })
                        .filter(
                          (narrative) => !!narrative,
                        ) as ProposalData['teamNarratives'])
                    : undefined,
                ),
              )
            }
          >
            Generate proposal
          </CallToActionButton>
          {url && (
            <TextButton
              highlight
              onClick={() => window.open(`${url}?edit=1`)}
              style={{ marginLeft: 32 }}
            >
              View Proposal
            </TextButton>
          )}
        </div>
      </div>
      <LoadingIndicator loading={loading} />
      {currentUserTfsPitches && currentUserTfsPitches.length > 0 && (
        <BlurbsHistoryModal
          builderName={(currentUserHistoryModal as UserObject).firstName}
          pitches={currentUserTfsPitches}
          onClose={() => {
            setHistoryModalUserId(undefined);
          }}
          isOpen={!!historyModalUserId}
          onBlurbSelected={handleSetBlurb}
        />
      )}
    </MissionLayout>
  );
};

export default observer(MissionProposalView);

const getTeamNarrativeTypeFromKey = (
  key: string,
): TeamNarrativeType | undefined => {
  switch (key) {
    case 'companies':
      return TeamNarrativeType.COMPANY;
    case 'projects':
      return TeamNarrativeType.PROJECT;
    case 'missions':
      return TeamNarrativeType.MISSION;
    case 'invitations':
      return TeamNarrativeType.INVITATION;
    case 'missionRecommendations':
      return TeamNarrativeType.TEAM_UP;
  }
  return;
};
