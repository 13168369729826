import Circle from '@src/components/Circle';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import CheckIcon from '@src/components/CheckIcon';

type DivProps = JSX.IntrinsicElements['div'];

export interface Props extends DivProps {
  completed?: boolean;
  icon?: (props: JSX.IntrinsicElements['div']) => ReactElement | null;
  title: string;
  subtitle?: string;
  disabled?: boolean;
  active?: boolean;
}

const useStyles = createUseStyles<Props>({
  wrapper: ({ disabled }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    width: '224px',
    cursor: disabled ? 'unset' : 'pointer',
  }),
  circle: ({ disabled }) => ({
    flex: '0 0 32px',
    overflow: 'hidden',
    opacity: disabled ? 0.5 : 1,
  }),
  completed: {
    backgroundColor: '#79CE00',
  },
  checkMark: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labels: {
    marginLeft: '16px',
  },
  title: ({ active, disabled }) => ({
    textOverflow: 'ellipsis',
    height: '32px',
    fontSize: '15px',
    lineHeight: '32px',
    margin: 0,
    color: active ? '#222222' : disabled ? '#62646A' : '#C0C0C0',
  }),
  subtitle: ({ active, disabled }) => ({
    fontSize: '12px',
    lineHeight: '16px',
    margin: 0,
    marginTop: '12px',
    color: active ? '#222222' : disabled ? '#62646A' : '#C0C0C0',
  }),
  icon: {},
});

const TeamPulseSidebarQuestionItem = (props: Props): ReactElement | null => {
  const styles = useStyles(props);
  const {
    completed,
    icon: QuestionIcon,
    title,
    subtitle,
    className,
    active,
    disabled,
    ...rest
  } = props;
  return (
    <div className={cx(styles.wrapper, className)} {...rest}>
      {completed ? (
        <Circle
          className={styles.circle}
          inner={{ className: styles.completed }}
        >
          <div className={styles.checkMark}>
            <CheckIcon fill="#ffffff" />
          </div>
        </Circle>
      ) : (
        <Circle className={styles.circle}>
          {QuestionIcon && <QuestionIcon className={styles.icon} />}
        </Circle>
      )}
      <div className={styles.labels}>
        <p className={styles.title}>{title}</p>
        {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      </div>
    </div>
  );
};

export default TeamPulseSidebarQuestionItem;
