import { Expertise } from '@src/stores/Profile/models';
import {
  TalentSpecialization,
  UserTalentSkillAssignment,
} from '@a_team/models/dist/TalentCategories';

export const skillToExpertise = (
  skill: UserTalentSkillAssignment,
  featured?: boolean,
): Expertise => {
  return {
    id: skill.talentSkillId,
    name: skill.talentSkillName ?? '',
    rating: skill.rating,
    featured: !!featured,
  };
};

export const roleToExpertise = (
  role: TalentSpecialization,
  featured?: boolean,
): Expertise => {
  return {
    id: role.id,
    name: role.name,
    featured: !!featured,
    categoryIds: role.talentCategoryIds,
  };
};

export const expertiseToSkill = (
  expertise: Expertise,
): UserTalentSkillAssignment => {
  return {
    talentSkillId: expertise.id,
    rating: expertise.rating,
    talentSkillName: expertise.name,
  };
};
