import { DateISOString } from '@a_team/models/dist/misc';

export enum SystemStatus {
  Operational = 'Operational',
  UnderMaintenance = 'UnderMaintenance',
  DegradedPerformance = 'DegradedPerformance',
  PartialOutage = 'PartialOutage',
  MajorOutage = 'MajorOutage',
}

export default interface StatusResponse {
  status: SystemStatus;
  components: Record<string, SystemStatus>;
  updatedAt: DateISOString;
}
