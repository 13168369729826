import React from 'react';

export const DraftsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        opacity="0.6"
        d="M5 5H10M7 8H5"
        stroke="#202020"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 13H4C2.89543 13 2 12.1046 2 11V4C2 2.89543 2.89543 2 4 2H11C12.1046 2 13 2.89543 13 4V8"
        stroke="#202020"
        strokeWidth="1.4"
      />
      <path
        d="M9.18038 15H7V12.8196C7 12.5544 7.10536 12.3 7.29289 12.1125L11.6983 7.70711C12.0888 7.31658 12.722 7.31658 13.1125 7.70711L14.2929 8.88749C14.6834 9.27801 14.6834 9.91118 14.2929 10.3017L9.88749 14.7071C9.69995 14.8946 9.4456 15 9.18038 15Z"
        stroke="#202020"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
