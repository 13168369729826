import React from 'react';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import {
  VettingProcessAudit,
  VettingProcessAuditTypes,
} from '@a_team/models/dist/vetting-process-audit';
import {
  OptOutReasonTypes,
  optOutReasonTypeToLabelMap,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { Spacing, TextColors } from '@ateams/components';
import { Text } from '@src/views/VettingFeedbackForm/components/typography';
import { ActivityRecordMainText } from '.';

export interface BuilderOptedOutOfVettingActivityRecordProps {
  vettingProcessAudit: VettingProcessAudit<VettingProcessAuditTypes.BuilderOptedOutOfVetting>;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainText: {
    color: TextColors.dangerLight,
    marginBottom: Spacing.small,
  },
});

export const BuilderOptedOutOfVettingActivityRecord: React.FC<
  BuilderOptedOutOfVettingActivityRecordProps
> = ({ vettingProcessAudit }) => {
  const styles = useStyles();

  const isDidNotShowUpToTheCall =
    vettingProcessAudit.payload.reason ===
    optOutReasonTypeToLabelMap[OptOutReasonTypes.DidNotShowUpToTheCall];

  if (isDidNotShowUpToTheCall) {
    return (
      <div className={styles.container}>
        <ActivityRecordMainText className={styles.mainText}>
          <b>Builder didn’t show up</b>
          {vettingProcessAudit.date
            ? ` on ${format(
                new Date(vettingProcessAudit.date),
                'MMMM d, yyyy',
              )}`
            : ''}
        </ActivityRecordMainText>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <ActivityRecordMainText className={styles.mainText}>
        Builder opted out of evaluation
      </ActivityRecordMainText>

      <Text>{vettingProcessAudit.payload.reason}</Text>
    </div>
  );
};
