import { Stores } from '@src/stores';

export async function loadMissionControl(stores: Stores): Promise<unknown> {
  const { missionControl } = stores;
  const loaded =
    !!missionControl.allMissions &&
    !!missionControl.recommended &&
    !!missionControl.applied &&
    !!missionControl.notInterested &&
    !!missionControl.completed;

  if (loaded) return Promise.resolve();

  return stores.missionControl.loadMissionControl();
}
