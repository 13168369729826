import { createUseStyles } from 'react-jss';
import { Classes } from 'jss';

const useStyles: () => Classes = createUseStyles({
  container: {
    marginTop: 40,
  },
  halfWidthContainer: {
    width: '50%',
  },
  title: {
    color: '#35373A',
    fontSize: 16,
    fontWeight: 500,
  },
  description: {
    marginTop: 4,
    marginBottom: 8,
    color: '#62646A',
    fontSize: 15,
    fontWeight: 400,
  },
  box: {
    marginTop: 8,
    padding: 16,
    border: '1px solid #DADADC',
    borderRadius: 8,
  },
  dottedBox: {
    border: '1px dotted #DADADC',
  },
  inputWithUnderline: {
    padding: '10px 12px',
    border: 'none',
    outline: 'none',
    background: 'none',
    borderBottom: '1px solid #DADADC',
    width: '100%',
  },
  borderlessInput: {
    border: 'none',
    outline: 'none',
    background: 'none',
    width: '100%',
  },
});

export default useStyles;
