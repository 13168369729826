import React, { ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { Colors } from '@ateams/components';
import checkIcon from './check.svg';
import starIcon from './star.svg';

interface Props {
  icon?: 'check' | 'star';
  children: ReactNode;
}

const useStyles = createUseStyles({
  root: {
    backgroundColor: (props: Props) =>
      props.icon === 'star' ? Colors.info : Colors.success,
    backgroundImage: (props: Props) =>
      `url("${props.icon === 'star' ? starIcon : checkIcon}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '24px center',
    color: '#fff',
    padding: '8px 8px 8px 78px',
    borderRadius: '0 0 8px 8px',
  },
});

export default function RoleRowNotification(props: Props): ReactElement {
  const { children } = props;
  const styles = useStyles(props);

  return <div className={styles.root}>{children}</div>;
}
