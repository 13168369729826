import { Button, Modal } from '@a_team/ui-components';
import { Breakpoints } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface TeamUpRequestNoLongerAvailableProps {
  open: boolean;
  handleModalClose: () => void;
}

const useStyles = createUseStyles({
  title: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
    margin: 0,
    textAlign: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  content: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#818388',
    textAlign: 'center',
    margin: 0,
  },
  modal: {
    width: 'auto',
    padding: 0,
    '& > div': {
      padding: 0,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 40,
    margin: 40,
    maxWidth: '800px',
  },
  closeBtn: {
    width: '120px !important',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      minWidth: 680,
      borderRadius: '16px !important',
    },
  },
});

const TeamUpRequestNoLongerAvailable = ({
  open,
  handleModalClose,
}: TeamUpRequestNoLongerAvailableProps): JSX.Element => {
  const styles = useStyles();
  return (
    <Modal
      variant="slideUp"
      isOpen={open}
      onClose={handleModalClose}
      className={styles.modal}
      shouldHideGradientStroke={true}
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h4 className={styles.title}>
            This team up request is no longer available
          </h4>
          <p className={styles.content}>
            The builder wasn't selected for the mission or withdrew their
            application and is no longer available to team up.{' '}
          </p>
        </div>
        <Button
          className={styles.closeBtn}
          size="md"
          variant="secondary"
          onClick={handleModalClose}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default TeamUpRequestNoLongerAvailable;
