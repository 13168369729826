import { CalComBookingFrequency } from '@a_team/models/src/vetting-processes/calcom';
import { ServiceAuth, ServiceEndpoint } from './utils';
import { EvaluationSettingsObject } from '@a_team/models/dist/EvaluationSettingsObject';

export const BasePath = '/evaluation-settings';

// exported functions

export default class EvaluationSettingsEndpoint extends ServiceEndpoint {
  public fetchEvaluationSettingsData(
    auth: ServiceAuth,
  ): Promise<EvaluationSettingsObject> {
    return this.fetch(auth, BasePath + `/`, null, 'get');
  }

  public saveBookingFrequency(
    auth: ServiceAuth,
    eventTypeId: number,
    bookingFrequency: CalComBookingFrequency | null,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/calcom-booking-frequency`,
      null,
      'PATCH',
      { eventTypeId, bookingFrequency },
    );
  }

  public enableDisableInterviewer(
    auth: ServiceAuth,
    isActive: boolean,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/enable-disable-interviewer`,
      null,
      'PATCH',
      {
        isActive,
      },
    );
  }
}
