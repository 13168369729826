import React from 'react';
import cx from 'classnames';
import { AnalysisScoreObject } from '@a_team/models/dist/MissionApplicationObject';
import { theme } from '@a_team/ui-components';
import { createUseStyles } from 'react-jss';
import { AnalysisScore } from './AnalysisScore';

interface AnalysisScoresProps {
  scores: AnalysisScoreObject;
  className?: string;
}

const useAnalysisScoresStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.Grey[0] as string,
    width: '788px',
    height: '104px',
    borderRadius: '8px',
    padding: '16px',
  },
});

export const AnalysisScores: React.FC<AnalysisScoresProps> = (props) => {
  const { scores, className } = props;
  const styles = useAnalysisScoresStyles();

  return (
    <div className={cx(styles.container, className)}>
      {typeof scores.overall === 'number' && (
        <AnalysisScore heading={'Overall Score'} score={scores.overall} />
      )}
      {typeof scores.clarity === 'number' && (
        <AnalysisScore heading={'Clarity'} score={scores.clarity} />
      )}
      {typeof scores.pca === 'number' && (
        <AnalysisScore heading={'PCA'} score={scores.pca} />
      )}
      {typeof scores.experience === 'number' && (
        <AnalysisScore heading={'Experience'} score={scores.experience} />
      )}
      {typeof scores.skills === 'number' && (
        <AnalysisScore heading={'Skills'} score={scores.skills} />
      )}
      {typeof scores.locations === 'number' && (
        <AnalysisScore heading={'Location'} score={scores.locations} />
      )}
    </div>
  );
};
