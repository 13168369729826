import React, { ReactElement, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router-dom';
import useWindowSize from '@src/hooks/useWindowSize';
import MainLayout from '@src/layouts/Main';
import { Colors, Spacing } from '@ateams/components';

export const useStyles = createUseStyles({
  content: {
    background: Colors.backgroundWhite,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100%',
    padding: 0,
    paddingTop: Spacing.xLarge,
    paddingBottom: Spacing.xLarge,
  },
});

const SelectionTeamRecordingView = (): ReactElement => {
  const styles = useStyles();
  const size = useWindowSize();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const recordingUrl = queryParams.get('recordingUrl');
  const startTime = queryParams.get('startTime');
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current && startTime) {
      const startSeconds = parseFloat(startTime);
      if (!isNaN(startSeconds)) {
        videoRef.current.currentTime = startSeconds;
      }
    }
  }, [recordingUrl, startTime]);

  return (
    <MainLayout title="Recording" contentClassName={styles.content}>
      {recordingUrl && (
        <>
          <video
            ref={videoRef}
            width={size.width - 150}
            height={size.height - 100}
            controls
          >
            <source src={recordingUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </>
      )}
    </MainLayout>
  );
};

export default SelectionTeamRecordingView;
