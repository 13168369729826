import {
  BorderRadius,
  Colors,
  Icon,
  IconType,
  Spacing,
  TextColors,
} from '@ateams/components';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  banner: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
    alignItems: 'center',
    background: Colors.banner,
    height: 64,
    borderRadius: BorderRadius.medium,
    padding: Spacing.medium,
  },
  icon: {
    width: 32,
    height: 32,
  },
  text: {
    fontSize: 15,
    color: TextColors.regular,
  },
  bannerMemberTop: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
    alignItems: 'center',
    background: Colors.bannerTop,
    height: 40,
    borderTopLeftRadius: Spacing.small,
    borderTopRightRadius: Spacing.small,
    padding: Spacing.medium,
  },
  iconMemberTop: {
    width: 16,
    height: 16,
  },
});

interface Props {
  isMemberTop?: boolean;
}

const StarredBuilderBanner = ({ isMemberTop }: Props): ReactElement => {
  const styles = useStyles();

  return (
    <div className={isMemberTop ? styles.bannerMemberTop : styles.banner}>
      <Icon
        type={IconType.StarredBuilder}
        size="exact"
        className={isMemberTop ? styles.iconMemberTop : styles.icon}
      />
      <div className={styles.text}>
        The company starred this builder's profile.
      </div>
    </div>
  );
};

export default StarredBuilderBanner;
