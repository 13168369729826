import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import { PreVettingLayout } from '@src/layouts/PreVetting';
import { useHistory } from 'react-router-dom';
import { PreVettingFormSubmittedLocation } from '@src/locations';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { useValidateURL } from './use-validate-url';
import { OnPreVettingFormSubmit, PreVettingForm } from './form';
import { SubmitPreVettingFormPayload } from '@ateams/api/dist/endpoints/vetting-process-pre-vetting';

const PreVettingView: React.FC = () => {
  const { auth, vettingProcesses } = useStores();
  const history = useHistory();
  const { nonce, loading: validateNonceLoading } = useValidateURL();
  const [submitFormLoading, setSubmitFormLoading] = useLoadingState();

  const createFormData = (payload: SubmitPreVettingFormPayload): FormData => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      if (key === 'additionalMaterialFiles' && Array.isArray(value)) {
        value.forEach((file) => {
          formData.append('additionalMaterialFiles', file);
        });
      } else if (Array.isArray(value)) {
        value.forEach((item) => {
          formData.append(key, item);
        });
      } else if (
        typeof value === 'object' &&
        !(value instanceof File) &&
        !Array.isArray(value)
      ) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value as string);
      }
    }
    return formData;
  };

  const onSubmit: OnPreVettingFormSubmit = (payload) => {
    setSubmitFormLoading(
      (async () => {
        const formData = createFormData(payload);

        await vettingProcesses.submitPreVettingForm(nonce, formData);

        if (auth.withNewEvaluation) {
          const { pathname, search } = history.location;
          const newPathname = pathname.replace('/form/', '/schedule/');
          const newUrl = `${newPathname}${search}`;
          history.replace(newUrl);
        } else {
          history.push(PreVettingFormSubmittedLocation);
        }
      })(),
    );
  };

  return (
    <PreVettingLayout title={'Pre-evaluation'}>
      {!validateNonceLoading &&
        vettingProcesses.preVettingFormUser &&
        vettingProcesses.formVariant && (
          <PreVettingForm
            variant={vettingProcesses.formVariant}
            userId={vettingProcesses.preVettingFormUser.id}
            firstName={vettingProcesses.preVettingFormUser.firstName}
            onSubmit={onSubmit}
          />
        )}
      <LoadingIndicator loading={validateNonceLoading || submitFormLoading} />
    </PreVettingLayout>
  );
};

export default observer(PreVettingView);
