import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  Icon,
  IconType,
  Spacing,
} from '@ateams/components';
import { useCommonStyles } from '../commonStyles';
import { VettingFeedbackFormAdditionalMaterial } from '@ateams/api/dist/endpoints/vetting-process-feedback-v2';

const useStyles = createUseStyles({
  uploadFilesContainer: {
    borderRadius: BorderRadius.xLarge,
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
  },
  uploadButton: {
    width: 'fit-content',
    padding: Spacing.small,
    borderRadius: BorderRadius.medium,
    border: `1px solid ${Colors.secondaryDark}`,
    backgroundColor: Colors.backgroundWhite,
    cursor: 'pointer',
    textAlign: 'center',
    color: Colors.secondaryDark,
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.xsmall,
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadIcon: {
    width: 16,
    height: 16,
  },
  uploadText: {
    fontSize: FontSizes.xsmall,
    lineHeight: '16px',
  },
  fileList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: Spacing.small,
  },
  fileItem: {
    width: 'fit-content',
    height: 32,
    display: 'flex',
    gap: Spacing.small,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: Spacing.small,
    backgroundColor: Colors.backgroundWhite,
    border: `1px solid ${BorderColors.lighter}`,
    borderRadius: BorderRadius.default,
    whiteSpace: 'nowrap',
    cursor: ({ isViewMode }: FileUploadSectionProps) =>
      isViewMode ? 'pointer' : 'default',
    transition: 'all 0.2s ease',
    '&:hover': {
      border: ({ isViewMode }: FileUploadSectionProps) =>
        isViewMode
          ? `1px solid ${Colors.secondaryDark}`
          : `1px solid ${BorderColors.lighter}`,
      backgroundColor: ({ isViewMode }: FileUploadSectionProps) =>
        isViewMode ? Colors.backgroundLight : Colors.backgroundWhite,
    },
  },
  fileInfo: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
    alignItems: 'center',
  },
  fileName: {
    fontSize: FontSizes.small,
    color: Colors.dark,
    fontWeight: FontWeights.semiBold,
    lineHeight: '16px',
  },
  fileSize: {
    fontSize: FontSizes.small,
    color: Colors.dark,
    lineHeight: '16px',
  },
  attachmentIcon: {
    width: 16,
    height: 16,
  },
  removeIcon: {
    width: 8,
    height: 8,
  },
  downloadIcon: {
    width: 16,
    height: 16,
    marginLeft: Spacing.small,
    color: Colors.secondaryDark,
  },
});

interface FileUploadSectionProps {
  additionalMaterialsFiles: VettingFeedbackFormAdditionalMaterial[];
  setAdditionalMaterialsFiles: React.Dispatch<
    React.SetStateAction<VettingFeedbackFormAdditionalMaterial[]>
  >;
  isViewMode: boolean;
}

const FileUploadSection = ({
  additionalMaterialsFiles,
  setAdditionalMaterialsFiles,
  isViewMode,
}: FileUploadSectionProps) => {
  const styles = useStyles({ isViewMode });
  const commonStyles = useCommonStyles();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newFiles = Array.from(files);
      setAdditionalMaterialsFiles((prevAddMaterials) => [
        ...prevAddMaterials,
        ...newFiles.map((file) => ({
          file,
          label: file.name,
          fileSize: file.size,
        })),
      ]);
    }
  };

  const removeFile = (fileName: string) => {
    setAdditionalMaterialsFiles((prevAddMaterials) =>
      prevAddMaterials.filter(
        (addMaterial) =>
          (addMaterial.file?.name || addMaterial.label) !== fileName,
      ),
    );
  };

  const formatFileSize = (size: number) => {
    if (size < 1024) {
      return `${size}B`;
    } else if (size < 1048576) {
      return `${(size / 1024).toFixed(2)}KB`;
    } else {
      return `${(size / 1048576).toFixed(2)}MB`;
    }
  };

  const handleFileDownload = (
    addMaterial: VettingFeedbackFormAdditionalMaterial,
  ) => {
    if (addMaterial.file) {
      const url = URL.createObjectURL(addMaterial.file);
      const a = document.createElement('a');
      a.href = url;
      a.download = addMaterial.file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else if (addMaterial.url) {
      window.open(addMaterial.url, '_blank');
    }
  };

  return (
    <div className={styles.uploadFilesContainer}>
      <div className={commonStyles.label}>Additional materials</div>
      <input
        type="file"
        id="fileInput"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <div className={styles.fileList}>
        {!isViewMode && (
          <div
            className={styles.uploadButton}
            onClick={() => document.getElementById('fileInput')?.click()}
          >
            <Icon type={IconType.Upload2} className={styles.uploadIcon} />
            <div className={styles.uploadText}>Upload files</div>
          </div>
        )}
        {additionalMaterialsFiles.map((addMaterial) => (
          <div
            className={styles.fileItem}
            key={addMaterial.file?.name || addMaterial.label}
            onClick={() => isViewMode && handleFileDownload(addMaterial)}
          >
            <Icon
              type={IconType.Attachment}
              className={styles.attachmentIcon}
            />
            <div className={styles.fileInfo}>
              <div className={styles.fileName}>
                {addMaterial.file?.name || addMaterial.label}
              </div>
              <div className={styles.fileSize}>
                {formatFileSize(
                  (addMaterial.file?.size || addMaterial.fileSize) ?? 0,
                )}
              </div>
            </div>
            {isViewMode ? (
              <Icon type={IconType.Download} className={styles.downloadIcon} />
            ) : (
              <Icon
                type={IconType.Close}
                className={styles.removeIcon}
                onClick={() =>
                  removeFile(
                    (addMaterial.file?.name || addMaterial.label) ?? '',
                  )
                }
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileUploadSection;
