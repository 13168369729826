import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import qs from 'qs';
import VettingDashboardLayout, {
  useAdminModeGuard,
} from '@src/layouts/Vetting';
import { useStores } from '@src/stores';
import { Pagination, PaginationProps } from '@src/components/Pagination';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import { FETCH_VETTERS_BATCH_SIZE } from '@src/stores/Vetters';
import { SelectionTeamTable } from './table-view';
import { VetterSummary } from '@a_team/models/dist/vetter';
import { SelectionTeamGrid } from './grid-view';
import { SelectionTeamCardObject } from '@a_team/models/dist/UserObject';
import { OnPageClick } from '@src/components/Pagination/page-select';
import { OnPageSizeChange } from '@src/components/Pagination/page-size-select';
import {
  OnVettersFiltersChange,
  SelectionTeamExplorerQueryBar,
} from './query-bar';
import { FetchVettersFilters } from '@ateams/api/dist/endpoints/vetter';
import { ToggleSwitch } from '@ateams/components';

const useStyles = createUseStyles({
  tableContainer: {
    marginTop: '20px',
  },
});

export const SelectionTeamExplorerV2: React.FC = observer(() => {
  useAdminModeGuard();
  const styles = useStyles();
  const { auth, vetters, vettingProcesses } = useStores();
  const [loading, setLoading] = useLoadingState();
  const history = useHistory();

  const filters = useMemo(() => {
    const { filters } = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    return filters;
  }, [history.location.search]) as FetchVettersFilters;

  const onFiltersChange: OnVettersFiltersChange = (filters) => {
    const searchParams = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    }) as unknown as { filters: FetchVettersFilters };

    searchParams.filters = filters;

    const newSearchParams = new URLSearchParams(
      qs.stringify(searchParams),
    ).toString();

    if (newSearchParams !== history.location.search.substring(1)) {
      history.replace({
        search: new URLSearchParams(qs.stringify(searchParams)).toString(),
      });
    }
  };

  const getVetters = () => {
    setLoading(
      vetters.getVetters({
        filters,
        page: vetters.pagination.page,
        batchSize: vetters.pagination.batchSize,
      }),
      'Selection Team members loaded',
    );
  };

  const onViewToggle = () => {
    vetters.clearVetters();
    vetters.setTableView(!vetters.isTableView);
    vetters.setVettersPage(1);
    getVetters();
  };

  const onPageClick: OnPageClick = (page) => {
    vetters.setVettersPage(page);
    getVetters();
  };

  const onPageBatchSizeChange: OnPageSizeChange = (pageBatchSize) => {
    vetters.setVettersBatchSize(pageBatchSize);
    getVetters();
  };

  const paginationProps: PaginationProps | undefined = useMemo(() => {
    if (vetters.vettersCount === undefined) {
      return undefined;
    }

    return {
      currentPage: vetters.pagination.page,
      pagesCount: Math.ceil(
        vetters.vettersCount / vetters.pagination.batchSize,
      ),
      recordsCount: vetters.vettersCount,
      pageBatchSize: vetters.pagination.batchSize,
      pageSizeOptions: Object.values(FETCH_VETTERS_BATCH_SIZE),
      onPageClick,
      onPageBatchSizeChange,
    };
  }, [
    vetters.vettersCount,
    vetters.pagination.page,
    vetters.pagination.batchSize,
  ]);

  useEffect(() => {
    if (auth.currentUser) {
      getVetters();
      vetters.getVettersCount({ filters });
    }
  }, [filters, vetters.isAdminView, auth.currentUser]);

  const isLoading = loading === true;

  return (
    <VettingDashboardLayout title={'Selection Team Members'}>
      {vettingProcesses.isAdminView && (
        <SelectionTeamExplorerQueryBar
          defaultValues={filters}
          onChange={onFiltersChange}
        />
      )}

      {vettingProcesses.isAdminView && (
        <ToggleSwitch
          size={'small'}
          checked={!vetters.isTableView}
          onChange={onViewToggle}
          label={vetters.isTableView ? 'Table view' : 'Grid view'}
        />
      )}

      {isLoading ? (
        ''
      ) : vetters.vetters?.length || vetters.pagination.page !== 1 ? (
        vetters.isTableView ? (
          <div className={styles.tableContainer}>
            <SelectionTeamTable
              rows={vetters.vetters as VetterSummary[]}
              paginationProps={paginationProps}
            />
          </div>
        ) : (
          <>
            <SelectionTeamGrid
              rows={vetters.vetters as SelectionTeamCardObject[]}
              paginationProps={paginationProps}
            />
            {paginationProps && <Pagination {...paginationProps} />}
          </>
        )
      ) : (
        <>
          <br />
          {'No Selection Team members found'}
        </>
      )}

      <LoadingIndicator loading={loading} />
    </VettingDashboardLayout>
  );
});
