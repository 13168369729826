import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useStores } from '@src/stores';
import {
  InferenceService,
  OpenAPI,
  Keywords as ExtractedKeywords,
} from '@a_team/data-science-api-client';
import { FontWeights, Icon, IconType, Spacing } from '@ateams/components';
import { SmallButton } from '@src/components/SmallButton';
import TextButton from '@src/components/TextButton';
import cx from 'classnames';

const useStyles = createUseStyles({
  root: {
    marginBottom: '24px',
  },
  title: {
    fontWeight: FontWeights.semiBold,
    marginBottom: Spacing.medium,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  skills: {},
  close: {
    marginTop: Spacing.medium,
  },
  closeIcon: {
    display: 'inline-block',
    marginLeft: Spacing.xsmall,
  },
  closeIconUp: {
    transform: 'rotate(180deg)',
  },
});

interface KeywordsProps {
  headline?: string;
}

function Keywords({ headline }: KeywordsProps): JSX.Element {
  const styles = useStyles();
  const { auth } = useStores();
  const [keywords, setKeywords] = useState<string[]>([]);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  useEffect(() => {
    const fetchKeywords = async () => {
      const roleKeywords = await getKeywords(headline);
      setKeywords(
        roleKeywords && roleKeywords.keywords ? roleKeywords.keywords : [],
      );
    };
    fetchKeywords();
  }, [headline]);

  const getKeywords = async (
    text: string | undefined,
  ): Promise<ExtractedKeywords> => {
    try {
      OpenAPI.TOKEN = auth.token ?? undefined;
      const keywordsResponse =
        await InferenceService.extractKeywordsWithUserAuth({
          text: text,
        });
      return keywordsResponse;
    } catch (error) {
      console.error('Error fetching keywords', error);
      return Promise.reject({ keywords: [] });
    }
  };

  const copyKeywords = () => {
    navigator.clipboard.writeText(keywords.join(' OR '));
  };

  return (
    <div className={styles.root}>
      {isCollapsed ? (
        <TextButton onClick={() => setIsCollapsed(false)} color="dark">
          {keywords.length || ''} keywords extracted{' '}
          <div className={cx(styles.closeIcon)}>
            <Icon type={IconType.Chevron} size="xsmall" />
          </div>
        </TextButton>
      ) : (
        <div className={styles.title}>
          <span>Extracted keywords</span>
          <SmallButton
            onClick={() => copyKeywords()}
            iconType={IconType.Copy}
            text="Copy"
          ></SmallButton>
        </div>
      )}
      {!isCollapsed && (
        <>
          <div className={styles.skills}>
            {keywords.length === 0 && <div>Loading...</div>}
            {keywords?.join(', ')}
          </div>

          <div className={styles.close}>
            <TextButton onClick={() => setIsCollapsed(true)}>
              Close{' '}
              <div className={cx(styles.closeIcon, styles.closeIconUp)}>
                <Icon type={IconType.Chevron} size="xsmall" />
              </div>
            </TextButton>
          </div>
        </>
      )}
    </div>
  );
}

export default Keywords;
