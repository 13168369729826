import React from 'react';

export const SparklesIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M2.32533 0.133862C2.37338 -0.0446211 2.62662 -0.0446206 2.67467 0.133863L3.11247 1.75988C3.12924 1.82214 3.17786 1.87076 3.24012 1.88752L4.86614 2.32533C5.04462 2.37338 5.04462 2.62662 4.86614 2.67467L3.24012 3.11247C3.17786 3.12924 3.12924 3.17786 3.11247 3.24012L2.67467 4.86614C2.62662 5.04462 2.37338 5.04462 2.32533 4.86614L1.88752 3.24012C1.87076 3.17786 1.82214 3.12924 1.75988 3.11247L0.133862 2.67467C-0.0446211 2.62662 -0.0446206 2.37338 0.133863 2.32533L1.75988 1.88752C1.82214 1.87076 1.87076 1.82214 1.88752 1.75988L2.32533 0.133862Z"
        fill="#6D00D7"
      />
      <path
        d="M8.51092 2.37481C8.64548 1.87506 9.35452 1.87506 9.48908 2.37482L10.7149 6.92767C10.7619 7.10198 10.898 7.23814 11.0723 7.28507L15.6252 8.51092C16.1249 8.64548 16.1249 9.35452 15.6252 9.48908L11.0723 10.7149C10.898 10.7619 10.7619 10.898 10.7149 11.0723L9.48908 15.6252C9.35452 16.1249 8.64548 16.1249 8.51092 15.6252L7.28507 11.0723C7.23814 10.898 7.10198 10.7619 6.92767 10.7149L2.37481 9.48908C1.87506 9.35452 1.87506 8.64548 2.37482 8.51092L6.92767 7.28507C7.10198 7.23814 7.23814 7.10198 7.28507 6.92767L8.51092 2.37481Z"
        fill="#6D00D7"
      />
    </svg>
  );
};
