import { VideoPlayer } from '@src/components/VideoPlayer';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Accordion from './Accordion';
import { observer } from 'mobx-react';

const useStyles = createUseStyles({
  accordionDesc: {
    marginTop: '0px',
  },
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
  },
  requestToJoinImg: {
    width: '100%',
    height: 'auto',
  },
});

const OurTeamBuildingProcess = (): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <h4>🚧️ Our Team-Building Process</h4>
      <p>
        <strong>
          For starters, this is how the process looks like from A → Z:
        </strong>
      </p>

      <Accordion title="Set your profile">
        <p className={styles.accordionDesc}>
          It takes around 3 minutes and it’s mainly based on your Linkedin! We
          will further explain its importance further, but keep in mind your
          profile is a mini-selling tool as its being shared with companies.
        </p>
      </Accordion>

      <Accordion title="Request to Join missions">
        <p className={styles.accordionDesc}>
          After the Onboarding Call (or while waiting for it), you can review
          open missions & request to join them via our Mission Control Page!
          <br />
          <br />
          When you find missions you are interested in, click Request to Join on
          the open role that is right for you.
          <br />
          <br />
          <img
            className={styles.requestToJoinImg}
            src="https://cdn.a.team/website/quick-onboarding-list.jpg"
            alt="request to join"
          />
        </p>
      </Accordion>

      <Accordion title="Evaluation Call">
        <div className={styles.accordionDesc}>
          <p className={styles.accordionDesc}>
            Before we propose you for an A.Team mission, we’ll invite you to hop
            on an evaluation call. Requesting to join a mission is the best
            practice to get a fast-tracked invite for the call, so after joining
            and filling out your profile and projects, you should scan the
            Missions Page and request to join any mission that seems like a
            great fit.
          </p>

          <p>
            The evaluation call is an opportunity for you to share what you do
            best! We are eager to hear about your experience, background,
            skills, and expertise. After we get to know you a little more, we
            can connect you to the right missions and pitch you more effectively
            to companies.
          </p>

          <p>
            It’s important to note that the evaluation call is{' '}
            <strong>a mandatory step</strong> before being considered for any of
            our missions. This call also provides us with an opportunity to
            confirm that you’re a good fit for our members-only network of
            top-tier builders.
          </p>
        </div>
      </Accordion>

      <Accordion title="Building Teams">
        <p className={styles.accordionDesc}>
          This is something that is done on our end - we're mixing and matching
          builders to form the best teams, based on complementary skills,
          experience, expertise, timezone & availability. Basically, everything.
        </p>
      </Accordion>

      <Accordion title="Pitching to companies">
        <div>
          <p className={styles.accordionDesc}>
            We're usually pitching 2 teams to companies within 2-3 days from the
            time we post a mission to our community. That company chooses which
            of the builders they wish to move forward with and interview them.
          </p>

          <p>
            We will let you know once your name was shared with a company, so
            keep an eye out for a potential request for an interview.
          </p>

          <p>
            If you're not selected, stay positive - many of our missions get
            extensions so you might have another opportunity to join it!
          </p>
        </div>
      </Accordion>

      <Accordion title="Mission interview with a company">
        <div>
          <p className={styles.accordionDesc}>
            You can expect a quick turnaround of interviews, the most common
            number is 2, but it can be anywhere between 1-3 interviews,
            depending on the company.
          </p>
          <p>If the interview was successful & you were selected: </p>

          <p>Contracts with A.Team & the company will be shared your way!</p>

          <p>
            Keep an eye out for a pre-kick-off and kick-off call that will be
            scheduled with the rest of the A.Team and the company.{' '}
          </p>
        </div>
      </Accordion>

      <Accordion title="Join our Slack Channel">
        <p className={styles.accordionDesc}>
          The Slack Channel is only designated for builders selected on
          missions, and includes mainly communications surrounding the mission’s
          progress.
        </p>
      </Accordion>

      <VideoPlayer url="https://www.loom.com/share/ee754b940446404fb3e6a781a0824333" />
    </div>
  );
};

export default observer(OurTeamBuildingProcess);
