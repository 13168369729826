import React, { ReactElement } from 'react';
import { Card } from '@ateams/components';
import {
  TimesheetId,
  TimesheetRecordData,
} from '@a_team/models/dist/TimesheetObject';
import AddRecordForm from './AddRecordForm';
import PaymentCycles from '@src/views/Mission/TimeTracking/PaymentCycles';
import { MissionPaymentCycleId } from '@a_team/models/dist/MissionPaymentCycleObject';
import TimesheetsStatuses from '@src/views/Mission/Admin/TimesheetsStatuses';
import Mission from '@src/stores/Missions/Mission';
import { SetLoading } from '@src/hooks/useLoadingState';

interface Props {
  addRecord(sid: TimesheetId, data: TimesheetRecordData): void;
  cycleDates: Date[] | null | undefined;
  mission: Mission;
  updateSelectedCycle?: (id: MissionPaymentCycleId) => void;
  adminView?: boolean;
  clientView?: boolean;
  disableAdd?: boolean;
  setLoading: SetLoading;
}

const defaultProps: Partial<Props> = {
  adminView: false,
  clientView: false,
};

ActionBar.defaultProps = defaultProps;

export default function ActionBar(props: Props): ReactElement {
  const {
    addRecord,
    cycleDates,
    updateSelectedCycle,
    adminView,
    clientView,
    mission,
    disableAdd,
    setLoading,
  } = props;

  const { currentUserTimesheet } = mission;

  const addSingleRecord = (
    date: Date | null,
    minutes: number,
    details: string,
  ) => {
    if (!currentUserTimesheet || !date) return;

    addRecord(currentUserTimesheet?.sid, {
      date,
      minutes,
      details,
    });
  };

  return (
    <Card style={{ padding: 0, marginRight: 0 }}>
      {mission.paymentCycles && mission.paymentCycles.length > 0 && (
        <PaymentCycles
          selectedPaymentCycleId={mission.selectedPaymentCycle?.data.yid}
          paymentCycles={mission.paymentCycles}
          updateSelectedCycle={updateSelectedCycle}
          clientView={clientView}
          adminView={adminView}
        />
      )}

      <>
        {adminView ? (
          <TimesheetsStatuses mission={mission} setLoading={setLoading} />
        ) : (
          !clientView && (
            <AddRecordForm
              addSingleRecord={addSingleRecord}
              cycleDates={cycleDates}
              disabled={disableAdd}
            />
          )
        )}
      </>
    </Card>
  );
}
