import React from 'react';
import users from './users.svg';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    margin: '8px 0 8px 0',
    fontWeight: 500,
    fontSize: 16,
  },
  description: {
    textAlign: 'center',
    margin: '8px 0 0 0',
    fontSize: 14,
  },
});

function EndOfRecommendations() {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <img src={users} alt="End of recommendations" width="120" />
      <h5 className={styles.title}>
        You’ve reached the end of the recommendation list.
      </h5>
      <div className={styles.description}>
        Connect with more builders to expand your network and get more
        recommendations.
      </div>
      <div className={styles.description}>
        Having more connections increases your chances of being selected for
        missions.
      </div>
    </div>
  );
}

export default EndOfRecommendations;
