import React, { ReactElement } from 'react';
import { MissionSearchResult } from '@a_team/models/dist/MissionObject';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { MissionPageAdminLocation } from '@src/locations';

interface Props {
  links: MissionSearchResult[];
  highlightedIndex?: number;
  onHover(index: number): void;
}

const useStyles = createUseStyles({
  resultsContainer: {
    maxHeight: 420,
    position: 'absolute',
    top: '100%',
    marginTop: 12,
    left: 0,
    width: '100%',
    backgroundColor: 'white',
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    zIndex: 10,
    overflow: 'auto',
  },
  searchResultsItem: {
    display: 'block',
    padding: '15px 24px',
  },
  highlighted: {
    backgroundColor: '#FFFAF7',
  },
});

export default function TopBarSearchResults({
  links,
  highlightedIndex,
  onHover,
}: Props): ReactElement {
  const styles = useStyles();

  return (
    <div className={styles.resultsContainer}>
      {links && links.length ? (
        links.map(({ title, mid, status }, index) => (
          <a
            key={mid}
            onMouseEnter={() => onHover(index)}
            className={cx(
              styles.searchResultsItem,
              highlightedIndex === index && styles.highlighted,
            )}
            style={{ color: '#222' }}
            href={MissionPageAdminLocation(mid)}
          >
            {title} ({status})
          </a>
        ))
      ) : (
        <div className={styles.searchResultsItem}>{'Nothing found'}</div>
      )}
    </div>
  );
}
