import React, { ReactElement } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { FontSizes, Paddings, Spacing } from '../theme/styles';
import Colors, { BorderColors, TextColors } from '../theme/colors';
import arrowDownBlack from '../assets/icons/arrowDownBlack.svg';

export interface FormGroupElementProps {
  inputColor?: 'regular' | 'secondaryLight';
  errors?: string[];
  disabled?: boolean;
}

const useStyles = createUseStyles({
  root: (props: FormGroupElementProps) => ({
    padding: Paddings.small,
    flex: '1 1 0px',
    position: 'relative',
    '&:not(:last-child)': {
      borderRight: `1px solid ${
        props.errors && props.errors.length > 0
          ? Colors['danger']
          : BorderColors['light']
      }`,
    },
    ...(props.errors && props.errors.length > 0
      ? {
          border: `1px solid ${Colors['danger']}`,
          margin: -1,
          background: '#fff',
        }
      : {}),

    '& label': {
      display: 'block',
      marginBottom: '0.3rem',
      fontSize: '16px',
    },
    '& input, select': {
      border: 'none',
      outline: 'none',
      color: TextColors[props.inputColor || 'regular'],
    },
    '& input': {
      width: '100%',
      background: 'transparent',
    },
    '& select': {
      padding: 0,
      appearance: 'none',
      background: 'transparent',
      backgroundImage: "url('" + arrowDownBlack + "')",
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: '100%',
      backgroundPositionY: '50%',
      paddingRight: '1rem',
    },
  }),
  errors: {
    color: Colors['danger'],
    fontSize: FontSizes.small,
    position: 'absolute',
    top: Spacing.small,
    right: Spacing.small,
    margin: 0,
    background: Colors['backgroundWhite'],
    maxWidth: '50%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export const FormGroupElement: React.FC<FormGroupElementProps> = (
  props,
): ReactElement => {
  const styles = useStyles(props);
  const errorDescription = props.errors?.join(', ');
  return (
    <div className={cx(styles.root)}>
      {props.children}
      {errorDescription ? (
        <p title={errorDescription} className={styles.errors}>
          {errorDescription}
        </p>
      ) : null}
    </div>
  );
};
