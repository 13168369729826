import React, { InputHTMLAttributes } from 'react';
import cx from 'classnames';
import useStyles from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  readonly?: boolean;
  error?: boolean;
  variant?: 'dashed';
}

const TextInput: React.FC<Props> = ({
  className,
  readonly,
  placeholder,
  disabled,
  error,
  variant,
  ...inputProps
}) => {
  const styles = useStyles({ variant });
  return (
    <input
      {...inputProps}
      placeholder={readonly ? '' : placeholder}
      disabled={readonly || disabled}
      className={cx(
        styles.input,
        { [styles.readonly]: readonly, [styles.error]: error },
        className,
      )}
    />
  );
};

export default TextInput;
