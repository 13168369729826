import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import tokenIcon from './token.svg';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    border: '1px solid #D3D6DC',
    borderRadius: '8px',
    background: 'white',
    overflow: 'hidden',
  },
  border: {
    width: '4px',
    backgroundColor: '#FE630C',
  },
  content: {
    padding: '20px',
    display: 'flex',
    alignItems: 'start',
    '& > img': {
      marginRight: '12px',
    },
  },
});

interface Props {
  className?: string;
  noIcon?: boolean;
}

const Alert: React.FC<Props> = ({ className, children, noIcon }) => {
  const styles = useStyles();

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.border} />
      <div className={styles.content}>
        {!noIcon && <img src={tokenIcon} alt="Token icon" />}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Alert;
