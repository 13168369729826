import { action, observable } from 'mobx';
import { Stores } from '@src/stores/index';
import {
  InvoiceObject,
  InvoiceId,
  PaymentPendingInvoiceReportRow,
} from '@a_team/models/dist/InvoiceObject';
import {
  apiInvoices,
  apiMissions,
  GlobalPaymentCycle,
  GlobalPaymentCycleId,
  GlobalPaymentCycleRole,
} from '@src/logic/services/endpoints';
import { QueryResult } from '@a_team/models/dist/misc';
import { TimesheetId } from '@a_team/models/dist/TimesheetObject';

export interface InvoicesStoreData {
  current?: InvoiceObject;
  unpaidInvoices?: PaymentPendingInvoiceReportRow[];
  nextTokens?: {
    unpaidInvoices?: string;
  };
  minBilledMinutes?: number;
  globalPaymentCycles?: QueryResult<
    GlobalPaymentCycle & { roles?: QueryResult<GlobalPaymentCycleRole> }
  >;
}

export default class InvoicesStore implements InvoicesStoreData {
  @observable public current: InvoicesStoreData['current'];
  @observable public unpaidInvoices: InvoicesStoreData['unpaidInvoices'];
  @observable public nextTokens: InvoicesStoreData['nextTokens'];
  @observable public minBilledMinutes: InvoicesStoreData['minBilledMinutes'];
  @observable
  public globalPaymentCycles: InvoicesStoreData['globalPaymentCycles'];
  private fetching: Partial<Record<string, Promise<unknown>>> = {};

  private readonly rootStore: Stores;

  public constructor(rootStore: Stores, initialState?: InvoicesStoreData) {
    this.rootStore = rootStore;

    if (initialState) {
      this.current = initialState.current;
      this.unpaidInvoices = initialState.unpaidInvoices;
      this.nextTokens = initialState.nextTokens;
      this.globalPaymentCycles = initialState.globalPaymentCycles;
      this.minBilledMinutes = initialState.minBilledMinutes;
    }
  }

  public serialize(): InvoicesStoreData {
    return {
      globalPaymentCycles: this.globalPaymentCycles,
    };
  }

  public getInvoice(iid: InvoiceId): InvoiceObject | null {
    if (!this.current || this.current.iid !== iid) {
      return null;
    }

    return this.current;
  }

  public async loadInvoiceByNonce(
    iid: InvoiceId,
    nonce: string,
  ): Promise<void> {
    if (this.current?.iid === iid) {
      return;
    }

    this.setCurrent(await apiInvoices.getInvoiceByNonce(iid, nonce));
  }

  public loadUnpaidInvoices = async (): Promise<void> => {
    const paymentPendingInvoiceReportRows =
      await apiInvoices.adminListPaymentPending(
        this.rootStore.auth,
        this.nextTokens?.unpaidInvoices,
      );
    this.setUnpaidInvoices(paymentPendingInvoiceReportRows.items);
    this.setUnpaidInvoicesNextToken(paymentPendingInvoiceReportRows.next);
  };

  public loadGlobalPaymentCycles = async (): Promise<void> => {
    return this.fetch(`global-payment-cycles`, async () => {
      if (this.globalPaymentCycles && !this.globalPaymentCycles.next) {
        return;
      }

      const result = await apiMissions.adminQueryRecentGlobalPaymentCycles(
        this.rootStore.auth,
        this.globalPaymentCycles?.next || undefined,
      );

      this.addGlobalPaymentCycles(result);
    });
  };

  public loadGlobalPaymentCycleRoles = async (
    id: GlobalPaymentCycleId,
    loadMore?: boolean,
  ): Promise<void> => {
    return this.fetch(`global-payment-cycle-${id}`, async () => {
      const item = this.globalPaymentCycles?.items.find(
        (item) => item.id === id,
      );
      if (!item) {
        throw new Error(`Global payment cycle missing in store: ${id}`);
      }

      if (item.roles && (!item.roles.next || !loadMore)) {
        return;
      }

      const result = await apiMissions.adminQueryGlobalPaymentCycleRoles(
        this.rootStore.auth,
        id,
        {
          minBilledMinutes: this.minBilledMinutes || 0,
          next: item.roles?.next || undefined,
        },
      );

      this.addGlobalPaymentCycleRoles(id, result);
    });
  };

  public prepayGlobalPaymentCycleRole = async (
    id: GlobalPaymentCycleId,
    sid: TimesheetId,
  ): Promise<void> => {
    const role = await apiMissions.adminPrepayGlobalPaymentCycleRole(
      this.rootStore.auth,
      id,
      sid,
    );

    this.replaceGlobalPaymentCycleRole(id, role);
  };

  public prepayGlobalPaymentCycleRoles = async (
    id: GlobalPaymentCycleId,
    sids: TimesheetId[],
  ): Promise<void> => {
    const roles = await apiMissions.adminPrepayGlobalPaymentCycleRoleBulk(
      this.rootStore.auth,
      id,
      sids,
    );

    roles.forEach((role) => this.replaceGlobalPaymentCycleRole(id, role));
  };

  @action setCurrent(invoice: InvoicesStoreData['current']): void {
    this.current = invoice;
  }

  @action setUnpaidInvoices(
    invoices: InvoicesStoreData['unpaidInvoices'],
  ): void {
    this.unpaidInvoices = [...(this.unpaidInvoices || []), ...(invoices || [])];
  }

  @action setUnpaidInvoicesNextToken = (token: string | null): void => {
    this.nextTokens = {
      ...this.nextTokens,
      unpaidInvoices: token || undefined,
    };
  };

  @action addGlobalPaymentCycles = (
    result: NonNullable<InvoicesStoreData['globalPaymentCycles']>,
  ): void => {
    this.globalPaymentCycles = {
      items: [...(this.globalPaymentCycles?.items || []), ...result.items],
      next: result.next,
    };
  };

  @action setMinBilledMinutes = (minBilledMinutes: number): void => {
    if ((this.minBilledMinutes || 0) === minBilledMinutes) return;

    this.minBilledMinutes = minBilledMinutes;

    this.globalPaymentCycles = this.globalPaymentCycles && {
      ...this.globalPaymentCycles,
      items: this.globalPaymentCycles.items.map((item) => ({
        ...item,
        roles: undefined,
      })),
    };
  };

  @action addGlobalPaymentCycleRoles = (
    id: GlobalPaymentCycleId,
    result: QueryResult<GlobalPaymentCycleRole>,
  ): void => {
    if (!this.globalPaymentCycles) return;

    this.globalPaymentCycles = {
      ...this.globalPaymentCycles,
      items: this.globalPaymentCycles.items.map((item) =>
        item.id !== id
          ? item
          : {
              ...item,
              roles: {
                items: [...(item.roles?.items || []), ...result.items],
                next: result.next,
              },
            },
      ),
    };
  };

  @action replaceGlobalPaymentCycleRole = (
    id: GlobalPaymentCycleId,
    role: GlobalPaymentCycleRole,
  ): void => {
    if (!this.globalPaymentCycles) return;

    const itemIndex = this.globalPaymentCycles.items.findIndex(
      (item) => item.id === id,
    );
    if (itemIndex === -1) return;

    const items = this.globalPaymentCycles.items.slice();
    const item = { ...items[itemIndex] };
    if (!item.roles) return;

    const roleIndex = item.roles.items.findIndex(
      (itemRole) => itemRole.sid === role.sid,
    );
    if (roleIndex === -1) return;

    const roleItems = item.roles.items.slice();
    const oldRole = roleItems[roleIndex];
    roleItems[roleIndex] = role;

    item.summary = {
      ...item.summary,
      paidAmount:
        item.summary.paidAmount -
        (oldRole.prepaid == null ? oldRole.summary.totalPayments : 0) +
        (role.prepaid == null ? role.summary.totalPayments : 0),
    };

    item.roles = { ...item.roles, items: roleItems };
    items[itemIndex] = item;

    this.globalPaymentCycles = {
      ...this.globalPaymentCycles,
      items,
    };
  };

  private fetch<T>(key: string, fn: () => Promise<T>): Promise<T> {
    let fetcher = this.fetching[key] as Promise<T> | undefined;
    if (fetcher) {
      return fetcher.then(() => this.fetch(key, fn));
    }

    fetcher = fn().then((res) => {
      this.fetching = {
        ...this.fetching,
        [key]: undefined,
      };

      return res;
    });

    this.fetching = {
      ...this.fetching,
      [key]: fetcher,
    };

    return fetcher;
  }
}
