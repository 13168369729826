import { Icon, IconType } from '@ateams/components';
import React from 'react';
import useCommonStyles from './commonStyles';

interface EmptyViewProps {
  skillsMissing?: boolean;
  projectsMissing?: boolean;
}

const EmptyView = ({ skillsMissing, projectsMissing }: EmptyViewProps) => {
  const styles = useCommonStyles();
  const missingSkillsText = skillsMissing
    ? 'update the missing required and preferred skills'
    : '';
  const misssingProjectsText = projectsMissing
    ? 'select at least two projects'
    : '';

  const concatinator = skillsMissing && projectsMissing ? ' as well as ' : '';
  return (
    <div className={styles.container}>
      <div className={styles.desc}>
        <Icon size="smaller" type={IconType.SuggestedTeams} />
        {`For a suggested response, ${missingSkillsText}${concatinator}${misssingProjectsText}.`}
      </div>
    </div>
  );
};

export default EmptyView;
