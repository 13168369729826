import {
  BuilderSuggestionId,
  BuilderSuggestionType,
  EditedSuggestion,
  ProfileEnhancementSuggestions,
  SuggestionActionType,
} from '@a_team/models/dist/ProfileEnhancements';
import { apiProfileSuggestions } from '@ateams/api';
import { useStores } from '@src/stores';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './keys';
import {
  JobExperienceData,
  ProjectExperienceData,
} from '@a_team/models/dist/ExperienceObject';
import { isNil } from 'lodash/fp';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import {
  refreshProfile,
  removeEntireProfileSuggestionBySid,
  removePartialProfileSuggestion,
} from './utils';
import { useState } from 'react';
import { profileSuggestions } from './keys/profileSuggestions';
import { ProfileViewMode } from '@src/stores/Profile/models';

export interface PartialSuggestion {
  sid: BuilderSuggestionId;
  type: BuilderSuggestionType;
  partialSuggestionId: string;
}

interface RoleSuggestion {
  sid: BuilderSuggestionId;
  roleId: string;
}

interface IndustrySuggestion {
  sid: BuilderSuggestionId;
  industryId: string;
}

export const useGetAdminProfileSuggestions = ({
  onSuccess,
}: {
  onSuccess?: (data: ProfileEnhancementSuggestions) => void;
} = {}) => {
  const { users, auth, missions } = useStores();
  const { profile } = users;
  const isCurrentUser =
    profile?.isCurrentUser ??
    missions.currentApplication?.isCurrentUser ??
    false;
  const isAdminRequest = auth.isAdmin && !isCurrentUser;
  const userId =
    profile?.data.uid ?? missions.currentApplication?.data.uid ?? undefined;

  return useQuery({
    enabled: isAdminRequest && !isNil(userId),
    queryKey: queryKeys.profileSuggestions.adminByUserId(userId ?? ''),
    queryFn: () => apiProfileSuggestions.userSuggestions(auth, userId ?? ''),
    onSuccess,
  });
};

export const useGetProfileSuggestions = ({
  onSuccess,
}: {
  onSuccess?: (data: ProfileEnhancementSuggestions) => void;
} = {}) => {
  const { users, auth, missions } = useStores();
  const { profile } = users;

  return useQuery({
    enabled:
      auth.isActive &&
      !!profile?.isCurrentUser &&
      !!profile?.data.uid &&
      (isNil(missions.currentApplication?.application?.aid) ||
        auth.withSuggestedProjectsOnApplication),
    queryKey: queryKeys.profileSuggestions.byUserId(profile?.data.uid ?? ''),
    queryFn: () => apiProfileSuggestions.suggestions(auth),
    onSuccess,
  });
};

export const useApplyProjectSuggestion = () => {
  const stores = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      sid,
      eid,
      editedExperience,
    }: {
      sid: BuilderSuggestionId;
      eid: string;
      editedExperience: ProjectExperienceData;
    }) =>
      apiProfileSuggestions.applySuggestedProject(
        stores.auth,
        sid,
        eid,
        editedExperience,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
      });
    },
  });
};

export const useRejectProfileSuggestion = () => {
  const stores = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: queryKeys.profileSuggestions.rejectByUserId(
      stores?.auth?.uid ?? '',
    ),
    mutationFn: async (sid: BuilderSuggestionId) =>
      apiProfileSuggestions.rejectSuggestion(stores.auth, sid),
    onSuccess: (_, sid) => {
      queryClient.setQueryData(
        queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
        (oldData: ProfileEnhancementSuggestions | undefined) => {
          return removeEntireProfileSuggestionBySid(oldData, sid);
        },
      );

      queryClient.invalidateQueries({
        queryKey: queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
      });
    },
  });
};

export const useApplyProfileSuggestion = ({
  shouldRefreshProfile,
}: {
  shouldRefreshProfile?: boolean;
}) => {
  const stores = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: queryKeys.profileSuggestions.acceptByUserId(
      stores?.auth?.uid ?? '',
    ),
    mutationFn: async ({
      sid,
      editedSuggestion,
    }: {
      sid: BuilderSuggestionId;
      editedSuggestion?: EditedSuggestion;
    }) =>
      apiProfileSuggestions.applySuggestion(stores.auth, sid, {
        editedSuggestion,
      }),
    onSuccess: async (_, { sid }) => {
      queryClient.setQueryData(
        queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
        (oldData: ProfileEnhancementSuggestions | undefined) => {
          return removeEntireProfileSuggestionBySid(oldData, sid);
        },
      );

      if (shouldRefreshProfile) {
        await refreshProfile(stores);
      }

      queryClient.invalidateQueries({
        queryKey: queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
      });
    },
  });
};

export const useApplySuggestedRole = () => {
  const stores = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: queryKeys.profileSuggestions.acceptRoleByUserId(
      stores?.auth?.uid ?? '',
    ),
    mutationFn: async (suggestedRole: RoleSuggestion) =>
      apiProfileSuggestions.applySuggestedRole(
        stores.auth,
        suggestedRole.sid,
        suggestedRole.roleId,
      ),
    onSuccess: (_, suggestedRole) => {
      queryClient.setQueryData(
        queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
        (oldData: ProfileEnhancementSuggestions | undefined) => {
          const suggestionKey = 'roles' as keyof ProfileEnhancementSuggestions;

          return removePartialProfileSuggestion(
            suggestionKey,
            suggestedRole.roleId,
            oldData,
          );
        },
      );

      queryClient.invalidateQueries({
        queryKey: queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
      });
    },
  });
};

export const useApplyJobSuggestion = () => {
  const stores = useStores();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();

  return useMutation({
    mutationFn: async ({
      sid,
      eid,
      editedExperience,
    }: {
      sid: BuilderSuggestionId;
      eid: string;
      editedExperience: JobExperienceData;
    }) =>
      apiProfileSuggestions.applySuggestedJob(
        stores.auth,
        sid,
        eid,
        editedExperience,
      ),
    onSuccess: (_, vars) => {
      analytics.trackProfileSuggestionInteraction(
        stores.auth.uid || '',
        vars.sid,
        SuggestionActionType.PARTIAL_ACCEPT,
        BuilderSuggestionType.JOBS,
        [vars.editedExperience.jobRole || '', vars.editedExperience.name || ''],
      );
      queryClient.invalidateQueries({
        queryKey: queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
      });
    },
  });
};

export const usePartiallyRejectSuggestion = () => {
  const stores = useStores();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();

  return useMutation({
    mutationKey: queryKeys.profileSuggestions.partiallyRejectByUserId(
      stores?.auth?.uid ?? '',
    ),
    mutationFn: async (rejectedSuggestion: PartialSuggestion) =>
      apiProfileSuggestions.rejectSuggestionPartially(
        stores.auth,
        rejectedSuggestion.sid,
        rejectedSuggestion.type,
        rejectedSuggestion.partialSuggestionId,
      ),
    onSuccess: (_, rejectedSuggestion) => {
      analytics.trackProfileSuggestionInteraction(
        stores.auth.uid || '',
        rejectedSuggestion.sid,
        SuggestionActionType.REJECT,
        rejectedSuggestion.type,
      );

      queryClient.setQueryData(
        queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
        (oldData: ProfileEnhancementSuggestions | undefined) => {
          const suggestionKey =
            rejectedSuggestion.type.toLowerCase() as keyof ProfileEnhancementSuggestions;

          return removePartialProfileSuggestion(
            suggestionKey,
            rejectedSuggestion.partialSuggestionId,
            oldData,
          );
        },
      );

      queryClient.invalidateQueries({
        queryKey: queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
      });
    },
  });
};

export const useEnhanceProfile = ({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}) => {
  const stores = useStores();
  return useMutation({
    mutationKey: queryKeys.profileSuggestions.enhanceProfileByUserId(
      stores?.auth.uid || '',
    ),
    mutationFn: async () =>
      apiProfileSuggestions.generateProfileSuggestions(stores.auth),
    onSuccess,
  });
};

export const useApplySuggestedIndustry = () => {
  const stores = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: profileSuggestions.acceptIndustryByUserId(
      stores?.auth?.uid ?? '',
    ),
    mutationFn: async (suggestedIndustry: IndustrySuggestion) =>
      apiProfileSuggestions.applySuggestedIndustry(
        stores.auth,
        suggestedIndustry.sid,
        suggestedIndustry.industryId,
      ),
    onSuccess: async (_, suggestedIndustry) => {
      queryClient.setQueryData(
        queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
        (oldData: ProfileEnhancementSuggestions | undefined) => {
          const suggestionKey =
            'industries' as keyof ProfileEnhancementSuggestions;

          return removePartialProfileSuggestion(
            suggestionKey,
            suggestedIndustry.industryId,
            oldData,
          );
        },
      );

      queryClient.invalidateQueries({
        queryKey: queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
      });
      await refreshProfile(stores);
    },
  });
};

export const useGenerateBioSuggestionOnDemand = ({
  onSuccess,
}: {
  onSuccess?: (data: string) => void;
} = {}) => {
  const { auth } = useStores();

  return useMutation({
    mutationFn: async () => {
      return apiProfileSuggestions.generateBioSuggestionOnDemand(auth);
    },
    onSuccess,
  });
};

const POLLING_INTERVAL = 5000; // Check for suggestions every 5 seconds
const POLLING_TIMEOUT = 300000; // Stop polling after 5 minutes

export const useEnhanceProfileWithPolling = () => {
  const stores = useStores();
  const [refetchEnabled, setRefetchEnabled] = useState(false);
  const { auth, users } = useStores();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const { data: suggestions } = useGetProfileSuggestions();

  const onSuccess = async () => {
    if (!refetchEnabled) {
      return;
    }

    setRefetchEnabled(false);
    users.setIsEnhancingProfile(false);
    analytics.trackProfileSuggestionsGenerated(auth.uid || '');

    queryClient.invalidateQueries({
      queryKey: queryKeys.profileSuggestions.byUserId(
        auth.currentUser?.uid ?? '',
      ),
    });
    await refreshProfile(stores);
  };
  const { mutate: enhanceUserProfile } = useEnhanceProfile({ onSuccess });

  useQuery({
    enabled: refetchEnabled,
    refetchIntervalInBackground: true,
    refetchInterval: POLLING_INTERVAL,
    queryKey:
      queryKeys.profileSuggestions.isLatestProfileEnhancementRequestCompleted(
        auth.uid ?? '',
      ),
    queryFn: () =>
      apiProfileSuggestions.isLatestProfileEnhancementRequestCompleted(auth),
    onSuccess: async (data) => {
      if (data.isCompleted) {
        await onSuccess();

        if (data.hasProjectsSuggestionError) {
          users.profile?.setProfileMode(ProfileViewMode.Edit);
          users.setHasProjectsSuggestionError(true);
        }
      }
    },
    onError: (error) => {
      console.error('Error checking for profile suggestions:', error);
      setRefetchEnabled(false);
    },
  });

  const initiateSuggestionsPolling = () => {
    users.setIsEnhancingProfile(true);

    // We don't want to start polling immediately
    setTimeout(() => {
      setRefetchEnabled(true);
    }, POLLING_INTERVAL);

    // We need to be extra careful as we don't want to keep polling forever
    // so we will stop polling after 5 minutes
    setTimeout(() => {
      setRefetchEnabled(false);
      users.setIsEnhancingProfile(false);
    }, POLLING_TIMEOUT);
  };

  const handleEnhanceProfile = () => {
    users.setIsEnhancingProfile(true);

    enhanceUserProfile();

    initiateSuggestionsPolling();
  };

  return {
    suggestions,
    handleEnhanceProfile,
    initiateSuggestionsPolling,
  };
};

export const useGenerateProjectsSuggestionFromWebsiteOrPdf = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}) => {
  const stores = useStores();
  return useMutation({
    mutationKey:
      queryKeys.profileSuggestions.useGenerateProjectsSuggestionFromWebsiteOrPdf(
        stores?.auth.uid || '',
      ),
    mutationFn: async ({
      websiteUrl,
      pdfUrl,
    }: {
      websiteUrl?: string;
      pdfUrl?: string;
    }) =>
      apiProfileSuggestions.generateProjectsSuggestionFromWebsiteOrPdfForUserOnDemand(
        stores.auth,
        {
          websiteUrl,
          pdfUrl,
        },
      ),
    onSuccess,
    onError,
  });
};
