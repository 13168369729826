import React, { ReactElement } from 'react';
import { UserItem } from '@src/components/BasicUserSelector/UserItem';
import { Link } from 'react-router-dom';
import { ClientAppLocation } from '../../../../locations';
import { MissionManager } from '../../../../../../../packages/models/dist/MissionObject';

interface Props {
  managers?: MissionManager[];
  workspaceId?: string;
  missionSpecId?: string;
}

const ClientUserList = (props: Props): ReactElement => {
  const { managers = [], missionSpecId, workspaceId } = props;
  const hasManagers = managers?.length > 0;
  const pathname =
    ClientAppLocation +
    (missionSpecId
      ? `/mission/${missionSpecId}`
      : `/settings/collaborators?workspaceId=${workspaceId}`);
  return (
    <>
      {hasManagers &&
        managers.map(({ user }) => (
          <UserItem
            key={user.uid}
            user={user}
            containerStyle={{ padding: '5px 0' }}
          />
        ))}
      {!hasManagers && (
        <p>
          <i>No client mission managers</i>
        </p>
      )}
      <p>
        <Link to={{ pathname }} target="_blank">
          Manage users on client app
        </Link>
      </p>
    </>
  );
};

export default ClientUserList;
