import React, { useMemo } from 'react';
import { VettingFeedbackFormViewModeData } from '@ateams/api/dist/endpoints/vetting-process-feedback';
import { formatLinkedinUrl } from '@src/views/VettingDashboard/vetting-table/columns/columns/builder-info';
import { ViewModeHeader } from './view-mode-header';
import {
  VettingFeedbackFormGeneralData,
  VettingFeedbackFormGeneralDataForm,
} from '../form/general-data';
import {
  VettingFeedbackFormMainInfo,
  VettingFeedbackFormMainInfoForm,
} from '../form/main-info';
import {
  VettingFeedbackFormBuilderInfo,
  VettingFeedbackFormBuilderInfoForm,
} from '../form/builder-info';
import {
  VettingFeedbackFormRoles,
  VettingFeedbackFormRolesForm,
} from '../form/roles';
import {
  VettingFeedbackFormExperience,
  VettingFeedbackFormExperienceForm,
} from '../form/experience';
import {
  VettingFeedbackFormProjects,
  VettingFeedbackFormProjectsForm,
} from '../form/projects';
import { VettingFeedbackFormHeader } from '../form/header';
import {
  VettingFeedbackFormTechStack,
  VettingFeedbackFormTechStackForm,
} from '../form/tech-stack';
import {
  VettingFeedbackFormIndustries,
  VettingFeedbackFormIndustriesForm,
} from '../form/industries';
import {
  VettingFeedbackFormGeneralComments,
  VettingFeedbackFormGeneralCommentsForm,
} from '../form/general-comments';
import {
  VettingFeedbackFormSummaryComments,
  VettingFeedbackFormSummaryCommentsForm,
} from '../form/summary-comments';
import {
  VettingFeedbackFormBackgroundComments,
  VettingFeedbackFormBackgroundCommentsForm,
} from '../form/background-comments';
import { VettingFeedbackFormTimestamp } from '../form/timestamp';
import { useVettingFormVariant } from '../form/form-variants';
import { useStyles } from '../form';

export interface VettingFeedbackFormViewModeProps {
  data: VettingFeedbackFormViewModeData;
  vettingProcessId: string;
  onClose: () => void;
}

export const VettingFeedbackFormViewMode: React.FC<
  VettingFeedbackFormViewModeProps
> = ({ data, vettingProcessId, onClose }) => {
  const styles = useStyles();
  const vettingFormVariant = useVettingFormVariant(
    data.general.vettingProcess.category,
  );

  const generalData: VettingFeedbackFormGeneralData = useMemo(() => {
    return {
      contactOwner: data.general.vettingProcess.contactOwner,
      vetter: data.general.vettingProcess.vetter,
      vettingType: data.general.vettingProcess.vettingType,
      category: data.general.vettingProcess.category,
      vettingStartedDate: data.general.vettingProcess.vettingStartedDate,
      submittedFormDate: data.general.vettingProcess.submittedFormDate,
      notifiedInterviewer: data.general.vettingProcess.notifiedInterviewerDate,
      feedbackFormSentDate: data.general.vettingProcess.feedbackFormSentDate,
    };
  }, [data]);

  const mainInfo: VettingFeedbackFormMainInfo = useMemo(() => {
    return {
      interviewDate: data.general.vettingProcess.interviewDate,
      builderDidNotShowUp: false,
      scores: {
        accent: data.answers.scores.accent,
        englishLevel: data.answers.scores.englishLevel,
        expertise: data.answers.scores.expertise,
        interactionExperience: data.answers.scores.interactionExperience,
      },
    };
  }, [data]);

  const builderInfo: VettingFeedbackFormBuilderInfo = useMemo(() => {
    return {
      position: data.general.user.roleCategory || undefined,
      techStack: data.general.user.profileTalentSkills,
      location: data.answers.location,
      worksElseWhere: data.answers.worksElseWhere,
      availability: data.answers.availability,
      callRecording: data.answers.callRecording,
      additionalMaterialsFiles: data.answers.additionalMaterialsFiles,
      additionalMaterials: data.answers.additionalMaterials,
      codeSample: data.general.vettingProcess.codeSample,
      additionalMaterialLinks:
        data.general.vettingProcess.additionalMaterialLinks,
      additionalMaterialFileUrls:
        data.general.vettingProcess.additionalMaterialFiles,
      additionalMaterialDetails:
        data.general.vettingProcess.additionalMaterialDetails,
      linkedIn: formatLinkedinUrl(data.general.user.linkedIn),
      website: data.general.user.website,
      email: data.general.user.email,
      calendarUrl: data.general.vettingProcess.calendarUrl,
      recordingDownloadLinks:
        data.general.vettingProcess.recordingDownloadLinks,
      transcriptJobIds: data.general.vettingProcess.transcriptJobIds,
    };
  }, [data]);

  const roles: VettingFeedbackFormRoles = useMemo(() => {
    return {
      roles: data.answers.roles || [],
    };
  }, [data]);

  const experience: VettingFeedbackFormExperience = useMemo(() => {
    return data.answers.experience;
  }, [data]);

  const projects: VettingFeedbackFormProjects = useMemo(() => {
    return {
      projects: data.answers.projects,
      biggestImpactDescription: data.answers.biggestImpactDescription,
    };
  }, [data]);

  const techStack: VettingFeedbackFormTechStack = useMemo(() => {
    return {
      skills: data.answers.skills,
      skillsComment: data.answers.skillsComment,
    };
  }, [data]);

  const industries: VettingFeedbackFormIndustries = useMemo(() => {
    return {
      talentIndustries: data.answers.industries.talentIndustries,
      comment: data.answers.industries.comment,
    };
  }, [data]);

  const backgroundComments: VettingFeedbackFormBackgroundComments =
    useMemo(() => {
      return {
        comments: data.answers.backgroundComments,
      };
    }, [data]);

  const generalComments: VettingFeedbackFormGeneralComments = useMemo(() => {
    return {
      comments: data.answers.generalComments,
    };
  }, [data]);

  const summaryComments: VettingFeedbackFormSummaryComments = useMemo(() => {
    return {
      comments: data.answers.summaryComments,
    };
  }, [data]);

  return (
    <>
      <ViewModeHeader />

      <div className={styles.container}>
        <VettingFeedbackFormHeader
          profileURL={data.general.user.profileURL}
          avatarSrc={data.general.user.profilePictureURL}
          fullName={data.general.user.fullName}
          vettingProcessId={vettingProcessId}
          onClose={onClose}
          className={styles.formHeader}
        />

        <VettingFeedbackFormGeneralDataForm
          errors={{}}
          defaultValues={generalData}
          className={styles.marginBottom}
          isReadOnly
        />

        <VettingFeedbackFormMainInfoForm
          errors={{ scores: {} }}
          defaultValues={mainInfo}
          className={styles.marginBottom}
          isReadOnly
        />

        <VettingFeedbackFormBuilderInfoForm
          defaultValues={builderInfo}
          className={styles.marginBottom}
          isReadOnly
        />

        <VettingFeedbackFormRolesForm
          errors={{}}
          defaultValues={roles}
          vettingFormVariant={vettingFormVariant}
          userRoleCategory={data.general.user.roleCategory}
          className={styles.marginBottom}
          isReadOnly
        />

        <VettingFeedbackFormExperienceForm
          defaultValues={experience}
          vettingFormVariant={vettingFormVariant}
          className={styles.marginBottom}
          isReadOnly
        />

        <VettingFeedbackFormProjectsForm
          defaultValues={projects}
          className={styles.marginBottom}
          isReadOnly
        />

        <VettingFeedbackFormTechStackForm
          defaultValues={techStack}
          className={styles.marginBottom}
          isReadOnly
        />

        <VettingFeedbackFormIndustriesForm
          values={industries}
          className={styles.marginBottom}
          isReadOnly
        />

        {
          /**
           * Background comments is @deprecated, but if a form was submitted with it we still want to show it
           * {@link https://buildateam.atlassian.net/browse/NEXUS-828}
           */
          Boolean(backgroundComments.comments.length) && (
            <VettingFeedbackFormBackgroundCommentsForm
              defaultValues={backgroundComments}
              className={styles.marginBottom}
              isReadOnly
            />
          )
        }

        {
          /**
           * General comments is @deprecated, but if a form was submitted with it we still want to show it
           * {@link https://buildateam.atlassian.net/browse/NEXUS-828}
           */
          Boolean(generalComments.comments.length) && (
            <VettingFeedbackFormGeneralCommentsForm
              defaultValues={generalComments}
              className={styles.marginBottom}
              isReadOnly
            />
          )
        }

        <VettingFeedbackFormSummaryCommentsForm
          defaultValues={summaryComments}
          errors={{}}
          className={styles.marginBottom}
          isReadOnly
        />

        <VettingFeedbackFormTimestamp
          submitDate={data.general.vettingProcess.lastSubmitDate}
          saveDate={data.general.vettingProcess.lastSaveDate}
          className={styles.marginBottom}
        />
      </div>
    </>
  );
};
