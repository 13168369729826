import React, { useMemo, useState } from 'react';
import Modal, { ModalBasicProps } from '..';
import { createUseStyles } from 'react-jss';
import {
  AdminVettingProcess,
  VettingProcessId,
  VettingType,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { UserId } from '@a_team/models/dist/UserObject';
import { TalentCategoryId } from '@a_team/models/dist/TalentCategories';
import { Spacing } from '@ateams/components';
import { useStores } from '@src/stores';
import {
  Size,
  Text,
} from '@src/views/VettingFeedbackForm/components/typography';
import {
  CallToActionButton,
  CallToActionButtonType,
} from '@src/views/VettingFeedbackForm/modals/cta-button';
import {
  EvaluationInviteModalForm,
  EvaluationInvitePayload,
  OnEvaluationInviteModalFormChange,
} from './form';
import { RoleCategoryId } from '@a_team/models/dist/RoleCategory';

export type OnEvaluationInviteSubmit = (
  payload: EvaluationInvitePayload,
) => void;

export type OnEvaluationFollowupInviteSubmit = (
  id: VettingProcessId,
  payload: EvaluationInvitePayload,
) => void;

interface EvaluationInviteModalProps extends ModalBasicProps {
  onSubmit: OnEvaluationInviteSubmit;
  vettingProcess?: AdminVettingProcess;
  defaultContactOwner?: UserId;
  defaultEvaluationType?: VettingType;
  defaultCategory?: TalentCategoryId;
  defaultPrimaryEvaluationRole?: RoleCategoryId;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '40px',
  },
  title: {
    fontWeight: 600,
    marginBottom: Spacing.small,
  },
  subTitle: {
    color: '#818388',
    marginBottom: Spacing.large,
  },
  form: {
    marginBottom: Spacing.large,
  },
  actionsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    height: '40px',
    width: '160px',
  },
});

/** Design: {@link https://www.figma.com/file/L1g2wFUmUk2Y7QNzrOxIfk/Profile-page?node-id=829-74&t=OTiNFjb1kDB2K5mw-0} */
export const EvaluationInviteModal: React.FC<EvaluationInviteModalProps> = (
  props,
) => {
  const styles = useStyles();
  const { open, onClose } = props;
  const [evaluationInvitePayload, setEvaluationInvitePayload] = useState<
    Partial<EvaluationInvitePayload>
  >({});
  const { auth, users } = useStores();

  function validatePayload(
    payload: Partial<EvaluationInvitePayload>,
  ): payload is EvaluationInvitePayload {
    if (!payload.contactOwner) {
      throw new Error('Contact owner is required');
    }

    if (!payload.sendEvaluationEmail) {
      return true;
    }
    if (!payload.templateType) {
      throw new Error('Template type is required');
    }
    if (!payload.emailBody) {
      throw new Error('Email body is required');
    }
    if (!payload.emailSubject) {
      throw new Error('Email subject is required');
    }
    if (!payload.category) {
      throw new Error('Category is required');
    }
    if (!payload.primaryRoleCategoryId) {
      throw new Error('Primary evaluation role is required');
    }

    return true;
  }

  const canSubmit = useMemo(() => {
    try {
      validatePayload(evaluationInvitePayload);

      return true;
    } catch (err) {
      return false;
    }
  }, [evaluationInvitePayload]);

  const handleSubmit = () => {
    try {
      if (validatePayload(evaluationInvitePayload)) {
        props.onSubmit(evaluationInvitePayload);
      }
    } catch (err) {
      console.error(
        "Button shouldn't been enabled if payload is invalid 🫠",
        err,
      );
    }
  };

  const onEvaluationInviteModalFormChange: OnEvaluationInviteModalFormChange = (
    payload,
  ) => {
    setEvaluationInvitePayload((cur) => ({ ...cur, ...payload }));
  };

  return (
    <Modal open={open} onClose={onClose} style={{ padding: 0, minWidth: 680 }}>
      <div className={styles.container}>
        <Text size={Size.ExtraLarge} className={styles.title}>
          Send invitation to evaluation call
        </Text>

        <Text size={Size.Medium} className={styles.subTitle}>
          Send invitation email or follow-up with a builder.
        </Text>

        <EvaluationInviteModalForm
          isNewProcess={!props.vettingProcess}
          emailsSentCount={props.vettingProcess?.reachoutEmails?.length || 0}
          defaultContactOwner={
            props.defaultContactOwner ||
            props.vettingProcess?.contactOwner?.uid ||
            auth.uid ||
            undefined
          }
          defaultCategory={
            props.defaultCategory || props.vettingProcess?.category?.id
          }
          defaultEvaluationType={
            props.defaultEvaluationType || props.vettingProcess?.vettingType
          }
          defaultPrimaryRoleCategoryId={
            props.vettingProcess?.primaryRoleCategoryId ||
            users.profile?.data?.talentProfile?.mainTalentSpecializationId
          }
          onChange={onEvaluationInviteModalFormChange}
          className={styles.form}
        />

        <div className={styles.actionsContainer}>
          <CallToActionButton
            ctaType={CallToActionButtonType.Secondary}
            onClick={onClose}
            className={styles.button}
          >
            Close
          </CallToActionButton>

          <CallToActionButton
            ctaType={CallToActionButtonType.Primary}
            onClick={handleSubmit}
            disabled={!canSubmit}
            className={styles.button}
          >
            Send
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
};
