import { AdminMissionApplicationObject } from '@a_team/models/dist/MissionApplicationObject';
import {
  SuggestedTeam,
  SuggestedTeamMemberObject,
} from '@a_team/models/dist/TeamGraphObject';
import { SuggestedTeamMemberWithApplication } from './SuggestedTeamMemberWithApplication';
import { ProposalDataCurrency } from '@a_team/models/dist/ProposalObject';

export interface SuggestedTeamWithApplications {
  members: Record<string, SuggestedTeamMemberWithApplication>;
  team: SuggestedTeam<SuggestedTeamMemberObject>;
}

export const getSuggestedTeamsWithApplications = (
  suggestedTeams?: SuggestedTeam<SuggestedTeamMemberObject>[],
  applications?: AdminMissionApplicationObject[],
): SuggestedTeamWithApplications[] => {
  if (!suggestedTeams || !applications) return [];
  const result = suggestedTeams.map((team) => {
    return {
      members: Object.keys(team.members).reduce((acc, memberRoleId) => {
        const member = team.members[memberRoleId];
        const application = applications?.find(
          (application) => application.user.uid === member.user,
        );
        if (!application) return acc;
        acc[memberRoleId] = {
          suggestedTeamMember: member,
          application: application,
        };
        return acc;
      }, {} as Record<string, SuggestedTeamMemberWithApplication>),
      team,
    };
  });
  return result;
};

export const getProposalDataCurrencySymbol = (
  currency: ProposalDataCurrency,
) => {
  switch (currency) {
    case ProposalDataCurrency.EUR:
      return '€';
    case ProposalDataCurrency.USD:
      return '$';
  }
};
