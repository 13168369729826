import { ServiceAuth, ServiceEndpoint } from './utils';
import { GenerateEmailBodyResponse } from '@a_team/models/dist/EmailFromTargeter';

export const BasePath = '/email-from-targeter';

export default class EmailFromTargeterEndpoint extends ServiceEndpoint {
  public generateEmailBody(
    auth: ServiceAuth,
    missionId: string,
  ): Promise<GenerateEmailBodyResponse> {
    return this.fetch(
      auth,
      BasePath + `/generate-email-body/${missionId}`,
      null,
      'get',
    );
  }
  public closeEmailFromTargeterGptUsageLog(
    auth: ServiceAuth,
    gptUsageLogId: string,
    body: string,
  ): Promise<GenerateEmailBodyResponse> {
    return this.fetch(
      auth,
      BasePath + `/close-gpt-usage-log/${gptUsageLogId}`,
      null,
      'post',
      { body },
    );
  }
}
