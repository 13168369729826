import React, { useMemo } from 'react';
import useToggle from '@src/hooks/useToggle';
import { AdminVettingProcessColumnProps } from '..';
import { Button } from '@ateams/components';
import {
  VettingProcessPublicStatus,
  VettingProcessStatus,
} from '@a_team/models/dist/vetting-processes/status';
import { getStatusFromVettingProcess } from './status';
import { OverwriteProfileScoresModal } from '@src/views/VettingFeedbackForm/modals/overwrite-profile-scores-modal';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import { useStores } from '@src/stores';
import { ApplyScoresModal } from '@src/views/VettingFeedbackForm/modals/apply-scores-modal';
import { VettingProcessFeedbackScores } from '@a_team/models/dist/vetting-processes/feedback';

export const FeedbackScoresActionsField: React.FC<
  AdminVettingProcessColumnProps
> = ({ vettingProcess }) => {
  const { vettingProcesses } = useStores();
  const [changeScoresModal, toggleChangeScoresModal] = useToggle();
  const [loading, setLoading] = useLoadingState();

  const publicStatus = useMemo(
    () => getStatusFromVettingProcess(vettingProcess),
    [vettingProcess],
  );

  const hasRatingConflict =
    publicStatus === VettingProcessPublicStatus.RatingConflict;

  const isFeedbackPending = (
    feedback: typeof vettingProcess.feedback | typeof vettingProcess.feedbackV2,
  ) =>
    feedback &&
    feedback.submitDate &&
    typeof feedback.feedbackAccepted === 'undefined';

  if (
    vettingProcess.status !== VettingProcessStatus.Completed ||
    (!isFeedbackPending(vettingProcess.feedback) &&
      !isFeedbackPending(vettingProcess.feedbackV2))
  ) {
    return null;
  }

  const handleAcceptOrRejectScores = (shouldAcceptNewScores: boolean) => {
    setLoading(
      (async () => {
        await vettingProcesses.acceptFeedbackForm(vettingProcess.id, {
          shouldAcceptNewScores,
        });

        toggleChangeScoresModal();
      })(),
    );
  };

  const handleAcceptScores = () => handleAcceptOrRejectScores(true);
  const handleRejectScores = () => handleAcceptOrRejectScores(false);

  const getNewScores = () => {
    return (vettingProcess.feedbackV2?.answers?.scores ||
      vettingProcess.feedback?.answers?.scores) as VettingProcessFeedbackScores;
  };

  return (
    <>
      <Button onClick={toggleChangeScoresModal} size={'small'}>
        Handle feedback scores
      </Button>

      {hasRatingConflict ? (
        <OverwriteProfileScoresModal
          open={changeScoresModal}
          onClose={toggleChangeScoresModal}
          currentScores={vettingProcess.user.scores}
          newScores={getNewScores()}
          onDiscard={handleRejectScores}
          onOverwrite={handleAcceptScores}
        />
      ) : (
        <ApplyScoresModal
          open={changeScoresModal}
          onClose={toggleChangeScoresModal}
          onSave={hasRatingConflict ? undefined : handleAcceptScores}
          onDiscard={handleRejectScores}
        />
      )}

      <LoadingIndicator loading={loading} />
    </>
  );
};
