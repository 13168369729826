import React, { ReactElement, useEffect, useState } from 'react';

import { Margins, Select, SelectOption } from '@ateams/components';
import { useStores } from '@src/stores';
import { apiAccounts } from '@ateams/api';
import useDebounceState from '@src/hooks/useDebounceState';

type SelectChangeValue = SelectOption | SelectOption[] | null;

interface Props {
  selectedAccount?: string;
  onChange: (roles: string[]) => void;
  margin: keyof typeof Margins;
}

const AccountSearch = (props: Props): ReactElement => {
  const { auth } = useStores();
  const { selectedAccount, onChange, margin } = props;
  const [accountOptions, setAccountOptions] = useState<SelectOption[]>([]);
  const [selectedAccountOptions, setSelectedAccountOptions] = useState<
    SelectOption[]
  >([]);

  const [, setSearchQuery] = useDebounceState<string>(
    selectedAccount ?? '',
    (query) => {
      apiAccounts.adminQueryMinimal(auth, query).then(({ items }) => {
        setAccountOptions(
          items.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
        );
      });
    },
    400,
  );

  useEffect(() => {
    if (selectedAccount) {
      const accountOption = accountOptions.find(
        ({ value }) => value === selectedAccount,
      );

      if (accountOption) {
        setSelectedAccountOptions([accountOption]);
      } else {
        apiAccounts
          .adminGetAccount(auth, selectedAccount)
          .then(({ id, name }) => {
            setSelectedAccountOptions([{ value: id, label: name }]);
          });
      }
    }
  }, [selectedAccount]);

  const handleSelections = (selections: SelectChangeValue): void => {
    const selectedOptions = !selections
      ? []
      : Array.isArray(selections)
      ? selections
      : [selections];

    onChange(selectedOptions.map(({ value }) => value));
  };

  return (
    <Select
      hideTags
      isSearchable
      isClearable
      placeholder="Select an account"
      value={selectedAccountOptions}
      margin={margin}
      onInputChange={(query) => setSearchQuery(query)}
      onChange={handleSelections}
      options={accountOptions}
    />
  );
};

AccountSearch.defaultProps = { margin: 'default' };
export default AccountSearch;
