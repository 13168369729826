import { VideoPlayer } from '@src/components/VideoPlayer';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
  },
});

const PlatformWalkthrough = (): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <h4>🛣️ Platform Walkthrough</h4>
      <p>
        <strong>Here’s how you can get selected for a mission:</strong>
      </p>

      <VideoPlayer url="https://www.loom.com/share/dc194d7ca4af4a718ef4de641497cf29" />
      <VideoPlayer url="https://www.loom.com/share/ab037d4ab69340f7ab475c415da3813f" />
    </div>
  );
};

export default PlatformWalkthrough;
