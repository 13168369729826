import { useEffect, useRef } from 'react';
import { EmailFormData } from './EmailForm';
import { ValidAudienceFormData } from './AudienceForm';

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const addSubaddressToEmail = (email: string, subaddress: string) => {
  const [local, domain] = email.split('@');
  return `${local}+${subaddress}@${domain}`;
};

export const persistEmailFromTargeterFormSessionStorage = (
  emailFormData: Partial<EmailFormData>,
) => {
  sessionStorage.setItem(
    'emailFromTargeterForm',
    JSON.stringify(emailFormData),
  );
};

export const persistEmailFromTargeterAudiencesFormSessionStorage = (
  audiencesFormData: Map<string, ValidAudienceFormData>,
) => {
  sessionStorage.setItem(
    'emailFromTargeterAudiencesForm',
    JSON.stringify(Array.from(audiencesFormData.entries())),
  );
};

export const getEmailFromTargeterFormSessionStorage = () => {
  const emailFromTargeterForm = sessionStorage.getItem('emailFromTargeterForm');
  if (emailFromTargeterForm) {
    return JSON.parse(emailFromTargeterForm);
  }
  return null;
};

export const getEmailFromTargeterAudiencesFormSessionStorage = (): Map<
  string,
  ValidAudienceFormData
> => {
  const emailFromTargeterAudiencesForm = sessionStorage.getItem(
    'emailFromTargeterAudiencesForm',
  );
  if (emailFromTargeterAudiencesForm) {
    return new Map(JSON.parse(emailFromTargeterAudiencesForm));
  }
  return new Map();
};

export const FORMATION_EMAIL = 'formation@a.team';

export const formationUserObject = {
  email: FORMATION_EMAIL,
  fullName: 'A.Team',
};
