import React, { ReactElement, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import RegistrationStep from '@src/views/Registration/RegistrationStep';
import { useStores } from '@src/stores';
import { validate as validateEmail } from 'email-validator';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import useDebounceState from '@src/hooks/useDebounceState';
import useLoadingState from '@src/hooks/useLoadingState';
import {
  Breakpoints,
  Checkbox,
  NotificationBanner,
  TextColors,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';
import TextButton from '@src/components/TextButton';
import {
  TalentPrivacyStatementLocation,
  TermsOfServiceLocation,
} from '@src/locations';

const useStyles = createUseStyles({
  banner: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: TextColors.primary,
    fontSize: 14,
    padding: 15,
    marginTop: 24,
    marginBottom: 0,
  },
  input: {
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  [`@media (min-width: ${Breakpoints.xl}px)`]: {
    input: {
      marginBottom: '2em',
      '& .input-label': {
        fontSize: '0.9em',
        marginBottom: '0.8em',
      },
    },
  },
});

const PersonalDetails = (): ReactElement => {
  const styles = useStyles();
  const {
    registration: {
      setEmail,
      formData,
      currentStep,
      setFirstName,
      setLastName,
      processExists,
      setAcceptedTos,
    },
  } = useStores();

  const [loading, setLoading] = useLoadingState();
  const [isValidEmail, setIsValidEmail] = useState<boolean | undefined>();
  const [email, setLocalEmail] = useDebounceState(formData.email, (email) => {
    if (!email) {
      setEmail('');
      setIsValidEmail(undefined);
      return;
    }

    const isValid = validateEmail(email);
    setIsValidEmail(isValid);
    isValid && setEmail(email);
  });
  const [localFirstName, setLocalFirstName] = useState(formData.firstName);
  const [localLastName, setLocalLastName] = useState(formData.lastName);

  const canSubmitStep = useMemo(() => {
    return loading !== true && currentStep.canSubmit && isValidEmail;
  }, [loading, currentStep.canSubmit, isValidEmail]);

  const onSubmit = () => {
    currentStep.onSubmit && setLoading(currentStep.onSubmit());
  };

  const capitalizeStringByCharacter = (name: string, character: string) => {
    const nameParts = name.split(character);
    return nameParts
      .map((namePart) => {
        if (!namePart) {
          return '';
        }
        return (namePart[0]?.toUpperCase() ?? '') + (namePart.slice(1) ?? '');
      })
      .join(character);
  };

  const fixNameFormatting = (name: string) => {
    const characters = [' ', '.', '-', "'"];

    let fixedName = name;
    for (const character of characters) {
      fixedName = capitalizeStringByCharacter(fixedName, character);
    }

    return fixedName;
  };

  const onBlurFirstName = () => {
    const fixedFirstName = fixNameFormatting(localFirstName || '');
    setLocalFirstName(fixedFirstName);
    setFirstName(fixedFirstName);
  };

  const onBlurLastName = () => {
    const fixedLastName = fixNameFormatting(localLastName || '');
    setLocalLastName(fixedLastName);
    setLastName(fixedLastName);
  };

  return (
    <RegistrationStep
      title={"First Off, What's Your Name?"}
      description={
        "And what's the best email address for us to get in contact with you?"
      }
      onSubmit={onSubmit}
      canSubmit={canSubmitStep}
      loading={loading}
    >
      <OutlinedInput
        value={localFirstName || ''}
        label={'First Name'}
        placeholder={'Jack...'}
        onChange={(e) => setLocalFirstName(e.target.value)}
        onBlur={() => onBlurFirstName()}
        required
        nextInputOnEnterKey
        className={styles.input}
      />
      <OutlinedInput
        value={localLastName || ''}
        label={'Last Name'}
        placeholder={'Jackson...'}
        onChange={(e) => setLocalLastName(e.target.value)}
        onBlur={() => onBlurLastName()}
        required
        nextInputOnEnterKey
        className={styles.input}
      />
      <OutlinedInput
        value={email || ''}
        label={'Email Address'}
        placeholder={'name@company.com...'}
        onChange={(e) => setLocalEmail(e.target.value)}
        errorTooltip={'Oops, the email you entered is invalid.'}
        valid={isValidEmail}
        error={!!email && isValidEmail === false}
        required
        nextInputOnEnterKey
        className={styles.input}
      />
      {processExists && (
        <NotificationBanner
          text={
            <span>
              This email is already registered at A.Team. We have sent you an
              email with a link which allows you to continue where you stopped
              your previous registration. Or you can change your email and start
              a new registration.
            </span>
          }
          className={styles.banner}
        />
      )}
      <Checkbox
        label={
          <span style={{ lineHeight: '24px' }}>
            I agree to the A.Team{' '}
            <TextButton
              highlight
              onClick={() => window.open(TermsOfServiceLocation, 'blank')}
            >
              Terms of Service
            </TextButton>{' '}
            and{' '}
            <TextButton
              highlight
              onClick={() =>
                window.open(TalentPrivacyStatementLocation, 'blank')
              }
            >
              Privacy Policy
            </TextButton>
            .
          </span>
        }
        checked={!formData.needsAcceptTOS}
        onChange={(e) => setAcceptedTos(e.target.checked)}
        className={styles.checkbox}
      />
    </RegistrationStep>
  );
};

export default observer(PersonalDetails);
