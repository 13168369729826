import React, { ReactElement } from 'react';
import { SkillList } from '@src/views/Profile/Main/RoleSkills/SkillList';
import { SkillPreference, SkillRequirement } from '@src/stores/Profile/models';
import Section from '@src/views/Profile/Main/partials/Section';

interface Props {
  requiredSkills?: SkillRequirement[];
  preferredSkills?: SkillPreference[];
  requiredGuidanceMessage?: ReactElement;
  preferredGuidanceMessage?: ReactElement;
  guidanceMessage?: ReactElement;
  onClick?: (type: 'required' | 'preferred') => void;
  disabledRequired?: boolean;
  disabledPreferred?: boolean;
}

const RoleSkills = (props: Props): ReactElement => {
  const {
    requiredSkills,
    preferredSkills,
    preferredGuidanceMessage,
    requiredGuidanceMessage,
    guidanceMessage,
    onClick,
    disabledRequired,
    disabledPreferred,
  } = props;

  return (
    <Section title={'Skills'} type={'large'}>
      {guidanceMessage}
      {!!requiredSkills?.length && (
        <SkillList
          title={'Required skills'}
          skills={requiredSkills}
          required
          guidanceMessage={requiredGuidanceMessage}
          onClick={() => onClick && onClick('required')}
          disabled={disabledRequired}
        />
      )}
      {!!preferredSkills?.length && (
        <SkillList
          title={'Preferred skills'}
          skills={preferredSkills}
          guidanceMessage={preferredGuidanceMessage}
          onClick={() => onClick && onClick('preferred')}
          disabled={disabledPreferred}
        />
      )}
    </Section>
  );
};

export default RoleSkills;
