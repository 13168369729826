import React, { useEffect, useMemo, useState } from 'react';
import { BasicVettingProcessColumnProps } from '..';
import { Button } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import useToggle from '@src/hooks/useToggle';
import EvaluationInterviewInviteModal from '@src/components/Modal/EvaluationInterviewInviteModal';

const useStyles = createUseStyles({
  button: {
    width: '200px',
  },
});

export const CancelInterviewField: React.FC<BasicVettingProcessColumnProps> = ({
  vettingProcess,
}) => {
  const styles = useStyles();
  const [showButton, setShowButton] = useState(false);
  const [
    evaluationInterviewInviteModalOpen,
    setEvaluationInterviewInviteModalOpen,
  ] = useToggle();

  useEffect(() => {
    setShowButton(
      !!vettingProcess.calComBookingUid && !!vettingProcess.preVettingFormNonce,
    );
  }, [vettingProcess.calComBookingUid, vettingProcess.preVettingFormNonce]);

  const onCancelInterview = () => {
    setEvaluationInterviewInviteModalOpen(true);
  };

  const timezoneName = useMemo(() => {
    return vettingProcess?.user?.timezone?.name || 'UTC';
  }, [vettingProcess]);

  return (
    <>
      {vettingProcess &&
        vettingProcess.preVettingFormNonce &&
        vettingProcess.interviewDate &&
        timezoneName && (
          <EvaluationInterviewInviteModal
            open={evaluationInterviewInviteModalOpen}
            onClose={() => {
              setEvaluationInterviewInviteModalOpen(false);
            }}
            onMarkAsRead={() => {
              setShowButton(false);
            }}
            preVettingFormNonce={vettingProcess.preVettingFormNonce || ''}
            interviewStartDate={vettingProcess.interviewDate as string}
            builderTimezone={timezoneName}
          />
        )}
      {showButton && (
        <>
          <Button
            onClick={onCancelInterview}
            size="small"
            className={styles.button}
          >
            Cancel Interview
          </Button>
        </>
      )}
    </>
  );
};
