import React, { useEffect, useMemo, useState } from 'react';
import useLoadingState from '@src/hooks/useLoadingState';
import { AsyncSelect, AsyncSelectProps } from '@ateams/components';
import {
  TalentSkill,
  TalentSkillId,
} from '@a_team/models/dist/TalentCategories';
import { fetchSkillList } from '@src/helpers/talent-skills-api';

export interface TalentSkillSelectorProps<IsMulti extends boolean = false>
  extends Omit<
    AsyncSelectProps<IsMulti, TalentSkillOptionType>,
    'loadOptions' | 'onChange'
  > {
  isMulti?: boolean;
  defaultValue?: IsMulti extends true
    ? TalentSkillId[] | TalentSkillOptionType[]
    : TalentSkillId | TalentSkillOptionType;
  omitSkillsWithoutParentCategory?: boolean;
}

export interface TalentSkillOptionType {
  value: string;
  label: string;
  talentSkill: TalentSkill;
}

export function talentSkillToOption(
  talentSkill: TalentSkill,
): TalentSkillOptionType {
  return {
    value: talentSkill?.id ?? '',
    label: talentSkill?.name ?? '',
    talentSkill,
  };
}

export const TalentSkillSelector = <
  T extends TalentSkillSelectorProps<T['isMulti'] extends true ? true : false>,
>(
  props: T,
) => {
  const {
    defaultValue,
    isDisabled,
    onChange,
    isMulti = false,
    omitSkillsWithoutParentCategory,
    ...rest
  } = props;
  const [loadingDefault, setLoadingDefault] = useLoadingState();
  const [talentSkill, setTalentSkill] = useState<
    TalentSkillOptionType[] | TalentSkillOptionType | null
  >();

  const setDefault = async () => {
    if (defaultValue) {
      try {
        setLoadingDefault(
          (async () => {
            if (Array.isArray(defaultValue)) {
              if (typeof defaultValue?.[0] === 'string') {
                const talentSkills = await fetchSkillList({
                  filter: {
                    id: defaultValue as string[],
                  },
                });
                setTalentSkill(talentSkills.map(talentSkillToOption));
              } else {
                setTalentSkill(defaultValue as TalentSkillOptionType[]);
              }
            } else {
              if (typeof defaultValue === 'string') {
                const talentSkill = await fetchSkillList({
                  filter: {
                    id: [defaultValue],
                  },
                });

                setTalentSkill(talentSkillToOption(talentSkill?.[0]));
              } else {
                setTalentSkill(defaultValue as TalentSkillOptionType);
              }
            }
          })(),
          false,
        );
      } catch (err) {
        console.error(err, 'Failed to find talent skill by id');
      }
    }
  };

  useEffect(() => {
    setDefault();
  }, []);

  const value = useMemo(
    () => props.value || talentSkill,
    [props.value, talentSkill],
  );

  const onTalentSkillChange = async (
    option: TalentSkillOptionType[] | TalentSkillOptionType | null,
  ) => {
    setTalentSkill(option);
    onChange && onChange(option);
  };

  const loadOptions = async (searchTerm: string) => {
    const talentSkills = await fetchSkillList({
      filter: {
        query: searchTerm,
      },
    });

    const options = omitSkillsWithoutParentCategory
      ? talentSkills
          .filter((skill) => !!skill.talentCategoryIds.length)
          .map(talentSkillToOption)
      : talentSkills.map(talentSkillToOption);
    return options;
  };

  const onRemoveOption =
    props.onRemoveOption || isMulti
      ? (option: TalentSkillOptionType) => {
          if (!value) {
            return;
          }

          const newTalentSkills = (value as TalentSkillOptionType[]).filter(
            ({ value }) => value !== option.value,
          );

          setTalentSkill(newTalentSkills);
          onChange && onChange(newTalentSkills);
        }
      : undefined;

  return (
    <AsyncSelect<
      T['isMulti'] extends true ? true : false,
      TalentSkillOptionType
    >
      placeholder={'Select Skill...'}
      loadOptions={loadOptions}
      onChange={onTalentSkillChange}
      value={value}
      useDebounce
      isClearable
      isDisabled={isDisabled || loadingDefault}
      isLoading={loadingDefault}
      onRemoveOption={onRemoveOption}
      isMulti={isMulti}
      {...rest}
    />
  );
};
