import React from 'react';
import cx from 'classnames';
import { useCommonStyles } from '../commonStyles';
import { Button } from '@a_team/ui-components';
import BrokenCalendar from './svgs/brokenCalendar.svg';
import CustomCalendars from './svgs/customCalendars.svg';
import TechIssues from './svgs/techIssue.svg';

interface CalendarHelpProps {
  onClose: () => void;
  onSupport: () => void;
}

export const CalendarHelp = (props: CalendarHelpProps) => {
  const styles = useCommonStyles();

  return (
    <>
      <div className={styles.hero}>
        <h4 className={styles.modalTitle}>Tell us why you can't connect</h4>
        <p className={cx(styles.heroCopy, 'lessPadding')}>
          Get support enabling interview scheduling
        </p>
      </div>
      <div className={styles.howtoWrap}>
        <div className={cx(styles.panel, 'ghost', 'fixed')}>
          <div className={styles.panelHeader}>
            <img
              src={BrokenCalendar}
              className={styles.helpImg}
              alt="Calendar isn't supported"
            />
          </div>
          <h5 className={styles.panelTitle}>My calendar isn’t supported</h5>
          <p className={styles.panelCopy}>
            Learn how to connect your unsupported calendars to enable easy
            interview scheduling.
          </p>
          <a
            className={styles.linkButton}
            href="https://guide.a.team/missions/interview-scheduling#a8340b01414744d98872ad7ad0233f8f"
            rel="noopener noreferrer"
            target="_blank"
          >
            Open A.Team article
          </a>
        </div>
        <div className={cx(styles.panel, 'ghost', 'fixed')}>
          <div className={styles.panelHeader}>
            <img
              src={CustomCalendars}
              className={styles.helpImg}
              alt="Multiple un-supported calendars"
            />
          </div>
          <h5 className={styles.panelTitle}>I use multiple calendars</h5>
          <p className={styles.panelCopy}>
            It’s easy to connect multiple calendars by using our built in
            calendar integrations.
          </p>
          <a
            className={styles.linkButton}
            href="https://guide.a.team/missions/interview-scheduling#008c5012242c4c5e88f448f0a4026d11"
            rel="noopener noreferrer"
            target="_blank"
          >
            Open A.Team article
          </a>
        </div>
        <div className={cx(styles.panel, 'ghost', 'fixed')}>
          <div className={styles.panelHeader}>
            <img
              src={TechIssues}
              className={styles.helpImg}
              alt="Technical Issues"
            />
          </div>
          <h5 className={styles.panelTitle}>
            I’m having technical difficulties
          </h5>
          <p className={styles.panelCopy}>
            I need help getting my calendar set up.
          </p>
          <button className={styles.linkButton} onClick={props.onSupport}>
            Contact Support
          </button>
        </div>
        <div className={styles.actions} style={{ paddingTop: 32 }}>
          <Button
            className={styles.closeBtn}
            variant="secondary"
            size="sm"
            onClick={props.onClose}
          >
            Back
          </Button>
        </div>
      </div>
    </>
  );
};
