import { TeamPulse, TeamPulseSurvey } from '@a_team/models/dist/TeamPulse';
import { Icon, IconType, Card } from '@ateams/components';
import React, { ReactElement, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import TeamPulseResponseModal from './TeamPulseResponseModal';
import cx from 'classnames';
import { useStores } from '@src/stores';
import {
  SurveyQuestionType,
  SurveyResponseMap,
} from '@a_team/models/dist/Survey';
import { sum } from 'lodash';
import formatDate from '@src/helpers/formatDate';
import ColoredCircle from '@src/components/ColoredCircle';
import ActiveRatingStar from '@src/components/RatingStar/ActiveRatingStar';
import TeamBarometerIndicator from './TeamBarometerIndicator';
import { getTeamPulseAverageScore } from './utils';

export interface Props {
  enableResponseModal?: boolean;
  teamPulse: TeamPulse;
  teamPulseSurveys?: TeamPulseSurvey[];
  adminView?: boolean;
}

const useStyles = createUseStyles({
  card: {
    marginRight: 0,
    maxWidth: '470px',
  },
  doubleCard: {
    marginRight: 0,
    maxWidth: '916px',
  },
  outerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '-12px',
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '422px',
    margin: '12px',
  },
  indicator: {
    width: '80px',
    height: '80px',
    flexShrink: 0,
  },
  noDataIndicator: {
    width: '32px',
    height: '32px',
    flexShrink: 0,
  },
  description: {
    paddingLeft: '24px',
    minHeight: '80px',
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginTop: 0,
    marginBottom: '8px',
    fontSize: '15px',
    lineHeight: '24px',
  },
  date: {
    color: '#62646A',
    marginBottom: 0,
    marginTop: '8px',
    fontSize: '15px',
    lineHeight: '24px',
  },
  indicatorChild: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    color: '#ffffff',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '26px',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clientRating: {
    padding: '12px',
    paddingTop: '9px',
  },
  clientRatingStar: {
    width: '100%',
    height: '100%',
  },
});

const DetailedTeamBarometerCard = (props: Props): ReactElement | null => {
  const styles = useStyles();
  const { enableResponseModal, teamPulse, teamPulseSurveys, adminView } = props;
  const [showResponseModal, setShowResponseModal] = useState(false);
  const { missions } = useStores();
  const { currentMission } = missions;

  const managers = useMemo(
    () => currentMission?.data?.managers?.map(({ user }) => user.uid) ?? [],
    [currentMission?.data?.managers],
  );
  // Only set in admin mode
  const managerSurveys = useMemo(
    () =>
      teamPulseSurveys?.filter((survey) => managers.includes(survey.userId)),
    [teamPulseSurveys, managers],
  );
  const managerRatings = useMemo(
    () =>
      managerSurveys
        ?.map(
          (survey) =>
            // The cast here is necessary because the component need not know the
            // type of the survey, so we assume the key is present and ignore
            // null values
            (
              survey.response as
                | SurveyResponseMap<{
                    overallRating: SurveyQuestionType.RatingWithFreeFormComment;
                  }>
                | { overallRating: never }
                | null
            )?.overallRating?.value,
        )
        .filter((value): value is number => typeof value === 'number'),
    [managerSurveys],
  );
  const averageManagerRating = useMemo(
    () =>
      managerRatings && managerRatings.length > 0
        ? sum(managerRatings) / managerRatings.length
        : null,
    [managerRatings],
  );

  const score = useMemo(
    () => getTeamPulseAverageScore(teamPulse, adminView),
    [teamPulse.averageRatingBuilder, teamPulse.averageRatingClient, adminView],
  );

  const onCloseModal = () => {
    setShowResponseModal(false);
  };

  const noAverageRating = score === null;

  return (
    <>
      <Card
        className={adminView ? styles.doubleCard : styles.card}
        onClick={() => setShowResponseModal(true)}
        style={{
          cursor: enableResponseModal && teamPulseSurveys ? 'pointer' : 'unset',
        }}
      >
        <div className={styles.outerWrapper}>
          <div className={cx(styles.innerWrapper)}>
            {noAverageRating ? (
              <div className={cx(styles.noDataIndicator, styles.center)}>
                <Icon type={IconType.Clock} />
              </div>
            ) : (
              <TeamBarometerIndicator detailed score={score as number} />
            )}

            <div className={styles.description}>
              <div>
                <p className={styles.label}>
                  {noAverageRating
                    ? 'Not enough people have answered this team pulse to display a score.'
                    : 'Average team score based on feedback from your team mates.'}
                </p>
                <p className={styles.date}>
                  {formatDate(teamPulse.coveringInterval.start)} –{' '}
                  {formatDate(teamPulse.coveringInterval.end)}
                </p>
              </div>
            </div>
          </div>

          {adminView && (
            <div className={cx(styles.innerWrapper)}>
              {noAverageRating ? (
                <div className={cx(styles.noDataIndicator, styles.center)}>
                  <Icon type={IconType.Clock} />
                </div>
              ) : (
                <ColoredCircle color="#FFF8EB" className={styles.indicator}>
                  <div
                    className={cx(
                      styles.indicatorChild,
                      styles.center,
                      styles.clientRating,
                    )}
                  >
                    <ActiveRatingStar className={styles.clientRatingStar} />
                  </div>
                  <div className={cx(styles.indicatorChild, styles.center)}>
                    {averageManagerRating?.toFixed(1)}
                  </div>
                </ColoredCircle>
              )}

              <div className={styles.description}>
                <div>
                  <p className={styles.label}>
                    {noAverageRating
                      ? "The client still hasn't evaluated the team's work and hasn't sent their feedback."
                      : 'Evaluation of teamwork by the client over the last working period.'}
                  </p>
                  <p className={styles.date}>
                    {formatDate(teamPulse.coveringInterval.start)} –{' '}
                    {formatDate(teamPulse.coveringInterval.end)}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
      {enableResponseModal && teamPulseSurveys && (
        <TeamPulseResponseModal
          onClose={onCloseModal}
          open={showResponseModal}
          teamPulse={teamPulse}
          teamPulseSurveys={teamPulseSurveys}
        />
      )}
    </>
  );
};

export default DetailedTeamBarometerCard;
