import React, { useState } from 'react';
import GeneratePitchView from './GeneratePitchView';
import SuggestionView from './SuggestionView';

interface AssistantProps {
  hasDescription?: boolean;
  hasPitch?: boolean;
  isLoading?: boolean;
  suggestion?: string;
  onGenerateSuggestion: () => void;
  onInsertSuggestion: (suggestion: string) => void;
  onDiscardSuggestion: (suggestion: string) => void;
}

const Assistant = (props: AssistantProps) => {
  const {
    isLoading,
    suggestion,
    onGenerateSuggestion,
    onInsertSuggestion,
    onDiscardSuggestion,
  } = props;
  const [isRewriting, setIsRewriting] = useState<boolean>(false);

  const handleRegeneration = () => {
    setIsRewriting(true);

    onGenerateSuggestion();
  };

  if (!suggestion) {
    return (
      <GeneratePitchView
        isLoading={isLoading}
        isRewriting={isRewriting}
        onGenerateSuggestion={onGenerateSuggestion}
      />
    );
  }

  return (
    <SuggestionView
      onRegenerateSuggestion={handleRegeneration}
      onInsertSuggestion={() => onInsertSuggestion(suggestion)}
      onDiscardSuggestion={() => onDiscardSuggestion(suggestion)}
      isLoading={isLoading}
      suggestion={suggestion}
    />
  );
};

export default Assistant;
