import { UserCardObject } from '@a_team/models/dist/UserObject';
import { Button } from '@a_team/ui-components';
import { Breakpoints } from '@ateams/components';
import { ProfileLocation } from '@src/locations';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

export interface BannerProps {
  users: UserCardObject[];
  title: string;
  description: string;
  ctaLabel?: string;
  ctaOnClick?: () => void;
  showUsersCounter?: boolean;
}

const useStyles = createUseStyles({
  parent: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  btn: {
    height: 40,
  },
  container: {
    borderRadius: 16,
    background: 'linear-gradient(270deg, #000 0%, #26014C 46.87%)',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    width: '100%',
    alignItems: 'flex-start',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    flex: 1,
  },
  imagesWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  images: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    borderRadius: 32,
    border: '2px solid #27024D',
    marginRight: -8,
    background: '#FFFFFF',
  },
  title: {
    color: '#FFFFFF',
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '24px',
  },
  description: {
    color: '#D3D6DC',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: 4,
  },
  tag: {
    color: '#FFFFFF',
    background: '#6D00D7',
    height: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    fontSize: 10,
    padding: '2px 4px',
    minWidth: 18,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '16px 24px',
    },
    content: {
      flexDirection: 'row',
    },
  },
});

const TOTAL_IMAGES_TO_DISPLAY = 5;

const Banner: React.FC<BannerProps> = ({
  users,
  title,
  description,
  ctaLabel,
  showUsersCounter,
  ctaOnClick,
}) => {
  const styles = useStyles();

  const profilePictureURLs = users.map((user) => user.profilePictureURL);

  const usersToDisplay = users.slice(0, TOTAL_IMAGES_TO_DISPLAY);

  if (usersToDisplay.length === 0) {
    return null;
  }

  return (
    <div className={styles.parent}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.imagesWrapper}>
            <span className={styles.images}>
              {usersToDisplay.map((user) => (
                <Link to={ProfileLocation(user.username)} target="_blank">
                  <img
                    key={user.uid}
                    className={styles.image}
                    src={user.profilePictureURL}
                    alt="User profile"
                    width={32}
                    height={32}
                  />
                </Link>
              ))}
              {showUsersCounter && (
                <span className={styles.tag}>{profilePictureURLs.length}</span>
              )}
            </span>
          </div>
          <div>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
          </div>
        </div>
        {ctaLabel && ctaOnClick && (
          <Button onClick={ctaOnClick} className={styles.btn}>
            {ctaLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Banner;
