import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { theme } from '@a_team/ui-components';
import { Button, CollapsibleContainer, Spacing } from '@ateams/components';
import { SparklesIcon } from './sparkles-icon';
import AnalyzeMissionApplication from './AnalyzeMissionApplication';
import { AnalysisScores } from './AnalysisScores';
import { useGetMissionApplicationAnalyses } from '@src/rq/missions';
import { toOrdinalNumber } from '@src/logic/utils';
import { AnalysisSuggestions } from './AnalysisSuggestions';

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: theme.colors.Hannibal[100],
    borderRadius: 12,
    padding: '16px',
    margin: 0,
    marginBottom: 40,
    width: '820px',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '20px',
    marginBottom: Spacing.medium,
  },
  titleIcon: {
    marginRight: Spacing.small,
  },
  sectionTitle: {
    color: theme.colors.Grey[900],
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
    height: '22px',
    marginBottom: Spacing.small,
  },
  sectionContent: {
    fontWeight: 300,
    fontSize: '15px',
    lineHeight: '24px',
    color: theme.colors.Grey[1000],
    marginBottom: Spacing.medium,
  },
  titleText: {
    color: theme.colors.Hannibal[600],
    fontWeight: 500,
    fontSize: '14px',
  },
  collapsibleContainer: {
    height: '20px',
    margin: 0,
  },
  collapsibleContainerTitle: {
    color: theme.colors.Hannibal[600],
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  collapsibleContainerArrow: {
    width: '12px',
    height: '12px',
  },
  analysisScores: {
    marginBottom: Spacing.medium,
  },
  evaluation: {
    marginBottom: 16,
  },
  loadMore: {
    alignSelf: 'flex-start',
    width: '132px',
    height: '32px',
    padding: 0,
    borderRadius: 8,
    marginBottom: 16,
  },
});

interface AnalysisProps {
  applicationId: string;
}

const ApplicationAnalysis = (props: AnalysisProps) => {
  const styles = useStyles();
  const [isAnalyzing, setIsAnalyzing] = useState<boolean>(false);
  const { data, isLoading, hasNextPage, fetchNextPage } =
    useGetMissionApplicationAnalyses(props.applicationId);

  const applicationAnalyses = useMemo(
    () => (data?.pages || []).flatMap(({ analyses }) => analyses),
    [data?.pages],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <SparklesIcon className={styles.titleIcon} />
        <div className={styles.titleText}>Application Analysis</div>
      </div>
      {isLoading && !isAnalyzing && `Loading analysis...`}
      {applicationAnalyses.map((analysis, idx) => (
        <div className={styles.evaluation} key={analysis.id}>
          <div className={styles.sectionTitle}>
            {`${toOrdinalNumber(idx + 1)}`} Evaluation
          </div>
          <div
            className={styles.sectionContent}
            data-testing-id={'application-analysis-evaluation'}
          >
            {analysis?.evaluation}
          </div>
          <CollapsibleContainer
            title="View Details"
            expandTitle="Hide Details"
            contentPlacement={'top'}
            headerClassName={styles.collapsibleContainer}
            titleClassName={styles.collapsibleContainerTitle}
            arrowClassName={styles.collapsibleContainerArrow}
          >
            {analysis?.suggestedImprovements?.length ? (
              <AnalysisSuggestions
                suggestions={analysis.suggestedImprovements}
              />
            ) : null}
            {analysis?.scores && (
              <AnalysisScores
                scores={analysis.scores}
                className={styles.analysisScores}
              />
            )}
          </CollapsibleContainer>
        </div>
      ))}
      {hasNextPage && (
        <Button
          outlined
          className={styles.loadMore}
          onClick={() => fetchNextPage()}
        >
          Load more
        </Button>
      )}
      <AnalyzeMissionApplication
        reAnalyze={applicationAnalyses.length > 0}
        onAnalyzing={() => setIsAnalyzing(true)}
        onSuccess={() => setIsAnalyzing(false)}
        missionApplicationId={props.applicationId}
      />
    </div>
  );
};

export default ApplicationAnalysis;
