import React from 'react';
import { DndProvider, DragObjectWithType, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

type DraggableItemProps = {
  id: string;
  index: number;
  onMove: (dragIndex: number, hoverIndex: number) => void;
};

type DraggableItemType = DragObjectWithType & {
  id: string;
  index: number;
};

const DraggableItem: React.FC<DraggableItemProps> = ({
  id,
  index,
  onMove,
  children,
}) => {
  const [, ref] = useDrag({
    item: { id, index, type: 'ITEM' } as DraggableItemType,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [, drop] = useDrop<DraggableItemType, any, any>({
    accept: 'ITEM',
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        onMove(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return <div ref={(node) => ref(drop(node))}>{children}</div>;
};

type DraggableContainerProps = {
  renderedItems: React.ReactElement[];
  items: string[];
  onOrderChange: (newOrder: string[]) => void;
};

const DraggableContainer: React.FC<DraggableContainerProps> = ({
  items,
  renderedItems,
  onOrderChange,
}) => {
  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const draggedItem = items[dragIndex];
    const newItems = [...items];
    newItems.splice(dragIndex, 1);
    newItems.splice(hoverIndex, 0, draggedItem);

    // Call the callback with the new order of strings
    onOrderChange(newItems);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 8,
        }}
      >
        {renderedItems.map((item, index) => (
          <DraggableItem
            key={index}
            id={index.toString()}
            index={index}
            onMove={moveItem}
          >
            {item}
          </DraggableItem>
        ))}
      </div>
    </DndProvider>
  );
};

export default DraggableContainer;
