import React, { ReactElement } from 'react';
import { Tag } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import { TeammateSuggestionObject } from '@a_team/models/dist/UserObject';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Breakpoints } from '@ateams/components';

interface Props {
  users?: TeammateSuggestionObject[];
  avatarSize?: number;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    background: '#EFEFF0',
    padding: '10px 16px',
    height: 'auto',
    position: 'relative',
  },
  tag: {
    position: 'absolute',
    zIndex: 9,
    fontSize: 11,
    padding: '2px 9px',
    top: 12,
    right: 8,
  },
  avatar: {
    marginRight: -8,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      height: '100%',
      padding: '20px 24px',
    },
  },
});

export const SelectedUsers = (props: Props): ReactElement => {
  const styles = useStyles();
  const { users, avatarSize = 40, className } = props;
  return (
    <div className={cx(styles.container, className)}>
      <Tag
        thin
        color={users?.length ? 'success' : 'regular'}
        className={styles.tag}
      >
        {users?.length || 0}
      </Tag>
      {users && users?.length > 0 ? (
        users.slice(0, 5).map((user) => (
          <UserAvatar
            key={user.uid}
            src={user.profilePictureURL}
            size={avatarSize}
            containerClassName={styles.avatar}
            containerStyle={{
              ...(users?.length === 1 && { marginRight: 0 }),
            }}
          />
        ))
      ) : (
        <div
          style={{
            width: avatarSize,
            height: avatarSize,
            background: '#FFF',
            borderRadius: '50%',
            border: '1px solid #C0C0C0',
          }}
        />
      )}
    </div>
  );
};
