import { Button, Icon } from '@a_team/ui-components';
import {
  ClientMissionPageLocation,
  EditMissionLocation,
  MissionAdminNotificationsLocation,
  MissionPageAdminLocation,
  MissionPageLocation,
  MissionProposalLocation,
  TargeterLocation,
} from '@src/locations';
import { useStores } from '@src/stores';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { Breakpoints, Icon as IconComp, IconType } from '@ateams/components';
import { MissionStatus } from '@a_team/models/dist/MissionObject';

interface AdminNavProps {
  toggleDeleteModal?: (force?: unknown) => void;
  togglePublish: (force?: unknown) => void;
}

const useStyles = createUseStyles({
  container: {
    background: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 24,
    padding: '75px 12px 12px 12px',
    borderBottom: '1px solid #DADADC',

    '& button': {
      border: '1px solid #DADADC !important',
      padding: '8px 12px !important',
      borderRadius: '8px !important',
      '& span': {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        // svg cursor pointer
        '& svg': {
          cursor: 'pointer',
        },
      },
    },
  },
  iconBtn: {
    padding: 0,
    height: '40px',
    width: '40px !important',
  },
  btnsContainer: {
    display: 'flex',
    gap: 8,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  verticalLine: {
    width: 1,
    height: 16,
    background: '#DADADC',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      padding: 24,
    },
  },
});

const AdminNav = ({ toggleDeleteModal, togglePublish }: AdminNavProps) => {
  const history = useHistory();
  const styles = useStyles();
  const { users, missions } = useStores();

  const mission = missions.currentMission;
  const isInReview = mission?.data.status === MissionStatus.Created;

  const openTargeterForMission = async (
    e: React.MouseEvent<HTMLElement>,
    type: 'outreach' | 'review' | 'teamsearch',
  ) => {
    if (!mission) return;

    const url = `${TargeterLocation}?mid=${mission.mid}&type=${type}`;

    window.open(url, '_blank');
    e.preventDefault();
    e.stopPropagation();

    switch (type) {
      case 'outreach':
        if (mission) {
          users.trackTargeterOutreachClicked(
            mission.data.mid,
            mission.data.title,
            mission.data.status,
          );
        }
        break;
      case 'review':
        if (mission) {
          users.trackTargeterReviewClicked(
            mission.data.mid,
            mission.data.title,
            mission.data.status,
          );
        }
        break;
      case 'teamsearch':
        if (mission) {
          users.trackTargeterTeamSearchClicked(
            mission.data.mid,
            mission.data.title,
            mission.data.status,
          );
        }
        break;
    }
  };

  if (!mission) return null;

  return (
    <div className={styles.container}>
      <div className={styles.btnsContainer}>
        <Button
          variant={'ghost'}
          size="sm"
          onClick={() => {
            history.push(MissionProposalLocation(mission?.mid));
          }}
        >
          <IconComp
            size="exact"
            type={IconType.Proposal}
            style={{
              height: '16px',
              width: '16px',
            }}
          />
          Create proposal
        </Button>
        <div className={styles.verticalLine} />
        <Button
          variant={'ghost'}
          size="sm"
          onClick={() =>
            history.push(MissionAdminNotificationsLocation(mission?.mid))
          }
        >
          <Icon name="list" size="md" />
          Reach out
        </Button>
        <Button
          variant={'ghost'}
          size="sm"
          onClick={(e) => openTargeterForMission(e, 'outreach')}
        >
          <Icon name="send" size="md" />
          Outreach
        </Button>
        <Button
          variant={'ghost'}
          size="sm"
          onClick={(e) => openTargeterForMission(e, 'review')}
        >
          <IconComp
            size="exact"
            type={IconType.Targeter}
            style={{
              height: '16px',
              width: '16px',
            }}
          />
          Review
        </Button>
        <Button
          variant={'ghost'}
          size="sm"
          onClick={(e) => openTargeterForMission(e, 'teamsearch')}
        >
          <Icon name="teamMembers" size="md" />
          Team Search
        </Button>
        <div className={styles.verticalLine} />
        <Button
          variant={'ghost'}
          size="sm"
          onClick={() => {
            const url = ClientMissionPageLocation(
              mission?.data.missionSpecId || '',
            );
            window.open(url, '_blank');
          }}
        >
          <Icon name="link" size="md" /> Open client app
        </Button>
      </div>
      <div className={styles.btnsContainer}>
        <Button
          className={styles.iconBtn}
          variant={'ghost'}
          size="sm"
          onClick={toggleDeleteModal}
        >
          <Icon name="delete" size="md" />
        </Button>
        <Button
          variant={'ghost'}
          size="sm"
          className={styles.iconBtn}
          onClick={() => {
            history.push(EditMissionLocation(mission.mid || ''));
          }}
        >
          <Icon name="edit" size="md" />
        </Button>
        <Button
          onClick={
            mission?.isAdminMode
              ? () => history.push(MissionPageLocation(mission.mid))
              : () => history.push(MissionPageAdminLocation(mission.mid))
          }
          variant={'ghost'}
        >
          {mission?.isAdminMode ? 'See builder view' : 'See admin view'}
        </Button>

        {isInReview ? (
          <Button onClick={togglePublish}>Publish</Button>
        ) : (
          <Button onClick={togglePublish}>Automated Reachout</Button>
        )}
      </div>
    </div>
  );
};

export default AdminNav;
