import { Icon, IconType } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import ConnectionStatus from './ConnectionStatus';
import { ConnectionStatus as ConnectionStatusEnum } from '@a_team/models/dist/ConnectionObject';
import AvatarGroup from './AvatarGroup';
import cx from 'classnames';
import Profile from '@src/stores/Profile/Profile';

interface ConnectionItemProps {
  sampleUsersImageUrls?: string[];
  className?: string;
  connectionStatus?: ConnectionStatusEnum;
  profile: Profile;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  connectionTotalStatusContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: ({ isCurrentUser }) => (!isCurrentUser ? 8 : 0),
  },
  wrapper: {
    padding: ({ isCurrentUser }) => (!isCurrentUser ? '16px 16px 0 16px' : 16),
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  connectionTotal: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  connectionType: {
    fontWeight: 500,
  },
  desc: {
    fontSize: '12px',
    textAlign: 'start',
  },
  descStatusContainer: {
    display: 'flex',
    justifyContent: ({ connectionStatus }) =>
      connectionStatus === ConnectionStatusEnum.Active
        ? 'center'
        : 'flex-start',
    borderTop: '1px solid #EFEFF0',
    padding: '7px 16px',
  },
  connectionTotalsContainer: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  workWithTxt: {
    color: '#6D00D7',
  },
});

const ConnectionItem = ({
  sampleUsersImageUrls,
  className,
  connectionStatus,
  profile,
}: ConnectionItemProps): JSX.Element => {
  const { isCurrentUser, totalWorkConnections, totalOverallConnections } =
    profile;
  const styles = useStyles({ isCurrentUser, connectionStatus });

  const iconType = IconType.Collaborator;

  const connectionLabel =
    totalOverallConnections === 1 ? 'connection' : 'connections';

  const getDesc = () => {
    if (!totalWorkConnections) {
      return;
    }
    if (isCurrentUser) {
      return (
        <>
          I've{' '}
          <span className={styles.workWithTxt}>
            worked with {totalWorkConnections}
          </span>{' '}
          of these connections
        </>
      );
    }
    return (
      <>
        {profile.data.firstName}{' '}
        <span className={styles.workWithTxt}>
          worked with {totalWorkConnections}
        </span>{' '}
        of these connections
      </>
    );
  };

  const description = getDesc();

  return (
    <div className={cx(className, styles.container)}>
      <div className={styles.connectionTotalStatusContainer}>
        <div className={styles.wrapper}>
          <div className={styles.connectionTotal}>
            <div className={styles.connectionTotalsContainer}>
              <Icon type={iconType} size="exact" />
              <span className={styles.connectionType}>
                {totalOverallConnections} {connectionLabel}
              </span>
            </div>
            {sampleUsersImageUrls && sampleUsersImageUrls.length > 0 && (
              <AvatarGroup avatarUrls={sampleUsersImageUrls} />
            )}
          </div>
          <div className={styles.desc}>{description}</div>
        </div>
        {!isCurrentUser && (
          <>
            <div className={styles.descStatusContainer}>
              <ConnectionStatus
                status={connectionStatus}
                firstName={profile.data.firstName}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConnectionItem;
