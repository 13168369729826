import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { Colors, Icon, IconType, TextColors } from '@ateams/components';

interface Props {
  onClose?: () => void;
  children: ReactNode;
  styles?: CSSProperties;
}

const StickyStrip = (props: Props): ReactElement => {
  const { onClose, children } = props;
  return (
    <>
      <div
        style={{
          width: '100%',
          position: 'sticky',
          top: 0,
          zIndex: 999,
          boxShadow: '0px 1px 5px rgb(0 0 0 / 15%)',
          alignItems: 'center',
          padding: '16px',
          background: Colors.backgroundWhite,
          display: 'flex',
          justifyContent: 'space-between',
          color: TextColors.backgroundWhite,
          ...props.styles,
        }}
      >
        {children}
        {onClose && (
          <Icon type={IconType.Close} size={'xsmall'} onClick={onClose} />
        )}
      </div>
    </>
  );
};

export default StickyStrip;
