import { apiTalentSpecializations } from '@ateams/api';
import { BuilderMissionStatus } from '@ateams/api/dist/endpoints/TeamGraph';
import { PreferencesDtoDisabledCategoriesEnum } from '@a_team/user-notification-service-js-sdk';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery';
import AuthStore from '@src/stores/Auth';
import { TargeterSearchCache } from '@src/stores/TeamGraph/TargeterTabManager';
import queryString, { StringifiableRecord } from 'query-string';
import sub from 'date-fns/sub';
import {
  ExperienceGraphService,
  OpenAPI,
  TalentSpecialization,
} from '@a_team/data-science-api-client';

const PRODUCT_DESIGNER_DEFAULT_FILTER: Partial<RoleFilter> = {
  unvetted: true,
  requireMainSpecialization: true,
};

const PRODUCT_MANAGER_DEFAULT_FILTER: Partial<RoleFilter> = {
  requireMainSpecialization: true,
};

const DEFAULT_QUERY: RoleFilter = {
  weeklyHoursAvailable: 10,
  includeUnknownAvailability: false,
  useEstimatedAvailability: false,
  requireMissionStatus: true,
  missionStatus: BuilderMissionStatus.NotActive,
  exceptionalATeamer: true,
  residentATeamer: true,
  includeExceptional: true,
  includeVerified: true,
  includeLegacyUnknown: true,
  requiredNotificationCategories: [
    PreferencesDtoDisabledCategoriesEnum.MissionNotification.toString(),
  ],
};

const getDefaultQuery = (options: Partial<RoleFilter> = {}): string => {
  return queryString.stringify(
    {
      ...DEFAULT_QUERY,
      ...options,
      latestApplicationDate: sub(new Date(), { days: 28 }).toISOString(),
    } as StringifiableRecord,
    { arrayFormat: 'bracket' },
  );
};

export const fetchDefaultTabData = async (
  auth: AuthStore,
): Promise<TargeterSearchCache[]> => {
  const [engineer, productManager, productOwner, designer] = await Promise.all([
    apiTalentSpecializations.queryTalentSpecializations(auth, {
      searchTerm: 'software engineer',
    }),
    apiTalentSpecializations.queryTalentSpecializations(auth, {
      searchTerm: 'product manager',
    }),
    apiTalentSpecializations.queryTalentSpecializations(auth, {
      searchTerm: 'product owner',
    }),
    apiTalentSpecializations.queryTalentSpecializations(auth, {
      searchTerm: 'product designer',
    }),
  ]);

  OpenAPI.TOKEN = auth.token ?? undefined;
  const engineers = await ExperienceGraphService.getAdjacentRoles(
    engineer.items[0].id,
  );

  return [
    {
      label: 'Software Engineer',
      filter: {
        specializations: [
          ...engineer.items.map(({ id }) => id),
          ...engineers.items.map((item) => (item as TalentSpecialization).id),
        ],
      },
    },
    {
      label: 'Product Manager',
      filter: {
        ...PRODUCT_MANAGER_DEFAULT_FILTER,
        specializations: [
          ...productManager.items.map(({ id }) => id),
          ...productOwner.items.map(({ id }) => id),
        ],
      },
    },
    {
      label: 'Product Designer',
      filter: {
        ...PRODUCT_DESIGNER_DEFAULT_FILTER,
        specializations: designer.items.map(({ id }) => id),
      },
    },
  ].map(({ label, filter }, position) => ({
    label,
    position,
    url: getDefaultQuery(filter),
    selectedBuilders: [],
    builders: [],
    next: undefined,
  }));
};
