import React, { ReactElement, useState, useMemo, useEffect } from 'react';
import { useStores } from '@src/stores';
import MainLayout from '@src/layouts/Main';
import { Input, Typography, Checkbox, Button } from '@a_team/ui-components';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';

const useStyles = createUseStyles({
  pageWrapper: {
    display: 'flex',
    padding: 0,
  },
  sidebarContainer: {
    borderRight: '1px solid #e5e5e5',
    minHeight: '100vh',
    padding: '20px',
    width: '350px',
  },
  templatesContainer: {
    minHeight: '300px',
  },
  templatePreview: {
    padding: 20,
    width: '100%',
  },
});

const SignatureCreator = (): ReactElement => {
  const styles = useStyles();
  const { auth } = useStores();
  const [fullName, setFullName] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [inviteLink, setInviteLink] = useState<string>('');

  const [includeDetails, setIncludeDetails] = useState<boolean>(true);
  const [loading, setLoading] = useLoadingState(null);

  useEffect(() => {
    setFullName(auth.user?.fullName || '');
    setTitle(auth.user?.title || '');
    setEmail(auth.user?.email || '');
    setPhone(auth.user?.phoneNumber || '');
    setInviteLink(`https://invite.a.team/${auth.user?.username}` || '');
  }, [auth.user]);

  const copySignature = async () => {
    const iframe = document.getElementById('sig-iframe') as HTMLIFrameElement;
    const iframeContent = iframe?.contentDocument;
    const content = iframeContent?.getElementById('email-signature')?.innerHTML;
    if (!content) {
      return;
    }
    const blob = new Blob([content], { type: 'text/html' });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const richTextInput = new ClipboardItem({ 'text/html': blob });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-restricted-globals
    setLoading(parent.navigator.clipboard.write([richTextInput]));
  };

  const signatureHtml = useMemo(() => {
    const nameAndTitle = `<tr>
          <td colspan="4" style="font-family: 'Arial'; font-style: normal; font-weight: 700; font-size: 22px; line-height: 25px;">${fullName}</td>
      </tr>
      <tr>
          <td colspan="4" style="font-family: 'Arial'; font-style: normal; font-weight: 700; font-size: 13px; line-height: 15px; color: #878787;">
              ${title.toUpperCase()}
          </td>
      </tr>`;
    const details = `<tr height="20">
        <td style="font-family: 'Arial'; font-style: normal; font-weight: 400; color: #aaaaaa; font-size: 12px; line-height: 14px; padding-right: 5px;">${phone}</td>
        <td height="12"><div style="height: 14px; width: 1px; border-right: 1px solid #d5d5d5;"></div></td>
        <td style="font-family: 'Arial'; font-style: normal; font-weight: 400; color: #aaaaaa; font-size: 12px; line-height: 14px; padding-left: 5px;">${email}</td>
    </tr>`;

    return `<div id="email-signature"><table style="font-family: 'Arial';">
        <table>
          <tbody>
              ${nameAndTitle}
              ${includeDetails ? details : ''}
              <tr height="30"><td colspan="4"><a href="${inviteLink}"><img src="https://cdn.a.team/email-signature/ateam-logo.png" height="17"></a></td></tr>
          </tbody>
      </table>
    </div>`;
  }, [fullName, title, email, phone, includeDetails]);

  return (
    <MainLayout title="A.Team Email Templates" style={{ padding: 0 }}>
      <div className={styles.pageWrapper}>
        <div className={styles.sidebarContainer}>
          <>
            {!!auth.user && (
              <div className={styles.templatesContainer}>
                <Typography variant={'textMedium'}>
                  Please edit your details here, click on the "copy to clipboard
                  and paste it into your email client
                </Typography>

                {/* full name */}
                <Typography
                  variant={'h4'}
                  margin={'xs'}
                  style={{ marginTop: 30 }}
                >
                  Full Name
                </Typography>
                <Input
                  placeholder={'Full Name...'}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                {/* title */}
                <Typography variant={'h4'} margin={'xs'}>
                  Title
                </Typography>
                <Input
                  placeholder={'Title...'}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {/* email */}
                <Typography variant={'h4'} margin={'xs'}>
                  Email
                </Typography>
                <Input
                  placeholder={'Email...'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {/* phone */}
                <Typography variant={'h4'} margin={'xs'}>
                  Phone
                </Typography>
                <Input
                  placeholder={'Phone...'}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <Typography
                  variant={'h4'}
                  margin={'xs'}
                  style={{ marginTop: 20 }}
                >
                  <Checkbox
                    label="Include details"
                    checked={includeDetails}
                    onChange={(e) => setIncludeDetails(e.target.checked)}
                  />
                </Typography>
                <Button onClick={() => copySignature()} size={'md'}>
                  Copy To Clipboard
                </Button>
              </div>
            )}
          </>
        </div>
        <div className={styles.templatePreview}>
          {auth.user && (
            <iframe
              title="template-previewer"
              id="sig-iframe"
              style={{ height: '100vh', width: '100%' }}
              frameBorder="0"
              srcDoc={signatureHtml}
            />
          )}
        </div>
      </div>
      <LoadingIndicator loading={loading} successMsg={'Copied to clipboard'} />
    </MainLayout>
  );
};

export default observer(SignatureCreator);
