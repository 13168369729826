import { action, computed, observable } from 'mobx';
import MissionPaymentCycleObject, {
  BasicMissionPaymentCycleAdminObject,
  BasicMissionPaymentCycleObject,
  MissionPaymentCycleAdminObject,
  MissionPaymentCycleAdminReportObject,
  MissionPaymentCycleStatus,
} from '@a_team/models/dist/MissionPaymentCycleObject';
import type { MissionPaymentCycleId } from '@a_team/models/dist/MissionPaymentCycleObject';
import TimesheetObject, {
  TimesheetStatus,
} from '@a_team/models/dist/TimesheetObject';
import {
  differenceInDays,
  eachDayOfInterval,
  format,
  isPast,
  isWithinInterval,
  subDays,
} from 'date-fns';
import MissionRole from '@a_team/models/dist/MissionRole';
import {
  BasicInvoiceObject,
  InvoiceStatus,
} from '@a_team/models/dist/InvoiceObject';
import {
  formatMinutesToTime,
  getLocalTime,
  stringifyDate,
  stringifyMinutes,
} from '@src/helpers/time';
import { numberWithCommas } from '@src/helpers/numbers';
import { MissionId } from '@a_team/models/dist/MissionObject';

export interface MissionPaymentCycleStoreData {
  data:
    | MissionPaymentCycleObject
    | MissionPaymentCycleAdminObject
    | BasicMissionPaymentCycleObject
    | BasicMissionPaymentCycleAdminObject
    | MissionPaymentCycleAdminReportObject;
  currentUserRole?: MissionRole;
}

export enum PaymentCycleDisplayStatus {
  Active = 'Active',
  Submit = 'Submit',
  Submitted = 'Submitted',
  Invoiced = 'Invoiced',
  Paid = 'Paid',
}

export default class MissionPaymentCycle
  implements MissionPaymentCycleStoreData
{
  @observable public data: MissionPaymentCycleStoreData['data'];
  @observable currentUserRole: MissionPaymentCycleStoreData['currentUserRole'];

  public constructor(
    paymentCycleData:
      | MissionPaymentCycleObject
      | MissionPaymentCycleAdminObject
      | BasicMissionPaymentCycleObject
      | BasicMissionPaymentCycleAdminObject,
    currentUserRole?: MissionRole,
  ) {
    this.data = paymentCycleData;
    if (currentUserRole) {
      this.currentUserRole = currentUserRole;
    }
  }

  @action public syncData = (
    paymentCycleData: MissionPaymentCycleAdminObject,
  ): void => {
    this.data = {
      ...this.data,
      ...paymentCycleData,
    };
  };

  serialize = (): MissionPaymentCycleStoreData => {
    return {
      data: this.data,
      currentUserRole: this.currentUserRole,
    };
  };

  @computed get mid(): MissionId | undefined {
    return 'mid' in this.data ? this.data.mid : undefined;
  }

  @computed get missionTitle(): MissionId | undefined {
    return 'title' in this.data ? this.data.title : undefined;
  }

  @computed get timesheets(): TimesheetObject[] | undefined {
    return 'timesheets' in this.data ? this.data?.timesheets : undefined;
  }

  @computed get invoice(): BasicInvoiceObject | undefined {
    return 'invoice' in this.data ? this.data.invoice : undefined;
  }

  @computed get submittedTimesheets(): TimesheetObject[] {
    return (
      this.timesheets?.filter(
        (timesheet) => timesheet.status === TimesheetStatus.Submitted,
      ) || []
    );
  }

  @computed get allTimesheetsSubmitted(): boolean {
    return this.timesheets?.length === this.submittedTimesheets.length;
  }

  @computed get currentUserTimesheet(): TimesheetObject | undefined {
    return this.timesheets?.find(
      (sheet) => sheet.rid === this.currentUserRole?.rid,
    );
  }

  @computed get yid(): MissionPaymentCycleId {
    return this.data.yid;
  }

  @computed get status(): MissionPaymentCycleStatus {
    return this.data.status;
  }

  @computed get submitted(): boolean | null {
    return (
      this.data.summary &&
      'submitted' in this.data.summary &&
      this.data.summary.submitted
    );
  }

  @computed get formattedStartDate(): string {
    return format(getLocalTime(new Date(this.data.startDate)), 'LLL d');
  }

  @computed get formattedStartDateFull(): string {
    return format(getLocalTime(new Date(this.data.startDate)), 'PP');
  }

  @computed get formattedEndDate(): string {
    return format(getLocalTime(new Date(this.data.endDate)), 'LLL d');
  }

  @computed get formattedEndDateFull(): string {
    return format(getLocalTime(new Date(this.data.endDate)), 'PP');
  }

  @computed get formattedYear(): string {
    return format(getLocalTime(new Date(this.data.endDate)), 'yyyy');
  }

  @computed get formattedTotalHours(): string {
    return this.data.summary
      ? stringifyMinutes(this.data.summary.totalMinutes)
      : '0h';
  }

  // formated hours in hh:mm
  @computed get formattedTotalHoursToHHMM(): string {
    return this.data.summary
      ? formatMinutesToTime(this.data.summary.totalMinutes)
      : '00:00h';
  }

  @computed get formattedPeriod(): string {
    const startDateFormatted = stringifyDate(this.data.startDate, true);
    const endDateFormatted = stringifyDate(this.data.endDate, false);

    return `${startDateFormatted} - ${endDateFormatted}`;
  }

  @computed get formattedTotalPayments(): string {
    return `$${
      this.data?.summary?.totalPayments
        ? numberWithCommas(Math.floor(this.data?.summary?.totalPayments))
        : 0
    }`;
  }

  @computed get passedEndDate(): boolean {
    const dayBeforeCycleEnds = subDays(new Date(this.data.endDate), 1);
    return isPast(dayBeforeCycleEnds);
  }

  @computed get progress(): number {
    if (this.displayStatus !== PaymentCycleDisplayStatus.Active) {
      return 0;
    }

    const inRange = isWithinInterval(new Date(), {
      start: new Date(this.data.startDate),
      end: new Date(this.data.endDate),
    });

    if (!inRange) return 0;

    const dates = eachDayOfInterval({
      start: new Date(this.data.startDate),
      end: new Date(this.data.endDate),
    });

    const daysLeft = differenceInDays(new Date(this.data.endDate), new Date());
    const finished = dates.length - daysLeft;
    return (finished * 100) / dates.length;
  }

  @computed get displayStatus(): PaymentCycleDisplayStatus {
    switch (this.status) {
      case MissionPaymentCycleStatus.Open:
        if (this.submitted) {
          return PaymentCycleDisplayStatus.Submitted;
        } else {
          return this.passedEndDate
            ? PaymentCycleDisplayStatus.Submit
            : PaymentCycleDisplayStatus.Active;
        }
      case MissionPaymentCycleStatus.Closed:
        if (this.data.invoiceStatus === InvoiceStatus.Paid) {
          return PaymentCycleDisplayStatus.Paid;
        } else if (
          this.data.invoiceStatus &&
          this.data.invoiceStatus !== InvoiceStatus.Canceled
        ) {
          return PaymentCycleDisplayStatus.Invoiced;
        } else {
          return PaymentCycleDisplayStatus.Submitted;
        }
    }
    return PaymentCycleDisplayStatus.Active;
  }

  @computed get paymentNotificationShown(): boolean {
    return (
      this.status !== MissionPaymentCycleStatus.Closed &&
      this.passedEndDate &&
      !this.submitted
    );
  }

  @computed get closeNotificationShown(): boolean {
    return (
      this.passedEndDate && this.data.status === MissionPaymentCycleStatus.Open
    );
  }

  @computed get invoiceNotificationShown(): boolean {
    return (
      this.passedEndDate &&
      this.data.status === MissionPaymentCycleStatus.Closed &&
      !('invoice' in this.data)
    );
  }

  @action updateSummary = (minutes: number): void => {
    if (this.data.summary) {
      this.data.summary.totalMinutes += minutes;

      if (this.currentUserRole?.hourlyRate) {
        this.data.summary.totalPayments =
          Math.round(
            (this.data.summary.totalMinutes / 60) *
              this.currentUserRole.hourlyRate *
              100,
          ) / 100;
      }
    }
  };
}
