import React, { ReactElement, useMemo } from 'react';
import Modal, { ModalBasicProps } from '..';
import SectionHeading from '@src/components/SectionHeading';
import { Button as CallToActionButton } from '@ateams/components';
import { format } from 'date-fns';

interface Props extends ModalBasicProps {
  startDate: Date;
  endDate: Date;
  onSubmit: () => void;
  title?: string;
  text?: string;
}

export default function AdminConfirmTimesheetModal(props: Props): ReactElement {
  const { startDate, endDate, onSubmit, onClose, title, text, ...left } = props;

  const formattedStartDate = useMemo(
    () => format(startDate, 'LLL d'),
    [startDate],
  );

  const formattedEndDate = useMemo(() => format(endDate, 'LLL d'), [endDate]);

  return (
    <Modal onClose={onClose} {...left}>
      <SectionHeading style={{ marginTop: 0 }}>
        {title || 'It’s Time to Get Your Payment!'}
      </SectionHeading>
      <p>
        {text ||
          `Are you ready to submit your timesheet, and get your payment for the period (${formattedStartDate}-${formattedEndDate})?`}
      </p>
      <div style={{ display: 'flex', marginTop: 32 }}>
        <CallToActionButton
          color="regular"
          onClick={onClose}
          style={{ margin: '0 8px', color: '#fff' }}
        >
          Not yet
        </CallToActionButton>
        <CallToActionButton
          onClick={() => {
            onSubmit();
            onClose();
          }}
          style={{ margin: '0 8px' }}
        >
          Submit Now
        </CallToActionButton>
      </div>
    </Modal>
  );
}
