import React, { ChangeEvent, ReactElement, ReactNode, useMemo } from 'react';
import {
  ApplicantStatus,
  ExclusiveStatus,
  MissionApplicationInternalStatus,
  MissionApplicationInternalStatusLabels,
  MissionApplicationLowCompetitivenessReason,
  MissionApplicationLowCompetitivenessReasonLabels,
  MissionApplicationRejectionReason,
  MissionApplicationRejectionReasonLabels,
  MissionApplicationStatus,
  ProposedMethod,
} from '@a_team/models/dist/MissionApplicationObject';
import { Checkbox, Select, SelectOption, Tag } from '@ateams/components';
import { MissionApplicationStatusUpdate } from '@ateams/api/dist/endpoints/Missions';

interface Props {
  status: MissionApplicationStatus;
  statusData: ApplicantStatus;
  onChange: (statusData: MissionApplicationStatusUpdate) => void;
  disabled: boolean;
  children?: ReactNode;
}

export const OldStatusControls = (props: Props): ReactElement => {
  const { statusData, onChange, disabled, children } = props;
  const {
    internalStatus,
    exclusiveStatus,
    proposedToClient,
    lowCompetitiveness,
    rejectionReason,
  } = statusData;

  const statusOptions: SelectOption[] = Object.values(
    MissionApplicationInternalStatus,
  )
    .filter(
      (stat) =>
        stat !== MissionApplicationInternalStatus.New &&
        stat !== MissionApplicationInternalStatus.ProbablyNot &&
        stat !== MissionApplicationInternalStatus.Rejected,
    )
    .map((stat) => {
      return {
        label: MissionApplicationInternalStatusLabels[stat],
        value: stat,
      };
    });

  const lowCompetitivenessOptions: SelectOption[] = Object.values(
    MissionApplicationLowCompetitivenessReason,
  ).map((reason) => {
    return {
      label: MissionApplicationLowCompetitivenessReasonLabels[reason],
      value: reason,
    };
  });

  const rejectionReasonOptions: SelectOption[] = Object.values(
    MissionApplicationRejectionReason,
  ).map((reason) => {
    return {
      label: MissionApplicationRejectionReasonLabels[reason],
      value: reason,
    };
  });

  const selectedInternalStatus = useMemo(() => {
    return {
      value: internalStatus,
      label:
        MissionApplicationInternalStatusLabels[
          internalStatus as MissionApplicationInternalStatus
        ],
    };
  }, [internalStatus]);

  const selectedLowCompetitiveness = useMemo(() => {
    if (!lowCompetitiveness) return [];
    return lowCompetitiveness.map((reason) => {
      return {
        value: reason,
        label:
          MissionApplicationLowCompetitivenessReasonLabels[
            reason as MissionApplicationLowCompetitivenessReason
          ],
      };
    });
  }, [lowCompetitiveness]);

  const selectedRejectionReason = useMemo(() => {
    if (!rejectionReason) return [];
    return rejectionReason.map((reason) => {
      return {
        value: reason,
        label:
          MissionApplicationRejectionReasonLabels[
            reason as MissionApplicationRejectionReason
          ],
      };
    });
  }, [rejectionReason]);

  return (
    <div style={{ padding: '0 40px 40px 40px' }}>
      <Checkbox
        label={'Proposed to Client'}
        checked={!!proposedToClient}
        disabled={disabled || proposedToClient === ProposedMethod.Proposal}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange({
            proposed: e.target.checked,
          })
        }
      />
      <Checkbox
        label={
          <span>
            Exclusive{' '}
            {exclusiveStatus === ExclusiveStatus.OnHold && (
              <Tag color={'danger'} thin style={{ marginLeft: 8 }}>
                On hold
              </Tag>
            )}
          </span>
        }
        checked={exclusiveStatus === ExclusiveStatus.Exclusive}
        disabled={disabled || exclusiveStatus === ExclusiveStatus.OnHold}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange({
            exclusive: e.target.checked,
          })
        }
      />
      <Select
        isDisabled={disabled}
        label="Internal Status"
        value={
          internalStatus === MissionApplicationInternalStatus.New
            ? undefined
            : selectedInternalStatus
        }
        hideSelectedOptions={false}
        placeholder={'Select internal status'}
        onChange={(e) =>
          onChange({
            internalStatus: e
              ? (e.value as MissionApplicationInternalStatus)
              : internalStatus,
          })
        }
        options={statusOptions}
      />
      <Select
        isMulti={true as false}
        hideTags
        itemCountPrefix={'Low competitiveness reason'}
        showItemCount
        isDisabled={disabled}
        isSearchable
        label="Low Competitiveness"
        placeholder={''}
        value={selectedLowCompetitiveness}
        hideSelectedOptions={false}
        onChange={(e: SelectOption[] | SelectOption | null) =>
          onChange({
            lowCompetitiveness: (e as SelectOption[]).map(
              (option: SelectOption) =>
                option.value as MissionApplicationLowCompetitivenessReason,
            ),
          })
        }
        options={lowCompetitivenessOptions}
      />
      <Select
        isMulti={true as false}
        hideTags
        itemCountPrefix={'Rejection reason'}
        showItemCount
        isDisabled={disabled}
        isSearchable
        label="Rejection Reason"
        placeholder={''}
        value={selectedRejectionReason}
        hideSelectedOptions={false}
        onChange={(e: SelectOption[] | SelectOption | null) =>
          onChange({
            internalStatus:
              (e as SelectOption[]).length > 0
                ? MissionApplicationInternalStatus.Rejected
                : undefined,
            rejectionReason: (e as SelectOption[]).map(
              (option: SelectOption) =>
                option.value as MissionApplicationRejectionReason,
            ),
          })
        }
        options={rejectionReasonOptions}
      />
      {children}
    </div>
  );
};
