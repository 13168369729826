import { DateInterval, DateISOString, TrafficLightColor } from './misc';
import { MissionId } from './MissionObject';
import { SurveyQuestionMap, SurveyResponseMap, SurveyType } from './Survey';
import { BasicUserObject, UserId } from './UserObject';

/** Indicator of the team health */
export type TeamBarometerScore = TrafficLightColor;

export type TeamPulseId = string;

export interface SharedSurveyResponse {
  firstName: string;
  lastName: string;
  pictureURL: string;
  whatYouLikedWhatCouldBeImproved: string;
}

/** A team pulse is a set of surveys for a particular mission tagged by a date */
export interface TeamPulse {
  teamPulseId: TeamPulseId;
  missionId: MissionId;
  coveringInterval: DateInterval;
  teamBarometerScore: TeamBarometerScore | null;
  averageRatingClient: number | null;
  averageRatingBuilder: number | null;
  sharedResponses?: SharedSurveyResponse[] | null;
}

export type TeamPulseSurveyId = string;
export type TeamPulseDateInterval = {
  start: DateISOString | null;
  end: DateISOString | null;
};

/** A particular `TeamPulse` survey for a particular user */
export interface TeamPulseSurvey<T extends SurveyType<keyof T> = SurveyType> {
  teamPulseSurveyId: TeamPulseSurveyId;
  teamPulseId: TeamPulseId;
  type?: TeamPulseSurveyType;
  userId: UserId;
  missionId: MissionId;
  questions: SurveyQuestionMap<T>;
  hiddenUsersIds?: UserId[] | null;
  response: SurveyResponseMap<T> | null;
  responseDateInterval: TeamPulseDateInterval;
  responseDate?: DateISOString | null;
}

export interface TeamPulseSurveyWithUser<
  T extends SurveyType<keyof T> = SurveyType,
> extends TeamPulseSurvey<T> {
  user: BasicUserObject;
}

/** Identifier of a team pulse survey type */
export enum TeamPulseSurveyType {
  Simple = 'simple',
  Detailed = 'detailed',
}

export interface TeamPulseDashboardItem {
  missionId: MissionId;
  missionTitle: string;
  teamBarometerScore: TeamBarometerScore | null;
  averageRatingClient: number | null;
  averageRatingBuilder: number | null;
  intervals: TeamPulse[];
}

export const teamPulseSurveyResponseDateIntervalHasStarted = (
  teamPulseSurvey: TeamPulseSurvey,
  date: Date = new Date(),
): boolean =>
  !teamPulseSurvey.responseDateInterval.start ||
  date > teamPulseSurvey.responseDateInterval.start;

export const teamPulseSurveyResponseDateIntervalHasEnded = (
  teamPulseSurvey: TeamPulseSurvey,
  date: Date = new Date(),
): boolean =>
  teamPulseSurvey.responseDateInterval.end
    ? date > teamPulseSurvey.responseDateInterval.end
    : false;

export const teamPulseSurveyIsOpen = (
  teamPulseSurvey: TeamPulseSurvey,
  date: Date = new Date(),
): boolean =>
  teamPulseSurveyResponseDateIntervalHasStarted(teamPulseSurvey, date) &&
  !teamPulseSurveyResponseDateIntervalHasEnded(teamPulseSurvey, date);
