import { VettingProcessEmailTemplates } from '@a_team/models/dist/vetting-processes/emails';
import { EmailTemplateGenerator } from '..';

export const createSelectionTeamInitialEmailTemplate: EmailTemplateGenerator = (
  newEvaluation,
) => {
  if (newEvaluation) {
    return {
      subject: '{{builderFirstName}}, schedule your A.Team evaluation call!',
      body: `<p>Hi {{builderFirstName}},</p>
<p>{{contactOwnerFirstName}} from A.Team here. I’m excited to invite you to an <a href="https://guide.a.team/getting-started/evaluation-call">evaluation call</a> — a mandatory next step before we can consider you for missions.</p>
<p>The <a href="https://guide.a.team/getting-started/evaluation-call">evaluation call</a> is your chance to tell us about all the great things we can use to pitch you for missions, and it's our opportunity to confirm that you're a good fit for our network and the top-tier companies we partner with.</p>
<p>Best to come prepared with:</p>
<ul>
  <li>1-2 minute "elevator pitch" about your skills, experience, and achievements</li>
  <li>2-3 project examples - focus on projects you had a significant contribution to and be ready to answer questions about them</li>
  <li>Your portfolio and case studies (for designers) OR sample code (for developers), if provided</li>
  <li>Be ready to walk the interviewer through your thought process and success metrics</li>
  <li>A stable internet connection, as well as high-quality audio and video setup</li>
</ul>
<div>{{requiredCallParticipationNote}}</div>
<h3><b>Schedule your evaluation call <a href="{{preVettingFormUrl}}">here</a>!</b></h3>
<p>Looking forward to get to know you!</p>
<p>Best,<br>
{{contactOwnerFirstName}}</p>
<p>P.S. We’ll be recording this call for quality control and internal learning purposes.</p>
<p>P.P.S. Unless you encounter any issues or have questions, there’s no need to reply to this email. We’ve got you covered.</p>
`,
      templateType: VettingProcessEmailTemplates.STInitial,
      sendInThread: false,
    };
  } else {
    return {
      subject:
        '{{builderFirstName}}, it’s time for your A.Team evaluation call',
      body: `<p>Hi {{builderFirstName}},</p>
<p>{{contactOwnerFirstName}} here from A.Team’s Selection Team. I’m excited to invite you to an <a href="https://guide.a.team/getting-started/evaluation-call">evaluation call</a> — a mandatory next step before we can consider you for missions.</p>
<p>The <a href="https://guide.a.team/getting-started/evaluation-call">evaluation call</a> is your chance to tell us about all the great things we can use to pitch you for missions, and it's our opportunity to confirm that you're a good fit for our network and the top-tier companies we partner with.</p>
<p>Come prepared with:</p>
<ul>
<li>1-2 minute "elevator pitch" about your skills, experience, and achievements</li>
<li>2-3 project examples - focus on projects you had a significant contribution to and be ready to answer questions about them</li>
<li>Your portfolio and case studies (for designers) OR sample code (for developers), if provided </li>
<li>Be ready to walk the interviewer through your thought process and success metrics </li>
</ul>
<p><b>To prepare for the call, please submit <a href="{{preVettingFormUrl}}">this short form</a>. Then I’ll reach out to schedule a call with you. </b></p>
<div>{{requiredCallParticipationNote}}</div>
<p>Looking forward to get to know you!</p>
<p>Best,<br>
{{contactOwnerFirstName}}</p>`,
      templateType: VettingProcessEmailTemplates.STInitial,
      sendInThread: false,
    };
  }
};

export const createSelectionTeamFirstFollowupTemplate: EmailTemplateGenerator =
  (newEvaluation) => {
    if (newEvaluation) {
      return {
        subject: 'Reminder! Schedule your A.Team evaluation call',
        body: `<p>Hi {{builderFirstName}},</p>
<p>{{contactOwnerFirstName}} from A.Team again. I sent you an email about scheduling your <a href="{{preVettingFormUrl}}">evaluation call</a> to assess your skills and ensure they’re a fit for our open missions.</p>
<h3><span>If you’re still interested, </span><a href="{{preVettingFormUrl}}">schedule a call with us!</a></h3>
<div>{{requiredCallParticipationWarning}}</div>
<p>I’m looking forward to talking soon!<br>
{{contactOwnerFirstName}}</p>`,
        templateType: VettingProcessEmailTemplates.STFollowUp,
        sendInThread: true,
      };
    } else {
      return {
        subject:
          '{{builderFirstName}}, it’s time for your A.Team evaluation call',
        body: `<p>Hi {{builderFirstName}},</p>
<p>{{contactOwnerFirstName}} here from A.Team’s Selection Team. I’m looking forward to talking soon! I sent you an email a few days ago about scheduling your <a href="https://guide.a.team/getting-started/evaluation-call">evaluation call</a> — to assess your skills and ensure they’re a fit for our open missions.</p>
<p><b>If you’re still interested, fill in <a href="{{preVettingFormUrl}}">this short form</a> and we’ll reach out to schedule a call.</b></p>
<div>{{requiredCallParticipationWarning}}</div>
<p>Best,<br>
{{contactOwnerFirstName}}</p>`,
        templateType: VettingProcessEmailTemplates.STFollowUp,
        sendInThread: true,
      };
    }
  };

export const createSelectionTeamSecondFollowupTemplate: EmailTemplateGenerator =
  (newEvaluation) => {
    if (newEvaluation) {
      return {
        subject: 'Don’t forget to schedule your A.Team evaluation call!',
        body: `<p>Hi {{builderFirstName}},</p>
<p>Making sure you saw my previous emails about setting up a time to talk to our team.</p>
<h3>If you want to get selected for missions, <a href="{{preVettingFormUrl}}">schedule a call with us!</a></h3>
<div>{{requiredCallParticipationWarning}}</div>
<p>I’m really looking forward to talking to you about your skills and experience.</p>
<p>Hope to meet you soon,<br>
{{contactOwnerFirstName}}</p>`,
        templateType: VettingProcessEmailTemplates.STSecondFollowUp,
        sendInThread: false,
      };
    } else {
      return {
        subject: 'Schedule your A.Team evaluation call',
        body: `<p>Hi {{builderFirstName}},</p>
  <p>Hey, it’s {{contactOwnerFirstName}} again from A.Team’s Selection Team. I’m really looking forward to talking to you about your skills and experience.</p>
  <p>If you want to get selected for missions, take five minutes to fill out <a href="{{preVettingFormUrl}}">this form</a> so we can schedule your evaluation call.</p>
  <div>{{requiredCallParticipationWarning}}</div>
  <p>Hope to meet you soon,<br>
  {{contactOwnerFirstName}}</p>`,
        templateType: VettingProcessEmailTemplates.STSecondFollowUp,
        sendInThread: false,
      };
    }
  };

export const createSelectionTeamScheduleCallTemplate: EmailTemplateGenerator =
  () => {
    return {
      subject: 'Scheduling your evaluation call',
      body: `<p>Hi {{builderFirstName}},</p>
<p>Thanks for filling in the form.</p>
<p>We sent you an invite to an <a href="https://guide.a.team/getting-started/evaluation-call">evaluation call</a> on {{interviewDate}}.</p>
<p>A few things to note before we chat:</p>
<ul>
<li>The evaluation call is your chance to tell us all about your technical skills, past experiences, major milestones and achievements — so come with 2-3 strong examples in mind. These details will help us pitch you better for the missions you want to join.</li>
<li>Please note, this call is also our opportunity to confirm that you're a good fit for our network and the top-tier companies we partner with. </li>
<li>If something comes up and you can’t make it, let us know in advance and we will reschedule.</li>
<li>Please check in advance that you have a stable internet connection and high-quality audio and video setup.</li>
</ul>
<div>{{requiredCallParticipationNote}}</div>
<p>Looking forward to meeting you,<br>
{{contactOwnerFirstName}}</p>
<p>P.S. We’ll be recording this call for internal learning purposes. If you don’t want to be recorded, let us know by replying to this email.</p>`,
      templateType: VettingProcessEmailTemplates.STScheduleCall,
      sendInThread: false,
    };
  };
