import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { Card } from '@ateams/components';
import { CompanyItem } from '@src/components/CompanyPicker/CompanyItem';
import { Company, CompanyId } from '@a_team/models/dist/Company';
import { createUseStyles } from 'react-jss';
import useDebounceState from '@src/hooks/useDebounceState';
import CompanyAvatar from '@src/components/CompanyAvatar/CompanyAvatar';
import { Input, Icon, theme } from '@a_team/ui-components';
import {
  useMutationCreateCompany,
  useQueryAutoCompleteCompany,
  useQueriesGetCompanies,
} from '@src/rq/companies';
import companyDefaultLogo from '@src/components/CompanyAvatar/avatar.svg';

const useStyles = createUseStyles({
  container: {
    position: 'relative',
  },
  companyAvatarsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  companyAvatar: {
    display: 'inline-flex',
    alignItems: 'center',
    margin: 3,
  },
  menu: {
    padding: 0,
    overflowY: 'scroll',
    margin: 0,
    display: 'block',
    width: '100%',
    maxHeight: 280,
  },
  dropdown: {
    position: 'absolute',
    minWidth: 300,
    left: 0,
    zIndex: 100,
  },
  companyPickerInput: {
    '&:focus': {
      borderColor: '#62646A !important',
      backgroundColor: '#FFF !important',
    },
  },
});

interface Props {
  onSelectCompanies(company: Company[]): void;

  canCreateNewCompanies?: boolean;
  isMulti?: boolean;
  required?: boolean;
  tabIndex?: number;
  error?: string;
  autoFocus?: boolean;
  initiallySelectedCompanyIds?: CompanyId[];
  className?: string;
  disabled?: boolean;
  inputTestingId?: string;
  useEmployeesCollection?: boolean;
  placeholder?: string;
  withCompanyIcon?: boolean;
}

export const CompanyPicker = ({
  canCreateNewCompanies,
  onSelectCompanies,
  autoFocus,
  required,
  tabIndex,
  error,
  initiallySelectedCompanyIds = [],
  className,
  isMulti,
  inputTestingId,
  disabled,
  useEmployeesCollection,
  placeholder,
  withCompanyIcon,
}: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([]);
  const [fetchingInitialValues, setFetchingInitialValues] = useState(true);
  const styles = useStyles();
  const inputRef = useRef(null as HTMLInputElement | null);
  const menuRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const [searchQuery, setSearchQuery] = useDebounceState<string | null>(
    '',
    (query) => setSearchTerm(query),
    400,
  );

  const {
    isLoading,
    error: errorQuery,
    data,
  } = useQueryAutoCompleteCompany({
    searchTerm: searchTerm ? searchTerm : '',
    onSuccess: () => {
      setIsOpen(true);
    },
    canCreateNewCompanies,
    useEmployeesCollection,
  });

  useEffect(() => {
    if (
      fetchingInitialValues &&
      selectedCompanies.length === initiallySelectedCompanyIds.length
    ) {
      setFetchingInitialValues(false);
    }
    if (!fetchingInitialValues) {
      onSelectCompanies(selectedCompanies);
    }
  }, [selectedCompanies, fetchingInitialValues]);

  useQueriesGetCompanies({
    enabled: fetchingInitialValues,
    companyIds: initiallySelectedCompanyIds,
    onSuccess: (data) => {
      setSelectedCompanies((current) => [...(current || []), data]);
    },
  });

  const createCompany = useMutationCreateCompany();

  const onSelectNewCompany = (name: string) => {
    createCompany.mutate(name, {
      onSuccess: (data) => {
        setSelectedCompanies((current) => [...(current || []), data]);
      },
    });
  };

  const onSelectSearchResult = (company: Company) => {
    setIsOpen(false);
    if (company.id === NewCompanyId && canCreateNewCompanies) {
      onSelectNewCompany(company.name);
    } else {
      setSelectedCompanies([...selectedCompanies, company]);
    }
  };

  const onChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setSearchQuery(e.target.value);
    if (!isMulti) {
      setSelectedCompanies([]);
    }
    if (e.target.value === '') {
      setIsOpen(false);
    }
  };

  const onCompanyDelete = (index: number) => {
    setSelectedCompanies([
      ...selectedCompanies.slice(0, index),
      ...selectedCompanies.slice(index + 1),
    ]);
  };

  useEffect(() => {
    inputRef.current?.blur();
  }, [selectedCompanies.length]);

  if (fetchingInitialValues) {
    return <Icon name="loading" size="lg" />;
  }

  return (
    <div className={styles.container}>
      <span className={styles.companyAvatarsContainer}>
        {selectedCompanies.map((company, index) => (
          <span key={company.id} className={styles.companyAvatar}>
            <CompanyAvatar
              src={company.logoUrl}
              label={isMulti ? company.name : undefined}
              key={index}
            />
            {isMulti && (
              <Icon
                style={{ marginLeft: 3, marginBottom: 6 }}
                name={'remove'}
                size={'sm'}
                onClick={() => onCompanyDelete(index)}
              />
            )}
          </span>
        ))}
      </span>

      <Input
        className={cx(styles.companyPickerInput, className)}
        disabled={disabled}
        ref={inputRef}
        type="text"
        size="stretch"
        placeholder={placeholder ?? 'Search for a company...'}
        name="team"
        onChange={onChange}
        value={
          isMulti
            ? searchQuery ?? ''
            : selectedCompanies[0]?.name ?? searchQuery ?? ''
        }
        required={required}
        autoFocus={autoFocus}
        icon={
          withCompanyIcon && selectedCompanies[0] ? (
            <img
              alt="company-icon"
              src={selectedCompanies[0]?.logoUrl || companyDefaultLogo}
              style={{
                maxWidth: 24,
                maxHeight: 24,
                width: 24,
                height: 24,
                position: 'absolute',
                top: 7,
                left: 10,
              }}
            />
          ) : isLoading ? (
            <Icon name={'loading'} size={'md'} />
          ) : selectedCompanies.length && !isMulti ? undefined : (
            <Icon name={'search'} size={'md'} />
          )
        }
        style={{
          borderColor: theme.colors.Grey['400'],
          paddingLeft:
            withCompanyIcon && selectedCompanies[0]
              ? '45px !important'
              : withCompanyIcon
              ? '45px !important'
              : 16,
        }}
        tabIndex={tabIndex}
        error={
          (error !== undefined && error !== '') || errorQuery ? true : undefined
        }
        errorText={error}
        data-testing-id={inputTestingId}
      />
      {isOpen && (
        <div className={styles.dropdown}>
          <Card className={styles.menu}>
            <div ref={menuRef}>
              {data?.items.map((company) => {
                return (
                  <CompanyItem
                    company={company}
                    onSelectCompany={onSelectSearchResult}
                    key={company.id}
                  />
                );
              })}
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export const NewCompanyId = 'new-company';
