import {
  useRejectProfileSuggestion,
  useGetProfileSuggestions,
  useApplySuggestedRole,
  useGetAdminProfileSuggestions,
} from '@src/rq/profileSuggestions';
import Profile from '@src/stores/Profile/Profile';
import React, { useState } from 'react';
import Suggestions from '../Suggestions';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { UserId } from '@a_team/models/dist/UserObject';
import {
  BuilderSuggestionType,
  SuggestionActionType,
} from '@a_team/models/dist/ProfileEnhancements';
import { ProfileViewMode } from '@src/stores/Profile/models';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    marginBottom: 24,
  },
});

interface SuggestedRolesProps {
  profile: Profile;
  userId: UserId;
  adminOnly?: boolean;
}
const SuggestedRoles = ({
  profile,
  userId,
  adminOnly,
}: SuggestedRolesProps) => {
  const [rolesModalOpen, setRolesModalOpen] = useState(false);
  const { data } = useGetProfileSuggestions();
  const { data: adminData } = useGetAdminProfileSuggestions();
  const { mutateAsync: applySuggestedRole } = useApplySuggestedRole();
  const { mutateAsync: rejectSuggestion } = useRejectProfileSuggestion();
  const analytics = useAnalytics();
  const styles = useStyles();

  const toggleSkillsModalOpen = () => {
    setRolesModalOpen(!rolesModalOpen);
  };

  const rawSuggestions = adminOnly ? adminData : data;

  const suggestionId = rawSuggestions?.roles?.sid;

  const roles = rawSuggestions?.roles?.roles.map((role) => ({
    suggestionId,
    partialSuggestionId: role.id,
    label: role.name,
    type: BuilderSuggestionType.ROLES,
  }));

  const onDiscard = async () => {
    try {
      if (rawSuggestions?.roles?.sid) {
        await rejectSuggestion(rawSuggestions.roles.sid);
        analytics.trackProfileSuggestionInteraction(
          userId,
          rawSuggestions.roles.sid,
          SuggestionActionType.REJECT,
          BuilderSuggestionType.ROLES,
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAccept = async (roleId: string) => {
    try {
      if (rawSuggestions?.roles?.sid) {
        const role = rawSuggestions.roles.roles.find(
          (role) => role.id === roleId,
        );

        if (!role) {
          return;
        }

        await applySuggestedRole({ sid: rawSuggestions.roles.sid, roleId });

        analytics.trackProfileSuggestionInteraction(
          userId,
          rawSuggestions.roles.sid,
          SuggestionActionType.ACCEPT,
          BuilderSuggestionType.ROLES,
          [role.name],
        );
        profile.refreshProfile(ProfileViewMode.View);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.container}>
      <Suggestions
        onDiscard={onDiscard}
        onAccept={handleAccept}
        title={'Suggested roles'}
        onClick={toggleSkillsModalOpen}
        userId={userId}
        suggestions={roles ?? []}
        adminOnly={adminOnly}
      />
    </div>
  );
};

export default SuggestedRoles;
