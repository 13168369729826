import React, { ReactElement, useEffect, useState } from 'react';
import { Checkbox, SelectOption } from '@ateams/components';
import { useStores } from '@src/stores';
import {
  GenericTalent,
  RoleFilter,
} from '@src/components/TeamGraphBuilderQuery';
import MixedTalentList from './MixedTalentList';
import { TalentSkillRating } from '@a_team/models/dist/TalentCategories';
import {
  fetchSkillList,
  getSkillSelectOptions,
} from '@src/helpers/talent-skills-api';

interface Props {
  preferredSkills: string[];
  requiredSkills: string[];
  setFilterValue: (filter: Partial<RoleFilter>) => void;
  talentToString: (
    talents: GenericTalent[],
    appendRating?: boolean,
  ) => string[];
  sidebar?: boolean;
  excludeBuildersWithPerformanceIssue?: boolean;
  includeApplicationSkills?: boolean;
  requireVerifiedSkills?: boolean;
  includeSuggestedSkills?: boolean;
  requireProposedSkills?: boolean;
}

const MixedSkillList = (props: Props): ReactElement => {
  const { auth } = useStores();
  const {
    preferredSkills,
    requiredSkills,
    setFilterValue,
    sidebar,
    talentToString,
    excludeBuildersWithPerformanceIssue,
    includeApplicationSkills,
    requireVerifiedSkills,
    includeSuggestedSkills,
    requireProposedSkills,
  } = props;

  const searchSkills = async (searchTerm: string): Promise<SelectOption[]> => {
    return await getSkillSelectOptions({
      filter: {
        query: searchTerm,
      },
    });
  };

  // fetch skills on from backend based on IDs parsed from URL
  const [preferredSkillsValues, setPreferredSkillsValues] = useState<
    GenericTalent[]
  >([]);
  const [requiredSkillsValues, setRequiredSkillsValues] = useState<
    GenericTalent[]
  >([]);
  const [isLoadingTags, setIsLoadingTags] = useState(true);

  useEffect(() => {
    const allIds = requiredSkills
      .concat(preferredSkills)
      .map((e) => e?.toString()?.split('-')[0]);
    if (!allIds.length) {
      setRequiredSkillsValues([]);
      setPreferredSkillsValues([]);
      setIsLoadingTags(false);
      return;
    }
    fetchSkillList({
      filter: { id: allIds?.length > 0 ? allIds : null },
    }).then((skills) => {
      const resMap = skills?.reduce(
        (acc: Record<string, GenericTalent>, skill) => {
          acc[skill.id] = {
            id: skill.id,
            label: skill.name,
          };
          return acc;
        },
        {},
      );
      setPreferredSkillsValues(
        preferredSkills.map((skill) => resMap[skill]).filter((e) => e?.id),
      );
      setRequiredSkillsValues(
        requiredSkills
          .map((skill) => {
            const [id, rating] = skill?.split('-');
            return {
              ...resMap[id],
              rating: rating
                ? Number(rating ?? TalentSkillRating.None)
                : undefined,
            } as GenericTalent;
          })
          .filter((e) => e?.id),
      );
      setIsLoadingTags(false);
    });
  }, [preferredSkills.join(), requiredSkills.join(), auth]);

  const handleSkillsChange = (
    requiredSkills: GenericTalent[],
    preferredSkills: GenericTalent[],
  ) => {
    setFilterValue({
      requiredSkills: talentToString(requiredSkills, true),
      preferredSkills: talentToString(preferredSkills),
    });
  };

  return (
    <>
      <Checkbox
        onChange={(e) =>
          setFilterValue({
            excludeBuildersWithPerformanceIssue: e.target.checked,
          })
        }
        checked={excludeBuildersWithPerformanceIssue}
        label="Exclude builders with performance issue"
        margin="top"
      />
      <Checkbox
        onChange={(e) =>
          setFilterValue({
            requireVerifiedSkills: e.target.checked,
            includeApplicationSkills:
              e.target.checked && includeApplicationSkills,
          })
        }
        checked={requireVerifiedSkills}
        data-testing-id={'targeter-require-verified-skill'}
        label="Require A.Team verified skill"
        margin="top"
      />
      <Checkbox
        onChange={(e) =>
          setFilterValue({
            includeApplicationSkills: e.target.checked,
          })
        }
        checked={includeApplicationSkills}
        label="Include applied skills"
        disabled={requireVerifiedSkills}
        margin="none"
      />
      <Checkbox
        onChange={(e) =>
          setFilterValue({
            includeSuggestedSkills: e.target.checked || undefined,
          })
        }
        checked={includeSuggestedSkills}
        label="Include inferred skills"
        margin="top"
      />
      <Checkbox
        onChange={(e) =>
          setFilterValue({
            requireProposedSkills: e.target.checked || undefined,
          })
        }
        checked={requireProposedSkills}
        label="Require proposed"
        margin="top"
      />
      <MixedTalentList
        isLoadingTags={isLoadingTags}
        onChange={handleSkillsChange}
        search={searchSkills}
        preferred={preferredSkillsValues}
        preferredPlaceholder={'Preferred Skills'}
        required={requiredSkillsValues}
        requiredPlaceholder={'Required Skills'}
        requiredHasRatings
        sidebar={sidebar}
      />
    </>
  );
};

export default MixedSkillList;
