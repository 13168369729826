import React, { ReactElement, useMemo } from 'react';
import { observer } from 'mobx-react';
import MainLayout from '@src/layouts/Main';
import SearchInput from '@src/views/Discovery/components/SearchInput';
import { createUseStyles } from 'react-jss';
import { useStores } from '@src/stores';
import UserCardList from '@src/components/UserCardList';
import SectionHeading from '@src/components/SectionHeading';
import { searchResultsLoader } from '@src/hooks/useSearchResults';
import { useActiveConnections } from '@src/rq/discovery';
import { Breakpoints, LoadMoreLink } from '@ateams/components';

export const searchResultsViewLoader = searchResultsLoader;

const useStyles = createUseStyles({
  stickyContainer: {
    position: 'fixed',
    top: 40,
    padding: 0,
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#f7f7f7',
    transition: 'all 0.5s',
    zIndex: 10,
  },
  innerContent: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 150,
  },
  count: {
    color: '#62646A',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    stickyContainer: {
      top: 0,
      width: 'calc(100% - 120px)',
      padding: 24,
      paddingLeft: '56px',
      paddingRight: '56px',
    },
    innerContent: {
      paddingLeft: 55,
      paddingRight: 55,
      paddingTop: 125,
    },
  },
});

const MyNetworkView = (): ReactElement => {
  const styles = useStyles();
  const { auth } = useStores();
  const { data, hasNextPage, fetchNextPage } = useActiveConnections();

  const activeConnections = useMemo(
    () => data?.pages.flatMap(({ activeConnections }) => activeConnections),
    [data?.pages],
  );

  return (
    <MainLayout title="Search Results" style={{ padding: 0 }}>
      <div className={styles.stickyContainer}>
        <SearchInput
          withMenu={true}
          style={{ width: '100%', position: 'relative' }}
        />
      </div>
      <div className={styles.innerContent}>
        <SectionHeading style={{ marginTop: 0 }}>
          Network Connections
        </SectionHeading>
        <p>
          Here are all of your potential teammates — all in one place! Got a
          mission that needs a great front end developer? Search for one here.
        </p>
        <p className={styles.count}>
          {auth.user?.connectionsCount} Connections
        </p>
        <UserCardList users={activeConnections || []} minimal />
        {hasNextPage && <LoadMoreLink onLoadMore={fetchNextPage} />}
      </div>
    </MainLayout>
  );
};

export default observer(MyNetworkView);
