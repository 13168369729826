import { Icon, IconType } from '@ateams/components';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { useQueryGetTimesheetInitiativesForMission } from '@src/rq/timesheets';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import InitiativesGuidance from '../../common/InitiativesGuidance';

interface InitiativesTooltipProp {
  sid: string;
}

function InitiativesTooltip({ sid }: InitiativesTooltipProp) {
  const { auth } = useStores();

  const { data } = useQueryGetTimesheetInitiativesForMission({
    sid,
    enabled: !!sid,
  });

  const showInitiativesGuidanceTooltip = useMemo(() => {
    if (!data || !auth) {
      return false;
    }

    if (!auth.user?.needsTimesheetInitiativesGuidance) {
      return true;
    }

    const hasUserCreatedAnyInitiatives = data.some(
      (option) => option.createdBy === auth.user?.uid,
    );

    return hasUserCreatedAnyInitiatives;
  }, [auth.user?.uid, auth.user?.needsTimesheetInitiativesGuidance, data]);

  if (!showInitiativesGuidanceTooltip) {
    return null;
  }

  return (
    <TooltipWrapped
      interactive
      theme={'light'}
      html={<InitiativesGuidance />}
      position="bottom-start"
      animation="fade"
    >
      <Icon type={IconType.Info} size="small" title="" />
    </TooltipWrapped>
  );
}

export default observer(InitiativesTooltip);
