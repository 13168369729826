import { CDN_BASE_URL } from '@src/config';
import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface EndOfRecommendationsProps {
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    maxWidth: 460,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    textAlign: 'center',
    margin: '0 auto',
  },
  illustration: {
    width: 100,
    height: 'auto',
  },
  title: {
    fontWeight: 500,
  },
});

const EndOfRecommendations = ({
  className,
}: EndOfRecommendationsProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={cx(styles.container, className)}>
      <img
        className={styles.illustration}
        src={CDN_BASE_URL + '/website/discovery-end-page-illustration.png'}
        alt="Grow your network"
      />
      <div className={styles.title}>
        You’ve reached the end of the recommendation list.
      </div>
      <div>
        Connect with more builders you want to work with to expand your network
        and get more relevant recommendations.
      </div>
    </div>
  );
};

export default EndOfRecommendations;
