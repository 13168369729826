import { RoleWithStatus } from '@a_team/models/dist/TalentCategories';
import { Button, Modal } from '@a_team/ui-components';
import { BorderColors, Colors, TextColors } from '@ateams/components';
import HideScrollerGlobally from '@src/components/HideScrollerGlobally';
import React, { ReactElement, useState, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import Select, { components } from 'react-select';

interface Props {
  onClose(): void;
  open: boolean;
  rolesWithStatus: RoleWithStatus[];
  onSubmit(roleId: string): void;
  isLoading?: boolean;
}

interface OptionProps {
  label: string;
  value: string;
  isDisabled: boolean;
}

const useStyles = createUseStyles({
  container: {
    padding: '24px 24px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 10,
  },
  mainContent: {
    textAlign: 'center',
    color: TextColors.lighter,
    lineHeight: '1.5',
  },
  selectWrapper: {
    marginTop: 24,
    marginBottom: 40,
    minWidth: 500,
  },
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  closeBtn: {
    width: '80px !important',
    padding: '8px 12px !important',
    borderColor: `${Colors.bannerTop} !important`,
    backgroundColor: `${Colors.bannerTop} !important`,
  },
  ctaBtn: {
    width: '80px !important',
    padding: '8px 12px !important',
  },
  footer: {
    width: 'calc(100% + 32px)',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: -16,
    padding: '16px 16px 0',
    borderTop: `1px solid ${BorderColors.lighter}`,
  },
  notAvailableLabel: {
    marginLeft: 'auto',
    fontSize: 12,
    color: TextColors.lighter,
  },
});

// Custom option component for the Select dropdown
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomOption = ({ children, ...props }: any) => {
  const styles = useStyles();
  const { data } = props;

  return (
    <components.Option {...props}>
      <div className={styles.optionContainer}>
        {children}
        {data.isDisabled && (
          <span className={styles.notAvailableLabel}>Not available</span>
        )}
      </div>
    </components.Option>
  );
};

const SelectNonWaitlistedRoleModal = ({
  open,
  onClose,
  rolesWithStatus,
  onSubmit,
  isLoading,
}: Props): ReactElement => {
  const styles = useStyles();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<OptionProps | null>(null);

  const options = useMemo(
    () =>
      rolesWithStatus
        .filter((role) => role.name && role.name.trim() !== '')

        .map((role) => ({
          label: role.name,
          value: role.roleId,
          isDisabled: role.markedAsWaitlisted,
        }))
        .sort(
          (a, b) => (a.isDisabled ? 1 : 0) - (b.isDisabled ? 1 : 0),
        ) as OptionProps[],
    [rolesWithStatus],
  );

  const handleRoleChange = (selectedOption: OptionProps | null) => {
    setSelectedRole(selectedOption);
  };

  const handleNextClick = () => {
    if (selectedRole) {
      onSubmit(selectedRole.value);
    }
  };

  return (
    <Modal
      variant="slideUp"
      isOpen={open}
      onClose={onClose}
      shouldHideGradientStroke={true}
      hideCloseButton
      styles={{
        width: '680px !important',
        borderRadius: '8px !important',
        padding: '0 !important',
      }}
      zIndex={9999}
    >
      <div className={styles.container}>
        <div className={styles.title}>
          Your primary role is waitlisted, please select another
        </div>
        <div className={styles.mainContent}>
          We’re prioritizing evaluations for roles with more client requests,
          and your primary role currently has fewer missions available. You can
          request an evaluation for one of your other roles or wait until we
          resume evaluations for your primary role.
        </div>
        <div className={styles.selectWrapper}>
          <Select
            placeholder="Select role..."
            options={options}
            value={selectedRole}
            components={{
              Option: CustomOption,
            }}
            onChange={(value) => handleRoleChange(value)}
            onMenuOpen={() => setIsSelectOpen(true)}
            onMenuClose={() => setIsSelectOpen(false)}
            menuIsOpen={isSelectOpen}
            styles={{
              container: (provided) => ({
                ...provided,
                maxWidth: 460,
                width: '100%',
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 999999,
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? '#b2d4ff' : 'white',
                color: state.isDisabled ? '#999999' : '#222222',
                cursor: state.isDisabled ? 'not-allowed' : 'default',
                '&:hover': {
                  backgroundColor: state.isDisabled ? 'white' : '#DEEBFF',
                  color: state.isDisabled ? '#999999' : '#222222',
                },
                '&:active': {
                  backgroundColor: state.isDisabled ? 'white' : '#b2d4ff',
                  color: state.isDisabled ? '#999999' : '#222222',
                },
              }),
            }}
            menuPortalTarget={document.body}
            isOptionDisabled={(option) => option.isDisabled}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button onClick={onClose} className={styles.closeBtn} variant="ghost">
          Cancel
        </Button>
        <Button
          onClick={handleNextClick}
          className={styles.ctaBtn}
          disabled={!selectedRole || isLoading}
          loading={isLoading}
        >
          Next
        </Button>
      </div>
      <HideScrollerGlobally />
    </Modal>
  );
};

export default SelectNonWaitlistedRoleModal;
