import React, { ReactNode, useMemo } from 'react';
import { observer } from 'mobx-react';
import AdminInvoicesDashboardLayout from '@src/layouts/InvoicesDashboard';
import { useStores } from '@src/stores';
import Table, { TableRow } from '@src/components/Table';
import { loadUnpaidInvoices } from '@src/url-loaders/loadInvoices';
import { LoadMoreLink } from '@ateams/components';
import TextButton from '@src/components/TextButton';
import Loader from '@src/components/Loader';
export const unpaidInvoicesLoader = loadUnpaidInvoices;

const UnpaidInvoicesListView = () => {
  const { invoices } = useStores();
  const { unpaidInvoices, loadUnpaidInvoices, nextTokens } = invoices;

  const headers: ReactNode[] = useMemo(() => {
    return ['Mission', 'Billing Client', 'Total amount', 'Days Overdue', ''];
  }, []);

  return (
    <AdminInvoicesDashboardLayout title={'Unpaid Invoice Statuses'}>
      {unpaidInvoices ? (
        <>
          <Table headers={headers} noDataText="No unpaid invoices">
            {unpaidInvoices &&
              unpaidInvoices.length > 0 &&
              unpaidInvoices.map((invoiceRow) => (
                <TableRow
                  key={invoiceRow.id}
                  cells={[
                    invoiceRow.missionTitle,
                    invoiceRow.billingClientName,
                    `$${invoiceRow.totalAmount}`,
                    invoiceRow.daysOverdue,
                    <TextButton
                      highlight
                      onClick={() =>
                        window.open(invoiceRow.downloadURL, 'blank')
                      }
                    >
                      Download
                    </TextButton>,
                  ]}
                />
              ))}
          </Table>
          {nextTokens?.unpaidInvoices && (
            <LoadMoreLink onLoadMore={loadUnpaidInvoices} />
          )}
        </>
      ) : (
        <Loader />
      )}
    </AdminInvoicesDashboardLayout>
  );
};

export default observer(UnpaidInvoicesListView);
