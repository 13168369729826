import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { Modal } from '@a_team/ui-components';
import BlurbsList, { BuilderTfsPitchObject } from './BlurbsList';
import { createUseStyles } from 'react-jss';
import BlurbPreview from './BlurbPreview';

interface BlurbsHistoryProps {
  onBlurbSelected(blurb: string): void;
  pitches: Array<BuilderTfsPitchObject>;
  isOpen: boolean;
  builderName: string;
  onClose(): void;
}

const useStyles = createUseStyles({
  modal: {
    '&&': {
      overflow: 'hidden',
      width: '100%',
      maxWidth: 950,
      height: '100vh',
      maxHeight: 480,
      borderRadius: 16,
      padding: 0,
    },

    // todo: add option to the modal hide the gradient
    '& > div:last-of-type': {
      display: 'none',
    },
    '& > div:first-of-type': {
      overflow: 'hidden',
      padding: 0,
    },
  },
  layout: {
    height: '100%',
    display: 'flex',
  },
});

const BlurbsHistoryModal: FC<BlurbsHistoryProps> = ({
  onBlurbSelected,
  pitches,
  builderName,
  ...modalPropos
}) => {
  const styles = useStyles();
  const [currentPitch, setcurrentPitch] = useState(pitches[0]);

  return (
    <Modal {...modalPropos} className={styles.modal}>
      <div className={styles.layout}>
        <BlurbsList
          onClick={(blurb: BuilderTfsPitchObject): void =>
            setcurrentPitch(blurb)
          }
          pitches={pitches}
          builderName={builderName}
        />
        <BlurbPreview
          onBlurbSelected={onBlurbSelected}
          proposal={currentPitch}
        />
      </div>
    </Modal>
  );
};

export default observer(BlurbsHistoryModal);
