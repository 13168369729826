import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';

const useStyles = createUseStyles({
  container: {
    border: '1px solid #DADADC',
    borderRadius: 8,
  },
  section: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
  },
});

const NoOpenMission = (): JSX.Element => {
  const styles = useStyles();
  const { auth } = useStores();
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.title}>Make sure your profile is up to date</div>
        <div>
          Give your profile a quick review, making sure you have a quality
          profile photo, a compelling "About me," and your most up-to-date
          skills.
        </div>
        <Link to={`/${auth.user?.username}`}>Update my profile</Link>
      </div>
    </div>
  );
};

export default observer(NoOpenMission);
