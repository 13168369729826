import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Spacing } from '@ateams/components';
import { Card } from '../components/card';
import { Text } from '../components/typography';
import { VettingFeedbackFormDefaults } from '@ateams/api/dist/endpoints/vetting-process-feedback';
import { Comment } from '../components/comment';

const useStyles = createUseStyles({
  sectionDescription: {
    marginBottom: Spacing.large,
  },
  comment: {
    marginBottom: Spacing.large,
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

export type VettingFeedbackFormGeneralComments = {
  comments: VettingFeedbackFormDefaults['answers']['generalComments'];
  comment?: string;
};

export type OnVettingFeedbackFormGeneralCommentsChange = (
  data: VettingFeedbackFormGeneralComments,
) => void;

export interface VettingFeedbackFormGeneralCommentsProps {
  defaultValues: VettingFeedbackFormGeneralComments;
  onChange?: OnVettingFeedbackFormGeneralCommentsChange;
  avatarSrc?: string;
  currentUserId?: string;
  isCurrentUserAdmin?: boolean;
  isReadOnly?: boolean;
  className?: string;
}

export const VettingFeedbackFormGeneralCommentsForm: React.FC<VettingFeedbackFormGeneralCommentsProps> =
  React.memo((props) => {
    const { isReadOnly } = props;
    const styles = useStyles();
    const [comments, setComments] = useState(props.defaultValues.comments);

    const onChange = (data: Partial<VettingFeedbackFormGeneralComments>) => {
      props.onChange?.({
        comments: data.comments || comments,
      });
    };

    const onCommentEdit = (comment: string, commentIndex: number) => {
      if (comments) {
        const newComments = [
          ...comments.slice(0, commentIndex),
          { ...comments[commentIndex], comment },
          ...comments.slice(commentIndex + 1),
        ];

        setComments(newComments);
        onChange({ comments: newComments });
      }
    };

    const onCommentRemove = (commentIndex: number) => {
      const newComments = comments?.filter((_, i) => commentIndex !== i);

      setComments(newComments);
      onChange({ comments: newComments });
    };

    return (
      <Card title={'Comments'} className={props.className}>
        {!isReadOnly && (
          <Text className={styles.sectionDescription}>
            Any comments / additional info you have on builder - an overview of
            their experience that they shared / info that you want to mention
            and is not falling under any other category. Any exceptional details
            will be appreciated. For example: soft skills that are worth
            mentioning, contribution to coding/open source communities etc.
          </Text>
        )}

        {comments &&
          comments.map((comment, i) => (
            <Comment
              key={comment.id}
              className={styles.comment}
              value={comment.comment}
              onCommentChange={(newComment) => onCommentEdit(newComment, i)}
              onCommentRemove={() => onCommentRemove(i)}
              showEditActions={
                props.isCurrentUserAdmin ||
                comment.user.uid === props.currentUserId
              }
              avatarSrc={comment.user.profilePictureURL}
              fullName={comment.user.fullName}
              date={comment.createdAt}
              isNewComment={false}
              isReadOnly={isReadOnly}
            />
          ))}

        {isReadOnly && !comments.length && (
          <Text isReadOnly readOnlyText={'No comments were added.'} />
        )}
      </Card>
    );
  });
