import React from 'react';
import { createUseStyles } from 'react-jss';
import { LocalHourRange } from '@a_team/models/dist/WorkingHoursObject';
import { theme } from '@a_team/ui-components';
import { Size, Text } from '../../components/typography';
import {
  minutesFromStartOfDayToOption,
  OnTimeSelectChange,
  TimeSelect,
} from '../../components/time-select';

const useStyles = createUseStyles({
  inputLabelSpace: {
    width: '12px',
  },
  valueRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'initial',
    alignItems: 'center',
  },
});

type WorkingHoursValue = Partial<LocalHourRange>;

export type OnWorkingHoursChange = (
  workingHours: WorkingHoursValue | undefined,
) => void;

export interface WorkingHoursInputProps {
  value?: WorkingHoursValue | undefined;
  onChange?: OnWorkingHoursChange;
  isReadOnly?: boolean;
  className?: string;
}

export const WorkingHoursInput: React.FC<WorkingHoursInputProps> = (props) => {
  const { isReadOnly, value } = props;
  const styles = useStyles();

  const onWorkingHoursFromChange: OnTimeSelectChange = (option) => {
    const startTime = option?.value;
    props.onChange?.({ ...value, startTime });
  };

  const onWorkingHoursToChange: OnTimeSelectChange = (option) => {
    const endTime = option?.value;
    props.onChange?.({ ...value, endTime });
  };

  if (isReadOnly) {
    const parts: string[] = [];

    if (value?.startTime) {
      parts.push(`from ${1}`);
    }
    if (value?.endTime) {
      parts.push(`to ${1}`);
    }

    let label = parts.length ? parts.join(' ') : undefined;
    // Capitalize first letter
    if (label) {
      label = label.charAt(0).toUpperCase() + label.slice(1);
    }

    return (
      <div className={props.className}>
        <Text size={Size.Small} isReadOnly>
          {label}
        </Text>
      </div>
    );
  }

  return (
    <div className={props.className}>
      <Text size={Size.Small} className={styles.valueRow}>
        From
        <div className={styles.inputLabelSpace} />
        <TimeSelect
          value={
            value?.startTime
              ? minutesFromStartOfDayToOption(value.startTime)
              : undefined
          }
          onChange={onWorkingHoursFromChange}
          placeholder={'...'}
          color={value?.startTime ? theme.colors.Grey[200] : undefined}
          isClearable
          menuPortalTarget={document.body}
        />
        <div className={styles.inputLabelSpace} />
        to
        <div className={styles.inputLabelSpace} />
        <TimeSelect
          value={
            value?.endTime
              ? minutesFromStartOfDayToOption(value.endTime)
              : undefined
          }
          onChange={onWorkingHoursToChange}
          placeholder={'...'}
          color={value?.endTime ? theme.colors.Grey[200] : undefined}
          isClearable
          menuPortalTarget={document.body}
        />
      </Text>
    </div>
  );
};
