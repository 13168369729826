import Hotjar from '@hotjar/browser';

const initHotjar = ({
  siteId,
  version,
  debug,
}: {
  siteId: number;
  version: number;
  debug?: boolean;
}) => {
  if (typeof window !== 'undefined') {
    Hotjar.init(siteId, version, { debug });
  }
};

const identifyHotjarUser = (
  userId: string,
  userAttributes: {
    email?: string;
    fullName?: string;
    username?: string;
    isAdmin?: boolean;
  },
) => {
  if (typeof window !== 'undefined') {
    Hotjar.identify(userId, userAttributes);
  }
};

export { initHotjar, identifyHotjarUser };
