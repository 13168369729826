import React from 'react';
import { createUseStyles } from 'react-jss';
import { VettingProcess } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { Colors } from '@ateams/components';
import { VettingColumnConfig } from './columns/mapping';
import { TableRowProps } from 'react-virtualized';

interface VettingProcessRowProps extends TableRowProps {
  columns: VettingColumnConfig<VettingProcess>[];
  vettingProcess: VettingProcess;
  isAdminView: boolean;
  isCurrentUserTheInterviewer: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${Colors.regular}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRight: `1px solid ${Colors.regular}`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
});

export const VettingProcessRow: React.FC<VettingProcessRowProps> = (props) => {
  const styles = useStyles();
  const { columns, vettingProcess, isAdminView, isCurrentUserTheInterviewer } =
    props;

  return (
    <div
      className={styles.container}
      style={props.style}
      data-testing-id={`selection-team-table-row-${vettingProcess.id}`}
    >
      {columns.map(({ header, width, Component }) => (
        <div
          key={header}
          className={styles.cell}
          style={{ width }}
          data-testing-id={`selection-team-table-row-column-${header}-${vettingProcess.id}`}
        >
          <Component
            vettingProcess={vettingProcess}
            isAdminView={isAdminView}
            isCurrentUserTheInterviewer={isCurrentUserTheInterviewer}
          />
        </div>
      ))}
    </div>
  );
};
