import { differenceInDays } from 'date-fns';
import { DateISOString } from '@a_team/models/dist/misc';

export const DAYS_TO_EDIT_FEEDBACK_FORM_ANSWERS = 30;

enum CannotSubmitOrEditFeedbackFormReasons {
  FormSubmissionTimeout = 'formSubmissionTimeout',
  NotTheInterviewer = 'notTheInterviewer',
}

/**
 * Checks if the user can submit/edit the feedback form
 * He can't if he is not an admin and more than 2 weeks has passed since initially submitting the form
 */
export function canSubmitOrEditVettingFeedbackForm(
  isCurrentUserAdmin: boolean,
  isCurrentUserTheInterviewer?: boolean,
  submitDate?: DateISOString,
): CannotSubmitOrEditFeedbackFormReasons | null {
  if (isCurrentUserAdmin) {
    return null;
  }

  if (!isCurrentUserTheInterviewer) {
    return CannotSubmitOrEditFeedbackFormReasons.NotTheInterviewer;
  }

  if (
    submitDate &&
    differenceInDays(new Date(), new Date(submitDate)) >=
      DAYS_TO_EDIT_FEEDBACK_FORM_ANSWERS
  ) {
    return CannotSubmitOrEditFeedbackFormReasons.FormSubmissionTimeout;
  }

  return null;
}

export enum GenerateCalendarType {
  Google = 'google',
  Apple = 'apple',
  Microsoft = 'microsoft',
}

export interface GenerateCalendarUrlEvent {
  title: string;
  start: string;
  end: string;
  description: string;
  location: string;
}

export function generateCalendarUrl(
  calendar: GenerateCalendarType,
  event: GenerateCalendarUrlEvent,
) {
  let calendarUrl = '';

  switch (calendar) {
    case 'google':
      calendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
        event.title,
      )}&dates=${event.start}/${event.end}&details=${encodeURIComponent(
        event.description,
      )}&location=${encodeURIComponent(event.location)}`;
      break;
    case 'apple':
      calendarUrl = `data:text/calendar;charset=utf8,BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nURL:${
        event.location
      }\nDTSTART:${event.start}\nDTEND:${
        event.end
      }\nSUMMARY:${encodeURIComponent(
        event.title,
      )}\nDESCRIPTION:${encodeURIComponent(
        event.description,
      )}\nLOCATION:${encodeURIComponent(
        event.location,
      )}\nEND:VEVENT\nEND:VCALENDAR`;
      break;
    case 'microsoft':
      calendarUrl = `https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/action/compose&subject=${encodeURIComponent(
        event.title,
      )}&startdt=${event.start}&enddt=${event.end}&body=${encodeURIComponent(
        event.description,
      )}&location=${encodeURIComponent(event.location)}`;
      break;
    default:
      break;
  }

  return calendarUrl;
}
