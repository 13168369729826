import { Stores } from '../stores';
import { match } from 'react-router-dom';
import {
  EditMissionLocationTemplate,
  MissionAdminAboutLocationTemplate,
  MissionAdminCreateTeamPulseLocationTemplate,
  MissionAdminDocumentsLocationTemplate,
  MissionAdminFAQLocationTemplate,
  MissionAdminTeamPulseLocationTemplate,
  MissionAdminNotificationsLocationTemplate,
  MissionAdminTimeTrackingLocationTemplate,
  MissionPageAdminLocationTemplate,
  MissionProposalLocationTemplate,
} from '@src/locations';
import { apiUsers } from '@ateams/api';

export interface MissionMatchParams {
  mid?: string;
  aid?: string;
}

export type MissionMatch = match<MissionMatchParams>;

// exported functions

export async function loadMission(
  stores: Stores,
  match: MissionMatch,
): Promise<unknown> {
  const { mid } = match.params;
  const { missions, auth } = stores;

  const isAdmin =
    (match.path === MissionPageAdminLocationTemplate ||
      match.path === MissionAdminAboutLocationTemplate ||
      match.path === MissionAdminTimeTrackingLocationTemplate ||
      match.path === MissionAdminDocumentsLocationTemplate ||
      match.path === EditMissionLocationTemplate ||
      match.path === MissionAdminFAQLocationTemplate ||
      match.path === MissionProposalLocationTemplate ||
      match.path === MissionAdminTeamPulseLocationTemplate ||
      match.path === MissionAdminNotificationsLocationTemplate ||
      match.path === MissionAdminCreateTeamPulseLocationTemplate) &&
    auth.isAdmin;

  if (!mid) {
    return Promise.resolve();
  }

  if (auth.username) {
    apiUsers
      .setupAccount(auth, auth.username ?? '')
      .catch((err) => console.error(err));
  }

  return missions.loadCurrent(mid, isAdmin);
}
