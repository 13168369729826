import { BasicUserObject } from '@a_team/models/dist/UserObject';
import React from 'react';

interface MutualConnectionMessageProps {
  mutualConnections: BasicUserObject[];
}

const MutualConnectionMessage = ({
  mutualConnections,
}: MutualConnectionMessageProps): JSX.Element | null => {
  const count = mutualConnections.length;

  switch (count) {
    case 0:
      return null;
    case 1:
      return <span>{mutualConnections[0].fullName}</span>;
    case 2:
      return (
        <span>
          {mutualConnections[0].fullName} and {mutualConnections[1].fullName}
        </span>
      );
    case 3:
      return (
        <span>
          {mutualConnections[0].fullName}, {mutualConnections[1].fullName} and{' '}
          <strong>1 other builder</strong>
        </span>
      );
    default:
      return (
        <span>
          {mutualConnections[0].fullName}, {mutualConnections[1].fullName} and{' '}
          <strong>{count - 2} other builders</strong>
        </span>
      );
  }
};

export default MutualConnectionMessage;
