import React from 'react';
import { createUseStyles } from 'react-jss';
import { MissionCardObject } from '@a_team/models/dist/MissionObject';
import MissionControlCardV2 from '@src/views/MissionControl/MissionControlCard/MissionControlCardV2/MissionControlCardV2';

interface RecommendedCardListProps {
  missions: MissionCardObject[];
}

const useStyles = createUseStyles({
  subTitle: {
    fontSize: '20px',
    fontWeight: 500,
    marginTop: 24,
    marginBottom: 8,
  },
  // second p should have marginTop of 64px
  '& p:nth-child(2)': {
    marginTop: 64,
  },
  title: {
    marginTop: 64,
  },
  desc: {
    margin: 0,
    maxWidth: 800,
  },
  recommendedCardsContainer: {
    marginTop: 16,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '24px',
  },
});

const RecommendedCardList = ({
  missions,
}: RecommendedCardListProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div>
      <h2>Recommended Missions</h2>
      <h4 className={styles.subTitle}>Missions you’ve been starred on</h4>
      <p className={styles.desc}>
        The companies behind these missions identified you as someone with
        relevant skills and experience. Starred builders have a higher chance of
        being selected.
      </p>
      <div className={styles.recommendedCardsContainer}>
        {missions.map((mission) => (
          <MissionControlCardV2 mission={mission} showVideo companyLiked />
        ))}
      </div>
      <h2 className={styles.title}>Missions with high compatibility</h2>
      <p className={styles.desc}>
        There is a strong compatibility between your expertise and the company's
        role requirement for these missions.
      </p>
    </div>
  );
};

export default RecommendedCardList;
