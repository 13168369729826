import React, { ReactElement } from 'react';
import { Range as RcRange, RangeProps as RcRangeProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { createUseStyles } from 'react-jss';
import {
  sliderHandleStyles,
  sliderRailStyles,
  sliderTrackStyles,
} from './styles';
import { HandleWithTooltip } from './HandleTooltip';
import { SliderPropsBase } from './index';

export type RangeProps = RcRangeProps & SliderPropsBase;

const useStyles = createUseStyles({
  '@global': {
    '.rc-slider-handle:active': {
      boxShadow: `0px 1px 8px rgba(0,0,0,0.25) !important`,
    },
    '.rc-slider-tooltip-arrow': {
      display: 'none',
    },
  },
});

export const RangeSlider = (props: RangeProps): ReactElement => {
  useStyles();
  const { tipFormatter, alwaysShowTooltip } = props;

  const defaultTipFormatter = (value: number): string => {
    return `${value}%`;
  };

  return (
    <RcRange
      railStyle={sliderRailStyles}
      trackStyle={[sliderTrackStyles]}
      handleStyle={[sliderHandleStyles, sliderHandleStyles]}
      handle={(props) => {
        const { ref, ...restProps } = props;
        return (
          <HandleWithTooltip
            {...restProps}
            {...{
              shown: alwaysShowTooltip,
              tipFormatter: tipFormatter || defaultTipFormatter,
              ariaValueTextFormatter: tipFormatter || defaultTipFormatter,
            }}
            key={`handle-with-tooltip-${props.index}`}
          />
        );
      }}
      {...props}
    />
  );
};
