import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import { TranscriptData } from '@ateams/api/dist/endpoints/Transcripts';
import { Select } from '@a_team/ui-components';

const useStyles = createUseStyles({
  switchBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
  info: {
    // add small fonts and color gray
    fontSize: 12,
    color: '#888',
  },
});

interface TranscriptSpeakerSwitchProps {
  transcript: TranscriptData;
  onChangeSpeakers: (newValue: unknown) => void;
}

const ManualSwitchSpeakers: React.FC<TranscriptSpeakerSwitchProps> = ({
  transcript,
  onChangeSpeakers,
}) => {
  const styles = useStyles();

  const options = [
    { label: 'Speaker 1', value: 0 },
    { label: 'Speaker 2', value: 1 },
  ];

  const [organizer, setOrganizer] = React.useState<{
    label: string;
    value: number;
  } | null>(null);
  const [attendee, setAttendee] = React.useState<{
    label: string;
    value: number;
  } | null>(null);

  useEffect(() => {
    if (!transcript) {
      return;
    } else {
      const organizerOption = options.find(
        (option) => option.value === transcript.organizer.number,
      );
      const attendeeOption = options.find(
        (option) => option.value === transcript.attendee.number,
      );
      setOrganizer(organizerOption || null);
      setAttendee(attendeeOption || null);
    }
  }, [transcript]);

  return (
    <>
      <div className={styles.switchBox}>
        <>
          <Select
            label={transcript.organizer.name}
            value={organizer}
            onChange={(newValue: unknown) => {
              const value = newValue as { value: number };
              if (transcript.organizer.id) {
                onChangeSpeakers({
                  speakers: [
                    {
                      uid: transcript.organizer.id,
                      name: transcript.organizer.name,
                      number: value.value,
                    },
                    {
                      uid: transcript.attendee.id,
                      name: transcript.attendee.name,
                      number: value.value === 0 ? 1 : 0,
                    },
                  ],
                });
              }
              if (value.value === 0) {
                setOrganizer(newValue as { label: string; value: number });
                setAttendee({ label: 'Speaker 2', value: 1 });
              } else {
                setOrganizer(newValue as { label: string; value: number });
                setAttendee({ label: 'Speaker 1', value: 0 });
              }
            }}
            options={options}
            isDisabled={!transcript.organizer.id || !transcript.attendee.id}
          />
          <Select
            label={transcript.attendee.name}
            value={attendee}
            onChange={(newValue: unknown) => {
              const value = newValue as { value: number };
              if (transcript.organizer.id) {
                onChangeSpeakers({
                  speakers: [
                    {
                      uid: transcript.organizer.id,
                      name: transcript.organizer.name,
                      number: value.value === 0 ? 1 : 0,
                    },
                    {
                      uid: transcript.attendee.id,
                      name: transcript.attendee.name,
                      number: value.value,
                    },
                  ],
                });
              }
              if (value.value === 0) {
                setAttendee(newValue as { label: string; value: number });
                setOrganizer({ label: 'Speaker 2', value: 1 });
              } else {
                setAttendee(newValue as { label: string; value: number });
                setOrganizer({ label: 'Speaker 1', value: 0 });
              }
            }}
            options={options}
            isDisabled={!transcript.organizer.id || !transcript.attendee.id}
          />
        </>
      </div>
      {!transcript.organizer.id || !transcript.attendee.id ? (
        <div className={styles.switchBox}>
          <span className={styles.info}>
            Could not identify pseaker user information. Manual switching is
            disabled.
          </span>
        </div>
      ) : null}
    </>
  );
};

export default ManualSwitchSpeakers;
