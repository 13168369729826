import { Checkbox, Select, SelectOption } from '@a_team/ui-components';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface HasManagedPeopleProps {
  hasManagedPeople: boolean;
  numberOfPeopleManaged: string;
  onChange: (hasManagedPeople: boolean, numberOfPeopleManaged: string) => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  label: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
});

const HasManagedPeople = ({
  hasManagedPeople,
  numberOfPeopleManaged,
  onChange,
}: HasManagedPeopleProps): JSX.Element => {
  const styles = useStyles();

  const managedPeople = Array.from({ length: 25 }, (_, i) => i + 1).map(
    (i) => ({
      value: i.toString(),
      label: i.toString(),
    }),
  );

  return (
    <div className={styles.container}>
      <div>
        <label className={styles.label}>
          Did this role require you to manage team members? (Optional)
        </label>
      </div>

      <div className={styles.wrapper}>
        <Checkbox
          checked={hasManagedPeople}
          onChange={() => {
            onChange(!hasManagedPeople, numberOfPeopleManaged);
          }}
          label="Yes, I managed"
        />
        <Select
          styles={{
            control: (base) => ({
              ...base,
              border: '1px solid #DADADC',
              borderColor: '#DADADC !important',
              backgroundColor: 'white',
              width: 80,
            }),
            placeholder: (base) => ({
              ...base,
              color: '#62646A',
            }),
          }}
          placeholder="0"
          defaultValue={managedPeople.find(
            (option) => option.value === numberOfPeopleManaged,
          )}
          options={managedPeople}
          onChange={(data) => {
            onChange(hasManagedPeople, (data as SelectOption).value);
          }}
          isDisabled={!hasManagedPeople}
        />
        team members.
      </div>
    </div>
  );
};

export default HasManagedPeople;
