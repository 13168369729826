import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Spacing } from '@ateams/components';
import { VettingProcessFeedbackAcquiredOrProgressed } from '@a_team/models/dist/vetting-processes/feedback';
import { InputLabel } from '../../components/typography';
import { CheckboxValueTag } from '../../components/value-tag';
import { TextInput } from '../../components/text-input';
import { Text } from '../../components/typography';

const INPUT_LABEL = 'Was the company acquired / did it progress considerably?';

type AcquiredOrProgressedValue =
  | VettingProcessFeedbackAcquiredOrProgressed
  | undefined;

export type OnAcquiredOrProgressedChange = (
  acquiredOrProgressed: AcquiredOrProgressedValue,
) => void;

export interface AcquiredOrProgressedInputProps {
  value: AcquiredOrProgressedValue;
  onChange?: OnAcquiredOrProgressedChange;
  isReadOnly?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  marginLeft: {
    marginLeft: Spacing.medium,
  },
  booleanCheckboxValueTag: {
    marginRight: Spacing.small,
    '&:last-child': {
      marginRight: 0,
    },
  },
  inputsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const AcquiredOrProgressedInput: React.FC<
  AcquiredOrProgressedInputProps
> = (props) => {
  const { isReadOnly, value } = props;
  const styles = useStyles();

  const onHasBeenAcquiredOrProgressedChange = (
    hasBeenAcquiredOrProgressed: boolean,
  ) => {
    let newAcquiredOrProgressed: AcquiredOrProgressedValue;
    if (value?.hasBeenAcquiredOrProgressed === hasBeenAcquiredOrProgressed) {
      newAcquiredOrProgressed = undefined;
    } else {
      newAcquiredOrProgressed = {
        ...value,
        hasBeenAcquiredOrProgressed,
        description: undefined,
      };
    }

    props.onChange?.(newAcquiredOrProgressed);
  };

  const onAcquiredOrProgressedDescriptionChange = (description: string) => {
    if (value) {
      const newAcquiredOrProgressed = {
        ...value,
        description,
      };

      props.onChange?.(newAcquiredOrProgressed);
    }
  };

  if (isReadOnly && !value) {
    return (
      <div className={cx(styles.marginBottom, props.className)}>
        <InputLabel className={styles.marginBottom}>{INPUT_LABEL}</InputLabel>
        <Text isReadOnly />
      </div>
    );
  }

  return (
    <div className={props.className}>
      <InputLabel className={styles.marginBottom}>{INPUT_LABEL}</InputLabel>
      <div className={cx(styles.inputsRow, styles.marginBottom)}>
        <CheckboxValueTag
          label={'Yes'}
          checked={value?.hasBeenAcquiredOrProgressed === true}
          className={styles.booleanCheckboxValueTag}
          onClick={() => onHasBeenAcquiredOrProgressedChange(true)}
          isReadOnly={isReadOnly}
        />
        <CheckboxValueTag
          label={'No'}
          checked={value?.hasBeenAcquiredOrProgressed === false}
          className={styles.booleanCheckboxValueTag}
          onClick={() => onHasBeenAcquiredOrProgressedChange(false)}
          isReadOnly={isReadOnly}
        />
      </div>
      {value?.hasBeenAcquiredOrProgressed === true && (
        <div className={cx(styles.marginLeft, styles.marginBottom)}>
          <TextInput
            value={value.description}
            onChange={(e) =>
              onAcquiredOrProgressedDescriptionChange(e.target.value)
            }
            placeholder={'Company details...'}
            isReadOnly={isReadOnly}
            readOnlyText={'No details were added.'}
          />
        </div>
      )}
    </div>
  );
};
