import React from 'react';
import { createUseStyles } from 'react-jss';
import greatEmoji from '../../emojis/great.svg';
import purpleCheckMark from './assets/purple-checkmark.svg';
import TeamMemberCard from './common/TeamMemberCard';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import { MemberResponses } from './TeamPulseModal';
import HiddenMembers from './HiddenMembers';

interface MissionReviewProps {
  usersToReview: BasicUserObject[];
  currentTeamMemberToReview?: BasicUserObject;
  moveUserToReview: (uid: string) => void;
  hiddenUsers: BasicUserObject[] | undefined;
  memberResponses: MemberResponses;
  toggleHiddenUsers: boolean;
  setToggleHiddenUsers: (toggle: boolean) => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  title: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
    marginTop: 0,
    marginBottom: 4,
  },
  desc: {
    color: '#707275',
    marginBottom: 16,
  },
  greatEmoji: {
    height: 24,
    width: 24,
  },
  missionStatusCard: {
    background: '#F7F7F7',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: 8,
    justifyContent: 'space-between',
  },
  missionStatusInfo: {
    display: 'flex',
    gap: 8,
  },
  checkMark: {
    height: 24,
    width: 24,
    marginLeft: 10,
  },
  teamMemberCardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
});

const MissionReview = ({
  usersToReview,
  currentTeamMemberToReview,
  hiddenUsers,
  memberResponses,
  moveUserToReview,
  toggleHiddenUsers,
  setToggleHiddenUsers,
}: MissionReviewProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div>
        <h5 className={styles.title}>Mission Review</h5>
        <div className={styles.desc}>
          Let us know about your experience on the mission
        </div>
        <div className={styles.missionStatusCard}>
          <div className={styles.missionStatusInfo}>
            <img
              className={styles.greatEmoji}
              src={greatEmoji}
              alt="Great emoji"
            />{' '}
            <span>Tell us about the mission</span>
          </div>
          <img
            className={styles.checkMark}
            src={purpleCheckMark}
            alt="Checkmark"
          />
        </div>
      </div>
      <div>
        <h5 className={styles.title}>Team Review</h5>
        <div className={styles.desc}>
          Let us know about your experience on the mission
        </div>
        <div className={styles.teamMemberCardsContainer}>
          {usersToReview.map((member, index) => {
            return (
              <TeamMemberCard
                key={index}
                fullName={member.fullName}
                profileImg={member.profilePictureURL}
                totalMembers={usersToReview.length}
                currentMemberIndex={index + 1}
                beingReviewed={member.uid === currentTeamMemberToReview?.uid}
                completed={
                  !!(
                    memberResponses[member.uid] &&
                    !memberResponses[member.uid].unableToRespond &&
                    member.uid !== currentTeamMemberToReview?.uid
                  )
                }
              />
            );
          })}
        </div>
      </div>
      <div>
        {hiddenUsers && !!hiddenUsers.length && (
          <HiddenMembers
            hiddenUsers={hiddenUsers}
            moveUserToReview={moveUserToReview}
            toggleHiddenUsers={toggleHiddenUsers}
            setToggleHiddenUsers={setToggleHiddenUsers}
          />
        )}
      </div>
    </div>
  );
};

export default MissionReview;
