import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Icon, IconType } from '@ateams/components';
import {
  TimesheetId,
  TimesheetRecordKey,
  TimesheetRecordData,
} from '@a_team/models/dist/TimesheetObject';
import {
  MissionAdminRole,
  MissionRoleId,
} from '@a_team/models/dist/MissionRole';
import { LoadingState } from '@src/hooks/useLoadingState';
import { stringifyMinutes } from '@src/helpers/time';
import GroupedTableRow from './GroupedTable/GroupedTableRow';
import { GroupedRecords } from '@src/stores/Missions/Mission';
import TextButton from '@src/components/TextButton';
import { useScreenClass } from 'react-grid-system';
import MobileTableContent from '@src/views/Mission/TimeTracking/TimesheetsTable/MobileTableContent';

export interface Props {
  currentUserRole: MissionAdminRole | undefined;
  userRecords?: GroupedRecords[] | undefined;
  totalMinutes?: number | undefined;
  timesheetId: TimesheetId | undefined;
  loading: LoadingState;
  updateRecord(
    sid: TimesheetId,
    key: TimesheetRecordKey,
    data: TimesheetRecordData,
  ): void;
  deleteRecord(sid: TimesheetId, key: TimesheetRecordKey): void;
  teamRecords?: GroupedRecords[] | undefined;
  timesheetsView: 'single' | 'team' | MissionRoleId;
  disabled?: boolean;
  invoiceURL?: string;
  adminView?: boolean;
  clientView?: boolean;
  exportTimesheet: (rid: MissionRoleId) => void;
}

const useStyles = createUseStyles({
  header: { fontSize: '15px', lineHeight: '24px', color: '#62646A' },
  footer: {
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#222222',
  },
  borderRight: { borderRight: '0.5px solid #C0C0C0' },
  cell: {
    paddingTop: '32px',
    paddingBottom: '32px',
    paddingRight: '24px',
    paddingLeft: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateCell: {
    width: '158px',
    flexShrink: 0,
  },
  teamCell: {
    width: '230px',
    flexShrink: 0,
  },
  hoursCell: {
    width: '158px',
    flexShrink: 0,
  },
  taskCell: {
    flex: 1,
  },
  editCell: {
    width: '158px',
    flexShrink: 0,
  },
  footerActions: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#FE630C',
  },
  smallDivider: {
    borderRight: '1px solid #C0C0C0',
    height: '16px',
    marginLeft: '12px',
    marginRight: '12px',
  },
});

export default function TableContent(props: Props): ReactElement {
  const {
    userRecords,
    currentUserRole,
    totalMinutes,
    timesheetId,
    loading,
    updateRecord,
    deleteRecord,
    teamRecords,
    timesheetsView,
    disabled,
    invoiceURL,
    adminView,
    clientView,
    exportTimesheet,
  } = props;

  const styles = useStyles();
  const screenClass = useScreenClass();

  if (screenClass === 'xs') {
    return <MobileTableContent {...props} />;
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          borderTop: '0.5px solid #C0C0C0',
        }}
      >
        <div
          className={cx(
            styles.cell,
            styles.dateCell,
            styles.header,
            styles.borderRight,
          )}
        >
          Date
        </div>
        {teamRecords && (
          <div
            className={cx(
              styles.cell,
              styles.teamCell,
              styles.header,
              styles.borderRight,
            )}
          >
            Team Members
          </div>
        )}
        <div
          className={cx(
            styles.cell,
            styles.hoursCell,
            styles.header,
            styles.borderRight,
          )}
        >
          Hours Worked
        </div>
        <div
          className={cx(
            styles.cell,
            styles.taskCell,
            styles.header,
            styles.borderRight,
          )}
        >
          Main Task
        </div>
        <div className={cx(styles.cell, styles.editCell, styles.header)}>
          Edit
        </div>
      </div>
      {teamRecords
        ? teamRecords.map((record, i) => (
            <GroupedTableRow
              key={i}
              record={record}
              timesheetId={timesheetId}
              loading={loading}
              updateRecord={updateRecord}
              deleteRecord={deleteRecord}
              currentUserRole={currentUserRole}
              timesheetsView={timesheetsView}
              clientView={clientView}
              disabled={disabled}
            />
          ))
        : userRecords?.map((record, i) => (
            <GroupedTableRow
              key={i}
              record={record}
              timesheetId={timesheetId}
              loading={loading}
              updateRecord={updateRecord}
              deleteRecord={deleteRecord}
              currentUserRole={currentUserRole}
              timesheetsView={timesheetsView}
              clientView={clientView}
              disabled={disabled}
            />
          ))}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          borderTop: '0.5px solid #C0C0C0',
        }}
      >
        <div
          className={cx(
            styles.cell,
            styles.dateCell,
            styles.footer,
            styles.borderRight,
          )}
        >
          Total
        </div>
        <div
          className={cx(
            styles.cell,
            styles.hoursCell,
            styles.footer,
            styles.borderRight,
          )}
        >
          {stringifyMinutes(totalMinutes || 0)}
        </div>
        {currentUserRole?.hourlyRate &&
          !!totalMinutes &&
          (timesheetsView === 'single' || adminView) && (
            <div
              className={cx(
                styles.cell,
                styles.dateCell,
                styles.footer,
                styles.borderRight,
              )}
            >
              $
              {(
                Math.round(
                  currentUserRole.hourlyRate * (totalMinutes / 60) * 100,
                ) / 100
              ).toLocaleString()}
            </div>
          )}
        <div
          className={cx(styles.cell, styles.taskCell, styles.header)}
          style={{ justifyContent: 'flex-end' }}
        >
          {!teamRecords && (
            <div className={styles.footerActions}>
              <TextButton
                highlight
                onClick={() =>
                  currentUserRole && exportTimesheet(currentUserRole.rid)
                }
              >
                <Icon
                  type={IconType.OrangeDownload}
                  style={{ marginRight: '8px', cursor: 'pointer' }}
                />
                Export Timesheet
              </TextButton>
              <div className={styles.smallDivider} />
              <TextButton
                highlight
                onClick={() => invoiceURL && window.open(invoiceURL)}
                disabled={!invoiceURL}
              >
                <Icon
                  type={IconType.OrangeDownload}
                  muted={!invoiceURL}
                  style={{ marginRight: '8px', cursor: 'pointer' }}
                />
                Export Invoice
              </TextButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
