export const countCharsForEditorValue = (value: string | undefined): number => {
  if (!value) {
    return 0;
  }

  const cleanedValue = value.replace(/<br>|<h3><\/h3>|<p><\/p>/g, '');
  const parser = new DOMParser();
  const doc = parser.parseFromString(cleanedValue, 'text/html');
  const plainText = doc.body.innerText.replace(/\s+/g, ' ');

  return plainText.length;
};
