import React, { useState } from 'react';
import Modal, { ModalBasicProps } from '..';
import { createUseStyles } from 'react-jss';
import {
  AdminVettingProcess,
  VettingProcessId,
  VettingType,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { UserId } from '@a_team/models/dist/UserObject';
import { TalentCategoryId } from '@a_team/models/dist/TalentCategories';
import {
  BorderRadius,
  Button,
  FontWeights,
  Select,
  Spacing,
} from '@ateams/components';
import { useStores } from '@src/stores';
import {
  Size,
  Text,
} from '@src/views/VettingFeedbackForm/components/typography';
import { EvaluationInvitePayload } from './form';
import { Vetter } from '@a_team/models/dist/vetter';
import { apiVetter, apiVettingProcess } from '@ateams/api';
import useDebounceState from '@src/hooks/useDebounceState';

export type OnEvaluationInviteSubmit = (
  payload: EvaluationInvitePayload,
) => void;

export type OnEvaluationFollowupInviteSubmit = (
  id: VettingProcessId,
  payload: EvaluationInvitePayload,
) => void;

interface GenerateBookingLinkModalProps extends ModalBasicProps {
  onSubmit: OnEvaluationInviteSubmit;
  vettingProcess?: AdminVettingProcess;
  defaultContactOwner?: UserId;
  defaultEvaluationType?: VettingType;
  defaultCategory?: TalentCategoryId;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '40px',
    height: '500px',
  },
  title: {
    fontWeight: FontWeights.medium,
    marginBottom: Spacing.small,
  },
  urlContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: BorderRadius.medium,
    border: '1px solid var(--grey-500, #DADADC)',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom: 16,
  },
  url: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginRight: Spacing.medium,
  },
});

export const GenerateBookingLinkModal: React.FC<
  GenerateBookingLinkModalProps
> = (props) => {
  const styles = useStyles();
  const { open, onClose } = props;
  const { auth } = useStores();
  const [vetters, setVetters] = useState<VetterOption[]>([]);
  const [selectedVetters, setSelectedVetters] = useState<VetterOption[]>([]);
  const [generatedBookingLink, setGeneratedBookingLink] = useState('');
  const [copied, setCopied] = useState(false);
  const [, setSearchQuery] = useDebounceState<string>(
    '',
    (query) => loadVettersOptions(query),
    700,
  );

  interface VetterOption {
    key: string;
    value: string;
    label: string;
    vetter: Vetter;
  }

  const loadVettersOptions = async (expression: string) => {
    if (!expression || expression.length === 0) {
      return;
    }

    const vetters = await apiVetter.adminSearchVettersByExpression(auth, {
      expression,
    });

    setVetters(vetters.items.map(vetterToOption));
  };

  const generateScheduleUrl = async () => {
    if (selectedVetters.length > 0) {
      const scheduleUrl = await apiVettingProcess.getScheduleUrl(
        auth,
        props.vettingProcess?.id as string,
        selectedVetters.map((v) => v.vetter.id),
      );

      setGeneratedBookingLink(scheduleUrl);
    }
  };

  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(generatedBookingLink);
    setCopied(true);
  };

  const vetterToOption = (vetter: Vetter): VetterOption => {
    return {
      key: vetter.id,
      value: vetter.id,
      label: vetter.user.fullName,
      vetter,
    };
  };

  return (
    <Modal open={open} onClose={onClose} style={{ padding: 0, minWidth: 680 }}>
      <div className={styles.container}>
        <Text size={Size.ExtraLarge} className={styles.title}>
          Generate booking link
        </Text>

        <div>
          <Select
            isMulti
            hideTags
            label="Select interviewers"
            showItemCount
            isSearchable
            itemCountPrefix="Interviewers"
            placeholder=""
            value={selectedVetters}
            onInputChange={(value) => setSearchQuery(value)}
            onChange={(options) =>
              setSelectedVetters(options.map((option) => option))
            }
            isOptionSelected={(option) =>
              selectedVetters.some(
                (selectedOption) => selectedOption.value === option.value,
              )
            }
            onMenuClose={generateScheduleUrl}
            options={vetters}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
          />
        </div>

        <div className={styles.urlContainer}>
          <Text size={Size.Medium} className={styles.url}>
            {generatedBookingLink}
          </Text>
          <Button
            size="small"
            width="auto"
            style={{
              borderRadius: 8,
              height: 40,
            }}
            color="secondaryDark"
            onClick={copyUrlToClipboard}
          >
            {copied ? 'Copied' : 'Copy'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
