import React from 'react';
import { createUseStyles } from 'react-jss';
import LabeledCheckboxInput from '@src/components/Inputs/LabeledCheckboxInput';
import { useStores } from '@src/stores';
import {
  FilterComponents,
  OnVettingProcessesFiltersChange,
  VettingProcessesFilters,
} from './filters';
import { FetchVettingProcessesFilters } from '@ateams/api/dist/endpoints/vetting-process';
import { ColumnsSelect, ColumnsSelectProps } from './columns-select';
import { Button } from '@ateams/components';

export interface VettingProcessesQueryBarProps {
  onFiltersChange?: OnVettingProcessesFiltersChange;
  defaultFilters?: FetchVettingProcessesFilters;
  availableFilters?: Set<FilterComponents>;
  columnsOptions: ColumnsSelectProps['options'];
  selectedColumns: ColumnsSelectProps['value'];
  onColumnsChange: ColumnsSelectProps['onChange'];
  onResetColumns: React.MouseEventHandler<HTMLButtonElement>;
  enableColumnsConfiguration: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  checkbox: {
    margin: '10px 0',
  },
  columnsSelect: {
    width: '280px',
    margin: '8px 0',
  },
  resetColumnsButton: {
    margin: '8px 0',
  },
});

export const VettingProcessesQueryBar: React.FC<
  VettingProcessesQueryBarProps
> = ({
  onFiltersChange,
  defaultFilters,
  availableFilters,
  columnsOptions,
  selectedColumns,
  onColumnsChange,
  onResetColumns,
  enableColumnsConfiguration,
}) => {
  const styles = useStyles();
  const { vettingProcesses } = useStores();

  return (
    <div className={styles.container}>
      <VettingProcessesFilters
        onChange={onFiltersChange}
        defaultValues={defaultFilters}
        availableFilters={availableFilters}
        showAllFilters={vettingProcesses.processesQuery.shouldShowAllColumns}
      />

      {enableColumnsConfiguration && (
        <div className={styles.container}>
          <ColumnsSelect
            options={columnsOptions}
            onChange={onColumnsChange}
            value={selectedColumns}
            className={styles.columnsSelect}
          />

          <Button
            onClick={onResetColumns}
            size={'small'}
            className={styles.resetColumnsButton}
          >
            Reset Columns
          </Button>

          <LabeledCheckboxInput
            label={'Show all columns'}
            onChange={(e) =>
              vettingProcesses.setShouldShowAllColumns(e.target.checked)
            }
            margin="none"
            checked={vettingProcesses.processesQuery.shouldShowAllColumns}
            className={styles.checkbox}
          />
        </div>
      )}
    </div>
  );
};
