import { validateQuestionResponse } from './questions';

export const validateSurveyResponse = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questions: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  responses: any,
): void => {
  const keys = Object.keys(questions) as Array<keyof typeof questions>;
  keys.forEach((key) => {
    if (questions[key].type) {
      validateQuestionResponse(
        questions[key].type,
        questions[key].props,
        responses[key],
      );
    } else {
      const keys2 = Object.keys(questions[key]) as Array<
        keyof typeof questions
      >;
      keys2.forEach((key2) => {
        if (responses[key]) {
          validateQuestionResponse(
            questions[key][key2].type,
            questions[key][key2].props,
            responses[key][key2],
          );
        }
      });
    }
  });
};
