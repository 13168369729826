import { apiConnectionsV2 } from '@ateams/api';
import {
  BasicConnectionObjectV2,
  ConnectionId,
  ConnectionObjectV2,
  ConnectionType,
  SharedJobExperience,
  SharedProjectExperience,
} from '@a_team/models/dist/ConnectionObject';
import { QueryNextToken } from '@a_team/models/dist/misc';
import { UserId } from '@a_team/models/dist/UserObject';
import { useStores } from '@src/stores';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { isNil } from 'lodash';
import { queryKeys } from './keys';
import {
  ConnectionsModalSources,
  useAnalytics,
} from '@ateams/analytics/dist/platform';
import { isEmpty } from 'lodash/fp';
import {
  BasicExperienceObject,
  JobExperienceData,
  ProjectExperienceData,
} from '@a_team/models/dist/ExperienceObject';
import { refreshProfile } from './utils';

export const useGetUserConnections = (userId: string, enabled = true) => {
  const stores = useStores();
  return useInfiniteQuery({
    queryKey: queryKeys.connections.byUserId(userId),
    queryFn: ({
      pageParam = undefined,
    }: {
      pageParam?: QueryNextToken | null;
    }) => {
      return apiConnectionsV2.getUserConnections(
        stores.auth,
        userId,
        pageParam ? pageParam : undefined,
      );
    },
    getNextPageParam: (lastPage) => {
      return lastPage.next;
    },
    enabled: enabled,
  });
};

export const useGetConnectionBetweenUsers = (userId: string) => {
  const stores = useStores();
  return useQuery({
    queryKey: queryKeys.connections.betweenUsers(userId),
    queryFn: () => {
      return apiConnectionsV2.getConnectionBetweenUsers(stores.auth, userId);
    },
    enabled: !!userId && stores.auth.basicAccess,
  });
};

export const useGetMutualConnectionsBetweenUsers = ({
  userId,
  enabled,
}: {
  userId: string;
  enabled?: boolean;
}) => {
  const stores = useStores();
  return useQuery({
    queryKey: queryKeys.connections.mutualConnections(userId),
    queryFn: () => {
      return apiConnectionsV2.getMutualConnectionsBetweenUsers(
        stores.auth,
        userId,
      );
    },
    enabled: enabled && stores.auth.basicAccess,
  });
};

export const useRequestConnection = ({
  source,
  connectActionOnCompleted,
}: {
  source: ConnectionsModalSources;
  connectActionOnCompleted?: (updatedConnection: ConnectionObjectV2) => void;
}) => {
  const stores = useStores();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      requestedUserId,
      type,
      sharedJobsExperiences,
      sharedProjectsExperiences,
    }: {
      requestedUserId: UserId;
      type: ConnectionType;
      sharedJobsExperiences?: SharedJobExperience[];
      sharedProjectsExperiences?: SharedProjectExperience[];
    }) => {
      return apiConnectionsV2.requestConnection(stores.auth, {
        requestedUserId,
        type,
        sharedJobsExperiences,
        sharedProjectsExperiences,
      });
    },
    onSuccess: async (newConnection: ConnectionObjectV2, vars) => {
      if (connectActionOnCompleted) {
        connectActionOnCompleted(newConnection);
      }
      const hasCustomData =
        !isEmpty(vars.sharedJobsExperiences) ||
        !isEmpty(vars.sharedProjectsExperiences);

      analytics.trackConnectionRequest(
        newConnection.initiator.uid,
        newConnection.requested.uid,
        newConnection.type,
        hasCustomData,
        source,
      );

      queryClient.invalidateQueries(
        queryKeys.connections.betweenUsers(newConnection.requested.uid),
      );
      await refreshProfile(stores);
    },
  });
};

export const useCancelConnectionRequest = (
  connectActionOnCompleted?: (updatedConnection: ConnectionObjectV2) => void,
) => {
  const stores = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      connection,
    }: {
      connection: BasicConnectionObjectV2;
    }) => apiConnectionsV2.cancelConnectionRequest(stores.auth, connection.cid),
    onSuccess: async (cancelledConnection: ConnectionObjectV2) => {
      if (connectActionOnCompleted) {
        connectActionOnCompleted(cancelledConnection);
      }

      queryClient.invalidateQueries(
        queryKeys.connections.betweenUsers(cancelledConnection.requested.uid),
      );
      await refreshProfile(stores);
    },
  });
};

export const useApproveConnection = ({
  source,
  connectActionOnCompleted,
}: {
  source: ConnectionsModalSources;
  connectActionOnCompleted?: (updatedConnection: ConnectionObjectV2) => void;
}) => {
  const stores = useStores();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      connection,
      sharedJobsExperiences,
      sharedProjectsExperiences,
    }: {
      connection: BasicConnectionObjectV2;
      sharedJobsExperiences?: SharedJobExperience[];
      sharedProjectsExperiences?: SharedProjectExperience[];
      connectActionOnCompleted?: (
        updatedConnection: ConnectionObjectV2,
      ) => void;
    }) =>
      apiConnectionsV2.approveConnection(stores.auth, connection.cid, {
        sharedJobsExperiences,
        sharedProjectsExperiences,
      }),
    onSuccess: async (updatedConnection: ConnectionObjectV2, vars) => {
      if (connectActionOnCompleted) {
        connectActionOnCompleted(updatedConnection);
      }

      const hasCustomData =
        !isEmpty(vars.sharedJobsExperiences) ||
        !isEmpty(vars.sharedProjectsExperiences);

      analytics.trackApproveConnectionRequest(
        updatedConnection.initiator.uid,
        updatedConnection.requested.uid,
        updatedConnection.type,
        hasCustomData,
        source,
      );

      queryClient.invalidateQueries(
        queryKeys.connections.betweenUsers(updatedConnection.initiator.uid),
      );
      await refreshProfile(stores);
    },
  });
};

export const useRejectConnectionRequest = ({
  source,
  connectActionOnCompleted,
}: {
  source: ConnectionsModalSources;
  connectActionOnCompleted?: (updatedConnection: ConnectionObjectV2) => void;
}) => {
  const stores = useStores();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      connection,
    }: {
      connection: BasicConnectionObjectV2;
    }) => apiConnectionsV2.rejectConnectionRequest(stores.auth, connection.cid),
    onSuccess: async (updatedConnection: ConnectionObjectV2, vars) => {
      if (connectActionOnCompleted) {
        connectActionOnCompleted(updatedConnection);
      }

      analytics.trackRejectConnectionRequest(
        updatedConnection.initiator.uid,
        updatedConnection.requested.uid,
        updatedConnection.type,
        source,
      );

      queryClient.invalidateQueries(
        queryKeys.connections.betweenUsers(updatedConnection.initiator.uid),
      );
      await refreshProfile(stores);
    },
  });
};

export const useDeleteConnection = (
  connectActionOnCompleted?: (updatedConnection: ConnectionObjectV2) => void,
) => {
  const stores = useStores();

  return useMutation({
    mutationFn: async ({
      connection,
    }: {
      connection: BasicConnectionObjectV2;
    }) => apiConnectionsV2.deleteConnection(stores.auth, connection.cid),
    onSuccess: async (updatedConnection: ConnectionObjectV2) => {
      if (connectActionOnCompleted) {
        connectActionOnCompleted(updatedConnection);
      }

      await refreshProfile(stores);
    },
  });
};

export const useUpdateSharedExperience = (
  connectActionOnCompleted?: (updatedConnection: ConnectionObjectV2) => void,
) => {
  const { auth } = useStores();

  return useMutation({
    mutationFn: async ({
      connectionId,
      requestedUserId,
      sharedJobsExperiences,
      sharedProjectsExperiences,
    }: {
      connectionId: ConnectionId;
      requestedUserId: UserId;
      sharedJobsExperiences?: SharedJobExperience[];
      sharedProjectsExperiences?: SharedProjectExperience[];
    }) =>
      apiConnectionsV2.updateConnectionSharedExperiences(auth, connectionId, {
        requestedUserId,
        sharedJobsExperiences,
        sharedProjectsExperiences,
      }),
    onSuccess: (newConnection: ConnectionObjectV2) => {
      if (connectActionOnCompleted) {
        connectActionOnCompleted(newConnection);
      }
    },
  });
};

export const useGetSharedExperiencesWithDetails = ({
  connectionId,
  enabled = true,
  onSuccess,
}: {
  connectionId: string | undefined;
  enabled?: boolean | undefined;
  onSuccess?: (data: {
    jobs: (BasicExperienceObject & JobExperienceData)[];
    projects: (BasicExperienceObject & ProjectExperienceData)[];
  }) => void;
}) => {
  const { auth } = useStores();
  const cid = connectionId || '';

  return useQuery({
    queryKey: queryKeys.connections.sharedExperiencesWithDetails(cid),
    queryFn: () => {
      return apiConnectionsV2.getConnectionSharedExperiencesWithDetails(
        auth,
        cid,
      );
    },
    enabled: !isNil(connectionId) && auth.basicAccess && enabled,
    onSuccess,
  });
};
