import { ServiceAuth, ServiceEndpoint } from './utils';
import {
  CompanyId,
  CompanyStage,
  EmployeeRange,
  EnrichableCompany,
  FundingRange,
  RevenueRange,
} from '@a_team/models/dist/Company';
import { AccountId } from '@a_team/models/dist/Account';

export interface EnrichableCompanyData {
  name: string;
  url: string;
  clientWorkspaces?: AccountId[];
}

export interface UpdateCompanyManualEnrichmentData {
  industries?: string[];
  city?: string;
  country?: string;
  timezone?: string;
  companyStage?: CompanyStage;
  description?: string;
  employeeRange?: EmployeeRange;
  fundingRange?: FundingRange;
  revenueRange?: RevenueRange;
}

export const BasePath = '/company-enrichment';

// exported functions

export default class EnrichableCompaniesEndpoint extends ServiceEndpoint {
  public createCompany(
    auth: ServiceAuth,
    data: EnrichableCompanyData,
  ): Promise<EnrichableCompany> {
    return this.fetch(auth, BasePath, null, 'post', data);
  }

  public getCompanyById(
    auth: ServiceAuth,
    cid: CompanyId,
  ): Promise<EnrichableCompany> {
    return this.fetch(auth, `${BasePath}/${cid}`);
  }

  public updateCompanyManualEnrichment(
    auth: ServiceAuth,
    cid: CompanyId,
    data: UpdateCompanyManualEnrichmentData,
  ): Promise<EnrichableCompany> {
    return this.fetch(auth, `${BasePath}/${cid}/manual`, null, 'post', data);
  }
}
