import React, { ReactElement } from 'react';
import { ServerSideLoader, ViewProps } from '@src/routes';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import { InvoiceType } from '@a_team/models/dist/InvoiceObject';
import MissionRoleTimesheetInvoice from './components/MissionRoleTimesheetInvoice';
import MissionPaymentCycleInvoice from './components/MissionPaymentCycleInvoice';
import MissionRolePlatformFeeInvoice from '@src/views/InvoicePdf/components/MissionRolePlatformFeeInvoice';
import WorkspacePaymentCycleInvoice from './components/WorkspacePaymentCycleInvoice';

type MatchParams = { iid: string; nonce: string };
type Props = ViewProps<MatchParams>;

export const invoicePdfViewLoader: ServerSideLoader<MatchParams> = async (
  stores,
  match,
) => {
  const { iid, nonce } = match.params;

  await stores.invoices.loadInvoiceByNonce(iid, nonce);
};

function InvoicePdfView(props: Props): ReactElement {
  const { iid } = props.match.params;
  const { invoices } = useStores();

  const invoice = invoices.getInvoice(iid);
  if (!invoice) {
    return <div>Loading invoice {iid}...</div>;
  }

  switch (invoice.type) {
    case InvoiceType.MissionRoleTimesheet:
      return <MissionRoleTimesheetInvoice invoice={invoice} />;

    case InvoiceType.MissionPaymentCycle:
      return <MissionPaymentCycleInvoice invoice={invoice} />;

    case InvoiceType.MissionRolePlatformFee:
      return <MissionRolePlatformFeeInvoice invoice={invoice} />;

    case InvoiceType.WorkspacePaymentCycle:
      return <WorkspacePaymentCycleInvoice invoice={invoice} />;
  }
}

export default observer(InvoicePdfView);
