import React, { ReactElement, useState } from 'react';
import { format } from 'date-fns';
import { numberWithCommas } from '@src/helpers/numbers';
import SectionHeading from '@src/components/SectionHeading';
import Modal, { ModalBasicProps } from '..';
import {
  Button as CallToActionButton,
  Select,
  SelectOption,
} from '@ateams/components';
import DatePicker from '@src/components/DatePicker';
import { DateISOString } from '@a_team/models/dist/misc';
import { InvoiceReceivableSource } from '@a_team/models/dist/InvoiceObject';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { createUseStyles } from 'react-jss';
import { MarkAsPaidData } from '@ateams/api/dist/endpoints/Invoices';

interface Props extends ModalBasicProps {
  createdAt: DateISOString;
  currency: string;
  totalAmount: number;
  onConfirm(data: MarkAsPaidData): void;
}

const receivableSourceOptions = [
  { value: InvoiceReceivableSource.BankOfAmerica, label: 'Bank of America' },
  { value: InvoiceReceivableSource.Brex, label: 'Brex' },
  { value: InvoiceReceivableSource.Stripe, label: 'Stripe' },
];

const useStyles = createUseStyles({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '32px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  button: {
    marginRight: '24px',
  },
  inputLabel: {
    margin: '24px 0 12px 0',
  },
  input: {
    margin: '12px 0',
  },
  smallNoteLabel: {
    fontSize: '14px',
    margin: '12px 0 18px 0',
  },
  noteLabel: {
    fontWeight: 800,
    margin: '36px 0',
  },
});

/**
 * A modal to mark an existing open invoice as paid
 * The invoices that are marked by hand are usually due to wire transfers
 * We want to add some metadata on these scenarios that we need to collect from the admin
 * {@link https://www.notion.so/ateams/When-finance-marks-payments-as-made-ask-them-for-more-info-abc383e5f1954516900a797f681b5868?d=a44500f9359048348909f4646711706a}
 */
export function InvoiceMarkPaidModal(props: Props): ReactElement {
  const { createdAt, currency, totalAmount, onClose, onConfirm, ...left } =
    props;
  const styles = useStyles();

  const [dateReceived, setDateReceived] = useState<Date>(new Date());
  const [receivableSourceOption, setReceivableSourceOption] =
    useState<null | SelectOption>(null);
  const [amountPaid, setAmountPaid] = useState<number>(0);
  const [memo, setMemo] = useState<string>('');

  const isValidInput = Boolean(receivableSourceOption);

  const onMarkAsPaid = () =>
    onConfirm({
      dateReceived,
      receivableSource:
        receivableSourceOption?.value as InvoiceReceivableSource,
      amountPaid,
      memo: memo || undefined,
    });

  return (
    <Modal onClose={onClose} hideCloseButton {...left}>
      <div style={{ color: 'black' }}>
        <SectionHeading isFirst>Mark Invoice as Paid</SectionHeading>
        <div>
          <p className={styles.inputLabel}>When was the payment received ?</p>
          <div className={styles.input}>
            <DatePicker
              onChange={({ date }) => setDateReceived(new Date(date))}
              date={dateReceived}
            />
          </div>
        </div>
        <div>
          <p className={styles.inputLabel}>Receivable Source</p>
          <Select
            options={receivableSourceOptions}
            value={receivableSourceOption}
            onChange={setReceivableSourceOption}
            className={styles.input}
          ></Select>
        </div>
        <div>
          <p className={styles.inputLabel}>Adjust amount paid</p>
          <OutlinedInput
            value={amountPaid}
            type="number"
            onChange={(e) => setAmountPaid(parseFloat(e.target.value))}
            precursor="$"
            className={styles.input}
          />
          {amountPaid !== totalAmount && (
            <p className={styles.smallNoteLabel}>
              Note: The amount paid is unequal to the total amount
            </p>
          )}
        </div>
        <div>
          <p className={styles.inputLabel}>Notes</p>
          <OutlinedInput
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            placeholder={'memo'}
            className={styles.input}
          />
        </div>

        <p
          className={styles.noteLabel}
        >{`Are you sure you want to mark the invoice from ${format(
          new Date(createdAt),
          'LLL d',
        )} with an amount of ${currency}${numberWithCommas(totalAmount)}?`}</p>

        <div className={styles.buttonsContainer}>
          <CallToActionButton
            color="regular"
            onClick={onClose}
            className={styles.button}
          >
            Cancel
          </CallToActionButton>
          <CallToActionButton
            onClick={onMarkAsPaid}
            className={styles.button}
            disabled={!isValidInput}
          >
            Confirm
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
}
