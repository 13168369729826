import { SurveyQuestionBaseProps } from '../SurveyQuestionBaseProps';
import { SurveyQuestionResponseValidator } from '../SurveyQuestionResponseValidator';
import { SurveyQuestionType } from '../SurveyQuestionType';

/** Properties of a rating question with a free form comment */
export interface YesOrNoSurveyQuestionProps extends SurveyQuestionBaseProps {
  title: string;
}

export interface PartialYesOrNoSurveyQuestionResponse {
  value?: boolean;
}
export interface AbleToRespond extends PartialYesOrNoSurveyQuestionResponse {
  unableToRespond?: never;
}

export interface UnableToRespond {
  value?: never;
  unableToRespond?: boolean;
}

export type YesOrNoSurveyQuestionResponse = AbleToRespond | UnableToRespond;

export const responseIsUnableToRespond = (
  response: YesOrNoSurveyQuestionResponse,
): response is UnableToRespond => 'unableToRespond' in response;

export const responseIsAbleToRespond = (
  response: YesOrNoSurveyQuestionResponse,
): response is AbleToRespond => 'value' in response;

export const validateYesOrNoSurveyQuestionResponse: SurveyQuestionResponseValidator<
  SurveyQuestionType.YesOrNo
> = (props, response): void => {
  if (responseIsAbleToRespond(response)) {
    if (props.required && !response) {
      throw new Error('required');
    }

    const { value } = response;

    if (typeof value !== 'boolean') {
      throw new Error('invalid value in YesOrNoSurvey response');
    }
  } else if (responseIsUnableToRespond(response)) {
    if (response.unableToRespond !== true) {
      throw new Error('unableToRespond must be unset or true');
    }
  } else if (props.required) {
    throw new Error(
      'Response for YesOrNoSurvey must contain `value` or `unableToRespond`',
    );
  }
};
