import {
  SharedJobExperience,
  SharedProjectExperience,
} from '@a_team/models/dist/ConnectionObject';
import {
  BasicExperienceObject,
  JobExperienceData,
  ProjectExperienceData,
} from '@a_team/models/dist/ExperienceObject';

export enum ConnectModalScreens {
  Home = 'Home',
  CancelConnectionRequest = 'CancelConnectionRequest',
  SetWorkingExperience = 'SetWorkingExperience',
  ConnectionConfirmed = 'ConnectionConfirmed',
  DeleteConnection = 'DeleteConnection',
  RejectConnectionRequest = 'RejectConnectionRequest',
  NoLongerAvailable = 'NoLongerAvailable',
}

export declare type EditingExperienceObject =
  | SharedJobExperience
  | SharedProjectExperience;

export declare type ExperienceObject = BasicExperienceObject &
  (ProjectExperienceData | JobExperienceData);
