import { Button } from '@a_team/ui-components';
import CompanyLogo from '@src/components/CompanyLogo';
import React from 'react';
import { useCommonStyles } from './commonStyles';
import interviewDeclinedIcon from './interview-declined.svg';

interface DeclineNotInterestedProps {
  companyImageUrl?: string | null;
  onClose: () => void;
  onBrowseMissions: () => void;
}

const DeclineNotInterested = ({
  companyImageUrl,
  onClose,
  onBrowseMissions,
}: DeclineNotInterestedProps): JSX.Element => {
  const styles = useCommonStyles();

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <CompanyLogo logoUrl={companyImageUrl} size={72} />
        <img
          className={styles.indicatorIcon}
          src={interviewDeclinedIcon}
          alt="interview declined"
        />
      </div>
      <h4 className={styles.title}>You’ve declined the interview</h4>
      <div className={styles.desc}>
        Thanks for letting us know, feel free to browse other open missions to
        find a role that fits your interests.
      </div>
      <div className={styles.btnsContainer}>
        <Button onClick={onClose} variant="secondary">
          Close
        </Button>
        <Button onClick={onBrowseMissions}>Browse missions</Button>
      </div>
    </div>
  );
};

export default DeclineNotInterested;
