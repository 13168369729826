import React, { ReactElement } from 'react';
import Modal, { ModalBasicProps } from '..';
import confetti from './confetti.svg';
import largeIcon from './largeIcon.svg';
import heroBanner from './heroBanner.png';
import { createUseStyles } from 'react-jss';
import { Button as CallToActionButton } from '@ateams/components';
import Reward from 'react-rewards';
import { Breakpoints } from '@ateams/components';
import cx from 'classnames';
import SectionHeading from '@src/components/SectionHeading';

const useStyles = createUseStyles({
  modal: {
    padding: '0px !important',
    backgroundColor: 'transparent !important',
    borderColor: 'transparent !important',
    overflowX: 'hidden !important',
    overflowY: 'hidden !important',
    maxHeight: 'unset !important',
    width: '100% !important',
    height: '100%',
    borderRadius: '0px !important',
    outline: 'none',
  },
  logo: {
    position: 'absolute',
    zIndex: 1000000000,
    width: 64,
    top: 20,
  },
  confetti: {
    position: 'absolute',
    top: -8,
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '34px',
    color: '#222222',
    textAlign: 'left',
  },
  text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#222222',
    marginTop: 0,
  },
  button: {
    width: 148,
  },
  '@keyframes scale-up-center': {
    '0%': {
      transform: 'scale(0)',
    },
    '50%': {
      transform: 'scale(1.5)',
    },

    '100%': {
      transform: 'scale(1)',
    },
  },
  scale: {
    animation:
      '$scale-up-center 0.8s cubic-bezier(0.175, 0.885, 0.320, 1.275) both',
  },
  container: {
    paddingTop: 0,
    overflow: 'hidden',
    height: '100%',
    position: 'relative',
  },
  inner: {
    padding: '145px 15px 40px 15px',
    background: `url(${heroBanner}) 0 0 no-repeat #fff`,
    overflow: 'scroll',
    maxHeight: 'unset',
    height: '100%',
    backgroundPosition: 'top center',
  },
  signature: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    background: 'linear-gradient(180deg, #E9E9E9 0%, #E1D5ED 100%)',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12,
    display: 'none',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '90% !important',
      maxWidth: 680,
      height: '90%',
      maxHeight: '95vh',
    },
    container: {
      paddingTop: '60px',
      overflow: 'hidden',
      height: '100%',
    },
    logo: {
      width: 96,
      top: 27,
    },
    confetti: {
      top: 40,
    },
    title: {
      textAlign: 'center',
      fontSize: 28,
    },
    text: {
      marginTop: 40,
    },
    button: {
      width: 'auto',
    },
    signature: {
      display: 'flex',
    },
    inner: {
      paddingTop: 114,
      padding: '114px 24px 40px 24px',
      height: 'auto',
      borderRadius: 16,
      backgroundSize: 'contain',
      backgroundPosition: '0 0',
    },
  },
});

interface Props extends ModalBasicProps {
  title?: string;
}

export default function WelcomeModal(props: Props): ReactElement {
  const { title, onClose, ...left } = props;
  const styles = useStyles();

  return (
    <Modal onClose={onClose} hideCloseButton className={styles.modal} {...left}>
      <div className={styles.container}>
        <div
          style={{
            width: '100%',
            position: 'absolute',
            top: 0,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={largeIcon}
            alt="logo"
            className={cx(styles.scale, styles.logo)}
          />
          <img
            className={cx(styles.scale, styles.confetti)}
            src={confetti}
            alt="confetti"
          />
        </div>
        <div className={styles.inner}>
          <div>
            <Reward
              //eslint-disable-next-line
              ref={(ref: any): void => {
                if (ref) {
                  ref.rewardMe();
                }
              }}
              type="confetti"
              config={{ spread: 180, particleCount: 120 }}
            >
              <SectionHeading isFirst className={styles.title}>
                You now have an A.Team profile!
              </SectionHeading>
            </Reward>
          </div>

          <div className={styles.text}>
            <p> We're glad you're here.</p>
            <p>
              We founded A.Team to help the industry's top tech talent connect
              with high-end clients, vetted collaborators as teammates,
              meaningful, ambitious work, and admin tools for simple payouts &
              contracts.
            </p>
            <p>
              Keep an eye out for an email from us about next steps for your
              application, and in the meantime, do continue to add projects,
              industries, and jobs to your profile - as the more we know about
              you, the better we’re able to match you to missions!
            </p>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div className={styles.signature}>
                <img
                  src={largeIcon}
                  style={{ width: 18 }}
                  alt={'A.Team Logo'}
                />
              </div>
              <div>
                <div>Onward and upward,</div>
                <div style={{ fontWeight: 500 }}>
                  Raphael, Kobi {'&'} the Build Team
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '40px',
            }}
          >
            <CallToActionButton
              onClick={props.onClose}
              className={styles.button}
              size={'small'}
            >
              Let's Go
            </CallToActionButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
