import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import UserObject, { UserCardObject } from '@a_team/models/dist/UserObject';
import useToggle from '@src/hooks/useToggle';
import MessageModal from '@src/components/Modal/MessageModal';
import { useStores } from '@src/stores';

export interface MessageButtonProps {
  user: UserObject | UserCardObject;
  className?: string;
}

const useStyles = createUseStyles({
  message: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '24px',
    color: '#FE630C',
    cursor: 'pointer',
  },
});

const MessageButton = (props: MessageButtonProps): ReactElement | null => {
  const styles = useStyles();
  const [messageModalOpen, setMessageModalOpen] = useToggle();
  const { auth } = useStores();

  return (
    <>
      {messageModalOpen && (
        <MessageModal
          currentUser={auth.user}
          profile={props.user}
          open={messageModalOpen}
          onClose={(): void => setMessageModalOpen(false)}
        />
      )}
      <div
        className={cx(styles.message, props.className)}
        onClick={(): void => setMessageModalOpen(true)}
      >
        Message
      </div>
    </>
  );
};

export default MessageButton;
