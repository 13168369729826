import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import UserAvatar from '../UserAvatar';
import { Link } from 'react-router-dom';
import { ProfileLocation } from '@src/locations';
import { Icon, IconType } from '@ateams/components';
import { RecommendationStatus } from '@a_team/models/dist/RecommendationObject';

interface UserTooltipProps {
  username: string;
  fullName: string;
  profilePictureURL: string;
  title?: string;
  status?: RecommendationStatus;
}

const useStyles = createUseStyles({
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    width: '204px',
    height: 'auto',
    maxHeight: '180px',
    padding: '13px',
    position: 'absolute',
    zIndex: '100',
    textAlign: 'center',
    transition: 'opacity 0.3s',
    borderRadius: 8,
    border: '1px solid #FF0FAD',
    background: 'white',
    boxShadow:
      '-5px 4px 9px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
    color: '#222222',
  },
  icon: {
    height: 16,
    width: 16,
    cursor: 'pointer',
  },
  avatarIconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    justifyContent: 'flex-start',
  },
  fullName: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'start',
  },
  title: {
    fontSize: 12,
    color: '#818388',
    textAlign: 'start',
  },
  status: {
    marginTop: 4,
    display: 'flex',
    gap: 8,
    fontSize: 12,
    textAlign: 'start',
    alignItems: 'center',
  },
});

const UserTooltip = ({
  username,
  fullName,
  profilePictureURL,
  title,
  status,
}: UserTooltipProps): JSX.Element => {
  const styles = useStyles();

  const statusMessage = useMemo(() => {
    if (status === RecommendationStatus.Unavailable) {
      return { color: '#818388', text: 'Unavailable' };
    }

    if (status === RecommendationStatus.Pending) {
      return { color: '#818388', text: 'Pending team up request' };
    }

    if (status === RecommendationStatus.Active) {
      return { color: '#79CE00', text: 'Accepted team up request' };
    }

    if (status === RecommendationStatus.Rejected) {
      return { color: '#222222', text: 'Declined team up request' };
    }

    return undefined;
  }, [status]);

  return (
    <Link to={ProfileLocation(username)} className={styles.tooltip}>
      <div className={styles.avatarIconContainer}>
        <UserAvatar
          alt={fullName}
          size={48}
          shouldResizeUploadCareImages={false}
          src={profilePictureURL}
        />

        <Icon type={IconType.ArrowUpRight} className={styles.icon} />
      </div>
      <div className={styles.content}>
        <div className={styles.fullName}>{fullName}</div>
        <div className={styles.title}>{title}</div>
        {statusMessage && (
          <div className={styles.status}>
            <span
              style={{
                background: statusMessage.color,
                height: 12,
                width: 12,
                borderRadius: 2,
              }}
            />
            {statusMessage.text}
          </div>
        )}
      </div>
    </Link>
  );
};

export default UserTooltip;
