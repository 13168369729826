import { Checkbox, Select } from '@a_team/ui-components';
import { isNonNullable } from '@src/helpers/sort';
import {
  ExistingProject,
  LocalProjects,
  NewProject,
} from '@src/stores/Profile/models';
import React from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { FormValues } from '.';
import RelatedImgContainer from './RelatedImgContainer';

interface RelatedProjectsProps {
  register: UseFormRegister<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  trigger: UseFormTrigger<FormValues>;
  watch: UseFormWatch<FormValues>;
  projects?: LocalProjects;
  jobId?: string;
}

const useStyles = createUseStyles({
  label: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  desc: {
    fontSize: '15px',
    fontWeight: 300,
    lineHeight: '24px',
    color: '#62646A',
    textAlign: 'start',
    margin: 0,
  },
  labelDescContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    marginBottom: 12,
  },
  noCover: {
    width: '12px',
    height: 'auto',
    '& span': {
      width: '12px',
      height: '12px',
      display: 'flex',
    },
    '& svg': {
      width: '12px !important',
      height: '12px !important',
    },
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    gap: '12px',
    justifyContent: 'flex-start',

    // hover bg
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },

    img: {
      width: 40,
      height: 40,
    },
  },
});

function isExistingProject(
  project: ExistingProject | NewProject,
): project is ExistingProject {
  return 'eid' in project;
}

const RelatedProjects = ({
  watch,
  register,
  setValue,
  trigger,
  projects,
  jobId,
}: RelatedProjectsProps): JSX.Element => {
  const styles = useStyles();

  const selectedProjects = watch('relatedProjectIds');

  const values = projects
    ?.filter(isExistingProject)
    .filter((project) =>
      selectedProjects?.some((selected) => selected === project.eid),
    )
    .map((project) => {
      const existingProject = isExistingProject(project);

      if (!existingProject) {
        return null;
      }
      return {
        value: project.eid,
        label: project.title,
        logo: project.logoURL,
      };
    })
    .filter(isNonNullable);

  const projectOptions = projects
    ?.map((project) => {
      const existingProject = isExistingProject(project);
      if (!existingProject) {
        return null;
      }

      const isDisabled = project.relatedJobId && project.relatedJobId !== jobId;

      return {
        value: project.eid,
        label: project.title,
        logo: project.logoURL,
        isDisabled,
      };
    })
    .filter(isNonNullable)
    .sort((a, b) => {
      if (a?.isDisabled && !b?.isDisabled) {
        return 1;
      }
      if (!a?.isDisabled && b?.isDisabled) {
        return -1;
      }
      return 0;
    });

  const existingProjects = projects?.filter(isExistingProject);

  return (
    <div>
      <div className={styles.labelDescContainer}>
        <label className={styles.label}>Related projects (Optional)</label>
        <span className={styles.desc}>
          Connect a project you worked on at this position.
        </span>
      </div>
      <Select
        alwaysShowCounter={true}
        isMulti={true}
        menuPlacement="top"
        styles={{
          control: (base) => ({
            ...base,
            border: '1px solid #DADADC',
          }),
          placeholder: (base) => ({
            ...base,
            '& span': {
              display: values?.length === 0 ? 'none' : 'inline',
              backgroundColor: '#6D00D7 !important',
              borderRadius: '8px !important',
            },
          }),
        }}
        {...register('relatedProjectIds')}
        value={values}
        placeholder={
          selectedProjects?.length ? (
            <div>
              Selected projects{' '}
              <span
                style={{
                  color: '#FFF',
                  marginLeft: 4,
                  padding: '2px 8px',
                  minWidth: 25,
                }}
              >
                {selectedProjects?.length}
              </span>
            </div>
          ) : (
            'Select projects'
          )
        }
        components={{
          Option: (props) => {
            const data = props.data as {
              value: string;
              label: string;
              isDisabled?: boolean;
            };

            const foundProject = existingProjects?.find(
              (p) => p.eid === data.value,
            );

            if (!foundProject) {
              return null;
            }

            const projectCover =
              foundProject.imagesUrls?.[0] ||
              foundProject.imageURL ||
              undefined;

            return (
              <div
                {...props.innerProps}
                className={styles.option}
                style={{
                  opacity: data?.isDisabled ? 0.5 : 1,
                  cursor: data?.isDisabled ? 'not-allowed' : 'pointer',
                }}
              >
                <Checkbox
                  onChange={() => null}
                  checked={props.isSelected}
                  disabled={data.isDisabled}
                />

                <RelatedImgContainer
                  projectCover={projectCover}
                  companyLogoUrl={
                    foundProject.logoURL || foundProject.companyData?.logoUrl
                  }
                  iconSize="sm"
                  logoSize={18}
                  index={1}
                  noCoverIconClassName={styles.noCover}
                />

                <div>
                  <label style={{ color: '#222' }}>{foundProject.title}</label>
                  <div style={{ color: '#62646A' }}>
                    {foundProject.companyName || foundProject.companyData?.name}
                  </div>
                </div>
              </div>
            );
          },
        }}
        onChange={(value) => {
          const newValues = value as { value: string; label: string }[];

          const newSelectedProjects = newValues.map((project) => project.value);

          setValue('relatedProjectIds', newSelectedProjects, {
            shouldDirty: true,
          });

          trigger('relatedProjectIds');
        }}
        options={projectOptions}
      />
    </div>
  );
};

export default RelatedProjects;
