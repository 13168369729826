import React from 'react';
import Skeleton from 'react-loading-skeleton';
import cx from 'classnames';
import { useScreenClass } from 'react-grid-system';
import { useStyles } from '.';

function StepSkeleton({
  isFinalStep,
  className,
  withMedia,
  isCurrentStep,
  withCta,
}: {
  isFinalStep?: boolean;
  className?: string;
  withMedia?: boolean;
  isCurrentStep?: boolean;
  withCta?: boolean;
}) {
  const styles = useStyles({ stepStatus: isCurrentStep, isFinalStep });
  const screenClass = useScreenClass();

  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  return (
    <div
      className={cx(
        styles.container,
        !isMobile && styles.containerDesktop,
        isCurrentStep &&
          (!isMobile ? styles.currentStepDesktop : styles.currentStepMobile),
        isFinalStep && styles.finalStepContainer,
        className,
      )}
    >
      <div className={styles.topContent}>
        {isMobile ? (
          <>
            <div className={styles.mobileHeader}>
              <div>
                <Skeleton height={40} width={40} borderRadius={11} />
              </div>
              <div className={styles.cta}>
                {withCta && <Skeleton height={40} width={80} />}
              </div>
            </div>
            <div className={styles.topMiddleContent}>
              <div className={styles.title}>
                <Skeleton width={`60%`} />
              </div>
              <div className={styles.description}>
                <Skeleton count={2} />
              </div>
              <div className={styles.completionTime}>
                <Skeleton width={`40%`} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.topLeftContent}>
              <div>
                <Skeleton height={40} width={40} borderRadius={11} />
              </div>
              <div className={styles.topMiddleContent}>
                <div className={styles.title}>
                  <Skeleton width={150} />
                </div>
                <div className={styles.description}>
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                </div>
                <div className={styles.completionTime}>
                  <Skeleton width={50} />
                </div>
              </div>
            </div>
            <div className={styles.cta}>
              <Skeleton height={40} width={100} />
            </div>
          </>
        )}
      </div>
      {withMedia && (
        <div className={styles.media}>
          <Skeleton
            height={300}
            className={styles.player}
            style={{
              marginTop: 50,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default StepSkeleton;
