import { ProposalId } from '@a_team/models/dist/ProposalObject';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const missionApplicationsMatchesOnDataKeys = createQueryKeys(
  'missionApplicationsMatchesOnData',
  {
    missionApplicationsMatchesOnData: (
      proposalIds: ProposalId[],
      aids: string[],
    ) => ({
      proposalIds,
      aids,
    }),
  },
);
