import React, { useEffect } from 'react';
import MainLayout from '@src/layouts/Main';
import { useStores } from '@src/stores';
import { useHistory } from 'react-router-dom';
import { MissionControlBase } from '@src/locations';
import { observer } from 'mobx-react';

const Architects = (): JSX.Element | null => {
  const { auth } = useStores();
  const history = useHistory();

  useEffect(() => {
    if (!auth.isAdmin) {
      history.push(`${MissionControlBase}/recommended`);
    }
  }, [auth.isAdmin]);

  if (!auth.currentUser) return null;

  return (
    <MainLayout title="Architects" style={{ padding: 0 }}>
      <iframe
        title="guide"
        is="x-frame-bypass"
        src={'https://architects-portal.super.site/'}
        width="100%"
        style={{ height: '100vh' }}
        frameBorder="0"
      />
    </MainLayout>
  );
};

export default observer(Architects);
