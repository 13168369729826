import React from 'react';
import Step from '../common/Step';
import StepIcon, { StepIconType } from '../common/Step/StepIcon';

function JoinTheTeamStep() {
  return (
    <Step
      stepIcon={<StepIcon type={StepIconType.FINAL} />}
      title="Join the team"
      description="If your skills and experience match the needs of the network, you’ll gain full access to team up, join missions, and appear in client discovery."
      isFinalStep
    />
  );
}

export default JoinTheTeamStep;
