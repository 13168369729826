import React, { ReactElement } from 'react';
import {
  TimesheetId,
  TimesheetRecordKey,
  TimesheetRecordData,
} from '@a_team/models/dist/TimesheetObject';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import cx from 'classnames';
import { LoadingState } from '@src/hooks/useLoadingState';
import {
  MissionAdminRole,
  MissionRoleId,
} from '@a_team/models/dist/MissionRole';
import TableRow from './TableRow';
import { GroupedRecords } from '@src/stores/Missions/Mission';
import { getLocalTime } from '@src/helpers/time';

export interface Props {
  record: GroupedRecords;
  timesheetId: TimesheetId | undefined;
  currentUserRole: MissionAdminRole | undefined;
  loading: LoadingState;
  updateRecord(
    sid: TimesheetId,
    key: TimesheetRecordKey,
    data: TimesheetRecordData,
  ): void;
  deleteRecord(sid: TimesheetId, key: TimesheetRecordKey): void;
  timesheetsView: 'single' | 'team' | MissionRoleId;
  disabled?: boolean;
  clientView?: boolean;
}

const useStyles = createUseStyles({
  cell: {
    paddingTop: '32px',
    paddingBottom: '32px',
    paddingRight: '24px',
    paddingLeft: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateCell: {
    width: '158px',
    flexShrink: 0,
  },
});

export default function GroupedTableRow(props: Props): ReactElement {
  //eslint-disable-next-line
  const {
    record,
    timesheetId,
    loading,
    currentUserRole,
    updateRecord,
    deleteRecord,
    timesheetsView,
    disabled,
    clientView,
  } = props;
  const styles = useStyles();

  const onEdit = (key: TimesheetRecordKey, data: TimesheetRecordData) => {
    if (timesheetId) {
      updateRecord(timesheetId, key, data);
    }
  };

  const onDelete = (key: TimesheetRecordKey) => {
    if (timesheetId) {
      deleteRecord(timesheetId, key);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        borderTop: '0.5px solid #C0C0C0',
      }}
    >
      <div className={cx(styles.cell, styles.dateCell)}>
        {format(getLocalTime(new Date(record.date)), 'EEE, LLL d')}
      </div>
      <div style={{ flex: 1 }}>
        {record.records.map((record, i) => (
          <TableRow
            key={`${record.key}${record.role.rid}`}
            record={record}
            index={i}
            currentUserRole={currentUserRole}
            loading={loading}
            onEdit={onEdit}
            onDelete={onDelete}
            timesheetsView={timesheetsView}
            disabled={disabled}
            clientView={clientView}
          />
        ))}
      </div>
    </div>
  );
}
