import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
  },
});

const MeetDeadlines = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <p>
        <strong>Meet deadlines</strong>: Timely delivery is key while building
        products. If a delay is expected, be honest and report that delay as far
        in advance as you can. A key way to handle these is by setting clear
        milestones & due dates to manage expectations.
      </p>

      <div>
        <p>
          <strong>Additional resources</strong>
        </p>

        <p>
          If you think your A.Team needs additional resources (either from
          A.Team or internally), voice this to the company. Companies often look
          to their A.Teamers to tell them what’s needed.
        </p>
      </div>
    </div>
  );
};

export default MeetDeadlines;
