import { VideoPlayer } from '@src/components/VideoPlayer';
import React from 'react';

const Welcome = (): JSX.Element => {
  return (
    <div>
      <h4>Welcome! We're psyched you're here.</h4>
      <p>
        We're building a home for the world's top builders, and we're so glad
        you're here with us!
      </p>

      <p>
        We're all about being the place for amazing people like you to get
        meaningful, high-paying work surrounded by equally accomplished and
        senior folks in the industry.
      </p>

      <p>
        In order to get you started on the right foot with A.Team, we've created
        this guide for you to get familiarized with the basics of A.Team, learn
        what we're all about, get the logistics out of the way, and hit the
        ground running on your first mission.
      </p>

      <VideoPlayer url="https://www.loom.com/share/85ce579c4d5243b4aa9e2a136451bdd8" />
    </div>
  );
};

export default Welcome;
