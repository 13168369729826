import ContractObject, {
  ContractPartyType,
  ContractType,
} from '@a_team/models/dist/ContractObject';
import MissionRole from '@a_team/models/dist/MissionRole';
import Modal, { ModalBasicProps } from '@src/components/Modal';
import { ContractView } from '@src/components/Modal/ContractModal/ContractView';
import { ModalHeader } from '@src/components/Modal/ContractModal/ModalHeader';
import SectionHeading from '@src/components/SectionHeading';
import UserAvatar from '@src/components/UserAvatar';
import { useStores } from '@src/stores';
import { format } from 'date-fns';
import React, { CSSProperties, ReactElement } from 'react';
import { useScreenClass } from 'react-grid-system';
import { createUseStyles } from 'react-jss';
import { getBuilderRate, getClientRate } from './utils';

interface Props extends ModalBasicProps {
  style?: CSSProperties;
  contract?: ContractObject;
  roles?: MissionRole[];
  isMissionManager?: boolean;
  isAdmin?: boolean;
}

const useStyles = createUseStyles({
  label: {
    fontWeight: 500,
    width: 100,
    display: 'inline-block',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    '&:last-of-type': {
      marginBottom: 24,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 4,
  },
});

export const SignedContractModal = (props: Props): ReactElement => {
  const styles = useStyles();
  const { onClose, open, contract, isMissionManager, isAdmin } = props;
  const screenClass = useScreenClass();
  const { auth } = useStores();

  const mainPartyType = auth.withContractsV2
    ? ContractPartyType.ATeam
    : ContractPartyType.BillingCustomer;
  const mainParty = contract?.parties.find(
    (party) => party.type === mainPartyType,
  );

  const member = contract?.parties.find(
    (party) => party.type === ContractPartyType.MissionRole,
  );

  const role = props.roles?.find((role) => role.rid === member?.rid);

  const platformFee =
    role?.clientHourlyRate && role?.hourlyRate
      ? (
          ((role.clientHourlyRate - role.hourlyRate) / role.clientHourlyRate) *
          100
        ).toFixed(2)
      : 0;

  const customContractCreatedDate = contract?.custom && contract.createdAt;
  const memberSignedDate = customContractCreatedDate || member?.signedAt;
  const mainPartySignedDate = customContractCreatedDate || mainParty?.signedAt;
  const clientRate = getClientRate(role);
  const builderRate = getBuilderRate(role);

  return (
    <Modal
      onClose={onClose}
      open={open}
      style={{
        width: screenClass === 'xs' ? '95%' : 800,
        display: 'flex',
        flexDirection: 'column',
        ...props.style,
      }}
    >
      {!contract ? (
        <div style={{ width: '100%', flexGrow: 1 }}>Loading...</div>
      ) : (
        <>
          <ModalHeader
            title={`Service Agreement between ${mainParty?.address.name} and A.Teamer`}
            subtitle={'A.Team'}
          />
          {contract.type !== ContractType.TermsOfService && (
            <p className={styles.section}>
              <SectionHeading isFirst>A.Teamer:</SectionHeading>
              <span className={styles.row}>
                <div style={{ display: 'flex', marginBottom: 8 }}>
                  <UserAvatar src={member?.user?.profilePictureURL} size={40} />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: 8,
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>
                      {member?.user?.fullName}
                    </span>
                    <span>{role?.category.title}</span>
                  </div>
                </div>
              </span>
              <span className={styles.row}>
                <span className={styles.label}>
                  {role?.isFullTimeRetainer ? 'Monthly' : 'Hourly'} rate:
                </span>
                {!contract.custom && (
                  <>
                    {isMissionManager ? `$${clientRate}` : `$${builderRate}`}
                    {isAdmin && (
                      <>
                        &nbsp;(Billed to client: ${clientRate}, A.Team service
                        fee ${platformFee}%)
                      </>
                    )}
                  </>
                )}
              </span>
              <span>
                <span className={styles.label}>Signed:</span>
                {memberSignedDate &&
                  format(new Date(memberSignedDate), 'PPPPpppp')}
              </span>
            </p>
          )}
          {mainParty && (
            <p className={styles.section}>
              <SectionHeading isFirst>
                {auth.withContractsV2 ? 'A.Team' : 'Client'}:
              </SectionHeading>
              <span className={styles.row}>{mainParty?.address?.name}</span>
              <span className={styles.row}>
                <span className={styles.label}>Mission:</span> A.Team for{' '}
                {mainParty?.address.name}
              </span>
              <span>
                <span className={styles.label}>Signed:</span>
                {mainPartySignedDate &&
                  format(new Date(mainPartySignedDate), 'PPPPpppp')}
              </span>
            </p>
          )}
        </>
      )}
      {contract?.downloadURL && <ContractView src={contract?.downloadURL} />}
    </Modal>
  );
};
