import { Checkbox, Typography } from '@a_team/ui-components';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import ErrorForm from '../common/ErrorForm';
import CommonStyles from '../common/styles';

const useStyles = createUseStyles({
  content: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
  },
});

const HasZeroToOneExperience = (): JSX.Element => {
  const {
    register,
    unregister,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const commonStyles = CommonStyles();
  const styles = useStyles({});

  useEffect(() => {
    register('hasZeroToOneExperience', {
      validate: (hasZeroToOneExperience: boolean | undefined) => {
        return true;
      },
    });

    return () => {
      unregister('hasZeroToOneExperience');
    };
  }, [register, unregister]);

  const hasZeroToOneExperience = watch('hasZeroToOneExperience');

  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.title}>
        Were you involved from inception to launch (0 {'->'} 1)? (Optional)
      </div>
      <div className={commonStyles.description}>
        Zero to one is creation and development of a unique product from the
        ground up.
      </div>
      <div className={styles.content}>
        <Checkbox
          onChange={(e) => {
            setValue('hasZeroToOneExperience', !hasZeroToOneExperience);
          }}
          checked={!!hasZeroToOneExperience}
          label={
            <Typography
              variant={'textMedium'}
              weight={'light'}
              style={{ marginLeft: 8, fontSize: 15 }}
            >
              I was involved in zero to one with this project
            </Typography>
          }
        />
      </div>
      <ErrorForm field="hasZeroToOneExperience" errors={errors} />
    </div>
  );
};

export default HasZeroToOneExperience;
