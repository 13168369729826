import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import UserAvatar from '../UserAvatar';
import { createUseStyles } from 'react-jss';

interface Props {
  user: BasicUserObject;
  title?: string;
  clickable?: boolean;
}

const useStyles = createUseStyles({
  title: {
    fontSize: '15px',
    lineHeight: '24px',
    color: '#62646A',
  },
});

const ExperienceMember = (props: Props): ReactElement => {
  const { user, title, clickable } = props;
  const styles = useStyles();

  return (
    <div style={{ paddingBottom: '24px' }}>
      <Link
        style={{
          textDecoration: 'none',
          color: 'inherit',
          // without basicAccess user can't see other users profile
          pointerEvents: clickable ? 'auto' : 'none',
        }}
        to={`/${user.username}`}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <UserAvatar
            src={user.profilePictureURL}
            containerStyle={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              marginLeft: '24px',
            }}
          >
            <div>{user.fullName}</div>
            <div className={styles.title}>{title || ''}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ExperienceMember;
