import React from 'react';
import { createUseStyles } from 'react-jss';
import { Icon, IconType } from '@ateams/components';
import { BasicVettingProcessColumnProps } from '..';
import { LinkType } from '@a_team/models/dist/vetting-processes/pre-vetting';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    cursor: 'pointer', // override Icon cursor
  },
  spacing: {
    width: '6px',
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export interface CodeSampleProps {
  codeSampleURL: string;
  codeSampleLinkType?: LinkType;
}

export const CodeSample: React.FC<CodeSampleProps> = (props) => {
  const { codeSampleURL, codeSampleLinkType } = props;
  const styles = useStyles();

  const isDownloadType = codeSampleLinkType === LinkType.Download;

  return (
    <a
      href={codeSampleURL}
      target="_blank"
      rel="noreferrer"
      className={styles.container}
    >
      <Icon
        type={isDownloadType ? IconType.OrangeDownload : IconType.Link}
        className={styles.icon}
      />
      <div className={styles.spacing} />
    </a>
  );
};

export const CodeSampleField: React.FC<BasicVettingProcessColumnProps> = ({
  vettingProcess,
}) => {
  const styles = useStyles();

  if (vettingProcess.preVetting && 'codeSample' in vettingProcess.preVetting) {
    return (
      <CodeSample
        codeSampleURL={vettingProcess.preVetting.codeSample}
        codeSampleLinkType={vettingProcess.preVetting.codeSampleLinkType}
      />
    );
  }

  if (vettingProcess.preVettingForm) {
    return (
      <div>
        <div className={styles.iconsContainer}>
          {vettingProcess.preVettingForm.additionalMaterialFiles &&
            vettingProcess.preVettingForm.additionalMaterialFiles.map(
              (additionalMaterialFileUrl) => (
                <CodeSample
                  key={additionalMaterialFileUrl}
                  codeSampleURL={additionalMaterialFileUrl}
                  codeSampleLinkType={LinkType.Download}
                />
              ),
            )}
        </div>
        <div className={styles.iconsContainer}>
          {vettingProcess.preVettingForm.additionalMaterialLinks &&
            vettingProcess.preVettingForm.additionalMaterialLinks.map(
              (additionalMaterialFileUrl) => (
                <CodeSample
                  key={additionalMaterialFileUrl}
                  codeSampleURL={additionalMaterialFileUrl}
                  codeSampleLinkType={LinkType.URL}
                />
              ),
            )}
        </div>
      </div>
    );
  }

  return null;
};
