import React, { ReactElement, useMemo } from 'react';
import {
  AdminMissionApplicationObject,
  MissionApplicationId,
} from '@a_team/models/dist/MissionApplicationObject';
import { createUseStyles } from 'react-jss';
import ApplicantPill from '@src/components/ApplicantPill';
import cx from 'classnames';
import {
  ApplicantsSortByOptions,
  getFilteredAndSortedApplications,
} from '@src/helpers/applications';
import { useStores } from '@src/stores';
import {
  Spacing,
  FontSizes,
  FontWeights,
  Icon,
  IconType,
} from '@ateams/components';

interface Props {
  applications: AdminMissionApplicationObject[];
  onApplicationClick: (application: AdminMissionApplicationObject) => void;
  onDrag: (aid: MissionApplicationId) => void;
}

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 30,
    flexWrap: 'wrap',
  },
  applicantsWrapper: {
    maxHeight: 400,
    overflowY: 'auto',
  },
  applicantsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.xsmall,
  },
  starredContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
    alignItems: 'center',
  },
  starredIcon: {
    width: 24,
    height: 24,
  },
  applicantsTitle: {
    paddingTop: Spacing.xsmall,
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.semiBold,
  },
});

export const Applicants = (props: Props): ReactElement => {
  const styles = useStyles();
  const stores = useStores();
  const { auth } = stores;
  const { applications, onApplicationClick, onDrag } = props;

  const filteredApplications = useMemo(() => {
    return getFilteredAndSortedApplications(
      applications,
      ApplicantsSortByOptions.ApplicationStatus,
      auth.withMissionApplicationStatusV2,
    );
  }, [applications]);

  const getApplicantsList = (
    filteredApplications: AdminMissionApplicationObject[] | null | undefined,
  ) => {
    return filteredApplications?.map((application) => {
      return (
        <ApplicantPill
          key={application.aid}
          application={application}
          onClick={() => onApplicationClick(application)}
          draggable
          onDragStart={() => onDrag(application.aid)}
        />
      );
    });
  };

  const starredApplications = useMemo(() => {
    return filteredApplications?.filter((a) => a.starred) ?? [];
  }, [filteredApplications]);

  const regularApplications = useMemo(() => {
    return filteredApplications?.filter((a) => !a.starred) ?? [];
  }, [filteredApplications]);

  return (
    <div className={cx(styles.row, styles.applicantsWrapper)}>
      {starredApplications.length > 0 ? (
        <div className={styles.applicantsContainer}>
          <div className={styles.starredContainer}>
            <Icon
              type={IconType.StarredBuilder}
              size="exact"
              className={styles.starredIcon}
            />
            <div className={styles.applicantsTitle}>Starred by the client</div>
          </div>
          <div>{getApplicantsList(starredApplications)}</div>
          <div className={styles.applicantsTitle}>Regular applicants</div>
          <div>{getApplicantsList(regularApplications)}</div>
        </div>
      ) : (
        <>{getApplicantsList(filteredApplications)}</>
      )}
    </div>
  );
};
