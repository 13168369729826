import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  VettingProcessAudit,
  VettingProcessAuditTypes,
} from '@a_team/models/dist/vetting-process-audit';
import { ActivityRecordMainText } from '.';
import { Colors } from '@ateams/components';
import { format } from 'date-fns';

export interface InterviewDateSetActivityRecordProps {
  vettingProcessAudit: VettingProcessAudit<VettingProcessAuditTypes.InterviewDateSet>;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainText: {
    color: Colors.secondaryDark,
  },
});

export const InterviewDateSetActivityRecord: React.FC<
  InterviewDateSetActivityRecordProps
> = ({ vettingProcessAudit }) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <ActivityRecordMainText className={styles.mainText}>
        {vettingProcessAudit.payload.interviewDate
          ? `Interview date set for ${format(
              new Date(vettingProcessAudit.payload.interviewDate),
              'MMMM dd, yyyy',
            )}`
          : 'Interview date cleared'}
      </ActivityRecordMainText>
    </div>
  );
};
