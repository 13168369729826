import React from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import copy from 'copy-to-clipboard';
import { theme } from '@a_team/ui-components';
import { FontWeights, Spacing } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import {
  VettingDashboardGuidebookLocation,
  getVettingFeedbackFormViewModeLocation,
} from '@src/locations';
import useLoadingState from '@src/hooks/useLoadingState';
import { Button } from '../components/button';
import { XMarkIcon } from '../components/icons/x-mark';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { Size, Text } from '../components/typography';

export interface VettingFeedbackFormHeaderProps {
  profileURL: string;
  avatarSrc: string;
  fullName: string;
  vettingProcessId: string;
  onClose: () => void;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  titlesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '40px',
  },
  title: {
    marginBottom: Spacing.small,
  },
  subTitle: {
    fontWeight: FontWeights.regular,
  },
  avatarAndButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  avatarTitlesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: Spacing.medium,
  },
  avatarLabel: {
    fontSize: '24px',
    fontWeight: '500',
    color: '#222222',
    lineHeight: '30px',
  },
  avatarTitle: {
    fontWeight: FontWeights.regular,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    height: '40px',
    padding: 0,
  },
  shareFormButton: {
    width: '105px',
    background: theme.colors.Grey[200],
    border: 'none',
  },
  closeButton: {
    width: '94px',
    marginLeft: Spacing.medium,
  },
});

export const VettingFeedbackFormHeader: React.FC<
  VettingFeedbackFormHeaderProps
> = ({
  profileURL,
  avatarSrc,
  fullName,
  vettingProcessId,
  onClose,
  className,
}) => {
  const styles = useStyles();
  const [loading, setLoading] = useLoadingState();

  const onShareForm = () => {
    const url =
      window.location.origin +
      getVettingFeedbackFormViewModeLocation(vettingProcessId);

    copy(url);
    setLoading(Promise.resolve(new Date()), 'URL copied to clipboard');
  };

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.titlesContainer}>
        <Text size={Size.ExtraLarge} className={styles.title}>
          Evaluation feedback on builder
        </Text>
        <Text className={styles.subTitle}>
          Use this form to document your builder evaluation call. Your feedback
          helps us maintain a high bar for A.Team’s network.
          <br />
          For more guidance on how to score builders, please take a look{' '}
          <Link to={VettingDashboardGuidebookLocation} target="_blank">
            here
          </Link>
        </Text>
      </div>

      <div className={styles.avatarAndButtonsContainer}>
        <Link
          to={{ pathname: profileURL }}
          target="_blank"
          className={styles.avatarContainer}
        >
          <UserAvatar src={avatarSrc} size={64} />
          <div className={styles.avatarTitlesContainer}>
            <Text size={Size.Small} className={styles.avatarTitle}>
              Builder being evaluated
            </Text>
            <div className={styles.avatarLabel}>{fullName}</div>
          </div>
        </Link>

        <div className={styles.buttonsContainer}>
          <Button
            className={cx(styles.button, styles.shareFormButton)}
            noColor
            onClick={onShareForm}
          >
            Share form
          </Button>

          <Button
            className={cx(styles.button, styles.closeButton)}
            endAdornment={<XMarkIcon />}
            noColor
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </div>

      <LoadingIndicator key={new Date().toISOString()} loading={loading} />
    </div>
  );
};
