import { rawTimeZones, RawTimeZone } from '@vvo/tzdb';
import { Option } from 'react-select/src/filters';
import React, { ReactElement, useCallback, useMemo } from 'react';
import Select, { SelectOption, SelectProps } from './';
import TimezoneObject from '@a_team/models/dist/TimezoneObject';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getZoneOffset } from '@vvo/tzdb/dist/utils/timeZone';
import { getGMTFromMinutesOffset } from '@ateams/react-utils/dist/helpers/timezone';
export interface TimezoneNameSelectProps extends Omit<SelectProps, 'onChange'> {
  onChange(data: TimezoneObject | TimezoneObject[] | undefined): void;
  value?: string | string[];
  readonly?: boolean;
}

const filterOptions = function (option: Option, input: string): boolean {
  const { data, label } = option;
  const { countryName, group, mainCities, name } = data;

  if (!input) return true;

  return !![countryName, ...group, ...mainCities, name, label].find(
    (searchable) => {
      return (
        input &&
        searchable &&
        searchable.toLowerCase().includes(input.toLocaleLowerCase())
      );
    },
  );
};

export const TimezoneNameSelect = (
  props: TimezoneNameSelectProps,
): ReactElement => {
  const { onChange, value: timezone, options, className, ...rest } = props;

  const customOptionsFilter = useCallback(filterOptions, []);

  const timezoneList = useMemo<(RawTimeZone & Option)[]>(() => {
    if (options) return options;

    return rawTimeZones.map(({ name, ...rest }, value) => ({
      label: `${name} - ${getGMTFromMinutesOffset(getZoneOffset(name) * -1)}`,
      value: name,
      name,
      ...rest,
    }));
  }, [rawTimeZones, options]);

  const selectedValue = useMemo(() => {
    if (!timezone) return null;

    if (Array.isArray(timezone)) {
      return timezoneList.filter(({ name }) =>
        timezone.find((tz) => tz === name),
      );
    } else {
      return timezoneList.find(({ name }) => name === timezone) || null;
    }
  }, [timezoneList, timezone]);

  const handleTimezoneNameSelect = (selected: SelectOption | null) => {
    if (selected) {
      const name = selected.value;

      onChange({ name, utcOffset: getZoneOffset(name) * -1 });
    } else {
      onChange(undefined);
    }
  };

  const handleMultiSelect = (selected: SelectOption[] | null) => {
    if (selected) {
      const selectedIndexes = selected.map(({ value }) => Number(value));
      const selectedTimezones = timezoneList.filter((_, index) =>
        selectedIndexes.includes(index),
      );

      onChange(
        selectedTimezones.map(({ name }) => {
          return {
            name,
            utcOffset: getZoneOffset(name) * -1,
          };
        }),
      );
    } else {
      onChange([]);
    }
  };

  return (
    <Select
      value={selectedValue && selectedValue}
      filterOption={customOptionsFilter}
      options={timezoneList}
      className={className}
      onChange={(val) =>
        Array.isArray(val)
          ? handleMultiSelect(val)
          : handleTimezoneNameSelect(val)
      }
      {...rest}
    />
  );
};
