import { useState } from 'react';
import { isPromiseLike } from '@src/hooks/utils';
import { ServiceError } from '@ateams/api/dist/endpoints/utils';

export type SuccessMessage = string;
export type LoadingState = boolean | Error | SuccessMessage | null;

export type SetLoading = (
  state: LoadingState | PromiseLike<SuccessMessage | unknown>,
  resolveTo?: LoadingState,
) => void;

export default function useLoadingState(
  initState: LoadingState = null,
  forceState?: LoadingState,
): [LoadingState, SetLoading, ServiceError | null] {
  const [state, setState] = useState(initState);

  const setLoading: SetLoading = (state, resolveTo): void => {
    if (isPromiseLike(state)) {
      setState(true);

      state.then(
        (res) =>
          setState(
            resolveTo !== undefined
              ? resolveTo
              : (typeof res === 'string' && res) || false,
          ),
        setState,
      );
      return;
    }

    setState(state);
  };

  const error: ServiceError | null =
    state && typeof state !== 'string' && state !== true ? state : null;

  return [forceState || state, setLoading, error];
}
