import React, { ReactElement } from 'react';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import AdminTeamWorkMissionsList from '../AdminMissionsList/AdminMissionsList';

function AdminTeamWorkPendingMissions(): ReactElement {
  const { missions } = useStores();

  return (
    <AdminTeamWorkMissionsList
      loadMore={() => missions.getAdminPendingMissions(true)}
      showMore={!!missions.adminNextTokens.pendingMissions}
      type={'archived'}
      title={'Pending missions'}
      missions={missions.adminViewMissions.pendingMissions || []}
    />
  );
}

export default observer(AdminTeamWorkPendingMissions);
