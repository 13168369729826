import React from 'react';
import RankingParam from './RankingParam';

type RankingParams = {
  [key: string]: number | undefined;
};

type Props = {
  params: RankingParams;
  setFilterValue: (filter: Partial<RankingParams>) => void;
};

const splitCamelCaseAndCapitalize = (str: string) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^./, (str) => str.toUpperCase());
};

const RankingParamsList = ({ params, setFilterValue }: Props) => {
  return (
    <div>
      {Object.entries(params).map(([key, value]) => (
        <RankingParam
          key={key}
          numericValue={value || undefined}
          name={splitCamelCaseAndCapitalize(key)}
          onChange={(newValue) =>
            setFilterValue({ [key]: newValue || undefined })
          }
        />
      ))}
    </div>
  );
};

export default RankingParamsList;
