import qs from 'query-string';

export interface OAuthResponse {
  code: string;
}

export class OAuth {
  private externalWindow: Window;
  private timer: number;

  constructor() {
    const width = Math.min(500, window.outerWidth);
    const height = Math.min(window.outerHeight * 0.8, 800);
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;

    const externalWindow = window.open(
      '',
      '',
      `width=${width},height=${height},left=${left},top=${top}`,
    );
    if (!externalWindow) {
      throw new Error(`Failed to open a popup, please check browser settings`);
    }

    this.externalWindow = externalWindow;
    this.timer = 0;
  }

  close() {
    clearInterval(this.timer);
    this.externalWindow.close();

    this.timer = 0;
  }

  exchange(redirectURL: string): Promise<OAuthResponse> {
    if (this.timer) {
      clearInterval(this.timer);
    }

    this.externalWindow.location.assign(redirectURL);

    return new Promise((resolve, reject): void => {
      this.timer = window.setInterval((): void => {
        if (this.externalWindow.closed) {
          clearInterval(this.timer);
          reject(new Error(`Popup closed by the user`));
          return;
        }

        try {
          if (this.externalWindow.location.origin !== window.location.origin) {
            return;
          }
        } catch (e) {
          // ignore errors when accessing `externalWindow` location
          return;
        }

        const { search } = this.externalWindow.location;
        const params = qs.parse(search);
        this.close();

        if (typeof params.code !== 'string') {
          reject(
            new Error(
              String(
                params.error_description ||
                  params.error ||
                  `Invalid response: ${search}`,
              ),
            ),
          );
          return;
        }

        resolve({ code: params.code });
      }, 100);
    });
  }
}
