import { ServiceEndpoint } from './utils';
import { ServiceAuth } from '@ateams/service-utils';
import { BasicPartnerObject } from '@a_team/models/dist/Partners';

export const BasePath = '/partners';

export interface PartnerPayload {
  name: string;
  logoURL?: string;
}

export default class PartnersEndpoint extends ServiceEndpoint {
  /***
   * Creates a new Partner record
   * Used by Retool partners dashboard
   */
  public adminCreatePartner(
    auth: ServiceAuth,
    request: PartnerPayload,
  ): Promise<BasicPartnerObject> {
    return this.fetch(auth, BasePath, null, 'post', request);
  }
}
