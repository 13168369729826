import { createQueryKeys } from '@lukemorales/query-key-factory';

export const placesKeys = createQueryKeys('places', {
  useGetPlacesSuggestions: (searchTerm: string) => ({
    searchTerm,
  }),
  useGetPlaceById: (placeId: string) => ({
    placeId,
  }),
});
