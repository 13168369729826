import React from 'react';
import { createUseStyles } from 'react-jss';
import copy from 'copy-to-clipboard';
import { Icon, IconType, Spacing } from '@ateams/components';
import {
  BasicVettingProcess,
  VettingProcessUser,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import { BasicVettingProcessColumnProps } from '..';

const useStyles = createUseStyles({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: Spacing.xsmall,
  },
  iconContainer: {
    cursor: 'pointer',
  },
  icon: {
    cursor: 'pointer',
  },
});

export const BuilderInfoField: React.FC<BasicVettingProcessColumnProps> = ({
  vettingProcess,
}) => {
  const styles = useStyles();
  const url = formatLinkedinUrl(vettingProcess.user.linkedIn);

  if (!url) {
    return null;
  }

  return (
    <div className={styles.container}>
      <LinkedInIconButton linkedIn={vettingProcess.user.linkedIn} />

      {vettingProcess.user.websites?.map((website) => (
        <WebsiteIconButton key={website} website={website} />
      ))}

      <EmailIconButton email={vettingProcess.user.email} />
    </div>
  );
};

export function formatLinkedinUrl(
  linkedIn: BasicVettingProcess['user']['linkedIn'],
) {
  if (!linkedIn) {
    return '';
  }

  return `https://www.linkedin.com/in/${linkedIn.username}/`;
}

const LinkedInIconButton = ({
  linkedIn,
}: {
  linkedIn: VettingProcessUser['linkedIn'];
}) => {
  const styles = useStyles();
  const url = formatLinkedinUrl(linkedIn);

  if (!url) {
    return null;
  }

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={styles.iconContainer}
    >
      <Icon type={IconType.LinkedIn} className={styles.icon} />
    </a>
  );
};

const WebsiteIconButton = ({ website }: { website: string }) => {
  const styles = useStyles();

  return (
    <a
      href={website}
      target="_blank"
      rel="noreferrer"
      className={styles.iconContainer}
    >
      <Icon type={IconType.Website} className={styles.icon} />
    </a>
  );
};

const EmailIconButton = ({ email }: { email: string }) => {
  const styles = useStyles();
  const [loading, setLoading] = useLoadingState();

  const onClick = () => {
    copy(email);
    setLoading(Promise.resolve(new Date()), 'Email copied to clipboard');
  };

  return (
    <>
      <Icon onClick={onClick} type={IconType.Mail} className={styles.icon} />

      <LoadingIndicator key={new Date().toISOString()} loading={loading} />
    </>
  );
};
