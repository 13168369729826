import {
  Breakpoints,
  Button as CallToActionButton,
  Icon,
  IconType,
  TextColors,
} from '@ateams/components';
import ExpertiseSelector from '@src/components/ExpertiseSelector';
import SectionHeading from '@src/components/SectionHeading';
import { Expertise } from '@src/stores/Profile/models';
import React, { ReactElement, ReactNode, useMemo, useState } from 'react';
import { useScreenClass } from 'react-grid-system';
import { createUseStyles } from 'react-jss';
import Modal, { ModalBasicProps } from '..';

interface Props extends ModalBasicProps {
  title?: string;
  onSubmit: (reqSkills: Expertise[] | []) => void;
  onSubmitWithoutUpdating: () => void;
  onSearch: (query: string) => Promise<Expertise[]>;
  selectedRequiredSkills?: Expertise[];
  description?: ReactNode;
  sideDescription?: ReactNode;
  userMissingLocation?: boolean;
}

const useStyles = createUseStyles({
  modal: {
    display: 'flex',
    padding: '40px 14px',
  },
  title: {
    fontSize: 17,
  },
  main: {
    width: '100%',
    overflow: 'visible',
    padding: '20px 5px',
    borderRadius: 10,
  },
  arrowWrapper: {
    color: TextColors.regularLight,
    fontWeight: 500,
    fontSize: 15,
    cursor: 'pointer',
  },
  legend: {
    padding: 0,
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '& > button': {
      width: '100%',
      height: 'auto',
    },
  },
  ContinueWithoutUpdating: {
    color: '#FFFFFF',
  },
  cancelApplication: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'center',
    '& > button': {
      background: 'white',
      color: TextColors.regularLight,
      padding: 0,
    },
  },
  description: {
    display: 'block',
    marginTop: 15,
    marginBottom: 15,
    fontSize: 15,
    '& span': {
      fontWeight: 'bold',
    },
  },
  expertises: { overflow: 'auto', height: 410 },
  sideDescription: {
    display: 'block',
    marginTop: 55,
    marginBottom: 95,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '100% !important',
      padding: '0px !important',
    },
    title: {
      fontSize: 28,
    },
    main: {
      minWidth: '50%',
      flex: 1,
      padding: 24,
    },
    buttonBox: {
      flexDirection: 'row',
    },
    cancelApplication: {
      marginTop: '28px',
    },
    expertises: { height: 510 },

    legend: {
      padding: '24px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
});

const ApplyLowProficiencyModal = (props: Props): ReactElement => {
  const styles = useStyles();
  const screenClass = useScreenClass();
  const {
    onClose,
    onSubmit,
    onSubmitWithoutUpdating,
    onSearch,
    title,
    selectedRequiredSkills,
    sideDescription,
    description,
    userMissingLocation,
    ...left
  } = props;
  const [page, setPage] = useState(0);
  const [localReqItems, setLocalReqItems] = useState<Expertise[]>(
    selectedRequiredSkills || [],
  );

  const isFirstPage = page === 0;
  const buttonLabels = {
    next: 'Continue & Update Skills',
    continue: 'Continue',
    submit: 'Request to Join',
  };

  const computeButton = () => {
    if (
      isFirstPage &&
      selectedRequiredSkills &&
      selectedRequiredSkills?.length > 0
    ) {
      return 'next';
    } else {
      return 'submit';
    }
  };

  const rightButton = computeButton();

  const isValid = useMemo(() => {
    if (rightButton === 'submit') {
      if (localReqItems && localReqItems.length > 0) {
        if (
          localReqItems.find(
            (item) => item.rating === undefined || item.rating < 3,
          )
        ) {
          return false;
        }
      }
    }
    return true;
  }, [localReqItems, rightButton]);

  const handleClick = () => {
    if (rightButton === 'submit') {
      setPage(0);

      onSubmit(localReqItems);
    } else if (rightButton === 'next') {
      setPage(page + 1);
    }
  };

  const mainButtonText = useMemo(() => {
    if (rightButton === 'submit') {
      if (userMissingLocation) {
        return buttonLabels.continue;
      } else {
        return buttonLabels.submit;
      }
    } else {
      return buttonLabels.next;
    }
  }, [rightButton, userMissingLocation, buttonLabels]);

  const handleOnClose = () => {
    setPage(0);
    onClose();
  };

  const goBack = () => {
    setPage(page - 1);
  };

  const handleOnSubmitWithoutUpdating = () => {
    setPage(0);
    onSubmitWithoutUpdating();
  };

  return (
    <Modal
      onClose={handleOnClose}
      hideCloseButton={true}
      className={styles.modal}
      style={{
        flexDirection:
          !isFirstPage && screenClass !== 'sm' && screenClass !== 'xs'
            ? 'row'
            : 'column',
        maxWidth: !isFirstPage ? 845 : 600,
      }}
      {...left}
    >
      <div
        className={styles.main}
        style={{ background: !isFirstPage ? '#F7F7F7' : '#FFFFF' }}
      >
        {title && isFirstPage && (
          <SectionHeading className={styles.title} isFirst>
            {title}
          </SectionHeading>
        )}
        {!isFirstPage && (
          <div className={styles.arrowWrapper} onClick={goBack}>
            <Icon
              type={IconType.ArrowBack}
              muted
              size={'medium'}
              style={{ opacity: 1, width: 22 }}
            />
            <span>Back</span>
          </div>
        )}
        {!isFirstPage && (
          <div className={styles.expertises}>
            {selectedRequiredSkills && selectedRequiredSkills?.length > 0 && (
              <ExpertiseSelector
                onSearch={onSearch}
                hideSearch={true}
                onChange={setLocalReqItems}
                selectedItems={selectedRequiredSkills}
                displayType={'score'}
                sectionTitle={'Required Skills'}
                withFeaturedCheckbox={false}
                limitAnswersHeight={false}
              />
            )}
          </div>
        )}
        {description && isFirstPage && (
          <div className={styles.description}>{description}</div>
        )}
      </div>
      <div
        className={styles.legend}
        style={{ ...(isFirstPage && { width: '100%' }) }}
      >
        {!isFirstPage && sideDescription && (
          <div className={styles.sideDescription}>{sideDescription}</div>
        )}
        <div className={styles.buttonBox}>
          <CallToActionButton
            width="auto"
            className={styles.ContinueWithoutUpdating}
            onClick={handleOnSubmitWithoutUpdating}
            color="regular"
            size="small"
          >
            Continue Without Updating
          </CallToActionButton>
          <CallToActionButton
            width="auto"
            onClick={handleClick}
            disabled={!isValid}
            size="small"
            style={rightButton === 'next' ? { alignSelf: 'flex-end' } : {}}
          >
            {mainButtonText}
          </CallToActionButton>
        </div>
        <div className={styles.cancelApplication}>
          <CallToActionButton
            width="auto"
            onClick={handleOnClose}
            disabled={!isValid}
            size="small"
          >
            Cancel Application
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
};

export default ApplyLowProficiencyModal;
