import { Icon, IconType, TextColors } from '@ateams/components';
import React, { useMemo, useState } from 'react';
import * as uploadcareApi from '@src/logic/uploadcare';

import { createUseStyles } from 'react-jss';
import { FileRejection, FileWithPath } from 'react-dropzone';
import { useSetCurrentUserCV } from '@src/rq/profile';
import useLoadingState from '@src/hooks/useLoadingState';
import FileDropArea from '@src/components/FileDropArea';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { SidebarSection } from '../SidebarSection';
import plus from './plus.svg';
import cx from 'classnames';
import { isNil } from 'lodash';
import { UserCV } from '@src/stores/Profile/Profile';
import { useEnhanceProfileWithPolling } from '@src/rq/profileSuggestions';

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginTop: 16,
  },
  textButton: {
    fontSize: '15px',
    fontWeight: 400,
    height: '24px',
    color: TextColors.primaryLight,
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9,
    },
    '& + div': {
      width: 'auto',
    },
  },
  removeIcon: {
    padding: 0,
  },
  uploader: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  plus: {
    width: 32,
    height: 32,
    borderRadius: 100,
    overflow: 'hidden',
    backgroundImage: `url(${plus})`,
    color: 'white',
    fontSize: 18,
    textAlign: 'center',
    lineHeight: '28px',
  },
  prompt: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
    color: '#222',

    '& span': {
      color: '#62646A',
      fontSize: '14px',
      fontWeight: 400,
    },
  },
});

interface CVUploaderProps {
  canView: boolean;
  canUpload: boolean;
  cv: UserCV;
  onRemove: () => void;
  onUpload: (cvUrl: string) => void;
}

export default function CVUploader(props: CVUploaderProps) {
  const styles = useStyles();
  const [uploading, setUploading] = useLoadingState(null);
  const [cv, setCV] = useState<UserCV>(props.cv);
  const { mutate: uploadCV } = useSetCurrentUserCV();

  const readOnly = useMemo(() => {
    return props.canView && !props.canUpload;
  }, [props.canUpload, props.canView]);

  const { initiateSuggestionsPolling } = useEnhanceProfileWithPolling();

  const handleFileSelected = async (
    files: Array<FileWithPath>,
    rejectedFiles: Array<FileRejection>,
  ) => {
    try {
      setUploading(null);

      if (rejectedFiles.length > 0) {
        return setUploading(new Error(rejectedFiles[0].errors[0].message));
      }

      if (!files.length) {
        return;
      }

      const [file] = files;

      setUploading(true);

      const downloadUrl = await uploadcareApi.uploadFile(
        new File([file], file.name, { type: file.type }),
      );

      await uploadCV(downloadUrl);

      props.onUpload(downloadUrl);

      setCV({
        ...cv,
        downloadUrl,
      });

      setUploading('Resume Uploaded');
      setUploading(null);
      initiateSuggestionsPolling();
    } catch (err) {
      setUploading(err as Error);
    }
  };

  const handleRemove = () => {
    setCV({
      ...cv,
      downloadUrl: undefined,
    });
    props.onRemove();
  };

  if (readOnly && !cv.downloadUrl) {
    return null;
  }

  if (!props.canUpload && !readOnly) {
    return null;
  }

  return (
    <SidebarSection title={'Resume'} readonly>
      <div className={styles.wrapper}>
        {cv.downloadUrl && (
          <a
            href={cv.downloadUrl}
            target="_blank"
            className={styles.textButton}
            rel="noopener noreferrer"
          >
            {cv.label}
          </a>
        )}
        {cv.downloadUrl && !readOnly && (
          <Icon
            className={styles.removeIcon}
            type={IconType.Close}
            size={'xsmall'}
            onClick={handleRemove}
          />
        )}
        {!cv.downloadUrl && !readOnly && (
          <>
            <FileDropArea
              disabled={!isNil(uploading)}
              accept={['application/pdf']}
              onFileDrop={handleFileSelected}
            >
              {({ openDialog }) => (
                <div
                  className={cx(styles.uploader, {
                    isLoading: !isNil(uploading),
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    openDialog();
                  }}
                >
                  <div className={styles.plus}>
                    <span>+</span>
                  </div>
                  <div className={styles.prompt}>
                    Upload your Resume
                    <span>PDF file format</span>
                  </div>
                </div>
              )}
            </FileDropArea>
            <LoadingIndicator
              successMsg={'Done'}
              loadingMsg={'Uploading Resume'}
              loading={uploading}
            />
          </>
        )}
      </div>
    </SidebarSection>
  );
}
