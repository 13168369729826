import React, { ReactElement } from 'react';
import TabButtons, { TabNavLink } from '@src/components/TabButtons';
import {
  AdminTeamWorkCreatedMissions,
  AdminTeamWorkPublishedMissions,
  AdminTeamWorkPendingMissions,
  AdminTeamWorkRunningMissions,
  AdminTeamWorkArchivedMissions,
  AdminTeamWorkEndedMissions,
  AdminTeamWorkScheduledToEndMissions,
} from '@src/locations';

const AdminTeamWorkNavBar = (): ReactElement => {
  const tabs = [
    {
      label: `Created`,
      to: AdminTeamWorkCreatedMissions,
    },
    {
      label: `Published`,
      to: AdminTeamWorkPublishedMissions,
    },
    {
      label: `Pending`,
      to: AdminTeamWorkPendingMissions,
    },
    {
      label: `Running`,
      to: AdminTeamWorkRunningMissions,
    },

    {
      label: `ScheduledToEnd`,
      to: AdminTeamWorkScheduledToEndMissions,
    },
    {
      label: `Ended`,
      to: AdminTeamWorkEndedMissions,
    },
    {
      label: `Archived`,
      to: AdminTeamWorkArchivedMissions,
    },
  ];

  return (
    <TabButtons style={{ boxShadow: 'none', marginBottom: 30 }}>
      {tabs.map(
        (tab, i) =>
          tab && (
            <TabNavLink key={i} to={tab.to}>
              <>{tab.label}</>
            </TabNavLink>
          ),
      )}
    </TabButtons>
  );
};

export default AdminTeamWorkNavBar;
