import { Modal, ModalWidth, Textarea } from '@a_team/ui-components';
import {
  RoleOptimizationRequestReason,
  RoleOptimizationRequestReasonLabels,
} from '@a_team/models/dist/RoleOptimizationRequest';
import React, { ReactElement, useState } from 'react';
import {
  BorderColors,
  BorderRadius,
  Button,
  Checkbox,
  FontSizes,
  Paddings,
  Spacing,
  TextColors,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onConfirm: (data: {
    reasons: RoleOptimizationRequestReason[];
    details: string;
  }) => void;
}

const reasons = [
  {
    label:
      RoleOptimizationRequestReasonLabels[
        RoleOptimizationRequestReason.CompanyDescriptionLacking
      ],
    name: RoleOptimizationRequestReason.CompanyDescriptionLacking,
    requireDetails: true,
  },
  {
    label:
      RoleOptimizationRequestReasonLabels[
        RoleOptimizationRequestReason.HourlyRateNeedsClarification
      ],
    name: RoleOptimizationRequestReason.HourlyRateNeedsClarification,
    requireDetails: true,
  },
  {
    label:
      RoleOptimizationRequestReasonLabels[
        RoleOptimizationRequestReason.MissionDescriptionLacking
      ],
    name: RoleOptimizationRequestReason.MissionDescriptionLacking,
    requireDetails: true,
  },
  {
    label:
      RoleOptimizationRequestReasonLabels[
        RoleOptimizationRequestReason.AvailabilityNeedsClarification
      ],
    name: RoleOptimizationRequestReason.AvailabilityNeedsClarification,
    requireDetails: true,
  },
  {
    label:
      RoleOptimizationRequestReasonLabels[
        RoleOptimizationRequestReason.RoleDescriptionLacking
      ],
    name: RoleOptimizationRequestReason.RoleDescriptionLacking,
    requireDetails: true,
  },
  {
    label:
      RoleOptimizationRequestReasonLabels[
        RoleOptimizationRequestReason.VideoMissing
      ],
    name: RoleOptimizationRequestReason.VideoMissing,
    requireDetails: false,
  },
  {
    label:
      RoleOptimizationRequestReasonLabels[
        RoleOptimizationRequestReason.SkillsNeedClarification
      ],
    name: RoleOptimizationRequestReason.SkillsNeedClarification,
    requireDetails: true,
  },
  {
    label:
      RoleOptimizationRequestReasonLabels[
        RoleOptimizationRequestReason.VideoNotWorking
      ],
    name: RoleOptimizationRequestReason.VideoNotWorking,
    requireDetails: false,
  },
  {
    label:
      RoleOptimizationRequestReasonLabels[
        RoleOptimizationRequestReason.LocationRequirementsNeedClarification
      ],
    name: RoleOptimizationRequestReason.LocationRequirementsNeedClarification,
    requireDetails: true,
  },
];

const useStyles = createUseStyles({
  modalContent: {
    padding: Paddings.large,
  },
  title: {
    textAlign: 'center',
    margin: 0,
    marginBottom: Spacing.xxLarge,
  },
  helpText: {
    margin: `${Spacing.xsmall}px 0 ${Spacing.large}px 0`,
    color: TextColors.regularLight,
    fontSize: FontSizes.small,
  },
  reasonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: Spacing.medium,
  },
  reasonContainer: {
    margin: 0,
    flexGrow: 0,
    flexShrink: 1,
    border: `1px solid ${BorderColors.light}`,
    marginBottom: Spacing.small,
    width: `calc(50% - ${(Spacing.medium as number) / 2}px)`,
    borderRadius: BorderRadius.default,
  },
  reasonCheckbox: {
    margin: 0,
    padding: Paddings.small,
  },
  addDetailsLabel: {
    marginTop: Spacing.large,
    marginBottom: Spacing.small,
    display: 'block',
  },
  addDetailsInput: {
    width: '100%',
  },
  buttonsContainer: {
    marginTop: Spacing.xLarge,
    justifyContent: 'center',
    display: 'flex',
    columnGap: Spacing.medium,
  },
});

const DETAILS_PLACEHOLDER = '[Details]';

export const RequestOptimizationModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
}: Props): ReactElement => {
  const styles = useStyles();
  const [selectedReasons, setSelectedReasons] = useState<
    RoleOptimizationRequestReason[]
  >([]);
  const [details, setDetails] = useState<string>('');

  const showDetails = selectedReasons.some((reason) => {
    const found = reasons.find((r) => r.name === reason);
    return found?.requireDetails;
  });

  const handleConfirm = async () => {
    try {
      onConfirm({
        reasons: selectedReasons,
        ...(showDetails && details ? { details } : { details: '' }),
      });
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  const handleClose = () => {
    setDetails('');
    setSelectedReasons([]);
    onClose();
  };

  const appendDetailsTemplateText = (reason: RoleOptimizationRequestReason) => {
    if (reasons.find((r) => r.name === reason)?.requireDetails === false)
      return;
    const reasonLabel = RoleOptimizationRequestReasonLabels[reason];
    setDetails((prev) => {
      const detailsPlaceholderText = `${reasonLabel}: ${DETAILS_PLACEHOLDER}. `;
      if (prev.includes(reasonLabel)) {
        return prev;
      }
      return prev + detailsPlaceholderText;
    });
  };

  const deleteDetailsTemplateTextIfNeeded = (
    reason: RoleOptimizationRequestReason,
  ) => {
    if (reasons.find((r) => r.name === reason)?.requireDetails === false)
      return;
    const reasonLabel = RoleOptimizationRequestReasonLabels[reason];
    setDetails((prev) =>
      prev.replace(`${reasonLabel}: ${DETAILS_PLACEHOLDER}. `, ''),
    );
  };

  const formValid =
    selectedReasons.length > 0 &&
    (!showDetails || details.length > 0) &&
    !(showDetails && details.includes(DETAILS_PLACEHOLDER));

  return (
    <Modal
      width={ModalWidth.fixed}
      style={{ width: 800 }}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <div className={styles.modalContent}>
        <h3 className={styles.title}>
          Select a reason for optimization request
        </h3>
        <strong>Select reason</strong>
        <p className={styles.helpText}>Items with * require details</p>
        <div className={styles.reasonsContainer}>
          {reasons.map((reason) => (
            <div key={reason.name} className={styles.reasonContainer}>
              <Checkbox
                className={styles.reasonCheckbox}
                label={reason.label + (reason.requireDetails ? ' *' : '')}
                checked={selectedReasons.includes(reason.name)}
                onChange={(e) => {
                  if (e.target.checked) {
                    appendDetailsTemplateText(reason.name);
                    setSelectedReasons((prev) => prev.concat(reason.name));
                  } else {
                    deleteDetailsTemplateTextIfNeeded(reason.name);
                    setSelectedReasons((prev) =>
                      prev.filter((r) => r !== reason.name),
                    );
                  }
                }}
              />
            </div>
          ))}
        </div>
        {showDetails ? (
          <>
            <strong className={styles.addDetailsLabel}>Add details *</strong>
            <p className={styles.helpText}>
              Placeholder text ({DETAILS_PLACEHOLDER}) must be replaced to
              submit
            </p>
            <Textarea
              size="stretch"
              className={styles.addDetailsInput}
              placeholder="Enter the details of the optimization request"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            ></Textarea>
          </>
        ) : null}

        <div className={styles.buttonsContainer}>
          <Button
            squared
            size="small"
            width="auto"
            onClick={handleClose}
            color="regularLight"
          >
            Close
          </Button>
          <Button
            disabled={!formValid}
            onClick={handleConfirm}
            color="secondaryDark"
            size="small"
            width="auto"
            loading={isLoading}
            squared
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};
