import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { VettingProcessFeedbackBuiltProductsFromScratch } from '@a_team/models/dist/vetting-processes/feedback';
import { theme } from '@a_team/ui-components';
import { Spacing } from '@ateams/components';
import { InputLabel } from '../../components/typography';
import { CheckboxValueTag } from '../../components/value-tag';
import { Separator } from '../../components/separator';
import { TextInput } from '../../components/text-input';
import { Text } from '../../components/typography';

const INPUT_LABEL = 'Has this builder worked on products from zero to one?';

type BuiltProductsFromScratchValue =
  | VettingProcessFeedbackBuiltProductsFromScratch
  | undefined;

export type OnBuiltProductsFromScratchChange = (
  builtProductsFromScratch: BuiltProductsFromScratchValue,
) => void;

export interface BuiltProductsFromScratchInputProps {
  value: BuiltProductsFromScratchValue;
  onChange?: OnBuiltProductsFromScratchChange;
  isReadOnly?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  marginLeft: {
    marginLeft: Spacing.medium,
  },
  inputsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  booleanCheckboxValueTag: {
    marginRight: Spacing.small,
    '&:last-child': {
      marginRight: 0,
    },
  },
});

export const BuiltProductsFromScratchInput: React.FC<
  BuiltProductsFromScratchInputProps
> = (props) => {
  const { isReadOnly, value } = props;
  const styles = useStyles();

  const onBuiltProductsFromScratchChange = (isChecked: boolean) => {
    const newBuiltProductsFromScratch =
      value?.hasBuiltProductsFromScratch === isChecked
        ? undefined
        : {
            hasBuiltProductsFromScratch: isChecked,
            areTheyStillUsed: undefined,
            wasSignificantOnProductFromScratchBuildingProcess: undefined,
            wasSignificantOnProductFromScratchBuildingProcessDescription:
              undefined,
          };

    props.onChange?.(newBuiltProductsFromScratch);
  };

  const onProductsFromScratchStillUsedChange = (isChecked: boolean) => {
    if (value) {
      const newBuiltProductsFromScratch = {
        ...value,
        areTheyStillUsed:
          value.areTheyStillUsed === isChecked ? undefined : isChecked,
      };

      props.onChange?.(newBuiltProductsFromScratch);
    }
  };

  const onWasSignificantOnProductFromScratchBuildingProcessChange = (
    isChecked: boolean,
  ) => {
    if (value) {
      const newBuiltProductsFromScratch = {
        ...value,
        wasSignificantOnProductFromScratchBuildingProcess:
          value.wasSignificantOnProductFromScratchBuildingProcess === isChecked
            ? undefined
            : isChecked,
        wasSignificantOnProductFromScratchBuildingProcessDescription: undefined,
      };

      props.onChange?.(newBuiltProductsFromScratch);
    }
  };

  const onWasSignificantOnProductFromScratchBuildingProcessDescriptionChange = (
    description: string,
  ) => {
    if (value) {
      const newBuiltProductsFromScratch = {
        ...value,
        wasSignificantOnProductFromScratchBuildingProcessDescription:
          description,
      };

      props.onChange?.(newBuiltProductsFromScratch);
    }
  };

  if (isReadOnly && !value) {
    return (
      <div className={styles.marginBottom}>
        <InputLabel className={styles.marginBottom}>{INPUT_LABEL}</InputLabel>
        <Text isReadOnly />
      </div>
    );
  }

  return (
    <div className={styles.marginBottom}>
      <InputLabel className={styles.marginBottom}>{INPUT_LABEL}</InputLabel>
      <div className={cx(styles.inputsRow, styles.marginBottom)}>
        <CheckboxValueTag
          label={'Yes'}
          checked={value?.hasBuiltProductsFromScratch === true}
          className={styles.booleanCheckboxValueTag}
          onClick={() => onBuiltProductsFromScratchChange(true)}
          isReadOnly={isReadOnly}
        />
        <CheckboxValueTag
          label={'No'}
          checked={value?.hasBuiltProductsFromScratch === false}
          className={styles.booleanCheckboxValueTag}
          onClick={() => onBuiltProductsFromScratchChange(false)}
          isReadOnly={isReadOnly}
        />
      </div>
      <Separator
        direction={'vertical'}
        space={Spacing.medium}
        width={2}
        color={theme.colors.Grey[200]}
        className={cx(styles.marginLeft, styles.marginBottom)}
      >
        <InputLabel className={styles.marginBottom}>
          Are these products still active today?
        </InputLabel>
        {isReadOnly && typeof value?.areTheyStillUsed !== 'boolean' ? (
          <Text isReadOnly />
        ) : (
          <div className={cx(styles.inputsRow, styles.marginBottom)}>
            <CheckboxValueTag
              label={'Yes'}
              checked={value?.areTheyStillUsed === true}
              className={styles.booleanCheckboxValueTag}
              disabled={value?.hasBuiltProductsFromScratch !== true}
              onClick={() => onProductsFromScratchStillUsedChange(true)}
              isReadOnly={isReadOnly}
            />
            <CheckboxValueTag
              label={'No'}
              checked={value?.areTheyStillUsed === false}
              className={styles.booleanCheckboxValueTag}
              disabled={value?.hasBuiltProductsFromScratch !== true}
              onClick={() => onProductsFromScratchStillUsedChange(false)}
              isReadOnly={isReadOnly}
            />
          </div>
        )}

        <InputLabel className={styles.marginBottom}>
          Did they play a significant role in the building process?
        </InputLabel>
        {isReadOnly &&
        typeof value?.wasSignificantOnProductFromScratchBuildingProcess !==
          'boolean' ? (
          <div
            className={cx(styles.inputsRow, {
              [styles.marginBottom]:
                value?.wasSignificantOnProductFromScratchBuildingProcess,
            })}
          >
            <Text isReadOnly />
          </div>
        ) : (
          <div
            className={cx(styles.inputsRow, {
              [styles.marginBottom]:
                value?.wasSignificantOnProductFromScratchBuildingProcess,
            })}
          >
            <CheckboxValueTag
              label={'Yes'}
              checked={
                value?.wasSignificantOnProductFromScratchBuildingProcess ===
                true
              }
              className={styles.booleanCheckboxValueTag}
              disabled={value?.hasBuiltProductsFromScratch !== true}
              onClick={() =>
                onWasSignificantOnProductFromScratchBuildingProcessChange(true)
              }
              isReadOnly={isReadOnly}
            />
            <CheckboxValueTag
              label={'No'}
              checked={
                value?.wasSignificantOnProductFromScratchBuildingProcess ===
                false
              }
              className={styles.booleanCheckboxValueTag}
              disabled={value?.hasBuiltProductsFromScratch !== true}
              onClick={() =>
                onWasSignificantOnProductFromScratchBuildingProcessChange(false)
              }
              isReadOnly={isReadOnly}
            />
          </div>
        )}
        {value?.wasSignificantOnProductFromScratchBuildingProcess && (
          <div className={styles.marginLeft}>
            <TextInput
              value={
                value.wasSignificantOnProductFromScratchBuildingProcessDescription
              }
              onChange={(e) =>
                onWasSignificantOnProductFromScratchBuildingProcessDescriptionChange(
                  e.target.value,
                )
              }
              placeholder={'Enter details...'}
              isReadOnly={isReadOnly}
              readOnlyText={'No details were added.'}
            />
          </div>
        )}
      </Separator>
    </div>
  );
};
