import React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import checkIcon from './check.svg';
import { ModalPages } from '.';
import { Colors, TextColors } from '@ateams/components';

interface PageIndicatorProps {
  page: ModalPages;
  isUserOnMission: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  page: {
    padding: '8px 16px',
    fontSize: '15px',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectedBg: {
    borderLeft: `2px solid ${Colors.secondaryDark}`,
    background: '#FCFAFF',
  },
  selectedText: {
    color: TextColors.regular,
  },
});

const PageIndicator = ({
  page,
  isUserOnMission,
}: PageIndicatorProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div
        className={cx(
          styles.page,
          page === ModalPages.PlatformCodeOfConduct && styles.selectedBg,
          styles.selectedText,
        )}
      >
        Platform Code of Conduct{' '}
        {page !== ModalPages.PlatformCodeOfConduct && (
          <img src={checkIcon} alt="check icon" />
        )}
      </div>

      {isUserOnMission ? (
        <div
          className={cx(
            styles.page,
            page === ModalPages.MissionCodeOfConduct && styles.selectedBg,
            page === ModalPages.MissionCodeOfConduct && styles.selectedText,
          )}
        >
          Mission Code of Conduct
        </div>
      ) : (
        <div
          className={cx(
            styles.page,
            page === ModalPages.QuickOnboardingChecklist && styles.selectedBg,
            page === ModalPages.QuickOnboardingChecklist && styles.selectedText,
          )}
        >
          Quick Onboarding List
        </div>
      )}
    </div>
  );
};

export default PageIndicator;
