import React from 'react';
import { createUseStyles } from 'react-jss';

import greyStar from './assets/grey-star.svg';
import yellowStar from './assets/yellow-star.svg';
import EmojiIcon from './common/EmojiIcon';
import ControlButtons from './common/ControlButtons';
import { Breakpoints, Colors, Icon, IconType } from '@ateams/components';
import TeamMemberCard from './common/TeamMemberCard';
import {
  IMemberResponse,
  TeamPulseUserReviewSectionsEnum,
} from './TeamPulseModal';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import cx from 'classnames';
import HiddenMembers from './HiddenMembers';
import TooltipWrapped from '@src/components/TooltipWrapped';

interface TeamMemberRatingProps {
  setSection: (section: string) => void;
  missionTitle: string;
  moveUserToReview: (uid: string) => void;
  isDesktop: boolean;
  hiddenUsers: BasicUserObject[] | undefined;
  toggleHiddenUsers: boolean;
  setToggleHiddenUsers: (toggle: boolean) => void;
  handleTeamMemberRating: ({
    value,
    unableToRespond,
    wouldLikeToTeamUpAgain,
  }: {
    value?: number | undefined;
    unableToRespond: boolean;
    wouldLikeToTeamUpAgain: boolean;
  }) => void;
  teamMember: BasicUserObject | undefined;
  onPreviousMember: () => void;
  moveUserToHidden: (uid: string) => void;
  onNextMember: () => void;
  memberResponse?: IMemberResponse;
}

const useStyles = createUseStyles({
  '@global': {
    '.tippy-popper': {
      zIndex: '999999 !important',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    height: '100%',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: 18,
    marginBottom: 0,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'start',
  },
  desc: {
    textAlign: 'start',
    color: '#707275',
    marginTop: 8,
  },
  iconsContainer: {
    width: '100%',
    marginTop: 40,
    marginBottom: 40,
    display: 'flex',
    gap: 16,
  },
  radioContainer: {
    border: `1px solid ${Colors.backgroundDark}`,
    borderRadius: 8,
    width: '100%',
  },
  radioWrapper: {
    height: 'fit-content',
    display: 'flex',
    gap: 10,
    padding: '15px 10px',
    alignItems: 'center',
  },
  radio: {
    margin: 0,
    accentColor: '#60a301',
    colorScheme: 'light',
    height: 16,
    width: 16,
  },
  label: {
    width: '100%',
  },
  labelIcon: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  wrapper: {
    padding: 20,
  },
  bottomContainer: {
    width: '100%',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    wrapper: {
      padding: 0,
    },
    bottomContainer: {
      position: 'sticky',
      bottom: 0,
    },
  },
});

enum TeamMemberRatingOptions {
  NotSignificantTimeSpent = 'NotSignificantTimeSpent',
  IWouldTeamUpAgain = 'IWouldTeamUpAgain',
}

const TeamMemberRating = ({
  setSection,
  missionTitle,
  handleTeamMemberRating,
  teamMember,
  onPreviousMember,
  moveUserToHidden,
  onNextMember,
  memberResponse,
  hiddenUsers,
  isDesktop,
  moveUserToReview,
  toggleHiddenUsers,
  setToggleHiddenUsers,
}: TeamMemberRatingProps): JSX.Element => {
  const [memberRatingOption, setMemberRatingOption] = React.useState<
    TeamMemberRatingOptions | undefined
  >(
    memberResponse?.wouldLikeToTeamUpAgain
      ? TeamMemberRatingOptions.IWouldTeamUpAgain
      : memberResponse?.unableToRespond
      ? TeamMemberRatingOptions.NotSignificantTimeSpent
      : undefined,
  );
  const [score, setScore] = React.useState<number | undefined>(
    memberResponse?.value || undefined,
  );
  const styles = useStyles();

  const onBadly = () => {
    setScore(1);
  };

  const onSoso = () => {
    setScore(2);
  };

  const onOkay = () => {
    setScore(3);
  };

  const onGreat = () => {
    setScore(4);
  };

  const onAmazing = () => {
    setScore(5);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name } = e.target;
    const option = name as TeamMemberRatingOptions;
    // if selected again set to undefined
    if (memberRatingOption === option) {
      setMemberRatingOption(undefined);
    } else {
      setMemberRatingOption(option);
      // if the option is NotSignificantTimeSpent set store to undefined
      if (option === TeamMemberRatingOptions.NotSignificantTimeSpent) {
        setScore(undefined);
      }
    }
  };

  const onContinue = () => {
    handleTeamMemberRating({
      value: score,
      unableToRespond:
        memberRatingOption === TeamMemberRatingOptions.NotSignificantTimeSpent,
      wouldLikeToTeamUpAgain:
        memberRatingOption === TeamMemberRatingOptions.IWouldTeamUpAgain,
    });
    // if not signicant time spent move to hidden
    if (
      memberRatingOption === TeamMemberRatingOptions.NotSignificantTimeSpent &&
      teamMember
    ) {
      moveUserToHidden(teamMember?.uid);
      setMemberRatingOption(undefined);
      onNextMember();
    } else {
      setSection(TeamPulseUserReviewSectionsEnum.TeamMemberFeedback);
    }
  };

  const onBack = () => {
    onPreviousMember();
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {teamMember && (
          <TeamMemberCard
            fullName={teamMember?.fullName}
            profileImg={teamMember?.profilePictureURL}
            simple
          />
        )}
        <h4 className={styles.title}>
          How’s working with {teamMember?.firstName}?
        </h4>
        <p className={styles.desc}>
          Your team's average score is shared with the company but your
          individual score is anonymous. Comments are not shared with{' '}
          {missionTitle}.
        </p>
        <div className={styles.iconsContainer}>
          <EmojiIcon
            selected={score === 1}
            title="Badly"
            emoji={yellowStar}
            grayEmoji={greyStar}
            onClick={onBadly}
            disabled={
              memberRatingOption ===
              TeamMemberRatingOptions.NotSignificantTimeSpent
            }
          />
          <EmojiIcon
            selected={score === 2}
            onClick={onSoso}
            title="So so"
            emoji={yellowStar}
            grayEmoji={greyStar}
            disabled={
              memberRatingOption ===
              TeamMemberRatingOptions.NotSignificantTimeSpent
            }
          />
          <EmojiIcon
            selected={score === 3}
            onClick={onOkay}
            title="Okay"
            emoji={yellowStar}
            grayEmoji={greyStar}
            disabled={
              memberRatingOption ===
              TeamMemberRatingOptions.NotSignificantTimeSpent
            }
          />
          <EmojiIcon
            selected={score === 4}
            onClick={onGreat}
            title="Great"
            emoji={yellowStar}
            grayEmoji={greyStar}
            disabled={
              memberRatingOption ===
              TeamMemberRatingOptions.NotSignificantTimeSpent
            }
          />
          <EmojiIcon
            selected={score === 5}
            onClick={onAmazing}
            title="Amazing"
            emoji={yellowStar}
            grayEmoji={greyStar}
            disabled={
              memberRatingOption ===
              TeamMemberRatingOptions.NotSignificantTimeSpent
            }
          />
        </div>
        <div className={styles.radioContainer}>
          <div
            className={styles.radioWrapper}
            style={{ borderBottom: '1px solid #EFEFF0' }}
          >
            <input
              id="NotSignificantTimeSpent"
              type="checkbox"
              onChange={handleChange}
              name={TeamMemberRatingOptions.NotSignificantTimeSpent}
              className={styles.radio}
              checked={
                memberRatingOption ===
                TeamMemberRatingOptions.NotSignificantTimeSpent
              }
            />
            <label
              htmlFor="NotSignificantTimeSpent"
              className={cx(styles.labelIcon, styles.label)}
            >
              I don’t spend significant time with this builder{' '}
            </label>
            <TooltipWrapped
              trigger="mouseenter"
              title={`If you check this box, we'll move this builder into "Hidden Teammates" and won't ask you for feedback about this builder again. You can always bring them back later.`}
              position={'bottom'}
            >
              <Icon type={IconType.Info} />
            </TooltipWrapped>
          </div>
          <div
            className={styles.radioWrapper}
            style={{ borderBottom: '1px solid #EFEFF0' }}
          >
            <input
              id="IWouldTeamUpAgain"
              type="checkbox"
              onChange={handleChange}
              name={TeamMemberRatingOptions.IWouldTeamUpAgain}
              className={styles.radio}
              checked={
                memberRatingOption === TeamMemberRatingOptions.IWouldTeamUpAgain
              }
            />
            <label htmlFor="IWouldTeamUpAgain" className={styles.label}>
              I would team up with this builder again
            </label>
          </div>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        {!isDesktop && hiddenUsers && !!hiddenUsers.length && (
          <HiddenMembers
            toggleHiddenUsers={toggleHiddenUsers}
            setToggleHiddenUsers={setToggleHiddenUsers}
            hiddenUsers={hiddenUsers}
            moveUserToReview={moveUserToReview}
          />
        )}
        <ControlButtons
          onContinue={onContinue}
          onBack={onBack}
          canContinue={
            score !== undefined ||
            memberRatingOption ===
              TeamMemberRatingOptions.NotSignificantTimeSpent
          }
        />
      </div>
    </div>
  );
};

export default TeamMemberRating;
