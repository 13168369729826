import React, {
  CSSProperties,
  InputHTMLAttributes,
  KeyboardEvent,
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import TextareaAutosize from 'react-textarea-autosize';
import OutlinedControl, {
  OutlinedControlProps,
} from '@src/components/Inputs/OutlinedControl';
import useCombinedRefs from '@src/hooks/useCombinedRefs';
import _ from 'lodash';

export interface OutlinedInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'width'>,
    Omit<
      OutlinedControlProps,
      'input' | 'onClick' | 'onFocus' | 'onDoubleClick'
    > {
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  fullWidth?: boolean;
  lengthCounter?: boolean;
  hideLength?: boolean;
  errorMessage?: string;
  noBorder?: boolean;
  autosize?: boolean;
  textAlign?: CSSProperties['textAlign'];
  inputStyles?: CSSProperties;
  inputClassName?: string;
  controlClassName?: string;
  nextInputOnEnterKey?: boolean;
}

const useStyles = createUseStyles({
  input: {
    margin: '-1em 0',
    padding: '1em 0',
    border: 0,
    background: 'none',
    width: (props: OutlinedInputProps): string =>
      props.noBorder ? (props.fullWidth ? '100%' : 'auto') : '100%',
    color: 'inherit',
  },
  sizer: {
    position: 'absolute',
    top: 0,
    left: 0,
    visibility: 'hidden',
    height: 0,
    overflow: 'scroll',
    whiteSpace: 'pre',
    fontSize: (props: OutlinedInputProps): string =>
      props.inputStyles?.fontSize
        ? `${props.inputStyles?.fontSize}${
            typeof props.inputStyles.fontSize === 'number' ? 'px' : ''
          }`
        : 'inherit',
  },
  textarea: {
    resize: 'vertical',
  },
});

const OutlinedInput = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  OutlinedInputProps
>((props, parentRef): ReactElement => {
  const {
    className,
    inputClassName,
    controlClassName,
    style,
    multiline,
    width,
    minRows,
    maxRows,
    borderRadius,
    lengthCounter,
    noBorder,
    autosize,
    fullWidth,
    textAlign,
    inputStyles,
    error,
    dropdown,
    dropdownPlacement,
    errorMessage,
    name,
    nextInputOnEnterKey,
    loading,
    ...left
  } = props;

  const leftForHtml = _.omit(left, ['endAdornment', 'controlClassName']);

  const styles = useStyles(props);

  const sizerRef = useRef<HTMLSpanElement>(null);
  const localRef = useRef<HTMLInputElement | HTMLTextAreaElement>();
  const ref = useCombinedRefs(parentRef, localRef);

  const [calcWidth, setCalcWidth] = useState<number | undefined>();
  const updateCalcWidth = useRef(
    _.throttle(
      () => sizerRef.current && setCalcWidth(sizerRef.current.scrollWidth + 8),
      200,
      { leading: true, trailing: true },
    ),
  );

  useEffect(() => {
    if (!autosize || !sizerRef.current) {
      setCalcWidth(undefined);
      return;
    }

    updateCalcWidth.current();
  }, [autosize, left.value, left.placeholder, sizerRef]);

  const onInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (nextInputOnEnterKey && e.key === 'Enter') {
      e.preventDefault();
      const inputs = [...document.querySelectorAll('input')].filter(
        (el) => el.type === 'text',
      );
      const index = inputs.indexOf(e.currentTarget);
      if (index !== -1 && index < inputs.length - 1) {
        inputs[index + 1].focus();
      } else {
        inputs[0].focus();
      }
    }
    props.onKeyDown && props.onKeyDown(e);
  };

  const input = multiline ? (
    <TextareaAutosize
      inputRef={ref as RefObject<HTMLTextAreaElement>}
      minRows={minRows}
      maxRows={maxRows}
      className={cx(styles.input, styles.textarea, inputClassName)}
      style={{ textAlign, ...inputStyles }}
      name={name}
      // TODO: remove this hack
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      {...(left as unknown)}
      onDoubleClick={(e) => e.stopPropagation()}
    />
  ) : (
    <input
      ref={ref as RefObject<HTMLInputElement>}
      className={cx(styles.input, inputClassName)}
      style={{
        textAlign,
        width: calcWidth,
        ...inputStyles,
      }}
      name={name}
      {...leftForHtml}
      onDoubleClick={(e) => e.stopPropagation()}
      onKeyDown={onInputKeyDown}
    />
  );

  const handleClick = (): void => {
    ref.current?.focus();
  };

  const handleDoubleClick = (): void => {
    const { current } = ref;
    if (!current) return;

    current.focus();
    current.select();
  };

  return (
    <>
      <OutlinedControl
        className={className}
        controlClassName={controlClassName}
        borderRadius={borderRadius}
        style={style}
        input={input}
        width={width}
        noBorder={noBorder}
        length={lengthCounter ? String(left.value || '').length : null}
        error={error}
        errorMessage={errorMessage}
        dropdown={dropdown}
        dropdownPlacement={dropdownPlacement}
        name={name}
        {...left}
        onClick={handleClick}
        onFocus={undefined}
        onDoubleClick={handleDoubleClick}
      />

      {!!autosize && (
        <span ref={sizerRef} className={styles.sizer}>
          {left.value || left.placeholder || ''}
        </span>
      )}
    </>
  );
});

export default OutlinedInput;
