import React, { ReactElement } from 'react';
import linkedinIcon from './linkedin.svg';
import googleIcon from './google.svg';
import facebookIcon from './facebook.svg';
import githubIcon from './github.svg';
import twitterIcon from './twitter.svg';
import angelListIcon from './angelList.svg';
import emailIcon from './email.svg';

export enum IconType {
  LinkedIn,
  Email,
  Google,
  Facebook,
  Twitter,
  GitHub,
  AngelList,
}

interface Props {
  type: IconType;
}

const icons = {
  [IconType.LinkedIn]: linkedinIcon,
  [IconType.Email]: emailIcon,
  [IconType.Google]: googleIcon,
  [IconType.Facebook]: facebookIcon,
  [IconType.Twitter]: twitterIcon,
  [IconType.GitHub]: githubIcon,
  [IconType.AngelList]: angelListIcon,
};

export default function ButtonIcon(props: Props): ReactElement {
  const { type } = props;

  return <img src={icons[type]} alt="icon" style={{ marginRight: '16px' }} />;
}
