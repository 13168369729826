import React from 'react';
import { createUseStyles } from 'react-jss';
import avatar from './avatar.svg';
import useToggle from '@src/hooks/useToggle';
import RequestBuildersModal from '@src/components/Modal/RequestBuildersModal';
import { useMission } from '../useMission';

const useStyles = createUseStyles({
  container: {
    padding: 16,
    display: 'flex',
    borderRadius: 8,
    background: '#F7F7F7',
    gap: 16,
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: '19px',
    cursor: 'pointer',
  },
  avatarWrapper: {
    width: 48,
    height: 48,
  },
  cta: {
    fontWeight: 600,
  },
});

function RequestMoreBuildersBanner() {
  const styles = useStyles();
  const { currentMission } = useMission();
  const [isRequestBuildersModalOpen, toggleRequestBuildersModal] = useToggle();

  return (
    <>
      <div className={styles.container} onClick={toggleRequestBuildersModal}>
        <div className={styles.avatarWrapper}>
          <img src={avatar} alt="Avatar" width={48} height={48} />
        </div>
        <div>
          <div>Does this mission need support?</div>
          <div className={styles.cta}>Request more builders</div>
        </div>
      </div>

      {isRequestBuildersModalOpen && currentMission?.mid && (
        <RequestBuildersModal
          open={isRequestBuildersModalOpen}
          mid={currentMission.mid}
          onClose={() => {
            toggleRequestBuildersModal();
          }}
        />
      )}
    </>
  );
}

export default RequestMoreBuildersBanner;
