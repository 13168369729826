import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
    '& ol li::marker': {
      fontWeight: 600,
    },
  },
});

const ResponsibleCommunity = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <h4>Responsible Community</h4>
      <p>
        <strong>Non-circumvention</strong>: We want to create a safe and curated
        experience for everyone involved the platform, and circumventing the
        platform undermines this. As a general rule: You should never contact a
        company you learn about on A.Team unless A.Team has connected you with
        them first.{' '}
      </p>

      <ol type="1">
        <li>
          <div>
            <p>
              <strong>Terms of Service</strong>
            </p>
            <p>
              Section 15 of the Terms of Service you accepted when joining
              A.Team expressly prohibits working with (or even soliciting work
              from) an A.Team company outside of our platform, as well as
              introducing the company to independent builders who are not a part
              of the A.Team community.
            </p>
          </div>
        </li>
        <li>
          <div>
            <p>
              <strong>Respect boundaries</strong>
            </p>
            <p>
              Legal requirements aside, companies frequently tell us that they
              don’t like when builders reach out to them directly! Finally, bear
              in mind that companies also agree not to connect with builders
              outside of the A.Team platform.
            </p>
          </div>
        </li>
      </ol>
    </div>
  );
};

export default ResponsibleCommunity;
