import React, { ReactElement, useEffect, useMemo } from 'react';
import Section from '../partials/Section';
import { useQueryPendingUserRecommendations } from '@src/rq/teamUp';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import TeamUpRequestCard from './TeamUpRequestCard';
import { createUseStyles } from 'react-jss';
import {
  RecommendationId,
  RecommendationObject,
  RecommendationStatus,
} from '@a_team/models/dist/RecommendationObject';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@src/rq/keys';
import { Breakpoints } from '@ateams/components';
import Application from '@src/stores/Application';
import { isApplyingToTheSameRole } from './utils';

interface TeamUpProps {
  application: Application;
  guidanceMessage?: ReactElement;
  guidanceTooltip?: ReactElement;
}

const useStyles = createUseStyles({
  cards: {
    width: '100%',
    overflowY: 'scroll',
    whiteSpace: 'nowrap',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    cards: {
      width: '100%',
      overflowY: 'auto',
    },
  },
});

const TeamUp = ({
  application,
  guidanceMessage,
  guidanceTooltip,
}: TeamUpProps) => {
  const styles = useStyles();
  const queryClient = useQueryClient();
  const { auth, missions } = useStores();
  const { currentMission } = missions;
  const { isLoading, data: userRecommendations } =
    useQueryPendingUserRecommendations({
      missionId: currentMission?.mid || '',
      enabled: !!currentMission?.mid,
    });
  const currentRoleId = application?.currentRole?.rid;

  const respondToRequest = (
    rid: RecommendationId,
    newStatus: RecommendationStatus,
  ) => {
    const pendingInvites = queryClient.getQueryData<RecommendationObject[]>(
      queryKeys.teamUp.getPendingUserRecommendations(
        auth.uid ?? '',
        currentMission?.mid ?? '',
      ),
    );

    if (!pendingInvites) {
      return;
    }

    const updatedPendingInvites = userRecommendations?.map((invite) => {
      if (invite.rid === rid) {
        return {
          ...invite,
          status: newStatus,
        };
      }

      return invite;
    });

    queryClient.setQueryData(
      queryKeys.teamUp.getPendingUserRecommendations(
        auth.uid || '',
        currentMission?.mid ?? '',
      ),
      updatedPendingInvites,
    );
  };

  const sortedUserRecommendations = useMemo(() => {
    return userRecommendations?.sort((userRecommendation) => {
      return currentRoleId &&
        isApplyingToTheSameRole(userRecommendation, currentRoleId)
        ? 1
        : -1;
    });
  }, [userRecommendations, currentRoleId]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const teamUpRequestResponses = new Map(
      userRecommendations
        ?.filter((userRecommendation) => {
          return (
            currentRoleId &&
            !isApplyingToTheSameRole(userRecommendation, currentRoleId)
          );
        })
        .map((userRecommendation) => [
          userRecommendation.rid,
          userRecommendation.status as RecommendationStatus,
        ]),
    );

    application.setTeamUpRequestResponses(teamUpRequestResponses);
  }, [isLoading, userRecommendations]);

  if (isLoading || !userRecommendations || userRecommendations.length === 0) {
    return null;
  }

  return (
    <Section
      type="large"
      title="Team up on this mission"
      description="Apply with teammates to stand out by offering a comprehensive skill set."
      guidanceTooltip={guidanceTooltip}
    >
      <div>{userRecommendations.length} builders invited you to team up</div>
      {guidanceMessage}
      <div className={styles.cards} style={{ marginTop: 16 }}>
        {sortedUserRecommendations?.map((userRecommendation) => (
          <TeamUpRequestCard
            userRecommendation={userRecommendation}
            currentRoleId={currentRoleId}
            onAccept={(rid: RecommendationId) =>
              respondToRequest(rid, RecommendationStatus.Active)
            }
            onReject={(rid: RecommendationId) =>
              respondToRequest(rid, RecommendationStatus.Rejected)
            }
            onPending={(rid: RecommendationId) =>
              respondToRequest(rid, RecommendationStatus.Pending)
            }
          />
        ))}
      </div>
    </Section>
  );
};

export default observer(TeamUp);
