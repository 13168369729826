import React, { ReactElement } from 'react';
import {
  AsyncSelect,
  AsyncSelectProps,
  SelectOption,
} from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  userAvatar: {
    marginTop: 5,
  },
});

export const AdminUserSelector = (props: AsyncSelectProps): ReactElement => {
  const styles = useStyles();
  return (
    <AsyncSelect
      {...props}
      width={'fixed'}
      defaultOptions={false}
      formatOptionLabel={(option: SelectOption) => {
        if (!option.value) {
          return 'Select Owner';
        }
        return (
          <span>
            <UserAvatar
              src={option?.profilePictureURL}
              size={24}
              label={option.label}
              containerClassName={styles.userAvatar}
            />
          </span>
        );
      }}
    />
  );
};
