import { isEmpty, sortBy } from 'lodash';
import { queryKeys } from './keys';
import { useQueries, useQuery, useMutation } from '@tanstack/react-query';
import {
  Company,
  CompanyId,
  CompanyStatus,
  EnrichableCompany,
} from '@a_team/models/dist/Company';
import { QueryResult } from '@a_team/models/dist/misc';
import {
  apiEnrichableCompanies,
  teamEngineSearchServiceApi,
} from '@src/logic/services/endpoints';
import { getAuthToken } from '@src/helpers/auth';
import { v1CompanyEntityApi } from '@a_team/team-engine-search-service-sdk-js';
import { NewCompanyId } from '@src/components/CompanyPicker';
import { useStores } from '@src/stores';
import AuthStore from '@src/stores/Auth';

const companyQueryFields = {
  companies: {
    _id: 1,
    name: 1,
    url: 1,
    enrichment: {
      structured: {
        name: 1,
        industries: 1,
        logoUrl: 1,
      },
    },
  },
};

const mapToCompanyWithUrl = (
  name: string,
  companyData: v1CompanyEntityApi,
): Company & { url?: string } => ({
  id: companyData._id,
  name,
  url: companyData.url,
  status: isEmpty(companyData.enrichment?.structured)
    ? CompanyStatus.Unverified
    : CompanyStatus.Verified,
  logoUrl: companyData.enrichment?.structured?.logoUrl,
  talentIndustryIds:
    companyData.enrichment?.structured?.industries?.filter(
      (i): i is string => !!i,
    ) ?? [],
});

export const useQueryAutoCompleteCompany = ({
  searchTerm,
  verified,
  page,
  onSuccess,
  canAddCompany,
}: {
  page?: { size?: number; current?: number };
  searchTerm: string;
  verified?: boolean;
  onSuccess?: (data: QueryResult<Company & { url?: string }>) => void;
  canAddCompany?: boolean;
}) => {
  const { size = 1000, current } = page ?? {};

  return useQuery(
    queryKeys.companiesV2.autoCompleteCompany(searchTerm, verified),
    async (): Promise<ReturnType<typeof teamEngineSearchServiceApi.query>> => {
      if (!searchTerm) return Promise.resolve({ data: undefined });

      return teamEngineSearchServiceApi.query(
        {
          v1ListCompanies: [
            {
              page: { size, current },
              filter: {
                query: searchTerm,
                simplyEnriched: verified,
              },
            },
            companyQueryFields,
          ],
        },
        { headers: { Authorization: getAuthToken() } },
      );
    },
    {
      select: ({ data }) => {
        const unsortedItems =
          data?.v1ListCompanies.companies
            .filter(Boolean)
            .map((companyData: v1CompanyEntityApi) =>
              mapToCompanyWithUrl(
                companyData.enrichment?.structured?.name || companyData.name,
                companyData,
              ),
            ) ?? [];

        const items = sortBy(unsortedItems, 'name');

        canAddCompany &&
          searchTerm &&
          items.push({
            id: NewCompanyId,
            name: searchTerm,
            status: CompanyStatus.Unverified,
          });

        return { items, next: null };
      },
      onSuccess,
    },
  );
};

export const useQueriesGetCompanies = ({
  companyIds,
  onSuccess,
  auth,
  enabled = true,
}: {
  companyIds: CompanyId[];
  enabled: boolean;
  auth: AuthStore;
  onSuccess?: (data: Company & { url: string }) => void;
}) => {
  return useQueries({
    queries: companyIds.map((companyId) => ({
      queryKey: queryKeys.companiesV2.getCompany(companyId),
      queryFn: () => {
        return (
          auth &&
          apiEnrichableCompanies
            .getCompanyById(auth, companyIds[0])
            .then((company) => ({
              id: company.cid,
              name: company.name,
              url: company.url,
              status: company.verified
                ? CompanyStatus.Verified
                : CompanyStatus.Unverified,
              logoUrl: company.logoUrl,
              talentIndustryIds:
                company.industries?.filter((i): i is string => !!i) ?? [],
            }))
        );
      },
      onSuccess,
      enabled,
    })),
  });
};

export const useMutationCreateCompany = ({
  onSuccess,
  onError,
}: {
  onSuccess: (company: EnrichableCompany) => void;
  onError?: (error: unknown) => void;
}) => {
  const stores = useStores();
  return useMutation({
    mutationFn: (companyData: { name: string; url: string }) => {
      return apiEnrichableCompanies
        .createCompany(stores.auth, companyData)
        .catch((error: { code: number; payload?: EnrichableCompany }) => {
          // duplicate company requests should resolve successfully
          if (error?.code === 409 && error.payload) {
            onSuccess(error.payload);
          } else {
            throw error;
          }
        });
    },
    onSuccess: (data) => data && onSuccess(data),
    onError: (error) => onError && onError(error),
  });
};
