import { Breakpoints, Icon, IconType } from '@ateams/components';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import React from 'react';
import { createUseStyles } from 'react-jss';
import TeamMemberCard from './common/TeamMemberCard';

interface HiddenMembersProps {
  hiddenUsers: BasicUserObject[];
  moveUserToReview: (uid: string) => void;
  toggleHiddenUsers: boolean;
  setToggleHiddenUsers: (toggle: boolean) => void;
}

const useStyles = createUseStyles({
  title: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
    marginTop: 0,
    marginBottom: 4,
  },
  desc: {
    color: '#707275',
    marginBottom: 16,
  },
  arrowBtn: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    height: 24,
    width: 24,
  },
  container: {
    padding: 0,
    width: '100%',
  },
  wrapper: {
    background: '#F7F7F7',
    width: '100%',
    padding: 20,
  },
  hiddenMembersWrapper: {
    padding: 20,
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    container: {
      padding: `0 0 40px 0`,
    },
    wrapper: {
      background: 'none',
      padding: 0,
    },
    hiddenMembersWrapper: {
      padding: 0,
    },
  },
});

const HiddenMembers = ({
  moveUserToReview,
  hiddenUsers,
  toggleHiddenUsers,
  setToggleHiddenUsers,
}: HiddenMembersProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h5 className={styles.title}>
          Hidden Members{' '}
          <button
            aria-label="show or hide hidden members"
            onClick={() => setToggleHiddenUsers(!toggleHiddenUsers)}
            className={styles.arrowBtn}
          >
            <Icon
              style={{
                // when toggleHiddenUsers is true, rotate the icon 180 degrees
                transform: toggleHiddenUsers
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
                transition: 'transform 0.3s ease-in-out',
                cursor: 'pointer',
              }}
              size={'xsmall'}
              type={IconType.ArrowDownBlack}
            />
          </button>
        </h5>
        {toggleHiddenUsers && (
          <div className={styles.desc}>
            You won’t be asked to review builders from this list. If you start
            collaborating with some of them, you can remove them from the list.
          </div>
        )}
      </div>
      <div className={styles.hiddenMembersWrapper}>
        {toggleHiddenUsers &&
          hiddenUsers.map((member, index) => {
            return (
              <TeamMemberCard
                key={index}
                fullName={member.fullName}
                profileImg={member.profilePictureURL}
                onRemove={() => moveUserToReview(member.uid)}
                simple
              />
            );
          })}
      </div>
    </div>
  );
};

export default HiddenMembers;
