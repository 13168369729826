import { JssStyle } from 'css-jss';

export const tagEditedGrayStyle: JssStyle = {
  backgroundColor: '#F7F7F7',
  color: '#000000',
  borderRadius: '8px',
  paddingLeft: '10px',
  paddingRight: '10px',
  paddingTop: '4px',
  paddingBottom: '4px',
  fontWeight: 400,
};

export const tagEditedYellowStyle: JssStyle = {
  backgroundColor: '#FFE819',
  color: '#222222',
  borderRadius: '8px',
  paddingLeft: '10px',
  paddingRight: '10px',
  paddingTop: '4px',
  paddingBottom: '4px',
  fontWeight: 400,
  height: '22px',
};
