import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Button } from './button';
import { CalendlyIcon } from './icons/calendly';

const useStyles = createUseStyles({
  button: {
    background: '#006BFF',
    padding: '8px 10px',
  },
});

export const CalendlyButton: typeof Button = (props) => {
  const styles = useStyles();

  return (
    <Button
      {...props}
      className={cx(styles.button, props.className)}
      startAdornment={<CalendlyIcon />}
    >
      Open invite
    </Button>
  );
};
