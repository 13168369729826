import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import heading from './heading.svg';
import bold from './bold.svg';
import italic from './italic.svg';
import underline from './underline.svg';
import ul from './ul.svg';
import ol from './ol.svg';
import divider from './divider.svg';
import { Editor } from '@tiptap/react';

interface MenuBarProps {
  editor: Editor;
  nodesWithNoStyling?: string[];
  hideHeading?: boolean;
}

const useStyles = createUseStyles({
  wrapper: {
    display: 'inline-block',
    '& > div': {
      display: 'flex',
      gap: 6,
      padding: '3px 6px',
      borderRadius: 4,
      border: '1px solid #EBEBEB',
      '& > button': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
        width: 30,
        height: 24,
        borderRadius: 4,
        '&:disabled': {
          opacity: 0.5,
          cursor: 'not-allowed',
        },
        '&.is-active': {
          backgroundColor: '#EFEFF0',
        },
      },
    },
  },
});

const MenuBar = ({ editor, nodesWithNoStyling, hideHeading }: MenuBarProps) => {
  const styles = useStyles();

  const skipAction = useMemo(() => {
    const nodeName = editor?.state?.selection?.$from?.parent?.type?.name;
    return !!(nodeName && nodesWithNoStyling?.includes(nodeName));
  }, [editor?.state?.selection?.$from?.parent?.type?.name]);

  if (!editor) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div>
        {!hideHeading && (
          <>
            <button
              type="button"
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 3 }).run()
              }
              className={
                editor.isActive('heading', { level: 3 }) ? 'is-active' : ''
              }
              disabled={skipAction}
            >
              <img src={heading} alt="Heading" />
            </button>{' '}
            <img src={divider} alt="Divider" />
          </>
        )}

        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
          disabled={skipAction}
        >
          <img src={bold} alt="Bold" />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
          disabled={skipAction}
        >
          <img src={italic} alt="Italic" />
        </button>
        <button
          type="button"
          onClick={() => {
            editor.chain().focus().toggleUnderline().run();
          }}
          className={editor.isActive('underline') ? 'is-active' : ''}
          disabled={skipAction}
        >
          <img src={underline} alt="Underline" />
        </button>
        <img src={divider} alt="Divider" />
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
          disabled={skipAction}
        >
          <img src={ul} alt="UL" />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
          disabled={skipAction}
        >
          <img src={ol} alt="OL" />
        </button>
      </div>
    </div>
  );
};

export default MenuBar;
