import { TalentSkillId } from '@a_team/models/dist/TalentCategories';
import React, { ReactElement } from 'react';
import useToggle from '@src/hooks/useToggle';
import { Icon as UiIcon } from '@a_team/ui-components';
import {
  Colors,
  SelectableTagList,
  Spacing,
  TextColors,
} from '@ateams/components';
import cx from 'classnames';
import {
  generateSkillNameWithRating,
  TalentSkillWithRating,
} from '@src/views/SkillTargeter/TeamView/TargeterTeamCardList';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  label: {
    display: 'flex',
    gap: 8,
    marginBottom: Spacing.small,
    alignItems: 'center',
  },
  closedSkills: {
    height: 104,
    overflow: 'hidden',
  },
});

export const Skills = ({
  selectedSkills,
  allSkills,
  onChange,
}: {
  selectedSkills: TalentSkillId[];
  allSkills: TalentSkillWithRating[];
  onChange: (val: string, selected: boolean) => void;
}): ReactElement => {
  const styles = useStyles();
  const SKILLS_LIMIT = 4;
  const [isHidden, setIsHidden] = useToggle(true);
  const longList = allSkills.length > 10;

  return (
    <div>
      <label htmlFor="skills" className={styles.label}>
        <UiIcon size="md" name="pin" />
        Skills:{' '}
        <span style={{ color: TextColors.lighter }}>
          (Select up to {SKILLS_LIMIT})
        </span>
      </label>
      <SelectableTagList
        square
        className={cx({ [styles.closedSkills]: longList && isHidden })}
        tagStyles={{ borderRadius: 8, padding: 10 }}
        limit={SKILLS_LIMIT}
        color={'backgroundLight'}
        selectedColor={'success'}
        selectedItems={selectedSkills || []}
        items={allSkills.map(
          (skill) =>
            generateSkillNameWithRating(skill.name, skill.rating || 0) || '',
        )}
        onChange={(val, selected) => onChange(val, selected)}
      />
      {longList && (
        <span style={{ color: Colors.secondaryDark }} onClick={setIsHidden}>
          {isHidden ? `Show all (${allSkills.length} skills)` : 'Close'}
        </span>
      )}
    </div>
  );
};
