import { createUseStyles } from 'react-jss';

export const useCommonStyles = createUseStyles({
  container: {
    minWidth: 1239,
    width: '100%',
    marginBottom: 50,
    overflow: 'visible',
    '& table': {
      border: '1px solid #DADADC',
      width: '100%',
      borderCollapse: 'collapse',
      fontSize: 14,
      lineHight: '19px',
      overflow: 'visible',
    },
    '& table thead': {
      background: '#F7F7F7',
    },
    '& table th': {
      border: '1px solid #DADADC',
      borderBottom: 'none',
      color: '#4C4D50',
      fontWeight: 'normal',
      textAlign: 'start',
    },
    '& table th, & table tbody tr td:first-child ': {
      padding: 16,
    },
    '& table td': {
      padding: ({ cellPadding }) => cellPadding ?? 0,
      borderCollapse: 'collapse',
      '&:focus-visible': {
        borderRadius: 8,
        outline: '2px solid #6D00D7',
        zIndex: 1,
      },
    },
    '& table tbody tr:first-child td ': {
      borderTop: 'none',
    },
    '& table tbody tr:last-child td ': {
      borderBottom: '1px solid #DADADC',
    },
    '& table tfoot th': {
      color: '#222',
      fontWeight: 500,
      textAlign: 'start',
    },
  },
  dateColumn: {
    width: 165,
  },
  timeColumn: {
    width: 122,
  },
  typeColumn: {
    width: 200,
  },
  initiativesColumn: {
    width: 369,
  },
  actionsColumn: {
    width: 67,
  },
  cell: {
    border: '1px solid #DADADC',
    borderBottom: 'none',
    borderCollapse: 'collapse',
  },
  dateCell: {
    borderRight: '1px solid #DADADC',
    borderLeft: '1px solid #DADADC',
    borderTop: '1px solid #DADADC',
    borderCollapse: 'collapse',
  },
  emptyCell: {
    borderBottom: 'none',
  },
  disabledBg: {
    backgroundColor: '#F7F7F7',
  },
});
