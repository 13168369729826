import { Wysiwyg, theme, Icon } from '@a_team/ui-components';
import { Spacing } from '@ateams/components';
import { getHTMLStringTextLength } from '@src/helpers/richTextEditor';
import cx from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  value: string;
  onValueChange: (value: string) => void;
}

const BLURB_TEXT_LENGTH_LIMIT = 1000;

const useStyles = createUseStyles({
  textInputContainer: {
    marginTop: Spacing.medium,
    width: '100%',
  },
  fullWidth: {
    width: '100%',
    flexShrink: 0,
  },
  blurbTextArea: {
    marginTop: 6,
    border: '1px solid #C0C0C0',
    borderRadius: 4,
    maxWidth: '100%',
    background: 'white',
    overflow: 'hidden',
    minHeight: 40,
    '&&': { paddingBottom: 0 },
    '& > div': {
      position: 'relative',
    },
    '&& .ProseMirror': {
      width: '100%',
      position: 'absolute',
      boxSizing: 'border-box',
      fontSize: 15,
      overflowY: 'hidden',
      padding: '8px 12px',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      '& p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& > p:first-of-type': {
        marginTop: 0,
      },
    },
  },
  root: {
    '&& .ProseMirror-focused, &&.menu-open .ProseMirror': {
      minHeight: 120,
      whiteSpace: 'unset',
      position: 'relative',
      overflowY: 'scroll',
    },
  },
  blurbTextAreaCounter: {
    fontSize: '12px',
    textAlign: 'end',
    height: '21px',
    marginTop: '4px',
  },
  blurbTextAreaCounterError: {
    color: theme.colors.Red[600],
  },
  blockLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: Spacing.small,
  },
});

export const TeamBlurbInput: React.FC<Props> = (props) => {
  const styles = useStyles();
  const invalid =
    props.value && props.value.length > BLURB_TEXT_LENGTH_LIMIT
      ? true
      : props.value
      ? false
      : undefined;

  return (
    <div className={cx(styles.textInputContainer, styles.fullWidth)}>
      <label htmlFor="blurb" className={cx(styles.blockLabel)}>
        <Icon className={styles.icon} size="md" name="pin" />
        Team Blurb (required):
      </label>
      <Wysiwyg
        className={cx(styles.blurbTextArea, styles.root)}
        placeholder="Short description..."
        error={invalid}
        html={props.value.toString() || ''}
        onChange={(val): void => {
          props.onValueChange(val);
        }}
      />
      <div
        className={cx(
          styles.blurbTextAreaCounter,
          invalid ? styles.blurbTextAreaCounterError : '',
        )}
      >
        {`${getHTMLStringTextLength(props.value)} / ${BLURB_TEXT_LENGTH_LIMIT}`}
      </div>
    </div>
  );
};
