import {
  CompanyWantsToInterviewYouNotificationObject,
  NotificationType,
} from '@a_team/models/dist/NotificationObject';
import React, { useMemo } from 'react';
import Notification from './Notification';

interface CompanyWantsToInterviewYouProps {
  notification: CompanyWantsToInterviewYouNotificationObject;
  onMarkAsRead(): void;
  isRead?: boolean;
  onViewRequest: () => void;
}

const CompanyWantsToInterviewYou = ({
  isRead,
  onMarkAsRead,
  onViewRequest,
  notification,
}: CompanyWantsToInterviewYouProps) => {
  const companyName = notification.companyName;

  const title = `${companyName} wants to interview you`;

  const { description, responseExpired } = useMemo(() => {
    const createdAt = new Date(notification.createdAt);
    const now = new Date();
    const timeLeftMs =
      createdAt.getTime() + 24 * 60 * 60 * 1000 - now.getTime();

    if (timeLeftMs <= 0) {
      return {
        description: 'The response time has expired.',
        responseExpired: true,
      };
    }

    const hoursLeft = Math.floor(timeLeftMs / (1000 * 60 * 60));
    const minutesLeft = Math.floor(
      (timeLeftMs % (1000 * 60 * 60)) / (1000 * 60),
    );

    let description;
    if (hoursLeft > 0) {
      description = `Review the request and respond within ${hoursLeft} hour${
        hoursLeft > 1 ? 's' : ''
      }.`;
    } else {
      description = `Review the request and respond within ${minutesLeft} minute${
        minutesLeft > 1 ? 's' : ''
      }.`;
    }

    return { description, responseExpired: false };
  }, [notification.createdAt]);

  return (
    <>
      <Notification
        createdAt={notification.createdAt}
        notificationType={NotificationType.CompanyLikedYou}
        ctaName={responseExpired ? undefined : 'Review request'}
        ctaAction={() => {
          onViewRequest();
        }}
        title={title}
        description={description}
        imageUrl={notification.companyImageUrl}
        onMarkAsRead={onMarkAsRead}
        isRead={isRead}
      />
    </>
  );
};

export default CompanyWantsToInterviewYou;
