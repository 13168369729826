import { Select, SelectOption, theme } from '@a_team/ui-components';
import { Icon, IconType, TextColors } from '@ateams/components';
import { Expertise } from '@src/stores/Profile/models';
import cx from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useScreenClass } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import ErrorForm from '../common/ErrorForm';
import CommonStyles from '../common/styles';

const useStyles = createUseStyles({
  container: {
    minHeight: 94,
    position: 'relative',
    paddingTop: 45,
    cursor: 'pointer',
  },
  selectWrapper: {
    width: 300,
    height: 40,
    position: 'absolute',
    top: 2,
    left: 5,
    '& div[class*="control"] > div:last-child': {
      display: 'none',
    },
    '& div[class*="placeholder"]': {
      color: TextColors.regularLight,
      fontSize: 15,
      '& > span': {
        display: 'none',
      },
    },
  },
  skillsTagsWrapper: {
    display: 'flex',
    gap: 8,
    flexWrap: 'wrap',
  },
  skillTag: {
    padding: '5px 10px',
    borderRadius: 6,
    backgroundColor: '#F7F7F7',
    fontSize: 12,
    flex: 'wrap',
    cursor: 'default',
  },
});

interface SkillsProps {
  allSkills: Expertise[] | undefined;
}

const Skills = ({ allSkills }: SkillsProps): JSX.Element => {
  const {
    register,
    unregister,
    trigger,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const commonStyles = CommonStyles();
  const screenClass = useScreenClass();
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null as HTMLInputElement | null);

  useEffect(() => {
    register('skills', {
      validate: (value) => {
        if (!Array.isArray(value) || value.length === 0) {
          return 'At least one skill is required.';
        } else if (value.length > 12) {
          return "You can't have more than 12 skills.";
        }

        return true;
      },
    });

    return () => {
      unregister('skills');
    };
  }, [register, unregister]);

  const profileSkills = useMemo(() => {
    return allSkills?.map((skill) => {
      return { value: skill.id, label: skill.name };
    });
  }, [allSkills]);

  const skills: string[] = watch('skills') || [];

  const selectedSkills = useMemo(() => {
    if (skills.length === 0 || !profileSkills) {
      return [];
    }

    const skillsArray: { value: string; label: string }[] = [];
    skills.forEach((skill) => {
      const foundSkill = profileSkills.find((pSkill) => pSkill.value === skill);
      if (foundSkill) {
        skillsArray.push(foundSkill);
      }
    });
    return skillsArray;
  }, [skills]);

  const removeSkill = (skillId: string) => {
    const newSkills = skills.filter((skill) => skill !== skillId);
    setValue('skills', newSkills);
    trigger('skills');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  return (
    <div
      className={commonStyles.container}
      data-testing-id="profile-project-card-skills-used-select"
    >
      <div className={commonStyles.title}>Skills used</div>
      <div className={commonStyles.description}>
        Add up to 12 skills from your profile.
      </div>

      <div
        ref={containerRef}
        className={cx(commonStyles.box, styles.container)}
      >
        <div className={styles.selectWrapper}>
          <Select
            alwaysShowCounter={false}
            isMulti={true}
            placeholder="Enter skills"
            onChange={(skills) => {
              setValue(
                'skills',
                (skills as SelectOption[])
                  .filter((skill) => !!skill?.value)
                  .map((skill) => skill.value),
              );
              trigger('skills');
            }}
            value={selectedSkills}
            options={profileSkills}
            styles={{
              control: (base) => ({
                ...base,
                borderColor: theme.colors.Grey['400'],
                height: '21px',
                minHeight: '21px',
                border: 'none',
                backgroundColor: 'white',
              }),
            }}
            menuIsOpen={!isMobile ? isOpen : undefined}
          />
        </div>
        <div className={styles.skillsTagsWrapper}>
          {selectedSkills.map((skill) => (
            <div key={skill.value} className={styles.skillTag}>
              {skill.label}{' '}
              <Icon
                size="smaller"
                type={IconType.CloseGray}
                onClick={() => removeSkill(skill.value)}
              />
            </div>
          ))}
        </div>
      </div>
      <ErrorForm field="skills" errors={errors} />
    </div>
  );
};

export default Skills;
