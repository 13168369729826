import React, { ReactElement } from 'react';

const InactiveRatingStar = (
  props: JSX.IntrinsicElements['svg'],
): ReactElement | null => (
  <svg
    width="36"
    height="35"
    viewBox="0 0 36 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6499 1.39289C16.6818 -0.464297 19.3182 -0.464297 20.3501 1.39289L24.2438 8.40072C24.6287 9.09356 25.2918 9.58224 26.0603 9.73955L33.8335 11.3306C35.8936 11.7522 36.7083 14.2958 35.286 15.8652L29.9192 21.7873C29.3886 22.3729 29.1353 23.1636 29.2253 23.9536L30.1357 31.9448C30.377 34.0625 28.2442 35.6345 26.3332 34.7473L19.1227 31.3996C18.4098 31.0686 17.5902 31.0686 16.8773 31.3996L9.66676 34.7473C7.75585 35.6345 5.62297 34.0625 5.86425 31.9448L6.77467 23.9536C6.86468 23.1636 6.61142 22.3729 6.08081 21.7873L0.714024 15.8652C-0.708263 14.2958 0.106421 11.7522 2.16645 11.3306L9.93969 9.73955C10.7082 9.58224 11.3713 9.09356 11.7562 8.40072L15.6499 1.39289Z"
      fill="#EFEFF0"
    />
  </svg>
);

export default InactiveRatingStar;
