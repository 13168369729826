import React, { ReactElement, useRef, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import SearchInput from '../SearchInput';
import cx from 'classnames';
import { Breakpoints } from '@ateams/components';
import { ComingSoon } from '@src/views/Discovery/components/ComingSoon/ComingSoon';

interface Props {
  sticky: boolean;
  setStickyHeight(val: number): void;
  track: (section: string) => void;
}

const useStyles = createUseStyles({
  coverImage: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'start',
    padding: 12,
  },
  stickyContainer: {
    position: 'fixed',
    top: 0,
    padding: '24px',
    paddingLeft: '56px',
    paddingRight: '56px',
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 120px)',
    backgroundColor: '#f7f7f7',
    transition: 'all 0.5s',
    zIndex: 999,
  },
  slideIn: {
    top: -150,
  },
  slideOut: {
    top: -150,
  },
  searchBar: {
    top: 'unset',
    left: 'unset',
    position: 'relative',
    marginTop: '20px',
    marginBottom: '20px',
    marginLeft: 0,
    marginRight: 0,
  },
  comingSoonContainer: {
    minWidth: '33%',
    width: '100%',
    height: '56px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    coverImage: {
      paddingLeft: 56,
      paddingRight: 56,
    },
    searchBar: {
      width: '100%',
      paddingRight: '24px',
    },
    slideIn: {
      top: 0,
    },
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    comingSoonContainer: {
      width: '33%',
      marginTop: '20px',
    },
  },
});

export default function NewTopBar(props: Props): ReactElement {
  const styles = useStyles();
  const barRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (barRef.current) {
      props.setStickyHeight(barRef.current.offsetHeight);
    }
  }, []);
  return (
    <div ref={barRef}>
      <div className={styles.coverImage}>
        <div style={{ flex: 1 }}>
          <SearchInput className={styles.searchBar} />
        </div>
        <div className={styles.comingSoonContainer}>
          <ComingSoon />
        </div>
      </div>

      <div
        className={cx(
          styles.stickyContainer,
          props.sticky ? styles.slideIn : styles.slideOut,
        )}
      >
        <SearchInput
          withMenu={props.sticky}
          style={{ marginRight: '56px', width: '100%', position: 'relative' }}
        />
      </div>
    </div>
  );
}
