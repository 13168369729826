import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Checkbox } from '@ateams/components';

const useStyles = createUseStyles({
  checkbox: {
    marginTop: '0.7em',
  },
});

interface Props {
  badgeCriteria: Record<string, boolean | undefined>;
  onChange: (badgeCriteria: Record<string, boolean | undefined>) => void;
}

const BadgeChecklist = (props: Props): ReactElement => {
  const { badgeCriteria, onChange } = props;
  const {
    selectionTeam,
    beenOnMission,
    exceptionalATeamer,
    residentATeamer,
    vettedATeamer,
    vettingScheduled,
    unvetted,
    includeUnscrubbed,
    unqualified,
    limitedAccess,
    vettingInterviewDate,
  } = badgeCriteria;
  const styles = useStyles();

  const handleCheck = (badgeCriteria: Record<string, boolean | undefined>) => {
    onChange(badgeCriteria);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Checkbox
          className={styles.checkbox}
          onChange={(e) =>
            handleCheck({
              selectionTeam: e.target.checked ? e.target.checked : undefined,
            })
          }
          checked={selectionTeam || false}
          label="SelectionTeam"
          margin="none"
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Checkbox
          className={styles.checkbox}
          onChange={(e) =>
            handleCheck({
              beenOnMission: e.target.checked ? e.target.checked : undefined,
            })
          }
          checked={beenOnMission || false}
          label="Been on mission"
          margin="none"
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Checkbox
          className={styles.checkbox}
          onChange={(e) =>
            handleCheck({
              exceptionalATeamer: e.target.checked
                ? e.target.checked
                : undefined,
            })
          }
          checked={exceptionalATeamer || false}
          label="Exceptional A.Teamer"
          margin="none"
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Checkbox
          className={styles.checkbox}
          onChange={(e) =>
            handleCheck({
              residentATeamer: e.target.checked ? e.target.checked : undefined,
            })
          }
          checked={residentATeamer || false}
          label="A.Teamer in Residence"
          margin="none"
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Checkbox
          className={styles.checkbox}
          onChange={(e) =>
            handleCheck({
              vettedATeamer: e.target.checked ? e.target.checked : undefined,
            })
          }
          checked={vettedATeamer || false}
          label="Vetted A.Teamer"
          margin="none"
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Checkbox
          className={styles.checkbox}
          onChange={(e) =>
            handleCheck({
              limitedAccess: e.target.checked ? e.target.checked : undefined,
            })
          }
          checked={limitedAccess || false}
          label="Limited access"
          margin="none"
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Checkbox
          className={styles.checkbox}
          onChange={(e) =>
            handleCheck({
              vettingInterviewDate: e.target.checked
                ? e.target.checked
                : undefined,
            })
          }
          checked={vettingInterviewDate || false}
          label="Evaluation Call Booked"
          margin="none"
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Checkbox
          className={styles.checkbox}
          onChange={(e) =>
            handleCheck({
              vettingScheduled: e.target.checked ? e.target.checked : undefined,
            })
          }
          checked={vettingScheduled || false}
          label="Evaluation Call Pending"
          margin="none"
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Checkbox
          className={styles.checkbox}
          onChange={(e) =>
            handleCheck({
              unvetted: e.target.checked ? e.target.checked : undefined,
            })
          }
          checked={unvetted}
          label="Unvetted"
          margin="none"
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Checkbox
          className={styles.checkbox}
          onChange={(e) =>
            handleCheck({
              includeUnscrubbed: e.target.checked
                ? e.target.checked
                : undefined,
            })
          }
          checked={includeUnscrubbed || false}
          label="Not Scrubbed"
          margin="none"
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Checkbox
          className={styles.checkbox}
          onChange={(e) =>
            handleCheck({
              unqualified: e.target.checked ? e.target.checked : undefined,
            })
          }
          checked={unqualified || false}
          label="Unqualified"
          margin="none"
        />
      </div>
    </>
  );
};

export default BadgeChecklist;
