import { Breakpoints } from '@ateams/components';
import { observer } from 'mobx-react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Step, { StepStatus } from '../Steps/common/Step';
import StepIcon, { StepIconType } from '../Steps/common/Step/StepIcon';
import MainLayout from '@src/layouts/Main';
import Header from './../Header';
import { Link } from 'react-router-dom';
import { UPLOADCARE_ASSETS_BASE_URL } from '@src/config';

export const useStyles = createUseStyles({
  container: {
    maxWidth: 1240,
    margin: '0 auto',
    padding: '16px 0',
  },
  stepsContainer: {
    margin: '-5px auto 0',
    maxWidth: 680,
    display: 'flex',
    gap: 16,
    flexDirection: 'column',
    zIndex: 9999,
    position: 'relative',
    '& div[class*="description"]': {
      maxWidth: 512,
    },
  },
  headerSubtitle: {
    maxWidth: 600,
  },
  disclaimer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    color: '#818388',
    gap: 16,
    fontSize: 15,
    marginTop: 28,
    '& button': {
      width: 138,
      height: 44,
      fontSize: 14,
      borderColor: '#DADADC !important',
    },
  },
  image: {
    width: '100%',
    borderRadius: 16,
    marginBottom: 40,
    marginTop: 20,
  },
  defaultStep: {
    backgroundColor: '#F9FAFC',
    padding: 16,
    borderRadius: 16,
    marginBottom: 16,
  },
  link: {
    color: '#6D00D7 !important',
    fontSize: 15,
    textDecoration: 'underline',
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    marginTop: 20,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 15,
    fontWeight: 400,
    color: '#62646A',
    marginTop: 8,
    marginBottom: 16,
    textAlign: 'center',
    lineHeight: '24px',
  },
  thankYouEvaluationCallHeader: {
    '& h1': {
      maxWidth: 250,
      margin: '0 auto',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    stepsContainer: {
      gap: 8,
      margin: '-15px auto 40px',
      zIndex: 999,
    },
    disclaimer: {
      flexDirection: 'row',
    },
    image: {
      marginTop: -5,
    },
    title: {
      marginTop: 50,
    },
    thankYouEvaluationCallHeader: {
      '& h1': {
        maxWidth: '100%',
      },
    },
  },
});
const ThankYouEvaluationCall = () => {
  const styles = useStyles();

  const curatedMissionsStepPayload = {
    stepIcon: <StepIcon type={StepIconType.COMPLETED_CIRCLE} />,
    title: 'Receive invites to specific missions',
    description:
      "If a mission is published that matches your expertise, we'll invite you to apply via email. If you’re selected, you’ll gain full access to A.Team.",
    stepStatus: StepStatus.INFO,
    className: styles.defaultStep,
  };

  const gettingStartedStepPayload = {
    stepIcon: <StepIcon type={StepIconType.GETTING_STARTED} />,
    title: 'Opt out of receiving mission invitations',
    description: (
      <>
        Don’t want mission invites? You can turn off{' '}
        <Link to="/settings?menu=notifications" className={styles.link}>
          mission notifications
        </Link>{' '}
        in settings, or request to have your account deactivated by emailing{' '}
        <span className={styles.link}>support@a.team</span>.
      </>
    ),
    stepStatus: StepStatus.INFO,
    className: styles.defaultStep,
  };

  return (
    <MainLayout
      title="Thanks for joining the evaluation call"
      style={{ padding: '15px 24px' }}
    >
      <div className={styles.container}>
        <Header
          title="Thanks for joining the evaluation call"
          subtitle="We’re unable to grant you access at this time. At the moment, your skillset and experience don’t quite match the current needs of companies in the network; however, if you’re interested, we’d like to keep in touch about specific opportunities that may match your expertise."
          hideOptions
          className={styles.thankYouEvaluationCallHeader}
          subtitleClassName={styles.headerSubtitle}
          marginMobile="130px"
        />

        <div className={styles.stepsContainer}>
          <div>
            <img
              className={styles.image}
              src={`${UPLOADCARE_ASSETS_BASE_URL}8d51725b-738b-4e50-b41a-ef9ee2723647/`}
              alt="curated missions"
            />
            <Step {...curatedMissionsStepPayload} />
            <Step {...gettingStartedStepPayload} />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default observer(ThankYouEvaluationCall);
