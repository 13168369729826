/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { EventLogItem } from './types';

export const replayEventLog = async (
  target: any,
  events: EventLogItem[],
): Promise<void> => {
  for (const { args, fields } of events) {
    await replayEventLogItem(target, fields, args);
  }
};

const replayEventLogItem = async (
  target: any,
  fields: string[],
  args: unknown[],
) => {
  try {
    let o = target;
    for (const field of fields) {
      o = o[field];
    }
    await Promise.resolve(o(...args));
  } catch {
    return;
  }
};
