import {
  AdminVettingProcess,
  BasicVettingProcess,
} from '@a_team/models/dist/vetting-processes/vetting-processes';

export interface BasicVettingProcessColumnProps {
  vettingProcess: BasicVettingProcess;
  isAdminView: boolean;
}

export interface AdminVettingProcessColumnProps {
  vettingProcess: AdminVettingProcess;
  isAdminView: boolean;
}

export enum VettingBasicColumns {
  Name = 'name',
  MainRole = 'mainRole',
  SkillForVetting = 'skillForVetting',
  InterviewerReadOnly = 'interviewerReadOnly',
  CodeSample = 'codeSample',
  BuilderInfo = 'builderInfo',
  BuilderNoShow = 'builderNoShow',
  InterviewDate = 'interviewDate',
  CancelInterview = 'cancelInterview',
  SubmitFeedback = 'submitFeedback',
  FeedbackSubmitDate = 'feedbackSubmitDate',
  PhoneNumber = 'phoneNumber',
  Expertise = 'expertise',
  InteractionExperience = 'interactionExperience',
  EnglishLevel = 'englishLevel',
  Accent = 'accent',
}

export enum VettingAdminColumns {
  NameWithOptOutIndication = 'nameWithOptOutIndication',
  Status = 'status',
  InterviewType = 'interviewType',
  Interviewer = 'interviewer',
  InterviewerSuggestions = 'interviewerSuggestions',
  PreVettingFormSentDate = 'preVettingFormSentDate',
  EvaluationInvite = 'evaluationInvite',
  RemoveApplication = 'removeApplication',
  FeedbackScoresActions = 'feedbackScoresActions',
  ContactOwner = 'contactOwner',
  CreatedAt = 'createdAt',
  InterviewerNoShow = 'interviewerNoShow',
  Transcript = 'transcript',
  BookingLink = 'bookingLink',
}

export const columnsSortingOrder: VettingColumns[] = [
  VettingAdminColumns.NameWithOptOutIndication,
  VettingBasicColumns.Name,
  VettingAdminColumns.InterviewType,
  VettingBasicColumns.BuilderInfo,
  VettingBasicColumns.MainRole,
  VettingBasicColumns.SkillForVetting,
  VettingAdminColumns.Status,
  VettingAdminColumns.ContactOwner,
  VettingBasicColumns.BuilderNoShow,
  VettingBasicColumns.CodeSample,
  VettingBasicColumns.InterviewerReadOnly,
  VettingAdminColumns.Interviewer,
  VettingAdminColumns.InterviewerSuggestions,
  VettingAdminColumns.CreatedAt,
  VettingAdminColumns.PreVettingFormSentDate,
  VettingAdminColumns.EvaluationInvite,
  VettingAdminColumns.BookingLink,
  VettingBasicColumns.PhoneNumber,
  VettingBasicColumns.InterviewDate,
  VettingAdminColumns.InterviewerNoShow,
  VettingBasicColumns.FeedbackSubmitDate,
  VettingBasicColumns.Expertise,
  VettingBasicColumns.InteractionExperience,
  VettingBasicColumns.EnglishLevel,
  VettingBasicColumns.Accent,
  VettingAdminColumns.Transcript,
  VettingAdminColumns.FeedbackScoresActions,
  VettingBasicColumns.SubmitFeedback,
  VettingAdminColumns.RemoveApplication,
  VettingBasicColumns.CancelInterview,
];

export type VettingColumns = VettingBasicColumns | VettingAdminColumns;
