import { UserCardObject } from '@a_team/models/dist/UserObject';
import { Breakpoints, Colors, TextColors } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

interface MutualUserProps {
  user: UserCardObject;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    borderRadius: '8px',

    '&:hover': {
      background: Colors.backgroundLight,
      transition: 'background 0.2s ease-in-out',
    },
  },
  userFullName: {
    fontWeight: 500,
  },
  userTitle: {
    fontSize: '14px',
    color: '#818388',
  },
  viewProfileBtn: {
    background: '#EFEFF0',
    border: 'none',
    borderRadius: '4px',
    color: TextColors.regular,
    padding: '6.5px 16px',
    fontSize: '14px',
    '&:hover': {
      background: '#DADADC',
      color: TextColors.regular,
    },
  },
  userContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
    width: '100%',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '100%',
  },
  viewProfileDesktop: {
    display: 'none',
  },
  viewProfileMobile: {
    display: 'block',
  },
  userFullNameViewProfileBtnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    viewProfileDesktop: {
      display: 'block',
    },
    viewProfileMobile: {
      display: 'none',
    },
    userInfo: {
      width: 'fit-content',
    },
    userContainer: {
      width: 'fit-content',
    },
  },
});

const MutualUser = ({ user }: MutualUserProps): JSX.Element => {
  const styles = useStyles();

  const title =
    user.talentProfile?.talentSpecializations?.mainTalentSpecialization?.name;

  return (
    <div className={styles.container}>
      <div className={styles.userContainer}>
        <div>
          <UserAvatar
            src={user.profilePictureURL}
            alt={`${user.firstName} ${user.lastName}`}
          />
        </div>
        <div className={styles.userInfo}>
          <div className={styles.userFullNameViewProfileBtnContainer}>
            <div>
              <div className={styles.userFullName}>{user.fullName}</div>
              {title && <div className={styles.userTitle}>{title}</div>}
            </div>
            <div className={styles.viewProfileMobile}>
              <Link to={`/${user.username}`} className={styles.viewProfileBtn}>
                View Profile
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.viewProfileDesktop}>
        <Link to={`/${user.username}`} className={styles.viewProfileBtn}>
          View Profile
        </Link>
      </div>
    </div>
  );
};

export default MutualUser;
