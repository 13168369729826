import React, { ReactElement, useEffect } from 'react';
import {
  match as Match,
  matchPath,
  Redirect,
  Switch,
  useLocation,
} from 'react-router-dom';
import { createLocation, Location } from 'history';
import { fetchStoreData, Stores } from '@src/stores';
import { Page } from '@ateams/analytics/dist/platform';
// Views
import SignIn from '@src/views/SignIn';
import SignOut from '@src/views/SignOut';
import NotFound from '@src/views/NotFound';
import {
  AdminInvoicesClientInvoiceListLocationTemplate,
  AdminInvoicesDashboardLocation,
  AdminInvoicesToBeIssuedLocationTemplate,
  AdminInvoicesUnpaidInvoiceListLocationTemplate,
  AdminSearchLocation,
  AdminTeamPulseDashboardLocation,
  AdminTeamWorkCreatedMissions,
  AdminTeamWorkPublishedMissions,
  AdminTeamWorkPendingMissions,
  AdminTeamWorkRunningMissions,
  AdminTeamWorkArchivedMissions,
  AdminTeamWorkEndedMissions,
  AdminTeamWorkMissionsLocation,
  AdminTeamWorkSearchResults,
  AirtableLocation,
  ClientDashboardLocation,
  ClientTermsOfServiceLocation,
  CodeOfConductLocation,
  CompanyRegistrationSignUpLocationTemplate,
  CompanyRegistrationTeamRequestLocation,
  DiscoveryLocation,
  EditMissionLocationTemplate,
  EmailTemplatesLocation,
  SignatureCreatorLocation,
  GuideLocation,
  InvoicePdfLocationTemplate,
  MissionAboutLocationTemplate,
  MissionAdminAboutLocationTemplate,
  MissionAdminCreateTeamPulseLocationTemplate,
  MissionAdminDocumentsLocationTemplate,
  MissionAdminFAQLocationTemplate,
  MissionAdminTeamPulseLocationTemplate,
  MissionAdminNotificationsLocationTemplate,
  MissionAdminTimeTrackingLocationTemplate,
  MissionApplicationLocationTemplate,
  MissionApplyEditLocationTemplate,
  MissionApplyLocationTemplate,
  MissionDocumentsLocationTemplate,
  MissionControlBase,
  MissionControlLocationTemplate,
  MissionFAQLocationTemplate,
  NotificationPreferencesLocation,
  NotificationPreferencesLocationNoToken,
  MissionPageAdminLocationTemplate,
  MissionPageLocation,
  MissionPageLocationTemplate,
  MissionProposalLocationTemplate,
  MissionTeamPulseLocationTemplate,
  MissionTimeTrackingLocationTemplate,
  MyNetworkLocation,
  OAuthLocationTemplate,
  PrivacyPolicyLocation,
  ProfileLocationTemplate,
  RegistrationLocation,
  RegistrationForwardLocation,
  ResetPasswordLocation,
  SearchResultsLocationTemplate,
  SignInLocation,
  SignOutLocation,
  TeamGraphTargeterLocation,
  TalentPrivacyStatementLocation,
  TeamWorkLocation,
  TeamWorkPageLocationTemplate,
  TermsOfServiceLocation,
  UpdatePasswordLocation,
  VettingDashboardCompletedReviewsLocation,
  VettingDashboardGuidebookLocation,
  VettingDashboardLocation,
  VettingDashboardMembersLocation,
  VettingDashboardMemberToVetLocation,
  VettingDashboardPendingInterviewsLocation,
  AdminInvoicesPrepayPaymentsLocation,
  PreVettingFormLocation,
  PreVettingFormSubmittedLocation,
  NonVerifiedGuideLocation,
  TokensLocation,
  RegistrationInviteLocationTemplate,
  RootLocation,
  MissionControlRecommendedLocation,
  VettingFeedbackFormLocation,
  AdminTeamWorkScheduledToEndMissions,
  TargeterLocation,
  VettingFeedbackFormViewModeLocation,
  AStoreLocation,
  CommunityLocation,
  ArchitectsLocation,
  PostEvaluationSurveyLocation,
  PostEvaluationSurveySubmittedLocation,
  AdminInvoicesDiscountsLocation,
  AdminInvoicesDiscountsNewLocation,
  AdminInvoicesDiscountsViewLocationTemplate,
  ElasticTargeterLocation,
  VettingDashboardSelectionTeamOnboardingPlanLocation,
  PreVettingScheduleLocation,
  EvaluationSettingsLocation,
  SettingsLocation,
  VettingDashboardMembersLocationV2,
  ProfileImageEditLocationTemplate,
  MissionTeamTimeTrackingLocationTemplate,
  VettingDashboardRecordingLocation,
  TranscriptLocationTemplate,
  InviteLocation,
  SetupLocation,
  PreVettingFormViewLocation,
  EmailUnsubscribeLocation,
  EmailSubscribeLocation,
} from '@src/locations';
import TranscriptView from '@src/views/Transcript';
import ProfileView, { profileViewLoader } from '@src/views/Profile';
import OAuthView from '@src/views/OAuth';
import { UserType } from '@a_team/models/dist/UserObject';
import DiscoveryView from '@src/views/Discovery';
import MissionControlView, {
  missionControlLoader,
} from '@src/views/MissionControl';
import EditTeamWorkView, {
  editTeamWorkViewLoader,
} from '@src/views/Mission/EditMission';
import MissionPageView, { missionPageViewLoader } from '@src/views/Mission';
import MissionApplyView, {
  editApplyMissionView,
} from '@src/views/Mission/ApplyMission';
import SearchResultsView, { searchResultsViewLoader } from '@src/views/Search';
import AdminSearchView, { adminSearchViewLoader } from '@src/views/AdminSearch';
import ResetPasswordView from '@src/views/ResetPassword';
import UpdatePasswordView from '@src/views/UpdatePassword';
import CompanyRegistrationSignUpView from '@src/views/CompanyRegistration/SignUp';
import CompanyRegistrationTeamRequestView from '@src/views/CompanyRegistration/TeamRequest';
import MyNetworkView from '@src/views/MyNetwork';
import InvoicePdfView, { invoicePdfViewLoader } from '@src/views/InvoicePdf';
import AdminTeamWorkViewCreatedMissions from '@src/views/AdminDashboard/AdminTeamwork/CreatedMissions/CreatedMissions';
import AdminTeamWorkViewPublishedMissions from '@src/views/AdminDashboard/AdminTeamwork/PublishedMissions/PublishedMissions';
import AdminTeamWorkViewPendingMissions from '@src/views/AdminDashboard/AdminTeamwork/PendingMissions/PendingMissions';
import AdminTeamWorkViewRunningMissions from '@src/views/AdminDashboard/AdminTeamwork/RunningMissions/RunningMissions';
import AdminTeamWorkViewArchivedMissions from '@src/views/AdminDashboard/AdminTeamwork/ArchivedMissions/ArchivedMissions';
import AdminTeamWorkViewEndedMissions from '@src/views/AdminDashboard/AdminTeamwork/EndedMissions/EndedMissions';
import AdminTeamWorkViewScheduledToEndMissions from '@src/views/AdminDashboard/AdminTeamwork/ScheduledToEndMissions/ScheduledToEndMissions';
import AdminTeamWorkViewSearchResults from '@src/views/AdminDashboard/AdminTeamwork/SearchResults/SearchResults';
import MissionAdminPageView, {
  missionAdminViewLoader,
} from '@src/views/Mission/Admin';
import AboutMissionView, {
  aboutMissionViewLoader,
} from '@src/views/Mission/AboutMission';
import MissionTimeTrackingView, {
  missionTimeTrackingViewLoader,
} from '@src/views/Mission/TimeTracking';
import MissionDocumentsView, {
  missionDocumentsViewLoader,
} from '@src/views/Mission/Documents';
import AirtableView from '@src/views/AirtableView';
import PrivacyPolicyView from '@src/views/PrivacyPolicy';
import MissionProposalView, {
  missionProposalViewLoader,
} from '@src/views/Mission/Proposal';
import { TargeterView } from '@src/views/SkillTargeter';
import EmailTemplatesView from '@src/views/EmailTemplates';
import SignatureCreatorView from '@src/views/SignatureCreator';
import ClientDashboardView, {
  clientDashboardViewLoader,
} from '@src/views/ClientDashboard';
import MissionFAQView, { missionFAQViewLoader } from '@src/views/Mission/FAQ';
import VettingDashboardView from '@src/views/VettingDashboard';
import {
  loadAdminTeamWorkArchivedMissions,
  loadAdminTeamWorkCreatedMissions,
  loadAdminTeamWorkEndedMissions,
  loadAdminTeamWorkPendingMissions,
  loadAdminTeamWorkPublishedMissions,
  loadAdminTeamWorkRunningMissions,
  loadAdminTeamWorkScheduledToEndMissions,
  loadAdminTeamWorkSearchResults,
} from '@src/url-loaders/loadAdminTeamWork';
import MembersToVetView from '@src/views/VettingDashboard/members-to-vet';
import PendingInterviewsView from '@src/views/VettingDashboard/pending-interviews';
import GuidebookView from '@src/views/VettingDashboard/Guidebook';
import CompletedReviewsView from '@src/views/VettingDashboard/completed-reviews';
import VettingFeedbackFormContainer from '@src/views/VettingFeedbackFormV2';
import MissionTeamPulseView, {
  missionTeamPulseViewLoader,
} from '@src/views/Mission/TeamPulse/MissionTeamPulseView';
import MissionNotificationsView, {
  missionNotificationsViewLoader,
} from '@src/views/Mission/Notifications/MissionNotificationsView';
import MissionCreateTeamPulseView, {
  missionCreateTeamPulseViewLoader,
} from '@src/views/Mission/TeamPulse/MissionCreateTeamPulseView';
import NotificationPreferencesView from '@src/views/NotificationPreferences';
import EvaluationSettingsView from '@src/views/EvaluationSettings';
import SettingsView from '@src/views/Settings';
import { loadNotificationPreferences } from '@src/url-loaders/loadNotificationPreferences';
import GuideView, { NonVerifiedGuide } from '@src/views/Guide';
import AStoreView from '@src/views/AStore';
import CommunityView from '@src/views/Community';
import ArchitectsView from '@src/views/Architects';
import InvoicesAdminDashboardView from '@src/views/InvoicesAdminDashboard';
import TeamPulseDashboardView from '@src/views/TeamPulseDashboard';
import ClientInvoicesListView from '@src/views/InvoicesAdminDashboard/clientInvoicesList';
import EndedPaymentCyclesView, {
  invoicesToBeIssuedLoader,
} from '@src/views/InvoicesAdminDashboard/endedPaymentCycles';
import UnpaidInvoicesListView, {
  unpaidInvoicesLoader,
} from '@src/views/InvoicesAdminDashboard/unpaidInvoicesList';
import AdminInvoicesPrepayPaymentsView, {
  adminInvoicesPrepayPaymentsLoader,
} from '@src/views/InvoicesAdminDashboard/PrepayPayments';
import { loadRegistrationInvitation } from '@src/url-loaders/loadRegistrationInvitation';
import PreVettingView from '@src/views/PreVetting/form';
import PreVettingScheduleView from '@src/views/PreVetting/schedule';
import { loadRegistration } from '@src/url-loaders/loadRegistration';
import CustomRoute, { CustomRouteProps } from './CustomRoute';
import { PreVettingFormSubmittedNotice } from '@src/views/PreVetting/form-submitted-notice';
import TokensView from '@src/views/Tokens';
import { SelectionTeamExplorer } from '@src/views/VettingDashboard/selection-team-explorer';
import { SelectionTeamOnboardingPlan } from '@src/views/VettingDashboard/selection-team-onboarding-plan';
import { REGISTRATION_BASE_URL } from '@src/config';
import RejectionView from '@src/views/RejectionView';
import { PostEvaluationSurveyView } from '@src/views/VettingPostEvaluationSurvey/form';
import { PostEvaluationSurveySubmittedView } from '@src/views/VettingPostEvaluationSurvey/form-submitted-notice';
import AdminInvoicesDiscountsView, {
  adminInvoicesDiscountsLoader,
} from '@src/views/InvoicesAdminDashboard/Discounts';
import AdminInvoicesDiscountsNewView, {
  adminInvoicesDiscountsNewLoader,
} from '@src/views/InvoicesAdminDashboard/Discounts/New';
import AdminInvoicesDiscountsDiscountView, {
  adminInvoicesDiscountsDiscountLoader,
} from '@src/views/InvoicesAdminDashboard/Discounts/Discount';
import { loadEvaluationSettings } from '@src/url-loaders/loadEvaluationSettings';
import { loadSettings } from '@src/url-loaders/loadSettings';
import { SelectionTeamExplorerV2 } from '@src/views/VettingDashboard/selection-team-explorer-v2';
import ProfileImageView from '@src/views/ProfileImageUploader';
import SelectionTeamRecordingView from '@src/views/VettingDashboard/recording';
import Invite from '@src/views/Invite';
import Setup from '@src/views/Setup';
import EmailUnsubscribe from '@src/views/EmailUnsubscribe';

export * from './CustomRoute';

// Note: routes below ProfileLocationTemplate will be overridden and treated as a user profile route
const routes: CustomRouteProps[] = [
  {
    path: AirtableLocation,
    exact: true,
    withAuth: true,
    component: AirtableView,
    name: Page.AirtableView,
  },
  {
    path: TalentPrivacyStatementLocation,
    exact: true,
    withAuth: false,
    component: PrivacyPolicyView,
    name: Page.PrivacyPolicyView,
  },
  {
    path: PrivacyPolicyLocation,
    exact: true,
    withAuth: false,
    component: PrivacyPolicyView,
    name: Page.PrivacyPolicyView,
  },
  {
    path: TermsOfServiceLocation,
    exact: true,
    withAuth: false,
    component: PrivacyPolicyView,
    name: Page.PrivacyPolicyView,
  },
  {
    path: ClientTermsOfServiceLocation,
    exact: true,
    withAuth: false,
    component: PrivacyPolicyView,
    name: Page.PrivacyPolicyView,
  },
  {
    path: CodeOfConductLocation,
    exact: true,
    withAuth: false,
    component: PrivacyPolicyView,
    name: Page.PrivacyPolicyView,
  },
  {
    path: GuideLocation,
    exact: true,
    withAuth: { isActiveBuilder: true, fullAccess: true },
    component: GuideView,
    name: Page.GuideView,
  },
  {
    path: AStoreLocation,
    exact: true,
    withAuth: { fullAccess: true },
    component: AStoreView,
    name: Page.AStore,
  },
  {
    path: CommunityLocation,
    exact: true,
    withAuth: { fullAccess: true },
    component: CommunityView,
    name: Page.CommunityView,
  },
  {
    path: ArchitectsLocation,
    exact: true,
    withAuth: { fullAccess: true },
    component: ArchitectsView,
    name: Page.ArchitectsView,
  },
  {
    path: NonVerifiedGuideLocation,
    exact: true,
    withAuth: { isActiveBuilder: true },
    component: NonVerifiedGuide,
    name: Page.NonVerifiedGuideView,
  },
  {
    path: TokensLocation,
    exact: true,
    withAuth: true,
    component: TokensView,
    name: Page.TokensView,
  },
  {
    path: InviteLocation,
    exact: true,
    withAuth: {
      isActiveBuilder: true,
    },
    component: Invite,
    name: Page.InviteView,
  },
  {
    path: SetupLocation,
    exact: true,
    withAuth: true,
    component: Setup,
    name: Page.SetupView,
  },
  {
    path: VettingDashboardLocation,
    exact: true,
    withAuth: true,
    component: VettingDashboardView,
    name: Page.VettingDashboardView,
    immediate: true,
  },
  {
    path: VettingDashboardMemberToVetLocation,
    exact: true,
    withAuth: true,
    component: MembersToVetView,
    name: Page.VettingDashboardView,
  },
  {
    path: VettingDashboardGuidebookLocation,
    exact: true,
    withAuth: true,
    component: GuidebookView,
    name: Page.VettingDashboardView,
    immediate: true,
  },
  {
    path: VettingDashboardPendingInterviewsLocation,
    exact: true,
    withAuth: true,
    component: PendingInterviewsView,
    name: Page.VettingDashboardView,
  },
  {
    path: VettingDashboardCompletedReviewsLocation,
    exact: true,
    withAuth: true,
    component: CompletedReviewsView,
    name: Page.VettingDashboardView,
  },
  {
    path: VettingDashboardMembersLocation,
    exact: true,
    withAuth: true,
    component: SelectionTeamExplorer,
    name: Page.VettingDashboardView,
  },
  {
    path: VettingDashboardMembersLocationV2,
    exact: true,
    withAuth: true,
    component: SelectionTeamExplorerV2,
    name: Page.VettingDashboardView,
  },
  {
    path: VettingDashboardSelectionTeamOnboardingPlanLocation,
    exact: true,
    withAuth: { isAdmin: true },
    component: SelectionTeamOnboardingPlan,
    name: Page.VettingDashboardView,
  },
  {
    path: VettingDashboardRecordingLocation,
    exact: true,
    withAuth: true,
    component: SelectionTeamRecordingView,
    name: Page.SelectionTeamRecordingView,
  },
  {
    path: TranscriptLocationTemplate,
    exact: true,
    withAuth: true,
    component: TranscriptView,
    name: Page.TranscriptView,
  },
  {
    path: VettingFeedbackFormViewModeLocation,
    exact: true,
    withAuth: true,
    component: VettingFeedbackFormContainer,
    name: Page.VettingFeedbackFormViewModeView,
  },
  {
    path: VettingFeedbackFormLocation,
    exact: true,
    withAuth: true,
    component: VettingFeedbackFormContainer,
    name: Page.VettingFeedbackFormView,
  },
  {
    path: TeamGraphTargeterLocation,
    exact: true,
    withAuth: { isAdmin: true },
    redirect: TargeterLocation,
    redirectParams: true,
    component: TargeterView,
    name: Page.TargeterView,
  },
  {
    path: ElasticTargeterLocation,
    exact: true,
    withAuth: { isAdmin: true },
    redirect: TargeterLocation,
    redirectParams: true,
    component: TargeterView,
    name: Page.TargeterView,
  },
  {
    path: TargeterLocation,
    exact: true,
    withAuth: { isAdmin: true },
    component: TargeterView,
    name: Page.TargeterView,
  },
  {
    path: EmailTemplatesLocation,
    exact: true,
    withAuth: true,
    component: EmailTemplatesView,
    name: Page.EmailTemplatesView,
  },
  {
    path: SignatureCreatorLocation,
    exact: true,
    withAuth: true,
    component: SignatureCreatorView,
    name: Page.SignatureCreatorView,
  },
  {
    path: DiscoveryLocation,
    exact: true,
    withAuth: { type: UserType.User },
    component: DiscoveryView,
    name: Page.DiscoveryView,
  },
  {
    path: SearchResultsLocationTemplate,
    exact: true,
    withAuth: { type: UserType.User, verified: true },
    component: SearchResultsView,
    name: Page.SearchResultsView,
    serverSideLoader: searchResultsViewLoader,
    immediate: true,
  },
  {
    path: MyNetworkLocation,
    exact: true,
    withAuth: { type: UserType.User, fullAccess: true },
    component: MyNetworkView,
    name: Page.MyNetworkView,
  },
  {
    path: AdminTeamWorkCreatedMissions,
    serverSideLoader: loadAdminTeamWorkCreatedMissions,
    exact: true,
    withAuth: true,
    component: AdminTeamWorkViewCreatedMissions,
    name: Page.AdminTeamWorkView,
    immediate: true,
  },
  {
    path: AdminTeamWorkPublishedMissions,
    serverSideLoader: loadAdminTeamWorkPublishedMissions,
    exact: true,
    withAuth: true,
    component: AdminTeamWorkViewPublishedMissions,
    name: Page.AdminTeamWorkView,
    immediate: true,
  },
  {
    path: AdminTeamWorkPendingMissions,
    serverSideLoader: loadAdminTeamWorkPendingMissions,
    exact: true,
    withAuth: true,
    component: AdminTeamWorkViewPendingMissions,
    name: Page.AdminTeamWorkView,
    immediate: true,
  },
  {
    path: AdminTeamWorkRunningMissions,
    serverSideLoader: loadAdminTeamWorkRunningMissions,
    exact: true,
    withAuth: true,
    component: AdminTeamWorkViewRunningMissions,
    name: Page.AdminTeamWorkView,
    immediate: true,
  },
  {
    path: AdminTeamWorkArchivedMissions,
    serverSideLoader: loadAdminTeamWorkArchivedMissions,
    exact: true,
    withAuth: true,
    component: AdminTeamWorkViewArchivedMissions,
    name: Page.AdminTeamWorkView,
    immediate: true,
  },
  {
    path: AdminTeamWorkEndedMissions,
    serverSideLoader: loadAdminTeamWorkEndedMissions,
    exact: true,
    withAuth: true,
    component: AdminTeamWorkViewEndedMissions,
    name: Page.AdminTeamWorkView,
    immediate: true,
  },
  {
    path: AdminTeamWorkScheduledToEndMissions,
    serverSideLoader: loadAdminTeamWorkScheduledToEndMissions,
    exact: true,
    withAuth: true,
    component: AdminTeamWorkViewScheduledToEndMissions,
    name: Page.AdminTeamWorkView,
    immediate: true,
  },
  {
    path: AdminTeamWorkSearchResults,
    serverSideLoader: loadAdminTeamWorkSearchResults,
    exact: true,
    withAuth: true,
    component: AdminTeamWorkViewSearchResults,
    name: Page.AdminTeamWorkView,
    immediate: true,
  },
  {
    path: TeamWorkLocation,
    exact: true,
    withAuth: true,
    redirect: MissionControlBase,
    name: Page.MissionControlView,
  },
  {
    path: MissionControlBase,
    exact: true,
    withAuth: { isActiveBuilder: true },
    component: MissionControlView,
    name: Page.MissionControlView,
  },
  {
    path: RootLocation,
    exact: true,
    withAuth: { isRejected: true },
    component: RejectionView,
    name: Page.RejectionView,
    immediate: true,
    loadUser: true,
  },
  {
    path: RootLocation,
    exact: true,
    withAuth: { isActiveBuilder: true },
    redirect: MissionControlRecommendedLocation,
    name: Page.MissionControlView,
  },
  {
    path: MissionControlLocationTemplate,
    exact: true,
    withAuth: { isActiveBuilder: true },
    immediate: true,
    component: MissionControlView,
    name: Page.MissionControlView,
    serverSideLoader: missionControlLoader,
  },
  {
    path: AdminTeamWorkMissionsLocation,
    exact: true,
    withAuth: true,
    redirect: AdminTeamWorkCreatedMissions,
    name: Page.TeamWorkView,
  },
  {
    path: MissionPageAdminLocationTemplate,
    exact: true,
    withAuth: true,
    component: MissionAdminPageView,
    name: Page.MissionAdminPageView,
    serverSideLoader: missionAdminViewLoader,
  },
  {
    path: MissionApplicationLocationTemplate,
    exact: true,
    withAuth: true,
    immediate: true,
    component: MissionPageView,
    name: Page.MissionPageView,
    serverSideLoader: missionPageViewLoader,
  },
  {
    path: MissionPageLocationTemplate,
    exact: true,
    withAuth: true,
    component: MissionPageView,
    name: Page.MissionPageView,
    serverSideLoader: editTeamWorkViewLoader,
    immediate: true,
  },
  {
    path: MissionApplyLocationTemplate,
    exact: true,
    withAuth: true,
    component: MissionApplyView,
    name: Page.MissionApplyView,
    serverSideLoader: editApplyMissionView,
    immediate: true,
  },
  {
    path: MissionApplyEditLocationTemplate,
    exact: true,
    withAuth: true,
    component: MissionApplyView,
    name: Page.MissionApplyView,
    serverSideLoader: editApplyMissionView,
    immediate: true,
  },
  {
    path: MissionProposalLocationTemplate,
    exact: true,
    withAuth: false,
    component: MissionProposalView,
    name: Page.MissionProposalView,
    serverSideLoader: missionProposalViewLoader,
    immediate: true,
  },
  {
    path: MissionAboutLocationTemplate,
    exact: true,
    withAuth: true,
    component: AboutMissionView,
    name: Page.AboutMissionView,
    serverSideLoader: aboutMissionViewLoader,
    immediate: true,
  },
  {
    path: MissionAdminAboutLocationTemplate,
    exact: true,
    withAuth: true,
    component({ match }: { match: Match<{ mid: string }> }): ReactElement {
      return <AboutMissionView match={match} adminView />;
    },
    serverSideLoader: aboutMissionViewLoader,
    immediate: true,
    name: Page.AdminAboutMissionView,
  },
  {
    path: MissionFAQLocationTemplate,
    exact: true,
    withAuth: true,
    component: MissionFAQView,
    name: Page.MissionFAQView,
    serverSideLoader: missionFAQViewLoader,
    immediate: true,
  },
  {
    path: MissionAdminFAQLocationTemplate,
    exact: true,
    withAuth: true,
    component({ match }: { match: Match<{ mid: string }> }): ReactElement {
      return <MissionFAQView match={match} adminView />;
    },
    serverSideLoader: missionFAQViewLoader,
    immediate: true,
    name: Page.AdminMissionFAQView,
  },
  {
    path: MissionTimeTrackingLocationTemplate,
    exact: true,
    withAuth: true,
    component: MissionTimeTrackingView,
    name: Page.MissionTimeTrackingView,
    serverSideLoader: missionTimeTrackingViewLoader,
    immediate: true,
  },
  {
    path: MissionTeamTimeTrackingLocationTemplate,
    exact: true,
    withAuth: true,
    component: MissionTimeTrackingView,
    name: Page.MissionTimeTrackingView,
    serverSideLoader: missionTimeTrackingViewLoader,
    immediate: true,
  },
  {
    path: MissionAdminTimeTrackingLocationTemplate,
    exact: true,
    withAuth: true,
    component({ match }: { match: Match<{ mid: string }> }): ReactElement {
      return <MissionTimeTrackingView match={match} adminView />;
    },
    serverSideLoader: missionTimeTrackingViewLoader,
    immediate: true,
    name: Page.MissionTimeTrackingView,
  },
  {
    path: MissionDocumentsLocationTemplate,
    exact: true,
    withAuth: true,
    component: MissionDocumentsView,
    name: Page.MissionDocumentsView,
    serverSideLoader: missionDocumentsViewLoader,
    immediate: true,
  },
  {
    path: MissionAdminDocumentsLocationTemplate,
    exact: true,
    withAuth: true,
    component({ match }: { match: Match<{ mid: string }> }): ReactElement {
      return <MissionDocumentsView match={match} adminView />;
    },
    serverSideLoader: missionDocumentsViewLoader,
    immediate: true,
    name: Page.AdminMissionDocumentsView,
  },
  {
    path: MissionTeamPulseLocationTemplate,
    exact: true,
    withAuth: true,
    component: MissionTeamPulseView,
    name: Page.MissionTeamPulseView,
    serverSideLoader: missionTeamPulseViewLoader,
    immediate: true,
  },
  {
    path: MissionAdminTeamPulseLocationTemplate,
    exact: true,
    withAuth: true,
    component({ match }: { match: Match<{ mid: string }> }): ReactElement {
      return <MissionTeamPulseView match={match} adminView />;
    },
    serverSideLoader: missionTeamPulseViewLoader,
    immediate: true,
    name: Page.AdminMissionTeamPulseView,
  },
  {
    path: MissionAdminNotificationsLocationTemplate,
    exact: true,
    withAuth: true,
    component({ match }: { match: Match<{ mid: string }> }): ReactElement {
      return <MissionNotificationsView match={match} />;
    },
    serverSideLoader: missionNotificationsViewLoader,
    immediate: true,
    name: Page.AdminMissionNotificationsView,
  },
  {
    path: MissionAdminCreateTeamPulseLocationTemplate,
    exact: true,
    withAuth: true,
    component({ match }: { match: Match<{ mid: string }> }): ReactElement {
      return <MissionCreateTeamPulseView match={match} adminView />;
    },
    name: Page.AdminMissionCreateTeamPulseView,
    serverSideLoader: missionCreateTeamPulseViewLoader,
    immediate: true,
  },
  {
    path: EditMissionLocationTemplate,
    exact: true,
    withAuth: true,
    immediate: true,
    component: EditTeamWorkView,
    name: Page.EditTeamWorkView,
    serverSideLoader: editTeamWorkViewLoader,
  },
  {
    path: TeamWorkPageLocationTemplate,
    exact: true,
    withAuth: true,
    component({ match }: { match: Match<{ mid: string }> }): ReactElement {
      return <Redirect to={MissionPageLocation(match.params.mid)} />;
    },
    immediate: true,
    serverSideLoader: missionPageViewLoader,
    name: Page.AdminMissionPageView,
  },
  {
    path: NotificationPreferencesLocationNoToken,
    exact: true,
    withAuth: true,
    component: NotificationPreferencesView,
    name: Page.NotificationPreferences,
    serverSideLoader: loadNotificationPreferences,
    immediate: true,
  },
  {
    path: EmailUnsubscribeLocation,
    exact: true,
    withAuth: false,
    component: EmailUnsubscribe,
    name: Page.EmailUnsubscribe,
  },
  {
    path: EmailSubscribeLocation,
    exact: true,
    withAuth: false,
    component: EmailUnsubscribe,
    name: Page.EmailUnsubscribe,
  },
  {
    path: EvaluationSettingsLocation,
    exact: true,
    withAuth: true,
    component: EvaluationSettingsView,
    name: Page.EvaluationSettings,
    serverSideLoader: loadEvaluationSettings,
    immediate: true,
  },
  {
    path: SettingsLocation,
    exact: true,
    withAuth: true,
    component: SettingsView,
    name: Page.Settings,
    serverSideLoader: loadSettings,
    immediate: true,
  },
  {
    path: ProfileImageEditLocationTemplate,
    exact: true,
    withAuth: true,
    component: ProfileImageView,
    name: Page.ProfileImage,
    serverSideLoader: profileViewLoader,
    immediate: true,
  },
  {
    path: NotificationPreferencesLocation,
    exact: true,
    withAuth: false,
    component: NotificationPreferencesView,
    name: Page.NotificationPreferences,
    serverSideLoader: loadNotificationPreferences,
    immediate: true,
  },
  {
    path: ClientDashboardLocation,
    exact: true,
    withAuth: { isActiveCompanyUser: true },
    immediate: true,
    component: ClientDashboardView,
    name: Page.ClientDashboardView,
    serverSideLoader: clientDashboardViewLoader,
  },
  {
    path: CompanyRegistrationTeamRequestLocation,
    exact: true,
    withAuth: false,
    component: CompanyRegistrationTeamRequestView,
    name: Page.CompanyRegistrationTeamRequestView,
  },
  {
    path: CompanyRegistrationSignUpLocationTemplate,
    exact: true,
    withAuth: false,
    component: CompanyRegistrationSignUpView,
    name: Page.CompanyRegistrationSignUpView,
  },
  {
    path: RegistrationInviteLocationTemplate,
    exact: true,
    withAuth: false,
    component: () => {
      if (typeof window !== 'object') return null;
      window.location.replace(
        `${REGISTRATION_BASE_URL}${window.location.search}`,
      );
      return null;
    },
    name: Page.RegistrationView,
    serverSideLoader: loadRegistrationInvitation,
    immediate: true,
  },
  {
    path: RegistrationForwardLocation,
    exact: true,
    withAuth: false,
    redirect: RegistrationLocation,
    redirectParams: true,
    name: Page.RegistrationView,
  },
  {
    path: RegistrationLocation,
    exact: true,
    withAuth: false,
    component: () => {
      if (typeof window !== 'object') return null;
      window.location.replace(
        `${REGISTRATION_BASE_URL}${window.location.search}`,
      );
      return null;
    },
    name: Page.RegistrationView,
    serverSideLoader: loadRegistration,
    immediate: true,
  },
  {
    path: PreVettingFormLocation,
    withAuth: true,
    component: PreVettingView,
    name: Page.PreVettingFormView,
  },
  {
    path: PreVettingFormViewLocation,
    withAuth: true,
    component: PreVettingView,
    name: Page.PreVettingFormView,
  },
  {
    path: PreVettingScheduleLocation,
    withAuth: false,
    component: PreVettingScheduleView,
    name: Page.PreVettingScheduleView,
  },
  {
    path: PreVettingFormSubmittedLocation,
    exact: true,
    withAuth: false,
    component: PreVettingFormSubmittedNotice,
    name: Page.PreVettingFormView,
  },
  {
    path: PostEvaluationSurveyLocation,
    withAuth: true,
    component: PostEvaluationSurveyView,
    name: Page.PostEvaluationSurveyView,
  },
  {
    path: PostEvaluationSurveySubmittedLocation,
    exact: true,
    withAuth: true,
    component: PostEvaluationSurveySubmittedView,
    name: Page.PostEvaluationSurveyView,
  },
  {
    path: UpdatePasswordLocation,
    exact: true,
    withAuth: false,
    component: UpdatePasswordView,
    name: Page.UpdatePasswordView,
  },
  {
    path: ResetPasswordLocation,
    exact: true,
    withAuth: false,
    component: ResetPasswordView,
    name: Page.ResetPasswordView,
  },
  {
    path: SignInLocation,
    exact: true,
    withAuth: false,
    component: SignIn,
    name: Page.SignIn,
  },
  {
    path: SignOutLocation,
    exact: true,
    withAuth: false,
    component: SignOut,
    name: Page.SignOut,
  },
  {
    path: ProfileLocationTemplate,
    exact: true,
    withAuth: true,
    component: ProfileView,
    name: Page.ProfileView,
    serverSideLoader: profileViewLoader,
    immediate: true,
  },
  {
    path: AdminInvoicesUnpaidInvoiceListLocationTemplate,
    exact: true,
    withAuth: true,
    component: UnpaidInvoicesListView,
    name: Page.InvoiceDashboardView,
    serverSideLoader: unpaidInvoicesLoader,
    immediate: true,
  },
  {
    path: AdminInvoicesPrepayPaymentsLocation,
    exact: true,
    withAuth: true,
    component: AdminInvoicesPrepayPaymentsView,
    name: Page.InvoiceDashboardView,
    serverSideLoader: adminInvoicesPrepayPaymentsLoader,
    immediate: true,
  },
  {
    path: AdminInvoicesDiscountsLocation,
    exact: true,
    withAuth: true,
    component: AdminInvoicesDiscountsView,
    name: Page.InvoiceDashboardView,
    serverSideLoader: adminInvoicesDiscountsLoader,
    immediate: true,
  },
  {
    path: AdminInvoicesDiscountsNewLocation,
    exact: true,
    withAuth: true,
    component: AdminInvoicesDiscountsNewView,
    name: Page.InvoiceDashboardView,
    serverSideLoader: adminInvoicesDiscountsNewLoader,
    immediate: true,
  },
  {
    path: AdminInvoicesDiscountsViewLocationTemplate,
    exact: true,
    withAuth: true,
    component: AdminInvoicesDiscountsDiscountView,
    name: Page.InvoiceDashboardView,
    serverSideLoader: adminInvoicesDiscountsDiscountLoader,
    immediate: true,
  },
  {
    path: OAuthLocationTemplate,
    exact: true,
    withAuth: false,
    component: OAuthView,
    name: Page.OAuthView,
  },
  {
    path: AdminSearchLocation,
    exact: true,
    withAuth: true,
    component: AdminSearchView,
    name: Page.AdminSearchView,
    serverSideLoader: adminSearchViewLoader,
  },
  {
    path: InvoicePdfLocationTemplate,
    exact: true,
    withAuth: false,
    component: InvoicePdfView,
    name: Page.InvoicePdfView,
    serverSideLoader: invoicePdfViewLoader,
    immediate: true,
  },
  {
    path: AdminInvoicesDashboardLocation,
    exact: true,
    withAuth: true,
    component: InvoicesAdminDashboardView,
    name: Page.InvoiceDashboardView,
  },
  {
    path: AdminInvoicesClientInvoiceListLocationTemplate,
    exact: true,
    withAuth: true,
    component: ClientInvoicesListView,
    name: Page.InvoiceDashboardView,
  },
  {
    path: AdminInvoicesToBeIssuedLocationTemplate,
    exact: true,
    withAuth: true,
    component: EndedPaymentCyclesView,
    name: Page.InvoiceDashboardView,
    serverSideLoader: invoicesToBeIssuedLoader,
    immediate: true,
  },
  {
    path: AdminTeamPulseDashboardLocation,
    exact: true,
    withAuth: true,
    component: TeamPulseDashboardView,
    name: Page.InvoiceDashboardView,
  },
  {
    component: NotFound,
    name: Page.NotFound,
    withAuth: false,
  },
];

export interface RouteMatch {
  route: CustomRouteProps;
  match: Match;
  location: Location;
}

export function resolveRoute(stores: Stores, url: string): RouteMatch | null {
  const location = createLocation(url);

  for (const route of routes) {
    if (route.withAuth && !stores.auth.token) {
      continue;
    }

    // TODO: Remove this hack when we have a better solution
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const match = matchPath(location.pathname, route);
    if (!match) {
      continue;
    }

    return { route, match, location };
  }

  return null;
}

export const loadServerSideRouteData = async (
  routeMatch: RouteMatch | null,
  stores: Stores,
): Promise<void> => {
  if (!routeMatch) {
    return;
  }

  const { route, match, location } = routeMatch;

  if (!route.serverSideLoader && !route.loadUser) {
    return;
  }

  try {
    await fetchStoreData(stores);

    if (route.serverSideLoader) {
      await route.serverSideLoader(stores, match, location);
    }
  } catch (error) {
    stores.error.setError(error as Error);
  }
};

export default function Routes(): ReactElement {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      {routes.map((props, i) => (
        <CustomRoute key={i} {...props} />
      ))}
    </Switch>
  );
}
