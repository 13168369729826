import { ServiceAuth } from '@ateams/service-utils';
import { VettingType } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { ServiceEndpoint } from './utils';
import {
  BasicVetterObject,
  Vetter,
  VetterConfigurationObject,
  VetterId,
  VetterSummary,
} from '@a_team/models/src/vetter';
import {
  TalentCategoryId,
  TalentSkillId,
} from '@a_team/models/dist/TalentCategories';
import { QueryRequestPayload, QueryResult } from '@a_team/models/src/misc';
import { UserCardObject, UserId } from '@a_team/models/src/UserObject';

export const BasePath = '/vetter';

export interface FetchVettersFilters {
  user?: UserId;
  mainRole?: TalentCategoryId;
  vettingSkills?: TalentSkillId[];
  vettingRoles?: TalentCategoryId[];
  type?: VettingType;
  isActive?: boolean;
}

export interface FetchVettersV2Filters extends FetchVettersFilters {
  hasCalendarConnected?: boolean;
}

export interface CountVettersQuery {
  filters?: FetchVettersFilters;
}

export interface CountVettersV2Query {
  filters?: FetchVettersV2Filters;
}

export interface FetchVettersQuery extends CountVettersQuery {
  page?: number;
  batchSize?: number;
}

export interface FetchVettersV2Query extends CountVettersV2Query {
  page?: number;
  batchSize?: number;
}

export interface PostAdminVetterPayload {
  userId: UserId;
}

export interface DeleteAdminVetterPayload {
  userId: UserId;
}

export interface PatchAdminVetterPayload {
  vetterId: VetterId;
  type?: VettingType;
  vettingSkills?: TalentSkillId[];
  vettingRoles?: TalentCategoryId[];
  calendarUrl?: string;
  interviewCapacity?: number;
  emailSignature?: string;
  isActive?: boolean;
}

export interface AdminSearchVettersByExpression extends QueryRequestPayload {
  expression: string;
}

export interface VetterSearchVettersByExpression extends QueryRequestPayload {
  expression: string;
}

export default class VetterEndpoint extends ServiceEndpoint {
  /**
   * Returns the current user vetting configuration
   * @param auth - Selection Team
   */
  public fetchVettingConfiguration(
    auth: ServiceAuth,
  ): Promise<BasicVetterObject> {
    return this.fetch(auth, `${BasePath}/configuration`, null, 'get');
  }

  /**
   * Queries the Selection Team members
   * @param auth - Admins
   */
  public adminFetchVettersUserCards(
    auth: ServiceAuth,
    query: FetchVettersQuery,
    abortController?: AbortController,
  ): Promise<UserCardObject[]> {
    return this.fetch(
      auth,
      `${BasePath}/cards/admin`,
      query,
      'get',
      undefined,
      abortController,
    );
  }

  /**
   * Queries the Selection Team members
   * @param auth - Admins
   */
  public adminFetchVettersUserCardsV2(
    auth: ServiceAuth,
    query: FetchVettersQuery,
    abortController?: AbortController,
  ): Promise<UserCardObject[]> {
    return this.fetch(
      auth,
      `${BasePath}/v2/cards/admin`,
      query,
      'get',
      undefined,
      abortController,
    );
  }
  /**
   * Queries the Selection Team members
   * @param auth - Selection Team
   */
  public fetchVettersUserCards(
    auth: ServiceAuth,
    query: FetchVettersQuery,
    abortController?: AbortController,
  ): Promise<UserCardObject[]> {
    return this.fetch(
      auth,
      `${BasePath}/cards`,
      query,
      'get',
      undefined,
      abortController,
    );
  }

  /**
   * Queries the Selection Team members
   * @param auth - Admins
   */
  public adminFetchVettersWithSummary(
    auth: ServiceAuth,
    query: FetchVettersQuery,
    abortController?: AbortController,
  ): Promise<VetterSummary[]> {
    return this.fetch(
      auth,
      `${BasePath}/summary/admin`,
      query,
      'get',
      undefined,
      abortController,
    );
  }

  /**
   * Counts the Selection Team members
   * @param auth - Admins
   */
  public adminCountVetters(
    auth: ServiceAuth,
    query: CountVettersQuery,
    abortController?: AbortController,
  ): Promise<number> {
    return this.fetch(
      auth,
      `${BasePath}/count/admin`,
      query,
      'get',
      undefined,
      abortController,
    );
  }

  /**
   * Counts the Selection Team members
   * @param auth - Admins
   */
  public adminCountVettersV2(
    auth: ServiceAuth,
    query: CountVettersQuery,
    abortController?: AbortController,
  ): Promise<number> {
    return this.fetch(
      auth,
      `${BasePath}/v2/count/admin`,
      query,
      'get',
      undefined,
      abortController,
    );
  }

  /**
   * Counts the Selection Team members
   * @param auth - Selection Team
   */
  public countVetters(
    auth: ServiceAuth,
    query: CountVettersQuery,
    abortController?: AbortController,
  ): Promise<number> {
    return this.fetch(
      auth,
      `${BasePath}/count`,
      query,
      'get',
      undefined,
      abortController,
    );
  }

  /**
   * Turns an existing user into a vetter
   * @param auth - Admins
   */
  public createVetter(
    auth: ServiceAuth,
    payload: PostAdminVetterPayload,
  ): Promise<VetterConfigurationObject> {
    return this.fetch(auth, BasePath + '/admin', null, 'post', payload);
  }

  /**
   * Turns a vetter into a regular user
   * @param auth - Admins
   */
  public deleteVetter(
    auth: ServiceAuth,
    payload: DeleteAdminVetterPayload,
  ): Promise<void> {
    return this.fetch(auth, BasePath + '/admin', null, 'delete', payload);
  }

  /**
   * Updates the vetting configuration for a given vetter
   * @param auth - Admins
   */
  public updateVetter(
    auth: ServiceAuth,
    payload: PatchAdminVetterPayload,
  ): Promise<void> {
    return this.fetch(auth, BasePath + '/admin', null, 'PATCH', payload);
  }

  /**
   * Search vetters using an expression
   * The expression is searched against the email, first and last names
   * @param auth - Admins
   */
  public adminSearchVettersByExpression(
    auth: ServiceAuth,
    query: AdminSearchVettersByExpression,
  ): Promise<QueryResult<Vetter>> {
    return this.fetch(
      auth,
      `${BasePath}/admin/query/by-string`,
      { ...query },
      'get',
    );
  }

  /**
   * Search vetters using an expression
   * The expression is searched against the email, first and last names
   * @param auth - Selection Team(Only Internal Technical)
   */
  public vetterSearchVettersByExpression(
    auth: ServiceAuth,
    query: VetterSearchVettersByExpression,
  ): Promise<QueryResult<Vetter>> {
    return this.fetch(auth, `${BasePath}/query/by-string`, { ...query }, 'get');
  }

  /**
   * Search for a vetter by ID
   * throws 404 error if vetter is not associated with ID
   * @param auth - Admins
   */
  public adminGetVetterById(
    auth: ServiceAuth,
    vetterId: VetterId,
  ): Promise<Vetter> {
    return this.fetch(auth, `${BasePath}/admin/by-id/${vetterId}`, null, 'get');
  }

  /**
   * Search for a vetter by ID
   * throws 404 error if vetter is not associated with ID
   * @param auth - Selection Team(Only Internal Technical)
   */
  public vetterGetVetterById(
    auth: ServiceAuth,
    vetterId: VetterId,
  ): Promise<Vetter> {
    return this.fetch(auth, `${BasePath}/by-id/${vetterId}`, null, 'get');
  }
}
