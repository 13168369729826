import React from 'react';
import { createUseStyles } from 'react-jss';
import InfoBox from '../InfoBox';

const useStyles = createUseStyles({
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
  },
});

const Intro = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <h4>This is the A.Team Mission Code of Conduct.</h4>
      <p>
        Our vision is to transform the way companies build and the way people
        work. As with any new model, especially one that emphasizes autonomy,
        we’re iterating on these guidelines to make all of us successful, safe,
        and the experience as rewarding as can be!
      </p>

      <p>
        We worked with a committee of 10 builders to draft these guidelines. The
        aim is to ensure builders and companies have the best possible
        experiences working together — experiences that lead to increased trust,
        and then longer and repeated engagements, which benefits everyone.
      </p>

      <p>
        <strong>
          This document applies after you’ve joined a mission. Refer to the
          “Platform Code of Conduct” for general guidelines.{' '}
        </strong>
      </p>

      <p>
        Questions? Shoot a note to <a href="mailto:help@a.team">help@a.team</a>{' '}
        😎{' '}
      </p>

      <InfoBox withReminderCopy />
    </div>
  );
};

export default Intro;
