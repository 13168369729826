import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { ReasonsToConnectTable } from './ReasonsToConnectTable';
import Image from '@src/components/Image';
import { Breakpoints } from '@ateams/components';
import { CalendarHelp } from './CalendarHelp';

interface NewTeaserProps {
  onContinue: () => void;
  onSupport: () => void;
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 40,
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: 8,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '15px',
    lineHeight: '24px',
    maxWidth: '520px',
    textAlign: 'center',
  },
  unstyledButton: {
    all: 'unset',
    cursor: 'pointer',
    color: '#818388',
    paddingBottom: 40,
  },
  mobileImage: {
    display: 'block',
    width: '70%',
    height: 'auto',
    maxWidth: '1000px',
  },
  desktopImage: {
    display: 'none',
  },
  helpButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    mobileImage: {
      display: 'none',
    },
    desktopImage: {
      display: 'block',
      width: '100%',
      height: 'auto',
      maxWidth: '1000px',
    },
  },
});

export const NewTeaser = ({ onContinue, onSupport }: NewTeaserProps) => {
  const styles = useStyles();

  const [showHelp, setShowHelp] = useState(false);

  if (showHelp) {
    return (
      <CalendarHelp onClose={() => setShowHelp(false)} onSupport={onSupport} />
    );
  }

  return (
    <div className={styles.root}>
      <div>
        <h4 className={styles.title}>Make interview scheduling effortless</h4>
        <p className={styles.subtitle}>
          Choose how you want clients to schedule interviews — save time and
          avoid conflicts with calendar connection or set your availability
          manually.
        </p>
      </div>
      <Image
        className={styles.mobileImage}
        src="https://ucarecdn.com/75946aa0-b1eb-4461-8d80-a40fe83f0cd3/-/preview/1000x701/"
        alt="A.Team calendar banner"
        aspectRatio={1000 / 701}
      />
      <Image
        className={styles.desktopImage}
        src="https://ucarecdn.com/e5df0fc6-7125-4dd2-8d2d-be08654f834f/-/preview/1000x350/"
        alt="A.Team calendar banner"
        aspectRatio={1000 / 350}
      />
      <div>
        <ReasonsToConnectTable onContinue={onContinue} />
        <div className={styles.helpButtonContainer}>
          <button
            className={styles.unstyledButton}
            onClick={() => {
              setShowHelp(true);
            }}
          >
            Get help connecting
          </button>
        </div>
      </div>
    </div>
  );
};
