import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Icon, IconType } from '@ateams/components';
import { Colors } from '@ateams/components';
import { UploaderTypes } from '@src/components/FileUploader';
import { theme, Typography } from '@a_team/ui-components';
import { PurpleFiles } from '../PurpleFilesIcon';
import { Breakpoints } from '@ateams/components';

interface Props {
  errors?: string[];
  isDragging: boolean;
  type: UploaderTypes;
  hints?: string[];
  onUrlUpload: (url: string) => void;
  openDialog: () => void;
}

interface StyleProps {
  isDragging: boolean;
  type: Props['type'];
  errors?: string[];
}

const useStyles = createUseStyles({
  container: (props: StyleProps) => ({
    backdropFilter: 'blur(10px)',
    backgroundColor:
      props?.errors && props.errors.length > 0
        ? theme.colors.Red['200']
        : theme.colors.Hannibal['100'],
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    transition: 'all 0.5s',
  }),
  catDesktop: {
    display: 'block',
  },
  catMobile: {
    display: 'none',
  },
  uploadIcon: {
    marginBottom: 16,
  },
  browse: {
    color: '#6D00D7',
    textAlign: 'center',
  },
  errors: (props: StyleProps) => ({
    top: props.type === 'cover' ? 400 : 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  error: {
    color: Colors.danger,
    fontSize: 14,
    marginBottom: 5,
  },
  hintsWrapper: {
    marginTop: 5,
    fontSize: 14,
    color: '#62646A',
    textAlign: 'center',
  },

  [`@media (max-width: ${Breakpoints.sm}px)`]: {
    container: {
      padding: '40px 30px',
    },
    uploadIcon: {
      display: 'none',
    },
    catDesktop: {
      display: 'none',
    },
    catMobile: {
      textAlign: 'center',
      color: '#6D00D7',
      display: 'block',
    },
  },

  [`@media (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.md}px)`]:
    {
      container: (props: StyleProps) => ({
        height: '150px',
        padding: 20,
        justifyContent: 'flex-start',
      }),
    },
});

export const EmptyView = (props: Props): ReactElement => {
  const { errors, isDragging, type } = props;
  const styles = useStyles({ isDragging, type, errors });

  return (
    <div className={styles.container}>
      {isDragging ? (
        <Icon
          type={IconType.Upload}
          size="xlarge"
          className={styles.uploadIcon}
        />
      ) : (
        <div className={styles.uploadIcon}>
          <PurpleFiles />
        </div>
      )}
      <Typography variant={'h3'} margin={'none'}>
        <div className={styles.catDesktop}>
          Drag and drop an image, or{' '}
          <span className={styles.browse}>Browse Files</span>
        </div>
        <div className={styles.catMobile}>Upload an image</div>
      </Typography>

      <span className={styles.hintsWrapper}>
        {props.hints
          ? props.hints.map((hint, index) => (
              <div key={`hint-${index}`}>{hint}</div>
            ))
          : 'An image 1600x1200 or larger recommended, up to 10Mb.'}
      </span>
      {errors && (
        <div className={styles.errors}>
          {errors.map((error) => (
            <span key={error} className={styles.error}>
              {error}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
