import { normalizeQueryParams } from '@src/logic/utils';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = (): URLSearchParams => {
  const { search } = useLocation();
  const decodedSearchParams = decodeURIComponent(search);
  // this will adjust the query param to ensure that it has only one `?` and all params are glued with `&`
  const normalizedQueryParams = normalizeQueryParams(decodedSearchParams);

  return React.useMemo(
    () => new URLSearchParams(normalizedQueryParams),
    [decodedSearchParams],
  );
};
