import { Select, SelectOption } from '@ateams/components';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import RoleCategoryObject from '@a_team/models/dist/RoleCategory';

export type RoleCategorySelectOption = SelectOption & {
  roleCategory?: RoleCategoryObject;
};

interface RoleMultiSelectProps {
  selectedRoles: RoleCategorySelectOption[];
  setSelectedRoles: (roles: RoleCategorySelectOption[]) => void;
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
  },
  // select class name
  select: {
    margin: 0,
  },
});

const RoleMultiSelect = ({
  selectedRoles,
  setSelectedRoles,
}: RoleMultiSelectProps): JSX.Element => {
  const styles = useStyles();
  const { auth, roleCategories } = useStores();
  useEffect(() => {
    roleCategories.load(auth).catch((err) => console.error(err));
  }, []);

  const roles = useMemo(() => {
    return roleCategories?.categories?.map((role) => {
      return { value: role.cid, label: role.title, roleCategory: role };
    });
  }, [roleCategories.categories]);

  const handleOnChange = (selectedRoles: SelectOption[]) => {
    setSelectedRoles(selectedRoles);
  };

  const handleOptionRemoved = (removedRole: SelectOption) => {
    setSelectedRoles(
      selectedRoles.filter((role) => role.value !== removedRole.value),
    );
  };

  return (
    <div className={styles.container}>
      <Select
        data-testing-id="role-multi-select"
        menuPortalTarget={document.body}
        isMulti
        className={styles.select}
        classNamePrefix="ReactModal__select"
        options={roles}
        placeholder={'Select roles'}
        value={selectedRoles}
        tagListClassName={'tag-list'}
        onChange={(items) =>
          handleOnChange(items as RoleCategorySelectOption[])
        }
        onRemoveOption={(option) => handleOptionRemoved(option as SelectOption)}
      />
    </div>
  );
};

export default observer(RoleMultiSelect);
