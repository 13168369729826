import React, { RefObject } from 'react';
import { Card } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { Tag } from '@ateams/components';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import useClickOutside from '@src/hooks/useClickOutside';
import { SearchItem } from '@src/components/SearchMenu/Searchitem';
import { ProfileLocation } from '@src/locations';
import { SearchCategories } from '@src/stores/Search';
import cx from 'classnames';

interface Props {
  onClose(): void;
  searchRef: RefObject<HTMLElement>;
  highlightedIndex?: number;
  hideTags?: boolean;
}

const useStyles = createUseStyles<{
  hideTags?: boolean;
}>({
  container: {
    position: 'absolute',
    width: '100%',
    padding: 0,
    top: 65,
    zIndex: 999,
    overflow: 'hidden',
  },
  highlighted: {
    background: '#FFFAF7',
    '& >.icon': {
      background: 'rgba(254,99,12, 0.05)',
    },
  },
  inner: {
    padding: ({ hideTags }) => (hideTags ? '8px' : '24px'),
  },
  listTitle: {
    padding: '0 24px',
    marginBottom: 5,
    marginTop: 5,
    fontWeight: 600,
  },
  clear: {
    color: '#F63131',
    fontWeight: 500,
    marginRight: 24,
    cursor: 'pointer',
  },
});

const SearchMenu = observer((props: Props) => {
  const styles = useStyles({
    hideTags: props.hideTags,
  });
  const history = useHistory();
  const { search } = useStores();
  const { highlightedIndex } = props;
  useClickOutside(props.searchRef, props.onClose);

  return (
    <Card className={styles.container}>
      <div className={styles.inner}>
        {!props.hideTags &&
          Object.keys(SearchCategories).map((category) => (
            <Tag
              key={category}
              color="success"
              style={{
                marginRight: 16,
                ...(search.searchCategory !== category && {
                  background: '#F7F7F7',
                  cursor: 'pointer',
                  color: '#62646A',
                }),
              }}
              onClick={(): void => search.onCategoryChange(category)}
            >
              {category}
            </Tag>
          ))}
      </div>
      <div>
        {search.shownUserSearchResults.length > 0 && (
          <div style={{ marginBottom: 5 }}>
            <h4 className={styles.listTitle}>Search Results</h4>
            {search.shownUserSearchResults.map((item, i) => {
              return (
                <SearchItem
                  key={item._id}
                  item={item}
                  className={cx({
                    [styles.highlighted]: i === highlightedIndex,
                  })}
                  onSelect={(item): void => {
                    search.addSearchHistory(item);
                    item.user?.username &&
                      history.push(ProfileLocation(item.user?.username));
                  }}
                />
              );
            })}
          </div>
        )}
        {search.recentSearches.length > 0 && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h4 className={styles.listTitle}>Search History</h4>
              <span
                className={styles.clear}
                onClick={search.clearSearchHistory}
              >
                Clear History
              </span>
            </div>
            {search.recentSearches.map((searchItem, i) => {
              const index = search.shownUserSearchResults.length + i;
              return (
                <SearchItem
                  className={cx({
                    [styles.highlighted]: index === highlightedIndex,
                  })}
                  key={searchItem._id}
                  item={searchItem}
                  onSelect={(item): void => {
                    'user' in item && item.user?.username
                      ? history.push(ProfileLocation(item.user?.username))
                      : search.onExistingSearchSelect(item);
                  }}
                />
              );
            })}
          </>
        )}
      </div>
    </Card>
  );
});

export default SearchMenu;
