type DateISOString = string;

export enum ReminderPeriod {
  OneMonth = 'OneMonth',
  ThreeMonths = 'ThreeMonths',
  SixMonths = 'SixMonths',
}

export enum AvailableType {
  Now = 'Now',
  FutureDate = 'FutureDate',
  NotAvailable = 'NotAvailable',
  UnknownAvailability = 'UnknownAvailability',
}

export interface AvailableNow {
  type: AvailableType.Now;
  weeklyHoursAvailable: number;
  rejectedUpdatePrompt?: DateISOString;
  notes?: string;
}

export interface AvailableInTheFuture {
  type: AvailableType.FutureDate;
  date: DateISOString;
  weeklyHoursAvailable: number;
  rejectedUpdatePrompt?: DateISOString;
  notes?: string;
}

export interface NotAvailable {
  type: AvailableType.NotAvailable;
  remindMeAt: DateISOString;
  keepNotifications?: boolean;
  rejectedUpdatePrompt?: DateISOString;
  notes?: string;
}

export interface UnknownAvailability {
  type: AvailableType.UnknownAvailability;
  rejectedUpdatePrompt?: DateISOString;
  notes?: string;
}

export type AvailabilityData =
  | AvailableNow
  | AvailableInTheFuture
  | NotAvailable
  | UnknownAvailability;

export interface AvailabilityObject {
  updatedAt?: DateISOString;
  availability: AvailabilityData;
  rejectedUpdatePrompt?: DateISOString;
}

export type UpdateAvailabilityRequest =
  | AvailableNow
  | AvailableInTheFuture
  | NotAvailable;

export interface AvailabilitySummaryObject {
  type: AvailableType;
  updatedAt?: DateISOString;
  remindMeAt?: DateISOString;
  availableFrom?: string;
  weeklyHoursAvailable?: number;
  /** @deprecated using notificationPreferences */
  keepNotifications?: boolean;
  rejectedUpdatePrompt?: DateISOString;
  notes?: string;
}
