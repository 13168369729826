import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useLoadingState, { LoadingState } from '@src/hooks/useLoadingState';
import { useAnalytics, UserEvent } from '@ateams/analytics/dist/platform';
import { useStores } from '@src/stores';
import {
  SurveyQuestionMap,
  SurveyQuestionResponse,
  SurveyQuestionType,
  SurveyResponseMap,
  SurveyType,
} from '@a_team/models/dist/Survey';
import { TeamPulseSurvey as TTeamPulseSurvey } from '@a_team/models/dist/TeamPulse';
import { createUseStyles } from 'react-jss';
import { Breakpoints, Button as CallToActionButton } from '@ateams/components';
import { apiTeamPulse } from '@ateams/api';
import { PersonRatingWithFreeFormCommentSurveyQuestionProps } from '@a_team/models/dist/Survey/questions/types/PersonRatingWithFreeFormCommentSurveyQuestion';
import {
  AnonymousUserObject,
  BasicUserObject,
} from '@a_team/models/dist/UserObject';
import TeamPulseSurveyModalSidebar from '@src/views/Mission/TeamPulse/components/TeamPulseSurveyModalSidebar';
import cx from 'classnames';
import UserAvatar from '@src/components/UserAvatar';
import Question from '@src/components/Survey/Question';
import Modal from '@src/components/Modal';

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    height: '100%',
  },
  sidebar: {
    flex: '0 0 300px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.08)',
    marginLeft: '-24px',
    marginTop: '-40px',
    marginBottom: '-40px',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '40px',
    paddingBottom: '40px',
    [`@media (max-width: ${Breakpoints.sm})`]: {
      display: 'none',
    },
  },
  questionView: {
    flex: '1 0 0%',
    [`@media (max-width: ${Breakpoints.sm})`]: {
      paddingLeft: 0,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  multiQuestion: {
    textAlign: 'center',
    paddingLeft: '24px',
  },
  question: {
    maxWidth: '540px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  questionHeadingContainer: {
    marginTop: 0,
    marginBottom: '32px',
  },
  questionHeading: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    fontWeight: 600,
    color: '#222222',
    fontSize: '28px',
    lineHeight: '34px',
    margin: 0,
  },
  multiQuestionHeading: {
    alignSelf: 'unset',
  },
  nextButton: {
    '&:disabled': {
      background: '#FFCBAE',
    },
  },
  block: {
    display: 'block',
  },
});

interface TeamPulseSurveyHelpersProps<
  T extends SurveyType<keyof T> = SurveyType<never>,
> {
  onClose?: VoidFunction;
  teamPulseSurvey: TTeamPulseSurvey<T>;
  adminView?: boolean;
  defaultResponses?: Responses<T>;
}

export type Responses<T extends SurveyType<keyof T> = SurveyType<never>> = {
  [K in keyof T]?:
    | {
        valid: boolean;
        error: Error | null;
        value: SurveyQuestionResponse[T[K]];
      }
    | undefined;
};

interface TeamPulseModalProps<
  T extends SurveyType<keyof T> = SurveyType<never>,
> {
  onCloseHandler: VoidFunction;
  open: boolean;
  questionCount: number;
  sideBarItems: {
    [K in keyof T]: {
      question: SurveyQuestionMap<T>[K];
      completed: boolean;
      disabled: boolean;
    };
  };
  questionKey: keyof T;
  questionIndex: number;
  setQuestionIndex: Dispatch<SetStateAction<number>>;
  keys: (keyof T)[];
  activeQuestionUser: AnonymousUserObject | BasicUserObject | null | undefined;
  activeQuestion: SurveyQuestionMap<T>[keyof T];
  onInput: (value: {
    value: SurveyQuestionResponse[T[keyof T]];
    valid: boolean;
    error?: Error | null | undefined;
  }) => void;
  isLastQuestion: boolean;
  onClick: (event: MouseEvent) => void;
  responses: Responses<T>;
  loading: LoadingState;
}

interface HelperSpecificReturnType {
  loading: LoadingState;
}

export const useTeamPulse = <
  T extends SurveyType<keyof T> = SurveyType<never>,
>({
  onClose,
  teamPulseSurvey: _teamPulseSurvey,
  adminView,
  defaultResponses,
}: TeamPulseSurveyHelpersProps<T>): TeamPulseModalProps<T> &
  HelperSpecificReturnType => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useLoadingState();

  const teamPulseSurvey = useMemo(() => {
    const questionsToFilterOut: (string | number | symbol)[] = [
      'canWeShareFeedbackWithClient',
      'tellUsMore',
      'isScopedWellDefined',
      'howAdditionalBuildersCouldHelp',
      'whatCouldImproveExperience',
      'shareProudMoments',
      'shareHighlights',
      'canMissionUseMoreBuilders',
      'rolesRecommendedForMission',
      'buildersRecommendedForMission',
      'whyTheseBuilders',
    ];

    const filteredQuestions = (
      Object.keys(
        _teamPulseSurvey.questions,
      ) as (keyof typeof _teamPulseSurvey.questions)[]
    )
      .filter(
        (key: keyof typeof _teamPulseSurvey.questions) =>
          !questionsToFilterOut.includes(key),
      )
      .reduce(
        (obj, key) => ({
          ...obj,
          [key]: _teamPulseSurvey.questions[key],
        }),
        {},
      );

    return {
      ..._teamPulseSurvey,
      questions: filteredQuestions as SurveyQuestionMap<T>,
    };
  }, [_teamPulseSurvey]);

  const onCloseHandler = () => {
    setOpen(false);
    onClose?.();
    setLoading(false);
  };

  const analytics = useAnalytics();
  const { auth, missions } = useStores();
  const { currentMission } = missions;

  useEffect(() => {
    if (currentMission && teamPulseSurvey)
      analytics.track(UserEvent.TeamPulseSurveyStarted, {
        mid: currentMission.mid,
        teamPulseId: teamPulseSurvey.teamPulseId,
        teamPulseSurveyId: teamPulseSurvey.teamPulseSurveyId,
      });
  }, [currentMission, teamPulseSurvey]);

  const submit = async <T extends SurveyType<keyof T>>(
    response: SurveyResponseMap<T>,
  ) => {
    const promise = apiTeamPulse.submitTeamPulseSurveyResponse(auth, {
      response,
      teamPulseSurveyId: teamPulseSurvey.teamPulseSurveyId,
    });
    setLoading(promise);
    await promise;

    setTimeout(onCloseHandler, 3000);
    setOpen(false);

    if (!currentMission) {
      throw new Error('currentMission is unset');
    }

    analytics.track(UserEvent.TeamPulseSurveySubmitted, {
      mid: currentMission.mid,
      teamPulseId: teamPulseSurvey.teamPulseId,
      teamPulseSurveyId: teamPulseSurvey.teamPulseSurveyId,
      overallRating: (
        response as unknown as SurveyResponseMap<{
          overallRating: SurveyQuestionType.RatingWithFreeFormComment;
        }>
      ).overallRating?.value as number,
    });

    await currentMission.refreshTeamPulse(adminView);
  };

  const questionCount = useMemo(
    () => Object.keys(teamPulseSurvey.questions).length,
    [teamPulseSurvey.questions],
  );
  const keys = Object.keys(teamPulseSurvey.questions) as Array<keyof T>;
  const [questionIndex, setQuestionIndex] = useState(0);
  const isLastQuestion = useMemo(
    () => questionIndex === questionCount - 1,
    [questionIndex, questionCount],
  );
  const questionKey = useMemo(() => keys[questionIndex], [keys, questionIndex]);
  const activeQuestion = useMemo(
    () => teamPulseSurvey.questions[questionKey],
    [teamPulseSurvey.questions, questionKey],
  );
  const activeQuestionUserId = useMemo(
    () =>
      (
        activeQuestion.props as PersonRatingWithFreeFormCommentSurveyQuestionProps
      ).uid,
    [activeQuestion],
  );
  const activeQuestionUser = useMemo(
    () =>
      activeQuestionUserId
        ? currentMission?.data.roles.find(
            (role) =>
              role.user &&
              (role.user as BasicUserObject).uid === activeQuestionUserId,
          )?.user
        : undefined,
    [activeQuestionUserId, currentMission],
  );

  const [responses, setResponses] = useState<Responses<T>>(
    defaultResponses || {},
  );

  const sideBarItems = useMemo(
    () =>
      Object.fromEntries(
        (
          Object.entries(teamPulseSurvey.questions) as Array<
            [keyof T, SurveyQuestionMap<T>[keyof T]]
          >
        ).map(
          <K extends keyof T>(
            [key, question]: [K, SurveyQuestionMap<T>[K]],
            index: number,
          ) => [
            key,
            {
              question,
              completed: !!responses[key],
              disabled: index > questionIndex,
            },
          ],
        ),
      ) as {
        [K in keyof T]: {
          question: SurveyQuestionMap<T>[K];
          completed: boolean;
          disabled: boolean;
        };
      },
    [teamPulseSurvey.questions],
  );

  const onInput = (value: {
    value: SurveyQuestionResponse[T[keyof T]];
    valid: boolean;
    error?: Error | null;
  }) => {
    setResponses({
      ...responses,
      [questionKey]: value,
    });
  };

  const onClick = (event: MouseEvent) => {
    event.preventDefault();
    if (isLastQuestion) {
      submit(
        Object.fromEntries(
          // The type cast is required because Object.entries does not return
          // useful types automatically
          (
            Object.entries(responses) as Array<
              [
                keyof T,
                {
                  valid: boolean;
                  error: Error | null;
                  value: SurveyQuestionResponse[T[keyof T]];
                },
              ]
            >
          ).map(([key, { value }]) => [key, value]),
        ),
      );
    } else {
      if (responses[questionKey]?.valid) {
        setQuestionIndex(questionIndex + 1);
      }
    }
  };

  return {
    open,
    loading,
    activeQuestionUser,
    sideBarItems,
    onInput,
    onClick,
    onCloseHandler,
    questionCount,
    questionKey,
    setQuestionIndex,
    questionIndex,
    keys,
    responses,
    activeQuestion,
    isLastQuestion,
  };
};

export function TeamPulseModal<
  T extends SurveyType<keyof T> = SurveyType<never>,
>({
  onCloseHandler,
  open,
  questionCount,
  sideBarItems,
  questionKey,
  questionIndex,
  setQuestionIndex,
  keys,
  activeQuestionUser,
  activeQuestion,
  onInput,
  isLastQuestion,
  onClick,
  responses,
  loading,
}: TeamPulseModalProps<T>): React.ReactElement {
  const styles = useStyles();
  return (
    <Modal
      onClose={onCloseHandler}
      open={open}
      hideCloseButton={questionCount > 1}
      style={
        questionCount > 1
          ? {
              width: '1138px',
              maxWidth: 'calc(100% - 48px)',
              maxHeight: 'calc(100% - 48px)',
              height: '780px',
            }
          : {}
      }
    >
      <div className={styles.row}>
        {questionCount > 1 && (
          <TeamPulseSurveyModalSidebar
            className={styles.sidebar}
            items={sideBarItems}
            selectedItem={questionKey}
            onSelectItem={(key) => {
              setQuestionIndex(keys.indexOf(key));
            }}
          />
        )}
        <div
          className={cx(
            styles.questionView,
            questionCount > 1 && styles.multiQuestion,
          )}
        >
          <div className={styles.block}>
            <div className={styles.questionHeadingContainer}>
              {questionIndex === 0 && (
                <h2
                  className={cx(
                    styles.questionHeading,
                    questionCount > 1 && styles.multiQuestionHeading,
                  )}
                >
                  Feedback Form
                </h2>
              )}
              {activeQuestionUser && (
                <h2
                  className={cx(
                    styles.questionHeading,
                    questionCount > 1 && styles.multiQuestionHeading,
                  )}
                >
                  <UserAvatar
                    size={32}
                    src={
                      activeQuestionUser?.username
                        ? activeQuestionUser?.profilePictureURL
                        : undefined
                    }
                    containerStyle={{ marginRight: '8px' }}
                  />
                  {activeQuestionUser.fullName}
                </h2>
              )}
            </div>
            <Question
              className={styles.question}
              question={activeQuestion}
              onInput={onInput}
              value={responses[questionKey]?.value}
            />
            <div className={styles.buttons}>
              <CallToActionButton
                className={styles.nextButton}
                disabled={!responses[questionKey]?.valid || loading === true}
                onClick={onClick}
              >
                {isLastQuestion ? 'Submit' : 'Next Step'}
              </CallToActionButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
