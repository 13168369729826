import { DropdownMenu, DropdownMenuItem, Icon } from '@a_team/ui-components';
import cx from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface HeaderProps {
  toggle: () => void;
  markAllAsRead?: () => void;
  onlyUnread: boolean;
  setOnlyUnread: (value: boolean) => void;
}

const useStyles = createUseStyles({
  container: {
    fontSize: 14,
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DADADC',
  },
  markAsReadBtn: {
    color: '#818388',
  },
  closeBtn: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    '& span': {
      height: 20,
    },
  },

  btn: {
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    outline: 'none',
    // svg cursor pointer
    '& svg': {
      cursor: 'pointer !important',
    },
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  selected: {
    color: '#6D00D7',
  },
  btnsContainer: {
    display: 'flex',
  },
});

const Header = ({
  toggle,
  markAllAsRead,
  onlyUnread,
  setOnlyUnread,
}: HeaderProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <button className={cx(styles.btn, styles.closeBtn)} onClick={toggle}>
        <Icon size="sm" name="arrowBack" /> Close
      </button>
      <div className={styles.btnsContainer}>
        {markAllAsRead && (
          <button
            onClick={markAllAsRead}
            className={cx(styles.btn, styles.markAsReadBtn)}
          >
            Mark all as read
          </button>
        )}
        <DropdownMenu
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: 5,
          }}
          triggerComponent={
            <button className={cx(styles.btn)}>
              <Icon size="md" name="filter" />
            </button>
          }
        >
          <DropdownMenuItem
            onClick={() => {
              setOnlyUnread(false);
            }}
          >
            <div className={styles.dropdownItem}>
              <Icon
                style={{
                  visibility: !onlyUnread ? 'visible' : 'hidden',
                }}
                color="Hannibal@500"
                size="md"
                name="statusPositiveNoBorder"
              />

              <span
                className={cx({
                  [styles.selected]: !onlyUnread,
                })}
              >
                Show all notifications
              </span>
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              setOnlyUnread(true);
            }}
          >
            <div className={styles.dropdownItem}>
              <Icon
                style={{
                  visibility: onlyUnread ? 'visible' : 'hidden',
                }}
                color="Hannibal@500"
                size="md"
                name="statusPositiveNoBorder"
              />
              <span
                className={cx({
                  [styles.selected]: onlyUnread,
                })}
              >
                Show unread notifications only
              </span>
            </div>
          </DropdownMenuItem>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default Header;
