import {
  useRejectProfileSuggestion,
  useGetProfileSuggestions,
  useGetAdminProfileSuggestions,
} from '@src/rq/profileSuggestions';
import React from 'react';
import Suggestions from '../Suggestions';
import { UserId } from '@a_team/models/dist/UserObject';
import { BuilderSuggestionType } from '@a_team/models/dist/ProfileEnhancements';
import { createUseStyles } from 'react-jss';
import { NewJob } from '@src/stores/Profile/models';
import { omit } from 'lodash';
import { getDateWithFormat } from '../../Main/Jobs/SuggestedJobs';

const useStyles = createUseStyles({
  container: {
    marginBottom: 24,
  },
});

interface SuggestedJobsProps {
  userId: UserId;
  adminOnly?: boolean;
  onAccept: (job: NewJob) => void;
}
const SuggestedJobs = ({ userId, adminOnly, onAccept }: SuggestedJobsProps) => {
  const { data } = useGetProfileSuggestions();
  const { data: adminData } = useGetAdminProfileSuggestions();
  const { mutateAsync: rejectSuggestion } = useRejectProfileSuggestion();
  const styles = useStyles();

  const rawSuggestions = adminOnly ? adminData : data;

  const suggestionId = rawSuggestions?.jobs?.sid;

  const jobs = rawSuggestions?.jobs?.jobs.map((job) => {
    const formattedStartDate = getDateWithFormat(job.startDate, 'MM/yyyy');
    const formattedEndDate = getDateWithFormat(job.endDate, 'MM/yyyy');

    return {
      suggestionId,
      partialSuggestionId: job.eid,
      label: job.companyName,
      subLabel: job.jobRole,
      metaData: `${formattedStartDate} - ${formattedEndDate || 'Current'}`,
      type: BuilderSuggestionType.JOBS,
    };
  });

  const onDiscard = async () => {
    if (!suggestionId) {
      return;
    }

    try {
      await rejectSuggestion(suggestionId);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAccept = (jobId: string) => {
    try {
      const acceptedJob = rawSuggestions?.jobs?.jobs.find(
        (job) => job.eid === jobId,
      );

      if (acceptedJob?.eid) {
        onAccept({
          ...omit(acceptedJob, 'eid'),
          _id: acceptedJob.eid,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.container}>
      <Suggestions
        onDiscard={onDiscard}
        onAccept={handleAccept}
        title={'Suggested jobs'}
        userId={userId}
        adminOnly={adminOnly}
        suggestions={jobs || []}
      />
    </div>
  );
};

export default SuggestedJobs;
