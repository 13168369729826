import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import { BorderColors, Breakpoints } from '@ateams/components';
import { useStores } from '@src/stores';
import { Expertise } from '@src/stores/Profile/models';
import SkillsDropDown from './SkillsDropDown';
import SkillsRating from './SkillsRating';
import { formatSkillsForSelect, Option } from './utils';

interface SkillsSelectorProps {
  defaultSelectedSkillIds: string[];
  profileSkills: Expertise[];
  onChange: (skills: Expertise[]) => void;
}

const useStyles = createUseStyles({
  container: {
    [`@media (min-width: ${Breakpoints.sm}px)`]: {
      border: `1px solid ${BorderColors.lighter}`,
      padding: 16,
      borderRadius: 8,
    },
  },
});

const SkillsSelector: React.FC<SkillsSelectorProps> = ({
  defaultSelectedSkillIds,
  profileSkills,
  onChange,
}) => {
  const styles = useStyles();
  const {
    users: { profile },
  } = useStores();
  const [allSkills, setAllSkills] = useState<Option[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<Option[]>([]);

  useEffect(() => {
    fetchSkills();
  }, []);

  const fetchSkills = useCallback(async () => {
    if (!profile) {
      return;
    }

    const skills = (await profile.querySkills('', false)) ?? [];
    const formattedSkills = formatSkillsForSelect(skills);

    setAllSkills(formattedSkills);

    // Filter and format initially selected skills
    const initialSelectedSkills = skills
      .filter((skill) => defaultSelectedSkillIds.includes(skill.id))
      .map((skill) => ({
        ...skill,
        rating: profileSkills.find((s) => s.id === skill.id)?.rating,
      }));

    setSelectedSkills(formatSkillsForSelect(initialSelectedSkills));
    onChange(initialSelectedSkills);
  }, [profile, defaultSelectedSkillIds, profileSkills]);

  const profileSkillsFormatted = useMemo(
    () => formatSkillsForSelect(profileSkills),
    [profileSkills],
  );

  const handleSetSelectedSkills = useCallback(
    (skills: Option[]) => {
      setSelectedSkills(skills);
      onChange(
        skills.map((skill) => ({
          ...skill.original,
          rating: skill.rating,
        })),
      );
    },
    [onChange],
  );

  if (allSkills.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <SkillsDropDown
        profileSkills={profileSkillsFormatted}
        allSkills={allSkills}
        selectedSkills={selectedSkills}
        setSelectedSkills={handleSetSelectedSkills}
      />
      <SkillsRating
        selectedSkills={selectedSkills}
        setSelectedSkills={handleSetSelectedSkills}
      />
    </div>
  );
};

export default observer(SkillsSelector);
