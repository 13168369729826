import React, { ReactElement } from 'react';
import AdminTeamWorkView from '..';
import SectionHeading from '@src/components/SectionHeading';
import { useStores } from '@src/stores';
import MissionList from '../MissionList';
import { observer } from 'mobx-react';
import { AdminViewMissionResponse } from '@ateams/api/dist/endpoints/Missions';

function AdminTeamWorkArchivedMissions(): ReactElement {
  const { missions } = useStores();
  const missionTypes: Map<keyof AdminViewMissionResponse, string> = new Map([
    ['createdMissions', 'Created'],
    ['publishedMissions', 'Published'],
    ['runningMissions', 'Running'],
    ['pendingMissions', 'Pending'],
    ['endedMissions', 'Ended'],
    ['archivedMissions', 'Archived'],
  ]);

  return (
    <AdminTeamWorkView>
      {[...missionTypes].map(
        ([missionType, type]) =>
          (missions.adminViewMissions[missionType] || []).length > 0 && (
            <>
              <SectionHeading isFirst>{type} Missions:</SectionHeading>
              <MissionList
                type={type.toLowerCase()}
                missions={missions.adminViewMissions[missionType] || []}
                showMore={!!missions.adminViewMissions[missionType]}
              />
            </>
          ),
      )}

      {!missions.adminViewMissions?.archivedMissions?.length &&
        !missions.adminViewMissions?.endedMissions?.length &&
        !missions.adminViewMissions?.createdMissions?.length &&
        !missions.adminViewMissions?.publishedMissions?.length &&
        !missions.adminViewMissions?.pendingMissions?.length &&
        !missions.adminViewMissions?.runningMissions?.length && (
          <div>{'Nothing found'}</div>
        )}
    </AdminTeamWorkView>
  );
}

export default observer(AdminTeamWorkArchivedMissions);
