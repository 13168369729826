import cx from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Icon, IconType } from '@ateams/components';
import { Breakpoints } from '@ateams/components';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
    gap: '8px',
  },
  badge: {
    padding: '6px 8px',
    borderRadius: '4px',
    width: 'auto',
    fontWeight: 500,
    fontSize: '14px',
    display: 'inline-block',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  badgeRecommended: {
    background: '#AEE2FF',
    color: '#017DC2',
  },
  badgeGreatFit: {
    background: '#E8D0FF',
    color: '#6D00D7',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      flexDirection: 'row',
      gap: '16px',
    },
    badge: {
      padding: '8px 10px',
    },
  },
});

function Badges() {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div>
        <div className={cx(styles.badge, styles.badgeRecommended)}>
          <div>
            <Icon
              type={IconType.RecommendedBadge}
              size={'exact'}
              style={{ marginRight: '8px' }}
            />
            Recommended role
          </div>
        </div>
      </div>
      <div>
        <div className={cx(styles.badge, styles.badgeGreatFit)}>
          <div>
            <Icon
              type={IconType.PurpleStar}
              size={'exact'}
              style={{ marginRight: '8px' }}
            />
            You seem to be a great fit for this role!
          </div>
        </div>
      </div>
    </div>
  );
}

export default Badges;
