import React from 'react';
import { Card } from '@ateams/components';
import Table, { TableRow } from '@src/components/Table';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import formatDate from '@src/helpers/formatDate';
import { RewardScoreType } from '@ateams/api/dist/endpoints/Rewards';
import { Breakpoints } from '@ateams/components';

const useStyles = createUseStyles({
  scrollContainer: {
    overflowY: 'hidden',
  },
  scroll: {
    minWidth: '820px',
    margin: 0,
  },
  title: {
    marginTop: 0,
    fontWeight: 700,
    fontSize: '16px',
  },
  header: {
    backgroundColor: '#F9FAFC',
    color: '#6E7177',
    paddingTop: '15px',
    paddingBottom: '15px',
    textAlign: 'left',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  cell: {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: 400,
  },
  typeCell: {
    fontWeight: 500,
  },
  tokenCell: {
    fontWeight: 600,
    color: '#FE630C',
    display: 'block',
    width: '100%',
  },
  ctaContainer: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'end',
  },
  emptyCtaContainer: {
    display: 'flex',
    justifyContent: 'start',
    marginTop: 16,
  },
  emptyTitle: {
    marginBottom: 0,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    emptyState: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    emptyCtaContainer: {
      marginTop: 0,
      justifyContent: 'end',
    },
  },
});

interface Props {
  className?: string;
}

const rewardScoreTypeMap: Map<RewardScoreType, string> = new Map([
  [RewardScoreType.BilledHoursToAnyMission, 'Hours worked'],
  [RewardScoreType.InvitedBuilderBilledToMission, 'Invited builder'],
  [RewardScoreType.InvitedCompanyBilledToMission, 'Invited company'],
  [RewardScoreType.CustomIssuance, 'Custom reward'],
]);

const Stats: React.FC<Props> = ({ className }) => {
  const styles = useStyles();
  const { rewards } = useStores();

  if (!rewards?.contributions?.length) {
    return (
      <Card className={className}>
        <div className={styles.emptyState}>
          <h3 className={cx(styles.title, styles.emptyTitle)}>Token events</h3>
          <div className={styles.emptyCtaContainer}>
            <div>
              <span>Tokens are in beta. Something look off?</span>{' '}
              <a href="mailto:token-review@a.team">Request a review</a>
            </div>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <div className={cx(className, styles.scrollContainer)}>
      <Card className={styles.scroll}>
        <h3 className={styles.title}>Token events</h3>
        <Table
          headers={['Date', 'Event', 'Type', 'Tokens']}
          headerClassName={styles.header}
          noMargin
        >
          {rewards?.contributions?.map(
            ({ date, description, totalScore, type }) => (
              <TableRow
                key={date.toString()}
                cells={[
                  format(date),
                  description,
                  <span className={styles.typeCell}>
                    {rewardScoreTypeMap.get(type)}
                  </span>,
                  <span className={styles.tokenCell}>
                    +{Math.floor(totalScore)}
                  </span>,
                ]}
                cellClassName={styles.cell}
              />
            ),
          )}
        </Table>
        <div className={styles.ctaContainer}>
          <div>
            <span>Tokens are in beta. Something look off?</span>{' '}
            <a href="mailto:token-review@a.team">Request a review</a>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default observer(Stats);

const format = (isoDateString: string | Date): string => {
  const isoDate = new Date(isoDateString);
  const resultDate = new Date(0);
  resultDate.setFullYear(isoDate.getUTCFullYear(), isoDate.getUTCMonth(), 1);
  return formatDate(resultDate.toISOString(), 'M/d/yy');
};
