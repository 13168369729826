import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import TargeterSuggestedTeamCard from './TeamCard';
import { UserBadge } from '@a_team/models/dist/UserObject';
import {
  SuggestedTeam,
  SuggestedTeamMemberObject,
} from '@a_team/models/dist/TeamGraphObject';
import { Button, Colors, Icon, IconType, Spacing } from '@ateams/components';
import { useStores } from '@src/stores';
import { SUGGESTED_TEAMS } from '../TeamGraphTabs';
import { SkeletonTheme } from 'react-loading-skeleton';
import TargeterSuggestedTeamsSidebar from './Sidebar';
import { TargeterSuggestedTeamsSidebarParameters } from '@a_team/models/dist/TargeterSuggestedTeams';
import { TeamTargeterCriteriaParametersV2 } from '@a_team/data-science-api-client';

export interface TargeterSuggestedTeamMember {
  userId: string;
  username: string;
  fullName: string;
  profilePictureURL: string;
  badges: UserBadge[];
  role: string;
  projects: TargeterSuggestedTeamProject[];
  connections: TargeterSuggestedTeamConnection[];
}

export interface TargeterSuggestedTeamProject {
  missionId: string;
  missionName: string;
  userId: string;
}

export enum TargeterSuggestedTeamConnectionType {
  WorkConnection,
  SocialConnection,
}

export interface TargeterSuggestedTeamConnection
  extends TargeterSuggestedTeamMember {
  connectionType: TargeterSuggestedTeamConnectionType;
}

export interface TargeterSuggestedTeam {
  id: string;
  members: TargeterSuggestedTeamMember[];
}

const LOADING_SUGGESTED_TEAM_MEMBERS = [...Array(3)].reduce(
  (accumulator, _, index) => ({
    ...accumulator,
    [`FakeRole${index}`]: {
      roleCategoryTitle: '',
      user: index.toString(),
      profileScore: 0,
      roleFit: 0,
      applicationScore: 0,
      teams: [],
      invitations: [],
      invitedBy: null,
      projects: [...Array(3)]
        .filter((value) => value !== index)
        .map((_, idx) => ({
          _id: '',
          key: '',
          name: '',
          logoUrl: '',
          overlap: false,
          overlapStart: undefined,
          overlapEnd: undefined,
          createdAt: '',
          connectionUser: idx.toString(),
        })),
      connections: [],
      missions: [],
      missionRecommendations: [],
      companies: [],
      username: '',
      fullName: '',
      profilePictureURL: '',
      badges: [],
    } as SuggestedTeamMemberObject,
  }),
) as Record<string, SuggestedTeamMemberObject>;

const LOADING_SUGGESTED_TEAM: SuggestedTeam = {
  members: LOADING_SUGGESTED_TEAM_MEMBERS,
  companyOverlaps: [],
  missionOverlaps: [],
  projectOverlaps: [],
  enrichedTeamNarrative: {},
};

const LOADING_SUGGESTED_TEAMS = [
  LOADING_SUGGESTED_TEAM,
  LOADING_SUGGESTED_TEAM,
];

interface Props {
  suggestedTeamsParameters?: TargeterSuggestedTeamsSidebarParameters;
  setSuggestedTeamsParameters: (
    parameters: TargeterSuggestedTeamsSidebarParameters,
  ) => void;
  suggestedTeams: SuggestedTeam[];
  suggestedTeamsCriteria: TeamTargeterCriteriaParametersV2 | null;
  setSuggestedTeamsCriteria: (
    criteria: TeamTargeterCriteriaParametersV2,
  ) => void;
  activeRoleTabNames?: string[];
  runSearch: () => void;
  cancelSearch: () => void;
}

const useStyles = createUseStyles({
  mainContainer: {
    background: `#F7F7F7`,
    flex: 1,
    display: 'flex',
  },
  card: {
    marginLeft: '40px',
  },
  suggestedTeamsContainer: {
    paddingTop: 40,
    paddingBottom: 64,
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    gap: 24,
  },
  fetchingDataContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#222222',
    backgroundColor: '#FFF4E4',
    maxWidth: '596px',
    height: '51px',
    marginLeft: '40px',
  },
  searchControl: {
    marginLeft: 40,
    paddingLeft: Spacing.medium,
    paddingRight: Spacing.medium,
  },
  runSearch: {
    width: 114,
  },
  cancelSearch: {
    width: 150,
  },
});

const TargeterSuggestedTeams = (props: Props): ReactElement => {
  const { targeterTabManager } = useStores();
  const styles = useStyles();
  const {
    suggestedTeamsParameters,
    setSuggestedTeamsParameters,
    suggestedTeams,
    suggestedTeamsCriteria,
    setSuggestedTeamsCriteria,
    activeRoleTabNames,
    runSearch,
    cancelSearch,
  } = props;
  const [suggestedTeamsToRender, setSuggestedTeamsToRender] = useState(
    LOADING_SUGGESTED_TEAMS,
  );

  const onSelectTeam = (team: SuggestedTeam) => {
    console.info(team);
  };

  const rolesCount = useMemo(() => {
    return Object.keys(suggestedTeamsCriteria?.teamCriteria ?? {}).reduce(
      (result, current) => {
        const criteria = suggestedTeamsCriteria?.teamCriteria[current];
        return criteria
          ? result + criteria.requiredCount + criteria.optionalCount
          : result;
      },
      0,
    );
  }, [suggestedTeamsCriteria?.teamCriteria]);

  const isLoading = targeterTabManager.loadingTabs?.[SUGGESTED_TEAMS] || false;
  const isCancellingTeamSearch =
    targeterTabManager.isCancellingTeamSearch || false;

  useEffect(() => {
    if (isLoading) {
      setSuggestedTeamsToRender([
        ...suggestedTeams,
        ...LOADING_SUGGESTED_TEAMS,
      ]);
    } else {
      setSuggestedTeamsToRender(suggestedTeams);
    }
  }, [suggestedTeams]);

  return (
    <SkeletonTheme
      baseColor={Colors.backgroundLight}
      highlightColor={Colors.backgroundWhite}
    >
      <div className={styles.mainContainer}>
        <TargeterSuggestedTeamsSidebar
          isLoading={isLoading}
          suggestedTeamsParameters={suggestedTeamsParameters}
          setSuggestedTeamsParameters={setSuggestedTeamsParameters}
          suggestedTeamsCriteria={suggestedTeamsCriteria}
          setSuggestedTeamsCriteria={setSuggestedTeamsCriteria}
          activeRoleTabNames={activeRoleTabNames}
          runSearch={runSearch}
        />
        <div className={styles.suggestedTeamsContainer}>
          {isLoading ? (
            <>
              <div className={styles.fetchingDataContainer}>
                <Icon
                  size="exact"
                  rotating={true}
                  type={IconType.CircleLoadingRed}
                  style={{ marginLeft: 8, cursor: 'pointer' }}
                />
                <span>
                  {isCancellingTeamSearch
                    ? 'Cancelling...'
                    : 'We’re fetching the data. This may take up to 5 minutes.'}
                </span>
              </div>
              <Button
                onClick={cancelSearch}
                squared
                size="small"
                width="auto"
                className={styles.cancelSearch + ' ' + styles.searchControl}
              >
                Cancel Search
              </Button>
            </>
          ) : (
            <Button
              onClick={runSearch}
              squared
              size="small"
              width="auto"
              className={styles.runSearch + ' ' + styles.searchControl}
            >
              Run Search
            </Button>
          )}
          {suggestedTeamsToRender.map((team, index) => {
            return (
              <TargeterSuggestedTeamCard
                key={index}
                rolesCount={rolesCount}
                team={team}
                onSelectTeam={() => onSelectTeam(team)}
                className={styles.card}
                isLoading={
                  Object.keys(team.members).filter((key) =>
                    key.startsWith('FakeRole'),
                  ).length > 0
                }
              />
            );
          })}
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default TargeterSuggestedTeams;
