import { Breakpoints } from '@ateams/components';
import React from 'react';
import useHover from '@src/hooks/useHover';
import { createUseStyles } from 'react-jss';

interface EmojiIconProps extends React.HTMLAttributes<HTMLDivElement> {
  emoji: string;
  grayEmoji: string;
  title: string;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
  dataTestingId?: string;
}

const useStyles = createUseStyles<{ disabled: boolean }>({
  icon: {
    height: 20,
    width: 20,
  },
  iconWrapper: {
    height: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    cursor: ({ disabled }) => (disabled ? 'not-allowed' : 'pointer'),
    borderRadius: 8,
    // on hover add transition
    transition: 'all 0.2s ease-in-out',

    '&:hover': {
      //transition
      opacity: ({ disabled }) => (disabled ? 1 : 0.8),
      background: '#EFEFF0',
    },
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    icon: {
      height: 44,
      width: 44,
    },
    iconWrapper: {
      height: 100,
      width: 100,
    },
  },
});

const EmojiIcon = ({
  emoji,
  grayEmoji,
  title,
  onClick,
  selected,
  disabled,
  ...rest
}: EmojiIconProps) => {
  const styles = useStyles({
    disabled,
  });
  const [hoverRef, isHovered] = useHover<HTMLInputElement>();

  return (
    <div
      {...rest}
      onClick={() => {
        // if disabled, do nothing
        if (disabled) {
          return;
        }
        // if not disabled, call onClick
        onClick && onClick();
      }}
      className={styles.iconWrapper}
      ref={hoverRef}
    >
      {!disabled && (isHovered || selected) ? (
        <img src={emoji} alt="soso" className={styles.icon} />
      ) : (
        <img src={grayEmoji} alt="soso" className={styles.icon} />
      )}

      <span>{title}</span>
    </div>
  );
};

export default EmojiIcon;
