import { Icon, IconType } from '@ateams/components';
import React from 'react';
import useCommonStyles from './commonStyles';

interface EmptyViewProps {
  text?: string;
}

const Recommendation = ({ text }: EmptyViewProps) => {
  const styles = useCommonStyles();

  return (
    <div className={styles.container}>
      <div className={styles.suggestedDescription}>
        <Icon size="smaller" type={IconType.SuggestedTeams} />
        <span>
          {text ?? `We are having trouble generating recommendation.`}
        </span>
      </div>
    </div>
  );
};

export default Recommendation;
