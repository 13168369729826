import UserObject, { UserCardObject } from '@a_team/models/dist/UserObject';
import { Button as CallToActionButton } from '@ateams/components';
import useToggle from '@src/hooks/useToggle';
import { useStores } from '@src/stores';
import cx from 'classnames';
import React, { ReactElement, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import ConnectModalV2 from '../Modal/ConnectModalV2';
import { getUpdatedConnectionsForUserToInteract } from '@src/helpers/connections';
import {
  ConnectionsModalSources,
  useAnalytics,
} from '@ateams/analytics/dist/platform';

export interface ConnectButtonProps {
  user: UserObject | UserCardObject;
  setLoading?: () => void;
  round?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  button: (data: { props: ConnectButtonProps; user: UserObject }) => ({
    width: 'auto',
    flexGrow: 1,
    'button + &': {
      marginLeft: '24px',
    },
    ...(!data.props.round && {
      background: 'none',
      width: 'auto',
      height: 'auto',
      padding: 0,
      flexGrow: 1,
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '24px',
      color: '#08A5FE',
      cursor: 'pointer',
    }),
  }),
});

const ConnectButton = (props: ConnectButtonProps): ReactElement | null => {
  const [userToInteract, setUserToInteract] = useState<UserObject>();
  const styles = useStyles({
    props,
    user: (userToInteract as UserObject) || props.user,
  });
  const { auth, users } = useStores();
  const [connectModalOpen, setConnectModalOpen] = useToggle();
  const analytics = useAnalytics();

  const onConnect = (): void => {
    users.getFullUser(props.user.username, auth).then((fullUser) => {
      setUserToInteract(fullUser);
      setConnectModalOpen();
      auth.user?.uid &&
        analytics.trackConnectionModalOpened(
          auth.user?.uid,
          ConnectionsModalSources.DiscoveryBuilders,
        );
    });
  };

  const onModalClose = (): void => {
    setConnectModalOpen(false);
  };

  const hasConnections = useMemo(() => {
    const userConnections = props.user.connections || [];
    const userToInteractConnections = userToInteract?.connections || [];

    return userConnections.length > 0 || userToInteractConnections.length > 0;
  }, [props.user.connections, userToInteract?.connections]);

  const status = hasConnections ? 'Edit Connection' : 'Connect';

  return (
    <>
      {userToInteract && auth.user && (
        <ConnectModalV2
          source={ConnectionsModalSources.DiscoveryBuilders}
          currentUser={auth.user}
          userToConnect={userToInteract}
          open={connectModalOpen}
          onClose={onModalClose}
          connectActionOnCompleted={(newConnection) => {
            setUserToInteract((prevUserToInteract) => {
              if (!prevUserToInteract) {
                return prevUserToInteract;
              }
              return getUpdatedConnectionsForUserToInteract(
                prevUserToInteract,
                newConnection,
              );
            });
          }}
        />
      )}
      <CallToActionButton
        color="info"
        className={cx(styles.button, props.className)}
        onClick={onConnect}
      >
        {status}
      </CallToActionButton>
    </>
  );
};

export default ConnectButton;
