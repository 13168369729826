import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  BuilderFeedbackNotificationObject,
  NotificationType,
} from '@a_team/models/dist/NotificationObject';
import Notification from './Notification';
import { ProfileLocation } from '@src/locations';

interface BuilderFeedbackNotificationProps {
  notification: BuilderFeedbackNotificationObject;
  onMarkAsRead(): void;
  isRead?: boolean;
  username: string;
  toggle: () => void;
}

const BuilderFeedbackNotification: React.FC<
  BuilderFeedbackNotificationProps
> = ({ isRead, onMarkAsRead, notification, username, toggle }) => {
  const companyName = notification.companyName;
  const history = useHistory();

  const title = `${companyName} added a recommendation to your profile.`;
  const description =
    'Recommendations help you to stand out as a builder with experience working on A.Team.';

  const handleCtaAction = () => {
    history.push(ProfileLocation(username));
    toggle();
  };

  return (
    <Notification
      createdAt={notification.createdAt}
      notificationType={NotificationType.BuilderRatings}
      ctaName="Review recommendation"
      ctaAction={handleCtaAction}
      title={title}
      description={description}
      imageUrl={notification.companyImageUrl}
      onMarkAsRead={onMarkAsRead}
      isRead={isRead}
    />
  );
};

export default BuilderFeedbackNotification;
