import React, { useEffect, useState } from 'react';
import Modal, { ModalBasicProps } from '@src/components/Modal';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery';
import {
  mapTargeterApiRequest,
  queryParametersToSearchRequestBody,
} from '@src/views/SkillTargeter/SearchView/utils';
import { useStores } from '@src/stores';
import { teamEngineSearchServiceApi } from '@src/logic/services/endpoints';
import { ServiceAuth } from '@ateams/service-utils';
import { SkillTargeterCriteria } from '@ateams/api/dist/endpoints/TeamGraph';
import { SkillTargeterUserObject } from '@a_team/models/dist/TeamGraphObject';
import SectionHeading from '@src/components/SectionHeading';
import { MatchingDetailsModalBody } from '@src/views/SkillTargeter/TargeterUserCardView/MatchesDetailsModalBody';

interface GetElasticTargeterMatchExplanationParams {
  auth: ServiceAuth;
  criteria: SkillTargeterCriteria;
  uid: string;
}

export async function getElasticTargeterMatchExplanation(
  params: GetElasticTargeterMatchExplanationParams,
) {
  const { auth, criteria, uid } = params;
  //
  const { filter } = await queryParametersToSearchRequestBody({
    auth,
    criteria,
  });
  const { data, errors } = await teamEngineSearchServiceApi.query(
    {
      v1ExplainTargeter: [
        {
          id: uid,
          filter,
        },
        {
          id: 1,
          matched: 1,
          structuredExplanations: 1,
          esParsedExplanations: 1,
          esSearchHighlight: 1,
          matchedValues: 1,
        },
      ],
    },
    { headers: { Authorization: auth.bearerToken } },
  );

  const {
    matchedValues,
    structuredExplanations,
    esParsedExplanations,
    esSearchHighlight,
    matched,
  } = data?.v1ExplainTargeter ?? {};

  return {
    matchedValues,
    structuredExplanations,
    esParsedExplanations,
    esSearchHighlight,
    matched,
    errors,
  };
}

export type MatchedValue = {
  type: string;
  value: string;
};

export type StructuredExplanation = {
  title: string;
  items?: StructuredExplanationItem[];
}[];

export type StructuredExplanationItem = {
  header: string;
  title: string;
  highlightedMatchedText?: string;
  platformLink?: string;
  metadata?: Record<string, unknown>;
  partialText: boolean;
};

interface Props extends ModalBasicProps {
  matchesOn: string;
  user: SkillTargeterUserObject;
  filter?: RoleFilter;
}

export function MatchesDetailsModal(props: Props) {
  const { onClose, user, filter, matchesOn, ...rest } = props;
  const { auth } = useStores();
  const [loading, setLoading] = useState(false);
  const [matchedValues, setMatchedValues] = useState<MatchedValue[]>();
  const [structuredExplanations, setStructuredExplanations] =
    useState<StructuredExplanation>();
  const [esParsedExplanations, setEsParsedExplanations] = useState<string[]>();
  const [esSearchHighlight, setEsSearchHighlight] = useState<string[]>();
  const [matched, setMatched] = useState<boolean>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const asyncFunc = async () => {
      if (!filter) {
        setError('Unable to extract Targeter search for explanation');
        return;
      }
      try {
        setLoading(true);
        const criteria = mapTargeterApiRequest(filter);
        const {
          matchedValues,
          structuredExplanations,
          esParsedExplanations,
          esSearchHighlight,
          matched,
          errors,
        } = await getElasticTargeterMatchExplanation({
          auth,
          criteria,
          uid: user.uid,
        });
        if (errors) setError(errors[0].message);
        setMatchedValues(matchedValues);
        setStructuredExplanations(structuredExplanations);
        setEsParsedExplanations(esParsedExplanations);
        setEsSearchHighlight(esSearchHighlight);
        setMatched(matched);
        setLoading(false);
      } catch (e) {
        setError((e as Error).message);
        setLoading(false);
      }
    };
    asyncFunc();
  }, []);

  const noResults = !loading && !structuredExplanations?.length;

  return (
    <Modal
      onClose={onClose}
      style={{
        width: '1138px',
        maxWidth: 'calc(100% - 48px)',
        maxHeight: 'calc(100% - 48px)',
        height: '780px',
      }}
      {...rest}
    >
      <SectionHeading style={{ marginTop: 0 }}>Matched on</SectionHeading>
      {loading && <div>Loading...</div>}
      {noResults && <strong>No matches on results found</strong>}
      {!loading && (
        <MatchingDetailsModalBody
          user={user}
          semanticQuery={filter?.semanticSearchQuery}
          matchesOn={matchesOn}
          matchedValues={matchedValues}
          structuredExplanations={structuredExplanations}
          esParsedExplanations={esParsedExplanations}
          esSearchHighlight={esSearchHighlight}
          matched={matched}
          error={error}
        />
      )}
    </Modal>
  );
}
