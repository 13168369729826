import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  '@global': {
    body: {
      overflow: 'hidden !important',
    },
  },
});

const HideScrollerGlobally = (): JSX.Element | null => {
  useStyles();
  return null;
};

export default HideScrollerGlobally;
