import React from 'react';
import { createUseStyles } from 'react-jss';
import emptyIllustration from './emptyIllustration.svg';

const useStyles = createUseStyles({
  container: {
    padding: 24,
    fontSize: 16,
    fontWeight: 500,
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
  },
  emptyIllustration: {
    width: 132,
    height: 80,
  },
});

const Empty = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <img
        className={styles.emptyIllustration}
        src={emptyIllustration}
        alt="empty"
      />
      <span>No notifications to show.</span>
    </div>
  );
};

export default Empty;
