import { createQueryKeys } from '@lukemorales/query-key-factory';

export const emailFromTargeterKeys = createQueryKeys('emailFromTargeter', {
  getReachoutAutomationPreviewForMission: (missionId: string) => ({
    missionId,
  }),
  getReachoutAutomationCampaignsForRole: (roleId: string) => ({
    roleId,
  }),
});
