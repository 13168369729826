import { useMemo } from 'react';
import { useStores } from '@src/stores';
import { PaginationProps } from '@src/components/Pagination';
import { PROCESSES_BATCH_SIZE } from '@src/stores/VettingProcesses';

export const usePagination = () => {
  const { vettingProcesses } = useStores();

  const paginationProps: PaginationProps | undefined = useMemo(() => {
    if (vettingProcesses.vettingProcessesCount === undefined) {
      return undefined;
    }

    return {
      currentPage: vettingProcesses.processesQuery.page,
      pagesCount: Math.ceil(
        vettingProcesses.vettingProcessesCount /
          vettingProcesses.processesQuery.batchSize,
      ),
      recordsCount: vettingProcesses.vettingProcessesCount,
      pageBatchSize: vettingProcesses.processesQuery.batchSize,
      pageSizeOptions: Object.values(PROCESSES_BATCH_SIZE),
      onPageClick: (page) => vettingProcesses.setProcessesPage(page),
      onPageBatchSizeChange: (pageBatchSize) =>
        vettingProcesses.setProcessesBatchSize(pageBatchSize),
    };
  }, [
    vettingProcesses.vettingProcessesCount,
    vettingProcesses.processesQuery.page,
    vettingProcesses.processesQuery.batchSize,
  ]);

  return paginationProps;
};
