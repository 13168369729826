import { Colors, TextColors } from '@ateams/components';
import { CDN_BASE_URL } from '@src/config';
import { DiscoveryLocation } from '@src/locations';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import cx from 'classnames';

interface NoConnectionsProps {
  className?: string;
  ctaName?: string;
  onClick?: () => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    border: '1px solid #DADADC',
    borderRadius: '8px',
    padding: '24px 16px',
  },
  title: {
    textAlign: 'center',
    color: TextColors.regular,
    fontWeight: 500,
  },
  desc: {
    textAlign: 'center',
    color: '#818388',
    margin: '8px 0 0 0',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    height: '140px',
    width: '140px',
    aspectRatio: '1/1',
  },
  linkButton: {
    borderRadius: '4px',
    padding: '8px 16px',
    width: 'fit-content',
    height: 'fit-content',
    background: Colors.primary,
    color: TextColors.primary,
  },
});

const NoConnections = ({
  className,
  ctaName,
  onClick,
}: NoConnectionsProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.imgContainer}>
        <img
          className={styles.img}
          src={`${CDN_BASE_URL}/website/empty-connection.jpg`}
          alt="no-connections"
        />
      </div>
      <div>
        <div className={styles.title}>Create your network</div>
        <p className={styles.desc}>
          Connect with other builders to increase the strength of your network
          and get more invites to amazing missions.
        </p>
      </div>
      <Link
        onClick={onClick}
        to={DiscoveryLocation}
        className={styles.linkButton}
      >
        {ctaName || 'Find Builders & Connect'}
      </Link>
    </div>
  );
};

export default NoConnections;
