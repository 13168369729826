import React from 'react';
import { theme } from '@a_team/ui-components';

export const XMarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M10.8333 5L5 10.8333M5 5L10.8333 10.8333"
        stroke={theme.colors.Grey[800]}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
