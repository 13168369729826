import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

const useStyles = createUseStyles({
  strengthMeter: {
    height: '10px',
    borderRadius: '5px',
    transition: 'width 0.5s ease, background 0.5s ease',
    background: (score) => {
      switch (score) {
        case 0:
        case 1:
        case 2:
          return '#ff3e3e';
        case 3:
          return '#4caf50';
        case 4:
          return '#4caf50';
        default:
          return '#dddddd';
      }
    },
  },
  container: {
    margin: '10px 0',
    width: '100%',
  },
  meterWrapper: {
    width: '100%',
    backgroundColor: '#dddddd',
    borderRadius: '5px',
  },
  strengthLabel: {
    display: 'block',
    textAlign: 'right',
    marginTop: '5px',
    fontSize: '12px',
    color: '#666',
  },
});

const PasswordStrengthIndicator = ({
  score,
  className,
  style,
}: {
  score: number;
  className?: string;
  style?: React.CSSProperties;
}) => {
  const styles = useStyles(score);
  const strengthLabel =
    ['Weak', 'Weak', 'Fair', 'Good', 'Strong'][score] || 'None';

  return (
    <div style={style} className={cx(styles.container, className)}>
      <div className={styles.meterWrapper}>
        <div
          className={styles.strengthMeter}
          style={{ width: `${score !== null ? (score + 1) * 20 : 0}%` }}
        ></div>
      </div>
      <small className={styles.strengthLabel}>{strengthLabel}</small>
    </div>
  );
};

export default PasswordStrengthIndicator;
