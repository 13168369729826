/**
 * @param time - string of format HH.MM
 * @returns the parsed hours and minutes
 * @example 12:34 -> { hours: 12, minutes: 34 }
 * @example :34 -> { hours: 0, minutes: 34 }
 * @example 12: -> { hours: 12, minutes: 0 }
 */
function parseColonTime(time: string) {
  const [hours, minutes] = time.split(':');

  return {
    hours: parseInt(hours) || 0,
    minutes: parseInt(minutes) || 0,
  };
}

/**
 * @param time - string of format HH.XXX
 * @returns the parsed hours and minutes
 * @example 2.5 -> { hours: 2, minutes: 30 }
 * @example .5 -> { hours: 0, minutes: 30 }
 * @example 2 -> { hours: 2, minutes: 0 }
 */
function parseFloatTime(time: string) {
  const [hours, minutes] = time.split('.');

  return {
    hours: parseInt(hours) || 0,
    minutes: Math.floor(parseFloat('.' + minutes) * 60) || 0,
  };
}

/**
 * @returns {boolean} indicating whether the given time is of
 * format HH:MM or a partial of it (partial is useful while
 * user is typing)
 * @examples 12:34, 12:2, 12:, :12, 99:99
 */
function isColonTime(time: string) {
  return /^(([0-9]{0,2}):)?([0-9]{0,2})$/.test(time);
}

/**
 * @returns {boolean} indicating whether the given time is of
 * format HH.XXX or a partial of it
 * @examples 2.5, .5, 0.5, 2
 */
function isFloatTime(time: string) {
  return /^[0-9]*\.[0-9]*$/.test(time);
}

export function calcTotalMinutes(hours: number, minutes: number) {
  return hours * 60 + minutes;
}

interface ParsedTime {
  minutes: number;
  hours: number;
  totalMinutes: number;
}

const MAX_HOURS = 24;
const MAX_MINUTES = 59;

/**
 * @param time - Input text from user
 * @returns parsed values - hours, minutes, totalMinutes(with hours)
 * @throws when format is invalid
 */
export function validateAndParseTime(time: string): ParsedTime {
  if (!isColonTime(time) && !isFloatTime(time)) {
    throw new RangeError('Invalid format');
  }

  const { hours, minutes } = isColonTime(time)
    ? parseColonTime(time)
    : parseFloatTime(time);

  if (minutes > MAX_MINUTES) {
    throw new RangeError(`Minutes cannot be higher than ${MAX_MINUTES}`);
  }

  if (hours > MAX_HOURS || (hours === MAX_HOURS && minutes)) {
    throw new RangeError(`Cannot insert more than ${MAX_HOURS} a day`);
  }

  const totalMinutes = calcTotalMinutes(hours, minutes);

  return { hours, minutes, totalMinutes };
}
