import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

export interface CardProps {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
  to?: string;
  onClick?(): void;
  id?: string;
  title?: string;
  tabIndex?: HTMLDivElement['tabIndex'];
  'data-testing-id'?: string;
}

const useStyles = createUseStyles({
  root: {
    display: 'block',
    textDecoration: 'none',
    background: '#fff',
    color: '#222',
    border: 0,
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: '0.5em',
    padding: '1.6em',
    position: 'relative',
    margin: '0 1.34em 1.34em 0',
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: 0,
  },
});

export default function Card(props: CardProps): ReactElement {
  const { className, style, children, to, onClick, id, title, tabIndex } =
    props;

  const styles = useStyles();

  const elmProps = {
    className: cx(styles.root, className),
    style,
    to,
    onClick,
    id,
    tabIndex,
  };

  if (to) {
    return (
      <Link
        className={className}
        style={style}
        to={to}
        onClick={onClick}
        id={id}
        tabIndex={tabIndex}
        data-testing-id={props['data-testing-id']}
      >
        <>
          {title && <h3 className={styles.title}>{title}</h3>}
          {children}
        </>
      </Link>
    );
  }

  return (
    <div {...elmProps} data-testing-id={props['data-testing-id']}>
      {title && <h3 className={styles.title}>{title}</h3>}
      {children}
    </div>
  );
}
