import { UserStatus, UserType } from '@a_team/models/dist/UserObject';
import AuthStore from '@src/stores/Auth';
import {
  ClientAppLocation,
  ClientDashboardLocation,
  LocationPath,
  LocationTemplate,
  MissionControlBase,
  NotificationPreferencesLocation,
  ProfileLocation,
  RegistrationLocation,
  RootLocation,
  SignInLocationTemplate,
} from '@src/locations';

const REGISTRATION_PATHS = [RegistrationLocation];
const CLIENT_EXPOSED_PATHS = [NotificationPreferencesLocation];

export interface AuthMatcher {
  status?: UserStatus;
  type?: UserType;
  isActive?: boolean;
  isActiveBuilder?: boolean;
  isActiveWaitlistedBuilder?: boolean;
  isActiveCompanyUser?: boolean;
  restrictedAccess?: boolean;
  isRejected?: boolean;
  basicAccess?: boolean;
  fullAccess?: boolean;
  isAdmin?: boolean;
  isVetter?: boolean;
  /** @deprecated: permissions use fullAccess **/
  verified?: boolean;
}

const redirectToClientApp = () => {
  if (window?.location) {
    window.location.href = ClientAppLocation;
  }
};

function isAuthMatch(
  withAuth: boolean | AuthMatcher,
  auth: AuthStore,
): boolean {
  if (!withAuth) return true;

  /** @deprecated: permissions translate true to isActive or isActiveWaitlisted  **/
  if (withAuth === true) {
    return auth.isActive || auth.isActiveWaitlisted;
  }

  return (
    (!withAuth.type || auth.userType === withAuth.type) &&
    (!withAuth.status || auth.userStatus === withAuth.status) &&
    (!withAuth.isActive || auth.isActive) &&
    (!withAuth.isActiveBuilder || auth.isActiveBuilder) &&
    (!withAuth.isActiveWaitlistedBuilder || auth.isActiveWaitlisted) &&
    (!withAuth.isActiveCompanyUser || auth.isActiveCompanyUser) &&
    (!withAuth.restrictedAccess || auth.restrictedAccess) &&
    (!withAuth.isRejected || auth.isRejected) &&
    (!withAuth.basicAccess || auth.basicAccess) &&
    (!withAuth.fullAccess || auth.fullAccess) &&
    (!withAuth.isAdmin || auth.isAdmin) &&
    (!withAuth.isVetter || auth.isVetter) &&
    /** @deprecated: permissions use fullAccess **/
    (!withAuth.verified || !!auth.verified)
  );
}

function getRootPath(auth: AuthStore, currentUrl: string): LocationPath {
  if (auth.userStatus === UserStatus.Registered) {
    return RegistrationLocation;
  }

  if (auth.isActiveCompanyUser) return ClientDashboardLocation;

  if (auth.basicAccess) return MissionControlBase;

  if (auth.isRejected) {
    return RootLocation;
  }

  return auth.isActive || auth.isActiveWaitlisted
    ? ProfileLocation(auth.username as string)
    : SignInLocationTemplate(currentUrl);
}

export const authorizeRoute = (
  withAuth: boolean | AuthMatcher,
  auth: AuthStore,
  currentUrl: string,
  path?: LocationTemplate | LocationPath,
): LocationPath | null => {
  if (!withAuth) return null;

  if (auth.isActiveCompanyUser) {
    const isClientExposedPath = path && CLIENT_EXPOSED_PATHS.includes(path);
    !isClientExposedPath && redirectToClientApp();
  }

  const hasNotStartedOnboarding =
    auth.userStatus === UserStatus.Registered && !auth.username;

  const isRegistrationPath = path && REGISTRATION_PATHS.includes(path);

  // For edge cases when a user passes login without a username
  if (hasNotStartedOnboarding && !isRegistrationPath) {
    return RegistrationLocation;
  }

  return !isAuthMatch(withAuth, auth) ? getRootPath(auth, currentUrl) : null;
};
