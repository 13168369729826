import { TalentSkillsQueryData } from '@ateams/api/dist/endpoints/TalentSkills';
import { TalentSkill } from '@a_team/models/dist/TalentCategories';
import { useStores } from '@src/stores';
import { Expertise } from '@src/stores/Profile/models';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './keys';
import { fetchSkillList } from '@src/helpers/talent-skills-api';

function fetchExpertise<T = Expertise[]>(params: TalentSkillsQueryData) {
  return async function (): Promise<T> {
    return (await fetchSkillList({
      filter: {
        query: params.searchTerm,
        id: params?.ids?.split(','),
        talentCategoryIds: params.talentCategoryIds,
        isVerified: params.onlyVerifiedSkills,
        isProgrammingLanguage: params.searchProgrammingLanguages,
        nodeType: 'skill',
      },
    })) as unknown as T;
  };
}

export const useTalentSkills = (query: TalentSkillsQueryData) => {
  return useQuery(
    queryKeys.skills.list(query),
    fetchExpertise<TalentSkill[]>(query),
  );
};

export const useUserTalentSkills = (query: TalentSkillsQueryData) => {
  const { profile } = useStores();
  const talentCategoryIds = [];

  if (profile?.allTalentCategoryIds) {
    talentCategoryIds.push(...profile.allTalentCategoryIds);
  }

  if (profile?.generalTalentCategoryId) {
    talentCategoryIds.push(profile.generalTalentCategoryId);
  }
  const expertiseQuery = {
    ...query,
    talentCategoryIds,
  };

  return useQuery(
    queryKeys.skills.userExpertise(query, talentCategoryIds),
    fetchExpertise(expertiseQuery),
  );
};
