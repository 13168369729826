import { config } from 'dotenv';

config();

export type EnvVars = Record<string, string | undefined>;

declare let window: Window & { ENV_VARS?: EnvVars; RELEASE?: string };

const env = {
  ...process.env,
  ...((typeof window !== 'undefined' && window.ENV_VARS) || undefined),
} as EnvVars;

// node settings
export const ENVIRONMENT: string = env.NODE_ENV || 'development';
export const IS_PROD_BUILD: boolean = ENVIRONMENT === 'production';

export const IS_SANDBOX: boolean = env.SANDBOX === 'true';
export const IS_PROD: boolean = IS_PROD_BUILD && !IS_SANDBOX;
export const IS_LOCALHOST: boolean =
  typeof window !== 'undefined' && window.location.hostname === 'localhost';

// Path settings
export const PLATFORM_SERVICE_BASE_URL =
  env.REACT_APP_PLATFORM_URL || 'https://platform.a.team';

export const USER_NOTIFICATION_SERVICE_BASE_URL =
  env.REACT_APP_USER_NOTIFICATION_SERVICE_BASE_URL ||
  (IS_PROD
    ? 'https://user-notification-service.svc.a.team'
    : IS_SANDBOX
    ? 'https://user-notification-service.eks.a.team'
    : 'https://user-notification-service.dev.eks.a.team');

export const REGISTRATION_BASE_URL = IS_PROD
  ? 'https://onboarding.a.team/builder'
  : IS_LOCALHOST
  ? 'http://localhost:5173/builder'
  : 'https://onboarding-dev.a.team/builder';

export const TEAM_ENGINE_SEARCH_SERVICE_BASE_URL =
  env.REACT_APP_TEAM_ENGINE_SEARCH_SERVICE_BASE_URL ||
  (IS_PROD
    ? 'https://team-engine-search-service.svc.a.team/graphql'
    : 'https://team-engine-search-service.eks.a.team/graphql');

// Builder onboarding App
export const BUILDER_ONBOARDING_URL =
  process.env.BUILDER_ONBOARDING_URL ||
  (IS_SANDBOX
    ? 'https://onboarding-dev.a.team/builder/'
    : 'https://onboarding.a.team/builder/');

// Services Settings
export const API_SERVICE_BASE_URL: string =
  env.REACT_APP_API_URL ||
  (IS_PROD_BUILD ? 'https://api-service.svc.a.team/' : 'http://localhost:5000');

// CDN
export const CDN_BASE_URL = 'https://cdn.a.team';

// TeamGraph settings
export const DATA_SCIENCE_API_SERVICE_BASE_URL: string =
  env.REACT_APP_DATA_SCIENCE_API_URL ||
  (IS_PROD
    ? 'https://data-science-api.svc.a.team'
    : 'https://data-science-api.eks.a.team');

// Segment Source (Analytics)
export const SEGMENT_WRITE_KEY = env.REACT_APP_SEGMENT_WRITE_KEY || '';

// Facebook App
// https://developers.facebook.com/apps/192110598759710/roles/roles/
export const FACEBOOK_APP_ID =
  env.REACT_APP_FACEBOOK_APP_ID ||
  (IS_PROD_BUILD ? '192110598759710' : '209153546816466');
// DO NOT PUT APP SECRET HERE!

// Google App
// https://console.developers.google.com/apis/credentials?authuser=4&organizationId=0&project=ateams
export const GOOGLE_CLIENT_ID = (env.REACT_APP_GOOGLE_CLIENT_ID =
  '329985322140-56fspmjosru5qp16g39gq7nl318024lt.apps.googleusercontent.com');
// DO NOT PUT APP SECRET HERE!

// LinkedIn App
// https://www.linkedin.com/developers/apps/50000204/settings
export const LINKEDIN_CLIENT_ID =
  env.REACT_APP_LINKEDIN_CLIENT_ID || '78fxpx9z8s42no';
// DO NOT PUT APP SECRET HERE!

// Recatpcha site key
export const RECAPTCHA_SITE_KEY =
  env.REACT_APP_RECAPTCHA_SITE_KEY ||
  '6Le6d-cUAAAAAEDW3cXNPpaWJ7I1I4AHNK702dpU';

// Unsplash
// https://unsplash.com/oauth/applications/132859
export const UNSPLASH_ACCESS_KEY =
  env.REACT_APP_UNSPLASH_ACCESS_KEY ||
  'Q1z9s8LNVoersU7DDrLb19ni3wLJ1XsFIDjL-hUf3XM';

// Uploadcare
export const UPLOADCARE_API_BASE_URL =
  env.REACT_APP_UPLOADCARE_API_BASE_URL || 'https://upload.uploadcare.com/';
export const UPLOADCARE_PUBLIC_ACCESS_KEY =
  env.REACT_APP_UPLOADCARE_PUBLIC_ACCESS_KEY || 'e1c3b9f6da75312c6d13';
export const UPLOADCARE_ASSETS_BASE_URL =
  env.REACT_APP_UPLOADCARE_ASSETS_BASE_URL || 'https://ucarecdn.com/';

// Webflow
export const WEBFLOW_API_BASE_URL = 'https://api.webflow.com/';

// Sentry
export const SENTRY_DSN =
  env.REACT_APP_SENTRY_DSN ||
  'https://72f90ece5bcc4d609caf2b580fbc8f7a@o477587.ingest.sentry.io/5541212';
export const SENTRY_RELEASE =
  env.REACT_APP_SENTRY_RELEASE ||
  env.REACT_APP_RELEASE ||
  env.SENTRY_RELEASE ||
  env.RELEASE ||
  (typeof window !== 'undefined' && window.RELEASE) ||
  undefined;
export const SENTRY_ENVIRONMENT = IS_PROD_BUILD
  ? IS_SANDBOX
    ? 'sandbox'
    : 'production'
  : 'development';
// Only enable sentry in production or sandbox
export const SENTRY_ENABLED = IS_PROD_BUILD;

export const REACT_APP_DISABLE_WHY_DID_YOU_RENDER =
  env.REACT_APP_DISABLE_WHY_DID_YOU_RENDER || '';

// designer category ids which help determine who is a designer
export const DESIGNER_CATEGORY_ID = IS_SANDBOX
  ? '60c21b9b4d57fbdb0341bf14'
  : '60c22477e9c821eacfb855ea';

// software engineer ids which help determine who is a software engineer
export const SOFTWARE_ENGINEER_CATEGORY_ID = IS_SANDBOX
  ? '60c22477e9c821eacfb855e3'
  : '60c22477e9c821eacfb855e3';

export const REACT_APP_CALCOM_URL: string =
  env.REACT_APP_CALCOM_URL ||
  (IS_PROD ? 'https://book.a.team' : 'https://cal-com-service.eks.a.team');

export const REACT_APP_EVALUATION_EMAIL_FROM: string =
  env.REACT_APP_EVALUATION_EMAIL_FROM || 'evaluation@a.team';

export const REACT_APP_EVALUATION_EMAIL_CC: string | undefined =
  env.REACT_APP_EVALUATION_EMAIL_CC || undefined;

export enum HubspotAccounts {
  DEV = '21704970',
  PROD = '8946194',
}

export const HubspotAccountID = IS_PROD
  ? HubspotAccounts.PROD
  : HubspotAccounts.DEV;

export const HubspotDealsURL = `https://app.hubspot.com/contacts/${HubspotAccountID}/objects/0-3/views/all/board`;

export const skipConnectCalendarToApply =
  env.REACT_APP_SKIP_CONNECT_CALENDAR_TO_APPLY === 'true';
