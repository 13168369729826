import React from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import {
  RootLocation,
  VettingDashboardMemberToVetLocation,
} from '@src/locations';
import { addAdminViewSearchParam } from '@src/layouts/Vetting';

const VettingDashboardView = () => {
  const { auth } = useStores();

  if (!auth.isAdmin && !auth.isVetter) {
    return <Redirect to={RootLocation} />;
  }

  return (
    <>
      <Redirect
        to={addAdminViewSearchParam(
          VettingDashboardMemberToVetLocation,
          auth.isAdmin,
        )}
      />
    </>
  );
};

export default observer(VettingDashboardView);
