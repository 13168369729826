import React from 'react';
import { createUseStyles } from 'react-jss';
import { Pagination, PaginationProps } from '@src/components/Pagination';
import { Colors } from '@ateams/components';
import { Card } from '@ateams/components';
import { VetterSummary } from '@a_team/models/dist/vetter';
import { columns } from './columns';
import { VetterRow } from './vetter-row';

export interface SelectionTeamTableProps {
  rows: VetterSummary[];
  paginationProps?: PaginationProps;
}

const useStyles = createUseStyles({
  container: {
    padding: 0,
    overflowX: 'auto',
    borderRadius: 0,
    margin: '0 0 20px 0',
    maxHeight: 'calc(100vh - 210px)',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    borderRight: '1px solid #C0C0C0',
    fontSize: 15,
    fontWeight: 'normal',
    color: '#62646A',
    padding: '12px',
    '&:last-child': {
      borderRight: 'none',
    },

    /** Sticky Header */
    position: 'sticky',
    top: 0,
    background: Colors.backgroundWhite,
    zIndex: 10,

    /** {@link https://stackoverflow.com/questions/41882616/why-border-is-not-visible-with-position-sticky-when-background-color-exists} */
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      borderBottom: '1px solid #C0C0C0',
    },
  },
  paginationContainer: {
    width: '100%',
    padding: '0 10px',

    /** Sticky Footer */
    position: 'sticky',
    bottom: 0,
    left: 0,
    background: Colors.backgroundWhite,
    zIndex: 10,

    /** {@link https://stackoverflow.com/questions/41882616/why-border-is-not-visible-with-position-sticky-when-background-color-exists} */
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      borderTop: '1px solid #C0C0C0',
    },
  },
  thFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
});

export const SelectionTeamTable: React.FC<SelectionTeamTableProps> = (
  props,
) => {
  const styles = useStyles();
  const { rows, paginationProps } = props;

  return (
    <Card className={styles.container}>
      <table className={styles.table}>
        <tbody>
          <tr>
            {columns.map(({ header }, i) => (
              <th key={i} className={styles.th} scope={'row'}>
                <div className={styles.thFlex}>{header}</div>
              </th>
            ))}
          </tr>
          {rows.map((row) => (
            <VetterRow columns={columns} vetter={row} />
          ))}
        </tbody>
      </table>
      {paginationProps && (
        <div className={styles.paginationContainer}>
          <Pagination {...paginationProps} />
        </div>
      )}
    </Card>
  );
};
