import { action, computed, observable } from 'mobx';
import { ExperienceId } from '@a_team/models/dist/ExperienceObject';
import type { ExperienceObject } from '@a_team/models/dist/ExperienceObject';
import { ReactNode } from 'react';
import { Stores } from '@src/stores/index';

export interface ExperienceStoreData {
  current?: ExperienceObject;
}

export interface FieldProps {
  title?: string;
  subtitle?: string;
  required?: boolean;
  checkboxLabel?: string;
  placeholder?: string;
  component?: ReactNode;
}

export interface ExperienceFields {
  [key: string]: FieldProps;
}

export default class ExperienceStore implements ExperienceStoreData {
  @observable public current: ExperienceStoreData['current'];

  public constructor(rootStore: Stores, initialState?: ExperienceStoreData) {
    if (initialState) {
      this.current = initialState.current;
    }
  }

  @computed
  public get currentId(): ExperienceId | null {
    return this.current?.eid || null;
  }

  public filterCurrent(eid: ExperienceId): ExperienceObject | null {
    if (!this.current || this.current.eid !== eid) {
      return null;
    }

    return this.current;
  }

  @action
  public setCurrent(experience: ExperienceObject): void {
    this.current = experience;
  }

  public serialize(): ExperienceStoreData {
    return {
      current: this.current,
    };
  }
}
