import { Select, SelectProps } from '@ateams/components';
import { TalentSpecialization } from '@a_team/models/dist/TalentCategories';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Option } from 'react-select/src/filters';
import { useTalentSpecializations } from './hooks';

type SelectOption = Omit<Option, 'data'> & {
  data: TalentSpecialization;
};

interface Props extends Omit<SelectProps, 'onChange'> {
  onChange(data: TalentSpecialization | undefined): void;
  defaultValue?: string;
  className?: string;
}

export const SpecializationSelect = (props: Props): ReactElement => {
  const { onChange, defaultValue, ...rest } = props;
  const [specializations] = useTalentSpecializations();
  const [selectedValue, setSelectedValue] = useState<SelectOption | undefined>(
    undefined,
  );
  const options: SelectOption[] = useMemo(
    () => specializations.map(toOption),
    [specializations],
  );
  const onSelect = (option: Omit<SelectOption, 'data'> | null) => {
    setSelectedValue(Array.isArray(option) ? option[0] : option ?? undefined);
  };

  useEffect(() => {
    const specialization = specializations.find(
      (s) => !!selectedValue && s.id === selectedValue.value,
    );
    onChange(specialization);
  }, [specializations, selectedValue]);

  useEffect(() => {
    if (!selectedValue && defaultValue) {
      const specialization = specializations.find((s) => s.id === defaultValue);
      if (specialization) {
        setSelectedValue({
          value: specialization.id,
          label: specialization.name,
          data: specialization,
        });
      }
    }
  }, [defaultValue, specializations.length, selectedValue]);

  return (
    <Select
      className={props.className}
      value={selectedValue}
      filterOption={filterOptions}
      options={options}
      onChange={(val) => onSelect(val)}
      placeholder={'Select a specialization...'}
      {...rest}
    />
  );
};

const filterOptions = function (
  { label }: SelectOption,
  input: string,
): boolean {
  return !input || !label
    ? true
    : label.toLowerCase().includes(input.toLocaleLowerCase());
};

const toOption = (specialization: TalentSpecialization): SelectOption => ({
  label: specialization.name,
  value: specialization.id,
  data: specialization,
});
