import { ProjectExperienceData } from '@a_team/models/dist/ExperienceObject';
import { Button } from '@a_team/ui-components';
import { Breakpoints, Icon, IconType } from '@ateams/components';
import CompanyLogoFallback from '@src/components/CompanyLogoFallback';
import Description from '@src/components/Modal/JobModalView/Description';
import NoCover from '@src/components/ProjectCardV2/NoCover';
import cx from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Image from '@src/components/Image';
import AuthStore from '@src/stores/Auth';

const useStyles = createUseStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gap: 24,
    alignItems: 'flex-start',
    padding: 16,
    borderRadius: 16,
    transition: 'background 0.3s',

    '&:hover': {
      background: '#F7F7F7',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    width: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '22px',
    margin: 0,
  },
  info: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  infoItem: {
    padding: 16,
    background: '#F7F7F7',
    fontSize: 14,
    borderRadius: 6,
    display: 'inline-flex',
    alignItems: 'center',
    gap: 8,
    height: 48,
  },
  projectCover: {
    borderRadius: 2,
    '& img': {
      objectFit: 'cover !important',
    },
  },
  period: {
    color: '#6E7177',
    whiteSpace: 'nowrap',
  },
  suggestion: {
    background: '#EDE0FF',
    color: '#6D00D7',
    padding: '12px 16px',
    borderRadius: 8,
    display: 'flex',
    gap: 8,
    width: 'fit-content',
    marginBottom: 0,
  },
  infoAndActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 10,
  },
  mobileActions: {
    display: 'flex',
  },
  desktopActions: {
    display: 'none',
  },
  descContainer: {
    // all h3 should have marginTop 0
    '& h3': {
      marginTop: 0,
    },
  },
  actions: {
    gap: 8,
    '& button': {
      width: 60,
      height: 32,
      borderRadius: 6,
      padding: 0,
      '& span': {
        fontSize: 12,
      },
    },
  },
  ghostBtn: {
    border: '#DADADC 1px solid !important',
  },
  acceptSuggestionButton: {
    background: '#6D00D7 !important',
    width: 'fit-content !important',
    paddingLeft: '8px !important',
    paddingRight: '8px !important',
    color: 'white !important',
    minWidth: '151px',
  },
  logoAndActions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: 16,
  },
  logo: {
    display: 'flex',
    margin: 0,
  },
  companyName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'box',
    lineClamp: 1,
    boxOrient: 'vertical',
  },
  mobileSuggestions: {
    display: 'flex',
  },
  desktopSuggestions: {
    display: 'none',
  },
  infoItemsContainer: {
    display: 'flex',
    gap: 8,
    flexWrap: 'wrap',
  },

  noCoverImg: {
    borderRadius: '2px',
  },
  noCover: {
    width: '26px',
    height: 'auto',
    '& span': {
      width: '4px',
      height: '4px',
      display: 'flex',
    },
    '& svg': {
      width: '4px !important',
      height: '4px !important',
    },
  },
  noCoverIconImg: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  projectImagesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    container: {
      gridTemplateColumns: '40px minmax(0, 1fr)',
    },
    desktopActions: {
      display: 'flex',
    },
    mobileActions: {
      display: 'none',
    },
    mobileSuggestions: {
      display: 'none',
    },
    desktopSuggestions: {
      display: 'flex',
    },
    logoAndActions: {
      width: 'auto',
    },

    suggestion: {
      marginBottom: 16,
    },
  },
});

const Job = ({
  jobTitle,
  companyName,
  period,
  description,
  totalSkills,
  companyLogo,
  isSuggested,
  relatedProjects,
  auth,
  onUpdateClick,
  onDeleteClick,
  onOpenViewModal,
  onAcceptSuggestion,
  onDiscardSuggestion,
  isDiscardingSuggestion,
}: {
  jobTitle?: string;
  companyName?: string | null;
  period: string;
  description?: string;
  totalSkills?: number;
  companyLogo?: string;
  isSuggested?: boolean;
  auth: AuthStore;
  relatedProjects?: Omit<ProjectExperienceData, 'relatedJob'>[] | undefined;
  onUpdateClick?: () => void;
  onDeleteClick?: () => void;
  onOpenViewModal?: () => void;
  onAcceptSuggestion?: () => void;
  onDiscardSuggestion?: () => void;
  isDiscardingSuggestion?: boolean;
}) => {
  const styles = useStyles();

  const isEditMode =
    Boolean(onUpdateClick) ||
    Boolean(onDeleteClick) ||
    Boolean(onAcceptSuggestion) ||
    Boolean(onDiscardSuggestion);

  return (
    <div
      className={styles.container}
      style={{
        cursor: !isEditMode ? 'pointer' : 'default',
      }}
      onClick={() => {
        if (!isEditMode) {
          onOpenViewModal && onOpenViewModal();
        }
      }}
    >
      {isSuggested && (
        <div className={cx(styles.suggestion, styles.mobileSuggestions)}>
          <Icon size="smaller" type={IconType.SuggestedTeams} />
          Suggested job
        </div>
      )}
      <div className={styles.logoAndActions}>
        <CompanyLogoFallback
          className={styles.logo}
          logoUrl={companyLogo}
          borderRadius={8}
          size={40}
        />
        <div className={cx(styles.actions, styles.mobileActions)}>
          {onUpdateClick && (
            <Button onClick={onUpdateClick} variant="secondary">
              Edit
            </Button>
          )}

          {onDeleteClick && (
            <Button
              onClick={onDeleteClick}
              variant="ghost"
              className={styles.ghostBtn}
            >
              Delete
            </Button>
          )}

          {onAcceptSuggestion && (
            <Button
              onClick={onAcceptSuggestion}
              className={styles.acceptSuggestionButton}
              variant="secondary"
              disabled={isDiscardingSuggestion}
              loading={isDiscardingSuggestion}
            >
              Review and approve
            </Button>
          )}

          {onDiscardSuggestion && (
            <Button
              onClick={onDiscardSuggestion}
              variant="secondary"
              disabled={isDiscardingSuggestion}
            >
              {isDiscardingSuggestion ? 'Loading' : 'Discard'}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.infoAndActions}>
          <div>
            {isSuggested && (
              <div className={cx(styles.suggestion, styles.desktopSuggestions)}>
                <Icon size="smaller" type={IconType.SuggestedTeams} />
                Suggested job
              </div>
            )}
            <h4 className={styles.title}>{jobTitle}</h4>
            <div className={styles.info}>
              {companyName && (
                <>
                  <span className={styles.companyName}>{companyName}</span>
                  <span>·</span>
                </>
              )}

              <span className={styles.period}>{period}</span>
            </div>
          </div>
          <div className={cx(styles.actions, styles.desktopActions)}>
            {onUpdateClick && (
              <Button onClick={onUpdateClick} variant="secondary">
                Edit
              </Button>
            )}

            {onDeleteClick && (
              <Button
                onClick={onDeleteClick}
                variant="ghost"
                className={styles.ghostBtn}
              >
                Delete
              </Button>
            )}

            {onAcceptSuggestion && (
              <Button
                onClick={onAcceptSuggestion}
                className={styles.acceptSuggestionButton}
                variant="secondary"
                disabled={isDiscardingSuggestion}
                loading={isDiscardingSuggestion}
              >
                Review and approve
              </Button>
            )}

            {onDiscardSuggestion && (
              <Button
                onClick={onDiscardSuggestion}
                variant="secondary"
                disabled={isDiscardingSuggestion}
              >
                {isDiscardingSuggestion ? 'Loading' : 'Discard'}
              </Button>
            )}
          </div>
        </div>
        {description && (
          <div className={styles.descContainer}>
            <Description description={description} />
          </div>
        )}
        <div className={styles.infoItemsContainer}>
          {(totalSkills || 0) > 0 && (
            <div>
              <div className={styles.infoItem}>
                {totalSkills} {totalSkills === 1 ? 'skill' : 'skills'}
              </div>
            </div>
          )}
          {auth.withRelatedExperiences &&
            relatedProjects &&
            relatedProjects.length > 0 && (
              <div>
                <div className={styles.infoItem}>
                  <div className={styles.projectImagesContainer}>
                    {relatedProjects.slice(0, 2).map((project, index) => {
                      const projectCover =
                        project.imagesUrls?.[0] ||
                        project.imageURL ||
                        undefined;

                      const isSecondProject = index === 1;

                      const secondProjectStyles = {
                        border: '3px solid #EFEFF0',
                        borderRadius: '6px',
                        marginLeft: '-8px',
                        zIndex: 2,
                      };

                      const commonStyles = {
                        border: '3px solid #EFEFF0',
                        borderRadius: '6px',
                        zIndex: 1,
                      };

                      return (
                        <div
                          key={index}
                          style={{
                            ...(isSecondProject
                              ? secondProjectStyles
                              : commonStyles),
                          }}
                        >
                          {projectCover ? (
                            <Image
                              alt={project.name || ''}
                              src={projectCover}
                              className={styles.projectCover}
                              sizes="26px"
                              width={26}
                              aspectRatio={4 / 3}
                            />
                          ) : (
                            <NoCover
                              companyLogo={companyLogo}
                              number={index}
                              className={styles.noCover}
                              imgClassName={styles.noCoverImg}
                              logoSize={8}
                              paddingLogoContainer="0px"
                              borderRadius={2}
                              logoClassName={styles.noCoverIconImg}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                  {relatedProjects.length}{' '}
                  {relatedProjects.length === 1
                    ? 'related project'
                    : 'related projects'}
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default Job;
