import React, { ReactElement, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import OutlinedInput from '../Inputs/OutlinedInput';

interface Props {
  minYearsExperience?: number;
  onChange: (value: number) => void;
}

const useStyles = createUseStyles({
  hourlyInput: {
    margin: 0,
    '& > div': {
      borderRadius: 4,
      minHeight: 'unset',
      padding: '0.6em 0.5em',
    },
  },
});

const YearsExperienceFilter = (props: Props): ReactElement => {
  const styles = useStyles();
  const { minYearsExperience, onChange } = props;
  const [value, setValue] = React.useState(minYearsExperience);

  useEffect(() => {
    setValue(minYearsExperience);
  }, [minYearsExperience]);

  return (
    <>
      <OutlinedInput
        type="number"
        className={styles.hourlyInput}
        onBlur={(e) => onChange(parseInt(e.target.value))}
        onChange={(e) => setValue(parseInt(e.target.value))}
        value={value || ''}
        endAdornment={<span style={{ marginLeft: 4 }}>years</span>}
        placeholder={'Years'}
      />
    </>
  );
};

export default YearsExperienceFilter;
