import React, { ReactElement } from 'react';
import { MissionPaymentCycleInvoiceObject } from '@a_team/models/dist/InvoiceObject';
import MissionMissionPaymentCycleInvoicePage from './Page';
import { createPageRows } from '../../helpers';

interface Props {
  invoice: MissionPaymentCycleInvoiceObject;
}

export default function MissionMissionPaymentCycleInvoice(
  props: Props,
): ReactElement {
  const { invoice } = props;
  const { rolesInvoices } = invoice;

  const pages = createPageRows({
    rows: rolesInvoices,
    heroSize: 6,
    rowSize: () => 1,
  });

  return (
    <>
      {pages.map((page, i) => (
        <MissionMissionPaymentCycleInvoicePage
          key={`page-${i + 1}`}
          invoice={invoice}
          roles={page}
          hero={i === 0}
          hasMore={i < pages.length - 1}
        />
      ))}
    </>
  );
}
