import React, { ReactElement } from 'react';
import { stringifyMinutes } from '@src/helpers/time';
import MobileTableRow from '@src/views/Mission/TimeTracking/TimesheetsTable/MobileTableRow';
import { Props } from '@src/views/Mission/TimeTracking/TimesheetsTable/TableContent';

export default function MobileTableContent(props: Props): ReactElement {
  const {
    userRecords,
    currentUserRole,
    totalMinutes,
    timesheetId,
    updateRecord,
    deleteRecord,
    teamRecords,
    timesheetsView,
    adminView,
    clientView,
    disabled,
  } = props;

  return (
    <div>
      {teamRecords
        ? teamRecords.map((record) => (
            <>
              {record.records.map((innerRecord) => (
                <MobileTableRow
                  key={`${innerRecord.key}${innerRecord.role.rid}`}
                  record={innerRecord}
                  timesheetId={timesheetId}
                  deleteRecord={deleteRecord}
                  updateRecord={updateRecord}
                  currentUserRole={currentUserRole}
                  clientView={clientView}
                  disabled={disabled}
                />
              ))}
            </>
          ))
        : userRecords?.map((record) => (
            <>
              {record.records.map((innerRecord) => (
                <MobileTableRow
                  key={`${innerRecord.key}${innerRecord.role.rid}`}
                  record={innerRecord}
                  timesheetId={timesheetId}
                  deleteRecord={deleteRecord}
                  updateRecord={updateRecord}
                  currentUserRole={currentUserRole}
                  clientView={clientView}
                  disabled={disabled}
                />
              ))}
            </>
          ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          background: 'rgba(0, 0, 0, 0.02)',
          padding: '24px 16px',
          fontSize: 17,
          fontWeight: 600,
        }}
      >
        <div>{stringifyMinutes(totalMinutes || 0)}</div>
        <div>
          {currentUserRole?.hourlyRate &&
          totalMinutes &&
          (timesheetsView === 'single' || adminView)
            ? `$${(
                Math.floor(
                  currentUserRole.hourlyRate * (totalMinutes / 60) * 100,
                ) / 100
              ).toLocaleString()}`
            : adminView
            ? '$0'
            : '$X,XXX'}
        </div>
      </div>
    </div>
  );
}
