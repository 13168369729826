import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

const useStyles = createUseStyles({
  star: {
    width: (props) => props.size.width || '6px',
    height: (props) => props.size.height || '7px',
  },
});

function Star({
  size = { width: '6px', height: '7px' },
  className,
}: {
  size?: { width?: string; height?: string };
  className?: string;
}) {
  const styles = useStyles({ size });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cx(styles.star, className)} // Combining JSS classes with custom class
      fill="none"
      viewBox="0 0 6 7"
    >
      <path
        fill="#6D00D7"
        d="M3 .5l.411 1.112a2.5 2.5 0 001.477 1.477L6 3.5l-1.112.411a2.5 2.5 0 00-1.477 1.477L3 6.5l-.411-1.112a2.5 2.5 0 00-1.477-1.477L0 3.5l1.112-.411a2.5 2.5 0 001.477-1.477L3 .5z"
      ></path>
    </svg>
  );
}

export default Star;
