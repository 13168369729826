import React, { ButtonHTMLAttributes, ReactElement } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import ButtonContainer from './ButtonContainer';
import Colors, { ColorName, TextColors } from '../theme/colors';
import Spinner from '../Spinner';

const Widths = {
  default: '280px',
  auto: 'auto',
  expend: '100%',
  small: '140px',
};

export type ButtonColor = ColorName;

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  width?: keyof typeof Widths;
  circle?: boolean;
  color?: ButtonColor;
  secondaryDisabledColor?: boolean;
  id?: string;
  size?: 'default' | 'small' | 'auto' | 'xsmall';
  loading?: boolean;
  squared?: boolean;
  outlined?: boolean;
  dataTestingId?: string;
}

const defaultProps = {
  secondaryDisabledColor: false,
};

const getBackgroundColor = (props: ButtonProps): string => {
  if (props.outlined) {
    return 'transparent';
  }
  return props.disabled
    ? props.secondaryDisabledColor
      ? Colors.secondaryLight
      : Colors.backgroundDark
    : Colors[props.color || 'primary'];
};

const getColor = (props: ButtonProps): string => {
  if (props.color === 'transparent') {
    return TextColors[props.color];
  } else if (props.outlined) {
    return props.disabled
      ? Colors.primaryLight
      : Colors[props.color || 'primary'];
  }
  return props.disabled && props.secondaryDisabledColor
    ? 'rgba(255,255,255, 0.5)'
    : TextColors[props.color || 'primary'];
};

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    padding: (props: ButtonProps): string => {
      switch (props.size) {
        case 'small':
          return '11px 24px';
        case 'xsmall':
          return '4px';
        default:
          return '16px 32px';
      }
    },
    paddingBottom: '1em',
    width: (props: ButtonProps): string =>
      props.circle ? '56px' : Widths[props.width || 'default'],
    height: (props: ButtonProps): number | string =>
      props.size === 'auto' || props.size === 'xsmall'
        ? 'auto'
        : props.size === 'small'
        ? 40
        : 56,
    lineHeight: (props: ButtonProps): string =>
      props.size === 'small' ? '16px' : 'auto',
    background: getBackgroundColor,
    borderRadius: (props: ButtonProps) => (props.squared ? '3px' : '4em'),
    border: (props: ButtonProps) =>
      props.outlined ? `1px solid ${Colors[props.color || 'primary']}` : 'none',
    fontWeight: (props: ButtonProps): number =>
      props.size === 'xsmall' ? 400 : 500,
    fontSize: (props: ButtonProps): string =>
      props.size === 'xsmall' ? '14px' : '1em',
    color: getColor,
    cursor: (props: ButtonProps): string =>
      props.disabled ? 'not-allowed' : 'pointer',
    textAlign: 'center',
  },
  spinnerContainer: {
    position: 'absolute',
    // center the spinner with flex
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
});

const CallToActionButton = (props: ButtonProps): ReactElement => {
  const {
    className,
    circle,
    width,
    color,
    secondaryDisabledColor,
    size,
    loading,
    outlined,
    squared,
    dataTestingId,
    ...left
  } = props;
  const { root, spinnerContainer } = useStyles(props);

  const children = loading ? (
    <span style={{ visibility: 'hidden' }}>{props.children}</span>
  ) : (
    props.children
  );

  return (
    <button
      className={cx(root, className)}
      data-testing-id={dataTestingId}
      {...left}
    >
      {loading ? (
        <span
          className={`${spinnerContainer} loading`}
          data-testing-id="loading-indicator"
        >
          <Spinner size={size === 'xsmall' ? 16 : 24} />{' '}
        </span>
      ) : null}

      {children}
    </button>
  );
};

CallToActionButton.defaultProps = defaultProps;
export default CallToActionButton;
export { ButtonContainer };
