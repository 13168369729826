import {
  parseServiceFetchError,
  ServiceAuth,
  serviceFetchParameters,
  ServiceFetchParams,
} from '@ateams/service-utils';
import {
  BasicVettingProcess,
  VettingFormVariant,
  VettingInterviewer,
  VettingProcessUser,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { VettingProcessFeedbackRoles } from '@a_team/models/dist/vetting-processes/feedback';
import {
  CompanySize,
  DidBuildProductsFromScratch,
  DidDesignedProductsFromScratch,
  ManagementExperience,
  PrimaryExpertise,
  ProductType,
  TypicalTeamSize,
  WeeklyHours,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import {
  TalentSkill,
  TalentSkillId,
} from '@a_team/models/dist/TalentCategories';
import { ServiceEndpoint } from './utils';
import { API_SERVICE_BASE_PATH } from '../config';
import { InterviewScheduledPayload } from './vetting-process';
import { VettingProcessStatus } from '@a_team/models/src/vetting-processes/status';

export const BasePath = '/vetting-process/pre-vetting';

export interface ValidatePreVettingFormNonceResponse {
  isSubmitted: boolean;
  user: VettingProcessUser;
  variant: VettingFormVariant;
  status: VettingProcessStatus;
}

export interface GetPreVettingFormResponse {
  talentCategoryIds: string[];
  requiredSkills: TalentSkill[];
}

/* @deprecated */
interface BaseSubmitPreVettingFormPayload {
  calendarUrl?: string;
  weeklyHours?: WeeklyHours;
}

/* @deprecated */
export interface SubmitSoftwareEngineeringPreVettingFormPayload
  extends BaseSubmitPreVettingFormPayload {
  variant: VettingFormVariant.SoftwareEngineering;
  companySize: CompanySize[];
  didBuildProductsFromScratch: DidBuildProductsFromScratch;
  managementExperience: ManagementExperience;
  codeSampleProgrammingLanguages: TalentSkillId[];
  otherCodeSampleProgrammingLanguage?: string;
  codeSample: string | File;
  codeSampleNotes?: string;
}

/* @deprecated */
export interface SubmitProductManagementPreVettingFormPayload
  extends BaseSubmitPreVettingFormPayload {
  variant: VettingFormVariant.ProductManagement;
  primaryExpertise: PrimaryExpertise[];
  companySize: CompanySize[];
  productType: ProductType[];
  hasExperienceWorkingOnAProduct: boolean;
  typicalTeamSize: TypicalTeamSize;
  hadProductDesignerOnTeam: boolean;
  hasProfitAndLossResponsibility?: boolean;
  hasTechnicalBackground: boolean;
}

/* @deprecated */
export interface SubmitProjectManagementPreVettingFormPayload
  extends BaseSubmitPreVettingFormPayload {
  variant: VettingFormVariant.ProjectManagement;
  primaryExpertise: PrimaryExpertise[];
  companySize: CompanySize[];
  productType: ProductType[];
  hasExperienceWorkingOnAProduct: boolean;
  typicalTeamSize: TypicalTeamSize;
  hadProductDesignerOnTeam: boolean;
  hasProfitAndLossResponsibility?: boolean;
  hasTechnicalBackground: boolean;
}

/* @deprecated */
export interface SubmitDesignPreVettingFormPayload
  extends BaseSubmitPreVettingFormPayload {
  variant: VettingFormVariant.Design;
  primaryRoles: VettingProcessFeedbackRoles[];
  companySize: CompanySize[];
  didDesignedProductsFromScratch?: DidDesignedProductsFromScratch;
  caseStudy: string | File;
  hadDesignedCaseStudyAsTeamOrIndividual: string;
}

/* @deprecated */
export type SubmitPreVettingFormPayloadOld =
  | SubmitSoftwareEngineeringPreVettingFormPayload
  | SubmitProductManagementPreVettingFormPayload
  | SubmitProjectManagementPreVettingFormPayload
  | SubmitDesignPreVettingFormPayload;

export interface SubmitPreVettingFormPayload {
  variant: VettingFormVariant;
  selectedSkills: string[];
  selectedExperiences: string[];
  additionalMaterialLinks: string[];
  additionalMaterialFiles: File[];
  additionalMaterialDetails: string;
}

export default class VettingProcessPreVettingEndpoint extends ServiceEndpoint {
  /**
   * Checks whether a given nonce token is valid, matches a prevetting form, and if the form was already submitted
   * @param auth - Builders
   * @param nonce - A token to match against the user form
   */
  public validatePreVettingNonce(
    auth: ServiceAuth,
    nonce: string,
  ): Promise<ValidatePreVettingFormNonceResponse> {
    return this.fetch(auth, `${BasePath}/validate/${nonce}`, null, 'get');
  }

  /**
   * Submits and presists the prevetting form
   * @param auth - Builders
   * @param nonce - A token to match against the user form
   */
  public async submitPreVettingForm(
    auth: ServiceAuth,
    nonce: string,
    formData: FormData,
  ): Promise<void> {
    const params: ServiceFetchParams = [
      auth,
      `${API_SERVICE_BASE_PATH}${BasePath}/${nonce}`,
      null,
      'PATCH',
    ];

    const [url, init] = serviceFetchParameters(...params);

    init.body = formData;

    const res = await fetch(url, init);
    if (res.ok) {
      return res.json();
    }

    return parseServiceFetchError(res, params);
  }

  /**
   * Cancels interview
   * @param auth - Builder
   * @param nonce - A token to match against the user form
   */
  public async cancelInterview(
    auth: ServiceAuth,
    nonce: string,
    reason: string,
  ): Promise<void> {
    const params: ServiceFetchParams = [
      auth,
      `${API_SERVICE_BASE_PATH}${BasePath}/${nonce}/cancel-interview`,
      null,
      'PATCH',
      { reason },
    ];

    const [url, init] = serviceFetchParameters(...params);

    const res = await fetch(url, init);
    if (res.ok) {
      return res.json();
    }

    return parseServiceFetchError(res, params);
  }

  /**
   * Marks the vetting process as a no show
   * @param nonce - A token to match against the user form
   * @param isBuilder - Whether the no show is from a builder
   */
  public async markAsNoShow(
    auth: ServiceAuth,
    nonce: string,
    isBuilder: boolean,
  ): Promise<void> {
    const params: ServiceFetchParams = [
      auth,
      `${API_SERVICE_BASE_PATH}${BasePath}/${nonce}/mark-as-no-show`,
      null,
      'PATCH',
      { isBuilder },
    ];

    const [url, init] = serviceFetchParameters(...params);

    const res = await fetch(url, init);
    if (res.ok) {
      return res.json();
    }

    return parseServiceFetchError(res, params);
  }

  /**
   * Fetch the interviewers for a given user
   * Used to show the cal.com calendar
   * @param auth - Builder
   */
  public fetchInterviewers(
    auth: ServiceAuth,
    nonce: string,
    skipFilters = false,
  ): Promise<VettingInterviewer[]> {
    return this.fetch(
      auth,
      `${BasePath}/interviewers/${nonce}?skipFilters=${skipFilters}`,
    );
  }

  /**
   * Fetches the pre-vetting form data
   * @param auth - Builder
   * @param nonce - A token to match against the user form
   */
  public getPreVettingFormData(
    auth: ServiceAuth,
    nonce: string,
  ): Promise<GetPreVettingFormResponse> {
    return this.fetch(auth, `${BasePath}/${nonce}`);
  }

  /**
   * Update the interviewer and the interview date for a given vetting process
   * @param auth - Builders
   */
  public updateInterviewScheduled(
    auth: ServiceAuth,
    nonce: string,
    payload: InterviewScheduledPayload,
  ): Promise<BasicVettingProcess> {
    return this.fetch(
      auth,
      BasePath + `/interview-scheduled/${nonce}`,
      null,
      'PATCH',
      payload,
    );
  }
}
