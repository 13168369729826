import React from 'react';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import { Colors, floatAnimation } from '@ateams/components';
import logo from './logo.svg';
import { Footer } from '@src/views/Registration/Footer';

export interface PreVettingLayoutOldProps {
  title?: string;
}

const useStyles = createUseStyles({
  root: {
    alignItems: 'center',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    padding: '40px',
  },
  content: {
    minHeight: '100vh',
    paddingRight: 20,
    overflow: 'auto',
    flexShrink: 0,
    flexGrow: 1,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    background: Colors.backgroundWhite,
  },
  contentInner: {
    alignItems: 'center',
    height: '100%',
    display: 'flex',
    flex: 1,
  },
  ...floatAnimation,
  footerContainer: {
    paddingLeft: '16px',
  },
  [`@media (max-width: 500px)`]: {
    topBar: {
      padding: '24px',
    },
  },
});

export const PreVettingLayoutOld: React.FC<PreVettingLayoutOldProps> = observer(
  (props) => {
    const { title, children } = props;
    const styles = useStyles();
    const { document } = useStores();

    document.setInnerTitle(title || 'PreVetting');

    return (
      <>
        <div className={styles.root}>
          <div className={styles.content}>
            <div className={styles.topBar}>
              <img src={logo} alt={'logo'} />
            </div>
            <div className={styles.contentInner}>{children}</div>

            <div className={styles.footerContainer}>
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  },
);
