import React from 'react';
import { createUseStyles } from 'react-jss';

interface LabelProps {
  checked?: boolean;
  children?: React.ReactNode;
}

const useStyles = createUseStyles<{
  checked?: boolean;
}>({
  container: {
    fontSize: '15px',
    textDecoration: ({ checked }) => (checked ? 'line-through' : 'none'),
  },
});

const Label = ({ checked, children }: LabelProps): JSX.Element => {
  const styles = useStyles({ checked });
  return <div className={styles.container}>{children}</div>;
};

export default Label;
