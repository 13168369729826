import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import logo from '@src/layouts/Registration/logo.svg';
import RegistrationStep from '@src/views/Registration/RegistrationStep';
import { useStores } from '@src/stores';
import { useScreenClass } from 'react-grid-system';
import { Button, ButtonContainer } from '@ateams/components';

const RegistrationIntroView = (): ReactElement => {
  const screenClass = useScreenClass();
  const { registration } = useStores();
  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  return (
    <RegistrationStep
      title={'Welcome to a New Way to Work'}
      withMobileVideo={registration.currentStep.withMobileVideo}
      description={
        <>
          A.Team is where the world best product builders team up to build for
          ambitious companies solving meaningful problems.
          <span style={{ marginTop: 8, display: 'block' }}>
            Here, you can team up on high-paying, quality missions with talented
            people — and work the way you want to work.
          </span>
        </>
      }
      buttonLabel={"Let's Start"}
      onSubmit={registration.stepForward}
      icon={
        isMobile ? (
          <img
            src={logo}
            style={{ marginBottom: 32, width: 30 }}
            alt={'Logo'}
          />
        ) : null
      }
      withButton={!isMobile}
    >
      {isMobile ? (
        <ButtonContainer
          align={'center'}
          style={{ marginTop: 20, marginBottom: 30 }}
        >
          <Button
            style={{ fontSize: 12 }}
            width={'auto'}
            size={'small'}
            onClick={registration.stepForward}
          >
            Let's Start
          </Button>
        </ButtonContainer>
      ) : null}
    </RegistrationStep>
  );
};

export default observer(RegistrationIntroView);
