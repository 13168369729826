import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import loadingIcon from '@src/components/LoadingIndicator/loading.png';
import ReactPlayer, { ReactPlayerProps } from 'react-player/lazy';
import { createUseStyles } from 'react-jss';
import { useScreenClass } from 'react-grid-system';
import { Breakpoints } from '@ateams/components';
import cx from 'classnames';

interface Props extends ReactPlayerProps {
  url: string | undefined;
  loading?: boolean;
  onReady?: () => void;
  onError?: (error: Error) => void;
  noBorder?: boolean;
  borderRadius?: number;
  className?: string;
  hideLoading?: boolean;
  hideLoomFields?: boolean;
}

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: (props: Props) => props.height || 300,
  },
  player: {
    background: 'rgb(243, 243, 243)',
    borderRadius: (props: Props) =>
      props.borderRadius !== undefined ? props.borderRadius : 8,
    border: (props: Props) =>
      props.noBorder ? 'none' : '1px solid rgba(192, 192, 192, 0.3)',
    overflow: 'hidden',
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    width: 60,
    height: 60,
    animation: '$loading 1s linear infinite',
    zIndex: 2,
  },
  '@keyframes loading': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: (props: Props) => ({
      width: props.width || 'auto',
      height: props.height || 'auto',
    }),
  },
});

const isLoomUrl = (url: string): boolean =>
  /(?:(http(s)?):\/\/)w{0,3}\.loom[^' '\n\r]+/g.test(url);

export const VideoPlayer = (props: Props): ReactElement => {
  const styles = useStyles(props);
  const screenClass = useScreenClass();
  const {
    url,
    onError,
    onReady,
    borderRadius,
    noBorder,
    className,
    loading: loadingProps,
    hideLoading,
    hideLoomFields,
    ...rest
  } = props;
  const [loading, setLoading] = useState(!!url || loadingProps);

  const loomId = useMemo(() => {
    if (url && isLoomUrl(url)) {
      return url.split('/').pop();
    } else {
      return undefined;
    }
  }, [url]);

  useEffect(() => {
    const hasUrl = url && !!url.length;
    hasUrl && setLoading(true);
  }, [url]);

  const loomUrl = useMemo(() => {
    // if hideLoomFields is true, then add the query params to the url
    let queryParams = '';

    if (rest.playing) {
      queryParams += '?autoplay=1';
    }

    if (hideLoomFields) {
      queryParams += queryParams ? '&' : '?';
      queryParams +=
        'hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';
    }

    if (loomId) {
      return `https://www.loom.com/embed/${loomId}${queryParams}`;
    } else {
      return undefined;
    }
  }, [loomId, rest.playing, hideLoomFields]);

  return (
    <div className={cx(styles.container, className)}>
      {loading && !hideLoading && (
        <img src={loadingIcon} className={styles.loader} alt={'loading'} />
      )}
      {loomId ? (
        <iframe
          onLoad={() => {
            setLoading(false);
            onReady && onReady();
          }}
          title={'video'}
          className={styles.player}
          style={{
            width: screenClass === 'xs' ? '100%' : rest.width || 640,
            height: screenClass === 'xs' ? '100%' : rest.height || 360,
          }}
          src={loomUrl}
          frameBorder="0"
          allowFullScreen
        />
      ) : (
        <ReactPlayer
          url={props.url}
          playing={rest.playing}
          className={styles.player}
          controls
          onReady={() => {
            setLoading(false);
            onReady && onReady();
          }}
          onError={(error) => {
            setLoading(false);
            onError && onError(error);
          }}
          width={screenClass === 'xs' ? '100%' : 640}
          height={screenClass === 'xs' ? '100%' : 360}
          {...rest}
        />
      )}
    </div>
  );
};
