import React from 'react';
import {
  TalentPrivacyStatementLocation,
  TermsOfServiceLocation,
} from '@src/locations';
import { createUseStyles } from 'react-jss';
import { Colors } from '@ateams/components';

const useStyles = createUseStyles({
  link: {
    color: Colors.secondaryDark,
  },
});

function ToS() {
  const styles = useStyles();

  return (
    <div>
      <h2>Terms of Service</h2>
      <div>
        By clicking "I agree", you agree to our{' '}
        <a
          href={TermsOfServiceLocation}
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          Terms of Service Agreement
        </a>{' '}
        and{' '}
        <a
          href={TalentPrivacyStatementLocation}
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          A.Teamer Privacy Statement
        </a>
      </div>
    </div>
  );
}

export default ToS;
