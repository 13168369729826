import { UserId } from '@a_team/models/dist/UserObject';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import BlurbSummary from './BlurbSummary';
import cx from 'classnames';
import { Icon, theme } from '@a_team/ui-components';
import { DateISOString } from '@a_team/models/dist/misc';

const useStyles = createUseStyles({
  main: {
    overflow: 'auto',
    flex: 1,
    minHeight: 100,
    backgroundColor: 'white',
    padding: 16,
    paddingTop: 0,
  },
  header: {
    zIndex: 2,
    backgroundColor: 'white',
    position: 'sticky',
    top: 0,
    padding: '25px 8px 9px 8px',
  },
  modalTitle: {
    fontSize: 20,
    margin: '0 0 6px',
  },
  subtitle: {
    margin: '0 0 5px',
  },
  list: {},
  listItem: {
    transition: 'all ease .15s',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: 4,
    padding: '9px 9px 7px',
  },
  active: {
    color: theme.colors.Baracus[600],
    backgroundColor: theme.colors.Baracus[100],
  },
  arrow: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
});

export interface BuilderTfsPitchObject {
  createdAt: DateISOString;
  tfsPitch: {
    blurb?: string;
    website?: string;
  };
  clientName?: string;
  projectName?: string;
  hourlyRate?: number;
  role: { rid: string; title: string };
  uid: UserId;
  proposalId: string;
}

interface BlurbsListProps {
  builderName: string;
  onClick(blurb: BuilderTfsPitchObject): void;
  pitches: Array<BuilderTfsPitchObject>;
}

interface BlurbListItemProps {
  tfsPitch: BuilderTfsPitchObject;
  active: boolean;
  onClick(): void;
}

const BlurbListItem: FC<BlurbListItemProps> = ({
  tfsPitch,
  onClick,
  active,
}) => {
  const styles = useStyles();

  return (
    <div
      className={cx(styles.listItem, { [styles.active]: active })}
      onClick={onClick}
    >
      <Icon
        color="inherit"
        className={styles.arrow}
        name="arrowForward"
        size="md"
      />
      <BlurbSummary showRate={false} tfsPitch={tfsPitch} />
    </div>
  );
};

const BlurbsList: FC<BlurbsListProps> = ({ pitches, builderName, onClick }) => {
  const styles = useStyles();
  const [active, setActive] = useState(0);

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h3 className={styles.modalTitle}>
          Blurbs History ({pitches.length || 0})
        </h3>
        <p className={styles.subtitle}>
          Find and re-use {builderName}'s proposal blurbs.
        </p>
      </div>

      <div className={styles.list}>
        {pitches.map((blurb, index) => (
          <BlurbListItem
            onClick={(): void => {
              setActive(index);
              onClick(blurb);
            }}
            active={active === index}
            key={blurb.proposalId}
            tfsPitch={blurb}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(BlurbsList);
