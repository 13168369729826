import { OnboardingStage } from '@a_team/models/dist/UserObject';
import { Breakpoints } from '@ateams/components';
import LimitedMissionsModal from '@src/components/Modal/LimitedMissions';
import MainLayout from '@src/layouts/Main';
import { MissionApplyLocation, RootLocation } from '@src/locations';
import { useGetBuilderTypeAndMissionData } from '@src/rq/missions';
import {
  // useGetEvaluationCallDetails,
  useGetProfileCompletion,
  useUpdateOnboardingStage,
} from '@src/rq/users';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Redirect, useHistory } from 'react-router-dom';
import { BecomeFullMember } from './BecomeFullMember';
import Header from './Header';
import ApplyNowStep from './Steps/ApplyNowStep';
import StepSkeleton from './Steps/common/Step/StepSkeleton';
import CompleteProfileStep from './Steps/CompleteProfileStep';
import EvaluationCallStep from './Steps/EvaluationCallStep';
import JoinTheTeamStep from './Steps/JoinTheTeamStep';
import ReviewProfileStep from './Steps/ReviewProfileStep';
import ReviseProfileStep from './Steps/ReviseProfileStep';
import ThankYouEvaluationCall from './ThankYouEvaluationCall';
import Welcome from './Welcome';
import YouAreIn from './YouAreIn';

const useStyles = createUseStyles({
  container: {
    maxWidth: 1240,
    margin: '0 auto',
    padding: '16px 0',
  },
  stepsContainer: {
    margin: '40px auto 0',
    maxWidth: 680,
    display: 'flex',
    gap: 40,
    flexDirection: 'column',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    stepsContainer: {
      gap: 8,
      marginBottom: 40,
    },
  },
});

const REFRESH_TOKEN_INTERVAL_MS = 30000; // 30 seconds

const Setup = () => {
  const { auth } = useStores();
  const styles = useStyles();

  const {
    data: builderTypeAndMissionData,
    isFetching: isFetchingBuilderTypeAndMissionData,
  } = useGetBuilderTypeAndMissionData();

  const isSourcedBuilder = builderTypeAndMissionData?.builderType === 'sourced';
  const isPrescrubbed =
    builderTypeAndMissionData?.builderType === 'prescrubbed';
  const hasApplied = builderTypeAndMissionData?.hasApplied;
  const isRoleStillAvailable = builderTypeAndMissionData?.isRoleStillAvailable;
  const mission = builderTypeAndMissionData?.mission;

  const { data: profileCompletion } = useGetProfileCompletion({
    // if it's a source builder we want to load the profile completion
    enabled: isSourcedBuilder && !isFetchingBuilderTypeAndMissionData,
  });

  const {
    mutate: updateOnboardingStage,
    isLoading: isUpdatingOnboardingStage,
  } = useUpdateOnboardingStage();

  const history = useHistory();

  const [onboardingStage, setOnboardingStage] = useState<OnboardingStage>(
    auth.onboardingStage ?? OnboardingStage.IncompleteProfile,
  );

  // if it is not a sourced builder we want to update the onboarding stage
  useEffect(() => {
    if (!isSourcedBuilder) {
      updateOnboardingStage();
    }
  }, [isSourcedBuilder]);

  useEffect(() => {
    // makes sure we have the latest data
    auth.fetchData(true);
  }, []);

  useEffect(() => {
    setOnboardingStage(
      auth.onboardingStage ?? OnboardingStage.IncompleteProfile,
    );

    let intervalId: number | null = null;

    if (
      [
        OnboardingStage.InterviewScheduled,
        OnboardingStage.Accepted,
        OnboardingStage.Completed,
      ].includes(onboardingStage) &&
      typeof window !== 'undefined'
    ) {
      intervalId = window.setInterval(() => {
        auth.renewToken();
      }, REFRESH_TOKEN_INTERVAL_MS);
    }

    return () => {
      if (intervalId !== null && typeof window !== 'undefined') {
        window.clearInterval(intervalId);
      }
    };
  }, [auth.onboardingStage, onboardingStage, auth]);

  if (auth.withOnboardingV2 === false) {
    return <Redirect to={RootLocation} />;
  }

  if (auth.restrictedAccess) {
    return <Redirect to={`/${auth.username}`} />;
  }

  const isYouAreIn = onboardingStage === OnboardingStage.Accepted;
  const isWelcome = onboardingStage === OnboardingStage.Completed;

  if (isYouAreIn) return <YouAreIn />;

  if (auth.limitedAccess) {
    return <ThankYouEvaluationCall />;
  } else if (isWelcome) {
    return <Welcome />;
  }

  const headerTitle = isSourcedBuilder
    ? 'We think you’d be a great fit for the following mission'
    : 'Getting started as an A.Team Builder';

  const headerDescription = isSourcedBuilder
    ? 'A.Team is a curated network of top product builders.You’ve been invited to apply to the mission listed below.'
    : 'Complete the steps below to request to join A.Team, a network of world-class builders pursuing their craft with autonomy.';

  return (
    <MainLayout
      title="Setup Page"
      style={{ padding: '15px 24px' }}
      contentClassName={styles.content}
    >
      {auth.isActiveWaitlistedAdVerified ? (
        <LimitedMissionsModal open />
      ) : (
        <div className={styles.container}>
          <Header
            isRoleStillAvailable={isSourcedBuilder && isRoleStillAvailable}
            mission={mission}
            onboardingStage={onboardingStage}
            title={headerTitle}
            subtitle={headerDescription}
          />

          <div className={styles.stepsContainer}>
            {auth.onboardingStage === undefined ||
            isFetchingBuilderTypeAndMissionData ||
            isUpdatingOnboardingStage ? (
              <>
                <StepSkeleton withCta withMedia isCurrentStep />
                <StepSkeleton />
                <StepSkeleton />
                <StepSkeleton isFinalStep />
              </>
            ) : (
              <>
                {isSourcedBuilder ? (
                  <>
                    <ApplyNowStep
                      missionName={mission?.title || ''}
                      companyLogo={mission?.logoURL}
                      onboardingStage={onboardingStage}
                      hasApplied={!!hasApplied}
                      onApply={() => {
                        if (mission?.mid && mission?.role.rid) {
                          history.push(
                            MissionApplyLocation(
                              mission?.mid,
                              mission?.role.rid,
                            ),
                          );
                        }
                      }}
                    />
                    <EvaluationCallStep
                      isSourcedBuilder={isSourcedBuilder}
                      onboardingStage={onboardingStage}
                    />
                    <BecomeFullMember />

                    <CompleteProfileStep
                      isSourcedBuilder={isSourcedBuilder}
                      isProfileCompleted={!!profileCompletion}
                      onboardingStage={onboardingStage}
                      hideCta={
                        onboardingStage !==
                          OnboardingStage.InterviewScheduled &&
                        onboardingStage !==
                          OnboardingStage.InterviewScheduledWithIncompleteProfile &&
                        onboardingStage !==
                          OnboardingStage.CompletedWithIncompleteProfile
                      }
                      hideVideo={
                        onboardingStage !==
                        OnboardingStage.CompletedWithIncompleteProfile
                      }
                      hideBorder={
                        onboardingStage !==
                        OnboardingStage.CompletedWithIncompleteProfile
                      }
                    />
                    <JoinTheTeamStep />
                  </>
                ) : isPrescrubbed ? (
                  <>
                    <CompleteProfileStep onboardingStage={onboardingStage} />
                    <EvaluationCallStep
                      isPreScrubbed={true}
                      onboardingStage={onboardingStage}
                    />
                    <JoinTheTeamStep />
                  </>
                ) : (
                  <>
                    <CompleteProfileStep onboardingStage={onboardingStage} />
                    <ReviewProfileStep onboardingStage={onboardingStage} />
                    <ReviseProfileStep onboardingStage={onboardingStage} />
                    <EvaluationCallStep onboardingStage={onboardingStage} />
                    <JoinTheTeamStep />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default observer(Setup);
