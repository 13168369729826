import { CDN_BASE_URL } from '@src/config';
import React from 'react';
import { createUseStyles } from 'react-jss';
import bookOpenPurpleIcon from './book-open-purple.svg';
import { Breakpoints } from '@ateams/components';
import cx from 'classnames';

interface GrowYourNetworkBannerProps {
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    background:
      'linear-gradient(180deg, #F8F2FF 0%, rgba(248, 242, 255, 0.00) 100%)',
    borderRadius: 16,
    // all a tags not only direct children
    '& a': {
      color: '#6D00D7 !important',
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      fontWeight: 500,
    },
  },
  title: {
    margin: 0,
    fontSize: 24,
    fontWeight: 500,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    gap: 8,
  },
  desc: {
    maxWidth: 395,
    margin: 0,
    lineHeight: '24px',
  },
  secondIllustration: {
    display: 'none',
  },
  firstIllustration: {
    marginBottom: 40,
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      width: 145,
      height: 'auto',
    },
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    container: {
      flexDirection: 'row',
      padding: '20px',
      gap: 40,
    },
    imageContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    firstIllustration: {
      marginBottom: 0,
    },
    secondIllustration: {
      display: 'block',
    },
  },
});

const GrowYourNetworkBanner = ({
  className,
}: GrowYourNetworkBannerProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.imageContainer}>
        <img
          className={styles.firstIllustration}
          src={
            CDN_BASE_URL + '/website/discovery-grow-network-illustration-1.png'
          }
          alt="Grow your network"
        />
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>
          Connect with builders you want to work with
        </h3>
        <p className={styles.desc}>
          Builders who like working together perform better and are more likely
          to be selected for missions.
        </p>
        <a
          href="https://guide.a.team/account/connections-and-discovery"
          target="_blank"
          rel="noreferrer"
        >
          <img src={bookOpenPurpleIcon} alt="Discover" />
          Learn why connections are important
        </a>
      </div>
      <div className={styles.imageContainer}>
        <img
          className={styles.secondIllustration}
          src={
            CDN_BASE_URL + '/website/discovery-grow-network-illustration-2.png'
          }
          alt="Grow your network"
        />
      </div>
    </div>
  );
};

export default GrowYourNetworkBanner;
