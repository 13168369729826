import { GlobalPaymentCycle, GlobalPaymentCycleId } from '@ateams/api';
import CycleCards, {
  CycleCard,
  CycleCardsState,
} from '@src/components/CycleCards';
import { numberWithCommas } from '@src/helpers/numbers';
import { stringifyDateRange } from '@src/helpers/time';
import React from 'react';
import { ReactElement } from 'react';

interface Props {
  items: GlobalPaymentCycle[];
  currentId?: GlobalPaymentCycleId;
  state?: CycleCardsState;
  setCurrent(id: GlobalPaymentCycleId, state: CycleCardsState): void;
}

export type { CycleCardsState };

export default function GlobalPaymentCycleNav(props: Props): ReactElement {
  return (
    <CycleCards state={props.state}>
      {props.items.map((item) => (
        <CycleCard
          key={item.id}
          success={item.summary.paidAmount >= item.summary.totalAmount}
          selected={props.currentId === item.id}
          progress={(100 * item.summary.paidAmount) / item.summary.totalAmount}
          label={`${stringifyDateRange(item.startDate, item.endDate)} (${
            item.summary.roles
          } builders)`}
          onClick={(state) => props.setCurrent(item.id, state)}
        >
          ${numberWithCommas(item.summary.totalAmount)}
        </CycleCard>
      ))}
    </CycleCards>
  );
}
