import React from 'react';
import { createUseStyles } from 'react-jss';
import coinIcon from './coin.svg';
import { Button } from '@a_team/ui-components';
import { Breakpoints } from '@ateams/components';

interface GetPaidBannerProps {
  onPaymentNotificationClick: () => void;
}

const useStyles = createUseStyles({
  container: {
    padding: 24,
    background: '#26014C',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    borderRadius: 8,
    marginBottom: 24,
    '& h3': {
      fontSize: 24,
      lineHeight: '30px',
      fontWeight: 500,
      margin: 0,
    },
    '& p': {
      margin: 0,
    },
  },
  coinIcon: {
    width: 80,
    height: 80,
  },
  content: {
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
    gap: 24,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    container: {
      flexDirection: 'row',
    },
    content: {
      marginBottom: 0,
    },
  },
});

const GetPaidBanner = ({
  onPaymentNotificationClick,
}: GetPaidBannerProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>
          <img src={coinIcon} alt="coin" className={styles.coinIcon} />
        </div>
        <div>
          <h3>Let's get you paid</h3>
          <p>
            Review your timesheet. Once everything looks good, hit the submit
            button.
          </p>
        </div>
      </div>
      <div className={styles.btnContainer}>
        <Button
          onClick={onPaymentNotificationClick}
          data-testing-id="timesheets-submit-button"
        >
          Submit timesheet
        </Button>
      </div>
    </div>
  );
};

export default GetPaidBanner;
