import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { FontSizes } from '../theme/styles';
import Icon, { IconType } from '../Icon';

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    display: 'inline-block',
    '& i': {
      cursor: 'pointer',
      verticalAlign: 'text-top',
    },
    '&:hover': {
      '& $tooltipText': {
        visibility: 'visible',
      },
    },
  },
  tooltipText: {
    visibility: 'hidden',
    width: 200,
    backgroundColor: 'black',
    color: '#fff',
    textAlign: 'center',
    padding: 10,
    borderRadius: '6px',
    position: 'absolute',
    zIndex: 900,
    bottom: 'calc(100% + 10px)',
    left: '50%',
    marginLeft: -100,
    fontSize: FontSizes.xsmall,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '100%' /* At the bottom of the tooltip */,
      left: '50%',
      marginLeft: -5,
      borderWidth: 5,
      borderStyle: 'solid',
      borderColor: 'black transparent transparent transparent',
    },
  },
});

interface HelpTooltipProps {
  className?: string;
  children: ReactNode;
}

export const HelpTooltip = (props: HelpTooltipProps) => {
  const { children, className } = props;

  const styles = useStyles(props);

  return (
    <div className={styles.root}>
      <Icon title="Help" size="smaller" type={IconType.Info} />
      <span className={cx(styles.tooltipText, className)}>{children}</span>
    </div>
  );
};
