import React, { ReactElement, InputHTMLAttributes, useMemo } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import check from './check.svg';
import checkDisabled from './check-disabled.svg';
import checkIndeterminate from './check-indeterminate.svg';
import checkIndeterminateDisabled from './check-indeterminate-disabled.svg';
import { uniqueId } from 'lodash';

const BG_CHECKED = `url(${check}) center 5px no-repeat #6D00D7`;
const BG_CHECKED_INDETERMINATE = `url(${checkIndeterminate}) center 4px no-repeat #6D00D7`;
const BG_CHECKED_INDETERMINATE_DISABLED = `url(${checkIndeterminateDisabled}) center 3px no-repeat`;
const BG_CHECKED_DISABLED = `url(${checkDisabled}) center 3px no-repeat`;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  customLabel?: string;
  indeterminate?: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    width: '18px',
    height: '18px',
    flexShrink: 0,
    display: 'inline-block',
    position: 'relative',
    cursor: (props: Props): string =>
      props.disabled ? 'not-allowed' : 'pointer',
    userSelect: 'none',
    background: (props: Props): string =>
      props.disabled
        ? props.indeterminate
          ? BG_CHECKED_INDETERMINATE_DISABLED
          : BG_CHECKED_DISABLED
        : props.indeterminate
        ? BG_CHECKED_INDETERMINATE
        : props.checked
        ? BG_CHECKED
        : 'none',
    border: (props: Props): string =>
      (props.checked || props.indeterminate) && !props.disabled
        ? 'none'
        : `2px solid ${props.disabled ? 'rgba(34,34,34,0.12)' : '#c0c0c0'}`,
    borderRadius: '5px',
    overflow: 'hidden',

    '& > input': {
      display: 'block',
      position: 'absolute',
      opacity: 0,
      cursor: 'inherit',
      height: '100%',
      width: '100%',
      left: -2,
      top: -2,
    },
  },
  label: {
    marginLeft: '8px',
  },
});

export default function Checkbox(props: Props): ReactElement {
  const {
    type,
    checked,
    disabled,
    style,
    className,
    customLabel,
    indeterminate,
    ...left
  } = props;
  const styles = useStyles(props);
  const id = useMemo(() => props.id || uniqueId(`checkbox-`), [props.id]);
  return (
    <div className={styles.container}>
      <div className={cx(styles.root, className)} style={style}>
        <input
          id={id}
          type={type || 'checkbox'}
          checked={checked}
          disabled={disabled}
          {...left}
        />
      </div>
      {customLabel && (
        <label className={styles.label} htmlFor={id}>
          {customLabel}
        </label>
      )}
    </div>
  );
}
