import React, { ReactElement, useEffect, useState } from 'react';
import { AsyncSelect, SelectOption } from '@ateams/components';
import { getIndustrySelectOptions } from '@src/helpers/talent-industry-api';

interface Props {
  defaultValues?: SelectOption[];
  onChange(talentIndustryIds: string[]): void;
}

export const SelectIndustry: (props: Props) => ReactElement = ({
  onChange,
  defaultValues,
}) => {
  const [value, setValue] = useState<SelectOption | SelectOption[] | null>([]);

  useEffect(() => {
    if (defaultValues) {
      setValue(defaultValues);
    }
  }, []);

  const searchIndustries = async (searchTerm: string) => {
    return getIndustrySelectOptions({
      filter: {
        query: searchTerm,
      },
    });
  };

  const onIndustriesSelected = (
    values: SelectOption | SelectOption[] | null,
  ) => {
    setValue(values);
    const ids =
      values !== null
        ? Array.isArray(values)
          ? values?.map((option: SelectOption) => option.value)
          : [values.value]
        : [];

    onChange(ids);
  };

  return (
    <AsyncSelect
      value={value}
      loadOptions={searchIndustries}
      onChange={onIndustriesSelected}
      isMulti={true}
      placeholder={'Select industry...'}
      margin={'none'}
    />
  );
};
