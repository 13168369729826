import { match } from 'react-router';
import { Stores } from '../stores';
import { Location } from 'history';

// exported functions

export async function loadAdminTeamWorkCreatedMissions(
  stores: Stores,
): Promise<unknown> {
  const { missions } = stores;
  if (missions.adminViewMissions.createdMissions) return;
  missions.setAdminTokens('createdMissions', null);
  return missions.getAdminCreatedMissions();
}

export async function loadAdminTeamWorkPublishedMissions(
  stores: Stores,
): Promise<unknown> {
  const { missions } = stores;
  if (missions.adminViewMissions.publishedMissions) return;
  missions.setAdminTokens('publishedMissions', null);
  return missions.getAdminPublishedMissions();
}

export async function loadAdminTeamWorkPendingMissions(
  stores: Stores,
): Promise<unknown> {
  const { missions } = stores;
  if (missions.adminViewMissions.pendingMissions) return;
  missions.setAdminTokens('pendingMissions', null);
  return missions.getAdminPendingMissions();
}

export async function loadAdminTeamWorkRunningMissions(
  stores: Stores,
): Promise<unknown> {
  const { missions } = stores;
  if (missions.adminViewMissions.runningMissions) return;
  missions.setAdminTokens('runningMissions', null);
  return missions.getAdminRunningMissions();
}

export async function loadAdminTeamWorkEndedMissions(
  stores: Stores,
): Promise<unknown> {
  const { missions } = stores;
  if (missions.adminViewMissions.endedMissions) return;
  missions.setAdminTokens('endedMissions', null);
  return missions.getAdminEndedMissions();
}

export async function loadAdminTeamWorkScheduledToEndMissions(
  stores: Stores,
): Promise<unknown> {
  const { missions } = stores;
  if (missions.adminViewMissions.scheduledToEndMissions) return;
  missions.setAdminTokens('scheduledToEndMissions', null);
  return missions.getAdminScheduledToEndMissions();
}

export async function loadAdminTeamWorkArchivedMissions(
  stores: Stores,
): Promise<unknown> {
  const { missions } = stores;
  if (missions.adminViewMissions.archivedMissions) return;
  missions.setAdminTokens('archivedMissions', null);
  return missions.getAdminArchivedMissions();
}

export async function loadAdminTeamWorkSearchResults(
  stores: Stores,
  _: match,
  location: Location,
): Promise<unknown> {
  const { missions, auth } = stores;

  const params = new URLSearchParams(location.search);
  const searchQuery = params.get('query') || undefined;
  const extendedSearch = params.get('extendedSearch');

  return missions.getAdminViewMissions(
    auth,
    searchQuery,
    extendedSearch === 'true',
  );
}
