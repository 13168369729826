import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import AdminInvoicesDashboardLayout from '@src/layouts/InvoicesDashboard';
import { useStores } from '@src/stores';
import { ServerSideLoader, ViewProps } from '@src/routes';
import AccountsSelector from '@src/components/AccountsSelector';
import { MinimalAccountObject } from '@a_team/models/dist/Account';
import TitledSection from '@src/components/TitledSection';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import {
  DiscountApplicableFor,
  DiscountType,
} from '@a_team/models/dist/Discount';
import { Button, ButtonContainer } from '@ateams/components';
import { CreateDiscountData } from '@ateams/api/dist/endpoints/Discounts';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { AdminInvoicesDiscountsLocation } from '@src/locations';
import AccountMissionsSelector from '@src/components/AccountMissionsSelector';
import { MissionId } from '@a_team/models/dist/MissionObject';

export const adminInvoicesDiscountsNewLoader: ServerSideLoader = async (
  stores,
  match,
  location,
) => {
  // do nothing
};

const AdminInvoicesDiscountsNewView = (props: ViewProps) => {
  const { discounts } = useStores();

  const [type, setType] = useState<'credit' | 'fixed' | 'percentage'>('credit');
  const [creditAmount, setCreditAmount] = useState('2500');
  const [amount, setAmount] = useState('500');
  const [percentage, setPercentage] = useState('10');
  const [months, setMonths] = useState('3');
  const [account, setAccount] = useState<MinimalAccountObject | undefined>();
  const [allowedMissions, setAllowedMissions] = useState<MissionId[]>([]);
  const [applicableFor, setApplicableFor] = useState<DiscountApplicableFor>(
    DiscountApplicableFor.FEES,
  );
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useLoadingState();

  const data = useMemo((): CreateDiscountData | null => {
    if (!account || !notes.trim()) {
      return null;
    }

    let dType: CreateDiscountData['type'];
    let dTotalCredit: CreateDiscountData['totalCredit'];
    let dPercentage: CreateDiscountData['percentage'];
    let dFixedAmount: CreateDiscountData['fixedAmount'];

    switch (type) {
      case 'credit': {
        dType = DiscountType.PERCENTAGE;
        dTotalCredit = Number(creditAmount);
        dPercentage = 1;
        break;
      }

      case 'fixed': {
        dType = DiscountType.FIXED_AMOUNT;
        dTotalCredit = Number(amount);
        dFixedAmount = Number(amount);

        if (!(dTotalCredit > 0)) {
          return null;
        }
        break;
      }

      case 'percentage': {
        dType = DiscountType.PERCENTAGE;
        dTotalCredit = Number(creditAmount);
        dPercentage = Number(percentage) / 100;

        if (!(dPercentage > 0 && dPercentage < 1)) {
          return null;
        }
        break;
      }
    }

    if (!(dTotalCredit > 0)) {
      return null;
    }

    return {
      account: account.id,
      allowedMissions: allowedMissions.length ? allowedMissions : undefined,
      type: dType,
      totalCredit: dTotalCredit,
      applicableFor,
      percentage: dPercentage,
      fixedAmount: dFixedAmount,
      notes: notes.trim(),
    };
  }, [
    account,
    allowedMissions,
    type,
    creditAmount,
    amount,
    percentage,
    applicableFor,
    notes,
  ]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!data) {
      return;
    }

    setLoading(
      discounts.createDiscount(data).then(() => {
        props.history.push(AdminInvoicesDiscountsLocation);
      }),
    );
  };

  return (
    <AdminInvoicesDashboardLayout title="Create Discount">
      <form onSubmit={handleSubmit} style={{ maxWidth: 600 }}>
        <h1>Create Discount</h1>

        <TitledSection
          title="Account"
          subtitle="Select the account to apply the discount to:"
        >
          <AccountsSelector selected={account} onSelect={setAccount} />
        </TitledSection>

        <TitledSection
          title="Allowed missions"
          subtitle="Choose the specific missions you want to allow for this discount, if none is selected, all account's missions will be allowed:"
        >
          <AccountMissionsSelector
            account={account}
            selected={allowedMissions}
            onSelect={setAllowedMissions}
          />
          <p style={{ fontSize: 12, color: '#999' }}>
            Press and hold <strong>Ctrl</strong> to select multiple missions.
          </p>
        </TitledSection>

        <TitledSection
          title="Discount type"
          subtitle="Select the type of discount you want to create:"
        >
          <RadioOption
            name="discount-type"
            label="Credit balance"
            selected={type === 'credit'}
            onSelect={() => setType('credit')}
          />

          <RadioOption
            name="discount-type"
            label="Fixed amount"
            selected={type === 'fixed'}
            onSelect={() => setType('fixed')}
          />

          <RadioOption
            name="discount-type"
            label="Percentage"
            selected={type === 'percentage'}
            onSelect={() => setType('percentage')}
          />
        </TitledSection>

        {type === 'credit' && (
          <TitledSection
            title="Credit amount"
            subtitle="Select the amount of discount you want to apply:"
          >
            <OutlinedInput
              width="fixed"
              type="number"
              label="Amount"
              precursor="$"
              value={creditAmount}
              onChange={(e) => setCreditAmount(e.target.value)}
              error={!(Number(creditAmount) > 0)}
            />
          </TitledSection>
        )}

        {type === 'percentage' && (
          <TitledSection
            title="Percentage"
            subtitle="Select the percentage of discount you want to apply:"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <OutlinedInput
                width="fixed"
                type="number"
                label="Percentage"
                precursor="%"
                value={percentage}
                onChange={(e) => setPercentage(e.target.value)}
                error={!(Number(percentage) > 0 && Number(percentage) < 100)}
              />

              <OutlinedInput
                width="fixed"
                type="number"
                label="Up to"
                precursor="$"
                value={creditAmount}
                onChange={(e) => setCreditAmount(e.target.value)}
                error={!(Number(creditAmount) > 0)}
                style={{ marginLeft: 32 }}
              />
            </div>
          </TitledSection>
        )}

        {type === 'fixed' && (
          <TitledSection
            title="Fixed amount"
            subtitle="Select the amount of discount you want to apply:"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <OutlinedInput
                width="fixed"
                type="number"
                label="Discount amount"
                precursor="$"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                error={!(Number(amount) > 0)}
              />
              <span style={{ margin: '1em 1em 0', fontSize: '2em' }}>
                &times;
              </span>
              <OutlinedInput
                width="fixed"
                type="number"
                label="Number of invoices"
                value={months}
                onChange={(e) => setMonths(e.target.value)}
                error={!(Number(months) > 0)}
              />
            </div>
          </TitledSection>
        )}

        <TitledSection
          title="Applicable for"
          subtitle="Select on which part of the invoice the discount will be applied:"
        >
          <RadioOption
            name="applicableFor"
            label="A.Team fees"
            selected={applicableFor === DiscountApplicableFor.FEES}
            onSelect={() => setApplicableFor(DiscountApplicableFor.FEES)}
          />

          <RadioOption
            name="applicableFor"
            label="Total amount"
            selected={applicableFor === DiscountApplicableFor.ALL}
            onSelect={() => setApplicableFor(DiscountApplicableFor.ALL)}
          />
        </TitledSection>

        <TitledSection
          title="Notes"
          subtitle="Add any notes you want to attach to this discount:"
        >
          <OutlinedInput
            type="text"
            label="Notes"
            placeholder="Provide more information about this discount"
            required
            value={notes}
            error={!!notes && !notes.trim()}
            onChange={(e) => setNotes(e.target.value)}
          />
        </TitledSection>

        <ButtonContainer>
          <Button type="submit" disabled={!data} loading={loading === true}>
            Create
          </Button>
          <LoadingIndicator loading={loading} />
        </ButtonContainer>
      </form>
    </AdminInvoicesDashboardLayout>
  );
};

export default observer(AdminInvoicesDiscountsNewView);

const RadioOption = ({
  name,
  label,
  selected,
  onSelect,
}: {
  name: string;
  label: string;
  selected: boolean;
  onSelect(): void;
}) => (
  <p>
    <label>
      <input
        type="radio"
        name={name}
        value={label}
        checked={selected}
        onChange={onSelect}
      />{' '}
      {label}
    </label>
  </p>
);
