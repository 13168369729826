import { Icon } from '@a_team/ui-components';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import React, { FC, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { BuilderTfsPitchObject } from './BlurbsList';
import cx from 'classnames';

interface BlurbSummaryProps {
  showRate?: boolean;
  tfsPitch: BuilderTfsPitchObject;
}

const useStyles = createUseStyles({
  item: {
    overflow: 'hidden',
    marginBottom: 3,
    '& svg': {
      color: 'inherit',
      verticalAlign: 'middle',
    },
    '& > span': { marginRight: 2 },
    whiteSpace: 'nowrap',
  },
  title: {
    maxWidth: '90%',
    textOverflow: 'ellipsis',
  },
});

const BlurbSummary: FC<BlurbSummaryProps> = ({ tfsPitch, showRate }) => {
  const styles = useStyles();
  const date = useMemo(() => {
    return format(new Date(tfsPitch.createdAt), 'PPPp');
  }, [tfsPitch.createdAt]);

  return (
    <>
      <div className={cx(styles.item, styles.title)}>
        <Icon color="inherit" size="md" name="list" />{' '}
        <strong className={styles.title}>
          {tfsPitch.clientName} - {tfsPitch.projectName}
        </strong>
      </div>
      <div className={styles.item}>
        <Icon color="inherit" size="md" name="user" /> {tfsPitch.role.title}
      </div>
      {tfsPitch.hourlyRate && showRate && (
        <div className={styles.item}>
          <Icon color="inherit" size="md" name="dollar" /> $
          {tfsPitch.hourlyRate}
        </div>
      )}
      <div className={styles.item}>
        <Icon color="inherit" size="md" name="calendarFull" /> {date}
      </div>
    </>
  );
};

export default observer(BlurbSummary);
