import { teamEngineSearchServiceApi } from '@src/logic/services/endpoints';
import { v1ProvinceListFilterApi } from '@a_team/team-engine-search-service-sdk-js/dist/schema';
import { getAuthToken } from '@src/helpers/auth';
import { queryKeys } from '@src/rq/keys';
import { useQuery } from '@tanstack/react-query';
import { SelectOption } from '@ateams/components';
import { countryListOptionMap } from '@src/helpers/rawCountryList';

export const useQueryUserProvincesByCountry = (params?: {
  filter?: v1ProvinceListFilterApi;
  applicationName?: string;
}) => {
  const { filter, applicationName } = params ?? {};
  const countries = filter?.countryCode?.filter((c): c is string => !!c) ?? [];

  return useQuery(
    queryKeys.provinces.byCountries(countries),
    async (): Promise<ReturnType<typeof teamEngineSearchServiceApi.query>> => {
      return teamEngineSearchServiceApi.query(
        {
          v1ListProvinces: [
            { applicationName, filter },
            {
              provinces: {
                province: 1,
                country: 1,
              },
            },
          ],
        },
        { headers: { Authorization: getAuthToken() } },
      );
    },
    {
      select: ({ data }) => {
        return (
          data?.v1ListProvinces?.provinces
            ?.sort()
            .filter(
              (entry: { province: string; country: string }) => entry.province,
            )
            .map(
              (entry: { province: string; country: string }) =>
                ({
                  value: entry.province,
                  label: `${entry.province} (${
                    countryListOptionMap[entry.country].label
                  })`,
                } as SelectOption),
            ) ?? []
        );
      },
    },
  );
};
