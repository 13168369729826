import { apiRoleCategories } from '@ateams/api';
import RoleCategoryObject from '@a_team/models/dist/RoleCategory';
import AuthStore from '@src/stores/Auth';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './keys';

const fetchRoleCategories =
  (auth: AuthStore) => async (): Promise<RoleCategoryObject[]> => {
    return apiRoleCategories.getAllCategories(auth);
  };

export const useRoleCategories = (auth: AuthStore) => {
  return useQuery(queryKeys.roleCategories.list(), fetchRoleCategories(auth));
};
