import { apiDiscovery } from '@ateams/api/dist/endpoints';
import { useStores } from '@src/stores';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './keys';

export const useSearchUsers = ({
  query,
  enabled,
  onSuccess,
}: {
  query: string;
  enabled?: boolean;
  onSuccess?: (data: unknown) => void;
}) => {
  const stores = useStores();
  return useQuery({
    queryKey: queryKeys.searchKeys.searchUsers(query),
    queryFn: () => {
      return apiDiscovery.searchUsers(stores.auth, query);
    },
    enabled: enabled && stores.auth.basicAccess,
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};
