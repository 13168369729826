import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Colors } from '@ateams/components';
import React from 'react';

interface GradientBoxProps {
  children: React.ReactNode;
  className?: string;
  boxBgColor?: string;
}
const useGradientBoxStyles = createUseStyles<{
  boxBgColor?: string;
}>({
  text: {
    fontWeight: 500,
    fontSize: 12,
    padding: '4px 8px',
  },
  gradientBox: {
    padding: 1,
    display: 'inline-block',
    backgroundImage: 'linear-gradient(270deg, #8F1BFF 0.56%, #FE4AC2 100.56%)',
    borderRadius: 8,
  },
  insideBox: {
    background: ({ boxBgColor }) => boxBgColor || Colors.backgroundWhite,
    display: 'block',
    borderRadius: 7,
    padding: '2.5px 8px',
  },
});

const GradientBox = ({ children, className, boxBgColor }: GradientBoxProps) => {
  const styles = useGradientBoxStyles({
    boxBgColor,
  });
  return (
    <div className={cx(styles.gradientBox, styles.text, className)}>
      <span className={styles.insideBox}>{children}</span>
    </div>
  );
};

export default GradientBox;
