import { OutlinedInputProps } from '@src/components/Inputs/OutlinedInput';
import TextInput from '@src/components/Inputs/TextInput/TextInput';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';

interface Props extends OutlinedInputProps {
  tooltip?: string;
}

const useStyles = createUseStyles({
  input: {
    width: '46px',
    backgroundColor: 'transparent',
    margin: '0 5px',
    lineHeight: '1.4em',
    textAlign: 'center',
  },
});

export const HoursInput = (props: Props): ReactElement => {
  const styles = useStyles();

  return (
    <TextInput
      variant="dashed"
      placeholder={'XX'}
      data-testing-id={'profile-availability-hours-text-input'}
      className={styles.input}
      {...props}
    />
  );
};
