import React from 'react';
import { createUseStyles } from 'react-jss';
import ControlButtons from './common/ControlButtons';
import { TeamPulseSectionsEnum } from './TeamPulseModal';
import UserSearchInput from './common/UserSearchInput';
import { UserCardObject } from '@a_team/models/src/UserObject';
import UserCard from './common/UserCard';
import { Breakpoints, Colors } from '@ateams/components';

interface AnyBuildersInMindProps {
  setSection: (section: string) => void;
  setUserReviewSection: (section: string) => void;
  setBuildersRecommendedForMission: (
    value: UserCardObject[] | undefined,
  ) => void;
  missionTitle: string;
  needsToReviewUsers?: boolean;
  initialState?: UserCardObject[];
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'start',
  },
  desc: {
    textAlign: 'start',
    color: '#707275',
    marginBottom: 16,
    marginTop: 8,
  },
  selectedBuildersContainer: {
    width: '100%',
  },
  selectedBuildersWrapper: {
    width: '100%',
    border: `1px solid ${Colors.backgroundDark}`,
  },
  selectedBuildersTitle: {
    fontSize: '15px',
    color: '#222222',
    fontWeight: 500,
    marginBottom: 12,
  },
  suggestedBuildersContainer: {
    width: '100%',
  },
  wrapper: {
    padding: 20,
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    wrapper: {
      padding: 0,
    },
  },
});

const AnyBuildersInMind = ({
  setSection,
  setUserReviewSection,
  setBuildersRecommendedForMission,
  missionTitle,
  needsToReviewUsers,
  initialState,
}: AnyBuildersInMindProps): JSX.Element => {
  const styles = useStyles();
  const [selectedUsers, setSelectedUsers] = React.useState<UserCardObject[]>(
    initialState || [],
  );

  const onBack = (): void => {
    setSection(TeamPulseSectionsEnum.HowBuildersWouldHelpMission);
  };

  const onContinue = (): void => {
    setBuildersRecommendedForMission(selectedUsers);
    setSection(TeamPulseSectionsEnum.WhyTheseBuildersForm);
  };

  const onSkip = (): void => {
    setBuildersRecommendedForMission(undefined);
    if (needsToReviewUsers) {
      setUserReviewSection(TeamPulseSectionsEnum.TeamMemberRating);
    } else {
      setSection(TeamPulseSectionsEnum.AllDone);
    }
  };

  const onUserSelect = (user: UserCardObject): void => {
    if (selectedUsers.find((u) => u.uid === user.uid)) {
      return;
    }
    setSelectedUsers(selectedUsers ? [...selectedUsers, user] : [user]);
  };

  const onRemoveUser = (uid: string): void => {
    setSelectedUsers(selectedUsers.filter((u) => u.uid !== uid));
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>Any builders in mind for this mission?</h4>
        <p className={styles.desc}>
          This question is optional and is not shared with {missionTitle}.
        </p>
        {/* <ReferBadge /> */}

        <div className={styles.suggestedBuildersContainer}>
          <label>
            <strong>Suggest Builders</strong>
          </label>
          <UserSearchInput
            selectedUsers={selectedUsers}
            onUserSelect={onUserSelect}
            onRemoveUser={onRemoveUser}
          />
        </div>

        {selectedUsers.length > 0 && (
          <div className={styles.selectedBuildersContainer}>
            <div className={styles.selectedBuildersTitle}>
              Selected Builders
            </div>
            <div className={styles.selectedBuildersWrapper}>
              {selectedUsers.map((user, index) => (
                <UserCard
                  key={user.uid}
                  onRemove={onRemoveUser}
                  user={user}
                  style={
                    index !== selectedUsers.length - 1
                      ? {
                          borderBottom: '1px solid #EFEFF0',
                        }
                      : {}
                  }
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <ControlButtons
        canContinue={selectedUsers.length > 0}
        onBack={onBack}
        onContinue={onContinue}
        onSkip={onSkip}
      />
    </div>
  );
};

export default AnyBuildersInMind;
