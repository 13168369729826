import {
  Breakpoints,
  Button,
  Colors,
  Icon,
  IconType,
  TextColors,
} from '@ateams/components';
import ConnectModalV2 from '@src/components/Modal/ConnectModalV2';
import useToggle from '@src/hooks/useToggle';
import { useStores } from '@src/stores';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  ConnectionsModalSources,
  useAnalytics,
} from '@ateams/analytics/dist/platform';
import { Link } from 'react-router-dom';
import UserObject, { UserCardObject } from '@a_team/models/dist/UserObject';
import { getUpdatedConnectionsForUserToInteract } from '@src/helpers/connections';
import { ConnectionObjectV2 } from '@a_team/models/dist/ConnectionObject';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    gap: '16px',
  },
  button: {
    display: 'flex',
    fontSize: '14px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '9px',
    border: 'none',
    padding: '8px 0',
    borderRadius: '4px',
    width: '100%',
    height: '40px',
    cursor: 'pointer',
    transition: 'background 0.3s ease',
    color: TextColors.regular,
  },
  seeProfileLinkBtn: {
    background: '#EFEFF0',
    '&:hover': {
      background: '#DADADC',
    },
  },
  editConnectionButton: {
    '&:hover': {
      background: '#DADADC',
    },
  },
  connectButton: {
    background: Colors.secondaryDark,
    color: TextColors.primary,
    '&:hover': {
      opacity: 0.8,
    },
  },
  icon: {
    height: '14px',
    width: '14px',
  },
  connectIcon: {
    height: '18px',
    width: '18px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      marginTop: '0',
      marginLeft: '0',
      marginRight: '0',
    },
  },
});

interface SeeProfileAndConnectProps {
  suggestedUser: UserCardObject;
}

const SeeProfileAndConnect = ({
  suggestedUser,
}: SeeProfileAndConnectProps): JSX.Element => {
  const [userToInteract, setUserToInteract] = useState<UserObject>();

  const [connectModal, toggleConnectModal] = useToggle();
  const styles = useStyles();
  const { users, auth } = useStores();
  const analytics = useAnalytics();

  const onToggleConnectModal = (): void => {
    users.getFullUser(suggestedUser.username, auth).then((fullUser) => {
      setUserToInteract(fullUser);
      toggleConnectModal();
      auth.user?.uid &&
        analytics.trackConnectionModalOpened(
          auth.user?.uid,
          ConnectionsModalSources.DiscoveryBuilders,
        );
    });
  };

  const hasConnections = useMemo(() => {
    const userConnections = suggestedUser.connections || [];
    const userToInteractConnections = userToInteract?.connections || [];

    return userConnections.length > 0 || userToInteractConnections.length > 0;
  }, [suggestedUser.connections, userToInteract?.connections]);

  const handleConnectActionOnCompleted = (
    newConnection: ConnectionObjectV2,
  ) => {
    setUserToInteract((prevUserToInteract) => {
      if (!prevUserToInteract) {
        return prevUserToInteract;
      }
      return getUpdatedConnectionsForUserToInteract(
        prevUserToInteract,
        newConnection,
      );
    });
  };

  return (
    <>
      <div className={styles.container}>
        <Link
          className={cx(styles.button, styles.seeProfileLinkBtn)}
          to={`/${suggestedUser.username}`}
          color="regularLight"
        >
          See Profile
        </Link>

        {hasConnections ? (
          <Button
            data-testing-id="edit-connection-button-in-profile"
            className={cx(styles.button, styles.editConnectionButton)}
            onClick={onToggleConnectModal}
            color="regularLight"
          >
            <Icon className={styles.icon} type={IconType.Edit} />
            Edit
          </Button>
        ) : (
          <Button
            data-testing-id="connect-button-in-profile"
            className={cx(styles.button, styles.connectButton)}
            onClick={onToggleConnectModal}
          >
            <Icon
              className={cx(styles.icon, styles.connectIcon)}
              type={IconType.Connect}
            />
            Connect
          </Button>
        )}
      </div>

      {auth.user && userToInteract && (
        <ConnectModalV2
          source={ConnectionsModalSources.DiscoveryBuilders}
          open={connectModal}
          onClose={toggleConnectModal}
          currentUser={auth.user}
          userToConnect={userToInteract}
          connectActionOnCompleted={handleConnectActionOnCompleted}
        />
      )}
    </>
  );
};

export default observer(SeeProfileAndConnect);
