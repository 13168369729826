import React from 'react';
import { Button, ButtonProps, Icon, IconType } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { useScreenClass } from 'react-grid-system';

const useStyles = createUseStyles({
  shareContainer: {
    display: 'flex',
    marginTop: '16px',
    marginBottom: '16px',
    '& > button': {
      lineHeight: 0,
      whiteSpace: 'noWrap',
      overFlow: 'hidden',
      textOverflow: 'clip',
      width: '33%',
      fontSize: '14px',
      '&:not(:last-child)': {
        marginRight: '8px',
      },
    },
  },
});

const buttonConfig: Pick<ButtonProps, 'size' | 'color'> = {
  color: 'regularLight',
  size: 'small',
};

interface Props {
  shareLink: string;
}

const Share: React.FC<Props> = ({ shareLink }) => {
  const styles = useStyles();
  const screenClass = useScreenClass();

  const iconSize = React.useMemo(
    () => (screenClass !== 'xxl' ? 'small' : 'exact'),
    [screenClass],
  );

  const wideEnoughForLabel = React.useMemo(
    () => screenClass === 'xxl',
    [screenClass],
  );

  const openUrl = (url: string) => () =>
    typeof window !== 'undefined' && window.open(url, '_blank');

  return (
    <div className={styles.shareContainer}>
      <Button
        {...buttonConfig}
        onClick={openUrl(
          `https://twitter.com/intent/tweet?&url=${encodeURI(shareLink)}`,
        )}
      >
        <Icon type={IconType.Twitter} size={iconSize} />
        {wideEnoughForLabel ? ' Twitter' : ''}
      </Button>
      <Button
        {...buttonConfig}
        onClick={openUrl(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
            shareLink,
          )}`,
        )}
      >
        <Icon type={IconType.LinkedInDark} size={iconSize} />
        {wideEnoughForLabel ? ' Linkedin' : ''}
      </Button>
      <Button
        {...buttonConfig}
        onClick={openUrl(
          `https://www.facebook.com/sharer.php?u=${encodeURI(shareLink)}`,
        )}
      >
        <Icon type={IconType.Facebook} size={iconSize} />
        {wideEnoughForLabel ? ' Facebook' : ''}
      </Button>
    </div>
  );
};

export default Share;
