import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { DateISOString } from '@a_team/models/dist/misc';
import { getDaysInMonth } from 'date-fns';
import { getYears, monthNames } from '@src/components/DatePicker/consts';
import DropdownInput from '@src/components/Inputs/DropdownInput';

interface Props {
  date?: string | Date;
  disabled?: boolean;
  mode?: 'default' | 'month';
  className?: string;
  onChange?: (arg: {
    day: number;
    month: number;
    year: number;
    date: DateISOString;
  }) => void;
}

const userStyles = createUseStyles({
  container: {
    display: 'flex',
  },
  input: {
    marginRight: 16,
  },
});

const getGivenDate = (date: Props['date']): Date => {
  if (!date) {
    return new Date();
  }

  if (typeof date === 'object') {
    return date;
  }

  return new Date(date);
};

export const DatePicker = (props: Props): ReactElement | null => {
  const hideDay = props.mode === 'month';

  const styles = userStyles();
  const givenDate = getGivenDate(props.date);
  const [selectedMonth, setSelectedMonth] = useState(givenDate.getMonth()); // Month indexed from 0
  const daysInMonth = getDaysInMonth(
    new Date(givenDate.getFullYear(), selectedMonth),
  );
  const [selectedDay, setSelectedDay] = useState(
    hideDay ? 1 : givenDate.getDate(),
  );
  const [selectedYear, setSelectedYear] = useState(givenDate.getFullYear());

  useEffect(() => {
    props.onChange &&
      props.onChange({
        day: selectedDay,
        month: selectedMonth + 1,
        year: selectedYear,
        date: new Date(selectedYear, selectedMonth, selectedDay).toISOString(),
      });
  }, [selectedMonth, selectedDay, selectedYear]);

  const onDayChange = useCallback((e) => {
    setSelectedDay(parseInt(e.target.value));
  }, []);

  const onMonthChange = useCallback((e) => {
    setSelectedMonth(parseInt(e.target.value));
  }, []);

  const onYearChange = useCallback((e) => {
    setSelectedYear(parseInt(e.target.value));
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.input}>
        <DropdownInput
          value={selectedMonth}
          onChange={onMonthChange}
          disabled={props.disabled}
          margin="none"
          className={props.className}
        >
          {monthNames.map((monthName, i) => (
            <option value={i} key={i}>
              {monthName}
            </option>
          ))}
        </DropdownInput>
      </div>
      {!hideDay && (
        <div className={styles.input}>
          <DropdownInput
            value={selectedDay}
            onChange={onDayChange}
            disabled={props.disabled}
            margin="none"
            className={props.className}
          >
            {Array(daysInMonth)
              .fill('')
              .map((day, i) => {
                const dayDisplay = i + 1;
                return (
                  <option value={dayDisplay} key={i}>
                    {dayDisplay}
                  </option>
                );
              })}
          </DropdownInput>
        </div>
      )}
      <div>
        <DropdownInput
          value={selectedYear}
          onChange={onYearChange}
          disabled={props.disabled}
          margin="none"
          className={props.className}
        >
          {getYears().map((year) => (
            <option value={year} key={year}>
              {year}
            </option>
          ))}
        </DropdownInput>
      </div>
    </div>
  );
};

export default DatePicker;
