import {
  BasicClientInterviewObject,
  ClientInterviewDeclineReason,
  ClientInterviewId,
  ClientInterviewStatus,
} from '@a_team/models/dist/ClientInterviewObject';
import { ServiceAuth, ServiceEndpoint } from './utils';

export const BasePath = '/client-interviews';

export default class ClientInterviews extends ServiceEndpoint {
  public getClientInterviewById(
    auth: ServiceAuth,
    ciid: ClientInterviewId,
  ): Promise<BasicClientInterviewObject> {
    return this.fetch(auth, BasePath + `/${ciid}`);
  }

  public updateClientInterviewStatusById(
    auth: ServiceAuth,
    ciid: ClientInterviewId,
    status: ClientInterviewStatus,
    declineReason?: ClientInterviewDeclineReason,
    declineOtherReasonDetails?: string,
    builderHourlyRate?: number,
  ): Promise<BasicClientInterviewObject> {
    return this.fetch(
      auth,
      BasePath + `/${ciid}/update-status`,
      null,
      'PATCH',
      {
        status,
        declineReason,
        declineOtherReasonDetails,
        builderHourlyRate,
      },
    );
  }
}
