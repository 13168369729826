/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useCommonStyles } from '../commonStyles';
import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  SelectOption,
  Spacing,
} from '@ateams/components';
import { FieldDropdown } from '../general/FieldDropdown';
import { theme } from '@a_team/ui-components';
import {
  LocationAutocompleteV2,
  LocationSelectOption,
  OnLocationChange,
} from '@src/components/LocationAutocompleteV2';
import {
  formatLocation,
  parseLocation,
} from '@src/components/LocationAutocompleteV2/utils';
import { FieldTextarea } from '../general/FieldTextarea';
import { useFormContext } from 'react-hook-form';
import { FieldReadOnly } from '../general/FieldReadOnly';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
    backgroundColor: Colors.backgroundLight,
    borderRadius: BorderRadius.xLarge,
  },
  locationAndAvailabilityExternalContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
    border: `1px solid ${BorderColors.light}`,
    borderRadius: BorderRadius.xLarge,
    backgroundColor: Colors.backgroundWhite,
    padding: Spacing.medium,
  },
  locationAndAvailabilityContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.medium,
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
  },
  locationAutocomplete: {
    width: 400,
    margin: 0,
  },
  impressionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    padding: Spacing.medium,
    paddingTop: 0,
  },
});

export interface LocationAndAvailabilitySectionProps {
  availabilityOptions: SelectOption[];
  isViewMode: boolean;
}

export const LocationAndAvailabilitySection: React.FC<
  LocationAndAvailabilitySectionProps
> = ({ availabilityOptions, isViewMode }) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const {
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const location = watch('location');
  const availability = watch('availability');
  const locationImpressions = watch('locationImpressions');

  const onLocationChangeInternal: OnLocationChange = (options) => {
    if (!options) {
      setValue('location', null);
      return;
    }
    const option = Array.isArray(options) ? options[0] : options;
    const { prediction, countryShortName } = option;

    if (prediction?.structured_formatting) {
      const locationData = parseLocation(
        prediction.structured_formatting,
        countryShortName,
      );

      setValue('location', locationData);
    }
  };

  const onAvailabilityChange = (option: SelectOption) => {
    setValue('availability', option);
  };

  return (
    <div className={commonStyles.section}>
      <div className={commonStyles.sectionTitle}>Location & Availability</div>
      <div className={styles.container}>
        <div className={styles.locationAndAvailabilityExternalContainer}>
          <div className={styles.locationAndAvailabilityContainer}>
            <div className={styles.locationContainer}>
              <div className={commonStyles.label}>Other location</div>
              {!isViewMode ? (
                <LocationAutocompleteV2
                  openMenuOnClick={false}
                  defaultInputValue={
                    location ? formatLocation(location) : undefined
                  }
                  value={
                    location
                      ? {
                          value: formatLocation(location),
                          label: formatLocation(location),
                        }
                      : null
                  }
                  autocompleteOptions={{ types: ['(cities)'] }}
                  onChange={(option) =>
                    onLocationChangeInternal(
                      option as LocationSelectOption | null,
                    )
                  }
                  className={styles.locationAutocomplete}
                  color={location ? theme.colors.Grey[200] : undefined}
                  placeholder={!isViewMode ? 'Enter location' : ''}
                  overrideStyles={{
                    control: (provided: any) => ({
                      ...provided,
                      border: `1px solid ${BorderColors.light}`,
                      borderRadius: BorderRadius.default,
                      boxShadow: 'none !important',
                      '&:hover': {
                        border: `1px solid ${Colors.regularDark}`,
                      },
                    }),
                    valueContainer: (provided: any) => ({
                      ...provided,
                      height: 38,
                      fontSize: FontSizes.small,
                      fontWeight: FontWeights.regular,
                    }),
                  }}
                  disabled={isViewMode}
                  isClearable={!isViewMode}
                />
              ) : (
                <FieldReadOnly
                  value={location ? formatLocation(location) : ''}
                  showBorder={true}
                />
              )}
            </div>
            <FieldDropdown
              label="Does this builder work elsewhere?"
              options={availabilityOptions}
              value={availability}
              onChange={(value) => onAvailabilityChange(value as SelectOption)}
              placeholder="Select"
              disabled={isViewMode}
              isClearable={!isViewMode}
            />
          </div>
        </div>
        {(location || availability) && (
          <div className={styles.impressionsContainer}>
            <FieldTextarea
              label="Note about location & availability *"
              placeholder="Note any inconsistencies or relevant details about availability/location"
              value={locationImpressions}
              onChange={(value) => setValue('locationImpressions', value)}
              minLength={20}
              error={errors.locationImpressions?.message as string}
              name="locationImpressions"
              clearErrors={clearErrors}
              disabled={isViewMode}
            />
          </div>
        )}
      </div>
    </div>
  );
};
