import {
  MissionAdminObject,
  MissionManager,
  MissionManagerAccessMode,
} from '@a_team/models/dist/MissionObject';
import {
  MissionData,
  MissionRoleData,
} from '@ateams/api/dist/endpoints/Missions';
import { MissionAdminRole } from '@a_team/models/dist/MissionRole';
import { BasicUserObject, UserUsername } from '@a_team/models/dist/UserObject';
import { generateUniqueId } from '@src/helpers/strings';
import { getDefaultMonthlyRatesForRole } from '../utils';

export type FormData = Omit<MissionData, 'roles' | 'managers' | 'owner'> & {
  roles: MissionAdminRole[];
  managers: MissionManager[];
  owner?: BasicUserObject;
};

export type ManagerFormData = {
  accessMode: MissionManagerAccessMode;
  username: UserUsername;
  excludeFromInvoiceEmails: boolean;
  excludeFromTeamPulseEmails: boolean;
  excludeFromBuilderFeedbackEmails: boolean;
  excludeFromMissionUpdatesEmails: boolean;
};

// exported functions

export function getFormData(doc?: MissionAdminObject | null): FormData {
  if (!doc) {
    return {
      title: '',
      description: '',
      // attachedLinks: [],
      roles: [],
      managers: [],
      // hidden: false,
      // promotedTags: [],
      skipContracts: false,
    };
  }

  return {
    logoURL: doc.logoURL,
    videoURL: doc.videoURL,
    shortCompanyDescription: doc.shortCompanyDescription || undefined,
    companyStory: doc.companyStory,
    title: doc.title,
    description: doc.description,
    billingPeriod: doc.billingPeriod,
    automaticInvoicingPeriod: doc.automaticInvoicingPeriod || null,
    clientMargin: doc.clientMargin,
    rolesMargin: doc.rolesMargin,
    expectedDurationMonths: doc.expectedDurationMonths,
    attachedLinks: doc.attachedLinks,
    roles: doc.roles.map((role) => {
      return {
        ...role,
        ...getDefaultMonthlyRatesForRole(role),
        automatedStatusesDisabled: role.automatedStatusesDisabled || false,
        isFullTimeRetainer: role.isFullTimeRetainer || false,
        collectMonthlyRate: role.collectMonthlyRate || false,
        readyForReview: role.readyForReview || false,
        customQuestions:
          !role.customQuestions || role.customQuestions.length === 0
            ? [
                {
                  qid: generateUniqueId(),
                  text: '',
                  isRequired: false,
                  isVisible: false,
                },
              ]
            : role.customQuestions,
      };
    }),
    hidden: doc.hidden,
    status: doc.status,
    talentIndustryIds: doc.industries.map((industry) => industry.id),
    applyStatus: doc.applyStatus,
    promotedTags: doc.promotedTags,
    managers: doc.managers,
    mainManagerUsername: doc.mainManagerUsername,
    skipContracts: doc.skipContracts,
    invoiceEmailGreeting: doc.invoiceEmailGreeting,
    invoicing: doc.invoicing,
    owner: doc.owner,
    internalMission: doc.internalMission,
    paymentTerms: doc.paymentTerms,
  };
}

export function getTeamWorkData(formData: FormData): MissionData {
  const { roles, managers, owner, ...left } = formData;

  return {
    ...left,
    roles: roles.map(
      (item): MissionRoleData => ({
        rid: item.rid,
        cid: item.category.cid,
        headline: item.headline,
        headlineHtml: item.headlineHtml,
        hourlyRate: item.hourlyRate,
        monthlyRate: item.monthlyRate,
        availability: item.availability,
        marginVAT: item.marginVAT,
        margin: item.margin,
        builderRateMin: item.builderRateMin,
        builderRateMax: item.builderRateMax,
        showRateRangeToBuilders: item.showRateRangeToBuilders,
        status: item.status,
        user: item.user
          ? item.user.username
            ? {
                username: item.user.username,
              }
            : { fullName: item.user.fullName }
          : null,
        utcOffsetRange: item.utcOffsetRange,
        workingHours: item.workingHours,
        locations: item.locations,
        requiredSkills: item.requiredSkills || [],
        preferredSkills: item.preferredSkills || [],
        visibility: item.visibility,
        customQuestions: item.customQuestions,
        automatedStatusesDisabled: item.automatedStatusesDisabled,
        builderMonthlyRateMin: item.builderMonthlyRateMin,
        builderMonthlyRateMax: item.builderMonthlyRateMax,
        isFullTimeRetainer: item.isFullTimeRetainer,
        collectMonthlyRate: item.collectMonthlyRate,
        readyForReview: item.readyForReview,
        isNiche: item.isNiche,
      }),
    ),
    ...(managers && {
      managers: managers.map(
        (manager): ManagerFormData => ({
          accessMode: manager.accessMode,
          username: manager.username,
          excludeFromInvoiceEmails: manager.excludeFromInvoiceEmails || false,
          excludeFromTeamPulseEmails:
            manager.excludeFromTeamPulseEmails || false,
          excludeFromBuilderFeedbackEmails:
            manager.excludeFromBuilderFeedbackEmails || false,
          excludeFromMissionUpdatesEmails:
            manager.excludeFromMissionUpdatesEmails || false,
        }),
      ),
    }),
    ...(owner && { owner: owner.username }),
  };
}
