import React, { ReactElement, useState } from 'react';
import useDebounceState from '@src/hooks/useDebounceState';
import { searchImages } from '@src/logic/unsplash';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { Icon, IconType } from '@ateams/components';
import { FileSelectionBoxProps } from '@src/components/FileUploader/components/FileSelectionBox';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  tabInner: {
    padding: 32,
  },
  imageList: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexFlow: 'wrap',
  },
  image: {
    width: 130,
    maxHeight: 130,
    margin: 12,
    borderRadius: 4,
    cursor: 'pointer',
  },
});

export const UnsplashTab = (props: FileSelectionBoxProps): ReactElement => {
  const [unsplashImages, setUnsplashImages] = useState<string[]>();
  const [query, setQuery] = useDebounceState<string | undefined>(
    undefined,
    (debouncedQuery) => {
      debouncedQuery &&
        searchImages(debouncedQuery).then((results) => {
          results &&
            setUnsplashImages(
              results.results.map((result) => result.urls.regular),
            );
        });
    },
  );
  const styles = useStyles();

  return (
    <div className={styles.tabInner}>
      <OutlinedInput
        value={query}
        placeholder={'Search for an image...'}
        endAdornment={<Icon type={IconType.Search} muted size="exact" />}
        onChange={(e): void => {
          setQuery(e.target.value);
        }}
      />
      <div className={styles.imageList}>
        {query &&
          (unsplashImages && unsplashImages.length ? (
            unsplashImages?.map((image) => (
              <img
                alt="Cover"
                key={image}
                className={styles.image}
                src={image}
                onClick={() =>
                  props.onUnsplashSelect && props.onUnsplashSelect(image)
                }
              />
            ))
          ) : (
            <p style={{ fontStyle: 'italic', color: '#62646A' }}>
              We didn't find any results{' '}
              <span role="img" aria-label="icon">
                🎨
              </span>
            </p>
          ))}
      </div>
    </div>
  );
};
