import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    border: '1px solid #D3D6DC',
    borderRadius: '8px',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  amount: {
    backgroundColor: 'rgba(235,236,239, 0.5)',
    opacity: 1,
    marginRight: '8px',
    width: '53px',
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    color: '#EC6C30',
    fontSize: '16px',
    fontWeight: 700,
  },
  description: {
    fontSize: '14px',
    fontWeight: 500,
  },
});

interface Props {
  value: string;
  description: string;
}

const InfoItem: React.FC<Props> = ({ value, description }) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.amount}>{value}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default InfoItem;
