import {
  ConnectionApprovedNotificationObject,
  NotificationType,
} from '@a_team/models/dist/NotificationObject';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import Notification from './Notification';
import { ProfileLocation } from '@src/locations';

interface ConnectionApprovedNotificationProps {
  notification: ConnectionApprovedNotificationObject;
  onMarkAsRead(): void;
  isRead: boolean;
}

export default function ConnectionApprovedNotification({
  onMarkAsRead,
  isRead,
  notification,
}: ConnectionApprovedNotificationProps): ReactElement {
  const history = useHistory();
  const { connection } = notification;
  const { requested } = connection;

  return (
    <>
      <Notification
        createdAt={notification.createdAt}
        notificationType={NotificationType.ConnectionApproved}
        ctaName="See profile"
        ctaAction={() => {
          onMarkAsRead();
          history.push(ProfileLocation(requested.username));
        }}
        title={'You have a new connection!'}
        description={`${requested.firstName} accepted your request`}
        imageUrl={requested.profilePictureURL}
        onMarkAsRead={onMarkAsRead}
        isRead={isRead}
      />
    </>
  );
}
