import React, { useEffect, ReactElement } from 'react';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import { RootLocation } from '@src/locations';
import MainLayout from '@src/layouts/Main';
import Loader from '@src/components/Loader';
import { useAnalytics } from '@ateams/analytics/dist/platform';

const SignOut = (): ReactElement | null => {
  const { auth } = useStores();
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.trackSignOut(auth.user);

    auth.logout(true).then(
      (): void => {
        // hard-redirect: force clear stores
        window.location.replace(RootLocation);
      },
      (err): void => {
        console.warn(err);
      },
    );
  }, []);

  return (
    <MainLayout title="Sign Out">
      <div style={{ textAlign: 'center', margin: '4em 0' }}>
        <Loader />
      </div>
    </MainLayout>
  );
};

export default observer(SignOut);
