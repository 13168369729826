import { Tag, TagList } from '@ateams/components';
import { Expertise } from '@src/stores/Profile/models';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  items: Expertise[];
  title: string;
  onRemove?: (skill: Expertise) => void;
}
const useStyles = createUseStyles({
  tags: {
    marginTop: 8,
    flexWrap: 'wrap',
  },
  title: {
    fontWeight: '500',
    fontSize: 15,
    marginTop: 25,
    marginBottom: 5,
  },
});

export const Tags = (props: Props): ReactElement => {
  const { items, title, onRemove } = props;
  const styles = useStyles();
  const handleRemove = (item: Expertise) => {
    if (onRemove) {
      onRemove(item);
    }
  };

  return (
    <div>
      <h3 className={styles.title}>{title}</h3>
      <TagList className={styles.tags}>
        {items.map((item) => {
          return (
            <Tag
              key={item.id}
              style={{
                fontSize: 15,
                marginLeft: 0,
                marginRight: 10,
                marginBottom: 8,
              }}
              iconTitle="don't add this skill"
              onRemove={onRemove ? () => handleRemove(item) : undefined}
              square
            >
              {item.name}
            </Tag>
          );
        })}
      </TagList>
    </div>
  );
};
