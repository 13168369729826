import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { BuilderTfsPitchObject } from './BlurbsList';
import { theme } from '@a_team/ui-components';
import BlurbSummary from './BlurbSummary';
import { Button } from '@ateams/components';

interface BlurbPreviewProps {
  proposal: BuilderTfsPitchObject;
  onBlurbSelected(blurb: string): void;
}

const useStyles = createUseStyles({
  preview: {
    overflow: 'auto',
    boxSizing: 'border-box',
    padding: 24,
    backgroundColor: theme.colors.Grey[100],
    width: 500,
  },
  blurb: {
    lineHeight: '24px',
    margin: '10px 0 13px',
  },
});

const BlurbPreview: FC<BlurbPreviewProps> = ({ proposal, onBlurbSelected }) => {
  const styles = useStyles();

  return (
    <div className={styles.preview}>
      <BlurbSummary showRate={true} tfsPitch={proposal} />
      {proposal.tfsPitch.blurb && (
        <div
          className={styles.blurb}
          dangerouslySetInnerHTML={{ __html: proposal.tfsPitch.blurb }}
        />
      )}
      <Button
        width="auto"
        size="small"
        onClick={() => onBlurbSelected(proposal.tfsPitch.blurb || '')}
      >
        Use Blurb
      </Button>
    </div>
  );
};

export default observer(BlurbPreview);
