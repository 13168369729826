import React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import { DateISOString } from '@a_team/models/dist/misc';
import { theme } from '@a_team/ui-components';
import { Text } from '../components/typography';

export interface VettingFeedbackFormTimestampProps {
  submitDate?: DateISOString;
  saveDate?: DateISOString;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  text: {
    color: theme.colors.Grey[500],
  },
});

export const VettingFeedbackFormTimestamp: React.FC<
  VettingFeedbackFormTimestampProps
> = (props) => {
  const styles = useStyles();
  const date = props.submitDate || props.saveDate;

  if (!date) {
    return null;
  }

  const isSubmitted = Boolean(props.submitDate);

  const formattedDate = format(new Date(date), 'MMM d, yyyy, h:mm a');

  return (
    <div className={cx(styles.container, props.className)}>
      <Text className={styles.text}>
        {isSubmitted ? 'Submitted' : 'Saved'} {formattedDate}
      </Text>
    </div>
  );
};
