import React from 'react';
import { Link } from 'react-router-dom';
import { VetterRowProps } from '.';
import UserAvatar from '@src/components/UserAvatar';

export const NameField: React.FC<VetterRowProps> = ({ vetter }) => {
  return (
    <Link to={{ pathname: vetter.user.profileURL }} target="_blank">
      <UserAvatar
        src={vetter.user.profilePictureURL}
        label={vetter.user.fullName}
        size={24}
      />
    </Link>
  );
};
