import {
  BasicConnectionObjectV2,
  ConnectionObjectV2,
  ConnectionStatus,
  ConnectionType,
} from '@a_team/models/dist/ConnectionObject';
import { UserCardObject } from '@a_team/models/dist/UserObject';
import { ConnectionsModalSources } from '@ateams/analytics/dist/platform';
import { Button, Colors, Icon, IconType, Checkbox } from '@ateams/components';
import {
  useApproveConnection,
  useRequestConnection,
} from '@src/rq/connections';
import { observer } from 'mobx-react';
import React, { ReactElement, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import Description from '../../partials/Description';
import Title from '../../partials/Title';
import { ConnectModalScreens } from '../../types';
import { getConnection } from '../../utils/utils';
import cx from 'classnames';

interface HomeProps {
  userToConnect: UserCardObject;
  source: ConnectionsModalSources;
  setScreen: (screen: ConnectModalScreens) => void;
  setCurrentConnection: (connection: BasicConnectionObjectV2) => void;
  connectActionOnCompleted?: (connection: ConnectionObjectV2) => void;
  onConnectionAction: (connectionType?: ConnectionType) => void;
  handleScreenClose: (keepModalOpen?: boolean) => void;
  loadingConnectionType?: ConnectionType;
}

const useStyles = createUseStyles({
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
  },
  profileLink: {
    color: '#818388',
  },
  checkbox: {
    margin: 0,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: 20,
  },
  button: {
    borderRadius: 8,
    marginTop: 40,
    padding: '11px 20px',
    width: 'auto',
    minWidth: 160,
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  addConnectionButton: {
    transition: 'background 0.3s ease',
    '&:hover': {
      background: '#5900b3',
    },
  },
  editIcon: {
    fontSize: 16,
  },
});

function Home({
  userToConnect,
  source,
  setCurrentConnection,
  setScreen,
  connectActionOnCompleted,
  handleScreenClose,
  onConnectionAction,
  loadingConnectionType,
}: HomeProps): ReactElement {
  const styles = useStyles();
  const connections = userToConnect.connections;
  const workConnection = getConnection(ConnectionType.Work, connections);
  const teamUpConnection = getConnection(ConnectionType.TeamUp, connections);
  const connection = workConnection ?? teamUpConnection;

  const canApproveRequest =
    connection &&
    connection.status === ConnectionStatus.Pending &&
    connection?.canApprove;

  const canCancelRequest =
    connection &&
    connection.status === ConnectionStatus.Pending &&
    !connection?.canApprove;

  const canDeleteConnection =
    connection && connection.status === ConnectionStatus.Active;

  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<JSX.Element | string>('');
  const [previouslyWorkedTogether, setPreviouslyWorkedTogether] =
    useState(false);
  const { mutate: requestConnection, isLoading: isRequesting } =
    useRequestConnection({
      source,
      connectActionOnCompleted,
    });
  const { mutateAsync: approveConnection, isLoading: isApprovingConnection } =
    useApproveConnection({
      source,
      connectActionOnCompleted,
    });

  const loading =
    isRequesting || !!loadingConnectionType || isApprovingConnection;

  const isNotificationOrDiscovery =
    ConnectionsModalSources.DiscoveryNotifications === source ||
    ConnectionsModalSources.NotificationBubble === source;

  const getTitle = () => {
    if (canCancelRequest) {
      return `Edit your connection with ${userToConnect.firstName}`;
    }

    if (canApproveRequest) {
      return `${userToConnect.fullName} wants to connect`;
    }

    if (connection?.status === ConnectionStatus.Active) {
      return `Edit your connection with ${userToConnect.fullName}`;
    }

    return `Connecting signals a mutual interest to work together`;
  };

  const getDescription = () => {
    if (canCancelRequest) {
      return (
        <div
          style={{
            color: '#818388',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <Icon title="Requested" size="xsmall" type={IconType.GreyEllipse} />{' '}
          Requested
        </div>
      );
    }

    if (connection && connection.status === ConnectionStatus.Active) {
      return (
        <div
          style={{
            color: Colors.secondaryDark,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <Icon title="Requested" size="xsmall" type={IconType.CheckPurple} />{' '}
          Connected
        </div>
      );
    }

    return 'Connected builders receive priority for mission selection. We’ve found the best teams in the world are formed from meaningful connections.';
  };

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [loading, canCancelRequest]);

  const handleConnection = async (): Promise<void> => {
    if (previouslyWorkedTogether) {
      setScreen(ConnectModalScreens.SetWorkingExperience);
      return;
    }

    await requestConnection({
      requestedUserId: userToConnect.uid,
      type: ConnectionType.TeamUp,
    });
    return;
  };

  const handleCancelConnectionRequest = (
    connection: BasicConnectionObjectV2,
  ): void => {
    setCurrentConnection(connection);
    setScreen(ConnectModalScreens.CancelConnectionRequest);
  };

  const handleDeleteConnection = (
    connection: BasicConnectionObjectV2,
  ): void => {
    setCurrentConnection(connection);
    setScreen(ConnectModalScreens.DeleteConnection);
  };

  const handleEditConnection = (connection: BasicConnectionObjectV2): void => {
    setCurrentConnection(connection);
    setScreen(ConnectModalScreens.SetWorkingExperience);
  };

  const handleAcceptConnectionRequest = async (
    connection: BasicConnectionObjectV2,
  ): Promise<void> => {
    await approveConnection({
      connection: connection,
    });
    setCurrentConnection(connection);
    setScreen(ConnectModalScreens.ConnectionConfirmed);
  };

  const handleRejectConnectionRequest = (
    connection: BasicConnectionObjectV2,
  ): void => {
    setCurrentConnection(connection);
    setScreen(ConnectModalScreens.RejectConnectionRequest);
  };

  return (
    <div>
      <Title text={title} />
      {isNotificationOrDiscovery && canApproveRequest && (
        <div className={styles.linkContainer}>
          <Link
            className={styles.profileLink}
            to={`/${userToConnect.username}`}
            onClick={() => {
              handleScreenClose();
            }}
          >
            View full profile
          </Link>
        </div>
      )}
      <Description text={description} />

      <div style={{ textAlign: 'center' }}>
        {!connection && (
          <div
            style={{
              marginTop: 16,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 10,
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.preventDefault();
              setPreviouslyWorkedTogether(!previouslyWorkedTogether);
            }}
            data-testing-id="previously-worked-with"
          >
            <Checkbox
              checked={previouslyWorkedTogether}
              className={styles.checkbox}
            />
            I’ve previously worked with {userToConnect.firstName}
          </div>
        )}

        <div className={styles.buttonsWrapper}>
          {!connection && (
            <Button
              onClick={handleConnection}
              className={cx(styles.button, styles.addConnectionButton)}
              color="secondaryDark"
              data-testing-id="send-connection-request"
              loading={loading}
            >
              {previouslyWorkedTogether
                ? 'Share your team experience'
                : 'Add Connection'}
            </Button>
          )}

          {connection && (
            <>
              {canDeleteConnection && (
                <Button
                  onClick={() => handleDeleteConnection(connection)}
                  className={styles.button}
                  color="backgroundDark"
                  data-testing-id="remove-connection"
                  loading={loading}
                >
                  Remove connection
                </Button>
              )}

              {canCancelRequest && (
                <>
                  {connection.type === ConnectionType.Work &&
                    connection.status === ConnectionStatus.Pending && (
                      <Button
                        onClick={() => handleEditConnection(connection)}
                        className={styles.button}
                        color={'regularLight'}
                        data-testing-id="edit-connection"
                        loading={loading}
                      >
                        <Icon
                          type={IconType.EditPencilGray}
                          className={styles.editIcon}
                        />
                        Edit connection
                      </Button>
                    )}

                  <Button
                    onClick={() => {
                      handleCancelConnectionRequest(connection);
                    }}
                    className={styles.button}
                    color={canCancelRequest ? 'regularLight' : 'secondaryDark'}
                    data-testing-id="cancel-connection-request"
                    loading={loading}
                  >
                    Cancel request
                  </Button>
                </>
              )}

              {canApproveRequest && (
                <>
                  <Button
                    onClick={() => handleRejectConnectionRequest(connection)}
                    className={styles.button}
                    color={'regularLight'}
                    data-testing-id="decline-connection-request"
                    loading={loading}
                  >
                    Decline
                  </Button>

                  {connection.type === ConnectionType.Work && (
                    <Button
                      onClick={() => {
                        setScreen(ConnectModalScreens.SetWorkingExperience);
                      }}
                      className={styles.button}
                      color={
                        canCancelRequest ? 'regularLight' : 'secondaryDark'
                      }
                      data-testing-id="review-connection-request"
                      loading={loading}
                    >
                      Review request
                    </Button>
                  )}

                  {connection.type === ConnectionType.TeamUp && (
                    <Button
                      onClick={() => {
                        handleAcceptConnectionRequest(connection);
                      }}
                      className={styles.button}
                      color={
                        canCancelRequest ? 'regularLight' : 'secondaryDark'
                      }
                      data-testing-id="accept-connection-request"
                      loading={loading}
                    >
                      Accept
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(Home);
