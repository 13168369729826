import React, { ReactElement } from 'react';
import Icon from './icon.svg';
import { createUseStyles } from 'react-jss';
import { Breakpoints, TextColors } from '@ateams/components';
import cx from 'classnames';

interface Props {
  text: string;
  className?: string;
}

const useStyles = createUseStyles({
  root: {
    background: 'linear-gradient(91.72deg, #FFF8F8 0%, #FFE5F6 100%)',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    padding: '16px 20px',
    fontSize: 15,
    flexDirection: 'column',
    textAlign: 'center',
    flexWrap: 'wrap',
    color: TextColors.regular,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      flexDirection: 'row',
      textAlign: 'left',
      flexWrap: 'nowrap',
    },
  },
});

const ApplicationRequirementsNotification = (props: Props): ReactElement => {
  const styles = useStyles();
  const { text, className } = props;
  return (
    <div className={cx(styles.root, className)}>
      <img src={Icon} alt={'Missing requirements'} />
      {text}
    </div>
  );
};

export default ApplicationRequirementsNotification;
