import React, { ReactElement } from 'react';
import {
  CloseButton as OrgCloseButton,
  CloseButtonProps,
} from '@ateams/components';

type Props = CloseButtonProps;

export default function CloseButton(props: Props): ReactElement {
  const { style, ...left } = props;

  return (
    <OrgCloseButton
      {...left}
      style={{
        position: 'absolute',
        top: '16px',
        right: '16px',
        cursor: 'pointer',
        zIndex: 100,
        ...style,
      }}
    />
  );
}
