import React, { ReactElement, useMemo } from 'react';
import { SurveyQuestionType } from '@a_team/models/dist/Survey';
import { commentIsRequired as _commentIsRequired } from '@a_team/models/dist/Survey/questions/types/RatingWithFreeFormCommentSurveyQuestion';
import { QuestionComponentProps } from './types';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import OutlinedInput from '../Inputs/OutlinedInput';
import StarRating from '../StarRating';
import LabeledCheckboxInput from '../Inputs/LabeledCheckboxInput';
import {
  PersonRatingWithFreeFormCommentSurveyQuestionResponse,
  validatePersonRatingWithFreeFormCommentSurveyQuestionResponse,
} from '@a_team/models/dist/Survey/questions/types/PersonRatingWithFreeFormCommentSurveyQuestion';

const useStyles = createUseStyles({
  wrapper: {},
  title: {
    fontWeight: 700,
    marginTop: '32px',
    marginBottom: '16px',
  },
  starRating: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  unableToRespondCheckBox: {
    display: 'inline-flex',
    margin: 0,
  },
  unableToRespondCheckBoxContainer: {
    display: 'block',
    marginTop: '16px',
    marginBottom: '16px',
  },
});

export type Props =
  QuestionComponentProps<SurveyQuestionType.PersonRatingWithFreeFormComment>;

const PersonRatingWithFreeFormCommentQuestion = (
  props: Props,
): ReactElement | null => {
  const {
    className,
    onInput,
    question: { props: question },
    value: { value, comment: commentValue, unableToRespond } = {
      value: null,
      comment: '',
    },
  } = props;
  const { title, max, min, comment, required } = question;

  const styles = useStyles();

  const commentIsRequired = useMemo(
    () => _commentIsRequired(value, comment),
    [value, comment],
  );

  const setInput = (
    newValue: PersonRatingWithFreeFormCommentSurveyQuestionResponse,
  ) => {
    try {
      validatePersonRatingWithFreeFormCommentSurveyQuestionResponse(
        question,
        newValue,
      );
      onInput({ value: newValue, valid: true, error: null });
    } catch (error) {
      if (error instanceof Error) {
        onInput({ value: newValue, valid: false, error });
      }
    }
  };

  return (
    <div className={cx(className, styles.wrapper)}>
      <div className={styles.unableToRespondCheckBoxContainer}>
        <LabeledCheckboxInput
          className={styles.unableToRespondCheckBox}
          label="I didn’t work with this teammate."
          checked={!!unableToRespond}
          onChange={() =>
            setInput(
              unableToRespond
                ? { value: null, comment: '' }
                : { unableToRespond: true },
            )
          }
        />
      </div>
      <p className={styles.title}>
        {title}
        {!required && <span> (Optional)</span>}
      </p>
      <div>
        <StarRating
          className={styles.starRating}
          value={value}
          onChange={(n) => setInput({ value: n, comment: commentValue })}
          min={min}
          max={max}
          disabled={!!unableToRespond}
        />
      </div>
      <label>
        <p className={styles.title}>
          {comment.commentTitle}
          {!commentIsRequired && <span> (Optional)</span>}
        </p>
        <OutlinedInput
          multiline
          required={commentIsRequired}
          placeholder={comment.commentPlaceholder}
          value={unableToRespond ? '' : commentValue}
          disabled={!!unableToRespond}
          minRows={4}
          maxRows={4}
          onInput={(e) => {
            setInput({
              value,
              comment: e.currentTarget.value,
            });
          }}
          onChange={(e) => {
            setInput({
              value,
              comment: e.currentTarget.value,
            });
          }}
        />
      </label>
    </div>
  );
};

export default PersonRatingWithFreeFormCommentQuestion;
