import { action, computed, observable, toJS } from 'mobx';

import TimesheetObject, {
  TimesheetId,
  TimesheetRecord,
  TimesheetRecordData,
  TimesheetRecordKey,
} from '@a_team/models/dist/TimesheetObject';
import {
  MissionPaymentCycleAdminObject,
  MissionPaymentCycleId,
  PaymentCycles,
  PaymentCycleSummary,
} from '@a_team/models/dist/MissionPaymentCycleObject';
import type { MissionId } from '@a_team/models/dist/MissionObject';
import MissionObject, {
  MissionAdminObject,
  MissionManager,
  MissionStatus,
} from '@a_team/models/dist/MissionObject';
import MissionRole, {
  MissionAdminRole,
  MissionRoleId,
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import {
  AdminMissionApplicationObject,
  ExclusiveStatus,
  MissionApplicationBasicObject,
  MissionApplicationId,
} from '@a_team/models/dist/MissionApplicationObject';
import _, { sortBy, uniqBy } from 'lodash';
import MissionPaymentCycle, {
  MissionPaymentCycleStoreData,
} from '@src/stores/Missions/MissionPaymentCycle';
import {
  apiContracts,
  apiInvoices,
  apiMissions,
  apiTeamPulse,
  apiTimesheets,
  apiUser,
  notificationLogApi,
} from '@src/logic/services/endpoints';
import type {
  BasicInvoiceObject,
  InvoiceId,
} from '@a_team/models/dist/InvoiceObject';
import {
  formatMinutesToTime,
  getLocalTime,
  stringifyDate,
} from '@src/helpers/time';
import { isWithinInterval } from 'date-fns';
import { Stores } from '@src/stores';
import {
  CurrentUserObject,
  UserId,
  UserUsername,
} from '@a_team/models/dist/UserObject';
import {
  AdminMissionPaymentCycleSummarize,
  BulkMissionApplicationStatusUpdate,
  MissionApplicationManuallyProposedData,
  MissionApplicationStatusUpdate,
  PaymentCycleInvoiceRequest,
  PaymentCycleReopenRequest,
} from '@ateams/api/dist/endpoints/Missions';
import { exportCSVFile } from '@src/helpers/csv';
import Proposal, { ProposalStoreData } from '@src/stores/Missions/Proposal';
import { QueryNextToken, QueryResult } from '@a_team/models/dist/misc';
import ContractObject, {
  BasicContractObject,
  ContractId,
  ContractStatus,
  ContractType,
} from '@a_team/models/dist/ContractObject';
import { TeamPulse, TeamPulseSurvey } from '@a_team/models/dist/TeamPulse';
import { MarkAsPaidData } from '@ateams/api/dist/endpoints/Invoices';
import { Expertise } from '../Profile/models';
import {
  TalentCategoryId,
  TalentSpecialization,
  UserTalentSkillAssignment,
} from '@a_team/models/dist/TalentCategories';
import { expertiseToSkill } from '@src/helpers/expertise';
import { NotificationLogDto } from '@a_team/user-notification-service-js-sdk';
import { AccountId } from '@a_team/models/dist/Account';
import { fetchSkillList } from '@src/helpers/talent-skills-api';
import { filterOutBracketedSkills } from '../utils';
import { TimesheetInitiativeObject } from '@a_team/models/dist/TimesheetInitiativeObject';

export type SerializedMissionStoreData = Omit<
  MissionStoreData,
  'selectedPaymentCycle' | 'paymentCycles' | 'proposal'
> & {
  selectedPaymentCycle?: MissionPaymentCycleStoreData;
  paymentCycles: MissionPaymentCycleStoreData[];
  proposal?: ProposalStoreData;
};

export interface RoleRecord extends TimesheetRecord {
  role: MissionRole;
}

export interface GroupedRecords {
  date: Date;
  records: RoleRecord[];
  id: string;
}

export enum Modes {
  User = 'User',
  Admin = 'Admin',
}

export interface MissionStoreData {
  mode: Modes;
  selectedPaymentCycle?: MissionPaymentCycle;
  timesheetsView: 'single' | 'team' | MissionRoleId;
  paymentCycles: MissionPaymentCycle[];
  invoices: BasicInvoiceObject[];
  data: MissionAdminObject | MissionObject;
  applications: AdminMissionApplicationObject[] | null;
  sortedApplicationsByRoleId: Map<
    MissionRoleId,
    AdminMissionApplicationObject[]
  > | null;
  applicationsAvailable: boolean;
  proposal?: Proposal;
  contracts: BasicContractObject[];
  teamPulses?: Array<TeamPulse>;
  myOpenIncompleteTeamPulseSurveys?: Array<TeamPulseSurvey>;
  teamPulseSurveys?: Array<TeamPulseSurvey>;
  notifications?: NotificationLogDto[];
  nextTokens: { invoices: QueryNextToken | null };
  isRecommended: boolean | undefined;
}

export default class Mission implements MissionStoreData {
  @observable mode: MissionStoreData['mode'] = Modes.User;
  @observable selectedPaymentCycle: MissionStoreData['selectedPaymentCycle'];
  @observable timesheetsView: MissionStoreData['timesheetsView'] = 'single';
  @observable data: MissionStoreData['data'];
  @observable invoices: MissionStoreData['invoices'] = [];
  @observable paymentCycles: MissionStoreData['paymentCycles'] = [];
  @observable applications: MissionStoreData['applications'] = null;
  @observable applicationsAvailable = false;
  @observable
  sortedApplicationsByRoleId: MissionStoreData['sortedApplicationsByRoleId'] = new Map();
  @observable proposal: MissionStoreData['proposal'];
  @observable contracts: MissionStoreData['contracts'] = [];
  @observable teamPulses: MissionStoreData['teamPulses'] = [];
  @observable
  myOpenIncompleteTeamPulseSurveys: MissionStoreData['myOpenIncompleteTeamPulseSurveys'] =
    [];
  @observable
  teamPulseSurveys: MissionStoreData['teamPulseSurveys'] = [];
  @observable notifications: MissionStoreData['notifications'];
  @observable nextTokens: MissionStoreData['nextTokens'] = { invoices: null };
  @observable isRecommended: boolean | undefined;
  @observable isNotInterested: boolean | undefined;

  private authStore: Stores['auth'];
  private paymentsStore: Stores['payments'];
  private profileStore: Stores['profile'];
  private missionsStore: Stores['missions'];
  private applicationsNext?: QueryNextToken | null;

  public constructor(
    missionData: MissionObject | MissionAdminObject,
    auth: Stores['auth'],
    profile: Stores['profile'],
    missions: Stores['missions'],
    payments: Stores['payments'],
    initialAdminMode?: boolean,
    initialState?: MissionStoreData,
    isRecommended?: boolean,
    isNotInterested?: boolean,
  ) {
    this.authStore = auth;
    this.profileStore = profile;
    this.missionsStore = missions;
    this.paymentsStore = payments;
    this.data = missionData;
    this.isRecommended = isRecommended;
    this.isNotInterested = isNotInterested;
    initialAdminMode ? this.setMode(Modes.Admin) : this.setMode(Modes.User);
    if (initialState) {
      this.data = initialState.data;
      this.mode = initialState.mode;
      this.selectedPaymentCycle = initialState.selectedPaymentCycle
        ? new MissionPaymentCycle(initialState.selectedPaymentCycle?.data)
        : undefined;
      this.timesheetsView = initialState.timesheetsView;
      this.invoices = initialState.invoices;
      this.nextTokens = initialState.nextTokens;
      initialState.contracts && (this.contracts = initialState.contracts);
      initialState.teamPulses && (this.teamPulses = initialState.teamPulses);
      initialState.myOpenIncompleteTeamPulseSurveys &&
        (this.myOpenIncompleteTeamPulseSurveys =
          initialState.myOpenIncompleteTeamPulseSurveys);
      initialState.teamPulseSurveys &&
        (this.teamPulseSurveys = initialState.teamPulseSurveys);

      this.notifications = initialState.notifications;
    }
    this.setData();
  }

  serialize = (): SerializedMissionStoreData => {
    return {
      mode: this.mode,
      data: this.data,
      selectedPaymentCycle: this.selectedPaymentCycle?.serialize(),
      paymentCycles: this.paymentCycles
        ? this.paymentCycles.map((cycle) => cycle.serialize())
        : [],
      timesheetsView: this.timesheetsView,
      invoices: this.invoices,
      contracts: this.contracts,
      teamPulses: this.teamPulses,
      myOpenIncompleteTeamPulseSurveys: this.myOpenIncompleteTeamPulseSurveys,
      teamPulseSurveys: this.teamPulseSurveys,
      applications: this.applications,
      sortedApplicationsByRoleId: this.sortedApplicationsByRoleId,
      applicationsAvailable: this.applicationsAvailable,
      proposal: this.proposal?.serialize(),
      nextTokens: this.nextTokens,
      isRecommended: this.isRecommended,
      notifications: this.notifications,
    };
  };

  public groupUserRecords = (
    userRecords: TimesheetRecord[],
    username?: UserUsername | null,
  ): GroupedRecords[] => {
    // a builder can have multiple roles in a mission but we only pick up the ones where the role is still active or scheduled to end.
    const recordsToGroup = userRecords.map((record) => {
      const roles = this.data.roles.filter(
        (role) =>
          role.user?.username === username &&
          [MissionRoleStatus.Active, MissionRoleStatus.ScheduledToEnd].includes(
            role.status,
          ),
      );

      return {
        role: roles[0],
        ...record,
      };
    }) as RoleRecord[];

    return _.chain(recordsToGroup)
      .groupBy('date')
      .map((records, date) => ({
        date: new Date(date),
        records,
        id: `${new Date().toISOString()}${Math.random()}`,
      }))
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      .value();
  };

  public groupAllUserRecords = ({
    userRecords,
    roleId,
  }: {
    userRecords: TimesheetRecord[];
    roleId: string;
  }): GroupedRecords[] => {
    const recordsToGroup = userRecords.map((record) => ({
      role: this.data.roles.find((role) => role.rid === roleId),
      ...record,
    })) as RoleRecord[];

    return _.chain(recordsToGroup)
      .groupBy('date')
      .map((records, date) => ({
        date: new Date(date),
        records,
        id: `${new Date().toISOString()}${Math.random()}`,
      }))
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      .value();
  };

  getInvoiceSummary = async (
    yid: MissionPaymentCycleId,
    request: PaymentCycleInvoiceRequest,
  ): Promise<AdminMissionPaymentCycleSummarize> => {
    return await apiMissions.adminSummarizeMissionPaymentCycle(
      this.authStore,
      this.mid,
      yid,
      request,
    );
  };

  getContracts = async (): Promise<QueryResult<BasicContractObject>> => {
    try {
      const contracts = await apiMissions.getMissionContracts(
        this.authStore,
        this.mid,
      );
      this.setContracts(contracts?.items || []);
      return contracts;
    } catch (_e) {
      return { items: [], next: null };
    }
  };

  getSingleContract = async (sid: ContractId): Promise<ContractObject> => {
    return await apiMissions.getMissionContract(this.authStore, this.mid, sid);
  };

  signSingleContract = async (sid: ContractId): Promise<ContractObject> => {
    if (this.isMissionManager) {
      return await apiMissions.signMissionContract(
        this.authStore,
        this.mid,
        sid,
      );
    } else {
      return await apiContracts.sign(this.authStore, sid);
    }
  };

  signContracts = async (ids: ContractId[]): Promise<ContractObject[]> => {
    const contracts = await Promise.all(
      ids.map((id) => this.signSingleContract(id)),
    );
    this.updateContracts(contracts);
    return contracts;
  };

  uploadCustomMissionAgreement = async (
    uid: UserId,
    rid: MissionRoleId,
    downloadURL: string,
  ): Promise<ContractObject | undefined> => {
    if (!this.mid || !downloadURL) {
      return;
    }
    if (!this.accountId) {
      return;
    }

    const contract = await apiMissions.createCustomMissionContract(
      this.authStore,
      this.mid,
      {
        uid,
        downloadURL,
        rid,
        accountId: this.accountId,
      },
    );

    return contract;
  };

  uploadCustomPlatformAgreement = async (
    downloadURL: string,
  ): Promise<ContractObject | undefined> => {
    return apiMissions.createCustomPlatformAgreement(this.authStore, this.mid, {
      downloadURL,
    });
  };

  deleteCustomContract = async (sid: ContractId): Promise<void | Error> => {
    if (sid) {
      try {
        await apiContracts.deleteCustomContract(this.authStore, sid);
        const newContracts = this.contracts
          .slice()
          .filter((contract) => contract.sid !== sid);

        this.setContracts(newContracts);
      } catch (error) {
        throw Error(`Failed to delete a contract: ${sid}`);
      }
    }
  };

  sendTimesheetReminders = async (
    ids: UserId[],
    yid: MissionPaymentCycleId,
  ): Promise<void[]> => {
    return await Promise.all(
      ids.map((id) => this.sendTimesheetReminder(id, yid)),
    );
  };

  sendTimesheetReminder = async (
    uid: UserId,
    yid: MissionPaymentCycleId,
  ): Promise<void> => {
    return await apiMissions.adminSendPaymentCycleReminderEmailToUser(
      this.authStore,
      this.mid,
      yid,
      uid,
    );
  };

  public toggleRoleLookingForApplications = async (
    rid: MissionRoleId,
    lookingForApplications: boolean,
  ): Promise<void> => {
    await apiMissions.toggleRoleLookingForApplications(
      this.authStore,
      this.mid,
      { rid, lookingForApplications },
    );

    this.setRoleLookingForApplications(rid, lookingForApplications);
  };

  public toggleRoleIsNiche = async (
    rid: MissionRoleId,
    isNiche: boolean,
  ): Promise<void> => {
    await apiMissions.setRoleIsNiche(this.authStore, rid, isNiche);
    this.setRoleIsNiche(rid, isNiche);
  };

  @action setRoleIsNiche = (rid: MissionRoleId, isNiche: boolean): void => {
    const roleIndex = this.data.roles.findIndex((role) => role.rid === rid);
    if (roleIndex !== -1) {
      (this.data.roles[roleIndex] as MissionAdminRole).isNiche = isNiche;
    }
  };

  @action setRoleLookingForApplications = (
    rid: MissionRoleId,
    lookingForApplications: boolean,
  ): void => {
    const roleIndex = this.data.roles.findIndex((role) => role.rid === rid);
    if (roleIndex !== -1) {
      (this.data.roles[roleIndex] as MissionAdminRole).lookingForApplications =
        lookingForApplications;
    }
  };

  public exportTimehseet = (rid: MissionRoleId): void => {
    const userRole = this.data.roles.find((role) => role.rid === rid);
    const timesheet = this.selectedPaymentCycle?.timesheets?.find(
      (sheet) => sheet.rid === rid,
    );
    timesheet &&
      exportCSVFile(
        {
          date: 'Date',
          details: 'Task',
          minutes: 'Hours',
        },
        timesheet.records.map((record) => {
          return {
            date: stringifyDate(record.date),
            details: record.details,
            minutes: formatMinutesToTime(record.minutes),
          };
        }),
        `${userRole?.user ? `${userRole.user?.fullName}'s` : ''} Timesheet - ${
          this.selectedPaymentCycle?.formattedStartDate
        } - ${this.selectedPaymentCycle?.formattedEndDate}`,
      );
  };

  public exportTimesheetV2 = async (
    rid: MissionRoleId,
    initiatives: TimesheetInitiativeObject[],
  ): Promise<void> => {
    const userRole = this.data.roles.find((role) => role.rid === rid);
    const timesheet = this.selectedPaymentCycle?.timesheets?.find(
      (sheet) => sheet.rid === rid,
    );

    timesheet &&
      exportCSVFile(
        {
          date: 'Date',
          type: 'Type',
          details: 'Task',
          initiative: 'Initiative',
          minutes: 'Hours',
        },
        timesheet.records.map((record) => {
          const initiativesString =
            record.initiativeIds
              ?.map((initiativeId) => {
                return initiatives.find(
                  (initiative) => initiative.siid === initiativeId,
                );
              })
              .map((initiative) => initiative?.name)
              .filter((initiativeName) => !!initiativeName)
              .join(', ') ?? '';

          return {
            date: stringifyDate(record.date),
            type: record.type ?? '',
            details: `"${record.details}"`,
            initiative: `"${initiativesString}"`,
            minutes: formatMinutesToTime(record.minutes),
          };
        }),
        `${userRole?.user ? `${userRole.user?.fullName}'s` : ''} Timesheet - ${
          this.selectedPaymentCycle?.formattedStartDate
        } - ${this.selectedPaymentCycle?.formattedEndDate}`,
      );
  };

  @computed get allRoles(): TalentSpecialization[] | [] {
    const allRoles = [];
    const mainRole =
      this.authStore.currentUser?.talentProfile?.talentSpecializations
        ?.mainTalentSpecialization;
    const additionalRoles =
      this.authStore.currentUser?.talentProfile?.talentSpecializations
        ?.additionalTalentSpecializations;
    if (mainRole) {
      allRoles.push(mainRole);
    }
    if (additionalRoles) {
      allRoles.push(...additionalRoles);
    }
    return allRoles;
  }

  @computed get accountId(): AccountId | undefined {
    return (this.data as MissionAdminObject).accountId;
  }

  public getMissingRole = (rid: MissionRoleId): Expertise | undefined => {
    const userRole = this.data.roles.find((role) => role.rid === rid);
    const category = userRole?.category;

    if (category && this.allRoles && this.allRoles.length > 0) {
      if (
        !this.allRoles.map((addnlRole) => addnlRole.id).includes(category?.cid)
      ) {
        const newRole: Expertise = {
          id: category.cid,
          name: category.title,
        };
        return newRole;
      }
    }
    return undefined;
  };

  @computed get mainSkills(): UserTalentSkillAssignment[] | [] {
    return (
      this.authStore.currentUser?.talentProfile?.talentSkills
        .mainTalentSkills || []
    );
  }

  @computed get additionalSkills(): UserTalentSkillAssignment[] | [] {
    return (
      this.authStore.currentUser?.talentProfile?.talentSkills
        .additionalTalentSkills || []
    );
  }
  @computed get allSkills(): { [id: string]: UserTalentSkillAssignment } {
    const objMap: { [id: string]: UserTalentSkillAssignment } = {};
    this.mainSkills.forEach((skill) => {
      objMap[skill.talentSkillId] = skill;
    });
    if (this.additionalSkills.length > 0) {
      this.additionalSkills.forEach((skill) => {
        objMap[skill.talentSkillId] = skill;
      });
    }
    return objMap;
  }
  @computed get allSkillsWithRating(): { [id: string]: boolean } {
    const objMap: { [id: string]: boolean } = {};
    this.mainSkills.forEach((skill) => {
      objMap[skill.talentSkillId] = true;
    });
    if (this.additionalSkills.length > 0) {
      this.additionalSkills.forEach((skill) => {
        objMap[skill.talentSkillId] = true;
      });
    }
    return objMap;
  }

  public getMissingSkills = (
    rid: MissionRoleId,
  ): {
    required: Expertise[];
    preferred: Expertise[];
  } => {
    const missingRequiredSkills: Expertise[] = [];
    const missingPreferredSkills: Expertise[] = [];

    const userRole = this.data.roles.find((role) => role.rid === rid);
    const requiredSkills = userRole?.requiredSkills;
    const preferredSkills = userRole?.preferredSkills;

    // Check for required Skills
    if (requiredSkills && requiredSkills.length > 0) {
      requiredSkills.forEach((requiredSkill) => {
        if (!this.allSkills[requiredSkill.talentSkillId]) {
          const newSkill: Expertise = {
            id: requiredSkill.talentSkillId,
            name: requiredSkill.talentSkillName || '',
          };
          missingRequiredSkills.push(newSkill);
        }
      });
    }
    if (preferredSkills && preferredSkills.length > 0) {
      preferredSkills.forEach((preferredSkill) => {
        if (!this.allSkills[preferredSkill.talentSkillId]) {
          const newSkill: Expertise = {
            id: preferredSkill.talentSkillId,
            name: preferredSkill.talentSkillName || '',
          };
          missingPreferredSkills.push(newSkill);
        }
      });
    }

    return {
      required: missingRequiredSkills,
      preferred: missingPreferredSkills,
    };
  };

  public getLowProficiencySkills = (
    rid: MissionRoleId,
  ): { required: Expertise[]; preferred: Expertise[] } => {
    const lowProficiencyRequiredSkills: Expertise[] = [];
    const lowProficiencyPreferredSkills: Expertise[] = [];

    const userRole = this.data.roles.find((role) => role.rid === rid);
    const requiredSkills = userRole?.requiredSkills;
    const preferredSkills = userRole?.preferredSkills;

    // Check for required Skills
    if (requiredSkills && requiredSkills.length > 0) {
      requiredSkills.forEach((requiredSkill) => {
        const lowProficiencySkill = this.allSkills[requiredSkill.talentSkillId];
        if (
          lowProficiencySkill !== undefined &&
          (lowProficiencySkill?.rating as number) < 3
        ) {
          const newSkill: Expertise = {
            id: lowProficiencySkill.talentSkillId,
            name: lowProficiencySkill.talentSkillName || '',
            rating: lowProficiencySkill.rating,
          };
          lowProficiencyRequiredSkills.push(newSkill);
        }
      });
    }

    if (preferredSkills && preferredSkills.length > 0) {
      preferredSkills.forEach((preferredSkill) => {
        const lowProficiencySkill =
          this.allSkills[preferredSkill.talentSkillId];
        if (
          lowProficiencySkill !== undefined &&
          (lowProficiencySkill?.rating as number) < 3
        ) {
          const newSkill: Expertise = {
            id: lowProficiencySkill.talentSkillId,
            name: lowProficiencySkill.talentSkillName || '',
            rating: lowProficiencySkill.rating,
          };
          lowProficiencyPreferredSkills.push(newSkill);
        }
      });
    }

    return {
      required: lowProficiencyRequiredSkills,
      preferred: lowProficiencyPreferredSkills,
    };
  };

  saveAdditionalSkills = async (
    additionalSkills: Expertise[],
  ): Promise<void> => {
    const allSkills = [
      ...additionalSkills.map(expertiseToSkill),
      ...toJS(this.additionalTalentSkills),
    ];

    const mainTalentSkills = this.mainSkills.map((mainSkill) => {
      const skill = allSkills.find(
        (skill) => skill.talentSkillId === mainSkill.talentSkillId,
      );
      return skill || mainSkill;
    });

    const additionalTalentSkills = allSkills.filter((skill) => {
      return !mainTalentSkills.find(
        (mainTalentSkill) =>
          mainTalentSkill.talentSkillId === skill.talentSkillId,
      );
    });

    const newUser = await apiUser.updateTalentSkills(this.authStore, {
      mainTalentSkills: mainTalentSkills,
      additionalTalentSkills: uniqBy(
        additionalTalentSkills,
        (s) => s.talentSkillId,
      ),
    });
    this.setAdditonalSkills(newUser);
  };

  saveAdditionalRoles = async (role: Expertise[]): Promise<void> => {
    const allRoles = [...role, ...this.additionalRoles];
    const newUser = await apiUser.updateTalentSpecializations(this.authStore, {
      mainSpecializationId: this.mainRole?.id,
      additionalSpecializationIds: allRoles.map((role) => role.id),
    });
    this.setAdditonalSkills(newUser);
  };

  updateUserSkillsAndRoles = async (
    role: Expertise[] | undefined,
    reqSkills: Expertise[],
    prefSkills: Expertise[],
  ): Promise<void> => {
    const promises = [];
    (reqSkills || prefSkills) &&
      promises.push(this.saveAdditionalSkills([...reqSkills, ...prefSkills]));
    role && promises.push(this.saveAdditionalRoles([...role]));
    // Set all user skills and roles
    await Promise.all(promises);
    // Set updated user
    await this.authStore.fetchData(true);
  };

  @action private setAdditonalSkills = (
    newUser: CurrentUserObject,
  ): boolean => {
    this.authStore.setUser({ ...newUser });

    return true;
  };
  querySkills = async (
    query: string,
    useLimit = true,
  ): Promise<Expertise[]> => {
    const skills = await fetchSkillList({
      filter: {
        query,
      },
    });

    const filteredSkills = filterOutBracketedSkills(skills);

    return filteredSkills;
  };

  @computed get allTalentCategoryIds(): TalentCategoryId[] {
    const ids: TalentCategoryId[] = [];
    const roles: Expertise[] = this.profileStore?.mainRole
      ? [...this.profileStore?.roles, this.profileStore?.mainRole]
      : [...this.profileStore?.roles];
    roles.forEach((role) => role.categoryIds && ids.push(...role.categoryIds));
    return ids;
  }

  @computed get additionalRoles(): TalentSpecialization[] | [] {
    return (
      this.authStore.currentUser?.talentProfile?.talentSpecializations
        ?.additionalTalentSpecializations || []
    );
  }
  @computed get mainRole(): TalentSpecialization | undefined {
    return (
      this.authStore.currentUser?.talentProfile?.talentSpecializations
        ?.mainTalentSpecialization || undefined
    );
  }
  @computed get additionalTalentSkills(): UserTalentSkillAssignment[] | [] {
    return (
      this.authStore.currentUser?.talentProfile?.talentSkills
        .additionalTalentSkills || []
    );
  }

  @computed get mid(): MissionId {
    return this.data.mid;
  }

  @computed get currentUserTimesheet(): TimesheetObject | undefined {
    return this.displayedTimesheets?.find(
      (sheet) => sheet.rid === this.currentUserRole?.rid && sheet,
    );
  }

  @computed get MissionManagers(): MissionManager[] | undefined {
    return this.data.managers;
  }

  @computed get currentMissionManager(): MissionManager | undefined {
    return this.data.managers?.find(
      (manager) => manager.user.uid === this.authStore.uid,
    );
  }

  @computed get isMissionManager(): boolean {
    return !!this.currentMissionManager;
  }

  @computed get displayedTimesheets(): TimesheetObject[] | undefined {
    return this.selectedPaymentCycle?.timesheets;
  }

  @computed get isAdminMode(): boolean {
    return this.mode === Modes.Admin;
  }

  @computed get areTabsShown(): boolean {
    return !!this.currentUserRole || this.isMissionManager || this.isAdminMode;
  }

  @computed get currentUserRole(): MissionRole | undefined {
    return this.data?.roles
      .slice()
      .sort((role) =>
        role.status === MissionRoleStatus.Active ||
        role.status === MissionRoleStatus.ScheduledToEnd
          ? -1
          : 1,
      )
      .find(
        (role) => role.user?.username && role.user?.uid === this.authStore.uid,
      );
  }

  @computed get timeTrackingTabDisabled(): boolean {
    if (this.authStore.isAdmin) {
      return false;
    }

    const roleActiveStatuses = [
      MissionRoleStatus.Active,
      MissionRoleStatus.ScheduledToEnd,
    ];

    return (
      this.isIdle ||
      (!this.currentUserRole && !this.isMissionManager) ||
      !!(
        this.currentUserRole &&
        !roleActiveStatuses.includes(this.currentUserRole.status)
      )
    );
  }

  @computed get documentsTabDisabled(): boolean {
    if (this.authStore.isAdmin) {
      return false;
    }
    return !this.currentUserRole && !this.isMissionManager;
  }

  @computed get teamPulseTabDisabled(): boolean {
    if (this.authStore.isAdmin) {
      return false;
    }

    const roleActiveStatuses = [
      MissionRoleStatus.Active,
      MissionRoleStatus.ScheduledToEnd,
    ];

    return (
      this.isIdle ||
      (!this.currentUserRole && !this.isMissionManager) ||
      !!(
        this.currentUserRole &&
        !roleActiveStatuses.includes(this.currentUserRole.status)
      )
    );
  }

  @computed get shouldDisplayMembers(): boolean {
    if (!this.authStore.username) return false;
    return (
      this.authStore.isAdmin ||
      this.data.status === MissionStatus.Published ||
      this.data.status === MissionStatus.Created ||
      this.data.status === MissionStatus.Running ||
      this.data.status === MissionStatus.ScheduledToEnd ||
      !!this.currentUserRole ||
      this.data.publisher?.uid === this.authStore.uid ||
      this.data.creator?.uid === this.authStore.uid
    );
  }

  @computed get cycleDates(): Date[] | null {
    if (!this.data.paymentCycles || !this.selectedPaymentCycle) return null;
    const newCycleDates = [new Date(this.selectedPaymentCycle.data.startDate)];
    const endDate = this.selectedPaymentCycle.passedEndDate
      ? new Date(this.selectedPaymentCycle.data.endDate).getTime()
      : new Date().getTime();
    for (
      let i = newCycleDates[0].getTime() + 24 * 3600e3;
      i < endDate;
      i += 24 * 3600e3
    ) {
      if (
        isWithinInterval(getLocalTime(new Date(i)), {
          start: getLocalTime(
            new Date(this.selectedPaymentCycle.data.startDate),
          ),
          end: endDate,
        })
      ) {
        newCycleDates.push(new Date(i));
      }
    }
    return newCycleDates;
  }

  @computed get groupedTeamTimesheetsRecords(): GroupedRecords[] {
    const teamRoles = new Map(
      (this.data.roles as MissionRole[]).map((role) => [role.rid, role]),
    );

    const teamTimesheetsRecords = this.displayedTimesheets
      ? ([] as RoleRecord[]).concat(
          ...this.displayedTimesheets.map((timesheet) => {
            const role = teamRoles.get(timesheet.rid) as MissionRole;
            return timesheet.records.map((record) => ({ role, ...record }));
          }),
        )
      : [];

    return _.chain(teamTimesheetsRecords)
      .groupBy('date')
      .map((records, date) => ({
        date: new Date(date),
        records,
        id: `${new Date().toISOString()}${Math.random()}`,
      }))
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      .value();
  }

  @computed get groupedUserTimesheetsRecords(): GroupedRecords[] {
    return this.currentUserTimesheet
      ? this.groupUserRecords(
          this.currentUserTimesheet?.records,
          this.authStore.username,
        )
      : [];
  }

  @computed get userTotalMinutes(): number {
    if (!this.groupedUserTimesheetsRecords.length) {
      return 0;
    }
    return this.groupedUserTimesheetsRecords
      ?.map((record) =>
        record.records.reduce(function (a, b) {
          return a + b['minutes'];
        }, 0),
      )
      .reduce((a, b) => a + b, 0);
  }

  @computed get teamTotalMinutes(): number {
    if (!this.groupedTeamTimesheetsRecords.length) {
      return 0;
    }
    return this.groupedTeamTimesheetsRecords
      ?.map((record) =>
        record.records.reduce(function (a, b) {
          return a + b['minutes'];
        }, 0),
      )
      .reduce((a, b) => a + b, 0);
  }

  @computed get isIdle(): boolean {
    return (
      this.data.status !== MissionStatus.Running &&
      this.data.status !== MissionStatus.ScheduledToEnd
    );
  }

  @computed get isPaymentAccountSuspended(): boolean {
    return this.paymentsStore.setupDetails?.status === 'Suspended';
  }

  @computed get isPaymentSetupPending(): boolean {
    return (
      this.paymentsStore.setupDetails?.payableReason !== 'PayeeUnknown' &&
      !this.paymentsStore.setupDetails?.status
    );
  }

  @computed get hasPaymentIssues(): boolean {
    const reason = this.paymentsStore.setupDetails?.payableReason ?? '';
    return reason !== '' && reason !== 'PayeeUnknown';
  }

  @computed get isPaymentSetupNoticeShown(): boolean {
    return !!(
      this.paymentsStore.setupDetails &&
      !this.paymentsStore.setupDetails?.isPayable &&
      !this.isMissionManager
    );
  }

  @computed get builderContracts(): BasicContractObject[] {
    return this.contracts.filter(
      (contract) => contract.type === ContractType.MissionAgreement,
    );
  }

  @computed get customPlatformAgreements(): Array<BasicContractObject> {
    return this.contracts.filter(
      ({ custom, type }) => custom && type === ContractType.TermsOfService,
    );
  }

  @computed get pendingContracts(): BasicContractObject[] {
    return this.contracts.filter(
      (contract) =>
        !contract.signedAt && contract.status !== ContractStatus.Completed,
    );
  }

  @computed get signedContracts(): BasicContractObject[] {
    return this.contracts.filter(
      (contract) =>
        contract.signedAt || contract.status === ContractStatus.Completed,
    );
  }

  @computed get serviceAgreementContracts(): BasicContractObject[] {
    return this.contracts.filter(
      (contract) => contract.type === ContractType.MissionAgreement,
    );
  }

  @computed get serviceAgreementPendingContracts(): BasicContractObject[] {
    return this.serviceAgreementContracts.filter(
      (contract) => !contract.signedAt,
    );
  }

  @computed get showCustomContractCards(): boolean {
    return this.authStore.isAdmin;
  }

  @computed get showClientContractCard(): boolean {
    return (
      this.isMissionManager &&
      this.serviceAgreementPendingContracts.length !== 0
    );
  }

  @computed get openRoles(): MissionRole[] {
    return this.data.roles.filter(
      (role) => role.status === MissionRoleStatus.Open,
    );
  }

  @computed get openRoleTitles(): string[] {
    const roles: string[] = [];
    this.openRoles.forEach((role) => {
      if (!roles.includes(role.category.title)) {
        roles.push(role.category.title);
      }
    });
    return roles;
  }

  @computed get rolesSortedByOpen(): MissionRole[] {
    if (this.openRoles.length === 0) return this.data.roles;
    return sortBy(this.data.roles, (role) => role.user !== null);
  }

  @computed get rolesSorted(): MissionRole[] {
    if (!this.authStore.withNewMissionControlAllMissionsSorting) {
      return this.rolesSortedByOpen;
    }

    const hasUserAppliedToARole = this.data.roles.some(
      (r) => r?.application?.withdrawn,
    );

    const allUserSecondaryRoles =
      this.authStore.currentUser?.talentProfile?.talentSpecializations?.additionalTalentSpecializations?.map(
        (ts) => ts.id,
      );

    return sortBy(
      this.data.roles,
      // Any role a builder is active on is shown first
      (role) =>
        role.user === this.authStore.uid &&
        role.status &&
        [MissionRoleStatus.Active, MissionRoleStatus.ScheduledToEnd].includes(
          role.status,
        )
          ? 0
          : 1,
      // Any roles a builder has applied to are shown first
      (role) => (!role.user && role?.application?.withdrawn ? 0 : 1),
      // If a builder hasn’t applied to any roles, actively recruiting roles shown first
      (role) =>
        !role.user && !hasUserAppliedToARole
          ? role.lookingForApplications
            ? 0
            : 1
          : 1,
      // Recommended roles
      (role) => (!role.user && role.recommended ? 0 : 1),
      // Primary role
      (role) =>
        !role.user &&
        role.category.cid ===
          this.authStore.currentUser?.talentProfile?.mainTalentSpecializationId
          ? 0
          : 1,
      // All profile roles
      (role) =>
        !role.user && allUserSecondaryRoles?.includes(role.category.cid)
          ? 0
          : 1,
      // All open roles
      (role) => (!role.user && role.status === MissionRoleStatus.Open ? 0 : 1),
    );
  }

  @computed get latestTeamPulse(): TeamPulse | undefined {
    return this.teamPulses?.[this.teamPulses.length - 1];
  }

  @computed get oldTeamPulses(): TeamPulse[] | undefined {
    return this.teamPulses?.slice(0, this.teamPulses.length - 1);
  }

  @computed get nextTeamPulseSurvey(): TeamPulseSurvey | undefined {
    return this.myOpenIncompleteTeamPulseSurveys?.[0];
  }

  @action setMode = (mode: Modes): void => {
    this.mode = mode;
  };

  @action addContract = (contract: ContractObject): void => {
    this.contracts = [contract, ...this.contracts];
  };

  @action updateContracts = (contracts: ContractObject[]): void => {
    contracts.forEach((contract) => {
      const index = this.contracts.findIndex(
        (contractToUpdate) => contractToUpdate.sid === contract.sid,
      );

      if (index === -1) return;

      const update = { ...this.contracts[index] };
      update.signedAt = contract.signedAt;
      update.status = contract.status;
      if (contract.parties) {
        update.parties?.forEach((partyToUpdate) => {
          const matchingParty = contract.parties.find(
            (p) => p.type === partyToUpdate.type,
          );
          if (matchingParty) {
            partyToUpdate.signedAt = matchingParty.signedAt;
          }
        });
      }

      this.contracts[index] = update;
    });
  };

  @action public changeTimesheetsView = (
    view: 'single' | 'team' | MissionRoleId,
  ): void => {
    this.timesheetsView = view;
  };

  @action setData = (): void => {
    if (!this.currentUserRole && !this.isMissionManager && !this.isAdminMode)
      return;
    this.setInitialPaymentCycle(this.isAdminMode);
    this.setPaymentCycles();
    this.paymentsStore.getSetupDetails();
    this.getContracts();
  };

  @action setContracts = (contracts: BasicContractObject[]): void => {
    this.contracts = contracts;
  };

  @action setInitialPaymentCycle = (isAdminMode: boolean): void => {
    if (this.data.paymentCycles?.current) {
      this.setSelectedPaymentCycle(
        this.data.paymentCycles?.current.yid,
        isAdminMode,
      );
      (isAdminMode || this.isMissionManager) &&
        this.changeTimesheetsView('team');
    }
    this.setPaymentCycles();
  };

  @action setProposal = async (): Promise<void> => {
    if (!this.applications) {
      await this.getMissionApplications();
    }

    // The proposal is only created for admin users
    this.proposal = new Proposal(
      this.authStore,
      this.data as MissionAdminObject,
      this.applications,
    );
  };

  @action setPaymentCycles = (): void => {
    const { paymentCycles } = this.data;

    const cycles = (
      (this.data.paymentCycles as PaymentCycles)?.items || []
    ).map((cycle) => new MissionPaymentCycle(cycle, this.currentUserRole));
    if (paymentCycles && (paymentCycles as PaymentCycles).current) {
      cycles.unshift(
        new MissionPaymentCycle(paymentCycles.current, this.currentUserRole),
      );
    }

    this.paymentCycles = cycles;
  };

  @action setSelectedPaymentCycle = async (
    yid: MissionPaymentCycleId,
    isAdmin?: boolean,
  ): Promise<void> => {
    let res;

    if (isAdmin) {
      res = await apiMissions.adminGetMissionPaymentCycle(
        this.authStore,
        this.mid,
        yid,
      );
    } else {
      res = await apiMissions.getMissionPaymentCycle(
        this.authStore,
        this.mid,
        yid,
      );
    }
    const initialSelectedPaymentCycle = this.selectedPaymentCycle;
    if (res) {
      this.selectedPaymentCycle = new MissionPaymentCycle(
        res,
        this.currentUserRole,
      );
      const indexToUpdate = this.paymentCycles.findIndex(
        (cycle) => cycle.yid === yid,
      );
      this.paymentCycles[indexToUpdate] = new MissionPaymentCycle(
        res,
        this.currentUserRole,
      );
    } else {
      this.selectedPaymentCycle = initialSelectedPaymentCycle;
    }
  };

  @action public getInvoices = async (adminView?: boolean): Promise<void> => {
    if (this.invoices.length > 0 && !this.nextTokens.invoices) return;

    if (adminView) {
      this.getCustomerInvoices();
    } else {
      this.isMissionManager ? this.getClientInvoices() : this.getUserInvoices();
    }
  };

  getUserInvoices = async (): Promise<void> => {
    const invoices = await apiMissions.listMissionInvoices(
      this.authStore,
      this.mid,
      this.nextTokens.invoices || undefined,
    );
    this.setInvoices(invoices);
  };

  getClientInvoices = async (): Promise<void> => {
    const invoices = await apiMissions.listClientInvoices(
      this.authStore,
      this.mid,
      this.nextTokens.invoices || undefined,
    );
    this.setInvoices(invoices);
  };

  @action setInvoices = (invoices: QueryResult<BasicInvoiceObject>): void => {
    this.invoices = [...this.invoices, ...invoices.items];
    this.nextTokens.invoices = invoices.next;
  };

  @action updateInvoice(invoice: BasicInvoiceObject): void {
    const index = this.invoices.findIndex((item) => item.iid === invoice.iid);

    if (index !== -1) {
      this.invoices[index] = invoice;
    }
  }

  getCustomerInvoices = async (): Promise<void> => {
    const invoices = await apiMissions.listCustomerInvoices(
      this.authStore,
      this.mid,
      this.nextTokens.invoices || undefined,
    );
    this.setInvoices(invoices);
  };

  getMissionApplications = async (
    forceRefresh = false,
    onlyOpenRoles = false,
  ): Promise<void> => {
    const pageLimit = 25;
    const results: QueryResult<AdminMissionApplicationObject>['items'] = [];
    let next: string | null | undefined = this.applicationsNext ?? undefined;

    do {
      const result: QueryResult<AdminMissionApplicationObject> =
        await apiMissions.getMissionApplications(
          this.authStore,
          this.mid,
          next,
          pageLimit,
          onlyOpenRoles,
        );

      results.push(...result.items);
      next = result.next;
      this.setMissionApplications(result.items, result.next, forceRefresh);
    } while (next);
  };

  async updateMissionApplicationStatus(
    aid: MissionApplicationId,
    data: MissionApplicationStatusUpdate,
  ): Promise<AdminMissionApplicationObject> {
    const res = await apiMissions.updateMissionApplicationStatus(
      this.authStore,
      this.mid,
      aid,
      data,
    );

    this.updateMissionApplication(res);

    if (res.exclusiveStatus !== ExclusiveStatus.OnHold) {
      this.updateApplicationsExclusiveStatus(
        res.user.uid,
        res.exclusiveStatus === ExclusiveStatus.Exclusive ? res.aid : undefined,
      );
    }

    return res;
  }

  async bulkUpdateMissionApplicationStatus(
    update: BulkMissionApplicationStatusUpdate,
  ): Promise<AdminMissionApplicationObject[]> {
    const resList = await apiMissions.bulkUpdateMissionApplicationStatus(
      this.authStore,
      this.mid,
      update,
    );

    for (const res of resList) {
      this.updateMissionApplication(res);

      if (res.exclusiveStatus !== ExclusiveStatus.OnHold) {
        this.updateApplicationsExclusiveStatus(
          res.user.uid,
          res.exclusiveStatus === ExclusiveStatus.Exclusive
            ? res.aid
            : undefined,
        );
      }
    }

    return resList;
  }

  updateManuallyProposedDetails = async (
    aid: MissionApplicationId,
    data: MissionApplicationManuallyProposedData,
  ): Promise<AdminMissionApplicationObject> => {
    const res =
      await apiMissions.updateMissionApplicationManuallyProposedStatus(
        this.authStore,
        this.mid,
        aid,
        data,
      );

    this.updateMissionApplication(res);

    return res;
  };

  endRole = async (): Promise<void> => {
    await apiMissions.endUserRoleInMission(this.authStore, this.mid);

    this.changeRoleStatus(MissionRoleStatus.Ended);
  };

  @action setMissionApplications = (
    applications: AdminMissionApplicationObject[],
    next: QueryNextToken | null,
    forceRefresh = false,
  ): void => {
    this.applicationsNext = next;
    this.applications = uniqBy(
      this.applications
        ? forceRefresh
          ? applications
          : [...this.applications, ...applications]
        : applications,
      ({ aid, rid }) => `${aid}:${rid}`,
    );

    this.applicationsAvailable = this.applicationsNext !== null;
  };

  @action setSortedApplicationsByRoleId = (
    rid: MissionRoleId,
    applications: AdminMissionApplicationObject[],
  ): void => {
    this.sortedApplicationsByRoleId?.set(rid, applications);
  };

  @action updateMissionApplication = (
    application: AdminMissionApplicationObject,
  ): void => {
    if (!this.applications) return;

    const index = this.applications.findIndex(
      (item) => item.aid === application.aid,
    );
    this.applications[index] = application;
  };

  @action changeRoleStatus = (status: MissionRoleStatus): boolean => {
    const roleIndex = this.data?.roles.findIndex(
      (role) => role.user?.username && role.user?.uid === this.authStore.uid,
    );

    if (roleIndex === -1) {
      return false;
    }

    this.data.roles[roleIndex].status = status;
    return true;
  };

  @action public updateRole = (
    roleId: MissionRoleId,
    application: MissionApplicationBasicObject,
  ): void => {
    this.data.roles = (this.data as MissionObject).roles.map((role) => {
      if (role.rid !== roleId) {
        return role;
      }

      return { ...role, application };
    });
  };

  @action public updateRoleApplicationWithdrawn = (
    roleId: MissionRoleId,
    withdrawn: boolean,
  ): void => {
    const index = this.data.roles.findIndex((role) => role.rid === roleId);
    if (index !== -1 && this.data.roles[index].application?.aid) {
      (
        this.data.roles[index].application as MissionApplicationBasicObject
      ).withdrawn = withdrawn;
    }
  };

  @action public appendRecord = (record: TimesheetRecord): void => {
    if (!this.currentUserTimesheet) {
      return;
    }
    this.currentUserTimesheet.records = [
      ...this.currentUserTimesheet.records,
      record,
    ];

    const cycleToUpdate = this.paymentCycles.find(
      (cycle) => cycle.yid === this.selectedPaymentCycle?.yid,
    );

    if (cycleToUpdate) {
      cycleToUpdate.updateSummary(record.minutes);
      this.selectedPaymentCycle?.updateSummary(record.minutes);
    }
  };

  @action public modifyRecord = (
    key: TimesheetRecordKey,
    data: TimesheetRecordData,
  ): void => {
    if (!this.currentUserTimesheet) {
      return;
    }
    const recordIndex = this.currentUserTimesheet.records.findIndex(
      (record) => record.key === key,
    );
    const prev = this.currentUserTimesheet.records[recordIndex];
    this.currentUserTimesheet.records[recordIndex] = {
      key,
      ...data,
    };

    const cycleToUpdate = this.paymentCycles.find(
      (cycle) => cycle.yid === this.selectedPaymentCycle?.yid,
    );

    if (cycleToUpdate) {
      cycleToUpdate.updateSummary(data.minutes - prev.minutes);
      this.selectedPaymentCycle?.updateSummary(data.minutes - prev.minutes);
    }
  };

  @action public removeRecord = (key: TimesheetRecordKey): void => {
    if (!this.currentUserTimesheet) {
      return;
    }
    const recordIndex = this.currentUserTimesheet.records.findIndex(
      (record) => record.key === key,
    );
    const prev = this.currentUserTimesheet.records[recordIndex];
    this.currentUserTimesheet.records.splice(recordIndex, 1);

    const cycleToUpdate = this.paymentCycles.find(
      (cycle) => cycle.yid === this.selectedPaymentCycle?.yid,
    );

    if (cycleToUpdate) {
      cycleToUpdate.updateSummary(-prev.minutes);
      this.selectedPaymentCycle?.updateSummary(-prev.minutes);
    }
  };

  public async addRecord(
    sid: TimesheetId,
    data: TimesheetRecordData,
  ): Promise<void> {
    const doc = await apiTimesheets.addRecord(this.authStore, sid, data);
    if (doc) {
      this.appendRecord(doc);
    }
  }

  public async updateRecord(
    sid: TimesheetId,
    key: TimesheetRecordKey,
    data: TimesheetRecordData,
  ): Promise<void> {
    await apiTimesheets.updateRecord(this.authStore, sid, key, data);
    this.modifyRecord(key, data);
  }

  public async deleteRecord(
    sid: TimesheetId,
    key: TimesheetRecordKey,
  ): Promise<void> {
    await apiTimesheets.deleteRecord(this.authStore, sid, key);
    this.removeRecord(key);
  }

  @action public updateTimesheet = (
    updatedTimesheet: TimesheetObject,
  ): void => {
    if (this.displayedTimesheets) {
      const timesheetIndexToUpdate = this.displayedTimesheets.findIndex(
        (timesheet) => timesheet.sid === updatedTimesheet.sid,
      );
      this.displayedTimesheets[timesheetIndexToUpdate] = updatedTimesheet;
    }
  };

  @action public setPaymentCycleSubmittedStatus = (): void => {
    if (this.selectedPaymentCycle) {
      (
        this.selectedPaymentCycle.data.summary as PaymentCycleSummary
      ).submitted = true;
      const cycleIndexToUpdate = this.paymentCycles.findIndex((cycle) => {
        return cycle.data.yid === this.selectedPaymentCycle?.data.yid;
      });

      if (cycleIndexToUpdate !== -1) {
        (
          this.paymentCycles[cycleIndexToUpdate].data
            .summary as PaymentCycleSummary
        ).submitted = true;
      }
    }
  };

  @action submitTimesheets = (): Promise<void> => {
    if (!this.currentUserTimesheet || this.selectedPaymentCycle?.submitted) {
      return Promise.reject('Could not submit timesheet');
    }
    return apiTimesheets
      .submit(this.authStore, this.currentUserTimesheet?.sid)
      .then((data) => {
        this.updateTimesheet(data.timesheet);
        this.setPaymentCycleSubmittedStatus();
        data.didEndRole && this.changeRoleStatus(MissionRoleStatus.Ended);
      })
      .catch((err) => {
        throw err;
      });
  };

  @action adminSubmitUserTimesheet = async (
    sid: TimesheetId,
  ): Promise<void> => {
    const data = await apiTimesheets.submit(this.authStore, sid);
    if (
      data.timesheet &&
      this.selectedPaymentCycle &&
      'timesheets' in this.selectedPaymentCycle?.data
    ) {
      const timesheetToUpdate = this.selectedPaymentCycle?.data.timesheets.find(
        (sheet: TimesheetObject) => sheet.sid === sid,
      );
      timesheetToUpdate && (timesheetToUpdate.status = data.timesheet.status);
    }
  };

  @action adminReopenTimesheet = async (sid: TimesheetId): Promise<void> => {
    const timehseet = await apiTimesheets.adminReopen(this.authStore, sid);
    if (
      timehseet &&
      this.selectedPaymentCycle &&
      'timesheets' in this.selectedPaymentCycle?.data
    ) {
      const timesheetToUpdate = this.selectedPaymentCycle?.data.timesheets.find(
        (sheet: TimesheetObject) => sheet.sid === sid,
      );
      timesheetToUpdate && (timesheetToUpdate.status = timehseet.status);
    }
  };

  @action updatePaymentCycle = (item: MissionPaymentCycleAdminObject): void => {
    const cycleIndex = this.paymentCycles.findIndex(
      (cycle) => cycle.yid === item.yid,
    );

    if (this.selectedPaymentCycle?.yid === item.yid) {
      this.selectedPaymentCycle.data = item;
    }
    if (cycleIndex !== -1) {
      this.paymentCycles[cycleIndex].data = item;
    }

    this.missionsStore.updateEndedPaymentCycle(item);
  };

  closePaymentCycle = async (
    yid: MissionPaymentCycleId,
    request: PaymentCycleInvoiceRequest,
  ): Promise<void> => {
    const updatedCycle = await apiMissions.adminCloseMissionPaymentCycle(
      this.authStore,
      this.mid,
      yid,
      request,
    );

    this.updatePaymentCycle(updatedCycle);
  };

  generateInvoices = async (): Promise<void> => {
    const paymentCycles =
      await apiMissions.adminGenerateMultipleMissionPaymentCyclesInvoices(
        this.authStore,
        this.mid,
      );

    paymentCycles.forEach((paymentCycle) => {
      this.updatePaymentCycle(paymentCycle);

      if (paymentCycle.invoice) {
        this.updateInvoice(paymentCycle.invoice);
      }
    });
  };

  generatePaymentCycleInvoice = async (
    yid: MissionPaymentCycleId,
  ): Promise<void> => {
    const paymentCycle =
      await apiMissions.adminGenerateMissionPaymentCycleInvoice(
        this.authStore,
        this.mid,
        yid,
      );

    this.updatePaymentCycle(paymentCycle);

    if (paymentCycle.invoice) {
      this.updateInvoice(paymentCycle.invoice);
    }
  };

  reopenPaymentCycle = async (
    yid: MissionPaymentCycleId,
    data: PaymentCycleReopenRequest,
  ): Promise<void> => {
    const updatedCycle = await apiMissions.adminReopenMissionPaymentCycle(
      this.authStore,
      this.mid,
      yid,
      data,
    );

    this.updatePaymentCycle(updatedCycle);

    if (updatedCycle.invoice) {
      // This update the the status of the invoice on the store from "Created" to "Canceled".
      this.updateInvoice(updatedCycle.invoice);

      // The invoice is return only once when canceled. It will not return again for this payment cycle after it
      // canceled so we removing it from the store as well.
      delete updatedCycle.invoice;
    }
  };

  createTimesheetInvoice = async (sid: TimesheetId): Promise<void> => {
    const updatedTimesheet = await apiMissions.adminInvoiceMissionTimesheet(
      this.authStore,
      this.mid,
      sid,
    );

    this.updateTimesheet(updatedTimesheet);

    if (updatedTimesheet.invoice) {
      this.updateInvoice(updatedTimesheet.invoice);
    }
  };

  processInvoice = async (iid: InvoiceId): Promise<void> => {
    const invoice = await apiInvoices.process(this.authStore, iid);

    this.updateInvoice(invoice);

    if (invoice.paymentCycle) {
      this.setSelectedPaymentCycle(invoice.paymentCycle, this.isAdminMode);
    }
  };

  public async markInvoiceAsPaid(
    iid: InvoiceId,
    data: MarkAsPaidData,
  ): Promise<void> {
    const invoice = await apiInvoices.markAsPaid(this.authStore, iid, data);

    this.updateInvoice(invoice);
  }

  getTeamPulses = async (admin = false): Promise<void> => {
    const props = {
      filters: {
        missionId: this.mid,
      },
    };
    const teamPulseResults = admin
      ? await apiTeamPulse.adminFindTeamPulses(this.authStore, {
          ...props,
          admin,
        })
      : await apiTeamPulse.findTeamPulses(this.authStore, props);
    this.setTeamPulses(teamPulseResults);
  };

  @action setTeamPulses(teamPulses?: TeamPulse[] | undefined): void {
    this.teamPulses = teamPulses;
  }

  getMyOpenIncompleteTeamPulseSurveys = async (): Promise<void> => {
    const myOpenIncompleteTeamPulseSurveys =
      await apiTeamPulse.findMyOpenIncompleteTeamPulseSurveys(this.authStore, {
        filters: {
          missionId: this.mid,
        },
      });
    this.setMyOpenIncompleteTeamPulseSurveys(myOpenIncompleteTeamPulseSurveys);
  };

  @action setMyOpenIncompleteTeamPulseSurveys(
    myOpenIncompleteTeamPulseSurveys?: TeamPulseSurvey[] | undefined,
  ): void {
    this.myOpenIncompleteTeamPulseSurveys = myOpenIncompleteTeamPulseSurveys;
  }

  getTeamPulseSurveys = async (): Promise<void> => {
    const teamPulseSurveys = await apiTeamPulse.adminFindTeamPulseSurveys(
      this.authStore,
      {
        filters: {
          missionId: this.mid,
        },
      },
    );
    this.setTeamPulseSurveys(teamPulseSurveys);
  };

  getMissionNotifications = async (): Promise<void> => {
    try {
      const notifications =
        await notificationLogApi.notificationLogControllerGetMissionReachoutNotifications(
          { missionId: this.mid },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.authStore.token,
            },
          },
        );
      this.setMissionNotifications(notifications);
    } catch (e) {
      console.error(e);
    }
  };

  @action setTeamPulseSurveys(
    teamPulseSurveys?: TeamPulseSurvey[] | undefined,
  ): void {
    this.teamPulseSurveys = teamPulseSurveys;
  }

  @action setMissionNotifications = (
    notifications: NotificationLogDto[],
  ): void => {
    this.notifications = notifications;
  };

  async refreshTeamPulse(isAdmin?: boolean): Promise<void> {
    const promises = this.authStore.currentUser
      ? [
          this.getTeamPulses(isAdmin),
          this.getMyOpenIncompleteTeamPulseSurveys(),
        ]
      : [];
    if (isAdmin) {
      promises.push(this.getTeamPulseSurveys());
    }
    await Promise.all(promises);
  }

  /**
   * Updates all of a given user's applications with the correct exclusive status
   */
  @action updateApplicationsExclusiveStatus = (
    uid: UserId,
    exclusiveApplication?: MissionApplicationId,
  ): void => {
    if (!this.applications) return;
    this.applications = this.applications.map((application) => {
      if (application.user.uid !== uid) return application;
      return {
        ...application,
        exclusiveStatus: exclusiveApplication
          ? application.aid === exclusiveApplication
            ? application.exclusiveStatus
            : ExclusiveStatus.OnHold
          : undefined,
      };
    });
  };
}
