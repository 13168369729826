import React, { useEffect, useState } from 'react';
import { AdminVettingProcessColumnProps } from '..';
import { Button } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import useToggle from '@src/hooks/useToggle';
import { apiVettingProcessPreVetting } from '@ateams/api';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';

const useStyles = createUseStyles({
  button: {
    width: '200px',
  },
});

export const InterviewerNoShowField: React.FC<
  AdminVettingProcessColumnProps
> = ({ vettingProcess }) => {
  const { auth } = useStores();
  const styles = useStyles();
  const [modal, toggleCloseModal] = useToggle();
  const [loading, setLoading] = useLoadingState();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setShowButton(
      !!vettingProcess.calComBookingUid && !!vettingProcess.preVettingFormNonce,
    );
  }, [vettingProcess.calComBookingUid, vettingProcess.preVettingFormNonce]);

  const onInterviewerNoShow = () => {
    toggleCloseModal();
  };

  const onConfirmInterviewerNoShow = async () => {
    setLoading(
      (async () => {
        if (!vettingProcess.preVettingFormNonce) {
          return;
        }

        await apiVettingProcessPreVetting.markAsNoShow(
          auth,
          vettingProcess.preVettingFormNonce,
          false,
        );

        toggleCloseModal();
        setShowButton(false);
        window.location.reload();
      })(),
      'Interviewer No Show Marked Successfully',
    );
  };

  return (
    <>
      {showButton && (
        <>
          <LoadingIndicator loading={loading} />
          <Button
            onClick={onInterviewerNoShow}
            size="small"
            className={styles.button}
          >
            Interviewer No Show
          </Button>
          <ConfirmModal
            title="Interviewer No Show"
            description="Are you sure you want to mark the interviewer as no show?"
            onConfirm={onConfirmInterviewerNoShow}
            open={modal}
            onClose={toggleCloseModal}
            actionColor={'danger'}
            actionLabel={'Confirm'}
          />
        </>
      )}
    </>
  );
};
