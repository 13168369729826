import { MissionBillingPeriod } from '@a_team/models/dist/MissionObject';
import MissionRole from '@a_team/models/dist/MissionRole';
import { DateISOString } from '@a_team/models/dist/misc';
import { numberWithCommas } from '@src/helpers/numbers';
import { formatMinutesToTime } from '@src/helpers/time';
import { useStores } from '@src/stores';
import { addDays, differenceInDays, getDaysInMonth } from 'date-fns';
import { useMemo } from 'react';

const MONTHLY_WORKING_HOURS = '173:12';
const BI_WEEKLY_WORKING_HOURS = '86:36';
const WEEKLY_WORKING_HOURS = '43:18';

function useGetPaymentAndHoursForGivenPeriod(
  startDate: DateISOString | undefined,
  endDate: DateISOString | undefined,
  currentUserRole?: MissionRole,
) {
  const { missions } = useStores();

  if (!currentUserRole) {
    currentUserRole = missions.currentMission?.currentUserRole || undefined;
  }

  const paymentCycleStartDate = startDate ? new Date(startDate) : undefined;
  const paymentCycleEndDate = endDate ? new Date(endDate) : undefined;

  return useMemo(() => {
    // If either paymentCycleStartDate or paymentCycleEndDate is missing, return default values.
    // This ensures that the function doesn't proceed with incomplete data.
    if (!paymentCycleStartDate || !paymentCycleEndDate) {
      return { fixedPaymentAmount: '$0', fixedHoursAmount: '00:00' };
    }

    const { monthlyRate = 0, availability } = currentUserRole ?? {};

    // Extract the billingPeriod from the current mission data.
    // If the current mission data is undefined, use an empty object as a fallback.
    const { billingPeriod } = missions.currentMission?.data ?? {};

    let fixedHoursAmount = '';
    let fixedPaymentAmount = 0;

    // Calculate the fixedHoursAmount and fixedPaymentAmount based on the billingPeriod.
    // The values are predefined for each billing period: Monthly, BiWeekly, and Weekly.
    switch (billingPeriod) {
      case MissionBillingPeriod.Monthly:
        fixedHoursAmount = MONTHLY_WORKING_HOURS;
        fixedPaymentAmount = monthlyRate / 4;
        break;
      case MissionBillingPeriod.BiWeekly:
        fixedHoursAmount = BI_WEEKLY_WORKING_HOURS;
        fixedPaymentAmount = monthlyRate / 2;
        break;
      case MissionBillingPeriod.Weekly:
        fixedHoursAmount = WEEKLY_WORKING_HOURS;
        fixedPaymentAmount = monthlyRate / 4;
        break;
    }

    // Convert the roleStartDate and roleEndDate from the availability object to Date objects.
    // If either date is undefined, the corresponding Date object will be undefined.
    const roleStartDate = availability?.date
      ? new Date(String(availability.date).split('T')[0])
      : undefined;

    const roleEndDate = availability?.scheduledEndDate
      ? new Date(String(availability.scheduledEndDate).split('T')[0])
      : undefined;

    if (paymentCycleStartDate && paymentCycleEndDate) {
      const daysInMonth = getDaysInMonth(paymentCycleEndDate);
      const dailyPayment = monthlyRate / daysInMonth;
      const dailyMinutes = (173.2 * 60) / daysInMonth;

      let partialTimeWorkedDuringPeriod = -1;

      // if the role start date is after the payment cycle end date
      // or the role end date is before the payment cycle start date
      // then can assume the user worked 0 hours during that payment cycle
      if (
        (roleStartDate && roleStartDate > paymentCycleEndDate) ||
        (roleEndDate && roleEndDate < paymentCycleStartDate)
      ) {
        partialTimeWorkedDuringPeriod = 0;
      } else {
        // Calculate the partial time worked during the payment cycle based on the role's start and end dates.
        // There are three possible scenarios:
        // 1. The role starts and ends within the payment cycle.
        // 2. The role starts after the payment cycle start date and ends after the payment cycle end date.
        // 3. The role starts before the payment cycle start date and ends before the payment cycle end date.
        if (
          roleStartDate &&
          paymentCycleStartDate < roleStartDate &&
          roleEndDate &&
          roleEndDate < paymentCycleEndDate
        ) {
          // Scenario 1: Calculate the number of days between the role's start and end dates.
          partialTimeWorkedDuringPeriod = differenceInDays(
            addDays(roleEndDate, 1),
            roleStartDate,
          );
        } else if (
          roleStartDate &&
          paymentCycleStartDate < roleStartDate &&
          (!roleEndDate || roleEndDate >= paymentCycleEndDate)
        ) {
          // Scenario 2: Calculate the number of days between the role's start date and the payment cycle end date.
          partialTimeWorkedDuringPeriod = differenceInDays(
            addDays(paymentCycleEndDate, 1),
            roleStartDate,
          );
        } else if (
          (!roleStartDate || paymentCycleStartDate >= roleStartDate) &&
          roleEndDate &&
          roleEndDate < paymentCycleEndDate
        ) {
          // Scenario 3: Calculate the number of days between the payment cycle start date and the role's end date.
          partialTimeWorkedDuringPeriod = differenceInDays(
            addDays(roleEndDate, 1),
            paymentCycleStartDate,
          );
        }
      }

      // If the partial time worked during the period is calculated (i.e., not -1),
      // update the fixedPaymentAmount and fixedHoursAmount based on the partial time worked.
      if (partialTimeWorkedDuringPeriod !== -1) {
        fixedPaymentAmount = dailyPayment * partialTimeWorkedDuringPeriod;
        const totalMinutes = dailyMinutes * partialTimeWorkedDuringPeriod;
        fixedHoursAmount = formatMinutesToTime(totalMinutes);
      }
    }

    // Return the calculated fixedPaymentAmount and fixedHoursAmount.
    // The fixedPaymentAmount is formatted as a currency string with commas and rounded down to the nearest integer.
    // The fixedHoursAmount is formatted as a string in the format "hours:minutes".
    return {
      fixedPaymentAmount: `$${numberWithCommas(
        Math.floor(fixedPaymentAmount),
      )}`,
      fixedHoursAmount,
    };
  }, [
    paymentCycleStartDate,
    paymentCycleEndDate,
    missions.currentMission?.currentUserRole,
    missions.currentMission?.data,
  ]);
}

export default useGetPaymentAndHoursForGivenPeriod;
