import React, { ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import logo from './logo.svg';
import {
  BasicInvoiceObject,
  InvoiceType,
} from '@a_team/models/dist/InvoiceObject';
import { stringifyDate } from '@src/helpers/time';
import { getHQAddress } from '@ateams/react-utils/dist/helpers/strings';
import { PaymentCycleDates } from '@a_team/models/dist/MissionPaymentCycleObject';

interface Props {
  invoice: BasicInvoiceObject;
  period?: PaymentCycleDates;
  footer?: ReactNode;
  withHideMarkups?: boolean;
  children: ReactNode;
}

const useStyles = createUseStyles({
  // Can't set @page via JSS so putting it under theme/base.css
  // '@page': {
  //   size: 'A4',
  //   margin: '0',
  // },
  '@global': {
    'html, body': {
      fontSize: '8pt',
      colorAdjust: 'exact',
      '-webkit-print-color-adjust': 'exact',
      printColorAdjust: 'exact',
    },
  },
  root: {
    width: '210mm',
    height: '297mm',
    // width: '100vh',
    // height: '100vh',
    padding: '40pt',
    // fontSize: '10pt',
    fontWeight: 400,
    pageBreakBefore: 'always',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    // alignItems: 'center',
    lineHeight: '1.5em',
    marginBottom: '30pt',
    color: '#62646a',
    position: 'relative',
  },
  logo: {
    width: '50pt',
    height: '45pt',
    marginRight: '20pt',
  },
  info: {
    flexGrow: 1,
  },
  details: {
    // position: 'absolute',
    // right: 0,
    // top: '-10pt',
    width: '50%',

    '& dl': {
      display: 'flex',
      flexWrap: 'wrap',
      margin: 0,
      padding: 0,

      '& > dt': {
        width: '50%',
        margin: 0,
        padding: 0,
      },

      '& > dd': {
        width: '50%',
        textAlign: 'right',
        margin: 0,
        padding: 0,
      },
    },
  },
  title: {
    color: 'rgba(167,75,248,1)',
    fontSize: '12pt',
    fontWeight: 600,
    margin: '0 0 10pt',
  },
  children: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  footer: {
    margin: '40pt -40pt -40pt',
    minHeight: '10pt',
    background:
      'linear-gradient(90deg, rgba(0,155,250,1) 0%, rgba(167,75,248,1) 100%)',
    fallbacks: {
      background: 'rgb(0,155,250)',
    },
    color: '#fff',
  },
});

export default function PageLayout(props: Props): ReactElement {
  const styles = useStyles();

  const isTimesheetInvoice =
    props.invoice.type === InvoiceType.MissionRoleTimesheet;
  const hidePaymentDetails = props.withHideMarkups && isTimesheetInvoice;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img className={styles.logo} src={logo} alt="A·Team" />
        <div className={styles.info}>
          <strong>ATeams, Inc.</strong>
          <br />
          {getHQAddress('line1')}
          {!!getHQAddress('line2') && `, ${getHQAddress('line2')}`}
          <br />
          {getHQAddress('rest')}
          <br />
          https://a.team
        </div>

        <div className={styles.details}>
          <h1 className={styles.title}>
            {props.withHideMarkups ? 'Earnings Statement' : 'Invoice'} #
            {props.invoice.referenceNumber}
          </h1>

          <dl>
            {props.invoice.purchaseOrderNumber != null && (
              <>
                <dt>P.O. number:</dt>
                <dd>{props.invoice.purchaseOrderNumber}</dd>
              </>
            )}

            {!!props.invoice.approverName && (
              <>
                <dt>Budget approver:</dt>
                <dd>{props.invoice.approverName}</dd>
              </>
            )}

            <dt>Date of issue:</dt>
            <dd>{stringifyDate(props.invoice.issuedAt)}</dd>

            {!hidePaymentDetails && (
              <>
                {!!props.invoice.paymentTerms && (
                  <>
                    <dt>Payment terms:</dt>
                    <dd>{props.invoice.paymentTerms}</dd>
                  </>
                )}

                {props.invoice.paymentDueAt ? (
                  <>
                    <dt>Payment due on:</dt>
                    <dd>{stringifyDate(props.invoice.paymentDueAt)}</dd>
                  </>
                ) : (
                  props.invoice.paidAt && (
                    <>
                      <dt>Paid on:</dt>
                      <dd>{stringifyDate(props.invoice.paidAt)}</dd>
                    </>
                  )
                )}
              </>
            )}
          </dl>
        </div>
      </div>

      <div className={styles.children}>{props.children}</div>

      <div className={styles.footer}>{!!props.footer && props.footer}</div>
    </div>
  );
}
