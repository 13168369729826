import React, { ReactElement, useMemo } from 'react';
import {
  ApplicationStatusUserColors,
  Breakpoints,
  Icon,
  IconType,
  Tag,
  TextColors,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';
import {
  CurrentUserMissionApplicationStatus,
  CurrentUserMissionApplicationStatusLabels,
} from '@a_team/models/dist/MissionApplicationObject';
import TooltipWrapped from '@src/components/TooltipWrapped';
import cx from 'classnames';

interface Props {
  status: CurrentUserMissionApplicationStatus;
  tooltip?: ReactElement;
}

const useStyles = createUseStyles({
  tag: {
    flex: '0 0 min-content',
    position: 'relative',
    '&.LowCompetitiveness': {
      width: '100%',
      height: '100%',
      fontSize: 15,
      borderRadius: 0,
      padding: 15,
      lineHeight: '24px',
      fontWeight: 500,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      '& .subtitle': {
        display: 'block',
        fontWeight: 300,
      },
    },
  },
  tooltipIcon: {
    display: 'none !important',
    marginLeft: 12,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    tag: {
      '&.LowCompetitiveness': {
        fontSize: 12,
        width: 'inherit',
        height: 'inherit',
        borderRadius: 104,
        padding: '8px 16px',
        paddingRight: 40,
        lineHeight: 'normal',
        fontWeight: 300,
        '& .subtitle': {
          display: 'none',
        },
      },
    },
    tooltipIcon: {
      display: 'block !important',
      position: 'absolute',
      right: 10,
      top: 7,
    },
  },
});

export default function ApplicationStatus(props: Props): ReactElement {
  const { status, tooltip } = props;
  const styles = useStyles();

  const colors: {
    background: string | undefined;
    text: string;
  } = useMemo(() => {
    switch (status) {
      case CurrentUserMissionApplicationStatus.Accepted:
        return {
          background: ApplicationStatusUserColors.success,
          text: TextColors.success,
        };
      case CurrentUserMissionApplicationStatus.Shortlist:
        return {
          background: ApplicationStatusUserColors.successLight,
          text: TextColors.regular,
        };
      case CurrentUserMissionApplicationStatus.Proposed:
        return {
          background: ApplicationStatusUserColors.info,
          text: TextColors.info,
        };
      case CurrentUserMissionApplicationStatus.MissionEnded:
      case CurrentUserMissionApplicationStatus.NotAvailable:
        return {
          background: ApplicationStatusUserColors.regularDark,
          text: TextColors.primary,
        };
      case CurrentUserMissionApplicationStatus.Interviewing:
        return {
          background: ApplicationStatusUserColors.warning,
          text: TextColors.regular,
        };
      case CurrentUserMissionApplicationStatus.Rejected:
      case CurrentUserMissionApplicationStatus.ConfirmedAnotherBuilder:
      case CurrentUserMissionApplicationStatus.LowCompetitiveness:
      case CurrentUserMissionApplicationStatus.RoleCancelled:
      case CurrentUserMissionApplicationStatus.RoleEnded:
      case CurrentUserMissionApplicationStatus.MissionCancelled:
        return {
          background: ApplicationStatusUserColors.dangerLight,
          text: TextColors.regular,
        };
      default:
        return {
          background: ApplicationStatusUserColors.tag,
          text: TextColors.regular,
        };
    }
  }, [status]);

  return (
    <Tag
      style={{
        background: colors.background,
        color: colors.text,
      }}
      className={cx(styles.tag, status)}
    >
      {CurrentUserMissionApplicationStatusLabels[status]}
      {!!props.tooltip && (
        <>
          <TooltipWrapped
            interactive
            html={<>{tooltip}</>}
            disabled={!tooltip}
            position="top"
            arrow
            animation="fade"
            className={styles.tooltipIcon}
          >
            <Icon type={IconType.Info} title={''} />
          </TooltipWrapped>
        </>
      )}
      {/*{ Will only show on mobile }*/}
      <span className={'subtitle'}>{tooltip}</span>
    </Tag>
  );
}
