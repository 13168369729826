import React from 'react';

export const LinkIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        opacity="0.6"
        d="M6 8H10"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M10 12H11C13.2091 12 15 10.2091 15 8V8C15 5.79086 13.2091 4 11 4H10M6 4H5C2.79086 4 1 5.79086 1 8V8C1 10.2091 2.79086 12 5 12H6"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
    </svg>
  );
};
