import React, { ReactNode } from 'react';
import {
  Breakpoints,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

const useStyles = createUseStyles<MenuItemProps>({
  menuItem: {
    color: Colors.dark,
    fontSize: FontSizes.small,
    height: 40,
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    backgroundColor: (props: MenuItemProps) =>
      props.selected ? Colors.banner : 'transparent',
    cursor: 'pointer',
  },
  selected: {
    borderBottom: `3px solid ${Colors.secondaryDark}`,
    fontWeight: FontWeights.medium,
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    menuItem: {
      paddingRight: Spacing.medium,
      paddingLeft: Spacing.medium,
    },
    selected: {
      borderRight: `3px solid ${Colors.secondaryDark}`,
      borderBottom: 'none',
      fontWeight: FontWeights.semiBold,
    },
  },
});

export interface MenuItemProps {
  children: ReactNode;
  selected?: boolean;
  onClick?: () => void;
}

export const MenuItem = (props: MenuItemProps) => {
  const styles = useStyles(props);

  return (
    <div
      className={cx(styles.menuItem, {
        [styles.selected]: props.selected,
      })}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};
