import React, { useMemo } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { AdminVettingProcessWithAudit } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { Colors, TextColors } from '@ateams/components';
import { Card } from '@src/views/VettingFeedbackForm/components/card';
import { Text } from '@src/views/VettingFeedbackForm/components/typography';

export interface VettingProcessSelectorProps {
  vettingProcesses: AdminVettingProcessWithAudit[];
  selectedVettingProcess: AdminVettingProcessWithAudit['id'];
  onChange: (vettingProcess: AdminVettingProcessWithAudit) => void;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '4px',
    padding: '4px',
    width: 'max-content',
    borderRadius: '4px',
  },
});

export const VettingProcessSelector: React.FC<VettingProcessSelectorProps> = (
  props,
) => {
  const styles = useStyles();
  const { vettingProcesses } = props;

  const vettingProcessesLabels = useMemo(() => {
    const categoryNamesCount: Record<string, number> = {};

    return vettingProcesses.map((vettingProcess, i) => {
      const categoryName =
        vettingProcess.category?.name || 'Software Engineering';

      const categoryCount = categoryNamesCount[categoryName] || 0;

      const label =
        categoryCount > 0
          ? `${categoryName} ${categoryCount + 1}`
          : `${categoryName}`;

      categoryNamesCount[categoryName] = categoryCount + 1;

      return label;
    });
  }, [vettingProcesses]);

  return (
    <Card className={cx(props.className, styles.container)}>
      {vettingProcesses.map((vettingProcess, i) => (
        <VettingProcessTag
          key={vettingProcess.id}
          label={vettingProcessesLabels[i]}
          isSelected={vettingProcess.id === props.selectedVettingProcess}
          isTicked={
            typeof vettingProcess.feedback?.feedbackAccepted !== 'undefined'
          }
          onClick={() => props.onChange(vettingProcess)}
        />
      ))}
    </Card>
  );
};

interface VettingProcessTagProps {
  isSelected: boolean;
  isTicked: boolean;
  label: string;
  onClick: () => void;
}

const useVettingProcessTagStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '6.5px 16px',
    borderRadius: '4px',
    color: TextColors.regularLight,
    cursor: 'pointer',
  },
  containerSelected: {
    color: 'initial',
    background: Colors.backgroundDark,
  },
  checkIcon: {
    marginRight: '4px',
  },
});

const VettingProcessTag: React.FC<VettingProcessTagProps> = (props) => {
  const styles = useVettingProcessTagStyles();

  return (
    <div
      className={cx(styles.container, {
        [styles.containerSelected]: props.isSelected,
      })}
      onClick={props.onClick}
    >
      {props.isTicked && <CheckIcon className={styles.checkIcon} />}

      <Text>{props.label}</Text>
    </div>
  );
};

export const CheckIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.03581 9.49479L11.3367 5.19389C11.5952 4.93537 12.0144 4.93537 12.2729 5.19389C12.5314 5.45242 12.5314 5.87158 12.2729 6.1301L7.03581 11.3672L3.99404 8.32543C3.73551 8.0669 3.73551 7.64775 3.99404 7.38922C4.25256 7.1307 4.67172 7.1307 4.93024 7.38922L7.03581 9.49479Z"
        fill="#62646A"
      />
    </svg>
  );
};
