import React, { ReactElement, useEffect, useState } from 'react';
import Modal, { ModalBasicProps } from '..';
import SectionHeading from '../../SectionHeading';
import { Button as CallToActionButton } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import OutlinedInput from '../../Inputs/OutlinedInput';
import info from './info.svg';
import inviteIllustration from './inviteIllustration.svg';
import { CurrentUserObject } from '@a_team/models/dist/UserObject';
import { InvitationRequestData } from '@ateams/api/dist/endpoints/Invitations';
import useLoadingState from '@src/hooks/useLoadingState';
import { apiInvitation } from '@src/logic/services/endpoints';
import { useStores } from '@src/stores';
import LoadingIndicator from '@src/components/LoadingIndicator';
import UserAvatar from '@src/components/UserAvatar';
import { Breakpoints } from '@ateams/components';
import { BasicMissionRole } from '@a_team/models/dist/MissionRole';
import { Card } from '@ateams/components';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import Alert from '@src/components/Alert';
import Invitation from '@src/layouts/Invitation';
import { useHistory, useLocation } from 'react-router-dom';

interface Props extends ModalBasicProps {
  currentUser: CurrentUserObject;
  openMissionRole?: BasicMissionRole;
}

interface ErrorInviteResponse {
  message?: string;
  payload?: {
    fullName?: string;
  };
}

const useStyles = createUseStyles({
  alert: {},
  alertTitle: {
    marginTop: 0,
    marginBottom: 0,
  },
  alertContent: {
    marginTop: 0,
    marginBottom: 0,
  },
  connectionImage: {
    justifyContent: 'center',
    position: 'relative',
    display: 'none',
  },
  openRole: {
    background: '#fff',
    padding: 16,
    margin: '48px 0 16px',

    '& h4': {
      fontSize: '1em',
      fontWeight: 500,
      margin: '0 0 4px',
    },
    '& h5': {
      fontSize: '1em',
      fontWeight: 300,
      margin: '0 0 12px',
      color: '#62646a',
    },
    '& p': {
      fontSize: '1em',
      fontWeight: 300,
      margin: 0,
      lineHeight: '1.5em',
      height: '1.5em',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  modalMain: {
    width: '100%',
    padding: '16px 40px 40px 40px',
  },
  modalSide: {
    width: '100%',
    paddingTop: 0,
    paddingBottom: 24,
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  modal: {
    width: 'auto',
  },
  contentOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerInner: {
    padding: '24px 24px 0 24px',
  },
  contentInnerContainer: {
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
  },
  inviteOnlyText: {
    display: 'none',
  },
  inviteButton: {
    width: '100%',
    marginTop: 24,
  },
  adminApprove: {
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    padding: '8px 16px',
    width: 320,
    color: '#999',
    fontWeight: 500,

    '& > select': {
      marginLeft: 8,
      border: 0,
      color: '#70a724',
      fontWeight: 500,
    },
  },

  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '856px !important',
    },
    contentInnerContainer: {
      flexDirection: 'row',
    },
    connectionImage: {
      display: 'flex',
    },
    modalSide: {
      width: '50%',
      paddingTop: 40,
      paddingBottom: 40,
    },
    modalMain: {
      width: '50%',
      padding: '16px 40px 40px 40px',
    },
    inviteOnlyText: {
      display: 'block',
      margin: '40px 0 0',
    },
  },
});

function parseLinkedInUsername(input: string): string | undefined {
  return (
    input
      .trim()
      .replace(/^((https?:\/\/)?(www\.)?linkedin\.com\/in)?\/|[/]+$/g, '') ||
    undefined
  );
}

export default function InviteModal(props: Props): ReactElement {
  const { onClose, currentUser, openMissionRole, ...left } = props;
  const location = useLocation();
  const history = useHistory();

  const styles = useStyles();
  const [data, setData] = useState<InvitationRequestData>({
    fullName: '',
    email: '',
    rid: openMissionRole?.rid,
  });
  const { auth } = useStores();
  const analytics = useAnalytics();
  const [loading, setLoading, error] = useLoadingState();
  const errorRes = error as ErrorInviteResponse;

  useEffect(() => {
    setData({
      fullName: '',
      email: '',
      rid: openMissionRole?.rid,
    });
  }, [left.open, openMissionRole]);

  const isOK = loading !== true && data.fullName && data.email;

  const handleOnClose = (): void => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('refer')) {
      queryParams.delete('refer');
      history.replace({
        search: queryParams.toString(),
      });
    }
    onClose();
  };

  const handleSubmit = (): void => {
    if (!isOK) return;

    analytics.trackInviteModalSubmitted(data);

    setLoading(
      apiInvitation.invite(auth, data).then(() => handleOnClose()),
      `Your invite was sent to ${data.fullName.trim().split(' ')[0]}.`,
    );
  };

  return (
    <>
      <Modal
        onClose={handleOnClose}
        style={{
          transition: 'all 0.5s',
          padding: 0,
          zIndex: 100000,
        }}
        className={styles.modal}
        {...left}
      >
        <div className={styles.contentOuterContainer}>
          <div className={styles.headerInner}>
            <SectionHeading style={{ fontSize: '28px' }} isFirst>
              {openMissionRole
                ? 'Refer a friend or a team'
                : "Invite someone you've worked with!"}
            </SectionHeading>
            <Alert className={styles.alert}>
              <h4 className={styles.alertTitle}>
                {openMissionRole
                  ? `Earn tokens for inviting a builder to this mission`
                  : 'Earn tokens for inviting a friend'}
              </h4>
              <p className={styles.alertContent}>
                Do you know the perfect builder for one or more of these open
                roles? Refer a builder and earn tokens for every hour they work
                on the mission.
              </p>
            </Alert>
          </div>
          <form
            className={styles.contentInnerContainer}
            onSubmit={(e): void => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className={styles.modalMain}>
              {!openMissionRole && (
                <Invitation
                  shareLink={`invite.a.team/${auth.currentUser?.username}`}
                  title="Get your referral link"
                />
              )}
              {(currentUser.isAdmin || openMissionRole) && (
                <>
                  <div
                    style={{
                      background:
                        'linear-gradient(242.02deg, #00CE84 17.76%, #79CE00 90.52%)',
                      borderRadius: '8px',
                      padding: '16px',
                      color: '#fff',
                    }}
                  >
                    Share some details about your friend, and we'll give them
                    priority on the waitlist.
                  </div>
                  {!errorRes?.payload?.fullName && errorRes?.message && (
                    <div
                      style={{
                        marginTop: '16px',
                        background: '#f63131',

                        borderRadius: '8px',
                        padding: '16px',
                        color: '#fff',
                      }}
                    >
                      {error?.message}
                    </div>
                  )}
                  <OutlinedInput
                    placeholder={'Full name...'}
                    style={{ width: '100%', marginTop: '24px' }}
                    value={data.fullName}
                    required
                    error={!!errorRes?.payload?.fullName}
                    errorMessage={errorRes?.payload?.fullName}
                    disabled={loading === true}
                    autoFocus
                    onChange={(e): void =>
                      setData({ ...data, fullName: e.target.value })
                    }
                  />

                  <OutlinedInput
                    type="email"
                    placeholder={'Email...'}
                    style={{ width: '100%' }}
                    value={data.email}
                    required
                    disabled={loading === true}
                    onChange={(e): void =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                  <OutlinedInput
                    precursor="linkedin.com/in/"
                    type="text"
                    placeholder="username... (optional)"
                    name="linkedin"
                    value={data.linkedInUsername}
                    style={{ width: '100%' }}
                    disabled={loading === true}
                    onChange={(e): void =>
                      setData({
                        ...data,
                        linkedInUsername: parseLinkedInUsername(e.target.value),
                      })
                    }
                  />

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <img src={info} alt="info" />
                    <p
                      style={{
                        marginLeft: '16px',
                        textDecoration: data.autoApprove
                          ? 'line-through'
                          : 'none',
                      }}
                    >
                      You will be featured on their profile page.
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className={styles.modalSide}>
              {openMissionRole ? (
                <Card className={styles.openRole}>
                  <h4>{openMissionRole.category.title}</h4>
                  <h5>Open Role</h5>

                  <p>{openMissionRole.headline}</p>
                </Card>
              ) : (
                <div className={styles.connectionImage}>
                  <img src={inviteIllustration} alt="invite" />
                  <UserAvatar
                    src={props.currentUser.profilePictureURL}
                    containerStyle={{
                      width: '80px',
                      height: '80px',
                      borderRadius: '80px',
                      position: 'absolute',
                      top: 'calc(50% - 40px)',
                    }}
                    alt="profile"
                  />
                </div>
              )}
              {!openMissionRole && (
                <p className={styles.inviteOnlyText}>
                  The best teams in the world are formed with people who love
                  working together. Share your referral code with builders you'd
                  like to team up with on an A.Team mission.
                </p>
              )}
              {(currentUser.isAdmin || openMissionRole) && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ width: '280px' }}>
                    <CallToActionButton
                      type="submit"
                      className={styles.inviteButton}
                      disabled={!isOK}
                    >
                      Send Invite
                    </CallToActionButton>
                  </div>
                </div>
              )}

              {/*
              // This is temporary disabled until the new onboarding is live
              // https://buildateam.atlassian.net/browse/BX-3699

              {currentUser.isAdmin && (
                <div className={styles.adminApprove}>
                  <LabeledCheckboxInput
                    label="Approve as:"
                    margin="none"
                    checked={!!data.autoApprove}
                    onChange={(e) =>
                      setData({ ...data, autoApprove: e.target.checked })
                    }
                  />
                  <select
                    disabled={!data.autoApprove}
                    value={data.scrubbed || UserScrubbed.Verified}
                    onChange={(e) =>
                      setData({
                        ...data,
                        scrubbed: e.target.value as UserScrubbed,
                      })
                    }
                  >
                    {Object.values(UserScrubbed).map((value) => (
                      <option key={value} value={value}>
                        {UserScrubbedLabels[value]}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              */}
            </div>
          </form>
        </div>
      </Modal>
      <LoadingIndicator loading={loading} />
    </>
  );
}
