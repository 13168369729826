import Profile from '@src/stores/Profile/Profile';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface ProfileRatesViewProps {
  profile: Profile;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    marginBottom: 40,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 8,
    marginTop: 0,
  },
  desc: {
    color: '#62646A',
    fontSize: 12,
  },
  rate: {
    color: '#222222',
    background: '#F7F7F7',
    fontSize: 15,
    padding: '8px 12px',
  },
  rateWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
  },
});

const ProfileRatesView = ({
  profile,
}: ProfileRatesViewProps): JSX.Element | null => {
  const styles = useStyles();

  const minimumMonthlyRate = profile.minimumMonthlyRate || 0;
  const minimumHourlyRate = profile.minimumHourlyRate || 0;

  if (minimumMonthlyRate === 0 && minimumHourlyRate === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div>
        <h3 className={styles.title}>Rate</h3>
        <div className={styles.desc}>Other builders can not see your rate.</div>
      </div>

      <div className={styles.rateWrapper}>
        {minimumHourlyRate && (
          <div className={styles.rate}>
            My minimum hourly rate is ${minimumHourlyRate}/h
          </div>
        )}

        {minimumMonthlyRate && (
          <div className={styles.rate}>
            My minimum monthly rate is ${minimumMonthlyRate}/m
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileRatesView;
