import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import {
  Colors,
  SelectableTagList,
  Spacing,
  TextColors,
} from '@ateams/components';
import { Icon as UiIcon } from '@a_team/ui-components';
import useToggle from '@src/hooks/useToggle';
import cx from 'classnames';
import {
  BasicExperienceObject,
  ExperienceId,
  ProjectExperienceData,
} from '@a_team/models/dist/ExperienceObject';

const useStyles = createUseStyles({
  label: {
    display: 'flex',
    gap: 8,
    marginBottom: Spacing.small,
    alignItems: 'center',
  },
  closedSkills: {
    height: 104,
    overflow: 'hidden',
  },
});

export const Projects = ({
  selectedProjects,
  allProjects,
  onChange,
  onDetailsClick,
}: {
  selectedProjects: ExperienceId[];
  allProjects: (BasicExperienceObject & ProjectExperienceData)[];
  onChange: (val: string, selected: boolean) => void;
  onDetailsClick: (val: string) => void;
}): ReactElement => {
  const styles = useStyles();
  const PROJECTS_LIMIT = 3;
  const [isHidden, setIsHidden] = useToggle(true);
  const longList = allProjects.length > 10;

  const selected = selectedProjects
    .map((id) => allProjects.find((p) => p.eid === id)?.title)
    .filter((project) => !!project) as string[];

  return (
    <div>
      <label htmlFor="skills" className={styles.label}>
        <UiIcon size="md" name="pin" />
        Projects:{' '}
        <span style={{ color: TextColors.lighter }}>
          (Select up to {PROJECTS_LIMIT})
        </span>
      </label>
      <SelectableTagList
        square
        className={cx({ [styles.closedSkills]: longList && isHidden })}
        tagStyles={{ borderRadius: 8, padding: 10 }}
        limit={PROJECTS_LIMIT}
        color={'backgroundLight'}
        selectedColor={'success'}
        selectedItems={selected || []}
        items={allProjects.map((project) => project.title)}
        format={(project) => (
          <span>
            {project} |{' '}
            <span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const match = allProjects.find((p) => p.title === project);
                match && onDetailsClick(match.eid);
              }}
              style={{
                color: Colors.secondaryDark,
              }}
            >
              Details
            </span>
          </span>
        )}
        onChange={(val, selected) => {
          const match = allProjects.find((p) => p.title === val);
          if (match) {
            onChange(match.eid, selected);
          }
        }}
      />
      {longList && (
        <span style={{ color: Colors.secondaryDark }} onClick={setIsHidden}>
          {isHidden ? `Show all (${allProjects.length} projects)` : 'Close'}
        </span>
      )}
    </div>
  );
};
