import { ServiceAuth, ServiceEndpoint } from './utils';
import { QueryNextToken, QueryResult } from '@a_team/models/dist/misc';
import {
  TalentCategoryId,
  TalentSpecialization,
  TalentSpecializationId,
} from '@a_team/models/dist/TalentCategories';

export const BasePath = '/talent-specializations';

export interface TalentSpecializationsQueryData {
  readonly searchTerm?: string;
  readonly ids?: TalentSpecializationId[];
  readonly talentCategoryId?: TalentCategoryId;
  readonly exclude?: string;
}

export interface CreateTalentSpecializationData {
  name: string;
  talentCategoryIds: TalentCategoryId[];
}

export type UpdateTalentSpecializationData =
  Partial<CreateTalentSpecializationData>;

export default class TalentSpecializationsEndpoint extends ServiceEndpoint {
  public queryTalentSpecializations(
    auth: ServiceAuth,
    data: TalentSpecializationsQueryData,
    next?: QueryNextToken,
  ): Promise<QueryResult<TalentSpecialization>> {
    return this.fetch(auth, BasePath, { ...data, next });
  }

  public getTalentSpecializationsById(
    auth: ServiceAuth,
    talentSpecializationId: string,
  ): Promise<TalentSpecialization> {
    return this.fetch(
      auth,
      `${BasePath}/${talentSpecializationId}`,
      null,
      'get',
    );
  }

  public createTalentSpecializations(
    auth: ServiceAuth,
    data: CreateTalentSpecializationData,
  ): Promise<TalentSpecialization> {
    return this.fetch(auth, BasePath, null, 'post', data);
  }

  public updateTalentSpecializations(
    talentSpecializationId: string,
    auth: ServiceAuth,
    data: UpdateTalentSpecializationData,
  ): Promise<TalentSpecialization> {
    return this.fetch(
      auth,
      `${BasePath}/${talentSpecializationId}`,
      null,
      'put',
      data,
    );
  }

  public deleteTalentSpecializations(
    auth: ServiceAuth,
    talentSpecializationId: string,
  ): Promise<TalentSpecialization> {
    return this.fetch(
      auth,
      `${BasePath}/${talentSpecializationId}`,
      null,
      'delete',
    );
  }
}
