import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import Profile from '@src/stores/Profile/Profile';
import { useGetConnectionBetweenUsers } from '@src/rq/connections';
import ConnectionItem from './ConnectionItem';
import { isNonNullable } from '@src/helpers/sort';
import { observer } from 'mobx-react';

interface ConnectionSummaryBoxProps {
  profile: Profile;
  onClick: () => void;
}

const useStyles = createUseStyles({
  connectionSummary: ({ hasConnections }) => ({
    border: '1px solid #EFEFF0',
    borderRadius: '8px',
    background: 'none',
    padding: '0',
    margin: '0',
    cursor: hasConnections ? 'pointer' : 'default',
    color: 'inherit',
  }),
});

const ConnectionSummaryBox = ({
  profile,
  onClick,
}: ConnectionSummaryBoxProps): JSX.Element => {
  const hasConnections =
    profile.totalTeamUpConnections > 0 || profile.totalWorkConnections > 0;
  const styles = useStyles({ hasConnections });

  const [sampleUsers] = useMemo(() => {
    return [
      profile.data?.connectionsSummary?.sampleUsersToBeDisplayed.map(
        (user) => user.profilePictureURL,
      ),
    ].filter(isNonNullable);
  }, [
    profile.data.uid,
    profile.data?.connectionsSummary?.sampleUsersToBeDisplayed,
  ]);

  const { data, isLoading, error } = useGetConnectionBetweenUsers(
    profile.data.uid,
  );

  const connection = data?.work ?? data?.teamUp;

  // if loading or error don't show anything
  if (isLoading || error) {
    return <></>;
  }

  return (
    <button
      className={styles.connectionSummary}
      onClick={hasConnections ? onClick : undefined}
    >
      <ConnectionItem
        profile={profile}
        sampleUsersImageUrls={sampleUsers}
        connectionStatus={connection?.status}
      />
    </button>
  );
};

export default observer(ConnectionSummaryBox);
