import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Star } from '@src/components/Star';

type DivProps = JSX.IntrinsicElements['div'];

export interface Props extends DivProps {
  value?: number | null;
}

const useStyles = createUseStyles<Props>({
  wrapper: ({ value }) => ({
    background:
      typeof value === 'undefined'
        ? '#EEEEEE'
        : value && value < 3
        ? '#F63131'
        : value && value === 3
        ? '#FFC93E'
        : '#79CE00',
    color: '#fff',
    padding: '3px 10px 3px 10px',
    borderRadius: '5px',
    whiteSpace: 'pre',
  }),
  star: {
    marginRight: '4px',
  },
});

const RatingIndicator = (props: Props): ReactElement | null => {
  const { value, className, ...rest } = props;
  const styles = useStyles(props);
  return (
    <div className={cx(className, styles.wrapper)} {...rest}>
      <Star className={styles.star} />
      <span>{value || '–'}</span>
    </div>
  );
};

export default RatingIndicator;
