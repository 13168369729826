import React, {
  ChangeEvent,
  CSSProperties,
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { formatMinutesToTime } from '@src/helpers/time';
import { Colors } from '@ateams/components';
import { validateAndParseTime } from './utils';

export type OnTimeFieldChange = (
  event: ChangeEvent<HTMLInputElement>,
  totalMinutes: number,
) => void;

export interface TimeFieldProps {
  name?: string;
  onChange?: OnTimeFieldChange;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  defaultFocused?: boolean;
  error?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
}

const defaultProps: Partial<TimeFieldProps> = {
  style: {},
  error: false,
  disabled: false,
  defaultFocused: false,
};

const useStyles = createUseStyles({
  input: {
    border: '1px solid rgb(192, 192, 192)',
    width: '100%',
    outline: 'none',
    borderRadius: '0.5em',
    padding: '1em',
    minHeight: 56,
    marginTop: 24,
    textAlign: 'center',
    color: '#222',
    transition: 'all 0.5s ease 0s',
    '&:focus': {
      borderColor: '#000',
    },
  },
  error: {
    borderColor: `${Colors.danger} !important`,
    backgroundColor: 'rgb(255, 248, 248)',
    color: Colors.danger,
  },
  disabled: {
    pointerEvents: 'none',
    borderColor: 'rgb(239, 239, 240)',
    color: 'rgb(136, 136, 136)',
    background: '#fff',
  },
});

export const TimeField: FC<TimeFieldProps> = (props) => {
  const styles = useStyles();
  const [value, setValue] = useState(props.defaultValue);
  const inputEl = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    props.defaultFocused && inputEl.current.focus();
  }, []);

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    try {
      const newValue = event.target.value;
      const { totalMinutes } = validateAndParseTime(newValue);
      setValue(newValue);

      if (props.onChange) {
        props.onChange(event, totalMinutes);
        event.persist(); /** {@link https://deepscan.io/docs/rules/react-missing-event-persist} */
      }
    } catch (err) {
      // pass
    }
  };

  const formatInput = () => {
    const { totalMinutes } = validateAndParseTime(value as string);

    if (totalMinutes) {
      const formattedTime = formatMinutesToTime(totalMinutes);

      setValue(formattedTime);
    }
  };

  const onBlur = () => {
    try {
      formatInput();
    } catch (err) {
      // pass
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Escape'].includes(e.key)) {
      try {
        formatInput();

        if (props.onKeyUp) {
          props.onKeyUp(e);
          e.persist(); /** {@link https://deepscan.io/docs/rules/react-missing-event-persist} */
        }
      } catch (err) {
        // pass
      }
    }
  };

  return (
    <input
      ref={inputEl}
      type="text"
      className={cx(styles.input, props.className, {
        [styles.error]: props.error,
        [styles.disabled]: props.disabled,
      })}
      style={props.style}
      name={props.name}
      value={value}
      onChange={onChange}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      disabled={props.disabled}
      placeholder="HH:MM"
    />
  );
};

TimeField.defaultProps = defaultProps;
