import React, { useState, useRef, useEffect } from 'react';
import { useCommonStyles } from '../commonStyles';
import { createUseStyles } from 'react-jss';
import {
  FontSizes,
  FontWeights,
  Colors,
  BorderColors,
  Spacing,
  BorderRadius,
} from '@ateams/components';
import cn from 'classnames';
const useStyles = createUseStyles({
  fieldReadOnlyValue: {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.regular,
    lineHeight: '1.5em',
    color: Colors.dark,
    '&.expandable': {
      position: 'relative',
      '&.collapsed': {
        maxHeight: '4.5em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  border: {
    border: `1px solid ${BorderColors.light}`,
    width: '100%',
    height: 38,
    borderRadius: BorderRadius.default,
    display: 'flex',
    padding: `${Spacing.small}px`,
    alignItems: 'center',
    backgroundColor: Colors.backgroundLight,
    '&.expanded': {
      height: 'auto',
      alignItems: 'flex-start',
    },
  },
  seeMoreLink: {
    color: Colors.secondaryDark,
    cursor: 'pointer',
    fontSize: FontSizes.small,
    fontWeight: FontWeights.semiBold,
    marginTop: `${Spacing.xsmall}px`,
    display: 'block',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  valueAndLabelContainer: ({ expandable }: { expandable: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    ...(expandable && {
      gap: Spacing.small,
    }),
  }),
  container: ({ expandable }: { expandable: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
    ...(expandable && {
      gap: Spacing.small,
    }),
  }),
});

export interface FieldReadOnlyProps {
  label?: string;
  value: string;
  showBorder?: boolean;
  expandable?: boolean;
}

export const FieldReadOnly = ({
  label,
  value,
  showBorder,
  expandable,
}: FieldReadOnlyProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles({ expandable });
  const [isExpanded, setIsExpanded] = useState(false);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expandable && contentRef.current) {
      const element = contentRef.current;
      setShowSeeMore(element.scrollHeight > element.clientHeight);
    }
  }, [value, expandable]);

  const containerClassName = showBorder
    ? `${styles.border} ${isExpanded ? 'expanded' : ''}`
    : '';

  const valueClassName = `${styles.fieldReadOnlyValue} ${
    expandable ? 'expandable' : ''
  } ${!isExpanded && expandable ? 'collapsed' : ''}`;

  return (
    <div className={styles.container}>
      <div className={cn(styles.valueAndLabelContainer, containerClassName)}>
        {label && <div className={commonStyles.label}>{label}</div>}
        <div ref={contentRef} className={valueClassName}>
          {value}
        </div>
      </div>
      {expandable && showSeeMore && (
        <span
          className={styles.seeMoreLink}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? 'See less' : 'See more'}
        </span>
      )}
    </div>
  );
};
