import React, { ReactElement, ReactNode } from 'react';
import SectionHeading from '@src/components/SectionHeading';

interface Props {
  children?: ReactNode;
  title: string;
  subtitle?: string;
}

const TitledSection = (props: Props): ReactElement => {
  const { title, subtitle, children } = props;
  return (
    <>
      <SectionHeading style={{ marginTop: 45 }}>{title}</SectionHeading>
      {subtitle && <p>{subtitle}</p>}
      {children}
    </>
  );
};

export default TitledSection;
