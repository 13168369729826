import React, { ReactElement } from 'react';
import UserAvatar from '@src/components/UserAvatar';
import MissionRole from '@a_team/models/dist/MissionRole';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';

interface Props {
  isCurrentUser?: boolean;
  user: MissionRole['user'];
}

const useStyles = createUseStyles({
  avatar: {
    display: 'none',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    avatar: {
      display: 'flex',
    },
  },
});

export const TabLabel = (props: Props): ReactElement => {
  const styles = useStyles();

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <UserAvatar
        imageClassName={styles.avatar}
        size={24}
        containerStyle={{ marginRight: '8px' }}
        src={props.user?.username ? props.user?.profilePictureURL : undefined}
        label={props.isCurrentUser ? 'My Timesheet' : props.user?.fullName}
      />
    </div>
  );
};
