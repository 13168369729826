import React, { useCallback, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import {
  BorderRadius,
  FontSizes,
  FontWeights,
  SelectOption,
  Spacing,
  Tag,
} from '@ateams/components';
import clsx from 'clsx';
import { FieldDropdown } from '../general/FieldDropdown';
import { ExpertiseScoreOptions } from '@src/components/ScoreInput/Scores';
import { FieldTextarea } from '../general/FieldTextarea';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { RoleExpertiseScoreError } from '../../VettingFeedbackFormV2';
import {
  VettingProcessFeedbackRoleExpertiseScore,
  VettingProcessFeedbackRoleExpertiseScoreType,
} from '@ateams/api/dist/endpoints/vetting-process-feedback-v2';

const useStyles = createUseStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '200px 148px 1fr',
    gap: Spacing.medium,
    minHeight: '60px',
    paddingTop: Spacing.small,
  },
  primaryRoleTag: {
    background: '#E5FCFF',
  },
  roleTag: {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.medium,
    borderRadius: BorderRadius.medium,
    padding: Spacing.small,
    whiteSpace: 'nowrap',
  },
  roleTagText: {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.regular,
  },
  reasonField: {
    paddingTop: Spacing.small,
  },
}));

interface RoleExpertiseScoreItemProps {
  roleExpertiseScore: VettingProcessFeedbackRoleExpertiseScore;
  onRoleExpertiseScoreChange: (
    roleExpertiseScore: VettingProcessFeedbackRoleExpertiseScore,
  ) => void;
}

export const RoleExpertiseScoreItem = ({
  roleExpertiseScore,
  onRoleExpertiseScoreChange,
}: RoleExpertiseScoreItemProps) => {
  const styles = useStyles();
  const {
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const roleExpertiseScoresErrors = errors?.roleExpertiseScores as
    | FieldErrors<Record<string, RoleExpertiseScoreError>>
    | undefined;
  const roleExpertiseScoreError =
    roleExpertiseScoresErrors?.[roleExpertiseScore.roleId]?.score?.message;
  const roleExpertiseScoreReasonError =
    roleExpertiseScoresErrors?.[roleExpertiseScore.roleId]?.reason?.message;

  const expertiseScoreOptions: SelectOption[] =
    ExpertiseScoreOptions as unknown as SelectOption[];

  const selectedExpertiseScore = useMemo(
    () =>
      expertiseScoreOptions.find(
        (option) =>
          roleExpertiseScore.score >= 0 &&
          option.value.toString() === roleExpertiseScore.score.toString(),
      ),
    [expertiseScoreOptions, roleExpertiseScore.score],
  );

  const handleExpertiseScoreChange = useCallback(
    (option: SelectOption | null) => {
      if (!option) return;

      onRoleExpertiseScoreChange({
        ...roleExpertiseScore,
        score: parseInt(option.value),
      });
    },
    [onRoleExpertiseScoreChange, roleExpertiseScore.roleId],
  );

  const handleReasonChange = useCallback(
    (reason: string) => {
      onRoleExpertiseScoreChange({ ...roleExpertiseScore, reason });
    },
    [onRoleExpertiseScoreChange],
  );

  return (
    <div className={styles.container}>
      <div>
        <Tag
          className={clsx(
            styles.roleTag,
            roleExpertiseScore.roleType ===
              VettingProcessFeedbackRoleExpertiseScoreType.PRIMARY &&
              styles.primaryRoleTag,
          )}
        >
          <div className={styles.roleTagText}>
            <div>{roleExpertiseScore.roleName}</div>
          </div>
        </Tag>
      </div>

      <FieldDropdown
        label=""
        options={expertiseScoreOptions}
        value={selectedExpertiseScore}
        placeholder="Select"
        onChange={handleExpertiseScoreChange}
        error={roleExpertiseScoreError as string}
        clearErrors={clearErrors}
      />

      <div className={styles.reasonField}>
        <FieldTextarea
          value={roleExpertiseScore.reason}
          onChange={handleReasonChange}
          placeholder="Add a comment..."
          showLeftBorder={false}
          minLength={20}
          compactField
          error={roleExpertiseScoreReasonError as string}
          name={`roleExpertiseScores.${roleExpertiseScore.roleId}`}
          clearErrors={clearErrors}
        />
      </div>
    </div>
  );
};
