import { Tag } from '@ateams/components';
import { InvoiceStatus } from '@a_team/models/dist/InvoiceObject';
import React, { ReactElement } from 'react';

interface Props {
  invoiceStatus: InvoiceStatus | undefined;
  submitted: boolean;
  totalPayments: number;
  prepayEligible?: boolean;
}

export default function PaymentStatus(props: Props): ReactElement {
  switch (props.invoiceStatus) {
    case InvoiceStatus.Paid:
      return <Tag color="success">Paid</Tag>;

    case InvoiceStatus.Processing:
      return <Tag color="info">Processing</Tag>;

    default:
      return props.submitted ? (
        props.totalPayments ? (
          props.prepayEligible !== false ? (
            <Tag color="danger">Unpaid</Tag>
          ) : (
            <Tag color="regular">Awaiting Payment</Tag>
          )
        ) : (
          <Tag color="regular">Submitted</Tag>
        )
      ) : (
        <Tag color="warning">To Submit</Tag>
      );
  }
}
