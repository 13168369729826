import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { LeftArrow, LeftArrowLast, RightArrow, RightArrowLast } from './svgs';
import { PageBox } from './page-box';

export type OnPageClick = (page: number) => unknown;

export interface PageSelectProps {
  currentPage: number;
  pagesCount: number;
  onPageClick: OnPageClick;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const MAX_PAGES = 7;
const DOTS = '...';

/**
 * @example
 * // returns [1, 2, 3]
 * getPages(3, 1);
 * @example
 * // returns [1, '...', 10, 11, 12, 13, 14]
 * getPages(14, 13);
 * @example
 * // returns [1, '...', 10, 11, 12, '...', 100]
 * getPages(100, 11);
 */
function getPages(
  pagesCount: number,
  currentPage: number,
  maxPages = MAX_PAGES,
) {
  if (pagesCount <= maxPages) {
    return new Array(pagesCount).fill(null).map((_, i) => i + 1);
  }

  const pages = new Array(maxPages)
    .fill(null)
    .map((_, i) => currentPage + i - Math.floor(maxPages / 2));

  for (let i = 0; i < pages.length; i++) {
    const page = pages[i];

    if (page < 1) {
      pages[pages.length + i] = pages[pages.length - 1] + 1;
      pages.shift();
      i--;
    } else if (page > pagesCount) {
      pages.splice(i, 1);
      pages.unshift(pages[0] - 1);
      i--;
    }
  }

  return applyDotsOnEnds(pages, pagesCount);
}

function applyDotsOnEnds(pages: (string | number)[], pagesCount: number) {
  if (pages[0] !== 1) {
    pages[0] = 1;
    pages[1] = DOTS;
  }

  if (pages[pages.length - 1] !== pagesCount) {
    pages[pages.length - 1] = pagesCount;
    pages[pages.length - 2] = DOTS;
  }

  return pages;
}

export const PageSelect: React.FC<PageSelectProps> = ({
  currentPage,
  pagesCount,
  onPageClick,
}) => {
  const styles = useStyles();

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === pagesCount;

  const pages = useMemo(
    () => getPages(pagesCount, currentPage),
    [pagesCount, currentPage],
  );

  return (
    <div className={styles.container}>
      <PageBox
        onClick={isFirstPage ? undefined : () => onPageClick(1)}
        isSelected={false}
        isDisabled={isFirstPage}
      >
        <LeftArrowLast />
      </PageBox>
      <PageBox
        onClick={isFirstPage ? undefined : () => onPageClick(currentPage - 1)}
        isSelected={false}
        isDisabled={isFirstPage}
      >
        <LeftArrow />
      </PageBox>

      {pages.map((page, i) => (
        <PageBox
          key={`${page}-${i}`}
          onClick={
            typeof page === 'number' ? () => onPageClick(page) : undefined
          }
          isSelected={currentPage === page}
        >
          {page}
        </PageBox>
      ))}

      <PageBox
        onClick={isLastPage ? undefined : () => onPageClick(currentPage + 1)}
        isSelected={false}
        isDisabled={isLastPage}
      >
        <RightArrow />
      </PageBox>
      <PageBox
        onClick={isLastPage ? undefined : () => onPageClick(pagesCount)}
        isSelected={false}
        isDisabled={isLastPage}
      >
        <RightArrowLast />
      </PageBox>
    </div>
  );
};
