import axios from 'axios';
import {
  UPLOADCARE_API_BASE_URL,
  UPLOADCARE_ASSETS_BASE_URL,
  UPLOADCARE_PUBLIC_ACCESS_KEY,
} from '@src/config';
import { File } from '@src/components/FileDropArea';
import { logger } from '@sentry/utils';

interface UploadcareResponse {
  status: string;
  done: number;
  total: number;
  uuid: string;
  error: string;
}

export const uploadFile = (
  file: File,
  opts?: {
    onProgress?: (progress: number) => void;
    preview?: { width: number; height: number };
  },
): Promise<string> => {
  const data = new FormData();
  data.set('UPLOADCARE_PUB_KEY', UPLOADCARE_PUBLIC_ACCESS_KEY);
  data.set('UPLOADCARE_STORE', 'auto');
  data.set('file_name', file.name);
  data.append('file', file);

  return axios
    .post(`${UPLOADCARE_API_BASE_URL}base/`, data, {
      onUploadProgress: (progressEvent) =>
        opts?.onProgress && opts.onProgress(progressEvent.loaded),
    })
    .then(async (res) => {
      let url = `${UPLOADCARE_ASSETS_BASE_URL}${res.data.file}/`;

      if (opts?.preview) {
        url += `-/preview/${opts.preview.width}x${opts.preview.height}/`;
      }

      // Give it one second to Uploadcare to process the image
      // to avoid loading a broken image
      await new Promise((resolve) => setTimeout(resolve, 1000));

      return url;
    })
    .catch((err) => {
      throw err;
    });
};

const checkUrlUploadStatus = (token: string): Promise<UploadcareResponse> => {
  return axios
    .get(`${UPLOADCARE_API_BASE_URL}from_url/status/`, {
      params: { token: token },
    })
    .then((res) => res.data);
};

const recheckUploadStatus = (
  token: string,
  onProgress?: (progress: number, total: number) => void,
): Promise<string> => {
  return checkUrlUploadStatus(token).then((data) => {
    if (data.status !== 'success' && data.status !== 'error') {
      onProgress && data.done && onProgress(data.done, data.total);
      return recheckUploadStatus(token);
    } else {
      if (data.status === 'success') {
        return `${UPLOADCARE_ASSETS_BASE_URL}${data.uuid}/`;
      } else {
        throw data.error;
      }
    }
  });
};

export const uploadFileFromUrl = (
  url: string,
  onProgress?: (progress: number, total: number) => void,
): Promise<string> => {
  const params = {
    pub_key: UPLOADCARE_PUBLIC_ACCESS_KEY,
    source_url: url,
    store: 'auto',
  };

  return axios
    .get(`${UPLOADCARE_API_BASE_URL}from_url/`, { params })
    .then((res) => {
      return recheckUploadStatus(res.data.token, onProgress);
    })
    .then((content) => content)
    .catch((err) => {
      throw err;
    });
};

export const UCARE_CDN_HOST = 'ucarecdn.com';

export function isUploadCareURL(url: string | URL): boolean {
  if (typeof url === 'string') {
    try {
      url = new URL(url);
    } catch (error) {
      logger.error({ url }, 'Failed to parse upload care URL');
      return false;
    }
  }

  return url.host === UCARE_CDN_HOST;
}

/**
 * Adds/Changes preview size for an uploadcare url
 * {@link https://uploadcare.com/docs/transformations/image/resize-crop/#operation-preview}
 */
export function previewUploadCareImageUrl(
  url: string | URL,
  size: number,
): string {
  if (typeof url === 'string') {
    url = new URL(url);
  }

  let pathname = url.pathname;

  // Remove optionally existing preview
  pathname = pathname.replace(/\/-\/preview\/[0-9]+x[0-9]+/, '');

  const parts = pathname.split('/');

  // Append new size
  parts.splice(2, 0, `-/preview/${size}x${size}`);

  pathname = parts.join('/');
  url.pathname = pathname;

  return url.toString();
}
