import { EnrichedTeamNarrativesResponse } from '@a_team/models/dist/TeamGraphObject';
import { AdminBasicUserObject } from '@a_team/models/dist/UserObject';
import { Spacing, TextColors, FontSizes, Icon } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { narrativeDetailsForType } from './narrativeDetailsForType';

export type NarrativeItemType = keyof EnrichedTeamNarrativesResponse;

export interface Props {
  overlapText: string;
  overlapUsers: AdminBasicUserObject[];
  narrativeType: NarrativeItemType;
}

const AVATAR_SIZE = 20;

const useStyles = createUseStyles({
  narrativeContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  itemIcon: {
    marginRight: Spacing.small,
  },
  avatarList: {
    display: 'flex',
    marginLeft: Spacing.small,
    marginTop: 3,
  },
  teamNarrativeAvatar: {
    marginRight: Spacing.xsmall,
  },
  overlapText: {
    fontSize: FontSizes.xsmall,
    color: TextColors.regularLight,
    marginTop: Spacing.xsmall,
  },
});

export const NarrativeItem = (props: Props) => {
  const styles = useStyles();
  return (
    <span className={styles.narrativeContent}>
      <div>
        <Icon
          type={narrativeDetailsForType[props.narrativeType].icon}
          className={styles.itemIcon}
        />
        {narrativeDetailsForType[props.narrativeType].text}
        <p className={styles.overlapText}>{props.overlapText}</p>
      </div>

      <div className={styles.avatarList}>
        {props.overlapUsers.map((u) => (
          <UserAvatar
            containerClassName={styles.teamNarrativeAvatar}
            size={AVATAR_SIZE}
            src={u?.profilePictureURL}
          ></UserAvatar>
        ))}
      </div>
    </span>
  );
};
