import React, { ReactElement } from 'react';
import BasicLayout from '@src/layouts/Basic';

export default function OAuthView(): ReactElement {
  return (
    <BasicLayout title="OAuth Redirect">
      <p>Please wait..</p>
    </BasicLayout>
  );
}
