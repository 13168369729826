/**
 * This component is supposed to act like the default react-select Input component
 * Here we use regular html <input /> and not what react-select uses(react-input-autoresize)
 */
import React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { InputProps } from 'react-select';

const useStyles = createUseStyles({
  input: ({ isHidden }: InputProps) => ({
    label: 'input',
    background: 0,
    border: 0,
    fontSize: 'inherit',
    opacity: isHidden ? 0 : 1,
    outline: 0,
    padding: 0,
    color: 'inherit',
    zIndex: 10,
  }),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RegularSelectInput: React.FC<any> = (props) => {
  const styles = useStyles(props);
  // we want to remove all the react-select props so we can spread the rest and avoid warnings
  const {
    className,
    clearValue,
    cx: inputCx,
    getStyles,
    getValue,
    hasValue,
    isMulti,
    isRtl,
    isHidden,
    isDisabled,
    innerRef,
    options,
    selectOption,
    selectProps,
    setValue,
    theme,
    ...innerProps
  } = props;

  return (
    <input
      className={cx(inputCx({ input: true, className }), styles.input)}
      {...innerProps}
    />
  );
};
