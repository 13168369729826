import React, { ReactElement, useCallback, useState } from 'react';
import SectionHeading from '@src/components/SectionHeading';
import Modal, { ModalBasicProps } from '..';
import { Button as CallToActionButton, Checkbox } from '@ateams/components';
import { createUseStyles } from 'react-jss';

interface Props extends ModalBasicProps {
  onConfirm(onlyCurrent: boolean): void;
}

const useStyles = createUseStyles({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '32px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  button: {
    marginRight: '24px',
  },
});

export function InvoiceGenerateModal(props: Props): ReactElement {
  const { onConfirm, onClose, ...left } = props;
  const styles = useStyles();

  const [onlyCurrent, setOnlyCurrent] = useState(false);

  const handleConfirm = useCallback(
    () => onConfirm(onlyCurrent),
    [onlyCurrent, onConfirm],
  );

  return (
    <Modal onClose={onClose} hideCloseButton {...left}>
      <div style={{ color: 'black' }}>
        <SectionHeading isFirst>Generate Invoice</SectionHeading>

        <Checkbox
          name="generateAll"
          label="Generate also all other mission invoices for this client"
          checked={!onlyCurrent}
          onChange={(e) => setOnlyCurrent(!e.target.checked)}
        />

        <div className={styles.buttonsContainer}>
          <CallToActionButton
            color="regular"
            onClick={onClose}
            className={styles.button}
          >
            Cancel
          </CallToActionButton>
          <CallToActionButton onClick={handleConfirm} className={styles.button}>
            Confirm
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
}
