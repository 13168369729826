import React from 'react';
import { useCommonStyles } from '../commonStyles';
import { createUseStyles } from 'react-jss';
import { FieldTextarea } from '../general/FieldTextarea';
import { Spacing, Colors, BorderRadius } from '@ateams/components';
import { useFormContext } from 'react-hook-form';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
    backgroundColor: Colors.backgroundLight,
    borderRadius: BorderRadius.xLarge,
  },
  impressionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    padding: Spacing.medium,
  },
  divider: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: Spacing.small,
    marginBottom: Spacing.small,
    borderTop: `1px solid ${Colors.regularLight}`,
  },
});

export interface OverallImpressionSectionProps {
  isViewMode: boolean;
}

export const OverallImpressionSection: React.FC<
  OverallImpressionSectionProps
> = ({ isViewMode }) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const {
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const callSummary = watch('callSummary');
  const vetterNotes = watch('vetterNotes');

  const handleCallSummaryChange = (value: string) => {
    setValue('callSummary', value);
  };

  const handleVetterNotesChange = (value: string) => {
    setValue('vetterNotes', value);
  };

  return (
    <div className={commonStyles.section}>
      <div className={commonStyles.sectionTitle}>Overall impression</div>
      <div className={styles.container}>
        <div className={styles.impressionsContainer}>
          <FieldTextarea
            label="Call summary *"
            placeholder="Enter your thoughts after the call"
            value={callSummary}
            onChange={handleCallSummaryChange}
            minLength={280}
            error={errors.callSummary?.message as string}
            name="callSummary"
            clearErrors={clearErrors}
            disabled={isViewMode}
          />
          <hr className={styles.divider} />
          <FieldTextarea
            label="Your notes *"
            placeholder="Enter overall impression"
            value={vetterNotes}
            onChange={handleVetterNotesChange}
            minLength={280}
            error={errors.vetterNotes?.message as string}
            name="vetterNotes"
            clearErrors={clearErrors}
            disabled={isViewMode}
          />
        </div>
      </div>
    </div>
  );
};
