/**
 * Email templates can be found on the following table: {@link https://www.notion.so/ateams/Revamping-evaluation-emails-3de06263cccc419e8b6169859f370488}
 */

import { VettingType } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { VettingProcessEmailTemplates } from '@a_team/models/dist/vetting-processes/emails';
import {
  createInternalNonTechnicalFirstFollowupEmailTemplate,
  createInternalNonTechnicalInitialEmailTemplate,
} from './edits/internal-non-technical';
import {
  createInternalTechnicalFirstFollowupEmailTemplate,
  createInternalTechnicalInitialEmailTemplate,
} from './edits/internal-technical';
import {
  createSelectionTeamFirstFollowupTemplate,
  createSelectionTeamInitialEmailTemplate,
  createSelectionTeamScheduleCallTemplate,
  createSelectionTeamSecondFollowupTemplate,
} from './edits/selection-team';

export interface EmailTemplate {
  subject: string;
  body: string;
  templateType: VettingProcessEmailTemplates;
  sendInThread: boolean;
}

export type EmailTemplateGenerator = (newEvaluation: boolean) => EmailTemplate;

const EmailTemplateToGenerator: Record<
  VettingProcessEmailTemplates,
  EmailTemplateGenerator
> = {
  // Selection Team
  [VettingProcessEmailTemplates.STInitial]:
    createSelectionTeamInitialEmailTemplate,
  [VettingProcessEmailTemplates.STFollowUp]:
    createSelectionTeamFirstFollowupTemplate,
  [VettingProcessEmailTemplates.STSecondFollowUp]:
    createSelectionTeamSecondFollowupTemplate,
  [VettingProcessEmailTemplates.STScheduleCall]:
    createSelectionTeamScheduleCallTemplate,

  // Internal Technical
  [VettingProcessEmailTemplates.InternalTechnicalInitial]:
    createInternalTechnicalInitialEmailTemplate,
  [VettingProcessEmailTemplates.InternalTechnicalFollowUp]:
    createInternalTechnicalFirstFollowupEmailTemplate,

  // Internal Non-Technical
  [VettingProcessEmailTemplates.InternalNonTechnicalInitial]:
    createInternalNonTechnicalInitialEmailTemplate,
  [VettingProcessEmailTemplates.InternalNonTechnicalFollowUp]:
    createInternalNonTechnicalFirstFollowupEmailTemplate,
};

export function createEmailTemplate(
  template: VettingProcessEmailTemplates,
  newEvaluation: boolean,
): EmailTemplate {
  return EmailTemplateToGenerator[template](newEvaluation);
}

export function getDefaultEmailTemplate(
  vettingType: VettingType,
  emailsSent: number,
  hasSubmittedPreVettingForm?: boolean,
) {
  switch (vettingType) {
    case VettingType.SelectionTeam:
      if (hasSubmittedPreVettingForm) {
        return VettingProcessEmailTemplates.STScheduleCall;
      }
      if (emailsSent === 0) {
        return VettingProcessEmailTemplates.STInitial;
      }
      if (emailsSent === 1) {
        return VettingProcessEmailTemplates.STFollowUp;
      }
      if (emailsSent === 2) {
        return VettingProcessEmailTemplates.STSecondFollowUp;
      }

      return VettingProcessEmailTemplates.STScheduleCall;
    case VettingType.InternalTechnical:
      return emailsSent === 0
        ? VettingProcessEmailTemplates.InternalTechnicalInitial
        : VettingProcessEmailTemplates.InternalTechnicalFollowUp;
    case VettingType.InternalNonTechnical:
      return emailsSent === 0
        ? VettingProcessEmailTemplates.InternalNonTechnicalInitial
        : VettingProcessEmailTemplates.InternalNonTechnicalFollowUp;
    default:
      throw new Error('Invalid vetting type');
  }
}
