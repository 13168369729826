import React, { ReactElement } from 'react';
import { Card } from '@ateams/components';
import TextButton from '@src/components/TextButton';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';

interface Props {
  pendingContracts: number;
  onCardClick: () => void;
}

const useStyles = createUseStyles({
  card: {
    display: 'flex',
    flex: '0 0 100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    cursor: 'pointer',
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
    marginBottom: 4,
  },
  details: {
    display: 'flex',
    color: '#62646A',
    fontWeight: 400,
    fontSize: 15,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    card: {
      flex: '0 0 430px',
    },
  },
});

export const PendingContractsCard = (props: Props): ReactElement => {
  const styles = useStyles();
  const { pendingContracts, onCardClick } = props;

  return (
    <Card className={styles.card} onClick={onCardClick}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h5 className={styles.title}>Service Agreement</h5>
        <div className={styles.details}>
          <span>{pendingContracts} contracts pending</span>
        </div>
      </div>
      <TextButton highlight onClick={() => onCardClick}>
        Open
      </TextButton>
    </Card>
  );
};
