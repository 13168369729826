import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import UserAvatar from '@src/components/UserAvatar';
import { UserBadge } from '@a_team/models/dist/UserObject';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface Props {
  username: string;
  fullName: string;
  profilePictureURL: string;
  badges: UserBadge[];
  role: string;
  isLoading?: boolean;
}

const useStyles = createUseStyles({
  listItem: {
    listStyle: 'none',
    margin: 0,
    cursor: 'pointer',
    maxWidth: 650,
    paddingTop: 16,
    alignItems: 'center',
  },
  userTitles: {
    paddingLeft: '1em',
  },
  userDetail: {
    fontSize: 14,
  },
  userAvatarSkeleton: {
    width: 46,
    height: 46,
    lineHeight: 1,
  },
});

const TargeterSuggestedTeamCardListItem = (props: Props): ReactElement => {
  const styles = useStyles();
  const { username, fullName, profilePictureURL, badges, role, isLoading } =
    props;

  return (
    <li className={styles.listItem}>
      <div style={{ display: 'flex' }}>
        {isLoading ? (
          <Skeleton
            circle
            width="100%"
            height="100%"
            containerClassName={styles.userAvatarSkeleton}
          />
        ) : (
          <a
            href={`/${username}`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <UserAvatar src={profilePictureURL} badges={badges} size={46} />
          </a>
        )}
        <div className={styles.userTitles}>
          <div style={{ fontWeight: 'bold' }}>
            {isLoading ? <Skeleton width={120} height={22} /> : fullName}
          </div>
          <div className={styles.userDetail}>
            {isLoading ? <Skeleton width={90} height={22} /> : role}
          </div>
        </div>
      </div>
    </li>
  );
};

export default TargeterSuggestedTeamCardListItem;
