import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useCommonStyles } from '../commonStyles';
import { Button, Checkbox } from '@a_team/ui-components';
import { useMutationOptoutCalendar } from '@src/rq/profile';
import MessageSent from '../ReasonsModal/svgs/sent.svg';
import { NoCalendarReason } from '@a_team/models/dist/UserObject';
import InterviewAvailability from '../InterviewAvailability';
import { createUseStyles } from 'react-jss';

interface SimpleOptoutProps {
  mainReason: NoCalendarReason;
  reasonText?: string;
  onSuccess: () => void;
  onClose: () => void;
}

const useStyles = createUseStyles({
  listItem: {
    marginTop: 8,
    borderRadius: 16,
    backgroundColor: '#F9FAFC',
    padding: 24,
    display: 'flex',
    gap: 16,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F0F2F7',
    },
    '&:focus-visible': {
      outline: '2px solid #007AFF',
      outlineOffset: '2px',
    },
  },
  checkboxWrapper: {
    pointerEvents: 'none',
    '& > div': {
      marginTop: 2,
    },
  },
});

export const SimpleOptout = (props: SimpleOptoutProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const [interviewOptout, setInterviewOptout] = useState(false);
  const [autoAvailabilityOptout, setAutoAvailabilityOptout] = useState(false);
  const [showSetAvailability, setShowSetAvailability] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { mutate: optoutCalendar } = useMutationOptoutCalendar();

  useEffect(() => {
    window.onmessage = function (e) {
      if (e.data === 'USER_AVAILABILITY_UPDATED') {
        handleAvailabilitySet();
      }
    };
  }, []);

  const handleAvailabilitySet = () => {
    optoutCalendar({
      data: {
        // skipped because now we don't ask for a reason
        mainReason: NoCalendarReason.NoReason,
        reasonText: props.reasonText,
      },
      onSuccess: () => setShowSuccess(true),
    });
  };

  if (showSuccess) {
    return (
      <>
        <div className={commonStyles.hero}>
          <img
            src={MessageSent}
            className={commonStyles.heroImg}
            alt="Message Sent"
          />
          <h4 className={commonStyles.modalTitle}>
            Thanks for setting your availability!
          </h4>
          <p className={cx(commonStyles.heroCopy, 'lessPadding')}>
            You can update it any time on the interview page in settings.
          </p>
        </div>
        <div className={commonStyles.actions} style={{ paddingTop: 32 }}>
          <Button
            className={commonStyles.closeBtn}
            variant="main"
            size="sm"
            onClick={props.onSuccess}
          >
            Done
          </Button>
        </div>
      </>
    );
  }

  if (showSetAvailability) {
    return (
      <InterviewAvailability
        title="Confirm your working hours"
        copy="Confirm when you are available for mission interviews. Clients can schedule interviews during the times you specify below for any mission you’ve applied to."
        fullWidth
      />
    );
  }

  return (
    <>
      <div className={commonStyles.hero}>
        <h4 className={commonStyles.modalTitle}>
          By not connecting my calendar, I understand:
        </h4>
      </div>
      <div className={commonStyles.howtoWrap}>
        <div
          className={styles.listItem}
          onClick={() => setInterviewOptout(!interviewOptout)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              setInterviewOptout(!interviewOptout);
            }
          }}
          role="checkbox"
          aria-checked={interviewOptout}
          tabIndex={0}
        >
          <span className={styles.checkboxWrapper}>
            <Checkbox
              checked={interviewOptout}
              onChange={() => setInterviewOptout(!interviewOptout)}
            />
          </span>
          <div>
            <h5 className={commonStyles.panelTitle}>
              My profile will not be eligible for direct interview booking
            </h5>
            <p className={commonStyles.panelCopy}>
              Without a connected calendar, clients can only schedule interviews
              with you for missions you've applied to. Your profile won't be
              eligible for direct interview requests from clients that find your
              profile through search and discovery.{' '}
              <a
                className={commonStyles.linkButtonLight}
                href="https://guide.a.team/getting-started/getting-discovered-selected-for-interviews"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
            </p>
          </div>
        </div>

        <div
          className={styles.listItem}
          onClick={() => setAutoAvailabilityOptout(!autoAvailabilityOptout)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              setAutoAvailabilityOptout(!autoAvailabilityOptout);
            }
          }}
          role="checkbox"
          aria-checked={autoAvailabilityOptout}
          tabIndex={0}
        >
          <span className={styles.checkboxWrapper}>
            <Checkbox
              checked={autoAvailabilityOptout}
              onChange={() =>
                setAutoAvailabilityOptout(!autoAvailabilityOptout)
              }
            />
          </span>
          <div>
            <h5 className={commonStyles.panelTitle}>
              It's my responsibility to keep my interview availability up to
              date
            </h5>
            <p className={commonStyles.panelCopy}>
              When you apply to a mission, companies can book an interview with
              you at any time during the working hours you set.
            </p>
          </div>
        </div>

        <div className={commonStyles.actions} style={{ paddingTop: 32 }}>
          <Button
            className={commonStyles.closeBtn}
            variant="secondary"
            size="sm"
            onClick={props.onClose}
          >
            Back
          </Button>
          <Button
            disabled={!interviewOptout || !autoAvailabilityOptout}
            className={commonStyles.primaryButton}
            variant="main"
            size="sm"
            onClick={() => setShowSetAvailability(true)}
          >
            Set availability
          </Button>
        </div>
      </div>
    </>
  );
};
