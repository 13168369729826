import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { SelectionTeamOnboardingPlanMilestone } from '@a_team/models/dist/selection-team-onboarding';
import { Colors, Icon, IconType, Spacing } from '@ateams/components';
import { InputContainer } from '@src/views/VettingFeedbackForm/components/input-container';
import {
  NumberInput,
  TextInput,
  TextInputProps,
} from '@src/views/VettingFeedbackForm/components/text-input';
import { Card } from '@src/views/VettingFeedbackForm/components/card';

export interface MilestoneInputProps {
  milestone: SelectionTeamOnboardingPlanMilestone;
  milestoneIndex: number;
  onChange: OnMilestoneInputChange;
  onRemove: OnRemoveMilestone;
  className?: string;
  isDisabled?: boolean;
}

export type OnMilestoneInputChange = (
  milestone: SelectionTeamOnboardingPlanMilestone,
  milestoneIndex: number,
) => void;

export type OnRemoveMilestone = (milestoneIndex: number) => void;

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.medium,
    backgroundColor: Colors.backgroundWhite,
  },
  marginLeft: {
    marginLeft: Spacing.medium,
  },
  inputContainer: {
    width: '180px',
  },
  noteInputContainer: {
    width: '400px',
  },
  trashIcon: ({ isDisabled }: MilestoneInputProps) => ({
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  }),
});

export const MilestoneInput: React.FC<MilestoneInputProps> = (props) => {
  const styles = useStyles(props);

  const onMinInterviewsChange = (minInterviews?: number) => {
    props.onChange(
      {
        ...props.milestone,
        minInterviews,
      },
      props.milestoneIndex,
    );
  };

  const onMinTimeInMonthsChange = (minTimeInMonths?: number) => {
    props.onChange(
      {
        ...props.milestone,
        minTimeInDays:
          typeof minTimeInMonths === 'number'
            ? minTimeInMonths * 30
            : undefined,
      },
      props.milestoneIndex,
    );
  };

  const onNoteChange: TextInputProps['onChange'] = (e) => {
    props.onChange(
      {
        ...props.milestone,
        note: e.target.value,
      },
      props.milestoneIndex,
    );
  };

  const onRemove = () => {
    if (props.isDisabled) {
      return;
    }

    props.onRemove(props.milestoneIndex);
  };

  return (
    <Card className={cx(styles.container, props.className)}>
      <InputContainer label={'Milestone'} className={styles.inputContainer}>
        {props.milestoneIndex}
      </InputContainer>

      <InputContainer
        label={'Number of interviews'}
        className={styles.inputContainer}
      >
        <div className={styles.marginLeft}>
          <NumberInput
            value={props.milestone.minInterviews}
            onChange={onMinInterviewsChange}
            withLine
            disabled={props.isDisabled}
          />
        </div>
      </InputContainer>

      <InputContainer
        label={'Number of months'}
        className={styles.inputContainer}
      >
        <div className={styles.marginLeft}>
          <NumberInput
            value={
              typeof props.milestone.minTimeInDays === 'number'
                ? Math.round(props.milestone.minTimeInDays / 30)
                : undefined
            }
            onChange={onMinTimeInMonthsChange}
            withLine
            disabled={props.isDisabled}
          />
        </div>
      </InputContainer>

      <InputContainer label={'Note'} className={styles.noteInputContainer}>
        <div className={styles.marginLeft}>
          <TextInput
            value={props.milestone.note}
            onChange={onNoteChange}
            withLine
            disabled={props.isDisabled}
          />
        </div>
      </InputContainer>

      <Icon
        type={IconType.TrashRed}
        onClick={onRemove}
        className={styles.trashIcon}
      />
    </Card>
  );
};
