import { MissionAdminRole } from '@a_team/models/dist/MissionRole';
import { round } from 'lodash';

const WEEKS_PER_MONTH = 4.33;
const MIN_RATE_MULTIPLIER = 0.75;
const MAX_RATE_MULTIPLIER = 0.85;
const ROUND_TO = 100;
const HOURS_PER_WEEK = 40;

export const getDefaultMonthlyRatesForRole = (
  role: MissionAdminRole,
): Pick<
  MissionAdminRole,
  'builderMonthlyRateMin' | 'builderMonthlyRateMax'
> => {
  if (
    role.builderMonthlyRateMin ||
    role.builderMonthlyRateMax ||
    !role.builderRateMin ||
    !role.builderRateMax
  ) {
    return {};
  }
  return {
    builderMonthlyRateMin: round(
      hourlyRateToMonthlyRate(role.builderRateMin, 'min') || 0,
      ROUND_TO,
    ),
    builderMonthlyRateMax: round(
      hourlyRateToMonthlyRate(role.builderRateMax, 'max') || 0,
      ROUND_TO,
    ),
  };
};

export const hourlyRateToMonthlyRate = (
  hourlyRate: number,
  multiplier?: 'max' | 'min',
): number => {
  const multiplierValue = multiplier
    ? multiplier === 'max'
      ? MAX_RATE_MULTIPLIER
      : MIN_RATE_MULTIPLIER
    : 1;
  return hourlyRate * HOURS_PER_WEEK * WEEKS_PER_MONTH * multiplierValue;
};

export const monthlyRateToHourlyRate = (
  monthlyRate: number,
  multiplier?: 'max' | 'min',
): number => {
  const multiplierValue = multiplier
    ? multiplier === 'max'
      ? MAX_RATE_MULTIPLIER
      : MIN_RATE_MULTIPLIER
    : 1;
  return monthlyRate / (HOURS_PER_WEEK * WEEKS_PER_MONTH * multiplierValue);
};

export const getMissionTitle = (missionTitle: string | undefined) => {
  if (!missionTitle) return '';
  const parts = missionTitle.split(':');
  return parts.length > 1 ? parts.slice(1).join(':').trim() : missionTitle;
};
