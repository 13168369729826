import cx from 'classnames';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useStylesDiscoveryUserCard } from './DiscoveryUserCardV2';

interface DiscoveryUserCardSkeletonProps {
  className?: string;
}

const DiscoveryUserCard = ({
  className,
}: DiscoveryUserCardSkeletonProps): JSX.Element => {
  const styles = useStylesDiscoveryUserCard({});

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.content}>
        <div className={styles.userInfo}>
          <Skeleton width={108} height={108} circle />

          <div className={styles.header}>
            <Skeleton width={108} />
          </div>

          <div className={styles.fullName}>
            <Skeleton width={150} />
          </div>

          <div className={styles.mainRole}>
            <Skeleton width={150} />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          gap: 8,
          justifyContent: 'center',
        }}
      >
        <Skeleton width={120} height={40} />
        <Skeleton width={120} height={40} />
      </div>
    </div>
  );
};

export default DiscoveryUserCard;
