import { Icon, IconType } from '@ateams/components';
import React, { ReactElement } from 'react';

export const TooltipContent: Record<
  | 'teamUp'
  | 'pitch'
  | 'clientQuestion'
  | 'rate'
  | 'profileRates'
  | 'missionRoleRates',
  { label: string; component: ReactElement }
> = {
  teamUp: {
    label: 'Benefits of teaming up',
    component: (
      <>
        <h3>Apply with teammates</h3>
        <p>
          Your application will be considered both individually and with your
          teammates.
        </p>
        <ul>
          <li>
            <h4>Enhanced Visibility</h4>
            <p>
              Stand out as a team that's ready to work together and highly
              compatible, boosting your visibility and chances of being
              selected.
            </p>
          </li>

          <li>
            <h4>Work with people you like</h4>
            <p>
              Team up with friends you like working with and get support from
              teammates when challenges arise.
            </p>
          </li>

          <li>
            <h4>Improved Skillset</h4>
            <p>
              Diverse skills within your team lead to innovative solutions and a
              higher likelihood of delivering successful outcomes, making teams
              a preferred choice for companies.
            </p>
          </li>
        </ul>
      </>
    ),
  },
  pitch: {
    label: 'How to stand out',
    component: (
      <>
        <h3>Use this section to set yourself apart from other builders.</h3>
        <ul>
          <li>
            <h4>
              How are your skills and experience relevant to this mission?
            </h4>
            <p>
              Elaborate on how your skills specifically align with this mission,
              diving deeper than surface-level mentions.
            </p>
            <p>
              For instance, instead of saying "Experienced in project
              management," you might share "Led a team of 5 in developing a
              successful mobile app within budget constraints, resulting in a
              30% user increase in 3 months."
            </p>
          </li>
        </ul>
      </>
    ),
  },
  clientQuestion: {
    label: 'How to answer company questions',
    component: (
      <>
        <h3>Mastering company questions</h3>
        <p>
          These questions are added by the company. The answers provide a unique
          insight into builders fit and companies rely on these answers when
          making the final decision.
        </p>
        <ul>
          <li>
            <h4>Highlight the important things</h4>
            <p>
              Avoid brief, vague responses. Instead of "Worked in data
              management," specify "Managed datasets of over 100,000 records at
              Acme Inc., optimizing retrieval speed by 40% in my first three
              weeks."
            </p>
          </li>
          <li>
            <h4>Be thorough but concise</h4>
            <p>
              Remember, your responses directly influence the company's
              decision. Showcase your strengths concisely but thoroughly.
            </p>
          </li>
        </ul>
      </>
    ),
  },
  rate: {
    label: 'Tips for setting your rate',
    component: (
      <>
        <h3>Tips for setting your rate</h3>
        <p>
          Companies set the preferred rate range in accordance with their budget
          and the guidance from the A.Team platform based on rates from similar
          roles in past missions.
        </p>
        <ul>
          <li>
            <h4>How to determine your rate?</h4>
            <p>
              Your rate should reflect your skills, experience, and market
              demand. You can use the preferred rate range to see the company's
              budget to help guide your decision.
            </p>
          </li>
          <li>
            <h4>What if my rate’s above the range?</h4>
            <p>If your desired rate exceeds the preferred range, consider:</p>
            <ol>
              <li>
                Applying anyway, if you believe your unique skills justify the
                higher rate
              </li>
              <li>
                Adjusting your rate for this specific mission if it aligns with
                your passion or long-term goals
              </li>
              <li>
                Searching for missions that are more in line with your rate
              </li>
            </ol>
          </li>
        </ul>
      </>
    ),
  },
  profileRates: {
    label: 'Learn about rates',
    component: (
      <>
        <h3>Hourly Rate</h3>
        <ul>
          <li>
            <h4>What is hourly rate?</h4>
            <p>
              This model involves payment for each hour worked, with the total
              hours varying based on the company's needs. It allows for a
              flexible schedule but results in fluctuating income, reflecting
              the actual hours worked in each pay period.
            </p>
          </li>
          <li>
            <h4>Benefits</h4>
            <p>
              You have the ability to work variable hours, which can be
              beneficial if you prefer a flexible schedule or have other
              commitments.
            </p>
          </li>
        </ul>
        <h3>Monthly Rate</h3>
        <ul>
          <li>
            <h4>What is monthly rate?</h4>
            <p>
              In this setup, you commit to a steady 40-hour work week and
              receive a consistent monthly salary. This offers predictability in
              both workload and earnings, ideal for those seeking regularity and
              financial stability.
            </p>
          </li>
          <li>
            <h4>Benefits</h4>
            <p>
              A fixed engagement ensures consistent income for easier financial
              planning and offers a stable, predictable workload for improved
              work-life balance and reduced stress.
            </p>
          </li>
        </ul>
        <a rel="noreferrer" target="_blank" href={'http://guide.a.team'}>
          <Icon type={IconType.BookOpenWhite} size="large" />
          <div>
            <h4>Learn how to set your rates</h4>
            <span>
              Open A.Guide{' '}
              <Icon type={IconType.LinkArrowLeftWhite} size="smaller" />
            </span>
          </div>
        </a>
      </>
    ),
  },
  missionRoleRates: {
    label: 'Learn about rates',
    component: (
      <>
        <h3>Tips for setting your rate</h3>
        <p>
          Companies set the preferred rate range in accordance with their budget
          and the guidance from the A.Team platform based on rates from similar
          roles in past missions.
        </p>
        <ul>
          <li>
            <h4>How to determine your rate?</h4>
            <p>
              Your rate should reflect your skills, experience, the mission
              requirements, and market demand. On most missions you can use the
              company's budget to help guide your decision.{' '}
            </p>
          </li>
          <li>
            <h4>What’s the difference between hourly and monthly rates?</h4>
            <p>
              Hourly rates are missions with variable time commitments based on
              company needs. Monthly rates are retainer missions where you’re
              guaranteed full time work and a fixed monthly income for a minimum
              of one month, but often longer.
            </p>
          </li>
        </ul>
        <a
          rel="noreferrer"
          target="_blank"
          href={'https://guide.a.team/missions/choosing-your-rate'}
        >
          <Icon type={IconType.BookOpenWhite} size="large" />
          <div>
            <h4>Learn how to set your rates</h4>
            <span>
              Open A.Guide{' '}
              <Icon type={IconType.LinkArrowLeftWhite} size="smaller" />
            </span>
          </div>
        </a>
      </>
    ),
  },
};
