import React, { ReactNode } from 'react';
import { Button } from '@a_team/ui-components';
import { useCommonStyles } from '../../commonStyles';
import { ConnectCalendarButton } from '../../ConnectCalendarButton';

interface HowToConnectProps {
  title: ReactNode;
  children: ReactNode;
  onClose: () => void;
  onSupport: () => void;
}

export const HowToConnect = (props: HowToConnectProps) => {
  const styles = useCommonStyles();

  return (
    <>
      <div className={styles.hero}>{props.title}</div>
      <div className={styles.howtoBody}>
        {props.children}
        <div
          className={styles.actions}
          style={{ paddingTop: 32, paddingBottom: 32 }}
        >
          <Button
            className={styles.closeBtn}
            variant="secondary"
            size="sm"
            onClick={props.onClose}
          >
            Back
          </Button>
          <ConnectCalendarButton />
        </div>
        <div className={styles.passive}>
          <button className={styles.linkButtonLight} onClick={props.onSupport}>
            Having Issues connecting?{' '}
            <span style={{ fontWeight: 500 }}>Get support</span>
          </button>
        </div>
      </div>
    </>
  );
};
