import { action, observable } from 'mobx';
import { Stores } from '@src/stores/index';

import { apiEvaluationSettings, apiUsers } from '@src/logic/services/endpoints';
import { EvaluationSettingsObject } from '@a_team/models/dist/EvaluationSettingsObject';
import { CalComBookingFrequency } from '@a_team/models/dist/vetting-processes/calcom';

export interface EvaluationSettingsUserStoreData {
  settings?: EvaluationSettingsObject;
  dataLoaded?: boolean;
}

export default class EvaluationSettingsUserStore
  implements EvaluationSettingsUserStoreData
{
  @observable
  public dataLoaded: EvaluationSettingsUserStoreData['dataLoaded'] = false;
  @observable
  settings: EvaluationSettingsUserStoreData['settings'] = {};
  protected authStore: Stores['auth'];

  public constructor(rootStore: Stores) {
    this.authStore = rootStore.auth;
  }

  public serialize(): EvaluationSettingsUserStoreData {
    return {
      settings: this.settings,
      dataLoaded: this.dataLoaded,
    };
  }

  @action setEvaluationSettings = (
    settings: EvaluationSettingsObject,
  ): void => {
    this.settings = settings;
  };

  async loadData(): Promise<void> {
    await this.fetchEvaluationSettingsData();
    this.dataLoaded = true;
  }

  async fetchEvaluationSettingsData(): Promise<EvaluationSettingsObject> {
    const settings = await apiEvaluationSettings.fetchEvaluationSettingsData(
      this.authStore,
    );
    this.setEvaluationSettings(settings);
    return settings;
  }

  async saveBookingFrequency(
    eventTypeId: number,
    bookingFrequency: CalComBookingFrequency | null,
  ): Promise<void> {
    return apiEvaluationSettings.saveBookingFrequency(
      this.authStore,
      eventTypeId,
      bookingFrequency,
    );
  }

  async enableDisableInterviewer(isActive: boolean): Promise<void> {
    return apiEvaluationSettings.enableDisableInterviewer(
      this.authStore,
      isActive,
    );
  }

  async setupAccount(): Promise<void> {
    if (this.authStore.username) {
      apiUsers.setupAccount(this.authStore, this.authStore.username);
    }
  }
}
