import React, { ReactElement, useState } from 'react';

interface Props<T> {
  items: T[];
  render(
    key: string,
    item: T | null,
    index: number,
    nextIndex: () => number,
  ): ReactElement;
  minLength: number;
}

export default function InlineRows<T>(props: Props<T>): ReactElement {
  const { items, render, minLength } = props;
  const [runningIndex, setRunningIndex] = useState(0);

  const content = items.map((item, i) =>
    render((i + runningIndex).toString(), item, i, () => i),
  );

  while (content.length < minLength) {
    (() => {
      const i = content.length;
      content.push(
        render(
          (content.length + runningIndex).toString(),
          null,
          content.length,
          () => {
            setRunningIndex(runningIndex - items.length + i);
            return items.length;
          },
        ),
      );
    })();
  }

  return <>{content}</>;
}
