import { ServiceAuth, ServiceEndpoint } from './utils';
import { QueryNextToken, QueryResult } from '@a_team/models/dist/misc';
import DiscountObject, {
  BasicDiscountObject,
  DiscountApplicableFor,
  DiscountId,
  DiscountType,
} from '@a_team/models/dist/Discount';
import { AccountId } from '@a_team/models/dist/Account';
import { MissionId } from '@a_team/models/dist/MissionObject';

export const BasePath = '/discounts';

export interface CreateDiscountData {
  account: AccountId;
  allowedMissions?: MissionId[];
  type: DiscountType;
  totalCredit: number;
  applicableFor: DiscountApplicableFor;
  fixedAmount?: number;
  percentage?: number;
  notes?: string;
}

export interface TerminateDiscountData {
  notes?: string;
}

// exported functions

export default class DiscountsEndpoint extends ServiceEndpoint {
  public getDiscounts(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<QueryResult<BasicDiscountObject>> {
    return this.fetch(auth, BasePath, { next });
  }

  public getDiscount(
    auth: ServiceAuth,
    did: DiscountId,
  ): Promise<DiscountObject> {
    return this.fetch(auth, `${BasePath}/${did}`);
  }

  public createDiscount(
    auth: ServiceAuth,
    data: CreateDiscountData,
  ): Promise<DiscountObject> {
    return this.fetch(auth, BasePath, null, 'post', data);
  }

  public terminateDiscount(
    auth: ServiceAuth,
    did: DiscountId,
    data: TerminateDiscountData,
  ): Promise<DiscountObject> {
    return this.fetch(auth, `${BasePath}/${did}/terminate`, null, 'post', data);
  }
}
