import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Icon, IconType, Spacing } from '@ateams/components';

interface Props {
  label: string;
  include?: boolean;
  disabled?: boolean;
  onChange: (include?: boolean) => void;
}

const useStyles = createUseStyles({
  toggleSwitchWithLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonGroup: {
    display: 'flex',
    '& button': {
      marginRight: Spacing.xsmall,
    },
  },
});

// can be ported to TileRadioButtonGroup
const IncludeExcludeIgnore = (props: Props): ReactElement => {
  const styles = useStyles();
  const { label, include, disabled, onChange } = props;

  return (
    <p className={styles.toggleSwitchWithLabel}>
      {label}
      <div className={styles.buttonGroup}>
        <Button
          disabled={disabled}
          size="xsmall"
          secondaryDisabledColor={include === true}
          squared
          color={include === true ? 'secondaryDark' : 'transparent'}
          onClick={() => onChange(true)}
          width="auto"
        >
          {include === true && <Icon size="exact" type={IconType.CheckWhite} />}{' '}
          Include
        </Button>
        <Button
          disabled={disabled}
          secondaryDisabledColor={include === false}
          size="xsmall"
          squared
          color={include === false ? 'secondaryDark' : 'transparent'}
          onClick={() => onChange(false)}
          width="auto"
        >
          {include === false && (
            <Icon size="exact" type={IconType.CheckWhite} />
          )}{' '}
          Exclude
        </Button>
        <Button
          disabled={disabled}
          secondaryDisabledColor={include === undefined}
          size="xsmall"
          squared
          color={include === undefined ? 'secondaryDark' : 'transparent'}
          onClick={() => onChange(undefined)}
          width="auto"
        >
          {include === undefined && (
            <Icon size="exact" type={IconType.CheckWhite} />
          )}{' '}
          Ignore
        </Button>
      </div>
    </p>
  );
};
export default IncludeExcludeIgnore;
