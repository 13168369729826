import React, { ReactElement } from 'react';
import noResultsIcon from './images/no-results.png';
import noRecommended from './images/no-recommended.svg';
import noSearchResults from './images/no-search-results.svg';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface Props {
  className?: string;
  imageType?: 'noResults' | 'noRecommended' | 'noSearchResults';
  title?: string;
  text?: string | ReactElement;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    minHeight: '80vh',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 150,
    textAlign: 'center',
  },
  textContainer: {
    marginTop: '1em',

    '& > p': {
      margin: 0,
    },
  },
});

const NoResults = (props: Props): ReactElement => {
  const {
    imageType = 'noResults',
    title = 'No results found!',
    text = "Sorry, we didn't find what you were looking for.",
  } = props;
  const styles = useStyles();

  const getImage = (type: Props['imageType']) => {
    switch (type) {
      case 'noRecommended':
        return noRecommended;
      case 'noSearchResults':
        return noSearchResults;
      default:
        return noResultsIcon;
    }
  };

  return (
    <div className={cx(styles.container, props.className)}>
      <img src={getImage(imageType)} alt="No Results" />
      <h4 style={{ fontSize: 20, fontWeight: 600, marginBottom: 0 }}>
        {title}
      </h4>
      <div className={styles.textContainer}>{text}</div>
    </div>
  );
};

export default NoResults;
