import React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import {
  VettingProcessFeedbackRole,
  VettingProcessFeedbackRoles,
  VettingProcessFeedbackRoleTags,
} from '@a_team/models/dist/vetting-processes/feedback';
import { RightArrow } from '../../components/icons/arrows';
import {
  CheckboxValueTag,
  CheckboxValueTagProps,
} from '../../components/value-tag';
import {
  VettingProcessFeedbackRolesLabels,
  VettingProcessFeedbackRoleTagsLabels,
} from './view-metadata';
import RoleCategoryObject from '@a_team/models/dist/RoleCategory';

interface RoleInputProps {
  role: VettingProcessFeedbackRoles;
  value?: VettingProcessFeedbackRole;
  onRoleClick: CheckboxValueTagProps['onClick'];
  onTagClick: (tag: string, isChecked: boolean) => void;
  isReadOnly?: boolean;
  className?: string;
}

const useRoleInputStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightArrow: {
    margin: '0 24px',
  },
  roleTag: {
    marginRight: '4px',
    '&:last-child': {
      marginRight: 0,
    },
  },
});

export const RoleInput: React.FC<RoleInputProps> = (props) => {
  const { isReadOnly } = props;
  const styles = useRoleInputStyles();

  const isRoleSelected = Boolean(props.value);
  const hasTags = props.role in VettingProcessFeedbackRoleTags;

  if (isReadOnly && !isRoleSelected) {
    return null;
  }

  return (
    <div className={cx(styles.container, props.className)}>
      <CheckboxValueTag
        label={VettingProcessFeedbackRolesLabels[props.role]}
        checked={isRoleSelected}
        onClick={props.onRoleClick}
        isReadOnly={isReadOnly}
      />
      {hasTags && (!isReadOnly || Boolean(props.value?.tags.length)) && (
        <>
          <RightArrow className={styles.rightArrow} />
          {Object.entries(
            VettingProcessFeedbackRoleTagsLabels[
              props.role as keyof typeof VettingProcessFeedbackRoleTagsLabels
            ],
          ).map(([tag, label]) => (
            <CheckboxValueTag
              key={tag}
              label={label}
              checked={props.value && props.value.tags.includes(tag)}
              onClick={(isChecked) => props.onTagClick(tag, isChecked)}
              disabled={!isRoleSelected}
              isReadOnly={isReadOnly}
              className={styles.roleTag}
            />
          ))}
        </>
      )}
    </div>
  );
};

interface UserRoleInputProps {
  role: RoleCategoryObject;
  value?: boolean;
  onRoleClick?: CheckboxValueTagProps['onClick'];
  isReadOnly?: boolean;
  className?: string;
}

export const UserRoleInput: React.FC<UserRoleInputProps> = (props) => {
  const { isReadOnly } = props;
  const styles = useRoleInputStyles();

  return (
    <div className={cx(styles.container, props.className)}>
      <CheckboxValueTag
        label={props.role.title}
        checked={props.value}
        onClick={props.onRoleClick}
        isReadOnly={isReadOnly}
      />
    </div>
  );
};
