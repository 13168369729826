type Sizes = Record<string, string | number>;

export const Widths: Sizes = {
  default: '100%',
  fixed: '240px',
};

export const Margins: Sizes = {
  small: '12px 0',
  default: '24px 0',
  none: 0,
  bottom: '0 0 14px',
};

export const Paddings: Sizes = {
  xsmall: 5,
  small: 10,
  medium: 15,
  large: 20,
  xLarge: 30,
};

export const FontSizes: Sizes = {
  xsmall: '.7rem', // 11.2px
  small: '.8rem', // 12.8px
  medium: '.9rem', // 14.4px
  regular: '1rem', // 16px
  large: '1.2rem', // 19.2px
  extraLarge: '1.4rem', // 22.4px
};

export const Spacing: Sizes = {
  xxsmall: 2,
  xsmall: 4,
  small: 8,
  medium: 16,
  large: 24,
  xLarge: 32,
  xxLarge: 48,
  xxxLarge: 64,
};

export const BorderRadius = {
  minimal: 2,
  default: 4,
  small: 6,
  medium: 8,
  large: 12,
  xLarge: 16,
};

export const FontWeights = {
  small: 300,
  regular: 400,
  semiBold: 500,
  medium: 600,
  bold: 700,
};

export const Breakpoints = {
  xs: 320,
  sm: 768,
  md: 1200,
  lg: 1600,
  xl: 2000,
};
