import React from 'react';
import Modal, { ModalBasicProps } from '..';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { VettingProcessPostEvaluationSurvery } from '@a_team/models/dist/vetting-processes/post-evaluation-survey';
import { theme } from '@a_team/ui-components';
import { Spacing } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import {
  InputLabel,
  Size,
  Text,
} from '@src/views/VettingFeedbackForm/components/typography';
import { TextInput } from '@src/views/VettingFeedbackForm/components/text-input';
import { ScoreInput } from '@src/views/VettingPostEvaluationSurvey/form/score-input';

interface EvaluationInviteModalProps extends ModalBasicProps {
  postEvaluationSurvey: VettingProcessPostEvaluationSurvery;
  builderFullName: string;
  builderProfileImageUrl: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '40px',
  },
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  largeMarginBottom: {
    marginBottom: '40px',
  },
  titlesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  builderAvatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  builderAvatar: {
    marginRight: Spacing.small,
  },
  builderName: {
    fontSize: '15px',
  },
  title: {
    fontWeight: 500,
    height: '30px',
  },
  optionalSpan: {
    color: theme.colors.Grey[500],
  },
});

/** Design: {@link https://www.figma.com/file/L1g2wFUmUk2Y7QNzrOxIfk/Profile-page?node-id=7214-48699&t=zAMsYlCqMLSV9Twl-0} */
export const PostEvaluationSurveyModal: React.FC<EvaluationInviteModalProps> = (
  props,
) => {
  const styles = useStyles();
  const { open, onClose } = props;

  return (
    <Modal open={open} onClose={onClose} style={{ padding: 0, minWidth: 680 }}>
      <div className={styles.container}>
        <div className={cx(styles.titlesContainer, styles.largeMarginBottom)}>
          <div
            className={cx(styles.builderAvatarContainer, styles.marginBottom)}
          >
            <UserAvatar
              src={props.builderProfileImageUrl}
              size={24}
              containerClassName={styles.builderAvatar}
            />

            <div className={styles.builderName}>{props.builderFullName}</div>
          </div>

          <Text size={Size.ExtraLarge} className={styles.title}>
            Post evaluation call survey answers.
          </Text>
        </div>

        <ScoreInput
          value={props.postEvaluationSurvey.experienceWithInterviewer}
          label={'How would you rate your experience with the interviewer?'}
          description={
            <>
              1 being <b>Extremely negative</b> and 10 being{' '}
              <b>Extremely positive</b>
            </>
          }
          isReadOnly
          className={styles.largeMarginBottom}
        />

        <ScoreInput
          value={props.postEvaluationSurvey.likelinessToRecommendATeam}
          label={
            'Based on your evaluation call experience, how likely are you to recommend A.Team to a friend or colleague?'
          }
          description={
            <>
              With 1 being <b>Very unlikely</b> and 10 being <b>Very likely</b>
            </>
          }
          isReadOnly
          className={styles.largeMarginBottom}
        />

        <InputLabel className={styles.marginBottom}>
          Feel free to add any additional feedback here{' '}
          <span className={styles.optionalSpan}>{'(Optional)'}</span>
        </InputLabel>

        <TextInput
          value={props.postEvaluationSurvey.additionalFeedback}
          placeholder={'Additional feedback was not provided.'}
          isReadOnly
        />
      </div>
    </Modal>
  );
};
