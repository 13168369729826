import { apiMissions, apiUsers } from '@ateams/api';
import { observer } from 'mobx-react';
import { ApplicantConnectionsResponse } from '@ateams/api/dist/endpoints/Missions';
import { ConnectionObject } from '@a_team/models/dist/ConnectionObject';
import { MissionApplicationId } from '@a_team/models/dist/MissionApplicationObject';
import { MissionId } from '@a_team/models/dist/MissionObject';
import { Icon, IconType } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import { ProfileLocation } from '@src/locations';
import React, { ReactElement, useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { useStores } from '@src/stores';

interface Props {
  username: string | undefined;
  mid: MissionId | undefined;
  aid: MissionApplicationId | null;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  icon: { cursor: 'pointer', marginLeft: '4px', transition: 'all 0.2s' },
});

const ConnectionsPanel = (props: Props): ReactElement => {
  const { username, mid, aid } = props;
  const styles = useStyles();
  const { auth } = useStores();
  const [recommendations, setRecommendations] = useState(false);
  const [recommendedBy, setRecommendedBy] = useState(false);
  const [invitations, setInvitations] = useState(false);
  const [connections, setConnections] = useState(false);
  const [applicantConnections, setApplicantConnections] =
    useState<ApplicantConnectionsResponse | null>(null);

  const [userConnections, setUserConnections] = useState<
    ConnectionObject[] | null
  >(null);

  useEffect(() => {
    if (mid && aid) {
      apiMissions
        .getApplicantConnections(auth, mid, aid)
        .then((connections) => setApplicantConnections(connections));

      if (username) {
        apiUsers.getUserConnections(auth, username).then(setUserConnections);
      }
    }
  }, [username, aid, mid]);

  return (
    <>
      {applicantConnections && (
        <div style={{ marginLeft: '40px' }}>
          <hr style={{ marginTop: '0px', marginBottom: '24px' }} />
          <div
            className={styles.container}
            onClick={() => setRecommendations(!recommendations)}
          >
            <div>
              Recommendations (
              {applicantConnections.recommendations.items.length})
            </div>
            <Icon
              type={IconType.CaretDown}
              className={styles.icon}
              style={{
                transform: recommendations ? 'rotate(-180deg)' : 'rotate(0deg)',
              }}
            />
          </div>
          {recommendations && (
            <div>
              {applicantConnections.recommendations.items.map(
                (item) =>
                  item.user.username && (
                    <Link
                      to={ProfileLocation(item.user.username)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        marginBottom: '4px',
                      }}
                    >
                      <UserAvatar size={20} src={item.user.profilePictureURL} />
                      <div style={{ marginLeft: '6px', color: '#222' }}>
                        {item.user.fullName}
                      </div>
                    </Link>
                  ),
              )}
            </div>
          )}
          <div
            className={styles.container}
            style={{ marginTop: '4px' }}
            onClick={() => setRecommendedBy(!recommendedBy)}
          >
            <div>
              Recommended by ({applicantConnections.recommendedBy.items.length})
            </div>
            <Icon
              type={IconType.CaretDown}
              className={styles.icon}
              style={{
                transform: recommendedBy ? 'rotate(-180deg)' : 'rotate(0deg)',
              }}
            />
          </div>
          {recommendedBy && (
            <div>
              {applicantConnections.recommendedBy.items.map(
                (item) =>
                  item.user.username && (
                    <Link
                      to={ProfileLocation(item.user.username)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        marginBottom: '4px',
                      }}
                    >
                      <UserAvatar size={20} src={item.user.profilePictureURL} />
                      <div style={{ marginLeft: '6px', color: '#222' }}>
                        {item.user.fullName}
                      </div>
                    </Link>
                  ),
              )}
            </div>
          )}
          <div
            className={styles.container}
            style={{ marginTop: '4px' }}
            onClick={() => setInvitations(!invitations)}
          >
            <div>
              Invitations ({applicantConnections.invitations.items.length})
            </div>
            <Icon
              type={IconType.CaretDown}
              className={styles.icon}
              style={{
                transform: invitations ? 'rotate(-180deg)' : 'rotate(0deg)',
              }}
            />
          </div>
          {invitations &&
            applicantConnections.invitations.items.map((item) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginBottom: '4px',
                }}
                key={item.user.uid}
              >
                <div style={{ color: '#222' }}>
                  {item.user.fullName}, {item.user.email}
                </div>
              </div>
            ))}
          <div
            className={styles.container}
            style={{ marginTop: '4px' }}
            onClick={() => setConnections(!connections)}
          >
            <div>Connections ({applicantConnections.connectionsCount})</div>
            <Icon
              type={IconType.CaretDown}
              className={styles.icon}
              style={{
                transform: connections ? 'rotate(-180deg)' : 'rotate(0deg)',
              }}
            />
          </div>
          {connections && (
            <div>
              {userConnections &&
                userConnections.map((item) => (
                  <Link
                    to={ProfileLocation(item.toUser.username)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      marginBottom: '4px',
                    }}
                  >
                    <UserAvatar size={20} src={item.toUser.profilePictureURL} />
                    <div style={{ marginLeft: '6px', color: '#222' }}>
                      {item.toUser.fullName}
                    </div>
                  </Link>
                ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default observer(ConnectionsPanel);
