/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import { AsyncSegmentBrowserAnalyticsLike, Batch } from './types';

/** Utility for creating callbacks matching the (wrong) segment type declaration */
const callBack =
  (resolve: (batch: Batch) => void, reject: (err?: any) => void) =>
  (event?: Promise<any>) => {
    event && event.then(resolve).catch(reject);
  };

/** Asynchronous wrapper class for client-side segment analytics */
export class AsyncSegmentBrowserAnalytics
  implements AsyncSegmentBrowserAnalyticsLike
{
  constructor(
    protected readonly analytics: () => SegmentAnalytics.AnalyticsJS,
  ) {}

  /** Use a plugin */
  use(plugin: (analytics: SegmentAnalytics.AnalyticsJS) => void): this {
    this.analytics().use(plugin);
    return this;
  }

  /** Initialize with the given integration `settings` and `options`. */
  init(
    settings?: SegmentAnalytics.IntegrationsSettings,
    options?: SegmentAnalytics.InitOptions,
  ): this {
    this.analytics().init(settings, options);
    return this;
  }

  /** Define a new integration */
  addIntegration(integration: (options: any) => void): this {
    this.analytics().addIntegration(integration);
    return this;
  }

  /**  Set the user's `id`. */
  setAnonymousId(id: string): this {
    this.analytics().setAnonymousId(id);
    return this;
  }

  /** Configure Segment with write key */
  load(writeKey: string): void;
  /** Configure Segment with write key & integration management.
  
         The load method can also be modified to take a second argument,
         an object with an integrations dictionary, which used to load
         only the integrations that are marked as enabled with the boolean value true.
         works in version 4.1.0 or higher */
  load(writeKey: string, options?: SegmentAnalytics.SegmentOpts): void;
  load(writeKey: string, options?: SegmentAnalytics.SegmentOpts): void {
    this.analytics().load(writeKey, options);
  }

  /** The identify method is how you tie one of your users and their actions
         to a recognizable userId and traits. */
  identify(
    userId: string,
    traits?: Object,
    options?: SegmentAnalytics.SegmentOpts,
  ): Promise<Batch>;
  identify(userId: string, traits: Object): Promise<Batch>;
  identify(userId: string): Promise<Batch>;
  identify(
    traits?: Object,
    options?: SegmentAnalytics.SegmentOpts,
  ): Promise<Batch>;
  identify(traits?: Object): Promise<Batch>;
  identify(): Promise<Batch>;
  identify(...args: any[]): Promise<Batch> {
    return new Promise<Batch>((resolve, reject) =>
      this.analytics().identify(...args, callBack(resolve, reject)),
    );
  }

  /** The track method lets you record any actions your users perform. */
  track(
    event: string,
    properties?: Object,
    options?: SegmentAnalytics.SegmentOpts,
  ): Promise<Batch>;
  track(event: string, properties?: Object): Promise<Batch>;
  track(event: string): Promise<Batch>;
  track(event: string, ...args: any[]): Promise<Batch> {
    return new Promise<Batch>((resolve, reject) =>
      this.analytics().track(event, ...args, callBack(resolve, reject)),
    );
  }

  /** The page method lets you record page views on your website, along with
         optional extra information about the page being viewed. */
  page(
    category?: string,
    name?: string,
    properties?: Object,
    options?: SegmentAnalytics.SegmentOpts,
  ): Promise<Batch>;
  page(
    name?: string,
    properties?: Object,
    options?: SegmentAnalytics.SegmentOpts,
  ): Promise<Batch>;
  page(name?: string, properties?: Object): Promise<Batch>;
  page(name?: string): Promise<Batch>;
  page(
    properties?: Object,
    options?: SegmentAnalytics.SegmentOpts,
  ): Promise<Batch>;
  page(): Promise<Batch>;
  page(...args: any[]): Promise<Batch> {
    return new Promise<Batch>((resolve, reject) =>
      this.analytics().page(...args, callBack(resolve, reject)),
    );
  }

  /** The group method associates an individual user with a group. The group
         can a company, organization, account, project, team or any other name
         you came up with for the same concept. */
  group(
    groupId: string,
    traits?: Object,
    options?: SegmentAnalytics.SegmentOpts,
  ): Promise<Batch>;
  group(groupId: string, traits?: Object): Promise<Batch>;
  group(groupId: string): Promise<Batch>;
  group(): void | {
    id(): string;
    traits(newTraits?: Object): void;
  };
  group(...args: [] | [string, Object?, SegmentAnalytics.SegmentOpts?]):
    | void
    | {
        id(): string;
        traits(newTraits?: Object): void;
      }
    | Promise<void>
    | Promise<Batch> {
    if (args.length === 0) {
      if (this.analytics) {
        return this.analytics().group();
      }
    } else {
      const [groupId, traits, options] = args;
      if (this.analytics) {
        return new Promise<Batch>((resolve, reject) =>
          this.analytics().group(
            groupId,
            traits,
            options,
            callBack(resolve, reject),
          ),
        );
      } else {
        return Promise.resolve();
      }
    }
  }

  /** The alias method combines two previously unassociated user identities.
         This comes in handy if the same user visits from two different devices
         and you want to combine their history.
  
         Some providers also don’t alias automatically for you when an anonymous
         user signs up (like Mixpanel), so you need to call alias manually right
         after sign up with their brand new userId. */
  alias(
    userId: string,
    previousId?: string,
    options?: SegmentAnalytics.SegmentOpts,
  ): Promise<Batch>;
  alias(userId: string, previousId?: string): Promise<Batch>;
  alias(userId: string): Promise<Batch>;
  alias(userId: string, options?: SegmentAnalytics.SegmentOpts): Promise<Batch>;
  alias(userId: string, ...args: any[]): Promise<Batch> {
    return new Promise<Batch>((resolve, reject) =>
      this.analytics().alias(userId, ...args, callBack(resolve, reject)),
    );
  }

  /** trackLink is a helper that binds a track call to whenever a link is
         clicked. Usually the page would change before you could call track, but
         with trackLink a small timeout is inserted to give the track call enough
         time to fire. */
  trackLink(
    elements: JQuery | Element[] | Element,
    event: string | { (elm: Element): string },
    properties?: Object | { (elm: Element): Object },
  ): void {
    return this.analytics().trackLink(elements, event, properties);
  }

  /** trackForm is a helper that binds a track call to a form submission.
         Usually the page would change before you could call track, but with
         trackForm a small timeout is inserted to give the track call enough
         time to fire. */
  trackForm(
    elements: JQuery | Element[] | Element,
    event: string | { (elm: Element): string },
    properties?: Object | { (elm: Element): Object },
  ): void {
    return this.analytics().trackForm(elements, event, properties);
  }

  /** The ready method allows you to pass in a callback that will be called as
         soon as all of your enabled integrations have loaded. It’s like jQuery’s
         ready method, except for integrations. */
  ready(): Promise<void> {
    return new Promise<void>((resolve) => this.analytics().ready(resolve));
  }

  /** If you need to clear the user and group id and traits we’ve added a
         reset function that is most commonly used when your identified users
         logout of your application. */
  reset(): void {
    this.analytics().reset();
  }

  /** Once Analytics.js loaded, you can retrieve information about the
         currently identified user or group like their id and traits. */
  user(): {
    id(): string;
    logout(): void;
    reset(): void;
    anonymousId(newId?: string): string;
    traits(newTraits?: Object): void;
  } {
    return this.analytics().user();
  }

  /** Analytics.js has a debug mode that logs helpful messages to the
         console. */
  debug(state?: boolean): void {
    this.analytics().debug(state);
  }

  /** The global analytics object emits events whenever you call alias, group,
         identify, track or page. That way you can listen to those events and run
         your own custom code. */
  on(
    event: string,
    callback: {
      (
        event: string,
        properties: Object,
        options: SegmentAnalytics.SegmentOpts,
      ): void;
    },
  ): void {
    this.analytics().on(event, callback);
  }

  /** You can extend the length (in milliseconds) of the method callbacks and
         helpers */
  timeout(milliseconds: number): void {
    return this.analytics().timeout(milliseconds);
  }
}
