import React, { ReactElement, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Select, SelectOption } from '@ateams/components';
import { useQueryCustomTags } from '@src/rq/customTags';

interface Props {
  customTags: string[];
  onChange: (tags: string[]) => void;
  sidebar?: boolean;
  label: string;
  labelClass?: string;
}

const useStyles = createUseStyles({
  selector: {
    maxWidth: 200,
    marginRight: 25,
    fontSize: 14,

    '& label.input-label': {
      color: '#222222',
    },
  },
  sidebar: {
    maxWidth: 'unset',
    marginRight: 0,
  },
  tagList: {
    marginTop: 8,
    marginBottom: 0,
  },
});

const CustomTagsSelect = (props: Props): ReactElement => {
  const styles = useStyles();
  const { customTags, onChange, sidebar, label } = props;
  const { data: allCustomTags } = useQueryCustomTags();

  const allCustomTagOptions = useMemo(() => {
    return allCustomTags?.map((customTag) => {
      return {
        value: customTag._id,
        label: customTag.name,
      };
    });
  }, [allCustomTags]);

  const selectedCustomTags = useMemo(() => {
    const newSelectedCustomTags: SelectOption[] = [];
    customTags.forEach((id) => {
      const customTag = allCustomTagOptions?.find((tag) => tag.value === id);
      if (customTag) {
        newSelectedCustomTags.push(customTag);
      }
    });
    return newSelectedCustomTags;
  }, [customTags, allCustomTagOptions]);

  const updateCustomTags = (
    options: SelectOption | SelectOption[] | null,
  ): void => {
    const customTags: string[] = !options
      ? []
      : Array.isArray(options)
      ? options.map(({ value }) => value)
      : [options.value];

    onChange(customTags);
  };

  const removeOption = (option: SelectOption): void => {
    const newSelectedCustomTags = selectedCustomTags.filter(
      (customTag) => customTag.value !== option.value,
    );
    updateCustomTags(newSelectedCustomTags);
  };

  return (
    <div>
      <Select
        label={label}
        value={selectedCustomTags}
        options={allCustomTagOptions}
        onChange={(e) => {
          updateCustomTags(e as SelectOption[]);
        }}
        onRemoveOption={(e) => {
          removeOption(e as SelectOption);
        }}
        placeholder={'Select user tags'}
        margin={'none'}
        className={cx(styles.selector, sidebar && styles.sidebar)}
        tagListClassName={styles.tagList}
        isMulti
      />
    </div>
  );
};

export default CustomTagsSelect;
