import { Modal } from '@a_team/ui-components';
import { Breakpoints, Button, ButtonColor } from '@ateams/components';
import { ModalBasicProps } from '@src/components/Modal';
import cx from 'classnames';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';

interface ConfirmModalV2Props extends ModalBasicProps {
  dataTestingId?: string;
  image?: JSX.Element;
  title: JSX.Element | string;
  description: JSX.Element | string;
  onCancel?: () => void;
  onCancelText?: string;
  onCancelColor?: ButtonColor;
  onConfirm: () => void;
  onConfirmText: string;
  onConfirmColor?: ButtonColor;
}

const useStyles = createUseStyles({
  modal: {
    padding: 0,
  },
  title: {
    textAlign: 'center',
    margin: 0,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '26px',
  },
  description: {
    textAlign: 'center',
    margin: '8px 0 0 0',
    fontSize: 15,
    lineHeight: '24px',
    color: '#818388',
  },
  buttonsWrapper: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'center',
    gap: 12,
  },
  button: {
    maxWidth: 185,
    borderRadius: 8,
    transition: 'background 0.3s ease',
    width: 'fit-content',
    padding: '8px 12px',
    fontSize: 14,
    minWidth: 132,
  },
  secondaryActionButton: {
    '&:hover': {
      background: '#DADADC',
    },
  },
  primaryActionButton: {
    '&:hover': {
      background: '#5900b3',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '100% !important',
      maxWidth: 680,
      padding: 24,
    },
  },
});

function ConfirmModalV2({
  dataTestingId,
  image,
  title,
  description,
  onCancel,
  onCancelText,
  onCancelColor,
  onConfirm,
  onConfirmText,
  onConfirmColor,
  open,
  onClose,
}: ConfirmModalV2Props): ReactElement {
  const styles = useStyles({ onConfirmColor });

  return (
    <Modal
      variant="slideUp"
      isOpen={open}
      onClose={onClose}
      className={styles.modal}
      shouldHideGradientStroke={true}
    >
      <div data-testing-id={dataTestingId}>
        {image}

        <h4 className={styles.title}>{title}</h4>

        {description && <div className={styles.description}>{description}</div>}

        <div className={styles.buttonsWrapper}>
          {onCancel && onCancelText && (
            <Button
              className={cx(styles.button, styles.secondaryActionButton)}
              onClick={onCancel}
              size="small"
              color={onCancelColor ?? 'backgroundDark'}
              data-testing-id="cancel-button"
            >
              {onCancelText}
            </Button>
          )}

          <Button
            className={cx(styles.button, styles.primaryActionButton)}
            onClick={onConfirm}
            size="small"
            color={onConfirmColor ?? 'secondaryDark'}
            data-testing-id="confirm-button"
          >
            {onConfirmText}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmModalV2;
