import React, { ReactElement, ReactNode } from 'react';
import ItemsCarousel, { CarouselState } from '@src/components/ItemsCarousel';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import { CycleCard } from './CycleCard';

interface Props {
  children: ReactNode;
  state?: CycleCardsState;
}

export type CycleCardsState = CarouselState | null;

const useStyles = createUseStyles({
  container: {
    padding: 8,
    position: 'absolute',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      padding: 8,
      position: 'static',
    },
  },
});

const CycleCards = (props: Props): ReactElement => {
  const styles = useStyles();

  return (
    <div
      style={{
        padding: '24px 32px',
        borderBottom: '1px solid #C0C0C0',
        height: 160,
      }}
    >
      <ItemsCarousel
        edgeButtons
        className={styles.container}
        startPosition={'start'}
        state={props.state}
      >
        {props.children}
      </ItemsCarousel>
    </div>
  );
};

export default CycleCards;

export { CycleCard };
