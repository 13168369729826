import React from 'react';
import { theme } from '@a_team/ui-components';

export const DeleteIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        opacity="0.6"
        d="M11 6V5C11 3.34315 9.65685 2 8 2V2C6.34315 2 5 3.34315 5 5V6"
        stroke={theme.colors.Grey[800]}
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M3.5 6L3.8828 12.1248C3.94868 13.1788 4.82278 14 5.8789 14H10.1211C11.1772 14 12.0513 13.1788 12.1172 12.1248L12.5 6M3.5 6H2M3.5 6H5.33333H10.6667H12.5M12.5 6H14"
        stroke={theme.colors.Grey[800]}
        strokeWidth="1.4"
        strokeLinecap="round"
      />
    </svg>
  );
};
