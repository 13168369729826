import LocationObject from '@a_team/models/dist/LocationObject';
import { Breakpoints } from '@ateams/components';
import {
  LocationAutocompleteV2,
  OnLocationChange,
} from '@src/components/LocationAutocompleteV2';
import {
  formatLocation,
  parseLocation,
} from '@src/components/LocationAutocompleteV2/utils';
import Section from '@src/views/Profile/Main/partials/Section';
import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  userLocation: {
    city: string;
    province?: string;
    country: string;
  };
  readonly?: boolean;
  onChange(data: LocationObject): void;
  guidanceMessage?: ReactElement;
  toggleAllLocationsModalOpen: () => void;
}

export const useStyles = createUseStyles({
  select: {
    width: '100%',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    select: {
      width: 400,
      marginLeft: 12,
    },
  },
});

const Location = (props: Props): ReactElement => {
  const styles = useStyles();

  const {
    userLocation,
    toggleAllLocationsModalOpen,
    guidanceMessage,
    onChange,
  } = props;

  const location = formatLocation({
    city: userLocation.city,
    province: userLocation.province,
    country: userLocation.country,
  });

  const onLocationChange: OnLocationChange = (options) => {
    if (!options) return;
    const option = Array.isArray(options) ? options[0] : options;
    const { prediction, countryShortName } = option;

    if (prediction?.structured_formatting) {
      const location = parseLocation(
        prediction.structured_formatting,
        countryShortName,
      );

      onChange(location);
    }
  };

  return (
    <Section
      title={'Location'}
      type={'large'}
      description={'The location you will primarily work from.'}
    >
      <div
        style={{
          color: '#35373A',
          fontSize: 16,
          fontWeight: 500,
        }}
      >
        The company requires builders in certain locations.{' '}
        <span
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={toggleAllLocationsModalOpen}
        >
          See required locations
        </span>
      </div>
      {guidanceMessage}
      <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
        <span>I am located in</span>

        {userLocation.country !== '' ? (
          location
        ) : (
          <LocationAutocompleteV2
            openMenuOnClick={false}
            defaultInputValue={location}
            autocompleteOptions={{ types: ['(cities)'] }}
            onChange={onLocationChange}
            margin={'small'}
            className={styles.select}
          />
        )}
      </div>
    </Section>
  );
};

export default observer(Location);
