/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  REACT_APP_CALCOM_URL,
  REACT_APP_EVALUATION_EMAIL_CC,
  REACT_APP_EVALUATION_EMAIL_FROM,
} from '@src/config';
import React, { useEffect, useRef } from 'react';

export const ON_BOOKING_SUCCESSFUL_TIMEOUT = 3000;
export interface EmbedCalendarProps {
  fullName: string;
  email: string;
  calLink: string;
  onCalendarReady?: () => void;
  onBookingSuccessful: (
    uid: string,
    id: number,
    email: string,
    date: Date,
  ) => void;
}
const EmbedCalendar = ({
  fullName,
  email,
  calLink,
  onCalendarReady,
  onBookingSuccessful,
}: EmbedCalendarProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const lastBookingTimeRef = useRef<number | null>(null);

  useEffect(() => {
    const iFrames = document.querySelectorAll('cal-inline');
    iFrames.forEach((iFrame) => {
      iFrame.remove();
    });
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;

    const options = {
      elementOrSelector: '#cal-container',
      calLink: calLink,
      layout: 'month_view',
      config: {
        name: fullName,
        email: email,
        emailFrom: REACT_APP_EVALUATION_EMAIL_FROM,
        emailCC: REACT_APP_EVALUATION_EMAIL_CC,
      },
    };

    script.innerHTML = `
      (function (C, A, L) { let p = function (a, ar) { a.q.push(ar); }; let d = C.document; C.Cal = C.Cal || function () { let cal = C.Cal; let ar = arguments; if (!cal.loaded) { cal.ns = {}; cal.q = cal.q || []; d.head.appendChild(d.createElement("script")).src = A; cal.loaded = true; } if (ar[0] === L) { const api = function () { p(api, arguments); }; const namespace = ar[1]; api.q = api.q || []; typeof namespace === "string" ? (cal.ns[namespace] = api) && p(api, ar) : p(cal, ar); return; } p(cal, ar); }; })(window, "${REACT_APP_CALCOM_URL}/embed/embed.js", "init");
      Cal("init", {origin:"${REACT_APP_CALCOM_URL}"});
      Cal("inline", ${JSON.stringify(options)});
      Cal("ui", {"styles":{"branding":{"brandColor":"#6D00D7"}},"hideEventTypeDetails":false,"layout":"month_view"});
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [calLink]);

  const handleResize = (entries: ResizeObserverEntry[]) => {
    if (entries && entries.length > 0) {
      const newHeight = entries[0].contentRect.height;

      if (newHeight >= 450) {
        onCalendarReady?.();
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(container);

      return () => {
        resizeObserver.disconnect();
      };
    }

    return;
  }, []);

  useEffect(() => {
    const calWindow = (window as any).Cal;
    if (!calWindow || typeof calWindow !== 'function') {
      return;
    }

    calWindow('on', {
      action: 'bookingSuccessful',
      callback: (e: any) => {
        if (typeof onBookingSuccessful === 'function') {
          const { data } = e.detail;
          const bookingTime = new Date(data.date).getTime();

          if (
            lastBookingTimeRef.current === null ||
            bookingTime - lastBookingTimeRef.current >=
              ON_BOOKING_SUCCESSFUL_TIMEOUT
          ) {
            onBookingSuccessful(
              data.booking.uid,
              data.booking.id,
              data.organizer.email,
              new Date(data.date),
            );
            lastBookingTimeRef.current = bookingTime;
          }
        } else {
          console.error('onBookingSuccessful is not a valid function.');
        }
      },
    });
  }, [(window as any).Cal, onBookingSuccessful]);

  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        overflow: 'scroll',
      }}
      id="cal-container"
    ></div>
  );
};

export default EmbedCalendar;
