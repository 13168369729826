import React, { useEffect } from 'react';
import MainLayout from '@src/layouts/Main';
import { useStores } from '@src/stores';
import { useHistory } from 'react-router-dom';
import { MissionControlBase } from '@src/locations';
import { observer } from 'mobx-react';

const Community = (): JSX.Element | null => {
  const { auth } = useStores();
  const history = useHistory();

  useEffect(() => {
    if (
      !auth.isAdmin &&
      auth.currentUser?.uid &&
      !auth.currentUser?.hasAccessToCommunity
    ) {
      history.push(`${MissionControlBase}/recommended`);
    }
  }, [auth.currentUser?.uid, !auth.currentUser?.hasAccessToCommunity]);

  if (!auth.currentUser) return null;

  return (
    <MainLayout title="Community" style={{ padding: 0 }}>
      <iframe
        title="guide"
        is="x-frame-bypass"
        src={'https://ateamcommunity.super.site/'}
        width="100%"
        style={{ height: '100vh' }}
        frameBorder="0"
      />
    </MainLayout>
  );
};

export default observer(Community);
