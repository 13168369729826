import { Colors, IconType, Icon, Tag, TextColors } from '@ateams/components';
import SectionHeading from '@src/components/SectionHeading';
import { ProfileTooltip } from '@src/views/Profile/ProfileTooltip';
import React, { ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import CommonStyles from '../styles';
import cx from 'classnames';

export interface SectionProps {
  type?: 'default' | 'large';
  dataTestingId?: string;
  iconType?: IconType;
  title: string | ReactNode;
  description?: string | ReactNode;
  tooltipText?: string;
  disabled?: boolean;
  readonly?: boolean;
  error?: boolean;
  isApplicationEdited?: boolean;
  marginTop?: number;
  disabledMaxWidth?: boolean;
  guidanceTooltip?: ReactElement;
  children: ReactNode;
  className?: string;
}

const useStyles = createUseStyles({
  root: {
    maxWidth: ({ disabledMaxWidth }) => (disabledMaxWidth ? 'auto' : 800),
  },
  contentWrapper: {
    marginTop: ({ marginTop }) => marginTop ?? 8,
    marginBottom: 40,
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  sectionHeading: {
    fontSize: ({ type }) => (type === 'large' ? 20 : 15),
    lineHeight: '30px',
    fontWeight: 500,
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    marginTop: -3,
    marginRight: 8,
    fontSize: 16,
  },
  description: {
    color: ({ type }) =>
      type === 'large' ? TextColors.regularLight : TextColors.regular,
  },
});

function Section({
  dataTestingId,
  iconType,
  title,
  description,
  tooltipText,
  readonly,
  isApplicationEdited,
  error,
  children,
  marginTop,
  disabledMaxWidth,
  type,
  guidanceTooltip,
  className,
}: SectionProps) {
  const commonStyles = CommonStyles();
  const styles = useStyles({ marginTop, disabledMaxWidth, type });

  return (
    <div className={cx(styles.root, className)} data-testing-id={dataTestingId}>
      <div className={cx({ [styles.headerWrapper]: type === 'large' })}>
        <div>
          <ProfileTooltip
            title={guidanceTooltip ? undefined : tooltipText}
            disabled={!tooltipText || !!guidanceTooltip}
            textColor="primaryDark"
          >
            <SectionHeading
              className={styles.sectionHeading}
              isFirst
              style={{
                ...(error &&
                  type !== 'large' &&
                  !readonly && {
                    color: Colors.danger,
                  }),
              }}
            >
              <div>
                {iconType && (
                  <Icon
                    type={iconType}
                    title={iconType}
                    className={styles.icon}
                  />
                )}

                {title}

                {isApplicationEdited ? (
                  <Tag className={commonStyles.tagEdited}>Edited</Tag>
                ) : null}
              </div>
            </SectionHeading>
          </ProfileTooltip>
          {description && (
            <div className={styles.description}>{description}</div>
          )}
        </div>
        {guidanceTooltip && guidanceTooltip}
      </div>

      <div
        className={styles.contentWrapper}
        data-testing-id={'section-content'}
      >
        {children}
      </div>
    </div>
  );
}

export default Section;
