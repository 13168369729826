import { Button } from '@a_team/ui-components';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface PreEvaluationEmailInvitesLogProps {
  builderEmail: string;
}

const useStyles = createUseStyles({
  container: {
    marginTop: 20,
  },
});

const URL =
  'https://bi.a.team/dashboard/13-email-analytics-dashboard' +
  '?event_type=Send' +
  '&event_type=Bounce' +
  '&recipient={builderEmail}' +
  '&relative_date=' + // important to keep this empty so it does not default to 'This Month'
  '&template_name=invite-evaluation-call' +
  '&template_name=first-reminder-evaluation-call' +
  '&template_name=second-reminder-evaluation-call' +
  '&template_name=builder-removed-from-waitlist';

const PreEvaluationEmailInvitesLog = ({
  builderEmail,
}: PreEvaluationEmailInvitesLogProps) => {
  const styles = useStyles();

  const handleClick = () => {
    const finalUrl = URL.replace('{builderEmail}', builderEmail);

    if (typeof window !== 'undefined') {
      window.open(finalUrl, '_blank');
    }
  };

  return (
    <div className={styles.container}>
      <Button onClick={handleClick} size="md">
        Pre-Evaluation Email Invites
      </Button>
    </div>
  );
};

export default PreEvaluationEmailInvitesLog;
