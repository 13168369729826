import {
  TeamUpRequestNotificationObject,
  NotificationType,
} from '@a_team/models/dist/NotificationObject';
import React from 'react';
import Notification from './Notification';
import { useHistory } from 'react-router-dom';
import { MissionPageLocation } from '@src/locations';
import { RecommendationStatus } from '@a_team/models/dist/RecommendationObject';

interface TeamUpRequestNotificationProps {
  notification: TeamUpRequestNotificationObject;
  openTeamUpRequestNoLongerAvailableModal: () => void;
  onMarkAsRead(): void;
  isRead?: boolean;
  toggle: () => void;
}

const TeamUpRequestNotification = ({
  notification,
  onMarkAsRead,
  openTeamUpRequestNoLongerAvailableModal,
  isRead,
  toggle,
}: TeamUpRequestNotificationProps): JSX.Element => {
  const history = useHistory();

  const onCtaAction = () => {
    onMarkAsRead();
    toggle();

    if (
      notification.userRecommendation.status ===
      RecommendationStatus.Unavailable
    ) {
      openTeamUpRequestNoLongerAvailableModal();
    } else {
      history.push(
        MissionPageLocation(notification.userRecommendation.mission.mid),
      );
    }
  };

  const requestorName = notification.userRecommendation.requestor.firstName;
  const mission = notification.userRecommendation.mission.title;
  const requestorProfilePictureURL =
    notification.userRecommendation.requestor.profilePictureURL;
  const title = `${requestorName} invited you to team up on a mission!`;

  const description = `${requestorName} wants to team up on ${mission}`;
  const missionLogo = notification.userRecommendation.mission.logoURL;

  return (
    <Notification
      createdAt={notification.createdAt}
      notificationType={NotificationType.TeamUpRequest}
      ctaName="Open mission"
      ctaAction={onCtaAction}
      title={title}
      description={description}
      companyLogoUrl={missionLogo}
      profilePictureURL={requestorProfilePictureURL}
      onMarkAsRead={onMarkAsRead}
      isRead={isRead}
    />
  );
};

export default TeamUpRequestNotification;
