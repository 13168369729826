import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { DiscoveryCardType, useStylesDiscoveryUserCard } from '.';
import cx from 'classnames';

interface DiscoveryUserCardSkeletonProps {
  type: DiscoveryCardType;
  className?: string;
}

const DiscoveryUserCardSkeleton = ({
  type,
  className,
}: DiscoveryUserCardSkeletonProps): JSX.Element => {
  const styles = useStylesDiscoveryUserCard({ type });

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.content}>
        <div>
          <div className={styles.header}>
            <Skeleton width={32} height={32} circle />

            <Skeleton width={50} />
          </div>
          <Skeleton width={150} />
          <Skeleton width={100} />
        </div>
        <div style={{ marginTop: 24 }}>
          <div className={styles.connectionTypesContainer}>
            <Skeleton width={32} height={32} />
          </div>
          <div className={styles.connectionInsightContainer}>
            <Skeleton width={200} />
          </div>
        </div>
      </div>
      <div className="btn-container">
        {type === DiscoveryCardType.Discovered && (
          <div
            style={{
              display: 'flex',
              gap: 8,
              justifyContent: 'center',
            }}
          >
            <Skeleton width={100} height={30} />
            <Skeleton width={100} height={30} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DiscoveryUserCardSkeleton;
