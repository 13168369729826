import { Breakpoints } from '@ateams/components';
import { createUseStyles } from 'react-jss';

export const useCommonStyles = createUseStyles({
  container: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  desc: {
    gap: '10px',
    textAlign: 'center',
    maxWidth: '500px',
    width: '100%',
  },
  input: {
    marginRight: '10px',
  },
  label: {
    fontSize: '16px',
    width: '100%',
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'center',
    margin: '24px 0 8px 0',
  },
  updateAvailabilityBtn: {
    padding: '10px !important',
  },
  date: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    gap: 12,
  },
  line: {
    height: 1,
    width: 20,
    backgroundColor: '#DADADC',
  },
  logoContainer: {
    position: 'relative',
  },
  indicatorIcon: {
    position: 'absolute',
    bottom: '-5px',
    right: '-12px',
  },

  btnsContainer: {
    marginTop: 40,
    display: 'flex',
    gap: 12,

    '& button': {
      minWidth: 120,
      height: 40,
    },
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    container: {
      padding: 40,
    },
    date: {
      fontSize: 16,
      gap: 16,
    },
  },
});
