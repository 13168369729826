import { Button, Icon } from '@a_team/ui-components';
import { Breakpoints, Icon as IconComp, IconType } from '@ateams/components';
import CompanyLogo from '@src/components/CompanyLogo';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useMediaQuery from '@src/hooks/useMediaQuery';
import { observer } from 'mobx-react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { ProfileTooltip } from '../ProfileTooltip';
import { useApplicationNavbarActions } from './useApplicationNavbarActions';

const useStyles = createUseStyles({
  container: {
    background: 'white',
    borderBottom: '1px solid #DADADC',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '10px 24px',
    '& button span': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
  },
  moreDetailsBtn: {
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    color: '#6D00D7',
    fontSize: 14,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  missionTitle: {
    color: '#222222',
    fontSize: 16,
    fontWeight: 500,
  },
  companyIconImg: {
    width: 40,
    height: 40,
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  backBtn: {
    display: 'flex',
    borderColor: '#C0C0C0 !important',
    padding: '10px !important',
  },
  submitBtn: {
    padding: '8px 12px !important',
  },
  skipBtn: {
    padding: '8px 12px !important',
  },
  teamUpBtns: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      padding: '0 24px',
    },
  },
});

const ApplicationNavbar = (): JSX.Element => {
  const isDesktop = useMediaQuery(`(min-width: ${Breakpoints.sm}px)`);
  const styles = useStyles();

  const {
    isRecommendationsStep,
    isNewConnectRecommendations,
    isInterviewAvailabilityStep,
    loading,
    isDetailedMenuOpen,
    companyLogo,
    companyTitle,
    profile,
    showDropdown,
    hideMissionTitle,
    isViewModeOnly,
    onDetailsMenuClick,
    onBackArrowClick,
    handleSkipPostApplicationSuggestions,
    handleSubmit,
  } = useApplicationNavbarActions();

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        {!isRecommendationsStep && !isInterviewAvailabilityStep && (
          <Button
            variant="ghost"
            className={styles.backBtn}
            onClick={onBackArrowClick}
          >
            <IconComp
              type={IconType.LeftArrowDark}
              style={{ height: 16, width: 16 }}
              clickable
            />
          </Button>
        )}

        <div>
          <CompanyLogo
            className={styles.companyIconImg}
            logoUrl={companyLogo}
            size={40}
          />
        </div>
        <div>
          {isDesktop && hideMissionTitle && (
            <div
              className={styles.missionTitle}
              data-testing-id="mission-application-title"
            >
              {companyTitle}
            </div>
          )}

          {showDropdown && (
            <button
              data-testing-id="mission-application-details-button"
              className={styles.moreDetailsBtn}
              onClick={onDetailsMenuClick}
            >
              More details{' '}
              {isDetailedMenuOpen ? (
                <Icon size="sm" color="Hannibal@600" name="arrowUp" />
              ) : (
                <Icon size="sm" color="Hannibal@600" name="arrowDown" />
              )}
            </button>
          )}
        </div>
      </div>
      {!isViewModeOnly && (
        <>
          {isNewConnectRecommendations ? (
            <div className={styles.teamUpBtns}>
              {isNewConnectRecommendations && (
                <Button
                  variant="secondary"
                  className={styles.skipBtn}
                  onClick={handleSkipPostApplicationSuggestions}
                >
                  Skip
                </Button>
              )}

              <Button
                className={styles.submitBtn}
                onClick={handleSubmit}
                disabled={
                  !!loading ||
                  !(
                    profile?.application?.requestedUsers &&
                    profile?.application?.requestedUsers.length > 0
                  ) ||
                  profile?.cta?.disabled
                }
              >
                <IconComp
                  clickable
                  type={IconType.SendLight}
                  style={{ height: 16, width: 16 }}
                />
                Team Up
              </Button>
            </div>
          ) : isInterviewAvailabilityStep ? (
            <Button
              variant="main"
              className={styles.skipBtn}
              onClick={handleSkipPostApplicationSuggestions}
            >
              <IconComp
                clickable
                type={IconType.SendLight}
                style={{ height: 16, width: 16 }}
              />
              Next
            </Button>
          ) : (
            <ProfileTooltip
              disabled={
                !profile?.cta?.tooltipErrors ||
                profile?.cta?.tooltipErrors.length === 0
              }
              position={'bottom'}
              html={
                <div style={{ paddingTop: 8 }}>
                  Please fill the following required fields:
                  <ul style={{ textAlign: 'left', marginTop: 8 }}>
                    {profile?.cta?.tooltipErrors?.map((error) => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                </div>
              }
            >
              <Button
                className={styles.submitBtn}
                onClick={handleSubmit}
                disabled={profile?.cta?.disabled}
                data-testing-id="profile-cta-button"
              >
                <IconComp
                  type={IconType.SendLight}
                  style={{ height: 16, width: 16 }}
                />
                Submit
              </Button>
            </ProfileTooltip>
          )}
        </>
      )}

      <LoadingIndicator loading={loading} />
    </div>
  );
};

export default observer(ApplicationNavbar);
