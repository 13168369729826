import React from 'react';

export const ScheduleSuccessIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      width="132"
      height="80"
      viewBox="0 0 132 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="92" y="10" width="40" height="60" rx="4" fill="#FCFAFF" />
      <rect y="10" width="40" height="60" rx="4" fill="#FCFAFF" />
      <rect x="26" width="80" height="80" rx="12" fill="#EDE0FF" />
      <path
        d="M26 12C26 5.37258 31.3726 0 38 0H94C100.627 0 106 5.37258 106 12V21H26V12Z"
        fill="#C385FF"
      />
      <rect x="36" y="50" width="20" height="20" rx="4" fill="#D4A8FF" />
      <path
        d="M48.6428 55.9695C48.6851 55.8122 48.9083 55.8122 48.9506 55.9695L49.3364 57.4023C49.3512 57.4572 49.394 57.5001 49.4489 57.5148L50.8817 57.9006C51.039 57.943 51.039 58.1661 50.8817 58.2084L49.4489 58.5942C49.394 58.609 49.3512 58.6519 49.3364 58.7067L48.9506 60.1395C48.9083 60.2968 48.6851 60.2968 48.6428 60.1395L48.257 58.7067C48.2422 58.6519 48.1994 58.609 48.1445 58.5942L46.7117 58.2084C46.5544 58.1661 46.5544 57.943 46.7117 57.9006L48.1445 57.5148C48.1994 57.5001 48.2422 57.4572 48.257 57.4023L48.6428 55.9695Z"
        fill="#6D00D7"
      />
      <path
        d="M43.587 57.4195C43.6534 57.1727 44.0036 57.1727 44.0701 57.4195L44.6756 59.6682C44.6987 59.7543 44.766 59.8216 44.8521 59.8448L47.1008 60.4502C47.3477 60.5167 47.3477 60.8669 47.1008 60.9334L44.8521 61.5388C44.766 61.562 44.6987 61.6293 44.6756 61.7154L44.0701 63.9641C44.0036 64.211 43.6534 64.211 43.587 63.9641L42.9815 61.7154C42.9583 61.6293 42.8911 61.562 42.805 61.5388L40.5562 60.9334C40.3094 60.8669 40.3094 60.5167 40.5562 60.4502L42.805 59.8448C42.8911 59.8216 42.9583 59.7543 42.9815 59.6682L43.587 57.4195Z"
        fill="#6D00D7"
      />
    </svg>
  );
};
