import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { ClientRoleQuestion } from '@a_team/models/dist/MissionRole';

const useStyles = createUseStyles({
  root: {
    marginBottom: '24px',
  },
  title: {
    fontWeight: 500,
    marginBottom: 8,
  },
  text: {
    margin: 0,
  },
});

interface Props {
  questions: ClientRoleQuestion[];
}

const ClientQuestions = ({ questions }: Props): ReactElement | null => {
  const styles = useStyles();

  if (questions.length === 0) {
    return null;
  }

  return (
    <>
      {questions.map((question) => {
        return (
          <div className={styles.root} key={question.qid}>
            <div className={styles.title}>Company’s custom question</div>
            <p
              className={styles.text}
              data-testing-id={'mission-role-card-custom-question'}
            >
              {question.text}
            </p>
          </div>
        );
      })}
    </>
  );
};

export default ClientQuestions;
