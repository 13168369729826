import React from 'react';
import { Select, SelectOption, SelectProps } from '@ateams/components';

export interface ColumnsSelectProps {
  options: SelectOption[];
  onChange: SelectProps<true>['onChange'];
  value: SelectOption[];
  className?: string;
}

export const ColumnsSelect: React.FC<ColumnsSelectProps> = (props) => {
  const { onChange, options, value, className } = props;

  return (
    <Select
      label={'Shown Columns'}
      placeholder={'Select columns...'}
      isMulti
      hideTags
      onChange={onChange}
      options={options}
      value={value}
      className={className}
    />
  );
};
