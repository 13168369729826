import React from 'react';
import { createUseStyles } from 'react-jss';
import book from '../../../Summary/book.svg';
import rocket from './rocket.svg';
import { Button } from '@a_team/ui-components';
import { Colors } from '@ateams/components';

interface InitiativesGuidanceProps {
  showDismissOption?: boolean;
  dismissTimesheetInitiativesGuidance?: () => void;
}

const useStyles = createUseStyles({
  selectOptionsHeader: {
    padding: 24,
    background: '#FFFFFF',
    fontSize: 14,
  },
  headerTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '22px',
  },
  headerDesc: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,
    fontSize: 15,
    lineHeight: '24px',
    color: '#818388',
  },
  link: {
    marginTop: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,
    color: Colors.secondaryDark,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '19px',
  },
  dismissWrapper: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '19px',
    cursor: 'pointer',
  },
  dismissButton: {
    border: 'none !important',
    padding: '0 !important',
    margin: '0 !important',
  },
});

function InitiativesGuidance({
  showDismissOption,
  dismissTimesheetInitiativesGuidance,
}: InitiativesGuidanceProps) {
  const styles = useStyles();

  return (
    <div>
      <div className={styles.selectOptionsHeader}>
        <div className={styles.headerTitle}>
          <img src={rocket} alt="Initiatives" />
          Initiatives
        </div>
        <div className={styles.headerDesc}>
          Initiatives help everyone understand what common goal the team is
          working towards.
        </div>
        <a
          className={styles.link}
          target="_blank"
          href="https://guide.a.team/missions/tracking-your-time#28c2065d290345ac971d785704fbea7a"
          rel="noreferrer"
        >
          <img src={book} alt="What makes a good initiative" /> What makes a
          good initiative
        </a>

        {showDismissOption && dismissTimesheetInitiativesGuidance && (
          <div className={styles.dismissWrapper}>
            <Button
              className={styles.dismissButton}
              onClick={dismissTimesheetInitiativesGuidance}
              variant="ghost"
            >
              Dismiss
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default InitiativesGuidance;
