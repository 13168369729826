import TextEditor from '@src/components/TextEditor';
import { useEditor } from '@src/hooks/useEditor';
import { Editor } from '@tiptap/react';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface DescriptionProps {
  value: string;
  onChange: (description: string, descriptionHTML: string) => void;
}

const useStyles = createUseStyles({
  container: {
    // fixes issue placeholder not showing up https://github.com/ueberdosis/tiptap/issues/2659#issuecomment-1081864697
    '& .ProseMirror p.is-editor-empty:first-child::before': {
      content: 'attr(data-placeholder)',
      float: 'left',
      color: '#adb5bd',
      pointerEvents: 'none',
      height: 0,
    },
  },
  label: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '24px',
  },
});

const Description = ({ value, onChange }: DescriptionProps): JSX.Element => {
  const styles = useStyles();

  const { editor } = useEditor({
    noTitle: true,
    placeholder: 'Add a description',
    initialValue: value,
    onDescriptionChange: (editor: Editor) => {
      onChange(editor.getText(), editor.getHTML());
    },
  });

  const charCount = editor?.getText().length;

  return (
    <div className={styles.container}>
      <div>
        <label className={styles.label}>Description</label>
      </div>
      {editor && (
        <TextEditor
          hideHeading
          maxCharCount="2,000"
          editor={editor}
          charCount={charCount}
        />
      )}
    </div>
  );
};

export default Description;
