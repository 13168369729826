import {
  BasicConnectionObjectV2,
  ConnectionObjectV2,
  ConnectionStatus,
  ConnectionType,
  SharedJobExperience,
  SharedProjectExperience,
} from '@a_team/models/dist/ConnectionObject';
import { UserCardObject } from '@a_team/models/dist/UserObject';
import { DropdownMenu, DropdownMenuItem } from '@a_team/ui-components';
import {
  Breakpoints,
  Button,
  Colors,
  Icon,
  IconType,
  TextColors,
} from '@ateams/components';
import { addApostropheToName } from '@src/helpers/strings';
import useToggle from '@src/hooks/useToggle';
import {
  useApproveConnection,
  useGetSharedExperiencesWithDetails,
  useRejectConnectionRequest,
  useRequestConnection,
  useUpdateSharedExperience,
} from '@src/rq/connections';
import { useStores } from '@src/stores';
import cx from 'classnames';
import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Description from '../../partials/Description';
import Title from '../../partials/Title';
import UserExperiences from './UserExperiences';
import AddJobExperienceModalWrapper from './AddJobExperienceModalWrapper';
import { ExperienceType } from '@a_team/models/dist/ExperienceObject';
import EditSharedExperience from '../EditSharedExperience';
import { useGetUserExperiencesByUserId } from '@src/rq/experiences';
import { isNil } from 'lodash';
import { ConnectModalScreens, EditingExperienceObject } from '../../types';
import {
  applySharedJobExperiencesUpdates,
  applySharedProjectExperiencesUpdates,
  withUpdatedSharedJobExperiences,
  withUpdatedSharedProjectExperiences,
} from './utils';
import AddProjectExperienceModalWrapper from './AddProjectExperienceModalWrapper';
import EmptyExperiences from './EmptyExperiences';
import {
  ConnectionsModalSources,
  useAnalytics,
} from '@ateams/analytics/dist/platform';
import ExperienceConnectionButtons from './ExperienceConnectionButtons';
import EditSharedExperienceV2 from '../EditSharedExperienceV2';

interface SetWorkingExperienceProps {
  userToConnect: UserCardObject;
  newlyCreatedExperiencesIds: string[];
  source: ConnectionsModalSources;
  setNewlyCreatedExperiencesIds: (ids: string[]) => void;
  setScreen: (screen: ConnectModalScreens, hidePictures?: boolean) => void;
  setCurrentConnection: (connection: BasicConnectionObjectV2) => void;
  onConnectionAction: (connectionType?: ConnectionType) => void;
  connectActionOnCompleted?: (connection: ConnectionObjectV2) => void;
  onClose: (keepModalOpen?: boolean) => void;
}

const useStyles = createUseStyles({
  container: { display: 'flex', flexDirection: 'column' },
  wrapper: {
    padding: '0 24px 24px 24px',
  },
  buttonsWrapper: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'space-between',
    marginTop: '30px',
    position: 'sticky',
    bottom: '0',
    background: 'white',
    padding: '16px',
    borderTop: '1px solid #DADADC',
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  button: {
    borderRadius: '4px',
    padding: '11px 20px',
    width: 'auto',
    transition: 'background 0.3s ease',
  },
  cancelButton: {
    color: TextColors.regular,
    '&:hover': {
      background: '#DADADC',
    },
  },
  rejectConnectionButton: {
    color: TextColors.regular,
    '&:hover': {
      background: '#DADADC',
    },
  },
  connectButton: {
    color: TextColors.primary,
    '&:not([disabled]):hover': {
      background: '#5900b3',
    },
  },
  editDetails: {
    padding: '16px',
    paddingRight: 30,
    background: Colors.backgroundLight,
  },

  experiences: {
    border: '1px solid #DADADC',
    borderRadius: '8px',
    display: 'flex',
    marginTop: 40,
    flexDirection: 'column',
    // add border bottom to all direct children except the last one
    '& > *:not(:last-child)': {
      borderBottom: '1px solid #DADADC',
    },
  },
  addExperience: {
    padding: '5px 16px',
  },
  experiencesToggleContainer: {
    display: 'flex',
    gap: '20px',
    marginBlock: '16px',
    justifyContent: 'center',
  },
  experiencesToggle: {
    display: 'flex',
    gap: '20px',
    padding: '0 12px 6px 12px',
    color: '#818388',
    fontWeight: 500,
  },
  unstyledButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  selected: {
    color: TextColors.regular,
    borderBottom: `2px solid #FE630C`,
  },
  addExperienceButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  actionWrap: {
    display: 'flex',
    gap: '16px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    wrapper: {
      padding: '0 40px 40px 40px',
    },
  },
});

function SetWorkingExperience({
  userToConnect,
  newlyCreatedExperiencesIds,
  source,
  setNewlyCreatedExperiencesIds,
  setCurrentConnection,
  setScreen,
  connectActionOnCompleted,
  onClose,
  onConnectionAction,
}: SetWorkingExperienceProps) {
  const styles = useStyles();
  const { auth } = useStores();
  const [jobsExperiencesOpen, toggleJobsExperiencesOpen] = useToggle();
  const [projectsExperiencesOpen, toggleProjectsExperiencesOpen] = useToggle();
  const connections = userToConnect.connections;
  const analytics = useAnalytics();

  const [sharedJobsExperiences, setSharedJobsExperiences] = useState<
    SharedJobExperience[]
  >([]);
  const [sharedProjectsExperiences, setSharedProjectsExperiences] = useState<
    SharedProjectExperience[]
  >([]);
  const [editingExperience, setEditingExperience] =
    useState<EditingExperienceObject>();

  const [isRequesterExperiences, setIsRequesterExperiences] = useToggle(true);

  const existingConnection = useMemo(
    () =>
      connections.find((connection) => connection.type === ConnectionType.Work),
    [connections.length],
  );

  const {
    error,
    data: userExperiences,
    isFetching: fetchingExperiencesByUserId,
  } = useGetUserExperiencesByUserId({
    userId: isRequesterExperiences ? (auth.uid as string) : userToConnect.uid,
    enabled: !existingConnection?.canApprove,
  });

  const {
    error: sharedExpWithDetailsError,
    data: sharedExpWithDetails,
    isFetching: fetchingSharedExperiencesWithDetails,
  } = useGetSharedExperiencesWithDetails({
    connectionId: existingConnection?.cid,
    onSuccess: (data) => {
      setSharedJobsExperiences(data.jobs);
      setSharedProjectsExperiences(data.projects);
    },
  });

  const fetchingExperiences =
    fetchingExperiencesByUserId || fetchingSharedExperiencesWithDetails;

  const allExperiences = existingConnection?.canApprove
    ? sharedExpWithDetails
    : userExperiences;

  const onOpenJobExperienceModal = (): void => {
    toggleJobsExperiencesOpen();
    auth.user?.uid &&
      analytics.trackCreateNewJobProjectClickedOnConnectionsModal(
        auth.user?.uid,
        source,
      );
  };

  const onOpenProjectExperienceModal = (): void => {
    toggleProjectsExperiencesOpen();
    auth.user?.uid &&
      analytics.trackCreateNewJobProjectClickedOnConnectionsModal(
        auth.user?.uid,
        source,
      );
  };

  const { mutate: requestConnection, isLoading: isRequesting } =
    useRequestConnection({
      source,
      connectActionOnCompleted: connectActionOnCompleted,
    });

  const { mutate: updateConnection, isLoading: isUpdating } =
    useUpdateSharedExperience(connectActionOnCompleted);

  const { mutate: approveConnection, isLoading: isApprovingConnection } =
    useApproveConnection({
      source,
      connectActionOnCompleted,
    });

  const { mutate: rejectConnectionRequest, isLoading: isRejectingConnection } =
    useRejectConnectionRequest({
      source,
      connectActionOnCompleted,
    });

  const handleWorkConnectionClick = async (): Promise<void> => {
    onConnectionAction(ConnectionType.Work);
    if (!existingConnection) {
      await requestConnection({
        requestedUserId: userToConnect.uid,
        type: ConnectionType.Work,
        sharedJobsExperiences,
        sharedProjectsExperiences,
      });
      onClose(true);
      return;
    }

    if (
      existingConnection.status === ConnectionStatus.Pending &&
      !existingConnection.canApprove
    ) {
      await updateConnection({
        connectionId: existingConnection.cid,
        requestedUserId: userToConnect.uid,
        sharedJobsExperiences,
        sharedProjectsExperiences,
      });
      onClose(true);
      return;
    }

    await approveConnection({
      connection: existingConnection,
      sharedJobsExperiences,
      sharedProjectsExperiences,
    });
    setCurrentConnection(existingConnection);
    setScreen(ConnectModalScreens.ConnectionConfirmed);
    return;
  };

  const handleRejectConnection = () => {
    if (existingConnection && existingConnection.canApprove) {
      onConnectionAction(ConnectionType.Work);
      rejectConnectionRequest({ connection: existingConnection });
      onClose(true);
    }
  };

  const loading = isRequesting || isApprovingConnection || isUpdating;

  const handleAddProjectExperience = (
    projectExperience: SharedProjectExperience,
  ) => {
    const foundProjectExperience = sharedProjectsExperiences.find(
      (experience) => experience.eid === projectExperience.eid,
    );

    if (foundProjectExperience) {
      setSharedProjectsExperiences(
        sharedProjectsExperiences.filter(
          (experience) => experience.eid !== projectExperience.eid,
        ),
      );
    } else {
      setSharedProjectsExperiences([
        ...sharedProjectsExperiences,
        projectExperience,
      ]);
    }
  };

  const handleAddJobExperience = (jobExperience: SharedJobExperience) => {
    const foundJobExperience = sharedJobsExperiences.find(
      (experience) => experience.eid === jobExperience.eid,
    );

    if (foundJobExperience) {
      setSharedJobsExperiences(
        sharedJobsExperiences.filter(
          (experience) => experience.eid !== jobExperience.eid,
        ),
      );
    } else {
      setSharedJobsExperiences([...sharedJobsExperiences, jobExperience]);
    }
  };

  const onRemoveExperience = (eid: string) => {
    setSharedJobsExperiences(
      sharedJobsExperiences.filter((experience) => experience.eid !== eid),
    );
    setSharedProjectsExperiences(
      sharedProjectsExperiences.filter((experience) => experience.eid !== eid),
    );
  };

  const checkIfExperienceIsSelected = (
    experience: SharedJobExperience | SharedProjectExperience,
  ) => {
    if (isRequesterExperiences) {
      return (
        sharedJobsExperiences.some(
          (jobExperience) => jobExperience.eid === experience.eid,
        ) ||
        sharedProjectsExperiences.some(
          (projectExperience) => projectExperience.eid === experience.eid,
        )
      );
    }

    return (
      sharedJobsExperiences.some(
        (jobExperience) => jobExperience.eid === experience.eid,
      ) ||
      sharedProjectsExperiences.some(
        (projectExperience) => projectExperience.eid === experience.eid,
      )
    );
  };

  const handleUpdatedExperience = (experience: EditingExperienceObject) => {
    setEditingExperience(undefined);
    if (experience.type === ExperienceType.Project) {
      setSharedProjectsExperiences(
        applySharedProjectExperiencesUpdates(
          sharedProjectsExperiences,
          experience,
        ),
      );
    } else {
      setSharedJobsExperiences(
        applySharedJobExperiencesUpdates(sharedJobsExperiences, experience),
      );
    }
  };

  const handleAddNewlyCreatedExperience = (eid: string) => {
    setNewlyCreatedExperiencesIds([...newlyCreatedExperiencesIds, eid]);
  };

  const handleEditExperience = (experience: EditingExperienceObject) => {
    setEditingExperience(experience);
    setScreen(ConnectModalScreens.SetWorkingExperience, true);
  };
  const handleCancelEditing = () => {
    setEditingExperience(undefined);
    setScreen(ConnectModalScreens.SetWorkingExperience, false);
  };

  if (editingExperience) {
    if (editingExperience.type === ExperienceType.Project) {
      return (
        <EditSharedExperienceV2
          editingExperience={editingExperience}
          onSave={handleUpdatedExperience}
          open={true}
          onClose={handleCancelEditing}
        />
      );
    } else {
      return (
        <EditSharedExperience
          editingExperience={editingExperience}
          sharedWith={userToConnect.fullName}
          onSave={handleUpdatedExperience}
          onCancel={handleCancelEditing}
        />
      );
    }
  }

  const renderHeader = () => {
    const titleText = existingConnection?.canApprove
      ? 'Edit shared experiences before connecting'
      : 'Share your experience working as a team';
    const descriptionText = existingConnection?.canApprove
      ? `${userToConnect.firstName} included some of your shared work experiences. We recommend editing them to clarify your role and contribution.`
      : `Demonstrating how you’ve worked with ${userToConnect.firstName} increases your chances of being selected for a mission by showing companies you have a proven track record of working well together.`;

    return (
      <>
        <Title text={titleText} />
        <Description text={descriptionText} />
      </>
    );
  };

  const hasExperiences =
    (allExperiences?.jobs?.length ?? 0) > 0 ||
    (allExperiences?.projects?.length ?? 0) > 0;

  const renderExperiencesHeader = () => {
    if (existingConnection?.canApprove) {
      return undefined;
    }

    if (isRequesterExperiences) {
      return (
        <div className={styles.addExperience}>
          <DropdownMenu
            style={{
              marginRight: 15,
              marginTop: 12,
              width: '150px',
            }}
            triggerComponent={
              <button
                className={cx(
                  styles.addExperienceButton,
                  styles.unstyledButton,
                )}
              >
                <Icon
                  title={false}
                  style={{
                    fontSize: 16,
                  }}
                  type={IconType.PlusBlack}
                />{' '}
                Add experience
              </button>
            }
          >
            <DropdownMenuItem onClick={onOpenJobExperienceModal}>
              <span>Add a job</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={onOpenProjectExperienceModal}>
              <span>Add a project</span>
            </DropdownMenuItem>
          </DropdownMenu>
        </div>
      );
    }

    if (!hasExperiences) {
      return null;
    }

    return (
      <div className={styles.editDetails}>
        Edit the details of the experiences before sending the request, to
        highlight your contribution.
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {renderHeader()}
        {!existingConnection?.canApprove && (
          <>
            <div className={styles.experiencesToggleContainer}>
              <button
                onClick={setIsRequesterExperiences}
                className={cx(styles.experiencesToggle, styles.unstyledButton, {
                  [styles.selected]: isRequesterExperiences,
                })}
                data-testing-id="my-experiences"
              >
                My experiences
              </button>
              <button
                onClick={setIsRequesterExperiences}
                className={cx(styles.experiencesToggle, styles.unstyledButton, {
                  [styles.selected]: !isRequesterExperiences,
                })}
                data-testing-id="requested-user-experiences"
              >
                {addApostropheToName(userToConnect.firstName)} experiences
              </button>
            </div>
          </>
        )}

        <div className={styles.experiences}>
          {renderExperiencesHeader()}
          {hasExperiences && !fetchingExperiences && (
            <UserExperiences
              onRemoveExperience={onRemoveExperience}
              newlyCreatedExperiencesIds={newlyCreatedExperiencesIds}
              canApprove={existingConnection?.canApprove}
              projects={withUpdatedSharedProjectExperiences(
                sharedProjectsExperiences,
                allExperiences?.projects,
              )}
              jobs={withUpdatedSharedJobExperiences(
                sharedJobsExperiences,
                allExperiences?.jobs,
              )}
              error={!isNil(error) || !isNil(sharedExpWithDetailsError)}
              sharedWith={
                !isRequesterExperiences ? userToConnect.fullName : undefined
              }
              onEditExperience={handleEditExperience}
              onAddProjectExperience={handleAddProjectExperience}
              onAddJobExperience={handleAddJobExperience}
              canEdit={
                existingConnection?.canApprove || !isRequesterExperiences
              }
              isRequesterExperiences={isRequesterExperiences}
              checkIfExperienceIsSelected={checkIfExperienceIsSelected}
            />
          )}

          {!hasExperiences &&
            !isRequesterExperiences &&
            !fetchingExperiences &&
            auth.user?.firstName &&
            userToConnect.firstName && (
              <EmptyExperiences
                requestorFirstName={
                  !isRequesterExperiences ? userToConnect.firstName : undefined
                }
              />
            )}
        </div>
      </div>

      <div className={styles.buttonsWrapper}>
        <Button
          className={cx(styles.button, styles.cancelButton)}
          onClick={() => setScreen(ConnectModalScreens.Home)}
          size="small"
          color="regularLight"
          loading={!!loading}
        >
          Back
        </Button>
        <div className={styles.actionWrap}>
          {existingConnection?.canApprove && (
            <Button
              onClick={handleRejectConnection}
              className={cx(styles.button, styles.rejectConnectionButton)}
              size="small"
              color="regularLight"
              loading={!!isRejectingConnection}
              data-testing-id="reject-work-connection-button"
            >
              Decline
            </Button>
          )}
          <ExperienceConnectionButtons
            disabled={
              sharedJobsExperiences.length === 0 &&
              sharedProjectsExperiences.length === 0
            }
            onClick={handleWorkConnectionClick}
            className={cx(styles.button, styles.connectButton)}
            loading={!!loading}
            existingConnection={existingConnection}
          />
        </div>
      </div>
      <AddJobExperienceModalWrapper
        onClose={toggleJobsExperiencesOpen}
        open={jobsExperiencesOpen}
        handleAddNewlyCreatedExperience={handleAddNewlyCreatedExperience}
        onAddJobExperience={handleAddJobExperience}
      />
      <AddProjectExperienceModalWrapper
        onClose={toggleProjectsExperiencesOpen}
        open={projectsExperiencesOpen}
        handleAddNewlyCreatedExperience={handleAddNewlyCreatedExperience}
        onAddProjectExperience={handleAddProjectExperience}
      />
    </div>
  );
}

export default SetWorkingExperience;
