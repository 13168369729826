import { Stores } from '../stores';
import { match } from 'react-router-dom';
import { InvitationId } from '@a_team/models/dist/InvitationObject';

export type RegistrationMatch = match<{
  invitation: InvitationId;
  nonce: string;
}>;

// exported functions

export function loadRegistrationInvitation(
  stores: Stores,
  match: RegistrationMatch,
): Promise<unknown> {
  const { invitation: invitationId, nonce } = match.params;
  const { registration } = stores;

  if (registration.invitation) {
    return Promise.resolve();
  }

  return registration.fetchInvitation(invitationId, nonce);
}
