import { action, computed, observable } from 'mobx';
import AdminNotesObject, {
  AdminNotesMission,
} from '@a_team/models/dist/AdminNotesObject';
import {
  UnverifiedUserScrubbedValues,
  UserScrubbed,
} from '@a_team/models/dist/UserObject';
import { Stores } from '@src/stores/index';
import { AdminVettingProcessWithAudit } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { apiMissions } from '@ateams/api';
import { isUndefined } from 'lodash';

export interface AdminNotesStoreData {
  selectionTeam: boolean;
  verified: boolean;
  data: AdminNotesObject;
  hasBeenScrubbed: boolean;
  beenOnMission: boolean;
  vettingScheduled: boolean;
  vettingProcesses?: AdminVettingProcessWithAudit[];
}

export default class AdminNotes implements AdminNotesStoreData {
  @observable public data: AdminNotesStoreData['data'];
  @observable selectionTeam: AdminNotesStoreData['selectionTeam'] = false;
  @observable verified: AdminNotesStoreData['verified'] = false;
  @observable hasBeenScrubbed: AdminNotesStoreData['hasBeenScrubbed'] = false;
  @observable beenOnMission: AdminNotesStoreData['beenOnMission'] = false;
  @observable vettingScheduled: AdminNotesStoreData['vettingScheduled'] = false;
  @observable vettingProcesses: AdminNotesStoreData['vettingProcesses'];

  private authStore: Stores['auth'];

  public constructor(
    adminNotesData: AdminNotesObject,
    isVetter: boolean,
    scrubbed: UserScrubbed | undefined,
    vettingScheduled: boolean,
    auth: Stores['auth'],
    beenOnMission: boolean,
  ) {
    this.authStore = auth;
    this.data = adminNotesData;
    this.selectionTeam = !!isVetter;
    this.beenOnMission = beenOnMission;
    this.verified = scrubbed
      ? !UnverifiedUserScrubbedValues.includes(scrubbed)
      : false;
    this.hasBeenScrubbed = Boolean(scrubbed);
    this.vettingScheduled = vettingScheduled;
  }

  serialize = (): AdminNotesStoreData => {
    return {
      data: this.data,
      verified: this.verified,
      selectionTeam: this.selectionTeam,
      hasBeenScrubbed: this.hasBeenScrubbed,
      beenOnMission: this.beenOnMission,
      vettingScheduled: this.vettingScheduled,
      vettingProcesses: this.vettingProcesses,
    };
  };

  setRolePerformanceFlag = async (
    updatedMission: AdminNotesMission,
  ): Promise<void> => {
    if (
      !updatedMission.rid ||
      isUndefined(updatedMission.hasPerformanceIssue)
    ) {
      return;
    }
    await apiMissions.setRolePerformanceFlag(
      this.authStore,
      updatedMission.rid,
      updatedMission.hasPerformanceIssue,
    );
    this.updateMission(updatedMission);
  };

  @computed get interviewed(): boolean {
    return (
      this.data.scores?.accent !== undefined ||
      this.data.scores?.english !== undefined ||
      this.data.scores?.expertise !== undefined ||
      this.data.scores?.interactionExperience !== undefined
    );
  }

  @computed get isMarkedAsRejected(): boolean {
    return !!this.data.markedAsRejectedAt;
  }

  @action setMarkedAsRejected = (rejected: boolean): void => {
    this.data.markedAsRejectedAt = rejected ? new Date() : undefined;
  };

  @action updateMission = (newMission: AdminNotesMission): void => {
    this.data.missions = this.data.missions.map((mission) =>
      mission.mid === newMission.mid ? newMission : mission,
    );
  };

  @action setVerified = (verified: boolean): void => {
    this.verified = verified;
  };

  @action setSelectionTeam = (isVetter: boolean): void => {
    this.selectionTeam = isVetter;
  };

  @action setVettingScheduled = (vettingScheduled: boolean): void => {
    this.vettingScheduled = vettingScheduled;
  };
}
