import { apiRequestBuilders } from '@ateams/api';
import { useStores } from '@src/stores';
import { useMutation } from '@tanstack/react-query';

export const useCreateRequestBuilder = () => {
  const stores = useStores();

  return useMutation({
    mutationFn: async ({
      urgency,
      roles,
      additionalTeammatesContribution,
      suggestedBuilders,
      mission,
    }: {
      urgency: 'high' | 'moderate' | 'low';
      roles: string[];
      additionalTeammatesContribution: string;
      suggestedBuilders?: string;
      mission: string;
    }) =>
      apiRequestBuilders.createRequestBuilder(
        stores.auth,
        mission,
        urgency,
        roles,
        additionalTeammatesContribution,
        suggestedBuilders,
      ),
  });
};
