import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  Margins,
  Select,
  SelectProps,
  TimezoneNameSelect,
} from '@ateams/components';
import {
  LocalHourRange,
  WorkingHoursMissionRoleSchema,
} from '@a_team/models/dist/WorkingHoursObject';
import TimezoneObject from '@a_team/models/dist/TimezoneObject';
import cx from 'classnames';
import { rawTimeZones } from '@vvo/tzdb';
import {
  getHoursFromMinutes,
  getWorkingHoursTimeOptions,
} from '@src/views/Profile/Sidebar/WorkingHours/utils';

const DEFAULT_START_TIME = 9 * 60; // Default Start time is 09:00
const DEFAULT_END_TIME = 18 * 60; // Default End time is 18:00

interface WorkingHoursMissionRoleUi
  extends Omit<Partial<WorkingHoursMissionRoleSchema>, 'daily'> {
  daily: Partial<LocalHourRange>[];
}

interface SelectOption {
  value: string;
  label: string;
}
interface Props {
  onChange(data?: WorkingHoursMissionRoleSchema | undefined): void;
  workingHours?: WorkingHoursMissionRoleSchema;
  defaultValue?: WorkingHoursMissionRoleUi;
  defaultTimezone?: TimezoneObject;
  selectInputStyle?: SelectProps['style'];
  className?: string;
  mode?: 'default' | 'inline';
  readonly?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
}

const useStyles = createUseStyles({
  tableRow: {
    alignItems: 'center',
    marginBottom: 10,
    display: 'flex',
  },
  inline: {
    display: 'flex',
    '& $tableRow': {
      flex: 1,
      marginRight: 0,
      '& ~ $tableRow': {
        marginLeft: 25,
      },
    },
  },
  selectInput: (props: Props) => {
    return {
      '&>div>div': props?.selectInputStyle || {},
    };
  },
});
const hoursValues = Array(9 * 2)
  .fill(0)
  .map((_, i) => {
    const hours = Math.floor((i + 1) / 2);
    const minutes = ((i + 1) % 2) * 30;
    const totalMinutes = hours * 60 + minutes;
    return {
      value: String(totalMinutes),
      label: `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`,
    };
  });

type SelectRef = SelectProps['ref'];
export const TeamWorkWorkingHours = (props: Props): ReactElement => {
  const styles = useStyles(props);
  const {
    onChange,
    className,
    mode = 'default',
    workingHours = {} as WorkingHoursMissionRoleUi,
    disabled,
    defaultValue,
    isClearable = true,
    defaultTimezone,
  } = props;

  const [workingHoursState, setWorkingHoursState] = useState<
    WorkingHoursMissionRoleUi | undefined
  >({
    ...defaultValue,
    ...(workingHours as WorkingHoursMissionRoleUi),
  });
  const [maxMinutes, setMaxMinutes] = useState(720);
  const selectRefs: Record<string, SelectRef> = {
    '0-from': useRef<SelectRef>(),
    '0-to': useRef<SelectRef>(),
    'overlap-minutes': useRef<SelectRef>(),
  };
  const { startTime, endTime } = workingHoursState?.daily?.[0] || {};

  useEffect(() => {
    const newMaxMinutes =
      typeof endTime !== 'undefined' && typeof startTime !== 'undefined'
        ? endTime - startTime
        : 720;

    setMaxMinutes(newMaxMinutes);
    if (
      workingHoursState?.numberOfMinutesOverlap &&
      workingHoursState?.numberOfMinutesOverlap > newMaxMinutes
    ) {
      selectRefs[`overlap-minutes`].current?.select?.clearValue();
    }
  }, [endTime, startTime]);

  const setWorkingHoursMinutesOverLap = (
    value?: WorkingHoursMissionRoleUi | undefined,
  ) => {
    setWorkingHoursState(value);
    onChange(value as WorkingHoursMissionRoleSchema);
  };

  const setWorkingHoursTimezone = (name?: string, utcOffset?: number) => {
    if (!workingHoursState) {
      return;
    }
    let newWorkingHours: WorkingHoursMissionRoleUi | undefined = {
      ...workingHoursState,
    };
    newWorkingHours['name'] = name;
    newWorkingHours['utcOffset'] = utcOffset;

    if (!newWorkingHours?.name?.length) {
      newWorkingHours = undefined;
    } else {
      if (!newWorkingHours.daily && !newWorkingHours.numberOfMinutesOverlap) {
        newWorkingHours.daily = [
          {
            startTime: DEFAULT_START_TIME,
            endTime: DEFAULT_END_TIME,
          },
        ];
      }
    }

    setWorkingHoursState(newWorkingHours);
    onChange(newWorkingHours as WorkingHoursMissionRoleSchema);
  };
  const timezone = rawTimeZones.find((e) => e.name === workingHoursState?.name);
  const workingHoursOptionsStart = getWorkingHoursTimeOptions({
    startingMinutes: 0,
  });
  const workingHoursOptionsEnd = getWorkingHoursTimeOptions({
    startingMinutes: 0,
  });

  return (
    <div className={cx(className, mode === 'inline' && styles.inline)}>
      <div className={styles.tableRow}>
        <TimezoneNameSelect
          disabled={disabled}
          isClearable={true}
          margin={Margins.default}
          inputId={`tzfrom`}
          value={timezone?.name}
          onChange={(selectedTimezone) => {
            if (!Array.isArray(selectedTimezone)) {
              setWorkingHoursTimezone(
                selectedTimezone?.name,
                selectedTimezone?.utcOffset,
              );
            }
          }}
          placeholder={'Working hours timezone'}
        />
      </div>
      <div className={styles.tableRow}>
        <Select
          key={`${startTime}-from`}
          className={styles.selectInput}
          selectRef={selectRefs[`0-from`]}
          defaultValue={workingHoursOptionsStart.find(
            (e) => Number(e.value) === startTime,
          )}
          isOptionDisabled={(option: SelectOption) => {
            if (!option) return false;
            return Number(option.value) > Number(endTime);
          }}
          hideSelectedOptions={false}
          options={workingHoursOptionsStart}
          placeholder={'Start Time'}
          onChange={(value) => {
            const { daily = [] } = workingHoursState || {};
            const slot = daily[0] || {};
            const { endTime } = slot as LocalHourRange;
            setWorkingHoursMinutesOverLap({
              ...workingHoursState,
              name: workingHoursState?.name ?? defaultTimezone?.name ?? '',
              daily: [
                {
                  startTime: value?.value ? Number(value.value) : undefined,
                  endTime,
                },
              ],
            });
          }}
          margin={'none'}
          isDisabled={disabled}
          isClearable={isClearable}
        />
        <div
          style={{
            width: '40px',
            textAlign: 'center',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {' - '}
        </div>
        <Select
          key={`${endTime}-to`}
          className={styles.selectInput}
          selectRef={selectRefs[`0-to`]}
          defaultValue={workingHoursOptionsEnd.find(
            (e) => Number(e.value) === endTime,
          )}
          isOptionDisabled={(option: SelectOption) => {
            if (!option) return false;
            return Number(option.value) < Number(startTime);
          }}
          hideSelectedOptions={false}
          options={workingHoursOptionsEnd}
          placeholder={'End Time'}
          onChange={(value) => {
            const { daily = [] } = workingHoursState || {};
            const slot = daily[0] || {};
            const { startTime } = slot as LocalHourRange;
            setWorkingHoursMinutesOverLap({
              ...workingHoursState,
              name: workingHoursState?.name ?? defaultTimezone?.name ?? '',
              daily: [
                {
                  startTime: startTime,
                  endTime: value?.value ? Number(value.value) : undefined,
                },
              ],
            });
          }}
          margin={'none'}
          isDisabled={disabled}
          isClearable={isClearable}
        />
      </div>
      <div className={styles.tableRow}>
        <Select
          key={`${startTime}-${endTime}-overlap-minutes`}
          selectRef={selectRefs[`overlap-minutes`]}
          defaultValue={
            workingHoursState?.numberOfMinutesOverlap
              ? {
                  value: workingHoursState?.numberOfMinutesOverlap?.toString(),
                  label: getHoursFromMinutes(
                    workingHoursState?.numberOfMinutesOverlap ?? 0,
                    true,
                  ),
                }
              : undefined
          }
          isOptionDisabled={(option: SelectOption) => {
            return Number(option.value) > maxMinutes;
          }}
          options={hoursValues}
          placeholder={'Hours overlap'}
          onChange={(value) => {
            setWorkingHoursMinutesOverLap({
              ...workingHoursState,
              name: workingHoursState?.name ?? defaultTimezone?.name ?? '',
              daily: workingHoursState?.daily ?? [],
              numberOfMinutesOverlap: Number(value?.value ?? '0'),
            });
          }}
          margin={'none'}
          isDisabled={disabled}
          isClearable={isClearable}
        />
      </div>
    </div>
  );
};
