import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { AutoSizer, Table, TableRowRenderer } from 'react-virtualized';
import { Card } from '@ateams/components';
import { Pagination, PaginationProps } from '@src/components/Pagination';
import { VettingProcess } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { Colors } from '@ateams/components';
import { VettingColumnConfig } from './columns/mapping';
import { VettingProcessRow } from './vetting-process-row';
import { SortableHeader } from './sortable-header';
import {
  FetchVettingProcessesSorting,
  FetchVettingProcessesSortingKeys,
  OrderingOperators,
} from '@ateams/api/dist/endpoints/vetting-process';
import { useStores } from '@src/stores';
import { CurrentUserObject } from '@a_team/models/dist/UserObject';

export type OnSortChange = (
  sortingKey: FetchVettingProcessesSortingKeys,
  operator?: OrderingOperators,
) => void;

interface Props {
  columns: VettingColumnConfig<VettingProcess>[];
  rows: VettingProcess[];
  paginationProps?: PaginationProps;
  isAdminView: boolean;
  sorting: FetchVettingProcessesSorting;
  onSortChange: OnSortChange;
}

const useStyles = createUseStyles({
  container: {
    padding: 0,
    overflowX: 'auto',
    overflowY: 'hidden',
    borderRadius: 0,
    margin: '0 0 20px 0',
    width: 'fit-content',
    maxWidth: '100%',
  },
  paginationContainer: {
    width: '100%',
    padding: '0 10px',

    /** Sticky Footer */
    position: 'sticky',
    bottom: 0,
    left: 0,
    background: Colors.backgroundWhite,

    /** {@link https://stackoverflow.com/questions/41882616/why-border-is-not-visible-with-position-sticky-when-background-color-exists} */
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      borderTop: `1px solid ${Colors.regular}`,
    },
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '40px',
    borderRight: `1px solid ${Colors.regular}`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    borderBottom: `1px solid ${Colors.regular}`,
  },
});

const SCROLL_WIDTH = 15;
const ROW_HEIGHT = 115;

const VettingTable: React.FC<Props> = (props) => {
  const { auth } = useStores();
  const styles = useStyles();
  const { columns, rows, paginationProps, isAdminView, sorting, onSortChange } =
    props;
  /** {@link https://stackoverflow.com/questions/65016565/react-why-is-that-changing-the-current-value-of-ref-from-useref-doesnt-trigger/68285048#68285048} */

  const [currentUser, setCurrentUser] = useState<CurrentUserObject | null>(
    null,
  );

  useEffect(() => {
    if (auth.user) {
      setCurrentUser(auth.user);
    }
  }, [auth.user]);

  const rowRenderer: TableRowRenderer = useCallback(
    (tableRowProps) => {
      const row = rows[tableRowProps.index];

      return (
        <VettingProcessRow
          {...tableRowProps}
          columns={columns}
          vettingProcess={row}
          isAdminView={isAdminView}
          isCurrentUserTheInterviewer={row.vetter?.user.id === currentUser?.uid}
        />
      );
    },
    [rows, columns, isAdminView],
  );

  const tableWidth = useMemo(() => {
    return (
      columns.reduce((sum, column) => sum + column.width, 0) + SCROLL_WIDTH
    );
  }, [columns]);

  return (
    <Card className={styles.container}>
      <div className={styles.headerContainer}>
        {columns.map(({ header, width, sortingKey }, i) => (
          <div key={i} className={styles.tableHeader} style={{ width }}>
            {header}
            {sortingKey && (
              <SortableHeader
                sortingState={
                  sorting.find(({ sortingKey: cur }) => sortingKey === cur)
                    ?.operator
                }
                onSortChange={(newOperator) =>
                  onSortChange(sortingKey, newOperator)
                }
              />
            )}
          </div>
        ))}
      </div>

      <div
        style={{
          height: `min(calc(100vh - 210px), ${rows.length * ROW_HEIGHT}px)`,
        }}
      >
        <AutoSizer>
          {({ height }) => (
            <Table
              height={Math.min(height, rows.length * ROW_HEIGHT)}
              width={tableWidth}
              headerHeight={0}
              rowHeight={ROW_HEIGHT}
              rowCount={rows.length}
              rowRenderer={rowRenderer}
              rowGetter={({ index }) => rows[index]}
            />
          )}
        </AutoSizer>
      </div>
      {paginationProps && (
        <div className={styles.paginationContainer}>
          <Pagination {...paginationProps} />
        </div>
      )}
    </Card>
  );
};

export default observer(VettingTable);
