import SectionHeading from '@src/components/SectionHeading';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useCommonStyles } from './common/commonStyles';

function TableSkeleton() {
  const commonStyles = useCommonStyles({ cellPadding: 16 });

  return (
    <div>
      <SectionHeading
        style={{
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
          gap: 16,
        }}
      >
        Time tracking{' '}
        <span
          style={{
            height: '1px',
            width: '20px',
            backgroundColor: '#DADADC',
            display: 'inline-block',
          }}
        />
        <span style={{ color: '#62646A', display: 'flex', gap: 10 }}>
          <Skeleton
            width={100}
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
            }}
          />{' '}
          -{' '}
          <Skeleton
            width={100}
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
            }}
          />{' '}
        </span>
      </SectionHeading>
      <div className={commonStyles.container}>
        <table>
          <thead>
            <tr>
              <th>
                <Skeleton width={130} />
              </th>
              <th>
                <Skeleton width={80} />
              </th>
              <th>
                <Skeleton width={180} />
              </th>
              <th>
                <Skeleton width={420} />
              </th>
              <th>
                <Skeleton width={280} />
              </th>
              <th>
                <Skeleton width={25} />
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 10 }).map((_, index) => (
              <tr key={index}>
                <td>
                  <Skeleton width={130} />
                </td>
                <td>
                  <Skeleton width={80} />
                </td>
                <td>
                  <Skeleton width={180} />
                </td>
                <td>
                  <Skeleton width={420} />
                </td>
                <td>
                  <Skeleton width={280} />
                </td>
                <td>
                  <Skeleton width={25} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TableSkeleton;
