import React from 'react';
import { createUseStyles } from 'react-jss';
import CompanyLogoFallback from '../CompanyLogoFallback';

interface CompanyLogoWithOwnerProps {
  logoUrl?: string | undefined | null;
  profilePictureURL?: string | undefined | null;
  size?: number;
}

const useStyles = createUseStyles({
  imageContainer: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
  },
  profilePictureURL: {
    position: 'absolute',
    bottom: -2,
    right: -2,
    borderRadius: '50%',
    border: '2px solid #FFFFFF',
  },
  noLogo: {
    height: 48,
    width: 48,
    borderRadius: '8px',
    background:
      'linear-gradient(197.15deg, rgb(148, 254, 12) 0%, rgb(84, 214, 255) 88.21%);',
  },
});

const CompanyLogoWithOwner = ({
  logoUrl,
  profilePictureURL,
  size,
}: CompanyLogoWithOwnerProps): JSX.Element => {
  const styles = useStyles();

  const finalSize = size ?? 48;

  return (
    <div className={styles.imageContainer}>
      {logoUrl && profilePictureURL ? (
        <div style={{ width: finalSize }}>
          <CompanyLogoFallback logoUrl={logoUrl} size={finalSize} />
          <img
            width={20}
            height={20}
            className={styles.profilePictureURL}
            src={profilePictureURL}
            alt="User avatar"
          />
        </div>
      ) : (
        <div className={styles.noLogo} />
      )}
    </div>
  );
};

export default CompanyLogoWithOwner;
