import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
  },
});

const Honesty = (): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <h4>Honesty:</h4>

      <p>
        <strong>Underpromise, Overdeliver:</strong> only join missions you
        actually have the bandwidth and are qualified for. This might mean
        turning down a really great mission once in a while.{' '}
      </p>

      <div>
        <p>
          <strong>1. Only take work you have the time for.</strong>
        </p>
        <p>
          Make sure your availability for a mission you ask to join isn’t
          interefered by other projects you are currently engaged in.{' '}
        </p>
      </div>

      <div>
        <p>
          <strong>2. Only take work you have the expertise for.</strong>
        </p>
        <p>
          Companies vary on how much experience with their tech stack and
          product requirements they’ll expect you to have on day one. Some will
          be okay with you spending time getting up to speed or learning a new
          language, but others may not.
        </p>
      </div>

      <div>
        <p>
          <strong>3. Work on missions you’re genuinely excited about.</strong>
        </p>
        <p>
          Being motivated and passionate about building a product is as
          important as it gets. We diversify the missions we get on board - so
          wait for ones that will actually excite you, and to which you can
          contribute from the off.
        </p>
      </div>

      <div>
        <p>
          <strong>4. Keep your information up to date.</strong>
        </p>
        <p>
          Make sure that you provide accurate information about your location,
          availability, skills, and experience on your profile and requests, and
          update it as necessary.
        </p>
      </div>
    </div>
  );
};

export default Honesty;
