import React, { ReactElement } from 'react';
import { Breakpoints, Button } from '@ateams/components';
import { createUseStyles } from 'react-jss';

interface Props {
  canContinue?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const useStyles = createUseStyles({
  footer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    padding: 16,
    borderTop: '1px solid #DADADC',
    position: 'sticky',
    background: '#fff',
    bottom: 0,
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    footer: {
      flexDirection: 'row',
    },
    inner: {
      marginBottom: 0,
    },
  },
});

export const Footer = (props: Props): ReactElement => {
  const styles = useStyles();
  const { canContinue, onClose, onSubmit } = props;
  return (
    <div className={styles.footer}>
      <Button
        squared
        size={'small'}
        width={'auto'}
        color={'backgroundDark'}
        onClick={onClose}
      >
        Cancel
      </Button>
      <div className={styles.inner}>
        {canContinue && (
          <Button
            squared
            size={'small'}
            width={'auto'}
            color={'backgroundDark'}
            onClick={onSubmit}
            style={{ marginRight: 8 }}
            dataTestingId={
              'mission-application-requirements-modal-continue-button'
            }
          >
            Continue anyway
          </Button>
        )}
        <Button
          squared
          size={'small'}
          width={'auto'}
          color={'primary'}
          onClick={onClose}
          dataTestingId={'mission-application-requirements-modal-edit-button'}
        >
          Edit application
        </Button>
      </div>
    </div>
  );
};
