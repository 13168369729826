import React, { ReactElement, useState } from 'react';
import Modal, { ModalBasicProps } from '..';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Colors } from '@ateams/components';
import { Button } from '@a_team/ui-components';

interface Props extends ModalBasicProps {
  src: string;
  onCropChange?: () => void;
  onCropSubmit: (crop: Crop) => void;
  onImageLoaded: (image: HTMLImageElement) => void;
  aspect?: number;
  circularCrop?: boolean;
}

const ImageCropModal = (props: Props): ReactElement => {
  const {
    src,
    aspect,
    onClose,
    onCropChange,
    onCropSubmit,
    onImageLoaded,
    circularCrop,
    ...left
  } = props;
  const [hasError, setError] = useState(false);
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 100,
    ...(aspect ? { aspect } : { height: 100 }),
  });

  return (
    <Modal onClose={onClose} {...left}>
      <h2>Now, Let's Crop Your Image</h2>
      <ReactCrop
        src={src}
        onImageLoaded={(img) => {
          onImageLoaded(img);
          setError(false);
        }}
        crop={crop}
        onChange={setCrop}
        style={{ width: 500 }}
        keepSelection
        circularCrop={circularCrop}
        onImageError={() => {
          setError(true);
        }}
      />
      {hasError && (
        <div
          style={{
            height: 300,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p style={{ color: Colors.danger, textAlign: 'center' }}>
            Sorry, we couldn't load this image.
            <br /> Please try another one!
          </p>
        </div>
      )}
      <Button
        size={'md'}
        onClick={() => onCropSubmit(crop)}
        disabled={hasError}
        style={{ margin: ' 0 auto', marginTop: 32 }}
        margin={'lg'}
      >
        Done
      </Button>
    </Modal>
  );
};

export default ImageCropModal;
