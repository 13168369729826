import { apiClientInterviews } from '@ateams/api';
import { queryKeys } from './keys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useStores } from '@src/stores';
import {
  BasicClientInterviewObject,
  ClientInterviewDeclineReason,
  ClientInterviewStatus,
} from '@a_team/models/dist/ClientInterviewObject';

export const useGetClientInterviewByIdQuery = ({
  id,
  onSuccess,
}: {
  id: string;
  onSuccess: (data: BasicClientInterviewObject) => void;
}) => {
  const stores = useStores();
  return useQuery<BasicClientInterviewObject, Error>({
    onSuccess: (data) => {
      onSuccess(data);
    },
    queryKey: queryKeys.clientInterviews.byId(id),
    queryFn: async () => {
      const interview = await apiClientInterviews.getClientInterviewById(
        stores.auth,
        id,
      );
      return interview;
    },
  });
};

interface UpdateClientInterviewParams {
  id: string;
  status: ClientInterviewStatus;
  declineReason?: ClientInterviewDeclineReason | undefined;
  declineOtherReasonDetails?: string | undefined;
  builderHourlyRate?: number | undefined;
}

export const useUpdateClientInterviewStatusByIdMutation = () => {
  const stores = useStores();
  const queryClient = useQueryClient();

  return useMutation<
    BasicClientInterviewObject,
    Error,
    UpdateClientInterviewParams,
    unknown
  >({
    mutationFn: async ({
      id,
      status,
      declineReason,
      declineOtherReasonDetails,
      builderHourlyRate,
    }: UpdateClientInterviewParams) => {
      return apiClientInterviews.updateClientInterviewStatusById(
        stores.auth,
        id,
        status,
        declineReason,
        declineOtherReasonDetails,
        builderHourlyRate,
      );
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(
        queryKeys.notifications.getAllNotifications(
          true,
          stores?.auth?.currentUser?.uid,
        ),
      );
    },
  });
};
