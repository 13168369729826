import React from 'react';
import { createUseStyles } from 'react-jss';
import trashIcon from './trashIcon.svg';

interface ImageOptionsProps {
  images: string[];
  getIsCoverImage: () => boolean;
  onSetCoverImage: () => void;
  onRemoveImage: () => void;
}

const useStyles = createUseStyles({
  wrapper: {
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 9999,
    display: 'flex',
    gap: 8,
  },
  option: {
    transition: 'opacity 0.2s ease-in-out',
    padding: '6px 12px',
    borderRadius: 4,
    background: '#FFFFFF',
    border: '1px solid #DADADC',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 500,
    '&:not([disabled])': {
      cursor: 'pointer',
    },
    '&:disabled': {
      cursor: 'default',
    },
    '&:not([disabled]):hover': {
      opacity: 0.7,
    },
  },
});

const ImageOptions = ({
  images,
  getIsCoverImage,
  onSetCoverImage,
  onRemoveImage,
}: ImageOptionsProps): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      {images.length > 1 &&
        (getIsCoverImage() ? (
          <button className={styles.option} disabled>
            Current cover
          </button>
        ) : (
          <button
            type="button"
            className={styles.option}
            onClick={onSetCoverImage}
          >
            Set as cover
          </button>
        ))}
      <button type="button" className={styles.option} onClick={onRemoveImage}>
        <img width="16" height="16" src={trashIcon} alt="Trash" />
      </button>
    </div>
  );
};

export default ImageOptions;
