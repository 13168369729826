import React, {
  Children,
  cloneElement,
  CSSProperties,
  isValidElement,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import TabNavLink from './TabNavLink';
import TabButton from './TabButton';
import { Breakpoints } from '@ateams/components';
import { Icon, IconType } from '@ateams/components';
import useToggle from '@src/hooks/useToggle';

interface Props {
  children: ReactNode;
  sticky?: boolean;
  style?: CSSProperties;
  className?: string;
  rootClassName?: string;
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
    position: (props: Props): string => (props.sticky ? 'sticky' : 'relative'),
    top: 0,
    zIndex: 99,
    height: 88,
    backgroundColor: '#f7f7f7',
    padding: '1em 0.5em 0',
    paddingLeft: 0,
    boxShadow:
      '0 -5px #f7f7f7,' + // masking top side
      '5px 0 #f7f7f7,' + // masking right side
      '0px 1px 10px rgba(0, 0, 0, 0.1)',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'absolute',
    top: 0,
    zIndex: 998,
    width: '100%',
    height: '100%',
    color: '#222',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  arrow: {
    position: 'absolute',
    zIndex: 999,
    width: 26,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.left': {
      left: 0,
      background:
        'linear-gradient(90deg, rgba(247,247,247,1) 65%, rgba(0,0,0,0) 100%)',
    },
    '&.right': {
      right: 0,
      background:
        'linear-gradient(-90deg, rgba(247,247,247,1) 65%, rgba(0,0,0,0) 100%)',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      justifyContent: 'unset',
    },
  },
});

export { TabNavLink, TabButton };

export default function TabButtons(props: Props): ReactElement {
  const { children, style, className, rootClassName } = props;
  const tabs = useRef<HTMLDivElement>(null);
  const [showRightArrow, toggleRightArrow] = useToggle(false);
  const [showLeftArrow, toggleLeftArrow] = useToggle(false);

  useEffect(() => {
    onResize();
    setArrows();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const onResize = () => {
    updateArrows();
    !!tabs.current &&
      tabs.current.parentElement &&
      (tabs.current.style.width =
        window.innerWidth > Breakpoints.md
          ? '100%'
          : `${tabs.current.parentElement.offsetWidth}px`);
  };

  const setArrows = () => {
    if (!tabs.current) return;
    const tabsInner = tabs.current.children[0];
    if (tabs.current.offsetWidth < tabsInner?.scrollWidth) {
      updateArrows();
      tabsInner.addEventListener('scroll', updateArrows);
    }
  };

  const updateArrows = () => {
    if (!tabs.current) return;
    const tabsInner = tabs.current.children[0];
    const toScroll = tabsInner?.scrollWidth - tabs.current.offsetWidth;
    if (tabs.current && tabsInner) {
      toggleRightArrow(tabsInner.scrollLeft < toScroll);
      toggleLeftArrow(!!tabsInner.scrollLeft);
    }
  };

  const styles = useStyles(props);

  return (
    <div ref={tabs} className={cx(styles.container, className)} style={style}>
      <div className={cx(styles.root, 'inner', rootClassName)}>
        {Children.toArray(children).map((elm, i) =>
          isValidElement(elm)
            ? cloneElement(elm, {
                // TODO: remove this hack
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                isFirst: i === 0,
              })
            : elm,
        )}
      </div>
      {showRightArrow && (
        <div className={cx(styles.arrow, 'right')}>
          <Icon type={IconType.Next} size={'exact'} muted />
        </div>
      )}
      {showLeftArrow && (
        <div className={cx(styles.arrow, 'left')}>
          <Icon type={IconType.Next} size={'exact'} muted flip={'horizontal'} />
        </div>
      )}
    </div>
  );
}
