import React, { ReactElement } from 'react';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import AdminTeamWorkMissionsList from '../AdminMissionsList/AdminMissionsList';

function AdminTeamWorkPublishedMissions(): ReactElement {
  const { missions } = useStores();

  return (
    <AdminTeamWorkMissionsList
      loadMore={() => missions.getAdminPublishedMissions(true)}
      showMore={!!missions.adminNextTokens.publishedMissions}
      type={'archived'}
      title={'Published missions'}
      missions={missions.adminViewMissions.publishedMissions || []}
    />
  );
}

export default observer(AdminTeamWorkPublishedMissions);
