import React, { ReactElement } from 'react';

interface Props {
  iconFill: string;
  iconStroke: string;
}

export default function EyeClosedIcon(props: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        className={props.iconFill}
        fillRule="evenodd"
        d="M15.748 4.716A10.31 10.31 0 0012 4C8.658 4 5.926 5.668 4.07 7.392A14.607 14.607 0 001.887 9.93a8.25 8.25 0 00-.61 1.06C1.152 11.259 1 11.637 1 12s.152.741.277 1.01c.148.317.355.678.61 1.06.49.737 1.19 1.6 2.073 2.435l1.414-1.415a12.577 12.577 0 01-1.823-2.13 6.28 6.28 0 01-.461-.795A2.233 2.233 0 013.02 12c.014-.037.036-.092.07-.165a6.28 6.28 0 01.46-.796 12.61 12.61 0 011.88-2.181C7.074 7.332 9.342 6 12 6c.756 0 1.48.108 2.168.297l1.58-1.581zM9.832 17.703c.688.19 1.412.297 2.168.297 2.658 0 4.926-1.332 6.57-2.858a12.61 12.61 0 001.88-2.181c.213-.32.365-.592.46-.796.034-.073.056-.128.07-.165a2.192 2.192 0 00-.07-.165 6.275 6.275 0 00-.46-.796 12.576 12.576 0 00-1.825-2.129l1.415-1.415a14.551 14.551 0 012.073 2.435c.255.382.462.743.61 1.06.125.269.277.647.277 1.01s-.152.741-.277 1.01a8.247 8.247 0 01-.61 1.06c-.51.767-1.249 1.67-2.183 2.538C18.074 18.332 15.342 20 12 20c-1.356 0-2.611-.275-3.749-.716l1.581-1.58z"
        clipRule="evenodd"
      ></path>
      <path
        className={props.iconStroke}
        d="M20.484 3.516l-16.97 16.97"
        opacity="0.6"
      ></path>
    </svg>
  );
}
