/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { InvitationRequestData } from '@ateams/api/dist/endpoints/Invitations';
import {
  ConnectionObject,
  ConnectionType,
} from '@a_team/models/dist/ConnectionObject';
import {
  MissionApplicationData,
  MissionApplicationId,
} from '@a_team/models/dist/MissionApplicationObject';
import MissionObject, {
  BasicMissionObject,
  BasicRoleMissionObject,
  MissionCardObject,
  MissionCompanyRequest,
  MissionId,
  MissionStatus,
} from '@a_team/models/dist/MissionObject';
import {
  CurrentUserObject,
  NoCalendarReason,
  OtherCalendarType,
  RegisteredUserObject,
  TeammateSuggestionObject,
  UserCardObject,
  UserId,
} from '@a_team/models/dist/UserObject';
import {
  AvailabilityUpdateSource,
  ConnectionsModalSources,
  IdentityTraits,
  OnboardingItem,
  Page,
  PageCategory,
  PageProps,
  Pages,
  ProfileUpdateSource,
  ProfileVersion,
  RegistrationFlow,
  SearchObject,
  UserEvent,
  UserEvents,
} from './types';
import { AsyncSegmentBrowserAnalyticsLike, Batch } from '../common/types';
import { MinimalRecommendationData } from '@a_team/models/dist/RecommendationObject';
import MissionRole, {
  MissionRoleId,
  MissionRoleQuestionId,
} from '@a_team/models/dist/MissionRole';
import {
  AvailabilitySummaryObject,
  ReminderPeriod,
} from '@a_team/models/dist/AvailabilityObject';
import {
  PaymentCycleAdminSummary,
  PaymentCycleSummary,
} from '@a_team/models/src/MissionPaymentCycleObject';
import { ContractType } from '@a_team/models/src/ContractObject';
import { ExperienceId } from '@a_team/models/dist/ExperienceObject';
import {
  BuilderSuggestionType,
  SuggestionActionType,
} from '@a_team/models/dist/ProfileEnhancements';
import { VettingType } from '@a_team/models/src/vetting-processes/vetting-processes';
import { AppliedMissionsDataResponse } from '@a_team/models/dist/v2/MissionApplication';
import { NotInterestedMissionsDataResponse } from '@a_team/models/src/v2/Mission';

/** Analytics manager for the platform service */
export class PlatformServiceAnalytics {
  constructor(protected readonly analytics: AsyncSegmentBrowserAnalyticsLike) {}

  identifyUser(identity: IdentityTraits, withAlias: boolean): void {
    if (identity.uid) {
      if (withAlias) {
        this.analytics.alias(identity.uid);
      }
      this.analytics.identify(identity.uid, identity);
    } else {
      this.analytics.identify(identity);
    }
  }

  track = <K extends UserEvent>(
    event: K,
    properties: UserEvents[K],
    options?: SegmentAnalytics.SegmentOpts,
  ): Promise<Batch> => {
    return this.analytics
      .track(event, properties as Object | undefined, options)
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        return [];
      });
  };

  protected reset(): void {
    this.analytics.reset();
  }

  page(props?: {
    category?: PageCategory;
    properties?: PageProps;
    options?: SegmentAnalytics.SegmentOpts;
  }): Promise<Batch>;
  page<K extends Page>(props: {
    category?: PageCategory;
    name: K;
    properties?: Pages[K] & PageProps;
    options?: SegmentAnalytics.SegmentOpts;
  }): Promise<Batch>;
  page<K extends Page>({
    category,
    name,
    properties,
    options,
  }: {
    category?: PageCategory;
    name?: K;
    properties?: Pages[K] & PageProps;
    options?: SegmentAnalytics.SegmentOpts;
  } = {}): Promise<Batch> {
    return this.analytics.page(category, name, properties, options);
  }

  // Request team

  trackCompanyTeamRequested = (data: MissionCompanyRequest): Promise<Batch> => {
    this.identifyUser(
      {
        uid: data.email,
        fullName: data.fullName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        websiteURL: data.websiteURL,
      },
      true,
    );

    return this.track(UserEvent.CompanyTeamRequested, data);
  };

  // Authentication

  trackSignUp = (user: RegisteredUserObject): Promise<Batch> => {
    this.identifyUser(user, true);

    return this.track(UserEvent.SignedUp, {
      uid: user.uid,
      type: user.type,
      status: user.status,
      email: user.email,
    });
  };

  trackSignIn = (
    user: CurrentUserObject | RegisteredUserObject,
  ): Promise<Batch> => {
    this.identifyUser(
      {
        uid: user.uid,
        email: user.email,
        fullName: user.fullName,
      },
      true,
    );

    return this.track(UserEvent.SignedIn, {
      uid: user.uid,
      status: user.status,
      email: user.email,
    });
  };

  trackSignOut = (user: CurrentUserObject | null): Promise<Batch> => {
    const result = this.track(
      UserEvent.SignedOut,
      user
        ? {
            uid: user.uid,
            email: user.email,
          }
        : {},
    );
    this.analytics.reset();
    return result;
  };

  // Interactions

  trackMessageSent = (sender: CurrentUserObject): Promise<Batch> => {
    return this.track(UserEvent.UserMessageSent, {
      uid: sender.uid,
      email: sender.email,
    });
  };
  trackSupportMessageSent = (sender: CurrentUserObject): Promise<Batch> => {
    return this.track(UserEvent.SupportMessageSent, {
      uid: sender.uid,
      email: sender.email,
    });
  };

  // Search

  trackSearchQuerySubmitted = (data: SearchObject): Promise<Batch> =>
    this.track(UserEvent.SearchQuerySubmitted, data);

  trackSearchHistoryClicked = (data: SearchObject): Promise<Batch> =>
    this.track(UserEvent.SearchHistoryClicked, data);

  // Discovery

  trackDiscoveryBarClicked = (data: { section: string }): Promise<Batch> =>
    this.track(UserEvent.DiscoveryBarClicked, data);

  trackDiscoveryTabClicked = (data: { tab: string }): Promise<Batch> =>
    this.track(UserEvent.DiscoveryTabClicked, data);

  trackDiscoveryOnboardingItemClicked = (
    data: OnboardingItem,
  ): Promise<Batch> =>
    this.track(UserEvent.DiscoveryOnboardingItemClicked, data);

  trackDiscoveryCurrentUserCardClicked = (): Promise<Batch> => {
    return this.track(UserEvent.DiscoveryCurrentUserCardClicked, {});
  };

  trackDiscoveryNetworkStrengthClicked = (): Promise<Batch> =>
    this.track(UserEvent.DiscoveryNetworkStrengthCardClicked, {});

  trackDiscoveryNotificationClicked = (
    data: ConnectionObject,
  ): Promise<Batch> => this.track(UserEvent.DiscoveryNotificationClicked, data);

  trackDiscoveryNotificationConnectionResponse = (data: {
    connection: ConnectionObject;
    response?: 'approve' | 'decline';
  }): Promise<Batch> =>
    this.track(UserEvent.DiscoveryNotificationConnectionResponded, data);

  trackDiscoveryInviteCTAClicked = (): Promise<Batch> =>
    this.track(UserEvent.DiscoveryInviteCTAClicked, {});

  trackDiscoveryShowMoreRolesClicked = (): Promise<Batch> =>
    this.track(UserEvent.DiscoveryShowMoreRolesLinkClicked, {});

  // User cards

  trackUserCardClicked = (data: UserCardObject): Promise<Batch> =>
    this.track(UserEvent.UserCardClicked, data);

  // Missions & Mission Control

  trackMissionCardClicked = (
    data: MissionCardObject & { recommended?: true },
  ): Promise<Batch> => this.track(UserEvent.MissionCardClicked, data);

  trackMissionCardClickedV2 = (
    data: AppliedMissionsDataResponse['mission'],
  ): Promise<Batch> => this.track(UserEvent.MissionCardClickedV2, data);

  trackMissionCardsFilterSelected = (
    roleCategories: { label: string; id: string }[],
  ): Promise<Batch> =>
    this.track(UserEvent.MissionCardsFilterSelected, {
      roleCategories,
    });

  trackMissionVideoPlayed = (
    data: MissionCardObject | MissionObject,
    source: 'mission' | 'card' | 'details',
  ): Promise<Batch> =>
    this.track(UserEvent.MissionVideoPlayed, {
      mid: data.mid,
      missionTitle: data.title,
      missionStatus: data.status,
      videoUrl: data.videoURL || '',
      source,
    });

  trackMissionCardNotInterested = (
    data: MissionCardObject | MissionObject,
    recommended: boolean,
    hide: boolean,
  ): Promise<Batch> =>
    this.track(UserEvent.MissionCardNotInterested, {
      mid: data.mid,
      missionTitle: data.title,
      missionStatus: data.status,
      recommended,
      hide,
    });

  trackMissionCardNotInterestedV2 = (
    data: NotInterestedMissionsDataResponse,
    recommended: boolean,
    hide: boolean,
  ): Promise<Batch> =>
    this.track(UserEvent.MissionCardNotInterested, {
      mid: data.mid,
      missionTitle: data.title ?? '',
      missionStatus: data.status ?? '',
      recommended,
      hide,
    });

  // Apply Modal

  trackMissionApplyStarted = (
    data: BasicRoleMissionObject,
    profileVersion?: ProfileVersion,
    recommended?: boolean,
  ): Promise<Batch> =>
    this.track(UserEvent.MissionApplyStarted, {
      mid: data.mid,
      missionTitle: data.title,
      missionStatus: data.status,
      rid: data.role.rid,
      roleCategoryTitle: data.role.category.title,
      roleHeadline: data.role.headline,
      profileVersion: profileVersion || ProfileVersion.Old,
      lookingForApplications: !!data.role.lookingForApplications,
      recommended,
    });

  trackMissionApplySubmitted = (
    data: BasicRoleMissionObject,
    application: MissionApplicationData,
    profileVersion?: ProfileVersion,
    recommended?: boolean,
  ): Promise<Batch> =>
    this.track(UserEvent.MissionApplySubmitted, {
      mid: data.mid,
      missionTitle: data.title,
      missionStatus: data.status,
      rid: data.role.rid,
      roleCategoryTitle: data.role.category.title,
      roleHeadline: data.role.headline,
      hourlyRate: application.hourlyRateRange?.max,
      profileVersion: profileVersion || ProfileVersion.Old,
      lookingForApplications: !!data.role.lookingForApplications,
      recommended,
    });

  trackTeammateRecommendationsViewed = (
    mission: BasicMissionObject,
    role: MissionRole,
    profileVersion?: ProfileVersion,
  ): Promise<Batch> =>
    this.track(UserEvent.MissionApplyTeammateRecommendationsViewed, {
      mid: mission.mid,
      missionTitle: mission.title,
      rid: role.rid,
      roleCategoryTitle: role.category.title,
      profileVersion: profileVersion || ProfileVersion.Old,
    });

  trackTeammateRecommendationsRequestedTeammateClick = (
    data: TeammateSuggestionObject,
    mission: BasicMissionObject,
    role: MissionRole,
    source: 'search' | 'recommendation',
    placement: 'card' | 'modal',
    profileVersion?: ProfileVersion,
  ): Promise<Batch> =>
    this.track(UserEvent.MissionApplyTeammateRequestClicked, {
      mid: mission.mid,
      missionTitle: mission.title,
      rid: role.rid,
      roleCategoryTitle: role.category.title,
      username: data.username,
      uid: data.uid,
      source,
      placement,
      profileVersion: profileVersion || ProfileVersion.Old,
    });

  trackTeammateRecommendationsTeammateCardClick = (
    data: TeammateSuggestionObject,
    mission: BasicMissionObject,
    role: MissionRole,
    source: 'search' | 'recommendation',
    profileVersion?: ProfileVersion,
  ): Promise<Batch> =>
    this.track(UserEvent.MissionApplyTeammateCardClicked, {
      mid: mission.mid,
      missionTitle: mission.title,
      rid: role.rid,
      roleCategoryTitle: role.category.title,
      uid: data.uid,
      username: data.username,
      source,
      profileVersion: profileVersion || ProfileVersion.Old,
    });

  trackTeammateRecommendationsModalNavigationClick = (
    data: TeammateSuggestionObject,
    mission: BasicMissionObject,
    role: MissionRole,
    source: 'search' | 'recommendation',
    profileVersion?: ProfileVersion,
  ): Promise<Batch> =>
    this.track(UserEvent.MissionApplyTeammateModalNavigationClicked, {
      mid: mission.mid,
      missionTitle: mission.title,
      rid: role.rid,
      roleCategoryTitle: role.category.title,
      uid: data.uid,
      username: data.username,
      profileVersion: profileVersion || ProfileVersion.Old,
    });

  trackTeammateRecommendationsSkipButtonClick = (
    mission: BasicMissionObject,
    role: MissionRole,
    profileVersion?: ProfileVersion,
  ): Promise<Batch> =>
    this.track(UserEvent.MissionApplyTeammateRecommendationsSkipped, {
      mid: mission.mid,
      missionTitle: mission.title,
      rid: role.rid,
      roleCategoryTitle: role.category.title,
      profileVersion: profileVersion || ProfileVersion.Old,
    });

  trackTeammateRecommendationsSearch = (
    mission: BasicMissionObject,
    role: MissionRole,
    query: string,
    profileVersion?: ProfileVersion,
  ): Promise<Batch> =>
    this.track(UserEvent.MissionApplyTeammateSearchQuerySubmitted, {
      mid: mission.mid,
      missionTitle: mission.title,
      rid: role.rid,
      roleCategoryTitle: role.category.title,
      query,
      profileVersion: profileVersion || ProfileVersion.Old,
    });

  trackTeammateRecommendationsInviteClick = (
    mission: BasicMissionObject,
    role: MissionRole,
    profileVersion?: ProfileVersion,
  ): Promise<Batch> =>
    this.track(UserEvent.MissionApplyTeammateInviteClicked, {
      mid: mission.mid,
      missionTitle: mission.title,
      rid: role.rid,
      roleCategoryTitle: role.category.title,
      profileVersion: profileVersion || ProfileVersion.Old,
    });

  trackTeammateRecommendationsSubmitted = (
    data: MinimalRecommendationData[],
    mission: BasicMissionObject,
    role: MissionRole,
    profileVersion?: ProfileVersion,
  ): Promise<Batch> =>
    this.track(UserEvent.MissionApplyTeammateRecommendationsSubmitted, {
      mid: mission.mid,
      missionTitle: mission.title,
      recommendationCount: data.length,
      rid: role.rid,
      roleCategoryTitle: role.category.title,
      profileVersion: profileVersion || ProfileVersion.Old,
    });

  // Profile

  trackProfileImageEditClicked = (
    source: ProfileUpdateSource,
    mission?: BasicMissionObject,
    role?: MissionRole,
    aid?: MissionApplicationId,
  ): Promise<Batch> =>
    this.track(UserEvent.ProfileImageEditClicked, {
      source,
      mid: mission?.mid,
      rid: role?.rid,
      aid,
    });

  trackProfileRolesAddClicked = (
    source: ProfileUpdateSource,
    mission?: BasicMissionObject,
    role?: MissionRole,
    aid?: MissionApplicationId,
  ): Promise<Batch> =>
    this.track(UserEvent.ProfileRolesAddButtonClicked, {
      source,
      mid: mission?.mid,
      rid: role?.rid,
      aid,
    });

  trackApplicationStarted = ({
    missionId,
    roleId,
    userId,
  }: {
    missionId: MissionId;
    roleId: MissionRoleId;
    userId: UserId;
  }): Promise<Batch> => {
    return this.track(UserEvent.ApplicationStarted, {
      userId: userId,
      missionId: missionId,
      roleId: roleId,
    });
  };

  trackApplicationCompleted = ({
    missionId,
    roleId,
    userId,
  }: {
    missionId: MissionId;
    roleId: MissionRoleId;
    userId: UserId;
  }): Promise<Batch> => {
    return this.track(UserEvent.ApplicationCompleted, {
      userId: userId,
      missionId: missionId,
      roleId: roleId,
    });
  };

  trackProfileSkillsEditClicked = (
    source: ProfileUpdateSource,
    mission?: BasicMissionObject,
    role?: MissionRole,
    aid?: MissionApplicationId,
  ): Promise<Batch> =>
    this.track(UserEvent.ProfileSkillsEditButtonClick, {
      source,
      mid: mission?.mid,
      rid: role?.rid,
      aid,
    });

  trackProfileIndustriesAddClicked = (
    source: ProfileUpdateSource,
    mission?: BasicMissionObject,
    role?: MissionRole,
    aid?: MissionApplicationId,
  ): Promise<Batch> =>
    this.track(UserEvent.ProfileIndustriesAddButtonClicked, {
      source,
      mid: mission?.mid,
      rid: role?.rid,
      aid,
    });

  trackProfileWebsiteLinkClicked = (
    uid: UserId,
    source: ProfileUpdateSource,
    url: string,
    mission?: BasicMissionObject,
    role?: MissionRole,
    aid?: MissionApplicationId,
  ): Promise<Batch> =>
    this.track(UserEvent.ProfileWebsiteLinkClicked, {
      uid,
      url,
      source,
      mid: mission?.mid,
      rid: role?.rid,
      aid,
    });

  trackProfileJobsAddClicked = (
    source: ProfileUpdateSource,
    mission?: BasicMissionObject,
    role?: MissionRole,
    aid?: MissionApplicationId,
  ): Promise<Batch> =>
    this.track(UserEvent.ProfileJobsAddButtonClicked, {
      source,
      mid: mission?.mid,
      rid: role?.rid,
      aid,
    });

  trackProfileProjectAddClicked = (
    source: ProfileUpdateSource,
    mission?: BasicMissionObject,
    role?: MissionRole,
    aid?: MissionApplicationId,
  ): Promise<Batch> =>
    this.track(UserEvent.ProfileProjectsAddButtonClicked, {
      source,
      mid: mission?.mid,
      rid: role?.rid,
      aid,
    });

  trackProfileProjectEditClicked = (eid: ExperienceId): Promise<Batch> =>
    this.track(UserEvent.ProfileProjectsEditButtonClicked, {
      eid,
    });

  trackProfileEditButtonClicked = (): Promise<Batch> =>
    this.track(UserEvent.ProfileEditButtonClicked, {});

  trackProfileSaveButtonClicked = (): Promise<Batch> =>
    this.track(UserEvent.ProfileSaveButtonClicked, {});

  trackProfileOptionsMenuClicked = (uid: UserId): Promise<Batch> =>
    this.track(UserEvent.ProfileOptionsMenuClicked, {
      uid,
    });

  trackProfileRoleChanged = (
    source: ProfileUpdateSource,
    mission?: BasicMissionObject,
    role?: MissionRole,
    aid?: MissionApplicationId,
  ): Promise<Batch> =>
    this.track(UserEvent.ProfileRoleChanged, {
      source,
      mid: mission?.mid,
      rid: role?.rid,
      aid,
    });

  trackProfileYearsExperienceChanged = (
    source: ProfileUpdateSource,
    years: number,
    mission?: BasicMissionObject,
    role?: MissionRole,
    aid?: MissionApplicationId,
  ): Promise<Batch> =>
    this.track(UserEvent.ProfileYearsExperienceChanged, {
      source,
      years,
      mid: mission?.mid,
      rid: role?.rid,
      aid,
    });

  trackProfileConnectButtonClicked = (
    uid: UserId,
    connect: boolean,
  ): Promise<Batch> =>
    this.track(UserEvent.ProfileConnectButtonClicked, {
      uid,
      connect,
    });

  // Invite

  trackReferFriendClicked = (data: BasicRoleMissionObject): Promise<Batch> =>
    this.track(UserEvent.ReferAFriendClicked, {
      mid: data.mid,
      missionTitle: data.title,
      missionStatus: data.status,
      rid: data.role.rid,
      roleCategoryTitle: data.role.category.title,
      roleHeadline: data.role.headline,
    });

  trackInviteModalSubmitted = (data: InvitationRequestData): Promise<Batch> =>
    this.track(UserEvent.InviteModalSubmitted, data);

  trackTimesheetInviteModalSubmitted = (
    data: InvitationRequestData,
  ): Promise<Batch> =>
    this.track(UserEvent.TimesheetInviteModalSubmitted, data);

  trackTimesheetSubmitted = (
    summary: PaymentCycleSummary | PaymentCycleAdminSummary,
  ): Promise<Batch> => this.track(UserEvent.TimesheetSubmitted, summary);

  // Availability

  trackAvailabilityUpdated = (
    data: AvailabilitySummaryObject,
    source: AvailabilityUpdateSource,
    reminderPeriod?: ReminderPeriod,
    uid?: UserId,
  ): Promise<Batch> => {
    if (uid) {
      this.identifyUser(
        {
          uid: uid,
          availability: data.type,
        },
        false,
      );
    }

    return this.track(UserEvent.AvailabilitySettingsUpdated, {
      source,
      reminderPeriod,
      type: data.type,
      weeklyHoursAvailable: data.weeklyHoursAvailable,
      availableFrom: data.availableFrom,
    });
  };

  // Registration

  trackRegistrationStepViewed = (
    step: string,
    flow: RegistrationFlow,
    user?: Partial<RegisteredUserObject>,
  ): Promise<Batch> =>
    this.track(UserEvent.RegistrationStepViewed, {
      uid: user?.uid,
      step,
      flow,
    });

  trackRegistrationStepSubmitted = (
    step: string,
    flow: RegistrationFlow,
    user?: Partial<RegisteredUserObject>,
  ): Promise<Batch> =>
    this.track(UserEvent.RegistrationStepSubmitted, {
      uid: user?.uid,
      step,
      flow,
    });

  trackRegistrationBackButtonClicked = (
    step: string,
    flow: RegistrationFlow,
    user?: Partial<RegisteredUserObject>,
  ): Promise<Batch> =>
    this.track(UserEvent.RegistrationBackButtonClicked, {
      uid: user?.uid,
      step,
      flow,
    });

  trackRegistrationVideoPlayClicked = (
    step: string,
    videoUrl: string,
    flow: RegistrationFlow,
    user?: Partial<RegisteredUserObject>,
  ): Promise<Batch> =>
    this.track(UserEvent.RegistrationVideoPlayClicked, {
      uid: user?.uid,
      step,
      flow,
      videoUrl,
    });

  trackRegistrationComplete = (
    step: string,
    flow: RegistrationFlow,
    user?: Partial<RegisteredUserObject>,
  ): Promise<Batch> =>
    this.track(UserEvent.RegistrationComplete, {
      uid: user?.uid,
      step,
      flow,
    });

  trackCustomContractUploadClick = (
    mid?: MissionId,
    uid?: UserId,
  ): Promise<Batch> =>
    this.track(UserEvent.CustomAgreementUploadClicked, {
      mid,
      uid,
    });

  trackCustomContractUploadStarted = (
    mid?: MissionId,
    uid?: UserId,
  ): Promise<Batch> =>
    this.track(UserEvent.CustomAgreementUploadStarted, {
      uid,
      mid,
    });

  trackCustomContractUploadEnded = (
    mid?: MissionId,
    uid?: UserId,
  ): Promise<Batch> =>
    this.track(UserEvent.CustomAgreementUploadEnded, {
      uid,
      mid,
    });

  trackContractViewed = (
    type: ContractType,
    custom: boolean,
    mid?: MissionId,
    uid?: UserId,
  ): Promise<Batch> =>
    this.track(UserEvent.ContractViewed, {
      type,
      custom,
      mid,
      uid,
    });

  trackContractDeleted = (
    mid: MissionId,
    type: ContractType,
    uid?: UserId,
  ): Promise<Batch> =>
    this.track(UserEvent.ContractDeleted, { mid, uid, type });

  // connections
  trackViewAllUserConnectionsClicked = (
    userViewedId: UserId,
    userViewerId: UserId,
  ): Promise<Batch> => {
    return this.track(UserEvent.ViewAllUserConnectionsClicked, {
      userViewedId,
      userViewerId,
    });
  };

  trackViewAllUserConnectionsViewProfileClicked = (
    userViewedId: UserId,
    userViewerId: UserId,
  ): Promise<Batch> => {
    return this.track(UserEvent.ViewAllUserConnectionsViewProfileClicked, {
      userViewedId,
      userViewerId,
    });
  };

  trackConnectionRequest = (
    initiatorId: UserId,
    requestedUserId: UserId,
    connectionType: ConnectionType,
    hasCustomData: boolean,
    source: ConnectionsModalSources,
  ): Promise<Batch> => {
    return this.track(UserEvent.ConnectionRequest, {
      initiatorId,
      requestedUserId,
      connectionType,
      hasCustomData,
      source,
    });
  };

  trackApproveConnectionRequest = (
    initiatorId: UserId,
    requestedUserId: UserId,
    connectionType: ConnectionType,
    hasCustomData: boolean,
    source: ConnectionsModalSources,
  ): Promise<Batch> => {
    return this.track(UserEvent.ApproveConnectionRequest, {
      initiatorId,
      requestedUserId,
      connectionType,
      hasCustomData,
      source,
    });
  };

  trackRejectConnectionRequest = (
    initiatorId: UserId,
    requestedUserId: UserId,
    connectionType: ConnectionType,
    source: ConnectionsModalSources,
  ): Promise<Batch> => {
    return this.track(UserEvent.RejectConnectionRequest, {
      initiatorId,
      requestedUserId,
      connectionType,
      source,
    });
  };

  trackCreateNewJobProjectClickedOnConnectionsModal = (
    userId: UserId,
    source: ConnectionsModalSources,
  ): Promise<Batch> => {
    return this.track(UserEvent.CreateNewJobProjectClickedOnConnectionsModal, {
      userId,
      source,
    });
  };

  trackConnectionModalOpened = (
    userId: UserId,
    source: ConnectionsModalSources,
  ): Promise<Batch> => {
    return this.track(UserEvent.ConnectionModalOpened, {
      userId,
      source,
    });
  };

  trackTargeterSearchClicked = (
    mid: string,
    missionTitle: string,
    missionStatus: MissionStatus,
  ): Promise<Batch> =>
    this.track(UserEvent.TargeterSearchClicked, {
      mid,
      missionTitle,
      missionStatus,
    });

  trackTargeterTeamSearchClicked = (
    mid: string,
    missionTitle: string,
    missionStatus: MissionStatus,
  ): Promise<Batch> =>
    this.track(UserEvent.TargeterTeamSearchClicked, {
      mid,
      missionTitle,
      missionStatus,
    });

  trackTargeterReviewClicked = (
    mid: string,
    missionTitle: string,
    missionStatus: MissionStatus,
  ): Promise<Batch> =>
    this.track(UserEvent.TargeterReviewClicked, {
      mid,
      missionTitle,
      missionStatus,
    });

  trackTargeterOutreachClicked = (
    mid: string,
    missionTitle: string,
    missionStatus: MissionStatus,
  ): Promise<Batch> =>
    this.track(UserEvent.TargeterOutreachClicked, {
      mid,
      missionTitle,
      missionStatus,
    });

  trackSuggestedTeamsTargeterTabClicked = (tabsCount: number): Promise<Batch> =>
    this.track(UserEvent.SuggestedTeamsTargeterTabClicked, {
      tabsCount,
    });

  trackSuggestedTeamsRunQueryClicked = (
    tabsCount: number,
    buildersCount: number,
    allowPartialTeams: boolean,
  ): Promise<Batch> =>
    this.track(UserEvent.SuggestedTeamsRunQueryClicked, {
      tabsCount,
      buildersCount,
      allowPartialTeams,
    });

  trackTargeterComposeEmailClicked = (tabsCount: number): Promise<Batch> =>
    this.track(UserEvent.TargeterComposeEmailClicked, {
      tabsCount,
    });

  trackTargeterPreviewEmailClicked = (
    mid: string,
    missionTitle: string,
    missionStatus: string,
    emailCategory: string,
    emailType: string,
    roleIds: string,
  ): Promise<Batch> =>
    this.track(UserEvent.TargeterPreviewEmailClicked, {
      mid,
      missionTitle,
      missionStatus,
      emailCategory,
      emailType,
      roleIds,
    });

  trackTargeterSendEmailClicked = (
    mid: string,
    missionTitle: string,
    missionStatus: string,
    emailCategory: string,
    emailType: string,
    roleIds: string,
  ): Promise<Batch> =>
    this.track(UserEvent.TargeterSendEmailClicked, {
      mid,
      missionTitle,
      missionStatus,
      emailCategory,
      emailType,
      roleIds,
    });

  trackTargeterBuilderCardClicked = (
    cardUserId: UserId,
    cardUsername: string,
    rankingPosition: number,
    totalResults: number,
    url: string,
  ): Promise<Batch> =>
    this.track(UserEvent.TargeterBuilderCardClicked, {
      cardUserId,
      cardUsername,
      rankingPosition,
      totalResults,
      url,
    });

  trackTargeterResultPageChanged = (
    originalPage: number,
    targetPage: number,
  ): Promise<Batch> =>
    this.track(UserEvent.TargeterResultPageChanged, {
      originalPage,
      targetPage,
    });

  trackProfileSuggestionInteraction = (
    userId: UserId,
    suggestionId: string,
    action: SuggestionActionType,
    type: BuilderSuggestionType,
    changes?: string[],
  ): Promise<Batch> => {
    return this.track(UserEvent.ProfileSuggestionActed, {
      userId,
      suggestionId,
      action,
      type,
      changes,
    });
  };

  trackProfileSuggestionRetriggered = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.ProfileSuggestionsRetriggered, {
      userId,
    });
  };

  trackProfileSuggestionsGenerated = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.ProfileSuggestionsGenerated, {
      userId,
    });
  };

  trackStartWritingProjectDescriptionClicked = (
    userId: UserId,
  ): Promise<Batch> => {
    return this.track(UserEvent.StartWritingProjectDescriptionClicked, {
      userId,
    });
  };

  trackProjectDescriptionInsertSuggestionClicked = (
    userId: UserId,
    suggestion: string,
  ): Promise<Batch> => {
    return this.track(UserEvent.ProjectDescriptionInsertSuggestionClicked, {
      userId,
      suggestion,
    });
  };

  trackProjectDescriptionDiscardSuggestionClicked = (
    userId: UserId,
    suggestion: string,
  ): Promise<Batch> => {
    return this.track(UserEvent.ProjectDescriptionDiscardSuggestionClicked, {
      userId,
      suggestion,
    });
  };

  trackPitchStartWritingClicked = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.PitchStartWritingClicked, {
      userId,
    });
  };

  trackPitchRewriteClicked = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.PitchRewriteClicked, {
      userId,
    });
  };

  trackPitchSuggestionInsertClicked = (
    userId: UserId,
    suggestion: string,
  ): Promise<Batch> => {
    return this.track(UserEvent.PitchInsertSuggestionClicked, {
      userId,
      suggestion,
    });
  };

  trackPitchSuggestionDiscardClicked = (
    userId: UserId,
    suggestion: string,
  ): Promise<Batch> => {
    return this.track(UserEvent.PitchDiscardSuggestionClicked, {
      userId,
      suggestion,
    });
  };

  trackQuestionRecommendationGenerated = (
    userId: UserId,
    missionId: MissionId,
    roleId: MissionRoleId,
    questionId: MissionRoleQuestionId,
  ): Promise<Batch> => {
    return this.track(UserEvent.QuestionRecommendationGenerated, {
      userId,
      missionId,
      roleId,
      questionId,
    });
  };

  trackConnectCalendarClick = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.ConnectCalendarClicked, {
      userId,
    });
  };

  trackConnectGoogleCalendarClick = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.ConnectGoogleCalendarClicked, {
      userId,
    });
  };

  trackConnectAppleCalendarClick = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.ConnectOutlookCalendarClicked, {
      userId,
    });
  };

  trackConnectOutlookCalendarClick = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.ConnectOutlookCalendarClicked, {
      userId,
    });
  };

  trackCantConnectCalendarClick = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.CantConnectCalendarClicked, {
      userId,
    });
  };

  trackMissionConnectCalendarClick = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.MissionConnectCalendarClick, {
      userId,
    });
  };

  trackNonSupportedCalendarProvided = (
    userId: UserId,
    calendarName: string,
  ): Promise<Batch> => {
    return this.track(UserEvent.NonSupportedCalendarProvided, {
      userId,
      calendarName,
    });
  };

  trackAddCustomCalendarClicked = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.AddCustomCalendarClicked, {
      userId,
    });
  };

  trackMissionAddCustomCalendarClicked = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.MissionAddCustomCalendarClicked, {
      userId,
    });
  };

  trackManageCalendarClick = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.ManageCalendarClicked, {
      userId,
    });
  };

  trackUpdateCustomCalendarLinkClicked = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.UpdateCustomCalendarLinkClicked, {
      userId,
    });
  };

  trackManageWorkingHoursClicked = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.ManageWorkingHoursClicked, {
      userId,
    });
  };

  trackConnectCalendarInsteadClicked = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.ConnectCalendarInsteadClicked, {
      userId,
    });
  };

  trackCustomCalendarAdded = (
    userId: UserId,
    mainReason: NoCalendarReason,
    calendarLink: string,
    calendarType?: OtherCalendarType,
    calendarName?: string,
    reasonText?: string,
  ): Promise<Batch> => {
    return this.track(UserEvent.CustomCalendarAdded, {
      userId,
      mainReason,
      calendarLink,
      calendarType,
      calendarName,
      reasonText,
    });
  };

  trackMoreAvailableTimesClicked = (
    vettingType: VettingType,
    eventName: string,
  ): Promise<Batch> =>
    this.track(UserEvent.MoreAvailableTimesClicked, {
      vettingType,
      eventName,
    });

  trackEvaluationCallBooked = (
    eventName: string,
    userId: UserId,
  ): Promise<Batch> =>
    this.track(UserEvent.EvaluationCallBooked, { eventName, userId });

  trackProfileSubmittedForReview = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.ProfileSubmittedForReview, { userId });
  };

  trackProfileCompleted = (userId: UserId): Promise<Batch> => {
    return this.track(UserEvent.ProfileCompleted, { userId });
  };
}
