import {
  AsyncSelect,
  AsyncSelectProps,
  SelectOption,
} from '@ateams/components';
import { UserCardObject } from '@a_team/models/src/UserObject';
import { apiDiscovery } from '@src/logic/services/endpoints';
import { useStores } from '@src/stores';
import { SearchCategories } from '@src/stores/Search';
import { observer } from 'mobx-react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { InnerRef } from 'react-select';
import UserCard from './UserCard';

interface UserSearchInputProps {
  onUserSelect: (user: UserCardObject) => void;
  onRemoveUser: (uid: string) => void;
  selectedUsers: UserCardObject[];
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
  },
});

const useCustomStyles = createUseStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
  },
});

interface CustomOptionProps {
  data: {
    _id: string;
    query: string;
    category: SearchCategories;
    user: UserCardObject;
    value: string;
    label: string;
  };
  onUserSelect: (user: UserCardObject) => void;
  onRemoveUser: (uid: string) => void;
  selectedUsers: UserCardObject[];
  innerRef: InnerRef;
  innerProps: {
    onClick: () => void;
  };
}

const CustomOption = ({
  data,
  innerRef,
  onUserSelect,
  selectedUsers,
  onRemoveUser,
  innerProps,
}: CustomOptionProps) => {
  const styles = useCustomStyles();

  const isUserSelected = selectedUsers.some(
    (user) => user.uid === data.user?.uid,
  );

  const handleOnSelect = (user: UserCardObject) => {
    onUserSelect(user);
    innerProps?.onClick();
  };

  const handleOnRemove = (uid: string) => {
    onRemoveUser(uid);
    innerProps?.onClick();
  };

  return (
    <div className={styles.container} ref={innerRef}>
      <UserCard
        user={data.user}
        onSelect={!isUserSelected ? handleOnSelect : undefined}
        onRemove={isUserSelected ? handleOnRemove : undefined}
      />
    </div>
  );
};

function userOption(user: UserCardObject): SelectOption {
  return {
    value: user.uid,
    label: user.fullName,
    user,
  };
}

const UserSearchInput = ({
  onUserSelect,
  selectedUsers,
  onRemoveUser,
}: UserSearchInputProps): JSX.Element => {
  const styles = useStyles();
  const { auth } = useStores();

  const loadOptions: AsyncSelectProps['loadOptions'] = async (
    searchTerm: string,
  ) => {
    const results = await apiDiscovery.searchUsers(auth, searchTerm);

    return results.items.map((user) => userOption(user));
  };

  return (
    <div className={styles.container}>
      <AsyncSelect
        data-testing-id="user-search-input"
        classNamePrefix="ReactModal__select"
        menuPortalTarget={document.body}
        loadOptions={loadOptions}
        loadingMessage={() => 'Searching for users...'}
        emptyValue
        noOptionsMessage={() => 'No users found'}
        components={{
          Option: (props: CustomOptionProps) => {
            return (
              <CustomOption
                {...props}
                onRemoveUser={onRemoveUser}
                onUserSelect={onUserSelect}
                selectedUsers={selectedUsers}
              />
            );
          },
        }}
      />
    </div>
  );
};

export default observer(UserSearchInput);
