import React from 'react';
import { Card } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import inviteIllustration from '@src/components/Modal/InviteModal/inviteIllustration.svg';
import UserAvatar from '@src/components/UserAvatar';
import { Breakpoints } from '@ateams/components';

interface Props {
  className?: string;
  profilePictureURL?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  left: {
    flex: 1,
    marginRight: '16px',
  },
  right: {
    flex: 'none',
    position: 'relative',
    width: '190px',
    height: '190px',
    '& img': {
      position: 'absolute',
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '22px',
    margin: 0,
  },
  subTitle: {
    marginBottom: 0,
  },
  link: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
  },
  list: {
    paddingInlineStart: '12px',
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      flexDirection: 'row',
    },
  },
});

const Teaser: React.FC<Props> = ({ className, profilePictureURL }) => {
  const styles = useStyles();

  return (
    <Card className={className}>
      <div className={styles.container}>
        <div className={styles.left}>
          <h3 className={styles.title}>
            Earn the value you bring to A.Team with the Build Token
          </h3>
          <p>
            The vision for this token is to create a mechanism that builds
            trust, incentivizes belonging, and rewards participation. Today,
            tokens can be earned, but not redeemed. As we build, we'll work with
            our community to define redemption and tokenization.
          </p>
          <h4 className={styles.subTitle}>How do I earn tokens?</h4>
          <ul className={styles.list}>
            <li>
              Missions: Number of hours you build on missions, that have been
              accepted and paid for by the client.
            </li>
            <li>
              Builder Referrals: Number of hours your builder referrals build on
              missions.
            </li>
            <li>
              Company Referrals: Number of hours your company referrals build on
              A.Team missions.
            </li>
          </ul>
          <h4 className={styles.subTitle}>
            When will I receive tokens from referred users?
          </h4>
          <ul className={styles.list}>
            <li>The user must have been accepted onto a mission.</li>
            <li>They have billed at least 50 hours across the platform.</li>
            <li>
              The hours they have billed have been accepted and paid for by the
              client.
            </li>
          </ul>
          <a
            className={styles.link}
            href="https://www.a.team/build-token"
            target="_blank"
            rel="noreferrer"
          >
            Help build the token &rarr;
          </a>
        </div>
        {profilePictureURL && (
          <div className={styles.right}>
            <img src={inviteIllustration} alt="invite" />
            <UserAvatar
              src={profilePictureURL}
              containerStyle={{
                width: '80px',
                height: '80px',
                borderRadius: '80px',
                position: 'absolute',
                top: 'calc(50% - 40px)',
                left: 'calc(50% - 40px)',
              }}
              alt="profile"
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default Teaser;
