import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { Colors, FontSizes, Icon, IconType, Spacing } from '@ateams/components';
import TooltipWrapped from '@src/components/TooltipWrapped';

const useStyles = createUseStyles({
  builderSectionLink: {
    fontSize: FontSizes.small,
    color: Colors.secondaryDark,
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.xsmall,
    cursor: 'pointer',
  },
  builderSectionLinkIcon: {
    width: 16,
    height: 16,
    cursor: 'pointer',
  },
  builderSectionLinkText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '200px',
  },
});

export interface LinkProps {
  href: string;
  text: string;
  showIcon?: boolean;
  className?: string;
}

export const Link = ({
  href,
  text,
  showIcon = false,
  className,
}: LinkProps) => {
  const styles = useStyles();

  const normalizedUrl = useMemo(() => {
    if (href.startsWith('http://') || href.startsWith('https://')) {
      return href;
    }
    return `https://${href}`;
  }, [href]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    e.preventDefault();
    window.open(normalizedUrl, '_blank');
  };

  return (
    <TooltipWrapped
      title={text}
      style={{ cursor: 'pointer' }}
      trigger={'mouseenter'}
    >
      <a
        className={cn(styles.builderSectionLink, className)}
        href={normalizedUrl}
        onClick={handleClick}
      >
        {showIcon && (
          <Icon
            type={IconType.LinkSecondary}
            className={styles.builderSectionLinkIcon}
            title={text}
          />
        )}
        <div className={styles.builderSectionLinkText} title={text}>
          {text}
        </div>
      </a>
    </TooltipWrapped>
  );
};
