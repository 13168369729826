import React, { CSSProperties, ReactElement, ReactNode } from 'react';

const Aligns = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
};

interface Props {
  style?: CSSProperties;
  className?: string;
  children: ReactNode;
  align?: keyof typeof Aligns;
}

export default function ButtonContainer(props: Props): ReactElement {
  const { children, style, className, align } = props;

  return (
    <div
      className={className}
      style={{
        display: 'flex',
        justifyContent: Aligns[align || 'center'],
        marginTop: '64px',
        ...style,
      }}
    >
      {children}
    </div>
  );
}
