import { TimesheetRecordType } from '@a_team/models/dist/TimesheetObject';
import { Icon } from '@a_team/ui-components';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import SelectOption from './SelectOption';
import { allOptions, groupedOptions } from './data';

interface TypeSelectProps {
  className?: string;
  cellValue: TimesheetRecordType | null;
  onChange: (value: TimesheetRecordType) => void;
  onUpdateMutation?: (newType: TimesheetRecordType) => void;
}

type OptionType = {
  label: TimesheetRecordType;
  value: TimesheetRecordType;
  color: string;
  background: string;
};

const TypeSelect = ({
  className,
  cellValue,
  onChange,
  onUpdateMutation,
}: TypeSelectProps): JSX.Element => {
  const [value, setValue] = useState<OptionType | null>(() => {
    return cellValue !== null
      ? allOptions.find((o) => o.label === cellValue) || null
      : null;
  });

  useEffect(() => {
    setValue(
      cellValue !== null
        ? allOptions.find((o) => o.label === cellValue) || null
        : null,
    );
  }, [cellValue]);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <div
      className={cx(className)}
      data-testing-id="timesheets-type-select-wrapper"
    >
      <Select
        value={value}
        components={{
          Option: ({ innerProps, innerRef, isFocused, label }) => {
            const option = allOptions.find((o) => o.label === label);

            return (
              <SelectOption
                isFocused={isFocused}
                innerProps={innerProps}
                innerRef={innerRef}
                bgColor={option?.background}
                color={option?.color}
              >
                {label}
              </SelectOption>
            );
          },
          SingleValue: ({ children, ...props }) => {
            return (
              <components.SingleValue {...props}>
                {children}
              </components.SingleValue>
            );
          },
          MultiValue: ({ children, ...props }) => (
            <components.MultiValue {...props}>
              <span
                style={{
                  background: value?.background,
                  color: value?.color,
                  fontSize: 12,
                }}
              >
                {children}
              </span>
            </components.MultiValue>
          ),
          MultiValueRemove: (props) => (
            <components.MultiValueRemove {...props}>
              <Icon
                color={value?.color === 'white' ? 'Green@100' : 'Green@1000'}
                name="remove"
                size="sm"
              />
            </components.MultiValueRemove>
          ),
          GroupHeading: ({ children, ...props }) => {
            if (!children) return null;
            return (
              <components.GroupHeading {...props}>
                {children}
              </components.GroupHeading>
            );
          },
        }}
        styles={{
          input: (base) => ({
            ...base,
            margin: 0,
            position: 'relative',
            zIndex: 1,
            background: 'transparent',
            border: 'none',
          }),
          control: (base, state) => ({
            ...base,
            boxShadow: 'none',
            background: 'transparent',
            // if it's not focused, display none
            border: 'none',
            outline: state.isFocused
              ? '2px solid #6D00D7'
              : '2px solid transparent',
            borderRadius: 8,
            padding: '0 14px',
            height: '52px',
            margin: 0,
            '&:hover': {
              border: 'none',
              outline: state.isFocused
                ? '2px solid #6D00D7'
                : '2px solid transparent',
            },
          }),
          groupHeading: (base) => ({
            ...base,
            fontSize: 12,
            fontWeight: 500,
            color: '#62646A',
            padding: '7px 10px',
            borderTop: '1px solid #DADADC',
            textTransform: 'initial',
          }),

          menuList: (base) => ({
            ...base,
            padding: 0,
          }),

          valueContainer: (base) => ({
            ...base,
            padding: 0,
            border: 'none',
            background: 'transparent',
          }),
          placeholder: (base) => ({
            ...base,
            width: 'fit-content',
            background: '#F7F7F7',
            padding: '5px 10px',
            borderRadius: '8px',
            color: '#818388',
            fontSize: 12,
            fontWeight: 500,
          }),
          multiValueRemove: (base) => ({
            ...base,
            display: 'flex',
            '& span': {
              display: 'flex',
            },
            '&:hover': {
              background: 'transparent',
            },
          }),
          multiValue: (base) => ({
            ...base,
            margin: 0,
            background: value?.background,
            color: value?.color,
            borderRadius: '8px',
          }),
          multiValueLabel: (base) => ({
            ...base,
            paddingRight: 0,
          }),
          menu: (base) => ({
            ...base,
            borderRadius: '8px',
            border: 'none',
            boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.10)',
            zIndex: 999,
          }),
          indicatorsContainer: (base) => ({
            ...base,
            display: 'none',
          }),
        }}
        isClearable
        isMulti
        placeholder="Enter Tag"
        onChange={(newValue) => {
          const newValues = newValue as OptionType[];
          setValue(() => {
            return newValues[newValues.length - 1] || null;
          });
          onChange(newValues[newValues.length - 1]?.label || undefined);
          onUpdateMutation &&
            onUpdateMutation(newValues[newValues.length - 1]?.label || '');
        }}
        options={groupedOptions}
        tabSelectsValue={false}
        menuIsOpen={menuIsOpen}
        onFocus={() => setMenuIsOpen(true)}
        onBlur={() => setMenuIsOpen(false)}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
      />
    </div>
  );
};

export default TypeSelect;
