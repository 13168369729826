import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import Modal, { ModalBasicProps } from '..';
import SectionHeading from '@src/components/SectionHeading';
import { Button as CallToActionButton, Colors } from '@ateams/components';
import Mission from '@src/stores/Missions/Mission';
import { AdminMissionPaymentCycleSummarize } from '@ateams/api/dist/endpoints/Missions';
import UserAvatar from '@src/components/UserAvatar';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import MissionPaymentCycle from '@src/stores/Missions/MissionPaymentCycle';
import { Link } from 'react-router-dom';
import { ProfileLocation } from '@src/locations';
import TextButton from '@src/components/TextButton';
import UserObject, {
  BasicUserObject,
  UserId,
} from '@a_team/models/dist/UserObject';
import {
  MissionAdminRole,
  MissionRoleId,
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import { copyToClipboard } from '@ateams/react-utils/dist/helpers/clipboard';

interface Props extends ModalBasicProps {
  mission: Mission;
  paymentCycle: MissionPaymentCycle;
  onGetUser: (username: string) => Promise<UserObject>;
  onSubmit: (ids: UserId[]) => void;
}

const TimesheetRemindersModal = (props: Props): ReactElement => {
  const { mission, paymentCycle, onSubmit, onClose, onGetUser, ...left } =
    props;
  const { getInvoiceSummary } = mission;
  const [invoiceSummary, setInvoiceSummary] = useState<
    AdminMissionPaymentCycleSummarize | undefined
  >();
  const [loading, setLoading] = useLoadingState();

  const missionRoles: MissionAdminRole[] = useMemo(() => {
    return invoiceSummary
      ? (invoiceSummary.roles
          .filter((role) => !role.submitted)
          .map((role) => {
            return mission.data.roles.find(
              (missionRole) => missionRole.rid === role.rid,
            );
          })
          .filter((role) => role) as MissionAdminRole[])
      : [];
  }, [invoiceSummary?.roles]);

  const missionRoleUserIds = useMemo(() => {
    return missionRoles
      .filter(
        (role) =>
          !!role.user &&
          'uid' in role.user &&
          !!role.user.uid &&
          role.status === MissionRoleStatus.Active,
      )
      .map((role) => {
        const user = role.user as BasicUserObject;
        return user.uid;
      });
  }, [missionRoles]);

  useEffect(() => {
    setLoading(
      getInvoiceSummary(paymentCycle.yid, {}).then((summary) => {
        setInvoiceSummary(summary);
      }),
    );
  }, [paymentCycle]);

  const onCopyEmail = (rid: MissionRoleId) => {
    const role = missionRoles.find((role) => role.rid === rid);
    if (role?.user?.username) {
      setLoading(
        onGetUser(role.user.username).then(
          (user) => user.email && copyToClipboard(user.email),
        ),
        'Copied user email address',
      );
    }
  };

  return (
    <Modal onClose={onClose} {...left}>
      <SectionHeading isFirst>Send timesheet reminders</SectionHeading>
      <p>
        The following users will recieve an email reminder to submit their
        timesheets:
      </p>
      {missionRoles &&
        missionRoles.length > 0 &&
        missionRoles.map((missionRole) => {
          const isActiveRole = missionRole.status === MissionRoleStatus.Active;
          return (
            missionRole?.user &&
            'profilePictureURL' in missionRole.user && (
              <div
                style={{
                  marginBottom: 16,
                  display: 'flex',
                  color: isActiveRole ? 'inherit' : Colors.regular,
                }}
                key={missionRole.rid}
              >
                <Link to={ProfileLocation(missionRole.user.username)}>
                  <UserAvatar src={missionRole.user.profilePictureURL} />
                </Link>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 8,
                  }}
                >
                  <span style={{ fontWeight: 500 }}>
                    {missionRole.user.fullName}
                  </span>
                  <span style={{ fontSize: 14 }}>{missionRole.user.title}</span>
                  {isActiveRole ? (
                    <TextButton
                      highlight
                      onClick={() => onCopyEmail(missionRole.rid)}
                    >
                      Copy email address
                    </TextButton>
                  ) : (
                    'Inactive Role'
                  )}
                </div>
              </div>
            )
          );
        })}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 32 }}>
        <CallToActionButton
          style={{ margin: '0 8px' }}
          onClick={() => onSubmit(missionRoleUserIds)}
        >
          Send reminders
        </CallToActionButton>
      </div>
      <LoadingIndicator loading={loading || null} />
    </Modal>
  );
};

export default observer(TimesheetRemindersModal);
