import React, { ReactElement } from 'react';
import { Select, ToggleSwitch } from '@ateams/components';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery/index';
import { ManagementExperience } from '@a_team/models/dist/vetting-processes/pre-vetting';

export const teamSizeValues = [
  { value: ManagementExperience.None, label: 'None' },
  { value: ManagementExperience.SmallTeam, label: '1-5 people' },
  { value: ManagementExperience.MediumTeam, label: '6-10 people' },
  { value: ManagementExperience.LargeAndAboveTeam, label: '11+ people' },
].map((status) => ({
  label: status.label,
  value: status.value,
}));

interface Props {
  setFilterValue: (filter: Partial<RoleFilter>) => void;
  mgmtExp?: string;
  mgmtPreferred?: boolean;
}

const ManagementExperienceFilters = (props: Props): ReactElement => {
  const { setFilterValue, mgmtExp, mgmtPreferred } = props;
  const value = mgmtExp
    ? teamSizeValues?.filter((e) => e.value === mgmtExp) ?? null
    : null;

  return (
    <div>
      <Select
        isMulti={false}
        hideTags
        showItemCount
        isSearchable
        itemCountPrefix="Management Experience"
        placeholder=""
        value={value}
        margin={'bottom'}
        onChange={(selectedOptions) =>
          setFilterValue({
            mgmtExp: selectedOptions?.value,
          })
        }
        options={teamSizeValues}
      />
      <ToggleSwitch
        label={!mgmtPreferred ? 'Required' : 'Preferred'}
        onChange={(e) => {
          setFilterValue({
            mgmtPreferred: !mgmtPreferred,
          });
        }}
        checked={!mgmtPreferred}
        size="small"
        justify="right"
        checkedBackgroundColor="secondary"
      />
    </div>
  );
};
export default ManagementExperienceFilters;
