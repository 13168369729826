import { Stores } from '@src/stores';
import { action, observable } from 'mobx';
import {
  apiVettingProcess,
  apiVettingProcessFeedback,
  apiVettingProcessFeedbackAdditionalMaterial,
  apiVettingProcessPreVetting,
} from '@ateams/api';
import type {
  VettingProcessUser,
  VettingProcessId,
  VettingFormVariant,
  VettingType,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import {
  AdminVettingProcess,
  BasicVettingProcess,
  VettingProcess,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import {
  FetchVettingProcessesQuery,
  PatchAdminVettingProcessPayload,
  SendInterviewInviteEmailPayload,
  SetInterviewDatePayload,
  PatchRemoveVettingProcessPayload,
  PatchInterviewerNotifiedRecordPayload,
  AddInterviewerNotifiedRecordPayload,
  RemoveInterviewerNotifiedRecordPayload,
  CountVettingProcessesQuery,
  NotifyVetterOnProcessPayload,
  UserOptOutByVetterPayload,
  SendEvaluationInvitationPayload,
  SubmitPostEvaluationSurveyPayload,
  CheckPostEvaluationSurveySubmitted,
  InterviewScheduledPayload,
} from '@ateams/api/dist/endpoints/vetting-process';
import {
  GetPreVettingFormResponse,
  ValidatePreVettingFormNonceResponse,
} from '@ateams/api/dist/endpoints/vetting-process-pre-vetting';
import {
  AcceptVettingFeedbackFormScoresPayload,
  SubmitVettingFeedbackFormPayload,
  VettingFeedbackFormAnswersDraft,
  VettingFeedbackFormDefaults,
  VettingFeedbackFormViewModeData,
} from '@ateams/api/dist/endpoints/vetting-process-feedback';
import { VetterSuggestion } from '@a_team/models/dist/vetter';
import {
  VettingProcessFeedbackAdditionalMaterial,
  VettingProcessFeedbackAdditionalMaterialId,
} from '@a_team/models/dist/vetting-processes/feedback-additional-material';
import { VettingInterviewer } from '@a_team/models/src/vetting-processes/vetting-processes';
import { PlatformServiceAnalytics } from '@ateams/analytics/dist/platform';
import { UserId } from '@a_team/models/dist/UserObject';

export const PROCESSES_BATCH_SIZE = {
  SMALL: 25,
  MEDIUM: 50,
  LARGE: 100,
};

export interface VettingStoreData {
  isAdminView?: boolean;
  vettingProcesses?: VettingProcess[];
  vettingProcessesCount?: number;
  preVettingFormUser?: VettingProcessUser;
  interviewers?: VettingInterviewer[];
  processesQuery: {
    shouldShowAllColumns: boolean;
    batchSize: number;
    page: number;
  };
}

export default class VettingStore implements VettingStoreData {
  @observable isAdminView: VettingStoreData['isAdminView'];
  @observable vettingProcesses: VettingStoreData['vettingProcesses'];
  @observable vettingProcessesCount: VettingStoreData['vettingProcessesCount'];
  @observable preVettingFormUser?: VettingStoreData['preVettingFormUser'];
  @observable interviewers?: VettingStoreData['interviewers'];
  @observable formVariant?: VettingFormVariant;
  @observable
  processesQuery: VettingStoreData['processesQuery'] = {
    shouldShowAllColumns: false,
    batchSize: PROCESSES_BATCH_SIZE.MEDIUM,
    page: 1,
  };
  private readonly rootStore: Stores;
  private analytics: PlatformServiceAnalytics;
  protected getVettingProcessesAbortController: AbortController | undefined;
  protected getVettingProcessesCountAbortController:
    | AbortController
    | undefined;

  public constructor(
    rootStore: Stores,
    analytics: PlatformServiceAnalytics,
    initialState?: VettingStoreData,
  ) {
    this.rootStore = rootStore;
    this.analytics = analytics;

    if (initialState) {
      this.isAdminView = initialState.isAdminView;
      this.vettingProcesses = initialState.vettingProcesses;
      this.preVettingFormUser = initialState.preVettingFormUser;
      this.processesQuery = initialState.processesQuery;
    }
  }

  public serialize(): VettingStoreData {
    return {
      isAdminView: this.isAdminView,
      vettingProcesses: this.vettingProcesses,
      preVettingFormUser: this.preVettingFormUser,
      processesQuery: this.processesQuery,
    };
  }

  @action setAdminView(isAdminView: boolean): void {
    this.isAdminView = isAdminView;
  }

  private countVettingProcesses = async (
    payload: CountVettingProcessesQuery,
    abortController?: AbortController,
  ): Promise<number> => {
    return apiVettingProcess[
      this.isAdminView ? 'countAdminVettingProcesses' : 'countVettingProcesses'
    ](this.rootStore.auth, payload, abortController);
  };

  getPreVettingFormData = async (
    nonce: string,
  ): Promise<GetPreVettingFormResponse> => {
    return apiVettingProcessPreVetting.getPreVettingFormData(
      this.rootStore.auth,
      nonce,
    );
  };

  getVettingProcessesCount = async (
    payload: CountVettingProcessesQuery,
  ): Promise<void> => {
    const newAbortController = new AbortController();

    this.getVettingProcessesCountAbortController?.abort();
    this.getVettingProcessesCountAbortController = newAbortController;

    try {
      this.vettingProcessesCount = await this.countVettingProcesses(
        payload,
        this.getVettingProcessesCountAbortController,
      );
    } catch (err) {
      if (!newAbortController.signal.aborted) {
        throw err;
      }
    }
  };

  private fetchVettingProcesses = async (
    payload: FetchVettingProcessesQuery,
    abortController?: AbortController,
  ): Promise<VettingProcess[]> => {
    return apiVettingProcess[
      this.isAdminView ? 'fetchAdminVettingProcesses' : 'fetchVettingProcesses'
    ](this.rootStore.auth, payload, abortController);
  };

  fetchInterviewers = async (
    nonce: string,
    skipFilters: boolean,
  ): Promise<VettingInterviewer[]> => {
    return apiVettingProcessPreVetting.fetchInterviewers(
      this.rootStore.auth,
      nonce,
      skipFilters,
    );
  };

  getVettingProcesses = async (
    payload: FetchVettingProcessesQuery,
  ): Promise<void> => {
    const newAbortController = new AbortController();

    this.getVettingProcessesAbortController?.abort();
    this.getVettingProcessesAbortController = newAbortController;

    try {
      const processes = await this.fetchVettingProcesses(
        {
          ...payload,
          batchSize: this.processesQuery.batchSize,
          page: this.processesQuery.page,
        },
        this.getVettingProcessesAbortController,
      );

      this.setVettingProcesses(processes);
    } catch (err) {
      if (!newAbortController.signal.aborted) {
        throw err;
      }
    }
  };

  @action setVettingProcesses = (
    vettingProcesses: VettingStoreData['vettingProcesses'],
  ): void => {
    this.vettingProcesses = vettingProcesses;
  };

  @action clearVettingProcesses(): void {
    this.vettingProcesses = undefined;
  }

  public patchVettingProcess = async (
    payload: PatchAdminVettingProcessPayload,
  ): Promise<AdminVettingProcess> => {
    const updatedVettingProcess = await apiVettingProcess.updateVettingProcess(
      this.rootStore.auth,
      payload,
    );

    this.updateVettingProcess(updatedVettingProcess);

    return updatedVettingProcess;
  };

  public updateInterviewScheduled = async (
    nonce: string,
    payload: InterviewScheduledPayload,
  ): Promise<BasicVettingProcess> => {
    const updatedVettingProcess =
      await apiVettingProcessPreVetting.updateInterviewScheduled(
        this.rootStore.auth,
        nonce,
        payload,
      );

    this.updateVettingProcess(updatedVettingProcess);

    return updatedVettingProcess;
  };

  public getInterviewerSuggestions = async (
    vettingProcessId: VettingProcessId,
  ): Promise<VetterSuggestion[]> => {
    return apiVettingProcess.getInterviewerSuggestions(
      this.rootStore.auth,
      vettingProcessId,
    );
  };

  public sendEvaluationInvite = async (
    payload: SendEvaluationInvitationPayload,
  ): Promise<AdminVettingProcess> => {
    const updatedVettingProcess = await apiVettingProcess.sendEvaluationInvite(
      this.rootStore.auth,
      payload,
    );

    this.updateVettingProcess(updatedVettingProcess);

    return updatedVettingProcess;
  };

  public sendInterviewInviteEmail = async (
    payload: SendInterviewInviteEmailPayload,
  ): Promise<AdminVettingProcess> => {
    const updatedVettingProcess =
      await apiVettingProcess.sendInterviewInviteEmail(
        this.rootStore.auth,
        payload,
      );

    this.updateVettingProcess(updatedVettingProcess);

    return updatedVettingProcess;
  };

  public notifyInterviewerOnProcess = async (
    payload: NotifyVetterOnProcessPayload,
  ): Promise<AdminVettingProcess> => {
    const updatedVettingProcess =
      await apiVettingProcess.notifyInterviewerOnProcess(
        this.rootStore.auth,
        payload,
      );

    this.updateVettingProcess(updatedVettingProcess);

    return updatedVettingProcess;
  };

  public setInterviewDate = async (
    payload: SetInterviewDatePayload,
  ): Promise<BasicVettingProcess> => {
    const updatedVettingProcess = await apiVettingProcess.setInterviewDate(
      this.rootStore.auth,
      payload,
    );

    this.updateVettingProcess(updatedVettingProcess);

    return updatedVettingProcess;
  };

  public adminSetInterviewDate = async (
    payload: SetInterviewDatePayload,
  ): Promise<AdminVettingProcess> => {
    const updatedVettingProcess = await apiVettingProcess.adminSetInterviewDate(
      this.rootStore.auth,
      payload,
    );

    this.updateVettingProcess(updatedVettingProcess);

    return updatedVettingProcess;
  };

  public addVetterNotifiedRecord = async (
    payload: AddInterviewerNotifiedRecordPayload,
  ): Promise<AdminVettingProcess> => {
    const updatedVettingProcess =
      await apiVettingProcess.addInterviewerNotifiedRecord(
        this.rootStore.auth,
        payload,
      );

    this.updateVettingProcess(updatedVettingProcess);

    return updatedVettingProcess;
  };

  public updateVetterNotifiedRecord = async (
    payload: PatchInterviewerNotifiedRecordPayload,
  ): Promise<AdminVettingProcess> => {
    const updatedVettingProcess =
      await apiVettingProcess.updateInterviewerNotifiedRecord(
        this.rootStore.auth,
        payload,
      );

    this.updateVettingProcess(updatedVettingProcess);

    return updatedVettingProcess;
  };

  public removeVetterNotifiedRecord = async (
    payload: RemoveInterviewerNotifiedRecordPayload,
  ): Promise<AdminVettingProcess> => {
    const updatedVettingProcess =
      await apiVettingProcess.removeInterviewerNotifiedRecord(
        this.rootStore.auth,
        payload,
      );

    this.updateVettingProcess(updatedVettingProcess);

    return updatedVettingProcess;
  };

  @action updateVettingProcess = (vettingProcess: VettingProcess): void => {
    if (this.vettingProcesses) {
      this.vettingProcesses = this.vettingProcesses.map((cur) =>
        cur.id === vettingProcess.id ? vettingProcess : cur,
      );
    }
  };

  public validatePreVettingFormNonce = async (
    nonce: string,
  ): Promise<ValidatePreVettingFormNonceResponse> => {
    const response = await apiVettingProcessPreVetting.validatePreVettingNonce(
      this.rootStore.auth,
      nonce,
    );

    this.setPreVettingFormUser(response.user);
    this.setFormVariant(response.variant);

    return response;
  };

  public submitPreVettingForm = async (
    nonce: string,
    formData: FormData,
  ): Promise<void> => {
    return apiVettingProcessPreVetting.submitPreVettingForm(
      this.rootStore.auth,
      nonce,
      formData,
    );
  };

  public checkPostEvaluationSurveySubmitted = async (
    id: VettingProcessId,
  ): Promise<CheckPostEvaluationSurveySubmitted> => {
    return apiVettingProcess.checkPostEvaluationSurveySubmitted(
      this.rootStore.auth,
      id,
    );
  };

  public submitPostEvaluationForm = async (
    payload: SubmitPostEvaluationSurveyPayload,
  ): Promise<void> => {
    return apiVettingProcess.submitPostEvaluationSurvey(
      this.rootStore.auth,
      payload,
    );
  };

  public getFeedbackFormDefaults = async (
    id: string,
  ): Promise<VettingFeedbackFormDefaults> => {
    return apiVettingProcessFeedback[
      this.rootStore.auth.isAdmin
        ? 'adminGetVettingFeedbackFormDefaults'
        : 'getVettingFeedbackFormDefaults'
    ](this.rootStore.auth, id);
  };

  public getFeedbackFormViewModeData = async (
    id: VettingProcessId,
  ): Promise<VettingFeedbackFormViewModeData> => {
    return apiVettingProcessFeedback.getViewModeData(id);
  };

  public adminSaveFeedbackFormDraft = async (
    id: string,
    payload: VettingFeedbackFormAnswersDraft,
  ): Promise<VettingFeedbackFormDefaults> => {
    const newDefaults =
      await apiVettingProcessFeedback.adminSaveFeedbackFormDraft(
        this.rootStore.auth,
        id,
        payload,
      );

    return newDefaults;
  };

  public vetterSaveFeedbackFormDraft = async (
    id: string,
    payload: VettingFeedbackFormAnswersDraft,
  ): Promise<VettingFeedbackFormDefaults> => {
    const newDefaults =
      await apiVettingProcessFeedback.vetterSaveFeedbackFormDraft(
        this.rootStore.auth,
        id,
        payload,
      );

    return newDefaults;
  };

  public adminSubmitVettingFeedbackForm = async (
    id: string,
    payload: SubmitVettingFeedbackFormPayload,
  ): Promise<VettingFeedbackFormDefaults> => {
    const newDefaults =
      await apiVettingProcessFeedback.adminSubmitVettingFeedbackForm(
        this.rootStore.auth,
        id,
        payload,
      );

    return newDefaults;
  };

  public vetterSubmitVettingFeedbackForm = async (
    id: string,
    payload: SubmitVettingFeedbackFormPayload,
  ): Promise<VettingFeedbackFormDefaults> => {
    const newDefaults =
      await apiVettingProcessFeedback.vetterSubmitVettingFeedbackForm(
        this.rootStore.auth,
        id,
        payload,
      );

    return newDefaults;
  };

  public vetterUploadFeedbackAdditionalMaterial = async (
    vettingProcessId: VettingProcessId,
    addionalMaterial: File,
  ): Promise<VettingProcessFeedbackAdditionalMaterial> => {
    return apiVettingProcessFeedbackAdditionalMaterial.vetterUploadAdditionalMaterial(
      this.rootStore.auth,
      vettingProcessId,
      addionalMaterial,
    );
  };

  public adminUploadFeedbackAdditionalMaterial = async (
    vettingProcessId: VettingProcessId,
    addionalMaterial: File,
  ): Promise<VettingProcessFeedbackAdditionalMaterial> => {
    return apiVettingProcessFeedbackAdditionalMaterial.adminUploadAdditionalMaterial(
      this.rootStore.auth,
      vettingProcessId,
      addionalMaterial,
    );
  };

  public vetterDeleteFeedbackAdditionalMaterial = async (
    vettingProcessId: VettingProcessId,
    additionalMaterialId: VettingProcessFeedbackAdditionalMaterialId,
  ): Promise<void> => {
    return apiVettingProcessFeedbackAdditionalMaterial.vetterDeleteAdditionalMaterial(
      this.rootStore.auth,
      vettingProcessId,
      additionalMaterialId,
    );
  };

  public adminDeleteFeedbackAdditionalMaterial = async (
    vettingProcessId: VettingProcessId,
    additionalMaterialId: VettingProcessFeedbackAdditionalMaterialId,
  ): Promise<void> => {
    return apiVettingProcessFeedbackAdditionalMaterial.adminDeleteAdditionalMaterial(
      this.rootStore.auth,
      vettingProcessId,
      additionalMaterialId,
    );
  };

  public acceptFeedbackForm = async (
    id: VettingProcessId,
    payload: AcceptVettingFeedbackFormScoresPayload,
  ): Promise<AdminVettingProcess> => {
    const updatedVettingProcess =
      await apiVettingProcessFeedback.acceptFeedbackForm(
        this.rootStore.auth,
        id,
        payload,
      );

    this.updateVettingProcess(updatedVettingProcess);

    return updatedVettingProcess;
  };

  public removeVettingProcess = async (
    payload: PatchRemoveVettingProcessPayload,
  ): Promise<void> => {
    await apiVettingProcess.removeVettingProcess(this.rootStore.auth, payload);

    this.removeVettingProcessFromStore(payload.vettingProcessId);
  };

  public vetterMarkBuilderAsOptedOut = async (
    payload: UserOptOutByVetterPayload,
  ): Promise<void> => {
    await apiVettingProcess.vetterMarkBuilderAsOptedOut(
      this.rootStore.auth,
      payload,
    );

    this.removeVettingProcessFromStore(payload.vettingProcessId);
  };

  public adminMarkBuilderAsOptedOutOnVetterBehalf = async (
    payload: UserOptOutByVetterPayload,
  ): Promise<void> => {
    await apiVettingProcess.adminMarkBuilderAsOptedOutOnVetterBehalf(
      this.rootStore.auth,
      payload,
    );

    this.removeVettingProcessFromStore(payload.vettingProcessId);
  };

  public trackMoreAvailableTimesClicked = (
    vettingType: VettingType,
    eventName: string,
  ): void => {
    this.analytics.trackMoreAvailableTimesClicked(vettingType, eventName);
  };

  public trackEvaluationCallBooked = (
    eventName: string,
    userId: UserId,
  ): void => {
    this.analytics.trackEvaluationCallBooked(eventName, userId);
  };

  @action removeVettingProcessFromStore(
    vettingProcessId: VettingProcessId,
  ): void {
    if (this.vettingProcesses) {
      this.vettingProcesses = this.vettingProcesses.filter(
        (cur) => cur.id !== vettingProcessId,
      );
    }
  }

  @action setPreVettingFormUser(preVettingFormUser?: VettingProcessUser): void {
    this.preVettingFormUser = preVettingFormUser;
  }

  @action setFormVariant(formVariant: VettingFormVariant): void {
    this.formVariant = formVariant;
  }

  @action setShouldShowAllColumns(shouldShowAllColumns: boolean): void {
    this.processesQuery.shouldShowAllColumns = shouldShowAllColumns;
  }

  @action setProcessesBatchSize(batchSize: number): void {
    this.processesQuery.batchSize = batchSize;
  }

  @action setProcessesPage(page: number): void {
    this.processesQuery.page = page;
  }
}
