import { Stores, useStores } from '../stores';
import { apiDiscovery } from '@src/logic/services/endpoints';
import { SearchCategories, SearchResults } from '@src/stores/Search';
import { generateUniqueId } from '@src/helpers/strings';

// exported functions

export function searchResultsLoader(stores: Stores): Promise<unknown> {
  const { search, auth } = stores;

  if (search.searchResultsAvailable) {
    return Promise.resolve();
  }

  const params = new URLSearchParams(window.location.search);
  const searchCategory = params.get('category');
  const searchQuery = params.get('query');

  if (!params || !searchQuery || !searchCategory) {
    return Promise.reject('Missing search parameters');
  }

  search.onCategoryChange(searchCategory);
  search.onSearchQueryChange(searchQuery);
  search.setUserSearch({
    _id: search.userSearch?._id || generateUniqueId(),
    query: searchQuery,
    category: searchCategory as SearchCategories,
  });
  search.userSearch && search.addSearchHistory(search.userSearch);

  return apiDiscovery.searchUsers(auth, searchQuery).then((results) => {
    search.setUserResults(results);
  });
}

export default function useSearchResults(): SearchResults {
  const stores = useStores();
  searchResultsLoader(stores).catch(stores.error.handleError);
  return {
    users: stores.search.searchResults.users,
  };
}
