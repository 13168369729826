import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import RegistrationStep from '@src/views/Registration/RegistrationStep';
import { useStores } from '@src/stores';
import { Button, ButtonContainer } from '@ateams/components';
import { TeamMemberRole } from '@a_team/models/dist/TeamObject';
import useLoadingState from '@src/hooks/useLoadingState';

const RegistrationApplyAsTeamView = (): ReactElement => {
  const { registration } = useStores();
  const [loading, setLoading] = useLoadingState();

  return (
    <RegistrationStep
      title={'Ready to team up?'}
      withMobileVideo={registration.currentStep.withMobileVideo}
      description={
        <>
          {registration.teamOwner?.fullName || ''} has invited you to join
          A.Team as part of their team. Do you want to team up with them in the
          future?
        </>
      }
      loading={loading}
      withButton={false}
    >
      <ButtonContainer
        align={'left'}
        style={{ marginTop: 20, marginBottom: 30 }}
      >
        <Button
          style={{ fontSize: 12, marginRight: 16, height: 'auto' }}
          width={'auto'}
          color={'regular'}
          size={'small'}
          onClick={() => {
            setLoading(
              registration.submitResponseToTeamRequestStep(false).then(() => {
                registration.redirectApplyAsTeam();
              }),
            );
          }}
        >
          Opt out and apply as individual
        </Button>
        <Button
          style={{ fontSize: 12, height: 'auto' }}
          width={'auto'}
          size={'small'}
          onClick={() => {
            setLoading(
              registration.submitResponseToTeamRequestStep(true).then(() => {
                registration.redirectApplyAsTeam(TeamMemberRole.Member);
              }),
            );
          }}
        >
          Opt in
        </Button>
      </ButtonContainer>
    </RegistrationStep>
  );
};

export default observer(RegistrationApplyAsTeamView);
