import { TeamPulse, TeamPulseSurvey } from '@a_team/models/dist/TeamPulse';
import React, { ReactElement, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import TeamPulseResponseModal from './TeamPulseResponseModal';
import { Icon, IconType, Card } from '@ateams/components';
import cx from 'classnames';
import formatDate from '@src/helpers/formatDate';
import TeamBarometerIndicator, { getScoreText } from './TeamBarometerIndicator';
import { getTeamPulseAverageScore } from './utils';
import FeedbackIcon from '../FeedbackIcon';
import TeamPulseSharedFeedbackModal from './TeamPulseSharedFeedbackModal';

export interface Props {
  enableResponseModal?: boolean;
  teamPulse: TeamPulse;
  teamPulseSurveys?: TeamPulseSurvey[];
  adminView?: boolean;
}

const useStyles = createUseStyles({
  card: {
    marginRight: 0,
    width: '360px',
    maxWidth: '360px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '24px',
  },
  indicator: {
    width: '32px',
    height: '32px',
    flexShrink: 0,
  },
  description: {
    minHeight: '80px',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  feedbackIconContainer: {
    alignSelf: 'flex-end',
  },
  label: {
    marginTop: 0,
    marginBottom: '8px',
    fontSize: '15px',
    lineHeight: '24px',
  },
  date: {
    color: '#62646A',
    marginBottom: 0,
    marginTop: '8px',
    fontSize: '15px',
    lineHeight: '24px',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const TeamBarometer = ({
  teamPulse,
  adminView,
}: Props): ReactElement | null => {
  const styles = useStyles();
  const score = useMemo(
    () => getTeamPulseAverageScore(teamPulse, adminView),
    [teamPulse.averageRatingBuilder, teamPulse.averageRatingClient, adminView],
  );

  return (
    <>
      <div className={styles.wrapper}>
        {score === null ? (
          <div className={cx(styles.indicator, styles.center)}>
            <Icon type={IconType.Clock} />
          </div>
        ) : (
          <TeamBarometerIndicator score={score} />
        )}

        <div className={styles.description}>
          <div>
            <p className={styles.label}>
              {score === null
                ? 'Not enough people have answered this team pulse to display a score.'
                : getScoreText(score || 0)}
            </p>
            <p className={styles.date}>
              {formatDate(
                teamPulse.coveringInterval.start,
                // format Short Month name, day and year example: Jan 1, 2020
                'MMM d, yyyy',
              )}{' '}
              – {formatDate(teamPulse.coveringInterval.end, 'MMM d, yyyy')}
            </p>
          </div>
          {teamPulse.sharedResponses && teamPulse.sharedResponses.length > 0 && (
            <div className={styles.feedbackIconContainer}>
              <FeedbackIcon value={teamPulse.sharedResponses.length} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const TeamBarometerCard = ({
  enableResponseModal,
  teamPulse,
  teamPulseSurveys,
}: Props): ReactElement | null => {
  const styles = useStyles();
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const onCloseModal = () => {
    setShowResponseModal(false);
  };

  const onCloseFeedbackModal = () => {
    setShowFeedbackModal(false);
  };

  const isAdminResponseModal = enableResponseModal && teamPulseSurveys;
  const feedbackCount = teamPulse.sharedResponses?.length || 0;
  const hasFeedback = feedbackCount > 0;

  return (
    <>
      <Card
        className={styles.card}
        onClick={() => {
          if (isAdminResponseModal) {
            setShowResponseModal(true);
          } else {
            if (hasFeedback) {
              setShowFeedbackModal(true);
            }
          }
        }}
        style={{
          cursor: isAdminResponseModal || hasFeedback ? 'pointer' : 'unset',
        }}
      >
        <TeamBarometer teamPulse={teamPulse} />
      </Card>
      {showFeedbackModal && (
        <TeamPulseSharedFeedbackModal
          teamPulse={teamPulse}
          onClose={onCloseFeedbackModal}
        />
      )}
      {enableResponseModal &&
        teamPulseSurveys &&
        teamPulseSurveys.length > 0 &&
        showResponseModal && (
          <TeamPulseResponseModal
            onClose={onCloseModal}
            open={showResponseModal}
            teamPulse={teamPulse}
            teamPulseSurveys={teamPulseSurveys}
          />
        )}
    </>
  );
};

export default TeamBarometerCard;
