import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import RegistrationStep from '@src/views/Registration/RegistrationStep';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import ExpertiseSelector from '@src/components/ExpertiseSelector';

const Skills = (): ReactElement => {
  const {
    registration: {
      currentStep,
      allSkills,
      featuredSkills,
      MAX_FEATURED_SKILLS,
      setSkills,
      querySkills,
    },
  } = useStores();

  const [loading, setLoading] = useLoadingState();

  const onSubmit = () => {
    currentStep.onSubmit && setLoading(currentStep.onSubmit());
  };

  const scoreTooltips = {
    1: 'I have no experience using this (professionally or personally)',
    2: 'I have limited experience using this professionally, but I can ramp up on the job.',
    3: 'I have 1-3y experience using this professionally. I can hit the ground running.',
    4: 'I have substantial experience using this professionally and am proficient.',
    5: 'I have substantial experience using this professionally and deep expertise. I feel comfortable teaching or mentoring others in this skill.',
  };

  return (
    <RegistrationStep
      title={'What are Some of Your Top Skills?'}
      description={
        "Choose the skills all your former teammates and managers would say you rock at. And select the ones you actually like because we'll use these to match you with missions."
      }
      onSubmit={onSubmit}
      canSubmit={loading !== true}
      loading={loading}
    >
      <ExpertiseSelector
        placeholder={'Select your skills...'}
        closeMenuOnSelect={false}
        onSearch={querySkills}
        onChange={setSkills}
        selectedItems={allSkills}
        defaultBgColor={'#F7F7F7'}
        setFeaturedOnScores={
          featuredSkills.length <= MAX_FEATURED_SKILLS ? [4, 5] : undefined
        }
        scoreTooltips={scoreTooltips}
        withFeaturedCheckbox={false}
        limitAnswersHeight={false}
        defaultMenuIsOpen={false}
      />
    </RegistrationStep>
  );
};

export default observer(Skills);
