import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  rotate: {
    animation: '$rotate 1.4s linear infinite',
  },
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
});

export const LoaderIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const styles = useStyles();

  return (
    <svg
      className={styles.rotate}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      {...props}
    >
      <g id="Filled">
        <mask id="path-1-inside-1_3132_6506" fill="white">
          <path d="M7 0C8.40429 1.6746e-08 9.78385 0.36965 11 1.0718C12.2162 1.77394 13.2261 2.78385 13.9282 4C14.6304 5.21616 15 6.59571 15 8C15 9.4043 14.6303 10.7839 13.9282 12C13.2261 13.2162 12.2161 14.2261 11 14.9282C9.78384 15.6304 8.40429 16 6.99999 16C5.5957 16 4.21615 15.6303 2.99999 14.9282C1.78384 14.2261 0.773938 13.2161 0.0717924 12L1.80248 11.0008C2.32923 11.9131 3.08686 12.6708 3.99921 13.1975C4.91156 13.7243 5.9465 14.0016 7 14.0016C8.05349 14.0016 9.08843 13.7243 10.0008 13.1975C10.9131 12.6708 11.6708 11.9131 12.1975 11.0008C12.7243 10.0884 13.0016 9.0535 13.0016 8C13.0016 6.94651 12.7243 5.91157 12.1975 4.99922C11.6708 4.08686 10.9131 3.32924 10.0008 2.80249C9.08843 2.27574 8.0535 1.99843 7 1.99843L7 0Z" />
        </mask>
        <path
          d="M7 0C8.40429 1.6746e-08 9.78385 0.36965 11 1.0718C12.2162 1.77394 13.2261 2.78385 13.9282 4C14.6304 5.21616 15 6.59571 15 8C15 9.4043 14.6303 10.7839 13.9282 12C13.2261 13.2162 12.2161 14.2261 11 14.9282C9.78384 15.6304 8.40429 16 6.99999 16C5.5957 16 4.21615 15.6303 2.99999 14.9282C1.78384 14.2261 0.773938 13.2161 0.0717924 12L1.80248 11.0008C2.32923 11.9131 3.08686 12.6708 3.99921 13.1975C4.91156 13.7243 5.9465 14.0016 7 14.0016C8.05349 14.0016 9.08843 13.7243 10.0008 13.1975C10.9131 12.6708 11.6708 11.9131 12.1975 11.0008C12.7243 10.0884 13.0016 9.0535 13.0016 8C13.0016 6.94651 12.7243 5.91157 12.1975 4.99922C11.6708 4.08686 10.9131 3.32924 10.0008 2.80249C9.08843 2.27574 8.0535 1.99843 7 1.99843L7 0Z"
          stroke="#7000E3"
          strokeWidth="4"
          mask="url(#path-1-inside-1_3132_6506)"
        />
      </g>
    </svg>
  );
};
