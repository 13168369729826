import React from 'react';
import { createUseStyles } from 'react-jss';
import { Icon, IconType } from '@ateams/components';
import TextButton from '@src/components/TextButton';
import RoleCategoryObject from '@a_team/models/dist/RoleCategory';

interface ListRoleCategoriesProps {
  roleCategories: Pick<RoleCategoryObject, 'cid' | 'title'>[];
}

const useStyles = createUseStyles({
  wrapper: {
    marginTop: 18,
  },
  roleButton: {
    '&:focus': {
      outline: 'auto',
    },
  },
  icon: {
    marginRight: '0.5em',
  },
});

const NUMBER_OF_ROLES_DISPLAYED = 3;

const ListRoleCategories = ({ roleCategories }: ListRoleCategoriesProps) => {
  const styles = useStyles();

  if (roleCategories.length === 0) return null;

  const topRoleCategories = roleCategories.slice(0, NUMBER_OF_ROLES_DISPLAYED);

  const extraRoles = roleCategories.length - NUMBER_OF_ROLES_DISPLAYED;

  return (
    <p className={styles.wrapper}>
      <Icon type={IconType.UsersGray} size={'exact'} className={styles.icon} />
      {topRoleCategories.map((topRoleCategory, ind) => (
        <span key={topRoleCategory.cid}>
          <TextButton className={styles.roleButton} color="primary">
            {topRoleCategory.title}
          </TextButton>
          {topRoleCategories[ind + 1] && <span>, </span>}
        </span>
      ))}
      {extraRoles > 0 && (
        <span>{`, +${extraRoles} Role${extraRoles > 1 ? 's' : ''}`}</span>
      )}
    </p>
  );
};

export default ListRoleCategories;
