import { Breakpoints } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import ControlButtons from './common/ControlButtons';
import RoleMultiSelect, {
  RoleCategorySelectOption,
} from './common/RoleMultiSelect';
import { TeamPulseSectionsEnum } from './TeamPulseModal';

interface MissionNeedsMoreBuildersProps {
  setSection: (section: string) => void;
  setRoleRecommendations: (
    value: Array<RoleCategorySelectOption> | undefined,
  ) => void;
  missionTitle: string;
  initialState?: Array<RoleCategorySelectOption>;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    height: '100%',
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'start',
  },
  desc: {
    textAlign: 'start',
    color: '#707275',
    marginBottom: 16,
    marginTop: 8,
  },
  wrapper: {
    padding: 20,
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    wrapper: {
      padding: 0,
    },
  },
});

const RoleRecommendation = ({
  setSection,
  setRoleRecommendations,
  missionTitle,
  initialState,
}: MissionNeedsMoreBuildersProps): JSX.Element => {
  const [selectedRoles, setSelectedRoles] = React.useState<
    Array<RoleCategorySelectOption>
  >(initialState || []);
  const styles = useStyles();

  const onBack = (): void => {
    setSection(TeamPulseSectionsEnum.MissionNeedsMoreBuilders);
  };

  const onContinue = (): void => {
    setRoleRecommendations(selectedRoles);
    setSection(TeamPulseSectionsEnum.HowBuildersWouldHelpMission);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>
          What kind of builders could this mission benefit from?
        </h4>
        <p className={styles.desc}>
          The answer will not be shared with {missionTitle}.
        </p>

        <RoleMultiSelect
          selectedRoles={selectedRoles}
          setSelectedRoles={setSelectedRoles}
        />
      </div>

      <ControlButtons
        onBack={onBack}
        canContinue={selectedRoles.length > 0}
        onContinue={onContinue}
      />
    </div>
  );
};

export default RoleRecommendation;
