import React, { ReactElement, useMemo } from 'react';
import OutlinedInput, {
  OutlinedInputProps,
} from '@src/components/Inputs/OutlinedInput';
import { MissionManager } from '@a_team/models/dist/MissionObject';

interface Props extends OutlinedInputProps {
  managers: MissionManager[];
}

export const InvoiceGreetingInput = (props: Props): ReactElement => {
  const { value, managers, ...left } = props;

  const defaultText = useMemo(() => {
    const firstNames = (managers || []).map(
      (manager) => manager.user.firstName,
    );

    if (firstNames.length === 0) return 'Hi,';

    return `Hi ${firstNames.join(', ').replace(/, ([^,]*)$/, ' and $1')},`;
  }, [managers]);

  return <OutlinedInput value={value || defaultText} {...left} />;
};
