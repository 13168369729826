import { TextColors, Colors, Breakpoints } from '@ateams/components';
import TextButton from '@src/components/TextButton';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface UpdateButtonProps {
  label: string;
  onClick(): void;
}

export const useStyles = createUseStyles({
  button: {
    fontSize: '12px',
    padding: '6px 16px',
    border: `1px solid ${Colors.regular}`,
    borderRadius: '8px',
    color: TextColors.regularLight,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    button: {
      padding: '2px 12px',
    },
  },
});

function UpdateButton({ label, onClick }: UpdateButtonProps): JSX.Element {
  const styles = useStyles();

  return (
    <TextButton highlight className={styles.button} onClick={onClick}>
      {label}
    </TextButton>
  );
}

export default UpdateButton;
