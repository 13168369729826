import { Stores } from '../stores';

export async function loadSettings(stores: Stores): Promise<void> {
  const { evaluationSettingsUser, notificationPreferencesUser, auth } = stores;
  if (auth.isVetter) {
    await evaluationSettingsUser.loadData();
  }

  await notificationPreferencesUser.loadData();
}
