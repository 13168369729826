import React, { ReactElement } from 'react';

const Indicator = (
  props: JSX.IntrinsicElements['svg'],
): ReactElement | null => (
  <svg
    width="6"
    height="6"
    viewBox="0 0 6 6"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="3" cy="3" r="3" />
  </svg>
);

export default Indicator;
