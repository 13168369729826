import { VideoPlayer } from '@src/components/VideoPlayer';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Accordion from './Accordion';

const useStyles = createUseStyles({
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
  },
  subtitle: {
    color: '#FE630C',
  },
  listItem: {
    padding: '8px 0',
  },
  videoPlayer: {
    marginTop: '24px',
  },
});

const SomeTips = (): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <h4>💡 Some tips for getting on your first mission</h4>
      <Accordion
        titleColor="#FE630C"
        title="Request to Join' Page Breakdown (expandable)"
      >
        <div>
          <div>
            <p className={styles.subtitle}>
              <strong>We move quickly</strong>
            </p>
            <p>
              We want to get builders selected ASAP, so we usually share a
              proposal with the company within 48-72 hours from the time we've
              posted the mission to the community.{' '}
            </p>
          </div>

          <div>
            <p className={styles.subtitle}>
              <strong>The more the merrier</strong>
            </p>
            <p>
              You can express interest in multiple missions at the same time. It
              actually increases your odds of being matched to one!
            </p>
          </div>

          <div>
            <p className={styles.subtitle}>
              <strong>Missions filter & Role Status</strong>
            </p>
            <ul>
              <li className={styles.listItem}>
                Filtering missions is the best way to find the most relevant
                mission for you.
              </li>
              <li className={styles.listItem}>
                You can learn of a role status by hovering over the (i) sign
                next to that role’s name (in the mission page).
              </li>
            </ul>
          </div>

          <div>
            <p className={styles.subtitle}>
              <strong>Work together</strong>
            </p>
            <div>
              if you'd like us to pitch you with some friends as a unit — let us
              know! Companies LOVE this! You can write it in your application or
              email us at formation@a.team.
            </div>
          </div>
        </div>
      </Accordion>

      <VideoPlayer
        className={styles.videoPlayer}
        url="https://www.loom.com/share/b7726819b11d4a8d955f0057116b6c86"
      />
    </div>
  );
};

export default SomeTips;
