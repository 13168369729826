import { Stores } from '../stores';

// exported functions

export function loadInvoicesToBeIssued(stores: Stores): Promise<unknown> {
  const { missions } = stores;
  if (missions.endedPaymentCycles.length > 0) return Promise.resolve();

  return missions.fetchEndedPaymentCycles();
}
