import React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';

import badlyEmoji from '../emojis/badly.svg';
import amazingEmoji from '../emojis/amazing.svg';
import sosoEmoji from '../emojis/soso.svg';
import greatEmoji from '../emojis/great.svg';
import okayEmoji from '../emojis/okay.svg';

interface TeamBarometerIndicatorProps {
  score: number;
  detailed?: boolean;
}

const useStyles = createUseStyles<{ detailed?: boolean }>({
  emojiContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  detailed: {
    background: '#FFF8EB',
    borderRadius: '50%',
    minHeight: '80px',
    minWidth: '80px',
  },
  emoji: ({ detailed }) => ({
    width: detailed ? '24px' : '32px',
    height: detailed ? '24px' : '32px',
  }),
  scoreText: {
    fontSize: '12px',
  },
});

export const getScoreText = (score: number): string => {
  if (score < 1.6) {
    return 'Badly';
  }
  if (score < 2.6) {
    return 'So-so';
  }
  if (score < 3.6) {
    return 'Ok';
  }
  if (score < 4.6) {
    return 'Great';
  }
  return 'Amazing';
};

const getEmojiSvg = (score: number): string => {
  if (score < 1.6) {
    return badlyEmoji;
  }
  if (score < 2.6) {
    return sosoEmoji;
  }
  if (score < 3.6) {
    return okayEmoji;
  }
  if (score < 4.6) {
    return greatEmoji;
  }
  return amazingEmoji;
};

const TeamBarometerIndicator = ({
  score,
  detailed,
}: TeamBarometerIndicatorProps): JSX.Element => {
  const styles = useStyles({ detailed });
  const emoji = getEmojiSvg(score);
  const scoreText = getScoreText(score);

  return (
    <div className={cx(styles.emojiContainer, detailed && styles.detailed)}>
      <img className={styles.emoji} src={emoji} alt={scoreText} />
      {detailed && <span className={styles.scoreText}>{scoreText}</span>}
    </div>
  );
};

export default TeamBarometerIndicator;
