import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { VMark } from './VMark';
import { XMark } from './XMark';

interface VXToggleProps extends HTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string | ReactNode;
}

const useStyles = createUseStyles(() => ({
  wrapper: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkboxAndVXContainer: {
    position: 'relative',
    alignItems: 'center',
  },
  VXContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '32px',
    width: '80px',
    boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  VX: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: '100%',
  },
  VContainer: ({ checked }: VXToggleProps) => ({
    backgroundColor: checked ? '#79CE00' : '#FFFFFF',
    color: checked ? '#FFFFFF' : '#79CE00',
  }),
  VMark: ({ checked }: VXToggleProps) => ({
    backgroundColor: checked ? '#79CE00' : '#FFFFFF',
    color: checked ? '#FFFFFF' : '#79CE00',
  }),
  XContainer: ({ checked }: VXToggleProps) => ({
    backgroundColor: !checked ? '#F63131' : '#FFFFFF',
    color: !checked ? '#FFFFFF' : '#F63131',
  }),
  XMark: ({ checked }: VXToggleProps) => ({
    backgroundColor: !checked ? '#F63131' : '#FFFFFF',
    color: !checked ? '#FFFFFF' : '#F63131',
  }),
  middleShadow: ({ checked }: VXToggleProps) => ({
    position: 'absolute',
    width: '1px',
    top: '2px',
    height: 'calc(100% - 4px)',
    left: checked ? '50%' : 'calc(50% - 1px)',
    background: '#EBECEF',
    pointerEvents: 'none',
  }),
  label: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#2222222',
  },
  input: {
    position: 'absolute',
    opacity: 0,
    height: '100%',
    width: '100%',
    margin: 0,
    cursor: 'pointer',
  },
}));

export const VXToggle: FC<VXToggleProps> = (props) => {
  const { checked, label, style, containerClassName, labelClassName, ...rest } =
    props;
  const styles = useStyles(props);

  return (
    <div className={cx(styles.wrapper, containerClassName)} style={style}>
      {label &&
        (typeof label === 'string' ? (
          <div className={cx(styles.label, labelClassName)}>{label}</div>
        ) : (
          label
        ))}
      <div className={styles.checkboxAndVXContainer}>
        <input type="checkbox" className={styles.input} {...rest} />
        <div className={styles.VXContainer}>
          <div className={cx(styles.VX, styles.VContainer)}>
            <VMark className={styles.VMark} />
          </div>
          <div className={styles.middleShadow} />
          <div className={cx(styles.VX, styles.XContainer)}>
            <XMark className={styles.XMark} />
          </div>
        </div>
      </div>
    </div>
  );
};
