import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { components, IndicatorProps } from 'react-select';
import Icon, { IconType } from '../Icon';
import cx from 'classnames';

const useStyles = createUseStyles({
  chevron: {
    fontSize: '10px',
    marginRight: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  reveal: {
    transform: 'rotate(-180deg)',
  },
});

export const DropdownIndicator = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: IndicatorProps<any, any>,
): ReactElement => {
  const styles = useStyles();

  return (
    <components.DropdownIndicator {...props}>
      <Icon
        className={cx(styles.chevron, props.isFocused ? styles.reveal : null)}
        type={IconType.Chevron}
      />
    </components.DropdownIndicator>
  );
};
