import { Expertise } from '@src/stores/Profile/models';
import { FormValues } from '.';
import { JobExperienceSkill } from '@a_team/models/dist/ExperienceObject';

export const applyRatingToSkills = (
  jobSkills: JobExperienceSkill[],
  userSkills: Expertise[],
): FormValues['selectedSkills'] => {
  return jobSkills.map((jobSkill) => {
    const userSkill = userSkills.find(
      (userSkill) => userSkill.id === jobSkill.talentSkillId,
    );
    return {
      talentSkillId: jobSkill.talentSkillId,
      talentSkillName: jobSkill.talentSkillName,
      rating: userSkill?.rating || undefined,
    };
  });
};
