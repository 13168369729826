import React, { useMemo, useState } from 'react';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  sizes?: string;
  width?: number;
  height?: number;
  aspectRatio?: number;
  className?: string;
  placeholderBlur?: number;
  withPlaceholder?: boolean;
  fallbackSrc?: string;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
}

const generateSrcSet = (uuid: string) => {
  const widths = [
    100, 116, 135, 156, 181, 210, 244, 283, 328, 380, 440, 509, 588, 678, 780,
    895, 1024, 1168, 1328, 1505, 1700, 1914, 2148, 2403, 2680, 2980,
  ];
  const srcSet = widths
    .map((w) => {
      return `https://ucarecdn.com/${uuid}/-/resize/${w}/ ${w}w`;
    })
    .join(', ');
  return srcSet;
};

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  sizes,
  width,
  height,
  aspectRatio,
  className,
  placeholderBlur = 20,
  withPlaceholder = false,
  fallbackSrc,
  objectFit = 'contain',
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(withPlaceholder);
  const [hasError, setHasError] = useState(false);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setHasError(true);
  };

  const { placeholderSrc, srcUrl, srcSetUrl } = useMemo(() => {
    let url;
    try {
      url = new URL(src);
    } catch (error) {
      console.error('Invalid URL:', src);
      return {
        placeholderSrc: '',
        srcUrl: fallbackSrc || '',
        srcSetUrl: undefined,
      };
    }

    const pathParts = url.pathname.split('/');
    const uuid = pathParts.length > 1 ? pathParts[1] : null;
    const isUploadCDN = url.hostname.includes('ucarecdn.com');
    const srcUrl = isUploadCDN && uuid ? src : url.toString();

    return {
      placeholderSrc: `https://ucarecdn.com/${uuid}/-/resize/20/-/blur/${placeholderBlur}`,
      srcUrl: hasError && fallbackSrc ? fallbackSrc : srcUrl,
      srcSetUrl: isUploadCDN && uuid ? generateSrcSet(uuid) : undefined,
    };
  }, [src, hasError]);

  return (
    <div
      style={{
        position: 'relative',
        width: width ? `${width}px` : undefined,
        height: height ? `${height}px` : undefined,
        aspectRatio: aspectRatio ? `${aspectRatio}` : undefined,
        overflow: 'hidden',
      }}
      className={className}
    >
      {isLoading && withPlaceholder && (
        <img
          src={placeholderSrc}
          alt={alt}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            filter: 'blur(10px)',
            transition: 'opacity 0.3s ease',
            opacity: isLoading ? 1 : 0,
            objectFit,
            objectPosition: 'center',
          }}
          {...rest}
        />
      )}
      <img
        src={srcUrl}
        srcSet={srcSetUrl}
        sizes={sizes}
        alt={alt}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          transition: 'opacity 0.3s ease',
          opacity: isLoading ? 0 : 1,
          objectFit,
          objectPosition: 'center',
        }}
        onLoad={handleLoad}
        onError={handleError}
        {...rest}
      />
    </div>
  );
};

export default Image;
