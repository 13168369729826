import { useGetMutualConnectionsBetweenUsers } from '@src/rq/connections';
import Profile from '@src/stores/Profile/Profile';
import { observer } from 'mobx-react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import MutualConnectionsModal from './MutualConnectionsModal';
import useToggle from '@src/hooks/useToggle';
import MutualConnectionMessage from './MutualConnectionMessage';
import UserAvatar from '@src/components/UserAvatar';
import { Colors } from '@ateams/components';
import AuthStore from '@src/stores/Auth';
import HideScrollerGlobally from '@src/components/HideScrollerGlobally';

interface MutualConnectionsProps {
  profile: Profile;
  auth: AuthStore;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    marginBottom: 40,
  },
  content: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  title: {
    fontSize: '14px',
    color: Colors.regularDark,
  },
  imagesContainer: {
    display: 'flex',
    '& > *:nth-child(2)': {
      marginLeft: -6,
    },
  },
});

const MutualConnections = ({
  profile,
  auth,
}: MutualConnectionsProps): JSX.Element | null => {
  const styles = useStyles();

  const [isOpen, toggle] = useToggle(false);

  const { isLoading, data } = useGetMutualConnectionsBetweenUsers({
    userId: profile.data.uid,
    enabled: !profile.isCurrentUser,
  });

  if (isLoading) {
    return null;
  }

  if (!data || !data.mutualConnections.length) {
    return null;
  }

  const firstTwoMutualConnections = data.mutualConnections.slice(0, 2);

  return (
    <>
      <div className={styles.container}>
        <span className={styles.title}>Mutual connections</span>
        <div onClick={toggle} className={styles.content}>
          <div className={styles.imagesContainer}>
            {firstTwoMutualConnections.map((user) => (
              <UserAvatar
                alt={user.fullName}
                src={user.profilePictureURL}
                size={32}
              />
            ))}
          </div>
          <MutualConnectionMessage mutualConnections={data.mutualConnections} />
        </div>
      </div>

      {isOpen && <HideScrollerGlobally />}

      <MutualConnectionsModal
        profile={profile}
        auth={auth}
        mutualConnections={data.mutualConnections}
        isOpen={isOpen}
        onClose={toggle}
      />
    </>
  );
};

export default observer(MutualConnections);
