import { SetStateAction, useCallback, useState } from 'react';

export default function useAlteredState<S>(
  initialState: S | (() => S),
): [S, boolean, (state: SetStateAction<S>, reset?: boolean) => void] {
  const [state, setState] = useState<S>(initialState);
  const [altered, setAltered] = useState(false);

  const setAlteredState = useCallback(
    (state: SetStateAction<S>, reset?: boolean): void => {
      setState(state);
      setAltered(!reset);
    },
    [],
  );

  return [state, altered, setAlteredState];
}
