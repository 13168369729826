import {
  AccentScoreLabels,
  AdminNotesScore,
} from '@a_team/models/dist/AdminNotesObject';
import React from 'react';
import { BasicVettingProcessColumnProps } from '..';

export const AccentField: React.FC<BasicVettingProcessColumnProps> = ({
  vettingProcess,
}) => {
  const accent =
    vettingProcess.feedbackV2?.answers.scores.accent ??
    vettingProcess.feedback?.answers.scores.accent;

  if (typeof accent !== 'number') {
    return null;
  }

  return <>{AccentScoreLabels[accent as AdminNotesScore]}</>;
};
