import React, { ReactElement, useMemo } from 'react';
import { SurveyQuestionType } from '@a_team/models/dist/Survey';
import {
  commentIsRequired as _commentIsRequired,
  RatingWithFreeFormCommentSurveyQuestionResponse,
  validateRatingWithFreeFormCommentSurveyQuestionResponse,
} from '@a_team/models/dist/Survey/questions/types/RatingWithFreeFormCommentSurveyQuestion';
import { QuestionComponentProps } from './types';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import OutlinedInput from '../Inputs/OutlinedInput';
import StarRating from '../StarRating';

const useStyles = createUseStyles({
  wrapper: {},
  title: {
    fontWeight: 700,
    marginTop: '32px',
    marginBottom: '16px',
  },
  starRating: {
    marginTop: '16px',
    marginBottom: '16px',
  },
});

const RatingWithFreeFormCommentQuestion = (
  props: QuestionComponentProps<SurveyQuestionType.RatingWithFreeFormComment>,
): ReactElement | null => {
  const {
    className,
    onInput,
    question: { props: question },
    value: { value, comment: commentValue } = {
      value: null,
      comment: '',
    },
  } = props;
  const { title, max, min, comment, required } = question;

  const styles = useStyles();

  const commentIsRequired = useMemo(
    () => _commentIsRequired(value, comment),
    [value, comment],
  );

  const setInput = (
    newValue: RatingWithFreeFormCommentSurveyQuestionResponse,
  ) => {
    try {
      validateRatingWithFreeFormCommentSurveyQuestionResponse(
        question,
        newValue,
      );
      onInput({ value: newValue, valid: true, error: null });
    } catch (error) {
      if (error instanceof Error) {
        onInput({ value: newValue, valid: false, error });
      }
    }
  };

  return (
    <div className={cx(className, styles.wrapper)}>
      <p className={styles.title}>
        {title}
        {!required && <span> (Optional)</span>}
      </p>
      <StarRating
        className={styles.starRating}
        value={value}
        onChange={(n) => setInput({ value: n, comment: commentValue })}
        min={min}
        max={max}
      />
      <label>
        <p className={styles.title}>
          {comment.commentTitle}
          {!commentIsRequired && <span> (Optional)</span>}
        </p>
        <OutlinedInput
          multiline
          required={commentIsRequired}
          placeholder={comment.commentPlaceholder}
          value={commentValue}
          minRows={4}
          maxRows={4}
          onInput={(e) => {
            setInput({
              value: value,
              comment: e.currentTarget.value,
            });
          }}
          onChange={(e) => {
            setInput({
              value: value,
              comment: e.currentTarget.value,
            });
          }}
        />
      </label>
    </div>
  );
};

export default RatingWithFreeFormCommentQuestion;
