import React, { ReactElement, ReactNode } from 'react';
import MainLayout, { MainLayoutProps } from '@src/layouts/Main';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { ProfileMatch } from '@src/url-loaders/loadProfileMatch';
import TopBar from '@src/views/Profile/TopBar';
import { createUseStyles } from 'react-jss';
import NavigationPrompt from '@src/components/NavigationPrompt';

interface Props extends MainLayoutProps {
  match?: ProfileMatch;
  autoConnectModal?: boolean;
  topBarContent?: ReactNode;
  topBarClassName?: string;
  blockNavigation?: boolean;
  navigate?: (path: string) => void;
  hideNavbar?: boolean;
}

const useStyles = createUseStyles({
  container: {
    position: 'relative',
  },
});

const ProfileLayout = (props: Props): ReactElement => {
  const styles = useStyles();

  const {
    containerClassName,
    autoConnectModal,
    topBarContent,
    topBarClassName,
    hideNavbar,
    blockNavigation,
    navigate,
    ...leftProps
  } = props;

  return (
    <>
      <MainLayout
        hideNavbar={hideNavbar}
        topNav={
          <TopBar
            autoConnectModal={autoConnectModal}
            children={topBarContent}
            className={topBarClassName}
          />
        }
        containerClassName={cx(styles.container, containerClassName)}
        {...leftProps}
      />
      {navigate && (
        <NavigationPrompt
          navigate={navigate}
          shouldBlockNavigation={() => {
            return !!blockNavigation;
          }}
        />
      )}
    </>
  );
};

export default observer(ProfileLayout);
