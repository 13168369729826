import React from 'react';
import { createUseStyles } from 'react-jss';
import bellIcon from './bell.svg';

interface BellProps {
  totalUnreadNotifications: number;
}

const useStyles = createUseStyles({
  bell: {
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  indicator: {
    width: 16,
    height: 16,
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: '#FF0FAD',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    borderRadius: 4,
    lineHeight: '16px',
  },
  // select img
  bellIcon: {
    width: 40,
    height: 40,
  },
});

const Bell = ({ totalUnreadNotifications }: BellProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.bell}>
      <img className={styles.bellIcon} src={bellIcon} alt="bell" />
      {totalUnreadNotifications > 0 && (
        <span className={styles.indicator}>{totalUnreadNotifications}</span>
      )}
    </div>
  );
};

export default Bell;
