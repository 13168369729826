import { TextColors } from '@ateams/components';
import { add, formatISO, sub } from 'date-fns';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { DateInput } from '../DateInput';

interface Props {
  scrubbedDateFrom?: string;
  scrubbedDateTo?: string;
  onChange: (data: {
    scrubbedDateFrom?: string;
    scrubbedDateTo?: string;
  }) => void;
}

const useStyles = createUseStyles({
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  blockDatePickerWrapper: {
    borderRadius: 4,
    height: 'fit-content',
  },
  blockDatePicker: {
    padding: '0.6em 0.5em',
    marginBottom: 12,
    width: '100%',
    lineHeight: 'initial',
    borderRadius: 4,
    border: `1px solid ${TextColors.regularDark}`,
    '&:focus': {
      border: '1px solid #000',
    },
  },
});

const ScrubbedAtRangeFilter = (props: Props): ReactElement => {
  const styles = useStyles();
  const { scrubbedDateFrom, scrubbedDateTo, onChange } = props;

  return (
    <div className={styles.datePickerContainer}>
      <DateInput
        dateFormat={'dd/MM/yyyy'}
        maxDate={
          scrubbedDateTo
            ? sub(new Date(scrubbedDateTo), { days: 1 })
            : add(new Date(), { days: 0 })
        }
        popperPlacement={'right'}
        selected={scrubbedDateFrom ? new Date(scrubbedDateFrom) : null}
        className={styles.blockDatePicker}
        wrapperClassName={styles.blockDatePickerWrapper}
        placeholderText="Scrubbed from..."
        onChange={(input) => {
          onChange({
            scrubbedDateFrom: input
              ? formatISO(input as Date, { representation: 'date' })
              : undefined,
          });
        }}
      />
      <DateInput
        dateFormat={'dd/MM/yyyy'}
        maxDate={add(new Date(), { days: 0 })}
        minDate={
          scrubbedDateFrom ? add(new Date(scrubbedDateFrom), { days: 1 }) : null
        }
        popperPlacement={'right'}
        selected={scrubbedDateTo ? new Date(scrubbedDateTo) : null}
        className={styles.blockDatePicker}
        wrapperClassName={styles.blockDatePickerWrapper}
        placeholderText="Scrubbed to..."
        onChange={(input) =>
          onChange({
            scrubbedDateTo: input
              ? formatISO(input as Date, { representation: 'date' })
              : undefined,
          })
        }
      />
    </div>
  );
};

export default ScrubbedAtRangeFilter;
