import { Button, TextColors } from '@ateams/components';
import {
  BasicConnectionObjectV2,
  ConnectionObjectV2,
  ConnectionStatus,
  ConnectionType,
} from '@a_team/models/dist/ConnectionObject';
import { UserCardObject } from '@a_team/models/dist/UserObject';
import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import Description from '../../partials/Description';
import Title from '../../partials/Title';
import cx from 'classnames';
import { useRejectConnectionRequest } from '@src/rq/connections';
import { ConnectionsModalSources } from '@ateams/analytics/dist/platform';
import { getConnection } from '../../utils/utils';
import { ConnectModalScreens } from '../../types';

export type ConnectionAction = 'reject' | 'accept';

interface RejectConnectionRequestProps {
  userToConnect: UserCardObject;
  connection: BasicConnectionObjectV2;
  onClose: (keepModalOpen?: boolean) => void;
  connectActionOnCompleted?: (connection: ConnectionObjectV2) => void;
  onConnectionAction: (
    connectionType?: ConnectionType,
    connectionAction?: ConnectionAction,
  ) => void;
  source: ConnectionsModalSources;
  handleScreenClose: (keepModalOpen?: boolean) => void;
  handleSetScreen: (screen: ConnectModalScreens) => void;
}

const useStyles = createUseStyles({
  buttonsWrapper: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'center',
    marginTop: 40,
  },
  button: {
    borderRadius: '4px',
    maxWidth: '160px',
    transition: 'background 0.3s ease',
  },
  neverMindButton: {
    color: TextColors.regular,
    '&:hover': {
      background: '#DADADC',
    },
  },
  removeButton: {
    color: TextColors.primary,
    '&:hover': {
      background: '#5900b3',
    },
  },
});

function RejectConnectionRequest({
  connection,
  onClose,
  connectActionOnCompleted,
  source,
  handleScreenClose,
  userToConnect,
  handleSetScreen,
  onConnectionAction,
}: RejectConnectionRequestProps): ReactElement {
  const styles = useStyles();

  const connections = userToConnect.connections;
  const workConnection = getConnection(ConnectionType.Work, connections);
  const teamUpConnection = getConnection(ConnectionType.TeamUp, connections);

  const { mutate: rejectConnectionRequest, isLoading: isRejectingConnection } =
    useRejectConnectionRequest({
      source,
      connectActionOnCompleted: (connection: ConnectionObjectV2) => {
        if (connectActionOnCompleted) {
          connectActionOnCompleted(connection);
        }
        const rejectedConnectionType = connection.type;

        const otherConnection =
          rejectedConnectionType === ConnectionType.Work
            ? teamUpConnection
            : workConnection;

        if (otherConnection?.status === ConnectionStatus.Pending) {
          handleSetScreen(ConnectModalScreens.Home);
        } else {
          handleScreenClose();
        }
      },
    });

  const onRejectConnectionRequest = async () => {
    onConnectionAction(connection.type, 'reject');
    await rejectConnectionRequest({
      connection,
    });
  };

  return (
    <div>
      <Title text="Decline this connection?" />

      <Description
        text={`You can always send ${userToConnect.firstName} another connection request in the future.`}
      />

      <div className={styles.buttonsWrapper}>
        <Button
          className={cx(styles.button, styles.neverMindButton)}
          onClick={() => onClose(true)}
          size="small"
          color="regularLight"
          disabled={isRejectingConnection}
        >
          Never mind
        </Button>
        <Button
          data-testing-id="confirm-decline-connection-request"
          className={cx(styles.button, styles.removeButton)}
          onClick={onRejectConnectionRequest}
          size="small"
          color="secondaryDark"
          loading={isRejectingConnection}
        >
          Decline
        </Button>
      </div>
    </div>
  );
}

export default observer(RejectConnectionRequest);
