import React, { ReactElement } from 'react';
import TextButton from '@src/components/TextButton';
import { Button as CallToActionButton } from '@ateams/components';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  stickyButton: {
    boxShadow: '1px 1px 6px rgba(0,0,0, .2)',
  },
  buttonWrapper: {
    transform: 'translateY(-50px)',
    padding: '0 35px',
    textAlign: 'center',
  },
});

interface Props {
  src: string;
}

export const ContractView = (props: Props): ReactElement => {
  const styles = useStyles();

  return (
    <div id="pdfContainer" style={{ flex: 1 }}>
      <object
        data={props.src}
        type="application/pdf"
        style={{ width: '100%', height: '100%', overflow: 'hidden' }}
      >
        <p style={{ padding: 24 }}>
          Please
          <TextButton
            style={{ padding: '0 4px' }}
            highlight
            onClick={() => window.open(props.src, 'blank')}
          >
            download the PDF file
          </TextButton>
          and review before agreeing.
        </p>
      </object>

      <div className={styles.buttonWrapper}>
        <CallToActionButton
          className={styles.stickyButton}
          size={'small'}
          width={'auto'}
          onClick={() => window.open(props.src, 'blank')}
        >
          Download
        </CallToActionButton>
      </div>
    </div>
  );
};
