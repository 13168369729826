import {
  useRejectProfileSuggestion,
  useGetProfileSuggestions,
  useGetAdminProfileSuggestions,
  useApplySuggestedIndustry,
} from '@src/rq/profileSuggestions';
import Profile from '@src/stores/Profile/Profile';
import React from 'react';
import Suggestions from '../Suggestions';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { UserId } from '@a_team/models/dist/UserObject';
import {
  BuilderSuggestionType,
  SuggestionActionType,
} from '@a_team/models/dist/ProfileEnhancements';
import { ProfileViewMode } from '@src/stores/Profile/models';
import { createUseStyles } from 'react-jss';
import { noop } from 'lodash/fp';
import { TextColors } from '@ateams/components';

const useStyles = createUseStyles({
  container: {
    marginBottom: 24,
  },
  title: {
    fontSize: 14,
    color: TextColors.regularLight,
  },
});

interface SuggestedIndustriesProps {
  profile: Profile;
  userId: UserId;
  adminOnly?: boolean;
}
const SuggestedIndustries = ({
  profile,
  userId,
  adminOnly,
}: SuggestedIndustriesProps) => {
  const { data } = useGetProfileSuggestions();
  const { data: adminData } = useGetAdminProfileSuggestions();
  const { mutateAsync: applySuggestedIndustry } = useApplySuggestedIndustry();
  const { mutateAsync: rejectSuggestion } = useRejectProfileSuggestion();
  const analytics = useAnalytics();
  const styles = useStyles();

  const rawSuggestions = adminOnly ? adminData : data;

  const suggestionId = rawSuggestions?.industries?.sid;

  const industries = rawSuggestions?.industries?.industries.map((industry) => ({
    suggestionId,
    partialSuggestionId: industry.id,
    label: industry.name,
    type: BuilderSuggestionType.INDUSTRIES,
  }));

  const onDiscard = async () => {
    try {
      if (rawSuggestions?.industries?.sid) {
        await rejectSuggestion(rawSuggestions.industries.sid);
        analytics.trackProfileSuggestionInteraction(
          userId,
          rawSuggestions.industries.sid,
          SuggestionActionType.REJECT,
          BuilderSuggestionType.INDUSTRIES,
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAccept = async (industryId: string) => {
    try {
      if (rawSuggestions?.industries?.sid) {
        const industry = rawSuggestions.industries.industries.find(
          (industry) => industry.id === industryId,
        );

        if (!industry) {
          return;
        }

        await applySuggestedIndustry({
          sid: rawSuggestions.industries.sid,
          industryId: industry.id,
        });

        analytics.trackProfileSuggestionInteraction(
          userId,
          rawSuggestions.industries.sid,
          SuggestionActionType.ACCEPT,
          BuilderSuggestionType.ROLES,
          [industry.id],
        );
        profile.refreshProfile(ProfileViewMode.View);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.container}>
      {!profile.showIndustries && !!industries?.length && (
        <span className={styles.title}>Industries</span>
      )}
      <Suggestions
        onDiscard={onDiscard}
        onAccept={handleAccept}
        title={'Suggested industries'}
        onClick={noop}
        userId={userId}
        suggestions={industries ?? []}
        adminOnly={adminOnly}
      />
    </div>
  );
};

export default SuggestedIndustries;
