import { Stores } from '@src/stores/index';

import { PlatformServiceAnalytics } from '@ateams/analytics/dist/platform';

import { preferencesApi } from '@src/logic/services/endpoints';
import { PreferencesDto } from '@a_team/user-notification-service-js-sdk';
import NotificationPreferencesUserStore from './NotificationPreferencesUser';

export interface NotificationPreferencesStoreData {
  token?: string;
  invalidToken?: boolean;
  preferences: PreferencesDto;
}

export default class NotificationPreferencesAdminStore
  extends NotificationPreferencesUserStore
  implements NotificationPreferencesStoreData
{
  private usersStore: Stores['users'];
  public constructor(
    private rootStore: Stores,
    analytics: PlatformServiceAnalytics,
    initialState?: NotificationPreferencesStoreData,
  ) {
    super(rootStore, analytics, initialState);
    this.usersStore = rootStore.users;
  }

  private getProfile() {
    return (
      this.usersStore.profile || this.rootStore.missions?.currentApplication
    );
  }

  async fetchNotificationPreferencesData(): Promise<void> {
    const profile = this.getProfile();
    if (!profile?.data.uid) {
      throw new Error(
        `Cannot find builder profile to fetch notification preferences`,
      );
    }
    try {
      const preferences =
        await preferencesApi.preferencesControllerGetPreferencesAdmin(
          { userId: profile.data.uid },
          {
            headers: {
              Authorization: 'Bearer ' + this.authStore.token,
            },
          },
        );
      this.setNotificationPreferences(preferences);
    } catch (err) {
      // probably the uns is not up
    }
  }

  async updateNotificationPreferences(
    preferences: PreferencesDto,
  ): Promise<void> {
    const profile = this.getProfile();
    if (!profile?.data.uid) {
      throw new Error(
        `Cannot find builder profile to update notification preferences`,
      );
    }
    return preferencesApi
      .preferencesControllerUpdatePreferencesAdmin(
        {
          preferencesDto: preferences,
          userId: profile.data.uid,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.authStore.token,
          },
        },
      )
      .then((updatedPreferences) => {
        this.setNotificationPreferences(updatedPreferences);
      });
  }

  async unsubscribeAll(): Promise<void> {
    const profile = this.getProfile();
    if (!profile?.data.uid) {
      throw new Error(
        `Cannot find builder profile to update notification preferences`,
      );
    }
    return preferencesApi
      .preferencesControllerUpdatePreferencesAdmin(
        {
          userId: profile.data.uid,
          preferencesDto: {
            disabledCategories: this.allCategoriesInUse,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.authStore.token,
          },
        },
      )
      .then((updatedPreferences) => {
        this.setNotificationPreferences(updatedPreferences);
      });
  }
  async subscribeAll(): Promise<void> {
    const profile = this.getProfile();
    if (!profile?.data.uid) {
      throw new Error(
        `Cannot find builder profile to update notification preferences`,
      );
    }
    return preferencesApi
      .preferencesControllerUpdatePreferencesAdmin(
        {
          userId: profile.data.uid,
          preferencesDto: {
            disabledCategories: [],
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.authStore.token,
          },
        },
      )
      .then((updatedPreferences) => {
        this.setNotificationPreferences(updatedPreferences);
      });
  }
}
