import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';

interface Props {
  name: string;
}

const useStyles = createUseStyles({
  container: {
    marginTop: 16,
  },
  name: {
    fontSize: 24,
    fontWeight: 600,
    display: 'block',
    lineHeight: '30px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      marginTop: 24,
    },
  },
});

export const Name = (props: Props): ReactElement => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <span className={styles.name} data-testing-id={'profile-full-name'}>
        {props.name}
      </span>
    </div>
  );
};
