import { Breakpoints } from '@ateams/components';
import { createUseStyles } from 'react-jss';

const useCommonStyles = createUseStyles({
  container: {
    background: '#FCFAFF',
    padding: 16,
    color: '#6D00D7',
    borderRadius: 8,
    marginTop: 8,
  },
  desc: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: 8,
    fontSize: 12,
  },
  suggestedDescription: {
    fontSize: 12,
    marginTop: 24,
    marginBottom: 24,
  },
  wrapper: {
    display: 'flex',

    justifyContent: 'space-between',

    gap: 8,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  btn: {
    padding: '8px !important',
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    borderRadius: '6px !important',
    // select first span child
    '& span': {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },
  },
  rewriteDiscardBtn: {
    background: '#EDE0FF !important',
    '& span': {
      color: '#222222 !important',
    },
  },
  btnsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 8,
  },
  hideOnMobile: {
    display: 'none !important',
  },
  hideOnDesktop: {
    display: 'inline !important',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    desc: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    hideOnMobile: {
      display: 'inline !important',
    },
    hideOnDesktop: {
      display: 'none !important',
    },
    suggestedDescription: {
      marginTop: 8,
      marginBottom: 8,
    },
  },
});

export default useCommonStyles;
