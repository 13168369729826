import React, { ReactElement } from 'react';
import ActiveRatingStar from './ActiveRatingStar';
import InactiveRatingStar from './InactiveRatingStar';

type SVGProps = JSX.IntrinsicElements['svg'];

export interface Props extends SVGProps {
  active: boolean;
}

const RatingStar = ({ active, ...props }: Props): ReactElement | null =>
  active ? <ActiveRatingStar {...props} /> : <InactiveRatingStar {...props} />;

export default RatingStar;
