import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { ExperienceUserMember } from '@a_team/models/dist/ExperienceObject';
import { Avatar } from '@a_team/ui-components';
import UserTooltip from '@src/components/UserTooltip/UserTooltip';
import { Expertise } from '@src/stores/Profile/models';

interface RoleProps {
  member: ExperienceUserMember;
  roles: Expertise[];
}

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: 48,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  fullName: {
    marginTop: 10,
    fontSize: 12,
    textAlign: 'center',
    lineHeight: '16px',
  },
  tooltip: {
    position: 'absolute',
    top: -137, // adjust as needed
    left: '-150%', // adjust as needed
    transform: 'translateX(-50%)', // centers the tooltip
    zIndex: 10,
  },
});

const Collaborator = ({ member, roles }: RoleProps): JSX.Element | null => {
  const styles = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);

  const memberTitle = useMemo(() => {
    if (!member.memberRole || !roles) return '';

    return roles.find((role) => role.id === member.memberRole)?.name || '';
  }, [member.memberRole, roles]);

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div>
        <Avatar
          size={'lg'}
          styles={{
            borderRadius: 100,
          }}
          src={member.profilePictureURL}
        />
      </div>
      <div className={styles.fullName}>{member.fullName}</div>
      {showTooltip && (
        <div className={styles.tooltip}>
          <UserTooltip
            fullName={member.fullName}
            profilePictureURL={member.profilePictureURL}
            username={member.username}
            title={memberTitle}
          />
        </div>
      )}
    </div>
  );
};

export default Collaborator;
