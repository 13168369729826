import { teamEngineSearchServiceApi } from '@src/logic/services/endpoints';
import {
  v1EntityPage,
  v1SkillListFilterApi,
} from '@a_team/team-engine-search-service-sdk-js/dist/schema';
import { getAuthToken } from '@src/helpers/auth';

interface Params {
  filter?: v1SkillListFilterApi;
  page?: v1EntityPage;
  applicationName?: string;
}

export async function fetchSkillList(params: Params) {
  const { filter, applicationName } = params;
  const { size = 1000, current } = params.page ?? {};
  const { data, errors } = await teamEngineSearchServiceApi.query(
    {
      v1ListSkills: [
        {
          applicationName,
          page: { size, current },
          filter,
        },
        {
          skills: {
            _id: 1,
            name: 1,
            parentTalentCategoryIds: 1,
            isProgrammingLanguage: 1,
          },
        },
      ],
    },
    { headers: { Authorization: getAuthToken() } },
  );

  if (errors) {
    console.error(`[fetchSkillList]: ${JSON.stringify(errors)}`);
    return [];
  }

  const items = data?.v1ListSkills?.skills ?? [];

  return (
    items?.map((skill) => {
      const {
        _id,
        name,
        parentTalentCategoryIds = [],
        isProgrammingLanguage = false,
      } = skill;
      return {
        id: _id,
        name,
        talentCategoryIds: parentTalentCategoryIds?.map(String),
        isProgrammingLanguage,
      };
    }) ?? []
  );
}

export async function getSkillSelectOptions(params: Params) {
  const items = await fetchSkillList(params);

  return (
    items?.map((skill) => {
      const {
        id,
        name,
        talentCategoryIds = [],
        isProgrammingLanguage = false,
      } = skill;
      return {
        value: id,
        label: name,
        talentCategoryIds,
        isProgrammingLanguage,
      };
    }) ?? []
  );
}
