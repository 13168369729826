import { apiMissions } from '@ateams/api';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './keys';
import AuthStore from '@src/stores/Auth';
import {
  MissionApplicationId,
  MissionApplicationsMatchesOnData,
} from '@a_team/models/dist/MissionApplicationObject';
import { ProposalId } from '@a_team/models/dist/ProposalObject';

const fetchMissionApplicationMatchesOnData =
  (auth: AuthStore, proposalIds: ProposalId[], aids: MissionApplicationId[]) =>
  async (): Promise<MissionApplicationsMatchesOnData[]> => {
    return aids.length > 0 || proposalIds.length > 0
      ? apiMissions.getMissionApplicationsMatchesOnData(auth, proposalIds, aids)
      : [];
  };

export const useGetMissionApplicationMatchesOnData = (
  auth: AuthStore,
  proposalIds: ProposalId[] = [],
  aids: MissionApplicationId[] = [],
) => {
  return useQuery(
    queryKeys.missionApplicationsMatchesOnData.missionApplicationsMatchesOnData(
      proposalIds,
      aids,
    ),
    fetchMissionApplicationMatchesOnData(auth, proposalIds, aids),
  );
};
