import { Button, Modal } from '@a_team/ui-components';
import { Colors, TextColors } from '@ateams/components';
import {
  TalentPrivacyStatementLocation,
  TermsOfServiceLocation,
} from '@src/locations';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  onClose(): void;
  open: boolean;
  onAccept(): void;
}

const defaultProps = {
  type: 'user',
};

const useStyles = createUseStyles({
  container: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 10,
  },
  mainContent: {
    textAlign: 'center',
    maxWidth: '490px',
    color: TextColors.regularLight,
    lineHeight: '1.5',
  },
  ctaBtn: {
    marginTop: '24px !important',
    padding: '10px 40px !important',
    fontSize: '14px !important',
  },
  link: {
    color: Colors.secondaryDark,
  },
});

const MakerToSModal = (props: Props): ReactElement => {
  const { open, onClose, onAccept } = props;
  const styles = useStyles();

  return (
    <Modal
      variant="slideUp"
      isOpen={open}
      onClose={onClose}
      shouldHideGradientStroke={true}
      hideCloseButton
      styles={{ width: '600px !important', borderRadius: '8px !important' }}
    >
      <div className={styles.container}>
        <div className={styles.title}>Terms of Service</div>
        <div className={styles.mainContent}>
          By clicking “I agree”, you agree to our{' '}
          <a
            href={TermsOfServiceLocation}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            Terms of Service Agreement
          </a>{' '}
          and{' '}
          <a
            href={TalentPrivacyStatementLocation}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            A.Teamer Privacy Statement
          </a>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={onAccept} className={styles.ctaBtn}>
            I agree
          </Button>
        </div>
      </div>
    </Modal>
  );
};

MakerToSModal.defaultProps = defaultProps;
export default MakerToSModal;
