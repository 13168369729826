import React from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import {
  Spacing,
  FontSizes,
  FontWeights,
  BorderRadius,
  Colors,
} from '@ateams/components';
import TooltipWrapped from '@src/components/TooltipWrapped';

const useStyles = createUseStyles({
  scoreSelection: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.xsmall,
  },
  score: {
    fontSize: FontSizes.xsmall,
    fontWeight: FontWeights.regular,
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: BorderRadius.default,
    border: `1px solid ${Colors.regularLight}`,
    userSelect: 'none',
  },
  scoreClickable: {
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: Colors.secondaryDark,
    color: Colors.light,
  },
});

export interface ScoreSelectionProps {
  score: number;
  onChange: (score: number) => void;
  disabled?: boolean;
}

export const ScoreSelection = ({
  score,
  onChange,
  disabled,
}: ScoreSelectionProps) => {
  const styles = useStyles();

  const scoreToTitleMap = {
    1: `No expertise`,
    2: `Basic knowledge`,
    3: `Proficient`,
    4: `Advanced`,
    5: `Deep expertise`,
  };

  return (
    <div className={styles.scoreSelection}>
      {[1, 2, 3, 4, 5].map((currentScore) => (
        <div key={currentScore}>
          <TooltipWrapped
            title={
              scoreToTitleMap[currentScore as keyof typeof scoreToTitleMap]
            }
          >
            <div
              onClick={() => !disabled && onChange(currentScore)}
              className={clsx(
                styles.score,
                !disabled && styles.scoreClickable,
                currentScore === score && styles.selected,
              )}
            >
              {currentScore}
            </div>
          </TooltipWrapped>
        </div>
      ))}
    </div>
  );
};
