import React, { useEffect, useState } from 'react';
import { BasicVettingProcessColumnProps } from '..';
import { Button } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import useToggle from '@src/hooks/useToggle';
import { apiVettingProcessPreVetting } from '@ateams/api';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';

const useStyles = createUseStyles({
  button: {
    width: '200px',
  },
});

export const BuilderNoShowField: React.FC<BasicVettingProcessColumnProps> = ({
  vettingProcess,
}) => {
  const { auth } = useStores();
  const styles = useStyles();
  const [modal, toggleCloseModal] = useToggle();
  const [loading, setLoading] = useLoadingState();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setShowButton(
      !!vettingProcess.calComBookingUid && !!vettingProcess.preVettingFormNonce,
    );
  }, [vettingProcess.calComBookingUid, vettingProcess.preVettingFormNonce]);

  const onBuilderNoShow = () => {
    toggleCloseModal();
  };

  const onConfirmBuilderNoShow = async () => {
    setLoading(
      (async () => {
        if (!vettingProcess.preVettingFormNonce) {
          return;
        }

        await apiVettingProcessPreVetting.markAsNoShow(
          auth,
          vettingProcess.preVettingFormNonce,
          true,
        );

        toggleCloseModal();
        setShowButton(false);
        window.location.reload();
      })(),
      'No Show Marked Successfully',
    );
  };

  return (
    <>
      {showButton && (
        <>
          <LoadingIndicator loading={loading} />
          <Button
            onClick={onBuilderNoShow}
            size="small"
            className={styles.button}
          >
            No Show
          </Button>
          <ConfirmModal
            title="Are you sure you want to mark this builder as a no-show?"
            description="Confirming their absence will send a warning email to the builder with a link to reschedule their evaluation. The current interview will be canceled and removed from your table. Any notes left on the Feedback Form will be deleted."
            onConfirm={onConfirmBuilderNoShow}
            open={modal}
            onClose={toggleCloseModal}
            actionColor={'backgroundPurpleDark'}
            cancelColor={'backgroundLight'}
            actionLabel={'Confirm'}
            cancelLabel={'Cancel'}
            width={630}
            hideCloseButton={false}
          />
        </>
      )}
    </>
  );
};
