import React, { ReactElement, useMemo, useState } from 'react';
import UserObject, {
  UserBadge,
  UserType,
} from '@a_team/models/dist/UserObject';
import {
  Breakpoints,
  Button,
  Colors,
  HelpTooltip,
  Icon,
  IconType,
  Select,
  Spacing,
} from '@ateams/components';
import TextButton from '@src/components/TextButton';
import { createUseStyles } from 'react-jss';
import Application from '@src/stores/Application';
import { format } from 'date-fns';
import useLoadingState from '@src/hooks/useLoadingState';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';
import { truncate } from 'lodash';
import {
  ClientBuilderChatLocation,
  ClientBuilderProfileLocation,
  getVettingFeedbackFormViewModeLocation,
} from '@src/locations';
import { Link } from 'react-router-dom';
import AuthStore from '@src/stores/Auth';
import cx from 'classnames';
import {
  AdminMissionApplicationObject,
  MissionApplicationId,
} from '@a_team/models/dist/MissionApplicationObject';
import ConnectionsPanel from '@src/components/Modal/ApplicationModal/ConnectionsPanel';
import { OldStatusControls } from '@src/components/Modal/ApplicationModal/OldStatusControls';
import { StatusControls } from '@src/components/Modal/ApplicationModal/StatusControls';
import { useStores } from '@src/stores';
import { MissionApplicationStatusUpdate } from '@ateams/api/dist/endpoints/Missions';

interface Props {
  profile: UserObject;
  auth: AuthStore;
  application?: Application;
  handleDetails: () => void;
  onClickOnCopyDataClipboard?: () => void;
  onClickOnCopyApplicationDataClipboard?: () => void;
  onClickOnCopyProfileUrlClipboard?: () => void;
  onClickOnCopyEmailClipboard?: () => void;
  onStatusChanged?(
    aid: MissionApplicationId,
    data: MissionApplicationStatusUpdate,
  ): Promise<void>;
  setProposedModalOpen?: (force?: boolean) => void;
  widthConnectionsPanel?: boolean;
}

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
    borderRadius: 5,
    padding: 24,
    paddingTop: 0,
    margin: 24,
    marginBottom: 32,
  },
  row: {
    marginBottom: 4,
  },
  fieldName: {
    fontWeight: 600,
  },
  textButton: {
    textAlign: 'left',
    overflowWrap: 'anywhere',
  },
  inlineTextButton: {
    marginLeft: Spacing.xsmall,
  },
  email: {
    overflowWrap: 'anywhere',
  },
  status: {
    overflowWrap: 'anywhere',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 0,
    width: '100%',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    wrapper: {
      textAlign: 'left',
      alignItems: 'flex-start',
      margin: 0,
      marginBottom: 32,
    },
  },
  multilineText: {
    textWrap: 'initial',
    textAlign: 'left',
    overflowWrap: 'anywhere',
  },
  emailInRed: {
    color: Colors.danger,
  },
});

export const AdminData = (props: Props): ReactElement => {
  const styles = useStyles();
  const { auth } = useStores();
  const {
    profile: {
      email,
      linkedIn,
      status,
      uid,
      dribbble,
      github,
      productHunt,
      medium,
      facebook,
      behance,
      websites,
      type,
      isAdmin,
      lastEvaluationProcess,
      username,
      emailNotificationsBlocked,
      onboardingType,
      adminDisplayedOnboardingStage,
    },
    application,
    onStatusChanged,
    setProposedModalOpen,
    widthConnectionsPanel,
  } = props;

  const applicationSubmitDate = useMemo(() => {
    return (
      !!application &&
      'createdAt' in application.data &&
      application.data.createdAt
    );
  }, [application]);

  const applicationUpdateDate = useMemo(() => {
    return (
      !!application &&
      'updatedAt' in application.data &&
      applicationSubmitDate !== application.data.updatedAt &&
      application.data.updatedAt
    );
  }, [application]);

  const defaultRoleSelect = useMemo(() => {
    return {
      value: application?.currentRole?.rid || '',
      label:
        `${application?.currentRole?.category.title}, ${application?.currentRole?.headline}` ||
        '',
    };
  }, [application?.currentRole?.rid]);
  const availableMissionRoles = useMemo(() => {
    return application?.missionRoles
      ?.filter(
        (e) =>
          ![
            MissionRoleStatus.Canceled,
            MissionRoleStatus.Ended,
            MissionRoleStatus.ScheduledToEnd,
          ].includes(e.status),
      )
      ?.map((role) => ({
        value: role?.rid || '',
        label: truncate(`${role.category.title}, ${role.headline}`, {
          length: 32,
        }),
      }));
  }, [application]);

  const [loading, setLoading] = useLoadingState();
  const [newAid, setNewAid] = useState<string>();

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <h4 style={{ fontWeight: 400, color: '#62646A' }}>Admin data</h4>
        <TextButton
          className={styles.textButton}
          onClick={props.handleDetails}
          highlight
        >
          Details
        </TextButton>
      </div>
      <div className={styles.row}>
        <strong className={styles.fieldName}>User ID:</strong> {uid}
      </div>
      <div className={styles.row}>
        <strong className={styles.fieldName}>Admin:</strong>{' '}
        {isAdmin ? 'Yes' : 'No'}
      </div>
      <div className={styles.row}>
        <strong className={styles.fieldName}>
          User type <HelpTooltip>Regular user or Company user</HelpTooltip> :
        </strong>{' '}
        {type === UserType.User ? 'Regular user' : 'Company user'}
      </div>
      {email && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>Email: </strong>
          <span
            className={cx(
              styles.email,
              emailNotificationsBlocked && styles.emailInRed,
            )}
          >
            {email}
          </span>
          {!emailNotificationsBlocked && (
            <TextButton
              className={styles.inlineTextButton}
              onClick={() => {
                if (props.onClickOnCopyEmailClipboard) {
                  props.onClickOnCopyEmailClipboard();
                }
              }}
            >
              <Icon size="exact" type={IconType.Copy} /> Copy Email
            </TextButton>
          )}
        </div>
      )}
      {
        <div className={styles.row}>
          <strong className={styles.fieldName}>
            Blocked from email notifications:{' '}
          </strong>
          {emailNotificationsBlocked ? 'Yes' : 'No'}
        </div>
      }
      {linkedIn && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>LinkedIn: </strong>
          <a
            target="_blank"
            className={styles.multilineText}
            href={linkedIn.profileUrl}
            rel="noreferrer"
          >
            {linkedIn.profileUrl}
          </a>
        </div>
      )}
      {dribbble && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>Dribbble: </strong>
          <a
            target="_blank"
            className={styles.multilineText}
            href={dribbble.profileURL}
            rel="noreferrer"
          >
            {dribbble.profileURL}
          </a>
        </div>
      )}
      {github && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>Github: </strong>
          <a
            target="_blank"
            className={styles.multilineText}
            href={github.profileUrl}
            rel="noreferrer"
          >
            {github.profileUrl}
          </a>
        </div>
      )}
      {productHunt && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>ProductHunt: </strong>
          <a
            target="_blank"
            className={styles.multilineText}
            href={productHunt.profileURL}
            rel="noreferrer"
          >
            {productHunt.profileURL}
          </a>
        </div>
      )}
      {medium && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>Medium: </strong>
          <a
            target="_blank"
            className={styles.multilineText}
            href={medium.profileURL}
            rel="noreferrer"
          >
            {medium.profileURL}
          </a>
        </div>
      )}
      {facebook && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>Facebook: </strong>
          <a
            target="_blank"
            className={styles.multilineText}
            href={facebook.profileUrl}
            rel="noreferrer"
          >
            {facebook.profileUrl}
          </a>
        </div>
      )}
      {behance && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>Behance: </strong>
          <a
            target="_blank"
            className={styles.multilineText}
            href={behance.profileURL}
            rel="noreferrer"
          >
            {behance.profileURL}
          </a>
        </div>
      )}
      {websites && websites.length > 0 && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>Websites: </strong>
          <ul className={styles.websites}>
            {websites.map((website) => (
              <li>
                <a
                  target="_blank"
                  className={styles.multilineText}
                  href={website}
                  rel="noreferrer"
                >
                  {website}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
      {uid && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>Profile URL: </strong>
          <a
            target="_blank"
            className={styles.multilineText}
            href={ClientBuilderProfileLocation(uid)}
            rel="noreferrer"
          >
            {ClientBuilderProfileLocation(uid)}
          </a>
          <TextButton
            className={styles.inlineTextButton}
            onClick={() => {
              if (props.onClickOnCopyProfileUrlClipboard) {
                props.onClickOnCopyProfileUrlClipboard();
              }
            }}
          >
            <Icon size="exact" type={IconType.Copy} /> Copy profile URL
          </TextButton>
        </div>
      )}

      {!props.auth.withAiChatCta && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>Chat: </strong>
          <a
            target="_blank"
            className={styles.multilineText}
            href={ClientBuilderChatLocation(username)}
            rel="noreferrer"
          >
            {ClientBuilderChatLocation(username)}
          </a>
        </div>
      )}

      {status && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>User status: </strong>
          <span className={styles.status}>{status}</span>
        </div>
      )}
      {onboardingType && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>Onboarding Type: </strong>
          <span className={styles.status}>{onboardingType}</span>
        </div>
      )}

      {adminDisplayedOnboardingStage && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>Onboarding Stage: </strong>
          <span
            className={styles.status}
            style={{
              textTransform: 'capitalize',
            }}
          >
            {adminDisplayedOnboardingStage}
          </span>
        </div>
      )}

      {lastEvaluationProcess && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>Evaluation feedback: </strong>
          <Link
            target="_blank"
            to={getVettingFeedbackFormViewModeLocation(
              lastEvaluationProcess.id,
            )}
          >
            Feedback form
          </Link>{' '}
          (
          {lastEvaluationProcess.createdAt
            ? format(new Date(lastEvaluationProcess.createdAt || ''), 'PPp')
            : ''}
          )
        </div>
      )}
      {applicationSubmitDate && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>Submitted at: </strong>
          <span>{format(new Date(applicationSubmitDate), 'PPp')}</span>
        </div>
      )}
      {applicationUpdateDate && (
        <div className={styles.row}>
          <strong className={styles.fieldName}>Updated at: </strong>
          <span>{format(new Date(applicationUpdateDate), 'PPp')}</span>
        </div>
      )}
      {auth.withMissionApplicationStatusV2
        ? onStatusChanged && (
            <>
              <hr style={{ margin: '16px 0', width: '100%' }} />
              <StatusControls
                accepted={application?.statusData?.accepted}
                exclusiveStatus={application?.statusData?.exclusiveStatus}
                proposalInterviewing={
                  application?.statusData?.proposalInterviewing
                }
                proposal={application?.statusData?.proposal}
                hasVettingScheduled={(
                  application as unknown as AdminMissionApplicationObject
                )?.user?.badges.includes(UserBadge.VettingScheduled)}
                reviewStatus={application?.statusData?.reviewStatus}
                onChange={(data) =>
                  application?.aid &&
                  setLoading(
                    onStatusChanged(application.aid, data).then(() => {
                      data.proposed && setProposedModalOpen?.();
                    }),
                  )
                }
                disabled={loading === true}
              ></StatusControls>
              <hr style={{ margin: '16px 0', width: '100%' }} />
            </>
          )
        : application?.statusData &&
          'status' in application?.data &&
          onStatusChanged && (
            <OldStatusControls
              status={application.data.status}
              statusData={application.statusData}
              onChange={(data) =>
                application?.aid &&
                setLoading(
                  onStatusChanged(application.aid, data).then(() => {
                    data.proposed && setProposedModalOpen?.();
                  }),
                )
              }
              disabled={loading === true}
            >
              {widthConnectionsPanel && (
                <ConnectionsPanel
                  username={username}
                  aid={application?.aid || null}
                  mid={application?.mid}
                />
              )}
            </OldStatusControls>
          )}
      {applicationSubmitDate && (
        <div className={styles.row}>
          <p className={styles.fieldName}>
            Re-assign application to another role
          </p>
          <Select
            key={application?.rid}
            isDisabled={!!loading}
            onChange={async (e) => {
              const newValue = e?.value;
              if (!newValue) return;
              const aid = application?.aid;
              if (!aid || !application?.updateApplicationRole) return;
              setNewAid(newValue);
            }}
            defaultValue={defaultRoleSelect}
            options={availableMissionRoles}
          />
          <Button
            size={'small'}
            disabled={!newAid}
            onClick={() => {
              const aid = application?.aid;
              if (!aid || !application?.updateApplicationRole || !newAid)
                return;
              setLoading(
                application
                  ?.updateApplicationRole(aid, newAid)
                  .then(() => setNewAid(undefined))
                  .catch((err) => {
                    setLoading(false);
                    setNewAid(undefined);
                    alert(
                      `Error reassigning application to another role ${err}`,
                    );
                  }),
                null,
              );
            }}
          >
            Update
          </Button>
        </div>
      )}

      <TextButton
        className={styles.textButton}
        onClick={() => {
          if (props.onClickOnCopyDataClipboard) {
            props.onClickOnCopyDataClipboard();
          }
        }}
      >
        <Icon size="exact" type={IconType.Copy} /> Copy builder data to
        clipboard
      </TextButton>

      {applicationSubmitDate && (
        <TextButton
          className={styles.textButton}
          onClick={() => {
            if (props.onClickOnCopyApplicationDataClipboard) {
              props.onClickOnCopyApplicationDataClipboard();
            }
          }}
        >
          <Icon size="exact" type={IconType.Copy} /> Copy application and
          builder data to clipboard
        </TextButton>
      )}
    </div>
  );
};
