import React from 'react';
import { SelectionTeamCardObject } from '@a_team/models/dist/UserObject';
import { PaginationProps } from '@src/components/Pagination';
import UserCardListForSelectionTeam from '@src/components/UserCardListForSelectionTeam';

export interface SelectionTeamGridProps {
  rows: SelectionTeamCardObject[];
  paginationProps?: PaginationProps;
}

export const SelectionTeamGrid: React.FC<SelectionTeamGridProps> = (props) => {
  const { rows } = props;

  return <UserCardListForSelectionTeam users={rows} />;
};
