import React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';

interface AspectRatioBoxProps {
  aspectRatio: number;
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
}

const useStyles = createUseStyles({
  container: {
    height: 0,
    overflow: 'hidden',
    paddingTop: ({ aspectRatio }: { aspectRatio: number }) => {
      return (1 / aspectRatio) * 100 + '%';
    },
    position: 'relative',
    width: '100%',
  },
  content: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
});

const AspectRatioBox = ({
  aspectRatio,
  children,
  className,
  contentClassName,
}: AspectRatioBoxProps): JSX.Element => {
  const styles = useStyles({ aspectRatio });
  return (
    <div className={cx(styles.container, className)}>
      <div className={cx(styles.content, contentClassName)}>{children}</div>
    </div>
  );
};

export default AspectRatioBox;
