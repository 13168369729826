import { format } from 'date-fns';
import { DateISOString } from '@a_team/models/dist/misc';
import { LocalHourRange } from '@a_team/models/dist/WorkingHoursObject';
import { DateTime } from 'luxon';

/**
 * THIS FUNCTION SHOULD ONLY BE USED BEFORE FORMATTING A DATE FOR DISPLAY AS IT RETURNS A DIFFERENT POINT IN TIME!
 * Offsets date object's time difference from UTC to enable displaying the UTC time when formatting.
 * @param {Date} date - The date to be displayed in UTC
 */
export const getLocalTime = (date: Date): Date =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
  );

/**
 * Returns the timestamp at midnight (00:00:00) of the current date in UTC.
 * The timestamp is in milliseconds elapsed since January 1, 1970 00:00:00 UTC.
 *
 * @returns {number} The timestamp at midnight of the current date in UTC.
 */
export const getUTCMidnightTimestamp = (): number => {
  const now = new Date();
  return Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
};

export const stringifyDateRangeAdvanced = (
  startDate?: DateISOString,
  endDate?: DateISOString,
  forceShowYear = false,
  allowOnlyStartDate = true,
  displayTimeInUTC = true,
) => {
  const dateTransformerFunc = displayTimeInUTC
    ? getLocalTime
    : (date: Date) => date;

  if (!startDate || (!endDate && !allowOnlyStartDate)) {
    return '';
  }

  startDate = dateTransformerFunc(new Date(startDate));

  const isCurrentYear =
    dateTransformerFunc(new Date()).getFullYear() === startDate.getFullYear();
  const yearString =
    !forceShowYear && isCurrentYear ? '' : ` ${format(startDate, 'yyyy')}`;

  if (!endDate) {
    return format(startDate, 'MMM dd') + yearString;
  }

  endDate = dateTransformerFunc(new Date(endDate));

  if (startDate.getFullYear() !== endDate.getFullYear()) {
    return `${format(startDate, 'MMM dd, yyyy')}-${format(
      endDate,
      'MMM dd, yyyy',
    )}`;
  }

  if (startDate.getMonth() !== endDate.getMonth()) {
    return `${format(startDate, 'MMM d')}-${format(
      endDate,
      'MMM d',
    )}${yearString}`;
  }
  return `${format(startDate, 'MMM d')}-${format(endDate, 'd')}${yearString}`;
};

export function stringifyDateRange(
  startDate: Date | string,
  endDate: Date | string,
): string {
  return `${format(getLocalTime(new Date(startDate)), 'LLL d')} - ${format(
    getLocalTime(new Date(endDate)),
    'LLL d',
  )}`;
}

export function stringifyDate(date: Date | string, noYear?: boolean): string {
  return format(
    new Date(String(date).slice(0, -1)),
    noYear ? 'MM/dd' : 'MM/dd/yyyy',
  );
}

export const formatToTime = (value?: string | number): string => {
  return `${value || ''}00`.substr(0, 2);
};

export function stringifyMinutes(minutes: number): string {
  if (!minutes) {
    return '0h';
  }

  const h = Math.floor(minutes / 60);
  const m = Math.floor(minutes % 60);

  return m ? `${h}h ${String(m).padStart(2, '0')}m` : `${h}h`;
}

export const formatMinutesToTime = (minutes: number): string => {
  const h = Math.floor(minutes / 60);
  const m = Math.floor(minutes % 60);

  return `${String(h)}:${String(m).padStart(2, '0')}`;
};

/**
 * Converts a date into a Day + hours string
 * i.e Dec 04, 2:53 AM
 * @param {Date} date
 */
export const formatDateWithTime = (date: Date): string => {
  return format(date, 'LLL dd, p');
};

export const getCurrentYear = (): number => {
  return new Date().getFullYear();
};

export const getTimezoneOffsetInMinutes = (
  timeZone = 'UTC',
  date = new Date(),
) => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};

export const getIntersectedLocalHourRange = (
  range1: LocalHourRange,
  range2: LocalHourRange,
): LocalHourRange | null => {
  const start = Math.max(range1.startTime, range2.startTime);
  const end = Math.min(range1.endTime, range2.endTime);
  if (start >= end) {
    return null;
  }

  return { startTime: start, endTime: end } as LocalHourRange;
};

export const beautifyTime = (time: string) => {
  return time.replace(':00', '').replace(' AM', 'am').replace(' PM', 'pm');
};

export const getCountryFromTimezoneName = (timezomeName: string) => {
  return timezomeName.split('/')[1]?.replace('_', ' ') ?? timezomeName;
};

export const getTimeOverlap = (
  userDaily: LocalHourRange[] = [
    {
      startTime: 510,
      endTime: 1170,
    },
  ],
  userTimezoneName: string,
  roleDaily: LocalHourRange[],
  roleTimezoneName: string,
) => {
  const roleWorkingHoursSlotsInUtc: LocalHourRange[] = [];
  const roleUtcOffset = getTimezoneOffsetInMinutes(roleTimezoneName);
  for (const roleDay of roleDaily) {
    let startTime = (roleDay.startTime - roleUtcOffset) % 1440;
    let endTime = (roleDay.endTime - roleUtcOffset) % 1440;

    if (startTime < 0) {
      startTime = 1440 + startTime;
    }

    if (endTime < 0) {
      endTime = 1440 + endTime;
    }

    if (startTime > endTime) {
      roleWorkingHoursSlotsInUtc.push({
        startTime,
        endTime: 1440,
      });
      roleWorkingHoursSlotsInUtc.push({
        startTime: 0,
        endTime,
      });
    } else {
      roleWorkingHoursSlotsInUtc.push({
        startTime,
        endTime,
      });
    }
  }

  const userWorkingHoursSlotsInUtc: LocalHourRange[] = [];
  const userUtcOffset = getTimezoneOffsetInMinutes(userTimezoneName);

  for (const userDay of userDaily) {
    let startTime = (userDay.startTime - userUtcOffset) % 1440;
    let endTime = (userDay.endTime - userUtcOffset) % 1440;

    if (startTime < 0) {
      startTime = 1440 + startTime;
    }

    if (endTime < 0) {
      endTime = 1440 + endTime;
    }

    if (startTime > endTime) {
      userWorkingHoursSlotsInUtc.push({
        startTime,
        endTime: 1440,
      });
      userWorkingHoursSlotsInUtc.push({
        startTime: 0,
        endTime,
      });
    } else {
      userWorkingHoursSlotsInUtc.push({
        startTime,
        endTime,
      });
    }
  }

  let overlappedWorkingHoursSlots: LocalHourRange[] = [];

  for (const roleSlot of roleWorkingHoursSlotsInUtc) {
    for (const userSlot of userWorkingHoursSlotsInUtc) {
      const intersectedRange = getIntersectedLocalHourRange(roleSlot, userSlot);
      if (intersectedRange) {
        overlappedWorkingHoursSlots.push(intersectedRange);
      }
    }
  }

  const overlappedTimeInMinutes = overlappedWorkingHoursSlots.reduce(
    (acc, { startTime, endTime }) => acc + endTime - startTime,
    0,
  );

  // max 2 slots will exist as the company working hours is only one range and the
  // user working hours is up to 2 ranges and the intersection of them produces 2 ranges max
  if (overlappedWorkingHoursSlots.length === 2) {
    const firstSlot = overlappedWorkingHoursSlots[0];
    const secondSlot = overlappedWorkingHoursSlots[1];
    if (firstSlot.endTime === 1440 && secondSlot.startTime === 0) {
      overlappedWorkingHoursSlots = [
        {
          startTime: firstSlot.startTime,
          endTime: secondSlot.endTime,
        },
      ];
    }
    if (firstSlot.startTime === 0 && secondSlot.endTime === 1440) {
      overlappedWorkingHoursSlots = [
        {
          startTime: secondSlot.startTime,
          endTime: firstSlot.endTime,
        },
      ];
    }

    // we need to sort the slots by the end time in the user's timezone
    overlappedWorkingHoursSlots = overlappedWorkingHoursSlots.sort((a, b) => {
      const aEndTime = DateTime.fromObject(
        {
          hour: Math.floor(a.endTime / 60),
          minute: a.endTime % 60,
        },
        { zone: 'UTC' },
      )
        .setZone(userTimezoneName)
        .toFormat('HHmm');

      const bEndTime = DateTime.fromObject(
        {
          hour: Math.floor(b.endTime / 60),
          minute: a.endTime % 60,
        },
        { zone: 'UTC' },
      )
        .setZone(userTimezoneName)
        .toFormat('HHmm');

      return parseInt(aEndTime) - parseInt(bEndTime);
    });
  }

  return { overlappedWorkingHoursSlots, overlappedTimeInMinutes };
};
