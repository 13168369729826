import React from 'react';
import cx from 'classnames';
import { useCommonStyles } from '../../commonStyles';
import { HowToConnect } from '.';
import { Step } from './Step';
import UnSupportCalendarsImg from '../svgs/unsupported.svg';
import CreateGoogleAccount from '../svgs/createGoogleAccount.svg';
import CreateLink from '../svgs/createLink.svg';
import Subscribe from '../svgs/subscribe.svg';
import AddCalendar from '../svgs/addCalendar.svg';

interface UnsupportedCalendarProps {
  title: string;
  copy: string;
  onClose: () => void;
  onSupport: () => void;
}

export const HowToUnsupportedCalendar = (props: UnsupportedCalendarProps) => {
  const styles = useCommonStyles();

  const getTitle = () => (
    <div className={styles.hero}>
      <img
        src={UnSupportCalendarsImg}
        className={styles.heroImg}
        alt="Connect Unsupported Calendars"
      />
      <h4 className={styles.modalTitle}>{props.title}</h4>
      <p className={cx(styles.heroCopy, 'lessPadding')}>{props.copy}</p>
    </div>
  );

  return (
    <HowToConnect
      title={getTitle()}
      onClose={props.onClose}
      onSupport={props.onSupport}
    >
      <div className={styles.steps}>
        <Step
          title="Step 1"
          copy="Open a free Google account to sync to your other calendar"
          link="https://accounts.google.com/signup"
          linkTitle="Set up a free Google account"
          img={<img src={CreateGoogleAccount} alt="Create Google Account" />}
        />
        <Step
          title="Step 2"
          copy="Create a subscription or sharing link from your calendar provider"
          link="https://guide.a.team/missions/interview-scheduling#a8340b01414744d98872ad7ad0233f8f"
          linkTitle="How to create a link"
          img={<img src={CreateLink} alt="Create a link to calendar" />}
        />
        <Step
          title="Step 3"
          copy="Subscribe your Google account to your other calendar so that it reflects your availability"
          link="https://guide.a.team/missions/interview-scheduling#a8340b01414744d98872ad7ad0233f8f"
          linkTitle="How to subscribe"
          img={<img src={Subscribe} alt="Subscribe to calendar" />}
        />
        <Step
          title="Step 4"
          copy="Connect your Google calendar to A.Team"
          link="https://guide.a.team/missions/interview-scheduling#a8340b01414744d98872ad7ad0233f8f"
          linkTitle="How to connect"
          img={<img src={AddCalendar} alt="Add calendar" />}
        />
      </div>
    </HowToConnect>
  );
};
