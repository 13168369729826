import React, { forwardRef } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    padding: 16,
    border: '1px solid #FE630C',
    background: '#FFFAF7',
    borderRadius: 8,
    marginBottom: 24,
  },
});

interface ErrorBarProps {
  error: string;
}

const ErrorBar = forwardRef<HTMLDivElement, ErrorBarProps>(({ error }, ref) => {
  const styles = useStyles();
  return (
    <div
      ref={ref}
      className={styles.container}
      data-testing-id="timesheets-error-banner"
    >
      {error}
    </div>
  );
});

export default ErrorBar;
