import React, { ReactElement, useMemo } from 'react';
import { observer } from 'mobx-react';
import RegistrationStep from '@src/views/Registration/RegistrationStep';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import { AsyncSelect, SelectOption } from '@ateams/components';
import { TalentSpecialization } from '@a_team/models/dist/TalentCategories';
import { useScreenClass } from 'react-grid-system';
import ExpertiseSelector from '@src/components/ExpertiseSelector';
import { Expertise } from '@src/stores/Profile/models';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  select: {
    marginBottom: 0,
  },
});

const MainSpecialization = (): ReactElement => {
  const styles = useStyles();
  const screenClass = useScreenClass();
  const {
    registration: {
      formData,
      currentStep,
      querySpecializations,
      setMainSpecialization,
    },
  } = useStores();
  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  const [loading, setLoading] = useLoadingState();

  const onSubmit = () => {
    currentStep.onSubmit && setLoading(currentStep.onSubmit());
  };

  const selectedValue = useMemo(() => {
    const role =
      formData.talentProfile?.talentSpecializations?.mainTalentSpecialization;
    return role
      ? {
          id: role.id,
          value: role.id,
          label: role.name,
          name: role.name,
          featured: true,
          talentCategoryIds: role.talentCategoryIds,
        }
      : undefined;
  }, [formData.talentProfile?.talentSpecializations?.mainTalentSpecialization]);

  const isTalentSpecialization = (
    item: SelectOption,
  ): item is TalentSpecialization & SelectOption => {
    return 'talentCategoryIds' in item;
  };

  const onQuery = async (query: string): Promise<Expertise[]> => {
    const options = await querySpecializations(query);
    return options.map((option) => {
      return {
        ...option,
        id: option.value,
        name: option.label,
      };
    });
  };

  const onSelect = (item: SelectOption | null) => {
    if (!item) return;
    if (isTalentSpecialization(item)) {
      const specialization: TalentSpecialization = {
        id: item.id,
        name: item.name,
        talentCategoryIds: item.talentCategoryIds,
      };
      setMainSpecialization(specialization);
    }
  };

  const onTagSelect = (roles: Expertise[]) => {
    if (!roles[0]) return;
    onSelect({
      ...roles[0],
      label: roles[0].name,
      value: roles[0].id,
    });
  };

  return (
    <RegistrationStep
      title={'What Role Best Describes You?'}
      description={
        "If you could only choose one, what would be your main role when building with a team? Don't worry, you'll have the opportunity to add secondary roles too."
      }
      onSubmit={onSubmit}
      canSubmit={loading !== true && currentStep.canSubmit}
      loading={loading}
    >
      {isMobile ? (
        <ExpertiseSelector
          displayType={'tags'}
          onSearch={onQuery}
          onChange={onTagSelect}
          selectedItems={selectedValue ? [selectedValue] : []}
          singleSelection
          style={{ marginBottom: 30 }}
          limitAnswersHeight={false}
        />
      ) : (
        <AsyncSelect
          placeholder={'Select your primary role...'}
          defaultInputValue={
            formData.talentProfile?.talentSpecializations
              ?.mainTalentSpecialization
              ? formData.talentProfile?.talentSpecializations
                  ?.mainTalentSpecialization.name
              : undefined
          }
          loadOptions={querySpecializations}
          onChange={(item: SelectOption | SelectOption[] | null) =>
            onSelect(item as SelectOption)
          }
          className={styles.select}
        />
      )}
    </RegistrationStep>
  );
};

export default observer(MainSpecialization);
