import React, { ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import Colors, { ColorName } from '../theme/colors';

export interface ToggleSwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string | ReactNode;
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  justify: 'left' | 'right';
  size?: 'xsmall' | 'small' | 'large';
  color?: 'normal' | 'purple';
  checkedBackgroundColor?: ColorName;
  ['data-testing-id']?: string;
}

const defaultProps = {
  size: 'large',
  checkedBackgroundColor: 'info',
  justify: 'left',
};

const getWidth = (size: ToggleSwitchProps['size']) => {
  switch (size) {
    case 'xsmall':
      return 30;
    case 'small':
      return 45;
    case 'large':
      return 60;
    default:
      return 60;
  }
};

const getHeight = (size: ToggleSwitchProps['size']) => {
  switch (size) {
    case 'xsmall':
      return 18;
    case 'small':
      return 24;
    case 'large':
      return 34;
    default:
      return 34;
  }
};

const getToggleSize = (size: ToggleSwitchProps['size']) => {
  switch (size) {
    case 'xsmall':
      return 14;
    case 'small':
      return 16;
    case 'large':
      return 26;
    default:
      return 26;
  }
};

const getTranslateX = (size: ToggleSwitchProps['size']) => {
  switch (size) {
    case 'xsmall':
      return 12;
    case 'small':
      return 21;
    case 'large':
      return 26;
    default:
      return 26;
  }
};

const getLeftBottom = (size: ToggleSwitchProps['size']) => {
  switch (size) {
    case 'xsmall':
      return 2;
    case 'small':
      return 4;
    default:
      return 4;
  }
};

const useStyles = createUseStyles<ToggleSwitchProps>({
  container: ({ justify }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: justify === 'right' ? 'row-reverse' : 'unset',
  }),
  label: ({ justify }) => ({
    marginRight: justify === 'right' ? 8 : 'unset',
    marginLeft: justify !== 'right' ? 8 : 'unset',
  }),
  switch: (props) => ({
    position: 'relative',
    display: 'inline-block',
    flex: '0 0 auto',
    width: getWidth(props.size),
    maxWidth: getWidth(props.size),
    height: getHeight(props.size),
    ...(props.disabled && {
      opacity: 0.5,
      cursor: 'default',
    }),
  }),
  checkbox: {
    opacity: 0,
    width: 0,
    height: 0,
  },
  slider: (props: ToggleSwitchProps) => ({
    position: 'absolute',
    cursor: props.disabled ? 'default' : 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: props.checked
      ? props.color === 'purple'
        ? Colors.secondaryDark
        : Colors[props.checkedBackgroundColor || 'info']
      : '#ccc',
    transition: '.4s',
    borderRadius: 34,
    '&:before': {
      position: 'absolute',
      content: '""',
      height: getToggleSize(props.size),
      width: getToggleSize(props.size),
      left: getLeftBottom(props.size),
      bottom: getLeftBottom(props.size),
      background: '#fff',
      transition: '.4s',
      borderRadius: '50%',
      transform: `translateX(${
        props.checked ? getTranslateX(props.size) + 'px' : '0'
      })`,
    },
  }),
});

export const ToggleSwitch = (props: ToggleSwitchProps): ReactElement => {
  const styles = useStyles(props);
  const { checked, onChange, disabled, className, label, containerClassName } =
    props;
  return (
    <div
      data-testing-id={props['data-testing-id']}
      className={cx(styles.container, containerClassName)}
    >
      <label className={cx(styles.switch, className)}>
        <input
          type="checkbox"
          className={styles.checkbox}
          checked={checked}
          disabled={disabled}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span className={styles.slider} />
      </label>
      {label && <span className={styles.label}>{label}</span>}
    </div>
  );
};

ToggleSwitch.defaultProps = defaultProps;
