import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import SectionHeading from '@src/components/SectionHeading';

interface Props {
  children: ReactNode;
  title?: string;
  style?: CSSProperties;
}

export const AssignmentsBox = (props: Props): ReactElement => {
  return (
    <div
      style={{
        width: '100%',
        marginBottom: 24,
        flexShrink: 1,
        minHeight: 200,
        ...props.style,
      }}
    >
      <SectionHeading isFirst>{props.title || 'Assignment'}</SectionHeading>
      {props.children}
    </div>
  );
};
