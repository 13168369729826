import React from 'react';
import { createUseStyles } from 'react-jss';
import { Select } from '@a_team/ui-components';
import ProjectUserSelector from '@src/components/ProjectUserSelector';
import { ExperienceMember } from '@a_team/models/dist/ExperienceObject';
import { Icon, IconType, SelectOption } from '@ateams/components';
import { BasicUserObject } from '@a_team/models/dist/UserObject';

interface CollaboratorProps {
  memberIdex: number;
  member: ExperienceMember;
  roles: { value: string; label: string }[] | undefined;
  setMember: (index: number, user: ExperienceMember) => void;
  setRole: (role: SelectOption, user: ExperienceMember) => void;
  removeCollaborator: (user: ExperienceMember) => void;
  isOwner: boolean;
}

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    marginBottom: 8,
  },
  collaborator: {
    width: '80%',
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    '& > div': {
      flex: 1,
    },
  },
  projectUserWrapper: {
    '& span[class*="indicatorSeparator"]': {
      display: 'none',
    },
    '& div[class*="ValueContainer"] > div:first-child >  div:first-child': {
      padding: '0 !important',
    },
    '& div[class*="control"]': {
      height: 41,
      borderColor: '#DADADC',
    },
    '& div[class*="placeholder"]': {
      color: '#B5B8BF',
    },
  },
  deleteButton: {
    transition: 'all 0.3s ease',

    borderRadius: 4,
    border: '1px solid #C0C0C0',
    background: '#FFFFFF',
    height: 38,
    cursor: 'pointer',
    padding: '0 10px',
    '&:hover': {
      opacity: 0.8,
    },
  },
});

const Collaborator = ({
  memberIdex,
  member,
  roles,
  setMember,
  setRole,
  removeCollaborator,
  isOwner,
}: CollaboratorProps): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <div className={styles.collaborator}>
        <div className={styles.projectUserWrapper}>
          <ProjectUserSelector
            user={member as BasicUserObject}
            placeholder="Add a collaborator"
            onSelect={(user): void =>
              setMember(memberIdex, user as ExperienceMember)
            }
            menuMaxHeight={150}
            disabled={!isOwner}
          />
        </div>
        <div>
          <Select
            styles={{
              control: (base) => ({
                ...base,
                border: '1px solid #DADADC',
              }),
              menu: (base) => ({
                ...base,
                maxHeight: 150,
                overflow: 'hidden',
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 999999,
                color: '#000000',
              }),
            }}
            value={roles?.find((role) => role.value === member?.memberRole)}
            placeholder="Select role"
            options={roles}
            onChange={(role) => setRole(role as SelectOption, member)}
            menuPortalTarget={document.body}
            isDisabled={!isOwner}
          />
        </div>
      </div>
      {isOwner && (
        <div>
          <button
            type="button"
            className={styles.deleteButton}
            onClick={() => removeCollaborator(member)}
          >
            <Icon
              type={IconType.TrashBlack}
              style={{ cursor: 'pointer' }}
              size={'exact'}
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default Collaborator;
