import { SurveyType } from '@a_team/models/dist/Survey/SurveyType';
import { TeamPulseSurvey } from '@a_team/models/dist/TeamPulse';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import Mission from '@src/stores/Missions/Mission';
import { useState } from 'react';
import {
  MemberResponses,
  TeamPulseSectionsEnum,
  TeamPulseUserReviewSectionsEnum,
} from '../TeamPulseModal';

interface UseControlDetailedTeamPulseProps {
  teamPulseSurvey: TeamPulseSurvey<SurveyType<never>>;
  currentMission: Mission | undefined;
  setSection: (section: string) => void;
  setUserReviewSection: (section: string | undefined) => void;
}

export const useControlDetailedTeamPulse = ({
  teamPulseSurvey,
  currentMission,
  setSection,
  setUserReviewSection,
}: UseControlDetailedTeamPulseProps) => {
  const getHiddenUsers = () => {
    const hiddenUserIds = teamPulseSurvey.hiddenUsersIds;
    const users = hiddenUserIds?.map((userId) => {
      const userRole = currentMission?.data.roles.find(
        (role) => role.user && 'uid' in role.user && role.user.uid === userId,
      );
      return userRole?.user;
    });

    if (!users) return undefined;

    return users.filter((user) => user !== undefined) as BasicUserObject[];
  };

  const getUsersToReview = () => {
    // filter out keys that are not in the team
    const keysToUserOut = [
      'overallRating',
      'canWeShareFeedbackWithClient',
      'tellUsMore',
      'shareProudMoments',
      'shareHighlights',
      'isScopedWellDefined',
      'howAdditionalBuildersCouldHelp',
      'whatCouldImproveExperience',
      'canMissionUseMoreBuilders',
      'rolesRecommendedForMission',
      'buildersRecommendedForMission',
      'whyTheseBuilders',
    ];
    const userIds = Object.keys(teamPulseSurvey.questions).filter(
      (key) => !keysToUserOut.includes(key),
    );

    const users = userIds.map((userId) => {
      const userRole = currentMission?.data.roles.find(
        (role) => role.user && 'uid' in role.user && role.user.uid === userId,
      );
      return userRole?.user;
    });

    // filter out hidden users
    const hiddenUsers = teamPulseSurvey.hiddenUsersIds;
    const filteredUsers = users.filter((user) => {
      if (!user) return false;
      if (!hiddenUsers) return true;
      if (!('uid' in user)) return false;
      return !hiddenUsers?.includes(user.uid);
    });

    return filteredUsers as BasicUserObject[];
  };

  const [usersToReview, setUsersToReview] = useState<BasicUserObject[]>(
    getUsersToReview(),
  );

  const [memberResponses, setMemberResponses] = useState<MemberResponses>({});
  const [hiddenUsers, setHiddenUsers] = useState<BasicUserObject[] | undefined>(
    getHiddenUsers(),
  );
  const [currentTeamMemberToReview, setCurrentTeamMemberToReview] = useState<
    BasicUserObject | undefined
  >(usersToReview[0]);

  const moveUserToHidden = (uid: string) => {
    const userToMove = usersToReview.find((user) => user.uid === uid);
    if (userToMove) {
      const newUsersToReview = usersToReview.filter((user) => user.uid !== uid);
      setUsersToReview(newUsersToReview);
      setHiddenUsers((prev) => [...(prev || []), userToMove]);
    }
  };

  const moveUserToReview = (uid: string) => {
    const userToMove = hiddenUsers?.find((user) => user.uid === uid);
    if (userToMove) {
      const newHiddenUsers = hiddenUsers?.filter((user) => user.uid !== uid);
      // remove userToRemove from memberResponses
      const newMemberResponses = Object.keys(memberResponses).reduce(
        (acc, key) => {
          if (key !== uid) {
            acc[key] = memberResponses[key];
          }
          return acc;
        },
        {} as MemberResponses,
      );
      setMemberResponses(newMemberResponses);
      setHiddenUsers(newHiddenUsers);
      setUsersToReview((prev) => [...prev, userToMove]);
    }
  };

  const handleTeamMemberRating = ({
    value,
    unableToRespond,
    wouldLikeToTeamUpAgain,
  }: {
    value?: number;
    unableToRespond: boolean;
    wouldLikeToTeamUpAgain: boolean;
  }) => {
    if (currentTeamMemberToReview) {
      setMemberResponses((prev) => ({
        ...prev,
        [currentTeamMemberToReview.uid]: {
          ...prev[currentTeamMemberToReview.uid],
          value,
          unableToRespond,
          wouldLikeToTeamUpAgain,
        },
      }));
    }
  };

  const handleTeamMemberFeedback = (
    shareFeedbackWithTeammate: boolean,
    comment?: string,
  ) => {
    if (currentTeamMemberToReview) {
      setMemberResponses((prev) => ({
        ...prev,
        [currentTeamMemberToReview.uid]: {
          ...prev[currentTeamMemberToReview.uid],
          comment,
          shareFeedbackWithTeammate,
        },
      }));
    }
  };

  const onNextMember = () => {
    const nextMemberIndex = usersToReview.findIndex(
      (user) => user.uid === currentTeamMemberToReview?.uid,
    );

    if (nextMemberIndex === usersToReview.length - 1) {
      setUserReviewSection(undefined);
      setSection(TeamPulseSectionsEnum.AllDone);
    } else {
      setCurrentTeamMemberToReview(usersToReview[nextMemberIndex + 1]);
      setUserReviewSection(TeamPulseUserReviewSectionsEnum.TeamMemberRating);
    }
  };

  const onPreviousMember = () => {
    const prevMemberIndex = usersToReview.findIndex(
      (user) => user.uid === currentTeamMemberToReview?.uid,
    );

    // clear responses from current member
    setMemberResponses((prev) => {
      const newResponses = { ...prev };
      if (currentTeamMemberToReview) {
        delete newResponses[currentTeamMemberToReview.uid];
      }
      return newResponses;
    });

    if (prevMemberIndex === 0) {
      setUserReviewSection(undefined);
    }

    if (prevMemberIndex > 0) {
      setCurrentTeamMemberToReview(usersToReview[prevMemberIndex - 1]);
      setUserReviewSection(TeamPulseUserReviewSectionsEnum.TeamMemberFeedback);
    }
  };

  return {
    usersToReview,
    hiddenUsers,
    memberResponses,
    currentTeamMemberToReview,
    onPreviousMember,
    setUsersToReview,
    setUserReviewSection,
    moveUserToHidden,
    moveUserToReview,
    handleTeamMemberRating,
    handleTeamMemberFeedback,
    onNextMember,
  };
};
