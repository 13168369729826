import { Button, Modal } from '@a_team/ui-components';
import { TextColors } from '@ateams/components';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';

interface TimesheetMissingDataModalProps {
  onClose(): void;
  open: boolean;
  onSubmit: () => void;
}

const useStyles = createUseStyles({
  container: {
    padding: '24px 24px 40px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 10,
  },
  mainContent: {
    textAlign: 'center',
    color: TextColors.lighter,
    lineHeight: '1.5',
    marginTop: 8,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 12,
    marginBottom: 24,
  },
});

const TimesheetMissingDataModal = ({
  open,
  onClose,
  onSubmit,
}: TimesheetMissingDataModalProps): ReactElement => {
  const styles = useStyles();

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Modal
      variant="slideUp"
      isOpen={open}
      onClose={onClose}
      shouldHideGradientStroke={true}
      styles={{ width: '600px !important', borderRadius: '8px !important' }}
    >
      <div className={styles.container}>
        <div className={styles.title}>
          Unable to submit due to missing information
        </div>
        <div className={styles.mainContent}>
          You cannot submit the user’s timesheet because required information is
          missing. Each row much be filled out completely to submit the
          timesheet.
        </div>
      </div>

      <div className={styles.buttonsContainer}>
        <Button variant="secondary" size="md" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="main" size="md" onClick={handleSubmit}>
          Update timesheet
        </Button>
      </div>
    </Modal>
  );
};

export default TimesheetMissingDataModal;
