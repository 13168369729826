import React from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Icon, IconType } from '@ateams/components';
import { withHttps } from '@src/helpers/urls';

interface Props {
  shareLink: string;
}

const useStyles = createUseStyles({
  linkButton: {
    width: '100%',
    fontSize: '16px',
    height: '100%',
    maxHeight: '80px',
  },
});

const CopyLink: React.FC<Props> = ({ shareLink }) => {
  const styles = useStyles();
  const [isCopied, setCopied] = React.useState(false);

  const handleCopy = (): void => {
    navigator.clipboard.writeText(withHttps(shareLink));
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Button
      color="regularLight"
      className={styles.linkButton}
      onClick={handleCopy}
    >
      {!isCopied ? (
        <>
          <Icon type={IconType.Copy} /> {shareLink}
        </>
      ) : (
        <>
          <Icon type={IconType.Check} /> Copied!
        </>
      )}
    </Button>
  );
};

export default CopyLink;
