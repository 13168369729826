import { computed } from 'mobx';
import { Stores } from '@src/stores/index';
import { ProfileEditLocation } from '@src/locations';
import { InvitedUserObject } from '@a_team/models/dist/UserObject';

export type OnboardingItem = {
  label: string;
  done: boolean;
  linkTo?: string;
  action?: string;
};

export default class StatisticsStore {
  private rootStore: Stores;

  public constructor(rootStore: Stores) {
    this.rootStore = rootStore;
  }

  @computed get onboardingItems(): {
    title: string;
    completionRate: number;
    items: OnboardingItem[];
  }[] {
    return [
      {
        title: 'Let’s complete your profile!',
        items: this.profileItems,
        completionRate: this.profileCompletionRate,
      },
    ];
  }

  @computed get profileItems(): OnboardingItem[] {
    return [
      {
        label: 'Upload your profile photo',
        done: this.hasProfileImage,
        linkTo: this.rootStore.auth.user
          ? ProfileEditLocation(this.rootStore.auth.user?.username)
          : undefined,
      },
      {
        label: 'Fill out your about text',
        done: this.hasAbout,
        linkTo: this.rootStore.auth.user
          ? ProfileEditLocation(this.rootStore.auth.user?.username)
          : undefined,
      },
      {
        label: 'Add at least 3 projects',
        done: this.hasProjects,
        linkTo: this.rootStore.auth.user
          ? ProfileEditLocation(this.rootStore.auth.user?.username)
          : undefined,
      },
      {
        label: 'Add 3 jobs',
        done: this.hasJobs,
        linkTo: this.rootStore.auth.user
          ? ProfileEditLocation(this.rootStore.auth.user?.username)
          : undefined,
      },
      {
        label: 'Add your years of experience',
        done: this.hasYearsExperience,
        linkTo: this.rootStore.auth.user
          ? ProfileEditLocation(this.rootStore.auth.user?.username)
          : undefined,
      },
    ];
  }

  @computed get invitedUsers(): InvitedUserObject[] {
    return this.rootStore.auth.user?.invitedUsers ?? [];
  }

  @computed get inviteItems(): OnboardingItem[] {
    return [];
  }

  @computed get hasProjects(): boolean {
    return (
      !!this.rootStore.auth.user &&
      this.rootStore.auth.user?.projects.length >= 3
    );
  }

  @computed get hasJobs(): boolean {
    return (
      !!this.rootStore.auth.user && this.rootStore.auth.user?.jobs.length >= 3
    );
  }

  @computed get hasYearsExperience(): boolean {
    return (
      !!this.rootStore.auth.user &&
      !!this.rootStore.auth.user?.yearsOfExperience
    );
  }

  @computed get hasProfileImage(): boolean {
    return (
      !!this.rootStore.auth.user?.profilePictureURL &&
      !this.rootStore.auth.user?.profilePictureURL.includes('profilepic')
    );
  }

  @computed get hasAbout(): boolean {
    return !!this.rootStore.auth.user?.aboutMe;
  }

  @computed get profileItemsDone(): number {
    return this.profileItems.filter((item) => item.done).length;
  }

  @computed get profileCompletionRate(): number {
    return Math.floor((this.profileItemsDone * 100) / this.profileItems.length);
  }

  @computed get inviteItemsDone(): number {
    return this.inviteItems.filter((item) => item.done).length;
  }

  @computed get overallItemsDone(): number {
    return this.profileItemsDone + this.inviteItemsDone;
  }

  @computed get overallCompletionRate(): number {
    const totalItems = this.profileItems.length + this.inviteItems.length;
    return Math.floor((this.overallItemsDone * 100) / totalItems);
  }

  public serialize(): void {
    return;
  }
}
