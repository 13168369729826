import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import RegistrationStep from '@src/views/Registration/RegistrationStep';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import TextButton from '@src/components/TextButton';
import { Colors } from '@ateams/components';
import { validate as isEmailValid } from 'email-validator';
import { LocalUserReferral } from '@src/stores/Registration/models';
import { createUseStyles } from 'react-jss';
import { ReferralUserForm } from '@src/views/Registration/Steps/referrals/ReferralUserForm';

export type EmailValidation = Record<
  LocalUserReferral['_id'],
  boolean | undefined
>;

const useStyles = createUseStyles({
  button: {
    display: 'block',
    textAlign: 'center',
    padding: '16px 20px',
    marginTop: 20,
    background: Colors.backgroundMedium,
    borderRadius: 12,
    width: '100%',
  },
});

const Referrals = (): ReactElement => {
  const styles = useStyles();
  const {
    registration: {
      referrals,
      addNewEmptyReferral,
      setReferral,
      removeReferral,
      currentStep,
      MAX_REFERRALS,
    },
  } = useStores();

  const [loading, setLoading] = useLoadingState();
  const [emailValidations, setEmailValidations] = useState<EmailValidation>({});

  useEffect(() => {
    const validations: EmailValidation = {};
    referrals.forEach((referral) => (validations[referral._id] = undefined));
  }, []);

  const hasEmailErrors = useMemo(() => {
    return Object.values(emailValidations).some((value) => value === false);
  }, [emailValidations]);

  const onReferralChange = (referral: LocalUserReferral) => {
    validateEmail(referral);
    setReferral(referral);
  };

  const validateEmail = (referral: LocalUserReferral) => {
    setEmailValidations({
      ...emailValidations,
      [referral._id]: referral.email ? isEmailValid(referral.email) : undefined,
    });
  };

  const onSubmit = () => {
    currentStep.onSubmit && setLoading(currentStep.onSubmit());
  };

  return (
    <RegistrationStep
      title={
        'If You Could Work with the Dream Team, Which of Your Friends Would be on It? '
      }
      description={
        "We believe in teams. And one of the things that makes a team great is people who've worked together before. So if you've got a friend you like building with, bring them along."
      }
      buttonLabel={referrals.length > 0 ? 'Next' : 'Skip'}
      onSubmit={onSubmit}
      canSubmit={loading !== true && currentStep.canSubmit && !hasEmailErrors}
      loading={loading}
    >
      {referrals?.map((referral) => {
        return (
          <ReferralUserForm
            key={referral._id}
            onChange={onReferralChange}
            onRemove={removeReferral}
            referral={referral}
            isEmailValid={emailValidations[referral._id]}
          />
        );
      })}
      <TextButton
        className={styles.button}
        highlight
        onClick={addNewEmptyReferral}
        disabled={referrals && referrals?.length >= MAX_REFERRALS}
      >
        + Recommend {referrals.length > 0 ? 'Another' : 'A'} Friend
      </TextButton>
    </RegistrationStep>
  );
};

export default observer(Referrals);
