import { Stores } from '../stores';
import { match } from 'react-router-dom';

export interface ProfileMatchParams {
  username: string;
}

export type ProfileMatch = match<ProfileMatchParams>;

// exported functions

export function loadProfileMatch(
  stores: Stores,
  match: ProfileMatch,
): Promise<unknown> {
  const { username } = match.params;
  const { users } = stores;

  return users.fetchCurrentProfile(username);
}
