import { ServiceAuth, ServiceEndpoint } from './utils';

export const BasePath = '/payments';

export interface PaymentsSetupDetails {
  isPayable: boolean | null;
  payableReason?: string;
  setupIFrameURL: string;
  status?: 'Active' | 'Closed' | 'Suspended' | 'Blocked';
}

// exported functions

export default class PaymentsEndpoint extends ServiceEndpoint {
  public getSetupDetails(auth: ServiceAuth): Promise<PaymentsSetupDetails> {
    return this.fetch(auth, BasePath + `/setup`);
  }
}
