import React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { Spacing } from '@ateams/components';
import { ErrorText, InputLabel, InputLabelProps, Size } from './typography';

export interface InputContainerProps {
  className?: string;
  label?: string;
  inputLabelProps?: InputLabelProps;
  labelSize?: Size;
  error?: string;
}

const useInputContainerStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacing: {
    height: Spacing.small,
  },
});

export const InputContainer: React.FC<InputContainerProps> = (props) => {
  const styles = useInputContainerStyles();

  return (
    <div className={cx(styles.container, props.className)}>
      {props.label && (
        <InputLabel {...props.inputLabelProps} size={props.labelSize}>
          {props.label}
        </InputLabel>
      )}
      {props.label && <div className={styles.spacing} />}
      {props.children}
      {typeof props.error === 'string' && <div className={styles.spacing} />}
      {typeof props.error === 'string' && <ErrorText>{props.error}</ErrorText>}
    </div>
  );
};
