import { Button, Icon } from '@a_team/ui-components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { ConnectCalendarButton } from '../ConnectCalendarButton';
import { Breakpoints } from '@ateams/components';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
  tableWrapper: {
    width: '90vw',
    overflowX: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
  comparisonTable: {
    display: 'grid',
    gridTemplateColumns:
      'minmax(300px, 2fr) minmax(220px, 1fr) minmax(220px, 1fr)',
    gridTemplateRows: 'auto repeat(4, minmax(100px, auto)) auto',
    gridTemplateAreas: `
      "empty header1 header2"
      "feature1 check1 check2"
      "feature2 check3 check4"
      "feature3 check5 check6"
      "feature4 check7 check8"
      "empty2 buttons1 buttons2"
    `,
    gap: '16px',
    width: '100%',
    minWidth: '768px',
  },
  headerCell: {
    backgroundColor: '#F9FAFB',
    padding: '16px',
    borderRadius: '8px',
    '& h3': {
      margin: '0 0 4px 0',
      fontSize: '16px',
      fontWeight: 500,
    },
    '& p': {
      margin: 0,
      color: '#6B7280',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  featureCell: {
    '& h3': {
      margin: '0 0 4px 0',
      fontSize: '16px',
      fontWeight: 500,
    },
    '& p': {
      margin: 0,
      color: '#6B7280',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  availabilityBtn: {
    width: '100% !important',
    height: '40px !important',
  },
  btnLink: {
    color: '#000000',
    textAlign: 'center',
    textDecoration: 'none',
    width: '100%',
    height: '40px',
    backgroundColor: '#EFEFF0',
    borderRadius: '8px',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#F3F4F6',
    },
  },

  checkCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F6FCF3',
    borderRadius: '8px',
    padding: '16px',
    '&.negative': {
      backgroundColor: '#FFF6F8',
    },
  },

  connectCalendarBtn: {
    width: '100%',
    '& button': {
      width: '100%',
    },
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    padding: '15px 5px 125px 6px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    tableWrapper: {
      width: '100%',
      maxWidth: '800px',
    },
  },
});

const features = [
  {
    title: "Book interviews for missions you've applied to",
    description:
      'Seamlessly book interviews through A.Team scheduling and video conferencing service',
    connected: true,
    manual: true,
  },
  {
    title: "Choose when you're available for interviews",
    description:
      'Select the days and times of the week clients are able to schedule interviews with you',
    connected: true,
    manual: true,
  },
  {
    title: 'Get invited to unpublished missions',
    description: (
      <>
        Clients can invite you to interviews directly through search and
        discovery.{' '}
        <a
          href="https://guide.a.team/getting-started/getting-discovered-selected-for-interviews"
          style={{ color: '#6D00D7' }}
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
      </>
    ),
    connected: true,
    manual: false,
  },
  {
    title: 'Automatic availability updates',
    description:
      'Ensure your availability is always current with real-time updates',
    connected: true,
    manual: false,
  },
];

interface ReasonsToConnectTableProps {
  onContinue: () => void;
}

export const ReasonsToConnectTable = ({
  onContinue,
}: ReasonsToConnectTableProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.tableWrapper}>
        <div className={styles.comparisonTable}>
          <div style={{ gridArea: 'empty' }}></div>

          <div className={styles.headerCell} style={{ gridArea: 'header1' }}>
            <h3>Connected calendar</h3>
            <p>
              Sync your calendar to avoid conflicts and let clients book
              interviews based on your availability.
            </p>
          </div>

          <div className={styles.headerCell} style={{ gridArea: 'header2' }}>
            <h3>Manual availability</h3>
            <p>
              Choose your availability for interviews and update manually to
              prevent conflicts.
            </p>
          </div>

          {features.map((feature, index) => (
            <React.Fragment key={index}>
              <div
                className={styles.featureCell}
                style={{ gridArea: `feature${index + 1}` }}
              >
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>

              <div
                className={styles.checkCell}
                style={{ gridArea: `check${index * 2 + 1}` }}
              >
                {feature.connected && (
                  <Icon name="statusPositiveNoBorder" color="Green@700" />
                )}
              </div>

              <div
                className={`${styles.checkCell} ${
                  !feature.manual ? 'negative' : ''
                }`}
                style={{ gridArea: `check${index * 2 + 2}` }}
              >
                {feature.manual ? (
                  <Icon name="statusPositiveNoBorder" color="Green@700" />
                ) : (
                  <Icon name="statusNegativeNoBorder" color="Red@600" />
                )}
              </div>
            </React.Fragment>
          ))}

          <div style={{ gridArea: 'empty2' }}></div>

          <div
            className={styles.buttonContainer}
            style={{ gridArea: 'buttons1' }}
          >
            <ConnectCalendarButton
              className={styles.connectCalendarBtn}
              connectCopy="Connect calendar"
            />
            <a
              className={styles.btnLink}
              href="https://guide.a.team/missions/interview-scheduling"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </div>

          <div
            className={styles.buttonContainer}
            style={{ gridArea: 'buttons2' }}
          >
            <Button className={styles.availabilityBtn} onClick={onContinue}>
              Set availability
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
