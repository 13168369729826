import React, { ReactElement, ReactNode } from 'react';
import { Card } from '@ateams/components';
import { Tag } from '@ateams/components';
import { Colors, Breakpoints } from '@ateams/components';
import ProgressCircle from '@src/components/ProgressCircle';
import Check from './Check.svg';
import CheckGreen from './CheckGreen.svg';
import Flag from './Flag.svg';
import { createUseStyles } from 'react-jss';
import { CarouselState, useCarouselState } from '../ItemsCarousel';

interface Props {
  status?: string;
  label?: ReactNode;
  children?: ReactNode;
  onClick?(state: CarouselState | null): void;
  selected?: boolean;
  progress?: number;
  warning?: boolean;
  success?: boolean;
}

const useStyles = createUseStyles({
  container: (props: Props) => ({
    maxWidth: 338,
    padding: 16,
    paddingLeft: 62,
    position: 'relative',
    flex: '1 0 270px',
    marginBottom: 0,
    transition: 'all 0.2s',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: 'rgba(255,250,247,0.8)',
    },
    ...(props.onClick && {
      cursor: 'pointer',
    }),
  }),
  icon: {
    position: 'absolute',
    left: 16,
    top: 16,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  summary: {
    fontSize: 20,
    fontWeight: 600,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      flex: '1 0 380px !important',
    },
  },
});

export const CycleCard = (props: Props): ReactElement | null => {
  const styles = useStyles(props);
  const getCarouselState = useCarouselState();

  const handleClick = () => {
    if (!props.onClick) return;

    props.onClick(getCarouselState());
  };

  return (
    <Card
      data-testing-id="cycle-card"
      className={styles.container}
      style={{
        ...(props.selected && {
          border: '1px solid #C0C0C0',
          boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.06)',
        }),
        ...(props.success && {
          background: Colors.success,
          color: '#fff',
          ...(props.selected && {
            border: `1px solid ${Colors.successDark}`,
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.4)',
          }),
        }),
      }}
      onClick={handleClick}
    >
      <div className={styles.icon}>
        {props.success ? (
          <img alt={props.status} src={props.success ? Check : CheckGreen} />
        ) : props.warning ? (
          <img alt={props.status} src={Flag} />
        ) : props.progress != null ? (
          <ProgressCircle
            progress={props.progress}
            radius={18}
            fillColor={props.success ? '#fff' : Colors.success}
            barColor={props.success ? Colors.successDark : '#EFEFF0'}
            strokeSize={2}
          />
        ) : (
          <img alt={props.status} src={CheckGreen} />
        )}
      </div>
      {!!(props.status || props.label) && (
        <div className={styles.header}>
          {!!props.status && (
            <Tag
              style={{
                ...(props.warning && {
                  color: '#fff',
                  backgroundColor: Colors.warning,
                }),
              }}
            >
              {props.status}
            </Tag>
          )}

          {!!props.label && (
            <div
              style={{
                color: props.success ? '#E6FFC5' : '#62646A',
              }}
            >
              {props.label}
            </div>
          )}
        </div>
      )}
      {props.children && <div className={styles.summary}>{props.children}</div>}
    </Card>
  );
};
