import React, { useEffect, useMemo, useState } from 'react';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import { MinimalAccountObject } from '@a_team/models/dist/Account';
import { apiAccounts } from '@src/logic/services/endpoints';
import {
  MinimalMissionObject,
  MissionId,
} from '@a_team/models/dist/MissionObject';
import useLoadingState from '@src/hooks/useLoadingState';
import { createUseStyles } from 'react-jss';

interface Props {
  account?: MinimalAccountObject;
  selected?: MissionId[];
  onSelect?(selected: MissionId[]): void;
}

const useStyles = createUseStyles({
  select: {
    border: '1px solid #bbb',
    borderRadius: 4,
    width: '100%',
    padding: 8,
  },
});

const AccountMissionsSelector = observer(
  ({ account, selected, onSelect }: Props) => {
    const { auth } = useStores();
    const [results, setResults] = useState<MinimalMissionObject[]>([]);
    const [loading, setLoading] = useLoadingState();
    const selectedIds = useMemo(() => new Set(selected), [selected]);
    const styles = useStyles();

    useEffect(() => {
      setResults([]);
      if (!account) return;

      setLoading(
        apiAccounts
          .adminGetMinimalMissions(auth, account.id)
          .then((res) => setResults(res.items)),
      );
    }, [account]);

    return (
      <>
        <select
          className={styles.select}
          multiple
          disabled={loading === true || !account || !onSelect}
          onChange={(e) =>
            onSelect?.(
              Array.from(
                e.target.selectedOptions,
                (option) => option.value as MissionId,
              ),
            )
          }
        >
          {!results.length && (
            <option disabled>
              {loading === true
                ? 'Loading...'
                : !loading
                ? 'No results'
                : String(loading)}
            </option>
          )}

          {results.map((mission) => (
            <option
              key={mission.mid}
              value={mission.mid}
              selected={selectedIds.has(mission.mid)}
            >
              {mission.title}
            </option>
          ))}
        </select>
      </>
    );
  },
);

export default AccountMissionsSelector;
