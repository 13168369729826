import {
  BasicConnectionObjectV2,
  ConnectionId,
  ConnectionObjectV2,
  ConnectionType,
  UserConnection,
  SharedExperienceData,
  SharedJobExperience,
  SharedProjectExperience,
} from '@a_team/models/dist/ConnectionObject';
import { UserCardObject, UserId } from '@a_team/models/dist/UserObject';
import { ServiceAuth, ServiceEndpoint } from './utils';
import { QueryNextToken, QueryResult } from '@a_team/models/src/misc';
import {
  BasicExperienceObject,
  JobExperienceData,
  ProjectExperienceData,
} from '@a_team/models/src/ExperienceObject';

export const BasePath = '/connectionsV2';

export interface ConnectionRequest {
  requestedUserId: UserId;
  type: ConnectionType;
  sharedJobsExperiences?: SharedExperienceData[];
  sharedProjectsExperiences?: SharedExperienceData[];
}

export interface ApproveConnectionRequest {
  sharedJobsExperiences?: SharedExperienceData[];
  sharedProjectsExperiences?: SharedExperienceData[];
}

export interface UpdateConnectionRequest {
  requestedUserId: UserId;
  sharedJobsExperiences?: SharedExperienceData[];
  sharedProjectsExperiences?: SharedExperienceData[];
}

export default class ConnectionsEndpointV2 extends ServiceEndpoint {
  public requestConnection(
    auth: ServiceAuth,
    request: ConnectionRequest,
  ): Promise<ConnectionObjectV2> {
    return this.fetch(auth, BasePath, null, 'post', request);
  }

  public approveConnection(
    auth: ServiceAuth,
    cid: ConnectionId,
    request: ApproveConnectionRequest,
  ): Promise<ConnectionObjectV2> {
    return this.fetch(auth, BasePath + `/${cid}/approve`, null, 'put', request);
  }

  public rejectConnectionRequest(
    auth: ServiceAuth,
    cid: ConnectionId,
  ): Promise<ConnectionObjectV2> {
    return this.fetch(auth, BasePath + `/${cid}/reject-request`, null, 'put');
  }

  public deleteConnection(
    auth: ServiceAuth,
    cid: ConnectionId,
  ): Promise<ConnectionObjectV2> {
    return this.fetch(auth, BasePath + `/${cid}/delete`, null, 'put');
  }

  public cancelConnectionRequest(
    auth: ServiceAuth,
    cid: ConnectionId,
  ): Promise<ConnectionObjectV2> {
    return this.fetch(auth, BasePath + `/${cid}/cancel-request`, null, 'put');
  }

  public getUserConnections(
    auth: ServiceAuth,
    uid: UserId,
    next?: QueryNextToken,
  ): Promise<QueryResult<UserConnection>> {
    return this.fetch(auth, BasePath + `/users/${uid}`, { next });
  }

  public getRecentChanges(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<QueryResult<ConnectionObjectV2>> {
    return this.fetch(auth, BasePath + '/recent-changes', { next });
  }

  public getConnectionBetweenUsers(
    auth: ServiceAuth,
    uid: UserId,
  ): Promise<{
    work: BasicConnectionObjectV2 | null;
    teamUp: BasicConnectionObjectV2 | null;
  }> {
    return this.fetch(auth, BasePath + `/users/${uid}/status`, null, 'get');
  }

  public getMutualConnectionsBetweenUsers(
    auth: ServiceAuth,
    uid: UserId,
  ): Promise<{
    mutualConnections: UserCardObject[];
  }> {
    return this.fetch(auth, BasePath + `/users/${uid}/mutual`, null, 'get');
  }

  public getConnectionSharedExperiences(
    auth: ServiceAuth,
    cid: ConnectionId,
  ): Promise<{
    sharedJobsExperiences: SharedJobExperience[];
    sharedProjectsExperiences: SharedProjectExperience[];
  }> {
    return this.fetch(
      auth,
      `${BasePath}/${cid}/sharedExperiences`,
      null,
      'get',
    );
  }

  public getConnectionSharedExperiencesWithDetails(
    auth: ServiceAuth,
    cid: ConnectionId,
  ): Promise<{
    jobs: (BasicExperienceObject & JobExperienceData)[];
    projects: (BasicExperienceObject & ProjectExperienceData)[];
  }> {
    return this.fetch(
      auth,
      `${BasePath}/${cid}/shared-experiences-with-details`,
      null,
      'get',
    );
  }

  public updateConnectionSharedExperiences(
    auth: ServiceAuth,
    cid: ConnectionId,
    request: UpdateConnectionRequest,
  ): Promise<ConnectionObjectV2> {
    return this.fetch(auth, `${BasePath}/${cid}/update`, null, 'put', request);
  }
}
