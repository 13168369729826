import MissionRole from '@a_team/models/dist/MissionRole';
import { DateISOString } from '@a_team/models/dist/misc';
import { useQueryGetMissionPaymentCycle } from '@src/rq/timesheets';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { RoleRecord } from '../TimesheetsTable/MobileTableRow';
import TableSkeleton from './TableSkeleton';
import TanstackTable from './TanstackTable';
import TanstackTableReadOnly from './TanstackTableReadOnly';
import { Link, useLocation } from 'react-router-dom';
import { GroupedRecords } from '@src/stores/Missions/Mission';
import { generateUniqueKey } from './utils';
import { MissionAdminTimeTrackingLocation } from '@src/locations';
import { MissionPaymentCycleId } from '@a_team/models/dist/MissionPaymentCycleObject';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  noDataMessage: {
    marginTop: 40,
  },
});

export const addPlaceholderRecords = (
  role: MissionRole | undefined,
  startDate: DateISOString | undefined,
  endDate: DateISOString | undefined,
  records: RoleRecord[],
) => {
  if (!role || !startDate || !endDate) {
    return records;
  }

  const newRecords = [...records];

  const start = new Date(startDate);
  const end = new Date(endDate);
  const dateSet = new Set(newRecords.map((record) => record.date));

  for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    const dateStr = d.toISOString();
    if (!dateSet.has(dateStr)) {
      newRecords.push({
        key: generateUniqueKey(),
        date: dateStr,
        minutes: 0,
        details: '',
        role: role,
      });
    }
  }

  newRecords.sort((a, b) => (b.date >= a.date ? 1 : -1));

  return newRecords;
};

interface TableProps {
  onPaymentNotificationClick: () => void;
  adminView: boolean;
  updateSelectedCycle?: (id: MissionPaymentCycleId) => void;
  submitAfterLoading?: boolean;
  setPaymentCycleToSubmit?: (id?: MissionPaymentCycleId) => void;
}

const Table = ({
  onPaymentNotificationClick,
  adminView,
  updateSelectedCycle,
  submitAfterLoading,
  setPaymentCycleToSubmit,
}: TableProps) => {
  const styles = useStyles();
  const {
    missions: { currentMission },
    auth,
  } = useStores();
  const location = useLocation();
  const isTeamTimeTracking = location.pathname.includes('team-time-tracking');

  const missionId = currentMission?.mid || '';
  const disabled = !!currentMission?.selectedPaymentCycle?.submitted;
  const paymentCycleId =
    currentMission?.selectedPaymentCycle?.yid ??
    currentMission?.data.paymentCycles?.current?.yid ??
    '';

  const { isLoading, isFetching, error, data } = useQueryGetMissionPaymentCycle(
    {
      mid: missionId,
      yid: paymentCycleId,
    },
  );

  const roleTimesheets = useMemo(() => {
    return (
      data?.timesheets?.filter((roleTimesheet) =>
        currentMission?.data.roles?.some(
          (role) =>
            role.rid === roleTimesheet.rid &&
            role.user?.username === auth.username,
        ),
      ) ?? []
    );
  }, [data?.timesheets]);

  const records = useMemo(() => {
    if (!data?.endDate) {
      return [];
    }

    const recordsFromDB =
      currentMission
        ?.groupUserRecords(
          roleTimesheets.flatMap((t) => t.records),
          auth.user?.username || '',
        )
        ?.flatMap((r) => r.records) ?? [];

    return recordsFromDB;
  }, [data, currentMission?.currentUserRole, addPlaceholderRecords]);

  const allRoleTimesheets = useMemo(() => {
    return (
      data?.timesheets?.filter((roleTimesheet) =>
        currentMission?.data.roles?.some(
          (role) => role.rid === roleTimesheet.rid && role.user?.username,
        ),
      ) ?? []
    );
  }, [data?.timesheets]);

  const teamRecords = useMemo(() => {
    if (!data?.endDate) {
      return [];
    }

    const today = new Date();
    const endDate = new Date(data.endDate) < today ? data.endDate : today;

    const recordsFromDB: GroupedRecords[] = [];

    if (currentMission && allRoleTimesheets) {
      allRoleTimesheets.forEach((t) => {
        const groupedRecords = currentMission.groupAllUserRecords({
          userRecords: t.records,
          roleId: t.rid,
        });
        recordsFromDB.push(...groupedRecords);
      });
    }

    const flatRecordsFromDb = recordsFromDB?.flatMap((r) => r.records) ?? [];

    return addPlaceholderRecords(
      currentMission?.currentUserRole,
      data.startDate,
      endDate,
      flatRecordsFromDb,
    );
  }, [data, currentMission?.currentUserRole, addPlaceholderRecords]);

  const sid = currentMission?.currentUserTimesheet?.sid;

  if (isLoading) return <TableSkeleton />;

  if (error)
    return (
      <div>
        Sorry there was an error{' '}
        {auth.isAdmin && currentMission?.mid && (
          <span>
            since you are an admin you might be looking for the timesheet's
            admin view{' '}
            <Link to={MissionAdminTimeTrackingLocation(currentMission?.mid)}>
              here
            </Link>
          </span>
        )}
      </div>
    );

  if (!data || !sid) return <div className={styles.noDataMessage}>No data</div>;

  if (isTeamTimeTracking) {
    return (
      <TanstackTableReadOnly
        sid={sid}
        currentMission={currentMission}
        records={teamRecords}
        teamTable
      />
    );
  }

  if (disabled) {
    return (
      <TanstackTableReadOnly
        sid={sid}
        currentMission={currentMission}
        records={records}
      />
    );
  }

  return (
    <TanstackTable
      submitAfterLoading={submitAfterLoading}
      setPaymentCycleToSubmit={setPaymentCycleToSubmit}
      onPaymentNotificationClick={onPaymentNotificationClick}
      sid={sid}
      adminView={adminView}
      currentMission={currentMission}
      records={records}
      updateSelectedCycle={updateSelectedCycle}
      isLoading={isLoading || isFetching}
    />
  );
};

export default observer(Table);
