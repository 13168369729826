import {
  ExperienceData,
  ExperienceId,
  JobExperienceData,
  MetricData,
  ProjectExperienceData,
} from './ExperienceObject';
import { DateISOString } from './misc';
import { TalentSkillId, TalentSpecializationId } from './TalentCategories';
import { BasicUserObject, UserCardObject } from './UserObject';

export type ConnectionId = string;

export enum ConnectionType {
  Social = 'Social',
  Work = 'Work',
  TeamUp = 'TeamUp',
}

export enum ConnectionTypeLabel {
  Work = 'Work',
  TeamUp = 'Team Up',
}

export interface UserConnection {
  user: UserCardObject;
  connectionTypes: ConnectionType[];
}

export enum ConnectionStatus {
  Pending = 'Pending',
  Active = 'Active',
  Rejected = 'Rejected',
  Deleted = 'Deleted',
}

export interface BasicConnectionObject {
  cid: ConnectionId;
  types: ConnectionType[];
  status: ConnectionStatus;
  canApprove?: boolean;
}

export interface BasicConnectionObjectV2 {
  cid: ConnectionId;
  type: ConnectionType;
  status: ConnectionStatus;
  canApprove?: boolean;
  initiatorUserId?: string;
  requestedUserId?: string;
}

export interface ConnectionObject extends BasicConnectionObject {
  toUser: BasicUserObject;
  updatedAt: DateISOString;
}

export interface ConnectionObjectV2 extends BasicConnectionObjectV2 {
  initiator: BasicUserObject;
  requested: BasicUserObject;
  createdAt: Date;
  updatedAt: Date;
  requestedAt: Date;
  approvedAt?: Date;
}

export interface SharedExperience {
  eid?: ExperienceId;
  talentSkillIds?: TalentSkillId[];
  talentSpecializationId?: TalentSpecializationId;
  startDate?: DateISOString;
  endDate?: DateISOString;
  description?: string;
  jobRole?: string;
  jobRoleId?: string;
  skills?: string[];
  metrics?: MetricData[];
  hasZeroToOneExperience?: boolean;
  hasManagedPeople?: boolean;
  numberOfPeopleManaged?: string;
}

export type SharedExperienceData = {
  eid?: ExperienceId;
} & Partial<ExperienceData>;

export type SharedJobExperience = SharedExperience & Partial<JobExperienceData>;

export type SharedProjectExperience = SharedExperience &
  Partial<ProjectExperienceData>;
