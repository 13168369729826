import React, { ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import HeroSection from './Section';
import HeroAddressSection from './AddressSection';

interface Props {
  children: ReactNode;
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    marginBottom: '30pt',
  },
});

export default function HeroSections(props: Props): ReactElement {
  const styles = useStyles();

  return <div className={styles.root}>{props.children}</div>;
}

export { HeroSection, HeroAddressSection };
