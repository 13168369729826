import { Breakpoints } from '@ateams/components';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import stars from './stars.svg';
import close from './close.svg';

export enum NotificationBannerV2Theme {
  Success = 'Success',
  Warning = 'Warning',
}

export interface NotificationBannerV2Props {
  profilePictureURLs: string[];
  title: string;
  description: string;
  ctaLabel?: string;
  ctaLink?: string;
  showUsersCounter?: boolean;
  showCloseButton?: boolean;
  highlightUser?: boolean;
  level?: NotificationBannerV2Theme;
}

const getBackground = (level: NotificationBannerV2Theme) => {
  if (level === NotificationBannerV2Theme.Warning) {
    return 'linear-gradient(270deg, #000 0%, #4C0145 46.87%)';
  }

  return 'linear-gradient(270deg, #000 0%, #26014C 46.87%)';
};

const useStyles = createUseStyles({
  parent: {
    position: 'sticky',
    bottom: 16,
    zIndex: 999,
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    borderRadius: 16,
    background: ({ level }) => getBackground(level),
    padding: '16px 24px',
    maxWidth: 800,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    bottom: 16,
    zIndex: 999,
    width: '100%',
  },
  imagesWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundImage: ({ highlightUser }) =>
      highlightUser ? `url("${stars}")` : '',
    width: ({ highlightUser }) => (highlightUser ? '57px' : 'auto'),
  },
  images: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: ({ highlightUser }) => (highlightUser ? `18px` : '0'),
  },
  image: {
    borderRadius: 32,
    border: '2px solid #27024D',
    marginRight: -8,
    background: '#FFFFFF',
  },
  title: {
    color: '#FFFFFF',
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '24px',
  },
  description: {
    color: '#D3D6DC',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: 4,
  },
  tag: {
    position: 'absolute',
    color: '#FFFFFF',
    top: 4,
    right: -16,
    background: '#6D00D7',
    height: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    fontSize: 10,
    padding: '2px 4px',
    minWidth: 18,
  },
  link: {
    color: '#C385FF',
  },
  closeButton: {
    position: 'absolute',
    cursor: 'pointer',
    right: 16,
    top: 28,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      flexDirection: 'row',
    },
  },
});

const TOTAL_IMAGES_TO_DISPLAY = 5;

const NotificationBannerV2: React.FC<NotificationBannerV2Props> = ({
  profilePictureURLs,
  title,
  description,
  ctaLabel,
  ctaLink,
  showUsersCounter,
  showCloseButton,
  highlightUser,
  level = NotificationBannerV2Theme.Success,
}) => {
  const styles = useStyles({ highlightUser, level });
  const [isClosed, setIsClosed] = useState(false);

  const profilePictureURLsToDisplay = profilePictureURLs.slice(
    0,
    TOTAL_IMAGES_TO_DISPLAY,
  );

  if (isClosed || profilePictureURLsToDisplay.length === 0) {
    return null;
  }

  return (
    <div className={styles.parent}>
      <div data-testing-id="notifications-banner" className={styles.container}>
        <div className={styles.imagesWrapper}>
          <span className={styles.images}>
            {profilePictureURLsToDisplay.map((url) => (
              <img
                key={url}
                className={styles.image}
                src={url}
                alt="User profile"
                width={32}
                height={32}
              />
            ))}
            {showUsersCounter && (
              <span className={styles.tag}>{profilePictureURLs.length}</span>
            )}
          </span>
        </div>
        <div>
          <div className={styles.title}>
            {title}{' '}
            {ctaLabel && ctaLink && (
              <Link className={styles.link} to={ctaLink}>
                {ctaLabel}
              </Link>
            )}
          </div>
          <div className={styles.description}>{description}</div>
        </div>
        {showCloseButton && (
          <img
            src={close}
            className={styles.closeButton}
            width="24"
            height="24"
            alt="Close"
            onClick={() => setIsClosed(true)}
          />
        )}
      </div>
    </div>
  );
};

export default NotificationBannerV2;
