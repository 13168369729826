import React, { ReactElement, useState } from 'react';
import { Icon as UiIcon, theme, Wysiwyg } from '@a_team/ui-components';
import cx from 'classnames';
import { getHTMLStringTextLength } from '@src/helpers/richTextEditor';
import { BLURB_TEXT_LENGTH_LIMIT } from '@src/views/Mission/Proposal/RoleMembersSelector/Member';
import { createUseStyles } from 'react-jss';
import { SmallButton } from '@src/components/SmallButton';
import { IconType, Spacing } from '@ateams/components';
import { useDebouncedEffect } from '@src/hooks/useDebouncedEffect';

const useStyles = createUseStyles({
  blurbTextArea: {
    marginTop: 6,
    border: '1px solid #C0C0C0',
    borderRadius: 4,
    maxWidth: '100%',
    background: 'white',
    overflow: 'hidden',
    minHeight: 40,
    '&&': { paddingBottom: 0 },
    '& > div': {
      position: 'relative',
    },
    '&& .ProseMirror': {
      width: '100%',
      position: 'absolute',
      boxSizing: 'border-box',
      fontSize: 15,
      overflowY: 'hidden',
      padding: '8px 12px',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      '& p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& > p:first-of-type': {
        marginTop: 0,
      },
    },
  },
  blurbTextAreaCounter: {
    fontSize: '12px',
    textAlign: 'end',
    height: '21px',
    marginTop: '4px',
  },
  blurbTextAreaCounterError: {
    color: theme.colors.Red[600],
  },
  root: {
    '&& .ProseMirror-focused, &&.menu-open .ProseMirror': {
      minHeight: 120,
      whiteSpace: 'unset',
      position: 'relative',
      overflowY: 'scroll',
    },
  },
  label: {
    display: 'flex',
    marginBottom: Spacing.small,
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  labelIcon: {
    marginRight: Spacing.xsmall,
  },
});

export const Blurb = ({
  onChange,
  onClickOnGenerateBlurb,
}: {
  onChange: (value: string) => void;
  onClickOnGenerateBlurb: (callback: (value: string) => void) => void;
}): ReactElement => {
  const styles = useStyles();
  const [blurb, setBlurb] = useState<string>('');

  useDebouncedEffect(
    () => {
      onChange(blurb);
    },
    500,
    [blurb],
  );

  const valueLength = getHTMLStringTextLength(blurb);
  return (
    <div>
      <label htmlFor="blurb" className={styles.label}>
        <div>
          <UiIcon className={styles.labelIcon} size="md" name="pin" />
          Blurb:
        </div>
        <SmallButton
          text="Generate blurb"
          iconType={IconType.CrystalBall}
          onClick={() =>
            onClickOnGenerateBlurb((value) => {
              setBlurb(value);
            })
          }
        />
      </label>
      <Wysiwyg
        className={cx(styles.blurbTextArea, styles.root)}
        placeholder="Short description"
        error={false}
        html={blurb.toString() || ''}
        onChange={setBlurb}
      />
      <div
        className={cx(styles.blurbTextAreaCounter, {
          [styles.blurbTextAreaCounterError]:
            valueLength >= BLURB_TEXT_LENGTH_LIMIT,
        })}
      >
        {`${valueLength} / ${BLURB_TEXT_LENGTH_LIMIT}`}
      </div>
    </div>
  );
};
