import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import type TRichTextEditor from 'react-rte';
import type { EditorValue, ToolbarConfig } from 'react-rte';
import { Spacing } from '@ateams/components';

export interface RichTextEditorProps {
  defaultValue?: string;
  placeholder?: string;
  onChange?: OnEmailBodyChange;
  isReadOnly?: boolean;
}

export type OnEmailBodyChange = (value: string) => void;

const useStyles = createUseStyles({
  emailEditor: {
    fontFamily: 'Inter !important',
    border: 'none !important',
  },
  toolbarClassName: {
    border: 'none !important',
    margin: '0 !important',
    padding: '0 !important',
    paddingBottom: `${Spacing.small}px !important`,
  },
  editorClassName: {
    border: 'none !important',

    '& div': {
      padding: '0 !important',
    },
  },
});

export const RichTextEditor: React.FC<RichTextEditorProps> = (props) => {
  // prettier-ignore
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const RichTextEditor: typeof TRichTextEditor =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('react-rte-17').default;

  const styles = useStyles();
  const [editorValue, setEditorValue] = React.useState<EditorValue>(() =>
    props.defaultValue
      ? RichTextEditor.createValueFromString(props.defaultValue, 'html')
      : RichTextEditor.createEmptyValue(),
  );

  const onEditorValueChange = (value: EditorValue) => {
    setEditorValue(value);
    props.onChange?.(value.toString('html'));
  };

  const toolbarConfig: ToolbarConfig | undefined = useMemo(() => {
    if (props.isReadOnly) {
      return undefined;
    }

    return {
      display: [
        'BLOCK_TYPE_DROPDOWN',
        'INLINE_STYLE_BUTTONS',
        'BLOCK_TYPE_BUTTONS',
        'LINK_BUTTONS',
        'HISTORY_BUTTONS',
      ],
      INLINE_STYLE_BUTTONS: [
        {
          label: 'Bold',
          style: 'BOLD',
        },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' },
        { label: 'Strikethrough', style: 'STRIKETHROUGH' },
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' },
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' },
      ],
    };
  }, [props.isReadOnly]);

  return (
    <RichTextEditor
      value={editorValue}
      onChange={onEditorValueChange}
      placeholder={props.placeholder}
      disabled={props.isReadOnly}
      toolbarConfig={toolbarConfig}
      className={styles.emailEditor}
      toolbarClassName={styles.toolbarClassName}
      editorClassName={styles.editorClassName}
    />
  );
};
