import { MutableRefObject, Ref, useEffect, useRef } from 'react';

// source:
// https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd

export default function useCombinedRefs<T>(
  ...refs: Ref<T | null>[]
): MutableRefObject<T | null> {
  const targetRef = useRef<T>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current || null);
      } else {
        (ref as MutableRefObject<T | null>).current = targetRef.current || null;
      }
    });
  }, [refs]);

  return targetRef;
}
