import React, { ReactElement, ReactNode } from 'react';
import SectionHeading from '@src/components/SectionHeading';
import { Button as CallToActionButton } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { Breakpoints, Colors, fadeInAnimation } from '@ateams/components';
import { Footer } from '@src/views/Registration/Footer';
import { useScreenClass } from 'react-grid-system';
import { LoadingState } from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';

interface Props {
  onSubmit?: () => void;
  title: string;
  description?: string | ReactNode;
  buttonLabel?: string;
  canSubmit?: boolean;
  children?: ReactNode;
  icon?: ReactNode;
  withButton?: boolean;
  withMobileVideo?: boolean;
  loading?: LoadingState;
}

const defaultProps = {
  canSubmit: true,
  withButton: true,
  withMobileVideo: false,
};

const useStyles = createUseStyles({
  step: {
    opacity: 0,
    animation: '$fadeIn 300ms ease-in-out 300ms both',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: '1.2em',
  },
  stepInner: {
    margin: '0 15px',
    height: 'auto',
    overflow: 'auto',
    paddingBottom: 70,
  },
  description: {
    lineHeight: '1.6em',
    marginTop: 4,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '18px 20px',
    boxShadow: '0px -2px 12px rgba(0, 0, 0, 0.08)',
    background: Colors.backgroundWhite,
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: 76,
  },
  button: {
    marginTop: 0,
  },
  title: {
    fontSize: '1.3em',
    lineHeight: '32px',
    marginBottom: 0,
  },
  ...fadeInAnimation,
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    step: {
      display: 'block',
      paddingBottom: 16,
      fontSize: '1em',
      maxHeight: '100%',
    },
    stepInner: {
      margin: 0,
      height: 'auto !important',
      overflow: 'visible',
      padding: 0,
    },
    button: {
      marginTop: 24,
    },
    buttonContainer: {
      background: 'none',
      boxShadow: 'none',
      padding: 0,
      display: 'block',
      height: 'auto',
      position: 'static',
    },
    title: {
      fontSize: '1.3em',
      lineHeight: '1.7em',
    },
    description: {
      marginBottom: 0,
    },
  },
  [`@media (min-width: ${Breakpoints.xl}px)`]: {
    step: {
      display: 'block',
      paddingBottom: 16,
      fontSize: '1.8em',
    },
    title: {
      fontSize: '1.3em',
      lineHeight: '1.8em',
    },
    button: {
      fontSize: '1em',
      height: '2.5em',
      padding: '0.8em 1em',
    },
  },
});

const RegistrationStep = (props: Props): ReactElement => {
  const styles = useStyles();
  const screenClass = useScreenClass();
  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  const {
    title,
    description,
    children,
    buttonLabel,
    onSubmit,
    canSubmit,
    icon,
    withButton,
    loading,
  } = props;

  return (
    <div className={styles.step}>
      <div className={styles.stepInner}>
        {icon && icon}
        <SectionHeading isFirst className={styles.title}>
          {title}
        </SectionHeading>
        {description && <p className={styles.description}>{description}</p>}
        {children && children}
        {isMobile && <Footer />}
      </div>
      {withButton && onSubmit && (
        <div className={styles.buttonContainer}>
          <CallToActionButton
            onClick={onSubmit}
            width={'auto'}
            size={'small'}
            className={styles.button}
            disabled={!canSubmit}
          >
            {buttonLabel || 'Next'}
          </CallToActionButton>
        </div>
      )}
      <LoadingIndicator
        loading={loading || null}
        withPortal={false}
        position={'absolute'}
        style={{ right: isMobile ? 18 : 0, bottom: isMobile ? 95 : 50 }}
      />
    </div>
  );
};

RegistrationStep.defaultProps = defaultProps;
export default RegistrationStep;
