import React, { ReactElement, ReactNode } from 'react';
import notSelected from './notselected.svg';
import selected from './selected.svg';
import { useCommonStyles } from '../../commonStyles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OnReasonClick = (option: any) => void;

interface Props {
  label: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: any;
  checked?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (option: any) => void;
}

export const DefaultOption = (props: Props): ReactElement => {
  const styles = useCommonStyles();
  const { label, option, onClick, checked = false } = props;

  return (
    <div className={styles.optionWrap} onClick={() => onClick(option)}>
      <img src={checked ? selected : notSelected} alt="selected" />
      <div className={styles.optionLabel}>{label}</div>
    </div>
  );
};
