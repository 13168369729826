import React, { ReactElement, ReactNode } from 'react';
import Dropzone, {
  DropzoneRef,
  FileRejection,
  FileWithPath,
} from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

export type File = FileWithPath;

export interface Props {
  children: (props: { openDialog: () => void }) => ReactNode;
  onFileDrop?: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
  onFileDrag?: (inDragArea: boolean) => void;
  maxFileSize?: number;
  minFileSize?: number;
  accept?: string[];
  allowMultiple?: boolean;
  disabled?: boolean;
}

const DEFAULT_ACCEPT_TYPE = ['image/*'];
const DEFAULT_MAX_FILE_SIZE = 10485760; // 10mb

const useStyles = createUseStyles({
  root: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
});

const FileDropArea = (props: Props): ReactElement => {
  const {
    allowMultiple = false,
    accept = DEFAULT_ACCEPT_TYPE,
    maxFileSize = DEFAULT_MAX_FILE_SIZE,
  } = props;
  const styles = useStyles();
  const dropzoneRef = React.createRef<DropzoneRef>();

  const openDialog = (): void => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const handleFileDrop = (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
  ): void => {
    props.onFileDrop && props.onFileDrop(acceptedFiles, rejectedFiles);
  };

  const handleFileDrag = (inDragArea: boolean): void => {
    props.onFileDrag && props.onFileDrag(inDragArea);
  };

  return (
    <Dropzone
      ref={dropzoneRef}
      disabled={props.disabled}
      maxSize={maxFileSize}
      minSize={props.minFileSize}
      accept={accept}
      onDrop={handleFileDrop}
      multiple={allowMultiple}
      onDragEnter={(): void => handleFileDrag(true)}
      onDragLeave={(): void => handleFileDrag(false)}
    >
      {({ getRootProps, getInputProps }): ReactElement => (
        <div
          {...getRootProps()}
          className={cx(styles.root, { [styles.disabled]: props.disabled })}
        >
          <input {...getInputProps()} />
          {props.children({
            ...{ openDialog },
          })}
        </div>
      )}
    </Dropzone>
  );
};

export default FileDropArea;
