import React from 'react';
import { createUseStyles } from 'react-jss';
import { Checkbox } from '@ateams/components';

interface OnlyBuilderCheckboxProps {
  canWeShareFeedbackWithClient?: boolean;
  setCanWeShareFeedbackWithClient: (value: boolean) => void;
  missionTitle?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '4px',
    background: '#F7F7F9',
    padding: '16px 12px',
  },
  checkbox: {
    margin: 0,
  },
});

const OnlyBuilderCheckbox = ({
  canWeShareFeedbackWithClient,
  setCanWeShareFeedbackWithClient,
  missionTitle,
}: OnlyBuilderCheckboxProps): JSX.Element => {
  const styles = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCanWeShareFeedbackWithClient(e.target.checked);
  };

  return (
    <div className={styles.container}>
      <Checkbox
        className={styles.checkbox}
        checked={canWeShareFeedbackWithClient}
        onChange={handleChange}
      />
      <label>Please do not share this rating with the {missionTitle}</label>
    </div>
  );
};

export default OnlyBuilderCheckbox;
