import _ from 'lodash';
import { action, computed, observable } from 'mobx';
import AuthStore from '@src/stores/Auth';
import RoleCategoryObject, {
  RoleCategoryAnchor,
  RoleCategoryId,
} from '@a_team/models/dist/RoleCategory';
import { apiRoleCategories } from '@src/logic/services/endpoints';
import { Stores } from '@src/stores/index';

export interface RoleCategoriesStoreData {
  dict?: { [id: string]: RoleCategoryObject };
  byAnchor?: { [anchor: string]: RoleCategoryId[] };
}

export type RoleCategoriesCatalog = RoleCategoriesCatalogItem[];

export interface RoleCategoriesCatalogItem {
  anchor: RoleCategoryAnchor;
  categories: RoleCategoryObject[];
}

export default class RoleCategoriesStore implements RoleCategoriesStoreData {
  @observable dict: RoleCategoriesStoreData['dict'];
  @observable byAnchor: RoleCategoriesStoreData['byAnchor'];

  public constructor(
    rootStore: Stores,
    initialState?: RoleCategoriesStoreData,
  ) {
    if (initialState) {
      this.dict = initialState.dict;
      this.byAnchor = initialState.byAnchor;
    }
  }

  @computed get categories(): RoleCategoryObject[] | null {
    if (!this.dict) {
      return null;
    }

    return Object.values(this.dict);
  }

  @computed get catalog(): RoleCategoriesCatalog | null {
    // quick fix for TypeScript warnings about `this.dict` maybe
    // an undefined when mapping items
    const { byAnchor, dict } = this;

    if (!byAnchor || !dict) {
      return null;
    }

    return Object.entries(byAnchor).map(
      ([anchor, ids]): RoleCategoriesCatalogItem => ({
        anchor: anchor as RoleCategoryAnchor,
        categories: ids.map((cid) => dict[cid]),
      }),
    );
  }

  @action setAllCategories(categories: RoleCategoryObject[]): void {
    if (this.dict && this.byAnchor) {
      return;
    }
    const dict: RoleCategoriesStoreData['dict'] = {};
    const byAnchor: RoleCategoriesStoreData['byAnchor'] = {};

    categories.forEach((item) => {
      dict[item.cid] = item;

      item.anchors.forEach((anchor) => {
        let list = byAnchor[anchor];
        if (!list) {
          list = byAnchor[anchor] = [];
        }

        list.push(item.cid);
      });
    });

    this.dict = dict;
    this.byAnchor = _(byAnchor).toPairs().sortBy(0).fromPairs().value();
  }

  /**
   * @deprecated use react-query and `setAllCategories` instead
   */
  public async load(auth: AuthStore): Promise<void> {
    if (this.dict && this.byAnchor) {
      return;
    }

    this.setAllCategories(await apiRoleCategories.getAllCategories(auth));
  }

  public serialize(): RoleCategoriesStoreData {
    return {
      dict: this.dict,
      byAnchor: this.byAnchor,
    };
  }
}
