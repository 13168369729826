import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import { VettingProcessId } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { BorderRadius, Colors, Spacing } from '@ateams/components';
import { useStores } from '@src/stores';
import { PostEvaluationSurveyLayout } from '@src/layouts/PostEvaluationSurvey';
import useLoadingState from '@src/hooks/useLoadingState';
import { PostEvaluationSurveySubmittedLocation } from '@src/locations';
import LoadingIndicator from '@src/components/LoadingIndicator';
import {
  OnPostEvaliationSurveySubmit,
  PostEvaluationSurvey,
} from './post-evaluation-survey';

const useStyles = createUseStyles({
  form: {
    width: '900px',
    height: 'fit-content',
    marginTop: '40px',
    paddingTop: Spacing.large,
    paddingBottom: Spacing.large,
    background: Colors.backgroundWhite,
    borderRadius: BorderRadius.medium,
    zIndex: 100,
  },
});

export const PostEvaluationSurveyView: React.FC = () => {
  const styles = useStyles();
  const { vettingProcesses } = useStores();
  const history = useHistory();
  const { vpid } = useParams<{
    vpid: VettingProcessId;
  }>();
  const [
    checkingPostEvaluationFormSubmittedLoading,
    setIsCheckingPostEvaluationFormSubmittedLoading,
  ] = useLoadingState();
  const [submitLoading, setSubmitLoading] = useLoadingState();

  useEffect(() => {
    setIsCheckingPostEvaluationFormSubmittedLoading(
      (async () => {
        const { postEvaluationSurveySubmitted } =
          await vettingProcesses.checkPostEvaluationSurveySubmitted(vpid);

        if (postEvaluationSurveySubmitted) {
          history.push(PostEvaluationSurveySubmittedLocation);
        }
      })(),
      'Survey loaded',
    );
  }, []);

  const onSubmit: OnPostEvaliationSurveySubmit = (payload) => {
    setSubmitLoading(
      (async () => {
        await vettingProcesses.submitPostEvaluationForm({
          ...payload,
          vettingProcessId: vpid,
        });

        history.push(PostEvaluationSurveySubmittedLocation);
      })(),
    );
  };

  const isLoading = checkingPostEvaluationFormSubmittedLoading === true;

  return (
    <PostEvaluationSurveyLayout title={'Post-evaluation'}>
      {!isLoading && (
        <PostEvaluationSurvey onSubmit={onSubmit} className={styles.form} />
      )}

      <LoadingIndicator
        loading={checkingPostEvaluationFormSubmittedLoading || submitLoading}
      />
    </PostEvaluationSurveyLayout>
  );
};

export default observer(PostEvaluationSurveyView);
