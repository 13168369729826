import { Breakpoints, SelectableTagList } from '@ateams/components';
import { TalentSkillId } from '@a_team/models/dist/TalentCategories';
import { Expertise } from '@src/stores/Profile/models';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  onChangeSelected: (skillIds: TalentSkillId[]) => void;
  defaultSkillIds?: TalentSkillId[];
  mainAndAdditionalSkills: Expertise[];
}

const useStyles = createUseStyles({
  skillsSelector: {
    [`@media (max-width: ${Breakpoints.md}px)`]: {
      maxHeight: '7.1rem',
    },
    [`@media (min-width: ${Breakpoints.lg}px)`]: {
      maxHeight: '12rem',
    },
  },
});

export const SelectUserSkillsSubset = ({
  onChangeSelected,
  defaultSkillIds,
  mainAndAdditionalSkills,
}: Props): ReactElement => {
  const styles = useStyles();
  const [selectedNames, setSelectedNames] = useState([] as string[]);
  const onChange = (name: string, selected: boolean) => {
    setSelectedNames((names) =>
      selected ? [...names, name] : names.filter((i) => i !== name),
    );
  };
  const selectedIds = useMemo(
    () =>
      mainAndAdditionalSkills
        .filter((s) => selectedNames.includes(s.name))
        .map((s) => s.id),
    [selectedNames, mainAndAdditionalSkills],
  );

  useEffect(() => onChangeSelected(selectedIds), [selectedIds.length]);

  useEffect(() => {
    if (
      defaultSkillIds &&
      defaultSkillIds.length > 0 &&
      mainAndAdditionalSkills.length > 0
    ) {
      const defaultSkills = mainAndAdditionalSkills
        .filter((s) => defaultSkillIds.includes(s.id))
        .map((s) => s.name);

      setSelectedNames(defaultSkills);
    }
  }, [
    defaultSkillIds?.join(','),
    mainAndAdditionalSkills?.map((s) => s.id).join(','),
  ]);

  return (
    <div className={styles.skillsSelector}>
      <SelectableTagList
        color="backgroundDark"
        selectedColor="success"
        items={mainAndAdditionalSkills.map(({ name }) => name)}
        disabled={false}
        onChange={onChange}
        selectedItems={selectedNames}
      />
    </div>
  );
};
