import React, { ReactElement } from 'react';
import { StaticContext } from 'react-router';
import BasicLayout from '@src/layouts/Basic';
import { createUseStyles } from 'react-jss';
import ateamLogo from './error-logo.svg';
import { Breakpoints } from '@ateams/components';

interface Props {
  staticContext?: StaticContext;
}

const useStyles = createUseStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    padding: '0 20px 40px 20px',
    textAlign: 'center',
    '& h1, h2': {
      margin: 0,
    },
    '& h1': {
      fontSize: 100,
      fontWeight: 'bold',
      lineHeight: '.8em',
    },
    '& h2': {
      fontSize: 20,
      letterSpacing: -0.5,
    },
  },
  ErrorLogo: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 100,
    fontWeight: 'bold',
    position: 'relative',
  },
  logo: {
    width: 150,
    margin: '0 -10px',
    zIndex: 1,
    transform: 'rotate(27deg)',
    transition: 'all .1s linear',
    '&:hover': {
      transform: 'rotate(0)',
    },
  },
  mirror: {
    transform: 'scale(-1, 1)',
  },
  prompt: {
    bottom: 20,
    maxWidth: 280,
    position: 'absolute',
    textAlign: 'center',
  },
  sorry: {
    fontSize: 20,
    fontWeight: 300,
    left: 40,
    position: 'absolute',
    top: 0,
    transform: 'rotate(-20deg)',
    zIndex: 2,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      '& h2': {
        fontSize: 25,
      },
    },
    prompt: {
      maxWidth: 'none',
    },
  },
});

function NotFound(props: Props): ReactElement {
  const styles = useStyles();

  const { staticContext } = props;

  if (staticContext) {
    staticContext.statusCode = 404;
  }

  return (
    <BasicLayout
      title="404 Error | Page Not Found"
      style={{
        background:
          'linear-gradient(45deg, rgba(2,0,36,1) 0%, rgba(107,8,219,1) 0%, rgba(153,68,180,1) 100%, rgba(0,212,255,1) 100%)',
        minHeight: '100vh',
        color: 'white',
        display: 'flex',
      }}
    >
      <div className={styles.container}>
        <div className={styles.ErrorLogo}>
          <div className={styles.sorry}>sorry</div>
          4
          <img src={ateamLogo} alt="A·Team" className={styles.logo} />
          <span className={styles.mirror}>4</span>
        </div>
        <h2>Page Not Found</h2>
        <div className={styles.prompt}>
          <p>
            {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
            You can <a href="javascript:history.go(-1);">go back</a>, return to
            the <a href="/">homepage</a>, or{' '}
            <a href="mailto:support@a.team">contact us</a> if you can't find
            what you're looking for.
          </p>
        </div>
      </div>
    </BasicLayout>
  );
}

export default NotFound;
