import { ServiceAuth, ServiceEndpoint } from './utils';
import { ClientSignalObject } from '@a_team/models/src/ClientSignals';

export const BasePath = '/builder-signals';

export default class BuilderSignalsEndpoint extends ServiceEndpoint {
  public dismissDiscoverySuggestion(
    auth: ServiceAuth,
    builderIds: string[],
  ): Promise<ClientSignalObject[]> {
    return this.fetch(auth, `${BasePath}/dismiss-suggestion`, null, 'post', {
      builderIds,
    });
  }
}
