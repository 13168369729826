import React, { ReactElement } from 'react';
import ProgressCircle from '@src/components/ProgressCircle';
import { createUseStyles } from 'react-jss';
import { UploaderTypes } from '@src/components/FileUploader/index';
import { useScreenClass } from 'react-grid-system';

interface Props {
  progress: number;
  type: UploaderTypes;
  radius?: number;
}

const useStyles = createUseStyles({
  uploading: {
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(247, 247, 247, 0.8)',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 8,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
  },
  description: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
    color: '#62646A',
  },
});

export const UploadingView = (props: Props): ReactElement => {
  const styles = useStyles();
  const screenClass = useScreenClass();

  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  return (
    <div className={styles.uploading}>
      <ProgressCircle progress={props.progress} radius={props.radius} />
      <div>
        <div className={styles.title}>Uploading</div>
        <div className={styles.description}>
          {isMobile ? (
            <>
              <div>Just a few seconds and we’ll have</div>
              <div>your image ready.</div>
            </>
          ) : (
            'Just a few seconds and we’ll have your image ready.'
          )}
        </div>
      </div>
    </div>
  );
};
