import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import {
  VettingProcessAudit,
  VettingProcessAuditTypes,
} from '@a_team/models/dist/vetting-process-audit';
import { VettingProcessEmailTemplates } from '@a_team/models/dist/vetting-processes/emails';
import { ActivityRecordMainText, ActivityRecordUser } from '.';

export interface EvaluationInviteEmailActivityRecordProps {
  vettingProcessAudit: VettingProcessAudit<VettingProcessAuditTypes.EvaluationInviteEmail>;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const EvaluationInviteEmailActivityRecord: React.FC<
  EvaluationInviteEmailActivityRecordProps
> = ({ vettingProcessAudit }) => {
  const styles = useStyles();

  const label = useMemo(() => {
    switch (vettingProcessAudit.payload.templateType) {
      case VettingProcessEmailTemplates.STInitial:
      case VettingProcessEmailTemplates.InternalTechnicalInitial:
      case VettingProcessEmailTemplates.InternalNonTechnicalInitial:
        return 'Reach-out email sent by';

      case VettingProcessEmailTemplates.STFollowUp:
      case VettingProcessEmailTemplates.InternalTechnicalFollowUp:
      case VettingProcessEmailTemplates.InternalNonTechnicalFollowUp:
        return 'First follow-up email sent by';

      case VettingProcessEmailTemplates.STSecondFollowUp:
        return 'Second follow-up email sent by';

      case VettingProcessEmailTemplates.STScheduleCall:
        return 'Schedule call email sent by';

      default:
        console.error(
          'Received unknown template type ',
          vettingProcessAudit.payload.templateType,
          ' 🥲',
        );
        return null;
    }
  }, [vettingProcessAudit]);

  if (!label) {
    return null;
  }

  return (
    <div className={styles.container}>
      <ActivityRecordMainText>{label}</ActivityRecordMainText>

      {vettingProcessAudit.user && (
        <ActivityRecordUser
          user={vettingProcessAudit.user}
          automationReason={vettingProcessAudit.automationReason}
        />
      )}
    </div>
  );
};
