import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { MissionCardObject } from '@a_team/models/dist/MissionObject';
import { Divider } from '@ateams/components';
import { Card } from '@ateams/components';
import {
  ProfileLocation,
  EditMissionLocation,
  MissionPageAdminLocation,
} from '@src/locations';
import { Link } from 'react-router-dom';
import UserAvatar from '@src/components/UserAvatar';
import { Icon, IconType, LoadMoreLink } from '@ateams/components';
import { observer } from 'mobx-react';

interface Props {
  missions: MissionCardObject[];
  type: string;
  clientView?: boolean;
  showMore?: boolean;
  onShowMore?: () => void;
}

const useStyles = createUseStyles({
  missionTitle: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#222222',
  },
  smallDivider: {
    borderRight: '1px solid #C0C0C0',
    height: '16px',
    marginLeft: '12px',
    marginRight: '12px',
  },
  actions: {
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#62646A',
    display: 'flex',
    alignItems: 'center',
  },
  itemsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
  },
  detailsContainer: {
    marginTop: '4px',
    display: 'flex',
    alignItems: 'center',
  },
  missionLink: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#62646A',
    '& i': {
      cursor: 'pointer',
    },
  },
});

function MissionList(props: Props): ReactElement {
  const { missions, clientView, showMore, onShowMore } = props;
  const styles = useStyles();

  return (
    <>
      <Card style={{ padding: 0 }}>
        {missions.map((item, i) => (
          <div key={item.mid}>
            <div className={styles.itemsContainer}>
              <div>
                <div className={styles.missionTitle}>{item.title}</div>
                <div className={styles.detailsContainer}>
                  {!!item?.expectedDurationMonths && (
                    <>
                      <div>
                        Mission Duration: {item.expectedDurationMonths} months
                      </div>
                      <div className={styles.smallDivider}></div>
                    </>
                  )}
                  {item.roles.length > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      Team Members:
                      {item.roles.map(
                        (member) =>
                          member.user && (
                            <Link
                              key={member.rid}
                              to={
                                member.user.username
                                  ? ProfileLocation(member.user.username)
                                  : ''
                              }
                              style={{ height: '24px', marginLeft: '8px' }}
                            >
                              <UserAvatar
                                size={24}
                                src={
                                  member.user.username
                                    ? member.user.profilePictureURL
                                    : 'placeholder'
                                }
                              />
                            </Link>
                          ),
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.actions}>
                {!clientView && (
                  <>
                    <Link
                      to={EditMissionLocation(item.mid)}
                      className={styles.missionLink}
                    >
                      Edit Mission
                    </Link>
                    <div className={styles.smallDivider} />
                  </>
                )}
                <Link
                  to={MissionPageAdminLocation(item.mid)}
                  className={styles.missionLink}
                >
                  Open Mission
                  <Icon
                    style={{ marginLeft: '12px' }}
                    size={'xsmall'}
                    type={IconType.ArrowRight}
                  />
                </Link>
              </div>
            </div>
            {missions.length - 1 !== i && <Divider style={{ margin: 0 }} />}
          </div>
        ))}
      </Card>
      {showMore && onShowMore && (
        <LoadMoreLink onLoadMore={onShowMore} buttonText={'Show more'} />
      )}
    </>
  );
}

export default observer(MissionList);
