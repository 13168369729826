import { Stores } from '../stores';
import { match } from 'react-router-dom';

export interface NotificationPreferencesMatchParams {
  token?: string;
}

// exported functions

export function loadNotificationPreferences(
  stores: Stores,
  match: match<NotificationPreferencesMatchParams>,
): Promise<unknown> {
  const { notificationPreferencesUser } = stores;
  const { token } = match.params;
  return notificationPreferencesUser.loadData(token);
}
