import React, {
  ReactElement,
  useRef,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import CarouselControl from '@src/components/ItemsCarousel/CarouselControl';
import {
  CarouselState,
  CarouselStateProvider,
  useCarouselState,
} from './context';

interface Props {
  className?: string;
  arrowClassName?: string;
  children: ReactNode;
  edgeButtons?: boolean;
  startPosition?: 'start' | 'end';
  state?: CarouselState | null;
  autoHideControls?: boolean;
  onCarouselActive?(active: boolean): void;
}

const defaultProps = {
  startPosition: 'start',
  autoHideControls: true,
};

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    height: '100%',
  },
  carouselContainer: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > *': {
      flexShrink: 0,
    },
  },
});

const ItemsCarousel = (props: Props): ReactElement => {
  const {
    className,
    arrowClassName,
    children,
    edgeButtons,
    startPosition,
    state,
    autoHideControls,
    onCarouselActive,
  } = props;

  const styles = useStyles();

  const containerRef = useRef<HTMLDivElement>(null);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(true);

  useEffect(() => calcScroll(0), []);
  useEffect(() => calcScroll(), [children]);
  useEffect(() => {
    if (state || startPosition === 'end') {
      scrollTo(state?.scrollLeft ?? 'end');
    }
  }, [startPosition, state]);

  useEffect(() => {
    onCarouselActive && onCarouselActive([hasPrev, hasNext].some(Boolean));
  }, [hasPrev, hasNext]);

  const scrollTo = (scrollLeft: number | 'end') => {
    const { current } = containerRef;
    if (!current) return;

    if (scrollLeft === 'end') {
      scrollLeft = current.scrollWidth;
    }

    current.scrollTo({
      top: 0,
      left: scrollLeft,
    });
    calcScroll(scrollLeft);
  };

  const calcScroll = (scrollLeft?: number): void => {
    if (!containerRef.current) {
      return;
    }

    const { offsetWidth, scrollWidth } = containerRef.current;
    if (scrollLeft == null) {
      scrollLeft = containerRef.current.scrollLeft;
    }

    // this function will run many times so we only update the state and rerender if needed

    const newHasPrev = scrollLeft > 0;
    if (newHasPrev !== hasPrev) {
      setHasPrev(newHasPrev);
    }

    const newHasNext = scrollWidth - scrollLeft > offsetWidth;
    if (newHasNext !== hasNext) {
      setHasNext(newHasNext);
    }
  };

  const scrollCarousel = (prev?: boolean): void => {
    const { current } = containerRef;
    if (!current) return;

    const item = current.firstElementChild as HTMLElement | null;
    if (!item) return;

    let itemsCount = current.childElementCount;
    if (itemsCount == null) {
      itemsCount = current.childNodes.length;
    }
    if (itemsCount < 2) return;

    const itemWidth =
      2 * Math.ceil(current.scrollWidth / itemsCount) - item.offsetWidth;

    let left = current.scrollLeft;
    left -= left % itemWidth;

    if (prev) {
      left -= itemWidth;
    } else {
      left += itemWidth;
    }

    current.scrollTo({
      top: 0,
      left,
      behavior: 'smooth',
    });
    calcScroll(left);
  };

  const buttonOffset = edgeButtons ? '-20px' : '20px';

  return (
    <div
      className={styles.root}
      onScroll={() => calcScroll()} // mouse scrolling
      onWheel={() => calcScroll()} // touch scrolling
    >
      <CarouselControl
        className={arrowClassName}
        show={hasPrev || !autoHideControls}
        type="left"
        onClick={(): void => scrollCarousel(true)}
        offset={buttonOffset}
        disable={!hasPrev}
      />

      <CarouselControl
        className={arrowClassName}
        show={hasNext || !autoHideControls}
        type="right"
        onClick={(): void => scrollCarousel()}
        offset={buttonOffset}
        disable={!hasNext}
      />

      <CarouselStateProvider
        value={() =>
          containerRef.current && {
            scrollLeft: containerRef.current.scrollLeft,
          }
        }
      >
        <div
          className={cx(styles.carouselContainer, className)}
          ref={containerRef}
        >
          {children}
        </div>
      </CarouselStateProvider>
    </div>
  );
};

ItemsCarousel.defaultProps = defaultProps;
export default ItemsCarousel;

export { useCarouselState };
export type { CarouselState };
