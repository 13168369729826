import React, { ReactElement } from 'react';
import { InvoiceStatus } from '@a_team/models/dist/InvoiceObject';
import { Colors } from '@ateams/components';
import { DateISOString } from '@a_team/models/dist/misc';
import { format } from 'date-fns';

interface StatusProps {
  status: InvoiceStatus;
  paidAt?: DateISOString;
}

export const InvoiceStatusDisplay = (props: StatusProps): ReactElement => {
  switch (props.status) {
    case InvoiceStatus.Created:
      return <span>Submitted</span>;
    case InvoiceStatus.Canceled:
      return <span>Canceled</span>;
    case InvoiceStatus.Processing:
      return <span style={{ color: Colors.info }}>Processing</span>;
    case InvoiceStatus.Paid:
      return (
        <span style={{ color: Colors.success }}>
          Paid {props.paidAt && format(new Date(props.paidAt), 'PP')}
        </span>
      );
    case InvoiceStatus.Static:
      return <span>N/A</span>;
  }
};
