import {
  CollaboratorRequestNotificationObject,
  NotificationType,
} from '@a_team/models/dist/NotificationObject';
import React, { ReactElement } from 'react';
import Notification from './Notification';
import {
  ExperienceMemberRole,
  ExperienceUserMember,
} from '@a_team/models/dist/ExperienceObject';
import { ExistingProject } from '@src/stores/Profile/models';

interface CollaboratorRequestNotificationProps {
  notification: CollaboratorRequestNotificationObject;
  onMarkAsRead(): void;
  onSeeCollaboratorRequest(project: ExistingProject): void;
  isRead: boolean;
}

export default function CollaboratorRequestNotification({
  onMarkAsRead,
  isRead,
  notification,
  onSeeCollaboratorRequest,
}: CollaboratorRequestNotificationProps): ReactElement | null {
  const { experience } = notification;

  const { logoURL, title, members, companyData } = experience;

  const owner = members.find(
    (member) => member.experienceRole === ExperienceMemberRole.Owner,
  ) as ExperienceUserMember;

  if (!owner) {
    return null;
  }

  const handleSeeCollaboratorRequest = () => {
    onSeeCollaboratorRequest(experience);
    onMarkAsRead();
  };

  const notificationTitle = `${owner.firstName} added you as a collaborator on a project.`;
  const notificationDescription = `${owner.firstName} added you on ‘${title}’ that you both worked on for ${companyData?.name}.`;

  return (
    <>
      <Notification
        createdAt={notification.createdAt}
        notificationType={NotificationType.CollaboratorRequest}
        ctaName="See request"
        ctaAction={handleSeeCollaboratorRequest}
        title={notificationTitle}
        description={notificationDescription}
        imageUrl={logoURL}
        profilePictureURL={owner.profilePictureURL}
        onMarkAsRead={onMarkAsRead}
        isRead={isRead}
      />
    </>
  );
}
