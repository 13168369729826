import React from 'react';
import { Breakpoints, Button, Colors } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import useMediaQuery from '@src/hooks/useMediaQuery';

interface ControlButtonsProps {
  onBack: () => void;
  onSkip?: () => void;
  onContinue: () => void;
  canContinue?: boolean;
}

const useStyles = createUseStyles({
  button: {
    width: 'fit-content',
    height: 40,
    padding: '0 20px',
  },
  secondaryButton: {
    background: Colors.backgroundDark,
    color: 'black',
  },
  container: {
    borderTop: `1px solid #DADADC`,
    padding: 20,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 40,
    gap: 16,
  },
  actionsRightSide: {
    display: 'flex',
    gap: 8,
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    container: {
      padding: 0,
      border: 'none',
      position: 'sticky',
      bottom: 0,
    },
  },
});

const ControlButtons = ({
  onBack,
  onSkip,
  onContinue,
  canContinue = true,
}: ControlButtonsProps): JSX.Element => {
  const styles = useStyles();
  const isDesktop = useMediaQuery(`(min-width: ${Breakpoints.md}px)`);

  return (
    <div className={styles.container}>
      <div>
        <Button
          onClick={onBack}
          className={cx(styles.button, styles.secondaryButton)}
        >
          Back
        </Button>
      </div>
      <div className={styles.actionsRightSide}>
        {onSkip && (
          <Button
            onClick={onSkip}
            className={cx(styles.button, styles.secondaryButton)}
          >
            {isDesktop ? 'Skip Question' : 'Skip'}
          </Button>
        )}

        <Button
          data-testing-id="continue-button"
          disabled={!canContinue}
          onClick={onContinue}
          className={styles.button}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default ControlButtons;
