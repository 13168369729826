import { Expertise } from '@src/stores/Profile/models';
import { AnswerScore } from '../AnswerScore';

export interface Option {
  value: string;
  label: string;
  rating?: AnswerScore;
  original: Expertise;
}

export const formatSkillsForSelect = (skills: Expertise[]): Option[] => {
  return skills.map((skill) => ({
    value: skill.id,
    label: skill.name,
    rating: skill.rating,
    original: skill,
  }));
};
