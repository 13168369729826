import React, { ReactElement } from 'react';
import { AirtableRecord } from '../..';
import UserAvatar from '@src/components/UserAvatar';
import { truncate } from '@src/helpers/strings';
import airtableLogo from '../../airtable.png';

interface Props {
  user: AirtableRecord;
}

const airtableURL = (id: string) =>
  `https://airtable.com/tbl2rBJAHcl9xKEk4/viwC0WXH9aBdi5B3g/${id}?blocks=hide`;

export default function User(props: Props): ReactElement {
  const { user } = props;

  return (
    <div
      style={{
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <UserAvatar
          src={
            user.Picture &&
            user.Picture[0] &&
            user.Picture[0].thumbnails &&
            user.Picture[0].thumbnails.small.url
          }
          alt="profilepic"
          containerStyle={{
            height: '40px',
            width: '40px',
            borderRadius: '40px',
          }}
        />
        {user['1. Name'] && (
          <div style={{ fontWeight: 'bold', marginLeft: '16px' }}>
            {user['1. Name']}
          </div>
        )}
        <div
          style={{
            height: '24px',
            borderRight: '2px solid #C0C0C0',
            marginLeft: '12px',
            marginRight: '12px',
          }}
        ></div>
        {user['13. ACE Score'] && <div>Score: {user['13. ACE Score']}</div>}
        <div
          style={{
            height: '24px',
            borderRight: '2px solid #C0C0C0',
            marginLeft: '12px',
            marginRight: '12px',
          }}
        ></div>
        {user['4. LinkedIn'] && (
          <a href={user['4. LinkedIn']}>
            {truncate(
              user['4. LinkedIn']
                .toLowerCase()
                .replace('https://', '')
                .replace('http://', '')
                .replace('www.', ''),
              24,
            )}
          </a>
        )}
        <div
          style={{
            height: '24px',
            borderRight: '2px solid #C0C0C0',
            marginLeft: '12px',
            marginRight: '12px',
          }}
        ></div>
        {user['3. Expertise'] &&
          user['3. Expertise'].map((expertise, i) => (
            <div
              key={`${i}-${expertise}`}
              style={{
                marginLeft: i > 0 ? '8px' : 0,
                border: '1px solid darkblue',
                padding: '6px',
                borderRadius: '8px',
                color: 'darkblue',
              }}
            >
              {expertise}
            </div>
          ))}
      </div>
      <div style={{ marginLeft: '8px' }}>
        <div
          onClick={() => window.open(airtableURL(user.recordID))}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <img
            src={airtableLogo}
            alt="airtable"
            style={{ width: '24px', height: '24px' }}
          />
          <div style={{ marginLeft: '8px', textDecoration: 'underline' }}>
            View on Airtable
          </div>
        </div>
      </div>
    </div>
  );
}
