import { createQueryKeys } from '@lukemorales/query-key-factory';

export const companiesKeys = createQueryKeys('companies', {
  autoCompleteCompany: (searchTerm: string, useEmployeesCollection) => ({
    searchTerm,
    useEmployeesCollection,
  }),
  getCompany: (companyId: string) => ({ companyId }),
});

export const companiesV2Keys = createQueryKeys('companiesV2', {
  autoCompleteCompany: (searchTerm: string, simplyEnriched?: boolean) => ({
    searchTerm,
    simplyEnriched,
  }),
  getCompany: (companyId: string) => ({ companyId }),
});
