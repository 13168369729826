import React, { ReactElement, useState } from 'react';
import { SidebarSection } from '@src/views/Profile/Sidebar/SidebarSection';
import { createUseStyles } from 'react-jss';
import TextButton from '@src/components/TextButton';
import { LocalWebsites, Website } from '@src/stores/Profile/models';
import { isValidUrl, withHttps } from '@src/helpers/urls';
import TextInput from '@src/components/Inputs/TextInput/TextInput';
import { Colors, TextColors } from '@ateams/components';

interface Props {
  websites: LocalWebsites;
  onChange(items: LocalWebsites): void;
  onAdd(): void;
  onLinkClick?(url: string): void;
  readonly?: boolean;
  error?: boolean;
  disabled?: boolean;
}

const useStyles = createUseStyles({
  website: {
    fontSize: 17,
    color: '#222',
    fontWeight: 400,
    marginTop: 12,
    marginBottom: 16,
  },
  input: {
    fontSize: '17px',
    color: TextColors.regular,
    fontWeight: '400',
    padding: '5px 0',
    marginTop: '5px',
    '&::placeholder': {
      color: Colors.regular,
    },
  },
});

export const Websites = (props: Props): ReactElement => {
  const styles = useStyles();
  const {
    websites,
    onChange,
    onLinkClick,
    onAdd,
    readonly = true,
    disabled,
    error,
  } = props;

  const [errorIds, setErrorIds] = useState<string[]>([]);

  const validateUrl = (website: Website) => {
    const updatedUrl = withHttps(website.url);
    handleChange(updatedUrl, website._id);

    if (!isValidUrl(updatedUrl)) {
      !errorIds.includes(website._id) &&
        setErrorIds([...errorIds, website._id]);
      return;
    } else {
      setErrorIds(
        [...errorIds].filter((existingId) => existingId !== website._id),
      );
    }
  };

  const handleChange = (url: string, id: string) => {
    const updatedWebsites = [...websites];
    const websiteIndex = updatedWebsites.findIndex(
      (exsitingWebsite) => exsitingWebsite._id === id,
    );
    updatedWebsites[websiteIndex].url = url;
    onChange(updatedWebsites);
  };

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: string,
  ) => {
    // Delete website row on entering backspace in empty input
    if (e.currentTarget.value.length === 0 && e.key === 'Backspace') {
      const updatedWebsites = websites.filter((website) => website._id !== id);
      onChange(updatedWebsites);
      e.stopPropagation();
    }
  };

  return (
    <SidebarSection
      title={'Website'}
      onAddClick={onAdd}
      readonly={readonly}
      disabled={disabled}
      empty={!websites.length}
      tooltipEmptyLabel={'Add other links'}
      error={error}
    >
      {websites.map((website) => {
        return readonly ? (
          <div key={website._id} className={styles.website}>
            <TextButton
              highlight
              style={{ wordBreak: 'break-word', textAlign: 'left' }}
              onClick={() => {
                window.open(website.url, '_blank');
                onLinkClick && onLinkClick(website.url);
              }}
            >
              {website.url}
            </TextButton>
          </div>
        ) : (
          <TextInput
            variant="dashed"
            key={website._id}
            placeholder={'http://...'}
            value={website.url || ''}
            onChange={(e) => handleChange(e.target.value, website._id)}
            onBlur={() => validateUrl(website)}
            onKeyDown={(e) => handleKeyPress(e, website._id)}
            error={error || errorIds.includes(website._id)}
            className={styles.input}
          />
        );
      })}
    </SidebarSection>
  );
};
