import { createUseStyles } from 'react-jss';
import React from 'react';
const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  line: {
    flex: 1,
    height: '1px',
    backgroundColor: '#D9D9D9',
  },
  text: {
    margin: '0 20px',
    whiteSpace: 'nowrap',
    fontSize: '15px',
    fontWeight: 600,
  },
});

export const BecomeFullMember = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <span className={styles.line} />
      <span className={styles.text}>Become a full member of A.Team</span>
      <span className={styles.line} />
    </div>
  );
};
