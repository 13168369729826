import React, { ReactElement, useMemo } from 'react';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import { Link, Redirect } from 'react-router-dom';
import { format } from 'date-fns';
import { CurrentUserObject } from '@a_team/models/dist/UserObject';
import { AvailableType } from '@a_team/models/dist/AvailabilityObject';
import { useStores } from '@src/stores';
import { AuthStoreData } from '@src/stores/Auth';
import {
  MissionControlAllMissionsLocation,
  ProfileEditLocation,
} from '@src/locations';
import DropdownContainer from '../DropdownContainer';
import CardList from '../CardList';
import NoResults from '@src/components/NoResults';
import { LoadingCards } from '@src/views/MissionControl/LoadingCards';
import RecommendedCardList from '../RecommendedCardList';
import { useQueryClientSignalMissions } from '@src/rq/signals';
import MissionControlCardV2 from '../MissionControlCard/MissionControlCardV2/MissionControlCardV2';
import { useGridMissionControlCardStyles } from '../MissionControlCard/common/styles';
import { getIsMissionLiked } from '../MissionControlCard/common/utils';
import Reserve from '../Reserve';
import { useGetRecommendedMissionsQuery } from '@src/rq/missions';

const useStyles = createUseStyles({
  bold: {
    fontWeight: 500,
  },
  info: {
    marginTop: '8px',
    marginBottom: '8px',
  },
});

const formatAvailability = (user: AuthStoreData['currentUser']): string => {
  const availability = (user as undefined | CurrentUserObject)?.availability;

  switch (availability?.type) {
    case AvailableType.Now:
      return ` (${availability.weeklyHoursAvailable} hrs/wk)`;
    case AvailableType.FutureDate:
      return ` (from ${format(
        new Date(availability.availableFrom as Date | string),
        'MMM. d',
      )} for ${availability.weeklyHoursAvailable} hrs/wk)`;
    default:
      return '';
  }
};

const RecommendedMissions = (): ReactElement => {
  const styles = useStyles();
  const gridStyles = useGridMissionControlCardStyles();

  const { auth, missionControl } = useStores();

  const { withBuilderLikes } = auth;
  const { data: starredMissions } = useQueryClientSignalMissions({
    enabled: withBuilderLikes,
  });
  const {
    isLoading: isLoadingRecommendedMissions,
    isError: isErrorLoadingRecommendedMissions,
  } = useGetRecommendedMissionsQuery();

  const missions = useMemo(
    () => missionControl.getRecommended,
    [
      isLoadingRecommendedMissions,
      isErrorLoadingRecommendedMissions,
      missionControl.getRecommended,
    ],
  );
  const availability = useMemo(
    () => formatAvailability(auth.currentUser),
    [auth, auth.currentUser],
  );

  const isMissionCardAvailable =
    !isLoadingRecommendedMissions && missions.length;

  const isRecommendationsEmpty =
    !isLoadingRecommendedMissions && missions.length === 0;

  const notInterestedMap = missionControl.notInterestedMap;
  const appliedMissions = missionControl.allApplied;

  const starredMissionsWithoutNotInterested = useMemo(() => {
    if (
      !notInterestedMap ||
      Object.keys(notInterestedMap).length === 0 ||
      !appliedMissions ||
      appliedMissions.length === 0
    ) {
      return starredMissions;
    }

    const appliedMissionIds = appliedMissions.map(
      (appliedMission) => appliedMission.mission.mid,
    );

    return starredMissions?.filter(
      (mission) =>
        !notInterestedMap.has(mission.mid) &&
        !appliedMissionIds.includes(mission.mid),
    );
  }, [notInterestedMap, starredMissions, appliedMissions]);

  // Once TE implements limited access badge, we'll also include auth.hasLimitedAccess
  const isLimitedAccess = auth.limitedAccess;

  if (auth.withOnboardingV2 && !auth.onboardingCompleted) {
    return <Redirect to={MissionControlAllMissionsLocation} />;
  }

  if (isLimitedAccess) {
    return <Reserve />;
  }

  return (
    <>
      <DropdownContainer>
        <p className={styles.info}>
          We recommend missions based on your{' '}
          <b className={styles.bold}>Roles</b>,{' '}
          <b className={styles.bold}>Skills</b>,{' '}
          <b className={styles.bold}>Industries</b> and{' '}
          <b className={styles.bold}>{`Availability${availability}`}</b>.{' '}
          <Link to={ProfileEditLocation(auth.currentUser?.username || '')}>
            Update Settings
          </Link>
        </p>
      </DropdownContainer>

      {withBuilderLikes &&
        starredMissionsWithoutNotInterested &&
        starredMissionsWithoutNotInterested.length > 0 && (
          <RecommendedCardList missions={starredMissionsWithoutNotInterested} />
        )}

      {isMissionCardAvailable ? (
        <div className={gridStyles.container}>
          {missions.map((mission, index) => (
            <MissionControlCardV2
              showVideo
              number={index}
              key={mission.mid}
              mission={mission}
              companyLiked={getIsMissionLiked(
                starredMissionsWithoutNotInterested,
                mission.mid,
              )}
            />
          ))}
        </div>
      ) : null}

      {isLoadingRecommendedMissions && (
        <CardList recommendedMissions>
          <LoadingCards type={'recommended'} count={6} />
        </CardList>
      )}

      {isRecommendationsEmpty && (
        <NoResults
          imageType="noRecommended"
          title="You don't have any recommendations"
          text={
            'Unfortunately, all the recommendations have ended, but we update the list frequently and tomorrow you will get something new.'
          }
        />
      )}
    </>
  );
};

export default observer(RecommendedMissions);
