import React from 'react';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import { AdminVettingProcessColumnProps } from '..';
import { Spacing } from '@ateams/components';
import { ValueTag } from '@src/views/VettingFeedbackForm/components/value-tag';
import {
  Size,
  Text,
} from '@src/views/VettingFeedbackForm/components/typography';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  valueTag: {
    marginBottom: Spacing.small,
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

export const ReachoutEmailDatesField: React.FC<
  AdminVettingProcessColumnProps
> = ({ vettingProcess }) => {
  const styles = useStyles();

  if (!vettingProcess.reachoutEmails?.length) {
    return null;
  }

  return (
    <div className={styles.container}>
      {vettingProcess.reachoutEmails.map(({ user: { email }, date }) => (
        <EmailValueTag
          key={email + date}
          className={styles.valueTag}
          email={email}
          date={date as unknown as string}
        />
      ))}
    </div>
  );
};

const useSkillValueTagStyles = createUseStyles({
  separator: {
    height: '16px',
    width: '1px',
    margin: '0 8px',
    background: '#C0C0C0',
  },
});

interface EmailValueTagProps {
  email: string;
  date: string;
  className?: string;
}

const EmailValueTag: React.FC<EmailValueTagProps> = (props) => {
  const styles = useSkillValueTagStyles();
  const { email, date, className } = props;

  return (
    <ValueTag className={className}>
      <Text size={Size.Small}>{email}</Text>
      <div className={styles.separator} />
      <Text size={Size.Small}>{format(new Date(date), 'MMM dd, yyyy')}</Text>
    </ValueTag>
  );
};
