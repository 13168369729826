import React from 'react';

function EmptyCell({ padding }: { padding?: number }) {
  return (
    <div
      style={{
        color: '#C0C0C0',
        padding: padding ?? 0,
      }}
    >
      —
    </div>
  );
}

export default EmptyCell;
