import React, { ReactElement, memo } from 'react';
import { Breakpoints } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface Props {
  children: ReactElement | ReactElement[];
  recommendedMissions?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  root: {
    paddingLeft: 0,
    paddingBottom: '4em',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      display: 'grid',
      gridTemplateColumns: (props: Props) =>
        props.recommendedMissions
          ? 'repeat(1, minmax(0, 1fr))'
          : 'repeat(2, minmax(0, 1fr))',
      gridGap: '1.5em',
      justifyContent: 'start',
    },
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    root: {
      gridTemplateColumns: (props: Props) =>
        props.recommendedMissions
          ? 'repeat(1, minmax(0, 1fr))'
          : 'repeat(3, minmax(0, 1fr))',
    },
  },
  [`@media (min-width: ${Breakpoints.lg}px)`]: {
    root: {
      gridTemplateColumns: (props: Props) =>
        props.recommendedMissions
          ? 'repeat(1, minmax(0, 1fr))'
          : 'repeat(4, minmax(0, 1fr))',
    },
  },
  [`@media (min-width: ${Breakpoints.xl}px)`]: {
    root: {
      gridTemplateColumns: (props: Props) =>
        props.recommendedMissions
          ? 'repeat(2, minmax(0, 1fr))'
          : 'repeat(4, minmax(0, 1fr))',
    },
  },
});

const areCardListsEqual = (prevProps: Props, nextProps: Props): boolean => {
  if (Array.isArray(prevProps.children) && Array.isArray(nextProps.children)) {
    if (prevProps.children.length !== nextProps.children.length) return false;
    return prevProps.children.every((m, i) =>
      m?.props?.mission?.id && (nextProps.children as ReactElement[])[i]
        ? m.props.mission.id ===
          (nextProps.children as ReactElement[])[i].props.mission.id
        : false,
    );
  }
  return false;
};

const CardList = memo((props: Props): ReactElement => {
  const { children } = props;
  const styles = useStyles(props);

  return <ul className={cx(styles.root, props.className)}>{children}</ul>;
}, areCardListsEqual);

export default CardList;
