import React from 'react';

export const CalendarIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M11 3H12C13.1046 3 14 3.89543 14 5V6M11 3V2M11 3H8M5 3H4C2.89543 3 2 3.89543 2 5V6M5 3V2M5 3H8M2 6V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V6M2 6H14M8 3V2"
        stroke="#62646A"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        opacity="0.6"
        d="M4 8.5H6M7 8.5H9M10 8.5H12M4 11H6M7 11H9"
        stroke="#62646A"
        strokeWidth="1.4"
      />
    </svg>
  );
};
