import React, { CSSProperties, ReactElement } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { BorderColors } from '../theme/colors';
import { BorderRadius } from '../theme/styles';

export interface FormGroupProps {
  className?: string;
  style?: CSSProperties;
}

const useStyles = createUseStyles({
  root: {
    width: '100%',
    border: `1px solid ${BorderColors['light']}`,
    borderRadius: BorderRadius.default,
  },
});

export const FormGroup: React.FC<FormGroupProps> = (props): ReactElement => {
  const { children, className, style } = props;
  const styles = useStyles();

  return (
    <div className={cx(styles.root, className)} style={style}>
      {children}
    </div>
  );
};
