import { SelectOption } from '@ateams/components';
import {
  AdminVettingProcess,
  BasicVettingProcess,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { VettingAdminColumns, VettingBasicColumns, VettingColumns } from '.';
import {
  adminVettingProcessColumns,
  basicVettingProcessColumns,
  VettingColumnConfig,
} from './mapping';

export function getColumnFromName(
  name: VettingColumns,
):
  | VettingColumnConfig<BasicVettingProcess>
  | VettingColumnConfig<AdminVettingProcess> {
  if (name in basicVettingProcessColumns) {
    return basicVettingProcessColumns[name as VettingBasicColumns];
  }

  if (name in adminVettingProcessColumns) {
    return adminVettingProcessColumns[name as VettingAdminColumns];
  }

  throw new Error(`Received invalid column name: ${name}`);
}

export function columnNameToSelectOption(name: VettingColumns): SelectOption {
  const { header } = getColumnFromName(name);

  return {
    value: name,
    label: header,
  };
}
