import { VettingProcessStatus } from '@a_team/models/dist/vetting-processes/status';
import {
  VettingAdminColumns,
  VettingBasicColumns,
} from '../vetting-table/columns';
import {
  VETTING_PROCESSES_TABLE_TABS,
  getProcessesTablePage,
} from '../vetting-processes-table-page';
import { FilterComponents } from '../vetting-processes-query-bar/filters';
import { VettingType } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { FiltersConfig } from '../vetting-processes-table-page/filters';

const COLUMNS = {
  ADMIN: {
    DEFAULT: [
      VettingAdminColumns.NameWithOptOutIndication,
      VettingAdminColumns.InterviewType,
      VettingBasicColumns.MainRole,
      VettingBasicColumns.SkillForVetting,
      VettingAdminColumns.Status,
      VettingAdminColumns.ContactOwner,
      VettingBasicColumns.CodeSample,
      VettingAdminColumns.Interviewer,
      VettingAdminColumns.InterviewerSuggestions,
      VettingAdminColumns.CreatedAt,
      VettingAdminColumns.PreVettingFormSentDate,
      VettingAdminColumns.EvaluationInvite,
      VettingAdminColumns.BookingLink,
      VettingBasicColumns.InterviewDate,
      VettingBasicColumns.SubmitFeedback,
      VettingAdminColumns.RemoveApplication,
    ],
    ALL: [
      VettingAdminColumns.NameWithOptOutIndication,
      VettingAdminColumns.InterviewType,
      VettingBasicColumns.BuilderInfo,
      VettingBasicColumns.MainRole,
      VettingBasicColumns.SkillForVetting,
      VettingAdminColumns.Status,
      VettingAdminColumns.ContactOwner,
      VettingBasicColumns.CodeSample,
      VettingAdminColumns.Interviewer,
      VettingAdminColumns.InterviewerSuggestions,
      VettingAdminColumns.CreatedAt,
      VettingAdminColumns.PreVettingFormSentDate,
      VettingAdminColumns.EvaluationInvite,
      VettingAdminColumns.BookingLink,
      VettingBasicColumns.InterviewDate,
      VettingBasicColumns.SubmitFeedback,
      VettingAdminColumns.RemoveApplication,
    ],
  },
  DEFAULT: [
    VettingBasicColumns.Name,
    VettingBasicColumns.BuilderInfo,
    VettingBasicColumns.MainRole,
    VettingBasicColumns.SkillForVetting,
    VettingBasicColumns.CodeSample,
    VettingBasicColumns.InterviewDate,
    VettingBasicColumns.SubmitFeedback,
  ],
  [VettingType.InternalTechnical]: [
    VettingBasicColumns.Name,
    VettingBasicColumns.BuilderInfo,
    VettingBasicColumns.MainRole,
    VettingBasicColumns.SkillForVetting,
    VettingBasicColumns.InterviewerReadOnly,
    VettingBasicColumns.CodeSample,
    VettingBasicColumns.InterviewDate,
    VettingBasicColumns.SubmitFeedback,
  ],
};

const FILTERS: FiltersConfig = {
  ADMIN: new Set([
    FilterComponents.User,
    FilterComponents.VettingType,
    FilterComponents.VettingStatus,
    FilterComponents.MainRole,
    FilterComponents.CodeSampleProgrammingLanguage,
    FilterComponents.HasSubmittedPreEvaluationForm,
    FilterComponents.CreatedAt,
    FilterComponents.Vetter,
  ]),
  [VettingType.InternalTechnical]: new Set([
    FilterComponents.Vetter,
    FilterComponents.User,
    FilterComponents.MainRole,
    FilterComponents.CreatedAt,
  ]),
};

export default getProcessesTablePage({
  tabName: VETTING_PROCESSES_TABLE_TABS.membersToVet,
  columnsConfig: COLUMNS,
  filtersConfig: FILTERS,
  title: 'Members to Review',
  noProcessesMessage: 'No users to vet',
  statuses: [
    VettingProcessStatus.PendingBuilderInterviewSchedule,
    VettingProcessStatus.PendingPreVettingForm,
    VettingProcessStatus.PendingVetterApproval,
    VettingProcessStatus.PendingVetterAssignment,
  ],
  isStale: false,
});
