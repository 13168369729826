import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Modal, { ModalBasicProps } from '@src/components/Modal';
import SectionHeading from '@src/components/SectionHeading';
import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  BorderRadius,
  Button,
  Checkbox,
  Colors,
  Icon,
  IconType,
  Paddings,
  Spacing,
  Spinner,
} from '@ateams/components';
import { useGetReachoutAutomationPreviewForMission } from '@src/rq/emailFromTargeter';
import { useStores } from '@src/stores';
import cx from 'classnames';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';

const useStyles = createUseStyles({
  container: {
    overflow: 'auto',
    padding: Paddings.xLarge,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  header: {
    marginBottom: Spacing.xLarge,
  },
  actionsBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '12px',
    paddingRight: '12px',
    gap: Spacing.medium,
  },
  roleCard: {
    padding: Paddings.large,
    textAlign: 'left',
    border: `1px solid ${BorderColors.light}`,
    borderRadius: BorderRadius.medium,
    marginBottom: Spacing.xLarge,
  },
  roleCardNotSelected: {
    backgroundColor: Colors.regularLight,
  },
  openInTargeterButton: {
    color: Colors.secondary,
  },
  openInTargeterButtonDisabled: {
    color: Colors.regular,
  },
  centerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
    marginBottom: Spacing.xLarge,
  },
  totalAudienceCount: {
    marginLeft: Spacing.small,
    color: Colors.regular,
    textDecoration: 'line-through',
  },
  totalAudience: {
    marginTop: Spacing.small,
    marginBottom: 0,
  },
  limitInput: {
    marginBottom: Spacing.small,
    textAlign: 'left',
  },
  recalculateButton: {
    marginLeft: Spacing.small,
  },
  recalculateButtonIcon: {
    marginRight: Spacing.small,
  },
  roleCategoryLabel: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: Spacing.small,
  },
  checkbox: {
    margin: `0 ${Spacing.small}px 0 0`,
    display: 'inline-block',
  },
});

const AUDIENCE_DEFAULT_LIMIT_AUTOMATED_REACHOUT = 75;

export interface AutomatedReachoutModalProps extends ModalBasicProps {
  missionId?: string;
  onSaveWithoutReachout?(): void;
  onSaveWithReachoutRedirect?(limit: number): void;
  onReachoutRedirect?(limit: number): void;
  saveAndOpenInTargeterLabel?: string;
  saveWithoutReachoutLabel?: string;
  saveWithReachoutLabel?: string;
}

const ROLE_HEADLINE_LIMIT = 150;

const AutomatedReachoutModal: React.FC<AutomatedReachoutModalProps> = ({
  open,
  onClose,
  onSaveWithoutReachout,
  onSaveWithReachoutRedirect,
  missionId,
  saveAndOpenInTargeterLabel,
  saveWithoutReachoutLabel,
  onReachoutRedirect,
}: AutomatedReachoutModalProps) => {
  const styles = useStyles();
  const { targeterTabManager } = useStores();
  const [maxNumberOfBuilders, setMaxNumberOfBuilders] = React.useState<number>(
    AUDIENCE_DEFAULT_LIMIT_AUTOMATED_REACHOUT,
  );
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [maxNumberOfBuildersCache, setMaxNumberOfBuildersCache] =
    React.useState<number>(AUDIENCE_DEFAULT_LIMIT_AUTOMATED_REACHOUT);
  const { data, isLoading, refetch, isRefetching } =
    useGetReachoutAutomationPreviewForMission({
      missionId: missionId || '',
      maxNumberOfBuilders,
      enabled: false,
    });
  const initializeTabCache = async (cb?: () => void) => {
    if (!data?.roles || !missionId) return;
    targeterTabManager.initializeTabCacheFromAutomatedReachoutMissionPreview(
      missionId,
      data?.roles.filter((role) => selectedRoles.includes(role.roleId)),
    );
    cb && cb();
  };
  useEffect(() => {
    if (missionId && open) {
      refetch();
    }
  }, [missionId, open]);
  useEffect(() => {
    if (data?.roles) {
      setSelectedRoles(data.roles.map((role) => role.roleId));
    }
  }, [data]);
  const invalidMaxNumberOfBuilders =
    !maxNumberOfBuilders || maxNumberOfBuilders < 1;
  const noRolesSelected = selectedRoles.length === 0;
  return (
    <Modal open={open} onClose={onClose} style={{ padding: 0, minWidth: 750 }}>
      <div className={styles.container}>
        <SectionHeading isFirst className={styles.header}>
          Confirm automated reachouts
        </SectionHeading>
        {isLoading || isRefetching ? (
          <div className={styles.centerBox}>
            <Spinner color="#333" />
          </div>
        ) : null}
        {!data && !isLoading && !isRefetching && (
          <div className={styles.centerBox}>
            <p>There are no eligible roles for reachout</p>
          </div>
        )}
        {data && data.roles?.length && !isLoading && !isRefetching ? (
          <div className={styles.limitInput}>
            <>
              <OutlinedInput
                label="Max number of builders"
                disabled={isLoading || isRefetching}
                value={maxNumberOfBuilders}
                type="number"
                onChange={(e) =>
                  !isLoading &&
                  !isRefetching &&
                  setMaxNumberOfBuilders(e.target.valueAsNumber)
                }
                endAdornment={
                  <Button
                    className={styles.recalculateButton}
                    color="regularLight"
                    squared
                    disabled={
                      isLoading || isRefetching || invalidMaxNumberOfBuilders
                    }
                    size="small"
                    width="auto"
                    onClick={() => {
                      refetch().then(() => {
                        setMaxNumberOfBuildersCache(maxNumberOfBuilders);
                      });
                    }}
                  >
                    <Icon
                      className={styles.recalculateButtonIcon}
                      type={IconType.Refresh}
                    />
                    {'Recalculate'}
                  </Button>
                }
              />
            </>
          </div>
        ) : null}
        {data?.roles.map((rolePreview) => (
          <div
            key={rolePreview.roleId}
            className={cx(
              styles.roleCard,
              !selectedRoles.includes(rolePreview.roleId) &&
                styles.roleCardNotSelected,
            )}
          >
            <strong className={styles.roleCategoryLabel}>
              <Checkbox
                className={styles.checkbox}
                checked={selectedRoles.includes(rolePreview.roleId)}
                onChange={(e) =>
                  setSelectedRoles(
                    e.target.checked
                      ? [...selectedRoles, rolePreview.roleId]
                      : selectedRoles.filter((id) => id !== rolePreview.roleId),
                  )
                }
                label={rolePreview.roleCategory}
              />
            </strong>
            <p>
              {rolePreview.roleHeadline.slice(0, ROLE_HEADLINE_LIMIT)}
              {rolePreview.roleHeadline.length > ROLE_HEADLINE_LIMIT
                ? '...'
                : ''}
            </p>
            <p className={styles.totalAudience}>
              Total audience targeted:{' '}
              {Math.min(rolePreview.userCount, maxNumberOfBuildersCache)}
              {rolePreview.userCount > maxNumberOfBuildersCache ? (
                <span className={styles.totalAudienceCount}>
                  {rolePreview.userCount}
                </span>
              ) : null}
            </p>
          </div>
        ))}
        <div className={styles.actionsBar}>
          {!isLoading && !isRefetching && !data ? (
            <></>
          ) : (
            <>
              <Button
                squared
                disabled={isLoading || isRefetching || noRolesSelected}
                size="small"
                width="auto"
                color="transparent"
                className={cx(
                  styles.openInTargeterButton,
                  (isLoading || isRefetching || noRolesSelected) &&
                    styles.openInTargeterButtonDisabled,
                )}
                onClick={() =>
                  initializeTabCache(() => {
                    onReachoutRedirect?.(maxNumberOfBuildersCache);
                  })
                }
              >
                {'Open in targeter'}
              </Button>
              {onSaveWithReachoutRedirect ? (
                <Button
                  squared
                  disabled={isLoading || isRefetching || noRolesSelected}
                  size="small"
                  width="auto"
                  color="transparent"
                  className={cx(
                    styles.openInTargeterButton,
                    (isLoading || isRefetching || noRolesSelected) &&
                      styles.openInTargeterButtonDisabled,
                  )}
                  onClick={() =>
                    initializeTabCache(() => {
                      onSaveWithReachoutRedirect?.(maxNumberOfBuildersCache);
                    })
                  }
                >
                  {saveAndOpenInTargeterLabel || 'Save and open in targeter'}
                </Button>
              ) : null}
            </>
          )}
          {onSaveWithoutReachout ? (
            <Button
              squared
              size="small"
              width="auto"
              color="regularLight"
              onClick={onSaveWithoutReachout}
            >
              {saveWithoutReachoutLabel || 'Save without reachout'}
            </Button>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default observer(AutomatedReachoutModal);
