import React, { ReactElement } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { Company } from '@a_team/models/dist/Company';
import CompanyAvatar from '@src/components/CompanyAvatar/CompanyAvatar';
import { NewCompanyId } from '.';
import { Colors, TextColors } from '@ateams/components';

interface CompanyItemProps {
  company: Company & { url?: string };
  onSelectCompany: (company: Company) => void;
}

const useStyles = createUseStyles({
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 24px',
    cursor: 'pointer',
    '&:last-of-type': {
      marginBottom: 0,
    },
    '&:hover': {
      background: '#FFFAF7',
    },
  },
  iconContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    fontSize: 15,
    color: TextColors.regular,
  },
  details: {
    marginLeft: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  urlFont: {
    color: TextColors.regularLight,
    fontSize: 12,
    lineHeight: '16px',
  },
  newCompany: {
    color: Colors.secondaryDark,
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '19px',
  },
  addCompanyIcon: {
    fontSize: 20,
    color: Colors.secondaryDark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const CompanyItem = (props: CompanyItemProps): ReactElement => {
  const { name, logoUrl, id, url } = props.company;
  const itemLogoUrl = logoUrl !== null ? logoUrl : undefined;
  const styles = useStyles();

  const onSelect = (): void => {
    props.onSelectCompany(props.company);
  };

  return (
    <div
      className={styles.item}
      onClick={onSelect}
      data-testing-id={'company-picker-item'}
    >
      <div className={styles.iconContainer}>
        {id === NewCompanyId ? (
          <span className={styles.addCompanyIcon}>+</span>
        ) : (
          <CompanyAvatar src={itemLogoUrl} />
        )}
      </div>
      <div className={styles.details}>
        <span
          className={cx(styles.name, id === NewCompanyId && styles.newCompany)}
        >
          {id === NewCompanyId ? `Add "${name}" company` : name}
        </span>
        {url && <span className={styles.urlFont}>{url}</span>}
      </div>
    </div>
  );
};
