import { Button, Colors } from '@ateams/components';
import React from 'react';
import Icon from './Icon.svg';
import { createUseStyles } from 'react-jss';

interface Props {
  onOpen(): void;
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Colors.backgroundWhite,
    borderRadius: 8,
    padding: 16,
    marginBottom: 40,
  },
  icon: {
    width: 32,
    height: 32,
    marginRight: 16,
  },
  title: {
    fontSize: '1em',
    fontWeight: 500,
    lineHeight: '24px',
    margin: 0,
  },
  content: {
    margin: 0,
  },
});

export const PaymentMethodBanner = ({ onOpen }: Props) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <img src={Icon} alt="Payment method" className={styles.icon} />
      <div style={{ flexGrow: 1 }}>
        <h3 className={styles.title}>Payment method</h3>
        <p className={styles.content}>
          Your default payment method will be used for all future payments.
        </p>
      </div>
      <div>
        <Button
          outlined
          size="small"
          width="auto"
          color="regularDark"
          onClick={onOpen}
        >
          Edit payment method
        </Button>
      </div>
    </div>
  );
};
