import React from 'react';
import { observer } from 'mobx-react';

import ProfileNavBar from './ProfileNavBar';
import { useStores } from '@src/stores';
import ApplicationNavbar from './ApplicationNavBar';

const Navigation = () => {
  const { users } = useStores();
  const { profile } = users;

  if (profile?.application) {
    return <ApplicationNavbar />;
  }

  return <ProfileNavBar />;
};

export default observer(Navigation);
