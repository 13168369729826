import cx from 'classnames';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';

export interface Props {
  inner?: JSX.IntrinsicElements['div'];
  aspectRatio: number;
}

const useStyles = createUseStyles<Props>({
  outer: {
    position: 'relative',
  },
  inner: ({ aspectRatio }) => ({
    display: 'block',
    width: '100%',
    height: 0,
    paddingBottom: `${100 / aspectRatio}%`,
  }),
});

const AspectRatioContainer = ({
  className,
  children,
  inner: { className: innerClassName, ...innerProps } = {},
  ...props
}: Props & JSX.IntrinsicElements['div']): ReactElement | null => {
  const styles = useStyles(props);
  const { aspectRatio, ...rest } = props;
  return (
    <div className={cx(styles.outer, className)} {...rest}>
      <div className={cx(styles.inner, innerClassName)} {...innerProps}>
        {children}
      </div>
    </div>
  );
};

export default AspectRatioContainer;
