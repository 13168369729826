import { Button } from '@a_team/ui-components';
import { Icon, IconType, Spinner } from '@ateams/components';
import React from 'react';
import useCommonStyles from './commonStyles';

interface GeneratePitchViewProps {
  isLoading?: boolean;
  isRewriting: boolean;
  onGenerateSuggestion: () => void;
}

const GeneratePitchView = ({
  onGenerateSuggestion,
  isLoading,
  isRewriting,
}: GeneratePitchViewProps) => {
  const styles = useCommonStyles();

  const renderText = () => {
    if (!isLoading) {
      return 'Suggested description available';
    }

    return isRewriting
      ? `We're working on an alternative suggestion, this might take a minute or two`
      : `Working on a suggestion, this might take a minute or two`;
  };
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.desc}>
          <Icon size="smaller" type={IconType.SuggestedTeams} />
          {renderText()}
        </div>
        <Button
          disabled={isLoading}
          onClick={(e) => {
            e.preventDefault();
            onGenerateSuggestion();
          }}
          className={styles.btn}
        >
          {isLoading ? (
            <Spinner size={14} color="white" />
          ) : (
            <Icon type={IconType.CrystalBallWhite} size="exact" />
          )}
          {isLoading ? 'Writing' : 'Start writing'}
        </Button>
      </div>
    </div>
  );
};

export default GeneratePitchView;
