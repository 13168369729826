import { createQueryKeys } from '@lukemorales/query-key-factory';

export const signalsKeys = createQueryKeys('signals', {
  getClientSignalById: (clientSignalId: string, userId: string) => ({
    clientSignalId,
    userId,
  }),
  getClientSignalMissions: (userId: string) => ({
    userId,
  }),
  getClientSignalsByIds: (clientSignalIds: string[], userId: string) => ({
    clientSignalIds,
    userId,
  }),
});
