import React, { ReactElement, useRef } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { FileSelectionBox } from '@src/components/FileUploader/components/FileSelectionBox';
import useToggle from '@src/hooks/useToggle';
import useClickOutside from '@src/hooks/useClickOutside';
import { Typography } from '@a_team/ui-components';

export type ButtonsPosition = 'bottom' | 'right' | 'left';

interface Props {
  openDialog?: () => void;
  onUrlUpload: (url: string) => void;
  onRandomClick?: () => void;
  onUnsplashSelect?: (url: string) => void;
  contained?: boolean;
  withImageSearch?: boolean;
  position?: ButtonsPosition;
  disabled?: boolean;
  disableFileSelect?: boolean;
  className?: string;
  withOptionsBox?: boolean;
  isImageAvailable: boolean;
  onRemoveImage?: () => void;
  onSetCoverImage?: () => void;
  getIsCoverImage?: () => boolean;
}

const defaultProps: Partial<Props> = {
  contained: true,
  withImageSearch: true,
  position: 'bottom',
  disabled: false,
  withOptionsBox: true,
};

const useStyles = createUseStyles({
  buttonsWrapper: (props: Props) => ({
    ...(props.contained && {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      cursor: 'default',
    }),
    position: 'relative',
  }),
  buttonsContainer: (props: Props) => ({
    width: '100%',
    background: '#fff',
    opacity: '0.9',
    display: 'flex',
    ...(props.onRemoveImage
      ? {
          justifyContent: 'space-between',
        }
      : { justifyContent: 'center' }),
    padding: '12px 16px',
  }),
});

export const FileSelectionButtons = (props: Props): ReactElement => {
  const {
    onUrlUpload,
    openDialog,
    contained,
    withImageSearch,
    onUnsplashSelect,
    position,
    disableFileSelect,
    className,
    withOptionsBox,
    isImageAvailable,
    onRemoveImage,
    onSetCoverImage,
    getIsCoverImage,
  } = props;
  const [isBoxShown, setIsBoxShown] = useToggle(false);
  const fileSelectionButtonsRef = useRef<HTMLDivElement>(null);
  const styles = useStyles(props);

  useClickOutside(fileSelectionButtonsRef, (): void => {
    setIsBoxShown(false);
  });

  const isCoverImage = getIsCoverImage && getIsCoverImage();

  return (
    <div
      ref={fileSelectionButtonsRef}
      onClick={(e): void => e.stopPropagation()}
      className={cx(styles.buttonsWrapper, className)}
    >
      <div className={contained ? styles.buttonsContainer : undefined}>
        {onRemoveImage && (
          <div>
            <Typography
              variant={'textMedium'}
              margin={'none'}
              color={'Baracus@600'}
              weight={'regular'}
              onClick={(e) => {
                e.preventDefault();
                withOptionsBox || !openDialog ? setIsBoxShown() : openDialog();
              }}
              style={{ cursor: 'pointer' }}
            >
              Add New Image
            </Typography>

            {onSetCoverImage && !isCoverImage && (
              <>
                <span> | </span>
                <Typography
                  variant={'textMedium'}
                  margin={'none'}
                  color={'Baracus@600'}
                  weight={'regular'}
                  onClick={(e) => {
                    e.preventDefault();
                    onSetCoverImage();
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Set as Cover
                </Typography>
              </>
            )}
          </div>
        )}

        <div style={{ justifyContent: 'flex-end' }}>
          {isImageAvailable && onRemoveImage && (
            <Typography
              variant={'textMedium'}
              margin={'none'}
              color={'Red@600'}
              weight={'light'}
              onClick={(e) => {
                e.preventDefault();
                onRemoveImage();
              }}
              style={{ cursor: 'pointer' }}
            >
              Remove Image
            </Typography>
          )}
          {!onRemoveImage && (
            <Typography
              variant={'textMedium'}
              margin={'none'}
              color={'Baracus@600'}
              weight={'extra-bold'}
              onClick={(e) => {
                e.preventDefault();
                withOptionsBox || !openDialog ? setIsBoxShown() : openDialog();
              }}
              style={{ cursor: 'pointer' }}
            >
              {isImageAvailable ? 'Change' : 'Choose'} Image
            </Typography>
          )}
        </div>
      </div>
      {isBoxShown && onUrlUpload && (
        <FileSelectionBox
          uploadFromUrl={onUrlUpload}
          onUnsplashSelect={onUnsplashSelect}
          openDialog={openDialog}
          disableFileSelect={disableFileSelect}
          withImageSearch={withImageSearch}
          position={position}
        />
      )}
    </div>
  );
};

FileSelectionButtons.defaultProps = defaultProps;
