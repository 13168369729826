import React from 'react';
import { createUseStyles } from 'react-jss';
import CopyLink from './CopyLink';
import Share from './Share';

const useStyles = createUseStyles({
  title: {
    marginTop: 0,
    fontWeight: 700,
    fontSize: '16px',
  },
});

interface Props {
  shareLink: string;
  title: string;
}

const Invitation: React.FC<Props> = ({ shareLink, title }) => {
  const styles = useStyles();

  return (
    <>
      <h3 className={styles.title}>{title}</h3>
      <CopyLink shareLink={shareLink} />
      <Share shareLink={shareLink} />
    </>
  );
};

export default Invitation;
