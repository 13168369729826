import React, { ReactElement, SyntheticEvent, useMemo } from 'react';
import Color from 'color';
import {
  BasicUserObject,
  TeammateSuggestionObject,
  UserId,
} from '@a_team/models/dist/UserObject';
import { Card } from '@ateams/components';
import cx from 'classnames';
import UserAvatar from '@src/components/UserAvatar';
import { Button as CallToActionButton } from '@ateams/components';
import { Icon, IconType } from '@ateams/components';
import { GroupName } from '@a_team/models/dist/RoleCategory';
import { truncate } from '@ateams/react-utils/dist/helpers/strings';
import { Divider } from '@ateams/components';
import { Tag } from '@ateams/components';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { Colors } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import HoverUserCard from '@src/components/HoverUserCard';
import { useScreenClass } from 'react-grid-system';
import checkRed from './checkRed.svg';

interface Props {
  card: TeammateSuggestionObject;
  onRequest: (
    uid: UserId,
    requested: boolean,
    placement: 'card' | 'modal',
  ) => void;
  onClick?: (uid: UserId) => void;
  requested?: boolean;
}

const useStyles = createUseStyles({
  card: {
    flex: 1,
    marginRight: 0,
    marginBottom: 25,
    paddingBottom: 80,
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  userDetails: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  avatarContainer: {
    borderRadius: '50%',
    padding: 3,
    marginRight: 16,
    width: 54,
    height: 54,
    boxSizing: 'content-box',
  },
  avatarImage: {
    border: '6px solid #fff',
    display: 'inline',
  },
  name: {
    fontWeight: 500,
    margin: 0,
    marginBottom: 2,
    fontSize: 15,
  },
  role: {
    color: '#62646A',
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '24px',
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    height: 40,
    minWidth: 185,
    '&.requestedButton:hover': {
      color: `${Colors.danger} !important`,
      borderColor: `${Colors.danger} !important`,
    },
    '& .requestedLabel': {
      display: 'block',
    },
    '& .cancelLabel': {
      display: 'none',
    },
    '&:hover': {
      '& .requestedLabel': {
        display: 'none',
      },
      '& .cancelLabel': {
        display: 'block',
      },
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    card: {
      flex: '0 1 48%',
      marginBottom: 40,
      paddingBottom: '1.6em',
    },
    name: {
      fontSize: 20,
      fontWeight: 600,
    },
    buttonContainer: {
      width: 'auto',
      justifyContent: 'unset',
      position: 'static',
    },
  },
});

const OutlineColors: { [group in GroupName]: string } = {
  [GroupName.Developers]: '#70CE4F',
  [GroupName.ProductManagers]: '#FF6A73',
  [GroupName.Designers]: '#FFB649',
  [GroupName.DataSpecialists]: '#6096FF',
  [GroupName.Marketing]: '#6096FF',
  [GroupName.Others]: '#6096FF',
};

export const TeammateCard = (props: Props): ReactElement => {
  const styles = useStyles();
  const screenClass = useScreenClass();
  const { card, onClick } = props;

  const tags = useMemo(() => {
    const location = card.location?.city;
    const list = card.teammateTags.expertise.concat(
      card.teammateTags.industries,
    );

    location && list.unshift({ label: location, mutual: false });
    return list;
  }, [card.teammateTags]);

  const baseColor =
    OutlineColors[
      card.roleCategory ? card.roleCategory.group : GroupName.Others
    ];
  const colorLight = Color(baseColor).lighten(0.2).saturate(0.3);
  const colorDark = Color(baseColor).darken(0.3).saturate(0.7);

  const handleRequest = (e: SyntheticEvent) => {
    e.stopPropagation();
    props.onRequest(card.uid, !props.requested, 'card');
  };

  return (
    <Card
      className={styles.card}
      onClick={() => (onClick ? onClick(card.uid) : undefined)}
    >
      <div className={styles.header}>
        <div className={styles.userDetails}>
          <div
            className={styles.avatarContainer}
            style={{
              background: `linear-gradient(to bottom, ${colorLight}, ${colorDark})`,
            }}
          >
            {/* TeammateSuggestionObject extends BasicUserObject */}
            <HoverUserCard user={card as BasicUserObject}>
              <UserAvatar
                src={card.profilePictureURL}
                size={screenClass === 'xs' ? 58 : 54}
                imageClassName={styles.avatarImage}
              />
            </HoverUserCard>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <h3 className={styles.name}>{card.fullName}</h3>
            <span className={styles.role}>{card.title}</span>
            {card.aid && (
              <Tag
                thin
                style={{
                  marginTop: 2,
                  marginBottom: 2,
                  background: 'rgba(0, 0, 0, 0.4)',
                  color: '#fff',
                }}
              >
                Applied
              </Tag>
            )}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <CallToActionButton
            width={'auto'}
            size={'small'}
            onClick={handleRequest}
            className={cx(styles.button, { requestedButton: props.requested })}
            style={{
              ...(props.requested && {
                background: '#fff',
                color: '#C0C0C0',
                border: '1px solid #C0C0C0',
              }),
            }}
          >
            {props.requested ? (
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icon
                  type={IconType.Check}
                  muted
                  size={'exact'}
                  style={{ opacity: 0.5, marginRight: 4 }}
                  className="requestedLabel"
                />
                <img src={checkRed} className="cancelLabel" alt={'cancel'} />
                <span className="requestedLabel">Requested</span>
                <span className="cancelLabel">Cancel Request</span>
              </span>
            ) : (
              'Request as Teammate'
            )}
          </CallToActionButton>
        </div>
      </div>
      {card.aboutMe && (
        <p style={{ fontWeight: 400, wordBreak: 'break-word' }}>
          {truncate(card.aboutMe, 300)}
        </p>
      )}
      {tags.length > 0 && (
        <>
          <Divider margin={'small'} color={'#C0C0C0'} />
          <div
            style={{ display: 'flex', flexWrap: 'wrap', lineHeight: '24px' }}
          >
            <Icon type={IconType.Tag} style={{ marginRight: 8 }} />
            {tags.slice(0, 7).map((tag, i) => (
              <span
                key={i}
                style={{
                  marginRight: 4,
                }}
              >
                <span style={{ ...(tag.mutual && { color: Colors.success }) }}>
                  {tag.label}
                </span>
                {i + 1 === tags.slice(0, 7).length ? '' : ', '}
              </span>
            ))}
            {tags.length > 7 && (
              <TooltipWrapped
                html={
                  <>
                    {tags.slice(7).map((tag, i) => (
                      <div key={i}>- {tag.label}</div>
                    ))}
                  </>
                }
                arrow
                animation="fade"
              >
                <Tag thin style={{ marginLeft: 8, color: Colors.success }}>
                  +{tags.length - 7}
                </Tag>
              </TooltipWrapped>
            )}
          </div>
        </>
      )}
    </Card>
  );
};
