import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { SidebarSection } from '@src/views/Profile/Sidebar/SidebarSection';
import { Icon, IconType, Tag, TagList } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { Expertise } from '@src/stores/Profile/models';
import { ShowMoreButton } from '@src/views/VettingFeedbackForm/components/show-more';

interface Props {
  roles: Expertise[];
  onAddClick?: () => void;
  readonly?: boolean;
  error?: boolean;
  disabled?: boolean;
  withEditableTags?: boolean;
  onRemove?: (roles: Expertise[]) => void;
  label?: string;
}

const defaultProps = {
  withEditableTags: true,
};

const useStyles = createUseStyles({
  tags: {
    marginTop: 16,
    flexWrap: 'wrap',
  },
  tag: {
    padding: 10,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
  },
  tagIcon: {
    marginRight: '0.5em',
    filter: 'grayscale(100%)',
  },
  divider: {
    display: 'inline-block',
    height: 16,
    width: 1,
    backgroundColor: '#D2D6DD',
    marginLeft: 8,
    marginRight: 8,
  },
});

const DEFAULT_NUMBER_OF_ROLES_TO_SHOW = 4;

const Roles = (props: Props): ReactElement => {
  const {
    readonly,
    roles,
    onAddClick,
    disabled,
    error,
    withEditableTags,
    onRemove,
    label,
  } = props;

  const styles = useStyles();
  const showAll = !!readonly && roles.length > DEFAULT_NUMBER_OF_ROLES_TO_SHOW;
  const [numberOfRolesToShow, setNumberOfRolesToShow] = useState(
    DEFAULT_NUMBER_OF_ROLES_TO_SHOW,
  );

  const handleRemove = useCallback(
    (role: Expertise) => () => {
      onRemove && onRemove(roles.filter((item) => item !== role));
    },
    [roles],
  );

  const tags = useMemo(() => {
    let rolesToDisplay: Expertise[] = [];
    if (readonly) {
      rolesToDisplay = roles.slice(0, numberOfRolesToShow);
    } else {
      rolesToDisplay = [...roles];
    }
    return rolesToDisplay.map((role) => {
      return (
        <Tag
          key={role.id}
          className={styles.tag}
          onRemove={!readonly ? handleRemove(role) : undefined}
          color="backgroundLight"
        >
          {role.confidence && (
            <Icon
              type={IconType.StarsPurple}
              size={'small'}
              className={styles.tagIcon}
            />
          )}
          {role.name}
          {role.confidence && (
            <>
              <div className={styles.divider}></div>
              {`${(role.confidence * 100).toFixed(0)}%`}
            </>
          )}
        </Tag>
      );
    });
  }, [roles, numberOfRolesToShow, readonly]);

  const isShowingMore = showAll && tags.length === roles.length;

  const handleShowAll = () => {
    if (isShowingMore) {
      setNumberOfRolesToShow(DEFAULT_NUMBER_OF_ROLES_TO_SHOW);
    } else {
      setNumberOfRolesToShow(roles.length);
    }
  };

  return (
    <SidebarSection
      title={label || (withEditableTags ? 'Roles' : 'Additional Roles')}
      onAddClick={onAddClick}
      readonly={readonly}
      disabled={disabled}
      error={error}
    >
      <TagList className={styles.tags}>{tags}</TagList>
      {showAll && (
        <ShowMoreButton
          showMoreLabel="Show all"
          showLessLabel="Show less"
          isShowingMore={isShowingMore}
          onClick={handleShowAll}
          style={{ marginTop: 16 }}
        />
      )}
    </SidebarSection>
  );
};

Roles.defaultProps = defaultProps;
export default Roles;
