import React from 'react';
import { createUseStyles } from 'react-jss';
import { ConnectionStatus as ConnectionStatusEnum } from '@a_team/models/dist/ConnectionObject';
import { Icon, IconType } from '@ateams/components';

interface ConnectionStatusProps {
  status?: ConnectionStatusEnum;
  firstName?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    gap: 8,
    fontSize: 12,
    alignItems: 'center',
  },
  icon: {
    fontSize: 16,
  },
  connectionStatus: {
    textAlign: 'end',
  },
  connected: {
    color: '#6D00D7',
  },
});

// create a map of the connection status enum to the icon type
const connectionStatusIconMap: Record<
  ConnectionStatusEnum.Pending | ConnectionStatusEnum.Active,
  { iconType: IconType; label: string }
> = {
  [ConnectionStatusEnum.Active]: {
    iconType: IconType.ConnectedPositive,
    label: `You are connected with`,
  },
  [ConnectionStatusEnum.Pending]: {
    iconType: IconType.PendingGray,
    label: `Requested`,
  },
};

const inactiveConnection = {
  iconType: IconType.CloseGray,
  label: `Not connected`,
};

const ConnectionStatus = ({
  status,
  firstName,
}: ConnectionStatusProps): JSX.Element => {
  const styles = useStyles();

  // if status is not pending or active, use the inactive connection
  const { iconType, label } =
    status === ConnectionStatusEnum.Pending ||
    status === ConnectionStatusEnum.Active
      ? connectionStatusIconMap[status]
      : inactiveConnection;

  const isConnected = status === ConnectionStatusEnum.Active;

  return (
    <div className={styles.container}>
      <Icon type={iconType} className={styles.icon} />
      {isConnected ? (
        <span className={styles.connected}>{`${label} ${firstName}`}</span>
      ) : (
        <span className={styles.connectionStatus}>{label}</span>
      )}
    </div>
  );
};

export default ConnectionStatus;
