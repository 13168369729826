import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { MissionCompanyRequest } from '@a_team/models/dist/MissionObject';
import { Colors, Card } from '@ateams/components';
import { splitToParagraphs } from '@src/helpers/strings';

interface Props {
  details: MissionCompanyRequest;
}

const useStyles = createUseStyles({
  root: {
    marginTop: 64,
  },
  title: {
    fontSize: '1.4em',
    fontWeight: 600,
    margin: '0 0 24px',

    '& > span': {
      fontSize: '1rem',
      fontWeight: 600,
      color: Colors.regular,
    },
  },
  dl: {
    margin: 0,
    padding: 0,

    '& > dt': {
      fontWeight: 500,
      float: 'left',
      width: '120px',
      margin: 0,
    },

    '& > dd': {
      display: 'block',
      margin: '0 0 8px 140px',

      '& > p': {
        margin: '0 0 8px',
      },
    },
  },
});

export default function CompanyRequestDetailsBox(props: Props): ReactElement {
  const { details } = props;
  const styles = useStyles();

  return (
    <Card className={styles.root}>
      <h4 className={styles.title}>
        Company Request <span>(Admin Only)</span>
      </h4>

      <dl className={styles.dl}>
        <dt>Company Name</dt>
        <dd>{details.companyName}</dd>

        <dt>Website URL</dt>
        <dd>
          <a
            href={details.websiteURL}
            target="_blank"
            rel="noreferrer noopener"
          >
            {details.websiteURL}
          </a>
        </dd>

        <dt>Full Name</dt>
        <dd>{details.fullName}</dd>

        <dt>Role</dt>
        <dd>{details.role}</dd>

        <dt>Email</dt>
        <dd>
          <a
            href={`mailto:${details.email}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            {details.email}
          </a>
        </dd>

        <dt>Phone</dt>
        <dd>{details.phoneNumber}</dd>

        <dt>Description</dt>
        <dd>{splitToParagraphs(details.description)}</dd>

        <dt>Notes</dt>
        <dd>{details.notes || '－'}</dd>
      </dl>
    </Card>
  );
}
