import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { UserTalentSkillAssignment } from '@a_team/models/dist/TalentCategories';
import { TextColors, Icon, IconType } from '@ateams/components';

const Skill = (props: { skill: UserTalentSkillAssignment }): ReactElement => {
  const { skill } = props;

  return (
    <span key={skill.talentSkillId} style={{ display: 'inline-block' }}>
      {`${skill.talentSkillName} `}
      <Icon type={IconType.Star} size="xsmall" />
      {`${skill.rating}`}
    </span>
  );
};

interface Props {
  matchesOn: string;
  requiredSkills: UserTalentSkillAssignment[];
  preferredSkills: UserTalentSkillAssignment[];
}

const useStyles = createUseStyles({
  skillsList: {
    '& > span': {
      borderLeft: `${TextColors.regular} solid 1px`,
      marginLeft: 5,
      paddingLeft: 5,
    },
    '& > span:first-child': {
      borderLeft: 0,
    },
  },
});

const MatchContainer = (props: Props): ReactElement => {
  const { matchesOn, requiredSkills, preferredSkills } = props;
  const styles = useStyles();

  return (
    <div>
      {matchesOn && (
        <div>
          <strong>Matches on: </strong>
          <span>{matchesOn}</span>
        </div>
      )}
      {!!requiredSkills.length && (
        <div>
          <strong>Required skills: </strong>
          <span className={styles.skillsList}>
            {requiredSkills.map((skill) => (
              <Skill key={skill.talentSkillId} skill={skill} />
            ))}
          </span>
        </div>
      )}
      {!!preferredSkills.length && (
        <div>
          <strong>Preferred skills: </strong>
          <span className={styles.skillsList}>
            {preferredSkills.map((skill) => (
              <Skill key={skill.talentSkillId} skill={skill} />
            ))}
          </span>
        </div>
      )}
    </div>
  );
};

export default MatchContainer;
