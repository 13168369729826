import React from 'react';
import { createUseStyles } from 'react-jss';
import CommonStyles from '../common/styles';

interface ExperienceProps {
  hasZeroToOneExperience?: boolean;
  hasManagedPeople?: boolean;
  numberOfPeopleManaged?: string;
}

const useStyles = createUseStyles({
  container: {
    marginTop: 64,
  },
  tags: {
    marginTop: 24,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
  },
  tag: {
    backgroundColor: '#F7F7F7',
    borderRadius: 6,
    padding: 16,
    fontSize: 14,
  },
});
const Experience = ({
  hasZeroToOneExperience,
  hasManagedPeople,
  numberOfPeopleManaged,
}: ExperienceProps): JSX.Element => {
  const commonStyles = CommonStyles();
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={commonStyles.title}>Experience</div>
      <div className={styles.tags}>
        {hasManagedPeople && numberOfPeopleManaged && (
          <div className={styles.tag}>
            I managed a team of {numberOfPeopleManaged} team members on this
            project
          </div>
        )}

        {hasZeroToOneExperience && (
          <div className={styles.tag}>
            I was involved from inception to launch (0 {'->'} 1)
          </div>
        )}
      </div>
    </div>
  );
};

export default Experience;
