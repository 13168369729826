import { Stores } from '@src/stores';
import { observable } from 'mobx';
import { apiVettingProcessV2 } from '@ateams/api';
import { VettingProcess } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { PlatformServiceAnalytics } from '@ateams/analytics/dist/platform';
import {
  VettingFeedbackFormDefaultsV2,
  VettingFeedbackSubmitFormV2,
} from '@ateams/api/dist/endpoints/vetting-process-feedback-v2';

export interface VettingProcessesV2StoreData {
  vettingProcesses?: VettingProcess[];
}

export default class VettingProcessesV2Store
  implements VettingProcessesV2StoreData
{
  @observable vettingProcesses: VettingProcessesV2StoreData['vettingProcesses'];

  private readonly rootStore: Stores;
  private analytics: PlatformServiceAnalytics;
  protected getVettingProcessesAbortController: AbortController | undefined;
  protected getVettingProcessesCountAbortController:
    | AbortController
    | undefined;

  public constructor(
    rootStore: Stores,
    analytics: PlatformServiceAnalytics,
    initialState?: VettingProcessesV2StoreData,
  ) {
    this.rootStore = rootStore;
    this.analytics = analytics;

    if (initialState) {
      this.vettingProcesses = initialState.vettingProcesses;
    }
  }

  public serialize(): VettingProcessesV2StoreData {
    return {};
  }

  public getFeedbackFormDefaults = async (
    id: string,
  ): Promise<VettingFeedbackFormDefaultsV2> => {
    return apiVettingProcessV2.getVettingFeedbackFormDefaults(
      this.rootStore.auth,
      id,
    );
  };

  public submitFeedbackForm = async (
    id: string,
    data: VettingFeedbackSubmitFormV2,
    files: File[],
  ): Promise<void> => {
    await apiVettingProcessV2.submitFeedbackForm(
      this.rootStore.auth,
      id,
      data,
      files,
    );
  };
}
