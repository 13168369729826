import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import TooltipWrapped from '@src/components/TooltipWrapped';
import 'react-tippy/dist/tippy.css';
import { format } from 'date-fns';
import UserAvatar, { BadgesPosition } from '@src/components/UserAvatar';
import {
  BasicVettingProcessColumnProps,
  AdminVettingProcessColumnProps,
} from '..';
import {
  BasicVettingProcess,
  UserOptedOut,
  VetterOptedOut,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { VettingProcessStatus } from '@a_team/models/dist/vetting-processes/status';

const useStyles = createUseStyles({
  '@global': {
    '.tippy-popper': {
      maxWidth: 'none',
    },
  },
  container: {
    marginRight: 'auto',
    marginLeft: '16px',
  },
  label: {
    textAlign: 'left',
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    padding: '8px',
  },
  spacing: {
    height: '8px',
  },
  redDot: {
    position: 'absolute',
    height: '18px',
    width: '18px',
    top: 'calc(50% - 24px)',
    left: '28px',
    background: 'red',
    borderRadius: '50%',
  },
});

function TooltipBuilderOptedOutHTML({
  builderOptedOut,
}: {
  builderOptedOut: UserOptedOut;
}) {
  const styles = useStyles();

  return (
    <div className={styles.tooltipContainer}>
      <div>{`User had opted out on ${format(
        new Date(builderOptedOut.date as Date),
        'MMM dd, yyyy',
      )} by ${builderOptedOut.markedBy?.fullName}`}</div>
      <div className={styles.spacing} />
      <div>{`Reason: ${builderOptedOut.reason}`}</div>
    </div>
  );
}

function TooltipVetterOptedOutHTML({
  vetterOptedOut,
}: {
  vetterOptedOut: VetterOptedOut;
}) {
  const styles = useStyles();

  return (
    <div className={styles.tooltipContainer}>
      <div>{`${
        vetterOptedOut.vetter.fullName
      } opted out of interview on ${format(
        new Date(vetterOptedOut.date),
        'MMM dd, yyyy HH:mm',
      )}`}</div>
      <div className={styles.spacing} />
      <div>{`Reason: ${vetterOptedOut.reason}`}</div>
    </div>
  );
}

export const NameWithOptOutIndication: React.FC<
  AdminVettingProcessColumnProps
> = ({ vettingProcess }) => {
  const shouldShowVetterOptedOutTooltip =
    vettingProcess.vetterOptedOut?.length && !vettingProcess.vetter;
  const shouldShowBuilderOptedOutTooltip =
    vettingProcess.userOptedOut?.vetter &&
    !vettingProcess.vetter &&
    vettingProcess.status !== VettingProcessStatus.OnHold;

  const vetterOptedOutRecord =
    vettingProcess.vetterOptedOut?.[vettingProcess.vetterOptedOut.length - 1];
  const builderOptedOutRecord = vettingProcess.userOptedOut?.vetter;

  const tooltipHTML = useMemo(() => {
    if (shouldShowBuilderOptedOutTooltip) {
      return (
        <TooltipBuilderOptedOutHTML
          builderOptedOut={builderOptedOutRecord as UserOptedOut}
        />
      );
    }

    if (shouldShowVetterOptedOutTooltip) {
      return (
        <TooltipVetterOptedOutHTML
          vetterOptedOut={vetterOptedOutRecord as VetterOptedOut}
        />
      );
    }

    return undefined;
  }, [vettingProcess]);

  if (!shouldShowVetterOptedOutTooltip && !shouldShowBuilderOptedOutTooltip) {
    return <NameWithAvatar vettingProcess={vettingProcess} />;
  }

  return (
    <TooltipWrapped trigger="mouseenter" arrow theme="light" html={tooltipHTML}>
      {/* <TooltipWrapped /> and <Link /> behave poorly together because <Link> has lower height then it's content, this div is used to fix it */}
      <div>
        <NameWithAvatar vettingProcess={vettingProcess} showRedDot />
      </div>
    </TooltipWrapped>
  );
};

export const NameField: React.FC<BasicVettingProcessColumnProps> = ({
  vettingProcess,
}) => {
  return <NameWithAvatar vettingProcess={vettingProcess} />;
};

interface NameWithAvatarProps {
  vettingProcess: BasicVettingProcess;
  showRedDot?: boolean;
}

export const NameWithAvatar: React.FC<NameWithAvatarProps> = ({
  vettingProcess,
  showRedDot,
}) => {
  const styles = useStyles();

  return (
    <Link
      to={{ pathname: vettingProcess.user.profileURL }}
      target="_blank"
      className={styles.container}
    >
      <UserAvatar
        src={vettingProcess.user.profilePictureURL}
        label={vettingProcess.user.fullName}
        badges={vettingProcess.user.badges}
        labelClassName={styles.label}
        size={46}
        badgesPosition={BadgesPosition.BelowName}
      >
        {showRedDot && <div className={styles.redDot}></div>}
      </UserAvatar>
    </Link>
  );
};
