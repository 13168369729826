import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Spacing } from '@ateams/components';
import Modal, { ModalBasicProps } from '@src/components/Modal';
import { CallToActionButton, CallToActionButtonType } from './cta-button';
import { Size, Text } from '../components/typography';

export interface UnsavedChangesModalProps extends ModalBasicProps {
  onSave: () => void;
  onDiscard: () => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    padding: '40px',
  },
  title: {
    marginBottom: Spacing.small,
  },
  description: {
    color: '#818388',
    marginBottom: '40px',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    height: '40px',
    width: '200px',
  },
  discardChangesButton: {
    marginRight: '12px',
  },
});

/**
 * Figma: {@link https://www.figma.com/file/fiIpGWCIUv1WVZWVn7wwP6/Admin-side%3A-Vetting?node-id=2945%3A847&t=pRBQ28URne5JjAQg-0}
 */
export const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = (
  props,
) => {
  const { onClose, onSave, onDiscard, ...rest } = props;
  const styles = useStyles();

  return (
    <Modal
      onClose={onClose}
      style={{ padding: 0, width: '680px' }} // using className doesn't work here
      {...rest}
    >
      <div className={styles.container}>
        <Text size={Size.ExtraLarge} className={styles.title}>
          {'Are you sure you want to leave this page?'}
        </Text>
        <Text size={Size.Medium} className={styles.description}>
          {'If you leave without saving, your changes will be discarded.'}
        </Text>
        <div className={styles.actionsContainer}>
          <CallToActionButton
            ctaType={CallToActionButtonType.Secondary}
            onClick={onDiscard}
            className={cx(styles.button, styles.discardChangesButton)}
          >
            {'Discard changes'}
          </CallToActionButton>
          <CallToActionButton
            ctaType={CallToActionButtonType.Primary}
            onClick={onSave}
            className={styles.button}
          >
            {'Save changes'}
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
};
