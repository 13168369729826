import React, { ReactElement } from 'react';
import { Breakpoints } from '@ateams/components';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactElement | ReactElement[];
}

const useStyles = createUseStyles({
  root: {
    height: 'min-content',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '& > *': {
      flexGrow: 1,
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      marginBottom: '24px',
      justifyContent: 'space-between',
      '& > *': {
        flexGrow: 'unset',
      },
    },
  },
});

export default function DropdownContainer(props: Props): ReactElement {
  const { children } = props;
  const styles = useStyles();

  return <div className={styles.root}>{children}</div>;
}
