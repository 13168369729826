import React from 'react';
import { useCommonStyles } from '../commonStyles';
import { createUseStyles } from 'react-jss';
import { SkillItem } from './SkillItem';
import { useFormContext } from 'react-hook-form';
import {
  BorderColors,
  Colors,
  BorderRadius,
  Spacing,
  FontSizes,
  FontWeights,
  SelectOption,
} from '@ateams/components';
import { SkillSelector } from '@src/components/SkillSelector';
import { FieldTextarea } from '../general/FieldTextarea';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  internalContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    backgroundColor: Colors.backgroundLight,
    borderRadius: BorderRadius.xLarge,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  skillsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    border: `1px solid ${BorderColors.light}`,
    borderRadius: BorderRadius.xLarge,
    padding: Spacing.medium,
    backgroundColor: Colors.backgroundWhite,
  },
  skillHeader: {
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.medium,
    color: Colors.dark,
  },
  skillSelector: {
    width: '360px',
    backgroundColor: Colors.transparent,
    padding: 0,
    marginTop: Spacing.medium,
  },
  impressionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    padding: Spacing.medium,
    paddingTop: 0,
  },
});

export interface Skill {
  id: string;
  name: string;
  score: number;
  required: boolean;
  addedFromInterviewer?: boolean;
  notes: string;
}

export interface SkillsSectionProps {
  talentCategoryIds: string[];
  isViewMode: boolean;
}

export const SkillsSection = ({
  talentCategoryIds,
  isViewMode,
}: SkillsSectionProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const {
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const skills: Skill[] = watch('skills');
  const skillsImpressions: string = watch('skillsImpressions');

  const onSkillItemChange = (updatedSkill: Skill) => {
    const updatedSkills = skills.map((s) =>
      s.id === updatedSkill.id ? updatedSkill : s,
    );
    setValue('skills', updatedSkills);
  };

  const onSkillItemDelete = (skillId: string) => {
    const updatedSkills = skills.filter((s) => s.id !== skillId);
    setValue('skills', updatedSkills);
  };

  const onSkillSelectorChange = (
    skillIds: string[],
    allSkills: SelectOption[] | undefined,
  ) => {
    const skillsToAdd = skillIds.filter(
      (skillId) => !skills.some((s) => s.id === skillId),
    );
    const skillsToRemove = skills.filter(
      (skill) => !skillIds.includes(skill.id) && skill.addedFromInterviewer,
    );

    const updatedSkills: Skill[] = [
      ...skills.filter((skill) => !skillsToRemove.includes(skill)),
      ...skillsToAdd.map((skillId) => ({
        id: skillId,
        name: allSkills?.find((s) => s.value === skillId)?.label || '',
        score: 0,
        required: false,
        addedFromInterviewer: true,
        notes: '',
      })),
    ];

    setValue('skills', updatedSkills);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={commonStyles.sectionTitle}>Skills</div>
        <div className={commonStyles.sectionDescription}>
          Rate the builder's skills from 1 to 5, with 5 being deep expertise and
          1 being basic familiarity. Ask technical questions to verify their
          proficiency. Skills relevant to specific missions applied for are
          highlighted in blue. In the comments, mention level of proficiency,
          how recent is their experience, the complexity of projects in which
          the skill was used, etc.
        </div>
      </div>
      <div className={styles.internalContainer}>
        <div className={styles.skillsContainer}>
          <div className={commonStyles.skill}>
            <div className={styles.skillHeader}>Skill</div>
            <div className={styles.skillHeader}>Level</div>
            <div className={styles.skillHeader}>
              Specify the reasons for the skill rating *
            </div>
          </div>
          {skills.map((skill) => (
            <SkillItem
              key={skill.id}
              skill={skill}
              onChange={onSkillItemChange}
              onDelete={() => onSkillItemDelete(skill.id)}
              disabled={isViewMode}
            />
          ))}

          {!isViewMode && (
            <SkillSelector
              type="all"
              value={skills.map((skill) => ({
                value: skill.id,
                label: skill.name,
              }))}
              talentCategoryIds={talentCategoryIds}
              onChange={onSkillSelectorChange}
              hideTags={true}
              className={styles.skillSelector}
            />
          )}
        </div>
        <div className={styles.impressionsContainer}>
          <FieldTextarea
            label="Add your impressions"
            placeholder="Add additional context about the skills"
            value={skillsImpressions}
            onChange={(value) => setValue('skillsImpressions', value)}
            minLength={40}
            error={errors.skillsImpressions?.message as string}
            name="skillsImpressions"
            clearErrors={clearErrors}
            disabled={isViewMode}
          />
        </div>
      </div>
    </div>
  );
};
