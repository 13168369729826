import React from 'react';
import { createUseStyles } from 'react-jss';
import { theme } from '@a_team/ui-components';
import { Size, Text } from '../../components/typography';
import { NumberValueTag } from '../../components/value-tag';

const MAX_EST_HOURS_OVERLAP = 8;

function getHoursOfOverlapWithESTColor(hoursOfOverlapWithEST: number) {
  if (hoursOfOverlapWithEST <= 2) {
    return theme.colors.Red[200];
  }
  if (hoursOfOverlapWithEST <= 4) {
    return theme.colors.Baracus[200];
  }
  if (hoursOfOverlapWithEST <= 6) {
    return '#AEE2FF';
  }

  return theme.colors.Green[200];
}

const useStyles = createUseStyles({
  estHoursOverlapValue: {
    marginRight: '4px',
    '&:last-child': {
      marginRight: 0,
    },
  },
});

export type OnESTHoursOverlapChange = (
  hoursOfOverlapWithEST: number | undefined,
) => void;

export interface ESTHoursOverlapInputProps {
  value?: number | undefined;
  onChange?: OnESTHoursOverlapChange;
  isReadOnly?: boolean;
  className?: string;
}

export const ESTHoursOverlapInput: React.FC<ESTHoursOverlapInputProps> = (
  props,
) => {
  const { isReadOnly, value } = props;
  const styles = useStyles();

  const onHoursOfOverlapWithESTChange = (
    tickedHoursOfOverlapWithEST: number,
  ) => {
    const hoursOfOverlapWithEST =
      tickedHoursOfOverlapWithEST !== value
        ? tickedHoursOfOverlapWithEST
        : undefined;
    props.onChange?.(hoursOfOverlapWithEST);
  };

  if (isReadOnly) {
    const hasValue = typeof value === 'number';
    const label = hasValue ? `${value} hours` : undefined;

    return (
      <div className={props.className}>
        <Text size={Size.Small} isReadOnly>
          {label}
        </Text>
      </div>
    );
  }

  return (
    <div className={props.className}>
      {new Array(MAX_EST_HOURS_OVERLAP).fill(null).map((_, i) => {
        const hoursOfOverlap = i + 1;

        return (
          <NumberValueTag
            key={hoursOfOverlap}
            className={styles.estHoursOverlapValue}
            color={
              value === hoursOfOverlap
                ? getHoursOfOverlapWithESTColor(value)
                : undefined
            }
            onClick={() => onHoursOfOverlapWithESTChange(hoursOfOverlap)}
          >
            {hoursOfOverlap}
          </NumberValueTag>
        );
      })}
    </div>
  );
};
