import React from 'react';
import { createUseStyles } from 'react-jss';
import { FontSizes, FontWeights, Spacing } from '@ateams/components';
import BuilderVisibility from './AccountSettings/BuilderVisibility';
import ClientVisibility from './AccountSettings/ClientVisibility';

const useStyles = createUseStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.xxxLarge,
  },
  title: {
    fontSize: FontSizes.extraLarge,
    fontWeight: FontWeights.semiBold,
  },
  subTitle: {
    fontSize: FontSizes.regular,
    marginTop: Spacing.small,
  },
});

export const PrivacyView = () => {
  const styles = useStyles();

  return (
    <div className={styles.mainContainer}>
      <div>
        <div className={styles.title}>Privacy</div>
        <div className={styles.subTitle}>
          Manage your profile visibility for clients and builders.
        </div>
      </div>
      <ClientVisibility />
      <BuilderVisibility />
    </div>
  );
};
