import { Stores } from '../stores';
import { match } from 'react-router-dom';
import { Location } from 'history';
import { TeamMemberRole } from '@a_team/models/dist/TeamObject';

// exported functions

export function loadRegistration(
  stores: Stores,
  match: match,
  location: Location,
): Promise<unknown> {
  const { registration } = stores;
  const params = new URLSearchParams(location.search);
  const onboardingToken =
    params.get('onboardingToken') ||
    registration.getLocalStorageOnboardingToken();
  const asTeamParam = params.get(registration.APPLY_AS_TEAM_URL_PARAM);
  const paramsMap = {
    asTeam: '1',
    asIndividual: '0',
  };

  if (asTeamParam) {
    // Param can be "0" or "1"
    registration.redirectApplyAsTeam(
      asTeamParam === paramsMap.asTeam ? TeamMemberRole.Owner : undefined,
    );
  }

  if (!onboardingToken) {
    return Promise.resolve();
  }

  return registration.loginReturningRegisteredUser(onboardingToken);
}
