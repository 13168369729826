import React from 'react';

export const CalendlyIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M10.7616 10.3752C10.2536 10.8261 9.61923 11.387 8.46894 11.387H7.78109C6.94869 11.387 6.19159 11.0845 5.64968 10.5361C5.12031 10.0002 4.82891 9.26678 4.82891 8.47064V7.52949C4.82891 6.73335 5.12031 5.99996 5.64968 5.46408C6.19159 4.91566 6.94869 4.61357 7.78109 4.61357H8.46894C9.62062 4.61357 10.2536 5.17407 10.7616 5.62489C11.2891 6.08966 11.7441 6.49586 12.9576 6.49586C13.1427 6.49581 13.3275 6.48105 13.5102 6.45171L13.506 6.44102C13.4334 6.26052 13.3481 6.08533 13.2509 5.91676L12.4399 4.51178C12.0742 3.87842 11.5481 3.35248 10.9147 2.98682C10.2813 2.62117 9.56283 2.42868 8.83145 2.42871H7.20896C6.47759 2.42868 5.75909 2.62117 5.12568 2.98682C4.49227 3.35248 3.96626 3.87842 3.60053 4.51178L2.78952 5.91676C2.42392 6.5502 2.23145 7.26869 2.23145 8.00007C2.23145 8.73144 2.42392 9.44994 2.78952 10.0834L3.60053 11.4884C3.96626 12.1217 4.49227 12.6477 5.12568 13.0133C5.75909 13.379 6.47759 13.5715 7.20896 13.5714H8.83145C9.56283 13.5715 10.2813 13.379 10.9147 13.0133C11.5481 12.6477 12.0742 12.1217 12.4399 11.4884L13.2509 10.0857C13.3481 9.91713 13.4334 9.74194 13.506 9.56144L13.5102 9.55122C13.3276 9.52088 13.1428 9.50533 12.9576 9.50474C11.7441 9.50474 11.2891 9.90862 10.7616 10.3757"
        fill="white"
      />
      <path
        d="M8.46883 5.46582H7.78098C6.51403 5.46582 5.68164 6.37072 5.68164 7.52891V8.47005C5.68164 9.62825 6.51403 10.5331 7.78098 10.5331H8.46883C10.3149 10.5331 10.1699 8.65085 12.9575 8.65085C13.2216 8.65055 13.4852 8.67482 13.7448 8.72335C13.8294 8.24448 13.8294 7.75448 13.7448 7.27561C13.4852 7.32391 13.2216 7.34818 12.9575 7.34811C10.1689 7.34811 10.3149 5.46582 8.46883 5.46582Z"
        fill="white"
      />
      <path
        d="M15.346 9.41172C14.8713 9.06403 14.3238 8.82867 13.7449 8.7234C13.7449 8.72852 13.7449 8.7327 13.7449 8.73735C13.6951 9.01485 13.6172 9.28658 13.5125 9.54836C13.9908 9.62242 14.4445 9.8093 14.8362 10.0935C14.8362 10.0972 14.8338 10.1019 14.8324 10.1061C14.3283 11.7384 13.2554 13.1358 11.8087 14.0444C10.3619 14.9529 8.63719 15.3124 6.94791 15.0575C5.25862 14.8026 3.71681 13.9502 2.60254 12.6552C1.48828 11.3601 0.875487 9.70841 0.875487 8C0.875487 6.29159 1.48828 4.63985 2.60254 3.34483C3.71681 2.04981 5.25862 1.19743 6.94791 0.942518C8.63719 0.687609 10.3619 1.04709 11.8087 1.95565C13.2554 2.86421 14.3283 4.26158 14.8324 5.89392C14.8324 5.8981 14.8348 5.90275 14.8362 5.90647C14.4446 6.19074 13.9908 6.37747 13.5125 6.45117C13.6171 6.71332 13.6949 6.98534 13.7449 7.26312C13.7446 7.26745 13.7446 7.2718 13.7449 7.27613C14.3238 7.17103 14.8713 6.93583 15.346 6.58828C15.8024 6.2504 15.7141 5.86882 15.6449 5.64295C15.0806 3.81276 13.8784 2.24574 12.2569 1.22663C10.6353 0.207528 8.70199 -0.196029 6.80816 0.089295C4.91433 0.374619 3.1857 1.32989 1.93639 2.78152C0.687081 4.23315 0 6.0848 0 8C0 9.9152 0.687081 11.7669 1.93639 13.2185C3.1857 14.6701 4.91433 15.6254 6.80816 15.9107C8.70199 16.196 10.6353 15.7925 12.2569 14.7734C13.8784 13.7543 15.0806 12.1872 15.6449 10.357C15.7141 10.1312 15.8024 9.7496 15.346 9.41172Z"
        fill="white"
      />
      <path
        d="M13.5099 6.45152C13.3273 6.48186 13.1425 6.4974 12.9573 6.49799C11.7438 6.49799 11.2888 6.09411 10.7618 5.62703C10.2533 5.1762 9.62033 4.61523 8.46864 4.61523H7.78079C6.94794 4.61523 6.1913 4.9178 5.64939 5.46622C5.12002 6.00209 4.82861 6.73549 4.82861 7.53163V8.47277C4.82861 9.26892 5.12002 10.0023 5.64939 10.5382C6.1913 11.0866 6.94794 11.3887 7.78079 11.3887H8.46864C9.62033 11.3887 10.2533 10.8282 10.7618 10.3774C11.2888 9.91261 11.7438 9.50641 12.9573 9.50641C13.1424 9.50645 13.3272 9.52122 13.5099 9.55056C13.6148 9.28885 13.6927 9.0171 13.7423 8.73955C13.7426 8.73491 13.7426 8.73025 13.7423 8.72561C13.4827 8.67746 13.2191 8.65335 12.955 8.65357C10.1664 8.65357 10.3124 10.5363 8.46632 10.5363H7.77847C6.51152 10.5363 5.67913 9.63097 5.67913 8.47277V7.52977C5.67913 6.37158 6.51152 5.46668 7.77847 5.46668H8.46632C10.3124 5.46668 10.1674 7.34851 12.955 7.34851C13.2191 7.34896 13.4827 7.32484 13.7423 7.27647C13.7423 7.27229 13.7423 7.26811 13.7423 7.26346C13.6924 6.98569 13.6145 6.71366 13.5099 6.45152Z"
        fill="#006BFF"
      />
      <path
        d="M13.5099 6.45152C13.3273 6.48186 13.1425 6.4974 12.9573 6.49799C11.7438 6.49799 11.2888 6.09411 10.7618 5.62703C10.2533 5.1762 9.62033 4.61523 8.46864 4.61523H7.78079C6.94794 4.61523 6.1913 4.9178 5.64939 5.46622C5.12002 6.00209 4.82861 6.73549 4.82861 7.53163V8.47277C4.82861 9.26892 5.12002 10.0023 5.64939 10.5382C6.1913 11.0866 6.94794 11.3887 7.78079 11.3887H8.46864C9.62033 11.3887 10.2533 10.8282 10.7618 10.3774C11.2888 9.91261 11.7438 9.50641 12.9573 9.50641C13.1424 9.50645 13.3272 9.52122 13.5099 9.55056C13.6148 9.28885 13.6927 9.0171 13.7423 8.73955C13.7426 8.73491 13.7426 8.73025 13.7423 8.72561C13.4827 8.67746 13.2191 8.65335 12.955 8.65357C10.1664 8.65357 10.3124 10.5363 8.46632 10.5363H7.77847C6.51152 10.5363 5.67913 9.63097 5.67913 8.47277V7.52977C5.67913 6.37158 6.51152 5.46668 7.77847 5.46668H8.46632C10.3124 5.46668 10.1674 7.34851 12.955 7.34851C13.2191 7.34896 13.4827 7.32484 13.7423 7.27647C13.7423 7.27229 13.7423 7.26811 13.7423 7.26346C13.6924 6.98569 13.6145 6.71366 13.5099 6.45152Z"
        fill="#006BFF"
      />
    </svg>
  );
};
