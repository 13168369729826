import notSelected from './notSelected.svg';
import selected from './selected.svg';
import React, { CSSProperties, ReactElement } from 'react';
import { Item } from '@src/components/RadioGroup/index';

export type OnRadioButtonClick = (item: Item) => void;

interface Props {
  item: Item;
  checked?: boolean;
  disabled?: boolean;
  onClick: OnRadioButtonClick;
  className?: string;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  'data-testing-id'?: string;
}

export const RadioButton = (props: Props): ReactElement => {
  const {
    item,
    onClick,
    checked = false,
    disabled = false,
    className,
    style,
    labelStyle,
  } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        cursor: 'pointer',
        marginBottom: 10,
        ...(disabled && {
          opacity: 0.3,
          pointerEvents: 'none',
        }),
        ...style,
      }}
      className={className}
      onClick={() => onClick(item)}
      data-testing-id={props['data-testing-id']}
    >
      <img
        src={checked ? selected : notSelected}
        alt="selected"
        style={{ marginRight: 8 }}
      />
      <div
        style={{
          lineHeight: '24px',
          ...labelStyle,
        }}
      >
        {item.label}
      </div>
    </div>
  );
};
