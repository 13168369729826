import React from 'react';
import { createUseStyles } from 'react-jss';
import Badge from '../Badge';
import cx from 'classnames';
import { TimesheetInitiativeId } from '@a_team/models/dist/TimesheetInitiativeObject';

interface SelectAndHideOptionProps {
  siid: TimesheetInitiativeId;
  value: string;
  children: React.ReactNode;
  innerProps: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  innerRef: SelectAndHideOptionProps['innerProps']['ref'];
  isFocused: boolean;
  bgColor?: string;
  color?: string;
  onHideTimesheet: (siid: TimesheetInitiativeId) => void;
  isHidden?: boolean;
  inputValue: string;
}

const useStyles = createUseStyles({
  container: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  focused: {
    backgroundColor: '#F7F7F7',
  },
  hide: {
    color: '#818388',
    fontSize: 12,
    cursor: 'pointer',
  },
});

const SelectAndHideOption = ({
  siid,
  value,
  innerProps,
  innerRef,
  children,
  isFocused,
  bgColor,
  color,
  onHideTimesheet,
  isHidden,
  inputValue,
}: SelectAndHideOptionProps) => {
  const styles = useStyles();

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={cx(styles.container, { [styles.focused]: isFocused })}
    >
      {value !== '-1' ? (
        <>
          <Badge bgColor={bgColor} color={color}>
            {children}
          </Badge>
          {!isHidden && (
            <span
              className={styles.hide}
              onClick={(e) => {
                e.stopPropagation();
                onHideTimesheet(siid);
              }}
            >
              Hide
            </span>
          )}
        </>
      ) : (
        <div
          style={{
            padding: 10,
            display: 'flex',
            gap: 10,
            alignItems: 'center',
            cursor: 'pointer',
            width: '100%',
          }}
        >
          Create
          <Badge bgColor="#FFF3C7" color="#222222">
            {inputValue}
          </Badge>
        </div>
      )}
    </div>
  );
};

export default SelectAndHideOption;
