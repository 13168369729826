import {
  InteractionExperienceLabels,
  AdminNotesScore,
} from '@a_team/models/dist/AdminNotesObject';
import React from 'react';
import { BasicVettingProcessColumnProps } from '..';

export const InteractionExperienceField: React.FC<
  BasicVettingProcessColumnProps
> = ({ vettingProcess }) => {
  const interactionExperience =
    vettingProcess.feedbackV2?.answers.scores.interactionExperience ??
    vettingProcess.feedback?.answers.scores.interactionExperience;

  if (typeof interactionExperience !== 'number') {
    return null;
  }

  return (
    <>{InteractionExperienceLabels[interactionExperience as AdminNotesScore]}</>
  );
};
