import React from 'react';
import { createUseStyles } from 'react-jss';
import UserAvatar from '@src/components/UserAvatar';
import { format, parseISO } from 'date-fns';
import {
  TranscriptData,
  TranscriptType,
} from '@ateams/api/dist/endpoints/Transcripts';
import { Colors, FontSizes, FontWeights, Spacing } from '@ateams/components';

const useStyles = createUseStyles({
  transcriptHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: Spacing.medium,
    paddingTop: Spacing.xLarge,
    paddingBottom: Spacing.xLarge,
  },
  transcriptTitle: {
    fontSize: FontSizes.extraLarge,
    fontWeight: FontWeights.semiBold,
    color: Colors.dark,
  },
  interviewerInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.small,
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.regular,
    color: Colors.regularDark,
  },
  missionNameAndRole: {
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.regular,
    color: Colors.regularDark,
  },
});

interface TranscriptHeaderProps {
  transcript: TranscriptData;
}

const TranscriptHeader: React.FC<TranscriptHeaderProps> = ({ transcript }) => {
  const styles = useStyles();

  return (
    <div className={styles.transcriptHeader}>
      {transcript.type === TranscriptType.Interview && (
        <UserAvatar
          size={24}
          src={transcript.organizer.pictureURL}
          label={transcript.organizer.name}
        />
      )}
      {transcript.type === TranscriptType.Evaluation ? (
        <div
          className={styles.transcriptTitle}
        >{`${transcript.attendee.name} evaluation transcript`}</div>
      ) : transcript.type === TranscriptType.Interview ? (
        <div
          className={styles.transcriptTitle}
        >{`Interview with ${transcript.companyName} transcript`}</div>
      ) : transcript.type === TranscriptType.DiscoverInterview ? (
        'Discover Interview'
      ) : (
        'Unknown'
      )}
      {transcript.type === TranscriptType.Evaluation ? (
        <div className={styles.interviewerInfo}>
          {`Interviewed by`}

          <UserAvatar
            size={24}
            src={transcript.organizer.pictureURL}
            label={transcript.organizer.name}
          />
        </div>
      ) : (
        <div className={styles.missionNameAndRole}>
          {`${transcript.missionName}  ·  ${transcript.roleName}`}
        </div>
      )}
      <div>{format(parseISO(transcript.interviewDate), 'dd MMM yyyy')}</div>
    </div>
  );
};

export default TranscriptHeader;
