import React, {
  ReactElement,
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react';
import { SidebarSection } from '@src/views/Profile/Sidebar/SidebarSection';
import { createUseStyles } from 'react-jss';
import {
  Breakpoints,
  Icon,
  IconType,
  Select,
  SelectProps,
  TextColors,
} from '@ateams/components';
import { WorkingHoursSchema } from '@a_team/models/dist/WorkingHoursObject';
import {
  getHoursFromMinutes,
  getWorkingHoursTimeOptions,
} from '@src/views/Profile/Sidebar/WorkingHours/utils';
import TimezoneObject from '@a_team/models/dist/TimezoneObject';
import TextButton from '@src/components/TextButton';

interface SelectOption {
  value: string;
  label: string;
}
interface Props {
  onChange(data: WorkingHoursSchema | undefined, isDefault?: boolean): void;
  selectInputStyle?: SelectProps['style'];
  workingHours?: WorkingHoursSchema;
  defaultValue?: WorkingHoursSchema;
  defaultTimezone?: TimezoneObject;
  hideSideBar?: boolean;
  sideBarLabel?: string;
  maxCount?: number;
  readonly?: boolean;
  isClearable?: boolean;
  error?: boolean;
  disabled?: boolean;
}

const useStyles = createUseStyles({
  workingHours: {
    minWidth: '320px',
  },
  control: {
    marginTop: 12,
  },
  valueReadonly: {
    margin: 0,
    fontSize: 17,
    color: TextColors.regular,
  },
  selectInput: (props: Props) => {
    return {
      '&>div>div': props?.selectInputStyle || {},
    };
  },
  description: {
    marginTop: 4,
    color: TextColors.regularLight,
    fontSize: 12,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    control: {
      marginTop: 0,
    },
  },
});

type SelectRef = SelectProps['ref'];
export const WorkingHours = (props: Props): ReactElement => {
  const styles = useStyles(props);
  const {
    readonly,
    onChange,
    workingHours,
    hideSideBar,
    sideBarLabel,
    error,
    disabled,
    isClearable = true,
    maxCount = 2,
    defaultTimezone,
    defaultValue = {
      name: defaultTimezone?.name ?? '',
      utcOffset: defaultTimezone?.utcOffset ?? 0,
      daily: [
        {
          startTime: 510, // 830
          endTime: 1170, // 1900
        },
      ],
    },
  } = props;

  const userHasWorkingHours = useMemo(() => {
    return !!workingHours?.name;
  }, [workingHours?.name]);

  const [workingHoursState, setWorkingHoursState] = useState(
    workingHours ?? defaultValue,
  );

  const selectRefs: Record<string, SelectRef> = {
    '0-from': useRef<SelectRef>(),
    '0-to': useRef<SelectRef>(),
    '1-from': useRef<SelectRef>(),
    '1-to': useRef<SelectRef>(),
  };

  const addWorkingHours = () => {
    const newWorkingHours = { ...workingHoursState };
    let firstEndTime = 0;
    if (newWorkingHours?.daily && newWorkingHours?.daily?.length > 0) {
      firstEndTime = newWorkingHours.daily[0].endTime;
      // in case of the addition is more than 24 hours - reset the time to 0 to start from 510
      if (firstEndTime + 100 > 1440 * 2) {
        firstEndTime = 0;
      }
    }
    newWorkingHours?.daily?.push({
      startTime: firstEndTime ? firstEndTime + 60 : 510,
      endTime: firstEndTime ? firstEndTime + 90 : 1170,
    });
    setWorkingHoursState(newWorkingHours);
    onChange(newWorkingHours);
  };

  const removeWorkingHours = (index: number) => {
    const newWorkingHours = { ...workingHoursState };
    newWorkingHours?.daily?.splice(index, 1);
    setWorkingHoursState(newWorkingHours);
    onChange(newWorkingHours);
  };

  useEffect(() => {
    if (!workingHours?.name && defaultValue) {
      setWorkingHoursState(defaultValue);
      onChange(defaultValue, true);
    }
  }, [workingHours?.name]);

  const setWorkingHours = (
    index: number,
    value: string,
    isStartTime?: boolean,
  ) => {
    const newWorkingHours = { ...workingHoursState };
    const daily = newWorkingHours?.daily || [];
    if (isStartTime) {
      daily[index].startTime = Number(value.replace(':', ''));
    } else {
      daily[index].endTime = Number(value.replace(':', ''));
    }
    if (daily[index].startTime > daily[index].endTime) {
      daily[index].endTime = daily[index].startTime + 30;
    }
    setWorkingHoursState(newWorkingHours);
    onChange(newWorkingHours);
  };

  const goToArticle = () => {
    window.open(
      'https://guide.a.team/getting-started/building-a-stellar-profile',
    );
  };

  const workingHoursElement = (
    <div className={styles.control}>
      {readonly ? (
        <>
          {userHasWorkingHours && (
            <>
              {workingHoursState?.daily?.map((daily, index) => {
                return (
                  <div key={`${daily.startTime}-${daily.endTime}`}>
                    <div className={styles.valueReadonly}>
                      From {getHoursFromMinutes(daily.startTime)} {' to '}
                      {getHoursFromMinutes(daily.endTime)}
                    </div>
                    {(daily.startTime > 1440 || daily.endTime > 1440) && (
                      <div className={''}>
                        These working hours continue to the next day.
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </>
      ) : (
        <>
          {workingHoursState?.daily?.map((daily, i) => {
            const { startTime, endTime } = daily;
            let previousStartTime = 0;
            let previousEndTime = 0;
            if (
              workingHoursState?.daily &&
              workingHoursState?.daily.length > 0
            ) {
              previousStartTime =
                workingHoursState?.daily[i - 1]?.startTime || 0;
              previousEndTime = workingHoursState?.daily[i - 1]?.endTime || 0;
            }
            const workingHoursOptionsStart = getWorkingHoursTimeOptions({
              startingMinutes: previousEndTime,
            });
            const workingHoursOptionsEnd = getWorkingHoursTimeOptions({
              startingMinutes: startTime,
            });
            return (
              <div key={i}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <Select
                    className={styles.selectInput}
                    selectRef={selectRefs[`${i}-from`]}
                    defaultValue={workingHoursOptionsStart.find(
                      (e) => Number(e.value) === startTime,
                    )}
                    isOptionDisabled={(option: SelectOption) => {
                      if (!option) return false;
                      return Number(option.value) < previousEndTime;
                    }}
                    hideSelectedOptions={false}
                    options={workingHoursOptionsStart}
                    placeholder={'Start Time'}
                    onChange={(value) => {
                      setWorkingHours(i, value?.value ?? '', true);
                      selectRefs[`${i}-to`].current?.select.selectOption(null);
                    }}
                    margin={'none'}
                    isDisabled={disabled}
                    isClearable={isClearable}
                  />

                  <div
                    style={{
                      margin: '0 15px',
                    }}
                  >
                    <Icon type={IconType.HorizontalInLineDivider} />
                  </div>

                  <Select
                    className={styles.selectInput}
                    selectRef={selectRefs[`${i}-to`]}
                    defaultValue={workingHoursOptionsEnd.find(
                      (e) => Number(e.value) === endTime,
                    )}
                    isOptionDisabled={(option: SelectOption) => {
                      if (!option) return false;
                      return (
                        Number(option?.value) <= startTime ||
                        Number(option?.value) < previousStartTime
                      );
                    }}
                    hideSelectedOptions={false}
                    options={workingHoursOptionsEnd}
                    placeholder={'End Time'}
                    onChange={(value) => {
                      setWorkingHours(i, value?.value ?? '', false);
                    }}
                    margin={'none'}
                    isDisabled={disabled}
                    isClearable={isClearable}
                  />
                  <div>
                    {!workingHoursState?.daily ||
                    workingHoursState?.daily?.length < maxCount ? (
                      <Icon
                        title={false}
                        type={IconType.PlusPurple}
                        onClick={addWorkingHours}
                        style={{
                          marginLeft: '12px',
                          opacity: disabled ? 0.5 : 1,
                          pointerEvents: disabled ? 'none' : 'auto',
                        }}
                      />
                    ) : (
                      <>
                        {maxCount > 1 && (
                          <Icon
                            title={false}
                            type={IconType.TrashRed}
                            onClick={() => removeWorkingHours(i)}
                            style={{
                              marginLeft: '12px',
                              opacity: disabled ? 0.5 : 1,
                              pointerEvents: disabled ? 'none' : 'auto',
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
                {(startTime > 1440 || endTime > 1440) && (
                  <div>These working hours continue to the next day.</div>
                )}
              </div>
            );
          })}
        </>
      )}
    </div>
  );

  return (
    <div className={styles.workingHours}>
      {hideSideBar ? (
        workingHoursElement
      ) : (
        <SidebarSection
          title={
            userHasWorkingHours ? sideBarLabel ?? 'Working hours range' : ''
          }
          withAddIcon={false}
          error={error}
        >
          <>
            {!readonly && (
              <div className={styles.description}>
                This represents your availability to work in your location. You
                can adjust your working hours to accommodate companies in other
                time zones.
                <TextButton
                  highlight
                  style={{ marginLeft: 2 }}
                  onClick={goToArticle}
                >
                  Learn how
                </TextButton>
              </div>
            )}
            {workingHoursState && workingHoursElement}
          </>
        </SidebarSection>
      )}
    </div>
  );
};
