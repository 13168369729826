import { Colors } from '@ateams/components';
import SectionHeading from '@src/components/SectionHeading';
import MissionLayout from '@src/layouts/Mission';
import { MissionControlBase, MissionPageLocation } from '@src/locations';
import { useStores } from '@src/stores';
import { loadMission, MissionMatch } from '@src/url-loaders/loadMission';
import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Redirect } from 'react-router-dom';

interface Props {
  match: MissionMatch;
  adminView?: boolean;
}

const useStyles = createUseStyles({
  link: {
    color: Colors.secondaryDark,
  },
});

export const missionFAQViewLoader = loadMission;

function MissionFAQView(props: Props): ReactElement | null {
  const { match } = props;
  const styles = useStyles();

  const stores = useStores();
  const { missions, auth } = stores;
  const { currentMission } = missions;

  if (!currentMission) {
    return null;
  }

  let notAllowed = currentMission.timeTrackingTabDisabled;
  auth.isAdmin && (notAllowed = false);

  if (notAllowed) {
    return (
      <Redirect
        to={
          match.params.mid
            ? MissionPageLocation(match.params.mid)
            : MissionControlBase
        }
      />
    );
  }
  return (
    <MissionLayout
      title={`${currentMission.data.title} | Time Tracking`}
      match={props.match}
    >
      <div style={{ maxWidth: '912px' }}>
        <SectionHeading isFirst style={{ marginTop: '24px' }}>
          FAQ
        </SectionHeading>
        <p>
          We’ve gathered the most frequently asked questions here for easy
          reference. If you have any other questions you can check the{' '}
          <a
            className={styles.link}
            href="https://guide.a.team"
            target="_blank"
            rel="noreferrer"
          >
            A.Guide
          </a>
        </p>

        <SectionHeading>Setting up your payment method</SectionHeading>
        <p>
          The first thing you want to do when joining a mission is to set up
          your payment method. This will allow you to submit your timesheet and
          is what we use to get you paid. You can be paid either as an
          individual or a company. You can read the{' '}
          <a
            className={styles.link}
            href="https://guide.a.team/missions/payments-tax-forms"
            target="_blank"
            rel="noreferrer"
          >
            Payments & Tax Forms
          </a>{' '}
          article of the A.Guide for more detailed information.
        </p>
        <p>
          If you need to update your payment method, head over to your profile
          page and from the settings menu click on Payment Method.
        </p>

        <img
          src="https://ucarecdn.com/b9fa23a2-f5a3-482a-be5f-dc4c3c2f2e3a/-/preview/1500x345"
          alt="Setting up your payment method"
          width="100%"
        />

        <SectionHeading>Do I need to create an invoice?</SectionHeading>
        <p>
          Our platform will generate an invoice on your behalf which you’ll find
          in the Documents tab a couple of days after submitting your timesheet.
        </p>

        <SectionHeading>How do timesheets work?</SectionHeading>
        <p>
          How you complete your timesheet will depend on whether you are on an
          hourly or monthly role. Timesheets need to be completed at least once
          a week, but it’s best practice to complete them at the end of the day
          so you can capture what you worked on and what value you delivered.
          Timesheets are submitted on the 15th and last day of the month. We
          allow an additional 3 days for submission, but on-time submission
          guarantees faster payments for you and your team.
        </p>

        <ul>
          <li>
            <strong>Hourly role</strong>
            <p style={{ marginTop: 5 }}>
              The most important thing to remember is honesty when logging your
              hours. Only log hours that you actually worked on the mission and
              provide valuable information when completing your timesheet. Add
              at least one entry for every day that you work, which includes the
              hours worked, a description of the work carried, the type of work
              and the relevant initiatives.
            </p>
          </li>
          <li>
            <strong>Monthly role</strong>
            <p style={{ marginTop: 5 }}>
              On a monthly role, you don’t need to log the hours worked per day
              as you are expected to work entire working days, five days a week.
              Our platform will calculate the equivalent amount of hours based
              on your start date and you will receive the agreed amount two
              times a month. You’re still expected to log at least an entry per
              day, describing what you worked on, the type of work and the
              relevant initiative.
            </p>
            <p>
              For more information check out the{' '}
              <a
                className={styles.link}
                href="https://guide.a.team/missions/tracking-your-time"
                target="_blank"
                rel="noreferrer"
              >
                Tracking your Time
              </a>{' '}
              article in the A.Guide.
            </p>
          </li>
        </ul>

        <SectionHeading>
          When should I expect to receive payment?
        </SectionHeading>
        <p>
          A.Team guarantees all payments. Companies usually pay within 2-3 weeks
          of being invoiced. After a company pays the invoice for their team,
          the funds are routed to each team member accordingly. If your invoice
          hasn't been paid within a month of submitting your timesheet, A.Team
          will advance the payment to you.
        </p>
        <p>
          Once you’ve logged 400 hours across your missions, you’re
          automatically enrolled in our guaranteed on-time payments program.
          After reaching the 400 hours threshold, you’ll always get paid on the
          15th and last day of each month for the previous pay cycle’s billed
          hours.
        </p>
        <SectionHeading>Can I get reimbursed for expenses?</SectionHeading>
        <p>
          Before making any expenses, ensure you get the client's approval. If
          you incurred approved expenses, you can submit them using{' '}
          <a
            className={styles.link}
            href="https://forms.gle/p31GmMUegLCbpQFt8"
            target="_blank"
            rel="noreferrer"
          >
            this form.
          </a>
        </p>

        <SectionHeading>Contracts</SectionHeading>
        <p>
          When you are assigned to your mission, our platform will generate a
          contract for you and the client. This can be found in the Documents
          tab under Builder Agreements. Click View and then Sign to sign the
          contract. Our platform uses the details you enter in Tipalti when
          signing the contract, so make sure you complete your payment method
          before signing your contract.
        </p>
        <p>
          If there is no document under Builder Agreements, watch out for an
          email from PandaDoc with your contract. You don’t need to create an
          account to sign through PandaDoc and you can edit your details before
          signing.
        </p>

        <SectionHeading>I need more help!</SectionHeading>
        <p>
          Here’s a{' '}
          <a
            className={styles.link}
            href="https://guide.a.team/support/get-help"
            target="_blank"
            rel="noreferrer"
          >
            comprehensive list of our teams.
          </a>{' '}
          For anything related to your mission you can contact{' '}
          <a className={styles.link} href="mailto:teamsuccess@a.team">
            TeamSuccess
          </a>{' '}
          and for anything else, our wonderful{' '}
          <a className={styles.link} href="mailto:help@a.team">
            Support team
          </a>{' '}
          is here for you.
        </p>
      </div>
    </MissionLayout>
  );
}

export default observer(MissionFAQView);
