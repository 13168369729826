import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { PopulatedProps } from '@src/helpers/types';
import { theme } from '@a_team/ui-components';

export interface Props {
  progress: number;
  strokeSize?: number;
  radius?: number;
  fillColor?: string;
  barColor?: string;
  barColorOpacity?: number;
  className?: string;
}

const defaultProps: Partial<Props> = {
  fillColor: undefined,
  barColor: '#fff',
  strokeSize: 4,
};

export type ActualProps = PopulatedProps<Props, typeof defaultProps>;

const useStyles = createUseStyles({
  circle: {
    transition: 'all 0.5s',
  },
});

const ProgressCircle = (props: ActualProps): ReactElement => {
  const radius = props.radius || 56;
  const stroke = props.strokeSize;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset =
    circumference - (props.progress / 100) * circumference;
  const styles = useStyles();

  return (
    <svg height={radius * 2} width={radius * 2} className={props.className}>
      <defs>
        <linearGradient id="Gradient2">
          <stop offset="0%" stopColor={theme.colors.Faceman['400']} />
          <stop offset="63%" stopColor={theme.colors.Hannibal['500']} />
          <stop offset="80%" stopColor={theme.colors.Baracus['400']} />
        </linearGradient>
      </defs>
      <circle
        stroke={props.barColor}
        strokeOpacity={props.barColorOpacity || 1}
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset: 1 }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        id="rect1"
        stroke={props.fillColor ? props.fillColor : 'url(#Gradient2)'}
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        className={styles.circle}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
};

ProgressCircle.defaultProps = defaultProps;
export default ProgressCircle;
