import React, { useMemo } from 'react';
import { AdminVettingProcessColumnProps } from '..';
import { format } from 'date-fns';
import { Button } from '@ateams/components';
import useToggle from '@src/hooks/useToggle';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { useStores } from '@src/stores';
import { createUseStyles } from 'react-jss';
import { VettingProcessStatus } from '@a_team/models/dist/vetting-processes/status';
import { OnEvaluationInviteSubmit } from '@src/components/Modal/EvaluationInviteModal';
import { GenerateBookingLinkModal } from '@src/components/Modal/GenerateBookingLinkModal';

const useStyles = createUseStyles({
  button: {
    width: '110px',
  },
});

export const BookingLinkField: React.FC<AdminVettingProcessColumnProps> = ({
  vettingProcess,
}) => {
  const styles = useStyles();
  const { vettingProcesses } = useStores();
  const [generateBookingLinkModalOpen, toggleGenerateBookingLinkModalOpen] =
    useToggle(false);
  const [loading, setLoading] = useLoadingState();

  const onEvaluationInvite: OnEvaluationInviteSubmit = (payload) => {
    setLoading(
      (async () => {
        const templateVariables: Record<string, string> = {};

        if (vettingProcess.interviewDate) {
          templateVariables.interviewDate = format(
            new Date(vettingProcess.interviewDate),
            'MMMM d, yyyy',
          );
        }

        await vettingProcesses.sendEvaluationInvite({
          id: vettingProcess.id,
          vettingType: payload.vettingType,
          category: payload.category,
          primaryRoleCategoryId: payload.primaryRoleCategoryId,
          contactOwner: payload.contactOwner,
          selectedVetterIds: payload.selectedVetterIds,
          vettingEmailTemplateParameters: {
            subject: payload.emailSubject,
            template: payload.emailBody,
            templateType: payload.templateType,
            templateVariables,
            calendarUrlType: payload.calendarUrlType,
            sendInThread: payload.sendInThread,
          },
        });

        toggleGenerateBookingLinkModalOpen();
      })(),
      'Evaluation Invitation email sent',
    );
  };

  const canSendEvaluationInvite = useMemo(() => {
    return Boolean(vettingProcess.status !== VettingProcessStatus.Completed);
  }, [vettingProcess]);

  const isComposeEmailButtonDisabled = useMemo(() => {
    return (
      !canSendEvaluationInvite ||
      (loading && typeof loading !== 'string') ||
      generateBookingLinkModalOpen
    );
  }, [canSendEvaluationInvite, loading, generateBookingLinkModalOpen]);

  return (
    <>
      <GenerateBookingLinkModal
        vettingProcess={vettingProcess}
        open={generateBookingLinkModalOpen}
        onClose={toggleGenerateBookingLinkModalOpen}
        onSubmit={onEvaluationInvite}
      />
      <Button
        onClick={toggleGenerateBookingLinkModalOpen}
        size="small"
        disabled={isComposeEmailButtonDisabled}
        className={styles.button}
      >
        Edit link
      </Button>
      <LoadingIndicator loading={loading} />
    </>
  );
};
