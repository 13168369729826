import {
  TeamPulse,
  TeamPulseId,
  TeamPulseSurvey,
  TeamPulseSurveyId,
  TeamPulseSurveyType,
  TeamPulseDashboardItem,
  TeamPulseSurveyWithUser,
} from '@a_team/models/dist/TeamPulse';
import { MissionId } from '@a_team/models/dist/MissionObject';
import { SurveyResponseMap } from '@a_team/models/dist/Survey';
import { UserId } from '@a_team/models/dist/UserObject';
import { ServiceAuth, ServiceEndpoint } from './utils';
import { removeUndefinedValues } from '@ateams/service-utils';
import {
  DateInterval,
  DateISOString,
  Nullable,
} from '@a_team/models/dist/misc';

export const basePath = '/team-pulse';

//
// User endpoints
//

// Find team pulses

export interface FindTeamPulsesRequest {
  filters: {
    missionId: MissionId;
  };
  admin?: false;
}
export type FindTeamPulsesResponse = TeamPulse[];

// Find a user's open, incomplete team pulse surveys

export interface FindMyOpenIncompleteTeamPulseSurveysRequest {
  filters: {
    missionId: MissionId;
  };
}
export type FindMyOpenIncompleteTeamPulseSurveysResponse = TeamPulseSurvey[];

// Submit team pulse survey response

export interface SubmitTeamPulseSurveyResponseRequest {
  teamPulseSurveyId: TeamPulseSurveyId;
  response: SurveyResponseMap;
  hiddenUsersIds?: UserId[];
}
export type SubmitTeamPulseSurveyResponseResponse = void;

class UserTeamPulseEndpoint extends ServiceEndpoint {
  /** Finds team pulse surveys by mission */
  findTeamPulses(
    auth: ServiceAuth,
    { filters: { missionId }, admin }: FindTeamPulsesRequest,
  ): Promise<FindTeamPulsesResponse> {
    return this.fetch(
      auth,
      basePath,
      removeUndefinedValues({ missionId, admin }),
      'get',
    );
  }

  /** Finds the user's own, open, incomplete team pulse surveys by mission */
  findMyOpenIncompleteTeamPulseSurveys(
    auth: ServiceAuth,
    { filters: { missionId } }: FindMyOpenIncompleteTeamPulseSurveysRequest,
  ): Promise<FindMyOpenIncompleteTeamPulseSurveysResponse> {
    return this.fetch(
      auth,
      `${basePath}/surveys/open`,
      removeUndefinedValues({
        missionId,
      }),
      'get',
    );
  }

  /** Submits a response to a team pulse survey */
  async submitTeamPulseSurveyResponse(
    auth: ServiceAuth,
    { teamPulseSurveyId, ...data }: SubmitTeamPulseSurveyResponseRequest,
  ): Promise<SubmitTeamPulseSurveyResponseResponse> {
    await this.fetch(
      auth,
      `${basePath}/surveys/${teamPulseSurveyId}`,
      null,
      'put',
      data,
    );
  }
}

//
// Admin endpoints
//

// Admin: Create team pulse (for one or all missions and optionally including surveys)

export interface AdminCreateEmptyTeamPulseForOneMissionRequest {
  missionId: MissionId;
  coveringInterval: DateInterval;
}
export type AdminCreateEmptyTeamPulseForOneMissionResponse = TeamPulse;

export interface AdminCreateTeamPulseAndSurveysForOneMissionRequest {
  missionId: MissionId;
  coveringInterval: DateInterval;
  teamPulseSurveyType: TeamPulseSurveyType;
  responseDateInterval?: Nullable<DateInterval>;
}
export interface AdminCreateTeamPulseAndSurveysForOneMissionResponse {
  teamPulse: TeamPulse;
  teamPulseSurveys: TeamPulseSurvey[];
}

export interface AdminCreateEmptyTeamPulseForAllOpenMissionsRequest {
  coveringInterval: DateInterval;
}
export type AdminCreateEmptyTeamPulseForAllOpenMissionsResponse = TeamPulse[];

export interface AdminCreateTeamPulseAndSurveysForAllOpenMissionsRequest {
  coveringInterval: DateInterval;
  teamPulseSurveyType: TeamPulseSurveyType;
  responseDateInterval?: Nullable<DateInterval>;
}
export type AdminCreateTeamPulseAndSurveysForAllOpenMissionsResponse = Array<{
  teamPulse: TeamPulse;
  teamPulseSurveys: TeamPulseSurvey[];
}>;

export type AdminCreateTeamPulseRequest =
  | AdminCreateEmptyTeamPulseForOneMissionRequest
  | AdminCreateTeamPulseAndSurveysForOneMissionRequest
  | AdminCreateEmptyTeamPulseForAllOpenMissionsRequest
  | AdminCreateTeamPulseAndSurveysForAllOpenMissionsRequest;
export type AdminCreateTeamPulseResponse =
  | AdminCreateEmptyTeamPulseForOneMissionResponse
  | AdminCreateTeamPulseAndSurveysForOneMissionResponse
  | AdminCreateEmptyTeamPulseForAllOpenMissionsResponse
  | AdminCreateTeamPulseAndSurveysForAllOpenMissionsResponse;

// Create team pulse survey (in an existing team pulse)

export interface AdminCreateTeamPulseSurveyForOneUserRequest {
  teamPulseId: TeamPulseId;
  teamPulseSurveyType: TeamPulseSurveyType;
  userId: UserId;
  responseDateInterval?: Nullable<DateInterval>;
}
export type AdminCreateTeamPulseSurveyForOneUserResponse = TeamPulseSurvey;

export interface AdminCreateTeamPulseSurveyForAllUsersRequest {
  teamPulseId: TeamPulseId;
  teamPulseSurveyType: TeamPulseSurveyType;
  responseDateInterval?: {
    start: DateISOString | null;
    end: DateISOString | null;
  };
}
export type AdminCreateTeamPulseSurveyForAllUsersResponse = TeamPulseSurvey[];

export type AdminCreateTeamPulseSurveyRequest =
  | AdminCreateTeamPulseSurveyForOneUserRequest
  | AdminCreateTeamPulseSurveyForAllUsersRequest;
export type AdminCreateTeamPulseSurveyResponse =
  | AdminCreateTeamPulseSurveyForOneUserResponse
  | AdminCreateTeamPulseSurveyForAllUsersResponse;

// Find team pulses

export interface AdminFindTeamPulsesRequest {
  filters?: {
    missionId?: MissionId;
    coveringDate?: Partial<DateInterval>;
  };
  admin: true;
}
export type AdminFindTeamPulsesResponse = Array<TeamPulse>;

// Find team pulse surveys

export interface AdminFindTeamPulseSurveysRequest {
  filters?: {
    missionId?: MissionId;
    userId?: UserId;
    completed?: boolean;
    teamPulseId?: TeamPulseId;
    open?: boolean;
    expired?: boolean;
    surveyId?: TeamPulseSurveyId;
  };
}
export type AdminFindTeamPulseSurveysResponse = TeamPulseSurvey[];
export type AdminFindTeamPulseSurveysWithUsersResponse =
  TeamPulseSurveyWithUser[];
export type AdminFindTeamPulseDashboardResponse = TeamPulseDashboardItem[];

export interface AdminGetTeamPulseUserAverageRatingRequest {
  userId: UserId;
  onlyLastPaymentCycle: boolean;
}

export interface AdminGetTeamPulseUserAverageRatingResponse {
  fromRegularUsers:
    | {
        rating: number;
        count: number;
      }
    | undefined;
  fromCompanyUsers:
    | {
        rating: number;
        count: number;
      }
    | undefined;
}

export interface AdminGetTeamPulseUsersAverageRatingByQuarterRequest {
  quarter: number;
  year: number;
  page: number;
  limit: number;
}

export interface AdminGetTeamPulseUsersAverageRatingByQuarterResponse {
  data: {
    missionId: MissionId;
    userId: UserId;
    email: string;
    username: string;
    missionTitle: string;
    averageRating: number;
  }[];
  metadata: {
    total: number;
    quarter: number;
    year: number;
    page: number;
    limit: number;
  };
}

export default class TeamPulseEndpoint extends UserTeamPulseEndpoint {
  /** Creates an empty team pulse for a particular mission */
  adminCreateTeamPulse(
    auth: ServiceAuth,
    data: AdminCreateEmptyTeamPulseForOneMissionRequest,
  ): Promise<AdminCreateEmptyTeamPulseForOneMissionResponse>;
  /** Creates a team pulse for a particular mission and the surveys in it */
  adminCreateTeamPulse(
    auth: ServiceAuth,
    data: AdminCreateTeamPulseAndSurveysForOneMissionRequest,
  ): Promise<AdminCreateTeamPulseAndSurveysForOneMissionResponse>;
  /** Creates an empty team pulse for all open missions */
  adminCreateTeamPulse(
    auth: ServiceAuth,
    data: AdminCreateEmptyTeamPulseForAllOpenMissionsRequest,
  ): Promise<AdminCreateEmptyTeamPulseForAllOpenMissionsResponse>;
  /** Creates a team pulse for all open surveys and the surveys in it */
  adminCreateTeamPulse(
    auth: ServiceAuth,
    data: AdminCreateTeamPulseAndSurveysForAllOpenMissionsRequest,
  ): Promise<AdminCreateTeamPulseAndSurveysForAllOpenMissionsResponse>;
  adminCreateTeamPulse(
    auth: ServiceAuth,
    data: AdminCreateTeamPulseRequest,
  ): Promise<AdminCreateTeamPulseResponse> {
    return this.fetch(auth, basePath, null, 'post', data);
  }

  /** Creates a survey for a particular user in a particular team pulse */
  adminCreateTeamPulseSurvey(
    auth: ServiceAuth,
    data: AdminCreateTeamPulseSurveyForOneUserRequest,
  ): Promise<AdminCreateTeamPulseSurveyForOneUserResponse>;
  /** AdminCreates a survey for all members of a team in a particular team pulse */
  adminCreateTeamPulseSurvey(
    auth: ServiceAuth,
    data: AdminCreateTeamPulseSurveyForAllUsersRequest,
  ): Promise<AdminCreateTeamPulseSurveyForAllUsersResponse>;
  adminCreateTeamPulseSurvey(
    auth: ServiceAuth,
    data: AdminCreateTeamPulseSurveyRequest,
  ): Promise<AdminCreateTeamPulseSurveyResponse> {
    return this.fetch(auth, `${basePath}/surveys`, null, 'post', data);
  }

  /** Finds team pulse surveys matching the filters */
  adminFindTeamPulses(
    auth: ServiceAuth,
    {
      filters: { missionId, coveringDate } = {},
      admin,
    }: AdminFindTeamPulsesRequest = { admin: true },
  ): Promise<AdminFindTeamPulsesResponse> {
    return this.fetch(
      auth,
      basePath,
      removeUndefinedValues({
        missionId,
        start: coveringDate?.start
          ? new Date(coveringDate.start).toISOString()
          : undefined,
        end: coveringDate?.end
          ? new Date(coveringDate.end).toISOString()
          : undefined,
        admin,
      }),
      'get',
    );
  }

  /** Finds team pulse surveys matching the filters */
  adminFindTeamPulseSurveys(
    auth: ServiceAuth,
    {
      filters: {
        missionId,
        userId,
        completed,
        teamPulseId,
        open,
        expired,
      } = {},
    }: AdminFindTeamPulseSurveysRequest = {},
  ): Promise<AdminFindTeamPulseSurveysResponse> {
    return this.fetch(
      auth,
      `${basePath}/surveys`,
      removeUndefinedValues({
        missionId,
        userId,
        completed,
        teamPulseId,
        open,
        expired,
      }),
      'get',
    );
  }

  /** Finds team pulse surveys matching the filters */
  adminFindTeamPulseSurveysWithUsers(
    auth: ServiceAuth,
    {
      filters: {
        missionId,
        userId,
        completed,
        teamPulseId,
        open,
        expired,
      } = {},
    }: AdminFindTeamPulseSurveysRequest = {},
  ): Promise<AdminFindTeamPulseSurveysWithUsersResponse> {
    return this.fetch(
      auth,
      `${basePath}/surveys-with-users`,
      removeUndefinedValues({
        missionId,
        userId,
        completed,
        teamPulseId,
        open,
        expired,
      }),
      'get',
    );
  }

  /** Finds team pulse surveys matching the filters */
  adminFindTeamDashboard(
    auth: ServiceAuth,
  ): Promise<AdminFindTeamPulseDashboardResponse> {
    return this.fetch(auth, `${basePath}/dashboard`, {}, 'get');
  }

  /** Gets team pulse user overall average rating */
  adminGetTeamPulseUserAverageRating(
    auth: ServiceAuth,
    data: AdminGetTeamPulseUserAverageRatingRequest,
  ): Promise<AdminGetTeamPulseUserAverageRatingResponse> {
    return this.fetch(auth, `${basePath}/user-average-rating`, data, 'get');
  }

  /** Gets average rating by quarter for a user's mission */
  adminGetTeamPulseUsersAverageRatingByQuarter(
    auth: ServiceAuth,
    data: AdminGetTeamPulseUsersAverageRatingByQuarterRequest,
  ): Promise<AdminGetTeamPulseUsersAverageRatingByQuarterResponse> {
    return this.fetch(
      auth,
      `${basePath}/users-mission-average-rating-by-quarter`,
      data,
      'get',
    );
  }
}
