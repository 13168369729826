import React, { ReactElement, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { AdminNote } from '@a_team/models/dist/AdminNotesObject';
import { format } from 'date-fns';
import { Icon, IconType } from '@ateams/components';
import SectionHeading from '@src/components/SectionHeading';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import UserAvatar from '@src/components/UserAvatar';
import { Button as CallToActionButton } from '@ateams/components';
import { ProfileLocation } from '@src/locations';
import Autolinker from 'autolinker';

interface Props {
  fullName: string;
  pitch?: Omit<AdminNote, 'nid' | 'createdAt'>;
  onSave: (text: string) => void;
}

const useStyles = createUseStyles({
  noteCard: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2em',
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    marginLeft: 12,
  },
  noteContent: {
    fontFamily: ['Inter', 'sans-serif'],
    whiteSpace: 'break-spaces',
  },
  cta: {
    alignSelf: 'flex-end',
    display: 'flex',
  },
  button: {
    color: '#fff',
    marginLeft: 12,
  },
  placeholder: {
    color: 'gray',
  },
  authorContainer: {
    display: 'flex',
  },
  authorText: {
    fontSize: 14,
    color: '#62646A',
    margin: 'auto 8px',
  },
});

export const AdminPitch = (props: Props): ReactElement => {
  const styles = useStyles();
  const { fullName, pitch } = props;
  const date = useMemo(
    () =>
      pitch
        ? format(new Date(pitch.updatedAt), "MMMM d, yyyy 'at' h:mm a")
        : '',
    [pitch, pitch?.updatedAt],
  );

  const [text, setText] = useState<string>(pitch?.text ?? '');
  const [edit, setEdit] = useState<boolean>(false);

  return (
    <div className={styles.noteCard}>
      <div className={styles.heading}>
        <SectionHeading isFirst style={{ marginBottom: 12 }}>
          Admin Pitch
        </SectionHeading>
        {!edit && (
          <Icon
            title={'Edit'}
            type={IconType.EditGray}
            className={styles.icon}
            onClick={() => setEdit(true)}
          />
        )}
      </div>
      {edit ? (
        <>
          <OutlinedInput
            value={text}
            multiline
            type="text"
            margin="none"
            onChange={(e): void => setText(e.target.value)}
          />
          <div className={styles.cta} style={{ marginTop: 16 }}>
            <CallToActionButton
              onClick={() => {
                props.onSave(text);
                setEdit(false);
              }}
              className={styles.button}
              size="small"
              width="auto"
            >
              Save
            </CallToActionButton>
            <CallToActionButton
              onClick={() => setEdit(false)}
              className={styles.button}
              color="regular"
              size="small"
              width="auto"
            >
              Cancel
            </CallToActionButton>
          </div>
        </>
      ) : (
        <>
          <pre
            className={cx(
              styles.noteContent,
              !pitch?.text && styles.placeholder,
            )}
            dangerouslySetInnerHTML={{
              __html: Autolinker.link(
                pitch?.text ??
                  `Write a pitch for ${fullName}. This pitch is shared on sample proposals...`,
              ),
            }}
          />
          {pitch && pitch.author && (
            <div className={styles.authorContainer}>
              <Link to={ProfileLocation(pitch.author.username || '')}>
                <UserAvatar src={pitch.author.profilePictureURL} size={24} />
              </Link>
              <span className={styles.authorText}>
                {`${pitch.author.fullName + ' '} ${
                  pitch.text ? 'added' : 'deleted'
                } on ${date}`}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};
