import { useStores } from '@src/stores';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiUserReviews } from '@ateams/api';
import { UserReviewObject } from '@a_team/models/dist/UserReviewObject';
import { queryKeys } from './keys';

export const useQueryUserReviews = ({
  uid,
  includeAllReviews = false,
  onSuccess,
  enabled,
}: {
  uid: string;
  includeAllReviews?: boolean;
  onSuccess?: (data: UserReviewObject[] | null) => void;
  enabled?: boolean;
}) => {
  const { auth } = useStores();
  const isEnabled = enabled || auth.onboardingCompleted;

  return useQuery({
    queryKey: queryKeys.userReviews.list(uid, includeAllReviews),
    queryFn: async () => {
      return await apiUserReviews.getUserReviews(auth, {
        uid,
        includeAllReviews,
      });
    },
    onSuccess,
    enabled: isEnabled,
  });
};

export const useMutationChangeUserReviewVisibility = ({
  uid,
  includeAllReviews,
}: {
  uid: string;
  includeAllReviews: boolean;
}) => {
  const { auth } = useStores();
  const queryClient = useQueryClient();

  return useMutation(
    async (args: { reviewId: string; visible: boolean }) => {
      return await apiUserReviews.changeUserReviewVisibility(
        auth,
        args.reviewId,
        args.visible,
      );
    },
    {
      onMutate: async ({ reviewId, visible }) => {
        // Cancel any outgoing refetches so they don't overwrite our optimistic update
        await queryClient.cancelQueries(
          queryKeys.userReviews.list(uid, includeAllReviews),
        );

        // Snapshot the previous value
        const previousReviews = queryClient.getQueryData<UserReviewObject[]>(
          queryKeys.userReviews.list(uid, includeAllReviews),
        );

        // Optimistically update to the new value
        queryClient.setQueryData(
          queryKeys.userReviews.list(uid, includeAllReviews),
          (old: UserReviewObject[] = []) =>
            old.map((review) =>
              review.id === reviewId ? { ...review, visible } : review,
            ),
        );

        // Return a context object with the snapshotted value
        return { previousReviews };
      },
      onError: (err, newReview, context) => {
        // If the mutation fails, use the context returned from onMutate to roll back
        const previousReviews = context as {
          previousReviews?: UserReviewObject[];
        };

        queryClient.setQueryData(
          queryKeys.userReviews.list(uid, includeAllReviews),
          previousReviews,
        );
      },
      onSettled: () => {
        // Always refetch after error or success to ensure we have the correct data
        queryClient.invalidateQueries(
          queryKeys.userReviews.list(uid, includeAllReviews),
        );
      },
    },
  );
};
