import {
  Button,
  Column,
  DatePicker,
  Input,
  Modal,
  ModalProps,
  Row,
  Typography,
} from '@a_team/ui-components';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { MissionApplicationManuallyProposedData } from '@ateams/api/dist/endpoints/Missions';

interface Props extends Omit<ModalProps, 'children'> {
  defaultBuilderRate?: number;
  defaultMargin?: number;
  onSubmit: (data: MissionApplicationManuallyProposedData) => Promise<void>;
}

export const ProposedDetailsModal = (props: Props) => {
  const [builderRate, setBuilderRate] = useState(props.defaultBuilderRate || 0);
  const [marginPercent, setMarginPercent] = useState(props.defaultMargin || 0);
  const [shareDate, setShareDate] = useState(new Date());

  useEffect(() => {
    props.defaultBuilderRate && setBuilderRate(props.defaultBuilderRate);
  }, [props.defaultBuilderRate]);

  useEffect(() => {
    props.defaultMargin && setMarginPercent(props.defaultMargin);
  }, [props.defaultMargin]);

  const margin = useMemo(() => {
    return marginPercent / 100;
  }, [marginPercent, builderRate]);

  const clientRate = useMemo(() => {
    if (!builderRate || !margin) return 0;
    return Math.floor(builderRate * (1 + margin));
  }, [margin, builderRate]);

  const onBuilderRateChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setBuilderRate(Number(e.target.value));
  };

  const onMarginChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setMarginPercent(Number(e.target.value));
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <Typography variant={'h3'} margin={'sm'}>
        Manual Proposal details
      </Typography>
      <Row noGutters>
        <Column>
          <Input
            value={builderRate}
            type={'number'}
            icon={<span>$</span>}
            min={0}
            iconPosition={'left'}
            onChange={onBuilderRateChange}
            label={'Proposed builder rate'}
          />
        </Column>
        <Column>
          <Input
            value={clientRate}
            type={'number'}
            icon={<span>$</span>}
            iconPosition={'left'}
            readOnly
            disabled
            onChange={() => null}
            label={'Proposed client rate'}
          />
        </Column>
      </Row>
      <Input
        value={marginPercent}
        type={'number'}
        min={0}
        onChange={onMarginChange}
        label={'Margin'}
        icon={<span>%</span>}
        iconPosition={'left'}
      />
      <DatePicker
        defaultDate={shareDate}
        onChange={setShareDate}
        label={'Date proposal was shared with the client'}
      />
      <Button
        margin={'sm'}
        disabled={!builderRate || !margin}
        onClick={() => {
          props
            .onSubmit({
              proposedRates: {
                clientHourlyRate: clientRate,
                builderHourlyRate: builderRate,
              },
              proposalManuallySharedAt: shareDate,
            })
            .then(() => props.onClose && props.onClose());
        }}
      >
        Submit
      </Button>
    </Modal>
  );
};
