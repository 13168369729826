import { Button as CallToActionButton } from '@ateams/components';
import SectionHeading from '@src/components/SectionHeading';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import Modal, { ModalBasicProps } from '..';

interface LowCompetitivenessModalProps extends ModalBasicProps {
  onSubmit: () => void;
}

const useStyles = createUseStyles({
  title: {
    fontSize: 28,
  },
  description: {
    fontSize: 14,
    marginBottom: 24,
  },
  buttonBox: {
    marginTop: 60,
    display: 'flex',
    gap: '16px',
    '& > button': {
      width: '100%',
      height: 'auto',
    },
  },
});

const LowCompetitivenessModal = ({
  onClose,
  onSubmit,
  open,
}: LowCompetitivenessModalProps): ReactElement => {
  const styles = useStyles();

  return (
    <Modal
      onClose={onClose}
      hideCloseButton={true}
      open={open}
      style={{
        maxWidth: '600px',
        width: '100%',
        padding: '24px',
        overflow: 'visible',
      }}
    >
      <div>
        <SectionHeading className={styles.title} isFirst>
          You’re missing one or more requirements
        </SectionHeading>

        <div className={styles.description}>
          <p>
            You are missing one or more conditions that the client marked
            required, therefore your application might be considered low
            competitiveness.
          </p>
          <p> Do you still want to apply?</p>
        </div>

        <div className={styles.buttonBox}>
          <CallToActionButton
            width="auto"
            onClick={onClose}
            size="small"
            color={'regularLight'}
          >
            No, Go back
          </CallToActionButton>
          <CallToActionButton
            width="auto"
            onClick={onSubmit}
            disabled={false}
            size="small"
          >
            Yes, Apply
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
};

export default LowCompetitivenessModal;
