import { ExperienceMemberRole } from '@a_team/models/dist/ExperienceObject';
import {
  BasicUserObject,
  CollaboratorStatus,
} from '@a_team/models/dist/UserObject';
import { Select } from '@a_team/ui-components';
import { Breakpoints, Icon, IconType, SelectOption } from '@ateams/components';
import ProjectUserSelector from '@src/components/ProjectUserSelector';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';

interface CollaboratorProps {
  index: number;
  memberIndex: number;
  roles: { value: string; label: string }[] | undefined;
  newMembers: number[];
  setNewMembers: Dispatch<SetStateAction<number[]>>;
}

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  collaborator: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    alignItems: 'center',
    width: '100%',
    '& > div': {
      flex: 1,
      width: '100%',
    },
  },
  projectUserWrapper: {
    '& span[class*="indicatorSeparator"]': {
      display: 'none',
    },
    '& div[class*="ValueContainer"] > div:first-child >  div:first-child': {
      padding: '0 !important',
    },
    '& div[class*="control"]': {
      height: 41,
      borderColor: '#DADADC',
    },
    '& div[class*="placeholder"]': {
      color: '#B5B8BF',
    },
  },
  deleteButton: {
    transition: 'all 0.3s ease',

    borderRadius: 4,
    border: '1px solid #C0C0C0',
    background: '#FFFFFF',
    height: 38,
    cursor: 'pointer',
    padding: '0 10px',
    '&:hover': {
      opacity: 0.8,
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    collaborator: {
      flexDirection: 'row',
      width: '80%',
    },
  },
});

const AddCollaborator = ({
  index,
  roles,
  memberIndex,
  newMembers,
  setNewMembers,
}: CollaboratorProps): JSX.Element => {
  const { setValue, watch } = useFormContext();

  const styles = useStyles();
  const [member, setMember] = useState<BasicUserObject | undefined>();
  const [role, setRole] = useState<string | undefined>();

  const showRemoveButton = useMemo(() => {
    if (index > 0) {
      return true;
    }
    if (member) {
      return true;
    }

    return false;
  }, [index, member]);

  const members = watch('members');

  useEffect(() => {
    if (member && role) {
      const list = [...members];
      list.push({
        ...member,
        ...{
          memberTitle: member.title,
          experienceRole: ExperienceMemberRole.Member,
          memberRole: role,
          collaboratorStatus: CollaboratorStatus.Pending,
        },
      });
      setValue('members', list);

      onRemove();
    }
  }, [member, role]);

  const onRemove = (): void => {
    setNewMembers(newMembers.filter((i) => memberIndex !== i));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.collaborator}>
        <div className={styles.projectUserWrapper}>
          <ProjectUserSelector
            placeholder="Add a collaborator"
            onSelect={(user): void => setMember(user as BasicUserObject)}
            menuMaxHeight={150}
          />
        </div>
        <div>
          <Select
            styles={{
              control: (base) => ({
                ...base,
                border: '1px solid #DADADC',
              }),
              menu: (base) => ({
                ...base,
                maxHeight: 150,
                overflow: 'hidden',
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 999999,
                color: '#000000',
              }),
              indicatorsContainer: (base) => ({
                ...base,
                '& > img': {
                  marginRight: 8,
                },
              }),
            }}
            placeholder="Select role"
            options={roles}
            onChange={(data) => {
              const role = data as SelectOption;
              if (role?.value) {
                setRole(role.value);
              }
            }}
            isDisabled={!member}
            menuPortalTarget={document.body}
          />
        </div>
      </div>
      {showRemoveButton && (
        <div>
          <button
            type="button"
            className={styles.deleteButton}
            onClick={onRemove}
          >
            <Icon
              type={IconType.TrashBlack}
              style={{ cursor: 'pointer' }}
              size={'exact'}
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default AddCollaborator;
