import React, { ReactElement, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { AsyncSelect, Icon, IconType, SelectOption } from '@ateams/components';
import {
  fetchIndustryList,
  getIndustrySelectOptions,
  Industry,
} from '@src/helpers/talent-industry-api';
import OutlinedInput from '../Inputs/OutlinedInput';

export interface IndustryWithMinYearsExperience {
  industryId: string;
  minYearsOfExperience: number;
}

interface NamedIndustryWithMinYearsExperience
  extends IndustryWithMinYearsExperience {
  industryName: string;
}

interface Props {
  industryYearsExperience?: IndustryWithMinYearsExperience[];
  onChange: (newIndustiesExperience: IndustryWithMinYearsExperience[]) => void;
}

const useStyles = createUseStyles({
  yearInputContainer: {
    marginTop: 8,
  },
  yearInputRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 4,
    paddingLeft: 5,
  },
  yoeInput: {
    margin: 0,
    width: '7em',
    '& > div': {
      borderRadius: 4,
      minHeight: 'unset',
      padding: '0.6em 0.5em',
    },
  },
  deleteIcon: {
    margin: 'auto 8px auto 0',
    padding: 3,
    fontSize: 7,
  },
});

const IndustryYearsExperienceFilter = (props: Props): ReactElement => {
  const styles = useStyles();
  const [allIndustries, setAllIndustries] = useState<Industry[]>([]);

  const searchIndustries = async (
    searchTerm: string,
  ): Promise<SelectOption[]> => {
    return getIndustrySelectOptions({
      filter: {
        query: searchTerm,
      },
    });
  };

  const selectedIndustries: NamedIndustryWithMinYearsExperience[] =
    allIndustries.length && props.industryYearsExperience
      ? props.industryYearsExperience
          ?.map((industry) => {
            return {
              ...industry,
              industryName:
                allIndustries.find((i) => i.id === industry.industryId)?.name ||
                '',
            };
          })
          .sort((a, b) => a.industryName.localeCompare(b.industryName))
      : [];

  useEffect(() => {
    fetchIndustryList({})
      .then((industries) => {
        setAllIndustries(industries);
      })
      .catch((err) => {
        console.error(err);
        setAllIndustries([]);
      });
  }, []);

  const onChange = (options: SelectOption[]): void => {
    props.onChange(
      options.map((option) => {
        return {
          industryId: option.value,
          minYearsOfExperience:
            props.industryYearsExperience?.find(
              (industry) => industry.industryId === option.value,
            )?.minYearsOfExperience || 1,
        };
      }),
    );
  };

  const onClickOnRemoveIndustry = (industryId: string): void => {
    props.onChange(
      props.industryYearsExperience?.filter(
        (industry) => industry.industryId !== industryId,
      ) || [],
    );
  };

  const onChangeMinYears = (industryId: string, value: number): void => {
    props.onChange(
      props.industryYearsExperience?.map((industry) => {
        if (industry.industryId === industryId) {
          return {
            ...industry,
            minYearsOfExperience: value,
          };
        }
        return industry;
      }) || [],
    );
  };

  return (
    <>
      <AsyncSelect
        label="Years of experience in industry"
        value={props.industryYearsExperience?.map((industry) => ({
          value: industry.industryId,
          label: industry.industryId,
        }))}
        loadOptions={searchIndustries}
        onChange={(options) => onChange(options as unknown as SelectOption[])}
        placeholder={'Industry years of experience'}
        margin={'none'}
        hideTags
        isMulti
      />
      <div className={styles.yearInputContainer}>
        {selectedIndustries?.map((industry) => (
          <div className={styles.yearInputRow} key={industry.industryId}>
            <div style={{ display: 'flex' }}>
              <Icon
                onClick={() => onClickOnRemoveIndustry(industry.industryId)}
                type={IconType.Close}
                title={'Delete'}
                size={'xxsmall'}
                className={styles.deleteIcon}
              ></Icon>
              {industry.industryName}
            </div>
            <OutlinedInput
              key={industry.industryId}
              type="number"
              className={styles.yoeInput}
              defaultValue={industry.minYearsOfExperience}
              endAdornment={<span style={{ marginLeft: 4 }}>YOE</span>}
              max={100}
              min={1}
              onBlur={(e) => {
                onChangeMinYears(
                  industry.industryId,
                  (e.target.value !== undefined && parseInt(e.target.value)) ||
                    1,
                );
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default IndustryYearsExperienceFilter;
