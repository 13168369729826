/**
 * Returns all numbers from `start` to `end` (inclusive) in steps of `step`
 *
 * @argument start The first number of the list
 * @argument end The last number of the list (if the match is exact)
 * @argument step The difference between two consecutive numbers in the list
 *
 * `step` must not be 0. If `end` is greater than `start`, `step` must be
 * positive. If `end` is less than `start`, `step` must be `negative`.
 *
 * e.g.
 *
 * ```
 * > step(1, 5)
 * [1, 2, 3, 4, 5]
 * > step(1, 5, 2)
 * [1, 3, 5]
 * > step(6, 1, -2)
 * [6, 4, 2]
 * > step(0, 0.5, 0.2)
 * [0, 0.2, 0.4]
 * ```
 */
const range = (start: number, end: number, step = 1): number[] => {
  if (step === 0) {
    throw new Error('`step` must not be 0');
  }
  if (end > start && step < 0) {
    throw new Error(
      'If the start value is less than the end value, the step must be positive.',
    );
  }
  if (start > end && step > 0) {
    throw new Error(
      'If the start value is greater than the end value, the step must be negative.',
    );
  }
  const directionIsPositive = step > 0;

  const values: number[] = [];

  for (
    let item = start;
    directionIsPositive ? item <= end : item >= end;
    item = item + step
  ) {
    values.push(item);
  }

  return values;
};

export default range;
