import React from 'react';

export const FormIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M4.84216 2.63086H4.21058C3.51295 2.63086 2.94742 3.1964 2.94742 3.89402V12.7361C2.94742 13.4337 3.51295 13.9993 4.21058 13.9993H11.7895C12.4871 13.9993 13.0527 13.4337 13.0527 12.7361V3.89402C13.0527 3.19639 12.4871 2.63086 11.7895 2.63086H11.1579"
        stroke="currentColor"
        strokeWidth="1.4"
      />
      <path
        opacity="0.4"
        d="M5.99995 7.05273H9.99995M5.99995 9.57905L7.49995 9.57907M9.39469 9.57907H9.40101"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.26318 2H6.73686C6.38805 2 6.10529 2.28277 6.10529 2.63158C6.10529 2.98039 6.38805 3.26316 6.73686 3.26316H9.26318C9.61199 3.26316 9.89476 2.98039 9.89476 2.63158C9.89476 2.28277 9.61199 2 9.26318 2Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
