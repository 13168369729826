import React, { CSSProperties, ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Icon, { IconType } from '../Icon';
import { FontSizes } from '../theme/styles';

export interface CloseButtonProps {
  to?: string;
  className?: string;
  style?: CSSProperties;
  onClick?(): void;
  layoutTop?: boolean;
  lightBG?: boolean;
  fixed?: boolean;
  light?: boolean;
  label?: string;
}

const useStyles = createUseStyles<CloseButtonProps>({
  root: (props) => ({
    display: props.label ? 'flex' : 'inline-block',
    alignItems: props.label ? 'center' : 'unset',
    border: 'none',
    background: 'none',
    padding: '0.5em',
    fontSize: '12px',
    margin: 0,
    verticalAlign: 'middle',
    cursor: 'pointer',
    zIndex: 999,

    '&.layoutTop': {
      position: (props) => (props.fixed ? 'fixed' : 'absolute'),
      top: '46px',
      right: '46px',
    },

    '&.lightBG': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(247, 247, 247, 0.8)',
      backdropFilter: 'blur(5px)',
      borderRadius: '64px',
      padding: '8px',
      position: 'fixed',
    },
  }),
  label: {
    marginLeft: '8px',
    fontSize: FontSizes.regular,
  },
});

export default function CloseButton(props: CloseButtonProps): ReactElement {
  const { to, onClick, className, style, layoutTop, lightBG } = props;
  const styles = useStyles(props);

  const elmProps = {
    className: cx(styles.root, { layoutTop, lightBG }, className),
    style,
    onClick,
    children: (
      <>
        <Icon
          type={props.light ? IconType.CloseLight : IconType.Close}
          size="exact"
          style={{ cursor: 'pointer' }}
        />
        {props.label ? (
          <span className={styles.label}>{props.label}</span>
        ) : null}
      </>
    ),
  };

  return to ? <Link to={to} {...elmProps} /> : <button {...elmProps} />;
}
