import { Crop } from 'react-image-crop';

/**
 * Executes a function when an image finishes loading
 * @param {string} src - The url of the image to load
 * @param {function} callback - The function to run once the image finished loading
 */
export const onImageLoad = (src: string, callback: () => unknown): void => {
  const downloadingImage = new Image();
  downloadingImage.src = src;
  downloadingImage.onload = callback;
};

/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 * @param {String} fileType - Type of the returned file in Promise
 */
export function getCroppedImg(
  image: HTMLImageElement,
  crop: Crop,
  fileName: string,
  fileType?: string,
): Promise<Blob | null> {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width ? crop.width * scaleX : 0;
  canvas.height = crop.height ? crop.height * scaleY : 0;
  const ctx = canvas.getContext('2d');

  ctx &&
    ctx.drawImage(
      image,
      crop.x ? crop.x * scaleX : 0,
      crop.y ? crop.y * scaleY : 0,
      crop.width ? crop.width * scaleX : 0,
      crop.height ? crop.height * scaleY : 0,
      0,
      0,
      crop.width ? crop.width * scaleX : 0,
      crop.height ? crop.height * scaleY : 0,
    );

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      fileType || 'image/jpeg',
      1,
    );
  });
}

export function generateThumbnail(url: string | undefined): string | false {
  if (!url) return false;
  const parsedUrl = new URL(url);
  if (parsedUrl.hostname === 'ucarecdn.com') {
    const uuid = parsedUrl.pathname.split('/')[1];
    return `https://ucarecdn.com/${uuid}/video/-/thumbs~1/0/`;
  } else {
    return false;
  }
}
