import {
  TeamUpRequestAcceptedNotificationObject,
  NotificationType,
} from '@a_team/models/dist/NotificationObject';
import React from 'react';
import Notification from './Notification';
import { useHistory } from 'react-router-dom';
import { MissionControlAppliedLocation } from '@src/locations';

interface TeamUpRequestAcceptedNotificationProps {
  notification: TeamUpRequestAcceptedNotificationObject;
  onMarkAsRead(): void;
  isRead?: boolean;
  toggle: () => void;
}

const TeamUpRequestAcceptedNotification = ({
  notification,
  onMarkAsRead,
  isRead,
  toggle,
}: TeamUpRequestAcceptedNotificationProps): JSX.Element => {
  const history = useHistory();

  const onCtaAction = () => {
    onMarkAsRead();
    toggle();
    history.push(MissionControlAppliedLocation);
  };

  const requestedName = notification.userRecommendation.requested.firstName;
  const missionTitle = notification.userRecommendation.mission.title;
  const requestedProfilePictureURL =
    notification.userRecommendation.requested.profilePictureURL;
  const title = `${requestedName} accepted your team up request!`;
  const missionLogo = notification.userRecommendation.mission.logoURL;

  const description = `${requestedName} wants to team up on ${missionTitle}`;

  return (
    <Notification
      createdAt={notification.createdAt}
      notificationType={NotificationType.TeamUpRequestAccepted}
      ctaName="Open mission"
      ctaAction={onCtaAction}
      title={title}
      description={description}
      companyLogoUrl={missionLogo}
      profilePictureURL={requestedProfilePictureURL}
      onMarkAsRead={onMarkAsRead}
      isRead={isRead}
    />
  );
};

export default TeamUpRequestAcceptedNotification;
