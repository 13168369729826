import {
  ApplicationStatusUserColors,
  CollapsibleContainer,
} from '@ateams/components';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { ContinentObject, CountryObject } from '../index';

interface ContinentProps {
  continent: ContinentObject;
  open: boolean;
}

const useStyles = createUseStyles({
  root: {
    '& > div[class^="headerContainer"]': {
      fontSize: '14px',
      padding: '14px 10px',
      borderBottom: `1px solid ${ApplicationStatusUserColors.tag}`,
      margin: '4px 0',
      '&.open': {
        backgroundColor: '#F7F7F7',
      },
    },
    '& > div[class^="content"]': {
      paddingLeft: '10px',
      paddingRight: '10px',
      '&.open': {
        marginTop: '20px',
      },
    },
  },
  title: {
    fontWeight: '500 !important',
  },
  countryName: {
    display: 'inline-block',
    width: '50%',
    marginBottom: '12px',
  },
  countryFlag: {
    marginRight: '8px',
  },
});

const Continent = ({ continent, open }: ContinentProps): ReactElement => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <CollapsibleContainer
        openDefault={open}
        title={continent.name}
        titleClassName={styles.title}
        arrowPosition="left"
        right={
          <div>
            {continent.countries.length}/{continent.totalCountries}
          </div>
        }
      >
        {continent.countries.map((country: CountryObject) => (
          <div
            key={`${continent.name}-${country.name}`}
            className={styles.countryName}
            data-testing-id="country"
          >
            <div>
              <span className={styles.countryFlag}>
                <img src={country.flagSVG} alt={country.name} width="15" />
              </span>
              {country.name}
            </div>
          </div>
        ))}
      </CollapsibleContainer>
    </div>
  );
};

export default Continent;
