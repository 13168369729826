import { apiTeamPulse } from '@ateams/api';
import { AdminGetTeamPulseUserAverageRatingResponse } from '@ateams/api/dist/endpoints/TeamPulse';
import { List, ListItem } from '@ateams/components';
import { UserId } from '@a_team/models/dist/UserObject';
import { useStores } from '@src/stores';
import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { BuilderRatingsListItemContent } from './BuilderRatingsListItemContent';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flex: '1 0 auto',
    gap: 20,
    marginBottom: '2em',
  },
  column: {
    flex: '1 0 auto',
  },
  listContainer: {
    backgroundColor: '#fff',
  },
});

interface UserAverageRatingsState {
  global?: AdminGetTeamPulseUserAverageRatingResponse;
  lastPaymentCycle?: AdminGetTeamPulseUserAverageRatingResponse;
}

interface Props {
  userId: UserId;
}

export const BuilderRatings: React.FC<Props> = ({ userId }) => {
  const { auth } = useStores();
  const styles = useStyles();

  const [userAverageRatings, setUserAverageRatings] =
    useState<UserAverageRatingsState>({});

  useEffect(() => {
    userId &&
      Promise.all([
        apiTeamPulse.adminGetTeamPulseUserAverageRating(auth, {
          userId,
          onlyLastPaymentCycle: false,
        }),
        apiTeamPulse.adminGetTeamPulseUserAverageRating(auth, {
          userId,
          onlyLastPaymentCycle: true,
        }),
      ]).then(
        ([userAverageRatingResponse, userAverageRatingLastCycleResponse]) => {
          setUserAverageRatings({
            global: userAverageRatingResponse,
            lastPaymentCycle: userAverageRatingLastCycleResponse,
          });
        },
      );
  }, [userId]);

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <h3>Builder ratings</h3>
        <List className={styles.listContainer}>
          <ListItem>
            <BuilderRatingsListItemContent
              label="Average Builder Pulse"
              count={userAverageRatings.global?.fromRegularUsers?.count || 0}
              rating={userAverageRatings.global?.fromRegularUsers?.rating || 0}
            ></BuilderRatingsListItemContent>
          </ListItem>
          <ListItem>
            <BuilderRatingsListItemContent
              label="Average Last Cycle"
              count={
                userAverageRatings.lastPaymentCycle?.fromRegularUsers?.count ||
                0
              }
              rating={
                userAverageRatings.lastPaymentCycle?.fromRegularUsers?.rating ||
                0
              }
            ></BuilderRatingsListItemContent>
          </ListItem>
        </List>
      </div>
      <div className={styles.column}>
        <h3>Client ratings</h3>
        <List className={styles.listContainer}>
          <ListItem>
            <BuilderRatingsListItemContent
              label="Average Builder Pulse"
              count={userAverageRatings.global?.fromCompanyUsers?.count || 0}
              rating={userAverageRatings.global?.fromCompanyUsers?.rating || 0}
            ></BuilderRatingsListItemContent>
          </ListItem>
          <ListItem>
            <BuilderRatingsListItemContent
              label="Average Last Cycle"
              count={
                userAverageRatings.lastPaymentCycle?.fromCompanyUsers?.count ||
                0
              }
              rating={
                userAverageRatings.lastPaymentCycle?.fromCompanyUsers?.rating ||
                0
              }
            ></BuilderRatingsListItemContent>
          </ListItem>
        </List>
      </div>
    </div>
  );
};
