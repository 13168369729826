import Checkbox from '@src/components/Checkbox';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface CoCChecksProps {
  checkedBoxes: Set<string>;
  onCheckboxChange: (id: string, checked: boolean) => void;
}

interface CheckboxItem {
  id: string;
  text: string;
}

export const TOTAL_CHECKBOXES = 3;

const COC_ITEMS: CheckboxItem[] = [
  {
    id: 'checkbox1',
    text: "I will not contact A.Team's clients about my application or solicit work from them outside of the A.Team platform.",
  },
  {
    id: 'checkbox2',
    text: 'I will uphold zero tolerance for discrimination, harassment, or inappropriate behavior (e.g., derogatory language, intimidation, etc.).',
  },
  {
    id: 'checkbox3',
    text: 'I will declare my actual, physical location on my profile.',
  },
];

const useStyles = createUseStyles({
  description: {
    marginBottom: 16,
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  listItem: {
    marginTop: 8,
    borderRadius: 16,
    backgroundColor: '#F9FAFC',
    padding: 24,
    display: 'flex',
    gap: 16,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F0F2F7',
    },
    '&:focus-visible': {
      outline: '2px solid #007AFF',
      outlineOffset: '2px',
    },
  },
  checkboxWrapper: {
    '& > div': {
      marginTop: 2,
    },
  },
});

function CoCChecks({ checkedBoxes, onCheckboxChange }: CoCChecksProps) {
  const styles = useStyles();

  const handleChange =
    (id: string) => (e?: React.MouseEvent | React.ChangeEvent) => {
      e?.stopPropagation();
      onCheckboxChange(id, !checkedBoxes.has(id));
    };

  return (
    <div role="region">
      <h2 id="coc-title">Agree to the A.Team Code of Conduct</h2>
      <div className={styles.description}>
        By opting in, you're agreeing to follow A.Team's Code of Conduct, which
        ensures a rewarding, safe, and productive experience for everyone on the
        platform. Here are some of the key points you're committing to:
      </div>
      <ul className={styles.list}>
        {COC_ITEMS.map(({ id, text }) => (
          <li
            key={id}
            className={styles.listItem}
            onClick={handleChange(id)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleChange(id)();
              }
            }}
            role="checkbox"
            aria-checked={checkedBoxes.has(id)}
            tabIndex={0}
          >
            <span className={styles.checkboxWrapper}>
              <Checkbox
                checked={checkedBoxes.has(id)}
                onChange={handleChange(id)}
              />
            </span>
            {text}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CoCChecks;
