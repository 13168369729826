import { Button } from '@ateams/components';
import { useAnalyzeMissionApplication } from '@src/rq/missions';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  row: {
    marginBottom: 4,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    width: '136px',
    height: '36px',
    padding: 0,
    borderRadius: 8,
  },
});

interface AnalyzeMissionApplicationProps {
  missionApplicationId: string | undefined;
  reAnalyze: boolean;
  onAnalyzing: () => void;
  onSuccess: () => void;
}

const AnalyzeMissionApplication = (props: AnalyzeMissionApplicationProps) => {
  const styles = useStyles();
  const {
    mutate: analyzeMissionApplication,
    isLoading,
    isError,
    isSuccess,
  } = useAnalyzeMissionApplication();

  useEffect(() => {
    props.onSuccess();
  }, [isSuccess]);

  const handleClick = () => {
    if (props.missionApplicationId) {
      props.onAnalyzing();
      analyzeMissionApplication(props.missionApplicationId);
    }
  };

  const renderMessage = () => {
    if (isLoading) {
      return `Hang tight, we're ${
        props.reAnalyze ? 're-' : ''
      }analyzing this application. This can take some time.`;
    }

    if (isError) {
      return `An error occured while analyzing this application. Please try again later.`;
    }

    return props.reAnalyze ? '' : `This application has not yet been analyzed.`;
  };

  return (
    <div className={styles.row}>
      <span>{renderMessage()}</span>
      <Button
        disabled={isLoading}
        onClick={handleClick}
        data-testing-id={'analyze-mission-application'}
        className={styles.button}
      >
        {`Analyze ${props.reAnalyze ? 'again' : 'now'}`}
      </Button>
    </div>
  );
};

export default AnalyzeMissionApplication;
