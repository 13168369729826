import React, { ReactElement } from 'react';
import TabButtons, { TabButton } from '@src/components/TabButtons';
import { createUseStyles } from 'react-jss';
import {
  AdminNotesTab,
  AdminNotesTabLabels,
  AdminNotesTabsState,
} from '@src/views/AdminNotes/Tabs/hooks';

interface Props {
  navbar?: boolean;
  state: AdminNotesTabsState;
}

const useStyles = createUseStyles({
  root: {
    margin: (props: Props) => (props.navbar ? 0 : `-40px 0 64px 0`),
    background: 'none',
    boxShadow: 'none',
  },
});

export default function AdminNotesTabs(props: Props): ReactElement {
  const [tab, setTab] = props.state;
  const styles = useStyles(props);

  return (
    <TabButtons className={styles.root}>
      {Object.entries(AdminNotesTabLabels).map(([key, label]) => (
        <TabButton
          key={key}
          active={tab === key}
          onClick={() => setTab(key as AdminNotesTab)}
        >
          {label}
        </TabButton>
      ))}
    </TabButtons>
  );
}
