import { createQueryKeys } from '@lukemorales/query-key-factory';

export const profileKeys = createQueryKeys('profile', {
  byUsername: (username: string) => ({
    username,
  }),
  experienceGraphAugmentedBuilder: (uid: string, confidence: number) => ({
    uid,
    confidence,
  }),
  calendar: (userId: string) => ({
    userId,
  }),
  interviewFrequency: (userId: string) => ({
    userId,
  }),
  applicantFlags: (userId: string) => ({
    userId,
  }),
  profilePictureAnalysis: (url: string) => ({ url }),
});
