import { Button as CallToActionButton } from '@ateams/components';
import LocationObject from '@a_team/models/dist/LocationObject';
import SectionHeading from '@src/components/SectionHeading';
import { SidebarSection } from '@src/views/Profile/Sidebar/SidebarSection';
import React, { ReactElement, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Modal, { ModalBasicProps } from '..';
import {
  LocationAutocompleteV2,
  OnLocationChange,
} from '@src/components/LocationAutocompleteV2';
import { parseLocation } from '@src/components/LocationAutocompleteV2/utils';

interface SetLocationModalProps extends ModalBasicProps {
  onSubmit: (location: LocationObject) => void;
}

const useStyles = createUseStyles({
  title: {
    fontSize: 28,
  },
  description: {
    fontSize: 14,
    marginBottom: 24,
  },
  select: {
    '& *': {
      zIndex: '1000 !important',
    },
  },
  buttonBox: {
    marginTop: 60,
    display: 'flex',
    gap: '16px',
    '& > button': {
      width: '100%',
      height: 'auto',
    },
  },
});

const SetLocationModal = ({
  onClose,
  onSubmit,
  open,
}: SetLocationModalProps): ReactElement => {
  const styles = useStyles();
  const [location, setLocation] = useState<LocationObject | null>(null);

  const onLocationChange: OnLocationChange = (options) => {
    if (!options) return;
    const option = Array.isArray(options) ? options[0] : options;
    const { prediction, countryShortName } = option;

    if (prediction?.structured_formatting) {
      const location = parseLocation(
        prediction.structured_formatting,
        countryShortName,
      );
      setLocation(location);
    }
  };

  return (
    <Modal
      onClose={onClose}
      hideCloseButton={true}
      open={open}
      style={{
        maxWidth: '600px',
        width: '100%',
        padding: '24px',
        overflow: 'visible',
      }}
    >
      <div>
        <SectionHeading className={styles.title} isFirst>
          Please add your location
        </SectionHeading>

        <div className={styles.description}>
          Your location is required for matching you with missions that have
          specific location requirements.
        </div>

        <SidebarSection title={'Location'} withAddIcon={false}>
          <LocationAutocompleteV2
            openMenuOnClick={true}
            onChange={onLocationChange}
            isOutlined={false}
            margin={'none'}
            className={styles.select}
            data-testing-id="location-autocomplete"
          />
        </SidebarSection>

        <div className={styles.buttonBox}>
          <CallToActionButton
            width="auto"
            onClick={() => {
              onClose();
            }}
            size="small"
            color={'regularLight'}
          >
            Cancel
          </CallToActionButton>
          <CallToActionButton
            width="auto"
            onClick={() => {
              if (location) {
                onSubmit(location);
              }
            }}
            disabled={false}
            size="small"
            data-testing-id="save-location-changes"
          >
            Save
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
};

export default SetLocationModal;
