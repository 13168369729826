import React from 'react';
import InfoBox from '../InfoBox';

const AteamRole = (): JSX.Element => {
  return (
    <div>
      <h4>A.Team’s Role</h4>
      <div>
        <p>
          <strong>Facilitating conversations</strong>
        </p>
        <p>
          Once selected for a mission, please be advised A.Team can help
          facilitate conversations with the company, including about billing.
          Billing is bi-weekly - the time a company may take to pay an invoice
          is 3 weeks, on average, and immediately thereafter A.Team advances
          payment. If 4 weeks have passed, let us know and we’ll advance payment
          immediately (all provided no dispute is in place).
        </p>
      </div>

      <div>
        <p>
          <strong>Feedback</strong>
        </p>
        <p>
          Provided you have any feedback for a company - reaching out to A.Team
          would increase the value we can provide to the community, being
          responsible for our colleagues’ experiences. You may also freely share
          feedback directly with the company which should provide them with the
          chance to mitigate any concerns.
        </p>

        <p>
          <strong>
            These are the foundations of the community and breaches may result
            in removal.
          </strong>
        </p>
      </div>

      <InfoBox />
    </div>
  );
};

export default AteamRole;
