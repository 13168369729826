import React, { ReactElement, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  AdminNotesScore,
  ExpertiseScore,
} from '@a_team/models/dist/AdminNotesObject';
import { AdminNotesRequestPayload } from '@ateams/api/dist/endpoints/Users';
import AdminNotesObject from '@a_team/models/dist/AdminNotesObject';
import { TalentCategoryId } from '@a_team/models/dist/TalentCategories';
import PhoneNumberInput from '@src/components/Inputs/PhoneNumberInput';
import { UserStatusInput } from './UserStatusInput';
import { AceScoreInput } from './AceScoreInput';
import { UserScrubbed } from '@a_team/models/dist/UserObject';
import {
  AccentScoreOptions,
  EnglishLevelOptions,
  ExpertiseScoreOptions,
  InteractionExperienceOptions,
} from '@src/components/ScoreInput/Scores';
import { ScoreInput } from '@src/components/ScoreInput/ScoreInput';
import { Checkbox, Select } from '@ateams/components';
import useToggle from '@src/hooks/useToggle';
import {
  EvaluationInviteModal,
  OnEvaluationInviteSubmit,
} from '@src/components/Modal/EvaluationInviteModal';
import { useStores } from '@src/stores';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import { DateISOString } from '@a_team/models/dist/misc';
import { format } from 'date-fns';
import { getLocalTime } from '@src/helpers/time';
import { CustomUserTagObject } from '@a_team/models/dist/CustomUserTagObject';

enum AdminNotesScoreType {
  InteractionExperience,
  Expertise,
  Accent,
  EnglishLevel,
}

const UnqualifiedScores = [0, 1];

interface Props {
  notes: AdminNotesObject;
  mainTalentCategoryId?: TalentCategoryId;
  scrubbedStatus?: UserScrubbed;
  onScrubbedStatusChange?: (value: UserScrubbed) => void;
  canScrub?: boolean;
  verified: boolean;
  interviewed: boolean;
  beenOnMission: boolean;
  selectionTeam: boolean;
  hasBeenScrubbed: boolean;
  vettingScheduled: boolean;
  preventPrepay?: boolean;
  altPhone?: string;
  updateNotes: (data: AdminNotesRequestPayload) => void;
  setSelectionTeam: (isVetter: boolean) => void;
  onEvaluationInvite: OnEvaluationInviteSubmit;
  isUserMarkedAsRejected: boolean;
  rejectionEmailSentAt?: DateISOString;
  onClickOnMarkAsRejectedUser: (rejected: boolean) => void;
  userFirstName: string;
  availableCustomUserTags?: CustomUserTagObject[];
  onCustomUserTagsChange?: (tagIds: string[]) => void;
  userCustomTags?: CustomUserTagObject[];
  unsubscribeAllPlatformEmails?: boolean;
  onUnsubscribeAllPlatformEmailsChange?: (value: boolean) => void;
  pendingAutomatedRejection?: boolean;
  onClickCancelAutomatedRejection: () => void;
}

const useStyles = createUseStyles({
  label: {
    fontSize: 14,
    lineHeight: '2.6em',
    color: '#62646A',
  },
  userRejected: {
    margin: 0,
  },
  userRejectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    margin: '12px 12px 24px 0px',
  },
  cancelAutomatedRejectionButton: {
    color: '#fe630e',
    fontSize: '14px',
    fontWeight: '400',
    cursor: 'pointer',
  },
});

export const AdminInput = (props: Props): ReactElement => {
  const styles = useStyles();
  const { auth, vetters } = useStores();
  const { notes, altPhone, updateNotes } = props;
  const [evaluationInviteModalOpen, toggleEvaluationInviteModalOpen] =
    useToggle();

  const [phoneNumber, setPhoneNumber] = useState<string>(
    notes.phoneNumber || altPhone || '',
  );

  const handleScoreChange = (
    type: AdminNotesScoreType,
    value: AdminNotesScore | null,
  ) => {
    if (UnqualifiedScores.some((score) => score === value)) {
      setIsRejectionConfirmationModalOpen(true);
    } else if (
      typeof value === 'number' &&
      type === AdminNotesScoreType.Expertise &&
      value <= ExpertiseScore.Adequate
    ) {
      setIsRejectionConfirmationModalOpen(true);
    }
    switch (type) {
      case AdminNotesScoreType.InteractionExperience:
        updateNotes({
          scores: {
            ...notes.scores,
            interactionExperience: value,
          },
        });
        break;
      case AdminNotesScoreType.Expertise:
        updateNotes({
          scores: {
            ...notes.scores,
            expertise: value,
          },
        });
        break;
      case AdminNotesScoreType.EnglishLevel:
        updateNotes({
          scores: {
            ...notes.scores,
            english: value,
          },
        });
        break;
      case AdminNotesScoreType.Accent:
        updateNotes({
          scores: {
            ...notes.scores,
            accent: value,
          },
        });
        break;
    }
  };

  const rejectionEmailSentText = useMemo(() => {
    return props.rejectionEmailSentAt
      ? `(Email sent at ${format(
          getLocalTime(new Date(props.rejectionEmailSentAt)),
          'MMM dd, yyyy',
        )})`
      : null;
  }, [props.rejectionEmailSentAt]);

  const [
    isRejectionConfirmationModalOpen,
    setIsRejectionConfirmationModalOpen,
  ] = useState<boolean>(false);
  const [
    isAutomatedRejectionCancelModalOpen,
    setIsAutomatedRejectionCancelModalOpen,
  ] = useState<boolean>(false);

  const handlePhoneNumber = (phone: string) => {
    isValidPhoneNumber(phone) && updateNotes({ phoneNumber: phone });
    setPhoneNumber(phone);
  };

  const onScheduleVetting: OnEvaluationInviteSubmit = (...args) => {
    props.onEvaluationInvite(...args);
    toggleEvaluationInviteModalOpen();
  };

  const onOpenScheduleVettingModal = async () => {
    if (auth.isVetter && !vetters.vetterConfiguration) {
      await vetters.getVettingConfiguration();
    }

    toggleEvaluationInviteModalOpen();
  };

  const handleCancelAutomatedRejection = () => {
    setIsAutomatedRejectionCancelModalOpen(true);
  };

  return (
    <>
      <div style={{ margin: '12px 0' }}>
        <Checkbox
          id="unsubscribedAllPlatformEmails"
          checked={!!props.unsubscribeAllPlatformEmails}
          onChange={() =>
            props.onUnsubscribeAllPlatformEmailsChange &&
            props.onUnsubscribeAllPlatformEmailsChange(
              !props.unsubscribeAllPlatformEmails,
            )
          }
          label={`Unsubscribe from all platform emails`}
        />
      </div>
      {evaluationInviteModalOpen && (
        <EvaluationInviteModal
          open
          defaultEvaluationType={vetters.vetterConfiguration?.type}
          defaultCategory={props.mainTalentCategoryId}
          onClose={toggleEvaluationInviteModalOpen}
          onSubmit={onScheduleVetting}
        />
      )}
      <div style={{ margin: '12px 0' }}>
        <Checkbox
          id="vettingScheduled"
          checked={!!props.vettingScheduled}
          onChange={onOpenScheduleVettingModal}
          label={`Invited to evaluation`}
        />
      </div>
      <ConfirmModal
        open={isRejectionConfirmationModalOpen}
        onConfirm={() => {
          props.onClickOnMarkAsRejectedUser(true);
          setIsRejectionConfirmationModalOpen(false);
        }}
        title={`Do you want to reject ${props.userFirstName}?`}
        description="Be careful, the user won't be able to be un-rejected once the email is sent."
        onClose={() => setIsRejectionConfirmationModalOpen(false)}
        actionLabel="Reject"
        cancelLabel="Do Not Reject"
      ></ConfirmModal>
      <ConfirmModal
        open={isAutomatedRejectionCancelModalOpen}
        onConfirm={() => {
          props.onClickCancelAutomatedRejection();
          setIsAutomatedRejectionCancelModalOpen(false);
        }}
        title={`Do you want to cancel automated rejection for ${props.userFirstName}?`}
        description="Are you sure?"
        onClose={() => setIsAutomatedRejectionCancelModalOpen(false)}
        actionLabel="Cancel"
        cancelLabel="Do Not Cancel"
      ></ConfirmModal>
      <div className={styles.userRejectionContainer}>
        <Checkbox
          id="userRejected"
          className={styles.userRejected}
          checked={!!props.isUserMarkedAsRejected}
          onChange={(event) =>
            event.target.checked
              ? setIsRejectionConfirmationModalOpen(true)
              : props.onClickOnMarkAsRejectedUser(false)
          }
          label={
            <>
              Rejected
              {rejectionEmailSentText ? (
                <span className={styles.label}> {rejectionEmailSentText}</span>
              ) : (
                <></>
              )}
            </>
          }
        />
        {props.pendingAutomatedRejection && (
          <span
            className={styles.cancelAutomatedRejectionButton}
            onClick={handleCancelAutomatedRejection}
          >
            Cancel Automated Rejection
          </span>
        )}
      </div>
      <Select
        disableLocalValue={true}
        placeholder="Select a tag"
        margin="none"
        overrideStyles={{ control: (styles) => ({ ...styles, height: 56 }) }}
        label="User tags"
        value={props.userCustomTags?.map((userTag) => ({
          value: userTag._id,
          label: userTag.name,
        }))}
        isMulti
        options={
          props.availableCustomUserTags?.map((userTag) => ({
            value: userTag._id,
            label: userTag.name,
          })) || []
        }
        onChange={(selectedOptions) => {
          props.onCustomUserTagsChange &&
            props.onCustomUserTagsChange(
              selectedOptions.map((option) => option.value),
            );
        }}
        onRemoveOption={(option) => {
          props.onCustomUserTagsChange &&
            props.onCustomUserTagsChange(
              props.userCustomTags
                ?.filter((userTag) => userTag._id !== option.value)
                .map((userTag) => userTag._id) || [],
            );
        }}
      ></Select>
      <UserStatusInput
        verified={props.verified}
        interviewed={props.interviewed}
        beenOnMission={props.beenOnMission}
        selectionTeam={props.selectionTeam}
        hasBeenScrubbed={props.hasBeenScrubbed}
        setSelectionTeam={props.setSelectionTeam}
      />
      {props.canScrub && (
        <AceScoreInput
          value={props.scrubbedStatus}
          onChange={props.onScrubbedStatusChange}
          title={'Ace Score'}
        />
      )}
      <ScoreInput
        scores={ExpertiseScoreOptions}
        value={notes.scores?.expertise}
        onChange={(value) =>
          handleScoreChange(AdminNotesScoreType.Expertise, value)
        }
        title={'Expertise Level'}
      />
      <ScoreInput
        scores={InteractionExperienceOptions}
        value={notes.scores?.interactionExperience}
        onChange={(value) =>
          handleScoreChange(AdminNotesScoreType.InteractionExperience, value)
        }
        title={'Interpersonal Skills'}
      />
      <ScoreInput
        scores={EnglishLevelOptions}
        value={notes.scores?.english}
        onChange={(value) =>
          handleScoreChange(AdminNotesScoreType.EnglishLevel, value)
        }
        title={'English Proficiency'}
      />
      <ScoreInput
        scores={AccentScoreOptions}
        value={notes.scores?.accent}
        onChange={(value) =>
          handleScoreChange(AdminNotesScoreType.Accent, value)
        }
        title={'English Pronunciation'}
      />
      <div style={{ marginTop: 12 }}>
        <span className={styles.label}>Alternative Phone Number</span>
        <PhoneNumberInput
          value={phoneNumber}
          onChange={handlePhoneNumber}
          style={{ margin: 0, color: phoneNumber ? '#000' : '#222' }}
        />
      </div>
      <div style={{ marginTop: 12 }}>
        <Checkbox
          id="preventPrepay"
          checked={!!props.preventPrepay}
          onChange={(e) =>
            props.updateNotes({ preventPrepay: e.target.checked })
          }
          label={`Prevent Prepay`}
        />
      </div>
    </>
  );
};
