import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  progressBarContainer: {
    height: '12px',
    backgroundColor: '#EDE0FF',
    borderRadius: '6px',
    position: 'relative',
    boxSizing: 'border-box',
  },
  progressBarFiller: {
    height: '100%',
    backgroundColor: '#6D00D7',
    borderRadius: 'inherit',
    transition: 'width 0.2s ease-in',
  },
  progressDot: {
    width: '6px',
    height: '6px',
    backgroundColor: '#D4A8FF',
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0 2px',
  },
  filledDot: {
    backgroundColor: '#FFF',
  },
});

interface ProgressBarProps {
  totalSteps: number;
  currentStep: number;
}

const ProgressBar = ({ totalSteps, currentStep }: ProgressBarProps) => {
  const styles = useStyles();
  const progressBarRef = useRef<HTMLDivElement | null>(null);
  const [stepWidth, setStepWidth] = useState(0);

  useEffect(() => {
    const updateStepWidth = () => {
      if (progressBarRef.current) {
        const totalWidth = progressBarRef.current.offsetWidth;
        setStepWidth(totalWidth / totalSteps);
      }
    };

    updateStepWidth();

    window.addEventListener('resize', updateStepWidth);

    return () => {
      window.removeEventListener('resize', updateStepWidth);
    };
  }, [totalSteps]);

  const paddingToCoverDot = 3; // assuming the dot is 6px in diameter
  const fillerWidth = stepWidth * currentStep + paddingToCoverDot;

  return (
    <div ref={progressBarRef} className={styles.progressBarContainer}>
      <div
        className={currentStep > 0 ? styles.progressBarFiller : ''}
        style={{ width: fillerWidth }}
      >
        {[...Array(totalSteps + 1)].map((_, index) => {
          if (index === 0) return null;

          const isLastStep = index === totalSteps;

          return (
            <div
              key={index}
              className={`${styles.progressDot} ${
                index <= currentStep ? styles.filledDot : ''
              }`}
              style={{ left: stepWidth * index - (isLastStep ? 7 : 4) }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProgressBar;
