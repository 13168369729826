import React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { VettingProcessFeedbackAdditionalMaterial } from '@a_team/models/dist/vetting-processes/feedback-additional-material';
import { Spacing } from '@ateams/components';
import {
  Size,
  Text,
} from '@src/views/VettingFeedbackForm/components/typography';
import { AdditionalMaterial } from '@src/views/VettingFeedbackForm/components/additional-material';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginBottom: {
    marginBottom: Spacing.small,
  },
  additionalMaterialsContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: Spacing.small,
  },
});

export interface VettingProcessSummaryAdditionalMaterialsProps {
  additionalMaterials: VettingProcessFeedbackAdditionalMaterial[];
  className?: string;
}

export const VettingProcessSummaryAdditionalMaterials: React.FC<
  VettingProcessSummaryAdditionalMaterialsProps
> = (props) => {
  const styles = useStyles();

  return (
    <div className={cx(styles.container, props.className)}>
      <Text size={Size.Small} className={styles.marginBottom}>
        Additional Materials
      </Text>

      <div className={styles.additionalMaterialsContainer}>
        {props.additionalMaterials.map((additionalMaterial) => (
          <AdditionalMaterial
            key={additionalMaterial.id}
            additionalMaterial={additionalMaterial}
          />
        ))}
      </div>
    </div>
  );
};
