import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Checkbox, NestedSelect, SelectOption } from '@ateams/components';
import {
  continentsOptionsMap,
  countryListOptionMap,
} from '@src/helpers/rawCountryList';
import cx from 'classnames';

interface Props {
  countries: string[];
  requireCountry?: boolean;
  onChange: (countries: string[], requireCountry?: boolean) => void;
  sidebar?: boolean;
  hideTags?: boolean;
  isClearable?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  selectionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginBottom: 8,
  },
  checkbox: {
    width: '100%',
    paddingBottom: 12,
  },
});

const CountryList = (props: Props): ReactElement => {
  const styles = useStyles();
  const {
    countries,
    requireCountry,
    onChange,
    sidebar,
    hideTags,
    isClearable = true,
    className,
  } = props;

  const countrySelections: string[] | undefined = countries;

  const updateCountries = (
    options: SelectOption | SelectOption[] | null,
  ): void => {
    const country: string[] = !options
      ? []
      : Array.isArray(options)
      ? options.map(({ value }) => value)
      : [options.value];
    onChange(country);
  };

  return (
    <div className={cx(className, styles.selectionContainer)}>
      <Checkbox
        className={styles.checkbox}
        label="Require countries"
        checked={requireCountry ?? true}
        onChange={(e) => onChange(countries, e.target.checked ?? undefined)}
        margin="none"
      />
      <NestedSelect
        placeholder="Countries"
        items={countrySelections}
        onChange={(items) =>
          updateCountries(items.map((i) => ({ value: i, label: i })))
        }
        sidebar={sidebar}
        isClearable={isClearable}
        hideTags={hideTags}
        allItemsMap={countryListOptionMap}
        allItemGroups={continentsOptionsMap}
      />
    </div>
  );
};
export default CountryList;
