import React, { CSSProperties, ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface ProgressStepProps {
  label?: string;
  position: number; // percent
  color?: string;
  onClick?: (position: number) => void;
  style?: CSSProperties;
}

const useStyles = createUseStyles({
  container: (props: ProgressStepProps) => ({
    borderRadius: '50%',
    background: props.color || '#EFEFF0',
    width: 12,
    height: 12,
    border: '2px solid #FFFFFF',
    position: 'absolute',
    left: 0,
    top: 0,
    transition: 'transform 0.2s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.clickable': {
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.7)',
      },
    },
  }),
  '@keyframes label': {
    from: { transform: 'scale(0)' },
    to: { transform: 'scale(1)' },
  },
  label: {
    background: '#222222',
    borderRadius: 4,
    color: '#fff',
    textAlign: 'center',
    fontSize: 12,
    padding: '4px 8px',
    animation: '$label .25s',
  },
});

export const ProgressStep = (props: ProgressStepProps): ReactElement => {
  const styles = useStyles(props);

  return (
    <div
      onClick={() =>
        props.onClick ? props.onClick(props.position) : undefined
      }
      className={cx(styles.container, { clickable: !!props.onClick })}
      style={props.style}
    >
      {props.label && <span className={styles.label}>{props.label}</span>}
    </div>
  );
};
