import React, { CSSProperties, useState } from 'react';
import { useScreenClass } from 'react-grid-system';
import { createUseStyles } from 'react-jss';

import {
  CommunityBadgeObject,
  CommunityBadgeType,
  UserBadge as UserBadgeType,
} from '@a_team/models/dist/UserObject';
import UserBadge from '@src/components/Badges/UserBadge';
import { joinWithCommasAnd } from '@src/logic/utils';
import CommunityBadge from '@src/views/Profile/Sidebar/CommunityBadges/CommunityBadge';
import cx from 'classnames';
import TooltipWrapped from '../TooltipWrapped';
import CondensedBadge from './CondensedBadge';
import UserBadgeDetailed, {
  availableCommunityBadges,
} from './UserBadgeDetailed';

interface UserBadgesProps {
  badges?: UserBadgeType[];
  communityBadges?: CommunityBadgeObject[];
  expandOnHover?: boolean;
  lastScheduledVettingInterviewDate?: Date;
  style?: CSSProperties;
}

const useStyles = createUseStyles({
  badgesWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    position: 'relative',
    overflow: 'visible',
  },
  communityBadgesWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
  },
  hoveredCommunityBadgesWrapper: {
    position: 'absolute',
    zIndex: 9999,
    backgroundColor: '#FFFFFF',
    transition: 'all 0.3s ease-in-out',
    marginTop: 16,
    border: '1px solid #E5E5E5',
    borderRadius: 8,
    minWidth: '100%',
    width: 'auto',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    left: '50%',
    transform: 'translateX(-50%)',
    whiteSpace: 'nowrap',
    maxHeight: 'none',
    overflow: 'visible',
  },
  hoveredBetaBadgesWrapper: {
    display: ({ hoveredBadgesType }) =>
      hoveredBadgesType === 'beta' ? 'block' : 'none',
    opacity: ({ hoveredBadgesType }) => (hoveredBadgesType === 'beta' ? 1 : 0),
    visibility: ({ hoveredBadgesType }) =>
      hoveredBadgesType === 'beta' ? 'visible' : 'hidden',
  },
  hoveredGuildBadgesWrapper: {
    display: ({ hoveredBadgesType }) =>
      hoveredBadgesType === 'guild' ? 'block' : 'none',
    opacity: ({ hoveredBadgesType }) => (hoveredBadgesType === 'guild' ? 1 : 0),
    visibility: ({ hoveredBadgesType }) =>
      hoveredBadgesType === 'guild' ? 'visible' : 'hidden',
  },
});

const UserBadges: React.FC<UserBadgesProps> = ({
  badges,
  communityBadges,
  expandOnHover = false,
  lastScheduledVettingInterviewDate,
  style,
}) => {
  const [hoveredBadgesType, setHoveredBadgesType] = useState<
    'beta' | 'guild'
  >();
  const styles = useStyles({ hoveredBadgesType });
  const screenClass = useScreenClass();
  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  const statusBadges = badges?.filter(
    (badge) => badge !== UserBadgeType.SelectionTeam,
  );

  const betaBadges = communityBadges?.filter((badge) =>
    [
      CommunityBadgeType.PowerUser,
      CommunityBadgeType.CommunityLeader,
      CommunityBadgeType.ValuableFeedbackGiver,
    ].includes(badge.type),
  );

  const guildBadges = communityBadges?.filter((badge) =>
    [
      CommunityBadgeType.GuildFintech,
      CommunityBadgeType.GuildHealthcare,
      CommunityBadgeType.GuildAI,
      CommunityBadgeType.GuildFrontend,
      CommunityBadgeType.CourseAIAugmentedGraduate,
      CommunityBadgeType.GuildProduct,
      CommunityBadgeType.GuildCTO,
    ].includes(badge.type),
  );

  let betaBadgeTitles =
    betaBadges?.map((badge) => availableCommunityBadges[badge.type].title) ??
    [];

  /**
   * The selection team badge is a special case and we count it as a community badge for this purpose
   * @link https://buildateam.atlassian.net/browse/NEXUS-702
   */
  const userHasSelectionTeamBadge = badges?.includes(
    UserBadgeType.SelectionTeam,
  );

  if (userHasSelectionTeamBadge) {
    betaBadgeTitles = [
      availableCommunityBadges[UserBadgeType.SelectionTeam].title,
      ...betaBadgeTitles,
    ];
  }

  const joinedBetaBadgeTitles = joinWithCommasAnd(betaBadgeTitles);

  const joinedGuildBadgeTitles = joinWithCommasAnd(
    guildBadges?.map((badge) => availableCommunityBadges[badge.type].title) ||
      [],
  );

  const betaBadgesLength =
    (betaBadges?.length ?? 0) + (userHasSelectionTeamBadge ? 1 : 0);
  const guildBadgesLength = guildBadges?.length ?? 0;

  const handleMouseEnter = (type: 'beta' | 'guild') => {
    if (isMobile) {
      return;
    }
    setHoveredBadgesType(type);
  };

  const handleMouseLeave = () => {
    if (isMobile) {
      return;
    }
    setHoveredBadgesType(undefined);
  };

  const handleClick = (type: 'beta' | 'guild') => {
    if (!isMobile) {
      return;
    }
    if (hoveredBadgesType === type) {
      setHoveredBadgesType(undefined);
    } else {
      setHoveredBadgesType(type);
    }
  };

  const renderBetaBadges = () => {
    if (betaBadgesLength === 1) {
      if (badges && userHasSelectionTeamBadge) {
        return (
          <UserBadge
            size={24}
            badge={UserBadgeType.SelectionTeam}
            tooltipPosition="top"
            lastScheduledVettingInterviewDate={
              lastScheduledVettingInterviewDate
            }
          />
        );
      } else if (betaBadges) {
        const betaBadge = betaBadges[0];
        return (
          <CommunityBadge
            style={{ padding: 0 }}
            badgeSize={24}
            communityBadgeType={betaBadge.type}
            date={betaBadge.assignedAt}
          />
        );
      }
    }

    if (betaBadges && betaBadgesLength > 1) {
      return (
        <TooltipWrapped
          theme="dark"
          position="top"
          title={expandOnHover ? '' : joinedBetaBadgeTitles}
          animation="fade"
          disabled={expandOnHover}
          arrow
        >
          <span
            onMouseEnter={() => handleMouseEnter('beta')}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick('beta')}
            style={{ cursor: 'pointer' }}
          >
            <CondensedBadge type="beta" total={betaBadgesLength} />
          </span>
        </TooltipWrapped>
      );
    }

    return null;
  };

  const renderGuildBadges = () => {
    if (guildBadges && guildBadgesLength === 1) {
      const guildBadge = guildBadges[0];
      return (
        <CommunityBadge
          style={{ padding: 0, display: 'flex' }}
          badgeSize={24}
          communityBadgeType={guildBadge.type}
          date={guildBadge.assignedAt}
        />
      );
    }

    if (guildBadges && guildBadgesLength > 1) {
      return (
        <TooltipWrapped
          theme="dark"
          position="top"
          title={expandOnHover ? '' : joinedGuildBadgeTitles}
          animation="fade"
          disabled={expandOnHover}
          arrow
        >
          <span
            onMouseEnter={() => handleMouseEnter('guild')}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick('guild')}
            style={{ cursor: 'pointer' }}
          >
            <CondensedBadge type="guild" total={guildBadgesLength} />
          </span>
        </TooltipWrapped>
      );
    }

    return null;
  };

  const renderHoveredBadgesType = (
    type: 'beta' | 'guild',
    badges: CommunityBadgeObject[],
  ) => {
    if (badges.length === 0) {
      return null;
    }

    return (
      <div
        className={cx(
          styles.hoveredCommunityBadgesWrapper,
          type === 'beta'
            ? styles.hoveredBetaBadgesWrapper
            : styles.hoveredGuildBadgesWrapper,
        )}
      >
        {type === 'beta' && userHasSelectionTeamBadge && (
          <UserBadgeDetailed communityBadgeType={UserBadgeType.SelectionTeam} />
        )}
        {badges.map((badge) => (
          <UserBadgeDetailed key={badge.type} communityBadgeType={badge.type} />
        ))}
      </div>
    );
  };

  return (
    <div style={{ position: 'relative', overflow: 'visible' }}>
      <div className={styles.badgesWrapper} style={{ ...style }}>
        {statusBadges?.map((badge) => (
          <UserBadge
            key={badge}
            size={24}
            badge={badge}
            tooltipPosition="top"
            lastScheduledVettingInterviewDate={
              lastScheduledVettingInterviewDate
            }
          />
        ))}
        <span className={styles.communityBadgesWrapper}>
          {renderBetaBadges()}
          {renderGuildBadges()}
        </span>
      </div>
      {expandOnHover && (
        <>
          {renderHoveredBadgesType('beta', betaBadges ?? [])}
          {renderHoveredBadgesType('guild', guildBadges ?? [])}
        </>
      )}
    </div>
  );
};

export default UserBadges;
