import React, { ReactElement, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { observer } from 'mobx-react';

import { Breakpoints } from '@ateams/components';
import { VideoPlayer } from '@src/components/VideoPlayer';
import { MissionLink } from '@a_team/models/dist/MissionObject';

interface Props {
  attachedLinks?: MissionLink[];
  description?: string;
  videoURL?: string;
  onVideoPlay?: () => void;
  isShowing: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  description: {
    marginBottom: 40,
  },
  linkInfo: {
    marginBottom: 12,
  },
  modal: {
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    top: '-200%',
    flexShrink: 0,
    maxHeight: 'calc(100% - 80px)',
    overflowY: 'scroll',
    position: 'fixed',
    width: '100%',
    zIndex: -1,
    transition: 'top .5s .0s, z-index 0s .5s', // sliding out
    '&.isShowing': {
      top: 170,
      zIndex: 2,
      transition: 'top .2s 0s, z-index 0s 0s', // sliding in
    },
    '& h4': {
      fontWeight: 600,
      fontSize: '20px',
      marginBottom: 20,
      marginTop: 0,
    },
  },
  left: {
    padding: '45px 50px 0px 50px',
    width: '100%',
    '& p': {
      margin: '0 0 20px 0',
    },
  },
  right: {
    padding: '45px 50px 40px 50px',
    width: '100%',
  },
  scrollable: {
    height: 'calc(100% - 90px)',
    overflowY: 'scroll',
    paddingRight: '20px',
  },
  url: {
    marginRight: '20px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    left: {
      padding: '45px 50px 20px 50px',
    },
    modal: {
      flexDirection: 'row',
      top: '-100%',
      '&.isShowing': {
        top: '80px',
      },
    },
  },
});

function ApplyDetailsModalDrawer(props: Props): ReactElement {
  const {
    attachedLinks,
    description,
    isShowing,
    videoURL,
    className,
    onVideoPlay,
  } = props;
  const styles = useStyles();

  const [loading, setLoading] = useState(true);

  const onVideoError = (error: Error) => {
    setLoading(false);
  };

  const onVideoReady = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (!videoURL) setLoading(false);
  }, [videoURL]);

  return (
    <div
      data-testing-id={'mission-application-details-drawer'}
      className={cx(styles.modal, className, { isShowing })}
    >
      <div className={styles.left}>
        <h4>Mission Description</h4>
        <div className={styles.scrollable}>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: `<p>${description?.replace(/\n\n/g, '</p><p>')}</p>`,
            }}
          />

          {attachedLinks &&
            attachedLinks.map((link, key) => {
              return (
                <div className={styles.linkInfo} key={key}>
                  <span className={styles.url}>{link.title}</span>
                  <a rel="noreferrer" target="_blank" href={link.URL}>
                    {link.URL}
                  </a>
                  <br />
                </div>
              );
            })}
        </div>
      </div>
      {videoURL && (
        <div className={styles.right}>
          <h4>Video Description</h4>
          <VideoPlayer
            loading={loading}
            height={'300px'}
            url={videoURL}
            onPlay={onVideoPlay}
            onReady={onVideoReady}
            onError={onVideoError}
            width={'100%'}
          />
        </div>
      )}
    </div>
  );
}

export default observer(ApplyDetailsModalDrawer);
