import {
  ExpertiseScoreLabels,
  AdminNotesScore,
} from '@a_team/models/dist/AdminNotesObject';
import React from 'react';
import { BasicVettingProcessColumnProps } from '..';

export const ExpertiseField: React.FC<BasicVettingProcessColumnProps> = ({
  vettingProcess,
}) => {
  const expertise =
    vettingProcess.feedbackV2?.answers.scores.expertise ??
    vettingProcess.feedback?.answers.scores.expertise;

  if (typeof expertise !== 'number') {
    return null;
  }

  return <>{ExpertiseScoreLabels[expertise as AdminNotesScore]}</>;
};
