import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { FetchVettingProcessesSorting } from '@ateams/api/dist/endpoints/vetting-process';
import { OnSortChange } from '../vetting-table';

export const useSort = () => {
  const history = useHistory();

  const sorting = useMemo(() => {
    const { sorting } = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    return sorting || [];
  }, [history.location.search]) as unknown as FetchVettingProcessesSorting;

  const onSortChange: OnSortChange = (sortingKey, operator) => {
    const searchParams = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    }) as unknown as { sorting: FetchVettingProcessesSorting };

    searchParams.sorting = operator ? [{ sortingKey, operator }] : [];

    const newSearchParams = new URLSearchParams(
      qs.stringify(searchParams),
    ).toString();

    if (newSearchParams !== history.location.search.substring(1)) {
      history.replace({
        search: new URLSearchParams(qs.stringify(searchParams)).toString(),
      });
    }
  };

  return {
    sorting,
    onSortChange,
  };
};
