import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import DropdownInput from '@src/components/Inputs/DropdownInput';
import {
  UserScrubbed,
  UserScrubbedLabels,
} from '@a_team/models/dist/UserObject';

interface Props {
  title: string;
  value?: UserScrubbed;
  onChange?: (score: UserScrubbed) => void;
  placeholder?: string;
}

const useStyles = createUseStyles({
  label: {
    fontSize: 14,
    lineHeight: '2.6em',
    color: '#62646A',
  },
});

export const AceScoreInput = (props: Props): ReactElement => {
  const styles = useStyles();
  const { title, onChange, placeholder, value } = props;

  return (
    <div style={{ marginTop: 12 }}>
      <span className={styles.label}>{title}</span>
      <DropdownInput
        value={value}
        onChange={(e) => onChange && onChange(e.target.value as UserScrubbed)}
        placeholder={placeholder || 'Select a score'}
        margin="none"
        style={value ? {} : { color: '#222' }}
      >
        {Object.keys(UserScrubbedLabels).map((key) => (
          <option
            key={UserScrubbed[key as UserScrubbed]}
            value={UserScrubbed[key as UserScrubbed]}
          >
            {UserScrubbedLabels[key as UserScrubbed]}
          </option>
        ))}
      </DropdownInput>
    </div>
  );
};
