import React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { VettingProcessFeedbackAdditionalMaterial } from '@a_team/models/dist/vetting-processes/feedback-additional-material';
import { Spacing } from '@ateams/components';
import { Button } from './button';
import { XMarkIcon } from './icons/x-mark';
import { LoaderIcon } from './icons/loader';
import { Size, Text } from './typography';
import { AttachmentIcon } from './icons/attachment';

const useStyles = createUseStyles({
  container: {
    height: '32px',
    padding: '0 10px',
  },
  text: {
    fontWeight: 400,
  },
  fileSize: {
    marginLeft: Spacing.small,
    fontWeight: 300,
  },
});

export interface AdditionalMaterialProps {
  additionalMaterial?: VettingProcessFeedbackAdditionalMaterial;
  isUploading?: boolean;
  isDeleting?: boolean;
  onRemoveClick?: () => void;
  isReadOnly?: boolean;
  className?: string;
}

function formatFileSize(fileSize: number) {
  if (fileSize < 1024) {
    return `${fileSize}B`;
  } else if (fileSize < 1024 * 1024) {
    return `${Math.round(fileSize / 1024)}KB`;
  } else {
    return `${Math.round(fileSize / (1024 * 1024))}MB`;
  }
}

export const AdditionalMaterial: React.FC<AdditionalMaterialProps> = (
  props,
) => {
  const {
    additionalMaterial,
    isDeleting,
    isUploading,
    onRemoveClick,
    isReadOnly,
    className,
  } = props;
  const styles = useStyles();

  const formattedFileSize = React.useMemo(
    () =>
      additionalMaterial
        ? formatFileSize(additionalMaterial.fileSize)
        : undefined,
    [additionalMaterial],
  );

  if (!additionalMaterial) {
    return (
      <Button
        className={cx(styles.container, className)}
        noColor
        startAdornment={<LoaderIcon />}
      >
        <Text size={Size.Small} className={styles.text}>
          Uploading
        </Text>
      </Button>
    );
  }

  const onRemove: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    return onRemoveClick && onRemoveClick();
  };

  return (
    <a href={additionalMaterial.url} target="_blank" rel="noreferrer">
      <Button
        className={cx(styles.container, className)}
        noColor
        startAdornment={
          isUploading ? (
            <LoaderIcon />
          ) : additionalMaterial ? (
            <AttachmentIcon />
          ) : undefined
        }
        endAdornment={
          isDeleting ? (
            <LoaderIcon />
          ) : onRemoveClick && !isReadOnly ? (
            <XMarkIcon onClick={onRemove} />
          ) : undefined
        }
      >
        <Text size={Size.Small} className={styles.text}>
          {isUploading
            ? 'Uploading'
            : isDeleting
            ? 'Deleting'
            : additionalMaterial
            ? additionalMaterial.fileName
            : null}
        </Text>

        {formattedFileSize && !isDeleting && (
          <Text size={Size.Small} className={styles.fileSize}>
            {formattedFileSize}
          </Text>
        )}
      </Button>
    </a>
  );
};
