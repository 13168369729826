import React, { ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface DescriptionProps {
  children: ReactNode;
  title: string;
}

const useStyles = createUseStyles({
  title: {
    fontWeight: '500',
    fontSize: '15px',
    marginTop: '25px',
    marginBottom: '10px',
  },
});

export const Description = ({
  children,
  title,
}: DescriptionProps): ReactElement => {
  const styles = useStyles();
  return (
    <>
      <p>
        Companies are generally looking for a proficiency level of at least 3
        stars on required skills. You can “Continue Without Updating” (you will
        have a lower chance of success) or update the rating of these skills by
        clicking "Continue & Update Skills".
      </p>
      <h3 className={styles.title}>{title}</h3>
      {children}
    </>
  );
};
