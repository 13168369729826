import React from 'react';
import { ConnectionSummaryType } from '@a_team/models/dist/UserObject';
import { createUseStyles } from 'react-jss';
import blueCompany from './blue-company.svg';
import blueEmail from './blue-email.svg';
import greenSuitcase from './green-suitcase.svg';
import pinkSuitcase from './pink-suitcase.svg';
import greyUser from './grey-user.svg';
import orangeUser from './orange-user.svg';
import purpleMission from './purple-mission.svg';
import purpleTeam from './purple-team.svg';
import blueLocation from './blue-location.svg';

interface IconsProps {
  types: ConnectionSummaryType[];
}

const useStyles = createUseStyles({
  connectionType: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    background: '#F7F7F7',
    borderRadius: '4px',
    '& img': {
      width: '16px',
      height: '16px',
    },
  },
});

function Icons({ types }: IconsProps) {
  const styles = useStyles();

  const icons: JSX.Element[] = [];

  if (
    types.includes('invitedBy') ||
    types.includes('invitations') ||
    types.includes('missionRecommendations')
  ) {
    icons.push(
      <div key="referred" className={styles.connectionType} title="Referred">
        <img src={blueEmail} alt="referred icon" />
      </div>,
    );
  }

  if (types.includes('missions')) {
    icons.push(
      <div key="mission" className={styles.connectionType} title="Mission">
        <img src={purpleMission} alt="mission icon" />
      </div>,
    );
  }

  if (types.includes('companies')) {
    icons.push(
      <div key="company" className={styles.connectionType} title="Company">
        <img src={blueCompany} alt="company icon" />
      </div>,
    );
  }

  if (types.includes('projects')) {
    icons.push(
      <div key="project" className={styles.connectionType} title="Project">
        <img src={greenSuitcase} alt="Project icon" />
      </div>,
    );
  }

  if (types.includes('mutualConnections')) {
    icons.push(
      <div
        key="mutualConnections"
        className={styles.connectionType}
        title="Mutual Connections"
      >
        <img src={orangeUser} alt="mutualConnections icon" />
      </div>,
    );
  }

  if (types.includes('teams')) {
    icons.push(
      <div key="teams" className={styles.connectionType} title="Team">
        <img src={purpleTeam} alt="team" />
      </div>,
    );
  }

  if (types.includes('role')) {
    icons.push(
      <div key="role" className={styles.connectionType} title="Role">
        <img src={pinkSuitcase} alt="Role icon" />
      </div>,
    );
  }

  if (types.includes('location')) {
    icons.push(
      <div key="location" className={styles.connectionType} title="Location">
        <img src={blueLocation} alt="Locations icon" />
      </div>,
    );
  }

  if (icons.length === 0) {
    icons.push(
      <div
        key="not-in-network"
        className={styles.connectionType}
        title="Builder not in network"
      >
        <img src={greyUser} alt="Builder not in network" />
      </div>,
    );
  }

  return <>{icons}</>;
}

export default Icons;
