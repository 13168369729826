import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import {
  Colors,
  TextColors,
  Icon,
  IconType,
  FontSizes,
  Spacing,
  FontWeights,
} from '@ateams/components';
import {
  TargeterSearchCache,
  TargeterTabCache,
} from '@src/stores/TeamGraph/TargeterTabManager';
import { useStores } from '@src/stores';
import TooltipWrapped from '@src/components/TooltipWrapped';

export const SUGGESTED_TEAMS = 'Suggested Teams';
export const SUGGESTED_TEAMS_TIMEOUT_CHECK = 1000;

interface Props {
  activeTab: string;
  setActiveTab: (label: string) => void;
  tabs: TargeterSearchCache[];
  updateTabs: (data: TargeterTabCache) => void;
}

const useStyles = createUseStyles({
  tabBar: {
    display: 'flex',
    gap: Spacing.large,
    justifyContent: 'space-between',
    borderBottom: `1px solid ${Colors.regular}`,
  },
  tabGroup: {
    display: 'flex',
    marginLeft: Spacing.small,
    overflow: 'hidden',
  },
  tabGroupRight: {
    display: 'flex',
    flex: '0 0 auto',
    marginRight: Spacing.small,
  },
  searchTabsContainer: {
    height: 32,
    marginRight: '-1px',
    display: 'flex',
    background: Colors.regularLight,
    color: TextColors.regular,
    overflow: 'hidden',
    border: '1px solid #ccc',
    borderBottom: 'none',
    borderRadius: '5px',
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
    alignItems: 'center',
  },
  active: {
    background: Colors.backgroundWhite,
    color: TextColors.primaryLight,
    fontWeight: FontWeights.regular,
    flex: '0 0 auto',
  },
  tabText: {
    fontSize: FontSizes.small,
    overflow: 'hidden',
    paddingTop: Spacing.xsmall,
    paddingBottom: Spacing.xsmall,
    paddingLeft: Spacing.small,
    marginRight: Spacing.xsmall,
    cursor: 'pointer',
    '&:focus': {
      border: 'none',
    },
    whiteSpace: 'nowrap',
  },
  tabOptions: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.xsmall,
    alignItems: 'center',
    fontSize: FontSizes.small,
    paddingRight: Spacing.small,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  duplicateIcon: {
    cursor: 'pointer',
    fontSize: 16,
  },
  deleteIcon: {
    cursor: 'pointer',
    fontSize: 10,
  },
  addIcon: {
    cursor: 'pointer',
    fontSize: 11,
  },
  addIconContainer: {
    width: 42,
    height: 32,
    border: '1px solid #ccc',
    paddingTop: Spacing.xsmall,
    paddingBottom: Spacing.xsmall,
    textAlign: 'center',
    borderRadius: '5px',
    background: 'oldlace',
    cursor: 'pointer',
    borderBottom: 'none',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
    flex: '0 0 auto',
  },
  suggestedTeams: {
    paddingLeft: Spacing.small,
    paddingRight: Spacing.xsmall,
  },
  suggestedTeamsActive: {
    display: 'flex',
    flexDirection: 'row',
    background: Colors.backgroundWhite,
    color: Colors.secondaryDark,
    fontWeight: FontWeights.regular,
    width: 'auto !important',
  },
  suggestedTeamIcon: {
    display: 'flex',
    flex: '0 0 auto',
    cursor: 'pointer',
  },
});

const TeamGraphTabs = (props: Props): ReactElement => {
  const { targeterTabManager, users } = useStores();
  const styles = useStyles();
  const { activeTab, setActiveTab, tabs, updateTabs } = props;
  const [isEditingName, setIsEditingName] = useState(false);
  const [currentTabName, setCurrentTabName] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    isEditingName && inputRef.current?.focus();
  }, [isEditingName]);

  const setTabName = (label: string, prevLabel: string) => {
    setIsEditingName(false);
    if (!label) return;

    const labelAlreadyExists = tabs.map((tab) => tab.label).includes(label);
    if (labelAlreadyExists) return window.alert(`Tab names must be unique`);

    const allTabs = tabs.map((tab) =>
      tab.label === prevLabel ? { ...tab, label } : tab,
    );

    handleTabUpdate(allTabs, label);
  };

  const duplicateTab = (tab: TargeterSearchCache) => {
    const tabLabel = `Copy of ${tab.label}`;
    const allTabs = [
      ...tabs,
      {
        ...tab,
        label: tabLabel,
        url: tab.url,
        position: tabs.length,
      },
    ];

    handleTabUpdate(allTabs, tabLabel);
  };

  const createTab = () => {
    const label = `Tab ${tabs.length + 1}`;
    const allTabs = [
      ...tabs,
      {
        label,
        url: '',
        selectedBuilders: [],
        builders: [],
        next: undefined,
        position: tabs.length,
      },
    ];

    handleTabUpdate(allTabs, label);
  };

  const deleteTab = (labelToDelete: string) => {
    const allTabs = tabs.filter(({ label }) => label !== labelToDelete);
    handleTabUpdate(
      allTabs,
      labelToDelete === activeTab ? allTabs[0].label : undefined,
    );
  };

  const handleTabUpdate = (tabs: TargeterSearchCache[], active?: string) => {
    const cache = tabs.reduce(
      (allTabs: TargeterTabCache, tabCache, position) => ({
        ...allTabs,
        [tabCache.label]: { ...tabCache, position },
      }),
      {},
    );

    updateTabs(cache);
    active && setActiveTab(active);
  };

  const onSuggestedTeamsTabClick = () => {
    setActiveTab(SUGGESTED_TEAMS);
    users.trackSuggestedTeamsTargeterTabClicked(props.tabs.length);
  };

  return (
    <div className={styles.tabBar}>
      <div className={styles.tabGroup}>
        {tabs.map((tab: TargeterSearchCache) => {
          const isActiveTab = tab.label === activeTab;

          return (
            <div
              key={tab.label}
              className={cx(
                styles.searchTabsContainer,
                isActiveTab && styles.active,
              )}
            >
              {isActiveTab && isEditingName ? (
                <input
                  key={tab.label}
                  ref={inputRef}
                  className={styles.tabText}
                  placeholder="Label tab"
                  onFocus={() => setCurrentTabName('')}
                  onChange={(e) => setCurrentTabName(e.target.value)}
                  onBlur={() => setTabName(currentTabName, activeTab)}
                  onKeyPress={({ key }) => {
                    key === 'Enter' && setTabName(currentTabName, activeTab);
                  }}
                />
              ) : (
                <div
                  className={cx(styles.tabText, isActiveTab && styles.active)}
                  key={tab.label}
                  onClick={() => {
                    if (!isActiveTab) return setActiveTab(tab.label);
                    setIsEditingName(true);
                  }}
                >
                  <TooltipWrapped
                    theme={'dark'}
                    position={'top'}
                    html={<div>{tab.label}</div>}
                  >
                    {tab.label}
                  </TooltipWrapped>
                </div>
              )}
              <div className={styles.tabOptions}>
                {isActiveTab && (
                  <Icon
                    size="exact"
                    type={IconType.Copy}
                    className={styles.duplicateIcon}
                    onClick={() => duplicateTab(tab)}
                  />
                )}
                {tabs.length > 1 && (
                  <Icon
                    muted
                    size="xsmall"
                    type={IconType.Close}
                    className={styles.deleteIcon}
                    onClick={() => deleteTab(tab.label)}
                  />
                )}
              </div>
            </div>
          );
        })}
        <div className={styles.addIconContainer} onClick={createTab}>
          <Icon
            size="xsmall"
            className={styles.addIcon}
            type={IconType.Plus}
            muted
          />
        </div>
      </div>

      {tabs.length > 1 && (
        <div className={styles.tabGroupRight}>
          <div
            className={cx(
              styles.searchTabsContainer,
              styles.suggestedTeams,
              activeTab === SUGGESTED_TEAMS && styles.suggestedTeamsActive,
            )}
          >
            {targeterTabManager.loadingTabs?.[SUGGESTED_TEAMS] || false ? (
              <Icon
                size="exact"
                type={IconType.CircleLoadingViolet}
                rotating={true}
                style={{ marginLeft: 8, cursor: 'pointer' }}
              />
            ) : (
              <Icon
                size="exact"
                type={
                  activeTab === SUGGESTED_TEAMS
                    ? IconType.SuggestedTeams
                    : IconType.SuggestedTeamsInactive
                }
                className={styles.suggestedTeamIcon}
              />
            )}
            <div
              className={cx(
                styles.tabText,
                activeTab === SUGGESTED_TEAMS && 'active',
              )}
              onClick={onSuggestedTeamsTabClick}
            >
              {SUGGESTED_TEAMS}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamGraphTabs;
