import { Button, Icon, IconType, Spacing } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';

export interface Props {
  onClick: () => void;
  text: string;
  iconType?: IconType;
}

const useStyles = createUseStyles({
  icon: {
    marginRight: Spacing.xsmall,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const SmallButton = (props: Props) => {
  const styles = useStyles();
  return (
    <Button
      size="xsmall"
      squared
      color="regularLight"
      width="auto"
      className={styles.button}
      onClick={() => props.onClick()}
    >
      {props.iconType ? (
        <Icon type={props.iconType} size="exact" className={styles.icon} />
      ) : null}

      {props.text}
    </Button>
  );
};
