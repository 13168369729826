import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import Description from '../../partials/Description';
import Title from '../../partials/Title';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  title: {
    marginTop: 0,
  },
  description: {
    marginBottom: 0,
  },
});

function NoLongerAvailable(): ReactElement {
  const styles = useStyles();
  return (
    <div>
      <Title
        className={styles.title}
        text={`This request is no longer available.`}
      />

      <Description
        className={styles.description}
        text={`The request has either been removed, rejected or accepted.`}
      />
    </div>
  );
}

export default observer(NoLongerAvailable);
