import { Button } from '@a_team/ui-components';
import cx from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode;
  open: boolean;
  onClick: (open: boolean) => void;
  className?: string;
}

const useStyles = createUseStyles({
  editBtn: {
    height: '40px !important',
    width: '40px !important',
    padding: '0 !important',
    border: '1px solid #DADADC !important',
  },
});

const NavMenuButton = (props: Props): ReactElement => {
  const { children, onClick, className } = props;
  const styles = useStyles();

  const clickHandler = () => {
    onClick(!props.open);
  };

  return (
    <Button
      onClick={clickHandler}
      className={cx(className, styles.editBtn)}
      variant="ghost"
    >
      {children}
    </Button>
  );
};

export default NavMenuButton;
