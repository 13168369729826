import React, { ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import Colors from '../theme/colors';
import cx from 'classnames';

interface Props {
  children: ReactNode;
  required?: boolean;
}

const useStyles = createUseStyles({
  label: {
    color: '#62646A',
    fontSize: 14,
    lineHeight: '19px',
    marginBottom: 8,
    display: 'block',
  },
  required: {
    paddingLeft: 2,
    color: Colors.danger,
  },
});

export const InputLabel = (props: Props): ReactElement => {
  const styles = useStyles();
  const { children, required } = props;
  return (
    <label className={cx(styles.label, 'input-label')}>
      {children}
      {required && <span className={styles.required}>*</span>}
    </label>
  );
};
