import ExpertiseModal from '@src/components/Modal/ExpertiseModal';
import { ExpertiseLegend } from '@src/components/Modal/ExpertiseModal/ExpertiseLegend';
import { skillToExpertise } from '@src/helpers/expertise';
import {
  useApplyProfileSuggestion,
  useRejectProfileSuggestion,
  useGetProfileSuggestions,
  useGetAdminProfileSuggestions,
} from '@src/rq/profileSuggestions';
import Profile from '@src/stores/Profile/Profile';
import React, { useState } from 'react';
import Suggestions from '../Suggestions';
import { Expertise, ProfileViewMode } from '@src/stores/Profile/models';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import {
  BuilderSuggestionType,
  SuggestionActionType,
} from '@a_team/models/dist/ProfileEnhancements';
import { createUseStyles } from 'react-jss';
import { TextColors } from '@ateams/components';

interface SuggestedSkillsProps {
  profile: Profile;
  adminOnly?: boolean;
}

const useStyles = createUseStyles({
  container: {
    marginBottom: 24,
  },
  title: {
    fontSize: 14,
    color: TextColors.regularLight,
  },
});

const SuggestedSkills = ({ profile, adminOnly }: SuggestedSkillsProps) => {
  const [skillsModalOpen, setSkillsModalOpen] = useState(false);
  const { mutateAsync: applySuggestion } = useApplyProfileSuggestion({
    shouldRefreshProfile: true,
  });
  const { mutateAsync: rejectSuggestion } = useRejectProfileSuggestion();
  const { data, isFetching } = useGetProfileSuggestions();
  const { data: adminData } = useGetAdminProfileSuggestions();
  const analytics = useAnalytics();
  const styles = useStyles();

  const rawSuggestions = adminOnly ? adminData : data;

  const toggleSkillsModalOpen = () => {
    setSkillsModalOpen(!skillsModalOpen);
  };

  const suggestionId = rawSuggestions?.skills?.sid;

  const expertise = rawSuggestions?.skills?.skills.map((skill) =>
    skillToExpertise(skill),
  );
  const skills = rawSuggestions?.skills?.skills.map((skill) => ({
    suggestionId,
    partialSuggestionId: skill.talentSkillId,
    label: skill.talentSkillName,
    type: BuilderSuggestionType.SKILLS,
  }));

  const onDiscard = async () => {
    try {
      if (rawSuggestions?.skills?.sid) {
        await rejectSuggestion(rawSuggestions.skills.sid);
        analytics.trackProfileSuggestionInteraction(
          profile.data.uid,
          rawSuggestions.skills.sid,
          SuggestionActionType.REJECT,
          BuilderSuggestionType.SKILLS,
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (items: Expertise[]) => {
    try {
      if (rawSuggestions?.skills?.sid) {
        await applySuggestion({
          sid: rawSuggestions.skills.sid,
          editedSuggestion: {
            skills: items.map((item) => {
              return {
                talentSkillId: item.id,
                name: item.name,
                talentCategoryIds: item.categoryIds || [],
                rating: item.rating,
                talentSkillName: item.name,
                isFeatured: item.featured,
              };
            }),
          },
        });
        analytics.trackProfileSuggestionInteraction(
          profile.data.uid,
          rawSuggestions.skills.sid,
          SuggestionActionType.ACCEPT,
          BuilderSuggestionType.SKILLS,
          items.map(({ name }) => name),
        );
        setSkillsModalOpen(false);
        profile.refreshProfile(ProfileViewMode.View);
      }
    } catch (e) {
      console.error(e);
      toggleSkillsModalOpen();
    }
  };

  return (
    <div className={styles.container}>
      {!profile.showSkills && !!skills?.length && (
        <span className={styles.title}>Skills</span>
      )}

      <Suggestions
        userId={profile.data.uid}
        adminOnly={adminOnly}
        onDiscard={onDiscard}
        onAccept={toggleSkillsModalOpen}
        title={'Suggested Skills'}
        onClick={toggleSkillsModalOpen}
        suggestions={skills ?? []}
        noLoadingIconOnAccept
      />
      <ExpertiseModal
        hideSearch
        suggestedType="skills"
        isLoading={isFetching}
        title={'Add Skills'}
        placeholder={'Type to search...'}
        onSubmit={onSubmit}
        selectedItems={expertise}
        open={skillsModalOpen}
        onSearch={(query) => profile?.querySkills(query, false)}
        onClose={toggleSkillsModalOpen}
        description={<ExpertiseLegend type={'Skills'} />}
        defaultFeatured
      />
    </div>
  );
};

export default SuggestedSkills;
