import {
  ConnectionRequestNotificationObject,
  NotificationType,
} from '@a_team/models/dist/NotificationObject';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import React, { ReactElement } from 'react';
import Notification from './Notification';

interface ConnectionRequestNotificationProps {
  notification: ConnectionRequestNotificationObject;
  onMarkAsRead(): void;
  isRead: boolean;
  onConnect(user: BasicUserObject): Promise<void>;
  dataTestingId?: string;
}

export default function ConnectionRequestNotification({
  onMarkAsRead,
  isRead,
  notification,
  onConnect,
  dataTestingId,
}: ConnectionRequestNotificationProps): ReactElement {
  const { connection } = notification;
  const { initiator } = connection;

  const handleConnect = async () => {
    await onConnect(initiator);
    onMarkAsRead();
  };

  return (
    <>
      <Notification
        dataTestingId={dataTestingId}
        createdAt={notification.createdAt}
        notificationType={NotificationType.ConnectionRequest}
        ctaName="See request"
        ctaAction={handleConnect}
        title={initiator.fullName}
        description={'Wants to connect with you'}
        imageUrl={initiator.profilePictureURL}
        onMarkAsRead={onMarkAsRead}
        isRead={isRead}
      />
    </>
  );
}
