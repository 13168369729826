import { MissionPaymentCycleId } from '@a_team/models/dist/MissionPaymentCycleObject';
import ItemsCarousel from '@src/components/ItemsCarousel';
import Mission from '@src/stores/Missions/Mission';
import React from 'react';
import { createUseStyles } from 'react-jss';
import TeamPaymentCycle from './TeamPaymentCycle';

interface TeamPaymentCyclesProps {
  mission: Mission;
  updateSelectedCycle: (id: MissionPaymentCycleId) => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    gap: 24,
  },
  wrapper: {
    maxWidth: '85vw',
    margin: '0 auto',
  },
});

const TeamPaymentCycles = ({
  mission,
  updateSelectedCycle,
}: TeamPaymentCyclesProps): JSX.Element => {
  const styles = useStyles();

  const handleClick = (id: MissionPaymentCycleId) => {
    updateSelectedCycle(id);
  };

  return (
    <div className={styles.wrapper}>
      <ItemsCarousel
        edgeButtons
        className={styles.container}
        startPosition={'start'}
      >
        {mission.paymentCycles?.map((cycle) => (
          <TeamPaymentCycle
            key={cycle.yid}
            paymentCycle={cycle}
            selected={cycle.data.yid === mission.selectedPaymentCycle?.data.yid}
            onClick={() => handleClick(cycle.data.yid)}
          />
        ))}
      </ItemsCarousel>
    </div>
  );
};

export default TeamPaymentCycles;
