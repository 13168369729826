import { useState } from 'react';

export default function useToggle(
  value = false,
): [boolean, (force?: boolean | unknown) => void] {
  const [toggle, setToggle] = useState(value);

  return [
    toggle,
    (force?: boolean | unknown): void =>
      setToggle(typeof force === 'boolean' ? force : !toggle),
  ];
}
