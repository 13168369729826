import React from 'react';

export const InboxIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        opacity="0.6"
        d="M6 5H10"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M14 8V12C14 13.1046 13.1046 14 12 14H4C2.89543 14 2 13.1046 2 12V8M14 8L12.2279 2.68377C12.0918 2.27543 11.7097 2 11.2792 2H4.72076C4.29033 2 3.90819 2.27543 3.77208 2.68377L2 8M14 8H11L10 10H6L5 8H2"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
