import {
  NewProject,
  ExistingProject,
  ExistingJob,
  NewJob,
} from '@src/stores/Profile/models';
import {
  ExperienceTeamMember,
  ExperienceUserMember,
} from '@a_team/models/dist/ExperienceObject';
import { UserType } from '@a_team/models/dist/UserObject';
import React from 'react';

export const getProjectId = (project: ExistingProject | NewProject): string => {
  return 'eid' in project ? project.eid : project._id;
};

export const getJobId = (job: ExistingJob | NewJob): string => {
  return 'eid' in job ? job.eid : job._id;
};

export const isNewExperienceItem = (
  item: ExistingProject | NewProject | ExistingJob | NewJob,
): item is NewProject | NewJob => {
  return !('eid' in item);
};

export const getProjectMemberId = (
  member: ExperienceUserMember | ExperienceTeamMember,
): string | undefined => {
  return 'uid' in member ? member.uid : undefined;
};

export const isProjectMemberUser = (
  member: ExperienceUserMember | ExperienceTeamMember,
): member is ExperienceUserMember => {
  return (member as ExperienceUserMember).type === UserType.User;
};

export const isEmptyProject = (item: ExistingProject | NewProject): boolean => {
  return !item.title && !item.description && !item.imageURL && !item.logoURL;
};

export const isPlaceholderProject = (
  item: ExistingProject | NewProject,
): boolean => {
  return (
    isNewExperienceItem(item) && isEmptyProject(item) && !!item.placeholder
  );
};

export const isSuperWideImage = (
  imageUrl: string | undefined,
  setter: React.Dispatch<React.SetStateAction<boolean>>,
): void => {
  // Check if super wide legacy images are being used
  // to adjust css
  if (imageUrl) {
    const img = new Image();
    img.onload = () => {
      setter(img.width / img.height > 3);
    };
    img.src = imageUrl;
  }
};
