import React, { ReactElement, useMemo } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import Select, { SelectOption, SelectProps, IndicatorSeparator } from './index';
import { customStyles, selectThemeColors, selectThemeStyles } from './styles';
import { Theme } from 'react-select';
import LocationObject from '@a_team/models/dist/LocationObject';
import { CSSProperties } from 'react';

const DEBOUNCE_TIME = 200; // milliseconds

/**
 * {@link https://developers.google.com/maps/documentation/places/web-service/autocomplete#types}
 */
export enum LocationTypes {
  GeoCode = 'geocode',
  Address = 'address',
  Establishment = 'establishment',
  Regions = '(regions)',
  Cities = '(cities)',
}

export enum LocationRegionTypes {
  Locality = 'locality',
  SubLocality = 'sublocality',
  PostalCode = 'postal_code',
  Country = 'country',
  AdministrativeAreaLevel1 = 'administrative_area_level_1',
  AdministrativeAreaLevel2 = 'administrative_area_level_2',
}

export interface LocationSelectOption {
  option: SelectOption;
  prediction?: GooglePrediction;
  countryShortName?: string;
}

export type OnLocationChange = (
  option: null | LocationSelectOption | LocationSelectOption[],
) => void;

export type FilterLocationOptions = (
  options: GooglePrediction[],
) => GooglePrediction[];

export interface LocationSelectProps extends Omit<SelectProps, 'onChange'> {
  apiKey: string;
  // Options for Google's api - See available params:
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  autocompleteOptions?: { [k: string]: any };
  onChange?: OnLocationChange;
  isOutlined?: boolean;
  filterOptions?: FilterLocationOptions;
}

export interface GooglePrediction {
  description: string;
  matched_substring: { length: number; offset: number }[];
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    main_text_matched_substrings: { length: number; offset: number }[];
    secondary_text: string;
    secondary_text_matched_substrings: { length: number; offset: number };
  };
  terms: { offset: number; value: string }[];
  types: LocationRegionTypes[];
}

interface GooglePlaceAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface GooglePlacesResult {
  address_components: GooglePlaceAddressComponent[];
}

/**
 * Matching on countries' long names leads to inconsistencies; short_name is used.
 */
const findShortName = (
  result: GooglePlacesResult,
  done: (shortName: string) => void,
) => {
  for (const component of result['address_components']) {
    if (component.types.includes('country')) {
      return done(component['short_name']);
    }
  }
};

/**
 * Serializes the structured_formatting field from google to the
 * LocationObject that we use in ATeam
 * @example { main_text: 'Tel Aviv', secondary_text: 'Israel' } -> { country: 'Israel', city: 'Tel Aviv' }
 * @example { main_text: 'New York', secondary_text: 'NY, USA' } -> { country: 'USA', province: 'NY', city: 'New York' }
 */
export function parseLocation(
  structuredFormatting: GooglePrediction['structured_formatting'],
  countryShortName?: string,
): LocationObject {
  const { main_text: city, secondary_text } = structuredFormatting;
  if (!secondary_text) return { country: city, countryShortName };

  const elements = secondary_text.split(',');
  const country = elements[elements.length - 1].trim(); // The country is the last element in the comma separated value

  let province: string | undefined;
  if (elements.length > 1) {
    province = elements // Remove the last element from the comma separated value
      .slice(0, elements.length - 1)
      .join(',')
      .trim();
  }

  return {
    country,
    province,
    city,
    countryShortName,
  };
}

/**
 * @example { city: 'Tel Aviv', country: 'Israel' } -> 'Tel Aviv, Israel'
 * @example { city: 'New York', province: 'NY', country: 'USA' } -> 'New York, NY, USA'
 */
export function formatLocation({
  city,
  province,
  country,
}: LocationObject): string {
  return [city, province, country].filter((el) => el).join(', ');
}

const DropdownIndicator = (): ReactElement => {
  return <span />;
};

const dashedStyles = {
  ...customStyles,
  control: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    border: 'none',
    boxShadow: 'none', // Removes the border when selected
    borderBottom: '1px dashed #C0C0C0',
  }),
  valueContainer: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    minHeight: 42,
    padding: 0,
  }),
};

export const LocationAutocomplete = (
  props: LocationSelectProps,
): ReactElement => {
  const {
    apiKey,
    error,
    autocompleteOptions,
    onChange,
    filterOptions,
    isOutlined = true,
    ...rest
  } = props;
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: props.apiKey,
    debounce: DEBOUNCE_TIME,
    language: 'en',
  });

  // See Google's api for prediction object structure:
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletePrediction
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toOption = (prediction: GooglePrediction): SelectOption => {
    return { value: prediction.place_id, label: prediction.description };
  };

  const loadOptions = (value: string) => {
    getPlacePredictions({ input: value, ...autocompleteOptions });
    return value;
  };

  const getCountryShortName = (placeId: string): Promise<string> =>
    new Promise((resolve) => {
      placesService.getDetails(
        { placeId, fields: ['address_components'] },
        (result: GooglePlacesResult) => findShortName(result, resolve),
      );
    });

  const getOptionResults = async (
    item: SelectOption,
  ): Promise<LocationSelectOption> => {
    // handle default values for countries
    if (item.shortName) {
      return { option: item, countryShortName: item.shortName };
    }

    const placeId = item.value;
    const prediction: GooglePrediction | undefined = placePredictions.find(
      (place) => place.place_id === placeId,
    );

    const countryShortName: string = await getCountryShortName(placeId);
    return { option: item, prediction, countryShortName };
  };

  const onOptionSelect = async (item: SelectOption[] | SelectOption | null) => {
    if (!onChange) return;

    if (!item) {
      onChange(null);
    } else if (Array.isArray(item)) {
      onChange(await Promise.all(item.map(getOptionResults)));
    } else {
      onChange(await getOptionResults(item));
    }
  };

  const options = useMemo(() => {
    const filteredOptions = filterOptions
      ? filterOptions(placePredictions)
      : placePredictions;

    return filteredOptions.map((place) => toOption(place));
  }, [placePredictions, filterOptions]);

  return (
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    <Select
      cacheOptions={false}
      options={options}
      isLoading={isPlacePredictionsLoading}
      onInputChange={loadOptions}
      components={{ DropdownIndicator, IndicatorSeparator }}
      theme={(theme: Theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          ...selectThemeColors(error),
        },
        ...selectThemeStyles,
      })}
      onChange={(item) => onOptionSelect(item as SelectOption)}
      placeholder={'Search location...'}
      {...(isOutlined ? {} : { styles: dashedStyles })}
      {...rest}
    />
  );
};
