import Button from '@a_team/ui-components/lib/Button';
import React from 'react';
import { createUseStyles } from 'react-jss';
import successIcon from './successIcon.svg';
import { Colors } from '@ateams/components';

interface SubmissionCompletedProps {
  onClose: () => void;
}

const useStyles = createUseStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '26px',
    margin: 0,
    textAlign: 'center',
    marginTop: 40,
  },
  description: {
    fontSize: 15,
    marginTop: 8,
    color: Colors.regularDark,
    textAlign: 'center',
    maxWidth: 400,
  },
  button: {
    marginTop: '40px !important',
    padding: '12px 12px !important',
    position: 'relative',
    fontSize: 14,
    minWidth: '180px !important',
  },
}));

const SubmissionCompleted = ({ onClose }: SubmissionCompletedProps) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <img src={successIcon} alt="Success" width={80} height={80} />
      <h1 className={styles.title}>Importing your project</h1>
      <div className={styles.description}>
        Once complete, the project will appear as a suggestion on your profile,
        and is only visible to you until approved.
      </div>
      <div>
        <Button variant="secondary" className={styles.button} onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default SubmissionCompleted;
