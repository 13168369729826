import { ClientInterviewDeclineReason } from '@a_team/models/dist/ClientInterviewObject';

export const declineReasonDescriptions: Record<
  ClientInterviewDeclineReason,
  string
> = {
  NotEnoughAvailability: 'I don’t have enough availability',
  RoleNotRelevant: 'The role is not relevant to me',
  NotInterested: 'I’m not interested in the mission',
  RateTooLow: 'The rate is too low',
  Other: 'Other',
};
