import { MissionMissingRequirement } from '@a_team/models/dist/MissionApplicationObject';
import MissionRole from '@a_team/models/dist/MissionRole';
import {
  continentsOptionsMap,
  rawCountryList,
} from '@src/helpers/rawCountryList';

export const getContinentAwareLocations = (role?: MissionRole): string[] => {
  if (!role) {
    return [];
  }
  // Find all continents where all countries are present
  const continents = Object.keys(continentsOptionsMap).filter(
    (continentCode) => {
      return rawCountryList
        .filter(({ continent }) => continent === continentCode)
        .every(({ code: countryCode }) =>
          role.locations?.includes(countryCode),
        );
    },
  );
  const unassignedCountries = rawCountryList
    .filter(
      ({ continent, code }) =>
        !continents.includes(continent) && role.locations?.includes(code),
    )
    .map(({ code }) => code);
  return [
    ...continents.map((continent) => `_${continent}`),
    ...unassignedCountries,
  ];
};

export const MissingRequirementLabel = {
  [MissionMissingRequirement.BufferedRateMismatch]: 'Rate',
  [MissionMissingRequirement.BufferedAvailabilityMismatch]: 'Availability',
  [MissionMissingRequirement.StrictAvailabilityMismatch]:
    'Availability (within threshold)',
  [MissionMissingRequirement.BufferedSkillsMismatch]: 'Skills',
  [MissionMissingRequirement.StrictSkillsMismatch]: 'Skills (within threshold)',
  [MissionMissingRequirement.StrictLocationMismatch]: 'Location',
  [MissionMissingRequirement.BufferedWorkingHoursMismatch]: 'Working Hours',
  [MissionMissingRequirement.StrictWorkingHoursMismatch]:
    'Working Hours (within threshold)',
  [MissionMissingRequirement.StrictRateMismatch]: 'Rate (within threshold)',
};
