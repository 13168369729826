import React, { useCallback } from 'react';
import ConfirmModalV2 from '@src/components/Modal/ConfirmModalV2';
import NavigationPrompt, {
  RenderCustomConfirmModal,
} from '@src/components/NavigationPrompt';
import { useHistory } from 'react-router-dom';
import { SelectableUser } from '../AdminTable';
import { observer } from 'mobx-react';

interface ConfirmModalOnLeaveProps {
  thereAreChangesToSave: boolean;
  userToSwitch: SelectableUser | undefined;
  selectedUser: SelectableUser | undefined;
  setSelectedUser: (user: SelectableUser | undefined) => void;
  setUserToSwitch: (user: SelectableUser | undefined) => void;
}

const confirmModalProps = {
  title: 'Do you want to discard your changes?',
  description: (
    <div>
      <div>Are you sure you want to exit?</div>
      <div>All your changes will be lost.</div>
    </div>
  ),
  onCancelText: 'Yes, discard',
  onConfirmText: 'No, stay',
};

function ConfirmModalOnLeave({
  thereAreChangesToSave,
  userToSwitch,
  selectedUser,
  setSelectedUser,
  setUserToSwitch,
}: ConfirmModalOnLeaveProps) {
  const history = useHistory();

  const renderNavigationPromptCustomModal: RenderCustomConfirmModal =
    useCallback((open, closeModal, handleConfirmNavigation) => {
      const onDiscard = () => {
        closeModal();
        handleConfirmNavigation();
      };

      return (
        <ConfirmModalV2
          title={confirmModalProps.title}
          description={confirmModalProps.description}
          onCancelText={confirmModalProps.onCancelText}
          onConfirmText={confirmModalProps.onConfirmText}
          open={open}
          onClose={onDiscard}
          onCancel={onDiscard}
          onConfirm={() => closeModal()}
          onConfirmColor="secondaryDark"
        />
      );
    }, []);

  return (
    <div>
      <ConfirmModalV2
        title={confirmModalProps.title}
        description={confirmModalProps.description}
        onCancelText={confirmModalProps.onCancelText}
        onConfirmText={confirmModalProps.onConfirmText}
        open={
          !!thereAreChangesToSave &&
          !!userToSwitch &&
          userToSwitch?.sid !== selectedUser?.sid
        }
        onClose={() => setSelectedUser(userToSwitch)}
        onCancel={() => setSelectedUser(userToSwitch)}
        onConfirm={() => setUserToSwitch(undefined)}
        onConfirmColor="secondaryDark"
      />

      <NavigationPrompt
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => thereAreChangesToSave}
        renderCustomConfirmModal={renderNavigationPromptCustomModal}
      />
    </div>
  );
}

export default observer(ConfirmModalOnLeave);
