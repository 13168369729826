import { OptionProps, Theme } from 'react-select';
import { CSSObject } from '@emotion/styled/macro';
import { Colors, TextColors } from '@ateams/components';

export const selectThemeColors = (error?: boolean): Theme['colors'] => {
  return {
    primary: '#62646A',
    primary75: Colors.backgroundDark,
    primary50: Colors.backgroundDark,
    primary25: Colors.backgroundMedium,
    danger: Colors.danger,
    dangerLight: Colors.dangerLight,
    neutral20: error ? Colors.danger : Colors.regular,
  };
};

export const selectThemeStyles = (): Pick<Theme, 'borderRadius'> => {
  return {
    borderRadius: 4,
  };
};

export const customStyles = (noBorder = false) => ({
  menuPortal: (provided: CSSObject) => ({ ...provided, zIndex: 1000 }),
  container: (provided: CSSObject): CSSObject => ({
    ...provided,
    boxShadow: 'none',
  }),
  control: (provided: CSSObject): CSSObject => ({
    ...provided,
    borderRadius: 4,
    boxShadow: 'none',
    border: noBorder ? 0 : provided.border,
  }),
  menu: (provided: CSSObject): CSSObject => ({
    ...provided,
    zIndex: 11, // Prevents clashing with react-rte package
  }),
  menuList: (provided: CSSObject): CSSObject => ({
    ...provided,
    transform: 'scale(1)', // Prevents blurry text when used in a modal
  }),
  option: (
    provided: CSSObject,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: OptionProps<any, any>,
  ): CSSObject => ({
    ...provided,
    color: TextColors.regular,
    ...(state.isSelected && {
      background: Colors.primaryVeryLight,
      color: TextColors.primaryLight,
    }),
    '&:active': {
      background: '#fff',
    },
    ...(state.isDisabled && {
      background: Colors.backgroundDark,
      color: TextColors.regularLight,
      cursor: 'not-allowed',
    }),
  }),
  singleValue: (provided: CSSObject): CSSObject => ({
    ...provided,
    marginLeft: 4,
    marginRight: 4,
  }),
  multiValue: (provided: CSSObject): CSSObject => ({
    ...provided,
    borderRadius: 100,
    padding: '0px 6px',
    margin: 3,
    marginRight: 5,
  }),
  multiValueRemove: (provided: CSSObject): CSSObject => ({
    ...provided,
    '&:hover': {
      backgroundColor: 'unset',
      color: 'unset',
    },
  }),
  valueContainer: (provided: CSSObject): CSSObject => ({
    ...provided,
    padding: noBorder ? 0 : 5.5,
  }),
  indicatorsContainer: (
    provided: CSSObject,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: { [k: string]: any },
  ): CSSObject => ({
    ...provided,
    '& svg': {
      color: '#222',
      ...(state.selectProps.menuIsOpen && {
        transform: 'scaleY(-1)',
      }),
    },
  }),
});
