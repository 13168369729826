import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { AssignmentsBox } from '../ContractModal/AssignmentsBox';
import { LegalPdfViewer } from '../LegalPdfViewer/LegalPdfViewer';
import { ClientToSUrl } from '@a_team/models/dist/ContractObject';
import TextButton from '@src/components/TextButton';
import {
  ClientTermsOfServiceLocation,
  PrivacyPolicyLocation,
} from '@src/locations';

interface Props {
  open: boolean;
  clientName: string;
  onClose(): void;
  onAccept(): void;
}

const useStyles = createUseStyles({
  clientToSModal: {},

  label: {
    fontWeight: 500,
    width: 110,
    minWidth: 110,
    display: 'inline-block',
  },
  section: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    marginBottom: 4,
  },
});

export const ClientToSModal = (props: Props): ReactElement => {
  const { onAccept, open, onClose, clientName } = props;
  const styles = useStyles();

  const consent = (
    <div>
      By clicking “I Agree”, you agree to our{' '}
      <TextButton
        highlight
        onClick={() => window.open(ClientTermsOfServiceLocation, '_blank')}
      >
        Terms of Service Agreement
      </TextButton>{' '}
      and{' '}
      <TextButton
        highlight
        onClick={() => window.open(PrivacyPolicyLocation, '_blank')}
      >
        Website Privacy Policy
      </TextButton>
    </div>
  );

  return (
    <div className={styles.clientToSModal}>
      <LegalPdfViewer
        loading={false}
        allowButtonEnabled={true}
        open={open}
        contractUrl={ClientToSUrl}
        onAccept={onAccept}
        canCancel={false}
        onClose={onClose}
        consent={consent}
      >
        <AssignmentsBox style={{ flex: 1, minHeight: 'auto' }}>
          <p className={styles.section}>
            <span className={styles.row}>
              <span className={styles.label}>Client Name:</span> {clientName}
            </span>
            <span className={styles.row}>
              <span className={styles.label}>Mission:</span> A.Team for{' '}
              {clientName}
            </span>
          </p>
        </AssignmentsBox>
      </LegalPdfViewer>
    </div>
  );
};
