import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  Icon,
  IconType,
  Spacing,
  TextColors,
} from '@ateams/components';
import { useCommonStyles } from './commonStyles';
import LinksSection from './components/LinksSection';
import FileUploadSection from './components/FileUploadSection';
import TooltipWrapped from '@src/components/TooltipWrapped';

const useStyles = createUseStyles({
  additionalMaterialsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  infoIcon: {
    cursor: 'pointer',
  },
  descriptionContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.xsmall,
  },
  tooltipContainer: {
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.xLarge,
    justifyContent: 'start',
    padding: Spacing.medium,
  },
  tooltipItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
    textAlign: 'left',
    fontSize: FontSizes.medium,
  },
  tooltipItemTitle: {
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.semiBold,
    color: TextColors.primary,
  },
  tooltipItemDescription: {
    fontSize: FontSizes.medium,
    color: TextColors.primary,
  },
});

export interface AdditionalMaterialsSectionProps {
  showAdditionalMaterialsError: boolean;
  links: string[];
  setLinks: (links: string[]) => void;
  validLinks: boolean[];
  setValidLinks: (validLinks: boolean[]) => void;
  uploadedFiles: File[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  additionalMaterialDetails: string;
  setAdditionalMaterialDetails: (details: string) => void;
}

const AdditionalMaterialsSection: React.FC<AdditionalMaterialsSectionProps> = ({
  showAdditionalMaterialsError,
  links,
  setLinks,
  validLinks,
  setValidLinks,
  uploadedFiles,
  setUploadedFiles,
  additionalMaterialDetails,
  setAdditionalMaterialDetails,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  return (
    <div className={styles.additionalMaterialsContainer}>
      <div className={commonStyles.title}>Additional materials</div>
      <div className={styles.descriptionContainer}>
        <div className={commonStyles.description}>
          What materials should I add?
        </div>
        <TooltipWrapped
          theme={'dark'}
          position={'top'}
          arrow
          animation="fade"
          style={{ display: 'flex' }}
          html={
            <div className={styles.tooltipContainer}>
              <div className={styles.tooltipItem}>
                Provide a work sample that showcases your best work and be
                prepared to discuss it in-depth (your thought process, metrics,
                and impact).
              </div>
              <div className={styles.tooltipItem}>
                <div className={styles.tooltipItemTitle}>For developers</div>
                <div className={styles.tooltipItemDescription}>
                  Submit a code sample or a link to a live project where you
                  made significant contributions. Be ready to discuss its
                  architecture and tech stack.
                </div>
              </div>
              <div className={styles.tooltipItem}>
                <div className={styles.tooltipItemTitle}>For other roles</div>
                <div className={styles.tooltipItemDescription}>
                  Submit a portfolio, case study, or live product showcasing
                  your relevant skills. If you don’t have a traditional
                  portfolio, provide a relevant alternative (e.g., design
                  prototype, project plan, etc.).
                </div>
              </div>
            </div>
          }
        >
          <Icon
            type={IconType.Info}
            size="smaller"
            className={styles.infoIcon}
          />
        </TooltipWrapped>
      </div>
      {showAdditionalMaterialsError && (
        <div className={commonStyles.errorText}>
          Please add at least one link or file.
        </div>
      )}
      <LinksSection
        links={links}
        setLinks={setLinks}
        validLinks={validLinks}
        setValidLinks={setValidLinks}
      />
      <FileUploadSection
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: Spacing.small,
        }}
      >
        <div
          style={{
            fontSize: FontSizes.medium,
            color: Colors.dark,
          }}
        >
          Material details
        </div>
        <textarea
          style={{
            width: 600,
            height: 140,
            border: `1px solid ${BorderColors.lighter}`,
            borderRadius: BorderRadius.medium,
            padding: Spacing.medium,
            fontSize: FontSizes.medium,
            color: Colors.dark,
          }}
          placeholder="Add details about the provided materials"
          value={additionalMaterialDetails}
          onChange={(e) => setAdditionalMaterialDetails(e.target.value)}
        />
      </div>
    </div>
  );
};

export default AdditionalMaterialsSection;
