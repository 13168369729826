import React, { ReactElement, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import OutlinedInput from '../Inputs/OutlinedInput';
import { BorderRadius, Colors, Spacing } from '@ateams/components';

const useStyles = createUseStyles({
  skillMatchingControl: {
    width: 130,
    borderRadius: BorderRadius.default,
  },
  skillMatchingInput: {
    width: '22px',
    color: Colors.regularDark,
  },
  skillMatchingWrapper: {
    margin: `${Spacing.small}px 0`,
  },
});

interface Props {
  onChange: (value: number) => void;
  value: number;
  numRequiredSkills: number;
  disabled?: boolean;
}

export const MinRequiredSkillsFilter = (props: Props): ReactElement => {
  const styles = useStyles();
  const { onChange, value, numRequiredSkills, disabled } = props;
  const [tempValue, setTempValue] = React.useState(value);
  useEffect(() => {
    setTempValue(value);
  }, [value]);
  return (
    <OutlinedInput
      disabled={disabled || false}
      type="text"
      className={styles.skillMatchingWrapper}
      controlClassName={styles.skillMatchingControl}
      inputClassName={styles.skillMatchingInput}
      onChange={(e) => {
        const num = Number(e.target.value);
        if (isNaN(num)) {
          return;
        }
        setTempValue(num);
      }}
      onBlur={() => {
        if (tempValue < 1) {
          setTempValue(1);
        }
        if (tempValue > numRequiredSkills) {
          onChange(numRequiredSkills);
          setTempValue(numRequiredSkills);
        } else {
          onChange(tempValue);
        }
      }}
      endAdornment={
        <span style={{ marginLeft: 4, width: 70 }}>
          of {numRequiredSkills} skills
        </span>
      }
      value={tempValue}
      textAlign="right"
    />
  );
};
