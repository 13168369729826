import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import Modal, { ModalBasicProps } from '@src/components/Modal';
import { useStores } from '@src/stores';
import ApplicationRequirementsNotification from '@src/views/Application/ApplicationRequirementsNotification';
import { RequirementSection } from '@src/views/Application/RequirementsModal/RequirementSection';
import { createUseStyles } from 'react-jss';
import { Footer } from '@src/views/Application/RequirementsModal/Footer';
import { Breakpoints } from '@ateams/components';
import { getHumanReadableTime } from '@src/views/Profile/Sidebar/WorkingHours/utils';

interface Props extends ModalBasicProps {
  onSubmit: () => void;
}

const useStyles = createUseStyles({
  title: {
    textAlign: 'center',
    fontSize: 20,
    marginTop: 0,
  },
  modal: {
    width: 'auto',
    maxWidth: 'none',
    padding: '0px !important',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '45% !important',
      maxWidth: '680px !important',
    },
  },
});

const RequirementsModal = (props: Props): ReactElement | null => {
  const styles = useStyles();
  const { open, onClose, onSubmit } = props;
  const {
    users: { profile },
  } = useStores();

  const missingRequirements = profile?.application?.missingRequirements;
  const missingPreferredRequirements =
    profile?.application?.missingPreferredRequirements;

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={styles.modal}
      data-testing-id={'mission-application-requirements-modal'}
    >
      <div style={{ padding: '40px 24px' }}>
        <h2 className={styles.title}>
          Your application is missing requirements
        </h2>
        <ApplicationRequirementsNotification
          text={
            missingRequirements
              ? 'Your application is missing information. To request to join the mission, please address the missing requirements.'
              : 'Your application is missing certain nice-to-haves set by the company. To improve your chances of acceptance, address the missing items.'
          }
        />
        {missingRequirements && (
          <RequirementSection
            title={'Missing requirements'}
            text={
              'These requirements are marked as “must have” by the client. To be considered, your application must include these requirements with a proficiency score above 3, where applicable.'
            }
            missingRole={
              profile?.application?.missingRole
                ? [profile?.application?.missingRole]
                : undefined
            }
            missingSkills={
              profile?.application?.missingRequiredSkills
                .map((skill) => skill.talentSkillName)
                .filter((skill) => !!skill) as string[]
            }
            lowProficiencySkills={
              profile?.application?.lowProficiencyRequiredSkills
                .map((skill) => skill.talentSkillName)
                .filter((skill) => !!skill) as string[]
            }
            missingCustomQuestions={profile?.application?.missingRequiredCustomQuestions.map(
              (question) => question.text,
            )}
          />
        )}
        {missingPreferredRequirements && (
          <RequirementSection
            title={'Missing nice to haves'}
            text={
              'You can still apply without these items in your profile, but there’s a low chance of acceptance.'
            }
            missingLocation={
              profile && profile?.application?.userLocationInRoleReqs
                ? undefined
                : [profile?.country || '']
            }
            missingPreferredSkills={
              profile?.application?.missingPreferredSkills
                .map((skill) => skill.talentSkillName)
                .filter((skill) => !!skill) as string[]
            }
            missingOverlap={
              profile?.application?.workingHoursOverlapTooLow &&
              profile.application.currentRole?.workingHours
                ?.numberOfMinutesOverlap
                ? [
                    `Company requires ${getHumanReadableTime(
                      profile.application.currentRole?.workingHours
                        ?.numberOfMinutesOverlap,
                    )}, ${
                      profile.application.hoursOverlapWithRoleInMinutes === 0
                        ? 'you have no overlap'
                        : `you only have ${profile?.application?.hoursOverlapInWords}`
                    }`,
                  ]
                : undefined
            }
            missingAvailability={
              profile?.application?.availabilityNotMatchingRole
                ? [
                    `Company requires ${profile.application.currentRole?.availability?.weeklyHoursAvailable}h per week, you only have ${profile?.application?.availability.hoursPerWeek}h`,
                  ]
                : undefined
            }
            missingCustomQuestions={profile?.application?.missingOptionalCustomQuestions.map(
              (question) => question.text,
            )}
          />
        )}
      </div>
      <Footer
        onClose={onClose}
        onSubmit={onSubmit}
        canContinue={missingPreferredRequirements && !missingRequirements}
      />
    </Modal>
  );
};

export default observer(RequirementsModal);
