import { SurveyQuestionBaseProps } from '../SurveyQuestionBaseProps';
import { SurveyQuestionResponseValidator } from '../SurveyQuestionResponseValidator';
import { SurveyQuestionType } from '../SurveyQuestionType';

export interface ListSurveyQuestionProps extends SurveyQuestionBaseProps {
  title: string;
}

export interface ListSurveyQuestionResponse {
  value?: string[];
}

export const validateListSurveyQuestionResponse: SurveyQuestionResponseValidator<
  SurveyQuestionType.List
> = (props, response): void => {
  if (props.required && !response) {
    throw new Error('required');
  }

  const { value } = response;

  // make sure value is an array of strings
  if (!Array.isArray(value) || value.some((v) => typeof v !== 'string')) {
    throw new Error('invalid value in ListSurvey response');
  }
};
