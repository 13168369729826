import { ServiceAuth, ServiceEndpoint } from './utils';
import { UserReviewObject } from '@a_team/models/dist/UserReviewObject';

export const BasePath = '/user-reviews';

export default class UserReviewsEndpoint extends ServiceEndpoint {
  public getUserReviews(
    auth: ServiceAuth,
    params: { uid: string; includeAllReviews?: boolean },
  ): Promise<UserReviewObject[]> {
    return this.fetch(auth, `${BasePath}/${params.uid}`, {
      includeAllReviews: params.includeAllReviews,
    });
  }

  public changeUserReviewVisibility(
    auth: ServiceAuth,
    reviewId: string,
    visible: boolean,
  ): Promise<{ success: boolean }> {
    return this.fetch(auth, `${BasePath}/${reviewId}/visibility`, null, 'put', {
      visible,
    });
  }
}
