import React, { ButtonHTMLAttributes } from 'react';
import { createUseStyles } from 'react-jss';
import { FontWeights } from '../theme/styles';
import Colors, { ColorName } from '../theme/colors';

const useStyles = createUseStyles({
  root: {
    background: 'transparent',
    border: 'none',
    color: (props: TextButtonProps): string => Colors[props.color],
    cursor: 'pointer',
    fontWeight: FontWeights.medium,
  },
});
export interface TextButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: ColorName;
}

const TextButton: React.FC<TextButtonProps> = (props) => {
  const styles = useStyles(props);
  const { color, children, ...rest } = props;
  return (
    <button className={styles.root} {...rest}>
      {children}
    </button>
  );
};

export default TextButton;
