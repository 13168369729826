import React, { useEffect, useState } from 'react';
import NotificationSliderMobile from './NotificationSliderMobile';
import NotificationSliderDesktop from './NotificationSliderDesktop';
import { useScreenClass } from 'react-grid-system';

interface NotificationCenterProps {
  isOpen: boolean | undefined;
  toggle: () => void;
}

const NotificationCenter = ({
  isOpen,
  toggle,
}: NotificationCenterProps): JSX.Element => {
  const screenClass = useScreenClass();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(screenClass === 'xs' || screenClass === 'sm');
  }, [screenClass]);

  return (
    <div>
      {isMobile ? (
        <NotificationSliderMobile isOpen={isOpen} toggle={toggle} />
      ) : (
        <NotificationSliderDesktop isOpen={isOpen} toggle={toggle} />
      )}
    </div>
  );
};

export default NotificationCenter;
