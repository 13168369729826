import React, { ReactElement, ReactNode, CSSProperties } from 'react';
import { useStores } from '@src/stores';

interface Props {
  className?: string;
  title: string;
  children: ReactNode;
  style?: CSSProperties;
}

export default function BasicLayout(props: Props): ReactElement {
  const { title, className, children, style } = props;
  const { document } = useStores();

  document.setInnerTitle(title);

  return (
    <div style={{ ...style }} className={className}>
      {children}
    </div>
  );
}
