import React, { ReactElement } from 'react';
import { ProfileTooltip } from '@src/views/Profile/ProfileTooltip';
import { OutlinedInputProps } from '@src/components/Inputs/OutlinedInput';
import TextInput from '@src/components/Inputs/TextInput/TextInput';
import { createUseStyles } from 'react-jss';
import { useScreenClass } from 'react-grid-system';

interface Props extends OutlinedInputProps {
  tooltip?: string;
}

const useStyles = createUseStyles({
  input: {
    width: '46px',
    backgroundColor: 'transparent',
    margin: '0 5px',
    lineHeight: '1.4em',
    textAlign: 'center',
  },
});

export const HoursInput = (props: Props): ReactElement => {
  const styles = useStyles();
  const screenClass = useScreenClass();

  return (
    <ProfileTooltip
      title={
        props.tooltip ||
        "Please enter the number of hours you'll be available to work on this Mission"
      }
      disabled={screenClass === 'xs'}
    >
      <TextInput
        variant="dashed"
        placeholder={'XX'}
        data-testing-id={'profile-availability-hours-text-input'}
        className={styles.input}
        {...props}
      />
    </ProfileTooltip>
  );
};
