import React from 'react';
import UserCardList from '@src/components/UserCardList';
import { UserCardObject } from '@a_team/models/dist/UserObject';
import { PaginationProps } from '@src/components/Pagination';

export interface SelectionTeamGridProps {
  rows: UserCardObject[];
  paginationProps?: PaginationProps;
}

export const SelectionTeamGrid: React.FC<SelectionTeamGridProps> = (props) => {
  const { rows } = props;

  return <UserCardList users={rows} minimal />;
};
