import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { MetricData } from '@a_team/models/dist/ExperienceObject';
import { Breakpoints } from '@ateams/components';
import gradientSvg from './gradient.svg';

interface MetricsProps {
  metrics: MetricData[];
}

const useStyles = createUseStyles({
  metrics: {
    marginTop: 64,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: 16,
  },
  metric: {
    padding: 24,
    backgroundColor: '#131313',
    color: '#FFFFFF',
    minHeight: 160,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundImage: `url(${gradientSvg})`,
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    overflow: 'hidden',
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '30px',
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: 40,
    lineHeight: '19px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    metrics: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
});

const Metrics = ({ metrics }: MetricsProps): JSX.Element | null => {
  const styles = useStyles();

  const filteredMetrics = useMemo(() => {
    return metrics?.filter((metric) => metric.title && metric.description);
  }, [metrics]);

  if (!filteredMetrics || filteredMetrics.length === 0) {
    return null;
  }

  return (
    <div className={styles.metrics}>
      {filteredMetrics.map((metric, index) => (
        <div key={`metric-${index}`} className={styles.metric}>
          <div className={styles.title}>{metric.title}</div>
          <div className={styles.description}>{metric.description}</div>
        </div>
      ))}
    </div>
  );
};

export default Metrics;
