import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import Modal, { ModalBasicProps } from '@src/components/Modal';
import { Button as CallToActionButton } from '@ateams/components';
import { ContractView } from '@src/components/Modal/ContractModal/ContractView';
import { useScreenClass } from 'react-grid-system';
import LabeledCheckboxInput from '@src/components/Inputs/LabeledCheckboxInput';
import useToggle from '@src/hooks/useToggle';
import { createUseStyles } from 'react-jss';

interface Props extends ModalBasicProps {
  loading: boolean;
  onAccept(): void;
  contractUrl?: string;
  children: ReactNode;
  canCancel?: boolean;
  style?: CSSProperties;
  allowButtonEnabled?: boolean;
  consent?: ReactElement;
  isFullTimeRetainer?: boolean;
}

const useStyles = createUseStyles({
  checkbox: {
    marginBottom: 24,
  },
  consent: {
    paddingTop: 20,
    fontSize: 14,
  },
});

export const LegalPdfViewer = (props: Props): ReactElement => {
  const {
    loading,
    onClose,
    open,
    onAccept,
    contractUrl,
    canCancel = true,
    consent,
    isFullTimeRetainer,
  } = props;
  const styles = useStyles();
  const screenClass = useScreenClass();
  const [checkboxChecked, toggleCheckboxChecked] = useToggle(false);

  return (
    <Modal
      onClose={onClose}
      open={open}
      hideCloseButton
      style={{
        width: screenClass === 'xs' ? '95%' : '85%',
        height: '80%',
        maxHeight: 600,
        maxWidth: 1300,
        display: screenClass === 'xs' ? 'block' : 'flex',
        padding: 0,
        ...props.style,
      }}
    >
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span>Loading...</span>
        </div>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: 24,
              maxWidth: 380,
            }}
          >
            {props.children}
            <div>
              <LabeledCheckboxInput
                label={
                  isFullTimeRetainer
                    ? 'I confirm the information above'
                    : 'Click to accept'
                }
                checked={checkboxChecked}
                onChange={toggleCheckboxChecked}
                className={styles.checkbox}
              />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CallToActionButton
                  disabled={!props.allowButtonEnabled || !checkboxChecked}
                  onClick={onAccept}
                  style={{ marginRight: 12 }}
                >
                  I Accept
                </CallToActionButton>
                {canCancel && (
                  <CallToActionButton
                    color={'regular'}
                    onClick={onClose}
                    width={'auto'}
                  >
                    Cancel
                  </CallToActionButton>
                )}
              </div>
              {consent && <div className={styles.consent}>{consent}</div>}
            </div>
          </div>
          {contractUrl && <ContractView src={contractUrl} />}
        </>
      )}
    </Modal>
  );
};
