import { JobExperienceSkill } from '@a_team/models/dist/ExperienceObject';
import { Select } from '@a_team/ui-components';
import { isNonNullable } from '@src/helpers/sort';
import { Expertise } from '@src/stores/Profile/models';
import React from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { FormValues } from '.';

interface SkillsProps {
  register: UseFormRegister<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  trigger: UseFormTrigger<FormValues>;
  watch: UseFormWatch<FormValues>;
  skills: Expertise[];
  selectedSkills?: JobExperienceSkill[];
}

const useStyles = createUseStyles({
  label: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  desc: {
    fontSize: '15px',
    fontWeight: 300,
    lineHeight: '24px',
    color: '#62646A',
    textAlign: 'start',
    margin: 0,
  },
  labelDescContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    marginBottom: 12,
  },
});

const Skills = ({
  skills,
  watch,
  register,
  setValue,
  trigger,
}: SkillsProps): JSX.Element => {
  const styles = useStyles();

  const options = skills.map((skill) => ({
    value: skill.id,
    label: skill.name,
  }));

  const skillsSelected = watch('selectedSkills');

  const values = skillsSelected
    ?.map((skill) => {
      const skillName = skills.find((s) => s.id === skill.talentSkillId)?.name;
      if (!skillName) {
        return null;
      }
      return {
        value: skill.talentSkillId,
        label: skillName,
      };
    })
    .filter(isNonNullable);

  return (
    <div>
      <div className={styles.labelDescContainer}>
        <label className={styles.label}>Skills used</label>
        <span className={styles.desc}>
          Add up to 12 skills from your profile.
        </span>
      </div>
      <Select
        alwaysShowCounter={true}
        isMulti={true}
        styles={{
          control: (base) => ({
            ...base,
            border: '1px solid #DADADC',
          }),
          placeholder: (base) => ({
            ...base,
            '& span': {
              display: values.length === 0 ? 'none' : 'inline',
              backgroundColor: '#6D00D7 !important',
              borderRadius: '8px !important',
            },
          }),
        }}
        {...register('selectedSkills')}
        value={values}
        placeholder={`Select skills`}
        onChange={(value) => {
          const newValues = value as { value: string; label: string }[];

          const newSelectedSkills: JobExperienceSkill[] = newValues.map(
            (skill) => ({
              talentSkillId: skill.value,
              talentSkillName: skill.label,
            }),
          );

          setValue('selectedSkills', newSelectedSkills, {
            shouldDirty: true,
          });
          trigger('selectedSkills');
        }}
        options={options}
      />
    </div>
  );
};

export default Skills;
