import React from 'react';
import { createUseStyles } from 'react-jss';
import Colors from '../theme/colors';
import cx from 'classnames';
import { BorderRadius, Spacing } from '../theme/styles';

export interface SecondaryInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
  prefix?: string;
  suffix?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    padding: `0 ${Spacing.small}px`,
    border: `1px solid ${Colors.regular}`,
    borderRadius: BorderRadius.default,
    width: 'auto',
    alignItems: 'center',
  },
  input: {
    border: 0,
    padding: Spacing.xsmall,
    backgroundColor: 'transparent',
    flex: '1 1 auto',
  },
});

export const SecondaryInput = (props: SecondaryInputProps) => {
  const styles = useStyles();
  const { prefix, inputClassName, suffix, ...rest } = props;
  return (
    <div className={styles.container}>
      {props.prefix ? (
        <span className={props.prefix}>{props.prefix}</span>
      ) : null}
      <input className={cx(styles.input, inputClassName)} {...rest} />
      {props.suffix ? (
        <span className={props.suffix}>{props.suffix}</span>
      ) : null}
    </div>
  );
};
