import React, { useState } from 'react';
import { Select } from '@ateams/components';
import {
  ReplacementVariableByCategory,
  ReplacementVariableByCategoryIdEnum,
} from '@a_team/user-notification-service-js-sdk';
import { createUseStyles } from 'react-jss';
import { copyToClipboard } from '@ateams/react-utils/dist/helpers/clipboard';

interface Props {
  replacementVariables: ReplacementVariableByCategory[];
  visibleCategories?: ReplacementVariableByCategoryIdEnum[];
}

const useStyles = createUseStyles({
  root: {
    margin: 0,
    width: '190px',
  },
});

const TIMEOUT_TO_RESET_PLACEHOLDER = 2000;
const PLACEHOLDER_TEXT = 'Variables';
const PLACEHOLDER_TEXT_COPIED = 'Copied!';

export const ReplacementVariableSelect: React.FC<Props> = ({
  replacementVariables,
  visibleCategories,
}) => {
  const styles = useStyles();
  const [placeholderText, setPlaceholderText] = useState(PLACEHOLDER_TEXT);
  const copyVariableToClipboard = (variable: string) => {
    copyToClipboard(`{{${variable}}}`);
    setPlaceholderText(PLACEHOLDER_TEXT_COPIED);
    setTimeout(() => {
      setPlaceholderText(PLACEHOLDER_TEXT);
    }, TIMEOUT_TO_RESET_PLACEHOLDER);
  };
  return (
    <Select
      disableLocalValue
      overrideStyles={{
        control: (provided) => ({
          ...provided,
          minHeight: '32px',
          height: '32px',
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '0 6px',
        }),
      }}
      width="160px"
      className={styles.root}
      onChange={(selected) =>
        selected && copyVariableToClipboard(selected.value)
      }
      value={null}
      placeholder={placeholderText}
      options={replacementVariables
        .filter(
          (category) =>
            !visibleCategories || visibleCategories?.includes(category.id),
        )
        .map((category) => ({
          label: category.label,
          options: category.variables.map((variable) => ({
            label: variable,
            value: variable,
          })),
        }))}
    ></Select>
  );
};
