import React, { ReactElement } from 'react';
import { AvailabilityTypeLabels } from '@src/stores/NotificationPreferencesUser';
import { enumKeys } from '@src/helpers/types';
import DropdownInput, {
  DropdownInputProps,
} from '@src/components/Inputs/DropdownInput';

export const StatusSelect = (props: DropdownInputProps): ReactElement => {
  return (
    <DropdownInput
      margin={'none'}
      placeholder={'Select...'}
      background={'transparent'}
      {...props}
    >
      {enumKeys(AvailabilityTypeLabels).map((key) => (
        <option key={key}>{AvailabilityTypeLabels[key]}</option>
      ))}
    </DropdownInput>
  );
};
