import React, { ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import RegistrationStep from '@src/views/Registration/RegistrationStep';
import { useStores } from '@src/stores';
import { validate as validateEmail } from 'email-validator';
import useLoadingState from '@src/hooks/useLoadingState';
import RadioGroup from '@src/components/RadioGroup';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { UserReferralSourceType } from '@ateams/api/dist/endpoints/User';
import useDebounceState from '@src/hooks/useDebounceState';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';

const useStyles = createUseStyles({
  radio: {
    marginTop: 24,
    '& > div:last-child': {
      marginBottom: '0 !important',
    },
  },
  [`@media (min-width: ${Breakpoints.xl}px)`]: {
    radio: {
      '& > div': {
        marginBottom: '0.8em !important',
      },
    },
  },
});

const Source = (): ReactElement => {
  const styles = useStyles();
  const {
    registration: { setSource, setSourceEmail, source, currentStep },
  } = useStores();

  const [loading, setLoading] = useLoadingState();
  const [isValidEmail, setIsValidEmail] = useState<boolean | undefined>();
  const [email, setLocalEmail] = useDebounceState(
    source?.sourceEmail,
    (email) => {
      if (!email) {
        setSourceEmail(undefined);
        setIsValidEmail(undefined);
        return;
      }

      const isValid = validateEmail(email);
      setIsValidEmail(isValid);
      isValid && setSourceEmail(email);
    },
  );

  const onSubmit = () => {
    currentStep.onSubmit && setLoading(currentStep.onSubmit());
  };

  const options = [
    {
      value: UserReferralSourceType.ATeamMember,
      label: (
        <>
          An A.Teamer referred me
          {source?.source === UserReferralSourceType.ATeamMember && (
            <OutlinedInput
              margin={'none'}
              style={{ marginTop: 8 }}
              value={source?.sourceName}
              onChange={(e) =>
                setSource({
                  ...source,
                  sourceName: e.target.value,
                })
              }
              label={'A.Teamer’s Name'}
              placeholder={'Jack Jackson...'}
            />
          )}
        </>
      ),
    },
    {
      value: UserReferralSourceType.Client,
      label: (
        <>
          An A.Team client referred me
          {source?.source === UserReferralSourceType.Client && (
            <>
              <OutlinedInput
                margin={'none'}
                style={{ marginTop: 8 }}
                value={source?.sourceName}
                onChange={(e) =>
                  setSource({
                    ...source,
                    sourceName: e.target.value,
                  })
                }
                label={'Client’s Name'}
                placeholder={'Jack Jackson...'}
              />
              <OutlinedInput
                margin={'none'}
                style={{ marginTop: 8 }}
                value={email}
                onChange={(e) => setLocalEmail(e.target.value)}
                label={'Client’s Email Address'}
                placeholder={'name@company.com...'}
                valid={isValidEmail}
                error={!!email && isValidEmail === false}
              />
            </>
          )}
        </>
      ),
    },
    {
      value: UserReferralSourceType.ArticleOrBlogPost,
      label: 'An article or blog post',
    },
    {
      value: UserReferralSourceType.SearchEngine,
      label: 'A search engine (Google, Duckduckgo, etc.)',
    },
    {
      value: UserReferralSourceType.SocialMedia,
      label: 'Social media (Facebook, Instagram, Twitter, etc.)',
    },
    {
      value: UserReferralSourceType.JobPost,
      label: 'A job post (LinkedIn or other job board)',
    },
    {
      value: UserReferralSourceType.Other,
      label: (
        <>
          Other
          {source?.source === UserReferralSourceType.Other && (
            <OutlinedInput
              margin={'none'}
              value={source?.otherText || ''}
              style={{ marginTop: 8 }}
              multiline
              minRows={3}
              onChange={(e) =>
                setSource({
                  ...source,
                  otherText: e.target.value,
                })
              }
              required
              placeholder={'Tell us in a few words how you heard about us…'}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <RegistrationStep
      title={'Last One, How Did You Hear About Us?'}
      description={
        'The growth of our community has been almost entirely word-of-mouth. We give the highest priority to builders who have been referred to us by our community or clients.'
      }
      buttonLabel={'Submit'}
      onSubmit={onSubmit}
      canSubmit={
        loading !== true && currentStep.canSubmit && isValidEmail !== false
      }
      loading={loading}
    >
      <RadioGroup
        onChange={(item) => {
          setSource({
            ...source,
            source: item.value,
            ...(item.value !== source?.source && {
              sourceName: undefined,
              sourceEmail: undefined,
              otherText: undefined,
            }),
          });
          setLocalEmail(undefined);
        }}
        selectedValue={source?.source}
        items={options}
        labelStyle={{ flex: 1 }}
        className={styles.radio}
      />
    </RegistrationStep>
  );
};

export default observer(Source);
