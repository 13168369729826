import { UserSuggestedTeamUp } from '@a_team/models/dist/UserObject';
import { Checkbox, Icon } from '@a_team/ui-components';
import { getInsightMessages } from '@src/components/DiscoveryUserCard/DiscoveryUserCardV2';
import UserAvatar from '@src/components/UserAvatar';
import { MissionControlAppliedLocation, ProfileLocation } from '@src/locations';
import { noop } from 'lodash/fp';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import moonIcon from './moon.svg';
import sunriseIcon from './sunrise.svg';

interface TeammateRecommendationCardProps {
  suggestedUser: UserSuggestedTeamUp;
  selected: boolean;
  onSelect: (user: UserSuggestedTeamUp) => void;
  onUnselect: (user: UserSuggestedTeamUp) => void;
  isSearch?: boolean;
}

export const useTeammateRecommendationCardStyles = createUseStyles({
  container: {
    position: 'relative',
    background: '#F7F7F7',
    borderRadius: 16,
    border: ({ selected }: { selected: boolean }) =>
      selected ? '1px solid #6D00D7' : '1px solid #F7F7F7',
    padding: 24,
  },
  mainRole: {
    color: '#222222',
    fontSize: '14px',
    marginBottom: '24px',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginBottom: '8px',
  },
  fullName: {
    color: '#222222',
    fontSize: '15px',
  },
  userAvatar: {
    display: 'flex',
    justifyContent: 'start',
  },
  iconMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  connectionInsightContainer: {
    color: '#62646A',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    alignItems: 'center',
  },
  userInvitedYou: {
    marginBottom: 15,
    fontSize: 14,
    color: '#62646A',
  },
  missionControlLink: {
    color: '#6D00D7',
  },
  userInvitedYouContainer: {
    display: 'flex',
    gap: 8,
  },
  checkbox: {
    position: 'absolute',
    top: 16,
    left: 16,
    pointerEvents: 'none', // Make checkbox non-clickable
  },
  iconContainer: {
    position: 'absolute',
    top: 16,
    right: 16,
    '&:hover $unavailableText': {
      opacity: 1,
      visibility: 'visible',
      zIndex: 1,
    },
  },
  unavailableText: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    borderRadius: '4px',
    padding: '4px 8px',
    top: '-35px',
    left: '50%',
    transform: 'translateX(-50%)',
    whiteSpace: 'nowrap',
    pointerEvents: 'none',
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.3s, visibility 0.3s',
  },
});

const TeammateRecommendationCard = ({
  suggestedUser,
  selected,
  onSelect,
  onUnselect,
  isSearch,
}: TeammateRecommendationCardProps): JSX.Element => {
  const styles = useTeammateRecommendationCardStyles({
    selected,
  });

  const mainRole =
    suggestedUser?.talentProfile?.talentSpecializations
      ?.mainTalentSpecialization?.name;

  const insightMessage = getInsightMessages(suggestedUser.narrativeConnection);

  const handleOnClick = () => {
    if (selected !== undefined) {
      if (selected) {
        onUnselect(suggestedUser);
      } else {
        onSelect(suggestedUser);
      }
    }
  };
  const isSearchAndInvitedByThem =
    isSearch && suggestedUser.invitationStatus === 'invitedByThem';

  const isSearchAndConsidered =
    isSearch && suggestedUser.invitationStatus === 'considered';

  const isSearchAndInvitedByYou =
    isSearch && suggestedUser.invitationStatus === 'invitedByYou';

  const isSearchAndInvited =
    isSearchAndInvitedByThem ||
    isSearchAndConsidered ||
    isSearchAndInvitedByYou;

  const isSearchAndUnavailable =
    isSearch && suggestedUser.nonAvailabilityReason === 'unavailable';
  const isSearchAndNotSelected =
    isSearch && suggestedUser.nonAvailabilityReason === 'notSelected';

  const isSearchAndNotAvailable =
    isSearchAndUnavailable || isSearchAndNotSelected;

  const handleCardClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isSearchAndInvited || isSearchAndNotSelected) return;

    e.stopPropagation();
    handleOnClick();
  };

  const handleAvatarClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };

  return (
    <div
      className={styles.container}
      style={{
        cursor:
          isSearchAndInvited || isSearchAndNotAvailable ? 'default' : 'pointer',
      }}
      onClick={handleCardClick}
    >
      {!isSearchAndInvited && !isSearchAndNotSelected && (
        <Checkbox
          className={styles.checkbox}
          checked={selected}
          onChange={noop}
        />
      )}

      {(isSearchAndInvited || isSearchAndNotAvailable) && (
        <div className={styles.iconContainer}>
          {isSearchAndUnavailable && (
            <span className={styles.unavailableText}>Unavailable</span>
          )}

          {isSearchAndNotSelected && (
            <span className={styles.unavailableText}>
              Unavailable - not selected
            </span>
          )}

          <img
            src={isSearchAndNotAvailable ? moonIcon : sunriseIcon}
            alt="indicator"
          />
        </div>
      )}

      <div
        className={styles.userInfo}
        style={{
          opacity: isSearchAndNotSelected ? 0.7 : 1,
        }}
      >
        <Link
          target="_blank"
          to={ProfileLocation(suggestedUser.username)}
          className={styles.header}
          onClick={handleAvatarClick}
        >
          <UserAvatar
            size={108}
            shouldResizeUploadCareImages={false}
            containerClassName={styles.userAvatar}
            src={suggestedUser.profilePictureURL}
          />
        </Link>

        <div
          style={{
            opacity: isSearchAndNotSelected ? 0.7 : 1,
          }}
          className={styles.fullName}
        >
          {suggestedUser.fullName}
        </div>
        {mainRole && (
          <div
            style={{
              opacity: isSearchAndNotSelected ? 0.7 : 1,
            }}
            className={styles.mainRole}
          >
            {mainRole}
          </div>
        )}

        <div
          style={{
            opacity: isSearchAndNotSelected ? 0.7 : 1,
          }}
        >
          {isSearchAndInvited && !isSearchAndNotAvailable && (
            <div className={styles.userInvitedYouContainer}>
              <Icon
                style={{ marginTop: 3 }}
                name="teamMembers"
                color="Hannibal@500"
                size="md"
              />
              {isSearchAndInvitedByThem && (
                <div className={styles.userInvitedYou}>
                  {suggestedUser.firstName} invited you to team up <br />
                  You can accept from{' '}
                  <Link
                    className={styles.missionControlLink}
                    to={MissionControlAppliedLocation}
                  >
                    mission control
                  </Link>
                </div>
              )}

              {isSearchAndConsidered && (
                <div className={styles.userInvitedYou}>
                  {suggestedUser.firstName} and you are already being considered
                  as a team for this mission.
                </div>
              )}

              {isSearchAndInvitedByYou && (
                <div className={styles.userInvitedYou}>
                  You’ve already invited {suggestedUser.firstName} to team up
                </div>
              )}
            </div>
          )}
          {!isSearch && (
            <div className={styles.connectionInsightContainer}>
              {insightMessage.map((message, index) => {
                return (
                  <div key={index} className={styles.iconMessageContainer}>
                    {message.icon}
                    {message.message}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeammateRecommendationCard;
