import { CurrentUserMissionApplicationObject } from '@a_team/models/dist/MissionApplicationObject';
import { MissionCardObject } from '@a_team/models/dist/MissionObject';
import {
  RecommendationObject,
  RecommendationStatus,
} from '@a_team/models/dist/RecommendationObject';
import { Icon } from '@a_team/ui-components';
import { BorderColors, Breakpoints, Colors } from '@ateams/components';
import TeamUpPendingInvitesModal, {
  hasAppliedToSameRole,
} from '@src/components/Modal/TeamUpPendingInvitesModal';
import React, { useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import TeamUpMember from './TeamUpMember';
import useClickOutside from '@src/hooks/useClickOutside';
import UserTooltip from '@src/components/UserTooltip/UserTooltip';
import { UserCardObject } from '@a_team/models/dist/UserObject';
import { previewUploadCareImageUrl } from '@src/logic/uploadcare';
import { BasicMissionRole } from '@a_team/models/dist/MissionRole';

interface TeamUpSectionProps {
  currentUserId: string;
  application: CurrentUserMissionApplicationObject;
  mission: Pick<MissionCardObject, 'mid'>;
  userRecommendations?: RecommendationObject[];
  hideInvite?: boolean;
}

const useStyles = createUseStyles({
  container: {
    gap: 8,
    border: `1px solid ${BorderColors.lighter}`,
    padding: 12,
    gridColumn: 'span 2',
    display: 'flex',
    borderRadius: 6,
    justifyContent: 'space-between',
    flexDirection: 'column',
    background: 'white',
    cursor: 'default',
  },
  pendingBtn: {
    border: 'none',
    outline: 'none',
    background: 'transparent',
    color: Colors.secondaryDark,
    cursor: 'pointer',
  },
  invite: {
    color: Colors.regularDark,
  },
  circle: {
    width: 3,
    height: 3,
    background: '#DADADC',
    borderRadius: '50%',
    margin: '0 8px',
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    position: 'relative',
  },
  tooltip: {
    position: 'absolute',
    top: '100%',
    left: '30%',
    transform: 'translateX(-50%)',
    zIndex: 10,
  },
  recommendationsContainer: {
    display: 'flex',
    '& > *:not(:first-child)': {
      marginLeft: -4,
    },
  },
  pictureContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    '& > span': {
      display: 'flex',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      flexDirection: 'row',
    },
  },
});

const TeamUpSection = ({
  mission,
  application,
  userRecommendations,
  currentUserId,
  hideInvite,
}: TeamUpSectionProps): JSX.Element => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const [selectedUser, setSelectedUser] = useState<{
    user: UserCardObject | null;
    status: RecommendationStatus | undefined;
    appliedRoles: BasicMissionRole[] | undefined;
  }>();

  const toggleTooltip = (
    user: UserCardObject,
    status: RecommendationStatus | undefined,
    open: boolean,
    appliedRoles: BasicMissionRole[] | undefined,
  ) => {
    setShowTooltip(open);
    setSelectedUser({ user, status, appliedRoles });
  };

  useClickOutside(ref, () => setShowTooltip(false));

  const statusMessage = useMemo(() => {
    const hasActiveUserRecommendation = userRecommendations?.some(
      (recommendation) => {
        return recommendation.status === RecommendationStatus.Active;
      },
    );

    if (!hasActiveUserRecommendation) {
      return 'You applied as an individual';
    }

    return 'You requested to team up with';
  }, [userRecommendations]);

  const totalPending = useMemo(() => {
    if (!userRecommendations) {
      return 0;
    }

    const pendingRecommendations = userRecommendations.filter(
      (recommendation) => {
        const isRequestedByCurrentUser =
          recommendation.requested.uid === currentUserId;
        const isPendingStatus =
          recommendation.status === RecommendationStatus.Pending;
        const hasNotAppliedToSameRole = !hasAppliedToSameRole(recommendation);

        return (
          isRequestedByCurrentUser && isPendingStatus && hasNotAppliedToSameRole
        );
      },
    );

    return pendingRecommendations.length;
  }, [userRecommendations, currentUserId]);

  const onOpenPendingModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(true);
  };

  const recommendationsToDisplay = useMemo(() => {
    if (!userRecommendations) {
      return [];
    }

    const filteredRecommendations = userRecommendations.filter(
      (recommendation) =>
        recommendation.status === RecommendationStatus.Active ||
        recommendation.status === RecommendationStatus.Unavailable ||
        (recommendation.status === RecommendationStatus.Pending &&
          recommendation.requestor.uid === currentUserId),
    );

    return filteredRecommendations.sort((a, b) => {
      if (
        a.status === RecommendationStatus.Active &&
        b.status !== RecommendationStatus.Active
      ) {
        return -1;
      }
      if (
        a.status !== RecommendationStatus.Active &&
        b.status === RecommendationStatus.Active
      ) {
        return 1;
      }
      if (
        a.status === RecommendationStatus.Unavailable &&
        b.status === RecommendationStatus.Pending
      ) {
        return -1;
      }
      if (
        a.status === RecommendationStatus.Pending &&
        b.status === RecommendationStatus.Unavailable
      ) {
        return 1;
      }
      if (a.status === undefined) {
        return b.status === undefined ? 0 : 1;
      }
      if (b.status === undefined) {
        return -1;
      }

      return 0;
    });
  }, [userRecommendations, currentUserId]);

  const tooltipAppliedRolesCopy = useMemo(() => {
    const roles = selectedUser?.appliedRoles;

    if (!roles || roles.length === 0) {
      return 'Hasn’t applied yet';
    }

    return roles.length === 1
      ? `${roles[0].category.title}`
      : `${roles[0].category.title} +${roles.length - 1}`;
  }, [selectedUser]);

  return (
    <>
      <div className={styles.container} ref={ref}>
        <div className={styles.leftSide}>
          <Icon className={styles.icon} name="teamMembers" size="sm" />
          <div>{statusMessage}</div>
          <div
            onMouseLeave={() => {
              setShowTooltip(false);
              setSelectedUser(undefined);
            }}
          >
            <div className={styles.recommendationsContainer}>
              {recommendationsToDisplay?.map((recommendation, index) => {
                if (recommendation.requestor.uid !== currentUserId) {
                  return (
                    <TeamUpMember
                      key={index}
                      toggleTooltip={(open: boolean) => {
                        toggleTooltip(
                          recommendation.requestor,
                          recommendation.status,
                          open,
                          recommendation.requestorAppliedRoles,
                        );
                      }}
                      user={recommendation.requestor}
                      className={styles.pictureContainer}
                      status={recommendation.status}
                    />
                  );
                }

                if (recommendation.requested.uid !== currentUserId) {
                  return (
                    <TeamUpMember
                      key={index}
                      toggleTooltip={(open: boolean) => {
                        toggleTooltip(
                          recommendation.requested,
                          recommendation.status,
                          open,
                          recommendation.requestedAppliedRoles,
                        );
                      }}
                      user={recommendation.requested}
                      className={styles.pictureContainer}
                      status={recommendation.status}
                    />
                  );
                }

                return undefined;
              })}
            </div>
            {showTooltip && selectedUser && selectedUser.user && (
              <div className={styles.tooltip}>
                <UserTooltip
                  fullName={selectedUser.user.fullName}
                  profilePictureURL={previewUploadCareImageUrl(
                    selectedUser.user.profilePictureURL,
                    180,
                  )}
                  username={selectedUser.user.username}
                  status={selectedUser.status}
                  title={tooltipAppliedRolesCopy}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.rightSide}>
          {totalPending > 0 && (
            <>
              <button
                className={styles.pendingBtn}
                onClick={onOpenPendingModal}
              >
                {totalPending} pending
              </button>
              <div className={styles.circle} />
            </>
          )}

          {!hideInvite && (
            <Link
              className={styles.invite}
              to={`/mission/${mission?.mid}/apply/${application?.rid}/edit/${application?.aid}?suggest=true&applied=true`}
            >
              Invite teammates
            </Link>
          )}
        </div>
      </div>

      {mission?.mid && open && (
        <TeamUpPendingInvitesModal
          reloadAppliedMissions
          onClose={() => setOpen(false)}
          missionId={mission.mid}
          isOpen={open}
        />
      )}
    </>
  );
};

export default TeamUpSection;
