import React from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { AdminVettingProcess } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { VettingDashboardLocation } from '@src/locations';
import {
  BorderColors,
  Colors,
  FontSizes,
  FontWeights,
  Icon,
  IconType,
  Spacing,
} from '@ateams/components';
import {
  Size,
  Text,
} from '@src/views/VettingFeedbackForm/components/typography';
import { Button } from '@src/views/VettingFeedbackForm/components/button';
import { DraftsIcon } from './drafts-icon';
import { FormIcon } from './form-icon';
import useToggle from '@src/hooks/useToggle';
import { Separator } from '@src/views/VettingFeedbackForm/components/separator';
import { EyeIcon } from './eye-icon';
import { PostEvaluationSurveyModal } from '@src/components/Modal/PostEvaluationSurveyModal';

export interface VettingProcessSummaryActionsProps {
  vettingProcess: AdminVettingProcess;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  titlesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.xsmall,
    alignItems: 'center',
    height: '20px',
    marginBottom: Spacing.small,
  },
  titleIcon: {
    marginRight: Spacing.small,
  },
  subTitle: {
    color: '#818388',
    marginBottom: Spacing.medium,
  },
  actions: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: Spacing.small,
  },
  button: {
    padding: '8px 10px',
    background: Colors.primary,
  },
  buttonNotContained: {
    background: 'transparent',
    color: '#62646A',
    border: '1px solid #62646A',
  },
  editFormIcon: {
    color: Colors.backgroundWhite,
  },
  separator: {
    width: '1px',
    height: 16,
    background: BorderColors.light,
    marginLeft: Spacing.small,
    marginRight: Spacing.small,
  },
  copyPreEvaluationFormLink: {
    color: Colors.regularDark,
    fontSize: FontSizes.small,
    fontWeight: FontWeights.regular,
  },
  copyPreEvaluationFormIcon: {
    cursor: 'pointer',
  },
});

export const VettingProcessSummaryActions: React.FC<
  VettingProcessSummaryActionsProps
> = (props) => {
  const { vettingProcess } = props;
  const styles = useStyles();
  const [
    postEvaluationSurveyAnswersModalOpen,
    togglePostEvaluationSurveyAnswersModalOpen,
  ] = useToggle();

  const copyVettingScheduleUrlToClipboard = async () => {
    if (vettingProcess.vettingScheduleUrl) {
      await navigator.clipboard.writeText(vettingProcess.vettingScheduleUrl);
    }
  };

  return (
    <div className={cx(styles.container, props.className)}>
      <div className={styles.titlesContainer}>
        <div className={styles.titleContainer}>
          <DraftsIcon className={styles.titleIcon} />
          <Text>Evaluation forms</Text>
          <div className={styles.separator}></div>
          <Icon
            type={IconType.Copy}
            className={styles.copyPreEvaluationFormIcon}
            onClick={copyVettingScheduleUrlToClipboard}
          />
          <div className={styles.copyPreEvaluationFormLink}>
            Copy pre-evaluation form link
          </div>
        </div>

        <Text size={Size.Small} className={styles.subTitle}>
          View evaluation details and share a feedback form
        </Text>
      </div>

      <div className={styles.actions}>
        <Link
          to={{
            pathname: `${VettingDashboardLocation}/feedback-form/${vettingProcess.id}`,
          }}
          target={'_blank'}
        >
          <Button
            className={styles.button}
            rounded
            startAdornment={<FormIcon className={styles.editFormIcon} />}
          >
            Edit Feedback Form
          </Button>
        </Link>

        <Link
          to={{
            pathname: `${VettingDashboardLocation}/feedback-form/view/${vettingProcess.id}`,
          }}
          target={'_blank'}
        >
          <Button
            className={cx(styles.button, styles.buttonNotContained)}
            rounded
            noColor
            startAdornment={<EyeIcon />}
          >
            View Feedback Form
          </Button>
        </Link>

        {vettingProcess.postEvaluationSurvey ? (
          <>
            <PostEvaluationSurveyModal
              open={postEvaluationSurveyAnswersModalOpen}
              onClose={togglePostEvaluationSurveyAnswersModalOpen}
              postEvaluationSurvey={vettingProcess.postEvaluationSurvey}
              builderFullName={vettingProcess.user.fullName}
              builderProfileImageUrl={vettingProcess.user.profilePictureURL}
            />

            <Separator direction={'vertical'} />

            <Button
              className={cx(styles.button, styles.buttonNotContained)}
              rounded
              noColor
              startAdornment={<EyeIcon />}
              onClick={togglePostEvaluationSurveyAnswersModalOpen}
            >
              View Post-Evaluation Survey
            </Button>
          </>
        ) : null}
      </div>
    </div>
  );
};
