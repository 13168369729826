import React, { ReactElement, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Modal, { ModalBasicProps } from '..';
import { PLATFORM_SERVICE_BASE_URL } from '@src/config';
import SectionHeading from '@src/components/SectionHeading';
import { createUseStyles } from 'react-jss';
import { Button } from '@a_team/ui-components';
import { Breakpoints } from '@ateams/components';

interface Props extends ModalBasicProps {
  title: string;
  description: string;
  location: string;
}

const useStyles = createUseStyles({
  modal: {
    width: '100% !important',
    maxWidth: '680px',
    outline: 'none',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    marginBottom: 0,
  },
  description: {
    textAlign: 'center',
    marginTop: 8,
    marginBottom: 0,
    color: '#818388',
    maxWidth: '470px',
  },
  btnUrlContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '40px',
    gap: 16,
    maxWidth: '516px',
    width: '100%',
  },
  btn: {
    whiteSpace: 'nowrap',
  },
  urlContainer: {
    width: '100%',
    height: '48px',
    background: 'white',
    border: '1px solid #C0C0C0',
    borderRadius: '4px',
    display: 'block',
    alignItems: 'center',
    padding: '10px 16px',
    fontSize: '15px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      padding: '40px !important',
    },
  },
});

export default function ShareModal(props: Props): ReactElement {
  const { title, description, location, onClose, ...left } = props;

  const styles = useStyles();

  const url = `${PLATFORM_SERVICE_BASE_URL}${location}`;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 4000);
      return (): void => clearTimeout(timeout);
    }
    return;
  }, [copied]);

  return (
    <Modal onClose={onClose} {...left} className={styles.modal}>
      <div style={{ color: 'black' }} className={styles.container}>
        <SectionHeading className={styles.title} isFirst>
          {title}
        </SectionHeading>
        <p className={styles.description}>{description}</p>
        <div className={styles.btnUrlContainer}>
          <div className={styles.urlContainer}>{url}</div>
          <CopyToClipboard text={url} onCopy={(): void => setCopied(true)}>
            <Button className={styles.btn}>
              {!copied ? 'Copy Link' : 'Copied!'}
            </Button>
          </CopyToClipboard>
        </div>
      </div>
    </Modal>
  );
}
