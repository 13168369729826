import { DateISOString } from '@a_team/models/dist/misc';
import { format, formatDistanceToNow, isValid, parseISO } from 'date-fns';

export const defaultFormat = 'MMMM dd, yyyy';

const formatDate = (
  date: DateISOString | number,
  formatString = defaultFormat,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  },
): string =>
  format(
    typeof date === 'number'
      ? date
      : typeof date === 'string'
      ? new Date(date)
      : date,
    formatString,
    options,
  );

export default formatDate;

export function formatTimeAgo(dateISOString: DateISOString) {
  let date;
  if (typeof dateISOString === 'string') {
    date = parseISO(dateISOString);
  } else if (isValid(dateISOString)) {
    date = dateISOString;
  } else {
    throw new Error('Invalid date provided');
  }

  return formatDistanceToNow(date, { addSuffix: true });
}
