import React, { useRef, useEffect, useCallback } from 'react';
import { useCommonStyles } from '../commonStyles';
import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from '@ateams/components';
import clsx from 'clsx';
import { debounce } from 'lodash';

const useStyles = createUseStyles({
  compactField: {
    gap: Spacing.xxsmall,
  },
  textarea: {
    width: '100%',
    minHeight: '20px',
    backgroundColor: Colors.transparent,
    borderTop: '0 !important',
    borderBottom: '0 !important',
    borderRight: '0 !important',
    borderLeft: `2px solid ${BorderColors.light} !important`,
    borderRadius: '0 !important',
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.small,
    padding: `0 ${Spacing.small}px`,
    display: 'flex',
    alignItems: 'center',
    resize: 'none',
    overflow: 'hidden',
    lineHeight: '20px',
  },
  noLeftBorder: {
    borderLeft: '0 !important',
  },
  noPadding: {
    padding: 0,
  },
  minLength: {
    fontSize: FontSizes.xsmall,
    fontWeight: FontWeights.regular,
    color: Colors.regularDark,
    paddingLeft: Spacing.small,
  },
  leftPadding: {
    paddingLeft: Spacing.small,
  },
});

export interface FieldTextareaProps {
  name?: string;
  label?: string;
  value: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  minLength?: number;
  showLeftBorder?: boolean;
  error?: string;
  compactField?: boolean;
  clearErrors?: (name?: string) => void;
  disabled?: boolean;
}

export const FieldTextarea = ({
  name,
  label,
  value,
  onChange,
  placeholder,
  minLength = 0,
  showLeftBorder = true,
  error,
  compactField = false,
  clearErrors,
  disabled,
}: FieldTextareaProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Debounce the height adjustment
  const debouncedAdjustHeight = useCallback(
    debounce(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = '20px';
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHeight + 'px';
      }
    }, 16), // One frame at 60fps
    [],
  );

  useEffect(() => {
    debouncedAdjustHeight();
    // Cleanup
    return () => {
      debouncedAdjustHeight.cancel();
    };
  }, [value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(e.target.value);
      if (clearErrors && name) {
        clearErrors(name);
      }
    },
    [onChange, clearErrors, name],
  );

  return (
    <div
      className={clsx(
        commonStyles.field,
        showLeftBorder && styles.noLeftBorder,
        compactField && styles.compactField,
      )}
    >
      {label && <div className={commonStyles.label}>{label}</div>}
      <textarea
        ref={textareaRef}
        name={name}
        value={value}
        placeholder={!disabled ? placeholder : ''}
        onChange={handleChange}
        className={clsx(
          styles.textarea,
          !showLeftBorder && [styles.noLeftBorder, styles.noPadding],
        )}
        disabled={disabled}
      />
      {error ? (
        <div
          className={clsx(
            commonStyles.error,
            showLeftBorder && styles.leftPadding,
          )}
        >
          {error}
        </div>
      ) : (
        <>
          {value && value.length > 0 && value.length < minLength && (
            <div className={styles.minLength}>
              {`Enter at least ${minLength} characters.`}
            </div>
          )}
        </>
      )}
    </div>
  );
};
