import TimezoneObject from '@a_team/models/dist/TimezoneObject';

export const getBrowserTimezone = (): TimezoneObject => {
  const utcOffset = new Date().getTimezoneOffset();
  const name = Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : '';

  return {
    utcOffset,
    name,
  };
};

export const getGMTFromMinutesOffset = (offset: number): string => {
  const abs = Math.abs(offset);
  const hours = String(Math.trunc(abs / 60)).padStart(2, '0');
  const minutes = String(abs % 60).padStart(2, '0');
  const sign = offset > 0 ? '-' : '+';

  return `GMT${sign}${hours}:${minutes}`;
};
