import { SurveyType } from '@a_team/models/dist/Survey';
import { TeamPulseSurvey as TTeamPulseSurvey } from '@a_team/models/dist/TeamPulse';
import LoadingIndicator from '@src/components/LoadingIndicator';
import React, { ReactElement } from 'react';
import {
  Responses,
  TeamPulseModal,
  useTeamPulse,
} from './TeamPulseSurveyHelpers';

export interface Props<T extends SurveyType<keyof T> = SurveyType<never>> {
  onClose?: () => void;
  teamPulseSurvey: TTeamPulseSurvey<T>;
  adminView?: boolean;
  defaultResponses?: Responses<T>;
}

const TeamPulseSurveyModal = <
  T extends SurveyType<keyof T> = SurveyType<never>,
>({
  onClose,
  teamPulseSurvey,
  adminView,
  defaultResponses,
}: Props<T>): ReactElement | null => {
  const teamPulseProps = useTeamPulse<T>({
    onClose,
    teamPulseSurvey,
    adminView,
    defaultResponses,
  });

  const loadingIndicator = (
    <LoadingIndicator
      loading={teamPulseProps.loading}
      successMsg="Thank you! We received your feedback."
    />
  );

  return (
    <>
      <TeamPulseModal {...teamPulseProps} />
      {loadingIndicator}
    </>
  );
};

export default TeamPulseSurveyModal;
