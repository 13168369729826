import React, { ReactElement, useState } from 'react';
import { createUseStyles } from 'react-jss';
import showMore from './showMore.svg';
import RoleCategoryObject, {
  RoleCategoryId,
} from '@a_team/models/dist/RoleCategory';
import LabeledCheckboxInput from '@src/components/Inputs/LabeledCheckboxInput';
import HighlightedText from '@src/components/HighlightedText/HighlightedText';
import { Breakpoints } from '@ateams/components';

interface Props {
  title: string;
  selected: Set<RoleCategoryId>;
  categories: RoleCategoryObject[];
  query?: string;
  onSelect(category: RoleCategoryObject): void;
  onDeselect?(category: RoleCategoryObject): void;
}

const useStyles = createUseStyles({
  root: {
    '& + &:before': {
      display: 'block',
      content: '""',
      margin: '8px 0 24px',
      borderBottom: '0.5px solid #C0C0C0',
    },
  },
  sectionTitle: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '26px',
    letterSpacing: '0.001em',
    color: '#222222',
    marginLeft: '16px',
  },
  sectionResultsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '8px',
  },
  sectionResultsItem: {
    width: '100%',
    height: '48px',
    padding: '12px 0 12px 20px',
    margin: 0,
    '&:hover': {
      backgroundColor: '#FFFAF7',
    },
  },
  showMore: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '24px',
    color: '#FE630C',
    cursor: 'pointer',
    margin: '16px',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  showMoreIcon: {
    marginLeft: '10px',
    transition: 'all 0.5s',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    sectionResultsItem: {
      width: '50%',
    },
  },
});

export default function RoleSection(props: Props): ReactElement {
  const { title, query, categories, selected, onSelect, onDeselect } = props;

  const styles = useStyles();
  const [expanded, setExpanded] = useState(false);

  const expendLimit = query ? 8 : 6;
  const isExpendable = categories.length > expendLimit;
  const item =
    isExpendable && !expanded ? categories.slice(0, expendLimit) : categories;

  return (
    <div className={styles.root}>
      <div className={styles.sectionTitle}>{title}</div>
      <div className={styles.sectionResultsContainer}>
        {item.map((category) => (
          <LabeledCheckboxInput
            key={category.cid}
            value={category.cid}
            label={
              query ? (
                <HighlightedText query={query}>
                  {category.title}
                </HighlightedText>
              ) : (
                category.title
              )
            }
            checked={selected.has(category.cid)}
            className={styles.sectionResultsItem}
            onChange={(e): void => {
              if (e.target.checked) {
                onSelect(category);
                return;
              }

              if (onDeselect) {
                onDeselect(category);
              }
            }}
          />
        ))}
        {isExpendable && (
          <div
            className={styles.showMore}
            onClick={(): void => setExpanded(!expanded)}
          >
            <div>
              Show {expanded ? 'less' : `${categories.length - 6} more`}
            </div>
            <img
              src={showMore}
              alt="more"
              className={styles.showMoreIcon}
              style={{
                transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
