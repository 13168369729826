import {
  BorderRadius,
  Checkbox,
  CloseButton,
  Colors,
  Spacing,
} from '@ateams/components';
import MissionRole, { MissionAdminRole } from '@a_team/models/dist/MissionRole';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';

interface NicheRoleProps {
  role: MissionRole | MissionAdminRole;
  onNicheToggle?: (isNiche: boolean) => void;
  withCloseButton?: boolean; // This shows a close button
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: Spacing.medium,
    borderRadius: BorderRadius.small,
    backgroundColor: Colors.backgroundLight,
    marginBottom: Spacing.medium,
    justifyContent: 'space-between',
  },
});

function NicheRole({
  role,
  onNicheToggle,
  withCloseButton,
}: NicheRoleProps): JSX.Element {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Checkbox
        margin={'none'}
        checked={Boolean((role as MissionAdminRole).isNiche)}
        onChange={(e): void => onNicheToggle?.(e.target.checked)}
        label={'This is a niche role'}
      />
      {withCloseButton ? (
        <div>
          <CloseButton
            label="Close"
            onClick={(): void => onNicheToggle?.(false)}
          />
        </div>
      ) : null}
    </div>
  );
}

export default observer(NicheRole);
