import Colors from '../theme/colors';

export const sliderRailStyles = {
  background: Colors.backgroundDark,
  borderRadius: 100,
  height: 2,
};

export const sliderTrackStyles = {
  backgroundColor: Colors.secondary,
  height: 2,
};

export const sliderHandleStyles = {
  borderColor: Colors.backgroundWhite,
  boxShadow: `0px 1px 8px rgba(0,0,0,0.2)`,
  width: 20,
  height: 20,
  marginTop: -10,
};
