import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import {
  BasicVetterObject,
  VetterConfigurationObject,
} from '@a_team/models/dist/vetter';
import {
  VettingType,
  vettingTypeToStatusMap,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { AsyncSelectProps, Select, SelectProps } from '@ateams/components';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import LabeledCheckboxInput from '@src/components/Inputs/LabeledCheckboxInput';
import {
  SkillSelector,
  SkillSelectorProps,
} from '@src/components/SkillSelector';
import {
  TalentCategoryId,
  TalentSkillId,
} from '@a_team/models/dist/TalentCategories';
import { SignatureCreatorLocation } from '@src/locations';
import { RoleSelector } from '@src/views/VettingDashboard/role-selector';
import { format, formatDistanceToNow } from 'date-fns';

export interface VetterConfigurationFormProps {
  defaultValues?: VetterConfigurationDefaultValues;
  vetterConfiguration?: VetterConfigurationObject;
  onChange?: OnVetterConfigurationFormChange;
}

type VetterConfigurationDefaultValues = Partial<
  Pick<
    BasicVetterObject,
    | 'type'
    | 'calendarUrl'
    | 'interviewCapacity'
    | 'emailSignature'
    | 'isActive'
    | 'vettingSkills'
    | 'vettingRoles'
  >
>;

type VetterConfigurationFormValues = Omit<
  VetterConfigurationDefaultValues,
  'vettingSkills' | 'vettingRoles'
> & { vettingSkills?: TalentSkillId[]; vettingRoles?: TalentCategoryId[] };

type OnVetterConfigurationFormChange = (
  values: VetterConfigurationFormValues,
) => void;

const useStyles = createUseStyles({
  inputRow: {
    marginBottom: '12px',
  },
  inputLabel: {
    fontSize: 14,
    lineHeight: '2.6em',
    color: '#62646A',
  },
  outlinedInput: {
    margin: 0,
  },
  inputContainer: {},
  iframe: {
    margin: 0,
    height: '100%',
    width: '100%',
    minHeight: '180px',
  },
  infoLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const VETTING_TYPE_OPTIONS = Object.entries(vettingTypeToStatusMap).map(
  ([key, label]) => ({ value: key, label }),
);

export const VetterConfigurationForm: React.FC<
  VetterConfigurationFormProps
> = ({ defaultValues, vetterConfiguration, onChange }) => {
  const styles = useStyles();

  const [type, setType] = useState<VetterConfigurationFormValues['type']>(
    defaultValues?.type,
  );

  const [vettingSkills, setVettingSkills] = useState<
    VetterConfigurationFormValues['vettingSkills']
  >(defaultValues?.vettingSkills?.map(({ id }) => id) || []);

  const [vettingRoles, setVettingRoles] = useState<
    VetterConfigurationFormValues['vettingRoles']
  >(defaultValues?.vettingRoles?.map(({ cid }) => cid) || []);

  const [calendarUrl, setCalendarUrl] = useState<
    VetterConfigurationFormValues['calendarUrl']
  >(defaultValues?.calendarUrl);

  const [interviewCapacity, setInterviewCapacity] = useState<
    VetterConfigurationFormValues['interviewCapacity']
  >(defaultValues?.interviewCapacity);

  const [emailSignature, setEmailSignature] = useState<
    VetterConfigurationFormValues['emailSignature']
  >(defaultValues?.emailSignature);

  const [isActive, setIsActive] = useState<
    VetterConfigurationFormValues['isActive']
  >(Boolean(defaultValues?.isActive));

  useEffect(() => {
    if (onChange) {
      onChange({
        type,
        vettingSkills,
        vettingRoles,
        calendarUrl,
        interviewCapacity,
        emailSignature,
        isActive,
      });
    }
  }, [
    type,
    vettingSkills,
    vettingRoles,
    calendarUrl,
    interviewCapacity,
    emailSignature,
    isActive,
  ]);

  const onVettingTypeChange: SelectProps['onChange'] = (option) => {
    if (option) {
      setType(option.value as VettingType);
    }
  };

  const onCalendarUrlChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setCalendarUrl(e.target.value);
  };

  const onInterviewCapacityChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    setInterviewCapacity(parseInt(e.target.value) || 0);
  };

  const onVettingSkillsChange: SkillSelectorProps['onChange'] = (skills) => {
    setVettingSkills(skills);
  };

  const onVettingRolesChange: AsyncSelectProps<true>['onChange'] = (roles) => {
    setVettingRoles(roles ? roles.map(({ value }) => value) : []);
  };

  const onIsActiveChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsActive(e.target.checked);
  };

  const onEmailSignatureChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setEmailSignature(e.target.value);
  };

  const onEmailSignaturePaste: React.ClipboardEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const html = e.clipboardData.getData('text/html');

    if (html) {
      setEmailSignature(html);
    }
  };

  return (
    <>
      <div className={styles.inputRow}>
        <div className={styles.inputContainer}>
          {vetterConfiguration && (
            <div className={styles.infoLabel}>
              <b>Joined Selection Team:</b>{' '}
              <div>
                {`${format(
                  new Date(vetterConfiguration.createdAt),
                  'MM/dd/yyyy',
                )} (${formatDistanceToNow(
                  new Date(vetterConfiguration.createdAt),
                )} ago)`}
              </div>
            </div>
          )}
          {vetterConfiguration && (
            <div>
              <b>Interviews done:</b> {vetterConfiguration.totalInterviewsDone}
            </div>
          )}
          <br />

          <div className={styles.inputLabel}>Evaluation Type</div>

          <Select
            onChange={onVettingTypeChange}
            options={VETTING_TYPE_OPTIONS}
            value={VETTING_TYPE_OPTIONS.find(({ value }) => value === type)}
            margin="none"
          />
        </div>
      </div>

      <div className={styles.inputRow}>
        <div className={styles.inputLabel}>Evaluation Roles</div>

        <div className={styles.inputContainer}>
          <RoleSelector
            type="all"
            isMulti
            defaultValue={defaultValues?.vettingRoles?.map(
              ({ cid, title }) => ({
                value: cid,
                label: title,
              }),
            )}
            isClearable={false}
            margin={'none'}
            onChange={onVettingRolesChange}
          />
        </div>
      </div>

      <div className={styles.inputRow}>
        <div className={styles.inputLabel}>Evaluation Skills</div>

        <div className={styles.inputContainer}>
          <SkillSelector
            type="all"
            defaultValue={defaultValues?.vettingSkills?.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            onChange={onVettingSkillsChange}
          />
        </div>
      </div>

      <div className={styles.inputRow}>
        <div className={styles.inputLabel}>Calendar Url</div>

        <div className={styles.inputContainer}>
          <OutlinedInput
            className={styles.outlinedInput}
            value={calendarUrl}
            placeholder="Calendar URL"
            onChange={onCalendarUrlChange}
          />
        </div>
      </div>

      <div className={styles.inputRow}>
        <div className={styles.inputLabel}>Interview Capacity</div>

        <div className={styles.inputContainer}>
          <OutlinedInput
            className={styles.outlinedInput}
            type="number"
            value={interviewCapacity}
            placeholder="Interview Capacity"
            onChange={onInterviewCapacityChange}
          />
        </div>
      </div>

      <br />

      <div className={styles.inputRow}>
        <LabeledCheckboxInput
          className={styles.outlinedInput}
          label="Interviewer is Active"
          checked={isActive}
          onChange={onIsActiveChange}
        />
      </div>

      <div className={styles.inputRow} onPaste={onEmailSignaturePaste}>
        <div className={styles.inputLabel}>
          Email Signature (
          <Link target="_blank" to={SignatureCreatorLocation}>
            Signature Creator
          </Link>
          )
        </div>

        <OutlinedInput
          className={styles.outlinedInput}
          value={emailSignature}
          onChange={onEmailSignatureChange}
          multiline
          maxRows={10}
        />
      </div>

      <div className={styles.inputRow}>
        <div className={styles.inputLabel}>Preview:</div>

        <iframe
          title="template-previewer"
          id="vetting-email-signature-iframe"
          className={styles.iframe}
          frameBorder="0"
          srcDoc={emailSignature}
        />
      </div>
    </>
  );
};
