import React, { ReactElement } from 'react';
import UserAvatar from '@src/components/UserAvatar';
import { Icon, IconType } from '@ateams/components';
import {
  AnonymousUserObject,
  BasicUserObject,
} from '@a_team/models/dist/UserObject';
import { createUseStyles } from 'react-jss';

interface UserItemProps {
  user: AnonymousUserObject | BasicUserObject;
  onUserSelect?: (user: BasicUserObject | AnonymousUserObject) => void;
  containerStyle?: React.CSSProperties;
}

const useStyles = createUseStyles({
  item: (props) => ({
    display: 'flex',
    padding: '15px 24px',
    '&:last-of-type': {
      marginBottom: 0,
    },
    ...(props.onUserSelect && {
      cursor: 'pointer',
      '&:hover': {
        background: '#FFFAF7',
      },
    }),
    ...(props.containerStyle && props.containerStyle),
  }),
  iconContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  plus: {
    position: 'absolute',
  },
  name: {
    fontSize: 15,
    color: '#222222',
    fontWeight: 500,
  },
  userDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 24,
    justifyContent: 'center',
  },
});

export const UserItem = (props: UserItemProps): ReactElement => {
  const { fullName } = props.user;
  const profileImage =
    props.user.username !== null ? props.user.profilePictureURL : undefined;
  const userRole = 'title' in props.user ? props.user.title : 'New Friend';
  const styles = useStyles({ ...props });

  const onSelect = (): void => {
    props.onUserSelect && props.onUserSelect(props.user);
  };

  return (
    <div className={styles.item} onClick={onSelect}>
      <div className={styles.iconContainer}>
        <UserAvatar src={profileImage} />
        {!profileImage && (
          <Icon type={IconType.Plus} className={styles.plus} size="exact" />
        )}
      </div>
      <div className={styles.userDetails}>
        <span className={styles.name}>{fullName}</span>
        <span>{userRole}</span>
      </div>
    </div>
  );
};
