import { ExperienceUserMember } from '@a_team/models/dist/ExperienceObject';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import CommonStyles from '../common/styles';
import Collaborator from './Collaborator';
import { CollaboratorStatus } from '@a_team/models/dist/UserObject';
import { Expertise } from '@src/stores/Profile/models';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';

interface CollaboratorsProps {
  members: ExperienceUserMember[];
}

const useStyles = createUseStyles({
  container: {
    marginTop: 64,
  },
  collaborators: {
    marginTop: 24,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 24,
  },
});

const Collaborators = ({ members }: CollaboratorsProps): JSX.Element | null => {
  const { users } = useStores();
  const commonStyles = CommonStyles();
  const styles = useStyles();
  const [roles, setRoles] = useState<Expertise[]>([]);

  const activeMembers = useMemo(
    () =>
      members.filter(
        (member) => member.collaboratorStatus === CollaboratorStatus.Active,
      ),
    [members],
  );

  useEffect(() => {
    if (roles.length || !users?.profile?.queryRoles) return;
    users.profile.queryRoles('').then((res) => {
      setRoles(res);
    });
  }, [roles.length, users?.profile?.queryRoles]);

  if (activeMembers.length <= 1) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={commonStyles.title}>Project collaborators</div>
      <div className={styles.collaborators}>
        {activeMembers.map((member) => (
          <Collaborator member={member} roles={roles} />
        ))}
      </div>
    </div>
  );
};

export default observer(Collaborators);
