import { Button } from '@a_team/ui-components';
import CompanyLogo from '@src/components/CompanyLogo';
import React from 'react';
import { useCommonStyles } from './commonStyles';
import interviewDeclinedIcon from './interview-declined.svg';

interface DeclineNotAvailableProps {
  companyImageUrl?: string | null;
  onClose: () => void;
  onUpdateAvailability: () => void;
}

const DeclineNotAvailable = ({
  companyImageUrl,
  onClose,
  onUpdateAvailability,
}: DeclineNotAvailableProps): JSX.Element => {
  const styles = useCommonStyles();

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <CompanyLogo logoUrl={companyImageUrl} size={72} />
        <img
          className={styles.indicatorIcon}
          src={interviewDeclinedIcon}
          alt="interview declined"
        />
      </div>
      <h4 className={styles.title}>You’ve declined the interview</h4>
      <div className={styles.desc}>
        Your availability has been updated to unavailable — please adjust if you
        want to be considered for new missions.
      </div>
      <div className={styles.btnsContainer}>
        <Button onClick={onClose} variant="secondary">
          Close
        </Button>
        <Button
          className={styles.updateAvailabilityBtn}
          onClick={onUpdateAvailability}
        >
          Update availability
        </Button>
      </div>
    </div>
  );
};

export default DeclineNotAvailable;
