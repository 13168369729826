import React from 'react';
import { Button } from '@a_team/ui-components';
import { observer } from 'mobx-react';
import Step, { SetupProps, StepStatus } from '../common/Step/';
import StepIcon, { StepIconType } from '../common/Step/StepIcon';
import { OnboardingStage } from '@a_team/models/dist/UserObject';
import { useStores } from '@src/stores';
import { Link, useHistory } from 'react-router-dom';
import { InviteLocation } from '@src/locations';

interface CompleteProfileStepProps {
  onboardingStage: OnboardingStage;
  hideCta?: boolean;
  hideVideo?: boolean;
  hideBorder?: boolean;
  isSourcedBuilder?: boolean;
  isProfileCompleted?: boolean;
}

const CompleteProfileStep = ({
  onboardingStage,
  hideCta,
  hideVideo,
  hideBorder,
  isSourcedBuilder,
  isProfileCompleted,
}: CompleteProfileStepProps) => {
  const { auth } = useStores();
  const history = useHistory();

  const handleOnClickCTA = () => {
    history.push(`/${auth.user?.username}`);
  };

  const incompleteTitle = isSourcedBuilder
    ? 'Gain full access by completing your profile'
    : 'Build a stellar profile to stand out';

  const incompleteDescription = isSourcedBuilder
    ? onboardingStage === OnboardingStage.CompletedWithIncompleteProfile
      ? 'Finish your profile to access all missions, get featured to companies, and connect with the community.'
      : 'Once you’ve completed your evaluation call, you can gain full access to the network by filling in your profile.'
    : 'Ensure your profile fully represents your skills and experience. To submit for review, complete all of the requirements at the top of the profile page.';

  const INCOMPLETE_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.NUMBER} value="1" />,
    title: incompleteTitle,
    description: incompleteDescription,
    completionTime: 'Estimated completion time: 15 to 60 minutes',
    cta: hideCta ? undefined : (
      <Button size="md" onClick={handleOnClickCTA}>
        Complete profile
      </Button>
    ),
    videoUrl: hideVideo
      ? undefined
      : 'https://www.loom.com/share/2e72a9dbf29e45a8bd456e8e015ed0ab',
    stepStatus: StepStatus.IN_PROGRESS,
    hideBorder: hideBorder,
  };

  const completedDescription =
    isSourcedBuilder &&
    onboardingStage === OnboardingStage.InterviewScheduled ? (
      <>
        Wait for your evaluation results to gain full access to the network. You
        can <Link to={InviteLocation}>invite teammates</Link> while you wait.
      </>
    ) : (
      "You've completed all of the requirements at the top of the profile page."
    );

  const COMPLETED_PROFILE_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.COMPLETED} />,
    title: 'Build a stellar profile to stand out',
    description: completedDescription,
    cta: hideCta ? undefined : <div>Completed</div>,
    stepStatus: StepStatus.COMPLETED,
  };

  const stepPayload: SetupProps =
    (isSourcedBuilder && !isProfileCompleted) ||
    onboardingStage === OnboardingStage.IncompleteProfile
      ? INCOMPLETE_DATA
      : COMPLETED_PROFILE_DATA;

  return <Step {...stepPayload} />;
};

export default observer(CompleteProfileStep);
