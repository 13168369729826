import { useStores } from '@src/stores';
import { Redirect } from 'react-router-dom';
import {
  AdminInvoicesToBeIssuedLocationTemplate,
  RootLocation,
} from '@src/locations';
import { observer } from 'mobx-react';
import React from 'react';

const InvoicesAdminDashboardView = () => {
  const { auth } = useStores();

  if (!auth.isAdmin) {
    return <Redirect to={RootLocation} />;
  }

  return (
    <>
      <Redirect to={AdminInvoicesToBeIssuedLocationTemplate} />
    </>
  );
};

export default observer(InvoicesAdminDashboardView);
