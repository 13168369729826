import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import useToggle from '@src/hooks/useToggle';
import OutlinedControl from '@src/components/Inputs/OutlinedControl';
import Dropdown, { DropdownItem } from '@src/components/Dropdown';
import LabeledCheckboxInput from '@src/components/Inputs/LabeledCheckboxInput';

interface Props {
  beenOnMission: boolean;
  verified: boolean;
  selectionTeam: boolean;
  interviewed: boolean;
  hasBeenScrubbed: boolean;
  setSelectionTeam: (isVetter: boolean) => void;
}

interface StatusProps {
  disabled?: boolean;
}

const useStyles = createUseStyles({
  label: {
    fontSize: 14,
    lineHeight: '2.6em',
    color: '#62646A',
  },
  status: {
    color: (props: StatusProps = {}): string =>
      props.disabled ? '#c0c0c0' : '#000',
  },
  counterBadge: {
    backgroundColor: '#79CE00',
    display: 'inline-block',
    padding: '0px 7px',
    borderRadius: 14,
    marginLeft: 8,
    color: '#fff',
    fontSize: 13,
  },
});

const statusLabels: Record<string, string> = {
  verified: 'Verified User',
  selectionTeam: 'Interviewer',
  interviewed: 'Already Interviewed',
  beenOnMission: 'Been On Mission',
  notScrubbed: 'Not Scrubbed',
};

const propsToStatus = (props: Props): string[] =>
  [
    props.verified ? statusLabels.verified : null,
    props.selectionTeam ? statusLabels.selectionTeam : null,
    props.interviewed ? statusLabels.interviewed : null,
    props.beenOnMission ? statusLabels.beenOnMission : null,
  ].filter((value): value is string => !!value);

const StatusItem = (props: {
  title: string;
  selected: boolean;
  disabled?: boolean;
  onChange?: () => void;
}): ReactElement => {
  const styles = useStyles();
  const { title, disabled, selected, onChange } = props;

  return (
    <DropdownItem style={{ display: 'flex' }}>
      <LabeledCheckboxInput
        label={
          <div className={styles.status} {...{ disabled }}>
            {title}
          </div>
        }
        checked={selected}
        disabled={disabled}
        onChange={onChange}
        margin="none"
      />
    </DropdownItem>
  );
};

export const UserStatusInput = (props: Props): ReactElement => {
  const styles = useStyles();
  const {
    beenOnMission,
    verified,
    selectionTeam,
    interviewed,
    hasBeenScrubbed,
  } = props;

  const [isVetter, setIsVetter] = useState<boolean>(selectionTeam);
  const [statusDropdown, toggleStatusDropdown] = useToggle(false);

  useEffect(() => setIsVetter(selectionTeam), [selectionTeam]);

  const status = useMemo(() => propsToStatus(props), [props]);

  return (
    <>
      <span className={styles.label}>User Status</span>
      <OutlinedControl
        caret
        input={
          status.length === 0 ? (
            <span style={{ color: 'gray' }}> Select a Status </span>
          ) : status.length === 1 ? (
            <span>{status[0]}</span>
          ) : (
            <>
              <span>Selected Statuses</span>
              <div className={styles.counterBadge}>{status.length}</div>
            </>
          )
        }
        onClick={toggleStatusDropdown}
        style={{ margin: 0 }}
        dropdownPlacement="bottom"
        dropdown={
          <Dropdown
            open={statusDropdown}
            onClose={() => toggleStatusDropdown(false)}
          >
            <>
              <StatusItem
                title={statusLabels.selectionTeam}
                selected={isVetter}
                onChange={() => props.setSelectionTeam(!isVetter)}
              />
              <StatusItem
                disabled={true}
                title={statusLabels.beenOnMission}
                selected={beenOnMission}
              />
              <StatusItem
                disabled={true}
                title={statusLabels.interviewed}
                selected={interviewed}
              />
              <StatusItem
                disabled={true}
                title={statusLabels.verified}
                selected={verified}
              />
              <StatusItem
                disabled={true}
                title={statusLabels.notScrubbed}
                selected={!hasBeenScrubbed}
              />
            </>
          </Dropdown>
        }
      />
    </>
  );
};
