import { Breakpoints } from '@ateams/components';
import TextAreaInput from '@src/components/Inputs/TextAreaInput';
import React from 'react';
import { createUseStyles } from 'react-jss';
import ControlButtons from './common/ControlButtons';
import { TeamPulseSectionsEnum } from './TeamPulseModal';

interface WhatCouldImproveExperienceProps {
  setSection: (section: string) => void;
  setUserReviewSection: (section: string) => void;
  setWhatCouldImproveExperience: (value: string | undefined) => void;
  missionTitle: string;
  needsToReviewUsers?: boolean;
  initialState?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    height: '100%',
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'start',
  },
  desc: {
    textAlign: 'start',
    color: '#707275',
    marginBottom: 40,
    marginTop: 8,
  },
  textArea: {
    border: '1px solid #D1D2D6',
    height: 140,
    borderRadius: 8,
    padding: 10,
  },
  wrapper: {
    padding: 20,
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    wrapper: {
      padding: 0,
    },
  },
});

const WhatCouldImproveExperience = ({
  setSection,
  setUserReviewSection,
  setWhatCouldImproveExperience,
  missionTitle,
  needsToReviewUsers,
  initialState,
}: WhatCouldImproveExperienceProps): JSX.Element => {
  const styles = useStyles();
  const [text, setText] = React.useState(initialState || '');

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setText(e.target.value.trimStart());
  };

  const onBack = (): void => {
    setSection(TeamPulseSectionsEnum.ScopedWellDefined);
  };

  const onContinue = (): void => {
    setWhatCouldImproveExperience(text);
    if (needsToReviewUsers) {
      setUserReviewSection(TeamPulseSectionsEnum.TeamMemberRating);
    } else {
      setSection(TeamPulseSectionsEnum.AllDone);
    }
  };

  const onSkip = (): void => {
    setWhatCouldImproveExperience(undefined);
    if (needsToReviewUsers) {
      setUserReviewSection(TeamPulseSectionsEnum.TeamMemberRating);
    } else {
      setSection(TeamPulseSectionsEnum.AllDone);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>What could improve your experience?</h4>
        <p className={styles.desc}>
          This question is optional and is not shared with the {missionTitle}.
        </p>
        <TextAreaInput
          value={text}
          className={styles.textArea}
          onChange={handleChange}
          placeholder="Tell us more about what’s going on."
        />
      </div>
      <ControlButtons
        canContinue={text.length > 0}
        onBack={onBack}
        onContinue={onContinue}
        onSkip={onSkip}
      />
    </div>
  );
};

export default WhatCouldImproveExperience;
