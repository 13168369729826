/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-interface */

import { InvitationRequestData } from '@ateams/api/dist/endpoints/Invitations';
import {
  ConnectionObject,
  ConnectionType,
} from '@a_team/models/dist/ConnectionObject';
import {
  MissionCardObject,
  MissionCompanyRequest,
  MissionId,
  MissionStatus,
} from '@a_team/models/dist/MissionObject';
import { TeamPulseId, TeamPulseSurveyId } from '@a_team/models/dist/TeamPulse';
import {
  NoCalendarReason,
  OtherCalendarType,
  UserCardObject,
  UserId,
  UserStatus,
  UserType,
} from '@a_team/models/dist/UserObject';
import {
  MissionRoleId,
  MissionRoleQuestionId,
} from '@a_team/models/dist/MissionRole';
import { MissionApplicationId } from '@a_team/models/dist/MissionApplicationObject';
import {
  AvailableType,
  ReminderPeriod,
} from '@a_team/models/dist/AvailabilityObject';
import { DateISOString } from '@a_team/models/dist/misc';
import {
  PaymentCycleAdminSummary,
  PaymentCycleSummary,
} from '@a_team/models/src/MissionPaymentCycleObject';
import { ContractType } from '@a_team/models/src/ContractObject';
import {
  BuilderSuggestionType,
  SuggestionActionType,
} from '@a_team/models/dist/ProfileEnhancements';
import { VettingType } from '@a_team/models/src/vetting-processes/vetting-processes';
import { AppliedMissionsDataResponse } from '@a_team/models/dist/v2/MissionApplication';

// Imported types

export enum SearchCategories {
  Members = 'Members',
  Teams = 'Teams',
}
export type SearchObject = { query: string; category: SearchCategories };

export enum ProfileVersion {
  New = 'New',
  Old = 'Old',
}

export enum ProfileUpdateSource {
  Profile = 'Profile',
  Application = 'Application',
}

export enum AvailabilityUpdateSource {
  MissionNotifier = 'MissionNotifier',
  Profile = 'Profile',
  AdminNotes = 'AdminNotes',
}

export type OnboardingItem = {
  label: string;
  done: boolean;
  linkTo?: string;
  action?: string;
};

export enum RegistrationFlow {
  Individual = 'Individual',
  TeamCreator = 'TeamCreator',
  TeamMemberResponse = 'TeamMemberResponse',
}

export enum ConnectionsModalSources {
  DiscoveryBuilders = 'DiscoveryBuilders',
  DiscoveryNotifications = 'DiscoveryNotifications',
  UserProfile = 'UserProfile',
  NotificationBubble = 'NotificationBubble',
}

export interface IdentityTraits {
  uid: string;
  type?: UserType;
  fullName?: string;
  email?: string;
  phoneNumber?: string;
  websiteURL?: string;
  availability?: AvailableType;
  username?: string | null;
  isAdmin?: boolean;
  createdAt?: DateISOString;
  scrubbedAt?: DateISOString;
  tags?: string[];
}

export enum UserEvent {
  SignedUp = 'Signed Up',
  SignedIn = 'Signed In',
  SignedOut = 'Signed Out',
  CompanyTeamRequested = 'Company Team Requested',
  SearchQuerySubmitted = 'Search Query Submitted',
  SearchHistoryClicked = 'Search History Clicked',
  DiscoveryBarClicked = 'Discovery Bar Clicked',
  DiscoveryTabClicked = 'Discovery Tab Clicked',
  DiscoveryOnboardingItemClicked = 'Discovery Onboarding item Clicked',
  DiscoveryCurrentUserCardClicked = 'Discovery Current User Card Clicked',
  DiscoveryNetworkStrengthCardClicked = 'Discovery Network Strength Card Clicked',
  DiscoveryNotificationClicked = 'Discovery Notification Clicked',
  DiscoveryNotificationConnectionResponded = 'Discovery Notification Connection Responded',
  DiscoveryInviteCTAClicked = 'Discovery Invite CTA Clicked',
  DiscoveryShowMoreRolesLinkClicked = 'Discovery Show More Roles Link Clicked',
  UserCardClicked = 'User Card Clicked',
  UserMessageSent = 'User Message Sent',
  SupportMessageSent = 'Support Message Sent',
  MissionCardClicked = 'Mission Card Clicked',
  MissionCardClickedV2 = 'Mission Card V2 Clicked',
  MissionVideoPlayed = 'Mission Video Played',
  MissionCardsFilterSelected = 'Mission Cards Filter Selected',
  MissionCardNotInterested = 'Mission Card Not Interested',
  MissionApplyStarted = 'Mission Apply Started',
  MissionApplySubmitted = 'Mission Apply Submitted',
  MissionApplyTeammateRequestClicked = 'Mission Apply Teammate Request Clicked',
  MissionApplyTeammateCardClicked = 'Mission Apply Teammate Card Clicked',
  MissionApplyTeammateInviteClicked = 'Mission Apply Teammate Invite Clicked',
  MissionApplyTeammateModalNavigationClicked = 'Mission Apply Teammate Modal Navigation Clicked',
  MissionApplyTeammateSearchQuerySubmitted = 'Mission Apply Teammate Search Query Submitted',
  MissionApplyTeammateRecommendationsSkipped = 'Mission Apply Teammate Recommendations Skipped',
  MissionApplyTeammateRecommendationsViewed = 'Mission Apply Teammate Recommendations Viewed',
  MissionApplyTeammateRecommendationsSubmitted = 'Mission Apply Teammate Recommendations Submitted',
  ProfileImageEditClicked = 'Profile Image Edit Clicked',
  ProfileRolesAddButtonClicked = 'Profile Roles Add Button Clicked',
  ProfileSkillsEditButtonClick = 'Profile Skills Edit Button Clicked',
  ProfileIndustriesAddButtonClicked = 'Profile Industries Add Button Clicked',
  ProfileJobsAddButtonClicked = 'Profile Jobs Add Button Clicked',
  ProfileProjectsAddButtonClicked = 'Profile Projects Add Button Clicked',
  ProfileProjectsEditButtonClicked = 'Profile Projects Edit Button Clicked',
  ProfileEditButtonClicked = 'Profile Edit Button Clicked',
  ProfileSaveButtonClicked = 'Profile Save Button Clicked',
  ProfileOptionsMenuClicked = 'Profile Options Menu Clicked',
  ProfileYearsExperienceChanged = 'Profile Years Experience Changed',
  ProfileRoleChanged = 'Profile Role Changed',
  ProfileWebsiteLinkClicked = 'Profile Website Add Button Clicked',
  ProfileConnectButtonClicked = 'Profile Connect Button Clicked',
  ReferAFriendClicked = 'Refer a Friend Clicked',
  InviteModalSubmitted = 'Invite Modal Submitted',
  TimesheetInviteModalSubmitted = 'Timesheet Invite Modal Submitted',
  TimesheetSubmitted = 'Timesheet Submitted',
  TeamPulseSurveyStarted = 'Team Pulse Survey Started',
  TeamPulseSurveySubmitted = 'Team Pulse Survey Submitted',
  AvailabilitySettingsUpdated = 'Availability Settings Updated',
  RegistrationStepViewed = 'Registration Step Viewed',
  RegistrationStepSubmitted = 'Registration Step Submitted',
  RegistrationBackButtonClicked = 'Registration Back Button Clicked',
  RegistrationVideoPlayClicked = 'Registration Video Play Clicked',
  RegistrationComplete = 'Registration Complete',
  CustomAgreementUploadClicked = 'Upload Custom Agreement Clicked',
  CustomAgreementUploadStarted = 'Started Upload Custom Agreement',
  CustomAgreementUploadEnded = 'Ended Upload Custom Agreement',
  ContractViewed = 'Contract Viewed',
  ContractDeleted = 'Contract Deleted',
  ViewAllUserConnectionsClicked = 'View All User Connections Clicked',
  ViewAllUserConnectionsViewProfileClicked = 'View All User Connections View Profile Clicked',
  ConnectionRequest = 'ConnectionRequest',
  ApproveConnectionRequest = 'ApproveConnectionRequest',
  RejectConnectionRequest = 'RejectConnectionRequest',
  CreateNewJobProjectClickedOnConnectionsModal = 'Create New Job or Project Clicked On Connections Modal',
  ConnectionModalOpened = 'Connection Modal Opened',
  TargeterSearchClicked = 'Targeter Search Clicked',
  TargeterTeamSearchClicked = 'Targeter Team Search Clicked',
  TargeterReviewClicked = 'Targeter Review Clicked',
  TargeterOutreachClicked = 'Targeter Outreach Clicked',
  SuggestedTeamsTargeterTabClicked = 'Suggested Teams Targeter Tab Clicked',
  SuggestedTeamsRunQueryClicked = 'Suggested Teams Run Query Clicked',
  TargeterComposeEmailClicked = 'Targeter Compose Email Clicked',
  TargeterPreviewEmailClicked = 'Targeter Preview Email Clicked',
  TargeterSendEmailClicked = 'Targeter Send Email Clicked',
  TargeterBuilderCardClicked = 'Targeter Builder Card Clicked',
  TargeterResultPageChanged = 'Targeter Result Page Changed',
  ProfileSuggestionActed = 'User Acted On A Profile Suggestion',
  ProfileSuggestionsRetriggered = 'User requested more profile suggestions',
  StartWritingProjectDescriptionClicked = 'User clicks Start Writing when creating/editing new project',
  ProjectDescriptionInsertSuggestionClicked = 'User inserts project description suggestion',
  ProjectDescriptionDiscardSuggestionClicked = 'User discards project description suggestion',
  PitchStartWritingClicked = 'User requested to generate a pitch suggestion',
  PitchRewriteClicked = 'User requested to generate a new pitch suggestion',
  PitchInsertSuggestionClicked = 'User inserts pitch suggestion',
  PitchDiscardSuggestionClicked = 'User discards pitch suggestion',
  ProfileSuggestionsGenerated = 'User requested generation of profile enhancement suggestions',
  QuestionRecommendationGenerated = `Custom role question recommendation was generated on user's behalf`,
  ConnectCalendarClicked = 'User clicked connect calendar',
  ConnectGoogleCalendarClicked = 'User clicked connect google calendar',
  ConnectAppleCalendarClicked = 'User clicked connect apple calendar',
  ConnectOutlookCalendarClicked = 'User clicked connect outlook calendar',
  CantConnectCalendarClicked = 'User clicked on cant connect calendar',
  NonSupportedCalendarProvided = 'User provided a non supported calendar',
  MissionConnectCalendarClick = 'User clicked connect caledar from mission',
  AddCustomCalendarClicked = 'User clicked add custom calendar link',
  MissionAddCustomCalendarClicked = 'User clicked add custom calendar link from mission',
  ManageCalendarClicked = 'User clicked manage calendar button on the settings page',
  UpdateCustomCalendarLinkClicked = 'User clicked to update custom calendar link',
  ManageWorkingHoursClicked = 'User clicked manage working hours button on the settings page',
  ConnectCalendarInsteadClicked = 'User clicked connect calendar instead from no calendar modal',
  CustomCalendarAdded = 'User added a custom calendar link',
  ApplicationStarted = 'Application Started',
  ApplicationCompleted = 'Application Completed',
  MoreAvailableTimesClicked = 'More available times Clicked',
  EvaluationCallBooked = 'Evaluation Call Booked',
  ProfileSubmittedForReview = 'Profile Submitted For Review',
  ProfileCompleted = 'Profile Completed',
}

export interface UserEvents {
  [UserEvent.SignedUp]: {
    uid: string;
    type: UserType;
    status: UserStatus;
    email: string;
  };
  [UserEvent.CompanyTeamRequested]: MissionCompanyRequest;
  [UserEvent.SignedIn]: {
    uid: string;
    status: UserStatus;
    email: string;
  };
  [UserEvent.SignedOut]:
    | {
        uid: string;
        email: string;
      }
    // eslint-disable-next-line @typescript-eslint/ban-types
    | {};
  [UserEvent.UserMessageSent]: {
    uid: string;
    email: string;
  };
  [UserEvent.SupportMessageSent]: {
    uid: string;
    email: string;
  };
  [UserEvent.SearchQuerySubmitted]: SearchObject;
  [UserEvent.SearchHistoryClicked]: SearchObject;
  [UserEvent.DiscoveryBarClicked]: { section: string };
  [UserEvent.DiscoveryTabClicked]: { tab: string };
  [UserEvent.DiscoveryOnboardingItemClicked]: OnboardingItem;
  [UserEvent.DiscoveryCurrentUserCardClicked]: {};
  [UserEvent.DiscoveryNetworkStrengthCardClicked]: {};
  [UserEvent.DiscoveryNotificationClicked]: ConnectionObject;
  [UserEvent.DiscoveryNotificationConnectionResponded]: {
    connection: ConnectionObject;
    response?: 'approve' | 'decline';
  };
  [UserEvent.DiscoveryInviteCTAClicked]: {};
  [UserEvent.DiscoveryShowMoreRolesLinkClicked]: {};
  [UserEvent.UserCardClicked]: UserCardObject;
  [UserEvent.MissionCardClicked]: MissionCardObject & { recommended?: true };
  [UserEvent.MissionCardClickedV2]: AppliedMissionsDataResponse['mission'];
  [UserEvent.MissionVideoPlayed]: {
    mid: string;
    missionTitle: string;
    missionStatus: MissionStatus;
    videoUrl: string;
    source: 'mission' | 'card' | 'details';
  };
  [UserEvent.MissionCardsFilterSelected]: {
    roleCategories: { label: string; id: string }[];
  };
  [UserEvent.MissionCardNotInterested]: {
    mid: string;
    missionTitle: string;
    missionStatus: MissionStatus;
    recommended: boolean;
    hide: boolean;
  };
  [UserEvent.MissionApplyStarted]: {
    mid: string;
    missionTitle: string;
    missionStatus: MissionStatus;
    rid: string;
    roleCategoryTitle: string;
    roleHeadline: string;
    lookingForApplications: boolean;
    profileVersion?: ProfileVersion;
    recommended?: boolean;
  };
  [UserEvent.MissionApplySubmitted]: {
    mid: string;
    missionTitle: string;
    missionStatus: MissionStatus;
    rid: string;
    roleCategoryTitle: string;
    roleHeadline: string;
    hourlyRate: number | undefined;
    lookingForApplications: boolean;
    profileVersion?: ProfileVersion;
    recommended?: boolean;
  };
  [UserEvent.MissionApplyTeammateRecommendationsViewed]: {
    mid: string;
    missionTitle: string;
    rid: string | undefined;
    roleCategoryTitle: string;
    profileVersion?: ProfileVersion;
  };
  [UserEvent.MissionApplyTeammateRequestClicked]: {
    mid: string;
    missionTitle: string;
    rid: string | undefined;
    roleCategoryTitle: string;
    uid: UserId;
    username: string;
    source: 'search' | 'recommendation';
    placement: 'card' | 'modal';
    profileVersion?: ProfileVersion;
  };
  [UserEvent.MissionApplyTeammateCardClicked]: {
    mid: MissionId;
    missionTitle: string;
    rid: string;
    roleCategoryTitle: string;
    uid: UserId;
    username: string;
    source: 'search' | 'recommendation';
    profileVersion?: ProfileVersion;
  };
  [UserEvent.MissionApplyTeammateInviteClicked]: {
    mid: MissionId;
    missionTitle: string;
    rid: string;
    roleCategoryTitle: string;
    profileVersion?: ProfileVersion;
  };
  [UserEvent.MissionApplyTeammateModalNavigationClicked]: {
    mid: MissionId;
    missionTitle: string;
    rid: string;
    roleCategoryTitle: string;
    uid: UserId;
    username: string;
    profileVersion?: ProfileVersion;
  };
  [UserEvent.MissionApplyTeammateSearchQuerySubmitted]: {
    mid: MissionId;
    missionTitle: string;
    rid: string;
    roleCategoryTitle: string;
    query: string;
    profileVersion?: ProfileVersion;
  };
  [UserEvent.MissionApplyTeammateRecommendationsSkipped]: {
    mid: MissionId;
    missionTitle: string;
    rid: string;
    roleCategoryTitle: string;
    profileVersion?: ProfileVersion;
  };
  [UserEvent.MissionApplyTeammateRecommendationsSubmitted]: {
    recommendationCount: number;
    mid: MissionId;
    missionTitle: string;
    rid: string;
    roleCategoryTitle: string;
    profileVersion?: ProfileVersion;
  };
  [UserEvent.ReferAFriendClicked]: {
    mid: string;
    missionTitle: string;
    missionStatus: MissionStatus;
    rid: string;
    roleCategoryTitle: string;
    roleHeadline: string;
  };
  [UserEvent.InviteModalSubmitted]: InvitationRequestData;
  [UserEvent.TimesheetInviteModalSubmitted]: InvitationRequestData;
  [UserEvent.TimesheetSubmitted]:
    | PaymentCycleSummary
    | PaymentCycleAdminSummary;
  [UserEvent.TeamPulseSurveyStarted]: {
    teamPulseId: TeamPulseId;
    teamPulseSurveyId: TeamPulseSurveyId;
    mid: string;
  };
  [UserEvent.TeamPulseSurveySubmitted]: {
    teamPulseId: TeamPulseId;
    teamPulseSurveyId: TeamPulseSurveyId;
    mid: string;
    overallRating: number;
  };
  [UserEvent.ProfileWebsiteLinkClicked]: {
    uid: UserId;
    url: string;
    source: ProfileUpdateSource;
    mid?: MissionId;
    rid?: MissionRoleId;
    aid?: MissionApplicationId;
  };
  [UserEvent.ProfileRoleChanged]: {
    source: ProfileUpdateSource;
    mid?: MissionId;
    rid?: MissionRoleId;
    aid?: MissionApplicationId;
  };
  [UserEvent.ProfileEditButtonClicked]: {};
  [UserEvent.ProfileSaveButtonClicked]: {};
  [UserEvent.ProfileYearsExperienceChanged]: {
    source: ProfileUpdateSource;
    years: number;
    mid?: MissionId;
    rid?: MissionRoleId;
    aid?: MissionApplicationId;
  };
  [UserEvent.ProfileImageEditClicked]: {
    source: ProfileUpdateSource;
    mid?: MissionId;
    rid?: MissionRoleId;
    aid?: MissionApplicationId;
  };
  [UserEvent.ProfileRolesAddButtonClicked]: {
    source: ProfileUpdateSource;
    mid?: MissionId;
    rid?: MissionRoleId;
    aid?: MissionApplicationId;
  };
  [UserEvent.ProfileSkillsEditButtonClick]: {
    source: ProfileUpdateSource;
    mid?: MissionId;
    rid?: MissionRoleId;
    aid?: MissionApplicationId;
  };
  [UserEvent.ProfileIndustriesAddButtonClicked]: {
    source: ProfileUpdateSource;
    mid?: MissionId;
    rid?: MissionRoleId;
    aid?: MissionApplicationId;
  };
  [UserEvent.ProfileJobsAddButtonClicked]: {
    source: ProfileUpdateSource;
    mid?: MissionId;
    rid?: MissionRoleId;
    aid?: MissionApplicationId;
  };
  [UserEvent.ProfileProjectsAddButtonClicked]: {
    source: ProfileUpdateSource;
    mid?: MissionId;
    rid?: MissionRoleId;
    aid?: MissionApplicationId;
  };
  [UserEvent.ProfileProjectsEditButtonClicked]: {
    eid: string;
  };
  [UserEvent.ProfileConnectButtonClicked]: {
    uid: UserId;
    connect: boolean;
  };
  [UserEvent.ProfileOptionsMenuClicked]: {
    uid: UserId;
  };
  [UserEvent.AvailabilitySettingsUpdated]: {
    uid?: UserId;
    source: AvailabilityUpdateSource;
    type: AvailableType;
    availableFrom?: DateISOString;
    reminderPeriod?: ReminderPeriod;
    weeklyHoursAvailable?: number;
  };
  [UserEvent.RegistrationStepViewed]: {
    uid?: UserId;
    step: string;
    flow: RegistrationFlow;
  };
  [UserEvent.RegistrationStepSubmitted]: {
    uid?: UserId;
    step: string;
    flow: RegistrationFlow;
  };
  [UserEvent.RegistrationBackButtonClicked]: {
    uid?: UserId;
    step: string;
    flow: RegistrationFlow;
  };
  [UserEvent.RegistrationVideoPlayClicked]: {
    uid?: UserId;
    step: string;
    flow: RegistrationFlow;
    videoUrl: string;
  };
  [UserEvent.RegistrationComplete]: {
    uid?: UserId;
    step: string;
    flow: RegistrationFlow;
  };
  [UserEvent.CustomAgreementUploadClicked]: {
    mid?: MissionId;
    uid?: UserId;
  };
  [UserEvent.CustomAgreementUploadStarted]: {
    mid?: MissionId;
    uid?: UserId;
  };
  [UserEvent.CustomAgreementUploadEnded]: {
    mid?: MissionId;
    uid?: UserId;
  };
  [UserEvent.ContractViewed]: {
    type: ContractType;
    custom: boolean;
    mid?: MissionId;
    uid?: UserId;
  };
  [UserEvent.ContractDeleted]: {
    mid: MissionId;
    type: ContractType;
    uid?: UserId;
  };
  [UserEvent.ViewAllUserConnectionsClicked]: {
    userViewerId: UserId;
    userViewedId: UserId;
  };
  [UserEvent.ViewAllUserConnectionsViewProfileClicked]: {
    userViewerId: UserId;
    userViewedId: UserId;
  };
  [UserEvent.ConnectionRequest]: {
    initiatorId: UserId;
    requestedUserId: UserId;
    connectionType: ConnectionType;
    hasCustomData: boolean;
    source: ConnectionsModalSources;
  };
  [UserEvent.ApproveConnectionRequest]: {
    initiatorId: UserId;
    requestedUserId: UserId;
    connectionType: ConnectionType;
    hasCustomData: boolean;
    source: ConnectionsModalSources;
  };
  [UserEvent.RejectConnectionRequest]: {
    initiatorId: UserId;
    requestedUserId: UserId;
    connectionType: ConnectionType;
    source: ConnectionsModalSources;
  };
  [UserEvent.CreateNewJobProjectClickedOnConnectionsModal]: {
    userId: UserId;
    source: ConnectionsModalSources;
  };
  [UserEvent.ConnectionModalOpened]: {
    userId: UserId;
    source: ConnectionsModalSources;
  };
  [UserEvent.TargeterSearchClicked]: {
    mid: string;
    missionTitle: string;
    missionStatus: MissionStatus;
  };
  [UserEvent.TargeterTeamSearchClicked]: {
    mid: string;
    missionTitle: string;
    missionStatus: MissionStatus;
  };
  [UserEvent.TargeterReviewClicked]: {
    mid: string;
    missionTitle: string;
    missionStatus: MissionStatus;
  };
  [UserEvent.TargeterOutreachClicked]: {
    mid: string;
    missionTitle: string;
    missionStatus: MissionStatus;
  };
  [UserEvent.SuggestedTeamsTargeterTabClicked]: {
    tabsCount: number;
  };
  [UserEvent.SuggestedTeamsRunQueryClicked]: {
    tabsCount: number;
    buildersCount: number;
    allowPartialTeams: boolean;
  };
  [UserEvent.TargeterComposeEmailClicked]: {
    tabsCount: number;
  };
  [UserEvent.TargeterPreviewEmailClicked]: {
    mid: string;
    missionTitle: string;
    missionStatus: string;
    emailCategory: string;
    emailType: string;
    roleIds: string;
  };
  [UserEvent.TargeterSendEmailClicked]: {
    mid: string;
    missionTitle: string;
    missionStatus: string;
    emailCategory: string;
    emailType: string;
    roleIds: string;
  };
  [UserEvent.TargeterBuilderCardClicked]: {
    cardUserId: UserId;
    cardUsername: string;
    rankingPosition: number;
    totalResults: number;
    url: string;
  };
  [UserEvent.TargeterResultPageChanged]: {
    originalPage: number;
    targetPage: number;
  };
  [UserEvent.ProfileSuggestionActed]: {
    userId: UserId;
    suggestionId: string;
    action: SuggestionActionType;
    type: BuilderSuggestionType;
    changes?: string[];
  };
  [UserEvent.ProfileSuggestionsRetriggered]: {
    userId: UserId;
  };
  [UserEvent.StartWritingProjectDescriptionClicked]: {
    userId: UserId;
  };
  [UserEvent.ProjectDescriptionInsertSuggestionClicked]: {
    userId: UserId;
    suggestion: string;
  };
  [UserEvent.ProjectDescriptionDiscardSuggestionClicked]: {
    userId: UserId;
    suggestion: string;
  };
  [UserEvent.PitchStartWritingClicked]: {
    userId: UserId;
  };
  [UserEvent.PitchRewriteClicked]: {
    userId: UserId;
  };
  [UserEvent.PitchInsertSuggestionClicked]: {
    userId: UserId;
    suggestion: string;
  };
  [UserEvent.PitchDiscardSuggestionClicked]: {
    userId: UserId;
    suggestion: string;
  };
  [UserEvent.ProfileSuggestionsGenerated]: {
    userId: UserId;
  };
  [UserEvent.QuestionRecommendationGenerated]: {
    userId: UserId;
    missionId: MissionId;
    roleId: MissionRoleId;
    questionId: MissionRoleQuestionId;
  };
  [UserEvent.ConnectCalendarClicked]: {
    userId: UserId;
  };
  [UserEvent.ConnectGoogleCalendarClicked]: {
    userId: UserId;
  };
  [UserEvent.ConnectAppleCalendarClicked]: {
    userId: UserId;
  };
  [UserEvent.ConnectOutlookCalendarClicked]: {
    userId: UserId;
  };
  [UserEvent.CantConnectCalendarClicked]: {
    userId: UserId;
  };
  [UserEvent.NonSupportedCalendarProvided]: {
    userId: UserId;
    calendarName: string;
  };
  [UserEvent.MissionConnectCalendarClick]: {
    userId: UserId;
  };
  [UserEvent.AddCustomCalendarClicked]: {
    userId: UserId;
  };
  [UserEvent.MissionAddCustomCalendarClicked]: {
    userId: UserId;
  };
  [UserEvent.ManageCalendarClicked]: {
    userId: UserId;
  };
  [UserEvent.UpdateCustomCalendarLinkClicked]: {
    userId: UserId;
  };
  [UserEvent.ManageWorkingHoursClicked]: {
    userId: UserId;
  };
  [UserEvent.ConnectCalendarInsteadClicked]: {
    userId: UserId;
  };
  [UserEvent.CustomCalendarAdded]: {
    userId: UserId;
    mainReason: NoCalendarReason;
    calendarLink: string;
    calendarType?: OtherCalendarType;
    calendarName?: string;
    reasonText?: string;
  };
  [UserEvent.ApplicationStarted]: {
    userId: UserId;
    missionId: MissionId;
    roleId: MissionRoleId;
  };
  [UserEvent.ApplicationCompleted]: {
    userId: UserId;
    missionId: MissionId;
    roleId: MissionRoleId;
  };
  [UserEvent.MoreAvailableTimesClicked]: {
    vettingType: VettingType;
    eventName: string;
  };
  [UserEvent.EvaluationCallBooked]: {
    eventName: string;
    userId: UserId;
  };
  [UserEvent.ProfileSubmittedForReview]: {
    userId: UserId;
  };
  [UserEvent.ProfileCompleted]: {
    userId: UserId;
  };
}

export enum PageCategory {
  Page = 'Page',
}

/** Page names */
export enum Page {
  AirtableView = 'AirtableView',
  PrivacyPolicyView = 'PrivacyPolicyView',
  TargeterView = 'TargeterView',
  EmailTemplatesView = 'EmailTemplatesView',
  SignatureCreatorView = 'SignatureCreatorView',
  DiscoveryView = 'DiscoveryView',
  SearchResultsView = 'SearchResultsView',
  TeamFormationView = 'TeamFormationView',
  MyNetworkView = 'MyNetworkView',
  AdminTeamWorkView = 'AdminTeamWorkView',
  TeamWorkView = 'TeamWorkView',
  EditTeamWorkView = 'EditTeamWorkView',
  MissionControlView = 'MissionControlView',
  MissionAdminPageView = 'MissionAdminPageView',
  MissionPageView = 'MissionPageView',
  MissionProposalView = 'MissionProposalView',
  AboutMissionView = 'AboutMissionView',
  MissionFAQView = 'MissionFAQView',
  MissionTimeTrackingView = 'MissionTimeTrackingView',
  MissionDocumentsView = 'MissionDocumentsView',
  ClientDashboardView = 'ClientDashboardView',
  CompanyRegistrationTeamRequestView = 'CompanyRegistrationTeamRequestView',
  CompanyRegistrationSignUpView = 'CompanyRegistrationSignUpView',
  RegistrationView = 'RegistrationView',
  RegistrationPersonalInfoView = 'RegistrationPersonalInfoView',
  RegistrationSocialExperienceView = 'RegistrationSocialExperienceView',
  RegistrationQuestionnaireView = 'RegistrationQuestionnaireView',
  UpdatePasswordView = 'UpdatePasswordView',
  ResetPasswordView = 'ResetPasswordView',
  SignIn = 'SignIn',
  SignOut = 'SignOut',
  TeamMarketingView = 'TeamMarketingView',
  TeamEditView = 'TeamEditView',
  TeamPageView = 'TeamPageView',
  TeamExperiencesViewExperienceView = 'TeamExperiencesViewExperienceView',
  TeamExperiencesEditExperienceView = 'TeamExperiencesEditExperienceView',
  TeamServicesEditServiceView = 'TeamServicesEditServiceView',
  ProfileView = 'ProfileView',
  ProfileEditAppearanceView = 'ProfileEditAppearanceView',
  ProfileEditQuestionnaireView = 'ProfileEditQuestionnaireView',
  ProfileEditPersonalInfoView = 'ProfileEditPersonalInfoView',
  ProfileEditExperienceView = 'ProfileEditExperienceView',
  ProfileEditSettingsView = 'ProfileEditSettingsView',
  ProfileServicesEditServiceView = 'ProfileServicesEditServiceView',
  ProfileExperiencesEditExperienceView = 'ProfileExperiencesEditExperienceView',
  ProfileExperiencesViewExperienceView = 'ProfileExperiencesViewExperienceView',
  OAuthView = 'OAuthView',
  AdminSearchView = 'AdminSearchView',
  InvoicePdfView = 'InvoicePdfView',
  InvoiceDashboardView = 'InvoiceDashboardView',
  TeamPulseDashboardView = 'TeamPulseDashboardView',
  NotFound = 'NotFound',
  AdminAboutMissionView = 'AdminAboutMissionView',
  AdminMissionDocumentsView = 'AdminMissionDocumentsView',
  AdminMissionPageView = 'AdminMissionPageView',
  AdminMissionFAQView = 'AdminMissionFAQView',
  MissionApplyView = 'MissionApplyView',
  VettingDashboardView = 'VettingDashboardView',
  VettingFeedbackFormViewModeView = 'VettingFeedbackFormViewModeView',
  VettingFeedbackFormView = 'VettingFeedbackFormView',
  MissionTeamPulseView = 'MissionTeamPulseView',
  NotificationPreferences = 'NotificationPreferences',
  EvaluationSettings = 'EvaluationSettings',
  Settings = 'Settings',
  ProfileImage = 'ProfileImage',
  MissionAdminNotificationWizardView = 'MissionAdminNotificationWizardView',
  AdminMissionTeamPulseView = 'AdminMissionTeamPulseView',
  AdminMissionCreateTeamPulseView = 'AdminMissionCreateTeamPulseView',
  AdminMissionNotificationsView = 'AdminMissionNotificationsView',
  GuideView = 'GuideView',
  TokensView = 'TokensView',
  PreVettingFormView = 'PreVettingFormView',
  PreVettingScheduleView = 'PreVettingScheduleView',
  PostEvaluationSurveyView = 'PostEvaluationSurveyView',
  NonVerifiedGuideView = 'NonVerifiedGuideView',
  AStore = 'AStore',
  CommunityView = 'CommunityView',
  ArchitectsView = 'ArchitectsView',
  RejectionView = 'RejectionView',
  SelectionTeamRecordingView = 'SelectionTeamRecordingView',
  TranscriptView = 'TranscriptView',
  InviteView = 'InviteView',
  SetupView = 'SetupView',
  EmailUnsubscribe = 'EmailUnsubscribe',
}

/** Map from page names to page properties */
export interface Pages {
  [Page.AirtableView]: {};
  [Page.PrivacyPolicyView]: {};
  [Page.TargeterView]: {};
  [Page.EmailTemplatesView]: {};
  [Page.SignatureCreatorView]: {};
  [Page.DiscoveryView]: {};
  [Page.SearchResultsView]: {};
  [Page.TeamFormationView]: {};
  [Page.MyNetworkView]: {};
  [Page.AdminTeamWorkView]: {};
  [Page.TeamWorkView]: {};
  [Page.EditTeamWorkView]: {};
  [Page.MissionControlView]: { tab: string };
  [Page.MissionAdminPageView]: {};
  [Page.MissionPageView]: {};
  [Page.MissionProposalView]: {};
  [Page.AboutMissionView]: {};
  [Page.MissionFAQView]: {};
  [Page.MissionTimeTrackingView]: {};
  [Page.MissionDocumentsView]: {};
  [Page.ClientDashboardView]: {};
  [Page.CompanyRegistrationTeamRequestView]: {};
  [Page.CompanyRegistrationSignUpView]: {};
  [Page.RegistrationView]: {};
  [Page.RegistrationPersonalInfoView]: {};
  [Page.RegistrationSocialExperienceView]: {};
  [Page.RegistrationQuestionnaireView]: {};
  [Page.UpdatePasswordView]: {};
  [Page.ResetPasswordView]: {};
  [Page.SignIn]: {};
  [Page.SignOut]: {};
  [Page.TeamMarketingView]: {};
  [Page.TeamEditView]: {};
  [Page.TeamPageView]: {};
  [Page.TeamExperiencesViewExperienceView]: {};
  [Page.TeamExperiencesEditExperienceView]: {};
  [Page.TeamServicesEditServiceView]: {};
  [Page.ProfileView]: {};
  [Page.ProfileEditAppearanceView]: {};
  [Page.ProfileEditQuestionnaireView]: {};
  [Page.ProfileEditPersonalInfoView]: {};
  [Page.ProfileEditExperienceView]: {};
  [Page.ProfileEditSettingsView]: {};
  [Page.ProfileServicesEditServiceView]: {};
  [Page.ProfileExperiencesEditExperienceView]: {};
  [Page.ProfileExperiencesViewExperienceView]: {};
  [Page.OAuthView]: {};
  [Page.AdminSearchView]: {};
  [Page.InvoicePdfView]: {};
  [Page.NotFound]: {};
  [Page.AdminAboutMissionView]: {};
  [Page.AdminMissionDocumentsView]: {};
  [Page.AdminMissionPageView]: {};
  [Page.AdminMissionFAQView]: {};
  [Page.VettingDashboardView]: {};
  [Page.VettingFeedbackFormViewModeView]: {};
  [Page.VettingFeedbackFormView]: {};
  [Page.MissionApplyView]: { mid: string; rid: string };
  [Page.MissionTeamPulseView]: { mid: string };
  [Page.AdminMissionTeamPulseView]: { mid: string };
  [Page.AdminMissionCreateTeamPulseView]: {
    mid: string;
  };
  [Page.AdminMissionNotificationsView]: { mid: string };
  [Page.NotificationPreferences]: {};
  [Page.EvaluationSettings]: {};
  [Page.Settings]: {};
  [Page.MissionAdminNotificationWizardView]: {};
  [Page.GuideView]: {};
  [Page.NonVerifiedGuideView]: {};
  [Page.TokensView]: {};
  [Page.InvoiceDashboardView]: {};
  [Page.TeamPulseDashboardView]: {};
  [Page.PreVettingFormView]: {};
  [Page.PreVettingScheduleView]: {};
  [Page.PostEvaluationSurveyView]: {};
  [Page.AStore]: {};
  [Page.CommunityView]: {};
  [Page.ArchitectsView]: {};
  [Page.RejectionView]: {};
  [Page.ProfileImage]: {};
  [Page.SelectionTeamRecordingView]: {};
  [Page.TranscriptView]: {};
  [Page.InviteView]: {};
  [Page.SetupView]: {};
  [Page.EmailUnsubscribe]: {};
}

/** Props all pages should have */
export interface PageProps {}
