import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';

export default createUseStyles({
  root: {
    background: 'none',
    border: 0,
    margin: 0,
    padding: '32px 12px',
    textDecoration: 'none',
    color: '#222',
    cursor: 'pointer',
    borderBottom: 'solid 2px transparent',
    transition: 'all 0.5s',

    '&.active': {
      borderBottom: 'solid 2px #fe630c',
    },
    '&.disabled': {
      opacity: 0.3,
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      padding: 32,
    },
  },
});
