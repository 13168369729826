import {
  EvaluationInterviewWaitingScheduleNotificationObject,
  NotificationType,
} from '@a_team/models/dist/NotificationObject';
import React from 'react';
import Notification from './Notification';
import aTeamLogo from '@src/assets/svgs/ateam-logo.svg';

interface EvaluationInterviewWaitingScheduleNotificationProps {
  notification: EvaluationInterviewWaitingScheduleNotificationObject;
  onViewRequest: () => void;
}

const EvaluationInterviewWaitingScheduleNotification = ({
  onViewRequest,
  notification,
}: EvaluationInterviewWaitingScheduleNotificationProps) => {
  return (
    <>
      <Notification
        createdAt={notification.createdAt}
        notificationType={NotificationType.EvaluationInterviewWaitingSchedule}
        ctaName="Schedule evaluation call"
        ctaAction={() => {
          onViewRequest();
        }}
        title="Schedule your A.Team evaluation call!"
        description={
          <>
            This is a <b>mandatory</b> step before we can consider you for
            missions.
          </>
        }
        imageUrl={aTeamLogo}
        isRead={false}
      />
    </>
  );
};

export default EvaluationInterviewWaitingScheduleNotification;
