import React, { ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode;
  onClick?(): void;
}

const useStyles = createUseStyles({
  root: {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#fe630c',
    textDecoration: 'none',
    background: 'none',
    marginLeft: '1em',
    border: 'none',
    padding: 0,
    margin: 0,
    display: 'inline-block',
    cursor: 'pointer',
  },
});

export default function HeadingButton(props: Props): ReactElement {
  const { children, onClick } = props;
  const styles = useStyles();

  return (
    <button className={styles.root} onClick={onClick}>
      {children}
    </button>
  );
}
