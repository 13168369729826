import React, { useEffect, useMemo, useState } from 'react';
import useLoadingState from '@src/hooks/useLoadingState';
import { AsyncSelect, AsyncSelectProps } from '@ateams/components';
import { apiTalentCategories } from '@ateams/api';
import {
  TalentCategory,
  TalentCategoryId,
} from '@a_team/models/dist/TalentCategories';
import { useStores } from '@src/stores';

export interface TalentCategorySelectorProps<IsMulti extends boolean = false>
  extends Omit<
    AsyncSelectProps<IsMulti, TalentCategoryOptionType>,
    'loadOptions' | 'onChange'
  > {
  isMulti?: boolean;
  defaultValue?: IsMulti extends true
    ? TalentCategoryId[] | TalentCategoryOptionType[]
    : TalentCategoryId | TalentCategoryOptionType;
  onChange?: OnTalentCategorySelectorChange<IsMulti>;
  isVettingEligible?: boolean;
}

export type OnTalentCategorySelectorChange<IsMulti extends boolean = false> = (
  option:
    | (IsMulti extends true
        ? TalentCategoryOptionType[]
        : TalentCategoryOptionType)
    | null,
) => void;

export interface TalentCategoryOptionType {
  value: string;
  label: string;
  talentCategory: TalentCategory;
}

export function talentCategoryToOption(
  talentCategory: TalentCategory,
): TalentCategoryOptionType {
  return {
    value: talentCategory.id,
    label: talentCategory.name,
    talentCategory,
  };
}

export const TalentCategorySelector = <
  T extends TalentCategorySelectorProps<
    T['isMulti'] extends true ? true : false
  >,
>(
  props: T,
) => {
  const {
    defaultValue,
    isDisabled,
    onChange,
    isMulti = false,
    isVettingEligible,
    ...rest
  } = props;
  const { auth } = useStores();
  const [loadingDefault, setLoadingDefault] = useLoadingState();
  const [talentCategory, setTalentCategory] = useState<
    TalentCategoryOptionType[] | TalentCategoryOptionType | null
  >();

  const setDefault = async () => {
    if (defaultValue) {
      try {
        setLoadingDefault(
          (async () => {
            if (Array.isArray(defaultValue)) {
              if (typeof defaultValue?.[0] === 'string') {
                const talentCategories =
                  await apiTalentCategories.queryTalentCategories(auth, {
                    ids: defaultValue.join(','),
                  });

                setTalentCategory(
                  talentCategories.items.map(talentCategoryToOption),
                );
              } else {
                setTalentCategory(defaultValue as TalentCategoryOptionType[]);
              }
            } else {
              if (typeof defaultValue === 'string') {
                const talentCategory =
                  await apiTalentCategories.getTalentCategoryById(
                    defaultValue as string,
                  );

                setTalentCategory(talentCategoryToOption(talentCategory));
              } else {
                setTalentCategory(defaultValue as TalentCategoryOptionType);
              }
            }
          })(),
          false,
        );
      } catch (err) {
        console.error(err, 'Failed to find talent Category by id');
      }
    }
  };

  useEffect(() => {
    setDefault();
  }, []);

  const value = useMemo(
    () => props.value || talentCategory,
    [props.value, talentCategory],
  );

  const onTalentCategoryChange: AsyncSelectProps<
    T['isMulti'] extends true ? true : false,
    TalentCategoryOptionType
  >['onChange'] = async (option) => {
    setTalentCategory(option);
    onChange && onChange(option);
  };

  const loadOptions = async (searchTerm: string) => {
    const talentCategories = await apiTalentCategories.queryTalentCategories(
      auth,
      {
        searchTerm,
        isVettingEligible,
      },
    );

    return talentCategories.items.map(talentCategoryToOption);
  };

  const onRemoveOption =
    props.onRemoveOption || isMulti
      ? (option: TalentCategoryOptionType) => {
          if (!value) {
            return;
          }

          const newTalentCategories = (
            value as TalentCategoryOptionType[]
          ).filter(({ value }) => value !== option.value);

          setTalentCategory(newTalentCategories);
          onChange &&
            onChange(
              newTalentCategories as (
                T['isMulti'] extends true ? true : false
              ) extends true
                ? TalentCategoryOptionType[]
                : TalentCategoryOptionType,
            );
        }
      : undefined;

  return (
    <AsyncSelect<
      T['isMulti'] extends true ? true : false,
      TalentCategoryOptionType
    >
      placeholder={'Select Category...'}
      loadOptions={loadOptions}
      onChange={onTalentCategoryChange}
      value={value}
      useDebounce
      isClearable
      isDisabled={isDisabled || loadingDefault}
      isLoading={loadingDefault}
      onRemoveOption={onRemoveOption}
      isMulti={isMulti}
      {...rest}
    />
  );
};
