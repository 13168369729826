import { UserCardObject } from '@a_team/models/dist/UserObject';
import { Modal } from '@a_team/ui-components';
import ProfilePictures from '@src/components/Modal/ConnectModalV2/partials/ProfilePictures';
import { ConnectModalScreens } from '@src/components/Modal/ConnectModalV2/types';
import AuthStore from '@src/stores/Auth';
import Profile from '@src/stores/Profile/Profile';
import React from 'react';
import { createUseStyles } from 'react-jss';
import MutualUser from './MutualUser';

interface MutualConnectionsModalProps {
  onClose: () => void;
  isOpen: boolean;
  profile: Profile;
  auth: AuthStore;
  mutualConnections: UserCardObject[];
}

const useStyles = createUseStyles<{
  isOpen: boolean;
}>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '40px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    margin: 0,
  },
  mutualUsersContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
  },
  userConnectionAvatarNameContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '23px',
  },
  modal: {
    '&&': {
      width: '100%',
      maxWidth: '100%',
    },
    // select only the second div inside the modal
    '& > div': {
      padding: '24px 8px !important',
    },
  },
  [`@media (min-width: 1024px)`]: {
    title: {
      fontSize: '20px',
      fontWeight: 600,
      margin: 0,
    },
    modal: {
      '&&': {
        width: '680px !important',
        maxHeight: '650px !important',
        maxWidth: '680px',
      },
      '& > div': {
        padding: '40px !important',
      },
      '& > div:last-of-type': {
        display: 'none',
      },
    },
  },
});

const MutualConnectionsModal = ({
  isOpen,
  onClose,
  profile,
  auth,
  mutualConnections,
}: MutualConnectionsModalProps): JSX.Element => {
  const styles = useStyles({
    isOpen,
  });

  return (
    <Modal
      variant="slideUp"
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
    >
      <div className={styles.container}>
        <div className={styles.userConnectionAvatarNameContainer}>
          <div>
            {auth.currentUser?.profilePictureURL && (
              <ProfilePictures
                screen={ConnectModalScreens.ConnectionConfirmed}
                currentUserProfilePictureURL={profile.data.profilePictureURL}
                userToConnectProfilePictureURL={
                  auth.currentUser?.profilePictureURL
                }
              />
            )}
          </div>
          <h4 className={styles.title}>
            Mutual connections with {profile.data.firstName}
          </h4>
        </div>
        <div className={styles.mutualUsersContainer}>
          {mutualConnections.map((user) => (
            <MutualUser user={user} />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default MutualConnectionsModal;
