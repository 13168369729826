import React, { ReactElement } from 'react';
import { Icon, IconType } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import { TeammateCardObject, UserId } from '@a_team/models/dist/UserObject';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';

interface Props {
  card: TeammateCardObject;
  direction: 'prev' | 'next';
  onClick: (uid: UserId) => void;
}

const useStyles = createUseStyles({
  container: (props: Props) => ({
    display: 'flex',
    cursor: 'pointer',
    textAlign: props.direction === 'prev' ? 'left' : 'right',
    position: 'absolute',
    height: '100%',
    top: 0,
    alignItems: 'center',
    ...(props.direction === 'prev'
      ? {
          left: 0,
        }
      : {
          right: 0,
          flexDirection: 'row-reverse',
        }),
  }),
  arrow: {
    background: '#EFEFF0',
    height: '100%',
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  name: {
    display: 'none',
  },
  role: {
    display: 'none',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    name: {
      display: 'block',
      fontSize: 15,
      fontWeight: 500,
    },
    role: {
      display: 'block',
      fontSize: 14,
      color: '#62646A',
      fontWeight: 400,
    },
  },
});

export const CardNavigationButton = (props: Props): ReactElement => {
  const { card, direction, onClick } = props;
  const styles = useStyles(props);

  return (
    <div className={styles.container} onClick={() => onClick(card.uid)}>
      <div className={styles.arrow}>
        <Icon
          style={{ cursor: 'pointer' }}
          type={IconType.Next}
          muted
          flip={direction === 'prev' ? 'horizontal' : undefined}
          size={'exact'}
        />
      </div>
      <UserAvatar
        src={card.profilePictureURL}
        size={40}
        containerStyle={{ margin: '0 16px' }}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span className={styles.name}>{card.fullName}</span>{' '}
        <span className={styles.role}>{card.roleCategory?.title}</span>
      </div>
    </div>
  );
};
