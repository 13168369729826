import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import {
  BorderRadius,
  Breakpoints,
  Button as CallToActionButton,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from '@ateams/components';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import UserObject from '@a_team/models/dist/UserObject';
import { ChangePasswordData } from '@ateams/api/dist/endpoints/User';
import useLoadingState from '@src/hooks/useLoadingState';
import { useStores } from '@src/stores';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { createUseStyles } from 'react-jss';
import { TextColors } from '@ateams/components';
import { ZxcvbnResult } from '@zxcvbn-ts/core';
import { useStrongPassword } from '@src/hooks/useStrongPassword';
import PasswordStrengthIndicator from '@src/components/PasswordStrenghtIndicator';
import { MIN_SCORE } from '@src/helpers/auth';
import { useHistory } from 'react-router-dom';

interface Props {
  profile: UserObject;
}

const useStyles = createUseStyles({
  title: {
    fontSize: FontSizes.extraLarge,
    fontWeight: FontWeights.semiBold,
  },
  subTitle: {
    fontSize: FontSizes.regular,
    marginTop: Spacing.small,
  },
  labelBold: {
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.medium,
    paddingTop: Spacing.xxxLarge,
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
  },
  label: {
    fontSize: FontSizes.regular,
    paddingTop: Spacing.medium,
  },
  outlinedInput: {
    margin: 0,
    padding: 0,
  },
  outlinedInputInternalInput: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  outlinedInputInternalControl: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 12,
    paddingRight: 12,
    minHeight: 40,
    width: '100%',
  },
  contactSupport: {
    color: Colors.secondaryDark,
    textDecoration: 'none',
  },
  submitContainer: {
    paddingTop: Spacing.xxxLarge,
    paddingBottom: Spacing.xLarge,
  },
  button: {
    width: 140,
    height: 40,
    background: Colors.secondaryDark,
    borderRadius: BorderRadius.medium,
    color: `${TextColors.primary} !important`,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    outlinedInputInternalControl: {
      width: 400,
    },
  },
});

const AccountSettings = (props: Props): ReactElement => {
  const { profile } = props;
  const { auth } = useStores();
  const styles = useStyles();
  const history = useHistory();

  const [data, setData] = useState<ChangePasswordData>({
    oldPassword: '',
    password: '',
  });

  const [passWordStrength, setPassWordStrength] = useState<ZxcvbnResult | null>(
    null,
  );

  const { checkPasswordStrength } = useStrongPassword();

  const [loading, setLoading, error] = useLoadingState();

  const isValid =
    loading !== true &&
    data.oldPassword &&
    data.password &&
    (!passWordStrength?.feedback.suggestions.length ||
      passWordStrength.score >= MIN_SCORE);

  const onCheckPasswordStrength = async () => {
    if (!data.password) {
      setPassWordStrength(null);
      return;
    }
    const result = await checkPasswordStrength(data.password);
    setPassWordStrength(result);
  };

  useEffect(() => {
    onCheckPasswordStrength();
  }, [data.password]);

  const handleInputChange =
    (name: keyof ChangePasswordData) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setData({ ...data, [name]: e.target.value });
    };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    if (!isValid) return;
    setLoading(
      auth.changePassword(data).then(() => {
        setData({ oldPassword: '', password: '' });
        history.push('/sign-in');
      }),
      'Password was updated!',
    );
  };

  return (
    <>
      <div className={styles.title}>Account</div>
      <div className={styles.subTitle}>Manage your profile settings.</div>
      <div>
        <div className={styles.fieldContainer}>
          <div className={styles.labelBold}>Username</div>
          <div>Your username shows up in the URL of your profile.</div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.label}>Username</div>
          <OutlinedInput
            disabled
            value={`platform.a.team/${profile.username}`}
            className={styles.outlinedInput}
            inputClassName={styles.outlinedInputInternalInput}
            controlClassName={styles.outlinedInputInternalControl}
          />
          <div>
            <a href={'mailto:support@a.team'} className={styles.contactSupport}>
              Contact support
            </a>{' '}
            to change your username
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.labelBold}>Password</div>
          <div>Set your account password.</div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldContainer}>
            <div className={styles.label}>Current Password</div>
            <OutlinedInput
              type="password"
              placeholder="Old Password..."
              name="oldPassword"
              onChange={handleInputChange('oldPassword')}
              value={data.oldPassword || ''}
              required
              error={!!error}
              className={styles.outlinedInput}
              inputClassName={styles.outlinedInputInternalInput}
              controlClassName={styles.outlinedInputInternalControl}
            />
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.label}>New Password</div>
            <OutlinedInput
              type="password"
              placeholder="New Password..."
              name="password"
              onChange={handleInputChange('password')}
              value={data.password || ''}
              disabled={loading === true}
              required
              error={!!error}
              className={styles.outlinedInput}
              inputClassName={styles.outlinedInputInternalInput}
              controlClassName={styles.outlinedInputInternalControl}
            />
            <div>
              <div
                style={{
                  width: '100%',
                  marginBottom: '0',
                }}
              >
                {passWordStrength?.feedback.suggestions.map((suggestion) => (
                  <small
                    key={suggestion}
                    style={{ color: '#ff0000', display: 'block' }}
                  >
                    {suggestion}
                  </small>
                ))}
              </div>
              {passWordStrength && (
                <PasswordStrengthIndicator
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                  }}
                  score={passWordStrength.score}
                />
              )}
            </div>
            {error && (
              <small style={{ color: '#ff0000' }}>{error.message}</small>
            )}
          </div>
        </div>

        <div className={styles.labelBold}>Email Address</div>
        <p>
          Your email address won’t be shown to other users, it will be used for
          important notifications from the Build Team and Support Team.
        </p>

        <div className={styles.fieldContainer}>
          <div className={styles.label}>Email address</div>
          <OutlinedInput
            disabled
            value={profile.email}
            className={styles.outlinedInput}
            inputClassName={styles.outlinedInputInternalInput}
            controlClassName={styles.outlinedInputInternalControl}
          />
          <div>
            <a href={'mailto:support@a.team'} className={styles.contactSupport}>
              Contact support
            </a>{' '}
            to change your email
          </div>
        </div>
        <div className={styles.submitContainer}>
          <CallToActionButton
            className={styles.button}
            disabled={!isValid}
            style={{
              // give it opacity if it's disabled
              opacity: isValid ? 1 : 0.5,
            }}
            type="submit"
            onClick={handleSubmit}
          >
            Save
          </CallToActionButton>
        </div>
      </div>
      <LoadingIndicator loading={loading} />
    </>
  );
};

export default AccountSettings;
