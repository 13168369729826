import React, { ReactElement } from 'react';
import TimezoneObject from '@a_team/models/dist/TimezoneObject';
import { SidebarSection } from '@src/views/Profile/Sidebar/SidebarSection';
import { getGMTFromMinutesOffset } from '@ateams/react-utils/dist/helpers/timezone';
import { createUseStyles } from 'react-jss';
import { TextColors, TimezoneSelect } from '@ateams/components';

interface Props {
  onChange(data: TimezoneObject): void;
  timezone?: TimezoneObject;
  readonly?: boolean;
  error?: boolean;
  disabled?: boolean;
}

const useStyles = createUseStyles({
  timezone: {},
  control: {
    marginTop: 12,
  },
  valueReadonly: {
    margin: 0,
    fontSize: 17,
    color: TextColors.regular,
  },
});

export const Timezone = (props: Props): ReactElement => {
  const styles = useStyles();
  const { readonly, onChange, timezone, error, disabled } = props;

  return (
    <div className={styles.timezone}>
      <SidebarSection title={'Time Zone'} withAddIcon={false} error={error}>
        {timezone && (
          <div className={styles.control}>
            {readonly ? (
              <p className={styles.valueReadonly}>
                {`${timezone.name} (${getGMTFromMinutesOffset(
                  timezone.utcOffset,
                )}`}
                )
              </p>
            ) : (
              <TimezoneSelect
                margin={'none'}
                isDisabled={disabled}
                value={timezone}
                onChange={onChange}
              />
            )}
          </div>
        )}
      </SidebarSection>
    </div>
  );
};
