import React from 'react';
import { createUseStyles } from 'react-jss';
import noResultsIllustration from './no-results-illustration.svg';
import { Colors } from '@ateams/components';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  title: {
    fontWeight: 500,
  },
  illustration: {
    width: 80,
    height: 'auto',
  },
  btn: {
    // remove default button styles
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    textDecoration: 'none',
    cursor: 'pointer',
    color: Colors.secondaryDark,
  },
});

interface NoResultsProps {
  onResetSearch: () => void;
}

const NoResults = ({ onResetSearch }: NoResultsProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <img
        className={styles.illustration}
        src={noResultsIllustration}
        alt="No Results"
      />

      <div className={styles.title}>No Results</div>
      <div>
        Try searching for a different name or{' '}
        <button className={styles.btn} onClick={onResetSearch}>
          go back to discovery.
        </button>
      </div>
    </div>
  );
};

export default NoResults;
