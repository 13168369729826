import { Select } from '@a_team/ui-components';
import { Colors } from '@ateams/components';
import CompanyLogoFallback from '@src/components/CompanyLogoFallback';
import { isNonNullable } from '@src/helpers/sort';
import { ExistingJob, LocalJobs, NewJob } from '@src/stores/Profile/models';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';

interface RelatedJobProps {
  allJobs: LocalJobs | undefined;
}

const useStyles = createUseStyles({
  container: {
    marginTop: 40,
  },
  label: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  desc: {
    fontSize: '15px',
    fontWeight: 300,
    lineHeight: '24px',
    color: '#62646A',
    textAlign: 'start',
    margin: 0,
  },
  labelDescContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    marginBottom: 12,
  },
  logoImg: {
    width: 40,
    height: 'auto',
    borderRadius: 8,
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    gap: '12px',
    justifyContent: 'flex-start',

    // hover bg
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },

    img: {
      width: 40,
      height: 40,
    },
  },
  placeholderContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  jobDetails: {
    display: 'flex',
    flexDirection: 'row',
    gap: 6,
  },
  jobRole: {
    color: Colors.dark,
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  companyDate: {
    color: Colors.regularDark,
    fontSize: '15px',
    lineHeight: '20px',
  },
});

function isExistingJob(project: ExistingJob | NewJob): project is ExistingJob {
  return 'eid' in project;
}

const RelatedJob = ({ allJobs }: RelatedJobProps): JSX.Element => {
  const styles = useStyles();

  const { register, trigger, setValue, watch } = useFormContext();

  const selectedJob = watch('relatedJobId');

  const values = allJobs
    ?.filter(isExistingJob)
    .filter((job) => job.eid === selectedJob)
    .map((job) => {
      const existingJob = isExistingJob(job);

      if (!existingJob) {
        return null;
      }
      return {
        value: job.eid,
        label: job.companyName || job.companyData?.name,
        logo: job.logoURL || job.companyData?.logoUrl,
      };
    })
    .filter(isNonNullable);

  const jobOptions = allJobs
    ?.map((job) => {
      const existingJob = isExistingJob(job);
      if (!existingJob) {
        return null;
      }
      return { label: job.title, value: job.eid };
    })
    .filter(isNonNullable);

  const existingJobs = allJobs?.filter(isExistingJob);

  const job = existingJobs?.find((job) => job.eid === selectedJob);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.labelDescContainer}>
        <label className={styles.label}>Related job (Optional)</label>
        <span className={styles.desc}>
          Connect a job this project was a part of.
        </span>
      </div>
      <Select
        menuIsOpen={isOpen}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        alwaysShowCounter={true}
        isMulti={true}
        menuPlacement="top"
        styles={{
          control: (base) => ({
            ...base,
            border: '1px solid #DADADC',
          }),
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 999999,
            color: '#000000',
          }),
          indicatorSeparator: (base) => ({
            ...base,
            display: 'none',
          }),
          dropdownIndicator: (base) => ({
            ...base,
            color: '#000000',
            width: 32,
            height: 32,
          }),
          placeholder: (base) => ({
            ...base,
            '& span': {
              display: values?.length === 0 ? 'none' : 'inline',
              backgroundColor: '#6D00D7 !important',
              borderRadius: '8px !important',
            },
          }),
        }}
        {...register('relatedJobId')}
        value={values}
        placeholder={
          selectedJob ? (
            <div className={styles.placeholderContainer}>
              <CompanyLogoFallback
                alt="Company logo"
                logoUrl={job?.logoURL || job?.companyData?.logoUrl}
                borderRadius={8}
                size={24}
              />
              <div className={styles.jobDetails}>
                <div className={styles.jobRole}>{job?.jobRole}</div>
                <div>at {job?.companyName}</div>
                <div className={styles.companyDate}>
                  {job?.startDate && (
                    <>
                      {' '}
                      · {format(new Date(job.startDate), 'MMM yyyy')} -{' '}
                      {job.endDate
                        ? format(new Date(job.endDate), 'MMM yyyy')
                        : 'Present'}
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            'Select a job'
          )
        }
        components={{
          Option: (props) => {
            const data = props.data as { value: string; label: string };

            const foundJob = existingJobs?.find((p) => p.eid === data.value);

            if (!foundJob) {
              return null;
            }

            const companyLogo =
              foundJob.logoURL || foundJob.companyData?.logoUrl;

            return (
              <div {...props.innerProps} className={styles.option}>
                <CompanyLogoFallback
                  className={styles.logoImg}
                  alt="Company logo"
                  logoUrl={companyLogo}
                  borderRadius={8}
                  size={40}
                />

                <div>
                  <label style={{ color: '#222' }}>{foundJob.jobRole}</label>
                  <div style={{ color: '#62646A' }}>
                    {foundJob.companyName || foundJob.companyData?.name}{' '}
                    {foundJob.startDate && (
                      <span>
                        ·{' '}
                        {format(new Date(foundJob.startDate || ''), 'MMM yyyy')}{' '}
                        -{' '}
                        {foundJob.endDate
                          ? format(new Date(foundJob.endDate), 'MMM yyyy')
                          : 'Present'}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          },
        }}
        onChange={(value) => {
          const newValues = value as { value: string; label: string }[];

          const newSelectedProject =
            newValues.length > 0
              ? newValues.find((v) => v.value !== selectedJob)?.value ??
                newValues[0].value
              : null;

          setValue('relatedJobId', newSelectedProject, {
            shouldDirty: true,
          });

          trigger('relatedJobId');
          setIsOpen(false);
        }}
        options={jobOptions}
      />
    </div>
  );
};

export default RelatedJob;
