import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
  },
});

const Availability = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <p>
        <strong>Availability</strong> Make sure you have a very clear
        understanding of (1) how many hours a company will expect you to work
        each week, (2) what hours in a day they expect you to be online and
        available to communicate, and (3) how this might change over time.{' '}
      </p>
    </div>
  );
};

export default Availability;
