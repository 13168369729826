import React, { ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import useStyles from './useStyles';

interface Props {
  onClick(e?: React.MouseEvent<HTMLButtonElement>): void;
  active?: boolean;
  children: ReactNode;
  className?: string;
}

export default function TabButton(props: Props): ReactElement {
  const { children, active, onClick } = props;
  const styles = useStyles();

  return (
    <button
      className={cx(styles.root, props.className, { active })}
      onClick={onClick}
      style={{ minWidth: 100, flexShrink: 0, height: '100%' }}
    >
      {children}
    </button>
  );
}
