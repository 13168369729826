import React, { ReactElement } from 'react';
import RcSlider, { SliderProps as RcSliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import {
  sliderHandleStyles,
  sliderRailStyles,
  sliderTrackStyles,
} from './styles';
import { HandleWithTooltip } from './HandleTooltip';
import { createUseStyles } from 'react-jss';

export interface SliderPropsBase {
  tipFormatter?: (value: number) => string;
  alwaysShowTooltip?: boolean;
}

export type SliderProps = RcSliderProps & SliderPropsBase;

const useStyles = createUseStyles({
  '@global': {
    '.rc-slider-handle:active': {
      boxShadow: `0px 1px 8px rgba(0,0,0,0.25) !important`,
    },
    '.rc-slider-tooltip-arrow': {
      display: 'none',
    },
  },
});

const Slider = (props: SliderProps): ReactElement => {
  useStyles();
  const { tipFormatter, alwaysShowTooltip } = props;

  const defaultTipFormatter = (value: number): string => {
    return `${value}%`;
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <RcSlider
      railStyle={sliderRailStyles}
      trackStyle={sliderTrackStyles}
      handleStyle={sliderHandleStyles}
      handle={(props) => (
        <HandleWithTooltip
          {...props}
          {...{
            shown: alwaysShowTooltip,
            tipFormatter: tipFormatter || defaultTipFormatter,
            ariaValueTextFormatter: tipFormatter || defaultTipFormatter,
          }}
        />
      )}
      {...props}
    />
  );
};

export default Slider;
