import React, { ReactElement } from 'react';
import {
  UserScrubbed,
  UserScrubbedCodes,
  UserType,
} from '@a_team/models/dist/UserObject';
import { createUseStyles } from 'react-jss';

interface Props {
  type: UserType;
  scrubbed?: UserScrubbed;
  isVetted: boolean;
}

const useStyles = createUseStyles({
  root: {
    marginLeft: 4,
    fontWeight: 500,
  },
});

export default function UserSup(props: Props): ReactElement {
  const { scrubbed, type, isVetted } = props;
  const styles = useStyles();

  const getScrubbedCode = () => {
    if (type !== UserType.User) {
      return 'C';
    }

    if (!scrubbed || isVetted) {
      return '';
    }

    return String(UserScrubbedCodes[scrubbed]);
  };

  const scrubbedCode = getScrubbedCode();
  const prettyScrubbedCode = scrubbedCode.length ? `[${scrubbedCode}]` : '';

  return <sup className={styles.root}>{prettyScrubbedCode}</sup>;
}
