import React, { ChangeEvent, ReactElement, useMemo } from 'react';
import { textareaStyling } from '@src/views/Profile/helpers/styles';
import { ProfileTooltip } from '@src/views/Profile/ProfileTooltip';
import InlineInput from '@src/components/Inputs/InlineInput';
import { Tag } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { tagEditedYellowStyle } from '@src/common-styles/tag-edited';

const useStyles = createUseStyles({
  tagEdited: {
    ...tagEditedYellowStyle,
    marginLeft: '8px',
  },
});

interface Props {
  text?: string;
  onChange(text: string): void;
  disabled?: boolean;
  readonly?: boolean;
  error?: boolean;
  isApplicationAvailabilityNotesEdited?: boolean;
}

export const NotesInput = React.memo((props: Props): ReactElement => {
  const {
    disabled,
    text,
    onChange,
    readonly,
    error,
    isApplicationAvailabilityNotesEdited,
  } = props;

  const styles = useStyles(props);

  const tooltipText = useMemo(() => {
    let tooltip;
    !text && (tooltip = 'Tell us details about your availability');
    error && (tooltip = 'Please enter a short description');
    return tooltip;
  }, [error, text]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.value.trimStart());
  };

  return (
    <div style={{ flexGrow: 1, marginTop: 4 }}>
      {readonly ? (
        <p
          style={{
            marginTop: 0,
            wordBreak: 'break-word',
          }}
        >
          {text}
          {isApplicationAvailabilityNotesEdited ? (
            <Tag className={styles.tagEdited}>Edited</Tag>
          ) : null}
        </p>
      ) : (
        <ProfileTooltip title={tooltipText} disabled={!tooltipText}>
          <InlineInput
            multiline
            id="notes"
            fullWidth
            autosize={false}
            disabled={disabled}
            placeholder={
              "Feel free to mention special circumstances, such as being able to ramp up over time in terms of hours per week, or being available for 'x' hours per week normally, but willing to do 'y' hours per week as an exception from time to time."
            }
            minRows={4}
            maxRows={4}
            error={error}
            value={text || ''}
            onChange={handleChange}
            inputStyles={{
              border: 'none',
              marginTop: 0,
              paddingTop: 0,
              marginBottom: 32,
              ...textareaStyling,
            }}
          />
        </ProfileTooltip>
      )}
    </div>
  );
});
