import { ServiceAuth, ServiceEndpoint } from './utils';

export const BasePath = '/builder-login-history';

export default class BuilderLoginHistoryEndpoint extends ServiceEndpoint {
  public getBuilderLoginHistory(
    auth: ServiceAuth,
    uid: string,
  ): Promise<{
    loginHistory: {
      month: string;
      countries: string[];
    }[];
    hasLocationMismatch: boolean;
    linkedinCountry: string | undefined;
  }> {
    return this.fetch(auth, BasePath + `/${uid}`);
  }
}
