import { Breakpoints } from '@ateams/components';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React, { useState, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import Modal from '..';
import Body from './Body';
import Footer from './Footer';
import Head from './Head';

interface CodeOfConductModalProps {
  isOpen: boolean;
  showOnly?: 'mission' | 'platform';
  hideExit?: boolean;
  toggleModal: () => void;
}

const useStyles = createUseStyles<{ showOnly?: 'mission' | 'platform' }>({
  // removes global scrollbar
  '@global': {
    'html, body': {
      overflow: 'hidden',
    },
  },
  modal: ({ showOnly }) => ({
    maxWidth: showOnly ? '700px' : '1120px',
    width: '100% !important',
    padding: '0 !important',
    overflow: 'hidden',
    '&:focus': {
      outline: 'none',
    },
  }),
  container: {
    overflow: 'auto',
    height: '95vh',
    maxHeight: '720px',
    '& h4': {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '26px',
    },
    '& a': {
      color: '#7000E3',
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    padding: '16px',
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    gridColumn: 'span 1',
  },
  head: {
    position: 'unset',
    top: 'unset',
    height: 'fit-content',
  },

  [`@media (min-width: ${Breakpoints.md}px)`]: {
    grid: ({ showOnly }: { showOnly?: 'mission' | 'platform' }) => ({
      gridTemplateColumns: showOnly ? '1fr' : '260px 1fr',
      gap: '80px',
      padding: '40px',
    }),
    head: {
      position: 'sticky',
      top: '40px',
      height: 'fit-content',
    },
    footer: {
      gridColumn: 'span 2',
    },
  },
});

export enum ModalPages {
  PlatformCodeOfConduct,
  MissionCodeOfConduct,
  QuickOnboardingChecklist,
  MissionCodeOfConductChecklist,
}

const CodeOfConductModal = ({
  isOpen,
  showOnly,
  hideExit,
  toggleModal,
}: CodeOfConductModalProps): JSX.Element => {
  const { auth } = useStores();
  const styles = useStyles({ showOnly });

  const [page, setPage] = useState<ModalPages>(() => {
    if (showOnly === 'mission') return ModalPages.MissionCodeOfConduct;
    if (showOnly === 'platform') return ModalPages.PlatformCodeOfConduct;
    return ModalPages.PlatformCodeOfConduct;
  });
  const [
    percentageMissionChecklistCompleted,
    setPercentageMissionChecklistCompleted,
  ] = useState(0);

  const [loading, setLoading] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const [percentageScrolledToBottom, setPercentageScrolledToBottom] =
    useState<number>(0);

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    // check how much of the modal has been scrolled vertically
    const percentageScrolled = Math.round(
      (event.currentTarget.scrollTop /
        (event.currentTarget.scrollHeight -
          event.currentTarget.clientHeight -
          100)) *
        100,
    );

    // update state with percentage scrolled only when it changes
    if (percentageScrolled !== percentageScrolledToBottom) {
      setPercentageScrolledToBottom(percentageScrolled);
    }
  };

  const handleBackButtonClick = () => {
    if (page === ModalPages.MissionCodeOfConductChecklist) {
      setPage(ModalPages.MissionCodeOfConduct);
    } else {
      setPage(ModalPages.PlatformCodeOfConduct);
    }

    ref.current?.scrollTo(0, 0);
  };

  const checkIfUserIsNew = () => {
    if (auth.user?.activatedAt) {
      const date = new Date(auth.user?.activatedAt);
      // date to be changed to when platform CoC is released
      const dateBefore = new Date('2022-10-01');
      return date < dateBefore;
    }
    return false;
  };

  const checkIfUserIsOnMission = () => {
    if (auth.user?.activeMissions) {
      return auth.user.activeMissions.length > 0;
    }
    return false;
  };

  const isNewUser = checkIfUserIsNew();
  const isUserOnMission = checkIfUserIsOnMission();

  const handleAgreePlatformCoC = async () => {
    if (showOnly === 'platform') {
      await handleAgreeChecklist();
      return;
    }
    const page = isUserOnMission
      ? ModalPages.MissionCodeOfConduct
      : ModalPages.QuickOnboardingChecklist;
    setPage(page);
    ref.current?.scrollTo(0, 0);
  };

  const handleNextMissionCoCChecklist = async () => {
    setPage(ModalPages.MissionCodeOfConductChecklist);
  };

  const handleAgreeMissionCoC = async () => {
    try {
      setLoading(true);
      await Promise.all([
        auth.acceptMissionCodeOfConduct(auth),
        showOnly === 'mission' ? null : auth.acceptPlatformCodeOfConduct(auth),
      ]);
      setLoading(false);
      toggleModal();
    } catch (error) {
      setLoading(false);
      toggleModal();
    }
  };

  const handleAgreeChecklist = async () => {
    try {
      setLoading(true);
      await auth.acceptPlatformCodeOfConduct(auth);
      setLoading(false);
      toggleModal();
    } catch (error) {
      setLoading(false);
      toggleModal();
    }
  };

  return (
    <Modal
      // passing empty func to prevent closing as it is a required prop
      onClose={() => {
        return;
      }}
      hideCloseButton
      open={isOpen}
      className={styles.modal}
    >
      <div ref={ref} onScroll={handleOnScroll} className={styles.container}>
        <div className={styles.grid}>
          {!showOnly && (
            <Head
              isNewUser={isNewUser}
              isUserOnMission={isUserOnMission}
              page={page}
              className={styles.head}
            />
          )}
          <Body
            page={page}
            setPercentageMissionChecklistCompleted={
              setPercentageMissionChecklistCompleted
            }
          />
        </div>

        <Footer
          hideExit={hideExit}
          onClose={() => toggleModal()}
          loading={loading}
          handleBackButtonClick={handleBackButtonClick}
          handleAgreePlatformCoC={handleAgreePlatformCoC}
          handleNextMissionCoCChecklist={handleNextMissionCoCChecklist}
          handleAgreeMissionCoC={handleAgreeMissionCoC}
          handleAgreeChecklist={handleAgreeChecklist}
          page={page}
          className={styles.footer}
          showOnly={showOnly}
          percentageScrolledToBottom={percentageScrolledToBottom}
          percentageMissionChecklistCompleted={
            percentageMissionChecklistCompleted
          }
        />
      </div>
    </Modal>
  );
};

export default observer(CodeOfConductModal);
