import { ServiceAuth } from '@ateams/service-utils';
import {
  AdminVettingProcess,
  BasicVettingProcess,
  VettingProcessId,
  VettingType,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import {
  VettingProcessPublicStatus,
  VettingProcessStatus,
} from '@a_team/models/dist/vetting-processes/status';
import {
  VetterNotified,
  VettingProcessEmailTemplates,
} from '@a_team/models/src/vetting-processes/emails';
import { VettingProcessPostEvaluationSurveyScore } from '@a_team/models/src/vetting-processes/post-evaluation-survey';
import { UserBadge, UserId } from '@a_team/models/dist/UserObject';
import {
  TalentCategoryId,
  TalentSkillId,
} from '@a_team/models/src/TalentCategories';
import { ServiceEndpoint } from './utils';
import { DateISOString } from '@a_team/models/src/misc';
import { VetterId, VetterSuggestion } from '@a_team/models/src/vetter';
import { RoleCategoryId } from '@a_team/models/src/RoleCategory';

export const BasePath = '/vetting-process';

export enum CalendarUrlTypes {
  OwnCalendar = 'ownCalendar',
  Random = 'random',
}

export interface VettingEmailTemplateParameters {
  subject: string;
  template: string;
  templateVariables: Record<string, string>;
}

export interface EvaluationInviteEmailTemplateParameters
  extends VettingEmailTemplateParameters {
  calendarUrlType?: CalendarUrlTypes;
  templateType: VettingProcessEmailTemplates;
  sendInThread?: boolean;
}

export interface StartVettingProcessPayload {
  userId: UserId;
  vettingType: VettingType;
  category: TalentCategoryId;
  primaryRoleCategoryId?: RoleCategoryId;
  contactOwner: UserId;
  selectedVetterIds?: VetterId[];
  vettingEmailTemplateParameters?: EvaluationInviteEmailTemplateParameters;
}

export interface RequestEvaluationCallPayload {
  roleId: RoleCategoryId;
}

export interface FetchVettingProcessesFilters {
  user?: UserId;
  vettingType?: VettingType;
  preVetting?: {
    codeSampleProgrammingLanguage?: TalentSkillId;
    hasSubmittedForm?: boolean;
  };
  createdAt?: {
    startDate?: Date;
    endDate?: Date;
  };
  interviewDate?: {
    startDate?: Date;
    endDate?: Date;
  };
  statuses?: VettingProcessStatus[];
  status?: VettingProcessPublicStatus;
  vetter?: VetterId;
  mainRole?: TalentCategoryId;
  badge?: UserBadge;
  isStale?: boolean;
}

export interface CountVettingProcessesQuery {
  filters?: FetchVettingProcessesFilters;
}

export enum OrderingOperators {
  Ascending = 'ascending',
  Descending = 'descending',
}

export enum FetchVettingProcessesSortingKeys {
  FirstReachoutEmail = 'firstReachoutEmail',
  FirstInterviewInviteEmail = 'firstInterviewInviteEmail',
  InterviewDate = 'interviewDate',
  PreVettingFormSubmitDate = 'preVettingFormSubmitDate',
  FeedbackExpertise = 'feedbackExpertise',
  FeedbackInteractionExperience = 'feedbackInteractionExperience',
  FeedbackEnglishLevel = 'feedbackEnglishLevel',
  FeedbackAccent = 'feedbackAccent',
}

export interface FetchVettingProcessesSortingComponent {
  sortingKey: FetchVettingProcessesSortingKeys;
  operator: OrderingOperators;
}
export type FetchVettingProcessesSorting =
  FetchVettingProcessesSortingComponent[];

export interface FetchVettingProcessesQuery extends CountVettingProcessesQuery {
  filters?: FetchVettingProcessesFilters;
  sorting?: FetchVettingProcessesSorting;
  page?: number;
  batchSize?: number;
}

export interface FetchUserVettingProcessesQuery {
  userId: UserId;
}

export interface PatchAdminVettingProcessPayload {
  id: VettingProcessId;
  vetter?: VetterId | null;
  vettingType?: VettingType;
  status?: VettingProcessStatus;
  interviewDate?: DateISOString;
  preVetting?: {
    codeSampleProgrammingLanguage?: TalentSkillId;
    codeSample?: string;
  };
  contactOwner?: UserId | null;
  allowedVetterUserIds?: UserId[];
}

export interface InterviewScheduledPayload {
  calComBookingId: number;
  calComBookingUid: string;
  vetter: VetterId;
  interviewDate?: DateISOString;
}

export interface GetScheduleUrlByOncePayload {
  vetterIds: VetterId[];
}

export interface CancelInterviewFromNoncePayload {
  uid: UserId;
  nonce: string;
  reason: string;
}

export interface CancelInterviewPayload {
  calComBookingUid: string;
  uid: UserId;
  reason: string;
}

export interface CreateInterviewNotificationPayload {
  calComBookingUid: string;
}

export interface AddMeetingRecordingPayload {
  calComDailyMeetingId: string;
  transcriptJobId: string;
}

export interface CheckPostEvaluationSurveySubmitted {
  postEvaluationSurveySubmitted: boolean;
}

export interface SubmitPostEvaluationSurveyPayload {
  vettingProcessId: VettingProcessId;
  experienceWithInterviewer: VettingProcessPostEvaluationSurveyScore;
  likelinessToRecommendATeam: VettingProcessPostEvaluationSurveyScore;
  additionalFeedback?: string;
}

export interface SendEvaluationInvitationPayload {
  id: VettingProcessId;
  vettingType: VettingType;
  category: TalentCategoryId;
  primaryRoleCategoryId?: RoleCategoryId;
  contactOwner: UserId;
  selectedVetterIds?: VetterId[];
  vettingEmailTemplateParameters: EvaluationInviteEmailTemplateParameters;
}

export interface SendInterviewInviteEmailPayload {
  id: VettingProcessId;
  vettingEmailTemplateParameters: VettingEmailTemplateParameters;
}

export interface NotifyVetterOnProcessPayload {
  vettingProcessId: VettingProcessId;
  vettingEmailTemplateParameters: VettingEmailTemplateParameters;
}

export interface AddInterviewerNotifiedRecordPayload {
  vettingProcessId: VettingProcessId;
  vetterId: VetterId;
  date: DateISOString;
}

export interface PatchInterviewerNotifiedRecordPayload {
  vettingProcessId: VettingProcessId;
  vetterNotifiedId: VetterNotified['id'];
  vetterId?: VetterId;
  date?: DateISOString;
}

export interface RemoveInterviewerNotifiedRecordPayload {
  vettingProcessId: VettingProcessId;
  vetterNotifiedId: VetterNotified['id'];
}

export interface SetInterviewDatePayload {
  id: VettingProcessId;
  interviewDate: DateISOString | null;
}

export interface PatchRemoveVettingProcessPayload {
  vettingProcessId: VettingProcessId;
  reason: string;
}

export interface UserOptOutByVetterPayload {
  vettingProcessId: VettingProcessId;
  reason: string;
}

export interface AcceptVettingProcessPayload {
  vettingProcessId: VettingProcessId;
  hasAccepted: boolean;
  reason?: string;
}

export default class VettingProcessEndpoint extends ServiceEndpoint {
  /**
   * Fetches an admin vetting process using the document id
   * @param auth - Admins
   */
  public fetchAdminVettingProcessById(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
  ): Promise<AdminVettingProcess> {
    return this.fetch(
      auth,
      `${BasePath}/admin/${vettingProcessId}`,
      null,
      'get',
    );
  }

  /**
   * Creates a new vetting process for a given user if there isn't an active process.
   * @param auth - Admins
   */
  public startVettingProcess(
    auth: ServiceAuth,
    payload: StartVettingProcessPayload,
  ): Promise<void> {
    return this.fetch(auth, BasePath, null, 'post', payload);
  }

  /**
   * Fetches a batch of vetting processes with filters and sorting parameters.
   * Used for the Selection Team pages.
   * @param auth - Selection Team
   */
  public fetchVettingProcesses(
    auth: ServiceAuth,
    query: FetchVettingProcessesQuery,
    abortController?: AbortController,
  ): Promise<BasicVettingProcess[]> {
    return this.fetch(auth, BasePath, query, 'get', undefined, abortController);
  }

  /**
   * Fetches a batch of vetting processes with filters and sorting parameters.
   * Used for the Selection Team pages.
   * @param auth - Admins
   */
  public fetchAdminVettingProcesses(
    auth: ServiceAuth,
    query: FetchVettingProcessesQuery,
    abortController?: AbortController,
  ): Promise<AdminVettingProcess[]> {
    return this.fetch(
      auth,
      BasePath + '/admin',
      query,
      'get',
      undefined,
      abortController,
    );
  }

  /**
   * Counts the number of vetting processes with filters
   * @param auth - Selection Team
   */
  public countVettingProcesses(
    auth: ServiceAuth,
    query: CountVettingProcessesQuery,
    abortController?: AbortController,
  ): Promise<number> {
    return this.fetch(
      auth,
      BasePath + '/count',
      query,
      'get',
      undefined,
      abortController,
    );
  }

  /**
   * Counts the number of vetting processes with filters
   * @param auth - Admins
   */
  public countAdminVettingProcesses(
    auth: ServiceAuth,
    query: CountVettingProcessesQuery,
    abortController?: AbortController,
  ): Promise<number> {
    return this.fetch(
      auth,
      BasePath + '/admin/count',
      query,
      'get',
      undefined,
      abortController,
    );
  }

  /**
   * Fetch the vetting processes for a given user
   * Used on the Admin Notes
   * @param auth - Admins
   */
  public fetchUserVettingProcesses(
    auth: ServiceAuth,
    query: FetchUserVettingProcessesQuery,
  ): Promise<AdminVettingProcess[]> {
    return this.fetch(auth, BasePath + '/user', { ...query }, 'get');
  }

  /**
   * Edits fields for a given vetting process
   * @param auth - Admins
   */
  public updateVettingProcess(
    auth: ServiceAuth,
    payload: PatchAdminVettingProcessPayload,
  ): Promise<AdminVettingProcess> {
    return this.fetch(auth, BasePath + '/admin', null, 'PATCH', payload);
  }

  /**
   * Finds the best interviewer suggestions for a given vetting process
   * @param auth - Admins
   */
  public getInterviewerSuggestions(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
  ): Promise<VetterSuggestion[]> {
    return this.fetch(
      auth,
      `${BasePath}/interviewer-suggestions/${vettingProcessId}`,
      null,
      'get',
    );
  }

  /**
   * Check if the post evaluation form was submitted for this vetting process
   * @param auth - Builders
   */
  public checkPostEvaluationSurveySubmitted(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
  ): Promise<CheckPostEvaluationSurveySubmitted> {
    return this.fetch(
      auth,
      `${BasePath}/post-evaluation-survey/${vettingProcessId}`,
      null,
      'get',
    );
  }

  /**
   * Submits the builder's post evaluation survey
   * @param auth - Builders
   */
  public submitPostEvaluationSurvey(
    auth: ServiceAuth,
    payload: SubmitPostEvaluationSurveyPayload,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + '/post-evaluation-survey',
      null,
      'post',
      payload,
    );
  }

  /**
   * Sends an evaluation invitation email to the user
   * @param auth - Admins
   */
  public sendEvaluationInvite(
    auth: ServiceAuth,
    payload: SendEvaluationInvitationPayload,
  ): Promise<AdminVettingProcess> {
    return this.fetch(
      auth,
      BasePath + '/admin/send-evaluation-invite',
      null,
      'post',
      payload,
    );
  }

  /**
   * Sends an email to the builder with the vetter calendar URL
   * @param auth - Admins
   */
  public sendInterviewInviteEmail(
    auth: ServiceAuth,
    payload: SendInterviewInviteEmailPayload,
  ): Promise<AdminVettingProcess> {
    return this.fetch(
      auth,
      BasePath + '/admin/send-interview-invite-email',
      null,
      'post',
      payload,
    );
  }

  /**
   * Sets the interview date for a vetting process
   * @param auth - Selection Team
   */
  public setInterviewDate(
    auth: ServiceAuth,
    payload: SetInterviewDatePayload,
  ): Promise<BasicVettingProcess> {
    return this.fetch(
      auth,
      BasePath + '/interview-date',
      null,
      'PATCH',
      payload,
    );
  }

  /**
   * Sets the interview date for a vetting process
   * @param auth - Admins
   */
  public adminSetInterviewDate(
    auth: ServiceAuth,
    payload: SetInterviewDatePayload,
  ): Promise<AdminVettingProcess> {
    return this.fetch(
      auth,
      `${BasePath}/admin/interview-date`,
      null,
      'PATCH',
      payload,
    );
  }

  /**
   * Removes an active vetting process and supply a reason (soft delete)
   * @param auth - Admins
   */
  public removeVettingProcess(
    auth: ServiceAuth,
    payload: PatchRemoveVettingProcessPayload,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + '/admin/remove-application',
      null,
      'PATCH',
      payload,
    );
  }

  /**
   * Marks a vetting process builder as opted out and supply a reason
   * @param auth - Selection Team
   */
  public vetterMarkBuilderAsOptedOut(
    auth: ServiceAuth,
    payload: UserOptOutByVetterPayload,
  ): Promise<void> {
    return this.fetch(
      auth,
      `${BasePath}/mark-builder-as-opted-out`,
      null,
      'PATCH',
      payload,
    );
  }

  /**
   * Marks a vetting process builder as opted out and supply a reason
   * @param auth - Admins
   */
  public adminMarkBuilderAsOptedOutOnVetterBehalf(
    auth: ServiceAuth,
    payload: UserOptOutByVetterPayload,
  ): Promise<void> {
    return this.fetch(
      auth,
      `${BasePath}/admin/mark-builder-as-opted-out`,
      null,
      'PATCH',
      payload,
    );
  }

  /**
   * Sends an email to the interviewer notifying him about the process
   * @param auth - Admins
   */
  public notifyInterviewerOnProcess(
    auth: ServiceAuth,
    payload: NotifyVetterOnProcessPayload,
  ): Promise<AdminVettingProcess> {
    return this.fetch(
      auth,
      BasePath + '/admin/notify-vetter-on-process',
      null,
      'post',
      payload,
    );
  }

  /**
   * Adds a record to specify when was the interviwer notified
   * @param auth - Admins
   */
  public addInterviewerNotifiedRecord(
    auth: ServiceAuth,
    payload: AddInterviewerNotifiedRecordPayload,
  ): Promise<AdminVettingProcess> {
    return this.fetch(
      auth,
      BasePath + '/admin/vetter-notified',
      null,
      'post',
      payload,
    );
  }

  /**
   * Edits a record that specifies when was the interviewer notified
   * @param auth - Admins
   */
  public updateInterviewerNotifiedRecord(
    auth: ServiceAuth,
    payload: PatchInterviewerNotifiedRecordPayload,
  ): Promise<AdminVettingProcess> {
    return this.fetch(
      auth,
      BasePath + '/admin/vetter-notified',
      null,
      'PATCH',
      payload,
    );
  }

  /**
   * Removes a record to specify when was the interviwer notified
   * @param auth - Admins
   */
  public removeInterviewerNotifiedRecord(
    auth: ServiceAuth,
    payload: RemoveInterviewerNotifiedRecordPayload,
  ): Promise<AdminVettingProcess> {
    return this.fetch(
      auth,
      BasePath + '/admin/vetter-notified',
      null,
      'delete',
      payload,
    );
  }

  public getScheduleUrl(
    auth: ServiceAuth,
    vettingProcessId: string,
    vetterIds: string[],
  ): Promise<string> {
    return this.fetch(
      auth,
      `${BasePath}/schedule-url/${vettingProcessId}`,
      { vetterIds },
      'get',
    );
  }
}
