import React, {
  FormEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import RegistrationLayout from '@src/layouts/RegistrationLegacy';
import RegistrationTitle from '@src/components/RegistrationTitle';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
// import { createUseStyles } from 'react-jss';
import {
  Button as CallToActionButton,
  ButtonContainer,
} from '@ateams/components';
import useToggle from '@src/hooks/useToggle';
import TextButton from '@src/components/TextButton';
import { Location } from 'history';
import qs from 'query-string';
import useDebounceState from '@src/hooks/useDebounceState';
import useLoadingState from '@src/hooks/useLoadingState';
import { apiRegistration } from '@src/logic/services/endpoints';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { MissionId } from '@a_team/models/dist/MissionObject';
import { match, useHistory } from 'react-router-dom';
import { useStores } from '@src/stores';
import { TeamWorkLocation } from '@src/locations';

interface Props {
  location: Location;
  match: match<{ rid: MissionId }>;
}

// const useStyles = createUseStyles({});

function CompanyRegistrationSignUpView(props: Props): ReactElement {
  const { location, match } = props;
  // const styles = useStyles();

  const { auth } = useStores();
  const history = useHistory();
  const [showPassword, toggleShowPassword] = useToggle();
  const [password, setPassword] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [emailLoading, setEmailLoading] = useLoadingState();
  const [loading, setLoading] = useLoadingState();
  const [email, setEmail, emailChanged] = useDebounceState<string>(
    '',
    (email) => {
      setValidEmail(false);

      setEmailLoading(
        apiRegistration
          .validateEmail(null, email)
          .then((isValid) => setValidEmail(isValid)),
      );
    },
    600,
  );

  useEffect((): void => {
    if (email) return;

    setEmail(String(qs.parse(location.search).email || ''));
  }, [location.search]);

  const isValid = useMemo(
    () => !emailChanged && password.length >= 6 && validEmail,
    [emailChanged, email, password, validEmail, emailLoading],
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!isValid) return;

    setLoading(
      auth
        .registerCompanyByRequest(match.params.rid, {
          email,
          password,
        })
        .then(() => history.push(TeamWorkLocation)),
    );
  };

  return (
    <RegistrationLayout title="Welcome to A·Team">
      <RegistrationTitle>Thanks for Signing Up!</RegistrationTitle>
      <p>
        We're glad you're here. Someone from the{' '}
        <a href="/FormationTeam" target="_blank">
          Formation Team
        </a>{' '}
        will get back to you within 48 hours to discuss your team needs!
      </p>
      <p style={{ marginBottom: '32px' }}>
        In the meantime, set up your password and start discovering teams and
        individual talent! And you can always add more details to your team
        request.
      </p>

      <form onSubmit={handleSubmit}>
        <OutlinedInput
          type="email"
          value={email}
          onChange={(e): void => setEmail(e.target.value)}
          valid={email && !emailChanged ? validEmail : undefined}
          loading={emailLoading === true}
          required
        />

        <OutlinedInput
          value={password}
          onChange={(e): void => setPassword(e.target.value)}
          placeholder="Password..."
          autoComplete="password"
          minLength={6}
          type={showPassword ? 'text' : 'password'}
          required
          endAdornment={
            <TextButton highlight onClick={toggleShowPassword}>
              {showPassword ? 'Hide' : 'Show'}
            </TextButton>
          }
        />
        <ButtonContainer align="right">
          <CallToActionButton width="auto" disabled={!isValid}>
            Discover Talent
          </CallToActionButton>
        </ButtonContainer>
      </form>
      <LoadingIndicator loading={loading} />
    </RegistrationLayout>
  );
}

export default observer(CompanyRegistrationSignUpView);
