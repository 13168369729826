import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import MainLayout from '@src/layouts/Main';
import { useStores } from '@src/stores';
import MissionList from '@src/views/AdminDashboard/AdminTeamwork/MissionList';
import SectionHeading from '@src/components/SectionHeading';
import { loadClientMissions } from '@src/url-loaders/loadClientMissions';

export const clientDashboardViewLoader = loadClientMissions;

const ClientDashboardView = (): ReactElement => {
  const {
    missions: { clientMissions },
  } = useStores();

  return (
    <MainLayout title="Your Missions">
      <SectionHeading>Running Missions</SectionHeading>
      {clientMissions?.active && clientMissions.active.items.length > 0 ? (
        <MissionList
          clientView
          type={'active'}
          missions={clientMissions.active.items}
        />
      ) : (
        'No Running Missions'
      )}
      <SectionHeading>Pending Missions</SectionHeading>
      {clientMissions?.pending && clientMissions.pending.items.length > 0 ? (
        <MissionList
          clientView
          type={'pending'}
          missions={clientMissions.pending.items}
        />
      ) : (
        'No Pending Missions'
      )}
      <SectionHeading>Archived Missions</SectionHeading>
      {clientMissions?.archived && clientMissions.archived.items.length > 0 ? (
        <MissionList
          clientView
          type={'archived'}
          missions={clientMissions.archived.items}
        />
      ) : (
        'No Archived Missions'
      )}
    </MainLayout>
  );
};

export default observer(ClientDashboardView);
