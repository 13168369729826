import React, { useMemo, useState } from 'react';
import { Button } from '@a_team/ui-components';
import { observer } from 'mobx-react';
import Step, { SetupProps, StepStatus } from '../common/Step';
import StepIcon, { StepIconType } from '../common/Step/StepIcon';
import { OnboardingStage } from '@a_team/models/dist/UserObject';
import {
  useGetUserRolesWithStatus,
  useGetEvaluationCallDetails,
  useRequestEvaluationCall,
} from '@src/rq/users';
import { format } from 'date-fns';
import SelectNonWaitlistedRoleModal from './SelectNonWaitlistedRoleModal';
import { RoleType } from '@a_team/models/dist/TalentCategories';

interface EvaluationCallStepProps {
  onboardingStage: OnboardingStage;
  isSourcedBuilder?: boolean;
  isPreScrubbed?: boolean;
}

const EvaluationCallStep = ({
  onboardingStage,
  isSourcedBuilder,
  isPreScrubbed,
}: EvaluationCallStepProps) => {
  const stepNumber =
    isSourcedBuilder || isPreScrubbed
      ? '2'
      : [
          OnboardingStage.PendingSecondReview,
          OnboardingStage.SecondReviewRequested,
        ].includes(onboardingStage)
      ? '4'
      : '3';

  const { mutate: requestEvaluationCall, isLoading } =
    useRequestEvaluationCall();

  const { data: callDetails, isLoading: isLoadingCallDetails } =
    useGetEvaluationCallDetails({
      enabled: onboardingStage === OnboardingStage.InterviewScheduled,
    });

  const { data: rolesWithStatus, isLoading: isLoadingCallWaitlistedRoles } =
    useGetUserRolesWithStatus({
      enabled:
        isSourcedBuilder ||
        [
          OnboardingStage.PendingInterview,
          OnboardingStage.PendingInterviewWithIncompleteProfile,
        ].includes(onboardingStage),
    });

  const [
    showSelectNonWaitlistedRoleModal,
    setShowSelectNonWaitlistedRoleModal,
  ] = useState(false);

  const handleRequestEvaluationCall = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();

    // If still loading waitlisted roles, exit early
    if (isLoadingCallWaitlistedRoles) {
      return;
    }

    // Check if the main role is waitlisted
    const isMainRoleWaitlisted = rolesWithStatus?.some(
      (role) => role.type === RoleType.Main && role.markedAsWaitlisted,
    );

    // Count non-waitlisted additional roles
    const totalAdditionalRolesNonWaitlisted =
      rolesWithStatus?.filter(
        (role) => role.type === RoleType.Additional && !role.markedAsWaitlisted,
      ).length ?? 0;

    // Handle different scenarios based on role status

    if (!isMainRoleWaitlisted) {
      // If main role is not waitlisted, request evaluation for it
      const mainRole = rolesWithStatus?.find(
        (role) => role.type === RoleType.Main,
      );
      if (mainRole) {
        await requestEvaluationCall(mainRole.roleId);
      }
    } else if (totalAdditionalRolesNonWaitlisted === 1) {
      // If there's exactly one non-waitlisted additional role, use it
      const additionalRole = rolesWithStatus?.find(
        (role) => role.type === RoleType.Additional && !role.markedAsWaitlisted,
      );
      if (additionalRole) {
        await requestEvaluationCall(additionalRole.roleId);
      }
    } else {
      setShowSelectNonWaitlistedRoleModal(true);
    }
  };

  const interviewDate = useMemo(() => {
    return callDetails?.date ? new Date(callDetails.date) : new Date();
  }, [isLoadingCallDetails, callDetails?.date]);

  const INCOMPLETE_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.NUMBER} value={stepNumber} />,
    title: 'Showcase your expertise on an evaluation call',
    description:
      'Schedule a call with our team to share details about your skills and past experience. You cannot be considered until you complete an evaluation call.',
    completionTime: 'Evaluation interview duration: 30-45 minutes',
    cta: (
      <Button
        size="md"
        onClick={handleRequestEvaluationCall}
        disabled={isLoading}
      >
        {isLoading ? 'Please wait...' : 'Schedule call'}
      </Button>
    ),
    videoUrl: 'https://www.loom.com/share/7bce27f2e8674de29d890b4e4f4792e2',
    stepStatus: [
      OnboardingStage.PendingInterview,
      OnboardingStage.PendingInterviewWithIncompleteProfile,
    ].includes(onboardingStage)
      ? StepStatus.IN_PROGRESS
      : StepStatus.INCOMPLETE,
  };

  const IN_PROGRESS_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.IN_PROGRESS} />,
    title: 'Your evaluation call has been scheduled',
    description: (
      <div>
        Join the call{' '}
        <a href={callDetails?.url} target="_blank" rel="noreferrer">
          here
        </a>{' '}
        on{' '}
        {callDetails?.date &&
          format(interviewDate, `MMMM d, yyyy 'at' h:mm a zzz`)}
        . After joining the call you'll receive an update in 2-5 business days.
      </div>
    ),
    completionTime: `Evaluation interview duration: 30-45 minutes`,
    cta: <div>Scheduled</div>,
    videoUrl: 'https://www.loom.com/share/51d678033a0a4ad4a123f8cb59b80e78',
    stepStatus: StepStatus.IN_PROGRESS,
  };

  const COMPLETED_DATA: SetupProps = {
    stepIcon: <StepIcon type={StepIconType.COMPLETED} />,
    title: 'Profile review complete',
    description: isSourcedBuilder
      ? 'You have been granted access to A.Team. If you’d like full access to the network, complete your profile below.'
      : 'A member of our team has reviewed your profile and invited you to an evaluation interview.',
    cta: <div>Completed</div>,
    stepStatus: StepStatus.COMPLETED,
  };

  const getPayload = () => {
    if (
      [
        OnboardingStage.IncompleteProfile,
        OnboardingStage.PendingReviewRequest,
        OnboardingStage.ReviewRequested,
        OnboardingStage.PendingSecondReview,
        OnboardingStage.SecondReviewRequested,
        OnboardingStage.PendingInterview,
        OnboardingStage.PendingInterviewWithIncompleteProfile,
      ].includes(onboardingStage)
    ) {
      return INCOMPLETE_DATA;
    }

    if (
      [
        OnboardingStage.InterviewScheduled,
        OnboardingStage.InterviewScheduledWithIncompleteProfile,
      ].includes(onboardingStage)
    ) {
      return IN_PROGRESS_DATA;
    }

    return COMPLETED_DATA;
  };

  const stepPayload = getPayload();

  return (
    <>
      <Step {...stepPayload} />
      <SelectNonWaitlistedRoleModal
        open={showSelectNonWaitlistedRoleModal}
        onClose={() => setShowSelectNonWaitlistedRoleModal(false)}
        rolesWithStatus={rolesWithStatus ?? []}
        onSubmit={(roleId) => {
          requestEvaluationCall(roleId);
        }}
        isLoading={isLoading}
      />
    </>
  );
};

export default observer(EvaluationCallStep);
