import { AdminMissionApplicationObject } from '@a_team/models/dist/MissionApplicationObject';
import { MissionStatus } from '@a_team/models/dist/MissionObject';
import MissionRole, {
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import { Card, Colors, Icon, IconType, Spacing } from '@ateams/components';
import SectionHeading from '@src/components/SectionHeading';
import TextButton from '@src/components/TextButton';
import UserAvatar from '@src/components/UserAvatar';
import { ApplicationStatusIndication } from '@src/views/AdminNotes/Main/ApplicationsTab/ApplicationStatusIndication';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { SuggestedTeamMemberWithApplication } from './SuggestedTeamMemberWithApplication';
import { SuggestedTeamWithApplications } from './utils';
import { Link } from 'react-router-dom';
import { ProfileLocation } from '@src/locations';
import { NarrativeItem } from '../Narratives/NarrativeItem';
import {
  EnrichedTeamNarrativeData,
  EnrichedTeamNarrativesResponse,
} from '@a_team/models/dist/TeamGraphObject';
import { isEmpty } from 'lodash';
import { narrativeDetailsForType } from '../Narratives/narrativeDetailsForType';

const VISIBLE_TEAMS_LENGTH = 3;

interface Props {
  teams: SuggestedTeamWithApplications[];
  onClickOnProposeTeam: (
    team: Record<string, SuggestedTeamMemberWithApplication>,
  ) => void;
  openRoles: MissionRole[];
  applications: AdminMissionApplicationObject[] | null;
  proposeTeamDisabled?: boolean;
  missionStatus: MissionStatus;
  proposeTeamButtonText?: string;
  showHeader?: boolean;
  allContentVisible?: boolean;
}

const useStyles = createUseStyles({
  precomputedTeamRoot: {},
  precomputedTeamHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      margin: 0,
    },
  },
  teamList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
  },
  teamListItem: {
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.medium,
    margin: `${Spacing.medium}px 0 0 ${Spacing.medium}px`,
    '& p': {
      margin: 0,
    },
    flex: '0 1 auto',
  },
  roleCategoryTitle: {},
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '1.34rem', // Same as card padding
    marginBottom: Spacing.medium,
  },
  statusIndicationContainer: {
    display: 'inline-block',
  },
  roleCategoryTitleText: {
    color: Colors.regularDark,
  },
  arrowUp: {
    transform: 'rotate(180deg)',
    marginTop: -3,
  },
  visibilityIndicator: {
    display: 'flex',
    '& p': {
      marginRight: Spacing.small,
    },
  },
  hideAllButton: {
    marginLeft: Spacing.small,
  },
  narrativesContainer: {
    margin: `${Spacing.xLarge}px 0 0`,
    width: '70%',
  },
  narrativesList: {
    margin: `${Spacing.small}px 0 0`,
    padding: 0,
    '& li': {
      listStyle: 'none',
    },
  },
  textMuted: {
    color: Colors.regularDark,
  },
  narrativesIconList: {
    display: 'flex',
    gap: Spacing.small,
    margin: 0,
    padding: 0,
    '& li': {
      listStyle: 'none',
    },
  },
  rotateUp: {
    transform: 'rotate(180deg)',
  },
  toggleNarrativesButton: {
    marginTop: Spacing.small,
    '& i': {
      marginLeft: Spacing.small,
    },
  },
  suggestedTeamsToggleButton: {
    border: 'none',
    background: 'none',
  },
  suggestedTeamsIconContainer: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: Spacing.small,
    borderRadius: '50%',
    backgroundColor: Colors.regularLight,
  },
  toggleIcon: {
    marginLeft: Spacing.small,
  },
  narrativesText: {
    marginBottom: 0,
  },
});

export const PotentialExtensions: React.FC<Props> = (props) => {
  const styles = useStyles();
  const [visibleLength, setVisibleLength] = React.useState(
    props.allContentVisible ? Infinity : VISIBLE_TEAMS_LENGTH,
  );
  const [rolesShowingNarratives, setRolesShowingNarratives] = useState<
    number[]
  >([]);

  const [isContentVisible, setIsContentVisible] = useState<boolean>(
    props.showHeader ?? true,
  );

  const toggleNarratives = (idx: number) => {
    if (rolesShowingNarratives.includes(idx)) {
      setRolesShowingNarratives((current) =>
        current.filter((id) => id !== idx),
      );
    } else {
      setRolesShowingNarratives((current) => [...current, idx]);
    }
  };

  return (
    <>
      {props.showHeader && (
        <button
          className={styles.suggestedTeamsToggleButton}
          onClick={() => setIsContentVisible(!isContentVisible)}
        >
          <SectionHeading
            isFirst
            style={{
              marginBottom: Spacing.large,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div className={styles.suggestedTeamsIconContainer}>
              <Icon size="xsmall" type={IconType.SuggestedTeamsInactive} />
            </div>
            Potential extensions
            <Icon
              size="xxsmall"
              className={`${styles.toggleIcon} ${
                isContentVisible ? styles.rotateUp : ''
              }`}
              type={IconType.ArrowDownBlack}
            />
          </SectionHeading>
        </button>
      )}
      {isContentVisible && (
        <>
          {props.teams.slice(0, visibleLength).map((team, idx) => {
            const activeTeamMembers = Object.keys(team.members).filter(
              (memberRoleId) => team.members[memberRoleId].application.accepted,
            );
            return (
              <Card>
                <div className={styles.precomputedTeamRoot}>
                  <div className={styles.precomputedTeamHeader}>
                    <TextButton
                      disabled={props.proposeTeamDisabled}
                      onClick={() => props.onClickOnProposeTeam(team.members)}
                      color="secondaryDark"
                    >
                      {props.proposeTeamButtonText || 'Preview'}
                    </TextButton>
                  </div>
                  <div>
                    <ul className={styles.teamList}>
                      {Object.keys(team.members).map((memberRoleId) => {
                        const member =
                          team.members[memberRoleId].suggestedTeamMember;
                        const applicationData =
                          team.members[memberRoleId].application;
                        const role = props.openRoles.find(
                          (role) => role.rid === memberRoleId,
                        );
                        if (!applicationData || !role) return null;
                        const confirmedAnotherBuilder =
                          role.status !== MissionRoleStatus.Canceled &&
                          role.user &&
                          role.user.username !== member.username;
                        return (
                          <li className={styles.teamListItem}>
                            <Link
                              target="_blank"
                              to={ProfileLocation(member.username || '')}
                            >
                              <UserAvatar
                                src={
                                  member.profilePictureURL ||
                                  applicationData.user.profilePictureURL
                                }
                              />
                            </Link>
                            <div>
                              <p>{member.fullName}</p>
                              <p className={styles.roleCategoryTitleText}>
                                {role.category.title}
                              </p>
                              <p className={styles.roleCategoryTitle}>
                                <div
                                  className={styles.statusIndicationContainer}
                                >
                                  <ApplicationStatusIndication
                                    noMargin
                                    hired={
                                      (role.user &&
                                        role.user.username ===
                                          member.username) ||
                                      false
                                    }
                                    confirmedAnotherBuilder={
                                      confirmedAnotherBuilder
                                    }
                                    withdrawn={applicationData.withdrawn}
                                    status={{
                                      reviewStatus:
                                        applicationData.reviewStatus,
                                      internalStatus:
                                        applicationData.internalStatus,
                                      proposedToClient:
                                        applicationData.proposedToClient,
                                      exclusiveStatus:
                                        applicationData.exclusiveStatus,
                                      lowCompetitiveness:
                                        applicationData.lowCompetitiveness,
                                      proposalInterviewing:
                                        applicationData.proposalInterviewing,
                                      proposal: applicationData.proposal,
                                    }}
                                    missionStatus={props.missionStatus}
                                    roleStatus={role.status}
                                  />
                                </div>
                              </p>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div>
                    <p className={styles.narrativesText}>
                      Has narratives with {activeTeamMembers.length} active
                      members
                    </p>
                  </div>
                  {!isEmpty(team.team.enrichedTeamNarrative) ? (
                    <div className={styles.narrativesContainer}>
                      {rolesShowingNarratives.includes(idx) ? (
                        <>
                          <p className={styles.textMuted}>Team narratives</p>
                          <ul className={styles.narrativesList}>
                            {(
                              Object.keys(
                                team.team.enrichedTeamNarrative,
                              ) as (keyof EnrichedTeamNarrativesResponse)[]
                            ).map((narrativeType) => {
                              const enrichedTeamNarrativeForType = team.team
                                .enrichedTeamNarrative[narrativeType] as
                                | EnrichedTeamNarrativeData
                                | undefined;
                              return enrichedTeamNarrativeForType ? (
                                <li key={narrativeType}>
                                  <NarrativeItem
                                    narrativeType={narrativeType}
                                    overlapText={
                                      enrichedTeamNarrativeForType.overlapText
                                    }
                                    overlapUsers={
                                      enrichedTeamNarrativeForType.overlapUsers
                                    }
                                  ></NarrativeItem>
                                </li>
                              ) : null;
                            })}
                          </ul>
                          <TextButton
                            className={styles.toggleNarrativesButton}
                            color="secondaryDark"
                            onClick={() => toggleNarratives(idx)}
                          >
                            Hide narratives
                            <Icon
                              className={styles.rotateUp}
                              size="exact"
                              type={IconType.ArrowDownSecondary}
                            />
                          </TextButton>
                        </>
                      ) : (
                        <>
                          <ul className={styles.narrativesIconList}>
                            {(
                              Object.keys(
                                team.team.enrichedTeamNarrative,
                              ) as (keyof EnrichedTeamNarrativesResponse)[]
                            ).map(
                              (narrativeType) =>
                                team.team.enrichedTeamNarrative[
                                  narrativeType
                                ] && (
                                  <li>
                                    <Icon
                                      type={
                                        narrativeDetailsForType[narrativeType]
                                          .icon
                                      }
                                    />
                                  </li>
                                ),
                            )}
                          </ul>
                          <TextButton
                            className={styles.toggleNarrativesButton}
                            color="secondaryDark"
                            onClick={() => toggleNarratives(idx)}
                          >
                            Show{' '}
                            {
                              Object.keys(team.team.enrichedTeamNarrative)
                                .length
                            }{' '}
                            team narratives
                            <Icon
                              size="exact"
                              type={IconType.ArrowDownSecondary}
                            />
                          </TextButton>
                        </>
                      )}
                    </div>
                  ) : null}
                </div>
              </Card>
            );
          })}
          <div className={styles.footer}>
            {!props.allContentVisible ? (
              <div className={styles.visibilityIndicator}>
                <p>
                  {Math.min(visibleLength, props.teams.length)} /{' '}
                  {props.teams.length} visible
                </p>
                {visibleLength < props.teams.length ? (
                  <TextButton
                    color="secondaryDark"
                    onClick={() =>
                      setVisibleLength((len) => len + VISIBLE_TEAMS_LENGTH)
                    }
                  >
                    Show more{' '}
                    <Icon size="exact" type={IconType.ArrowDownSecondary} />
                  </TextButton>
                ) : null}
              </div>
            ) : null}
            {visibleLength > VISIBLE_TEAMS_LENGTH &&
            !props.allContentVisible ? (
              <TextButton
                className={styles.hideAllButton}
                color="regularDark"
                onClick={() => setVisibleLength(VISIBLE_TEAMS_LENGTH)}
              >
                Hide{' '}
                <Icon
                  className={styles.arrowUp}
                  size="exact"
                  type={IconType.ArrowDownBlack}
                />
              </TextButton>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};
