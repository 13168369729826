import CompleteSetupModal from '@src/components/Modal/CompleteSetupModal';
import NoResults from '@src/components/NoResults';
import TextButton from '@src/components/TextButton';
import { useGetOpenMissionsV2 } from '@src/rq/missions';
import { useStores } from '@src/stores';
import { LoadingCards } from '@src/views/MissionControl/LoadingCards';
import { observer } from 'mobx-react';
import React, { ReactElement, useMemo, useState } from 'react';
import MissionControlCardOptimized from '../MissionControlCard/MissionControlCardV2/MissionControlCardOptimized';
import { useGridMissionControlCardStyles } from '../MissionControlCard/common/styles';
import MissionFiltersV2 from '../MissionFilters/MissionFiltersV2';
import Reserve from '../Reserve';
import { OpenMissionDataResponse } from '@a_team/models/dist/v2/MissionObjectV2';

// Hoisted helper functions
export const extractUniqueSkills = (
  missions: OpenMissionDataResponse[] | undefined = [],
) => {
  return missions
    .flatMap((mission) => mission.skills)
    .filter(
      (skill, index, self) =>
        index ===
        self.findIndex((s) => s.talentSkillId === skill.talentSkillId),
    )
    .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
};

export const extractUniqueRoleCategories = (
  missions: OpenMissionDataResponse[] | undefined = [],
) => {
  return missions
    .flatMap((mission) => mission.roleCategories)
    .filter(
      (roleCategory, index, self) =>
        index === self.findIndex((r) => r.id === roleCategory.id),
    )
    .sort((a, b) => (a.title || '').localeCompare(b.title || ''));
};

export const extractUniqueIndustries = (
  missions: OpenMissionDataResponse[],
) => {
  return missions
    .flatMap((mission) => mission.industries)
    .filter(
      (industry, index, self) =>
        index === self.findIndex((i) => i.id === industry.id),
    )
    .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
};

const AllMissions = (): ReactElement => {
  const { auth, missionControl } = useStores();
  const gridStyles = useGridMissionControlCardStyles();

  const [showCompleteSetupModal, setShowCompleteSetupModal] = useState(false);
  const [filterByUserLocation, setFilterByUserLocation] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [selectedRoleCategories, setSelectedRoleCategories] = useState<
    string[]
  >([]);
  const [industries, setSelectedIndustries] = useState<string[]>([]);
  const [filterByLookingForBuilders, setFilterByLookingForBuilders] =
    useState(false);
  const [filterByAvailableMissions, setFilterByAvailableMissions] =
    useState(false);

  const { filtersOn, clearFilters, filterByUserSettings, appliedUserFilters } =
    missionControl;

  const { data, isLoading } = useGetOpenMissionsV2();

  const openMissionsV2 = data?.data;

  const loading = isLoading && !auth.currentUser && !appliedUserFilters;

  const showEmptyState =
    auth.currentUser && !missionControl.loading && !openMissionsV2?.length;

  const resetFilters = (): void => {
    if (auth && auth.currentUser) {
      filterByUserSettings(auth.currentUser);
    } else {
      clearFilters();
    }
  };

  const isLimitedAccess = auth.limitedAccess;

  const userLocation = auth.currentUser?.location?.countryShortName;

  const allSkills = useMemo(
    () => extractUniqueSkills(openMissionsV2),
    [openMissionsV2],
  );

  const allRoleCategories = useMemo(
    () => extractUniqueRoleCategories(openMissionsV2),
    [openMissionsV2],
  );

  const allIndustries = useMemo(
    () => extractUniqueIndustries(openMissionsV2 || []),
    [openMissionsV2],
  );

  const filteredMissions = openMissionsV2?.filter((mission) => {
    const locationMatch =
      !filterByUserLocation ||
      !mission.locations?.length ||
      mission.locations.includes(userLocation || '');

    const skillsMatch =
      selectedSkills.length === 0 ||
      mission.skills.some((skill) =>
        selectedSkills.includes(skill.talentSkillId),
      );

    const roleCategoriesMatch =
      selectedRoleCategories.length === 0 ||
      mission.roleCategories.some((role) =>
        selectedRoleCategories.includes(role.id),
      );

    const industriesMatch =
      industries.length === 0 ||
      mission.industries.some((industry) => industries.includes(industry.id));

    const lookingForBuildersMatch =
      !filterByLookingForBuilders || mission.lookingForBuilders;

    const availabilityMatch =
      !filterByAvailableMissions || mission.isMissionAvailable;

    return (
      locationMatch &&
      skillsMatch &&
      roleCategoriesMatch &&
      industriesMatch &&
      lookingForBuildersMatch &&
      availabilityMatch
    );
  });

  if (isLimitedAccess) {
    return <Reserve />;
  }

  return (
    <>
      <MissionFiltersV2
        selectedSkills={selectedSkills}
        setSelectedSkills={setSelectedSkills}
        selectedRoleCategories={selectedRoleCategories}
        setSelectedRoleCategories={setSelectedRoleCategories}
        selectedIndustries={industries}
        setSelectedIndustries={setSelectedIndustries}
        filterByUserLocation={filterByUserLocation}
        setFilterByUserLocation={setFilterByUserLocation}
        filterByLookingForBuilders={filterByLookingForBuilders}
        setFilterByLookingForBuilders={setFilterByLookingForBuilders}
        filterByAvailableMissions={filterByAvailableMissions}
        setFilterByAvailableMissions={setFilterByAvailableMissions}
        disabled={loading}
        allSkills={allSkills}
        allRoleCategories={allRoleCategories}
        allIndustries={allIndustries}
      />

      {filteredMissions && (
        <div className={gridStyles.container}>
          {filteredMissions.map((mission) => (
            <MissionControlCardOptimized key={mission.mid} mission={mission} />
          ))}
        </div>
      )}

      <CompleteSetupModal
        title="Complete setup to apply to missions"
        description="Complete the steps on the setup tab to continue"
        open={showCompleteSetupModal}
        onClose={() => setShowCompleteSetupModal(false)}
      />

      {loading && (
        <div className={gridStyles.container}>
          <LoadingCards type={'all'} />
        </div>
      )}

      {showEmptyState &&
        (filtersOn ? (
          <NoResults
            imageType="noSearchResults"
            title="No results found!"
            text={
              <>
                <p>Sorry, we didn't find any missions that fit your needs.</p>
                <p>
                  Please update your selected filters or{' '}
                  <TextButton onClick={resetFilters} color="primary">
                    start again
                  </TextButton>
                  .
                </p>
              </>
            }
          />
        ) : (
          <NoResults
            imageType="noSearchResults"
            title="We don't have any new missions"
            text={
              <>
                <p>Unfortunately, right now we don't have any new missions.</p>
                <p>Please check Mission Control later.</p>
              </>
            }
          />
        ))}
    </>
  );
};

export default observer(AllMissions);
