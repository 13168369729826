import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  VettingProcessAudit,
  VettingProcessAuditTypes,
} from '@a_team/models/dist/vetting-process-audit';
import { Text } from '@src/views/VettingFeedbackForm/components/typography';
import { ActivityRecordMainText } from '.';
import { Spacing, TextColors } from '@ateams/components';

export interface ProcessRemovedActivityRecordProps {
  vettingProcessAudit: VettingProcessAudit<VettingProcessAuditTypes.ProcessRemoved>;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainText: {
    color: TextColors.dangerLight,
    marginBottom: Spacing.small,
  },
});

export const ProcessRemovedActivityRecord: React.FC<
  ProcessRemovedActivityRecordProps
> = ({ vettingProcessAudit }) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <ActivityRecordMainText className={styles.mainText}>
        Process removed
      </ActivityRecordMainText>

      <Text>{vettingProcessAudit.payload.reason}</Text>
    </div>
  );
};
