import { TimesheetRecordType } from '@a_team/models/dist/TimesheetObject';

const options = [
  {
    label: TimesheetRecordType.DataAnalysis,
    value: TimesheetRecordType.DataAnalysis,
    color: 'black',
    background: '#EDE0FF',
  },
  {
    label: TimesheetRecordType.Design,
    value: TimesheetRecordType.Design,
    color: 'black',
    background: '#FFE0C7',
  },
  {
    label: TimesheetRecordType.Documentation,
    value: TimesheetRecordType.Documentation,
    color: 'black',
    background: '#FFF3C7',
  },
  {
    label: TimesheetRecordType.Engineering,
    value: TimesheetRecordType.Engineering,
    color: 'black',
    background: '#DCF2CF',
  },
  {
    label: TimesheetRecordType.Growth,
    value: TimesheetRecordType.Growth,
    color: 'black',
    background: '#E3E0FF',
  },
  {
    label: TimesheetRecordType.Management,
    value: TimesheetRecordType.Management,
    color: 'black',
    background: '#E0EFFF',
  },
  {
    label: TimesheetRecordType.Marketing,
    value: TimesheetRecordType.Marketing,
    color: 'black',
    background: '#CCC7FF',
  },
  {
    label: TimesheetRecordType.Meetings,
    value: TimesheetRecordType.Meetings,
    color: 'black',
    background: '#FFCCDC',
  },
  {
    label: TimesheetRecordType.Operational,
    value: TimesheetRecordType.Operational,
    color: 'black',
    background: '#FFD2AD',
  },
  {
    label: TimesheetRecordType.Planning,
    value: TimesheetRecordType.Planning,
    color: 'black',
    background: '#C5EDBB',
  },
  {
    label: TimesheetRecordType.Research,
    value: TimesheetRecordType.Research,
    color: 'black',
    background: '#C7D0FF',
  },
  {
    label: TimesheetRecordType.Strategy,
    value: TimesheetRecordType.Strategy,
    color: 'black',
    background: '#E8C7FF',
  },
  {
    label: TimesheetRecordType.Testing,
    value: TimesheetRecordType.Testing,
    color: 'black',
    background: '#FFCCDC',
  },
  {
    label: TimesheetRecordType.Other,
    value: TimesheetRecordType.Other,
    color: 'black',
    background: '#FFE9C7',
  },
];

const outOutOfficeOptions = [
  {
    label: TimesheetRecordType.Holiday,
    value: TimesheetRecordType.Holiday,
    color: 'white',
    background: '#818388',
  },
  {
    label: TimesheetRecordType.SickDay,
    value: TimesheetRecordType.SickDay,
    color: 'white',
    background: '#818388',
  },
  {
    label: TimesheetRecordType.TimeOff,
    value: TimesheetRecordType.TimeOff,
    color: 'white',
    background: '#818388',
  },
];

export const outOfOfficeTypes = new Set(
  outOutOfficeOptions.map((option) => option.label),
);

export const groupedOptions = [
  {
    options,
  },
  {
    label: 'Out of office types',
    options: outOutOfficeOptions,
  },
];

export const allOptions = [...options, ...outOutOfficeOptions];
