import { Breakpoints, IconType, Tag } from '@ateams/components';
import { ApplicationRateRange } from '@a_team/models/src/MissionApplicationObject';
import { tagEditedYellowStyle } from '@src/common-styles/tag-edited';
import TextInput from '@src/components/Inputs/TextInput/TextInput';
import { observer } from 'mobx-react';
import React, { ReactElement, useMemo } from 'react';
import { useScreenClass } from 'react-grid-system';
import { createUseStyles } from 'react-jss';
import Section, { SectionProps } from '../partials/Section';

interface Props {
  minRange?: number;
  maxRange?: number;
  builderRateMin?: number;
  builderRateMax?: number;
  showRateRangeToBuilders?: boolean;
  onChange(range: { min?: number; max?: number }): void;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  guidanceMessage?: ReactElement;
  guidanceTooltip?: ReactElement;
  lastHourlyRateRange?: ApplicationRateRange;
  isApplicationEdited?: boolean;
  type?: SectionProps['type'];
}

const useStyles = createUseStyles({
  inputWrapper: {
    position: 'relative',
    margin: 0,
  },
  prefix: {
    position: 'absolute',
    left: 10,
    top: 9,
  },
  input: {
    border: '1px solid #DADADC',
    borderRadius: 4,
    padding: ({ readOnly }) =>
      readOnly ? '8px 0 8px 20px' : '8px 8px 8px 26px',
    width: ({ readOnly }) => (readOnly ? 56 : 100),
    fontWeight: 400,
  },
  tagEdited: {
    ...tagEditedYellowStyle,
    marginLeft: '8px',
  },
  hourlyRateWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  hourlyRateLabelWrapper: {
    marginBottom: 12,
  },
  hourlyRateInputsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    hourlyRateWrapper: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    hourlyRateLabelWrapper: {
      marginBottom: 0,
    },
    hourlyRateInputsWrapper: {
      marginLeft: ({ readOnly }: { readOnly?: boolean }) => (readOnly ? 0 : 16),
    },
  },
});

const HourlyRateRangeV2 = (props: Props): ReactElement => {
  const {
    maxRange,
    minRange,
    builderRateMin,
    builderRateMax,
    showRateRangeToBuilders,
    onChange,
    readOnly,
    error,
    disabled,
    lastHourlyRateRange,
    isApplicationEdited,
    guidanceMessage,
    guidanceTooltip,
    type,
  } = props;

  const styles = useStyles({ readOnly });
  const screenClass = useScreenClass();

  const isValid = (text: string) => {
    return !isNaN(Number(text));
  };

  const tooltipText = useMemo(() => {
    if (screenClass === 'xs') return undefined;
    let tooltip =
      'Setting a top and bottom rate makes it easier to connect with more companies who are at different stages of growth.\n' +
      '\n' +
      'The rate that you set here is your take-home rate. The client will pay rate that is 20% higher rate which means A.Team will collect 16.67% of the total payout. For example, if you set the rate to $100 the client will pay $120 and A.Team will collect $20 as fees';
    error && (tooltip = 'Please enter a minimum and maximum rate');
    return tooltip;
  }, [error, screenClass]);

  return (
    <Section
      iconType={type !== 'large' ? IconType.Banknotes : undefined}
      type={type}
      title="Rate"
      tooltipText={tooltipText}
      readonly={readOnly}
      isApplicationEdited={isApplicationEdited}
      error={error}
      guidanceTooltip={guidanceTooltip}
    >
      <div>
        {!readOnly &&
          showRateRangeToBuilders &&
          builderRateMin &&
          builderRateMax && (
            <>
              <div
                style={{
                  marginBottom: 8,
                }}
              >
                The preferred rate range for this mission is $
                {Math.round(builderRateMin)} - ${Math.round(builderRateMax)}
              </div>
              {guidanceMessage}
            </>
          )}
        <div className={styles.hourlyRateWrapper}>
          <div className={styles.hourlyRateLabelWrapper}>
            My hourly rate range is
          </div>
          <div className={styles.hourlyRateInputsWrapper}>
            <div
              className={styles.inputWrapper}
              style={{ marginRight: readOnly ? 0 : 16 }}
            >
              <span className={styles.prefix}>$</span>
              <TextInput
                variant="dashed"
                disabled={disabled}
                placeholder={type === 'large' ? 'Low' : 'XXX'}
                error={error}
                data-testing-id={'mission-application-min-rate-text-input'}
                className={styles.input}
                value={minRange || ''}
                readonly={readOnly}
                onChange={(e) => {
                  if (!isValid(e.target.value)) return;

                  onChange({
                    min: Number(e.target.value),
                    max: maxRange,
                  });
                }}
                onBlur={() => {
                  if (minRange && maxRange && minRange > maxRange) {
                    onChange({
                      min: minRange,
                      max: undefined,
                    });
                  }
                }}
              />
            </div>
            to
            <div
              className={styles.inputWrapper}
              style={{ marginLeft: readOnly ? 0 : 16 }}
            >
              <span className={styles.prefix}>$</span>
              <TextInput
                variant="dashed"
                disabled={disabled}
                placeholder={type === 'large' ? 'High' : 'XXX'}
                error={error}
                value={maxRange || ''}
                data-testing-id={'mission-application-max-rate-text-input'}
                className={styles.input}
                readonly={readOnly}
                onChange={(e) => {
                  if (!isValid(e.target.value)) return;

                  onChange({
                    min: minRange,
                    max: Number(e.target.value),
                  });
                }}
                onBlur={() => {
                  if (minRange && maxRange && minRange > maxRange) {
                    onChange({
                      min: undefined,
                      max: maxRange,
                    });
                  }
                }}
              />
            </div>
          </div>
          {lastHourlyRateRange ? (
            <div>
              (Previously ${lastHourlyRateRange.min}-$
              {lastHourlyRateRange.max})
            </div>
          ) : null}
          {isApplicationEdited ? (
            <Tag className={styles.tagEdited}>Edited</Tag>
          ) : null}
        </div>
      </div>
    </Section>
  );
};

export default observer(HourlyRateRangeV2);
