import { Checkbox, Select, Typography } from '@a_team/ui-components';
import { Breakpoints, SelectOption } from '@ateams/components';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import ErrorForm from '../common/ErrorForm';
import CommonStyles from '../common/styles';

const useStyles = createUseStyles({
  content: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
  },
  numberOfPeopleManagedSelect: {
    width: 80,
    display: 'inline-block',
    padding: 0,
    margin: '0 15px 0 15px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {},
});

const HasManagedPeople = (): JSX.Element => {
  const {
    getValues,
    register,
    unregister,
    trigger,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const commonStyles = CommonStyles();
  const styles = useStyles({});

  useEffect(() => {
    register('hasManagedPeople', {
      validate: (hasManagedPeople: boolean | undefined) => {
        const numberOfPeopleManaged = getValues('numberOfPeopleManaged');
        if (hasManagedPeople && !numberOfPeopleManaged) {
          return 'Number of people managed is required';
        }

        return true;
      },
    });

    return () => {
      unregister('hasManagedPeople');
    };
  }, [register, unregister]);

  const hasManagedPeople = watch('hasManagedPeople');
  const numberOfPeopleManaged = watch('numberOfPeopleManaged');

  const options = useMemo(() => {
    const options = Array.from({ length: 24 }, (_, k) => ({
      value: `${k + 1}`,
      label: `${k + 1}`,
    }));

    options.push({ value: '25+', label: '25+' });

    return options;
  }, []);

  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.title}>
        Did this role require you to manage team members? (Optional)
      </div>
      <div className={styles.content}>
        <Checkbox
          onChange={(e) => {
            setValue('hasManagedPeople', !hasManagedPeople);

            if (hasManagedPeople) {
              setValue('numberOfPeopleManaged', undefined);
            }
          }}
          checked={!!hasManagedPeople}
          label={
            <Typography
              variant={'textMedium'}
              weight={'light'}
              style={{ marginLeft: 8, fontSize: 15 }}
            >
              Yes, I managed
            </Typography>
          }
        />
        <Select
          styles={{
            control: (base) => ({
              ...base,
              border: '1px solid #DADADC',
              borderColor: '#DADADC !important',
              backgroundColor: 'white',
            }),
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 999,
            }),
            placeholder: (base) => ({
              ...base,
              color: '#62646A',
            }),
          }}
          className={styles.numberOfPeopleManagedSelect}
          placeholder="—"
          value={
            numberOfPeopleManaged
              ? { value: numberOfPeopleManaged, label: numberOfPeopleManaged }
              : null
          }
          onChange={(data) => {
            setValue('numberOfPeopleManaged', (data as SelectOption).value);
            trigger('hasManagedPeople');
            if (!hasManagedPeople) {
              setValue('hasManagedPeople', !hasManagedPeople);
            }
          }}
          options={options}
        />
        team members.
      </div>
      <ErrorForm field="hasManagedPeople" errors={errors} />
    </div>
  );
};

export default HasManagedPeople;
