import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  VettingProcessAudit,
  VettingProcessAuditTypes,
} from '@a_team/models/dist/vetting-process-audit';
import { ActivityRecordMainText, ActivityRecordUser } from '.';

export interface ProcessDeclinedByInterviewerActivityRecordProps {
  vettingProcessAudit: VettingProcessAudit<VettingProcessAuditTypes.ProcessDeclinedByInterviewer>;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const ProcessDeclinedByInterviewerActivityRecord: React.FC<
  ProcessDeclinedByInterviewerActivityRecordProps
> = ({ vettingProcessAudit }) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <ActivityRecordMainText>{`Interview request declined${
        vettingProcessAudit.user ? ' by' : ''
      }`}</ActivityRecordMainText>

      {vettingProcessAudit.user && (
        <ActivityRecordUser user={vettingProcessAudit.user} />
      )}
    </div>
  );
};
