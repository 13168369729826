import React from 'react';
import { Option } from '../utils';
import { createUseStyles } from 'react-jss';
import { BorderColors, Breakpoints } from '@ateams/components';
import Rating from './Rating';
import removeIcon from '../icons/remove.svg';
import { AnswerScore } from '@src/components/AnswerScore';
import { Tooltip } from '@a_team/ui-components';

interface SkillsRatingProps {
  selectedSkills: Option[];
  setSelectedSkills: (skills: Option[]) => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    border: `1px solid ${BorderColors.lighter}`,
    borderRadius: 8,
  },
  skill: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    fontWeight: 500,
    borderBottom: `1px solid ${BorderColors.lighter}`,
    flexDirection: 'column',
    gap: 12,
    '&:last-child': {
      borderBottom: 'none',
    },
    [`@media (min-width: ${Breakpoints.sm}px)`]: {
      flexDirection: 'row',
    },
  },
  skillContent: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    width: '100%',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    fontSize: 16,
    [`@media (min-width: ${Breakpoints.sm}px)`]: {
      width: 'auto',
      flexDirection: 'row',
      justifyContent: 'inherit',
      fontSize: 15,
    },
  },
  ratingContainer: {
    display: 'flex',
    gap: 8,
    width: '100%',
    [`@media (min-width: ${Breakpoints.sm}px)`]: {
      gap: 4,
      width: 'auto',
    },
  },
  removeIcon: {
    cursor: 'pointer',
    width: 16,
    height: 16,
  },
});

const SkillsRating: React.FC<SkillsRatingProps> = ({
  selectedSkills,
  setSelectedSkills,
}) => {
  const styles = useStyles();

  if (selectedSkills.length === 0) {
    return null;
  }

  const handleRemoveSkill = (skillToRemove: Option) => {
    setSelectedSkills(
      selectedSkills.filter((skill) => skill.value !== skillToRemove.value),
    );
  };

  const ratingValues: AnswerScore[] = [1, 2, 3, 4, 5];

  return (
    <div className={styles.container}>
      {selectedSkills.map((selectedSkill) => (
        <div key={selectedSkill.value} className={styles.skill}>
          <div className={styles.skillContent}>
            <Tooltip text="Remove skill" placement="top">
              <img
                src={removeIcon}
                alt="Remove"
                className={styles.removeIcon}
                onClick={() => handleRemoveSkill(selectedSkill)}
              />
            </Tooltip>
            <div>{selectedSkill.label}</div>
          </div>
          <div className={styles.ratingContainer}>
            {ratingValues.map((value) => (
              <Rating
                key={value}
                value={value}
                selectedSkill={selectedSkill}
                selectedSkills={selectedSkills}
                setSelectedSkills={setSelectedSkills}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkillsRating;
