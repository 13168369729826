import { Button, Modal } from '@a_team/ui-components';
import { Breakpoints, TextColors } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import completeSetupIllustration from './complete-setup-illustration.svg';

interface CompleteSetupModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
}

const useStyles = createUseStyles({
  modal: {
    textAlign: 'center',
    padding: 0,
    width: '680px !important',
    '& a': {
      color: '#6D00D7',
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },

  illustration: {
    width: 95,
  },

  title: {
    marginTop: 40,
    marginBottom: 8,
    fontSize: 24,
    fontWeight: 600,
  },

  desc: {
    maxWidth: 450,
    margin: 0,
    fontSize: 15,
    color: TextColors.lighter,
  },

  actions: {
    display: 'flex',
    gap: 12,
    justifyContent: 'center',
    '& > button': {
      height: 40,
    },
  },

  [`@media (min-width: ${Breakpoints.md}px)`]: {
    modal: {
      padding: 30,
    },
  },
});

const CompleteSetupModal = ({
  open,
  onClose,
  title,
  description,
}: CompleteSetupModalProps): JSX.Element => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <Modal
      variant="slideUp"
      isOpen={open}
      onClose={onClose}
      className={styles.modal}
      shouldHideGradientStroke={true}
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <img
            className={styles.illustration}
            src={completeSetupIllustration}
            alt="Complete setup illustration"
          />
          <h4 className={styles.title}>{title}</h4>
          <p className={styles.desc}>{description}</p>
        </div>

        <div className={styles.actions}>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              history.push(`/setup`);
            }}
          >
            Go to setup
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CompleteSetupModal;
