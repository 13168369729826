import { Modal } from '@a_team/ui-components';
import { CDN_BASE_URL } from '@src/config';
import { useQueryPendingUserRecommendations } from '@src/rq/teamUp';
import React from 'react';
import { createUseStyles } from 'react-jss';
import PendingInviteUser from './PendingInviteUser';
import { Breakpoints } from '@ateams/components';
import HideScrollerGlobally from '@src/components/HideScrollerGlobally';
import {
  RecommendationObject,
  RecommendationStatus,
} from '@a_team/models/dist/RecommendationObject';

interface TeamUpPendingInvitesModalProps {
  isOpen: boolean;
  missionId: string;
  onClose: () => void;
  reloadAppliedMissions?: boolean;
}

const useStyles = createUseStyles({
  modal: {
    width: '680px !important',
    '& > div:first-child': {
      padding: 24,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 24,
  },
  title: {
    marginTop: 0,
    marginBottom: 8,
    textAlign: 'center',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '26px',
  },
  desc: {
    maxWidth: 300,
    margin: 0,
    textAlign: 'center',
    fontSize: 14,
  },
  img: {
    aspectRatio: '1',
    width: 120,
    height: 'auto',
  },
  users: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    modal: {
      width: '680px !important',
      '& > div:first-child': {
        padding: 40,
      },
    },
  },
});

export const hasAppliedToSameRole = (
  recommendation: RecommendationObject,
): boolean => {
  const requestorAppliedRolesIds = new Set(
    recommendation.requestorAppliedRoles.map((role) => role.rid),
  );

  const requestedAppliedRolesIds = new Set(
    recommendation.requestedAppliedRoles?.map((role) => role.rid) || [],
  );

  if (requestorAppliedRolesIds.size !== requestedAppliedRolesIds.size) {
    return false;
  }

  for (const role of requestorAppliedRolesIds) {
    if (!requestedAppliedRolesIds.has(role)) {
      return false;
    }
  }

  return true;
};

const TeamUpPendingInvitesModal = ({
  isOpen,
  missionId,
  onClose,
  reloadAppliedMissions,
}: TeamUpPendingInvitesModalProps): JSX.Element | null => {
  const styles = useStyles();

  const { data } = useQueryPendingUserRecommendations({
    missionId,
  });

  const hasPendingInvites =
    data?.some(
      (recommendation) =>
        recommendation.status === RecommendationStatus.Pending &&
        !hasAppliedToSameRole(recommendation),
    ) || false;

  return (
    <Modal
      shouldHideGradientStroke
      isOpen={isOpen}
      onClose={onClose}
      variant="slideUp"
      className={styles.modal}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <img
            src={`${CDN_BASE_URL}/website/paper-plane.jpg`}
            alt="Team up pending invites"
            className={styles.img}
          />
          <div>
            <h4 className={styles.title}>
              {hasPendingInvites
                ? 'Pending Invites'
                : 'No more pending invites'}
            </h4>
            <p className={styles.desc}>
              {hasPendingInvites
                ? `Team up with builders you like to work with to increase your
              chances of being selected.`
                : 'You have replied to all team up invites.'}
            </p>
          </div>
        </div>
        <div className={styles.users}>
          {hasPendingInvites &&
            data?.map((recommendation) => {
              return (
                <PendingInviteUser
                  key={recommendation.rid + recommendation.status}
                  recommendation={recommendation}
                  reloadAppliedMissions={reloadAppliedMissions}
                />
              );
            })}
        </div>
      </div>
      {isOpen && <HideScrollerGlobally />}
    </Modal>
  );
};

export default TeamUpPendingInvitesModal;
