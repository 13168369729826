import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { Colors, Icon, IconType, TextColors } from '@ateams/components';
import Dropdown from '@src/components/Dropdown/index';

interface Props {
  className?: string;
  onClick?(e: React.SyntheticEvent): void;
  children: ReactNode;
  to?: string;
  nestedItems?: ReactNode[]; // DropDownItem[]
  style?: CSSProperties;
  danger?: boolean;
  disabled?: boolean;
}

const userStyles = createUseStyles({
  dropdown: {
    position: 'absolute',
    zIndex: 1000,
    left: '100%',
    top: 0,
    maxHeight: '80vh',
    display: 'none',
  },
  root: (props: Props) => ({
    display: 'flex',
    cursor: props?.disabled ? 'not-allowed' : 'pointer',
    position: 'relative',
    border: 'none',
    padding: '1em',
    margin: 0,
    fontSize: '1em',
    background: 'none',
    width: '100%',
    textAlign: 'left',
    textDecoration: 'none',
    color: TextColors.regular,
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...(props?.disabled
      ? {}
      : {
          '&:hover, &:focus': {
            background: Colors.primaryVeryLight,
            color: Colors.primary,
            '& > $dropdown': {
              display: 'block',
            },
          },
        }),
    '&.danger': {
      color: Colors.danger,
    },
  }),
});

const ArrowIcon = () => <Icon type={IconType.ArrowRight} size={'xsmall'} />;

const NestedDropdown = (props: Pick<Props, 'nestedItems'>) => {
  const styles = userStyles();

  return (
    <>
      <ArrowIcon />
      <Dropdown className={styles.dropdown} open>
        {props.nestedItems}
      </Dropdown>
    </>
  );
};

export default function DropdownItem(props: Props): ReactElement | null {
  const { className, onClick, children, nestedItems, to, style, danger } =
    props;
  const styles = userStyles(props);
  const showNestedItems = nestedItems && nestedItems.length > 0;
  const rootClass = cx(styles.root, className, { danger });

  if (to) {
    return (
      <Link to={to} style={style} className={rootClass} onClick={onClick}>
        <>
          {children}
          {showNestedItems && <NestedDropdown nestedItems={nestedItems} />}
        </>
      </Link>
    );
  }

  if (onClick) {
    return (
      <>
        <button
          style={style}
          type="button"
          className={rootClass}
          onClick={(e): void => {
            e.stopPropagation();
            onClick(e);
          }}
        >
          {children}
          {showNestedItems && <NestedDropdown nestedItems={nestedItems} />}
        </button>
      </>
    );
  }

  return (
    <div style={style} className={rootClass}>
      {children}
      {showNestedItems && <NestedDropdown nestedItems={nestedItems} />}
    </div>
  );
}
