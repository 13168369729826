import { useAnalytics } from '@ateams/analytics/dist/platform';
import { apiUsers } from '@ateams/api';
import { useStores } from '@src/stores';
import { useMutation } from '@tanstack/react-query';

export const useSendMessage = () => {
  const stores = useStores();

  const analytics = useAnalytics();

  return useMutation({
    mutationFn: async (payload: {
      username: string;
      message: string;
      recaptchaToken: string;
      email?: string;
      fullName?: string;
    }) =>
      apiUsers.messageUser(stores.auth, payload.username, {
        message: payload.message,
        recaptchaToken: payload.recaptchaToken,
        ...(payload.email &&
          payload.fullName && {
            email: payload.email,
            fullName: payload.fullName,
          }),
      }),
    onSuccess: () => {
      if (stores.auth.user) {
        analytics.trackMessageSent(stores.auth.user);
      }
    },
  });
};

export const useSendSupportMessage = () => {
  const stores = useStores();
  const analytics = useAnalytics();

  return useMutation({
    mutationFn: async (payload: { message: string; recaptchaToken: string }) =>
      apiUsers.contactSupport(stores.auth, {
        message: payload.message,
        recaptchaToken: payload.recaptchaToken,
      }),
    onSuccess: () => {
      if (stores.auth.user) {
        analytics.trackSupportMessageSent(stores.auth.user);
      }
    },
  });
};
