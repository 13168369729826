import { Modal } from '@a_team/ui-components';
import { Colors, TextColors } from '@ateams/components';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import icon from './icon.svg';

interface PaymentAccountTemporarilySuspendedModalProps {
  onClose(): void;
  open: boolean;
}

const useStyles = createUseStyles({
  container: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 600,
    marginTop: 40,
    marginBottom: 10,
  },
  mainContent: {
    textAlign: 'center',
    maxWidth: '490px',
    color: TextColors.lighter,
    lineHeight: '1.5',
    marginTop: 8,
  },

  link: {
    color: Colors.secondaryDark,
  },
});

const PaymentAccountTemporarilySuspendedModal = ({
  open,
  onClose,
}: PaymentAccountTemporarilySuspendedModalProps): ReactElement => {
  const styles = useStyles();

  return (
    <Modal
      variant="slideUp"
      isOpen={open}
      onClose={onClose}
      shouldHideGradientStroke={true}
      styles={{ width: '600px !important', borderRadius: '8px !important' }}
    >
      <div className={styles.container}>
        <img src={icon} alt="icon" width={120} />
        <div className={styles.title}>Temporarily suspended account</div>
        <div className={styles.mainContent}>
          Your payments account is temporarily suspended, please reach out to{' '}
          <span className={styles.link}>payments@a.team</span> to resolve the
          issue.
        </div>
      </div>
    </Modal>
  );
};

export default PaymentAccountTemporarilySuspendedModal;
