export enum BillingPaymentDue {
  Net0 = 'Net0',
  Net15 = 'Net15',
  Net30 = 'Net30',
  Net45 = 'Net45',
  Net60 = 'Net60',
  Net90 = 'Net90',
  Net120 = 'Net120',
}

export interface BillingPaymentTerms {
  due: BillingPaymentDue;
}
