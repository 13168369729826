import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from './keys';
import { apiHubspot } from '@ateams/api';
import { useStores } from '@src/stores';

export const useDeal = (dealId: string) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.hubspot.byDealId(dealId),
    queryFn: () => {
      return apiHubspot.adminGetDealById(auth, dealId);
    },
    enabled: auth.isAdmin && Boolean(dealId),
  });
};

export const useUpdateMissionPostedDate = () => {
  const { auth } = useStores();

  return useMutation({
    mutationFn: (roleIds: string[]) => {
      return apiHubspot.updateMissionPostedDate(auth, { roleIds });
    },
  });
};
