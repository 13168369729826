import React, { ReactElement } from 'react';
import { TagList } from '../Tag/TagList';
import Tag from '../Tag';
import { SelectOption } from './index';

interface MultiValueListProps<T extends SelectOption = SelectOption> {
  values: T[];
  onRemove: (option: T) => void;
  className?: string;
}

export const MultiValueList = <T extends SelectOption = SelectOption>(
  props: MultiValueListProps<T>,
): ReactElement => {
  const { values, onRemove, className } = props;

  return (
    <TagList className={className}>
      {values.map((val) => (
        <Tag key={val.value} onRemove={() => onRemove(val)}>
          {val.label}
        </Tag>
      ))}
    </TagList>
  );
};
