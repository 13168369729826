import React, { ReactElement, ReactNode, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  BasicUserObject,
  AnonymousUserObject,
} from '@a_team/models/dist/UserObject';
import UserCard from '@src/components/UserCard';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import useDebounceState from '@src/hooks/useDebounceState';

interface Props {
  children: ReactNode;
  user: BasicUserObject | AnonymousUserObject;
}

const useStyles = createUseStyles({
  root: { position: 'relative' },
  tooltip: {
    position: 'absolute',
    top: '-308px',
    left: 'calc(50% - 120px)',
    zIndex: 1000,
    cursor: 'default',
  },
});

const HoverUserCard = observer((props: Props): ReactElement => {
  const styles = useStyles();
  const [hovering, setHovering] = useState(false);
  const [hoverOutTimeout, sethoverOutTimeout] = useState<number | undefined>();

  const {
    auth,
    users: { updatCurrentUserCard, userToInteract },
  } = useStores();

  const [creatorTooltip, setCreatorTooltip] = useDebounceState<boolean>(
    false,
    (creatorTooltip) =>
      creatorTooltip
        ? updatCurrentUserCard(props.user as BasicUserObject, auth)
        : undefined,
    300,
  );

  const isAnonymous = props.user.username == null || !auth.verified;

  function hoverOut(): void {
    sethoverOutTimeout(
      window.setTimeout(function () {
        setCreatorTooltip(false);
        updatCurrentUserCard(null);
      }, 300),
    );
  }

  function stopHoverOut(): void {
    clearTimeout(hoverOutTimeout);
  }

  const handleHoverContainer = (enter: boolean): void => {
    if (enter) {
      setCreatorTooltip(true);
      stopHoverOut();
    } else if (!enter && !hovering) {
      hoverOut();
    }
  };

  return isAnonymous ? (
    <div>{props.children}</div>
  ) : (
    <div
      onMouseEnter={(): void => handleHoverContainer(true)}
      onMouseLeave={(): void => handleHoverContainer(false)}
      className={styles.root}
      onClick={(e): void => e.stopPropagation()}
    >
      {creatorTooltip && (
        <div
          className={styles.tooltip}
          onMouseEnter={(): void => setHovering(true)}
          onMouseLeave={(): void => {
            setHovering(false);
            setCreatorTooltip(false);
          }}
        >
          {userToInteract && <UserCard user={userToInteract} minimal />}
        </div>
      )}
      {props.children}
    </div>
  );
});

export default HoverUserCard;
