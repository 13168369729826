import Modal from '@src/components/Modal';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { TeamPulse } from '@a_team/models/dist/TeamPulse';
import useMediaQuery from '@src/hooks/useMediaQuery';
import { Breakpoints } from '@ateams/components';

interface TeamPulseSharedFeedbackModalProps {
  teamPulse: TeamPulse;
  onClose: () => void;
}

const useStyles = createUseStyles({
  '@global': {
    'html, body': {
      overflow: 'hidden',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    margin: 0,
    fontSize: '20px',
  },
  desc: {
    margin: 0,
    color: '#707275',
  },
  feedbackCardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
});

const TeamPulseSharedFeedbackModal = ({
  onClose,
  teamPulse,
}: TeamPulseSharedFeedbackModalProps): JSX.Element => {
  const styles = useStyles();
  const isDesktop = useMediaQuery(`(min-width: ${Breakpoints.md}px)`);

  return (
    <Modal
      onClose={onClose}
      open={true}
      hideCloseButton={false}
      style={{
        width: '100%',
        maxWidth: isDesktop ? '680px' : '100%',
        maxHeight: isDesktop ? 'calc(100% - 48px)' : '100%',
        height: isDesktop ? '600px' : '100%',
        outline: 'none',
      }}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <h3 className={styles.title}>
            Your team mates shared feedback with you
          </h3>
          <p className={styles.desc}>
            This feedback is a part of the team review.
          </p>
        </div>
        <div className={styles.feedbackCardsContainer}>
          {teamPulse?.sharedResponses?.map((response) => {
            return (
              <FeedbackCard
                firstName={response.firstName}
                lastName={response.lastName}
                avatarUrl={response.pictureURL}
                feedback={response.whatYouLikedWhatCouldBeImproved}
              />
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default TeamPulseSharedFeedbackModal;

const useFeedbackCardStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #DADADC;',
    borderRadius: '8px',
    padding: '16px',
    gap: '16px',
  },
  title: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '22px',
    margin: 0,
  },
  userAvatarContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    fontSize: '14px',
  },
  userAvatar: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
  },
});

interface FeedbackCardProps {
  firstName: string;
  lastName: string;
  avatarUrl: string;
  feedback: string;
}

const FeedbackCard = ({
  firstName,
  lastName,
  avatarUrl,
  feedback,
}: FeedbackCardProps): JSX.Element => {
  const styles = useFeedbackCardStyles();
  return (
    <div className={styles.container}>
      <div className={styles.userAvatarContainer}>
        <img className={styles.userAvatar} src={avatarUrl} alt="user avatar" />
        <span>{`${firstName} ${lastName}`}</span>
      </div>
      <h5 className={styles.title}>
        What have you liked about teaming up with {firstName} — and what could
        be improved?
      </h5>
      <div>{feedback}</div>
    </div>
  );
};
