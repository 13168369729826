import { teamEngineSearchServiceApi } from '@src/logic/services/endpoints';
import {
  v1EntityPage,
  v1IndustryListFilterApi,
} from '@a_team/team-engine-search-service-sdk-js/dist/schema';
import { getAuthToken } from '@src/helpers/auth';

interface Params {
  filter?: v1IndustryListFilterApi;
  page?: v1EntityPage;
  applicationName?: string;
}

export interface Industry {
  id: string;
  name: string;
  description: string;
}

export async function fetchIndustryList(params: Params): Promise<Industry[]> {
  const { filter, applicationName } = params;
  const { size = 1000, current } = params.page ?? {};

  const { data, errors } = await teamEngineSearchServiceApi.query(
    {
      v1ListIndustries: [
        {
          applicationName,
          page: { size, current },
          filter,
        },
        { industries: { _id: 1, name: 1, description: 1 } },
      ],
    },
    { headers: { Authorization: getAuthToken() } },
  );

  if (errors) {
    console.error(`[fetchIndustryList]: ${JSON.stringify(errors)}`);
    return [];
  }

  const items = data?.v1ListIndustries?.industries ?? [];

  return (
    items?.map((industry) => {
      const { _id, name, description = '' } = industry;
      return {
        id: _id,
        name,
        description,
      };
    }) ?? []
  );
}

export async function getIndustrySelectOptions(params: Params) {
  const items = await fetchIndustryList(params);
  return (
    items?.map((industry) => {
      const { id, name, description } = industry;
      return {
        value: id,
        label: name,
        description: description ?? '',
      };
    }) ?? []
  );
}
