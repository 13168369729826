import React, {
  ReactElement,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import Modal from '..';
import Sidebar from '@src/views/Profile/Sidebar';
import Main from '@src/views/Profile/Main';
import { Header } from '@src/components/Modal/ApplicationModal/Header';
import Profile from '@src/stores/Profile/Profile';
import { TeammateCardObject, UserId } from '@a_team/models/dist/UserObject';
import { CardNavigationButton } from '@src/components/Modal/ApplicationModal/CardNavigationButton';
import Loader from '@src/components/Loader';
import LabeledCheckboxInput from '@src/components/Inputs/LabeledCheckboxInput';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import useToggle from '@src/hooks/useToggle';
import AdminNotesMain from '@src/views/AdminNotes/Main';
import AdminNotesSidebar from '@src/views/AdminNotes/Sidebar';
import { ProfileViewMode } from '@src/stores/Profile/models';
import {
  MissionApplicationManuallyProposedData,
  MissionApplicationStatusUpdate,
} from '@ateams/api/dist/endpoints/Missions';
import { MissionApplicationId } from '@a_team/models/dist/MissionApplicationObject';
import { ProposedDetailsModal } from '@src/components/Modal/ApplicationModal/ProposedDetailsModal';
import { MissingRequirements } from '@src/views/Profile/Sidebar/MissingRequirements';
import { useStores } from '@src/stores';
import { apiMissions } from '@ateams/api';
import { queryKeys } from '@src/rq/keys';
import { useQuery } from '@tanstack/react-query';
import { ApplicationPotentialTeamsAndExtensions } from '@src/views/Profile/Sidebar/ApplicationPotentialTeams';
import Mission from '@src/stores/Missions/Mission';
import { SuggestedTeamWithApplications } from '@src/views/Mission/Proposal/utils';
import { EvaluationScoresBanner } from '@src/views/Profile/Sidebar/EvaluationScoresBanner';
import { useGetApplicantFlags } from '@src/rq/profile';
import { BuilderFlagsBanner } from '@src/views/Profile/Sidebar/BuilderFlagsBanner';
import { Icon, IconType, Spacing } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import ConnectionsPanel from './ConnectionsPanel';

interface Props {
  open: boolean;
  onClose(): void;
  profile?: Profile;
  onStatusChanged?(
    aid: MissionApplicationId,
    data: MissionApplicationStatusUpdate,
  ): Promise<void>;
  onManuallyProposedDetailsChange?(
    aid: MissionApplicationId,
    data: MissionApplicationManuallyProposedData,
  ): Promise<void>;
  onRequest?: (
    uid: UserId,
    requested: boolean,
    placement: 'card' | 'modal',
  ) => void;
  requested?: boolean;
  onNavigationClick?: (uid: UserId) => void;
  prevCard?: TeammateCardObject;
  nextCard?: TeammateCardObject;
  widthConnectionsPanel?: boolean;
  onToggleMissionNotification?(toSend: boolean): void;
  currentMission?: Mission;
  precomputedTeams?: SuggestedTeamWithApplications[];
  extensions?: SuggestedTeamWithApplications[];
}

const useStyles = createUseStyles({
  applicationInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.small,
    position: 'absolute',
    right: Spacing.xxLarge,
    top: Spacing.large,
    zIndex: 10000,
  },
  arrowButton: {
    cursor: 'pointer',
  },
});

const ApplicationModal = (props: Props): ReactElement => {
  const styles = useStyles();
  const {
    onClose,
    open,
    onStatusChanged,
    profile,
    onRequest,
    prevCard,
    nextCard,
    onNavigationClick,
    requested,
    widthConnectionsPanel,
    onToggleMissionNotification,
  } = props;
  const { missions, auth } = useStores();
  const [proposedModalOpen, setProposedModalOpen] = useToggle(false);
  const [loading, setLoading] = useLoadingState();
  const [shouldSendMissionNotification, toggleShouldSendMissionNotification] =
    useToggle();
  const currentMission = missions.currentMission;

  const [applicationIndex, setApplicationIndex] = useState(0);

  const sortedApplications = useMemo(() => {
    return currentMission?.sortedApplicationsByRoleId?.get(
      missions.currentApplication?.application?.rid || '',
    );
  }, [currentMission?.sortedApplicationsByRoleId]);

  useEffect(() => {
    if (
      sortedApplications &&
      sortedApplications.length > 0 &&
      missions.currentApplication?.data?.uid &&
      open &&
      !loading
    ) {
      const currentApplicationIndex = sortedApplications.findIndex(
        (application) =>
          application.user.uid === missions.currentApplication?.data.uid,
      );

      if (currentApplicationIndex === -1) return;

      setApplicationIndex(currentApplicationIndex);
    }
  }, [sortedApplications, missions.currentApplication, open, loading]);

  const onPreviousApplication = async () => {
    const prevIndex = applicationIndex - 1;

    if (prevIndex < 0) return;

    const prevApplication = sortedApplications?.[prevIndex];

    if (!prevApplication) return;

    if (!auth.user) return;

    await missions.getApplicationProfile(
      prevApplication.user.username,
      prevApplication,
      prevApplication.rid,
    );
  };

  const onNextApplication = async () => {
    const nextIndex = applicationIndex + 1;

    if (nextIndex >= (sortedApplications?.length || 0)) return;

    const nextApplication = sortedApplications?.[nextIndex];

    if (!nextApplication) return;

    if (!auth.user) return;

    await missions.getApplicationProfile(
      nextApplication.user.username,
      nextApplication,
      nextApplication.rid,
    );
  };

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (!open) return;

      if (profile?.mode === ProfileViewMode.Admin) return;

      if (event.key === 'ArrowLeft') {
        onPreviousApplication();
      } else if (event.key === 'ArrowRight') {
        onNextApplication();
      }
    },
    [open, onPreviousApplication, onNextApplication],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const saveNotes = () => {
    profile?.didAvailabilityChange &&
      setLoading(
        profile.updateAvailability(true).then(() => {
          profile?.setProfileMode(ProfileViewMode.View);
        }),
      );
  };

  const missingRequirementsQuery = useQuery({
    enabled: !!profile?.application?.aid,
    queryKey: queryKeys.missions.missionApplicationMissingRequirements(
      profile?.application?.aid,
    ),
    queryFn: () =>
      profile?.application?.aid
        ? apiMissions.getMissionApplicationMissingRequirements(
            auth,
            profile.application?.aid,
          )
        : Promise.resolve(null),
  });

  const { data: applicantFlags, isLoading: applicantFlagsLoading } =
    useGetApplicantFlags(profile?.data.uid || '');

  useEffect(() => {
    toggleShouldSendMissionNotification(!!profile?.isTeamGraphPreview);
  }, [profile]);

  useEffect(() => {
    if (onToggleMissionNotification) {
      onToggleMissionNotification(shouldSendMissionNotification);
    }
  }, [shouldSendMissionNotification]);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        style={{
          width: '90%',
          height: '90%',
          padding: 0,
          transition: 'all 0.5s',
          zIndex: 10000,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        {sortedApplications && (
          <div className={styles.applicationInfo}>
            <Icon
              type={IconType.ArrowRight}
              style={{ transform: 'scaleX(-1)' }}
              className={styles.arrowButton}
              onClick={onPreviousApplication}
            />
            <span>
              Application {applicationIndex + 1} of {sortedApplications?.length}
            </span>
            <Icon
              type={IconType.ArrowRight}
              className={styles.arrowButton}
              onClick={onNextApplication}
            />
          </div>
        )}

        {onRequest && (
          <Header
            applicationPreview={profile}
            onRequest={onRequest}
            onClose={onClose}
            requested={requested}
          />
        )}
        <div>
          {profile && missingRequirementsQuery.data ? (
            <MissingRequirements
              applicationMissingRequirementsResponse={
                missingRequirementsQuery.data
              }
              profile={profile.data}
              application={profile?.application}
            />
          ) : null}
          {profile?.vettingScores ? (
            <EvaluationScoresBanner scores={profile.vettingScores} />
          ) : null}
          {applicantFlags && !applicantFlagsLoading ? (
            <BuilderFlagsBanner
              customTags={profile?.data.customTags}
              flags={applicantFlags}
            />
          ) : null}
          {profile && props.currentMission ? (
            <ApplicationPotentialTeamsAndExtensions
              extensions={props.extensions}
              currentMission={props.currentMission}
              precomputedTeams={props.precomputedTeams}
            />
          ) : null}

          <div
            style={{
              display: 'flex',
              height: onNavigationClick ? '80%' : '100%',
              overflow: 'auto',
              transform: 'perspective(1px)',
            }}
          >
            {profile ? (
              <>
                <div
                  style={{
                    minWidth: 400,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {profile.mode === ProfileViewMode.Admin ? (
                    <AdminNotesSidebar profile={profile} onSave={saveNotes} />
                  ) : (
                    <>
                      <Sidebar
                        profile={profile}
                        linkToProfile
                        setProposedModalOpen={setProposedModalOpen}
                        widthConnectionsPanel={widthConnectionsPanel || false}
                        onStatusChanged={onStatusChanged}
                      />
                      {profile.isTeamGraphPreview !== null ? (
                        <div style={{ padding: '0 40px 40px 40px' }}>
                          <LabeledCheckboxInput
                            margin={'none'}
                            label="Send Mission Notification"
                            checked={shouldSendMissionNotification}
                            disabled={loading === true}
                            onChange={toggleShouldSendMissionNotification}
                          />
                        </div>
                      ) : (
                        widthConnectionsPanel && (
                          <ConnectionsPanel
                            username={profile.data.username}
                            aid={profile?.application?.aid || null}
                            mid={profile?.application?.mid}
                          />
                        )
                      )}
                    </>
                  )}
                </div>
                <div style={{ flexGrow: 2, padding: 40 }}>
                  {profile.mode === ProfileViewMode.Admin ? (
                    <AdminNotesMain
                      profile={profile}
                      style={{ background: 'none' }}
                    />
                  ) : (
                    <Main
                      profile={profile}
                      style={{ background: 'none' }}
                      skipScrollToTop
                    />
                  )}
                </div>
              </>
            ) : (
              <Loader />
            )}
          </div>
          {onNavigationClick && (
            <div
              style={{
                background: '#F7F7F7',
                height: '10%',
                position: 'relative',
              }}
            >
              {prevCard && (
                <CardNavigationButton
                  card={prevCard}
                  direction={'prev'}
                  onClick={onNavigationClick}
                />
              )}
              {nextCard && (
                <CardNavigationButton
                  card={nextCard}
                  direction={'next'}
                  onClick={onNavigationClick}
                />
              )}
            </div>
          )}
        </div>
      </Modal>
      <ProposedDetailsModal
        defaultBuilderRate={
          profile?.application?.data.proposedRates?.builderHourlyRate ||
          profile?.application?.data.hourlyRateRange?.max
        }
        defaultMargin={profile?.application?.roleMarginPercent}
        isOpen={proposedModalOpen}
        onClose={setProposedModalOpen}
        onSubmit={(data) =>
          profile?.application?.aid && props.onManuallyProposedDetailsChange
            ? props.onManuallyProposedDetailsChange(
                profile.application.aid,
                data,
              )
            : Promise.reject()
        }
      />
      <LoadingIndicator loading={loading} />
    </>
  );
};

export default observer(ApplicationModal);
