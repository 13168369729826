import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import VettingDashboardLayout from '@src/layouts/Vetting';
import LoadingIndicator from '@src/components/LoadingIndicator';
import {
  OnSubmitSelectionTeamOnboardingPlanForm,
  SelectionTeamOnboardingPlanForm,
} from './form';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export const SelectionTeamOnboardingPlan: React.FC = observer(() => {
  const styles = useStyles();
  const { selectionTeamOnboarding } = useStores();
  const [formOpenedAt, setFormOpenedAt] = useState(new Date());
  const [loadingCurrentPlan, setLoadingCurrentPlan] = useLoadingState();
  const [loadingSubmit, setLoadingSubmit] = useLoadingState();

  useEffect(() => {
    setLoadingCurrentPlan(
      selectionTeamOnboarding.getCurrentSelectionTeamOnboardingPlan(),
      'Selection team onboarding plan loaded',
    );
  }, []);

  const onSubmit: OnSubmitSelectionTeamOnboardingPlanForm = (milestones) => {
    setLoadingSubmit(
      (async () => {
        try {
          await selectionTeamOnboarding.replaceSelectionTeamOnboardingPlan(
            milestones,
          );
          setFormOpenedAt(new Date());
        } catch (err) {
          console.error(
            'Failed to submit selection team onboarding plan 💩',
            err,
          );

          throw err;
        }
      })(),
      'Selection team onboarding plan saved',
    );
  };

  const isLoadingCurrentPlan = loadingCurrentPlan === true;

  return (
    <VettingDashboardLayout
      title={'Selection Team Onboarding'}
      contentClassName={styles.container}
    >
      {!isLoadingCurrentPlan && (
        <SelectionTeamOnboardingPlanForm
          defaultValues={
            selectionTeamOnboarding.currentSelectionTeamOnboardingPlan
          }
          onSubmit={onSubmit}
          key={formOpenedAt.getTime()}
        />
      )}

      <LoadingIndicator loading={loadingCurrentPlan} />
      <LoadingIndicator loading={loadingSubmit} />
    </VettingDashboardLayout>
  );
});
