import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import AdminDashboardLayout from '@src/layouts/Admin';
import TopBar from './TopBar';
import { observer } from 'mobx-react';
import AdminTeamWorkNavBar from './NavBar';
import { useHistory, useLocation } from 'react-router';
import {
  AdminTeamWorkMissionsLocation,
  AdminTeamWorkSearchResults,
} from '@src/locations';

interface Props {
  children: ReactNode;
}

const useStyles = createUseStyles({
  tabsArea: {
    alignItems: 'center',
    marginBottom: 30,

    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    paddingLeft: '56px',
    paddingRight: '56px',
    paddingTop: '20px',
    paddingBottom: '40px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    content: {
      paddingLeft: '56px',
      paddingRight: '56px',
      paddingTop: '20px',
      paddingBottom: '40px',
    },
  },
});

function AdminTeamWorkView(props: Props): ReactElement {
  const styles = useStyles();
  const [stickyOffset, setStickyOffset] = useState(0);
  const [sticky, setSticky] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchQuery = params.get('query') || undefined;
  const isExtendedSearch = params.get('extendedSearch') || undefined;
  const { children } = props;

  const handleScroll = (): void => {
    window.pageYOffset >= stickyOffset ? setSticky(true) : setSticky(false);
  };

  const onSearch = (query: string, extendedSearch?: boolean) => {
    if (query) {
      const queryStr = new URLSearchParams({
        query,
        extendedSearch: extendedSearch ? extendedSearch.toString() : '',
      }).toString();

      history.push({
        pathname: AdminTeamWorkSearchResults,
        search: query ? `?${queryStr}` : '',
      });
    } else {
      history.push(AdminTeamWorkMissionsLocation);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return (): void => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [stickyOffset]);

  return (
    <AdminDashboardLayout title="Admin Team Work" style={{ padding: 0 }}>
      <TopBar
        searchQuery={searchQuery}
        isExtendedSearch={isExtendedSearch === 'true'}
        onSearch={onSearch}
        onReset={() => console.info('hi')}
        onNew={(): void => console.info('hi')}
        sticky={sticky}
        setStickyOffset={(val: number): void => setStickyOffset(val)}
      />
      <div className={styles.content}>
        <div className={styles.tabsArea}>
          {!searchQuery && <AdminTeamWorkNavBar />}
        </div>
        {children}
      </div>
    </AdminDashboardLayout>
  );
}

export default observer(AdminTeamWorkView);
