import React, { CSSProperties, ReactElement } from 'react';
import ReactImageFallback from 'react-image-fallback';

const WHITE_PIXEL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=';

interface Props {
  className?: string;
  style?: CSSProperties;
  src?: string;
  alt?: string;
  fallbackImage?: string | ReactElement;
  initialImage?: string;
}

// >>> Temporary fix to avoid warning. Check this: https://github.com/socialtables/react-image-fallback/issues/49
const UNSAFE_LIFECYCLE_METHOD_NAMES = [
  'componentWillMount',
  'componentWillReceiveProps',
  'componentWillUpdate',
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function renameUnsafeLifecycleMethods(Component: any) {
  const { prototype } = Component;

  UNSAFE_LIFECYCLE_METHOD_NAMES.forEach((methodName) => {
    if (!prototype[methodName]) return;

    Object.defineProperty(prototype, `UNSAFE_${methodName}`, {
      configurable: true,
      value: prototype[methodName],
      writable: true,
    });
    Reflect.deleteProperty(prototype, methodName);
  });

  return Component;
}

const ImageFallbackRenamed = renameUnsafeLifecycleMethods(ReactImageFallback);

// <<< Temporary fix to avoid warning. Check this: https://github.com/socialtables/react-image-fallback/issues/49

export default function ImageFallback(props: Props): ReactElement {
  const { src, fallbackImage, initialImage, ...left } = props;

  return (
    <ImageFallbackRenamed
      src={src || fallbackImage || WHITE_PIXEL}
      fallbackImage={fallbackImage || WHITE_PIXEL}
      initialImage={initialImage || WHITE_PIXEL}
      {...left}
    />
  );
}
