import NotificationBannerV2, {
  NotificationBannerV2Props,
} from '@src/components/NotificationBannerV2';
import React from 'react';
import { useLocation } from 'react-router-dom';

function DisplayNotificationBannerFromLocationState() {
  const location = useLocation<NotificationBannerV2Props>();
  const state = location.state;

  if (
    !state ||
    !state.profilePictureURLs ||
    !state.title ||
    !state.description
  ) {
    return null;
  }

  return <NotificationBannerV2 {...state} showCloseButton />;
}

export default DisplayNotificationBannerFromLocationState;
