import React, { ReactElement, useMemo, useState } from 'react';
import AdminNotesObject from '@a_team/models/dist/AdminNotesObject';
import ApplicationItem from './ApplicationItem';
import LabeledCheckboxInput from '@src/components/Inputs/LabeledCheckboxInput';
import { createUseStyles } from 'react-jss';
import { BorderColors, FontSizes, Spacing } from '@ateams/components';
import UserObject from '@a_team/models/dist/UserObject';

interface Props {
  applications: AdminNotesObject['applications'];
  user?: UserObject;
}

const useStyles = createUseStyles({
  optionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.medium,
    paddingTop: Spacing.large,
  },
});

const ApplicationsTab = (props: Props): ReactElement => {
  const styles = useStyles();
  const { applications, user } = props;
  const [order, setOrder] = useState(-1);
  const [showApplicationText, setShowApplicationText] = useState(false);
  const [showRoleDescription, setShowRoleDescription] = useState(false);

  const sortedItems = useMemo(() => {
    return applications.items
      .slice()
      .sort(
        (a, b) =>
          order * String(a.createdAt).localeCompare(String(b.createdAt)),
      );
  }, [order, applications]);

  return (
    <>
      <div
        style={{
          marginBottom: 40,
          borderBottom: `1px dashed ${BorderColors.light}`,
          paddingBottom: Spacing.medium,
        }}
      >
        <strong
          style={{ marginRight: Spacing.large, fontSize: FontSizes.large }}
        >
          Applications ({sortedItems.length})
        </strong>
        <div className={styles.optionsContainer}>
          <LabeledCheckboxInput
            label={'Reversed'}
            margin={'none'}
            checked={order === 1}
            onChange={() => setOrder((current) => current * -1)}
          />
          <LabeledCheckboxInput
            label={'Show role description'}
            margin={'none'}
            checked={showRoleDescription}
            onChange={() => setShowRoleDescription((current) => !current)}
          />
          <LabeledCheckboxInput
            label={'Show Application Text'}
            margin={'none'}
            checked={showApplicationText}
            onChange={() => setShowApplicationText((current) => !current)}
          />
        </div>
      </div>

      <ul
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 40,
          padding: 0,
        }}
      >
        {sortedItems.map((item) => (
          <ApplicationItem
            item={item}
            key={item.aid}
            user={user}
            showApplicationText={showApplicationText}
            showRoleHeadline={showRoleDescription}
          />
        ))}
      </ul>
    </>
  );
};

export default ApplicationsTab;
