import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  percent: number;
  size?: number;
  className?: string;
}

const usePercentageIndicatorStyles = createUseStyles({
  percentLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    alignSelf: 'center',
    justifySelf: 'center',
    position: 'relative',
    zIndex: 10,
    fontWeight: 600,
  },
});

const PercentageIndicator: FC<Props> = ({ percent, size = 50, className }) => {
  const radius = (size - 4) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = ((100 - percent) / 100) * circumference;
  const isGoodScore = percent >= 70;
  const background = isGoodScore ? '#FBF6FF' : '#FFFBF8';
  const strokeColor = isGoodScore ? '#A54CFF' : '#FF7A00';
  const fontColor = isGoodScore ? '#A54CFF' : '#FF7A00';
  const styles = usePercentageIndicatorStyles();

  return (
    <div
      style={{
        position: 'relative',
        width: size,
        minHeight: size,
      }}
      className={className}
    >
      <svg
        width={size}
        height={size}
        style={{ transform: 'rotate(-90deg)', position: 'absolute' }}
      >
        <circle
          stroke={strokeColor}
          fill={background}
          strokeWidth="4"
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset: offset }}
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
      </svg>
      <div className={styles.percentLabel} style={{ color: fontColor }}>
        {percent}
      </div>
    </div>
  );
};

export default PercentageIndicator;
