import React, { useEffect, useState, useMemo, ReactElement } from 'react';
import { useStores } from '@src/stores';
import { Link, Redirect } from 'react-router-dom';
import { MissionTeamPulseLocation, RootLocation } from '@src/locations';
import Table, { TableRow } from '@src/components/Table';
import MainLayout from '@src/layouts/Main';
import SectionHeading from '@src/components/SectionHeading';
import Loader from '@src/components/Loader';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { TeamBarometer } from '../Mission/TeamPulse/components/TeamBarometerCard';
import { apiTeamPulse } from '@src/logic/services/endpoints';
import { createUseStyles } from 'react-jss';
import {
  TeamPulse,
  TeamPulseDashboardItem,
  TeamPulseSurvey,
} from '@a_team/models/dist/TeamPulse';
import TeamPulseResponseModal from '../Mission/TeamPulse/components/TeamPulseResponseModal';

const useStyles = createUseStyles({
  searchBar: {
    width: 300,
  },
  tableBarometerCell: {
    padding: 0,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    '& > p': {
      color: '#fe630c',
    },
  },
  missionLink: {
    cursor: 'pointer',
    color: '#fe630c',
  },
  tableCell: {
    padding: '8px 24px',
    textAlign: 'left',
  },
});

const TeamPulseDashboardView = (): ReactElement => {
  const styles = useStyles();
  const { auth } = useStores();
  const headers = [
    'Mission',
    'Team Rating',
    'Client Rating',
    'Team Barometer',
    '',
  ];
  const [missionPulses, setMissionPulses] = useState<TeamPulseDashboardItem[]>(
    [],
  );
  const [query, setQuery] = useState<string>('');
  const [missionIdWithOpenModal, setMissionIdWithOpenModal] =
    useState<string>();
  const [responseModalTeamPulse, setResponseModalTeamPulse] =
    useState<TeamPulse>();
  const [responseModalTeamPulseSurveys, setResponseModalTeamPulseSurveys] =
    useState<TeamPulseSurvey[]>([]);

  useEffect(() => {
    apiTeamPulse.adminFindTeamDashboard(auth).then((res) => {
      setMissionPulses(res);
    });
  }, []);

  useEffect(() => {
    const mission = missionPulses.find(
      (mission) => mission.missionId === missionIdWithOpenModal,
    );
    if (mission && mission.intervals && mission.intervals.length > 0) {
      const teamPulse = mission.intervals[mission.intervals.length - 1];
      apiTeamPulse
        .adminFindTeamPulseSurveysWithUsers(auth, {
          filters: { teamPulseId: teamPulse?.teamPulseId },
        })
        .then((teamPulseSurveys) => {
          setResponseModalTeamPulseSurveys(teamPulseSurveys);
          setResponseModalTeamPulse(teamPulse);
        });
    }
  }, [missionIdWithOpenModal]);

  const clearResponseModal = () => {
    setMissionIdWithOpenModal(undefined);
    setResponseModalTeamPulse(undefined);
    setResponseModalTeamPulseSurveys([]);
  };

  const filteredContent = useMemo(() => {
    return missionPulses.filter((m: TeamPulseDashboardItem) =>
      m.missionTitle.toLowerCase().includes(query?.toLowerCase()),
    );
  }, [missionPulses, query]);

  if (!auth.isAdmin) {
    return <Redirect to={RootLocation} />;
  }

  return (
    <MainLayout
      title={'Team Pulse dashboard'}
      topNav={
        <div
          style={{
            padding: '40px 50px 15px 50px',
            boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <SectionHeading isFirst>Admin Team Pulse dashboard</SectionHeading>
        </div>
      }
    >
      <OutlinedInput
        value={query}
        className={styles.searchBar}
        placeholder="Search Running Missions..."
        onChange={(e): void => {
          setQuery(e.target.value);
        }}
      />
      {missionPulses.length ? (
        <div>
          <Table headers={headers} noDataText="No open missions">
            {filteredContent.map((mission) => (
              <TableRow
                key={mission.missionId}
                cells={[
                  <div style={{ maxWidth: 400 }} className={styles.missionLink}>
                    <Link to={MissionTeamPulseLocation(mission.missionId)}>
                      {mission.missionTitle}
                    </Link>
                  </div>,
                  <div style={{ maxWidth: 100 }}>
                    {mission.averageRatingBuilder?.toPrecision(3)}
                  </div>,
                  <div style={{ maxWidth: 100 }}>
                    {mission.averageRatingClient?.toPrecision(3)}
                  </div>,
                  <div
                    onClick={() => setMissionIdWithOpenModal(mission.missionId)}
                    className={styles.tableBarometerCell}
                  >
                    <TeamBarometer
                      teamPulse={
                        mission.intervals[mission.intervals.length - 1]
                      }
                    />
                    <p>Open</p>
                  </div>,
                  <div style={{ maxWidth: 400 }} />,
                ]}
                cellClassName={styles.tableCell}
              />
            ))}
          </Table>
          {responseModalTeamPulse && (
            <TeamPulseResponseModal
              onClose={clearResponseModal}
              open={!!responseModalTeamPulse}
              teamPulse={responseModalTeamPulse}
              teamPulseSurveys={responseModalTeamPulseSurveys}
            />
          )}
        </div>
      ) : (
        <Loader />
      )}
    </MainLayout>
  );
};

export default TeamPulseDashboardView;
