import React, { ReactElement } from 'react';
import ConfirmModalV2 from '@src/components/Modal/ConfirmModalV2';

interface Props {
  role?: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmRoleModal = (props: Props): ReactElement => {
  const { role, onConfirm, open, onClose } = props;
  return (
    <ConfirmModalV2
      dataTestingId={'mission-application-confirm-role-modal'}
      title={
        <span>
          Are you sure you want to add <br /> {role || 'this role'} as a
          secondary role?
        </span>
      }
      description={
        "Only add this role if you have relevant experience. By adding this role to your profile, you'll start receiving mission recommendations related to this role."
      }
      onCancel={onClose}
      onCancelText={'Cancel'}
      onConfirm={onConfirm}
      onConfirmColor={'secondaryDark'}
      onConfirmText={'Add role'}
      open={open}
      onClose={onClose}
    />
  );
};
