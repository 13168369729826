import { Button, Modal } from '@a_team/ui-components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import NoOpenMission from './NoOpenMission';
import { ClientSignalObject } from '@a_team/models/src/ClientSignals';
import { MissionStatus } from '@a_team/models/dist/MissionObject';
import { Breakpoints } from '@ateams/components';
import { useHistory } from 'react-router-dom';
import companyLikedYouIcon from '@src/assets/svgs/company-liked-you-star.svg';

interface CompanyLikedYouModalProps {
  open: boolean;
  signal: ClientSignalObject;
  isJustPublished?: boolean;
  onClose: () => void;
}

const useStyles = createUseStyles({
  modal: {
    padding: 0,
    width: '680px !important',
    '& a': {
      color: '#6D00D7',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  companyLikedYouIcon: {
    transition: 'color 0.3s ease, transform 0.3s ease',
    cursor: 'default',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  actions: {
    display: 'flex',
    gap: 12,
    justifyContent: 'center',
    '& > button': {
      height: 40,
    },
  },
  companyInfo: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 24,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    fontSize: '24px',
    lineHeight: '26px',
    fontWeight: 600,
    margin: '0 0 8px 0',
    textAlign: 'center',
  },
  companyWebsiteAndCategory: {
    display: 'flex',
    gap: 8,
    justifyContent: 'center',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    '& > img': {
      width: 72,
      height: 72,
      borderRadius: 16,
      aspectRatio: '1/1',
    },
  },
  noOpenMissionText: {
    textAlign: 'center',
    margin: '40px 0 0 0',
  },
  noLogo: {
    height: 72,
    width: 72,
    borderRadius: '8px',
    background:
      'linear-gradient(197.15deg, rgb(148, 254, 12) 0%, rgb(84, 214, 255) 88.21%);',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    modal: {
      padding: 40,
    },
  },
});

const CompanyLikedYouModal = ({
  open,
  signal,
  onClose,
  isJustPublished,
}: CompanyLikedYouModalProps): JSX.Element => {
  const styles = useStyles();
  const history = useHistory();

  const companyLogo = signal.clientLogoUrl;
  const companyCategory = signal.clientCompanyCategory;

  const companyName = signal.clientName;
  const companyWebsite = signal.clientWebsite;

  const isMissionPublished =
    signal.mission?.status === MissionStatus.Published || isJustPublished;

  const title = isJustPublished
    ? `${companyName} just published a mission`
    : `${companyName} starred your profile`;

  return (
    <Modal
      variant="slideUp"
      isOpen={open}
      onClose={onClose}
      className={styles.modal}
      shouldHideGradientStroke={true}
    >
      <div className={styles.wrapper}>
        <div className={styles.companyInfo}>
          <div className={styles.imageWrapper}>
            {companyLogo ? (
              <img src={companyLogo} alt="Company Logo" />
            ) : (
              <div className={styles.noLogo} />
            )}
          </div>
          <div>
            <h4 className={styles.title}>
              <img
                title={`${companyName} starred you`}
                className={styles.companyLikedYouIcon}
                src={companyLikedYouIcon}
                alt="Company starred you"
              />{' '}
              {title}
            </h4>
            <div className={styles.companyWebsiteAndCategory}>
              <span>{companyCategory}</span>
              {companyCategory && companyWebsite && <span>·</span>}
              <a href={companyWebsite} target="_blank" rel="noreferrer">
                {companyWebsite}
              </a>
            </div>
            {!isMissionPublished ? (
              <p className={styles.noOpenMissionText}>
                Someone from {companyName} found your skills and experience
                relevant for their upcoming mission. As soon as their mission is
                published we’ll notify you and you can fast track the
                application process.
              </p>
            ) : (
              <p className={styles.noOpenMissionText}>
                Have a look at {companyName}’s new mission to see if it’s
                something you’re interested in building. Since {companyName}{' '}
                previously starred your profile, your application has a high
                chance of getting noticed!
              </p>
            )}
          </div>
        </div>

        {!isMissionPublished && <NoOpenMission />}

        {signal.mission?.mid && isMissionPublished && (
          <div className={styles.actions}>
            <Button onClick={onClose} variant="secondary">
              Not interested
            </Button>
            <Button
              onClick={() => {
                history.push(`/mission/${signal.mission?.mid}`);
              }}
            >
              View mission
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CompanyLikedYouModal;
