import { useEffect, useMemo, useState } from 'react';
import {
  VettingProcess,
  VettingType,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { SelectProps } from '@ateams/components';
import { sortSubArrayByArray } from '@src/helpers/sort';
import {
  columnsSortingOrder,
  VettingBasicColumns,
  VettingColumns,
} from '../vetting-table/columns';
import {
  columnNameToSelectOption,
  getColumnFromName,
} from '../vetting-table/columns/columns-select';
import { VettingColumnConfig } from '../vetting-table/columns/mapping';

export interface ColumnsConfig {
  ADMIN: {
    DEFAULT: VettingColumns[];
    ALL: VettingColumns[];
  };
  DEFAULT: VettingBasicColumns[];
  [VettingType.SelectionTeam]?: VettingBasicColumns[];
  [VettingType.InternalNonTechnical]?: VettingBasicColumns[];
  [VettingType.InternalTechnical]?: VettingBasicColumns[];
}

export function getInitialColumns(
  columnsConfig: ColumnsConfig,
  tabName: string,
  shouldShowAllColumns: boolean,
  vettingType?: VettingType,
  isAdminView?: boolean,
): VettingColumns[] {
  if (!isAdminView) {
    if (vettingType && vettingType in columnsConfig) {
      return columnsConfig[vettingType] as VettingColumns[];
    }

    return columnsConfig.DEFAULT;
  }

  if (shouldShowAllColumns) {
    return columnsConfig.ADMIN.ALL;
  }

  const localValue = localStorage.getItem(getLocalStorageColumnsKey(tabName));
  if (!localValue) {
    return columnsConfig.ADMIN.DEFAULT;
  }

  const columns = decodeColumnsFromLocalStorage(localValue, columnsConfig);
  if (Array.isArray(columns)) {
    return columns;
  } else {
    return columnsConfig.ADMIN.DEFAULT;
  }
}

function getLocalStorageColumnsKey(tabName: string) {
  return `vettingTable:${tabName}`;
}

function encodeColumnsForLocalStorage(
  columns: VettingColumns[],
  columnsConfig: ColumnsConfig,
) {
  return JSON.stringify(
    columnsConfig.ADMIN.ALL.reduce<Partial<Record<VettingColumns, boolean>>>(
      (columnMap, columnName) => {
        columnMap[columnName] = columns.includes(columnName);

        return columnMap;
      },
      {},
    ),
  );
}

function decodeColumnsFromLocalStorage(
  encoded: string,
  columnsConfig: ColumnsConfig,
) {
  try {
    const parsed = JSON.parse(encoded);

    const columns = Object.keys(parsed) // take all of the keys saved
      .filter((column): column is VettingColumns => parsed[column]) // filter only the ones with value true
      .filter((column) => columnsConfig.ADMIN.ALL.includes(column)); // removes columns that might no longer exist

    // adds new columns that are default
    const newDefaultColumns = columnsConfig.ADMIN.DEFAULT.filter(
      (column) => !(column in parsed),
    );

    return [...columns, ...newDefaultColumns];
  } catch (_) {
    return null;
  }
}

export const useColumns = (
  columnsConfig: ColumnsConfig,
  tabName: string,
  shouldShowAllColumns: boolean,
  vettingType?: VettingType,
  isAdminView?: boolean,
) => {
  const [selectedColumns, setSelectedColumns] = useState<VettingColumns[]>(() =>
    getInitialColumns(
      columnsConfig,
      tabName,
      shouldShowAllColumns,
      vettingType,
      isAdminView,
    ),
  );

  useEffect(() => {
    setSelectedColumns(
      getInitialColumns(
        columnsConfig,
        tabName,
        shouldShowAllColumns,
        vettingType,
        isAdminView,
      ),
    );
  }, [columnsConfig, tabName, shouldShowAllColumns, vettingType, isAdminView]);

  const changeColumns = (newColumns: VettingColumns[]) => {
    setSelectedColumns(newColumns);

    if (isAdminView && !shouldShowAllColumns) {
      localStorage.setItem(
        getLocalStorageColumnsKey(tabName),
        encodeColumnsForLocalStorage(newColumns, columnsConfig),
      );
    }
  };

  const onColumnsChange: SelectProps<true>['onChange'] = (options) => {
    const newSelectedColumns = options.map(
      ({ value }) => value as VettingColumns,
    );

    changeColumns(newSelectedColumns);
  };

  const onResetColumns = () => {
    changeColumns(columnsConfig.ADMIN.DEFAULT);
  };

  const columns = useMemo(
    () =>
      sortSubArrayByArray(selectedColumns, columnsSortingOrder).map(
        (selectedColumn) => getColumnFromName(selectedColumn),
      ),
    [selectedColumns, columnsConfig.ADMIN.ALL],
  ) as VettingColumnConfig<VettingProcess>[];

  const selectedColumnsOptions = useMemo(
    () =>
      selectedColumns.map((selectedColumn) =>
        columnNameToSelectOption(selectedColumn),
      ),
    [selectedColumns],
  );

  const columnsOptions = useMemo(
    () =>
      columnsConfig.ADMIN.ALL.map((selectedColumn) =>
        columnNameToSelectOption(selectedColumn),
      ),
    [columnsConfig.ADMIN.ALL],
  );

  return {
    columns,
    columnsOptions,
    selectedColumns,
    selectedColumnsOptions,
    onColumnsChange,
    onResetColumns,
  };
};
