import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import { Checkbox, NestedSelect, SelectOption } from '@ateams/components';

import { useStores } from '@src/stores';
import {
  apiRoleCategories,
  apiTalentCategories,
} from '@src/logic/services/endpoints';
import { RoleFilter } from '.';
import _ from 'lodash';
import { fetchAdjacentSpecializationsMap } from '@src/helpers/missions';

interface Props {
  requireMainSpecialization?: boolean;
  includeAdjacentSpecializations?: boolean;
  requireAssignedSpecialization?: boolean;
  includeSuggestedSpecializations?: boolean;
  specializations: string[];
  onChange: (filter: Partial<RoleFilter>) => void;
  sidebar?: boolean;
  mainSpecializationCheckBox?: boolean;
  includeBeenOnARoleCheckbox?: boolean;
  includeAdjacentSpecializationsCheckbox?: boolean;
  adjacentSpecializations?: string[];
  requireProposedSpecializations?: boolean;
}

const useStyles = createUseStyles({
  selectionContainer: {
    marginBottom: 8,
  },
  checkbox: {
    marginTop: '0.7em',
  },
});

type SelectOptionEx = SelectOption & { options: SelectOption[] };

const SpecializationsSelect = (props: Props): ReactElement => {
  const styles = useStyles();
  const { auth } = useStores();
  const {
    specializations,
    adjacentSpecializations,
    requireMainSpecialization,
    includeAdjacentSpecializations,
    onChange,
    sidebar,
    mainSpecializationCheckBox,
    includeAdjacentSpecializationsCheckbox,
    includeBeenOnARoleCheckbox,
    requireAssignedSpecialization,
    includeSuggestedSpecializations,
    requireProposedSpecializations,
  } = props;

  const isFirstRender = useRef(true);
  const [adjacentSpecializationsMap, setAdjacentSpecializationsMap] = useState<
    Record<string, string[]>
  >({});
  const [allCategoriesMap, setAllCategoriesMap] = useState<
    Record<string, SelectOption>
  >({});
  const [allSpecializationGroups, setAllSpecializationGroups] = useState<
    Record<string, SelectOptionEx>
  >({});

  useEffect(() => {
    async function loadData() {
      const talentCategories = await apiTalentCategories.queryTalentCategories(
        auth,
        {},
      );
      const categories = await apiRoleCategories.getAllCategories(auth);
      const categoriesMap: Record<string, SelectOption> = {};

      const newAllSpecializationGroups: Record<string, SelectOptionEx> = {};

      categories.forEach((category) => {
        categoriesMap[category.cid] = {
          value: category.cid,
          label: category.title,
        };
      });

      talentCategories.items.forEach((talentCategory) => {
        newAllSpecializationGroups[talentCategory.name] = {
          value: talentCategory.name,
          label: talentCategory.name,
          options: categories
            .filter((c) => c.talentCategoryIds.includes(talentCategory.id))
            .map((c) => ({ value: c.cid, label: c.title })),
        };
      });

      setAllCategoriesMap(categoriesMap);
      setAllSpecializationGroups(newAllSpecializationGroups);
    }

    loadData();
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const options = specializations.map(
      (s) => ({ value: s, label: s } as SelectOption),
    );
    updateSpecializations({
      options,
      reset: true,
      includeAdjacentSpecializationsOverride: includeAdjacentSpecializations,
    });
  }, []);

  const adjacentSpecializationsCount = useMemo(() => {
    return adjacentSpecializations?.length || 0;
  }, [adjacentSpecializations]);

  const updateSpecializations = async ({
    options,
    reset,
    includeAdjacentSpecializationsOverride,
  }: {
    options: SelectOption | SelectOption[] | null;
    reset?: boolean;
    includeAdjacentSpecializationsOverride?: boolean;
  }): Promise<void> => {
    const newSpecializations: string[] = !options
      ? []
      : Array.isArray(options)
      ? options.map(({ value }) => value)
      : [options.value];

    let specializationsToAdd = includeAdjacentSpecializationsOverride
      ? newSpecializations
      : newSpecializations.filter((s) => !specializations.includes(s));

    if (reset) {
      specializationsToAdd = newSpecializations;
    }

    let newAdjacentSpecializations: string[] | undefined = undefined;

    if (
      includeAdjacentSpecializations ||
      includeAdjacentSpecializationsOverride
    ) {
      const adjacentSpecializationsMapToAdd =
        await fetchAdjacentSpecializationsMap(auth, specializationsToAdd);
      setAdjacentSpecializationsMap({
        ...adjacentSpecializationsMap,
        ...adjacentSpecializationsMapToAdd,
      });

      if (adjacentSpecializations) {
        newAdjacentSpecializations = _.uniq([
          ...adjacentSpecializations,
          ...Object.values(adjacentSpecializationsMapToAdd).flat(),
        ]);
      }
    }

    onChange({
      specializations: newSpecializations,
      adjacentSpecializations: newAdjacentSpecializations,
      requireMainSpecialization,
      includeAdjacentSpecializations:
        includeAdjacentSpecializationsOverride ||
        includeAdjacentSpecializations,
    });
  };

  const customMultiValueListValues = (options: SelectOption[]) => {
    const coreOptions = _.sortBy(
      options.map((option) => {
        return {
          value: option.value,
          label: `${option.label} (Core)`,
        };
      }),
      'label',
    );

    const adjacentOptions = _.sortBy(
      adjacentSpecializations?.map((skillId: string) => {
        return {
          value: skillId,
          label: allCategoriesMap[skillId]?.label || '',
        };
      }),
      'label',
    );

    return _.uniqBy([...coreOptions, ...adjacentOptions], 'value');
  };

  const onRemoveOption = async (option: SelectOption) => {
    const coreSkill = adjacentSpecializationsMap[option.value];
    if (coreSkill) {
      const newSpecializations = specializations.filter(
        (s) => s !== option.value,
      );

      const newAdjacentSpecializationsMap: Record<string, string[]> = {
        ...adjacentSpecializationsMap,
      };

      const newAdjacentSpecializations = adjacentSpecializations?.filter(
        (s) => !newAdjacentSpecializationsMap[option.value].includes(s),
      );

      delete newAdjacentSpecializationsMap[option.value];
      setAdjacentSpecializationsMap(newAdjacentSpecializationsMap);

      let newIncludeAdjacentSpecializations = includeAdjacentSpecializations;

      if (newSpecializations.length === 0) {
        newIncludeAdjacentSpecializations = undefined;
      }

      onChange({
        specializations: newSpecializations,
        adjacentSpecializations: newAdjacentSpecializations,
        includeAdjacentSpecializations: newIncludeAdjacentSpecializations,
      });
    } else {
      const newAdjacentSpecializations = adjacentSpecializations?.filter(
        (s) => s !== option.value,
      );
      const newSpecializations = specializations?.filter(
        (s) => s !== option.value,
      );

      let newIncludeAdjacentSpecializations = includeAdjacentSpecializations;

      if (newSpecializations.length === 0) {
        newIncludeAdjacentSpecializations = undefined;
      }
      onChange({
        specializations: newSpecializations,
        adjacentSpecializations: newAdjacentSpecializations,
        includeAdjacentSpecializations: newIncludeAdjacentSpecializations,
      });
    }
  };

  return (
    <div className={styles.selectionContainer}>
      {mainSpecializationCheckBox && (
        <Checkbox
          className={styles.checkbox}
          onChange={(e) =>
            onChange({
              requireMainSpecialization: e.target.checked || undefined,
            })
          }
          checked={requireMainSpecialization || false}
          label="Require main specialization"
          margin="none"
          disabled={!specializations || specializations.length === 0}
        />
      )}
      {includeBeenOnARoleCheckbox && (
        <Checkbox
          className={styles.checkbox}
          onChange={(e) =>
            onChange({
              requireAssignedSpecialization: e.target.checked || undefined,
            })
          }
          checked={requireAssignedSpecialization}
          label="Require been on a role"
          margin="none"
          disabled={!specializations || specializations.length === 0}
        />
      )}
      {includeAdjacentSpecializationsCheckbox && (
        <Checkbox
          className={styles.checkbox}
          onChange={async (e) => {
            const value = e.target.checked || undefined;
            if (!value) {
              onChange({
                adjacentSpecializations: undefined,
                includeAdjacentSpecializations: undefined,
              });
              return;
            }
            updateSpecializations({
              options: specializations.map((s) => ({ value: s, label: s })),
              includeAdjacentSpecializationsOverride: true,
            });
          }}
          checked={includeAdjacentSpecializations || false}
          label="Include adjacent roles"
          margin="none"
          disabled={!specializations || specializations.length === 0}
        />
      )}
      <Checkbox
        className={styles.checkbox}
        onChange={(e) =>
          onChange({
            includeSuggestedSpecializations: e.target.checked || undefined,
          })
        }
        checked={includeSuggestedSpecializations}
        label="Include inferred roles"
        margin="none"
        disabled={!specializations || specializations.length === 0}
      />
      <Checkbox
        className={styles.checkbox}
        onChange={(e) =>
          onChange({
            requireProposedSpecializations: e.target.checked || undefined,
          })
        }
        checked={requireProposedSpecializations}
        label="Require proposed"
        margin="none"
        disabled={!specializations || specializations.length === 0}
      />
      <NestedSelect
        placeholder="Specializations"
        items={specializations}
        onChange={(items) => {
          if (specializations.length !== items.length) {
            updateSpecializations({
              options: items.map((i) => ({ value: i, label: i })),
            });
          }
        }}
        onRemoveOption={onRemoveOption}
        sidebar={sidebar}
        isClearable={false}
        allItemsMap={allCategoriesMap}
        allItemGroups={allSpecializationGroups}
        customMultiValueListValues={customMultiValueListValues}
        additionalPlaceholder={
          adjacentSpecializationsCount > 0
            ? `+${adjacentSpecializationsCount} adjacent`
            : undefined
        }
      />
    </div>
  );
};

export default SpecializationsSelect;
