import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import ReactModal from 'react-modal';
import CloseButton from './CloseButton';
import { useScreenClass } from 'react-grid-system';

export interface ModalBasicProps {
  width?: number;
  open: boolean;
  onClose(): void;
  'data-testing-id'?: string;
}

interface Props extends ModalBasicProps {
  open: boolean;
  hideCloseButton?: boolean;
  onClose(): void;
  style?: CSSProperties;
  className?: string;
  children: ReactNode;
  lightCloseButton?: boolean;
  closeButtonStyle?: CSSProperties;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
}

ReactModal.setAppElement('#root');

const Modal = React.forwardRef<HTMLInputElement, Props>(
  (props, ref): ReactElement => {
    const {
      open,
      onClose,
      style,
      children,
      hideCloseButton,
      lightCloseButton,
      closeButtonStyle,
      shouldCloseOnOverlayClick,
      shouldCloseOnEsc,
      width,
    } = props;
    const zIndex = (style?.zIndex as number | undefined) || 10001;
    const screenClass = useScreenClass();

    return (
      <ReactModal
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
        isOpen={open}
        onRequestClose={onClose}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: screenClass === 'xs' ? '95%' : width || 496,
            maxHeight: '95vh',
            padding: '40px 24px',
            zIndex: zIndex,
            position: 'absolute',
            color: '#222',
            background: '#fff',
            overflow: 'auto',
            borderRadius: 0,
            ...style,
          },
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.6)',
            zIndex: zIndex - 1,
          },
        }}
        contentLabel="modal"
        className={cx(props.className, 'bordered')}
        data={{ 'testing-id': props['data-testing-id'] }}
      >
        <>
          {!hideCloseButton && (
            <CloseButton
              onClick={onClose}
              light={lightCloseButton}
              style={closeButtonStyle}
            />
          )}
          {children}
        </>
      </ReactModal>
    );
  },
);

export { CloseButton };
export default Modal;
