import { action, computed, observable } from 'mobx';
import { Stores } from '@src/stores/index';
import { add, differenceInWeeks, format, isPast, setHours } from 'date-fns';

import {
  TalentCategory,
  TalentSkill,
} from '@a_team/models/dist/TalentCategories';
import { PlatformServiceAnalytics } from '@ateams/analytics/dist/platform';
import { merge } from 'lodash';

import {
  preferencesApi,
  reSubscriptionReminderApi,
} from '@src/logic/services/endpoints';
import {
  PreferencesDto,
  PreferencesDtoDisabledCategoriesEnum,
} from '@a_team/user-notification-service-js-sdk';
import { apiUser } from '@ateams/api';

export enum PausePeriod {
  OneWek = '1 week',
  TwoWeeks = '2 weeks',
  OneMonth = '1 month',
  ThreeMonths = '3 months',
}

export enum AvailabilityTypeLabels {
  Now = 'Right Now',
  FutureDate = 'Future Date',
  NotAvailable = 'Not Available',
}

export enum AvailabilityReminderLabels {
  OneMonth = '1 Month',
  ThreeMonths = '3 Months',
  SixMonths = '6 Months',
}

export interface NotificationPreferencesStoreData {
  token?: string;
  invalidToken?: boolean;
  preferences: PreferencesDto;
  dataLoaded?: boolean;
}

export default class NotificationPreferencesUserStore
  implements NotificationPreferencesStoreData
{
  @observable
  public dataLoaded: NotificationPreferencesStoreData['dataLoaded'] = false;
  @observable
  public token: NotificationPreferencesStoreData['token'] = undefined;
  @observable
  public invalidToken: NotificationPreferencesStoreData['invalidToken'] = false;
  @observable
  preferences: NotificationPreferencesStoreData['preferences'] = {};
  protected authStore: Stores['auth'];
  talentCategories: TalentCategory[] = [];
  talentSkills: TalentSkill[] = [];

  public constructor(
    rootStore: Stores,
    analytics: PlatformServiceAnalytics,
    initialState?: NotificationPreferencesStoreData,
  ) {
    if (initialState) {
      this.token = initialState.token;
    }
    this.authStore = rootStore.auth;
  }

  public serialize(): NotificationPreferencesStoreData {
    return {
      token: this.token,
      preferences: this.preferences,
      dataLoaded: this.dataLoaded,
    };
  }

  public allCategoriesInUse = [
    PreferencesDtoDisabledCategoriesEnum.MissionNotification,
    //PreferencesDtoDisabledCategoriesEnum.Community,
    PreferencesDtoDisabledCategoriesEnum.AvailabilityReminder,
    PreferencesDtoDisabledCategoriesEnum.CompanyMarketing,
  ];

  async fetchNotificationPreferencesData(): Promise<void> {
    try {
      const preferences =
        await preferencesApi.preferencesControllerGetPreferences({
          headers: {
            Authorization: 'Bearer ' + (this.token || this.authStore.token),
          },
        });
      this.setNotificationPreferences(preferences);
    } catch (err) {
      // probably the uns is not up
    }
  }

  getPausePeriod = (
    startDate: Date,
    endDate: Date,
  ): PausePeriod | undefined => {
    const weeks = differenceInWeeks(endDate, startDate);
    switch (weeks) {
      case 0:
      case 1:
        return PausePeriod.OneWek;
      case 2:
        return PausePeriod.TwoWeeks;
      case 4:
      case 5:
        return PausePeriod.OneMonth;
      case 12:
      case 13:
        return PausePeriod.ThreeMonths;
      default:
        return undefined;
    }
  };

  getStartAndEndDateFromPeriod = (
    period: PausePeriod,
  ): { startDate: Date; endDate: Date } => {
    const today = setHours(new Date(), 0).setMinutes(0);
    switch (period) {
      case PausePeriod.OneWek:
        return {
          startDate: new Date(today),
          endDate: add(today, { days: 7 }),
        };
      case PausePeriod.TwoWeeks:
        return {
          startDate: new Date(today),
          endDate: add(today, { days: 14 }),
        };
      case PausePeriod.OneMonth:
        return {
          startDate: new Date(today),
          endDate: add(today, { months: 1 }),
        };
      case PausePeriod.ThreeMonths:
        return {
          startDate: new Date(today),
          endDate: add(today, { months: 3 }),
        };
    }
  };

  confirmAvailability = async (): Promise<void> => {
    await apiUser.confirmAvailabilitySettings(
      this.token,
      this.authStore.token ? this.authStore : undefined,
    );
  };

  async updateNotificationPreferences(
    preferences: PreferencesDto,
  ): Promise<void> {
    return preferencesApi
      .preferencesControllerUpdatePreferences(
        { preferencesDto: preferences },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + (this.token || this.authStore.token),
          },
        },
      )
      .then((updatedPreferences) => {
        this.setNotificationPreferences(updatedPreferences);
      });
  }

  async dismissReSubscriptionReminder(): Promise<void> {
    await reSubscriptionReminderApi.reSubscriptionReminderControllerDismissReSubscriptionReminder(
      {
        headers: {
          Authorization: 'Bearer ' + (this.token || this.authStore.token),
        },
      },
    );
  }

  async loadData(token?: string): Promise<void> {
    token && this.setToken(token);
    await this.fetchNotificationPreferencesData();
    this.dataLoaded = true;
  }

  @action setToken = (token: string): void => {
    if (!token || token.length < 1) {
      this.invalidToken = true;
      this.token = undefined;
      throw new TypeError(`Invalid token payload`);
    }

    this.invalidToken = false;
    this.token = token;
  };

  @action setNotificationPreferences = (preferences: PreferencesDto): void => {
    this.preferences = preferences;
  };

  @action updatePauseNotifications(pauseNotifications: boolean): void {
    if (pauseNotifications) {
      this.updateNotificationPreferences(
        merge({}, this.preferences, {
          pauseNotifications: this.getStartAndEndDateFromPeriod(
            PausePeriod.OneWek,
          ),
        }),
      );
    } else {
      this.updateNotificationPreferences(
        merge({}, this.preferences, {
          pauseNotifications: null,
        }),
      );
    }
  }

  @action updatePauseNotificationsPeriod(period: PausePeriod): void {
    this.updateNotificationPreferences(
      merge({}, this.preferences, {
        pauseNotifications: this.getStartAndEndDateFromPeriod(period),
      }),
    );
  }

  @action async addDisabledCategory(
    category: PreferencesDtoDisabledCategoriesEnum,
  ): Promise<void> {
    if (!this.preferences.disabledCategories?.includes(category)) {
      await this.updateNotificationPreferences(
        Object.assign({}, this.preferences, {
          disabledCategories: [
            ...(this.preferences.disabledCategories || []),
            category,
          ],
        }),
      );
    }
  }

  @action async removeDisabledCategory(
    category: PreferencesDtoDisabledCategoriesEnum,
  ): Promise<void> {
    if (this.preferences.disabledCategories?.includes(category)) {
      await this.updateNotificationPreferences(
        Object.assign({}, this.preferences, {
          disabledCategories: this.preferences.disabledCategories?.filter(
            (disabledcategory: PreferencesDtoDisabledCategoriesEnum) =>
              disabledcategory !== category,
          ),
        }),
      );
    }
  }

  @computed get currentActivePausePeriod(): PausePeriod | undefined {
    if (
      !!this.preferences.pauseNotifications &&
      !!this.preferences.pauseNotifications.startDate &&
      !!this.preferences.pauseNotifications.endDate &&
      !isPast(new Date(this.preferences.pauseNotifications.endDate))
    ) {
      return this.getPausePeriod(
        new Date(this.preferences.pauseNotifications.startDate),
        new Date(this.preferences.pauseNotifications.endDate),
      );
    }
    return undefined;
  }

  @computed get formatedEndDate(): string | undefined {
    if (
      !!this.preferences.pauseNotifications &&
      !!this.preferences.pauseNotifications.startDate &&
      !!this.preferences.pauseNotifications.endDate &&
      !isPast(new Date(this.preferences.pauseNotifications.endDate))
    ) {
      return format(
        new Date(this.preferences.pauseNotifications.endDate),
        'PP',
      );
    }
    return undefined;
  }

  @computed get missionRecommendationEnabled(): boolean {
    return !this.preferences.disabledCategories?.includes(
      PreferencesDtoDisabledCategoriesEnum.MissionNotification,
    );
  }
  @computed get communityNotificationsEnabled(): boolean {
    return !this.preferences.disabledCategories?.includes(
      PreferencesDtoDisabledCategoriesEnum.Community,
    );
  }
  @computed get availabilityReminderNotificationsEnabled(): boolean {
    return !this.preferences.disabledCategories?.includes(
      PreferencesDtoDisabledCategoriesEnum.AvailabilityReminder,
    );
  }

  @computed get companyMarketingNotificationsEnabled(): boolean {
    return !this.preferences.disabledCategories?.includes(
      PreferencesDtoDisabledCategoriesEnum.CompanyMarketing,
    );
  }

  @computed get allCategoriesDisabled(): boolean {
    return this.allCategoriesInUse.every((category) =>
      this.preferences.disabledCategories?.includes(category),
    );
  }

  @computed get unsubscribeButtonsDisabled(): boolean {
    return !!this.currentActivePausePeriod;
  }
}
