import {
  SharedJobExperience,
  SharedProjectExperience,
} from '@a_team/models/dist/ConnectionObject';
import { ExperienceType } from '@a_team/models/dist/ExperienceObject';
import { DateISOString } from '@a_team/models/dist/misc';
import { Expertise } from '@src/stores/Profile/models';
import { parseISO } from 'date-fns';
import { first, get, isEqual, isNil, omit, pipe, reduce } from 'lodash/fp';
import { z, ZodIssue } from 'zod';
import { EditingExperienceObject } from '../../types';
import { EditSharedExperienceState } from './reducer';

interface ProfileSkill {
  label?: string;
  value: string;
}

interface Roles {
  label: string;
  value: string;
}

export const toDate = (date: DateISOString | undefined): Date | undefined => {
  if (date instanceof Date) {
    return date;
  }

  return date && typeof date === 'string' ? parseISO(date) : undefined;
};

export const getSkillsUsed = (
  experience: EditingExperienceObject,
  userProfileSkills?: ProfileSkill[],
): ProfileSkill[] => {
  if (!userProfileSkills?.length) {
    return [];
  }

  return userProfileSkills.filter((profileSkill) =>
    experience.talentSkillIds?.some((skill) => skill === profileSkill.value),
  );
};

export const toSkillLabels = (
  defaultPlaceholder: string,
  experience: EditingExperienceObject,
  profileSkills?: ProfileSkill[],
): string[] | string => {
  const skillsUsed = getSkillsUsed(experience, profileSkills);

  return skillsUsed.length
    ? skillsUsed.map((skill) => skill.label || '')
    : defaultPlaceholder;
};

export const toRoleOptions = (roles: Expertise[]): Roles[] =>
  roles.map(({ name, id }) => ({ label: name, value: id }));

export const toPlaceholder = (
  roles: Roles[],
  defaultPlaceholder: string,
  experience: EditingExperienceObject,
): string => {
  const role = roles.find(
    ({ value }) => value === experience.talentSpecializationId,
  );

  return role?.label || defaultPlaceholder;
};

export const hasChanges = (state: EditSharedExperienceState): boolean =>
  !isEqual(omit('isCurrent', state.experience), state.originalExperience);

export const hasErrors = (state: EditSharedExperienceState): boolean =>
  Object.keys(state.errors).length > 0;

const validProjectSchema = z.object({
  startDate: z.date().optional(),
  endDate: z.date().optional(),
  description: z.string().min(1, { message: 'Description is required' }),
  jobRole: z.string().optional(),
  talentSkillIds: z.array(z.string().length(24, 'Invalid skill')).optional(),
  talentSpecializationId: z.string().length(24, 'Invalid role').optional(),
});

const validJobSchema = z.object({
  startDate: z.date().optional(),
  endDate: z.date().optional(),
  description: z.string().optional(),
  jobRole: z.string().optional(),
  talentSkillIds: z.array(z.string().length(24, 'Invalid skill')).optional(),
  talentSpecializationId: z.string().length(24, 'Invalid role').optional(),
});

export interface SharedExpereienceErrors {
  [key: string]: string;
}

const transform = (
  acc: SharedExpereienceErrors,
  error: ZodIssue,
): SharedExpereienceErrors => {
  const key = first(error.path) as string;

  return {
    ...acc,
    [key]: error.message,
  };
};

const extractErrors = pipe(get('error.issues'), reduce(transform, {}));

export const validate = (
  experience: SharedJobExperience | SharedProjectExperience,
): SharedExpereienceErrors => {
  const response =
    experience.type === ExperienceType.Project
      ? validProjectSchema.safeParse(experience)
      : validJobSchema.safeParse(experience);

  if (response.success) {
    return {};
  }

  return extractErrors(response);
};

export const hasError = (
  errors: SharedExpereienceErrors,
  key: string,
): boolean | undefined => {
  const error = get(key, errors);

  if (isNil(error)) {
    return undefined;
  }

  return error.length > 0;
};

export const getSelectedRole = (
  experience: EditingExperienceObject,
): string | undefined => {
  if (
    experience.type === ExperienceType.Project ||
    experience.type === ExperienceType.Job
  ) {
    return experience.talentSpecializationId;
  }

  return undefined;
};
