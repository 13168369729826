import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import HardBreak from '@tiptap/extension-hard-break';
import {
  Editor,
  Extensions,
  useEditor as useTipTapEditor,
} from '@tiptap/react';
import ListItem from '@tiptap/extension-list-item';
import { Plugin, PluginKey } from 'prosemirror-state';
import { Extension } from '@tiptap/core';
import { DOMParser } from 'prosemirror-model';

export const useEditor = ({
  onDescriptionChange,
  initialValue,
  readOnly,
  noTitle,
  placeholder,
}: {
  onDescriptionChange: (description: Editor) => void;
  initialValue: string;
  readOnly?: boolean;
  noTitle?: boolean;
  placeholder?: string;
}) => {
  const Title = Heading.configure({ levels: [3] });
  const DocumentWithTitle = Document;

  if (!noTitle) {
    DocumentWithTitle.extend({
      content: 'heading block+',
    });
  } else {
    DocumentWithTitle.extend({
      content: 'block+',
    });
  }

  // Extension to handle pasting content
  const HandlePasteCustomExtension = Extension.create({
    name: 'handlePaste',

    addProseMirrorPlugins() {
      return [
        new Plugin({
          key: new PluginKey('handlePaste'),
          props: {
            handlePaste: (view, event: ClipboardEvent) => {
              const text = event.clipboardData?.getData('text/plain') || '';

              // Normalize the text: Convert single newlines to spaces and double newlines to single newlines
              const normalizedText = text
                .replace(/(?<!\n)\n(?!\n)/g, ' ')
                .replace(/\n\n/g, '\n');

              // Split the normalized text by newlines to separate paragraphs
              const paragraphs = normalizedText.split(/\n/);

              // Wrap paragraphs in <p> tags
              const htmlContent = paragraphs
                .map((paragraph) => `<p>${paragraph.trim()}</p>`)
                .join('');

              const { schema } = view.state;
              const parser = DOMParser.fromSchema(schema);

              // Create a temporary div to parse the HTML content
              const div = document.createElement('div');
              div.innerHTML = htmlContent;

              // Parse the div's content as a ProseMirror document
              const doc = parser.parse(div);

              // Create a slice from the parsed document
              const slice = doc ? doc.slice(0) : null;

              if (slice) {
                const { tr } = view.state;
                if (!tr.selection.empty) tr.deleteSelection();

                // Replace the selection with the slice
                tr.replaceSelection(slice);

                view.dispatch(tr);
              }

              event.preventDefault();
              return true;
            },
          },
        }),
      ];
    },
  });

  // Extension to ensure the first line is a heading if noTitle is false
  const EnsureFirstLineIsHeadingExtension = Extension.create({
    name: 'ensureFirstLineIsHeading',
    onCreate() {
      if (!noTitle) {
        if (
          !this.editor.isEmpty &&
          this.editor.state.doc.firstChild?.type.name !== 'heading'
        ) {
          this.editor.commands.setNode('heading', { level: 3 });
        }
      }
    },
    onUpdate() {
      if (!noTitle) {
        // Ensure the first node is always a heading
        const firstNode = this.editor.state.doc.firstChild;
        if (firstNode && firstNode.type.name !== 'heading') {
          this.editor.commands.setNodeSelection(0);
          this.editor.commands.setNode('heading', { level: 3 });
        }
      }
    },
  });

  const extensions: Extensions | undefined = [
    DocumentWithTitle,
    Paragraph,
    Text,
    Title,
    Bold,
    Italic,
    Highlight,
    Underline,
    ListItem,
    BulletList,
    OrderedList,
    HardBreak,
    Link.configure({
      openOnClick: false,
      protocols: ['http', 'https'],
      validate: (href) => {
        return /^https?:\/\//.test(href);
      },
    }),
    Placeholder.configure({
      showOnlyCurrent: false,
      placeholder: ({ node }) => {
        if (node.type.name === 'heading' && node.attrs.level === 3) {
          return 'Add a headline';
        }

        if (placeholder) {
          return placeholder;
        }

        return 'Introduce the company and project to your readers';
      },
    }),
    HandlePasteCustomExtension,
    EnsureFirstLineIsHeadingExtension,
  ];

  const editor = useTipTapEditor({
    extensions: extensions,
    content: initialValue,
    editable: !readOnly,
    onUpdate({ editor }) {
      onDescriptionChange(editor as Editor);
    },
  });

  return {
    editor,
  };
};
