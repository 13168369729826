import React, { ReactElement } from 'react';
import { SidebarSection } from '@src/views/Profile/Sidebar/SidebarSection';
import { UserCollaborator } from '@a_team/models/dist/UserObject';
import ExperienceMember from '@src/components/ExperienceMember';

interface CollaboratorsProps {
  collaborators: UserCollaborator[];
  clickable?: boolean;
}

const getTitle = (user: UserCollaborator) => {
  const title = user.memberTitle ?? '';
  const projectsCount =
    user.experiencesCount > 1 ? `${user.experiencesCount} projects` : '';

  return projectsCount.length > 0
    ? title.length > 0
      ? `${title}, ${projectsCount}`
      : projectsCount
    : title;
};

export const Collaborators = (props: CollaboratorsProps): ReactElement => {
  const { collaborators, clickable } = props;
  return (
    <div>
      <SidebarSection title={'Frequent Collaborators'} withAddIcon={false}>
        <div style={{ marginTop: 12 }}>
          {collaborators.map((user) => (
            <ExperienceMember
              key={user.uid}
              user={user}
              clickable={clickable}
              title={getTitle(user)}
            />
          ))}
        </div>
      </SidebarSection>
    </div>
  );
};
