import { SurveyQuestionBaseProps } from '../SurveyQuestionBaseProps';
import { SurveyQuestionResponseValidator } from '../SurveyQuestionResponseValidator';
import { SurveyQuestionType } from '../SurveyQuestionType';

/** Properties of a rating question with a free form comment */
export interface RatingWithFreeFormCommentSurveyQuestionProps
  extends SurveyQuestionBaseProps {
  title: string;
  min: number;
  max: number;
  comment: {
    required: boolean | { from: number | null; to: number | null };
    commentTitle: string;
    commentPlaceholder: string;
  };
}

/** Response value of a rating question with a free form comment */
export interface RatingWithFreeFormCommentSurveyQuestionResponse {
  value: number | null | undefined;
  comment?: string;
}

export const commentIsRequired = (
  value: RatingWithFreeFormCommentSurveyQuestionResponse['value'],
  comment: RatingWithFreeFormCommentSurveyQuestionProps['comment'],
): boolean => {
  if (typeof comment.required === 'boolean') {
    return comment.required;
  } else if (
    typeof comment.required === 'object' &&
    typeof value === 'number'
  ) {
    const lowerBound = comment.required.from as number;
    const hasLowerBound = typeof lowerBound === 'number';
    const upperBound = comment.required.to as number;
    const hasUpperBound = typeof upperBound === 'number';
    return (
      (!hasLowerBound || value >= lowerBound) &&
      (!hasUpperBound || value <= upperBound)
    );
  } else {
    return false;
  }
};

export const validateRatingWithFreeFormCommentSurveyQuestionResponse: SurveyQuestionResponseValidator<
  SurveyQuestionType.RatingWithFreeFormComment
> = (props, response): void => {
  if (props.required && !response) {
    throw new Error('required');
  }

  const { value, comment } = response;

  if (typeof value === 'undefined' || value === null) {
    if (props.required) {
      throw new Error('required');
    }
  } else if (typeof value !== 'number') {
    throw new Error('wrong type');
  } else {
    if (!Number.isInteger(value)) {
      throw new Error('value needs to be an integer');
    }
    if (value < props.min || value > props.max) {
      throw new Error('value outside range');
    }
  }

  if (
    typeof comment !== 'string' &&
    typeof comment !== 'undefined' &&
    comment !== null
  ) {
    throw new Error('wrong type');
  }
};
