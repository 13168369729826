import * as googleMaps from '@googlemaps/google-maps-services-js';
import LocationObject from '@a_team/models/dist/LocationObject';

/**
 * Serializes the structured_formatting field from google to the
 * LocationObject that we use in ATeam
 * @example { main_text: 'Tel Aviv', secondary_text: 'Israel' } -> { country: 'Israel', city: 'Tel Aviv' }
 * @example { main_text: 'New York', secondary_text: 'NY, USA' } -> { country: 'USA', province: 'NY', city: 'New York' }
 */
export function parseLocation(
  structuredFormatting: googleMaps.PlaceAutocompleteResult['structured_formatting'],
  countryShortName?: string,
): LocationObject {
  const { main_text: city, secondary_text } = structuredFormatting;
  if (!secondary_text) return { country: city, countryShortName };

  const elements = secondary_text.split(',');
  const country = elements[elements.length - 1].trim(); // The country is the last element in the comma separated value

  let province: string | undefined;
  if (elements.length > 1) {
    province = elements // Remove the last element from the comma separated value
      .slice(0, elements.length - 1)
      .join(',')
      .trim();
  }

  return {
    country,
    province,
    city,
    countryShortName,
  };
}

/**
 * @example { city: 'Tel Aviv', country: 'Israel' } -> 'Tel Aviv, Israel'
 * @example { city: 'New York', province: 'NY', country: 'USA' } -> 'New York, NY, USA'
 */
export function formatLocation({
  city,
  province,
  country,
}: LocationObject): string {
  return [city, province, country].filter((el) => el).join(', ');
}
