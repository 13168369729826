/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  ComponentType,
  createContext,
  createElement,
  FC,
  useContext,
} from 'react';
import { PlatformServiceAnalytics } from './PlatformServiceAnalytics';

/** React context for the analytics object */
export const PlatformServiceAnalyticsContext = createContext<
  PlatformServiceAnalytics | undefined
>(undefined);

/** React hook for retrieving the analytics object */
export const useAnalytics = (): PlatformServiceAnalytics => {
  const analytics = useContext(PlatformServiceAnalyticsContext);

  if (!analytics) {
    throw new Error('useAnalytics must be used within a Context Provider.');
  }

  return analytics;
};

/** React HOC for injecting the analytics object */
export const withAnalytics =
  <P>(
    component: ComponentType<P & { analytics: PlatformServiceAnalytics }>,
  ): FC<P> =>
  (props: P) => {
    const analytics = useAnalytics();
    return createElement(component, { ...props, analytics });
  };
