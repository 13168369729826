import {
  SubmitVettingFeedbackFormPayload,
  SubmitVettingFeedbackFormPayloadComments,
  VettingFeedbackFormDefaults,
} from '@ateams/api/dist/endpoints/vetting-process-feedback';
import { VettingProcessFeedbackComment } from '@a_team/models/dist/vetting-processes/feedback';
import { VettingFeedbackFormBackgroundComments } from './background-comments';
import { VettingFeedbackFormBuilderInfo } from './builder-info';
import { VettingFeedbackFormExperience } from './experience';
import { VettingFeedbackFormGeneralComments } from './general-comments';
import { VettingFeedbackFormGeneralData } from './general-data';
import { VettingFeedbackFormMainInfo } from './main-info';
import { VettingFeedbackFormProjects } from './projects';
import { VettingFeedbackFormRoles } from './roles';
import { VettingFeedbackFormSummaryComments } from './summary-comments';
import { VettingFeedbackFormTechStack } from './tech-stack';
import { VettingFeedbackFormIndustries } from './industries';

function prepareComments(
  {
    comments,
    comment,
  }: {
    comments?: VettingProcessFeedbackComment[];
    comment?: string;
  },
  originalComments?: VettingProcessFeedbackComment[],
): SubmitVettingFeedbackFormPayloadComments {
  // Form was not submitted or dont have a comments - we can't edit or remove comments in that case
  if (!originalComments) {
    return {
      commentToAdd: comment,
      commentsToEdit: [],
      commentsToDelete: [],
    };
  }

  const commentsToEdit =
    comments
      // Find original comment and compare it to the current one, if they are different then
      // it means that the comment was edited, in which case we filter it
      ?.filter((currentComment) => {
        const originalComment = originalComments.find(
          ({ id }) => id === currentComment.id,
        );

        if (originalComment) {
          if (originalComment.comment !== currentComment.comment) {
            return true;
          }
        } else {
          console.error(
            'Expected to find comment by id, but it was not found 🙈',
          );
        }

        return false;
      })
      .map(({ id, comment }) => ({ id, comment })) || [];

  const commentsToDelete = originalComments
    // Compare the current comments vs the original comments to find the missing
    // ones (this means they were deleted)
    .filter(
      (originalComment) =>
        !comments?.find((comment) => originalComment.id === comment.id),
    )
    // Extract the id property
    .map(({ id }) => id);

  return {
    commentToAdd: comment,
    commentsToEdit,
    commentsToDelete,
  };
}

export const useVettingFeedbackFormSerializePayload = (
  defaultValues: VettingFeedbackFormDefaults,
  {
    generalData,
    mainInfo,
    builderInfo,
    roles,
    experience,
    projects,
    techStack,
    industries,
    backgroundComments,
    generalComments,
    summaryComments,
  }: {
    generalData: VettingFeedbackFormGeneralData;
    mainInfo: VettingFeedbackFormMainInfo;
    builderInfo: VettingFeedbackFormBuilderInfo;
    roles: VettingFeedbackFormRoles;
    experience: VettingFeedbackFormExperience;
    projects: VettingFeedbackFormProjects;
    techStack: VettingFeedbackFormTechStack;
    industries: VettingFeedbackFormIndustries;
    backgroundComments: VettingFeedbackFormBackgroundComments;
    generalComments: VettingFeedbackFormGeneralComments;
    summaryComments: VettingFeedbackFormSummaryComments;
  },
  isCurrentUserAdmin: boolean,
) => {
  function preparePayload() {
    const payload = {
      answers: {
        location: builderInfo.location,
        workElseWhere: builderInfo.worksElseWhere,
        availability: builderInfo.availability,
        additionalMaterialsFiles: builderInfo.additionalMaterialsFiles?.map(
          ({ id }) => id,
        ),
        additionalMaterials: builderInfo.additionalMaterials,
        scores: mainInfo.scores,
        roles: roles.roles,
        userRole: roles.userRole?.cid,
        experience: experience,
        skills: techStack.skills.map((skill) => ({
          skill: skill.skill.id,
          level: skill.level,
          comment: skill.comment,
        })),
        skillsComment: techStack.skillsComment,
        industries: {
          talentIndustries: industries.talentIndustries.map(({ id }) => id),
          comment: industries.comment,
        },
        projects: projects.projects?.map((project) => ({
          ...project,
          role: project.role?.id,
          industries: project.industries.map(({ id }) => id),
          skills: project.skills.map(({ id }) => id),
        })),
        biggestImpactDescription: projects.biggestImpactDescription,
        backgroundComments: prepareComments(
          backgroundComments,
          defaultValues.answers.backgroundComments,
        ),
        generalComments: prepareComments(
          generalComments,
          defaultValues.answers.generalComments,
        ),
        summaryComments: prepareComments(
          summaryComments,
          defaultValues.answers.summaryComments,
        ),
      },
      general: {
        interviewDate: mainInfo.interviewDate,
        category: generalData.category?.id,
      },
    } as SubmitVettingFeedbackFormPayload;

    if (isCurrentUserAdmin) {
      payload.general.contactOwner = generalData.contactOwner?.uid;
      payload.general.vetter = generalData.vetter?.id;
      payload.general.vettingType = generalData.vettingType;
    }

    return payload;
  }

  const prepareDraftPayload = preparePayload;
  const prepareSubmitPayload = preparePayload;

  return { prepareDraftPayload, prepareSubmitPayload };
};
