import React, { useEffect, useMemo, useState } from 'react';
import { useCommonStyles } from '../commonStyles';
import { createUseStyles } from 'react-jss';
import { ProjectCard } from './ProjectCard';
import { useFormContext } from 'react-hook-form';
import {
  Icon,
  IconType,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from '@ateams/components';
import ViewProjectModalV2 from '@src/views/Profile/Main/Projects/ViewProjectModalV2';
import { ExistingProject, NewProject } from '@src/stores/Profile/models';
import { useStores } from '@src/stores';
import { getProjectId } from '@src/views/Profile/helpers/experience';
import { getEmptyProject } from '@src/views/VettingFeedbackForm/form/projects';
import { apiExperiences } from '@ateams/api';
import {
  ExperienceMemberRole,
  ExperienceUserMember,
} from '@a_team/models/dist/ExperienceObject';
import { CollaboratorStatus } from '@a_team/models/dist/UserObject';
import { useQueryTalentIndustries } from '@src/rq/industries';
import { useTalentSkills } from '@src/rq/useTalentSkills';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  seeAllButton: {
    fontSize: FontSizes.small,
    color: Colors.secondary,
    cursor: 'pointer',
    fontWeight: FontWeights.medium,
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.small,
    userSelect: 'none',
  },
  sectionDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.regular,
    color: Colors.danger,
  },
  arrowIcon: ({ showAllProjects }: { showAllProjects: boolean }) => ({
    transition: 'transform 0.3s ease-in-out',
    transform: showAllProjects ? 'rotate(180deg)' : 'none',
    cursor: 'pointer',
  }),
});

export interface ProjectSkill {
  id: string;
  name: string;
}

export interface Project {
  id: string;
  name: string;
  jobRole: string;
  company: string;
  companyLogoUrl: string;
  websiteUrl: string;
  projectUrl: string;
  skills: ProjectSkill[];
  impressions: string;
}

export interface ProjectsSectionProps {
  uid: string;
  selectedProjects: string[];
  showAllProjects: boolean;
  setShowAllProjects: (showAllProjects: boolean) => void;
  isViewMode: boolean;
}

export const ProjectsSection = ({
  uid,
  selectedProjects,
  showAllProjects,
  setShowAllProjects,
  isViewMode,
}: ProjectsSectionProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles({ showAllProjects });
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { auth } = useStores();
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [allProjects, setAllProjects] = useState<
    (NewProject | ExistingProject)[]
  >([]);
  const [selectedProjectId, setSelectedProjectId] = useState<string>();
  const { data: allIndustries } = useQueryTalentIndustries();
  const { data: allSkills } = useTalentSkills({});

  const projects: Project[] = watch('projects') || [];

  const getProjects = async () => {
    const response = await apiExperiences.getExperiencesByUserId(auth, uid);
    const displayedProjects = response.projects.filter((project) => {
      return project.members.find(
        (member) =>
          (member as ExperienceUserMember).uid === uid &&
          (member.experienceRole === ExperienceMemberRole.Owner ||
            member.collaboratorStatus === CollaboratorStatus.Active),
      );
    });

    setAllProjects(displayedProjects);
  };

  useEffect(() => {
    getProjects();
  }, [uid]);

  const selectedProject: NewProject | ExistingProject | undefined =
    useMemo(() => {
      const existingProject = allProjects.find(
        (project) => getProjectId(project) === selectedProjectId,
      );
      return existingProject;
    }, [selectedProjectId, projects.length, getEmptyProject]);

  const visibleProjects = useMemo(() => {
    if (showAllProjects || selectedProjects.length === 0) {
      return projects;
    }

    return projects.filter((project) => selectedProjects.includes(project.id));
  }, [selectedProjects, projects, showAllProjects]);

  const onImpressionsChange = (projectId: string, impressions: string) => {
    const updatedProjects = projects.map((project: Project) =>
      project.id === projectId ? { ...project, impressions } : project,
    );
    setValue('projects', updatedProjects);
  };

  const handleViewProjectModalV2Close = () => {
    setSelectedProjectId(undefined);
  };

  const handleCardClick = (id: string) => {
    setSelectedProjectId(id);
    setViewModalOpen(true);
  };

  return (
    <div className={commonStyles.section}>
      <div className={commonStyles.sectionTitle}>Projects</div>
      <div className={styles.sectionDescriptionContainer}>
        <div className={commonStyles.sectionDescription}>
          Understand the scale and scope of the builder's projects, including
          their contribution, leadership experience and similar. Please add
          impressions to at least 1 project.
        </div>
        <div data-name="projectsGlobal" className={styles.error}>
          {errors.projectsGlobal?.message}
        </div>
      </div>
      <div className={styles.container}>
        {visibleProjects.map((project: Project) => (
          <ProjectCard
            key={project.id}
            project={project}
            onImpressionsChange={onImpressionsChange}
            isViewMode={isViewMode}
            onOpenProject={handleCardClick}
          />
        ))}
      </div>
      {projects.length > 0 &&
        projects.length !== selectedProjects.length &&
        selectedProjects.length !== 0 && (
          <div
            className={styles.seeAllButton}
            onClick={() => setShowAllProjects(!showAllProjects)}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setShowAllProjects(!showAllProjects);
              }
            }}
          >
            <span>
              {showAllProjects ? 'Collapse the list' : 'See all projects'}
            </span>
            <Icon
              type={IconType.ArrowDownSecondarySmall}
              size={'xsmall'}
              className={styles.arrowIcon}
            />
          </div>
        )}
      {selectedProject && (
        <ViewProjectModalV2
          viewModalOpen={viewModalOpen}
          setViewModalOpen={setViewModalOpen}
          onClose={handleViewProjectModalV2Close}
          selectedProject={selectedProject as ExistingProject}
          allIndustries={allIndustries}
          allProfileSkills={allSkills}
          projects={allProjects}
          onOpenProject={handleCardClick}
        />
      )}
    </div>
  );
};
