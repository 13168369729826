import { DateISOString } from './misc';
import { BasicMissionRole, MissionRoleId } from './MissionRole';
import { BasicMissionObject, MissionId } from './MissionObject';
import { UserCardObject, UserId } from './UserObject';

export type RecommendationId = string;

export enum RecommendationType {
  MissionTeammate = 'MissionTeammate',
}

export enum RecommendationStatus {
  Pending = 'Pending',
  Active = 'Active',
  Rejected = 'Rejected',
  Unavailable = 'Unavailable',
}

export interface MinimalRecommendationData {
  userId: UserId;
  roleId?: MissionRoleId;
  status?: RecommendationStatus;
}

export interface RecommendationData {
  mid: MissionId;
  referenceUser: UserId;
  recommendedUser: UserId;
  roleId?: MissionRoleId;
  type: RecommendationType;
  status?: RecommendationStatus;
}

export interface RecommendationObject {
  rid: RecommendationId;
  requestor: UserCardObject;
  requested: UserCardObject;
  requestorAppliedRoles: BasicMissionRole[];
  requestedAppliedRoles?: BasicMissionRole[];
  mission: BasicMissionObject;
  status?: RecommendationStatus;
}

export interface UserRecommendationObject extends RecommendationData {
  rid: RecommendationId;
  createdAt: DateISOString;
  type: RecommendationType;
}
