import { MissionId } from '@a_team/models/dist/MissionObject';
import { ServiceAuth, ServiceEndpoint } from './utils';
import {
  RecommendationId,
  RecommendationObject,
  RecommendationStatus,
} from '@a_team/models/src/RecommendationObject';

export const BasePath = '/user-recommendations';

// exported functions

export default class UserRecommendationsEndpoint extends ServiceEndpoint {
  public getPendingUserRecommendations(
    auth: ServiceAuth,
    mid: MissionId,
    includeInactive?: boolean,
  ): Promise<RecommendationObject[]> {
    return this.fetch(
      auth,
      `${BasePath}/${mid}/pending${
        includeInactive ? '?includeUnavailable=true' : ''
      }`,
    );
  }

  public respondToUserRecommendationRequest(
    auth: ServiceAuth,
    rid: RecommendationId,
    status: RecommendationStatus,
  ): Promise<RecommendationObject[]> {
    return this.fetch(auth, `${BasePath}/${rid}/respond`, null, 'put', {
      status,
    });
  }

  public getUserRecommendationById(
    auth: ServiceAuth,
    rid: RecommendationId,
  ): Promise<RecommendationObject> {
    return this.fetch(auth, `${BasePath}/${rid}`);
  }
}
