import { DEFAULT_PROFILE_IMAGES } from '@a_team/models/dist/constants/Profile';
import ImageFallback from '@src/components/ImageFallback';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface AvatarGroupProps {
  avatarUrls: string[];
}

const useStyles = createUseStyles({
  container: {
    display: 'inline-flex',
    flexDirection: 'row-reverse',
  },
  avatar: {
    position: 'relative',
    overflow: 'hidden',
    width: '24px',
    height: '24px',
    '& > img': {
      border: '1px solid #fff',
      borderRadius: '50%',
      width: '100%',
      height: '100%',
    },
    '&:not(:last-child)': {
      marginLeft: '-8px',
    },
    '& img': {
      width: '100%',
      display: 'block',
    },
  },
});

const AvatarGroup = ({ avatarUrls }: AvatarGroupProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      {avatarUrls.map((avatarUrl, index) => (
        <span className={styles.avatar} key={index}>
          <ImageFallback
            src={avatarUrl || DEFAULT_PROFILE_IMAGES.general}
            fallbackImage={DEFAULT_PROFILE_IMAGES.general}
            alt="avatar"
          />
        </span>
      ))}
    </div>
  );
};

export default AvatarGroup;
