import React, { ReactElement, useEffect, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { Breakpoints, SelectOption } from '@ateams/components';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import {
  FilterValue,
  FilterValues,
} from '@src/stores/MissionControl/MissionControl';
import TextButton from '@src/components/TextButton';
import { Select } from '@ateams/components';
import Checkbox from '@src/components/Checkbox';

interface Props {
  disabled?: boolean;
}

type SelectChangeValue = SelectOption | SelectOption[] | null;

const useStyles = createUseStyles({
  form: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  inputsWrapper: {
    width: '100%',
  },
  inputsRow: {
    '& > *': {
      marginTop: '10px',
    },
  },
  select: {
    margin: '0px',
    marginBottom: '5px',
  },
  clear: {
    textAlign: 'left',
    marginTop: 0,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    form: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    inputsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: 'auto',
    },
    inputsRow: {
      display: 'flex',
      flexDirection: 'row',
      '& > *': {
        marginRight: '12px !important',
        flexBasis: '220px',
      },
    },
  },
});

const optionSorter = (a: SelectOption, b: SelectOption): number => {
  if (b.selected) return 1;
  if (a.selected) return -1;
  return a.label > b.label ? 1 : -1;
};

const getDropdownOptions = (
  filterOptions: { label: string; id: FilterValue }[],
  filterValues: FilterValue[],
) => {
  const options: SelectOption[] = [];
  const selectedOptions: SelectOption[] = [];
  filterOptions.forEach((item) => {
    const selected = filterValues.find((val) => val === item.id);
    const itemObj = {
      value: item.id,
      label: item.label,
    };

    options.push({
      ...itemObj,
      ...(selected ? { selected: true } : {}),
    });
    if (selected) {
      selectedOptions.push(itemObj);
    }
  });

  return [options.sort(optionSorter), selectedOptions];
};

const MissionFilters = (props: Props): ReactElement => {
  const styles = useStyles();
  const { disabled } = props;
  const { missionControl, auth } = useStores();
  const {
    filterOptions,
    filterValues,
    clearFilters,
    setQueryFilter,
    setFilterValues,
  } = missionControl;

  useEffect(() => {
    // when the builder does not have access to the missions, we want to clear the filters
    // so they can see all redacted missions
    const allMissions = missionControl?.allMissions?.items || [];
    if (
      allMissions.length > 0 &&
      allMissions.every((mission) => mission.companyName === 'Company hidden')
    ) {
      clearFilters();
    }
  }, [missionControl.allMissions]);

  const updateFilters = (
    selectedValues: SelectOption[],
    name: keyof FilterValues,
  ) => {
    setFilterValues(
      name,
      selectedValues.map((item) => item.value) as FilterValue[],
    );
  };

  const [allRoles, selectedRoles] = useMemo(() => {
    return getDropdownOptions(filterOptions.roles, filterValues.roles);
  }, [filterOptions.roles, filterValues.roles]);

  const [allSkills, selectedSkills] = useMemo(() => {
    return getDropdownOptions(filterOptions.skills, filterValues.skills);
  }, [filterOptions.skills, filterValues.skills]);

  const [allIndustries, selectedIndustries] = useMemo(() => {
    return getDropdownOptions(
      filterOptions.industries,
      filterValues.industries,
    );
  }, [filterOptions.industries, filterValues.industries]);

  const [allOwners, selectedOwners] = useMemo(() => {
    return getDropdownOptions(filterOptions.owners, filterValues.owners);
  }, [filterOptions.owners, filterValues.owners]);

  const [, selectedHasOpenRoles] = useMemo(() => {
    return getDropdownOptions(
      filterOptions.hasOpenRoles,
      filterValues.hasOpenRoles,
    );
  }, [filterOptions.hasOpenRoles, filterValues.hasOpenRoles]);

  const [, selectedHasAvailability] = useMemo(() => {
    return getDropdownOptions(
      filterOptions.hasAvailability,
      filterValues.hasAvailability,
    );
  }, [filterOptions.hasAvailability, filterValues.hasAvailability]);

  const [, selectedHasLocation] = useMemo(() => {
    return getDropdownOptions(
      filterOptions.hasLocation,
      filterValues.hasLocation,
    );
  }, [filterOptions.hasLocation, filterValues.hasLocation]);

  const handleRolesFilterChange = (selected: SelectChangeValue) => {
    updateFilters(selected as SelectOption[], 'roles');
  };

  const handleSkillsFilterChange = (selected: SelectChangeValue) => {
    updateFilters(selected as SelectOption[], 'skills');
  };

  return (
    <form className={styles.form}>
      <div className={styles.inputsWrapper}>
        <div className={styles.inputsRow}>
          <Select
            isDisabled={disabled}
            hideTags
            isMulti={true as false}
            isSearchable
            showItemCount
            itemCountPrefix="Roles"
            placeholder=""
            className={styles.select}
            value={selectedRoles}
            onChange={handleRolesFilterChange}
            options={allRoles}
          />
          <Select
            isDisabled={disabled}
            hideTags
            isMulti={true as false}
            isSearchable
            showItemCount
            itemCountPrefix="Skills"
            placeholder=""
            className={styles.select}
            value={selectedSkills}
            onChange={handleSkillsFilterChange}
            options={allSkills}
          />
          <Select
            hideTags
            isDisabled={disabled}
            isMulti={true as false}
            isSearchable
            showItemCount
            itemCountPrefix="Industries"
            placeholder=""
            className={styles.select}
            value={selectedIndustries}
            onChange={(selected: SelectChangeValue) =>
              updateFilters(selected as SelectOption[], 'industries')
            }
            options={allIndustries}
          />
          {auth.user?.isAdmin && (
            <>
              <Select
                isDisabled={disabled}
                hideTags
                isMulti={true as false}
                isSearchable
                showItemCount
                itemCountPrefix="TFS Owners"
                placeholder=""
                className={styles.select}
                value={selectedOwners}
                onChange={(selected: SelectChangeValue) =>
                  updateFilters(selected as SelectOption[], 'owners')
                }
                options={allOwners}
              />
            </>
          )}
          <div>
            <TextButton
              disabled={disabled}
              onClick={() => {
                clearFilters();
                setQueryFilter('');
              }}
              className={styles.clear}
              color="primary"
            >
              Clear All
            </TextButton>
          </div>
        </div>
        <div className={styles.inputsRow}>
          <Checkbox
            disabled={disabled}
            checked={selectedHasOpenRoles.length > 0}
            onChange={(e): void =>
              updateFilters(
                e.target.checked
                  ? [{ value: 'yes', label: 'Yes' }]
                  : ([] as SelectOption[]),
                'hasOpenRoles',
              )
            }
            customLabel={`Looking for builders`}
          />
          <Checkbox
            disabled={disabled}
            checked={selectedHasAvailability.length > 0}
            onChange={(): void => {
              updateFilters(
                selectedHasAvailability.length > 0
                  ? ([] as SelectOption[])
                  : [{ value: 'yes', label: 'Yes' }],
                'hasAvailability',
              );
            }}
            customLabel={`Filter by my availability`}
          />
          <Checkbox
            disabled={disabled}
            checked={selectedHasLocation.length > 0}
            onChange={(): void => {
              updateFilters(
                selectedHasLocation.length > 0
                  ? ([] as SelectOption[])
                  : [{ value: 'yes', label: 'Yes' }],
                'hasLocation',
              );
            }}
            customLabel={`Filter by my location`}
          />
        </div>
      </div>
    </form>
  );
};

export default observer(MissionFilters);
