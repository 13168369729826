import React, { ReactElement } from 'react';
import { InvoiceAddress } from '@a_team/models/dist/InvoiceObject';
import HeroSection from './Section';

interface Props {
  grow?: boolean;
  title: string;
  address: InvoiceAddress;
}

export default function HeroAddressSection(props: Props): ReactElement {
  const { address, ...sectionProps } = props;

  return (
    <HeroSection {...sectionProps}>
      <strong>{address.name}</strong>
      <br />
      {address.line1}
      {!!address.line2 && `, ${address.line2}`}
      <br />
      {address.city}
      {!!address.state && `, ${address.state}`} {address.postalCode}
      <br />
      {address.country}
    </HeroSection>
  );
}
