// local functions

// exported functions

export function createPageRows<T>(props: {
  rows: T[];
  rowSize(row: T): number;
  heroSize: number;
}): T[][] {
  const { rows, heroSize, rowSize } = props;
  const pageSize = 29;
  const footerSize = 6;

  const res: T[][] = [];
  let i = 0;
  let hasMore = false;

  do {
    const rowsInPage = i ? pageSize : pageSize - heroSize;

    const pageRows = [];
    let rowsHeight = 0;
    hasMore = false;

    while (i < rows.length) {
      const row = rows[i];
      const hr = rowSize(row);

      if (rowsHeight + hr > rowsInPage) {
        hasMore = true;
        break;
      }

      pageRows.push(row);

      i += 1;
      rowsHeight += hr;
    }

    // if we don't have place for the footer remove last row and add a new page
    if (!hasMore && rowsHeight > rowsInPage - footerSize) {
      i = rows.length - 1;
      pageRows.pop();
      hasMore = true;
    }

    res.push(pageRows);
  } while (hasMore);

  return res;
}
