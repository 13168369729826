import {
  EnglishLevelLabels,
  AdminNotesScore,
} from '@a_team/models/dist/AdminNotesObject';
import React from 'react';
import { BasicVettingProcessColumnProps } from '..';

export const EnglishField: React.FC<BasicVettingProcessColumnProps> = ({
  vettingProcess,
}) => {
  const englishLevel =
    vettingProcess.feedbackV2?.answers.scores.englishLevel ??
    vettingProcess.feedback?.answers.scores.englishLevel;

  if (typeof englishLevel !== 'number') {
    return null;
  }

  return <>{EnglishLevelLabels[englishLevel as AdminNotesScore]}</>;
};
