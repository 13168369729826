import React, { ReactElement, useMemo, useRef } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { useHistory, useLocation } from 'react-router-dom';
import { Select } from '@ateams/components';
import MissionTabLink from './MissionTabLink';
import { Colors, TextColors, Breakpoints } from '@ateams/components';
import { Tab } from './MissionTabLink';

interface Props {
  sticky?: boolean;
  className?: string;
  tabValues: Tab[];
}

const useStyles = createUseStyles({
  tabContainer: {
    position: (props: Props): string => (props.sticky ? 'sticky' : 'relative'),
    zIndex: 99,
    boxShadow: 'none',
    backgroundColor: Colors.backgroundWhite,
    borderBottom: `1px solid ${Colors.regular}`,
  },
  desktopContainer: {
    display: 'none',
  },
  desktopTabs: {
    display: 'none',
  },
  mobileTabs: {
    padding: '1em',
    width: '100%',
    top: '50px',
  },
  tab: {}, // this is to initiate the class for use in media query below
  tabLeft: {},
  tabRight: {},
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    mobileTabs: {
      top: 0,
      padding: '1em 36px 1em 36px',
    },
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    desktopContainer: {
      display: 'block',
      width: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
      padding: '0 36px',
      top: 0,
    },
    desktopTabs: {
      display: 'flex',
      flexFlow: 'row nowrap',
      zIndex: 998,
      width: '100%',
      height: '100%',
      color: TextColors.regular,
      justifyContent: 'space-between',
    },
    tab: {
      width: 'max-content',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'flex-end',
    },
    tabLeft: {
      justifyContent: 'flex-start',
    },
    tabRight: {
      justifyContent: 'flex-end',
    },
    mobileTabs: {
      display: 'none',
    },
  },
});

export default function TabButtonsMissionControl(props: Props): ReactElement {
  const { className, tabValues } = props;
  const tabs = useRef<HTMLDivElement>(null);
  const styles = useStyles(props);
  const leftTabs = useMemo(
    () =>
      tabValues
        .filter((tab) => Boolean(tab?.rightAligned) === false)
        .map((tab) => ({
          ...tab,
          skipTotal: true,
        })),
    [tabValues],
  );
  const rightTabs = useMemo(
    () => tabValues.filter((tab) => tab?.rightAligned),
    [tabValues],
  );

  const history = useHistory();
  const location = useLocation();

  const selectOptions = useMemo(
    () =>
      tabValues.map((tab) => ({
        label: tab.label,
        value: tab.value,
      })),
    [tabValues],
  );

  const currentOption = selectOptions.find(
    (item) => location.pathname === item.value,
  );

  return (
    <>
      <form className={cx(styles.mobileTabs, styles.tabContainer)}>
        <Select
          inputId="tab-select-input"
          onChange={(tab) => history.push(tab?.value || '')}
          options={selectOptions}
          value={currentOption || selectOptions[0]}
          width="default"
          margin="none"
        />
      </form>

      <div
        ref={tabs}
        className={cx(styles.desktopContainer, styles.tabContainer, className)}
      >
        <div className={cx(styles.desktopTabs, 'inner')}>
          <div className={cx(styles.tab, styles.tabLeft)}>
            {leftTabs.map((tab) => (
              <MissionTabLink
                key={tab.value}
                tab={tab}
                current={tab.value === currentOption?.value}
              />
            ))}
          </div>
          <div className={cx(styles.tab, styles.tabRight)}>
            {rightTabs.map((tab) => (
              <MissionTabLink
                key={tab.value}
                tab={tab}
                current={tab.value === currentOption?.value}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
