import React, { useEffect } from 'react';
import SkillsSelector from '@src/components/SkillsSelector';
import CommonStyles from '../common/styles';
import { Expertise } from '@src/stores/Profile/models';
import ErrorForm from '../common/ErrorForm';
import { useFormContext } from 'react-hook-form';

interface SkillsV2Props {
  projectSkills: string[];
  profileSkills: Expertise[];
}

function SkillsV2({ projectSkills, profileSkills }: SkillsV2Props) {
  const commonStyles = CommonStyles();

  const {
    register,
    unregister,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    register('skills', {
      validate: (value) => {
        if (!Array.isArray(value) || value.length === 0) {
          return 'At least one skill is required.';
        } else if (value.length > 12) {
          return "You can't have more than 12 skills.";
        }

        return true;
      },
    });

    register('selectedSkills', {
      validate: (value) => {
        if (
          !Array.isArray(value) ||
          value.length === 0 ||
          value.some((s: Expertise) => !s.rating)
        ) {
          return 'Skill rating is required.';
        }

        return true;
      },
    });

    return () => {
      unregister('skills');
      unregister('selectedSkills');
    };
  }, [register, unregister]);

  const handleOnChange = (skills: Expertise[]) => {
    setValue(
      'skills',
      skills.map((skill) => skill.id),
    );
    if (errors.skills) {
      trigger('skills');
    }

    setValue('selectedSkills', skills);
    if (errors.selectedSkills) {
      trigger('selectedSkills');
    }
  };

  return (
    <>
      <div
        className={commonStyles.container}
        data-testing-id="profile-project-card-skills-used-select"
      >
        <div className={commonStyles.title}>
          Skills used — Add up to 12 skills
        </div>
        <div className={commonStyles.description}>
          Any new skills will be added to your profile.
        </div>
        <SkillsSelector
          defaultSelectedSkillIds={projectSkills}
          profileSkills={profileSkills}
          onChange={handleOnChange}
        />
      </div>
      <ErrorForm field="skills" errors={errors} />

      <ErrorForm field="selectedSkills" errors={errors} />
    </>
  );
}

export default SkillsV2;
