import {
  ServiceAuth,
  ServiceFetchParams,
  parseServiceFetchError,
  serviceFetchParameters,
} from '@ateams/service-utils';
import { VettingProcessFeedbackAdditionalMaterial } from '@a_team/models/src/vetting-processes/feedback-additional-material';
import { VettingProcessId } from '@a_team/models/src/vetting-processes/vetting-processes';
import { ServiceEndpoint } from './utils';
import { API_SERVICE_BASE_PATH } from '../config';

export const BasePath = '/vettingx-process/feedback/additional-material';

export default class VettingProcessFeedbackAdditionalMaterialEndpoint extends ServiceEndpoint {
  /**
   * Upload an additional material to the vetting feedback form
   * @param auth - Selection Team
   */
  public async vetterUploadAdditionalMaterial(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
    additionalMaterial: File,
  ): Promise<VettingProcessFeedbackAdditionalMaterial> {
    const params: ServiceFetchParams = [
      auth,
      `${API_SERVICE_BASE_PATH}${BasePath}/${vettingProcessId}`,
      null,
      'post',
    ];

    const [url, init] = serviceFetchParameters(...params);

    const formData = new FormData();
    formData.append('additionalMaterial', additionalMaterial);

    init.body = formData;

    const res = await fetch(url, init);
    if (res.ok) {
      return res.json();
    }

    return parseServiceFetchError(res, params);
  }

  /**
   * Upload an additional material to the vetting feedback form
   * @param auth - Admins
   */
  public async adminUploadAdditionalMaterial(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
    additionalMaterial: File,
  ): Promise<VettingProcessFeedbackAdditionalMaterial> {
    const params: ServiceFetchParams = [
      auth,
      `${API_SERVICE_BASE_PATH}${BasePath}/${vettingProcessId}/admin`,
      null,
      'post',
    ];

    const [url, init] = serviceFetchParameters(...params);

    const formData = new FormData();
    formData.append('additionalMaterial', additionalMaterial);

    init.body = formData;

    const res = await fetch(url, init);
    if (res.ok) {
      return res.json();
    }

    return parseServiceFetchError(res, params);
  }

  /**
   * Removes an additional material to the vetting feedback form
   * @param auth - Selection Team
   */
  public vetterDeleteAdditionalMaterial(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
    additionalMaterialId: string,
  ): Promise<void> {
    return this.fetch(
      auth,
      `${BasePath}/${vettingProcessId}/${additionalMaterialId}`,
      null,
      'delete',
    );
  }

  /**
   * Removes an additional material to the vetting feedback form
   * @param auth - Admins
   */
  public adminDeleteAdditionalMaterial(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
    additionalMaterialId: string,
  ): Promise<void> {
    return this.fetch(
      auth,
      `${BasePath}/${vettingProcessId}/${additionalMaterialId}/admin`,
      null,
      'delete',
    );
  }
}
