import React, { useMemo, useState } from 'react';
import SectionHeading from '@src/components/SectionHeading';
import Modal, { ModalBasicProps } from '..';
import {
  Button as CallToActionButton,
  Select,
  SelectProps,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { PatchRemoveVettingProcessPayload } from '@ateams/api/dist/endpoints/vetting-process';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';

export type OnRemoveVettingProcessSubmit = (
  payload: Omit<PatchRemoveVettingProcessPayload, 'vettingProcessId'>,
) => void;

interface Props extends ModalBasicProps {
  onSubmit: OnRemoveVettingProcessSubmit;
}

enum ReasonTypes {
  BuilderOptedOut = 'builderOptedOut',
  NoResponseFromBuilder = 'noResponseFromBuilder',
  Other = 'other',
}

const reasonTypeToLabelMap: Record<ReasonTypes, string> = {
  [ReasonTypes.BuilderOptedOut]: 'Builder opted out of vetting',
  [ReasonTypes.NoResponseFromBuilder]: 'No response from builder',
  [ReasonTypes.Other]: 'Other (specify custom reason)',
};

const REASON_TYPE_OPTIONS = Object.entries(reasonTypeToLabelMap).map(
  ([key, label]) => ({ value: key, label }),
);

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
    padding: '40px 24px',
  },
  inputContainer: {
    width: '300px',
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: '40px',
    },
  },
  textInput: {
    padding: 0,
    margin: 0,
    height: '20px',
  },
  textInputControl: {
    padding: '10px 12px',
    height: '40px',
    minHeight: 'initial',
    borderRadius: '4px',
  },
});

export const RemoveVettingProcessModal: React.FC<Props> = (props) => {
  const styles = useStyles();
  const { open, onClose, onSubmit } = props;
  const [reasonType, setResaonType] = useState<ReasonTypes>();
  const [customReason, setCustomReason] = useState('');

  const reason = useMemo(() => {
    if (!reasonType) {
      return '';
    }

    if (reasonType === ReasonTypes.Other && customReason) {
      return customReason;
    }

    return reasonType;
  }, [reasonType, customReason]);

  /**
   * The reason type is required
   * If custom reason is picked then we also verify that the user typed in the reason
   */
  const canSubmit = useMemo(() => {
    return Boolean(reason);
  }, [reasonType, customReason]);

  const handleSubmit = () => {
    onSubmit({
      reason,
    });
  };

  const onReasonTypeChange: SelectProps['onChange'] = (option) => {
    if (option) {
      setResaonType(option.value as ReasonTypes);
    }
  };

  const onCustomReasonChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setCustomReason(e.target.value);
  };

  const isCustomReason = reasonType === ReasonTypes.Other;

  return (
    <Modal open={open} onClose={onClose} style={{ padding: 0, width: 380 }}>
      <div className={styles.container}>
        <SectionHeading isFirst>Remove Vetting Process</SectionHeading>

        <div className={styles.inputContainer}>
          <Select
            onChange={onReasonTypeChange}
            options={REASON_TYPE_OPTIONS}
            label="Reason Type"
            placeholder="Specify reason..."
            margin="none"
          />
        </div>

        {isCustomReason && (
          <div className={styles.inputContainer}>
            <OutlinedInput
              value={customReason}
              onChange={onCustomReasonChange}
              required
              label="Reason"
              placeholder="Type reason..."
              margin="none"
              controlClassName={styles.textInputControl}
              inputClassName={styles.textInput}
            />
          </div>
        )}

        <CallToActionButton
          disabled={!canSubmit}
          onClick={handleSubmit}
          width="expend"
          size="small"
        >
          Submit
        </CallToActionButton>
      </div>
    </Modal>
  );
};
