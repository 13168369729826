import { TimesheetsDetails } from '@a_team/models/dist/AdminNotesObject';
import { Modal } from '@a_team/ui-components';
import {
  BorderRadius,
  Breakpoints,
  Colors,
  Icon,
  IconType,
  Spacing,
  FontSizes,
  FontWeights,
  TextColors,
} from '@ateams/components';
import SectionHeading from '@src/components/SectionHeading';
import formatDate from '@src/helpers/formatDate';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';

interface TimesheetSummariesModalProps {
  open: boolean;
  onClose: () => void;
  builderFirstName: string;
  summaries: TimesheetsDetails[];
}

const useStyles = createUseStyles({
  modal: {
    width: '100% !important',
    padding: '0px !important',
    maxWidth: '800px',
    outline: 'none',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    color: Colors.dark,
  },
  title: {
    textAlign: 'left',
    fontSize: FontSizes.large,
    fontWeight: FontWeights.medium,
    marginBottom: Spacing.large,
  },
  summaryContainer: {
    overflowY: 'auto',
    padding: 0,
  },
  summaryItem: {
    marginBottom: Spacing.large,
  },
  summaryDate: {
    fontWeight: FontWeights.medium,
    marginBottom: Spacing.xsmall,
  },
  summaryText: {
    color: TextColors.lighter,
    margin: 0,
    marginBottom: Spacing.medium,
  },
  demoVideoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.xsmall,
  },
  demoVideo: {
    width: 'fit-content',
    color: Colors.secondaryDark,
    backgroundColor: Colors.banner,
    borderRadius: BorderRadius.medium,
    padding: Spacing.small,
  },
  videoIcon: {
    marginRight: Spacing.xsmall,
    width: 16,
    height: 16,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      padding: `${Spacing.xxLarge}px !important`,
    },
  },
});

const TimesheetSummariesModal: React.FC<TimesheetSummariesModalProps> = ({
  open: isOpen,
  onClose,
  builderFirstName,
  summaries,
}): ReactElement => {
  const styles = useStyles();

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
      <div className={styles.container}>
        <SectionHeading className={styles.title} isFirst>
          Timesheet summaries
        </SectionHeading>
        <div className={styles.summaryContainer}>
          {summaries.map((summary, index) => (
            <div key={index} className={styles.summaryItem}>
              <div className={styles.summaryDate}>
                Summary, {formatDate(summary.startDate)} —{' '}
                {formatDate(summary.endDate)}
              </div>
              <p className={styles.summaryText}>{summary.summary}</p>
              {summary.demoLink && (
                <div className={styles.demoVideoContainer}>
                  <div>Demo Video</div>
                  <div className={styles.demoVideo}>
                    <Icon type={IconType.Video} className={styles.videoIcon} />
                    <a href={summary.demoLink} target="_blank" rel="noreferrer">
                      {builderFirstName} shared a demo
                    </a>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default TimesheetSummariesModal;
