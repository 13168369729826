import React, { ReactElement } from 'react';
import AllMissionCard from './MissionControlCard/AllMissionCard';
import RecommendedMissionCard from './MissionControlCard/RecommendedMissionCard';
import NotInterestedMissionCard from './MissionControlCard/NotInterestedMissionCard';
import AppliedMissionCard from './MissionControlCard/AppliedMissionCard';
import CompletedMissionCard from './MissionControlCard/CompletedMissionCard';

interface LoadingCardsProps {
  count?: number;
  type: 'all' | 'recommended' | 'applied' | 'notInterested' | 'completed';
}

export const LoadingCards = (props: LoadingCardsProps): ReactElement => {
  const loadingCardsBase = [...Array(props.count || 8).keys()];

  const elements = {
    all: AllMissionCard,
    recommended: RecommendedMissionCard,
    notInterested: NotInterestedMissionCard,
    applied: AppliedMissionCard,
    completed: CompletedMissionCard,
  };

  const Card = elements[props.type];

  return (
    <>
      {loadingCardsBase.map((i) => (
        <Card key={i} loading />
      ))}
    </>
  );
};
