import React, { useContext, useState } from 'react';
import cx from 'classnames';
import { Button } from '@a_team/ui-components';
import { ReCaptcha } from 'react-recaptcha-v3';
import AGuideImage from '../svgs/aguide.svg';
import { useCommonStyles } from '../../commonStyles';
import TextAreaInput from '@src/components/Inputs/TextAreaInput';
import { useSendSupportMessage } from '@src/rq/messages';
import { RECAPTCHA_SITE_KEY } from '@src/config';
import MessageSent from '../svgs/sent.svg';
import { ReasonsModalContext } from '../context';

interface SupportProps {
  label: string;
  placeholder: string;
  showBanner?: boolean;
  dismissModal?: boolean;
  onClose: () => void;
}

export const Support = (props: SupportProps) => {
  const styles = useCommonStyles();
  const [message, setMessage] = useState<string>('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [success, setSuccess] = useState<boolean>(false);
  const { onModalClose } = useContext(ReasonsModalContext);
  const {
    mutateAsync: contactSupport,
    isLoading,
    isError,
  } = useSendSupportMessage();

  const verifyCallback = (recaptchaToken: string): void => {
    setRecaptchaToken(recaptchaToken);
  };

  const submitSupportTicket = async (): Promise<void> => {
    const payload = {
      message,
      recaptchaToken,
    };
    await contactSupport(payload);
    setMessage('');
    setSuccess(true);
  };

  if (success) {
    return (
      <>
        <div className={styles.hero}>
          <img
            src={MessageSent}
            className={styles.heroImg}
            alt="Message Sent"
          />
          <h4 className={styles.modalTitle}>
            Thanks! We’ll get back to you within 48 hours.
          </h4>
          <p className={cx(styles.heroCopy, 'lessPadding')}>
            If you’re blocked from applying to a mission, reach out to the team
            architect on the mission page to state your interest.
          </p>
        </div>
        <div className={styles.actions} style={{ paddingTop: 32 }}>
          <Button
            className={styles.closeBtn}
            variant="secondary"
            size="sm"
            onClick={onModalClose}
          >
            Close
          </Button>
        </div>
      </>
    );
  }

  const getBanner = () => {
    if (!props.showBanner) {
      return null;
    }

    return (
      <div className={cx(styles.panel, 'support')}>
        <div className={styles.stepWrap}>
          <div className={styles.stepCopy}>
            <h5 className={styles.panelTitle}>Need help getting connected?</h5>
            <p className={styles.panelCopy}>
              View the A.Guide article and FAQs on how to connect your calendar.
            </p>
            <a
              href="https://guide.a.team/missions/interview-scheduling"
              target="_blank"
              className={styles.linkButton}
              rel="noreferrer"
            >
              Explore A.Guide
            </a>
          </div>
          <img src={AGuideImage} alt="Visit A.Guid" />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.hero}>
        <h4 className={styles.modalTitle}>Contact Support</h4>
      </div>
      {getBanner()}
      <div className={cx(styles.teaserBody, 'leftAligned')}>
        <label htmlFor="supportMessage" className={styles.calendarLinkLabel}>
          {props.label}
        </label>
        <TextAreaInput
          className={styles.message}
          onChange={(e): void => setMessage(e.target.value)}
          placeholder={props.placeholder}
          minHeight={135}
          autoFocus
          autoResize
          disabled={isLoading}
          footer={
            isError ? (
              <p className={styles.error}>
                An error occured. Please try again.
              </p>
            ) : undefined
          }
        />
        <ReCaptcha
          sitekey={RECAPTCHA_SITE_KEY}
          action="action_name"
          verifyCallback={verifyCallback}
        />
        <div className={styles.actions} style={{ paddingTop: 32 }}>
          <Button
            className={styles.closeBtn}
            variant="secondary"
            size="sm"
            onClick={props.dismissModal ? onModalClose : props.onClose}
          >
            Back
          </Button>
          <Button
            disabled={!message.length || isLoading}
            variant="main"
            size="sm"
            className={styles.primaryButton}
            onClick={submitSupportTicket}
          >
            Send
          </Button>
        </div>
      </div>
    </>
  );
};
