import cx from 'classnames';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import AspectRatioContainer from '../AspectRatioContainer';

export interface Props {
  inner?: JSX.IntrinsicElements['div'];
}

const useStyles = createUseStyles({
  inner: {
    borderRadius: '50%',
  },
});

const Circle = ({
  inner: { className: innerClassName, ...innerProps } = {},
  ...props
}: Props & JSX.IntrinsicElements['div']): ReactElement | null => {
  const styles = useStyles();
  return (
    <AspectRatioContainer
      aspectRatio={1}
      inner={{ className: cx(innerClassName, styles.inner), ...innerProps }}
      {...props}
    />
  );
};

export default Circle;
