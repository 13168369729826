import React, { ReactElement, useState } from 'react';
import Modal, { ModalBasicProps } from '..';
import { RoleRecord } from '@src/views/Mission/TimeTracking/TimesheetsTable/MobileTableRow';
import SectionHeading from '@src/components/SectionHeading';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { TimeField } from '@src/components/TimeField';
import { Button as CallToActionButton } from '@ateams/components';
import DropdownInput from '@src/components/Inputs/DropdownInput';
import { format } from 'date-fns';
import { formatMinutesToTime, getLocalTime } from '@src/helpers/time';
import { TimesheetRecord } from '@a_team/models/dist/TimesheetObject';

interface Props extends ModalBasicProps {
  record: RoleRecord;
  onEdit: (record: TimesheetRecord) => void;
}

export default function EditTimesheetRecordModal(props: Props): ReactElement {
  const { record, onEdit, onClose, ...left } = props;
  const [hoursError, setHoursError] = useState(false);
  const [detailsError, setDetailsError] = useState(false);
  const [minutes, setMinutes] = useState(record.minutes);
  const [details, setDetails] = useState(record.details);

  const handleSubmit = () => {
    if (!minutes) {
      setHoursError(true);
    } else if (details.length < 1) {
      setDetailsError(true);
    } else {
      setHoursError(false);
      setDetailsError(false);
      const update = {
        date: record.date,
        minutes,
        details,
      };
      onEdit({
        ...record,
        ...update,
      });
      onClose();
    }
  };

  return (
    <Modal onClose={onClose} hideCloseButton={false} {...left}>
      <SectionHeading isFirst>Edit Logged Time</SectionHeading>
      <div style={{ fontSize: 15 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <label>Date:</label>
          <DropdownInput value={1} disabled style={{ maxWidth: 162 }}>
            <option key={1} value={1}>
              {format(getLocalTime(new Date(record.date)), 'EEE, LLL d')}
            </option>
          </DropdownInput>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <label>Hours Worked:</label>
          <TimeField
            defaultValue={formatMinutesToTime(record.minutes)}
            style={{ marginTop: 0, maxWidth: 80 }}
            onChange={(e, minutes) => {
              setMinutes(minutes);
              setHoursError(false);
            }}
            error={hoursError}
          />
        </div>
        <OutlinedInput
          minRows={5}
          multiline
          value={details}
          onChange={(e) => {
            setDetails(e.target.value);
            setDetailsError(false);
          }}
          error={detailsError}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CallToActionButton onClick={handleSubmit}>Done</CallToActionButton>
        </div>
      </div>
    </Modal>
  );
}
