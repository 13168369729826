import { Colors, Spacing } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Text } from '../components/typography';

const useStyles = createUseStyles({
  container: {
    width: '100%',
    height: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: Colors.backgroundDark,
    marginBottom: Spacing.xLarge,
  },
});

export const ViewModeHeader = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Text>You are in view mode - Editing is unavailable</Text>
    </div>
  );
};
