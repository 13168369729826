import React, { ReactElement, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { PreferencesDtoDisabledCategoriesEnum } from '@a_team/user-notification-service-js-sdk';
import { Checkbox, Select, SelectOption } from '@ateams/components';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery';

type SelectChangeValue = SelectOption | SelectOption[] | null;

const NOTIFICATION_CATEGORIES: SelectOption[] = [
  {
    label: 'Recommended missions',
    value: PreferencesDtoDisabledCategoriesEnum.MissionNotification,
  },
  {
    label: 'Availability reminders',
    value: PreferencesDtoDisabledCategoriesEnum.AvailabilityReminder,
  },
  {
    label: 'Community & Product',
    value: PreferencesDtoDisabledCategoriesEnum.CompanyMarketing,
  },
];

interface Props {
  requiredNotificationCategories?: string[];
  requireAllNotificationCategories?: boolean;
  includePausedNotifications?: boolean;
  excludeEmailNotificationsBlocked?: boolean;
  requireEmailNotificationsBlocked?: boolean;
  requireAutomaticallyDisabledCategories?: boolean;
  includeCategoriesDisabledAutomatically?: boolean;
  onChange: (options: Partial<RoleFilter>) => void;
}

const useStyles = createUseStyles({
  checkbox: {
    marginTop: '0.5em',
  },
  select: {
    margin: 0,
    marginBottom: 10,
  },
  nestedCheckbox: {
    marginLeft: '1em',
  },
});

const NotificationPreferences = (props: Props): ReactElement => {
  const styles = useStyles();
  const {
    requiredNotificationCategories,
    requireAllNotificationCategories,
    includePausedNotifications,
    excludeEmailNotificationsBlocked,
    requireEmailNotificationsBlocked,
    requireAutomaticallyDisabledCategories,
    includeCategoriesDisabledAutomatically,
    onChange,
  } = props;

  const selectedCategories = useMemo(
    () =>
      NOTIFICATION_CATEGORIES.filter(({ value }) =>
        requiredNotificationCategories?.includes(value),
      ) ?? [],
    [requiredNotificationCategories],
  );

  const handleChange = (data: Partial<RoleFilter>) => {
    onChange({
      requiredNotificationCategories,
      requireAllNotificationCategories,
      includePausedNotifications,
      excludeEmailNotificationsBlocked,
      requireEmailNotificationsBlocked,
      requireAutomaticallyDisabledCategories,
      includeCategoriesDisabledAutomatically,
      ...data,
    });
  };

  const updateCategories = (categories: SelectChangeValue): void => {
    const requiredNotificationCategories = !categories
      ? []
      : Array.isArray(categories)
      ? categories.map(({ value }) => value)
      : [categories.value];

    handleChange({ requiredNotificationCategories });
  };

  const excludeEmailNotificationsBlockedWithDefault =
    excludeEmailNotificationsBlocked ?? true;

  return (
    <>
      <Select
        hideTags
        isMulti={true as false}
        isSearchable
        showItemCount
        itemCountPrefix="Required categories"
        placeholder=""
        className={styles.select}
        value={selectedCategories}
        onChange={updateCategories}
        options={NOTIFICATION_CATEGORIES}
      />
      <Checkbox
        className={styles.checkbox}
        disabled={!selectedCategories.length}
        onChange={(e) =>
          handleChange({
            requireAllNotificationCategories: e.target.checked
              ? e.target.checked
              : undefined,
          })
        }
        checked={requireAllNotificationCategories}
        label="Require all selected categories"
        margin="none"
      />
      <Checkbox
        className={styles.checkbox}
        onChange={(e) =>
          handleChange({
            includePausedNotifications: e.target.checked
              ? e.target.checked
              : undefined,
          })
        }
        checked={includePausedNotifications}
        label="Include paused notifications"
        margin="none"
      />
      <Checkbox
        className={styles.checkbox}
        onChange={(e) =>
          handleChange({
            excludeEmailNotificationsBlocked: !e.target.checked,
          })
        }
        checked={!excludeEmailNotificationsBlockedWithDefault}
        label="Include suppressed builders"
        margin="none"
      />
      {!excludeEmailNotificationsBlockedWithDefault && (
        <div className={styles.nestedCheckbox}>
          <Checkbox
            className={styles.checkbox}
            onChange={(e) =>
              handleChange({
                requireEmailNotificationsBlocked: e.target.checked
                  ? e.target.checked
                  : undefined,
              })
            }
            checked={requireEmailNotificationsBlocked}
            label="Only show suppressed builders"
            margin="none"
          />
        </div>
      )}
      <Checkbox
        className={styles.checkbox}
        onChange={(e) =>
          handleChange({
            includeCategoriesDisabledAutomatically: e.target.checked,
          })
        }
        checked={includeCategoriesDisabledAutomatically}
        label="Include sunsetted builders"
        margin="none"
      />
      {includeCategoriesDisabledAutomatically && (
        <div className={styles.nestedCheckbox}>
          <Checkbox
            className={styles.checkbox}
            onChange={(e) =>
              handleChange({
                requireAutomaticallyDisabledCategories: e.target.checked
                  ? e.target.checked
                  : undefined,
              })
            }
            checked={requireAutomaticallyDisabledCategories}
            label="Only show sunsetted builders"
            margin="none"
          />
        </div>
      )}
    </>
  );
};

export default NotificationPreferences;
