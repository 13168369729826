import MainLayout from '@src/layouts/Main';
import { observer } from 'mobx-react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import BuilderForm from './BuilderForm';
import CompanyForm from './CompanyForm';
import { Breakpoints } from '@ateams/components';
import cx from 'classnames';

export const useInviteStyles = createUseStyles({
  container: {
    paddingTop: 20,
    paddingBottom: 100,
    paddingLeft: 16,
    paddingRight: 16,
  },

  formsContainer: {
    marginTop: 40,
    display: 'flex',
    gap: 40,
    justifyContent: 'center',
    flexDirection: 'column',
  },

  title: {
    marginTop: 40,
    marginBottom: 8,
    fontSize: 24,
    fontWeight: 600,
    textAlign: 'start',
  },

  body: {
    fontSize: 15,
    fontWeight: 400,
    textAlign: 'center',
  },

  builderFormContainer: {
    background: 'linear-gradient(180deg, #F8F8F8 0%, #FFF 50%);',
  },
  companyFormContainer: {
    background: 'linear-gradient(180deg, #F7F4FF 0%, #FFF 50%)',
  },

  companyBuilderFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 16,
    border: '1px solid #DADADC',

    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.06), 0px 5px 3px -2px rgba(0, 0, 0, 0.02)',
    padding: 16,
  },

  formTitle: {
    marginTop: 16,
    marginBottom: 8,
    fontSize: 20,
    fontWeight: 500,
    textAlign: 'center',
  },

  greyText: {
    color: '#62646A',
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
  },

  pageDesc: {
    textAlign: 'start',
  },

  howReferralsWorkButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
  },

  learnMoreLink: {
    color: '#7000E3',
  },

  infoContainer: {
    width: '100%',
    display: 'flex',
    marginTop: 24,
    justifyContent: 'space-between',
  },

  steps: {
    marginTop: 16,
    border: '1px solid #E8D0FF',

    '& > div:not(:last-child)': {
      borderBottom: '1px solid #E8D0FF',
    },
  },

  step: {
    color: '#62646A',
    background: '#EDE0FF',
    borderRadius: 100,
    padding: '4px 10px',
    whiteSpace: 'nowrap',
  },

  stepContainer: {
    padding: 16,
    display: 'flex',

    gap: 16,
  },

  stepDesc: {
    textAlign: 'left',
  },

  questionsStep: {
    background: '#FBF7FF',
    textAlign: 'center',
    padding: 16,
  },

  icon: {
    display: 'flex',
    alignItems: 'center',
  },

  divider: {
    background: '#DADADC',
    height: 1,
    width: '100%',
    margin: '16px 0',
  },
  formGroup: {
    marginBottom: '20px',
    width: '100%',
  },
  label: {
    display: 'block',
    marginBottom: '8px',
    fontSize: '14px',
    fontWeight: '400',
    color: '#222',
  },
  input: {
    width: '100%',
    padding: '12px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    '&::placeholder': {
      color: '#999',
    },
  },
  formDesc: {
    marginTop: 0,
    marginBottom: '8px',
    color: '#62646A',
  },
  textarea: {
    width: '100%',
    padding: '12px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    minHeight: '100px',
    resize: 'vertical',
    '&::placeholder': {
      color: '#999',
    },
  },
  submitButton: {
    background: '#6D00D7',
    padding: '8px 16px',
    fontSize: '14px',
    color: '#fff',
    borderRadius: '8px',
    border: 'none',
    height: 40,
    width: '100%',
  },
  formIntro: {
    width: '100%',
    marginBottom: 16,
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btnContainerBuilder: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 24,
  },
  featured: {
    display: 'flex',
    fontSize: 14,
    color: '#62646A',
    gap: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },

  error: {
    color: '#fe640b',
    fontSize: 14,
  },

  [`@media (min-width: ${Breakpoints.md}px)`]: {
    formsContainer: {
      marginTop: 40,
      display: 'flex',
      gap: 40,
      justifyContent: 'center',
      flexDirection: 'row',
    },
    container: {
      paddingTop: 0,
      paddingBottom: 100,
    },
    companyBuilderFormContainer: {
      maxWidth: 530,
      padding: 40,
    },
    btnContainerBuilder: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 0,
    },
    submitButton: {
      width: 'fit-content',
    },
    pageDesc: {
      textAlign: 'center',
    },
    title: {
      textAlign: 'center',
    },
  },
});

const Invite = () => {
  const styles = useInviteStyles();

  return (
    <MainLayout title="Invite" style={{ padding: 0 }}>
      <div className={styles.container}>
        <div>
          <h1 className={styles.title}>
            Invite Companies and Builders to A.Team
          </h1>
          <p className={cx(styles.body, styles.pageDesc)}>
            Team up with friends and get compensated to grow the A.Team network.
          </p>
        </div>
        <div className={styles.formsContainer}>
          <CompanyForm />
          <BuilderForm />
        </div>
      </div>
    </MainLayout>
  );
};

export default observer(Invite);
