import { SurveyQuestionType } from '@a_team/models/dist/Survey';
import UserAvatar from '@src/components/UserAvatar';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import RatingIndicator from './RatingIndicator';
import cx from 'classnames';
import { ResponseProps } from './ResponseProps';

export type Props = ResponseProps<SurveyQuestionType.RatingWithFreeFormComment>;

const useStyles = createUseStyles({
  userName: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '26px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatarPlaceholder: {
    width: '32px',
    height: '32px',
  },
  noMargin: {
    margin: 0,
  },
  justifyStart: {
    justifyContent: 'flex-start',
  },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
  marginTop: {
    marginTop: '1em',
  },
});

const RatingWithFreeFormCommentResponse = (
  props: Props,
): ReactElement | null => {
  const { question, response, teamPulseSurvey, user, children, ...rest } =
    props;
  const styles = useStyles();

  const profilePictureUrl = user?.profilePictureURL;

  return (
    <div {...rest}>
      <div
        className={cx(
          styles.noMargin,
          styles.row,
          styles.justifySpaceBetween,
          styles.marginTop,
        )}
      >
        <div className={cx(styles.row, styles.justifyStart)}>
          {profilePictureUrl ? (
            <UserAvatar
              size={32}
              src={profilePictureUrl}
              containerStyle={{ marginRight: '8px' }}
            />
          ) : (
            <div className={styles.avatarPlaceholder} />
          )}
          <p className={cx(styles.noMargin, styles.userName)}>
            {user?.fullName ?? 'Unknown user'}
          </p>
        </div>
        <div>
          <RatingIndicator value={response?.value} />
        </div>
      </div>
      <div>
        {response?.comment && <p>{response?.comment}</p>}
        {children}
      </div>
    </div>
  );
};

export default RatingWithFreeFormCommentResponse;
