import {
  Button as CallToActionButton,
  TextColors,
  Select,
  SelectProps,
  Icon,
  IconType,
} from '@ateams/components';
import TimezoneObject from '@a_team/models/dist/TimezoneObject';
import { WorkingHoursSchema } from '@a_team/models/src/WorkingHoursObject';
import SectionHeading from '@src/components/SectionHeading';
import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import Modal, { ModalBasicProps } from '..';
import { getWorkingHoursTimeOptions } from '@src/views/Profile/Sidebar/WorkingHours/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { getCountryFromTimezoneName } from '@src/helpers/time';

interface SelectOption {
  value: string;
  label: string;
}

interface SetTimezoneModalProps extends ModalBasicProps {
  currentUserTimezone: TimezoneObject | undefined;
  workingHours: WorkingHoursSchema | undefined;
  onSubmit: (
    timezone: TimezoneObject,
    workingHours: WorkingHoursSchema,
  ) => void;
}

const useStyles = createUseStyles({
  title: {
    fontSize: 28,
  },
  description: {
    fontSize: 14,
    marginBottom: 24,
  },
  label: {
    color: TextColors.regularLight,
    marginBottom: '12px',
  },
  buttonBox: {
    marginTop: 60,
    display: 'flex',
    gap: '16px',
    '& > button': {
      width: '100%',
      height: 'auto',
    },
  },
  workingHoursLabel: {
    marginTop: 40,
    marginBottom: 20,
  },
});

type SelectRef = SelectProps['ref'];
const SetTimezoneModal = ({
  currentUserTimezone,
  workingHours,
  onClose,
  onSubmit,
  open,
}: SetTimezoneModalProps): ReactElement => {
  const styles = useStyles();
  const [timezone, setTimezone] = useState<TimezoneObject | undefined>(
    currentUserTimezone,
  );
  const defaultValue: WorkingHoursSchema = {
    name: timezone?.name ?? '',
    utcOffset: timezone?.utcOffset ?? 0,
    daily: [
      {
        startTime: 510, // 830
        endTime: 1170, // 1900
      },
    ],
  };

  const [workingHoursState, setWorkingHoursState] =
    useState<WorkingHoursSchema>(defaultValue);

  useEffect(() => {
    if (workingHours && workingHours.daily && workingHours.daily.length > 0) {
      setWorkingHoursState(workingHours);
    }
  }, [workingHours]);

  useEffect(() => {
    if (currentUserTimezone) {
      setTimezone(currentUserTimezone);
    }
  }, [currentUserTimezone]);

  const selectRefs: Record<string, SelectRef> = {
    '0-from': useRef<SelectRef>(),
    '0-to': useRef<SelectRef>(),
    '1-from': useRef<SelectRef>(),
    '1-to': useRef<SelectRef>(),
  };

  const addWorkingHours = () => {
    const newWorkingHours = {
      ...workingHoursState,
      name: timezone?.name ?? '',
      utcOffset: timezone?.utcOffset ?? 0,
    };
    let firstEndTime = 0;
    if (newWorkingHours?.daily && newWorkingHours?.daily?.length > 0) {
      firstEndTime = newWorkingHours.daily[0].endTime;
      // in case of the addition is more than 24 hours - reset the time to 0 to start from 510
      if (firstEndTime + 100 > 1440 * 2) {
        firstEndTime = 0;
      }
    }
    newWorkingHours?.daily?.push({
      startTime: firstEndTime ? firstEndTime + 60 : 510,
      endTime: firstEndTime ? firstEndTime + 90 : 1170,
    });
    setWorkingHoursState(newWorkingHours);
  };

  const removeWorkingHours = (index: number) => {
    const newWorkingHours = {
      ...workingHoursState,
      name: timezone?.name ?? '',
      utcOffset: timezone?.utcOffset ?? 0,
    };
    newWorkingHours?.daily?.splice(index, 1);
    setWorkingHoursState(newWorkingHours);
  };

  const setWorkingHours = (
    index: number,
    value: string,
    isStartTime?: boolean,
  ) => {
    const newWorkingHours = {
      ...workingHoursState,
      name: timezone?.name ?? '',
      utcOffset: timezone?.utcOffset ?? 0,
    };
    const daily = newWorkingHours?.daily || [];
    if (isStartTime) {
      daily[index].startTime = Number(value.replace(':', ''));
    } else {
      daily[index].endTime = Number(value.replace(':', ''));
    }
    if (daily[index].startTime > daily[index].endTime) {
      daily[index].endTime = daily[index].startTime + 30;
    }
    setWorkingHoursState(newWorkingHours);
  };

  const handleOnSubmit = (
    timezone: TimezoneObject,
    workingHours: WorkingHoursSchema,
  ) => {
    onSubmit(timezone, workingHours);
  };

  const maxCount = 2;

  return (
    <Modal
      onClose={() => {
        onClose();
      }}
      hideCloseButton={true}
      open={open}
      style={{
        maxWidth: '600px',
        width: '100%',
        padding: '24px',
        overflow: 'visible',
      }}
    >
      <div>
        <SectionHeading className={styles.title} isFirst>
          Please edit your working hours
        </SectionHeading>

        <div className={styles.description}>
          You don’t have enough time overlap for this role. Please update your
          working hours to continue.
        </div>

        <div className={cx(styles.workingHoursLabel, styles.label)}>
          Working Hours on {getCountryFromTimezoneName(timezone?.name ?? '')}{' '}
          time
        </div>
        <div>
          {workingHoursState?.daily?.map((daily, i) => {
            const { startTime, endTime } = daily;
            let previousStartTime = 0;
            let previousEndTime = 0;
            if (
              workingHoursState?.daily &&
              workingHoursState?.daily.length > 0
            ) {
              previousStartTime =
                workingHoursState?.daily[i - 1]?.startTime || 0;
              previousEndTime = workingHoursState?.daily[i - 1]?.endTime || 0;
            }
            const workingHoursOptionsStart = getWorkingHoursTimeOptions({
              startingMinutes: previousEndTime,
            });
            const workingHoursOptionsEnd = getWorkingHoursTimeOptions({
              startingMinutes: startTime,
            });
            return (
              <div key={i}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <Select
                    selectRef={selectRefs[`${i}-from`]}
                    defaultValue={workingHoursOptionsStart.find(
                      (e) => Number(e.value) === startTime,
                    )}
                    isOptionDisabled={(option: SelectOption) => {
                      if (!option) return false;
                      return Number(option.value) < previousEndTime;
                    }}
                    hideSelectedOptions={false}
                    options={workingHoursOptionsStart}
                    placeholder={'Start Time'}
                    onChange={(value) => {
                      setWorkingHours(i, value?.value ?? '', true);
                      selectRefs[`${i}-to`].current?.select.selectOption(null);
                    }}
                    margin={'none'}
                    isClearable
                    data-testing-id={'working-hours-start-select'}
                  />
                  <div style={{ width: '40px', textAlign: 'center' }}>
                    {' - '}
                  </div>
                  <Select
                    selectRef={selectRefs[`${i}-to`]}
                    defaultValue={workingHoursOptionsEnd.find(
                      (e) => Number(e.value) === endTime,
                    )}
                    isOptionDisabled={(option: SelectOption) => {
                      if (!option) return false;
                      return (
                        Number(option?.value) <= startTime ||
                        Number(option?.value) < previousStartTime
                      );
                    }}
                    hideSelectedOptions={false}
                    options={workingHoursOptionsEnd}
                    placeholder={'End Time'}
                    onChange={(value) => {
                      setWorkingHours(i, value?.value ?? '', false);
                    }}
                    margin={'none'}
                    isClearable
                    data-testing-id={'working-hours-end-select'}
                  />
                  <div>
                    {!workingHoursState?.daily ||
                    workingHoursState?.daily?.length < maxCount ? (
                      <Icon
                        title={false}
                        type={IconType.PlusOrange}
                        onClick={addWorkingHours}
                        style={{
                          marginLeft: '12px',
                          opacity: 1,
                          pointerEvents: 'auto',
                        }}
                      />
                    ) : (
                      <>
                        {maxCount > 1 && (
                          <Icon
                            title={false}
                            type={IconType.TrashRed}
                            onClick={() => removeWorkingHours(i)}
                            style={{
                              marginLeft: '12px',
                              opacity: 1,
                              pointerEvents: 'auto',
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
                {(startTime > 1440 || endTime > 1440) && (
                  <div>These working hours continue to the next day.</div>
                )}
              </div>
            );
          })}
        </div>

        <div className={styles.buttonBox}>
          <CallToActionButton
            width="auto"
            onClick={() => {
              onClose();
            }}
            size="small"
            color={'regularLight'}
          >
            Cancel
          </CallToActionButton>
          <CallToActionButton
            width="auto"
            onClick={() => {
              if (timezone && workingHoursState) {
                handleOnSubmit(timezone, workingHoursState);
              }
            }}
            disabled={!timezone || !workingHoursState}
            size="small"
            data-testing-id="save-timezone-changes"
          >
            Save
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
};

export default observer(SetTimezoneModal);
