import React, { ReactElement, useState } from 'react';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { VideoPlayer } from '@src/components/VideoPlayer';
import { Tag } from '@ateams/components';

interface Props {
  url: string | undefined;
  onChange: (videoUrl: string) => void;
  onError?: (error: Error) => void;
  showNoChangeVideoWarning?: boolean;
}

export const VideoSelector = (props: Props): ReactElement => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);

  const onUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hasUrl = !!e.target.value.trim().length;
    !hasUrl && setHasError(false);
    props.onChange(e.target.value.trim());
  };

  const onVideoError = (error: Error) => {
    setLoading(false);
    setHasError(true);
    props.onError && props.onError(error);
  };

  const onVideoReady = () => {
    setLoading(false);
    setHasError(false);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <VideoPlayer
        url={props.url}
        loading={loading}
        onReady={onVideoReady}
        onError={onVideoError}
      />
      {props.showNoChangeVideoWarning ? (
        <Tag square color="warning">
          !! Do not change the video URL on platform unless this mission is not
          on the client app. If you change this thumbnails may stop working. !!
        </Tag>
      ) : null}
      <OutlinedInput
        value={props.url}
        onChange={onUrlChange}
        placeholder={'Link to a video about your mission...'}
        style={{ marginBottom: 8 }}
        error={hasError}
      />
      <small style={{ color: '#C0C0C0', fontWeight: 500 }}>
        Supported: YouTube, Vimeo, Facebook, Loom
      </small>
    </div>
  );
};
