import React from 'react';
import { createUseStyles } from 'react-jss';

interface CircleProps {
  id: number;
  selectedId: number;
  onSelect: (id: number) => void;
}

const useStyles = createUseStyles({
  circle: {
    width: 4,
    height: 4,
    background: '#DADADC',
    borderRadius: '50%',
    marginRight: 8, // adjust the margin to get 8px spacing
    cursor: 'pointer',
    '&.selected': {
      width: 8,
      height: 8,
      background: '#6D00D7',
    },
  },
});

const Circle = ({ id, selectedId, onSelect }: CircleProps): JSX.Element => {
  const styles = useStyles();
  const isSelected = id === selectedId;

  return (
    <div
      className={`${styles.circle} ${isSelected ? 'selected' : ''}`}
      onClick={() => onSelect(id)}
    />
  );
};

export default Circle;
