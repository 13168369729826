import React, { ReactElement, ReactNode, CSSProperties } from 'react';
import cx from 'classnames';
import HeadingButton from './HeadingButton';
import { createUseStyles } from 'react-jss';

interface Props {
  isFirst?: boolean;
  children: ReactNode;
  anchor?: string;
  className?: string;
  style?: CSSProperties;
  appendix?: ReactNode;
}

const useStyles = createUseStyles({
  root: {
    marginTop: (props: Props): string | number => (props.isFirst ? 0 : '3em'),
  },
  appendix: {
    fontSize: '15px',
    lineHeight: '24px',
    color: '#C0C0C0',
    marginLeft: '8px',
  },
});

export { HeadingButton };

export default function SectionHeading(props: Props): ReactElement {
  const { children, appendix, anchor, className, style } = props;

  const styles = useStyles(props);

  return (
    <h3
      id={anchor || undefined}
      className={cx(styles.root, className)}
      style={style}
    >
      {children}
      {!!appendix && <span className={styles.appendix}>{appendix}</span>}
    </h3>
  );
}
