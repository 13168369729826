import { observable, action } from 'mobx';
import { Stores } from '@src/stores/index';

export interface ErrorData {
  message: string;
  statusCode?: number;
}

export interface ErrorStoreData {
  error?: ErrorData;
}

export default class ErrorStore implements ErrorStoreData {
  @observable public error: ErrorStoreData['error'];

  private rootStore: Stores;

  public constructor(rootStore: Stores, initialState?: ErrorStoreData) {
    if (initialState) {
      this.error = initialState.error;
    }

    this.rootStore = rootStore;
  }

  public handleError = (err: (Error & ErrorData) | ErrorData): void => {
    this.setError(err);
  };

  @action
  public setError(error: (Error & ErrorData) | ErrorData): void {
    // extract error data

    let { statusCode } = error;
    if (statusCode == null && error instanceof Error) {
      // try to extract original error code from error object
      const { code } = error as { code?: number };

      // check if code is a valid HTTP status code
      if (code != null && code >= 400 && code < 600) {
        statusCode = code;
      }
    }

    // particular error message sent from the jwt library
    if (error.message === 'The token has been revoked.') {
      // delete token from cookie if it's revoked
      this.rootStore.auth.revokeToken();
    }

    this.error = {
      message: error.message,
      statusCode,
    };
  }

  @action
  public clearError(): void {
    this.error = undefined;
  }

  public serialize(): ErrorStoreData {
    return {
      error: this.error,
    };
  }
}
