import { Colors } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import referBadgeIcon from '../assets/refer-badge.svg';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    background: Colors.primaryVeryLight,
    padding: 16,
    borderRadius: 8,
    gap: 16,
    marginBottom: 40,
  },
  icon: {
    height: 48,
    width: 48,
  },
});

interface ReferBadgeProps {
  missionTitle: string;
}

const ReferBadge = ({ missionTitle }: ReferBadgeProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <img className={styles.icon} src={referBadgeIcon} alt="refer" />
      If {missionTitle} hires a builder you refer, you'll get +2 Build Token
      points for every hour they work
    </div>
  );
};

export default ReferBadge;
