import React, { ChangeEvent, useRef, useState } from 'react';
import OutlinedInput, {
  OutlinedInputProps,
} from '@src/components/Inputs/OutlinedInput';
import searchIcon from '@src/components/Inputs/UsersSearchInput/search.svg';
import { useStores } from '@src/stores';
import { UserType } from '@a_team/models/dist/UserObject';
import useClickOutside from '@src/hooks/useClickOutside';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import UserAvatar from '@src/components/UserAvatar';
import useDebounceState from '@src/hooks/useDebounceState';
import { UserItem } from '@src/components/BasicEmailUserSelector/UserItem';
import { EmailFormDataUser } from '@src/views/SkillTargeter/TargeterEmail/EmailForm';
import { Card } from '@ateams/components';

export interface BasicEmailUserSelectorProps {
  selectedUser?: EmailFormDataUser;
  displaySelection?: boolean;
  required?: boolean;
  margin?: OutlinedInputProps['margin'];
  placeholder?: string;
  onSelect(user?: EmailFormDataUser): void;
  disabled?: boolean;
  includeClientUsers?: boolean;
  /**
   * This only have effect if the logged in user is an admin
   */
  onlyAdmins?: boolean;
  noBorder?: boolean;
  name?: string;
  additionalEmails?: string[];
}

const useStyles = createUseStyles({
  menu: {
    padding: 0,
    overflowY: 'scroll',
    margin: 0,
    display: 'block',
    width: '100%',
    maxHeight: 336,
  },
  userIcon: {
    marginRight: 8,
  },
});

const BasicEmailUserSelector = observer(
  (props: BasicEmailUserSelectorProps) => {
    const stores = useStores();
    const {
      users: { adminQueryByString },
    } = stores;
    const { displaySelection = true, disabled = false } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [userResults, setUserResults] = useState<EmailFormDataUser[]>([]);

    const [searchQuery, setSearchQuery] = useDebounceState<string>(
      displaySelection && props.selectedUser ? props.selectedUser.email : '',
      (query) => getUsers(query, props.includeClientUsers ?? false),
      400,
    );

    const menuRef = useRef(null);
    const styles = useStyles();

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
      setSearchQuery(e.target.value);
      !isOpen && setIsOpen(true);
      if (e.target.value === '') {
        props.onSelect();
        setIsOpen(false);
      }
    };

    const onPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
      if (e.keyCode === 8 && props.selectedUser) {
        props.onSelect();
      }
    };

    const getUsers = async (
      query: string,
      includeCompanyUsers = false,
    ): Promise<void> => {
      if (query !== '') {
        const result = await adminQueryByString(query, {
          queryOnlyAdminUsers: props.onlyAdmins,
        });
        const results = result
          .filter(
            (user) =>
              (includeCompanyUsers && user.type === UserType.CompanyUser) ||
              user.type === UserType.User,
          )
          .map((user) => ({
            id: user.uid,
            email: user.email,
            fullName: user.fullName,
            imageUrl: user.profilePictureURL,
          }));
        const extraResults = (props.additionalEmails || [])
          .filter((email) => email.includes(query))
          .map((email) => ({
            email: email,
          }));
        setUserResults([...results, ...extraResults]);
      }
    };

    const onUserSelect = (user: EmailFormDataUser): void => {
      setSearchQuery('');
      props.onSelect(user);
      setIsOpen(false);
    };

    useClickOutside(menuRef, () => setIsOpen(false));

    return (
      <OutlinedInput
        disabled={disabled}
        type="text"
        placeholder={props.placeholder || 'Add a person...'}
        name={props.name || 'team'}
        noBorder={props.noBorder}
        onChange={onChange}
        onKeyDown={onPress}
        value={props.selectedUser?.email || searchQuery}
        required={props.required}
        margin={props.margin}
        icon={
          props.selectedUser ? (
            <UserAvatar
              src={props.selectedUser.imageUrl}
              size={24}
              containerClassName={styles.userIcon}
            />
          ) : (
            searchIcon
          )
        }
        dropdown={
          isOpen && (
            <Card className={styles.menu}>
              <div ref={menuRef}>
                {userResults.map((user) => {
                  return (
                    <UserItem
                      user={user}
                      onUserSelect={onUserSelect}
                      key={user.email}
                    />
                  );
                })}
              </div>
            </Card>
          )
        }
      />
    );
  },
);

export default BasicEmailUserSelector;
