import React from 'react';
import { createUseStyles } from 'react-jss';
import { TextColors } from '@ateams/components';
import icon from './icon.svg';
import { PreVettingLayoutOld } from '@src/layouts/PreVettingOld';

const useStyles = createUseStyles({
  container: {
    height: '100%',
    width: '438px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'Inter',
    color: TextColors.regular,
  },
  logo: {
    width: '94px',
    marginBottom: '28px',
  },
  title: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '26px',
    letterSpecing: '0.002em',
  },
  subTitle: {
    fontSize: '15px',
    lineHeight: '24px',
    margin: '4px 0',
  },
});

export const PreVettingFormSubmittedNotice: React.FC = () => {
  const styles = useStyles();

  return (
    <PreVettingLayoutOld title={`Pre-evaluation`}>
      <div
        className={styles.container}
        data-testing-id={'pre-vetting-form-submitted-notice'}
      >
        <img className={styles.logo} src={icon} alt={'success'} />
        <div className={styles.title}>Thank you!</div>
        <div className={styles.subTitle}>
          The form was submitted successfully. A member from A.Team network will
          be in touch with you shortly.
        </div>
      </div>
    </PreVettingLayoutOld>
  );
};
