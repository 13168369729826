import React, { useState } from 'react';
import cx from 'classnames';
import { useCommonStyles } from '../commonStyles';
import { Icon, IconType } from '@ateams/components';
import { Button } from '@a_team/ui-components';
import SupportedCalendars from './svgs/supported.svg';
import UnsupportedCalendars from './svgs/unsupported.svg';
import { HowToUnsupportedCalendar } from './HowToConnect/HowToUnsupportedCalendar';
import { HowToSupportedCalendar } from './HowToConnect/HowToSupportedCalendar';

interface MultiCalendarsProps {
  onClose: () => void;
  onSupport: () => void;
}

export const MultiCalendars = (props: MultiCalendarsProps) => {
  const styles = useCommonStyles();
  const [showSupportedCalendarHowTo, setShowSupportedCalendarHowTo] =
    useState(false);
  const [showUnsupportedCalendarHowTo, setShowUnsupportedCalendarHowTo] =
    useState(false);

  const handleClose = () => {
    setShowUnsupportedCalendarHowTo(false);
    setShowSupportedCalendarHowTo(false);
  };

  if (showUnsupportedCalendarHowTo) {
    return (
      <HowToUnsupportedCalendar
        title="Connect multiple unsupported calendars"
        copy="Set up a Google Calendar, sync it with your other calendar and then connect the Google Calendar to A.Team."
        onClose={handleClose}
        onSupport={props.onSupport}
      />
    );
  }

  if (showSupportedCalendarHowTo) {
    return (
      <HowToSupportedCalendar
        onClose={handleClose}
        onSupport={props.onSupport}
      />
    );
  }

  return (
    <>
      <div className={styles.hero}>
        <h4 className={styles.modalTitle}>
          Using multiple calendars? We’ve got you covered.
        </h4>
        <p className={cx(styles.heroCopy, 'lessPadding')}>
          Make sure to sync all of your calendars so companies only schedule
          interviews when you’re available.
        </p>
      </div>
      <div className={styles.howtoWrap}>
        <div
          className={cx(styles.panel, 'ghost', 'interactive')}
          onClick={() => setShowSupportedCalendarHowTo(true)}
        >
          <div className={styles.panelHeader}>
            <img
              src={SupportedCalendars}
              className={styles.headerImg}
              alt="Mulitple supported calendars"
            />
            <Icon type={IconType.LinkArrowLeft} size="small" />
          </div>
          <h5 className={styles.panelTitle}>
            Connect multiple supported calendars
          </h5>
          <p className={styles.panelCopy}>
            It’s easy to connect multiple supported calendars (Google, Apple,
            Microsoft) by using our built in calendar integrations.
          </p>
        </div>
        <div
          className={cx(styles.panel, 'ghost', 'interactive')}
          onClick={() => setShowUnsupportedCalendarHowTo(true)}
        >
          <div className={styles.panelHeader}>
            <img
              src={UnsupportedCalendars}
              className={styles.headerImg}
              alt="Multiple un-supported calendars"
            />
            <Icon type={IconType.LinkArrowLeft} size="small" />
          </div>
          <h5 className={styles.panelTitle}>
            Connect multiple unsupported calendars
          </h5>
          <p className={styles.panelCopy}>
            Sync your unsupported calendars to a Google Calendar and then
            connect your Google Calendar to A.Team.
          </p>
        </div>
        <div className={styles.actions} style={{ paddingTop: 32 }}>
          <Button
            className={styles.closeBtn}
            variant="secondary"
            size="sm"
            onClick={props.onClose}
          >
            Back
          </Button>
        </div>
      </div>
    </>
  );
};
