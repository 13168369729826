import MissionLayout from '@src/layouts/Mission';
import { MissionPageLocation, MissionControlBase } from '@src/locations';
import { useStores } from '@src/stores';
import { loadMission, MissionMatch } from '@src/url-loaders/loadMission';
import { observer } from 'mobx-react';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Redirect } from 'react-router-dom';
import PaymentNotification from '../TimeTracking/PaymentNotification';
import SectionHeading from '@src/components/SectionHeading';
import TeamPulseSurveyModal from './components/TeamPulseSurveyModal';
import { TeamPulseSurvey } from '@a_team/models/dist/TeamPulse';
import TeamBarometerCard from './components/TeamBarometerCard';
import DetailedTeamBarometerCard from './components/DetailedTeamBarometerCard';
import { useQuery } from '@src/hooks/useQuery';
import { apiTeamPulse } from '@ateams/api';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import TeamPulseModal from './components/newTeamPulseModal/TeamPulseModal';

export interface MissionTeamPulseViewProps {
  match: MissionMatch;
  adminView?: boolean;
}

const useStyles = createUseStyles({
  heading: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '26px',
    letterSpacing: '0.001em',
    color: '#222222',
    marginTop: '32px',
    marginBottom: '32px',
    '&:first-child': {
      marginTop: 0,
    },
  },
  cardGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: '-12px',
    '& > *': {
      margin: '12px',
    },
  },
});

export const missionTeamPulseViewLoader = loadMission;

const MissionTeamPulseView = observer(
  ({ match, adminView }: MissionTeamPulseViewProps): ReactElement | null => {
    const styles = useStyles();
    const stores = useStores();
    const { missions, auth } = stores;
    const { currentMission } = missions;
    const [showTeamPulseSurvey, setShowTeamPulseSurvey] = useState(false);
    const [nextTeamPulseSurvey, setNextTeamPulseSurvey] = useState<
      TeamPulseSurvey | undefined
    >();
    const query = useQuery();
    const [loading, setLoading] = useLoadingState();

    useEffect(() => {
      const star = query.get('star');
      setNextTeamPulseSurvey(currentMission?.nextTeamPulseSurvey);
      if (currentMission?.nextTeamPulseSurvey && star) {
        setShowTeamPulseSurvey(true);
        setLoading(
          apiTeamPulse.submitTeamPulseSurveyResponse(auth, {
            response: {
              overallRating: {
                value: parseInt(query.get('star') as string),
              },
            },
            teamPulseSurveyId:
              currentMission?.nextTeamPulseSurvey.teamPulseSurveyId,
          }),
        );
      }
    }, [currentMission?.nextTeamPulseSurvey]);

    const defaultTeampulseResponses = useMemo(() => {
      const starString = query.get('star');
      if (!starString) {
        return {};
      }
      const starNum = parseInt(query.get('star') as string);
      if (isNaN(starNum)) {
        return {};
      } else {
        return {
          overallRating: {
            value: {
              value: starNum,
            },
            valid: true,
          },
        };
      }
    }, []);

    if (!currentMission) {
      return null;
    }

    const notAllowed =
      !auth.isAdmin && (adminView || currentMission.teamPulseTabDisabled);

    if (notAllowed) {
      return (
        <Redirect
          to={
            match.params.mid
              ? MissionPageLocation(match.params.mid)
              : MissionControlBase
          }
        />
      );
    }

    const latestTeamPulse = currentMission.latestTeamPulse;
    const oldTeamPulses = currentMission.oldTeamPulses;

    return (
      <MissionLayout
        title={`${currentMission.data.title} | TeamPulse`}
        match={match}
      >
        <LoadingIndicator
          successMsg={`Team Pulse successfully submitted`}
          loading={loading}
        />
        <div>
          {(latestTeamPulse || adminView) && (
            <SectionHeading className={styles.heading}>
              {adminView ? 'Team Barometer' : 'Current Scores'}
            </SectionHeading>
          )}

          {currentMission.nextTeamPulseSurvey && (
            <PaymentNotification
              title="It's Time to Respond to your Team Pulse survey!"
              text={
                'You have an open Team Pulse survey to complete for this mission.'
              }
              buttonText={'Send feedback'}
              withButton
              onButtonClick={() => {
                setShowTeamPulseSurvey(true);
              }}
              style={{ maxWidth: '912px', marginBottom: '32px' }}
            />
          )}

          {nextTeamPulseSurvey && showTeamPulseSurvey ? (
            nextTeamPulseSurvey?.type ? (
              <TeamPulseModal
                teamPulseSurvey={nextTeamPulseSurvey}
                onClose={() => {
                  setShowTeamPulseSurvey(false);
                  setNextTeamPulseSurvey(currentMission.nextTeamPulseSurvey);
                }}
              />
            ) : (
              <TeamPulseSurveyModal
                defaultResponses={defaultTeampulseResponses}
                teamPulseSurvey={nextTeamPulseSurvey}
                onClose={() => {
                  setShowTeamPulseSurvey(false);
                  setNextTeamPulseSurvey(currentMission.nextTeamPulseSurvey);
                }}
              />
            )
          ) : null}

          {latestTeamPulse && (
            <DetailedTeamBarometerCard
              teamPulse={latestTeamPulse}
              teamPulseSurveys={
                adminView
                  ? currentMission.teamPulseSurveys?.filter(
                      (survey) =>
                        survey.teamPulseId === latestTeamPulse.teamPulseId,
                    )
                  : undefined
              }
              enableResponseModal={adminView}
              adminView={adminView}
            />
          )}

          {oldTeamPulses && oldTeamPulses.length > 0 && (
            <>
              <h2 className={styles.heading}>Past Scores</h2>
              <div className={styles.cardGrid}>
                {oldTeamPulses.reverse().map((teamPulse, i) => (
                  <TeamBarometerCard
                    key={i}
                    teamPulse={teamPulse}
                    teamPulseSurveys={
                      adminView
                        ? currentMission.teamPulseSurveys?.filter(
                            (survey) =>
                              survey.teamPulseId === teamPulse.teamPulseId,
                          )
                        : undefined
                    }
                    enableResponseModal={adminView}
                    adminView={adminView}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </MissionLayout>
    );
  },
);

export default MissionTeamPulseView;
