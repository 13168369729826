import React from 'react';
import AteamRole from './AteamRole';
import Honesty from './Honesty';
import InclusivityAndSafety from './InclusivityAndSafety';
import Intro from './Intro';
import ResponsibleCommunity from './ResponsibleCommunity';

const PlatformCodeOfConduct = (): JSX.Element => {
  return (
    <div>
      <h2>Platform Code of Conduct </h2>
      <Intro />
      <Honesty />
      <ResponsibleCommunity />
      <InclusivityAndSafety />
      <AteamRole />
    </div>
  );
};

export default PlatformCodeOfConduct;
