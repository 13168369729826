import React, { ReactElement, useState, ReactNode, CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode;
  label: ReactNode | null;
  style?: CSSProperties;
  rootStyle?: CSSProperties;
  onHover?: (bool: boolean) => void;
}

const useStyles = createUseStyles({
  root: { position: 'relative', display: 'inline-block' },
  tooltip: {
    position: 'absolute',
    top: '-80px',
    width: '178px',
    left: 'calc(50% - 89px)',
    background: '#222222',
    borderRadius: '8px',
    padding: '16px',
    color: '#fff',
  },
});

export default function Tooltip(props: Props): ReactElement {
  const { onHover } = props;
  const styles = useStyles();
  const [hovering, setHovering] = useState(false);

  const handleHover = (bool: boolean): void => {
    setHovering(bool);
    if (onHover) onHover(bool);
  };

  return (
    <div
      onMouseEnter={(): void => handleHover(true)}
      onMouseLeave={(): void => handleHover(false)}
      className={styles.root}
      style={props.rootStyle}
    >
      {hovering && props.label && (
        <div className={styles.tooltip} style={props.style}>
          {props.label}
        </div>
      )}
      {props.children}
    </div>
  );
}
