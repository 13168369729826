import { observer } from 'mobx-react';
import VettingFeedbackFormV2 from './VettingFeedbackFormV2';
import VettingFeedbackFormView from '../VettingFeedbackForm';
import React, { useEffect, useMemo, useState } from 'react';
import { useStores } from '@src/stores';
import { useLocation, useParams } from 'react-router-dom';
import { VettingFeedbackFormDefaultsV2 } from '@ateams/api/dist/endpoints/vetting-process-feedback-v2';
import LoadingIndicator from '@src/components/LoadingIndicator';
import VettingFeedbackFormViewModeView from '@src/views/VettingFeedbackForm/view-mode';

const VettingFeedbackFormContainer = () => {
  const { auth, vettingProcessesV2 } = useStores();
  const { vpid } = useParams<{
    vpid: string;
  }>();
  const [feedbackFormDefaults, setFeedbackFormDefaults] = useState<
    VettingFeedbackFormDefaultsV2 | undefined
  >(undefined);
  const location = useLocation();

  useEffect(() => {
    const fetchFeedbackFormDefaults = async () => {
      const defaults = await vettingProcessesV2.getFeedbackFormDefaults(vpid);
      setFeedbackFormDefaults(defaults);
    };
    fetchFeedbackFormDefaults();
  }, [vpid]);

  const showNewEvaluationFeedbackForm = useMemo(() => {
    return (
      (auth.withNewEvaluationFeedbackForm &&
        feedbackFormDefaults?.fromNewEvaluationFeedbackForm === undefined) ||
      feedbackFormDefaults?.fromNewEvaluationFeedbackForm === true
    );
  }, [auth.withNewEvaluationFeedbackForm, feedbackFormDefaults]);

  const isViewMode = useMemo(() => {
    return (
      feedbackFormDefaults?.isViewMode ||
      location.pathname.includes('/feedback-form/view/')
    );
  }, [feedbackFormDefaults?.isViewMode, location.pathname]);

  if (!auth.user || !feedbackFormDefaults) {
    return <LoadingIndicator loading />;
  }

  return showNewEvaluationFeedbackForm ? (
    <VettingFeedbackFormV2
      vpid={vpid}
      feedbackFormDefaults={feedbackFormDefaults}
      isViewMode={isViewMode}
    />
  ) : isViewMode ? (
    <VettingFeedbackFormViewModeView />
  ) : (
    <VettingFeedbackFormView />
  );
};

export default observer(VettingFeedbackFormContainer);
