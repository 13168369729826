import React, { ReactElement, useMemo } from 'react';
import {
  MissionCardObject,
  MissionStatus,
} from '@a_team/models/dist/MissionObject';
import MissionControlCard, { CardType } from '..';
import ApplicationStatus from '../ApplicationStatus';
import ApplicationDetails from '../ApplicationDetails';
import {
  CurrentUserMissionApplicationObject,
  CurrentUserMissionApplicationStatus,
  CurrentUserMissionApplicationStatusLabels,
  MissionApplicationLowCompetitivenessReason,
  MissionApplicationLowCompetitivenessReasonDescriptions,
  MissionApplicationLowCompetitivenessReasonLabels,
} from '@a_team/models/dist/MissionApplicationObject';
import ActionButton from '../ActionButton';
import { Breakpoints, Divider, Tag } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { MissionApplyEditLocation } from '@src/locations';
import { useStores } from '@src/stores';
import { RecommendationObject } from '@a_team/models/dist/RecommendationObject';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';

interface Props {
  mission?: MissionCardObject;
  application?: CurrentUserMissionApplicationObject;
  onWithdrawClick?: (
    mid: string,
    application: CurrentUserMissionApplicationObject,
  ) => void;
  loading?: boolean;
  userRecommendations?: RecommendationObject[];
  hideInvite?: boolean;
}

const useStyles = createUseStyles({
  '@global': {
    '.tippy-popper div': {
      borderRadius: '4px !important',
    },
  },
  desktopTooltip: {
    display: 'none',
  },
  mobileTooltip: {
    display: 'block',
    fontSize: 12,
    lineHeight: 'initial',
  },
  divider: {
    margin: '16px 0',
  },
  tag: {
    background: '#383A3D',
    color: '#fff',
    border: '1px solid #494B50',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    desktopTooltip: {
      display: 'block',
    },
    mobileTooltip: {
      display: 'none',
    },
  },
});

const useMissionCardStyles = createUseStyles({
  withdrawButton: {
    background: '#EFEFF0',
    height: '40px',
    padding: '0 24px',
    borderRadius: '20px',
  },
});

export const LowCompTooltip = (props: {
  reasons?: MissionApplicationLowCompetitivenessReason[];
}): ReactElement | null => {
  const styles = useStyles();

  return (
    <>
      {/* Shown on desktop */}
      <div
        className={styles.desktopTooltip}
        style={{ fontSize: 12, textAlign: 'left' }}
      >
        <h3 className={styles.title}>
          {CurrentUserMissionApplicationStatusLabels.LowCompetitiveness}
        </h3>
        <Divider color={'#38393D'} className={styles.divider} />
        {props.reasons?.map((reason) => (
          <div className={styles.tagContainer} key={reason}>
            <Tag className={styles.tag} thin>
              {MissionApplicationLowCompetitivenessReasonLabels[reason]}
            </Tag>

            <p
              style={{ color: '#C0C0C0' }}
              className={styles['reason-description']}
            >
              {MissionApplicationLowCompetitivenessReasonDescriptions[reason]}
            </p>
          </div>
        ))}
      </div>
      {/* Shown on mobile */}
      <div className={styles.mobileTooltip}>
        {props.reasons
          ?.map(
            (reason) =>
              MissionApplicationLowCompetitivenessReasonLabels[reason],
          )
          .join(', ')}
      </div>
    </>
  );
};

const AppliedMissionCard = (props: Props): ReactElement => {
  const { mission, application, onWithdrawClick, loading } = props;
  const styles = useMissionCardStyles();
  const { auth } = useStores();

  const hourlyRate = useMemo(() => {
    let rate = 'Unspecified';
    if (application?.hourlyRateRange)
      rate = `$${application.hourlyRateRange.min}-$${application.hourlyRateRange.max}/hour`;
    return rate;
  }, [application?.hourlyRateRange?.min, application?.hourlyRateRange?.max]);

  const handleWithdrawClick = () => {
    if (!mission || !application) return;
    onWithdrawClick && onWithdrawClick(mission.mid, application);
  };

  const canEditApplication = useMemo(() => {
    if (!application) return false;
    const endedApplicationStatuses = [
      CurrentUserMissionApplicationStatus.MissionCancelled,
      CurrentUserMissionApplicationStatus.MissionEnded,
      CurrentUserMissionApplicationStatus.RoleCancelled,
      CurrentUserMissionApplicationStatus.ConfirmedAnotherBuilder,
      CurrentUserMissionApplicationStatus.NotAvailable,
      CurrentUserMissionApplicationStatus.Rejected,
      CurrentUserMissionApplicationStatus.Accepted,
    ];

    if (
      auth.withViewPastApplications &&
      (mission?.status === MissionStatus.Ended ||
        mission?.status === MissionStatus.Archived)
    ) {
      return false;
    }

    const applicationRole = mission?.roles.find(
      (role) => role.rid === application.rid,
    );

    if (
      auth.withViewPastApplications &&
      (applicationRole?.status === MissionRoleStatus.Ended ||
        applicationRole?.status === MissionRoleStatus.Canceled)
    ) {
      return false;
    }

    return !endedApplicationStatuses.includes(application.status);
  }, [application?.status]);

  const redirectUrl = useMemo(() => {
    if (!mission || !application) return undefined;
    return canEditApplication
      ? MissionApplyEditLocation(mission.mid, application.rid, application.aid)
      : undefined;
  }, [mission?.mid, application?.aid]);

  const useStatusV2 = auth.withMissionApplicationStatusV2;

  return (
    <MissionControlCard
      hideInvite={props.hideInvite}
      type={CardType.applied}
      loading={loading}
      linkTo={redirectUrl}
      canEditApplication={canEditApplication}
      clickable={canEditApplication || auth.withViewPastApplications}
      mission={mission}
      application={application}
      userRecommendations={props.userRecommendations}
      className={
        application?.status ===
        CurrentUserMissionApplicationStatus.LowCompetitiveness
          ? 'lowCompetitiveness'
          : undefined
      }
      badge={
        !useStatusV2 && application ? (
          <ApplicationStatus
            status={application.status}
            tooltip={
              application.status ===
              CurrentUserMissionApplicationStatus.LowCompetitiveness ? (
                <LowCompTooltip reasons={application.lowCompetitiveness} />
              ) : undefined
            }
          />
        ) : undefined
      }
      details={
        !application ? (
          <div />
        ) : (
          <ApplicationDetails
            role={application.roleCategory.title}
            commitment={`${application.availability.hoursPerWeek} hours/week`}
            hourlyRate={hourlyRate}
            dateApplied={application.createdAt}
          />
        )
      }
      fullWidth={false}
      descriptionLines={2}
      actionButton={
        application?.canWithdraw && canEditApplication ? (
          <ActionButton
            type="withdraw"
            className={styles.withdrawButton}
            onClick={handleWithdrawClick}
          />
        ) : undefined
      }
    />
  );
};

export default AppliedMissionCard;
