import { TalentCategoryId } from './TalentCategories';

export type RoleCategoryId = string;

export enum RoleCategoryAnchor {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
}

export enum GroupName {
  Developers = 'Developers',
  Designers = 'Designers',
  ProductManagers = 'Product Managers',
  DataSpecialists = 'Data Specialists',
  Marketing = 'Marketing',
  Others = 'Others',
}

export default interface RoleCategoryObject {
  cid: RoleCategoryId;
  title: string;
  group: GroupName;
  anchors: RoleCategoryAnchor[];
  talentCategoryIds: TalentCategoryId[];
}
