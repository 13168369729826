import React from 'react';
import { createUseStyles } from 'react-jss';

import { CommunityBadgeType } from '@a_team/models/dist/UserObject';
import { DateISOString } from '@a_team/models/dist/misc';
import cx from 'classnames';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { availableCommunityBadges } from './AvailableCommunityBadges';
import { observer } from 'mobx-react';

interface CommunityBadgeProps {
  communityBadgeType: CommunityBadgeType;
  date: DateISOString;
  showBadgeDetails?: boolean;
  badgeSize?: number;
  className?: string;
  style?: React.CSSProperties;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    gap: 16,
    padding: 16,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 8,
  },
  title: {
    color: ' #222222',
    fontWeight: 500,
    fontSize: 15,
    lineHeight: '24px',
  },
  desc: {
    fontSize: 12,
    lineHeight: '16px',
  },
  date: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#818388',
  },
  badge: ({ badgeSize }) => ({
    width: badgeSize || 80,
    height: badgeSize || 80,
    aspectRatio: '1/1',
  }),
});

const CommunityBadge = (props: CommunityBadgeProps): JSX.Element => {
  const styles = useStyles(props);

  const { title, desc, badgeV2 } =
    availableCommunityBadges[props.communityBadgeType];

  const dateObj = new Date(props.date);
  const month = dateObj.toLocaleString('default', { month: 'long' });
  const year = dateObj.getFullYear();

  return (
    <div style={props.style} className={cx(styles.container, props.className)}>
      <div>
        <TooltipWrapped
          title={title}
          theme={'dark'}
          position={'top'}
          arrow
          animation="fade"
          style={{ display: 'flex' }}
        >
          <img className={styles.badge} src={badgeV2} alt={title} />
        </TooltipWrapped>
      </div>
      {props.showBadgeDetails && (
        <div className={styles.content}>
          <div>
            <div className={styles.title}>{title}</div>
            <div className={styles.desc}>{desc}</div>
          </div>
          <div className={styles.date}>
            {month} {year}
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(CommunityBadge);
