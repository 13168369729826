import { apiTeamPulse } from '@ateams/api';
import { TeamPulseSurveyType } from '@a_team/models/dist/TeamPulse';
import { Button as CallToActionButton } from '@ateams/components';
import useLoadingState from '@src/hooks/useLoadingState';
import MissionLayout from '@src/layouts/Mission';
import {
  MissionAdminTeamPulseLocation,
  MissionPageLocation,
  MissionControlBase,
} from '@src/locations';
import { useStores } from '@src/stores';
import { loadMission, MissionMatch } from '@src/url-loaders/loadMission';
import { observer } from 'mobx-react';
import React, { MouseEvent, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import DropdownInput from '@src/components/Inputs/DropdownInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { createUseStyles } from 'react-jss';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { ReactElement } from 'react';

export interface Props {
  match: MissionMatch;
  adminView?: boolean;
}

export const missionCreateTeamPulseViewLoader = loadMission;

const useStyles = createUseStyles({
  noMargin: {
    margin: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  marginLeft: {
    marginLeft: '1em',
  },
});

const MissionCreateTeamPulseView = observer(
  ({ match, adminView }: Props): ReactElement | null => {
    const styles = useStyles();
    const stores = useStores();
    const history = useHistory();
    const { missions, auth } = stores;
    const { currentMission } = missions;
    const [loading, setLoading, error] = useLoadingState();

    const [teamPulseSurveyType, setTeamPulseSurveyType] = useState(
      TeamPulseSurveyType.Simple,
    );
    const [coveringIntervalStartDate, setCoveringIntervalStartDate] =
      useState<Date | null>(null);
    const [coveringIntervalEndDate, setCoveringIntervalEndDate] =
      useState<Date | null>(null);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const mid = currentMission?.mid;

    if (!mid || !currentMission) {
      return null;
    }

    const createTeamPulse = async (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (!coveringIntervalStartDate || !coveringIntervalEndDate) {
        return;
      }
      setLoading(
        apiTeamPulse
          .adminCreateTeamPulse(auth, {
            coveringInterval: {
              start: coveringIntervalStartDate,
              end: coveringIntervalEndDate,
            },
            missionId: mid,
            teamPulseSurveyType: teamPulseSurveyType,
            responseDateInterval: {
              start: startDate,
              end: endDate,
            },
          })
          .then(() => currentMission.refreshTeamPulse(true))
          .then(() =>
            setTimeout(
              () => history.push(MissionAdminTeamPulseLocation(mid)),
              500,
            ),
          ),
      );
    };

    const notAllowed = !auth.isAdmin || !adminView;

    if (notAllowed) {
      return (
        <Redirect
          to={
            match.params.mid
              ? MissionPageLocation(match.params.mid)
              : MissionControlBase
          }
        />
      );
    }

    return (
      <MissionLayout
        title={`${currentMission.data.title} | Create a Team Pulse`}
        match={match}
      >
        <div style={{ maxWidth: '912px' }}>
          <div>
            <h1>Create a Team Pulse</h1>
            <form>
              <label>
                <p>Survey type</p>
                <DropdownInput
                  value={teamPulseSurveyType}
                  onChange={(e) => {
                    setTeamPulseSurveyType(
                      e.currentTarget.value as TeamPulseSurveyType,
                    );
                  }}
                >
                  <option value={TeamPulseSurveyType.Simple}>Simple</option>
                  <option value={TeamPulseSurveyType.Detailed}>Detailed</option>
                </DropdownInput>
              </label>
              <div className={styles.row}>
                <label>
                  <p className={styles.noMargin}>Covering period start date</p>
                  <DatePicker
                    selected={coveringIntervalStartDate}
                    required={true}
                    onChange={(date) =>
                      setCoveringIntervalStartDate(date as Date | null)
                    }
                    popperPlacement="top-end"
                    customInput={
                      <OutlinedInput placeholder="Covering period start date" />
                    }
                  />
                </label>
                <label className={styles.marginLeft}>
                  <p className={styles.noMargin}>Covering period end date</p>
                  <DatePicker
                    selected={coveringIntervalEndDate}
                    required={true}
                    onChange={(date) =>
                      setCoveringIntervalEndDate(date as Date | null)
                    }
                    popperPlacement="top-end"
                    customInput={
                      <OutlinedInput placeholder="Covering period end date" />
                    }
                  />
                </label>
              </div>
              <div className={styles.row}>
                <label>
                  <p className={styles.noMargin}>Open from</p>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date as Date | null)}
                    maxDate={endDate}
                    popperPlacement="top-end"
                    customInput={<OutlinedInput placeholder="Start date" />}
                  />
                </label>
                <label className={styles.marginLeft}>
                  <p className={styles.noMargin}>Open until</p>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date as Date | null)}
                    minDate={startDate}
                    popperPlacement="top-end"
                    customInput={<OutlinedInput placeholder="End date" />}
                  />
                </label>
              </div>
            </form>
          </div>
          {error && <div>{error.message}</div>}
          <div>
            <LoadingIndicator loading={loading} />
            <CallToActionButton onClick={createTeamPulse}>
              Create Team Pulse
            </CallToActionButton>
          </div>
        </div>
      </MissionLayout>
    );
  },
);

export default MissionCreateTeamPulseView;
