import { UserCardObject } from '@a_team/models/dist/UserObject';
import { useState } from 'react';
import { WhyTheseBuildersReasons } from '../WhyTheseBuildersForm';
import { TeamPulseRecommendedRole } from '@src/views/Mission/TeamPulse/components/newTeamPulseModal/hooks/useTeamPulse';
import { RoleCategorySelectOption } from '@src/views/Mission/TeamPulse/components/newTeamPulseModal/common/RoleMultiSelect';

export const useControlSimpleTeamPulse = () => {
  const [score, setScore] = useState<number | undefined>();
  const [tellUsMore, setTellUsMore] = useState<string | undefined>();

  const [shareHighlights, setShareHighlights] = useState<string | undefined>();
  const [shareProudMoments, setShareProudMoments] = useState<
    string | undefined
  >();

  const [missionNeedsMoreBuilders, setMissionNeedsMoreBuilders] = useState<
    boolean | undefined
  >();
  const [scopedWellDefined, setScopedWellDefined] = useState<
    boolean | undefined
  >();
  const [whatCouldImproveExperience, setWhatCouldImproveExperience] = useState<
    string | undefined
  >();

  const [roleRecommendations, setRoleRecommendations] = useState<
    Array<RoleCategorySelectOption> | undefined
  >();

  const [detailedRoleRecommendations, setDetailedRoleRecommendations] =
    useState<Array<TeamPulseRecommendedRole>>();

  const [whyTheseBuilders, setWhyTheseBuilders] = useState<
    WhyTheseBuildersReasons | undefined
  >();

  const [canWeShareFeedbackWithClient, setCanWeShareFeedbackWithClient] =
    useState<boolean | undefined>();

  const [buildersRecommendedForMission, setBuildersRecommendedForMission] =
    useState<UserCardObject[] | undefined>();

  const onResetState = () => {
    // if user goes back to the same step we don't want to reset the state
    if (score === 1 || score === 2) {
      setScopedWellDefined(undefined);
      setWhatCouldImproveExperience(undefined);
      setMissionNeedsMoreBuilders(undefined);
      setRoleRecommendations(undefined);
      setDetailedRoleRecommendations(undefined);
      setBuildersRecommendedForMission(undefined);
      setWhyTheseBuilders(undefined);
    }

    if (score === 3) {
      setTellUsMore(undefined);
      setMissionNeedsMoreBuilders(undefined);
      setRoleRecommendations(undefined);
      setDetailedRoleRecommendations(undefined);
      setBuildersRecommendedForMission(undefined);
      setWhyTheseBuilders(undefined);
    }

    if (score === 4 || score === 5) {
      setTellUsMore(undefined);
      setMissionNeedsMoreBuilders(undefined);
      setRoleRecommendations(undefined);
      setDetailedRoleRecommendations(undefined);
      setBuildersRecommendedForMission(undefined);
      setWhyTheseBuilders(undefined);
    }
  };

  return {
    score,
    setScore,
    tellUsMore,
    setTellUsMore,
    shareHighlights,
    setShareHighlights,
    shareProudMoments,
    setShareProudMoments,
    missionNeedsMoreBuilders,
    setMissionNeedsMoreBuilders,
    scopedWellDefined,
    setScopedWellDefined,
    whatCouldImproveExperience,
    setWhatCouldImproveExperience,
    roleRecommendations,
    setRoleRecommendations,
    detailedRoleRecommendations,
    setDetailedRoleRecommendations,
    whyTheseBuilders,
    setWhyTheseBuilders,
    buildersRecommendedForMission,
    setBuildersRecommendedForMission,
    canWeShareFeedbackWithClient,
    setCanWeShareFeedbackWithClient,
    onResetState,
  };
};
