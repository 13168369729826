import React, { ReactElement, useState } from 'react';
import { ExistingJob, NewJob } from '@src/stores/Profile/models';
import InlineInput from '@src/components/Inputs/InlineInput';
import { createUseStyles } from 'react-jss';
import { ProfileTooltip } from '@src/views/Profile/ProfileTooltip';
import { Icon, IconType } from '@ateams/components';

interface Props {
  role?: ExistingJob | NewJob;
  updateJob: (job: ExistingJob | NewJob, remove?: boolean) => void;
  onUpdateClick: (job: ExistingJob | NewJob) => void;
  disabled?: boolean;
  tooltip?: string;
  isOwner?: boolean;
  defaultRole?: string;
  disableEditIcon?: boolean;
}

const useStyles = createUseStyles({
  input: {
    maxWidth: '100%',
  },
  tooltip: {
    width: '100%',
  },
  overlayButtons: {
    position: 'absolute',
    right: 0,
    top: '0.5rem',
    background: 'white',
  },
});

export const JobRow = (props: Props): ReactElement => {
  const styles = useStyles();
  const {
    role,
    updateJob,
    disabled,
    tooltip,
    isOwner,
    defaultRole,
    onUpdateClick,
    disableEditIcon,
  } = props;
  const [roleError, hasRoleError] = useState(false);
  const isStructuredCompany = role?.companyV2Id !== undefined;

  const updateName = (name: string) => {
    if (!role) return;

    hasRoleError(!name && !!role.jobRole);
    updateJob({ ...role, name });
  };

  const updateJobRole = (jobRole: string) => {
    if (!role) return;

    hasRoleError(!role.name);
    updateJob({ ...role, jobRole });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!role) return;

    if (!role.name && !role.jobRole && e.key === 'Backspace') {
      updateJob(role, true);
    }
  };

  return (
    <li>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '100%',
          position: 'relative',
        }}
      >
        <ProfileTooltip
          disabled={!tooltip}
          title={tooltip}
          className={styles.tooltip}
        >
          <div
            className="jobInput"
            style={{
              display: 'flex',
              marginTop: '16px',
              flexWrap: 'wrap',
              maxWidth: '100%',
            }}
          >
            <InlineInput
              onChange={(e) => updateName(e.target.value)}
              onKeyDown={handleKeyPress}
              value={role?.name || ''}
              className={styles.input}
              disabled={isStructuredCompany || disabled}
              readOnly={isStructuredCompany}
              inputStyles={{
                fontWeight: 400,
                maxWidth: '100%',
              }}
              error={roleError}
              placeholder={'Company name'}
            />
            {role && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '100%',
                }}
              >
                <InlineInput
                  onChange={(e) => updateJobRole(e.target.value)}
                  disabled={disabled}
                  readOnly={isStructuredCompany}
                  onKeyDown={handleKeyPress}
                  className={styles.input}
                  value={isOwner ? role?.jobRole || '' : defaultRole}
                  placeholder={'Role'}
                  inputStyles={{
                    color: '#62646A',
                    fontWeight: 400,
                    maxWidth: '100%',
                  }}
                />
              </div>
            )}
          </div>
        </ProfileTooltip>
        <div className={styles.overlayButtons}>
          {!disabled && !disableEditIcon && (
            <Icon
              type={IconType.Pencil}
              title={'Edit'}
              size={'small'}
              style={{ marginTop: '0.5rem', marginRight: '0.5rem' }}
              onClick={() => role && onUpdateClick(role)}
            />
          )}
          {!disabled && (
            <Icon
              type={IconType.Close}
              title={'Delete'}
              size={'xsmall'}
              style={{ marginTop: '0.5rem' }}
              testingId={'profile-job-delete-button'}
              onClick={() => role && updateJob(role, true)}
            />
          )}
        </div>
      </div>
    </li>
  );
};
