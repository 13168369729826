import React, { ReactElement, useMemo } from 'react';
import {
  MissionCardObject,
  MissionStatus,
  RecommendedMission,
} from '@a_team/models/dist/MissionObject';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { useStores } from '@src/stores';
import MissionControlCard from '..';
import Flag from '../Flag';
import ActionButton from '../ActionButton';
import MissionRoles from '../MissionRoles';
import { getRecommendationMatchingCriteria } from '../RecommendedMissionCard';
import MatchList from '../MatchList';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';
import { Tag } from '@ateams/components';
import { CurrentUserMissionApplicationObject } from '@a_team/models/dist/MissionApplicationObject';
import ApplicationStatus from '../ApplicationStatus';
import { observer } from 'mobx-react';

interface Props {
  mission?: MissionCardObject | RecommendedMission;
  application?: CurrentUserMissionApplicationObject;
  recommended?: boolean;
  onWithdrawClick?: (
    mid: string,
    application: CurrentUserMissionApplicationObject,
  ) => void;
  loading?: boolean;
}

const AllMissionCard = (props: Props): ReactElement => {
  const {
    mission,
    recommended = false,
    application = null,
    loading,
    onWithdrawClick,
  } = props;
  const { missionControl, auth } = useStores();
  const analytics = useAnalytics();

  const criteria: string[] =
    recommended && mission ? getRecommendationMatchingCriteria(mission) : [];

  const onHide = (): void => {
    if (!mission) return;
    analytics.trackMissionCardNotInterested(mission, recommended, true);
    missionControl.setNotInterested(mission.mid);
  };

  const onWithdraw = () => {
    if (!mission || !application) return;
    onWithdrawClick && onWithdrawClick(mission.mid, application);
  };

  const filteredRoles = useMemo(() => {
    if (!mission) return [];
    return mission.roles.filter(
      (role) => role.status === MissionRoleStatus.Open,
    );
  }, [mission?.roles]);

  const renderBadge = () => {
    const isNotPublished = mission?.status === MissionStatus.Created;

    if (auth.isAdmin && isNotPublished) {
      return <Tag>Not Published</Tag>;
    }

    if (application) {
      return <ApplicationStatus status={application.status} />;
    }

    return undefined;
  };

  const renderHideButton = () => {
    if (!application) {
      return <ActionButton type="hide" onClick={onHide} />;
    }

    if (application.canWithdraw && mission) {
      return <ActionButton type="withdraw" onClick={onWithdraw} />;
    }

    return undefined;
  };

  return (
    <MissionControlCard
      loading={loading}
      mission={mission}
      badge={renderBadge()}
      flag={recommended ? <Flag /> : undefined}
      actionButton={renderHideButton()}
      details={<MissionRoles roles={filteredRoles} />}
      matchList={
        criteria.length ? (
          <MatchList criteria={criteria} numberOnly />
        ) : undefined
      }
      fullWidth={false}
    />
  );
};

export default observer(AllMissionCard);
