import {
  ApplicationStatusNotificationObject,
  NotificationType,
} from '@a_team/models/dist/NotificationObject';
import React from 'react';
import Notification from './Notification';
import { useHistory } from 'react-router-dom';
import { MissionControlAppliedLocation } from '@src/locations';

interface ApplicationStatusNotificationProps {
  notification: ApplicationStatusNotificationObject;
  onMarkAsRead(): void;
  isRead?: boolean;
  toggle: () => void;
}

const ApplicationStatusNotification = ({
  notification,
  onMarkAsRead,
  isRead,
  toggle,
}: ApplicationStatusNotificationProps): JSX.Element => {
  const history = useHistory();

  const onCtaAction = () => {
    onMarkAsRead();
    toggle();
    history.push(MissionControlAppliedLocation);
  };

  return (
    <Notification
      createdAt={notification.createdAt}
      notificationType={NotificationType.ApplicationStatus}
      ctaName="Open mission"
      ctaAction={onCtaAction}
      title={notification.title}
      status={notification.status}
      color={notification.color}
      description={notification.description}
      imageUrl={notification.companyImageUrl}
      onMarkAsRead={onMarkAsRead}
      isRead={isRead}
    />
  );
};

export default ApplicationStatusNotification;
