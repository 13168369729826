import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Modal } from '@a_team/ui-components';
import AspectRatioBox from '@src/components/AspectRatioBox';
import useMediaQuery from '@src/hooks/useMediaQuery';
import { Breakpoints } from '@ateams/components';
import Circle from './Circle';
import Lottie from 'lottie-react';
import status from './status.json';
import teamUpFlow from './team-up-flow.json';

interface ReleaseStepsModalProps {
  setIsWhatsNewModalOpen: (force?: unknown) => void;
  open: boolean;
  onClose: (force?: unknown) => void;
}

const useStyles = createUseStyles({
  modal: {
    // select child div and give it a with of 600
    width: '680px !important',
    '& > div': {
      padding: 0,
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
  },
  content: {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: 16,
  },
  img: {
    width: '100%',
    height: 'auto',
    borderRadius: '16px 16px 0px 0px',
  },
  btnsContainer: {
    marginTop: 30,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    justifyContent: 'space-between',
    width: '100%',
    // select all buttons and give them a width of fit-content
    '& > button': {
      width: 'fit-content',
    },
  },
  nextBtn: {
    justifySelf: 'flex-end',
    background: '#6D00D7 !important',
  },
  circleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ctaBtn: {
    justifySelf: 'flex-end',
  },
});

enum StepsEnum {
  ONE = 1,
  TWO = 2,
}

const ReleaseStepsModal = ({
  setIsWhatsNewModalOpen,
  open,
  onClose,
}: ReleaseStepsModalProps): JSX.Element => {
  const styles = useStyles();
  const [step, setStep] = useState<StepsEnum>(StepsEnum.ONE);
  const isDesktop = useMediaQuery(`(min-width: ${Breakpoints.md}px)`);
  const stepsArray: StepsEnum[] = [StepsEnum.ONE, StepsEnum.TWO];

  const image = useMemo(() => {
    if (step === StepsEnum.ONE) {
      return teamUpFlow;
    }

    return status;
  }, [step, isDesktop]);

  const handleLastStep = () => {
    onClose(false);
  };

  return (
    <Modal
      variant="slideUp"
      className={styles.modal}
      isOpen={open}
      onClose={() => onClose(false)}
    >
      <div>
        <div>
          <AspectRatioBox aspectRatio={17 / 6}>
            <Lottie className={styles.img} animationData={image} loop={true} />
          </AspectRatioBox>
        </div>
        {step === 1 && (
          <div className={styles.content}>
            <div className={styles.title}>Build your team</div>
            <div>
              After you apply to a mission with multiple roles, you’ll be
              invited to build your team. Each builder you invite will need to
              apply to the mission and accept your request for you to be
              considered as a team.
            </div>
            <div className={styles.btnsContainer}>
              <Button
                size="md"
                variant="secondary"
                onClick={() => {
                  setIsWhatsNewModalOpen(true);
                  onClose();
                }}
              >
                Back
              </Button>
              <div className={styles.circleContainer}>
                {stepsArray.map((s) => (
                  <Circle
                    key={s}
                    id={s}
                    onSelect={() => setStep(s)}
                    selectedId={step}
                  />
                ))}
              </div>
              <Button
                className={styles.nextBtn}
                size="md"
                onClick={() => setStep(StepsEnum.TWO)}
              >
                Next
              </Button>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className={styles.content}>
            <div className={styles.title}>Manage your team</div>
            <div>
              Head to Applied Missions to see the status of your application and
              Team Up requests. From there, you can also add more teammates and
              accept new requests.
            </div>
            <div className={styles.btnsContainer}>
              <Button
                size="md"
                variant="secondary"
                onClick={() => setStep(StepsEnum.ONE)}
              >
                Back
              </Button>
              <div className={styles.circleContainer}>
                {stepsArray.map((s) => (
                  <Circle
                    key={s}
                    id={s}
                    onSelect={() => setStep(s)}
                    selectedId={step}
                  />
                ))}
              </div>
              <Button
                className={styles.ctaBtn}
                size="md"
                onClick={handleLastStep}
              >
                Done
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ReleaseStepsModal;
