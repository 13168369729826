import { Checkbox, Select, SelectOption } from '@a_team/ui-components';
import React from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { FormValues } from '.';

interface HasManagedPeopleProps {
  managedPeople: SelectOption[];
  register: UseFormRegister<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  trigger: UseFormTrigger<FormValues>;
  watch: UseFormWatch<FormValues>;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  label: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
});

const HasManagedPeople = ({
  managedPeople,
  trigger,
  setValue,
  register,
  watch,
}: HasManagedPeopleProps): JSX.Element => {
  const styles = useStyles();

  const hasManagedPeople = watch('hasManagedPeople');
  const numberOfPeopleManaged = watch('numberOfPeopleManaged');

  return (
    <div className={styles.container}>
      <div>
        <label className={styles.label}>
          Did this role require you to manage team members? (Optional)
        </label>
      </div>

      <div className={styles.wrapper}>
        <Checkbox
          onChange={() => {
            setValue('hasManagedPeople', !hasManagedPeople, {
              shouldDirty: true,
            });
            trigger('hasManagedPeople');
          }}
          label="Yes, I managed"
          checked={watch('hasManagedPeople')}
        />
        <Select
          styles={{
            control: (base) => ({
              ...base,
              border: '1px solid #DADADC',
              borderColor: '#DADADC !important',
              backgroundColor: 'white',
              width: 80,
            }),
            placeholder: (base) => ({
              ...base,
              color: '#62646A',
            }),
          }}
          {...register('numberOfPeopleManaged')}
          placeholder="0"
          defaultValue={managedPeople.find(
            (option) => option.value === numberOfPeopleManaged,
          )}
          options={managedPeople}
          onChange={(data) => {
            setValue('numberOfPeopleManaged', (data as SelectOption).value, {
              shouldDirty: true,
            });
            trigger('numberOfPeopleManaged');
          }}
          isDisabled={!hasManagedPeople}
        />
        team members.
      </div>
    </div>
  );
};

export default HasManagedPeople;
