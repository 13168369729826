import React, { ReactElement } from 'react';
import TextButton from '@src/components/TextButton';
import { createUseStyles } from 'react-jss';
import { Breakpoints, Colors, TextColors } from '@ateams/components';
import { PrivacyPolicyLocation, TermsOfServiceLocation } from '@src/locations';

const useStyles = createUseStyles({
  footer: {
    display: 'flex',
    fontSize: 12,
    marginBottom: 30,
    marginTop: 30,
    flexWrap: 'wrap',
    justifyContent: 'center',
    color: TextColors.regularLight,
  },
  link: {
    marginRight: 8,
    paddingRight: 8,
    borderRight: `1px solid ${Colors.regular}`,
    flexShrink: 0,
    lineHeight: '13px',
    '&:last-of-type': {
      marginRight: 0,
      paddingRight: 0,
      border: 'none',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    footer: {
      justifyContent: 'flex-start',
      marginBottom: 16,
    },
  },
});

export const Footer = (): ReactElement => {
  const styles = useStyles();
  return (
    <footer className={styles.footer}>
      <span style={{ marginRight: 4 }}>
        ©{new Date().getFullYear()} ATeams Inc., All rights reserved.
      </span>
      <div>
        <TextButton
          highlight
          className={styles.link}
          onClick={() => window.open(TermsOfServiceLocation, '_blank')}
        >
          Terms of Service
        </TextButton>
        <TextButton
          highlight
          className={styles.link}
          onClick={() => window.open(PrivacyPolicyLocation, '_blank')}
        >
          Privacy Policy
        </TextButton>
        <TextButton
          highlight
          className={styles.link}
          onClick={() => window.open('mailto:help@a.team', '_blank')}
        >
          Contact Us
        </TextButton>
      </div>
    </footer>
  );
};
