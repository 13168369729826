import { Stores } from '@src/stores';
import { ProfileViewMode } from '@src/stores/Profile/models';
import {
  BuilderSuggestionId,
  ProfileEnhancementSuggestions,
} from '@a_team/models/dist/ProfileEnhancements';

export const refreshProfile = async (stores: Stores) => {
  await stores.auth.fetchData(true);
  if (stores.users.profile) {
    await stores.users.profile.refreshProfile(ProfileViewMode.View);
  }
};

export const removePartialProfileSuggestion = (
  suggestionKey: keyof ProfileEnhancementSuggestions,
  partialSuggestionId: string,
  suggestions: ProfileEnhancementSuggestions | undefined,
): ProfileEnhancementSuggestions | undefined => {
  if (!suggestions) return suggestions;

  const updatedData = { ...suggestions };

  const suggestion = suggestions[suggestionKey];

  if (suggestion) {
    if ('bio' in suggestion && suggestionKey === 'bio') {
      updatedData[suggestionKey] = {
        ...suggestion,
        bio: '',
      };
    } else if ('phoneNumber' in suggestion && suggestionKey === 'phoneNumber') {
      updatedData[suggestionKey] = {
        ...suggestion,
        phoneNumber: '',
      };
    } else if ('website' in suggestion && suggestionKey === 'website') {
      updatedData[suggestionKey] = {
        ...suggestion,
        website: '',
      };
    } else if ('skills' in suggestion && suggestionKey === 'skills') {
      updatedData[suggestionKey] = {
        ...suggestion,
        skills: suggestion.skills.filter(
          (skill) => skill.talentSkillId !== partialSuggestionId,
        ),
      };
    } else if ('roles' in suggestion && suggestionKey === 'roles') {
      updatedData[suggestionKey] = {
        ...suggestion,
        roles: suggestion.roles.filter(
          (role) => role.id !== partialSuggestionId,
        ),
      };
    } else if ('industries' in suggestion && suggestionKey === 'industries') {
      updatedData[suggestionKey] = {
        ...suggestion,
        industries: suggestion.industries.filter(
          (industry) => industry.id !== partialSuggestionId,
        ),
      };
    } else if ('projects' in suggestion && suggestionKey === 'projects') {
      updatedData[suggestionKey] = {
        ...suggestion,
        projects: suggestion.projects.filter(
          (project) => project.eid !== partialSuggestionId,
        ),
      };
    } else if ('jobs' in suggestion && suggestionKey === 'jobs') {
      updatedData[suggestionKey] = {
        ...suggestion,
        jobs: suggestion.jobs.filter(
          (jobs) => jobs.eid !== partialSuggestionId,
        ),
      };
    }
  }

  return updatedData;
};

export const removeEntireProfileSuggestionBySid = (
  suggestions: ProfileEnhancementSuggestions | undefined,
  sid: BuilderSuggestionId,
) => {
  if (!suggestions) return suggestions;

  const updatedData = { ...suggestions };

  for (const key in updatedData) {
    const suggestion = updatedData[key as keyof ProfileEnhancementSuggestions];
    if (suggestion && 'sid' in suggestion && suggestion.sid === sid) {
      delete updatedData[key as keyof ProfileEnhancementSuggestions];
      break;
    }
  }

  return updatedData;
};
