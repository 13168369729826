import React from 'react';
import { OrderingOperators } from '@ateams/api/dist/endpoints/vetting-process';
import NotSorting from './table-sort.svg';
import SortingAscending from './table-sort-up.svg';
import SortingDescending from './table-sort-down.svg';
import { createUseStyles } from 'react-jss';

export type OnSortChange = (newOperator?: OrderingOperators) => void;

export interface SortableHeaderProps {
  sortingState?: OrderingOperators;
  onSortChange: OnSortChange;
}

const useStyles = createUseStyles({
  operatorImg: {
    width: '12px',
    height: '100%',
    cursor: 'pointer',
  },
});

export const SortableHeader: React.FC<SortableHeaderProps> = (props) => {
  const { sortingState, onSortChange } = props;
  const styles = useStyles();

  if (!sortingState) {
    return (
      <img
        alt="sort"
        src={NotSorting}
        className={styles.operatorImg}
        onClick={() => onSortChange(OrderingOperators.Descending)}
      />
    );
  }

  if (sortingState === OrderingOperators.Ascending) {
    return (
      <img
        alt="ascending"
        src={SortingAscending}
        className={styles.operatorImg}
        onClick={() => onSortChange()}
      />
    );
  }

  if (sortingState === OrderingOperators.Descending) {
    return (
      <img
        alt="descending"
        src={SortingDescending}
        className={styles.operatorImg}
        onClick={() => onSortChange(OrderingOperators.Ascending)}
      />
    );
  }

  console.error('Invalid sorting state');

  return null;
};
