import { SurveyQuestionType } from '@a_team/models/dist/Survey';
import React, { ReactElement } from 'react';
import RatingWithFreeFormCommentResponse from './RatingWithFreeFormCommentResponse';
import PersonRatingWithFreeFormCommentResponse from './PersonRatingWithFreeFormCommentResponse';
import { ResponseProps } from './ResponseProps';

export type Props<T extends SurveyQuestionType> = ResponseProps<T>;

const components = {
  [SurveyQuestionType.RatingWithFreeFormComment]:
    RatingWithFreeFormCommentResponse,
  [SurveyQuestionType.PersonRatingWithFreeFormComment]:
    PersonRatingWithFreeFormCommentResponse,
  [SurveyQuestionType.RecommendedRoles]: null,
  [SurveyQuestionType.Comment]: null,
  [SurveyQuestionType.YesOrNo]: null,
  [SurveyQuestionType.List]: null,
};

const Response = <T extends SurveyQuestionType>(
  props: Props<T>,
): ReactElement | null => {
  // The type cast is necessary because TypeScript cannot pick up the type correctly

  const Component = components[props.question.type]
    ? (components[props.question.type] as (
        props: Props<T>,
      ) => ReactElement | null)
    : null;

  return Component ? <Component {...props} /> : null;
};

export default Response;
