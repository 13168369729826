import React, { ReactElement } from 'react';
import UserAvatar from '@src/components/UserAvatar';
import logo from '@src/components/Modal/ContractModal/logo.svg';
import SectionHeading from '@src/components/SectionHeading';

interface Props {
  title: string;
  subtitle: string;
}

export const ModalHeader = (props: Props): ReactElement => {
  return (
    <div style={{ display: 'flex', marginBottom: 24 }}>
      <UserAvatar src={logo} containerStyle={{ marginRight: 16 }} />
      <div>
        <span>{props.subtitle}</span>
        <SectionHeading isFirst style={{ marginBottom: 0, fontSize: 18 }}>
          {props.title}
        </SectionHeading>
      </div>
    </div>
  );
};
