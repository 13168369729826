import { Colors, Icon, IconType } from '@ateams/components';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import Suggestion from './Suggestion';
import { BuilderSuggestionType } from '@a_team/models/dist/ProfileEnhancements';
import { UserId } from '@a_team/models/dist/UserObject';
import { useIsMutating } from '@tanstack/react-query';
import { profileSuggestions } from '@src/rq/keys/profileSuggestions';

interface SuggestionType {
  suggestionId?: string;
  partialSuggestionId?: string;
  type: BuilderSuggestionType;
  label?: string | null;
  subLabel?: string;
  metaData?: string;
}
interface SuggestionsProps {
  title: string;
  suggestions: SuggestionType[];
  userId: UserId;
  adminOnly?: boolean;
  onDiscard?: () => void;
  onAccept?: (partialSuggestionId: string) => Promise<void> | void;
  onClick?: () => void;
  noLoadingIconOnAccept?: boolean; // Some suggestions like skills are accepted in an extra step, so we don't need a loading icon
}

const useStyles = createUseStyles({
  container: ({ isLoading, suggestions }) => ({
    marginTop: suggestions.length > 0 || isLoading ? 16 : 0,
    display: 'flex',
    flexDirection: 'column',
    padding: suggestions.length > 0 || isLoading ? 12 : 0,
    border: suggestions.length > 0 || isLoading ? '1px solid #E8D1FF' : 0,
    gap: 16,
    borderRadius: 8,
  }),
  title: {
    color: Colors.secondaryDark,
    fontSize: 14,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  loading: {
    color: Colors.secondaryDark,
    fontSize: 12,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  titleAndDiscardBtnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  suggestionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
  },
  discardBtn: {
    background: 'none',
    border: 'none',
    fontSize: '14px',
    fontWeight: 400,
    color: '#818388',
    cursor: 'pointer',
  },
});

const Suggestions = ({
  title,
  suggestions,
  userId,
  adminOnly,
  onDiscard,
  onAccept,
  onClick,
  noLoadingIconOnAccept,
}: SuggestionsProps) => {
  const mutationCount = useIsMutating({
    mutationKey: profileSuggestions.enhanceProfileByUserId(userId),
  });

  const isLoading = useMemo(() => mutationCount > 0, [mutationCount]);
  const styles = useStyles({ isLoading, suggestions });

  const handleDiscardClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    if (onDiscard) {
      onDiscard();
    }
  };

  const loadingOrTitleState = useMemo(() => {
    if (suggestions.length === 0 && !isLoading) {
      return null;
    }

    const normalTitle = suggestions.length === 0 ? '' : title;
    const loadingTitle = `Loading ${title.toLowerCase()}`;

    return (
      <div className={isLoading ? styles.loading : styles.title}>
        <Icon
          size="smaller"
          type={
            isLoading ? IconType.CircleLoadingViolet : IconType.SuggestedTeams
          }
          rotating={isLoading}
        />
        {isLoading ? loadingTitle : normalTitle}
      </div>
    );
  }, [isLoading, title, suggestions]);

  if (suggestions.length === 0) {
    return null;
  }

  return (
    <>
      <div className={styles.container} onClick={onClick}>
        <div className={styles.titleAndDiscardBtnContainer}>
          {loadingOrTitleState}
          {!adminOnly && onDiscard && suggestions.length > 0 && (
            <button className={styles.discardBtn} onClick={handleDiscardClick}>
              Discard
            </button>
          )}
        </div>
        <div className={styles.suggestionsContainer}>
          {suggestions.map((suggestion) => (
            <Suggestion
              key={suggestion.partialSuggestionId}
              suggestionId={suggestion.suggestionId}
              label={suggestion.label ?? undefined}
              subLabel={suggestion.subLabel}
              metaData={suggestion.metaData}
              partialSuggestionId={suggestion.partialSuggestionId}
              type={suggestion.type}
              userId={userId}
              adminOnly={adminOnly}
              onAccept={onAccept}
              noLoadingIconOnAccept={noLoadingIconOnAccept}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Suggestions;
