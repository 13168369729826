import React, { MouseEvent, ReactElement } from 'react';
import RatingStar from '../RatingStar';
import range from '../../helpers/range';
import { createUseStyles } from 'react-jss';

export interface Props {
  value?: number | null | undefined;
  onChange?: (value: number | null) => void;
  min?: number;
  max?: number;
  disabled?: boolean;
  className?: string;
}

const useStyles = createUseStyles<Props>({
  wrapper: {
    display: 'inline-flex',
    flexDirection: 'row',
  },
  star: (props) => ({
    cursor: props.onChange ? 'pointer' : 'unset',
    marginLeft: '12px',
    '&:first-child': {
      marginLeft: 0,
    },
  }),
});

const StarRating = (props: Props): ReactElement | null => {
  const { min = 1, max = 5, value, onChange, disabled, className } = props;
  const styles = useStyles(props);

  if (min <= 0) {
    throw new Error('The minimum value must be non-negative number');
  }

  const onStarClick = (newValue: number) => (event: MouseEvent) => {
    event.preventDefault();
    if (!disabled && onChange) {
      onChange(
        value === newValue ? null : Math.max(min, Math.min(max, newValue)),
      );
    }
  };

  return (
    <div className={className}>
      <div className={styles.wrapper}>
        {range(min, max).map((starValue, i) => (
          <RatingStar
            className={styles.star}
            key={i}
            active={
              !disabled && typeof value === 'number' && starValue <= value
            }
            onClick={onStarClick(starValue)}
          />
        ))}
      </div>
    </div>
  );
};

export default StarRating;
