import React from 'react';
import { createUseStyles } from 'react-jss';
import StickyStrip from '../StickyStrip';
import { Button } from '@a_team/ui-components';
import useMediaQuery from '@src/hooks/useMediaQuery';
import { Breakpoints, TextColors } from '@ateams/components';
import ReleaseConnectionModal from '../ReleaseConnectionsModal';
import { useStores } from '@src/stores';
import GradientBox from '../GradientBox';
import useToggle from '@src/hooks/useToggle';
import ReleaseStepsModal from '../Modal/ReleaseStepsModalConnection';

interface ReleaseConnectionsBannerProps {
  onClose: () => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    gap: '16px',
    background: '#26014D',
    color: TextColors.primary,
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    width: '100%',
    padding: '16px 24px',
    justifyContent: 'space-between',
  },
  gradientBg: {
    background: 'linear-gradient(90deg, #FB50C7 0%, #AD55FF 100%) !important',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  leftSideContainer: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  desc: {
    margin: 0,
  },
});

const ReleaseConnectionsBanner = ({
  onClose,
}: ReleaseConnectionsBannerProps): JSX.Element => {
  const styles = useStyles();
  const isDesktop = useMediaQuery(`(min-width: 1300px)`);
  const isTablet = useMediaQuery(`(min-width: ${Breakpoints.sm}px)`);

  const { auth } = useStores();
  const [isReleaseStepsModalOpen, setIsReleaseStepsModalOpen] =
    useToggle(false);

  const [isWhatsNewModalOpen, setIsWhatsNewModalOpen] = useToggle(false);

  const handleOnClose = async () => {
    try {
      await auth.setWhatsNewBannerAsDisplayed(auth);
    } catch (e) {
      console.error(e);
    } finally {
      onClose();
    }
  };

  return (
    <StickyStrip
      styles={{
        padding: 0,
        // add styles using hideOnScroll to hide the banner on scroll
        ...(!isTablet
          ? {
              position: 'relative',
              top: 50,
            }
          : {}),
      }}
    >
      <div className={styles.container}>
        <div className={styles.leftSideContainer}>
          {isDesktop && (
            <GradientBox boxBgColor="#26014D">New Connections</GradientBox>
          )}

          <p className={styles.desc}>
            {isTablet
              ? `A.Team connections are new and improved to help you build your network and land more missions.`
              : `Connections got a facelift!`}
          </p>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            style={{
              height: '32px',
              borderRadius: '4px',
            }}
            className={styles.gradientBg}
            onClick={setIsWhatsNewModalOpen}
          >
            {isDesktop ? 'Explore connections' : 'Explore'}
          </Button>
          <Button
            style={{
              height: '32px',
              borderRadius: '4px',
              background: isDesktop ? '#7000E3' : 'transparent',
            }}
            onClick={handleOnClose}
          >
            Close
          </Button>
        </div>
      </div>
      <ReleaseConnectionModal
        setIsReleaseStepsModalOpen={setIsReleaseStepsModalOpen}
        isOpen={isWhatsNewModalOpen}
        onClose={setIsWhatsNewModalOpen}
      />
      <ReleaseStepsModal
        open={isReleaseStepsModalOpen}
        onClose={setIsReleaseStepsModalOpen}
        setIsWhatsNewModalOpen={setIsWhatsNewModalOpen}
      />
    </StickyStrip>
  );
};

export default ReleaseConnectionsBanner;
