// supports tsconfig { "emitDecoratorMetadata": true }
import 'reflect-metadata';
import 'tslib';

import * as Sentry from '@sentry/react';

import React, { ReactElement, useEffect } from 'react';
import { ScreenClass, ScreenClassProvider } from 'react-grid-system';
import Routes from '@src/routes';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { RECAPTCHA_SITE_KEY } from './config';

export interface AppProps {
  // The estimated CSS width of the browser.
  fallbackScreenClass?: ScreenClass;
}

const App = (props: AppProps): ReactElement => {
  useEffect(() => {
    loadReCaptcha(RECAPTCHA_SITE_KEY);
  }, []);
  return (
    <ScreenClassProvider {...props}>
      <Routes />
    </ScreenClassProvider>
  );
};

export default Sentry.withProfiler(App);
