import {
  BorderColors,
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  SelectOption,
  Spacing,
} from '@ateams/components';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useCommonStyles } from '../commonStyles';
import { FieldDropdown } from '../general/FieldDropdown';
import { useFormContext } from 'react-hook-form';
import { CustomUserTagSelectOption } from '@ateams/api/dist/endpoints/vetting-process-feedback-v2';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  internalContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  skillsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    border: `1px solid ${BorderColors.light}`,
    borderRadius: BorderRadius.xLarge,
    backgroundColor: Colors.backgroundWhite,
    padding: Spacing.medium,
  },
  label: {
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.medium,
    whiteSpace: 'nowrap',
  },
  dropdown: {
    width: 278,
  },
});

const guildsMapping: Record<string, string> = {
  guild_fintech: 'Fintech guild',
  guild_healthcare: 'Healthcare guild',
  guild_ai: 'A.I. guild',
  guild_frontend: 'Front-end guild',
  guild_cto: 'CTO guild',
  guild_product: 'Product guild',
};

export interface GuildsRecommendationSectionProps {
  guildsValues: CustomUserTagSelectOption[];
  isViewMode: boolean;
}

export const GuildsRecommendationSection = ({
  guildsValues,
  isViewMode,
}: GuildsRecommendationSectionProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const { watch, setValue } = useFormContext();

  const guildsRecommendationValues: CustomUserTagSelectOption[] = watch(
    'guildsRecommendation',
  );

  const convertGuildValueToLabel = (value: string) => {
    const label = value.replace('guild_', '').replace('_', ' ') + ' guild';
    return label.charAt(0).toUpperCase() + label.slice(1);
  };

  const guildsRecommendation = useMemo(() => {
    return guildsRecommendationValues.map((tag) => ({
      label: guildsMapping[tag.name] || convertGuildValueToLabel(tag.name),
      value: tag.name,
    }));
  }, [guildsRecommendationValues]);

  const guildsOptions = useMemo(() => {
    return guildsValues.map((tag) => ({
      label: guildsMapping[tag.name] || convertGuildValueToLabel(tag.name),
      value: tag.name,
    }));
  }, [guildsValues]);

  const handleGuildsChange = (
    selectedOptions: SelectOption[] | SelectOption | null,
  ) => {
    const guildsRecommendation = selectedOptions
      ? Array.isArray(selectedOptions)
        ? selectedOptions.map((option) => option.value)
        : [selectedOptions.value]
      : [];
    setValue(
      'guildsRecommendation',
      guildsRecommendation.map((tag) => {
        const guild = guildsValues.find((option) => option.name === tag);
        return {
          id: guild?.id ?? '',
          name: guild?.name ?? '',
        };
      }),
    );
  };

  const handleRemoveOption = (option: SelectOption) => {
    const guildsRecommendation = guildsRecommendationValues.filter(
      (tag) => tag.name !== option.value,
    );
    setValue('guildsRecommendation', guildsRecommendation);
  };

  return (
    <div className={styles.container}>
      <div className={styles.internalContainer}>
        <div className={commonStyles.sectionTitle}>Guilds recommendation</div>
      </div>
      <div className={styles.skillsContainer}>
        <FieldDropdown
          label="Could this builder potentially fit any of the guilds?"
          options={guildsOptions}
          value={guildsRecommendation}
          onChange={handleGuildsChange}
          onRemoveOption={handleRemoveOption}
          placeholder="Select guilds"
          className={styles.dropdown}
          labelClassName={styles.label}
          isMulti
          disabled={isViewMode}
        />
      </div>
    </div>
  );
};
