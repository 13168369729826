import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import RegistrationStep from '@src/views/Registration/RegistrationStep';
import { useStores } from '@src/stores';
import { Button, ButtonContainer, Icon, IconType } from '@ateams/components';
import { TeamMemberRole } from '@a_team/models/dist/TeamObject';
import TooltipWrapped from '@src/components/TooltipWrapped';

const RegistrationApplyAsTeamView = (): ReactElement => {
  const { registration } = useStores();

  return (
    <RegistrationStep
      title={'Before we start, do you already have a team?'}
      withMobileVideo={registration.currentStep.withMobileVideo}
      description={
        <>
          At A.Team we welcome people with existing teams or people they like to
          team up with to apply together, so we can propose you to clients as a
          unit. You can also apply as an individual and find/invite people to
          team up with later on.
          <p style={{ fontSize: 12 }}>
            For Apply as a Team we don’t support agencies
            <TooltipWrapped
              arrow
              html={
                <p style={{ fontSize: 12, textAlign: 'left' }}>
                  The A.Team vision is to empower builders, allowing them to set
                  their own rates, choosing what they want to work in, and with
                  whom.
                  <br />
                  <br />
                  Middlemen in that process take away from the builders’ rates
                  and as such goes against our policies. That’s why we’re not
                  accepting software development agencies/companies to be
                  providing their services through our platform.
                  <br />
                  <br />
                  If you wish to collaborate with another builder, you can
                  invite them to the platform and work together, but we aren’t
                  supporting outsourcing.
                </p>
              }
            >
              <Icon type={IconType.Info} title="" style={{ marginLeft: 4 }} />
            </TooltipWrapped>
          </p>
        </>
      }
      onSubmit={registration.stepForward}
      withButton={false}
    >
      <ButtonContainer
        align={'left'}
        style={{ marginTop: 20, marginBottom: 30 }}
      >
        <Button
          style={{ fontSize: 12, marginRight: 16, height: 'auto' }}
          width={'auto'}
          size={'small'}
          onClick={() => {
            registration.redirectApplyAsTeam();
          }}
          disabled={registration.isTeamOwner}
        >
          Apply as an Individual
        </Button>
        <Button
          style={{ fontSize: 12, height: 'auto' }}
          width={'auto'}
          size={'small'}
          onClick={() => {
            registration.redirectApplyAsTeam(TeamMemberRole.Owner);
          }}
        >
          Apply as a Team
        </Button>
      </ButtonContainer>
    </RegistrationStep>
  );
};

export default observer(RegistrationApplyAsTeamView);
