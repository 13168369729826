import React, { ReactElement } from 'react';
import { Checkbox, ToggleSwitch } from '@ateams/components';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery/index';

interface Props {
  setFilterValue: (filter: Partial<RoleFilter>) => void;
  zeroToOne?: boolean;
  zeroToOnePreferred?: boolean;
}

const ZeroToOneFilters = (props: Props): ReactElement => {
  const { setFilterValue, zeroToOne, zeroToOnePreferred } = props;

  return (
    <div>
      <Checkbox
        margin={'top'}
        onChange={(e) => {
          setFilterValue({
            zeroToOne: e.target.checked ? true : undefined,
          });
        }}
        checked={zeroToOne !== undefined}
        label="Has Zero to One Experience"
      />
      <ToggleSwitch
        label={!zeroToOnePreferred ? 'Required' : 'Preferred'}
        onChange={(e) => {
          setFilterValue({
            zeroToOnePreferred: !zeroToOnePreferred,
          });
        }}
        checked={!zeroToOnePreferred}
        size="small"
        justify="right"
        checkedBackgroundColor="secondary"
      />
    </div>
  );
};
export default ZeroToOneFilters;
