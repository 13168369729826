import { v1SearchUserFilterApi } from '@a_team/team-engine-search-service-sdk-js';
import {
  EmailFromTargeterAudienceCountResponseDto,
  EmailFromTargeterAudienceDto,
  EmailFromTargeterSendDtoTypeEnum,
  EmailTemplatesControllerGetTemplateIdEnum,
} from '@a_team/user-notification-service-js-sdk';
import {
  apiEmailFromTargeter,
  apiMissions,
  emailFromTargeterApi,
  emailTemplatesApi,
  reachoutAutomationApi,
  replacementVariablesApi,
} from '@src/logic/services/endpoints';
import AuthStore from '@src/stores/Auth';
import { EmailFormData } from './EmailForm';
import { GenerateEmailBodyResponse } from '@a_team/models/dist/EmailFromTargeter';

export class TargeterEmailService {
  static sendPreviewEmail(
    auth: AuthStore,
    formData: EmailFormData,
    audiences: EmailFromTargeterAudienceDto[],
  ) {
    if (
      !auth.currentUser ||
      !auth.currentUser.email ||
      !auth.currentUser.uid ||
      !auth.currentUser.username ||
      !auth.currentUser.firstName
    ) {
      throw new Error('User is not logged in');
    }
    return emailFromTargeterApi.emailFromTargeterControllerSend(
      {
        emailFromTargeterSendDto: {
          type: formData.type,
          from: {
            email: formData.fromUser.email,
            name: formData.fromUser.fullName || 'A.Team',
          },
          cc:
            formData.type !== EmailFromTargeterSendDtoTypeEnum.RichReachout &&
            formData.type !== EmailFromTargeterSendDtoTypeEnum.RichReachoutV2
              ? formData.ccAddresses?.map(({ value }) => ({
                  email: value,
                  name: value,
                }))
              : [],
          bcc:
            formData.type !== EmailFromTargeterSendDtoTypeEnum.RichReachout &&
            formData.type !== EmailFromTargeterSendDtoTypeEnum.RichReachoutV2
              ? formData.bccAddresses?.map(({ value }) => ({
                  email: value,
                  name: value,
                }))
              : [],
          replyTo: [
            {
              email: formData.mainReplyToUser.email,
              name: formData.mainReplyToUser.fullName || 'A.Team',
            },
            ...(formData.additionalReplyToUsers || [])
              .filter((user) => !!user)
              .map((user) => ({
                email: user.email,
                name: user.fullName || 'A.Team',
              })),
          ],
          audiences: audiences.map((audience) => ({
            ...audience,
            filters: {},
            recipients: [
              {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                uid: auth.currentUser!.uid,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                email: auth.currentUser!.email,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                firstName: auth.currentUser!.firstName,
                lastName: auth.currentUser?.lastName || '',
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                username: auth.currentUser!.username!,
              },
            ],
          })),
          subject: `{Preview} ${formData.subject}`,
          templateData: {
            html: formData.body,
            htmlSingle: formData.bodySingleRoleMatch,
            htmlMultiple: formData.bodyMultipleRoleMatch,
          },
          missionId: formData.missionId,
          emailCategory: formData.category,
          videoURL: formData.videoURL,
          ...(formData.signatureUser?.email
            ? {
                signature: {
                  email: formData.signatureUser.email,
                },
              }
            : {}),
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        },
      },
    );
  }

  static sendEmail(
    auth: AuthStore,
    formData: EmailFormData,
    audiences: EmailFromTargeterAudienceDto[],
  ) {
    return emailFromTargeterApi.emailFromTargeterControllerSend(
      {
        emailFromTargeterSendDto: {
          type: formData.type,
          from: {
            email: formData.fromUser.email,
            name: formData.fromUser.fullName || 'A.Team',
          },
          cc:
            formData.type !== EmailFromTargeterSendDtoTypeEnum.RichReachout &&
            formData.type !== EmailFromTargeterSendDtoTypeEnum.RichReachoutV2
              ? formData.ccAddresses?.map(({ value }) => ({
                  email: value,
                  name: value,
                }))
              : [],
          bcc:
            formData.type !== EmailFromTargeterSendDtoTypeEnum.RichReachout &&
            formData.type !== EmailFromTargeterSendDtoTypeEnum.RichReachoutV2
              ? formData.bccAddresses?.map(({ value }) => ({
                  email: value,
                  name: value,
                }))
              : [],
          replyTo: [
            {
              email: formData.mainReplyToUser.email,
              name: formData.mainReplyToUser.fullName || 'A.Team',
            },
            ...(formData.additionalReplyToUsers || [])
              .filter((user) => !!user)
              .map((user) => ({
                email: user.email,
                name: user.fullName || 'A.Team',
              })),
          ],
          audiences,
          subject: formData.subject,
          templateData: {
            html: formData.body,
            htmlSingle: formData.bodySingleRoleMatch,
            htmlMultiple: formData.bodyMultipleRoleMatch,
          },
          missionId: formData.missionId,
          emailCategory: formData.category,
          videoURL: formData.videoURL,
          ...(formData.signatureUser?.email
            ? {
                signature: {
                  email: formData.signatureUser.email,
                },
              }
            : {}),
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        },
      },
    );
  }

  static async createCampaign(
    auth: AuthStore,
    formData: EmailFormData,
    audiences: EmailFromTargeterAudienceDto[],
    timeBetweenEmails: number,
    maxNumberOfBuildersPerRole: Record<string, number>,
  ) {
    const res =
      await reachoutAutomationApi.reachoutAutomationControllerCreateAutomationCampaign(
        {
          createAutomationCampaignDto: {
            emailFromTargeterSendDto: {
              type: formData.type,
              from: {
                email: formData.fromUser.email,
                name: formData.fromUser.fullName || 'A.Team',
              },
              cc:
                formData.type !==
                  EmailFromTargeterSendDtoTypeEnum.RichReachout &&
                formData.type !==
                  EmailFromTargeterSendDtoTypeEnum.RichReachoutV2
                  ? formData.ccAddresses?.map(({ value }) => ({
                      email: value,
                      name: value,
                    }))
                  : [],
              bcc:
                formData.type !==
                  EmailFromTargeterSendDtoTypeEnum.RichReachout &&
                formData.type !==
                  EmailFromTargeterSendDtoTypeEnum.RichReachoutV2
                  ? formData.bccAddresses?.map(({ value }) => ({
                      email: value,
                      name: value,
                    }))
                  : [],
              replyTo: [
                {
                  email: formData.mainReplyToUser.email,
                  name: formData.mainReplyToUser.fullName || 'A.Team',
                },
                ...(formData.additionalReplyToUsers || [])
                  .filter((user) => !!user)
                  .map((user) => ({
                    email: user.email,
                    name: user.fullName || 'A.Team',
                  })),
              ],
              audiences,
              subject: formData.subject,
              templateData: {
                html: formData.body,
                htmlSingle: formData.bodySingleRoleMatch,
                htmlMultiple: formData.bodyMultipleRoleMatch,
              },
              missionId: formData.missionId,
              emailCategory: formData.category,
              videoURL: formData.videoURL,
              ...(formData.signatureUser?.email
                ? {
                    signature: {
                      email: formData.signatureUser.email,
                    },
                  }
                : {}),
            },
            timeBetweenRuns: timeBetweenEmails,
            roleSettings: Object.entries(maxNumberOfBuildersPerRole).map(
              ([missionRoleId, maxNumberOfBuilders]) => ({
                missionRoleId,
                stopConditions: {
                  maxNumberOfBuildersMatchingAcceptanceCriteria:
                    maxNumberOfBuilders,
                },
              }),
            ),
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          },
        },
      );
    return res;
  }

  static async getAudienceCount(
    auth: AuthStore,
    filters: v1SearchUserFilterApi,
  ): Promise<EmailFromTargeterAudienceCountResponseDto> {
    return emailFromTargeterApi.emailFromTargeterControllerAudienceCount(
      {
        emailFromTargeterAudienceCountDto: {
          filters,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        },
      },
    );
  }

  static async getTotalAudienceCount(
    auth: AuthStore,
    audiences: EmailFromTargeterAudienceDto[],
  ): Promise<EmailFromTargeterAudienceCountResponseDto> {
    return emailFromTargeterApi.emailFromTargeterControllerTotalAudienceCount(
      {
        emailFromTargeterTotalAudienceCountDto: {
          audiences,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        },
      },
    );
  }

  static getTemplateContent = async (
    auth: AuthStore,
    templateId: EmailTemplatesControllerGetTemplateIdEnum,
  ) => {
    return emailTemplatesApi.emailTemplatesControllerGetTemplate(
      {
        id: templateId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        },
      },
    );
  };

  static getReplacementVariablesByCategory = async (auth: AuthStore) => {
    return replacementVariablesApi.replacementVariablesControllerGetReplacementVariablesByCategory(
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        },
      },
    );
  };

  static assignOwnerToMission = async (
    auth: AuthStore,
    missionId: string,
    ownerId: string,
  ) => {
    await apiMissions.adminAssignMissionOwner(auth, missionId, ownerId);
  };

  static setRolesVisible = async (
    auth: AuthStore,
    missionId: string,
    roleIds: string[],
  ) => {
    await apiMissions.adminMissionSetRolesVisible(auth, missionId, roleIds);
  };

  static generateEmail = async (
    auth: AuthStore,
    missionId: string,
  ): Promise<GenerateEmailBodyResponse> => {
    return apiEmailFromTargeter.generateEmailBody(auth, missionId);
  };
}
