import { UserTalentIndustriesAssignment } from './TalentIndustry';

export type TalentCategoryId = string;

/**
 * TalentCategory represents a grouping of certain skill sets, such as Software Engineering, Data Science, etc.
 * TalentCategories can form a tree structure for sub-groupings.
 */
export interface TalentCategory {
  id: TalentCategoryId;
  parentTalentCategoryIds: TalentCategoryId[];
  name: string;
  textId: string;
  isVettingEligible?: boolean;
}

export type TalentSkillId = string;

/**
 * TalentSkill is a specific skill that a freelancer can have, like Python Programming or AdWords.
 */
export interface TalentSkill {
  id: TalentSkillId;
  name: string;
  talentCategoryIds: TalentCategoryId[];
  isProgrammingLanguage?: boolean;
}

export type TalentSpecializationId = string;

/**
 * TalentSpecialization refers to a specific job title, like Data Scientist or Front-end Developer.
 * TalentSpecializations are analogous to RoleCategories in the old model.
 */
export interface TalentSpecialization {
  id: TalentSpecializationId;
  name: string;
  talentCategoryIds: TalentCategoryId[];
}

export enum TalentSkillRating {
  None = 1,
  BelowAverage = 2,
  Average = 3,
  AboveAverage = 4,
  Deep = 5,
}

/**
 * UserTalentSkillAssignment represents a skill that a user has, combined with how
 *  much experience they have in that specific skill.
 */
export interface UserTalentSkillAssignment {
  talentSkillId: TalentSkillId;
  talentSkillName?: string;
  rating?: TalentSkillRating;
  yearsOfExperience?: number;
}

export interface UserTalentSkillAssignmentData {
  mainTalentSkills: UserTalentSkillAssignment[];
  /* @deprecated */
  additionalTalentSkills?: UserTalentSkillAssignment[];
}

export interface UserTalentSpecializationAssignmentData {
  mainTalentSpecialization?: TalentSpecialization;
  additionalTalentSpecializations: TalentSpecialization[];
}

export interface TalentProfile {
  mainTalentCategoryId?: TalentCategoryId;
  mainTalentSpecializationId?: TalentSpecializationId;
  talentSkills: UserTalentSkillAssignmentData;
  talentSpecializations?: UserTalentSpecializationAssignmentData;
  talentIndustries?: UserTalentIndustriesAssignment;
}

export enum RoleType {
  Main = 'Main',
  Additional = 'Additional',
}

export interface RoleWithStatus {
  roleId: TalentSpecializationId;
  markedAsWaitlisted: boolean;
  name?: string;
  type?: RoleType;
}
