import React from 'react';
import Experience from '../../partials/Experience';
import {
  SharedJobExperience,
  SharedProjectExperience,
} from '@a_team/models/dist/ConnectionObject';
import { EditingExperienceObject } from '../../types';
import {
  ExperienceMemberRole,
  ExperienceType,
} from '@a_team/models/dist/ExperienceObject';
import { createUseStyles } from 'react-jss';
import { useDeleteUserExperienceMutation } from '@src/rq/experiences';
import { useStores } from '@src/stores';

interface UserExperiencesProps {
  sharedWith?: string;
  projects?: SharedProjectExperience[];
  jobs?: SharedJobExperience[];
  error?: boolean;
  canEdit?: boolean;
  canApprove?: boolean;
  isRequesterExperiences?: boolean;
  newlyCreatedExperiencesIds: string[];
  onAddProjectExperience: (projectExperience: SharedProjectExperience) => void;
  onAddJobExperience: (jobExperience: SharedJobExperience) => void;
  onRemoveExperience: (eid: string) => void;
  onEditExperience: (experience: EditingExperienceObject) => void;
  checkIfExperienceIsSelected: (
    experience: SharedJobExperience | SharedProjectExperience,
  ) => boolean;
}

const useStyles = createUseStyles({
  container: {
    '& > :last-child': {
      borderBottom: 'none',
    },
  },
});

const UserExperiences = ({
  sharedWith,
  projects,
  jobs,
  error,
  canEdit,
  canApprove,
  isRequesterExperiences,
  newlyCreatedExperiencesIds,
  onRemoveExperience,
  onAddProjectExperience,
  onAddJobExperience,
  checkIfExperienceIsSelected,
  onEditExperience,
}: UserExperiencesProps): JSX.Element => {
  const styles = useStyles();
  const { auth } = useStores();
  const { mutateAsync: deleteUserExperience } =
    useDeleteUserExperienceMutation();

  if (error) {
    return <div>Sorry there was a problem loading the experiences</div>;
  }

  const handleSelect = (
    experience: SharedJobExperience | SharedProjectExperience,
  ) => {
    if (experience.type === ExperienceType.Job) {
      onAddJobExperience(experience);
    }
    if (experience.type === ExperienceType.Project) {
      onAddProjectExperience(experience);
    }
  };

  const onRemove = async (eid: string) => {
    await deleteUserExperience(eid);
    onRemoveExperience(eid);
  };

  const experiences = [...(projects || []), ...(jobs || [])];

  const experiencesWithoutNewlyCreatedExperiences = experiences.filter(
    (experience) =>
      experience.eid && !newlyCreatedExperiencesIds.includes(experience.eid),
  );

  const experiencesWithNewlyCreatedExperiences = experiences.filter(
    (experience) =>
      experience.eid && newlyCreatedExperiencesIds.includes(experience.eid),
  );

  return (
    <div className={styles.container}>
      {experiencesWithNewlyCreatedExperiences?.map((experience) => {
        const isOwner = experience.members?.find(
          (member) =>
            auth.user?.username === member.username &&
            member.experienceRole === ExperienceMemberRole.Owner,
        );

        return (
          <Experience
            key={experience.eid}
            experience={experience}
            onRemove={
              experience.eid &&
              newlyCreatedExperiencesIds.includes(experience.eid)
                ? onRemove
                : undefined
            }
            isRequester={isRequesterExperiences && !canApprove}
            sharedWith={sharedWith}
            canEdit={canEdit && !isOwner}
            onSelect={!canApprove ? handleSelect : undefined}
            onEdit={onEditExperience}
            selected={checkIfExperienceIsSelected(experience)}
          />
        );
      })}
      {experiencesWithoutNewlyCreatedExperiences?.map((experience) => {
        const isOwner = experience.members?.find(
          (member) =>
            auth.user?.username === member.username &&
            member.experienceRole === ExperienceMemberRole.Owner,
        );

        return (
          <Experience
            key={experience.eid}
            experience={experience}
            onRemove={
              experience.eid &&
              newlyCreatedExperiencesIds.includes(experience.eid)
                ? onRemove
                : undefined
            }
            isRequester={isRequesterExperiences && !canApprove}
            sharedWith={sharedWith}
            canEdit={canEdit && !isOwner}
            onSelect={!canApprove ? handleSelect : undefined}
            onEdit={onEditExperience}
            selected={checkIfExperienceIsSelected(experience)}
          />
        );
      })}
    </div>
  );
};

export default UserExperiences;
