import React from 'react';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { useStores } from '@src/stores';
import { floatAnimation } from '@ateams/components';
import { Footer } from '@src/views/Registration/Footer';

export interface PostEvaluationSurveyLayoutProps {
  title?: string;
  className?: string;
}

const useStyles = createUseStyles({
  root: {
    alignItems: 'center',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  },
  content: {
    display: 'flex',
    minHeight: 'calc(100vh - 67px)', // space for footer
  },
  ...floatAnimation,
  footerContainer: {
    paddingLeft: '16px',
  },
  /**
   * This is a hackish solution for a background with linear gradient
   * {@link https://www.figma.com/file/fiIpGWCIUv1WVZWVn7wwP6/Admin-side%3A-Vetting?node-id=3156-2028&t=V3XsaYGZkAexp8cQ-0}
   */
  gradient: {
    position: 'absolute',
    width: '92vw',
    height: '88vh',
    left: '4vw',
    top: '-58vh',
  },
  gradientCircle1: {
    position: 'absolute',
    width: '55vw',
    height: '88vh',
    left: '40vw',
    background: '#A63CF9',
    opacity: 0.2,
    filter: 'blur(100px)',
    borderRadius: '50%',
  },
  gradientCircle2: {
    position: 'absolute',
    width: '55vw',
    height: '88vh',
    left: '4vw',
    background: '#F950C8',
    opacity: 0.2,
    filter: 'blur(100px)',
    borderRadius: '50%',
  },
});

export const PostEvaluationSurveyLayout: React.FC<PostEvaluationSurveyLayoutProps> =
  observer((props) => {
    const { title, children } = props;
    const styles = useStyles();
    const { document } = useStores();

    document.setInnerTitle(title || 'PostEvaluationSurvey');

    return (
      <div className={cx(styles.root, props.className)}>
        <div className={styles.gradient}>
          <div className={styles.gradientCircle1} />
          <div className={styles.gradientCircle2} />
        </div>

        <div className={styles.content}>{children}</div>

        <div className={styles.footerContainer}>
          <Footer />
        </div>
      </div>
    );
  });
