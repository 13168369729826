import { Breakpoints } from '@ateams/components';
import TextAreaInput from '@src/components/Inputs/TextAreaInput';
import React from 'react';
import { createUseStyles } from 'react-jss';
import ControlButtons from './common/ControlButtons';
import { TeamPulseSectionsEnum } from './TeamPulseModal';

interface ShareProudMomentsProps {
  setSection: (section: string) => void;
  setUserReviewSection: (section: string) => void;
  setShareProudMoments: (value: string | undefined) => void;
  missionTitle: string;
  needsToReviewUsers?: boolean;
  initialState?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    height: '100%',
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'start',
  },
  desc: {
    textAlign: 'start',
    color: '#707275',
    marginBottom: 40,
    marginTop: 8,
  },
  textArea: {
    border: '1px solid #D1D2D6',
    height: 140,
    borderRadius: 8,
    padding: 10,
  },
  wrapper: {
    padding: 20,
    width: '100%',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    wrapper: {
      padding: 0,
    },
  },
});

const ShareProudMoments = ({
  setSection,
  setShareProudMoments,
  setUserReviewSection,
  missionTitle,
  initialState,
}: ShareProudMomentsProps): JSX.Element => {
  const styles = useStyles();
  const [text, setText] = React.useState(initialState || '');

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setText(e.target.value.trimStart());
  };

  const onBack = (): void => {
    setSection(TeamPulseSectionsEnum.HowsTheMissionIsGoing);
  };

  const onContinue = (): void => {
    setShareProudMoments(text);
    setSection(TeamPulseSectionsEnum.AllDone);
  };

  const onSkip = (): void => {
    setShareProudMoments(undefined);
    setSection(TeamPulseSectionsEnum.AllDone);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>
          Wow! Can you share your proudest moment?
        </h4>
        <p className={styles.desc}>
          This question is optional and is not shared with {missionTitle}.
        </p>
        <TextAreaInput
          value={text}
          className={styles.textArea}
          onChange={handleChange}
          placeholder="You’re smashing it. We’d also love to hear about learnings or insights you’d like to share with us."
        />
      </div>
      <ControlButtons
        canContinue={text.length > 0}
        onBack={onBack}
        onContinue={onContinue}
        onSkip={onSkip}
      />
    </div>
  );
};

export default ShareProudMoments;
