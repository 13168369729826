import { BasicConnectionObject } from '@a_team/models/dist/ConnectionObject';

export const getConnectButtonStatus = (
  connection?: BasicConnectionObject | null,
): string | null => {
  const STATUS = {
    connect: 'Connect',
    approve: 'Approve',
    pending: 'Pending...',
  };

  if (!connection) {
    return STATUS.connect;
  }

  let status;

  switch (connection.status) {
    case 'Active':
      status = null;
      break;
    case 'Pending':
      status = connection.canApprove ? STATUS.approve : STATUS.pending;
      break;
    default:
      status = STATUS.connect;
  }

  return status;
};
