import {
  Breakpoints,
  Button,
  Icon,
  IconType,
  TextColors,
} from '@ateams/components';
import ConnectModalV2 from '@src/components/Modal/ConnectModalV2';
import MessageModal from '@src/components/Modal/MessageModal';
import useToggle from '@src/hooks/useToggle';
import { useStores } from '@src/stores';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  ConnectionsModalSources,
  useAnalytics,
} from '@ateams/analytics/dist/platform';
import { useGetConnectionBetweenUsers } from '@src/rq/connections';
import { ConnectionStatus } from '@a_team/models/dist/ConnectionObject';

const useStyles = createUseStyles({
  container: {
    marginBottom: 40,
    display: 'flex',
    gap: '8px',
    marginLeft: '24px',
    marginRight: '24px',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '9px',
    border: 'none',
    padding: '8px 0',
    borderRadius: '8px',
    width: '100%',
    height: '40px',
    cursor: 'pointer',
    transition: 'background 0.3s ease',
    color: TextColors.regular,
  },
  messageButton: {
    '&:hover': {
      background: '#DADADC',
    },
  },
  edictConnectionButton: {
    '&:hover': {
      background: '#DADADC',
    },
  },
  connectButton: {
    color: TextColors.primary,
    transition: 'background 0.3s ease',
    '&:hover': {
      background: '#5900b3',
    },
  },
  icon: {
    height: '14px',
    width: '14px',
  },
  connectIcon: {
    height: '18px',
    width: '18px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      marginTop: '0',
      marginLeft: '0',
      marginRight: '0',
    },
  },
});

const MessageAndConnect = (): JSX.Element => {
  const [connectModal, toggleConnectModal] = useToggle();
  const styles = useStyles();
  const [messageModalOpen, setMessageModalOpen] = useToggle();
  const { users, auth } = useStores();
  const { profile } = users;
  const hasConnection = profile?.hasConnection;
  const analytics = useAnalytics();

  const { data, isLoading, error, refetch } = useGetConnectionBetweenUsers(
    profile?.data.uid || '',
  );

  const closeMessageModal = (): void => {
    setMessageModalOpen(false);
  };

  const onToggleConnectModal = (): void => {
    toggleConnectModal();
    auth.user?.uid &&
      analytics.trackConnectionModalOpened(
        auth.user?.uid,
        ConnectionsModalSources.NotificationBubble,
      );

    refetch();
  };

  const getButtonLabel = () => {
    if (isLoading) {
      return '';
    }

    // If there's an error, return 'Error'
    if (error) {
      return 'Error';
    }

    const workConnectionStatus = data?.work?.status;
    const teamUpConnectionStatus = data?.teamUp?.status;
    const workInitiatorUserId = data?.work?.initiatorUserId;
    const teamUpInitiatorUserId = data?.teamUp?.initiatorUserId;

    const isWorkPending = workConnectionStatus === ConnectionStatus.Pending;
    const isTeamUpPending = teamUpConnectionStatus === ConnectionStatus.Pending;
    const isWorkInitiator = workInitiatorUserId === auth.user?.uid;
    const isTeamUpInitiator = teamUpInitiatorUserId === auth.user?.uid;

    // If there's any connection where the receiver has a pending connection, show 'Review'
    if (
      (isWorkPending && !isWorkInitiator) ||
      (isTeamUpPending && !isTeamUpInitiator)
    ) {
      return 'Review';
    }

    // If there's any connection where the initiator has a pending connection, show 'Pending'
    if (
      (isWorkPending && isWorkInitiator) ||
      (isTeamUpPending && isTeamUpInitiator)
    ) {
      return 'Pending';
    }

    // Otherwise, show 'Edit Connection'
    return 'Edit Connection';
  };

  const buttonLabel = getButtonLabel();

  return (
    <>
      <div className={styles.container}>
        <Button
          className={cx(styles.button, styles.messageButton)}
          onClick={setMessageModalOpen}
          color="regularLight"
        >
          <Icon className={styles.icon} type={IconType.Mail} />
          Message
        </Button>
        {hasConnection ? (
          <Button
            data-testing-id="edit-connection-button-in-profile"
            className={cx(styles.button, styles.edictConnectionButton)}
            onClick={onToggleConnectModal}
            color="regularLight"
          >
            <Icon className={styles.icon} type={IconType.Edit} />
            {buttonLabel}
          </Button>
        ) : (
          <Button
            data-testing-id="connect-button-in-profile"
            className={cx(styles.button, styles.connectButton)}
            onClick={onToggleConnectModal}
            color="secondaryDark"
          >
            <Icon
              className={cx(styles.icon, styles.connectIcon)}
              type={IconType.Connect}
            />
            Connect
          </Button>
        )}
      </div>

      {profile?.data && (
        <MessageModal
          currentUser={auth.user}
          profile={profile.data}
          open={messageModalOpen}
          onClose={closeMessageModal}
        />
      )}

      {auth.user && profile && (
        <ConnectModalV2
          source={ConnectionsModalSources.UserProfile}
          open={connectModal}
          onClose={toggleConnectModal}
          currentUser={auth.user}
          userToConnect={profile.data}
        />
      )}
    </>
  );
};

export default observer(MessageAndConnect);
