import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Company } from '@a_team/models/dist/Company';
import CompanyAvatar from '@src/components/CompanyAvatar/CompanyAvatar';
import { Colors, TextColors } from '@ateams/components';
import { NewCompanyId } from '../CompanyPicker';

interface CompanyItemProps {
  company: Company & { url?: string };
  onSelectCompany: (company: Company) => void;
}

const useStyles = createUseStyles({
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    cursor: 'pointer',
    '&:last-of-type': {
      marginBottom: 0,
    },
    '&:hover': {
      background: '#FFFAF7',
    },
  },
  iconContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    fontSize: 15,
    color: TextColors.regular,
  },
  details: {
    marginLeft: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  urlFont: {
    color: TextColors.regularLight,
    fontSize: 12,
    lineHeight: '16px',
  },
  newCompany: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '19px',
    borderRadius: '8px',
    marginLeft: '8px',
    padding: '8px',
    background: Colors.backgroundLight,
  },
});

export const CompanyItem = (props: CompanyItemProps): ReactElement => {
  const { name, logoUrl, id, url } = props.company;
  const itemLogoUrl = logoUrl !== null ? logoUrl : undefined;
  const styles = useStyles();

  const onSelect = (): void => {
    props.onSelectCompany(props.company);
  };

  return (
    <div className={styles.item} onClick={onSelect}>
      {id === NewCompanyId ? (
        <>
          <span className={styles.name}>Add company</span>
          <div className={styles.newCompany}>{name}</div>
        </>
      ) : (
        <>
          <div className={styles.iconContainer}>
            <CompanyAvatar src={itemLogoUrl} />
          </div>
          <div className={styles.details}>
            <span className={styles.name}>{name}</span>
            {url && <span className={styles.urlFont}>{url}</span>}
          </div>
        </>
      )}
    </div>
  );
};
