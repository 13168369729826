import React, { ReactElement, useCallback } from 'react';
import { useStores } from '@src/stores';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { Application } from './MissionControlCard/useWithdrawApplication';

interface WithdrawApplicationModalProps {
  application: Application | null;
  open: boolean;
  onClose: () => void;
}

const WithdrawApplicationModal = (
  props: WithdrawApplicationModalProps,
): ReactElement => {
  const [loading, setLoading] = useLoadingState();
  const { missionControl } = useStores();

  const onWithdrawApplication = useCallback(async () => {
    if (props.application) {
      const { mid, application } = props.application;

      setLoading(
        missionControl
          .withdrawApplication(mid, application)
          .then(() => props.onClose()),
        'The application was withdrawn.',
      );
    }
  }, [
    props.application?.mid,
    props.application?.application.aid,
    props.onClose,
  ]);

  return (
    <>
      <ConfirmModal
        title="Are You no Longer Available for This Mission?"
        description={
          'We will stop sending you updates about this application, you will no longer be considered for this mission, and can continue to apply to other opportunities.'
        }
        actionLabel="Withdraw"
        onConfirm={onWithdrawApplication}
        open={props.open}
        onClose={props.onClose}
      />
      <LoadingIndicator loading={loading} />
    </>
  );
};

export default WithdrawApplicationModal;
