import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { RadioButton } from '@src/components/RadioGroup/RadioButton';

export type Item = {
  label: string | ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
};

export type OnRadioGroupChange = (selectedItem: Item) => void;

interface Props {
  items: Item[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedValue?: any;
  onChange?: OnRadioGroupChange;
  labelStyle?: CSSProperties;
  buttonsStyle?: CSSProperties;
  className?: string;
  itemClassName?: string;
  'data-testing-id'?: string;
}

export const RadioGroup = (props: Props): ReactElement => {
  const {
    items,
    selectedValue,
    onChange,
    labelStyle,
    buttonsStyle,
    className,
  } = props;
  const handleRadioClick = (item: Item) => {
    onChange && onChange(item);
  };

  return (
    <div className={className} data-testing-id={props['data-testing-id']}>
      {items.map((item) => (
        <RadioButton
          key={item.value}
          item={item}
          className={props.itemClassName}
          onClick={handleRadioClick}
          checked={item.value === selectedValue}
          labelStyle={labelStyle}
          style={buttonsStyle}
          data-testing-id={`${props['data-testing-id']}-${item.value}`}
        />
      ))}
    </div>
  );
};

export default RadioGroup;
