import React, { forwardRef } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { theme } from '@a_team/ui-components';
import { Spacing } from '@ateams/components';

export enum Size {
  ExtraLarge = 'extra large',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

const useStyles = createUseStyles({
  size: {
    fontSize: ({ size }: { size?: Size }) => {
      switch (size) {
        case Size.ExtraLarge:
          return '20px';

        case Size.Large:
          return '16px';

        case Size.Medium:
          return '14px';

        case Size.Small:
        default:
          return '12px';
      }
    },
    lineHeight: ({ size }: { size?: Size }) => {
      switch (size) {
        case Size.ExtraLarge:
          return '26px';

        case Size.Large:
          return '22px';

        case Size.Medium:
          return '20px';

        case Size.Small:
        default:
          return '18px';
      }
    },
  },
});

const useInputLabelStyles = createUseStyles({
  inputLabel: {
    fontWeight: 500,
    color: theme.colors.Grey[800],
    padding: 0,
  },
});

export interface InputLabelProps {
  size?: Size;
  className?: string;
}

export const InputLabel: React.FC<InputLabelProps> = (props) => {
  const { children } = props;
  const styles = useStyles(props);
  const inputLabelStyles = useInputLabelStyles();

  return (
    <div
      className={cx(inputLabelStyles.inputLabel, styles.size, props.className)}
    >
      {children}
    </div>
  );
};

InputLabel.defaultProps = {
  size: Size.Medium,
};

export interface TextProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  size?: Size;
  isReadOnly?: boolean;
  readOnlyText?: string;
}

const useTextStyles = createUseStyles({
  text: {
    fontWeight: 400,
    color: ({ isReadOnlyEmpty }: { isReadOnlyEmpty: boolean }) =>
      isReadOnlyEmpty ? theme.colors.Grey[500] : theme.colors.Grey[800],
    whiteSpace: 'pre-line',
  },
});

export const Text = forwardRef<HTMLDivElement, TextProps>((props, ref) => {
  const { size, isReadOnly, readOnlyText, ...rest } = props;
  const isReadOnlyEmpty = !props.children;
  const styles = useStyles(props);
  const textStyles = useTextStyles({ isReadOnlyEmpty });

  return (
    <div
      {...rest}
      ref={ref}
      className={cx(textStyles.text, styles.size, props.className)}
    >
      {props.children ||
        (isReadOnly && isReadOnlyEmpty ? readOnlyText : undefined)}
    </div>
  );
});

Text.defaultProps = {
  size: Size.Medium,
  readOnlyText: '--',
};

const useErrorTextStyles = createUseStyles({
  errorText: {
    color: theme.colors.Red[600],
  },
});

export const ErrorText: typeof Text = forwardRef<HTMLDivElement, TextProps>(
  (props, ref) => {
    const errorTextStyles = useErrorTextStyles();

    return (
      <Text
        {...props}
        ref={ref}
        size={Size.Small}
        className={cx(errorTextStyles.errorText, props.className)}
      />
    );
  },
);

export interface ErrorTagProps {
  text: string;
  className?: string;
}

const useErrorTagStyles = createUseStyles({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
    background: theme.colors.Red[100],
    borderRadius: Spacing.small,
  },
});

export const ErrorTag: React.FC<ErrorTagProps> = (props) => {
  const styles = useErrorTagStyles();

  return (
    <div className={cx(styles.container, props.className)}>
      <ErrorText>{props.text}</ErrorText>
    </div>
  );
};
