import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { SidebarSection } from '../SidebarSection';
import PortfolioObject from '@a_team/models/dist/PortfolioObject';
import TextInput from '@src/components/Inputs/TextInput/TextInput';
import TextAreaInput from '@src/components/Inputs/TextAreaInput';
import cx from 'classnames';
import { Icon } from '@a_team/ui-components';
import { isValidUrl, withHttps } from '@src/helpers/urls';

interface PortfolioProps {
  portfolio?: PortfolioObject;
  readonly?: boolean;
  onChange(portfolio: PortfolioObject): void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  sectionTitle: {
    fontSize: 14,
    color: '#62646A',
  },
  portfolioUrl: {
    marginTop: 12,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    '& > a': {
      fontSize: 17,
      fontWeight: 500,
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  sectionText: {
    color: '#222222',
    fontSize: 15,
  },
  passwordContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 5,
    '& > button': {
      border: 'none',
      background: 'none',
      color: '#62646A',
      fontSize: 14,
      fontWeight: 500,
      cursor: 'pointer',
    },
  },
  textArea: {
    minHeight: 120,
  },
  input: {
    border: '1px solid #DADADC',
    borderRadius: 4,
    padding: '8px 12px',
  },
  portfolioUrlInput: {
    fontSize: 17,
  },
  passwordButton: {
    fontWeight: 500,
    color: '#62646A',
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
});

const emptyPortfolio: PortfolioObject = {
  url: '',
  password: '',
  description: '',
};

const Portfolio = ({
  portfolio: portfolioProp,
  readonly,
  onChange,
}: PortfolioProps): JSX.Element => {
  const styles = useStyles();
  const [hidePassword, setHidePassword] = useState(true);

  const portfolio = portfolioProp || emptyPortfolio;
  // add https to the url if it doesn't have it
  const portfolioUrlWithHttps = withHttps(portfolio.url);

  return (
    <SidebarSection title={'Portfolio'} withAddIcon={false}>
      <div className={styles.container}>
        <div className={styles.portfolioUrl}>
          {readonly && portfolio ? (
            <>
              <Icon
                style={{
                  color: '#fe630c',
                }}
                size="md"
                name={'lock'}
              />{' '}
              <a
                href={portfolioUrlWithHttps}
                target="_blank"
                rel="noopener noreferrer"
              >
                {portfolioUrlWithHttps}
              </a>
            </>
          ) : (
            <TextInput
              variant="dashed"
              placeholder={'http://...'}
              value={portfolio?.url || ''}
              onBlur={() => {
                onChange({
                  ...portfolio,
                  url: withHttps(portfolio.url),
                });
              }}
              onChange={(e) => {
                onChange({
                  ...portfolio,
                  url: e.target.value,
                });
              }}
              className={styles.portfolioUrlInput}
            />
          )}
        </div>
        {(portfolio.password || !readonly) && (
          <div className={styles.section}>
            <div className={styles.sectionTitle}>Password</div>
            {readonly ? (
              <div className={styles.passwordContainer}>
                <span className={styles.sectionText}>
                  {hidePassword ? (
                    <span>
                      &lowast;&lowast;&lowast;&lowast;&lowast;&lowast;&lowast;&lowast;&lowast;&lowast;
                    </span>
                  ) : (
                    portfolio.password
                  )}
                </span>
                <button
                  className={styles.passwordButton}
                  onClick={() => setHidePassword(!hidePassword)}
                >
                  {hidePassword ? (
                    <>
                      Show <Icon name={'previewOn'} />
                    </>
                  ) : (
                    <>
                      Hide <Icon name={'previewOff'} />
                    </>
                  )}
                </button>
              </div>
            ) : (
              <TextInput
                placeholder={'Password'}
                value={portfolio.password}
                onChange={(e) =>
                  onChange({
                    ...portfolio,
                    password: e.target.value,
                  })
                }
                className={styles.input}
              />
            )}
          </div>
        )}

        <div className={styles.section}>
          <div className={styles.sectionTitle}>Portfolio details</div>
          {readonly ? (
            <span className={styles.sectionText}>{portfolio.description}</span>
          ) : (
            <TextAreaInput
              placeholder={'Description'}
              value={portfolio.description}
              required
              onBlur={() => isValidUrl(portfolio.description)}
              onChange={(e) =>
                onChange({
                  ...portfolio,
                  description: e.target.value,
                })
              }
              className={cx(styles.textArea, styles.input)}
            />
          )}
        </div>
      </div>
    </SidebarSection>
  );
};

export default Portfolio;
