import {
  BorderRadius,
  Breakpoints,
  FontSizes,
  FontWeights,
  Icon,
  IconType,
  Spacing,
  TextColors,
} from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useQueryClient } from '@tanstack/react-query';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { useStores } from '@src/stores';
import { goToAddACalendarClick } from '@src/views/InterviewSettingsViewV2/helpers';
import { useLocation } from 'react-router-dom';
import { ConnectCalendarButton } from '@src/views/InterviewSettingsViewV2/ConnectCalendarButton';

const useStyles = createUseStyles({
  calendarMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: Spacing.medium,
    backgroundColor: '#F7F7F7',
    borderRadius: BorderRadius.medium,
    gap: 24,
    marginBottom: Spacing.xLarge,
  },
  calendarWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: Spacing.medium,
    alignItems: 'center',
  },
  noCalendarInstalledTitle: {
    color: TextColors.regular,
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.semiBold,
  },
  connectCalendar: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  [`@media (max-width: ${Breakpoints.sm}px)`]: {
    calendarMainContainer: {
      marginBottom: Spacing.xxLarge,
    },
    calendarWrap: {
      flexDirection: 'column',
    },
    noCalendarInstalledTitle: {
      fontSize: FontSizes.xsmall,
      fontWeight: FontWeights.regular,
    },
  },
});

export const CalendarBanner = () => {
  const styles = useStyles();
  const { auth } = useStores();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const location = useLocation();

  const handleCalendarClick = () => {
    goToAddACalendarClick(auth, queryClient, location.pathname);
    analytics.trackMissionConnectCalendarClick(auth.uid ?? '');
  };

  return (
    <div className={styles.calendarMainContainer}>
      <div className={styles.calendarWrap}>
        <div className={styles.connectCalendar}>
          <Icon type={IconType.PurpleCalendar} size="large" />
          <div>
            <div className={styles.noCalendarInstalledTitle}>
              Speed up interview scheduling by connecting your calendar.
            </div>
          </div>
        </div>
        <ConnectCalendarButton onCalendarClick={handleCalendarClick} />
      </div>
    </div>
  );
};
