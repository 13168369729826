import { EmailSubscriptionChangeDtoCategoryEnum } from '@a_team/user-notification-service-js-sdk';
import { preferencesApi } from '@src/logic/services/endpoints';
import { useMutation } from '@tanstack/react-query';

export enum UpdateSubscriptionAction {
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
}

export interface UpdateSubscriptionMutationParams {
  action: UpdateSubscriptionAction;
  token: string;
  category: EmailSubscriptionChangeDtoCategoryEnum;
}

/**
 * Subscribes / Unsubscribes a user from a category of emails
 */
export const useMutationUpdateEmailCategorySubscription = () => {
  return useMutation({
    mutationFn: async ({
      action,
      token,
      category,
    }: UpdateSubscriptionMutationParams) => {
      const data = {
        emailSubscriptionChangeDto: {
          token,
          category,
        },
      };
      return action === UpdateSubscriptionAction.SUBSCRIBE
        ? preferencesApi.preferencesControllerEmailSubscribe(data)
        : preferencesApi.preferencesControllerEmailUnsubscribe(data);
    },
  });
};
