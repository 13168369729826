import { useState, useRef } from 'react';
import deepEqual from 'fast-deep-equal';

export default function useDebounceState<S>(
  initialState: S | (() => S),
  onDebounce: (value: S) => void,
  delay = 200,
): [S, (value: S, ignore?: boolean) => void, boolean] {
  const [value, setValue] = useState<S>(initialState);
  const debounceRef = useRef<{ timer: NodeJS.Timeout; value: S } | null>(null);

  return [
    value,
    (newValue: S, ignore?: boolean): void => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current.timer);

        if (deepEqual(debounceRef.current.value, newValue)) {
          debounceRef.current = null;
          setValue(newValue);
          return;
        }
      }

      debounceRef.current = ignore
        ? null
        : {
            timer: setTimeout((): void => {
              debounceRef.current = null;
              onDebounce(newValue);
            }, delay),
            value,
          };

      setValue(newValue);
    },
    debounceRef.current != null,
  ];
}
