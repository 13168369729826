import React from 'react';

export const NegativeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6ZM12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM3.64645 4.35355L5.29289 6L3.64645 7.64645L4.35355 8.35355L6 6.70711L7.64645 8.35355L8.35355 7.64645L6.70711 6L8.35355 4.35355L7.64645 3.64645L6 5.29289L4.35355 3.64645L3.64645 4.35355Z"
        fill="currentColor"
      />
    </svg>
  );
};
