import React, { ReactElement } from 'react';

interface Props {
  iconFill?: string;
  iconStroke: string;
}

export default function UndoIcon(props: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        className={props.iconStroke}
        d="M2 6h8a4 4 0 010 8H6"
        opacity="0.6"
      ></path>
      <path className={props.iconStroke} d="M6 10L2 6l4-4"></path>
    </svg>
  );
}
