import React, { CSSProperties, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import Profile from '@src/stores/Profile/Profile';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import AdminNotesTabs from '@src/views/AdminNotes/Tabs';
import {
  AdminNotesTab,
  AdminNotesTabsState,
  useAdminNotesTabs,
} from '@src/views/AdminNotes/Tabs/hooks';
import NotesTab from '@src/views/AdminNotes/Main/NotesTab';
import MissionsTab from '@src/views/AdminNotes/Main/MissionsTab';
import ApplicationsTab from '@src/views/AdminNotes/Main/ApplicationsTab';
import { VettingTab } from './VettingTab';
import { DiscoveryTab } from './DiscoveryTab';

interface Props {
  profile?: Profile;
  style?: CSSProperties;
  tabsState?: AdminNotesTabsState;
}

const useStyles = createUseStyles({
  wrapper: {
    padding: '0',
    maxWidth: 1440,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    wrapper: {
      padding: '20px 40px',
    },
  },
});

const AdminNotesMain = (props: Props) => {
  const styles = useStyles();
  const { auth, users } = useStores();

  const localTabsState = useAdminNotesTabs();
  const tabsState = props.tabsState || localTabsState;

  const profile = useMemo(() => {
    return props.profile || users.profile;
  }, [props.profile, users.profile]);

  if (!profile) {
    return null;
  }

  let content;
  switch (tabsState[0]) {
    case AdminNotesTab.Notes: {
      content = <NotesTab profile={profile} authUsername={auth.username} />;
      break;
    }

    case AdminNotesTab.Missions: {
      content = profile.adminNotes && (
        <MissionsTab
          missions={profile.adminNotes?.data.missions || []}
          setRolePerformanceFlag={profile.adminNotes?.setRolePerformanceFlag}
          latestTimesheetsSummaries={
            profile.adminNotes?.data.latestTimesheetsSummaries
          }
        />
      );
      break;
    }

    case AdminNotesTab.Applications: {
      content = profile.adminNotes && (
        <ApplicationsTab
          applications={profile.adminNotes?.data.applications}
          user={profile.data}
        />
      );
      break;
    }

    case AdminNotesTab.Discovery: {
      content = profile.adminNotes && (
        <DiscoveryTab discovery={profile?.adminNotes.data.discovery} />
      );
      break;
    }

    case AdminNotesTab.Vetting: {
      content = profile.adminNotes && (
        <VettingTab
          vettingProcesses={profile.adminNotes.data.vettingProcesses || []}
          applications={profile?.adminNotes.data.applications.items}
          profile={profile}
        />
      );
      break;
    }
  }

  return (
    <>
      {!props.tabsState && <AdminNotesTabs state={tabsState} />}

      <div className={styles.wrapper} style={props.style}>
        {content}
      </div>
    </>
  );
};

export default observer(AdminNotesMain);
