import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { loadApplication } from '@src/url-loaders/loadApplication';
import { useStores } from '@src/stores';
import ApplicationView from '@src/views/Application';
import Loader from '@src/components/Loader';

export const editApplyMissionView = loadApplication;

function ApplyMissionView(): ReactElement {
  const stores = useStores();
  const { auth } = stores;

  if (!auth.user) {
    return <Loader />;
  }

  return <ApplicationView />;
}

export default observer(ApplyMissionView);
