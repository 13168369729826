import React, { ReactElement, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import useStyles from './useStyles';
import cx from 'classnames';
import inViewport from 'in-viewport';

export interface Props {
  to: string;
  children: JSX.Element;
  isFirst?: boolean;
  disabled?: boolean;
}

export default function TabNavLink(props: Props): ReactElement {
  const { to, children, isFirst } = props;

  const styles = useStyles();
  const history = useHistory();
  const [isInViewport, setIsInViewport] = useState(false);

  const anchor = to.split('#')[1] || '';
  const hash = `#${anchor}`;

  useEffect((): (() => void) => {
    function handleScroll(): void {
      if (anchor == null) return;

      const elm = document.getElementById(anchor);
      if (!elm) return;

      if (inViewport(elm) !== isInViewport) {
        if (!isInViewport && history.location.hash !== hash) {
          history.replace(
            history.location.pathname + history.location.search + hash,
          );
        }

        setIsInViewport(!isInViewport);
      }
    }

    if (isFirst && !history.location.hash && anchor) {
      history.replace(
        history.location.pathname + history.location.search + hash,
      );
    }

    document.addEventListener('scroll', handleScroll);

    return (): void => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, ['to']);

  return (
    <NavLink
      to={to}
      className={cx(styles.root, { disabled: props.disabled })}
      style={{ minWidth: 100, flexShrink: 0, textAlign: 'center' }}
      isActive={(match, location): boolean => {
        if (!match) {
          return false;
        }

        return anchor === location.hash.replace(/^#/, '');
      }}
      onClick={(): void => {
        if (!anchor) {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          return;
        }

        setTimeout((): void => {
          const elm = document.getElementById(anchor);
          if (!elm) return;

          const rect = elm.getBoundingClientRect();
          const navOffset = 140; // Math.min(window.innerHeight * 0.2, 140);

          window.scrollTo({
            top: rect.top + window.pageYOffset - navOffset,
            // left: 0,
            behavior: 'smooth',
          });
        });
      }}
    >
      {children}
    </NavLink>
  );
}
