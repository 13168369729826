import { Icon, IconType } from '@ateams/components';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import React, { ChangeEvent } from 'react';
import { createUseStyles } from 'react-jss';

interface SearchProps {
  searchQuery: string;
  onSearch: (query: string) => void;
  isLoading?: boolean;
}

const useStyles = createUseStyles({
  root: {
    marginTop: 36,
  },
  inputWrapper: {
    position: 'relative',
    display: 'inline-block',
    maxWidth: 600,
    width: '100%',
  },
  input: {
    '& > div': {
      padding: '12px 16px',
      border: '1px solid #DADADC',
      borderRadius: 8,
      minHeight: 'auto',
    },
  },
});

function Search({ searchQuery, onSearch, isLoading }: SearchProps) {
  const styles = useStyles();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };

  return (
    <div className={styles.root}>
      <div className={styles.inputWrapper}>
        <OutlinedInput
          margin={'none'}
          value={searchQuery}
          onChange={onChange}
          placeholder="Search for your connections"
          className={styles.input}
          endAdornment={
            isLoading ? (
              <Icon
                type={IconType.CircleLoadingViolet}
                rotating={true}
                size={'medium'}
              />
            ) : (
              <Icon type={IconType.SearchGrey} size={'medium'} />
            )
          }
        />
      </div>
    </div>
  );
}

export default Search;
