import React, { ReactElement } from 'react';
import { Icon, IconType, Tag, TagList } from '@ateams/components';
import { createUseStyles } from 'react-jss';

interface Props {
  title: string;
  text: string;
  missingRole?: string[];
  missingSkills?: string[];
  lowProficiencySkills?: string[];
  missingPreferredSkills?: string[];
  missingOverlap?: string[];
  missingAvailability?: string[];
  missingLocation?: string[];
  missingCustomQuestions?: string[];
}

interface RowProps {
  title: string;
  tags?: string[];
  tagsColor?: string;
  tagsIcon?: ReactElement;
}

const useStyles = createUseStyles({
  row: {
    padding: 16,
    borderBottom: '1px solid #DADADC',
    '&:last-of-type': {
      border: 'none',
    },
  },
  wrap: { borderRadius: 8, border: '1px solid #DADADC' },
});

const Row = (props: RowProps) => {
  const styles = useStyles();
  const { title, tags, tagsColor = '#F7F7F7', tagsIcon } = props;
  return (
    <div className={styles.row}>
      <h4
        style={{
          fontSize: 15,
          fontWeight: 'normal',
          marginTop: 0,
          marginBottom: 10,
        }}
      >
        {title}
      </h4>
      <TagList>
        {tags?.map((tag) => (
          <Tag
            square
            key={tag}
            style={{ background: tagsColor, fontSize: 15, padding: 10 }}
          >
            {tagsIcon}
            {tag}
          </Tag>
        ))}
      </TagList>
    </div>
  );
};

export const RequirementSection = (props: Props): ReactElement => {
  const styles = useStyles();
  const {
    title,
    text,
    missingRole,
    missingSkills,
    lowProficiencySkills,
    missingAvailability,
    missingPreferredSkills,
    missingOverlap,
    missingLocation,
    missingCustomQuestions,
  } = props;

  const rows: RowProps[] = [
    {
      title: 'Role',
      tags: missingRole,
      tagsColor: '#FFF7F8',
    },
    {
      title: 'Skills',
      tags: missingSkills,
      tagsColor: '#FFF7F8',
    },
    {
      title: 'Proficiency',
      tags: lowProficiencySkills,
      tagsIcon: (
        <Icon
          type={IconType.ArrowBack}
          size={'exact'}
          style={{ transform: 'rotate(-90deg)', marginRight: 8 }}
        />
      ),
      tagsColor: '#FFF7F8',
    },
    {
      title: 'Location',
      tags: missingLocation,
    },
    {
      title: 'Skills',
      tags: missingPreferredSkills,
    },
    {
      title: 'Working-hours overlap',
      tags: missingOverlap,
    },
    {
      title: 'Availability',
      tags: missingAvailability,
    },
    {
      title: 'Client Question',
      tags: missingCustomQuestions,
    },
  ];

  return (
    <>
      <h3>{title}</h3>
      <p>{text}</p>
      <div className={styles.wrap}>
        {rows.map(
          (row) =>
            row.tags &&
            row.tags.length > 0 && (
              <Row
                key={row.title}
                title={row.title}
                tags={row.tags}
                tagsIcon={row.tagsIcon}
                tagsColor={row.tagsColor}
              />
            ),
        )}
      </div>
    </>
  );
};
