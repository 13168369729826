import React from 'react';
import { createUseStyles } from 'react-jss';
import timeGift from '@src/assets/gifs/time.gif';

const useStyles = createUseStyles({
  content: {
    padding: 24,
  },
  headerTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '22px',
  },
  headerDesc: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,
    fontSize: 15,
    lineHeight: '24px',
    color: '#818388',
  },
});

function TimeGuidance() {
  const styles = useStyles();

  return (
    <div>
      <img width="100%" src={timeGift} alt="Time" />
      <div className={styles.content}>
        <div className={styles.headerTitle}>Inputting time in timesheets</div>
        <div className={styles.headerDesc}>
          Enter time as Hours:Minutes, separated by a colon (e.g., 02:30 for 2
          hours and 30 minutes).
        </div>
      </div>
    </div>
  );
}

export default TimeGuidance;
