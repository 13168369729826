import React, { InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import check from '../assets/checkbox/check.svg';
import checkDisabled from '../assets/checkbox/check-disabled.svg';
import checkIndeterminate from '../assets/checkbox/check-indeterminate.svg';
import Colors from '../theme/colors';

const MARGINS = { none: 0, default: '24px 0', top: '0.5em 0' };

export interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'margin'> {
  margin?: 'default' | 'none' | 'top';
  label?: ReactNode;
  labelClassName?: string;
  labelPosition?: 'left' | 'right';
  indeterminate?: boolean;
  color?: string;
  checkboxContainerClassName?: string;
}

const defaultProps = {
  labelPosition: 'right',
  margin: 'default',
};

const useStyles = createUseStyles({
  root: (props: CheckboxProps) => ({
    margin: MARGINS[props.margin || 'default'],
    userSelect: 'none',
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    display: 'flex',
    alignItems: 'center',
    ...(props.labelPosition === 'left' && {
      flexDirection: 'row-reverse',
    }),
  }),
  text: (props: CheckboxProps) => ({
    marginLeft: 8,
    ...(props.labelPosition === 'left' && {
      marginLeft: 0,
      marginRight: 8,
    }),
  }),
  checkboxContainer: {
    width: '18px',
    height: '18px',
    display: 'inline-block',
    position: 'relative',
    cursor: (props: CheckboxProps): string =>
      props.disabled ? 'not-allowed' : 'pointer',
    userSelect: 'none',
    background: (props: CheckboxProps): string =>
      props.indeterminate
        ? `url(${checkIndeterminate}) center 4px no-repeat ${
            props.color || Colors.success
          }`
        : props.checked
        ? props.disabled
          ? `url(${checkDisabled}) center 3px no-repeat`
          : `url(${check}) center 5px no-repeat ${
              props.color || Colors.success
            }`
        : 'none',
    border: (props: CheckboxProps): string =>
      (props.checked || props.indeterminate) && !props.disabled
        ? 'none'
        : `2px solid ${props.disabled ? 'rgba(34,34,34,0.12)' : '#c0c0c0'}`,
    borderRadius: '5px',
    overflow: 'hidden',
    flexShrink: 0,

    '& > input': {
      display: 'block',
      position: 'absolute',
      opacity: 0,
      cursor: 'inherit',
      height: '100%',
      width: '100%',
      left: -2,
      top: -2,
    },
  },
});

const Checkbox = (props: CheckboxProps): ReactElement => {
  const {
    label,
    className,
    labelClassName,
    margin,
    labelPosition,
    indeterminate,
    checkboxContainerClassName,
    ...left
  } = props;
  const styles = useStyles(props);

  return (
    <label className={cx(styles.root, className)}>
      <div className={cx(styles.checkboxContainer, checkboxContainerClassName)}>
        <input type={'checkbox'} {...left} />
      </div>
      {label && (
        <span className={cx(styles.text, labelClassName)}>{label}</span>
      )}
    </label>
  );
};

Checkbox.defaultProps = defaultProps;
export default Checkbox;
