import { action, computed, observable } from 'mobx';
import Cookies from 'js-cookie';
import UserObject, {
  AdminBasicUserObject,
  AdminUnvettedApplicant,
  BasicUserObject,
  UserCardObject,
  UserId,
  UserStatus,
  UserType,
  UserUsername,
} from '@a_team/models/dist/UserObject';
import {
  apiConnectionsV2,
  apiMissions,
  apiUsers,
  teamEngineSearchServiceApi,
} from '@src/logic/services/endpoints';
import AuthStore from '@src/stores/Auth';
import {
  ConnectionObjectV2,
  ConnectionType,
} from '@a_team/models/dist/ConnectionObject';
import { Stores } from '@src/stores/index';
import { UsersStats } from '@ateams/api/dist/endpoints/Users';
import { QueryResult } from '@a_team/models/dist/misc';
import Profile, {
  SerializedProfileStoreData,
} from '@src/stores/Profile/Profile';
import { ApplicationViewMode } from '@src/stores/Application';
import { PlatformServiceAnalytics } from '@ateams/analytics/dist/platform';
import {
  ApplicationDetails,
  ProfileViewMode,
} from '@src/stores/Profile/models';
import { TeamId } from '@a_team/models/dist/TeamObject';
import { apiTeams } from '@ateams/api';
import { ConnectionId } from '@a_team/models/dist/ConnectionObject';
import { MissionStatus } from '@a_team/models/dist/MissionObject';
import {
  UserApplicationDataChatGtpExportObject,
  UserDataChatGtpExportObject,
} from '@a_team/models/dist/UserDataChatGtpExport';

// START: Types for inferred return types
export type Awaited<T> = T extends PromiseLike<infer U> ? U : T;
export type AwaitedReturnType<T> = Awaited<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ReturnType<T extends (...args: any) => any ? T : never>
>;
export type APIUserList = AwaitedReturnType<
  typeof UsersStore.prototype.findUsers
>;
export type FirstItem<T> = T extends Array<infer U> ? U : never;
export type FirstUser = FirstItem<APIUserList>;
// END: Types for inferred return types

type userId = string;

type SerializedUsersStoreData = Omit<UsersStoreData, 'profile'> & {
  profile?: SerializedProfileStoreData;
};

export interface UsersStoreData {
  profile?: Profile;
  isCurrentUser: boolean;
  userCards: { [uid: string]: UserCardObject };
  currentUserCard?: userId;
  stats?: UsersStats;
  newUsers?: AdminBasicUserObject[];
  unvettedApplicants?: AdminUnvettedApplicant[];
  nextTokens?: {
    unvettedApplicants?: string;
  };
  isEnhancingProfile?: boolean;
  hasProjectsSuggestionError?: boolean;
}

export default class UsersStore implements UsersStoreData {
  @observable public profile: UsersStoreData['profile'];
  @observable public isCurrentUser = false;
  @observable public userCards: UsersStoreData['userCards'] = {};
  @observable public currentUserCard: UsersStoreData['currentUserCard'];
  @observable public stats: UsersStoreData['stats'];
  @observable public newUsers: UsersStoreData['newUsers'];
  @observable public unvettedApplicants: UsersStoreData['unvettedApplicants'];
  @observable public nextTokens: UsersStoreData['nextTokens'];
  @observable public isEnhancingProfile = false;
  @observable public hasProjectsSuggestionError = false;

  private rootStore: Stores;
  private analytics: PlatformServiceAnalytics;
  protected abortControllerQueryByString: AbortController | undefined;

  PROFILE_VIEW_TIMEOUT_MS = 20 * 60e3; // 20 minutes

  public constructor(
    rootStore: Stores,
    analytics: PlatformServiceAnalytics,
    initialState?: UsersStoreData,
  ) {
    this.rootStore = rootStore;
    this.analytics = analytics;

    if (initialState) {
      const profileData = initialState.profile?.data;
      this.isCurrentUser = initialState.isCurrentUser;
      this.userCards = initialState.userCards;
      if (profileData) {
        this.profile = new Profile(
          profileData,
          rootStore.auth,
          analytics,
          rootStore.missions,
        );
        if (
          initialState.profile?.application?.mid &&
          initialState.profile?.application?.rid
        ) {
          this.profile.setApplication(
            {
              mid: initialState.profile.application.mid,
              rid: initialState.profile.application.rid,
              aid: initialState.profile.application.aid,
            },
            initialState.profile?.application.data,
            ApplicationViewMode.Edit,
          );
        }
        initialState.profile?.mode &&
          this.profile.setProfileMode(initialState.profile?.mode);
      }
    }
  }

  public serialize = (): SerializedUsersStoreData => ({
    profile: this.profile?.serialize(),
    isCurrentUser: this.isCurrentUser,
    userCards: this.userCards,
    stats: this.stats,
    newUsers: this.newUsers,
    unvettedApplicants: this.unvettedApplicants,
  });

  public fetchCurrentProfile = (
    username: string,
    reload?: boolean,
  ): Promise<unknown> => {
    if (this.currentUsername === username && !reload) {
      this.setIsCurrentUser(this.rootStore.auth.username);
      return Promise.resolve();
    }

    // Reset the previous user for loading state
    this.setProfile(null);

    return apiUsers
      .getUserByUsername(this.rootStore.auth, username)
      .then((user): void => {
        this.setProfile(user);
        this.setIsCurrentUser(this.rootStore.auth.username);
      });
  };

  public fetchAdminNotes = async (profile: Profile): Promise<void> => {
    if (!(this.rootStore.auth.isAdmin && profile)) {
      return;
    }

    if (profile) {
      await profile.fetchAdminNotes();
      profile.setProfileMode(ProfileViewMode.Admin);
    }
  };

  public fetchApplication = async (
    applicationDetails: ApplicationDetails,
  ): Promise<void> => {
    if (!applicationDetails) return;
    let applicationData = null;
    let mode;

    if (
      !this.rootStore.missions.currentMission ||
      this.rootStore.missions.currentMission?.mid !== applicationDetails.mid
    ) {
      // Fetch mission
      await this.rootStore.missions.loadCurrent(
        applicationDetails.mid,
        this.rootStore.auth.isAdmin,
      );
    }

    if (applicationDetails.aid) {
      applicationData = await apiMissions.getMissionApplication(
        this.rootStore.auth,
        applicationDetails.mid,
        applicationDetails.aid,
      );
    } else {
      applicationData = await apiMissions.getMissionApplicationDraft(
        this.rootStore.auth,
        applicationDetails.rid,
      );
    }

    await this.fetchCurrentProfile(applicationData.user.username, true);

    if (
      applicationData.user.uid === this.rootStore.auth.uid ||
      this.rootStore.auth.isAdmin
    ) {
      mode = ApplicationViewMode.Edit;
      this.profile?.setProfileMode(ProfileViewMode.Edit);
    }

    if (
      !this.profile?.application ||
      this.profile.application.rid !== applicationDetails.rid
    ) {
      this.profile?.setApplication(applicationDetails, applicationData, mode);
    }
  };

  getFullUser = (username: string, maybeAuth: AuthStore): Promise<UserObject> =>
    apiUsers.getUserByUsername(maybeAuth, username);

  getUserCard = (
    username: string,
    maybeAuth: AuthStore,
  ): Promise<UserCardObject> =>
    apiUsers.getUserCardByUsername(maybeAuth, username);

  getUsersByString = (query: string): Promise<BasicUserObject[]> =>
    apiUsers.queryByString(this.rootStore.auth, query);

  @computed
  public get userToInteract(): UserCardObject | undefined {
    return this.currentUserCard
      ? this.userCards[this.currentUserCard]
      : undefined;
  }

  @computed
  public get currentUsername(): string | null {
    return this.profile ? this.profile.data.username : null;
  }

  @computed
  public get currentFirstName(): string | null {
    return this.profile ? this.profile.data.firstName : null;
  }

  public verifyUserProfile(username: UserUsername): Promise<void> {
    return apiUsers
      .verifyUserProfile(this.rootStore.auth, username)
      .then((user) => {
        this.profile?.setData(user);
      });
  }

  @action setProfile = (user: UserObject | null): void => {
    if (!user) {
      this.profile = undefined;
      return;
    }

    this.profile = new Profile(
      user,
      this.rootStore.auth,
      this.analytics,
      this.rootStore.missions,
    );
  };

  acceptTeamInvite = async (teamId: TeamId): Promise<void> => {
    await apiTeams.respondToTeamUpRequest(this.rootStore.auth, teamId, {
      accepted: true,
    });
    this.rootStore.registration.setApproveTeamFromEmail(undefined);
  };

  @action setIsCurrentUser = (username: string | null): void => {
    this.isCurrentUser = username
      ? username === this.profile?.data.username
      : false;
  };

  @action
  public setCurrentUserCard = (user: UserCardObject | null): void => {
    user && (this.userCards[user.uid] = user);
    this.currentUserCard = user ? user.uid : undefined;
  };

  @action requestConnection = (
    auth: AuthStore,
    requested: UserId,
    type: ConnectionType,
  ): Promise<ConnectionObjectV2> => {
    return apiConnectionsV2
      .requestConnection(auth, {
        requestedUserId: requested,
        type,
      })
      .then((updatedConnection: ConnectionObjectV2) => {
        return updatedConnection;
      });
  };

  @action approveConnection = (
    auth: AuthStore,
    cid: ConnectionId,
  ): Promise<ConnectionObjectV2> => {
    return apiConnectionsV2
      .approveConnection(auth, cid, {})
      .then((updatedConnection: ConnectionObjectV2) => {
        return updatedConnection;
      });
  };

  @action deleteConnection = (
    auth: AuthStore,
    cid: ConnectionId,
  ): Promise<ConnectionObjectV2> => {
    return apiConnectionsV2
      .deleteConnection(auth, cid)
      .then((updatedConnection: ConnectionObjectV2) => {
        return updatedConnection;
      });
  };

  @action cancelConnectionRequest = (
    auth: AuthStore,
    cid: ConnectionId,
  ): Promise<ConnectionObjectV2> => {
    return apiConnectionsV2
      .cancelConnectionRequest(auth, cid)
      .then((updatedConnection: ConnectionObjectV2) => {
        return updatedConnection;
      });
  };

  @action updatCurrentUserCard = (
    user: BasicUserObject | null,
    auth?: AuthStore,
  ): void => {
    if (!user) {
      this.setCurrentUserCard(null);
      return;
    }

    if (!this.userCards[user.uid] && auth) {
      this.getUserCard(user.username, auth).then((result) => {
        this.setCurrentUserCard(result);
      });
    } else {
      this.setCurrentUserCard(this.userCards[user.uid]);
    }
  };

  @action trackProfileView = (username: string, auth: AuthStore): void => {
    const usernameCookie = `profile-view-${username}`;
    if (Cookies.get(usernameCookie) || username === auth.username) {
      return;
    }

    Cookies.set(usernameCookie, 'viewed', {
      expires: new Date(Date.now() + this.PROFILE_VIEW_TIMEOUT_MS),
    });

    apiUsers
      .recordProfileVisit(auth, username)
      .catch((err) => console.warn(err));
  };

  public async loadUserStats(): Promise<void> {
    if (this.stats) return;

    await apiUsers
      .getUsersStats(this.rootStore.auth)
      .then((stats) => this.setStats(stats));
  }

  @action
  public setStats(stats: UsersStoreData['stats']): void {
    this.stats = stats;
  }

  public async loadNewUsers(): Promise<void> {
    if (this.newUsers) return;

    const result = await this.findUsers();
    this.setNewUsers(result as unknown as AdminBasicUserObject[]);
  }

  @action
  public setNewUsers(newUsers: UsersStoreData['newUsers']): void {
    this.newUsers = newUsers;
  }

  public async getUserDataExport(
    username: string,
  ): Promise<UserDataChatGtpExportObject | undefined> {
    return apiUsers.getUserDataChatGptExport(this.rootStore.auth, username);
  }

  public async getUserApplicationDataExport(
    applicationId: string,
    username: string,
  ): Promise<UserApplicationDataChatGtpExportObject | undefined> {
    return apiUsers.getUserApplicationDataChatGptExport(
      this.rootStore.auth,
      username,
      applicationId,
    );
  }

  public adminQueryByString = async (
    query: string,
    options?: {
      queryOnlyAdminUsers?: boolean;
      userStatus?: UserStatus[];
      userType?: UserType[];
    },
  ): Promise<AdminBasicUserObject[]> => {
    this.abortControllerQueryByString?.abort();
    this.abortControllerQueryByString = new AbortController();
    return apiUsers.adminQueryByString(
      this.rootStore.auth,
      query,
      options,
      this.abortControllerQueryByString,
    );
  };

  public findUsers = async (params?: {
    query?: string;
    queryOnlyAdminUsers?: boolean;
    userStatus?: UserStatus[];
    userType?: UserType[];
    pageSize?: number;
    currentPage?: number;
  }) => {
    const {
      query,
      queryOnlyAdminUsers,
      userStatus,
      userType,
      pageSize = 50,
      currentPage = 1,
    } = params || {};

    const { data, errors } = await teamEngineSearchServiceApi.query(
      {
        v1ListUsers: [
          {
            filter: {
              query,
              status: userStatus,
              type: userType,
              isAdmin: queryOnlyAdminUsers,
            },
            sort: [{ field: 'createdAt', order: 'desc' }],
            page: { current: currentPage, size: pageSize },
          },
          {
            users: {
              badges: 1,
              status: 1,
              email: 1,
              firstName: 1,
              lastName: 1,
              fullName: 1,
              scrubbed: 1,
              title: 1,
              pictureURL: 1,
              profilePictureURL: 1,
              profileURL: 1,
              type: 1,
              uid: 1,
              verified: 1,
              englishScore: 1,
              expertiseScore: 1,
              interactionExperienceScore: 1,
              accentScore: 1,
              username: 1,
              updatedAt: 1,
              createdAt: 1,
            },
          },
        ],
      },
      {
        headers: {
          Authorization: this.rootStore.auth.bearerToken,
        },
      },
    );
    if (errors) {
      throw new Error(errors[0].message);
    }
    return data?.v1ListUsers?.users;
  };

  /**
   * Sets this.unvettedApplicants to the list of
   * unvetted users to have applied to missions.
   */
  @action
  public setUnvettedApplicants(
    unvettedApplicants: UsersStoreData['unvettedApplicants'],
  ): void {
    this.unvettedApplicants = [
      ...(this.unvettedApplicants || []),
      ...(unvettedApplicants || []),
    ];
  }

  @action setUnvettedApplicantsNextToken = (token: string | null): void => {
    this.nextTokens = {
      ...this.nextTokens,
      unvettedApplicants: token || undefined,
    };
  };

  @action setIsEnhancingProfile = (value: boolean): void => {
    this.isEnhancingProfile = value;
  };

  @action setHasProjectsSuggestionError = (value: boolean): void => {
    this.hasProjectsSuggestionError = value;
  };

  /**
   * Loads a list of unvetted users who have applied to missions.
   * They are unvetted applicants.
   * Then sets the value on this store.
   */
  public loadUnvettedApplicants = async (): Promise<void> => {
    await apiUsers
      .adminQueryUnvettedApplicants(
        this.rootStore.auth,
        this.nextTokens?.unvettedApplicants,
      )
      .then((res: QueryResult<AdminUnvettedApplicant>) => {
        this.setUnvettedApplicants(res.items);
        this.setUnvettedApplicantsNextToken(res.next);
      });
  };

  public trackTargeterSearchClicked = (
    mid: string,
    missionTitle: string,
    missionStatus: MissionStatus,
  ) => {
    this.analytics.trackTargeterSearchClicked(mid, missionTitle, missionStatus);
  };

  public trackTargeterTeamSearchClicked = (
    mid: string,
    missionTitle: string,
    missionStatus: MissionStatus,
  ) => {
    this.analytics.trackTargeterTeamSearchClicked(
      mid,
      missionTitle,
      missionStatus,
    );
  };

  public trackTargeterReviewClicked = (
    mid: string,
    missionTitle: string,
    missionStatus: MissionStatus,
  ) => {
    this.analytics.trackTargeterReviewClicked(mid, missionTitle, missionStatus);
  };

  public trackTargeterOutreachClicked = (
    mid: string,
    missionTitle: string,
    missionStatus: MissionStatus,
  ) => {
    this.analytics.trackTargeterOutreachClicked(
      mid,
      missionTitle,
      missionStatus,
    );
  };

  public trackSuggestedTeamsTargeterTabClicked = (tabsCount: number) => {
    this.analytics.trackSuggestedTeamsTargeterTabClicked(tabsCount);
  };

  public trackSuggestedTeamsRunQueryClicked = (
    tabsCount: number,
    buildersCount: number,
    allowPartialTeams: boolean,
  ) => {
    this.analytics.trackSuggestedTeamsRunQueryClicked(
      tabsCount,
      buildersCount,
      allowPartialTeams,
    );
  };

  public trackTargeterComposeEmailClicked = (tabsCount: number) => {
    this.analytics.trackTargeterComposeEmailClicked(tabsCount);
  };

  public trackTargeterPreviewEmailClicked = (
    mid: string,
    missionTitle: string,
    missionStatus: string,
    emailCategory: string,
    emailType: string,
    roleIds: string,
  ) => {
    this.analytics.trackTargeterPreviewEmailClicked(
      mid,
      missionTitle,
      missionStatus,
      emailCategory,
      emailType,
      roleIds,
    );
  };

  public trackTargeterSendEmailClicked = (
    mid: string,
    missionTitle: string,
    missionStatus: string,
    emailCategory: string,
    emailType: string,
    roleIds: string,
  ) => {
    this.analytics.trackTargeterSendEmailClicked(
      mid,
      missionTitle,
      missionStatus,
      emailCategory,
      emailType,
      roleIds,
    );
  };

  public trackTargeterBuilderCardClicked = (
    cardUserId: UserId,
    cardUsername: string,
    rankingPosition: number,
    totalResults: number,
    url: string,
  ) => {
    this.analytics.trackTargeterBuilderCardClicked(
      cardUserId,
      cardUsername,
      rankingPosition,
      totalResults,
      url,
    );
  };

  public trackTargeterResultPageChanged = (
    originalPage: number,
    targetPage: number,
  ) => {
    this.analytics.trackTargeterResultPageChanged(originalPage, targetPage);
  };
}
