import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import RegistrationStep from '@src/views/Registration/RegistrationStep';
import useLoadingState from '@src/hooks/useLoadingState';
import icon from './success.svg';
import SetPasswordModal from '@src/components/Modal/SetPasswordModal';
import useToggle from '@src/hooks/useToggle';
import { useStores } from '@src/stores';
import { TalkSoonLocation } from '@src/locations';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { useScreenClass } from 'react-grid-system';
import { Button, ButtonContainer } from '@ateams/components';

const Success = (): ReactElement => {
  const {
    registration: {
      formData,
      flow,
      currentStep,
      currentStepIndex,
      submitPasswordModal,
      setAcceptedTos,
    },
    auth: { currentUser, registeredUser },
  } = useStores();
  const screenClass = useScreenClass();
  const analytics = useAnalytics();
  const isMobile = screenClass === 'xs' || screenClass === 'sm';
  const [loading, setLoading] = useLoadingState();
  const [modalOpen, toggleModalOpen] = useToggle();

  const onModalSubmit = (password: string) => {
    analytics.trackRegistrationComplete(
      `${currentStepIndex}-${currentStep.id}`,
      flow,
      formData,
    );
    setLoading(
      submitPasswordModal(password).then(() => {
        if (!registeredUser?.wasScrubbed && window?.location) {
          window.location.href = `${TalkSoonLocation}${
            currentUser?.username ? `?username=${currentUser.username}` : ''
          }`;
        }
      }),
    );
  };

  return (
    <RegistrationStep
      title={"We've Got All That!"}
      description={
        'Thanks for taking the time to tell us a bit about yourself. We appreciate it. A member from our Community.Team will look everything over and get back to you soon.'
      }
      withMobileVideo={currentStep.withMobileVideo}
      buttonLabel={'Set My Password'}
      onSubmit={toggleModalOpen}
      icon={
        <img
          src={icon}
          style={{ marginBottom: 28, ...(isMobile && { width: 94 }) }}
          alt={'success'}
        />
      }
      withButton={!isMobile}
      loading={loading}
    >
      <SetPasswordModal
        open={registeredUser?.wasScrubbed ? true : modalOpen}
        onClose={toggleModalOpen}
        email={formData.email || ''}
        onSubmit={onModalSubmit}
        onTosChange={currentUser?.needsAcceptTOS ? setAcceptedTos : undefined}
      />
      {isMobile ? (
        <ButtonContainer
          align={'center'}
          style={{ marginTop: 20, marginBottom: 30 }}
        >
          <Button
            style={{ fontSize: 12 }}
            width={'auto'}
            size={'small'}
            onClick={toggleModalOpen}
          >
            Set My Password
          </Button>
        </ButtonContainer>
      ) : null}
    </RegistrationStep>
  );
};

export default observer(Success);
