import React, { ReactNode } from 'react';
import cx from 'classnames';
import { useCommonStyles } from '../../commonStyles';

interface StepProps {
  title: string;
  copy: string;
  link: string;
  linkTitle: string;
  img: ReactNode;
}

export const Step = (props: StepProps) => {
  const styles = useCommonStyles();

  return (
    <>
      <div className={cx(styles.panel, styles.noPadding)}>
        <div className={styles.stepWrap}>
          <div className={styles.stepCopy}>
            <h5 className={styles.panelTitle}>{props.title}</h5>
            <p className={styles.panelCopy}>{props.copy}</p>
            <a
              href={props.link}
              className={styles.linkButton}
              rel="noreferrer nopener"
              target="_blank"
            >
              {props.linkTitle}
            </a>
          </div>
          {props.img}
        </div>
      </div>
    </>
  );
};
