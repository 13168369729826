import React, { useCallback, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import uniqueId from 'lodash/uniqueId';
import { theme } from '@a_team/ui-components';
import { SelectOption, Spacing } from '@ateams/components';
import { Card } from '../components/card';
import { TextInput } from '../components/text-input';
import { InputLabel, Text } from '../components/typography';
import { VettingFeedbackFormDefaults } from '@ateams/api/dist/endpoints/vetting-process-feedback';
import { TalentSkillSelector } from '../components/select';
import {
  TalentSkill,
  TalentSkillId,
} from '@a_team/models/dist/TalentCategories';
import { NumberValueTag } from '../components/value-tag';
import { XMarkIcon } from '../components/icons/x-mark';
import { VettingProcessFeedbackTalentSkill } from '@a_team/models/dist/vetting-processes/feedback';
import {
  TalentSkillOptionType,
  talentSkillToOption,
} from '@src/components/TalentSkillSelector';
import { Separator } from '../components/separator';

const useStyles = createUseStyles({
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  marginLeft: {
    marginLeft: Spacing.medium,
  },
  sectionDescription: {
    marginBottom: Spacing.large,
  },
  techStackSkill: {
    '&:last-child': {
      marginBottom: 0,
    },
  },
  skillsRowsTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  skillsRowsTitle: {
    display: 'flex',
    width: '249.33px',
    height: '32px',
    marginRight: Spacing.large,
    '&:last-child': {
      marginRight: 0,
    },
  },
});

export type VettingFeedbackFormTechStack = {
  skills: VettingFeedbackFormDefaults['answers']['skills'];
  skillsComment?: string;
};

export type OnVettingFeedbackFormTechStackChange = (data: {
  skills?: VettingProcessFeedbackTalentSkill[];
  skillsComment?: string;
}) => void;

export interface VettingFeedbackFormTechStackProps {
  defaultValues: VettingFeedbackFormTechStack;
  onChange?: OnVettingFeedbackFormTechStackChange;
  isReadOnly?: boolean;
  className?: string;
}

export const VettingFeedbackFormTechStackForm: React.FC<
  VettingFeedbackFormTechStackProps
> = (props) => {
  const { isReadOnly } = props;
  const styles = useStyles();
  const [skills, setSkills] = useState<VettingProcessFeedbackTalentSkill[]>(
    props.defaultValues.skills || [],
  );
  /**
   * This is calculated only on skill changes to avoid rendering all the children when a field such as the "comment" changes which results in
   * a performance issue with combination of using react-select
   */
  const [selectedSkillsSet, setSelectedSkillsSet] = useState(
    new Set(skills?.map(({ skill }) => skill?.id)),
  );
  const [skillsComment, setSkillsComment] = useState(
    props.defaultValues.skillsComment,
  );
  /**
   * We need a key on the new skill selector because we are mapping skill selectors just above it
   * Without changing the key on each new skill React gets confused between the added skillselector
   * and the skillselector for adding yet another skill
   * {@link https://buildateam.atlassian.net/browse/NEXUS-884}
   */
  const [newSkillSelectorKey, setNewSkillSelectorKey] = useState(() =>
    uniqueId(),
  );

  const onSkillsChange = useCallback(
    (newSkill: VettingProcessFeedbackTalentSkill) => {
      setSkills((skills) => {
        const newSkills = skills.map((skill) =>
          skill.skill === newSkill.skill ? newSkill : skill,
        );

        // This callback is called when the "comment" and "level" changes too and we don't want setState again for the selectedSkillsSet
        const newSelectedSkillsSet = new Set(
          newSkills?.map(({ skill }) => skill?.id),
        );
        if (!isEqual(selectedSkillsSet, newSelectedSkillsSet)) {
          setSelectedSkillsSet(newSelectedSkillsSet);
        }

        props.onChange?.({ skills: newSkills });
        return newSkills;
      });
    },
    [setSkills],
  );

  const onAddSkill = useCallback(
    (skill: TalentSkill) => {
      setSkills((skills) => {
        const newSkills = [...skills, { skill }];
        setSelectedSkillsSet(new Set(newSkills.map(({ skill }) => skill.id)));
        setSkills(newSkills);
        setNewSkillSelectorKey(uniqueId());
        props.onChange?.({ skills: newSkills });
        return skills;
      });
    },
    [setSkills, setSelectedSkillsSet, setNewSkillSelectorKey, props.onChange],
  );

  const onRemoveSkill = useCallback(
    (skillToRemove: VettingProcessFeedbackTalentSkill) => {
      setSkills((skills) => {
        const newSkills = skills.filter(
          (skill) => skill.skill !== skillToRemove.skill,
        );

        setSelectedSkillsSet(new Set(newSkills.map(({ skill }) => skill.id)));
        props.onChange?.({ skills: newSkills });
        return newSkills;
      });
    },
    [setSkills],
  );

  const onSkillsCommentChange = (skillsComment: string) => {
    setSkillsComment(skillsComment);
    props.onChange?.({ skillsComment });
  };

  const skillsToDisplay = useMemo(() => {
    if (!props.isReadOnly) {
      return skills;
    }

    return skills.filter(
      ({ level, comment }) => typeof level === 'number' || comment,
    );
  }, [props.isReadOnly, skills]);

  return (
    <Card title={'Skills'} className={props.className}>
      {!props.isReadOnly && (
        <Text className={styles.sectionDescription}>
          It's important for us to understand the builder's experience with
          different tech stacks so we can consider them for the right roles.
          Include all stacks they have worked with, then rate their skill level
          based on answers to your technical questions.
        </Text>
      )}

      <div className={cx(styles.marginBottom, styles.techStackSkill)}>
        {isReadOnly && !skills.length ? (
          <Text isReadOnly readOnlyText={'No skills have been selected.'} />
        ) : (
          <>
            <div className={styles.skillsRowsTitleContainer}>
              <Text className={styles.skillsRowsTitle}>Skill</Text>
              <Text className={styles.skillsRowsTitle}>Level</Text>
              <Text className={styles.skillsRowsTitle}>Comments</Text>
            </div>

            {skillsToDisplay.map((skill) => (
              <TechStackSkillRow
                key={skill?.skill?.id}
                className={styles.marginBottom}
                techStackSkill={skill}
                selectedSkillsSet={selectedSkillsSet}
                onTechStackSkillChange={onSkillsChange}
                onRemoveSkill={onRemoveSkill}
                isReadOnly={isReadOnly}
              />
            ))}
          </>
        )}

        {!isReadOnly && (
          <NewTechStackSkillRow
            selectedSkillsSet={selectedSkillsSet}
            onAddSkill={onAddSkill}
            className={styles.techStackSkill}
            key={`new-skill-selector-${newSkillSelectorKey}`}
          />
        )}
      </div>

      <Separator direction={'horizontal'} space={Spacing.medium} />

      <InputLabel className={styles.marginBottom}>Comments</InputLabel>
      <div className={styles.marginLeft}>
        <TextInput
          value={skillsComment}
          onChange={(e) => onSkillsCommentChange(e.target.value)}
          placeholder={'Add other skills...'}
          isReadOnly={isReadOnly}
        />
      </div>
    </Card>
  );
};

interface TechStackSkillRowProps {
  techStackSkill: VettingProcessFeedbackTalentSkill;
  selectedSkillsSet: Set<TalentSkillId>;
  onTechStackSkillChange?: (
    techStackSkill: TechStackSkillRowProps['techStackSkill'],
  ) => void;
  onRemoveSkill?: (techStackSkill: VettingProcessFeedbackTalentSkill) => void;
  isReadOnly?: boolean;
  className?: string;
}

const useTechStackSkillStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cell: {
    alignItems: 'center',
    minHeight: '32px',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginRight: Spacing.large,
  },
  level: {
    marginRight: Spacing.small,
    '&:last-child': {
      marginRight: 0,
    },
  },
  removeSkillContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '32px',
    width: '32px',
    cursor: 'pointer',
  },
  removeSkill: {
    color: theme.colors.Grey[500],
  },
  addSkillRightSpace: {
    width: '32px',
  },
});

function getLevelColor(level: number) {
  if (level <= 2) {
    return theme.colors.Red[200];
  }
  if (level <= 3) {
    return theme.colors.Baracus[200];
  }

  return theme.colors.Green[200];
}

const MAX_LEVELS = 5;

const TechStackSkillRow: React.FC<TechStackSkillRowProps> = React.memo(
  (props) => {
    const { isReadOnly } = props;
    const styles = useTechStackSkillStyles();

    const onSkillChange = (option: TalentSkillOptionType) => {
      props.onTechStackSkillChange?.({
        ...props.techStackSkill,
        skill: option.talentSkill,
      });
    };

    const onLevelChange = (level: number) => {
      props.onTechStackSkillChange?.({
        ...props.techStackSkill,
        level: props.techStackSkill.level === level ? undefined : level,
      });
    };

    const onCommentChange = (comment: string) => {
      props.onTechStackSkillChange?.({
        ...props.techStackSkill,
        comment,
      });
    };

    const talentSkillSelectorDefaultValue = useMemo(
      () => talentSkillToOption(props.techStackSkill.skill),
      [],
    );

    return (
      <div className={cx(styles.container, props.className)}>
        <div className={styles.cell}>
          <TalentSkillSelector
            omitSkillsWithoutParentCategory
            defaultValue={talentSkillSelectorDefaultValue}
            onChange={onSkillChange}
            isOptionDisabled={(option: SelectOption) =>
              props.selectedSkillsSet.has(option.value)
            }
            isClearable={false}
            isReadOnly={isReadOnly}
            menuPortalTarget={document.body}
          />
        </div>

        <div className={styles.cell}>
          {new Array(MAX_LEVELS).fill(null).map((_, i) => {
            const level = i + 1;
            const color =
              props.techStackSkill.level === level
                ? getLevelColor(level)
                : undefined;

            if (isReadOnly && !color) {
              return null;
            }

            return (
              <NumberValueTag
                className={styles.level}
                key={level}
                color={color}
                onClick={isReadOnly ? undefined : () => onLevelChange(level)}
                isReadOnly={isReadOnly}
              >
                {level}
              </NumberValueTag>
            );
          })}
        </div>

        <div className={styles.cell}>
          <TextInput
            placeholder={'Add a comment...'}
            value={props.techStackSkill.comment}
            onChange={(e) => onCommentChange(e.target.value)}
            isReadOnly={isReadOnly}
            withLine={false}
          />
        </div>

        {!isReadOnly && (
          <div className={styles.removeSkillContainer}>
            <XMarkIcon
              className={styles.removeSkill}
              onClick={() => props.onRemoveSkill?.(props.techStackSkill)}
            />
          </div>
        )}
      </div>
    );
  },
);

interface NewTechStackSkillRowProps {
  selectedSkillsSet: Set<TalentSkillId>;
  onAddSkill: (skill: TalentSkill) => void;
  className?: string;
}

const NewTechStackSkillRow: React.FC<NewTechStackSkillRowProps> = (props) => {
  const styles = useTechStackSkillStyles();

  const onAddSkill = useCallback(
    (option: TalentSkillOptionType) => {
      props.onAddSkill(option.talentSkill);
    },
    [props.onAddSkill],
  );

  return (
    <div className={cx(styles.container, props.className)}>
      <div className={styles.cell}>
        <TalentSkillSelector
          omitSkillsWithoutParentCategory
          value={null}
          placeholder={'Add'}
          onChange={onAddSkill}
          isOptionDisabled={(option: SelectOption) =>
            props.selectedSkillsSet.has(option.value)
          }
          isClearable={false}
          menuPortalTarget={document.body}
        />
      </div>

      <div className={styles.cell}>
        {new Array(MAX_LEVELS).fill(null).map((_, i) => {
          const level = i + 1;

          return (
            <NumberValueTag className={styles.level} key={level} disabled>
              {level}
            </NumberValueTag>
          );
        })}
      </div>

      <div className={styles.cell}>
        <TextInput placeholder={'Add a comment...'} disabled withLine={false} />
      </div>

      <div className={styles.addSkillRightSpace} />
    </div>
  );
};
