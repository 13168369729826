import { Breakpoints, Icon, IconType } from '@ateams/components';
import { TalentSkillRating } from '@a_team/models/dist/TalentCategories';
import TooltipWrapped from '@src/components/TooltipWrapped';
import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface TagProps {
  name: string;
  iconType?: IconType;
  rating?: TalentSkillRating;
  tooltipMessage?: string;
  strikethrough?: boolean;
}

export const useStyles = createUseStyles({
  tag: {
    display: 'inline-block',
    background: '#F7F7F7',
    borderRadius: '8px',
    fontSize: '14px',
    padding: '2.5px 10px',
    '& > div': {
      display: 'inline-block',
      marginLeft: '6px',
      fontWeight: 300,
    },
  },
  smallIcon: {
    marginRight: '10px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    tag: {
      fontSize: '15px',
      padding: '4px 10px',
    },
  },
  strikethrough: {
    textDecorationLine: 'line-through',
    color: '#818388',
  },
});

function Tag({
  iconType,
  name,
  rating,
  tooltipMessage,
  strikethrough,
}: TagProps): JSX.Element {
  const styles = useStyles();

  const getTag = (name: string, rating: TalentSkillRating | undefined) => {
    return (
      <div
        className={cx(styles.tag, strikethrough ? styles.strikethrough : null)}
      >
        {iconType && (
          <Icon type={iconType} size={'xsmall'} className={styles.smallIcon} />
        )}
        {name}
      </div>
    );
  };

  if (tooltipMessage) {
    return (
      <TooltipWrapped title={tooltipMessage}>
        {getTag(name, rating)}
      </TooltipWrapped>
    );
  } else {
    return getTag(name, rating);
  }
}

export default Tag;
