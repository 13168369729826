import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  Colors,
  FontSizes,
  FontWeights,
  Icon,
  IconType,
  Spacing,
  Tag,
} from '@ateams/components';
import TextButton from '@src/components/TextButton';
import cx from 'classnames';
import Table, { TableRow } from '@src/components/Table';
import Modal from '@src/components/Modal';
import {
  useGetReachoutAutomationCampaignsForRole,
  useMutationStartCampaignAudience,
  useMutationStopCampaignAudience,
} from '@src/rq/emailFromTargeter';
import {
  AutomationCampaignAudienceResponseDtoStatusEnum,
  AutomationCampaignAudienceRunLogResponseDtoOutcomeEnum,
} from '@a_team/user-notification-service-js-sdk';
import { formatDateWithTime } from '@src/helpers/time';

const LOW_BUILDER_COUNT_THRESHOLD = 10;

const useStyles = createUseStyles({
  root: {
    marginBottom: '24px',
    width: '100%',
    borderTopColor: Colors.regular,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
  },
  title: {},
  collapsedBanner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  close: {
    marginTop: Spacing.medium,
  },
  closeIcon: {
    display: 'inline-block',
    marginLeft: Spacing.xsmall,
  },
  closeIconUp: {
    transform: 'rotate(180deg)',
  },
  standardPadding: {
    padding: `${Spacing.large}px ${Spacing.large}px ${Spacing.xsmall}px ${Spacing.large}px`,
  },
  tableTitle: {
    marginBottom: Spacing.medium,
  },
  actions: {
    display: 'flex',
    gap: Spacing.medium,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noRunLogs: {
    padding: Spacing.large,
    textAlign: 'center',
  },
  lowBuilderCount: {
    fontSize: FontSizes.small,
    color: Colors.danger,
    fontWeight: FontWeights.bold,
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.small,
    flexDirection: 'column',
  },
});

interface RoleCampaignsProps {
  roleId: string;
}

export const RoleCampaigns: React.FC<RoleCampaignsProps> = ({ roleId }) => {
  const styles = useStyles();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [modalOpenForAudience, setModalOpenForAudience] = useState<
    string | null
  >(null);
  const { data, refetch } = useGetReachoutAutomationCampaignsForRole({
    roleId,
  });
  const { mutate: startCampaignAudience } = useMutationStartCampaignAudience({
    onSuccess: () => {
      refetch();
    },
  });
  const { mutate: stopCampaignAudience } = useMutationStopCampaignAudience({
    onSuccess: () => {
      refetch();
    },
  });
  const audiences = useMemo(
    () =>
      data?.audiences.sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }),
    [data],
  );

  const numStartedAudiences = audiences?.filter(
    (audience) =>
      audience.status ===
      AutomationCampaignAudienceResponseDtoStatusEnum.Started,
  ).length;

  const selectedSortedAudienceRunLogs = useMemo(() => {
    return (
      data?.audiences.find((audience) => audience.id === modalOpenForAudience)
        ?.runLog || []
    ).sort((a, b) => {
      return new Date(b.runAt).getTime() - new Date(a.runAt).getTime();
    });
  }, [data?.audiences, modalOpenForAudience]);

  const lowBuilderCountAudiences = useMemo(() => {
    return new Set(
      audiences
        ?.filter(
          (audience) =>
            (audience.runLog.length &&
              audience.runLog
                .filter(
                  (runLog) =>
                    runLog.outcome ===
                    AutomationCampaignAudienceRunLogResponseDtoOutcomeEnum.Success,
                )
                .sort(
                  (a, b) =>
                    new Date(b.runAt).getTime() - new Date(a.runAt).getTime(),
                )[0]?.count) ??
            0 < LOW_BUILDER_COUNT_THRESHOLD,
        )
        .map((audience) => audience.id),
    );
  }, [audiences]);

  if (!data?.audiences?.length || !audiences?.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      {isCollapsed ? (
        <div className={cx(styles.collapsedBanner, styles.standardPadding)}>
          <TextButton onClick={() => setIsCollapsed(false)} color="dark">
            {audiences.length} Reachout automation campaign audiences
            <div className={cx(styles.closeIcon)}>
              <Icon type={IconType.Chevron} size="xsmall" />
            </div>
          </TextButton>
          <div>
            <Tag color={numStartedAudiences ? 'success' : 'regular'}>
              {numStartedAudiences
                ? `${numStartedAudiences} Started`
                : 'No campaign audiences started'}
            </Tag>
          </div>
        </div>
      ) : (
        <div className={styles.title}>
          <div className={cx(styles.standardPadding, styles.tableTitle)}>
            <strong>Reachout automation campaign audiences</strong>
          </div>
          <Table
            noShadow
            noMargin
            headers={[
              'Status',
              'Created at',
              'Frequency',
              'Last run',
              'Builders emailed',
              'Actions',
            ]}
          >
            {audiences.map((audience) => (
              <TableRow
                cells={[
                  <div className={styles.status}>
                    <Tag
                      color={
                        audience.status ===
                        AutomationCampaignAudienceResponseDtoStatusEnum.Started
                          ? 'success'
                          : 'regular'
                      }
                    >
                      {audience.status}
                    </Tag>
                    {audience.status ===
                      AutomationCampaignAudienceResponseDtoStatusEnum.Started &&
                    lowBuilderCountAudiences.has(audience.id) ? (
                      <span className={styles.lowBuilderCount}>
                        <Icon size="exact" type={IconType.Low} /> Low count
                      </span>
                    ) : null}
                  </div>,
                  formatDateWithTime(audience.createdAt),
                  `${audience.timeBetweenRuns} h`,
                  audience.lastRunAt
                    ? formatDateWithTime(audience.lastRunAt)
                    : '-',
                  audience.totalCount,
                  <div className={styles.actions}>
                    <TextButton
                      onClick={() => setModalOpenForAudience(audience.id)}
                    >
                      <Icon type={IconType.Eye} />
                    </TextButton>
                    {audience.status ===
                    AutomationCampaignAudienceResponseDtoStatusEnum.Started ? (
                      <TextButton
                        onClick={() =>
                          stopCampaignAudience({ audienceId: audience.id })
                        }
                      >
                        <Icon type={IconType.Pause} />
                      </TextButton>
                    ) : (
                      <TextButton
                        onClick={() =>
                          startCampaignAudience({ audienceId: audience.id })
                        }
                      >
                        <Icon type={IconType.Play} />
                      </TextButton>
                    )}
                  </div>,
                ]}
              />
            ))}
          </Table>
        </div>
      )}
      {!isCollapsed && (
        <>
          <div className={cx(styles.close, styles.standardPadding)}>
            <TextButton onClick={() => setIsCollapsed(true)}>
              Close{' '}
              <div className={cx(styles.closeIcon, styles.closeIconUp)}>
                <Icon type={IconType.Chevron} size="xsmall" />
              </div>
            </TextButton>
          </div>
        </>
      )}
      <Modal
        width={600}
        open={!!modalOpenForAudience}
        onClose={() => setModalOpenForAudience(null)}
      >
        <div>
          {selectedSortedAudienceRunLogs.length ? (
            <Table
              noShadow
              noMargin
              headers={['Time', 'Status', 'Preset', 'Count', 'Link']}
            >
              {selectedSortedAudienceRunLogs.map((runLog) => (
                <TableRow
                  cells={[
                    formatDateWithTime(runLog.runAt),
                    runLog.outcome,
                    runLog.preset,
                    runLog.count,
                    runLog.missionNotificationLogId ? (
                      <a
                        href={`https://bi.a.team/dashboard/118-mission-notification-logs-dashboard?roleid=${roleId}&notificationid=${runLog.missionNotificationLogId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Icon type={IconType.Link} />
                      </a>
                    ) : null,
                  ]}
                />
              ))}
            </Table>
          ) : (
            <div className={styles.noRunLogs}>
              This audience has not been run yet
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
