import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import AdminInvoicesDashboardLayout from '@src/layouts/InvoicesDashboard';
import { useStores } from '@src/stores';
import { ServerSideLoader, ViewProps } from '@src/routes';
import TitledSection from '@src/components/TitledSection';
import { DiscountStatus } from '@a_team/models/dist/Discount';
import { Button, ButtonContainer, Tag } from '@ateams/components';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import {
  AdminInvoicesDiscountsLocation,
  MissionAboutLocation,
  ProfileLocation,
} from '@src/locations';
import Loader from '@src/components/Loader';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { numberWithCommas } from '@src/helpers/numbers';
import Table, { TableRow } from '@src/components/Table';
import { InvoiceStatus } from '@a_team/models/dist/InvoiceObject';

interface ViewParams {
  id: string;
}

export const adminInvoicesDiscountsDiscountLoader: ServerSideLoader<
  ViewParams
> = async (stores, match, location) => {
  const { discounts } = stores;
  const { id } = match.params;

  await discounts.loadDiscount(id);
};

const AdminInvoicesDiscountsDiscountView = ({
  match,
  history,
}: ViewProps<ViewParams>) => {
  const { discounts } = useStores();
  const [loading, setLoading] = useLoadingState();
  const [terminate, setTerminate] = useState(false);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (!terminate) return;

    setNotes('');
  }, [terminate]);

  const discount = discounts.getDiscount(match.params.id);

  const handleTerminate = () => {
    if (!discount || !notes.trim()) return;

    setTerminate(false);

    setLoading(
      discounts
        .terminateDiscount(discount.did, {
          notes,
        })
        .then(() => history.push(AdminInvoicesDiscountsLocation)),
    );
  };

  return (
    <AdminInvoicesDashboardLayout title="Discount">
      <h1>Discount details</h1>

      <ConfirmModal
        open={terminate}
        title="Terminate discount"
        description="Are you sure you want to terminate this discount?"
        onConfirm={handleTerminate}
        onClose={() => setTerminate(false)}
        actionDisabled={!notes || !notes.trim()}
      >
        <OutlinedInput
          type="text"
          placeholder="Provide a reason for terminating this discount"
          required
          value={notes}
          error={!!notes && !notes.trim()}
          onChange={(e) => setNotes(e.target.value)}
        />
      </ConfirmModal>

      {discount ? (
        <>
          <div>
            <strong>{discount.summary}</strong>
            <span style={{ marginLeft: '2em' }}>
              {DiscountStatusMap[discount.status]}
            </span>
            <span style={{ marginLeft: '0.5em' }}>
              <strong>
                {Math.round((100 * discount.usedCredit) / discount.totalCredit)}
                %
              </strong>{' '}
              <span style={{ color: '#999' }}>
                (${numberWithCommas(discount.usedCredit)} of $
                {numberWithCommas(discount.totalCredit)})
              </span>
            </span>
          </div>

          <div style={{ display: 'flex' }}>
            <div>
              <TitledSection title="Account">
                {discount.account.name}
              </TitledSection>
            </div>

            <div style={{ marginLeft: 48 }}>
              <TitledSection title="Affected missions">
                {discount.missions.length
                  ? discount.missions.map((m, i) => (
                      <Fragment key={m.mid}>
                        {i > 0 && (
                          <>
                            ,<br />
                          </>
                        )}
                        <a
                          href={MissionAboutLocation(m.mid)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {m.title}
                        </a>
                      </Fragment>
                    ))
                  : '-'}
              </TitledSection>
            </div>
          </div>

          <TitledSection title="Invoices">
            <Table
              headers={[
                'Invoice #',
                'Status',
                'Total amount',
                'Discount amount',
                'Notes',
              ]}
              noDataText="No invoices found"
            >
              {discount.invoices.length > 0 &&
                discount.invoices.map(({ invoice, appliedCredit }) => (
                  <TableRow
                    key={invoice.iid}
                    cells={[
                      invoice.downloadURL ? (
                        <a
                          href={invoice.downloadURL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {invoice.referenceNumber}
                        </a>
                      ) : (
                        invoice.referenceNumber
                      ),
                      <Tag>{invoice.status}</Tag>,
                      `$${numberWithCommas(invoice.totalAmount)}`,
                      <>
                        $
                        {numberWithCommas(
                          invoice.status === InvoiceStatus.Canceled
                            ? 0
                            : invoice.discountAmount ?? 0,
                        )}
                        {invoice.status === InvoiceStatus.Canceled ||
                          (invoice.discountAmount !== appliedCredit && (
                            <>
                              {' '}
                              <span
                                style={{
                                  color: '#999',
                                }}
                              >
                                (${numberWithCommas(appliedCredit)} applied)
                              </span>
                            </>
                          ))}
                      </>,
                      invoice.discountReason ?? '-',
                    ]}
                  />
                ))}
            </Table>
          </TitledSection>

          <TitledSection title="History">
            <Table headers={['Date', 'Change', 'User', 'Notes']}>
              {discount.changes.length > 0 &&
                discount.changes.map((change) => (
                  <TableRow
                    key={change.id}
                    cells={[
                      new Date(change.appliedAt).toLocaleString(),
                      <Tag>{change.action}</Tag>,
                      <a
                        href={ProfileLocation(change.user.username)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {change.user.fullName}
                      </a>,
                      change.notes,
                    ]}
                  />
                ))}
            </Table>
          </TitledSection>
        </>
      ) : (
        <Loader />
      )}

      <ButtonContainer style={{ justifyContent: 'space-between' }}>
        <Button
          type="button"
          color="tag"
          size="small"
          onClick={() =>
            history.length
              ? history.goBack()
              : history.push(AdminInvoicesDiscountsLocation)
          }
        >
          Back
        </Button>

        {discount?.status === DiscountStatus.ACTIVE && (
          <Button
            type="button"
            color="danger"
            size="small"
            loading={loading === true}
            onClick={() => setTerminate(true)}
          >
            Terminate
          </Button>
        )}
        <LoadingIndicator loading={loading} />
      </ButtonContainer>
    </AdminInvoicesDashboardLayout>
  );
};

export default observer(AdminInvoicesDiscountsDiscountView);

export const DiscountStatusMap: Record<DiscountStatus, ReactNode> = {
  [DiscountStatus.ACTIVE]: <Tag color="info">Active</Tag>,
  [DiscountStatus.CANCELED]: <Tag color="tag">Canceled</Tag>,
  [DiscountStatus.TERMINATED]: <Tag color="tag">Terminated</Tag>,
  [DiscountStatus.COMPLETED]: <Tag color="success">Completed</Tag>,
};
