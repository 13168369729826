import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { TextColors } from '@ateams/components';
import TextButton from '@src/components/TextButton';
import DropdownInput from '@src/components/Inputs/DropdownInput';
import { ScoreOption } from './Scores';
import { AdminNotesScore } from '@a_team/models/dist/AdminNotesObject';

interface Props {
  title: string;
  scores: ScoreOption[];
  value?: number;
  onChange: (score: AdminNotesScore | null) => void;
  placeholder?: string;
  inputClassName?: string;
}

const useStyles = createUseStyles({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  clearButton: {
    color: TextColors.primaryLight,
  },
  label: {
    fontSize: 14,
    lineHeight: '2.6em',
    color: '#62646A',
  },
});

export const ScoreInput = (props: Props): ReactElement => {
  const styles = useStyles();
  const { title, onChange, placeholder, value, scores } = props;

  return (
    <div style={{ marginTop: 12 }}>
      <div className={styles.titleContainer}>
        <span className={styles.label}>{title}</span>
        <TextButton
          className={styles.clearButton}
          onClick={() => onChange(null)}
        >
          clear
        </TextButton>
      </div>
      <DropdownInput
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value) as AdminNotesScore)}
        placeholder={placeholder || 'Select a Rating'}
        margin="none"
        style={value ? {} : { color: '#222' }}
        className={props.inputClassName}
      >
        {scores.map((score) => (
          <option key={score.label} value={score.value}>
            {score.label}
          </option>
        ))}
      </DropdownInput>
    </div>
  );
};
