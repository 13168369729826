import { MissionRoleId } from '@a_team/models/dist/MissionRole';
import { RecommendationObject } from '@a_team/models/dist/RecommendationObject';

export const isApplyingToTheSameRole = (
  recommendation: RecommendationObject,
  currentRoleId: MissionRoleId,
): boolean => {
  if (recommendation.requestorAppliedRoles.length > 1) {
    return false;
  }

  return recommendation.requestorAppliedRoles.some(
    (role) => role.rid === currentRoleId,
  );
};
