import { Icon, IconType } from '@ateams/components';
import cx from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import TextButton from '../TextButton';

interface SelectUnselectProps {
  isSelected: boolean;
}

const useStyles = createUseStyles({
  button: {
    color: '#7000E3',
  },
});

const SelectedStatus = ({ isSelected }: SelectUnselectProps): JSX.Element => {
  const styles = useStyles();

  return (
    <>
      {isSelected ? (
        <TextButton className={cx(styles.button)}>
          <Icon type={IconType.CheckPurple} size={'exact'} />
        </TextButton>
      ) : (
        <TextButton className={cx(styles.button)}>Select</TextButton>
      )}
    </>
  );
};

export default SelectedStatus;
