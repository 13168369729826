import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

const useStyles = createUseStyles({
  container: {
    border: '1px solid #ddd',
    borderRadius: 7,
    margin: 0,
    padding: 0,
  },
});

interface Props {
  className?: string;
}

const List: React.FC<Props> = ({ children, className }) => {
  const styles = useStyles();
  return <ul className={cx(styles.container, className)}>{children}</ul>;
};

export default List;
