import React, { ReactNode } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    margin: 0,
    padding: 0,
    border: '1px solid #ddd',
    borderRadius: 5,
    display: 'flex',
    overflow: 'hidden',
  },
  item: {
    margin: 0,
    padding: '.5em 1.2em',
    listStyleType: 'none',
    borderRadius: 4,
    cursor: 'pointer',
  },
  active: {
    background: '#eee',
  },
});

export interface TabItem {
  id: string;
  content: ReactNode;
}

export interface TabsProps {
  items: TabItem[];
  selectedItem: TabItem;
  onClickOnItem: (item: TabItem) => void;
}

export const Tabs: React.FC<TabsProps> = ({
  items,
  selectedItem,
  onClickOnItem,
}) => {
  const styles = useStyles();
  return (
    <ul className={styles.container}>
      {(items || []).map((item) => (
        <li
          key={item.id}
          onClick={() => onClickOnItem(item)}
          className={cx(
            styles.item,
            ...(item.id === selectedItem.id ? [styles.active] : []),
          )}
        >
          {item.content}
        </li>
      ))}
    </ul>
  );
};
