import { createQueryKeys } from '@lukemorales/query-key-factory';

export const connectionKeys = createQueryKeys('connections', {
  byUserId: (userId: string) => ({
    userId,
  }),
  // get connection between two users
  betweenUsers: (uid: string) => ({
    uid,
  }),
  sharedExperiences: (connectionId: string) => ({ connectionId }),
  sharedExperiencesWithDetails: (connectionId: string) => ({ connectionId }),
  mutualConnections: (uid: string) => ({ uid }),
});
