import React, { useMemo } from 'react';
import { VettingType } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { VettingProcessEmailTemplates } from '@a_team/models/dist/vetting-processes/emails';
import {
  Select,
  SelectProps,
} from '@src/views/VettingFeedbackForm/components/select';
import { EmailTemplateToLabelMap, getEmailTemplatesOptions } from './options';
import { EmailTemplateSelectorOption } from './select-option';

export interface EmailTemplateSelectorProps extends SelectProps {
  evaluationType?: VettingType;
  value?: EmailTemplateOptionType | null;
  onChange?: OnEmailTemplateSelect;
  className?: string;
}

export type OnEmailTemplateSelect = (
  template: EmailTemplateOptionType | null,
) => void;

export interface EmailTemplateOptionType {
  value: string;
  label: string;
  template: VettingProcessEmailTemplates;
}

export function emailTemplateToOption(
  template: VettingProcessEmailTemplates,
): EmailTemplateOptionType {
  return {
    value: template,
    label: EmailTemplateToLabelMap[template],
    template,
  };
}

export const EmailTemplateSelector: React.FC<EmailTemplateSelectorProps> = (
  props,
) => {
  const { evaluationType, ...rest } = props;

  const options = useMemo(
    () => getEmailTemplatesOptions(evaluationType),
    [evaluationType],
  );

  const components = useMemo(() => {
    return {
      Option: EmailTemplateSelectorOption,
    };
  }, []);

  return (
    <Select
      placeholder={'Select a template'}
      components={components}
      options={options}
      {...rest}
    />
  );
};
