import React from 'react';

const FinalNotes = (): JSX.Element => {
  return (
    <div>
      <h4>✍️ Final Notes</h4>

      <p>
        😊 Get a walkthrough of the whole A.Guide, and specifically our FAQ page
        - we've gathered the answers to all of your most common questions there!
      </p>

      <p>
        📞 If there is something else on your mind you'd like to talk about; any
        questions, comments, issues, or feedback, don't hesitate to contact our
        onboarding Manager, Romiel at romiel@a.team or schedule a 1 on 1.
      </p>

      <div>
        <p style={{ color: '#FE630C' }}>🧑🏻‍🤝‍🧑🏿 Friends</p>
        <p>
          If you have friends or colleagues you'd love to work on a mission
          with, refer them using that huge INVITE button to your left (and earn
          BuildTokens for doing this)!{' '}
        </p>
      </div>

      <p>
        <strong>
          Aside from that... Welcome to A.Team!
          <br />
          <br />
          See you around! 👋
        </strong>
      </p>
    </div>
  );
};

export default FinalNotes;
