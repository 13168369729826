import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { noop } from 'lodash';
import { useEditor } from '@src/hooks/useEditor';
import TextEditor from '@src/components/TextEditor';

interface DescriptionProps {
  description: string;
}

const useStyles = createUseStyles({
  container: {
    marginTop: 64,
  },
});

const Description = ({ description }: DescriptionProps): JSX.Element | null => {
  const styles = useStyles();
  const { editor } = useEditor({
    initialValue: description,
    onDescriptionChange: noop,
    readOnly: true,
  });

  useEffect(() => {
    if (editor && description) {
      editor.commands.setContent(description);
    }
  }, [editor, description]);

  if (!editor) {
    return null;
  }

  return (
    <div className={styles.container}>
      <TextEditor readOnly editor={editor} />
    </div>
  );
};

export default Description;
