import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import { getLocalTime } from '@src/helpers/time';
import ConfirmTimesheetModal from '@src/components/Modal/ConfirmTimesheetModal';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { LoadingState, SetLoading } from '@src/hooks/useLoadingState';
import { useMutationSaveTimesheetSummary } from '@src/rq/timesheets';

interface Props {
  isOpen: boolean;
  onCloseSubmission: VoidFunction;
  setLoading: SetLoading;
  loading?: LoadingState;
}

const Submission: React.FC<Props> = ({
  isOpen,
  loading,
  onCloseSubmission,
  setLoading,
}) => {
  const stores = useStores();
  const { missions, auth, uiStore } = stores;
  const analytics = useAnalytics();

  const { currentMission } = missions;

  const { mutateAsync: saveTimesheetSummary } =
    useMutationSaveTimesheetSummary();

  const handleSubmitTimesheetSummary = async () => {
    const originalSummarySuggestion = uiStore.originalTimesheetDescriptionHtml;
    const hasSummarySuggestionChange =
      originalSummarySuggestion !== uiStore.timesheetDescriptionHtml;

    const timesheetId = missions?.currentMission?.currentUserTimesheet?.sid;

    if (timesheetId) {
      await saveTimesheetSummary({
        sid: timesheetId,
        summary: uiStore.timesheetDescription,
        summaryHtml: uiStore.timesheetDescriptionHtml,
        demoLink: uiStore.timesheetVideoLink,
        usedMachineTextForSummary: !hasSummarySuggestionChange,
        gptUsageLogId: uiStore.gptUsageLogIdForTimesheetSummary,
      });
    }
  };

  const closeAndSubmit = async (isInactive = false) => {
    try {
      if (!currentMission) {
        onCloseSubmission();
      }

      const promises = [currentMission?.submitTimesheets()];

      if (isInactive) {
        promises.push(currentMission?.endRole());
      }

      if (auth.withNewTimesheets && !isInactive) {
        promises.push(handleSubmitTimesheetSummary());
      }

      setLoading(
        Promise.all(promises)
          .then(() => {
            if (currentMission?.selectedPaymentCycle?.data?.summary) {
              analytics.trackTimesheetSubmitted(
                currentMission.selectedPaymentCycle?.data?.summary,
              );
            }
            onCloseSubmission();
          })
          .catch((e) => {
            onCloseSubmission();
            throw e;
          }),
      );
    } catch (e) {
      console.error(e);
      onCloseSubmission();
    }
  };

  const onCancel = (): void => {
    onCloseSubmission();
  };

  if (!isOpen) {
    return null;
  }

  if (currentMission?.selectedPaymentCycle) {
    return (
      <ConfirmTimesheetModal
        firstName={auth.currentUser?.firstName}
        totalAmount={
          currentMission.selectedPaymentCycle?.data?.summary?.totalPayments
        }
        isEmptyTimesheet={missions.isEmptyTimesheet}
        startDate={getLocalTime(
          new Date(currentMission.selectedPaymentCycle?.data.startDate),
        )}
        endDate={getLocalTime(
          new Date(currentMission.selectedPaymentCycle?.data.endDate),
        )}
        open
        loading={loading}
        onClose={closeAndSubmit}
        onCancel={onCancel}
      />
    );
  }

  return null;
};

export default observer(Submission);
