import React from 'react';
import { createUseStyles } from 'react-jss';

import badlyEmoji from '../../emojis/badly.svg';
import amazingEmoji from '../../emojis/amazing.svg';
import sosoEmoji from '../../emojis/soso.svg';
import greatEmoji from '../../emojis/great.svg';
import okayEmoji from '../../emojis/okay.svg';

import badlyGrayEmoji from '../../emojis/badly-gray.svg';
import amazingGrayEmoji from '../../emojis/amazing-gray.svg';
import sosoGrayEmoji from '../../emojis/soso-gray.svg';
import greatGrayEmoji from '../../emojis/great-gray.svg';
import okayGrayEmoji from '../../emojis/okay-gray.svg';
import OnlyBuilderCheckbox from './common/OnlyBuilderCheckbox';
import { TeamPulseSectionsEnum } from './TeamPulseModal';
import EmojiIcon from './common/EmojiIcon';
import { Breakpoints } from '@ateams/components';

interface HowsTheMissionIsGoingProps {
  setSection: (section: string) => void;
  setScore: (score: number) => void;
  missionTitle: string;
  isOnlyOneBuilder: boolean;
  onResetState: () => void;
  canWeShareFeedbackWithClient?: boolean;
  setCanWeShareFeedbackWithClient: (value: boolean) => void;
  isTeamReview?: boolean;
  withTeamPulseNewQuestions?: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'start',
  },
  desc: {
    textAlign: 'start',
    color: '#707275',
    marginTop: 8,
  },
  iconsContainer: {
    width: '100%',
    marginTop: 40,
    display: 'flex',
    gap: 16,
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    container: {
      padding: 0,
    },
  },
});

const HowsTheMissionIsGoing = ({
  setSection,
  setScore,
  missionTitle,
  isOnlyOneBuilder,
  onResetState,
  canWeShareFeedbackWithClient,
  setCanWeShareFeedbackWithClient,
  isTeamReview,
  withTeamPulseNewQuestions,
}: HowsTheMissionIsGoingProps): JSX.Element => {
  const styles = useStyles();

  const onBadly = () => {
    onResetState();
    setScore(1);
    setSection(TeamPulseSectionsEnum.TellUsMore);
  };

  const onSoso = () => {
    onResetState();
    setScore(2);
    setSection(TeamPulseSectionsEnum.TellUsMore);
  };

  const onOkay = () => {
    onResetState();
    setScore(3);
    setSection(TeamPulseSectionsEnum.ScopedWellDefined);
  };

  const onGreat = () => {
    onResetState();
    setScore(4);

    if (isTeamReview || !withTeamPulseNewQuestions) {
      setSection(TeamPulseSectionsEnum.MissionNeedsMoreBuilders);
    } else {
      setSection(TeamPulseSectionsEnum.ShareHighlights);
    }
  };

  const onAmazing = () => {
    onResetState();

    setScore(5);
    if (isTeamReview || !withTeamPulseNewQuestions) {
      setSection(TeamPulseSectionsEnum.MissionNeedsMoreBuilders);
    } else {
      setSection(TeamPulseSectionsEnum.ShareProudMoments);
    }
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>How’s this mission going for you?</h4>
      {isOnlyOneBuilder ? (
        <div>
          <p className={styles.desc}>
            We usually share builders' average Team Pulse scores with the
            company. As you're the only builder on this mission, this average
            will be based solely on your Team Pulse score.
            <br />
            <br />
            You're welcome to opt out of sharing your score with {
              missionTitle
            }{' '}
            — in this case, only A.Team will see it.
          </p>
          <OnlyBuilderCheckbox
            canWeShareFeedbackWithClient={canWeShareFeedbackWithClient}
            setCanWeShareFeedbackWithClient={setCanWeShareFeedbackWithClient}
            missionTitle={missionTitle}
          />
        </div>
      ) : (
        <p className={styles.desc}>
          Your team’s average score is shared only with A.Team and your
          individual score is anonymous.
        </p>
      )}
      <div className={styles.iconsContainer}>
        <EmojiIcon
          title="Badly"
          emoji={badlyEmoji}
          grayEmoji={badlyGrayEmoji}
          onClick={onBadly}
        />
        <EmojiIcon
          onClick={onSoso}
          title="So so"
          emoji={sosoEmoji}
          grayEmoji={sosoGrayEmoji}
        />
        <EmojiIcon
          onClick={onOkay}
          title="Okay"
          emoji={okayEmoji}
          grayEmoji={okayGrayEmoji}
        />
        <EmojiIcon
          onClick={onGreat}
          title="Great"
          emoji={greatEmoji}
          grayEmoji={greatGrayEmoji}
        />
        <EmojiIcon
          data-testing-id="rate-mission-amazing"
          onClick={onAmazing}
          title="Amazing"
          emoji={amazingEmoji}
          grayEmoji={amazingGrayEmoji}
        />
      </div>
    </div>
  );
};

export default HowsTheMissionIsGoing;
