import React, { ReactElement } from 'react';
import Modal, { ModalBasicProps } from '..';
import { useScreenClass } from 'react-grid-system';

interface Props extends ModalBasicProps {
  iframeUrl: string;
}

export default function PaymentDetailsModal(props: Props): ReactElement {
  const { iframeUrl, onClose, ...left } = props;
  const screenClass = useScreenClass();
  return (
    <Modal
      onClose={onClose}
      hideCloseButton={false}
      style={{
        width: screenClass === 'xs' ? '90%' : '816px',
        height: '90%',
        padding: 0,
        backgroundColor: '#f7f7f7',
        border: 'none',
        overflow: 'hidden',
      }}
      {...left}
    >
      <iframe
        src={iframeUrl}
        title="Payment Details"
        style={{ width: '100%', height: '100%' }}
      />
    </Modal>
  );
}
