import { Icon, IconType } from '@ateams/components';
import TooltipWrapped from '@src/components/TooltipWrapped';
import React from 'react';
import { createUseStyles } from 'react-jss';
import ReferPeer from '../ReferPeer';
import RequestToJoin from '../RequestToJoin';
import { Breakpoints } from '@ateams/components';
import MissionRole, {
  MissionAdminRole,
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import { getGMTFromMinutesOffset } from '@ateams/react-utils/dist/helpers/timezone';
import { Link } from 'react-router-dom';
import { ProfileLocation } from '@src/locations';
import Image from '@src/components/Image';

interface HeaderProps {
  logoURL: string | undefined;
  role: MissionRole | MissionAdminRole;
  referralMode?: boolean;
  onApply?(): void;
  onEdit?(): void;
  onInvite?(): void;
  currentUserHasRole?: MissionRole;
  hideMember?: boolean;
  withDraftingStatus?: boolean;
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: 24,
    marginBottom: 24,
  },
  roleInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  logoFrame: {
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F7F7F7',
  },
  anonymousLogo: {
    background: 'linear-gradient(197.15deg, #94FE0C 0%, #54D6FF 88.21%)',
  },

  cta: {
    display: 'flex',
    gap: '8px',
    flexDirection: 'row',
    '& > div': {
      flex: 1,
    },
  },
  roleTitle: {
    fontWeight: 600,
    fontSize: '20px',
  },
  openPositions: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '8px',
  },
  status: {
    fontSize: '14px',
    color: '#62646A',
  },

  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      flexDirection: 'row',
    },
    roleInfo: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    logoFrame: {
      width: '60px',
      height: '60px',
      borderRadius: '60px',
    },
    cta: {
      gap: '16px',
      alignItems: 'center',
      '& > div': {
        flex: 'none',
      },
    },
    status: {
      fontSize: '15px',
    },
  },
});

function Header({
  logoURL,
  referralMode,
  role,
  onApply,
  onEdit,
  onInvite,
  currentUserHasRole,
  hideMember,
  withDraftingStatus,
}: HeaderProps) {
  const styles = useStyles();

  const userProfilePictureURL =
    role?.user?.username != null && !hideMember
      ? role.user.profilePictureURL
      : undefined;

  return (
    <div className={styles.root}>
      <div className={styles.roleInfo}>
        <div className={styles.logoFrame}>
          {role.user ? (
            <Link to={ProfileLocation(role.user.username as string)}>
              {userProfilePictureURL && (
                <Image
                  style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                  src={userProfilePictureURL}
                  alt="logo"
                  sizes="100px"
                />
              )}
            </Link>
          ) : (
            <>
              {logoURL ? (
                <img
                  src={logoURL}
                  alt="logo"
                  style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                />
              ) : (
                <div
                  className={styles.anonymousLogo}
                  style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                />
              )}
            </>
          )}
        </div>

        <div>
          <div className={styles.roleTitle}>{role.category.title}</div>
          {role.user
            ? hideMember
              ? ''
              : role.user.fullName
            : role.status !== MissionRoleStatus.Canceled
            ? 'Open role'
            : 'Canceled role'}

          {role.utcOffsetRange && (
            <>
              <div className={styles.smallDivider} />
              From{' '}
              {role.utcOffsetRange?.from &&
                getGMTFromMinutesOffset(
                  role.utcOffsetRange?.from?.utcOffset,
                )}{' '}
              To{' '}
              {role.utcOffsetRange?.to &&
                getGMTFromMinutesOffset(role.utcOffsetRange?.to?.utcOffset)}
            </>
          )}
          {!role.user && withDraftingStatus && (
            <div className={styles.openPositions}>
              <div>
                <span className={styles.status}>
                  {role.lookingForApplications
                    ? 'Drafting proposals'
                    : 'Reviewing builders'}{' '}
                  <TooltipWrapped
                    title={
                      role.lookingForApplications
                        ? 'We’re actively looking for more builder profiles to share with the company. This is the best time to request to join!'
                        : 'We’ve already shared some profiles with the company. You may still request to join, but the chance of success is lower.'
                    }
                  >
                    <Icon type={IconType.Info} size="small" />
                  </TooltipWrapped>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={styles.cta}>
        {role.user === null && (
          <>
            <RequestToJoin
              role={role}
              referralMode={referralMode}
              onApply={onApply}
              onEdit={onEdit}
              currentUserHasRole={currentUserHasRole}
            />
            {onInvite && <ReferPeer onInvite={onInvite} />}
          </>
        )}
      </div>
    </div>
  );
}

export default Header;
