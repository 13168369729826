import { MissionStatus } from '@a_team/models/dist/MissionObject';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';
import { BasicUserObject, UserStatus } from '@a_team/models/dist/UserObject';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { useStores } from '@src/stores';
import { useEffect, useMemo } from 'react';

export const useMission = () => {
  const {
    auth,
    missions: { currentMission },
    missionControl,
  } = useStores();
  const currentItem = currentMission?.data;
  const analytics = useAnalytics();

  const activeTeamMembers = useMemo(() => {
    return currentItem?.roles.filter((role) => {
      const user = role.user as BasicUserObject;
      return (
        user &&
        user.status !== UserStatus.Deleted &&
        (role.status === MissionRoleStatus.Active ||
          role.status === MissionRoleStatus.ScheduledToEnd)
      );
    });
  }, [currentItem]);

  useEffect(() => {
    if (auth.currentUser?.uid) {
      missionControl.loadNotInterestedMissions();
    }
  }, [auth.currentUser?.uid]);

  const teamArchitect = currentItem?.owner;
  const isRecommended = currentMission?.isRecommended;
  const website = currentItem?.website;
  const isInReview = currentItem?.status === MissionStatus.Created;

  const missionTitle = useMemo(() => {
    if (!currentItem) return '';
    const parts = currentItem.title.split(':');
    return parts.length > 1
      ? parts.slice(1).join(':').trim()
      : currentItem.title;
  }, [currentItem?.title]);

  const logo = currentItem?.logoURL;
  const industry = currentItem?.industries?.[0]?.name;
  const companyName = currentItem?.companyName;
  const isNotInterested = currentMission?.isNotInterested;

  const isAuthUserActiveTeamMember = useMemo(() => {
    if (!currentItem || !auth.user) return false;
    return activeTeamMembers?.some(
      (role) => role.user?.username === auth.user?.username,
    );
  }, [activeTeamMembers, auth.user]);

  const hasAtLeastOneOpenRoleLookingForMoreApplicants = useMemo(
    () =>
      currentItem?.roles.some(
        (role) =>
          role.status === MissionRoleStatus.Open && role.lookingForApplications,
      ) ?? false,
    [currentItem?.roles],
  );

  const toggleHideMission = () => {
    if (currentItem && currentMission) {
      analytics.trackMissionCardNotInterested(
        currentItem,
        !!currentMission.isRecommended,
        !currentMission.isNotInterested,
      );

      if (currentMission?.isNotInterested) {
        missionControl.restoreNotInterested(currentMission.mid);
      } else {
        missionControl.setNotInterested(currentMission.mid);
      }
    }
  };

  return {
    activeTeamMembers,
    teamArchitect,
    isRecommended,
    website,
    missionTitle,
    logo,
    isAuthUserActiveTeamMember,
    mid: currentMission?.mid,
    currentMission,
    isInReview,
    industry,
    companyName,
    isNotInterested,
    hasAtLeastOneOpenRoleLookingForMoreApplicants,
    toggleHideMission,
  };
};
