import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface BadgeProps {
  children: string | React.ReactNode;
  bgColor?: string;
  color?: string;
  className?: string;
}

const useStyles = createUseStyles<{
  bgColor?: string;
  color?: string;
}>({
  container: {
    borderRadius: '8px',
    background: ({ bgColor }) => bgColor || '#FFCCDC',
    color: ({ color }) => color || '#222',
    width: 'fit-content',
    padding: '3px 10px',
    fontSize: '12px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Badge = ({
  children,
  bgColor,
  color,
  className,
}: BadgeProps): JSX.Element => {
  const styles = useStyles({
    bgColor,
    color,
  });

  return <span className={cx(styles.container, className)}>{children}</span>;
};

export default Badge;
