import { TranscriptLine } from '@ateams/api/dist/endpoints/Transcripts';
import {
  Spacing,
  FontSizes,
  FontWeights,
  Colors,
  TextColors,
} from '@ateams/components';
import { VettingDashboardRecordingLocation } from '@src/locations';
import { format } from 'date-fns';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  transcriptLines: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: Spacing.large,
    margin: Spacing.xLarge,
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: Spacing.small,
  },
  speakerGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.small,
  },
  speakerName: {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.semiBold,
    color: Colors.dark,
  },
  speakerTime: {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.semiBold,
    lineHeight: `${Spacing.xLarge}px`,
    color: TextColors.lighter,
  },
  lineText: {
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.small,
    lineHeight: `${Spacing.large}px`,
    color: Colors.dark,
  },
});

interface TranscriptLinesProps {
  recordingUrl: string;
  lines: TranscriptLine[];
}

const TranscriptLines: React.FC<TranscriptLinesProps> = ({
  recordingUrl,
  lines,
}) => {
  const styles = useStyles();

  const getStartFormatted = (start: number) => {
    return format(new Date(start * 1000), 'mm:ss');
  };

  return (
    <div className={styles.transcriptLines}>
      {lines.map((line, index) => (
        <div key={index} className={styles.lineItem}>
          <div className={styles.speakerGroup}>
            <div className={styles.speakerName}>{line.speakerName}</div>
            <a
              className={styles.speakerTime}
              href={`${VettingDashboardRecordingLocation}?recordingUrl=${encodeURIComponent(
                recordingUrl,
              )}&startTime=${encodeURIComponent(line.start.toString())}`}
              target="_blank"
              rel="noreferrer"
            >
              {getStartFormatted(line.start)}
            </a>
          </div>
          <div className={styles.lineText}>{line.text}</div>
        </div>
      ))}
    </div>
  );
};

export default TranscriptLines;
