import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Icon, IconType, Spacing } from '@ateams/components';
import cx from 'classnames';

interface Option<T> {
  label: string;
  value: T;
  selected?: boolean;
}

interface Props<T> {
  label: string;
  options: Option<T>[];
  onChange: (value: T) => void;
  disabled?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  toggleSwitchWithLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 0,
  },
  buttonGroup: {
    display: 'flex',
    '& button': {
      marginRight: Spacing.xsmall,
    },
  },
  button: {
    padding: '4px 8px',
  },
});

const TileRadioButtonGroup = <T extends unknown>(
  props: Props<T>,
): ReactElement => {
  const styles = useStyles();
  const { label, options, onChange, disabled } = props;

  return (
    <p className={cx(styles.toggleSwitchWithLabel, props.className)}>
      {label}
      <div className={styles.buttonGroup}>
        {options.map((option, idx) => {
          return (
            <Button
              key={idx}
              disabled={disabled}
              size="xsmall"
              secondaryDisabledColor={option.selected}
              squared
              color={option.selected ? 'secondaryDark' : 'transparent'}
              onClick={() => onChange(option.value)}
              width="auto"
              className={styles.button}
            >
              {option.selected && (
                <Icon size="exact" type={IconType.CheckWhite} />
              )}{' '}
              {option.label}
            </Button>
          );
        })}
      </div>
    </p>
  );
};
export default TileRadioButtonGroup;
