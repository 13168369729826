import React from 'react';
import { Card } from '@ateams/components';
import Invitation from '@src/layouts/Invitation';

interface Props {
  className?: string;
  shareLink: string;
}

const Invite: React.FC<Props> = ({ className, shareLink }) => (
  <Card className={className}>
    <Invitation shareLink={shareLink} title="Share your invite code" />
  </Card>
);

export default Invite;
