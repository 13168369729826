import React, { ReactElement, useEffect, useState } from 'react';
import { SelectOption } from '@ateams/components';
import { useStores } from '@src/stores';
import { GenericTalent } from '@src/components/TeamGraphBuilderQuery';
import MixedTalentList from './MixedTalentList';
import { TalentSkillRating } from '@a_team/models/dist/TalentCategories';
import {
  fetchIndustryList,
  getIndustrySelectOptions,
} from '@src/helpers/talent-industry-api';

interface Props {
  preferredIndustries: string[];
  requiredIndustries: string[];
  onChange: (
    requiredIndustries: GenericTalent[],
    preferredIndustries: GenericTalent[],
  ) => void;
  sidebar?: boolean;
}

const MixedIndustryList = (props: Props): ReactElement => {
  const { auth } = useStores();
  const { preferredIndustries, requiredIndustries, onChange, sidebar } = props;

  const searchIndustries = async (
    searchTerm: string,
  ): Promise<SelectOption[]> => {
    return getIndustrySelectOptions({
      filter: {
        query: searchTerm,
      },
    });
  };

  // fetch industries on from backend based on IDs parsed from URL
  const [preferredIndustriesValues, setPreferredIndustriesValues] = useState(
    [] as GenericTalent[],
  );
  const [requiredIndustriesValues, setRequiredIndustriesValues] = useState(
    [] as GenericTalent[],
  );
  const [isLoadingTags, setIsLoadingTags] = useState(true);

  useEffect(() => {
    const allIds = requiredIndustries
      .concat(preferredIndustries)
      .map((e) => e?.toString()?.split('-')[0]);
    if (!allIds.length) {
      setPreferredIndustriesValues([]);
      setRequiredIndustriesValues([]);
      setIsLoadingTags(false);
      return;
    }

    fetchIndustryList({
      filter: { id: allIds?.length > 0 ? allIds : null },
    }).then((industries) => {
      const resMap = industries?.reduce(
        (acc: Record<string, GenericTalent>, industries) => {
          acc[industries.id] = {
            id: industries.id,
            label: industries.name,
          };
          return acc;
        },
        {},
      );
      setPreferredIndustriesValues(
        preferredIndustries
          .map((industry) => resMap[industry])
          .filter((e) => e?.id),
      );
      setRequiredIndustriesValues(
        requiredIndustries
          .map((industry) => {
            const [id, rating] = industry?.split('-');
            return {
              ...resMap[id],
              rating: Number(rating ?? TalentSkillRating.None),
            };
          })
          .filter((e) => e?.id),
      );
      setIsLoadingTags(false);
    });
  }, [preferredIndustries.join(), requiredIndustries.join(), auth]);

  const handleIndustriesChange = (
    requiredIndustries: GenericTalent[],
    preferredIndustries: GenericTalent[],
  ) => {
    onChange(requiredIndustries, preferredIndustries);
  };

  return (
    <>
      <MixedTalentList
        isLoadingTags={isLoadingTags}
        onChange={handleIndustriesChange}
        search={searchIndustries}
        preferred={preferredIndustriesValues}
        preferredPlaceholder={'Preferred Industries'}
        required={requiredIndustriesValues}
        requiredPlaceholder={'Required Declared Industries'}
        sidebar={sidebar}
      />
    </>
  );
};

export default MixedIndustryList;
