import {
  TalentSkillId,
  TalentSkillRating,
} from '@a_team/models/dist/TalentCategories';
import { SkillTargeterUserObject } from '@a_team/models/dist/TeamGraphObject';
import {
  BuilderMissionStatus,
  SkillTargeterCriteria,
} from '@ateams/api/dist/endpoints/TeamGraph';
import {
  apiClientSignals,
  teamEngineSearchServiceApi,
} from '@src/logic/services/endpoints';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery';
import { ServiceAuth } from '@ateams/service-utils';
import {
  v1EntityPage,
  v1SearchUserFilterApi,
  v1SearchUserFilterApiManagementExperience,
  v1SearchUserFilterApiRoleApplicationStatus,
  v1SearchUserRankingCalibrationApi,
  v1SearchUserFilterApiKeywordSource,
  v1SearchUserSkills,
  v1UserBadge,
  v1UserScrubbed,
  v1UserStatus,
} from '@a_team/team-engine-search-service-sdk-js/dist/schema';
import { UserBadge } from '@a_team/models/dist/UserObject';
import { format, formatISO } from 'date-fns';

// Targeter API Transformers
function getSkillRequirementQuery(requiredSkillsFilter: string[] | undefined): {
  requiredSkills?: TalentSkillId[];
  skillRequirements?: TalentSkillRating[];
} {
  const requiredSkills: string[] = [];
  const skillRequirements: number[] = [];
  requiredSkillsFilter?.forEach((talentSkillId) => {
    const [id, rating] = talentSkillId?.split('-');
    requiredSkills.push(id);
    skillRequirements.push(Number(rating ?? TalentSkillRating.None));
  });
  // TODO: Targeter API return 400 if empty data
  return {
    requiredSkills: requiredSkills.length ? requiredSkills : undefined,
    skillRequirements,
  };
}

export function mapTargeterApiRequest(
  criteria: RoleFilter,
): SkillTargeterCriteria {
  return {
    ...criteria,
    ...getSkillRequirementQuery(criteria?.requiredSkills),
    useTrustedTalentProfile: criteria.useTrustedTalentProfile ?? false,
    keyword: criteria.keyword && String(criteria.keyword).trim(),
    keywordSources: criteria.keywordSources?.filter(
      (source) => source !== null,
    ),
    includeUnknownAvailability: criteria.includeUnknownAvailability ?? true,
    useEstimatedAvailability: criteria.useEstimatedAvailability ?? true,
    includeExceptional: criteria.includeExceptional ?? true,
    includeVerified: criteria.includeVerified ?? true,
    latestApplicationDate: criteria?.includeApplicationDate
      ? criteria?.latestApplicationDate
      : undefined,
    missionStatus: criteria?.requireMissionStatus
      ? criteria?.missionStatus
      : undefined,
    specializations: [
      ...new Set([
        ...(criteria.specializations ?? []),
        ...(criteria.adjacentSpecializations ?? []),
      ]),
    ],
  };
}

export function mapTargeterApiRankingParamsRequest(
  criteria: RoleFilter,
): v1SearchUserRankingCalibrationApi {
  return {
    scoreStrictnessFactor: criteria.scoreStrictnessFactor,
    additionalSpecializationBoostingFactor:
      criteria.additionalSpecializationBoosting,
    cityBoostingFactor: criteria.cityBoosting,
    companyBoostingFactor: criteria.companyBoosting,
    countryBoostingFactor: criteria.countryBoosting,
    emailBoostingFactor: criteria.emailBoosting,
    exactManagementExperienceBoostingFactor:
      criteria.exactManagementExperienceBoosting,
    hourlyRateDecayRateOutside: criteria.hourlyRateDecayRateOutside,
    hourlyRateDefaultBoostingFactorIfMissing:
      criteria.hourlyRateDefaultBoostingFactorIfMissing,
    hourlyRateMinBoostingFactor: criteria.hourlyRateMinBoostingFactor,
    hourlyRateWithinRangeBoostingFactor:
      criteria.hourlyRateWithinRangeBoostingFactor,
    hoursAvailableBoostingFactor: criteria.hoursAvailableBoosting,
    immediateAvailabilityBoostingFactor: criteria.immediateAvailabilityBoosting,
    industryExperienceBoostingFactor: criteria.industryExperienceBoosting,
    keywordBoostingFactor: criteria.keywordBoosting,
    mainSpecializationBoostingFactor: criteria.mainSpecializationBoosting,
    managementExperienceBoostingFactor: criteria.managementExperienceBoosting,
    midTermAvailabilityBoostingFactor: criteria.midTermAvailabilityBoosting,
    midTermUpdateBoostingFactor: criteria.midTermUpdateBoosting,
    preferredIndustriesBoostingFactor: criteria.preferredIndustriesBoosting,
    preferredSkillsBoostingFactor: criteria.preferredSkillsBoosting,
    preferredTagsBoostingFactor: criteria.preferredTagsBoosting,
    profileCompletenessBoostingFactor: criteria.profileCompletenessBoosting,
    recentUpdateBoostingFactor: criteria.recentUpdateBoosting,
    requiredIndustriesBoostingFactor: criteria.requiredIndustriesBoosting,
    requiredSkillsBoostingFactor: criteria.requiredSkillsBoosting,
    requiredTagsBoostingFactor: criteria.requiredTagsBoosting,
    shortTermAvailabilityBoostingFactor: criteria.shortTermAvailabilityBoosting,
    zeroToOneBoostingFactor: criteria.zeroToOneBoosting,
  };
}

export interface GetElasticTargeterSearchParams {
  auth: ServiceAuth;
  criteria: SkillTargeterCriteria;
  rankingParams: v1SearchUserRankingCalibrationApi;
  limit?: number;
  pageSize?: number;
}

async function excludeBuildersFromClientSignals(
  auth: ServiceAuth,
  accountId: string | undefined,
  accountUserIds: string[] | undefined,
  includeStarredBuilders: boolean | undefined,
  includeHiddenBuilders: boolean | undefined,
): Promise<{
  buildersToInclude: string[] | undefined;
  buildersToExclude: string[] | undefined;
}> {
  let buildersToInclude: string[] | undefined = undefined;
  let buildersToExclude: string[] | undefined = undefined;

  if (accountId) {
    const clientSignals = await apiClientSignals.getClientSignalsByAccountId(
      auth,
      accountId,
    );

    if ((accountUserIds?.length || 0) > 0) {
      const starredUserIds = clientSignals
        ?.filter((cs) => cs.actionType === 'Star')
        .filter((cs) => accountUserIds?.includes(cs.client))
        .map((cs) => cs.builderId);

      const hiddenUserIds = clientSignals
        ?.filter((cs) => cs.actionType === 'NotInterested')
        .filter((cs) => accountUserIds?.includes(cs.client))
        .map((cs) => cs.builderId);

      if (includeStarredBuilders !== undefined) {
        if (includeStarredBuilders) {
          if (!buildersToInclude) {
            buildersToInclude = [];
          }
          starredUserIds?.forEach((id) => {
            buildersToInclude?.push(id);
          });
        } else {
          if (!buildersToExclude) {
            buildersToExclude = [];
          }
          starredUserIds?.forEach((id) => {
            buildersToExclude?.push(id);
          });
        }
      }

      if (includeHiddenBuilders !== undefined) {
        if (includeHiddenBuilders) {
          if (!buildersToInclude) {
            buildersToInclude = [];
          }
          hiddenUserIds?.forEach((id) => {
            buildersToInclude?.push(id);
          });
        } else {
          if (!buildersToExclude) {
            buildersToExclude = [];
          }
          hiddenUserIds?.forEach((id) => {
            buildersToExclude?.push(id);
          });
        }
      }
    }

    if (buildersToInclude?.length === 0) {
      buildersToInclude.push(''); // Push an empty string to return no results
    }
  }

  return {
    buildersToInclude,
    buildersToExclude: buildersToExclude?.filter((exc) =>
      buildersToInclude?.includes(exc),
    ),
  };
}

export async function queryParametersToSearchRequestBody({
  auth,
  criteria,
  pageSize = 25, // default page size
  currentPage = 1,
}: {
  auth: GetElasticTargeterSearchParams['auth'];
  criteria: GetElasticTargeterSearchParams['criteria'];
  pageSize?: GetElasticTargeterSearchParams['pageSize'];
  currentPage?: number;
}): Promise<{
  filter: v1SearchUserFilterApi;
  page: v1EntityPage;
  unsupportedFields?: string[]; // todo use this to show unsupported fields alert in the UI
}> {
  const availableFrom = criteria?.availabilityDate?.toString();
  let workingHoursOverlap: v1SearchUserFilterApi['workingHoursOverlap'];
  if (criteria?.whFrom && criteria?.whTo && criteria?.whOl && criteria?.whTz) {
    workingHoursOverlap = {
      intervals: [
        {
          startTime: criteria?.whFrom,
          endTime: criteria?.whTo,
        },
      ],
      minutes: criteria?.whOl,
      timezone: criteria?.whTz,
      // todo check if we need matchUsersWithoutOverlap now or later
      // matchUsersWithoutOverlap: true,
      startDate: availableFrom?.split('T')[0] ?? undefined, // format: yyyy-MM-dd
    };
  }
  const scrubbed: v1UserScrubbed[] = [];
  criteria?.includeExceptional !== false && scrubbed.push('Exceptional');
  criteria?.includeVerified !== false && scrubbed.push('Verified');
  criteria?.includeLegacyUnknown && scrubbed.push('LegacyUnknown');
  criteria?.includeUnknown && scrubbed.push('Unknown');
  criteria?.includeInsufficient && scrubbed.push('Insufficient');

  const badges: v1UserBadge[] = [];
  criteria?.selectionTeam && badges.push('SelectionTeam');
  criteria?.beenOnMission && badges.push('BeenOnMission');
  criteria?.exceptionalATeamer && badges.push('ExceptionalATeamer');
  criteria?.residentATeamer && badges.push('ATeamerResidence');
  criteria?.vettedATeamer && badges.push('ATeamer');
  criteria?.vettingScheduled && badges.push('VettingScheduled');
  criteria?.unvetted && badges.push('Unvetted');
  criteria.limitedAccess && badges.push('LimitedAccess');
  criteria?.includeUnscrubbed && badges.push('NotScrubbed');
  criteria?.unqualified && badges.push('UnqualifiedUser');
  criteria?.vettingInterviewDate && badges.push('VettingInterviewDate');

  const emailTypesToInclude = [];
  const emailTypesToExclude = [];

  if (criteria?.includeRichReachoutEmailType === false) {
    emailTypesToExclude.push('ReachoutTemplated');
    emailTypesToExclude.push('ReachoutTemplatedV2');
  } else if (criteria?.includeRichReachoutEmailType) {
    emailTypesToInclude.push('ReachoutTemplated');
    emailTypesToInclude.push('ReachoutTemplatedV2');
  }

  if (criteria?.includePlainReachoutEmailType === false) {
    emailTypesToExclude.push('ReachoutPlain');
  } else if (criteria?.includePlainReachoutEmailType) {
    emailTypesToInclude.push('ReachoutPlain');
  }

  const { buildersToInclude, buildersToExclude } =
    await excludeBuildersFromClientSignals(
      auth,
      criteria.accountId,
      criteria.accountUserIds,
      criteria.includeStarredBuilders,
      criteria.includeHiddenBuilders,
    );

  const unsupportedFields: string[] = [];

  const profileCompletenessFilter: Record<string, number | boolean> = {};
  if (criteria.profileCompletenessVariant === 'mvp') {
    profileCompletenessFilter.profileCompleteness = 1;
  } else if (criteria.profileCompletenessVariant === 'hqp') {
    profileCompletenessFilter.highQualityProfile = 1;
    profileCompletenessFilter.requireHighQualityProfile = true;
  } else if (criteria.profileCompletenessVariant === 'mvp-v1') {
    profileCompletenessFilter.profileCompletenessV1 = 1;
    profileCompletenessFilter.requireProfileCompletenessV1 = true;
  } else if (criteria.profileCompletenessVariant === 'hqp-v1') {
    profileCompletenessFilter.highQualityProfileV1 = 1;
    profileCompletenessFilter.requireHighQualityProfileV1 = true;
  }

  const filters: v1SearchUserFilterApi = {
    isAdmin: !!criteria.isAdmin,
    requireCurrentlyInterviewing: criteria?.requireCurrentlyInterviewing
      ? criteria?.requireCurrentlyInterviewing
      : undefined,
    requireCurrentlyProposed: criteria?.requireCurrentlyProposed
      ? criteria?.requireCurrentlyProposed
      : undefined,
    // =================
    query: criteria.keyword,
    keywordSearchSources: criteria.keywordSources as
      | (v1SearchUserFilterApiKeywordSource | null)[]
      | undefined,
    useQueryKeywordForSorting: criteria.useKeywordForSorting,
    semanticSearchQuery: criteria.semanticSearchQuery,
    neighbors: criteria.semanticResults,
    searchType: criteria.searchType,
    // Trusted Talent Profile
    useTrustedTalentProfile: criteria.useTrustedTalentProfile,
    // =================
    // Specializations
    specializations: criteria.specializations,
    requireMainSpecialization: criteria.requireMainSpecialization,
    requireAssignedSpecialization: criteria.requireAssignedSpecialization,
    includeSuggestedSpecializations: criteria?.includeSuggestedSpecializations,

    excludeBuildersWithPerformanceIssue:
      criteria.excludeBuildersWithPerformanceIssue,
    // =================
    // Mission Context
    excludeAppliedForRoles: criteria?.excludeAppliedRoles,
    appliedForRoles: criteria?.appliedRoles,
    notifiedRoles:
      emailTypesToInclude.length || emailTypesToExclude.length
        ? criteria?.notifiedForRoles
        : undefined,

    // =================
    // Custom Tags
    customTags: criteria?.customTags,
    requireAllCustomTags: !criteria?.atLeastOneCustomTagShouldMatch,
    excludedTags: criteria?.excludedCustomTags,
    requireAllExcludedTags: criteria?.excludeOnlyIfHasAllTags,
    preferredTags: criteria?.preferredCustomTags,

    // notInterestedRoles: // todo: v2
    // =================
    // Availability
    dateAvailableFrom: availableFrom,
    minimumRequiredAvailableHours: criteria?.weeklyHoursAvailable ?? 0,
    includeUnknownAvailability: criteria.includeUnknownAvailability,
    useEstimatedAvailability: criteria.useEstimatedAvailability,
    includeNotAvailable: criteria.includeNotAvailable,
    appliedSince: criteria?.latestApplicationDate?.toString(),
    appliedSinceExclude: criteria.appliedSinceExclude,
    activeOnMission:
      typeof criteria?.missionStatus === 'undefined'
        ? undefined
        : criteria?.missionStatus === BuilderMissionStatus.Active,
    missionStatusDate: criteria?.missionStatusAvailabilityDate?.toString(),
    requireScheduledToEnd: criteria?.requireHasScheduledEndDate,

    // =================
    // notification preferences
    requiredNotificationCategories: criteria?.requiredNotificationCategories,
    includePausedNotifications: criteria?.includePausedNotifications,
    excludeEmailNotificationsBlocked:
      criteria?.excludeEmailNotificationsBlocked ?? true,
    requireEmailNotificationsBlocked:
      criteria?.requireEmailNotificationsBlocked,
    requireAllSelectedNotificationCategories:
      criteria?.requireAllNotificationCategories,

    requireAutomaticallyDisabledCategories:
      criteria?.requireAutomaticallyDisabledCategories ?? false,
    includeCategoriesDisabledAutomatically:
      criteria?.includeCategoriesDisabledAutomatically ?? false,

    // =================
    // Skills
    status: criteria?.userStatus
      ? (criteria?.userStatus as v1UserStatus[])
      : ['Active'],
    includeSuggestedSkills: criteria?.includeSuggestedSkills,

    scrubbed:
      scrubbed?.length && badges.includes('Unvetted') ? scrubbed : undefined,
    // =================
    // Industries
    preferredIndustries: criteria?.preferredIndustries,
    requiredIndustries: criteria?.requiredIndustries,
    includeAppliedIndustries: criteria?.includeApplicationIndustries,
    industriesExperience:
      criteria?.industriesExperienceIndustries?.length &&
      criteria?.industriesExperienceIndustries?.length ===
        criteria.industriesExperienceMinYears?.length
        ? criteria?.industriesExperienceIndustries?.map((industry, i) => {
            return {
              industryId: industry,
              minYearsOfExperience: criteria?.industriesExperienceMinYears?.[i],
            };
          })
        : undefined,

    requireAllSkills: !criteria?.atLeastOneSkillShouldMatch,
    minimumRequiredSkills: criteria?.minimumRequiredSkills,
    requiredSkills: criteria?.requiredSkills?.map((sid, i) => {
      return { sid, rating: criteria?.skillRequirements?.[i] ?? 0 };
    }),
    preferredSkills: criteria?.preferredSkills,

    // =================
    // Skill Matching
    requireVerifiedSkills: criteria.requireVerifiedSkills,
    includeAppliedSkills: criteria.includeApplicationSkills,

    // =================
    // Hourly Rate
    suggestedBuilderHourlyRateMax: criteria?.hourlyRateMax,
    suggestedBuilderHourlyRateMin: criteria?.hourlyRateMin,
    requireSuggestedBuilderHourlyRate: !criteria?.isHourlyRatePreferred,

    // =================
    // Location
    countries: criteria?.countries,
    cities: criteria?.cities,
    provinces: criteria?.provinces,
    requireCity: criteria?.requireCity,
    requireProvince: criteria?.requireProvince,
    requireCountry: criteria?.requireCountry,
    workingHoursOverlap,
    // =================
    // badges
    badges: badges?.length ? badges : undefined,

    // =================
    // Ace Score
    englishScore: criteria?.englishScore,
    accentScore: criteria?.accentScore,
    expertiseScore: criteria?.expertiseScore,
    interactionExperienceScore: criteria?.interactionScore,

    expertiseScoreOperator:
      criteria?.onlyExactScores === true ? '==' : undefined,
    accentScoreOperator: criteria?.onlyExactScores === true ? '==' : undefined,
    englishScoreOperator: criteria?.onlyExactScores === true ? '==' : undefined,
    interactionExperienceScoreOperator:
      criteria?.onlyExactScores === true ? '==' : undefined,

    // =================
    // Years of experience
    minYearsExperience:
      criteria?.minYearsExperience && !isNaN(criteria?.minYearsExperience)
        ? criteria?.minYearsExperience
        : undefined,
    // =================
    // Created at
    createdAtFrom: criteria?.createdAtFrom?.toString(),
    createdAtTo: criteria?.createdAtTo?.toString(),
    // Scrubbed at
    scrubbedDateFrom: criteria?.scrubbedDateFrom?.toString(),
    scrubbedDateTo: criteria?.scrubbedDateTo?.toString(),
    lastLoginAt: criteria?.lastLoginAt
      ? formatISO(new Date(criteria?.lastLoginAt))
      : undefined,
    lastLoginAtExclude: criteria?.lastLoginAtExclude || false,
    companiesV2: criteria?.companies,
    emailTypesToInclude: emailTypesToInclude.length
      ? emailTypesToInclude
      : undefined,
    emailTypesToExclude: emailTypesToExclude.length
      ? emailTypesToExclude
      : undefined,

    ids: buildersToInclude,
    excludeIds: [...(buildersToExclude ?? []), ...(criteria.excludeIds ?? [])],
    optedOutOfClientDiscovery: criteria?.optedOutOfClientDiscovery
      ? !criteria?.optedOutOfClientDiscovery
      : undefined,
    ...profileCompletenessFilter,
    declaredIndustryYearsOfExperience:
      criteria?.declaredIndustryYearsOfExperience,
    requireAllIndustries: !criteria?.requireAnyIndustry,
    requireAllIndustriesExperience: !criteria?.requireAnyIndustry,
    // applicationStatus
    roleApplicationStatus:
      criteria?.roleApplicationStatus as v1SearchUserFilterApiRoleApplicationStatus[],
    excludeAppliedStatus: criteria?.excludeAppliedStatus,
    excludeOnHoldApplicants: criteria?.excludeOnHoldApplicants,
    // vetting data
    managementExperience:
      criteria?.mgmtExp as v1SearchUserFilterApiManagementExperience,
    isManagementExperiencePreferred: criteria?.mgmtPreferred,
    hasZeroToOneExperience: criteria?.zeroToOne,
    isZeroToOneExperiencePreferred: criteria?.zeroToOnePreferred,
    requireProposedSkills: criteria?.requireProposedSkills,
    requireProposedSpecializations: criteria?.requireProposedSpecializations,
  };

  return {
    unsupportedFields,
    filter: filters,
    // todo add pagination
    page: {
      current: isNaN(currentPage) ? 1 : currentPage,
      size: pageSize, // up to 10000 for example in user notification service set the value to 10000
    },
  };
}

const forceStringArray = (
  value: string[] | (string | null)[] | undefined | null,
): string[] => {
  return (value?.filter((v): v is string => !!v) as string[]) || [];
};

export function v1SearchUserFilterApiToSearchCriteria(
  filter: v1SearchUserFilterApi,
  mid: string,
): RoleFilter {
  const criteria: RoleFilter = {};

  criteria.keyword = filter.query ?? undefined;
  criteria.keywordSources =
    (filter.keywordSearchSources as v1SearchUserFilterApiKeywordSource[]) ??
    undefined;
  criteria.useKeywordForSorting = filter.useQueryKeywordForSorting ?? false;
  criteria.specializations = forceStringArray(filter.specializations);
  criteria.useTrustedTalentProfile = filter.useTrustedTalentProfile ?? false;
  criteria.requiredSkills = filter.requiredSkills
    ?.filter((s): s is v1SearchUserSkills => !!s)
    ?.map((skill) => `${skill?.sid}-${skill?.rating}`);
  criteria.preferredSkills = forceStringArray(filter.preferredSkills);

  criteria.hourlyRateMax = filter.suggestedBuilderHourlyRateMax ?? undefined;

  criteria.countries = forceStringArray(filter.countries);
  criteria.availabilityDate = filter.dateAvailableFrom
    ? format(new Date(filter.dateAvailableFrom), 'yyyy-MM-dd')
    : undefined;
  criteria.weeklyHoursAvailable = filter.minimumRequiredAvailableHours ?? 0;
  criteria.useEstimatedAvailability = filter.useEstimatedAvailability ?? false;
  criteria.includeUnknownAvailability =
    filter.includeUnknownAvailability ?? true;
  criteria.notifiedForRoles = forceStringArray(filter.notifiedRoles) ?? [];
  criteria.preferredIndustries = forceStringArray(filter.preferredIndustries);
  criteria.whFrom =
    filter.workingHoursOverlap?.intervals?.[0]?.startTime ?? undefined;
  criteria.whTo =
    filter.workingHoursOverlap?.intervals?.[0]?.endTime ?? undefined;
  criteria.whOl = filter.workingHoursOverlap?.minutes ?? undefined;
  criteria.whTz = filter.workingHoursOverlap?.timezone ?? undefined;
  criteria.excludeBuildersWithPerformanceIssue =
    filter.excludeBuildersWithPerformanceIssue ?? false;

  criteria.residentATeamer =
    filter.badges?.includes('ATeamerResidence') ?? false;
  criteria.selectionTeam = filter.badges?.includes('SelectionTeam') ?? false;
  criteria.beenOnMission = filter.badges?.includes('BeenOnMission') ?? false;
  criteria.exceptionalATeamer =
    filter.badges?.includes('ExceptionalATeamer') ?? false;
  criteria.residentATeamer =
    filter.badges?.includes('ATeamerResidence') ?? false;
  criteria.vettedATeamer = filter.badges?.includes('ATeamer') ?? false;
  criteria.vettingScheduled =
    filter.badges?.includes('VettingScheduled') ?? false;
  criteria.vettingInterviewDate =
    filter.badges?.includes('VettingInterviewDate') ?? false;
  criteria.unvetted = filter.badges?.includes('Unvetted') ?? false;
  criteria.limitedAccess = filter.badges?.includes('LimitedAccess') ?? false;
  criteria.includeUnscrubbed = filter.badges?.includes('NotScrubbed') ?? false;
  criteria.unqualified = filter.badges?.includes('UnqualifiedUser') ?? false;
  criteria.lastLoginAt = filter.lastLoginAt
    ? format(new Date(filter.lastLoginAt), 'yyyy-MM-dd')
    : undefined;
  criteria.userStatus = filter.status?.filter((s): s is v1UserStatus => !!s);
  criteria.requiredNotificationCategories = forceStringArray(
    filter.requiredNotificationCategories,
  );
  criteria.contextMission = mid;

  criteria.excludeAppliedRoles = filter.excludeAppliedForRoles ?? false;
  criteria.appliedRoles = forceStringArray(filter.appliedForRoles) ?? undefined;
  criteria.includePlainReachoutEmailType = getIncludePlainReachout(
    filter.emailTypesToExclude ?? [],
    filter.emailTypesToInclude ?? [],
  );
  criteria.includeRichReachoutEmailType = getIncludeRichReachout(
    filter.emailTypesToExclude ?? [],
    filter.emailTypesToInclude ?? [],
  );
  criteria.excludedCustomTags =
    forceStringArray(filter.excludedTags) ?? undefined;

  criteria.requireCountry = filter.requireCountry ?? false;
  criteria.includeNotAvailable = filter.includeNotAvailable ?? false;
  criteria.atLeastOneSkillShouldMatch = !filter.requireAllSkills;
  criteria.minimumRequiredSkills = filter.minimumRequiredSkills ?? 0;
  criteria.isHourlyRatePreferred = !filter.requireSuggestedBuilderHourlyRate;
  criteria.excludeEmailNotificationsBlocked =
    filter.excludeEmailNotificationsBlocked ?? true;
  criteria.requireEmailNotificationsBlocked =
    filter.requireEmailNotificationsBlocked || false;

  return criteria;
}
const getIncludePlainReachout = (
  emailTypesToExclude: (string | null)[],
  emailTypesToInclude: (string | null)[],
): boolean | undefined => {
  if (emailTypesToExclude?.some((emailType) => emailType === 'ReachoutPlain')) {
    return false;
  }
  if (emailTypesToInclude?.some((emailType) => emailType === 'ReachoutPlain')) {
    return true;
  }
  return undefined;
};

const getIncludeRichReachout = (
  emailTypesToExclude: (string | null)[],
  emailTypesToInclude: (string | null)[],
): boolean | undefined => {
  if (
    emailTypesToExclude?.some(
      (emailType) =>
        emailType === 'ReachoutTemplated' ||
        emailType === 'ReachoutTemplatedV2',
    )
  ) {
    return false;
  }
  if (
    emailTypesToInclude?.some(
      (emailType) =>
        emailType === 'ReachoutTemplated' ||
        emailType === 'ReachoutTemplatedV2',
    )
  ) {
    return true;
  }
  return undefined;
};

export interface ElasticTargeterQueryResult<T> {
  items: T[];
  metadata?: {
    totalPages?: number;
    totalRecords?: number;
    currentPage?: number;
    pageSize?: number;
    sunsettedBuildersCount?: number;
    suppressedBuildersCount?: number;
  };
}

export type GetElasticTargeterSearchResponse =
  ElasticTargeterQueryResult<SkillTargeterUserObject>;
export async function getElasticTargeterSearch(
  params: GetElasticTargeterSearchParams,
  includeSunsettedBuildersCount?: boolean,
  includeSuppressedBuildersCount?: boolean,
): Promise<GetElasticTargeterSearchResponse> {
  const { auth, criteria, rankingParams, pageSize: requestedPageSize } = params;
  const { filter, page } = await queryParametersToSearchRequestBody({
    auth,
    criteria,
    pageSize: requestedPageSize ?? 50,
    currentPage: Number(criteria.page ?? 1),
  });

  const [
    searchResponse,
    sunsettedBuildersCountResponse,
    suppressedBuildersCountResponse,
  ] = await Promise.all([
    await teamEngineSearchServiceApi.query(
      {
        v1SearchUsers: [
          {
            filter,
            rankingFactors: rankingParams,
            page,
          },
          {
            users: {
              badges: 1,
              communityBadges: 1,
              status: 1,
              email: 1,
              displayEmail: 1,
              firstName: 1,
              lastName: 1,
              fullName: 1,
              scrubbed: 1,
              title: 1,
              titles: 1,
              pictureURL: 1,
              profilePictureURL: 1,
              profileURL: 1,
              type: 1,
              uid: 1,
              isAdmin: 1,
              isVetter: 1,
              aboutMe: 1,
              availability: 1,
              estimatedAvailability: 1,
              rateRange: {
                min: 1,
                max: 1,
              },
              hourlyRate: {
                min: 1,
                max: 1,
              },
              suggestedBuilderHourlyRate: 1,
              location: {
                city: 1,
                country: 1,
                countryShortName: 1,
              },
              talentProfile: 1,
              verified: 1,
              englishScore: 1,
              expertiseScore: 1,
              interactionExperienceScore: 1,
              accentScore: 1,
              username: 1,
              updatedAt: 1,
              createdAt: 1,
              matchedOnQueries: 1,
              matchedOnSemanticQuery: 1,
              metadata: 1,
              linkedin: {
                username: 1,
              },
              adminNotes: {
                scores: {
                  english: 1,
                  accent: 1,
                  expertise: 1,
                  interactionExperience: 1,
                },
              },
              notificationPreferences: {
                emailNotificationsBlockedAt: 1,
                categoriesDisabledAutomatically: 1,
              },
            },
            pageSize: 1,
            currentPage: 1,
            totalPages: 1,
            totalRecords: 1,
          },
        ],
      },
      {
        headers: {
          Authorization: auth.bearerToken,
        },
      },
    ),
    includeSunsettedBuildersCount &&
    filter.requiredNotificationCategories?.length &&
    filter.includeCategoriesDisabledAutomatically
      ? await teamEngineSearchServiceApi.query(
          {
            v1SearchUsers: [
              {
                filter: {
                  ...filter,
                  requireAutomaticallyDisabledCategories: true,
                },
              },
              {
                totalRecords: 1,
              },
            ],
          },
          {
            headers: {
              Authorization: auth.bearerToken,
            },
          },
        )
      : undefined,
    includeSuppressedBuildersCount && !filter.excludeEmailNotificationsBlocked
      ? await teamEngineSearchServiceApi.query(
          {
            v1SearchUsers: [
              {
                filter: {
                  ...filter,
                  requireEmailNotificationsBlocked: true,
                },
              },
              {
                totalRecords: 1,
              },
            ],
          },
          {
            headers: {
              Authorization: auth.bearerToken,
            },
          },
        )
      : undefined,
  ]);

  const { data, errors } = searchResponse;
  const sunsettedBuildersCount =
    sunsettedBuildersCountResponse?.data?.v1SearchUsers?.totalRecords;
  const suppressedBuildersCount =
    suppressedBuildersCountResponse?.data?.v1SearchUsers?.totalRecords;

  if (errors) {
    throw new Error(errors[0].message);
  }

  const { users, totalRecords, pageSize, currentPage, totalPages } =
    data?.v1SearchUsers ?? {};
  const items =
    users?.map((user) => {
      return {
        uid: user.uid,
        email: user.displayEmail || user.email,
        englishScore: user.adminNotes?.scores?.english,
        accentScore: user.adminNotes?.scores?.accent,
        expertiseScore: user.adminNotes?.scores?.expertise,
        interactionExperienceScore:
          user.adminNotes?.scores?.interactionExperience,
        type: user.type,
        verified: user.verified,
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: user.fullName,
        title: user.title,
        titles: user.titles,
        isVetter: user.isVetter,
        aboutMe: user.aboutMe,
        location: user.location,
        profilePictureURL: user.profilePictureURL,
        profileURL: user.profileURL,
        username: user.username,
        matchedOnSemanticQuery:
          user.matchedOnSemanticQuery?.fields?.vectorizedTexts
            ?.at(0)
            ?.text?.at(0),
        createdAt: user.createdAt,
        badges: user.badges
          ?.filter((badge) => badge !== UserBadge.Unvetted)
          .reverse(),
        communityBadges: user.communityBadges?.map((badge) => ({
          type: badge,
          assignedAt: new Date(),
        })),
        status: user.status,
        scrubbed: user.scrubbed,
        talentProfile: user.talentProfile,
        availability: {
          rejectedUpdatePrompt: user?.availability?.rejectedUpdatePrompt,
          notes: user?.availability?.notes,
          remindMeAt: user?.availability?.remindMeAt,
          updatedAt: user?.availability?.updatedAt,
          type: user?.availability?.availability?.type,
          weeklyHoursAvailable:
            user?.availability?.availability?.weeklyHoursAvailable ?? 0,
          availableFrom: user?.availability?.availability?.date,
        },
        estimatedAvailability: user?.estimatedAvailability,
        linkedIn: user?.linkedin?.username
          ? {
              profileUrl: `https://linkedin.com/in/${user?.linkedin?.username}`,
            }
          : undefined,
        rateRange: {
          min: user?.suggestedBuilderHourlyRate
            ? user?.suggestedBuilderHourlyRate
            : user?.hourlyRate?.min && user?.rateRange?.min
            ? Math.min(user?.hourlyRate?.min, user?.rateRange?.min)
            : user?.hourlyRate?.min || user?.rateRange?.min,
          max: user?.suggestedBuilderHourlyRate
            ? user?.suggestedBuilderHourlyRate
            : user?.hourlyRate?.max && user?.rateRange?.max
            ? Math.max(user?.hourlyRate?.max, user?.rateRange?.max)
            : user?.hourlyRate?.max || user?.rateRange?.max,
        },
        emailNotificationsBlocked:
          !!user?.notificationPreferences?.emailNotificationsBlockedAt,
        categoriesDisabledAutomatically:
          user?.notificationPreferences?.categoriesDisabledAutomatically,
        matchesOn: user?.matchedOnQueries?.reduce((acc, query) => {
          acc[String(query)] = true;
          return acc;
        }, {} as Record<string, boolean>),
      } as SkillTargeterUserObject;
    }) || [];
  return {
    items,
    metadata: {
      totalRecords,
      pageSize,
      currentPage,
      totalPages,
      sunsettedBuildersCount,
      suppressedBuildersCount,
    },
  };
}
