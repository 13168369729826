import React from 'react';
import { createUseStyles } from 'react-jss';
import { uniqBy } from 'lodash';
import { Icon, IconType } from '@ateams/components';
import { useHistory } from 'react-router-dom';
import TextButton from '@src/components/TextButton';
import MissionRole from '@a_team/models/dist/MissionRole';

interface Props {
  roles: MissionRole[];
}

const useStyles = createUseStyles({
  roleButton: {
    '&:focus': {
      outline: 'auto',
    },
  },
  icon: {
    marginRight: '0.5em',
  },
});

const NUMBER_OF_ROLES_DISPLAYED = 3;

const MissionRoles: React.FC<Props> = (props) => {
  const { roles: allRoles } = props;
  const history = useHistory();
  const styles = useStyles();

  const roles: MissionRole[] = uniqBy(allRoles, ({ category }) => category.cid);
  const extraRoles =
    roles.length > NUMBER_OF_ROLES_DISPLAYED &&
    roles.length - NUMBER_OF_ROLES_DISPLAYED;

  const handleClick = () => {
    history.push('');
  };

  if (roles.length === 0) return null;

  return (
    <p>
      <Icon type={IconType.UsersGray} size={'exact'} className={styles.icon} />
      {roles.slice(0, NUMBER_OF_ROLES_DISPLAYED).map((role, ind) => (
        <span key={`${role.category.cid}-${ind}`}>
          <TextButton
            onClick={handleClick}
            className={styles.roleButton}
            color="primary"
          >
            {role.category.title}
          </TextButton>
          {roles[ind + 1] && <span>, </span>}
        </span>
      ))}
      {extraRoles && (
        <span>{`+${extraRoles} Role${extraRoles > 1 ? 's' : ''}`}</span>
      )}
    </p>
  );
};

export default MissionRoles;
