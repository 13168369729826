import React, { useState, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { TooltipProps } from 'react-tippy';
import { add, endOfMonth, format, startOfMonth } from 'date-fns';
import OutlinedInput, {
  OutlinedInputProps,
} from '@src/components/Inputs/OutlinedInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { XMarkIcon } from '../x-mark-icon';
import TooltipWrapped from '@src/components/TooltipWrapped';

export type OnDateRangeChange = (startDate?: Date, endDate?: Date) => unknown;

export interface DateRangePickerProps {
  startDate?: Date;
  endDate?: Date;
  placeholder?: string;
  onChange: OnDateRangeChange;
  tooltipProps?: TooltipProps;
  inputProps?: OutlinedInputProps;
  'data-testing-id'?: string;
}

enum DateTemplate {
  CustomRange = 'customRange',
  Last7Days = 'last7Days',
  Last30Days = 'last30Days',
  ThisMonth = 'thisMonth',
  LastMonth = 'lastMonth',
}

const colors = {
  primary: '#027CC0',
  primaryLight: '#E5F3FC',
  grey: '#F6F7F7',
  white: '#fff',
  transparent: 'transparent',
};

const useStyles = createUseStyles<{ isSelected?: boolean }>({
  '@global': {
    '.tippy-popper': {
      maxWidth: 'none',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px 10px',
    '& .react-datepicker': {
      border: 'none',
    },
    '& .react-datepicker__header': {
      borderBottom: 'none',
      backgroundColor: colors.transparent,
    },
  },
  leftColumnContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '20px',
  },
  dateTemplatesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  datePickerContainer: {
    height: '332px',
    marginRight: '12px',
    '&:last-child': {
      marginRight: 0,
    },
  },
  dateTemplate: {
    width: '100%',
    textAlign: 'left',
    fontSize: '16px',
    padding: '8px 12px',
    marginBottom: '16px',
    borderRadius: '4px',
    color: colors.primary,
    backgroundColor: ({ isSelected }) =>
      isSelected ? colors.primaryLight : colors.grey,
    '&:hover': {
      backgroundColor: colors.primaryLight,
    },
    cursor: 'pointer',
  },
  buttonContained: {
    fontSize: '18px',
    color: colors.white,
    backgroundColor: colors.primary,
    border: `1px solid ${colors.primary}`,
    borderRadius: '4px',
    padding: '8px 16px',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9,
    },
    marginRight: '8px',
    width: '94px',
  },
  button: {
    fontSize: '18px',
    color: colors.primary,
    backgroundColor: colors.transparent,
    border: `1px solid ${colors.primary}`,
    borderRadius: '4px',
    padding: '8px 16px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.primary,
      color: colors.white,
    },
    width: '94px',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  input: {
    fontSize: '15px',
    margin: 0,
  },
  datePickerInput: {
    marginBottom: '16px',
  },
  textInput: {
    padding: 0,
    margin: 0,
    height: '20px',
  },
  textInputControl: {
    padding: '10px 12px',
    height: '42px',
    minHeight: 'initial',
    borderRadius: '4px',
  },
  externalInputControl: {
    minWidth: '280px',
  },
  calendarIcon: {
    width: '16px',
    marginRight: '8px',
  },
});

export const DateRangePicker: React.FC<DateRangePickerProps> = (props) => {
  const {
    startDate,
    endDate,
    placeholder,
    onChange,
    tooltipProps,
    inputProps,
  } = props;
  // set to false because it was never in props
  const styles = useStyles({ isSelected: false });
  const [isOpen, setIsOpen] = useState(false);
  const [dateTemplate, setDateTemplate] = useState<DateTemplate>(
    DateTemplate.CustomRange,
  );
  const [tempStartDate, setTempStartDate] = useState<Date | undefined>();
  const [tempEndDate, setTempEndDate] = useState<Date | undefined>();

  const changeDateTemplate = (dateTemplate: DateTemplate) => {
    setDateTemplate(dateTemplate);

    switch (dateTemplate) {
      case DateTemplate.Last7Days: {
        const date = new Date();

        date.setHours(0, 0, 0, 0);
        setTempStartDate(add(date, { days: -7 }));
        setTempEndDate(undefined);
        break;
      }
      case DateTemplate.Last30Days: {
        const date = new Date();

        date.setHours(0, 0, 0, 0);
        setTempStartDate(add(date, { days: -30 }));
        setTempEndDate(undefined);
        break;
      }
      case DateTemplate.ThisMonth: {
        setTempStartDate(startOfMonth(new Date()));
        setTempEndDate(undefined);
        break;
      }
      case DateTemplate.LastMonth: {
        let date = new Date();

        date = add(date, { months: -1 });
        setTempStartDate(startOfMonth(date));
        setTempEndDate(endOfMonth(date));
        break;
      }
      case DateTemplate.CustomRange:
      default:
        break;
    }
  };

  const inputLabel = useMemo(() => {
    if (!startDate && !endDate) {
      return undefined;
    }

    const start = startDate ? format(startDate, 'MM/dd/yyyy') : 'Now';
    const end = endDate ? format(endDate, 'MM/dd/yyyy') : 'Now';

    return `${start} - ${end}`;
  }, [startDate, endDate]);

  const toggleTooltip = (isOpen: boolean) => {
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    setIsOpen(isOpen);

    if (!isOpen) {
      changeDateTemplate(DateTemplate.CustomRange);
    }
  };

  const onApply = () => {
    if (tempStartDate || tempEndDate) {
      toggleTooltip(false);
      onChange(tempStartDate, tempEndDate);
    }
  };

  return (
    <TooltipWrapped
      hidden={() => {
        setTempStartDate(undefined);
        setTempEndDate(undefined);
      }}
      trigger="click"
      interactive
      arrow
      theme="light"
      open={isOpen}
      html={
        <div
          className={styles.container}
          data-testing-id={
            props['data-testing-id'] && `${props['data-testing-id']}-toast`
          }
        >
          <div className={styles.leftColumnContainer}>
            <div className={styles.datePickerContainer}>
              <OutlinedInput
                value={
                  tempStartDate ? format(tempStartDate, 'MMMM dd, yyyy') : ''
                }
                disabled
                className={cx(styles.input, styles.datePickerInput)}
                controlClassName={styles.textInputControl}
                inputClassName={styles.textInput}
                icon={<CalendarIcon className={styles.calendarIcon} />}
              />

              <DatePicker
                selected={tempStartDate}
                onChange={
                  dateTemplate === DateTemplate.CustomRange
                    ? (date) => setTempStartDate(date as Date)
                    : () => null
                }
                selectsStart
                startDate={tempStartDate}
                endDate={tempEndDate}
                inline
              />
            </div>

            <div className={styles.datePickerContainer}>
              <OutlinedInput
                value={tempEndDate ? format(tempEndDate, 'MMMM dd, yyyy') : ''}
                disabled
                className={cx(styles.input, styles.datePickerInput)}
                controlClassName={styles.textInputControl}
                inputClassName={styles.textInput}
                icon={<CalendarIcon className={styles.calendarIcon} />}
              />

              <DatePicker
                selected={tempEndDate}
                onChange={
                  dateTemplate === DateTemplate.CustomRange
                    ? (date) => setTempEndDate(date as Date)
                    : () => null
                }
                selectsEnd
                startDate={tempStartDate}
                endDate={tempEndDate}
                minDate={tempStartDate}
                inline
              />
            </div>
          </div>
          <div className={styles.rightColumnContainer}>
            <div className={styles.dateTemplatesContainer}>
              <DateTemplateButton
                isSelected={dateTemplate === DateTemplate.Last7Days}
                onClick={() => changeDateTemplate(DateTemplate.Last7Days)}
                data-testing-id={'filter-button-last-7-days'}
              >
                Last 7 Days
              </DateTemplateButton>
              <DateTemplateButton
                isSelected={dateTemplate === DateTemplate.Last30Days}
                onClick={() => changeDateTemplate(DateTemplate.Last30Days)}
                data-testing-id={'filter-button-last-30-days'}
              >
                Last 30 Days
              </DateTemplateButton>
              <DateTemplateButton
                isSelected={dateTemplate === DateTemplate.ThisMonth}
                onClick={() => changeDateTemplate(DateTemplate.ThisMonth)}
              >
                This Month
              </DateTemplateButton>
              <DateTemplateButton
                isSelected={dateTemplate === DateTemplate.LastMonth}
                onClick={() => changeDateTemplate(DateTemplate.LastMonth)}
              >
                Last Month
              </DateTemplateButton>
              <DateTemplateButton
                isSelected={dateTemplate === DateTemplate.CustomRange}
                onClick={() => changeDateTemplate(DateTemplate.CustomRange)}
              >
                Custom Range
              </DateTemplateButton>
            </div>
            <div className={styles.actionsContainer}>
              <div
                className={styles.buttonContained}
                onClick={onApply}
                data-testing-id={'apply-button'}
              >
                Apply
              </div>
              <div
                className={styles.button}
                onClick={() => toggleTooltip(false)}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      }
      {...tooltipProps}
    >
      <OutlinedInput
        placeholder={placeholder}
        value={inputLabel}
        onClick={() => setIsOpen(true)}
        className={styles.input}
        controlClassName={cx(
          styles.textInputControl,
          styles.externalInputControl,
        )}
        inputClassName={styles.textInput}
        endAdornment={
          startDate || endDate ? (
            <XMarkIcon
              onClick={() => {
                setTempStartDate(undefined);
                setTempEndDate(undefined);
                onChange(undefined, undefined);
              }}
            />
          ) : undefined
        }
        data-testing-id={
          props['data-testing-id'] && `${props['data-testing-id']}-input`
        }
        {...inputProps}
      />
    </TooltipWrapped>
  );
};

const DateTemplateButton: React.FC<{
  isSelected: boolean;
  onClick?: () => unknown;
}> = (props) => {
  const { isSelected, ...rest } = props;
  const styles = useStyles({ isSelected });

  return (
    <div className={styles.dateTemplate} {...rest}>
      {props.children}
    </div>
  );
};

const CalendarIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 448 512" {...props}>
      <path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM64 304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V272C128 263.2 120.8 256 112 256H80C71.16 256 64 263.2 64 272V304zM192 304C192 312.8 199.2 320 208 320H240C248.8 320 256 312.8 256 304V272C256 263.2 248.8 256 240 256H208C199.2 256 192 263.2 192 272V304zM336 256C327.2 256 320 263.2 320 272V304C320 312.8 327.2 320 336 320H368C376.8 320 384 312.8 384 304V272C384 263.2 376.8 256 368 256H336zM64 432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V400C128 391.2 120.8 384 112 384H80C71.16 384 64 391.2 64 400V432zM208 384C199.2 384 192 391.2 192 400V432C192 440.8 199.2 448 208 448H240C248.8 448 256 440.8 256 432V400C256 391.2 248.8 384 240 384H208zM320 432C320 440.8 327.2 448 336 448H368C376.8 448 384 440.8 384 432V400C384 391.2 376.8 384 368 384H336C327.2 384 320 391.2 320 400V432z" />
    </svg>
  );
};
