import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  useState,
} from 'react';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { Icon, IconType } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import { Divider } from '@ateams/components';

interface Props {
  onSubmit: (query: string) => void;
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
    position: 'relative',
  },
  divider: {
    display: 'block',
    width: '100%',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    divider: {
      display: 'none',
    },
    container: {
      width: 300,
    },
  },
});

export const Search = (props: Props): ReactElement => {
  const styles = useStyles();
  const [query, setQuery] = useState('');

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      props.onSubmit(query);
    }
  };

  return (
    <>
      <Divider margin={'small'} className={styles.divider} />
      <div className={styles.container}>
        <OutlinedInput
          fullWidth
          size={20}
          margin={'none'}
          value={query}
          onChange={onChange}
          onKeyDown={onKeyPress}
          placeholder={'Who are you looking for?'}
          // className={styles.search}
          endAdornment={
            <Icon
              type={IconType.SearchGrey}
              size={'medium'}
              onClick={() => props.onSubmit(query)}
            />
          }
        />
      </div>
    </>
  );
};
