import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { theme } from '@a_team/ui-components';

type SeparatorDirection = 'horizontal' | 'vertical';
type SeparatorPosition = 'start' | 'end';

interface SeparatorProps {
  direction: SeparatorDirection;
  position?: SeparatorPosition;
  expand?: boolean;
  color?: string;
  width?: string | number;
  space?: string | number;
  className?: string;
}

const useStyles = createUseStyles({
  separator: (props: React.PropsWithChildren<SeparatorProps>) => {
    const width = props.width || '1px';
    const space = props.space || 0;

    return {
      width: props.expand && props.direction === 'horizontal' ? '100%' : 'auto',
      height: props.expand && props.direction === 'vertical' ? '100%' : 'auto',
      color: props.color || theme.colors.Grey[300],
      borderStyle: 'solid',
      borderTopWidth:
        props.position === 'start' && props.direction === 'horizontal'
          ? width
          : 0,
      borderRightWidth:
        props.position === 'end' && props.direction === 'vertical' ? width : 0,
      borderBottomWidth:
        props.position === 'end' && props.direction === 'horizontal'
          ? width
          : 0,
      borderLeftWidth:
        props.position === 'start' && props.direction === 'vertical'
          ? width
          : 0,
      marginTop:
        props.position === 'end' && props.direction === 'horizontal' && space,
      marginRight:
        props.position === 'start' && props.direction === 'vertical' && space,
      marginBottom:
        props.position === 'start' && props.direction === 'horizontal' && space,
      marginLeft:
        props.position === 'end' && props.direction === 'vertical' && space,
      paddingTop:
        Boolean(props.children) &&
        props.position === 'start' &&
        props.direction === 'horizontal' &&
        space,
      paddingRight:
        Boolean(props.children) &&
        props.position === 'end' &&
        props.direction === 'vertical' &&
        space,
      paddingBottom:
        Boolean(props.children) &&
        props.position === 'end' &&
        props.direction === 'horizontal' &&
        space,
      paddingLeft:
        Boolean(props.children) &&
        props.position === 'start' &&
        props.direction === 'vertical' &&
        space,
    };
  },
});

export const Separator: React.FC<SeparatorProps> = (props) => {
  const styles = useStyles(props);

  return (
    <div className={cx(styles.separator, props.className)}>
      <div>{props.children}</div>
    </div>
  );
};

Separator.defaultProps = {
  position: 'start',
};
