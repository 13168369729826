import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import MainLayout from '@src/layouts/Main';
import SectionHeading from '@src/components/SectionHeading';
import airtableLogo from './airtable.png';
import { createUseStyles } from 'react-jss';
import { useStores } from '@src/stores';
import { Redirect } from 'react-router-dom';
import { RootLocation } from '@src/locations';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import { apiUsers } from '@src/logic/services/endpoints';
import AirtableUsers from './AirtableUsers';

type AirtableThumbnail = {
  height: number;
  url: string;
  width: number;
};

type AirtableThumbnails = {
  full: AirtableThumbnail;
  large: AirtableThumbnail;
  small: AirtableThumbnail;
};

type AirtablePic = {
  filename: string;
  id: string;
  size: number;
  thumbnails: AirtableThumbnails;
};

export type AirtableRecord = {
  recordID: string;
  ['0. AceNumber']: number;
  ['1. Name']: string;
  ['2. Email']: string;
  ['3. Expertise']: string[];
  ['4. LinkedIn']: string;
  ['5. Site']: string;
  ['6. City']: string[];
  ['7. Current Work']: string;
  ['8. Bio']: string;
  ['9. Other Info']: string;
  ['10. Stage']: string[];
  ['12. Roadblocks']: string;
  ['13. ACE Score']: number;
  ['14.5 Source Tag']: string;
  ['14. Source/Referred By']: string;
  ['15. Name/Email Ppl they Referred']: string;
  ['18. Feature in post?']: string[];
  ['22. Scrubed?']: string[];
  ['Added By']: string;
  ['Date Added']: string;
  ['Last Edited']: string;
  ['Last Edited By']: string;
  ['Picture']: AirtablePic[];
};

const useStyles = createUseStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    marginTop: '28px',
  },
  bold: {
    fontWeight: 'bold',
    marginRight: '8px',
  },
  loader: {
    color: '#fff',
    background: '#ffbf2b',
    padding: '8px',
    borderRadius: '4px',
    opacity: 1,
    animation: '$loading 1s linear infinite',
  },
  '@keyframes loading': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
});

export default function AirtableView(): ReactElement {
  const { auth } = useStores();
  const [airtable, setAirtable] = useState([]);
  const [totalUsers, setTotalUsers] = useState<BasicUserObject[]>([]);
  const styles = useStyles();

  useEffect(() => {
    fetch('https://twitter-freelance.herokuapp.com/airtable')
      .then((results) => results.json())
      .then((data) => setAirtable(data));
    apiUsers.queryAll(auth).then((userList: BasicUserObject[]): void => {
      setTotalUsers(userList);
    });
  }, []);

  const totalMembers = airtable.length;
  const totalScrubbed = useMemo(
    () =>
      airtable.filter(
        (item: AirtableRecord) =>
          item['22. Scrubed?'] &&
          item['22. Scrubed?'].find((option) => option === 'Yes'),
      ),
    [airtable],
  );
  const totalProcessed = useMemo(
    () =>
      airtable.filter(
        (item: AirtableRecord) =>
          item['10. Stage'] &&
          item['10. Stage'].find((option) => option === 'In ACE'),
      ),
    [airtable],
  );
  /*const totalNotScrubbed = airtable.filter(
    (item: AirtableRecord) =>
      item['22. Scrubed?'] === undefined ||
      item['22. Scrubed?'].find((option) => option === 'No'),
  );*/
  const scrubbedMinusOnes = useMemo(
    () =>
      totalScrubbed.filter(
        (item: AirtableRecord) =>
          item['13. ACE Score'] && item['13. ACE Score'] === -1,
      ),
    [airtable],
  );
  const scrubbedZerosOnes = useMemo(
    () =>
      totalScrubbed.filter(
        (item: AirtableRecord) =>
          item['13. ACE Score'] &&
          (item['13. ACE Score'] === 0 || item['13. ACE Score'] === 1),
      ),
    [airtable],
  );
  const scrubbedZerosOnesDevelopers = useMemo(
    () =>
      scrubbedZerosOnes.filter(
        (item: AirtableRecord) =>
          item['3. Expertise'] &&
          item['3. Expertise'].find((option) => option === 'Developer'),
      ),
    [airtable],
  );
  const scrubbedZerosOnesDesigners = useMemo(
    () =>
      scrubbedZerosOnes.filter(
        (item: AirtableRecord) =>
          item['3. Expertise'] &&
          item['3. Expertise'].find(
            (option) => option === 'Designer' || option === 'Designer/UX',
          ),
      ),
    [airtable],
  );
  const scrubbedZerosOnesProducts = useMemo(
    () =>
      scrubbedZerosOnes.filter(
        (item: AirtableRecord) =>
          item['3. Expertise'] &&
          item['3. Expertise'].find((option) => option === 'Product'),
      ),
    [airtable],
  );
  const telAviv = useMemo(
    () =>
      airtable.reduce(
        (prev, item: AirtableRecord) =>
          prev +
          (item['6. City'] &&
          item['6. City'].find((option) => option === 'Tel Aviv')
            ? 1
            : 0),
        0,
      ),
    [airtable],
  );
  const newYork = useMemo(
    () =>
      airtable.reduce(
        (prev, item: AirtableRecord) =>
          prev +
          (item['6. City'] &&
          item['6. City'].find(
            (option) => option === 'New York' || option === 'New York City',
          )
            ? 1
            : 0),
        0,
      ),
    [airtable],
  );
  const sanFrancisco = useMemo(
    () =>
      airtable.reduce(
        (prev, item: AirtableRecord) =>
          prev +
          (item['6. City'] &&
          item['6. City'].find((option) => option === 'San Francisco')
            ? 1
            : 0),
        0,
      ),
    [airtable],
  );

  return (
    <MainLayout title={'Airtable'}>
      {!auth.isAdmin ? (
        <Redirect to={RootLocation} />
      ) : (
        <div>
          <SectionHeading
            isFirst
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '28px',
              fontWeight: 700,
              marginBottom: '48px',
            }}
          >
            <img
              src={airtableLogo}
              alt="airtable"
              style={{ marginRight: '18px', width: '48px' }}
            />
            <div>Freelancer DB Dashboard</div>
          </SectionHeading>
          <AirtableUsers
            title={'Total freelancers'}
            users={airtable}
            value={totalMembers}
          />
          <AirtableUsers
            title={'Processed (In Ace)'}
            users={totalProcessed}
            value={totalProcessed.length}
          />
          <AirtableUsers
            title={'Scrubbed'}
            users={totalScrubbed}
            value={`${totalScrubbed.length} of ${totalMembers} (${Math.round(
              (totalScrubbed.length / totalMembers) * 100,
            )}%)`}
          />
          <AirtableUsers
            title={'-1s'}
            users={scrubbedMinusOnes}
            value={scrubbedMinusOnes.length}
          />
          <AirtableUsers
            title={'0s and 1s'}
            users={scrubbedZerosOnes}
            value={scrubbedZerosOnes.length}
          />
          <AirtableUsers
            title={'0s and 1s (Dev)'}
            users={scrubbedZerosOnesDevelopers}
            value={scrubbedZerosOnesDevelopers.length}
          />
          <AirtableUsers
            title={'0s and 1s (Design)'}
            users={scrubbedZerosOnesDesigners}
            value={scrubbedZerosOnesDesigners.length}
          />
          <AirtableUsers
            title={'0s and 1s (Product)'}
            users={scrubbedZerosOnesProducts}
            value={scrubbedZerosOnesProducts.length}
          />
          <hr style={{ marginTop: '24px', marginBottom: '24px' }} />
          <div className={styles.header}>
            <div className={styles.bold}>New York:</div>{' '}
            {airtable.length < 1 ? (
              <div className={styles.loader} />
            ) : (
              `${Math.round((newYork / totalMembers) * 100)}%`
            )}
          </div>
          <div className={styles.header}>
            <div className={styles.bold}>San Francisco:</div>{' '}
            {airtable.length < 1 ? (
              <div className={styles.loader} />
            ) : (
              `${Math.round((sanFrancisco / totalMembers) * 100)}%`
            )}
          </div>
          <div className={styles.header}>
            <div className={styles.bold}>Tel Aviv:</div>{' '}
            {airtable.length < 1 ? (
              <div className={styles.loader} />
            ) : (
              `${Math.round((telAviv / totalMembers) * 100)}%`
            )}
          </div>
          <hr style={{ marginTop: '24px', marginBottom: '24px' }} />
          <div className={styles.header} style={{ marginTop: '124px' }}>
            <div className={styles.bold}>Platform Sign Ups:</div>{' '}
            {totalUsers.length < 1 ? (
              <div className={styles.loader} />
            ) : (
              totalUsers.length
            )}
          </div>
        </div>
      )}
    </MainLayout>
  );
}
