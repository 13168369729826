import {
  BuilderSuggestion,
  BuilderSuggestionId,
  BuilderSuggestionType,
  ProfileEnhancementSuggestions,
  SuggestionActionRequest,
} from '@a_team/models/dist/ProfileEnhancements';
import { ServiceAuth, ServiceEndpoint } from './utils';
import { ExperienceData } from '@a_team/models/src/ExperienceObject';
import { UserId } from '@a_team/models/src/UserObject';

export const BasePath = '/profile-suggestions';

export default class ProfileSuggestionsEndpoint extends ServiceEndpoint {
  public suggestions(
    auth: ServiceAuth,
  ): Promise<ProfileEnhancementSuggestions> {
    return this.fetch(auth, BasePath, null, 'get');
  }

  public userSuggestions(
    auth: ServiceAuth,
    userId: UserId,
  ): Promise<ProfileEnhancementSuggestions> {
    return this.fetch(auth, `${BasePath}/${userId}`, null, 'get');
  }

  public rejectSuggestion(
    auth: ServiceAuth,
    sid: BuilderSuggestionId,
  ): Promise<void> {
    return this.fetch(auth, BasePath + `/${sid}/reject`, null, 'PATCH');
  }

  public applySuggestedProject(
    auth: ServiceAuth,
    sid: BuilderSuggestionId,
    eid: string,
    request: ExperienceData,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/${sid}/project/${eid}/accept`,
      null,
      'put',
      request,
    );
  }

  public applySuggestedJob(
    auth: ServiceAuth,
    sid: BuilderSuggestionId,
    eid: string,
    request: ExperienceData,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/${sid}/job/${eid}/accept`,
      null,
      'put',
      request,
    );
  }

  public applySuggestion(
    auth: ServiceAuth,
    sid: BuilderSuggestionId,
    request: SuggestionActionRequest,
  ): Promise<BuilderSuggestion> {
    return this.fetch(auth, BasePath + `/${sid}/accept`, null, 'put', request);
  }

  public applySuggestedRole(
    auth: ServiceAuth,
    sid: BuilderSuggestionId,
    roleId: string,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/${sid}/role/${roleId}/accept`,
      null,
      'PATCH',
    );
  }

  public rejectSuggestionPartially(
    auth: ServiceAuth,
    sid: BuilderSuggestionId,
    type: BuilderSuggestionType,
    partialSuggestionId: string,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/${sid}/${type}/${partialSuggestionId}/reject`,
      null,
      'PATCH',
    );
  }

  public generateProfileSuggestions(auth: ServiceAuth): Promise<void> {
    return this.fetch(auth, `${BasePath}/generate`, null, 'put');
  }

  public isLatestProfileEnhancementRequestCompleted(
    auth: ServiceAuth,
  ): Promise<{ isCompleted: boolean; hasProjectsSuggestionError?: boolean }> {
    return this.fetch(auth, `${BasePath}/latest/status`, null, 'get');
  }

  public applySuggestedIndustry(
    auth: ServiceAuth,
    sid: BuilderSuggestionId,
    industryId: string,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/${sid}/industry/${industryId}/accept`,
      null,
      'PATCH',
    );
  }

  public generateBioSuggestionOnDemand(auth: ServiceAuth): Promise<string> {
    return this.fetch(auth, `${BasePath}/generate-on-demand/bio`, null, 'put');
  }

  public generateProjectsSuggestionFromWebsiteOrPdfForUserOnDemand(
    auth: ServiceAuth,
    payload: {
      websiteUrl?: string;
      pdfUrl?: string;
    },
  ): Promise<string> {
    return this.fetch(
      auth,
      `${BasePath}/generate-on-demand/from-website-or-pdf/projects`,
      null,
      'post',
      payload,
    );
  }
}
