import React, { ReactElement } from 'react';
import {
  TimesheetId,
  TimesheetRecord,
  TimesheetRecordData,
  TimesheetRecordKey,
} from '@a_team/models/dist/TimesheetObject';
import MissionRole, { MissionAdminRole } from '@a_team/models/dist/MissionRole';
import { format } from 'date-fns';
import { getLocalTime, stringifyMinutes } from '@src/helpers/time';
import UserAvatar from '@src/components/UserAvatar';
import Dropdown, { DropdownItem } from '@src/components/Dropdown';
import { Icon, IconType } from '@ateams/components';
import useToggle from '@src/hooks/useToggle';
import EditTimesheetRecordModal from '@src/components/Modal/EditTimesheetRecordModal';

export interface RoleRecord extends TimesheetRecord {
  role: MissionRole;
}

interface Props {
  record: RoleRecord;
  timesheetId: TimesheetId | undefined;
  currentUserRole: MissionAdminRole | undefined;
  updateRecord(
    sid: TimesheetId,
    key: TimesheetRecordKey,
    data: TimesheetRecordData,
  ): void;
  deleteRecord(sid: TimesheetId, key: TimesheetRecordKey): void;
  clientView?: boolean;
  disabled?: boolean;
}

export default function MobileTableRow(props: Props): ReactElement {
  const {
    record,
    timesheetId,
    currentUserRole,
    updateRecord,
    deleteRecord,
    clientView,
    disabled,
  } = props;
  const [menuOpen, setMenuOpen] = useToggle();
  const [modalOpen, setModalOpen] = useToggle();

  const onEdit = (record: TimesheetRecord) => {
    if (timesheetId) {
      updateRecord(timesheetId, record.key, {
        date: record.date,
        minutes: record.minutes,
        details: record.details,
      });
    }
  };

  const onDelete = (record: TimesheetRecord) => {
    if (timesheetId) {
      deleteRecord(timesheetId, record.key);
    }
  };

  return (
    <>
      <EditTimesheetRecordModal
        record={record}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onEdit={onEdit}
      />
      <div
        style={{
          padding: 16,
          paddingTop: 0,
          marginTop: 24,
          paddingBottom: 24,
          borderBottom: '1px solid rgba(192,192,192, 0.5)',
        }}
      >
        <div style={{ fontWeight: 500, fontSize: 15 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <UserAvatar
              src={
                record.role.user?.username
                  ? record.role.user.profilePictureURL
                  : undefined
              }
              label={record.role.user?.fullName}
              size={24}
              containerStyle={{ marginBottom: '8px' }}
            />
            {currentUserRole?.rid === record.role.rid && !clientView && (
              <div style={{ position: 'relative' }}>
                <Icon
                  type={IconType.More}
                  onClick={setMenuOpen}
                  style={{
                    ...(disabled && { opacity: 0.3, pointerEvents: 'none' }),
                  }}
                />
                <Dropdown
                  onClose={() => setMenuOpen(false)}
                  open={menuOpen}
                  style={{ position: 'absolute', top: 24, right: 0 }}
                >
                  <DropdownItem
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => setModalOpen(true)}
                  >
                    <Icon type={IconType.EditGray} style={{ marginRight: 8 }} />{' '}
                    Edit logged time
                  </DropdownItem>
                  <DropdownItem
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => onDelete(record)}
                  >
                    <Icon type={IconType.TrashRed} style={{ marginRight: 8 }} />
                    Remove logged time
                  </DropdownItem>
                </Dropdown>
              </div>
            )}
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 16 }}>
              {format(getLocalTime(new Date(record.date)), 'EEE, LLL d')}
            </div>
            {stringifyMinutes(record.minutes)}
          </div>
        </div>
        <p
          style={{
            fontSize: 15,
            margin: 0,
            marginTop: 8,
            wordBreak: 'break-word',
          }}
        >
          {record.details}
        </p>
      </div>
    </>
  );
}
