import { action, observable } from 'mobx';
import { find, minBy, pick } from 'lodash';
import { QueryNextToken } from '@a_team/models/dist/misc';
import TargeterSearchObject, {
  TargeterSearchTab,
} from '@a_team/models/dist/TargeterSearchObject';
import { SkillTargeterUserObject } from '@a_team/models/dist/TeamGraphObject';
import { apiMissions, apiTeamGraph } from '@ateams/api';
import AuthStore from '../Auth';
import { parse } from 'query-string';
import {
  getTabsFromAutomatedReachoutMissionPreview,
  getTabsFromMission,
} from '@src/helpers/missions';
import { FromMissionType } from '@src/views/SkillTargeter';
import { MissionAdminObject } from '@a_team/models/dist/MissionObject';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery';
import { MissionPreviewRoleDto } from '@a_team/user-notification-service-js-sdk';

export const DEFAULT_TAB: TargeterSearchCache = {
  label: 'Current search',
  url: '',
  selectedBuilders: [],
  position: 0,
  builders: [],
  next: undefined,
  metadata: undefined,
};

export interface SelectableBuilder extends SkillTargeterUserObject {
  select?: boolean;
}

export interface TargeterSearchCache extends TargeterSearchTab {
  position: number;
  builders: SelectableBuilder[];
  searchKey?: string;
  next?: QueryNextToken;
  metadata?: {
    totalRecords?: number;
    pageSize?: number;
    currentPage?: number;
    totalPages?: number;
    sunsettedBuildersCount?: number;
    suppressedBuildersCount?: number;
  };
  automatedReachoutPreset?: string;
  roleId?: string;
}

export type TargeterTabCache = Record<string, TargeterSearchCache>;

export type TargeterSearchState = Pick<
  TargeterSearchObject,
  'tsid' | 'name' | 'creator' | 'suggestedTeamsParameters'
>;

interface TargeterTabManagerStoreData {
  tabCache?: TargeterTabCache;
  activeTab?: string;
  mostRecentSearch?: TargeterSearchState;
  loadingTabs?: Record<string, boolean>;
  cancellingTeamSearch?: boolean;
}

export function parseTargeterQuery(
  query: string | undefined | null,
): RoleFilter & {
  tsid?: string;
  mid?: string;
  type?: FromMissionType;
  loadFromTabCache?: string;
  role?: string;
  limit?: number;
} {
  const filter = parse(query ?? '', {
    arrayFormat: 'bracket',
    parseBooleans: true,
    parseNumbers: true,
  });

  /**
   * Mongo oids have been interpreted as numbers:
   * eg, 65830434472421001201e074 in `customUserTags`
   */
  const oidFilter = pick(
    parse(query ?? '', { arrayFormat: 'bracket', parseNumbers: false }),
    [
      'tsid',
      'mid',
      'keyword',
      'specializations',
      'adjacentSpecializations',
      'requiredSkills',
      'preferredSkills',
      'industriesExperienceIndustries',
      'requiredIndustries',
      'preferredIndustries',
      'teammates',
      'contextMission',
      'appliedRoles',
      'customTags',
      'excludedCustomTags',
      'preferredCustomTags',
      'notifiedForRoles',
      'accountId',
      'accountUserIds',
    ],
  );

  return { ...filter, ...oidFilter } as RoleFilter & {
    tsid?: string;
    mid?: string;
    type?: FromMissionType;
    role?: string;
  };
}

export default class TargeterTabManager implements TargeterTabManagerStoreData {
  @observable tabCache: TargeterTabManagerStoreData['tabCache'] = undefined;
  @observable activeTab: TargeterTabManagerStoreData['activeTab'] = undefined;
  @observable loadingTabs: TargeterTabManagerStoreData['loadingTabs'] = {};
  @observable
  mostRecentSearch: TargeterTabManagerStoreData['mostRecentSearch'] = undefined;
  @observable isCancellingTeamSearch = false;

  serialize = (): TargeterTabManagerStoreData => ({});

  /**
   * Initialization sequence:
   *   - use current URL, if not empty query & different from most recent state
   *   - most recent state is:
   *     - from store, if available
   *     - from localStorage, if available
   */
  public initializeTabCache = async (
    auth: AuthStore,
  ): Promise<{
    cache: TargeterTabCache;
    activeTab: string;
    mostRecentSearch?: TargeterSearchState;
    fromMission?: MissionAdminObject;
    fromMissionType?: FromMissionType;
    activeRoleTabNames?: string[];
    search?: TargeterSearchObject;
    role?: string;
    limit?: number;
  }> => {
    const storeCache = this.tabCache || {};

    const cache: TargeterTabCache =
      Object.keys(storeCache).length > 0
        ? storeCache
        : { [DEFAULT_TAB.label]: DEFAULT_TAB };

    // active tab should match query, come from stored state, or be first tab
    const query = window.location.search.substr(1) ?? '';
    const activeTab =
      find(cache, ({ url }) => url === query)?.label ??
      (this.activeTab && Object.keys(cache).includes(this.activeTab)
        ? (this.activeTab as string)
        : minBy(Object.values(cache), 'position')?.label ?? '');

    const storeSearch = this.mostRecentSearch;

    const mostRecentSearch = storeSearch;

    const isPastedUrl = query !== '' && cache[activeTab]?.url !== query;

    let result: {
      cache: TargeterTabCache;
      activeTab: string;
      mostRecentSearch?: TargeterSearchState;
      fromSavedSearch?: boolean;
      fromMission?: MissionAdminObject;
      fromMissionType?: FromMissionType;
      activeRoleTabNames?: string[];
      search?: TargeterSearchObject;
      role?: string;
      limit?: number;
    };

    // Initialize it as default
    result = { cache, activeTab, mostRecentSearch };

    if (isPastedUrl) {
      const queryParams = parseTargeterQuery(query);

      if (queryParams.tsid) {
        const tsid: string = queryParams.tsid as string;
        const queryResult = await apiTeamGraph.getTargeterSearchById(
          auth,
          tsid,
        );

        const newCache: TargeterTabCache = {};
        queryResult.tabs.forEach((tab, index) => {
          newCache[tab.label] = {
            label: tab.label,
            builders: [],
            position: index,
            selectedBuilders: tab.selectedBuilders,
            url: tab.url,
            next: undefined,
          };
        });

        result = {
          cache: newCache,
          activeTab: queryResult.tabs[0].label,
          mostRecentSearch: {
            name: queryResult.name,
            creator: queryResult.creator,
            tsid: queryResult.tsid,
            suggestedTeamsParameters: queryResult.suggestedTeamsParameters,
          },
          fromSavedSearch: true,
          search: queryResult,
        };
      } else if (queryParams.mid && queryParams.loadFromTabCache) {
        const mission = await apiMissions.adminGetMissionById(
          auth,
          queryParams.mid,
        );
        result = {
          cache,
          activeTab,
          fromMission: mission,
          fromMissionType: 'automated-reachout',
          mostRecentSearch,
          limit: queryParams.limit,
        };
      } else if (queryParams.mid) {
        const mid: string = queryParams.mid as string;
        const type: FromMissionType = queryParams.type as FromMissionType;
        const role = queryParams.role as string;

        const { tabs, activeRoleTabNames, mission } = await getTabsFromMission(
          auth,
          mid,
          type,
          role,
        );

        const newCache: TargeterTabCache = {};
        tabs.forEach((tab, index) => {
          let label = tab.label;
          let count = 1;
          while (newCache[label]) {
            count++;
            label = `${tab.label} (${count})`;
          }

          newCache[label] = {
            label: label,
            builders: [],
            position: index,
            selectedBuilders: [],
            url: tab.url,
            next: undefined,
            roleId: tab.roleId,
          };
        });
        result = {
          cache: newCache,
          activeTab: tabs[0].label,
          mostRecentSearch: undefined,
          fromMission: mission,
          fromMissionType: type,
          role,
          activeRoleTabNames,
        };
      } else {
        result = {
          cache: { [DEFAULT_TAB.label]: { ...DEFAULT_TAB, url: query } },
          activeTab: DEFAULT_TAB.label,
          mostRecentSearch: undefined,
        };
      }
    } else {
      if (Object.keys(storeCache).length === 0) {
        const lastSearches = await apiTeamGraph.getUserTargeterSearches(
          auth,
          undefined,
          1,
        );

        if (lastSearches.items.length > 0) {
          const lastSearchItem = await apiTeamGraph.getTargeterSearchById(
            auth,
            lastSearches.items[0].tsid,
          );

          const newCache: TargeterTabCache = {};
          lastSearchItem.tabs.forEach((tab, index) => {
            newCache[tab.label] = {
              label: tab.label,
              builders: [],
              position: index,
              selectedBuilders: tab.selectedBuilders,
              url: tab.url,
              next: undefined,
            };
          });

          result = {
            cache: newCache,
            activeTab: lastSearchItem.tabs[0].label,
            mostRecentSearch,
          };
        }
      }
    }

    this.setTabCache(result.cache);

    return result;
  };

  @action initializeTabCacheFromAutomatedReachoutMissionPreview = (
    mid: string,
    roles: MissionPreviewRoleDto[],
  ) => {
    const tabs = getTabsFromAutomatedReachoutMissionPreview(roles, mid);
    const newCache: TargeterTabCache = {};
    tabs.forEach((tab, index) => {
      let label = tab.label;
      let count = 1;
      while (newCache[label]) {
        count++;
        label = `${tab.label} (${count})`;
      }

      newCache[label] = {
        label: label,
        builders: [],
        position: index,
        selectedBuilders: [],
        url: tab.url,
        next: undefined,
        automatedReachoutPreset: tab.automatedReachoutPreset,
        roleId: tab.roleId,
      };
    });

    this.setTabCache(newCache);
  };

  @action setActiveTab(label: string): void {
    this.activeTab = label;
  }

  @action setLoadingTab(label: string, loading: boolean): void {
    if (this.loadingTabs) {
      this.loadingTabs[label] = loading;
    }
  }

  @action setTabCache(cache: TargeterTabCache): void {
    this.tabCache = cache;
  }

  @action setMostRecentSearch(
    searchState: TargeterSearchState | undefined,
  ): void {
    this.mostRecentSearch = searchState;
  }
}
