import React from 'react';
import { createUseStyles } from 'react-jss';
import { theme } from '@a_team/ui-components';
import {
  Checkbox,
  Colors,
  FontSizes,
  Spacing,
  TextColors,
} from '@ateams/components';
import { NumberValueTag } from '@src/views/VettingFeedbackForm/components/value-tag';
import {
  Size,
  Text,
} from '@src/views/VettingFeedbackForm/components/typography';

export type OnSendEvaluationEmailChange = (sendEmail: boolean) => void;

export interface EvaluationInviteModalFormTitleProps {
  emailsSentCount: number;
  isNewProcess: boolean;
  sendEvaluationEmail?: boolean;
  onSendEvaluationEmailChange?: OnSendEvaluationEmailChange;
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  emailCountContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  valueTag: ({ emailsSentCount }: EvaluationInviteModalFormTitleProps) => ({
    height: '24px',
    width: '24px',
    background: emailsSentCount > 0 ? Colors.secondaryDark : '#DADADC',
    color: emailsSentCount > 0 ? TextColors.primary : theme.colors.Grey[800],
    borderRadius: '6px',
    marginRight: Spacing.small,
  }),
  checkboxLabel: {
    fontSize: FontSizes.small,
  },
});

export const EvaluationInviteModalFormTitle: React.FC<
  EvaluationInviteModalFormTitleProps
> = (props) => {
  const styles = useStyles(props);

  return (
    <div className={styles.container}>
      <div className={styles.emailCountContainer}>
        <NumberValueTag isReadOnly className={styles.valueTag}>
          {props.emailsSentCount}
        </NumberValueTag>

        <Text size={Size.Small}>Emails sent to this builder so far</Text>
      </div>

      {props.isNewProcess && (
        <Checkbox
          margin={'none'}
          label={'Send Email'}
          checked={props.sendEvaluationEmail}
          onChange={(e) =>
            props.onSendEvaluationEmailChange?.(e.target.checked)
          }
          labelClassName={styles.checkboxLabel}
        />
      )}
    </div>
  );
};
