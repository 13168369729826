import { MissionPaymentCycleId } from '@a_team/models/dist/MissionPaymentCycleObject';
import ItemsCarousel from '@src/components/ItemsCarousel';
import Mission from '@src/stores/Missions/Mission';
import React from 'react';
import { createUseStyles } from 'react-jss';
import PaymentCycle from './PaymentCycle';
import { Colors } from '@ateams/components';
import useGetMonthlyRetainerDataForCurrentUserRole from '../hooks/useGetMonthlyRetainerDataForCurrentUserRole';

interface PaymentCyclesProps {
  adminView?: boolean;
  isActiveRole: boolean;
  mission: Mission;
  updateSelectedCycle: (id: MissionPaymentCycleId) => void;
  setPaymentCycleToSubmit: (id: MissionPaymentCycleId) => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    gap: 24,
  },
  wrapper: {
    maxWidth: '85vw',
    margin: '0 auto',
    marginLeft: 0,
  },
  monthlyRetainerInfo: {
    fontSize: 15,
    fontWeight: '400',
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  verticalDivider: {
    width: 2,
    height: 24,
    background: Colors.regular,
  },
});

const PaymentCycles = ({
  adminView,
  isActiveRole,
  mission,
  updateSelectedCycle,
  setPaymentCycleToSubmit,
}: PaymentCyclesProps): JSX.Element => {
  const styles = useStyles();

  const handleClick = (id: MissionPaymentCycleId) => {
    updateSelectedCycle(id);
  };

  const handleClickSubmit = (id: MissionPaymentCycleId) => {
    handleClick(id);
    setPaymentCycleToSubmit(id);
  };

  const { isFullTimeRetainer, monthlyRate } =
    useGetMonthlyRetainerDataForCurrentUserRole();

  return (
    <div className={styles.wrapper}>
      {isFullTimeRetainer && (
        <div className={styles.monthlyRetainerInfo}>
          <span className={styles.verticalDivider}></span>This is a full-time
          retainer mission, with a monthly rate of {monthlyRate} per month.
        </div>
      )}

      <ItemsCarousel
        edgeButtons
        className={styles.container}
        startPosition={'start'}
      >
        {mission.paymentCycles?.map((cycle) => (
          <PaymentCycle
            key={cycle.yid}
            paymentCycle={cycle}
            selected={cycle.data.yid === mission.selectedPaymentCycle?.data.yid}
            onClick={() => handleClick(cycle.data.yid)}
            onSubmitClick={() => handleClickSubmit(cycle.data.yid)}
            adminView={adminView}
            isActiveRole={isActiveRole}
            mission={mission}
          />
        ))}
      </ItemsCarousel>
    </div>
  );
};

export default PaymentCycles;
