import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Checkbox, Select, SelectOption } from '@ateams/components';
import { useQueryUserProvincesByCountry } from '@src/rq/provinceSearch';
import { ENVIRONMENT } from '@src/config';

interface Props {
  provinces: string[];
  onChange: (provinces: string[], requireProvince?: boolean) => void;
  sidebar?: boolean;
  countries?: string[];
  requireProvince?: boolean;
}

const useStyles = createUseStyles({
  selectionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginBottom: 8,
  },
  selector: {
    fontSize: 14,
  },
  sidebar: {
    maxWidth: 'unset',
    marginRight: 0,
    marginTop: 12,
  },
  checkbox: {
    paddingBottom: 12,
  },
});

const ProvinceList = (props: Props): ReactElement => {
  const styles = useStyles();
  const { provinces, onChange, sidebar, requireProvince, countries } = props;
  const provinceSelections: string[] | undefined = provinces;

  const removeProvince = (options: SelectOption | SelectOption[]): void => {
    const provinceToRemove: string[] = !options
      ? []
      : Array.isArray(options)
      ? options.map(({ value }) => value)
      : [options.value];
    const newProvinceSelections = provinceSelections
      ? provinceSelections.filter(
          (province) => !provinceToRemove.includes(province),
        )
      : [];

    onChange(newProvinceSelections);
  };

  const updateProvinces = (
    options: SelectOption | SelectOption[] | null,
  ): void => {
    const provinces: string[] = !options
      ? []
      : Array.isArray(options)
      ? options.map(({ value }) => value)
      : [options.value];

    onChange(provinces);
  };

  const { isLoading, data: allProvinces } = useQueryUserProvincesByCountry({
    filter: { countryCode: countries },
    applicationName: `targeter-province-select-${ENVIRONMENT}}`,
  });

  return (
    <div className={styles.selectionContainer}>
      <Checkbox
        className={styles.checkbox}
        label="Require provinces"
        checked={requireProvince ?? true}
        onChange={(e) => onChange(provinces, e.target.checked ?? undefined)}
        margin="none"
      />
      <Select
        isLoading={isLoading}
        hideTags={false}
        className={cx(styles.selector, sidebar && styles.sidebar)}
        options={allProvinces}
        value={provinceSelections.map((value) => {
          return { value: value, label: value };
        })}
        onChange={updateProvinces}
        placeholder={`Provinces`}
        margin="none"
        onRemoveOption={removeProvince}
        isMulti={true as false}
        keepTextOnSelect={true}
      />
    </div>
  );
};
export default ProvinceList;
