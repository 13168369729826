import React, { ReactElement } from 'react';
import LocationObject from '@a_team/models/dist/LocationObject';
import { observer } from 'mobx-react';
import { Breakpoints, TextColors } from '@ateams/components';
import {
  LocationAutocompleteV2,
  OnLocationChange,
} from '@src/components/LocationAutocompleteV2';
import {
  formatLocation,
  parseLocation,
} from '@src/components/LocationAutocompleteV2/utils';
import { Icon, Tooltip } from '@a_team/ui-components';
import TooltipBody from './TooltipBody';
import { createUseStyles } from 'react-jss';
import AuthStore from '@src/stores/Auth';
import { useQueryBuilderLoginHistory } from '@src/rq/builderLoginHistory';
import Profile from '@src/stores/Profile/Profile';

interface Props {
  city: string;
  province?: string;
  country: string;
  description?: string;
  onChange(data: LocationObject): void;
  readonly?: boolean;
  error?: boolean;
  disabled?: boolean;
  auth: AuthStore;
  profile: Profile;
}

const useStyles = createUseStyles({
  container: {
    margin: '0 24px',
    marginBottom: 24,
  },
  tooltip: {
    with: 300,
    '& div': {
      maxWidth: '340px !important',
    },
  },

  mismatchContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  title: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  iconContainer: { height: 20 },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      margin: 0,
      marginBottom: 40,
    },
  },
});

const Location = (props: Props): ReactElement => {
  const { readonly, city, province, country, onChange, description, ...rest } =
    props;
  const styles = useStyles();

  const { data } = useQueryBuilderLoginHistory({
    uid: props.profile?.data?.uid,
  });

  const onLocationChange: OnLocationChange = (options) => {
    if (!options) return;
    const option = Array.isArray(options) ? options[0] : options;
    const { prediction, countryShortName } = option;

    if (prediction?.structured_formatting) {
      const location = parseLocation(
        prediction.structured_formatting,
        countryShortName,
      );

      onChange(location);
    }
  };

  const hasLocationMismatch = data?.hasLocationMismatch;

  const location = formatLocation({ city, province, country });

  return (
    <div className={styles.container}>
      <div
        className={styles.title}
        style={{
          color: hasLocationMismatch ? '#F63131' : '#62646A',
        }}
      >
        Location {hasLocationMismatch && 'mismatch'}
        {data && (
          <div className={styles.mismatchContainer}>
            <Tooltip
              className={styles.tooltip}
              size="md"
              placement={'right'}
              text={
                <TooltipBody
                  linkedinCountry={data.linkedinCountry}
                  loginHistory={data.loginHistory}
                />
              }
            >
              <span className={styles.iconContainer}>
                <Icon
                  color={hasLocationMismatch ? 'Red@600' : 'Grey@600'}
                  name="details"
                  size="md"
                />
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      {readonly ? (
        <>
          <p
            style={{
              margin: 0,
              marginTop: '12px',
              fontSize: 17,
              color: hasLocationMismatch ? '#F63131' : '#222',
            }}
          >
            {location}
          </p>
        </>
      ) : (
        <>
          {description && (
            <div
              style={{
                marginTop: 4,
                color: TextColors.regularLight,
                fontSize: 12,
              }}
            >
              {description}
            </div>
          )}
          <LocationAutocompleteV2
            defaultInputValue={location}
            onChange={onLocationChange}
            {...rest}
          />
        </>
      )}
    </div>
  );
};

export default observer(Location);
