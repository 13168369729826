import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import type TRichTextEditor from 'react-rte';
import type { EditorValue } from 'react-rte';

export interface EmailBodyEditorProps {
  template: string;
  onChange?: OnEmailBodyChange;
}

export type OnEmailBodyChange = (value: string) => void;

const useStyles = createUseStyles({
  emailEditor: {
    fontFamily: 'Inter',
    border: 'none !important',
  },
  toolbarClassName: {
    border: 'none !important',
    margin: '0 !important',
  },
  editorClassName: {
    border: 'none !important',

    '& div': {
      padding: '0 !important',
    },
  },
});

export const EmailBodyEditor: React.FC<EmailBodyEditorProps> = (props) => {
  // prettier-ignore
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const RichTextEditor: typeof TRichTextEditor =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('react-rte-17').default;

  const styles = useStyles();
  const [emailBody, setEmailBody] = React.useState<EditorValue>(() =>
    props.template
      ? RichTextEditor.createValueFromString(props.template, 'html')
      : RichTextEditor.createEmptyValue(),
  );

  const onEmailBodyChange = (value: EditorValue) => {
    setEmailBody(value);
    props.onChange?.(value.toString('html'));
  };

  useEffect(() => {
    setEmailBody(RichTextEditor.createValueFromString(props.template, 'html'));
  }, [props.template]);

  return (
    <RichTextEditor
      value={emailBody}
      onChange={onEmailBodyChange}
      placeholder={'Enter email text'}
      toolbarConfig={{
        display: [
          'BLOCK_TYPE_DROPDOWN',
          'INLINE_STYLE_BUTTONS',
          'BLOCK_TYPE_BUTTONS',
          'LINK_BUTTONS',
          'HISTORY_BUTTONS',
        ],
        INLINE_STYLE_BUTTONS: [
          {
            label: 'Bold',
            style: 'BOLD',
          },
          { label: 'Italic', style: 'ITALIC' },
          { label: 'Underline', style: 'UNDERLINE' },
          { label: 'Strikethrough', style: 'STRIKETHROUGH' },
        ],
        BLOCK_TYPE_DROPDOWN: [
          { label: 'Normal', style: 'unstyled' },
          { label: 'Heading Large', style: 'header-one' },
          { label: 'Heading Medium', style: 'header-two' },
          { label: 'Heading Small', style: 'header-three' },
        ],
        BLOCK_TYPE_BUTTONS: [
          { label: 'UL', style: 'unordered-list-item' },
          { label: 'OL', style: 'ordered-list-item' },
        ],
      }}
      className={styles.emailEditor}
      toolbarClassName={styles.toolbarClassName}
      editorClassName={styles.editorClassName}
    />
  );
};
