/// <reference types="@welldone-software/why-did-you-render" />

import React from 'react';
import { ENVIRONMENT, REACT_APP_DISABLE_WHY_DID_YOU_RENDER } from '@src/config';

if (
  ENVIRONMENT === 'development' &&
  REACT_APP_DISABLE_WHY_DID_YOU_RENDER !== 'true'
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}
