import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from '../general/Link';
import { Spacing, FontSizes, Colors } from '@ateams/components';
import PortfolioObject from '@a_team/models/dist/PortfolioObject';

const useStyles = createUseStyles({
  builderSectionLinks: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
    alignItems: 'center',
  },
  builderSectionLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.xxsmall,
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  builderSectionLinkPassword: {
    fontSize: FontSizes.xsmall,
    color: Colors.dark,
  },
});

export interface BuilderSectionHorizontalLinksProps {
  linkedinUrl: string;
  resumeUrl: string;
  portfolio?: PortfolioObject;
}

export const BuilderSectionHorizontalLinks = ({
  linkedinUrl,
  resumeUrl,
  portfolio,
}: BuilderSectionHorizontalLinksProps) => {
  const styles = useStyles();

  return (
    <div className={styles.builderSectionLinks}>
      {linkedinUrl && <Link href={linkedinUrl} text="LinkedIn" />}
      {resumeUrl && <Link href={resumeUrl} text="Resume" />}
      {portfolio && (
        <div className={styles.builderSectionLinkContainer}>
          <Link href={portfolio?.url} text={'Portfolio'} />
          {portfolio?.password && (
            <div className={styles.builderSectionLinkPassword}>
              ({portfolio?.password})
            </div>
          )}
        </div>
      )}
    </div>
  );
};
