import React from 'react';
import { createUseStyles } from 'react-jss';
import InfoBox from '../InfoBox';

const useStyles = createUseStyles({
  container: {
    '& ol li::marker': {
      fontWeight: 600,
    },
    // select li
    '& li': {
      listStyle: 'initial',
    },
  },
});

const Plagiarism = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <p>
        <strong>Plagiarism:</strong> All work you perform for companies should
        be yours, period. Embedding code that isn’t yours or stealing designs is
        strictly forbidden and can result in legal action directly against you
        by a company.
      </p>

      <ol type="1">
        <li>
          <div>
            <p>
              <strong>Be careful with open source software</strong>
            </p>
            <p>
              You should always get clear permission from a company before using
              any open source software.
            </p>
            <ul>
              <li>
                <p>
                  If you’re not sure - ask. Generally speaking, Apache, MIT, and
                  BSD licenses are ofter considered permissive.
                </p>
              </li>
              <li>
                <p>
                  That said, the Builder Agreement between A.Teamers and
                  companies also prohibit using any software licensed under the
                  GNU GPL or LGPL.
                </p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div>
            <p>
              <strong>Get permission for third-party software</strong>
            </p>
            <p>
              The Builder Agreement requires you to get written permission from
              a company before using any third-party technology that you would
              need to license from another vendor.
            </p>
          </div>
        </li>
      </ol>

      <p>
        <strong>
          These are the foundations of the community and breaches may result in
          removal.
        </strong>
      </p>

      <InfoBox />
    </div>
  );
};

export default Plagiarism;
