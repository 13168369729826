import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface PropsDescription {
  text: JSX.Element | string;
  align?: 'center' | 'left' | 'right';
  bottomMargin?: 'large' | 'small' | 'xsmall' | 'none';
  className?: string;
}

const useStyles = createUseStyles({
  description: {
    textAlign: (props) => props.align,
    color: '#818388',
    fontSize: '15px',
    lineHeight: '24px',
    marginTop: 16,
  },
});

function Description({
  text,
  align = 'center',
  bottomMargin = 'large',
  className,
}: PropsDescription) {
  const styles = useStyles({ align, bottomMargin });
  return <div className={cx(styles.description, className)}>{text}</div>;
}

export default Description;
