import { Stores } from '../stores';

// exported functions

export function loadInvoicesToBeIssued(stores: Stores): Promise<unknown> {
  const { missions } = stores;
  if (missions.endedPaymentCycles.length > 0) Promise.resolve();

  return missions.fetchEndedPaymentCycles();
}

export function loadUnpaidInvoices(stores: Stores): Promise<unknown> {
  const { invoices } = stores;

  if (invoices.unpaidInvoices && invoices.unpaidInvoices?.length > 0)
    return Promise.resolve();
  return invoices.loadUnpaidInvoices();
}
