import { observer } from 'mobx-react';
import React, { ReactElement, useEffect, useState } from 'react';
import { useClientOnly } from 'react-client-only/index.js';
import { createUseStyles } from 'react-jss';
import type TRichTextEditor from 'react-rte';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const useStyles = createUseStyles({
  richTextEditor: {
    border: [[0], '!important'],
  },
  richTextEditorToolbar: {
    margin: [[0], '!important'],
    padding: [[0], '!important'],
    borderBottom: [[0], '!important'],
    '& input': {
      width: 'auto',
    },
  },
});

function EmailRTEInternal({
  RichTextEditor,
  value,
  onChange,
}: Props & { RichTextEditor: typeof TRichTextEditor }): ReactElement | null {
  const styles = useStyles();

  const [emailBodyRTE, setEmailBodyRTE] = useState(
    RichTextEditor.createValueFromString(value || '', 'html'),
  );

  useEffect(() => {
    // We clear the RTE if the value is empty or if different from the RTE value
    if (!value) {
      setEmailBodyRTE(RichTextEditor.createEmptyValue());
    } else if (emailBodyRTE.toString('html', {}) !== value) {
      setEmailBodyRTE(
        RichTextEditor.createValueFromString(value || '', 'html'),
      );
    }
  }, [value]);

  return (
    <RichTextEditor
      className={styles.richTextEditor}
      toolbarClassName={styles.richTextEditorToolbar}
      value={emailBodyRTE}
      onChange={(val) => {
        setEmailBodyRTE(val);
        if (val.getEditorState().getCurrentContent().hasText()) {
          onChange(val.toString('html', {}));
        } else {
          onChange('');
        }
      }}
      toolbarConfig={{
        display: [
          'BLOCK_TYPE_DROPDOWN',
          'INLINE_STYLE_BUTTONS',
          'BLOCK_TYPE_BUTTONS',
          'LINK_BUTTONS',
          'HISTORY_BUTTONS',
        ],
        INLINE_STYLE_BUTTONS: [
          {
            label: 'Bold',
            style: 'BOLD',
          },
          { label: 'Italic', style: 'ITALIC' },
          { label: 'Underline', style: 'UNDERLINE' },
          { label: 'Strikethrough', style: 'STRIKETHROUGH' },
        ],
        BLOCK_TYPE_DROPDOWN: [
          { label: 'Normal', style: 'unstyled' },
          { label: 'Heading Large', style: 'header-one' },
          { label: 'Heading Medium', style: 'header-two' },
          { label: 'Heading Small', style: 'header-three' },
        ],
        BLOCK_TYPE_BUTTONS: [
          { label: 'UL', style: 'unordered-list-item' },
          { label: 'OL', style: 'ordered-list-item' },
        ],
      }}
    />
  );
}

const EmailFormRTE = (props: Props): ReactElement | null => {
  const isOnClient = useClientOnly();
  if (!isOnClient) {
    return null;
  }
  // prettier-ignore
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const RichTextEditor: typeof TRichTextEditor =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('react-rte-17').default;
  return <EmailRTEInternal RichTextEditor={RichTextEditor} {...props} />;
};

export default observer(EmailFormRTE);
