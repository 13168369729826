import { apiUserRecommendations } from '@ateams/api';
import { useStores } from '@src/stores';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from './keys';
import {
  RecommendationObject,
  RecommendationStatus,
} from '@a_team/models/dist/RecommendationObject';

export const useQueryPendingUserRecommendations = ({
  missionId,
  onSuccess,
  enabled,
  includeUnavailable,
}: {
  missionId: string;
  onSuccess?: (data: RecommendationObject[] | null) => void;
  enabled?: boolean;
  includeUnavailable?: boolean;
}) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.teamUp.getPendingUserRecommendations(
      auth.uid || '',
      missionId,
      includeUnavailable,
    ),
    queryFn: async () => {
      return await apiUserRecommendations.getPendingUserRecommendations(
        auth,
        missionId,
        includeUnavailable,
      );
    },
    onSuccess,
    enabled,
  });
};

export const useMutationRespondToUserRecommendationRequest = () => {
  const { auth } = useStores();

  return useMutation(
    async (args: {
      recommendationId: string;
      status: RecommendationStatus;
    }) => {
      return await apiUserRecommendations.respondToUserRecommendationRequest(
        auth,
        args.recommendationId,
        args.status,
      );
    },
  );
};

export const useQueryUserRecommendationById = ({
  recommendationId,
  onSuccess,
  enabled,
}: {
  recommendationId: string;
  onSuccess?: (data: RecommendationObject | null) => void;
  enabled?: boolean;
}) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.teamUp.getUserRecommendationById(
      auth.uid || '',
      recommendationId,
    ),
    queryFn: async () => {
      return await apiUserRecommendations.getUserRecommendationById(
        auth,
        recommendationId,
      );
    },
    onSuccess,
    enabled,
  });
};
