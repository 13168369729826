import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import {
  Spacing,
  BorderColors,
  FontSizes,
  FontWeights,
  Colors,
} from '@ateams/components';

const useStyles = createUseStyles({
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: Spacing.medium,
    borderBottom: `1px solid ${BorderColors.lighter}`,
    width: '100%',
  },
  tab: {
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.regular,
    color: Colors.dark,
    cursor: 'pointer',
    paddingBottom: Spacing.xsmall,
    userSelect: 'none',
  },
  tabActive: {
    color: Colors.secondary,
    cursor: 'default',
    borderBottom: `4px solid ${Colors.secondary}`,
  },
});

export enum TranscriptTabType {
  Transcript = 0,
  Summary = 1,
}

const TranscriptTabsMapping = {
  [TranscriptTabType.Transcript]: 'Transcript',
  [TranscriptTabType.Summary]: 'Summary',
};

interface TranscriptTabsProps {
  activeTab: TranscriptTabType;
  onTabChange: (tab: TranscriptTabType) => void;
}

const TranscriptTabs: React.FC<TranscriptTabsProps> = ({
  activeTab,
  onTabChange,
}) => {
  const styles = useStyles();
  return (
    <div className={styles.tabs}>
      {Object.keys(TranscriptTabType).map((key) => {
        const tab = TranscriptTabType[key as keyof typeof TranscriptTabType];
        return (
          <div
            key={tab}
            className={
              activeTab === tab ? cx(styles.tab, styles.tabActive) : styles.tab
            }
            onClick={() => onTabChange(tab)}
          >
            {TranscriptTabsMapping[tab]}
          </div>
        );
      })}
    </div>
  );
};

export default TranscriptTabs;
