import React from 'react';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import builderIcon from './builderIcon.svg';
import builderReferralIcon from './builderReferralIcon.svg';
import companyReferralIcon from './companyReferralIcon.svg';

const useStyles = createUseStyles({
  title: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 12,
  },
  container: {
    border: '1px solid #D3D6DC',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    '& > div': {
      fontSize: '14px',
      fontWeight: 400,
      padding: '8px',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& > div img': {
      marginRight: '8px',
    },
  },
  pill: {
    backgroundColor: '#FFE9D4',
    borderRadius: '400px',
    padding: '4px 8px',
    color: '#E15100',
    fontWeight: 600,
    fontSize: '13px',
    whiteSpace: 'noWrap',
    overFlow: 'hidden',
    textOverflow: 'clip',
    marginLeft: '4px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      flexDirection: 'row',
      '& > div:not(:last-child)': {
        borderRight: '1px solid #D3D6DC',
      },
    },
  },
});

interface Props {
  builderTotal?: number;
  builderReferralTotal?: number;
  companyReferralTotal?: number;
}

const Breakdown: React.FC<Props> = ({
  builderTotal,
  builderReferralTotal,
  companyReferralTotal,
}) => {
  const styles = useStyles();

  return (
    <>
      <h4 className={styles.title}>Tokens are based on time spent building</h4>
      <div className={styles.container}>
        <div>
          <img src={builderIcon} alt="Builder icon" /> Your time on missions{' '}
          <span className={styles.pill}>{format(builderTotal)}</span>
        </div>
        <div>
          <img src={builderReferralIcon} alt="Builder referral icon" /> Your
          builder referrals{' '}
          <span className={styles.pill}>{format(builderReferralTotal)}</span>
        </div>
        <div>
          <img src={companyReferralIcon} alt="Company referral icon" /> Your
          company referrals{' '}
          <span className={styles.pill}>{format(companyReferralTotal)}</span>
        </div>
      </div>
    </>
  );
};

export default Breakdown;

const format = (hours?: number): string => {
  if (!hours) {
    return '0 hrs';
  }

  const hoursFloored = Math.floor(hours);
  return `${hoursFloored} ${hoursFloored === 1 ? 'hr' : 'hrs'}`;
};
