import {
  LocationAutocompleteV2,
  OnLocationChange,
} from '@src/components/LocationAutocompleteV2';
import {
  formatLocation,
  parseLocation,
} from '@src/components/LocationAutocompleteV2/utils';
import useLoadingState from '@src/hooks/useLoadingState';
import { useStores } from '@src/stores';
import RegistrationStep from '@src/views/Registration/RegistrationStep';
import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  select: {
    marginBottom: 0,
  },
});

const Location = (): ReactElement => {
  const styles = useStyles();
  const {
    registration: { formData, currentStep, setLocation },
  } = useStores();

  const [loading, setLoading] = useLoadingState();

  const onSubmit = () => {
    currentStep.onSubmit && setLoading(currentStep.onSubmit());
  };

  const onLocationChange: OnLocationChange = (options) => {
    if (!options) return;
    const option = Array.isArray(options) ? options[0] : options;
    const { prediction, countryShortName } = option;

    if (prediction?.structured_formatting) {
      const location = parseLocation(
        prediction.structured_formatting,
        countryShortName,
      );

      setLocation(location);
    }
  };

  return (
    <RegistrationStep
      title={'What City are You Based in?'}
      description={
        '98% of A.Team missions are remote with no location requirements. However, we host community events around the world — and this information helps us organize.'
      }
      onSubmit={onSubmit}
      canSubmit={loading !== true && currentStep.canSubmit}
      loading={loading}
    >
      <LocationAutocompleteV2
        openMenuOnClick={false}
        defaultInputValue={
          formData.location ? formatLocation(formData.location) : undefined
        }
        autocompleteOptions={{ types: ['(cities)'] }}
        onChange={onLocationChange}
        className={styles.select}
      />
    </RegistrationStep>
  );
};

export default observer(Location);
