import React, { FormEvent, ReactElement, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import RegistrationLayout from '@src/layouts/RegistrationLegacy';
import RegistrationTitle from '@src/components/RegistrationTitle';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
// import { createUseStyles } from 'react-jss';
import {
  Button as CallToActionButton,
  ButtonContainer,
} from '@ateams/components';
import PhoneNumberInput from '@src/components/Inputs/PhoneNumberInput';
import useLoadingState from '@src/hooks/useLoadingState';
import { apiMissions } from '@src/logic/services/endpoints';
import { useHistory } from 'react-router-dom';
import { CompanyRegistrationSignUpLocation } from '@src/locations';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { MissionCompanyRequest } from '@a_team/models/dist/MissionObject';
import { Divider } from '@ateams/components';
import { useAnalytics } from '@ateams/analytics/dist/platform';

// interface Props {
//   something?: never;
// }

// const useStyles = createUseStyles({});

function CompanyRegistrationTeamRequestView(): ReactElement {
  // const styles = useStyles();
  const [data, setData] = useState<MissionCompanyRequest>({
    companyName: '',
    websiteURL: '',
    fullName: '',
    role: '',
    email: '',
    phoneNumber: '',
    description: '',
  });
  const [loading, setLoading] = useLoadingState();
  const history = useHistory();
  const analytics = useAnalytics();

  const isValid = useMemo(
    () =>
      data.companyName &&
      data.websiteURL &&
      data.fullName &&
      data.role &&
      data.email &&
      data.phoneNumber &&
      data.description,
    [data],
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!isValid) return;

    setLoading(
      apiMissions.companyRequestTeam(data).then((rid) => {
        analytics.trackCompanyTeamRequested(data);

        history.push(
          CompanyRegistrationSignUpLocation(rid) +
            `?email=${encodeURIComponent(data.email)}`,
        );
      }),
    );
  };

  return (
    <RegistrationLayout title="Request a Team">
      <RegistrationTitle>Welcome to A·Team</RegistrationTitle>
      <p style={{ marginBottom: '32px' }}>
        Let's give your vision an on-demand, remote team. Build an expert team
        around you. World-class tech talent. Elastic staffing. Frictionless
        payouts. Without the risk & inflated rates.
      </p>

      <form onSubmit={handleSubmit}>
        <OutlinedInput
          value={data.companyName}
          onChange={(e): void =>
            setData({ ...data, companyName: e.target.value })
          }
          placeholder="What's the name of your company?.."
          required
          minLength={2}
          hideLength
        />
        <OutlinedInput
          value={data.websiteURL}
          onChange={(e): void =>
            setData({ ...data, websiteURL: e.target.value })
          }
          required
          placeholder="And your company's website?.."
          minLength={4}
          hideLength
        />

        <Divider margin="compact" />

        <OutlinedInput
          value={data.fullName}
          onChange={(e): void => setData({ ...data, fullName: e.target.value })}
          required
          placeholder="Who's the contact person?"
          minLength={2}
          hideLength
        />
        <OutlinedInput
          value={data.role}
          onChange={(e): void => setData({ ...data, role: e.target.value })}
          required
          placeholder="What's the contact person's role?"
          minLength={2}
          hideLength
        />
        <OutlinedInput
          type="email"
          value={data.email}
          onChange={(e): void => setData({ ...data, email: e.target.value })}
          required
          placeholder="Best email address for contact?"
          minLength={4}
          hideLength
        />
        <PhoneNumberInput
          value={data.phoneNumber}
          onChange={(phoneNumber): void => setData({ ...data, phoneNumber })}
        />

        <Divider margin="compact" />

        <OutlinedInput
          value={data.description}
          onChange={(e): void =>
            setData({ ...data, description: e.target.value })
          }
          required
          placeholder="Give us some context on what you need your A-Team to work on..."
          multiline
          minRows={5}
          minLength={2}
          hideLength
        />

        <OutlinedInput
          value={data.notes || ''}
          onChange={(e): void =>
            setData({ ...data, notes: e.target.value || undefined })
          }
          placeholder="Have any questions or comments?"
        />

        <ButtonContainer align="right">
          <CallToActionButton type="submit" width="auto" disabled={!isValid}>
            Request Team
          </CallToActionButton>
        </ButtonContainer>
      </form>
      <LoadingIndicator loading={loading} />
    </RegistrationLayout>
  );
}

export default observer(CompanyRegistrationTeamRequestView);
