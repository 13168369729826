import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import { inputStyles } from '@src/components/Inputs/InlineInput/styles';
import {
  AvailabilitySummaryObject,
  AvailableType,
  ReminderPeriod,
} from '@a_team/models/dist/AvailabilityObject';
import { add, format, parseISO } from 'date-fns';
import {
  AvailabilityReminderLabels,
  AvailabilityTypeLabels,
} from '@src/stores/NotificationPreferencesUser';
import { DateInput } from '@src/components/DateInput';
import { StatusSelect } from '@src/views/Profile/Main/Availability/StatusSelect';
import { HoursInput } from '@src/views/Profile/Main/Availability/HoursInput';
import { enumKeys } from '@src/helpers/types';
import { DateISOString } from '@a_team/models/dist/misc';
import DropdownInput from '@src/components/Inputs/DropdownInput';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import Checkbox from '@src/components/Checkbox';
import { NotesInput } from './NotesInput';
import { NotificationPreferencesLocationNoToken } from '@src/locations';
import { Link } from 'react-router-dom';
import { WorkingHours } from '@src/views/Profile/Sidebar/WorkingHours';
import { WorkingHoursSchema } from '@a_team/models/dist/WorkingHoursObject';
import TimezoneObject from '@a_team/models/dist/TimezoneObject';
import { getHoursFromMinutes } from '@src/views/Profile/Sidebar/WorkingHours/utils';
import { Breakpoints, Colors, Tag } from '@ateams/components';
import { tagEditedYellowStyle } from '@src/common-styles/tag-edited';
import { toJS } from 'mobx';
import cx from 'classnames';

export enum AvailabilityMode {
  Block = 'Block',
  Inline = 'Inline',
}

export const MAX_AVAILABILITY_HOURS = 80;

interface Props {
  withStatusSelect?: boolean;
  withWorkingHours?: boolean;
  mode?: AvailabilityMode;
  availability?: AvailabilitySummaryObject;
  workingHours?: WorkingHoursSchema;
  applicationAvailability?: {
    startDate: DateISOString;
    hoursPerWeek: number;
    notes?: string;
  };
  estimatedAvailability?: number;
  keepNotifications?: boolean;
  onKeepNotificationsChange?: (keepNotifications: boolean) => void;
  keepNotificationsPausedEndDate?: string;
  reminderPeriod?: ReminderPeriod;
  onChange(availability: AvailabilitySummaryObject): void;
  onWorkingHoursChange?(workingHours: WorkingHoursSchema): void;
  profileTimezone?: TimezoneObject;
  onReminderPeriodChange?: (period: ReminderPeriod) => void;
  disabled?: boolean;
  readOnly?: boolean;
  availabilityError?: boolean;
  availabilityHoursError?: boolean;
  popperBelow?: boolean;
  promptFontSize?: number;
  disableKeepNotificationsIfPaused?: boolean;
  profAvailabilityData?: AvailabilitySummaryObject;
  isApplicationAvailabilityEdited?: boolean;
  isApplicationAvailabilityNotesEdited?: boolean;
  isApplicationWorkingHoursEdited?: boolean;
}

const useStyles = createUseStyles({
  container: {
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
    fontSize: 17,
    lineHeight: '33px',
  },
  inputGroup: {
    display: (props?: Props): string =>
      props?.mode === (AvailabilityMode.Block as AvailabilityMode)
        ? 'block'
        : 'flex',
    width: (props?: Props): string =>
      props?.mode === (AvailabilityMode.Block as AvailabilityMode)
        ? '100%'
        : 'inherit',
    alignItems: 'center',
  },
  break: {
    flexBasis: '100%',
    height: 0,
    marginTop: 8,
  },
  prompt: {
    flexShrink: 0,
    marginRight: '8px',
    fontSize: (props?: Props): number => props?.promptFontSize ?? 17,
  },
  statusSelect: {
    background: 'none',
    border: 'none',
    ...inputStyles,
    padding: '0 8px',
    marginBottom: 0,
    paddingBottom: 0,
    marginRight: 8,
  },
  select: {
    background: 'none',
    border: 'none',
    ...inputStyles,
    padding: '0 8px',
    marginBottom: 0,
    paddingBottom: 0,
    marginRight: 8,
  },
  checkbox: {
    marginRight: 8,
  },
  sectionLabel: {
    fontSize: 14,
    lineHeight: '2.6em',
    color: '#62646A',
    display: 'block',
    marginTop: 12,
  },
  blockDatePickerWrapper: {
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '0.5em',
  },
  blockDatePicker: {
    padding: '1em',
    minHeight: 56,
    width: '100%',
    lineHeight: 'initial',
    background: 'none',
    border: 'none',
  },
  blockHourInput: {
    maxWidth: 150,
  },
  workingHoursContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 16,
  },
  notesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 5,
  },
  smallText: {
    margin: 0,
    fontSize: 12,
    color: '#999',
  },
  tagEdited: {
    ...tagEditedYellowStyle,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    workingHoursContainer: {
      marginTop: 5,
    },
  },
  estimatedAvailability: {
    color: Colors.danger,
  },
});

const Availability = (props: Props): ReactElement | null => {
  const {
    withStatusSelect = true,
    mode = AvailabilityMode.Inline,
    availability,
    applicationAvailability,
    estimatedAvailability,
    keepNotifications,
    onKeepNotificationsChange,
    keepNotificationsPausedEndDate,
    reminderPeriod,
    onChange,
    onReminderPeriodChange,
    withWorkingHours,
    workingHours,
    onWorkingHoursChange,
    profileTimezone,
    disabled,
    readOnly,
    availabilityError,
    availabilityHoursError,
    popperBelow,
    profAvailabilityData,
    disableKeepNotificationsIfPaused = true,
    isApplicationAvailabilityEdited,
    isApplicationAvailabilityNotesEdited,
    isApplicationWorkingHoursEdited,
  } = props;
  const styles = useStyles(props);
  const [hours, setHours] = useState<number | undefined>(
    availability?.weeklyHoursAvailable,
  );
  const [fromDate, setFromDate] = useState<DateISOString | undefined>(
    availability?.availableFrom,
  );
  const [notes, setNotes] = useState<string | undefined>(availability?.notes);

  useEffect(() => {
    // Handles value update as user types
    availability?.weeklyHoursAvailable &&
      hours !== availability?.weeklyHoursAvailable &&
      setHours(availability.weeklyHoursAvailable);
  }, [availability?.weeklyHoursAvailable]);

  useEffect(() => {
    // Sets initial value on hours
    if (
      !availability?.weeklyHoursAvailable ||
      availability?.weeklyHoursAvailable === 0
    ) {
      setHours(profAvailabilityData?.weeklyHoursAvailable);
    }
  }, []);

  useEffect(() => {
    // Sets initial value on fromDate
    if (profAvailabilityData?.availableFrom) {
      setFromDate(parseISO(profAvailabilityData?.availableFrom));
    }
  }, []);

  useEffect(() => {
    // Handles Date value updates as user type
    availability?.availableFrom &&
      fromDate !== availability?.availableFrom &&
      setFromDate(availability.availableFrom);
  }, [availability?.availableFrom]);

  useEffect(() => {
    availability?.notes &&
      notes !== availability?.notes &&
      setNotes(availability.notes);
  }, [availability?.notes]);

  useEffect(() => {
    if (hours === availability?.weeklyHoursAvailable) return;
    onChange({
      ...availability,
      ...{
        type: availability?.type || AvailableType.UnknownAvailability,
        weeklyHoursAvailable: hours,
        notes: notes,
      },
    });
  }, [hours]);

  useEffect(() => {
    if (fromDate === availability?.availableFrom) return;

    onChange({
      ...availability,
      ...{
        type: availability?.type || AvailableType.UnknownAvailability,
        availableFrom: fromDate
          ? new Date(fromDate).toISOString()
          : new Date().toISOString(),
        notes: notes,
      },
    });
  }, [fromDate]);

  useEffect(() => {
    if (notes === availability?.notes) return;

    onChange({
      ...availability,
      ...{
        type: availability?.type || AvailableType.UnknownAvailability,
        notes: notes,
      },
    });
  }, [notes]);

  const showHoursInput = useMemo(() => {
    return (
      availability?.type !== AvailableType.NotAvailable &&
      availability?.type !== AvailableType.UnknownAvailability
    );
  }, [availability?.type]);

  const showDateInput = useMemo(() => {
    return availability?.type === AvailableType.FutureDate;
  }, [availability?.type]);

  const showReminder = useMemo(() => {
    return availability?.type === AvailableType.NotAvailable;
  }, [availability?.type]);

  const isValidHours = (text: string) => {
    return !isNaN(Number(text)) && Number(text) <= MAX_AVAILABILITY_HOURS;
  };

  const onHoursChange = (value: string) => {
    if (!isValidHours(value)) return;
    setHours(Number(value));
  };

  const onNotesChange = useCallback((value: string) => {
    setNotes(value);
  }, []);

  const onReminderChange = (label: string) => {
    const period = enumKeys(AvailabilityReminderLabels).find(
      (key) => key === label,
    );
    if (period && onReminderPeriodChange) {
      onReminderPeriodChange(period as ReminderPeriod);
    }
  };

  const onStatusChange = (label: string) => {
    const status = enumKeys(AvailabilityTypeLabels).find(
      (key) => AvailabilityTypeLabels[key] === label,
    );
    onChange({
      ...availability,
      type: (status as AvailableType) || AvailableType.UnknownAvailability,
    });
  };

  const isBlock = mode === (AvailabilityMode.Block as AvailabilityMode);

  const formattedEstimatedAvailability: string = useMemo(() => {
    if (estimatedAvailability !== undefined) {
      return `${estimatedAvailability} hours weekly`;
    }
    return '';
  }, []);

  if (withStatusSelect) {
    if (readOnly) return null;

    return (
      <div className={styles.container}>
        <div className={styles.inputGroup}>
          {isBlock ? (
            <div className={styles.sectionLabel}> Availability </div>
          ) : (
            <div className={styles.prompt}>My availability status is:</div>
          )}
          <StatusSelect
            error={availabilityError}
            onChange={(e) => onStatusChange(e.target.value)}
            className={isBlock ? undefined : styles.statusSelect}
            width={isBlock ? 'default' : 'fixed'}
            noBorder={!isBlock}
            value={
              availability?.type === AvailableType.UnknownAvailability
                ? ''
                : availability
                ? AvailabilityTypeLabels[availability.type]
                : ''
            }
          />
        </div>
        {showDateInput && (
          <>
            {isBlock ? (
              <div className={styles.sectionLabel}> Starting at </div>
            ) : (
              <span>-</span>
            )}
            <DateInput
              minDate={add(new Date(), { days: 1 })}
              popperPlacement={isBlock || popperBelow ? undefined : 'top-end'}
              className={isBlock ? styles.blockDatePicker : undefined}
              wrapperClassName={
                isBlock ? styles.blockDatePickerWrapper : undefined
              }
              selected={fromDate ? new Date(fromDate) : new Date()}
              onChange={(date) =>
                setFromDate(date ? (date as Date).toISOString() : undefined)
              }
              disabled={disabled}
            />
          </>
        )}
        {estimatedAvailability !== undefined && (
          <div className={styles.inputGroup}>
            <div className={styles.sectionLabel}> Estimated Availability </div>
            {estimatedAvailability <
            (availability?.weeklyHoursAvailable || 40) ? (
              <div
                className={cx(styles.userDetail, styles.estimatedAvailability)}
              >
                {formattedEstimatedAvailability}
              </div>
            ) : (
              <div className={styles.userDetail}>
                {formattedEstimatedAvailability}
              </div>
            )}
          </div>
        )}
        {showHoursInput && (
          <>
            {isBlock ? (
              <div>
                <div className={styles.sectionLabel}> Available Hours </div>
                <OutlinedInput
                  value={hours || ''}
                  onChange={(e) => onHoursChange(e.target.value)}
                  endAdornment={<span>hr/wk</span>}
                  className={styles.blockHourInput}
                  fullWidth={false}
                  placeholder={'XX'}
                  margin="none"
                />
              </div>
            ) : (
              <>
                <span>for</span>
                <HoursInput
                  disabled={disabled}
                  error={availabilityHoursError}
                  value={hours || ''}
                  onChange={(e) => onHoursChange(e.target.value)}
                  tooltip={
                    "Please enter the number of hours you'll be available to work on Missions"
                  }
                />
                <div className={styles.prompt}>hours per week</div>
              </>
            )}
          </>
        )}
        <div className={styles.notesContainer}>
          <label htmlFor="notes" className={styles.prompt}>
            Note on availability:
          </label>
          <NotesInput
            readonly={readOnly}
            disabled={disabled}
            text={notes || ''}
            onChange={onNotesChange}
          />
        </div>
        {showReminder && (
          <>
            <div className={styles.inputGroup}>
              {isBlock ? (
                <div className={styles.sectionLabel}>
                  {' '}
                  Send reminder email in{' '}
                </div>
              ) : (
                <div className={styles.prompt}>
                  Remind me to update my availability in
                </div>
              )}
              <DropdownInput
                margin={'none'}
                noBorder={!isBlock}
                placeholder={'Select...'}
                className={isBlock ? undefined : styles.select}
                value={
                  reminderPeriod
                    ? AvailabilityReminderLabels[reminderPeriod]
                    : ''
                }
                onChange={(e) => {
                  const periodKey = enumKeys(AvailabilityReminderLabels).find(
                    (key) => AvailabilityReminderLabels[key] === e.target.value,
                  );
                  periodKey && onReminderChange(periodKey as ReminderPeriod);
                }}
              >
                {enumKeys(AvailabilityReminderLabels).map((key) => (
                  <option key={key}>{AvailabilityReminderLabels[key]}</option>
                ))}
              </DropdownInput>
            </div>
            <div className={styles.break} />
            <div className={styles.inputGroup}>
              <Checkbox
                disabled={
                  disableKeepNotificationsIfPaused &&
                  !!keepNotificationsPausedEndDate
                }
                id="keepNotifications"
                className={styles.checkbox}
                checked={keepNotifications}
                onChange={(e) =>
                  onKeepNotificationsChange &&
                  onKeepNotificationsChange(e.target.checked)
                }
              />
              <label className={styles.prompt} htmlFor="keepNotifications">
                Continue to send me emails about new recommended mission
                postings.
              </label>
            </div>
            <div className={styles.break} />
            {!!keepNotificationsPausedEndDate && (
              <p className={styles.smallText}>
                Your notifications are paused until{' '}
                {keepNotificationsPausedEndDate}.{' '}
                {disableKeepNotificationsIfPaused ? (
                  <>
                    To change this, go to your{' '}
                    <Link to={NotificationPreferencesLocationNoToken}>
                      notification preferences
                    </Link>
                  </>
                ) : (
                  <></>
                )}
              </p>
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.prompt}>I’ll be available from</div>
      {readOnly ? (
        <div>
          {format(
            new Date(
              applicationAvailability
                ? applicationAvailability.startDate
                : availability?.availableFrom || '',
            ),
            'MMMM dd, yyyy',
          )}
        </div>
      ) : (
        <DateInput
          selected={fromDate ? new Date(fromDate) : new Date()}
          onChange={(date) => setFromDate((date as Date).toISOString())}
          disabled={disabled}
          minDate={new Date()}
        />
      )}
      <div style={{ marginLeft: '8px' }}>for</div>
      {readOnly ? (
        <div style={{ marginLeft: '8px', marginRight: '8px' }}>
          {availability?.weeklyHoursAvailable}
        </div>
      ) : (
        <HoursInput
          disabled={disabled}
          error={availabilityHoursError}
          value={
            applicationAvailability
              ? applicationAvailability.hoursPerWeek
              : hours || ''
          }
          onChange={(e) => onHoursChange(e.target.value)}
        />
      )}
      <div className={styles.prompt}>hours per week</div>
      {isApplicationAvailabilityEdited ? (
        <Tag className={styles.tagEdited}>Edited</Tag>
      ) : null}
      {withWorkingHours && (
        <div className={styles.workingHoursContainer}>
          {workingHours && (
            <div className={styles.prompt}>My working hours</div>
          )}
          {readOnly ? (
            <div style={{ marginLeft: '8px', marginRight: '8px' }}>
              {workingHours && workingHours.daily?.length && (
                <span>
                  {' from '}
                  {workingHours.daily?.map((daily, index) => {
                    return (
                      <span key={index}>
                        {getHoursFromMinutes(daily.startTime)} to{' '}
                        {getHoursFromMinutes(daily.endTime)}{' '}
                        {index === 0 && ' or '}
                      </span>
                    );
                  })}{' '}
                  in (UTC{workingHours.utcOffset}) {workingHours.name}
                </span>
              )}{' '}
              {isApplicationWorkingHoursEdited ? (
                <Tag
                  className={styles.tagEdited}
                  style={{
                    marginLeft: '4px',
                  }}
                >
                  Edited
                </Tag>
              ) : null}
            </div>
          ) : (
            <WorkingHours
              defaultValue={toJS(workingHours)}
              defaultTimezone={profileTimezone}
              selectInputStyle={{
                borderTop: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderBottom: '1px #ccc dashed',
                backgroundColor: '#f7f7f7',
              }}
              hideSideBar={true}
              isClearable={false}
              onChange={(val) => {
                onWorkingHoursChange && val && onWorkingHoursChange(val);
              }}
            />
          )}
        </div>
      )}
      <div className={styles.notesContainer} style={{ marginTop: 15 }}>
        <label htmlFor="notes" className={styles.prompt}>
          Note on availability:
        </label>
        <NotesInput
          readonly={readOnly}
          disabled={disabled}
          text={
            applicationAvailability?.notes
              ? applicationAvailability.notes
              : notes
          }
          onChange={onNotesChange}
          isApplicationAvailabilityNotesEdited={
            isApplicationAvailabilityNotesEdited
          }
        />
      </div>
    </div>
  );
};

export default Availability;
