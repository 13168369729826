import React from 'react';
import { Card } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import InfoItem from './InfoItem';

interface Props {
  className?: string;
}

const useStyles = createUseStyles({
  title: {
    marginTop: 0,
    fontWeight: 700,
    fontSize: '16px',
    marginBottom: '16px',
  },
});

const TokenInfo: React.FC<Props> = ({ className }) => {
  const styles = useStyles();

  return (
    <Card className={className}>
      <h3 className={styles.title}>Ways to earn tokens</h3>
      <InfoItem value="10X" description="For every hour you build" />
      <InfoItem
        value="2X"
        description="For every hour your builder referrals build"
      />
      <InfoItem
        value="6X"
        description="For every hour your company referrals build"
      />
    </Card>
  );
};

export default TokenInfo;
