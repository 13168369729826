import React, { CSSProperties, ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import CompanyLogoFallback from '../CompanyLogoFallback';

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    fontSize: 15,
    lineHeight: '24px',
    color: '#222222',
    marginLeft: 8,
  },
});

interface Props {
  className?: string;
  src?: string;
  alt?: string;
  size?: number;
  label?: string;
  containerStyle?: CSSProperties;
}

export default function CompanyAvatar(props: Props): ReactElement {
  const { className, src, label, alt, size, containerStyle } = props;
  const styles = useStyles(props);

  const finalSize = size || 40;

  return (
    <>
      <div className={styles.container} style={{ ...containerStyle }}>
        <CompanyLogoFallback
          className={className}
          logoUrl={src}
          borderRadius={8}
          size={finalSize}
          alt={alt}
        />
      </div>
      {label && <div className={styles.label}>{label}</div>}
    </>
  );
}
