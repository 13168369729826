import { action, observable } from 'mobx';
import { Stores } from '@src/stores/index';
import { apiPayments } from '@ateams/api';
import { PaymentsSetupDetails } from '@ateams/api/dist/endpoints/Payments';

export interface PaymentsStoreData {
  setupDetails?: PaymentsSetupDetails;
}

export default class PaymentsStore implements PaymentsStoreData {
  @observable setupDetails: PaymentsStoreData['setupDetails'];

  private rootStore: Stores;

  public constructor(rootStore: Stores, initialState?: PaymentsStoreData) {
    if (initialState) {
      this.setupDetails = initialState.setupDetails;
    }

    this.rootStore = rootStore;
  }

  public serialize(): PaymentsStoreData {
    return {
      setupDetails: this.setupDetails,
    };
  }

  @action private setSetupDetails = (
    setupDetails: PaymentsSetupDetails,
  ): void => {
    this.setupDetails = setupDetails;
  };

  getSetupDetails = async (): Promise<void> => {
    if (this.setupDetails) return;

    const paymentsData = await apiPayments.getSetupDetails(this.rootStore.auth);
    this.setSetupDetails(paymentsData);
  };
}
