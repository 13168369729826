import React, { ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import SectionHeading from '@src/components/SectionHeading';

interface Props {
  title: string;
  optional?: boolean;
  description?: string;
  children: ReactNode;
}

const useStyles = createUseStyles({
  root: {
    marginTop: '64px',
  },
});

export default function FormSection(props: Props): ReactElement {
  const { title, optional, description, children } = props;
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <SectionHeading appendix={optional && '(Optional)'}>
        {title}
      </SectionHeading>

      {!!description && <p>{description}</p>}

      {children}
    </div>
  );
}
