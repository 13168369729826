import { Checkbox, DatePicker } from '@a_team/ui-components';
import { format } from 'date-fns';
import React from 'react';
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { FormValues } from '.';
import { DateISOString } from '@a_team/models/dist/misc';

interface DurationProps {
  startDate?: DateISOString;
  endDate?: DateISOString;
  isOngoing: boolean;
  setValue: UseFormSetValue<FormValues>;
  trigger: UseFormTrigger<FormValues>;
  setStillWorking: (bool: boolean) => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  datePickersContainer: {
    display: 'flex',
    gap: 16,
    alignItems: 'baseline',
  },
  label: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  datePickerContainer: {
    position: 'relative',
    width: '140px',
    '& input': {
      width: '140px',
      maxWidth: '100%',
    },
  },
  calendar: {
    minWidth: '296px !important',
    position: 'absolute',
    zIndex: 999999,
  },
  stillWorkHere: {
    marginTop: 10,
  },
});

const Duration = ({
  startDate,
  endDate,
  isOngoing,
  setValue,
  trigger,
  setStillWorking,
}: DurationProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <label className={styles.label}>Duration</label>
      <div className={styles.datePickersContainer}>
        <div className={styles.datePickerContainer}>
          <DatePicker
            placeholder="Date"
            style={{
              width: '140px',
              maxWidth: '100%',
              borderColor: '#DADADC',
              paddingTop: '8px !important',
              paddingBottom: '8px !important',
            }}
            calendarOptions={{
              maxDetail: 'year',
              className: styles.calendar,
              maxDate: endDate ? new Date(endDate) : undefined,
            }}
            dateFormatter={(date) => format(date, 'MMM, yyyy')}
            defaultDate={startDate ? new Date(startDate) : undefined}
            onChange={(date) => {
              setValue('startDate', date, {
                shouldDirty: true,
              });
              trigger('startDate');
            }}
          />
        </div>
        <div>to</div>
        <div className={styles.datePickerContainer}>
          {isOngoing ? (
            <DatePicker
              key="date-picker-present"
              placeholder="Present"
              style={{
                width: '140px',
                maxWidth: '100%',
                borderColor: '#DADADC',
                paddingTop: '8px !important',
                paddingBottom: '8px !important',
              }}
              defaultValue=""
              disabled
            />
          ) : (
            <DatePicker
              key="date-picker-end-date"
              placeholder="Date"
              style={{
                width: '140px',
                maxWidth: '100%',
                borderColor: '#DADADC',
                paddingTop: '8px !important',
                paddingBottom: '8px !important',
              }}
              calendarOptions={{
                maxDetail: 'year',
                className: styles.calendar,
                minDate: startDate ? new Date(startDate) : undefined,
              }}
              dateFormatter={(date) => format(date, 'MMM, yyyy')}
              defaultDate={endDate ? new Date(endDate) : undefined}
              onChange={(date) => {
                setValue('endDate', date, {
                  shouldDirty: true,
                });
                setValue('isOngoing', false);
              }}
              disabled={isOngoing || !startDate}
            />
          )}
        </div>
      </div>
      <div className={styles.stillWorkHere}>
        <Checkbox
          onChange={() => {
            if (!isOngoing) {
              setValue('endDate', undefined, {
                shouldDirty: true,
              });
            }
            setStillWorking(!isOngoing);
          }}
          label="I still work here"
          checked={isOngoing}
        />
      </div>
    </div>
  );
};

export default Duration;
