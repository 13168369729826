import React, { useContext, useState } from 'react';
import cx from 'classnames';
import { Button } from '@a_team/ui-components';
import { useCommonStyles } from '../commonStyles';
import { DefaultOption } from './OptionsGroup/DefaultOption';
import { NoCalendarReason } from '@a_team/models/dist/UserObject';
import { ReasonsModalContext } from './context';
import { OtherOption } from './OptionsGroup/OtherOption';

interface ReasonsProps {
  onSelectReason: (reason?: NoCalendarReason, reasonText?: string) => void;
  onClose: () => void;
}

export const Reasons = (props: ReasonsProps) => {
  const styles = useCommonStyles();
  const [reason, setReason] = useState<NoCalendarReason>();
  const [otherReason, setOtherReason] = useState('');
  const { canClose } = useContext(ReasonsModalContext);

  const handleContinue = () => {
    props.onSelectReason(reason, otherReason);
  };

  return (
    <>
      <div className={styles.hero}>
        <h4 className={styles.modalTitle}>
          Share why you don’t want to connect
        </h4>
        <p className={cx(styles.heroCopy, 'lessPadding')}>
          Learn more about interview scheduling on A.Team
        </p>
      </div>
      <div className={styles.optionsWrap}>
        <DefaultOption
          option={NoCalendarReason.ClientPrivacy}
          label={`I don’t want clients to see my events`}
          checked={reason === NoCalendarReason.ClientPrivacy}
          onClick={setReason}
        />
        <DefaultOption
          option={NoCalendarReason.CustomAvailability}
          label={`I want to choose the times I’m available`}
          checked={reason === NoCalendarReason.CustomAvailability}
          onClick={setReason}
        />
        <DefaultOption
          option={NoCalendarReason.PrivacyConcern}
          label={`I have privacy concerns`}
          checked={reason === NoCalendarReason.PrivacyConcern}
          onClick={setReason}
        />
        <DefaultOption
          option={NoCalendarReason.DataSecurity}
          label={`I’m concerned about data security`}
          checked={reason === NoCalendarReason.DataSecurity}
          onClick={setReason}
        />
        <OtherOption
          option={NoCalendarReason.Other}
          placeholder="Please enter the reason"
          checked={reason === NoCalendarReason.Other}
          onClick={setReason}
          onOtherValue={setOtherReason}
        />
      </div>
      <div className={styles.actions}>
        {canClose && (
          <Button
            variant="secondary"
            size="sm"
            className={styles.closeBtn}
            onClick={props.onClose}
          >
            Close
          </Button>
        )}
        <Button
          disabled={
            !reason || (reason === NoCalendarReason.Other && otherReason === '')
          }
          variant="main"
          size="sm"
          className={styles.primaryButton}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </div>
    </>
  );
};
