import { BasicUserObject } from '@a_team/models/dist/UserObject';
import UserTooltip from '@src/components/UserTooltip/UserTooltip';
import { ProfileLocation } from '@src/locations';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import Image from '@src/components/Image';

interface TeamMemberProps {
  user: BasicUserObject;
  roleTitle?: string;
}

const useStyles = createUseStyles({
  teamMemberAvatar: {
    margin: '-4px',
    height: 34,
    width: 34,
    borderRadius: '50%',
    border: '2px solid white',
  },
  container: {
    position: 'relative',
    display: 'inline-block', // or 'inline-flex' if you prefer
  },
  tooltip: {
    position: 'absolute',
    top: '100%', // adjust as needed
    left: '50%', // adjust as needed
    transform: 'translateX(-50%)', // centers the tooltip
    zIndex: 10,
  },
});

const TeamMember = ({ user, roleTitle }: TeamMemberProps): JSX.Element => {
  const styles = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);

  const userProfilePictureURL =
    user.username != null ? user.profilePictureURL : undefined;

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <Link to={user.username ? ProfileLocation(user.username) : ''}>
        {userProfilePictureURL && (
          <Image
            className={styles.teamMemberAvatar}
            src={userProfilePictureURL}
            alt="Team Member Avatar"
            sizes="50px"
          />
        )}
      </Link>
      {showTooltip && (
        <div className={styles.tooltip}>
          <UserTooltip
            fullName={user.fullName}
            profilePictureURL={user.profilePictureURL}
            username={user.username}
            title={roleTitle}
          />
        </div>
      )}
    </div>
  );
};

export default TeamMember;
