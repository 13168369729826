import { logger } from '@sentry/utils';
import striptags from 'striptags';

export const getHTMLStringTextLength = (html = ''): number => {
  try {
    const sanitized = striptags(html);

    return sanitized.length;
  } catch (error) {
    logger.error(error);
    return 0;
  }
};

export const validateStringLength = (
  string = '',
  maxLength: number,
  minLength = 1,
): boolean => {
  const length = getHTMLStringTextLength(string);
  return length >= minLength && length <= maxLength;
};
