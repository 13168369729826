import React, { CSSProperties, ReactElement } from 'react';
import { Breakpoints, TextColors } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import EyeClosedIcon from './EyeClosedIcon';
import UndoIcon from './UndoIcon';

interface Props {
  type: string;
  size: 'xsmall' | 'exact' | 'small' | 'medium' | 'large' | 'xlarge';
  style: CSSProperties;
  color: string;
  defaultOnDesktop?: boolean;
}

export const SizeMap: { [key: string]: string } = {
  xsmall: '0.8em',
  exact: '1em',
  small: '1.5em',
  medium: '1.8em',
  large: '2.5em',
  xlarge: '4em',
};

const useStyles = createUseStyles<Props>({
  iconContainer: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: (props) => SizeMap[props.size || 'small'],
    height: 'auto',
  },
  iconFill: {
    fill: (props: Props) => props.color,
    [`@media (min-width: ${Breakpoints.sm}px)`]: {
      fill: (props: Props) =>
        props.defaultOnDesktop ? TextColors.regular : props.color,
    },
  },
  iconStroke: {
    stroke: (props) => props.color,
    [`@media (min-width: ${Breakpoints.sm}px)`]: {
      stroke: (props: Props) =>
        props.defaultOnDesktop ? TextColors.regular : props.color,
    },
  },
});

export default function IconWithColor(props: Props): ReactElement {
  const styles = useStyles(props);
  const { type, style } = props;

  const getIcon = (iconType: string): ReactElement => {
    const iconProps = {
      iconFill: styles.iconFill,
      iconStroke: styles.iconStroke,
    };

    switch (iconType) {
      case 'EyeClosed':
        return <EyeClosedIcon {...iconProps} />;
      case 'Undo':
        return <UndoIcon {...iconProps} />;
      default:
        return <></>;
    }
  };

  return (
    <div style={style} className={styles.iconContainer}>
      {getIcon(type)}
    </div>
  );
}
