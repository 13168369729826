import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  VettingDashboardCompletedReviewsLocation,
  VettingDashboardGuidebookLocation,
  VettingDashboardMembersLocation,
  VettingDashboardMembersLocationV2,
  VettingDashboardMemberToVetLocation,
  VettingDashboardPendingInterviewsLocation,
  VettingDashboardSelectionTeamOnboardingPlanLocation,
} from '@src/locations';
import MainLayout, { MainLayoutProps } from '@src/layouts/Main';
import SectionHeading from '@src/components/SectionHeading';

import TabButtons, { TabNavLink } from '@src/components/TabButtons';
import { useStores } from '@src/stores';
import { createUseStyles } from 'react-jss';
import TextButton from '@src/components/TextButton';

const IS_ADMIN_MODE_PARAM = 'is-admin-mode';

export const addAdminViewSearchParam = (
  path: string,
  isAdminView: boolean,
): string => {
  if (!isAdminView) {
    return path;
  }

  return `${path}?${IS_ADMIN_MODE_PARAM}=true`;
};

export const useAdminView = () => {
  const { vetters, vettingProcesses } = useStores();
  const history = useHistory();

  const isAdminView = useMemo(() => {
    return Boolean(
      new URLSearchParams(history.location.search).get(IS_ADMIN_MODE_PARAM),
    );
  }, [history.location.search]);

  useEffect(() => {
    vettingProcesses.setAdminView(isAdminView);
    vetters.setAdminView(isAdminView);
  }, [isAdminView]);

  const toggleAdminMode = () => {
    const params = new URLSearchParams(history.location.pathname);

    if (isAdminView) {
      params.delete(IS_ADMIN_MODE_PARAM);
    } else {
      params.append(IS_ADMIN_MODE_PARAM, 'true');
    }

    history.replace({
      search: params.toString(),
    });
  };

  return {
    isAdminView,
    toggleAdminMode,
  };
};

export const useAdminModeGuard = () => {
  const { auth } = useStores();
  const { isAdminView, toggleAdminMode } = useAdminView();

  useEffect(() => {
    if (!auth.isAdmin && isAdminView) {
      toggleAdminMode();
    }
  }, [isAdminView]);
};

const useStyles = createUseStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '21px',
  },
  title: {
    marginBottom: 0,
  },
  adminViewLabel: {},
  smallDivider: {
    borderRight: '1px solid #C0C0C0',
    height: '16px',
    marginLeft: '12px',
    marginRight: '12px',
  },
  tabButtons: {
    zIndex: 0,
    boxShadow: 'none',
    marginLeft: -32,
  },
  tabButtonsRoot: {
    zIndex: 0,
  },
});

const VettingDashboardLayout = (props: MainLayoutProps) => {
  const { auth } = useStores();
  const { title, ...leftProps } = props;
  const styles = useStyles();
  const { isAdminView, toggleAdminMode } = useAdminView();

  const tabs = [
    {
      label: 'Members to Review',
      to: VettingDashboardMemberToVetLocation,
    },
    {
      label: `Upcoming Interviews`,
      to: VettingDashboardPendingInterviewsLocation,
    },
    {
      label: `Completed Reviews`,
      to: VettingDashboardCompletedReviewsLocation,
    },
    {
      label: 'Guidebook',
      to: VettingDashboardGuidebookLocation,
    },
    {
      label: 'SelectionTeam Members',
      to:
        auth.withNewEvaluation && auth.isAdmin
          ? VettingDashboardMembersLocationV2
          : VettingDashboardMembersLocation,
    },
  ].map(({ label, to }) => ({
    label,
    to: addAdminViewSearchParam(to, isAdminView),
  }));

  if (auth.isAdmin) {
    tabs.push({
      label: 'ST Onboarding',
      to: VettingDashboardSelectionTeamOnboardingPlanLocation,
    });
  }

  return (
    <MainLayout
      title={title || 'Selection Team'}
      topNav={
        <div
          style={{
            padding: '40px 56px 0',
            boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div className={styles.titleContainer}>
            <SectionHeading isFirst className={styles.title}>
              SelectionTeam HQ
            </SectionHeading>
            {auth.isAdmin && (
              <>
                <div className={styles.smallDivider} />
                <TextButton
                  highlight
                  className={styles.adminViewLabel}
                  onClick={toggleAdminMode}
                >
                  {isAdminView
                    ? 'Go to SelectionTeam user view'
                    : 'Go to Admin view'}
                </TextButton>
              </>
            )}
          </div>
          <p>
            Everything you need to help A.Team shape its community of
            accomplished product builders is right here, in the SelectionTeam HQ
            − where you can review members, schedule interviews, and provide
            feedback.
          </p>
          <TabButtons
            className={styles.tabButtons}
            rootClassName={styles.tabButtonsRoot}
          >
            {tabs.map((tab, i) => (
              <TabNavLink key={i} to={tab.to}>
                <>{tab.label}</>
              </TabNavLink>
            ))}
          </TabButtons>
        </div>
      }
      {...leftProps}
    />
  );
};

export default observer(VettingDashboardLayout);
