import FileDropArea from '@src/components/FileDropArea';
import React, { FC, ReactElement } from 'react';
import { FileRejection, FileWithPath } from 'react-dropzone';
import * as uploadcareApi from '@src/logic/uploadcare';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import { useAnalytics, UserEvent } from '@ateams/analytics/dist/platform';

export interface ContractUploaderProps {
  onUploaded(fileUrl: string): Promise<void>;
  children: ReactElement;
  className?: string;
}

const acceptFileTypes = ['application/pdf'];
const LOADING_MSG = 'Uploading agreement...';
const SUCCESS_MSG = 'Agreement was uploaded.';

const ContractUploader: FC<ContractUploaderProps> = (props) => {
  const { onUploaded, children, className } = props;
  const [uploading, setUploading] = useLoadingState(null);
  const analytics = useAnalytics();

  const handleFileSelected = async (
    files: Array<FileWithPath>,
    rejectedFiles: Array<FileRejection>,
  ) => {
    try {
      setUploading(null);

      if (rejectedFiles.length > 0) {
        return setUploading(new Error(rejectedFiles[0].errors[0].message));
      }

      if (!files.length) {
        return;
      }

      const [file] = files;

      setUploading(true);
      analytics.track(UserEvent.CustomAgreementUploadStarted, {});

      const downloadURL = await uploadcareApi.uploadFile(
        new File([file], file.name, { type: file.type }),
      );
      await onUploaded(downloadURL);
      analytics.track(UserEvent.CustomAgreementUploadEnded, {});
      setUploading(SUCCESS_MSG);
    } catch (err) {
      setUploading(err as Error);
    }
  };

  return (
    <>
      <FileDropArea accept={acceptFileTypes} onFileDrop={handleFileSelected}>
        {({ openDialog }) => (
          <div
            className={className}
            onClick={(e) => {
              e.stopPropagation();
              openDialog();
              analytics.track(UserEvent.CustomAgreementUploadClicked, {});
            }}
          >
            {children}
          </div>
        )}
      </FileDropArea>
      <LoadingIndicator
        successMsg={SUCCESS_MSG}
        loadingMsg={LOADING_MSG}
        loading={uploading}
      />
    </>
  );
};

export default ContractUploader;
