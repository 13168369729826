import React, { ReactElement, useState } from 'react';
import ContractObject, {
  ContractId,
  ContractPartyObject,
  ContractPartyType,
} from '@a_team/models/dist/ContractObject';
import { ModalBasicProps } from '@src/components/Modal';
import MissionRole from '@a_team/models/dist/MissionRole';
import UserAvatar from '@src/components/UserAvatar';
import Checkbox from '@src/components/Checkbox';
import { ContractModal } from '@src/components/Modal/ContractModal/ContractModal';
import { AssignmentsBox } from '@src/components/Modal/ContractModal/AssignmentsBox';
import { createUseStyles } from 'react-jss';

interface Props extends ModalBasicProps {
  contracts?: ContractObject[];
  signedContracts?: ContractId[];
  roles?: MissionRole[];
  onAccept(ids: ContractId[]): void;
}

const useStyles = createUseStyles({
  memberList: {
    maxHeight: '100%',
    overflow: 'auto',
  },
  member: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
    marginRight: 12,
  },
  memberDetails: {
    display: 'flex',
    alignItems: 'flex-start',
    flex: 1,
  },
  memberDetailsInner: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    color: '#62646A',
    marginRight: 8,
  },
  name: {
    fontSize: 15,
    fontWeight: 500,
    color: '#222',
    paddingRight: 0,
  },
});

export const ClientContractModal = (props: Props): ReactElement | null => {
  const styles = useStyles();
  const { contracts, signedContracts, onClose, open } = props;

  const [approvedContracts, setApprovedContracts] = useState<
    ContractId[] | undefined
  >([]);

  let members: ContractPartyObject[] = [];
  contracts?.forEach(
    (contract) =>
      (members = members.concat(
        contract.parties.filter(
          (party) => party.type === ContractPartyType.MissionRole,
        ),
      )),
  );

  const onCheck = (checked: boolean, sid: ContractId) => {
    if (checked) {
      setApprovedContracts([...(approvedContracts || []), sid]);
    } else {
      setApprovedContracts(
        approvedContracts?.filter((contractId) => contractId !== sid),
      );
    }
  };

  return (
    <ContractModal
      loading={!contracts}
      onAccept={props.onAccept}
      acceptedContracts={approvedContracts || []}
      onClose={onClose}
      open={open}
      contractUrl={contracts && contracts[0].downloadURL}
      allowButtonEnabled={approvedContracts?.length !== 0}
    >
      <AssignmentsBox>
        <div className={styles.memberList}>
          {contracts?.map((contract) => {
            const members = contract.parties.filter(
              (party) => party.type === ContractPartyType.MissionRole,
            );
            return members.map((member) => {
              const role = props.roles?.find(
                (role) => role.rid === member?.rid,
              );
              const wasSigned = signedContracts?.includes(contract.sid);
              const isChecked = approvedContracts?.includes(contract.sid);
              return (
                <div className={styles.member} key={contract.sid}>
                  <div
                    className={styles.memberDetails}
                    onClick={() =>
                      !wasSigned && onCheck(!isChecked, contract.sid)
                    }
                  >
                    <UserAvatar
                      src={member.user?.profilePictureURL}
                      containerStyle={{ marginRight: 16 }}
                    />
                    <div className={styles.memberDetailsInner}>
                      <span className={styles.name}>
                        {member.user?.fullName}{' '}
                        {member.address.name !== member.user?.fullName
                          ? `(${member.address.name})`
                          : ''}
                      </span>
                      <span>{role?.category.title}</span>
                      <span>Hourly rate: ${role?.clientHourlyRate || 0}</span>
                    </div>
                  </div>
                  <Checkbox
                    disabled={wasSigned}
                    checked={isChecked || wasSigned}
                    onChange={(e) => onCheck(e.target.checked, contract.sid)}
                  />
                </div>
              );
            });
          })}
        </div>
      </AssignmentsBox>
    </ContractModal>
  );
};
