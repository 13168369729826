import React, { CSSProperties, ReactElement, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import RoleCategoryCardSelector from '@src/views/Mission/EditMission/RoleCategoryCardSelector';
import useToggle from '@src/hooks/useToggle';
import RoleCategoryObject, {
  RoleCategoryId,
} from '@a_team/models/dist/RoleCategory';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import OutlinedControl, {
  BasicOutlinedControlProps,
  OutlinedControlProps,
} from '@src/components/Inputs/OutlinedControl';
import { useRoleCategories } from '@src/rq/useRoleCategories';

interface Props extends BasicOutlinedControlProps {
  cid?: RoleCategoryId | null;
  onChange?(category: RoleCategoryObject): void;
  style?: CSSProperties;
  cardStyles?: CSSProperties;
  inputStyles?: CSSProperties;
  dropdownPlacement?: OutlinedControlProps['dropdownPlacement'];
  caret?: boolean;
  disabled?: boolean;
}

const useStyles = createUseStyles({
  selector: {
    position: 'static',
    top: 0,
    left: 0,
  },
});

function RoleCategorySelect(props: Props): ReactElement {
  const {
    cid,
    onChange,
    caret = true,
    disabled = false,
    dropdownPlacement,
    style,
    cardStyles,
    inputStyles,
    ...leftProps
  } = props;

  const styles = useStyles();
  const { auth, roleCategories } = useStores();
  const [open, toggleOpen] = useToggle();

  const roleCategoriesQueryResult = useRoleCategories(auth);

  useEffect(() => {
    if (roleCategoriesQueryResult.isSuccess) {
      roleCategories.setAllCategories(roleCategoriesQueryResult.data);
    }
  }, [roleCategoriesQueryResult.isSuccess]);

  return (
    <OutlinedControl
      input={
        <span style={inputStyles}>
          {cid
            ? (roleCategories.dict && roleCategories.dict[cid]?.title) ||
              'Loading...'
            : 'Role'}
        </span>
      }
      caret={caret}
      tabIndex={0}
      onFocus={toggleOpen.bind(null, !disabled && true)}
      onClick={toggleOpen.bind(null, !disabled && true)}
      dropdown={
        open && (
          <RoleCategoryCardSelector
            catalog={roleCategories.catalog}
            categories={roleCategories.categories}
            onSelect={(cat): void => {
              onChange && onChange(cat);
              toggleOpen();
            }}
            onClose={toggleOpen}
            className={styles.selector}
            style={cardStyles}
          />
        )
      }
      dropdownPlacement={dropdownPlacement}
      style={style}
      disabled={disabled}
      {...leftProps}
    />
  );
}

export default observer(RoleCategorySelect);
