import {
  RecommendationObject,
  RecommendationStatus,
} from '@a_team/models/dist/RecommendationObject';
import { Button, Icon } from '@a_team/ui-components';
import { Breakpoints } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import { useMutationRespondToUserRecommendationRequest } from '@src/rq/teamUp';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@src/rq/keys';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import { hasAppliedToSameRole } from '.';

interface PendingInviteUserProps {
  recommendation: RecommendationObject;
  reloadAppliedMissions?: boolean;
}

const useStyles = createUseStyles({
  container: {
    padding: 16,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 16,
    borderRadius: 8,
    '&:hover': {
      transition: 'background-color 0.2s ease-in-out',
      backgroundColor: '#EFEFF0',
    },
  },
  fullName: {
    fontWeight: 500,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  btns: {
    display: 'flex',
    gap: 8,
    '& button': {
      width: 'fit-content',
      padding: 8,
      height: 32,
    },
  },
  accepted: {
    display: 'flex',
    gap: 5,
    color: '#6D00D7',
    fontSize: 12,
    alignItems: 'center',
  },
  declined: {
    display: 'flex',
    gap: 5,
    color: '#62646A',
    fontSize: 12,
    alignItems: 'center',
  },
  appliedRoles: {
    fontSize: 12,
  },
  appliedToSameRole: {
    opacity: 0.5,
  },
  icon: {
    '& span': {
      display: 'flex',
    },
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },
});

const PendingInviteUser = ({
  recommendation,
  reloadAppliedMissions,
}: PendingInviteUserProps): JSX.Element => {
  const styles = useStyles();
  const queryClient = useQueryClient();
  const { auth, missionControl } = useStores();

  const { mutate, isLoading } = useMutationRespondToUserRecommendationRequest();

  const updatePendingRecommendationsStatus = (
    newStatus: RecommendationStatus,
  ) => {
    const pendingInvites = queryClient.getQueryData<RecommendationObject[]>(
      queryKeys.teamUp.getPendingUserRecommendations(
        auth.uid || '',
        recommendation.mission.mid,
      ),
    );

    if (!pendingInvites) {
      return;
    }

    const updatedPendingInvites = pendingInvites.map((invite) => {
      if (invite.rid === recommendation.rid) {
        return {
          ...invite,
          status: newStatus,
        };
      }

      return invite;
    });

    queryClient.setQueryData(
      queryKeys.teamUp.getPendingUserRecommendations(
        auth.uid || '',
        recommendation.mission.mid,
      ),
      updatedPendingInvites,
    );
  };

  const onDecline = () => {
    mutate(
      {
        recommendationId: recommendation.rid,
        status: RecommendationStatus.Rejected,
      },
      {
        onSuccess: () => {
          updatePendingRecommendationsStatus(RecommendationStatus.Rejected);
          missionControl.loadAppliedToMissions(false);
        },
      },
    );
  };

  const onAccept = () => {
    mutate(
      {
        recommendationId: recommendation.rid,
        status: RecommendationStatus.Active,
      },
      {
        onSuccess: () => {
          updatePendingRecommendationsStatus(RecommendationStatus.Active);
          missionControl.loadAppliedToMissions(false);
        },
      },
    );
  };
  const isPending = recommendation.status === RecommendationStatus.Pending;
  const isAccepted = recommendation.status === RecommendationStatus.Active;
  const isDeclined = recommendation.status === RecommendationStatus.Rejected;

  const user = recommendation.requestor;

  const appliedRolesText = useMemo(() => {
    // applied for X role and 2 others
    const appliedRoles = recommendation.requestorAppliedRoles.map(
      (role) => role.category.title,
    );
    const appliedRolesCount = appliedRoles.length;

    if (appliedRolesCount === 0) {
      return '';
    }

    if (appliedRolesCount === 1) {
      return `Applied for the ${appliedRoles[0]} role`;
    }

    return `Applied for the ${appliedRoles[0]} role and ${
      appliedRolesCount - 1
    } others`;
  }, [recommendation.requestorAppliedRoles]);

  const appliedToTheSameRole = useMemo(() => {
    return hasAppliedToSameRole(recommendation);
  }, [recommendation]);

  return (
    <div className={styles.container}>
      <div
        className={cx(
          styles.info,
          appliedToTheSameRole ? styles.appliedToSameRole : '',
        )}
      >
        <UserAvatar src={user.profilePictureURL} alt={user.fullName} />
        <div>
          <div className={styles.fullName}>{user.fullName}</div>
          <div className={styles.appliedRoles}>{appliedRolesText}</div>
        </div>
      </div>
      <div className={styles.btns}>
        {appliedToTheSameRole ? (
          <div>You applied to the same role</div>
        ) : isPending ? (
          <>
            <Button disabled={isLoading} onClick={onAccept}>
              Team up
            </Button>
            <Button
              disabled={isLoading}
              onClick={onDecline}
              variant="secondary"
            >
              Decline
            </Button>
          </>
        ) : (
          <>
            {isAccepted && (
              <span className={styles.accepted}>
                <Icon
                  className={styles.icon}
                  color="Hannibal@500"
                  size="sm"
                  name="statusPositiveNoBorder"
                />
                Accepted
              </span>
            )}

            {isDeclined && (
              <span className={styles.declined}>
                <Icon
                  className={styles.icon}
                  color="Grey@500"
                  size="sm"
                  name="statusNegativeNoBorder"
                />
                Declined
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default observer(PendingInviteUser);
