import React, { ReactNode, useEffect, useMemo } from 'react';
import { MissionApplicationStatusUpdate } from '@ateams/api/dist/endpoints/Missions';
import {
  ExclusiveStatus,
  MissionApplicationReviewStatus,
  MissionApplicationReviewStatusNotSelected,
  MissionApplicationReviewStatusOpportunityToUpdate,
  MissionApplicationReviewStatusOther,
  MissionApplicationReviewStatusWaitlisted,
} from '@a_team/models/dist/MissionApplicationObject';
import { NestedSelect, SelectOption, Spacing } from '@ateams/components';
import { BULK_UPDATE_APPLICATION_STATUS_OPTIONS } from '@src/components/ApplicantPill';
import { GroupTypeBase, PlaceholderProps } from 'react-select';
import { createUseStyles } from 'react-jss';
import _ from 'lodash';
import { toggleArrayItem } from '@src/logic/utils';
import {
  StatusTag,
  TagStatusExternalValues,
  TagStatusItem,
  allTagStatusItems,
} from '@src/views/AdminNotes/Main/ApplicationsTab/StatusTag';

interface Props {
  accepted?: boolean;
  exclusiveStatus?: ExclusiveStatus;
  proposalInterviewing?: boolean;
  proposal?: string;
  hasVettingScheduled?: boolean;
  reviewStatus?: MissionApplicationReviewStatus;
  onChange: (statusData: MissionApplicationStatusUpdate) => void;
  disabled: boolean;
  children?: ReactNode;
}

const useStyles = createUseStyles({
  statusPlaceholderContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    justifyContent: 'space-between',
    padding: '0px 5px',
  },
  statusPlaceholderText: {
    fontSize: 14,
  },
  statusPlaceholderCount: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    color: 'white',
    backgroundColor: '#79CE00',
    borderRadius: 12,
    width: 29,
    height: 24,
    padding: '2.5px 10px',
  },
  tagsList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    marginTop: 8,
    maxWidth: 320,
  },
  calculatedStatusContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
    paddingBottom: Spacing.medium,
    alignItems: 'flex-start',
  },
  title: {
    color: '#62646A',
    fontSize: 14,
  },
});
export const StatusControls = (props: Props) => {
  const styles = useStyles();
  const {
    accepted,
    exclusiveStatus,
    proposalInterviewing,
    proposal,
    hasVettingScheduled,
    reviewStatus,
    onChange,
    children,
  } = props;
  const [reviewStatusInternal, setReviewStatusInternal] =
    React.useState<MissionApplicationReviewStatus>(reviewStatus || {});

  useEffect(() => {
    setReviewStatusInternal(reviewStatus || {});
  }, [reviewStatus]);

  const selectedStatuses = useMemo<TagStatusItem[]>(() => {
    return (
      allTagStatusItems &&
      _.concat<TagStatusItem>(
        reviewStatusInternal?.notSelected
          ?.map((s) => allTagStatusItems.find((o) => o.value === s))
          .filter((item): item is TagStatusItem => item !== undefined) || [],
        reviewStatusInternal?.opportunityToUpdate
          ?.map((s) => allTagStatusItems.find((o) => o.value === s))
          .filter((item): item is TagStatusItem => item !== undefined) || [],
        reviewStatusInternal?.waitlisted
          ?.map((s) => allTagStatusItems.find((o) => o.value === s))
          .filter((item): item is TagStatusItem => item !== undefined) || [],
        reviewStatusInternal?.other
          ?.map((s) => allTagStatusItems.find((o) => o.value === s))
          .filter((item): item is TagStatusItem => item !== undefined) || [],
      )
    );
  }, [reviewStatusInternal]);

  const mainStatusesOptionsMap = useMemo(() => {
    const map: Record<
      string,
      {
        label: string;
        value: string;
        options: { label: string; value: string }[];
      }
    > = {};
    BULK_UPDATE_APPLICATION_STATUS_OPTIONS.forEach((item) => {
      map[item.value] = {
        value: item.value,
        label: item.label,
        options:
          item.subItems?.map((si) => ({ label: si.label, value: si.value })) ||
          [],
      };
    });

    return map;
  }, []);

  const statusListOptionMap = useMemo(() => {
    const map: Record<string, SelectOption> = {};
    BULK_UPDATE_APPLICATION_STATUS_OPTIONS.forEach((item) => {
      item.subItems?.forEach((si) => {
        map[si.value] = { label: si.label, value: si.value };
      });
    });
    return map;
  }, []);

  const externalStatusTagItem = useMemo<TagStatusItem | undefined>(() => {
    if (accepted) {
      return allTagStatusItems.find(
        (o) => o.value === TagStatusExternalValues.Accepted,
      );
    } else if (exclusiveStatus === ExclusiveStatus.OnHold) {
      return allTagStatusItems.find(
        (o) => o.value === TagStatusExternalValues.OnHold,
      );
    } else if (proposalInterviewing) {
      return allTagStatusItems.find(
        (o) => o.value === TagStatusExternalValues.ProposalInterviewing,
      );
    } else if (proposal) {
      return allTagStatusItems.find(
        (o) => o.value === TagStatusExternalValues.Proposal,
      );
    } else if (hasVettingScheduled) {
      return allTagStatusItems.find(
        (o) => o.value === TagStatusExternalValues.HasVettingScheduled,
      );
    }

    return undefined;
  }, [
    accepted,
    exclusiveStatus,
    proposalInterviewing,
    proposal,
    hasVettingScheduled,
  ]);

  const StatusCustomPlaceholder = (
    props: PlaceholderProps<
      SelectOption,
      false,
      GroupTypeBase<SelectOption>
    > & {
      itemSelections: string[] | undefined;
      hideTags?: boolean | undefined;
    },
  ) => {
    const styles = useStyles();
    const { itemSelections } = props;

    return (
      <div className={styles.statusPlaceholderContainer}>
        <div className={styles.statusPlaceholderText}>Status</div>
        <div className={styles.statusPlaceholderCount}>
          {itemSelections?.length || 0}
        </div>
      </div>
    );
  };

  const getListStatuses = (
    reviewStatus: MissionApplicationReviewStatus,
  ): string[] => {
    return _.concat(
      reviewStatus?.notSelected?.map((s) => s.toString()) || [],
      reviewStatus?.opportunityToUpdate?.map((s) => s.toString()) || [],
      reviewStatus?.waitlisted?.map((s) => s.toString()) || [],
      reviewStatus?.other?.map((s) => s.toString()) || [],
    );
  };

  const updateStatuses = (items: string[]): void => {
    const newReviewStatus: MissionApplicationReviewStatus = {
      notSelected: [],
      opportunityToUpdate: [],
      waitlisted: [],
      other: [],
    };

    items.forEach((item) => {
      if (
        Object.keys(MissionApplicationReviewStatusNotSelected).includes(item)
      ) {
        newReviewStatus.notSelected =
          toggleArrayItem<MissionApplicationReviewStatusNotSelected>(
            newReviewStatus.notSelected,
            item as MissionApplicationReviewStatusNotSelected,
          );
      } else if (
        Object.keys(MissionApplicationReviewStatusOpportunityToUpdate).includes(
          item,
        )
      ) {
        newReviewStatus.opportunityToUpdate =
          toggleArrayItem<MissionApplicationReviewStatusOpportunityToUpdate>(
            newReviewStatus.opportunityToUpdate,
            item as MissionApplicationReviewStatusOpportunityToUpdate,
          );
      } else if (
        Object.keys(MissionApplicationReviewStatusWaitlisted).includes(item)
      ) {
        newReviewStatus.waitlisted =
          toggleArrayItem<MissionApplicationReviewStatusWaitlisted>(
            newReviewStatus.waitlisted,
            item as MissionApplicationReviewStatusWaitlisted,
          );
      } else if (
        Object.keys(MissionApplicationReviewStatusOther).includes(item)
      ) {
        newReviewStatus.other =
          toggleArrayItem<MissionApplicationReviewStatusOther>(
            newReviewStatus.other,
            item as MissionApplicationReviewStatusOther,
          );
      }
    });

    const existingStatuses = getListStatuses(reviewStatusInternal);
    const newStatuses = getListStatuses(newReviewStatus);

    const changedStatuses = _.difference(newStatuses, existingStatuses);

    if (changedStatuses.length > 0) {
      const changedStatus = changedStatuses[0];

      if (
        Object.keys(MissionApplicationReviewStatusNotSelected).includes(
          changedStatus,
        )
      ) {
        newReviewStatus.opportunityToUpdate = [];
        newReviewStatus.waitlisted = [];
      } else if (
        Object.keys(MissionApplicationReviewStatusOpportunityToUpdate).includes(
          changedStatus,
        )
      ) {
        newReviewStatus.notSelected = [];
        newReviewStatus.waitlisted = [];
      } else if (
        Object.keys(MissionApplicationReviewStatusWaitlisted).includes(
          changedStatus,
        )
      ) {
        newReviewStatus.notSelected = [];
        newReviewStatus.opportunityToUpdate = [];
        newReviewStatus.waitlisted = [
          changedStatus as MissionApplicationReviewStatusWaitlisted,
        ];
      }
    }

    setReviewStatusInternal(newReviewStatus);
  };

  const onMenuClose = () => {
    const changed = !_.isEqual(reviewStatusInternal, reviewStatus);

    if (changed) {
      onChange({
        reviewStatus: reviewStatusInternal,
      });
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {externalStatusTagItem && (
        <div className={styles.calculatedStatusContainer}>
          <span className={styles.title}>Calculated status</span>
          <StatusTag
            key={externalStatusTagItem.value}
            item={externalStatusTagItem}
          />
        </div>
      )}
      <NestedSelect
        placeholder="Status"
        items={selectedStatuses.map((ss) => ss.value)}
        onChange={(items) => updateStatuses(items)}
        onMenuClose={onMenuClose}
        sidebar={false}
        isClearable={false}
        hideTags={true}
        allItemsMap={statusListOptionMap}
        allItemGroups={mainStatusesOptionsMap}
        CustomPlaceholderOverride={StatusCustomPlaceholder}
        menuPlacement="top"
        hideGroupCheckbox={true}
      />
      <div className={styles.tagsList}>
        {selectedStatuses.map((ss) => (
          <StatusTag item={ss} />
        ))}
      </div>
      {children}
    </div>
  );
};
