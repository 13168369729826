export enum VettingProcessStatus {
  OnHold = 'onHold',
  PendingPreVettingForm = 'pendingPreVettingForm',
  PendingVetterAssignment = 'pendingVetterAssignment',
  PendingVetterApproval = 'pendingVetterApproval',
  PendingBuilderInterviewSchedule = 'pendingBuilderInterviewSchedule',
  PendingFeedback = 'pendingFeedback',
  Completed = 'completed',
}

export const VettingProccessStatusLabels: Record<VettingProcessStatus, string> =
  {
    [VettingProcessStatus.OnHold]: 'On Hold',
    [VettingProcessStatus.PendingPreVettingForm]: 'Pending Pre-vetting Form',
    [VettingProcessStatus.PendingVetterAssignment]: 'Pending Vetter Assignment',
    [VettingProcessStatus.PendingVetterApproval]: 'Pending Vetter Approval',
    [VettingProcessStatus.PendingBuilderInterviewSchedule]:
      'Pending Builder Interview Schedule',
    [VettingProcessStatus.PendingFeedback]: 'Pending Feedback',
    [VettingProcessStatus.Completed]: 'Completed',
  };

export enum VettingProcessPublicStatus {
  OnHold = 'onHold',
  SentPreVettingForm = 'sentPreVettingForm',
  PendingVetterAssignment = 'pendingVetterAssignment',
  PendingVetterApproval = 'pendingVetterApproval',
  PendingCandidateScheduling = 'pendingCandidateScheduling',
  InterviewScheduled = 'interviewScheduled',
  PendingFeedback = 'pendingFeedback',
  RatingConflict = 'ratingConflict',
  Completed = 'completed',
}

export const VettingProcessPublicStatusLabels = {
  [VettingProcessPublicStatus.OnHold]: 'On Hold',
  [VettingProcessPublicStatus.SentPreVettingForm]: 'Sent pre-evaluation form',
  [VettingProcessPublicStatus.PendingVetterAssignment]:
    'Pending interviewer assignment',
  [VettingProcessPublicStatus.PendingVetterApproval]:
    'Pending interviewer approval',
  [VettingProcessPublicStatus.PendingCandidateScheduling]:
    'Pending candidate scheduling',
  [VettingProcessPublicStatus.InterviewScheduled]: 'Interview scheduled',
  [VettingProcessPublicStatus.PendingFeedback]: 'Pending interviewer feedback',
  [VettingProcessPublicStatus.RatingConflict]: 'Rating Conflict',
  [VettingProcessPublicStatus.Completed]: 'Completed',
};

/**
 * Status mapping: {@link https://buildateam.atlassian.net/browse/BX-194}
 * An internal status may map to several external statuses depending on other properties
 */
export const publicStatusToInternalStatusMap = {
  [VettingProcessPublicStatus.OnHold]: VettingProcessStatus.OnHold,
  [VettingProcessPublicStatus.SentPreVettingForm]:
    VettingProcessStatus.PendingPreVettingForm,
  [VettingProcessPublicStatus.PendingVetterAssignment]:
    VettingProcessStatus.PendingVetterAssignment,
  [VettingProcessPublicStatus.PendingVetterApproval]:
    VettingProcessStatus.PendingVetterApproval,
  [VettingProcessPublicStatus.PendingCandidateScheduling]:
    VettingProcessStatus.PendingBuilderInterviewSchedule,
  [VettingProcessPublicStatus.InterviewScheduled]:
    VettingProcessStatus.PendingFeedback,
  [VettingProcessPublicStatus.PendingFeedback]:
    VettingProcessStatus.PendingFeedback,
  [VettingProcessPublicStatus.RatingConflict]: VettingProcessStatus.Completed,
  [VettingProcessPublicStatus.Completed]: VettingProcessStatus.Completed,
};
