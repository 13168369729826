import { BasicUserObject } from '@a_team/models/dist/UserObject';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { MemberResponses } from './TeamPulseModal';

interface MissionReviewMobileProps {
  isReviewingTeamMember: boolean;
  usersToReview: BasicUserObject[];
  currentTeamMemberToReview?: BasicUserObject;
  memberResponses: MemberResponses;
}

const useStyles = createUseStyles({
  container: {
    background: '#F7F7F7',
    borderBottom: '1px solid #DADADC',
    padding: '20px 20px 0 20px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    marginTop: 0,
    marginBottom: 4,
  },
  usersToReviewContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
  },

  usersToReviewImg: {
    height: 32,
    width: 32,
    borderRadius: '50%',
  },
});

const MissionReviewMobile = ({
  isReviewingTeamMember,
  usersToReview,
  currentTeamMemberToReview,
}: MissionReviewMobileProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <h5 className={styles.title}>
        {isReviewingTeamMember ? 'Team Review' : 'Mission Review'}
      </h5>
      <p>
        {isReviewingTeamMember
          ? 'Let us know about your experience with your fellow builders'
          : 'Let us know about your experience on the mission'}
      </p>
      {isReviewingTeamMember && (
        <div className={styles.usersToReviewContainer}>
          {usersToReview.map((user) => (
            <div
              key={user.uid}
              style={
                user.uid === currentTeamMemberToReview?.uid
                  ? {
                      borderBottom: '2px solid #6d00d7',
                      paddingBottom: 12,
                    }
                  : { opacity: 0.5, paddingBottom: 12 }
              }
            >
              <img
                className={styles.usersToReviewImg}
                src={user.profilePictureURL}
                alt={user.firstName}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MissionReviewMobile;
