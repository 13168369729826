import { SurveyQuestionMap, SurveyType } from '@a_team/models/dist/Survey';
import {
  TeamPulse,
  TeamPulseSurvey,
  TeamPulseSurveyWithUser,
} from '@a_team/models/dist/TeamPulse';
import Modal from '@src/components/Modal';
import { useStores } from '@src/stores';
import React, { Fragment, ReactElement, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import TeamPulseSurveyModalSidebar from '../TeamPulseSurveyModalSidebar';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import Response from './Response';
import { Breakpoints } from '@ateams/components';
import { ResponseProps } from './ResponseProps';
import MissionRole, { MissionAdminRole } from '@a_team/models/dist/MissionRole';

export interface Props<T extends SurveyType<keyof T> = SurveyType<never>> {
  open: boolean;
  onClose: () => void;
  teamPulse: TeamPulse;
  teamPulseSurveys: (TeamPulseSurvey<T> | TeamPulseSurveyWithUser<T>)[];
}

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    height: '100%',
  },
  sidebar: {
    flex: '0 0 300px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.08)',
    marginLeft: '-24px',
    marginTop: '-40px',
    marginBottom: '-40px',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '40px',
    paddingBottom: '40px',
    [`@media (max-width: ${Breakpoints.sm})`]: {
      display: 'none',
    },
  },
  questionView: {
    flex: '1 0 0%',
    [`@media (max-width: ${Breakpoints.sm})`]: {
      paddingLeft: 0,
    },
    width: '100%',
    overflowY: 'auto',
    height: '100%',
    paddingLeft: '40px',
  },
  block: {
    display: 'block',
  },
  responseList: {
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    marginTop: '24px',
    marginBottom: '24px',
  },
  questionTitle: {
    fontWeight: 600,
  },
});

// eslint-disable-next-line @typescript-eslint/ban-types
const fromEntries = <T extends object>(entries: [keyof T, T[keyof T]][]): T =>
  Object.fromEntries(entries) as T;

const TeamPulseResponseModal = <T extends SurveyType<keyof T>>({
  onClose,
  open,
  teamPulseSurveys,
}: Props<T>): ReactElement | null => {
  const styles = useStyles();
  const { missions } = useStores();
  const { currentMission } = missions;

  const [questionIndex, setQuestionIndex] = useState(0);

  const missionUsers = useMemo(
    () => [
      ...(currentMission?.data.roles
        .map((role: MissionAdminRole | MissionRole) => role.user)
        .filter((user): user is BasicUserObject => !!user && 'uid' in user) ??
        []),
      ...(currentMission?.data.managers?.map((manager) => manager.user) ?? []),
    ],
    [currentMission],
  );

  const questionKeys = useMemo(
    () =>
      Array.from(
        new Set(
          teamPulseSurveys.flatMap(
            (teamPulseSurvey) =>
              Object.keys(teamPulseSurvey.questions) as Array<keyof T>,
          ),
        ),
      ),
    [teamPulseSurveys],
  );

  const responsesGroupedByKey = useMemo(() => {
    const responseMap = fromEntries<{
      [K in keyof T]: Array<ResponseProps<T[K]>>;
    }>(questionKeys.map((key) => [key, []]));

    for (const teamPulseSurvey of teamPulseSurveys) {
      for (const key in teamPulseSurvey.questions) {
        const question = teamPulseSurvey.questions[key];
        const response = teamPulseSurvey.response?.[key];
        const user =
          (teamPulseSurvey as TeamPulseSurveyWithUser).user ??
          missionUsers?.find((user) => user.uid === teamPulseSurvey.userId);
        responseMap[key].push({
          question,
          response,
          teamPulseSurvey,
          user,
        });
      }
    }
    return responseMap;
  }, [teamPulseSurveys, questionKeys]);

  const sideBarItems = useMemo(() => {
    const questionsToFilterOut: (string | number | symbol)[] = [
      'canWeShareFeedbackWithClient',
      'tellUsMore',
      'shareProudMoments',
      'shareHighlights',
      'isScopedWellDefined',
      'howAdditionalBuildersCouldHelp',
      'whatCouldImproveExperience',
      'canMissionUseMoreBuilders',
      'rolesRecommendedForMission',
      'buildersRecommendedForMission',
      'whyTheseBuilders',
    ];
    return fromEntries<{
      [K in keyof T]: {
        question: SurveyQuestionMap<T>[keyof T];
        completed: boolean;
        disabled: boolean;
      };
    }>(
      questionKeys
        .filter((key) => !questionsToFilterOut.includes(key))
        .map((key) => [
          key,
          {
            question: responsesGroupedByKey[key][0].question,
            completed: false,
            disabled: false,
          },
        ]),
    );
  }, [questionKeys, responsesGroupedByKey]);

  const questionCount = useMemo(() => questionKeys.length, [questionKeys]);

  const questionKey = useMemo(
    () => questionKeys[questionIndex],
    [questionKeys, questionIndex],
  );

  const activeResponses = useMemo(
    () => responsesGroupedByKey[questionKey],
    [responsesGroupedByKey, questionKey],
  );

  const questionTitles = useMemo(
    () =>
      activeResponses &&
      Array.from(
        new Set(activeResponses.map((item) => item.question.props.title)),
      ),
    [activeResponses],
  );

  const responsesGroupedByTitle = useMemo(
    () =>
      questionTitles &&
      questionTitles.map((questionTitle) => ({
        questionTitle,
        responses: activeResponses.filter(
          (response) => response.question.props.title === questionTitle,
        ),
      })),
    [questionTitles, activeResponses],
  );

  if (questionCount === 0) {
    return null;
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      hideCloseButton={false}
      style={{
        width: '1138px',
        maxWidth: 'calc(100% - 48px)',
        maxHeight: 'calc(100% - 48px)',
        height: '780px',
      }}
    >
      <div className={styles.row}>
        <TeamPulseSurveyModalSidebar<SurveyType<keyof T>>
          className={styles.sidebar}
          items={sideBarItems}
          selectedItem={questionKey}
          onSelectItem={(key) => {
            setQuestionIndex(questionKeys.indexOf(key));
          }}
          hideQuestionSubtitles
          hideBackButton
        />
        <div className={styles.questionView}>
          <div className={styles.block}>
            {responsesGroupedByTitle.map(({ questionTitle, responses }, i) => (
              <Fragment key={i}>
                <p className={styles.questionTitle}>{questionTitle}</p>
                <ul className={styles.responseList}>
                  {responses.map((props, j) => (
                    <li key={j} className={styles.listItem}>
                      <Response {...props} />
                    </li>
                  ))}
                </ul>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TeamPulseResponseModal;
