import React, { ReactElement } from 'react';
import arrowRight from './arrowRight.svg';
import arrowLeft from './arrowLeft.svg';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface Props {
  type: 'left' | 'right';
  offset?: number | string;
  show?: boolean;
  disable?: boolean;
  className?: string;
  onClick(): void;
}

const useStyles = createUseStyles({
  root: {
    zIndex: 10,
    position: 'absolute',
    top: '50%',
    marginTop: '-20px',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    backgroundColor: '#F7F7F7',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  disabled: {
    cursor: 'default',
    opacity: 0.5,
  },
});

export default function CarouselControl(props: Props): ReactElement {
  const { type, offset, show, disable, className, onClick } = props;
  const styles = useStyles();

  return (
    <>
      {show && (
        <div
          className={cx(
            'noSelect',
            type,
            styles.root,
            {
              [styles.disabled]: disable,
            },
            className,
          )}
          style={{
            [type]: offset,
          }}
          onClick={onClick}
        >
          <img src={type === 'left' ? arrowLeft : arrowRight} alt={type} />
        </div>
      )}
    </>
  );
}
