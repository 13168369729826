import { Button, Input, Modal } from '@a_team/ui-components';
import { Breakpoints, Colors, Icon, IconType } from '@ateams/components';
import { useQueryGenerateTimesheetSummaryBlurb } from '@src/rq/timesheets';
import { useStores } from '@src/stores';
import { Editor } from '@tiptap/react';
import { observer } from 'mobx-react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Skeleton from 'react-loading-skeleton';
import z from 'zod';
import play from './play.svg';
import book from './book.svg';
import { useEditor } from '@src/hooks/useEditor';
import TextEditor from '@src/components/TextEditor';
import { countCharsForEditorValue } from '@src/components/TextEditor/helper';

interface SummaryProps {
  summaryModalOpen: boolean;
  setSummaryModalOpen: (isOpen: boolean) => void;
  sid: string;
  onContinue: () => void;
}

const useStyles = createUseStyles({
  container: {},
  modal: {
    // first child div padding 0
    width: '680px !important',
    '& > div': {
      padding: 0,
    },
  },
  heroImage: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    lineHeight: '26px',
    fontWeight: 600,
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 8,
  },
  descWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 40,
  },
  desc: {
    maxWidth: 400,
    fontSize: 15,
    lineHeight: '24px',
    textAlign: 'center',
    color: '#818388',
    marginBottom: 0,
    marginTop: 0,
  },
  videoLinkInput: {
    border: '1px solid #DADADC',
    borderColor: '#DADADC !important',
  },
  link: {
    marginTop: 8,
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    color: Colors.secondaryDark,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '19px',
  },
  executiveSummaryTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    marginTop: 32,
    marginBottom: 8,
    fontSize: 15,
    color: '#6D00D7',
    fontWeight: 500,
  },
  executiveSummaryDesc: {
    color: '#62646A',
    marginTop: 0,
    fontSize: 14,
    lineHeight: '19px',
  },
  modalContent: {
    padding: 15,
  },
  inputLabel: { fontSize: 15, fontWeight: 500 },
  inputLabelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    marginBottom: 16,
    '& > span': {
      fontSize: 14,
      color: '#62646A !important',
    },
  },
  optional: {
    color: '#818388',
  },
  btnsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 12,
    marginTop: 40,
    padding: 16,
    borderTop: '1px solid #DADADC',
    // select button and width should be fit content and padding 8 12
    '& > button': {
      width: 'fit-content',
      padding: '8px 12px',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modalContent: {
      padding: 40,
      marginBottom: 70,
    },
    btnsContainer: {
      position: 'absolute',
      zIndex: 999,
      bottom: 0,
      width: '100%',
      backgroundColor: '#FFFFFF',
    },
  },
});

export function parseSummaryToHtml(suggestion: string) {
  let parseSuggestion = '';

  const paragraphs = suggestion
    .split('\n')
    .filter((block) => block.trim() !== '');

  if (paragraphs?.length) {
    paragraphs.forEach((paragraph) => {
      parseSuggestion += `<p>${paragraph}</p>`;
    });

    return parseSuggestion;
  }

  return suggestion;
}

const Summary = ({
  summaryModalOpen,
  setSummaryModalOpen,
  sid,
  onContinue,
}: SummaryProps): JSX.Element => {
  const styles = useStyles();
  const { uiStore } = useStores();

  const { editor } = useEditor({
    initialValue: '',
    noTitle: true,
    onDescriptionChange: (editor: Editor) => {
      uiStore.setTimesheetDescription(editor.getText());
      uiStore.setTimesheetDescriptionHtml(editor.getHTML());
    },
  });

  const onInsertSuggestion = (suggestion: string) => {
    const contentHTML = parseSummaryToHtml(suggestion);
    // Set the content in the editor
    editor?.commands.setContent(contentHTML);

    // Update the MobX store with the new content
    uiStore.setTimesheetDescription(editor?.getText() || '');
    uiStore.setTimesheetDescriptionHtml(editor?.getHTML() || '');
  };

  const { isLoading } = useQueryGenerateTimesheetSummaryBlurb({
    sid,
    onSuccess: (data) => {
      if (data) {
        uiStore.setOriginalTimesheetDescriptionHtml(
          parseSummaryToHtml(data.suggestion),
        );
        onInsertSuggestion(data.suggestion);
        uiStore.setGptUsageLogIdForTimesheetSummary(data.gptUsageLogId);
      }
    },
    enabled: summaryModalOpen,
  });

  const charCount = countCharsForEditorValue(editor?.getHTML());

  const onClose = () => {
    setSummaryModalOpen(false);
  };

  const handleOnContinue = async () => {
    onContinue();
  };

  const isValidUrl = z
    .string()
    .url()
    .optional()
    .safeParse(uiStore.timesheetVideoLink);

  return (
    <div className={styles.container}>
      <Modal
        variant="slideUp"
        isOpen={summaryModalOpen}
        onClose={onClose}
        className={styles.modal}
        shouldHideGradientStroke
      >
        <div>
          <div className={styles.modalContent}>
            <div className={styles.heroImage}>
              <img src={play} alt="Demo day" />
            </div>
            <h4 className={styles.title}>Demo day</h4>
            <div className={styles.descWrapper}>
              <p className={styles.desc}>
                Take a moment to reflect on what you've built over the last two
                weeks and share it with the team!
              </p>
              <a
                className={styles.link}
                target="_blank"
                href="https://guide.a.team/missions/demo-day-showcase-your-impact"
                rel="noreferrer"
              >
                <img src={book} alt="How to create a good demo" /> How to create
                a good demo
              </a>
            </div>

            <div>
              <div className={styles.inputLabelWrapper}>
                <label className={styles.inputLabel}>
                  Show off what you built by adding a short video
                  <span className={styles.optional}> (Optional)</span>
                </label>
              </div>
              <Input
                error={
                  isValidUrl.success === false &&
                  uiStore.timesheetVideoLink.length > 0
                }
                className={styles.videoLinkInput}
                value={uiStore.timesheetVideoLink}
                onChange={(e) => uiStore.setTimesheetVideoLink(e.target.value)}
                placeholder="Enter a Loom or other video link"
                size="stretch"
              />
              <small style={{ marginTop: 8 }}>
                {isValidUrl.success === false &&
                  uiStore.timesheetVideoLink.length > 0 &&
                  'Please enter a valid URL'}
              </small>
            </div>

            <div data-testing-id="timesheets-summary-wrapper">
              <p className={styles.executiveSummaryTitle}>
                <Icon type={IconType.SuggestedTeams} size="smaller" /> Impact
                summary
              </p>
              <div className={styles.executiveSummaryDesc}>
                <div>
                  Take this opportunity to show off what you accomplished and
                  the impact you had.
                </div>
                <div>Edit the summary below the reflect your contribution.</div>
              </div>
              {isLoading && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                    border: '1px solid #DADADC',
                    padding: 16,
                    borderRadius: 8,
                    marginTop: 8,
                  }}
                >
                  <Skeleton height={30} width="200px" />{' '}
                  <Skeleton height={30} width="100%" />{' '}
                  <Skeleton height={30} width="100%" />{' '}
                  <Skeleton height={30} width="100%" />{' '}
                  <Skeleton height={30} width="100%" />{' '}
                  <Skeleton height={30} width="100%" />{' '}
                </div>
              )}
              {!isLoading && editor && (
                <TextEditor editor={editor} hideHeading charCount={charCount} />
              )}
            </div>
          </div>
          <div className={styles.btnsContainer}>
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
            <Button
              disabled={
                uiStore.timesheetDescription.length === 0 ||
                (uiStore.timesheetVideoLink.length > 0 &&
                  isValidUrl.success === false)
              }
              onClick={handleOnContinue}
              data-testing-id="timesheets-summary-submit-button"
            >
              Continue
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(Summary);
