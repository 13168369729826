import { Checkbox } from '@a_team/ui-components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { Spacing, FontSizes, Colors, FontWeights } from '@ateams/components';

const useStyles = createUseStyles({
  container: ({ disabled }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.xsmall,
    backgroundColor: disabled ? Colors.backgroundLight : 'transparent',
    cursor: disabled ? 'not-allowed' : 'pointer',
  }),
  label: {
    fontSize: FontSizes.small,
    color: Colors.regularDark,
    fontWeight: FontWeights.regular,
    userSelect: 'none',
  },
});

export interface FieldCheckboxProps {
  text: string;
  value: boolean | undefined;
  onChange: (value: boolean) => void;
  className?: string;
  textClassName?: string;
  disabled?: boolean;
}

export const FieldCheckbox = ({
  text,
  value,
  onChange,
  className,
  textClassName,
  disabled,
}: FieldCheckboxProps) => {
  const styles = useStyles({ disabled });

  return (
    <div className={cn(styles.container, className)}>
      <Checkbox
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      <div
        className={cn(styles.label, textClassName)}
        onClick={(e) => {
          e.stopPropagation();
          onChange(!value);
        }}
      >
        {text}
      </div>
    </div>
  );
};
