import { ServiceAuth, ServiceEndpoint } from './utils';
import { EmailTemplateObject } from '@a_team/models/dist/EmailTemplate';

export const BasePath = '/email-templates';

export default class EmailTemplatesEndpoint extends ServiceEndpoint {
  public getAllTemplates(auth: ServiceAuth): Promise<EmailTemplateObject[]> {
    return this.fetch(auth, BasePath);
  }

  public getTemplateByName(
    auth: ServiceAuth,
    templateName: string,
  ): Promise<EmailTemplateObject> {
    return this.fetch(auth, BasePath + `/${templateName}`);
  }

  public sendEmailTemplate(
    auth: ServiceAuth,
    to: string,
    subject: string,
    templateHtml: string,
  ): Promise<void> {
    return this.fetch(auth, BasePath + `/send`, null, 'post', {
      to,
      subject,
      templateHtml,
    });
  }
}
