import { Breakpoints } from '@ateams/components';
import { CDN_BASE_URL } from '@src/config';
import MissionPaymentCycle, {
  PaymentCycleDisplayStatus,
} from '@src/stores/Missions/MissionPaymentCycle';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface TeamPaymentCycleProps {
  paymentCycle: MissionPaymentCycle;
  selected: boolean;
  onClick: () => void;
}

const useStyles = createUseStyles<{
  selected: boolean;
  isToSubmit: boolean;
}>({
  container: {
    border: ({ selected }) =>
      selected ? '2px solid #6D00D7' : '1px solid #DADADC',
    width: 260,
    color: '#222',
    borderRadius: 8,
  },

  infoContainer: {
    height: '100%',
    padding: 16,
    background: '#FFFFFF',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    borderRadius: 8,
  },
  dateRange: {
    fontSize: 14,
    fontWeight: 400,
  },
  statusIcon: {
    height: 24,
    width: 24,
  },
  verticalLine: {
    display: 'inline-block',
    width: 2,
    height: 24,
    background: '#6D00D7',
    marginRight: 8,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      width: 320,
    },
  },
});

const TeamPaymentCycle = ({
  paymentCycle,
  selected,
  onClick,
}: TeamPaymentCycleProps): JSX.Element => {
  const displayStatus = paymentCycle.displayStatus;
  const styles = useStyles({
    selected,
    isToSubmit: displayStatus === PaymentCycleDisplayStatus.Submit,
  });

  const getStatusIcon = () => {
    if (displayStatus === PaymentCycleDisplayStatus.Active) {
      return `${CDN_BASE_URL}/platform/active-timesheet.svg`;
    }

    return null;
  };

  const statusIcon = getStatusIcon();

  const date = `${paymentCycle.formattedStartDate} - ${paymentCycle.formattedEndDate}`;

  return (
    <div onClick={onClick} className={styles.container}>
      <div className={styles.infoContainer}>
        {(statusIcon || selected) && (
          <div>
            {selected && <div className={styles.verticalLine} />}
            {statusIcon && (
              <img
                className={styles.statusIcon}
                alt="status icon"
                src={statusIcon}
              />
            )}
          </div>
        )}

        <div className={styles.dateRange}>{date}</div>
      </div>
    </div>
  );
};

export default TeamPaymentCycle;
