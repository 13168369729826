import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { VettingProcessFeedbackPeopleManaged } from '@a_team/models/dist/vetting-processes/feedback';
import {
  ManagementExperience,
  ManagementExperienceLabels,
  ManagementExprienceRadioLabels,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import { theme } from '@a_team/ui-components';
import { SelectOption, Spacing } from '@ateams/components';
import { InputLabel, Size } from '../../components/typography';
import { CheckboxValueTag, RadioValueTag } from '../../components/value-tag';
import { Separator } from '../../components/separator';
import { InputContainer } from '../../components/input-container';
import { TextInput } from '../../components/text-input';
import { Text } from '../../components/typography';

const ManagementExperienceOptions = Object.entries(
  ManagementExprienceRadioLabels,
).map(([key, label]) => ({ value: key, label }));

const INPUT_LABEL = 'Does this builder have management experience?';

type ManagementExperienceValue =
  | VettingProcessFeedbackPeopleManaged
  | undefined;

export type OnManagementExperienceChange = (
  peopleManaged: ManagementExperienceValue,
) => void;

export interface ManagementExperienceInputProps {
  value: ManagementExperienceValue;
  onChange?: OnManagementExperienceChange;
  isReadOnly?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  marginLeft: {
    marginLeft: Spacing.medium,
  },
  inputsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  booleanCheckboxValueTag: {
    marginRight: Spacing.small,
    '&:last-child': {
      marginRight: 0,
    },
  },
  peopleManagedRadioGroupContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.small,
  },
});

export const ManagementExperienceInput: React.FC<
  ManagementExperienceInputProps
> = (props) => {
  const { isReadOnly, value } = props;
  const styles = useStyles();
  const [hasManagementExperience, setHasManagementExperience] = useState(
    typeof value !== 'undefined'
      ? value.managementExperience !== ManagementExperience.None
      : undefined,
  );

  const onHasManagementExperienceChange = (isChecked: boolean) => {
    let newHasManagementExperience: boolean | undefined = undefined;
    let newPeopleManaged: ManagementExperienceValue = undefined;

    if (isChecked !== hasManagementExperience) {
      newHasManagementExperience = isChecked;

      if (isChecked === false) {
        newPeopleManaged = {
          managementExperience: ManagementExperience.None,
          description: undefined,
        };
      }
    }

    setHasManagementExperience(newHasManagementExperience);
    props.onChange?.(newPeopleManaged);
  };

  const onManagementExperienceChange = (option: SelectOption) => {
    const newPeopleManaged = {
      ...value,
      managementExperience: option.value as ManagementExperience,
    };

    props.onChange?.(newPeopleManaged);
  };

  const onPeopleManagedDescriptionChange = (description: string) => {
    if (value) {
      const newPeopleManaged = {
        ...value,
        description,
      };

      props.onChange?.(newPeopleManaged);
    }
  };

  if (isReadOnly && !value) {
    return (
      <div className={props.className}>
        <InputLabel className={styles.marginBottom}>{INPUT_LABEL}</InputLabel>
        <Text isReadOnly />
      </div>
    );
  }

  return (
    <div className={props.className}>
      <InputLabel className={styles.marginBottom}>{INPUT_LABEL}</InputLabel>
      <div className={cx(styles.inputsRow, styles.marginBottom)}>
        <CheckboxValueTag
          label={'Yes'}
          checked={hasManagementExperience === true}
          className={styles.booleanCheckboxValueTag}
          onClick={() => onHasManagementExperienceChange(true)}
          isReadOnly={isReadOnly}
        />
        <CheckboxValueTag
          label={'No'}
          checked={hasManagementExperience === false}
          className={styles.booleanCheckboxValueTag}
          onClick={() => onHasManagementExperienceChange(false)}
          isReadOnly={isReadOnly}
        />
      </div>

      {hasManagementExperience && (
        <Separator
          direction={'vertical'}
          space={Spacing.medium}
          width={2}
          color={theme.colors.Grey[200]}
          className={cx(styles.marginLeft, styles.marginBottom)}
        >
          {isReadOnly ? (
            value?.managementExperience && (
              <Text className={styles.marginBottom}>
                {ManagementExperienceLabels[value.managementExperience]}
              </Text>
            )
          ) : (
            <InputContainer
              label="How many people have they managed?"
              inputLabelProps={{ size: Size.Small }}
              className={styles.marginBottom}
            >
              <div className={styles.peopleManagedRadioGroupContainer}>
                {ManagementExperienceOptions.map((option) => (
                  <RadioValueTag
                    label={option.label}
                    checked={option.value === value?.managementExperience}
                    onClick={() => onManagementExperienceChange(option)}
                  />
                ))}
              </div>
            </InputContainer>
          )}

          <div className={styles.marginLeft}>
            <TextInput
              value={value?.description}
              onChange={(e) => onPeopleManagedDescriptionChange(e.target.value)}
              placeholder={'Enter details...'}
              isReadOnly={isReadOnly}
              readOnlyText={'No details were added.'}
            />
          </div>
        </Separator>
      )}
    </div>
  );
};
