import React, { useMemo } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { VettingProcessAudit } from '@a_team/models/dist/vetting-process-audit';
import useToggle from '@src/hooks/useToggle';
import { Text } from '@src/views/VettingFeedbackForm/components/typography';
import { UpArrowIcon } from './icons/up-arrow';
import { DownArrowIcon } from './icons/down-arrow';
import { ActivityRecord } from './activity-record';
import { Spacing } from '@ateams/components';

export interface VettingProcessSummaryProps {
  vettingProcessAudit: VettingProcessAudit[];
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  activitySortToggle: {
    marginBottom: Spacing.medium,
  },
  activityRecord: {
    marginBottom: Spacing.large,
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

function sortVettingProcessAudit(
  records: VettingProcessAudit[],
  isDescending: boolean,
) {
  return records.sort((a, b) => {
    const diff = isDescending ? 1 : -1;
    if (a.date === b.date) {
      return 0;
    }

    if (!a.date || !b.date) {
      if (a.date) {
        return -1;
      } else {
        return 1;
      }
    }

    if (a.date > b.date) {
      return -diff;
    } else {
      return diff;
    }
  });
}

export const VettingProcessActivity: React.FC<VettingProcessSummaryProps> = (
  props,
) => {
  const styles = useStyles();
  const { vettingProcessAudit } = props;
  const [isSortingDescending, toggleIsSortingDescending] = useToggle(true);

  const records = useMemo(
    () => sortVettingProcessAudit(vettingProcessAudit, isSortingDescending),
    [vettingProcessAudit, isSortingDescending],
  );

  return (
    <div className={cx(styles.container, props.className)}>
      <ActivitySortToggle
        className={styles.activitySortToggle}
        isSortingDescending={isSortingDescending}
        onClick={toggleIsSortingDescending}
      />

      {records.map((record) => (
        <ActivityRecord
          key={record.id}
          vettingProcessAudit={record}
          className={styles.activityRecord}
        />
      ))}
    </div>
  );
};

interface ActivitySortToggleProps {
  isSortingDescending: boolean;
  onClick?: () => void;
  className?: string;
}

const useActivitySortToggleStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  text: {
    marginRight: Spacing.medium,
  },
});

const ActivitySortToggle: React.FC<ActivitySortToggleProps> = (props) => {
  const styles = useActivitySortToggleStyles();

  return (
    <div
      className={cx(styles.container, props.className)}
      onClick={props.onClick}
    >
      <Text className={styles.text}>Activity</Text>
      {props.isSortingDescending ? <UpArrowIcon /> : <DownArrowIcon />}
    </div>
  );
};
