import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { VettingFeedbackFormDefaults } from '@ateams/api/dist/endpoints/vetting-process-feedback';
import {
  TalentIndustryOptionType,
  talentIndustryToOption,
} from '@src/components/TalentIndustrySelector';
import {
  SelectValueWithCountContainer,
  TalentIndustrySelector,
} from '../components/select';
import { Card } from '../components/card';
import { InputLabel, Size, Text } from '../components/typography';
import { TextInput } from '../components/text-input';
import { Spacing } from '@ateams/components';
import { ValueTag } from '../components/value-tag';
import { Separator } from '../components/separator';

const useStyles = createUseStyles({
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  marginLeft: {
    marginLeft: Spacing.medium,
  },
  talentIndustriesSelector: {
    width: '200px',
  },
  tagsContainer: {
    display: 'inline-flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '4px',
  },
});

export type VettingFeedbackFormIndustries = {
  talentIndustries: VettingFeedbackFormDefaults['answers']['industries']['talentIndustries'];
  comment?: string;
};

export type OnVettingFeedbackFormIndustriesChange = (
  data: Partial<VettingFeedbackFormIndustries>,
) => void;

export interface VettingFeedbackFormIndustriesProps {
  values: VettingFeedbackFormIndustries;
  onChange?: OnVettingFeedbackFormIndustriesChange;
  isReadOnly?: boolean;
  className?: string;
}

export const VettingFeedbackFormIndustriesForm: React.FC<
  VettingFeedbackFormIndustriesProps
> = (props) => {
  const { isReadOnly } = props;
  const styles = useStyles();

  const onTalentIndustriesChange = (options: TalentIndustryOptionType[]) => {
    const talentIndustries = options.map(
      ({ talentIndustry }) => talentIndustry,
    );

    props.onChange?.({ talentIndustries });
  };

  const onCommentChange = (comment: string) => {
    props.onChange?.({ comment });
  };

  const selectComponents = useMemo(
    () => ({
      ValueContainer: SelectValueWithCountContainer,
    }),
    [],
  );

  const hasSelectedTalentIndustries = Boolean(
    props.values.talentIndustries.length,
  );

  const talentIndustriesOptions = useMemo(() => {
    return props.values.talentIndustries.map((talentIndustry) =>
      talentIndustryToOption(talentIndustry),
    );
  }, [props.values.talentIndustries]);

  return (
    <Card title={'Industries'} className={props.className}>
      {!props.isReadOnly && (
        <Text className={styles.marginBottom}>
          Select the industries the builder has solid experience in.
        </Text>
      )}

      <div className={styles.marginBottom}>
        {!isReadOnly && (
          <TalentIndustrySelector
            className={cx(styles.talentIndustriesSelector, {
              [styles.marginBottom]: hasSelectedTalentIndustries,
            })}
            value={talentIndustriesOptions}
            onChange={onTalentIndustriesChange}
            isMulti
            placeholder={'Select'}
            hideTags
            isClearable={false}
            components={selectComponents}
            controlShouldRenderValue
            isReadOnly={isReadOnly}
            menuPortalTarget={document.body}
          />
        )}

        {hasSelectedTalentIndustries && (
          <div className={styles.tagsContainer}>
            {isReadOnly && !props.values.talentIndustries.length ? (
              <Text
                isReadOnly
                readOnlyText={'No industries have been selected.'}
              />
            ) : (
              props.values.talentIndustries.map((industry) => (
                <ValueTag key={industry.id}>
                  <Text size={Size.Small}>{industry.name}</Text>
                </ValueTag>
              ))
            )}
          </div>
        )}
      </div>

      <Separator direction={'horizontal'} space={Spacing.medium} />

      <InputLabel className={styles.marginBottom}>Comments</InputLabel>
      {isReadOnly ? (
        <Text isReadOnly>{props.values.comment}</Text>
      ) : (
        <div className={styles.marginLeft}>
          <TextInput
            value={props.values.comment}
            onChange={(e) => onCommentChange(e.target.value)}
            placeholder={'E.g., Years of experience'}
            withLine={!isReadOnly}
            isReadOnly={isReadOnly}
          />
        </div>
      )}
    </Card>
  );
};
