import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { format } from 'date-fns';
import { theme } from '@a_team/ui-components';
import { Spacing } from '@ateams/components';
import { DateISOString } from '@a_team/models/dist/misc';
import UserAvatar from '@src/components/UserAvatar';
import { Text } from './typography';
import { EditActions } from './button';
import { RichTextEditor } from './rich-text-editor';
import { Separator } from './separator';

export interface CommentProps {
  avatarSrc?: string;
  fullName?: string;
  date?: DateISOString;
  isNewComment: boolean;
  value?: string;
  showEditActions?: boolean;
  onCommentChange?: (comment: string) => void;
  onCommentRemove?: () => unknown;
  isReadOnly?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  avatar: {
    alignSelf: 'start',
    marginRight: Spacing.medium,
  },
  titleFullName: {
    color: theme.colors.Grey[800],
    fontWeight: 500,
  },
  titleDateTime: {
    color: theme.colors.Grey[500],
    fontWeight: 400,
  },
  leftBorder: {
    marginLeft: '11px',
  },
  leftPadding: {
    paddingLeft: '27px',
    color: theme.colors.Grey[800], // We need this because the editor is inheriting the color(thus without is getting color from the Separator)
  },
  readonlyCommentContainer: {
    position: 'relative',
    marginTop: Spacing.medium,
  },
  editActions: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

export const Comment: React.FC<CommentProps> = (props) => {
  const styles = useStyles();
  const [isEditing, setIsEditing] = useState(false);

  // New comment
  if (props.isNewComment) {
    return (
      <div className={cx(styles.container, props.className)}>
        <div className={styles.row}>
          <UserAvatar
            src={props.avatarSrc}
            containerClassName={styles.avatar}
            size={24}
          />
          <RichTextEditor
            defaultValue={props.value}
            onChange={props.onCommentChange}
            placeholder={'Add a comment...'}
          />
        </div>
      </div>
    );
  }

  // Existing comment (read/edit mode)
  return (
    <div className={cx(styles.container, props.className)}>
      {!props.isReadOnly && props.showEditActions && props.onCommentRemove && (
        <EditActions
          className={styles.editActions}
          isEditing={isEditing}
          onDeleteClick={props.onCommentRemove}
          onEditClick={() => setIsEditing(true)}
        />
      )}

      <div className={styles.row}>
        <UserAvatar
          src={props.avatarSrc}
          containerClassName={styles.avatar}
          size={24}
        />
        <Text>
          {props.fullName && (
            <span className={styles.titleFullName}>{`${props.fullName} `}</span>
          )}
          {props.date && (
            <span className={styles.titleDateTime}>
              {format(new Date(props.date), "MMMM d, yyyy 'at' h:mm a")}
            </span>
          )}
        </Text>
      </div>

      <div className={cx(styles.row, styles.readonlyCommentContainer)}>
        <Separator
          direction={'vertical'}
          width={2}
          color={theme.colors.Grey[200]}
          className={styles.leftBorder}
        >
          <div className={styles.leftPadding}>
            {isEditing ? (
              <RichTextEditor
                defaultValue={props.value}
                onChange={props.onCommentChange}
                placeholder={'Edit comment...'}
              />
            ) : (
              <RichTextEditor defaultValue={props.value} isReadOnly />
            )}
          </div>
        </Separator>
      </div>
    </div>
  );
};

Comment.defaultProps = {
  showEditActions: true,
};
