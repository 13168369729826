import { Colors } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import purpleCheckMark from '../assets/purple-checkmark.svg';

interface TeamMemberCardProps {
  fullName: string;
  profileImg: string;
  totalMembers?: number;
  currentMemberIndex?: number;
  beingReviewed?: boolean;
  simple?: boolean;
  completed?: boolean;
  onRemove?: () => void;
}

const useStyles = createUseStyles<{
  beingReviewed?: boolean;
  simple?: boolean;
  completed?: boolean;
}>({
  teamMemberCard: {
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    borderLeft: ({ beingReviewed }) => {
      return beingReviewed ? `2px solid ${Colors.secondaryDark}` : 'none';
    },
    background: ({ beingReviewed }) => {
      return beingReviewed ? '#FCFAFF' : 'none';
    },
    opacity: ({ beingReviewed, simple, completed }) => {
      return beingReviewed || simple || completed ? 1 : 0.5;
    },
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  profileImg: {
    height: 24,
    width: 24,
    // make image rounded
    borderRadius: '50%',
  },
  stepIndicator: {
    background: '#E8D0FF',
    color: Colors.secondaryDark,
    borderRadius: 8,
    padding: '4px 8px',
    fontWeight: 400,
  },
  removeBtn: {
    cursor: 'pointer',
    color: '#818388',
    border: '1px solid #DADADC',
    borderRadius: 8,
    padding: '4px 8px',
    background: 'none',
    outline: 'none',
    fontSize: 12,
  },
  checkMark: {
    height: 24,
    width: 24,
    marginLeft: 10,
  },
});

const TeamMemberCard = ({
  fullName,
  profileImg,
  totalMembers,
  currentMemberIndex,
  beingReviewed,
  simple,
  completed,
  onRemove,
}: TeamMemberCardProps): JSX.Element => {
  const styles = useStyles({
    beingReviewed,
    completed,
    simple,
  });
  return (
    <div className={styles.teamMemberCard}>
      <div className={styles.profileContainer}>
        <img className={styles.profileImg} src={profileImg} alt={fullName} />
        {fullName}
      </div>
      {beingReviewed && currentMemberIndex && totalMembers && (
        <span className={styles.stepIndicator}>
          {currentMemberIndex}/{totalMembers}
        </span>
      )}
      {onRemove && (
        <button onClick={onRemove} className={styles.removeBtn}>
          Remove
        </button>
      )}
      {completed && (
        <img
          className={styles.checkMark}
          src={purpleCheckMark}
          alt="Checkmark"
        />
      )}
    </div>
  );
};

export default TeamMemberCard;
