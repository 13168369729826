import React, { ReactElement, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Select, SelectOption } from '@ateams/components';
import { v1SearchUserFilterApiKeywordSource } from '@a_team/team-engine-search-service-sdk-js';

export const allSources = [
  { label: 'Email', value: 'email' },
  { label: 'Name', value: 'name' },
  { label: 'Username', value: 'username' },
  { label: 'About Me', value: 'profileAbout' },
  { label: 'Admin Notes', value: 'adminNotes' },
  { label: 'Experiences Jobs', value: 'experiencesJobs' },
  { label: 'Experiences Projects', value: 'experiencesProjects' },
  { label: 'Profile Suggestions Jobs', value: 'profileSuggestionsJobs' },
  {
    label: 'Profile Suggestions Projects',
    value: 'profileSuggestionsProjects',
  },
  { label: 'Applications', value: 'applications' },
  { label: 'Proposal Blurbs', value: 'proposalBlurbs' },
  { label: 'Evaluation Feedback Forms', value: 'evaluationFeedbackForms' },
  { label: 'Evaluation Transcripts', value: 'evaluationTranscripts' },
  { label: 'Interview Transcripts', value: 'interviewTranscripts' },
  { label: 'Timesheet Summaries', value: 'timesheetSummaries' },
];

interface Props {
  label: string;
  keywordSources: Array<v1SearchUserFilterApiKeywordSource | null>;
  onChange: (keywordSources: string[]) => void;
}

const useStyles = createUseStyles({
  selector: {
    maxWidth: 200,
    marginRight: 25,
    fontSize: 14,

    '& label.input-label': {
      color: '#222222',
    },
  },
  sidebar: {
    maxWidth: 'unset',
    marginRight: 0,
  },
  tagList: {
    marginTop: 8,
    marginBottom: 0,
  },
});

const KeywordSourcesSelect = (props: Props): ReactElement => {
  const styles = useStyles();
  const { onChange, keywordSources, label } = props;

  const selectedKeywordSources = useMemo(() => {
    const newSelectedKeywordSources: SelectOption[] = [];
    keywordSources.forEach((entry) => {
      const keywordSource = allSources?.find(
        (source) => source.value === entry,
      );
      if (keywordSource) {
        newSelectedKeywordSources.push(keywordSource);
      }
    });
    return newSelectedKeywordSources;
  }, [keywordSources, allSources]);

  const updateKeywordSources = (
    options: SelectOption | SelectOption[] | null,
  ): void => {
    const sources: string[] = !options
      ? []
      : Array.isArray(options)
      ? options.map(({ value }) => value)
      : [options.value];

    onChange(sources);
  };

  const removeOption = (option: SelectOption): void => {
    const newSelectedKeywordSources = selectedKeywordSources.filter(
      (keywordSource) => keywordSource.value !== option.value,
    );
    updateKeywordSources(newSelectedKeywordSources);
  };

  return (
    <div>
      <Select
        label={label}
        value={selectedKeywordSources}
        options={allSources}
        onChange={(e) => {
          updateKeywordSources(e as SelectOption[]);
        }}
        onRemoveOption={(e) => {
          removeOption(e as SelectOption);
        }}
        placeholder={'Select keyword sources'}
        margin={'none'}
        className={cx(styles.selector && styles.sidebar)}
        tagListClassName={styles.tagList}
        isMulti
        allowSelectAll
      />
    </div>
  );
};

export default KeywordSourcesSelect;
