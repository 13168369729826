import React from 'react';
import { createUseStyles } from 'react-jss';
import CommonStyles from '../common/styles';
import CompanyLogoFallback from '@src/components/CompanyLogoFallback';
import { useHistory, useLocation } from 'react-router-dom';

interface RelatedJobProps {
  companyName: string | null | undefined;
  period: string;
  title: string | undefined;
  logoUrl: string | null | undefined;
  relatedJobId: string;
  onCloseProjectModal: () => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    marginTop: 64,
  },
  relatedJob: {
    border: '1px solid #DADADC',
    padding: 16,
    borderRadius: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 24,
    transition: 'all 0.2s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F7F7F7',
      '& $openButton': {
        visibility: 'visible',
      },
    },
  },
  relatedJobWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  openButton: {
    color: '#62646A',
    fontSize: 15,
    cursor: 'pointer',
    visibility: 'hidden',
    transition: 'all 0.2s',
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    margin: 0,
  },
  companyName: {
    fontSize: '14px',
  },
  period: {
    fontSize: '14px',
    color: '#6E7177',
  },
  periodCompanyNameContainer: {
    display: 'flex',
    gap: 8,
  },
});

const RelatedJob = ({
  companyName,
  period,
  title,
  logoUrl,
  relatedJobId,
  onCloseProjectModal,
}: RelatedJobProps): JSX.Element => {
  const styles = useStyles();
  const commonStyles = CommonStyles();

  const history = useHistory();
  const location = useLocation();

  const handleClick = () => {
    onCloseProjectModal();
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('project');
    searchParams.set('job', relatedJobId);
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    history.push(newUrl);
  };

  return (
    <div className={styles.container}>
      <div className={commonStyles.title}>Related job</div>
      <div>
        <div className={styles.relatedJob} onClick={handleClick}>
          <div className={styles.relatedJobWrapper}>
            <div>
              <CompanyLogoFallback
                logoUrl={logoUrl || undefined}
                borderRadius={8}
                size={40}
              />
            </div>
            <div>
              <h4 className={styles.title}>{title}</h4>
              <div className={styles.periodCompanyNameContainer}>
                <div className={styles.companyName}>{companyName}</div>
                <div className={styles.period}>
                  {' · '}
                  {period}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.openButton}>Open</div>
        </div>
      </div>
    </div>
  );
};

export default RelatedJob;
