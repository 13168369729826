import { Icon } from '@a_team/ui-components';
import useClickOutside from '@src/hooks/useClickOutside';
import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

// Define the styles using JSS
const useStyles = createUseStyles({
  dropdownButton: {
    borderRadius: '4px',
    padding: '5px',
    minHeight: '58px',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:focus': {
      outline: '2px solid #6D00D7',
    },
  },
  dropdown: {
    position: 'relative',
    display: 'block',
  },
  dropdownContent: {
    display: 'none',
    position: 'absolute',
    backgroundColor: 'white',
    minWidth: '104px',
    zIndex: 1,
    right: '30px',
    bottom: '-30px',
    width: 'auto',
    boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: '4px',
    '& button': {
      color: 'black',
      padding: '14px 16px',
      fontSize: '14px',
      border: 'none',
      width: '100%',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      '&:hover, &:focus': {
        backgroundColor: '#f1f1f1',
      },
    },
  },
  show: {
    display: 'block',
  },
});

interface ClearRowDropdownProps {
  onClearRow: () => void;
}

const ClearRowDropdown = ({ onClearRow }: ClearRowDropdownProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const styles = useStyles();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const firstMenuItemRef = useRef<HTMLButtonElement | null>(null); // Ref for the first menu item
  const blurTimeout = useRef<ReturnType<typeof setTimeout> | null>();

  useClickOutside(dropdownRef, () => setShowMenu(false));

  const toggleMenu = () => {
    if (blurTimeout.current) {
      clearTimeout(blurTimeout.current);
      blurTimeout.current = null;
    }
    setShowMenu(!showMenu);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (showMenu) {
      if (event.key === 'ArrowDown') {
        event.preventDefault(); // Prevent default to avoid scrolling the page
        firstMenuItemRef.current?.focus(); // Focus the first menu item
      } else if (event.key === 'ArrowUp') {
        event.preventDefault(); // Prevent the dropdown from closing
      }
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    blurTimeout.current = setTimeout(() => {
      if (!dropdownRef?.current?.contains(document.activeElement)) {
        setShowMenu(false);
      }
    }, 100);
  };

  const handleFocus = () => {
    if (blurTimeout.current) {
      clearTimeout(blurTimeout.current);
      blurTimeout.current = null;
    }
  };

  const handleClearRow = () => {
    setShowMenu(false);
    onClearRow();
  };

  return (
    <>
      <button
        data-testing-id="timesheets-clear-row-dropdown"
        className={styles.dropdownButton}
        onClick={toggleMenu}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown} // Add the key down event handler
      >
        <Icon name="more" />
      </button>
      <div className={cx(styles.dropdown, showMenu ? styles.show : '')}>
        <div
          ref={dropdownRef}
          className={cx(styles.dropdownContent, showMenu && styles.show)}
          onBlur={handleBlur} // Attach onBlur to the dropdown content
          onFocus={handleFocus}
        >
          <button
            data-testing-id="timesheets-clear-row-button"
            ref={firstMenuItemRef}
            onClick={handleClearRow}
          >
            Delete row
          </button>
          {/* Add more options here */}
        </div>
      </div>
    </>
  );
};

export default ClearRowDropdown;
