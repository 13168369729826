import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { components, ValueContainerProps } from 'react-select';
import Colors, { TextColors } from '../theme/colors';

const useStyles = createUseStyles({
  valueContainer: {
    height: '29px',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
  },
  badge: {
    flex: '0 0 min-content',
    color: TextColors.info,
    margin: '0px 4px',
    display: 'flex',
    padding: '2px 11px',
    flexFlow: 'row nowrap',
    fontSize: '80%',
    background: Colors.success,
    alignItems: 'center',
    borderRadius: '50px',
  },
});

export const MultiValueContainer = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: ValueContainerProps<any, any>,
): ReactElement => {
  const styles = useStyles();
  const { children, hasValue, selectProps } = props;
  const numOfValues = props.getValue().length;

  return (
    <components.ValueContainer {...props}>
      {
        <div className={styles.valueContainer}>
          {selectProps.selectProps.itemCountPrefix}
          {hasValue && <span className={styles.badge}>{numOfValues}</span>}
        </div>
      }
      {children}
    </components.ValueContainer>
  );
};
