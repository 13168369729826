import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Colors, TextColors } from '@ateams/components';

interface NotPublishedProps {
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    background: Colors.regularDark,
    color: TextColors.primary,
    padding: '6px 12px',
    borderRadius: 6,
    fontSize: 14,
  },
});

const NotPublished = ({ className }: NotPublishedProps): JSX.Element => {
  const styles = useStyles();
  return <div className={cx(styles.container, className)}>Not published</div>;
};

export default NotPublished;
