import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
  },
});

export const TagList = (props: Props): ReactElement => {
  const { children, className, style } = props;
  const styles = useStyles();

  return (
    <div className={cx(styles.root, className)} style={style}>
      {children}
    </div>
  );
};
