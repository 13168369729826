import React, { ReactElement } from 'react';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import AdminTeamWorkMissionsList from '../AdminMissionsList/AdminMissionsList';

function AdminTeamWorkScheduledToEndMissions(): ReactElement {
  const { missions } = useStores();

  return (
    <AdminTeamWorkMissionsList
      loadMore={() => missions.getAdminScheduledToEndMissions(true)}
      showMore={!!missions.adminNextTokens.scheduledToEndMissions}
      type={'scheduledToEnd'}
      title={'Scheduled to End missions'}
      missions={missions.adminViewMissions.scheduledToEndMissions || []}
    />
  );
}

export default observer(AdminTeamWorkScheduledToEndMissions);
