import { Card } from '@ateams/components';
import CompanyAvatar from '@src/components/CompanyAvatar/CompanyAvatar';
import TooltipWrapped from '@src/components/TooltipWrapped';
import UserAvatar from '@src/components/UserAvatar';
import { MissionLocation } from '@src/locations';
import { stripDescriptionHtml } from '@src/logic/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import ApplicationDetails from '../../MissionControlCard/ApplicationDetails';
import { useStyles } from '../../MissionControlCard/styles';
import { CompletedMissionsDataResponse } from '@a_team/models/dist/v2/Mission';
import { getMissionTitle } from '@src/views/Mission/utils';

interface CompletedMissionCardV2Props {
  mission: CompletedMissionsDataResponse;
  isLoading: boolean;
}

const CompletedMissionCardV2 = ({
  mission,
  isLoading,
}: CompletedMissionCardV2Props) => {
  const styles = useStyles({ descriptionLines: 2, fullWidth: false });

  const missionTitle = useMemo(() => {
    return getMissionTitle(mission?.title);
  }, [mission?.title]);

  const additionalRoles =
    (mission?.totalFilledRoles || 0) -
    (mission?.topActiveUsersOnMission?.length || 0);

  const hourlyRate = useMemo(() => {
    if (!mission?.role?.hourlyRate) {
      return '';
    }
    return `$${mission.role.hourlyRate}/hour`;
  }, [mission?.role?.hourlyRate]);

  return (
    <li className={cx(styles.listItem)}>
      <Card className={styles.card}>
        <Link
          to={`${MissionLocation}/${mission.mid}`}
          className={styles.cardLink}
        >
          <div className={styles.headerContainer}>
            <div className={styles.logo}>
              {isLoading ? (
                <Skeleton
                  circle
                  height={40}
                  width={40}
                  className={styles.logoInner}
                />
              ) : (
                <CompanyAvatar
                  src={mission?.logoURL ?? ''}
                  alt={`${mission?.companyName} company logo`}
                  size={40}
                  className={styles.logoInner}
                />
              )}

              {mission?.topActiveUsersOnMission?.map((user) => {
                return (
                  <TooltipWrapped
                    key={user.uid}
                    theme={'dark'}
                    position={'top'}
                    arrow
                    html={
                      <div className={styles.tooltip}>
                        <p>{user?.fullName}</p>
                      </div>
                    }
                  >
                    <UserAvatar
                      src={user.profilePictureURL || ''}
                      alt={user?.fullName}
                      size={24}
                      containerStyle={{
                        width: '24px',
                        height: '24px',
                        marginRight: '4px',
                      }}
                    />
                  </TooltipWrapped>
                );
              })}
              {additionalRoles > 0 && `+${additionalRoles}`}
            </div>
          </div>
          <div className={cx(styles.descriptionContainer, styles.container)}>
            {isLoading ? (
              <>
                <Skeleton className={styles.companyName} />
                <Skeleton className={styles.title} />
                <Skeleton count={4} />
              </>
            ) : (
              <>
                <p className={styles.companyName}>
                  <small>{mission?.companyName}</small>
                </p>

                <h4 className={styles.title}>{missionTitle}</h4>

                <p className={styles.description}>
                  {mission && stripDescriptionHtml(mission.description)}
                </p>
              </>
            )}
          </div>
          <div className={cx(styles.detailsContainer, styles.container)}>
            <ApplicationDetails
              role={mission?.role?.categoryTitle ?? ''}
              hourlyRate={hourlyRate}
              dateApplied={mission?.role?.userAssignedAt}
            />
          </div>
        </Link>
      </Card>
    </li>
  );
};

export default observer(CompletedMissionCardV2);
