import { Breakpoints, Button, TextColors } from '@ateams/components';
import { useStores } from '@src/stores';
import cx from 'classnames';
import React, { MutableRefObject, useEffect } from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { ProjectWithSelectedSkills } from '../..';

interface FooterProps {
  isMissingMinimumRequirements?: boolean;
  handleClose: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  headerRef?: MutableRefObject<HTMLInputElement>;
  imagesRef: MutableRefObject<HTMLInputElement>;
  roleRef: MutableRefObject<HTMLInputElement>;
  skillsRef: MutableRefObject<HTMLInputElement>;
  hasZeroToOneExperienceRef: MutableRefObject<HTMLInputElement>;
  hasManagedPeopleRef: MutableRefObject<HTMLInputElement>;
  metricsRef: MutableRefObject<HTMLInputElement>;
  descriptionRef: MutableRefObject<HTMLInputElement>;
  errors: FieldErrors<ProjectWithSelectedSkills>;
  loading?: boolean;
}

const useStyles = createUseStyles({
  footerWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 999,
    background: '#FFFFFF',
    right: 0,
    borderTop: '1px solid #DADADC',
    borderRadius: '0 0 16px 16px',
  },
  footer: {
    marginTop: 16,
    marginBottom: 16,
    maxWidth: '800px',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: '0 24px',
  },
  footerButton: {
    borderRadius: 4,
    padding: '11px 20px',
    width: 140,
    transition: 'background 0.3s ease',
    cursor: 'pointer !important',
  },
  cancelButton: {
    color: TextColors.regular,
    '&:hover': {
      background: '#DADADC',
    },
  },
  connectButton: {
    color: TextColors.primary,
    '&:not([disabled]):hover': {
      background: '#5900b3',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    footer: {
      padding: '0',
    },
  },
});

const Footer = ({
  isMissingMinimumRequirements,
  handleClose,
  confirmLabel,
  cancelLabel,
  headerRef,
  imagesRef,
  roleRef,
  skillsRef,
  hasZeroToOneExperienceRef,
  hasManagedPeopleRef,
  metricsRef,
  descriptionRef,
  errors,
  loading,
}: FooterProps): JSX.Element => {
  const { auth } = useStores();
  const { trigger } = useFormContext();

  useEffect(() => {
    if (isMissingMinimumRequirements) {
      trigger('title');
      trigger('companyV2Id');
      trigger('industry');
      trigger('jobRoleId');
      trigger('startDate');
      trigger('endDate');
      trigger('skills');
      trigger('description');
      trigger('descriptionHTML');

      if (auth.withSkillsSelectorInline) {
        trigger('selectedSkills');
      }

      handleScrolling();
    }
  }, [isMissingMinimumRequirements]);

  const styles = useStyles();

  const scrollTo = (sectionRef?: MutableRefObject<HTMLInputElement>) => {
    if (sectionRef) {
      sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrolling = () => {
    setTimeout(() => {
      scrollToSectionsWithErrors();
    }, 400);
  };

  const scrollToSectionsWithErrors = () => {
    if (
      errors.companyName ||
      errors.companyV2Id ||
      errors.industry ||
      errors.industries ||
      errors.title
    ) {
      scrollTo(headerRef);
    } else if (errors.imagesUrls) {
      scrollTo(imagesRef);
    } else if (errors.jobRoleId || errors.startDate || errors.endDate) {
      scrollTo(roleRef);
    } else if (errors.skills || errors.selectedSkills) {
      scrollTo(skillsRef);
    } else if (errors.hasZeroToOneExperience) {
      scrollTo(hasZeroToOneExperienceRef);
    } else if (errors.hasManagedPeople) {
      scrollTo(hasManagedPeopleRef);
    } else if (errors.metrics) {
      scrollTo(metricsRef);
    } else if (errors.description || errors.descriptionHTML) {
      scrollTo(descriptionRef);
    }
  };

  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footer}>
        <Button
          type="button"
          className={cx(styles.footerButton, styles.cancelButton)}
          size="small"
          color="regularLight"
          onClick={handleClose}
        >
          {cancelLabel ?? 'Cancel'}
        </Button>

        <Button
          className={cx(styles.footerButton, styles.connectButton)}
          size="small"
          color="secondaryDark"
          onClick={handleScrolling}
          disabled={loading}
          loading={loading}
          data-testing-id="profile-project-edit-modal-submit-button"
        >
          {confirmLabel ?? 'Publish'}
        </Button>
      </div>
    </div>
  );
};

export default Footer;
