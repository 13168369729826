import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  Breakpoints,
  FontSizes,
  FontWeights,
  Icon,
  IconType,
  Spacing,
} from '@ateams/components';
import { MenuItem } from './MenuItem';
import MainLayout from '@src/layouts/Main';
import EvaluationSettingsView from '../EvaluationSettings';
import { useStores } from '@src/stores';
import { useHistory, useLocation } from 'react-router-dom';
import { PrivacyView } from './PrivacyView';
import AccountSettings from './AccountSettings';
import NotificationPreferences from '../NotificationPreferences/NotificationPreferences';
import InterviewSettingsView from '../InterviewSettingsView';
import InterviewSettingsViewV2 from '../InterviewSettingsViewV2';

interface Props {
  location: Location;
}

const useStyles = createUseStyles({
  mainContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRight: `1px solid ${BorderColors.lighter}`,
    width: '100%',
    marginTop: 20,
  },
  settingsTitle: {
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.medium,
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
    alignItems: 'center',
    height: 54,
    padding: Spacing.medium,
    borderBottom: `1px solid ${BorderColors.lighter}`,
  },
  contentContainer: {
    backgroundColor: 'white',
    height: '100%',
    flex: 1,
    paddingLeft: Spacing.medium,
    paddingRight: Spacing.medium,
    paddingTop: Spacing.large,
    paddingBottom: Spacing.xxxLarge,
  },
  menuItemsContainer: {
    padding: `0 ${Spacing.medium}px`,
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    borderBottom: '1px solid #DADADC',
    gap: Spacing.medium,
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    contentContainer: {
      padding: Spacing.xLarge,
    },
    mainContainer: {
      flexDirection: 'row',
    },
    menuContainer: {
      width: 240,
      marginTop: 0,
    },
    menuItemsContainer: {
      flexDirection: 'column',
      borderBottom: 'none',
      gap: 0,
      padding: 0,
    },
  },
});

enum SettingsMenuItem {
  Account = 'Account',
  Calendar = 'Calendar',
  Connections = 'Connections',
  Notifications = 'Notifications',
  Privacy = 'Privacy',
  Interviews = 'Interviews',
}

const SettingsView = (props: Props) => {
  const { auth, evaluationSettingsUser } = useStores();
  const styles = useStyles();
  const [selectedMenuItem, setSelectedMenuItem] = useState<SettingsMenuItem>(
    SettingsMenuItem.Account,
  );
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const setupAccount = async () => {
      await evaluationSettingsUser.setupAccount();
      await auth.fetchData(true);
    };

    if (!auth.user?.calComUserId) {
      setupAccount();
    }
  }, [auth.user?.calComUserId, auth.user?.isVetter]);

  const onMenuClick = (menuItem: SettingsMenuItem) => {
    setSelectedMenuItem(menuItem);
    history.push(`/settings?menu=${menuItem.toLocaleLowerCase()}`);
  };

  useEffect(() => {
    if (queryParams.get('menu') === 'calendar') {
      setSelectedMenuItem(SettingsMenuItem.Calendar);
    } else if (queryParams.get('menu') === 'notifications') {
      setSelectedMenuItem(SettingsMenuItem.Notifications);
    } else if (queryParams.get('menu') === 'privacy') {
      setSelectedMenuItem(SettingsMenuItem.Privacy);
    } else if (queryParams.get('menu') === 'interviews') {
      setSelectedMenuItem(SettingsMenuItem.Interviews);
    } else {
      setSelectedMenuItem(SettingsMenuItem.Account);
    }
  }, []);

  const renderInterviewSettingsView = () => {
    if (auth.withSharedCalendarFlow) {
      return <InterviewSettingsViewV2 />;
    }

    return <InterviewSettingsView />;
  };

  return (
    <MainLayout
      title={'Settings'}
      style={{ backgroundColor: 'white', height: '100%', padding: 0 }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.menuContainer}>
          <div className={styles.settingsTitle}>
            <Icon type={IconType.Settings} size="exact" />
            <div>Settings</div>
          </div>
          <div className={styles.menuItemsContainer}>
            <MenuItem
              onClick={() => onMenuClick(SettingsMenuItem.Account)}
              selected={selectedMenuItem === SettingsMenuItem.Account}
            >
              Account
            </MenuItem>
            {auth.user?.isVetter && (
              <MenuItem
                onClick={() => onMenuClick(SettingsMenuItem.Calendar)}
                selected={selectedMenuItem === SettingsMenuItem.Calendar}
              >
                Calendar
              </MenuItem>
            )}
            {!auth.user?.isVetter && (
              <MenuItem
                onClick={() => onMenuClick(SettingsMenuItem.Interviews)}
                selected={selectedMenuItem === SettingsMenuItem.Interviews}
              >
                Interviews
              </MenuItem>
            )}
            <MenuItem
              onClick={() => onMenuClick(SettingsMenuItem.Notifications)}
              selected={selectedMenuItem === SettingsMenuItem.Notifications}
            >
              Notifications
            </MenuItem>
            <MenuItem
              onClick={() => onMenuClick(SettingsMenuItem.Privacy)}
              selected={selectedMenuItem === SettingsMenuItem.Privacy}
            >
              Privacy
            </MenuItem>
          </div>
        </div>
        <div className={styles.contentContainer}>
          {selectedMenuItem === SettingsMenuItem.Account && auth.user && (
            <AccountSettings profile={auth.user} />
          )}
          {selectedMenuItem === SettingsMenuItem.Calendar && auth.isVetter && (
            <EvaluationSettingsView />
          )}
          {selectedMenuItem === SettingsMenuItem.Interviews
            ? renderInterviewSettingsView()
            : null}
          {selectedMenuItem === SettingsMenuItem.Notifications && (
            <NotificationPreferences location={props.location} />
          )}
          {selectedMenuItem === SettingsMenuItem.Privacy && <PrivacyView />}
        </div>
      </div>
    </MainLayout>
  );
};

export default observer(SettingsView);
