export default class DocumentStore {
  private serverTitle: string | undefined;

  public constructor() {
    if (typeof window === 'object') {
      this.serverTitle = document.title;
    }
  }

  public get title(): string {
    if (typeof window === 'object') {
      return document.title;
    } else {
      return this.serverTitle || '';
    }
  }

  public set title(title: string) {
    if (typeof window === 'object') {
      document.title = title;
    } else {
      this.serverTitle = title;
    }
  }

  public setInnerTitle(title: string): void {
    this.serverTitle = title;

    if (typeof window === 'object') {
      document.title = this.serverTitle;
    }
  }

  public serialize(): null {
    return null;
  }
}
