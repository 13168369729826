import React from 'react';
import { createUseStyles } from 'react-jss';

export interface RecordsCountProps {
  recordsCount: number;
  pageBatchSize: number;
  currentPage: number;
}

const useStyles = createUseStyles({
  container: {
    alignSelf: 'center',
    fontSize: '16px',
  },
});

export const RecordsCount: React.FC<RecordsCountProps> = ({
  recordsCount,
  pageBatchSize,
  currentPage,
}) => {
  const styles = useStyles();

  const minRecordIndex = Math.max(0, 1 + (currentPage - 1) * pageBatchSize);
  const maxRecordIndex = Math.min(currentPage * pageBatchSize, recordsCount);

  return (
    <div className={styles.container}>
      {`${minRecordIndex}-${maxRecordIndex} of ${recordsCount}`}
    </div>
  );
};
