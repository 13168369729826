import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Radio, theme } from '@a_team/ui-components';
import { Checkbox, Spacing } from '@ateams/components';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    fontSize: '12px',
    fontWeight: 400,
    height: '32px',
    lineHeight: '16px',
    padding: '8px 10px',
    color: theme.colors.Grey[800],
    background: theme.colors.Grey[200],
    borderRadius: '4px',
  },
  adornmentSpacing: {
    width: Spacing.small,
  },
});

export interface ValueTagProps {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  'data-testing-id'?: string;
}

export const ValueTag: React.FC<ValueTagProps> = (props) => {
  const styles = useStyles(props);
  const { children } = props;

  return (
    <div
      className={cx(styles.container, props.className)}
      onClick={props.onClick}
      data-testing-id={props['data-testing-id']}
    >
      {props.startAdornment}
      {props.startAdornment && <div className={styles.adornmentSpacing} />}
      {children}
      {props.endAdornment}
      {props.endAdornment && <div className={styles.adornmentSpacing} />}
    </div>
  );
};

const useCheckboxValueTagStyles = createUseStyles({
  checkboxContainer: ({ checked, disabled }: CheckboxValueTagProps) => ({
    width: '16px',
    height: '16px',
    border: checked
      ? '1px solid transparent'
      : disabled
      ? '1px solid #B7B8BB'
      : `1px solid ${theme.colors.Grey[500]}`,
    borderRadius: '3px',
    marginRight: Spacing.small,
    pointerEvents: disabled ? 'none' : 'auto',
  }),
  checkboxValueTag: ({
    checked,
    disabled,
    isReadOnly,
  }: CheckboxValueTagProps) => ({
    background: checked ? theme.colors.Grey[200] : 'transparent',
    color: checked
      ? theme.colors.Grey[800]
      : disabled
      ? '#B7B8BB'
      : theme.colors.Grey[500],
    height: '32px',
    border: checked
      ? '1px solid transparent'
      : disabled
      ? '1px solid #EAEBEE'
      : `1px solid ${theme.colors.Grey[300]}`,
    cursor: isReadOnly ? 'auto' : disabled ? 'not-allowed' : 'pointer',
  }),
  checkbox: {
    pointerEvents: 'none',
  },
});

export interface CheckboxValueTagProps {
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onClick?: (isChecked: boolean) => void;
  isReadOnly?: boolean;
  className?: string;
  'data-testing-id'?: string;
}

export const CheckboxValueTag: React.FC<CheckboxValueTagProps> = (props) => {
  const styles = useCheckboxValueTagStyles(props);

  if (props.isReadOnly && !props.checked) {
    return null;
  }

  return (
    <ValueTag
      className={cx(styles.checkboxValueTag, props.className)}
      onClick={
        !props.disabled && !props.isReadOnly
          ? () => props.onClick?.(!props.checked)
          : undefined
      }
      data-testing-id={props['data-testing-id']}
    >
      {!props.isReadOnly && (
        <Checkbox
          checked={props.checked}
          className={styles.checkbox}
          checkboxContainerClassName={styles.checkboxContainer}
          color={theme.colors.Hannibal[600]}
        />
      )}
      {props.label}
    </ValueTag>
  );
};

const useRadioValueTagStyles = createUseStyles({
  radio: {
    marginRight: Spacing.small,
  },
  radioValueTag: ({
    checked,
    disabled,
    isReadOnly,
  }: CheckboxValueTagProps) => ({
    background: checked ? theme.colors.Grey[200] : 'transparent',
    color: checked
      ? theme.colors.Grey[800]
      : disabled
      ? '#B7B8BB'
      : theme.colors.Grey[500],
    height: '32px',
    border: checked
      ? '1px solid transparent'
      : disabled
      ? '1px solid #EAEBEE'
      : `1px solid ${theme.colors.Grey[300]}`,
    cursor: isReadOnly ? 'auto' : disabled ? 'not-allowed' : 'pointer',
  }),
  checkbox: {
    pointerEvents: 'none',
  },
});

export interface RadioValueTagProps {
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onClick?: (isChecked: boolean) => void;
  isReadOnly?: boolean;
  className?: string;
  'data-testing-id'?: string;
}

export const RadioValueTag: React.FC<RadioValueTagProps> = (props) => {
  const styles = useRadioValueTagStyles(props);

  if (props.isReadOnly && !props.checked) {
    return null;
  }

  return (
    <ValueTag
      className={cx(styles.radioValueTag, props.className)}
      onClick={
        !props.disabled && !props.isReadOnly
          ? () => props.onClick?.(!props.checked)
          : undefined
      }
      data-testing-id={props['data-testing-id']}
    >
      {!props.isReadOnly && (
        <Radio
          checked={props.checked}
          className={styles.radio}
          value={''}
          handleInputChange={() => null}
        />
      )}
      {props.label}
    </ValueTag>
  );
};

export interface NumberValueTagProps {
  color?: string;
  disabled?: boolean;
  onClick?: () => void;
  isReadOnly?: boolean;
  lightFontColor?: boolean;
  className?: string;
}

const useNumberValueTagStyles = createUseStyles({
  valueTag: ({
    color,
    disabled,
    isReadOnly,
    lightFontColor,
  }: NumberValueTagProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: color || 'transparent',
    color: color
      ? lightFontColor
        ? (theme.colors.Grey[0] as string)
        : theme.colors.Grey[800]
      : disabled
      ? theme.colors.Grey[300]
      : theme.colors.Grey[500],
    border: color
      ? 'none'
      : disabled
      ? `1px solid ${theme.colors.Grey[200]}`
      : `1px solid ${theme.colors.Grey[300]}`,
    height: '32px',
    width: '32px',
    cursor: isReadOnly ? 'auto' : disabled ? 'not-allowed' : 'pointer',
    userSelect: 'none',
  }),
});

export const NumberValueTag: React.FC<NumberValueTagProps> = (props) => {
  const styles = useNumberValueTagStyles(props);

  return (
    <ValueTag
      className={cx(styles.valueTag, props.className)}
      onClick={props.onClick}
    >
      {props.children}
    </ValueTag>
  );
};

NumberValueTag.defaultProps = {
  disabled: false,
};
