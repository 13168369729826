import React from 'react';
import { createUseStyles } from 'react-jss';
import InfoBox from '../InfoBox';

const useStyles = createUseStyles({
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
  },
  listElement: {
    padding: '8px 0',
  },
});

const InclusivityAndSafety = (): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <p>
        <strong>Inclusivity & Safety: </strong>Diversity and inclusion make our
        community strong. We encourage participation from the most varied and
        diverse backgrounds possible and want to be very clear about where we
        stand. Any discrimination will not be tolerated. Diversity of thoughts
        and backgrounds is at the core of making great teams and a great
        community.
      </p>

      <InfoBox withReminderCopy />

      <ol type="1">
        <li className={styles.listElement}>
          Conduct or speech which might be considered sexist, racist,
          homophobic, transphobic, ableist or otherwise discriminatory or
          offensive in nature.
        </li>
        <li className={styles.listElement}>
          Do not use unwelcome, suggestive, derogatory or inappropriate
          nicknames or terms.
        </li>
        <li className={styles.listElement}>
          Do not show disrespect towards others (jokes, innuendo, dismissive
          attitudes).
        </li>
        <li className={styles.listElement}>
          Intimidation or harassment (online or in-person).
        </li>
        <li className={styles.listElement}>
          Disrespect towards differences of opinion.
        </li>
        <li className={styles.listElement}>
          Inappropriate attention or contact. Be aware of how your actions
          affect others. If it makes someone uncomfortable, stop.
        </li>
        <li className={styles.listElement}>
          Not understanding the differences between constructive criticism and
          disparagement.
        </li>
        <li className={styles.listElement}>Sustained disruptions.</li>
        <li className={styles.listElement}>
          Violence, threats of violence or violent language.
        </li>
      </ol>
    </div>
  );
};

export default InclusivityAndSafety;
