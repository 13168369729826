import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { theme } from '@a_team/ui-components';
import { Colors } from '@ateams/components';
import { Button, ButtonProps } from '../components/button';

export enum CallToActionButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export interface CallToActionButtonProps extends ButtonProps {
  ctaType?: CallToActionButtonType;
}

const useStyles = createUseStyles({
  button: ({ ctaType }: CallToActionButtonProps) => ({
    background:
      ctaType === CallToActionButtonType.Primary ? Colors.primary : '#EFEFF0',
    color:
      ctaType === CallToActionButtonType.Primary
        ? (theme.colors.Grey[0] as string)
        : theme.colors.Grey[800],
  }),
});

export const CallToActionButton: React.FC<CallToActionButtonProps> = (
  props,
) => {
  const { ctaType, ...rest } = props;
  const styles = useStyles(props);

  return <Button {...rest} className={cx(styles.button, props.className)} />;
};

CallToActionButton.defaultProps = {
  ctaType: CallToActionButtonType.Primary,
};
