import MissionRole from '@a_team/models/dist/MissionRole';
import { hourlyRateToMonthlyRate } from '@src/views/Mission/utils';

export const getBuilderRate = (role?: MissionRole) => {
  if (role?.isFullTimeRetainer) {
    return (role.monthlyRate ?? 0).toLocaleString();
  }

  return role?.hourlyRate ?? 0;
};

export const getClientRate = (role?: MissionRole) => {
  const clientHourlyRate = role?.clientHourlyRate ?? 0;

  if (role?.isFullTimeRetainer) {
    return hourlyRateToMonthlyRate(clientHourlyRate).toLocaleString();
  }

  return clientHourlyRate;
};
