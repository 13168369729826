import React, { ReactElement } from 'react';

export type Props = JSX.IntrinsicElements['svg'];

export const Star = (props: Props): ReactElement | null => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 0L7.76336 3.57295L11.7063 4.1459L8.85317 6.92705L9.52671 10.8541L6 9L2.47329 10.8541L3.14683 6.92705L0.293661 4.1459L4.23664 3.57295L6 0Z"
      fill="white"
    />
  </svg>
);
