import React, { ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import { useStores } from '@src/stores';
import { createUseStyles } from 'react-jss';
import logo from '@src/layouts/RegistrationLegacy/logo.svg';
import Footer from '@src/layouts/RegistrationLegacy/Footer';
import coverImage from '@src/layouts/RegistrationLegacy/cover-image.jpg';
import { Breakpoints } from '@ateams/components';

interface Props {
  title: string;
  content?: ReactNode;
  children: ReactNode;
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    // overflow: 'hidden',
    minHeight: '100vh',
    color: '#222',
  },

  sideContent: {
    position: 'relative',
    flexShrink: 0,
    margin: 0,
    zIndex: 10,
    padding: '0 24px',

    '&, $header': {
      width: '100%',
    },
  },

  coverImage: {
    display: 'none',
    flexGrow: 1,
    background: '#f7f7f7',
    zIndex: 0,

    '&.empty': {
      backgroundImage: `url(${coverImage})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
  },

  header: {
    position: 'absolute',
    background: 'rgba(255,255,255, 0.95)',
    backdropFilter: 'blur(10px)',
    padding: 24,
    left: 0,
    top: 0,
    zIndex: 1000,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    coverImage: {
      display: 'block',
    },
    sideContent: {
      margin: '0 56px 40px',
      padding: 0,
      '&, $header': {
        width: '528px',
      },
    },
    header: {
      padding: '40px 40px 20px',
      position: 'fixed',
    },
  },
});

/** @deprecated signup **/
export default function RegistrationLayout(props: Props): ReactElement {
  const { title, children, content } = props;

  const styles = useStyles();
  const { document } = useStores();

  document.setInnerTitle(title);

  return (
    <div className={styles.root}>
      <div className={styles.sideContent}>
        <header className={styles.header}>
          <img src={logo} alt="logo" />
        </header>

        {children}

        <Footer />
      </div>
      <div className={cx(styles.coverImage, { empty: !content })}>
        {content}
      </div>
    </div>
  );
}
