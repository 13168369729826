import React, { ReactElement } from 'react';
import { MissionPaymentCycleId } from '@a_team/models/dist/MissionPaymentCycleObject';
import MissionPaymentCycle, {
  PaymentCycleDisplayStatus,
} from '@src/stores/Missions/MissionPaymentCycle';
import { CycleCard } from '@src/components/CycleCards';

interface Props {
  cycle: MissionPaymentCycle;
  onClick?: (id: MissionPaymentCycleId) => void;
  selected?: boolean;
  showTotals?: boolean;
}

const defaultProps: Partial<Props> = {
  showTotals: true,
};

export const PaymentCycle = (props: Props): ReactElement | null => {
  const { cycle, onClick, selected, showTotals } = props;

  const childProps = {
    label: `${cycle.formattedStartDate} - ${cycle.formattedEndDate}`,
    selected,
    onClick: onClick ? () => onClick(cycle.data.yid) : undefined,
    children:
      showTotals &&
      `${cycle.formattedTotalHours}/${cycle.formattedTotalPayments}`,
  };

  switch (cycle.displayStatus) {
    case PaymentCycleDisplayStatus.Active:
      return (
        <CycleCard status="Active" progress={cycle.progress} {...childProps} />
      );

    case PaymentCycleDisplayStatus.Submit:
      return <CycleCard status="To Submit" warning {...childProps} />;

    case PaymentCycleDisplayStatus.Submitted:
      return <CycleCard status="Submitted" {...childProps} />;

    case PaymentCycleDisplayStatus.Invoiced:
      return <CycleCard status="Invoiced" {...childProps} />;

    case PaymentCycleDisplayStatus.Paid:
      return <CycleCard status="Paid Out" success {...childProps} />;

    default:
      return null;
  }
};

PaymentCycle.defaultProps = defaultProps;
