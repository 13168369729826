import { ClientSignalObject } from '@a_team/models/src/ClientSignals';
import { apiBuilderSignals, apiClientSignals } from '@ateams/api';
import { useStores } from '@src/stores';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from './keys';
import { MissionCardObject } from '@a_team/models/dist/MissionObject';

export const useQueryClientSignalById = ({
  clientSignalId,
  onSuccess,
  enabled,
}: {
  clientSignalId: string;
  onSuccess?: (data: ClientSignalObject | null) => void;
  enabled?: boolean;
}) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.signals.getClientSignalById(
      auth.uid || '',
      clientSignalId,
    ),
    queryFn: async () => {
      return await apiClientSignals.getClientSignal(auth, clientSignalId);
    },
    onSuccess,
    enabled,
  });
};

export const useQueryClientSignalsByIds = ({
  clientSignalIds,
  onSuccess,
  enabled,
}: {
  clientSignalIds: string[];
  onSuccess?: (data: ClientSignalObject[]) => void;
  enabled?: boolean;
}) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.signals.getClientSignalsByIds(
      clientSignalIds,
      auth.uid || '',
    ),
    queryFn: async () => {
      return await apiClientSignals.getClientSignalsByIds(
        auth,
        clientSignalIds,
      );
    },
    onSuccess,
    enabled,
  });
};

export const useQueryClientSignalMissions = ({
  onSuccess,
  enabled,
}: {
  onSuccess?: (data: MissionCardObject[]) => void;
  enabled?: boolean;
}) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.signals.getClientSignalMissions(auth.uid || ''),
    queryFn: async () => {
      return await apiClientSignals.getClientSignalMissions(auth);
    },
    onSuccess,
    enabled,
  });
};

export const useDismissDiscoverySuggestion = () => {
  const { auth } = useStores();

  return useMutation({
    mutationFn: async (builderIds: string[]) => {
      return await apiBuilderSignals.dismissDiscoverySuggestion(
        auth,
        builderIds,
      );
    },
  });
};
