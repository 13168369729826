import { Button, Modal } from '@a_team/ui-components';
import { Breakpoints } from '@ateams/components';
import React, { useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm, SubmitErrorHandler } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import Duration from './Duration';
import HasManagedPeople from './HasManagedPeople';
import HasZeroToOneExperience from './HasZeroToOneExperience';
import Role from './Role';
import CompanyName from './CompanyName';
import Description from './Description';
import Skills from './Skills';
import { useStores } from '@src/stores';
import HideScrollerGlobally from '@src/components/HideScrollerGlobally';
import { useQueryTalentSpecializationRoles } from '@src/rq/specializations';
import { ExistingJob, Expertise, NewJob } from '@src/stores/Profile/models';
import {
  useCreateUserExperience,
  useUpdateUserExperience,
} from '@src/rq/experiences';
import {
  ExperienceData,
  ExperienceMemberRole,
  ExperienceObject,
  ExperienceSource,
  ExperienceType,
} from '@a_team/models/dist/ExperienceObject';
import { useGetProfileByUsername } from '@src/rq/profile';
import { CollaboratorStatus } from '@a_team/models/dist/UserObject';
import { isExistingJob } from '@src/views/Profile/Main/Jobs';
import Profile from '@src/stores/Profile/Profile';
import { NewCompanyId } from '@src/components/CompanyPicker';
import Industry from './Industry';
import SuggestedBanner from './SuggestedBanner';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import ErrorMessage from './ErrorMessage';
import ConfirmModalV2 from '../ConfirmModalV2';
import RelatedProjects from './RelatedProjects';
import AuthStore from '@src/stores/Auth';
import {
  useApplyJobSuggestion,
  useGetProfileSuggestions,
} from '@src/rq/profileSuggestions';
import { AnswerScore } from '@src/components/AnswerScore';
import { observer } from 'mobx-react';
import SkillsV2 from './SkillsV2';
import { applyRatingToSkills } from './utils';
import { isNil } from 'lodash';
import CompanyLogoFallback from '@src/components/CompanyLogoFallback';
import FileDropArea from '@src/components/FileDropArea';
import trashSvg from '../../../views/Profile/Main/Projects/EditProjectModalV2/sections/Header/trash.svg';
import editSvg from '../../../views/Profile/Main/Projects/EditProjectModalV2/sections/Header/edit.svg';
import defaultProjectLogo from '../../../views/Profile/Main/Projects/EditProjectModalV2/sections/Header/defaultProjectLogo.svg';
import useLoadingState from '@src/hooks/useLoadingState';
import { FileRejection } from 'react-dropzone';
import { FileWithPath } from 'react-dropzone';
import * as uploadcareApi from '@src/logic/uploadcare';
import { Company } from '@a_team/models/dist/Company';
import cx from 'classnames';

interface JobModalProps {
  open: boolean;
  job?: NewJob | ExistingJob;
  profile: Profile;
  auth: AuthStore;
  handleModalClose: () => void;
  onSuccess?: (job: ExperienceObject) => void;
}

const useStyles = createUseStyles({
  title: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
    margin: 0,
    textAlign: 'start',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    maxWidth: '100%',
  },
  desc: {
    fontSize: '15px',
    fontWeight: 300,
    lineHeight: '24px',
    color: '#62646A',
    textAlign: 'start',
    margin: 0,
  },
  modal: {
    width: 'auto',
    padding: 0,
    '& > div': {
      padding: 0,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    gap: 40,
    margin: 40,
    maxWidth: '800px',
  },
  closeBtn: {
    width: '80px !important',
  },
  btnsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
    padding: 16,
    borderTop: '1px solid #DADADC',
    position: 'sticky',
    bottom: 0,
    background: '#FFF',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
  },
  logoWrapper: {
    display: 'flex',
    gap: 8,
  },
  logoActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  editLogoBtn: {
    // select button
    '& > div': {
      background: 'none',
      cursor: 'pointer',
      borderRadius: 6,
      border: '1px solid #DADADC',
      height: 32,
      width: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // select first child div
      '& > div': {
        display: 'flex',
      },
    },
  },
  deleteLogoBtn: {
    background: 'none',
    cursor: 'pointer',
    borderRadius: 6,
    border: '1px solid #DADADC',
    height: 32,
    width: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  companyNameLogoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  companyNameWrapper: {
    flex: 1,
  },
  label: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: 12,
  },
  hideOnDesktop: {
    display: 'block',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      minWidth: 680,
      borderRadius: '16px !important',
    },
    companyNameLogoWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: 24,
    },
    hideOnDesktop: {
      display: 'none',
    },
  },
});

const managedPeople = Array.from({ length: 25 }, (_, i) => i + 1).map((i) => ({
  value: i.toString(),
  label: i.toString(),
}));
managedPeople.push({ value: '25+', label: '25+' });

const schema = z.object({
  jobRoleId: z.string({
    required_error: 'Specialization is required',
  }),
  jobTitle: z.string(),
  startDate: z.date({
    required_error: 'Start date is required',
  }),
  endDate: z.date().optional(),
  isOngoing: z.boolean(),
  selectedSkills: z
    .array(
      z.object({
        talentSkillId: z.string(),
        talentSkillName: z.string().optional(),
        rating: z.number().optional(),
      }),
    )
    .min(1, 'At least one skill is required')
    .max(12, "You can't have more than 12 skills."),
  hasManagedPeople: z.boolean(),
  numberOfPeopleManaged: z.string().optional(),
  hasZeroToOneExperience: z.boolean(),
  companyV2Id: z.string().optional(),
  companyName: z
    .string({
      required_error: 'Company is required',
    })
    .min(1, 'Company is required'),
  companyUrl: z.string().optional().nullable(),
  description: z
    .string({
      required_error: 'Description must be at least 100 characters',
    })
    .min(100, 'Description must be at least 100 characters')
    .max(2000, 'Description must be at most 2000 characters'),
  descriptionHTML: z.string().optional(),
  industries: z
    .array(z.string())
    .min(1, 'Industry is required')
    .max(2, `You can't have more than 2 industries.`),
  logoURL: z.string().optional(),
  relatedProjectIds: z.array(z.string()).optional().nullable(),
});

export type FormValues = z.infer<typeof schema>;

const JobModal = ({
  open,
  handleModalClose,
  job,
  profile,
  auth,
  onSuccess,
}: JobModalProps): JSX.Element => {
  const { mutate: createExperience, isLoading: isCreateLoading } =
    useCreateUserExperience();
  const { mutate: updateExperience, isLoading: isUpdateLoading } =
    useUpdateUserExperience();
  const { data: user } = useGetProfileByUsername();
  const { mutateAsync: applyJobSuggestion, isLoading: isApplyJobSuggestion } =
    useApplyJobSuggestion();
  const { data: profileSuggestions } = useGetProfileSuggestions();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const [uploading, setUploading] = useLoadingState(null);
  const [selectedCompany, setSelectedCompany] = useState<Company>();

  const defaultCompanyName =
    job?.companyName ??
    (job && isExistingJob(job) ? job.companyData?.name : undefined);
  const defaultLogoURL =
    job?.logoURL ??
    (job && isExistingJob(job) ? job.companyData?.logoUrl : undefined);

  const defaultRelatedProjectIds =
    job && isExistingJob(job) ? job.relatedProjectIds : undefined;

  const jobId = job && isExistingJob(job) ? job.eid : undefined;

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    trigger,
    watch,
    formState,
  } = useForm<FormValues>({
    defaultValues: {
      selectedSkills: applyRatingToSkills(
        job?.applicableTalentSkills || [],
        user?.allSkills || [],
      ),
      jobTitle: job?.jobRole || '',
      jobRoleId: job?.applicableTalentSpecialization?.talentSpecializationId,
      startDate: job?.startDate ? new Date(job.startDate) : new Date(),
      endDate: job?.endDate ? new Date(job.endDate) : undefined,
      hasManagedPeople: job?.hasManagedPeople || false,
      isOngoing: job && !job.endDate ? true : false,
      hasZeroToOneExperience: job?.hasZeroToOneExperience || false,
      numberOfPeopleManaged: job?.numberOfPeopleManaged || '',
      companyV2Id: job?.companyV2Id || undefined,
      companyName:
        job && defaultCompanyName && job?.companyV2Id
          ? defaultCompanyName
          : undefined,
      companyUrl: job?.websiteURL,
      description: job?.description ?? '',
      descriptionHTML: job?.descriptionHTML,
      industries: job?.industries || [],
      logoURL: defaultLogoURL,
      relatedProjectIds: defaultRelatedProjectIds,
    },
    resolver: zodResolver(schema),
  });
  const styles = useStyles();
  const { users } = useStores();

  const { data: roles } = useQueryTalentSpecializationRoles({
    query: '',
  });

  const roleOptions = useMemo(() => {
    return (
      roles?.map((role) => {
        return { label: role.name, value: role.id };
      }) || []
    );
  }, [roles]);

  const companyNameErrorRef = useRef<HTMLDivElement>(null);
  const industriesErrorRef = useRef<HTMLDivElement>(null);
  const jobRoleIdErrorRef = useRef<HTMLDivElement>(null);
  const startDateErrorRef = useRef<HTMLDivElement>(null);
  const selectedSkillsErrorRef = useRef<HTMLDivElement>(null);
  const descriptionErrorRef = useRef<HTMLDivElement>(null);

  const scrollToError = (errorRef: React.RefObject<HTMLDivElement>) => {
    errorRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const onError: SubmitErrorHandler<FormValues> = (errors) => {
    if (errors.companyName) {
      scrollToError(companyNameErrorRef);
      return;
    }

    if (errors.industries) {
      scrollToError(industriesErrorRef);
      return;
    }

    if (errors.jobRoleId) {
      scrollToError(jobRoleIdErrorRef);
      return;
    }

    if (errors.startDate) {
      scrollToError(startDateErrorRef);
      return;
    }

    if (errors.selectedSkills) {
      scrollToError(selectedSkillsErrorRef);
      return;
    }

    if (errors.description) {
      scrollToError(descriptionErrorRef);
      return;
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!data.companyName) {
      return;
    }

    // Validate if all selected skills have a rating
    if (
      auth.withSkillsSelectorInline &&
      data.selectedSkills.some((s) => !s.rating)
    ) {
      setError('selectedSkills', {
        type: 'manual',
        message: 'Skill rating is required',
      });
      scrollToError(selectedSkillsErrorRef);
      return;
    }

    if (auth.withSkillsSelectorInline) {
      const skillsToSave: Expertise[] = data.selectedSkills.map((skill) => ({
        id: skill.talentSkillId,
        name: skill.talentSkillName ?? '',
        rating: skill.rating as AnswerScore,
      }));

      profile?.saveSkillsAsMain(skillsToSave);
    }

    if (job?.isSuggested && profileSuggestions?.jobs?.sid) {
      await applyJobSuggestion({
        sid: profileSuggestions?.jobs?.sid,
        eid: (job as NewJob)._id,
        editedExperience: {
          ...job,
          name: data?.companyName,
          jobRole: data.jobTitle,
        },
      });
    }

    const jobData: ExperienceData = {
      type: 'job' as ExperienceType.Job,
      members: user
        ? [
            {
              ...user,
              ...{
                memberTitle: user?.roleCategory?.title || '',
                experienceRole: ExperienceMemberRole.Owner,
                collaboratorStatus: CollaboratorStatus.Active,
              },
            },
          ]
        : [],
      jobRole: data.jobTitle,
      source: ExperienceSource.Default,
      applicableTalentSkills: data.selectedSkills,
      applicableTalentSpecialization: data.jobRoleId
        ? { talentSpecializationId: jobRoleId }
        : undefined,
      startDate: data.startDate.toISOString(),
      endDate: data.isOngoing ? undefined : data.endDate?.toISOString(),
      companyV2Id: data.companyV2Id === NewCompanyId ? null : data.companyV2Id,
      companyName: data.companyName,
      websiteURL: data.companyUrl,
      // TODO: remove once linking to enriched companies are linked
      name: data.companyName,
      hasManagedPeople: data.hasManagedPeople,
      hasZeroToOneExperience: data.hasZeroToOneExperience,
      numberOfPeopleManaged: data.numberOfPeopleManaged,
      description: data.description,
      descriptionHTML: data.descriptionHTML,
      industries: data.industries,
      logoURL: data.logoURL,
      relatedProjectIds: data.relatedProjectIds,
    };

    if (job && isExistingJob(job)) {
      updateExperience(
        {
          data: jobData,
          experienceId: job.eid,
        },
        {
          onSuccess: () => {
            profile.refreshProfile(profile.mode);
            handleModalClose();
          },
        },
      );
    } else {
      createExperience(jobData, {
        onSuccess: (job: ExperienceObject) => {
          onSuccess && onSuccess(job);
          profile.refreshProfile(profile.mode);
          handleModalClose();
        },
      });
    }
  };

  const mainAndAdditionalSkills = useMemo(() => {
    const profile = users.profile;
    const skills = profile?.skills;

    if (!skills) {
      return [];
    }

    return [...skills.main, ...skills.additional].sort((a, b) =>
      a.name.localeCompare(b.name),
    );
  }, [users.profile?.skills.additional, users.profile?.skills.main]);

  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const isOngoing = watch('isOngoing');
  const jobRoleId = watch('jobRoleId');

  const isDirty = !!Object.keys(formState.dirtyFields).length;

  const handleFileSelected = async (
    files: Array<FileWithPath>,
    rejectedFiles: Array<FileRejection>,
  ) => {
    try {
      setUploading(null);

      if (rejectedFiles.length > 0) {
        return setUploading(new Error(rejectedFiles[0].errors[0].message));
      }

      if (!files.length) {
        return;
      }

      const [file] = files;

      setUploading(true);

      const logoUrl = await uploadcareApi.uploadFile(
        new File([file], file.name, { type: file.type }),
      );

      setValue('logoURL', logoUrl);

      setUploading('Resume Uploaded');
      setUploading(null);
    } catch (err) {
      setUploading(err as Error);
    }
  };

  const onRemoveIcon = () => {
    setValue('logoURL', '');
  };

  const handleClose = () => {
    setShowConfirmModal(false);
    handleModalClose();
  };

  const isConfirmButtonLoading =
    isCreateLoading || isUpdateLoading || isApplyJobSuggestion;

  const isCompanySelected = !!watch('companyV2Id');
  const logoUrl = watch('logoURL');

  const isCustomCompanyLogo = logoUrl?.includes('ucarecdn.com');

  return (
    <>
      <Modal
        variant="slideUp"
        isOpen={open}
        onClose={() => {
          if (isDirty) {
            setShowConfirmModal(true);
          } else {
            handleModalClose();
          }
        }}
        className={styles.modal}
        shouldHideGradientStroke={true}
        style={{ display: showConfirmModal ? 'none' : 'flex' }}
      >
        <form>
          <div className={styles.container} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.wrapper}>
              {job?.isSuggested && <SuggestedBanner />}

              <div>
                <h4 className={styles.title}>Create a job experience</h4>
                <p className={styles.desc}>
                  Jobs help companies understand your past experience.
                </p>
              </div>
              <div className={styles.companyNameLogoWrapper}>
                <div>
                  <label className={cx(styles.label, styles.hideOnDesktop)}>
                    Company logo
                  </label>
                  <span className={styles.logoWrapper}>
                    <CompanyLogoFallback
                      logoUrl={
                        logoUrl ||
                        selectedCompany?.logoUrl ||
                        defaultProjectLogo
                      }
                      size={72}
                    />

                    {isCompanySelected && (
                      <div className={styles.logoActions}>
                        <div className={styles.editLogoBtn}>
                          <FileDropArea
                            disabled={!isNil(uploading)}
                            onFileDrop={handleFileSelected}
                          >
                            {({ openDialog }) => (
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openDialog();
                                }}
                              >
                                <img
                                  src={editSvg}
                                  alt="Edit"
                                  width="16"
                                  height="16"
                                />
                              </div>
                            )}
                          </FileDropArea>
                        </div>

                        {isCustomCompanyLogo && (
                          <button
                            type="button"
                            className={styles.deleteLogoBtn}
                            onClick={onRemoveIcon}
                          >
                            <img
                              src={trashSvg}
                              alt="Remove"
                              width="16"
                              height="16"
                            />
                          </button>
                        )}
                      </div>
                    )}
                  </span>
                </div>
                <div
                  ref={companyNameErrorRef}
                  className={styles.companyNameWrapper}
                >
                  <CompanyName
                    isCustomCompanyLogo={!!isCustomCompanyLogo}
                    setSelectedCompany={setSelectedCompany}
                    register={register}
                    setValue={setValue}
                    trigger={trigger}
                    watch={watch}
                    job={job}
                  />
                  {formState.errors.companyName?.message && (
                    <ErrorMessage
                      message={formState.errors.companyName.message}
                    />
                  )}
                </div>
              </div>
              <div ref={industriesErrorRef}>
                <Industry
                  register={register}
                  setValue={setValue}
                  trigger={trigger}
                  watch={watch}
                />
                {formState.errors.industries?.message && (
                  <ErrorMessage message={formState.errors.industries.message} />
                )}
              </div>
              <div ref={jobRoleIdErrorRef}>
                <Role
                  register={register}
                  setValue={setValue}
                  trigger={trigger}
                  roles={roleOptions}
                  jobRoleId={jobRoleId}
                />
                {formState.errors.jobRoleId?.message && (
                  <ErrorMessage message={formState.errors.jobRoleId.message} />
                )}
              </div>
              <div ref={startDateErrorRef}>
                <Duration
                  startDate={startDate}
                  endDate={endDate}
                  isOngoing={isOngoing}
                  setValue={setValue}
                  trigger={trigger}
                  setStillWorking={() => {
                    setValue('endDate', undefined, {
                      shouldDirty: true,
                    });
                    setValue('isOngoing', !isOngoing, {
                      shouldDirty: true,
                    });
                  }}
                />
                {formState.errors.startDate?.message && (
                  <ErrorMessage message={formState.errors.startDate.message} />
                )}
              </div>
              <div ref={selectedSkillsErrorRef}>
                {auth.withSkillsSelectorInline ? (
                  <SkillsV2
                    jobSkills={
                      job?.applicableTalentSkills?.map(
                        (skill) => skill.talentSkillId,
                      ) ?? []
                    }
                    profileSkills={user?.allSkills || []}
                    setValue={setValue}
                  />
                ) : (
                  <Skills
                    skills={mainAndAdditionalSkills}
                    register={register}
                    setValue={setValue}
                    trigger={trigger}
                    watch={watch}
                  />
                )}
                {formState.errors.selectedSkills?.message && (
                  <ErrorMessage
                    message={formState.errors.selectedSkills.message}
                  />
                )}
              </div>
              <div ref={descriptionErrorRef}>
                <Description
                  watch={watch}
                  trigger={trigger}
                  setValue={setValue}
                />
                {formState.errors.description?.message && (
                  <ErrorMessage
                    message={formState.errors.description.message}
                  />
                )}
              </div>
              <HasManagedPeople
                managedPeople={managedPeople}
                watch={watch}
                trigger={trigger}
                setValue={setValue}
                register={register}
              />
              <HasZeroToOneExperience
                watch={watch}
                trigger={trigger}
                setValue={setValue}
              />
              {auth.withRelatedExperiences && (
                <RelatedProjects
                  jobId={jobId}
                  register={register}
                  setValue={setValue}
                  trigger={trigger}
                  watch={watch}
                  projects={profile.projects}
                />
              )}
            </div>
          </div>
          <div className={styles.btnsContainer}>
            <Button
              className={styles.closeBtn}
              size="md"
              variant="secondary"
              onClick={handleModalClose}
            >
              Close
            </Button>
            <Button
              loading={isConfirmButtonLoading}
              disabled={isConfirmButtonLoading}
              size="md"
              onClick={handleSubmit(onSubmit, onError)}
            >
              Add experience
            </Button>
          </div>
        </form>
        {open && <HideScrollerGlobally />}
      </Modal>
      <ConfirmModalV2
        title="Are you sure you want to quit?"
        description="Your progress will be lost and the details you entered will not be saved."
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onCancel={() => setShowConfirmModal(false)}
        onCancelText="Never mind"
        onConfirm={handleClose}
        onConfirmText="Quit"
        onConfirmColor="secondaryDark"
      />
    </>
  );
};

export default observer(JobModal);
