import { AdminNotesScore } from '@a_team/models/dist/AdminNotesObject';
import {
  ExpertiseScoreLabels,
  InteractionExperienceLabels,
  EnglishLevelLabels,
  AccentScoreLabels,
  ScoreLabelsMap,
} from '@a_team/models/dist/AdminNotesObject';

function toScoreOptions(scoreLabels: ScoreLabelsMap): ScoreOption[] {
  return Object.entries(scoreLabels).map(([key, label]) => ({
    value: parseInt(key) as AdminNotesScore, // Object.entries converts the key to string
    label,
  }));
}
export interface ScoreOption {
  label: string;
  value: AdminNotesScore;
}
export const ExpertiseScoreOptions = toScoreOptions(ExpertiseScoreLabels);
export const InteractionExperienceOptions = toScoreOptions(
  InteractionExperienceLabels,
);
export const EnglishLevelOptions = toScoreOptions(EnglishLevelLabels);
export const AccentScoreOptions = toScoreOptions(AccentScoreLabels);
