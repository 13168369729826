import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import playIcon from './play-icon.svg';

interface PlayMissionVideoProps {
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    background: 'rgba(34, 34, 34, 0.8)',
    color: '#FFFFFF',
    padding: 8,
    width: 190,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    borderRadius: 8,
  },
});

const PlayMissionVideo = ({
  className,
}: PlayMissionVideoProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={cx(styles.container, className)}>
      <img src={playIcon} alt="play" />
      Play mission video
    </div>
  );
};

export default PlayMissionVideo;
