import { Colors } from '@ateams/components';
import React, { ReactElement, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface ImageCropInlineProps {
  src: string;
  onCropChange?: () => void;
  onCropSubmit: (crop: Crop) => void;
  disregardCropping: () => void;
  onImageLoaded: (image: HTMLImageElement) => void;
  aspect?: number;
  circularCrop?: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    '& .ReactCrop__image': {
      width: 'auto',
      maxHeight: 600,
      objectFit: 'cover',
    },
  },
  button: {
    position: 'absolute',
    transition: 'opacity 0.2s ease-in-out',
    padding: '6px 12px',
    borderRadius: 4,
    background: '#FFFFFF',
    border: '1px solid #DADADC',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 500,
    '&:not([disabled]):hover': {
      opacity: 0.7,
    },
  },
  done: {
    top: 16,
    right: 16,
  },
  trash: {
    top: 16,
    right: 80,
  },
  errorMessage: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: Colors.danger,
    textAlign: 'center',
  },
});

const ImageCropInline = ({
  src,
  aspect,
  onCropSubmit,
  disregardCropping,
  onImageLoaded,
  circularCrop,
}: ImageCropInlineProps): ReactElement => {
  const styles = useStyles();
  const [hasError, setError] = useState(false);
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 100,
    ...(aspect ? { aspect } : { height: 100 }),
  });

  return (
    <div className={styles.container}>
      <ReactCrop
        src={src}
        onImageLoaded={(img) => {
          onImageLoaded(img);
          setError(false);
        }}
        crop={crop}
        onChange={setCrop}
        keepSelection
        circularCrop={circularCrop}
        onImageError={() => {
          setError(true);
        }}
      />
      {hasError && (
        <div className={styles.errorMessage}>
          Sorry, we couldn't load this image. Please try another one!
        </div>
      )}
      <button
        type="button"
        className={cx(styles.button, styles.trash)}
        onClick={() => disregardCropping()}
      >
        Delete
      </button>
      <button
        type="button"
        className={cx(styles.button, styles.done)}
        onClick={() => onCropSubmit(crop)}
      >
        Done
      </button>
    </div>
  );
};

export default ImageCropInline;
