import { Breakpoints, Select, SelectOption } from '@ateams/components';
import Checkbox from '@src/components/Checkbox';
import TextButton from '@src/components/TextButton';
import { observer } from 'mobx-react';
import React from 'react';

import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  form: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  inputsWrapper: {
    width: '100%',
  },
  inputsRow: {
    '& > *': {
      marginTop: '10px',
    },
  },
  select: {
    margin: '0px',
    marginBottom: '5px',
  },
  clear: {
    textAlign: 'left',
    marginTop: 0,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    form: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    inputsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: 'auto',
    },
    inputsRow: {
      display: 'flex',
      flexDirection: 'row',
      '& > *': {
        marginRight: '12px !important',
        flexBasis: '220px',
      },
    },
  },
});

interface MissionFiltersV2Props {
  selectedSkills: string[];
  setSelectedSkills: (skills: string[]) => void;
  selectedRoleCategories: string[];
  setSelectedRoleCategories: (roles: string[]) => void;
  selectedIndustries: string[];
  setSelectedIndustries: (industries: string[]) => void;
  filterByUserLocation: boolean;
  setFilterByUserLocation: (filter: boolean) => void;
  filterByLookingForBuilders: boolean;
  setFilterByLookingForBuilders: (filter: boolean) => void;
  filterByAvailableMissions: boolean;
  setFilterByAvailableMissions: (filter: boolean) => void;
  disabled?: boolean;
  allSkills:
    | {
        rating?: number;
        talentSkillId: string;
        name?: string;
      }[];

  allRoleCategories:
    | {
        id: string;
        title: string;
      }[];

  allIndustries:
    | {
        id: string;
        name?: string;
      }[];
}

const MissionFiltersV2: React.FC<MissionFiltersV2Props> = ({
  selectedSkills,
  setSelectedSkills,
  selectedRoleCategories,
  setSelectedRoleCategories,
  selectedIndustries,
  setSelectedIndustries,
  filterByUserLocation,
  setFilterByUserLocation,
  filterByLookingForBuilders,
  setFilterByLookingForBuilders,
  filterByAvailableMissions,
  setFilterByAvailableMissions,
  disabled,
  allSkills,
  allRoleCategories,
  allIndustries,
}) => {
  const styles = useStyles();

  const handleClearAll = () => {
    setSelectedSkills([]);
    setSelectedRoleCategories([]);
    setFilterByUserLocation(false);
    setFilterByLookingForBuilders(false);
    setFilterByAvailableMissions(false);
  };

  const hasActiveFilters =
    selectedSkills.length > 0 ||
    selectedRoleCategories.length > 0 ||
    selectedIndustries.length > 0 ||
    filterByUserLocation ||
    filterByLookingForBuilders ||
    filterByAvailableMissions;

  return (
    <form className={styles.form}>
      <div className={styles.inputsWrapper}>
        <div className={styles.inputsRow}>
          <Select
            isDisabled={disabled}
            hideTags
            isMulti
            isSearchable
            showItemCount
            itemCountPrefix="Skills"
            placeholder=""
            className={styles.select}
            value={selectedSkills.map((skillId) => ({
              value: skillId,
              label:
                allSkills?.find((s) => s.talentSkillId === skillId)?.name || '',
            }))}
            onChange={(selected) => {
              const s = selected as SelectOption[];
              setSelectedSkills(s.map((option) => option.value));
            }}
            options={allSkills?.map((skill) => ({
              value: skill.talentSkillId,
              label: skill.name || '',
            }))}
          />
          <Select
            isDisabled={disabled}
            hideTags
            isMulti
            isSearchable
            showItemCount
            itemCountPrefix="Roles"
            placeholder=""
            className={styles.select}
            value={selectedRoleCategories.map((roleId) => ({
              value: roleId,
              label:
                allRoleCategories?.find((r) => r.id === roleId)?.title || '',
            }))}
            onChange={(selected) => {
              const s = selected as SelectOption[];
              setSelectedRoleCategories(s.map((option) => option.value));
            }}
            options={allRoleCategories?.map((role) => ({
              value: role.id,
              label: role.title || '',
            }))}
          />
          <Select
            isDisabled={disabled}
            hideTags
            isMulti
            isSearchable
            showItemCount
            itemCountPrefix="Industries"
            placeholder=""
            className={styles.select}
            value={selectedIndustries.map((industryId) => ({
              value: industryId,
              label:
                allIndustries?.find((i) => i.id === industryId)?.name || '',
            }))}
            onChange={(selected) => {
              const s = selected as SelectOption[];
              setSelectedIndustries(s.map((option) => option.value));
            }}
            options={allIndustries?.map((industry) => ({
              value: industry.id,
              label: industry.name || '',
            }))}
          />
          {hasActiveFilters && (
            <div>
              <TextButton
                disabled={disabled}
                onClick={handleClearAll}
                className={styles.clear}
                color="primary"
              >
                Clear All
              </TextButton>
            </div>
          )}
        </div>
        <div className={styles.inputsRow}>
          <Checkbox
            disabled={disabled}
            checked={filterByLookingForBuilders}
            onChange={(e): void =>
              setFilterByLookingForBuilders(e.target.checked)
            }
            customLabel="Looking for builders"
          />
          <Checkbox
            disabled={disabled}
            checked={filterByAvailableMissions}
            onChange={(e): void =>
              setFilterByAvailableMissions(e.target.checked)
            }
            customLabel="Filter by my availability"
          />
          <Checkbox
            disabled={disabled}
            checked={filterByUserLocation}
            onChange={(e): void => setFilterByUserLocation(e.target.checked)}
            customLabel="Filter by my location"
          />
        </div>
      </div>
    </form>
  );
};

export default observer(MissionFiltersV2);
