import { Button } from '@a_team/ui-components';
import React from 'react';
import { useCommonStyles } from './commonStyles';

interface RequestExpiredProps {
  onClose: () => void;
}

const RequestExpired = ({ onClose }: RequestExpiredProps): JSX.Element => {
  const styles = useCommonStyles();

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>
        This interview request is no longer available
      </h4>
      <div className={styles.desc} style={{ color: '#818388' }}>
        The request has either expired or was declined.
      </div>
      <div className={styles.desc} style={{ marginTop: 2, color: '#818388' }}>
        If you have questions, reach out to help@a.team.
      </div>
      <div className={styles.btnsContainer}>
        <Button onClick={onClose} variant="secondary">
          Close
        </Button>
      </div>
    </div>
  );
};

export default RequestExpired;
