import { Toggle } from '@a_team/ui-components';
import { observer } from 'mobx-react';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface VisibilityProps {
  title: string;
  description: string;
  checked: boolean;
  handleToggle: () => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #DADADC',
    padding: 16,
    borderRadius: 8,
  },
  title: {
    marginTop: 0,
    marginBottom: 4,
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '26px',
  },
  desc: {
    marginTop: 0,
    marginBottom: 16,
    fontSize: '15px',
    lineHeight: '24px',
    maxWidth: 780,
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
});

const Visibility = ({
  title,
  description,
  checked,
  handleToggle,
}: VisibilityProps): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.desc}>{description}</p>
      <div className={styles.toggleContainer}>
        <Toggle checked={checked} onChange={handleToggle} />
        <span>
          {checked ? 'You are discoverable' : 'You are not discoverable'}
        </span>
      </div>
    </div>
  );
};

export default observer(Visibility);
