import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { SelectOption, SelectProps, IndicatorSeparator } from './index';
import AsyncReactSelect from 'react-select/async';
import { SelectComponentsConfig, OptionTypeBase } from 'react-select';
import debounce from 'lodash.debounce';
import { customStyles, selectThemeColors, selectThemeStyles } from './styles';
import { MultiValueList } from './MultiValueList';
import { DropdownIndicator } from './DropdownIndicator';
import { InputLabel } from './InputLabel';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { Margins, Widths } from '..';
import { Option } from './Option';
import { RegularSelectInput } from './RegularSelectInput';

export interface AsyncSelectProps<
  IsMulti extends boolean = false,
  T extends SelectOption = SelectOption,
> extends Omit<SelectProps, 'onChange'> {
  loadOptions: (value: string) => Promise<T[]>;
  loadingMessage?: (obj: { inputValue?: string }) => string;
  onChange?: (option: (IsMulti extends true ? T[] : T) | null) => void;
  emptyValue?: boolean;
  menuPortalTarget?: HTMLElement | null;

  /** @default false */
  useDebounce?: boolean;

  /** In milliseconds @default 350 */
  debounceTime?: number;

  /**
   * Replaces the default Input component from react-select with RegularSelectInput
   * This is used to avoid performance issues when we have many react-select instances
   * {@link https://buildateam.atlassian.net/browse/NEXUS-646}
   */
  useRegularSelectInput?: boolean;

  overrideStyles?: SelectProps<IsMulti, T>['styles'];
  noBorder?: boolean;
}

const useStyles = createUseStyles<SelectProps>({
  root: {
    width: (props) => Widths[props.width || 'default'],
    margin: (props) => Margins[props.margin || 'default'],
  },
});

const AsyncSelect = <
  IsMulti extends boolean = false,
  T extends SelectOption = SelectOption,
>(
  props: AsyncSelectProps<IsMulti, T>,
): ReactElement => {
  const styles = useStyles({ margin: props.margin, width: props.width });
  const {
    error,
    onChange,
    value,
    className,
    label,
    required,
    hideTags,
    loadOptions: loadOptionsProp,
    useDebounce = false,
    debounceTime = 350,
    controlShouldRenderValue = !props.isMulti,
    overrideStyles,
    components,
    useRegularSelectInput = false,
    emptyValue,
    menuPortalTarget,
    ...rest
  } = props;
  const [localValue, setLocalValue] = useState(props.defaultValue || value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const selectStyles = useMemo(() => {
    return { ...customStyles(props.noBorder), ...overrideStyles };
  }, [overrideStyles]);

  const removeOption = (removedOption: T) => {
    const newValue = (localValue as T[]).filter(
      (option) => option.value !== removedOption.value,
    );
    setLocalValue(newValue);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onChange && onChange(newValue);
  };

  /**
   * {@link https://stackoverflow.com/questions/65730793/react-hook-form-controller-react-asyncselect-lodash-debounce-failed-show}
   */
  const loadOptions = React.useCallback(
    useDebounce
      ? debounce((expression, cb) => {
          loadOptionsProp(expression).then(cb);
        }, debounceTime)
      : loadOptionsProp,
    [],
  );

  const selectComponents = useMemo(() => {
    const selectComponents: SelectComponentsConfig<OptionTypeBase, IsMulti> = {
      DropdownIndicator,
      IndicatorSeparator,
    };

    if (props.isMulti) {
      selectComponents['Option'] = Option;
    }

    if (useRegularSelectInput) {
      selectComponents['Input'] = RegularSelectInput;
    }

    return {
      ...selectComponents,
      ...components,
    };
  }, [props.isMulti, useRegularSelectInput, components]);

  return (
    <div
      className={cx(styles.root, className)}
      data-testing-id={props['data-testing-id']}
    >
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <AsyncReactSelect
        value={
          emptyValue ? null : typeof value === 'undefined' ? localValue : value
        }
        cacheOptions
        defaultOptions
        menuPortalTarget={menuPortalTarget}
        components={selectComponents}
        closeMenuOnSelect={!props.isMulti}
        controlShouldRenderValue={controlShouldRenderValue}
        hideSelectedOptions={!props.isMulti}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            ...selectThemeColors(error),
          },
          ...selectThemeStyles,
        })}
        loadOptions={loadOptions}
        onChange={(item) => {
          setLocalValue(item);
          onChange && onChange(item);
        }}
        isClearable={false}
        isDisabled={!!props.disabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        styles={selectStyles}
        {...rest}
      />
      {!hideTags && props.isMulti && (
        <MultiValueList<T>
          onRemove={removeOption}
          values={(localValue as T[]) || []}
        />
      )}
    </div>
  );
};

export default AsyncSelect;
