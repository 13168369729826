import { Checkbox } from '@ateams/components';
import MissionRole, { MissionAdminRole } from '@a_team/models/dist/MissionRole';
import TeamGraphRoleSearchUrl from '@src/views/Mission/AboutMission/TeamGraphRoleSearchUrl';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import CopySourcedBuilderInviteUrl from '../../CopySourcedBuilderInviteUrl';

interface LookingForMoreApplicantsProps {
  role: MissionRole | MissionAdminRole;
  onLookingForMoreApplicationsToggle?: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
});

function LookingForMoreApplicants({
  role,
  onLookingForMoreApplicationsToggle,
}: LookingForMoreApplicantsProps): JSX.Element {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Checkbox
        checked={Boolean((role as MissionAdminRole).lookingForApplications)}
        onChange={onLookingForMoreApplicationsToggle}
        label={'Looking for more applicants'}
      />
      <span style={{ marginLeft: '0.5rem' }}>
        {'•'} <TeamGraphRoleSearchUrl role={role} />
      </span>
      <span style={{ marginLeft: '0.5rem' }}>
        {'•'} <CopySourcedBuilderInviteUrl roleId={role.rid} />
      </span>
    </div>
  );
}

export default observer(LookingForMoreApplicants);
