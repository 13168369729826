import AspectRatioBox from '@src/components/AspectRatioBox';
import { CDN_BASE_URL } from '@src/config';
import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import CompanyLogoFallback from '@src/components/CompanyLogoFallback';

interface NoCoverProps {
  number: number;
  companyLogo?: string;
  className?: string;
}

const useStyles = createUseStyles<{
  noCoverColor: string;
}>({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  logoContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '0 24px',
  },
  logoImgContainer: {
    width: 64,
    height: 64,
  },
  logoImgWrapper: {
    borderRadius: '8px',
  },
  noLogo: {
    height: 64,
    width: 64,
    borderRadius: '8px',
    background:
      'linear-gradient(197.15deg, rgb(148, 254, 12) 0%, rgb(84, 214, 255) 88.21%);',
  },
});

const defaultCovers = [
  `${CDN_BASE_URL}/website/mission-cover-01.jpg`,
  `${CDN_BASE_URL}/website/mission-cover-02.jpg`,
  `${CDN_BASE_URL}/website/mission-cover-03.jpg`,
  `${CDN_BASE_URL}/website/mission-cover-04.jpg`,
  `${CDN_BASE_URL}/website/mission-cover-05.jpg`,
  `${CDN_BASE_URL}/website/mission-cover-06.jpg`,
  `${CDN_BASE_URL}/website/mission-cover-07.jpg`,
  `${CDN_BASE_URL}/website/mission-cover-08.jpg`,
  `${CDN_BASE_URL}/website/mission-cover-09.jpg`,
  `${CDN_BASE_URL}/website/mission-cover-10.jpg`,
  `${CDN_BASE_URL}/website/mission-cover-11.jpg`,
  `${CDN_BASE_URL}/website/mission-cover-12.jpg`,
];

export const noCoverColors = [
  'linear-gradient(180deg, #44B1C9 0%, #0B6CA3 100%)',
  'linear-gradient(180deg, #7F4DE8 0%, #6A1ECA 100%)',
  'linear-gradient(180deg, #3CBA92 0%, #0BA360 100%)',
];

const NoCover = ({
  number,
  className,
  companyLogo,
}: NoCoverProps): JSX.Element => {
  const styles = useStyles({
    noCoverColor: noCoverColors[number % noCoverColors.length],
  });
  const coverIndex = number % defaultCovers.length;
  const coverUrl = defaultCovers[coverIndex];

  return (
    <div className={cx(styles.container, className)}>
      <AspectRatioBox aspectRatio={16 / 9}>
        <div>
          <img className={styles.image} src={coverUrl} alt="cover" />
          <div className={styles.logoContainer}>
            {companyLogo ? (
              <div className={styles.logoImgContainer}>
                <AspectRatioBox
                  aspectRatio={1}
                  className={styles.logoImgWrapper}
                >
                  <CompanyLogoFallback logoUrl={companyLogo} />
                </AspectRatioBox>
              </div>
            ) : (
              <div className={styles.noLogo} />
            )}
          </div>
        </div>
      </AspectRatioBox>
    </div>
  );
};

export default NoCover;
