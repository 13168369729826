import { Icon, IconType } from '@ateams/components';
import MissionRole from '@a_team/models/dist/MissionRole';
import SectionHeading from '@src/components/SectionHeading';
import {
  continentsOptionsMap,
  rawCountryList,
} from '@src/helpers/rawCountryList';
import React, { ReactElement, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Modal, { ModalBasicProps } from '..';
import Continent from './Continent';
import { Colors } from '@ateams/components';

export interface CountryObject {
  name: string;
  flag: string;
  flagSVG: string;
}

export interface ContinentObject {
  name: string;
  countries: CountryObject[];
  totalCountries: number;
}

interface AllLocationsModalProps extends ModalBasicProps {
  role: MissionRole | undefined;
  userCountry: [CountryObject | undefined, boolean];
}

const useStyles = createUseStyles({
  modal: {
    maxWidth: '700px',
    width: '100% !important',
    padding: '24px !important',
    outline: 'none',
  },
  title: {
    fontSize: '28px',
    marginBottom: '8px',
  },
  description: {
    fontSize: '15px',
  },
  userLocation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#F7F7F7',
    padding: '19px 16px',
    marginTop: '24px',
  },
  countryName: {
    display: 'flex',
    alignItems: 'center',
  },
  countryFlag: {
    marginRight: '8px',
  },
  searchInputTitle: {
    fontSize: '15px',
    margin: '24px 0 12px 0',
  },
  searchInput: {
    width: '100%',
    border: `1px solid ${Colors.regular}`,
    padding: '12px 16px',
    marginBottom: '24px',
    borderRadius: '8px',
    '&::placeholder': {
      color: Colors.regular,
    },
  },
  icon: {
    marginRight: '10px',
  },
});

const AllLocationsModal = ({
  role,
  userCountry,
  onClose,
  open,
}: AllLocationsModalProps): ReactElement => {
  const styles = useStyles();
  const [search, setSearch] = useState('');
  const [currentUserCountry, userCountryInRoleLocations] = userCountry;
  const data = new Map<string, ContinentObject>();

  for (const code of role?.locations || []) {
    const country = rawCountryList.find((country) => country.code === code);
    if (!country) {
      continue;
    }

    if (
      search &&
      !country.name.toLowerCase().includes(search.toLowerCase().toLowerCase())
    ) {
      continue;
    }

    const continent = continentsOptionsMap[country.continent];
    if (!continent) {
      continue;
    }

    let continentRecord: ContinentObject | undefined = data.get(
      country.continent,
    );

    if (!continentRecord) {
      continentRecord = {
        name: continent.label,
        countries: [],
        totalCountries: continent.options.length,
      };
    }

    const countryRecord = continentRecord.countries.find(
      (c) => c.name === country.name,
    );
    if (countryRecord) {
      continue;
    }

    data.set(continent.value, {
      ...continentRecord,
      countries: [...continentRecord.countries, country],
    });
  }

  return (
    <Modal
      onClose={onClose}
      className={styles.modal}
      open={open}
      data-testing-id="all-locations-modal"
    >
      <div>
        <SectionHeading className={styles.title} isFirst>
          Allowed Locations
        </SectionHeading>
        <div className={styles.description}>
          The locations that client marked as required will show up here. If
          your country is not on a list, that means that it’s not supported.
        </div>
        {userCountryInRoleLocations && currentUserCountry && (
          <div className={styles.userLocation}>
            <div className={styles.countryName}>
              <span className={styles.countryFlag}>
                {currentUserCountry.flag}
              </span>
              {currentUserCountry.name} is allowed
            </div>
            <Icon
              className={styles.icon}
              type={IconType.CheckBlack}
              size={'exact'}
            />
          </div>
        )}
        <div>
          <div className={styles.searchInputTitle}>Search locations</div>
          <div>
            <input
              type="text"
              value={search}
              className={styles.searchInput}
              placeholder="Enter country name"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      {Array.from(data.entries()).map((value, i) => {
        const continent = value[1];
        return (
          <Continent
            key={`continent-${continent?.name}`}
            continent={continent}
            open={i === 0}
          />
        );
      })}
    </Modal>
  );
};

export default AllLocationsModal;
