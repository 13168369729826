import React, { ReactElement, CSSProperties, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import rank1 from './rank1.svg';
import rank2 from './rank2.svg';
import rank3 from './rank3.svg';
import UserAvatar from '@src/components/UserAvatar';
import {
  CurrentUserObject,
  UserCardObject,
} from '@a_team/models/dist/UserObject';
import { getConnectButtonStatus } from '@src/helpers/connection';
import { truncate } from '@src/helpers/strings';
import ConnectButton from '@src/components/ConnectButton';
import { Link } from 'react-router-dom';
import { ProfileLocation } from '@src/locations';
import MessageButton from '@src/components/MessageButton';

interface Props {
  level?: string;
  topMember?: boolean;
  rank?: number;
  user: UserCardObject | CurrentUserObject;
  minimal?: boolean;
  withActionButtons?: boolean;
  withOneLiner?: boolean;
  children?: ReactNode;
  onClick?: (user: UserCardObject | CurrentUserObject) => void;
  style?: CSSProperties;
  className?: string;
}

const defaultProps: Partial<Props> = {
  withActionButtons: true,
  withOneLiner: true,
};

const useStyles = createUseStyles({
  root: (props: Props) => ({
    width: props.topMember ? 377 : 272,
    height: props.topMember ? 330 : 400,
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
    position: 'relative',
    color: '#222222',
    background: '#fff',
    animation: '$fadeIn 300ms ease-in-out 300ms both',
    ...(props.minimal && {
      height: 288,
    }),
    ...(props.children && {
      height: 'auto',
    }),
  }),
  cardHeader: {
    height: '56px',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
    padding: '16px',
    position: 'relative',
  },
  tag: {
    border: '1px solid #AB54FF',
    borderRadius: '40px',
    backgroundColor: 'white',
    width: '46px',
    height: '24px',
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#AB54FF',
  },
  userAvatar: {
    marginTop: -25,
    position: 'relative',
    marginBottom: 16,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  name: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#222',
  },
  profession: {
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#62646A',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  connection: {
    textAlign: 'center',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  footer: {
    display: 'flex',
    paddingLeft: '24px',
    paddingRight: '24px',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    height: '60px',
  },
  divider: {
    borderRight: '1px solid #C0C0C0',
    height: '16px',
    marginLeft: '24px',
    marginRight: '24px',
  },
  connectButton: {
    flexGrow: 0,
  },
  profileButton: {
    color: '#08A5FE',
  },
  oneLiner: {
    maxWidth: '80%',
    wordBreak: 'break-all',
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
});

export default function UserCard(props: Props): ReactElement {
  const styles = useStyles(props);
  const { fullName, username, profilePictureURL, aboutMe, connection } =
    props.user;

  const buttonStatus = getConnectButtonStatus(connection);
  const showOneLiner = !props.topMember && !props.minimal && props.withOneLiner;

  return (
    <div
      className={cx(styles.root, props.className)}
      style={{ ...props.style }}
    >
      <Link
        to={ProfileLocation(username)}
        onClick={() => (props.onClick ? props.onClick(props.user) : undefined)}
      >
        <div className={styles.cardHeader} style={{ background: '#8B39DB' }}>
          {props.level && <div className={styles.tag}>{props.level}</div>}
          {props.rank && (
            <img
              src={props.rank === 1 ? rank1 : props.rank === 2 ? rank2 : rank3}
              alt="rank"
              style={{ position: 'absolute', top: '138px', left: '215px' }}
            />
          )}
        </div>
      </Link>
      <Link
        to={ProfileLocation(username)}
        onClick={() => (props.onClick ? props.onClick(props.user) : undefined)}
      >
        <div className={styles.userAvatar}>
          <UserAvatar
            src={profilePictureURL}
            size={props.minimal ? 120 : 160}
          />
        </div>
      </Link>
      <div>
        <Link
          to={ProfileLocation(username)}
          onClick={() =>
            props.onClick ? props.onClick(props.user) : undefined
          }
        >
          <div className={styles.name}>{fullName}</div>
        </Link>
        <div className={styles.profession} style={{ marginTop: '2px' }}>
          <div style={{ width: '224px' }}>
            {
              props.user?.talentProfile?.talentSpecializations
                ?.mainTalentSpecialization?.name
            }
          </div>
        </div>
        {props.children}
        <>
          {showOneLiner && aboutMe && (
            <div className={styles.connection} style={{ marginTop: '27px' }}>
              <div className={styles.oneLiner}>{truncate(aboutMe, 50)}</div>
            </div>
          )}
          {props.withActionButtons && (
            <div className={styles.footer}>
              <MessageButton user={props.user} />
              {props.minimal ? (
                <>
                  <div className={styles.divider} />
                  <Link to={ProfileLocation(username)}>
                    <div className={styles.profileButton}>Profile</div>
                  </Link>
                </>
              ) : (
                !!buttonStatus && (
                  <>
                    <div className={styles.divider} />
                    <ConnectButton
                      className={styles.connectButton}
                      user={props.user}
                    />
                  </>
                )
              )}
            </div>
          )}
        </>
      </div>
    </div>
  );
}

UserCard.defaultProps = defaultProps;
