import React, { ReactElement } from 'react';
import { ToastProps } from 'react-toastify/dist/types';
import { Breakpoints, Button, Colors } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface Props {
  isShown: boolean;
  toastProps?: ToastProps;
  onClose?: (shouldUpdate: boolean) => void;
  children?: ReactElement;
  className?: string;
}

const useStyles = createUseStyles({
  root: { height: 0, transition: 'all .5s ease-in-out', overflow: 'hidden' },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Inter',
    flexWrap: 'wrap',
    gap: 8,
    background: Colors.backgroundLight,
    borderRadius: 8,
    padding: 16,
  },
  shown: {
    height: 100,
  },
  button: {
    padding: '4px 8px',
    width: 60,
    borderRadius: 6,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    wrapper: {
      justifyContent: 'space-between',
      gap: 'unset',
    },
    shown: {
      height: 70,
    },
  },
});

const UpdateProfileToast = (props: Props): ReactElement => {
  const styles = useStyles();
  const { children, onClose, isShown, className } = props;

  const handleToastClose = (shouldUpdate: boolean) => {
    onClose && onClose(shouldUpdate);
  };

  return (
    <div className={cx(styles.root, className, { [styles.shown]: isShown })}>
      <div className={styles.wrapper}>
        {children}
        <div>
          <Button
            squared
            size={'xsmall'}
            width={'auto'}
            color={'secondaryDark'}
            onClick={() => {
              handleToastClose(true);
            }}
            style={{ marginRight: 8 }}
            className={styles.button}
          >
            Yes
          </Button>
          <Button
            squared
            size={'xsmall'}
            width={'auto'}
            color={'backgroundDark'}
            onClick={() => {
              handleToastClose(false);
            }}
            className={styles.button}
          >
            No
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfileToast;
