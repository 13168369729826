// type definitions

import { IS_PROD, IS_SANDBOX } from './config';
import { normalizeQueryParams } from './logic/utils';

export type LocationParam = string;
export type LocationPath = string;
export type LocationTemplate = string;

// main locations

export const RootLocation = '/';

// discovery locations

export const DiscoveryLocation = '/discovery';
export const DiscoverySuggestionsLocation =
  DiscoveryLocation + '#discovery-suggestions';

// admin search locations

export const AdminSearchLocation = '/admin/search';

// team work locations

export const TeamWorkLocation = `/request-for-teams`;
export const TeamWorkPageLocationTemplate = `${TeamWorkLocation}/:mid`;

//mission control locations

export const MissionControlBase = '/mission-control';
export const MissionControlLocation = (tab: LocationParam): LocationPath =>
  `${MissionControlBase}/${tab}`;
export const MissionControlLocationTemplate = `${MissionControlBase}/:tab`;
export const MissionControlRecommendedLocation = `${MissionControlBase}/recommended`;
export const MissionControlAllMissionsLocation = `${MissionControlBase}/all`;
export const MissionControlAppliedLocation = `${MissionControlBase}/applied`;
export const MissionControlNotInterestedLocation = `${MissionControlBase}/not-interested`;
export const MissionControlCompletedLocation = `${MissionControlBase}/completed`;

// admin dashboard locations

export const AdminTeamWorkBase = '/admin-teamwork';
export const AdminTeamWorkCreatedMissions = `${AdminTeamWorkBase}/missions/created`;
export const AdminTeamWorkPublishedMissions = `${AdminTeamWorkBase}/missions/published`;
export const AdminTeamWorkPendingMissions = `${AdminTeamWorkBase}/missions/pending`;
export const AdminTeamWorkRunningMissions = `${AdminTeamWorkBase}/missions/running`;
export const AdminTeamWorkArchivedMissions = `${AdminTeamWorkBase}/missions/archived`;
export const AdminTeamWorkEndedMissions = `${AdminTeamWorkBase}/missions/ended`;
export const AdminTeamWorkScheduledToEndMissions = `${AdminTeamWorkBase}/missions/scheduledToEnd`;
export const AdminTeamWorkSearchResults = `${AdminTeamWorkBase}/missions/search`;
export const AdminTeamWorkMissionsLocation = `${AdminTeamWorkBase}/missions`;

// client dashboard locations

export const ClientDashboardLocation = `/client`;

// airtable

export const AirtableLocation = '/airtable';

// skill targeter location
// old targeter location - redirects to new targeter location
export const TeamGraphTargeterLocation = '/teamgraph-targeter';
// old targeter location - redirects to new targeter location
export const ElasticTargeterLocation = '/elastic-targeter';
export const TargeterLocation = '/targeter';

// email templates location

export const EmailTemplatesLocation = '/email-templates';

// signature creator location

export const SignatureCreatorLocation = '/signature-creator';

// team formation locations

export const TeamFormationLocation = '/team-formation';

// privacy policy locations

export const PrivacyPolicyLocation = '/privacy-policy';

// terms of service locations

export const TermsOfServiceLocation = '/terms-of-service';

// talent privacy statement locations

export const TalentPrivacyStatementLocation = '/talent-privacy-statement';

// client terms of service location

export const ClientTermsOfServiceLocation = '/client-terms-of-service';

// code of conducts location

export const CodeOfConductLocation = '/code-of-conduct';

// transcript
export const TranscriptLocationTemplate = '/transcript/:jobId([0-9a-f]+)';
export const TranscriptLocation = (jobId: string): LocationPath =>
  `/transcript/${jobId}`;

// vetting
export const VettingDashboardLocation = '/selection-team';
export const VettingDashboardMemberToVetLocation = `${VettingDashboardLocation}/members-to-vet`;
export const VettingDashboardPendingInterviewsLocation = `${VettingDashboardLocation}/pending-interviews`;
export const VettingDashboardCompletedReviewsLocation = `${VettingDashboardLocation}/completed-reviews`;
export const VettingDashboardGuidebookLocation = `${VettingDashboardLocation}/guidebook`;
export const VettingDashboardMembersLocation = `${VettingDashboardLocation}/members`;
export const VettingDashboardMembersLocationV2 = `${VettingDashboardLocation}/members-v2`;
export const getVettingFeedbackFormViewModeLocation = (vpid: string) =>
  `${VettingDashboardLocation}/feedback-form/view/${vpid}`;
export const VettingFeedbackFormViewModeLocation =
  getVettingFeedbackFormViewModeLocation(':vpid([0-9a-f]+)');
export const VettingFeedbackFormLocation = `${VettingDashboardLocation}/feedback-form/:vpid([0-9a-f]+)`;
export const VettingDashboardSelectionTeamOnboardingPlanLocation = `${VettingDashboardLocation}/onboarding-plan`;
export const VettingDashboardRecordingLocation = `${VettingDashboardLocation}/recording`;

// prevetting form
export const PreVettingFormLocation = '/prevetting/form/:nonce([0-9a-f]+)';
export const PreVettingFormSubmittedLocation = '/prevetting/submitted';
export const PreVettingScheduleLocation =
  '/prevetting/schedule/:nonce([0-9a-f]+)';
export const PreVettingFormViewLocation = '/prevetting/view/:nonce([0-9a-f]+)';

// post evaluation form
export const PostEvaluationSurveyLocation =
  '/post-evaluation/form/:vpid([0-9a-f]+)';
export const PostEvaluationSurveySubmittedLocation =
  '/post-evaluation/submitted';

// mission locations

export const MissionLocation = '/mission';
export const MissionPageLocationTemplate = `${MissionLocation}/:mid`;
export const MissionPageLocation = (mid: LocationParam): LocationPath =>
  `${MissionLocation}/${mid}`;

export const EditMissionLocation = (mid: LocationParam): LocationPath =>
  `${MissionLocation}/${mid}/edit`;
export const EditMissionLocationTemplate = `${MissionLocation}/:mid/edit`;

export const MissionAboutLocationTemplate = `${MissionLocation}/:mid/about`;
export const MissionAboutLocation = (mid: LocationParam): LocationPath =>
  `${MissionLocation}/${mid}/about`;
export const MissionAdminAboutLocationTemplate = `${MissionLocation}/:mid/about/admin`;
export const MissionAdminAboutLocation = (mid: LocationParam): LocationPath =>
  `${MissionLocation}/${mid}/about/admin`;

export const MissionApplyLocationTemplate = `${MissionLocation}/:mid/apply/:rid`;
export const MissionApplyLocation = (
  mid: LocationParam,
  rid: LocationParam,
): LocationPath => `${MissionLocation}/${mid}/apply/${rid}`;
export const MissionApplyEditLocationTemplate = `${MissionLocation}/:mid/apply/:rid/edit/:aid`;
export const MissionApplyEditLocation = (
  mid: LocationParam,
  rid: LocationParam,
  aid: LocationParam,
): LocationPath => `${MissionLocation}/${mid}/apply/${rid}/edit/${aid}`;
export const MissionTimeTrackingLocationTemplate = `${MissionLocation}/:mid/time-tracking`;
export const MissionTimeTrackingLocation = (mid: LocationParam): LocationPath =>
  `${MissionLocation}/${mid}/time-tracking`;

export const MissionTeamTimeTrackingLocationTemplate = `${MissionLocation}/:mid/team-time-tracking`;
export const MissionTeamTimeTrackingLocation = (
  mid: LocationParam,
): LocationPath => `${MissionLocation}/${mid}/team-time-tracking`;

export const MissionAdminTimeTrackingLocationTemplate = `${MissionLocation}/:mid/time-tracking/admin`;
export const MissionAdminTimeTrackingLocation = (
  mid: LocationParam,
): LocationPath => `${MissionLocation}/${mid}/time-tracking/admin`;

export const MissionFAQLocationTemplate = `${MissionLocation}/:mid/faq`;
export const MissionFAQLocation = (mid: LocationParam): LocationPath =>
  `${MissionLocation}/${mid}/faq`;
export const MissionAdminFAQLocationTemplate = `${MissionLocation}/:mid/faq/admin`;
export const MissionAdminFAQLocation = (mid: LocationParam): LocationPath =>
  `${MissionLocation}/${mid}/faq/admin`;

export const MissionDocumentsLocationTemplate = `${MissionLocation}/:mid/documents`;
export const MissionDocumentsLocation = (mid: LocationParam): LocationPath =>
  `${MissionLocation}/${mid}/documents`;
export const MissionAdminDocumentsLocationTemplate = `${MissionLocation}/:mid/documents/admin`;
export const MissionAdminDocumentsLocation = (
  mid: LocationParam,
): LocationPath => `${MissionLocation}/${mid}/documents/admin`;

export const MissionTeamPulseLocationTemplate = `${MissionLocation}/:mid/team-pulse`;
export const MissionTeamPulseLocation = (mid: LocationParam): LocationPath =>
  `${MissionLocation}/${mid}/team-pulse`;
export const MissionAdminTeamPulseLocationTemplate = `${MissionTeamPulseLocationTemplate}/admin`;
export const MissionAdminTeamPulseLocation = (
  mid: LocationParam,
): LocationPath => `${MissionTeamPulseLocation(mid)}/admin`;

export const MissionAdminNotificationsLocationTemplate = `${MissionLocation}/:mid/notifications/admin`;
export const MissionAdminNotificationsLocation = (
  mid: LocationParam,
): LocationPath => `${MissionLocation}/${mid}/notifications/admin`;

export const MissionAdminCreateTeamPulseLocationTemplate = `${MissionAdminTeamPulseLocationTemplate}/new`;
export const MissionAdminCreateTeamPulseLocation = (
  mid: LocationParam,
): LocationPath => `${MissionAdminTeamPulseLocation(mid)}/new`;

export const MissionPageAdminLocation = (mid: LocationParam): LocationPath =>
  `${MissionLocation}/${mid}/admin`;
export const MissionPageAdminLocationTemplate = `${MissionLocation}/:mid/admin`;

export const MissionApplicationLocationTemplate = `${MissionLocation}/:mid/applications/:aid`;
export const MissionApplicationLocation = (
  mid: LocationParam,
  aid: LocationParam,
): LocationPath => `${MissionLocation}/${mid}/applications/${aid}`;

export const MissionProposalLocationTemplate = `${MissionLocation}/:mid/proposal`;
export const MissionProposalLocation = (mid: LocationParam): LocationPath =>
  `${MissionLocation}/${mid}/proposal`;

// export const MissionContractLocationTemplate = `${MissionLocation}/:mid/documents/contracts/:sid`;
// export const MissionContractLocation = (
//   mid: LocationParam,
//   sid: LocationParam,
// ) => `${MissionLocation}/${mid}/documents/contracts/${sid}`;

// Notification Center
export const NotificationPreferencesLocation = `/notification-preferences/:token`;

export const NotificationPreferencesLocationNoToken = `/notification-preferences`;

export const EmailUnsubscribeLocation = `/unsubscribe`;

export const EmailSubscribeLocation = `/subscribe`;
// Evaluation settings
export const EvaluationSettingsLocation = `/evaluation-settings`;

export const SettingsLocation = `/settings`;
export const SettingsAccountLocation = `/settings?menu=account`;
export const SettingsNotificationLocation = `/settings?menu=notifications`;

// company registration locations

export const CompanyRegistrationTeamRequestLocation = '/request-team';

export const CompanyRegistrationSignUpLocationTemplate = `${CompanyRegistrationTeamRequestLocation}/:rid/sign-up`;
export const CompanyRegistrationSignUpLocation = (
  rid: LocationParam,
): LocationPath => `${CompanyRegistrationTeamRequestLocation}/${rid}/sign-up`;

// update password locations

export const UpdatePasswordLocation =
  '/reset-password/:uid([0-9a-f]+)-:hashCode([0-9a-f]+)';

// reset password locations

export const ResetPasswordLocation = '/reset-password';

// sign in locations

export const SignInLocation = '/sign-in';
export const SignInLocationTemplate = (redirectUrl: string): LocationPath =>
  redirectUrl !== '/'
    ? `/sign-in?redirect=${normalizeQueryParams(redirectUrl, true)}`
    : '/sign-in';
export const SignOutLocation = '/sign-out';

// registration locations

export const RegistrationInviteLocationTemplate =
  '/invite/:invitation([0-9a-f]+)-:nonce([0-9a-f]+)';
export const RegistrationForwardLocation = '/registration';
export const RegistrationLocation = '/dayone';
export const VerifyEmailLocation = '/verify-email';
export const FinishOnboardingLocation = '/complete';

export const TalkSoonLocation = 'https://letstalksoon.a.team';

// oauth

export const OAuthLocation = (type: LocationParam): LocationPath =>
  `/oauth/${type}`;
export const OAuthLocationTemplate = '/oauth/:type';

// profile locations

export const ProfileLocation = (username: LocationParam): LocationPath =>
  `/${username}`;
export const ProfileLocationTemplate = '/:username';

export const ProfileEditLocation = (username: LocationParam): LocationPath =>
  `${ProfileLocation(username)}?edit=1`;
export const ProfileImageEditLocationTemplate = `${ProfileLocationTemplate}/profile-image`;
export const ProfileImageEditLocation = (
  username: LocationParam,
): LocationPath => `${ProfileLocation(username)}/profile-image`;

// search results locations
export const SearchResultsLocationTemplate = `/search`;

// my network location
export const MyNetworkLocation = `/my-network`;

// invoice PDF
export const InvoicePdfLocationTemplate = `/invoice/:iid([0-9a-f]+)-:nonce([0-9a-f]+)`;
export const InvoicePdfLocation = (
  iid: LocationParam,
  nonce: LocationParam,
): LocationPath => `/invoice/${iid}-${nonce}`;

// guide location

export const GuideLocation = '/a-guide';
export const NonVerifiedGuideLocation = '/a-builder-guide';

// A Store location
export const AStoreLocation = '/a-store';

// Community location
export const CommunityLocation = '/community';

// Architects location
export const ArchitectsLocation = '/architects';

// points location
export const TokensLocation = '/tokens';

// invoices admin dashboard
export const AdminInvoicesDashboardLocation = '/admin/invoices';
export const AdminInvoicesClientInvoiceListLocationTemplate = `${AdminInvoicesDashboardLocation}/client-invoice-list`;
export const AdminInvoicesToBeIssuedLocationTemplate = `${AdminInvoicesDashboardLocation}/to-be-issued`;
export const AdminInvoicesUnpaidInvoiceListLocationTemplate = `${AdminInvoicesDashboardLocation}/unpaid-invoice-list`;
export const AdminInvoicesPrepayPaymentsLocation = `${AdminInvoicesDashboardLocation}/prepay-payments`;
export const AdminInvoicesDiscountsLocation = `${AdminInvoicesDashboardLocation}/discounts`;
export const AdminInvoicesDiscountsNewLocation = `${AdminInvoicesDashboardLocation}/discounts/new`;
export const AdminInvoicesDiscountsViewLocation = (
  did: LocationParam,
): LocationPath => `${AdminInvoicesDashboardLocation}/discounts/${did}`;
export const AdminInvoicesDiscountsViewLocationTemplate =
  AdminInvoicesDiscountsViewLocation(':id');

// team pulse admin dashboard
export const AdminTeamPulseDashboardLocation = '/admin/team-pulse/dashboard';

// Client app locations
export const ClientAppLocation = IS_PROD
  ? 'https://client.a.team'
  : IS_SANDBOX
  ? 'https://client-sandbox.a.team'
  : 'http://localhost:3000';

export const ClientMissionLocation = `${ClientAppLocation}/mission`;
export const ClientMissionPageLocationTemplate = `${ClientMissionLocation}/:msid`;
export const ClientMissionPageLocation = (msid: LocationParam): LocationPath =>
  `${ClientMissionLocation}/${msid}`;
export const ClientBuilderProfileLocation = (
  uid: LocationParam,
): LocationPath => `${ClientAppLocation}/builders/${uid}`;

export const ClientBuilderChatLocation = (
  username: LocationParam,
): LocationPath => `${ClientAppLocation}/chat/${username}`;

// invite
export const InviteLocation = '/invite';

export const SetupLocation = '/setup';
