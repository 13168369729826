import MissionRole from '@a_team/models/dist/MissionRole';
import { Breakpoints, Icon, IconType } from '@ateams/components';
import SectionHeading from '@src/components/SectionHeading';
import { useQueryGetTimesheetInitiativesForMission } from '@src/rq/timesheets';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  headingSection: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 16,
    fontWeight: 500,
  },
  headingText: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    gap: 8,
    fontSize: 20,
  },
  headingTextDivider: {
    height: '1px',
    width: '20px',
    backgroundColor: '#DADADC',
    display: 'none',
  },
  headingDates: {
    color: '#62646A',
    fontSize: 16,
  },
  exportButtonsWrapper: {
    display: 'flex',
    gap: 16,
  },
  exportButton: {
    border: '1px solid #DADADC',
    backgroundColor: '#FFFFFF',
    padding: '8px 12px',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '19px',
    borderRadius: 8,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: '#f6f7f8',
    },
  },
  exportButtonIcon: {
    width: 16,
    marginRight: '8px',
    cursor: 'pointer',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    headingSection: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    headingText: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: 16,
    },
    headingDates: {
      fontSize: 20,
    },
    headingTextDivider: {
      display: 'inline-block',
    },
  },
});

const SectionHeader = ({
  sid,
  currentUserRole,
}: {
  sid?: string;
  currentUserRole?: MissionRole;
}) => {
  const {
    missions: { currentMission },
  } = useStores();
  const styles = useStyles();

  const userRole = currentUserRole
    ? currentUserRole
    : currentMission?.currentUserRole;

  const invoiceURL = currentMission?.currentUserTimesheet?.invoice?.downloadURL;
  const exportTimesheet = currentMission?.exportTimesheetV2;

  const { data: initiatives } = useQueryGetTimesheetInitiativesForMission({
    sid:
      sid ??
      currentMission?.data.paymentCycles?.current?.timesheets[0]?.sid ??
      '',
  });

  return (
    <div>
      {currentMission?.selectedPaymentCycle && (
        <SectionHeading className={styles.headingSection}>
          <div className={styles.headingText}>
            <span>Time tracking</span>
            <span className={styles.headingTextDivider} />
            <span className={styles.headingDates}>
              {currentMission.selectedPaymentCycle?.formattedStartDate} -{' '}
              {currentMission.selectedPaymentCycle?.formattedEndDate},{' '}
              {currentMission.selectedPaymentCycle?.formattedYear} Timesheet
            </span>
          </div>
          <div className={styles.exportButtonsWrapper}>
            {userRole && exportTimesheet && (
              <button
                className={styles.exportButton}
                onClick={() => {
                  exportTimesheet(userRole.rid, initiatives ?? []);
                }}
              >
                <Icon
                  type={IconType.Download}
                  className={styles.exportButtonIcon}
                />
                Export Timesheet
              </button>
            )}

            {invoiceURL && (
              <button
                className={styles.exportButton}
                onClick={() => window.open(invoiceURL)}
              >
                <Icon
                  type={IconType.Download}
                  className={styles.exportButtonIcon}
                />
                Export Invoice
              </button>
            )}
          </div>
        </SectionHeading>
      )}
    </div>
  );
};

export default observer(SectionHeader);
