import { createQueryKeys } from '@lukemorales/query-key-factory';

export const teamUpKeys = createQueryKeys('teamUp', {
  getPendingUserRecommendations: (
    userId: string,
    missionId: string,
    includeUnavailable?: boolean,
  ) => ({
    userId,
    missionId,
    includeUnavailable,
  }),
  getUserRecommendationById: (userId: string, recommendationId: string) => ({
    userId,
    recommendationId,
  }),
});
