import React from 'react';
import { createUseStyles } from 'react-jss';
import { Spacing } from '@ateams/components';
import { WorkElseWhere } from '@a_team/models/dist/vetting-processes/feedback';
import { CheckboxValueTag } from '../../components/value-tag';
import { Size, Text } from '../../components/typography';

const WorksElseWhereLabels: Record<WorkElseWhere, string> = {
  [WorkElseWhere.FullTime]: 'Yes, full-time',
  [WorkElseWhere.PartTime]: 'Yes, part-time',
  [WorkElseWhere.No]: 'No',
};

export type OnWorksElseWhereChange = (
  workElseWhere: WorkElseWhere | undefined,
) => void;

export interface WorksElseWhereInputProps {
  value?: WorkElseWhere | undefined;
  onChange?: OnWorksElseWhereChange;
  isReadOnly?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  valueTag: {
    marginRight: Spacing.small,
    '&:last-child': {
      marginRight: 0,
    },
  },
});

export const WorksElseWhereInput: React.FC<WorksElseWhereInputProps> = (
  props,
) => {
  const { isReadOnly, value } = props;
  const styles = useStyles();

  if (isReadOnly) {
    return (
      <div className={props.className}>
        <Text size={Size.Small} isReadOnly>
          {value ? WorksElseWhereLabels[value] : undefined}
        </Text>
      </div>
    );
  }

  const onChange = (tickedWorksElseWhere: WorkElseWhere) => {
    const newWorksElseWhere =
      value !== tickedWorksElseWhere ? tickedWorksElseWhere : undefined;

    props.onChange?.(newWorksElseWhere);
  };

  return (
    <div className={props.className}>
      <CheckboxValueTag
        checked={value === WorkElseWhere.FullTime}
        label={WorksElseWhereLabels[WorkElseWhere.FullTime]}
        className={styles.valueTag}
        onClick={() => onChange(WorkElseWhere.FullTime)}
      />
      <CheckboxValueTag
        checked={value === WorkElseWhere.PartTime}
        label={WorksElseWhereLabels[WorkElseWhere.PartTime]}
        className={styles.valueTag}
        onClick={() => onChange(WorkElseWhere.PartTime)}
      />
      <CheckboxValueTag
        checked={value === WorkElseWhere.No}
        label={WorksElseWhereLabels[WorkElseWhere.No]}
        className={styles.valueTag}
        onClick={() => onChange(WorkElseWhere.No)}
      />
    </div>
  );
};
