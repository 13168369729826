import React, {
  ChangeEvent,
  CSSProperties,
  useMemo,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import SearchMenu from '@src/components/SearchMenu';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import { Icon, IconType } from '@ateams/components';
import { useHistory } from 'react-router-dom';
import { Breakpoints } from '@ateams/components';
import { ProfileLocation } from '@src/locations';

interface Props {
  style?: CSSProperties;
  withMenu?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  searchBarContainer: {
    width: '100%',
    height: '56px',
    display: 'flex',
    margin: 32,
  },
  searchInput: {
    margin: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    width: '100%',
  },
  searchButton: {
    cursor: 'pointer',
    background: '#FE630C',
    height: '56px',
    width: '80px',
    borderTopRightRadius: '8px',
    flexShrink: 0,
    borderBottomRightRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)), #FE630C',
    },
  },
  icon: {
    width: 14,
    height: 14,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    searchBarContainer: {
      width: '744px',
      margin: 0,
    },
  },
});

const SearchInput: React.FC<Props> = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const searchRef = useRef(null);
  const { search } = useStores();
  const { style, withMenu = true, className } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>();

  const highlightedItem = useMemo(() => {
    return highlightedIndex !== undefined
      ? search.totalSearchItems[highlightedIndex]
      : undefined;
  }, [highlightedIndex]);

  const redirectToSearch = (): void => {
    search.userSearch && search.addSearchHistory(search.userSearch);
    search.setUserResultsSummary();
    history.push(search.searchUrl);
  };

  const redirectToUserProfile = (): void => {
    search.setUserResultsSummary();
    highlightedItem?.user &&
      history.push(ProfileLocation(highlightedItem.user.username));
  };

  const onInputEnterPress = () => {
    setMenuOpen(false);
    if (highlightedItem) {
      search.addSearchHistory(highlightedItem);
      search.onExistingSearchSelect(highlightedItem);
      highlightedItem.user ? redirectToUserProfile() : redirectToSearch();
      return;
    }
    search.setUserResults();
    search.fetchSearchResults(false, redirectToSearch);
  };

  const traverseList = (key: KeyboardEvent['key']) => {
    switch (key) {
      case 'ArrowDown':
        if (highlightedIndex === search.totalSearchItemsLength - 1) return;
        setHighlightedIndex(
          highlightedIndex !== undefined ? highlightedIndex + 1 : 0,
        );
        break;
      case 'ArrowUp':
        setHighlightedIndex((highlightedIndex || 1) - 1);
        break;
      default:
        return;
    }
  };

  return (
    <div
      className={cx(styles.searchBarContainer, className)}
      ref={searchRef}
      style={{ ...style }}
    >
      <OutlinedInput
        loading={search.loadingResults}
        borderRadius={'left'}
        value={search.searchQuery}
        placeholder="Who are you looking for?"
        className={styles.searchInput}
        onFocus={(): void => setMenuOpen(true)}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
          search.onSearchQueryChange(e.target.value);
          setHighlightedIndex(undefined);
        }}
        onKeyPress={(e): void =>
          e.key === 'Enter' ? onInputEnterPress() : undefined
        }
        onKeyDown={(e) => traverseList(e.key)}
      />
      <div
        className={styles.searchButton}
        onClick={(): void => search.fetchSearchResults(false, redirectToSearch)}
      >
        <Icon
          type={IconType.Search}
          size="xsmall"
          className={styles.icon}
          clickable
        />
      </div>
      {menuOpen && withMenu && (
        <SearchMenu
          highlightedIndex={highlightedIndex}
          searchRef={searchRef}
          onClose={(): void => setMenuOpen(false)}
        />
      )}
    </div>
  );
};

export default observer(SearchInput);
