import React from 'react';
import { observer } from 'mobx-react';
import AdminInvoicesDashboardLayout from '@src/layouts/InvoicesDashboard';

const ClientInvoicesListView = () => {
  return (
    <AdminInvoicesDashboardLayout title={'Client Invoices'}>
      <p>No invoices to display</p>
    </AdminInvoicesDashboardLayout>
  );
};

export default observer(ClientInvoicesListView);
