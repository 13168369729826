import React, { ReactElement } from 'react';
import { WorkspacePaymentCycleInvoiceObject } from '@a_team/models/dist/InvoiceObject';
import MissionMissionPaymentCycleInvoicePage from '../MissionPaymentCycleInvoice/Page';
import { createPageRows } from '../../helpers';

interface Props {
  invoice: WorkspacePaymentCycleInvoiceObject;
}

export default function WorkspacePaymentCycleInvoice(
  props: Props,
): ReactElement {
  const { invoice } = props;
  const { missionInvoices } = invoice;

  const pages = createPageRows({
    rows: missionInvoices.flatMap((mission) => mission.rolesInvoices),
    heroSize: 6,
    rowSize: () => 1,
  });

  return (
    <>
      {pages.map((page, i) => (
        <MissionMissionPaymentCycleInvoicePage
          key={`page-${i + 1}`}
          invoice={invoice}
          roles={page}
          hero={i === 0}
          hasMore={i < pages.length - 1}
          showMissionTitles={true}
        />
      ))}
    </>
  );
}
