import { Breakpoints } from '@ateams/components';
import { createUseStyles } from 'react-jss';

export const useGridMissionControlCardStyles = createUseStyles({
  container: {
    marginTop: '24px',
    marginBottom: '24px',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gap: '24px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    container: {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    },
  },
  [`@media (min-width: ${Breakpoints.lg}px)`]: {
    container: {
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    },
  },
});
