import React, { ReactElement, useEffect, useState } from 'react';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import DropdownInput from '@src/components/Inputs/DropdownInput';
import Tooltip from '@src/components/Tooltip';
import { getLocalTime } from '@src/helpers/time';
import { Breakpoints } from '@ateams/components';
import { TimeField } from '@src/components/TimeField';

interface Props {
  addSingleRecord(date: Date | null, minutes: number, details: string): void;
  cycleDates: Date[] | null | undefined;
  disabled?: boolean;
}

const useStyles = createUseStyles({
  actionSection: {
    paddingTop: '32px',
    paddingBottom: '32px',
    paddingLeft: '24px',
    paddingRight: '24px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  subTitle: {
    fontSize: '15px',
    lineHeight: '24px',
    color: '#62646A',
  },
  addCTA: {
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#FE630C',
    cursor: 'pointer',
    textAlign: 'right',
  },
  date: {
    textAlign: 'center',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  dateSelect: {
    marginBottom: 0,
    marginTop: 0,
    maxWidth: 170,
  },
  hours: {
    textAlign: 'center',
    flexShrink: 0,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  hoursInput: {
    marginTop: 0,
    maxWidth: 70,
  },
  task: {
    width: '100%',
    textAlign: 'left',
  },
  taskInputContainer: {
    display: 'block',
    alignItems: 'center',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    actionSection: {
      flexDirection: 'row',
    },
    date: {
      width: '212px',
      display: 'block',
      marginRight: '24px',
      marginBottom: 0,
    },
    hours: {
      display: 'block',
      width: '110px',
      marginRight: '24px',
      marginBottom: 0,
    },
    hoursInput: {
      marginTop: 24,
      maxWidth: 'none',
    },
    dateSelect: {
      marginTop: 24,
      maxWidth: 'none',
    },
    task: {
      textAlign: 'center',
    },
    taskInputContainer: {
      display: 'flex',
    },
    addCTA: {
      textAlign: 'left',
    },
  },
});

export default function AddRecordForm(props: Props): ReactElement {
  const { addSingleRecord, cycleDates, disabled } = props;
  const styles = useStyles();

  const [date, setDate] = useState<Date | null>(null);
  const [hoursError, setHoursError] = useState(false);
  const [detailsError, setDetailsError] = useState(false);
  const [minutes, setMinutes] = useState<number>();
  const [details, setDetails] = useState('');

  useEffect(() => {
    if (!cycleDates) return;
    setDate(
      cycleDates
        .slice()
        .reverse()
        .find(
          (date) => getLocalTime(date).getDate() === new Date().getDate(),
        ) || cycleDates[0],
    );
  }, [cycleDates]);

  const checkForEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      addRecord();
    }
  };

  const addRecord = () => {
    if (!minutes && minutes !== 0) {
      setHoursError(true);
    } else if (details.length < 1) {
      setDetailsError(true);
    } else {
      setHoursError(false);
      setDetailsError(false);
      addSingleRecord(date, minutes, details);
      setDetails('');
    }
  };

  return (
    <div className={styles.actionSection} data-testing-id="timesheet-add">
      <div className={styles.date}>
        <div className={styles.subTitle}>Date</div>
        <DropdownInput
          className={styles.dateSelect}
          name="date"
          placeholder="Select Date"
          value={date?.toISOString() || ''}
          required
          onChange={(e): void => setDate(new Date(e.target.value))}
          disabled={disabled}
        >
          {cycleDates?.map((date) => (
            <option key={date.toISOString()} value={date.toISOString()}>
              {format(getLocalTime(date), 'EEE, LLL d')}
            </option>
          ))}
        </DropdownInput>
      </div>
      <div className={styles.hours}>
        <div className={styles.subTitle}>Hours Worked</div>
        <Tooltip label={hoursError && '❌Please enter hours'}>
          <TimeField
            onChange={(e, minutes) => {
              setMinutes(minutes);
              setHoursError(false);
            }}
            name="hours"
            onKeyUp={checkForEnter}
            error={hoursError}
            disabled={disabled}
            className={styles.hoursInput}
            defaultFocused
          />
        </Tooltip>
      </div>
      <div className={styles.task}>
        <div className={styles.subTitle}>Main Task</div>
        <div className={styles.taskInputContainer}>
          <Tooltip
            label={detailsError && '❌Please enter your task description'}
            rootStyle={{ width: '100%' }}
          >
            <OutlinedInput
              name="details"
              style={{ marginBottom: 0 }}
              error={detailsError}
              value={details ? details : ''}
              placeholder="Describe the main task..."
              onChange={(e): void => {
                setDetails(e.target.value);
                setDetailsError(false);
              }}
              onFocus={(e) => e.target.select()}
              onKeyUp={checkForEnter}
              disabled={disabled}
            />
          </Tooltip>
          <div
            className={styles.addCTA}
            style={{
              marginLeft: '24px',
              marginTop: '24px',
              flexShrink: 0,
              ...(disabled && {
                color: '#555',
                opacity: 0.8,
                cursor: 'default',
              }),
            }}
            data-testing-id="add-record"
            onClick={disabled ? () => undefined : addRecord}
          >
            + Add Time
          </div>
        </div>
      </div>
    </div>
  );
}
