import React from 'react';
import { createUseStyles } from 'react-jss';
import Badge from './Badge';
import cx from 'classnames';

interface SelectOptionProps {
  children: React.ReactNode;
  innerProps: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  innerRef: SelectOptionProps['innerProps']['ref'];
  isFocused: boolean; // Add isFocused prop
  bgColor?: string;
  color?: string;
}

const useStyles = createUseStyles({
  container: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  focused: {
    backgroundColor: '#F7F7F7',
  },
});

const SelectOption = ({
  innerProps,
  innerRef,
  children,
  isFocused, // Add isFocused prop
  bgColor,
  color,
}: SelectOptionProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={cx(styles.container, { [styles.focused]: isFocused })} // Apply focused style
    >
      <Badge bgColor={bgColor} color={color}>
        {children}
      </Badge>
    </div>
  );
};

export default SelectOption;
