import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Colors, FontWeights, Spacing } from '@ateams/components';
import { VettingProcessPostEvaluationSurveyScore } from '@a_team/models/dist/vetting-processes/post-evaluation-survey';
import { NumberValueTag } from '@src/views/VettingFeedbackForm/components/value-tag';
import {
  InputLabel,
  Size,
  Text,
} from '@src/views/VettingFeedbackForm/components/typography';

const POST_EVALUATION_SURVEY_MAX_SCORE = 10;

type OnScoreInputChange = (
  value: VettingProcessPostEvaluationSurveyScore,
) => void;

interface ScoreInputScales {
  low: string;
  high: string;
}

export interface ScoreInputProps {
  value?: VettingProcessPostEvaluationSurveyScore;
  onChange?: OnScoreInputChange;
  label?: string | ReactNode;
  description?: ReactNode;
  scales?: ScoreInputScales;
  isReadOnly?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  xSmallMarginBottom: {
    marginBottom: Spacing.xsmall,
  },
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  scoreInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  scoreInputLabel: {
    fontWeight: FontWeights.bold,
    '&:first-child': {
      width: '83px',
      marginRight: Spacing.small,
    },
    '&:last-child': {
      marginLeft: Spacing.small,
    },
  },
  scoreTag: {
    marginRight: Spacing.xsmall,
    '&:last-of-type': {
      marginRight: 0,
    },
  },
});

export const ScoreInput: React.FC<ScoreInputProps> = (props) => {
  const styles = useStyles();

  return (
    <div className={cx(styles.container, props.className)}>
      {props.label && (
        <InputLabel
          className={
            props.description ? styles.xSmallMarginBottom : styles.marginBottom
          }
        >
          {props.label}
        </InputLabel>
      )}

      {props.description && (
        <Text size={Size.Small} className={styles.marginBottom}>
          {props.description}
        </Text>
      )}

      <div className={styles.scoreInputContainer}>
        {props.scales?.low && (
          <Text className={styles.scoreInputLabel} size={Size.Small}>
            {props.scales.low}
          </Text>
        )}

        {Array(POST_EVALUATION_SURVEY_MAX_SCORE)
          .fill(null)
          .map((_, i) => {
            const number = (i + 1) as VettingProcessPostEvaluationSurveyScore;

            return (
              <NumberValueTag
                key={number}
                color={
                  number === props.value ? Colors.secondaryDark : undefined
                }
                lightFontColor
                onClick={
                  props.isReadOnly ? undefined : () => props.onChange?.(number)
                }
                isReadOnly={props.isReadOnly}
                className={styles.scoreTag}
              >
                {number}
              </NumberValueTag>
            );
          })}

        {props.scales?.high && (
          <Text className={styles.scoreInputLabel} size={Size.Small}>
            {props.scales.high}
          </Text>
        )}
      </div>
    </div>
  );
};
