import { MetricData } from '@a_team/models/dist/ExperienceObject';
import { Breakpoints } from '@ateams/components';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import ErrorForm from '../common/ErrorForm';
import CommonStyles from '../common/styles';
import Metric, { DESCRIPTION_LIMIT, TITLE_LIMIT } from './Metric';

const useStyles = createUseStyles({
  metrics: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    gap: 8,
    flexDirection: 'column',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    metrics: {
      flexDirection: 'row',
    },
  },
});

const DEFAULT_METRICS = [
  {
    title: '60,000',
    description: 'New products added to the marketplace year-over-year',
  },
  {
    title: '72%',
    description: 'Bounce rate reduction',
  },
  {
    title: '60%',
    description: 'Growth year over year',
  },
];

const Metrics = (): JSX.Element => {
  const {
    register,
    unregister,
    watch,
    formState: { errors },
  } = useFormContext();

  const commonStyles = CommonStyles();
  const styles = useStyles({});

  useEffect(() => {
    register('metrics', {
      validate: (metrics: MetricData[] | undefined) => {
        if (!metrics) {
          return true;
        }

        for (let i = 0; i < metrics.length; i++) {
          const metric = metrics[i];
          const metricPosition = i + 1;
          if (!metric.title && !metric.description) {
            continue;
          }
          if (!metric.title) {
            return `The metric ${metricPosition} is missing the title`;
          }
          if (!metric.description) {
            return `The metric ${metricPosition} is missing the description`;
          }

          if (metric.title.length > TITLE_LIMIT) {
            return `The title for metric ${metricPosition} cannot exceed ${TITLE_LIMIT} characters`;
          }

          if (metric.description.length > DESCRIPTION_LIMIT) {
            return `The description for metric ${metricPosition} cannot exceed ${DESCRIPTION_LIMIT} characters`;
          }
        }

        return true;
      },
    });

    return () => {
      unregister('metrics');
    };
  }, [register, unregister]);

  const metrics: MetricData[] = watch('metrics') || [];

  while (metrics.length < 3) {
    metrics.push({ title: '', description: '' });
  }

  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.title}>Add metrics (Optional)</div>
      <div className={commonStyles.description}>
        Metrics help you visually show the outcome of a project. You can add up
        to 3 metrics.
      </div>
      <div className={styles.metrics}>
        {metrics.map((metric, index) => (
          <Metric
            key={`metric-${index}`}
            index={index}
            title={metric.title}
            description={metric.description}
            titlePlaceholder={DEFAULT_METRICS[index].title}
            descriptionPlaceholder={DEFAULT_METRICS[index].description}
          />
        ))}
      </div>
      <ErrorForm field="metrics" errors={errors} />
    </div>
  );
};

export default Metrics;
