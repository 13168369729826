import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import cx from 'classnames';

interface LinkContainerProps {
  to: React.ComponentProps<Link>['to'];
  className?: string;
  showActive?: boolean;
  children?: React.ReactNode;
}

const useStyles = createUseStyles({
  items: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '4px',
    paddingLeft: 18,
    '& > *': {
      minWidth: 0,
    },
  },
  userItems: {
    marginBottom: '28px',
    '&:last-of-type': {
      marginBottom: 28,
    },
  },
  active: {
    background:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), rgba(0, 0, 0, 0)',
    boxShadow: '0px 1px 2px rgba(232, 208, 255, 0.24)',
    borderRadius: 0,
  },
  highlight: {
    display: 'none',
    height: '100%',
    width: '3px',
    backgroundColor: 'white',
    position: 'absolute',
    left: '4px',
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    items: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: -4,
      padding: 4,
    },
    active: {
      background: 'none',
      boxShadow: 'none',
      position: 'relative',
      '& span': {
        display: 'inline !important',
      },
    },
    userItems: {
      padding: 0,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  },
});

const LinkContainer = ({
  children,
  to,
  className,
  showActive,
}: LinkContainerProps): JSX.Element => {
  const styles = useStyles();

  if (showActive) {
    return (
      <NavLink
        to={to}
        className={cx(className, styles.items, styles.userItems)}
        activeClassName={styles.active}
      >
        <>
          {children}
          {showActive && <span className={styles.highlight} />}
        </>
      </NavLink>
    );
  }

  return (
    <Link to={to} className={cx(className, styles.items, styles.userItems)}>
      <>
        {children}
        {showActive && <span className={styles.highlight} />}
      </>
    </Link>
  );
};

export default LinkContainer;
