import React from 'react';
import { theme } from '@a_team/ui-components';

export const EditIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        opacity="0.6"
        d="M8 5L11 8"
        stroke={theme.colors.Grey[800]}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.47768 14H2V10.5223C2 10.2571 2.10536 10.0028 2.29289 9.81521L9.401 2.70711C9.79152 2.31658 10.4247 2.31658 10.8152 2.70711L13.2929 5.18478C13.6834 5.57531 13.6834 6.20848 13.2929 6.599L6.18478 13.7071C5.99725 13.8946 5.74289 14 5.47768 14Z"
        stroke={theme.colors.Grey[800]}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
