import {
  SharedJobExperience,
  SharedProjectExperience,
} from '@a_team/models/dist/ConnectionObject';
import {
  ExperienceType,
  ExperienceTypeNames,
} from '@a_team/models/dist/ExperienceObject';
import { Icon } from '@a_team/ui-components';
import { Breakpoints, Checkbox, Colors, TextColors } from '@ateams/components';
import useMediaQuery from '@src/hooks/useMediaQuery';
import format from 'date-fns/format';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { ExperienceObject } from '../../types';
import { Icon as IconComponent, IconType } from '@ateams/components';
import ImageFallback from '@src/components/ImageFallback';

interface ExperienceProps {
  sharedWith?: string;
  experience: ExperienceObject | SharedJobExperience | SharedProjectExperience;
  isRequester?: boolean;
  canEdit?: boolean;
  onSelect?: (
    experience: SharedJobExperience | SharedProjectExperience,
  ) => void;
  onEdit?: (experience: SharedJobExperience | SharedProjectExperience) => void;
  onRemove?: (eid: string) => void;
  selected?: boolean;
  className?: string;
  minimal?: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    gap: '16px',
    borderBottom: '1px solid #DADADC',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  imgContainer: {
    borderRadius: '4px',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: Colors.backgroundLight,
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
      borderRadius: '4px',
    },
  },
  checkbox: {
    margin: 0,
  },
  description: {
    marginTop: 0,
  },
  button: {
    border: 'none',
    background: Colors.backgroundDark,
    padding: '7px 10px',
    borderRadius: '4px',
    marginTop: '8px',
    color: TextColors.regular,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  removeButton: {
    fontSize: 14,
    width: 93,
    cursor: 'pointer',
  },
  addDisabled: {
    opacity: 0.5,
    '&:hover': {
      cursor: 'default',
    },
  },
  content: {
    display: 'flex',
    gap: '16px',
  },
  typeCollaboratorsContainer: {
    color: '#818388',
    display: 'flex',
    gap: '8px',
  },
  experienceDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    experienceDetails: {
      flexDirection: 'row',
    },
  },
});

const Experience = ({
  sharedWith,
  experience,
  onSelect,
  onEdit,
  onRemove,
  isRequester,
  canEdit,
  selected,
  className,
  minimal,
}: ExperienceProps): JSX.Element => {
  const styles = useStyles();
  const isDesktop = useMediaQuery(`(min-width: ${Breakpoints.md}px)`);

  const { members, imagesUrls, imageURL, description, jobRole } = experience;

  const collaboratorsText =
    members?.length === 1 ? 'collaborator' : 'collaborators';
  const totalCollaborators = members?.length;

  const image = imagesUrls?.[0] || imageURL || undefined;

  const handleEdit = () => {
    if (onEdit) {
      onEdit(experience);
    }
  };

  // format date with date-

  const startDate = experience.startDate
    ? format(new Date(experience.startDate), 'MMMM yyyy')
    : undefined;

  const endDate = experience.endDate
    ? format(new Date(experience.endDate), 'MMMM yyyy')
    : undefined;

  const editable = canEdit && !minimal && onEdit;

  const title = useMemo<string | undefined>(() => {
    if (experience.type === ExperienceType.Job) {
      return experience.name;
    }

    if (experience.type === ExperienceType.Project) {
      return experience.title;
    }

    return undefined;
  }, [experience.type]);

  const skills = useMemo(() => {
    let totalSkills = 0;
    if ('talentSkillIds' in experience && experience.talentSkillIds?.length) {
      totalSkills = experience.talentSkillIds.length;
    } else if (experience.type === ExperienceType.Project) {
      totalSkills = experience.skills?.length || 0;
    } else if (experience.type === ExperienceType.Job) {
      totalSkills = experience.applicableTalentSkills?.length || 0;
    }

    if (totalSkills === 0) {
      return undefined;
    }

    return `${totalSkills} skill${totalSkills > 1 ? 's' : ''}`;
  }, [experience]);

  const renderCollaborators = () => {
    if (minimal && sharedWith) {
      return (
        <div>
          Shared {experience.type} experience with {sharedWith}
        </div>
      );
    }

    return (
      <>
        <div>{experience.type ? ExperienceTypeNames[experience.type] : ''}</div>
        <span>&#183;</span>
        <div>
          {totalCollaborators} {collaboratorsText}
        </div>
      </>
    );
  };

  return (
    <div
      className={cx(styles.container, className)}
      data-testing-id="experience"
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.imgContainer}>
            {image ? (
              <ImageFallback
                src={image}
                alt={title}
                fallbackImage={
                  <IconComponent type={IconType.File} size="small" />
                }
              />
            ) : (
              <IconComponent type={IconType.File} size="small" />
            )}
          </div>
          <div>
            <div>{title}</div>
            <div className={styles.typeCollaboratorsContainer}>
              {renderCollaborators()}
            </div>
          </div>
        </div>
        {onSelect && (
          <div>
            <Checkbox
              className={styles.checkbox}
              checked={selected}
              onChange={() => {
                onSelect(experience);
              }}
            />
          </div>
        )}
      </div>

      {!isRequester && !minimal && (
        <div>
          {description && <p className={styles.description}>{description}</p>}
          <div className={styles.experienceDetails}>
            <span>{jobRole}</span>
            {startDate && (
              <>
                {isDesktop && <span>&#183;</span>}
                <span>
                  {startDate} {endDate ? ` - ${endDate}` : ' - Present'}
                </span>
              </>
            )}
            {skills && (
              <>
                {isDesktop && <span>&#183;</span>}
                <span>{skills}</span>
              </>
            )}
          </div>
          {editable && (
            <button
              disabled={!selected}
              onClick={handleEdit}
              className={cx(styles.button, {
                [styles.addDisabled]: !selected,
              })}
              data-testing-id="add-custom-data-to-experience"
            >
              <Icon name="edit" size="md" color="Grey@800" />
              Edit
            </button>
          )}
        </div>
      )}
      {onRemove && (
        <button
          onClick={() => onRemove(experience.eid as string)}
          className={cx(styles.button, styles.removeButton)}
        >
          <Icon name="remove" size="md" color="Grey@800" /> Remove
        </button>
      )}
    </div>
  );
};

export default Experience;
