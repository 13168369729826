import React, { ReactElement } from 'react';
import { MissionCardObject } from '@a_team/models/dist/MissionObject';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { useStores } from '@src/stores';
import { VideoPlayer } from '@src/components/VideoPlayer';
import MissionControlCard from '..';
import Flag from '../Flag';
import ActionButton from '../ActionButton';
import MissionRoles from '../MissionRoles';
import MatchList from '../MatchList';
import { getRecommendationMatchingCriteria } from '../RecommendedMissionCard';
import { RecommendedMission } from '@a_team/models/dist/MissionObject';

interface Props {
  mission?: MissionCardObject | RecommendedMission;
  recommended?: boolean;
  loading?: boolean;
}

const NotInterestedMissionCard = (props: Props): ReactElement => {
  const { mission, recommended = false, loading } = props;
  const { missionControl } = useStores();
  const analytics = useAnalytics();

  const criteria: string[] =
    recommended && mission ? getRecommendationMatchingCriteria(mission) : [];

  const onUnhide = (): void => {
    if (!mission) return;
    analytics.trackMissionCardNotInterested(mission, recommended, false);
    missionControl.restoreNotInterested(mission.mid);
  };

  return (
    <MissionControlCard
      loading={loading}
      mission={mission}
      flag={recommended ? <Flag /> : undefined}
      actionButton={<ActionButton type="restore" onClick={onUnhide} />}
      details={<MissionRoles roles={mission?.roles || []} />}
      video={
        mission?.videoURL ? (
          <VideoPlayer
            url={mission.videoURL}
            noBorder={true}
            borderRadius={0}
            width="100%"
          />
        ) : undefined
      }
      matchList={
        criteria.length ? (
          <MatchList criteria={criteria} numberOnly />
        ) : undefined
      }
      fullWidth={false}
    />
  );
};

export default NotInterestedMissionCard;
