import { VettingType } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { VettingProcessEmailTemplates } from '@a_team/models/dist/vetting-processes/emails';
import { EmailTemplateOptionType, emailTemplateToOption } from '.';

export const EmailTemplateToLabelMap: Record<
  VettingProcessEmailTemplates,
  string
> = {
  // Selection Team
  [VettingProcessEmailTemplates.STInitial]: 'First email',
  [VettingProcessEmailTemplates.STFollowUp]: 'First follow-up',
  [VettingProcessEmailTemplates.STSecondFollowUp]: 'Second follow-up',
  [VettingProcessEmailTemplates.STScheduleCall]: 'Schedule call',

  // Internal Technical
  [VettingProcessEmailTemplates.InternalTechnicalInitial]: 'First email',
  [VettingProcessEmailTemplates.InternalTechnicalFollowUp]: 'First follow-up',

  // Internal Non-Technical
  [VettingProcessEmailTemplates.InternalNonTechnicalInitial]: 'First email',
  [VettingProcessEmailTemplates.InternalNonTechnicalFollowUp]:
    'First follow-up',
};

export const EmailTemplateToDescriptionMap: Record<
  VettingProcessEmailTemplates,
  string
> = {
  // Selection Team
  [VettingProcessEmailTemplates.STInitial]:
    'Send email to builder to fill pre-evaluation form',
  [VettingProcessEmailTemplates.STFollowUp]:
    'When there is no response to first email in 3-5 days',
  [VettingProcessEmailTemplates.STSecondFollowUp]:
    'When there is no response to follow-up in 3-5 days',
  [VettingProcessEmailTemplates.STScheduleCall]:
    'Send email to invite builder to evaluation call',

  // Internal Technical
  [VettingProcessEmailTemplates.InternalTechnicalInitial]:
    'Send email to invite builder to evaluation call',
  [VettingProcessEmailTemplates.InternalTechnicalFollowUp]:
    'When there is no response to first email in 3-5 days',

  // Internal Non-Technical
  [VettingProcessEmailTemplates.InternalNonTechnicalInitial]:
    'Send email to invite builder to evaluation call',
  [VettingProcessEmailTemplates.InternalNonTechnicalFollowUp]:
    'When there is no response to first email in 3-5 days',
};

const EmailTemplateToEvaluationTypeMap: Record<
  VettingProcessEmailTemplates,
  VettingType
> = {
  // Selection Team
  [VettingProcessEmailTemplates.STInitial]: VettingType.SelectionTeam,
  [VettingProcessEmailTemplates.STFollowUp]: VettingType.SelectionTeam,
  [VettingProcessEmailTemplates.STSecondFollowUp]: VettingType.SelectionTeam,
  [VettingProcessEmailTemplates.STScheduleCall]: VettingType.SelectionTeam,

  // Internal Technical
  [VettingProcessEmailTemplates.InternalTechnicalInitial]:
    VettingType.InternalTechnical,
  [VettingProcessEmailTemplates.InternalTechnicalFollowUp]:
    VettingType.InternalTechnical,

  // Internal Non-Technical
  [VettingProcessEmailTemplates.InternalNonTechnicalInitial]:
    VettingType.InternalNonTechnical,
  [VettingProcessEmailTemplates.InternalNonTechnicalFollowUp]:
    VettingType.InternalNonTechnical,
};

export function getEmailTemplatesOptions(
  evaluationType?: VettingType,
): EmailTemplateOptionType[] {
  if (!evaluationType) {
    return Object.values(VettingProcessEmailTemplates).map(
      emailTemplateToOption,
    );
  }

  return Object.entries(EmailTemplateToEvaluationTypeMap)
    .filter(([, type]) => type === evaluationType)
    .map(([template]) =>
      emailTemplateToOption(template as VettingProcessEmailTemplates),
    );
}
