import { CDN_BASE_URL } from '@src/config';
import React from 'react';
import { createUseStyles } from 'react-jss';
import AspectRatioBox from '../AspectRatioBox';
import { Icon } from '@a_team/ui-components';
import CompanyLogoFallback from '../CompanyLogoFallback';
import cx from 'classnames';

interface NoCoverProps {
  number: number;
  companyLogo?: string;
  className?: string;
  logoSize?: number;
  imgClassName?: string;
  paddingLogoContainer?: string;
  borderRadius?: number;
  logoClassName?: string;
  style?: React.CSSProperties;
  iconSize?: 'md' | 'sm' | 'lg';
  noCoverIconClassName?: string;
}

const useStyles = createUseStyles<{
  noCoverColor: string;
  logoSize?: number;
  paddingLogoContainer: string;
  borderRadius: number;
}>({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  logoContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: ({ paddingLogoContainer }) => paddingLogoContainer || '0 24px',
  },
  logoImgContainer: {
    width: ({ logoSize }) => logoSize || 64,
    height: ({ logoSize }) => logoSize || 64,
  },
  logoImgWrapper: {
    borderRadius: ({ borderRadius }) => borderRadius || '8px',
  },
  noCompanyLogo: {
    background: ({ noCoverColor }) => noCoverColor,
    borderRadius: ({ borderRadius }) => borderRadius || '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ({ logoSize }) => logoSize || 64,
    height: ({ logoSize }) => logoSize || 64,
  },
});

const defaultCovers = [
  `${CDN_BASE_URL}/website/project-cover-0.jpg`,
  `${CDN_BASE_URL}/website/project-cover-1.jpg`,
  `${CDN_BASE_URL}/website/project-cover-2.jpg`,
  `${CDN_BASE_URL}/website/project-cover-3.jpg`,
  `${CDN_BASE_URL}/website/project-cover-4.jpg`,
  `${CDN_BASE_URL}/website/project-cover-5.jpg`,
  `${CDN_BASE_URL}/website/project-cover-6.jpg`,
  `${CDN_BASE_URL}/website/project-cover-7.jpg`,
  `${CDN_BASE_URL}/website/project-cover-8.jpg`,
  `${CDN_BASE_URL}/website/project-cover-9.jpg`,
  `${CDN_BASE_URL}/website/project-cover-10.jpg`,
  `${CDN_BASE_URL}/website/project-cover-11.jpg`,
];

export const noCoverColors = [
  'linear-gradient(180deg, #44B1C9 0%, #0B6CA3 100%)',
  'linear-gradient(180deg, #7F4DE8 0%, #6A1ECA 100%)',
  'linear-gradient(180deg, #3CBA92 0%, #0BA360 100%)',
];

const NoCover = ({
  number,
  companyLogo,
  className,
  logoSize,
  imgClassName,
  paddingLogoContainer,
  borderRadius,
  logoClassName,
  style,
  iconSize,
  noCoverIconClassName,
}: NoCoverProps): JSX.Element => {
  const styles = useStyles({
    noCoverColor: noCoverColors[number % noCoverColors.length],
    logoSize,
    paddingLogoContainer,
    borderRadius,
  });
  const coverIndex = number % defaultCovers.length;
  const coverUrl = defaultCovers[coverIndex];

  return (
    <div className={cx(styles.container, className)} style={style}>
      <AspectRatioBox aspectRatio={4 / 3}>
        <div>
          <img
            className={cx(styles.image, imgClassName)}
            src={coverUrl}
            alt="cover"
          />
          <div className={styles.logoContainer}>
            {companyLogo ? (
              <div className={styles.logoImgContainer}>
                <AspectRatioBox
                  aspectRatio={1}
                  className={styles.logoImgWrapper}
                >
                  <CompanyLogoFallback
                    className={logoClassName}
                    borderRadius={borderRadius}
                    logoUrl={companyLogo}
                  />
                </AspectRatioBox>
              </div>
            ) : (
              <div className={styles.noCompanyLogo}>
                <Icon
                  className={noCoverIconClassName}
                  name="projects"
                  size={iconSize || 'lg'}
                  color="Grey@100"
                />
              </div>
            )}
          </div>
        </div>
      </AspectRatioBox>
    </div>
  );
};

export default NoCover;
