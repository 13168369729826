import UserAvatar from '@src/components/UserAvatar';
import { observer } from 'mobx-react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { ConnectModalScreens } from '../../types';
import connectionAdd from './connectionAdd.svg';
import connectionRemove from './connectionRemove.svg';
import { ConnectionsModalSources } from '@ateams/analytics/dist/platform';

interface ProfilePicturesProps {
  currentUserProfilePictureURL: string;
  userToConnectProfilePictureURL: string;
  screen: ConnectModalScreens;
  source?: ConnectionsModalSources;
}

const useStyles = createUseStyles<{
  screen: ConnectModalScreens;
}>({
  wrapper: {
    marginTop: ({ screen }) =>
      [ConnectModalScreens.SetWorkingExperience].includes(screen) ? 34 : 0,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: 280,
    height: 120,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  connectionAdd: {
    zIndex: 9999,
    width: 64,
    height: 64,
    top: 30,
    position: 'absolute',
  },
});

const commonContainerStyles: React.CSSProperties = {
  position: 'absolute',
  borderRadius: '50%',
  aspectRatio: '1/1',
};

function ProfilePictures({
  currentUserProfilePictureURL,
  userToConnectProfilePictureURL,
  screen,
}: ProfilePicturesProps) {
  const styles = useStyles({
    screen,
  });

  const getMiddleImage = (screen: ConnectModalScreens) => {
    if (
      screen === ConnectModalScreens.CancelConnectionRequest ||
      screen === ConnectModalScreens.RejectConnectionRequest ||
      screen === ConnectModalScreens.DeleteConnection
    ) {
      return connectionRemove;
    }

    return connectionAdd;
  };

  return (
    <div className={styles.wrapper}>
      <img
        src={getMiddleImage(screen)}
        alt="Connect"
        className={styles.connectionAdd}
      />

      <UserAvatar
        src={currentUserProfilePictureURL}
        containerStyle={{
          ...commonContainerStyles,
          top: 0,
          left: 0,
          height: 120,
          width: 120,
        }}
        size={120}
      />

      <UserAvatar
        src={userToConnectProfilePictureURL}
        containerStyle={{
          ...commonContainerStyles,
          top: 0,
          right: 0,
          height: 120,
          width: 120,
        }}
        size={120}
      />
    </div>
  );
}

export default observer(ProfilePictures);
