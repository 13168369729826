import NoResults from '@src/components/NoResults';
import { useGetNotInterestedMissionsQueryV2 } from '@src/rq/missions';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import CardList from '../CardList';
import NotInterestedMissionCardV2 from './NotInterestedMissionCardV2';
import { useStores } from '@src/stores';

const NotInterestedMissionsV2 = () => {
  const { missionControl } = useStores();
  const { data, isLoading } = useGetNotInterestedMissionsQueryV2();

  const notInterestedMissions = useMemo(() => {
    if (!data?.data) return [];

    if (!missionControl?.queryFilter) return data?.data;

    const words = missionControl.toQueryFilters(missionControl.queryFilter);

    if (!words.length) {
      return data?.data;
    }

    return data?.data.filter((mission) => {
      const keywords = [
        mission.title,
        mission.description,
        mission.companyName,
        ...mission.roleCategories.map((roleCategory) =>
          [roleCategory.title].join(' '),
        ),
      ].join(' ');

      return words.every((word) => word.test(keywords));
    });
  }, [missionControl?.queryFilter, data?.data]);

  return (
    <div>
      {notInterestedMissions.length === 0 || isLoading ? (
        <NoResults
          imageType="noSearchResults"
          title="No not interested missions found!"
          text={<p>Looks like you haven't not interested any mission yet.</p>}
        />
      ) : (
        notInterestedMissions.length > 0 && (
          <CardList>
            {notInterestedMissions?.map((mission) => (
              <NotInterestedMissionCardV2
                key={`not-interested-mission-${mission.mid}`}
                mission={mission}
                isLoading={isLoading}
              />
            ))}
          </CardList>
        )
      )}
    </div>
  );
};

export default observer(NotInterestedMissionsV2);
