import { useEffect, useRef, DependencyList } from 'react';

export function useDebouncedEffect(
  callback: () => void,
  delay: number,
  dependencies: DependencyList,
): void {
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Clear the timer on unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Set up the debounced effect
    const timerId = setTimeout(() => {
      callback();
    }, delay);

    // Clear the previous timer if a new effect is scheduled
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Store the new timer ID
    timerRef.current = timerId;

    // Clean up the timer if the dependencies change
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, dependencies);
}
