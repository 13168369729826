import React, { CSSProperties, ReactElement, ReactNode, useRef } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import useClickOutside from '@src/hooks/useClickOutside';
import DropdownItem from './Item';

interface Props {
  className?: string;
  style?: CSSProperties;
  open: boolean;
  onClose?: () => void;
  children: ReactNode;
}

const userStyles = createUseStyles({
  root: {
    minWidth: '150px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    userSelect: 'none',
  },

  // background: #fffaf7;
  // color: #fe630c;
});

export default function Dropdown(props: Props): ReactElement | null {
  const { className, style, open, onClose, children } = props;
  const styles = userStyles();

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, (): void => {
    if (!open || !onClose) return;

    onClose();
  });

  if (!open) {
    return null;
  }

  return (
    <div ref={ref} className={cx(styles.root, className)} style={style}>
      {children}
    </div>
  );
}

export { DropdownItem };
