import React, { useEffect, useMemo, useState } from 'react';
import { components, OptionProps, SingleValueProps } from 'react-select';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { useStores } from '@src/stores';
import { AsyncSelect, AsyncSelectProps, SelectProps } from '@ateams/components';
import { apiUsers } from '@ateams/api';
import UserAvatar from '@src/components/UserAvatar';
import useLoadingState from '@src/hooks/useLoadingState';
import {
  AdminBasicUserObject,
  BasicUserObject,
  UserId,
  UserStatus,
} from '@a_team/models/dist/UserObject';

export type OnUserSelect = (user: UserOptionType | null) => void;
export type OptionUser = Pick<
  AdminBasicUserObject,
  'uid' | 'email' | 'fullName' | 'profilePictureURL'
>;

export interface UserSelectorProps
  extends Omit<AsyncSelectProps<false, UserOptionType>, 'loadOptions'> {
  onUserSelect?: OnUserSelect;
  value?: UserOptionType;
  defaultValue?: UserId | OptionUser;
  userStatus?: UserStatus[];
  userOptionsProps?: UserOptionProps;
  userSingleValueProps?: UserSingleValueProps;
  'data-testing-id'?: string;
}

export interface UserOptionType {
  key: string;
  value: string;
  label: string;
  user?: OptionUser | null;
}

function userToOption(user: OptionUser): UserOptionType {
  return {
    key: user.uid,
    value: user.uid,
    label: user.fullName,
    user,
  };
}

const noOptionsMessage: SelectProps['noOptionsMessage'] = ({ inputValue }) =>
  inputValue ? 'No options' : 'Type to search';

export const UserSelector: React.FC<UserSelectorProps> = (props) => {
  const {
    defaultValue,
    onUserSelect,
    isDisabled,
    userOptionsProps,
    userSingleValueProps,
    ...rest
  } = props;
  const { auth } = useStores();
  const [loadingDefault, setLoadingDefault] = useLoadingState();
  const [user, setUser] = useState<OptionUser>();

  const setDefault = async () => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        try {
          setLoadingDefault(
            (async () => {
              const user = await apiUsers.getUserById(auth, defaultValue);

              setUser(user as OptionUser);
            })(),
            false,
          );
        } catch (err) {
          console.error(err, 'Failed to find user by ID');
        }
      } else {
        setUser(defaultValue);
      }
    }
  };

  useEffect(() => {
    setDefault();
  }, []);

  const value = useMemo(
    () => props.value?.user || (user ? userToOption(user) : undefined),
    [props.value?.user, user],
  );

  const onUserChange = async (option: UserOptionType | null) => {
    const user = option?.user || undefined;

    setUser(user);
    onUserSelect && onUserSelect(option);
  };

  const loadOptions = async (expression: string) => {
    if (!expression) {
      return [];
    }

    let users: BasicUserObject[];
    if (auth.isAdmin) {
      users = await apiUsers.adminQueryByString(auth, expression, {
        userStatus: props.userStatus,
      });
    } else {
      users = await apiUsers.queryByString(auth, expression);
    }

    return users.map((user) => userToOption(user as OptionUser));
  };

  const components = useMemo(() => {
    return {
      Option: (props: React.ComponentProps<typeof UserOption>) => (
        <UserOption {...props} {...userOptionsProps} />
      ),
      SingleValue: (props: React.ComponentProps<typeof UserSingleValue>) => (
        <UserSingleValue
          {...props}
          {...userSingleValueProps}
          data-testing-id={
            rest['data-testing-id']
              ? `${rest['data-testing-id']}-single-value`
              : undefined
          }
        />
      ),
    };
  }, [userOptionsProps, userSingleValueProps]);

  return (
    <AsyncSelect
      loadOptions={loadOptions}
      onChange={onUserChange}
      value={value}
      noOptionsMessage={noOptionsMessage}
      useDebounce
      isClearable
      isDisabled={isDisabled || loadingDefault}
      components={components}
      isLoading={loadingDefault}
      {...rest}
    />
  );
};

const useOptionStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  labelsContainer: {
    marginLeft: '12px',
  },
  fullNameLabel: {
    fontSize: '16px',
  },
  emailLabel: {
    fontSize: '12px',
  },
});

interface UserOptionProps {
  containerClassName?: string;
  labelsContainerClassName?: string;
  fullNameLabelClassName?: string;
  emailLabelClassName?: string;
  avatarSize?: number;
}

const UserOption: React.FC<
  OptionProps<UserOptionType, false> & UserOptionProps
> = (props) => {
  const {
    containerClassName,
    labelsContainerClassName,
    fullNameLabelClassName,
    emailLabelClassName,
    avatarSize,
  } = props;
  const styles = useOptionStyles();

  return (
    <components.Option {...props}>
      <div className={cx(styles.container, containerClassName)}>
        <UserAvatar src={props.data.user.profilePictureURL} size={avatarSize} />
        <div className={cx(styles.labelsContainer, labelsContainerClassName)}>
          <div className={cx(styles.fullNameLabel, fullNameLabelClassName)}>
            {props.data.user.fullName}
          </div>
          <div className={cx(styles.emailLabel, emailLabelClassName)}>
            {props.data.user.email}
          </div>
        </div>
      </div>
    </components.Option>
  );
};

interface UserSingleValueProps {
  containerClassName?: string;
  labelsContainerClassName?: string;
  fullNameLabelClassName?: string;
  avatarSize?: number;
  'data-testing-id'?: string;
}

const UserSingleValue: React.FC<
  SingleValueProps<UserOptionType> & UserSingleValueProps
> = (props) => {
  const {
    containerClassName,
    labelsContainerClassName,
    fullNameLabelClassName,
    avatarSize = 28,
    'data-testing-id': dataTestingId,
  } = props;
  const styles = useOptionStyles();

  return (
    <components.SingleValue {...props}>
      {props.data.user && (
        <div
          className={cx(styles.container, containerClassName)}
          data-testing-id={dataTestingId}
        >
          <UserAvatar
            src={props.data.user.profilePictureURL}
            size={avatarSize}
          />
          <div className={cx(styles.labelsContainer, labelsContainerClassName)}>
            <div className={cx(styles.fullNameLabel, fullNameLabelClassName)}>
              {props.data.user.fullName}
            </div>
          </div>
        </div>
      )}
    </components.SingleValue>
  );
};
