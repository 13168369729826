import useCommonStyles from './commonStyles';
import { Button } from '@a_team/ui-components';
import { Icon, IconType, Spinner } from '@ateams/components';
import React from 'react';
import cx from 'classnames';

interface SuggestionViewProps {
  suggestion: string;
  onInsertSuggestion: (suggestion: string) => void;
  onDiscardSuggestion: (suggestion: string) => void;
  onRegenerateSuggestion: () => void;
  isLoading?: boolean;
}

const SuggestionView = ({
  onInsertSuggestion,
  onDiscardSuggestion,
  onRegenerateSuggestion,
  suggestion,
  isLoading,
}: SuggestionViewProps) => {
  const styles = useCommonStyles();

  const renderSuggestionContent = () => {
    const chunks = suggestion?.split('\n') ?? [];

    return (
      <>
        {chunks.map((bioChunk, idx) => (
          <React.Fragment key={idx}>
            {bioChunk}
            {idx < chunks.length - 1 && <br />}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.desc}>
        <Icon size="smaller" type={IconType.SuggestedTeams} />
        Suggested bio
      </div>
      <div>
        <div className={styles.suggestedDescription}>
          {renderSuggestionContent()}
        </div>
        <div className={styles.btnsContainer}>
          <div className={styles.btnsContainer}>
            <Button
              className={styles.btn}
              onClick={(e) => {
                e.preventDefault();
                onInsertSuggestion(suggestion);
              }}
            >
              <span className={styles.hideOnDesktop}>Use</span>
              <span className={styles.hideOnMobile}>Use suggestion</span>
            </Button>
            <Button
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault();
                onRegenerateSuggestion();
              }}
              className={cx(styles.btn, styles.rewriteDiscardBtn)}
            >
              {isLoading ? (
                <Spinner size={14} color="black" />
              ) : (
                <Icon type={IconType.CrystalBall} size="exact" />
              )}
              Rewrite
            </Button>
          </div>
          <Button
            onClick={(e) => {
              e.preventDefault();
              onDiscardSuggestion(suggestion);
            }}
            className={cx(styles.btn, styles.rewriteDiscardBtn)}
          >
            Discard
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuggestionView;
