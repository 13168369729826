import { action, computed, observable } from 'mobx';
import { Stores } from '@src/stores/index';
import { VettingType } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { TalentSkillId } from '@a_team/models/dist/TalentCategories';
import { RoleCategoryId } from '@a_team/models/dist/RoleCategory';
import { apiVetter } from '@ateams/api';
import type { PatchAdminVetterPayload } from '@ateams/api/dist/endpoints/vetter';
import { VetterConfigurationObject } from '@a_team/models/src/vetter';

export interface VetterConfigurationStoreData {
  data: VetterConfigurationObject;
  type?: VettingType;
  vettingSkills?: TalentSkillId[];
  vettingRoles?: RoleCategoryId[];
  calendarUrl?: string;
  interviewCapacity?: number;
  emailSignature?: string;
  isActive: boolean;
}

export default class VetterConfiguration
  implements VetterConfigurationStoreData
{
  @observable public data: VetterConfigurationStoreData['data'];
  @observable type: VetterConfigurationStoreData['type'];
  @observable vettingSkills: VetterConfigurationStoreData['vettingSkills'];
  @observable vettingRoles: VetterConfigurationStoreData['vettingRoles'];
  @observable calendarUrl: VetterConfigurationStoreData['calendarUrl'];
  @observable
  interviewCapacity: VetterConfigurationStoreData['interviewCapacity'];
  @observable emailSignature: VetterConfigurationStoreData['emailSignature'];
  @observable isActive: VetterConfigurationStoreData['isActive'];

  private authStore: Stores['auth'];

  public constructor(
    auth: Stores['auth'],
    vetterConfigurationData: VetterConfigurationObject,
  ) {
    this.authStore = auth;
    this.data = vetterConfigurationData;
    this.type = vetterConfigurationData.type;
    this.vettingSkills = vetterConfigurationData.vettingSkills?.map(
      ({ id }) => id,
    );
    this.vettingRoles = vetterConfigurationData.vettingRoles?.map(
      ({ cid }) => cid,
    );
    this.calendarUrl = vetterConfigurationData.calendarUrl;
    this.interviewCapacity = vetterConfigurationData.interviewCapacity;
    this.emailSignature = vetterConfigurationData.emailSignature;
    this.isActive = vetterConfigurationData.isActive;
  }

  serialize = (): VetterConfigurationStoreData => {
    return {
      data: this.data,
      type: this.type,
      vettingSkills: this.vettingSkills,
      vettingRoles: this.vettingRoles,
      calendarUrl: this.calendarUrl,
      interviewCapacity: this.interviewCapacity,
      emailSignature: this.emailSignature,
      isActive: this.isActive,
    };
  };

  @computed get hasVetterConfigurationChanged(): boolean {
    return (
      this.hasTypeChanged ||
      this.hasCalendarUrlChanged ||
      this.hasInterviewCapacityChanged ||
      this.hasEmailSignatureChanged ||
      this.hasIsActiveChanged ||
      this.hasVettingSkillsChanged ||
      this.hasVettingRolesChanged
    );
  }

  @computed get hasTypeChanged(): boolean {
    return this.data.type !== this.type;
  }

  @computed get hasCalendarUrlChanged(): boolean {
    return this.data.calendarUrl !== this.calendarUrl;
  }

  @computed get hasInterviewCapacityChanged(): boolean {
    return this.data.interviewCapacity !== this.interviewCapacity;
  }

  @computed get hasEmailSignatureChanged(): boolean {
    return this.data.emailSignature !== this.emailSignature;
  }

  @computed get hasIsActiveChanged(): boolean {
    return this.data.isActive !== this.isActive;
  }

  @computed get hasVettingSkillsChanged(): boolean {
    if (this.data.vettingSkills?.length !== this.vettingSkills?.length) {
      return true;
    }

    return !this.data.vettingSkills
      ?.map(({ id }) => id)
      .every((vettingSkill, i) => vettingSkill === this.vettingSkills?.[i]);
  }

  @computed get hasVettingRolesChanged(): boolean {
    if (this.data.vettingRoles?.length !== this.vettingRoles?.length) {
      return true;
    }

    return !this.data.vettingRoles
      ?.map(({ cid }) => cid)
      .every((vettingRole, i) => vettingRole === this.vettingRoles?.[i]);
  }

  @computed get updateVetterPayload(): PatchAdminVetterPayload {
    const payload: PatchAdminVetterPayload = {
      vetterId: this.data.id,
    };

    if (this.hasTypeChanged) {
      payload.type = this.type;
    }

    if (this.hasCalendarUrlChanged) {
      payload.calendarUrl = this.calendarUrl;
    }

    if (this.hasInterviewCapacityChanged) {
      payload.interviewCapacity = this.interviewCapacity;
    }

    if (this.hasEmailSignatureChanged) {
      payload.emailSignature = this.emailSignature;
    }

    if (this.hasIsActiveChanged) {
      payload.isActive = this.isActive;
    }

    if (this.hasVettingSkillsChanged) {
      payload.vettingSkills = this.vettingSkills;
    }

    if (this.hasVettingRolesChanged) {
      payload.vettingRoles = this.vettingRoles;
    }

    return payload;
  }

  updateVetter = async (payload: PatchAdminVetterPayload): Promise<void> => {
    await apiVetter.updateVetter(this.authStore, payload);
  };

  @action setType = (type: VettingType): void => {
    this.type = type;
  };

  @action setCalendarUrl = (calendarUrl: string): void => {
    this.calendarUrl = calendarUrl;
  };

  @action setInterviewCapacity = (interviewCapacity: number): void => {
    this.interviewCapacity = interviewCapacity;
  };

  @action setEmailSignature = (emailSignature: string): void => {
    this.emailSignature = emailSignature;
  };

  @action setIsActive = (isActive: boolean): void => {
    this.isActive = isActive;
  };

  @action setVettingSkills = (vettingSkills: TalentSkillId[]): void => {
    this.vettingSkills = vettingSkills;
  };

  @action setVettingRoles = (vettingRoles: RoleCategoryId[]): void => {
    this.vettingRoles = vettingRoles;
  };
}
