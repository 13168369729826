import React from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Colors } from '@ateams/components';
import cx from 'classnames';

interface ExitConfirmationProps {
  onExit: () => void;
  onBack: () => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    height: '100%',
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'center',
  },
  desc: {
    textAlign: 'center',
    color: '#707275',
    marginTop: 10,
  },
  buttonContainer: {
    display: 'flex',
    gap: 8,
    marginTop: 40,
  },
  button: {
    width: 'fit-content',
    height: 40,
    padding: '0 20px',
  },
  secondaryButton: {
    background: Colors.backgroundDark,
    color: 'black',
  },
});

const ExitConfirmation = ({
  onBack,
  onExit,
}: ExitConfirmationProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Are you sure you want to exit?</h4>
      <p className={styles.desc}>
        All the progress you made will be lost. You will have to fill it out
        again next time, before submitting your timesheet.
      </p>
      <div className={styles.buttonContainer}>
        <Button
          onClick={onBack}
          className={cx(styles.button, styles.secondaryButton)}
        >
          No, back to Team Pulse
        </Button>
        <Button onClick={onExit} className={styles.button}>
          Yes, exit
        </Button>
      </div>
    </div>
  );
};

export default ExitConfirmation;
