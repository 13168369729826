import { Breakpoints, Colors } from '@ateams/components';
import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import HideScrollerGlobally from '../HideScrollerGlobally';
import Body from './Body';
import useClickOutside from '@src/hooks/useClickOutside';
import useMediaQuery from '@src/hooks/useMediaQuery';

interface NotificationSliderProps {
  isOpen: boolean | undefined;
  toggle: () => void;
}

const LEFT_NAV_WIDTH = 120;

const useStyles = createUseStyles({
  overlay: {
    content: '""',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 'calc(120px)',
    background: 'rgba(128, 128, 128, 0.5)',
    transition: 'opacity 0.3s ease-in-out',
    zIndex: 1000,
    opacity: (props) => (props.isOpen ? 1 : 0),
    pointerEvents: (props) => (props.isOpen ? 'auto' : 'none'),
  },
  navigation: {
    background: Colors.backgroundWhite,
    position: 'fixed',
    top: 0,
    left: (props) => (props.isOpen ? `${LEFT_NAV_WIDTH}px` : `-${500}px`),
    height: '100%',
    maxHeight: '100vh',
    width: '500px',
    transition: 'left 0.3s ease-in-out',
    zIndex: 1001,
    overflowY: 'auto',
  },
});

const NotificationSlider = ({ isOpen, toggle }: NotificationSliderProps) => {
  const styles = useStyles({ isOpen });
  const ref = useRef<HTMLDivElement>(null);
  const isDesktop = useMediaQuery(`(min-width: ${Breakpoints.sm}px)`);

  useClickOutside(ref, () => {
    if (isOpen && isDesktop) {
      toggle();
    }
  });

  return (
    <>
      <div className={styles.overlay} />
      <div ref={ref} className={styles.navigation}>
        {isOpen && <HideScrollerGlobally />}
        <Body toggle={toggle} />
      </div>
    </>
  );
};

export default NotificationSlider;
