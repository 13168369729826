import { DateISOString } from '@a_team/models/src/misc';
import { ServiceAuth, ServiceEndpoint } from './utils';

/**
 * RewardScoreType type of reward score.
 */
export enum RewardScoreType {
  /**
   * BilledHoursToAnyMission is awarded for:
   * - You billing any amount of hours to missions.
   */
  BilledHoursToAnyMission = 'billedHoursToAnyMission',
  /**
   * InvitedBuilderBilledToMission is awarded for:
   * - You vetted a builder, who then billed hours to a mission.
   */
  InvitedBuilderBilledToMission = 'invitedBuilderBilledToMission',
  /**
   * InvitedCompanyBilledToMission is awarded for:
   * - You invited a company, that then billed hours to a mission.
   */
  InvitedCompanyBilledToMission = 'invitedCompanyBilledToMission',
  CustomIssuance = 'CustomIssuance',
}

export enum RewardBadgeType {
  /**
   * FoundingMember is a member of the network that was active as of April 2022.
   */
  FoundingMember = 'foundingMember',
  CommunityLeader = 'communityLeader',
  FeedbackGiver = 'feedbackGiver',
  PowerUser = 'powerUser',
}

/**
 * CurrentRewardsScoreContribution is one part of the total score contribution.
 */
export interface CurrentRewardsScoreContribution {
  /**
   * date at which this result was generated.
   */
  readonly date: DateISOString;

  /**
   * type of the reward score.
   * @type {RewardScoreType}
   */
  readonly type: RewardScoreType;

  /**
   * description suitable for displaying to the end user.
   */
  readonly description: string;

  /**
   * totalScore contribution from this part of the total calculation.
   */
  readonly totalScore: number;

  /**
   * totalHours contribution from this part of the total calculation.
   */
  readonly totalHours: number;
}

/**
 * CurrentRewardsBadgeContribution is a single badge, awarded to the user at some point in history.
 */
export interface RewardBadge {
  /**
   * date at which this badge was awarded.
   */
  readonly date: DateISOString;

  /**
   * type of badge
   * @type {RewardBadgeType}
   */
  readonly type: RewardBadgeType;
}

export interface CurrentRewardsScoreResponse {
  /**
   * date at which this result was generated.
   * Note: these results may be cached. If that is the case, this value
   *  will represent the date at which the result was originally calculated.
   */
  readonly date: DateISOString;

  /**
   * totalScore assigned to the user at the time of calculation.
   */
  readonly totalScore: number;

  /**
   * contributions from each of the score parts.
   */
  readonly contributions: readonly CurrentRewardsScoreContribution[];

  /**
   * badges currently awarded to the user.
   */
  readonly badges: readonly RewardBadge[];
}

export const BasePath = '/rewards';

export default class RewardsEndpoint extends ServiceEndpoint {
  public getCurrentRewardsScores(
    auth: ServiceAuth,
  ): Promise<CurrentRewardsScoreResponse> {
    return this.fetch(auth, BasePath + `/current-score`);
  }
}
