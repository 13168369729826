import React, { useMemo } from 'react';
import { useGetAppliedMissionsQueryV2 } from '@src/rq/missions';
import TextButton from '@src/components/TextButton';
import NoResults from '@src/components/NoResults';
import WithdrawApplicationModal from '../WithdrawApplicationModal';
import useWithdrawApplication from '../MissionControlCard/useWithdrawApplication';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import AppliedMissionCardV2 from './AppliedMissionCardV2';
import CardList from '../CardList';
import { createUseStyles } from 'react-jss';
import { toGroupedApplications } from './AppliedMissionCardV2/utils';
const useStyles = createUseStyles<{
  isNewStatus: boolean;
}>({
  '@global': {
    '.tippy-popper div': {
      borderRadius: '4px !important',
    },
  },
  section: {
    fontWeight: 500,
  },
  cardListContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 612px))',
  },
});

const AppliedMissionsV2 = () => {
  const styles = useStyles();
  const { missionControl, auth } = useStores();
  const { data, isLoading, refetch } = useGetAppliedMissionsQueryV2();
  const applications = data?.data ?? [];
  const { activeApplications, concludedApplications } = useMemo(() => {
    return toGroupedApplications(applications, auth.user?.username);
  }, [applications, auth.user?.username]);

  const {
    selectedApplication,
    confirmationModalOpen,
    onConfirmWithdrawModalOpen,
    onConfirmModalClose,
  } = useWithdrawApplication();

  const clearFilters = () => {
    missionControl.clearAppliedMissionsFilters();
    refetch();
  };

  return (
    <div>
      {applications.length === 0 || isLoading ? (
        <NoResults
          imageType="noSearchResults"
          title="No results found!"
          text={
            <>
              <p>
                Sorry, we didn't find any applications with the selected status.
              </p>
              <p>
                Please update your selected filters or{' '}
                <TextButton onClick={clearFilters} color="primary">
                  start again
                </TextButton>
                .
              </p>
            </>
          }
        />
      ) : (
        <>
          {activeApplications.length > 0 && (
            <>
              <p className={styles.section}>Active</p>
              <CardList className={styles.cardListContainer}>
                {activeApplications?.map((application) => (
                  <AppliedMissionCardV2
                    key={`active-${application.aid}`}
                    application={application}
                    isLoading={isLoading}
                    onConfirmWithdrawModalOpen={onConfirmWithdrawModalOpen}
                  />
                ))}
              </CardList>
            </>
          )}

          {concludedApplications.length > 0 && (
            <>
              <p className={styles.section}>Concluded</p>
              <CardList className={styles.cardListContainer}>
                {concludedApplications?.map((application) => (
                  <AppliedMissionCardV2
                    key={`concluded-${application.aid}`}
                    application={application}
                    onConfirmWithdrawModalOpen={onConfirmWithdrawModalOpen}
                    hideInvite
                    isLoading={isLoading}
                  />
                ))}
              </CardList>
            </>
          )}
        </>
      )}

      <WithdrawApplicationModal
        open={confirmationModalOpen}
        application={selectedApplication}
        onClose={onConfirmModalClose}
      />
    </div>
  );
};

export default observer(AppliedMissionsV2);
