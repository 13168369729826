import {
  BasicConnectionObjectV2,
  ConnectionObjectV2,
  ConnectionStatus,
} from '@a_team/models/dist/ConnectionObject';
import { UserId } from '@a_team/models/dist/UserObject';

export const getUpdatedConnectionsForUserToInteract = <
  T extends { connections: BasicConnectionObjectV2[] },
>(
  userToInteract: T,
  connection: ConnectionObjectV2,
) => {
  let connections: BasicConnectionObjectV2[] = [];
  switch (connection.status) {
    case ConnectionStatus.Rejected:
    case ConnectionStatus.Deleted:
      connections = userToInteract.connections.filter(
        (c) => c.cid !== connection.cid,
      );
      break;
    case ConnectionStatus.Active:
      connections = userToInteract.connections.map((c) =>
        c.cid === connection.cid ? connection : c,
      );
      break;
    case ConnectionStatus.Pending:
      connections = [...userToInteract.connections, connection];
      break;
  }

  return {
    ...userToInteract,
    connections,
  };
};

export const getToUser = (
  connection: ConnectionObjectV2,
  currentUserId: UserId | undefined,
) => {
  return currentUserId === connection.initiator.uid
    ? connection.requested
    : connection.initiator;
};
