import React, { ReactElement, useState } from 'react';
import Modal from '..';
import SectionHeading from '@src/components/SectionHeading';
import FileUploader, { FileUploaderProps } from '@src/components/FileUploader';
import { Button } from '@a_team/ui-components';

interface Props
  extends Pick<
    FileUploaderProps,
    'cropAspect' | 'circularCrop' | 'withCrop' | 'type' | 'downscale'
  > {
  initialUrl?: string;
  open: boolean;
  onClose(): void;
  onAccept(url: string): void;
  title?: string;
}

const UploadImageModal = (props: Props): ReactElement => {
  const {
    initialUrl,
    open,
    onClose,
    onAccept,
    title,
    cropAspect,
    withCrop = true,
    circularCrop,
    type,
    downscale,
  } = props;
  const [url, setUrl] = useState(initialUrl);

  const onImageChange = (newUrl: string) => {
    setUrl(newUrl);
  };

  const onSubmit = () => {
    url && onAccept(url);
  };

  return (
    <Modal onClose={onClose} open={open} style={{ overflow: 'visible' }}>
      {title && <SectionHeading isFirst>{title}</SectionHeading>}
      <FileUploader
        fileUrl={url}
        onFileUploadSuccess={onImageChange}
        cropAspect={cropAspect}
        withOptionBox={false}
        withCrop={withCrop}
        circularCrop={circularCrop}
        type={type}
        downscale={downscale}
      />
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 42 }}>
        <Button
          size={'lg'}
          onClick={onSubmit}
          disabled={url === initialUrl || !url}
          margin={'none'}
        >
          Publish
        </Button>
      </div>
    </Modal>
  );
};

export default UploadImageModal;
