import React, { ReactElement } from 'react';

export const PurpleFiles = (): ReactElement => {
  return (
    <svg
      width="64"
      height="48"
      viewBox="0 0 64 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Image">
        <rect id="Rectangle 202" width="64" height="48" rx="6" fill="#EDE0FF" />
        <path
          id="Intersect"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32 45.0001H57C59.2091 45.0001 61 43.2093 61 41.0001V36.7839L51.2165 26.736C50.8239 26.3328 50.1761 26.3328 49.7835 26.736L32 45.0001Z"
          fill="#D4A8FF"
        />
        <path
          id="Intersect_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 44.9997H49L22.4142 18.4139C21.6332 17.6329 20.3668 17.6329 19.5858 18.4139L3 34.9997V40.9997C3 43.2088 4.79086 44.9997 7 44.9997Z"
          fill="#E8D0FF"
        />
        <circle id="Ellipse 63" cx="53" cy="11" r="6" fill="#FCFAFF" />
      </g>
    </svg>
  );
};
