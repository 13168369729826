import React from 'react';
import MainLayout from '@src/layouts/Main';
import { observer } from 'mobx-react';

interface Props {
  src?: string;
}

const GuideView: React.FC<Props> = ({ src }) => {
  return (
    <MainLayout title="A.Guide" style={{ padding: 0 }}>
      <iframe
        title="guide"
        is="x-frame-bypass"
        src={src ?? 'https://guide.a.team/'}
        width="100%"
        style={{ height: '100vh' }}
        frameBorder="0"
      />
    </MainLayout>
  );
};

export default observer(GuideView);
export { NonVerifiedGuide } from './NonVerifiedGuide';
