import { ServiceAuth, ServiceEndpoint } from './utils';
import { QueryRequestPayload, QueryResult } from '@a_team/models/dist/misc';
import {
  TalentCategoryId,
  TalentSkill,
} from '@a_team/models/dist/TalentCategories';

export const BasePath = '/talent-skills';

export interface TalentSkillsQueryData extends QueryRequestPayload {
  ids?: string; // ids separated by comma
  talentCategoryIds?: TalentCategoryId[];
  searchTerm?: string;
  searchProgrammingLanguages?: boolean;
  useLimit?: boolean;
  onlyVerifiedSkills?: boolean;
}

export interface TalentSkillData {
  name: string;
  talentCategoryIds: TalentCategoryId[];
}

export default class TalentSkillsEndpoint extends ServiceEndpoint {
  public queryTalentSkills(
    query: TalentSkillsQueryData,
  ): Promise<QueryResult<TalentSkill>> {
    return this.fetch(null, BasePath, { ...query });
  }

  public getTalentSkillById(talentSkillId: string): Promise<TalentSkill> {
    return this.fetch(null, `${BasePath}/${talentSkillId}`, null, 'get');
  }

  public createTalentSkills(
    auth: ServiceAuth,
    data: TalentSkillData,
  ): Promise<TalentSkill> {
    return this.fetch(auth, BasePath, null, 'post', data);
  }

  public updateTalentSkill(
    auth: ServiceAuth,
    data: TalentSkillData,
    talentSkillId: string,
  ): Promise<TalentSkill> {
    return this.fetch(auth, `${BasePath}/${talentSkillId}`, null, 'put', data);
  }

  public deleteTalentSkill(
    auth: ServiceAuth,
    talentSkillId: string,
  ): Promise<void> {
    return this.fetch(auth, `${BasePath}/${talentSkillId}`, null, 'delete');
  }
}
