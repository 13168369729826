import {
  Colors,
  BorderColors,
  Icon,
  IconType,
  BorderRadius,
  FontSizes,
  Spacing,
} from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  linkItem: {
    width: '100%',
    height: 48,
    display: 'flex',
    gap: Spacing.xsmall,
    alignItems: 'center',
    position: 'relative',
    borderRadius: BorderRadius.medium,
    padding: `${Spacing.small}px ${Spacing.medium}px`,
  },
  input: {
    border: 'none',
    borderRadius: BorderRadius.medium,
    width: '100%',
    height: 24,
    fontSize: FontSizes.medium,
  },
  linkIcon: {
    width: 16,
    height: 16,
  },
  checkIcon: {
    width: 16,
    height: 16,
  },
  closeIcon: {
    width: 12,
    height: 12,
  },
});

const LinkInput: React.FC<{
  link: string;
  index: number;
  valid: boolean;
  onChange: (index: number, value: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}> = ({ link, index, valid, onChange, onKeyDown }) => {
  const styles = useStyles();
  return (
    <div
      className={styles.linkItem}
      style={{
        border: link
          ? valid
            ? `1px solid ${Colors.secondaryDark}`
            : `1px solid ${Colors.primary}`
          : `1px solid ${BorderColors.lighter}`,
      }}
    >
      {link && (
        <Icon
          type={valid ? IconType.LinkSecondary : IconType.LinkBlack}
          className={styles.linkIcon}
        />
      )}
      <input
        className={styles.input}
        placeholder="Enter link"
        value={link}
        onChange={(e) => onChange(index, e.target.value)}
        onKeyDown={onKeyDown}
      />
      {link &&
        (valid ? (
          <Icon type={IconType.CheckPurple} className={styles.checkIcon} />
        ) : (
          <Icon type={IconType.Close} className={styles.closeIcon} />
        ))}
    </div>
  );
};

export default LinkInput;
