import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import useLoadingState from '@src/hooks/useLoadingState';
import { Select, SelectOption, SelectProps, Spacing } from '@ateams/components';
import {
  TalentSkill,
  TalentSkillId,
} from '@a_team/models/dist/TalentCategories';
import { useTalentSkills } from '@src/rq/useTalentSkills';
import { fetchSkillList } from '@src/helpers/talent-skills-api';
import { createUseStyles } from 'react-jss';

export interface CodeSampleProgrammingLanguageSelectorProps<
  IsMulti extends boolean = false,
> extends Omit<SelectProps<IsMulti>, 'loadOptions'> {
  isMulti?: boolean;
  defaultValue?: IsMulti extends true ? TalentSkillId[] : TalentSkillId;
}

function talentSkillToOption(talentSkill: TalentSkill): SelectOption {
  return {
    value: talentSkill.id,
    label: talentSkill.name,
  };
}

const useStyles = createUseStyles({
  tagListClassName: {
    marginTop: Spacing.small,
  },
});

export const CodeSampleProgrammingLanguageSelector = <
  T extends CodeSampleProgrammingLanguageSelectorProps<
    T['isMulti'] extends true ? true : false
  >,
>(
  props: T,
): ReactElement => {
  const styles = useStyles();

  const {
    defaultValue,
    isDisabled,
    onChange,
    isMulti = false,
    ...rest
  } = props;
  const [loadingDefault, setLoadingDefault] = useLoadingState();
  const [codeSampleProgrammingLanguage, setCodeSampleProgrammingLanguage] =
    useState<SelectOption[] | SelectOption | null>();
  const [searchTerm, setSearchTerm] = useState('');
  const { data: skills, isLoading } = useTalentSkills({
    searchTerm,
    searchProgrammingLanguages: true,
    useLimit: false,
  });

  const setDefault = async () => {
    if (defaultValue) {
      try {
        setLoadingDefault(
          (async () => {
            if (isMulti) {
              const codeSampleProgrammingLanguages = await fetchSkillList({
                filter: {
                  id: defaultValue as string[],
                },
              });

              setCodeSampleProgrammingLanguage(
                codeSampleProgrammingLanguages.map(talentSkillToOption),
              );
            } else {
              const codeSampleProgrammingLanguage = await fetchSkillList({
                filter: {
                  id: [defaultValue as string],
                },
              });
              setCodeSampleProgrammingLanguage(
                talentSkillToOption(codeSampleProgrammingLanguage?.[0]),
              );
            }
          })(),
          false,
        );
      } catch (err) {
        console.error(
          err,
          'Failed to find code sample programming language by id',
        );
      }
    }
  };

  useEffect(() => {
    setDefault();
  }, []);

  const value = useMemo(
    () => props.value || codeSampleProgrammingLanguage,
    [props.value, codeSampleProgrammingLanguage],
  );

  const onCodeSampleProgrammingLanguageChange = async (
    option: SelectOption[] | SelectOption | null,
  ) => {
    setCodeSampleProgrammingLanguage(option);
    onChange && onChange(option);
  };

  const onRemoveOption =
    props.onRemoveOption || isMulti
      ? (option: SelectOption) => {
          if (!value) {
            return;
          }

          const newCodeSampleProgrammingLanguages = (
            value as SelectOption[]
          ).filter(({ value }) => value !== option.value);

          setCodeSampleProgrammingLanguage(newCodeSampleProgrammingLanguages);
          onChange && onChange(newCodeSampleProgrammingLanguages);
        }
      : undefined;

  return (
    <Select
      placeholder={'Select a language...'}
      onInputChange={(query) => setSearchTerm(query)}
      options={skills?.map(talentSkillToOption)}
      onChange={onCodeSampleProgrammingLanguageChange}
      value={value}
      useDebounce
      isClearable
      isDisabled={isDisabled || loadingDefault}
      isLoading={isLoading}
      onRemoveOption={onRemoveOption}
      isMulti={isMulti}
      {...rest}
      tagListClassName={styles.tagListClassName}
    />
  );
};
