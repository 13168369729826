import React from 'react';
import { Colors, Icon, IconType } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface Props {
  text: string;
  error?: boolean;
  type?: 'regular' | 'secondary';
  withIcon?: boolean;
  iconType?: IconType;
  className?: string;
}

const useStyles = createUseStyles({
  message: {
    fontSize: 12,
    lineHeight: '15px',
    marginTop: 6,
    marginBottom: 20,
    display: 'flex',
    gap: 8,
    alignItems: 'flex-start',
    color: Colors.secondaryDark,
    '& i': {
      flexShrink: 0,
    },
    '&.error': {
      color: Colors.primary,
      '&.secondary': {
        color: Colors.regularDark,
      },
    },
  },
});

export const GuidanceMessage = (props: Props) => {
  const {
    text,
    error,
    type = 'regular',
    withIcon,
    iconType,
    className,
  } = props;
  const styles = useStyles();
  const showIcon =
    withIcon !== undefined ? withIcon : type === 'regular' || !error;
  return (
    <div className={cx(className, styles.message, type, { error: !!error })}>
      {showIcon && (
        <Icon
          type={
            iconType || (error ? IconType.CloseOrange : IconType.CheckPurple)
          }
          title="Check"
          style={{ fontSize: 14 }}
          muted={error && type === 'secondary'}
        />
      )}
      <span>{text}</span>
    </div>
  );
};
