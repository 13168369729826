import { apiDiscovery, apiUser } from '@ateams/api';
import { DiscoveryResponse } from '@ateams/api/dist/endpoints/Discovery';
import { QueryResult } from '@a_team/models/dist/misc';
import { UserCardObject } from '@a_team/models/dist/UserObject';
import { Stores, useStores } from '@src/stores';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { queryKeys } from './keys';

const querySuggestedUsers = async (
  next: string | undefined,
  stores: Stores,
) => {
  const { auth, error: errorStore } = stores;

  try {
    const discoveryResponse = await apiDiscovery.querySuggestedUsers(
      auth,
      next,
    );

    return {
      suggestedUsers: discoveryResponse.items,
      next: discoveryResponse.next || undefined,
    };
  } catch (error) {
    errorStore.handleError(error as Error);

    throw error;
  }
};

const queryDiscovery = async (stores: Stores): Promise<DiscoveryResponse> =>
  apiDiscovery.getDiscovery(stores.auth);

const queryActiveConnections = async (
  stores: Stores,
  activeConnections: QueryResult<UserCardObject> | undefined,
  next: string | undefined,
) => {
  if (!next) {
    return {
      activeConnections: activeConnections?.items || [],
      next: activeConnections?.next,
    };
  }

  const activeConnectionUsers = await apiUser.activeConnectionUsers(
    stores.auth,
    next,
  );

  return {
    activeConnections: activeConnectionUsers.items,
    next: activeConnectionUsers.next,
  };
};

export const useDiscovery = () => {
  const stores = useStores();

  return useQuery(queryKeys.discovery._def, () => queryDiscovery(stores));
};

export const useSuggestedUsers = ({ enabled }: { enabled?: boolean }) => {
  const stores = useStores();

  return useInfiniteQuery(
    queryKeys.discovery.suggestedUsers,
    ({ pageParam = undefined }) => querySuggestedUsers(pageParam, stores),
    {
      getNextPageParam: (lastPage) => lastPage.next,
      enabled,
    },
  );
};

export const useActiveConnections = () => {
  const stores = useStores();
  const { data: discovery } = useDiscovery();

  const activeConnections = discovery?.activeConnectionUsers;

  return useInfiniteQuery(
    queryKeys.discovery.activeConnections,
    ({ pageParam = undefined }) =>
      queryActiveConnections(stores, activeConnections, pageParam),
    {
      getNextPageParam: (lastPage) => lastPage?.next,
      enabled: activeConnections?.items !== undefined,
    },
  );
};
