import React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { Colors } from '@ateams/components';
import { TalentCategoryId } from '@a_team/models/dist/TalentCategories';
import { useStores } from '@src/stores';
import useToggle from '@src/hooks/useToggle';
import { Card } from '@src/views/VettingFeedbackForm/components/card';
import {
  Size,
  Text,
} from '@src/views/VettingFeedbackForm/components/typography';
import { Button } from '@src/views/VettingFeedbackForm/components/button';
import {
  EvaluationInviteModal,
  OnEvaluationInviteSubmit,
} from '@src/components/Modal/EvaluationInviteModal';

export interface NoVettingProcessesCardProps {
  onEvaluationInvite: OnEvaluationInviteSubmit;
  mainTalentCategoryId?: TalentCategoryId;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    border: '1px solid #DADADC',
    alignItems: 'center',
  },
  marginBottom: {
    marginBottom: '24px',
  },
  button: {
    height: '32px',
    padding: '10px 16px',
    fontSize: Size.Small,
    background: Colors.primary,
  },
});

export const NoVettingProcessesCard: React.FC<NoVettingProcessesCardProps> = (
  props,
) => {
  const styles = useStyles();
  const { auth, vetters } = useStores();
  const [evaluationInviteModalOpen, toggleEvaluationInviteModalOpen] =
    useToggle();

  const onEvaluationInvite: OnEvaluationInviteSubmit = async (...args) => {
    props.onEvaluationInvite(...args);
    toggleEvaluationInviteModalOpen();
  };

  const onOpenScheduleVettingModal = async () => {
    if (auth.isVetter && !vetters.vetterConfiguration) {
      await vetters.getVettingConfiguration();
    }

    toggleEvaluationInviteModalOpen();
  };

  return (
    <Card className={cx(styles.container, props.className)}>
      {evaluationInviteModalOpen && (
        <EvaluationInviteModal
          open
          defaultEvaluationType={vetters.vetterConfiguration?.type}
          defaultCategory={props.mainTalentCategoryId}
          onClose={toggleEvaluationInviteModalOpen}
          onSubmit={onEvaluationInvite}
        />
      )}
      <IllustrationIcon className={styles.marginBottom} />
      <Text className={styles.marginBottom}>
        The evaluation process has not yet started
      </Text>
      <Button
        className={styles.button}
        rounded
        onClick={onOpenScheduleVettingModal}
      >
        Start Evaluation
      </Button>
    </Card>
  );
};

const IllustrationIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="132" height="80" viewBox="0 0 132 80" fill="none" {...props}>
      <rect x="92" y="10" width="40" height="60" rx="4" fill="#FCFAFF" />
      <rect y="10" width="40" height="60" rx="4" fill="#FCFAFF" />
      <rect x="26" width="80" height="80" rx="4" fill="#EDE0FF" />
      <path
        d="M54.6937 18.5965C56.0508 13.5262 60.6448 10 65.8936 10C71.1424 10 75.7364 13.5262 77.0935 18.5965L81.5967 35.4217C82.2309 37.7914 80.4452 40.1179 77.9921 40.1179H53.7951C51.342 40.1179 49.5563 37.7914 50.1905 35.4217L54.6937 18.5965Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.4959 41.8125L49.8481 44.5212C44.3188 46.0736 39.9634 50.3393 38.2964 55.8351L34 69.9997H62.6179H69.278H97.8959L93.5995 55.8351C91.9325 50.3393 87.5771 46.0736 82.0478 44.5212L72.4 41.8125H69.278H62.6179H59.4959Z"
        fill="#6D00D7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.808 36.1953L58.3511 47.8446H65.7397H66.26H73.6486L72.1917 36.1953H66.26H65.7397H59.808Z"
        fill="#87514E"
      />
      <path
        d="M56.4823 45.2909C55.9662 44.0873 56.6462 42.7072 57.915 42.383L65.8439 40.3574L73.6729 42.3945C74.9042 42.7149 75.5704 44.0476 75.0876 45.2248L74.1896 47.4143C72.7922 50.8216 69.4746 53.0469 65.7919 53.0469C62.1616 53.0469 58.8806 50.8838 57.4499 47.5474L56.4823 45.2909Z"
        fill="#87514E"
      />
      <path
        d="M93.7036 55.8351L98 69.9997H69.3821L72.5041 41.8125L77.135 43.1126L81.7659 44.4128L82.1519 44.5212C87.6812 46.0736 92.0366 50.3393 93.7036 55.8351Z"
        fill="#F09400"
      />
      <path
        d="M38.2964 55.8351L34 69.9997H62.6179L59.4959 41.8125L54.865 43.1126L50.2341 44.4128L49.8481 44.5212C44.3188 46.0736 39.9634 50.3393 38.2964 55.8351Z"
        fill="#F09400"
      />
      <path
        d="M66.2601 36.1953H72.1918L72.9203 41.916L66.5723 40.8238L59.8081 36.1953H65.7398H66.2601Z"
        fill="#6E423F"
      />
      <path
        d="M56.9333 19.8664C56.9333 15.0063 60.8732 11.0664 65.7333 11.0664C70.5935 11.0664 74.5333 15.0063 74.5333 19.8664V29.7892C74.5333 32.9126 73.1383 35.8727 70.7293 37.8608C67.8288 40.2546 63.6379 40.2546 60.7374 37.8608C58.3284 35.8727 56.9333 32.9126 56.9333 29.7892V19.8664Z"
        fill="#87514E"
      />
      <path
        d="M65.7332 31.3223C64.0489 31.3223 62.2946 32.3884 61.7332 32.9214C62.1542 33.8099 63.7121 35.5867 65.7332 35.5867C67.7542 35.5867 69.1718 33.8099 69.7332 32.9214C69.0314 32.3884 67.4174 31.3223 65.7332 31.3223Z"
        fill="black"
      />
      <path
        d="M61.2004 22.793C60.0776 22.793 58.908 23.4593 58.5337 23.7925C58.8144 24.3477 59.853 25 61.2004 25C62.5477 25 63.4928 24.3477 63.867 23.7925C63.3992 23.4593 62.3232 22.793 61.2004 22.793Z"
        fill="white"
      />
      <path
        d="M61.2 22.793C60.84 22.793 60.4752 22.8615 60.1333 22.9655L60.1315 22.9697C59.9538 23.379 59.7081 23.9452 60.1333 24.7678C60.4568 24.9097 60.8158 25 61.2 25C61.6965 25 62.1383 24.8492 62.5171 24.631C62.7175 23.9093 62.6006 23.4354 62.5171 23.0595C62.1116 22.9047 61.6597 22.793 61.2 22.793Z"
        fill="black"
      />
      <path
        d="M70.2664 22.793C71.3892 22.793 72.5588 23.4593 72.9331 23.7925C72.6524 24.3477 71.6138 25 70.2664 25C68.9191 25 67.974 24.3477 67.5998 23.7925C68.0676 23.4593 69.1436 22.793 70.2664 22.793Z"
        fill="white"
      />
      <path
        d="M70.2668 22.793C70.6268 22.793 70.9916 22.8615 71.3335 22.9655L71.3353 22.9697C71.513 23.379 71.7587 23.9452 71.3335 24.7678C71.01 24.9097 70.651 25 70.2668 25C69.7703 25 69.3285 24.8492 68.9497 24.631C68.7493 23.9093 68.8662 23.4354 68.9497 23.0595C69.3552 22.9047 69.8071 22.793 70.2668 22.793Z"
        fill="black"
      />
      <path
        d="M65.7333 29.7227C66.3878 29.7227 67.4788 29.7227 68.1333 28.6565L63.3333 28.6565C63.9878 29.7227 65.1878 29.7227 65.7333 29.7227Z"
        fill="#653C3A"
      />
      <path
        d="M63.3139 21.1693L63.0283 21.0969C61.9 20.8111 60.7153 20.8361 59.6001 21.1693V21.1693"
        stroke="black"
        strokeWidth="0.841169"
        strokeLinecap="round"
      />
      <path
        d="M68.6668 21.1693L68.9524 21.0969C70.0807 20.8111 71.2654 20.8361 72.3806 21.1693V21.1693"
        stroke="black"
        strokeWidth="0.841169"
        strokeLinecap="round"
      />
      <path
        d="M57.1999 19.5942C62.3545 19.5942 66.5332 15.4176 66.5332 10.2656L62.2665 11.0652L57.4665 13.997L55.5999 19.5942H57.1999Z"
        fill="white"
      />
      <path
        d="M74.8001 19.5942C69.6455 19.5942 65.4668 15.4176 65.4668 10.2656L69.7335 11.0652L74.2668 13.997L76.4001 19.5942H74.8001Z"
        fill="white"
      />
      <path
        d="M104.2 59.4596C103.947 58.6825 104.683 57.9469 105.46 58.1995L114.955 61.2854C115.795 61.5585 115.894 62.7072 115.113 63.1204L111.466 65.0501C111.289 65.144 111.144 65.289 111.05 65.4663L109.12 69.1133C108.707 69.8941 107.559 69.7948 107.285 68.9547L104.2 59.4596Z"
        fill="#26014D"
      />
      <rect
        x="54"
        y="24.5"
        width="3"
        height="5"
        rx="1.5"
        transform="rotate(-30 54 24.5)"
        fill="#87514E"
      />
      <path d="M56 27L54.5 28.5" stroke="black" />
      <rect
        width="3"
        height="5"
        rx="1.5"
        transform="matrix(-0.866025 -0.5 -0.5 0.866025 77.0981 24.5)"
        fill="#87514E"
      />
    </svg>
  );
};
