import cx from 'classnames';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import CommonStyles from '../common/styles';
import gradientSvg from './gradient.svg';
import { useFormContext } from 'react-hook-form';
import { MetricData } from '@a_team/models/dist/ExperienceObject';

interface MetricProps {
  index: number;
  title: string;
  titlePlaceholder: string;
  description: string;
  descriptionPlaceholder: string;
}

const useStyles = createUseStyles({
  metric: {
    flex: 1,
    padding: 24,
    minHeight: 160,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  },
  activeMetric: {
    backgroundColor: '#131313',
    backgroundImage: `url(${gradientSvg})`,
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    border: 'none',
    '& textarea': {
      color: '#FFFFFF',
    },
    '& div': {
      color: '#FFFFFF',
    },
  },
  metricTitle: {
    padding: 0,
    color: '#222222',
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 500,
    overflowY: 'hidden',
    height: 30,
    resize: 'none',
    '&:placeholder': {
      color: '#818388',
    },
  },
  metricTitleCharLimit: {
    top: 50,
    color: '#222222',
    fontSize: 12,
    fontWeight: 400,
    height: 21,
  },
  metricDescription: {
    padding: 0,
    marginTop: 23,
    color: '#222222',
    fontSize: 14,
    lineHeight: '19px',
    fontWeight: 400,
    overflowY: 'hidden',
    height: 19,
    resize: 'none',
    '&:placeholder': {
      color: '#818388',
    },
  },
  metricDescriptionCharLimit: {
    position: 'absolute',
    bottom: 5,
    color: '#222222',
    fontSize: 12,
    fontWeight: 400,
    height: 21,
  },
  error: {
    color: '#F63041 !important',
  },
});

export const TITLE_LIMIT = 30;
export const DESCRIPTION_LIMIT = 80;

const Metric = ({
  index,
  title,
  titlePlaceholder,
  description,
  descriptionPlaceholder,
}: MetricProps): JSX.Element => {
  const { setValue, watch } = useFormContext();

  const commonStyles = CommonStyles();
  const styles = useStyles({});
  const [isActive, setIsActive] = useState(!!title && !!description);
  const [focusedInput, setFocusedInput] = useState('');
  const metrics: MetricData[] = watch('metrics') || [];

  const textareaTitleRef = useRef<HTMLTextAreaElement>(null);
  const textareaDescriptionRef = useRef<HTMLTextAreaElement>(null);

  const adjustTextareaHeight = (
    textareaRef: RefObject<HTMLTextAreaElement>,
    minHeight: number,
  ) => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      const height =
        textareaRef.current.scrollHeight < minHeight
          ? minHeight
          : textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${height}px`;
    }
  };

  const onChangeTitle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    const newMetrics = metrics.map((metric, i) => {
      if (i === index) {
        return { ...metric, title: value };
      } else {
        return metric;
      }
    });
    setValue('metrics', newMetrics);
    adjustTextareaHeight(textareaTitleRef, 39);
  };

  const onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    const newMetrics = metrics.map((metric, i) => {
      if (i === index) {
        return { ...metric, description: value };
      } else {
        return metric;
      }
    });
    setValue('metrics', newMetrics);
    adjustTextareaHeight(textareaDescriptionRef, 19);
  };

  useEffect(() => {
    adjustTextareaHeight(textareaTitleRef, 30);
    adjustTextareaHeight(textareaDescriptionRef, 19);
  }, []);

  const isTitleInvalid = (title?.length ?? 0) > TITLE_LIMIT;
  const isDescriptionInvalid = (description?.length ?? 0) > DESCRIPTION_LIMIT;

  const onBlur = () => {
    const isActive =
      !!title && !!description && !isTitleInvalid && !isDescriptionInvalid;
    setIsActive(isActive);
    setFocusedInput('');
  };

  return (
    <div
      className={cx(
        commonStyles.dottedBox,
        styles.metric,
        isActive ? styles.activeMetric : null,
      )}
    >
      <textarea
        ref={textareaTitleRef}
        rows={1}
        className={cx(commonStyles.borderlessInput, styles.metricTitle)}
        value={title}
        placeholder={titlePlaceholder}
        onChange={onChangeTitle}
        onBlur={onBlur}
        onFocus={() => setFocusedInput('title')}
        onInput={() => adjustTextareaHeight(textareaTitleRef, 30)}
      />

      <div
        className={cx(
          styles.metricTitleCharLimit,
          isTitleInvalid ? styles.error : undefined,
        )}
      >
        {(focusedInput === 'title' || isTitleInvalid) && (
          <>
            {title?.length ?? 0}/{TITLE_LIMIT} characters
          </>
        )}
      </div>

      <textarea
        ref={textareaDescriptionRef}
        rows={1}
        className={cx(commonStyles.borderlessInput, styles.metricDescription)}
        value={description}
        placeholder={descriptionPlaceholder}
        onChange={onChangeDescription}
        onBlur={onBlur}
        onFocus={() => setFocusedInput('description')}
        onInput={() => adjustTextareaHeight(textareaDescriptionRef, 19)}
      />

      <div
        className={cx(
          styles.metricDescriptionCharLimit,
          isDescriptionInvalid ? styles.error : undefined,
        )}
      >
        {(focusedInput === 'description' || isDescriptionInvalid) && (
          <>
            {description?.length ?? 0}/{DESCRIPTION_LIMIT} characters
          </>
        )}
      </div>
    </div>
  );
};

export default Metric;
