import React, { useState, useRef } from 'react';
import { GenericTalent } from '@src/components/TeamGraphBuilderQuery';
import { createUseStyles } from 'react-jss';
import { Icon, IconType, Tag } from '@ateams/components';
import { TalentSkillRating } from '@a_team/models/dist/TalentCategories';
import Dropdown, { DropdownItem } from '../Dropdown';
import { truncate } from '@src/helpers/strings';
import useClickOutside from '@src/hooks/useClickOutside';

interface Props {
  talent: GenericTalent;
  tagClassName: string;
  hasRatings: boolean;
  starIconClassName: string;
  closeIconClassName: string;
  maxLabelLength: number;
  onRatingChange: (rating?: TalentSkillRating) => void;
  onClickOnRemove: () => void;
  onClickOnStar?: () => void;
}

const useStyles = createUseStyles({
  tagContent: { display: 'flex', cursor: 'pointer', marginRight: 5 },
  talentRating: {
    display: 'inline',
    paddingRight: 5,
  },
  ratingDropdown: {
    position: 'absolute',
    zIndex: 10,
  },
  ratingDropdownItem: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  ratingDropdownButton: {
    cursor: 'pointer',
    background: 'none',
    border: 0,
    padding: 0,
    margin: '0 8px',
  },
});

const availableRatings = [
  TalentSkillRating.Deep,
  TalentSkillRating.AboveAverage,
  TalentSkillRating.Average,
  TalentSkillRating.BelowAverage,
  TalentSkillRating.None,
];

export const TalentTag: React.FC<Props> = ({
  talent,
  tagClassName,
  hasRatings,
  starIconClassName,
  onRatingChange,
  closeIconClassName,
  maxLabelLength,
  onClickOnRemove,
  onClickOnStar,
}) => {
  const styles = useStyles();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  const onClickOnRatingItem = (rating?: TalentSkillRating) => {
    setIsDropdownOpen(false);
    onRatingChange(rating);
  };

  return (
    <Tag key={talent.id} className={tagClassName}>
      <div className={styles.tagContent}>
        {hasRatings ? (
          <>
            <button
              className={styles.ratingDropdownButton}
              onClick={() => setIsDropdownOpen((isOpen) => !isOpen)}
            >
              <Icon
                className={starIconClassName}
                type={talent.rating ? IconType.Star : IconType.EmptyStar}
                size={'exact'}
              />
              <div className={styles.talentRating}>{talent.rating}</div>
              <Icon size={'xsmall'} type={IconType.ArrowDownBlack} />
            </button>
            <div ref={dropdownRef}>
              <Dropdown className={styles.ratingDropdown} open={isDropdownOpen}>
                {availableRatings.map((rating: TalentSkillRating) => (
                  <DropdownItem
                    key={rating}
                    onClick={() => onClickOnRatingItem(rating)}
                    className={styles.ratingDropdownItem}
                  >
                    <Icon
                      className={starIconClassName}
                      type={IconType.Star}
                      size={'exact'}
                    />
                    {rating} star
                    {rating === TalentSkillRating.None ? '' : 's'}
                  </DropdownItem>
                ))}
                {talent.rating ? (
                  <DropdownItem
                    className={styles.ratingDropdownItem}
                    onClick={() => onClickOnRatingItem()}
                  >
                    <Icon
                      className={starIconClassName}
                      type={IconType.EmptyStar}
                      size={'exact'}
                    />{' '}
                    Preferred Skills
                  </DropdownItem>
                ) : null}
              </Dropdown>
            </div>
          </>
        ) : (
          <Icon
            onClick={() => onClickOnStar && onClickOnStar()}
            className={starIconClassName}
            type={talent.rating ? IconType.Star : IconType.EmptyStar}
            size={'xsmall'}
          />
        )}
        {truncate(talent.label, maxLabelLength)}{' '}
      </div>
      <Icon
        className={closeIconClassName}
        type={IconType.Close}
        size={'xsmall'}
        onClick={onClickOnRemove}
      />
    </Tag>
  );
};
