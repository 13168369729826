import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import {
  MissionAdminRole,
  MissionRoleId,
} from '@a_team/models/dist/MissionRole';
import { Applicants } from '@src/views/Mission/Proposal/RoleMembersSelector/Applicants';
import {
  AdminMissionApplicationObject,
  MissionApplicationId,
} from '@a_team/models/dist/MissionApplicationObject';
import {
  ProposalMemberPropertyToUpdate,
  ProposalTeamMember,
} from '@src/stores/Missions/Proposal';
import { Member } from '@src/views/Mission/Proposal/RoleMembersSelector/Member';
import {
  Icon,
  IconType,
  Card,
  Tag,
  TextColors,
  Spacing,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { countryListOptionMap } from '@src/helpers/rawCountryList';
import { format } from 'date-fns';
import { getGMTFromMinutesOffset } from '@ateams/react-utils/dist/helpers/timezone';
import { DateTime } from 'luxon';
import {
  formatOffset,
  getHumanReadableTime,
} from '@src/views/Profile/Sidebar/WorkingHours/utils';
import { UserId } from '@a_team/models/dist/UserObject';
import { ObservableMap } from 'mobx';
import { BuilderTfsPitchDescriptor } from '@ateams/api/dist/endpoints/Proposals';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { apiUsers } from '@ateams/api';
import { useMutation } from '@tanstack/react-query';
import { ProposalDataCurrency } from '@a_team/models/dist/ProposalObject';
import { getProposalDataCurrencySymbol } from '../utils';

interface Props {
  mid?: string;
  role?: MissionAdminRole;
  members?: ProposalTeamMember[];
  missionRolesMargin: number;
  applications: AdminMissionApplicationObject[] | null;
  onApplicationClick: (application: AdminMissionApplicationObject) => void;
  onMemberAdd: (
    aid: MissionApplicationId,
    rid: MissionRoleId,
    fromIndex?: number,
    toIndex?: number,
  ) => void;
  onMemberRemove: (aid: MissionApplicationId, rid: MissionRoleId) => void;
  onMemberDataEdit: (
    aid: MissionApplicationId,
    property: ProposalMemberPropertyToUpdate,
    data: string | number | boolean | string[],
  ) => void;
  onIncludeReplyToggle: (aid: MissionApplicationId, checked: boolean) => void;
  onRoleOrderChange: (rid: MissionRoleId, updateIndex: 'up' | 'down') => void;
  setDraggedApplication: (aid: MissionApplicationId | undefined) => void;
  onOpenBlurbHistory: (userId: UserId) => void;
  draggedApplication: MissionApplicationId | undefined;
  allowOrderIncrease?: boolean;
  allowOrderDecrease?: boolean;
  showTfsPitch?: boolean;
  tfsPitches: ObservableMap<UserId, Array<BuilderTfsPitchDescriptor>>;
  currency: ProposalDataCurrency;
}
const useStyles = createUseStyles({
  fieldTitle: {
    fontWeight: '500',
  },
  headline: {
    marginTop: '8px',
  },
  smallDivider: {
    borderRight: '1px solid #C0C0C0',
    height: '16px',
    marginLeft: '12px',
    marginRight: '12px',
    alignSelf: 'center',
  },
  memberContainer: {
    marginTop: Spacing.large,
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
});

const RoleMembersSelector = (props: Props): ReactElement | null => {
  const {
    role,
    members,
    applications,
    missionRolesMargin,
    onApplicationClick,
    onMemberAdd,
    onMemberRemove,
    onMemberDataEdit,
    onIncludeReplyToggle,
    onRoleOrderChange,
    draggedApplication,
    setDraggedApplication,
    allowOrderDecrease,
    allowOrderIncrease,
    showTfsPitch,
    onOpenBlurbHistory,
    tfsPitches,
    currency,
  } = props;

  const stores = useStores();
  const { auth, users } = stores;
  const [draggingOver, setDraggingOver] = useState(false);
  const [fromIndex, setFromIndex] = useState<number>();
  const [toIndex, setToIndex] = useState<number>();
  const applicants = applications?.filter(
    (application) => application.rid === role?.rid,
  );
  const [loading, setLoading] = useLoadingState();

  const onRemove = (aid: MissionApplicationId, rid: MissionRoleId) => {
    onMemberRemove(aid, rid);
  };

  const onDrag = (aid: MissionApplicationId, fromIndex?: number) => {
    setDraggedApplication(aid);
    fromIndex !== undefined && setFromIndex(fromIndex);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingOver(true);
  };

  const onMemberDragOver = (toIndex: number) => {
    setToIndex(toIndex);
  };

  const onDragOLeave = () => {
    setDraggingOver(false);
  };

  const onDrop = (rid: MissionRoleId) => {
    draggedApplication &&
      onMemberAdd(draggedApplication, rid, fromIndex, toIndex);
    setDraggedApplication(undefined);
    setFromIndex(undefined);
    setToIndex(undefined);
    setDraggingOver(false);
  };

  const copyApplicationDataClipboard = (
    applicationId: string,
    username: string,
  ) => {
    setLoading(
      users
        .getUserApplicationDataExport(applicationId, username)
        .then((result) => {
          navigator.clipboard.writeText(JSON.stringify(result));
          return 'Copied to clipboard';
        }),
    );
  };

  const generateBlurbMutation = useMutation({
    mutationFn: (member: ProposalTeamMember) =>
      apiUsers.generateUserBlurbForProposal(auth, member.username, member.aid),
  });

  const onClickOnGenerateBlurb = useCallback((member: ProposalTeamMember) => {
    setLoading(
      generateBlurbMutation
        .mutateAsync(member)
        .then(({ text, gptUsageLogId }) => {
          onMemberDataEdit(
            member.aid,
            ProposalMemberPropertyToUpdate.Blurb,
            text,
          );
          onMemberDataEdit(
            member.aid,
            ProposalMemberPropertyToUpdate.GptUsageLogId,
            gptUsageLogId,
          );
          return 'Blurb generated';
        })
        .catch((err) => {
          return Promise.reject('Error generating blurb');
        }),
    );
  }, []);

  const styles = useStyles();
  const [showAllCountries, setShowAllCountries] = useState(false);

  const requiredSkills = useMemo(() => {
    return role?.requiredSkills && role.requiredSkills.length
      ? role.requiredSkills
          .map(({ talentSkillName }) => talentSkillName)
          .filter((name) => name !== undefined)
          .join(', ')
      : '';
  }, [role?.requiredSkills]);

  const preferredSkills = useMemo(() => {
    return role?.preferredSkills && role.preferredSkills.length
      ? role.preferredSkills
          .map(({ talentSkillName }) => talentSkillName)
          .filter((name) => name !== undefined)
          .join(', ')
      : '';
  }, [role?.preferredSkills]);

  const roleLocations =
    role?.locations?.filter((code) => !!countryListOptionMap[code]) ?? [];

  if (!role) {
    return null;
  }

  return (
    <>
      <div>
        <LoadingIndicator loading={loading} />
        <div
          onDrop={() => onDrop(role.rid)}
          onDragOver={onDragOver}
          onDragLeave={onDragOLeave}
        >
          <Card
            style={{
              background: draggingOver ? '#FFFAF7' : '#fff',
              marginRight: 0,
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 16,
              }}
            >
              {allowOrderIncrease && (
                <Icon
                  type={IconType.ArrowDown}
                  flip={'vertical'}
                  muted
                  size={'exact'}
                  onClick={() => onRoleOrderChange(role?.rid, 'up')}
                />
              )}
              {allowOrderDecrease && (
                <Icon
                  type={IconType.ArrowDown}
                  muted
                  size={'exact'}
                  onClick={() => onRoleOrderChange(role?.rid, 'down')}
                />
              )}
            </div>
            <div style={{ flex: 1 }}>
              <div
                style={{
                  position: 'relative',
                  fontWeight: 500,
                  marginBottom: 4,
                }}
              >
                {role.category.title}
                {members && members.length > 0 && (
                  <Tag style={{ position: 'absolute', top: 0, right: 0 }} thin>
                    {members.length}
                  </Tag>
                )}
              </div>
              {role.headline}
              {role.utcOffsetRange && (
                <>
                  <div className={styles.smallDivider} />
                  From{' '}
                  {role.utcOffsetRange?.from &&
                    getGMTFromMinutesOffset(
                      role.utcOffsetRange?.from?.utcOffset,
                    )}{' '}
                  To{' '}
                  {role.utcOffsetRange?.to &&
                    getGMTFromMinutesOffset(role.utcOffsetRange?.to?.utcOffset)}
                </>
              )}
              {role?.builderRateMin && role?.builderRateMax && (
                <div className={styles.headline}>
                  <span className={styles.fieldTitle}>Hourly rate range: </span>
                  {getProposalDataCurrencySymbol(currency)}
                  {Math.round(role.builderRateMin)} -{' '}
                  {getProposalDataCurrencySymbol(currency)}
                  {Math.round(role.builderRateMax)}
                </div>
              )}
              {role?.builderMonthlyRateMin && role?.builderMonthlyRateMax && (
                <div className={styles.headline}>
                  <span className={styles.fieldTitle}>
                    Monthly rate range:{' '}
                  </span>
                  {getProposalDataCurrencySymbol(currency)}
                  {Math.round(role.builderMonthlyRateMin)} -{' '}
                  {getProposalDataCurrencySymbol(currency)}
                  {Math.round(role.builderMonthlyRateMax)}
                </div>
              )}
              {role.workingHours &&
                role.workingHours.daily &&
                !!role.workingHours?.name &&
                !!role.workingHours?.numberOfMinutesOverlap &&
                role.workingHours.daily.length > 0 && (
                  <div className={styles.headline}>
                    <span className={styles.fieldTitle}>Working Hours:</span>{' '}
                    {getHumanReadableTime(
                      role.workingHours.numberOfMinutesOverlap,
                    )}{' '}
                    between{' '}
                    {role.workingHours.daily.map((day, index) => {
                      return (
                        <span key={index}>
                          {DateTime.fromObject({
                            hour: Math.floor(day.startTime / 60),
                            minute: day.startTime % 60,
                          }).toFormat('h:mm a')}{' '}
                          and{' '}
                          {DateTime.fromObject({
                            hour: Math.floor(day.endTime / 60),
                            minute: day.endTime % 60,
                          }).toFormat('h:mm a')}{' '}
                        </span>
                      );
                    })}
                    in (UTC{formatOffset(role.workingHours?.name)},{' '}
                    {role.workingHours?.name})
                  </div>
                )}
              {role.availability && (
                <>
                  {role.availability.date && (
                    <div className={styles.headline}>
                      <span className={styles.fieldTitle}>
                        Expected start date:
                      </span>{' '}
                      {format(new Date(role.availability.date), 'MMMM d, yyyy')}
                    </div>
                  )}
                  {!!role.availability.weeklyHoursAvailable && (
                    <div className={styles.headline}>
                      <span className={styles.fieldTitle}>
                        Min. hours per week:
                      </span>{' '}
                      {role.availability.weeklyHoursAvailable}
                    </div>
                  )}
                </>
              )}
              {
                // show required location if not all countries are selected
                !!roleLocations.length && roleLocations.length < 245 && (
                  <div className={styles.headline}>
                    <span className={styles.fieldTitle}>
                      Required Location:
                    </span>{' '}
                    {roleLocations.length && (
                      <>
                        {showAllCountries && (
                          <>
                            {': '}
                            {roleLocations
                              .map((code) => countryListOptionMap[code].label)
                              .join(', ')}
                          </>
                        )}{' '}
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setShowAllCountries(!showAllCountries);
                          }}
                        >
                          <span
                            style={{
                              fontSize: 'small',
                              color: TextColors.primaryLight,
                            }}
                          >
                            {' '}
                            {!showAllCountries && (
                              <>
                                ... Show All ({roleLocations.length} Locations)
                              </>
                            )}
                            {showAllCountries && <>... Show Less</>}
                          </span>
                        </span>
                      </>
                    )}
                  </div>
                )
              }
              {requiredSkills && (
                <div className={styles.headline}>
                  <span className={styles.fieldTitle}>Required Skills:</span>{' '}
                  {requiredSkills}
                </div>
              )}{' '}
              {preferredSkills && (
                <div className={styles.headline}>
                  <span className={styles.fieldTitle}>Preferred Skills:</span>{' '}
                  {preferredSkills}
                </div>
              )}{' '}
              {members && members.length > 0 && (
                <div className={styles.memberContainer}>
                  {members.map((member, i) => {
                    const application = applications?.find(
                      (a) => a.aid === member.aid,
                    );

                    return (
                      <Member
                        monthlyRatesDisabled={!role.collectMonthlyRate}
                        onClickOnAvatar={() =>
                          application && onApplicationClick(application)
                        }
                        currency={currency}
                        index={i}
                        key={member.aid}
                        member={member}
                        application={application}
                        missionRolesMargin={missionRolesMargin}
                        onMemberRemove={onRemove}
                        onDataEdit={onMemberDataEdit}
                        onIncludeReplyToggle={onIncludeReplyToggle}
                        onDragStart={onDrag}
                        onDrop={onDrop}
                        draggable={members?.length > 1}
                        isBeingDragged={draggedApplication === member.aid}
                        onDragOver={onMemberDragOver}
                        isDropArea={!!draggedApplication && i === toIndex}
                        onOpenBlurbHistory={onOpenBlurbHistory}
                        blurbHistoryCount={
                          (member.userId &&
                            tfsPitches.get(member.userId)?.length) ||
                          0
                        }
                        showTfsPitch={showTfsPitch}
                        onClickOnCopyApplicationDataClipboard={() =>
                          copyApplicationDataClipboard(
                            member.aid,
                            member.username,
                          )
                        }
                        onClickOnGenerateBlurb={() =>
                          onClickOnGenerateBlurb(member)
                        }
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </Card>
        </div>
        {applicants && applicants.length > 0 && (
          <Applicants
            applications={applicants}
            onApplicationClick={(selectedApplication) =>
              onApplicationClick(selectedApplication)
            }
            onDrag={onDrag}
          />
        )}
      </div>
    </>
  );
};

export default observer(RoleMembersSelector);
