import React from 'react';
import { createUseStyles } from 'react-jss';
import mailIcon from './mailIcon.svg';

const useStyles = createUseStyles({
  container: {
    marginTop: 64,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    maxWidth: 462,
    textAlign: 'center',
    fontSize: 15,
    lineHeight: '24px',
  },
  imgContainer: {
    marginBottom: 16,
  },
  title: {
    marginTop: 16,
    marginBottom: 8,
    fontWeight: 500,
  },
});

const Reserve = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.imgContainer}>
          <img src={mailIcon} alt="Mail" width={64} height={64} />
        </div>
        <div className={styles.title}>
          You’ll be notified about relevant missions via email
        </div>
        <div>
          Keep your profile up to date and your mission notifications turned on
          and we’ll notify you if relevant missions become available. If you’re
          selected for a mission, you’ll gain full access to the A.Team network.
        </div>
      </div>
    </div>
  );
};

export default Reserve;
