import { useState } from 'react';

function useMultipleImages({ imageUrls }: { imageUrls: string[] }) {
  const [images, setImages] = useState(imageUrls);

  const [index, setIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(images[index]);

  const next = () => {
    if (index < images.length - 1) {
      setIndex(index + 1);
      setCurrentImage(images[index + 1]);
    } else {
      setIndex(0);
      setCurrentImage(images[0]);
    }
  };

  const prev = () => {
    if (index > 0) {
      setIndex(index - 1);
      setCurrentImage(images[index - 1]);
    } else {
      setIndex(images.length - 1);
      setCurrentImage(images[images.length - 1]);
    }
  };

  const onRemoveImage = () => {
    const newImages = images.filter((image) => image !== currentImage);
    setImages(newImages);
    setCurrentImage(newImages[0]);
  };

  const onAddImage = (newImageUrl: string) => {
    const newImages = [...images, newImageUrl];
    setImages(newImages);
    setCurrentImage(newImageUrl);
    setIndex(newImages.length - 1);
  };

  const onSetCoverImage = () => {
    // takes the image of the current index and moves it to the front of the array
    const newImages = images.filter((image) => image !== currentImage);
    newImages.unshift(currentImage);
    setImages(newImages);
    setIndex(0);

    // set the current image to the first image in the array
    setCurrentImage(newImages[0]);
  };

  const getIsCoverImage = () => {
    return images[0] === currentImage;
  };

  return {
    currentImage,
    next,
    prev,
    onRemoveImage,
    onAddImage,
    onSetCoverImage,
    setCurrentImage,
    images,
    setImages,
    getIsCoverImage,
  };
}

export default useMultipleImages;
