import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  text: string;
  textAlign?: 'left' | 'center' | 'right';
  className?: string;
}

const useStyles = createUseStyles({
  title: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '26px',
    textAlign: (props) => props.textAlign,
    margin: '16px 0 0 0',
  },
});

function Title({
  text,
  level = 'h4',
  textAlign = 'center',
  className,
}: TitleProps) {
  const styles = useStyles({ textAlign });

  const TitleHeading = ({
    ...props
  }: React.HTMLAttributes<HTMLHeadingElement>) =>
    React.createElement(level, props, text);

  return (
    <TitleHeading className={cx(styles.title, className)}>{text}</TitleHeading>
  );
}

export default Title;
