import React, { ReactElement } from 'react';
import { Icon, IconType } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { DateISOString } from '@a_team/models/dist/misc';
import { format } from 'date-fns';

interface Props {
  dateApplied: DateISOString;
}

const useStyles = createUseStyles({
  icon: {
    marginRight: '0.5em',
  },
  date: {
    marginTop: '0',
  },
});

export default function DateString(props: Props): ReactElement {
  const { dateApplied } = props;
  const styles = useStyles();

  return (
    <p className={styles.date}>
      <Icon type={IconType.Calendar} size={'exact'} className={styles.icon} />
      {format(new Date(dateApplied), 'MMMM dd, yyyy')}
    </p>
  );
}
