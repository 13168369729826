import React, { ReactElement, useState } from 'react';
import { DropdownMenu } from '@a_team/ui-components';
import {
  Colors,
  TextColors,
  Icon,
  IconType,
  Breakpoints,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';
import Stars from './stars.svg';

interface Props {
  label: string;
  children: ReactElement;
}

interface TriggerProps {
  label: string;
  onClick?: () => void;
  open?: boolean;
}

const useStyles = createUseStyles({
  '@global': {
    '.menu-open': {
      zIndex: '102 !important',
    },
  },
  trigger: {
    cursor: 'pointer',
    display: 'none',
    lineHeight: '12px',
    gap: 12,
    alignItems: 'center',
    padding: 12,
    border: '1px solid #DADADC',
    borderRadius: 8,
    background: Colors.backgroundWhite,
    color: Colors.secondaryDark,
    fontSize: 12,
    height: 40,
  },
  content: {
    width: 400,
    padding: 24,
    background: Colors.dark,
    color: TextColors.primary,
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 400,
    '& h3': {
      fontSize: 20,
      margin: 0,
      fontWeight: 600,
      marginBottom: 8,
    },
    '& h3 + p': {
      color: TextColors.regularDark,
    },
    '& h4': {
      fontSize: 16,
      fontWeight: 500,
      marginBottom: 4,
    },
    '& p': {
      margin: 0,
      marginBottom: 16,
    },
    '& ul': {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
    '& ul > li': {
      paddingLeft: 32,
      background: `url(${Stars}) 0 0 no-repeat`,
    },
    '& ol': {
      paddingLeft: 20,
    },
    '& ol > li': {
      marginBottom: 8,
    },
    '& a': {
      display: 'flex',
      flexdirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 12,
      background: '#2E2E2E',
      padding: 12,
      borderRadius: 16,
      color: TextColors.dark,
    },
    '& a > div': {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
    '& a > div > h4': {
      fontSize: 16,
      fontWeight: 500,
      margin: 0,
    },
    '& a > div > span': {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    trigger: {
      display: 'flex',
    },
  },
});

const GuidanceTooltipTrigger = (props: TriggerProps) => {
  const styles = useStyles();
  const { label, onClick } = props;
  return (
    <div onClick={onClick} className={styles.trigger}>
      <Icon clickable type={IconType.BookOpen} />
      {label}
    </div>
  );
};

export const GuidanceTooltip = (props: Props) => {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DropdownMenu
      style={{ width: 'auto', height: 'auto', zIndex: 100, flexShrink: 0 }}
      menuStyle={{ boxShadow: 'none' }}
      triggerComponent={
        <GuidanceTooltipTrigger
          label={props.label}
          open={isOpen}
          onClick={toggleIsOpen}
        />
      }
    >
      <div className={styles.content}>{props.children}</div>
    </DropdownMenu>
  );
};
