import fetch from 'isomorphic-fetch';
import { createApi } from 'unsplash-js';
import { Random } from 'unsplash-js/dist/methods/photos/types';
import { UNSPLASH_ACCESS_KEY } from '../config';

declare let global: NodeJS.Global & { fetch: typeof fetch };
if (typeof window === 'undefined') {
  global.fetch = fetch;
} else {
  window.fetch = fetch;
}

const unsplash = createApi({ accessKey: UNSPLASH_ACCESS_KEY });

export default unsplash;

export interface UnsplashImage {
  imageURLs: {
    desktop: string;
    mobile: string;
  };
  credit: {
    label: string;
    URL: string;
  };
}

export interface UnsplashResult {
  urls: {
    regular: string;
  };
}

export interface UnsplashSearchResults {
  results: UnsplashResult[];
}

// exported functions

export async function fetchRandomCoverImage(): Promise<UnsplashImage> {
  // https://unsplash.com/documentation#get-a-random-photo
  const blob = await unsplash.photos.getRandom({
    orientation: 'landscape',
    featured: true,
    // content_filter: 'high',
  });
  const res = blob.response as Random;

  const regular = res.urls.regular;
  const small = res.urls.small;

  // sanity check
  if (typeof regular !== 'string' || typeof small !== 'string') {
    throw new Error(`Invalid unsplash response`);
  }

  return {
    imageURLs: {
      desktop: regular.replace(/&w=\d+&/, '/&w=1200&/'),
      mobile: small.replace(/&w=\d+&/, '/&w=600&/'),
    },
    credit: {
      label: `@${res.user.username} at Unsplash`,
      URL: res.user.links.html,
    },
  };
}

export const searchImages = async (
  query: string,
): Promise<UnsplashSearchResults | null> => {
  const searchResults = await unsplash.search.getPhotos({
    query,
    page: 1,
    perPage: 12,
    orientation: 'landscape',
  });

  return searchResults.response || null;
};
