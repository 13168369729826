import React, {
  CSSProperties,
  ReactElement,
  ReactNode,
  MouseEvent,
  FocusEvent,
} from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import loadingIcon from './loading.png';
import alert from './alert.svg';
import check from './check.svg';
import caretIcon from './caret.svg';
import { Colors, Margins, TextColors, Widths } from '@ateams/components';
import TooltipWrapped from '@src/components/TooltipWrapped';

export interface BasicOutlinedControlProps {
  name?: string;
  margin?: keyof typeof Margins;
  width?: keyof typeof Widths;
  label?: string;
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  noBorder?: boolean;
}

export interface OutlinedControlProps extends BasicOutlinedControlProps {
  precursor?: ReactNode;
  errorTooltip?: string;
  highlightPrecursor?: boolean;
  icon?: string | ReactNode;
  loading?: boolean;
  required?: boolean;
  valid?: boolean | null;
  input: ReactNode;
  endAdornment?: ReactNode;
  errorMessage?: string;
  dropdown?: ReactNode;
  dropdownPlacement?: 'bottom' | 'top';
  maxLength?: number;
  minLength?: number;
  length?: number | null;
  hideLength?: boolean;
  caret?: boolean;
  borderRadius?: 'right' | 'left' | 'both';
  tabIndex?: number;
  controlClassName?: string;
  onClick?(e: MouseEvent<HTMLDivElement>): void;
  onFocus?(e: FocusEvent<HTMLDivElement>): void;
  onDoubleClick?(e: MouseEvent<HTMLDivElement>): void;
}

const MARGIN_WITH_BORDER = 68;
const MARGIN_WITHOUT_BORDER = 40;

const useStyles = createUseStyles({
  root: {
    margin: (props: OutlinedControlProps): string | number =>
      Margins[props.margin || 'default'],
    width: (props: OutlinedControlProps) =>
      props.noBorder ? 'auto' : Widths[props.width || 'default'],
  },
  control: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: (props: OutlinedControlProps): string =>
      props.noBorder ? '0' : '1em',
    minHeight: (props: OutlinedControlProps): string =>
      props.noBorder ? '0' : '56px',
    border: (props: OutlinedControlProps): string =>
      props.noBorder ? 'none' : '1px solid #c0c0c0',
    boxSizing: 'border-box',
    borderTopRightRadius: (props: OutlinedControlProps): string =>
      props.borderRadius
        ? props.borderRadius === 'right' || props.borderRadius === 'both'
          ? '0.5em'
          : '0'
        : '0.5em',
    borderBottomRightRadius: (props: OutlinedControlProps): string =>
      props.borderRadius
        ? props.borderRadius === 'right' || props.borderRadius === 'both'
          ? '0.5em'
          : '0'
        : '0.5em',
    borderTopLeftRadius: (props: OutlinedControlProps): string =>
      props.borderRadius
        ? props.borderRadius === 'left' || props.borderRadius === 'both'
          ? '0.5em'
          : '0'
        : '0.5em',
    borderBottomLeftRadius: (props: OutlinedControlProps): string =>
      props.borderRadius
        ? props.borderRadius === 'left' || props.borderRadius === 'both'
          ? '0.5em'
          : '0'
        : '0.5em',
    fontFamily: '"Inter"',
    fontWeight: 300,
    fontSize: '1em',
    lineHeight: '1.4em',
    transition: 'all 0.5s',
    boxShadow: 'inner 0px 1px 6px rgba(0, 0, 0, 0.08)',
    margin: 0,
    backgroundColor: (props: OutlinedControlProps): string =>
      props.noBorder ? 'none' : '#fff',
    userSelect: 'none',
    cursor: (props: OutlinedControlProps) =>
      props.dropdown ? 'pointer' : undefined,

    '&:focus,&:focus-within': {
      borderColor: '#62646a',
      outline: 'none',
    },

    '&.error': {
      backgroundColor: '#fff8f8',
      borderColor: Colors.danger,
      color: Colors.danger,
    },

    '&.success': {
      backgroundColor: '#fff8f8',
      borderColor: Colors.success,
      color: Colors.success,
    },

    '&.disabled': {
      borderColor: Colors.backgroundDark,
      boxShadow: 'none',
      color: TextColors.regularLight,
      '-webkit-text-fill-color': TextColors.regularLight,
      opacity: 1 /* required on iOS */,
    },
  },
  errorMessage: {
    color: '#F63041',
    display: 'inline-block',
    marginTop: '6px',
  },
  input: {
    flexGrow: 1,
    userSelect: 'auto',
    maxWidth: '100%',
  },
  label: {
    color: '#62646A',
    fontSize: 14,
    lineHeight: '19px',
    marginBottom: 8,
    display: 'block',
  },
  required: {
    paddingLeft: 2,
    color: Colors.danger,
  },
  precursor: {
    color: (props: OutlinedControlProps): string =>
      props.highlightPrecursor ? '#fe630c' : '#222',
    whiteSpace: 'nowrap',
    margin: 0,
    padding: '0 1px 0 0',
  },
  icon: {
    marginRight: 13,
  },
  '@keyframes rotating': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  rotating: {
    animation: '$rotating 2s linear infinite',
  },
  limits: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#62646a',
    fontSize: 14,
    margin: '8px 0px',
  },
  minLength: {
    flexGrow: 1,
  },
  maxLength: {
    flexGrow: 1,
    textAlign: 'right',
  },
  dropdown: {
    position: 'absolute',
    minWidth: '100%',
    left: 0,
    top: (props: OutlinedControlProps) =>
      props.dropdownPlacement !== 'top'
        ? props.noBorder
          ? MARGIN_WITHOUT_BORDER
          : MARGIN_WITH_BORDER
        : undefined,
    bottom: (props: OutlinedControlProps) =>
      props.dropdownPlacement === 'top' ? 56 + 12 : undefined,
    zIndex: 100,
  },
  caret: {
    margin: '0 -2px 0 8px',
  },
});

export default function OutlinedControl(
  props: OutlinedControlProps,
): ReactElement {
  const {
    className,
    precursor,
    input,
    error,
    errorTooltip,
    errorMessage,
    disabled,
    success,
    loading,
    icon,
    endAdornment,
    dropdown,
    valid,
    style,
    caret,
    maxLength,
    minLength,
    length,
    tabIndex,
    controlClassName,
    onClick,
    onFocus,
    onDoubleClick,
    hideLength,
    label,
    required,
    name,
  } = props;
  const styles = useStyles(props);

  return (
    <div
      className={cx(styles.root, className)}
      style={style}
      data-testing-id={name}
    >
      {label && (
        <label className={cx(styles.label, 'input-label')}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </label>
      )}
      <div
        className={cx(
          styles.control,
          { error, disabled, success },
          controlClassName,
        )}
        tabIndex={tabIndex}
        onClick={onClick}
        onFocus={onFocus}
        onDoubleClick={onDoubleClick}
      >
        {icon &&
          (typeof icon === 'string' ? (
            <img src={icon} alt="icon" className={styles.icon} />
          ) : (
            icon
          ))}

        {precursor ? <div className={styles.precursor}>{precursor}</div> : null}

        <div className={styles.input}>{input}</div>

        {endAdornment ||
          (loading ? (
            <img src={loadingIcon} className={styles.rotating} alt="loading" />
          ) : valid != null ? (
            valid ? (
              <img src={check} alt="valid" />
            ) : (
              <TooltipWrapped
                html={
                  <div style={{ maxWidth: 150, fontSize: '0.8em' }}>
                    {errorTooltip}
                  </div>
                }
                disabled={!errorTooltip}
                position="top"
                arrow
                animation="fade"
                style={{ height: 20 }}
              >
                <img src={alert} alt="error" style={{ width: 20 }} />
              </TooltipWrapped>
            )
          ) : null)}

        {caret && <img src={caretIcon} alt="caret" className={styles.caret} />}
        {dropdown ? <div className={styles.dropdown}>{dropdown}</div> : null}
      </div>

      {errorMessage && (
        <span className={styles.errorMessage}>{errorMessage}</span>
      )}

      {(length || minLength) && !hideLength ? (
        <div className={styles.limits}>
          {minLength ? (
            <span className={styles.minLength}>
              Min. {minLength} characters
            </span>
          ) : null}
          {length != null ? (
            <span className={styles.maxLength}>
              {maxLength ? `${length} / ${maxLength}` : `${length} characters`}
            </span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
