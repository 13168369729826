import {
  NotificationType,
  ReSubscriptionReminderNotificationObject,
} from '@a_team/models/dist/NotificationObject';
import React, { ReactElement } from 'react';
import Notification from './Notification';

interface ReSubscriptionReminderMissionNotificationsProps {
  notification: ReSubscriptionReminderNotificationObject;
  onResubscribe(): Promise<void>;
  isRead: boolean;
  dataTestingId?: string;
}

export default function ReSubscriptionReminderMissionNotifications({
  onResubscribe,
  isRead,
  notification,
  dataTestingId,
}: ReSubscriptionReminderMissionNotificationsProps): ReactElement {
  const handleResubscribe = async () => {
    await onResubscribe();
  };

  return (
    <Notification
      dataTestingId={dataTestingId}
      createdAt={notification.createdAt}
      notificationType={NotificationType.AutomaticallyUnsubscribedEmail}
      ctaName="Re-subscribe"
      ctaAction={handleResubscribe}
      title={`Would you like to re-subscribe to mission notifications?`}
      description={
        'Mission notifications ensure you’re one of the first to know about new relevant mission opportunities, increasing your chance of success. '
      }
      isRead={isRead}
    />
  );
}
