import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { Spacing } from '@ateams/components';
import {
  SubmitVettingFeedbackFormPayload,
  VettingFeedbackFormAnswersDraft,
  VettingFeedbackFormDefaults,
} from '@ateams/api/dist/endpoints/vetting-process-feedback';
import { UserId } from '@a_team/models/dist/UserObject';
import {
  TalentIndustry,
  TalentIndustryId,
} from '@a_team/models/dist/TalentIndustry';
import { formatLinkedinUrl } from '@src/views/VettingDashboard/vetting-table/columns/columns/builder-info';
import {
  OnVettingFeedbackFormGeneralDataChange,
  VettingFeedbackFormGeneralData,
  VettingFeedbackFormGeneralDataForm,
} from './general-data';
import {
  OnVettingFeedbackFormMainInfoChange,
  VettingFeedbackFormMainInfo,
  VettingFeedbackFormMainInfoForm,
} from './main-info';
import {
  OnVettingFeedbackFormBuilderInfoChange,
  VettingFeedbackFormBuilderInfo,
  VettingFeedbackFormBuilderInfoForm,
} from './builder-info';
import {
  OnVettingFeedbackFormRolesChange,
  VettingFeedbackFormRoles,
  VettingFeedbackFormRolesForm,
} from './roles';
import {
  OnVettingFeedbackFormExperienceChange,
  VettingFeedbackFormExperience,
  VettingFeedbackFormExperienceForm,
} from './experience';
import {
  OnVettingFeedbackFormProjectsChange,
  VettingFeedbackFormProjects,
  VettingFeedbackFormProjectsForm,
  getEmptyProject,
} from './projects';
import { VettingFeedbackFormHeader } from './header';
import {
  OnVettingFeedbackFormTechStackChange,
  VettingFeedbackFormTechStack,
  VettingFeedbackFormTechStackForm,
} from './tech-stack';
import {
  OnVettingFeedbackFormIndustriesChange,
  VettingFeedbackFormIndustries,
  VettingFeedbackFormIndustriesForm,
} from './industries';
import {
  OnVettingFeedbackFormGeneralCommentsChange,
  VettingFeedbackFormGeneralComments,
  VettingFeedbackFormGeneralCommentsForm,
} from './general-comments';
import {
  OnVettingFeedbackFormSummaryCommentsChange,
  VettingFeedbackFormSummaryComments,
  VettingFeedbackFormSummaryCommentsForm,
} from './summary-comments';
import { VettingFeedbackFormTimestamp } from './timestamp';
import { VettingFeedbackFormFooter } from './footer';
import {
  OnVettingFeedbackFormBackgroundCommentsChange,
  VettingFeedbackFormBackgroundCommentsForm,
} from './background-comments';
import { useVettingFeedbackFormErrors } from './form-errors';
import { useVettingFeedbackFormSerializePayload } from './serialize-payload';
import NavigationPrompt, {
  RenderCustomConfirmModal,
} from '@src/components/NavigationPrompt';
import { UnsavedChangesModal } from '../modals/unsaved-changes-modal';
import { useVettingFormVariant } from './form-variants';
import {
  OnDeleteAdditionalMaterial,
  OnUploadAdditionalMaterial,
} from './builder-info/additional-materials-files';

export type OnVettingFeedbackFormSubmit = (
  payload: SubmitVettingFeedbackFormPayload,
) => unknown;

export type OnVettingFeedbackFormSave = (
  payload: VettingFeedbackFormAnswersDraft,
  skipLoading?: boolean,
) => unknown;

export interface VettingFeedbackFormProps {
  defaultValues: VettingFeedbackFormDefaults;
  vettingProcessId: string;
  currentUserProfilePictureURL: string;
  isCurrentUserAdmin: boolean;
  currentUserId: UserId;
  onClose: () => void;
  onSubmit: OnVettingFeedbackFormSubmit;
  onBuilderDidNotShowUpToCallSubmit: () => void;
  onSave: OnVettingFeedbackFormSave;
  onUploadAdditionalMaterial: OnUploadAdditionalMaterial;
  onDeleteAdditionalMaterial: OnDeleteAdditionalMaterial;
}

export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '900px',
  },
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  formHeader: {
    marginBottom: Spacing.xLarge,
  },
  formFooter: {
    marginBottom: Spacing.medium,
  },
});

export const VettingFeedbackForm: React.FC<VettingFeedbackFormProps> = (
  props,
) => {
  const history = useHistory();
  const styles = useStyles();
  const [isDirty, setIsDirty] = useState(false);
  const [
    additionalMaterialsOperationsCount,
    setAdditionalMaterialsOperationsCount,
  ] = useState(0);
  const [generalData, setGeneralData] =
    useState<VettingFeedbackFormGeneralData>(() => ({
      contactOwner: props.defaultValues.general.vettingProcess.contactOwner,
      vetter: props.defaultValues.general.vettingProcess.vetter,
      vettingStartedDate:
        props.defaultValues.general.vettingProcess.vettingStartedDate,
      vettingType: props.defaultValues.general.vettingProcess.vettingType,
      category: props.defaultValues.general.vettingProcess.category,
      feedbackFormSentDate:
        props.defaultValues.general.vettingProcess.feedbackFormSentDate,
      notifiedInterviewer:
        props.defaultValues.general.vettingProcess.notifiedInterviewerDate,
      submittedFormDate:
        props.defaultValues.general.vettingProcess.submittedFormDate,
    }));
  const [mainInfo, setMainInfo] = useState<VettingFeedbackFormMainInfo>({
    interviewDate: props.defaultValues.general.vettingProcess.interviewDate,
    builderDidNotShowUp: false,
    scores: props.defaultValues.answers.scores,
  });
  const [builderInfo, setBuilderInfo] =
    useState<VettingFeedbackFormBuilderInfo>({
      location: props.defaultValues.answers.location,
      availability: props.defaultValues.answers.availability,
      additionalMaterialsFiles:
        props.defaultValues.answers.additionalMaterialsFiles,
      additionalMaterials: props.defaultValues.answers.additionalMaterials,
      worksElseWhere: props.defaultValues.answers.worksElseWhere,
      position: props.defaultValues.general.user.roleCategory || undefined,
      techStack: props.defaultValues.general.user.profileTalentSkills,
      codeSample: props.defaultValues.general.vettingProcess.codeSample,
      additionalMaterialLinks:
        props.defaultValues.general.vettingProcess.additionalMaterialLinks,
      additionalMaterialFileUrls:
        props.defaultValues.general.vettingProcess.additionalMaterialFiles,
      additionalMaterialDetails:
        props.defaultValues.general.vettingProcess.additionalMaterialDetails,
      linkedIn: formatLinkedinUrl(props.defaultValues.general.user.linkedIn),
      website: props.defaultValues.general.user.website,
      email: props.defaultValues.general.user.email,
      calendarUrl: props.defaultValues.general.vettingProcess.calendarUrl,
      recordingDownloadLinks:
        props.defaultValues.general.vettingProcess.recordingDownloadLinks,
      transcriptJobIds:
        props.defaultValues.general.vettingProcess.transcriptJobIds,
    });
  const [roles, setRoles] = useState<VettingFeedbackFormRoles>({
    roles: props.defaultValues.answers.roles || [],
    userRole: props.defaultValues.answers.userRole,
  });
  const [experience, setExperience] = useState<VettingFeedbackFormExperience>(
    props.defaultValues.answers.experience,
  );
  const [projects, setProjects] = useState<VettingFeedbackFormProjects>({
    projects:
      !props.defaultValues.answers.projects.length &&
      !props.defaultValues.general.vettingProcess.feedbackFormSentDate
        ? [getEmptyProject(), getEmptyProject()]
        : props.defaultValues.answers.projects,
    biggestImpactDescription:
      props.defaultValues.answers.biggestImpactDescription,
  });
  const [techStack, setTechStack] = useState<VettingFeedbackFormTechStack>({
    skills: props.defaultValues.answers.skills,
    skillsComment: props.defaultValues.answers.skillsComment,
  });
  const [industries, setIndustries] = useState<VettingFeedbackFormIndustries>({
    talentIndustries: props.defaultValues.answers.industries.talentIndustries,
    comment: props.defaultValues.answers.industries.comment,
  });
  const [backgroundComments, setBackgroundComments] =
    useState<VettingFeedbackFormGeneralComments>({
      comments: props.defaultValues.answers.backgroundComments,
    });
  const [generalComments, setGeneralComments] =
    useState<VettingFeedbackFormGeneralComments>({
      comments: props.defaultValues.answers.generalComments,
    });
  const [summaryComments, setSummaryComments] =
    useState<VettingFeedbackFormSummaryComments>({
      comments: props.defaultValues.answers.summaryComments,
    });
  const vettingFormVariant = useVettingFormVariant(generalData.category);
  const [hasUserTriedToSubmit, setHasUserTriedToSubmit] = useState(false);
  const { formErrors, formErrorsNotes, hasFormSubmissionTimeout } =
    useVettingFeedbackFormErrors(
      {
        generalData,
        mainInfo,
        builderInfo,
        roles,
        experience,
        projects,
        techStack,
        industries,
        backgroundComments,
        generalComments,
        summaryComments,
      },
      hasUserTriedToSubmit,
      props.defaultValues.general.vettingProcess.feedbackFormSentDate,
      props.isCurrentUserAdmin,
      additionalMaterialsOperationsCount,
    );
  const { prepareDraftPayload, prepareSubmitPayload } =
    useVettingFeedbackFormSerializePayload(
      props.defaultValues,
      {
        generalData,
        mainInfo,
        builderInfo,
        roles,
        experience,
        projects,
        techStack,
        industries,
        backgroundComments,
        generalComments,
        summaryComments,
      },
      props.isCurrentUserAdmin,
    );

  const onSave = async (skipLoading?: boolean) => {
    try {
      const payload = prepareDraftPayload();

      await props.onSave(payload, skipLoading);
      setIsDirty(false);
    } catch (_) {
      // pass
    }
  };

  const onSubmit = async () => {
    try {
      if (mainInfo.builderDidNotShowUp) {
        props.onBuilderDidNotShowUpToCallSubmit();
      }

      setHasUserTriedToSubmit(true);

      if (formErrorsNotes.length) {
        if (hasUserTriedToSubmit) {
          console.error(
            "We have a bug, we shouldn't had reached this code block! 😱",
          );
        }
        return;
      }

      const payload = prepareSubmitPayload();

      await props.onSubmit(payload);
      setIsDirty(false);
    } catch (_) {
      // pass
    }
  };

  const renderNavigationPromptCustomModal: RenderCustomConfirmModal =
    useCallback(
      (open, closeModal, handleConfirmNavigation) => {
        const onSaveModal = async () => {
          await onSave();
          handleConfirmNavigation();
        };

        return (
          <UnsavedChangesModal
            open={open}
            onClose={closeModal}
            onSave={onSaveModal}
            onDiscard={handleConfirmNavigation}
          />
        );
      },
      [onSave],
    );

  const onGeneralDataChange: OnVettingFeedbackFormGeneralDataChange =
    useCallback(
      (data) => {
        setIsDirty(true);
        setGeneralData(data);
      },
      [setIsDirty, setGeneralData],
    );

  const onMainInfoChange: OnVettingFeedbackFormMainInfoChange = useCallback(
    (data) => {
      setIsDirty(true);
      setMainInfo(data);
    },
    [setIsDirty, setMainInfo],
  );

  const onBuilderInfoChange: OnVettingFeedbackFormBuilderInfoChange =
    useCallback(
      (data) => {
        setIsDirty(true);
        setBuilderInfo(data);
      },
      [setIsDirty, setBuilderInfo],
    );

  const onExperienceChange: OnVettingFeedbackFormExperienceChange = useCallback(
    (data) => {
      setIsDirty(true);
      setExperience(data);
    },
    [setIsDirty, setExperience],
  );

  const onProjectsChange: OnVettingFeedbackFormProjectsChange = useCallback(
    (data) => {
      setIsDirty(true);
      setProjects((projects) => {
        /**
         * When we add a new industry to one of the projects we want it to be also added to the industries list
         */
        if (data.projects) {
          // To avoid duplicates we first want to get all the current industries
          const currentIndustries = new Set<TalentIndustryId>();
          for (const project of projects.projects) {
            for (const industry of project.industries) {
              currentIndustries.add(industry.id);
            }
          }

          // Find potentially new industries that don't already exist on the form industries
          const newIndustries: TalentIndustry[] = [];
          for (const project of data.projects) {
            for (const industry of project.industries) {
              if (!currentIndustries.has(industry.id)) {
                newIndustries.push(industry);
              }
            }
          }

          // Add the new industries to the industries list
          if (newIndustries.length) {
            setIndustries((industries) => ({
              ...industries,
              talentIndustries: [
                ...industries.talentIndustries,
                ...newIndustries,
              ],
            }));
          }
        }

        return { ...projects, ...data };
      });
    },
    [setIsDirty, setProjects, setIndustries],
  );

  const onTechStackChange: OnVettingFeedbackFormTechStackChange = useCallback(
    (data) => {
      setIsDirty(true);
      setTechStack((techStack) => ({ ...techStack, ...data }));
    },
    [setIsDirty, setTechStack],
  );

  const onIndustriesChange: OnVettingFeedbackFormIndustriesChange = useCallback(
    (data) => {
      setIsDirty(true);
      setIndustries((industries) => ({ ...industries, ...data }));
    },
    [setIsDirty, setIndustries],
  );

  const onRolesChange: OnVettingFeedbackFormRolesChange = useCallback(
    (data) => {
      setIsDirty(true);
      setRoles((roles) => ({ ...roles, ...data }));
    },
    [setIsDirty, setRoles],
  );

  const onBackgroundCommentsChange: OnVettingFeedbackFormBackgroundCommentsChange =
    useCallback(
      (data) => {
        setIsDirty(true);
        setBackgroundComments(data);
      },
      [setIsDirty, setBackgroundComments],
    );

  const onGeneralCommentsChange: OnVettingFeedbackFormGeneralCommentsChange =
    useCallback(
      (data) => {
        setIsDirty(true);
        setGeneralComments(data);
      },
      [setIsDirty, setGeneralComments],
    );

  const onSummaryCommentsChange: OnVettingFeedbackFormSummaryCommentsChange =
    useCallback(
      (data) => {
        setIsDirty(true);
        setSummaryComments(data);
      },
      [setIsDirty, setSummaryComments],
    );

  const onUploadAdditionalMaterial: OnUploadAdditionalMaterial = async (
    file,
  ) => {
    setAdditionalMaterialsOperationsCount((count) => count + 1);

    try {
      return await props.onUploadAdditionalMaterial(file);
    } finally {
      setAdditionalMaterialsOperationsCount((count) => count - 1);
    }
  };

  const onDeleteAdditionalMaterial: OnDeleteAdditionalMaterial = async (
    additionalMaterialId,
  ) => {
    setAdditionalMaterialsOperationsCount((count) => count + 1);

    try {
      return await props.onDeleteAdditionalMaterial(additionalMaterialId);
    } finally {
      setAdditionalMaterialsOperationsCount((count) => count - 1);
    }
  };

  return (
    <div className={styles.container}>
      <VettingFeedbackFormHeader
        profileURL={props.defaultValues.general.user.profileURL}
        avatarSrc={props.defaultValues.general.user.profilePictureURL}
        fullName={props.defaultValues.general.user.fullName}
        vettingProcessId={props.vettingProcessId}
        onClose={props.onClose}
        className={styles.formHeader}
      />

      <VettingFeedbackFormGeneralDataForm
        errors={formErrors['generalData']}
        defaultValues={generalData}
        onChange={onGeneralDataChange}
        isAdmin={props.isCurrentUserAdmin}
        className={styles.marginBottom}
      />

      <VettingFeedbackFormMainInfoForm
        errors={formErrors['mainInfo']}
        defaultValues={mainInfo}
        onChange={onMainInfoChange}
        className={styles.marginBottom}
      />

      <VettingFeedbackFormBuilderInfoForm
        defaultValues={builderInfo}
        onChange={onBuilderInfoChange}
        onUploadAdditionalMaterial={onUploadAdditionalMaterial}
        onDeleteAdditionalMaterial={onDeleteAdditionalMaterial}
        className={styles.marginBottom}
      />

      <VettingFeedbackFormRolesForm
        errors={formErrors['roles']}
        defaultValues={roles}
        onChange={onRolesChange}
        vettingFormVariant={vettingFormVariant}
        wasFeedbackFormSubmitted={Boolean(
          props.defaultValues.general.vettingProcess.feedbackFormSentDate,
        )}
        userRoleCategory={props.defaultValues.general.user.roleCategory}
        className={styles.marginBottom}
      />

      <VettingFeedbackFormExperienceForm
        defaultValues={experience}
        onChange={onExperienceChange}
        vettingFormVariant={vettingFormVariant}
        className={styles.marginBottom}
      />

      <VettingFeedbackFormProjectsForm
        defaultValues={projects}
        onChange={onProjectsChange}
        className={styles.marginBottom}
      />

      <VettingFeedbackFormTechStackForm
        defaultValues={techStack}
        onChange={onTechStackChange}
        className={styles.marginBottom}
      />

      <VettingFeedbackFormIndustriesForm
        values={industries}
        onChange={onIndustriesChange}
        className={styles.marginBottom}
      />

      {
        /**
         * Background comments is @deprecated, but if a form was submitted with it we still want to show it
         * {@link https://buildateam.atlassian.net/browse/NEXUS-828}
         */
        Boolean(backgroundComments.comments.length) && (
          <VettingFeedbackFormBackgroundCommentsForm
            avatarSrc={props.currentUserProfilePictureURL}
            currentUserId={props.currentUserId}
            isCurrentUserAdmin={props.isCurrentUserAdmin}
            defaultValues={backgroundComments}
            onChange={onBackgroundCommentsChange}
            className={styles.marginBottom}
          />
        )
      }

      {
        /**
         * General comments is @deprecated, but if a form was submitted with it we still want to show it
         * {@link https://buildateam.atlassian.net/browse/NEXUS-828}
         */
        Boolean(generalComments.comments.length) && (
          <VettingFeedbackFormGeneralCommentsForm
            avatarSrc={props.currentUserProfilePictureURL}
            currentUserId={props.currentUserId}
            isCurrentUserAdmin={props.isCurrentUserAdmin}
            defaultValues={generalComments}
            onChange={onGeneralCommentsChange}
            className={styles.marginBottom}
          />
        )
      }

      <VettingFeedbackFormSummaryCommentsForm
        avatarSrc={props.currentUserProfilePictureURL}
        currentUserId={props.currentUserId}
        isCurrentUserAdmin={props.isCurrentUserAdmin}
        defaultValues={summaryComments}
        onChange={onSummaryCommentsChange}
        errors={formErrors['summaryComments']}
        className={styles.marginBottom}
      />

      <VettingFeedbackFormTimestamp
        submitDate={props.defaultValues.general.vettingProcess.lastSubmitDate}
        saveDate={props.defaultValues.general.vettingProcess.lastSaveDate}
        className={styles.marginBottom}
      />

      <VettingFeedbackFormFooter
        formErrorsNotes={formErrorsNotes}
        hasFormSubmissionTimeout={hasFormSubmissionTimeout}
        hasUserTriedToSubmit={hasUserTriedToSubmit}
        builderDidNotShowUpTicked={mainInfo.builderDidNotShowUp}
        onCancelAndExit={props.onClose}
        onSave={onSave}
        onSubmit={onSubmit}
        className={styles.formFooter}
      />

      <NavigationPrompt
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => isDirty && !mainInfo.builderDidNotShowUp}
        renderCustomConfirmModal={renderNavigationPromptCustomModal}
      />
    </div>
  );
};
