import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { theme } from '@a_team/ui-components';
import { Spacing } from '@ateams/components';
import { Button } from '../components/button';
import { LeftArrow } from '../components/icons/arrows';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { Size, Text } from '../components/typography';
import { NegativeIcon } from '../components/icons/negative';

export interface VettingFeedbackFormFooterProps {
  formErrorsNotes: string[];
  hasFormSubmissionTimeout: boolean;
  hasUserTriedToSubmit: boolean;
  builderDidNotShowUpTicked: boolean;
  onCancelAndExit: () => void;
  onSave: () => unknown;
  onSubmit: () => void;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  submitButton: {
    marginLeft: Spacing.medium,
  },
  button: {
    height: '40px',
  },
});

export const VettingFeedbackFormFooter: React.FC<
  VettingFeedbackFormFooterProps
> = ({
  formErrorsNotes,
  hasFormSubmissionTimeout,
  hasUserTriedToSubmit,
  builderDidNotShowUpTicked,
  onCancelAndExit,
  onSave,
  onSubmit,
  className,
}) => {
  const styles = useStyles();

  const shouldSubmitBeDisabled = useMemo(() => {
    if (builderDidNotShowUpTicked) {
      return false;
    }

    if (hasFormSubmissionTimeout) {
      return true;
    }

    return Boolean(hasUserTriedToSubmit && formErrorsNotes.length);
  }, [hasFormSubmissionTimeout, hasUserTriedToSubmit, formErrorsNotes]);

  return (
    <div className={cx(styles.container, className)}>
      <Button
        className={styles.button}
        noColor
        startAdornment={<LeftArrow />}
        onClick={onCancelAndExit}
      >
        Cancel
      </Button>

      <div className={styles.container}>
        <TooltipFormErrors
          formErrorsNotes={formErrorsNotes}
          isHidden={!shouldSubmitBeDisabled}
        >
          <Button
            className={styles.button}
            contained={false}
            onClick={onSave}
            disabled={shouldSubmitBeDisabled}
            data-testing-id={'vetting-feedback-form-save-button'}
          >
            Save draft
          </Button>
        </TooltipFormErrors>
        <TooltipFormErrors
          formErrorsNotes={formErrorsNotes}
          isHidden={!shouldSubmitBeDisabled}
        >
          <Button
            className={cx(styles.button, styles.submitButton)}
            onClick={onSubmit}
            disabled={shouldSubmitBeDisabled}
            data-testing-id={'vetting-feedback-form-submit-button'}
          >
            Submit
          </Button>
        </TooltipFormErrors>
      </div>
    </div>
  );
};

interface TooltipFormErrorsProps {
  formErrorsNotes: string[];
  isHidden?: boolean;
}

const useTooltipFormErrorsStyles = createUseStyles({
  '@global': {
    '.tippy-tooltip': {
      padding: 0,
    },
    '.tippy-popper': {
      maxWidth: 'none',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: Spacing.small,
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '4px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  negativeIcon: {
    color: theme.colors.Red[600],
    marginRight: '4px',
  },
  errorText: {
    textAlign: 'left',
  },
});

const TooltipFormErrors: React.FC<TooltipFormErrorsProps> = ({
  formErrorsNotes,
  isHidden,
  children,
}) => {
  const styles = useTooltipFormErrorsStyles();

  if (!formErrorsNotes.length || isHidden) {
    return <>{children}</>;
  }

  return (
    <TooltipWrapped
      trigger={'mouseenter'}
      arrow
      theme={'light'}
      position={'top'}
      html={
        <div className={styles.container}>
          {formErrorsNotes.map((formError) => (
            <div key={formError} className={styles.line}>
              <NegativeIcon className={styles.negativeIcon} />
              <Text size={Size.Small} className={styles.errorText}>
                {formError}
              </Text>
            </div>
          ))}
        </div>
      }
    >
      {children}
    </TooltipWrapped>
  );
};
