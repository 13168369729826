import { Button as CallToActionButton } from '@ateams/components';
import React, { ReactElement } from 'react';
import { FileSelectionBoxProps } from './FileSelectionBox';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  tabInner: {
    padding: 32,
  },
  button: {
    marginTop: 25,
    display: 'block',
    margin: '0 auto',
  },
});

export const UploadTab = (props: FileSelectionBoxProps): ReactElement => {
  const styles = useStyles();

  return (
    <div className={styles.tabInner}>
      <p>An image that's 1600X1200 will work best. </p>
      <CallToActionButton
        className={styles.button}
        onClick={(e) => {
          e.preventDefault();
          props.openDialog && props.openDialog();
        }}
      >
        Select File
      </CallToActionButton>
    </div>
  );
};
