import React from 'react';
import { AdminVettingProcessColumnProps } from '..';
import { Button } from '@ateams/components';
import useToggle from '@src/hooks/useToggle';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { useStores } from '@src/stores';
import { createUseStyles } from 'react-jss';
import {
  OnRemoveVettingProcessSubmit,
  RemoveVettingProcessModal,
} from '@src/components/Modal/RemoveVettingProcessModal';

const useStyles = createUseStyles({
  button: {
    width: '200px',
  },
});

export const RemoveApplicationField: React.FC<
  AdminVettingProcessColumnProps
> = ({ vettingProcess }) => {
  const styles = useStyles();
  const { vettingProcesses } = useStores();
  const [removeVettingProcessModalOpen, toggleRemoveVettingProcessModalOpen] =
    useToggle();
  const [loading, setLoading] = useLoadingState();

  const onRemoveVettingProcessSubmit: OnRemoveVettingProcessSubmit = ({
    reason,
  }) => {
    setLoading(
      (async () => {
        await vettingProcesses.removeVettingProcess({
          vettingProcessId: vettingProcess.id,
          reason,
        });

        toggleRemoveVettingProcessModalOpen();
      })(),
      'Vetting process removed',
    );
  };

  return (
    <>
      <RemoveVettingProcessModal
        open={removeVettingProcessModalOpen}
        onClose={toggleRemoveVettingProcessModalOpen}
        onSubmit={onRemoveVettingProcessSubmit}
      />
      <Button
        onClick={toggleRemoveVettingProcessModalOpen}
        size="small"
        className={styles.button}
      >
        Remove Application
      </Button>
      <LoadingIndicator loading={loading} />
    </>
  );
};
