import {
  VettingProcessFeedbackRoles,
  VettingProcessFeedbackRoleTags,
} from '@a_team/models/dist/vetting-processes/feedback';
import { VettingFormVariant } from '@a_team/models/dist/vetting-processes/vetting-processes';

export const VettingProcessFeedbackRolesLabels: Record<
  VettingProcessFeedbackRoles,
  string
> = {
  [VettingProcessFeedbackRoles.FullStackDeveloper]: 'Full Stack',
  [VettingProcessFeedbackRoles.BackEnd]: 'Backend',
  [VettingProcessFeedbackRoles.FrontEnd]: 'Frontend',
  [VettingProcessFeedbackRoles.DevOps]: 'Devops',
  [VettingProcessFeedbackRoles.QAEngineer]: 'QA Engineer',
  [VettingProcessFeedbackRoles.DataScientist]: 'Data Scientist',
  [VettingProcessFeedbackRoles.SoftwareArchitect]: 'Software Architect',
  [VettingProcessFeedbackRoles.SoftwareEngineer]: 'Software Engineer',
  [VettingProcessFeedbackRoles.MobileDeveloper]: 'Mobile Developer',
  [VettingProcessFeedbackRoles.IndividualContributor]: 'Individual Contributor',
  [VettingProcessFeedbackRoles.TeamLead]: 'Team Lead',
  [VettingProcessFeedbackRoles.CXOLevel]: 'CXO Level',
  [VettingProcessFeedbackRoles.HandsOnContributor]: 'Hands-On Contributor',
  [VettingProcessFeedbackRoles.ConsultantOrStrategist]:
    'Consultant / Strategist',
  [VettingProcessFeedbackRoles.TechnicalProductManager]:
    'Technical Product Manager',
  [VettingProcessFeedbackRoles.DesignProductManager]: 'Design Product Manager',
  [VettingProcessFeedbackRoles.StrategyProductManager]:
    'Strategy Product Manager',
  [VettingProcessFeedbackRoles.GrowthProductManager]: 'Growth Product Manager',
  [VettingProcessFeedbackRoles.ProductMarketingManager]:
    'Product Marketing Manager',
  [VettingProcessFeedbackRoles.MarketingProductManager]:
    'Marketing Product Manager',
  [VettingProcessFeedbackRoles.ProjectManager]: 'Project Manager',
  [VettingProcessFeedbackRoles.ProductOwner]: 'Product Owner',
  [VettingProcessFeedbackRoles.ArtDirector]: 'Art Director',
  [VettingProcessFeedbackRoles.BrandDesigner]: 'Brand Designer',
  [VettingProcessFeedbackRoles.GameDesigner]: 'Game Designer',
  [VettingProcessFeedbackRoles.GraphicsDesigner]: 'Graphics Designer',
  [VettingProcessFeedbackRoles.IndustrialDesigner]: 'Industrial Designer',
  [VettingProcessFeedbackRoles.MotionDesigner]: 'Motion Designer',
  [VettingProcessFeedbackRoles.ProductDesigner]: 'Product Designer',
  [VettingProcessFeedbackRoles.UXDesigner]: 'UX Designer',
  [VettingProcessFeedbackRoles.UXResearcher]: 'UX Researcher',
  [VettingProcessFeedbackRoles.PPC]: 'PPC',
  [VettingProcessFeedbackRoles.SEO]: 'SEO',
  [VettingProcessFeedbackRoles.GrowthHacker]: 'Growth Hacker',
  [VettingProcessFeedbackRoles.Strategy]: 'Strategy',
  [VettingProcessFeedbackRoles.ContentCreation]: 'Content Creation',
  [VettingProcessFeedbackRoles.EmailMarketing]: 'Email Marketing',
  [VettingProcessFeedbackRoles.SocialMarketing]: 'Social Marketing',
  [VettingProcessFeedbackRoles.Branding]: 'Branding',
  [VettingProcessFeedbackRoles.PerformanceMarketing]: 'Performance Marketing',
  [VettingProcessFeedbackRoles.Testing]: 'Testing',
  [VettingProcessFeedbackRoles.CMO]: 'CMO',
};

const VettingProcessFeedbackSoftwareEngineeringRoles = [
  VettingProcessFeedbackRoles.FullStackDeveloper,
  VettingProcessFeedbackRoles.BackEnd,
  VettingProcessFeedbackRoles.FrontEnd,
  VettingProcessFeedbackRoles.DevOps,
  VettingProcessFeedbackRoles.QAEngineer,
  VettingProcessFeedbackRoles.DataScientist,
  VettingProcessFeedbackRoles.SoftwareArchitect,
  VettingProcessFeedbackRoles.SoftwareEngineer,
  VettingProcessFeedbackRoles.MobileDeveloper,
  VettingProcessFeedbackRoles.IndividualContributor,
  VettingProcessFeedbackRoles.TeamLead,
  VettingProcessFeedbackRoles.CXOLevel,
  VettingProcessFeedbackRoles.ConsultantOrStrategist,
];

const VettingProcessFeedbackProductManagerRoles = [
  VettingProcessFeedbackRoles.TechnicalProductManager,
  VettingProcessFeedbackRoles.DesignProductManager,
  VettingProcessFeedbackRoles.StrategyProductManager,
  VettingProcessFeedbackRoles.GrowthProductManager,
  VettingProcessFeedbackRoles.ProductMarketingManager,
  VettingProcessFeedbackRoles.MarketingProductManager,
  VettingProcessFeedbackRoles.ProjectManager,
  VettingProcessFeedbackRoles.ProductOwner,
  VettingProcessFeedbackRoles.IndividualContributor,
  VettingProcessFeedbackRoles.CXOLevel,
  VettingProcessFeedbackRoles.ConsultantOrStrategist,
];

const VettingProcessFeedbackProjectManagerRoles = [
  VettingProcessFeedbackRoles.TechnicalProductManager,
  VettingProcessFeedbackRoles.DesignProductManager,
  VettingProcessFeedbackRoles.StrategyProductManager,
  VettingProcessFeedbackRoles.GrowthProductManager,
  VettingProcessFeedbackRoles.ProductMarketingManager,
  VettingProcessFeedbackRoles.MarketingProductManager,
  VettingProcessFeedbackRoles.ProjectManager,
  VettingProcessFeedbackRoles.ProductOwner,
  VettingProcessFeedbackRoles.IndividualContributor,
  VettingProcessFeedbackRoles.CXOLevel,
  VettingProcessFeedbackRoles.ConsultantOrStrategist,
];

const VettingProcessFeedbackDesignerRoles = [
  VettingProcessFeedbackRoles.ArtDirector,
  VettingProcessFeedbackRoles.BrandDesigner,
  VettingProcessFeedbackRoles.GameDesigner,
  VettingProcessFeedbackRoles.GraphicsDesigner,
  VettingProcessFeedbackRoles.IndustrialDesigner,
  VettingProcessFeedbackRoles.MotionDesigner,
  VettingProcessFeedbackRoles.ProductDesigner,
  VettingProcessFeedbackRoles.UXDesigner,
  VettingProcessFeedbackRoles.UXResearcher,
  VettingProcessFeedbackRoles.IndividualContributor,
  VettingProcessFeedbackRoles.TeamLead,
  VettingProcessFeedbackRoles.CXOLevel,
  VettingProcessFeedbackRoles.ConsultantOrStrategist,
];

const VettingProcessFeedbackMarketingRoles = [
  VettingProcessFeedbackRoles.PPC,
  VettingProcessFeedbackRoles.SEO,
  VettingProcessFeedbackRoles.GrowthHacker,
  VettingProcessFeedbackRoles.Strategy,
  VettingProcessFeedbackRoles.ContentCreation,
  VettingProcessFeedbackRoles.EmailMarketing,
  VettingProcessFeedbackRoles.SocialMarketing,
  VettingProcessFeedbackRoles.Branding,
  VettingProcessFeedbackRoles.PerformanceMarketing,
  VettingProcessFeedbackRoles.Testing,
  VettingProcessFeedbackRoles.CMO,
  VettingProcessFeedbackRoles.IndividualContributor,
  VettingProcessFeedbackRoles.TeamLead,
  VettingProcessFeedbackRoles.CXOLevel,
  VettingProcessFeedbackRoles.ConsultantOrStrategist,
];

const VettingProcessFeedbackOperationRoles = [
  VettingProcessFeedbackRoles.IndividualContributor,
  VettingProcessFeedbackRoles.TeamLead,
  VettingProcessFeedbackRoles.CXOLevel,
  VettingProcessFeedbackRoles.ConsultantOrStrategist,
];

export const VettingProcessFeedbackRoleTagsLabels: typeof VettingProcessFeedbackRoleTags =
  {
    [VettingProcessFeedbackRoles.FullStackDeveloper]: {
      BackEndOriented: 'Backend Oriented',
      FrontEndOriented: 'Frontend Oriented',
      DevOpsOriented: 'DevOps Oriented',
      UIUXOriented: 'UX/UI Oriented',
    },
    [VettingProcessFeedbackRoles.QAEngineer]: {
      Manual: 'Manual',
      Automation: 'Automation',
    },
    [VettingProcessFeedbackRoles.DataScientist]: {
      DataAnalyst: 'Data Analyst',
      DataEngineer: 'Data Engineer',
      DataScientist: 'Data Scientist',
    },
    [VettingProcessFeedbackRoles.MobileDeveloper]: {
      Android: 'Android',
      IOS: 'iOS',
      ReactNativeOrOther: 'React Native / Other',
    },
  };

const IsVettingProcessFeedbackRoleShownOnOtherSet: Set<VettingProcessFeedbackRoles> =
  new Set([
    VettingProcessFeedbackRoles.IndividualContributor,
    VettingProcessFeedbackRoles.TeamLead,
    VettingProcessFeedbackRoles.CXOLevel,
    VettingProcessFeedbackRoles.HandsOnContributor,
    VettingProcessFeedbackRoles.ConsultantOrStrategist,
  ]);

export function getFeedbackFormRolesByVettingFormVariant(
  variant: VettingFormVariant,
) {
  const roles = getFeedbackFormRolesListByVettingFormVariant(variant);

  return divideOtherRoles(roles);
}

/**
 * Differentiating between the regular/other roles on the view
 * Divide VettingProcessFeedbackRoles to 2 arrays based on IsVettingProcessFeedbackRoleShownOnOtherSet
 */
function divideOtherRoles(roles: VettingProcessFeedbackRoles[]) {
  return Object.values(roles).reduce<{
    RegularVettingProcessFeedbackRoles: VettingProcessFeedbackRoles[];
    OtherVettingProcessFeedbackRoles: VettingProcessFeedbackRoles[];
  }>(
    (acc, vettingProcessFeedbackRole) => {
      acc[
        IsVettingProcessFeedbackRoleShownOnOtherSet.has(
          vettingProcessFeedbackRole,
        )
          ? 'OtherVettingProcessFeedbackRoles'
          : 'RegularVettingProcessFeedbackRoles'
      ].push(vettingProcessFeedbackRole);

      return acc;
    },
    {
      RegularVettingProcessFeedbackRoles: [],
      OtherVettingProcessFeedbackRoles: [],
    },
  );
}

function getFeedbackFormRolesListByVettingFormVariant(
  variant: VettingFormVariant,
): VettingProcessFeedbackRoles[] {
  switch (variant) {
    case VettingFormVariant.ProductManagement:
      return VettingProcessFeedbackProductManagerRoles;

    case VettingFormVariant.ProjectManagement:
      return VettingProcessFeedbackProjectManagerRoles;

    case VettingFormVariant.Design:
      return VettingProcessFeedbackDesignerRoles;

    case VettingFormVariant.Marketing:
      return VettingProcessFeedbackMarketingRoles;

    case VettingFormVariant.Operations:
      return VettingProcessFeedbackOperationRoles;

    case VettingFormVariant.SoftwareEngineering:
    default:
      return VettingProcessFeedbackSoftwareEngineeringRoles;
  }
}
