import React, { ReactElement } from 'react';
import RoleCategoryObject from '@a_team/models/dist/RoleCategory';
import RoleCategorySelect from '@src/views/Mission/EditMission/RoleCategorySelect';
import { createUseStyles } from 'react-jss';

interface Props {
  roleId?: string;
  roleTitle?: string;
  readonly?: boolean;
  disabled?: boolean;
  onChange?(category: RoleCategoryObject): void;
  onEditClick?(): void;
}

const useStyles = createUseStyles({
  title: (props: Props) => ({
    border: 'none',
    padding: 0,
    paddingBottom: 6,
    borderBottom: '1px dashed #C0C0C0',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '34px',
    display: 'block',
    cursor: 'pointer',
    ...(!props.roleTitle && {
      color: 'rgb(189, 189, 189)',
      fontWeight: 400,
    }),
  }),
});

export const Title = (props: Props): ReactElement => {
  const styles = useStyles(props);
  const { roleId, roleTitle, readonly, onChange, onEditClick } = props;
  return (
    <div>
      {readonly ? (
        <span style={{ fontSize: 24, fontWeight: 600, lineHeight: '34px' }}>
          {roleTitle}
        </span>
      ) : onEditClick ? (
        <span className={styles.title} onClick={onEditClick}>
          {roleTitle || 'Enter your main role'}
        </span>
      ) : (
        <RoleCategorySelect
          cid={roleId}
          onChange={onChange}
          margin={'none'}
          noBorder
          caret={false}
          className={styles.title}
          inputStyles={{ fontWeight: 600 }}
          cardStyles={{ fontSize: 15, marginTop: -28 }}
        />
      )}
    </div>
  );
};
