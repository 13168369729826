import { Icon, IconType } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { TextColors } from '@ateams/components';

interface RateRangeProps {
  builderRateMin: number;
  builderRateMax: number;
}

const useStyles = createUseStyles({
  root: {
    marginBottom: 24,
    background: '#F7F7F7',
    borderRadius: 8,
    padding: 16,
  },
  preferredRateWrapper: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
  preferredRateDescription: {
    marginTop: 8,
    color: TextColors.regularLight,
    fontSize: 12,
    lineHeight: '16px',
  },
  cashIcon: {
    marginRight: '10px',
  },
});

function RateRange({
  builderRateMin,
  builderRateMax,
}: RateRangeProps): JSX.Element {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.preferredRateWrapper}>
        <Icon type={IconType.Cash} size={'exact'} className={styles.cashIcon} />
        Company’s preferred rate range: ${Math.round(builderRateMin)} - $
        {Math.round(builderRateMax)}
      </div>
      <div className={styles.preferredRateDescription}>
        If your rate is higher, you can still request to join — but your chances
        of getting on the mission may be lower.
      </div>
    </div>
  );
}

export default RateRange;
