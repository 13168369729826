import React from 'react';
import { createUseStyles } from 'react-jss';
import { theme } from '@a_team/ui-components';
import { Spacing } from '@ateams/components';

const useStyles = createUseStyles({
  sectionTitle: {
    color: theme.colors.Grey[900],
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
    height: '22px',
    marginBottom: Spacing.small,
  },
  sectionContent: {
    fontWeight: 300,
    fontSize: '15px',
    lineHeight: '24px',
    color: theme.colors.Grey[1000],
    marginBottom: Spacing.medium,
  },
});

interface AnalysisSuggestionsProps {
  suggestions: Array<string>;
}

export const AnalysisSuggestions: React.FC<AnalysisSuggestionsProps> = (
  props,
) => {
  const { suggestions } = props;
  const styles = useStyles();

  return (
    <>
      <div className={styles.sectionTitle}>Improvement Suggestions</div>
      <ul className={styles.sectionContent}>
        {suggestions.map((suggestion) => (
          <li key={suggestion}>{suggestion}</li>
        ))}
      </ul>
    </>
  );
};
