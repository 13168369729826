import cx from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import plusIcon from './plusIcon.svg';
import DraggableContainer from '@src/components/DraggableContainer';

interface PreviewImagesUnderneathProps {
  images: string[];
  setImages?: (images: string[]) => void;
  currentImage: string;
  setCurrentImage: (image: string) => void;
  openDialog: () => void;
}

const useStyles = createUseStyles({
  thumbnails: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center',
    gap: 8,
    marginBottom: 40,
  },
  thumbnail: {
    '&.active': {
      borderRadius: 6,
      border: '1px solid #6D00D7',
      padding: 3,
    },
    cursor: 'pointer',
  },
  image: {
    width: 64,
    height: 48,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 6,
    '&.active': {
      width: 53,
      height: 40,
      borderRadius: 3,
    },
  },
  addImage: {
    width: 64,
    height: 48,
    borderRadius: 6,
    border: '1px dashed #DADADC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const PreviewImagesUnderneath = ({
  images,
  setImages,
  currentImage,
  setCurrentImage,
  openDialog,
}: PreviewImagesUnderneathProps): JSX.Element => {
  const styles = useStyles();

  const items = () => {
    return images.map((image, index) => (
      <div
        key={`thumbnail-${index}`}
        className={cx(
          styles.thumbnail,
          currentImage === image ? 'active' : undefined,
        )}
        onClick={(e) => {
          e.stopPropagation();
          setCurrentImage(image);
        }}
      >
        <div
          className={cx(styles.image, currentImage === image ? 'active' : '')}
          style={{ backgroundImage: `url("${image}")` }}
        ></div>
      </div>
    ));
  };

  return (
    <div className={styles.thumbnails}>
      {setImages ? (
        <DraggableContainer
          items={images}
          onOrderChange={(images) => {
            setImages(images);
          }}
          renderedItems={items()}
        />
      ) : (
        items()
      )}
      <div
        className={styles.addImage}
        onClick={(e) => {
          e.stopPropagation();
          openDialog();
        }}
      >
        <img src={plusIcon} width="16" height="16" alt="Add" />
      </div>
    </div>
  );
};

export default PreviewImagesUnderneath;
