import { ServiceAuth, ServiceEndpoint } from './utils';

export const BasePath = '/hubspot';

export type HubspotResponse = {
  createdate: string;
  hubspot_owner_id: string;
  dealname: string;
  hs_lastmodifieddate: string;
  hs_object_id: string;
  deal_owner_name: string;
  deal_owner_email: string;
};

export default class HubspotEndpoint extends ServiceEndpoint {
  public adminGetDealById(
    auth: ServiceAuth,
    id: string,
  ): Promise<HubspotResponse> {
    return this.fetch(auth, BasePath + `/${id}`);
  }

  public updateMissionPostedDate(
    auth: ServiceAuth,
    data: { roleIds: string[] },
  ) {
    return this.fetch(
      auth,
      BasePath + '/mission-posted-date',
      null,
      'post',
      data,
    );
  }
}
