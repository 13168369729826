import React, { ReactElement, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { sectionTitleStyle } from '../common-styles';

interface Props {
  grow?: boolean;
  title: ReactNode;
  children: ReactNode;
}

const useStyles = createUseStyles({
  root: {
    minWidth: '22.5%',
    flexGrow: (props: Props) => (props.grow ? 1 : 0),

    '& + &': {
      marginLeft: '5%',
    },
  },
  title: {
    ...sectionTitleStyle,
    margin: '0 0 16px',
  },
});

export default function HeroSection(props: Props): ReactElement {
  const styles = useStyles(props);

  return (
    <div className={styles.root}>
      <h4 className={styles.title}>{props.title}</h4>

      {props.children}
    </div>
  );
}
