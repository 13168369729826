import { Stores } from '@src/stores';
import { action, observable } from 'mobx';
import { UserCardObject, UserId } from '@a_team/models/dist/UserObject';
import { apiVetter } from '@ateams/api';
import { BasicVetterObject, VetterSummary } from '@a_team/models/dist/vetter';
import {
  CountVettersQuery,
  FetchVettersQuery,
} from '@ateams/api/dist/endpoints/vetter';

export const FETCH_VETTERS_BATCH_SIZE = {
  SMALL: 25,
  MEDIUM: 50,
  LARGE: 100,
};

export interface VettersStoreData {
  isAdminView?: boolean;
  isTableView?: boolean;
  vetters?: (VetterSummary | UserCardObject)[];
  vettersCount?: number;
  pagination: {
    batchSize: number;
    page: number;
  };
  vetterConfiguration?: BasicVetterObject;
}

export default class VettersStore implements VettersStoreData {
  @observable isAdminView: VettersStoreData['isAdminView'];
  @observable isTableView: VettersStoreData['isTableView'];
  @observable vetters: VettersStoreData['vetters'];
  @observable vettersCount: VettersStoreData['vettersCount'];
  @observable pagination: VettersStoreData['pagination'] = {
    batchSize: FETCH_VETTERS_BATCH_SIZE.LARGE,
    page: 1,
  };
  @observable vetterConfiguration?: VettersStoreData['vetterConfiguration'];
  private readonly rootStore: Stores;
  protected getVettersAbortController?: AbortController;
  protected getVettersCountAbortController?: AbortController;

  public constructor(rootStore: Stores, initialState?: VettersStoreData) {
    this.rootStore = rootStore;

    if (initialState) {
      this.isAdminView = initialState.isAdminView;
      this.isTableView = initialState.isTableView;
      this.vetters = initialState.vetters;
      this.vettersCount = initialState.vettersCount;
      this.pagination = initialState.pagination;
      this.vetterConfiguration = initialState.vetterConfiguration;
    }
  }

  public serialize(): VettersStoreData {
    return {
      isAdminView: this.isAdminView,
      isTableView: this.isTableView,
      vetters: this.vetters,
      vettersCount: this.vettersCount,
      pagination: this.pagination,
      vetterConfiguration: this.vetterConfiguration,
    };
  }

  @action setAdminView(isAdminView: boolean): void {
    this.isAdminView = isAdminView;
  }

  private fetchVettingConfiguration = async (): Promise<BasicVetterObject> => {
    return apiVetter.fetchVettingConfiguration(this.rootStore.auth);
  };

  getVettingConfiguration = async () => {
    const vettingConfiguration = await this.fetchVettingConfiguration();

    this.vetterConfiguration = vettingConfiguration;

    return vettingConfiguration;
  };

  private fetchVetters = async (
    payload: FetchVettersQuery,
    abortController?: AbortController,
  ): Promise<(VetterSummary | UserCardObject)[]> => {
    return apiVetter[
      this.isAdminView
        ? this.isTableView
          ? 'adminFetchVettersWithSummary'
          : this.rootStore.auth.withNewEvaluation
          ? 'adminFetchVettersUserCardsV2'
          : 'adminFetchVettersUserCards'
        : 'fetchVettersUserCards'
    ](this.rootStore.auth, payload, abortController);
  };

  getVetters = async (payload: FetchVettersQuery) => {
    const newAbortController = new AbortController();

    this.getVettersAbortController?.abort();
    this.getVettersAbortController = newAbortController;

    try {
      this.vetters = await this.fetchVetters(
        payload,
        this.getVettersAbortController,
      );
    } catch (err) {
      if (!newAbortController.signal.aborted) {
        throw err;
      }
    }
  };

  private countVetters = async (
    payload: CountVettersQuery,
    abortController?: AbortController,
  ): Promise<number> => {
    return apiVetter[
      this.rootStore.auth.isAdmin
        ? this.rootStore.auth.withNewEvaluation
          ? 'adminCountVettersV2'
          : 'adminCountVetters'
        : 'countVetters'
    ](this.rootStore.auth, payload, abortController);
  };

  getVettersCount = async (payload: CountVettersQuery): Promise<void> => {
    const newAbortController = new AbortController();

    this.getVettersCountAbortController?.abort();
    this.getVettersCountAbortController = newAbortController;

    try {
      this.vettersCount = await this.countVetters(
        payload,
        this.getVettersCountAbortController,
      );
    } catch (err) {
      if (!newAbortController.signal.aborted) {
        throw err;
      }
    }
  };

  addToSelectionTeam = async (userId: UserId): Promise<void> => {
    const vetter = await apiVetter.createVetter(this.rootStore.auth, {
      userId,
    });

    if (this.rootStore.users.profile?.data.uid === userId) {
      this.rootStore.users.profile.setIsVetter(true);
      this.rootStore.users.profile.setVetterConfiguration(vetter);
    }
  };

  removeFromSelectionTeam = async (userId: UserId): Promise<void> => {
    await apiVetter.deleteVetter(this.rootStore.auth, { userId });

    if (this.rootStore.users.profile?.data.uid === userId) {
      this.rootStore.users.profile.setIsVetter(false);
      this.rootStore.users.profile.setVetterConfiguration(undefined);
    }
  };

  @action clearVetters(): void {
    this.vetters = undefined;
  }

  @action setTableView(isTableView: boolean): void {
    this.isTableView = isTableView;
  }

  @action setVettersBatchSize(batchSize: number): void {
    this.pagination.batchSize = batchSize;
  }

  @action setVettersPage(page: number): void {
    this.pagination.page = page;
  }
}
