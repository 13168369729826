import React, { ReactElement, useMemo, useState } from 'react';
import {
  AdminMissionApplicationObject,
  CurrentUserMissionApplicationStatus,
  MissionApplicationInternalStatus,
  MissionApplicationLowCompetitivenessReason,
  MissionApplicationLowCompetitivenessReasonLabels,
  MissionApplicationRejectionReason,
  MissionApplicationRejectionReasonLabels,
  MissionApplicationReviewStatusNotSelected,
  MissionApplicationReviewStatusNotSelectedLabels,
  MissionApplicationReviewStatusOpportunityToUpdate,
  MissionApplicationReviewStatusOpportunityToUpdateLabels,
  MissionApplicationReviewStatusOther,
  MissionApplicationReviewStatusOtherLabels,
  MissionApplicationReviewStatusWaitlisted,
  MissionApplicationReviewStatusWaitlistedLabels,
  MissionApplicationStatusStage,
  MissionMissingRequirement,
} from '@a_team/models/dist/MissionApplicationObject';
import { createUseStyles } from 'react-jss';
import UserAvatar, { BadgesPosition } from '@src/components/UserAvatar';
import UserSup from '@src/components/UserSup';
import cx from 'classnames';
import {
  ApplicationStatusAdminColors,
  BorderRadius,
  Checkbox,
  Colors,
  Icon,
  IconType,
  Spacing,
  Tag,
  TextColors,
} from '@ateams/components';
import {
  PROPOSED_BUT_NOT_SHARED,
  getApplicationStatusStage,
  isMissionApplicationEdited,
} from '@src/helpers/applications';
import { isUserFullyVetted } from '@src/helpers/users';
import Dropdown, { DropdownItem } from '../Dropdown';
import LabeledCheckboxInput from '../Inputs/LabeledCheckboxInput';
import { tagEditedGrayStyle } from '@src/common-styles/tag-edited';
import { useStores } from '@src/stores';
import TooltipWrapped from '../TooltipWrapped';
import { MissingRequirementLabel } from '@src/views/Profile/Sidebar/MissingRequirements/MissingRequirementsUtils';

interface Props {
  application: AdminMissionApplicationObject;
  onClick?(): void;
  draggable?: boolean;
  onDragStart?(): void;
  selected?: boolean;
  setSelected?: (selected: boolean) => void;
  onBulkMenuClick?: (
    application: AdminMissionApplicationObject,
    item: BulkMenuItemOld | BulkMenuItem,
    subItem?: BulkSubMenuItemOld | BulkSubMenuItem,
    checked?: boolean,
    apply?: boolean,
  ) => void;
  countSelectedApplications?: number;
  unionLowCompetitiveness?: Map<string, number>;
  unionRejectionReason?: Map<string, number>;
  unionNotSelected?: Map<string, number>;
  unionWaitlisted?: Map<string, number>;
  unionOpportunityToUpdate?: Map<string, number>;
  unionOther?: Map<string, number>;
  proposalInterviews?: number;
}

const useStyles = createUseStyles({
  applicantPill: {
    marginRight: '8px',
    marginBottom: '8px',
    display: 'inline-flex',
  },
  applicantPillInner: {
    backgroundColor: Colors.tag,
    borderRadius: BorderRadius.default,
    padding: '8px',
    alignItems: 'center',
    cursor: 'pointer',
    display: 'inline-flex',
    position: 'relative',
  },
  invalidApplicantPillIndicator: {
    backgroundColor: Colors['dangerLight'],
    borderRadius: BorderRadius.default,
    marginLeft: '-6px',
    padding: '0 6px 0 12px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropdownContainer: {
    position: 'absolute',
    right: 0,
    top: '100%',
    zIndex: 1000,
  },
  tagEdited: {
    ...tagEditedGrayStyle,
    marginLeft: '8px',
  },
  [MissionApplicationStatusStage.New]: {},
  [MissionApplicationStatusStage.VettingScheduled]: {
    backgroundColor: Colors[ApplicationStatusAdminColors.VettingScheduled],
    color: TextColors['regular'],
  },
  [MissionApplicationStatusStage.Rejected]: {
    backgroundColor: Colors[ApplicationStatusAdminColors.Rejected],
    color: TextColors[ApplicationStatusAdminColors.Rejected],
  },
  [PROPOSED_BUT_NOT_SHARED as never]: {
    backgroundColor: '#FF69B4',
    color: TextColors[ApplicationStatusAdminColors.Proposed],
  },
  [CurrentUserMissionApplicationStatus.Interviewing]: {
    backgroundColor: Colors[ApplicationStatusAdminColors.Interviewing],
    color: TextColors['successDark'],
  },
  [MissionApplicationStatusStage.Proposed]: {
    backgroundColor: Colors[ApplicationStatusAdminColors.Proposed],
    color: TextColors['regular'],
  },
  [MissionApplicationStatusStage.ShortlistGood]: {
    backgroundColor: Colors[ApplicationStatusAdminColors.ShortlistGood],
    color: TextColors[ApplicationStatusAdminColors.ShortlistGood],
  },
  [MissionApplicationStatusStage.ShortlistStrong]: {
    backgroundColor: Colors[ApplicationStatusAdminColors.ShortlistStrong],
    color: TextColors[ApplicationStatusAdminColors.ShortlistStrong],
  },
  [MissionApplicationStatusStage.Exclusive]: {
    backgroundColor: Colors[ApplicationStatusAdminColors.Exclusive],
    color: TextColors['regular'],
  },
  [MissionApplicationStatusStage.ProbablyNot]: {
    backgroundColor: Colors[ApplicationStatusAdminColors.LowCompetitiveness],
    color: TextColors['regular'],
  },
  [CurrentUserMissionApplicationStatus.LowCompetitiveness]: {
    backgroundColor: Colors[ApplicationStatusAdminColors.LowCompetitiveness],
    color: TextColors['regular'],
  },
  [MissionApplicationStatusStage.NotAvailable]: {
    backgroundColor: Colors[ApplicationStatusAdminColors.NotAvailable],
    color: TextColors[ApplicationStatusAdminColors.NotAvailable],
  },
  [MissionApplicationStatusStage.Accepted]: {
    backgroundColor: Colors[ApplicationStatusAdminColors.Accepted],
    color: TextColors['secondary'],
  },
  [MissionApplicationStatusStage.OnHold]: {
    backgroundColor: Colors[ApplicationStatusAdminColors.OnHold],
    color: TextColors['regular'],
  },
  [MissionApplicationStatusStage.OpportunityToUpdate]: {
    backgroundColor: Colors[ApplicationStatusAdminColors.OpportunityToUpdate],
    color: TextColors['secondary'],
  },
  checkbox: {
    marginRight: 8,
  },
});

/**
 * @deprecated
 */
export interface BulkSubMenuItemOld {
  value:
    | MissionApplicationLowCompetitivenessReason
    | MissionApplicationRejectionReason;
  label: string;
}

/**
 * @deprecated
 */
export interface BulkMenuItemOld {
  value: MissionApplicationInternalStatus;
  label: string;
  subItems?: BulkSubMenuItemOld[];
}

/**
 * @deprecated
 */
const BULK_UPDATE_APPLICATION_STATUS_OPTIONS_OLD: BulkMenuItemOld[] = [
  {
    value: MissionApplicationInternalStatus.ShortlistGood,
    label: 'Shortlist - Good',
  },
  {
    value: MissionApplicationInternalStatus.ShortlistStrong,
    label: 'Shortlist - Strong',
  },
  {
    value: MissionApplicationInternalStatus.Interviewing,
    label: 'Interviewing',
  },
  {
    value: MissionApplicationInternalStatus.New,
    label: 'Low competitiveness',
    subItems: Object.entries(
      MissionApplicationLowCompetitivenessReasonLabels,
    ).map(
      (v) =>
        ({
          value: v[0],
          label: v[1],
        } as BulkSubMenuItemOld),
    ),
  },
  {
    value: MissionApplicationInternalStatus.Rejected,
    label: 'Rejected',
    subItems: Object.entries(MissionApplicationRejectionReasonLabels).map(
      (v) =>
        ({
          value: v[0],
          label: v[1],
        } as BulkSubMenuItemOld),
    ),
  },
  {
    value: MissionApplicationInternalStatus.NotAvailable,
    label: 'Unavailable',
  },
];

export enum BulkMenuMainItems {
  NotSelected = 'NotSelected',
  OpportunityToUpdate = 'OpportunityToUpdate',
  Waitlisted = 'Waitlisted',
  Other = 'Other',
}

export interface BulkSubMenuItem {
  value:
    | MissionApplicationReviewStatusNotSelected
    | MissionApplicationReviewStatusOpportunityToUpdate
    | MissionApplicationReviewStatusWaitlisted
    | MissionApplicationReviewStatusOther;
  label: string;
}

export interface BulkMenuItem {
  value: BulkMenuMainItems;
  label: string;
  subItems?: BulkSubMenuItem[];
}

export const BULK_UPDATE_APPLICATION_STATUS_OPTIONS: BulkMenuItem[] = [
  {
    value: BulkMenuMainItems.NotSelected,
    label: 'Not Selected',
    subItems: Object.entries(
      MissionApplicationReviewStatusNotSelectedLabels,
    ).map(
      (v) =>
        ({
          value: v[0],
          label: v[1],
        } as BulkSubMenuItem),
    ),
  },
  {
    value: BulkMenuMainItems.OpportunityToUpdate,
    label: 'Opportunity to update',
    subItems: Object.entries(
      MissionApplicationReviewStatusOpportunityToUpdateLabels,
    ).map(
      (v) =>
        ({
          value: v[0],
          label: v[1],
        } as BulkSubMenuItem),
    ),
  },
  {
    value: BulkMenuMainItems.Waitlisted,
    label: 'Wait/Short List',
    subItems: Object.entries(
      MissionApplicationReviewStatusWaitlistedLabels,
    ).map(
      (v) =>
        ({
          value: v[0],
          label: v[1],
        } as BulkSubMenuItem),
    ),
  },
  {
    value: BulkMenuMainItems.Other,
    label: 'Other',
    subItems: Object.entries(MissionApplicationReviewStatusOtherLabels).map(
      (v) =>
        ({
          value: v[0],
          label: v[1],
        } as BulkSubMenuItem),
    ),
  },
];

export default function ApplicantPill({
  application,
  onClick,
  draggable,
  onDragStart,
  selected,
  setSelected,
  onBulkMenuClick,
  countSelectedApplications,
  unionLowCompetitiveness,
  unionRejectionReason,
  unionNotSelected,
  unionWaitlisted,
  unionOpportunityToUpdate,
  unionOther,
  proposalInterviews,
}: Props): ReactElement {
  const { auth, missions } = useStores();
  const styles = useStyles(application);

  const [bulkMenuOpen, setBulkMenuOpen] = useState<boolean>(false);
  const [lowCompetitivenessChanged, setLowCompetitivenessChanged] =
    useState(false);
  const [rejectionReasonChanged, setRejectionReasonChanged] = useState(false);
  const [notSelectedChanged, setNotSelectedChanged] = useState(false);
  const [opportunityToUpdateChanged, setOpportunityToUpdateChanged] =
    useState(false);
  const [waitlistedChanged, setWaitlistedChanged] = useState(false);
  const [otherChanged, setOtherChanged] = useState(false);

  const options = useMemo(() => {
    if (auth.withMissionApplicationStatusV2) {
      return BULK_UPDATE_APPLICATION_STATUS_OPTIONS;
    }
    return BULK_UPDATE_APPLICATION_STATUS_OPTIONS_OLD;
  }, [auth.withMissionApplicationStatusV2]);

  const url = useMemo(() => {
    let url = '';
    if (typeof window !== 'undefined' && window.location) {
      const parsedUrl = new URL(window?.location?.href ?? 'https://a.team');
      parsedUrl.searchParams.set('aid', application?.aid);
      url = parsedUrl.toString();
    }
    return url;
  }, []);

  const isApplicationEdited = useMemo(
    () => isMissionApplicationEdited(application),
    [application.changes],
  );

  const onClickInternal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // command ( ctrl ) click
    if (e.ctrlKey || e.metaKey) {
      return;
    }
    // for shallow routing / client side routing
    e.preventDefault();
    e.stopPropagation();
    window.history.pushState(null, '', url);
    onClick?.();
  };

  const cleanUpNotSelected = () => {
    const item = (options as BulkMenuItem[]).find(
      (o) => o.value === BulkMenuMainItems.NotSelected,
    );
    if (item) {
      const statuses = Object.values(MissionApplicationReviewStatusNotSelected);
      for (const status of statuses) {
        if (unionNotSelected?.get(status)) {
          const subItem = item.subItems?.find((si) => si.value === status);
          onBulkMenuClick?.(application, item, subItem, false, false);
        }
      }
    }
  };

  const cleanUpOpportunityToUpdate = () => {
    const item = (options as BulkMenuItem[]).find(
      (o) => o.value === BulkMenuMainItems.OpportunityToUpdate,
    );
    if (item) {
      const statuses = Object.values(
        MissionApplicationReviewStatusOpportunityToUpdate,
      );
      for (const status of statuses) {
        if (unionOpportunityToUpdate?.get(status)) {
          const subItem = item.subItems?.find((si) => si.value === status);
          onBulkMenuClick?.(application, item, subItem, false, false);
        }
      }
    }
  };

  const cleanUpWaitlisted = () => {
    const item = (options as BulkMenuItem[]).find(
      (o) => o.value === BulkMenuMainItems.Waitlisted,
    );
    if (item) {
      const statuses = Object.values(MissionApplicationReviewStatusWaitlisted);
      for (const status of statuses) {
        if (unionWaitlisted?.get(status)) {
          const subItem = item.subItems?.find((si) => si.value === status);
          onBulkMenuClick?.(application, item, subItem, false, false);
        }
      }
    }
  };

  const onBulkMenuClickInternal = (item: BulkMenuItemOld | BulkMenuItem) => {
    setBulkMenuOpen(false);
    missions.resetCurrentApplication();
    onBulkMenuClick?.(application, item, undefined, undefined, true);
  };

  const onChangeSubmenu = (
    item: BulkMenuItemOld | BulkMenuItem,
    subItem: BulkSubMenuItemOld | BulkSubMenuItem,
    checked: boolean,
  ) => {
    if (auth.withMissionApplicationStatusV2) {
      setNotSelectedChanged(false);
      setOpportunityToUpdateChanged(false);
      setWaitlistedChanged(false);
      setOtherChanged(false);

      switch (item.value) {
        case BulkMenuMainItems.NotSelected:
          if (checked) {
            cleanUpOpportunityToUpdate();
            cleanUpWaitlisted();
          }
          setNotSelectedChanged(true);
          break;
        case BulkMenuMainItems.OpportunityToUpdate:
          if (checked) {
            cleanUpNotSelected();
            cleanUpWaitlisted();
          }
          setOpportunityToUpdateChanged(true);
          break;
        case BulkMenuMainItems.Waitlisted:
          if (checked) {
            cleanUpNotSelected();
            cleanUpOpportunityToUpdate();
            cleanUpWaitlisted();
          }
          setWaitlistedChanged(true);
          break;
        case BulkMenuMainItems.Other:
          setOtherChanged(true);
          break;
      }

      onBulkMenuClick?.(application, item, subItem, checked, false);
    } else {
      onBulkMenuClick?.(application, item, subItem, checked, false);

      if (item.value === MissionApplicationInternalStatus.Rejected) {
        setRejectionReasonChanged(true);
      } else {
        setLowCompetitivenessChanged(true);
      }
    }
  };

  const onBulkMenuClose = () => {
    setBulkMenuOpen(false);

    if (auth.withMissionApplicationStatusV2) {
      if (notSelectedChanged) {
        const item = (options as BulkMenuItem[]).find(
          (mi) => mi.value === BulkMenuMainItems.NotSelected,
        );
        if (item) {
          setNotSelectedChanged(false);
          missions.resetCurrentApplication();
          onBulkMenuClick?.(application, item, undefined, undefined, true);
        }
      }

      if (opportunityToUpdateChanged) {
        const item = (options as BulkMenuItem[]).find(
          (mi) => mi.value === BulkMenuMainItems.OpportunityToUpdate,
        );
        if (item) {
          setOpportunityToUpdateChanged(false);
          missions.resetCurrentApplication();
          onBulkMenuClick?.(application, item, undefined, undefined, true);
        }
      }

      if (waitlistedChanged) {
        const item = (options as BulkMenuItem[]).find(
          (mi) => mi.value === BulkMenuMainItems.Waitlisted,
        );
        if (item) {
          setWaitlistedChanged(false);
          missions.resetCurrentApplication();
          onBulkMenuClick?.(application, item, undefined, undefined, true);
        }
      }

      if (otherChanged) {
        const item = (options as BulkMenuItem[]).find(
          (mi) => mi.value === BulkMenuMainItems.Other,
        );
        if (item) {
          setOtherChanged(false);
          missions.resetCurrentApplication();
          onBulkMenuClick?.(application, item, undefined, undefined, true);
        }
      }
    } else {
      const item = (options as BulkMenuItemOld[]).find(
        (mi) => mi.value === MissionApplicationInternalStatus.New,
      );

      if (item && lowCompetitivenessChanged) {
        setLowCompetitivenessChanged(false);
        missions.resetCurrentApplication();
        onBulkMenuClick?.(application, item, undefined, undefined, true);
      }

      if (item && rejectionReasonChanged) {
        setRejectionReasonChanged(false);
        missions.resetCurrentApplication();
        onBulkMenuClick?.(application, item, undefined, undefined, true);
      }
    }
  };

  const isChecked = (item: BulkMenuItem, subItem: BulkSubMenuItem) => {
    switch (item.value) {
      case BulkMenuMainItems.NotSelected:
        return (unionNotSelected?.get(subItem.value) || 0) > 0;
      case BulkMenuMainItems.OpportunityToUpdate:
        return (unionOpportunityToUpdate?.get(subItem.value) || 0) > 0;
      case BulkMenuMainItems.Waitlisted:
        return (unionWaitlisted?.get(subItem.value) || 0) > 0;
      case BulkMenuMainItems.Other:
        if (
          subItem.value ===
            MissionApplicationReviewStatusOther.InterviewingFromFormation &&
          (proposalInterviews || 0) > 0
        ) {
          return true;
        } else {
          return (unionOther?.get(subItem.value) || 0) > 0;
        }
    }
  };

  const isIndeterminate = (item: BulkMenuItem, subItem: BulkSubMenuItem) => {
    let indeterminate = false;
    const notSelectedCount = unionNotSelected?.get(subItem.value) || 0;
    const opportunityToUpdateCount =
      unionOpportunityToUpdate?.get(subItem.value) || 0;
    const waitlistedCount = unionWaitlisted?.get(subItem.value) || 0;
    const otherCount = unionOther?.get(subItem.value) || 0;

    switch (item.value) {
      case BulkMenuMainItems.NotSelected:
        indeterminate =
          notSelectedCount > 0 &&
          notSelectedCount !== countSelectedApplications;
        break;
      case BulkMenuMainItems.OpportunityToUpdate:
        indeterminate =
          opportunityToUpdateCount > 0 &&
          opportunityToUpdateCount !== countSelectedApplications;
        break;
      case BulkMenuMainItems.Waitlisted:
        indeterminate =
          waitlistedCount > 0 && waitlistedCount !== countSelectedApplications;
        break;
      case BulkMenuMainItems.Other:
        if (
          subItem.value ===
          MissionApplicationReviewStatusOther.InterviewingFromFormation
        ) {
          indeterminate =
            (proposalInterviews || 0) > 0 &&
            proposalInterviews !== countSelectedApplications;
        } else {
          indeterminate =
            otherCount > 0 && otherCount !== countSelectedApplications;
        }
        break;
    }

    return indeterminate;
  };

  const isInterviewingFromClient = (subItem: BulkSubMenuItem) => {
    return (
      subItem.value ===
        MissionApplicationReviewStatusOther.InterviewingFromFormation &&
      (proposalInterviews || 0) > 0
    );
  };

  const missingBufferedRequirements = useMemo(() => {
    return (application.missingRequirements || []).filter(
      (r) =>
        r !== MissionMissingRequirement.StrictAvailabilityMismatch &&
        r !== MissionMissingRequirement.StrictRateMismatch &&
        r !== MissionMissingRequirement.StrictWorkingHoursMismatch &&
        r !== MissionMissingRequirement.StrictSkillsMismatch,
    );
  }, [application.missingRequirements]);

  return (
    <div
      className={cx(styles.applicantPill)}
      draggable={draggable}
      onDragStart={onDragStart}
    >
      <div
        className={cx(
          styles.applicantPillInner,
          styles[
            getApplicationStatusStage(
              application,
              auth.withMissionApplicationStatusV2,
            )
          ],
        )}
      >
        {setSelected ? (
          <Checkbox
            checked={selected}
            onChange={(e) => setSelected(e.target.checked)}
            margin="none"
            className={styles.checkbox}
          />
        ) : null}
        <UserAvatar
          badgesInline
          src={application.user.profilePictureURL}
          badges={application.user.badges}
          communityBadges={application.user.communityBadges}
          badgesPosition={BadgesPosition.BeforeName}
          size={24}
          containerStyle={{ marginRight: '8px' }}
          onClick={onClickInternal}
          lastScheduledVettingInterviewDate={
            application.user.lastScheduledVettingInterviewDate
              ? new Date(application.user.lastScheduledVettingInterviewDate)
              : undefined
          }
        />
        <div onClick={onClickInternal} style={{ display: 'flex' }}>
          {application.user.fullName}
          <UserSup
            type={application.user.type}
            scrubbed={application.user.scrubbed}
            isVetted={isUserFullyVetted(application.user)}
          />
          {isApplicationEdited ? (
            <Tag className={styles.tagEdited}>Edited</Tag>
          ) : null}
        </div>
        {onBulkMenuClick ? (
          <>
            <div
              style={{
                marginLeft: Spacing.small,
                marginRight: Spacing.small,
                cursor: 'pointer',
              }}
              onClick={(e) => {
                setSelected?.(true);
                setBulkMenuOpen((prevValue) => !prevValue);
                e?.preventDefault();
                e?.stopPropagation();
              }}
            >
              <Icon
                type={IconType.DropdownOpen}
                size={'xxsmall'}
                title="Bulk Status Update"
                style={{ cursor: 'pointer' }}
              />
            </div>
            {auth.withMissionApplicationStatusV2 ? (
              <Dropdown
                open={bulkMenuOpen}
                onClose={onBulkMenuClose}
                className={styles.dropdownContainer}
              >
                {(options as BulkMenuItem[]).map((item) => {
                  return (
                    <DropdownItem
                      key={item.value}
                      nestedItems={
                        item.subItems &&
                        item.subItems.map((subItem) => {
                          return (
                            <DropdownItem
                              key={subItem.value}
                              disabled={isInterviewingFromClient(subItem)}
                            >
                              <LabeledCheckboxInput
                                className={styles.checkbox}
                                label={
                                  isInterviewingFromClient(subItem)
                                    ? 'Interviewing (Client)'
                                    : subItem.label
                                }
                                margin="none"
                                checked={isChecked(item, subItem)}
                                indeterminate={isIndeterminate(item, subItem)}
                                onChange={(e) =>
                                  onChangeSubmenu(
                                    item,
                                    subItem,
                                    e.target.checked,
                                  )
                                }
                                disabled={isInterviewingFromClient(subItem)}
                              />
                            </DropdownItem>
                          );
                        })
                      }
                      onClick={() =>
                        !item.subItems && onBulkMenuClickInternal(item)
                      }
                    >
                      {item.label}
                    </DropdownItem>
                  );
                })}
              </Dropdown>
            ) : (
              <Dropdown
                open={bulkMenuOpen}
                onClose={onBulkMenuClose}
                className={styles.dropdownContainer}
              >
                {options.map((item) => {
                  return (
                    <DropdownItem
                      key={item.value}
                      nestedItems={
                        item.subItems &&
                        item.subItems.map((subItem) => {
                          return (
                            <DropdownItem key={subItem.value}>
                              {
                                <LabeledCheckboxInput
                                  className={styles.checkbox}
                                  label={subItem.label}
                                  margin="none"
                                  checked={
                                    item.value ===
                                    MissionApplicationInternalStatus.Rejected
                                      ? (unionRejectionReason?.get(
                                          subItem.value,
                                        ) || 0) > 0
                                      : (unionLowCompetitiveness?.get(
                                          subItem.value,
                                        ) || 0) > 0
                                  }
                                  indeterminate={
                                    item.value ===
                                    MissionApplicationInternalStatus.Rejected
                                      ? (unionRejectionReason?.get(
                                          subItem.value,
                                        ) || 0) > 0 &&
                                        (unionRejectionReason?.get(
                                          subItem.value,
                                        ) || 0) !== countSelectedApplications
                                      : (unionLowCompetitiveness?.get(
                                          subItem.value,
                                        ) || 0) > 0 &&
                                        (unionLowCompetitiveness?.get(
                                          subItem.value,
                                        ) || 0) !== countSelectedApplications
                                  }
                                  onChange={(e): void =>
                                    onChangeSubmenu(
                                      item,
                                      subItem,
                                      e.target.checked,
                                    )
                                  }
                                />
                              }
                            </DropdownItem>
                          );
                        })
                      }
                      onClick={() =>
                        !item.subItems && onBulkMenuClickInternal(item)
                      }
                    >
                      {item.label}
                    </DropdownItem>
                  );
                })}
              </Dropdown>
            )}
          </>
        ) : null}
      </div>
      {(missingBufferedRequirements.length || 0) > 0 && (
        <div className={styles.invalidApplicantPillIndicator}>
          <TooltipWrapped
            position={'top'}
            arrow
            html={
              <>
                {missingBufferedRequirements
                  .map((requirement) => MissingRequirementLabel[requirement])
                  .join(', ') || ''}
              </>
            }
          >
            <Icon type={IconType.CloseRedCircle} />
          </TooltipWrapped>
        </div>
      )}
    </div>
  );
}
