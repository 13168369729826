import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import uniqueId from 'lodash/uniqueId';
import { Spacing } from '@ateams/components';
import { Card } from '../../components/card';
import { TextInput } from '../../components/text-input';
import { InputLabel, Text } from '../../components/typography';
import { VettingFeedbackFormDefaults } from '@ateams/api/dist/endpoints/vetting-process-feedback';
import { VettingProcessFeedbackProject } from '@a_team/models/dist/vetting-processes/feedback';
import { Button } from '../../components/button';
import { PlusIcon } from '../../components/icons/plus';
import { Project, VettingProcessFeedbackProjectWithId } from './project';
import { Separator } from '../../components/separator';

const useStyles = createUseStyles({
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  marginLeft: {
    marginLeft: '16px',
  },
  projectsContainer: {
    display: 'inline-flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '14px',
  },
});

export type VettingFeedbackFormProjects = {
  projects: VettingFeedbackFormDefaults['answers']['projects'];
  biggestImpactDescription: VettingFeedbackFormDefaults['answers']['biggestImpactDescription'];
};

export type OnVettingFeedbackFormProjectsChange = (data: {
  projects?: VettingFeedbackFormDefaults['answers']['projects'];
  biggestImpactDescription?: VettingFeedbackFormDefaults['answers']['biggestImpactDescription'];
}) => void;

export interface VettingFeedbackFormProjectsProps {
  defaultValues: VettingFeedbackFormProjects;
  onChange?: OnVettingFeedbackFormProjectsChange;
  isReadOnly?: boolean;
  className?: string;
}

export function getEmptyProject(): VettingProcessFeedbackProjectWithId {
  return {
    id: uniqueId(),
    isEditing: true,
    companyName: '',
    impactOrRole: '',
    description: '',
    skills: [],
    industries: [],
  };
}

function addIdToProject(
  project: VettingProcessFeedbackProject,
): VettingProcessFeedbackProjectWithId {
  return { ...project, id: uniqueId(), isEditing: false };
}

export const VettingFeedbackFormProjectsForm: React.FC<VettingFeedbackFormProjectsProps> =
  React.memo((props) => {
    const { isReadOnly } = props;
    const styles = useStyles();
    const [projects, setProjects] = useState(
      props.defaultValues.projects.map(addIdToProject),
    );
    const [biggestImpactDescription, setBiggestImpactDescription] = useState(
      props.defaultValues.biggestImpactDescription,
    );

    const onProjectChange = useCallback(
      (project: VettingProcessFeedbackProjectWithId) => {
        setProjects((projects) => {
          const newProjects = projects.map((curProject) =>
            curProject.id === project.id ? project : curProject,
          );

          props.onChange?.({ projects: newProjects });

          return newProjects;
        });
      },
      [props.onChange, setProjects],
    );

    const onAddProject = () => {
      const newProjects = [...projects, getEmptyProject()];

      setProjects(newProjects);
      props.onChange?.({ projects: newProjects });
    };

    const onProjectRemove = useCallback(
      (project: VettingProcessFeedbackProjectWithId) => {
        setProjects((projects) => {
          const newProjects = projects.filter(
            (curProject) => curProject.id !== project.id,
          );

          props.onChange?.({ projects: newProjects });
          return newProjects;
        });
      },
      [props.onChange, setProjects],
    );

    const onProjectEdit = useCallback(
      (project: VettingProcessFeedbackProjectWithId) => {
        setProjects((projects) => {
          return projects.map((curProject) =>
            curProject.id === project.id
              ? { ...project, isEditing: true }
              : curProject,
          );
        });
      },
      [setProjects],
    );

    const onBiggestImpactDescriptionChange = (
      biggestImpactDescription: string,
    ) => {
      setBiggestImpactDescription(biggestImpactDescription);
      props.onChange?.({ biggestImpactDescription });
    };

    return (
      <Card title={'Projects'} className={props.className}>
        {!props.isReadOnly && (
          <Text className={styles.marginBottom}>
            We want to understand the scale and scope of the projects this
            builder has worked on. Feel free to include details about their
            contribution to projects as well as any information about their
            leadership experience or if they were an early hire.
          </Text>
        )}

        <div className={cx(styles.projectsContainer, styles.marginBottom)}>
          {isReadOnly && !projects.length ? (
            <Text isReadOnly readOnlyText={'No projects have been added.'} />
          ) : (
            projects.map((project, i) => (
              <Project
                key={project.id}
                project={project}
                isEditing={project.isEditing}
                onProjectChange={onProjectChange}
                onProjectRemove={onProjectRemove}
                onProjectEdit={onProjectEdit}
                isReadOnly={isReadOnly}
              />
            ))
          )}
        </div>

        {!isReadOnly && (
          <Button
            endAdornment={<PlusIcon />}
            noColor
            onClick={onAddProject}
            className={styles.marginBottom}
          >
            Add Project
          </Button>
        )}

        <Separator direction={'horizontal'} space={Spacing.medium} />

        <InputLabel className={styles.marginBottom}>
          What are they most proud of?
        </InputLabel>
        {isReadOnly ? (
          <Text isReadOnly readOnlyText={'Nothing was added.'}>
            {biggestImpactDescription}
          </Text>
        ) : (
          <div className={styles.marginLeft}>
            <TextInput
              value={biggestImpactDescription}
              onChange={(e) => onBiggestImpactDescriptionChange(e.target.value)}
              placeholder={'E.g., Helped mentor junior employees...'}
              withLine={!isReadOnly}
              isReadOnly={isReadOnly}
            />
          </div>
        )}
      </Card>
    );
  });
