import React from 'react';

export const UpArrow: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" {...props}>
      <path
        d="M8.5 5L5 1.5L1.5 5"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DownArrow: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" {...props}>
      <path
        d="M1.5 1.5L5 5L8.5 1.5"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const RightArrow: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M3.3335 7.99967H12.6668M12.6668 7.99967L8.00016 12.6663M12.6668 7.99967L8.00016 3.33301"
        stroke="#B5B8BF"
        strokeWidth="1.33333"
      />
    </svg>
  );
};

export const LeftArrow: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M12.6668 8.00065H3.3335M3.3335 8.00065L8.00016 3.33398M3.3335 8.00065L8.00016 12.6673"
        stroke="#62646A"
        strokeWidth="1.06667"
      />
    </svg>
  );
};
