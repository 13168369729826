import { Button, TextColors } from '@ateams/components';
import {
  BasicConnectionObjectV2,
  ConnectionObjectV2,
  ConnectionType,
} from '@a_team/models/dist/ConnectionObject';
import { UserCardObject } from '@a_team/models/dist/UserObject';
import { useDeleteConnection } from '@src/rq/connections';
import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import Description from '../../partials/Description';
import Title from '../../partials/Title';
import cx from 'classnames';

interface DeleteConnectionProps {
  userToConnect: UserCardObject;
  connection: BasicConnectionObjectV2;
  onClose: (keepModalOpen?: boolean) => void;
  connectActionOnCompleted?: (connection: ConnectionObjectV2) => void;
  onConnectionAction: (connectionType?: ConnectionType) => void;
}

const useStyles = createUseStyles({
  buttonsWrapper: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'center',
    marginTop: 40,
  },
  button: {
    borderRadius: '4px',
    maxWidth: '160px',
    transition: 'background 0.3s ease',
  },
  neverMindButton: {
    color: TextColors.regular,
    '&:hover': {
      background: '#DADADC',
    },
  },
  removeButton: {
    color: TextColors.primary,
    '&:hover': {
      background: '#5900b3',
    },
  },
});

function DeleteConnection({
  userToConnect,
  connection,
  onClose,
  connectActionOnCompleted,
  onConnectionAction,
}: DeleteConnectionProps): ReactElement {
  const styles = useStyles();

  const { mutateAsync: deleteConnection, isLoading: isDeletingConnection } =
    useDeleteConnection(connectActionOnCompleted);

  const handleDeleteConnection = async (): Promise<void> => {
    onConnectionAction(connection.type);
    await deleteConnection({ connection });
    onClose(true);
  };

  return (
    <div>
      <Title text="Remove this connection?" />

      <Description
        text={`You can always send ${userToConnect.firstName} another connection request in the future.`}
      />

      <div className={styles.buttonsWrapper}>
        <Button
          className={cx(styles.button, styles.neverMindButton)}
          onClick={() => onClose(true)}
          size="small"
          color="regularLight"
          disabled={isDeletingConnection}
        >
          Never mind
        </Button>
        <Button
          className={cx(styles.button, styles.removeButton)}
          onClick={handleDeleteConnection}
          size="small"
          loading={isDeletingConnection}
          color="secondaryDark"
          data-testing-id="confirm-remove-connection"
        >
          Remove
        </Button>
      </div>
    </div>
  );
}

export default observer(DeleteConnection);
