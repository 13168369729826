import { numberWithCommas } from '@src/helpers/numbers';
import { useStores } from '@src/stores';
import { useMemo } from 'react';

function useGetMonthlyRetainerDataForCurrentUserRole() {
  const { missions, auth } = useStores();

  return useMemo(() => {
    const {
      isFullTimeRetainer = false,
      monthlyRate = 0,
      availability,
    } = missions.currentMission?.currentUserRole ?? {};
    const { billingPeriod } = missions.currentMission?.data ?? {};

    return {
      isFullTimeRetainer: auth.withMonthlyRetainerInvoicing
        ? isFullTimeRetainer
        : false,
      monthlyRate: `$${numberWithCommas(Math.floor(monthlyRate))}`,
      roleStartDate: availability?.date,
      roleEndDate: availability?.scheduledEndDate,
      billingPeriod,
    };
  }, [missions.currentMission?.currentUserRole, missions.currentMission?.data]);
}

export default useGetMonthlyRetainerDataForCurrentUserRole;
