import React, { ReactElement, useEffect, useState } from 'react';
import { useStores } from '@src/stores';
import MainLayout from '@src/layouts/Main';
import LoadingIndicator from '@src/components/LoadingIndicator';
import SectionHeading from '@src/components/SectionHeading';
import { Button as CallToActionButton } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { EmailTemplateObject } from '@a_team/models/dist/EmailTemplate';
import useLoadingState from '@src/hooks/useLoadingState';
import { observer } from 'mobx-react';

import { apiEmailTemplates } from '@ateams/api';

const useStyles = createUseStyles({
  pageWrapper: {
    display: 'flex',
    padding: 0,
  },
  sidebarContainer: {
    borderRight: '1px solid #e5e5e5',
    minHeight: '100vh',
    padding: '20px',
    width: '400px',
  },
  templatesContainer: {
    minHeight: '300px',
  },
  templatesContainerInner: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  templateSelector: {
    lineHeight: '30px',
    fontSize: '16px',
    cursor: 'pointer',
    color: '#fe630c',
    textDecoration: 'none',
    '&:hover': {
      color: '#fe630c',
    },
  },
  templatePreview: {
    padding: 0,
    width: '100%',
  },
});

const EmailTemplates = (): ReactElement => {
  const styles = useStyles();
  const { auth } = useStores();
  const [isSendLoading, setSendIsLoading] = useLoadingState(false);
  const [templates, setTemplates] = useState<EmailTemplateObject[]>([]);
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>('');
  const [selectedTemplate, setSelectedTemplate] = useState<string | undefined>(
    undefined,
  );
  const [selectedObjectStr, setSelectedObjectStr] = useState<string>('');

  const fetchAllTemplates = async () => {
    const allTemplates: EmailTemplateObject[] =
      await apiEmailTemplates.getAllTemplates(auth);
    setTemplates(allTemplates);
  };

  const fetchEmailTemplate = async (templateName: string) => {
    return await apiEmailTemplates.getTemplateByName(auth, templateName);
  };

  const sendEmailTemplate = async () => {
    setSendIsLoading(true);
    await apiEmailTemplates.sendEmailTemplate(
      auth,
      auth.email as string,
      selectedTemplateName + ' - Preview',
      selectedTemplate as string,
    );
    setSendIsLoading(false);
  };

  const onTemplateSelection = async (templateName: string) => {
    const template: EmailTemplateObject = await fetchEmailTemplate(
      templateName,
    );
    setSelectedTemplateName(template.templateName);
    setSelectedTemplate(template.templateHtml);
    setSelectedObjectStr(JSON.stringify(template.data, null, 2));
  };

  useEffect(() => {
    fetchAllTemplates();
  }, []);

  return (
    <MainLayout title="A.Team Email Templates" style={{ padding: 0 }}>
      <div className={styles.pageWrapper}>
        <div className={styles.sidebarContainer}>
          <>
            <SectionHeading>Templates</SectionHeading>
            <div className={styles.templatesContainer}>
              {templates.map((t) => (
                <div
                  key={t.templateName}
                  className={styles.templateSelector}
                  onClick={() => onTemplateSelection(t.templateName)}
                >
                  {t.templateName}
                </div>
              ))}
            </div>
            {selectedTemplateName && (
              <>
                <SectionHeading>Object</SectionHeading>
                <p className={styles.templatesContainer}>
                  <pre className={styles.templatesContainerInner}>
                    {selectedObjectStr}
                  </pre>
                </p>
                <CallToActionButton
                  onClick={() => sendEmailTemplate()}
                  style={{ width: 'auto' }}
                >
                  Send to my email
                </CallToActionButton>
                <LoadingIndicator
                  loading={isSendLoading}
                  successMsg={`Email Sent to ${auth.email}`}
                />
              </>
            )}
          </>
        </div>
        <div className={styles.templatePreview}>
          <iframe
            title="template-previewer"
            style={{ height: '100vh', width: '100%' }}
            frameBorder="0"
            srcDoc={selectedTemplate}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default observer(EmailTemplates);
