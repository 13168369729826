import React, { ReactElement, SyntheticEvent, useMemo } from 'react';
import cx from 'classnames';
import TextButton from '@src/components/TextButton';
import { createUseStyles } from 'react-jss';
import { Breakpoints, TextColors } from '@ateams/components';
import IconWithColor from '@src/components/IconWithColor';

interface Props {
  type: 'hide' | 'restore' | 'withdraw';
  onClick: () => void;
  className?: string;
}

const useStyles = createUseStyles({
  hiddenButton: {
    color: (props: Props) =>
      props.type === 'restore'
        ? TextColors.successLight
        : TextColors.dangerLight,
    minWidth: 180,
    padding: 0,
    display: 'flex',
    flex: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'end',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    hiddenButton: {
      position: 'relative',
      color: () => TextColors.regular, // this is to maintain the same level of specificity as mobile properties above
    },
  },
});

export default function ActionButton(props: Props): ReactElement {
  const { type, onClick, className } = props;
  const styles = useStyles(props);

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  const buttonProps = useMemo(() => {
    switch (type) {
      case 'hide':
        return {
          icon: 'EyeClosed',
          label: 'Not interested',
        };

      case 'restore':
        return {
          icon: 'Undo',
          label: 'Restore Mission',
        };

      case 'withdraw': {
        return {
          icon: 'Undo',
          label: 'Withdraw Application',
        };
      }
    }
  }, [type]);

  return (
    <TextButton
      className={cx(styles.hiddenButton, [className])}
      onClick={handleClick}
    >
      <IconWithColor
        type={buttonProps.icon}
        size={'exact'}
        style={{ marginRight: '0.5em' }}
        color={
          type === 'restore' ? TextColors.successLight : TextColors.dangerLight
        }
        defaultOnDesktop
      />
      {buttonProps.label}
    </TextButton>
  );
}
