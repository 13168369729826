import { ConnectionsModalSources } from '@ateams/analytics/dist/platform';
import { Breakpoints } from '@ateams/components';
import ConnectModalV2 from '@src/components/Modal/ConnectModalV2';
import useToggle from '@src/hooks/useToggle';
import { useStores } from '@src/stores';
import { ApplicationStep } from '@src/stores/Application';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import Navigation from './Navigation';

interface Props {
  autoConnectModal?: boolean;
  fixed?: boolean;
  className?: string;
  children?: ReactNode;
}

const useStyles = createUseStyles({
  container: {
    background: 'white',
    position: 'absolute',
    top: 50,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inner: {
    height: 'auto',
  },
  flex: {
    display: 'flex',
  },
  fullWidth: {
    width: '100%',
  },
  fixed: {
    position: 'fixed',
    zIndex: 999,
    top: 50,
    width: '100%',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      width: 'auto',
      justifyContent: 'flex-end',
      position: 'static',
    },
    fixed: {
      position: 'fixed',
      top: 'unset',
      width: 'calc(100% - 120px)',
    },
    inner: {
      height: ({ navigationVisible }: { navigationVisible: boolean }) =>
        navigationVisible ? 80 : 'auto',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
});

const TopBar = (props: Props) => {
  const { className, fixed, autoConnectModal } = props;
  const { users, missions, auth } = useStores();
  const { profile } = users;
  const [isConnectModalOpen, setConnectModalOpen] = useToggle(autoConnectModal);
  const isApplicationStep =
    users.profile?.application?.step === ApplicationStep.Application;
  const shouldShowConnectModal = autoConnectModal && !profile?.isCurrentUser;

  const navigationVisible =
    !!profile?.application ||
    users.isCurrentUser ||
    auth.isAdmin ||
    auth.canSwitchUser ||
    (!!profile?.cta && !auth.readonlyAccess);
  const styles = useStyles({ navigationVisible });

  return (
    <>
      {shouldShowConnectModal && auth.user && profile?.data && (
        <ConnectModalV2
          currentUser={auth.user}
          userToConnect={profile?.data}
          source={ConnectionsModalSources.UserProfile}
          open={isConnectModalOpen}
          onClose={setConnectModalOpen}
        />
      )}
      <div
        className={cx(styles.container, { [styles.fixed]: fixed }, className)}
      >
        {props.children}

        <div
          className={cx(styles.inner, {
            [styles.fullWidth]: Boolean(
              profile?.application && missions.currentMission,
            ),
            [styles.flex]: isApplicationStep,
          })}
        >
          <Navigation />
        </div>
      </div>
    </>
  );
};

export default observer(TopBar);
