import React, { FormEvent, ReactElement, useState } from 'react';
import { History } from 'history';
import { useStores } from '@src/stores';
import { SignInLocation } from '@src/locations';
import MainLayout from '@src/layouts/Main';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { createUseStyles } from 'react-jss';
import SectionHeading from '@src/components/SectionHeading';
import { Button as CallToActionButton } from '@ateams/components';
import { Icon, IconType } from '@ateams/components';

interface Props {
  history: History;
}

interface PasswordResetPayload {
  email: string;
}

const useStyles = createUseStyles({
  signInCTA: {
    width: '280px',
    position: 'absolute',
    bottom: '-27px',
  },
  form: {
    position: 'relative',
    backgroundColor: '#fff',
    padding: '24px',
    borderRadius: '1em',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.08)',
    width: '384px',
    margin: '5em auto 1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  email: {
    marginBottom: '72px',
  },
});

export default function ResetPasswordView(props: Props): ReactElement {
  const { history } = props;
  const { auth } = useStores();

  const [data, setData] = useState<Partial<PasswordResetPayload>>({});
  const [loading, setLoading, error] = useLoadingState();

  const isValid = loading !== true && data.email;

  const styles = useStyles();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleInputChange =
    (name: keyof PasswordResetPayload) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setData({ ...data, [name]: e.target.value });
    };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    setLoading(
      auth
        .requestPasswordReset(data as PasswordResetPayload)
        .then((): string => {
          return 'Email was sent.';
        }),
    );
  };

  const ignoreSubmit = (e: FormEvent): void => {
    e.preventDefault();
  };

  return (
    <MainLayout title="Reset Password" naked>
      <form
        onSubmit={isValid ? handleSubmit : ignoreSubmit}
        className={styles.form}
      >
        <SectionHeading
          isFirst
          style={{
            alignSelf: 'flex-start',
            marginBottom: 0,
            marginTop: '16px',
          }}
        >
          <Icon
            type={IconType.ArrowBack}
            style={{ marginRight: '16px', fontSize: '24px' }}
            onClick={(): void => history.push(SignInLocation)}
          />
          Reset Password
        </SectionHeading>
        <div style={{ marginTop: '16px' }}>
          Please enter your A·Team email address, and we will send a link to
          reset your password.
        </div>
        <OutlinedInput
          type="email"
          placeholder="Email..."
          name="email"
          onChange={handleInputChange('email')}
          value={data.email || ''}
          disabled={loading === true}
          required
          error={!!error}
          className={styles.email}
        />
        <CallToActionButton
          type="submit"
          disabled={!isValid}
          className={styles.signInCTA}
        >
          Reset Password
        </CallToActionButton>
        <LoadingIndicator loading={loading} />
      </form>
    </MainLayout>
  );
}
