import {
  AdminMissionApplicationObject,
  CurrentUserMissionApplicationStatus,
  ExclusiveStatus,
  MissionApplicationBadgeStatus,
  MissionApplicationInternalStatus,
  MissionApplicationReviewStatusNotSelected,
  MissionApplicationReviewStatusOther,
  MissionApplicationReviewStatusWaitlisted,
  MissionApplicationStatusStage,
  ProposedMethod,
} from '@a_team/models/dist/MissionApplicationObject';
import { AdminNotesMissionApplication } from '@a_team/models/dist/AdminNotesObject';
import { UserBadge } from '@a_team/models/dist/UserObject';
import { MissionApplicationData } from '@a_team/models/src/MissionApplicationObject';
import { ClientRoleQuestion } from '@a_team/models/dist/MissionRole';

export const PROPOSED_BUT_NOT_SHARED = 'ProposedButNotShared';

export const getApplicationStatusStage = (
  application: AdminMissionApplicationObject | AdminNotesMissionApplication,
  withMissionApplicationStatusV2: boolean,
): MissionApplicationBadgeStatus | string => {
  if (withMissionApplicationStatusV2) {
    if (application.accepted) {
      return MissionApplicationStatusStage.Accepted;
    }

    if (
      application.reviewStatus?.other?.includes(
        MissionApplicationReviewStatusOther.Unavailable,
      )
    ) {
      return MissionApplicationStatusStage.NotAvailable;
    }

    if (application.exclusiveStatus) {
      return MissionApplicationStatusStage[application.exclusiveStatus];
    }

    if ((application.reviewStatus?.notSelected?.length || 0) > 0) {
      if (
        application.reviewStatus?.notSelected?.includes(
          MissionApplicationReviewStatusNotSelected.AfterInterview,
        ) ||
        application.reviewStatus?.notSelected?.includes(
          MissionApplicationReviewStatusNotSelected.AfterProposal,
        )
      ) {
        return MissionApplicationStatusStage.Rejected;
      } else {
        return CurrentUserMissionApplicationStatus.LowCompetitiveness;
      }
    }

    if ((application.reviewStatus?.opportunityToUpdate?.length || 0) > 0) {
      return CurrentUserMissionApplicationStatus.OpportunityToUpdate;
    }

    if (
      application.proposalInterviewing ||
      application.reviewStatus?.other?.includes(
        MissionApplicationReviewStatusOther.InterviewingFromFormation,
      )
    ) {
      return CurrentUserMissionApplicationStatus.Interviewing;
    }

    if (
      application.proposal ||
      (application.reviewStatus?.other?.includes(
        MissionApplicationReviewStatusOther.PresentedToClient,
      ) &&
        !application.proposalManuallySharedAt)
    ) {
      return PROPOSED_BUT_NOT_SHARED;
    }

    if (
      application.reviewStatus?.other?.includes(
        MissionApplicationReviewStatusOther.PresentedToClient,
      )
    ) {
      return MissionApplicationStatusStage.Proposed;
    }

    if (
      application.reviewStatus?.waitlisted?.includes(
        MissionApplicationReviewStatusWaitlisted.WaitlistedGood,
      )
    ) {
      return MissionApplicationStatusStage.ShortlistGood;
    } else if (
      application.reviewStatus?.waitlisted?.includes(
        MissionApplicationReviewStatusWaitlisted.WaitlistedStrong,
      )
    ) {
      return MissionApplicationStatusStage.ShortlistStrong;
    }

    const hasVettingScheduled =
      (application as AdminMissionApplicationObject).user?.badges.includes(
        UserBadge.VettingScheduled,
      ) ||
      (application as AdminMissionApplicationObject).user?.badges.includes(
        UserBadge.VettingInterviewDate,
      );

    return hasVettingScheduled
      ? MissionApplicationStatusStage.VettingScheduled
      : MissionApplicationStatusStage.New;
  } else {
    if (application.accepted) return MissionApplicationStatusStage.Accepted;
    if (
      application.internalStatus ===
      MissionApplicationInternalStatus.NotAvailable
    )
      return MissionApplicationStatusStage.NotAvailable;
    if (
      application.internalStatus === MissionApplicationInternalStatus.Rejected
    )
      return MissionApplicationStatusStage.Rejected;
    if (application.exclusiveStatus)
      return MissionApplicationStatusStage[application.exclusiveStatus];
    if (
      application.proposalInterviewing ||
      application.internalStatus ===
        MissionApplicationInternalStatus.Interviewing
    )
      return CurrentUserMissionApplicationStatus.Interviewing;
    if (
      application.proposedToClient === ProposedMethod.Proposal ||
      (application.proposedToClient === ProposedMethod.Manual &&
        !application.proposalManuallySharedAt)
    )
      return PROPOSED_BUT_NOT_SHARED;
    if (application.proposedToClient === ProposedMethod.Manual)
      return MissionApplicationStatusStage.Proposed;
    if ((application.lowCompetitiveness?.length || 0) > 0)
      return CurrentUserMissionApplicationStatus.LowCompetitiveness;
    if (
      application.internalStatus ===
      MissionApplicationInternalStatus.ProbablyNot
    )
      return CurrentUserMissionApplicationStatus.LowCompetitiveness;

    if (application.internalStatus !== MissionApplicationInternalStatus.New)
      return MissionApplicationStatusStage[application.internalStatus];

    const hasVettingScheduled = (
      application as AdminMissionApplicationObject
    ).user?.badges.includes(UserBadge.VettingScheduled);

    return hasVettingScheduled
      ? MissionApplicationStatusStage.VettingScheduled
      : MissionApplicationStatusStage.New;
  }
};

enum ApplicationStatusOrderDeprecated {
  New = 0,
  Interviewing = 1,
  ProposedShared = 2,
  ProposedNotShared = 3,
  ShortlistedStrong = 4,
  ShortlistedGood = 5,
  LowCompetitiveness = 6,
  Unavailable = 7,
  Rejected = 8,
}

enum ApplicationStatusOrder {
  New = 0,
  Accepted = 1,
  Exclusive = 2,
  Interviewing = 3,
  ProposedShared = 4,
  ProposedNotShared = 5,
  WaitlistedStrong = 6,
  WaitlistedGood = 7,
  OpportunityToUpdate = 8,
  EvaluationCallPending = 9,
  NotSelected = 10,
  OnHold = 11,
  Unavailable = 12,
}

enum UserBadgeOrder {
  SelectionTeam = 0,
  BeenOnMission = 1,
  ExceptionalATeamer = 2,
  ATeamerResidence = 3,
  VettingScheduled = 4,
  HighPotential = 5,
  ATeamer = 6,
  NoBadge = 7,
  UnqualifiedUser = 8,
  LimitedAccess = 9,
}

export enum ApplicantsSortByOptions {
  ApplicationStatus = 'ApplicationStatus',
  DateOfSubmission = 'Date of Submission',
}

function getApplicationStatusOrder(
  application: AdminMissionApplicationObject,
  withMissionApplicationStatusV2: boolean,
): number {
  if (withMissionApplicationStatusV2) {
    if (application.accepted) {
      return ApplicationStatusOrder.Accepted;
    } else if (application.exclusiveStatus === ExclusiveStatus.Exclusive) {
      return ApplicationStatusOrder.Exclusive;
    } else if (application.exclusiveStatus === ExclusiveStatus.OnHold) {
      return ApplicationStatusOrder.OnHold;
    } else if (application.vettingScheduled) {
      return ApplicationStatusOrder.Interviewing;
    } else if (
      application.reviewStatus?.waitlisted?.includes(
        MissionApplicationReviewStatusWaitlisted.WaitlistedStrong,
      )
    ) {
      return ApplicationStatusOrder.WaitlistedStrong;
    } else if (
      application.reviewStatus?.waitlisted?.includes(
        MissionApplicationReviewStatusWaitlisted.WaitlistedGood,
      )
    ) {
      return ApplicationStatusOrder.WaitlistedGood;
    } else if (
      application.reviewStatus?.other?.includes(
        MissionApplicationReviewStatusOther.Unavailable,
      )
    ) {
      return ApplicationStatusOrder.Unavailable;
    } else if (
      application.reviewStatus?.notSelected?.includes(
        MissionApplicationReviewStatusNotSelected.AfterInterview,
      ) ||
      application.reviewStatus?.notSelected?.includes(
        MissionApplicationReviewStatusNotSelected.AfterProposal,
      )
    ) {
      return ApplicationStatusOrder.NotSelected;
    } else if (
      application.reviewStatus?.other?.includes(
        MissionApplicationReviewStatusOther.InterviewingFromFormation,
      ) ||
      application.proposalInterviewing
    ) {
      return ApplicationStatusOrder.Interviewing;
    } else if (
      application.user.badges.includes(UserBadge.VettingScheduled) ||
      application.user.badges.includes(UserBadge.VettingInterviewDate)
    ) {
      return ApplicationStatusOrder.EvaluationCallPending;
    } else if (
      (application.reviewStatus?.opportunityToUpdate?.length || 0) > 0
    ) {
      return ApplicationStatusOrder.OpportunityToUpdate;
    } else if ((application.reviewStatus?.notSelected?.length || 0) > 0) {
      return ApplicationStatusOrder.NotSelected;
    } else if (
      application.reviewStatus?.other?.includes(
        MissionApplicationReviewStatusOther.PresentedToClient,
      )
    ) {
      return ApplicationStatusOrder.ProposedShared;
    } else if (application.proposal) {
      return ApplicationStatusOrder.ProposedNotShared;
    } else {
      return ApplicationStatusOrder.New;
    }
  } else {
    if (
      application.internalStatus ===
      MissionApplicationInternalStatus.ShortlistStrong
    ) {
      return ApplicationStatusOrderDeprecated.ShortlistedStrong;
    } else if (
      application.internalStatus ===
      MissionApplicationInternalStatus.ShortlistGood
    ) {
      return ApplicationStatusOrderDeprecated.ShortlistedGood;
    } else if (
      application.internalStatus ===
      MissionApplicationInternalStatus.NotAvailable
    ) {
      return ApplicationStatusOrderDeprecated.Unavailable;
    } else if (
      application.internalStatus === MissionApplicationInternalStatus.Rejected
    ) {
      return ApplicationStatusOrderDeprecated.Rejected;
    } else if (
      application.internalStatus ===
        MissionApplicationInternalStatus.Interviewing ||
      application.proposalInterviewing
    ) {
      return ApplicationStatusOrderDeprecated.Interviewing;
    } else if ((application.lowCompetitiveness?.length || 0) > 0) {
      return ApplicationStatusOrderDeprecated.LowCompetitiveness;
    } else if (application.proposedToClient === ProposedMethod.Manual) {
      return ApplicationStatusOrderDeprecated.ProposedShared;
    } else if (application.proposedToClient === ProposedMethod.Proposal) {
      return ApplicationStatusOrderDeprecated.ProposedNotShared;
    } else if (
      application.internalStatus === MissionApplicationInternalStatus.New
    ) {
      return ApplicationStatusOrderDeprecated.New;
    }
  }

  return -1;
}

function getBadgeOrder(application: AdminMissionApplicationObject): number {
  const badge = application.user.badges.length
    ? application.user.badges[0]
    : null;

  if (!badge) {
    return UserBadgeOrder.NoBadge;
  }

  switch (badge) {
    case UserBadge.SelectionTeam:
      return UserBadgeOrder.SelectionTeam;
    case UserBadge.BeenOnMission:
      return UserBadgeOrder.BeenOnMission;
    case UserBadge.ExceptionalATeamer:
      return UserBadgeOrder.ExceptionalATeamer;
    case UserBadge.ATeamerResidence:
      return UserBadgeOrder.ATeamerResidence;
    case UserBadge.VettingScheduled:
      return UserBadgeOrder.VettingScheduled;
    case UserBadge.HighPotential:
      return UserBadgeOrder.HighPotential;
    case UserBadge.ATeamer:
      return UserBadgeOrder.ATeamer;
    case UserBadge.UnqualifiedUser:
      return UserBadgeOrder.UnqualifiedUser;
    default:
      return -1;
  }
}

export const getFilteredAndSortedApplications = (
  applications: AdminMissionApplicationObject[] | null | undefined,
  applicantsSortBy: ApplicantsSortByOptions,
  withMissionApplicationStatusV2: boolean,
): AdminMissionApplicationObject[] | null | undefined => {
  const tempApplications =
    applications?.filter((app) => !app.withdrawn) || null;

  tempApplications?.sort((a, b) => {
    let diff = 0;

    if (applicantsSortBy === ApplicantsSortByOptions.ApplicationStatus) {
      diff =
        getApplicationStatusOrder(a, withMissionApplicationStatusV2) -
        getApplicationStatusOrder(b, withMissionApplicationStatusV2);

      if (diff === 0) {
        diff = getBadgeOrder(a) - getBadgeOrder(b);
      }
    }

    if (diff === 0) {
      diff = new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    }

    return diff;
  });

  return tempApplications;
};

export const isMissionApplicationEdited = (
  application: AdminMissionApplicationObject | AdminNotesMissionApplication,
): boolean => {
  if (application?.changes?.lastReviewAt) {
    const lastReview: Date = new Date(application?.changes?.lastReviewAt || 0);
    const pitchUpdatedAt: Date = new Date(
      application.changes?.pitchUpdatedAt || 0,
    );
    const rateUpdatedAt: Date = new Date(
      application.changes?.rateUpdatedAt || 0,
    );
    const availabilityUpdatedAt: Date = new Date(
      application.changes?.availabilityUpdatedAt || 0,
    );
    const workingHoursUpdatedAt: Date = new Date(
      application.changes?.workingHoursUpdatedAt || 0,
    );
    const noteOnAvailabilityUpdatedAt: Date = new Date(
      application.changes?.noteOnAvailabilityUpdatedAt || 0,
    );
    const customQuestionRepliesUpdatedAt = (): {
      qid: string;
      edited: boolean;
    }[] => {
      const updateData =
        application?.changes?.customQuestionRepliesUpdatedAt || {};
      return Object.keys(updateData).map((key) => {
        return {
          qid: key,
          edited: new Date(updateData[key]) > lastReview,
        };
      });
    };

    return (
      pitchUpdatedAt > lastReview ||
      rateUpdatedAt > lastReview ||
      availabilityUpdatedAt > lastReview ||
      workingHoursUpdatedAt > lastReview ||
      noteOnAvailabilityUpdatedAt > lastReview ||
      customQuestionRepliesUpdatedAt().some((reply) => reply.edited)
    );
  } else {
    return false;
  }
};

export const getFieldsEdited = (
  applicationData: MissionApplicationData | undefined,
): FieldEditedData => {
  if (applicationData?.changes?.lastReviewAt) {
    const lastReview: Date = new Date(
      applicationData?.changes?.lastReviewAt || 0,
    );
    const pitchUpdatedAt: Date = new Date(
      applicationData?.changes?.pitchUpdatedAt || 0,
    );
    const aboutUpdatedAt: Date = new Date(
      applicationData?.changes?.aboutUpdatedAt || 0,
    );
    const rateUpdatedAt: Date = new Date(
      applicationData?.changes?.rateUpdatedAt || 0,
    );
    const availabilityUpdatedAt: Date = new Date(
      applicationData?.changes?.availabilityUpdatedAt || 0,
    );
    const workingHoursUpdatedAt: Date = new Date(
      applicationData?.changes?.workingHoursUpdatedAt || 0,
    );
    const noteOnAvailabilityUpdatedAt: Date = new Date(
      applicationData?.changes?.noteOnAvailabilityUpdatedAt || 0,
    );
    const customQuestionRepliesEdited = ():
      | ClientRoleQuestion['qid'][]
      | false => {
      const editedReplies: ClientRoleQuestion['qid'][] = [];
      const updateData =
        applicationData?.changes?.customQuestionRepliesUpdatedAt;

      if (!updateData || Object.keys(updateData).length === 0) {
        return false;
      }

      Object.keys(updateData).forEach((key) => {
        if (new Date(updateData[key]) > lastReview) {
          editedReplies.push(key);
        }
      });

      return editedReplies.length > 0 ? editedReplies : false;
    };

    return {
      pitchEdited: pitchUpdatedAt > lastReview,
      aboutEdited: aboutUpdatedAt > lastReview,
      rateEdited: rateUpdatedAt > lastReview,
      availabilityEdited: availabilityUpdatedAt > lastReview,
      workingHoursEdited: workingHoursUpdatedAt > lastReview,
      noteOnAvailabilityEdited: noteOnAvailabilityUpdatedAt > lastReview,
      customQuestionRepliesEdited: customQuestionRepliesEdited(),
    };
  } else {
    return {
      pitchEdited: false,
      aboutEdited: false,
      rateEdited: false,
      availabilityEdited: false,
      workingHoursEdited: false,
      noteOnAvailabilityEdited: false,
      customQuestionRepliesEdited: false,
    };
  }
};

export interface FieldEditedData {
  pitchEdited: boolean;
  aboutEdited: boolean;
  rateEdited: boolean;
  availabilityEdited: boolean;
  workingHoursEdited: boolean;
  noteOnAvailabilityEdited: boolean;
  customQuestionRepliesEdited: ClientRoleQuestion['qid'][] | false;
}

export const getApplicationSelectedUnion = <T>(
  selectedApplicants: string[],
  filteredApplications: AdminMissionApplicationObject[] | null | undefined,
  getArray: (a: AdminMissionApplicationObject) => T[] | undefined,
) => {
  const unionMap = new Map<T, number>();

  if (selectedApplicants && selectedApplicants.length > 0) {
    const selectedApplications = filteredApplications?.filter((a) =>
      selectedApplicants.includes(a.aid),
    );

    selectedApplications?.forEach((a) => {
      const array = getArray(a);
      if (array) {
        array.forEach((item) => {
          unionMap.set(item, (unionMap.get(item) || 0) + 1);
        });
      }
    });

    return unionMap;
  } else {
    return undefined;
  }
};
