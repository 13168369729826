import { Button, Input, Modal, Select } from '@a_team/ui-components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { z } from 'zod';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import HideScrollerGlobally from '@src/components/HideScrollerGlobally';
import { Breakpoints } from '@ateams/components';
import RequestBuildersIllustration from './request-builders-illustration.svg';
import { useCreateRequestBuilder } from '@src/rq/requestBuilders';
import RolesMultiSelect from './RolesMultiSelect';
import cx from 'classnames';
import planeIllustration from './plane-illustration.svg';

interface RequestBuildersModalProps {
  open: boolean;
  mid: string;
  onClose: () => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
    padding: 16,
  },
  head: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 24,
  },
  successContainer: {
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    margin: 0,
    textAlign: 'center',
  },
  desc: {
    fontSize: '15px',
    marginTop: 8,
    color: '#818388',
    textAlign: 'center',
    maxWidth: 400,
  },
  label: {
    fontSize: '15px',
    color: '#222',
    fontWeight: 500,
  },
  textArea: {
    borderRadius: 8,
    border: '1px solid #C0C0C0',
    padding: 16,
    height: 140,
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
    padding: 16,
    borderTop: '1px solid #DADADC',
    position: 'sticky',
    bottom: 0,
    background: '#fff',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  modal: {
    '& > div': {
      padding: 0,
    },
  },
  btn: {
    padding: '8px 12px !important',
    height: '40px !important',
  },
  labelDesc: {
    fontSize: '14px',
    color: '#62646A',
    marginTop: 8,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      minWidth: 680,
      borderRadius: '16px !important',
    },
    container: {
      padding: 40,
    },
  },
});

const urgencyOptions = [
  {
    label:
      'High — We need support immediately to prevent mission deliverable delays',
    value: 'high',
  },
  {
    label: 'Moderate — We need support soon to accomplish goals',
    value: 'moderate',
  },
  {
    label: 'Low — We need support in the next 1-2 months',
    value: 'low',
  },
];

const schema = z.object({
  urgency: z.enum(['high', 'moderate', 'low']),
  roles: z.array(z.string()),
  additionalTeammatesContribution: z.string(),
  suggestedBuilders: z.string().optional(),
});

export type FormData = z.infer<typeof schema>;

const RequestBuildersModal = ({
  open,
  onClose,
  mid,
}: RequestBuildersModalProps): JSX.Element => {
  const styles = useStyles();
  const {
    control,
    register,
    handleSubmit,
    trigger,
    setValue,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      urgency: undefined,
      roles: [],
      additionalTeammatesContribution: '',
      suggestedBuilders: '',
    },
  });

  const {
    mutate: createRequestBuilder,
    isLoading,
    isError,
  } = useCreateRequestBuilder();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    createRequestBuilder({
      urgency: data.urgency,
      roles: data.roles,
      additionalTeammatesContribution: data.additionalTeammatesContribution,
      suggestedBuilders: data.suggestedBuilders,
      mission: mid,
    });
  };

  return (
    <Modal
      variant="slideUp"
      isOpen={open}
      onClose={onClose}
      className={styles.modal}
      shouldHideGradientStroke={true}
    >
      {isSubmitSuccessful ? (
        <div className={cx(styles.container, styles.successContainer)}>
          <div className={styles.head}>
            <img src={planeIllustration} alt="Request Builders" />
            <div>
              <h4 className={styles.title}>
                Thanks for requesting a teammate.
              </h4>
              <p className={styles.desc}>
                We will review your request and discuss the possibility of
                adding more team members with the company.
              </p>
            </div>
          </div>
          <Button
            style={{ width: 120 }}
            className={styles.btn}
            onClick={onClose}
            disabled={isLoading}
          >
            Close
          </Button>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.container}>
            <div className={styles.head}>
              <img src={RequestBuildersIllustration} alt="Request Builders" />
              <div>
                <h4 className={styles.title}>Does your mission need help?</h4>
                <p className={styles.desc}>
                  Tell us the specific types of builders or expertise this
                  project needs and we'll try to secure them.
                </p>
              </div>
            </div>
            <div className={styles.inputGroup}>
              <label className={styles.label}>
                What is the urgency for this role?
              </label>
              <Controller
                name="urgency"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder={`Select the urgency level`}
                    value={urgencyOptions.find(
                      (option) => option.value === field.value,
                    )}
                    options={urgencyOptions}
                    onChange={(selectedOption) => {
                      const val = selectedOption as {
                        value?: string;
                        label: string;
                      };
                      field.onChange(val?.value);
                      trigger('urgency');
                    }}
                  />
                )}
              />
              {errors.urgency && <span>{errors.urgency.message}</span>}
            </div>
            <div className={styles.inputGroup}>
              <label className={styles.label}>
                What roles would the mission benefit from?
              </label>
              <RolesMultiSelect
                register={register}
                trigger={trigger}
                setValue={setValue}
                roles={watch('roles')}
              />
              {errors.roles && <span>{errors.roles.message}</span>}
            </div>
            <div className={styles.inputGroup}>
              <label className={styles.label}>
                How would additional teammates contribute to achieving the
                mission objectives?
              </label>
              <textarea
                className={styles.textArea}
                {...register('additionalTeammatesContribution')}
                placeholder="e.g. Adding another part time product designer would help to
              unblock engineering and allow us to deliver milestone one on time.
              Without a product designer, we will not be able to deliver
              milestone one on time."
              />
              {errors.additionalTeammatesContribution && (
                <span>{errors.additionalTeammatesContribution.message}</span>
              )}
            </div>
            <div className={styles.inputGroup}>
              <div>
                <label className={styles.label}>
                  Suggest builders (optional)
                </label>
                <div className={styles.labelDesc}>
                  If you know builders who would be a good fit, add their full
                  names or emails
                </div>
              </div>
              <Input
                {...register('suggestedBuilders')}
                placeholder={'Enter name or email address'}
                size="stretch"
              />
              {errors.roles && <span>{errors.roles.message}</span>}
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              className={styles.btn}
              variant="secondary"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              className={styles.btn}
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          </div>
          {isError && (
            <div>An error occurred while submitting the request.</div>
          )}
        </form>
      )}

      {open && <HideScrollerGlobally />}
    </Modal>
  );
};

export default RequestBuildersModal;
