import React, { ReactElement } from 'react';
import RcSlider, { SliderTooltip, HandleProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Colors, TextColors } from '../index';

export interface HandleWithTooltipProps extends HandleProps {
  shown?: boolean;
  dragging?: boolean;
  index: number;
  tipFormatter: (value: number) => string;
}

export const HandleWithTooltip = (
  props: HandleWithTooltipProps,
): ReactElement => {
  const { Handle } = RcSlider;
  const { value, dragging, shown, index, tipFormatter, ...restProps } = props;

  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={tipFormatter(value || 0)}
      visible={shown || dragging}
      placement="top"
      key={index}
      overlayInnerStyle={{
        marginTop: 5,
        marginBottom: 5,
        padding: '9px 12px',
        height: 30,
        background: Colors.backgroundWhite,
        color: TextColors.backgroundWhite,
      }}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};
