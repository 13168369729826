import React from 'react';
import { createUseStyles } from 'react-jss';
import MissionRole from '@a_team/models/dist/MissionRole';
import { TargeterLocation } from '@src/locations';
import { useStores } from '@src/stores';

import TextButton from '@src/components/TextButton';

interface Props {
  role: MissionRole;
}

const useStyles = createUseStyles({
  container: {},
});

const TeamGraphRoleSearchUrl = (props: Props) => {
  const styles = useStyles();
  const { role } = props;
  const { users, missions } = useStores();

  const onTargeterSearchClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const mission = missions.currentMission?.data;

    if (!mission) return;

    const url = `${TargeterLocation}?mid=${mission.mid}&type=outreach&role=${role.rid}`;

    window.open(url, '_blank');
    e.preventDefault();
    e.stopPropagation();

    if (mission) {
      users.trackTargeterSearchClicked(
        mission.mid,
        mission.title,
        mission.status,
      );
    }
  };

  return (
    <TextButton
      onClick={onTargeterSearchClick}
      className={styles.container}
      color="primary"
    >
      Targeter search
    </TextButton>
  );
};

export default TeamGraphRoleSearchUrl;
