import React, { ReactElement, SelectHTMLAttributes } from 'react';
import { createUseStyles } from 'react-jss';
import OutlinedControl, {
  OutlinedControlProps,
} from '@src/components/Inputs/OutlinedControl';
import caretIcon from '../OutlinedControl/caret.svg';

export interface DropdownInputProps
  extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'width'>,
    Omit<
      OutlinedControlProps,
      'input' | 'onClick' | 'onFocus' | 'onDoubleClick'
    > {
  background?: string;
}

const defaultProps = {
  background: '#fff',
};

const useStyles = createUseStyles({
  input: {
    '-webkit-appearance': 'none',
    fontFamily: '"Inter"',
    fontWeight: 300,
    fontSize: '1em',
    margin: '-1em 0',
    padding: '1em 0',
    border: 0,
    // adds caret icon to the end of the select
    background: (props: DropdownInputProps) =>
      `url(${caretIcon}) ${props.background} no-repeat 98.5% !important`,
    width: '100%',
    color: 'inherit',
    paddingRight: '1.5em',
  },
});

function DropdownInput(props: DropdownInputProps): ReactElement {
  const {
    className,
    style,
    width,
    onClick,
    onFocus,
    placeholder,
    children,
    value,
    dropdown,
    noBorder,
    ...left
  } = props;

  const styles = useStyles(props);

  return (
    <OutlinedControl
      className={className}
      style={style}
      width={width}
      noBorder={noBorder}
      input={
        <select
          className={styles.input}
          onClick={onClick}
          onFocus={onFocus}
          value={value ?? ''}
          {...left}
        >
          {placeholder ? (
            <option disabled value="">
              {placeholder}
            </option>
          ) : null}
          {children}
        </select>
      }
      dropdown={dropdown}
      {...left}
      onFocus={undefined}
      onDoubleClick={undefined}
    />
  );
}

DropdownInput.defaultProps = defaultProps;
export default DropdownInput;
