import React, { ReactElement, useState } from 'react';
import { useStores } from '@src/stores';
import UserObject, {
  AnonymousUserObject,
  BasicUserObject,
  UserType,
} from '@a_team/models/dist/UserObject';
import AsyncSelect from '@a_team/ui-components/lib/Select/AsyncSelect';
import {
  OnChangeVal,
  SelectOption,
  theme,
  Typography,
} from '@a_team/ui-components';
import UserAvatar from '../UserAvatar';
import { components } from 'react-select';
import caret from './caret.svg';

export interface ProjectUserSelectorProps {
  user?: BasicUserObject | UserObject | AnonymousUserObject;
  placeholder?: string;
  onSelect(user: AnonymousUserObject | BasicUserObject | null): void;
  includeClientUsers?: boolean;
  disabled?: boolean;
  menuMaxHeight?: number;
}

const ProjectUserSelector = (props: ProjectUserSelectorProps): ReactElement => {
  const { user, onSelect, disabled } = props;
  const {
    users: { getUsersByString, adminQueryByString },
    auth,
  } = useStores();

  const [selectedUser, setSelectedUser] = useState<
    BasicUserObject | AnonymousUserObject | undefined | null
  >(user);

  const getUsers = async (
    query: string,
    includeCompanyUsers = false,
  ): Promise<Array<AnonymousUserObject | BasicUserObject | null>> => {
    if (query !== '') {
      const result = auth.isAdmin
        ? await adminQueryByString(query)
        : await getUsersByString(query);
      const filtered = result.filter(
        (user) =>
          (includeCompanyUsers && user.type === UserType.CompanyUser) ||
          user.type === UserType.User,
      );
      return filtered;
    }
    return [];
  };

  const loadOptionsAsync = (query: string): Promise<Array<SelectOption>> => {
    return getUsers(query).then((res) => {
      return res
        .filter((userRes) =>
          userRes?.fullName?.toLowerCase().includes(query.toLowerCase()),
        )
        .map((user) => {
          return { value: user?.username, label: user?.fullName, user };
        }) as SelectOption[];
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const UserOption = (props: any): ReactElement => {
    if (!props.hasValue && !props.data.user) {
      return <span />;
    }
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', padding: 5 }}>
          <UserAvatar src={props.data.user.profilePictureURL} size={32} />
          <div
            style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}
          >
            <div>
              <Typography variant={'textMedium'}>
                {props.data.user.fullName}
              </Typography>
            </div>
          </div>
        </div>
      </components.Option>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const UserValue = (props: any): ReactElement => {
    if (!props.hasValue) {
      return <span />;
    }
    const value = props.getValue()[0];
    return (
      <div style={props.getStyles('singleValue', props)} {...props.innerProps}>
        <div style={{ display: 'flex', padding: 5 }}>
          <UserAvatar src={value.profilePictureURL} size={21} />
          <div style={{ marginLeft: 10 }}>{value.fullName}</div>
        </div>
      </div>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const DropdownIndicator = (props: any) => {
    return (
      <div
        style={props.getStyles('dropdownIndicator', props)}
        {...props.innerProps}
      >
        <img src={caret} alt="drop icon" />
      </div>
    );
  };

  return (
    <AsyncSelect
      isDisabled={disabled}
      alwaysShowCounter={false}
      isMulti={false}
      value={selectedUser}
      loadOptions={loadOptionsAsync}
      placeholder={props.placeholder || 'Add a person...'}
      onChange={(val: OnChangeVal) => {
        if (Array.isArray(val)) return;
        if (!val) return;
        setSelectedUser(val.user);
        onSelect(val.user);
      }}
      components={{
        Option: UserOption,
        SingleValue: UserValue,
        DropdownIndicator,
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          borderColor: theme.colors.Grey['400'],
        }),
        menu: (base) => ({
          ...base,
          maxHeight: props.menuMaxHeight ?? '200px',
          overflow: 'hidden',
        }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 20000,
          color: theme.colors.Grey['800'],
        }),
      }}
      menuPortalTarget={document.body}
    />
  );
};

export default ProjectUserSelector;
