import { Stores } from '../stores';
import { match } from 'react-router-dom';

export interface ApplicationMatchParams {
  mid: string;
  rid: string;
  aid?: string;
}

export type ApplicationMatch = match<ApplicationMatchParams>;

// exported functions

export function loadApplication(
  stores: Stores,
  match: ApplicationMatch,
): Promise<unknown> {
  const { rid, mid, aid } = match.params;
  const { users } = stores;

  if (!mid) {
    return Promise.resolve();
  }

  return users.fetchApplication({ rid, mid, aid });
}
