import React from 'react';
import { createUseStyles } from 'react-jss';
import condensed2BetaBadges from './images/condensed2BetaBadges.svg';
import condensed3BetaBadges from './images/condensed3BetaBadges.svg';
import condensed4BetaBadges from './images/condensed4BetaBadges.svg';
import condensed2GuildBadges from './images/condensed2GuildBadges.svg';
import condensed3GuildBadges from './images/condensed3GuildBadges.svg';
import condensed4GuildBadges from './images/condensed4GuildBadges.svg';

interface CondensedBadgeProps {
  type: 'beta' | 'guild';
  total: number;
}

const useStyles = createUseStyles({
  condensedBadgeWrapper: {
    display: 'flex',
  },
});

const condensedBadgeImage = (type: 'beta' | 'guild', total: number) => {
  if (type === 'beta') {
    switch (total) {
      case 2:
        return condensed2BetaBadges;
      case 3:
        return condensed3BetaBadges;
      case 4:
        return condensed4BetaBadges;
    }
  }

  if (type === 'guild') {
    switch (total) {
      case 2:
        return condensed2GuildBadges;
      case 3:
        return condensed3GuildBadges;
      case 4:
        return condensed4GuildBadges;
    }
  }

  return;
};

const CondensedBadge: React.FC<CondensedBadgeProps> = ({ type, total }) => {
  const styles = useStyles();

  return (
    <div className={styles.condensedBadgeWrapper}>
      <img width={24} src={condensedBadgeImage(type, total)} alt="" />
    </div>
  );
};

export default CondensedBadge;
