import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import UserAvatar from '@src/components/UserAvatar';
import Skeleton from 'react-loading-skeleton';
import cx from 'classnames';

interface Props {
  username: string;
  profilePictureURL: string;
  itemClassName?: string;
  isLoading?: boolean;
}

const useStyles = createUseStyles({
  listItem: {
    listStyle: 'none',
    margin: 0,
    cursor: 'pointer',
    alignItems: 'center',
  },
  userAvatarSkeleton: {
    width: 24,
    height: 24,
    lineHeight: 1,
  },
});

const TargeterSuggestedTeamCardConnectionItem = (
  props: Props,
): ReactElement => {
  const styles = useStyles();
  const { username, profilePictureURL, isLoading } = props;

  return (
    <li className={cx(styles.listItem, props.itemClassName)}>
      <div style={{ display: 'flex' }}>
        {isLoading ? (
          <Skeleton
            circle
            width="100%"
            height="100%"
            containerClassName={styles.userAvatarSkeleton}
          />
        ) : (
          <a
            href={`/${username}`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <UserAvatar src={profilePictureURL} size={24} />
          </a>
        )}
      </div>
    </li>
  );
};

export default TargeterSuggestedTeamCardConnectionItem;
