import { FieldError } from 'react-hook-form';

export const ErrorMessages: Record<string, string> = {
  required: 'This field is required',
  minLength: 'This field is too short',
};

export const FieldErrorToMessageList = (
  error?: Partial<FieldError>,
): string[] => {
  if (!error || !error.type) return [];
  return [ErrorMessages[error.type] || error.message || 'Unknown error'];
};
