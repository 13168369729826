import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Location } from 'history';
import { useStores } from '@src/stores';
import AboutMission from './AboutMission';
import { loadMission, MissionMatch } from '@src/url-loaders/loadMission';
import { Redirect } from 'react-router-dom';
import {
  MissionTimeTrackingLocation,
  MissionControlBase,
} from '@src/locations';

interface Props {
  location: Location;
  match: MissionMatch;
}

export type Tab = {
  id: string;
  to?: string;
  label: string | ReactNode;
  disabled?: boolean;
};

export const missionPageViewLoader = loadMission;

function MissionPageView(props: Props): ReactElement {
  const { match } = props;
  const stores = useStores();

  const {
    missions: { currentMission },
  } = stores;

  if (
    currentMission?.currentUserRole &&
    !currentMission.timeTrackingTabDisabled
  ) {
    return (
      <Redirect
        to={
          match.params.mid
            ? MissionTimeTrackingLocation(match.params.mid)
            : MissionControlBase
        }
      />
    );
  }

  return (
    <>
      <AboutMission match={match} />
    </>
  );
}

export default observer(MissionPageView);
