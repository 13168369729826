import React, { ReactElement, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import TargeterSuggestedTeamCardListItem from './TeamCardListItem';
import { Card } from '@a_team/ui-components';
import { Colors, Icon, IconType, TextColors } from '@ateams/components';
import TextButton from '@src/components/TextButton';
import {
  SuggestedTeam,
  SuggestedTeamMemberObject,
  TeamCardUserTooltipGroup,
} from '@a_team/models/dist/TeamGraphObject';
import TargeterSuggestedTeamCardConnectionItem from './TeamCardConnectionItem';
import { uniq, uniqBy } from 'lodash';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import TeamCardUserTooltip from '@src/views/SkillTargeter/SuggestedTeams/TeamCardUserTooltip';

interface Props {
  rolesCount: number;
  team: SuggestedTeam;
  onSelectTeam: () => void;
  className?: string;
  isLoading?: boolean;
}

const useStyles = createUseStyles({
  cardContainer: {},
  card: {
    maxWidth: 596,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: `0px 2px 20px rgba(0, 0, 0, 0.08)`,
    borderRadius: 8,
    overflow: 'hidden',
    background: Colors.backgroundWhite,
    margin: 0,
    borderWidth: 0,
    paddingTop: '0px !important',
    paddingBottom: '16px',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.backgroundLight,
    height: 40,
  },
  teamCompletenessIcon: {
    marginLeft: 8,
    cursor: 'pointer',
  },
  teamCompleteness: (props) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: 8,
    color:
      props.rolesCount === Object.keys(props.team).length
        ? TextColors.secondaryLight
        : TextColors.regular,
    alignItems: 'center',
  }),
  teamCompletenessLabel: {
    fontWeight: 400,
    paddingLeft: 8,
  },
  members: {
    paddingLeft: 16,
    margin: 0,
  },
  connections: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    paddingLeft: 0,
    margin: 0,
  },
  selectTeamButton: {
    color: TextColors.primaryLight,
    paddingRight: 8,
  },
  teamNarrativesTitle: {
    color: '#818388',
    paddingLeft: 16,
    paddingTop: 26,
  },
  teamNarrativesSection: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 16,
    paddingTop: 12,
    paddingRight: 16,
    justifyContent: 'space-between',
  },
  teamNarrativesSectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
  },
});

function getFilteredConnections(
  team: Record<string, SuggestedTeamMemberObject>,
  connectionRole: string | null,
) {
  const users = Object.values(team);

  let userIds: string[] = [];

  users.forEach(
    (user) =>
      (userIds = userIds.concat(
        (user.connections || [])
          .filter(
            (c) =>
              c.connectionRole === connectionRole ||
              (connectionRole === 'Social' && !c.connectionRole), // Temporary until they fix missing connection role with migration
          )
          .map((u) => u.connectionUser),
      )),
  );

  return uniq(userIds).map((userId) => users.find((u) => u.user === userId));
}

function getConnectedUsers(tooltipGroups: TeamCardUserTooltipGroup[]) {
  const allUsers = tooltipGroups.reduce(
    (users: SuggestedTeamMemberObject[], group) => [
      ...users,
      ...group.connections.flatMap((c) => c.users),
    ],
    [] as SuggestedTeamMemberObject[],
  );
  return uniqBy(allUsers, (u) => u.user);
}

const TargeterSuggestedTeamCard = (props: Props): ReactElement => {
  const styles = useStyles(props);
  const { rolesCount, team, onSelectTeam, className, isLoading } = props;

  function getUserOfConnectionType(
    team: SuggestedTeam,
    getConnectionType: (suggestedTeam: SuggestedTeamMemberObject) => string[],
  ) {
    const userIds: string[] = Object.values(team.members).reduce(
      (userIds, user) => [...userIds, ...getConnectionType(user)],
      [] as string[],
    );

    const users = Object.values(team.members);
    return uniq(userIds).map((userId) => users.find((u) => u.user === userId));
  }

  const teamConnections = useMemo(() => {
    return getUserOfConnectionType(
      team,
      (user: SuggestedTeamMemberObject): string[] => {
        if (user.teams.length > 0) {
          return [...user.teams.map((u) => u.connectionUser), user.user];
        } else {
          return [];
        }
      },
    );
  }, [team]);

  const projectConnections = useMemo(() => {
    return getConnectedUsers(team.projectOverlaps);
  }, [team]);
  const companyConnections = useMemo(() => {
    return getConnectedUsers(team.companyOverlaps);
  }, [team]);
  const missionConnections = useMemo(() => {
    return getConnectedUsers(team.missionOverlaps);
  }, [team]);

  const invitedByOrInvitationsConnections = useMemo(() => {
    return getUserOfConnectionType(
      team,
      (user: SuggestedTeamMemberObject): string[] => [
        ...(user.invitedBy ? user.invitedBy.map((u) => u.connectionUser) : []),
        ...(user.invitations
          ? user.invitations.map((u) => u.connectionUser)
          : []),
      ],
    );
  }, [team]);

  const workConnections = useMemo(() => {
    return getFilteredConnections(team.members, 'Work');
  }, [team]);

  const teamUpConnections = useMemo(() => {
    return getFilteredConnections(team.members, 'TeamUp');
  }, [team]);

  const socialConnections = useMemo(() => {
    return getFilteredConnections(team.members, 'Social');
  }, [team]);

  return (
    <div className={cx(styles.cardContainer, className)}>
      <Card className={styles.card}>
        <div className={styles.cardHeader}>
          {!isLoading ? (
            <>
              <div className={styles.teamCompleteness}>
                {rolesCount === Object.keys(team.members).length && (
                  <Icon
                    size="exact"
                    type={IconType.SuggestedTeams}
                    className={styles.teamCompletenessIcon}
                  />
                )}
                <div className={styles.teamCompletenessLabel}>
                  {rolesCount === Object.keys(team.members).length
                    ? 'Full team'
                    : `Partial Team ${
                        Object.keys(team.members).length
                      } / ${rolesCount}`}
                </div>
              </div>
              <TextButton
                className={styles.selectTeamButton}
                onClick={() => onSelectTeam()}
              >
                Select team
              </TextButton>
            </>
          ) : null}
        </div>
        <ul className={styles.members}>
          {Object.keys(team.members).map((key) => {
            const member = team.members[key];
            return (
              <TargeterSuggestedTeamCardListItem
                key={`${key}-${member.user || ''}`}
                username={member.username || ''}
                fullName={member.fullName || ''}
                profilePictureURL={member.profilePictureURL || ''}
                badges={member.badges || []}
                role={key}
                isLoading={isLoading}
              />
            );
          })}
        </ul>
        {teamConnections.length > 0 ||
        projectConnections.length > 0 ||
        missionConnections.length > 0 ||
        companyConnections.length > 0 ||
        invitedByOrInvitationsConnections.length > 0 ? (
          <>
            <div className={styles.teamNarrativesTitle}>
              {isLoading ? (
                <Skeleton width={120} height={22} />
              ) : (
                'Team Narratives'
              )}
            </div>
            {teamConnections.length > 0 ? (
              <div className={styles.teamNarrativesSection}>
                {isLoading ? (
                  <Skeleton width={200} height={22} />
                ) : (
                  <div className={styles.teamNarrativesSectionTitle}>
                    <Icon size="exact" type={IconType.TeamCollection} />
                    <div>TeamCollection</div>
                  </div>
                )}
                <ul className={styles.connections}>
                  {teamConnections.map((user) => (
                    <TargeterSuggestedTeamCardConnectionItem
                      key={`team-${user?.user || ''}`}
                      username={user?.username || ''}
                      profilePictureURL={user?.profilePictureURL || ''}
                      isLoading={isLoading}
                    />
                  ))}
                </ul>
              </div>
            ) : null}
            {projectConnections.length > 0 ? (
              <div className={styles.teamNarrativesSection}>
                {isLoading ? (
                  <Skeleton width={200} height={22} />
                ) : (
                  <div className={styles.teamNarrativesSectionTitle}>
                    <Icon size="exact" type={IconType.WorkConnection} />
                    <div>Worked together on a project</div>
                  </div>
                )}
                <TeamCardUserTooltip
                  groups={team.projectOverlaps}
                  disabled={isLoading}
                >
                  <ul className={styles.connections}>
                    {projectConnections.map((user) => (
                      <TargeterSuggestedTeamCardConnectionItem
                        key={`project-${user?.user || ''}`}
                        username={user?.username || ''}
                        profilePictureURL={user?.profilePictureURL || ''}
                        isLoading={isLoading}
                      />
                    ))}
                  </ul>
                </TeamCardUserTooltip>
              </div>
            ) : null}
            {missionConnections.length > 0 ? (
              <div className={styles.teamNarrativesSection}>
                <div className={styles.teamNarrativesSectionTitle}>
                  <Icon size="exact" type={IconType.ATeamsConnection} />
                  <div>Worked together on A.Team</div>
                </div>
                <TeamCardUserTooltip
                  groups={team.missionOverlaps}
                  disabled={isLoading}
                >
                  <ul className={styles.connections}>
                    {missionConnections.map((user) => (
                      <TargeterSuggestedTeamCardConnectionItem
                        key={`mission-${user?.user || ''}`}
                        username={user?.username || ''}
                        profilePictureURL={user?.profilePictureURL || ''}
                        isLoading={isLoading}
                      />
                    ))}
                  </ul>
                </TeamCardUserTooltip>
              </div>
            ) : null}
            {companyConnections.length > 0 ? (
              <div className={styles.teamNarrativesSection}>
                <div className={styles.teamNarrativesSectionTitle}>
                  <Icon size="exact" type={IconType.WorkTogether} />
                  <div>Worked together in a company</div>
                </div>
                <TeamCardUserTooltip
                  groups={team.companyOverlaps}
                  disabled={isLoading}
                >
                  <ul className={styles.connections}>
                    {companyConnections.map((user) => (
                      <TargeterSuggestedTeamCardConnectionItem
                        key={`company-${user?.user || ''}`}
                        username={user?.username || ''}
                        profilePictureURL={user?.profilePictureURL || ''}
                        isLoading={isLoading}
                      />
                    ))}
                  </ul>
                </TeamCardUserTooltip>
              </div>
            ) : null}
            {invitedByOrInvitationsConnections.length > 0 ? (
              <div className={styles.teamNarrativesSection}>
                <div className={styles.teamNarrativesSectionTitle}>
                  <Icon size="exact" type={IconType.SocialConnection} />
                  <div>Referral Connection</div>
                </div>
                <ul className={styles.connections}>
                  {invitedByOrInvitationsConnections.map((user) => (
                    <TargeterSuggestedTeamCardConnectionItem
                      key={`referral-connection-${user?.user || ''}`}
                      username={user?.username || ''}
                      profilePictureURL={user?.profilePictureURL || ''}
                      isLoading={isLoading}
                    />
                  ))}
                </ul>
              </div>
            ) : null}
          </>
        ) : null}
        {!isLoading &&
        (workConnections.length > 0 ||
          teamUpConnections.length > 0 ||
          socialConnections.length > 0) ? (
          <>
            <div className={styles.teamNarrativesTitle}>Connection Types</div>
            {workConnections.length > 0 ? (
              <div className={styles.teamNarrativesSection}>
                <div className={styles.teamNarrativesSectionTitle}>
                  <Icon size="exact" type={IconType.WorkConnection} />
                  <div>Work connection</div>
                </div>
                <ul className={styles.connections}>
                  {workConnections.map((user) => (
                    <TargeterSuggestedTeamCardConnectionItem
                      key={`work-connection-${user?.user || ''}`}
                      username={user?.username || ''}
                      profilePictureURL={user?.profilePictureURL || ''}
                      isLoading={isLoading}
                    />
                  ))}
                </ul>
              </div>
            ) : null}
            {teamUpConnections.length > 0 ? (
              <div className={styles.teamNarrativesSection}>
                <div className={styles.teamNarrativesSectionTitle}>
                  <Icon size="exact" type={IconType.WorkConnection} />
                  <div>Team Up connection</div>
                </div>
                <ul className={styles.connections}>
                  {teamUpConnections.map((user) => (
                    <TargeterSuggestedTeamCardConnectionItem
                      key={user?.user || ''}
                      username={user?.username || ''}
                      profilePictureURL={user?.profilePictureURL || ''}
                    />
                  ))}
                </ul>
              </div>
            ) : null}
            {socialConnections.length > 0 ? (
              <div className={styles.teamNarrativesSection}>
                <div className={styles.teamNarrativesSectionTitle}>
                  <Icon size="exact" type={IconType.SocialConnection} />
                  <div>Platform connection</div>
                </div>
                <ul className={styles.connections}>
                  {socialConnections.map((user) => (
                    <TargeterSuggestedTeamCardConnectionItem
                      key={user?.user || ''}
                      username={user?.username || ''}
                      profilePictureURL={user?.profilePictureURL || ''}
                    />
                  ))}
                </ul>
              </div>
            ) : null}
          </>
        ) : null}
      </Card>
    </div>
  );
};

export default TargeterSuggestedTeamCard;
