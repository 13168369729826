import React from 'react';
import { action, computed, observable } from 'mobx';
import { Stores } from '@src/stores';
import supGif from '../../assets/gifs/sup.gif';
import cityGif from '../../assets/gifs/city.gif';
import yodaGif from '../../assets/gifs/yoda.gif';
import hoopGif from '../../assets/gifs/hoop.gif';
import bigGif from '../../assets/gifs/big.gif';
import yayGif from '../../assets/gifs/yay.gif';
import {
  apiAuth,
  apiExperiences,
  apiInvitation,
  apiRegistration,
  apiTalentCategories,
  apiTalentSpecializations,
  apiTeams,
  apiUser,
} from '@ateams/api';
import InvitationObject, {
  InvitationId,
} from '@a_team/models/dist/InvitationObject';
import {
  LocalTeamMember,
  LocalUserReferral,
  RegistrationFlow,
  RegistrationProgressStage,
} from '@src/stores/Registration/models';
import type { StepData } from '@src/stores/Registration/models';
import RegistrationIntroView from '@src/views/Registration/Steps/Intro';
import PersonalDetails from '@src/views/Registration/Steps/PersonalDetails';
import {
  CollaboratorStatus,
  RegisteredUserObject,
  UserStatus,
} from '@a_team/models/dist/UserObject';
import { LoginWithMethod } from '@ateams/api/dist/endpoints/Auth';
import Social from '@src/views/Registration/Steps/Social';
import {
  getDribbbleUsernameFromURL,
  getGithubUsernameFromURL,
  getLinkedinUsernameFromURL,
} from '@src/helpers/urls';
import Location from '@src/views/Registration/Steps/Location';
import HourlyRate from '@src/views/Registration/Steps/HourlyRate';
import {
  TalentCategoryId,
  TalentSpecialization,
  UserTalentSkillAssignment,
} from '@a_team/models/dist/TalentCategories';
import { SelectOption } from '@ateams/components';
import MainSpecialization from '@src/views/Registration/Steps/MainSpecialization';
import AdditionalSpecializations from '@src/views/Registration/Steps/AdditionalSpecializations';
import Skills from '@src/views/Registration/Steps/Skills';
import {
  ExistingProject,
  Expertise,
  NewProject,
} from '@src/stores/Profile/models';
import { expertiseToSkill, skillToExpertise } from '@src/helpers/expertise';
import Project from '@src/views/Registration/Steps/project/Project';
import {
  ExperienceMemberRole,
  ExperienceType,
  ExperienceUserMember,
} from '@a_team/models/dist/ExperienceObject';
import { isNewExperienceItem } from '@src/views/Profile/helpers/experience';
import Referrals from '@src/views/Registration/Steps/referrals/Referrals';
import { generateUniqueId } from '@src/helpers/strings';
import Source from '@src/views/Registration/Steps/Source';
import Success from '@src/views/Registration/Steps/success/Success';
import {
  SocialExperience,
  UTMParams,
} from '@ateams/api/dist/endpoints/Registration';
import { PlatformServiceAnalytics } from '@ateams/analytics/dist/platform';
import {
  UserReferralSourceData,
  UserReferralSourceType,
} from '@ateams/api/dist/endpoints/User';
import { CDN_BASE_URL } from '@src/config';
import { TalentCategory } from '@a_team/models/src/TalentCategories';
import LocationObject from '@a_team/models/dist/LocationObject';
import ApplyAsTeam from '@src/views/Registration/Steps/ApplyAsTeam';
import TeamMembers from '@src/views/Registration/Steps/teamMembers/TeamMembers';
import TeamObject, {
  BasicTeamMemberObject,
  TeamId,
  TeamMemberObject,
  TeamMemberRole,
  TeamMemberStatus,
} from '@a_team/models/dist/TeamObject';
import { NewTeamMemberUserData } from '@ateams/api/dist/endpoints/Teams';
import TeamRequestResponse from '@src/views/Registration/Steps/TeamRequestResponse';
import { fetchSkillList } from '@src/helpers/talent-skills-api';
import { filterOutBracketedSkills } from '../utils';

export interface RegistrationStoreData {
  currentStepIndex: number;
  flow: RegistrationFlow;
  invitation?: InvitationObject;
  nonce?: string;
  onboardingToken?: string;
  formData: Partial<RegisteredUserObject>;
  project: NewProject | ExistingProject;
  importedJobs?: SocialExperience[];
  referrals: LocalUserReferral[];
  teamMembers: LocalTeamMember[];
  source?: UserReferralSourceData;
  hourlyRate?: RegisteredUserObject['rateRange'];
  extraSpecializationsStepSubmitted?: boolean;
  skillsStepSubmitted?: boolean;
  referralsStepSubmitted?: boolean;
  sourceStepSubmitted?: boolean;
  processExists: boolean;
  utmParams?: UTMParams;
  approveTeamFromEmail?: TeamId;
  generalTalentCategory?: TalentCategory;
}

export default class RegistrationStore implements RegistrationStoreData {
  @observable currentStepIndex: RegistrationStoreData['currentStepIndex'] = 0;
  @observable flow: RegistrationStoreData['flow'] = RegistrationFlow.Individual;
  @observable invitation: RegistrationStoreData['invitation'];
  @observable nonce: RegistrationStoreData['nonce'];
  @observable onboardingToken: RegistrationStoreData['onboardingToken'];
  @observable project: RegistrationStoreData['project'] = {
    _id: '',
    title: '',
    description: '',
    type: ExperienceType.Project,
    members: [],
  };
  @observable importedJobs: RegistrationStoreData['importedJobs'] = [];
  @observable referrals: RegistrationStoreData['referrals'] = [];
  @observable teamMembers: RegistrationStoreData['teamMembers'] = [];
  @observable source: RegistrationStoreData['source'];
  @observable processExists: RegistrationStoreData['processExists'] = false;
  @observable hourlyRate: RegistrationStoreData['hourlyRate'];
  @observable
  extraSpecializationsStepSubmitted: RegistrationStoreData['extraSpecializationsStepSubmitted'] =
    undefined;
  @observable
  skillsStepSubmitted: RegistrationStoreData['skillsStepSubmitted'] = undefined;
  @observable
  referralsStepSubmitted: RegistrationStoreData['referralsStepSubmitted'] = undefined;
  @observable
  sourceStepSubmitted: RegistrationStoreData['sourceStepSubmitted'] = undefined;
  @observable formData: RegistrationStoreData['formData'] = {
    talentProfile: {
      talentSkills: {
        mainTalentSkills: [],
        additionalTalentSkills: [],
      },
    },
    needsAcceptTOS: true,
  };
  @observable utmParams: RegistrationStoreData['utmParams'];
  @observable
  approveTeamFromEmail: RegistrationStoreData['approveTeamFromEmail'];
  @observable
  generalTalentCategory: RegistrationStoreData['generalTalentCategory'];

  MAX_REFERRALS = 4;
  MIN_HOURLY_RATE_LIMIT = 60;
  MAX_HOURLY_RATE_LIMIT = 250;
  MIN_HOURLY_RATE_DEFAULT = 100;
  MAX_HOURLY_RATE_DEFAULT = 150;
  MAX_FEATURED_SKILLS = 20;
  PROJECT_TITLE_MIN_CHARS = 2;
  PROJECT_DESCRIPTION_MIN_CHARS = 40;
  LS_ONBOARDING_TOKEN_KEY = 'aTeamOnboardingToken';
  APPLY_AS_TEAM_URL_PARAM = 'asTeam';
  GENERAL_TALENT_CATEGORY_TEXTID = 'general';

  private rootStore: Stores;
  private analytics: PlatformServiceAnalytics;

  public constructor(
    rootStore: Stores,
    analytics: PlatformServiceAnalytics,
    initialState?: RegistrationStoreData,
  ) {
    if (initialState) {
      this.currentStepIndex = initialState.currentStepIndex;
      this.flow = initialState.flow;
    }
    this.rootStore = rootStore;
    this.analytics = analytics;
  }

  public serialize(): RegistrationStoreData {
    return {
      currentStepIndex: this.currentStepIndex,
      flow: this.flow,
      formData: this.formData,
      project: this.project,
      referrals: this.referrals,
      teamMembers: this.teamMembers,
      processExists: this.processExists,
    };
  }

  fetchInvitation = async (
    invitationId: InvitationId,
    nonce: string,
  ): Promise<void> => {
    const invitation = await apiInvitation.getInvitation(invitationId, nonce);
    this.setInvitationData(invitation, nonce);
    await this.registerUser(invitation, nonce);
    this.setInitialFormData();
  };

  registerUser = async (
    invitation: InvitationObject,
    nonce: string,
  ): Promise<void> => {
    const { token, user, onboardingToken } = await apiRegistration.register(
      invitation.id,
      nonce,
      {
        method: LoginWithMethod.InviteEmail,
        payload: invitation.email,
      },
    );

    this.rootStore.auth.token = token;
    this.updateUser(user);
    onboardingToken && this.setOnboardingToken(onboardingToken);

    this.analytics.trackSignUp(user);
  };

  registerUserWithEmail = async (): Promise<void> => {
    if (
      !this.formData.email ||
      !this.formData.firstName ||
      !this.formData.lastName
    )
      return Promise.reject(new Error('No email address or name provided'));

    let res;
    try {
      res = await apiRegistration.registerWithEmail({
        email: this.formData.email,
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        utmParams: this.utmParams,
      });
    } catch (e) {
      console.error(e);
      this.setProcessExists(true);
      return Promise.reject(null);
    }

    if (res) {
      this.rootStore.auth.token = res.token;
      this.updateUser(res.user);
      this.setProcessExists(false);
      this.setInitialFormData(false);
      res.onboardingToken && this.setOnboardingToken(res.onboardingToken);
    }
  };

  loginReturningRegisteredUser = async (
    onboaridngToken: string,
  ): Promise<void> => {
    let res;
    try {
      res = await apiAuth.loginWith({
        method: LoginWithMethod.OnboardingToken,
        payload: onboaridngToken,
      });
      if (res) {
        this.rootStore.auth.token = res.token;
        if (!res.user.username) {
          res.user = await apiRegistration.setContactInfo(this.rootStore.auth, {
            firstName: res.user.firstName || '',
            lastName: res.user.lastName || '',
            email: res.user.email || '',
          });
        }
      }
    } catch (e) {
      console.error(e);
    }

    if (res) {
      res.user && this.updateUser(res.user);
    }

    this.setOnboardingToken(onboaridngToken);
    this.setInitialFormData();
  };

  setLocalStorageOnboardingToken = (token?: string): void => {
    if (typeof window !== 'object') return;

    if (!token) {
      localStorage.removeItem(this.LS_ONBOARDING_TOKEN_KEY);
      return;
    }

    localStorage.setItem(this.LS_ONBOARDING_TOKEN_KEY, token);
  };

  getLocalStorageOnboardingToken = (): string | false | null => {
    return (
      typeof window === 'object' &&
      localStorage.getItem(this.LS_ONBOARDING_TOKEN_KEY)
    );
  };

  setReturningUserStartingStep = (): void => {
    const stepId = this.getReturnStepId();
    const index = this.steps.findIndex((step) => step.id === stepId);
    this.steps.forEach(
      (step, i) => i < index && step.setSubmitted && step.setSubmitted(true),
    );

    if (index !== -1) {
      this.setCurrentIndex(index);
    }
  };

  getReturnStepId = (): StepData['id'] => {
    const { registeredUser } = this.rootStore.auth;
    if (!registeredUser) return RegistrationProgressStage.Intro;

    // Move scrubbed users to the password step
    if (registeredUser.wasScrubbed && registeredUser.username) {
      return 'Success';
    }

    const notSubmitted = this.steps.find((step) => step.submitted === false);
    return notSubmitted ? notSubmitted.id : RegistrationProgressStage.Intro;
  };

  validateEmail = async (email: string): Promise<boolean> => {
    return await apiRegistration.validateEmail(
      this.rootStore.auth.maybeAuth,
      email,
    );
  };

  updateSocialNetworks = async (): Promise<RegisteredUserObject> => {
    const hasLinkedInDefined =
      !!this.rootStore.auth.currentUser?.linkedIn &&
      this.rootStore.auth.currentUser?.linkedIn.username ===
        this.formData.linkedIn?.username;

    const res = await apiRegistration.setSocialNetworks(this.rootStore.auth, {
      linkedInUsername: this.formData.linkedIn?.username,
      dribbbleUsername: this.formData.dribbble?.username,
      githubUsername: this.formData.github?.username,
      scanAll: true,
      linkedInOptOut: this.formData.linkedInOptOut,
    });

    !hasLinkedInDefined &&
      this.setImportedJobs(
        res.newExperiences.filter(
          (experience) => experience.type === ExperienceType.Job,
        ),
      );

    return res.user;
  };

  updateContactInfo = async (): Promise<void> => {
    const user = await apiRegistration.setContactInfo(this.rootStore.auth, {
      firstName: this.formData.firstName || '',
      lastName: this.formData.lastName || '',
      email: this.formData.email || '',
      isNewOnboarding: true,
    });

    this.updateUser(user);
  };

  updateHourlyRate = async (): Promise<void> => {
    if (!this.hourlyRate)
      return Promise.reject(new Error('No hourly rate defined'));

    const user = await apiRegistration.setRateInfo(this.rootStore.auth, {
      minRate: this.hourlyRate.min,
      maxRate: this.hourlyRate.max,
    });

    this.updateUser(user);
  };

  updatePersonalInfo = async (): Promise<RegisteredUserObject | void> => {
    const { aboutMe, websites, location, dribbble, linkedIn } = this.formData;
    if (!websites && !location) return Promise.resolve();

    const user = await apiUser.updatePersonalInfo(this.rootStore.auth, {
      ...(aboutMe && {
        aboutMe,
      }),
      ...(websites && {
        websites,
      }),
      ...(location && {
        location,
      }),
      ...(dribbble && {
        dribbbleUsername: dribbble.username,
      }),
      ...(linkedIn && {
        linkedinUsername: linkedIn.username,
      }),
      isNewOnboarding: true,
    });

    return user;
  };

  updateSource = async (): Promise<void> => {
    if (!this.source) return Promise.resolve();
    await apiUser.updateReferralSource(this.rootStore.auth, this.source);
  };

  getSource = async (): Promise<void> => {
    const source = await apiUser.getReferralSource(this.rootStore.auth);
    source && this.setSource(source);
  };

  setPassword = async (password: string): Promise<void> => {
    if (!this.formData.username)
      return Promise.reject(new Error('No username defined'));

    const user = await apiUser.changePassword(this.rootStore.auth, {
      oldPassword: '',
      password: password,
    });

    this.updateUser(user);
  };

  setAcceptToS = async (): Promise<void> => {
    await apiUser.acceptTOS(this.rootStore.auth);
    this.setAcceptedTos(true);
    if (this.rootStore.auth.currentUser) {
      this.rootStore.auth.setUser({
        ...this.rootStore.auth.currentUser,
        needsAcceptTOS: false,
      });
    }
  };

  querySpecializations = async (
    query: string,
    excludeMain?: boolean,
  ): Promise<SelectOption[]> => {
    const specializations =
      await apiTalentSpecializations.queryTalentSpecializations(
        this.rootStore.auth,
        {
          searchTerm: query,
          talentCategoryId:
            this.formData.talentProfile?.mainTalentSpecializationId,
          ...(excludeMain && {
            exclude:
              this.formData.talentProfile?.talentSpecializations
                ?.mainTalentSpecialization?.id,
          }),
        },
      );
    return specializations.items.map((item) => {
      return { ...item, value: item.id, label: item.name };
    });
  };

  saveSpecializations = async (): Promise<void> => {
    if (!this.formData.talentProfile?.talentSpecializations)
      return Promise.resolve();

    const { mainTalentSpecialization, additionalTalentSpecializations } =
      this.formData.talentProfile?.talentSpecializations;

    const user = await apiUser.updateTalentSpecializations(
      this.rootStore.auth,
      {
        ...(mainTalentSpecialization && {
          mainSpecializationId: mainTalentSpecialization.id,
        }),
        additionalSpecializationIds:
          additionalTalentSpecializations.map((item) => item.id) || [],
      },
    );

    this.updateUser(user);
    this.setDefaultProjectRole();
  };

  getGeneralTalentCategory = async (): Promise<void> => {
    const skills = await apiTalentCategories.queryTalentCategories(
      this.rootStore.auth,
      {
        textId: this.GENERAL_TALENT_CATEGORY_TEXTID,
      },
    );
    this.setGeneralTalentCategory(skills.items[0]);
  };

  querySkills = async (query: string): Promise<Expertise[]> => {
    if (!this.generalTalentCategoryId) {
      await this.getGeneralTalentCategory();
    }
    const skills = await fetchSkillList({
      filter: {
        query,
      },
    });

    const filteredSkills = filterOutBracketedSkills(skills);

    return filteredSkills;
  };

  saveSkills = async (): Promise<void> => {
    if (!this.formData.talentProfile) return;

    const { mainTalentSkills, additionalTalentSkills } =
      this.formData.talentProfile.talentSkills;

    const user = await apiUser.updateTalentSkills(this.rootStore.auth, {
      mainTalentSkills,
      additionalTalentSkills,
    });

    this.updateUser(user);
  };

  saveProject = async (): Promise<void> => {
    let project;
    if (isNewExperienceItem(this.project)) {
      project = await apiExperiences.createUserExperience(this.rootStore.auth, {
        ...this.project,
        members: [
          {
            ...this.rootStore.auth.currentUser,
            ...{
              memberTitle: this.formData.title,
              experienceRole: ExperienceMemberRole.Owner,
              collaboratorStatus: CollaboratorStatus.Active,
            },
          } as ExperienceUserMember,
          ...this.project.members,
        ],
      });
    } else {
      project = await apiExperiences.updateExperience(
        this.rootStore.auth,
        this.project.eid,
        this.project,
      );
    }

    this.setProject(project as ExistingProject);
  };

  saveReferrals = async (): Promise<void> => {
    const user = await apiUser.createReferral(this.rootStore.auth, {
      referrals: this.filledReferrals.map((referral) => {
        return {
          firstName: referral.firstName,
          lastName: referral.lastName,
          email: referral.email,
          website: referral.website,
          linkedinUrl: referral.linkedinUrl,
        };
      }),
    });

    user && this.updateUser(user);
  };

  completeRegistration = async (): Promise<void> => {
    const res = await apiRegistration.complete(this.rootStore.auth, {
      experiences: this.importedJobs,
      utmParams: this.utmParams,
      isNewOnboarding: true,
    });
    this.updateUser(res.user);
    this.setOnboardingToken(undefined);
    this.rootStore.auth.token = res.token;
  };

  submitPersonalInfoStep = async (): Promise<void> => {
    if (!this.formData.email)
      return Promise.reject(new Error('Please provide an email address'));

    // Check for existing emails or current user email
    const isValidEmail = await this.validateEmail(this.formData.email);

    if (!isValidEmail) {
      this.setProcessExists(true);
      return Promise.reject(null);
    }

    if (this.invitation || this.personalDetailsChanged) {
      await this.updateContactInfo();
    } else {
      if (!this.rootStore.auth.currentUser) {
        this.onboardingToken
          ? await this.loginReturningRegisteredUser(this.onboardingToken)
          : await this.registerUserWithEmail();
      }
    }

    this.rootStore.auth.registeredUser?.needsAcceptTOS &&
      (await this.setAcceptToS());
    this.stepForward();
  };

  submitResponseToTeamRequestStep = async (
    accepted: boolean,
  ): Promise<void> => {
    if (!this.rootStore.auth.currentUser?.teams?.[0]?.tid) return;
    const team = { ...this.rootStore.auth.currentUser.teams[0] };

    const memberIndex = team.members.findIndex(
      (member) => member.uid === this.rootStore.auth.uid,
    );

    if (
      (team as TeamObject).members[memberIndex].memberStatus ===
        TeamMemberStatus.Active &&
      accepted
    ) {
      return;
    }

    const updatedMember = await apiTeams.respondToTeamUpRequest(
      this.rootStore.auth,
      team.tid,
      { accepted },
    );

    if (memberIndex !== -1 && team.members[memberIndex]) {
      team.members[memberIndex] = updatedMember;
      this.updateUser({
        ...(this.rootStore.auth.registeredUser as RegisteredUserObject),
        teams: [team],
      });
    }
  };

  submitSocialStep = async (): Promise<void> => {
    let user;
    user = await this.updateSocialNetworks();
    const res = await this.updatePersonalInfo();
    res && (user = res);

    user && this.updateUser(user);
    this.stepForward();
  };

  submitLocationStep = async (): Promise<void> => {
    const user = await this.updatePersonalInfo();
    user && this.updateUser(user);
    this.stepForward();
  };

  submitHourlyRateStep = async (): Promise<void> => {
    await this.updateHourlyRate();
    this.stepForward();
  };

  submitSpecializationStep = async (): Promise<void> => {
    await this.saveSpecializations();
    this.stepForward();
    this.setAdditionalSpecializationsSubmitted(true);
  };

  submitSkillsStep = async (): Promise<void> => {
    await this.saveSkills();
    this.stepForward();
    this.setSkillsSubmitted(true);
  };

  submitProjectStep = async (): Promise<void> => {
    await this.saveProject();
    this.stepForward();
  };

  submitReferralsStep = async (): Promise<void> => {
    await this.saveReferrals();
    this.stepForward();
    this.setReferralsSubmitted(true);
  };

  submitTeamMembersStep = async (): Promise<void> => {
    if (!this.rootStore.auth.uid) {
      throw new Error('You must create a user before creating a team');
    }

    let team;

    const membersPayload = [
      {
        teamRole: TeamMemberRole.Owner,
        fullName: this.rootStore.auth.registeredUser?.fullName || '',
        email: this.rootStore.auth.email || '',
      },
      ...this.teamMembersWithoutOwner.map((member) =>
        this.toTeamMemberPayload(member),
      ),
    ];

    if (this.teamMembersStepSubmitted && this.formData.teams) {
      team = await apiTeams.updateTeam(
        this.rootStore.auth,
        this.formData.teams[0].tid,
        {
          members: membersPayload,
        },
      );
    } else {
      team = await apiRegistration.registerTeam(this.rootStore.auth, {
        members: membersPayload,
      });
    }

    if (this.rootStore.auth.registeredUser) {
      this.updateUser({
        ...this.rootStore.auth.registeredUser,
        teams: [team],
      });
    }

    this.setTeamMembers(
      team.members.map((member: TeamMemberObject) =>
        this.toLocalTeamMember(member),
      ),
    );

    this.setProjectMembers(
      team.members.map((member: TeamMemberObject) =>
        this.toProjectMember(member),
      ),
    );

    this.stepForward();
  };

  submitSourceStep = async (): Promise<void> => {
    await this.updateSource();
    this.stepForward();
    this.setSourceSubmitted(true);
  };

  submitPasswordModal = async (password: string): Promise<void> => {
    const acceptedTOSinModal =
      this.rootStore.auth.currentUser?.needsAcceptTOS &&
      !this.formData.needsAcceptTOS;

    await Promise.all([
      this.setPassword(password),
      this.completeRegistration(),
      ...(acceptedTOSinModal ? [this.setAcceptToS()] : []),
    ]);
  };

  trackStepView = (): void => {
    this.analytics.trackRegistrationStepViewed(
      `${this.currentStepIndex}-${this.currentStep.id}`,
      this.flow,
      this.formData,
    );
  };

  trackVideoPlay = (): void => {
    this.analytics.trackRegistrationVideoPlayClicked(
      `${this.currentStepIndex}-${this.currentStep.id}`,
      this.currentStep.videoUrl || '',
      this.flow,
      this.formData,
    );
  };

  toProjectMember = (
    member: TeamMemberObject | BasicTeamMemberObject,
  ): ExperienceUserMember => {
    return {
      ...member,
      memberTitle: member.memberTitle?.name || '',
      experienceRole:
        member.teamRole === TeamMemberRole.Owner
          ? ExperienceMemberRole.Owner
          : ExperienceMemberRole.Member,
    };
  };

  toLocalTeamMember = (
    member: TeamMemberObject | BasicTeamMemberObject,
  ): LocalTeamMember => {
    return {
      _id: member.uid,
      fullName: member.fullName,
      linkedInUsername: member.linkedInUsername || '',
      teamRole: member.teamRole,
      ...(member.memberTitle && {
        role: member.memberTitle,
      }),
      email: 'email' in member && member.email ? member.email : '',
      existingMember: true,
    };
  };

  toTeamMemberPayload = (member: LocalTeamMember): NewTeamMemberUserData => {
    return {
      ...member,
      role: {
        mainSpecializationId: member.role?.id,
      },
    };
  };

  @computed get applyingAsTeam(): boolean {
    return this.flow === RegistrationFlow.TeamCreator;
  }

  @computed get stepCount(): number {
    switch (this.flow) {
      case RegistrationFlow.Individual:
      case RegistrationFlow.TeamCreator:
        return 11;
      case RegistrationFlow.TeamMemberResponse:
        return 6;
      default:
        return 10;
    }
  }

  @computed get introStep(): StepData {
    return {
      id: RegistrationProgressStage.Intro,
      label: 'Intro',
      title: 'Welcome to A.Team',
      videoUrl: `${CDN_BASE_URL}/video/registration/intro.mov`,
      component: <RegistrationIntroView />,
      withMobileVideo: true,
    };
  }

  @computed get applyAsTeamStep(): StepData {
    return {
      id: RegistrationProgressStage.ApplyAsTeam,
      label: `${this.currentStepIndex}/${this.stepCount}`,
      title: 'Apply as a Team',
      gif: supGif,
      component: <ApplyAsTeam />,
    };
  }

  @computed get respondToTeamRequestStep(): StepData {
    return {
      id: RegistrationProgressStage.TeamUpResponse,
      label: `Opt in`,
      title: 'Respond to team invite',
      gif: supGif,
      component: <TeamRequestResponse />,
      submitted: this.respondToTeamRequestStepSubmitted,
    };
  }

  @computed get personalDetailsStep(): StepData {
    return {
      id: RegistrationProgressStage.PersonalDetails,
      label: `${this.currentStepIndex}/${this.stepCount}`,
      title: 'Personal Details',
      gif: supGif,
      component: <PersonalDetails />,
      canSubmit: this.canSubmitPersonalDetails,
      onSubmit: this.submitPersonalInfoStep,
      submitted: this.personalDetailsStepSubmitted,
    };
  }

  @computed get externalLinksStep(): StepData {
    return {
      id: RegistrationProgressStage.ExternalProfileLinks,
      label: `${this.currentStepIndex}/${this.stepCount}`,
      title: 'Online Presence',
      videoUrl: `${CDN_BASE_URL}/video/registration/online_presence.mov`,
      offsetVideo: 25,
      component: <Social />,
      canSubmit: this.canSubmitSocial,
      onSubmit: this.submitSocialStep,
      submitted: this.socialStepSubmitted,
    };
  }

  @computed get teamMembersStep(): StepData {
    return {
      id: RegistrationProgressStage.TeamMembers,
      label: `${this.currentStepIndex}/${this.stepCount}`,
      title: 'Team Members',
      videoUrl: `${CDN_BASE_URL}/video/registration/referrals.mp4`,
      offsetVideo: 60,
      component: <TeamMembers />,
      canSubmit: this.canSubmitTeamMembers,
      onSubmit: this.submitTeamMembersStep,
      submitted: this.teamMembersStepSubmitted,
    };
  }

  @computed get locationStep(): StepData {
    return {
      id: RegistrationProgressStage.Location,
      label: `${this.currentStepIndex}/${this.stepCount}`,
      title: 'Location',
      gif: cityGif,
      component: <Location />,
      canSubmit: this.canSubmitLocation,
      onSubmit: this.submitLocationStep,
      submitted: this.locationStepSubmitted,
    };
  }

  @computed get hourlyRateStep(): StepData {
    return {
      id: RegistrationProgressStage.HourlyRate,
      label: `${this.currentStepIndex}/${this.stepCount}`,
      title: 'Hourly Rate',
      videoUrl: `${CDN_BASE_URL}/video/registration/rate.mov`,
      offsetVideo: 10,
      component: <HourlyRate />,
      canSubmit: this.canSubmitHourlyRate,
      onSubmit: this.submitHourlyRateStep,
      submitted: this.hourlyRateStepSubmitted,
    };
  }

  @computed get mainSpecializationStep(): StepData {
    return {
      id: RegistrationProgressStage.MainSpecialization,
      label: `${this.currentStepIndex}/${this.stepCount}`,
      title: 'Specialization',
      videoUrl: `${CDN_BASE_URL}/video/registration/main_role.mp4`,
      offsetVideo: 12,
      component: <MainSpecialization />,
      canSubmit: this.canSubmitMainSpecialization,
      onSubmit: this.submitSpecializationStep,
      submitted: this.mainSpecializationStepSubmitted,
    };
  }

  @computed get extraSpecializationsStep(): StepData {
    return {
      id: RegistrationProgressStage.ExtraSpecializations,
      label: `${this.currentStepIndex}/${this.stepCount}`,
      title: 'Additional Specializations',
      gif: yodaGif,
      component: <AdditionalSpecializations />,
      onSubmit: this.submitSpecializationStep,
      submitted: this.extraSpecializationsStepSubmitted,
      setSubmitted: this.setAdditionalSpecializationsSubmitted,
    };
  }

  @computed get skillsStep(): StepData {
    return {
      id: RegistrationProgressStage.Skills,
      label: `${this.currentStepIndex}/${this.stepCount}`,
      title: 'Skills',
      gif: hoopGif,
      component: <Skills />,
      onSubmit: this.submitSkillsStep,
      submitted: this.skillsStepSubmitted,
      setSubmitted: this.setSkillsSubmitted,
    };
  }

  @computed get projectStep(): StepData {
    return {
      id: RegistrationProgressStage.PreviousProjects,
      label: `${this.currentStepIndex}/${this.stepCount}`,
      title: 'Project',
      videoUrl: `${CDN_BASE_URL}/video/registration/project.MP4`,
      component: <Project />,
      canSubmit: this.canSubmitProject,
      onSubmit: this.submitProjectStep,
      submitted: this.projectStepSubmitted,
    };
  }

  @computed get referralsStep(): StepData {
    return {
      id: RegistrationProgressStage.Referrals,
      label: `${this.currentStepIndex}/${this.stepCount}`,
      title: 'Peers',
      videoUrl: `${CDN_BASE_URL}/video/registration/referrals.mp4`,
      offsetVideo: 60,
      component: <Referrals />,
      canSubmit: this.canSubmitReferrals,
      onSubmit: this.submitReferralsStep,
      submitted: this.referralsStepSubmitted,
      setSubmitted: this.setReferralsSubmitted,
    };
  }

  @computed get sourceStep(): StepData {
    return {
      id: RegistrationProgressStage.Source,
      label: `${this.currentStepIndex}/${this.stepCount}`,
      title: 'Source',
      gif: bigGif,
      component: <Source />,
      canSubmit: this.canSubmitSource,
      onSubmit: this.submitSourceStep,
      submitted: this.sourceStepSubmitted,
      setSubmitted: this.setSourceSubmitted,
    };
  }

  @computed get successStep(): StepData {
    return {
      id: 'Success',
      label: 'Done',
      title: 'Complete',
      gif: yayGif,
      component: <Success />,
      submitted: this.passwordStepSubmitted,
      withMobileVideo: true,
    };
  }

  @computed get individualFlowSteps(): StepData[] {
    return [
      this.introStep,
      this.applyAsTeamStep,
      this.personalDetailsStep,
      this.externalLinksStep,
      this.locationStep,
      this.hourlyRateStep,
      this.mainSpecializationStep,
      this.extraSpecializationsStep,
      this.skillsStep,
      this.projectStep,
      this.referralsStep,
      this.sourceStep,
      this.successStep,
    ];
  }

  @computed get teamCreatorFlowSteps(): StepData[] {
    return [
      this.introStep,
      this.applyAsTeamStep,
      this.personalDetailsStep,
      this.externalLinksStep,
      this.teamMembersStep,
      this.locationStep,
      this.hourlyRateStep,
      this.mainSpecializationStep,
      this.extraSpecializationsStep,
      this.skillsStep,
      this.projectStep,
      this.sourceStep,
      this.successStep,
    ];
  }

  @computed get teamMemberResponseFlowSteps(): StepData[] {
    return [
      this.respondToTeamRequestStep,
      this.externalLinksStep,
      this.locationStep,
      this.hourlyRateStep,
      this.extraSpecializationsStep,
      this.skillsStep,
      this.successStep,
    ];
  }

  @computed get steps(): StepData[] {
    switch (this.flow) {
      case RegistrationFlow.Individual:
        return this.individualFlowSteps;
      case RegistrationFlow.TeamCreator:
        return this.teamCreatorFlowSteps;
      case RegistrationFlow.TeamMemberResponse:
        return this.teamMemberResponseFlowSteps;
      default:
        return this.individualFlowSteps;
    }
  }

  @computed get currentStep(): StepData {
    return this.steps[this.currentStepIndex];
  }

  @computed get nextStep(): StepData {
    return this.steps[this.currentStepIndex + 1];
  }

  @computed get previousStep(): StepData {
    return this.steps[this.currentStepIndex - 1];
  }

  @computed get totalStepsCount(): number {
    return this.steps.length;
  }

  @computed get progressBarPosition(): number {
    return (100 * this.currentStepIndex) / (this.totalStepsCount - 1);
  }

  @computed get progressBarProgress(): number {
    let lastSubmittedStepIndex = 0;
    this.steps.forEach((step, i) => {
      if (step.submitted) {
        lastSubmittedStepIndex = i;
      }
    });
    const pos = (100 * lastSubmittedStepIndex) / (this.totalStepsCount - 1);
    return pos > this.progressBarPosition ? pos : this.progressBarPosition;
  }

  @computed get allSkills(): Expertise[] {
    if (!this.formData.talentProfile) return [];
    return [
      ...this.featuredSkills.map((skill) => skillToExpertise(skill, true)),
      ...(this.formData.talentProfile.talentSkills.additionalTalentSkills?.map(
        (skill) => skillToExpertise(skill),
      ) ?? []),
    ];
  }

  @computed get featuredSkills(): UserTalentSkillAssignment[] {
    return this.formData.talentProfile?.talentSkills.mainTalentSkills || [];
  }

  @computed get canSubmitPersonalDetails(): boolean {
    return (
      !!this.formData.firstName &&
      this.formData.firstName !== '' &&
      !!this.formData.lastName &&
      this.formData.lastName !== '' &&
      !!this.formData.email &&
      this.formData.email !== '' &&
      !this.formData.needsAcceptTOS
    );
  }

  @computed get canSubmitSocial(): boolean {
    return (
      !!this.formData.linkedIn?.profileUrl || !!this.formData.linkedInOptOut
    );
  }

  @computed get canSubmitLocation(): boolean {
    return !!this.formData.location?.country;
  }

  @computed get canSubmitHourlyRate(): boolean {
    return this.hourlyRateValid;
  }

  @computed get canSubmitMainSpecialization(): boolean {
    return !!this.formData.talentProfile?.talentSpecializations
      ?.mainTalentSpecialization;
  }

  @computed get canSubmitProject(): boolean {
    const teamProjectReqs = this.applyingAsTeam
      ? this.project.members.length > 1
      : true;
    return (
      !!this.project.title &&
      !!this.project.description &&
      !!this.project.jobRole &&
      this.project.title.length >= this.PROJECT_TITLE_MIN_CHARS &&
      this.project.description.length >= this.PROJECT_DESCRIPTION_MIN_CHARS &&
      teamProjectReqs
    );
  }

  @computed get canSubmitSource(): boolean {
    const memberNotFilled =
      this.source?.source === UserReferralSourceType.ATeamMember &&
      !this.source.sourceName;
    const clientNotFilled =
      this.source?.source === UserReferralSourceType.Client &&
      (!this.source.sourceName || !this.source.sourceEmail);
    const otherNotFilled =
      this.source?.source === UserReferralSourceType.Other &&
      !this.source.otherText;

    return !(memberNotFilled || clientNotFilled || otherNotFilled);
  }

  @computed get canSubmitReferrals(): boolean {
    if (this.referrals.length === 0) return true;
    return this.allReferralsFilled;
  }

  @computed get canSubmitTeamMembers(): boolean {
    if (this.teamMembersWithoutOwner.length === 0) return false;
    return this.allTeamMembersFilled;
  }

  @computed get personalDetailsChanged(): boolean {
    const { registeredUser } = this.rootStore.auth;
    if (!registeredUser) return false;
    return (
      registeredUser.firstName !== this.formData.firstName ||
      registeredUser.lastName !== this.formData.lastName ||
      registeredUser.email !== this.formData.email
    );
  }

  @computed get personalDetailsStepSubmitted(): boolean {
    const { registeredUser } = this.rootStore.auth;
    if (!registeredUser) return false;

    return !!registeredUser.username && !registeredUser.needsAcceptTOS;
  }

  @computed get respondToTeamRequestStepSubmitted(): boolean {
    const { currentUser } = this.rootStore.auth;
    if (!currentUser?.teams) return false;
    const currentUserMember = currentUser.teams[0].members.find(
      (member) => member.uid === currentUser.uid,
    );
    return (
      !!currentUserMember &&
      (currentUserMember as TeamMemberObject).memberStatus !==
        TeamMemberStatus.Pending
    );
  }

  @computed get teamMembersStepSubmitted(): boolean {
    const { registeredUser } = this.rootStore.auth;
    if (!registeredUser) return false;

    return !!registeredUser.teams && registeredUser.teams.length !== 0;
  }

  @computed get socialStepSubmitted(): boolean {
    const { registeredUser } = this.rootStore.auth;
    if (!registeredUser) return false;
    return (
      !!registeredUser.linkedIn?.profileUrl || !!registeredUser.linkedInOptOut
    );
  }

  @computed get locationStepSubmitted(): boolean {
    const { registeredUser } = this.rootStore.auth;
    if (!registeredUser) return false;
    return (
      !!registeredUser.location?.country && !!registeredUser.location?.city
    );
  }

  @computed get hourlyRateStepSubmitted(): boolean {
    const { registeredUser } = this.rootStore.auth;
    if (!registeredUser) return false;
    return !!registeredUser.rateRange;
  }

  @computed get mainSpecializationStepSubmitted(): boolean {
    const { registeredUser } = this.rootStore.auth;
    if (!registeredUser) return false;
    return !!registeredUser.talentProfile?.talentSpecializations
      ?.mainTalentSpecialization;
  }

  @computed get projectStepSubmitted(): boolean {
    const { registeredUser } = this.rootStore.auth;
    if (!registeredUser) return false;
    return !!registeredUser.projects && registeredUser.projects.length > 0;
  }

  @computed get passwordStepSubmitted(): boolean {
    const { userStatus } = this.rootStore.auth;
    if (!userStatus) return false;
    return userStatus === UserStatus.Active;
  }

  @computed get allTalentCategoryIds(): TalentCategoryId[] {
    if (!this.formData.talentProfile?.talentSpecializations) return [];
    const { mainTalentSpecialization, additionalTalentSpecializations } =
      this.formData.talentProfile.talentSpecializations;
    const ids: TalentCategoryId[] = [];
    const roles: TalentSpecialization[] = mainTalentSpecialization
      ? [...additionalTalentSpecializations, mainTalentSpecialization]
      : [...additionalTalentSpecializations];
    roles.forEach(
      (role) => role.talentCategoryIds && ids.push(...role.talentCategoryIds),
    );
    return ids;
  }

  @computed get filledReferrals(): LocalUserReferral[] {
    return this.referrals.filter(
      (referral) =>
        !!referral.firstName || !!referral.lastName || !!referral.email,
    );
  }

  @computed get allReferralsFilled(): boolean {
    if (this.filledReferrals.length === 0) return false;
    return this.filledReferrals.every(
      (referral) =>
        !!referral.email && !!referral.firstName && !!referral.lastName,
    );
  }

  @computed get allTeamMembersFilled(): boolean {
    if (this.teamMembersWithoutOwner.length === 0) return false;
    return this.teamMembersWithoutOwner.every(
      (member) => !!member.email && !!member.fullName && !!member.role,
    );
  }

  @computed get hourlyRateValid(): boolean {
    if (!this.hourlyRate?.min || !this.hourlyRate?.max) return false;
    return this.hourlyRate.max > this.hourlyRate.min;
  }

  @computed get generalTalentCategoryId(): string | undefined {
    return this.generalTalentCategory?.id;
  }

  @computed get teamMembersWithoutOwner(): LocalTeamMember[] {
    return this.teamMembers.filter(
      (member) => member.teamRole !== TeamMemberRole.Owner,
    );
  }

  @computed get teamOwner(): LocalTeamMember | undefined {
    return this.teamMembers.find(
      (member) => member.teamRole === TeamMemberRole.Owner,
    );
  }

  @computed get isTeamOwner(): boolean {
    return this.teamOwner?._id === this.rootStore.auth.uid;
  }

  @computed get currentUserTeamMember(): BasicTeamMemberObject | undefined {
    return (
      this.formData.teams &&
      this.formData.teams[0].members.find(
        (member) => member.uid === this.rootStore.auth.uid,
      )
    );
  }

  @action setFlow = (flow: RegistrationFlow): void => {
    this.flow = flow;
  };

  @action setOnboardingToken = (token?: string): void => {
    this.onboardingToken = token;
    this.setLocalStorageOnboardingToken(token);
  };

  @action setInvitationData = (
    invitation: InvitationObject,
    nonce: string,
  ): void => {
    this.invitation = invitation;
    this.nonce = nonce;
  };

  @action setInitialFormData = (updateStep = true): void => {
    this.addNewEmptyReferral();
    this.getSource();
    this.setInitialProject();
    this.setInitialTeamMembers();
    this.setDefaultProjectRole();
    this.setInitialHourlyRate();
    updateStep && this.setReturningUserStartingStep();
  };

  @action setInitialProject = (): void => {
    if (this.formData.projects && this.formData.projects.length) {
      this.setProject(this.formData.projects[0]);
    }
  };

  @action setInitialTeamMembers = (): void => {
    if (this.formData.teams && this.formData.teams.length) {
      if (
        this.currentUserTeamMember?.memberStatus === TeamMemberStatus.Declined
      ) {
        return;
      }

      this.setTeamMembers(
        this.formData.teams[0].members.map((member) =>
          this.toLocalTeamMember(member),
        ),
      );
      if (!this.project.members || this.project.members.length === 0)
        this.setProjectMembers(
          this.formData.teams[0].members.map((member) =>
            this.toProjectMember(member),
          ),
        );
      const isTeamOwner = this.formData.teams[0].members.find(
        (member) =>
          member.teamRole === TeamMemberRole.Owner &&
          member.uid === this.rootStore.auth.uid,
      );
      this.setFlow(
        isTeamOwner
          ? RegistrationFlow.TeamCreator
          : RegistrationFlow.TeamMemberResponse,
      );
    }
  };

  @action setInitialHourlyRate = (): void => {
    this.setHourlyRateRange([
      this.formData.rateRange?.min || this.MIN_HOURLY_RATE_DEFAULT,
      this.formData.rateRange?.max || this.MAX_HOURLY_RATE_DEFAULT,
    ]);
  };

  @action redirectApplyAsTeam = (asTeamRole?: TeamMemberRole): void => {
    this.setFlow(
      asTeamRole
        ? asTeamRole === TeamMemberRole.Member
          ? RegistrationFlow.TeamMemberResponse
          : RegistrationFlow.TeamCreator
        : RegistrationFlow.Individual,
    );

    if (
      this.flow === RegistrationFlow.Individual ||
      this.flow === RegistrationFlow.TeamCreator
    ) {
      this.goToStep(this.steps[2].id);
    } else {
      this.stepForward();
    }
  };

  @action setUTMParams = (params: UTMParams): void => {
    this.utmParams = params;
  };

  @action setApproveTeamFromEmail = (teamId?: TeamId): void => {
    this.approveTeamFromEmail = teamId;
  };

  @action updateUser = (user: RegisteredUserObject): void => {
    this.rootStore.auth.setUser(user);
    this.formData = user;
  };

  @action setCurrentIndex = (index: number): void => {
    this.currentStepIndex = index;
  };

  @action stepForward = (): void => {
    this.analytics.trackRegistrationStepSubmitted(
      `${this.currentStepIndex}-${this.currentStep.id}`,
      this.flow,
      this.formData,
    );

    this.setCurrentIndex(this.currentStepIndex + 1);
  };

  @action stepBack = (): void => {
    this.analytics.trackRegistrationBackButtonClicked(
      `${this.currentStepIndex}-${this.currentStep.id}`,
      this.flow,
      this.formData,
    );

    this.setCurrentIndex(this.currentStepIndex - 1);
  };

  @action goToStep = (stepId: StepData['id']): void => {
    const index = this.steps.findIndex((step) => step.id === stepId);
    index !== -1 && this.setCurrentIndex(index);
  };

  @action setProcessExists = (exists: boolean): void => {
    this.processExists = exists;
  };

  @action setFirstName = (name: string): void => {
    this.formData.firstName = name;
  };

  @action setLastName = (name: string): void => {
    this.formData.lastName = name;
  };

  @action setEmail = (email: string): void => {
    this.formData.email = email;
  };

  @action setAcceptedTos = (accepted: boolean): void => {
    this.formData.needsAcceptTOS = !accepted;
  };

  @action setLinkedIn = (profileUrl?: string): void => {
    const username = profileUrl ? getLinkedinUsernameFromURL(profileUrl) : '';
    this.formData.linkedIn = profileUrl ? { profileUrl, username } : undefined;
  };

  @action setLinkedInOptOut = (checked: boolean): void => {
    this.formData.linkedInOptOut = checked;
  };

  @action setGitHub = (profileUrl?: string): void => {
    const username = profileUrl ? getGithubUsernameFromURL(profileUrl) : '';
    this.formData.github = profileUrl ? { profileUrl, username } : undefined;
  };

  @action setDribbble = (profileURL?: string): void => {
    const username = profileURL ? getDribbbleUsernameFromURL(profileURL) : '';
    this.formData.dribbble = profileURL ? { profileURL, username } : undefined;
  };

  @action setWebsite = (url: string): void => {
    let websites = [...(this.formData.websites || [])];
    if (url) {
      websites[0] = url;
    } else {
      websites = [];
    }
    this.formData.websites = websites;
  };

  @action setImportedJobs = (jobs: SocialExperience[]): void => {
    this.importedJobs = jobs;
  };

  @action setLocation = (location: LocationObject): void => {
    this.formData.location = location;
  };

  @action setHourlyRateRange = (range: number[]): void => {
    this.hourlyRate = {
      min: range[0],
      max: range[1],
    };
  };

  @action setMainSpecialization = (
    specialization: TalentSpecialization,
  ): void => {
    const mainTalentSkills =
      this.formData.talentProfile?.talentSkills.mainTalentSkills || [];
    const additionalTalentSkills =
      this.formData.talentProfile?.talentSkills.additionalTalentSkills || [];
    const additionalTalentSpecializations =
      this.formData.talentProfile?.talentSpecializations
        ?.additionalTalentSpecializations || [];

    this.formData.talentProfile = {
      talentSpecializations: {
        mainTalentSpecialization: specialization,
        additionalTalentSpecializations,
      },
      talentSkills: {
        mainTalentSkills,
        additionalTalentSkills,
      },
    };
    this.saveSpecializations();
  };

  @action setAdditionalSpecializations = (
    specializations: TalentSpecialization[],
  ): void => {
    const mainTalentSkills =
      this.formData.talentProfile?.talentSkills.mainTalentSkills || [];
    const additionalTalentSkills =
      this.formData.talentProfile?.talentSkills.additionalTalentSkills || [];
    const mainTalentSpecialization =
      this.formData.talentProfile?.talentSpecializations
        ?.mainTalentSpecialization;

    this.formData.talentProfile = {
      talentSpecializations: {
        mainTalentSpecialization,
        additionalTalentSpecializations: specializations,
      },
      talentSkills: {
        mainTalentSkills,
        additionalTalentSkills,
      },
    };
  };

  @action setAdditionalSpecializationsSubmitted = (
    submitted: boolean,
  ): void => {
    this.extraSpecializationsStepSubmitted = submitted;
  };

  @action setSkills = (skills: Expertise[]): void => {
    if (!this.formData.talentProfile) return;

    this.formData.talentProfile.talentSkills.mainTalentSkills = skills
      .filter((skill) => skill.rating && skill.featured)
      .map(expertiseToSkill);
    this.formData.talentProfile.talentSkills.additionalTalentSkills = skills
      .filter((skill) => skill.rating && !skill.featured)
      .map(expertiseToSkill);
  };

  @action setSkillsSubmitted = (submitted: boolean): void => {
    this.skillsStepSubmitted = submitted;
  };

  @action setProject = (project: NewProject | ExistingProject): void => {
    this.project = project;
  };

  @action setProjectMembers = (members: ExistingProject['members']): void => {
    this.project.members = members;
  };

  @action setDefaultProjectRole = (): void => {
    const mainTalentSpecialization =
      this.formData.talentProfile?.talentSpecializations
        ?.mainTalentSpecialization;

    if (!this.project.jobRole && mainTalentSpecialization) {
      this.setProject({
        ...this.project,
        jobRole: mainTalentSpecialization.name,
      });
    }
  };

  @action addNewEmptyReferral = (): void => {
    this.referrals?.unshift({
      _id: generateUniqueId(),
      firstName: '',
      lastName: '',
      linkedinUrl: '',
      website: '',
      email: '',
    });
  };

  @action setReferral = (referral: LocalUserReferral): void => {
    const referralIndex = this.referrals?.findIndex(
      (item) => item._id === referral._id,
    );
    referralIndex !== undefined && (this.referrals[referralIndex] = referral);
  };

  @action removeReferral = (referral: LocalUserReferral): void => {
    this.referrals = this.referrals.filter((item) => item._id !== referral._id);
  };

  @action setReferralsSubmitted = (submitted: boolean): void => {
    this.referralsStepSubmitted = submitted;
  };

  @action addNewEmptyTeamMember = (): void => {
    this.teamMembers?.unshift({
      _id: generateUniqueId(),
      fullName: '',
      linkedInUsername: '',
      email: '',
      teamRole: TeamMemberRole.Member,
    });
  };

  @action setTeamMembers = (members: LocalTeamMember[]): void => {
    this.teamMembers = members;
  };

  @action setTeamMember = (teamMember: LocalTeamMember): void => {
    const memberIndex = this.teamMembers?.findIndex(
      (item) => item._id === teamMember._id,
    );
    memberIndex !== undefined && (this.teamMembers[memberIndex] = teamMember);
  };

  @action removeTeamMember = (teamMember: LocalTeamMember): void => {
    this.teamMembers = this.teamMembers.filter(
      (item) => item._id !== teamMember._id,
    );
  };

  @action setSource = (sourceData: UserReferralSourceData): void => {
    this.source = sourceData;
  };

  @action setSourceEmail = (email?: string): void => {
    if (!this.source) return;
    this.source.sourceEmail = email;
  };

  @action setSourceSubmitted = (submitted: boolean): void => {
    this.sourceStepSubmitted = submitted;
  };

  @action setGeneralTalentCategory = (
    generalTalentCategory: TalentCategory,
  ): void => {
    this.generalTalentCategory = generalTalentCategory;
  };
}
