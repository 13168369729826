import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Button } from '@ateams/components';
import Modal from '@src/components/Modal';

const useStyles = createUseStyles({
  button: {
    margin: '0.5em',
    width: 200,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalMessage: {
    fontSize: 18,
    fontWeight: 'bolder',
    padding: '3em',
    textAlign: 'center',
  },
});

interface Props {
  downloadProgress: number;
  downloadUrl: string | null;
  open: boolean;
  onClose: () => void;
}

const DownloadModal = (props: Props): ReactElement => {
  const styles = useStyles();
  const { downloadProgress = 0, downloadUrl, open, onClose } = props;
  const fetching = !downloadUrl;

  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.modalContent}>
        <span className={styles.modalMessage}>
          <span style={{ fontSize: 48 }}>{fetching ? '🕵 ' : '🍾 '}</span>
          {fetching
            ? `\tHang tight, fetching builders... ${
                Math.round(100 * downloadProgress) || 0
              }%.`
            : '\tYour download is ready!'}
        </span>
        <Button
          className={styles.button}
          disabled={!downloadUrl}
          onClick={() => {
            if (downloadUrl && typeof window !== undefined) {
              window.open(downloadUrl);
              onClose();
            }
          }}
        >
          {downloadUrl ? 'Download results' : 'Fetching...'}
        </Button>
      </div>
    </Modal>
  );
};

export default DownloadModal;
