import React, { ReactElement } from 'react';
import { BasicContractPartyObject } from '@a_team/models/dist/ContractObject';
import { createUseStyles } from 'react-jss';
import { Colors } from '@ateams/components';
import cx from 'classnames';
import { format } from 'date-fns';
import { observer } from 'mobx-react';

interface StatusProps {
  party?: BasicContractPartyObject;
  custom?: boolean;
}

const useStyles = createUseStyles({
  root: {
    color: Colors.regularDark,
  },
  signed: {
    color: Colors.success,
  },
});

export const ContractStatusDisplay = observer(
  (props: StatusProps): ReactElement => {
    const { party, custom } = props;
    const styles = useStyles();
    const statusText = custom
      ? 'Uploaded'
      : party?.signedAt
      ? `Signed ${format(new Date(party.signedAt), 'PP')}`
      : 'Pending';

    return (
      <>
        {party && (
          <div
            className={cx(styles.root, {
              [styles.signed]: !!party.signedAt || custom,
            })}
          >
            {statusText}
          </div>
        )}
      </>
    );
  },
);
