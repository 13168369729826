import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { theme } from '@a_team/ui-components';
import { Spacing } from '@ateams/components';
import { TextInput } from '../../components/text-input';
import { InputLabel, Size, Text } from '../../components/typography';
import { VettingProcessFeedbackProject } from '@a_team/models/dist/vetting-processes/feedback';
import { EditActions } from '../../components/button';
import {
  RoleSelector,
  SelectValueWithCountContainer,
  TalentIndustrySelector,
  TalentSkillSelector,
} from '../../components/select';
import { TalentIndustryOptionType } from '@src/components/TalentIndustrySelector';
import { ValueTag } from '../../components/value-tag';
import { InputContainer } from '../../components/input-container';
import {
  OnRoleSelectorChange,
  talentSpecializationToOption,
} from '@src/views/VettingDashboard/role-selector';

export interface VettingProcessFeedbackProjectWithId
  extends VettingProcessFeedbackProject {
  id: string;
  isEditing: boolean;
}

interface ProjectProps {
  project: VettingProcessFeedbackProjectWithId;
  isEditing: boolean;
  onProjectChange?: (project: VettingProcessFeedbackProjectWithId) => void;
  onProjectRemove: (project: VettingProcessFeedbackProjectWithId) => unknown;
  onProjectEdit: (project: VettingProcessFeedbackProjectWithId) => unknown;
  isReadOnly?: boolean;
  className?: string;
}

const useProjectStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '416px',
    maxWidth: '416px',
    border: `1px solid ${theme.colors.Grey[300]}`,
    borderRadius: '4px',
    padding: Spacing.medium,
  },
  textInputContainer: {
    marginBottom: Spacing.large,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  inputLabel: {
    marginBottom: Spacing.small,
  },
  companyInput: {
    fontSize: Size.Large,
    fontWeight: 500,
  },
  talentSkillOrIndustriesSelector: {
    marginBottom: Spacing.small,
  },
  tagsContainer: {
    display: 'inline-flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '4px',
  },
  editActions: {
    position: 'absolute',
    top: Spacing.medium,
    right: Spacing.medium,
    cursor: 'pointer',
  },
});

export const Project: React.FC<ProjectProps> = React.memo((props) => {
  const { isReadOnly } = props;
  const styles = useProjectStyles();

  const onCompanyNameChange = (companyName: string) => {
    props.onProjectChange?.({
      ...props.project,
      companyName,
    });
  };

  const onImpactOrRoleChange = (impactOrRole: string) => {
    props.onProjectChange?.({
      ...props.project,
      impactOrRole,
    });
  };

  const onRoleChange: OnRoleSelectorChange = (option) => {
    props.onProjectChange?.({
      ...props.project,
      role: option?.talentSpecialization,
    });
  };

  const onTalentIndustriesChange = (options: TalentIndustryOptionType[]) => {
    props.onProjectChange?.({
      ...props.project,
      industries: options.map(({ talentIndustry }) => talentIndustry),
    });
  };

  const onDescriptionChange = (description: string) => {
    props.onProjectChange?.({
      ...props.project,
      description,
    });
  };

  const selectComponents = useMemo(
    () => ({
      ValueContainer: SelectValueWithCountContainer,
    }),
    [],
  );

  const hasSelectedSkills = Boolean(props.project.skills.length);
  const hasSelectedIndustries = Boolean(props.project.industries.length);

  return (
    <div className={cx(styles.container, props.className)}>
      {!isReadOnly && (
        <EditActions
          className={styles.editActions}
          isEditing={props.isEditing}
          onDeleteClick={() => props.onProjectRemove(props.project)}
          onEditClick={() => props.onProjectEdit(props.project)}
        />
      )}

      <InputContainer
        label={'Company Name'}
        className={styles.textInputContainer}
      >
        {props.isEditing ? (
          <TextInput
            className={styles.companyInput}
            value={props.project.companyName}
            onChange={(e) => onCompanyNameChange(e.target.value)}
            placeholder={'Enter Name...'}
            withLine={false}
          />
        ) : (
          <Text className={styles.companyInput}>
            {props.project.companyName}
          </Text>
        )}
      </InputContainer>

      <InputContainer
        label={'About the project'}
        className={styles.textInputContainer}
      >
        {props.isEditing ? (
          <TextInput
            value={props.project.description}
            onChange={(e) => onDescriptionChange(e.target.value)}
            placeholder={'Enter project details...'}
            withLine={false}
          />
        ) : (
          <Text style={{ overflowWrap: 'break-word' }}>
            {props.project.description}
          </Text>
        )}
      </InputContainer>

      {props.project.impactOrRole && (
        <InputContainer label={'Impact'} className={styles.textInputContainer}>
          {props.isEditing ? (
            <TextInput
              value={props.project.impactOrRole}
              onChange={(e) => onImpactOrRoleChange(e.target.value)}
              placeholder={'Enter impact the builder had...'}
              withLine={false}
            />
          ) : (
            <Text>{props.project.impactOrRole}</Text>
          )}
        </InputContainer>
      )}

      <InputContainer
        label={props.project.impactOrRole ? 'Role' : 'Impact'}
        className={styles.textInputContainer}
      >
        {props.isEditing ? (
          <div>
            <RoleSelector
              onChange={onRoleChange}
              defaultValue={
                props.project.role
                  ? talentSpecializationToOption(props.project.role)
                  : undefined
              }
              placeholder={'Select'}
              isClearable
              menuPortalTarget={document.body}
            />
          </div>
        ) : (
          <Text>{props.project.role?.name}</Text>
        )}
      </InputContainer>

      {hasSelectedSkills && (
        <div className={styles.textInputContainer}>
          <InputLabel className={styles.inputLabel}>Skills</InputLabel>

          {props.isEditing && hasSelectedSkills && (
            <TalentSkillSelector
              omitSkillsWithoutParentCategory
              className={styles.talentSkillOrIndustriesSelector}
              defaultValue={props.project.skills.map(({ id }) => id)}
              isDisabled
              isMulti
              placeholder={'Select'}
              hideTags
              isClearable={false}
              components={selectComponents}
              controlShouldRenderValue
              menuPortalTarget={document.body}
            />
          )}

          {hasSelectedSkills && (
            <div className={styles.tagsContainer}>
              {props.project.skills.map((skill) => (
                <ValueTag>
                  <Text size={Size.Small}>{skill.name}</Text>
                </ValueTag>
              ))}
            </div>
          )}
        </div>
      )}

      {(props.isEditing || hasSelectedIndustries) && (
        <div className={styles.textInputContainer}>
          <InputLabel className={styles.inputLabel}>Industries</InputLabel>

          {props.isEditing && (
            <TalentIndustrySelector
              className={styles.talentSkillOrIndustriesSelector}
              defaultValue={props.project.industries.map(({ id }) => id)}
              onChange={onTalentIndustriesChange}
              isMulti
              placeholder={'Select'}
              hideTags
              isClearable={false}
              components={selectComponents}
              controlShouldRenderValue
              menuPortalTarget={document.body}
            />
          )}

          {hasSelectedIndustries && (
            <div className={styles.tagsContainer}>
              {props.project.industries.map((industry) => (
                <ValueTag>
                  <Text size={Size.Small}>{industry.name}</Text>
                </ValueTag>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
});
