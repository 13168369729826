import { ReactNode } from 'react';
import { UserReferral } from '@a_team/models/dist/UserObject';
import { NewTeamMemberUserData } from '@ateams/api/dist/endpoints/Teams';
import { TalentSpecialization } from '@a_team/models/dist/TalentCategories';

type LocalTempId = string;

export enum RegistrationProgressStage {
  Intro = 'Intro',
  ApplyAsTeam = 'ApplyAsTeam',
  TeamUpResponse = 'TeamUpResponse',
  TeamMembers = 'TeamMembers',
  PersonalDetails = 'PersonalDetails',
  ExternalProfileLinks = 'ExternalProfileLinks',
  Location = 'Location',
  HourlyRate = 'HourlyRate',
  MainSpecialization = 'MainSpecialization',
  ExtraSpecializations = 'ExtraSpecializations',
  Skills = 'Skills',
  PreviousProjects = 'PreviousProjects',
  Referrals = 'Referrals',
  Source = 'Source',
}

export enum RegistrationFlow {
  Individual = 'Individual',
  TeamCreator = 'TeamCreator',
  TeamMemberResponse = 'TeamMemberResponse',
}

/**
 * The step data defined for each step of the registration.
 * @label: Label to be displayed in the progress bar when on current step
 * @title: Determines the page title for the current step
 * @videoUrl: The url of the video to be shown in the player
 * @videoOffset: Offset video player from the top
 * @gif: A gif url to display in place of a video
 * @component: The component to be rendered for the current step
 * @canSubmit: Enables/Disables the submit button for the step depending on the condition
 * @onSubmit: The function to be called upon step submission
 * @setSubmitted: An optional function to set the step's submitted status
 * @withMobileVideo: Displays the video also in mobile-web
 * @submitted: Determine if the step was already submitted
 */
export interface StepData {
  id: RegistrationProgressStage | 'Success';
  title?: string;
  label?: string;
  videoUrl?: string;
  gif?: string;
  offsetVideo?: number;
  component: ReactNode;
  canSubmit?: boolean;
  submitted?: boolean;
  setSubmitted?: (submitted: boolean) => void;
  onSubmit?: () => Promise<void>;
  withMobileVideo?: boolean;
}

export interface LocalUserReferral extends UserReferral {
  _id: LocalTempId;
}

export interface LocalTeamMember extends Omit<NewTeamMemberUserData, 'role'> {
  _id: LocalTempId;
  role?: TalentSpecialization;
  existingMember?: boolean;
}
