import React, { ReactElement, ReactNode, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { TableItem } from '@src/components/Table/index';
import { generateUniqueId } from '@src/helpers/strings';
import cx from 'classnames';

interface Props {
  cells: ReactNode[];
  cellClassName?: string;
}

const useStyles = createUseStyles({
  tr: {
    borderBottom: '1px solid #C0C0C0',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  td: {
    padding: 24,
    textAlign: 'center',
    borderRight: '1px solid #C0C0C0',
    '&:last-child': {
      borderRight: 'none',
    },
  },
});

export const TableRow = (props: Props): ReactElement => {
  const { cells, cellClassName } = props;

  const localCells: TableItem[] = useMemo(() => {
    return cells.map((cell) => {
      return { id: generateUniqueId(), content: cell };
    });
  }, [cells]);

  const styles = useStyles();
  return (
    <tr className={styles.tr}>
      {localCells.map((cell) => (
        <td key={cell.id} className={cx(styles.td, cellClassName)}>
          {cell.content}
        </td>
      ))}
    </tr>
  );
};
