import humanize from 'humanize-duration';
import { IANAZone } from 'luxon';

interface WorkingHoursTimeOptions {
  startingMinutes?: number;
  minutesPerSlot?: number;
  slotCount?: number;
  hideAmPm?: boolean;
}

export function getWorkingHoursTimeOptions(
  params?: WorkingHoursTimeOptions,
): { value: string; label: string }[] {
  const {
    startingMinutes = 0,
    minutesPerSlot = 30,
    slotCount = 48,
    hideAmPm = false,
  } = params || {};
  const options = [];
  for (let i = 0; i < slotCount; i++) {
    const minutes = startingMinutes + i * minutesPerSlot;
    if (minutes > 36 * 60) {
      // maximums of 36 hours of working hours on 2 slots
      break;
    }
    const minutesLabel = minutes - Math.floor(minutes / 1440) * 1440;
    options.push({
      value: String(minutes),
      label: getHoursFromMinutes(minutesLabel, hideAmPm),
    });
  }
  return options;
}

export function getHoursFromMinutes(
  minutes?: number,
  hideAmPm?: boolean,
  padHours?: boolean,
): string {
  if (typeof minutes === 'undefined') {
    return '';
  }
  let hour = Math.floor(minutes / 60);
  hour = hour - Math.floor(hour / 24) * 24;
  const minute = minutes % 60;
  const ampm = hour >= 12 ? 'PM' : 'AM';
  const hourAmPm = hour > 12 ? hour - 12 : hour;
  return `${
    hour ? hourAmPm.toString().padStart(padHours ? 2 : 1, '0') : 12
  }:${minute.toString().padStart(2, '0')} ${hideAmPm ? '' : ampm}`;
}

export function formatOffset(timezone?: string): string {
  if (!timezone) {
    return '';
  }
  try {
    const offset = IANAZone.create(timezone).offset(Date.now());
    return offset
      ? `${offset < 0 ? '' : '+'}${Math.floor(offset / 60)}:${String(
          offset % 60,
        ).padStart(2, '0')}`
      : '0:00';
  } catch (e) {
    return '';
  }
}

export function getHumanReadableTime(minutes: number): string {
  return humanize(minutes * 60 * 1000, {
    delimiter: ' and ',
  });
}
