import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { BuilderMissionStatus } from '@ateams/api/dist/endpoints/TeamGraph';
import { Checkbox, Select, TextColors } from '@ateams/components';
import { DateInput } from '../DateInput';
import { add } from 'date-fns';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery/index';

const MISSION_STATUS_OPTIONS = [
  { label: 'Not Active', value: BuilderMissionStatus.NotActive },
  { label: 'Active', value: BuilderMissionStatus.Active },
];

interface Props {
  requireMissionStatus?: boolean;
  missionStatus?: BuilderMissionStatus;
  setFilterValue: (filter: Partial<RoleFilter>) => void;
  requireHasScheduledEndDate?: boolean;
  availabilityDate?: string;
}

const useStyles = createUseStyles({
  container: {
    paddingTop: 10,
    display: 'flex',
  },
  requireMissionStatusCheckbox: {
    width: '35%',
  },
  selectInput: {
    width: '32%',
    paddingLeft: 12,
  },
  blockDatePickerWrapper: {
    paddingLeft: 12,
    width: '33%',
    borderRadius: 8,
    height: 'fit-content',
  },
  blockDatePicker: {
    padding: '0.6em 0.5em',
    marginBottom: 12,
    width: '100%',
    lineHeight: 'initial',
    borderRadius: 8,
    border: `1px solid ${TextColors.regularDark}`,
    '&:focus': {
      border: '1px solid #000',
    },
  },
});

const MissionStatusInput = (props: Props): ReactElement => {
  const {
    requireMissionStatus,
    missionStatus,
    setFilterValue,
    availabilityDate,
    requireHasScheduledEndDate,
  } = props;
  const styles = useStyles();

  const handleMissionStatusCheck = (checked?: boolean) => {
    return setFilterValue({
      requireMissionStatus: checked || undefined,
      missionStatus: missionStatus || BuilderMissionStatus.NotActive,
      availabilityDate,
    });
  };

  const handleStatusChange = (value: string | null | undefined) => {
    setFilterValue({
      requireMissionStatus,
      missionStatus: (value || undefined) as BuilderMissionStatus | undefined,
      availabilityDate,
    });
  };

  const handleDateChange = (input: Date | null): void => {
    if (!input) return;

    setFilterValue({
      requireMissionStatus,
      missionStatus: missionStatus || BuilderMissionStatus.NotActive,
      missionStatusAvailabilityDate: input?.toISOString().split('T')[0],
    });
  };

  return (
    <>
      <div className={styles.container}>
        <Checkbox
          className={styles.requireMissionStatusCheckbox}
          checked={requireMissionStatus}
          onChange={(e) =>
            handleMissionStatusCheck(e.target.checked || undefined)
          }
          label={`Mission status`}
          margin="none"
        />
        <Select
          className={styles.selectInput}
          onChange={(input) => handleStatusChange(input?.value)}
          options={MISSION_STATUS_OPTIONS}
          value={MISSION_STATUS_OPTIONS.find(
            ({ value }) => value === missionStatus,
          )}
          margin="none"
          styles={{
            control: () => ({
              display: 'flex',
              borderBottom: 'grey dashed 1px',
            }),
          }}
        />
        <DateInput
          minDate={add(new Date(), { days: 0 })}
          popperPlacement={'right'}
          selected={
            availabilityDate
              ? new Date(availabilityDate + 'T00:00:01')
              : add(new Date(), { days: 14 })
          }
          className={styles.blockDatePicker}
          wrapperClassName={styles.blockDatePickerWrapper}
          placeholderText="Latest start date..."
          onChange={(input) => handleDateChange(input as Date | null)}
        />
      </div>
      <div className={styles.container}>
        <Checkbox
          checked={requireHasScheduledEndDate}
          onChange={(e) =>
            setFilterValue({
              requireHasScheduledEndDate: e.target.checked || undefined,
            })
          }
          label="Only show builders with Scheduled End Date"
          margin="none"
        />
      </div>
    </>
  );
};

export default MissionStatusInput;
