import React, {
  ReactElement,
  RefObject,
  useRef,
  MouseEvent,
  useState,
  useCallback,
  useMemo,
  memo,
} from 'react';
import { createUseStyles } from 'react-jss';
import OutlinedControl, {
  BasicOutlinedControlProps,
} from '@src/components/Inputs/OutlinedControl';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  isValidPhoneNumber,
  Value,
} from 'react-phone-number-input';

export interface OutlinedInputProps extends BasicOutlinedControlProps {
  value: string;
  onChange(value: string): void;
}

const useStyles = createUseStyles({
  input: {
    '& > div': {
      marginRight: '12px',
      paddingRight: '12px',
      borderRight: '#c0c0c0 1px solid',
    },
    '& > input': {
      margin: '-1em 0',
      padding: '1em 0',
      border: 0,
      background: 'none',
      width: '100%',
      color: 'inherit',
    },
  },
});

const PhoneNumberInput = memo((props: OutlinedInputProps): ReactElement => {
  const { value, onChange, ...parentProps } = props;

  const styles = useStyles();
  const ref = useRef<HTMLInputElement>(null);
  const [localStatePhoneNumber, setLocalStatePhoneNumber] = useState(value);

  const handleChange = useCallback((value?: Value) => {
    setLocalStatePhoneNumber(value || '');
  }, []);

  const handleBlur = useCallback(() => {
    onChange(localStatePhoneNumber);
  }, [localStatePhoneNumber]);

  const numberInputProps = useMemo(() => {
    return { placeholder: '+1 (000) 000-0000' };
  }, []);

  const input = (
    <PhoneInput
      // ignore the type error, it's a bug in the library
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref as unknown as RefObject<PhoneInput>}
      // inputComponent={props => <input {...props} ref={ref} />}
      className={styles.input}
      value={localStatePhoneNumber}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={parentProps.disabled}
      addInternationalOption={false}
      defaultCountry="US"
      numberInputProps={numberInputProps}
    />
  );

  const handleClick = (e: MouseEvent<HTMLDivElement>): void => {
    if (!ref.current || e.currentTarget.contains(document.activeElement)) {
      return;
    }

    ref.current.focus();
  };

  const handleDoubleClick = (): void => {
    const { current } = ref;
    if (!current) return;

    current.focus();
    current.select();
  };

  return (
    <OutlinedControl
      input={input}
      error={value ? !isValidPhoneNumber(value) : false}
      {...parentProps}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    />
  );
});

export default PhoneNumberInput;
