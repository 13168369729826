import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { Margins, Select, SelectOption } from '@ateams/components';
import { apiAccounts, apiClientSignals } from '@ateams/api';
import { useStores } from '@src/stores';
import {
  ClientSignalActionType,
  ClientSignalObject,
} from '@a_team/models/src/ClientSignals';
import { MinimalAccountMemberObject } from '@a_team/models/dist/Account';

type SelectChangeValue = SelectOption | SelectOption[] | null;

interface Props {
  selectedAccount?: string;
  userSelection?: string[];
  onChange: (roles: string[]) => void;
  itemCountPrefix: string;
  margin: keyof typeof Margins;
}

const AccountUserSearch = (props: Props): ReactElement => {
  const { auth } = useStores();
  const { selectedAccount, userSelection, onChange, margin, itemCountPrefix } =
    props;
  const [members, setMembers] = useState<MinimalAccountMemberObject[]>([]);
  const [clientSignals, setClientSignals] = useState<ClientSignalObject[]>([]);
  const clientSignalTypes: ClientSignalActionType[] = ['Star', 'NotInterested'];

  useEffect(() => {
    if (selectedAccount) {
      apiClientSignals
        .getClientSignalsByAccountId(auth, selectedAccount)
        .then((items) => {
          setClientSignals(items);
        });
    }
  }, [selectedAccount]);

  const clientSignalsFiltered = useMemo(
    () =>
      clientSignals.filter(({ actionType }) =>
        clientSignalTypes.includes(actionType),
      ),
    [clientSignals],
  );

  useEffect(() => {
    if (!selectedAccount) return;

    apiAccounts
      .adminGetAccountMembers(auth, selectedAccount)
      .then(({ items }) => {
        setMembers(items);
      });
  }, [selectedAccount]);

  const userOptions = useMemo(() => {
    if (!selectedAccount) return [];

    return members
      .filter((item) =>
        clientSignalsFiltered.find(({ client }) => client === item.uid),
      )
      .map(({ uid, firstName, lastName }) => ({
        value: uid,
        label: `${firstName} ${lastName}`,
      }));
  }, [members, clientSignalsFiltered]);

  const selectedRoles: SelectOption[] = useMemo(() => {
    if (userOptions.length === 0 || userSelection?.length === 0) return [];

    return userOptions.filter(({ value }) => userSelection?.includes(value));
  }, [userOptions, userSelection]);

  const handleSelections = (selections: SelectChangeValue): void => {
    const selectedOptions = !selections
      ? []
      : Array.isArray(selections)
      ? selections
      : [selections];

    onChange(selectedOptions.map(({ value }) => value));
  };

  return (
    <Select
      hideTags
      isMulti={true as false}
      isSearchable
      showItemCount
      itemCountPrefix={itemCountPrefix}
      placeholder=""
      value={selectedRoles}
      margin={margin}
      onChange={handleSelections}
      options={userOptions}
    />
  );
};

AccountUserSearch.defaultProps = { margin: 'default' };
export default AccountUserSearch;
