import React, { ReactElement } from 'react';
import { Props } from '@src/views/Mission/Documents/contractsTable/index';
import { format } from 'date-fns';
import { createUseStyles } from 'react-jss';
import TextButton from '@src/components/TextButton';
import { Icon, IconType, Card } from '@ateams/components';
import { ContractStatusDisplay } from '@src/views/Mission/Documents/contractsTable/ContractStatusDisplay';
import {
  ContractPartyType,
  ContractTitles,
} from '@a_team/models/dist/ContractObject';

const useStyles = createUseStyles({
  row: {
    marginBottom: 8,
  },
  title: {
    marginTop: 4,
    fontSize: 15,
    fontWeight: 600,
  },
});

export const MobileContractsTable = (props: Props): ReactElement => {
  const styles = useStyles();

  return (
    <Card style={{ padding: 0, marginRight: 0 }}>
      {props.contracts.map((contract) => (
        <div
          style={{
            padding: '24px 16px',
            borderBottom: '1px solid #C0C0C0',
            fontSize: 15,
          }}
        >
          <div
            className={styles.row}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span style={{ fontWeight: 500 }}>
              {contract.signedAt && format(new Date(contract.signedAt), 'PP')}
            </span>
            {contract.downloadURL && (
              <TextButton
                highlight
                onClick={() => window.open(contract.downloadURL)}
              >
                <Icon type={IconType.OrangeDownload} /> Download
              </TextButton>
            )}
          </div>
          <div className={styles.row}>
            <h5 className={styles.title}>{ContractTitles[contract.type]}</h5>
            <ContractStatusDisplay
              party={contract.parties.find(
                ({ type }) => type === ContractPartyType.MissionRole,
              )}
            />
          </div>
        </div>
      ))}
    </Card>
  );
};
