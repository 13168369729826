import { ExistingJob, NewJob } from '@src/stores/Profile/models';
import { format } from 'date-fns';

export const getDateString = (job: ExistingJob | NewJob): string => {
  const { startDate, endDate } = job;

  if (startDate && endDate) {
    return ` ${format(new Date(startDate), 'MM/yyyy')} - ${format(
      new Date(endDate),
      'MM/yyyy',
    )}`;
  }

  if (startDate) {
    return ` ${format(new Date(startDate), 'MM/yyyy')} - Present`;
  }

  return '';
};
