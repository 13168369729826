import React, { ReactElement, ReactNode } from 'react';
import Icon, { IconType } from '../Icon';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';

export interface AccordionMenuItemProps {
  id: string | number;
  title: string | ReactNode;
  openItemId?: string | number | undefined;
  onOpen?: (id: string | number | undefined) => void;
  withArrow?: boolean;
  children?: ReactNode;
}

const useStyles = createUseStyles({
  onboardingGroup: (props: AccordionMenuItemProps) => ({
    background: '#F7F7F7',
    padding: '16px 24px',
    position: 'relative',
    cursor: 'pointer',
    ...(props.withArrow && {
      paddingRight: 60,
    }),
  }),
  arrow: {
    position: 'absolute',
    right: 24,
    top: 16,
    cursor: 'pointer',
    transition: 'all 0.3s',
  },
  content: {
    maxHeight: 0,
    overflow: 'hidden',
    transition: 'all 0.3s ease',
    '&.open': {
      maxHeight: 500,
    },
  },
});

export const AccordionMenuItem = (
  props: AccordionMenuItemProps,
): ReactElement => {
  const styles = useStyles(props);
  const isOpen = props.openItemId === props.id;

  return (
    <div>
      <div
        className={styles.onboardingGroup}
        onClick={() =>
          props.onOpen && props.onOpen(isOpen ? undefined : props.id)
        }
      >
        {props.title}
        {props.withArrow && (
          <Icon
            type={IconType.ArrowDown}
            muted
            size="exact"
            flip={isOpen ? undefined : 'vertical'}
            className={styles.arrow}
          />
        )}
      </div>
      <div className={cx(styles.content, { open: isOpen })}>
        {props.children}
      </div>
    </div>
  );
};
