import { MissionStatus } from '@a_team/models/dist/MissionObject';
import {
  CompanyLikedYouNotificationObject,
  NotificationType,
} from '@a_team/models/dist/NotificationObject';
import { ClientSignalObject } from '@a_team/models/src/ClientSignals';
import React from 'react';
import Notification from './Notification';

interface CompanyLikedYouNotificationProps {
  notification: CompanyLikedYouNotificationObject;
  onMarkAsRead(): void;
  isRead?: boolean;
  onClientLikedYou: () => void;
  data: ClientSignalObject;
}

const CompanyLikedYouNotification = ({
  isRead,
  data,
  onMarkAsRead,
  onClientLikedYou,
  notification,
}: CompanyLikedYouNotificationProps) => {
  const isMissionPublished = data?.mission?.status === MissionStatus.Published;
  const companyName = data?.clientName;

  const title = notification.justPublished
    ? `${companyName} just published a mission`
    : `${companyName} starred your profile`;

  const description = notification.justPublished
    ? 'You have a high potential to be selected'
    : isMissionPublished
    ? 'Review their mission and apply.'
    : 'Get selected for their next mission.';

  return (
    <>
      <Notification
        createdAt={notification.createdAt}
        notificationType={NotificationType.CompanyLikedYou}
        ctaName={isMissionPublished ? 'View mission' : `Learn more`}
        ctaAction={() => {
          onClientLikedYou();
        }}
        title={title}
        description={description}
        imageUrl={data?.clientLogoUrl}
        onMarkAsRead={onMarkAsRead}
        isRead={isRead}
      />
    </>
  );
};

export default CompanyLikedYouNotification;
