import { Modal } from '@a_team/ui-components';
import { Breakpoints } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import ticketIcon from './ticket-with-bg.svg';
import GradientBox from '@src/components/GradientBox';

interface LimitedMissionsModalProps {
  open: boolean;
}

const useStyles = createUseStyles({
  '@global': {
    '.ReactModal__Overlay': {
      top: '80px !important',
      background: 'none !important',
    },
  },
  modal: {
    textAlign: 'center',
    padding: 0,
    width: '680px !important',
    display: 'flex',
    flexDirection: 'row',
    '& a': {
      color: '#6D00D7',
    },
    '& > div': {
      padding: '0 !important',
    },
    left: '0 !important',
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: '"illustration" "content"',
    textAlign: 'left',
  },
  content: {
    gridArea: 'content',
    padding: 20,
    flex: 1,
  },
  illustration: {
    gridArea: 'illustration',
    backgroundColor: '#FCFAFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 80,
    borderRadius: '20px',
  },
  list: {
    padding: 0,
    margin: 0,
  },
  image: {
    maxWidth: '100%',
    borderRadius: '50%',
  },
  desktopImage: {
    display: 'none',
  },
  mobileImage: {
    display: 'block',
  },
  title: {
    fontSize: 24,
    fontWeight: '500',
  },
  description: {
    fontSize: 15,
  },
  note: {
    marginTop: 40,
    color: '#757575',
    textAlign: 'center',
  },
  listItem: {
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  ticketIcon: {
    marginRight: 10,
  },
  [`@media (min-width: 1024px)`]: {
    modal: {
      left: 'calc(50% + 50px) !important',
    },
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    '@global': {
      '.ReactModal__Overlay': {
        top: '0 !important',
        left: '120px !important',
      },
    },
    modal: {
      padding: '0 !important',
      maxWidth: '850px !important',
      width: '100% !important',
    },
    illustration: {
      padding: 0,
    },
    wrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: '"content illustration"',
    },
    content: {
      padding: 40,
    },
    desktopImage: {
      display: 'block',
    },
    mobileImage: {
      display: 'none',
    },
  },
});

const LimitedMissionsModal = ({
  open,
}: LimitedMissionsModalProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Modal
      variant="slideUp"
      isOpen={open}
      className={styles.modal}
      hideCloseButton={true}
      shouldHideGradientStroke={true}
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.header}>
            <GradientBox>Role waitlisted</GradientBox>
          </div>
          <div className={styles.body}>
            <h2 className={styles.title}>
              Limited missions available for your role at the moment.
            </h2>
            <p className={styles.description}>
              Our team was impressed with your profile, and you’ve moved to the
              next step. We’ll contact you via email once relevant roles are
              available, at which point you can:
            </p>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <img
                  src={ticketIcon}
                  alt="Ticket"
                  className={styles.ticketIcon}
                />
                Schedule your evaluation call
              </li>
              <li className={styles.listItem}>
                <img
                  src={ticketIcon}
                  alt="Ticket"
                  className={styles.ticketIcon}
                />
                Apply to your first mission
              </li>
            </ul>
            <p className={styles.note}>
              There’s nothing more you need to do right now.
            </p>
          </div>
        </div>
        <div className={styles.illustration}>
          <img
            src="https://ucarecdn.com/a0c8d2f1-57a2-4e8b-aaba-fcef01ee8695/-/preview/850x900/"
            alt="Illustration"
            className={`${styles.image} ${styles.desktopImage}`}
          />
          <img
            src="https://ucarecdn.com/4a0c84e2-667c-4384-94eb-b0e58d645b3e/-/preview/1000x480/"
            alt="Illustration"
            className={`${styles.image} ${styles.mobileImage}`}
          />
        </div>
      </div>
    </Modal>
  );
};

export default LimitedMissionsModal;
