import React, { ReactElement } from 'react';
import notSelected from './notselected.svg';
import selected from './selected.svg';
import { useCommonStyles } from '../../commonStyles';

interface Props {
  checked?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (option: any) => void;
  onOtherValue: (otherValue: string) => void;
  placeholder: string;
}

export const OtherOption = (props: Props): ReactElement => {
  const styles = useCommonStyles();
  const { option, onClick, checked = false, onOtherValue, placeholder } = props;

  return (
    <div className={styles.otherOptionWrap} onClick={() => onClick(option)}>
      <div className={styles.otherOption}>
        <img src={checked ? selected : notSelected} alt="selected" />
        <div className={styles.label}>Other</div>
      </div>
      {checked && (
        <input
          type="text"
          autoFocus
          className={styles.otherOptionInput}
          placeholder={placeholder}
          onChange={(e) => {
            onOtherValue(e.target.value);
          }}
        />
      )}
    </div>
  );
};
