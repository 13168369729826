import React from 'react';
import { createUseStyles } from 'react-jss';

import star from '../assets/icons/star-alt.svg';
import starHalf from '../assets/icons/star-half-alt.svg';
import starEmpty from '../assets/icons/star-empty-alt.svg';

const useStyles = createUseStyles({
  container: {
    margin: 0,
    padding: 0,
    '& li': {
      display: 'inline',
      listStyle: 'none',
    },
    '& img': {
      width: 20,
      height: 20,
      marginRight: 4,
    },
  },
});

interface Props {
  score: number;
}

export const RatingStars: React.FC<Props> = ({ score }) => {
  const styles = useStyles();
  const roundedScoreToHalf = Math.round(score * 2) / 2;
  const starImages = Array(5)
    .fill('')
    .map((item, idx) => {
      if (roundedScoreToHalf >= idx + 1) {
        return star;
      } else if (roundedScoreToHalf === idx + 0.5) {
        return starHalf;
      } else {
        return starEmpty;
      }
    });
  return (
    <ul className={styles.container}>
      {starImages.map((image, index) => (
        <li key={index}>
          <img src={image} alt="star"></img>
        </li>
      ))}
    </ul>
  );
};
