import React, { ReactElement } from 'react';
import Indicator from '@src/components/Indicator';
import { createUseStyles } from 'react-jss';

interface Props {
  drafting?: boolean;
  companyName?: string;
}
const useStyles = createUseStyles({
  root: {
    borderBottom: '1px solid #6E7177',
    fontSize: 15,
    padding: 12,
    paddingLeft: 24,
    display: 'flex',
    alignItems: 'center',
  },
  indicator: {
    marginRight: 8,
    width: 8,
    height: 8,
  },
});

const RoleDraftingStatus = (props: Props): ReactElement => {
  const { drafting, companyName } = props;
  const styles = useStyles();
  const statusColors: Record<'drafting' | 'notDrafting', string> = {
    drafting: '#FF0FAD',
    notDrafting: '#818388',
  };

  return (
    <div
      data-testing-id={'mission-role-card-role-drafting-status'}
      className={styles.root}
      style={{
        borderColor: drafting
          ? statusColors.drafting
          : statusColors.notDrafting,
      }}
    >
      <Indicator
        fill={drafting ? statusColors.drafting : statusColors.notDrafting}
        className={styles.indicator}
      />
      {drafting
        ? 'We’re actively looking for builders. This is the best time to request to join.'
        : `We've proposed builders to ${
            companyName || 'the client'
          }. You can still apply but there's a low chance of acceptance.`}
    </div>
  );
};

export default RoleDraftingStatus;
