import React from 'react';
import { FieldReadOnly } from '../general/FieldReadOnly';
import { createUseStyles } from 'react-jss';
import { Spacing } from '@ateams/components';

const useStyles = createUseStyles({
  builderEvaluationInfo: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.xLarge,
  },
});

export interface BuilderEvaluationInfoProps {
  evaluationType: string;
  roleCategory: string;
  admin: string;
  interviewer: string;
}

export const BuilderEvaluationInfo = ({
  evaluationType,
  roleCategory,
  admin,
  interviewer,
}: BuilderEvaluationInfoProps) => {
  const styles = useStyles();

  return (
    <div className={styles.builderEvaluationInfo}>
      <FieldReadOnly label="Evaluation type" value={evaluationType} />
      <FieldReadOnly label="Role category" value={roleCategory} />
      <FieldReadOnly label="Admin" value={admin} />
      <FieldReadOnly label="Interviewer" value={interviewer} />
    </div>
  );
};
