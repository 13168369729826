import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { OnUserSelect } from '@src/components/UserSelector';
import { Colors, SelectProps, Spacing } from '@ateams/components';
import { OnVetterSelect } from '@src/views/VettingDashboard/vetter-selector';
import { Vetter } from '@a_team/models/dist/vetter';
import {
  ContactOwner,
  VettingType,
  vettingTypeToStatusMap,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { TalentCategory } from '@a_team/models/dist/TalentCategories';
import {
  OnTalentCategorySelectorChange,
  talentCategoryToOption,
} from '@src/components/TalentCategorySelector';
import { ShowMoreButton } from '../components/show-more';
import useToggle from '@src/hooks/useToggle';
import { Card } from '../components/card';
import { DateISOString } from '@a_team/models/dist/misc';
import {
  Select,
  TalentCategorySelector,
  UserSelector,
  VetterSelector,
} from '../components/select';
import { InputContainer } from '../components/input-container';
import { Size } from '../components/typography';
import { DateInput } from '../components/date-input';
import { VettingFeedbackFormErrors } from './form-errors';

const useStyles = createUseStyles({
  container: {
    backgroundColor: Colors.backgroundMedium,
  },
  inputsRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: Spacing.medium,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  inputContainer: {
    width: '200px',
    marginRight: Spacing.medium,
    '&:last-child': {
      marginRight: 0,
    },
  },
});

const vettingTypeOptions = Object.entries(vettingTypeToStatusMap).map(
  ([key, value]) => ({
    value: key,
    label: value,
  }),
);

export interface VettingFeedbackFormGeneralData {
  contactOwner?: ContactOwner;
  vetter?: Vetter;
  vettingType: VettingType;
  category?: TalentCategory;
  vettingStartedDate?: DateISOString;
  submittedFormDate?: DateISOString;
  notifiedInterviewer?: DateISOString;
  feedbackFormSentDate?: DateISOString;
}

export type OnVettingFeedbackFormGeneralDataChange = (
  data: VettingFeedbackFormGeneralData,
) => void;

export interface VettingFeedbackFormGeneralDataProps {
  errors: VettingFeedbackFormErrors['generalData'];
  defaultValues: VettingFeedbackFormGeneralData;
  onChange?: OnVettingFeedbackFormGeneralDataChange;
  isReadOnly?: boolean;
  isAdmin?: boolean;
  className?: string;
}

export const VettingFeedbackFormGeneralDataForm: React.FC<VettingFeedbackFormGeneralDataProps> =
  React.memo((props) => {
    const { isReadOnly, isAdmin } = props;
    const styles = useStyles();
    const [contactOwner, setContactOwner] = useState(
      props.defaultValues.contactOwner,
    );
    const [vetter, setVetter] = useState(props.defaultValues.vetter);
    const [vettingType, setVettingType] = useState(
      props.defaultValues.vettingType,
    );
    const [category, setCategory] = useState(props.defaultValues.category);
    const [isShowingMore, toggleIsShowingMore] = useToggle(isReadOnly);

    const vettingTypeOption = useMemo(
      () => vettingTypeOptions.find((option) => option.value === vettingType),
      [vettingType],
    );

    const onChange = (data: Partial<VettingFeedbackFormGeneralData>) => {
      props.onChange?.({
        category: data.category || category,
        contactOwner: data.contactOwner || contactOwner,
        vetter: data.vetter || vetter,
        vettingType: data.vettingType || vettingType,
        vettingStartedDate: props.defaultValues.vettingStartedDate,
        feedbackFormSentDate: props.defaultValues.feedbackFormSentDate,
        notifiedInterviewer: props.defaultValues.notifiedInterviewer,
        submittedFormDate: props.defaultValues.submittedFormDate,
      });
    };

    const onContactOwnerChange: OnUserSelect = (option) => {
      if (option?.user) {
        setContactOwner(option.user);
        onChange({ contactOwner: option.user });
      }
    };

    const onVetterSelect: OnVetterSelect = (option) => {
      if (option?.vetter) {
        setVetter(option.vetter);
        onChange({ vetter: option.vetter });
      }
    };

    const onVettingTypeChange: SelectProps['onChange'] = (option) => {
      const vettingType = option?.value as VettingType;

      setVettingType(vettingType);
      onChange({ vettingType });
    };

    const onVettingCategoryChange: OnTalentCategorySelectorChange = (
      option,
    ) => {
      const category = option?.talentCategory;

      setCategory(category);
      onChange({ category });
    };

    const specializationDefaultValue = useMemo(
      () =>
        props.defaultValues.category
          ? talentCategoryToOption(props.defaultValues.category)
          : undefined,
      [props.defaultValues.category],
    );

    return (
      <Card className={cx(styles.container, props.className)}>
        <div className={styles.inputsRow}>
          <UserSelector
            inputContainerClassName={styles.inputContainer}
            label={'Admin'}
            defaultValue={contactOwner}
            onUserSelect={onContactOwnerChange}
            isClearable={false}
            isReadOnly={isReadOnly}
            isDisabled={!isAdmin}
            menuPortalTarget={document.body}
            data-testing-id={'vetting-feedback-form-admin-selector'}
          />

          <VetterSelector
            inputContainerClassName={styles.inputContainer}
            label={isReadOnly ? 'Interviewer Name' : 'Interviewer Name *'}
            defaultValue={vetter}
            onVetterSelect={onVetterSelect}
            isClearable={false}
            error={props.errors.interviewer}
            isReadOnly={isReadOnly}
            isDisabled={!isAdmin}
            menuPortalTarget={document.body}
            data-testing-id={'vetting-feedback-form-interviewer-selector'}
          />

          <Select
            inputContainerClassName={styles.inputContainer}
            label={isReadOnly ? 'Evaluation Type' : 'Evaluation Type *'}
            value={vettingTypeOption}
            onChange={onVettingTypeChange}
            isClearable={false}
            hideSelectedOptions={false}
            options={vettingTypeOptions}
            isReadOnly={isReadOnly}
            isDisabled={!isAdmin}
            menuPortalTarget={document.body}
            data-testing-id={'vetting-feedback-form-evaluation-type-selector'}
          />

          <TalentCategorySelector
            inputContainerClassName={styles.inputContainer}
            label={isReadOnly ? 'Role category' : 'Role category *'}
            defaultValue={specializationDefaultValue}
            onChange={onVettingCategoryChange}
            isClearable={false}
            error={props.errors.category}
            isVettingEligible
            isReadOnly={isReadOnly}
            isDisabled={!isAdmin}
            menuPortalTarget={document.body}
            data-testing-id={'vetting-feedback-form-role-category-selector'}
          />
        </div>

        {isShowingMore && (
          <div className={styles.inputsRow}>
            {props.defaultValues.vettingStartedDate && (
              <InputContainer
                className={styles.inputContainer}
                label={'Evaluation started'}
                labelSize={Size.Small}
              >
                <DateInput
                  selected={new Date(props.defaultValues.vettingStartedDate)}
                  isDisabled
                  isClearable={false}
                  isReadOnly={isReadOnly}
                />
              </InputContainer>
            )}

            {props.defaultValues.submittedFormDate && (
              <InputContainer
                className={styles.inputContainer}
                label={'Pre-evaluation form submitted'}
                labelSize={Size.Small}
              >
                <DateInput
                  selected={new Date(props.defaultValues.submittedFormDate)}
                  isDisabled
                  isClearable={false}
                  isReadOnly={isReadOnly}
                />
              </InputContainer>
            )}

            {props.defaultValues.notifiedInterviewer && (
              <InputContainer
                className={styles.inputContainer}
                label={'Interviewer notified'}
                labelSize={Size.Small}
              >
                <DateInput
                  selected={new Date(props.defaultValues.notifiedInterviewer)}
                  isDisabled
                  isClearable={false}
                  isReadOnly={isReadOnly}
                />
              </InputContainer>
            )}

            {props.defaultValues.feedbackFormSentDate && (
              <InputContainer
                className={styles.inputContainer}
                label={'Feedback form submitted'}
                labelSize={Size.Small}
              >
                <DateInput
                  selected={new Date(props.defaultValues.feedbackFormSentDate)}
                  isDisabled
                  isClearable={false}
                  isReadOnly={isReadOnly}
                />
              </InputContainer>
            )}
          </div>
        )}

        {!isReadOnly && (
          <ShowMoreButton
            isShowingMore={isShowingMore}
            onClick={toggleIsShowingMore}
          />
        )}
      </Card>
    );
  });
