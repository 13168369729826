import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { theme } from '@a_team/ui-components';
import { Spacing } from '@ateams/components';
import { DownArrow, UpArrow } from './icons/arrows';

export interface ShowMoreButtonProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  isShowingMore: boolean;
  showMoreLabel?: string;
  showLessLabel?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.colors.Hannibal[600],
    cursor: 'pointer',
  },
  label: {
    color: 'inherit',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  spacing: {
    width: Spacing.small,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    height: '12px',
    width: '12px',
  },
});

export const ShowMoreButton: React.FC<ShowMoreButtonProps> = (props) => {
  const { isShowingMore, showMoreLabel, showLessLabel, ...rest } = props;
  const styles = useStyles();

  const label = isShowingMore
    ? showLessLabel || 'Show Less'
    : showMoreLabel || 'Show More';

  return (
    <div {...rest} className={cx(styles.container, props.className)}>
      <div className={styles.label}>{label}</div>
      <div className={styles.spacing} />
      <div className={styles.iconContainer}>
        {isShowingMore ? <UpArrow /> : <DownArrow />}
      </div>
    </div>
  );
};
