import { Breakpoints } from '@ateams/components';
import cx from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { ModalPages } from '.';
import PageIndicator from './PageIndicator';

interface HeadProps {
  className?: string;
  page: ModalPages;
  isNewUser: boolean;
  isUserOnMission: boolean;
}

const useStyles = createUseStyles({
  title: {
    marginTop: 0,
  },
  container: {
    marginBottom: '12px',
    paddingBottom: '12px',
    borderBottom: '1px solid #EFEFF0',
  },
  description: {
    marginBottom: '35px',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    container: {
      borderBottom: 'none',
      marginBottom: '0',
      paddingBottom: '0',
    },
    description: {
      marginBottom: '16px',
    },
  },
});

const Head = ({
  className,
  page,
  isNewUser,
  isUserOnMission,
}: HeadProps): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={cx(className, styles.container)}>
      <h4 className={styles.title}>
        {isNewUser ? 'Updated Code of Conduct' : 'Welcome to A.Team!'}
      </h4>

      {isNewUser ? (
        <p className={styles.description}>
          We have updated our Platform and Mission Code of Conduct. Please take
          a moment to read through both documents.
        </p>
      ) : (
        <p className={styles.description}>
          We’re happy to have you here. We just need you to go over the final
          few details.
        </p>
      )}

      <PageIndicator isUserOnMission={isUserOnMission} page={page} />
    </div>
  );
};

export default Head;
