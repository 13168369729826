import { apiTalentSpecializations } from '@ateams/api';
import { useStores } from '@src/stores';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './keys';
import { roleToExpertise } from '@src/helpers/expertise';
import { Expertise } from '@src/stores/Profile/models';

export const useQueryTalentSpecializationRoles = ({
  query,
  onSuccess,
}: {
  query: string;
  onSuccess?: (data: Expertise[]) => void;
}) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.specializations.list(query),
    queryFn: async () => {
      const roles = await apiTalentSpecializations.queryTalentSpecializations(
        auth,
        {
          searchTerm: query,
        },
      );

      return roles.items.map((role) => roleToExpertise(role));
    },
    onSuccess: onSuccess,
  });
};
