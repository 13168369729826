import React from 'react';
import { createUseStyles } from 'react-jss';
import { VetterSuggestion } from '@a_team/models/dist/vetter';
import { Link } from 'react-router-dom';
import UserAvatar from '@src/components/UserAvatar';
import {
  OnRadioButtonClick,
  RadioButton,
} from '@src/components/RadioGroup/RadioButton';

export type OnVetterSuggestionCheck = (suggestion: VetterSuggestion) => void;

export interface VetterSuggestionRowProps {
  suggestion: VetterSuggestion;
  checked: boolean;
  onCheck: OnVetterSuggestionCheck;
}

const useStyles = createUseStyles({
  marginBottom: {
    height: '12px',
    '&:last-child': {
      height: 0,
    },
  },
  radioButtonContainer: {
    margin: 0,
    marginRight: '8px',
    alignSelf: 'center',
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  profilePicture: {
    marginRight: '12px',
  },
  userDataContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const VetterSuggestionRow: React.FC<VetterSuggestionRowProps> = (
  props,
) => {
  const { suggestion, checked } = props;
  const styles = useStyles();

  const onCheck: OnRadioButtonClick = () => {
    props.onCheck(suggestion);
  };

  return (
    <>
      <tr>
        <td className={styles.userContainer}>
          <RadioButton
            item={{ value: suggestion.id, label: '' }}
            checked={checked}
            onClick={onCheck}
            className={styles.radioButtonContainer}
          />

          <Link
            to={{ pathname: suggestion.user.profileURL }}
            target="_blank"
            className={styles.profilePicture}
          >
            <UserAvatar src={suggestion.user.profilePictureURL} />
          </Link>

          <div className={styles.userDataContainer}>
            <b>{suggestion.user.fullName}</b>
            <div>{suggestion.user.email}</div>
          </div>
        </td>

        <td>
          <div>Total interview capacity: {suggestion.interviewCapacity}</div>
          <div>
            Remaining interview capacity:{' '}
            {suggestion.remainingInterviewCapacity}
          </div>
        </td>
      </tr>
      <tr className={styles.marginBottom} />
    </>
  );
};
