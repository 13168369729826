import { TextColors } from '@ateams/components';
import { add, formatISO, sub } from 'date-fns';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { DateInput } from '../DateInput';

interface Props {
  createdAtFrom?: string;
  createdAtTo?: string;
  onChange: (data: { createdAtFrom?: string; createdAtTo?: string }) => void;
}

const useStyles = createUseStyles({
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  blockDatePickerWrapper: {
    borderRadius: 4,
    height: 'fit-content',
  },
  blockDatePicker: {
    padding: '0.6em 0.5em',
    marginBottom: 12,
    width: '100%',
    lineHeight: 'initial',
    borderRadius: 4,
    border: `1px solid ${TextColors.regularDark}`,
    '&:focus': {
      border: '1px solid #000',
    },
  },
});

const CreatedAtRangeFilter = (props: Props): ReactElement => {
  const styles = useStyles();
  const { createdAtFrom, createdAtTo, onChange } = props;

  return (
    <div className={styles.datePickerContainer}>
      <DateInput
        dateFormat={'dd/MM/yyyy'}
        maxDate={
          createdAtTo
            ? sub(new Date(createdAtTo), { days: 1 })
            : add(new Date(), { days: 0 })
        }
        popperPlacement={'right'}
        selected={createdAtFrom ? new Date(createdAtFrom) : null}
        className={styles.blockDatePicker}
        wrapperClassName={styles.blockDatePickerWrapper}
        placeholderText="Created from..."
        onChange={(input) => {
          onChange({
            createdAtFrom: input
              ? formatISO(input as Date, { representation: 'date' })
              : undefined,
          });
        }}
      />
      <DateInput
        dateFormat={'dd/MM/yyyy'}
        maxDate={add(new Date(), { days: 0 })}
        minDate={
          createdAtFrom ? add(new Date(createdAtFrom), { days: 1 }) : null
        }
        popperPlacement={'right'}
        selected={createdAtTo ? new Date(createdAtTo) : null}
        className={styles.blockDatePicker}
        wrapperClassName={styles.blockDatePickerWrapper}
        placeholderText="Created to..."
        onChange={(input) =>
          onChange({
            createdAtTo: input
              ? formatISO(input as Date, { representation: 'date' })
              : undefined,
          })
        }
      />
    </div>
  );
};

export default CreatedAtRangeFilter;
