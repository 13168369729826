import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { sub } from 'date-fns';
import { Checkbox, ToggleSwitch } from '@ateams/components';
import { DateInput } from '@src/components/DateInput';
import { inputStyles } from '@src/components/Inputs/InlineInput/styles';

interface Props {
  includeApplicationDate?: boolean;
  applicationDate?: string;
  exclude?: boolean;
  onChange: (include?: boolean, date?: string, exclude?: boolean) => void;
}

const useStyles = createUseStyles({
  container: {
    paddingTop: 10,
    display: 'flex',
  },
  dateInput: {
    ...inputStyles,
    width: 88,
    background: 'none',
    border: 'none',
    fontWeight: 600,
    padding: '0 8px',
    marginBottom: 0,
    paddingBottom: 0,
    fontSize: 16,
    lineHeight: '1.4em',
    textAlign: 'center',
  },
});

const AppliedSinceInput = (props: Props): ReactElement => {
  const { includeApplicationDate, applicationDate, onChange, exclude } = props;
  const styles = useStyles();

  const handleCheck = (checked?: boolean) => {
    return onChange(
      checked || undefined,
      applicationDate || sub(new Date(), { days: 30 }).toISOString(),
      exclude,
    );
  };

  const handleDateChange = (value: Date | null) => {
    onChange(includeApplicationDate, value?.toISOString(), exclude);
  };

  const handleExcludeChange = (newExcludeValue?: boolean) => {
    return onChange(
      includeApplicationDate,
      applicationDate || sub(new Date(), { days: 30 }).toISOString(),
      newExcludeValue,
    );
  };
  return (
    <div className={styles.container}>
      <Checkbox
        checked={includeApplicationDate}
        onChange={(e) => handleCheck(e.target.checked || undefined)}
        label={`Applied since`}
        margin="none"
      />
      <DateInput
        className={styles.dateInput}
        popperPlacement={'right'}
        selected={
          applicationDate
            ? new Date(applicationDate)
            : sub(new Date(), { days: 30 })
        }
        maxDate={new Date()}
        onChange={(input) => handleDateChange(input as Date | null)}
      />
      <ToggleSwitch
        label={exclude ? 'Excluded' : 'Included'}
        onChange={() => handleExcludeChange(!exclude)}
        checked={!exclude}
        size="small"
        justify="right"
        checkedBackgroundColor="secondary"
      />
    </div>
  );
};

export default AppliedSinceInput;
