import { Icon, IconType, Spinner } from '@ateams/components';
import React from 'react';
import useCommonStyles from './commonStyles';

interface EmptyViewProps {
  isLoading: boolean;
}

const EmptyView = (props: EmptyViewProps) => {
  const styles = useCommonStyles();

  const getText = () => {
    if (props.isLoading) {
      return `We're working on a recommendation`;
    }

    return `We are having trouble generating recommendation.`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.desc}>
        {props.isLoading ? (
          <Spinner size={14} color="#6D00D7" />
        ) : (
          <Icon size="smaller" type={IconType.SuggestedTeams} />
        )}
        {getText()}
      </div>
    </div>
  );
};

export default EmptyView;
