import React, {
  ReactElement,
  useRef,
  useEffect,
  useState,
  FormEvent,
  KeyboardEvent,
  useMemo,
} from 'react';
import { createUseStyles } from 'react-jss';
import useDebounceState from '@src/hooks/useDebounceState';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { Icon, IconType } from '@ateams/components';
import { Button as CallToActionButton } from '@ateams/components';
import cx from 'classnames';
import TopBarSearchResults from './TopBarSearchResults';
import { Breakpoints } from '@ateams/components';
import { useStores } from '@src/stores';
import { MissionSearchResult } from '@a_team/models/dist/MissionObject';
import { useHistory } from 'react-router';
import { MissionPageAdminLocation } from '@src/locations';
import Checkbox from '@src/components/Checkbox';

interface Props {
  searchQuery?: string;
  isExtendedSearch?: boolean;
  onSearch(query: string, extendedSearch?: boolean): void;
  onReset(): void;
  sticky: boolean;
  setStickyOffset(val: number): void;
  onNew(): void;
}

const useStyles = createUseStyles({
  root: {
    position: 'fixed',
    top: 0,
    padding: '24px',
    paddingLeft: '56px',
    paddingRight: '56px',
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 120px)',
    backgroundColor: '#f7f7f7',
    transition: 'all 0.5s',
    zIndex: 10,
  },
  searchInput: {
    margin: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    width: '70vw',
  },
  searchButton: {
    cursor: 'pointer',
    backgroundColor: '#FE630C',
    height: '56px',
    width: '80px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchContainer: {
    display: 'flex',
    position: 'relative',
  },
  CTA: {
    width: '190px',
    flexShrink: 0,
  },
  searchWrapper: {
    zIndex: 999,
    display: 'inline-flex',
    position: 'relative',
    padding: '35px 0 0 55px',
  },
  slideIn: {
    top: -150,
  },
  slideOut: {
    top: -150,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      position: 'fixed',
      top: 0,
      padding: '24px',
      paddingLeft: '56px',
      paddingRight: '56px',
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'calc(100% - 120px)',
      backgroundColor: '#f7f7f7',
      transition: 'all 0.5s',
      zIndex: 10,
    },
    searchInput: {
      margin: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      width: '744px',
    },
    searchButton: {
      backgroundColor: '#FE630C',
      height: '56px',
      width: '80px',
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    searchContainer: {
      alignSelf: 'flex-start',
      display: 'flex',
    },
    CTA: {
      width: '190px',
      flexShrink: 0,
    },
    slideIn: {
      top: 0,
    },
    slideOut: {
      top: -150,
    },
  },
  adminTopBar: {
    position: 'relative',

    zIndex: 999,
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
  },
});

export default function TopBar(props: Props): ReactElement {
  const { searchQuery, isExtendedSearch, onSearch, onNew } = props;
  const { missions } = useStores();
  const [loading, setLoading] = useState<boolean>(false);
  const [extendedSearch, setExtendedSearch] = useState<boolean>(
    !!isExtendedSearch,
  );
  const [foundMissions, setFoundMissions] = useState<MissionSearchResult[]>([]);
  const [highlightedIndex, setHighlightedIndex] = useState<number>();
  const barRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const styles = useStyles();
  const [stickyHeight, setStickyHeight] = useState<number>(0);
  const [query, setQuery] = useDebounceState<string | undefined>(
    undefined,
    (debouncedQuery) => {
      if (debouncedQuery && debouncedQuery.length > 0) {
        missions
          .searchAdminMission(debouncedQuery, extendedSearch)
          .then((missions) => setFoundMissions(missions))
          .catch(() => setFoundMissions([]))
          .finally(() => setLoading(false));
      } else {
        setFoundMissions([]);
      }
    },
    500,
  );

  const highlightedItem = useMemo(() => {
    return highlightedIndex !== undefined
      ? foundMissions[highlightedIndex]
      : undefined;
  }, [highlightedIndex]);

  useEffect(() => {
    setHighlightedIndex(undefined);
  }, [foundMissions]);

  const handleSearch = () => {
    setFoundMissions([]);
    onSearch(query || inputRef.current?.value || '', extendedSearch);
    setQuery('');
  };

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(false);
    if (highlightedItem) {
      const { mid } = highlightedItem;
      history.push(MissionPageAdminLocation(mid));
    } else {
      handleSearch();
    }
  };

  useEffect(() => {
    if (barRef.current) {
      props.setStickyOffset(barRef.current.offsetTop);
      setStickyHeight(barRef.current.offsetHeight);
    }
  }, []);

  const traverseList = (e: KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;

    switch (key) {
      case 'ArrowDown':
        if (highlightedIndex === foundMissions.length - 1) return;
        setHighlightedIndex(
          highlightedIndex !== undefined ? highlightedIndex + 1 : 0,
        );
        break;
      case 'ArrowUp':
        setHighlightedIndex((highlightedIndex || 1) - 1);
        break;
      default:
        return;
    }
  };

  return (
    <div ref={barRef} className={styles.adminTopBar}>
      {props.sticky ? (
        <>
          <div style={{ height: `${stickyHeight}px` }}></div>
          <div
            className={cx(
              styles.root,
              props.sticky ? styles.slideIn : styles.slideOut,
            )}
          >
            <form
              className={styles.searchContainer}
              onSubmit={handleFormSubmit}
            >
              <OutlinedInput
                loading={loading}
                onKeyDown={traverseList}
                ref={inputRef}
                borderRadius={'left'}
                placeholder="What type of mission are you looking for?"
                className={styles.searchInput}
                defaultValue={searchQuery}
                onChange={(e): void => {
                  setQuery(e.target.value);
                  setLoading(e.target.value.length > 0);
                }}
              />
              <div onClick={handleSearch} className={styles.searchButton}>
                <Icon type={IconType.Search} size="small" />
              </div>
              <div className={styles.checkboxWrapper}>
                <Checkbox
                  onChange={() => setExtendedSearch(!extendedSearch)}
                  checked={extendedSearch}
                />
                <span style={{ marginLeft: 10 }}>{'Extended search'}</span>
              </div>
              {query && query.length > 0 && props.sticky && !loading && (
                <TopBarSearchResults
                  onHover={(index) => setHighlightedIndex(index)}
                  highlightedIndex={highlightedIndex}
                  links={foundMissions}
                />
              )}
            </form>
            <CallToActionButton onClick={onNew} className={styles.CTA}>
              Post a Mission
            </CallToActionButton>
          </div>
        </>
      ) : (
        <div className={styles.searchWrapper}>
          <form className={styles.searchContainer} onSubmit={handleFormSubmit}>
            <OutlinedInput
              loading={loading}
              onKeyDown={traverseList}
              ref={inputRef}
              borderRadius={'left'}
              placeholder="What type of mission are you looking for?"
              className={styles.searchInput}
              defaultValue={searchQuery}
              onChange={(e): void => {
                setQuery(e.target.value);
                setLoading(e.target.value.length > 0);
              }}
            />
            <div className={styles.searchButton} onClick={handleSearch}>
              <Icon type={IconType.Search} size="small" />
            </div>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                onChange={() => setExtendedSearch(!extendedSearch)}
                checked={extendedSearch}
              />
              <span style={{ marginLeft: 10 }}>{'Extended search'}</span>
            </div>
            {query && query.length > 0 && (
              <TopBarSearchResults
                onHover={(index) => setHighlightedIndex(index)}
                highlightedIndex={highlightedIndex}
                links={foundMissions}
              />
            )}
          </form>
        </div>
      )}
    </div>
  );
}
