import React from 'react';
import { createUseStyles } from 'react-jss';
import emptyExperienceIcon from './emptyExperienceIcon.svg';

interface EmptyExperiencesProps {
  requestorFirstName?: string;
}

const useStyles = createUseStyles({
  container: {
    padding: 16,
    borderRadius: 4,

    display: 'flex',
    alignItems: 'center',
    gap: 16,

    '& img': {
      width: 40,
      height: 40,
    },
  },
});

const EmptyExperiences = ({
  requestorFirstName,
}: EmptyExperiencesProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <img src={emptyExperienceIcon} alt="empty experience icon" />
      {requestorFirstName ? (
        <span>{requestorFirstName} doesn’t have any experiences yet.</span>
      ) : (
        <span>You don’t have any experiences yet.</span>
      )}
    </div>
  );
};

export default EmptyExperiences;
