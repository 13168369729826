import { createQueryKeys } from '@lukemorales/query-key-factory';

export const cityKeys = createQueryKeys('cities', {
  byCountries: (countries: string[]) => ({
    countries: countries.sort().join('-'),
  }),
  byCountriesOrProvinces: (countries: string[], provinces: string[]) => ({
    countries: countries.sort().join('-'),
    provinces: provinces.sort().join('-'),
  }),
});
