import { apiTalentSpecializations } from '@ateams/api';
import { TalentSpecialization } from '@a_team/models/dist/TalentCategories';
import { useStores } from '@src/stores';
import { useEffect, useState } from 'react';

export const useTalentSpecializations = (
  searchTerm?: string,
): [TalentSpecialization[], boolean] => {
  const { auth } = useStores();
  const [results, setResults] = useState<TalentSpecialization[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    apiTalentSpecializations
      .queryTalentSpecializations(auth, { searchTerm })
      .then((specializations) => {
        setResults(specializations.items);
      })
      .finally(() => setLoading(false));
  }, [auth, searchTerm]);

  return [results, loading];
};
