import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
  },
});

const ResponsibleCommunity = (): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <h4>Responsible Community</h4>

      <p>
        <strong>Grow the community:</strong> One of our core values is creating
        transparency, as opposed to an opaque agency structure when you don’t
        know the people actually doing the work. Another one is abundance: more
        great builders = more great companies = more great missions. It also
        means more great teammates for you! Accordingly:
      </p>

      <div>
        <p>
          <strong>1. Subcontracting</strong>
        </p>
        <p>
          For transparency’s sake, the Builder Agreement (that you’ll typically
          sign with the companies you engage) prohibits subcontracting your
          obligations without (1) getting A.Team’s & that company’s express
          written consent; and (2) signing a clear agreement with the
          subcontractor.
        </p>
      </div>

      <div>
        <p>
          <strong>2. As an Alternative to Subcontracting</strong>
        </p>
        <p>
          Once you find a mission that could be relevant for a friend, invite
          them to join the A.Team platform directly (and earn BuildTokens for
          doing this!).
        </p>
      </div>
    </div>
  );
};

export default ResponsibleCommunity;
