import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { theme } from '@a_team/ui-components';
import { Spacing, TextColors } from '@ateams/components';
import { EditIcon } from './icons/edit';
import { DeleteIcon } from './icons/delete';

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  contained?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  noColor?: boolean;
  fullWidth?: boolean;
  rounded?: boolean;
}

const useStyles = createUseStyles({
  button: ({
    disabled,
    contained,
    noColor,
    fullWidth,
    rounded,
  }: ButtonProps) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '19px',
    color: noColor
      ? '#222222'
      : contained
      ? TextColors.primary
      : theme.colors.Baracus[600],
    background:
      contained && !noColor ? theme.colors.Baracus[600] : 'transparent',
    border: noColor
      ? `1px solid ${theme.colors.Grey[300]}`
      : contained
      ? 'none'
      : `1px solid ${theme.colors.Baracus[600]}`,
    borderRadius: rounded ? '20px' : '4px',
    padding: contained ? '8px 16px' : '7px 15px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    width: fullWidth ? 'auto' : 'fit-content',
  }),
  startAdornmentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: Spacing.small,
  },
  endAdornmentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: Spacing.small,
  },
});

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    contained,
    startAdornment,
    endAdornment,
    noColor,
    fullWidth,
    rounded,
    children,
    className,
    ...rest
  } = props;
  const styles = useStyles(props);

  return (
    <button className={cx(styles.button, className)} {...rest}>
      {startAdornment && (
        <div className={styles.startAdornmentContainer}>{startAdornment}</div>
      )}
      {children}
      {endAdornment && (
        <div className={styles.endAdornmentContainer}>{endAdornment}</div>
      )}
    </button>
  );
};

Button.defaultProps = {
  contained: true,
  noColor: false,
  fullWidth: false,
  rounded: false,
};

const useIconButtonStyles = createUseStyles({
  container: {
    height: '24px',
    width: '36px',
    padding: 0,
  },
  editButton: {
    background: theme.colors.Grey[200],
  },
});

export const EditButton: React.FC<ButtonProps> = (props) => {
  const styles = useIconButtonStyles();

  return (
    <Button
      {...props}
      className={cx(styles.container, styles.editButton, props.className)}
    >
      <EditIcon />
    </Button>
  );
};

export const DeleteButton: React.FC<ButtonProps> = (props) => {
  const styles = useIconButtonStyles();

  return (
    <Button
      noColor
      {...props}
      className={cx(styles.container, props.className)}
    >
      <DeleteIcon />
    </Button>
  );
};

export interface EditActionsProps {
  isEditing: boolean;
  onEditClick?: () => unknown;
  onDeleteClick: () => unknown;
  className?: string;
}

const useEditActionsStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  editButton: {
    marginRight: Spacing.small,
  },
});

export const EditActions: React.FC<EditActionsProps> = (props) => {
  const styles = useEditActionsStyles();

  return (
    <div className={cx(styles.container, props.className)}>
      {!props.isEditing && (
        <EditButton onClick={props.onEditClick} className={styles.editButton} />
      )}

      <DeleteButton onClick={props.onDeleteClick} />
    </div>
  );
};
