import React, { ReactElement, useMemo } from 'react';
import { flatten } from 'lodash';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { useStores } from '@src/stores';
import MissionControlCard from '..';
import Flag from '../Flag';
import ActionButton from '../ActionButton';
import MissionRoles from '../MissionRoles';
import MatchList from '../MatchList';
import { VideoPlayer } from '@src/components/VideoPlayer';
import {
  MissionRoleStatus,
  MissionRoleVisibilityStatus,
} from '@a_team/models/dist/MissionRole';
import { RecommendedMission } from '@a_team/models/dist/MissionObject';

interface Props {
  mission?: RecommendedMission;
  loading?: boolean;
}

export const getRecommendationMatchingCriteria = (
  mission: RecommendedMission,
): string[] => {
  const roleMatches = mission.roles
    .filter(({ recommended }) => recommended)
    .map(({ matches }) => [
      ...(matches?.industries?.map(({ name }) => name) ?? []),
      ...(matches?.skills?.map(({ talentSkillName: name }) => name) ?? []),
    ])
    .filter(function (matches: (string | undefined)[]): matches is string[] {
      return matches.filter((match) => !!match).length > 0;
    });

  const matchSet: Set<string> = new Set(flatten(roleMatches));
  return [...matchSet];
};

const RecommendedMissionCard = (props: Props): ReactElement => {
  const { mission, loading } = props;
  const { missionControl, auth } = useStores();
  const analytics = useAnalytics();

  const criteria: string[] = mission
    ? getRecommendationMatchingCriteria(mission)
    : [];

  const onHide = (): void => {
    if (!mission) return;
    analytics.trackMissionCardNotInterested(mission, true, true);
    missionControl.setNotInterested(mission.mid);
  };

  const openRoles = useMemo(() => {
    if (!mission) return [];
    return mission.roles.filter(
      (role) =>
        role.status === MissionRoleStatus.Open &&
        (auth.isAdmin ||
          !role.visibility?.visibilityStatus ||
          role.visibility?.visibilityStatus ===
            MissionRoleVisibilityStatus.All),
    );
  }, [mission?.roles]);

  return (
    <MissionControlCard
      loading={loading}
      mission={mission}
      flag={<Flag />}
      actionButton={<ActionButton type="hide" onClick={onHide} />}
      video={
        mission?.videoURL ? (
          <VideoPlayer
            url={mission.videoURL}
            noBorder={true}
            borderRadius={0}
            width="100%"
          />
        ) : undefined
      }
      details={<MissionRoles roles={openRoles} />}
      matchList={
        criteria.length ? <MatchList criteria={criteria} /> : undefined
      }
      fullWidth={true}
    />
  );
};

export default RecommendedMissionCard;
