import { Icon, IconType } from '@ateams/components';
import cx from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface NotInterestedProps {
  onClick: () => void;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    cursor: 'pointer',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 7px',
    border: '1px solid #DADADC',
    borderRadius: '50%',
    width: 32,
    height: 32,
    overflow: 'hidden',
    direction: 'rtl',
    transition: 'width 0.2s ease-in-out, border-radius 0.2s ease-in-out',
    '&:hover': {
      width: '134px',
      borderRadius: '4px',
      // hide Icon end is visible
      '& $hideIconEnd': {
        display: 'block',
      },
      // hide Icon start is hidden
      '& $hideIconStart': {
        display: 'none',
      },
      '& $notInterested': {
        visibility: 'visible',
        opacity: '1',
        marginLeft: 8,
      },
    },

    '& img': {
      width: 16,
      height: 'auto',
    },
  },

  hideIconStart: {
    width: 16,
    padding: 8,
    display: 'block',
  },
  hideIconEnd: {
    width: 16,
    display: 'none',
  },
  notInterested: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    visibility: 'hidden',
    opacity: '0',
    transition: 'visibility 0.2s, opacity 0.2s linear',
  },
});

const NotInterested = ({
  onClick,
  className,
}: NotInterestedProps): JSX.Element => {
  const styles = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    event?.preventDefault();
    onClick();
  };

  return (
    <>
      <button className={cx(styles.container, className)} onClick={handleClick}>
        <Icon
          size="exact"
          className={styles.hideIconStart}
          type={IconType.EyeClosed}
        />
        <span className={styles.notInterested}>Not Interested</span>
        <Icon
          size="exact"
          className={styles.hideIconEnd}
          type={IconType.EyeClosed}
        />
      </button>
    </>
  );
};

export default NotInterested;
