import { Stores } from '../stores';
import { match as Match } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface Loader<T extends Record<string, string> = any> {
  (stores: Stores, match: Match<T>): Promise<unknown>;
}

export function mergeLoaders(...loaders: Loader[]): Loader {
  return (...args): Promise<unknown> =>
    Promise.all(loaders.map((loader) => loader(...args)));
}

export function isPromiseLike<T>(value: unknown): value is PromiseLike<T> {
  return (
    Boolean(value) && typeof (value as PromiseLike<unknown>).then === 'function'
  );
}

export function loadScript(id: string, src: string): void {
  if (document.getElementById(id)) {
    return;
  }

  const elm = document.createElement('script');

  elm.id = id;
  elm.src = src;

  const firstScript = document.getElementsByTagName('script')[0];
  (firstScript.parentNode as HTMLElement).insertBefore(elm, firstScript);
}
