import SkillsSelector from '@src/components/SkillsSelector';
import { Expertise } from '@src/stores/Profile/models';
import React from 'react';
import { FormValues } from '..';
import { UseFormSetValue } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';

interface SkillsV2Props {
  jobSkills: string[];
  profileSkills: Expertise[];
  setValue: UseFormSetValue<FormValues>;
}

const useStyles = createUseStyles({
  label: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  desc: {
    fontSize: '15px',
    fontWeight: 300,
    lineHeight: '24px',
    color: '#62646A',
    textAlign: 'start',
    margin: 0,
  },
  labelDescContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    marginBottom: 12,
  },
});

function SkillsV2({ jobSkills, profileSkills, setValue }: SkillsV2Props) {
  const styles = useStyles();

  const handleOnChange = (skills: Expertise[]) => {
    const selectedSkills = skills.map((skill) => ({
      talentSkillId: skill.id,
      talentSkillName: skill.name,
      rating: skill.rating,
    }));

    setValue('selectedSkills', selectedSkills);
  };

  return (
    <div data-testing-id="profile-job-card-skills-used-select">
      <div className={styles.labelDescContainer}>
        <label className={styles.label}>
          Skills used — Add up to 12 skills
        </label>
        <span className={styles.desc}>
          Any new skills will be added to your profile.
        </span>
      </div>
      <SkillsSelector
        defaultSelectedSkillIds={jobSkills}
        profileSkills={profileSkills}
        onChange={handleOnChange}
      />
    </div>
  );
}

export default observer(SkillsV2);
