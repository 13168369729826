import {
  CurrentUserMissionApplicationObject,
  CurrentUserMissionApplicationStatus,
} from '@a_team/models/dist/MissionApplicationObject';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';
import { AppliedMissionsDataResponse } from '@a_team/models/dist/v2/MissionApplication';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { Card, Button as CallToActionButton } from '@ateams/components';
import CompanyAvatar from '@src/components/CompanyAvatar/CompanyAvatar';
import TooltipWrapped from '@src/components/TooltipWrapped';
import UserAvatar from '@src/components/UserAvatar';
import { MissionApplyEditLocation, MissionLocation } from '@src/locations';
import { stripDescriptionHtml } from '@src/logic/utils';
import { useStores } from '@src/stores';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useHistory } from 'react-router-dom';
import ActionButton from '../../MissionControlCard/ActionButton';
import ApplicationDetails from '../../MissionControlCard/ApplicationDetails';
import { getStatus } from '../../MissionControlCard/applicationStatusesUtils';
import ApplicationV2StatusV2 from '../../MissionControlCard/ApplicationStatusV2';
import { useStyles } from '../../MissionControlCard/styles';
import TeamUpSection from '../../MissionControlCard/TeamUpSection';
import { getMissionTitle } from '@src/views/Mission/utils';

interface AppliedMissionCardV2Props {
  application: AppliedMissionsDataResponse;
  onConfirmWithdrawModalOpen: (
    mid: string,
    application: CurrentUserMissionApplicationObject,
  ) => void;
  hideInvite?: boolean;
  isLoading: boolean;
}

const AppliedMissionCardV2 = ({
  application,
  onConfirmWithdrawModalOpen,
  hideInvite,
  isLoading,
}: AppliedMissionCardV2Props) => {
  const analytics = useAnalytics();
  const { mission, role, availability } = application;
  const history = useHistory();
  const styles = useStyles({ descriptionLines: 2, fullWidth: false });
  const { auth } = useStores();

  const missionTitle = useMemo(() => {
    return getMissionTitle(mission?.title);
  }, [mission?.title]);

  const hourlyRate = useMemo(() => {
    let rate = 'Unspecified';
    if (application?.hourlyRateRange)
      rate = `$${application.hourlyRateRange.min}-$${application.hourlyRateRange.max}/hour`;
    return rate;
  }, [application?.hourlyRateRange?.min, application?.hourlyRateRange?.max]);

  const canEditApplication = useMemo(() => {
    if (!application.status) return false;
    const endedApplicationStatuses = [
      CurrentUserMissionApplicationStatus.MissionCancelled,
      CurrentUserMissionApplicationStatus.MissionEnded,
      CurrentUserMissionApplicationStatus.RoleCancelled,
      CurrentUserMissionApplicationStatus.ConfirmedAnotherBuilder,
      CurrentUserMissionApplicationStatus.NotAvailable,
      CurrentUserMissionApplicationStatus.Rejected,
      CurrentUserMissionApplicationStatus.Accepted,
    ];

    if (
      auth.withViewPastApplications &&
      (role?.status === MissionRoleStatus.Ended ||
        role?.status === MissionRoleStatus.Canceled)
    ) {
      return false;
    }

    return !endedApplicationStatuses.includes(application.status);
  }, [
    application?.hourlyRateRange?.min,
    application?.hourlyRateRange?.max,
    application.status,
    role?.status,
    auth.withViewPastApplications,
  ]);

  const clickable = canEditApplication || auth.withViewPastApplications;

  const className =
    application.status ===
    CurrentUserMissionApplicationStatus.LowCompetitiveness
      ? 'lowCompetitiveness'
      : undefined;

  const handleCardClick = () => {
    analytics.trackMissionCardClickedV2(mission);
  };

  const status = useMemo(() => {
    if (!application) return null;
    if (!mission) return null;
    if (!role) return null;

    return getStatus({
      mission,
      missionApplication: application,
      userBadges: auth?.user?.badges,
      lookingForApplications: role.lookingForApplications,
    });
  }, [application, mission, role?.lookingForApplications, auth.user?.badges]);

  const handleEdit = useCallback(() => {
    if (!mission.mid || !role?.rid || !application?.aid) {
      return;
    }
    const location = MissionApplyEditLocation(
      mission.mid,
      role.rid,
      application.aid,
    );
    history.push(location);
  }, [mission.mid, role?.rid, application?.aid]);

  const redirectUrl = useMemo(() => {
    if (!mission.mid || !role?.rid || !application?.aid) {
      return;
    }
    return canEditApplication
      ? MissionApplyEditLocation(mission.mid, role?.rid, application?.aid)
      : undefined;
  }, [mission.mid, application?.aid, canEditApplication, role?.rid]);

  const additionalRoles =
    (application?.totalFilledRoles || 0) -
    (application?.topActiveUsersOnMission?.length || 0);

  const handleWithdrawClick = useCallback(() => {
    if (!mission || !application) return;
    auth.user &&
      onConfirmWithdrawModalOpen &&
      onConfirmWithdrawModalOpen(mission.mid, {
        ...application,
        user: auth.user,
      });
  }, [mission, application, auth.user, onConfirmWithdrawModalOpen]);

  return (
    <li
      className={cx(styles.listItem, className, {
        [styles.disabled]: !clickable || isLoading,
      })}
    >
      <Card className={styles.card} onClick={handleCardClick}>
        {status && <ApplicationV2StatusV2 status={status} />}
        <Link
          to={
            clickable && !isLoading
              ? redirectUrl || `${MissionLocation}/${mission.mid}`
              : '#'
          }
          className={styles.cardLink}
        >
          <div className={styles.headerContainer}>
            <div className={styles.logo}>
              {isLoading ? (
                <Skeleton
                  circle
                  height={40}
                  width={40}
                  className={styles.logoInner}
                />
              ) : (
                <CompanyAvatar
                  src={mission?.logoURL ?? ''}
                  alt={`${mission?.companyName} company logo`}
                  size={40}
                  className={styles.logoInner}
                />
              )}

              {application?.topActiveUsersOnMission?.map((user) => {
                return (
                  <TooltipWrapped
                    key={user.uid}
                    theme={'dark'}
                    position={'top'}
                    arrow
                    html={
                      <div className={styles.tooltip}>
                        <p>{user?.fullName}</p>
                      </div>
                    }
                  >
                    <UserAvatar
                      src={user.profilePictureURL || ''}
                      alt={user?.fullName}
                      size={24}
                      containerStyle={{
                        width: '24px',
                        height: '24px',
                        marginRight: '4px',
                      }}
                    />
                  </TooltipWrapped>
                );
              })}
              {additionalRoles > 0 && `+${additionalRoles}`}
            </div>
          </div>
          <div className={cx(styles.descriptionContainer, styles.container)}>
            {isLoading ? (
              <>
                <Skeleton className={styles.companyName} />
                <Skeleton className={styles.title} />
                <Skeleton count={4} />
              </>
            ) : (
              <>
                <p className={styles.companyName}>
                  <small>{mission?.companyName}</small>
                </p>

                <h4 className={styles.title}>{missionTitle}</h4>

                <p className={styles.description}>
                  {mission && stripDescriptionHtml(mission.description)}
                </p>
              </>
            )}
          </div>
          <div className={cx(styles.detailsContainer, styles.container)}>
            <ApplicationDetails
              role={role?.categoryTitle ?? ''}
              commitment={`${availability?.hoursPerWeek} hours/week`}
              hourlyRate={hourlyRate}
              dateApplied={application.createdAt}
            />
          </div>
        </Link>
        {mission &&
          application &&
          auth.uid &&
          auth.user &&
          (application?.totalRoles || 0) > 1 && (
            <div className={styles.teamUpSectionContainer}>
              <TeamUpSection
                hideInvite={hideInvite}
                currentUserId={auth.uid}
                userRecommendations={application?.userRecommendations}
                mission={mission}
                application={{ ...application, user: auth.user }}
              />
            </div>
          )}
        <div className={styles.editActionButtonContainer}>
          {isLoading ? (
            <>
              <Skeleton width={200} />
              <Skeleton width={100} />
            </>
          ) : (
            <>
              <div>
                {application && clickable && (
                  <CallToActionButton
                    onClick={handleEdit}
                    className={styles.applyEditCTA}
                  >
                    {canEditApplication ? 'Edit' : 'View'}
                  </CallToActionButton>
                )}
              </div>

              {application?.canWithdraw && canEditApplication ? (
                <div>
                  <ActionButton
                    type="withdraw"
                    className={styles.withdrawButton}
                    onClick={handleWithdrawClick}
                  />
                </div>
              ) : undefined}
            </>
          )}
        </div>
      </Card>
    </li>
  );
};

export default observer(AppliedMissionCardV2);
