import React from 'react';
import { createUseStyles } from 'react-jss';
import UserAvatar from '../UserAvatar';

interface UserAvatarWithCompanyLogoProps {
  logoUrl?: string | undefined | null;
  profilePictureURL?: string | undefined | null;
  size?: number;
}

const useStyles = createUseStyles({
  imageContainer: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
  },
  companyLogo: {
    position: 'absolute',
    bottom: -2,
    right: -2,
    borderRadius: 3,
    border: '2px solid #FFFFFF',
    background: '#FFFFFF',
    zIndex: 1,
  },
});

const UserAvatarWithCompanyLogo = ({
  logoUrl,
  profilePictureURL,
}: UserAvatarWithCompanyLogoProps): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.imageContainer}>
      <div>
        {logoUrl && (
          <img
            width={20}
            height={20}
            className={styles.companyLogo}
            src={logoUrl}
            alt="Company logo"
          />
        )}

        <UserAvatar
          shouldResizeUploadCareImages={false}
          size={56}
          src={profilePictureURL || undefined}
          alt={'user avatar'}
        />
      </div>
    </div>
  );
};

export default UserAvatarWithCompanyLogo;
