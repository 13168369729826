import {
  SharedJobExperience,
  SharedProjectExperience,
} from '@a_team/models/dist/ConnectionObject';
import {
  BasicExperienceObject,
  ExperienceType,
  ProjectExperienceData,
} from '@a_team/models/dist/ExperienceObject';
import { EditingExperienceObject } from '../../types';

const applyCommonUpdates = (
  experience: SharedJobExperience | SharedProjectExperience,
  updates:
    | EditingExperienceObject
    | SharedJobExperience
    | SharedProjectExperience
    | undefined,
): Pick<
  EditingExperienceObject | SharedJobExperience | SharedProjectExperience,
  | 'imagesUrls'
  | 'startDate'
  | 'endDate'
  | 'description'
  | 'descriptionHTML'
  | 'talentSkillIds'
  | 'jobRole'
  | 'jobRoleId'
  | 'skills'
  | 'metrics'
  | 'hasZeroToOneExperience'
  | 'hasManagedPeople'
  | 'numberOfPeopleManaged'
> => {
  if (!updates) {
    return experience;
  }

  if (experience.eid !== updates.eid) {
    return experience;
  }

  return {
    imagesUrls: updates.imagesUrls,
    startDate: updates.startDate,
    endDate: updates.endDate,
    description: updates.description,
    descriptionHTML: updates.descriptionHTML,
    talentSkillIds: updates.talentSkillIds,
    jobRole: updates.jobRole,
    jobRoleId: updates.jobRoleId,
    skills: updates.skills,
    metrics: updates.metrics,
    hasZeroToOneExperience: updates.hasZeroToOneExperience,
    hasManagedPeople: updates.hasManagedPeople,
    numberOfPeopleManaged: updates.numberOfPeopleManaged,
  };
};

const getRoleId = (
  updates:
    | EditingExperienceObject
    | SharedJobExperience
    | SharedProjectExperience,
): string | undefined => {
  if (
    updates.type === ExperienceType.Project ||
    updates.type === ExperienceType.Job
  ) {
    return updates.talentSpecializationId;
  }

  return undefined;
};

const applySharedJobExperienceUpdates = (
  experience: SharedJobExperience,
  updates: EditingExperienceObject | SharedJobExperience,
): SharedJobExperience => {
  if (experience.eid !== updates.eid) {
    return experience;
  }

  return {
    ...experience,
    ...applyCommonUpdates(experience, updates),
    talentSpecializationId: getRoleId(updates),
  };
};

const applySharedProjectExperienceUpdates = (
  experience: SharedProjectExperience,
  updates: EditingExperienceObject,
): SharedProjectExperience => {
  if (experience.eid !== updates.eid) {
    return experience;
  }

  return {
    ...experience,
    ...applyCommonUpdates(experience, updates),
    talentSpecializationId: getRoleId(updates),
  };
};

export const applySharedJobExperiencesUpdates = (
  experiences: SharedJobExperience[],
  updates: EditingExperienceObject,
): SharedJobExperience[] =>
  experiences.map((experience) =>
    applySharedJobExperienceUpdates(experience, updates),
  );

export const applySharedProjectExperiencesUpdates = (
  experiences: SharedProjectExperience[],
  updates: EditingExperienceObject,
): SharedProjectExperience[] =>
  experiences.map((experience) =>
    applySharedProjectExperienceUpdates(experience, updates),
  );

export const withUpdatedSharedJobExperiences = (
  updates: SharedJobExperience[],
  experiences?: SharedJobExperience[],
): SharedJobExperience[] => {
  if (!experiences) {
    return [];
  }

  return experiences.map((experience) => {
    const updatedExperience = updates.find(({ eid }) => eid === experience.eid);

    if (!updatedExperience) {
      return experience;
    }

    return applySharedJobExperienceUpdates(experience, updatedExperience);
  });
};

export const withUpdatedSharedProjectExperiences = (
  updates: SharedProjectExperience[],
  experiences?: (BasicExperienceObject & ProjectExperienceData)[],
): SharedProjectExperience[] => {
  if (!experiences) {
    return [];
  }

  return experiences.map((experience) => {
    const updatedExperience = updates.find(({ eid }) => eid === experience.eid);

    if (!updatedExperience) {
      return experience;
    }

    return applySharedProjectExperienceUpdates(experience, updatedExperience);
  });
};

export const addProjectSkills = (
  experiences: SharedProjectExperience[],
): SharedProjectExperience[] =>
  experiences.map((experience) => ({
    ...experience,
    talentSpecializationId: experience.jobRoleId,
  }));
