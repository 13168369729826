import { SharedJobExperience } from '@a_team/models/dist/ConnectionObject';
import { ExperienceObject } from '@a_team/models/dist/ExperienceObject';
import JobModal from '@src/components/Modal/JobModal';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React from 'react';

interface AddJobExperienceModalWrapperProps {
  open: boolean;
  onClose: () => void;
  handleAddNewlyCreatedExperience: (experienceId: string) => void;
  onAddJobExperience: (jobExperience: SharedJobExperience) => void;
}

const AddJobExperienceModalWrapper = ({
  open,
  handleAddNewlyCreatedExperience,
  onAddJobExperience,
  onClose,
}: AddJobExperienceModalWrapperProps): JSX.Element => {
  const { users, auth } = useStores();

  const onSuccess = (job: ExperienceObject) => {
    handleAddNewlyCreatedExperience(job.eid);
    onAddJobExperience(job as SharedJobExperience);
    onClose();
  };

  return (
    <>
      {auth.withNewJobs && users?.profile && (
        <JobModal
          auth={auth}
          profile={users.profile}
          open={open}
          handleModalClose={onClose}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default observer(AddJobExperienceModalWrapper);
