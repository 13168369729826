import React, { ReactElement, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import MissionFilters from '../MissionFilters';
import NoResults from '@src/components/NoResults';
import TextButton from '@src/components/TextButton';
import { LoadingCards } from '@src/views/MissionControl/LoadingCards';
import MissionControlCardV2 from '../MissionControlCard/MissionControlCardV2/MissionControlCardV2';
import { useGridMissionControlCardStyles } from '../MissionControlCard/common/styles';
import { useQueryClientSignalMissions } from '@src/rq/signals';
import { getIsMissionLiked } from '../MissionControlCard/common/utils';
import Reserve from '../Reserve';
import {
  useGetAllMissionsQuery,
  useGetRecommendedMissionsQuery,
} from '@src/rq/missions';
import CompleteSetupModal from '@src/components/Modal/CompleteSetupModal';

const AllMissions = (): ReactElement => {
  const { auth, missionControl } = useStores();
  const gridStyles = useGridMissionControlCardStyles();

  const [showCompleteSetupModal, setShowCompleteSetupModal] = useState(false);

  const { withBuilderLikes } = auth;

  const { filtersOn, clearFilters, filterByUserSettings, appliedUserFilters } =
    missionControl;
  const {
    isLoading: isLoadingAllMissions,
    isError: isErrorLoadingAllMissions,
  } = useGetAllMissionsQuery();
  const { isLoading: isLoadingRecommendedMissions } =
    useGetRecommendedMissionsQuery();

  const missions = useMemo(
    () => missionControl.filteredMissions,
    [
      isLoadingAllMissions,
      isErrorLoadingAllMissions,
      missionControl.filteredMissions,
    ],
  );

  const { data } = useQueryClientSignalMissions({
    enabled: withBuilderLikes,
  });

  const loading =
    (isLoadingAllMissions || isLoadingRecommendedMissions) &&
    !auth.currentUser &&
    !appliedUserFilters;

  const showEmptyState =
    auth.currentUser && !missionControl.loading && !missions.length;

  const resetFilters = (): void => {
    if (auth && auth.currentUser) {
      filterByUserSettings(auth.currentUser);
    } else {
      clearFilters();
    }
  };

  const isLimitedAccess = auth.limitedAccess;

  if (isLimitedAccess) {
    return <Reserve />;
  }

  return (
    <>
      <MissionFilters disabled={loading} />

      {!loading && (
        <div className={gridStyles.container}>
          {missions.map((mission) => (
            <MissionControlCardV2
              key={mission.mid}
              mission={mission}
              hideMatchedOn
              companyLiked={getIsMissionLiked(data, mission.mid)}
              onCompleteSetup={() => setShowCompleteSetupModal(true)}
            />
          ))}
        </div>
      )}

      <CompleteSetupModal
        title="Complete setup to apply to missions"
        description="Complete the steps on the setup tab to continue"
        open={showCompleteSetupModal}
        onClose={() => setShowCompleteSetupModal(false)}
      />

      {loading && (
        <div className={gridStyles.container}>
          <LoadingCards type={'all'} />
        </div>
      )}

      {showEmptyState &&
        (filtersOn ? (
          <NoResults
            imageType="noSearchResults"
            title="No results found!"
            text={
              <>
                <p>Sorry, we didn't find any missions that fit your needs.</p>
                <p>
                  Please update your selected filters or{' '}
                  <TextButton onClick={resetFilters} color="primary">
                    start again
                  </TextButton>
                  .
                </p>
              </>
            }
          />
        ) : (
          <NoResults
            imageType="noSearchResults"
            title="We don't have any new missions"
            text={
              <>
                <p>Unfortunately, right now we don't have any new missions.</p>
                <p>Please check Mission Control later.</p>
              </>
            }
          />
        ))}
    </>
  );
};

export default observer(AllMissions);
