import TimesheetObject from './TimesheetObject';
import { DateISOString, QueryResult } from './misc';
import { BasicInvoiceObject, InvoiceStatus } from './InvoiceObject';
import { MissionId } from './MissionObject';
import { TimesheetSummaryFeedbackObject } from './TimesheetSummaryFeedbackObject';

export type MissionPaymentCycleId = string;

export enum MissionPaymentCycleStatus {
  Open = 'Open',
  Closed = 'Closed',
}

export interface PaymentCycleAdminSummary {
  totalMinutes: number;
  totalPayments: number;
}

export interface PaymentCycleSummary extends PaymentCycleAdminSummary {
  submitted: boolean;
}

export interface PaymentCycleDates {
  startDate: DateISOString;
  endDate: DateISOString;
}

export interface BasicMissionPaymentCycleObject extends PaymentCycleDates {
  yid: MissionPaymentCycleId;
  status: MissionPaymentCycleStatus;
  // startDate: DateISOString;
  // endDate: DateISOString;
  summary: PaymentCycleSummary | null;
  invoiceStatus?: InvoiceStatus;
  teamSummary?: string;
  teamSummaryHtml?: string;
  teamSummaryGeneratedAt?: Date;
  teamSummaryFeedbackFromAuthUser?: TimesheetSummaryFeedbackObject[];
}

export interface BasicMissionPaymentCycleAdminObject
  extends Omit<BasicMissionPaymentCycleObject, 'summary'> {
  summary: PaymentCycleAdminSummary;
}

export default interface MissionPaymentCycleObject
  extends BasicMissionPaymentCycleObject {
  timesheets: TimesheetObject[];
}

export interface MissionPaymentCycleAdminObject
  extends Omit<MissionPaymentCycleObject, 'summary'> {
  summary: BasicMissionPaymentCycleAdminObject['summary'];
  invoice?: BasicInvoiceObject;
}

export interface MissionPaymentCycleAdminReportObject
  extends MissionPaymentCycleAdminObject {
  mid: MissionId;
  title: string;
}

export interface PaymentCycles
  extends QueryResult<BasicMissionPaymentCycleObject> {
  current: MissionPaymentCycleObject;
}

export interface PaymentCyclesAdmin
  extends QueryResult<BasicMissionPaymentCycleAdminObject> {
  current: MissionPaymentCycleAdminObject;
}
