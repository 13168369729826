import { validateAndParseTime } from '@src/components/TimeField/utils';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

export type OnTimeInputChange = (
  event: ChangeEvent<HTMLInputElement>,
  totalMinutes: number,
) => void;

export interface TimeInputProps {
  initialValue: number;
  onUpdateMutation?: () => void;
  onChange: (value: number) => void;
}

const useStyles = createUseStyles({
  input: {
    margin: 0,
    border: 'none',
    backgroundColor: 'transparent',
    color: '#222',
    fontSize: 'inherit',
    padding: 16,
    width: '100%',
    transition: 'none',
    '&:focus': {
      borderRadius: 8,
      outline: '2px solid #6D00D7',
    },
  },
});

export const minutesToTime = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
};

function TimeInput({
  initialValue,
  onUpdateMutation,
  onChange,
}: TimeInputProps) {
  const styles = useStyles();
  const inputEl = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [value, setValue] = useState('');

  useEffect(() => {
    const newValue = initialValue > 0 ? minutesToTime(initialValue) : '';

    if (
      value !== newValue &&
      inputEl.current &&
      inputEl.current !== document.activeElement
    ) {
      setValue(newValue);
    }
  }, [initialValue]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    try {
      const newValue = event.target.value;
      const { totalMinutes } = validateAndParseTime(newValue);
      setValue(newValue);

      if (onChange) {
        onChange(totalMinutes);
      }
    } catch (err) {
      // pass
    }
  };

  const formatInput = () => {
    const { totalMinutes } = validateAndParseTime(value as string);

    if (totalMinutes) {
      const formattedTime = minutesToTime(totalMinutes);

      setValue(formattedTime);
    }
  };

  const handleOnBlur = () => {
    try {
      formatInput();
      onUpdateMutation && onUpdateMutation();
    } catch (err) {
      // pass
    }
  };

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Escape'].includes(e.key)) {
      try {
        formatInput();
      } catch (err) {
        // pass
      }
    }
  };

  return (
    <input
      data-testing-id="timesheets-hours-input"
      ref={inputEl}
      value={value}
      type="text"
      className={styles.input}
      name="hours"
      onChange={handleOnChange}
      onKeyUp={handleOnKeyUp}
      onBlur={handleOnBlur}
      placeholder="HH:MM"
    />
  );
}

export default TimeInput;
