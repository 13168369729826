import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { SidebarSection } from '@src/views/Profile/Sidebar/SidebarSection';
import { createUseStyles } from 'react-jss';
import {
  getGithubUsernameFromURL,
  isValidGithubUrl,
  withHttps,
} from '@src/helpers/urls';
import TextInput from '@src/components/Inputs/TextInput/TextInput';
import { Checkbox, Colors, TextColors } from '@ateams/components';
import useToggle from '@src/hooks/useToggle';

interface Props {
  existingUrl?: string;
  onChange: (url?: string) => void;
  isSoftwareEngineer: boolean;
}

enum Errors {
  Empty = `This field can't be empty`,
  Invalid = `This url looks invalid`,
}

const useStyles = createUseStyles({
  input: {
    fontSize: '17px',
    color: TextColors.regular,
    fontWeight: '400',
    padding: '5px 0',
    marginTop: '5px',
    '&::placeholder': {
      color: Colors.regular,
    },
  },
  error: {
    marginTop: 4,
    color: TextColors.primaryLight,
    fontSize: 12,
  },
  checkbox: {
    '& span': {
      color: TextColors.regular,
    },
  },
});

export const GithubUrl = ({
  existingUrl,
  onChange,
  isSoftwareEngineer,
}: Props): ReactElement => {
  const styles = useStyles();

  const [error, setError] = useState<string | undefined>(undefined);
  const [url, setUrl] = useState<string | undefined>(existingUrl);

  const hasGithubProfile = useMemo(
    () => isValidGithubUrl(existingUrl),
    [existingUrl],
  );
  const [disabled, setDisabled] = useToggle(!hasGithubProfile);

  useEffect(() => {
    if (disabled) {
      setError(undefined);
      onChange(undefined);
    } else if (!disabled && !hasGithubProfile) {
      setError(Errors.Empty);
      onChange('');
    }
  }, [disabled, hasGithubProfile]);

  const handleBlur = () => {
    if (url) {
      const updatedUrl = withHttps(url);
      handleChange(updatedUrl);
    }
  };

  const handleChange = (newUrl: string) => {
    setUrl(newUrl);
    const isValid = isValidGithubUrl(newUrl);
    let errorMessage: string | undefined;

    if (newUrl === '') {
      errorMessage = Errors.Empty;
    } else if (!isValid) {
      errorMessage = Errors.Invalid;
    }

    setError(errorMessage);

    if (isValid) {
      onChange(getGithubUsernameFromURL(newUrl));
    } else {
      onChange('');
    }
  };

  return (
    <SidebarSection title={'GitHub'} readonly>
      <TextInput
        key={`input-${disabled}`} /* ensures autofocus on checkbox state */
        variant="dashed"
        defaultValue={existingUrl}
        value={url}
        onInput={(e) => handleChange(e.currentTarget.value)}
        onBlur={handleBlur}
        autoFocus
        className={styles.input}
        disabled={disabled}
      />
      {error && <p className={styles.error}>{error}</p>}
      {(!isSoftwareEngineer || !hasGithubProfile) && (
        <Checkbox
          checked={disabled}
          className={styles.checkbox}
          onChange={() => {
            setDisabled(!disabled);
          }}
          color={Colors.secondaryDark}
          label="I don't have a GitHub"
          key={disabled.toString()} // Add a key to force re-render
        />
      )}
    </SidebarSection>
  );
};
