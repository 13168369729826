import { Breakpoints } from '@ateams/components';
import { SurveyType } from '@a_team/models/dist/Survey';
import { TeamPulseSurvey } from '@a_team/models/dist/TeamPulse';
import Modal from '@src/components/Modal';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import AllDone from './AllDone';
import AnyBuildersInMind from './AnyBuildersInMind';
import ExitConfirmation from './ExitConfirmation';
import { useTeamPulse } from './hooks/useTeamPulse';
import HowBuildersWouldHelpMission from './HowBuildersWouldHelpMission';
import HowsTheMissionIsGoing from './HowsTheMissionIsGoing';
import MissionNeedsMoreBuilders from './MissionNeedsMoreBuilders';
import MissionReview from './MissionReview';
import RoleRecommendation from './RoleRecommendation';
import ScheduleCall from './ScheduleCall';
import ScopedWellDefined from './ScopedWellDefined';
import TeamMemberFeedback from './TeamMemberFeedback';
import TeamMemberRating from './TeamMemberRating';
import TellUsMore from './TellUsMore';
import TimeForTeamPulse from './TimeForTeamPulse';
import WhatCouldImproveExperience from './WhatCouldImproveExperience';
import WhyTheseBuildersForm, {
  WhyTheseBuildersReasons,
} from './WhyTheseBuildersForm';
import { useControlDetailedTeamPulse } from './hooks/useControlDetailedTeamPulse';
import { useControlSimpleTeamPulse } from './hooks/useControlSimpleTeamPulse';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import useMediaQuery from '@src/hooks/useMediaQuery';
import MissionReviewMobile from './MissionReviewMobile';
import { RoleCategorySelectOption } from '@src/views/Mission/TeamPulse/components/newTeamPulseModal/common/RoleMultiSelect';
import _ from 'lodash';
import ShareHighlights from './ShareHighlights';
import ShareProudMoments from './ShareProudMoments';
import { useMutationSaveTimesheetSummary } from '@src/rq/timesheets';

interface TeamPulseModalProps {
  onClose: () => void;
  teamPulseSurvey: TeamPulseSurvey<SurveyType<never>>;
  withSubmitTimesheets?: boolean;
}

export interface IMemberResponse {
  value?: number;
  comment?: string;
  unableToRespond: boolean;
  wouldLikeToTeamUpAgain: boolean;
  shareFeedbackWithTeammate: boolean;
}

export type MemberResponses = Record<string, IMemberResponse>;

type TeamMemberSubmitFormat = Record<
  string,
  {
    [key: string]: {
      value?: number | boolean | string;
      unableToRespond?: boolean;
    };
  }
>;

const useStyles = createUseStyles<{
  isTeamReview: boolean;
}>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    height: '100%',
  },
  sectionContainer: {
    padding: '0',
    height: '100%',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    // removes global scrollbar
    '@global': {
      'html, body': {
        overflow: 'hidden',
      },
    },
    container: {
      display: 'grid',
      flexDirection: 'column',
      gap: 40,
      alignItems: 'start',
    },
  },
});

export const TeamPulseSectionsEnum = {
  ScheduleCall: 'ScheduleCall',
  MissionNeedsMoreBuilders: 'MissionNeedsMoreBuilders',
  ScopedWellDefined: 'ScopedWellDefined',
  TellUsMore: 'TellUsMore',
  HowsTheMissionIsGoing: 'HowsTheMissionIsGoing',
  TimeForTeamPulse: 'TimeForTeamPulse',
  AllDone: 'AllDone',
  RoleRecommendation: 'RoleRecommendation',
  HowBuildersWouldHelpMission: 'HowBuildersWouldHelpMission',
  WhyTheseBuildersForm: 'WhyTheseBuildersForm',
  WhatCouldImproveExperience: 'WhatCouldImproveExperience',
  AnyBuildersInMind: 'AnyBuildersInMind',
  ExitConfirmation: 'ExitConfirmation',
  TeamMemberRating: 'TeamMemberRating',
  TeamMemberFeedback: 'TeamMemberFeedback',
  ShareHighlights: 'ShareHighlights',
  ShareProudMoments: 'ShareProudMoments',
};

export const TeamPulseUserReviewSectionsEnum = {
  TeamMemberRating: 'TeamMemberRating',
  TeamMemberFeedback: 'TeamMemberFeedback',
};

const TeamPulseModal = ({
  onClose,
  teamPulseSurvey,
  withSubmitTimesheets,
}: TeamPulseModalProps): JSX.Element => {
  const [onExit, setOnExit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | Error>();
  const [toggleHiddenUsers, setToggleHiddenUsers] = useState(false);
  const analytics = useAnalytics();

  const isDesktop = useMediaQuery(`(min-width: ${Breakpoints.md}px)`);

  const { missions, auth, uiStore } = useStores();
  const { currentMission } = missions;

  const [section, setSection] = useState(
    TeamPulseSectionsEnum.TimeForTeamPulse,
  );

  const [userReviewSection, setUserReviewSection] = useState<
    undefined | string
  >();

  const {
    score,
    setScore,
    tellUsMore,
    setTellUsMore,
    shareHighlights,
    setShareHighlights,
    shareProudMoments,
    setShareProudMoments,
    missionNeedsMoreBuilders,
    setMissionNeedsMoreBuilders,
    scopedWellDefined,
    setScopedWellDefined,
    whatCouldImproveExperience,
    setWhatCouldImproveExperience,
    roleRecommendations,
    setRoleRecommendations,
    detailedRoleRecommendations,
    setDetailedRoleRecommendations,
    whyTheseBuilders,
    setWhyTheseBuilders,
    buildersRecommendedForMission,
    setBuildersRecommendedForMission,
    canWeShareFeedbackWithClient,
    setCanWeShareFeedbackWithClient,
    onResetState,
  } = useControlSimpleTeamPulse();

  const { mutate: saveTimesheetSummary } = useMutationSaveTimesheetSummary();

  const {
    usersToReview,
    hiddenUsers,
    memberResponses,
    onPreviousMember,
    currentTeamMemberToReview,
    moveUserToHidden,
    moveUserToReview,
    handleTeamMemberRating,
    handleTeamMemberFeedback,
    onNextMember,
  } = useControlDetailedTeamPulse({
    teamPulseSurvey,
    currentMission,
    setSection,
    setUserReviewSection,
  });

  const [isTeamReview, showMissionReview] = useMemo(() => {
    // if there are no users to review, or hidden users then we treat the survey as simple one
    const isTeamReview =
      teamPulseSurvey?.type === 'detailed' &&
      (usersToReview.length > 0 || (hiddenUsers && hiddenUsers.length > 0));

    const showMissionReview =
      isTeamReview &&
      !onExit &&
      section !== TeamPulseSectionsEnum.TimeForTeamPulse &&
      section !== TeamPulseSectionsEnum.AllDone;

    return [isTeamReview, showMissionReview];
  }, [onExit, section, teamPulseSurvey]);

  const styles = useStyles({
    isTeamReview: showMissionReview,
  });

  const { submit } = useTeamPulse({ teamPulseSurvey });

  const handleSubmitTimesheetSummary = async () => {
    const originalSummarySuggestion = uiStore.originalTimesheetDescriptionHtml;
    const hasSummarySuggestionChange =
      originalSummarySuggestion !== uiStore.timesheetDescriptionHtml;

    const timesheetId = missions?.currentMission?.currentUserTimesheet?.sid;

    if (timesheetId) {
      await saveTimesheetSummary({
        sid: timesheetId,
        summary: uiStore.timesheetDescription,
        summaryHtml: uiStore.timesheetDescriptionHtml,
        demoLink: uiStore.timesheetVideoLink,
        usedMachineTextForSummary: !hasSummarySuggestionChange,
        gptUsageLogId: uiStore.gptUsageLogIdForTimesheetSummary,
      });
    }
  };

  const handleSubmit = async (): Promise<void> => {
    const formattedMemberResponses = Object.keys(memberResponses).reduce(
      (acc: TeamMemberSubmitFormat, key) => {
        if (memberResponses[key]) {
          const memberResponse = memberResponses[key] as IMemberResponse;
          const unableToRespond = memberResponse.unableToRespond
            ? true
            : undefined;
          acc[key] = {
            overallRating: {
              value: !unableToRespond ? memberResponse.value : undefined,
              unableToRespond,
            },
            didNotSpendSignificantTime: {
              value: memberResponse.unableToRespond,
            },
            wouldTimeUpAgain: {
              value: !unableToRespond
                ? memberResponse.wouldLikeToTeamUpAgain
                : undefined,
              unableToRespond,
            },
            whatYouLikedWhatCouldBeImproved: {
              value: !unableToRespond ? memberResponse.comment : undefined,
              unableToRespond,
            },
            share: {
              value: !unableToRespond
                ? memberResponse.shareFeedbackWithTeammate
                : undefined,
              unableToRespond,
            },
          };
        }

        return acc;
      },
      {},
    );

    const submitData = {
      overallRating: {
        value: score,
      },
      canWeShareFeedbackWithClient:
        canWeShareFeedbackWithClient !== undefined
          ? {
              value: canWeShareFeedbackWithClient,
            }
          : undefined,
      tellUsMore: tellUsMore
        ? {
            value: tellUsMore,
          }
        : undefined,
      shareHighlights: shareHighlights
        ? {
            value: shareHighlights,
          }
        : undefined,
      shareProudMoments: shareProudMoments
        ? {
            value: shareProudMoments,
          }
        : undefined,
      whatCouldImproveExperience: whatCouldImproveExperience
        ? {
            value: whatCouldImproveExperience,
          }
        : undefined,
      canMissionUseMoreBuilders:
        missionNeedsMoreBuilders !== undefined
          ? {
              value: missionNeedsMoreBuilders,
            }
          : undefined,
      rolesRecommendedForMission: detailedRoleRecommendations
        ? {
            roles: detailedRoleRecommendations.map((role) =>
              _.omit(role, ['roleCategory']),
            ),
          }
        : undefined,
      buildersRecommendedForMission: buildersRecommendedForMission && {
        value: buildersRecommendedForMission.map((builder) => builder.uid),
      },
      isScopedWellDefined:
        scopedWellDefined !== undefined
          ? {
              value: scopedWellDefined,
            }
          : undefined,
      whyTheseBuilders: whyTheseBuilders && {
        value: Object.keys(whyTheseBuilders).reduce((acc, key) => {
          if (whyTheseBuilders[key as keyof WhyTheseBuildersReasons]) {
            if (key === 'Other') {
              acc.push(
                whyTheseBuilders[
                  key as keyof WhyTheseBuildersReasons
                ] as string,
              );
            } else {
              acc.push(key);
            }
          }
          return acc;
        }, [] as string[]),
      },
      ...(isTeamReview && formattedMemberResponses),
    };

    const hiddenUserIds = hiddenUsers?.map((user) => user.uid);

    try {
      setLoading(true);
      await submit(
        submitData,
        hiddenUserIds?.length ? hiddenUserIds : undefined,
      );
      if (withSubmitTimesheets && currentMission) {
        const promises = [currentMission?.submitTimesheets()];
        await Promise.all(promises);

        if (currentMission?.selectedPaymentCycle?.data?.summary) {
          analytics.trackTimesheetSubmitted(
            currentMission.selectedPaymentCycle?.data?.summary,
          );
        }

        if (auth.withNewTimesheets) {
          await handleSubmitTimesheetSummary();
        }
      }
      onClose();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOnExit = () => {
    setOnExit(true);
  };

  const onCloseConfirmed = () => {
    onClose();
  };

  const onBack = () => {
    setOnExit(false);
  };

  const onRecommendedRolesSelected = (
    roleCategoryOptions: RoleCategorySelectOption[] | undefined,
  ) => {
    setRoleRecommendations(roleCategoryOptions);
    if (roleCategoryOptions) {
      const updatedDetailedRoleRecommendations = roleCategoryOptions.map(
        (role) => {
          const detailedRole = detailedRoleRecommendations?.find(
            (dr) => dr.roleCategoryId === role.value,
          );
          return (
            detailedRole || {
              roleCategoryId: role.value,
              name: role.label,
              skillIds: [],
              comment: '',
              roleCategory: role.roleCategory,
            }
          );
        },
      );
      setDetailedRoleRecommendations(updatedDetailedRoleRecommendations);
    } else {
      setDetailedRoleRecommendations(undefined);
    }
  };

  const isOnlyOneBuilder = useMemo(() => {
    const rolesWithBuilders = currentMission?.rolesSorted.filter(
      (role) => role.user && 'uid' in role.user && role.user.uid,
    );

    return rolesWithBuilders?.length === 1;
  }, [currentMission]);

  const renderSection = () => {
    if (!currentMission) return;

    if (
      userReviewSection === TeamPulseUserReviewSectionsEnum.TeamMemberRating
    ) {
      return (
        <TeamMemberRating
          hiddenUsers={hiddenUsers}
          toggleHiddenUsers={toggleHiddenUsers}
          setToggleHiddenUsers={setToggleHiddenUsers}
          moveUserToReview={moveUserToReview}
          isDesktop={isDesktop}
          handleTeamMemberRating={handleTeamMemberRating}
          missionTitle={currentMission?.data.title || ''}
          teamMember={currentTeamMemberToReview}
          setSection={setUserReviewSection}
          onPreviousMember={onPreviousMember}
          moveUserToHidden={moveUserToHidden}
          onNextMember={onNextMember}
          memberResponse={
            currentTeamMemberToReview
              ? memberResponses[currentTeamMemberToReview?.uid]
              : undefined
          }
        />
      );
    }

    if (
      userReviewSection === TeamPulseUserReviewSectionsEnum.TeamMemberFeedback
    ) {
      return (
        <TeamMemberFeedback
          hiddenUsers={hiddenUsers}
          toggleHiddenUsers={toggleHiddenUsers}
          setToggleHiddenUsers={setToggleHiddenUsers}
          moveUserToReview={moveUserToReview}
          isDesktop={isDesktop}
          handleTeamMemberFeedback={handleTeamMemberFeedback}
          missionTitle={currentMission?.data.title || ''}
          teamMember={currentTeamMemberToReview}
          setSection={setUserReviewSection}
          onNextMember={onNextMember}
          memberResponse={
            currentTeamMemberToReview
              ? memberResponses[currentTeamMemberToReview?.uid]
              : undefined
          }
        />
      );
    }

    switch (section) {
      case TeamPulseSectionsEnum.TimeForTeamPulse:
        return (
          <TimeForTeamPulse longForm={!!isTeamReview} setSection={setSection} />
        );
      case TeamPulseSectionsEnum.HowsTheMissionIsGoing:
        return (
          <HowsTheMissionIsGoing
            isTeamReview={isTeamReview}
            withTeamPulseNewQuestions={auth.withTeamPulseNewQuestions}
            canWeShareFeedbackWithClient={canWeShareFeedbackWithClient}
            setCanWeShareFeedbackWithClient={setCanWeShareFeedbackWithClient}
            isOnlyOneBuilder={isOnlyOneBuilder}
            missionTitle={currentMission.data.title}
            setSection={setSection}
            setScore={setScore}
            onResetState={onResetState}
          />
        );
      case TeamPulseSectionsEnum.TellUsMore:
        return (
          <TellUsMore
            setTellUsMore={setTellUsMore}
            missionTitle={currentMission.data.title}
            setUserReviewSection={setUserReviewSection}
            setSection={setSection}
            initialState={tellUsMore}
          />
        );
      case TeamPulseSectionsEnum.ScheduleCall:
        return (
          <ScheduleCall
            setUserReviewSection={setUserReviewSection}
            setSection={setSection}
            needsToReviewUsers={usersToReview && usersToReview.length > 0}
          />
        );
      case TeamPulseSectionsEnum.AllDone:
        return (
          <AllDone
            withSubmitTimesheets={withSubmitTimesheets}
            loading={loading}
            handleSubmit={handleSubmit}
            paymentCycle={currentMission?.selectedPaymentCycle}
          />
        );
      case TeamPulseSectionsEnum.MissionNeedsMoreBuilders:
        return (
          <MissionNeedsMoreBuilders
            needsToReviewUsers={usersToReview && usersToReview.length > 0}
            setMissionNeedsMoreBuilders={setMissionNeedsMoreBuilders}
            missionTitle={currentMission.data.title}
            setUserReviewSection={setUserReviewSection}
            setSection={setSection}
            initialState={missionNeedsMoreBuilders}
          />
        );

      case TeamPulseSectionsEnum.ShareHighlights:
        return (
          <ShareHighlights
            setShareHighlights={setShareHighlights}
            missionTitle={currentMission.data.title}
            setUserReviewSection={setUserReviewSection}
            setSection={setSection}
            initialState={tellUsMore}
          />
        );
      case TeamPulseSectionsEnum.ShareProudMoments:
        return (
          <ShareProudMoments
            setShareProudMoments={setShareProudMoments}
            missionTitle={currentMission.data.title}
            setUserReviewSection={setUserReviewSection}
            setSection={setSection}
            initialState={tellUsMore}
          />
        );
      case TeamPulseSectionsEnum.ScopedWellDefined:
        return (
          <ScopedWellDefined
            setScopedWellDefined={setScopedWellDefined}
            missionTitle={currentMission.data.title}
            setSection={setSection}
            initialState={scopedWellDefined}
          />
        );
      case TeamPulseSectionsEnum.WhatCouldImproveExperience:
        return (
          <WhatCouldImproveExperience
            needsToReviewUsers={usersToReview && usersToReview.length > 0}
            setWhatCouldImproveExperience={setWhatCouldImproveExperience}
            missionTitle={currentMission.data.title}
            setUserReviewSection={setUserReviewSection}
            setSection={setSection}
            initialState={whatCouldImproveExperience}
          />
        );
      case TeamPulseSectionsEnum.RoleRecommendation:
        return (
          <RoleRecommendation
            setRoleRecommendations={onRecommendedRolesSelected}
            missionTitle={currentMission.data.title}
            setSection={setSection}
            initialState={roleRecommendations}
          />
        );
      case TeamPulseSectionsEnum.HowBuildersWouldHelpMission:
        return (
          <HowBuildersWouldHelpMission
            setRoleRecommendations={setDetailedRoleRecommendations}
            missionTitle={currentMission.data.title}
            setSection={setSection}
            initialState={detailedRoleRecommendations || []}
          />
        );
      case TeamPulseSectionsEnum.AnyBuildersInMind:
        return (
          <AnyBuildersInMind
            needsToReviewUsers={usersToReview && usersToReview.length > 0}
            setBuildersRecommendedForMission={setBuildersRecommendedForMission}
            missionTitle={currentMission.data.title}
            setUserReviewSection={setUserReviewSection}
            setSection={setSection}
            initialState={buildersRecommendedForMission}
          />
        );
      case TeamPulseSectionsEnum.ExitConfirmation:
        return <ExitConfirmation onExit={onCloseConfirmed} onBack={onBack} />;
      case TeamPulseSectionsEnum.WhyTheseBuildersForm:
        return (
          <WhyTheseBuildersForm
            needsToReviewUsers={usersToReview && usersToReview.length > 0}
            missionTitle={currentMission.data.title}
            setUserReviewSection={setUserReviewSection}
            setSection={setSection}
            setWhyTheseBuilders={setWhyTheseBuilders}
            initialState={whyTheseBuilders}
          />
        );
    }

    return;
  };

  const padding = isDesktop
    ? section === TeamPulseSectionsEnum.HowBuildersWouldHelpMission
      ? '40px 40px 0 40px'
      : '40px'
    : '0';

  return (
    <Modal
      onClose={handleOnExit}
      // unmounting takes care of showing the modal again
      open={true}
      hideCloseButton={isDesktop}
      className={styles.modal}
      style={{
        width: isDesktop ? (showMissionReview && !onExit ? 1130 : 680) : '100%',
        maxWidth: isDesktop ? 'calc(100% - 48px)' : '100%',
        maxHeight: isDesktop ? 'calc(100% - 48px)' : '100%',
        height: isDesktop ? 'auto' : '100%',
        padding,
      }}
    >
      {onExit ? (
        <ExitConfirmation onExit={onCloseConfirmed} onBack={onBack} />
      ) : (
        <div
          className={styles.container}
          style={{
            gridTemplateColumns: isDesktop
              ? showMissionReview
                ? '350px 1fr'
                : '1fr'
              : undefined,
            height: isDesktop ? (showMissionReview ? 730 : 'auto') : undefined,
          }}
        >
          {showMissionReview && isDesktop && (
            <MissionReview
              toggleHiddenUsers={toggleHiddenUsers}
              setToggleHiddenUsers={setToggleHiddenUsers}
              usersToReview={usersToReview}
              moveUserToReview={moveUserToReview}
              hiddenUsers={hiddenUsers}
              memberResponses={memberResponses}
              currentTeamMemberToReview={
                userReviewSection ? currentTeamMemberToReview : undefined
              }
            />
          )}
          {showMissionReview && !isDesktop && (
            <MissionReviewMobile
              isReviewingTeamMember={!!userReviewSection}
              usersToReview={usersToReview}
              memberResponses={memberResponses}
              currentTeamMemberToReview={
                userReviewSection ? currentTeamMemberToReview : undefined
              }
            />
          )}
          <div className={styles.sectionContainer}>{renderSection()}</div>
          {error ? (
            <span
              style={{
                color: 'red',
              }}
            >
              {error?.message}
            </span>
          ) : null}
        </div>
      )}
    </Modal>
  );
};

export default observer(TeamPulseModal);
