import { ServiceAuth } from '@ateams/service-utils';
import { ServiceEndpoint } from './utils';
import { QueryResult } from '@a_team/models/dist/misc';
import PaymentMethod, {
  PaymentMethodId,
  PaymentMethodSetupIntent,
} from '@a_team/models/dist/PaymentMethod';
import { ClientCompanyId } from '@a_team/models/src/ClientCompanies';

export const BasePath = '/payment-methods';

export interface RegisterPaymentMethodData extends UpdatePaymentMethodData {
  setupIntentId: string;
  // nickname?: string;
  // preferable?: boolean;
}

export interface UpdatePaymentMethodData {
  nickname?: string;
  preferable?: boolean;
}

export default class PaymentMethodsEndpoint extends ServiceEndpoint {
  public queryByClientCompany(
    auth: ServiceAuth,
    clientCompanyId: ClientCompanyId,
  ): Promise<QueryResult<PaymentMethod>> {
    return this.fetch(
      auth,
      `${BasePath}`,
      { clientCompany: clientCompanyId },
      'get',
    );
  }

  public createSetupIntent(
    auth: ServiceAuth,
    clientCompanyId: ClientCompanyId,
  ): Promise<PaymentMethodSetupIntent> {
    return this.fetch(
      auth,
      `${BasePath}/setup-intent`,
      { clientCompany: clientCompanyId },
      'post',
    );
  }

  public register(
    auth: ServiceAuth,
    clientCompanyId: ClientCompanyId,
    data: RegisterPaymentMethodData,
  ): Promise<PaymentMethod> {
    return this.fetch(
      auth,
      `${BasePath}`,
      { clientCompany: clientCompanyId },
      'post',
      data,
    );
  }

  public get(auth: ServiceAuth, id: PaymentMethodId): Promise<PaymentMethod> {
    return this.fetch(auth, `${BasePath}/${id}`);
  }

  public update(
    auth: ServiceAuth,
    id: PaymentMethodId,
    data: UpdatePaymentMethodData,
  ): Promise<PaymentMethod> {
    return this.fetch(auth, `${BasePath}/${id}`, null, 'put', data);
  }

  public remove(auth: ServiceAuth, id: PaymentMethodId): Promise<void> {
    return this.fetch(auth, `${BasePath}/${id}`, null, 'delete');
  }
}
