import { RecommendationStatus } from '@a_team/models/dist/RecommendationObject';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import { Breakpoints } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import useMediaQuery from '@src/hooks/useMediaQuery';
import { ProfileLocation } from '@src/locations';
import { previewUploadCareImageUrl } from '@src/logic/uploadcare';
import cx from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';

interface TeamUpMemberProps {
  className?: string;
  user: BasicUserObject;
  status?: RecommendationStatus;
  toggleTooltip: (open: boolean) => void;
}

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    cursor: 'pointer',
  },
  profilePicture: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    border: '1px solid white',
  },
  noAction: {
    opacity: 0.5,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    profilePicture: {
      width: 32,
      height: 32,
    },
  },
});

const TeamUpMember = ({
  className,
  user,
  toggleTooltip,
  status,
}: TeamUpMemberProps): JSX.Element => {
  const styles = useStyles();
  const history = useHistory();
  const isDesktop = useMediaQuery(`(min-width: ${Breakpoints.md}px)`);

  return (
    <div
      onMouseEnter={() => {
        toggleTooltip(true);
      }}
      onClick={() => {
        if (isDesktop) {
          history.push(ProfileLocation(user.username));
        }
        toggleTooltip(true);
      }}
      className={cx(styles.container, className)}
    >
      <UserAvatar
        src={previewUploadCareImageUrl(user.profilePictureURL, 180)}
        size={32}
        shouldResizeUploadCareImages={false}
        imageClassName={cx(styles.profilePicture, {
          [styles.noAction]:
            status === RecommendationStatus.Pending ||
            status === RecommendationStatus.Unavailable,
        })}
      />
    </div>
  );
};

export default TeamUpMember;
