import React, { ReactElement, useMemo } from 'react';
import { useStores } from '@src/stores';
import logo from './logo.svg';
import heart from './heart.svg';
import MainLayout from '@src/layouts/Main';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { Breakpoints, Colors, TextColors } from '@ateams/components';
import { addMonths, differenceInDays, differenceInMonths } from 'date-fns';
import { pluralize } from '@src/helpers/strings';
import { Button } from '@a_team/ui-components';

const useStyles = createUseStyles({
  gradient: {
    background:
      'linear-gradient(170deg, rgba(99,42,83,1) 0%, rgba(51,17,86,1) 40%, rgba(0,0,0,1) 60%)',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    height: '100vh',
    '& > div > div': {
      borderLeft: 'none',
    },
  },
  wrap: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px 0',
  },
  notification: {
    backgroundColor: '#fff',
    padding: '40px 115px',
    borderRadius: '1em',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.08)',
    maxWidth: '830px',
    margin: '1em auto 1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '30px',
    color: '#222',
    margin: '0 0 16px',
  },
  copy: {
    color: '#818388',
    marginTop: 0,
  },
  reapply: {
    color: '#818388',
    marginTop: 18,
  },
  button: {
    lineHeight: 1,
    borderRadius: 8,
    marginTop: 16,
    maxWidth: '156px',
    maxHeight: '40px',
    padding: '11px 20px',
    backgroundColor: `${Colors.backgroundDark} !important`,
    color: `${TextColors.regular} !important`,
  },
  logoutButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: 8,
  },
  [`@media (max-width: ${Breakpoints.sm}px)`]: {
    notification: {
      padding: '20px',
      margin: '1em',
    },
  },
});

function RejectionView(): ReactElement {
  const { auth } = useStores();

  const styles = useStyles();
  const [months, days] = useMemo(() => {
    if (auth.currentUser?.joinedAt) {
      const now = new Date();
      const resubmitDate = addMonths(new Date(auth.currentUser.joinedAt), 12);

      const months = Math.max(0, differenceInMonths(resubmitDate, now));
      const days = Math.max(0, differenceInDays(resubmitDate, now));
      return [months, days];
    }

    return [12, 365];
  }, [auth.currentUser?.joinedAt]);

  return (
    <MainLayout title="Not Fit" naked hideNavbar className={styles.gradient}>
      <div className={styles.wrap}>
        <img src={logo} style={{ marginBottom: 32, width: 30 }} alt={'Logo'} />
        <div className={styles.notification}>
          <img
            src={heart}
            style={{ marginBottom: 40, width: 120 }}
            alt={'Not fit'}
          />
          <h1 className={styles.title}>
            {auth.currentUser?.firstName}, we're not a good fit right now.
          </h1>
          <p className={styles.copy}>
            Thank you for taking the time to request to join A.Team. After a
            careful review of your profile, we’ve concluded that our missions
            aren’t a good match for your skills.
          </p>
          <p className={styles.reapply}>
            {months === 0 && days === 0 ? (
              `You can request to join again by reaching out to support@a.team.`
            ) : (
              <div>
                You can request to join again in{' '}
                {months > 0
                  ? pluralize(months, 'month')
                  : pluralize(days, 'day')}
                .
              </div>
            )}
          </p>
          <div>
            <div className={styles.logoutButtonWrapper}>
              <Button className={styles.button}>Log out</Button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default observer(RejectionView);
