import { SharedProjectExperience } from '@a_team/models/dist/ConnectionObject';
import {
  ExperienceMemberRole,
  ExperienceSource,
  ExperienceType,
} from '@a_team/models/dist/ExperienceObject';
import { CollaboratorStatus } from '@a_team/models/dist/UserObject';
import { generateUniqueId } from '@src/helpers/strings';
import { useCreateUserExperience } from '@src/rq/experiences';
import { useGetProfileByUsername } from '@src/rq/profile';
import { useQueryTalentSpecializationRoles } from '@src/rq/specializations';
import { useStores } from '@src/stores';
import { ExistingProject, NewProject } from '@src/stores/Profile/models';
import EditProjectModalV2 from '@src/views/Profile/Main/Projects/EditProjectModalV2';
import React, { useMemo } from 'react';

interface AddProjectExperienceModalWrapperProps {
  open: boolean;
  handleAddNewlyCreatedExperience: (experienceId: string) => void;
  onAddProjectExperience: (projectExperience: SharedProjectExperience) => void;
  onClose: () => void;
}

const AddProjectExperienceModalWrapper = ({
  open,
  onAddProjectExperience,
  handleAddNewlyCreatedExperience,
  onClose,
}: AddProjectExperienceModalWrapperProps): JSX.Element => {
  const { data: user } = useGetProfileByUsername();
  const { data: roles } = useQueryTalentSpecializationRoles({
    query: '',
  });

  const createUserExperience = useCreateUserExperience();

  const stores = useStores();
  const { auth, profile } = stores;

  const mainRole =
    user?.talentProfile?.talentSpecializations?.mainTalentSpecialization;

  const roleOptions = useMemo(() => {
    return (
      roles?.map((role) => {
        return { label: role.name, value: role.id };
      }) || []
    );
  }, [roles]);

  const newProject: NewProject = {
    _id: generateUniqueId(),
    type: ExperienceType.Project,
    title: '',
    jobRoleId: mainRole?.id || '',
    jobRole: '',
    description: '',
    source: ExperienceSource.Default,
    members: auth.user
      ? [
          {
            ...auth.user,
            ...{
              memberTitle: auth.user.roleCategory?.title || '',
              experienceRole: ExperienceMemberRole.Owner,
              collaboratorStatus: CollaboratorStatus.Active,
            },
          },
        ]
      : [],
  };

  const onSubmitProject = async (newProject: NewProject | ExistingProject) => {
    const res = await createUserExperience.mutateAsync(newProject);
    handleAddNewlyCreatedExperience(res.eid);
    const project = res as SharedProjectExperience;
    onAddProjectExperience(project);
    onClose();
  };

  return (
    <div>
      {newProject && open && user && (
        <EditProjectModalV2
          allJobs={profile?.jobs}
          allSkills={user.allSkills}
          currentUserId={user.uid}
          setEditModalOpen={onClose}
          editModalOpen={open}
          selectedProject={newProject}
          isOwner
          roles={roleOptions}
          onSubmitProject={onSubmitProject}
          onClose={onClose}
          loading={createUserExperience.isLoading}
        />
      )}
    </div>
  );
};

export default AddProjectExperienceModalWrapper;
