import cx from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface PreviewImagesUnderneathProps {
  images: string[];
  currentImage: string;
  setCurrentImage: (image: string) => void;
}

const useStyles = createUseStyles({
  thumbnails: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center',
    gap: 8,
  },
  thumbnail: {
    '&.active': {
      borderRadius: 6,
      border: '1px solid #6D00D7',
      padding: 3,
    },
    cursor: 'pointer',
  },
  image: {
    width: 64,
    height: 48,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 6,
    '&.active': {
      width: 53,
      height: 40,
      borderRadius: 3,
    },
  },
});

const PreviewImagesUnderneath = ({
  images,
  currentImage,
  setCurrentImage,
}: PreviewImagesUnderneathProps): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.thumbnails}>
      {images.map((image, index) => (
        <div
          key={`thumbnail-${index}`}
          className={cx(
            styles.thumbnail,
            currentImage === image ? 'active' : undefined,
          )}
          onClick={(e) => {
            e.stopPropagation();
            setCurrentImage(image);
          }}
        >
          <div
            className={cx(styles.image, currentImage === image ? 'active' : '')}
            style={{ backgroundImage: `url("${image}")` }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default PreviewImagesUnderneath;
