import { ServiceAuth, ServiceEndpoint } from './utils';
import {
  TeammateSuggestionObject,
  UserCardObject,
} from '@a_team/models/dist/UserObject';
import {
  DateISOString,
  QueryNextToken,
  QueryResult,
} from '@a_team/models/dist/misc';
import { ConnectionObjectV2 } from '@a_team/models/dist/ConnectionObject';
import { MissionId } from '@a_team/models/dist/MissionObject';
import { MissionRoleId } from '@a_team/models/dist/MissionRole';

export const BasePath = '/discovery';

export interface ProfileViews {
  period: 'weekly';
  totalViews: number;
  lastPeriodViews: number;
  dailyRecords: { views: number; date: DateISOString }[];
}

export interface ConnectionsCategory {
  title: string;
  count: number;
}

export interface ConnectionsOverview {
  totalCount: number;
  categories: ConnectionsCategory[];
}

export interface UserStatistics {
  invitesSent: number;
}

export interface DiscoveryResponse {
  suggestedUsers: QueryResult<UserCardObject>;
  topUsers: UserCardObject[];
  profileViews: ProfileViews;
  statistics: UserStatistics;
  connectionsOverview: ConnectionsOverview;
  recentConnectionChanges: QueryResult<ConnectionObjectV2>;
  activeConnectionUsers: QueryResult<UserCardObject>;
}

// exported functions

export default class DiscoveryEndpoint extends ServiceEndpoint {
  public getDiscovery(auth: ServiceAuth): Promise<DiscoveryResponse> {
    return this.fetch(auth, BasePath);
  }

  public querySuggestedUsers(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<DiscoveryResponse['suggestedUsers']> {
    return this.fetch(auth, BasePath + `/users/suggested`, { next });
  }

  public searchUsers(
    auth: ServiceAuth,
    query: string,
    next?: QueryNextToken,
  ): Promise<QueryResult<UserCardObject>> {
    return this.fetch(auth, BasePath + `/users/search`, { query, next });
  }

  public searchProspectiveTeammates(
    auth: ServiceAuth,
    mid: MissionId,
    appliedRole: MissionRoleId,
    query: string,
    next?: QueryNextToken,
  ): Promise<QueryResult<TeammateSuggestionObject>> {
    return this.fetch(
      auth,
      BasePath + `/missions/${mid}/roles/${appliedRole}/search`,
      { query, next },
    );
  }
}
