import { createQueryKeys } from '@lukemorales/query-key-factory';

export const profileSuggestions = createQueryKeys('profileSuggestions', {
  byUserId: (userId: string) => ({
    userId,
  }),
  adminByUserId: (userId: string) => ({
    userId,
    admin: true,
  }),
  enhanceProfileByUserId: (userId: string) => ({
    userId,
  }),
  isLatestProfileEnhancementRequestCompleted: (userId: string) => ({
    userId,
  }),
  acceptByUserId: (userId: string) => ({
    userId,
  }),
  acceptIndustryByUserId: (userId: string) => ({
    userId,
  }),
  acceptRoleByUserId: (userId: string) => ({
    userId,
  }),
  partiallyRejectByUserId: (userId: string) => ({
    userId,
  }),
  rejectByUserId: (userId: string) => ({
    userId,
  }),
  useGenerateProjectsSuggestionFromWebsiteOrPdf: (userId: string) => ({
    userId,
  }),
});
