import { apiUser } from '@ateams/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './keys';

export const useGetPlacesSuggestions = ({
  searchTerm,
  enabled,
}: {
  searchTerm: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.places.useGetPlacesSuggestions(searchTerm),
    queryFn: async () => {
      if (!searchTerm) return [];

      return await apiUser.getPlacesSuggestions(searchTerm);
    },
    enabled,
  });
};

export const useGetPlaceById = () => {
  const queryClient = useQueryClient();

  const getPlaceById = async (placeId: string) => {
    if (!placeId) return {};

    const queryKey = queryKeys.places.useGetPlaceById(placeId);

    return await queryClient.fetchQuery(queryKey, () =>
      apiUser.getPlaceById(placeId),
    );
  };

  return getPlaceById;
};
