import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Icon, IconType, ToggleSwitch } from '@ateams/components';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { Button } from '@a_team/ui-components';
import { useCommonStyles } from './commonStyles';
import {
  useGetUserCalendar,
  useMutationUpdateCalendarPreferences,
} from '@src/rq/profile';
import { NoCalendarReasonsModal } from './ReasonsModal';
import { goToManageCalendarClick, goToWorkingHoursClick } from './helpers';
import { useStores } from '@src/stores';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { ConnectCalendarButton } from './ConnectCalendarButton';

const InterviewSettingsView = () => {
  const styles = useCommonStyles();
  const { auth } = useStores();
  const analytics = useAnalytics();
  const { data: calendarData, isLoading: isCalendarLoading } =
    useGetUserCalendar();

  const { isLoading: isUpdating, mutate: updatePreferences } =
    useMutationUpdateCalendarPreferences();

  const [showCustomModal, setShowCustomModal] = useState<boolean>(false);

  const calcomCalendar = calendarData?.calcom?.selectedCalendar;
  const userId = auth.uid ?? '';
  const autoRecord = !!calendarData?.calcom?.autoRecord;

  const handleCalendarClick = () => {
    if (!calcomCalendar) {
      setShowCustomModal(true);
      return;
    }

    goToManageCalendarClick();
    analytics.trackManageCalendarClick(userId);
  };

  return (
    <div>
      <div className={styles.section}>
        <div className={styles.headline}>Interviews</div>
        <div className={styles.mainCopy}>
          Enable companies to schedule interviews with you directly.
        </div>
      </div>
      {!isCalendarLoading && (
        <div className={styles.section}>
          <div className={styles.subHeadline}>
            {calcomCalendar
              ? `Connected calendar`
              : `Schedule interviews faster`}
          </div>
          <div className={styles.copy}>
            {calcomCalendar
              ? `Companies can now schedule interviews with you directly.`
              : `Connect your Calendar to allow companies to schedule interviews with you directly.`}
          </div>
          <div className={styles.cardsContainer}>
            <div className={styles.card}>
              <div className={styles.calendarMainContainer}>
                <div className={styles.calendarWrap}>
                  <Icon type={IconType.PurpleCalendar} size="large" />
                  <div>
                    <div
                      className={
                        calcomCalendar
                          ? styles.calendarInstalledTitle
                          : styles.noCalendarInstalledTitle
                      }
                    >
                      Interviewing
                      {calcomCalendar ? ' ' : ' not '}
                      enabled
                    </div>
                    {calcomCalendar && (
                      <div className={styles.calendarInstalledSubtitle}>
                        Calendar connected
                      </div>
                    )}
                  </div>
                </div>
                <ConnectCalendarButton
                  triggerTeaser
                  onCalendarClick={handleCalendarClick}
                  hasCalendar={!!calcomCalendar}
                />
              </div>
            </div>
            {calcomCalendar && (
              <div className={styles.card}>
                <div className={styles.subHeadline}>Working hours</div>
                <div className={styles.copy}>
                  Configure days and time this event can be booked{' '}
                </div>
                <div className={styles.calendarMainContainer}>
                  <div>
                    <div className={styles.calendarInstalledTitle}>
                      Manage your working hours
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      className={styles.calendarButton}
                      onClick={() => {
                        goToWorkingHoursClick(
                          calendarData.calcom?.schedule?.id,
                        );
                        analytics.trackManageWorkingHoursClicked(userId);
                      }}
                      variant="ghost"
                    >
                      Adjust working hours
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {calcomCalendar && (
              <div className={styles.card}>
                <div className={styles.subHeadline}>
                  Interview Recording & Feedback
                </div>
                <div className={styles.copy}>
                  When enabled, interviews you schedule with A.Team will be
                  recorded, transcribed and analyzed to give you feedback, help
                  improve your profile and more.
                </div>
                <div className={styles.calendarMainContainer}>
                  <div>
                    <div className={styles.calendarInstalledTitle}>
                      <ToggleSwitch
                        color="purple"
                        size={'small'}
                        checked={autoRecord}
                        onChange={() =>
                          updatePreferences({
                            autoRecord: !autoRecord,
                          })
                        }
                        disabled={isUpdating || isCalendarLoading}
                        label={`Interview recordings ${
                          autoRecord ? 'enabled' : 'disabled'
                        }`}
                      />{' '}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <NoCalendarReasonsModal
        open={showCustomModal}
        onClose={() => setShowCustomModal(false)}
        fullScreen
      />
      <LoadingIndicator
        loading={isCalendarLoading}
        successMsg="Settings loaded"
      />
    </div>
  );
};

export default observer(InterviewSettingsView);
