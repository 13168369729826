import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Spacing } from '@ateams/components';
import {
  VettingProcessFeedbackRole,
  VettingProcessFeedbackRoles,
} from '@a_team/models/dist/vetting-processes/feedback';
import { VettingFormVariant } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { Card } from '../../components/card';
import { ErrorTag, Text } from '../../components/typography';
import { RoleInput, UserRoleInput } from './role-input';
import { getFeedbackFormRolesByVettingFormVariant } from './view-metadata';
import { VettingFeedbackFormErrors } from '../form-errors';
import { Separator } from '../../components/separator';
import RoleCategoryObject from '@a_team/models/dist/RoleCategory';

const useStyles = createUseStyles({
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  regularRolesInputs: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  regularRoleInputsColumnContainer: {
    flexDirection: 'row',
  },
  otherRolesInputs: {
    display: 'inline-flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: Spacing.small,
  },
  regularRoleInputsColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

export interface VettingFeedbackFormRoles {
  roles: VettingProcessFeedbackRole[];
  userRole?: RoleCategoryObject;
}

export type OnVettingFeedbackFormRolesChange = (data: {
  roles?: VettingProcessFeedbackRole[];
  userRole?: RoleCategoryObject;
}) => void;

export interface VettingFeedbackFormRolesProps {
  vettingFormVariant: VettingFormVariant;
  userRoleCategory: RoleCategoryObject | null;
  wasFeedbackFormSubmitted?: boolean;
  errors: VettingFeedbackFormErrors['roles'];
  defaultValues: VettingFeedbackFormRoles;
  onChange?: OnVettingFeedbackFormRolesChange;
  isReadOnly?: boolean;
  className?: string;
}

export const VettingFeedbackFormRolesForm: React.FC<VettingFeedbackFormRolesProps> =
  React.memo((props) => {
    const { isReadOnly } = props;
    const styles = useStyles();
    const [roles, setRoles] = useState(props.defaultValues.roles);
    const [userRole, setUserRole] = useState(props.defaultValues.userRole);
    /** {@link https://stackoverflow.com/a/53351556} */
    const isFirstRender = useRef(true);

    const showUserRoleInput = useMemo(
      () => props.vettingFormVariant === VettingFormVariant.Operations,
      [props.vettingFormVariant],
    );

    useEffect(() => {
      if (
        !props.wasFeedbackFormSubmitted &&
        showUserRoleInput &&
        props.userRoleCategory
      ) {
        setUserRole(props.userRoleCategory);
        props.onChange?.({ userRole: props.userRoleCategory });
      }
    }, []);

    const onUserRoleClick = () => {
      const newUserRole =
        userRole?.cid === props.userRoleCategory?.cid
          ? undefined
          : props.userRoleCategory || undefined;
      setUserRole(newUserRole);
      props.onChange?.({ userRole: newUserRole });
    };

    const onRoleClick = (
      role: VettingProcessFeedbackRoles,
      isChecked: boolean,
    ) => {
      const newRoles = isChecked
        ? [...roles, { role, tags: [] }]
        : roles.filter((selectedRole) => selectedRole.role !== role);

      setRoles(newRoles);
      props.onChange?.({ roles: newRoles });
    };

    const onTagClick = (
      role: VettingProcessFeedbackRoles,
      tag: string,
      isChecked: boolean,
    ) => {
      let newRoles: VettingFeedbackFormRoles['roles'];
      if (isChecked) {
        newRoles = roles.map((selectedRole) => ({
          role: selectedRole.role,
          tags:
            role === selectedRole.role
              ? [...selectedRole.tags, tag]
              : selectedRole.tags,
        }));
      } else {
        newRoles = roles.map((selectedRole) => ({
          role: selectedRole.role,
          tags:
            role === selectedRole.role
              ? selectedRole.tags.filter((selectedTag) => selectedTag !== tag)
              : selectedRole.tags,
        }));
      }

      setRoles(newRoles);
      props.onChange?.({ roles: newRoles });
    };

    const {
      RegularVettingProcessFeedbackRoles,
      OtherVettingProcessFeedbackRoles,
    } = useMemo(
      () => getFeedbackFormRolesByVettingFormVariant(props.vettingFormVariant),
      [props.vettingFormVariant],
    );

    /**
     * If we change the variant we want to keep having the same roles(and remove those that don't exist on the new variant)
     */
    useEffect(() => {
      if (isFirstRender.current) {
        return;
      }

      if (userRole && !showUserRoleInput) {
        setUserRole(undefined);
        props.onChange?.({ userRole: undefined });
      }

      setRoles((previousRoles) => {
        const newRoles: VettingProcessFeedbackRole[] = [];
        const allCurrentRoles = [
          ...RegularVettingProcessFeedbackRoles,
          ...OtherVettingProcessFeedbackRoles,
        ];

        for (const previousRole of previousRoles) {
          if (allCurrentRoles.includes(previousRole.role)) {
            newRoles.push(previousRole);
          }
        }

        props.onChange?.({ roles: newRoles });

        return newRoles;
      });
    }, [props.vettingFormVariant]);

    const selectedRolesSet = useMemo(
      () => new Set(roles.map(({ role }) => role)),
      [roles],
    );

    const selectedRegularRolesSet = useMemo(() => {
      const selectedRegularRolesSet = new Set<VettingProcessFeedbackRoles>();
      for (const role of RegularVettingProcessFeedbackRoles) {
        if (selectedRolesSet.has(role)) {
          selectedRegularRolesSet.add(role);
        }
      }
      return selectedRegularRolesSet;
    }, []);

    const selectedOtherRolesSet = useMemo(() => {
      const selectedOtherRoles = new Set<VettingProcessFeedbackRoles>();
      for (const role of OtherVettingProcessFeedbackRoles) {
        if (selectedRolesSet.has(role)) {
          selectedOtherRoles.add(role);
        }
      }
      return selectedOtherRoles;
    }, []);

    useEffect(() => {
      isFirstRender.current = false;
    }, []);

    const renderRoleInputs = () => {
      const inputs = [];

      if (!selectedRegularRolesSet.size && isReadOnly) {
        return (
          <div className={styles.regularRolesInputs}>
            <Text
              isReadOnly
              readOnlyText={'No primary roles have been selected.'}
              className={styles.marginBottom}
            />
          </div>
        );
      }

      if (isReadOnly && props.defaultValues.userRole) {
        inputs.push(
          <UserRoleInput
            role={props.defaultValues.userRole}
            value={true}
            isReadOnly
            className={styles.marginBottom}
          />,
        );
      } else if (!isReadOnly && props.userRoleCategory && showUserRoleInput) {
        inputs.push(
          <UserRoleInput
            role={props.userRoleCategory}
            value={userRole?.cid === props.userRoleCategory?.cid}
            onRoleClick={onUserRoleClick}
            className={styles.marginBottom}
          />,
        );
      }

      for (const role of RegularVettingProcessFeedbackRoles) {
        inputs.push(
          <RoleInput
            key={role}
            role={role as VettingProcessFeedbackRoles}
            value={roles.find((selectedRole) => selectedRole.role === role)}
            onRoleClick={(isChecked) =>
              onRoleClick(role as VettingProcessFeedbackRoles, isChecked)
            }
            onTagClick={(tag, isChecked) =>
              onTagClick(role as VettingProcessFeedbackRoles, tag, isChecked)
            }
            isReadOnly={isReadOnly}
            className={styles.marginBottom}
          />,
        );
      }

      if (
        props.isReadOnly ||
        [
          VettingFormVariant.SoftwareEngineering,
          VettingFormVariant.Operations,
        ].includes(props.vettingFormVariant)
      ) {
        return <div className={styles.regularRolesInputs}>{inputs}</div>;
      }

      return (
        <div
          className={cx(
            styles.regularRolesInputs,
            styles.regularRoleInputsColumnContainer,
          )}
        >
          <div className={styles.regularRoleInputsColumn}>
            {inputs.filter((_, index) => index % 2 === 0)}
          </div>

          <div className={styles.regularRoleInputsColumn}>
            {inputs.filter((_, index) => index % 2 === 1)}
          </div>
        </div>
      );
    };

    return (
      <Card title={'Role'} className={props.className}>
        {props.errors.generalError && (
          <ErrorTag
            text={props.errors.generalError}
            className={styles.marginBottom}
          />
        )}

        {renderRoleInputs()}

        <Separator direction={'horizontal'} space={Spacing.medium} />

        <Text className={styles.marginBottom}>Other</Text>

        <div className={styles.otherRolesInputs}>
          {isReadOnly && !selectedOtherRolesSet.size ? (
            <Text
              isReadOnly
              readOnlyText={'No other roles have been selected.'}
            />
          ) : (
            OtherVettingProcessFeedbackRoles.map((role) => (
              <RoleInput
                key={role}
                role={role}
                value={roles.find((selectedRole) => selectedRole.role === role)}
                onRoleClick={(isChecked) => onRoleClick(role, isChecked)}
                onTagClick={(tag, isChecked) =>
                  onTagClick(role, tag, isChecked)
                }
                isReadOnly={isReadOnly}
              />
            ))
          )}
        </div>
      </Card>
    );
  });
