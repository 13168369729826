import React, { ReactElement, useMemo } from 'react';
import { observer } from 'mobx-react';
import RegistrationStep from '@src/views/Registration/RegistrationStep';
import { useStores } from '@src/stores';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import {
  isDribbbleUrl,
  isGithubUrl,
  isLinkedInUrl,
  isValidUrl,
  withHttps,
} from '@src/helpers/urls';
import useLoadingState from '@src/hooks/useLoadingState';
import LabeledCheckboxInput from '@src/components/Inputs/LabeledCheckboxInput';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';

const useStyles = createUseStyles({
  input: {
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  checkbox: {},
  [`@media (min-width: ${Breakpoints.xl}px)`]: {
    input: {
      marginBottom: '2em',
      '& .input-label': {
        fontSize: '0.9em',
        marginBottom: '0.8em',
      },
    },
    checkbox: {
      marginBottom: '2em',
      marginTop: '1em',
    },
  },
});

const PersonalDetails = (): ReactElement => {
  const styles = useStyles();
  const {
    registration: {
      setDribbble,
      setLinkedInOptOut,
      setLinkedIn,
      setGitHub,
      setWebsite,
      formData,
      currentStep,
    },
  } = useStores();

  const [loading, setLoading] = useLoadingState();

  const onSubmit = () => {
    currentStep.onSubmit && setLoading(currentStep.onSubmit());
  };

  const addHttps = (value: string) => {
    return value !== '' ? withHttps(value) : value;
  };

  const isLinkedInValid = useMemo(() => {
    if (!formData.linkedIn?.profileUrl) return undefined;
    return isLinkedInUrl(formData.linkedIn.profileUrl);
  }, [formData.linkedIn?.profileUrl]);

  const isGithubValid = useMemo(() => {
    if (!formData.github?.profileUrl) return undefined;
    return (
      isValidUrl(formData.github.profileUrl) &&
      isGithubUrl(formData.github.profileUrl)
    );
  }, [formData.github?.profileUrl]);

  const isDribbbleValid = useMemo(() => {
    if (!formData.dribbble?.profileURL) return undefined;
    return (
      isValidUrl(formData.dribbble.profileURL) &&
      isDribbbleUrl(formData.dribbble.profileURL)
    );
  }, [formData.dribbble?.profileURL]);

  const isWebsiteValid = useMemo(() => {
    if (
      !formData.websites ||
      !formData.websites.length ||
      formData.websites[0] === ''
    )
      return undefined;
    return isValidUrl(formData.websites[0]);
  }, [formData.websites]);

  return (
    <RegistrationStep
      title={'Where Can We See Some of Your Work?'}
      description={
        'You can just share your LinkedIn profile, but if you have a link that shows off some of your recent work samples, even better.'
      }
      onSubmit={onSubmit}
      canSubmit={loading !== true && currentStep.canSubmit}
      loading={loading}
    >
      <OutlinedInput
        value={formData.linkedIn ? formData.linkedIn.profileUrl : ''}
        label={'LinkedIn'}
        placeholder={'https://linkedin.com/in/...'}
        onChange={(e) => setLinkedIn(e.target.value)}
        disabled={formData.linkedInOptOut}
        valid={isLinkedInValid}
        error={!!formData.linkedIn?.profileUrl && !isLinkedInValid}
        style={{ marginBottom: 16 }}
        className={styles.input}
        errorTooltip={'Oops, you entered an invalid link.'}
      />
      <LabeledCheckboxInput
        label={'I don’t have a LinkedIn profile'}
        checked={formData.linkedInOptOut}
        margin={'none'}
        onChange={(e) => {
          setLinkedInOptOut(e.target.checked);
          setLinkedIn(undefined);
        }}
        className={styles.checkbox}
      />
      <OutlinedInput
        value={formData.github ? formData.github.profileUrl : ''}
        label={'GitHub (Optional)'}
        placeholder={'https://github.com/...'}
        valid={isGithubValid}
        error={!!formData.github?.profileUrl && !isGithubValid}
        onChange={(e) => setGitHub(e.target.value)}
        onBlur={(e) => setGitHub(addHttps(e.target.value))}
        className={styles.input}
        errorTooltip={'Oops, you entered an invalid link.'}
      />
      <OutlinedInput
        value={formData.dribbble ? formData.dribbble.profileURL : ''}
        label={'Dribbble (Optional)'}
        placeholder={'https://dribbble.com/...'}
        valid={isDribbbleValid}
        error={!!formData.dribbble?.profileURL && !isDribbbleValid}
        onBlur={(e) => setDribbble(addHttps(e.target.value))}
        onChange={(e) => setDribbble(e.target.value)}
        className={styles.input}
        errorTooltip={'Oops, you entered an invalid link.'}
      />
      <OutlinedInput
        value={formData.websites ? formData.websites[0] : ''}
        label={'Personal Website (Optional)'}
        placeholder={'https://mywebsite.com...'}
        valid={isWebsiteValid}
        error={!!formData.websites && !!formData.websites[0] && !isWebsiteValid}
        onBlur={(e) => setWebsite(addHttps(e.target.value))}
        onChange={(e) => setWebsite(e.target.value)}
        className={styles.input}
        errorTooltip={'Oops, you entered an invalid link.'}
      />
    </RegistrationStep>
  );
};

export default observer(PersonalDetails);
