import React, { useEffect, useMemo, useState } from 'react';
import { components, OptionProps, SingleValueProps } from 'react-select';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { useStores } from '@src/stores';
import { AsyncSelect, AsyncSelectProps, SelectProps } from '@ateams/components';
import { apiVetter } from '@ateams/api';
import { Vetter, VetterId } from '@a_team/models/dist/vetter';
import UserAvatar from '@src/components/UserAvatar';
import {
  VettingType,
  vettingTypeToStatusMap,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import useLoadingState from '@src/hooks/useLoadingState';

export type OnVetterSelect = (vetter: VetterOptionType | null) => unknown;

export interface VetterSelectorProps
  extends Omit<AsyncSelectProps<false, VetterOptionType>, 'loadOptions'> {
  onVetterSelect?: OnVetterSelect;
  value?: Vetter;
  defaultValue?: VetterId | Vetter;
  vetterOptionsProps?: InterviewerOptionProps;
  vetterSingleValueProps?: InterviewerSingleValueProps;
  'data-testing-id'?: string;
}

export interface VetterOptionType {
  key: string;
  value: string;
  label: string;
  vetter?: Vetter | null;
}

function vetterToOption(vetter: Vetter): VetterOptionType {
  return {
    key: vetter.id,
    value: vetter.id,
    label: vetter.user.fullName,
    vetter,
  };
}

const noOptionsMessage: SelectProps['noOptionsMessage'] = ({ inputValue }) =>
  inputValue ? 'No options' : 'Type to search';

export const VetterSelector: React.FC<VetterSelectorProps> = (props) => {
  const {
    defaultValue,
    onVetterSelect,
    isDisabled,
    value,
    vetterOptionsProps,
    vetterSingleValueProps,
    ...rest
  } = props;
  const { auth } = useStores();
  const [loadingDefault, setLoadingDefault] = useLoadingState();
  const [awaitingParentElementCallback, setAwaitingParentElementCallback] =
    useLoadingState();
  const [vetter, setVetter] = useState(value);

  const setDefault = async () => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        try {
          setLoadingDefault(
            (async () => {
              const vetter = await apiVetter[
                auth.isAdmin ? 'adminGetVetterById' : 'vetterGetVetterById'
              ](auth, defaultValue);

              setVetter(vetter);
            })(),
            false,
          );
        } catch (err) {
          console.error(err, 'Failed to find vetter by ID');
        }
      } else {
        setVetter(defaultValue);
      }
    }
  };

  useEffect(() => {
    setDefault();
  }, [defaultValue]);

  const selectValue = useMemo(() => {
    /**
     * We want to address the parent element OnChange callback so that if in that callback we
     * are changing a value on the backend, and we are loading, and a value is passed via props
     * it wouldn't be locked there while making the backend request (UX-wise we want to show
     * the new option while the select is disabled and loading until the backend request is
     * finished)
     */
    if (awaitingParentElementCallback) {
      return undefined;
    }

    const v = props.value || vetter;

    return v ? vetterToOption(v) : null;
  }, [props.value, vetter]);

  const onVetterChange = async (option: VetterOptionType | null) => {
    const vetter = option?.vetter || undefined;

    setVetter(vetter);
    if (onVetterSelect) {
      setAwaitingParentElementCallback(
        Promise.resolve(onVetterSelect(option)),
        false,
      );
    }
  };

  const loadOptions = async (expression: string) => {
    if (!expression) {
      return [];
    }

    const vetters = await apiVetter[
      auth.isAdmin
        ? 'adminSearchVettersByExpression'
        : 'vetterSearchVettersByExpression'
    ](auth, {
      expression,
    });

    return vetters.items.map(vetterToOption);
  };

  const components = useMemo(() => {
    return {
      Option: (props: React.ComponentProps<typeof InterviewerOption>) => (
        <InterviewerOption {...props} {...vetterOptionsProps} />
      ),
      SingleValue: (
        props: React.ComponentProps<typeof InterviewerSingleValue>,
      ) => (
        <InterviewerSingleValue
          {...props}
          {...vetterSingleValueProps}
          data-testing-id={
            rest['data-testing-id']
              ? `${rest['data-testing-id']}-single-value`
              : undefined
          }
        />
      ),
    };
  }, [vetterOptionsProps, vetterSingleValueProps]);

  return (
    <AsyncSelect
      loadOptions={loadOptions}
      onChange={onVetterChange}
      value={selectValue}
      noOptionsMessage={noOptionsMessage}
      useDebounce
      isClearable
      isDisabled={isDisabled || loadingDefault}
      components={components}
      isLoading={loadingDefault}
      {...rest}
    />
  );
};

const useOptionStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  labelsContainer: {
    marginLeft: '12px',
  },
  fullNameLabel: {
    fontSize: '16px',
  },
  vetterTypeLabel: {
    fontSize: '12px',
  },
});

interface InterviewerOptionProps {
  containerClassName?: string;
  labelsContainerClassName?: string;
  fullNameLabelClassName?: string;
  vetterTypeLabelClassName?: string;
  avatarSize?: number;
}

const InterviewerOption: React.FC<
  OptionProps<VetterOptionType, false> & InterviewerOptionProps
> = (props) => {
  const {
    containerClassName,
    labelsContainerClassName,
    fullNameLabelClassName,
    vetterTypeLabelClassName,
    avatarSize,
  } = props;
  const styles = useOptionStyles();

  return (
    <components.Option {...props}>
      <div className={cx(styles.container, containerClassName)}>
        <UserAvatar
          src={props.data.vetter.user.profilePictureURL}
          size={avatarSize}
        />
        <div className={cx(styles.labelsContainer, labelsContainerClassName)}>
          <div className={cx(styles.fullNameLabel, fullNameLabelClassName)}>
            {props.data.vetter.user.fullName}
          </div>
          <div className={cx(styles.vetterTypeLabel, vetterTypeLabelClassName)}>
            {vettingTypeToStatusMap[props.data.vetter.type as VettingType]}
          </div>
        </div>
      </div>
    </components.Option>
  );
};

interface InterviewerSingleValueProps {
  containerClassName?: string;
  labelsContainerClassName?: string;
  fullNameLabelClassName?: string;
  avatarSize?: number;
  'data-testing-id'?: string;
}

const InterviewerSingleValue: React.FC<
  SingleValueProps<VetterOptionType> & InterviewerSingleValueProps
> = (props) => {
  const {
    containerClassName,
    labelsContainerClassName,
    fullNameLabelClassName,
    avatarSize = 28,
    'data-testing-id': dataTestingId,
  } = props;
  const styles = useOptionStyles();

  return (
    <components.SingleValue {...props}>
      {props.data.vetter && (
        <div
          className={cx(styles.container, containerClassName)}
          data-testing-id={dataTestingId}
        >
          <UserAvatar
            src={props.data.vetter?.user.profilePictureURL}
            size={avatarSize}
          />
          <div className={cx(styles.labelsContainer, labelsContainerClassName)}>
            <div className={cx(styles.fullNameLabel, fullNameLabelClassName)}>
              {props.data.vetter?.user.fullName}
            </div>
          </div>
        </div>
      )}
    </components.SingleValue>
  );
};
