import {
  BuilderSuggestionType,
  SuggestionActionType,
} from '@a_team/models/dist/ProfileEnhancements';
import { UserId } from '@a_team/models/dist/UserObject';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import {
  useApplyProfileSuggestion,
  useGetAdminProfileSuggestions,
  useGetProfileSuggestions,
  useRejectProfileSuggestion,
} from '@src/rq/profileSuggestions';
import { ProfileViewMode } from '@src/stores/Profile/models';
import Profile from '@src/stores/Profile/Profile';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Suggestions from '../Suggestions';

const useStyles = createUseStyles({
  container: {
    marginBottom: 24,
  },
});

interface SuggestedWebsiteProps {
  profile: Profile;
  userId: UserId;
  adminOnly?: boolean;
}
const SuggestedWebsite = ({
  profile,
  userId,
  adminOnly,
}: SuggestedWebsiteProps) => {
  const [websiteModalOpen, setWebsiteModalOpen] = useState(false);
  const { data } = useGetProfileSuggestions();
  const { data: adminData } = useGetAdminProfileSuggestions();
  const { mutateAsync: applySuggestedWebsite } = useApplyProfileSuggestion({
    shouldRefreshProfile: true,
  });
  const { mutateAsync: rejectSuggestion } = useRejectProfileSuggestion();
  const analytics = useAnalytics();
  const styles = useStyles();

  const toggleSkillsModalOpen = () => {
    setWebsiteModalOpen(!websiteModalOpen);
  };

  const rawSuggestions = adminOnly ? adminData : data;

  const website = rawSuggestions?.website;

  const websites = website?.website
    ? [
        {
          suggestionId: rawSuggestions?.website?.sid,
          partialSuggestionId: rawSuggestions?.website?.sid,
          label: website.website,
          type: BuilderSuggestionType.WEBSITE,
        },
      ]
    : [];

  const onDiscard = async () => {
    try {
      if (rawSuggestions?.website) {
        await rejectSuggestion(rawSuggestions.website.website);
        analytics.trackProfileSuggestionInteraction(
          userId,
          rawSuggestions.website.sid,
          SuggestionActionType.REJECT,
          BuilderSuggestionType.WEBSITE,
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAccept = async () => {
    try {
      if (rawSuggestions?.website?.sid) {
        const website = rawSuggestions.website.website;

        if (!website) {
          return;
        }

        await applySuggestedWebsite({
          sid: rawSuggestions.website.sid,
        });

        analytics.trackProfileSuggestionInteraction(
          userId,
          rawSuggestions.website.sid,
          SuggestionActionType.ACCEPT,
          BuilderSuggestionType.WEBSITE,
          [website],
        );
        profile.refreshProfile(ProfileViewMode.View);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.container}>
      <Suggestions
        onDiscard={onDiscard}
        onAccept={handleAccept}
        title={'Suggested website'}
        onClick={toggleSkillsModalOpen}
        userId={userId}
        suggestions={websites ?? []}
        adminOnly={adminOnly}
      />
    </div>
  );
};

export default SuggestedWebsite;
