import {
  TimesheetInitiativeId,
  TimesheetInitiativeObject,
} from '@a_team/models/dist/TimesheetInitiativeObject';
import { Icon } from '@a_team/ui-components';
import {
  useCreateTimesheetInitiativeForMission,
  useHideTimesheetInitiativeForMission,
  useQueryGetTimesheetInitiativesForMission,
} from '@src/rq/timesheets';
import React, { useEffect, useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import CustomMenuList from './CustomMenuList';
import SelectAndHideOption from './SelectAndHideOption';
import { mergeAndDedupe, toInitiativeOptionType } from './utils';
import { UserId } from '@a_team/models/dist/UserObject';

interface InitiativeSelectProps {
  sid: string;
  cellValue?: TimesheetInitiativeId[];
  onChange: (value: TimesheetInitiativeId[]) => void;
  onUpdateMutation?: (initiativeIds: TimesheetInitiativeId[]) => void;
}

export type InitiativeOptionType = {
  label: string;
  value: TimesheetInitiativeId;
  color: string;
  background: string;
  isHidden: boolean;
  createdBy: UserId;
};

const InitiativeSelect = ({
  sid,
  cellValue,
  onChange,
  onUpdateMutation,
}: InitiativeSelectProps): JSX.Element | null => {
  const [value, setValue] = useState<InitiativeOptionType[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const { data } = useQueryGetTimesheetInitiativesForMission({
    sid,
    enabled: !!sid,
    onSuccess: (timesheetInitiatives: TimesheetInitiativeObject[]) => {
      const newValues: InitiativeOptionType[] = timesheetInitiatives
        .filter((timesheetInitiative) =>
          cellValue?.includes(timesheetInitiative.siid),
        )
        .map(toInitiativeOptionType);

      setValue((currentValues) => mergeAndDedupe(currentValues, newValues));
    },
  });

  const { mutate: createTimesheetInitiativeForMission } =
    useCreateTimesheetInitiativeForMission({
      sid,
      onSuccess: (timesheetInitiative: TimesheetInitiativeObject) => {
        const newValues = mergeAndDedupe(value, [
          toInitiativeOptionType(timesheetInitiative),
        ]);

        setValue(newValues);
        onChange(newValues.map((value) => value.value));
        onUpdateMutation &&
          onUpdateMutation(newValues.map((value) => value.value));
      },
    });

  const { mutate: hideTimesheetInitiativeForMission } =
    useHideTimesheetInitiativeForMission({
      sid,
    });

  const onCreateTimesheet = async (name: string) => {
    setInputValue('');
    await createTimesheetInitiativeForMission({
      sid,
      name,
    });
  };

  const onHideTimesheet = async (siid: TimesheetInitiativeId) => {
    await hideTimesheetInitiativeForMission({ sid, siid });
  };

  const options: InitiativeOptionType[] = useMemo(() => {
    let options = data?.map(toInitiativeOptionType) ?? [];

    options = options.filter((option) => {
      const isHidden = option.isHidden;
      const isMatch =
        inputValue &&
        option.label
          .toLowerCase()
          .trim()
          .includes(inputValue.toLowerCase().trim());

      const optionSelected = value.find(
        (v) =>
          option.label.toLowerCase().trim() === v.label.toLowerCase().trim(),
      );

      return isMatch || optionSelected || !isHidden;
    });

    const optionFound =
      inputValue &&
      options.some(
        (o) => o.label.toLowerCase().trim() === inputValue.toLowerCase().trim(),
      );

    if (inputValue && !optionFound) {
      options.push({
        label: inputValue,
        value: '-1',
        color: '',
        background: '',
        isHidden: false,
        createdBy: '',
      });
    }

    return options;
  }, [data, inputValue]);

  useEffect(() => {
    const selectedOptions = options.filter((option) =>
      cellValue?.includes(option.value),
    );
    setValue(selectedOptions);
  }, [cellValue]);

  return (
    <div data-testing-id="timesheets-initiatives-select-wrapper">
      <Select
        inputValue={inputValue}
        onInputChange={(newValue) => setInputValue(newValue)}
        value={value}
        components={{
          MenuList: (props) => (
            <CustomMenuList inputValue={inputValue} value={value} {...props} />
          ),
          Option: ({ innerProps, innerRef, isFocused, label }) => {
            const option = options.find((o) => o.label === label);

            return (
              <SelectAndHideOption
                siid={option?.value || ''}
                value={option?.value || ''}
                isFocused={isFocused}
                innerProps={innerProps}
                innerRef={innerRef}
                bgColor={option?.background}
                color={option?.color}
                onHideTimesheet={onHideTimesheet}
                isHidden={option?.isHidden}
                inputValue={inputValue}
              >
                <div>{label}</div>
              </SelectAndHideOption>
            );
          },
          SingleValue: ({ children, ...props }) => {
            return (
              <components.SingleValue {...props}>
                {children}
              </components.SingleValue>
            );
          },
          MultiValue: (props) => {
            const option = options.find((o) => o.value === props.data.value);
            return (
              <components.MultiValue {...props}>
                <span
                  style={{
                    background: option?.background,
                    color: option?.color,
                    fontSize: 12,
                  }}
                >
                  {props.children}
                </span>
              </components.MultiValue>
            );
          },
          MultiValueRemove: (props) => {
            const option = options.find((o) => o.value === props.data.value);

            // Determine the color based on the option's properties
            const iconColor =
              option?.color === 'white' ? 'Green@100' : 'Green@1000';

            return (
              <components.MultiValueRemove {...props}>
                <Icon color={iconColor} name="remove" size="sm" />
              </components.MultiValueRemove>
            );
          },
          GroupHeading: ({ children, ...props }) => {
            if (!children) return null;
            return (
              <components.GroupHeading {...props}>
                {children}
              </components.GroupHeading>
            );
          },
          NoOptionsMessage: () => <div></div>,
        }}
        styles={{
          input: (base) => ({
            ...base,
            margin: 0,
            position: 'relative',
            zIndex: 1,
            background: 'transparent',
            border: 'none',
          }),
          control: (base, state) => ({
            ...base,
            boxShadow: 'none',
            background: 'transparent',
            // if it's not focused, display none
            border: 'none',
            outline: state.isFocused
              ? '2px solid #6D00D7'
              : '2px solid transparent',
            borderRadius: 8,
            padding: '0 14px',
            height: '52px',
            margin: 0,
            '&:hover': {
              border: 'none',
              outline: state.isFocused
                ? '2px solid #6D00D7'
                : '2px solid transparent',
            },
          }),
          groupHeading: (base) => ({
            ...base,
            fontSize: 12,
            fontWeight: 500,
            color: '#62646A',
            padding: '7px 10px',
            borderTop: '1px solid #DADADC',
            textTransform: 'initial',
          }),

          menuList: (base) => ({
            ...base,
            padding: 0,
            maxHeight: 410,
            overflow: 'auto',
          }),

          valueContainer: (base) => ({
            ...base,
            padding: 0,
            border: 'none',
            background: 'transparent',
          }),
          placeholder: (base) => ({
            ...base,
            width: 'fit-content',
            background: '#F7F7F7',
            padding: '5px 10px',
            borderRadius: '8px',
            color: '#818388',
            fontSize: 12,
            fontWeight: 500,
          }),
          multiValueRemove: (base) => ({
            ...base,
            display: 'flex',
            '& span': {
              display: 'flex',
            },
            '&:hover': {
              background: 'transparent',
            },
          }),
          multiValue: (base, state) => {
            const option = options.find((o) => o.value === state.data.value);
            return {
              ...base,
              background: option?.background,
              color: option?.color,
              borderRadius: '8px',
            };
          },
          multiValueLabel: (base) => ({
            ...base,
            paddingRight: 0,
          }),
          menu: (base) => ({
            ...base,
            borderRadius: '8px',
            border: 'none',
            boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.10)',
            zIndex: 3,
          }),
          indicatorsContainer: (base) => ({
            ...base,
            display: 'none',
          }),
        }}
        isClearable
        isMulti
        placeholder="Enter Tag"
        onChange={(newValue) => {
          const newValues = newValue as InitiativeOptionType[];
          const createOption = newValues.find((nv) => nv.value === '-1');
          if (createOption) {
            onCreateTimesheet(createOption.label);
          } else {
            setValue(newValues);
            onChange(newValues.map((value) => value.value));
            onUpdateMutation &&
              onUpdateMutation(newValues.map((value) => value.value));
          }
        }}
        options={options}
        tabSelectsValue={false}
        menuIsOpen={menuIsOpen}
        onFocus={() => setMenuIsOpen(true)}
        onBlur={() => setMenuIsOpen(false)}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
      />
    </div>
  );
};

export default InitiativeSelect;
