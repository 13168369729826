import React, { ReactElement, CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

const Margins = {
  none: '0',
  default: '56px 0',
  compact: '24px 0 32px',
  small: '24px 0',
};

export interface DividerProps {
  margin?: keyof typeof Margins;
  style?: CSSProperties;
  color?: string;
  className?: string;
}

const useStyles = createUseStyles({
  root: (props: DividerProps) => ({
    margin: Margins[props.margin || 'default'],
    color: props.color || '#c0c0c0',
    backgroundColor: props.color || '#c0c0c0',
    border: 0,
    height: '1px',
    boxShadow: 0,
  }),
});

export default function Divider(props: DividerProps): ReactElement {
  const { style, className } = props;
  const styles = useStyles(props);

  return <hr className={cx(styles.root, className)} style={{ ...style }} />;
}
