import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useFormContext } from 'react-hook-form';
import { BuilderSectionHorizontalLinks } from './BuilderSectionHorizontalLinks';
import { BuilderSectionVerticalLinks } from './BuilderSectionVerticalLinks';
import FileUploadSection from './FileUploadSection';
import {
  BorderColors,
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from '@ateams/components';
import { BuilderEvaluationInfo } from './BuilderEvaluationInfo';
import PortfolioObject from '@a_team/models/dist/PortfolioObject';
import {
  VettingFeedbackFormAdditionalMaterial,
  VettingFeedbackFormEvaluationCall,
} from '@ateams/api/dist/endpoints/vetting-process-feedback-v2';
import {
  TranscriptLocation,
  VettingDashboardRecordingLocation,
} from '@src/locations';
import { VettingType } from '@a_team/models/dist/vetting-processes/vetting-processes';
import UserAvatar from '@src/components/UserAvatar';
import { Link } from 'react-router-dom';
import { FieldReadOnly } from '../general/FieldReadOnly';
import { format } from 'date-fns';

const useStyles = createUseStyles({
  builderSection: {
    padding: Spacing.large,
    border: 'none',
    borderRadius: BorderRadius.xLarge,
    background: `linear-gradient(${Colors.backgroundLight}, ${Colors.backgroundWhite})`,
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  builderSectionHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.medium,
  },
  builderSectionHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.xsmall,
    width: '100%',
  },
  builderSectionAvatar: {
    borderRadius: BorderRadius.xLarge,
    flexShrink: 0,
  },
  builderSectionFullName: {
    fontSize: FontSizes.large,
    fontWeight: FontWeights.semiBold,
    color: Colors.secondaryDark,
    lineHeight: '26px',
    textAlign: 'left',
  },
  builderSectionEmailAndLocation: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
    alignItems: 'center',
    fontSize: FontSizes.xsmall,
    color: Colors.dark,
  },
  builderSectionSubtitleItem: {
    fontSize: FontSizes.small,
    color: Colors.dark,
  },
  builderSectionLinksAndNotesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    borderBottom: `1px solid ${BorderColors.lighter}`,
    paddingBottom: Spacing.medium,
  },
  builderSectionVerticalLinksContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: Spacing.medium,
    paddingBottom: Spacing.medium,
  },
});

export interface BuilderData {
  name: string;
  email: string;
  location: string;
  avatarUrl: string;
  profileUrl: string;
  linkedinUrl: string;
  resumeUrl: string;
  portfolio?: PortfolioObject;
  evaluationCalls: VettingFeedbackFormEvaluationCall[];
  missionsBuilderAppliedTo: {
    label: string;
    url: string;
  }[];
  uploadedMaterials: {
    label: string;
    url: string;
  }[];
  additionalMaterialDetails: string;
  evaluationType: VettingType;
  talentCategory: {
    tid: string;
    name: string;
  };
  admin: {
    uid: string;
    name: string;
  };
  interviewer: {
    uid: string;
    name: string;
  };
}

export interface BuilderSectionProps {
  builderData: BuilderData;
  isViewMode: boolean;
}

export const BuilderSection = ({
  builderData,
  isViewMode,
}: BuilderSectionProps) => {
  const styles = useStyles();
  const { watch, setValue } = useFormContext();
  const additionalMaterialsFiles = watch('additionalMaterialsFiles');

  const handleSetAdditionalMaterialsFiles: React.Dispatch<
    React.SetStateAction<VettingFeedbackFormAdditionalMaterial[]>
  > = (files) => {
    if (typeof files === 'function') {
      setValue('additionalMaterialsFiles', files(additionalMaterialsFiles));
    } else {
      setValue('additionalMaterialsFiles', files);
    }
  };

  const evaluationCallLinks = useMemo(() => {
    const links: { label: string; url: string }[] = [];
    builderData.evaluationCalls.forEach((call) => {
      links.push({
        label: `Call video (${format(
          new Date(call.interviewDate),
          'MMM dd, yyyy',
        )})`,
        url: `${
          window.location.origin
        }${VettingDashboardRecordingLocation}?recordingUrl=${encodeURIComponent(
          call.recordingUrl ?? '',
        )}`,
      });
      links.push({
        label: `Call transcript (${format(
          new Date(call.interviewDate),
          'MMM dd, yyyy',
        )})`,
        url: `${window.location.origin}${TranscriptLocation(call.jobId)}`,
      });
    });
    return links;
  }, [builderData.evaluationCalls]);

  const evaluationTypeName = useMemo(() => {
    switch (builderData.evaluationType) {
      case VettingType.SelectionTeam:
        return 'Selection team';
      case VettingType.InternalNonTechnical:
        return 'Internal non-technical';
      case VettingType.InternalTechnical:
        return 'Internal technical';
    }
  }, [builderData.evaluationType]);

  return (
    <div className={styles.builderSection}>
      <div className={styles.builderSectionHeaderContainer}>
        <Link to={{ pathname: builderData.profileUrl }} target="_blank">
          <UserAvatar
            src={builderData.avatarUrl}
            size={72}
            imageClassName={styles.builderSectionAvatar}
          />
        </Link>
        <div className={styles.builderSectionHeader}>
          <Link
            to={{ pathname: builderData.profileUrl }}
            target="_blank"
            className={styles.builderSectionFullName}
          >
            {builderData.name}
          </Link>
          <div className={styles.builderSectionEmailAndLocation}>
            <div className={styles.builderSectionSubtitleItem}>
              {builderData.email}
            </div>
            <div className={styles.builderSectionSubtitleItem}>·</div>
            <div className={styles.builderSectionSubtitleItem}>
              {builderData.location}
            </div>
          </div>
          <BuilderSectionHorizontalLinks
            linkedinUrl={builderData.linkedinUrl}
            resumeUrl={builderData.resumeUrl}
            portfolio={builderData.portfolio}
          />
        </div>
      </div>
      <div className={styles.builderSectionLinksAndNotesContainer}>
        <div className={styles.builderSectionVerticalLinksContainer}>
          <BuilderSectionVerticalLinks
            title="Evaluation call links"
            maxRows={2}
            links={evaluationCallLinks}
          />
          <BuilderSectionVerticalLinks
            title="Missions builder applied to"
            maxRows={2}
            links={builderData.missionsBuilderAppliedTo}
          />
          <BuilderSectionVerticalLinks
            title="Builder uploaded materials"
            maxRows={2}
            links={builderData.uploadedMaterials}
          />
        </div>
        <FieldReadOnly
          label="Builder uploaded material notes"
          value={builderData.additionalMaterialDetails}
          expandable
        />
      </div>
      <FileUploadSection
        additionalMaterialsFiles={additionalMaterialsFiles}
        setAdditionalMaterialsFiles={handleSetAdditionalMaterialsFiles}
        isViewMode={isViewMode}
      />
      <BuilderEvaluationInfo
        evaluationType={evaluationTypeName}
        roleCategory={builderData.talentCategory.name}
        admin={builderData.admin.name}
        interviewer={builderData.interviewer.name}
      />
    </div>
  );
};
