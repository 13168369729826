import React from 'react';
import { observer } from 'mobx-react';
import {
  AdminInvoicesUnpaidInvoiceListLocationTemplate,
  AdminInvoicesToBeIssuedLocationTemplate,
  RootLocation,
  AdminInvoicesPrepayPaymentsLocation,
  AdminInvoicesDiscountsLocation,
} from '@src/locations';
import MainLayout, { MainLayoutProps } from '@src/layouts/Main';
import { Redirect } from 'react-router-dom';
import SectionHeading from '@src/components/SectionHeading';
import TabButtons, { TabNavLink } from '@src/components/TabButtons';
import { useStores } from '@src/stores';

const AdminInvoicesDashboardLayout = (props: MainLayoutProps) => {
  const { auth } = useStores();

  const tabs = [
    {
      label: 'Invoices to be issued',
      to: AdminInvoicesToBeIssuedLocationTemplate,
    },
    {
      label: 'Unpaid invoice statuses',
      to: AdminInvoicesUnpaidInvoiceListLocationTemplate,
    },
    {
      label: 'Prepay Payments',
      to: AdminInvoicesPrepayPaymentsLocation,
    },
    {
      label: 'Discounts',
      to: AdminInvoicesDiscountsLocation,
    },
    // {
    //   label: 'Client invoices',
    //   to: AdminInvoicesClientInvoiceListLocationTemplate,
    // },
  ];

  if (!auth.isAdmin) {
    return <Redirect to={RootLocation} />;
  }

  return (
    <MainLayout
      {...props}
      title={props.title || 'Invoices dashboard'}
      topNav={
        <div
          style={{
            padding: '40px 56px 0',
            boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <SectionHeading isFirst>Admin Invoices Dashboard</SectionHeading>

          <TabButtons style={{ boxShadow: 'none', marginLeft: -32 }}>
            {tabs.map((tab, i) => (
              <TabNavLink key={i} to={tab.to}>
                <>{tab.label}</>
              </TabNavLink>
            ))}
          </TabButtons>
        </div>
      }
    />
  );
};

export default observer(AdminInvoicesDashboardLayout);
