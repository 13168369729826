import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { components, MenuListComponentProps } from 'react-select';
import { InitiativeOptionType } from '.';
import { BorderColors, Colors, TextColors } from '@ateams/components';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import Badge from '../Badge';
import InitiativesGuidance from '../common/InitiativesGuidance';

const useStyles = createUseStyles({
  selectOptionsSubHeader: {
    padding: 16,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
    background: Colors.backgroundLight,
    borderTop: `1px solid ${BorderColors.lighter}`,
    borderBottom: `1px solid ${BorderColors.lighter}`,
  },
  demoOption: {
    padding: 8,
  },
  muted: {
    color: TextColors.lighter,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
    marginRight: 8,
  },
});

interface CustomMenuListProps
  extends MenuListComponentProps<InitiativeOptionType, true> {
  inputValue: string;
  value: InitiativeOptionType[];
}

const CustomMenuList = ({
  inputValue,
  value,
  options,
  ...left
}: CustomMenuListProps) => {
  const styles = useStyles();
  const { auth } = useStores();

  const showInitiativesGuidance = useMemo(() => {
    if (!auth.user?.needsTimesheetInitiativesGuidance) {
      return false;
    }

    const hasUserCreatedAnyInitiatives = options.some(
      (option) => option.createdBy === auth.user?.uid,
    );

    return !hasUserCreatedAnyInitiatives && !inputValue && value.length === 0;
  }, [
    auth.user?.needsTimesheetInitiativesGuidance,
    options,
    inputValue,
    value,
  ]);

  const dismissTimesheetInitiativesGuidance = async () => {
    await auth.dismissTimesheetInitiativesGuidance(auth);
  };

  return (
    <components.MenuList options={options} {...left}>
      <div>
        {showInitiativesGuidance && (
          <InitiativesGuidance
            showDismissOption
            dismissTimesheetInitiativesGuidance={
              dismissTimesheetInitiativesGuidance
            }
          />
        )}
        <div className={styles.selectOptionsSubHeader}>
          Initiatives are shared across mission team members.
        </div>
        {showInitiativesGuidance && (
          <div>
            <div className={styles.demoOption}>
              <span className={styles.muted}>Example</span>
              <Badge bgColor="#EDE0FF" color="#222222">
                Increasing user adoption
              </Badge>
            </div>
            <div className={styles.demoOption}>
              <span className={styles.muted}>Example</span>
              <Badge bgColor="#FFE9D4" color="#222222">
                Decreasing platform load time
              </Badge>
            </div>
            <div className={styles.demoOption}>
              <span className={styles.muted}>Example</span>
              <Badge bgColor="#FFE5F6" color="#222222">
                New chat feature
              </Badge>
            </div>
          </div>
        )}
      </div>
      {left.children}
    </components.MenuList>
  );
};

export default observer(CustomMenuList);
