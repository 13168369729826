import React, { ReactElement, useMemo } from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import { Icon, IconType, Button, TextColors } from '@ateams/components';
import { numberWithCommas } from '@src/helpers/numbers';
import { useStyles } from '.';
import { createUseStyles } from 'react-jss';
import { LoadingState } from '@src/hooks/useLoadingState';

export interface SendTimesheetProps {
  totalAmount: number | undefined;
  loading?: LoadingState;
  startDate: Date;
  endDate: Date;
  isEmptyTimesheet: boolean;
  onSubmit: (isInactive: boolean) => void;
}

const useSendTimesheetStyles = createUseStyles({
  title: {
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: 600,
    letterSpacing: '0.002em',
    color: TextColors.regular,
    marginBottom: '8px',
  },
  buttonsContainer: {
    fontSize: '15px',
    fontWeight: 'normal',
  },
  button: {
    padding: '11px 32px',
  },
  notActiveButton: {
    background: 'transparent',
    color: TextColors.primaryLight,
    padding: 0,
  },
  description: {
    fontSize: '15px',
  },
});

export function SendTimesheet(props: SendTimesheetProps): ReactElement {
  const commonStyles = useStyles();
  const styles = useSendTimesheetStyles();
  const { totalAmount, startDate, endDate, isEmptyTimesheet, onSubmit } = props;

  const formattedStartDate = useMemo(
    () => format(startDate, 'LLL d'),
    [startDate],
  );

  const formattedEndDate = useMemo(() => format(endDate, 'LLL d'), [endDate]);

  return (
    <div className={commonStyles.wrapper}>
      <div className={commonStyles.leftSide}>
        <Icon type={IconType.Money} className={commonStyles.moneyIcon} />
      </div>
      <div className={commonStyles.rightSide}>
        <div className={styles.title}>
          {isEmptyTimesheet
            ? 'Are you no longer active on this mission?'
            : 'It’s Time to Get Paid!'}
        </div>
        <div className={styles.description}>
          {isEmptyTimesheet
            ? 'This will stop timesheet submission requests. You will retain access to prior timesheets and documents.'
            : `Are you ready to submit your timesheet, ${
                totalAmount
                  ? 'and get paid $' + numberWithCommas(totalAmount)
                  : ''
              } for the period (${formattedStartDate}-${formattedEndDate})`}
        </div>

        <div
          className={cx([
            commonStyles.button,
            styles.buttonsContainer,
            { [commonStyles.buttons]: isEmptyTimesheet },
          ])}
        >
          {isEmptyTimesheet && (
            <Button
              disabled={props.loading === true}
              onClick={() => onSubmit(true)}
              size="small"
              width="auto"
              className={cx([styles.notActiveButton])}
            >
              Submit, Not Active
            </Button>
          )}

          <Button
            disabled={props.loading === true}
            onClick={() => onSubmit(false)}
            size="small"
            width="auto"
            className={styles.button}
          >
            {isEmptyTimesheet ? 'Submit, Still Active' : 'Submit Now'}
          </Button>
        </div>
      </div>
    </div>
  );
}
