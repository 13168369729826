import { ServiceAuth, ServiceEndpoint } from './utils';
import {
  ClientSignalObject,
  ClientSignalId,
} from '@a_team/models/src/ClientSignals';
import { MissionCardObject } from '@a_team/models/dist/MissionObject';
import { AccountId } from '@a_team/models/src/Account';

export const BasePath = '/client-signals';

export default class ClientSignalsEndpoint extends ServiceEndpoint {
  public getClientSignalsByAccountId(
    auth: ServiceAuth,
    id: AccountId,
  ): Promise<ClientSignalObject[]> {
    return this.fetch(auth, `${BasePath}/account/${id}`);
  }
  public getClientSignal(
    auth: ServiceAuth,
    id: ClientSignalId,
  ): Promise<ClientSignalObject | null> {
    return this.fetch(auth, `${BasePath}/${id}`);
  }

  public getClientSignalsByIds(
    auth: ServiceAuth,
    signalIds: ClientSignalId[],
  ): Promise<ClientSignalObject[]> {
    return this.fetch(auth, `${BasePath}/ids`, null, 'post', {
      signalIds,
    });
  }

  public getClientSignalMissions(
    auth: ServiceAuth,
  ): Promise<MissionCardObject[]> {
    return this.fetch(auth, `${BasePath}/missions/recommended`);
  }
}
