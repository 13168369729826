import isEqual from 'lodash/isEqual';

export function propComparison<T>(
  ...origKeys: (keyof T)[]
): (prevProps: T, nextProps: T) => boolean {
  const orig = {} as Record<keyof T, 1>;
  origKeys.forEach((key) => {
    orig[key] = 1;
  });

  return (prevProps: T, nextProps: T): boolean => {
    // TODO: remove this hack
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const keys = Object.keys({ ...prevProps, ...nextProps }) as (keyof T)[];
    return keys.every((key) => prevProps[key] === nextProps[key] || orig[key]);
  };
}

/***
 * Deep-compares props for when more complex data structures are used.
 * To be used with React.memo()
 * @param prevProps
 * @param nextProps
 */
export const isDeepEqual = <T>(prevProps: T, nextProps: T): boolean => {
  const keys = Object.keys({ ...prevProps, ...nextProps }) as (keyof T)[];
  return keys.every((key) => isEqual(prevProps[key], nextProps[key]));
};
