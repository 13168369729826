import React, { ReactElement } from 'react';
import { AsyncSelect, InputLabel, SelectOption } from '@ateams/components';
import LabeledCheckboxInput from '@src/components/Inputs/LabeledCheckboxInput';
import { createUseStyles } from 'react-jss';
import { LocalTeamMember } from '@src/stores/Registration/models';

interface Props {
  member: LocalTeamMember;
  inProject?: boolean;
  onRoleChange: (role: SelectOption | null) => void;
  onInProjectChange: (checked: boolean) => void;
  onQuerySpecializations: (query: string) => Promise<SelectOption[]>;
}

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
  },
  checkbox: {
    flexShrink: 0,
    marginTop: 0,
    marginLeft: 16,
  },
  select: {
    marginTop: 0,
    marginBottom: 0,
  },
});

export const ProjectMember = (props: Props): ReactElement => {
  const styles = useStyles();
  const {
    member,
    onRoleChange,
    onInProjectChange,
    onQuerySpecializations,
    inProject,
  } = props;

  return (
    <>
      <InputLabel required>How Did {member.fullName} Contribute?</InputLabel>
      <div className={styles.row}>
        <AsyncSelect
          placeholder={`Select ${member.fullName}'s role...`}
          defaultInputValue={member.role?.name}
          loadOptions={onQuerySpecializations}
          hideSelectedOptions={false}
          onChange={onRoleChange}
          required
          isDisabled={!inProject}
          className={styles.select}
          style={{ marginTop: 0, marginRight: 16 }}
        />
        <LabeledCheckboxInput
          label={'Not in this project'}
          onChange={(e) => onInProjectChange(!e.target.checked)}
          className={styles.checkbox}
          margin={'none'}
          checked={!inProject}
        />
      </div>
    </>
  );
};
