import { TimesheetInitiativeObject } from '@a_team/models/dist/TimesheetInitiativeObject';
import { InitiativeOptionType } from '.';
import { outOfOfficeTypes } from '../data';
import { Table } from '@tanstack/react-table';
import { TimeEntry } from '../TanstackTable';

export const toInitiativeOptionType = (
  timesheetInitiative: TimesheetInitiativeObject,
): InitiativeOptionType => {
  return {
    label: timesheetInitiative.name,
    value: timesheetInitiative.siid,
    color: 'black',
    background: timesheetInitiative.backgroundColor,
    isHidden: timesheetInitiative.isHidden,
    createdBy: timesheetInitiative.createdBy,
  };
};

export const mergeAndDedupe = (
  currentValues: InitiativeOptionType[],
  newValues: InitiativeOptionType[],
) => {
  const filteredNewValues = newValues.filter(
    (newValue) =>
      !currentValues.find(
        (currentValue) => currentValue.value === newValue.value,
      ),
  );

  return [...currentValues, ...filteredNewValues];
};

export const checkIfRowIsOOO = (table: Table<TimeEntry>, rowKey: string) => {
  const rowType = table
    .getRowModel()
    .rows.find((row) => row.original.key === rowKey)?.original.type;

  return rowType && outOfOfficeTypes.has(rowType);
};
