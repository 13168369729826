import React, { useState } from 'react';
import { useCommonStyles } from '../commonStyles';
import { Button } from '@a_team/ui-components';
import { ConnectCalendarButton } from '../ConnectCalendarButton';
import { CDN_BASE_URL } from '@src/config';
import { CalendarHelp } from './CalendarHelp';

interface TeaserProps {
  onSupport: () => void;
  onContinue: () => void;
  onClose: () => void;
}

export const Teaser = (props: TeaserProps) => {
  const styles = useCommonStyles();
  const [showHelp, setShowHelp] = useState(false);

  if (showHelp) {
    return (
      <CalendarHelp
        onClose={() => setShowHelp(false)}
        onSupport={props.onSupport}
      />
    );
  }

  return (
    <>
      <div className={styles.hero}>
        <h4 className={styles.modalTitle}>
          Connect your calendar to enable direct booking
        </h4>
        <p className={styles.heroCopy}>
          After you connect your calendar, companies can discover your profile
          and schedule interviews with you directly.
        </p>
      </div>
      <img
        src={`${CDN_BASE_URL}/website/calcom-banner-01.jpg`}
        alt="A.Team calendar banner"
        className={styles.img}
      />
      <div className={styles.teaserBody}>
        <div className={styles.panel}>
          <h5 className={styles.panelTitle}>
            Your events are private and secure
          </h5>
          <p className={styles.panelCopy}>
            Companies can only view your availability for the next 7 days during
            your designated working hours, not your specific events.
          </p>
        </div>
        <div className={styles.panel}>
          <h5 className={styles.panelTitle}>
            Expedited feedback and mission selection
          </h5>
          <p className={styles.panelCopy}>
            Connecting your calendar enables A.Team to know when interviews
            occur, allowing us to promptly follow up with clients for quick
            feedback and mission selection.
          </p>
        </div>
        <div className={styles.panel}>
          <h5 className={styles.panelTitle}>
            Get featured in company discovery
          </h5>
          <p className={styles.panelCopy}>
            When your calendar’s connected, companies can find and schedule
            interviews with you directly.
            <br />
            <a
              className={styles.linkButton}
              href="https://guide.a.team/getting-started/getting-discovered-selected-for-interviews"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </a>
          </p>
        </div>
        <div className={styles.actions} style={{ paddingTop: 32 }}>
          <Button
            className={styles.helpBtn}
            variant="secondary"
            size="sm"
            onClick={() => setShowHelp(true)}
          >
            Get help connecting
          </Button>
          <ConnectCalendarButton topAligned />
        </div>
        <div className={styles.passive}>
          <button
            className={styles.passiveLinkButton}
            onClick={props.onContinue}
          >
            I don't want to connect
          </button>
        </div>
      </div>
    </>
  );
};
