import React, { ReactElement, useEffect, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import TextButton from '@src/components/TextButton';
import useToggle from '@src/hooks/useToggle';
import { MissionManager } from '@a_team/models/dist/MissionObject';
import MissionManagerInput from '@src/views/Mission/EditMission/MissionManagersSelector/MissionManagerInput';

interface Props {
  managers?: MissionManager[];
  onChange(managers: MissionManager[], mainManagerUsername?: string): void;
  mainManagerUsername?: string;
}

const useStyles = createUseStyles({
  root: {},
});

const MissionManagersSelector = (props: Props): ReactElement => {
  const { managers, onChange, mainManagerUsername } = props;

  const styles = useStyles();
  const [draft, toggleDraft] = useToggle(!managers?.length);

  const singleManager = useMemo(() => {
    return (
      managers &&
      managers.length === 1 &&
      mainManagerUsername !== managers[0].username
    );
  }, [managers, mainManagerUsername]);

  useEffect((): void => {
    toggleDraft(!managers?.length);
  }, [managers]);

  useEffect(() => {
    if (
      managers &&
      managers.length > 0 &&
      (singleManager || !mainManagerUsername)
    ) {
      onMainManagerChange(managers[0].username);
    }
  }, [singleManager, managers, mainManagerUsername]);

  const handleRoleChange = (manager: MissionManager, index: number): void => {
    const newRoles = managers ? [...managers] : [];
    newRoles[index] = manager;
    onChange(newRoles, mainManagerUsername);
  };

  const handleRoleRemove = (index: number): void => {
    const newRoles = managers ? [...managers] : [];
    newRoles.splice(index, 1);

    onChange(newRoles, mainManagerUsername);
  };

  const handleAdd = (manager: MissionManager): void => {
    onChange(
      managers ? [...managers, manager] : [manager],
      mainManagerUsername,
    );
    toggleDraft(false);
  };

  const onMainManagerChange = (username?: string) => {
    const newRoles = managers ? [...managers] : [];
    const index = newRoles.findIndex(
      (manager) => manager.username === username,
    );
    if (index !== -1) {
      newRoles[index] = {
        ...newRoles[index],
        excludeFromInvoiceEmails: false,
        excludeFromTeamPulseEmails: false,
        excludeFromBuilderFeedbackEmails: false,
        excludeFromMissionUpdatesEmails: false,
      };
    }
    onChange(newRoles, username);
  };

  return (
    <div className={styles.root}>
      {managers &&
        managers
          .map((manager, i) => (
            <MissionManagerInput
              key={manager.user.uid || `role-${i}`}
              manager={manager}
              mainManager={mainManagerUsername}
              onMainManagerChange={onMainManagerChange}
              onChange={(manager): void => handleRoleChange(manager, i)}
              onRemove={(): void => handleRoleRemove(i)}
            />
          ))
          // using concat to fix draft smooth rendering
          .concat(
            draft
              ? [
                  <MissionManagerInput
                    key={`role-${managers.length}`}
                    onChange={handleAdd}
                    onMainManagerChange={onMainManagerChange}
                    onRemove={managers.length ? toggleDraft : undefined}
                  />,
                ]
              : [],
          )}
      <TextButton disabled={draft} onClick={toggleDraft} color="success">
        + Add Client
      </TextButton>
    </div>
  );
};

export default MissionManagersSelector;
