import { theme } from '@a_team/ui-components';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  label: {
    display: 'block',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '24px',
    textAlign: 'left',
    margin: '24px 0 12px',
    userSelect: 'none',
  },
  optional: {
    color: theme.colors.Grey['400'],
    marginLeft: '5px',
  },
});

interface LabelProps {
  text: string;
  optional?: boolean;
}

function Label({ text, optional }: LabelProps) {
  const styles = useStyles();

  const renderOptional = () => {
    if (!optional) {
      return null;
    }

    return <span className={styles.optional}>(Optional)</span>;
  };

  return (
    <label className={styles.label}>
      {text}
      {renderOptional()}
    </label>
  );
}

export default Label;
