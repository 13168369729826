import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Spacing } from '@ateams/components';
import { Card } from '../components/card';
import { ErrorTag, Text } from '../components/typography';
import { VettingFeedbackFormDefaults } from '@ateams/api/dist/endpoints/vetting-process-feedback';
import { Comment } from '../components/comment';
import { VettingFeedbackFormErrors } from './form-errors';

const useStyles = createUseStyles({
  generalErrorTag: {
    marginBottom: Spacing.large,
  },
  sectionDescription: {
    marginBottom: Spacing.large,
  },
  comment: {
    marginBottom: Spacing.large,
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

export type VettingFeedbackFormSummaryComments = {
  comments: VettingFeedbackFormDefaults['answers']['summaryComments'];
  comment?: string;
};

export type OnVettingFeedbackFormSummaryCommentsChange = (
  data: VettingFeedbackFormSummaryComments,
) => void;

export interface VettingFeedbackFormSummaryCommentsProps {
  errors: VettingFeedbackFormErrors['summaryComments'];
  defaultValues: VettingFeedbackFormSummaryComments;
  onChange?: OnVettingFeedbackFormSummaryCommentsChange;
  avatarSrc?: string;
  currentUserId?: string;
  isCurrentUserAdmin?: boolean;
  isReadOnly?: boolean;
  className?: string;
}

export const VettingFeedbackFormSummaryCommentsForm: React.FC<VettingFeedbackFormSummaryCommentsProps> =
  React.memo((props) => {
    const { isReadOnly } = props;
    const styles = useStyles();
    const [comment, setComment] = useState<string>();
    const [comments, setComments] = useState(props.defaultValues.comments);

    const onChange = (data: Partial<VettingFeedbackFormSummaryComments>) => {
      props.onChange?.({
        comments: data.comments || comments,
        comment: data.comment || comment,
      });
    };

    const onNewCommentChange = (comment: string) => {
      setComment(comment);
      onChange({ comment });
    };

    const onCommentEdit = (comment: string, commentIndex: number) => {
      if (comments) {
        const newComments = [
          ...comments.slice(0, commentIndex),
          { ...comments[commentIndex], comment },
          ...comments.slice(commentIndex + 1),
        ];

        setComments(newComments);
        onChange({ comments: newComments });
      }
    };

    const onCommentRemove = (commentIndex: number) => {
      const newComments = comments?.filter((_, i) => commentIndex !== i);

      setComments(newComments);
      onChange({ comments: newComments });
    };

    return (
      <Card
        title={'Overall impression and additional comments'}
        className={props.className}
      >
        {props.errors.generalError && (
          <ErrorTag
            text={props.errors.generalError}
            className={styles.generalErrorTag}
          />
        )}

        {!isReadOnly && (
          <Text className={styles.sectionDescription}>
            We’d love to hear any information or feedback on this builder that
            was not covered in the other sections. Are there areas where they
            stood out as exceptional? Areas where they have room for
            improvement? Are there certain types of missions or roles where you
            could see this builder excelling? What’s your overall impression of
            this builder?
          </Text>
        )}

        {!isReadOnly && (
          <Comment
            value={comment}
            onCommentChange={onNewCommentChange}
            avatarSrc={props.avatarSrc}
            className={styles.comment}
            isNewComment
          />
        )}

        {comments &&
          comments.map((comment, i) => (
            <Comment
              key={comment.id}
              className={styles.comment}
              value={comment.comment}
              onCommentChange={(newComment) => onCommentEdit(newComment, i)}
              onCommentRemove={() => onCommentRemove(i)}
              showEditActions={
                props.isCurrentUserAdmin ||
                comment.user.uid === props.currentUserId
              }
              avatarSrc={comment.user.profilePictureURL}
              fullName={comment.user.fullName}
              date={comment.createdAt}
              isNewComment={false}
              isReadOnly={isReadOnly}
            />
          ))}

        {isReadOnly && !comments.length && (
          <Text isReadOnly readOnlyText={'No comments were added.'} />
        )}
      </Card>
    );
  });
