import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import SectionHeading from '@src/components/SectionHeading';
import { Breakpoints } from '@ateams/components';
import { getHQAddress } from '@ateams/react-utils/dist/helpers/strings';

const useStyles = createUseStyles({
  root: {
    lineHeight: '24px',
  },
  bold: {
    fontWeight: 500,
  },
  light: {
    color: '#62646A',
  },
  tableColumn: {
    width: '25%',
    padding: '16px',
    flexShrink: 1,
    borderLeft: '1px solid #62646A',
  },
  tableHeader: {
    width: '25%',
    padding: '16px',
    flexShrink: 1,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    tableColumn: {
      flexShrink: 0,
    },
    tableHeader: {
      flexShrink: 0,
    },
  },
});

export default function WebsitePolicy(): ReactElement {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.bold}>Last Updated: September 1st, 2020</div>
      <div style={{ marginTop: '32px' }}>
        Effective as of September 1st, 2020.
      </div>
      <div style={{ marginTop: '8px' }}>
        This “Privacy Policy” describes the privacy practices of ATeams Inc.’s
        and our subsidiaries and affiliates (collectively, “ATeams”, “we”, “us”,
        or “our”) in connection with the a.team website and any other website
        that we own or control and which posts or links to this Privacy Policy
        (collectively, the “Service”), and the rights and choices available to
        individuals with respect to their information. ATeams may provide
        additional or supplemental privacy policies to individuals for specific
        services that we offer at the time we collect personal information. For
        example, we provide a specific “Talent” Privacy Statement for those
        individuals who are interested in using ATeams to seek out job
        opportunities with our enterprise customers. These supplemental privacy
        policies will govern how we may process the information in the context
        of the specific product or service.
      </div>
      <hr style={{ marginBottom: '32px' }} />
      <div>
        We provide important information for individuals located in the European
        Union,{' '}
        <a
          href={
            'https://ec.europa.eu/eurostat/statistics-explained/index.php/Glossary:European_Economic_Area_(EEA))'
          }
        >
          European Economic Area
        </a>
        , and United Kingdom (collectively, “Europe” or “European”) below.
      </div>
      <SectionHeading>Table of Contents</SectionHeading>
      <ul>
        <li>
          <a href={'#personal-information'}>Personal Information We Collect</a>
        </li>

        <li>
          <a href={'#how-we-use'}>How We Use Your Personal Information</a>
        </li>
        <li>
          <a href={'#how-we-share'}>How We Share Your Personal Information</a>
        </li>
        <li>
          <a href={'#your-choices'}>Your Choices</a>
        </li>
        <li>
          <a href={'#other-sites'}>
            Other Sites, Mobile Applications and Services
          </a>
        </li>
        <li>
          <a href={'#security'}>Security Practices</a>
        </li>
        <li>
          <a href={'#international'}>International Data Transfers</a>
        </li>
        <li>
          <a href={'#children'}>Children</a>
        </li>
        <li>
          <a href={'#changes'}>Changes to this Privacy Policy</a>
        </li>
        <li>
          <a href={'#contact'}>How to Contact Us</a>
        </li>
        <li>
          <a href={'#notice'}>Notice to European Users</a>
        </li>
        <li>
          <a href={'#cookie'}>Cookie Policy</a>
        </li>
      </ul>
      <SectionHeading>
        <div id="personal-information">Personal Information We Collect</div>
      </SectionHeading>
      <div>
        Information you provide to us. Personal information you provide to us
        through the Service or otherwise may include:
      </div>
      <ul style={{ marginTop: '8px' }}>
        <li>
          Business and personal contact information, such as your first and last
          name, email and mailing addresses, phone number, professional title
          and organizational name.
        </li>
        <li>
          Content you choose to upload to the Service, such as text, images,
          audio, and video, along with the metadata associated with the files
          you upload.
        </li>
        <li>
          Profile information, such as your username and password that you may
          set to establish an online account with us.
        </li>
        <li>
          Registration information, such as information that may be related to a
          service, an account or an event for which you register.
        </li>
        <li>
          Feedback or correspondence, such as information you provide when you
          contact us with questions, feedback, or otherwise correspond with us
          online.
        </li>
        <li>
          Demographic Information, such as your city, state, country of
          residence, postal code.
        </li>
        <li>
          Usage information, such as information about how you use the Service
          and interact with us, including information associated with any
          content you upload to the websites or otherwise submit to us, and
          information you provide when you use any interactive features of the
          Service.
        </li>
        <li>
          Marketing information, such as your preferences for receiving
          communications about our activities, events, and publications, and
          details about how you engage with our communications
        </li>
        <li>
          Other information that we may collect which is not specifically listed
          here, but which we will use in accordance with this Privacy Policy or
          as otherwise disclosed at the time of collection.
        </li>
      </ul>
      <div style={{ marginTop: '8px' }}>
        Information we obtain from social media platforms. We may maintain pages
        for ATeams on social media platforms, such as Facebook, LinkedIn,
        Twitter, Google, Instagram, and other third party platforms. When you
        visit or interact with our pages on those platforms, the platform
        provider’s privacy policy will apply to your interactions and their
        collection, use and processing of your personal information. You or the
        platforms may provide us with information through the platform, and we
        will treat such information in accordance with this Privacy Policy.
      </div>
      <div style={{ marginTop: '8px' }}>
        Information we obtain from other third parties. We may receive personal
        information about you from third-party sources. For example, a business
        partner may share your contact information with us if you have expressed
        interest in learning specifically about our services, or the types of
        services we offer. We may obtain your personal information from other
        third parties, such as marketing partners, publicly-available sources
        and data providers.
      </div>
      <div style={{ marginTop: '8px' }} className={styles.bold}>
        Cookies and Other Information Collected by Automated Means
      </div>
      <div style={{ marginTop: '8px' }}>
        We, our service providers, and our business partners may automatically
        log information about you, your computer or mobile device, and activity
        occurring on or through the Service. The information that may be
        collected automatically includes your computer or mobile device
        operating system type and version number, manufacturer and model, device
        identifier (such as the Google Advertising ID or Apple ID for
        Advertising), browser type, screen resolution, IP address, the website
        you visited before browsing to our website, general location information
        such as city, state or geographic area; and information about your use
        of and actions on the Service, such as pages or screens you viewed, how
        long you spent on a page or screen, navigation paths between pages or
        screens, information about your activity on a page or screen, access
        times, and length of access. Our service providers and business partners
        may collect this type of information over time and across third-party
        websites and mobile applications.
      </div>
      <div style={{ marginTop: '8px' }}>
        On our webpages, this information is collected using cookies, browser
        web storage (also known as locally stored objects, or “LSOs”), web
        beacons, and similar technologies, and our emails may also contain web
        beacons.
      </div>
      <div style={{ marginTop: '8px' }}>
        See our Cookie Policy for more information.{' '}
      </div>
      <div style={{ marginTop: '8px' }} className={styles.bold}>
        Referrals
      </div>
      <div style={{ marginTop: '8px' }}>
        Users of the Service may have the opportunity to refer colleagues or
        other contacts to us. If you are an existing user, you may only submit a
        referral if you have permission to provide the referral’s contact
        information to us so that we may contact them.{' '}
      </div>
      <SectionHeading>
        <div id="how-we-use">How We Use Your Personal Information</div>
      </SectionHeading>
      <div>
        We use your personal information for the following purposes and as
        otherwise described in this Privacy Policy or at the time of collection:
        To operate the Service. We use your personal information to:
      </div>
      <ul style={{ marginTop: '8px' }}>
        <li>provide, operate and improve the Service;</li>
        <li>provide information about our products and services;</li>
        <li>establish and maintain your user profile on the Service;</li>
        <li>
          facilitate social features of the Service, such as by identifying and
          suggesting connections with other users of the Service and providing
          chat or messaging functionality;
        </li>
        <li>
          communicate with you about the Service, including by sending you
          announcements, updates, security alerts, and support and
          administrative messages;
        </li>
        <li>
          communicate with you about events or contests in which you
          participate;
        </li>
        <li>
          understand your needs and interests, and personalize your experience
          with the Service and our communications;
        </li>
        <li>provide support and maintenance for the Service;</li>
        <li>respond to your requests, questions and feedback.</li>
      </ul>
      <div style={{ marginTop: '8px' }}>
        For research and development. We analyze use of the Service to improve
        the Service and to develop new services.
      </div>
      <div style={{ marginTop: '8px' }}>
        To send you marketing and promotional communications. We may send you
        ATeams-related marketing communications as permitted by law. You will
        have the ability to opt-out of our marketing and promotional
        communications as described in the Opt out of marketing communications
        section below.{' '}
      </div>
      <div style={{ marginTop: '8px' }}>
        To display advertisements. We work with advertising partners to display
        advertisements on the Service or elsewhere online. These advertisements
        are delivered by our advertising partners and may be targeted based on
        your use of the Service or your activity elsewhere online. To learn more
        about your choices in connection with advertisements, please see the
        section below titled “Targeted online advertising.”
      </div>
      <div style={{ marginTop: '8px' }}>
        To comply with law. We use your personal information as we believe
        necessary or appropriate to comply with applicable laws, lawful
        requests, and legal process, such as to respond to subpoenas or requests
        from government authorities.
      </div>
      <div style={{ marginTop: '8px' }}>
        For compliance, fraud prevention, and safety. We may use your personal
        information and disclose it to law enforcement, government authorities,
        and private parties as we believe necessary or appropriate to: (a)
        protect our, your or others’ rights, privacy, safety or property
        (including by making and defending legal claims); (b) enforce the terms
        and conditions that govern the Service; and (c) protect, investigate and
        deter against fraudulent, harmful, unauthorized, unethical or illegal
        activity.
      </div>
      <div style={{ marginTop: '8px' }}>
        With your consent. In some cases we may specifically ask for your
        consent to collect, use or share your personal information, such as when
        required by law.{' '}
      </div>
      <div style={{ marginTop: '8px' }}>
        To create anonymous, aggregated or de-identified data. We may create
        anonymous, aggregated or de-identified data from your personal
        information and other individuals whose personal information we collect.
        We make personal information into anonymous, aggregated or de-identified
        data by removing information that makes the data personally identifiable
        to you. We may use this anonymous, aggregated or de-identified data and
        share it with third parties for our lawful business purposes, including
        to analyze and improve the Service and promote our business.{' '}
      </div>
      <SectionHeading>
        <div id="how-we-share">How We Share Your Personal Information</div>
      </SectionHeading>
      <div>
        Affiliates. We may share your personal information with our corporate
        parent, subsidiaries, and affiliates, for purposes consistent with this
        Privacy Policy.
      </div>
      <div style={{ marginTop: '8px' }}>
        Service providers. We may share your personal information with third
        party companies and individuals that provide services on our behalf or
        help us operate the Service (such as customer support, hosting,
        analytics, email delivery, marketing, and database management services).{' '}
      </div>
      <div style={{ marginTop: '8px' }}>
        Other Users of the Service and the Public. We may provide functionality
        that enables you to disclose personal information to other users of the
        Service including the ATeams Talent community or the public. For
        instance, you may be able to maintain a user profile with information
        about yourself that you can make available to other users or the public.
        You may also be able to submit content to the Service (such as workplace
        postings, comments, reviews, surveys, blogs, photos, and videos), and we
        will display your name, username, and a link to your user profile along
        with the content you submit. We do not control how other users or third
        parties use any personal information that you make available to other
        users or the public.
      </div>
      <div style={{ marginTop: '8px' }}>
        Professional advisors. We may disclose your personal information to
        professional advisors, such as lawyers, bankers, auditors and insurers,
        where necessary in the course of the professional services that they
        render to us.
      </div>
      <div style={{ marginTop: '8px' }}>
        For compliance, fraud prevention and safety. We may share your personal
        information for the compliance, fraud prevention and safety purposes
        described above.
      </div>
      <div style={{ marginTop: '8px' }}>
        Business transfers. We may sell, transfer or otherwise share some or all
        of our business or assets, including your personal information, in
        connection with a business transaction (or potential business
        transaction) such as a corporate divestiture, merger, consolidation,
        acquisition, reorganization or sale of assets, or in the event of
        bankruptcy or dissolution.
      </div>
      <div style={{ marginTop: '8px' }}>
        ATeams use and transfer to any other app of information received from
        Google APIs will adhere to{' '}
        <a
          href={
            'https://developers.google.com/terms/api-services-user-data-policy'
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          Google API Services User Data Policy
        </a>
        , including the Limited Use requirements.
      </div>
      <SectionHeading>
        <div id="your-choices">Your Choices</div>
      </SectionHeading>
      <div>
        In this section, we describe the rights and choices available to all
        users. Users who are located within the European Union can find
        additional information about their rights below.
      </div>
      <div style={{ marginTop: '8px' }}>
        Access or Update Your Information. If you have registered for an account
        with us, you may review and update certain personal information in your
        account profile by logging into the account.
      </div>
      <div style={{ marginTop: '8px' }}>
        Opt out of marketing communications. You may opt out of
        marketing-related emails by following the opt-out or unsubscribe
        instructions at the bottom of the email, or by contacting us at{' '}
        <a href={'mailto:privacy@a.team'}>privacy@a.team</a>. You may continue
        to receive service-related and other non-marketing emails.
      </div>
      <div style={{ marginTop: '8px' }}>
        Cookies & Browser Web Storage. We may allow service providers and other
        third parties to use cookies and similar technologies to track your
        browsing activity over time and across the Service and third party
        websites. For more details, see our Cookie Policy.
      </div>
      <div style={{ marginTop: '8px' }}>
        Targeted online advertising. Some of the business partners that collect
        information about users’ activities on or through the Service may be
        members of organizations or programs that provide choices to individuals
        regarding the use of their browsing behavior or mobile application usage
        for purposes of targeted advertising.{' '}
      </div>
      <div style={{ marginTop: '8px' }}>
        Users may opt out of receiving targeted advertising on websites through
        members of the Network Advertising Initiative by clicking{' '}
        <a href="https://optout.networkadvertising.org/?c=1">here</a> or the
        Digital Advertising Alliance by clicking{' '}
        <a href="https://optout.aboutads.info/?c=2&lang=EN">here</a>. European
        users may opt out of receiving targeted advertising on websites through
        members of the European Interactive Digital Advertising Alliance by
        clicking <a href="https://youronlinechoices.eu/">here</a>, selecting the
        user’s country, and then clicking “Choices” (or similarly titled link).
        Please note that we also may work with companies that offer their own
        opt-out mechanisms and may not participate in the opt-out mechanisms
        that we linked above. In addition, your mobile device settings may
        provide functionality to limit our, or our partners’, ability to engage
        in ad tracking or targeted advertising using the Google Advertising ID
        or Apple ID for Advertising associated with your mobile device. If you
        choose to opt-out of targeted advertisements, you will still see
        advertisements online but they may not be relevant to you. Even if you
        do choose to opt out, not all companies that serve online behavioral
        advertising are included in this list, so you may still receive some
        cookies and tailored advertisements from companies that are not listed.
      </div>
      <div style={{ marginTop: '8px' }}>
        Do Not Track. Some Internet browsers may be configured to send “Do Not
        Track” signals to the online services that you visit. We currently do
        not respond to “Do Not Track” or similar signals. To find out more about
        “Do Not Track,” please visit{' '}
        <a href="http://www.allaboutdnt.com">http://www.allaboutdnt.com</a>.
      </div>
      <div style={{ marginTop: '8px' }}>
        Choosing not to share your personal information. Where we are required
        by law to collect your personal information, or where we need your
        personal information in order to provide the Service to you, if you do
        not provide this information when requested (or you later ask to delete
        it), we may not be able to provide you with our services. We will tell
        you what information you must provide to receive the Service by
        designating it as required at the time of collection or through other
        appropriate means.
      </div>
      <SectionHeading>
        <div id="other-sites">
          Other Sites, Mobile Applications and Services
        </div>
      </SectionHeading>
      <div>
        The Service may contain links to other websites, mobile applications,
        and other online services operated by third parties. These links are not
        an endorsement of, or representation that we are affiliated with, any
        third party. In addition, our content may be included on web pages or in
        mobile applications or online services that are not associated with us.
        We do not control third party websites, mobile applications or online
        services, and we are not responsible for their actions. Other websites
        and services follow different rules regarding the collection, use and
        sharing of your personal information. We encourage you to read the
        privacy policies of the other websites and mobile applications and
        online services you use.
      </div>
      <SectionHeading>
        <div id="security">Security Practices</div>
      </SectionHeading>
      <div>
        The security of your personal information is important to us. We employ
        a number of organizational, technical and physical safeguards designed
        to protect the personal information we collect. However, security risk
        is inherent in all internet and information technologies and we cannot
        guarantee the security of your personal information.{' '}
      </div>
      <SectionHeading>
        <div id="international">International Data Transfers</div>
      </SectionHeading>
      <div>
        We are headquartered in the United States and may have service providers
        in other countries, and your personal information may be transferred to
        the United States or other locations outside of your state, province, or
        country where privacy laws may not be as protective as those in your
        state, province, or country.
      </div>
      <div style={{ marginTop: '8px' }}>
        European Union users should read the important information provided
        below about transfer of personal information outside of the European
        Union.
      </div>
      <SectionHeading>
        <div id="children">Children</div>
      </SectionHeading>
      <div>
        The Service is not directed to, and we do not knowingly collect personal
        information from, anyone under the age of 16. If a parent or guardian
        becomes aware that his or her child has provided us with information
        without their consent, he or she should contact us. We will delete such
        information from our files as soon as reasonably practicable. We
        encourage parents with concerns to contact us.
      </div>
      <SectionHeading>
        <div id="changes">Changes to this Privacy Policy</div>
      </SectionHeading>
      <div>
        We reserve the right to modify this Privacy Policy at any time. If we
        make material changes to this Privacy Policy, we will notify you by
        updating the date of this Privacy Policy and posting it on the Service.
        We may, and if required by law will, also provide notification of
        changes in another way that we believe is reasonably likely to reach
        you, such as via e-mail (if you have an account where we have your
        contact information) or another manner through the Service.
      </div>
      <div style={{ marginTop: '8px' }}>
        Any modifications to this Privacy Policy will be effective upon our
        posting the new terms and/or upon implementation of the new changes on
        the Service (or as otherwise indicated at the time of posting). In all
        cases, your continued use of the Service after the posting of any
        modified Privacy Policy indicates your acceptance of the terms of the
        modified Privacy Policy.
      </div>
      <SectionHeading>
        <div id="contact">How to Contact Us</div>
      </SectionHeading>
      <div>
        Please direct any questions or comments about this Policy or privacy
        practices to <a href="mailto:privacy@a.team">privacy@a.team</a>. You may
        also write to us via postal mail at:
      </div>
      <div style={{ marginTop: '8px', marginLeft: '40px' }}>
        {getHQAddress('name')} <br />
        Attn: Legal – Privacy <br />
        {getHQAddress()}
      </div>
      <SectionHeading>
        <div id="notice">Notice to European Users</div>
      </SectionHeading>
      <div>
        The information provided in this “Notice to European Users” section
        applies only to individuals in Europe.
      </div>
      <div style={{ marginTop: '8px' }}>
        Personal information. References to “personal information” in this
        Privacy Policy are equivalent to “personal data” governed by European
        data protection legislation.
      </div>
      <div style={{ marginTop: '8px' }}>
        Controller and Representative. ATeams Inc. is the controller of your
        personal information covered by this Privacy Policy for purposes of
        European data protection legislation.{' '}
      </div>
      <div style={{ marginTop: '8px' }}>
        The contact information for our representative in Europe is:{' '}
        <a href="mailto:privacy@a.team">privacy@a.team</a>
      </div>
      <div style={{ marginTop: '24px' }}>
        Legal bases for processing. We use your personal information only as
        permitted by law. Our legal bases for processing the personal
        information described in this Privacy Policy are described in the table
        below.
      </div>
      <div style={{ border: '1px solid #62646A', marginTop: '24px' }}>
        <div style={{ display: 'flex', backgroundColor: '#fff' }}>
          <div style={{ maxWidth: '45%', padding: '16px' }}>
            <div className={styles.bold}>Processing purpose</div>
            <div className={styles.light} style={{ marginTop: '8px' }}>
              Details regarding each processing purpose listed below are
              provided in the section above titled “How We Use Your Personal
              Information”.
            </div>
          </div>
          <div
            style={{ padding: '16px', borderLeft: '1px solid #62646A' }}
            className={styles.bold}
          >
            Legal basis
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div style={{ minWidth: '45%', padding: '16px' }}>
            <div className={styles.bold}>To operate the Service</div>
          </div>
          <div style={{ padding: '16px', borderLeft: '1px solid #62646A' }}>
            Processing is necessary to perform the contract governing our
            provision of the Service or to take steps that you request prior to
            signing up for the Service. If we have not entered into a contract
            with you, we process your personal information based on our
            legitimate interest in providing the Service you access and request.
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div style={{ minWidth: '45%', padding: '16px' }}>
            <div className={styles.bold}>
              <li>For research and development</li>
              <li>To send you marketing communications</li>
              <li>To display advertisements</li>
              <li>For compliance, fraud prevention and safety</li>
              <li>To create anonymous, aggregated or de-identified data</li>
            </div>
          </div>
          <div style={{ padding: '16px', borderLeft: '1px solid #62646A' }}>
            These activities constitute our legitimate interests. We do not use
            your personal information for these activities where our interests
            are overridden by the impact on you (unless we have your consent or
            are otherwise required or permitted to by law).
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div style={{ minWidth: '45%', padding: '16px' }}>
            <div className={styles.bold}>To comply with law</div>
          </div>
          <div style={{ padding: '16px', borderLeft: '1px solid #62646A' }}>
            Processing is necessary to comply with our legal obligations.
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div style={{ minWidth: '45%', padding: '16px', flexShrink: 0 }}>
            <div className={styles.bold}>With your consent</div>
          </div>
          <div style={{ padding: '16px', borderLeft: '1px solid #62646A' }}>
            Processing is based on your consent. Where we rely on your consent
            you have the right to withdraw it any time in the manner indicated
            when you consent or in the Service.
          </div>
        </div>
      </div>
      <div style={{ marginTop: '8px' }}>
        Use for new purposes. We may use your personal information for reasons
        not described in this Privacy Policy where permitted by law and the
        reason is compatible with the purpose for which we collected it. If we
        need to use your personal information for an unrelated purpose, we will
        notify you and explain the applicable legal basis.
      </div>
      <div style={{ marginTop: '8px' }}>
        We may share any information shared with us via the builder profile,
        mission applications, and other interactions with our platform,
        services, and staff, with generative language models such as OpenAI,
        based on our legitimate interest in providing the Service you access and
        request.
      </div>
      <div style={{ marginTop: '8px' }}>
        Sensitive personal information. We ask that you not provide us with any
        sensitive personal information (e.g., information related to racial or
        ethnic origin, political opinions, religion or other beliefs, health,
        biometrics or genetic characteristics, criminal background or trade
        union membership) on or through the Service, or otherwise to us.{' '}
      </div>
      <div style={{ marginTop: '8px' }}>
        If you provide us with any sensitive personal information to us when you
        use the Service, you must consent to our processing and use of such
        sensitive personal information in accordance with this Privacy Policy.
        If you do not consent to our processing and use of such sensitive
        personal information, you must not submit such sensitive personal
        information through our Service.
      </div>
      <div style={{ marginTop: '8px' }}>
        Retention. We retain personal information for as long as necessary to
        fulfill the purposes for which we collected it, including for the
        purposes of satisfying any legal, accounting, or reporting requirements,
        to establish or defend legal claims, or for fraud prevention purposes.
      </div>
      <div style={{ marginTop: '8px' }}>
        To determine the appropriate retention period for personal information,
        we consider the amount, nature, and sensitivity of the personal
        information, the potential risk of harm from unauthorized use or
        disclosure of your personal information, the purposes for which we
        process your personal information and whether we can achieve those
        purposes through other means, and the applicable legal requirements.
        When we no longer require the personal information we have collected
        about you, we will either delete or anonymize it or, if this is not
        possible (for example, because your personal information has been stored
        in backup archives), then we will securely store your personal
        information and isolate it from any further processing until deletion is
        possible. If we anonymize your personal information (so that it can no
        longer be associated with you), we may use this information indefinitely
        without further notice to you.
      </div>
      <div style={{ marginTop: '8px' }}>
        Your rights. European data protection laws give you certain rights
        regarding your personal information. If you are located within the
        European Union, you may ask us to take the following actions in relation
        to your personal information that we hold:
      </div>
      <ul style={{ marginTop: '8px' }}>
        <li>
          Access. Provide you with information about our processing of your
          personal information and give you access to your personal information.
        </li>
        <li>
          Correct. Update or correct inaccuracies in your personal information.
        </li>
        <li>Delete. Delete your personal information.</li>
        <li>
          Transfer. Transfer a machine-readable copy of your personal
          information to you or a third party of your choice.
        </li>
        <li>Restrict. Restrict the processing of your personal information.</li>
        <li>
          Object. Object to our reliance on our legitimate interests as the
          basis of our processing of your personal information that impacts your
          rights.
        </li>
      </ul>
      <div style={{ marginTop: '8px' }}>
        You may submit these requests by email to{' '}
        <a href="mailto:privacy@a.team">privacy@a.team</a> or our postal address
        provided above. We may request specific information from you to help us
        confirm your identity and process your request. Applicable law may
        require or permit us to decline your request. If we decline your
        request, we will tell you why, subject to legal restrictions. If you
        would like to submit a complaint about our use of your personal
        information or our response to your requests regarding your personal
        information, you may contact us or submit a complaint to the data
        protection regulator in your jurisdiction. You can find your data
        protection regulator{' '}
        <a href="https://edpb.europa.eu/about-edpb/board/members_en">here</a>.
      </div>
      <div style={{ marginTop: '8px' }}>
        Cross-Border Data Transfer. If we transfer your personal information out
        of Europe to a country not deemed by the European Commission to provide
        an adequate level of personal information protection, the transfer will
        be performed:
      </div>
      <ul style={{ marginTop: '8px' }}>
        <li>
          Pursuant to the recipient’s compliance with standard contractual
          clauses, Swiss-US Privacy Shield (as applicable), or Binding Corporate
          Rules.
        </li>
        <li>
          Pursuant to the consent of the individual to whom the personal
          information pertains.
        </li>
        <li>As otherwise permitted by applicable European requirements.</li>
      </ul>
      <div style={{ marginTop: '8px' }}>
        You may contact us if you want further information on the specific
        mechanism used by us when transferring your personal information out of
        Europe.
      </div>
      <SectionHeading>
        <div id="cookie">Cookie Policy</div>
      </SectionHeading>
      <div>
        This Cookie Policy explains how ATeams Inc. (“ATeams”, “we”, “us” or
        “our”) uses cookies and similar technologies in connection with the
        a.team website and any other website that we own or control and which
        posts or links to this Cookie Policy (collectively, the “Sites”).
      </div>
      <div style={{ marginTop: '8px' }}>
        What are cookies? Cookies are small data files that are placed on your
        computer or mobile device when you visit a website. Cookies serve
        different purposes, like helping us understand how a site is being used,
        letting you navigate between pages efficiently, remembering your
        preferences and generally improving your browsing experience.{' '}
      </div>
      <div style={{ marginTop: '8px' }}>
        Our Sites may use both session cookies (which expire once you close your
        web browser) and persistent cookies (which stay on your computer or
        mobile device until you delete them).{' '}
      </div>
      <div style={{ marginTop: '8px' }}>
        We use two broad categories of cookies: (1) first party cookies, served
        directly by us to your computer or mobile device, which we use to
        recognize your computer or mobile device when it revisits our Sites; and
        (2) third party cookies, which are served by service providers or
        business partners on our Sites, and can be used by these parties to
        recognize your computer or mobile device when it visits other websites.
        Third party cookies can be used for a variety of purposes, including
        site analytics, advertising and social media features.
      </div>
      <div style={{ marginTop: '8px' }}>
        What types of cookies and similar tracking technologies does ATeams use
        on the Sites? On the Sites, we use cookies and other tracking
        technologies in the following categories described in the table below.{' '}
      </div>
      <div style={{ border: '1px solid #62646A', marginTop: '24px' }}>
        <div style={{ display: 'flex', backgroundColor: '#fff' }}>
          <div className={styles.tableHeader}>
            <div className={styles.bold}>Type</div>
          </div>
          <div className={styles.tableColumn}>
            <div className={styles.bold}>Description</div>
          </div>
          <div className={styles.tableColumn}>
            <div className={styles.bold}>Who serves the cookies</div>
            <div className={styles.light}>(link to privacy policy/site)</div>
          </div>
          <div
            style={{ padding: '16px', borderLeft: '1px solid #62646A' }}
            className={styles.bold}
          >
            How to control them
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div className={styles.tableHeader}>
            <div>Advertising</div>
          </div>
          <div className={styles.tableColumn}>
            <div>
              These cookies are used by advertising companies to collect
              information about how you use our Sites and other websites over
              time. These companies use this information to show you ads they
              believe will be relevant to you within our Service and elsewhere,
              and to measure how the ads perform.
            </div>
          </div>
          <div className={styles.tableColumn}>
            <a href="https://www.facebook.com/privacy/explanation">Facebook</a>
            <br />
            <a
              href="https://twitter.com/en/privacy"
              style={{ marginTop: '48px' }}
            >
              Twitter
            </a>
          </div>
          <div
            style={{ padding: '16px', borderLeft: '1px solid #62646A' }}
            className={styles.bold}
          >
            See ‘your choices’ below.
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div className={styles.tableHeader}>
            <div>Analytics</div>
          </div>
          <div className={styles.tableColumn}>
            <div>
              These cookies help us understand how our Service is performing and
              being used. These cookies may work with web beacons included in
              emails we send to track which emails are opened and which links
              are clicked by recipients.
            </div>
          </div>
          <div className={styles.tableColumn}>
            <a href="https://policies.google.com/privacy?hl=en">
              Google Analytics
            </a>
            <br />
            <a
              href="https://mixpanel.com/legal/privacy-policy/"
              style={{ marginTop: '48px' }}
            >
              Mixpanel
            </a>
          </div>
          <div
            style={{ padding: '16px', borderLeft: '1px solid #62646A' }}
            className={styles.bold}
          >
            See ‘your choices’ below. Google Analytics uses its own cookies. You
            can find out more information about Google Analytics cookies{' '}
            <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
              here
            </a>{' '}
            and about how Google protects your data{' '}
            <a href="https://marketingplatform.google.com/about/">here</a>. You
            can prevent the use of Google Analytics relating to your use of our
            Sites by downloading and installing a browser plugin available{' '}
            <a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB">here</a>
            .
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div className={styles.tableHeader}>
            <div>Essential</div>
          </div>
          <div className={styles.tableColumn}>
            <div>
              These cookies are necessary to allow the technical operation of
              our Service (e.g., they enable you to move around on a website and
              to use its features).
            </div>
          </div>
          <div className={styles.tableColumn}>
            <a href="https://policies.google.com/privacy?hl=en">
              Google Tag Manager
            </a>
          </div>
          <div
            style={{ padding: '16px', borderLeft: '1px solid #62646A' }}
            className={styles.bold}
          >
            See ‘your choices’ below.
          </div>
        </div>
        <div style={{ display: 'flex', borderTop: '1px solid #62646A' }}>
          <div className={styles.tableHeader}>
            <div>Functionality / performance</div>
          </div>
          <div className={styles.tableColumn}>
            <div>
              These cookies enhance the performance and functionality of our
              Service.
            </div>
          </div>
          <div className={styles.tableColumn}>
            <a href="https://www.intercom.com/legal/terms-and-policies">
              Intercom
            </a>
          </div>
          <div
            style={{ padding: '16px', borderLeft: '1px solid #62646A' }}
            className={styles.bold}
          >
            See ‘your choices’ below.
          </div>
        </div>
      </div>
      <div style={{ marginTop: '8px' }}>
        Other technologies In addition to cookies, our Sites may use other
        technologies, such as Flash technology and pixel tags to collect
        information automatically.
      </div>
      <div style={{ marginTop: '8px' }}>
        Browser Web Storage We may use browser web storage (including via
        HTML5), also known as locally stored objects (“LSOs”), for similar
        purposes as cookies. Browser web storage enables the storage of a larger
        amount of data than cookies. Your web browser may provide functionality
        to clear your browser web storage.
      </div>
      <div style={{ marginTop: '8px' }}>
        Web Beacons We may also use web beacons (which are also known as pixel
        tags and clear GIFs) on our Sites and in our HTML formatted emails to
        track the actions of users on our Sites and interactions with our
        emails. Unlike cookies, which are stored on the hard drive of your
        computer or mobile device by a website, pixel tags are embedded
        invisibly on webpages or within HTML formatted emails. Pixel tags are
        used to demonstrate that a webpage was accessed or that certain content
        was viewed, typically to measure the success of our marketing campaigns
        or engagement with our emails and to compile statistics about usage of
        the Sites, so that we can manage our content more effectively.
      </div>
      <div style={{ marginTop: '8px' }}>
        Your choices Most browsers let you remove or reject cookies. To do this,
        follow the instructions in your browser settings. Many browsers accept
        cookies by default until you change your settings. Please note that if
        you set your browser to disable cookies, the Sites may not work
        properly.{' '}
      </div>
      <div style={{ marginTop: '8px' }}>
        For more information about cookies, including how to see what cookies
        have been set on your computer or mobile device and how to manage and
        delete them, visit www.allaboutcookies.org. If you do not accept our
        cookies, you may experience some inconvenience in your use of our Sites.
        For example, we may not be able to recognize your computer or mobile
        device and you may need to log in every time you visit our Sites.
      </div>
      <div style={{ marginTop: '8px' }}>
        Users may opt out of receiving targeted advertising on websites through
        members of the Network Advertising Initiative by clicking{' '}
        <a href="https://optout.networkadvertising.org/?c=1">here</a> or the
        Digital Advertising Alliance by clicking{' '}
        <a href="https://optout.aboutads.info/?c=2&lang=EN">here</a>. European
        users may opt out of receiving targeted advertising on websites through
        members of the European Interactive Digital Advertising Alliance by
        clicking <a href="https://www.youronlinechoices.eu/">here</a>, selecting
        the user’s country, and then clicking “Choices” (or similarly-titled
        link). Please note that we also may work with companies that offer their
        own opt-out mechanisms and may not participate in the opt-out mechanisms
        that we linked above.
      </div>
      <div style={{ marginTop: '8px' }}>
        If you choose to opt-out of targeted advertisements, you will still see
        advertisements online but they may not be relevant to you. Even if you
        do choose to opt out, not all companies that serve online behavioral
        advertising are included in this list, and so you may still receive some
        cookies and tailored advertisements from companies that are not listed.
      </div>
      <div style={{ marginTop: '8px' }}>
        For more information about how we collect, use and share your
        information, see our Privacy Policy.
      </div>
      <div style={{ marginTop: '8px' }}>
        Changes Information about the cookies we use may be updated from time to
        time, so please check back on a regular basis for any changes.
      </div>
      <div style={{ marginTop: '8px' }}>
        Questions If you have any questions about this Cookie Policy, please
        contact us by email at{' '}
        <a href="mailto:privacy@a.team">privacy@a.team</a>.
      </div>
    </div>
  );
}
