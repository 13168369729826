import React from 'react';
import Checkbox from './Checkbox';
import Label from './Label';
import FadeInOut from './FadeInOut';
import { useStyles } from '.';
import AuthStore from '@src/stores/Auth';
import { ProfileStep as IProfileStep } from './utils';

interface ProfileStepProps {
  step: IProfileStep;
  auth: AuthStore;
}

const ProfileStep = ({ step, auth }: ProfileStepProps) => {
  const styles = useStyles();

  const renderJobStep = () => (
    <div className={styles.groupItem}>
      <Checkbox checked={step.completed} />
      <div className={styles.jobItem}>
        <Label checked={step.completed}>{step.label}</Label>
        {!step.haveNotHadThreeJobs && (
          <>
            <span className={styles.line} />
            <button
              className={styles.linkBtn}
              onClick={() =>
                auth.handleProfileCompleteness(auth, {
                  ...(auth?.user?.profileCompleteness ?? {}),
                  haveNotHadThreeJobs: true,
                })
              }
            >
              I haven't had 3 jobs
            </button>
          </>
        )}
      </div>
    </div>
  );

  return (
    <FadeInOut isVisible={!step.completed} duration={step.duration}>
      {step.id === 'jobs' ? (
        renderJobStep()
      ) : (
        <div className={styles.groupItem}>
          <Checkbox checked={step.completed} />
          <Label checked={step.completed}>{step.label}</Label>
        </div>
      )}
    </FadeInOut>
  );
};

export default ProfileStep;
