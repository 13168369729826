import React from 'react';
import { createUseStyles } from 'react-jss';

interface TooltipBodyProps {
  loginHistory: {
    month: string;
    countries: string[];
  }[];
  linkedinCountry: string | undefined;
}

const useStyles = createUseStyles({
  container: {
    width: 300,
  },
  title: {
    fontSize: 15,
    fontWeight: '500',
    marginBottom: 8,
  },
  description: {
    fontSize: 12,
    color: '#C0C0C0',
    marginBottom: 16,
  },
  loginHistoryContainer: {
    marginBottom: 16,
  },
  loginHistoryItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  loginHistoryItemMonth: {
    fontSize: 12,
  },
  loginHistoryItemCountries: {
    fontSize: 12,
  },
  linkedinCountry: {
    fontWeight: '500',
    fontSize: 12,
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #62646A',
    paddingTop: 8,
  },
});

const TooltipBody = ({
  linkedinCountry,
  loginHistory,
}: TooltipBodyProps): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.title}>Login IP location history</div>
      <div className={styles.description}>
        Countries logged in from in the last 90 days
      </div>
      <div className={styles.loginHistoryContainer}>
        {loginHistory.map((history) => (
          <div key={history.month} className={styles.loginHistoryItem}>
            <div className={styles.loginHistoryItemMonth}>{history.month}</div>
            <div className={styles.loginHistoryItemCountries}>
              {history.countries.join(', ')}
            </div>
          </div>
        ))}
      </div>
      {linkedinCountry && (
        <div className={styles.linkedinCountry}>
          <span>LinkedIn country</span> <span>{linkedinCountry}</span>
        </div>
      )}
    </div>
  );
};

export default TooltipBody;
