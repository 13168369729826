import React from 'react';
import { createUseStyles } from 'react-jss';
import AspectRatioBox from '@src/components/AspectRatioBox';
import Image from '@src/components/Image';
import NoCover from '@src/components/ProjectCardV2/NoCover';
import cx from 'classnames';

const useStyles = createUseStyles({
  relatedImgContainer: {
    width: 56,
  },
  relatedProjectImageCover: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 9,
  },
});

interface RelatedImgContainerProps {
  projectCover?: string;
  companyLogoUrl?: string;
  logoSize?: number;
  index: number;
  iconSize?: 'md' | 'sm' | 'lg';
  noCoverIconClassName?: string;
}

const RelatedImgContainer = ({
  projectCover,
  companyLogoUrl,
  index,
  logoSize,
  iconSize,
  noCoverIconClassName,
}: RelatedImgContainerProps) => {
  const styles = useStyles();

  return (
    <div className={styles.relatedImgContainer}>
      <AspectRatioBox aspectRatio={4 / 3}>
        {projectCover ? (
          <Image
            alt="project cover"
            src={projectCover}
            className={styles.relatedProjectImageCover}
            sizes="250px"
          />
        ) : (
          <NoCover
            iconSize={iconSize || 'lg'}
            imgClassName={cx(styles.relatedProjectImageCover)}
            noCoverIconClassName={noCoverIconClassName}
            logoSize={logoSize || 24}
            companyLogo={companyLogoUrl}
            number={index}
            borderRadius={3}
          />
        )}
      </AspectRatioBox>
    </div>
  );
};

export default RelatedImgContainer;
