import React, { useMemo } from 'react';
import { ClientRoleQuestion } from '@a_team/models/dist/MissionRole';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { Checkbox, ToggleSwitch } from '@ateams/components';

interface Props {
  question: ClientRoleQuestion;
  onChange: (question: ClientRoleQuestion) => void;
  disabled?: boolean;
}

export const CustomQuestionEdit = (props: Props) => {
  const { question, disabled, onChange } = props;

  const textIsSet = useMemo(() => {
    return question.text.trim().length > 0;
  }, [question.text]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <OutlinedInput
          placeholder={'Do you have experience leading teams?'}
          value={question.text}
          margin={'none'}
          disabled={disabled}
          onChange={(e): void =>
            onChange({
              ...question,
              text: e.target.value,
            })
          }
        />
        <div style={{ minWidth: 180, marginLeft: 16 }}>
          <ToggleSwitch
            disabled={!textIsSet}
            checked={!!question.isVisible}
            onChange={(checked): void =>
              onChange({
                ...question,
                isVisible: !!checked,
              })
            }
            label={'Include question'}
            justify={'left'}
            size={'small'}
          />
        </div>
      </div>
      <Checkbox
        disabled={!textIsSet}
        label={'The answer to this question is mandatory.'}
        checked={!!question.isRequired}
        onChange={(e): void =>
          onChange({
            ...question,
            isRequired: e.target.checked,
          })
        }
      />
    </>
  );
};
