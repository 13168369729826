import React, {
  CSSProperties,
  ReactElement,
  useMemo,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import search from './search.svg';
import RoleCategoriesSection from './RoleCategoriesSection';
import RoleCategoryObject, {
  RoleCategoryId,
} from '@a_team/models/dist/RoleCategory';
import useClickOutside from '@src/hooks/useClickOutside';
import { RoleCategoriesCatalog } from '@src/stores/RoleCategories';
import { Breakpoints } from '@ateams/components';

interface Props {
  selected?: RoleCategoryId[];
  onSelect(category: RoleCategoryObject): void;
  onDeselect?(category: RoleCategoryObject): void;
  onClose(): void;
  catalog: RoleCategoriesCatalog | null;
  categories: RoleCategoryObject[] | null;
  className?: string;
  style?: CSSProperties;
}

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    top: '36px',
    background: '#fff',
    borderRadius: '8px',
    width: '100%',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25)',
    zIndex: 1000,
    overflow: 'hidden',
  },
  searchContainer: {
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
    paddingTop: '24px',
    paddingBottom: '24px',
    paddingLeft: '16px',
    paddingRight: '16px',
    background: '#fff',
  },
  resultsContainer: {
    padding: '24px 16px',
    overflow: 'auto',
    maxHeight: '320px',
  },
  noResults: {
    fontSize: '14px',
    fontStyle: 'italic',
    color: '#62646A',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      width: '664px',
    },
  },
});

export default function RoleCategoryCardSelector(props: Props): ReactElement {
  const {
    selected,
    catalog,
    categories,
    onSelect,
    onDeselect,
    onClose,
    className,
    style,
  } = props;

  const styles = useStyles();
  const ref = useRef(null);
  useClickOutside(ref, onClose);
  const selectedSet = useMemo(() => new Set(selected), [selected]);
  const [query, setQuery] = useState('');
  const queryResult = useMemo(() => {
    if (!categories || !query.trim()) return null;

    return categories.filter((item) =>
      item.title.toLowerCase().includes(query.toLowerCase()),
    );
  }, [categories, query]);

  return (
    <div ref={ref} className={cx(styles.root, className)} style={style}>
      <div className={styles.searchContainer}>
        <OutlinedInput
          style={{ margin: 0 }}
          value={query}
          onChange={(e): void => setQuery(e.target.value)}
          icon={search}
          placeholder={'Search professional role...'}
          margin="none"
        />
      </div>
      <div className={styles.resultsContainer}>
        {catalog && categories ? (
          queryResult ? (
            queryResult.length ? (
              <RoleCategoriesSection
                title="Relevant Roles"
                query={query.trim()}
                selected={selectedSet}
                onSelect={onSelect}
                onDeselect={onDeselect}
                categories={queryResult}
              />
            ) : (
              <div className={styles.noResults}>
                We didn't find this professional role, try something else.
              </div>
            )
          ) : (
            catalog.map((section) => (
              <RoleCategoriesSection
                key={section.anchor}
                selected={selectedSet}
                onSelect={onSelect}
                onDeselect={onDeselect}
                title={section.anchor}
                categories={section.categories}
              />
            ))
          )
        ) : (
          'Loading...'
        )}
      </div>
    </div>
  );
}
