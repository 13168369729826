import React, { useLayoutEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import {
  Size,
  Text,
} from '@src/views/VettingFeedbackForm/components/typography';
import { VettingProcessFeedbackTalentSkill } from '@a_team/models/dist/vetting-processes/feedback';
import { ValueTag } from '@src/views/VettingFeedbackForm/components/value-tag';
import { ShowMoreButton } from '@src/views/VettingFeedbackForm/components/show-more';

export interface VettingProcessSummarySkillsProps {
  className?: string;
  skills: VettingProcessFeedbackTalentSkill[];
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginBottom: {
    marginBottom: '8px',
  },
  skillsTagsContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '4px',
    overflow: 'hidden',
    height: '32px',
  },
  skillTagsContainerShowingMore: {
    height: 'auto',
  },
});

/**
 * Checks if the div content is exceeding the size of its parent
 * {@link https://stackoverflow.com/a/9541579}
 */
const checkHasOverflown = ({
  clientWidth,
  clientHeight,
  scrollWidth,
  scrollHeight,
}: HTMLDivElement) => {
  return scrollHeight > clientHeight || scrollWidth > clientWidth;
};

export const VettingProcessSummarySkills: React.FC<
  VettingProcessSummarySkillsProps
> = (props) => {
  const styles = useStyles();
  const skillsRef = useRef<HTMLDivElement>(null);
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [areSkillsOverflowing, setAreSkillsOverflowing] = useState<boolean>();

  /** {@link https://daveceddia.com/useeffect-vs-uselayouteffect/} */
  useLayoutEffect(() => {
    if (skillsRef.current && !isShowingMore) {
      const newAreSkillsOverflowing = checkHasOverflown(skillsRef.current);

      setAreSkillsOverflowing(newAreSkillsOverflowing);
    }
  }, [isShowingMore, setAreSkillsOverflowing, skillsRef.current]);

  return (
    <div className={cx(styles.container, props.className)}>
      <Text size={Size.Small} className={styles.marginBottom}>
        Skills
      </Text>

      <div
        className={cx(styles.skillsTagsContainer, styles.marginBottom, {
          [styles.skillTagsContainerShowingMore]: isShowingMore,
        })}
        ref={skillsRef}
      >
        {props.skills.map((skill, i) => (
          <SkillValueTag key={skill.skill.id} skill={skill} />
        ))}
      </div>

      {areSkillsOverflowing && (
        <ShowMoreButton
          showMoreLabel={'Show all skills'}
          isShowingMore={isShowingMore}
          onClick={() => setIsShowingMore(!isShowingMore)}
        />
      )}
    </div>
  );
};

interface SkillValueTagProps {
  skill: VettingProcessFeedbackTalentSkill;
}

const useSkillValueTagStyles = createUseStyles({
  separator: {
    height: '16px',
    width: '1px',
    margin: '0 8px',
    background: '#C0C0C0',
  },
});

const SkillValueTag: React.FC<SkillValueTagProps> = (props) => {
  const styles = useSkillValueTagStyles();
  const { skill } = props;

  return (
    <ValueTag>
      <Text size={Size.Small}>{skill.skill.name}</Text>
      <div className={styles.separator} />
      <Text size={Size.Small}>{skill.level}</Text>
    </ValueTag>
  );
};
