import React, { useEffect, useMemo, useState } from 'react';
import useLoadingState from '@src/hooks/useLoadingState';
import { AsyncSelect, AsyncSelectProps } from '@ateams/components';
import {
  TalentIndustry,
  TalentIndustryId,
} from '@a_team/models/dist/TalentIndustry';
import { fetchIndustryList } from '@src/helpers/talent-industry-api';

export interface TalentIndustrySelectorProps<IsMulti extends boolean = false>
  extends Omit<
    AsyncSelectProps<IsMulti, TalentIndustryOptionType>,
    'loadOptions' | 'onChange'
  > {
  isMulti?: boolean;
  defaultValue?: IsMulti extends true
    ? TalentIndustryId[] | TalentIndustryOptionType[]
    : TalentIndustryId | TalentIndustryOptionType;
}

export interface TalentIndustryOptionType {
  value: string;
  label: string;
  talentIndustry: TalentIndustry;
}

export function talentIndustryToOption(
  talentIndustry: TalentIndustry,
): TalentIndustryOptionType {
  return {
    value: talentIndustry?.id ?? '',
    label: talentIndustry?.name ?? '',
    talentIndustry,
  };
}

export const TalentIndustrySelector = <
  T extends TalentIndustrySelectorProps<
    T['isMulti'] extends true ? true : false
  >,
>(
  props: T,
) => {
  const {
    defaultValue,
    isDisabled,
    onChange,
    isMulti = false,
    ...rest
  } = props;
  const [loadingDefault, setLoadingDefault] = useLoadingState();
  const [talentIndustry, setTalentIndustry] = useState<
    TalentIndustryOptionType[] | TalentIndustryOptionType | null
  >();

  const setDefault = async () => {
    if (defaultValue) {
      try {
        setLoadingDefault(
          (async () => {
            if (Array.isArray(defaultValue)) {
              if (typeof defaultValue?.[0] === 'string') {
                const talentIndustries = await fetchIndustryList({
                  filter: {
                    id: defaultValue as string[],
                  },
                });

                setTalentIndustry(
                  talentIndustries?.map(talentIndustryToOption),
                );
              } else {
                setTalentIndustry(defaultValue as TalentIndustryOptionType[]);
              }
            } else {
              if (typeof defaultValue === 'string') {
                const talentIndustry = await fetchIndustryList({
                  filter: {
                    id: [defaultValue],
                  },
                });

                setTalentIndustry(talentIndustryToOption(talentIndustry?.[0]));
              } else {
                setTalentIndustry(defaultValue as TalentIndustryOptionType);
              }
            }
          })(),
          false,
        );
      } catch (err) {
        console.error(err, 'Failed to find talent industry by id');
      }
    }
  };

  useEffect(() => {
    setDefault();
  }, []);

  const value = useMemo(
    () => props.value || talentIndustry,
    [props.value, talentIndustry],
  );

  const onTalentIndustryChange = async (
    option: TalentIndustryOptionType[] | TalentIndustryOptionType | null,
  ) => {
    setTalentIndustry(option);
    onChange && onChange(option);
  };

  const loadOptions = async (searchTerm: string) => {
    const talentIndustries = await fetchIndustryList({
      filter: {
        query: searchTerm,
      },
    });

    return talentIndustries?.map(talentIndustryToOption);
  };

  const onRemoveOption =
    props.onRemoveOption || isMulti
      ? (option: TalentIndustryOptionType) => {
          if (!value) {
            return;
          }

          const newTalentIndustries = (
            value as TalentIndustryOptionType[]
          ).filter(({ value }) => value !== option.value);

          setTalentIndustry(newTalentIndustries);
          onChange && onChange(newTalentIndustries);
        }
      : undefined;

  return (
    <AsyncSelect<
      T['isMulti'] extends true ? true : false,
      TalentIndustryOptionType
    >
      placeholder={'Select Industry...'}
      loadOptions={loadOptions}
      onChange={onTalentIndustryChange}
      value={value}
      useDebounce
      isClearable
      isDisabled={isDisabled || loadingDefault}
      isLoading={loadingDefault}
      onRemoveOption={onRemoveOption}
      isMulti={isMulti}
      {...rest}
    />
  );
};
