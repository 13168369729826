import { Modal } from '@a_team/ui-components';
import { Breakpoints } from '@ateams/components';
import CompanyLogoFallback from '@src/components/CompanyLogoFallback';
import { useQueryTalentIndustries } from '@src/rq/industries';
import Profile from '@src/stores/Profile/Profile';
import { ExistingJob } from '@src/stores/Profile/models';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory, useLocation } from 'react-router-dom';
import RelatedImgContainer from '../JobModal/RelatedImgContainer';
import Description from './Description';
import AuthStore from '@src/stores/Auth';

interface JobModalViewProps {
  open: boolean;
  onClose: () => void;
  job?: ExistingJob;
  profile: Profile;
  auth: AuthStore;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: 8,
  },
  info: {
    display: 'flex',
    justifyContent: 'center',
    gap: 8,
    alignItems: 'center',
    '& p': {
      margin: 0,
    },
  },
  modal: {},
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  sectionTitle: {
    fontSize: '16px',
    fontWeight: 500,
  },
  skill: {
    borderRadius: 6,
    padding: 16,
    background: '#F7F7F7',
    fontSize: 14,
  },
  skillsContainer: {
    display: 'flex',
    gap: 8,
    flexWrap: 'wrap',
  },
  openRelatedProject: {
    color: '#62646A',
    fontSize: 15,
    cursor: 'pointer',
    visibility: 'hidden',
    transition: 'all 0.2s',
  },
  noCover: {
    width: '16px !important',
    height: '16px !important',
    '& span': {
      width: '15px',
      height: '15px',
      display: 'flex',
    },
    '& svg': {
      width: '15px !important',
      height: '15px !important',
    },
  },
  relatedProjectContainer: {
    display: 'flex',
    gap: 16,
    padding: 16,
    border: '1px solid #EFEFF0',
    borderRadius: 12,
    marginBottom: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'all 0.2s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F7F7F7',
      '& $openRelatedProject': {
        visibility: 'visible',
      },
    },
  },
  relatedProjectWrapper: {
    display: 'flex',
    gap: 16,
  },
  relatedProjectTitle: {
    fontSize: '16px',
    fontWeight: 500,
  },
  relatedProjectCompanyContainer: {
    display: 'flex',
    gap: 8,
    marginTop: 8,
    color: '#35373A',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      minWidth: 680,
      borderRadius: '16px !important',
      '& > div': {
        padding: 40,
      },
    },
  },
});

const JobModalView = ({
  onClose,
  open,
  job,
  profile,
  auth,
}: JobModalViewProps): JSX.Element => {
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation();

  const handleModalClose = () => {
    // remove job query param from url
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('job');
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    history.push(newUrl);
    onClose();
  };

  const { data: allIndustries } = useQueryTalentIndustries({
    query: '',
  });

  const duration = useMemo(() => {
    if (job?.startDate) {
      const startDate = format(new Date(job.startDate), 'MMMM yyyy');
      const endDate = job.endDate
        ? format(new Date(job.endDate), 'MMMM yyyy')
        : 'Present';
      return `${startDate} - ${endDate}`;
    } else {
      return null;
    }
  }, [job?.startDate, job?.endDate]);

  const hasManagedPeople = job?.hasManagedPeople;
  const hasZeroToOneExperience = job?.hasZeroToOneExperience;
  const numberOfPeopleManaged = job?.numberOfPeopleManaged;
  const userFirstName = profile?.data.firstName;

  const experienceDescription = useMemo(() => {
    if (hasManagedPeople && hasZeroToOneExperience && numberOfPeopleManaged) {
      if (numberOfPeopleManaged === '1') {
        return `${userFirstName} managed 1 person and was involved from 0 -> 1 at this job.`;
      } else {
        return `${userFirstName} managed ${numberOfPeopleManaged} people and was involved from 0 -> 1 at this job.`;
      }
    } else if (hasZeroToOneExperience) {
      return `${userFirstName} was involved from 0 -> 1 at this job.`;
    } else if (hasManagedPeople && numberOfPeopleManaged) {
      if (numberOfPeopleManaged === '1') {
        return `${userFirstName} managed 1 person at this job.`;
      } else {
        return `${userFirstName} managed ${numberOfPeopleManaged} people at this job.`;
      }
    } else {
      return null;
    }
  }, [
    hasManagedPeople,
    hasZeroToOneExperience,
    numberOfPeopleManaged,
    userFirstName,
  ]);

  const companyName = job?.companyName || job?.companyData?.name;

  return (
    <Modal
      variant="slideUp"
      isOpen={open}
      onClose={handleModalClose}
      className={styles.modal}
      shouldHideGradientStroke={true}
    >
      <div className={styles.container}>
        <div>
          <div className={styles.logoContainer}>
            <CompanyLogoFallback
              logoUrl={job?.logoURL || job?.companyData?.logoUrl}
              borderRadius={8}
              size={72}
            />
          </div>
          <h3 className={styles.title}>{job?.jobRole}</h3>
          <div className={styles.info}>
            {companyName && <p>{companyName}</p>}{' '}
            {companyName &&
              ((job?.industries?.length || 0) > 0 || duration) && (
                <span>·</span>
              )}{' '}
            {(job?.industries?.length || 0) > 0 && (
              <p>
                {' '}
                {job?.industries
                  ?.map((iid) => {
                    const industry = allIndustries?.find((i) => i.id === iid);
                    return industry?.name;
                  })
                  .join(', ')}{' '}
              </p>
            )}{' '}
            {(job?.industries?.length || 0) > 0 && duration && <span>·</span>}{' '}
            {duration && <p>{duration}</p>}{' '}
          </div>
        </div>

        {job?.descriptionHTML && (
          <Description description={job.descriptionHTML} />
        )}

        {experienceDescription && (
          <div className={styles.section}>
            <div className={styles.sectionTitle}>Experience</div>
            {experienceDescription}
          </div>
        )}

        {!!job?.applicableTalentSkills?.length && (
          <div className={styles.section}>
            <div className={styles.sectionTitle}>Skills used</div>
            <div className={styles.skillsContainer}>
              {job?.applicableTalentSkills?.map((skill) => (
                <div className={styles.skill} key={skill.talentSkillId}>
                  {skill.talentSkillName}
                </div>
              ))}
            </div>
          </div>
        )}

        {auth.withRelatedExperiences &&
          job?.relatedProjects &&
          job?.relatedProjects.length > 0 && (
            <div className={styles.section}>
              <div className={styles.sectionTitle}>Related projects</div>
              <div>
                {job.relatedProjects.map((project, index) => {
                  const projectCover =
                    project.imagesUrls?.[0] || project.imageURL || undefined;

                  const onClick = () => {
                    const projectId = project?.eid;
                    const currentPath = location.pathname;

                    if (!projectId) {
                      return;
                    }

                    const searchParams = new URLSearchParams(location.search);
                    searchParams.set('project', projectId);
                    searchParams.delete('job');
                    const newUrl = `${currentPath}?${searchParams.toString()}`;

                    history.push(newUrl);

                    onClose();
                  };

                  return (
                    <div
                      onClick={onClick}
                      key={index}
                      className={styles.relatedProjectContainer}
                    >
                      <div className={styles.relatedProjectWrapper}>
                        <RelatedImgContainer
                          projectCover={projectCover}
                          companyLogoUrl={
                            job?.logoURL || job?.companyData?.logoUrl
                          }
                          index={index}
                          noCoverIconClassName={styles.noCover}
                        />
                        <div>
                          <div className={styles.relatedProjectTitle}>
                            {project.title}
                          </div>
                          <div
                            className={styles.relatedProjectCompanyContainer}
                          >
                            <CompanyLogoFallback
                              logoUrl={
                                project.logoURL || project.companyData?.logoUrl
                              }
                              borderRadius={5}
                              size={24}
                            />
                            <div>
                              {project.companyName || project.companyData?.name}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.openRelatedProject}>Open</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
      </div>
    </Modal>
  );
};

export default JobModalView;
