import { Breakpoints, IconType, Tag, TextColors } from '@ateams/components';
import TimezoneObject from '@a_team/models/dist/TimezoneObject';
import {
  LocalHourRange,
  WorkingHoursMissionRoleSchema,
  WorkingHoursSchema,
} from '@a_team/models/dist/WorkingHoursObject';
import { beautifyTime, getCountryFromTimezoneName } from '@src/helpers/time';
import { WorkingHours } from '@src/views/Profile/Sidebar/WorkingHours';
import { toJS } from 'mobx';
import React, { ReactElement, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  getHoursFromMinutes,
  getHumanReadableTime,
} from '../../Sidebar/WorkingHours/utils';
import Section, { SectionProps } from '../partials/Section';
import UpdateProfileToast from '@src/views/Application/UpdateProfileToast';
import { DateTime } from 'luxon';

interface Props {
  title?: string;
  roleWorkingHours?: WorkingHoursMissionRoleSchema;
  userWorkingHours?: WorkingHoursSchema;
  location?: string;
  profileTimezone?: TimezoneObject;
  isApplicationWorkingHoursEdited?: boolean;
  readOnly?: boolean;
  error?: boolean;
  onWorkingHoursChange?(
    roleWorkingHours: WorkingHoursSchema,
    isDefault?: boolean,
  ): void;
  onToastClick?: (save: boolean) => void;
  guidanceMessage?: ReactElement;
  type?: SectionProps['type'];
}

const useStyles = createUseStyles({
  workingHoursContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 16,
  },
  toast: {
    marginTop: 24,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    workingHoursContainer: {
      marginTop: 0,
    },
  },
});

export const WorkingHoursOverlap = React.memo(
  ({
    title,
    roleWorkingHours,
    userWorkingHours,
    profileTimezone,
    isApplicationWorkingHoursEdited,
    readOnly,
    error,
    onWorkingHoursChange,
    onToastClick,
    guidanceMessage,
    location,
    type,
  }: Props): ReactElement => {
    const styles = useStyles();

    const [isToastShown, setShowToast] = useState<boolean | undefined>(
      undefined,
    );
    const userDaily = userWorkingHours?.daily as LocalHourRange[];
    const roleTimezoneName = roleWorkingHours?.name;
    const numberOfMinutesOverlap = roleWorkingHours?.numberOfMinutesOverlap;
    // BX-2399. this is a temp fix, as we need to rethink how we were calculating the UTC offsets in the backend.
    const userOffset = DateTime.local().setZone(userWorkingHours?.name).offset;
    const companyOffset = DateTime.local().setZone(roleTimezoneName).offset;
    const delta = companyOffset - userOffset;

    const handleChange = (
      value: WorkingHoursSchema | undefined,
      isDefault: boolean,
    ) => {
      onWorkingHoursChange && value && onWorkingHoursChange(value);
      isToastShown === undefined && !isDefault && setShowToast(true);
    };

    return (
      <Section
        iconType={type !== 'large' ? IconType.World : undefined}
        type={type}
        title={title || 'Working-hours overlap'}
        readonly={readOnly}
        error={error}
        marginTop={0}
      >
        {!readOnly && roleTimezoneName && numberOfMinutesOverlap && (
          <div>
            Company requires {getHumanReadableTime(numberOfMinutesOverlap)}{' '}
            overlap with{' '}
            {roleWorkingHours?.daily?.map((daily, index) => {
              const startTime = daily.startTime - delta;
              const endTime = daily.endTime - delta;
              return (
                <span key={`${daily.startTime}-${daily.endTime}`}>
                  {' '}
                  {beautifyTime(getHoursFromMinutes(startTime))}
                  {` `}
                  {' - '}
                  {beautifyTime(getHoursFromMinutes(endTime))}
                </span>
              );
            })}{' '}
            {getCountryFromTimezoneName(userWorkingHours?.name ?? '')} time.{' '}
          </div>
        )}
        {guidanceMessage}
        {!readOnly && type !== 'large' && (
          <div
            style={{
              fontSize: 12,
              lineHeight: '16px',
              marginTop: 4,
              marginBottom: 8,
              color: TextColors.regularLight,
            }}
          >
            If you’re open to work on different time zones, set a wider range of
            hours to increase the overlap with more potential companies.
          </div>
        )}
        <div className={styles.workingHoursContainer}>
          <div style={{ marginRight: readOnly ? 0 : 16 }}>
            My working hours range {location ? `on ${location} time` : ''}
          </div>

          {readOnly ? (
            <div style={{ marginLeft: '8px', marginRight: '8px' }}>
              {userDaily?.length > 0 && (
                <span>
                  {' from '}
                  {userDaily?.map((daily, index) => {
                    return (
                      <span key={index}>
                        {getHoursFromMinutes(daily.startTime)} to{' '}
                        {getHoursFromMinutes(daily.endTime)}{' '}
                        {index === 0 && ' or '}
                      </span>
                    );
                  })}{' '}
                  in (UTC{userWorkingHours?.utcOffset}) {userWorkingHours?.name}
                </span>
              )}{' '}
              {isApplicationWorkingHoursEdited ? (
                <Tag
                  className={styles.tagEdited}
                  style={{
                    marginLeft: '4px',
                  }}
                >
                  Edited
                </Tag>
              ) : null}
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 16,
              }}
            >
              <WorkingHours
                defaultValue={toJS(userWorkingHours)}
                defaultTimezone={profileTimezone}
                selectInputStyle={{
                  '&[class$="-control"]': {
                    backgroundColor: 'transparent',
                  },
                }}
                hideSideBar={true}
                isClearable={false}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
        {onToastClick && (
          <UpdateProfileToast
            onClose={(save) => {
              onToastClick(save);
              setShowToast(false);
            }}
            isShown={!!isToastShown}
            className={styles.toast}
          >
            <>Do you want to save these new working hours to your profile?</>
          </UpdateProfileToast>
        )}
      </Section>
    );
  },
);
