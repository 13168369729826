import {
  MissionApplyEditLocation,
  MissionControlLocation,
} from '@src/locations';

export const getTitle = (
  shouldShowRequirementsMissingWarning: boolean,
  companyName: string,
  isNewApplication: boolean,
  totalRequestedTeammates: number,
): string => {
  if (shouldShowRequirementsMissingWarning && isNewApplication) {
    return `You applied to ${companyName} mission with missing requirements.`;
  }

  if (shouldShowRequirementsMissingWarning) {
    return `Your application to ${companyName} mission is still missing requirements.`;
  }

  if (totalRequestedTeammates > 0) {
    return `Congrats, you requested to team up on ${companyName} mission.`;
  }

  if (!isNewApplication) {
    return `You edited your application to ${companyName} mission.`;
  }

  return `Congrats, you applied to ${companyName} mission.`;
};

export const getDescription = (
  shouldShowRequirementsMissingWarning: boolean,
  isNewApplication: boolean,
): string => {
  if (shouldShowRequirementsMissingWarning) {
    return `We'll review your application, but there's a low chance of acceptance.`;
  }

  if (!isNewApplication) {
    return `Our team formation specialists will review your edits soon.`;
  }

  return `You can expect to receive an update within 3 days.`;
};

export const getCtaLabel = (shouldShowRequirementsMissingWarning: boolean) => {
  return shouldShowRequirementsMissingWarning
    ? 'Edit application'
    : 'View application';
};

export const getCtaLink = (
  shouldShowRequirementsMissingWarning: boolean,
  mid: string,
  rid: string,
  aid: string,
) => {
  return shouldShowRequirementsMissingWarning
    ? MissionApplyEditLocation(mid, rid, aid)
    : MissionControlLocation('applied');
};
