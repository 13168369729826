import React from 'react';
import { ProposalTeamMember } from '@src/stores/Missions/Proposal';
import UserAvatar from '@src/components/UserAvatar';
import SectionHeading from '@src/components/SectionHeading';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import {
  ProposalCandidateRecommendation,
  ProposalDataCurrency,
} from '@a_team/models/dist/ProposalObject';
import { Checkbox, Icon, IconType, Spacing } from '@ateams/components';
import { ProfileLocation } from '@src/locations';
import { Link } from 'react-router-dom';
import { getProposalDataCurrencySymbol } from './utils';

interface Props {
  members: ProposalTeamMember[];
  onIsNarrativeProposalChange: (isNarrativeProposal: boolean) => void;
  isNarrativeProposal: boolean;
  onClickOnClearTeam?: () => void;
  narrativeProposalDisabled?: boolean;
  currency: ProposalDataCurrency;
  isShowingRatesForAllBuilders?: boolean;
  onIsShowingRatesForAllBuildersChange?: (
    isShowingRatesForAllBuilders: boolean,
  ) => void;
}

const useStyles = createUseStyles({
  root: {
    position: 'sticky',
    top: 80,
  },
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: 32,
    paddingBottom: 0,
    background: '#efefef',
    border: '1px solid #C0C0C0',
    borderRadius: 8,
    minHeight: 200,
    marginTop: Spacing.large,
    position: 'relative',
  },
  boxText: {
    width: '100%',
    height: '100%',
    alignSelf: 'center',
    textAlign: 'center',
    color: '#C0C0C0',
    paddingBottom: 32,
  },
  member: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '0 0 50%',
    textAlign: 'center',
    marginBottom: 32,
  },
  clearButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    background: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    outline: 'none',
  },
  showRatesCheckbox: {
    marginTop: Spacing.small,
  },
});

export const TeamPreview = observer((props: Props) => {
  const styles = useStyles();
  const sortedMembers = props.members
    .slice()
    .sort((a, b) =>
      (a.roleStatus && b.roleStatus && a.roleStatus < b.roleStatus) ||
      !b.roleStatus
        ? 1
        : -1,
    );

  return (
    <div className={styles.root}>
      <SectionHeading isFirst style={{ marginBottom: 8 }}>
        Team Preview
      </SectionHeading>
      <p style={{ marginTop: 0, marginBottom: Spacing.large }}>
        This is how the team will be presented in the proposal
      </p>
      <Checkbox
        disabled={props.narrativeProposalDisabled}
        onChange={() => {
          props.onIsNarrativeProposalChange &&
            props.onIsNarrativeProposalChange(!props.isNarrativeProposal);
        }}
        checked={props.isNarrativeProposal}
        label="Make this a team narrative proposal"
        margin="none"
      />
      <Checkbox
        className={styles.showRatesCheckbox}
        onChange={() => {
          props.onIsShowingRatesForAllBuildersChange &&
            props.onIsShowingRatesForAllBuildersChange(
              !props.isShowingRatesForAllBuilders,
            );
        }}
        checked={props.isShowingRatesForAllBuilders}
        label="Show monthly and hourly rates for all builders"
        margin="none"
      />

      <div className={styles.box}>
        <button
          className={styles.clearButton}
          onClick={() => props.onClickOnClearTeam && props.onClickOnClearTeam()}
        >
          <Icon type={IconType.CloseGray} />
        </button>
        {props.members.length === 0 && (
          <div className={styles.boxText}>Preview</div>
        )}
        {sortedMembers.map((member) => {
          return (
            <div className={styles.member} key={member.aid}>
              {member.roleStatus ===
                ProposalCandidateRecommendation.Alternative && (
                <div
                  style={{
                    fontFamily: 'serif',
                    marginBottom: 16,
                    fontSize: 18,
                  }}
                >
                  Alternative {member.roleCategory?.title}
                </div>
              )}
              <Link target="_blank" to={ProfileLocation(member.username || '')}>
                <UserAvatar
                  src={member.profilePictureURL}
                  size={80}
                  containerStyle={{ marginBottom: 8 }}
                />
              </Link>
              <div style={{ fontWeight: 500, fontSize: 16 }}>
                {member.fullName}
              </div>
              <div style={{ fontSize: 12 }}>
                {member.isTeamLead && 'Team Lead / '}
                {member.roleCategory?.title}
              </div>
              {member.hourlyRate && member.showHourlyRate ? (
                <div style={{ fontWeight: 'bold' }}>
                  {getProposalDataCurrencySymbol(props.currency)}
                  {member.hourlyRate ? member.hourlyRate.toFixed(2) : 0}/hour
                </div>
              ) : null}
              {member.monthlyRate && member.showMonthlyRate ? (
                <div style={{ fontWeight: 'bold' }}>
                  {getProposalDataCurrencySymbol(props.currency)}
                  {member.monthlyRate.toFixed()}/month
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
});
