import { useMutationSetOptOutClientDiscovery } from '@src/rq/profile';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React from 'react';
import Visibility from './Visibility';

const ClientVisibility = (): JSX.Element => {
  const { auth } = useStores();

  const { mutate } = useMutationSetOptOutClientDiscovery({
    onSuccess: (optedOut) => {
      // makes sure that the state is updated after the request is sent to be in sync with the server
      auth.updateOptedOutOfClientDiscovery(optedOut);
    },
  });

  const handleToggle = () => {
    mutate({
      optOut: !auth.currentUser?.optedOutOfClientDiscovery,
    });
    // updates state immediately to avoid waiting for the request to finish
    auth.updateOptedOutOfClientDiscovery(
      !auth.currentUser?.optedOutOfClientDiscovery,
    );
  };

  return (
    <Visibility
      title="Company Visibility"
      description="Companies can search, discover and star eligible builders for their upcoming missions. You can choose to not be shown in company discovery or in Team Formation AI by disabling the toggle."
      checked={!auth.currentUser?.optedOutOfClientDiscovery}
      handleToggle={handleToggle}
    />
  );
};

export default observer(ClientVisibility);
