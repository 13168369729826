import { EnrichedTeamNarrativesResponse } from '@a_team/models/dist/TeamGraphObject';
import {
  Checkbox,
  Icon,
  IconType,
  Spacing,
  TextColors,
} from '@ateams/components';
import Loader from '@src/components/Loader';
import SectionHeading from '@src/components/SectionHeading';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { NarrativeItem } from '../Narratives/NarrativeItem';

interface Props {
  enrichedTeamNarratives?: EnrichedTeamNarrativesResponse;
  teamNarrativeTypesSelected?: string[];
  onTeamNarrativeTypesSelectedChange?: (
    teamNarrativeTypesSelected: string[],
  ) => void;
  isLoading: boolean;
  onClickOnCopyTeamNarrativeData: () => void;
}

const useStyles = createUseStyles({
  root: {
    marginTop: Spacing.large,
  },
  textMuted: {
    color: TextColors.regularLight,
  },
  narrativeContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  narrativeCheckbox: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  narrativeCheckboxLabel: {
    width: '100%',
  },
  narrativeCheckboxContainer: {
    marginTop: 3,
  },
  copyIcon: {
    marginLeft: Spacing.small,
  },
});

export const TeamNarrative = (props: Props) => {
  const styles = useStyles();
  const toggleNarrativeSelection = (narrativeType: string) => {
    if (props.teamNarrativeTypesSelected?.includes(narrativeType)) {
      props.onTeamNarrativeTypesSelectedChange?.(
        props.teamNarrativeTypesSelected?.filter((t) => t !== narrativeType),
      );
    } else {
      props.onTeamNarrativeTypesSelectedChange?.([
        ...(props.teamNarrativeTypesSelected || []),
        narrativeType,
      ]);
    }
  };
  return (
    <div className={styles.root}>
      <SectionHeading isFirst style={{ marginBottom: 8 }}>
        Team Narrative
        <Icon
          className={styles.copyIcon}
          size="exact"
          onClick={() => {
            props.onClickOnCopyTeamNarrativeData &&
              props.onClickOnCopyTeamNarrativeData();
          }}
          type={IconType.Copy}
        ></Icon>
      </SectionHeading>
      {props.isLoading ? (
        <Loader></Loader>
      ) : (
        <>
          <p style={{ marginTop: 0, marginBottom: Spacing.large }}>
            Select up to 3 narratives to include in the proposal
          </p>
          <p className={styles.textMuted}>Team Narratives</p>
          {props.enrichedTeamNarratives?.missionRecommendations && (
            <Checkbox
              onChange={() =>
                toggleNarrativeSelection('missionRecommendations')
              }
              checked={props.teamNarrativeTypesSelected?.includes(
                'missionRecommendations',
              )}
              className={styles.narrativeCheckbox}
              labelClassName={styles.narrativeCheckboxLabel}
              checkboxContainerClassName={styles.narrativeCheckboxContainer}
              label={
                <div className={styles.narrativeContent}>
                  <NarrativeItem
                    narrativeType={'missionRecommendations'}
                    overlapUsers={
                      props.enrichedTeamNarratives?.missionRecommendations
                        .overlapUsers
                    }
                    overlapText={
                      props.enrichedTeamNarratives?.missionRecommendations
                        .overlapText
                    }
                  />
                </div>
              }
              margin={'top'}
            />
          )}
          {props.enrichedTeamNarratives?.missions && (
            <Checkbox
              onChange={() => toggleNarrativeSelection('missions')}
              checked={props.teamNarrativeTypesSelected?.includes('missions')}
              className={styles.narrativeCheckbox}
              labelClassName={styles.narrativeCheckboxLabel}
              checkboxContainerClassName={styles.narrativeCheckboxContainer}
              label={
                <div className={styles.narrativeContent}>
                  <NarrativeItem
                    narrativeType={'missions'}
                    overlapUsers={
                      props.enrichedTeamNarratives?.missions.overlapUsers
                    }
                    overlapText={
                      props.enrichedTeamNarratives?.missions.overlapText
                    }
                  />
                </div>
              }
              margin={'top'}
            />
          )}
          {props.enrichedTeamNarratives?.projects && (
            <Checkbox
              className={styles.narrativeCheckbox}
              onChange={() => toggleNarrativeSelection('projects')}
              checked={props.teamNarrativeTypesSelected?.includes('projects')}
              labelClassName={styles.narrativeCheckboxLabel}
              checkboxContainerClassName={styles.narrativeCheckboxContainer}
              label={
                <span className={styles.narrativeContent}>
                  <NarrativeItem
                    narrativeType={'projects'}
                    overlapUsers={
                      props.enrichedTeamNarratives?.projects.overlapUsers
                    }
                    overlapText={
                      props.enrichedTeamNarratives?.projects.overlapText
                    }
                  />
                </span>
              }
              margin={'top'}
            />
          )}
          {props.enrichedTeamNarratives?.companies && (
            <Checkbox
              className={styles.narrativeCheckbox}
              onChange={() => toggleNarrativeSelection('companies')}
              checked={props.teamNarrativeTypesSelected?.includes('companies')}
              labelClassName={styles.narrativeCheckboxLabel}
              checkboxContainerClassName={styles.narrativeCheckboxContainer}
              label={
                <span className={styles.narrativeContent}>
                  <NarrativeItem
                    narrativeType={'companies'}
                    overlapUsers={
                      props.enrichedTeamNarratives?.companies.overlapUsers
                    }
                    overlapText={
                      props.enrichedTeamNarratives?.companies.overlapText
                    }
                  />
                </span>
              }
              margin={'top'}
              key={'companies'}
            />
          )}
          {props.enrichedTeamNarratives?.invitations && (
            <Checkbox
              className={styles.narrativeCheckbox}
              onChange={() => toggleNarrativeSelection('invitations')}
              checked={props.teamNarrativeTypesSelected?.includes(
                'invitations',
              )}
              checkboxContainerClassName={styles.narrativeCheckboxContainer}
              labelClassName={styles.narrativeCheckboxLabel}
              label={
                <span className={styles.narrativeContent}>
                  <NarrativeItem
                    narrativeType={'invitations'}
                    overlapUsers={
                      props.enrichedTeamNarratives?.invitations.overlapUsers
                    }
                    overlapText={
                      props.enrichedTeamNarratives?.invitations.overlapText
                    }
                  />
                </span>
              }
              margin={'top'}
            />
          )}
        </>
      )}
    </div>
  );
};
