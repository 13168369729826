import React, { CSSProperties, ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import Icon, { IconType } from '../Icon';
import cx from 'classnames';
import Colors, { ColorName, TextColors } from '../theme/colors';

export interface BadgeProps {
  text: string;
  backgroundColor: ColorName;
  color: ColorName;
  style?: CSSProperties;
  className?: string;
  icon?: IconType;
}

const defaultProps = {
  text: 'Recommended',
  backgroundColor: 'infoLight',
  color: 'infoDark',
  icon: IconType.RecommendedBadge,
};

const useStyles = createUseStyles({
  Badge: (props: BadgeProps) => ({
    padding: '5px 10px',
    borderRadius: '8px',
    backgroundColor: props.backgroundColor
      ? Colors[props.backgroundColor]
      : Colors.infoLight,
    color: props.color ? TextColors[props.color] : TextColors.infoDark,
    display: 'flex',
    alignItems: 'center',
    gap: '0.4em',
  }),
});

const Badge = (props: BadgeProps): ReactElement => {
  const styles = useStyles(props);
  const { style, text, className, icon } = props;

  return (
    <div className={cx(styles.Badge, className)} style={style}>
      {icon && <Icon type={icon} size={'exact'} />}
      {text}
    </div>
  );
};

Badge.defaultProps = defaultProps;
export default Badge;
