import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { Breakpoints, Checkbox, Colors } from '@ateams/components';
import ControlButtons from './common/ControlButtons';
import { TeamPulseSectionsEnum } from './TeamPulseModal';

interface WhyTheseBuildersFormProps {
  setSection: (section: string) => void;
  setWhyTheseBuilders: (value: WhyTheseBuildersReasons) => void;
  setUserReviewSection: (section: string) => void;
  missionTitle: string;
  needsToReviewUsers?: boolean;
  initialState?: WhyTheseBuildersReasons;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    height: '100%',
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'start',
  },
  desc: {
    textAlign: 'start',
    color: '#707275',
    marginTop: 8,
  },
  checkboxContainer: {
    border: `1px solid ${Colors.backgroundDark}`,
    borderRadius: 8,
    width: '100%',
  },
  checkboxWrapper: {
    height: 'fit-content',
    display: 'flex',
    gap: 10,
    padding: '15px 10px',
  },
  checkbox: {
    margin: 0,
  },
  otherReasonInput: {
    display: 'block',
    borderRight: 'none',
    borderLeft: '2px solid #EBECEF;',
    borderTop: 'none',
    borderBottom: 'none',
    paddingLeft: 12,
    marginLeft: 40,
    marginBottom: 16,
  },
  wrapper: {
    padding: 20,
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    wrapper: {
      padding: 0,
    },
  },
});

enum WhyTheseBuildersReasonsEnum {
  WorkedTogether = 'We worked together in the past',
  WorkedTogetherWithSameTech = 'We worked with the same technologies in the past',
  FrequentlyCollaborate = 'We frequently collaborate',
  AdmireTheirWork = 'I admire their work',
  FollowedTheirContributionsInOtherProjects = `I’ve been following their contributions to other projects`,
  Other = 'Other',
}

export type WhyTheseBuildersReasons = {
  [key in WhyTheseBuildersReasonsEnum]?: true | string;
};

const WhyTheseBuildersForm = ({
  setSection,
  missionTitle,
  setUserReviewSection,
  setWhyTheseBuilders,
  needsToReviewUsers,
  initialState,
}: WhyTheseBuildersFormProps): JSX.Element => {
  const styles = useStyles();

  const [reasons, setReasons] = React.useState<WhyTheseBuildersReasons>(
    initialState || {},
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name } = e.target;

    // if checked remove the reason from the state
    const newReasons = { ...reasons };
    if (newReasons[name as WhyTheseBuildersReasonsEnum]) {
      delete newReasons[name as WhyTheseBuildersReasonsEnum];
    } else {
      // if it's Other set the value to empty string
      if (name === WhyTheseBuildersReasonsEnum.Other) {
        newReasons[name as WhyTheseBuildersReasonsEnum] = '';
      } else {
        newReasons[name as WhyTheseBuildersReasonsEnum] = true;
      }
    }
    setReasons(newReasons);
  };

  const onSkip = (): void => {
    if (needsToReviewUsers) {
      setUserReviewSection(TeamPulseSectionsEnum.TeamMemberRating);
    } else {
      setSection(TeamPulseSectionsEnum.AllDone);
    }
  };

  const onBack = (): void => {
    setSection(TeamPulseSectionsEnum.AnyBuildersInMind);
  };

  const onContinue = (): void => {
    setWhyTheseBuilders(reasons);

    if (needsToReviewUsers) {
      setUserReviewSection(TeamPulseSectionsEnum.TeamMemberRating);
    } else {
      setSection(TeamPulseSectionsEnum.AllDone);
    }
  };

  const isOtherChecked = useMemo(() => {
    return Object.keys(reasons).includes(WhyTheseBuildersReasonsEnum.Other);
  }, [reasons[WhyTheseBuildersReasonsEnum.Other]]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>
          Thanks for the suggestion. Can you tell us why you chose these
          builders?
        </h4>
        <p className={styles.desc}>
          This question is optional and is not shared with the {missionTitle}.
        </p>
        {/* <ReferBadge /> */}
        <div className={styles.checkboxContainer}>
          <div
            className={styles.checkboxWrapper}
            style={{ borderBottom: '1px solid #EFEFF0' }}
          >
            <Checkbox
              id={WhyTheseBuildersReasonsEnum.WorkedTogether}
              onChange={handleChange}
              name={WhyTheseBuildersReasonsEnum.WorkedTogether}
              className={styles.checkbox}
              checked={!!reasons[WhyTheseBuildersReasonsEnum.WorkedTogether]}
            />
            <label
              data-testing-id="worked-together-checkbox"
              htmlFor={WhyTheseBuildersReasonsEnum.WorkedTogether}
            >
              We worked together in the past
            </label>
          </div>
          <div
            className={styles.checkboxWrapper}
            style={{ borderBottom: '1px solid #EFEFF0' }}
          >
            <Checkbox
              id={WhyTheseBuildersReasonsEnum.WorkedTogetherWithSameTech}
              onChange={handleChange}
              name={WhyTheseBuildersReasonsEnum.WorkedTogetherWithSameTech}
              className={styles.checkbox}
              checked={
                !!reasons[
                  WhyTheseBuildersReasonsEnum.WorkedTogetherWithSameTech
                ]
              }
            />
            <label
              htmlFor={WhyTheseBuildersReasonsEnum.WorkedTogetherWithSameTech}
            >
              We worked with the same technologies in the past
            </label>
          </div>
          <div
            className={styles.checkboxWrapper}
            style={{ borderBottom: '1px solid #EFEFF0' }}
          >
            <Checkbox
              id={WhyTheseBuildersReasonsEnum.FrequentlyCollaborate}
              onChange={handleChange}
              name={WhyTheseBuildersReasonsEnum.FrequentlyCollaborate}
              className={styles.checkbox}
              checked={
                !!reasons[WhyTheseBuildersReasonsEnum.FrequentlyCollaborate]
              }
            />
            <label htmlFor={WhyTheseBuildersReasonsEnum.FrequentlyCollaborate}>
              We frequently collaborate
            </label>
          </div>
          <div
            className={styles.checkboxWrapper}
            style={{ borderBottom: '1px solid #EFEFF0' }}
          >
            <Checkbox
              id={WhyTheseBuildersReasonsEnum.AdmireTheirWork}
              onChange={handleChange}
              name={WhyTheseBuildersReasonsEnum.AdmireTheirWork}
              className={styles.checkbox}
              checked={!!reasons[WhyTheseBuildersReasonsEnum.AdmireTheirWork]}
            />
            <label htmlFor={WhyTheseBuildersReasonsEnum.AdmireTheirWork}>
              I admire their work
            </label>
          </div>
          <div
            className={styles.checkboxWrapper}
            style={{ borderBottom: '1px solid #EFEFF0' }}
          >
            <Checkbox
              id={
                WhyTheseBuildersReasonsEnum.FollowedTheirContributionsInOtherProjects
              }
              onChange={handleChange}
              name={
                WhyTheseBuildersReasonsEnum.FollowedTheirContributionsInOtherProjects
              }
              className={styles.checkbox}
              checked={
                !!reasons[
                  WhyTheseBuildersReasonsEnum
                    .FollowedTheirContributionsInOtherProjects
                ]
              }
            />
            <label
              htmlFor={
                WhyTheseBuildersReasonsEnum.FollowedTheirContributionsInOtherProjects
              }
            >
              I’ve been following their contributions to other projects
            </label>
          </div>
          <div>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                id={WhyTheseBuildersReasonsEnum.Other}
                onChange={handleChange}
                name={WhyTheseBuildersReasonsEnum.Other}
                className={styles.checkbox}
                checked={
                  // if key exists then it is checked and if it's not true then it's Other
                  isOtherChecked
                }
              />
              <label
                data-testing-id="other-checkbox"
                htmlFor={WhyTheseBuildersReasonsEnum.Other}
              >
                Other
              </label>
            </div>
            {isOtherChecked && (
              <input
                data-testing-id="other-reason-input"
                type="text"
                className={styles.otherReasonInput}
                placeholder="Please enter the reason"
                value={reasons[WhyTheseBuildersReasonsEnum.Other] as string}
                onChange={(e) =>
                  setReasons({
                    ...reasons,
                    [WhyTheseBuildersReasonsEnum.Other]: e.target.value,
                  })
                }
              />
            )}
          </div>
        </div>
      </div>

      <ControlButtons onSkip={onSkip} onBack={onBack} onContinue={onContinue} />
    </div>
  );
};

export default WhyTheseBuildersForm;
