/**
 * Mongo ID of a Company.
 */
import { AccountId } from './Account';
import { TalentIndustryId } from './TalentIndustry';
import { DateISOString } from './misc';

export type CompanyId = string;

/**
 * CompanyTalentIndustry is an industry that a Company is associated with.
 */
export interface CompanyTalentIndustry {
  /**
   * Id of the talent industry.
   */
  id: TalentIndustryId;

  /**
   * Name of the talent industry.
   */
  name: string;
}

/**
 * Company represents an entity or organisation that employs builders.
 */
export interface Company {
  /**
   * Id of the Company.
   */
  id: CompanyId;

  /**
   * Name of the Company.
   */
  name: string;

  /**
   * Status of the Company record.
   */
  status: CompanyStatus;

  /**
   * URL to the logo of the company.
   */
  logoUrl?: string;

  /**
   * Industries associated with this company.
   */
  talentIndustryIds: TalentIndustryId[];
}

/**
 * CompanyStatus represents whether this company record has been approved
 *  by a member of staff as valid, good data.
 *  - unverified: This item has not been validated. It may still appear on users profiles.
 *  - verified: This item has been validated.
 *  - denied: This item has been viewed and is not suitable to show on profiles.
 */
export enum CompanyStatus {
  Unverified = 'Unverified',
  Verified = 'Verified',
  Denied = 'Denied',
}

export enum CompanyStage {
  VC = 'VC',
  Enterprise = 'Enterprise',
}

export enum EmployeeRange {
  UpToTen = '1-10',
  UpToFifty = '11-50',
  UpToOneHundred = '51-100',
  UpToTwoHundred = '101-200',
  UpToFiveHundred = '201-500',
  UpToOneThousand = '501-1000',
  UpToFiveThousand = '1001-5000',
  OverFiveThousand = '5001+',
}

export enum FundingRange {
  UpTo50k = '0-50000',
  UpTo500k = '50001-500000',
  UpTo1m = '500001-1M',
  UpTo5m = '1M-5M',
  UpTo10m = '5M-10M',
  UpTo25m = '10M-25M',
  UpTo50m = '25M-50M',
  UpTo100m = '50M-100M',
  UpTo500m = '100M-500M',
  UpTo1b = '500M-1B',
  Over1b = '1B+',
  NA = 'N/A',
}

export enum RevenueRange {
  UpTo100k = '0-100000',
  UpTo500k = '100001-500000',
  UpTo1m = '500001-1M',
  UpTo5m = '1M-5M',
  UpTo10m = '5M-10M',
  UpTo50m = '10M-50M',
  UpTo100m = '50M-100M',
  UpTo500m = '100M-500M',
  UpTo1b = '500M-1B',
  UpTo5b = '1B-5B',
  Over5b = '5B+',
}

export interface EnrichableCompany {
  cid: CompanyId;
  name: string;
  url: string;
  keyword: string;
  createdAt: DateISOString;
  updatedAt: DateISOString;
  clientWorkspaces?: AccountId[];
  verified?: boolean;
  logoUrl?: string;
  industries?: TalentIndustryId[];
}
