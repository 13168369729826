import {
  Spacing,
  Colors,
  BorderRadius,
  FontSizes,
  FontWeights,
} from '@ateams/components';
import { FieldCheckbox } from '../general/FieldCheckbox';
import { FieldTextarea } from '../general/FieldTextarea';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useFormContext } from 'react-hook-form';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    backgroundColor: Colors.backgroundLight,
    borderRadius: BorderRadius.xLarge,
    padding: Spacing.medium,
  },
  scaleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
  },
  checkbox: {
    marginTop: Spacing.xsmall,
  },
  checkboxText: {
    fontSize: FontSizes.small,
    fontWeight: FontWeights.medium,
    color: Colors.dark,
  },
});

interface MostProudOfSectionProps {
  isViewMode: boolean;
}

export const MostProudOfSection = ({ isViewMode }: MostProudOfSectionProps) => {
  const styles = useStyles();
  const {
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const mostProudOf = watch('mostProudOf');
  const workOnProductThatScaled = watch('workOnProductThatScaled');
  const scaleNotes = watch('scaleNotes');

  const handleMostProudOfChange = (value: string) => {
    setValue('mostProudOf', value);
  };

  const handleWorkOnProductThatScaledChange = (value: boolean) => {
    setValue('workOnProductThatScaled', value);
  };

  const handleScaleNotesChange = (value: string) => {
    setValue('scaleNotes', value);
  };

  return (
    <div className={styles.container}>
      <FieldTextarea
        label="What is the builder most proud of?"
        placeholder="E.g., Helped mentor junior employees..."
        value={mostProudOf ?? ''}
        onChange={handleMostProudOfChange}
        minLength={40}
        error={errors.mostProudOf?.message as string}
        name="mostProudOf"
        clearErrors={clearErrors}
        disabled={isViewMode}
      />
      <div className={styles.scaleContainer}>
        <FieldCheckbox
          text="Did this builder work on a product that scaled?"
          textClassName={styles.checkboxText}
          value={workOnProductThatScaled}
          onChange={handleWorkOnProductThatScaledChange}
          className={styles.checkbox}
          disabled={isViewMode}
        />
        {workOnProductThatScaled && (
          <FieldTextarea
            placeholder='What is the scale? Ex. "saved $10M a year"'
            value={scaleNotes ?? ''}
            onChange={handleScaleNotesChange}
            minLength={20}
            error={errors.scaleNotes?.message as string}
            name="scaleNotes"
            clearErrors={clearErrors}
            disabled={isViewMode}
          />
        )}
      </div>
    </div>
  );
};
