import { InvoiceStatus } from '@a_team/models/dist/InvoiceObject';
import { DateISOString } from '@a_team/models/dist/misc';
import { MissionPaymentCycleStatus } from '@a_team/models/dist/MissionPaymentCycleObject';
import TimesheetObject from '@a_team/models/dist/TimesheetObject';
import MissionPaymentCycle from '@src/stores/Missions/MissionPaymentCycle';
import { checkIfRowIsValid } from '../TimeTracking/Table/utils';
import { TimeEntry } from '../TimeTracking/Table/TanstackTable';

const NUM_DAYS_PAYMENT_CYCLES_CAN_BE_RE_OPENED = 180;

const daysNotOlderThan = (
  dateToCheck: DateISOString,
  numDays: number,
): boolean => {
  const now = new Date();
  return (
    new Date(dateToCheck) >
    new Date(new Date().setDate(now.getDate() - numDays))
  );
};

export const reOpenPaymentCycleModalText = (
  selectedPaymentCycle: MissionPaymentCycle,
) => ({
  title: `Re-open payment cycle for ${selectedPaymentCycle.formattedStartDate}-${selectedPaymentCycle.formattedEndDate}`,
  description: `Are you sure? This will cancel all existing payment cycle invoices for the client and builders. A voided invoice email will also be sent to the client.`,
});

// Conditions must be met:
// 1) Invoice status does not exist, or else the status is canceled or created
// 2) The mission payment cycle is closed
// 3) the payment cycle start date is is no more than  days agi
export const canReopenPaymentCycle = (
  selectedPaymentCycle: MissionPaymentCycle,
) =>
  (!selectedPaymentCycle?.invoice?.status ||
    [InvoiceStatus.Canceled, InvoiceStatus.Created].includes(
      selectedPaymentCycle?.invoice?.status as InvoiceStatus,
    )) &&
  selectedPaymentCycle?.status === MissionPaymentCycleStatus.Closed &&
  daysNotOlderThan(
    selectedPaymentCycle?.data?.startDate,
    NUM_DAYS_PAYMENT_CYCLES_CAN_BE_RE_OPENED,
  );

export const isTimesheetMissingData = (
  timesheet: TimesheetObject,
  isFullTimeRetainer: boolean,
) => {
  for (const record of timesheet.records) {
    const entry: TimeEntry = {
      key: record.key,
      date: new Date(record.date).getTime(),
      time: record.minutes,
      type: record.type || null,
      task: record.details,
      hasErrors: false,
      initiativeIds: record.initiativeIds ?? [],
    };
    if (!checkIfRowIsValid(entry, isFullTimeRetainer)) {
      return true;
    }
  }
  return false;
};
