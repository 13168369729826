import {
  Breakpoints,
  CollapsibleContainer,
  FontSizes,
  FontWeights,
  Icon,
  IconType,
  InputLabel,
  Select,
  Spacing,
} from '@ateams/components';
import { Button } from '@a_team/ui-components';
import {
  AdminMissionApplicationObject,
  MissionApplicationLowCompetitivenessReason,
  MissionApplicationRejectionReason,
  MissionApplicationReviewStatusNotSelected,
  MissionApplicationReviewStatusOpportunityToUpdate,
  MissionApplicationReviewStatusOther,
  MissionApplicationReviewStatusWaitlisted,
  ProposedMethod,
} from '@a_team/models/dist/MissionApplicationObject';
import MissionRole, {
  MissionAdminRole,
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import ApplicantPill from '@src/components/ApplicantPill';
import {
  ApplicantsSortByOptions,
  getApplicationSelectedUnion,
} from '@src/helpers/applications';
import { onApplicationPillBulkMenuClick } from '@src/helpers/missions';
import { useStores } from '@src/stores';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { BulkMissionApplicationStatusUpdateItem } from '@ateams/api/dist/endpoints/Missions';
import ItemsToUpdateSummary from './ItemsToUpdateSummary';
import { cloneDeep } from 'lodash/fp';
import NavigationPrompt from '@src/components/NavigationPrompt';
import { useHistory } from 'react-router-dom';

interface FilteredApplicationsProps {
  applicantsSortBy: ApplicantsSortByOptions;
  setApplicantsSortBy: React.Dispatch<
    React.SetStateAction<ApplicantsSortByOptions>
  >;
  filteredApplications: AdminMissionApplicationObject[] | null | undefined;
  role: MissionRole | MissionAdminRole;
  applications?: AdminMissionApplicationObject[] | null;
  onApplicationClick?: (application: AdminMissionApplicationObject) => void;
  showMoreButton?: ReactNode;
}

export const ApplicantsSortByLabels: Record<ApplicantsSortByOptions, string> = {
  [ApplicantsSortByOptions.ApplicationStatus]: 'Application Status',
  [ApplicantsSortByOptions.DateOfSubmission]: 'Date of Submission',
};

const APPLICANTS_SORT_BY_OPTIONS = Object.entries(ApplicantsSortByLabels).map(
  ([key, label]) => ({ value: key, label }),
);

const useStyles = createUseStyles({
  collapsibleContent: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  applicantsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.xsmall,
  },
  starredContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
    alignItems: 'center',
  },
  starredIcon: {
    width: 24,
    height: 24,
  },
  applicantsTitle: {
    paddingTop: Spacing.xsmall,
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.semiBold,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    applicantsSortByContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&> label': {
        marginBottom: 0,
        marginRight: 8,
      },
    },
  },
});

function FilteredApplications({
  applicantsSortBy,
  setApplicantsSortBy,
  filteredApplications,
  role,
  applications,
  onApplicationClick,
  showMoreButton,
}: FilteredApplicationsProps) {
  const stores = useStores();
  const { auth, missions } = stores;
  const { currentMission } = missions;
  const [itemsToUpdate, setItemsToUpdate] = useState<
    BulkMissionApplicationStatusUpdateItem[]
  >([]);

  const history = useHistory();

  const [initFilteredApplications, setInitFilteredApplications] = useState<
    AdminMissionApplicationObject[] | null | undefined
  >(cloneDeep(filteredApplications));

  const styles = useStyles();
  const [selectedApplicants, setSelectedApplicants] = useState<string[]>([]);

  const unionLowCompetitiveness = useMemo(() => {
    return getApplicationSelectedUnion<MissionApplicationLowCompetitivenessReason>(
      selectedApplicants,
      filteredApplications,
      (a) => a.lowCompetitiveness,
    );
  }, [selectedApplicants, filteredApplications]);

  const starredApplications = useMemo(() => {
    return filteredApplications?.filter((a) => a.starred) ?? [];
  }, [filteredApplications]);

  const regularApplications = useMemo(() => {
    return filteredApplications?.filter((a) => !a.starred) ?? [];
  }, [filteredApplications]);

  useEffect(() => {
    // This function will be triggered when the user tries to leave the page
    const warnOnClose = (e: BeforeUnloadEvent) => {
      if (itemsToUpdate.length > 0) {
        e.preventDefault();
        e.returnValue =
          'You have unsaved changes! Are you sure you want to leave?';
        return e.returnValue;
      }
    };

    // Attach the event if there are items to update
    if (itemsToUpdate.length > 0) {
      window.addEventListener('beforeunload', warnOnClose);
    }

    // Clean up the event listener
    return () => window.removeEventListener('beforeunload', warnOnClose);
  }, [itemsToUpdate.length]);

  useEffect(() => {
    if (currentMission) {
      currentMission.setSortedApplicationsByRoleId(
        role.rid,
        filteredApplications ?? [],
      );
    }
  }, [filteredApplications, role.rid]);

  const unionRejectionReason = useMemo(() => {
    return getApplicationSelectedUnion<MissionApplicationRejectionReason>(
      selectedApplicants,
      filteredApplications,
      (a) => a.rejectionReason,
    );
  }, [selectedApplicants, filteredApplications]);

  const unionNotSelected = useMemo(() => {
    return getApplicationSelectedUnion<MissionApplicationReviewStatusNotSelected>(
      selectedApplicants,
      filteredApplications,
      (a) => a.reviewStatus?.notSelected,
    );
  }, [selectedApplicants, filteredApplications]);

  const unionOpportunityToUpdate = useMemo(() => {
    return getApplicationSelectedUnion<MissionApplicationReviewStatusOpportunityToUpdate>(
      selectedApplicants,
      filteredApplications,
      (a) => a.reviewStatus?.opportunityToUpdate,
    );
  }, [selectedApplicants, filteredApplications]);

  const unionWaitlisted = useMemo(() => {
    return getApplicationSelectedUnion<MissionApplicationReviewStatusWaitlisted>(
      selectedApplicants,
      filteredApplications,
      (a) => a.reviewStatus?.waitlisted,
    );
  }, [selectedApplicants, filteredApplications]);

  const proposalInterviews = useMemo(() => {
    return (
      filteredApplications?.filter(
        (a) => selectedApplicants.includes(a.aid) && a.proposalInterviewing,
      )?.length || 0
    );
  }, [selectedApplicants, filteredApplications]);

  const unionOther = useMemo(() => {
    return getApplicationSelectedUnion<
      MissionApplicationReviewStatusOther | ProposedMethod
    >(selectedApplicants, filteredApplications, (a) => [
      ...(a.reviewStatus?.other ?? []),
    ]);
  }, [selectedApplicants, filteredApplications]);

  const setSelected = (aid: string, newSelected: boolean) => {
    if (newSelected) {
      if (!selectedApplicants.includes(aid)) {
        setSelectedApplicants([...selectedApplicants, aid]);
      }
    } else {
      setSelectedApplicants(selectedApplicants.filter((a) => a !== aid));
    }
  };

  const onApplicationClickInternal = (
    application: AdminMissionApplicationObject,
  ) => {
    if (!filteredApplications) return;
    onApplicationClick?.(application);
  };

  const getApplicantsList = (
    filteredApplications: AdminMissionApplicationObject[] | null | undefined,
  ) => {
    return filteredApplications?.map((application) => {
      return (
        <ApplicantPill
          key={application.aid}
          application={application}
          selected={selectedApplicants.includes(application.aid)}
          setSelected={(selected) => setSelected(application.aid, selected)}
          onClick={() => onApplicationClickInternal(application)}
          onBulkMenuClick={(application, item, subItem, checked, apply) =>
            onApplicationPillBulkMenuClick(
              auth.withMissionApplicationStatusV2,
              currentMission,
              missions,
              applications,
              filteredApplications,
              unionLowCompetitiveness,
              unionRejectionReason,
              unionNotSelected,
              unionOpportunityToUpdate,
              unionWaitlisted,
              unionOther,
              selectedApplicants,
              setSelectedApplicants,
              application,
              item,
              subItem,
              checked,
              apply,
              auth.withApplicationPreSave ? setItemsToUpdate : undefined,
            )
          }
          countSelectedApplications={selectedApplicants.length}
          unionLowCompetitiveness={unionLowCompetitiveness}
          unionRejectionReason={unionRejectionReason}
          unionNotSelected={unionNotSelected}
          unionOpportunityToUpdate={unionOpportunityToUpdate}
          unionWaitlisted={unionWaitlisted}
          unionOther={unionOther}
          proposalInterviews={proposalInterviews}
        />
      );
    });
  };

  const saveBulkStatusUpdate = async () => {
    try {
      await currentMission?.bulkUpdateMissionApplicationStatus({
        itemsToUpdate,
      });
      setInitFilteredApplications(cloneDeep(filteredApplications));
      setItemsToUpdate([]);
      setSelectedApplicants([]);
    } catch (e) {
      console.error(e);
    }
  };

  const onDiscardUpdates = () => {
    setItemsToUpdate([]);
    setSelectedApplicants([]);

    if (
      currentMission &&
      currentMission.applications &&
      initFilteredApplications
    ) {
      const unchangedApplications = currentMission.applications.filter(
        (app) =>
          !initFilteredApplications.some((initApp) => initApp.aid === app.aid),
      );

      // Merge the unchanged applications with the initial applications
      const mergedApplications = [
        ...unchangedApplications,
        ...initFilteredApplications,
      ];

      // Update currentMission.applications with merged list
      currentMission.applications = mergedApplications;
    }
  };

  return (
    <CollapsibleContainer
      overflow="unset"
      maxContentHeight={'none'}
      title={'Applicants'}
      openDefault={
        role.status !== MissionRoleStatus.Active &&
        role.status !== MissionRoleStatus.ScheduledToEnd
      }
      icon={IconType.Applicant}
      right={
        <div className={styles.applicantsSortByContainer}>
          <InputLabel>Sort by:</InputLabel>
          <Select
            width="fixed"
            hideSelectedOptions={false}
            onChange={(option) => {
              setApplicantsSortBy(
                (option?.value ||
                  ApplicantsSortByOptions.ApplicationStatus) as ApplicantsSortByOptions,
              );
            }}
            options={APPLICANTS_SORT_BY_OPTIONS}
            defaultValue={APPLICANTS_SORT_BY_OPTIONS.find(
              (o) => o.value === applicantsSortBy,
            )}
            margin={'none'}
          />
        </div>
      }
    >
      <div className={styles.collapsibleContent}>
        {starredApplications.length > 0 ? (
          <div className={styles.applicantsContainer}>
            <div className={styles.starredContainer}>
              <Icon
                type={IconType.StarredBuilder}
                size="exact"
                className={styles.starredIcon}
              />
              <div className={styles.applicantsTitle}>
                Starred by the client
              </div>
            </div>
            <div>{getApplicantsList(starredApplications)}</div>
            <div className={styles.applicantsTitle}>Regular applicants</div>
            <div>{getApplicantsList(regularApplications)}</div>
          </div>
        ) : (
          <>{getApplicantsList(filteredApplications)}</>
        )}
      </div>
      {showMoreButton}
      {auth.withApplicationPreSave && (
        <div
          style={{ marginTop: 10, marginBottom: 30, display: 'flex', gap: 10 }}
        >
          {!!itemsToUpdate.length && (
            <Button variant="secondary" onClick={onDiscardUpdates}>
              Discard
            </Button>
          )}

          {auth.withApplicationPreSave && (
            <Button
              disabled={!itemsToUpdate.length}
              onClick={saveBulkStatusUpdate}
            >
              {itemsToUpdate.length
                ? `Save (${itemsToUpdate.length})`
                : 'No changes to save'}
            </Button>
          )}

          <NavigationPrompt
            navigate={(path) => history.push(path)}
            shouldBlockNavigation={() => !!itemsToUpdate.length}
          />
        </div>
      )}

      {!!itemsToUpdate.length && (
        <div>
          <ItemsToUpdateSummary
            filteredApplications={filteredApplications}
            itemsToUpdate={itemsToUpdate}
          />
        </div>
      )}
    </CollapsibleContainer>
  );
}

export default FilteredApplications;
