import React from 'react';
import { createUseStyles } from 'react-jss';
import { Colors, TextColors } from '@ateams/components';

export interface PageBoxProps {
  isSelected: boolean;
  isDisabled?: boolean;
  onClick?: () => unknown;
}

const usePageBoxStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '32px',
    width: '32px',
    marginRight: '6px',
    '&:last-child': {
      marginRight: 0,
    },
    borderRadius: '50%',
    background: ({ isSelected, isDisabled }: PageBoxProps) => {
      if (isSelected) {
        return isDisabled ? Colors.primaryLight : Colors.primary;
      }

      return 'transparent';
    },
    color: ({ isSelected }: PageBoxProps) =>
      isSelected ? TextColors.primary : TextColors.primaryLight,
    fill: ({ isSelected }: PageBoxProps) =>
      isSelected ? TextColors.primary : TextColors.primaryLight,
    cursor: ({ isDisabled }: PageBoxProps) =>
      isDisabled ? 'not-allowed' : 'pointer',
    '&:hover': {
      background: ({ isSelected, isDisabled }: PageBoxProps) =>
        isSelected && !isDisabled ? Colors.primary : Colors.primaryLight,
      color: TextColors.primary,
      fill: TextColors.primary,
    },
    transition:
      'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
});

export const PageBox: React.FC<PageBoxProps> = (props) => {
  const { children, isDisabled, isSelected, ...rest } = props;
  const styles = usePageBoxStyles(props);

  return (
    <div className={styles.container} {...rest}>
      {children}
    </div>
  );
};
