import React from 'react';
import { VetterRowProps } from '.';
import { Tag, TagList } from '@ateams/components';

export const VettingSkillsField: React.FC<VetterRowProps> = ({ vetter }) => {
  return (
    <TagList>
      {vetter.vettingSkills
        ?.filter((skill) => !!skill)
        .map((skill) => (
          <Tag key={skill.id}>{skill.name}</Tag>
        ))}
    </TagList>
  );
};
