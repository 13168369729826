import { useQueryGetTimesheetInitiativesForMission } from '@src/rq/timesheets';
import React, { useMemo } from 'react';
import Tag from './Tag';
import { useStores } from '@src/stores';
import { TimesheetInitiativeId } from '@a_team/models/dist/TimesheetInitiativeObject';
import { createUseStyles } from 'react-jss';

interface InitiativeTagsProps {
  sid?: string;
  initiativeIds: TimesheetInitiativeId[];
}

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    gap: 8,
  },
});

function InitiativeTags({ sid, initiativeIds }: InitiativeTagsProps) {
  const styles = useStyles();
  const { missions } = useStores();
  const { currentMission } = missions;

  const { data: initiatives } = useQueryGetTimesheetInitiativesForMission({
    sid:
      sid ??
      currentMission?.data.paymentCycles?.current?.timesheets[0]?.sid ??
      '',
  });

  const initiativesToRender = useMemo(() => {
    return (
      initiatives?.filter((initiative) =>
        initiativeIds.includes(initiative.siid),
      ) ?? []
    );
  }, [initiatives, initiativeIds]);

  return (
    <div className={styles.wrapper}>
      {initiativesToRender.map((initiative) => (
        <Tag title={initiative.name} background={initiative.backgroundColor} />
      ))}
    </div>
  );
}

export default InitiativeTags;
