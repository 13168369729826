import { Button as CallToActionButton } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';

interface ReferPeerProps {
  onInvite?(): void;
}

const useStyles = createUseStyles({
  referPeer: {
    width: '100%',
    color: '#FFFFFF',
    fontSize: '14px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    referPeer: {
      fontSize: '15px',
    },
  },
});

function ReferPeer({ onInvite }: ReferPeerProps): JSX.Element {
  const styles = useStyles();
  return (
    <div>
      <CallToActionButton
        disabled={!onInvite}
        onClick={onInvite}
        className={styles.referPeer}
        color="regular"
        size="small"
      >
        Refer a peer
      </CallToActionButton>
    </div>
  );
}

export default ReferPeer;
