import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { Button } from '@a_team/ui-components';
import { useCommonStyles } from '../commonStyles';
import { ReasonsModalContext } from './context';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { useStores } from '@src/stores';

interface CalendarTypeProps {
  onContinue: (calendar: string) => void;
  onClose: () => void;
}

export const CalendarType = (props: CalendarTypeProps) => {
  const styles = useCommonStyles();
  const [calendar, setCalendar] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { canClose } = useContext(ReasonsModalContext);
  const { auth } = useStores();
  const analytics = useAnalytics();

  useEffect(() => {
    if (auth?.currentUser?.unSupportedCalendarName) {
      setCalendar(auth.currentUser.unSupportedCalendarName);
    }
  }, [auth?.currentUser?.unSupportedCalendarName]);

  const handleContinue = async () => {
    if (calendar.length > 0) {
      try {
        setIsLoading(true);
        await auth.updateUserUnsupportedCalendarName(calendar);
      } catch (err) {
        // do nothing
      }
      setIsLoading(false);
      props.onContinue(calendar);
      analytics.trackNonSupportedCalendarProvided(auth.uid ?? '', calendar);
    }
  };

  return (
    <>
      <div className={styles.hero}>
        <h4 className={styles.modalTitle}>Share what calendar you use</h4>
        <p className={cx(styles.heroCopy, 'lessPadding')}>
          We’ll use this information to prioritize future support
        </p>
      </div>
      <div className={styles.linkWrap}>
        <label htmlFor="calendarLink" className={styles.calendarLinkLabel}>
          What other calendars do you use?
        </label>
        <input
          className={styles.linkInput}
          type="text"
          id="calendarLink"
          placeholder="Enter your calendar"
          onChange={(e) => setCalendar(e.target.value)}
          value={calendar}
        />
      </div>
      <div className={styles.actions}>
        {canClose && (
          <Button variant="secondary" size="sm" onClick={props.onClose}>
            Back
          </Button>
        )}
        <Button
          disabled={!calendar.length || isLoading}
          variant="main"
          size="sm"
          onClick={handleContinue}
        >
          {isLoading ? `Please wait...` : `Continue`}
        </Button>
      </div>
    </>
  );
};
