import { ServiceAuth, ServiceEndpoint } from './utils';
import { RequestBuilderObject } from '@a_team/models/dist/RequestBuilderObject';

export const BasePath = '/request-builders';

export default class RequestBuildersEndpoint extends ServiceEndpoint {
  public createRequestBuilder(
    auth: ServiceAuth,
    mission: string,
    urgency: 'high' | 'moderate' | 'low',
    roles: string[],
    additionalTeammatesContribution: string,
    suggestedBuilders?: string,
  ): Promise<RequestBuilderObject> {
    return this.fetch(auth, BasePath + '/', null, 'post', {
      urgency,
      roles,
      mission,
      additionalTeammatesContribution,
      suggestedBuilders,
    });
  }
}
