import React from 'react';
import { createUseStyles } from 'react-jss';
import { VetterSummary } from '@a_team/models/dist/vetter';
import { Colors } from '@ateams/components';
import { VetterColumn } from './columns';

interface Props {
  columns: VetterColumn[];
  vetter: VetterSummary;
}

const useStyles = createUseStyles({
  tr: {
    borderBottom: `1px solid ${Colors.regular}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  td: {
    padding: 12,
    textAlign: 'center',
    borderRight: `1px solid ${Colors.regular}`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
});

export const VetterRow: React.FC<Props> = (props) => {
  const styles = useStyles();
  const { columns, vetter } = props;

  return (
    <tr className={styles.tr} key={vetter.id}>
      {columns.map(({ header, Component }) => (
        <td key={header} className={styles.td}>
          <Component vetter={vetter} />
        </td>
      ))}
    </tr>
  );
};
