import {
  MissionApplicationReviewStatusNotSelected,
  MissionApplicationReviewStatusOpportunityToUpdate,
  MissionApplicationReviewStatusWaitlisted,
  MissionApplicationReviewStatusOther,
  MissionApplicationReviewStatusNotSelectedLabels,
  MissionApplicationReviewStatusOpportunityToUpdateLabels,
  MissionApplicationReviewStatusOtherLabels,
  MissionApplicationReviewStatusWaitlistedLabels,
} from '@a_team/models/dist/MissionApplicationObject';
import {
  BorderRadius,
  Colors,
  FontSizes,
  Spacing,
  TextColors,
} from '@ateams/components';
import IconWithColor from '@src/components/IconWithColor';
import _ from 'lodash';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';

export interface TagStatusItem {
  value:
    | MissionApplicationReviewStatusNotSelected
    | MissionApplicationReviewStatusOpportunityToUpdate
    | MissionApplicationReviewStatusWaitlisted
    | MissionApplicationReviewStatusOther
    | string;
  label: string;
  color: string;
  icon?: ReactElement;
}

export enum TagStatusExternalValues {
  InReview = 'inReview',
  Accepted = 'accepted',
  Exclusive = 'exclusive',
  OnHold = 'onHold',
  ProposalInterviewing = 'proposalInterviewing',
  Proposal = 'proposal',
  HasVettingScheduled = 'hasVettingScheduled',
  SelectedForRole = 'selectedForRole',
  MissionCancelled = 'missionCancelled',
  RoleCancelled = 'roleCancelled',
  ConfirmedAnotherBuilder = 'confirmedAnotherBuilder',
  Withdrawn = 'withdrawn',
}

export interface Props {
  item: TagStatusItem;
  useTagStyle?: boolean;
}

export const allTagStatusItems = _.concat<TagStatusItem>(
  [
    {
      value: TagStatusExternalValues.MissionCancelled,
      label: 'Mission cancelled',
      color: Colors.dangerLight,
    },
  ],
  [
    {
      value: TagStatusExternalValues.RoleCancelled,
      label: 'Role cancelled',
      color: Colors.dangerLight,
    },
  ],
  [
    {
      value: TagStatusExternalValues.Withdrawn,
      label: 'Application withdrawn by builder',
      color: '#FFFFFF',
      icon: (
        <IconWithColor
          type={'Undo'}
          size={'exact'}
          style={{ width: 12, height: 12 }}
          color={TextColors.dangerLight}
          defaultOnDesktop
        />
      ),
    },
  ],
  [
    {
      value: TagStatusExternalValues.Proposal,
      label: 'Proposed - Not shared',
      color: '#FF69B4',
    },
  ],
  [
    {
      value: TagStatusExternalValues.ConfirmedAnotherBuilder,
      label: 'Confirmed another builder',
      color: Colors.dangerLight,
    },
  ],
  [
    {
      value: TagStatusExternalValues.InReview,
      label: 'In Review',
      color: '#818388',
    },
  ],
  [
    {
      value: TagStatusExternalValues.Accepted,
      label: 'Accepted',
      color: '#79CE00',
    },
  ],

  [
    {
      value: TagStatusExternalValues.OnHold,
      label: 'On Hold',
      color: '#FFCBAE',
    },
  ],
  [
    {
      value: TagStatusExternalValues.ProposalInterviewing,
      label: 'Interviewing',
      color: '#B78301',
    },
  ],
  [
    {
      value: TagStatusExternalValues.HasVettingScheduled,
      label: 'Vetting Scheduled',
      color: '#9024FF',
    },
  ],
  [
    {
      value: TagStatusExternalValues.SelectedForRole,
      label: 'Selected for Role',
      color: '#FFFFFF',
    },
  ],
  Object.values(MissionApplicationReviewStatusNotSelected).map((s) => ({
    value: s,
    label: MissionApplicationReviewStatusNotSelectedLabels[s],
    color:
      s === MissionApplicationReviewStatusNotSelected.AfterInterview ||
      s === MissionApplicationReviewStatusNotSelected.AfterProposal
        ? '#B81F1F'
        : '#FFCDCD',
  })) || [],
  Object.values(MissionApplicationReviewStatusOpportunityToUpdate).map((s) => ({
    value: s,
    label: MissionApplicationReviewStatusOpportunityToUpdateLabels[s],
    color: '#6D00D7',
  })) || [],
  Object.values(MissionApplicationReviewStatusWaitlisted).map((s) => ({
    value: s,
    label: MissionApplicationReviewStatusWaitlistedLabels[s],
    color:
      s === MissionApplicationReviewStatusWaitlisted.WaitlistedStrong
        ? '#79CE00'
        : '#ab54ff',
  })) || [],
  Object.values(MissionApplicationReviewStatusOther).map((s) => ({
    value: s,
    label: MissionApplicationReviewStatusOtherLabels[s],
    color:
      s === MissionApplicationReviewStatusOther.Unavailable
        ? '#C0C0C0'
        : s === MissionApplicationReviewStatusOther.Exclusive
        ? '#AEE2FF'
        : s === MissionApplicationReviewStatusOther.InterviewingFromFormation
        ? '#B78301'
        : s === MissionApplicationReviewStatusOther.PresentedToClient
        ? '#FF69B4'
        : '#9024FF',
  })) || [],
);

const useStyles = createUseStyles({
  tagItem: ({ useTagStyle }: Props) => ({
    ...(useTagStyle
      ? {
          backgroundColor: '#F7F7F7',
          padding: '4px 8px',
        }
      : {}),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.small,
    borderRadius: BorderRadius.medium,
  }),
  tagItemRect: {
    width: FontSizes.small,
    height: FontSizes.small,
    borderRadius: BorderRadius.minimal,
  },
  tagItemText: {
    color: Colors.regularDark,
  },
});

export const StatusTag = (props: Props) => {
  const styles = useStyles(props);
  const item = props.item;
  return (
    <div key={item.value} className={styles.tagItem}>
      {item.icon ? (
        item.icon
      ) : (
        <div
          className={styles.tagItemRect}
          style={{ backgroundColor: item.color }}
        ></div>
      )}
      <div className={styles.tagItemText}>{item.label}</div>
    </div>
  );
};
