import React, { ReactElement, ReactNode, useMemo, useState } from 'react';
import Modal, { ModalBasicProps } from '..';
import SectionHeading from '@src/components/SectionHeading';
import {
  Button as CallToActionButton,
  Icon,
  IconType,
} from '@ateams/components';
import { Expertise } from '@src/stores/Profile/models';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import { useScreenClass } from 'react-grid-system';
import ExpertiseSelector from '@src/components/ExpertiseSelector';
import { Typography } from '@a_team/ui-components';

interface Props extends ModalBasicProps {
  title?: string;
  onSubmit: (
    role: Expertise[] | undefined,
    reqSkills: Expertise[] | [],
    prefSkills: Expertise[] | [],
  ) => void;
  onSearch: (query: string) => Promise<Expertise[]>;
  selectedRequiredSkills?: Expertise[];
  selectedPrefSkills?: Expertise[];
  selectedRole?: Expertise[];
  description?: ReactNode;
  sideDescription?: ReactNode;
  userHasLowProficiencySkills?: boolean;
}

const defaultProps = {
  singeSelection: false,
};

const useStyles = createUseStyles({
  modal: {
    display: 'flex',
    padding: '40px 14px',
  },
  title: {
    fontSize: 17,
  },
  main: {
    width: '100%',
    overflow: 'visible',
    padding: '20px 5px',
    borderRadius: 10,
  },
  arrowWrapper: {
    color: '#62646A',
    fontWeight: 500,
    fontSize: 15,
    cursor: 'pointer',
  },
  legend: {
    padding: 0,
  },
  buttonBox: {
    display: 'flex',
    gap: '16px',
    '& > button': {
      width: '100%',
      height: 'auto',
    },
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  button: {
    width: '100%',
    height: 48,
  },
  description: {
    display: 'block',
    marginTop: 15,
    marginBottom: 15,
    fontSize: 15,
    '& span': {
      fontWeight: 'bold',
    },
  },
  expertises: { overflow: 'auto', height: 410 },
  sideDescription: {
    display: 'block',
    marginTop: 55,
    marginBottom: 95,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '100% !important',
      padding: '0px !important',
    },
    title: {
      fontSize: 28,
      marginBottom: 16,
    },
    main: {
      minWidth: '50%',
      flex: 1,
      padding: 24,
    },
    expertises: { height: 510 },

    legend: {
      width: '45%',
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
});

const ApplyExpertiseModal = (props: Props): ReactElement => {
  const styles = useStyles();
  const screenClass = useScreenClass();
  const {
    onClose,
    onSubmit,
    onSearch,
    title,
    selectedRole,
    selectedRequiredSkills,
    selectedPrefSkills,
    sideDescription,
    description,
    userHasLowProficiencySkills,
    ...left
  } = props;
  const [page, setPage] = useState(0);
  const [localReqItems, setLocalReqItems] = useState<Expertise[]>(
    selectedRequiredSkills || [],
  );
  const [localPrefItems, setLocalPrefItems] = useState<Expertise[]>(
    selectedPrefSkills || [],
  );
  const buttonLabels = {
    next: 'Continue & Update Skills',
    continue: 'Continue',
    submit: 'Request to Join',
  };

  const computeButton = () => {
    if (
      page === 0 &&
      ((selectedRequiredSkills && selectedRequiredSkills?.length > 0) ||
        (selectedPrefSkills && selectedPrefSkills?.length > 0))
    ) {
      return 'next';
    } else {
      return 'submit';
    }
  };

  const rightButton = useMemo(
    () => computeButton(),
    [page, selectedPrefSkills, selectedRequiredSkills],
  );

  const isValid = useMemo(() => {
    if (rightButton === 'submit') {
      if (localReqItems && localReqItems.length > 0) {
        if (localReqItems.find((item) => item.rating === undefined)) {
          return false;
        }
      }
    }
    return true;
  }, [localReqItems, rightButton]);

  const handleClick = () => {
    if (rightButton === 'submit') {
      setPage(0);
      const localPrefItemsRated = localPrefItems.filter(
        (localPrefItem) => localPrefItem.rating !== undefined,
      );
      onSubmit(selectedRole, localReqItems, localPrefItemsRated);
    } else if (rightButton === 'next') {
      setPage(page + 1);
    }
  };

  const renderButtonText = (): string => {
    if (rightButton === 'submit') {
      if (userHasLowProficiencySkills) {
        return buttonLabels.continue;
      } else {
        return buttonLabels.submit;
      }
    } else {
      return buttonLabels.next;
    }
  };

  return (
    <Modal
      onClose={() => {
        setPage(0);
        setLocalPrefItems([]);
        onClose();
      }}
      hideCloseButton={true}
      className={styles.modal}
      style={{
        flexDirection:
          page !== 0 && screenClass !== 'sm' && screenClass !== 'xs'
            ? 'row'
            : 'column',
        maxWidth: page !== 0 ? 845 : 600,
      }}
      {...left}
    >
      <div
        className={styles.main}
        style={{ background: page !== 0 ? '#F7F7F7' : '#FFFFF' }}
      >
        {title && page === 0 && (
          <SectionHeading className={styles.title} isFirst>
            {title}
          </SectionHeading>
        )}
        {page !== 0 && (
          <div
            className={styles.arrowWrapper}
            onClick={() => setPage(page - 1)}
          >
            <Icon
              type={IconType.ArrowBack}
              muted
              size={'medium'}
              style={{ opacity: 1, width: 22 }}
            />
            <span>Back</span>
          </div>
        )}
        {page !== 0 && (
          <div className={styles.expertises}>
            {selectedRequiredSkills && selectedRequiredSkills?.length > 0 && (
              <ExpertiseSelector
                onSearch={onSearch}
                hideSearch={true}
                onChange={setLocalReqItems}
                selectedItems={selectedRequiredSkills}
                displayType={'score'}
                sectionTitle={'Required Skills'}
                withFeaturedCheckbox={false}
                limitAnswersHeight={false}
              />
            )}
            {selectedPrefSkills && selectedPrefSkills?.length > 0 && (
              <ExpertiseSelector
                onSearch={onSearch}
                hideSearch={true}
                onChange={setLocalPrefItems}
                selectedItems={selectedPrefSkills}
                displayType={'score'}
                sectionTitle={'Nice-to-Have Skills'}
                withFeaturedCheckbox={false}
                limitAnswersHeight={false}
              />
            )}
          </div>
        )}
        {description && page === 0 && (
          <div className={styles.description}>{description}</div>
        )}
      </div>
      <div
        className={styles.legend}
        style={{ ...(page === 0 && { width: '100%' }) }}
      >
        {page !== 0 && sideDescription && (
          <div className={styles.sideDescription}>{sideDescription}</div>
        )}
        {page !== 0 && !isValid && (
          <div style={{ marginBottom: '10px' }}>
            {' '}
            <Typography variant={'textSmall'} color="Baracus@400">
              Add ratings to continue
            </Typography>
          </div>
        )}
        <div className={styles.buttonBox}>
          <CallToActionButton
            width="auto"
            className={styles.button}
            onClick={() => {
              setPage(0);
              onClose();
            }}
            size="small"
            color={'regularLight'}
          >
            Cancel Application
          </CallToActionButton>
          <CallToActionButton
            width="auto"
            className={styles.button}
            onClick={() => handleClick()}
            disabled={!isValid}
            size="small"
            style={rightButton === 'next' ? { alignSelf: 'flex-end' } : {}}
          >
            {renderButtonText()}
          </CallToActionButton>
        </div>
      </div>
    </Modal>
  );
};

ApplyExpertiseModal.defaultProps = defaultProps;
export default ApplyExpertiseModal;
