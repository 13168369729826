import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import RegistrationStep from '@src/views/Registration/RegistrationStep';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import TextButton from '@src/components/TextButton';
import { Colors } from '@ateams/components';
import { validate as isEmailValid } from 'email-validator';
import { LocalTeamMember } from '@src/stores/Registration/models';
import { createUseStyles } from 'react-jss';
import { MemberForm } from '@src/views/Registration/Steps/teamMembers/MemberForm';

export type EmailValidation = Record<
  LocalTeamMember['_id'],
  boolean | undefined
>;

const useStyles = createUseStyles({
  button: {
    display: 'block',
    textAlign: 'center',
    padding: '16px 20px',
    marginTop: 20,
    background: Colors.backgroundMedium,
    borderRadius: 12,
    width: '100%',
  },
});

const TeamMembers = (): ReactElement => {
  const styles = useStyles();
  const {
    registration: {
      teamMembersWithoutOwner,
      addNewEmptyTeamMember,
      setTeamMember,
      removeTeamMember,
      currentStep,
      querySpecializations,
    },
  } = useStores();

  const [loading, setLoading] = useLoadingState();
  const [emailValidations, setEmailValidations] = useState<EmailValidation>({});

  useEffect(() => {
    const validations: EmailValidation = {};
    teamMembersWithoutOwner.forEach(
      (teamMember) => (validations[teamMember._id] = undefined),
    );
  }, [teamMembersWithoutOwner]);

  const hasEmailErrors = useMemo(() => {
    return Object.values(emailValidations).some((value) => value === false);
  }, [emailValidations]);

  const onMemberChange = (teamMember: LocalTeamMember) => {
    validateEmail(teamMember);
    setTeamMember(teamMember);
  };

  const validateEmail = (teamMember: LocalTeamMember) => {
    setEmailValidations({
      ...emailValidations,
      [teamMember._id]: teamMember.email
        ? isEmailValid(teamMember.email)
        : undefined,
    });
  };

  const onSubmit = () => {
    currentStep.onSubmit && setLoading(currentStep.onSubmit());
  };

  return (
    <RegistrationStep
      title={'Add your Team Members'}
      description={
        <>
          <strong>We believe in teams.</strong> And one of the things that makes
          a team great is people who've worked together before. Add all your
          team members so we can invite them to create their profiles and join
          missions with you. Please note that we review each team member
          individually and might not admit every member.
        </>
      }
      buttonLabel={'Next'}
      onSubmit={onSubmit}
      canSubmit={loading !== true && currentStep.canSubmit && !hasEmailErrors}
      loading={loading}
    >
      {teamMembersWithoutOwner?.map((member) => {
        return (
          <MemberForm
            key={member._id}
            onChange={onMemberChange}
            onRemove={removeTeamMember}
            member={member}
            isEmailValid={emailValidations[member._id]}
            onRoleSearch={querySpecializations}
          />
        );
      })}
      <TextButton
        className={styles.button}
        highlight
        onClick={addNewEmptyTeamMember}
      >
        + Add {teamMembersWithoutOwner.length > 0 ? 'Another' : 'A'} Team Member
      </TextButton>
    </RegistrationStep>
  );
};

export default observer(TeamMembers);
