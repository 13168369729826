import { Button } from '@ateams/components';
import { BasicConnectionObjectV2 } from '@a_team/models/dist/ConnectionObject';
import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import Description from '../../partials/Description';
import Title from '../../partials/Title';
import { UserCardObject } from '@a_team/models/dist/UserObject';
import { useHistory } from 'react-router-dom';

interface ConnectionConfirmedProps {
  connection: BasicConnectionObjectV2;
  userToConnect: UserCardObject;
  onClose: () => void;
}

const useStyles = createUseStyles({
  buttonsWrapper: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'center',
    marginTop: 40,
  },
  button: {
    borderRadius: '4px',
    maxWidth: '300px',
    width: 'fit-content',
  },
});

function ConnectionConfirmed({
  connection,
  onClose,
  userToConnect,
}: ConnectionConfirmedProps): ReactElement {
  const styles = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(`/${userToConnect.username}`);
    onClose();
  };

  return (
    <div>
      <Title text={`Start a conversation with your new connection`} />

      <Description
        text={`You and ${userToConnect.fullName} are now connections on A.Team.`}
      />

      <div className={styles.buttonsWrapper}>
        <Button
          className={styles.button}
          onClick={handleClick}
          size="small"
          color="secondaryDark"
          data-testing-id="connection-confirmed-acknowledge-button"
        >
          Message {userToConnect.firstName}
        </Button>
      </div>
    </div>
  );
}

export default observer(ConnectionConfirmed);
