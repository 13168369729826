import { TalentSkillsQueryData } from '@ateams/api/dist/endpoints/TalentSkills';
import { TalentCategoryId } from '@a_team/models/dist/TalentCategories';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const skillKeys = createQueryKeys('skills', {
  list: (query: TalentSkillsQueryData) => ({ query }),
  userExpertise: (
    query: TalentSkillsQueryData,
    talentCategoryIds: TalentCategoryId[] = [],
  ) => ({ query, talentCategoryIds }),
});
