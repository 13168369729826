import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import AdminInvoicesDashboardLayout from '@src/layouts/InvoicesDashboard';
import { useStores } from '@src/stores';
import Loader from '@src/components/Loader';
import { ServerSideLoader, ViewProps } from '@src/routes';
import Table, { TableRow } from '@src/components/Table';
import { Button, Icon, IconType, LoadMoreLink } from '@ateams/components';
import {
  AdminInvoicesDiscountsNewLocation,
  AdminInvoicesDiscountsViewLocation,
  MissionAboutLocation,
} from '@src/locations';
import { Link } from 'react-router-dom';
import { DiscountStatusMap } from './Discount';
import { numberWithCommas } from '@src/helpers/numbers';

export const adminInvoicesDiscountsLoader: ServerSideLoader = async (
  stores,
  match,
  location,
) => {
  const { discounts } = stores;

  if (!discounts.list) {
    await discounts.loadDiscounts(true);
  }
};

const AdminInvoicesDiscountsView = (props: ViewProps) => {
  const { discounts } = useStores();
  const rows = discounts.getDiscounts();

  return (
    <AdminInvoicesDashboardLayout title="Discounts">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginBottom: '2em',
        }}
      >
        <Button
          size="small"
          width="auto"
          onClick={() => props.history.push(AdminInvoicesDiscountsNewLocation)}
        >
          <Icon type={IconType.Plus} size="xsmall" /> New discount
        </Button>
      </div>

      {rows ? (
        <>
          <Table
            headers={[
              'Account name',
              'Affected missions',
              'Status',
              'Summary',
              'Actions',
            ]}
            noDataText="No discounts found"
          >
            {rows.items.length > 0 &&
              rows.items.map((item) => (
                <TableRow
                  key={item.did}
                  cells={[
                    <strong>{item.account.name}</strong>,
                    item.missions.length
                      ? item.missions.map((m, i) => (
                          <Fragment key={m.mid}>
                            {i > 0 && (
                              <>
                                ,<br />
                              </>
                            )}
                            <a
                              href={MissionAboutLocation(m.mid)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {m.title}
                            </a>
                          </Fragment>
                        ))
                      : '-',
                    <>
                      {DiscountStatusMap[item.status]}
                      <div style={{ marginTop: '0.5em' }}>
                        <strong>
                          {Math.round(
                            (100 * item.usedCredit) / item.totalCredit,
                          )}
                          %
                        </strong>{' '}
                        <span style={{ color: '#999' }}>
                          (${numberWithCommas(item.usedCredit)} of $
                          {numberWithCommas(item.totalCredit)})
                        </span>
                      </div>
                    </>,
                    item.summary,
                    <Link to={AdminInvoicesDiscountsViewLocation(item.did)}>
                      Details
                    </Link>,
                  ]}
                />
              ))}
          </Table>

          {rows.next && (
            <LoadMoreLink onLoadMore={() => discounts.loadDiscounts()} />
          )}
        </>
      ) : (
        <Loader />
      )}
    </AdminInvoicesDashboardLayout>
  );
};

export default observer(AdminInvoicesDiscountsView);
