import React, { CSSProperties, useMemo } from 'react';
import MainApplication from './MainApplication';
import MainProfile from './MainProfile';
import Profile from '@src/stores/Profile/Profile';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';

interface MainProps {
  profile?: Profile;
  style?: CSSProperties;
  skipScrollToTop?: boolean;
}

const Main = (props: MainProps): JSX.Element | null => {
  const { users } = useStores();

  const profile = useMemo(() => {
    return props.profile || users.profile;
  }, [props.profile, users.profile]);

  if (profile?.application) {
    return (
      <MainApplication
        profile={profile}
        style={props.style}
        skipScrollToTop={props.skipScrollToTop}
      />
    );
  }

  return <MainProfile profile={profile} style={props.style} />;
};

export default observer(Main);
