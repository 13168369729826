import { IconType } from '@ateams/components';
import { LocalHourRange } from '@a_team/models/dist/WorkingHoursObject';
import {
  beautifyTime,
  getCountryFromTimezoneName,
  getTimeOverlap,
} from '@src/helpers/time';
import { useStores } from '@src/stores';
import {
  getHoursFromMinutes,
  getHumanReadableTime,
} from '@src/views/Profile/Sidebar/WorkingHours/utils';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import React, { Fragment, useMemo } from 'react';
import Requirement from '../common/Requirement';

interface TimezoneProps {
  numberOfMinutesOverlap: number;
  roleDaily: LocalHourRange[];
  roleTimezoneName: string;
  userDaily?: LocalHourRange[];
  userTimezoneName?: string;
  toggleSetTimezoneModalOpen?: () => void;
  setNotEnoughOverlap?: (value: boolean) => void;
}

function Timezone({
  numberOfMinutesOverlap,
  roleDaily,
  roleTimezoneName,
  userDaily,
  userTimezoneName,
  toggleSetTimezoneModalOpen,
  setNotEnoughOverlap,
}: TimezoneProps): JSX.Element {
  const stores = useStores();
  const { auth } = stores;

  const { overlappedWorkingHoursSlots, overlappedTimeInMinutes } =
    useMemo(() => {
      if (
        userTimezoneName === undefined ||
        roleDaily?.length === 0 ||
        roleTimezoneName === undefined
      ) {
        if (setNotEnoughOverlap) {
          setNotEnoughOverlap(true);
        }
        return { overlappedWorkingHoursSlots: [], overlappedTimeInMinutes: 0 };
      }

      const { overlappedWorkingHoursSlots, overlappedTimeInMinutes } =
        getTimeOverlap(
          userDaily,
          userTimezoneName,
          roleDaily,
          roleTimezoneName,
        );

      return { overlappedWorkingHoursSlots, overlappedTimeInMinutes };
    }, [roleDaily, roleTimezoneName, userDaily, userTimezoneName]);

  const getOverlapMessage = (
    overlappedWorkingHoursSlots: LocalHourRange[],
    userTimezoneName: string,
  ) => {
    return (
      <div>
        You overlap for {getHumanReadableTime(overlappedTimeInMinutes)}
        {` (`}
        {overlappedWorkingHoursSlots.map(
          (overlappedWorkingHoursSlot, index) => (
            <Fragment key={`day-${index}`}>
              {index > 0 && <span>, </span>}
              <span>
                {beautifyTime(
                  DateTime.fromObject(
                    {
                      hour: Math.floor(
                        overlappedWorkingHoursSlot.startTime / 60,
                      ),
                      minute: overlappedWorkingHoursSlot.startTime % 60,
                    },
                    { zone: 'UTC' },
                  )
                    .setZone(userTimezoneName)
                    .toFormat('h:mm a'),
                )}
                -
                {beautifyTime(
                  DateTime.fromObject(
                    {
                      hour: Math.floor(overlappedWorkingHoursSlot.endTime / 60),
                      minute: overlappedWorkingHoursSlot.endTime % 60,
                    },
                    { zone: 'UTC' },
                  )
                    .setZone(userTimezoneName)
                    .toFormat('h:mm a'),
                )}
              </span>
            </Fragment>
          ),
        )}{' '}
        your time)
      </div>
    );
  };

  const getNoOverlapMessage = () => {
    return 'You don’t have enough overlap';
  };

  const meetsRequirement: boolean =
    overlappedWorkingHoursSlots.length > 0 &&
    overlappedTimeInMinutes >= numberOfMinutesOverlap &&
    userTimezoneName !== undefined;

  const feedback =
    meetsRequirement && userTimezoneName
      ? getOverlapMessage(overlappedWorkingHoursSlots, userTimezoneName)
      : getNoOverlapMessage();

  const onClickEvent =
    !meetsRequirement && auth?.user?.username
      ? toggleSetTimezoneModalOpen
      : undefined;

  return (
    <Requirement
      iconType={IconType.World}
      name="Working-hours overlap"
      dataTestingId="working-hours-overlap-requirement"
      meetsRequirement={meetsRequirement}
      feedback={feedback}
      onClick={onClickEvent}
    >
      <div style={{ flex: 'none' }}>
        {getHumanReadableTime(numberOfMinutesOverlap)} with
        {roleDaily?.map((daily, index) => {
          return (
            <span key={`${daily.startTime}-${daily.endTime}`}>
              {' '}
              {beautifyTime(getHoursFromMinutes(daily.startTime))} {' - '}{' '}
              {beautifyTime(getHoursFromMinutes(daily.endTime))},{' '}
            </span>
          );
        })}{' '}
        {getCountryFromTimezoneName(roleTimezoneName)}
      </div>
    </Requirement>
  );
}

export default observer(Timezone);
