import { ServiceAuth, ServiceEndpoint } from './utils';
import { ExperienceId } from '@a_team/models/dist/ExperienceObject';
import { UserUsername } from '@a_team/models/dist/UserObject';
import {
  ConnectionObject,
  ConnectionId,
  ConnectionType,
} from '@a_team/models/dist/ConnectionObject';
import { QueryNextToken, QueryResult } from '@a_team/models/dist/misc';

export const BasePath = '/connections';

export interface ConnectionRequest {
  toUsername: UserUsername;
  types: ConnectionType[];
  sharedExperiences: ExperienceId[] | null;
}

export type ConnectionApproveRequest = Omit<
  ConnectionRequest,
  'toUsername' | 'types'
>;

// exported functions

export default class ConnectionsEndpoint extends ServiceEndpoint {
  public connect(
    auth: ServiceAuth,
    request: ConnectionRequest,
  ): Promise<ConnectionObject> {
    return this.fetch(auth, BasePath, null, 'post', request);
  }

  public approve(
    auth: ServiceAuth,
    cid: ConnectionId,
    request: ConnectionApproveRequest,
  ): Promise<ConnectionObject> {
    return this.fetch(
      auth,
      BasePath + `/${cid}/approve`,
      null,
      'post',
      request,
    );
  }

  public getRecentChanges(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<QueryResult<ConnectionObject>> {
    return this.fetch(auth, BasePath + '/recent-changes', { next });
  }

  public list(auth: ServiceAuth): Promise<ConnectionObject[]> {
    return this.fetch(auth, BasePath);
  }

  public remove(auth: ServiceAuth, cid: ConnectionId): Promise<null> {
    return this.fetch(auth, BasePath + `/${cid}`, null, 'delete');
  }
}
