import React from 'react';
import { VetterSummary } from '@a_team/models/dist/vetter';
import { NameField } from './name';
import { vettingTypeToStatusMap } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { VettingSkillsField } from './vetting-skills';
import { VettingRolesField } from './vetting-roles';

export interface VetterRowProps {
  vetter: VetterSummary;
}

export interface VetterColumn {
  header: string;
  Component: React.FC<VetterRowProps>;
}

export const columns: VetterColumn[] = [
  {
    header: 'Name',
    Component: NameField,
  },
  {
    header: 'Interviewer Type',
    Component: ({ vetter }) =>
      vetter.type ? <>{vettingTypeToStatusMap[vetter.type]}</> : null,
  },
  {
    header: 'Main Role',
    Component: ({ vetter }) => <>{vetter.mainRole?.title}</>,
  },
  {
    header: 'Evaluation Skills',
    Component: VettingSkillsField,
  },
  {
    header: 'Evaluation Roles',
    Component: VettingRolesField,
  },
  {
    header: 'Interview Capacity (monthly)',
    Component: ({ vetter }) => <>{vetter.interviewCapacity}</>,
  },
  {
    header: 'Remaining Interviews (this month)',
    Component: ({ vetter }) => <>{vetter.remainingInterviewCapacity}</>,
  },
  {
    header: 'Declined Interviews (monthly)',
    Component: ({ vetter }) => <>{vetter.thisMonthDeclinedInterviewsCount}</>,
  },
  {
    header: 'Total Interviews Done',
    Component: ({ vetter }) => <>{vetter.totalInterviewsDone}</>,
  },
  {
    header: 'Is on Break?',
    Component: ({ vetter }) => <>{vetter.isActive ? 'No' : 'Yes'}</>,
  },
];
