import { useState } from 'react';
import { CurrentUserMissionApplicationObject } from '@a_team/models/dist/MissionApplicationObject';
import useToggle from '@src/hooks/useToggle';

export type Application = {
  mid: string;
  application: CurrentUserMissionApplicationObject;
};

interface WithdrawApplicationState {
  confirmationModalOpen: boolean;
  selectedApplication: Application | null;
  onConfirmWithdrawModalOpen: (
    mid: string,
    application: CurrentUserMissionApplicationObject,
  ) => void;
  onConfirmModalClose: () => void;
}

const useWithdrawApplication = (): WithdrawApplicationState => {
  const [selectedApplication, setSelectedApplication] =
    useState<Application | null>(null);
  const [confirmationModalOpen, toggleConfirmationModalOpen] = useToggle();

  const onConfirmWithdrawModalOpen = (
    mid: string,
    application: CurrentUserMissionApplicationObject,
  ): void => {
    setSelectedApplication({ mid, application });
    toggleConfirmationModalOpen();
  };

  const onConfirmModalClose = () => {
    setSelectedApplication(null);
    toggleConfirmationModalOpen();

    requestAnimationFrame(() => {
      (document.activeElement as HTMLElement)?.blur();
    });
  };

  return {
    confirmationModalOpen,
    selectedApplication,
    onConfirmWithdrawModalOpen,
    onConfirmModalClose,
  };
};

export default useWithdrawApplication;
