import {
  BorderColors,
  BorderRadius,
  FontSizes,
  FontWeights,
  Spacing,
} from '@ateams/components';
import { DateInput } from '@src/components/DateInput';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useCommonStyles } from '../commonStyles';

const useStyles = createUseStyles({
  dateInput: {
    fontSize: FontSizes.xsmall,
    fontWeight: FontWeights.regular,
    width: 100,
    height: 32,
    borderRadius: BorderRadius.default,
    border: `1px solid ${BorderColors.light}`,
    paddingLeft: Spacing.small,
  },
});

export interface FieldDatePickerProps {
  label: string;
  value: Date | undefined;
  onChange: (value: Date) => void;
  disabled?: boolean;
}

export const FieldDatePicker = ({
  label,
  value,
  onChange,
  disabled,
}: FieldDatePickerProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();

  const formattedDate = useMemo(() => {
    return value ? format(value, 'MMM d,yyyy') : '';
  }, [value]);

  return (
    <div className={commonStyles.field}>
      <div className={commonStyles.label}>{label}</div>
      <DateInput
        value={formattedDate}
        onChange={onChange}
        className={styles.dateInput}
        disabled={disabled}
      />
    </div>
  );
};
