import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    marginBottom: '24px',
    paddingBottom: '24px',
    borderBottom: '1px solid #C0C0C0',
  },
});

const Professionalism = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <h4>Professionalism:</h4>
      <p>
        <strong>Confidentiality:</strong> Do your best to protect any sensitive
        information you receive from companies. The Builder Agreement requires
        you to protect a company’s confidential information the same way you
        would protect your own!
      </p>
      <div>
        <strong>Post-Engagement</strong>
        <p>
          To the extent relevant, at the end of an engagement or when
          confidential information is no longer needed, you should either return
          or remove it from your computer and equipment.{' '}
        </p>
      </div>
    </div>
  );
};

export default Professionalism;
