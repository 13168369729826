import React, { ReactElement } from 'react';
import IconWithColor from '../IconWithColor';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { Breakpoints, Colors, TextColors } from '@ateams/components';
import { Icon } from '@a_team/ui-components';

const useStyles = createUseStyles({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    background: 'none',
    border: 0,
    margin: '0.5em 1em 0 0',
    paddingBottom: '0.5em',
    textDecoration: 'none',
    color: TextColors.regular,
    cursor: 'pointer',
    borderBottom: 'solid 4px transparent',
    transition: 'all 0.5s',

    '&.active': {
      color: Colors.secondary,
      borderBottomColor: Colors.secondary,
    },

    '&.disabled': {
      opacity: 0.3,
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      marginRight: '1.5em',

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
});

export interface Tab {
  label: string;
  value: string;
  amount: number;
  iconType?: string;
  isFirst?: boolean;
  disabled?: boolean;
  total?: number;
  rightAligned?: boolean;
  skipTotal?: boolean;
}

interface Props {
  tab: Tab;
  current: boolean;
}

export function getTabDisplayAmount(tab: Tab): string {
  return tab.total !== undefined
    ? `${tab.amount}/${tab.total}`
    : tab.amount.toString();
}

export default function MissionTabLink(props: Props): ReactElement {
  const { tab, current } = props;
  const styles = useStyles();
  const displayAmount = getTabDisplayAmount(tab);

  return (
    <NavLink
      to={tab.value}
      className={cx(styles.root, { disabled: false })}
      style={{
        minWidth: tab.skipTotal ? 80 : 100,
        flexShrink: 0,
        textAlign: 'center',
        pointerEvents: tab.disabled ? 'none' : 'auto',
        color: tab.disabled ? TextColors.lighter : TextColors.regular,
      }}
      isActive={(): boolean => !!current} // to match the type in NavLink
    >
      {tab.iconType && (
        <IconWithColor
          type={tab.iconType}
          size={'exact'}
          style={{ marginRight: '0.5em' }}
          color={current ? Colors.secondary : TextColors.regular}
          defaultOnDesktop={false}
        />
      )}
      {tab.disabled && (
        <Icon
          color="Grey@700"
          size="md"
          name="lock"
          style={{ marginRight: '0.5em' }}
        />
      )}
      {tab.label} {!tab.skipTotal && <>({displayAmount})</>}
    </NavLink>
  );
}
