import { ServiceEndpoint } from './utils';
import {
  BasicReferralObject,
  BasicReferrerObject,
  ReferralCode,
} from '@a_team/models/dist/Referrals';
import { ServiceAuth } from '@ateams/service-utils';
import { PartnerId } from '@a_team/models/dist/Partners';
import { UserId } from '@a_team/models/dist/UserObject';

export const BasePath = '/referrals';

export interface ReferralsPayload {
  referrerId: PartnerId | UserId;
  destinationURL?: string;
  customCode?: ReferralCode;
}

export interface UpdateInviterUser {
  userId: UserId;
  inviterUserId?: UserId;
  refreshInviterRewardsScores?: boolean;
}

export default class ReferralsEndpoint extends ServiceEndpoint {
  public getReferrerByReferralCode(
    referralCode: ReferralCode,
  ): Promise<BasicReferrerObject> {
    return this.fetch(null, BasePath + `/referrer/${referralCode}`);
  }

  /***
   * Creates a new referral code for a partner
   * Used by Retool partners dashboard
   */
  public adminCreatePartnerReferral(
    auth: ServiceAuth,
    request: ReferralsPayload,
  ): Promise<BasicReferralObject> {
    return this.fetch(auth, BasePath + `/admin/partner`, null, 'post', request);
  }

  /***
   * Creates a new referral code for a company user
   * Used by Retool partners dashboard
   */
  public adminCreateUserReferral(
    auth: ServiceAuth,
    request: ReferralsPayload,
  ): Promise<BasicReferralObject> {
    return this.fetch(auth, BasePath + `/admin/user`, null, 'post', request);
  }

  /**
   * Sets, or resets, the inviterUser field of a user.
   * @param auth
   * @param request
   */
  public adminUpdateInviterUser(
    auth: ServiceAuth,
    request: UpdateInviterUser,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/admin/inviterUser`,
      null,
      'post',
      request,
    );
  }
}
