import React, { ReactElement } from 'react';
import { ScoreInput } from '@src/components/ScoreInput/ScoreInput';
import {
  AccentScoreOptions,
  EnglishLevelOptions,
  ExpertiseScoreOptions,
  InteractionExperienceOptions,
} from '@src/components/ScoreInput/Scores';
import {
  ScoreType,
  ScoreTypeLabels,
} from '@a_team/models/dist/AdminNotesObject';
import { AdminNotesScoreQueryParameters } from '@ateams/api/dist/endpoints/TeamGraph';

interface Props {
  vettingCriteria: AdminNotesScoreQueryParameters;
  onChange: (scrubCriteria: AdminNotesScoreQueryParameters) => void;
}

const VettingChecklist = (props: Props): ReactElement => {
  const { vettingCriteria, onChange } = props;
  const { expertiseScore, interactionScore, englishScore, accentScore } =
    vettingCriteria ?? {};

  const handleCheck = (vetting: AdminNotesScoreQueryParameters) => {
    onChange(vetting);
  };

  return (
    <div>
      <ScoreInput
        scores={ExpertiseScoreOptions}
        value={expertiseScore}
        onChange={(score) =>
          handleCheck({ expertiseScore: score ?? undefined })
        }
        title={ScoreTypeLabels[ScoreType.Expertise]}
      />
      <ScoreInput
        scores={InteractionExperienceOptions}
        value={interactionScore}
        onChange={(score) =>
          handleCheck({ interactionScore: score ?? undefined })
        }
        title={ScoreTypeLabels[ScoreType.InteractionExperience]}
      />
      <ScoreInput
        scores={EnglishLevelOptions}
        value={englishScore}
        onChange={(score) => handleCheck({ englishScore: score ?? undefined })}
        title={ScoreTypeLabels[ScoreType.English]}
      />
      <ScoreInput
        scores={AccentScoreOptions}
        value={accentScore}
        onChange={(score) => handleCheck({ accentScore: score ?? undefined })}
        title={ScoreTypeLabels[ScoreType.Accent]}
      />
    </div>
  );
};

export default VettingChecklist;
