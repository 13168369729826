import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useLoadingState, { LoadingState } from '@src/hooks/useLoadingState';
import { useStores } from '@src/stores';
import {
  PreVettingFormSubmittedLocation,
  RootLocation,
  SignInLocation,
} from '@src/locations';
import { VettingProcessStatus } from '@a_team/models/dist/vetting-processes/status';
import { ServiceError } from '@ateams/api/dist/endpoints/utils';

export function useValidateURL(): {
  nonce: string;
  loading: LoadingState;
  status?: VettingProcessStatus;
} {
  const { vettingProcesses } = useStores();
  const { nonce } = useParams<{
    nonce: string;
  }>();
  const history = useHistory();
  const [validateNonceLoading, setValidateNonceLoading] = useLoadingState();
  const [status, setStatus] = useState<VettingProcessStatus | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!nonce) {
      history.push(RootLocation);
    }

    setValidateNonceLoading(
      (async () => {
        try {
          const {
            isSubmitted,
            user,
            status: newStatus,
          } = await vettingProcesses.validatePreVettingFormNonce(nonce);

          if (user.isNewEvaluation) {
            setStatus(newStatus);
            if (history.location.pathname.includes('/form/')) {
              if (isSubmitted) {
                const { pathname, search } = history.location;
                const newPathname = pathname.replace('/form/', '/schedule/');
                const newUrl = `${newPathname}${search}`;
                history.replace(newUrl);
              }
            }
          } else {
            if (isSubmitted) {
              history.push(PreVettingFormSubmittedLocation);
            }
          }
        } catch (err) {
          if ((err as ServiceError)?.code === 401) {
            history.push(
              `${SignInLocation}?redirect=/prevetting/form/${nonce}`,
            );
            return;
          }
          history.push(RootLocation);
        }
      })(),
    );
  }, []);

  return {
    nonce,
    loading: validateNonceLoading,
    status,
  };
}
