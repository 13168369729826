import { ServiceAuth, ServiceEndpoint } from './utils';
import ServiceObject, {
  ServiceData,
  ServiceId,
} from '@a_team/models/dist/ServiceObject';

export const BasePath = '/services';

// exported functions

export default class ServicesEndpoint extends ServiceEndpoint {
  public updateService(
    auth: ServiceAuth,
    sid: ServiceId,
    data: ServiceData,
  ): Promise<ServiceObject> {
    return this.fetch(auth, BasePath + `/${sid}`, null, 'put', data);
  }

  public deleteService(auth: ServiceAuth, sid: ServiceId): Promise<null> {
    return this.fetch(auth, BasePath + `/${sid}`, null, 'delete');
  }
}
