import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { createUseStyles } from 'react-jss';
import { MissionLink } from '@a_team/models/dist/MissionObject';
import TextButton from '@src/components/TextButton';

interface Props {
  link?: MissionLink;
  onChange(link: MissionLink): void;
  onRemove?(): void;
}

const useStyles = createUseStyles({
  inputContainer: { display: 'flex' },
  titleInput: { width: '224px', marginRight: '16px' },
  linkInput: { width: '400px' },
  remove: {
    textAlign: 'right',
  },
});

function isValid(data: Partial<MissionLink>): {
  isValidTitle: boolean;
  isValidURL: boolean;
} {
  return {
    isValidTitle: !!data.title?.trim(),
    isValidURL: data.URL ? data.URL.match(/^https?:\/\/[^/ ]+/) != null : false,
  };
}

export default function LinkInput(props: Props): ReactElement {
  const { link, onChange, onRemove } = props;

  const [data, setData] = useState<Partial<MissionLink>>(link || {});
  const styles = useStyles();

  const { isValidTitle, isValidURL } = useMemo(() => isValid(data), [data]);

  const handleChange = (link: Partial<MissionLink>): void => {
    const newData = { ...data, ...link };
    setData(newData);

    const { isValidTitle, isValidURL } = isValid(newData);
    if (!isValidTitle || !isValidURL) {
      return;
    }

    onChange(newData as MissionLink);
  };

  useEffect((): void => {
    setData(link || {});
  }, [link]);

  return (
    <>
      <div className={styles.inputContainer}>
        <OutlinedInput
          placeholder="Title..."
          className={styles.titleInput}
          value={data.title || ''}
          error={data.title ? !isValidTitle : false}
          onChange={(e): void => handleChange({ title: e.target.value })}
        />
        <OutlinedInput
          placeholder="https://"
          className={styles.linkInput}
          value={data.URL || ''}
          error={data.URL ? !isValidURL : false}
          onChange={(e): void => handleChange({ URL: e.target.value })}
        />
      </div>
      {onRemove && (
        <div className={styles.remove}>
          <TextButton onClick={onRemove} highlight>
            Remove
          </TextButton>
        </div>
      )}
    </>
  );
}
