import React, { InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import Checkbox from '@src/components/Checkbox';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'margin'> {
  label: ReactNode;
  margin?: 'default' | 'none';
  labelPosition?: 'left' | 'right';
  indeterminate?: boolean;
}

const defaultProps = {
  labelPosition: 'right',
};

const useStyles = createUseStyles({
  root: (props: Props) => ({
    margin: props.margin === 'none' ? '0' : '64px 0 0',
    userSelect: 'none',
    cursor: props?.disabled ? 'not-allowed' : 'pointer',
    display: 'flex',
    alignItems: 'center',
    ...(props.labelPosition === 'left' && {
      flexDirection: 'row-reverse',
    }),
  }),
  text: (props: Props) => ({
    marginLeft: 8,
    ...(props.labelPosition === 'left' && {
      marginLeft: 0,
      marginRight: 8,
    }),
  }),
});

const LabeledCheckboxInput = (props: Props): ReactElement => {
  const { label, className, margin, labelPosition, ...left } = props;
  const styles = useStyles(props);

  return (
    <label className={cx(styles.root, className)}>
      <Checkbox {...left} />
      <span className={styles.text}>{label}</span>
    </label>
  );
};

LabeledCheckboxInput.defaultProps = defaultProps;
export default LabeledCheckboxInput;
