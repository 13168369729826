import { RatingStars } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
  },
  labels: {
    display: 'flex',
    flex: '1 0 auto',
    justifyContent: 'space-between',
    borderRight: '1px solid #ddd',
    marginRight: 10,
    paddingRight: 10,
  },
  rating: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 120,
  },
});

interface BuilderRatingsListItemContentProps {
  label: string;
  count: number;
  rating: number;
}

export const BuilderRatingsListItemContent: React.FC<
  BuilderRatingsListItemContentProps
> = ({ label, count, rating }) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.labels}>
        <span>{label}</span>
        <span>{count > 0 ? `(${count})` : `N/A`}</span>
      </div>
      <div className={styles.rating}>
        <RatingStars score={rating} />
      </div>
    </div>
  );
};
